/**
 * class for TimedTargetedOffer deal coin bar
 */
export class TimedTargetedOffer_DealCoinBar extends Phaser.Group {
  constructor(coinbarConfig, value) {
    super(game);
    this._init(value, 'IAP-store-coin-bar', 'coin_3', 'window_timedPopupOffer-coinbarTxt', coinbarConfig.textOffsetY, 1);
  }

  _init(value, bgAsset, coinAsset, style, textOffsetY, scale) {
    this._bg = G.makeImage(0, 0, bgAsset, 0.5, this);

    this._counter = new G.TextCounter(7, textOffsetY, value, style || G.OMTsettings.elements.LossAversionWheelGroup.defaultCounterStyle, 0.5, 110);
    this.add(this._counter);

    this._coins = G.makeImage(-80, 0, coinAsset, 0.5);
    this._coins.scale.setTo(0.5);
    this.addChild(this._coins);

    if (scale != null) this.scale.x = this.scale.y = scale;
  }
}
