/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
import { Window } from '../../../00_IMMEDIATE/Window';
import { Friendship_Chest } from './Friendship_Chest';
import Friendship_Chestv2_FTUX from './Friendship_Chestv2_FTUX';

/**
 * Another window for Friendship chest that gives the First Time User who was invited by a friend
 * a reward for reaching their goal of X levels!
 * @author Sandra Koo
 */
export class Window_friendshipChestFTUAward extends Window {
  constructor(parent) {
    super(parent);

    this.onFinishedEnter.addOnce(() => { this.enterFinished(); }); // onFinishEnter is called in Window base... somehow I made it in time!?

    this.checkAssets();

    if (game.state.current === 'World') {
      this.y = -G.WindowMgr.Constants.WorldVerticalOffset;
    }
  }

  /**
   * Checks if the friendship chest assets are ready before drawing
   */
  async checkAssets() {
    this.displayContainer = new Phaser.Group(G.game, this);
    this.card = undefined;
    this.claimElements = false;
    this.enterDone = false;

    if (!this._waitingIcon) this._waitingIcon = new G.WaitingIcon(0, 0, 'waiting_icon_white'); // Loading...
    this.addChild(this._waitingIcon);
    const result = await Friendship_Chest.areFTUAssetsReady();

    if (result) {
      this.drawElements();
      if (this._waitingIcon) {
        const tw = game.add.tween(this._waitingIcon)
          .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
        tw.onComplete.addOnce(() => {
          if (this._waitingIcon) {
            if (this._waitingIcon.parent) {
              this._waitingIcon.parent.removeChild(this._waitingIcon);
            }
            this._waitingIcon.destroy();
          }
          this._waitingIcon = null;
        });
      }
    }
  }

  /**
   * Draws the elements for the friendship chest
   */
  async drawElements() {
    this.friendshipChestContainer = new Phaser.Group(game, this);
    this._chest = new Friendship_Chestv2_FTUX({
      window: this,
      container: this.friendshipChestContainer,
    });
    this._chest.signals.onCloseClicked.addOnce(() => { this.closeWindow(); });
    this.drawComplete();
  }

  /**
   * When the window finishes entering
   */
  enterFinished() {
    this.enterDone = true;
    this.tryToAnimate();
  }

  /**
   * When the Claim objects are ready
   * @param {Object} goods
   */
  drawComplete() {
    this.claimElements = true;
    this.tryToAnimate();
  }

  /**
   * Waits for the claim elements to be done and the window has finished entering
   */
  tryToAnimate() {
    if (!this.claimElements || !this.enterDone) {
      return;
    }
    this.animateSequence();
  }

  /**
   * Waits and animate Claim!
   */
  animateSequence() {
    game.time.events.add(2000, () => {
      this._chest.animateClaim();
    }, this);
  }

  /**
   * @returns {boolean} is the window fullscreen? (for low res landscape purposes)
   */
  get isFullscreen() {
    return true;
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.friendshipChestFTUAward = Window_friendshipChestFTUAward;
