/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
// import ShareTickButton from '../ShareTickbutton';
import { Window } from '../../00_IMMEDIATE/Window';

/**
 * base class for windows with reward overlays
 */
export class Window_OverlayReward extends Window {
  /**
   * constructor
   * @param {*} parent
   */
  constructor(parent) {
    super(parent);

    this.state = game.state.getCurrentState();
    this.NOFADEINOUT = true;
  }

  /**
   * overlay a reward on the window
   * @param {*} config
   */
  overlayReward(config) {
    this._config = config;
    G.sb('hideMainHighscorePanel').dispatch();
    this._initInputHandler();
    this._setIntroAnimation();
  }

  /**
   * init the input handler
   */
  _initInputHandler() {
    this._inputHandler = G.makeImage(0, 0, null, 0.5, this);
    this._inputHandler.inputEnabled = true;
    this._inputHandler.hitArea = new Phaser.Rectangle(-2000, -2000, 4000, 4000);
    this._inputHandler.events.onInputDown.add(() => {
      // console.log('inputHandler clicked');
    }, this);
  }

  /**
   * set the intro animation
   */
  _setIntroAnimation() {
    this._shine = this._addShine(0, 100);
    this._box = this._addBox(10, 100);
    this._giftsGroup = this._addGiftGroup(0, 200);
    this.addChild(this._giftsGroup);
    this._giftsGroup.position = this._box.position;
    this._box.onOpen.add(() => {
      game.sfx.pop.play();
      game.sfx.transition.play();
      this._giftsGroup.showGifts();

      game.add.tween(this._box)
        .to({ y: 300 }, 200, Phaser.Easing.Sinusoidal.Out, true);

      game.add.tween(this._shine)
        .to({ y: 300, alpha: 0.2 }, 200, Phaser.Easing.Sinusoidal.Out, true);

      game.time.events.add(400, () => {
        this._inputHandler.events.onInputDown.addOnce(() => {
          this._goToLivesPresentation();
        }, this);
      }, this);

      game.time.events.add(2000, () => {
        this._goToLivesPresentation();
      }, this);
    }, this);
  }

  /**
   * set the animation showing lives given
   */
  _goToLivesPresentation() {
    if (this._wentToLivesPresentation) return;
    this._wentToLivesPresentation = true;

    game.sfx.whoosh.play();

    game.add.tween(this._box)
      .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true);
    game.add.tween(this._shine)
      .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true);

    G.stopTweens(this._giftsGroup.children[1].scale);
    game.add.tween(this._giftsGroup.children[1].scale)
      .to({ x: 0, y: 0 }, 500, Phaser.Easing.Sinusoidal.Out, true);
    G.stopTweens(this._giftsGroup.children[2].scale);
    game.add.tween(this._giftsGroup.children[2].scale)
      .to({ x: 0, y: 0 }, 500, Phaser.Easing.Sinusoidal.Out, true);
    G.stopTweens(this._giftsGroup.children[0]);
    game.add.tween(this._giftsGroup.children[0])
      .to({ y: -420 }, 500, Phaser.Easing.Sinusoidal.Out, true);

    this._msgs = this._dailymissiongift_addMsgs(0, 50);
    game.add.tween(this._msgs)
      .from({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.In, true);
  }

  /**
   * set gift feedback messages
   * @param {number} x
   * @param {number} y
   */
  _dailymissiongift_addMsgs(x, y) {
    const group = game.make.group();
    group.position.setTo(x, y);

    group.congrats = group.add(new G.Text(0, 0, OMT.language.getText('Congratulations!'), {
      style: 'font-white',
      fontSize: 60,
    }, 0.5, 500));

    group.youEarned = group.add(new G.Text(0, 120, this._config.endMsg, {
      style: 'font-white',
      fontSize: 40,
      lineSpacing: -10,
    }, 0.5, 500, 200, true, 'center'));

    if (this._config.showShare) {
      // group.shareUI = group.add(new ShareTickButton(0, 215, {
      //   style: 'font-white',
      //   fontSize: 36,
      //   lineSpacing: -10,
      // }, false));
      // group.shareUI.signals.onClick.addOnce(this._share, this);
    }

    group.tapAnywhere = group.add(new G.Text(0, 400,
      OMT.language.getText('Tap anywhere on the screen to continue.'), {
        style: 'font-white',
        fontSize: 40,
        lineSpacing: -10,
      }, 0.5, 500, 200, true, 'center'));
    game.add.tween(group.tapAnywhere)
      .to({ alpha: 0.2 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

    this.add(group);

    game.time.events.add(1000, () => {
      this._inputHandler.events.onInputDown.addOnce(this._finish, this);
    }, this);
    return group;
  }

  /**
   * on share / brag selected
   */
  _share() {
    game.sfx.pop.play();
    G.GiftUI.HelperFunctions.showShareLoadingOverlay(this);
    let closed = false;

    const timeout = G.AsyncUtils.createScheduler(game);
    timeout.rescheduleAction(() => {
      closed = true;
      this._handleClose();
    }, 5000);

    this._config.shareFunction(() => {
      if (closed) return;
      timeout.cancelSchedule();
      this._handleClose();
    });
  }

  /**
   * on animation sequencing finished
   */
  _finish() {
    game.sfx.pop.play();
    this._handleClose();
  }

  /**
   * handle window close
   */
  _handleClose() {
    const part = this.state.uiTargetParticles.init(
      game.world.bounds.x + this._giftsGroup.children[0].worldPosition.x,
      this._giftsGroup.children[0].worldPosition.y,
      'heart_unlimited', this.state.panel.lifeUI,
    );
    part.onStart.add(() => {
      game.add.tween(part.scale)
        .from({ x: 2, y: 2 }, 500, Phaser.Easing.Sinusoidal.Out, true);
    });
    part.onFinish.add(() => {
      // merged these 2 calls together so they could be tracked as 1
      const gifts = this._config.gifts.slice().concat([[G.gift.GiftContentType.UnlimitedLife, this._config.unlimitedLivesMin]]);
      // DDNA.transactionHelper.trackRewards(gifts, [], this._config.ddnaEventData);

      OMT.transactionTracking.logInventoryTransactionBegin();
      G.gift.applyGift(gifts, false, null, null);
      OMT.transactionTracking.logInventoryTransactionEnd();

      if (this._config.onClaimCallback) {
        this._config.onClaimCallback();
      }
      game.sfx.line.play();
    }, this);
    part.start();

    G.sb('showMainHighscorePanel').dispatch();
    this.closeWindow();
  }

  /**
   * add shine graphics
   * @param {number} x
   * @param {number} y
   */
  _addShine(x, y) {
    const group = game.make.group();
    group.position.setTo(x, y);
    group.shine1 = G.makeImage(0, 0, 'shine_godrays', 0.5, group);
    group.shine1.blendMode = 1;
    game.add.tween(group.shine1.scale)
      .to({
        x: 1.1,
        y: 1.1,
      }, 400, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

    group.shine2 = G.makeImage(0, 0, 'shine_godrays', 0.5, group);
    group.shine2.blendMode = 1;
    group.shine2.visible = false;
    game.add.tween(group.shine2.scale)
      .to({
        x: 1.1,
        y: 1.1,
      }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

    group.update = () => {
      group.shine1.angle += 0.25;
      group.shine2.angle += 1;
    };

    this.add(group);
    return group;
  }

  /**
   * add gift-box graphics
   * @param {number} x
   * @param {number} y
   */
  _addBox(x, y) {
    const group = game.make.group();
    group.position.setTo(x, y);

    let gift = null;
    let giftLid = null;
    let giftGlow = null;
    if (G.OMTsettings.elements.gifts.twoFrameOpen) {
      gift = G.makeImage(0, 0, 'gift_full', 0.5, group);
    } else {
      gift = G.makeImage(0, 0, 'gift_bottom', 0.5, group);
      giftLid = G.makeImage(0, 0, 'gift_top', 0.5, gift);
    }
    if (G.OMTsettings.elements.gifts.shineEnabled) {
      giftGlow = G.makeImage(0, 0, 'gift_open_glow', 0.5, gift);
      giftGlow.alpha = 0;
      giftGlow.x += G.OMTsettings.elements.gifts.shineOffsetX;
      giftGlow.y += G.OMTsettings.elements.gifts.shineOffsetY;
    }
    gift.scale.set(1.2);
    gift.inputEnabled = true;
    gift.input.useHandCursor = true;
    group.onOpen = new Phaser.Signal();
    gift.events.onInputDown.add(() => {
      // change to open
      gift.inputEnabled = false;
      gift.opened = true;
      group.onOpen.dispatch();
      if (!G.OMTsettingsDefault.elements.gifts.twoFrameOpen) {
        game.add.tween(giftLid)
          .to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.Out, true);
      } else {
        console.log('changing frame here');
        gift.frameName = 'gift_open';
      }
      if (giftGlow) {
        game.add.tween(giftGlow)
          .to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.Out, true);
      }
    });

    function boxJump() {
      if (gift.alpha === 1 && gift.visible && gift.game !== null && !gift.opened) {
        const moveTweenA = game.add.tween(gift).to({ y: -15 }, 150, Phaser.Easing.Cubic.Out);
        const moveTweenB = game.add.tween(gift).to({ y: 0 }, 150, Phaser.Easing.Circular.In);
        moveTweenA.chain(moveTweenB);
        moveTweenA.start();

        const tweenAngleA = game.add.tween(gift).to({ angle: -3 }, 100, Phaser.Easing.Cubic.InOut);
        const tweenAngleB = game.add.tween(gift).to({ angle: 3 }, 170, Phaser.Easing.Sinusoidal.In);
        const tweenAngleC = game.add.tween(gift).to({ angle: 0 }, 30, Phaser.Easing.Cubic.InOut);

        tweenAngleA.chain(tweenAngleB, tweenAngleC);
        tweenAngleA.start();

        game.time.events.add(1000, boxJump, this);
      }
    }
    boxJump();

    this.add(group);
    return group;
  }

  /**
   * add group with gift asset icons / animations
   * @param {number} x
   * @param {number} y
   */
  _addGiftGroup(x, y) {
    const group = game.make.group();
    group.position.setTo(x, y);
    let { gifts } = this._config;

    group.gift1 = this._makeGiftIcon(
      0, 0, ['lifeUnlimited', this._config.unlimitedLivesMin], { x: 10, y: -266, angle: -3, delay: 100 },
    );
    group.gift2 = this._makeGiftIcon(
      0, 0, gifts[0], { x: 133, y: -499, angle: 15, delay: 200 },
    );
    group.gift3 = this._makeGiftIcon(
      0, 0, gifts[1], { x: -133, y: -425, angle: -10, delay: 300 },
    );

    gifts = [group.gift1, group.gift2, group.gift3];
    group.showGifts = () => {
      gifts.forEach((gift) => {
        group.addChild(gift);
        gift.show();
      });
    };
    return group;
  }

  /**
   * make a gift icon
   * @param {number} x
   * @param {number} y
   * @param {Array} gift
   * @param {Object} tweenSettings
   */
  _makeGiftIcon(x, y, gift, tweenSettings) {
    const group = game.make.group();
    group.position.setTo(x, y);
    group.scale.setTo(0);

    if (gift[0] === 'coin') {
      const coin = G.makeImage(0, 0, 'coin_3', 0.5, group);
      coin.scale.setTo(1.2);
      const amount = new G.Text(0, 40, `x${gift[1]}`, {
        style: 'font-white',
        fontSize: 50,
      }, 0.5);
      group.add(amount);
    } else if (gift[0] === 'lifeUnlimited') {
      const icon = G.makeImage(0, 0, 'heart_unlimited', 0.5, group);
      icon.scale.setTo(2);
      let time;
      const hours = Math.floor(gift[1] / 60);
      const min = Math.floor(gift[1] % 60);
      if (hours && min) {
        time = `${hours}h ${min}min`;
      } else if (hours) {
        time = `${hours}h`;
      } else {
        time = `${min}min`;
      }
      const amount = new G.Text(0, 40, time, {
        style: 'font-white',
        fontSize: 50,
      }, 0.5);
      group.add(amount);
    } else {
      // assume it is booster
      const boosterBtn = G.makeImage(0, 0, G.gift.getIcon(gift), 0.5, group);
      boosterBtn.scale.setTo(1.5);
      const amountBg = G.makeImage(40, 40, 'booster_ammount', 0.5, group);
      amountBg.scale.setTo(1.5);
      const amountTxt = new G.Text(40, 43, gift[1].toString(), {
        style: 'font-blue',
        fontSize: 45,
      }, 0.5);
      group.add(amountTxt);
    }

    group.show = () => {
      game.add.tween(group.scale)
        .to({ x: 1, y: 1 }, 500, Phaser.Easing.Elastic.Out, true);
      game.add.tween(group)
        .to({
          x: tweenSettings.x,
          y: tweenSettings.y,
          angle: tweenSettings.angle,
        }, 2000, Phaser.Easing.Exponential.Out, true);
    };
    return group;
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.overlayReward = Window_OverlayReward;
