/* eslint-disable no-unused-vars */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */

import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import OMT_VILLAINS from '../../../OMT_UI/OMT_Villains';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import { TextTimer, TIMER_FORMAT } from '../../TextTimer';
import Window_Level from '../Window_level';
import { EventLevelCollection } from './EventLevelCollection';
import { EventLevelProgression } from './EventLevelProgression';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

/**
 * Class for pre-level window for special event levels
 * Forked from Window_level with a sprinkle of Window_dailyChallenge
 */
export default class Window_EventLevel extends Window_Level {
  /**
   * constructor
   * @param {Object} parent
   * @param {number} optLvlIndex (optional)
   * @param {Object} retryConfig (optional) config passed for retrying level
   */
  constructor(parent, optLvlIndex, retryConfig) {
    super(parent, optLvlIndex, retryConfig);
    this._initProgressBar();
    this._initTimer();

    // Move window up
    if (game.state.current === 'Game' && OMT.systemInfo.orientation === ORIENTATION.vertical) {
      this.y -= 75;
    }

    this._onResize();
    if (this._onResizeSB && this._onResizeSB.detach) this._onResizeSB.detach(); // Detach window_level resize token
    this._onResizeSB = G.sb('onScreenResize').add(this._onResize, this);
  }

  /**
   * Manage the mode tracker
   * this is needed for the levelWindowOpen signal and this function is run before thats dispatched in Window_level
   */
  _manageMode() {
    if (game.state.getCurrentState().mode === LevelType.NONE) { // Game state will manage but World state does not
      game.state.getCurrentState().mode = LevelType.COLLECT_EVENT;
    }
    this.gameMode = LevelType.COLLECT_EVENT;
  }

  /**
   * setup level data
   * @param {*} level
   */
  // the level passed here is actually the data, not just the level index (different from Window_level)
  _manageData(level) {
    // fetch event level data again if needed
    if ((typeof level) !== 'object') level = G.saveState.getEventLevel(false);

    // set global level data
    if (level !== undefined) {
      // when we get level the first time, its an object, but if we go to the shop and come back, its a number, so we check for both.
      if (typeof level === 'number') {
        G.lvlData = G.Helpers.levelDataMgr.getLevelByIndex(level);
      } else {
        G.lvlData = level;
      }
      this.levelData = G.lvlData;
      G.lvlNr = this.levelData.levelNumber;
    }
    this._parentLevelName = 'eventLevel';
    this._eventLevel = this.levelData.levelNumber;

    OMT_VILLAINS.setLevelType(this.gameMode);
  }

  /**
   * init the generic graphics layout
   */
  _initLayout() {
    const headerTxt = OMT.language.getText('tokenEvent Level');
    this._extraContainer = new Phaser.Group(game, this); // For some reason calling this.toLocal returns NaN but this.Group.toLocal is fine
    this._levelContainer = new Phaser.Group(game, this._extraContainer);
    this.bg = G.makeImage(0, 0, 'tokenEvent_popup_background', 0.5, this._levelContainer);

    // banner
    this._banner = G.makeImage(
      0, -295,
      'tokenEvent_banner',
      0.5,
      this._levelContainer,
    );

    // title text
    this._titleTxt = new G.Text(
      G.OMTsettings.tokenEvent.windowHeader.text.x,
      G.OMTsettings.tokenEvent.windowHeader.text.y,
      headerTxt, 'tokenEvent-eventLevelTitle', 0.5, G.OMTsettings.tokenEvent.windowHeader.text.width, 125, true, 'center',
    );
    this._levelContainer.add(this._titleTxt);
  }

  /**
   * init task UI
   */
  _initTasks() {
    this._taskBg = G.makeImage(0, 5, 'tokenEvent_popup_bigtext_backgr', 0.5, this._levelContainer);
    this._taskTxt = new G.Text(0, -70, `${OMT.language.getText('Task')}:`, {
      style: 'font-blue',
      fontSize: '45px',
      fill: G.OMTsettings.tokenEvent.styling.windowTextFill,
    }, 0.5, 380);
    this._levelContainer.add(this._taskTxt);

    if (this.levelData.goal[0] === 'collect') {
      const textStyle = {
        ...G.OMTsettings.elements.taskCollectPanels.amount.style,
        fill: G.OMTsettings.tokenEvent.styling.taskTextFill,
      };
      this._taskPanel = G.Helpers.createTaskCollectPanels(this.levelData.goal[1], textStyle);
      this._taskPanel.y = 5;
      this._levelContainer.add(this._taskPanel);
    } else {
      this._levelContainer.add(new G.Text(0, 5, `${OMT.language.getText('points').toUpperCase()}: ${this.levelData.goal[1]}`, {
        style: 'font-blue',
        fontSize: '50px',
        fill: G.OMTsettings.tokenEvent.styling.taskTextFill,
      }, 0.5, 380));
    }
  }

  /**
   * init booster and booster UI
   * @param {number} optLvlIndex
   * @param {number} windowType string
   */
  _initBoosters(levelNumber, windowType = 'eventLevel') {
    const lvlNumber = levelNumber || G.lvlNr;
    const saveStateMgr = G.saveState;
    this._buyTxt = new G.Text(0, 90, ':', {
      style: 'font-blue',
      fontSize: '35px',
      fill: G.OMTsettings.tokenEvent.styling.windowTextFill,
    }, 0.5, 510);

    this.disableText = (new G.Text(0, 0, OMT.language.getText('This booster is disabled for this level.'), {
      style: 'font-blue',
      fill: G.OMTsettings.tokenEvent.styling.windowTextFill,
    }));
    this.bgImage = new PhaserNineSlice.NineSlice(0, 0, 'treasure-hunt', 'th_tutorial_box', this.bg.width, this.disableText.height + 30,
      {
        left: 30,
        right: 30,
        bottom: 30,
        top: 30,
      });
    this.bgImage.x = -this.bgImage.width / 2;
    this.bgImage.y = -this.bgImage.height / 2;
    this.disabledBoosterMessage.add(this.bgImage);
    this.disabledBoosterMessage.add(this.disableText);

    this.disableText.width = this.bg.width - 50;
    this.disableText.scale.y = this.disableText.scale.x;

    this.bgImage.width = this.bg.width;
    this.bgImage.height = 60;

    this.disableText.x = -this.disableText.width / 2;
    this.disableText.y = -this.disableText.height / 2;

    this.disabledBoosterMessage.alpha = 0;

    const checkText = () => { // in scope of this._buyTxt
      if (!this.alive) return;
      const bst = saveStateMgr.getBoosterArray();
      const allActivate = bst[5] > 0 && bst[7] > 0 && bst[8] > 0;
      const allZero = bst[5] === 0 && bst[7] === 0 && bst[8] === 0;
      let newTxt;
      if (allActivate) {
        newTxt = `${OMT.language.getText('Activate boosters')}:`;
      } else if (allZero) {
        newTxt = `${OMT.language.getText('Buy some boosters')}:`;
      } else {
        newTxt = `${OMT.language.getText('Buy or activate boosters')}:`;
      }
      if (this._buyTxt.text !== newTxt) this._buyTxt.setText(newTxt);
    };

    this._buyTxt.checkText = checkText.bind(this._buyTxt);

    this._buyTxt.checkText();
    this._levelContainer.add(this._buyTxt);

    this._buyTxtBinding = G.sb('onStartBoosterSelectBounce').add(function () {
      G.stopTweens(this._buyTxt);
      this._buyTxt.checkText();
      this._buyTxt.scale.setTo(1);
      game.add.tween(this._buyTxt.scale)
        .to({ x: 1.2, y: 1.2 }, 200, Phaser.Easing.Custom.SoftBounce, true, 0, 0, true);
    }, this);

    this._buyTxt.events.onDestroy.add(function () {
      if (this._buyTxtBinding && this._buyTxtBinding.detach) {
        this._buyTxtBinding.detach();
      }
    }, this);

    this._boosterBg = G.makeImage(0, 170, 'tokenEvent_popup_bigtext_backgr', 0.5, this._levelContainer);

    // For more rounded level windows, the booster panel background needs to be shrinked
    let boosterBgOffset;
    if (G.OMTsettings.tokenEvent.window_eventLevel.boosterBgWidth) {
      boosterBgOffset = this._boosterBg.width - G.OMTsettings.tokenEvent.window_eventLevel.boosterBgWidth;
      this._boosterBg.width = G.OMTsettings.tokenEvent.window_eventLevel.boosterBgWidth;
    }

    let movesBoosterType = 5;
    if (G.IAP) {
      movesBoosterType = 6;
    }

    // if we dont have any boosters left, make sure theyre not selected in the startBoosterConfig
    if (this.state.startBoosterConfig.data[lvlNumber]) {
      if (saveStateMgr.getBoosterAmount(movesBoosterType) === 0) this.state.startBoosterConfig.data[lvlNumber][movesBoosterType] = 0;
      if (saveStateMgr.getBoosterAmount(7) === 0) this.state.startBoosterConfig.data[lvlNumber][7] = 0;
      if (saveStateMgr.getBoosterAmount(8) === 0) this.state.startBoosterConfig.data[lvlNumber][8] = 0;
    }

    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const allowMultiple = OMT.feature.multiplePreLevelBoostersAllowed() && isNotNormalLevel;
    // Original booster positioning
    let boosterSeparation = 195;
    if (boosterBgOffset) {
      // The booster bg width was changed
      boosterSeparation -= boosterBgOffset / 2;
    }
    this._boosters = [
      new G.UI_StartBoosterButton(this, -boosterSeparation, 170, movesBoosterType, lvlNumber, windowType, {
        layerName: this.parent.layerName,
        args: [lvlNumber],
      }, false, allowMultiple, G.OMTsettings.tokenEvent.styling.windowTextFill),
      new G.UI_StartBoosterButton(this, 0, 170, 7, lvlNumber, windowType, {
        layerName: this.parent.layerName,
        args: [lvlNumber],
      }, this.levelData.noPreBoosters, allowMultiple, G.OMTsettings.tokenEvent.styling.windowTextFill),
      new G.UI_StartBoosterButton(this, boosterSeparation, 170, 8, lvlNumber, windowType, {
        layerName: this.parent.layerName,
        args: [lvlNumber],
      }, this.levelData.noPreBoosters, allowMultiple, G.OMTsettings.tokenEvent.styling.windowTextFill),
    ];

    if (this.levelData.noPreBoosters) {
      for (let i = 0; i < this._boosters.length; i++) {
        const booster = this._boosters[i];
        booster.onSelected = this._onBoosterSelected.bind(this);
      }
    }

    if (this.state.startBoosterConfig.isSelected(lvlNumber, movesBoosterType) && saveStateMgr.getBoosterAmount(movesBoosterType) > 0) {
      if (allowMultiple) this._boosters[0].increment(false);
      else this._boosters[0].select(false);
    }
    if (this.state.startBoosterConfig.isSelected(lvlNumber, 7) && saveStateMgr.getBoosterAmount(7) > 0) {
      if (allowMultiple) this._boosters[1].increment(false);
      else this._boosters[1].select(false);
    }
    if (this.state.startBoosterConfig.isSelected(lvlNumber, 8) && saveStateMgr.getBoosterAmount(8) > 0) {
      if (allowMultiple) this._boosters[2].increment(false);
      else this._boosters[2].select(false);
    }

    this._levelContainer.addMultiple(this._boosters);
  }

  _initButtons() {
    super._initButtons(BUTTONCOLOURS.green, this._onContinueClick, false);

    const layout = {};
    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      layout.postcard = {
        x: -300,
        y: 410,
      };
      layout.tournament = {
        x: 300,
        y: 410,
      };
    } else {
      layout.postcard = {
        x: 0,
        y: 410,
      };
      layout.tournament = {
        x: 0,
        y: 495,
      };
    }

    this._postcardsButton = new G.Button(layout.postcard.x, layout.postcard.y, 'tokenEvent_postcards_button', this._onPostcardsBtnClick.bind(this));
    this._postcardsIcon = G.makeImage(layout.postcard.x - 175, layout.postcard.y, 'tokenEvent_open_envelope', 0.5);
    this._postcardsIcon.angle = G.OMTsettings.tokenEvent.window_eventLevel.postcardsIconAngle || 0;
    this._postcardsText = new G.Text(layout.postcard.x, layout.postcard.y, OMT.language.getText('Postcards'), {
      style: 'font-white',
      fontSize: 35,
    }, 0.5);

    this._levelContainer.add(this._postcardsButton);
    this._levelContainer.add(this._postcardsIcon);
    this._levelContainer.add(this._postcardsText);

    this._leaderboardButton = new G.Button(layout.tournament.x, layout.tournament.y, 'tokenEvent_leaderboard_button', this._onLeaderboardBtnClick.bind(this));
    this._leaderboardText = new G.Text(layout.tournament.x, layout.tournament.y, OMT.language.getText('Leaderboard'), {
      style: 'font-white',
      fontSize: 35,
    }, 0.5);

    this._levelContainer.add(this._leaderboardButton);
    this._levelContainer.add(this._leaderboardText);
  }

  /**
   * Set up the tournament promotion button that sits below the window.  Clicking it will lead to the tournament
   * not used in this class, but Window_Level still calls it
   */
  _setupTournamentPromoButton() {
    // unused
  }

  /**
   * display stars achived
   * not used in this class, but Window_Level still calls it
   */
  _initStars() {
    // unused
  }

  /**
   * display event progress
   */
  _initProgressBar() {
    if (G.saveState.tokenEventManager.allPostCardsCollected) {
      const style = {
        style: 'font-blue',
        fontSize: '45px',
        fill: G.OMTsettings.tokenEvent.styling.windowTextFill,
      };
      this._collectionPanel = new EventLevelCollection(0, -140, style);
      this._levelContainer.add(this._collectionPanel);
    } else {
      this._progressBar = new EventLevelProgression(0, -140);
      this._levelContainer.add(this._progressBar);
    }
  }

  _initTimer() {
    this._timerGroup = new Phaser.Group(game, null);
    this._timerGroup.y = G.OMTsettings.elements.Window_EventLevel.yPos.min;
    this._levelContainer.add(this._timerGroup);

    this._timerBg = G.makeImage(0, 0, 'timer-bar', 0.5, this._timerGroup);

    const curTime = OMT.connect.getServerTimestampSync();
    const durationLeftSec = (G.featureUnlock.tokenEvent.range.end - curTime) / 1000;
    let timeStyle = TIMER_FORMAT.MS;
    const shownEndTime = G.changeSecToDHMS(durationLeftSec);
    if (Number.parseInt(shownEndTime[0]) > 0) {
      timeStyle = TIMER_FORMAT.DH;
    } else if (Number.parseInt(shownEndTime[1]) > 0) {
      timeStyle = TIMER_FORMAT.HM;
    }

    this._textTimer = new TextTimer({
      x: 25,
      y: 0,
      style: 'eventPostcard-eventLevelTimer',
      anchor: 0.5,
      maxWidth: 275,
      timerFormat: timeStyle,
      wrapperText: OMT.language.getText('Event ends in: %TIME%'),
    });
    this._textTimer.setSecLeft(durationLeftSec);
    this._textTimer.active = true;
    this._timerGroup.add(this._textTimer);
  }

  /**
   * on continue button clicked
   */
  _onContinueClick() {
    const levelData = {
      lvlIndex: this._eventLevel - 1,
      debugMode: false,
      startBoosters: super._assembleLevelBoosters(this._eventLevel),
      eventLvl: this.levelData,
      preLevelTrackingData: {},
    };
    G.saveState.tokenEventManager.resetLevelTokens();
    G.sb('onStateChange').dispatch('Game', levelData);
  }

  _onPostcardsBtnClick() {
    G.sb('pushWindow').dispatch(['eventPostcard', this._getReturnArgs(), {
      startIndex: Math.max(G.saveState.tokenEventManager.getPostcardLastOpenedIndex(), 0),
    }]);
    this.closeWindow();
  }

  _onLeaderboardBtnClick() {
    G.sb('pushWindow').dispatch(['tokenEventLeaderboard', this._getReturnArgs()]);
    this.closeWindow();
  }

  _getReturnArgs() {
    return {
      windowName: 'eventLevel',
      args: [G.saveState.getEventLevel(false), this._retryConfig],
      layerName: this.parent.layerName,
    };
  }

  /**
   * Resize handler
   */
  _onResize() {
    const yData = G.OMTsettings.elements.Window_EventLevel.yPos;
    const yPos = yData[OMT.systemInfo.orientationKey];
    const yLerpFactor = Math.min(Math.max((game.height - yData.minScreenHeight) / (yData.maxScreenHeight - yData.minScreenHeight), 0), 1);
    if (this._timerGroup) {
      this._timerGroup.y = yPos.min + (yPos.max - yPos.min) * yLerpFactor;
    }
  }

  destroy() {
    super.destroy();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.eventLevel = Window_EventLevel;
