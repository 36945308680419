G.CommonObjects = G.CommonObjects || {};

G.CommonObjects.PageScroller = function (config) {
  Phaser.Group.call(this, game);

  this.onPageIndexChanged = new Phaser.Signal();

  this.scrollInput = this.addDragContainer(
    config.width, config.height,
  );

  this.pagesContainer = game.add.group(this);
  this.currentPageIndex = 0;
  this.visiblePageIndex = 0;

  this.pageWidth = config.pageWidth;
  this.dragThreshold = config.dragThreshold;
};

G.CommonObjects.PageScroller.prototype = Object.create(Phaser.Group.prototype);

G.CommonObjects.PageScroller.prototype.addDragContainer = function (width, height) {
  const scrollInput = new G.ScrollInput(width * -0.5, height * -0.5, width, height);
  this.add(scrollInput);
  return scrollInput;
};

G.CommonObjects.PageScroller.prototype.update = function () {
  Phaser.Group.prototype.update.call(this);

  this.updateInput();
  this.pagesContainer.x = this.visiblePageIndex * this.pageWidth * -1;
  this.updatePagesAlpha();
};

G.CommonObjects.PageScroller.prototype.updateInput = function () {
  if (this.scrollInput.isDuringDrag()) {
    this.visiblePageIndex += this.scrollInput.getDeltaX() / this.pageWidth;
    const diff = this.visiblePageIndex - this.currentPageIndex;
    if (Math.abs(diff) > this.dragThreshold) {
      this.scrollInput.stopDragging();
      this.changePage(this.currentPageIndex + (Math.sign(diff)));
    }
  } else {
    this.visiblePageIndex = G.lerp(
      this.visiblePageIndex,
      this.currentPageIndex,
      0.3,
      0.01,
    );
  }
};

G.CommonObjects.PageScroller.prototype.updatePagesAlpha = function () {
  for (let i = 0, len = this.pagesContainer.length; i < len; i++) {
    const page = this.pagesContainer.getChildAt(i);
    if (page) {
      const diff = Math.abs(this.visiblePageIndex - i);
      page.alpha = game.math.clamp(1 - diff, 0, 1);
    }
  }
};

G.CommonObjects.PageScroller.prototype.changePage = function (pageIndex) {
  pageIndex = game.math.clamp(pageIndex, 0, this.getMaxPageIndex());

  if (this.currentPageIndex === pageIndex) return false;

  const preChangePage = this.pagesContainer.children[this.currentPageIndex];
  if (preChangePage) preChangePage.ignoreChildInput = true;

  this.currentPageIndex = pageIndex;

  const postChangePage = this.pagesContainer.children[this.currentPageIndex];
  if (postChangePage) postChangePage.ignoreChildInput = false;

  this.onPageIndexChanged.dispatch(
    this.currentPageIndex, this.getMaxPageIndex(),
  );

  return true;
};

G.CommonObjects.PageScroller.prototype.goToNextPage = function () {
  this.changePage(this.currentPageIndex + 1);
};

G.CommonObjects.PageScroller.prototype.goToPreviousPage = function () {
  this.changePage(this.currentPageIndex - 1);
};

G.CommonObjects.PageScroller.prototype.addPage = function (page) {
  page.x = this.pagesContainer.children.length * this.pageWidth;
  this.pagesContainer.add(page);

  if (this.currentPageIndex !== this.pagesContainer.getChildIndex(page)) {
    page.ignoreChildInput = true;
    page.alpha = 0;
  }

  return page;
};

G.CommonObjects.PageScroller.prototype.getCurrentPageIndex = function () {
  return this.currentPageIndex;
};

G.CommonObjects.PageScroller.prototype.getMaxPageIndex = function () {
  return this.pagesContainer.length - 1;
};
