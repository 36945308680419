import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../../OMT_UI/OMT_UI_SquareButton';

/**
 * button class for spinning the wheel in loss aversion flow
 */
export default class OOMSpinBtn extends OMT_UI_SquareButton {
  constructor(x, y, config) {
    super(x, y, config);

    this.refreshState();
    this._bindings = [
      G.sb('onFreeMoneySpinUsed').add(this.refreshState, this),
    ];
  }

  /**
   * draw button elements
   */
  _drawButton() {
    this._iconGroup = new Phaser.Group(game, this);
    this._icon = G.makeImage(0, 0, 'new_wheel_menu', 0.5, this._iconGroup);
    this._videoIcon = G.makeImage(0, 0, 'video_wheel_menu', 0.5, this._iconGroup);
    this._videoIcon.x = this._icon.x + (this._icon.width - this._videoIcon.width) / 2;
    this._videoIcon.y = this._icon.y + (this._icon.height - this._videoIcon.width) / 2;
    this._videoIcon.visible = false;
    this._iconGroup.scale.setTo(0.8);
    this.config.text.icon = {
      image: this._iconGroup,
      align: 'left',
      offset: {
        x: this._iconGroup.width / 4,
        y: 0,
      },
    };
    this.config.text.offset.x = this._iconGroup.width / 8;
    super._drawButton();
  }

  /**
   * refresh the button state based on availability of spins
   */
  refreshState() {
    if (this.game === null) return;
    const prev = this.spinState;
    const current = G.saveState.isFreeMoneySpinAvailable() && OMT.feature.failFlowWheelFreeSpin();

    if (current !== prev) {
      if (current) {
        this.setColour(BUTTONCOLOURS.orange);
        this.setText(OMT.language.getText('Free Spin'));
        this.resize(309, 100);
        this._videoIcon.visible = false;
      } else if (game.incentivised()) {
        this.setColour(BUTTONCOLOURS.purple);
        this.setText(OMT.language.getText('Spin Wheel'));
        this.resize(309, 100);
        this._videoIcon.visible = true;
      } else {
        this.setColour(BUTTONCOLOURS.gray);
        this.setText(OMT.language.getText('Free Spin'));
        this.resize(309, 100);
        this._videoIcon.visible = false;
      }
    }
    this.spinState = current;
  }

  /**
   * destruction method
   */
  destroy() {
    this._bindings.forEach((binding) => {
      binding.detach();
    });
    super.destroy();
  }
}
