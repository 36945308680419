const DEFAULT_OPACITY = 0.5;
const DEFAULT_DURATION = 500;

/**
 * Class used for blocking input events. It will resize to the screen dimensions automatically. You just need to align it to 0,0
 */
export default class UI_EventBlocker extends Phaser.Group {
  /**
   * constructor
   * @param {number} width (optional) default will stretch to game width
   * @param {number} height (optional) default will stretch to game height
   * @param {boolean} autoResize (boolean) default true
   */
  constructor(width = -1, height = -1, autoResize = true) {
    super(game);

    if (width === -1) width = game.width;
    if (height === -1) height = game.height;
    this._createEventBlockerGraphics(width, height);

    if (autoResize) {
      G.sb('onScreenResize').add(this._onScreenResize, this);
      this._onScreenResize();
    }
  }

  /**
   * create the event blocker graphics
   * @param {number} width
   * @param {number} height
   */
  _createEventBlockerGraphics(width, height) {
    this._eventBlocker = new Phaser.Graphics(game);
    this._eventBlocker.beginFill(0x000000, 1);
    this._eventBlocker.drawRect(0, 0, width, height);
    this._eventBlocker.inputEnabled = true;
    this._eventBlocker.alpha = 0;
    this._eventBlocker.visible = false;
    this.addChild(this._eventBlocker);
  }

  /**
   * show the event blocker
   * @param {number} duration (optional) in milliseconds
   * @param {number} opacity (optional) 0-1
   */
  show(duration = DEFAULT_DURATION, opacity = DEFAULT_OPACITY) {
    this._eventBlocker.visible = true;
    if (this._tween) this._tween.stop();
    if (duration === 0) {
      this._eventBlocker.alpha = opacity;
    } else {
      this._tween = game.add.tween(this._eventBlocker);
      this._tween.to({ alpha: opacity }, duration, Phaser.Easing.Quadratic.Out, false);
      this._tween.start();
    }
  }

  /**
   * hide the event blocker
   * @param {number} duration (optional) in milliseconds
   */
  hide(duration = DEFAULT_DURATION) {
    if (this._tween) this._tween.stop();
    if (duration === 0) {
      this._eventBlocker.alpha = 0;
      this._eventBlocker.visible = false;
    } else {
      this._tween = game.add.tween(this._eventBlocker);
      this._tween.to({ alpha: 0 }, duration, Phaser.Easing.Quadratic.Out, false);
      this._tween.onComplete.addOnce(() => { this._eventBlocker.visible = false; });
      this._tween.start();
    }
  }

  /**
   * resize the event blocker, called on screenResize
   */
  _resizeEventBlocker() {
    this._eventBlocker.width = game.width;
    this._eventBlocker.height = game.height;
  }

  /**
   * resize content to fit screen layout
   */
  _onScreenResize() {
    this._resizeEventBlocker();
  }

  /**
   * destruction method
   */
  destroy() {
    G.sb('onScreenResize').remove(this._onScreenResize, this);
    if (this._tween) this._tween.stop();
    this._tween = null;
    super.destroy();
  }
}
