export class CoinBar extends Phaser.Group {
  constructor(x, y, hidePlusButton, counterStyle, bgAsset = 'top-panel-coinsBg') {
    super(G.game, null);
    this.position.setTo(x, y);
    this.counterStyle = counterStyle;
    this.bg = G.makeImage(0, 0, bgAsset, 0.5, this);
    this.bg.scale.setTo(1.1);
    this.counter = new G.TextCounter(21, 4, G.saveState.getCoins(), this.counterStyle || G.OMTsettings.elements.LossAversionWheelGroup.defaultCounterStyle, 0.5, 110);
    this.add(this.counter);

    G.Utils.cleanUpOnDestroy(this,
      G.sb('onCoinsChange').add(function (coins) {
        this.counter.setAmount(coins, 10);
      }, this));

    this.onPlusBtnClicked = new Phaser.Signal();

    if (!hidePlusButton) {
      this.plusBtn = new G.Button(115, 0, 'btn_plus', function () {
        if (this.onPlusBtnClicked.getNumListeners() > 0) {
          this.onPlusBtnClicked.dispatch();
        }
      }, this);
      this.add(this.plusBtn);
    }
  }
}
G.CoinBar = CoinBar;
