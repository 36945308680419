import UI_GoalTournamentBase from '../base/UI_GoalTournament';
import { TournamentSocialDisplayHorizontal } from './Tournament/TournamentSocialDisplayHorizontal';

export default class UI_GoalTournamentHorizontal extends UI_GoalTournamentBase {
  /**
   * Returns the class used for socialDisplay
   * @returns {Class}
   */
  _createTournamentDisplay() {
    return new TournamentSocialDisplayHorizontal();
  }

  _createPointsCounter() {
    this._pointsCounter = new G.Text(0, 40, '0', 'UIGoalPanelPointAmount-tournament-horizontal', 0.5, 200,
      null, true, 'center');
    if (this._socialDisplay.numberOfParticipants === 0) {
      const pointTxt = new G.Text(0, 0, G.capitalize(OMT.language.getText('points')), 'UIGoalPanelPointText-tournament-horizontal', 0.5, 200);
      this.add(pointTxt);
      this.add(this._pointsCounter);
    }
  }

  /**
   * Updates text
   * @param {boolean} forceUpdate
   */
  update(forceUpdate = false) {
    const { points } = G.lvl;
    if (this._points !== points || forceUpdate) {
      this._pointsCounter.setText(OMT.language.toLocaleNumber(points));
      this._points = points;
      this._socialDisplay.setPoints(this._points);
    }
  }
}
