import TutorialGrid from '../BoardAnim/G.TutorialGrid';
import TutorialGroup from '../BoardAnim/TutorialGroup';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';

const tileSize = 90;
const mechanicSprite = 'dirt_s_';
const gridScale = 1.2;

/**
 * @author Sandra Koo
 */
class WaffleBreakTutorial extends TutorialGroup {
  constructor() {
    super(game, null);

    this.currentPage = 0;
    this.pageOne = new Phaser.Group(game, null);
    this.gridBg = undefined;
    this.waffles = undefined;
    this.gems = undefined;
    this.extras = undefined;
    this.gemEmitter = [];
    this.tutHand = undefined;
    this.description = undefined;
    this.continueButton = undefined;

    this.pageTwo = new Phaser.Group(game, null);

    this.drawEverything();
    this.showPageOne();
  }

  /**
   * Draws all elements
   * Initializes some of them
   */
  drawEverything() {
    this.gridBg = new TutorialGrid(); // Grid bg. Has the background sprite

    this.mechanic = new TutorialGrid(); // For the infection

    this.gems = new TutorialGrid(); // For the gems

    this.extras = new TutorialGrid(); // For the gems

    for (let i = 0; i < 3; i++) { // Particle emitters for the gems
      const emitter = new FxParticle(); // For the sfx
      emitter.scale.setTo(gridScale); // It also grows big
      this.gemEmitter.push(emitter);
    }

    this.tutHand = new Phaser.Group(game);
    G.makeImage(0, 0, 'tut_hand', 0, this.tutHand); // Haaaand

    this.description = new G.Text(0, -150, ' ', { // The instructions
      style: 'font-blue',
      fontSize: 35,
      lineSpacing: -15,
    }, 0.5, 400, 200, true, 'center');

    this.continueButton = new OMT_UI_SquareButton(0, 260, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onContinueClick.bind(this),
        },
      },
    });
    this.addChild(this.continueButton);
  }

  /**
   * When continue is clicked
   */
  async onContinueClick() {
    this.stopAllActions(); // Stop all tweens
    if (this.currentPage === 1) { // If page one, go to page 2
      this.continueButton.active = false;
      await this.wrapTween(this.pageOne, { alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut);
      this.removeChild(this.pageOne);
      this.showPageTwo();
      this.continueButton.active = true;
    } else { // Otherwise close
      this.signals.onContinueClick.dispatch();
      this.destroy();
    }
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this.gems) {
      this.gems.destroy();
    }

    if (this.gridBg) {
      this.gridBg.destroy();
    }

    if (this.mechanic) {
      this.mechanic.destroy();
    }

    if (this.extras) {
      this.extras.destroy();
    }

    this.pageOne.destroy();
    this.pageTwo.destroy();

    super.destroy();
  }

  /**
   * Positions all grids
   * @param {number} targetX
   * @param {number} targetY
   */
  positionGrids(targetX, targetY) {
    this.gridBg.x = targetX;
    this.gridBg.y = targetY;

    this.mechanic.x = targetX;
    this.mechanic.y = targetY;

    this.gems.x = targetX;
    this.gems.y = targetY;

    this.extras.x = targetX;
    this.extras.y = targetY - tileSize; // Is a little offset above
  }

  /**
   * Shows the first part of the tutorial
   */
  showPageOne() {
    this.currentPage = 1;
    const gridX = 4;
    const gridY = 3;

    this.description.setText(OMT.language.getText('Match %gems% on the %infections% to crush them!'));

    this.gridBg.init(gridX, gridY, tileSize, 'tut_tile', gridScale);

    // init grids with candies
    const mechanicData = [0, 0, 0, 0,
                          1, 1, 1, 0, // eslint-disable-line indent
                          0, 0, 0, 0].map((num) => (num === 0 ? null : `${mechanicSprite}${num}`)); // eslint-disable-line indent
    this.mechanic.init(gridX, gridY, tileSize, mechanicData, gridScale); // Passes in an array of null/string to denote which parts have nothing and which have the icon

    const gemsData = [5, 5, 1, 5,
                      4, 4, 1, 3, // eslint-disable-line indent
                      5, 3, 4, 5].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    const extraData = [3, 4, 5, 0,
                       0, 0, 0, 0, // eslint-disable-line indent
                       0, 0, 0, 0].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.extras.init(gridX, gridY, tileSize, extraData, gridScale);


    this.positionGrids(0, this.description.y + (this.description.height + this.gridBg.height) / 2);

    this.tutHand.alpha = 0; // Hide this
    this.pageOne.alpha = 0;

    this.addChild(this.description);
    this.pageOne.addChild(this.gridBg);
    this.pageOne.addChild(this.mechanic);
    this.pageOne.addChild(this.gems);
    this.pageOne.addChild(this.extras);
    for (let i = 0; i < this.gemEmitter.length; i++) { // I only need 2 gem emitters
      this.pageOne.addChild(this.gemEmitter[i]);
    }
    this.pageOne.addChild(this.tutHand);
    this.addChild(this.pageOne);
    this.addChild(this.continueButton);

    this.animatePageOne();
  }

  /**
   * Animates page one
   */
  async animatePageOne() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 1; // Modifies the shouldEventComplete by checking the page

    // Handy function because I'll need a lot of gems
    const getGemData = (board, cellX, cellY) => ({
      sprite: board.getSpriteByCell(cellX, cellY),
      pos: board.getPxPos(cellX, cellY),
    });

    const gem00 = getGemData(this.gems, 0, 0);
    const gem10 = getGemData(this.gems, 1, 0);
    const gem20 = getGemData(this.gems, 2, 0);
    const gem01 = getGemData(this.gems, 0, 1);
    const gem11 = getGemData(this.gems, 1, 1);
    const gem21 = getGemData(this.gems, 2, 1);
    const gem22 = getGemData(this.gems, 2, 2);
    const jelly01 = getGemData(this.mechanic, 0, 1);
    const jelly11 = getGemData(this.mechanic, 1, 1);
    const jelly21 = getGemData(this.mechanic, 2, 1);
    const extra00 = getGemData(this.extras, 0, 0);
    const extra10 = getGemData(this.extras, 1, 0);
    const extra20 = getGemData(this.extras, 2, 0);
    const fxGemTargets = [gem21, gem11, gem01]; // Target gems for the sfx
    const fadeGemTargets = [gem22, gem11, gem01]; // Target gems for fading;
    const fadeJellyTargets = [jelly21, jelly11, jelly01];

    this.tutHand.pivot.copyFrom(this.gems.pivot); // Copies and sets hand pivot to the grid

    const tweenGo = async () => {
      // Reset EVERYTHING
      this.tutHand.scale.set(1); // Hand is normal sized, in position, hidden
      this.tutHand.position.set(this.gems.x + gem22.pos.x, this.gems.y + gem22.pos.y);
      this.tutHand.alpha = 0;
      gem00.sprite.position.copyFrom(gem00.pos);
      gem10.sprite.position.copyFrom(gem10.pos);
      gem20.sprite.position.copyFrom(gem20.pos);
      gem01.sprite.alpha = 1;
      gem11.sprite.alpha = 1;
      gem21.sprite.position.copyFrom(gem21.pos);
      gem22.sprite.position.copyFrom(gem22.pos);
      extra00.sprite.position.copyFrom(extra00.pos);
      extra00.sprite.alpha = 0;
      extra10.sprite.position.copyFrom(extra10.pos);
      extra10.sprite.alpha = 0;
      extra20.sprite.position.copyFrom(extra20.pos);
      extra20.sprite.alpha = 0;
      gem22.sprite.alpha = 1;
      jelly01.sprite.alpha = 1;
      jelly11.sprite.alpha = 1;
      jelly21.sprite.alpha = 1;

      await this.wrapTween(this.pageOne, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page one fades in
      await this.wrapTween(this.tutHand, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand fades in
      await this.wait(tweenDelay / 2); // Wait
      await this.wrapTween(this.tutHand.scale, { x: 0.9, y: 0.9 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand clicks
      this.wrapTween(gem21.sprite, gem22.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The above moves down
      this.wrapTween(gem22.sprite, gem21.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The below moves up
      await this.wrapTween(this.tutHand, { x: this.gems.x + gem21.pos.x, y: this.gems.y + gem21.pos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand moves
      this.wrapTween(this.tutHand, { alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand fades out quick
      this.wrapTween(this.tutHand.scale, { scale: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand unclicks (but fades out before you can see)
      for (let i = 0; i < fxGemTargets.length; i++) {
        _.delay(() => {
          this.gemEmitter[i].burstCandy(this.gems.x + fxGemTargets[i].pos.x - tileSize * 1.5, this.gems.y + fxGemTargets[i].pos.y - tileSize, 4); // sfx happens
          this.wrapTween(fadeGemTargets[i].sprite, { alpha: 0 }, tweenTime / 4, Phaser.Easing.Sinusoidal.InOut); // Disappearing tween also happens if it should disappear
          this.wrapTween(fadeJellyTargets[i].sprite, { alpha: 0 }, tweenTime / 4, Phaser.Easing.Sinusoidal.InOut);
        }, i * (tweenTime / 4));
      }
      await this.wait(tweenTime);
      this.wrapTween(gem00.sprite, gem01.pos, tweenTime, Phaser.Easing.Back.Out);
      this.wrapTween(gem10.sprite, gem11.pos, tweenTime, Phaser.Easing.Back.Out);
      this.wrapTween(gem20.sprite, gem21.pos, tweenTime, Phaser.Easing.Back.Out);
      this.wrapTween(extra00.sprite, gem01.pos, tweenTime, Phaser.Easing.Back.Out);
      this.wrapTween(extra10.sprite, gem11.pos, tweenTime, Phaser.Easing.Back.Out);
      this.wrapTween(extra20.sprite, gem21.pos, tweenTime, Phaser.Easing.Back.Out);
      this.wrapTween(extra00.sprite, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(extra10.sprite, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(extra20.sprite, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wait(tweenDelay); // Wait
      await this.wrapTween(this.pageOne, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page fades out

      if (this.shouldEventComplete() && this.currentPage === 1) { // Repeats
        tweenGo();
      }
    };
    tweenGo(); // Triggers tweens
  }

  /**
   * Shows page two
   */
  showPageTwo() {
    this.currentPage = 2;

    const gridX = 4;
    const gridY = 3;

    this.description.setText(OMT.language.getText('Be careful! If you don’t remove any %infections%, they will spread!'));

    this.gridBg.init(gridX, gridY, tileSize, 'tut_tile', gridScale);

    // init grids with candies
    const mechanicData = [0, 0, 0, 0,
                          1, 1, 1, 0, // eslint-disable-line indent
                          0, 0, 1, 0].map((num) => (num === 0 ? null : `${mechanicSprite}${num}`)); // eslint-disable-line indent
    this.mechanic.init(gridX, gridY, tileSize, mechanicData, gridScale); // Passes in an array of null/string to denote which parts have nothing and which have the icon

    const gemsData = [5, 5, 1, 5,
                      4, 4, 1, 3, // eslint-disable-line indent
                      5, 3, 3, 5].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    const extraData = [3, 4, 5, 0,
                       0, 0, 0, 0, // eslint-disable-line indent
                       0, 0, 0, 0].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.extras.init(gridX, gridY, tileSize, extraData, gridScale);

    this.positionGrids(0, this.description.y + (this.description.height + this.gridBg.height) / 2);

    this.tutHand.alpha = 0; // Hide these
    this.pageTwo.alpha = 0;

    this.addChild(this.description);
    this.pageTwo.addChild(this.gridBg);
    this.pageTwo.addChild(this.mechanic);
    this.pageTwo.addChild(this.gems);
    this.pageTwo.addChild(this.extras);
    for (let i = 0; i < this.gemEmitter.length; i++) {
      this.pageTwo.addChild(this.gemEmitter[i]);
    }
    this.pageTwo.addChild(this.tutHand);
    this.addChild(this.pageTwo);
    this.addChild(this.continueButton);

    this.animatePageTwo();
  }

  /**
   * Animates page two
   */
  animatePageTwo() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 2; // Modifies shouldEventComplete by checking the page

    // Handy function because I'll need a lot of gems
    const getGemData = (board, cellX, cellY) => ({
      sprite: board.getSpriteByCell(cellX, cellY),
      pos: board.getPxPos(cellX, cellY),
    });

    const gem00 = getGemData(this.gems, 0, 0);
    const gem10 = getGemData(this.gems, 1, 0);
    const gem20 = getGemData(this.gems, 2, 0);
    const gem30 = getGemData(this.gems, 3, 0);
    const gem01 = getGemData(this.gems, 0, 1);
    const gem11 = getGemData(this.gems, 1, 1);
    const gem21 = getGemData(this.gems, 2, 1);
    const extra00 = getGemData(this.extras, 0, 0);
    const extra10 = getGemData(this.extras, 1, 0);
    const extra20 = getGemData(this.extras, 2, 0);
    const jelly22 = getGemData(this.mechanic, 2, 2);
    const fxGemTargets = [gem20, gem10, gem00]; // Target gems for the sfx
    const fadeOutGems = [gem30, gem10, gem00];

    this.tutHand.pivot.copyFrom(this.gems.pivot); // Copies and sets hand pivot to the grid

    const tweenGo = async () => {
      // Reset EVERYTHING
      this.tutHand.scale.set(1); // Hand is normal sized, in position, hidden
      this.tutHand.position.set(this.gems.x + gem30.pos.x, this.gems.y + gem30.pos.y);
      this.tutHand.alpha = 0;
      gem00.sprite.alpha = 1;
      gem10.sprite.alpha = 1;
      gem20.sprite.position.copyFrom(gem20.pos);
      gem30.sprite.position.copyFrom(gem30.pos);
      extra00.sprite.position.copyFrom(extra00.pos);
      extra00.sprite.alpha = 0;
      extra10.sprite.position.copyFrom(extra10.pos);
      extra10.sprite.alpha = 0;
      extra20.sprite.position.copyFrom(extra20.pos);
      extra20.sprite.alpha = 0;
      gem30.sprite.alpha = 1;
      jelly22.sprite.scale.set(0.1);

      await this.wrapTween(this.pageTwo, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page two fades in
      await this.wrapTween(this.tutHand, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand fades in
      await this.wait(tweenDelay / 2); // Wait
      await this.wrapTween(this.tutHand.scale, { x: 0.9, y: 0.9 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand clicks
      this.wrapTween(gem30.sprite, gem20.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The above moves left
      this.wrapTween(gem20.sprite, gem30.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The below moves right
      await this.wrapTween(this.tutHand, { x: this.gems.x + gem20.pos.x, y: this.gems.y + gem20.pos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand moves
      this.wrapTween(this.tutHand, { alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand fades out quick
      this.wrapTween(this.tutHand.scale, { scale: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand unclicks (but fades out before you can see)
      for (let i = 0; i < fxGemTargets.length; i++) {
        _.delay(() => {
          this.gemEmitter[i].burstCandy(this.gems.x + fxGemTargets[i].pos.x - tileSize * 1.5, this.gems.y + fxGemTargets[i].pos.y - tileSize, 5); // sfx happens
          this.wrapTween(fadeOutGems[i].sprite, { alpha: 0 }, tweenTime / 4, Phaser.Easing.Sinusoidal.InOut); // Disappearing tween also happens if it should disappear
        }, i * (tweenTime / 4));
      }
      await this.wait(tweenTime);
      this.wrapTween(extra00.sprite, gem01.pos, tweenTime, Phaser.Easing.Back.Out);
      this.wrapTween(extra10.sprite, gem11.pos, tweenTime, Phaser.Easing.Back.Out);
      this.wrapTween(extra20.sprite, gem21.pos, tweenTime, Phaser.Easing.Back.Out);
      this.wrapTween(extra00.sprite, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(extra10.sprite, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(extra20.sprite, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wait(tweenDelay / 2);
      await this.wrapTween(jelly22.sprite.scale, { x: gridScale, y: gridScale }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wait(tweenDelay); // Wait
      await this.wrapTween(this.pageTwo, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page fades out

      if (this.shouldEventComplete() && this.currentPage === 2) { // Repeats
        tweenGo();
      }
    };
    tweenGo(); // Triggers tweens
  }
}

// create global references
G.WaffleBreakTutorial = WaffleBreakTutorial;
