if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getSwapTwoElementsAnimation = function getSwapTwoElementsAnimation() {
  return { animate: animate };

  function animate(game, target1, target2, duration) {
    // store pos
    var target1Pos = {
      x: target1.x,
      y: target1.y,
    };
    var target2Pos = {
      x: target2.x,
      y: target2.y,
    };
  
    game.add
      .tween(target2)
      .to(target1Pos, duration || 500, Phaser.Easing.Sinusoidal.Out, true);
  
    return game.add
      .tween(target1)
      .to(target2Pos, duration || 500, Phaser.Easing.Sinusoidal.Out, true);
  };
}