/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

/**
 * Class for fixing errored save states
 */
export class SaveStateFixer {
  /**
     * constructor
     */
  constructor() {
    this._starsPerLevel = 3;
    this._lives = 5;
  }

  /**
   * fix a save data Object
   * @param {Object} saveData save data Object
   * @param {number} levelsCompleted # of levels completed
   * @param {*} reason reason??
   */
  fixSaveData(saveData, levelsCompleted, reason, overrideLevels = false, starsPerLevel = this._starsPerLevel) {
    const grantedStars = levelsCompleted * starsPerLevel;
    G.saveState.setLives(this._lives);
    this.unlockGates(saveData, levelsCompleted, overrideLevels);
    this._completeTutorials(saveData, levelsCompleted, overrideLevels);
    this._unlockLevels(saveData, levelsCompleted, starsPerLevel, overrideLevels);
  }

  /**
   * unlock gates up to the specified level
   * Also generates map interactions for the final gate
   * @param {Object} saveData save data Object
   * @param {number} levelsCompleted number of levels completed
   */
  unlockGates(saveData, levelsCompleted, overrideGates = false) {
    // apply to open gates
    G.saveState.gateManager.fixGatesUpToLevel(levelsCompleted - G.saveState.gateManager.levelGap);
    const nextGate = G.saveState.gateManager.getNextGateLevel();
    G.saveState.gateManager.openGate(nextGate);
  }

  /**
   * check if chests are below the specified level index
   * @param {Object} mapChestSettings chest settings Object
   * @param {number} levelIndex index # of highest level reached
   */
  _isChestBelowLevel(mapChestSettings, levelIndex) {
    const levelPosition = G.Helpers.levelDataMgr.getMapPosition(levelIndex);
    return -mapChestSettings.mapY < -levelPosition.y;
  }

  /**
   * mark tutorials as completed
   * @param {Object} saveData save data Object
   * @param {number} levelsCompleted # of levels completed
   */
  _completeTutorials(saveData, levelsCompleted, overrideTutorials = false) {
    if (overrideTutorials) saveData.finishedTutorials = [];
    for (let i = 0; i < levelsCompleted; i++) {
      const levelData = G.Helpers.levelDataMgr.getLevelByIndex(i);
      if (levelData && levelData.tutID) {
        G.StorageUtils.addUnique(saveData.finishedTutorials, levelData.tutID);
      }
    }
  }

  /**
   * unlock levels up to levelCompleted #
   * @param {Object} saveData save data Object
   * @param {number} levelsCompleted # of levels completed
   * @param {number} starsPerLevel stars to unlock
   */
  _unlockLevels(saveData, levelsCompleted, starsPerLevel, overrideCurrent = false) {
    if (overrideCurrent) saveData.levels = [];
    for (let i = 0; i < levelsCompleted; ++i) {
      if (overrideCurrent) saveData.levels.push(starsPerLevel);
      else {
        saveData.levels[i] = starsPerLevel;
      }
    }
  }
}
