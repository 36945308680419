import TutorialGrid from '../BoardAnim/G.TutorialGrid';
import TutorialGroup from '../BoardAnim/TutorialGroup';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';

const tileSize = 90;
const gridScale = 1.2;

/**
 * @author Sandra Koo
 */
class RockLayersTutorial extends TutorialGroup {
  constructor() {
    super(game, null);

    this.currentPage = 0;
    this.pageOne = new Phaser.Group(game, null);
    this.description = undefined; // Text
    this.rockImage = undefined; // The rock imagery

    this.pageTwo = new Phaser.Group(game, null);
    this.gridBg = undefined; // Grid background
    this.rock = undefined; // rock fronts
    this.gems = undefined; // gems
    this.extras = undefined;
    this.tutHand = undefined; // Haaaaaand
    this.gemEmitter = []; // sfx
    this.rockEmitter = []; // sfx
    this.continueButton = undefined; // Button

    this.drawEverything();
    this.showPageOne();
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this.gridBg) {
      this.gridBg.destroy();
    }

    if (this.rock) {
      this.rock.destroy();
    }

    if (this.gems) {
      this.gems.destroy();
    }

    if (this.extras) {
      this.extras.destroy();
    }

    this.pageOne.destroy();
    this.pageTwo.destroy();

    super.destroy();
  }

  /**
   * Draws all elements
   * Initializes some of them
   */
  drawEverything() {
    const gridX = 4;
    const gridY = 3;
    const maxDimension = 70 * 1.2;

    const makeGemRocked = (rockLayer) => { // Makes crushed ice ;)
      const pack = new Phaser.Group(game, null);
      G.makeImage(0, 0, 'candy_5', 0.5, pack);
      G.makeImage(0, 0, `concrete_${rockLayer}`, 0.5, pack);
      pack.width = maxDimension;
      pack.height = maxDimension;
      return pack;
    };

    const makeArrowImage = () => { // Makes arrow and makes it big
      const pack = new Phaser.Group(game, null);
      G.makeImage(0, 0, 'arrow_right', 0.5, pack);
      pack.width = maxDimension / 1.5;
      pack.height = maxDimension / 1.5;
      return pack;
    };

    this.rockImage = new Phaser.Group(game, null);
    const iceImages = [makeGemRocked('3'), makeArrowImage(), makeGemRocked('2'), makeArrowImage(), makeGemRocked('1')]; // Makes all the ice images
    const distance = 10;
    const totalWidth = (maxDimension * iceImages.length) + (distance * (iceImages.length - 1)); // Calculates total width
    let nextX = (maxDimension - totalWidth) / 2; // Sets it back
    for (let i = 0; i < iceImages.length; i++) { // Centers accordingly
      const img = iceImages[i];
      img.x = nextX;
      this.rockImage.addChild(img);
      nextX += maxDimension + distance;
    }

    this.gridBg = new TutorialGrid(); // Grid bg. Has the background sprite
    this.gridBg.init(gridX, gridY, tileSize, 'tut_tile', gridScale);

    this.rock = new TutorialGrid(); // For the Ice

    this.gems = new TutorialGrid(); // For the gems

    this.extras = new TutorialGrid();

    for (let i = 0; i < 4; i++) { // Particle emitters
      const emitter = new FxParticle(); // For the sfx
      emitter.scale.setTo(gridScale); // It also grows big
      this.gemEmitter.push(emitter);
    }

    for (let i = 0; i < 3; i++) {
      const emitter = new FxParticle(); // For the sfx
      emitter.scale.setTo(gridScale);
      this.rockEmitter.push(emitter);
    }

    this.tutHand = new Phaser.Group(game, null);
    G.makeImage(0, 0, 'tut_hand', 0, this.tutHand); // Haaaand

    this.description = new G.Text(0, -150, ' ', { // The instructions
      style: 'font-blue',
      fontSize: 35,
      lineSpacing: -15,
    }, 0.5, 400, 200, true, 'center');

    this.continueButton = new OMT_UI_SquareButton(0, 260, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onContinueClick.bind(this),
        },
      },
    });
    this.addChild(this.continueButton);
  }

  /**
   * When continue is clicked
   */
  async onContinueClick() {
    this.stopAllActions(); // Stop all tweens
    if (this.currentPage === 1) { // If page one, go to page 2
      this.continueButton.active = false;
      await this.wrapTween(this.pageOne, { alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut);
      this.removeChild(this.pageOne);
      this.showPageTwo();
      this.continueButton.active = true;
    } else { // Otherwise close
      this.signals.onContinueClick.dispatch();
      this.destroy();
    }
  }

  showPageOne() {
    this.currentPage = 1;
    this.description.setText(OMT.language.getText('Some %gems% are trapped under multiple layers of %rock%.'));

    this.rockImage.y = this.description.y + (this.description.height / 2) + (this.rockImage.height * 1.5); // Puts it down in the middle

    this.pageOne.addChild(this.description);
    this.pageOne.addChild(this.rockImage);

    this.addChild(this.pageOne);
    this.addChild(this.continueButton);
  }

  showPageTwo() {
    this.currentPage = 2;
    const gridX = 4;
    const gridY = 3;

    this.description.setText(OMT.language.getText('Break through each layer of %rock% to crush the trapped %gems%!'));

    // init grids with candies
    const mechanicData = [2, 2, 1, 0,
                          3, 2, 1, 0, // eslint-disable-line indent
                          1, 1, 0, 0]; // eslint-disable-line indent
    this.rock.init(gridX, gridY, tileSize, mechanicData.map((num) => (num === 0 ? null : `concrete_${num}`)), gridScale); // Passes in an array of null/string to denote which

    const gemsData = [4, 4, 1, 2,
                      1, 1, 1, 3, // eslint-disable-line indent
                      5, 5, 4, 1].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    const extraData = [0, 0, 0, 2,
                       0, 0, 0, 0, // eslint-disable-line indent
                       0, 0, 0, 0].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.extras.init(gridX, gridY, tileSize, extraData, gridScale);

    this.gridBg.y = this.description.y + (this.description.height + this.gridBg.height) / 2;
    this.rock.y = this.gridBg.y;
    this.gems.y = this.gridBg.y;
    this.extras.y = this.gridBg.y - tileSize;

    this.pageTwo.alpha = 0;
    this.tutHand.alpha = 0;

    this.addChild(this.description);
    this.pageTwo.addChild(this.gridBg);
    this.pageTwo.addChild(this.gems);
    this.pageTwo.addChild(this.extras);
    this.pageTwo.addChild(this.rock);
    for (let i = 0; i < this.gemEmitter.length; i++) {
      this.pageTwo.addChild(this.gemEmitter[i]);
    }
    for (let i = 0; i < this.rockEmitter.length; i++) {
      this.pageTwo.addChild(this.rockEmitter[i]);
    }
    this.pageTwo.addChild(this.tutHand);
    this.addChild(this.pageTwo);
    this.addChild(this.continueButton);

    this.animatePageTwo();
  }

  animatePageTwo() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 2; // Modifies shouldEventComplete by checking the page

    // Handy function because I'll need a lot of gems
    const getGemData = (board, cellX, cellY) => ({
      sprite: board.getSpriteByCell(cellX, cellY),
      pos: board.getPxPos(cellX, cellY),
    });

    const gem32 = getGemData(this.gems, 3, 2);
    const gem31 = getGemData(this.gems, 3, 1);
    const rock01 = getGemData(this.rock, 0, 1);
    const rock11 = getGemData(this.rock, 1, 1);
    const rock21 = getGemData(this.rock, 2, 1);
    const gem30 = getGemData(this.gems, 3, 0);
    const extra30 = getGemData(this.extras, 3, 0);
    const fxGemTargets = [gem31, rock21, rock11, rock01]; // Target gems for the sfx

    this.tutHand.pivot.copyFrom(this.gems.pivot); // Copies and sets hand pivot to the grid

    const tweenGo = async () => {
      // Reset EVERYTHING
      this.tutHand.scale.set(1); // Hand is normal sized, in position, hidden
      this.tutHand.position.set(this.gems.x + gem32.pos.x, this.gems.y + gem32.pos.y);
      this.tutHand.alpha = 0;
      gem32.sprite.position.copyFrom(gem32.pos);
      gem32.sprite.alpha = 1;
      gem31.sprite.position.copyFrom(gem31.pos);
      gem30.sprite.position.copyFrom(gem30.pos);
      extra30.sprite.position.copyFrom(extra30.pos);
      extra30.sprite.alpha = 0;
      rock01.sprite.changeTexture('concrete_3');
      rock11.sprite.changeTexture('concrete_2');
      rock21.sprite.alpha = 1;

      await this.wrapTween(this.pageTwo, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page two fades in
      await this.wrapTween(this.tutHand, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand fades in
      await this.wait(tweenDelay / 2); // Wait
      await this.wrapTween(this.tutHand.scale, { x: 0.9, y: 0.9 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand clicks
      this.wrapTween(gem31.sprite, gem32.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The above moves down
      this.wrapTween(gem32.sprite, gem31.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The below moves up
      await this.wrapTween(this.tutHand, { x: this.gems.x + gem31.pos.x, y: this.gems.y + gem31.pos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand moves
      this.wrapTween(this.tutHand, { alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand fades out quick
      this.wrapTween(this.tutHand.scale, { scale: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand unclicks (but fades out before you can see)
      for (let i = 0; i < fxGemTargets.length; i++) {
        _.delay(() => {
          this.gemEmitter[i].burstCandy(this.gems.x + fxGemTargets[i].pos.x - tileSize * 1.5, this.gems.y + fxGemTargets[i].pos.y - tileSize, 3); // gem sfx happens
          if (i !== 0) {
            this.rockEmitter[i - 1].burstConcreteAnim(this.gems.x + fxGemTargets[i].pos.x - tileSize * 1.5, this.gems.y + fxGemTargets[i].pos.y - tileSize, 3); // rock sfx happens
          }
        }, i * (tweenTime / 4));
      }
      await this.wait(tweenTime / 4); // After waiting for the first sfx happening
      await this.wrapTween(gem32.sprite, { alpha: 0 }, tweenTime / 4, Phaser.Easing.Sinusoidal.InOut); // gem fades out
      await this.wrapTween(rock21.sprite, { alpha: 0 }, tweenTime / 4, Phaser.Easing.Sinusoidal.InOut); // Rock fades out
      rock11.sprite.changeTexture('concrete_1'); // texture changes
      await this.wait(tweenTime / 4); // Its been a whole tweenTime here
      rock01.sprite.changeTexture('concrete_2'); // texture changes
      await this.wait(tweenTime / 2);
      this.wrapTween(gem30.sprite, gem31.pos, tweenTime, Phaser.Easing.Back.Out);
      this.wrapTween(extra30.sprite, gem31.pos, tweenTime, Phaser.Easing.Back.Out);
      await this.wrapTween(extra30.sprite, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wait(tweenDelay);
      await this.wrapTween(this.pageTwo, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Fades out

      if (this.shouldEventComplete() && this.currentPage === 2) { // Repeats
        tweenGo();
      }
    };
    tweenGo();
  }
}
// create global references
G.RockLayersTutorial = RockLayersTutorial;
