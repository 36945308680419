export const TILE_SIZE = 72;

export const TOKEN_TYPES = {
  BURNT: 'burntCandy',
  CONCRETE: 'concrete',
  CANDY_BOX: 'bx',
  CHAIN: 'chain',
  CHEST: 'chest',
  CHEST_TH: 'chest_th',
  DIRT: 'dirt',
  DIRT_S: 'dirtS',
  EVENT_TOKEN: 'eventToken',
  FORTUNE: 'fortune',
  GOAL_CANDY: 'goalCandy',
  ICE: 'ice',
  INFECTION: 'infection',
  JAM: 'jam',
  LAYER_CAKE: 'lc',
  TEMP_HIT: 'h',
  TEMP_MATCH: 'm',
  TEMP_MATCH_SPECIAL: 'mSpecial',
};

export const EDITOR_SYMBOLS = {
  BLANK: 'X',
  CHAIN: 'W',
  CANDY_BOX: 'bx',
  CONCRETE: 'cn',
  DIRT: 'dirt',
  DIRT_S: 'dS',
  FORTUNE: 'fortune',
  ICE: 'ice',
  JAM: 'jam',
  LAYER_CAKE: 'lc',
  RANDOM: 'r',
  WALL: 'J',
};

export const SPECIAL_TYPES = {
  CROSS: 'cross',
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  SPIRAL: 'spiral',
  RANDOM_LINE: 'randomLineBooster',
};

export const WALL_DIRECTIONS = {
  NORTH: 0,
  EAST: 1,
  SOUTH: 2,
  WEST: 3,
};
