export default class LevelBg extends Phaser.Image {
  /**
   * The background on the game state
   * @param {string} [lvl_gfx] defaults to 'background_1
   */
  constructor(lvl_gfx) {
    super(game);
    this.anchor.set(0.5);

    G.changeTexture(this, lvl_gfx || 'background_1');

    this._resizeBinding = G.sb('onScreenResize').add(this.onScreenResize, this);
    this.onScreenResize();

    game.add.existing(this);
  }

  /**
   * Clear up listeners and things
   */
  destroy() {
    if (this._resizeBinding) {
      this._resizeBinding.detach();
      this._resizeBinding = null;
    }
    super.destroy();
  }

  /**
   * Things happen when screen resizes
   */
  onScreenResize() {
    this.x = game.world.bounds.x + game.width * 0.5;
    this.y = game.world.bounds.y + game.height * 0.5;

    this.scale.setTo(1);

    // this.height = game.height;
    // this.scale.x = this.scale.y;
    this.width = Math.max(this.width, game.width);
    this.height = Math.max(this.height, game.height);
    this.width += 10;
    this.height += 10;


    this.updateCache();
  }
}
