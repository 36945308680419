G.WorldSidePanelWidgetButton = function(x, y) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);

  var bg = G.makeImage(0, 0, "missions_map_box", 0, this);

  createClickSignal(bg).add(
    function() {
      if (this.widgets.length > 0) {
        this.onClicked.dispatch();
      }
    },
    this
  );
  bg.input.useHandCursor = true;
  bg.scale.setTo(1.05);

  this.widgets = [];
  this.currentWidget = null;
  this.widgetGroup = this.add(game.add.group());
  this.widgetGroup.position.setTo(82, 60);

  this.widgetPlaceholder = new G.WorldSidePanelLockedWidget();

  this.onClicked = new Phaser.Signal();

  this.timer = this.add(new G.WorldSidePanelWidgetButtonTimer(-18, 0));

  this.showNextWidget();
  game.time.events.loop(5000, this.showNextWidget, this);

  this.notification = new G.WorldSidePanelWidgetButtonNotification(145, 5);
  this.add(this.notification);

  G.sb("addSidePanelButtonWidget").add(this.addWidget, this);
  G.sb("removeSidePanelButtonWidget").add(this.removeWidget, this);

  function createClickSignal(target) {
    var clickHandler = G.Input.createCustomInputClickHandler();

    G.Input.initializeCustomInput(target);
    target.customInput.addHandler(clickHandler);

    return clickHandler.clickSignal;
  }

  this.onDestroy.add(function() {
    if (this.widgetPlaceholder) {
      this.widgetPlaceholder.destroy();
    }
    this.widgets.forEach(function(widget) {
      widget.destroy();
    });
  }, this);
};

G.WorldSidePanelWidgetButton.prototype = Object.create(Phaser.Group.prototype);

G.WorldSidePanelWidgetButton.prototype.addWidget = function(widget) {
  this.widgets.push(widget);
  widget.visible = false;

  if (this.widgets.length == 1) {
    this.showNextWidget();
  }
};

G.WorldSidePanelWidgetButton.prototype.removeWidget = function(widget) {
  var index = this.widgets.indexOf(widget);
  if (index !== -1) {
    this.widgets.splice(index, 1);
  }
};

G.WorldSidePanelWidgetButton.prototype.showNextWidget = function() {
  var nextWidget = this.getNextWidget();

  if (this.currentWidget !== nextWidget) {

    if (this.currentWidget) {
      this.hideWidget(this.currentWidget);
    }

    this.showWidget(nextWidget);

    this.currentWidget = nextWidget;
  }

};

G.WorldSidePanelWidgetButton.prototype.getNextWidget = function() {

  if (this.widgets.length == 0) {
    return this.widgetPlaceholder;
  }
  var index = (this.widgets.indexOf(this.currentWidget) + 1) % this.widgets.length;
  return this.widgets[index];

};


G.WorldSidePanelWidgetButton.prototype.hideWidget = function(widget) {

  game.add.tween(widget)
    .to({alpha: 0}, 400, Phaser.Easing.Sinusoidal.Out, true)
    .onComplete.add(function() {
      if (widget.parent === this.widgetGroup) {
        this.widgetGroup.removeChild(widget);
        widget.visible = false;
      }
    }, this);

};


G.WorldSidePanelWidgetButton.prototype.showWidget = function(widget) {
  this.widgetGroup.add(widget);
  widget.visible = true;
  widget.alpha = 0;
  game.add.tween(widget).to({alpha: 1}, 400, Phaser.Easing.Sinusoidal.Out, true);

  if (widget.getBannerConfig) {
    this.timer.showBanner(widget.getBannerConfig());
  } else {
    this.timer.hideBanner();
  }

  // if (widget.TIMELIMITED) {
  //   this.timer.showTimer(widget.getRemainingSeconds());
  // } else {
  //   this.timer.hideTimer()
  // }

};