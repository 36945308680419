const MAX_ENTRY_COUNT = 25;

/**
 *  class for handling leaderboards on social platforms
 */
export class OMT_PlatformLeaderboards {
  /**
   * Post a score to a FB leaderboard
   * @param {string} leaderboardId
   * @param {number} score
   * @param {Object} extraData (optional)
   * @returns {Promise<Number>} your high score
   */
  async postLeaderboardScore(leaderboardId, score, extraData = '') {
    let leaderboard;
    // get the leaderboard reference from FB
    try {
      leaderboard = await FBInstant.getLeaderboardAsync(leaderboardId);
    } catch (error) {
      console.log(error);
      return 0;
    }
    // attempt to post to the leaderboard
    let entryData;
    try {
      entryData = await leaderboard.setScoreAsync(score, extraData);
    } catch (error) {
      console.log(error);
      return 0;
    }
    return entryData.getScore();
  }

  /**
   * get a list of FB leaderboard entries
   * @param {string} leaderboardId leaderboard id
   * @param {number} limit record limit
   * @returns {Promise<Array>}
   */
  async getLeaderboardEntries(leaderboardId, limit = MAX_ENTRY_COUNT) {
    let leaderboard;
    const entryList = [];
    // get the leaderboard reference from FB
    try {
      leaderboard = await FBInstant.getLeaderboardAsync(leaderboardId);
    } catch (error) {
      console.log(error);
      return entryList;
    }
    // get the entry list
    try {
      // translate fb leaderboard entries to OMT format
      const fbEntryList = await leaderboard.getEntriesAsync(limit);
      let fbEntryData; let entryData;
      for (fbEntryData of fbEntryList) {
        entryData = this._createLeaderboardUserData(fbEntryData);
        entryList.push(entryData);
      }
      // append the current user data if available and not in the list
      const currentUsersFound = entryList.filter((data) => data.isCurrentUser).length > 0;
      if (!currentUsersFound) {
        fbEntryData = await this.getLeaderboardEntryData(leaderboardId);
        if (fbEntryData) {
          // entryList.pop();
          entryData = this._createLeaderboardUserData(fbEntryData);
          entryData.rank = '';
          entryList.push(entryData);
        }
      }
    } catch (error) {
      console.log(error);
      return entryList;
    }
    return entryList;
  }

  /**
   * Get the current users FB leaderboard entryData
   * @param {string} leaderboardId
   * @returns {Promise<Object>} your high score
   */
  async getLeaderboardEntryData(leaderboardId) {
    let leaderboard;
    // get the leaderboard reference from FB
    try {
      leaderboard = await FBInstant.getLeaderboardAsync(leaderboardId);
    } catch (error) {
      console.log(error);
      return null;
    }
    // attempt to post to the leaderboard
    let entryData;
    try {
      entryData = await leaderboard.getPlayerEntryAsync();
    } catch (error) {
      console.log(error);
      return null;
    }
    return entryData;
  }

  /**
   * get a count of entries in a leaderboard
   * @param {string} leaderboardId leaderboard id
   * @returns {Promise<Number>}
   */
  async getLeaderboardEntryCount(leaderboardId) {
    let leaderboard;
    let entryCount = 0;
    // get the leaderboard reference from FB
    try {
      leaderboard = await FBInstant.getLeaderboardAsync(leaderboardId);
    } catch (error) {
      console.log(error);
      return entryCount;
    }

    // attempt to get the entryCount
    try {
      entryCount = await leaderboard.getEntryCountAsync();
    } catch (error) {
      console.log(error);
    }
    return entryCount;
  }

  /**
   * Get a your higest score from the leaderboard
   * @param {string} leaderboardId
   * @returns {Promise<Number>} your high score
   */
  async getLeaderboardHighScore(leaderboardId) {
    const entryData = await this.getLeaderboardEntryData(leaderboardId);
    if (!entryData) return 0;
    return entryData.getScore();
  }

  /**
   * create a leaderboard entry object in the format we use for OMT
   * @param {Object} fbEntryData data from FB leaderboard
   * @returns {Object}
   */
  _createLeaderboardUserData(fbEntryData) {
    const fbPlayer = fbEntryData.getPlayer();
    const leaderboardEntryData = {};
    leaderboardEntryData.id = leaderboardEntryData.userId = fbPlayer.getID();
    leaderboardEntryData.rank = fbEntryData.getRank();
    leaderboardEntryData.score = fbEntryData.getScore();
    leaderboardEntryData.extraData = fbEntryData.getExtraData();
    const isCurrentUser = leaderboardEntryData.id === OMT.envData.settings.user.userId;
    leaderboardEntryData.isCurrentUser = isCurrentUser;
    if (!isCurrentUser) {
      leaderboardEntryData.name = fbPlayer.getName();
      leaderboardEntryData.image = fbPlayer.getPhoto();
    } else { // prefer envData due to fallbacks
      leaderboardEntryData.name = OMT.envData.settings.user.name;
      leaderboardEntryData.image = OMT.envData.settings.user.avatar;
    }
    return leaderboardEntryData;
  }
}
