/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */

export class OMT_UI_AvatarWithFrame extends Phaser.Group {
  /**
   * constructor
   * @param {string} imageURL
   * @param {number} imageSize
   * @param {Array.<string>} defaultFrames
   */
  constructor(imageURL, imageSize, defaultFrames = ['avatar_m']) {
    super(game);

    // this.signals = {
    //   onImageLoaded: new Phaser.Signal(),
    // };

    // this._imageURL = imageURL;
    // this._imageSize = imageSize;
    // this._defaultFrames = defaultFrames;
    // this._frameSize = Math.round(this._imageSize * 1.1);
    // this._imageLoaded = false;

    // this._initAvatarElements();
  }


  /**
   * init avatar display elements
   */
  _initAvatarElements() {
    this._initAvatarImage(this._imageURL, this._defaultFrames);
    this._initAvatarFrame();
  }

  /**
   * init the user avatar image
   * @param {string} imageURL
   * @param {Array.<string>} defaultFrames
   */
  _initAvatarImage(imageURL, defaultFrames) {
    const defaultFrame = ArrayUtil.getRandomElement(defaultFrames);
    if (imageURL != null) {
      this._avatarImage = G.makeExtImage(0, 0, this._imageURL, defaultFrame, 0.5, this, false, (image) => {
        this._onAvatarImageLoaded(image);
      });
    } else {
      this._avatarImage = G.makeImage(0, 0, defaultFrame, 0.5);
    }
    this._avatarImage.width = this._avatarImage.height = this._imageSize;
    this.addChild(this._avatarImage);
  }

  /**
   * called when the intial avatar image has loded
   * @param {Phaser.Image} image
   */
  _onAvatarImageLoaded(image) {
    image.width = image.height = this._imageSize;
    this._avatarImage = image;
    this._imageLoaded = true;
    this.signals.onImageLoaded.dispatch();
  }

  /**
   * check if the image is loaded
   */
  get imageLoaded() {
    return this._imageLoaded;
  }

  /**
   * init the avatar frame
   */
  _initAvatarFrame() {
    const frameSize = this._frameSize;
    this._avatarFrame = G.makeImage(0, 0, 'avatar_frame_big', 0.5, null);
    this._avatarFrame.width = this._avatarFrame.height = frameSize;
    this.addChild(this._avatarFrame);
  }

  /**
   * show the frame
   */
  showFrame() {
    this._avatarFrame.visible = true;
  }

  /**
   * hide frame
   */
  hideFrame() {
    this._avatarFrame.visible = false;
  }
}
