/* eslint-disable no-unused-vars */
import { CandyType_Basic } from './CandyType_Basic';

const MAX_CAKE_HP = 4;

/**
 * class for Layered Cake CandyType
 */
export class CandyType_Cake extends CandyType_Basic {
  /**
   * constructor
   * @param {BoardGameHooks} gameHooks
   */
  constructor(gameHooks) {
    super(gameHooks);
  }

  /**
   * main initialization.
   * @param {Candy} candy
   * @param {Object} config
   * @param {number} hp
   */
  init(candy, config, hp) {
    this._config = config;
    this._candy = candy;
    this._matchable = G.Utils.defined(config.matchable, true);
    this._changeableToSpecialType = G.Utils.defined(config.canHaveSpecialType, true);
    this._tags = G.Utils.defined(config.tags, []);
    this._editorSymbol = G.Utils.defined(config.editorSymbol, '');

    this._maxHp = MAX_CAKE_HP;
    this._hp = parseInt(G.Utils.defined(hp, this.maxHp));
    this._spriteName = config.texture;

    this._fallingBlocked = true;

    this._updateTexture();
  }

  /**
   * on moved with another candy.
   * @param {Candy} otherCandy
   * @returns {boolean}
   */
  onMove(otherCandy) {
    this._decreaseHp();
    return true;
  }

  /**
   * on candy part of a match.
   * @returns {boolean}
   */
  onMatch() {
    return false;
  }

  /**
   * on booster used on candy.
   */
  onBooster() {
    // Boosters do not affect layer cakes
  }

  /**
   * decrease hp / layers.
   */
  _decreaseHp() {
    this._hp--;
    if (this._hp > 0) this._updateTexture();
    else this.signals.removeCandy.dispatch();
  }

  /**
   * update sprite texture to match layers / hp.
   */
  _updateTexture() {
    const textureName = this._spriteName.replace('HP', this._hp + 1);
    this.signals.changeCandyTexture.dispatch(textureName);
  }

  /**
   * export editor symbol for editor
   * @returns {string}
   */
  export() {
    return `${this._editorSymbol}|${this.hp}`;
  }

  /**
   * change layer state for editor.
   */
  changeForEditor() {
    if (this._hp === 1) this._hp = this._maxHp;
    else this._hp--;
    this._updateTexture();
  }

  /** GETTER METHODS ********************************* */

  /** @returns {number} get max hp / layers for cake */
  get maxHp() { return this._maxHp; }

  /** @returns {number} get current hp */
  get hp() { return this._hp; }
}
