/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */

import OMT_UI_ScrollBarBase from './OMT_UI_ScrollBarBase';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';

/**
 * scroll bar for the scroll window
 */
export default class OMT_UI_VerticalScrollBar extends OMT_UI_ScrollBarBase {
  /**
   * constructor
   * @param {Phaser.Rectangle} scrollRect
   * @param {OMT_UI_VerticalScrollBarConfig} scrollBarConfig
   */
  constructor(scrollRect, scrollBarConfig) {
    super(scrollRect, scrollBarConfig);

    this._autoHide = this._config.autoHide;

    // adjust the position, height, bounds of the scrollbar
    this.x = this._config.overlapScrollArea ? this._scrollRect.width - this._config.width : this._scrollRect.width;
    this.y = (((1 - this._config.heightRatio) * this._scrollRect.height) / 2) + this._config.offsetY;
    this._scrollRect.height *= this._config.heightRatio;
  }

  /**
   * on drag update
   * @param {Phaser.Sprite} sprite
   * @param {Object} pointer
   * @param {number} dragX
   * @param {number} dragY
   * @param {Object} snapPoint
   */
  _dragUpdate(sprite, pointer, dragX, dragY, snapPoint) {
    this._scrollKnob.x = 0;

    const knobHeight = this._knobHeight;
    const maxY = this._scrollRect.height - knobHeight;
    const minY = 0;

    if (this._scrollKnob.y > maxY) this._scrollKnob.y = maxY;
    else if (this._scrollKnob.y < minY) this._scrollKnob.y = minY;

    this.signals.onScrollChange.dispatch();
  }

  /**
   * update the scrollKnob graphics
   * @param {number} viewPercent 0-1
   */
  updateScrollKnobGraphics(viewPercent) {
    viewPercent = Math.min(Math.max(0, viewPercent), 1);
    this._viewPercent = viewPercent;

    const knobHeight = this._scrollRect.height * viewPercent;
    const { cornerSize } = this._config;

    this._scrollKnob.scale.x = 1;
    const minGraphicsWidth = (cornerSize * 2) + this._config.minMiddleWidth;
    const graphicsWidth = this._config.width > minGraphicsWidth ? this._config.width : minGraphicsWidth;

    if (this._scrollKnob.graphics) { // resize existing 9-slice
      this._scrollKnob.graphics.resize(graphicsWidth, knobHeight);
      if (this._scrollKnobBackground) {
        this._scrollKnobBackground.resize(graphicsWidth, this._scrollRect.height);
      }
    } else { // create new 9 slice graphics
      if (this._config.scrollBackground) {
        this._scrollKnobBackground = new UI_NineSlice(
          0, 0, this._config.scrollBackground, graphicsWidth, this._scrollRect.height,
          {
            left: cornerSize, right: cornerSize, top: cornerSize, bottom: cornerSize,
          },
        );
        this._scrollKnobBackground.width = this._config.width; // ??? Why is this width potentially different from what is set!?
        this.addChild(this._scrollKnobBackground);
      }

      this._scrollKnob.graphics = new UI_NineSlice(
        0, 0, this._config.knobAssetFrame, graphicsWidth, knobHeight,
        {
          left: cornerSize, right: cornerSize, top: cornerSize, bottom: cornerSize,
        },
      );
      this._scrollKnob.addChild(this._scrollKnob.graphics);
    }
    this._scrollKnob.graphics.tint = this._config.knobTint;
    this._scrollKnob.graphics.width = this._config.width;

    // update knob height for other functions
    this._knobHeight = knobHeight;
  }

  /**
   * set the scroll percent / position
   * @param {number} percent 0-1
   * @param {boolean} dispatchEvent
   */
  setScrollPercent(percent, dispatchEvent = true) {
    if (isNaN(percent)) percent = 0;
    percent = Math.min(Math.max(0, percent), 1);
    const knobHeight = this._knobHeight;
    const scrollHeight = this._scrollRect.height - knobHeight;
    this._scrollKnob.y = scrollHeight * percent;
    if (dispatchEvent) this.signals.onScrollChange.dispatch();
  }

  /**
   * get the scroll percent / position
   * @returns {number} 0-1
   */
  getScrollPercent() {
    const knobHeight = this._knobHeight;
    const scrollHeight = this._scrollRect.height - knobHeight;
    const knobY = this._scrollKnob.y;
    return knobY / scrollHeight;
  }

  /**
   * phaser update method
   */
  updateEnabledState() {
    const state = this._knobHeight + 0.01 < this._scrollRect.height;
    if (this._scrollKnob.inputEnabled !== state) {
      this._scrollKnob.inputEnabled = state;
      if (this._autoHide) this.visible = state;
    }
  }
}
