import ComponentGameHooks from '../../ComponentGameHooks';

/* eslint-disable func-names */
if (typeof G === 'undefined') G = {};

/**
 * Refactor of old G.Button ES5 code
 */
export class UI_GButtonES6 extends Phaser.Button {
  /**
   * constructor
   * @param {number} x
   * @param {number} y
   * @param {Phaser.DisplayObject} sprite
   * @param {Function} callback
   * @param {Object} context
   */
  constructor(x, y, sprite, callback, context) {
    super(game, Math.floor(x), Math.floor(y), null);

    this.state = game.state.getCurrentState();

    G.changeTexture(this, sprite);
    this.anchor.setTo(0.5);

    this.sfx = 'pop';

    this.active = true;

    this.onClick = new Phaser.Signal();
    if (callback) this.onClick.add(callback, context || this);

    this._addClickListener();

    this.terms = [];

    this.IMMEDIATE = false;

    this.scaleOnClick = true;

    this.refractorPeriod = 200;
    this.scaleChange = 0.1;

    this.pulsing = false;
  }

  /**
   * add click listener, this uses some over complicated code
   */
  _addClickListener() {
    const clickHandler = G.Input.createCustomInputClickHandler();
    clickHandler.clickSignal.add(this.click, this);
    clickHandler.downSignal.add(this.beforeDown, this);
    clickHandler.cancelSignal.add(this.beforeCancel, this);

    G.Input.initializeCustomInput(this);
    this.customInput.addHandler(clickHandler);
  }

  /**
   * create a click shape to define the clickable area
   * @param {number} width
   * @param {number} height
   * @param {Array} anchor
   * @param {number} alpha
   */
  createClickShape(width, height, anchor = [0.5, 0.5], alpha = 0) {
    const graphics = new Phaser.Graphics(game);
    graphics.beginFill(0xFF0000, alpha);
    graphics.drawRect(0, 0, width, height);
    graphics.x = anchor[0] * -width;
    graphics.y = anchor[1] * -height;
    this.addChild(graphics);
  }

  /**
   * set the button pulse animation
   * @param {number} maxScale
   */
  pulse(maxScale) {
    this.pulsing = true;
    this.pulsingTween = game.add.tween(this.scale).to({ x: maxScale || 1.1, y: maxScale || 1.1 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  }

  /**
   * stop the button pulse animation
   * @param {number} maxScale
   */
  stopPulse(maxScale) {
    if (this.pulsingTween) this.pulsingTween.stop();
    this.scale.setTo(maxScale || 1);
    this.pulsing = false;
  }

  /**
   * Memory reference spot for down()
   */
  beforeDown() {
    this.down();
  }

  /**
   * Called when the button is pushed down. Override the function
   */
  down() {
    // Override
  }

  /**
   * Memory reference spot for down()
   */
  beforeCancel() {
    this.cancel();
  }

  /**
   * Called when the button is pushed cancel. Override the function
   */
  cancel() {
    // Override
  }

  /**
   * on click function
   */
  click() {
    if (!this.active || !this.inputEnabled) return;
    if (!this._checkTerms()) return;

    this.active = false;
    this.onClick.dispatch(this);

    if (this.sfx) ComponentGameHooks.playSoundById(this.sfx);

    const orgScaleX = this.scale.x;
    const orgScaleY = this.scale.y;

    if (this.IMMEDIATE) {
      this.active = true;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (this.pulsing || !this.scaleOnClick) {
        game.time.events.add(this.refractorPeriod, () => { this.active = true; }, this);
      } else {
        game.add.tween(this.scale).to({
          x: orgScaleX + this.scaleChange,
          y: orgScaleY + this.scaleChange,
        }, this.refractorPeriod, Phaser.Easing.Quadratic.Out, true, 0, 0, true)
          .onComplete.add(() => {
            this.active = true;
          }, this);
      }
    }
  }

  /**
   * check terms
   */
  _checkTerms() {
    for (let i = 0; i < this.terms.length; i++) {
      if (!this.terms[i][0].call(this.terms[i][1])) {
        return false;
      }
    }
    return true;
  }

  /**
   * add term
   * @param {Function} callback
   * @param {Object} context
   */
  addTerm(callback, context) {
    this.terms.push([callback, context]);
  }

  /**
   * add a image instance as a label
   * @param {*} image
   */
  addImageLabel(image) {
    this.label = game.make.image(0, 0, 'buttons', image);
    this.label.anchor.setTo(0.5);
    this.addChild(this.label);
  }

  /**
   * add / create a text label
   * @param {Object || string} font
   * @param {string} text
   * @param {number} size
   * @param {number} x
   * @param {number} y
   * @param {number} maxWidth
   */
  addTextLabel(font, text, size, x, y, maxWidth) {
    const multi = 1;
    x = (typeof x === 'undefined' ? -2 : x);
    y = (typeof y === 'undefined' ? -2 : y);

    maxWidth = (typeof maxWidth === 'undefined' ? this.width * multi * 0.7 : maxWidth);
    this.label = new G.Text(x, y, text, {
      style: font,
      fontSize: size || Math.floor(this.height * multi * 0.7),
    }, 0.5, maxWidth);
    this.label.hitArea = new Phaser.Rectangle(0, 0, 0, 0);
    this.addChild(this.label);
  }

  /**
   * add multiline text label
   * @param {Object || string} font
   * @param {string} text
   */
  addTextLabelMultiline(font, text) {
    this.label = new G.Text(0, 0, text, {
      style: font,
      fontSize: Math.floor(this.height * 0.5),
    }, 0.5, this.width * 0.7, this.height * 0.7, true, 'center');
    this.addChild(this.label);
  }

  /**
   * stop all tweens
   */
  stopTweens() {
    G.stopTweens(this);
  }

  /**
   * change button texture
   * @param {*} image
   */
  changeTexture(image) {
    G.changeTexture(this, image);
  }

  /**
   * add a display element to the button
   * @param {Phaser.DisplayObject} obj
   */
  add(obj) {
    return this.addChild(obj);
  }

  /**
   * enable interactivity
   */
  enable() {
    this.inputEnabled = true;
    this.input.useHandCursor = true;
    this.active = true;
  }

  /**
   * disable interactivity
   */
  disable() {
    this.inputEnabled = false;
    this.input.useHandCursor = false;
    this.active = false;
  }

  /**
   * update child elements
   */
  updateChildren() {
    for (let i = this.children.length; i--;) {
      this.children[i].update();
    }
  }

  /**
   * destruction method
   */
  destroy() {
    if (this.signals) Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    this.onClick.removeAll();
    super.destroy();
  }
}

G.Button = UI_GButtonES6;
