/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */

import { createRealMoneySpinId } from '../../Elements/SpinningWheels/RealMoneyWheel/RealMoneyWheelHelpers';
import { RMWHEEL_EPS, RMWHEEL_MODES } from '../../Elements/SpinningWheels/RealMoneyWheel/rmWheelEnums';
import { Events } from './OMT_PlatformTrackingEvents';
import AnnuityManager from './dataTracking/annuityManager/AnnuityManager';

const PAYMENTS_READY_TIMEOUT_DURATION = 500;
const COIN_REGEX = /(\d+)\k/;

/**
 * class for payments / realy-money transactions
 */
export class OMT_Payments {
  /**
   * constructor
   */
  constructor() {
    this._catalog = [];
    this._paymentsReady = false;
    this._defineSignals();
  }

  /**
   * define phaser signals
   */
  _defineSignals() {
    this.signals = {
      onIAPConsumed: new Phaser.Signal(),
    };
  }

  /**
   * needs to be called at game startup to resolve payments ready status.
   * @returns {boolean} paymentsReady
   */
  async waitForPaymentsReady() {
    const supportedAPIs = FBInstant.getSupportedAPIs();
    const paymentsSupported = supportedAPIs.includes('payments.purchaseAsync') && supportedAPIs.includes('payments.getCatalogAsync');

    if (paymentsSupported) {
      this._paymentsReady = await new Promise((resolve) => {
        // set a timeout so we can log a failure.
        let timedOut = false;
        const timeoutId = setTimeout(() => {
          timedOut = true;
          OMT.platformTracking.logEvent(Events.PaymentsReadyTimedOut, PAYMENTS_READY_TIMEOUT_DURATION, { time: PAYMENTS_READY_TIMEOUT_DURATION });
          resolve(false);
        }, PAYMENTS_READY_TIMEOUT_DURATION);

        // wait for Facebook payemnts.onReady callback
        try {
          const startTime = Date.now();
          FBInstant.payments.onReady(() => {
            if (timedOut) return;
            const readyTime = Date.now() - startTime;
            // console.log(`PAYMENTS LOADED ${readyTime} ms`);
            OMT.platformTracking.logEvent(Events.PaymentsReady, readyTime, { time: readyTime });
            clearTimeout(timeoutId);
            resolve(true);
          });
        } catch (error) {
          // don't expect any errors here, but in-case Facebook.
        }
      });
    }
    return this._paymentsReady;
  }

  /**
   * @returns {boolean} true if Facebook payments aready ready / available
   */
  get paymentsReady() {
    return this._paymentsReady;
  }

  /**
   * get the catalog of purchasable items
   * @param {Function} callback
   * @returns {Promise<Array>}
   */
  async getCatalogAsync() {
    // return cached catalog if available
    if (this._catalog.length > 0) return this._catalog;
    // fetch catalog from FB
    try {
      this._catalog = await FBInstant.payments.getCatalogAsync();
      if (!this._catalog) throw new Error('invalid catalog');
    } catch (error) {
      this._catalog = [];
    }
    return this._catalog;
  }

  /**
   * get the catalog of purchasable items, catalog must be loaded first by getCatalogAsync()
   * @returns {Array}
   */
  getCatalogSync() {
    return this._catalog;
  }

  /**
   * get list of productIDs in the loaded catalog.
   */
  getProductIDList() {
    const productIDList = [];
    if (!Array.isArray(this._catalog)) return productIDList;
    for (const productData of this._catalog) {
      if (productData.productID) productIDList.push(productData.productID);
    }
    return productIDList;
  }

  /**
   * splits up Shop IDs into their rewards
   * @param {String} deal ID of shopItem
   * @returns {Object}
   */
  parseRewards(deal) {
    const retValue = {};
    const splitdeal = deal.productID.split('_');
    const { iapMultiplier } = G.saveState;

    retValue.coins = 0;
    retValue.infiniteLives = 0;
    if (COIN_REGEX.test(splitdeal[2])) {
      retValue.coins = (parseInt(COIN_REGEX.exec(splitdeal[2])[1], 10) * 1000) * iapMultiplier;
    } else if (splitdeal[1] === 'inflives' || splitdeal[1] === 'nmlinflives') { // Infinite lives by itself
      retValue.infiniteLives = (this.parseTimeToMinutes(splitdeal[2])) * iapMultiplier;
    }
    retValue.booster = [];
    retValue.fullPrice = deal.fullPrice;

    for (let i = 3; i < splitdeal.length; i++) {
      if (splitdeal[i].startsWith('b')) {
        retValue.booster.push({
          item: splitdeal[i].replace('b', 'booster#'),
          amount: (parseInt(splitdeal[i + 1], 10)) * iapMultiplier,
        });
      } else if (splitdeal[i].startsWith('il')) { // Infinite lives in special deals. Always in hours
        retValue.infiniteLives += (parseInt(splitdeal[i + 1], 10) * 60) * iapMultiplier;
      }
    }

    return retValue;
  }

  /**
   * Parses
   * @param {string} time
   * @returns {number}
   */
  parseTimeToMinutes(time) {
    let minutes = 0;
    // eslint-disable-next-line no-useless-escape
    const timeReg = /(?:(\d+)\h)?(?:(\d+)\m)?/;
    const timeData = timeReg.exec(time); // This will return an array of potentially [string, undefined, undefined] or [string, number, number]. Depending on what was there
    if (timeData[1]) { // Hours
      minutes += parseInt(timeData[1], 10) * 60;
    }
    if (timeData[2]) { // minutes
      minutes += parseInt(timeData[2], 10);
    }
    return minutes;
  }

  /**
   * converts the new object notation for Deals to the old one, that is still used
   * @param {Object} parsedDeal Object returned by parseRewards()
   */
  convertToOldProductData(parsedDeal) {
    const giftData = [];
    if (parsedDeal.coins >= 0) giftData.push(['coin', parsedDeal.coins]);
    for (let i = 0; i < parsedDeal.booster.length; i++) {
      giftData.push([parsedDeal.booster[i].item, parsedDeal.booster[i].amount]);
    }
    if (parsedDeal.infiniteLives > 0) {
      giftData.push([G.gift.GiftContentType.UnlimitedLife, parsedDeal.infiniteLives]);
    }
    return {
      giftData,
      labels: {
        amount: parsedDeal.coins,
      },
    };
  }

  /**
   * buy and attempt to immediately consume a product
   * @param {Object} product product object
   * @param {boolean} showMessageOnSuccess show "Purchase Successful" message on success?
   * @param {Function} callback callback function
   */
  buyAndConsumeProduct(product, showMessageOnSuccess, callback) {
    // for debug / fake purchases
    /*if (!G.BuildEnvironment.production && !G.BuildEnvironment.testPurchaseSdk) {
      callback(product, null, true);
      OMT.transactionTracking.logRealMoneyTransaction(product.price, product.productID, product.priceCurrencyCode);
      if (product.productID.includes('annuity')) {
        this._redeemAnnuity(product.productID, product.description, showMessageOnSuccess);
      } else {
        const productData = this.convertToOldProductData(this.parseRewards(product));
        if (showMessageOnSuccess) {
          const purchaseMsg = new G.PurchaseMsg({
            success: true,
            reward: productData.giftData,
          });
        }
      }
      this.signals.onIAPConsumed.dispatch(product);
      return;
    }*/

    const adOverlay = new G.AdLoadOverlay();
    let purchaseAsyncTimedOut = true;

    // clear overlay after 30 seconds due to facebook bug of no error being thrown after long waits
    setTimeout(() => {
      if (purchaseAsyncTimedOut) {
        adOverlay.destroy();
        console.log('FBInstant.payments.purchaseAsync: resolve/reject timeout');
      }
    }, 30000);

    FBInstant.payments.purchaseAsync({
      productID: product.productID,
    }).then((purchase) => { // purchase success
      // Transaction tracking
      OMT.transactionTracking.logRealMoneyTransaction(product.price, product.productID, product.priceCurrencyCode);

      // attempt to immediately consume the product
      this.consumeProduct(purchase, (success) => {
        adOverlay.destroy();
        callback(product, purchase, success);
        if (success) {
          if (product.productID.includes('annuity')) {
            this._redeemAnnuity(product.productID, product.description, showMessageOnSuccess);
          } else {
            const productData = this.convertToOldProductData(this.parseRewards(product));
            if (showMessageOnSuccess) {
              const purchaseMsg = new G.PurchaseMsg({
                success: true,
                reward: productData.giftData,
              });
            }
            this.signals.onIAPConsumed.dispatch(product);
          }
        }
        if (window.flushUserData){
          window.flushUserData();
        }
      });

      purchaseAsyncTimedOut = false;
    }).catch((error) => { // purchase failed or cancelled
      adOverlay.destroy();
      console.log('FBInstant.payments.purchaseAsync ERROR', error);

      const transactionFailReason = error.code;

      // DDNA.transactionHelper.trackCoinShopState({
      //   transactionState: 'FAIL',
      //   transactionFailReason,
      //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
      //   transactionID: '',
      // }, product);

      OMT.platformTracking.logEvent(OMT.platformTracking.Events.IAPFail, null, {
        price: product.price,
        productID: product.productID,
        priceCurrencyCode: product.priceCurrencyCode,
        reason: error.message,
      });

      // eslint-disable-next-line no-new
      new G.PurchaseMsg({ success: false });
      callback(null, null, false);
      purchaseAsyncTimedOut = false;
    });
  }

  /**
   * consume a purchased product.
   * @param {Object} purchase purchase object returned from facebook
   * @param {Function} callback callback function
   */
  consumeProduct(purchase, callback) {
    FBInstant.payments.consumePurchaseAsync(purchase.purchaseToken).then(() => {
      callback(true);
    }).catch((error) => {
      console.log('ERROR: consumeProduct could not consume the product');
      callback(false);
    });
  }

  /**
   * check if the user has any unconsumed products from previous transactions.
   * @returns {Promise}
   */
  async checkUnconsumedProducts() {
    FBInstant.payments.getPurchasesAsync().then((purchases) => {
      if (purchases.length > 0) {
        this.consumeDelayedProduct(purchases[0], () => {
          this.checkUnconsumedProducts(); // check for more purchases
        });
      }
    }).catch((error) => {
      console.log('ERROR: checkUnconsumedProducts() could not get purchases');
    });
  }

  /**
   * consume a delayed / queued product returned by facebook from a previous session.
   * @param {Object} purchase
   * @param {Function} callback
   */
  consumeDelayedProduct(purchase, callback) {
    this.consumeProduct(purchase, (success) => {
      if (!success) return;

      // Check if purchase is a wheel spin
      const productIDParts = purchase.productID.split('_');
      const dataCapture = DDNA.tracking.getDataCapture();
      const transactionID = purchase.paymentID ? purchase.paymentID : '';

      if (productIDParts[2] === 'hivalwheel' || productIDParts[2] === 'convwheel') {
        const wheelMode = productIDParts[2] === 'hivalwheel' ? RMWHEEL_MODES.HighValue : RMWHEEL_MODES.Conversion;
        let wheelEP;

        // Determine wheel entry point
        switch (productIDParts[1]) {
          case 'prize':
            wheelEP = RMWHEEL_EPS.Replacement;
            break;
          case 'helper':
            wheelEP = RMWHEEL_EPS.Helper;
            break;
          case 'targeted':
            wheelEP = RMWHEEL_EPS.TargetedOffer;
            break;
          case 'payload':
            wheelEP = RMWHEEL_EPS.EPPayload;
            break;
          default:
            wheelEP = RMWHEEL_EPS.Replacement;
        }

        // Add purchase to unclaimed real money spins
        const spinId = createRealMoneySpinId(null, wheelEP, false, true);
        G.saveState.addPendingRealMoneySpin(wheelMode, spinId);
        G.saveState.saveRealMoneySpinResult(spinId, -1);

        callback();
        return;
      }

      // Handle store purchases
      if (G.saveState) G.saveState.incrementIAPCount(); // keep track of IAP count
      if (productIDParts[2] === 'annuity') {
        this._redeemAnnuity(purchase.productID, purchase.description);

        // dataCapture.addToPlayerCharacterizationParam('realMoneySpent', DDNA.utils.formatCurrencyValueAsInteger(purchase.price) / 100, false);
        // dataCapture.addToPlayerCharacterizationSessionParam('realMoneySpentThisSession', DDNA.utils.formatCurrencyValueAsInteger(purchase.price) / 100);
        dataCapture.addToPlayerCharacterizationParam('realMoneyPurchasesMade', 1, true);

        const annuityDetails = AnnuityManager.getAnnuityDetails(purchase.productID, purchase.description);
        const catalogItem = this.getCatalogSync.find((element) => { return purchase.productID === element.productID; });
        // DDNA.transactionHelper.trackCoinShopPurchase(['coin', annuityDetails.coin], {
        //   transactionType: 'PURCHASE',
        //   tActionType: 'INVENTORY_PURCHASE',
        //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
        //   transactionID,
        // }, catalogItem);
        const purchaseMsg = new G.PurchaseMsg({
          success: true,
        });

        // purchase message completed
        purchaseMsg.onFinished.addOnce(() => {
          callback();
        });
        return;
      }

      const productData = this.convertToOldProductData(this.parseRewards(purchase));
      const reward = productData.giftData;
      if (!reward) { // no reward so execute callback to continue
        callback();
        return;
      }

      // get catalog before continuing
      this.getCatalogAsync().then((catalog) => {
        const catalogItem = catalog.find((element) => { return purchase.productID === element.productID; });

        OMT.transactionTracking.logRealMoneyTransaction(catalogItem.price, catalogItem.productID, catalogItem.priceCurrencyCode);

        // capture player stats
        if (G.saveState.getLives() === 0) dataCapture.addToPlayerCharacterizationParam('zeroLivesRealMoneyPurchases', 1, false);
        // dataCapture.addToPlayerCharacterizationParam('realMoneySpent', DDNA.utils.formatCurrencyValueAsInteger(catalogItem.price) / 100, false);
        // dataCapture.addToPlayerCharacterizationSessionParam('realMoneySpentThisSession', DDNA.utils.formatCurrencyValueAsInteger(catalogItem.price) / 100);
        dataCapture.addToPlayerCharacterizationParam('coinValueBoughtWithRealMoney', G.gift.getGiftTotalCoinValue(productData.giftData), false);
        dataCapture.addToPlayerCharacterizationParam('realMoneyPurchasesMade', 1, true);

        // track coinshop purchase
        // DDNA.transactionHelper.trackCoinShopPurchase(productData.giftData, {
        //   transactionType: 'PURCHASE',
        //   tActionType: 'INVENTORY_PURCHASE',
        //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
        //   transactionID,
        // }, catalogItem);

        // apply gift and show related message
        G.gift.applyGift(reward);
        const purchaseMsg = new G.PurchaseMsg({
          success: true,
          reward,
        });

        // purchase message completed
        purchaseMsg.onFinished.addOnce(() => {
          callback();
        });
      });
    });
  }

  /**
   * Adds the annuity pack to the user state (if its not in) and claims them the missing coins
   * @param {string} productId
   * @param {string} productDesc
   * @param {boolean} showMessageOnSuccess
   */
  _redeemAnnuity(productId, productDesc, showMessageOnSuccess = false) {
    const annuityMan = G.saveState.annuityManager;
    const annuityDetails = AnnuityManager.getAnnuityDetails(productId, productDesc);
    const annuityPack = annuityDetails.id;
    const existingAnnuity = annuityMan.getAnnuityPack(annuityPack);
    if (!existingAnnuity) {
      annuityMan.addNewAnnuity(annuityDetails.id, annuityDetails.days, annuityDetails.coin);
    }
    annuityMan.claimAnnuityForMissingDays(annuityPack, () => {
      if (showMessageOnSuccess) {
        const purchaseMsg = new G.PurchaseMsg({
          success: true,
        });
      }
    });
  }

  /**
   * Returns the converted price of the given `price` from the `originatingCurrency` in the `targetCurrency`'s currency
   * @param {number} price
   * @param {string} originatingCurrency
   * @param {string} targetCurrency
   * @returns {number}
   */
  convertPriceToAnotherCurrency(price, originatingCurrency, targetCurrency) {
    /**
     * Exchange currency rate is using EUR as base.
     * Retrieved from https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html
     * on Jan 18, 2021, 2:00pm EST
     */
    const exchangeData = G.json['configs/exchangeRate'];
    const { rates } = exchangeData;
    let scrubbedPrice = Number.parseFloat(price);
    if (Number.isNaN(scrubbedPrice)) {
      scrubbedPrice = 0;
    }
    const originatingCurrencyRate = rates[originatingCurrency];
    const targetCurrencyRate = rates[targetCurrency];
    if (!originatingCurrencyRate || !targetCurrencyRate) {
      return 0;
    }
    return (scrubbedPrice / originatingCurrencyRate) * targetCurrencyRate;
  }
}
