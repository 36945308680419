/* eslint-disable func-names */
if (typeof G === 'undefined') G = {};
G.Math = G.Math || {};

G.Math.Vector2 = function (optX, optY) {
  this.x = optX || 0;
  this.y = optY || 0;
};

G.Math.Vector2.prototype.set = function (x, y) {
  this.x = x;
  this.y = y;
};

G.Math.Vector2.prototype.clone = function () {
  return new G.Math.Vector2(this.x, this.y);
};

G.Math.Vector2.prototype.copyFrom = function (other) {
  this.x = other.x;
  this.y = other.y;
};
