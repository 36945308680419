/* eslint-disable no-undef */

import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';

let BG_WIDTH;
const BG_Y = 100;

const GOD_RAYS_Y = 50;
const GOD_RAYS_SCALE = 1.8;
const GOD_RAYS_ALPHA = 0.4;
const GOD_RAYS_VARIABLE_ALPHA = 0.2;
const GOD_RAYS_ROTATION_SPEED = 0.15;

let INNER_FRAME_WIDTH;
let INNER_FRAME_HEIGHT;

const DEFAULT_OUTER_BG_ASSET = 'shop3_ftuBox';

/**
 * class for TargetedOffer background group
 */
export class TargetedOffer_BackgroundGroup extends Phaser.Group {
  /**
   * constructor
   * @param {Object} bgConfig
   * @param {number} bgConfig.height
   * @param {Object} bgConfig.outerSlices
   * @param {string} bgConfig.outerBgAsset (optional)
   * @param {Object} bgConfig.innerSlices
   * @param {number} bgConfig.innerOffsetX (optional)
   * @param {number} bgConfig.innerOffsetY (optional)
   * @param {number} bgConfig.innerTint
   * @param {number} bgConfig.innerFrameVisible (optional)
   */
  constructor(bgConfig) {
    super(game);

    BG_WIDTH = OMT.systemInfo.orientation === ORIENTATION.horizontal ? 800 : 450;
    INNER_FRAME_WIDTH = OMT.systemInfo.orientation === ORIENTATION.horizontal ? 250 : 410;
    INNER_FRAME_HEIGHT = OMT.systemInfo.orientation === ORIENTATION.horizontal ? 430 : 380;

    const {
      height,
      outerSlices,
      outerBgAsset,
      innerSlices,
      innerOffsetX,
      innerOffsetY,
      innerTint,
      innerFrameVisible,
    } = bgConfig;

    this._drawGodRays();

    if (outerSlices) { // we use 9-scale if outerSlices are defined
      this._drawSlicedOuterBG(height, outerSlices, outerBgAsset);
    } else { // event customizations not using 9-scale
      this._drawBasicOuterBG(height, outerBgAsset);
    }

    const innerFrameOffset = { x: innerOffsetX || 0, y: innerOffsetY || 0 };
    this._drawInnerFrame(innerSlices, innerFrameOffset, innerTint, innerFrameVisible);
  }

  /**
   * draw the god rays behind the background frame
   */
  _drawGodRays() {
    this._godRays = G.makeImage(0, GOD_RAYS_Y, 'shine_godrays', 0.5);
    this._godRays.scale.set(GOD_RAYS_SCALE);
    this._godRays.alpha = GOD_RAYS_ALPHA;
    this.addChildAt(this._godRays, 0);
    this._updateGodRays();
  }

  /**
   * update the god rays animation
   */
  _updateGodRays() {
    this._godRays.angle += G.deltaTime * GOD_RAYS_ROTATION_SPEED;
    const alpha = GOD_RAYS_ALPHA + (GOD_RAYS_VARIABLE_ALPHA * Math.sin(Date.now() / 500));
    this._godRays.alpha = alpha;
  }

  /**
   * draw 9-slice outer bg
   * @param {number} height
   * @param {Object} outerSlices { left:number, right:number, top:number, bottom:number }
   * @param {string} outerBgAsset (optional)
   */
  _drawSlicedOuterBG(height, outerSlices, outerBgAsset = DEFAULT_OUTER_BG_ASSET) {
    this._outerBg = new UI_NineSlice(0, BG_Y, outerBgAsset, BG_WIDTH, height, outerSlices);
    this._outerBg.anchor.set(0.5);
    this.addChild(this._outerBg);
  }

  /**
   * draw basic outer bg with no 9-scale
   * @param {number} height
   * @param {string} outerBgAsset (optional)
   */
  _drawBasicOuterBG(height, outerBgAsset = DEFAULT_OUTER_BG_ASSET) {
    this._outerBg = G.makeImage(0, BG_Y, outerBgAsset);
    this._outerBg.height = height;
    this._outerBg.scale.x = this._outerBg.scale.y;
    this._outerBg.anchor.set(0.5);
    this.addChild(this._outerBg);
  }

  /**
   * draw the inner frame
   * @param {number} innerSlices { left:number, right:number, top:number, bottom:number }
   * @param {number} offsets { x:number, y:number }
   * @param {number} innerTint
   * @param {boolean} innerFrameVisible (optional)
   */
  _drawInnerFrame(innerSlices, offsets, innerTint, innerFrameVisible = true) {
    const innerBg = new UI_NineSlice(
      offsets.x, BG_Y + offsets.y, 'booster_box', INNER_FRAME_WIDTH, INNER_FRAME_HEIGHT, innerSlices,
    );
    const innerBgHighlight = new UI_NineSlice(
      0, 0, 'booster_box_highlight', INNER_FRAME_WIDTH, INNER_FRAME_HEIGHT, innerSlices,
    );
    innerBg.addChild(innerBgHighlight);
    innerBgHighlight.anchor.set(0.5);
    innerBg.tint = innerTint;
    innerBgHighlight.blendmode = PIXI.blendModes.MULTIPLY;
    innerBg.anchor.set(0.5);
    if (!innerFrameVisible) innerBg.alpha = 0;
    this.addChild(innerBg);
    this._innerBg = innerBg;
  }

  /**
   * Phaser update method
   */
  update() {
    this._updateGodRays();
  }

  /** @returns {number} get width of outer bg */
  get outerWidth() { return this._outerBg.width; }

  /** @returns {number} get height of outer bg */
  get outerHeight() { return this._outerBg.height; }

  /** @returns {number} get x position of outer bg */
  get outerBgX() { return super.x + this._outerBg.x; }

  /** @returns {number} get y position of outer bg */
  get outerBgY() { return super.y + this._outerBg.y; }

  /** @returns {number} get width of inner bg */
  get innerWidth() { return this._innerBg.width; }

  /** @returns {number} get height of inner bg */
  get innerHeight() { return this._innerBg.height; }

  /** @returns {number} get x position of inner bg */
  get innerBgX() { return super.x + this._innerBg.x; }

  /** @returns {number} get y position of inner bg */
  get innerBgY() { return super.y + this._innerBg.y; }
}
