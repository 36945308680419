import { Window } from '../../00_IMMEDIATE/Window';
import { LevelType } from '../../Libs/omt-game-board/Managers/GameEnums';
import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';
import { OMT_SystemInfo, ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

export class Window_TaskSlider extends Window {
  /**
   * The window that slides in to display the tasks at the beginning of the level.
   * @param {Object} parent The parent layer to add this window to.
   */
  constructor(parent, mode) {
    super(parent);

    // the window should not fade in/out like a regular window
    // because we're going to slide it in.
    this.NOFADEINOUT = true;

    // start from the top of the game window
    this.y = game.height * -1.5;

    G.sfx.whoosh_short_1.play();

    // setting up layout

    const isHorizontal = OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal;
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const isDailyChallenge = mode === LevelType.CHALLENGE; // LvlDataManager is not instantiated yet
    const isTreasureHunt = mode === LevelType.TREASURE_HUNT; // LvlDataManager is not instantiated yet
    const useSuperHardGraphics = isDailyChallenge || isSuperHardLevel;
    // add bg
    if (isTreasureHunt) {
      this.addBackground('task_slider_treasureHunt');
    } else if (isNotNormalLevel) {
      this.addBackground(useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_task_slider') : OMT_VILLAINS.getPrefixedName('hard_task_slider'));
    } else {
      this.addBackground('task_slider');
    }
    this.bg.y = 120;

    let taskTxtStyle = G.OMTsettings.elements.Window_taskSlider.taskTxt.style;
    let goalTxtStyle = G.OMTsettings.elements.Window_taskSlider.goal.style;
    let _taskPanelTxtStyle;
    if (isNotNormalLevel && !isTreasureHunt) {
      goalTxtStyle = {
        style: useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_2') : OMT_VILLAINS.getPrefixedName('hard_2'),
        fontSize: 50,
      };
      _taskPanelTxtStyle = {
        style: useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_2') : OMT_VILLAINS.getPrefixedName('hard_2'),
        fontSize: 50,
      };
      taskTxtStyle = {
        style: useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_6') : OMT_VILLAINS.getPrefixedName('hard_6'),
        fontSize: 32,
      };
    }

    // add header text
    let targetSliderY = G.OMTsettings.elements.Window_taskSlider.taskTxt.y;
    if (isHorizontal && G.OMTsettings.elements.Window_taskSlider.taskTxt.horizontalY) {
      targetSliderY = G.OMTsettings.elements.Window_taskSlider.taskTxt.horizontalY; // Available in MATCH
    }
    this.taskTxt = new G.Text(0, targetSliderY, `${OMT.language.getText('Task')}:`, taskTxtStyle, 0.5, 380);
    this.add(this.taskTxt);

    // if its point based display the points, otherwise display the blocks to collect.
    const { goalManager } = G.lvl;
    if (goalManager.isPointBased()) {
      this.goal = new G.Text(0, 112, `${OMT.language.getText('points').toUpperCase()}: ${goalManager.getPointTarget()}`, goalTxtStyle, 0.5, 380);
      this.add(this.goal);
    } else {
      this._taskPanel = G.Helpers.createTaskCollectPanels(G.lvlData.goal[1], _taskPanelTxtStyle);
      this._taskPanel.y = 112;
      this.add(this._taskPanel);
    }

    const { scale, scaleX } = OMT_Utils.generateConditionalObject({
      scale: 1,
      scaleX: 1,
    }, {
      condition: () => isHorizontal,
      parameters: {
        scale: 1.2,
        scaleX: 1.3,
      },
    });
    this.bg.scale.setTo(scale);
    this.bg.scale.x *= scaleX;

    // tween in
    game.add.tween(this).to({ y: -120 }, 400, Phaser.Easing.Sinusoidal.Out, true).onComplete.add(() => {
      // wait a second
      game.time.events.add(1000, G.sfx.whoosh_short_2.play, G.sfx.whoosh_short_2);
      // tween out to the bottom of the window
      game.add.tween(this).to({ y: game.height * 1.5 }, 400, Phaser.Easing.Sinusoidal.Out, true, 1000).onComplete.add(() => {
        G.sb('onWindowClosed').dispatch();
        this.destroy();
      });
    });
  }
}
// create global references
if (!window.Windows) window.Windows = {};
Windows.taskSlider = Window_TaskSlider;
