G.WorldSidePanelLockedWidget = function() {
  Phaser.Group.call(this, game);

  this.lock = G.makeImage(0, 4, "lock", 0.5, this);
  game.world.removeChild(this);

  this.unlockTxt = new G.Text(0, 4, OMT.language.getText('Unlock at Level X').replace('X',G.featureUnlock.unlockLevels.globalGoals+1), "world-sidePanelLockedWidget",0.5,140,150, true, 'center');
  this.unlockTxt.lineSpacing = -15;
  this.unlockTxt.setShadow(0, 0, 'black', 3);
  this.add(this.unlockTxt);

  game.add.tween(this.unlockTxt.scale).to({x:0.9,y:0.9},500,Phaser.Easing.Sinusoidal.InOut,true,0,-1,true);
};

G.WorldSidePanelLockedWidget.prototype = Object.create(Phaser.Group.prototype);