const DEFAULT_CORNER_SIZE = 11;
const DEFAULT_TINT = 0xFFFFFF;
const DEFAULT_FRAME = 'move_extra_label';
const DEFAULT_OVERLAP = false;
const DEFAULT_AUTOHIDE = true;

/**
 * Base class for configuring scroll bars
 */
export default class UI_ScrollBarConfigBase {
  /**
   * constructor
   * @param {number} cornerSize 9-scale corner size
   * @param {number} tint tint to apply to the knob
   * @param {string} knobAssetFrame frame for knob asset
   * @param {boolean} overlapScrollArea set to false if you want to overlap the scrollbar in the content area
   * @param {string} scrollBackground some background used
   * @param {boolean} autoHide set if the scrollbar should auto hide content if width/height is less then the view width/height
   */
  constructor(cornerSize, tint, depreciated_knobAssetSheet, knobAssetFrame, overlapScrollArea, scrollBackground, autoHide) {
    this._cornerSize = cornerSize || DEFAULT_CORNER_SIZE;
    this._tint = tint || DEFAULT_TINT;
    this._knobAssetFrame = knobAssetFrame || DEFAULT_FRAME;
    this._overlapScrollArea = overlapScrollArea == null ? DEFAULT_OVERLAP : overlapScrollArea;
    this._scrollBackground = scrollBackground || null;
    this._autoHide = autoHide == null ? DEFAULT_AUTOHIDE : autoHide;
  }

  /**
   * set 9-scale corner size
   * @param {number} value
   */
  set cornerSize(value) {
    this._cornerSize = value;
  }

  /**
   * get 9-scale corner size
   * @returns {number}
   */
  get cornerSize() {
    return this._cornerSize;
  }

  /**
   * set the knob asset frame
   * @param {number} value
   */
  set knobAssetFrame(value) {
    this._knobAssetFrame = value;
  }

  /**
   * get the knob asset frame
   * @returns {string}
   */
  get knobAssetFrame() {
    return this._knobAssetFrame;
  }

  /**
   * set the scroll background frame, Assumes to be in the same sheet as knobAssetFrame and the same slices
   * @param {string} value
   */
  set scrollBackground(value) {
    this._scrollBackground = value;
  }

  /**
   * Get the scroll background frame, Assumes to be in the same sheet as knobAssetFrame and the same slices
   * @returns {string}
   */
  get scrollBackground() {
    return this._scrollBackground;
  }

  /**
   * set the knob tint
   * @param {number} value
   */
  set knobTint(value) {
    this._tint = value;
  }

  /**
   * get the knob tint
   * @returns {number}
   */
  get knobTint() {
    return this._tint;
  }

  /**
   * set scroll area masking state
   * @param {boolean} value
   */
  set overlapScrollArea(value) {
    this._overlapScrollArea = value;
  }

  /**
   * true if scrollbar area needs to be masked
   * @returns {boolean}
   */
  get overlapScrollArea() {
    return this._overlapScrollArea;
  }

  /**
   * set if the scroll bar should auto-hide if content is smaller then view
   * @param {boolean} value
   */
  set autoHide(value) {
    this._autoHide = value;
  }

  /**
   * get if the scroll bar should auto-hide if content is smaller then view
   * @returns {boolean}
   */
  get autoHide() {
    return this._autoHide;
  }
}
