import { GameModePromo_HeaderBase } from './GameModePromo_HeaderBase';

const HEADER_TEXT_STYLE = { style: 'ui-tournament-promo', fontSize: 54 };

/**
 * Class for Daily Challenge Promotion Header
 */
export class DailyChallengePromo_Header extends GameModePromo_HeaderBase {
  /**
   * constructor
   */
  constructor() {
    super('Daily Challenge', 'tournament_promo_header_bg', HEADER_TEXT_STYLE);
  }
}
