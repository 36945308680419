export default class OMT_UI_FloatingItem extends Phaser.Image {
  constructor(
    position = { x: 50, y: 50 },
    sprite,
    target,
    onPop = () => Promise.resolve(),
  ) {
    super(game, 0, 0);

    this.anchor.setTo(0.5);
    const { x, y } = position;
    this.position.setTo(
      game.world.bounds.x + x * game.width,
      game.world.bounds.y + y * game.height,
    );

    this.tweenFloating = game.add
      .tween(this.position)
      .to(
        { y: this.position.y + 30 },
        1000,
        Phaser.Easing.Sinusoidal.InOut,
        true,
        0,
        -1,
        true,
      );

    game.add
      .tween(this.scale)
      .from({ x: 0, y: 0 }, 1000, Phaser.Easing.Elastic.Out, true);

    G.changeTexture(this, sprite);

    this.target = target;
    this.onPop = onPop;
  }

  /**
   * Go to the target and pop!
   * @param {number} delay The delay before the tween happens
   */
  goToTarget(delay = 0) {
    if (!this.target) {
      game.time.events.add(delay + 500, () => {
        this.tweenFloating.stop();
        this.pop();
      });
    } else {
      game.time.events.add(
        delay,
        () => {
          this.tweenFloating.stop();
          game.add
            .tween(this.position)
            .to(
              {
                x: game.world.bounds.x + this.target.worldPosition.x,
                y: game.world.bounds.y + this.target.worldPosition.y,
              },
              300,
              Phaser.Easing.Sinusoidal.In,
              true,
            )
            .onComplete.add(this.pop, this);

          game.add.tween(this.scale).to(
            {
              x: 0.6,
              y: 0.6,
            },
            300,
            Phaser.Easing.Sinusoidal.In,
            true,
          );
        },
        this,
      );
    }
  }

  /**
   * Triggers the onPop callback and creates UIfx on the position of the animation
   */
  pop() {
    this.onPop();

    for (let i = 0; i < 4; i++) {
      G.sb('UIfx').dispatch(
        game.world.bounds.x + this.worldPosition.x,
        game.world.bounds.y + this.worldPosition.y,
        'whiteStarPart',
      );
    }
    this.destroy();
  }
}
