/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
import OMT_UI_VerticalScrollBar from './ScrollBar/OMT_UI_VerticalScrollBar';
import OMT_UI_VerticalScrollBarConfig from './ScrollBarConfig/OMT_UI_VerticalScrollBarConfig';

import {
  UI_ScrollAreaBase,
  LOCK_INPUT_VELOCITY,
  MAX_OVERSCROLL,
  WHEEL_SCROLL_SPEED,
} from '@omt-components/UI/Scroll/UI_ScrollAreaBase';

/**
 * class for windows with scrollable content
 */
export default class OMT_UI_VerticalScrollArea extends UI_ScrollAreaBase {
  /**
   * constructor
   * @param {Phaser.Rectangle} scrollRect Rectangle for scrollable area
   * @param {boolean} hasScrollBar should the scrollArea have a scrollbar?
   * @param {OMT_UI_VerticalScrollBarConfig} scrollBarConfig Class reference for the scroll bar config. Should extend OMT_UI_ScrollBarConfigBase.
   */
  constructor(scrollRect, hasScrollBar = true, scrollBarConfig = null) {
    super(scrollRect);

    this._lastContentHeight = 0;
    this._extraHeight = 0;
    this._autoResize = true;
    this._allowOverscroll = false;

    // init scroll physics params
    this._contentDragStartY = 0;
    this._contentDragVelY = 0;

    this._initContentContainer();
    this._initScrollMask();
    this._setPointerMovedCallback();
    this._setMouseWheelEvent();

    if (hasScrollBar) { // scroll bar should be active
      // set default scrollBarConfig if none passed
      if (!scrollBarConfig) scrollBarConfig = new OMT_UI_VerticalScrollBarConfig(12, 5, 1, 1, null, null, 'shop2', 'scrollbar_slide', true, null, false);
      // adjust rectangles to compensate for scrollbar overlap / no overlap
      if (!scrollBarConfig.overlapScrollArea) this._scrollRect.width -= scrollBarConfig.width;
      else this._pointerRect.width -= scrollBarConfig.width;
      this._initScrollBar(scrollBarConfig);
      this.addScrollCondition(() => this._enabled && !this._scrollBar.dragActive && !OMT.social.isOverlayVisible);
    } else { // no scrollbar
      this.addScrollCondition(() => this._enabled && !OMT.social.isOverlayVisible);
    }
  }

  /**
   * get the scrollbar config
   * @returns {OMT_UI_VerticalScrollBarConfig}
   */
  get scrollBarConfig() {
    return this._scrollBar != null ? this._scrollBar.config : null;
  }

  /**
   * get the height of the content ignoring negative portion
   * @param {boolean} update update height values
   * @returns {number}
   */
  _getContentHeight(update = true) {
    if (!update) return this._lastContentHeight;
    const contentMinY = this._content.getBounds(this._content).y;
    const contentHeight = this._extraHeight + (contentMinY >= 0 ? this._content.height : this._content.height + contentMinY);
    return contentHeight;
  }

  /**
   * set some extra height if needed as sometimes Phaser height calculations can be buggy
   * @param {number} value
   */
  set extraHeight(value) {
    this._extraHeight = value;
  }

  /**
   * init the scroll bar
   * @param {OMT_UI_VerticalScrollBarConfig} scrollBarConfig
   */
  _initScrollBar(scrollBarConfig) {
    this._scrollBar = new OMT_UI_VerticalScrollBar(this._scrollRect, scrollBarConfig);
    this.addChild(this._scrollBar);
    this._scrollBar.signals.onScrollChange.add(this._onScrollBarScrolled, this);
  }

  /**
   * get a reference to the scrollbar
   * @returns {OMT_UI_VerticalScrollBar} returns a reference to the scrollbar
   */
  get scrollBar() {
    return this._scrollBar;
  }

  /**
   * user scrolled with scrollbar
   */
  _onScrollBarScrolled() {
    const contentHeight = this._getContentHeight();
    const visiblePercent = this._scrollRect.height / contentHeight;
    const scrollHeight = contentHeight * (1 - visiblePercent);
    const scrollPercent = contentHeight > this._scrollRect.height ? this._scrollBar.getScrollPercent() : 0;
    this._content.y = -scrollHeight * scrollPercent;
    this._contentDragVelY = 0;
    this.signals.onScrolled.dispatch();
  }

  /**
   * update called by phaser
   */
  update() {
    super.update();

    const localPointer = this._getLocalPointer();
    const containsPointer = this.scrollConditionsOK && Phaser.Rectangle.containsPoint(this._scrollRect, localPointer);
    this._updateContentDragging(containsPointer);

    const velocityLock = Math.abs(this._contentDragVelY) > LOCK_INPUT_VELOCITY;
    this._updateInputState(this._content, containsPointer && !velocityLock);

    if (this._scrollBar) { // update scrollbar if set
      if (this._autoResize) this.updateScrollBar();
      this._scrollBar.updateEnabledState();
    }
  }

  /**
   * check if the scroll area height changed and adjust if neccessary
   */
  updateScrollBar() {
    const contentHeight = this._getContentHeight(true);
    const scrollHeight = contentHeight - this._scrollRect.height;

    // update scrollbar / knob
    if (this._lastContentHeight !== contentHeight) {
      const viewPercent = this._scrollRect.height / contentHeight;
      const scrollPercent = -this._content.y / scrollHeight;
      this._scrollBar.updateScrollKnobGraphics(viewPercent);
      this._scrollBar.setScrollPercent(scrollPercent, false);
      this._lastContentHeight = contentHeight;
    }
  }

  /**
   * update content dragging
   * @param {boolean} containsPointer
   */
  _updateContentDragging(containsPointer) {
    const contentHeight = this._getContentHeight(this._autoResize);
    if (contentHeight < this._scrollRect.height) return;
    const lastY = this._content.y;

    // capture the pointer down state
    if (!this._pointerDown && game.input.activePointer.isDown) {
      this._pointerDown = true;
      this._pointerDownInContent = containsPointer;
      this._contentDragStartY = game.input.y;
    } else if (this._pointerDown && !game.input.activePointer.isDown) {
      this._pointerDown = false;
      this._pointerDownInContent = false;
    } else if (this._pointerDown && (!containsPointer && !this._allowOverscroll)) {
      this._pointerDownInContent = false;
    }

    const contentTopOverflow = (this._content.y + contentHeight) - this._scrollRect.height;
    const contentBottomOverflow = this._content.y;

    if (this._pointerDownInContent) { // drag active
      const overflow = Math.max(-contentTopOverflow, contentBottomOverflow, 0);
      const overScrollResistance = Math.min(overflow / MAX_OVERSCROLL, 1);
      this._contentDragVelY = (this._contentDragStartY - game.input.y) * (1 - overScrollResistance);
      this._contentDragStartY = game.input.y;
    } else if (contentTopOverflow < -0.5) { // drag inactive, bounce back to top limit
      this._contentDragVelY = 0;
      this._content.y -= contentTopOverflow * this._elasticity;
    } else if (contentBottomOverflow > 0.5) { // drag inactive, bounce back to bottom limit
      this._contentDragVelY = 0;
      this._content.y -= contentBottomOverflow * this._elasticity;
    }

    if (!this._allowOverscroll) { // dont allow overscrolling if this._allowOverscroll = false
      if (this._contentDragVelY > 0 && this._content.y === contentHeight) { this._contentDragVelY = 0; return; } // Clamped to top
      if (this._contentDragVelY < 0 && this._content.y === 0) { this._contentDragVelY = 0; return; } // Clamped to bot
    }

    // scroll as long as we have velocity
    if (Math.abs(this._contentDragVelY) > 0) {
      // update content position
      this._content.y -= this._contentDragVelY;
      // dont allow overscrolling if this._allowOverscroll = false
      if (!this._allowOverscroll) this._content.y = Math.max(Math.min(this._content.y, 0), this._scrollRect.height - contentHeight);

      if (this._scrollBar) { // update scroll bar position
        const scrollPercent = -this._content.y / (contentHeight - this._scrollRect.height);
        this._scrollBar.setScrollPercent(scrollPercent, false);
      }

      // apply friction to drag velocity
      this._contentDragVelY *= this._friction;
    }

    // dispatch onScrolled event
    if (this._content.y !== lastY) this.signals.onScrolled.dispatch();
  }

  /**
   * jump to content position
   * @param {number} yPos
   */
  jumpToContentPosition(yPos) {
    // move content
    const contentHeight = this._getContentHeight(this._autoResize);
    this._contentDragVelY = 0;

    if (contentHeight > this._scrollRect.height) {
      this._content.y = -yPos;
      this._content.y = Math.max(Math.min(this._content.y, 0), this._scrollRect.height - contentHeight);
    } else {
      this._content.y = 0;
    }

    if (this._scrollBar) { // update scroll bar position
      const scrollPercent = -this._content.y / (contentHeight - this._scrollRect.height);
      this._scrollBar.setScrollPercent(scrollPercent, false);
    }

    // dispatch onScrolled event
    this.signals.onScrolled.dispatch();
  }

  /**
   * on pointer moved callback
   */
  _onPointerMoved() {
    try {
      const localPointer = this._getLocalPointer();
      const containsPointer = this.scrollConditionsOK && Phaser.Rectangle.containsPoint(this._pointerRect, localPointer);
      const velocityLock = Math.abs(this._contentDragVelY) > LOCK_INPUT_VELOCITY;
      this._updateInputState(this._content, containsPointer && !velocityLock);
      if (this._scrollBar) this._scrollBar.updateEnabledState();
    } catch (error) {
      this._deletePointerMovedCallback();
    }
  }

  /**
   * set the mouse wheel moved event
   */
  _setMouseWheelEvent() {
    G.sb('mouseWheelEvent').add(this._onMouseWheel, this);
  }

  /**
   * on mouse wheel moved
   * @param {Object} event
   * @param {number} event.deltaY
   */
  _onMouseWheel(event) {
    if (!this.scrollConditionsOK) return; // scrolling is locked
    const localPointer = this._getLocalPointer();
    const containsPointer = this.scrollConditionsOK && Phaser.Rectangle.containsPoint(this._scrollRect, localPointer);
    if (containsPointer) { // check if pointer is in the scroll area
      const contentHeight = this._getContentHeight(this._autoResize);
      const velY = this._contentDragVelY + (event.deltaY * WHEEL_SCROLL_SPEED);
      const newYPos = this._content.y - (velY / Math.abs(velY));
      const contentTopOverflow = (newYPos + contentHeight) - this._scrollRect.height;
      const contentBottomOverflow = newYPos;
      // only add velocity if new position is in bounds
      if (contentTopOverflow > 0 && contentBottomOverflow < 0) {
        this._contentDragVelY = velY;
      }
    }
  }

  /**
   * true if the scrollBar is currently visible
   * @returns {boolean}
   */
  get isScrollBarVisible() {
    return this._scrollBar != null ? this._scrollBar.visible : false;
  }

  /**
   * resize the scroll rect / view
   * @param {number} width
   * @param {number} height
   */
  resizeScrollArea(width, height) {
    super.resizeScrollArea(width, height);
    this.jumpToContentPosition(-this._content.y);
  }

  /**
   * toggle auto resizing of the scroll content area
   * @param {boolean} value
   */
  set autoResize(value) {
    this._autoResize = value;
  }

  /**
   * toggle if we allow over scrolling
   * @param {boolean} value
   */
  set allowOverScroll(value) {
    this._allowOverscroll = value;
  }

  /**
   * descruction method
   */
  destroy() {
    G.sb('mouseWheelEvent').remove(this._onMouseWheel, this);
    super.destroy();
  }
}
