import WorldMap2_Util, { interactionType, WorldMapTwoMapInteractionLimit } from '../../../../Elements/WorldMap2/WorldMap2_Util';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

export const CHESTSHUFFLE_MANAGER_DATA_KEY = 'chest-shuffle-manager';

export default class ChestShuffleDataManager {
  /**
   * Data manager for the chest shuffle game for Saga Map 2.0
   * @param {Object} data
   */
  init(data) {
    this.dataReference = data;

    const defaultData = {
      cs: [], // cs = Chest Shuffle
      o: 0, // o = times Opened
    };

    /**
     * data = {
     *    cs: [{u:number}] // u = unlock
     * };
     */
    OMT_Utils.mergeMissingObject(this.dataReference, defaultData);

    if (G.firstTime && !OMT.envData.isRestoreProgressPayload) { // If the user is playing the game for the first time, this will generate chests before the first gate
      this.generateChestShuffles();
      this.save();
    }
  }

  /**
   * Returns the number of times the chest shuffle window has been opened
   * @returns {number}
   */
  get opened() {
    return this.dataReference.o;
  }

  /**
   * Returns all levels that a chest shuffle would unlock at
   * @returns {Array<number>}
   */
  getAllExistingChestLevel() {
    return this.dataReference.cs.map((chestShuffle) => chestShuffle.u);
  }

  /**
   * Generates a shuffle chest at the given levels
   * If no levels are given, it will generate some at the beginning
   * @param {Array<number>} levelUnlock
   */
  generateChestShuffles(levelUnlock = []) {
    if (levelUnlock.length === 0) {
      levelUnlock = this.determineUnlockLevel();
    }

    levelUnlock.forEach((level) => {
      if (!this.doesChestAtLevelExist(level) && level > WorldMapTwoMapInteractionLimit) {
        this.dataReference.cs.push({ u: level });
      }
    });
  }

  /**
   * Determines the levels the chest shuffle should be unlockable at, starting from 0
   * Generates levels calculating from the rewards per tile length
   * @returns {Array<number>}
   */
  determineUnlockLevel() {
    const shuffleInstances = G.OMTsettings.elements.worldMap.rewardPerTile.map((reward) => reward.toLowerCase() === interactionType.chestShuffle);
    const levelUnlocksFromZero = [];
    shuffleInstances.forEach((isAShuffle, index) => {
      if (isAShuffle) {
        levelUnlocksFromZero.push(WorldMap2_Util.getLevelCountAtTile(index) - 3);
      }
    });
    return levelUnlocksFromZero;
  }

  /**
   * Checks if theres supposed to be a chest at the given level
   * @param {number} level
   */
  doesChestAtLevelExist(level) {
    return this.dataReference.cs.find((shuffleData) => shuffleData.u === level);
  }

  /**
   * Marks the chest as opened (by removing it out of the data)
   * @param {number} level
   * @param {boolean} saveNow
   */
  markChestAsSeen(level, saveNow = true) {
    const chest = this.doesChestAtLevelExist(level);
    if (chest) {
      this.dataReference.cs.splice(this.dataReference.cs.indexOf(chest), 1);
      if (saveNow) {
        this.save();
      }
    }
  }

  /**
   * Increments the number of times the chest shuffle was opened
   * @param {boolean} saveNow
   */
  incrementOpened(saveNow = false) {
    this.dataReference.o++;
    if (saveNow) {
      this.save();
    }
  }

  /**
   * Removes all chests up to level
   * @param {number} level
   */
  removeAllChestsUpToLevel(level) {
    _.remove(this.dataReference.cs, (chest) => chest.u < level);
  }

  /**
   * Internal save with new data key
   */
  save() {
    OMT.userData.writeUserData(CHESTSHUFFLE_MANAGER_DATA_KEY, this.dataReference);
  }
}
