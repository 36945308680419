/* eslint-disable no-unused-vars */

// keys for the board features flags
export const BOARD_FEATURE_KEYS = {
  TREASURE_HUNT: 'treasureHunt',
  FORTUNE_COOKIE: 'fortuneCookie',
  EVENT_TOKENS: 'eventTokens',
};

// default states for board feature flags
const BOARD_DEFAULT_FEATURES = {
  [BOARD_FEATURE_KEYS.TREASURE_HUNT]: () => false,
  [BOARD_FEATURE_KEYS.FORTUNE_COOKIE]: () => false,
  [BOARD_FEATURE_KEYS.EVENT_TOKENS]: () => false,
};

/**
 * base class / mock interface for Board to Game hooks
 */
export class BoardGameHooks {
  /**
   * constructor
   * @param {Object} levelData parsed level data
   */
  constructor(levelData) {
    this._levelData = levelData;
    this._featureChecks = { ...BOARD_DEFAULT_FEATURES };
  }

  /**
   * check if a board feature is active
   * @param {string} featureId key on this._featureChecks
   */
  isFeatureActive(featureId) {
    const featureActive = this._featureChecks[featureId] ? this._featureChecks[featureId]() : false;
    // console.log(`FEATURES CHECK ${featureId}: ${featureActive}`);
    return featureActive;
  }

  /**
   * get localized text. override to implement.
   * @param {string} key localization key, basically the english text.
   * @returns {string}
   */
  getText(key) { return key; }

  /**
   * update players coins. override to implement.
   * @param {number} value value to modify coin count by
   * @returns {number} new coin value
   */
  changeCoins(value) { return 0; }

  /**
   * triggers any external actions upon booster use. override to implement.
   * @param {number} boosterNum
   * @param {number} unlimitedMode
   */
  onBoosterUse(boosterNum, unlimitedMode) {}

  /**
   * triggers any external actions upon start booster use. override to implement.
   * @param {number} boosterNum
   * @param {number} boosterCount
   * @param {boolean} isAdRewarded
   */
  onStartBoosterUse(boosterNum, boosterCount, isAdRewarded) {}

  /**
   * triggers tracking of pre-level boosters on level start. override to implement.
   */
  trackStartBoosters(startBoosterTrackingData) {}

  /**
   * triggers any external actions upon out-of-moves booster use. override to implement.
   * @param {string} boosterType
   */
  onOOMBoosterUse(boosterType) {}

  /**
   * increment the daily challenge win count. override to implement.
   */
  incrementDailyChallengeWinCount() {}

  /**
   * Adds the token amount collected from the treasure hunt. Override to implement
   * @param {number} amount
   */
  collectTreasureHuntToken(amount) {}

  /**
   * triggers when the level goal is reached
   */
  onGoalAchieved() {}

  /**
   * triggers when Board.deconstruct() is first called
   */
  onBoardDesconstruct() {}

  /**
   * Get an array of objects representing temporary boosters for the start booster action. override to implement
   * @returns {{[key: string]: Array<SPECIAL_TYPES> | Array<number>}}
   */
  getTemporaryBoosters() { return {}; }

  /**
   * Do cleanup regarding temporary boosters. override to implement.
   */
  resolveTemporaryBoosters() {}

  /**
   * get icon asset for boosterType
   * @param {string} boosterType
   * @returns {String}
   */
  getIconForBoosterType(boosterType) { return null; }

  /**
   * get mvoes for a boosterType
   * @param {string} boosterType
   * @returns {number}
   */
  getMovesForBoosterType(boosterType) { return 1; }

  /**
   * get array of active mystery gifts. override to implement.
   * @returns {Array<SPECIAL_TYPES>}
   */
  getActiveMysteryGifts() { return []; }

  /**
   * Get array of temporary villains tutorial boosters. override to implement.
   * @returns {Array.<number>}
   */
  getVillainsStartBoosters() { return []; }

  /**
   * Reset the booster settings for villains temporary boosters. override to implement.
   */
  resolveVillainsStartBoosters() {}

  /**
   * true if discounted loss aversion can be shown. override to implement.
   * @param {number} maxSpins
   * @returns {boolean}
   */
  canShowLossAversionDiscount(maxSpins) { return false; }

  /**
   * log out inventory transactions with appropriate game systems. override to implement.
   * @param {Array.<Object>} inventoryTransactions
   */
  logInventoryTransactions(inventoryTransactions) {}

  /**
   * log a ftux event. override to implement.
   * @param {number} stepNum
   * @param {string} ddnaEventName
   * @param {string} fbaEventName
   */
  logFTUXEvent(stepNum, ddnaEventName, fbaEventName) {}

  /**
   * play sound by id. override to implement.
   * @param {string} soundId
   * @param {number} volume (optional)
   */
  playSound(soundId, volume = 1) {}

  /**
   * Starts cascade skip sequence after player clicks on Skip
   */
  startCascadeSkipSequence() {}

  /**
   * Calculates the final score after player skips final scascade sequence
   * Note: Should be rounded in some way to avoid fractional points
   */
  calculateFinalScoreAfterCascadeSkip() { return Math.floor(1.5); }

  /** GETTER METHODS ********************************* */

  /** @returns {number} gets last passed level. override to implement. */
  get lastPassedLevelNum() { return 0; }

  /** @returns {boolean} check if hints are allowed. */
  get hintsAllowed() { return true; }

  /** @returns {number} get count of loss aversion wheel spins remaining. */
  get lossAversionWheelSpinsRemaining() { return 0; }

  /** @returns {Object} override to return instance */
  get treasureHuntManager() { return undefined; }

  /** @returns {Object} override to return instance */
  get fortuneCookieDataManager() { return undefined; }

  /** @returns {number} some check for the fortune cookie drops, not really sure. */
  get fortuneCookieMovesLeft() { return Infinity; }

  /** @returns {Object} override to return instance */
  get tokenEventManager() { return undefined; }

  /** @returns {any} get the token event counter display */
  get eventTokenCounter() { return undefined; }

  /** @returns {Point} get animation target for extra moves. */
  get extraMovesAnimationTarget() { return { x: 0, y: 0 }; }

  /** @returns {Phaser.DisplayObject} display layer for booster FX. */
  get startBoosterFXLayer() { return undefined; }

  /** @returns {any} top ui bar. */
  get uiTopBar() { return undefined; }

  /** @returns {boolean} is the device desktop? */
  get deviceIsDesktop() { return undefined; }

  /** @returns {number} game orientation */
  get orientation() { return undefined; }

  /** @returns {number} game scale */
  get gameScale() { return 1; }

  /**
   * This should ONLY be true in OMT
   * @returns {boolean} has reduced landscape resolution
   */
  get hasLowResLandscape() { return false; }

  /** @returns {Object} config for skipping the final cascade */
  get cascadeSkipConfig() {
    return {
      enabled: false,
      buttonText: 'Tap to Skip',
      buttonStyle: null,
    };
  }
}
