import {
  TAB_TEXT_WIDTH_RATIO, TAB_TEXT_HEIGHT_RATIO,
  TAB_TEXT_STYLE,
} from '../MainLeaderboardSettings';

const TAB_FRAMES = { active: 'lb2_active_tab', inactive: 'lb2_inactive_tab' };

/**
 * class for leaderboard content panel tab button
 */
export class MainLeaderboardPanelTab extends Phaser.Group {
  /**
   * constructor
   * @param {string} labelText panel name / label
   */
  constructor(labelText) {
    super(game);

    this.signals = {
      onTabClicked: new Phaser.Signal(),
    };

    this._initTabImage();
    this._initTabLabel(labelText);
    this._initInputEvents();
  }

  /**
   * initialize the tab image
   */
  _initTabImage() {
    this._tabImage = G.makeImage(0, 0, TAB_FRAMES.active, [0, 1], this);
  }

  /**
   * update the tab frame
   * @param {boolean} inFocus is the tabs panel in focus?
   */
  updateTabFrame(inFocus) {
    G.changeTexture(this._tabImage, inFocus ? TAB_FRAMES.active : TAB_FRAMES.inactive);
  }

  /**
   * initialize the tab label
   * @param {string} labelText panel name / label
   */
  _initTabLabel(labelText) {
    const labelField = new G.Text(
      Math.round(this._tabImage.width / 2),
      -Math.round(this._tabImage.height / 2),
      OMT.language.getText(labelText),
      TAB_TEXT_STYLE,
      [0.5, 0.5], // anchor
      this._tabImage.width * TAB_TEXT_WIDTH_RATIO, // max width
      this._tabImage.height * TAB_TEXT_HEIGHT_RATIO, // max height
      false, // no wrap
    );
    this.addChild(labelField);
  }

  /**
   * initialize tab input events
   */
  _initInputEvents() {
    this._tabImage.inputEnabled = true;
    this._tabImage.input.useHandCursor = true;
    this._tabImage.events.onInputDown.add(() => { this._onTabClick(); });
  }

  /**
   * tab click event
   */
  _onTabClick() {
    this.signals.onTabClicked.dispatch();
  }

  /**
   * destruction method
   */
  destroy() {
    Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    super.destroy();
  }
}
