G.CommonObjects = G.CommonObjects || {};

G.CommonObjects.DoubleShine = function (optX, optY) {
  Phaser.Group.call(this, game);
  this.position.setTo(optX || 0, optY || 0);

  this.shine1 = G.makeImage(0, 0, 'shine_godrays', 0.5, this);
  this.shine1.blendMode = 1;
  this.shine1.name = 'shine1';

  const animation = G.Animations.getDoubleShineAnimation();
  animation.animate(this.game, this);
};

G.CommonObjects.DoubleShine.prototype = Object.create(Phaser.Group.prototype);
