import { HelperMoves } from '../Helpers/HelperMoves';
import { HelperRealMoneyWheel } from '../Helpers/HelperRealMoneyWheel';
import { RMWHEEL_EPS, RMWHEEL_EVENTS } from '../../SpinningWheels/RealMoneyWheel/rmWheelEnums';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

/* eslint-disable no-multi-spaces */

export class UI_HelpersManager extends Phaser.Group {
  /**
   * Constructor.
   * @param {*} game
   * @param {*} parent The object this should be added to.
   * @param {*} gameManager The Game.js instance.  Used to position the helper based on other elements in the scene.
   */
  constructor(game, state, gameManager) {
    super(game);

    // referencing this because we need to position ourselves based on the top bar and the board
    this._state = state; // Game.js
    this.gm = gameManager;
    this._active = [LevelType.TOURNAMENT, LevelType.TREASURE_HUNT].indexOf(this._state.mode) === -1; // Disable in tournaments and treasure hunts
    this._helperWheelShown = false;
    this._helperMovesFriendAvailable = false;
    this._helperMoves = new HelperMoves(game, state, gameManager);
    this._helperRealMoneyWheel = new HelperRealMoneyWheel(game);
    this.add(this._helperMoves);
    this.add(this._helperRealMoneyWheel);
  }

  /**
   * Init function
   */
  async init() {
    this._helperMovesFriendAvailable = await this._helperMoves.init();

    // Create signal bindings
    this._onChangeMoveNumber = G.sb('changeMoveNumber').add(this.onChangeMoveNumber.bind(this));
    this._onLevelEnd = G.sb('onGoalAchieved').addOnce(() => {
      this._active = false;
      if (this._helperRealMoneyWheel.isVisible) {
        this._helperRealMoneyWheel.hideWheelIcon();
      }

      if (this._helperMoves.isVisible) {
        this._helperMoves.cancelHelp();
      }
    });
  }

  /**
   * When the player has 5 moves left, we calculate the probability of whether the helper should be displayed or not.
   */
  onChangeMoveNumber() {
    // defined in OMT_Cheats
    if (G.lvl.activateMovesHelperOverride) {
      G.lvl.activateMovesHelperOverride = false;
      if (this._helperMovesFriendAvailable) {
        this._helperMoves.startTweens();
      } else {
        OMT_Utils.stylizedLog('Warning: Cannot activate moves helper. No friend data available', '#FFFF80');
      }
      return;
    }

    if (G.lvl.activateRealMoneyHelperWheelOverride) {
      G.lvl.activateRealMoneyHelperWheelOverride = false;
      this._helperWheelShown = true;
      this._helperRealMoneyWheel.showWheelIcon();
      return;
    }

    if (this._active) {
      // const isConversion = DDNA.tracking.getDataCapture().getRealMoneyWheelConversionStatus();

      if (OMT.feature.getFeatureRequestMoveHelp()
        && G.IAP && G.lvl.moves === 5
        && (!G.levelsResolvedOOM || !G.levelsResolvedOOM[this.gm.lvlIndex])
        && !this._helperRealMoneyWheel.isVisible) {
        // When the player has 5 moves left, we calculate the probability of whether the moves helper should be displayed or not.
        let levelFails = 0;
        if (G.saveState.sessionData.levelsFailedSinceUsingHelper && G.saveState.sessionData.levelsFailedSinceUsingHelper[this.gm.lvlIndex]) {
          levelFails = G.saveState.sessionData.levelsFailedSinceUsingHelper[this.gm.lvlIndex];
        }
        const percentages = G.OMTsettings.requestMoveHelp ? G.OMTsettings.requestMoveHelp.probabilities : [10, 20, 30, 40];
        const index = levelFails - 1;
        let percent = index >= 0 ? percentages[Math.min(index, percentages.length - 1)] : 0;
        percent /= 100;

        if (this._helperMovesFriendAvailable) {
          const showHelper = Math.random() <= percent;
          if (showHelper) {
            this._helperMoves.startTweens();
          } else {
            OMT_Utils.stylizedLog('Warning: Cannot activate moves helper. No friend data available', '#FFFF80');
          }
        }
      } else {
        const ingameBoosters = G.saveState.getBoosterArray().slice(1, 4);

        // Conditions for rolling for the chance to display the real money wheel helper
        const conditions = [
          !this._helperWheelShown,                      // helper hasn't appeared before in level session
          !this._helperMoves.isVisible,                 // helper isn't already on-screen
          G.IAP,                                        // player is in IAP env
          OMT.feature.getFeatureRealMoneyHelperWheel(), // real money helper wheel is active
          !G.saveState.getRealMoneyWheelOnCoolDown(),   // real money wheel is not on cooldown
          G.lvl.lvlIndex > 20,                          // player is past level 21
          (G.lvl.moves <= 10 && G.lvl.moves >= 2),      // player has 2-10 moves left
          // player is converted (high value) OR hasn't seen the replacement wheel in the last 3 sessions
          // (!isConversion || G.saveState.getRealMoneyWheelSessionsNotSeen() >= 3),
          // player lost their last level attempt OR has no in-game boosters
          ((G.levelsResolvedOOM && G.levelsResolvedOOM[this.gm.lvlIndex]) || ingameBoosters.every((count) => count <= 0)),
        ];

        // console.log(conditions);

        // If all conditions are true, we calculate the probability of whether the real money helper wheel should be displayed or not.
        const conditionsPassed = conditions.every((condition) => condition);

        if (conditionsPassed) {
          const roll = Math.random();
          // console.log(roll);
          if (roll <= G.json.settings.realMoneyHelperWheel.appearanceChance / 100) {
            this._helperWheelShown = true;
            this._helperRealMoneyWheel.showWheelIcon();

            // Track wheel seen
            // DDNA.missionTracker.trackWheelEvent(RMWHEEL_EPS.Helper, RMWHEEL_EVENTS.Seen, isConversion, 1, false);
          } else {
            // Track wheel eligibilty
            // DDNA.missionTracker.trackWheelEvent(RMWHEEL_EPS.Helper, RMWHEEL_EVENTS.Eligible, isConversion, 1, true);
          }
        }
      }
    }
  }

  destroy() {
    super.destroy();
    this._onChangeMoveNumber.detach();
    this._onLevelEnd.detach();
  }
}

G.UI_HelpersManager = UI_HelpersManager;
