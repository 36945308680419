/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import { MILLISECONDS_IN_MIN } from '@omt-components/Utils/TimeUtil';
import { GATE_COOLDOWN_KEY } from '../OMT/dataTracking/gateManager/GateManager';
import TargetedOfferDataManager from '../OMT/dataTracking/targetedOffer/TargetedOfferDataManager';

/**
* Class for handling DDNA tracking events
*/
class DDNATracking {
  /**
  * constructor
  */
  constructor() {
    //
  }

  /**
  * initialize the tracking instance
  * @param {Object} config DDNA configuration object
  * @param {number} jsInitTime timestamp of js init
  */
  async init(config, jsInitTime) {
    this._config = config;
    this._eventPriority = config.eventPriority ? parseInt(config.eventPriority) : 0;

    this._dataCapture = new DDNADataCapture(jsInitTime);
    this._dataStore = { data: null, key: 'DDNA-data' };

    await this.getDataStore();
    this.initEventQueue();

    // Set sessions since last targeted offer in player characterization params
    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam(
    //   'sessionsSinceLastTargetedOffer',
    //   TargetedOfferDataManager.getInstance().getSessionNumber(),
    //   false,
    // );
  }

  /**
  * get the data store if it exists, otherwise create one.
  */
  async getDataStore() {
    const data = OMT.userData.getUserData(this._dataStore.key);

    // check if valid data on server. if not create it.
    if (data && data.ftuxTable && data.clientDeviceList) {
      this._dataStore.data = data;
    } else {
      this._dataStore.data = { ftuxTable: {}, clientDeviceList: [] };
    }

    // if the player characterization data exists send it to the data capture instance
    if (this._dataStore.data.pcData) {
      await this._dataCapture.setPlayerCharacterizationData(this._dataStore.data.pcData);
      this._dataCapture.updatePlayerCharacterizationLoginParams();
    } else {
      await this._dataCapture.createDefaultPlayerCharacterizationData();
    }

    // if the social data exists send it to the social tracker
    if (this._dataStore.data.socialData) {
      // DDNA.socialTracker.setData(this._dataStore.data.socialData);
    } else {
      // DDNA.socialTracker.createDefaultData();
    }
  }

  /**
  * write the data store after a change
  */
  writeSaveData() {
    OMT.userData.writeUserData(this._dataStore.key, this._dataStore.data);
  }

  /**
  * clear the data store
  */
  clearSaveData() {
    OMT.userData.writeUserData(this._dataStore.key, null);
  }

  /**
  * add an object to the save data
  * @param {string} key Key for the data storage
  * @param {Object} obj Object to write to storage
  * @param {boolean} write Optional boolean to enable / disable writing to the server
  */
  addToSaveData(key, obj, write = true) {
    this._dataStore.data[key] = obj;
    if (write) this.writeSaveData();
  }

  /**
  * get the enabled / disabled state
  * @returns {boolean}
  */
  get enabled() {
    return this._config != null;
  }

  /**
  * internal method to generate the URL for collect events
  * @param {string} route collect route
  * @returns {string} collect url
  */
  getCollectURL(route = '') {
    let url = this._config.collect;
    const envKey = this._config.env;
    url = `${url + envKey}/${route}`;
    return url;
  }

  /**
  * internal method to generate the URL for engage events
  * @param {string} route engage route
  * @returns {string} engage url
  */
  getEngageURL(route = '') {
    let url = this._config.engage;
    const envKey = this._config.env;
    url = `${url + envKey}/${route}`;
    return url;
  }

  /**
  * internal method for sending post requests
  * @param {string} url generated collect / engage URL
  * @param {string} data stringified JSON
  * @param {Function} onSuccess success callback
  * @param {Function} onError error callback
  */
  sendPostRequest(url, data, onSuccess, onError) {
   /* const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.onreadystatechange = () => {
      // console.log('DDNATracking.sendPostRequest() : xhr.readyState=' + xhr.readyState + ', xhr.status=' + xhr.status);
      if (xhr.readyState > 3 && (xhr.status >= 200 && xhr.status < 300)) {
        if (onSuccess) onSuccess();
      } else if (xhr.readyState > 1 && xhr.status === 0) {
        if (onError) onError();
      }
    };
    xhr.send(data);*/
  }

  /**
   * send a single event ( no queue )
   * @param {Object} eventObj
   */
  sendEvent(eventObj) {
   /* const url = this.getCollectURL();
    this.sendPostRequest(url, JSON.stringify(eventObj));*/
  }

  /**
  * init the event queue / event timers
  */
  initEventQueue() {
   /* this._eventQueue = [];
    this._queueInterval = this._config.queueInterval ? parseInt(this._config.queueInterval) : 1000;
    this.restoreQueueFromLocalStorage();
    setInterval(() => { this.sendQueuedEvents(); }, this._queueInterval);
    this.sendQueuedEvents();*/
  }

  /**
  * internal method for queue events for bulk send
  * @param {Object} event event object to queue
  */
  addEventToQueue(event) {
   /* this._eventQueue.push(event);
    this.writeQueueToLocalStorage(this._eventQueue);*/
  }

  /**
  * send queued events / generate bulk send Object
  */
  sendQueuedEvents() {
   /* if (this._eventQueue.length === 0) return;

    // add each event to the bulk event list
    const bulkEventObj = {};
    bulkEventObj.eventList = [];
    for (let i = 0; i < this._eventQueue.length; i++) {
      bulkEventObj.eventList.push(this._eventQueue[i]);
    }

    // send bulk event
    const tempEventList = this._eventQueue.slice();
    this._eventQueue.length = 0;

    const url = this.getCollectURL('bulk');
    this.sendPostRequest(
      url, JSON.stringify(bulkEventObj),
      () => { this.onSendQueueSuccess(tempEventList); },
      () => { this.onSendQueueError(tempEventList); },
    );*/
  }

  /**
  * event queue was sent successfully
  * @param {Array.<Object>} tempEventList list of event objects
  */
  onSendQueueSuccess(tempEventList) {
   // this.clearQueueFromLocalStorage();
  }

  /**
  * error ocurred sending the event queue
  * @param {Array.<Object>} tempEventList list of event objects
  */
  onSendQueueError(tempEventList) {
    // if no connection can be established add back to the queue and retry
   // this._eventQueue = this._eventQueue.concat(tempEventList);
   // this.writeQueueToLocalStorage(this._eventQueue);
  }

  /**
   * check if event is active based on priority and enabled state
   * @param {number} priority
   * @returns {boolean}
   */
  isEventActive(priority) {
    return this.enabled && priority <= this._eventPriority;
  }

  /**
  * create / queue DDNA collect events
  * @param {string} eventName name of event
  * @param {Object} eventParams (optional) param object for event
  * @param {number} priority (optional) for enabling / disabling events
  * @param {string} logEventName (optional) log name / label for event
  * @param {boolean} useQueue (optional) if true queue event instead of immediate send
  */
  collectEvent(eventName, eventParams = null, priority = 0, logEventName = 'DDNA-CollectEvent', useQueue = true) {
   /* if (!this.isEventActive(priority)) return;

    if (!eventParams) eventParams = {};
    const eventObj = {
      eventName,
      eventParams,
      // eventTimestamp: // DDNA.utils.getTimestamp() // we are letting DDNA fill in the timestamp now
    };

    this._dataCapture.assignUserData(eventObj);
    this._dataCapture.assignEnvParams(eventObj.eventParams);
    this._dataCapture.assignGameProgressionParams(eventObj.eventParams);
    this._dataCapture.assignPlayerCharacterizationParams(eventObj.eventParams);
    this._dataCapture.assignPlayerCharacterizationSessionParams(eventObj.eventParams);
    // DDNA.utils.deleteNullParameters(eventObj);
    console.log(`!! ${logEventName} (${priority}) : ${JSON.stringify(eventObj)}`);

    if (useQueue) this.addEventToQueue(eventObj);
    else this.sendEvent(eventObj);*/
  }

  /**
  * anonymous event sent with limited data
  * @param {string} eventName name of event
  * @param {Object} eventParams param object for event
  * @param {number} priority (optional) for enabling / disabling events
  */
  ghostEvent(eventName, eventParams, priority = 0) {
    /*if (!this.isEventActive(priority)) return;

    if (!eventParams) eventParams = {};
    const eventObj = {
      eventName,
      eventParams,
      // eventTimestamp: // DDNA.utils.getTimestamp() // we are letting DDNA fill in the timestamp now
    };

    this._dataCapture.assignUserData(eventObj);
    delete eventObj.sessionID; // we dont want the session ID sent
    // DDNA.utils.deleteNullParameters(eventObj);

    this.addEventToQueue(eventObj);

    console.log(`!! DDNA-GhostEvent : ${JSON.stringify(eventObj)}`);*/
  }

  /**
  * ftuxEvent. Expands on collectEvent adding ftux version and ensures the call is executed only once.
  * @param {number} stepIdx ftux step number (-1 to disable)
  * @param {string} stepName name of ftux step
  * @param {Object} eventParams (optional) param object for event
  * @param {number} priority (optional) for enabling / disabling events
  */
  ftuxEvent(stepIdx, stepName, eventParams = null, priority = 0) {
    if (!this.isEventActive(priority) || !OMT.feature.isFTUXEnabled()) return;

    // check if ftux already sent. continue and store it if not.
    const { ftuxTable } = this._dataStore.data;

    if (ftuxTable[stepName]) return;
    ftuxTable[stepName] = true;
    this.writeSaveData();

    if (!eventParams) eventParams = {};
    if (stepIdx !== -1) eventParams.stepIdx = stepIdx;
    eventParams.stepName = stepName;
    eventParams.ftuxVersion = parseInt(this._config.ftuxVersion);

    this.collectEvent('ftux', eventParams, priority, 'DDNA-FTUXEvent', false);
  }

  /**
  * create a transaction event for purchases. Use the DDNATransactionHelper class to form these events.
  * @param {Object} eventParams param object for event
  * @param {Array.<Object>} itemsUsed list of items used
  * @param {Array.<Object>} itemsReceived list of items receieved
  * @param {number} priority (optional) for enabling / disabling events
  */
  transactionEvent(eventParams, itemsUsed, itemsReceived, priority = 0) {
    if (!this.isEventActive(priority)) return;

    // create the productsSpent / productsReceived Objects
    if (!eventParams.productsSpent) eventParams.productsSpent = {};
    if (itemsUsed.length > 0) eventParams.productsSpent.items = itemsUsed;
    if (!eventParams.productsReceived) eventParams.productsReceived = {};
    if (itemsReceived.length > 0) eventParams.productsReceived.items = itemsReceived;

    this.collectEvent('transaction', eventParams, priority, 'DDNA-TransactionEvent');
  }

  /**
  * create a transaction state event for IAPs. Use the DDNATransactionHelper class to form these events.
  * @param {Object} eventParams param object for event
  */
  transactionStateEvent(eventParams, priority = 0) {
    if (!this.isEventActive(priority)) return;

    this.collectEvent('transactionState', eventParams, priority, 'DDNA-TransactionStateEvent');
  }

  /**
  * create a resource event for purchases. This is a aternative to transactions for testing purposes.
  * Use the DDDNATransactionHelper class to form these events.
  * @param {Object} eventParams param object for event
  * @param {number} priority (optional) for enabling / disabling events
  */
  resourceEvent(eventParams, priority = 0) {
    if (!this.isEventActive(priority)) return;
    this.collectEvent('resource', eventParams, priority, 'DDNA-ResourceEvent');
  }

  /**
  * create a social event for tracking
  * @param {string} socialActionType type of social action
  * @param {string} targetUserID id of targeted user
  * @param {string} message custom message to send along with the event (disabled)
  * @param {Object} additionalParams (optional) Object with additional parameters to append to the event
  * @param {number} priority (optional) for enabling / disabling events
  */
  async socialActionEvent(socialActionType, socialActionReward, targetUserID, message = '', additionalParams = null, priority = 0) {
    if (!this.isEventActive(priority)) return;
    if (targetUserID == null) { // checks for null and undefined, == is fine
      targetUserID = '';
    }

    const eventParams = await DDNA.socialTracker.getParamObject();
    eventParams.socialActionType = socialActionType;
    eventParams.targetUserID = targetUserID;
    eventParams.targetUserIDHash = OMT.crypt.sha3Hash(targetUserID);
    eventParams.socialActionReward = socialActionReward;
    // assign additional parameters if passed
    if (additionalParams) Object.assign(eventParams, additionalParams);
    // eventParams.customText = message.length > 0 ? 1 : 0; // disabled as we werent receiving this text in game

    // Check whether we are in a level context
    if (game.state.getCurrentState().key === 'Game') {
      // Level context
      eventParams.socialActionLevelContext = G.lvlData.levelNumber || G.saveState.getLastPassedLevelNr() + 1;
      eventParams.socialActionMapContext = '';
    } else {
      // Map Context
      eventParams.socialActionLevelContext = 0;
      eventParams.socialActionMapContext = 'world_map';
    }

    eventParams.socialNetwork = 'FACEBOOK'; // hard coded for now

    this.collectEvent('socialAction', eventParams, priority, 'DDNA-SocialActionEvent');
  }

  /**
  * create a gate open event.
  * @param {{gateId: number, gatePrice: number, gateCooldown: number, gateOpenMethod: GATE_OPEN_REASON,
      invitesSent: number, invitesClaimed: number, inviteDiff: number, starDiff: number, gateTimeOpen:number,
      coinDiff: number, timeLeft:number}} gateTrackingData the gate being opened
  * @param {number} priority (optional) for enabling / disabling events
  */
  gateOpenEvent(gateTrackingData, priority = 0) {
    if (!this.isEventActive(priority)) return;

    const eventParams = {
      goGateID: gateTrackingData.gateId,
      goGateLevel: gateTrackingData.gateId,
      goGateOpenMethod: gateTrackingData.gateOpenMethod,
      goTimeGateWasOpened: gateTrackingData.gateTimeOpen,
      goInvitesSent: gateTrackingData.invitesSent,
      goInvitesClaimed: gateTrackingData.invitesClaimed,
      goStarsDifference: gateTrackingData.starDiff,
      goCoinsDifference: gateTrackingData.coinDiff,
      // See GateManager.js for details on goInvitesDifference
      goInvitesDifference: gateTrackingData.inviteDiff,
      goTimeLeft: gateTrackingData.timeLeft,
    };

    this.collectEvent('gateOpen', eventParams, priority, 'DDNA-GateOpenEvent');
  }

  /**
  * check if client is on a new device. send the "clientDevice" Event if they are
  * @param {number} priority (optional) for enabling / disabling events
  */
  checkClientDeviceStatus(priority = 0) {
    if (!this.isEventActive(priority)) return;

    // check if this is a new device. If so save the device and send the clientDevice event
    const { clientDeviceList } = this._dataStore.data;
    const clientDeviceId = DDNA.utils.generateCliendId();

    const additionalParams = {
      deviceName: OMT.systemInfo.friendlyDeviceIdentifier,
      devicePixelRatio: window.devicePixelRatio,
    };

    // detect new devices and keep track of them in the save state
    if (clientDeviceList.indexOf(clientDeviceId) === -1) {
      clientDeviceList.push(clientDeviceId);
      this.collectEvent('clientDevice', additionalParams, priority);
      this.writeSaveData();
    }
  }

  /**
  * write the event queue to local storage
  * @param {Array.<Object>} eventQueue Array of events objects
  */
  writeQueueToLocalStorage(eventQueue) {
    try {
      localStorage.setItem('eventQueue', JSON.stringify({ queue: eventQueue }));
    } catch (e) { console.log('Error // DDNA.tracking.writeQueueToLocalStorage() could not access localStorage.'); }
  }

  /**
  * clear the event queue from local storage
  */
  clearQueueFromLocalStorage() {
    try {
      localStorage.removeItem('eventQueue');
    } catch (e) { console.log('Error // DDNA.tracking.clearQueueFromLocalStorage() could not access localStorage.'); }
  }

  /**
  * restore event queue from local storage
  */
  restoreQueueFromLocalStorage() {
    try {
      if (localStorage.getItem('eventQueue') != null) {
        this._eventQueue = JSON.parse(localStorage.getItem('eventQueue')).queue;
        // console.log("!! RESTORE ", this._eventQueue);
      }
    } catch (e) { console.log('Error // DDNA.tracking.restoreQueueFromLocalStorage() could not access localStorage.'); }
  }

  /**
  * get a reference to the data capture class
  * @returns {Object}
  */
  getDataCapture() {
    return this._dataCapture;
  }
}

// create global references
if (!window.DDNA) window.DDNA = {};
//DDNA.tracking = new DDNATracking();
