import OMT_UI_AvatarWithCustomFrame from './OMT_UI_AvatarWithFrameOffset';

export default class OMT_UI_AvatarWithBadge extends OMT_UI_AvatarWithCustomFrame {
  /**
   * Assembles data for use with the avatar.
   * @param {number} [size]
   * @param {string} userImage
   * @param {string} userBadge
   * @returns {{imageURL:string, imageSize:number, frameOffset:{x:number, y:number}, frameMultSize:number, frameAsset:string}}
   */
  static _assembleData(userImage, userBadge, size = 100) {
    const targetBadgeName = G.OMTsettings.elements.badges[userBadge] ? userBadge : 'default';
    const targetBadgeData = G.OMTsettings.elements.badges[targetBadgeName];
    return {
      imageURL: userImage,
      imageSize: size,
      frameOffset: targetBadgeData,
      frameMultSize: targetBadgeData.scale,
      frameAsset: targetBadgeData.asset,
    };
  }

  /**
   * Creates the avatar with given size. Has a custom badge
   * If user is not given, it will default to the current player
   * @param {number} size
   * @param {string} user
   */
  constructor(userImage, userBadge, size) {
    super(OMT_UI_AvatarWithBadge._assembleData(userImage, userBadge, size));
  }
}
