
G.NotificationsMgr = {};

G.NotificationsMgr.activeNotification = null;
G.NotificationsMgr._queuedNotifications = [];

/**
 * Pushes a notification to the screen, or enqueues it 
 * @static
 * @param {string} txt 
 * @param icon 
 * @param {integer} [duration] In milliseconds
 */
G.NotificationsMgr.pushNotification = function(txt, icon, duration) {
  var notification = G.NotificationsMgr._createNotificationObj(txt,icon);

  if(G.NotificationsMgr.activeNotification){
    G.NotificationsMgr._queuedNotifications.push(notification);
    notification.visible = false;
  } else {
    G.NotificationsMgr.activeNotification = notification;
    G.NotificationsMgr.activeNotification.showNotification(G.NotificationsMgr._onShowComplete, duration);
  }
  game.world.add(notification);
}

G.NotificationsMgr._shiftQueue = function(duration){
  G.NotificationsMgr.activeNotification = G.NotificationsMgr._queuedNotifications.shift();
  G.NotificationsMgr.activeNotification.showNotification(G.NotificationsMgr._onShowComplete, duration);
}

G.NotificationsMgr._onShowComplete = function(duration){
  G.NotificationsMgr.activeNotification.hideNotification();
  if(G.NotificationsMgr._queuedNotifications.length > 0){
    G.NotificationsMgr._shiftQueue(duration);
  } else {
    G.NotificationsMgr.activeNotification = null;
  }
}

/**
 * @static
 * @param {string} txt 
 * @param {string} iconKey
 */
G.NotificationsMgr._createNotificationObj = function(txt, iconKey){
  var group = new Phaser.Group(game);
  group.fixedToCamera = true;

  // create with 0,0 anchor
  var bg = G.makeImage(
    0,0,
    'First_try_bar',
    0,
    group
  );
  bg.width = game.width;
  bg.height = bg.height * 1.33;
  group.cameraOffset.y = -bg.height;

  var text = new G.Text(
    game.width * 0.5, 
    bg.height * 0.5 + 7, 
    txt, 
    {
      style: "font-blue",
      fontSize: "30px"
    }, 
    [0.5,0.5], 
    game.width - (iconKey ? 125 : 30)
  );
  group.add(text);

  if(iconKey){
    var icon = G.makeImage(
      0,0,
      iconKey,
      0.5,
      group
    );
    icon.width = 40;
    icon.height = 40;
    icon.x = (game.width * 0.5) + (-text.width * 0.5) + (-25);
    icon.y = bg.height * 0.55;
    group.add(icon);
  }

  group.showNotification = function(onCompleteFunc, duration){
    group.visible = true;
    game.add.tween(group.cameraOffset)
      .to({y: -(bg.height * 0.2)}, 500, Phaser.Easing.Sinusoidal.Out, true)
      .onComplete.add(function(){
        game.time.events.add(duration || 1500, function(){
          onCompleteFunc(duration);
        });
      });
  };
  group.hideNotification = function(){
    game.add.tween(group.cameraOffset)
      .to({y: -bg.height}, 500, Phaser.Easing.Sinusoidal.Out, true)
      .onComplete.add(function(){
        group.destroy();
      });
  };

  group.y = -bg.height;
  return group;
}