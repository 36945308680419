import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import OMT_UI_DrawUtil from '../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import TreasureHuntManager from '../../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';

export default class Window_TreasureHuntUpdate extends Window {
  constructor(parent) {
    super(parent);

    this._init();
    G.saveState.treasureHuntManager.setUpdateMessageSeen();
  }

  /**
   * Async init because we need assets to load before we can show this...
   */
  _init() {
    this._drawWindow();
    this._drawContents();
    this._drawContinue();
  }

  /**
   * Draws the main window elements
   */
  _drawWindow() {
    this._container = new Phaser.Group(game, this);
    this._bg = G.makeImage(0, 0, 'popup_background_treasureHunt', 0.5, this._container);
    this._tokenContainer = new Phaser.Group(game, this._container);
    this._mascotContainer = new Phaser.Group(game, this._container); // Behind the bg

    // banner
    this._banner = G.makeImage(
      0, -265,
      'treasureHunt_banner',
      0.5,
      this._container,
    );

    this.addCloseButton();

    // title text
    this._titleTxt = new G.Text(0, -267, OMT.language.getText('Treasure Hunt Update!'), 'treasureHunt-headerTitleText', 0.5, 330, 125, true, 'center');
    this._container.add(this._titleTxt);

    this._initMascots();
  }

  /**
   * Overrides the close button. Makes it big actually.
   */
  addCloseButton() {
    super.addCloseButton();
    this.closeButton.scale.set(1.15);
    this.closeButton.x += 5;
  }

  /**
   * Draws the mascot and positions according to orientation
   */
  _initMascots() {
    const mascotContainer = new Phaser.Group(game, this._mascotContainer);
    const dataArr = [G.OMTsettings.treasureHuntSuper.updateWindow.left];
    const isHorizontal = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    if (isHorizontal) {
      dataArr.push(G.OMTsettings.treasureHuntSuper.updateWindow.right);
    }
    for (const mascotData of dataArr) {
      const mascotGroup = new Phaser.Group(game, mascotContainer);
      const mascotImg = G.makeImage(mascotData.offset.x, mascotData.offset.y, `treasureHunt_prelevelMascot${mascotData.mascot}`, 0.5, mascotGroup);
      const targetScale = mascotData.scale[OMT.systemInfo.orientationKey] ? mascotData.scale[OMT.systemInfo.orientationKey] : mascotData.scale;
      mascotImg.scale.set(targetScale);

      const targetPos = mascotData.pos[OMT.systemInfo.orientationKey] ? mascotData.pos[OMT.systemInfo.orientationKey] : mascotData.pos;
      mascotGroup.x = targetPos.x;
      mascotGroup.y = targetPos.y;
    }
  }

  /**
   * Draws the contents of the window
   */
  _drawContents() {
    const mainWords = 'We listened to your feedback and made some changes:';
    const tMain = this._makeText(OMT.language.getText(mainWords));
    tMain.y = -100;
    this._container.addChild(tMain);

    const padding = 90;
    const bulletData = [
      { text: 'Lives are not lost when losing levels' },
      { text: 'Levels are easier' },
      { text: 'Added more treasure to the final chest', y: 10 },
    ];

    let nextY = 15;
    for (const bullet of bulletData) {
      const text = new G.Text(-213, 0, `• ${OMT.language.getText(bullet.text)}`, 'treasureHunt-updateBulletText', [0, 0.5], this._bg.width * 0.7, null, true, 'left');
      text.y = nextY + (bullet.y || 0);
      nextY += padding;
      this._container.addChild(text);
    }

    const triangleFormation = [
      { x: 0, y: -40 },
      { x: 25, y: 0 },
      { x: -25, y: 0 },
    ];
    const tokenGroupPos = [
      { x: this._bg.width / 3.1, y: this._bg.height / 2 },
    ];
    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      tokenGroupPos.push({ x: -this._bg.width / 3.1, y: this._bg.height / 2 });
    }
    for (const tokenPos of tokenGroupPos) {
      const tokenGroup = new Phaser.Group(game, this._tokenContainer);
      for (const formation of triangleFormation) {
        G.makeImage(formation.x, formation.y, 'treasureHunt_token', 0.5, tokenGroup);
      }
      tokenGroup.x = tokenPos.x;
      tokenGroup.y = tokenPos.y;
    }
  }

  /**
   * Makes a text.
   * They all use the same style and I was kinda lazy.
   * @param {string} text
   * @param {number} height
   * @returns {G.Text}
   */
  _makeText(text, height = null, textStyle = 'treasureHunt-hintWindowTitle') {
    return new G.Text(0, 0, text, textStyle, 0.5, this._bg.width * 0.8, height, true, 'center');
  }

  /**
   * Draws the continue button
   */
  _drawContinue() {
    this._continueButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 220,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Play'),
        textStyle: {
          style: 'font-white',
          fontSize: 70,
        },
        dimensionMods: {
          width: 0.9,
          height: 0.9,
        },
      },
      options: {
        clickFunction: {
          onClick: this._playClicked.bind(this),
          disableAfterClick: true,
        },
        cacheButton: false,
      },
    });
    this._continueButton.y = this._bg.y + this._bg.height / 2;
    this._container.addChild(this._continueButton);
    this._continueButton.visible = true;
  }

  /**
   * When the play button is clicked
   */
  _playClicked() {
    TreasureHuntManager.openTreasureHuntPopup();
    this.closeWindow();
  }
}

if (!window.Windows) window.Windows = {};
Windows.treasureHuntUpdate = Window_TreasureHuntUpdate;
