/* eslint-disable no-unused-vars */

// import { MainLeaderboardExpandButton } from './MainLeaderboardExpandButton';
import { FriendsScoresPanel } from './Friends/FriendsScoresPanel';
import { LifeRequestPanel } from './Lives/LifeRequestPanel';
import UI_EventBlocker from '@omt-components/UI/EventBlocker/UI_EventBlocker';
import { MainLeaderboardInviteButtonsPanel } from './MainLeaderboardInviteButtonsPanel';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../States/Scaling/GameScaleController';

import {
  ACTIVE_TABS, DEAULT_TAB,
  COLLAPSED_Y_OFFSET, COLLAPSE_ANIMATION_DURATION,
  EXPANDED_Y_OFFSET, EXPAND_ANIMATION_DURATION,
  EXPAND_BTN_POS,
  HIDE_ANIMATION_DURATION,
  EXPANDED_STATE_FADE_OUT_DURATION,
  EXPANDED_STATE_FADE_IN_DURATION,
  COLLAPSED_STATE_FADE_OUT_DURATION,
  COLLAPSED_STATE_FADE_IN_DURATION,
  COLLAPSED_STATE_EVENT_BLOCKER_Y,
  PANEL_CONTENT_RECT,
  PANEL_BG_FILL_PADDING,
  FRIENDS_PANEL_COLLAPSED_OVERLAY_BG_RECT,
} from '../MainLeaderboardSettings';

const GAME_CONTENT_WIDTH = 640;

// tabName / id references
const TAB_FRIENDS = 'Friends';
const TAB_LIVES = 'Lives';

/**
 * Leaderboard Main class
 */
export class MainLeaderboard extends Phaser.Group {
  /**
   * constructor
   * @param {Function} rewardCoinsCallback
   * @param {UI_EventBlocker} [externalUIBlocker]
   */
  constructor(rewardCoinsCallback, externalUIBlocker) {
    super(game);

    this._expanded = false; // keeps track of expand / collapse state
    this._focussedPanel = null; // keeps track of the focussed / active panel

    this.signals = {
      onExpanded: new Phaser.Signal(),
      onCollapsed: new Phaser.Signal(),
    };

    this._initContentGroups();
    this._initInviteButtonsPanel();

    this._initEventBlocker(externalUIBlocker);
    this._initCollapsedStateEventBlocker();

    // intialize the panels
    const { deviceIsIOS } = OMT.systemInfo;
    if (ACTIVE_TABS.indexOf(TAB_FRIENDS) >= 0) this._initFriendsScoresPanel(rewardCoinsCallback);
    if (ACTIVE_TABS.indexOf(TAB_LIVES) >= 0 && !deviceIsIOS) this._initLifeRequestPanel();

    // focus default and init the expand button as long as we have leaderboards active
    if (this._panels.length > 0) {
      // this._initExpandButton();
      this.focusPanelByName(DEAULT_TAB);
    }

    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      this.scale.setTo(GameScaleController.getInstance().gameScale);
    }

    this._addGlobalListeners();
  }

  /**
   * add listeners for global events
   */
  _addGlobalListeners() {
    // add the screen resize event
    G.sb('onScreenResize').add(this._onScreenResize, this);
    this._onScreenResize();

    // listen for global events to hide / show the leaderboard
    G.sb('levelWindowOpen').add(this.hide, this);
    G.sb('levelWindowClose').add(this.show, this);
    G.sb('hideMainHighscorePanel').add(this.hide, this);
    G.sb('showMainHighscorePanel').add(this.show, this);
    G.sb('openLifeTransactionsMenu').add(this._openExpandedLivesPanel, this);
  }

  /**
   * remove listeners for global events
   */
  _removeGlobalListeners() {
    G.sb('onScreenResize').remove(this._onScreenResize, this);
    G.sb('levelWindowOpen').remove(this.hide, this);
    G.sb('levelWindowClose').remove(this.show, this);
    G.sb('hideMainHighscorePanel').remove(this.hide, this);
    G.sb('showMainHighscorePanel').remove(this.show, this);
    G.sb('openLifeTransactionsMenu').remove(this._openExpandedLivesPanel, this);
  }

  /**
   * initialize the main content groups
   */
  _initContentGroups() {
    // should contain everything. This gets animated for hide / show.
    this._contentGroup = new Phaser.Group(game);
    this.addChild(this._contentGroup);

    // should contain all panel content. This gets animated for expand / collapse.
    this._panels = [];
    this._panelGroup = new Phaser.Group(game);
    this._panelGroup.y = COLLAPSED_Y_OFFSET;
    this._contentGroup.addChild(this._panelGroup);
  }

  /**
   * get a panel instance by its name
   * @param {string} panelName
   * @returns {MainLeaderboardPanel}
   */
  _getPanelByName(panelName) {
    for (const panel of this._panels) {
      if (panel.panelName === panelName) return panel;
    }
    return null;
  }

  /**
   * initialize the friends / highscore panel
   * @param {Function} rewardCoinsCallback
   */
  _initFriendsScoresPanel(rewardCoinsCallback) {
    const tabIndex = this._panels.length;
    const panel = new FriendsScoresPanel(tabIndex, rewardCoinsCallback);
    panel.signals.onTabClicked.add(() => { this._onPanelTabClicked(panel); });
    this._panelGroup.addChild(panel);
    this._panels.push(panel);
  }

  /**
   * initialize the life request panel
   */
  _initLifeRequestPanel() {
    const tabIndex = this._panels.length;
    const panel = new LifeRequestPanel(tabIndex);
    panel.signals.onTabClicked.add(() => { this._onPanelTabClicked(panel); });
    this._panelGroup.addChild(panel);
    this._panels.push(panel);
  }

  /**
   * switch focus to the inputted panel
   * @param {MainLeaderboardPanel} toFocusPanel
   */
  _focusPanel(toFocusPanel) {
    if (this._focussedPanel === toFocusPanel) return;
    for (const panel of this._panels) {
      if (panel !== toFocusPanel) panel.blur();
    }
    this._panelGroup.addChild(toFocusPanel);
    toFocusPanel.focus();
    this._focussedPanel = toFocusPanel;
  }

  /**
   * focus a panel by the panelName
   * @param {string} panelName
   */
  focusPanelByName(panelName) {
    const defaultPanel = this._getPanelByName(panelName);
    if (defaultPanel) this._focusPanel(defaultPanel);
  }

  /**
   * on panel select tab clicked
   * @param {MainLeaderboardPanel} panel panel containing clicked tab
   */
  _onPanelTabClicked(panel) {
    this._focusPanel(panel);
  }

  /**
   * intialize the expand / collapse button
   */
  _initExpandButton() {
    // this._expandButton = new MainLeaderboardExpandButton();
    // this._expandButton.x = EXPAND_BTN_POS.x;
    // this._expandButton.y = EXPAND_BTN_POS.y;
    // this._panelGroup.addChild(this._expandButton);
    // this._expandButton.signals.onClicked.add(() => {
    //   this._toggleExpandState();
    // });
  }

  /**
   * toggle expand / collapse state
   */
  _toggleExpandState() {
    if (this._locked) return;
    if (this._expanded) {
      this.collapseContent();
    } else {
      this.expandContent();
    }
  }

  /**
   * expand the active content / panel
   */
  expandContent() {
    if (this._expanded) return;
    this._expanded = true;
    this.lock();
    // this._expandButton.setExpandedState();

    // transition panels
    for (const panel of this._panels) {
      panel.transitionToExpandedState(EXPAND_ANIMATION_DURATION, EXPANDED_STATE_FADE_IN_DURATION, COLLAPSED_STATE_FADE_OUT_DURATION, EXPANDED_Y_OFFSET);
    }

    // expand leaderboard
    if (this._expandTween) this._expandTween.stop();
    this._expandTween = game.add.tween(this._panelGroup);
    this._expandTween.to({ y: EXPANDED_Y_OFFSET }, EXPAND_ANIMATION_DURATION, Phaser.Easing.Quadratic.Out, false);
    this._expandTween.onComplete.addOnce(this._onExpandComplete, this);
    this._expandTween.start();

    // transition invite buttons
    this._inviteButtonsPanel.animateToExpandedState(EXPAND_ANIMATION_DURATION, EXPANDED_STATE_FADE_IN_DURATION);

    this._showEventBlocker();
    this.signals.onExpanded.dispatch();
  }

  /**
   * on expand animation complete
   */
  _onExpandComplete() {
    this.unlock();
    for (const panel of this._panels) {
      panel.onExpandComplete();
    }
  }

  /**
   * collapse the active content / panel
   */
  collapseContent() {
    if (!this._expanded) return;
    this._expanded = false;
    this.lock();
    // this._expandButton.setCollapsedState();

    // transition panels
    for (const panel of this._panels) {
      panel.transitionToCollapsedState(COLLAPSE_ANIMATION_DURATION, EXPANDED_STATE_FADE_OUT_DURATION, COLLAPSED_STATE_FADE_IN_DURATION, COLLAPSED_Y_OFFSET);
    }

    // colapse leaderboard
    if (this._expandTween) this._expandTween.stop();
    this._expandTween = game.add.tween(this._panelGroup);
    this._expandTween.to({ y: COLLAPSED_Y_OFFSET }, COLLAPSE_ANIMATION_DURATION, Phaser.Easing.Quadratic.Out, false);
    this._expandTween.onComplete.addOnce(this._onCollapseComplete, this);
    this._expandTween.start();

    // transition invite buttons
    this._inviteButtonsPanel.animateToCollapsedState(COLLAPSE_ANIMATION_DURATION, EXPANDED_STATE_FADE_OUT_DURATION);

    this._hideEventBlocker();
    this.signals.onCollapsed.dispatch();
  }

  /**
   * on collapse animation complete
   */
  _onCollapseComplete() {
    this.unlock();
    for (const panel of this._panels) {
      panel.onCollapseComplete();
    }
  }

  /**
   * check if the leaderboard is expanded
   * @returns {boolean}
   */
  get isExpanded() {
    return this._expanded;
  }

  /**
   * initialize the event blocker layer.
   *
   * externalUIBlocker is a UI blocker that was made outside of the class.
   * Its on a separate layer than this class, and is positioned by external functions.
   * The blocker works as intended though!
   * @param {UI_EventBlocker} [externalUIBlocker]
   */
  _initEventBlocker(externalUIBlocker) {
    this._usingExternalUIBlocker = Boolean(externalUIBlocker);
    if (this._usingExternalUIBlocker) {
      this._eventBlocker = externalUIBlocker;
    } else {
      this._eventBlocker = new UI_EventBlocker();
      this.addChildAt(this._eventBlocker, 0);
    }
  }

  /**
   * show the event blocker
   */
  _showEventBlocker() {
    this._eventBlocker.show(EXPAND_ANIMATION_DURATION);
  }

  /**
   * hide the event blocker
   */
  _hideEventBlocker() {
    this._eventBlocker.hide(COLLAPSE_ANIMATION_DURATION);
  }

  /**
   * 2nd blocker for when the leaderboard is in the collapsed state, events were still going through to the map
   */
  _initCollapsedStateEventBlocker() {
    const eventBlocker = new UI_EventBlocker(GAME_CONTENT_WIDTH, this.height, false);
    eventBlocker.show(0, 0);
    eventBlocker.y = COLLAPSED_STATE_EVENT_BLOCKER_Y;
    this._panelGroup.addChildAt(eventBlocker, 0);
    this._collapsedEventBlocker = eventBlocker;
  }

  /**
   * init the expanded invite button
   */
  _initInviteButtonsPanel() {
    this._inviteButtonsPanel = new MainLeaderboardInviteButtonsPanel();
    this._contentGroup.addChild(this._inviteButtonsPanel);
  }

  /**
   * show the leaderboard, if it was hidden
   */
  show() {
    if (this._hideTween) this._hideTween.stop();
    this._hideTween = game.add.tween(this._contentGroup);
    this._hideTween.to({ y: 0 }, HIDE_ANIMATION_DURATION, Phaser.Easing.Quadratic.Out, false);
    this._hideTween.onComplete.addOnce(this.unlock, this);
    this._hideTween.start();
    this._collapsedEventBlocker.visible = true;
  }

  /**
   * hide the leaderboard offscreen
   * @param {number} (optional) duration milliseconds
   */
  hide() {
    if (this._expanded) this.collapseContent();
    this.lock();
    if (this._hideTween) this._hideTween.stop();
    this._hideTween = game.add.tween(this._contentGroup);
    this._hideTween.to({ y: -COLLAPSED_Y_OFFSET - COLLAPSED_STATE_EVENT_BLOCKER_Y }, HIDE_ANIMATION_DURATION, Phaser.Easing.Quadratic.Out, false);
    this._hideTween.start();
    this._collapsedEventBlocker.visible = false;
  }

  /**
   * hide with no animation
   */
  forceHide() {
    this.lock();
    if (this._hideTween) this._hideTween.stop();
    this._contentGroup.y = -COLLAPSED_Y_OFFSET - COLLAPSED_STATE_EVENT_BLOCKER_Y;
    this._collapsedEventBlocker.visible = false;
  }

  /**
   * open the expanded lives panel
   */
  _openExpandedLivesPanel() {
    this.focusPanelByName('Lives');
    this.expandContent();
  }

  /**
   * lock the expand / contract
   */
  lock() {
    this._locked = true;
  }

  /**
   * unlock the expand / contract
   */
  unlock() {
    this._locked = false;
  }

  /**
   * resize content to fit screen layout
   */
  _onScreenResize() {
    this.y = game.height; // anchor leaderboard to the bottom of the screen
    if (!this._usingExternalUIBlocker) {
      this._eventBlocker.y = -game.height; // realign the event blocker to 0,0
      this._eventBlocker.x = -(game.width - GAME_CONTENT_WIDTH) / 2;
    }
  }

  /**
   * update the panels content expanded %
   */
  _updatePanelsExpandedRatio() {
    const expandRatio = (-this._panelGroup.y + COLLAPSED_Y_OFFSET) / (-EXPANDED_Y_OFFSET + COLLAPSED_Y_OFFSET);
    if (expandRatio === this._lastExpandRatio) return;
    for (const panel of this._panels) panel.updateExpandedRatio(expandRatio);
    this._lastExpandRatio = expandRatio;
  }

  /**
   * main update method
   */
  update() {
    super.update();
    this._updatePanelsExpandedRatio();
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    // stop tweens
    if (this._expandTween) this._expandTween.stop();
    if (this._hideTween) this._hideTween.stop();
    // clear signals
    Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    // remove global events
    this._removeGlobalListeners();
  }

  /**
   * Getters and setters
   */

  /**
   * Returns the width of the leaderboard visual (in expanded size)
   * If theres no focused tab, it attempts to calculate it.
  * @returns {number}
  */
  get width() {
    if (this._focussedPanel) {
      return this._focussedPanel.calculatedBounds.width;
    }
    return PANEL_CONTENT_RECT.width + (PANEL_BG_FILL_PADDING * 2);
  }

  /**
   * Returns the height of the leaderboard visual (in expanded size)
   * If theres no focused tab, it attempts to calculate it.
  * @returns {number}
  */
  get height() {
    if (this._focussedPanel) {
      return this._focussedPanel.calculatedBounds.height;
    }
    return PANEL_CONTENT_RECT.height + (PANEL_BG_FILL_PADDING * 2);
  }

  /**
   * Returns the collapsed height of the panel
   * Returns approximation if none are focused
   * @returns {number}
   */
  get collapsedHeight() {
    if (this._focussedPanel && this._focussedPanel.collapsedHeight) {
      return this._focussedPanel.collapsedHeight;
    }
    return FRIENDS_PANEL_COLLAPSED_OVERLAY_BG_RECT.height;
  }
}
