import GingyCharacterTutorial, { CHARACTER_KEYS } from '../../../GingyTutorial/G.GingyCharacterTutorial';
import { GameModePromo_TutorialTextBase } from './GameModePromo_TutorialTextBase';

const TEXT_STYLE = { style: 'ui-tournament-promo', fontSize: 45 };
const MASCOT_CENTER_OFFSET_X = -150;

/**
 * Class for displaying horizontal tutorial bar with mascot for tournament.
 * Currently, the daily challenge promo also uses this class
 */
export class TreasureHuntPromo_Text extends GameModePromo_TutorialTextBase {
  /**
   * constructor
   */
  constructor() {
    super('tutorial_bar', TEXT_STYLE);
  }

  /**
   * init the tutorial mascot
   */
  _initTutorialMascot() {
    const mascot = G.saveState.treasureHuntManager.currentMascot;
    const mascotName = G.OMTsettings.treasureHuntSuper.mascotOrder[mascot];
    this._gingy = new GingyCharacterTutorial(0, 0, CHARACTER_KEYS[mascotName]);
    this.addChild(this._gingy);
    this._gingy.x = MASCOT_CENTER_OFFSET_X;
    this._gingy.y = this._textBgBox.y - this._gingy.height / 2 + G.OMTsettings.treasureHuntSuper.promo.mascotOffset[mascotName].y;
  }
}
