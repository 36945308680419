import UI_GoalPanelPointsBase from '../base/UI_GoalPanelPointsBase';

export default class UI_GoalPanelPointsHorizontal extends UI_GoalPanelPointsBase {
  _drawEverything(gameMode) {
    const { label } = this._getTextStyles(gameMode);
    this._labelTxt = new G.Text(0, 0, `${G.lvlData.goal[1]}`, label, [0.5, 0], 150, null, true, 'center');
    const pointsTxt = new G.Text(0, -50, `${G.capitalize(OMT.language.getText('points'))}:`, label, 0.5, 300);
    this.add(pointsTxt);
    super._drawEverything();
    this.add(this._labelTxt);
  }

  /**
   * Repositions layout
   */
  _onScreenResize() {
    // Don't do anything
  }
}
