/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable func-names */
/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
if (typeof G === 'undefined') G = {};

// eslint-disable-next-line no-unused-vars
G.GridArray = function (width, height, value, dbg) {
  if (typeof width === 'number') {
    this.createGrid.apply(this, arguments);
  } else if (typeof width === 'string') {
    this.data = JSON.parse(arguments[0]);
    this.width = this.data.length;
    this.height = this.data[0].length;
  } else if (Array.isArray(width)) {
    this.data = arguments[0];
    this.width = this.data.length;
    this.height = this.data[0].length;
  }
};

G.GridArray.prototype = {
  createGrid(width, height, value) {
    this.data = [];
    this.width = width;
    this.height = height;

    for (let collumn = 0; collumn < width; collumn++) {
      this.data[collumn] = [];
      for (let row = 0; row < height; row++) {
        this.data[collumn][row] = value;
      }
    }
  },

  set(x, y, val) {
    if (this.isInGrid(x, y)) {
      return (this.data[x][y] = val);
    }
    if (this.dbg) console.log('setValue OUT OF RANGE');
    return false;
  },

  get(x, y) {
    if (this.isInGrid(x, y)) {
      return this.data[x][y];
    }
    if (this.dbg) console.log('getValue OUT OF RANGE');
    return false;
  },

  swapValues(x1, y1, x2, y2) {
    if (this.isInGrid(x1, y1) && this.isInGrid(x2, y2)) {
      const tmp = this.data[x1][y1];
      this.data[x1][y1] = this.data[x2][y2];
      this.data[x2][y2] = tmp;
    } else {
      if (this.dbg) console.log('swapValues OUT OF RANGE');
      return false;
    }
  },

  isInGrid(x, y) {
    return !(x < 0 || x >= this.width || y < 0 || y >= this.height);
  },

  find(func, context) {
    for (let coll = 0; coll < this.width; coll++) {
      for (let row = 0; row < this.height; row++) {
        const val = func.call(
          context,
          this.data[coll][row],
          coll,
          row,
          this.data,
        );
        if (val) return this.data[coll][row];
      }
    }

    return false;
  },

  filter(func, context) {
    const result = [];

    for (let coll = 0; coll < this.width; coll++) {
      for (let row = 0; row < this.height; row++) {
        const val = func.call(
          context,
          this.data[coll][row],
          coll,
          row,
          this.data,
        );
        if (val) result.push(this.data[coll][row]);
      }
    }

    return result;
  },

  loop(func, context) {
    for (let coll = 0; coll < this.width; coll++) {
      for (let row = 0; row < this.height; row++) {
        func.call(context, this.data[coll][row], coll, row, this.data);
      }
    }
  },

  clear(value) {
    this.loop((elem, x, y, array) => {
      array[x][y] = value || false;
    });
  },

  findPattern(positions, mark) {
    let result = false;
    const len = positions.length;

    this.loop(function (elem, x, y, array) {
      if (elem == mark && !result) {
        for (let i = 0; i < len; i += 2) {
          // console.log('pos: '+(x+positions[i])+'x'+(y+positions[i+1])+' val: ' + this.get(x+positions[i],y+positions[i+1]));
          if (!this.get(x + positions[i], y + positions[i + 1])) return;
          if (this.get(x + positions[i], y + positions[i + 1]) !== mark) return;
        }

        // console.log("PASSED FIRST LOOP "+x+'x'+y);
        result = [];
        for (let j = 0; j < len; j += 2) {
          result.push(x + positions[j], y + positions[j + 1]);
        }
        // console.log('got patt: ');
        // console.log(x+'x'+y);
        // console.log(result);
      }
    }, this);

    return result;
  },

  count() {
    let result = 0;

    for (let coll = 0; coll < this.width; coll++) {
      for (let row = 0; row < this.height; row++) {
        if (this.data[coll][row]) {
          result++;
        }
      }
    }

    return result;
  },

  getAllElements() {
    const result = [];

    for (let coll = 0; coll < this.width; coll++) {
      for (let row = 0; row < this.height; row++) {
        if (this.data[coll][row]) {
          result.push(this.data[coll][row]);
        }
      }
    }

    return result;
  },

  destroy() {
    // clearing this data was calling issues with callback
    /*
    for (let coll = 0; coll < this.width; coll++) {
      this.data[coll].length = 0;
      this.data[coll] = null;
    }
    this.data.length = 0;
    this.data = null;
    */
  },
};
