/**
 * Controls all board input, including swaps, and gem feedback when the player hovers over or taps a gem.
 */
export class InputController extends Phaser.Group {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    super(game);

    this._board = board;

    this._clicked = false;
    this._clickedCell = null;
    this._anyWindowOpen = false;
    this._firstTouchMade = false;

    this._possibleCandies = [];
    this._locked = false;

    this._addGlobalListeners();
  }

  /**
   * set global event listeners
   */
  _addGlobalListeners() {
    this._signalBindings = [
      G.sb('onWindowOpened').add(() => { this._anyWindowOpen = true; }, this),
      G.sb('onAllWindowsClosed').add(() => { this._anyWindowOpen = false; }, this),
      game.input.onUp.add(() => { this._clicked = false; }, this),
    ];
    // click action not active for editor
    const { lvlDataManager } = this._board;
    if (!lvlDataManager.isEditor) this._signalBindings.push(game.input.onDown.add(this._onClick, this));
  }

  /**
   * remove global listeners set by _addGlobalListeners()
   */
  _removeGlobalListeners() {
    for (const signalBinding of this._signalBindings) signalBinding.detach();
    this._signalBindings.length = 0;
  }

  /**
   * Stop all tweens on the selected gem.
   */
  stopTweens() {
    if (this._selectTween) {
      this._selectTween.stop();
      this._popTween.stop();
      this._selectTween.target.set(1);
    }
  }

  /**
   * Checks if the two cells are neighbours, then checks if theres a wall between them
   * @param {Array<number>} cellA
   * @param {Array<number} cellB
   * @returns {boolean}
   */
  canMoveHappen(cellA, cellB) {
    const neighbour = this._areNeighbours(cellA, cellB);
    if (!neighbour) { return false; }
    return this._board.walls.canMoveHappen(cellA, cellB);
  }

  /**
   * Runs every frame. Used to give feedback when the player is hovered over a cell.
   */
  update() {
    this._board.tileShade.visible = false;

    const over = this._pointerToCell2(game.input.activePointer);
    const { lvlDataManager } = this._board;

    // show highlight of tile on desktop
    if (this._board.gameHooks.deviceIsDesktop && !lvlDataManager.goalAchieved && this._board.isCellOnBoard(over[0], over[1])) {
      this._board.tileShade.visible = true;
      this._board.tileShade.x = this._board.cellXToPxIn(over[0]);
      this._board.tileShade.y = this._board.cellYToPxIn(over[1]);
    }

    if (!this._canMakeMove()) return;

    if (this._clicked) {
      // Check if possible candies to click are limited / set by a tutorial
      if (this._isTutorialBlockingClick(this._clickedCell) || this._isTutorialBlockingClick(over)) return;

      if (over && this._board.isMoveable(over[0], over[1]) && this._areNeighbours(this._clickedCell, over) && this._board.getCandy(over[0], over[1])) {
        this.stopTweens();
        this._board.makeMove(this._board.getCandy(this._clickedCell[0], this._clickedCell[1]), this._board.getCandy(over[0], over[1]));
        this._clicked = false;
        this._clickedCell = null;
      }
    }
  }

  /**
   * check if a tutorial is blocking a click action.
   * @param {Array} cell [x,y]
   * @returns {boolean}
   */
  _isTutorialBlockingClick(cell) {
    if (this._possibleCandies.length === 0) return false;
    const blocked = this._possibleCandies.indexOf(this._board.getCandy(cell[0], cell[1])) === -1;
    return blocked;
  }

  /**
   * Wether or not we can currently make a swap on the board
   * @returns boolean
   */
  _canMakeMove() {
    if (this._locked) return false;
    if (this._board.actionManager.hasActiveAction) return false;
    if (this._board.lvlDataManager.goalAchieved) return false;
    if (this._anyWindowOpen) return false;
    return true;
  }

  /**
   * When the player clicks or taps a gem.
   * @param {object} pointer The pointer object, can represent a mouse or tap.
   */
  _onClick(pointer) {
    if (!this._canMakeMove()) return;

    const cell = this.pointerToCell(pointer);
    if (!cell || !this._board.isMoveable(cell[0], cell[1])) return;
    const candy = this._board.getCandy(cell[0], cell[1]);
    if (candy) {
      // track the first gem interaction on the first level
      const { lvlDataManager } = this._board;
      if (lvlDataManager.lvlIndex === 0 && !this._firstTouchMade) {
        this._firstTouchMade = true;
        this._board.gameHooks.logFTUXEvent(0, null, 'FTUFirstInteractionWithGems');
      }

      this._board.gameHooks.playSound('pop');
      this.stopTweens();
      this._board.selectShade.visible = false;
      if (this._clickedCell) {
        if (Math.abs(this._clickedCell[0] - cell[0]) + Math.abs(this._clickedCell[1] - cell[1]) === 1) {
          if (this._possibleCandies.length > 0) {
            // dont allow click if tutorial is blocking this cell
            if (!this._isTutorialBlockingClick(this._clickedCell) && !this._isTutorialBlockingClick(cell)) {
              this._board.makeMove(this._board.getCandy(this._clickedCell[0], this._clickedCell[1]), this._board.getCandy(cell[0], cell[1]));
              this._clickedCell = null;
              this._clicked = false;
              return;
            }
          } else {
            this._board.makeMove(this._board.getCandy(this._clickedCell[0], this._clickedCell[1]), this._board.getCandy(cell[0], cell[1]));
            this._clickedCell = null;
            this._clicked = false;
            return;
          }
        }
      }
      // if we havent selected anything, or the thing we're clicking isnt a neighbour of the selected cell, select the cell we clicked.
      // 'isnt a neighbour' in this case means either the cell we're clicking is somewhere else on the board, or we're clicking on the selected cell itself
      if ((!this._clickedCell || !this._areNeighbours(this._clickedCell, cell)) && !this._isTutorialBlockingClick(cell)) {
        this._setCandySelectAnimation(candy, cell);
      }
      this._clicked = true;
      this._clickedCell = cell;
    }
  }

  /**
   * set the candy selected loop animation
   * @param {Candy} candy
   * @param {Array} cell [x,y]
   */
  _setCandySelectAnimation(candy, cell) {
    this._selectTween = game.add.tween(candy.scale).to({ x: 1.15, y: 1.15 }, 220, null, false, 0, -1, true);
    this._popTween = game.add.tween(candy.scale).to({ x: 1.23, y: 1.23 }, 110, null, true, 0, 0, true);
    this._popTween.chain(this._selectTween);
    this._board.selectShade.visible = true;
    this._board.selectShade.x = this._board.cellXToPxIn(cell[0]);
    this._board.selectShade.y = this._board.cellYToPxIn(cell[1]);
  }

  /**
   * Converts the pointer position to a board grid position.
   *
   * @param {Object} pointer The pointer object, can represent a mouse or tap.
   * @returns If the pointer is inside the board, an array containing the x and y position of the grid cell. Otherwise false.
   */
  pointerToCell(pointer) {
    if (this._anyWindowOpen) return false;

    const xx = pointer.worldX;
    const yy = pointer.worldY;

    if (this._isPointerInRange(pointer)) {
      return [
        Math.floor((xx - (this._board.x + this._board.offsetX)) / (this._board.tileSize * this._board.scale.x)),
        Math.floor((yy - (this._board.y + this._board.offsetY)) / (this._board.tileSize * this._board.scale.y)),
      ];
    }
    return false;
  }

  /**
   * Converts the pointer position to a board grid position.
   * @param {Object} pointer The pointer object, can represent a mouse or tap
   * @returns An array containing the x and y positions of the grid cell.
   */
  _pointerToCell2(pointer) {
    const xx = pointer.worldX;
    const yy = pointer.worldY;

    return [
      Math.floor((xx - (this._board.x + this._board.offsetX)) / (this._board.tileSize * this._board.scale.x)),
      Math.floor((yy - (this._board.y + this._board.offsetY)) / (this._board.tileSize * this._board.scale.y)),
    ];
  }

  /**
   * Determines whether the pointer is on the board.
   * @param {object} pointer The pointer object. Can represent a mouse or tap.
   */
  _isPointerInRange(pointer) {
    const x = pointer.worldX;
    const y = pointer.worldY;
    return !(x < this._board.x + this._board.offsetX || x > this._board.x + this._board.offsetX + this._board.width
      || y < this._board.y + this._board.offsetY || y > this._board.y + this._board.offsetY + this._board.height);
  }

  /**
   * Determines whether cell1 and cell2 are neighbours. Only checks up/down/left/right, no diagonals.
   * @param {Array<number>} cell1 Cell 1.
   * @param {Array<number>} cell2 Cell 2.
   */
  _areNeighbours(cell1, cell2) {
    if (cell1[0] === cell2[0]) return Math.abs(cell1[1] - cell2[1]) === 1;
    if (cell1[1] === cell2[1]) return Math.abs(cell1[0] - cell2[0]) === 1;
    return false;
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._removeGlobalListeners();

    this._board = null;
    this._possibleCandies.length = 0;
  }

  /** GETTER / SETTER METHODS ********************************* */

  /** @returns {boolean} get locked status  */
  get locked() { return this._locked; }

  /** @param value set locked status  */
  set locked(value) { this._locked = value; }

  /** @returns {Array.<Candy>} get list of possible candies */
  get possibleCandies() { return this._possibleCandies; }

  /** @param {Array.<Candy>} candyList set by tutorial to limit possible candies clicked  */
  set possibleCandies(candyList) { this._possibleCandies = candyList; }
}
