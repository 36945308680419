import UI_ProgressBar from './UI_ProgressBar';
import UI_PointsCounter from './UI_PointsCounter';
import UI_GoalPanelCollect from './UI_GoalPanelCollect';
import UI_GoalPanelPoints from './UI_GoalPanelPoints';
import UI_GoalTournament from './UI_GoalTournament';
import OMT_VILLAINS from '../../../../../OMT_UI/OMT_Villains';
import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import UI_TopBarBase from '../base/UI_TopBarBase';
import { PROGRESS_MARKER_TYPE } from '../base/UI_ProgressBarUtils';

export default class UI_TopBar extends UI_TopBarBase {
  /**
   * Returns the moves group for positioning purposes
   * @returns {G.Text}
   */
  get movesTxt() {
    return this._movesTxt;
  }

  /**
   * creat the goal panel
   * @param {string} goalType
   */
  _createGoalLayout(levelData, gameMode) {
    super._createGoalLayout(levelData, gameMode);
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const {
      isTournamentLevel,
      isTokenEventLevel,
      isDailyChallengeLevel,
      isTreasureHuntLevel,
    } = LvlDataManager.getInstance();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;

    // set the background type
    let topBarBackgroundTexture = 'gameplay_top_bar_background_normal';
    if (isTournamentLevel) {
      topBarBackgroundTexture = 'gameplay_top_bar_background_tournament';
    } else if (isNotNormalLevel) {
      topBarBackgroundTexture = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_header_topbar') : OMT_VILLAINS.getPrefixedName('hard_header_topbar');
    } else if (isTokenEventLevel) {
      topBarBackgroundTexture = 'tokenEvent_gameplay_top_bar_background';
    } else if (isTreasureHuntLevel) {
      topBarBackgroundTexture = 'gameplay_top_bar_background_treasureHunt';
    }
    this._createGameBG(topBarBackgroundTexture);

    // create moves display / counter
    this._createMovesGroup();

    // progress bar + points counter
    // Don't track stars in event level
    let markerType = PROGRESS_MARKER_TYPE.STARS;
    if (isTokenEventLevel || isTreasureHuntLevel) markerType = PROGRESS_MARKER_TYPE.NONE;
    if (isDailyChallengeLevel) markerType = PROGRESS_MARKER_TYPE.COINS;

    this.progressBar = new UI_ProgressBar(132, 110, markerType); // Accessed externally
    const uiPointsTextStyle = {
      points: G.OMTsettings.elements.UI_PointsCounter.text.style,
      amount: G.OMTsettings.elements.UI_PointsCounter.text.style,
    };
    if (isTreasureHuntLevel) {
      uiPointsTextStyle.points = 'UIPointsLabel-treasureHunt';
      uiPointsTextStyle.amount = 'UIPointsLabel-treasureHunt';
    }
    this.pointsCounter = new UI_PointsCounter(405, 105, uiPointsTextStyle); // Accessed externally

    if (isTournamentLevel) { // tournament play
      this.goalPanel = new UI_GoalTournament(410, 47); // Collecting points for tournament
      // hide the points counter and the progress bar
      this.progressBar.alpha = 0;
      this.pointsCounter.alpha = 0;
      // offset points counter so scores animate to the correct position
      this.pointsCounter.y -= 50;
      // add tournament banner / streamer graphics
      this._createTournamentStreamer();
    } else if (levelData.goal[0] === 'collect') {
      this.goalPanel = new UI_GoalPanelCollect(410, 50); // Collecting gems
    } else {
      this.goalPanel = new UI_GoalPanelPoints(410, 50); // Collecting points
    }

    this.addChild(this.progressBar);
    this.addChild(this.goalPanel);
    this.addChild(this.pointsCounter);
  }

  /**
   * init the bg graphics
   * @param {string} assetFrame
   */
  _createGameBG(assetFrame) {
    super._createGameBG();
    this.bg = G.makeImage( // Accessed externally
      320, -2,
      assetFrame,
      [0.5, 0],
      this,
    );

    this._bgWidth = this.bg.width;
  }

  /**
   * create tournament banner / streamer image
   */
  _createTournamentStreamer() {
    super._createTournamentStreamer();
    this._tournamentBanner = G.makeImage(
      320, 120,
      'streamer_tournament',
      [0.5, 0],
      this,
    );
  }

  /**
   * create the moves display group
   */
  _createMovesGroup(gameMode) {
    super._createMovesGroup(gameMode);
    this._movesGroup = new Phaser.Group(game);
    this._movesGroup.x = 130;
    this._movesGroup.y = 75;
    this.add(this._movesGroup);

    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const {
      isTournamentLevel,
      isTokenEventLevel,
      isDailyChallengeLevel,
      isTreasureHuntLevel,
    } = LvlDataManager.getInstance();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;

    let movesTxtStyle = 'UITopMovesLabel-normal';
    let movesAmountTxtStyle = 'UITopMovesAmount-normal';
    if (isNotNormalLevel) {
      movesTxtStyle = {
        style: useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_2') : OMT_VILLAINS.getPrefixedName('hard_2'),
        fontSize: 22,
      };
      movesAmountTxtStyle = {
        style: useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_2') : OMT_VILLAINS.getPrefixedName('hard_2'),
        fontSize: 28,
      };
    }

    if (isTokenEventLevel) {
      this._movesTxt = new G.Text(0, 5, OMT.language.getText('Moves'), 'UITopMovesLabel-tokenEvent', [0.5, 0], 160);
      this._movesAmountTxt = new G.Text(0, 25, this._gameplayController.moves, 'UITopMovesAmount-tokenEvent', [0.5, 0], 160);
    } else if (isTournamentLevel) { // tournament play
      this._movesTxt = new G.Text(0, 5, OMT.language.getText('Moves'), 'UITopMovesLabel-tournament', [0.5, 0], 160);
      this._movesAmountTxt = new G.Text(0, -42, this._gameplayController.moves, 'UITopMovesAmount-tournament', [0.5, 0], 160);
    } else if (isTreasureHuntLevel) {
      this._movesTxt = new G.Text(0, 0, OMT.language.getText('Moves'), 'UITopMovesLabel-treasureHunt', [0.5, 0], 160);
      this._movesAmountTxt = new G.Text(0, 23, this._gameplayController.moves, 'UITopMovesAmount-treasureHunt', [0.5, 0], 160);
    } else { // normal or daily challenge
      // Accessed externally
      this._movesTxt = new G.Text(0, 0, OMT.language.getText('Moves'), movesTxtStyle, [0.5, 0], 160);
      // moves counter
      this._movesAmountTxt = new G.Text(0, 25, this._gameplayController.moves, movesAmountTxtStyle, [0.5, 0], 160);
    }

    this._movesGroup.addChild(this._movesTxt);
    this._movesGroup.addChild(this._movesAmountTxt);
  }

  /**
   * Has a resize function but it doesn't do anything...
   */
  _onScreenResize() {
    super._onScreenResize();
    if (this._tournamentBanner) {
      this._tournamentBanner.visible = game.width === 640 && game.height >= 1080;
    }
    if (!FBInstant.deviceDectorFunctions().isMobileOrTab()) {
      this.x = (game.width - this._bgWidth) / 2;
      this.bg.x = (game.width - this._bgWidth) - 18;
    }
  }

  /**
   * Hides the hand
   */
  hideSuggestion() {
    if (this._hand) {
      this._hand.visible = false;
    }
  }
}
