import { Window_Generic, GENERIC_WINDOW_STYLE } from './Window_Generic';

/**
 * notification window for ask a spin ad fallback. Uses generic window.
 */
class Window_BotRewardExpired extends Window_Generic {
  /**
   * constructor
   * @param {} parent
   */
  constructor(parent, expiryTimeInMinutes) {
    // here, we would like to create a prop and assign it to expiryTimeInMinutes, but
    // this class calls the function its needed in, in the super.  meaning the prop would be
    // undefined when we needed it.  so instead we pass it to the super, and assign it later.
    super(parent, { expiryTimeInMinutes });
  }

  /**
   * apply the generic config
   */
  applyGenericConfig(supposedConfig) {
    // here is where we assign the variable, for use in the next function
    this.expiryTimeInMinutes = supposedConfig.expiryTimeInMinutes;
    const config = this._getGenericWindowConfig();
    super.applyGenericConfig(config);
  }

  /**
   * get window configuration
   * @returns {Object}
   */
  _getGenericWindowConfig() {
    // here we use the variable mentioned above to show what the expiry time is for this bot gift.
    this.txt = OMT.language.getText('Reward expired. Please claim your rewards every X hours');
    this.txt = this.txt.replace('X', Math.round(this.expiryTimeInMinutes / 60));
    const config = {
      windowStyle: GENERIC_WINDOW_STYLE,
      template: {
        title: {
          text: OMT.language.getText('Expired Reward'),
          textStyle: { style: 'font-white', fontSize: 40 },
        },

        text: {
          text: this.txt,
          textStyle: 'font-blue',
          boundaries: new Phaser.Rectangle(0, 70, 500, 500),
        },
        close: {
          onClick: this._onCloseClicked.bind(this),
        },
      },
    };
    return config;
  }

  /**
   * close button clicked
   */
  _onCloseClicked() {
    // not doing anything yet
  }

  /**
   * button1 button clicked
   */
  _onButton1Clicked() {
    this.closeWindow(() => {
    });
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.BotRewardExpired = Window_BotRewardExpired;
