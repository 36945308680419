const MULTIPLIER_TEXT = 'x3';
const MULTIPLIER_TEXT_ANGLE = 30;
const MULTIPLIER_BASE_TINT = 0xff1c9f;

/**
 * class for layered visuals for TargetedOffer multiplier display.
 */
export class TargetedOffer_MultiplierGroup extends Phaser.Group {
  /**
   * constructor
   * @param {Object} multiConfig
   * @param {number} multiConfig.bgScale
   * @param {Object} multiConfig.txt
   * @param {number} multiConfig.txt.textOffsetX
   * @param {number} multiConfig.txt.textOffsetY
   * @param {string} multiConfig.altBgAsset
   */
  constructor(multiConfig) {
    super(game);

    const { bgScale, altBgAsset } = multiConfig;
    const { offsetX: textOffsetX, offsetY: textOffsetY, content: textContent } = multiConfig.txt;

    this._drawLayers(bgScale, textOffsetX, textOffsetY, altBgAsset, textContent);

    this.cacheAsBitmap = true;
  }

  /**
   * draw the layers
   * @param {number} bgScale scale of background element
   * @param {number} textOffsetX
   * @param {number} textOffsetY
   * @param {string} altBgAsset (optional)
   * @param {string} textContent (optional)
   */
  _drawLayers(bgScale, textOffsetX, textOffsetY, altBgAsset = null, textContent = MULTIPLIER_TEXT) {
    if (!altBgAsset) { // default layered graphic
      const multiplierBacking = G.makeImage(0, 0, 'shop3_multiplierBg', 0.5, this);
      multiplierBacking.scale.set(bgScale);

      const multiplierBase = G.makeImage(0, 0, 'shop3_multiplierBase', 0.5, this);
      multiplierBase.scale.set(bgScale);

      const multiplierBaseTinted = G.makeImage(0, 0, 'shop3_multiplierBase', 0.5, this);
      multiplierBaseTinted.tint = MULTIPLIER_BASE_TINT;
      multiplierBaseTinted.scale.set(bgScale);

      const multiplierHighlight = G.makeImage(0, 0, 'shop3_multiplierHighlight', 0.5, this);
      multiplierHighlight.scale.set(bgScale);
    } else { // alt single bg asset was passed
      const multiplierBacking = G.makeImage(0, 0, altBgAsset, 0.5, this);
      multiplierBacking.scale.set(bgScale);
    }

    const multiplierTxtGroup = new Phaser.Group(game, this);
    const multiplierTxt = new G.Text(0, 0, textContent, 'window_popupOffer-multiplierTxt', 0.5, 0, 0, false, 'center');

    multiplierTxtGroup.x = textOffsetX;
    multiplierTxtGroup.y = textOffsetY;
    multiplierTxtGroup.add(multiplierTxt);
    multiplierTxtGroup.angle = MULTIPLIER_TEXT_ANGLE;
  }
}
