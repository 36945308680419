/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import GingyCharacterTutorial, {
  CHARACTER_KEYS,
} from '../Elements/GingyTutorial/G.GingyCharacterTutorial';
import OMT_TweenUtils from '../Utils/Animation/OMT_TweenUtils';
import OMT_StackManager from '../Utils/OMT_StackManager';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import OMT_VILLAINS from './OMT_Villains';
import VillainsBaseClass from './Villains/VillainsBaseClass';
import VillainsBaseContainer from './Villains/VillainsBaseContainer';
import { ORIENTATION } from '../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../States/Scaling/GameScaleController';

export default class OMT_GingySpeechTutorial extends VillainsBaseClass {
  constructor(parent, text, offset) {
    super(parent);

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this.parent = parent;
    this.config = {
      offset,
    };
    this.objectPrefix = '_gingy';

    const classContainer = new VillainsBaseContainer(this);
    this._gingy_container = {
      type: 'group',
      object: classContainer,
    };

    const gingy = new GingyCharacterTutorial(0, 0, CHARACTER_KEYS.gingy);
    classContainer.add(gingy);
    this._gingy_character = {
      type: 'gingy',
      object: gingy,
    };
    this.gingy = gingy;

    const bubbleContainer = new Phaser.Group(game);
    this._gingy_bubble_container = {
      type: 'group_bubble',
      object: bubbleContainer,
    };
    this.bubbleContainer = bubbleContainer;

    const bubbleSlice =
      G.OMTsettings.elements.superhard.speech_bubble.gingy_bubble;
    const speechBubble = new UI_NineSlice(
      0,
      0,
      OMT_VILLAINS.getPrefixedName('gingy_bubble'),
      1210,
      310,
      bubbleSlice,
    );
    speechBubble.anchor.setTo(0.5);
    speechBubble.scale.setTo(0.4);
    bubbleContainer.add(speechBubble);
    this._gingy_speechBubble = {
      type: 'speechBubble',
      object: speechBubble,
    };
    this.speechBubble = speechBubble;

    const bubble_text = new G.Text(
      speechBubble.x + speechBubble.width * G.OMTsettings.elements.superhard.gingyBubble.offsetX,
      speechBubble.y,
      OMT.language.getText(text),
      `${OMT_VILLAINS.getPrefixedName('villain_2')}_gingy`,
      0.5,
      speechBubble.width * 0.9,
      speechBubble.height * 0.75,
      true,
      'center',
      false,
      true,
      true,
    );
    if (this._isLandscape) {
      bubble_text.y -= 50;
    }
    bubbleContainer.add(bubble_text);
    this._gingy_bubble_text = {
      type: 'text',
      object: bubble_text,
    };

    classContainer.add(bubbleContainer);
    if (this._isLandscape) {
      classContainer.scale.setTo(this._gameScale);
    }

    this.init();

    parent.add(classContainer);
  }

  /**
   * Reinitializes the class with the default values
   */
  async init() {
    const { gingy, bubbleContainer, speechBubble } = this;

    gingy.alpha = 0;
    gingy.scale.setTo(0.75);
    bubbleContainer.alpha = 0;
    speechBubble.scale.setTo(0.4);

    this.positionBubbleContainer();

    gingy.x = -speechBubble.width * 0.5;
    gingy.y = gingy.body.height * 0.25;

    if (this._isLandscape) {
      gingy.y -= 50;
      bubbleContainer.y -= 50;
      speechBubble.y -= 50;
    }
  }

  /**
   * Position / reposition / tween the bubble container based on the latest values
   */
  async positionBubbleContainer() {
    const { config, speechBubble, bubbleContainer } = this;
    const { offset } = config;
    const bubbleHorizontalOffset = speechBubble.width * 0.12;
    bubbleContainer.x = bubbleHorizontalOffset;
    bubbleContainer.y = -speechBubble.height * 0.135;
    const stack = OMT_StackManager.getFreeStack();
    if (offset) {
      const { x, y } = offset;
      let targetX = bubbleContainer.x;
      let targetY = bubbleContainer.y;
      if (x !== undefined) {
        targetX += x;
      }
      if (y !== undefined) {
        targetY += y;
      }
      stack.addPromise(() =>
        OMT_TweenUtils.tweenMultiple({
          object: bubbleContainer,
          duration: 200,
          props: {
            x: targetX,
            y: targetY,
          },
        }),
      );
    }
    return stack.run();
  }

  /**
   * Shows the tutorial
   */
  async show() {
    const gingy = this.getObjects({ type: 'gingy' })[0];
    const speechBubble = this.getObjects({ type: 'group_bubble' })[0];

    const stack = OMT_StackManager.getFreeStack();

    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: gingy,
        alpha: 1,
        duration: 300,
      }),
    );
    stack.addEvent(() => {
      gingy.talk();
    });
    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: speechBubble,
        alpha: 1,
        duration: 300,
      }),
    );
    stack.addEvent(() => {
      gingy.idle();
    });

    return stack.run();
  }

  /**
   * Hides the tutorial
   */
  async hide() {
    const gingy = this.getObjects({ type: 'gingy' })[0];
    const speechBubble = this.getObjects({ type: 'group_bubble' })[0];

    const stack = OMT_StackManager.getFreeStack();

    const subStack_1 = OMT_StackManager.getFreeStack();
    subStack_1.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: gingy,
        alpha: 0,
        duration: 300,
      }),
    );
    const subStack_2 = OMT_StackManager.getFreeStack();
    subStack_2.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: speechBubble,
        alpha: 0,
        duration: 300,
      }),
    );

    stack.addParallel([subStack_1, subStack_2]);
    return stack.run();
  }

  /**
   * Hides the speech bubble
   */
  async hideBubble() {
    const speechBubble = this.getObjects({ type: 'group_bubble' })[0];

    const stack = OMT_StackManager.getFreeStack();

    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: speechBubble,
        alpha: 0,
        duration: 300,
      }),
    );

    return stack.run();
  }

  /**
   * Sets the text with animation
   * @param {string} text The text to change for the speech bubble
   */
  async setText(text) {
    this.setTextSync(text);
    const gingy = this.getObjects({ type: 'gingy' })[0];
    const bubbleText = this.getObjects({ type: 'text' })[0];

    bubbleText.alpha = 0;
    const stack = OMT_StackManager.getFreeStack();

    stack.addEvent(() => {
      gingy.talk();
    });
    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: bubbleText,
        alpha: 1,
        duration: 300,
      }),
    );
    stack.wait(500);
    stack.addEvent(() => {
      gingy.idle();
    });

    return stack.run();
  }

  /**
   * Directly sets the text of the speech bubble
   * @param {string} text The text to change for the speech bubble
   */
  setTextSync(text) {
    const { bubbleContainer } = this;
    const speechBubble = this.getObjects({ type: 'speechBubble' })[0];
    const bubbleText = this.getObjects({ type: 'text' })[0];

    bubbleText.destroy();
    const bubble_text = new G.Text(
      speechBubble.x + speechBubble.width * G.OMTsettings.elements.superhard.gingyBubble.offsetX,
      speechBubble.y,
      OMT.language.getText(text),
      `${OMT_VILLAINS.getPrefixedName('villain_2')}_gingy`,
      0.5,
      speechBubble.width * 0.9,
      speechBubble.height * 0.75,
      true,
      'center',
      false,
      true,
      true,
    );
    bubbleContainer.add(bubble_text);
    this._gingy_bubble_text = {
      type: 'text',
      object: bubble_text,
    };
  }
}
