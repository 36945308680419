/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
G.MuteReason = {
  UserChoice: 'userChoice',
  AppPaused: 'appPaused',
};

G.createSoundManager = function createSoundManager(getAudioMutedFunc, setAudioMutedFunc) {
  const fxMuted = new G.Reasons(((mute) => { updateAudioMuted(); }));
  const musicMuted = new G.Reasons(((mute) => { updateMusicMuted(); updateAudioMuted(); }));
  let pauseLocked = false;

  G.phaserStageVisibilityChanged.add((paused) => { paused ? pause() : resume(); });

  return {
    pause,
    resume,
    getSoundEnabled,
    setSoundEnabled,
    getMusicEnabled,
    setMusicEnabled,
    wrapAudioElement,
  };

  function pause(lock = false) {
    if (pauseLocked) return;
    pauseLocked = lock;
    setSoundEnabled(G.MuteReason.AppPaused, false);
    setMusicEnabled(G.MuteReason.AppPaused, false);
  }

  function resume(unlock = false) {
    if (pauseLocked && !unlock) return;
    pauseLocked = false;
    setSoundEnabled(G.MuteReason.AppPaused, true);
    setMusicEnabled(G.MuteReason.AppPaused, true);
  }

  function getSoundEnabled() { return !fxMuted.hasAny(); }
  function setSoundEnabled(reason, enabled) { fxMuted.setPresence(reason, !enabled); }

  function getMusicEnabled() { return !musicMuted.hasAny(); }
  function setMusicEnabled(reason, enabled) { musicMuted.setPresence(reason, !enabled); }

  function updateMusicMuted() {
    if (G.music) {
      musicMuted.hasAny() ? G.music.pause() : G.music.resume();
    }
  }

  function updateAudioMuted() {
    const currentMuted = getAudioMutedFunc();
    const newMuted = !(getSoundEnabled() || getMusicEnabled());
    if (currentMuted !== newMuted) {
      setAudioMutedFunc(newMuted);
    }
  }

  //

  function wrapAudioElement(audio, soundKey, audiosprite) {
    const wrappedAudio = Object.create(audio);
    if (soundKey === 'music') {
      audio.loop = true;
    }
    wrappedAudio.play = wrappedPlay;
    return wrappedAudio;

    function wrappedPlay(volume = 1) {
      if (soundKey === 'music') {
        audio.play(); // Need to instaniate...
        if (!getMusicEnabled()) {
          audio.pause();
        }
      } else if (getSoundEnabled()) { // Not music. Must be sfx
        audiosprite.play(soundKey, volume);
      }
    }
  }
};
