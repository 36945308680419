import { ORIENTATION } from '@omt-game-board/Managers/GameEnums';

/**
 * Class for cascade skip button
 */
export class CascadeSkipButton extends Phaser.Group {
  /**
   * constructor
   * @param {Board} board reference to board
   * @param {Object} config
   * @param {string} config.text button label text
   * @param {string} config.style button label style
   * @param {ORIENTATION} config.orientation orientation of game
   * @param {boolean} config.hasLowResLandscape is the game using a low-res landscape mode
   */
  constructor(board, config) {
    super(game);
    this._board = board;
    this._initButton(config);
  }

  /**
   * Initializes Skip button
   * @param {Object} config
   * @param {string} config.text button label text
   * @param {string} config.style button label style
   * @param {ORIENTATION} config.orientation orientation of game
   * @param {boolean} config.hasLowResLandscape is the game using a low-res landscape mode
   * @param {number} config.gameScale
   */
  _initButton(config) {
    const {
      text,
      style,
      orientation,
      hasLowResLandscape,
      gameScale,
    } = config;
    this._button = G.makeImage(100, 0, null, 0, this);
    this._button.inputEnabled = true;
    this._button.input.useHandCursor = true;
    this._button.hitArea = new Phaser.Rectangle(-2000, -2000, 4000, 4000);

    this._text = new G.Text(0, 0, text, style, 0.5, game.width, 500, true, 'center');
    this._button.addChild(this._text);

    if (orientation === ORIENTATION.vertical) {
      this._button.x = game.width / 2;
      // Ipad specific check
      const isIpad = FBInstant.deviceDectorFunctions().isIpad();
      if (isIpad) {
        this._button.x = game.world.bounds.x + game.width / 2;
      }
      // Galaxy Fold specific check
      const isFold = /SM-F916B/i.test(navigator.userAgent);
      if (isFold && Math.abs(window.innerWidth - window.innerHeight) < 100) {
        this._button.x = game.world.bounds.x + game.width / 2;
      }
    } else if (hasLowResLandscape) {
      this._button.x = 540;
      this._button.scale.setTo(gameScale);
    } else {
      this._button.x = 320;
    }

    this._button.y = orientation === ORIENTATION.horizontal && hasLowResLandscape
      ? (450 * gameScale) - (this._text.height - game.height) / 2
      : 450 - (this._text.height - game.height) / 2;

    const blinkTime = 1000;
    const pulse = game.time.events.loop(blinkTime, () => {
      if (this._button && this._button.visible && this._button.parent) {
        game.add.tween(this._text)
          .to({ alpha: 0.75 }, blinkTime / 2, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
      }
    }, this);
    this._button.addChild(this._text);
    game.add.tween(this._text) // initial blink
      .to({ alpha: 0.75 }, blinkTime / 2, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);

    this._button.pulse = pulse;
    this._button.events.onInputDown.add((this._onSkipClick.bind(this)));
  }

  /**
   * button click handler
   */
  _onSkipClick() {
    if (this._board) {
      this._board.startCascadeSkipSequence();
    }
  }

  destroy() {
    super.destroy();
    if (this._button.pulse && this._button.pulse.destroy) {
      game.time.events.remove(this._button.pulse);
      this._button.pulse = null;
    }
  }
}
