import { Window_OutOfMovesIAP } from '../../OOM/Window_outOfMovesIAP';
import { TokenEventOOMFailView } from './views/TokenEventOOMFailView';

/**
 * token event out of moves window
 */
class Window_TokenEventOutOfMoves extends Window_OutOfMovesIAP {
  /**
  * @param {Object} parent
  * @param {Object} popUpConfig
  * @param {Function} levelFailedCallback (optional) function to call instead of 'levelFailed'
  */
  constructor(parent, popUpConfig, levelFailedCallback) {
    super(parent, popUpConfig, levelFailedCallback);
  }

  /**
   * init the background graphics
   */
  _initBackground() {
    this.addBackground('tokenEvent_popup_background');
  }

  /**
   * init the fail view, overrides baseclass
   */
  _initFailView() {
    const { additionalBoosterData } = this._popUpConfig;
    const failViewInstanece = new TokenEventOOMFailView(additionalBoosterData);
    super._initFailView(failViewInstanece);
  }

  /**
   * overrides event check for special event
   */
  _shouldShowEventGingyNow() {
    if (this.state.warningGingyShown) {
      this.state.specialEventGingy.show();
    }
  }

  /**
   * go to coin shop. hide gingy if needed.
   */
  _goToShopCoin() {
    const success = super._goToShopCoin('tokenEventOutOfMoves');
    if (!success) return;

    const gingyIsShown = this.state.warningGingyShown;
    const gingy = this.state.specialEventGingy;
    if (gingy && gingyIsShown) gingy.hide();
  }
}

if (!window.Windows) window.Windows = {};
Windows.tokenEventOutOfMoves = Window_TokenEventOutOfMoves;
