
import { Window_BragBaseWindow } from './Window_BragBaseWindow';

const BANNER_TITLE_STRING = 'So close!';
const BANNER_SUBTITLE_STRINGS = ["Don't worry, let's try another level"];
const BRAG_BUTTON_DEFAULT_TEXT = 'Share Results';

/**
 * Window for when you lost a brag 2.0 challenge from clickin on a share post
 */
class Window_BragChallengeLost extends Window_BragBaseWindow {
  /**
   * constructor
   * @param {object} parent
   * @param {number} levelNum
   */
  constructor(parent, levelNum) {
    super(parent, G.OMTsettings.elements.Window_Brag, levelNum);

    // we skipped the normal level flow so we will send the onLevelFailed event now
    G.sb('onLevelFailed').dispatch();
  }

  /**
   * override customize the various window banners
   */
  _createBanners() {
    this._createTopBanner(OMT.language.getText(BANNER_TITLE_STRING));
    this._createSubTitles(OMT.language.getTextList(BANNER_SUBTITLE_STRINGS));
  }

  /**
   * create the specific window content
   */
  _createContent() {
    super._createContent();
    this._initSoloAvatarLayout();
  }

  /**
   * override and customize brag button text
   */
  _createBragButton() {
    super._createBragButton(BRAG_BUTTON_DEFAULT_TEXT);
  }

  /**
   * on brag button clicked, TODO: this should show the alternate brag message.
   */
  async _onBragClicked() {
    await OMT.social.shareBrag(() => {
      this.closeWindow();
    }, this._levelNum, this._bragRightsData);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.bragChallengeLost = Window_BragChallengeLost;
