import TimeUtil from '@omt-components/Utils/TimeUtil';
import Shop3_CornerBanner from './Shop3_CornerBanner';

/**
 * banner with time display used for special event sales in the shop
 */
export default class Shop3_SaleTimeBanner extends Shop3_CornerBanner {
  /**
   * constructor
   * @param {{saleEndTime:number, assetImage:string}} config
   */
  constructor(config) {
    super(config);
    this._saleEndTime = this._config.saleEndTime;
  }

  /**
   * Draws everything required
   */
  _beginDrawing(assetImage) {
    // this._drawBgRect();
    this._initCountDownText();
    super._beginDrawing(assetImage);
    this._updateCountDown();
  }

  /**
   * initialize the countdown text field
   */
  _initCountDownText() {
    const textWidth = this._width - this._iconSize / 2;
    const textHeight = this._height;
    const textX = this._iconSize + this._textPadding;
    const textY = this._textOffsetY + (this._height / 2);
    const textWrap = true;
    const countDownStyle = G.Text.getStyle('shop3-saleBanner');
    const countDownText = new G.Text(textX, textY, '', countDownStyle, [0, 0.5], textWidth, textHeight, textWrap, 'left', undefined, undefined, true);
    this.addChild(countDownText);
    this._countDownText = countDownText;
  }

  /**
   * update the countdown clock
   */
  _updateCountDown() {
    const currentTime = OMT.connect.getServerTimestampSync();
    const timeRemaining = this._saleEndTime > currentTime ? this._saleEndTime - currentTime : 0;
    const timeCode = TimeUtil.getTimeCode(timeRemaining);

    const textLine2 = OMT.language.getText('Summer Sale ends in %TIME%').replace('%TIME%', timeCode);
    this._countDownText.setText(textLine2);
  }

  /**
   * phaser update method
   */
  update() {
    super.update();
    this._updateCountDown();
  }
}
