G.Input = G.Input || {};
G.Input.EventType = {
  InputOver: 'inputOver',
  InputOut: 'inputOut',
  InputDown: 'inputDown',
  InputUp: 'inputUp',
  DragStart: 'dragStart',
  DragUpdate: 'dragUpdate',
  DragStop: 'dragStop',
};
