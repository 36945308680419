import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

export const GATE_COOLDOWN_KEY = 'gateId_';
export const GATE_MANAGER_DATA_KEY = 'gate-manager';

export const GATE_OPEN_REASON = {
  unknown: 'UNKNOWN',
  coins: 'COINS',
  time: 'TIME',
  friend: 'INVITE',
  stars: 'STARS',
};

export default class GateManager {
  /**
   * The new manager that tries to replace functionality from GateMgr
   */
  constructor() {
    // shrug
  }

  /**
   * Initialize!
   * @param {Object} dataRef
   */
  init(dataRef) {
    this.dataReference = dataRef;

    const defaultData = {
      og: 0, // og = Opened Gates
      po: [], // po = Pending Open
      il: {}, // List<number, string>. il = Invite List
      oil: {}, // List<number, string>. oil = Outgoing Invite List
      co: [], // co = Coin Opened
    };

    OMT_Utils.mergeMissingObject(this.dataReference, defaultData);

    this._gateDataJson = G.json['configs/newGates'][G.featureUnlock.gateAlgorithm];
    if (G.saveState.data.gates) {
      this._mergeOldGates();
      delete G.saveState.data.gates; // Removes old gates
    }
    this._fixUpGateData();
    this._checkCooldownGate();
  }

  /**
   * Returns the level gap between gates
   * @returns {number}
   */
  get levelGap() {
    return this._gateDataJson.gateLevelGap;
  }

  /**
   * Returns the last level the gate was opened at
   * @returns {number}
   */
  get lastGate() {
    return this.dataReference.og;
  }

  /**
   * Merges old gates with new gates
   */
  _mergeOldGates() {
    const gateSortedData = { open: [], closed: [] };
    G.saveState.data.gates.forEach((gate) => {
      if (!gate) {
        return;
      }
      const gatePack = {
        gateId: gate.gateId,
        level: gate.gateId * this.levelGap,
        invited: gate.invites,
        startedAt: gate.timerStartedAt,
      };
      const targetObj = gate.open ? gateSortedData.open : gateSortedData.closed;
      targetObj.push(gatePack);
    });
    gateSortedData.open.forEach((gateData) => {
      if (!this.hasGateBeenOpened(gateData.level)) {
        this.pushOpenGate(gateData.level);
      }
    });
    gateSortedData.closed.forEach((gateData) => {
      const oldCooldownKey = `${GATE_COOLDOWN_KEY}${gateData.gateId}`;
      const cooldownTime = G.saveState.getUserCooldownRemaining(oldCooldownKey, '');

      if (cooldownTime > 0) {
        this.addPotentialGate(gateData.level);
        const newCooldownKey = `${GATE_COOLDOWN_KEY}${gateData.level}`;
        G.saveState.setUserCooldown(oldCooldownKey, '', 0);
        G.saveState.setUserCooldown(newCooldownKey, '', cooldownTime);

        this.dataReference.il[gateData.level] = gateData.invited;
      }
    });
  }

  /**
   * Fix issues with gate data such as undefined gate data, or multiple instances of opened gates
   */
  _fixUpGateData() {
    if (Array.isArray(this.dataReference.og)) {
      _.remove(this.dataReference.og, this._isGateUndefined.bind(this)); // Looks for undefined Gates and removes them
      this.dataReference.og = _.uniqBy(this.dataReference.og, 'lv'); // Removes duplicates caused by previous builds
      if (this.dataReference.og.length > 0) {
        this.dataReference.og.sort((a, b) => a.lv - b.lv); // Sort
        const lastPastGate = this.dataReference.og[this.dataReference.og.length - 1]; // Find the highest
        this.dataReference.og = lastPastGate.lv; // Change to number
      } else {
        this.dataReference.og = 0;
      }
    } else if (Number.isNaN(this.dataReference.og)) {
      const tempGate = Math.floor(G.saveState.getLastPassedLevelNr() / this.levelGap) * this.levelGap;
      if (tempGate % this.levelGap === 0) {
        if (!this.canGateBeOpened(this.getGateObject(tempGate)).result) {
          this.dataReference.og = Math.max(0, tempGate - this.levelGap);
        } else {
          this.dataReference.og = tempGate;
        }
      }
    }
  }

  /**
   * Checks if the gate level in the gate data is undefined or not
   * @param {{lv:number}} gateData
   */
  _isGateUndefined(gateData) {
    return isNaN(gateData.lv); // eslint-disable-line no-restricted-globals
  }

  /**
   * Increments the number of invites that were sent out for a gate
   * @param {number} gateLevel
   */
  increaseInvitesOutForGate(gateLevel) {
    if (!this.dataReference.oil[gateLevel]) { this.dataReference.oil[gateLevel] = 0; }
    this.dataReference.oil[gateLevel]++;
  }

  /**
   * Checks all gate cooldowns and removes them if they're 0
   */
  _checkCooldownGate() {
    const cooldownObj = G.saveState.data.cooldownData;
    if (!cooldownObj) { return; }

    const gateKeys = Object.keys(cooldownObj).filter((key) => key.indexOf(GATE_COOLDOWN_KEY) > -1);

    gateKeys.forEach((key) => {
      G.saveState.getUserCooldownRemaining(key); // Find all gate cooldown times and remove them if they're 0
    });
  }

  /**
   * Increments the number of invites a gate gets if its not opened and is pending
   * @param {number} gateLevel
   */
  addInviteToGate(gateLevel) {
    if (this.hasGateBeenOpened(gateLevel)) { return; }
    if (this.isGatePendingOpen(gateLevel)) {
      if (!this.dataReference.il[gateLevel]) {
        this.dataReference.il[gateLevel] = 0;
      }
      this.dataReference.il[gateLevel]++;
      this.save();
    }
  }

  /**
   * Potentially adds a gate if it hasn't been opened or in a pending opened state
   * Returns true if the gate added was sucessful
   * false if not
   * @param {number} level
   * @return {boolean}
   */
  addPotentialGate(level) {
    if (this.hasGateBeenOpened(level)) { return false; }
    if (this.dataReference.po.indexOf(level) > -1) { return false; }
    const gateObject = this.getGateObject(level);
    if (gateObject.timeReq && this.dataReference.po.indexOf(level) === -1) {
      G.saveState.setUserCooldown(`${GATE_COOLDOWN_KEY}${level}`, '', gateObject.timeReq * 60 * 1000);
      this.dataReference.po.push(level);
      this.save();
      return true;
    }
    return false;
  }

  /**
   * Checks if the gate has been previously opened
   * @param {number} level
   * @returns {boolean}
   */
  hasGateBeenOpened(level) {
    return level < this.dataReference.og;
  }

  /**
   * Has \any/ gate been opened yet
   * @returns {boolean}
   */
  hasAGateBeenOpened() {
    return this.dataReference.og > 0;
  }

  /**
   * Checks if gate is in the pending open list
   * @param {number} level
   * @returns {boolean}
   */
  isGatePendingOpen(level) {
    return this.dataReference.po.indexOf(level) > -1;
  }

  /**
   * Returns the number of required stars for the gate
   * @param {number} gateLevel
   * @returns {number}
   */
  getStarRequirementForGate(gateLevel) {
    const starDataSet = this._organizeData(this._gateDataJson.starRequirement, gateLevel);
    return Math.floor(gateLevel * starDataSet.dataSet);
  }

  /**
   * Returns an object for the requirements to open the gate.
   * If the user has enough stars to open it, the rest of the data is not collected
   * Turning on `force` will return the entire object regardless of easy unlock or not
   * @param {number} gateLevel
   * @param {boolean} force
   * @returns {{gateLevel:number, starReq:number, coinReq?:number, timeReq?:number, inviteReq?:number}}
   */
  getGateObject(gateLevel, force) {
    const gateObj = {
      gateLevel,
    };

    gateObj.starReq = this.getStarRequirementForGate(gateLevel);

    if (G.saveState.getAllStars() >= gateObj.starReq && !force) {
      return gateObj;
    }

    const coinDataSet = this._organizeData(this._gateDataJson.coinCost, gateLevel);
    gateObj.coinReq = Math.floor(coinDataSet.dataSet.base + ((Math.floor((gateLevel - coinDataSet.initialLevel) / coinDataSet.dataSet.levelIncrement)) * coinDataSet.dataSet.increment));

    const timeDataSet = this._organizeData(this._gateDataJson.unlockTime, gateLevel); // Is in minutes
    gateObj.timeReq = Math.floor(timeDataSet.dataSet.base + ((Math.floor((gateLevel - timeDataSet.initialLevel) / this.levelGap)) * timeDataSet.dataSet.increment));

    const inviteDataSet = this._organizeData(this._gateDataJson.invites, gateLevel);
    gateObj.inviteReq = inviteDataSet.dataSet;

    return gateObj;
  }

  /**
   * Filters through the json data and finds which object corresponds to the gate level.
   * dataSet can either be an object or a number, depending on how the json was formatted
   * @param {{initialLevel: number, dataSet:Object}} gateDataSegment
   * @returns {{initialLevel: number, dataSet:{base: number, initialLevel: number, levelIncrement?:number, increment:number}}}
   */
  _organizeData(gateDataSegment, gateLevel) {
    const organizer = {
      levelKey: [],
    };
    Object.keys(gateDataSegment).forEach((key) => {
      if (isNaN(key)) { // eslint-disable-line no-restricted-globals
        organizer[key] = gateDataSegment[key];
      } else {
        organizer.levelKey.push(parseInt(key));
      }
    });
    organizer.levelKey.sort((a, b) => a - b);

    const dataSetLevel = organizer.levelKey.find((level) => gateLevel <= level);
    if (dataSetLevel) {
      const dataSetIndex = organizer.levelKey.indexOf(dataSetLevel);
      const prevIndexKey = Math.max(0, dataSetIndex - 1);
      const initialLevel = dataSetIndex === prevIndexKey ? 0 : organizer.levelKey[prevIndexKey];
      return {
        initialLevel,
        dataSet: gateDataSegment[dataSetLevel],
      };
    }
    let initialLevel = organizer.levelKey[organizer.levelKey.length - 1];
    if (isNaN(initialLevel)) { // eslint-disable-line no-restricted-globals
      initialLevel = 0;
    }
    return {
      initialLevel,
      dataSet: gateDataSegment.default,
    };
  }

  /**
   * Returns true if the gate can be opened through stars, cooldown, invites
   * Returns false if not
   * @param {{gateLevel:number, starReq:number, coinReq?:number, timeReq?:number, inviteReq?:number}} gatePack
   * @returns {{ result: boolean, reason:GATE_OPEN_REASON }}
   */
  canGateBeOpened(gatePack) {
    const result = {
      result: false,
      reason: GATE_OPEN_REASON.unknown,
      immediate: true,
    };
    if (gatePack.gateLevel < this.dataReference.og) {
      result.result = true;
    }

    if (G.saveState.getAllStars() >= gatePack.starReq) {
      result.result = true;
      result.reason = GATE_OPEN_REASON.stars;
    }

    const gateCooldown = G.saveState.getUserCooldownRemaining(`${GATE_COOLDOWN_KEY}${gatePack.gateLevel}`, '', false, false);
    if (gateCooldown === 0) {
      if (this.dataReference.po.indexOf(gatePack.gateLevel) === -1 && G.saveState.getLastPassedLevelNr() >= gatePack.gateLevel) { // Wasn't opened or pending. Probably new gate
        this.addPotentialGate(gatePack.gateLevel);
      } else {
        result.result = true;
        result.reason = GATE_OPEN_REASON.time;
        result.immediate = false;
      }
    }

    if (this.dataReference.il[gatePack.gateLevel] >= gatePack.inviteReq) {
      result.result = true;
      result.reason = GATE_OPEN_REASON.friend;
      result.immediate = false;
    }

    return result;
  }

  /**
   * Opens the gate and does a lot of data re-organizing
   * @param {number} gateLevel
   */
  openGate(gateLevel) {
    if (!this.hasGateBeenOpened(gateLevel)) {
      this.pushOpenGate(gateLevel); // Saves gate into array
    }

    if (G.saveState.getLastPassedLevelNr() > gateLevel) { return; } // Do not generate goods if you're cheating

    this.generateGoods(gateLevel);
  }

  /**
   * Generates goods that comes with the gates
   * @param {number} gateLevel
   */
  generateGoods(gateLevel) {
    // Determines unlock levels for shuffle chests
    let shuffleChests = G.saveState.chestShuffleDataManager.determineUnlockLevel();
    // eslint-disable-next-line no-return-assign
    shuffleChests = shuffleChests.map((unlockLevel) => unlockLevel += gateLevel); // Offsets it by the current level
    G.saveState.chestShuffleDataManager.generateChestShuffles(shuffleChests);

    // Determines unlock levels for level map
    let mapChests = G.saveState.mapChestDataManager.determineUnlockLevel();
    // eslint-disable-next-line no-return-assign
    mapChests = mapChests.map((unlockLevel) => unlockLevel += gateLevel); // Offsets it by current level
    G.saveState.mapChestDataManager.generateSGTwoChests(mapChests);

    let mailboxes = G.saveState.mailboxManager.determineUnlockLevel();
    // eslint-disable-next-line no-return-assign
    mailboxes = mailboxes.map((unlockLevel) => unlockLevel += gateLevel); // Offsets it by current level
    G.saveState.mailboxManager.generateMailboxes(mailboxes);
  }

  /**
   * Function to push objects into Opened Gates since it became complicated
   * @param {number} gateLevel
   */
  pushOpenGate(gateLevel) {
    // Remove gate from pending list, if its there
    const pendingIndex = this.dataReference.po.indexOf(gateLevel);
    if (pendingIndex > -1) {
      this.dataReference.po.splice(pendingIndex, 1);
    }

    this.dataReference.og = Math.max(this.dataReference.og, gateLevel);
    delete this.dataReference.il[gateLevel];
    delete this.dataReference.oil[gateLevel];
  }

  /**
   *
   * @param {number} level
   * @returns {{ out: number, claimed: number }}
   */
  getInviteDataOf(level) {
    return {
      out: this.dataReference.oil[level] || 0, // invites Out
      claimed: this.dataReference.il[level] ? this.dataReference.il[level] : 0, // invites Claimed
    };
  }

  /**
   * Finds the next gate level
   * @returns {number}
   */
  getNextGateLevel() {
    return this.dataReference.og + this.levelGap;
  }

  /**
   * Returns true if the level given is potentially a gate node
   * @param {number} level
   * @returns {boolean}
   */
  isLevelPotentiallyAGate(level) {
    return level % this.levelGap === 0;
  }

  /**
   * Used for restore payloads. Opens gates up to the level given
   * @param {number} level
   */
  fixGatesUpToLevel(level, checkEligibility) {
    for (let i = 0; i <= level; i += this.levelGap) {
      if (!this.hasGateBeenOpened(i) && (checkEligibility ? this.canGateBeOpened(this.getGateObject(level)).result : true)) {
        this.pushOpenGate(i);
      }
    }
  }

  /**
   * Save function to specific save key
   */
  save() {
    OMT.userData.writeUserData(GATE_MANAGER_DATA_KEY, this.dataReference);
  }
}

if (typeof G === 'undefined') G = {};
if (typeof G.worldMap2 === 'undefined') G.worldMap2 = {};
G.worldMap2.gateManager = GateManager;
