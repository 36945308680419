export default class VillainsBaseContainer extends Phaser.Group {
  /**
   * Creates a base group object that can be extended as needed
   * @param {object} parentClass A parent class that preferable extends BaseClass
   */
  constructor(parentClass) {
    super(game);

    this.parentClass = parentClass;
  }

  /**
   * Custom destroy function that is responsible for additional cleanup
   */
  destroy() {
    super.destroy();

    const { parentClass } = this;
    if (parentClass.destroy) {
      parentClass.destroy();
    }
  }
}
