/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import { Window } from '../../../00_IMMEDIATE/Window';
import { Shop_CoinBar } from '../Shop/Shop_CoinBar';
import ShopUtils, { SHOP_EVENT_KEYS, SHOP_DEAL_TYPES, REG_DEAL_PRIORITY } from '../Shop/Shop_Utils';
import Shop3_SpecialDeal from './Shop3_SpecialDeal';
import Shop3_RegularDeal from './Shop3_RegularDeal';
import Shop3_SaleTimeBanner from './Components/Shop3_SaleTimeBanner';
import Shop3_DiscountTag from './Components/Shop3_SaleDiscountTag';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import OMT_UI_VerticalScrollArea from '../../../OMT_UI/Scroll/OMT_UI_VerticalScrollArea';
import OMT_UI_HorizontalScrollArea from '../../../OMT_UI/Scroll/OMT_UI_HorizontalScrollArea';
import { OMT_TweenPauseUtil } from '@omt-components/Utils/Animation/OMT_TweenPauseUtil';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { OMT_AssetLoader } from '../../../Services/OMT/OMT_AssetLoader';
import OMT_UI_HorizontalScrollBarConfig from '../../../OMT_UI/Scroll/ScrollBarConfig/OMT_UI_HorizontalScrollBarConfig';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';
import { INTERSTITIAL_RULES } from '../../../Services/OMT/ads/OMT_InterstitialAdRules';
import Shop3_iapMultiplierBanner from './Components/Shop3_iapMultiplierBanner';
import AnnuityManager from '../../../Services/OMT/dataTracking/annuityManager/AnnuityManager';

const VIEWMODE = { // View mode of the shop
  simplified: 0,
  complete: 1,
};

const MAX_HEADER_TEXT_WIDTH = 200;

/**
 * Please account for:
 * Horizontal mode
 * Shop display rules will come from GB - Either organize functions or ShopUtils
 */

class Window_CoinShop3 extends Window {
  /**
   * AKA Shop 3.0
   * @author Sandra Koo
   * @param {any} parent
   * @param {Object} config
   * @param {boolean} config.freeGift
   * @param {number} config.orientation
   */
  constructor(parent, config) {
    super(parent);

    OMT_TweenPauseUtil.pauseAllTweens();

    // shift window in game scene
    if (game.state.current === 'World') {
      this.y = -G.WindowMgr.Constants.WorldVerticalOffset;
    }

    this._init(config);
  }

  /**
   * async init Shop 3.0
   * @author Sandra Koo
   * @param {Object} config
   * @param {boolean} config.freeGift
   * @param {number} config.orientation
   * @returns {Promise}
   */
  async _init(config) {
    this._config = config || {};
    this._config.freeGift = OMT.feature.is3hGiftInShop() // Replaces what was passed in for freeGift
      && OMT.feature.getFeature3hGift() // After doing a bunch of checking
      && OMT.feature.getFeature3hGiftActiveByLevel()
      && G.saveState.getUserCooldownRemaining('3h_gift', '') === 0 && this._config.freeGift;

    this._attemptToLoadShop = _.debounce(this._loadShop, 100); // Just in case race conditions happen
    this._weekendDealOn = OMT.feature.isWeekendDealOn() && !OMT.feature.isTokenEventOn(true);
    this._annuityOn = OMT.feature.isAnnuityEnabled() || G.saveState.annuityManager.isThereAnnuityPackage(); // Is feature on and is there a pack that needs to be claimed?
    this._waitingIcon = undefined; // The waiting icon. Used a few times
    this._dealContainer = undefined; // The container that holds most of this
    this._itemsContainer = undefined; // A container in ^ that holds all the assets that gets scrolled around
    this._scroll = undefined; // The scrolling component
    this._catalog = undefined; // The given catalog. Already filtered. Has everything
    this._organizedSpecialDeals = undefined; // Array{<dealType:string, deal: FBCatalog>}
    this._organizedRegularDeals = undefined; // Object of keys with Array{<dealType:string, deal:FBcatalog>}
    this._runningEvent = undefined; // An object that checks holds what event is running and what to do about it
    this._readyFlags = {
      shopIsGo: false, // A flag to not re-initialize the shop again
      gbData: false, // A flag for checking Game Backend and doing whatever it needs to do
      asset: false, // A flag for when the main assets are ready
      catalog: false, // A flag for when the catalog is ready
      event: false, // A collection of events that are running
    };
    this._headerCoinBar = false; // don't need this anymore for landscape
    this._orientation = !(this._config.orientation === null || this._config.orientation === undefined) ? this._config.orientation : OMT.systemInfo.orientation;
    this._maxSpecialDimensionCalculation = this._orientation === ORIENTATION.horizontal ? game.width / 4 : Math.max(260, game.height / 4);
    this._unlimitedLivesCap = G.saveState.getUnlimitedLivesSec();
    this._currentViewMode = this._getSimplifiedViewMode(); // Current View mode is default to simplified mode
    this._layoutDetails = undefined; // Holds the data on what layout to use from the shop
    this._fallbackSimplifiedLayoutDeals = [ // The fallback
      'coins_4k',
      'coins_15k',
    ];
    if (this._orientation === ORIENTATION.horizontal) {
      this._fallbackSimplifiedLayoutDeals.push('coins_35k');
    }
    this._totalSlotsOnSpecialExpanded = G.OMTsettings.elements.Window_shop3.maxSpecialSlots;
    this._signalTokens = []; // All G.sb signals that need to be cleaned up
    this._allTweens = []; // All tweens saved for destruction
    this._headers = []; // Header assets
    this._gameScale = GameScaleController.getInstance().gameScale;

    // we need to await the loading of the images before drawing assets.
    this._initWaitingIcon();
    const assetLoader = OMT_AssetLoader.getInstance();
    const assetsLoaded = await assetLoader.waitOnSecondaryImages(['shop/ui', 'shop/nine-slices', 'shop/deal-graphics']);
    this._removeWaitingIcon();
    super._setOpenWindowAnimation(this.isFullscreen);
    if (!assetsLoaded) { // could not load assets
      this._drawHeader();
      this._repositionMainHeader();
      this._showCouldNotLoadCatalogError();
      return;
    }
    this._initAssets();

    this.onFinishedEnter.addOnce(() => { // When the window entering is done, Do a bunch of stuff
      this._backEndConnect();
      this._collectShopCatalog();
      this._checkEvents();
    });
  }

  /**
   * disabled due to loading delay. call super._setOpenWindowAnimation() when ready.
   */
  _setOpenWindowAnimation() {
    this.alpha = 0;
  }

  /**
   * Destroy!!
   */
  destroy() {
    this._allTweens.forEach((tw) => {
      if (tw) {
        tw.stop();
      }
    });
    this._signalTokens.forEach((sig) => {
      if (sig) {
        if (sig.detach) {
          sig.detach();
        }
        sig = null;
      }
    });
    super.destroy();
  }

  /**
   * close window method
   */
  closeWindow() {
    OMT_TweenPauseUtil.resumeAllTweens();
    super.closeWindow();
  }

  /**
   * Returns an object that defines what can be shown
   * @returns {viewMode:number, specialDealSlots:number, freeGift:boolean, regularDeal:Array<String>}
   */
  _getSimplifiedViewMode() {
    const obj = {
      viewMode: VIEWMODE.simplified, // View mode is set to simplified
      specialDealSlots: 2, // The number of special deal slots that can be used in simplified mode
      freeGift: this._config.freeGift, // Is there a free gift?
      regularDeal: undefined, // The number of regular deals that will be shown underneath the special deals. Indicate what you want!
    };
    return obj;
  }

  /**
   * Returns an object that defines what can can be shown
   * @returns {viewMode:number, specialDealSlots:number, freeGift:boolean}
   */
  _getComplicatedViewMode() {
    const obj = {
      viewMode: VIEWMODE.complete,
      specialDealSlots: this._config.specialDealSlots || this._totalSlotsOnSpecialExpanded, // This is used to supress special deals during events!
      freeGift: this._config.freeGift,
    };
    return obj;
  }

  /**
   * Draws the background and the waiting icon.
   * Then tries to load the shop
   */
  _initAssets() {
    this._drawHeader();
    this._repositionMainHeader();
    this._initWaitingIcon();
    this._readyFlags.asset = true;
    this._attemptToLoadShop();
    if (this._waitingIcon) this.addChild(this._waitingIcon);
  }

  /**
   * Adds in the waiting icon. It spins!
   */
  _initWaitingIcon() {
    if (!this._waitingIcon) this._waitingIcon = new G.WaitingIcon(0, 0, 'waiting_icon_white'); // Loading...
    this.parent.addChild(this._waitingIcon);
  }

  /**
   * Function that tweens out the icon
   */
  _removeWaitingIcon() {
    if (this._waitingIcon) {
      const tw = game.add.tween(this._waitingIcon)
        .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
      tw.onComplete.addOnce(() => {
        if (this._waitingIcon) {
          if (this._waitingIcon.parent) {
            this._waitingIcon.parent.removeChild(this._waitingIcon);
          }
          this._waitingIcon.destroy();
        }
        this._waitingIcon = null;
      });
      this._allTweens.push(tw);
    }
  }

  /**
   * Draws the header part that doesn't get scrolled.
   */
  _drawHeader() {
    // Header section
    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      this._header = new UI_NineSlice(0, 0, 'shop3_header1', game.width / this._gameScale, 75 / this._gameScale, G.OMTsettings.elements.Window_shop3.headerSlice);
      this._headerText = new G.Text(0, 0, OMT.language.getText('Store'), 'shop3-headerTitle', 0.5, MAX_HEADER_TEXT_WIDTH / this._gameScale, this._header.height / this._gameScale);
    } else {
      this._header = new UI_NineSlice(0, 0, 'shop3_header1', game.width, 75, G.OMTsettings.elements.Window_shop3.headerSlice);
      this._headerText = new G.Text(0, 0, OMT.language.getText('Store'), 'shop3-headerTitle', 0.5, MAX_HEADER_TEXT_WIDTH, this._header.height);
    }

    this._header.addChild(this._headerText);
    this._header.anchor.set(0.5);
    this.addChild(this._header);

    // Close button
    this._closeBtn = new G.Button(0, 0, 'btn_x', this.closeWindow.bind(this));
    const closeBtnScale = this._orientation === ORIENTATION.vertical
      ? (this._header.height * 0.9) / this._closeBtn.height
      : ((this._header.height * 0.9) / this._closeBtn.height) * this._gameScale;
    this._closeBtn.scale.set(closeBtnScale);

    this.addChild(this._closeBtn);

    // Shop coin bar
    this._coinBar = new Shop_CoinBar();
    this._coinBar.show();
    this._coinBar.resize = () => {}; // Kill its resize func. I'll handle this!
    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      this._coinBar.scale.setTo(1.3 * this._gameScale);
    }

    this.addChild(this._coinBar);
  }

  /**
   * Function for back end connection.
   * It doesn't exist right now though
   */
  async _backEndConnect() {
    this._readyFlags.gbData = true;
    this._attemptToLoadShop();
  }

  /**
   * Collects catalog from FB and other async notes that need to be collected
   */
  async _collectShopCatalog() {
    const allCatalog = await OMT.payments.getCatalogAsync();
    this._catalog = ShopUtils.filterCurrentTier(allCatalog); // Gets catalog and filters it
    this._layoutDetails = ShopUtils.getShopLayoutData(allCatalog, _.clone(this._fallbackSimplifiedLayoutDeals));
    if (!this._catalog || this._catalog.length === 0) { // If theres no catalog
      this._removeWaitingIcon();
      this._showCouldNotLoadCatalogError();
    } else { // There is!? Try to load the shop
      this._readyFlags.catalog = true;
      this._attemptToLoadShop();
    }
  }

  /**
   * Checks if theres any events running.
   * this._runningEvent gets populated here with important points
   */
  _checkEvents() {
    if (OMT.feature.isSpecialShopDealsOn(true)) {
      this._runningEvent = {
        key: SHOP_EVENT_KEYS.SPECIALDEALS,
        packageId: G.OMTsettings.specialShopDeal.regularPackId,
        firstTimeBuyerId: G.OMTsettings.specialShopDeal.firstTimePackId,
        timeout: ShopUtils.determineSpecialDealsTimeOut(),
        assetReplacement: {
          saleIcon: 'summerSaleBanner',
          specialDeal: {
            frame: 'shop3_ftuBox',
            ribbon: 'shop3_ftudealRibbon',
            boxTint: G.OMTsettings.elements.Window_shop3.specialDealTintData[3],
            value: {
              base: 'summerSaleValueBase',
              tint: 'summerSaleValueTint',
              highlight: 'summerSaleValueHighlight',
              textMultiplier: G.OMTsettings.specialShopDeal.valueTagMultiplier,
              tintColour: G.OMTsettings.specialShopDeal.valueTagColours,
            },
            boosterBox: {
              coinBar: {
                tint: G.OMTsettings.specialShopDeal.coinBarTint,
              },
            },
            chest: 'summerSaleDeal',
          },
        },
      };
    } else if (OMT.feature.getEventPostcardFeature() && G.OMTsettings.postcardEvent.shop) { // Check event postcard
      const curTime = OMT.connect.getServerTimestampSync();
      let endTime = 0; // Get the end time
      try {
        endTime = G.featureUnlock.eventPostcard.range.end;
      } catch (err) {
        endTime = curTime + (48 * 1000 * 60 * 60); // 2 days from now
      }
      const timeout = (endTime - curTime) / 1000;
      this._runningEvent = {
        key: SHOP_EVENT_KEYS.POSTCARD,
        packageId: G.OMTsettings.postcardEvent.eventId,
        firstTimeBuyerId: G.OMTsettings.postcardEvent.shop.firstTimeBuyerKey + G.OMTsettings.postcardEvent.eventId,
        timeout,
      };
    } else if (OMT.feature.isTokenEventOn(true) && G.OMTsettings.tokenEvent.shopData) {
      const curTime = OMT.connect.getServerTimestampSync();
      let endTime = 0; // Get the end time
      try {
        endTime = G.featureUnlock.tokenEvent.range.end;
      } catch (err) {
        endTime = curTime + (48 * 1000 * 60 * 60); // 2 days from now
      }
      const timeout = endTime;
      this._runningEvent = {
        key: SHOP_EVENT_KEYS.TOKEN_EVENT,
        packageId: G.OMTsettings.tokenEvent.shopData.shopKey,
        firstTimeBuyerId: G.OMTsettings.tokenEvent.shopData.shopKey + G.OMTsettings.tokenEvent.shopData.firstTimeBuyerKey,
        layoutKey: G.OMTsettings.tokenEvent.shopData.layoutKey,
        timeout,
        assetReplacement: {
          saleIcon: 'tokenEvent_shopSaleIcon', // THIS IS REQUIRED
          specialDeal: {
            frame: 'tokenEvent_shopBox',
            ribbon: 'tokenEvent_shopRibbon',
            boxTint: {
              boosterBox: {
                asset: 'tokenEvent_shopDetail',
                coinBar: {
                  tint: G.OMTsettings.tokenEvent.shopData.coinBarTint,
                  textStyle: G.OMTsettings.tokenEvent.shopData.coinBarTextStyle,
                },
              },
              valueTagTint: 0xFFFFFF,
            },
            value: {
              base: 'tokenEvent_shopBadge',
              tint: null,
              highlight: null,
              textMultiplier: G.OMTsettings.tokenEvent.shopData.valueTextMultiplier,
              tintColour: '',
            },
            boosterBox: {
              coinBar: {
                tint: G.OMTsettings.specialShopDeal.coinBarTint,
              },
            },
            chest: 'tokenEvent_ShopDeal',
          },
        },
      };
      this._config.specialDealSlots = G.OMTsettings.tokenEvent.shopData.specialDealSlots;
    }
    this._readyFlags.event = true;
    this._attemptToLoadShop();
  }

  /**
   * Checks all the flags and starts to load the shop
   */
  _loadShop() {
    // Assets, catalog ready!? And we didn't show the shop yet!?
    if (this._readyFlags.catalog && this._readyFlags.asset && this._readyFlags.gbData && this._readyFlags.event && !this._readyFlags.shopIsGo) {
      this._readyFlags.shopIsGo = true;
      try { // IF ANYTHING BREAKS, JUST SHOW THE ERROR SCREEN
        this._dealContainer = new Phaser.Group(game, this);
        this._dealContainer.alpha = 0; // Its hidden at first to try to make the shop load/look faster
        this._dealContainer.visible = false;
        this._itemsContainer = new Phaser.Group(game, null); // The items container that gets scrolled around
        this._dealContainer.addChild(this._itemsContainer);

        this._showSimplifiedView(); // Default instantly load simplified
        this._signalTokens.push(G.sb('onScreenResize').add(this.onResize, this));
        this.onResize();

        // deal container tweens in
        this._dealContainer.visible = true;
        const tw = game.add.tween(this._dealContainer)
          .to({ alpha: 1 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
        this._allTweens.push(tw);
      } catch (err) {
        console.log('Shop has encountered an error!');
        console.error(err);
        this._showCouldNotLoadCatalogError();
      }
      this._removeWaitingIcon(); // Not needed anymore
    }
  }

  /**
   * show error when catalog load failed
   */
  _showCouldNotLoadCatalogError() {
    if (this._dealContainer) { // Attempts to remove the deal container that has all the deals
      this.removeChild(this._dealContainer);
    }
    this._errorTxt = new G.Text(0, -50, OMT.language.getText('Something went wrong! Please, try again.'), {
      style: 'font-white-blue-out',
      fontSize: '38px',
    }, 0.5, 420, 300, true, 'center');
    this.addChild(this._errorTxt);
  }

  /**
   * Looks through the catalog and finds all the special deals
   */
  _organizeSpecialDeals() {
    this._organizedSpecialDeals = ShopUtils.organizeSpecialDeals(this._catalog, Object.keys(REG_DEAL_PRIORITY));
    this._allSpecialDeals = [];
  }

  /**
   * Looks through the catalog and finds all deals with productID matching the ones in REG_DEAL_PRIORITY
   */
  _organizeRegularDeals() {
    this._organizedRegularDeals = ShopUtils.organizeRegularDeals(this._catalog, Object.keys(REG_DEAL_PRIORITY));
    this._allRegularDeals = [];
    for (const key in REG_DEAL_PRIORITY) { // eslint-disable-line guard-for-in
      this._allRegularDeals[REG_DEAL_PRIORITY[key]] = [];
    }

    if (this._layoutDetails.freeGift && this._layoutDetails.freeGift.length === 0) {
      let theSmallest;
      for (const deal of this._organizedRegularDeals.coins) {
        const amount = Number.parseInt(deal.type.substr('coins_'.length).replace('k', '000'));
        if (Number.isFinite(amount)) {
          if (!theSmallest || (theSmallest && amount < theSmallest.amount)) {
            theSmallest = {
              amount,
              deal,
            };
          }
        }
      }
      if (theSmallest) {
        this._layoutDetails.freeGift.push(theSmallest.deal.type);
      }
    }
  }

  /**
   * Gets the special deals.
   * Will prioritize deals from running events. If there are no special deals, it'll look for the usual special deals
   * existingDeals is passed to avoid getting the same deals shown
   * @param {boolean} lookForAnnuity Annuity only shows up at complex mode
   * @returns {Array<{deal: FBShopPack, dealType: string, slot: number}>}
   */
  _getSpecialDeals(lookForAnnuity) {
    const targetSpecialArray = this._currentViewMode.viewMode === VIEWMODE.simplified ? this._layoutDetails.simplifiedSpecDeal : this._layoutDetails.specialDeals;
    let targetPriorityDeals = targetSpecialArray.concat([]);
    if (this._runningEvent && this._runningEvent.layoutKey && this._layoutDetails[this._runningEvent.layoutKey]) {
      const otherDeal = this._layoutDetails[this._runningEvent.layoutKey].concat([]);
      targetPriorityDeals = _.merge(targetPriorityDeals, otherDeal);
    }
    if (this._weekendDealOn && this._layoutDetails.weekend.length > 0) {
      if (!(this._currentViewMode.viewMode === VIEWMODE.complete && G.saveState.getIAPCount() > 0)) {
        const replacePack = G.OMTsettings.elements.Window_shop3.weekendDealReplace;
        const replacementIndex = targetPriorityDeals.indexOf(replacePack);
        if (replacementIndex > -1) {
          targetPriorityDeals.splice(replacementIndex, 1, this._layoutDetails.weekend[0]);
        }
      } else {
        targetPriorityDeals.unshift(this._layoutDetails.weekend[0]);
      }
    }
    const chosenSpecialDeals = ShopUtils.getSpecialDeals({
      catalog: this._organizedSpecialDeals,
      maxSlot: this._currentViewMode.specialDealSlots,
      existingDeals: this._allSpecialDeals,
      runningEvent: this._runningEvent,
      prioritySpecialDeals: targetPriorityDeals,
      assetOrder: this._layoutDetails.specialDeals,
      weekendIsOn: this._weekendDealOn,
      annuityIsOn: this._annuityOn && lookForAnnuity,
    });
    return chosenSpecialDeals;
  }

  /**
   * Creates a special deal with the given params
   * @param {Object} deal
   * @param {Object} deal.deal The facebook deal data
   * @param {String} deal.dealType
   * @param {String} deal.asset
   * @param {index} index
   */
  _createSpecialDeal(deal, index) {
    let assetReplacement = {};
    let weekendTimerData;
    if (this._runningEvent) {
      if (deal.dealType.indexOf(this._runningEvent.packageId) > -1) {
        if (this._runningEvent.assetReplacement && this._runningEvent.assetReplacement) {
          assetReplacement = this._runningEvent.assetReplacement;
        }
      }
    } else if (deal.dealType === SHOP_DEAL_TYPES.WEEKEND) {
      assetReplacement = {
        specialDeal: {
          frame: 'shop3_specialDealBg4',
          ribbon: 'shop3_dealRibbon4',
          boxTint: G.OMTsettings.elements.Window_shop3.specialDealTintData[4],
          chest: 'shop3_specialDeal4',
          value: {
            tintColour: G.OMTsettings.elements.Window_shop3.specialDealTintData[4].valueTagTint,
          },
        },
      };
      weekendTimerData = {
        dimensions: G.OMTsettings.elements.Window_shop3.weekendTimerArea,
        timeout: 0,
      };
    }
    const spec = new Shop3_SpecialDeal(deal, {
      index,
      dimensions: this._getSpecialDealDimensions(this._maxSpecialDimensionCalculation, deal.slot),
      orientation: this._orientation,
      assetReplacement,
      layout: this._layoutDetails.specDealBold,
      timerData: weekendTimerData,
    });
    spec.signals.onClick.add(this._onSpecialDealPurchase.bind(this));

    return spec;
  }

  /**
   * Creates a regular deal. If the box height is not includes, it'll be the same height as the width
   * @param {{type:string, deal:FBDeal}} targetPack
   * @param {number} index
   * @param {number} [boxWidth]
   * @param {number} [boxHeight]
   */
  _createRegularDeal(targetPack, index, boxWidth, boxHeight) {
    const deal = new Shop3_RegularDeal(targetPack, {
      index,
      dimensions: this._getRegularDealDimensions(boxWidth, boxHeight, targetPack.type),
      orientation: this._orientation,
    });
    deal.signals.onClick.add((shopDeal, button) => {
      this._onRegularDealClick(targetPack.type, shopDeal, button);
    });
    this._giveDiscountTag(deal);

    return deal;
  }

  /**
   * get dimensions of a a special deal
   * @param maxDimen number
   * @param height number
   * @returns {Object} { width, height }
   */
  _getSpecialDealDimensions(maxDimen, dealSlot) {
    if (this._orientation === ORIENTATION.horizontal) {
      return {
        width: (maxDimen * dealSlot) - 20,
        height: game.height * 0.72,
      };
    }
    return {
      height: (maxDimen * dealSlot) - 20,
      width: game.width * 0.9,
    };
  }

  /**
   * Returns an object of that is used to determine the dimensions of the regular pack
   * @param {number} [maxWidth]
   * @param {number} [boxHeight]
   * @param {string} dealType
   * @returns {{width:number, height:number}}
   */
  _getRegularDealDimensions(maxWidth, boxHeight, dealType) {
    let dimension;
    const type = dealType.split('_')[0];
    if (this._orientation === ORIENTATION.horizontal) {
      switch (type) {
        case 'inflives': // TODO properly
          dimension = { height: (boxHeight - 30) / 3 };
          break;
        case 'boosters': // TODO properly
          dimension = { height: (boxHeight - 10) / 2 };
          break;
        default: // and coins
          dimension = { height: (boxHeight - 10) / 2 };
          break;
      }
      dimension.width = maxWidth || dimension.height;
    } else {
      switch (type) {
        case 'inflives': // Lives
          dimension = { width: (maxWidth - 30) / 3 };
          break;
        case 'boosters': // TODO properly
          dimension = { width: (maxWidth - 10) / 2 };
          break;
        default: // and coins
          dimension = { width: (maxWidth - 10) / 2 };
          break;
      }
      dimension.height = boxHeight || dimension.width;
    }
    return dimension;
  }

  /**
   * make the more offers button
   * @param {number} givenWidth
   * @param {number} givenHeight
   */
  _makeMoreOffersButton(givenWidth, givenHeight) {
    const buttonText = OMT.language.getText('More Offers');
    const plusIcon = G.makeImage(0, 0, 'shop3_moreOffers', 0.5, null);
    if (this._orientation === ORIENTATION.horizontal) {
      const buttonDimen = this._getRegularDealDimensions(givenWidth, givenHeight, 'coins');
      buttonDimen.width /= this._gameScale;
      buttonDimen.height /= this._gameScale;

      this._button = new G.Button(0, 0, null, this._onMoreDealsClicked.bind(this));
      const frame = new UI_NineSlice(0, 0, 'shop3_smallFiller', buttonDimen.width, buttonDimen.height, G.OMTsettings.elements.Window_shop3.regularDealFrameSlice);
      frame.anchor.set(0.5);
      this._button.addChild(frame);
      this._button.calculatedBounds = frame.getBounds().clone();

      frame.scale.setTo(this._gameScale);
      this._button.calculatedBounds.width *= this._gameScale;
      this._button.calculatedBounds.height *= this._gameScale;

      const text = new G.Text(0, 0, buttonText, 'shop3-moreOffer', 0.5, frame.width * 0.85, frame.height / 2, true, 'center');
      text.y = -text.height / 4;
      this._button.addChild(text);

      plusIcon.scale.set(2 * this._gameScale);
      plusIcon.y = text.y + (text.height + plusIcon.height) / 2;
      this._button.addChild(plusIcon);
      this._itemsContainer.addChild(this._button);
    } else {
      plusIcon.anchor.set(1, 0.5);
      this._button = new OMT_UI_SquareButton(0, 0, {
        button: {
          tint: BUTTONCOLOURS.orange,
          dimensions: {
            width: 310, // original button size
            height: plusIcon.height * 1.5,
          },
          extraDetail: false,
        },
        text: {
          string: buttonText,
          textStyle: 'shop3-moreOffer',
          icon: {
            image: plusIcon,
            align: 'right',
          },
          offset: { x: -plusIcon.width / 2 },
        },
        options: {
          clickFunction: {
            onClick: this._onMoreDealsClicked.bind(this),
          },
        },
      });
    }
  }

  /**
   * Shows the simplified view of the shop which has only a few special deals and
   * some regular deals that we really want to show
   */
  _showSimplifiedView() {
    if (!this._organizedSpecialDeals) { this._organizeSpecialDeals(); }
    if (!this._organizedRegularDeals) { this._organizeRegularDeals(); }
    this._currentViewMode.regularDeal = _.clone(this._layoutDetails.simplified);

    // Get special deals
    const chosenSpecialDeals = this._getSpecialDeals();

    let nextPos = 0;
    for (let i = 0; i < this._currentViewMode.specialDealSlots; /* increment at end of loop */) { // Loop through deals until slots are filled
      const deal = chosenSpecialDeals[i];
      if (deal) {
        const spec = this._createSpecialDeal(deal, i);
        if (this._orientation === ORIENTATION.horizontal) {
          nextPos += spec.width + 35 * this._gameScale;
        } else {
          nextPos += spec.height + 35;
        }

        this._itemsContainer.addChild(spec);
        this._allSpecialDeals.push(spec);

        i += deal.slot; // Increment by how many slots the deal takes up
      } else {
        i++;
      }
    }
    const leftoverSpace = { // Check how much space is left
      width: (game.width - nextPos - 10) / 2,
      height: 40 + (game.height - nextPos) / 2,
    };
    const specDealDimen = this._getSpecialDealDimensions(this._maxSpecialDimensionCalculation, 1);
    const regDealDimen = {
      width: this._orientation === ORIENTATION.horizontal ? (leftoverSpace.width < specDealDimen.height ? leftoverSpace.width : null) : specDealDimen.width, // eslint-disable-line no-nested-ternary, max-len
      height: this._orientation === ORIENTATION.horizontal ? specDealDimen.height : (leftoverSpace.height < specDealDimen.width ? Math.min(leftoverSpace.height, specDealDimen.width / this._currentViewMode.regularDeal.length) : null), // eslint-disable-line no-nested-ternary, max-len
    };
    let createdRegDeals = 0;
    const createRegDeal = (wantedDeal, type) => { // Function for creating regular deals. In a function for repeat use
      const dealSection = this._organizedRegularDeals[type];
      let targetPack = dealSection.find((sectionDeal) => sectionDeal.type === wantedDeal); // Find the pack
      if (targetPack) {
        targetPack = this._checkPack(targetPack); // filter pack
        // Create special deal. If the height of it is more than the width, make it a square instead
        const deal = this._createRegularDeal(targetPack, dealSection.indexOf(targetPack), regDealDimen.width, regDealDimen.height);
        this._itemsContainer.addChild(deal);
        this._allRegularDeals[REG_DEAL_PRIORITY[type]].push(deal); // Push the deal into the proper array
        createdRegDeals++;
      }
    };
    for (let i = 0; i < this._currentViewMode.regularDeal.length; i++) { // Create all in the regular deals list
      const wantedDeal = this._currentViewMode.regularDeal[i];
      const type = wantedDeal.split('_')[0];
      if (Object.hasOwnProperty.call(this._organizedRegularDeals, type)) {
        createRegDeal(wantedDeal, type);
      }
    }
    if (createdRegDeals < this._currentViewMode.regularDeal.length) { // If not enough were created because of missing packages, make fallbacks
      for (let i = 0; i < this._fallbackSimplifiedLayoutDeals.length && createdRegDeals < this._currentViewMode.regularDeal.length; i++) {
        const wantedDeal = this._fallbackSimplifiedLayoutDeals[i];
        const type = wantedDeal.split('_')[0];
        const existingDeal = this._allRegularDeals[REG_DEAL_PRIORITY[type]].find((deal) => deal.dealData.type === this._checkPack({ type: wantedDeal }).type);
        if (existingDeal) { continue; }
        if (Object.hasOwnProperty.call(this._organizedRegularDeals, type)) {
          createRegDeal(wantedDeal, type);
        }
      }
    }

    // Show more offers button
    this._makeMoreOffersButton(regDealDimen.width, regDealDimen.height);
    this._showCornerBanner();

    this._repositionItems();
  }

  /**
   * Shows the complete view with everything.
   * Also has scrolling
   */
  _showCompleteView() {
    if (!this._organizedSpecialDeals) { this._organizedSpecialDeals(); }
    if (!this._organizedRegularDeals) { this._organizeRegularDeals(); }
    const chosenSpecialDeals = this._getSpecialDeals(true); // Get deals

    const reorderSpecialDeals = (this._allSpecialDeals.length > 0 && this._allSpecialDeals[0].slot === 1) || this._annuityOn;
    let index = this._allSpecialDeals.length;
    for (const spec of chosenSpecialDeals) { // Create new deals with new indexes
      const specialDeal = this._createSpecialDeal(spec, index);
      this._itemsContainer.addChild(specialDeal);
      this._allSpecialDeals.push(specialDeal);
      index++;
    }
    // Reorder deals
    if (reorderSpecialDeals) { // Reorder deals if the first slot was a 1 instead of a 2
      this._reorderSpecialDeals();
    }

    for (let i = 0; i < this._allRegularDeals.length; i++) { // Clear all regular deals and just remake it (because of size difference)
      const section = this._allRegularDeals[i];
      for (const deal of section) {
        deal.destroy();
      }
      this._allRegularDeals[i] = [];
    }
    const specDealDimen = this._getSpecialDealDimensions(this._maxSpecialDimensionCalculation, 1);
    const regDealDimen = {
      width: this._orientation === ORIENTATION.horizontal ? null : specDealDimen.width, // eslint-disable-line no-nested-ternary, max-len
      height: this._orientation === ORIENTATION.horizontal ? specDealDimen.height : null, // eslint-disable-line no-nested-ternary, max-len
    };
    Object.keys(this._organizedRegularDeals).forEach((sect) => { // make a deal graphic for each regular deal
      const section = this._organizedRegularDeals[sect];
      for (let i = 0; i < section.length; i++) {
        const deal = this._checkPack(section[i]);
        if (REG_DEAL_PRIORITY[sect] === REG_DEAL_PRIORITY.inflives) { // We're at infinite lives
          const infSeconds = OMT.payments.parseTimeToMinutes(deal.type.split('_')[1]) * 60; // Check how long this pack is
          if (G.saveState.getUnlimitedLivesSec() + infSeconds > (G.OMTsettings.unlimitedLivesMinCap * 60)) { continue; } // If greater than cap, don't add it
        }
        const regularDeal = this._createRegularDeal(deal, i, regDealDimen.width, regDealDimen.height);
        this._itemsContainer.addChild(regularDeal);
        this._allRegularDeals[REG_DEAL_PRIORITY[sect]].push(regularDeal); // push into proper array
      }
    });

    // Making headers
    if (this._orientation === ORIENTATION.vertical) {
      Object.keys(REG_DEAL_PRIORITY).forEach((key) => { // Go through each important pack in the shop
        const keyIndex = REG_DEAL_PRIORITY[key];
        if (this._allRegularDeals[keyIndex].length > 0) { // If theres an entry, make a header
          let headerDetails;
          switch (keyIndex) {
            case REG_DEAL_PRIORITY.coins:
              return;
            case REG_DEAL_PRIORITY.inflives:
              headerDetails = {
                asset: 'shop3_header2',
                title: OMT.language.getText('Infinite Lives Packs'),
              };
              break;
            default: break;
          }
          const header = new Phaser.Group(game, this._itemsContainer);
          const headerBg = new UI_NineSlice(0, 0, headerDetails.asset, regDealDimen.width, this._header.height, G.OMTsettings.elements.Window_shop3.headerSlice);
          headerBg.anchor.set(0.5);
          header.addChild(headerBg);
          const headerText = new G.Text(0, 0, headerDetails.title, 'shop3-headerTitle', 0.5, headerBg.width, headerBg.height);
          header.addChild(headerText);

          this._headers[keyIndex] = header; // Push the header. The position its in is the same as REG_DEAL_PRIORITY
        }
      });
    }

    this._repositionItems();
    this._initScrolling();
    this.addChild(this._coinBar); // Keep coinbar up
    this._showCornerBanner();
  }

  /**
   * Reorders special deals by price. And then puts the weekend deal at the front.
   * And then puts the first time offer at the front
   */
  _reorderSpecialDeals() {
    const reorderedArr = [];
    this._allSpecialDeals = this._allSpecialDeals.sort((a, b) => a.dealCoins - b.dealCoins); // Sort by coins
    for (const targetDeal of this._layoutDetails.specialDeals) { // Then sort according to given layout
      for (let i = 0; i < this._allSpecialDeals.length; i++) {
        const specDeal = this._allSpecialDeals[i];
        if (specDeal.dealName.indexOf(targetDeal) > -1) {
          reorderedArr.push(specDeal);
          this._allSpecialDeals.splice(i, 1); // Remove it from array
          break;
        }
      }
    }
    this._allSpecialDeals = reorderedArr.concat(this._allSpecialDeals); // Append on everything that remained
    const weekendEntry = this._allSpecialDeals.find((deal) => deal.dealType === SHOP_DEAL_TYPES.WEEKEND);
    if (weekendEntry) {
      const deal = this._allSpecialDeals.splice(this._allSpecialDeals.indexOf(weekendEntry), 1)[0];
      this._allSpecialDeals.unshift(deal);
    }
    const FTUEntry = this._allSpecialDeals.find((deal) => deal.dealType === SHOP_DEAL_TYPES.FIRST);
    if (FTUEntry) {
      const deal = this._allSpecialDeals.splice(this._allSpecialDeals.indexOf(FTUEntry), 1)[0];
      this._allSpecialDeals.unshift(deal);
    }
  }

  /**
   * Filter the pack.
   * If theres a free gift and the pack is coins_4k, swap it out for the free gift
   * @param {Object} pack
   * @param {string} pack.type
   * @returns {{type:string, deal:Object}}
   */
  _checkPack(pack) {
    if (pack.type.indexOf(this._layoutDetails.freeGift[0]) > -1 && this._currentViewMode.freeGift) {
      return {
        type: SHOP_DEAL_TYPES.FREE,
        deal: {
          price: `${OMT.language.getText('Free')}!`,
        },
      };
    }
    return pack;
  }

  /**
   * if a timed sale is on show the countdown timer
   */
  _showCornerBanner() {
    if (this._runningEvent && this._runningEvent.timeout && !this._saleEndTimeBanner && this._runningEvent.assetReplacement.saleIcon) {
      const { saleIcon } = this._runningEvent.assetReplacement;
      const saleTimeBanner = new Shop3_SaleTimeBanner({
        saleEndTime: this._runningEvent.timeout,
        assetImage: saleIcon,
      });
      this.addChild(saleTimeBanner);
      this._cornerBanner = saleTimeBanner;
    } else if (G.saveState.iapMultiplier > 1) {
      const banner = new Shop3_iapMultiplierBanner({
        text: '%MULT%x bonus on next purchase!',
        assetImage: `shop3_banner_${OMT.systemInfo.orientationKey}`,
        orientation: this._orientation,
      });
      this.addChild(banner);
      this._cornerBanner = banner;
    }
    this._repositionCornerBanner();
  }

  /**
   * repositions the sale end time banner on resize
   */
  _repositionCornerBanner() {
    if (!this._cornerBanner) return;
    if (this._headerCoinBar) { // coin bar in header show on right
      this._cornerBanner.y = Math.round(this._header.y - (this._cornerBanner.height / 2));
      this._cornerBanner.x = Math.round(this._closeBtn.x - (this._closeBtn.width / 2) - this._cornerBanner.width);

      // if timer will overlap the header text shift it to the left
      const headerOverlap = (this._headerText.width / 2) - this._cornerBanner.x;
      if (headerOverlap > 0) {
        this._headerText.x -= headerOverlap + 10;
      }
    } else { // coin bar not in header show on left
      this._cornerBanner.y = Math.round(this._header.y - (this._cornerBanner.height / 2));
      this._cornerBanner.x = Math.round(this._header.x - (this._header.width / 2) + 5);
    }
  }

  /**
   * Checks against the layout details if theres a regular deal that requires a \Discount/ tag attached to it visually
   * @param {Shop3_RegularDeal} deal
   */
  _giveDiscountTag(deal) {
    const requiredDiscountTag = this._layoutDetails.discount.indexOf(deal.dealData.type) > -1;
    if (!requiredDiscountTag) { return; } // eslint-disable-line no-useless-return
    const config = {};
    if (this._runningEvent) {
      if (this._runningEvent.assetReplacement && this._runningEvent.assetReplacement.discount) {
        config.tagAsset = this._runningEvent.assetReplacement.discount;
      }
    }
    deal.addDiscountTag(new Shop3_DiscountTag(15, config)); // TODO: make discount % dynamic somewhere.
  }

  /**
   * Initializes the scrolling component.
   * Only vertical is working right now
   */
  _initScrolling() {
    const viewHeight = Math.abs(this._dealContainer.y);
    if (this._orientation === ORIENTATION.horizontal) {
      // Do the horizontal somehow
      const yOffset = 230;

      const scrollRect = new Phaser.Rectangle(0, 0, game.width, game.height / this._gameScale - yOffset);
      const scrollBarConfig = new OMT_UI_HorizontalScrollBarConfig(12, 5, 8, 0.99, null, null, 'leaderboard', 'lb_scroll_knob_horizontal', true, null, false);
      this._scroll = new OMT_UI_HorizontalScrollArea(scrollRect, true, true, scrollBarConfig);
      this._scroll.allowOverScroll = true;
      this._dealContainer.addChild(this._scroll);
      this._itemsContainer.y += yOffset + this._itemsContainer.height / 2;
      this._scroll.y = -yOffset - (this._itemsContainer.height / 2);
      this._scroll.content.addChild(this._itemsContainer);
    } else { // For all else and vertical
      const yOffset = 35;
      const scrollRect = new Phaser.Rectangle(-game.width / 2, -yOffset, game.width, (yOffset / 2) + viewHeight + game.height / 2);
      this._scroll = new OMT_UI_VerticalScrollArea(scrollRect, false);
      this._scroll.allowOverScroll = true;
      this._dealContainer.addChild(this._scroll);
      this._itemsContainer.x = (game.width) / 2;
      this._itemsContainer.y = yOffset;
      this._scroll.content.addChild(this._itemsContainer);
    }
  }

  /**
   * get free gifts!
   * @param {number} giftSeed
   * @param {Object} giftConfig
   */
  _getGifts(giftSeed, giftConfig) {
    game.rnd.sow([giftSeed]);
    if (G.IAP) {
      return [G.gift.getGift('3H_Gift')];
    }
    if (game.rnd.realInRange(0, 1) < giftConfig.chanceToGetBooster) {
      return [
        G.gift.getGift('normalsGingyGift_boosters', true),
      ];
    }
    return [
      G.gift.getGift('normalsGingyGift_coins', true),
    ];
  }

  /**
   * When a regular deal button is pressed.
   * @param {Object} dealData
   * @param {Object} shopData
   * @param {OMT_UI_SquareButton} button
   */
  _onRegularDealClick(dealData, shopData, button) {
    if (dealData === SHOP_DEAL_TYPES.FREE) { // If free gift.
      const giftConfig = G.Utils.clone(G.json.settings.GingyGiftSettings);
      const giftSeed = G.saveState.getCooldownSeed('3h_gift_pick', 1000 * 60 * 60 * 3);

      // Vertical settings are in json/settings(IAP).js
      // Horizontal settings are done outside here...!
      if (this._orientation === ORIENTATION.horizontal) {
        giftConfig.gingyOffset.x = 180;
        giftConfig.gingyOffset.y = 350;
        giftConfig.gingyAngle = 10;
        giftConfig.gingyScale = G.OMTsettings.elements.giftUI.sideGingy.gingyScale.horizontal;
      }

      // eslint-disable-next-line prefer-const
      const onClaim = async () => {
        G.sb('mapGiftCollect').dispatch();
        G.saveState.setUserCooldown('3h_gift', '', 1000 * 60 * 60 * 3);
        await OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, 'HourlyGift', 3 * 60 * 60);
        this.state.checkForAds(() => {
          G.sb('pushWindow').dispatch(['coinShop', { orientation: this._orientation }]); // COINSHOP
        }, INTERSTITIAL_RULES.GIFT_GAIN);
      };

      giftConfig.onClaim = onClaim;
      giftConfig.gifts = this._getGifts(giftSeed, giftConfig);
      giftConfig.placement = G.BuildEnvironment.adPlacements.double3hGift;
      if (!game.incentivised() || !G.featureUnlock.mapGiftDoubling) {
        giftConfig.monetiseType = false;
      }
      giftConfig.twoFrameOpen = G.OMTsettings.elements.gifts.twoFrameOpen;

      G.sb('pushWindow').dispatch(['gift', giftConfig], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
      this.closeWindow();
    } else {
      ShopUtils.purchaseItem(shopData, button, this._coinBar, true, () => {
        this._removeIAPmultiplierBonus(shopData);
      });
    }
  }

  /**
   * When the show more offers button is clicked
   */
  _onMoreDealsClicked() {
    this._initWaitingIcon(); // Show waiting icon
    this._waitingIcon.x = this._button.x;
    this._waitingIcon.y = this._button.y;

    const tweenTime = 200;
    this._button.visible = true;
    const tw = game.add.tween(this._button) // Fade out button
      .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
    tw.onComplete.add(() => {
      this._button.visible = false;
      this._button.destroy();
      this._button = null; // Destroy it. Won't be needed

      this._showCompleteView(); // Show it
      this._removeWaitingIcon(); // Remove the icon
    });
    for (const section of this._allRegularDeals) { // Fade out all regular deals
      for (const deal of section) {
        const dealTween = game.add.tween(deal)
          .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
        this._allTweens.push(dealTween);
      }
    }
    this._allTweens.push(tw);

    this._currentViewMode = this._getComplicatedViewMode(); // Get the new mode details
  }

  /**
   * When a special deal is purchased, it checks if it was a first time offer purchase.
   * If it was, the entry was a first time offer, it tweens away and the normal offers tween in
   * @param {SHOP_DEAL_TYPES} dealType
   * @param {Object} shopData Facebook deal data
   * @param {G.Button} button Graphic origin
   * @param {Shop3_SpecialDeal} specDeal
   */
  _onSpecialDealPurchase(dealType, shopData, button, specDeal) {
    const isAnnuityPack = dealType === SHOP_DEAL_TYPES.ANNUITY;
    if (isAnnuityPack) { // If it is an annuity pack
      const annuityMan = G.saveState.annuityManager;
      const annuityDetails = AnnuityManager.getAnnuityDetails(shopData.productID, shopData.description);
      const annuityPack = annuityDetails.id;
      const existingAnnuity = annuityMan.getAnnuityPack(annuityPack);
      if (existingAnnuity) {
        annuityMan.claimAnnuityForMissingDays(annuityPack, (coins) => {
          let x = 0;
          let y = 0;
          if (button !== null) {
            x = game.world.bounds.x + button.worldPosition.x;
            y = button.worldPosition.y;
          }
          ShopUtils.createCoinParticles(x, y, coins, this._coinBar); // Claim the coins only
        });
        specDeal.changeToWaitAnnuity(); // Change it
        G.sb('annuityClear').dispatch();
        return; // Leave
      }
    }

    ShopUtils.purchaseItem(shopData, button, this._coinBar, true, () => {
      if (isAnnuityPack) {
        specDeal.changeToWaitAnnuity(); // Back end saving happens in ShopUtils
      } else {
        this._removeIAPmultiplierBonus();
        if (dealType === SHOP_DEAL_TYPES.FIRST) { // If the purchase was a
          let delayCounter = 0;
          const tweenTime = 750;
          let lastTween;

          this._allSpecialDeals.forEach((spec) => {
            spec.toggleButton(false);
            game.time.events.add(delayCounter * 100, () => {
              if (spec.game) {
                let tweenTarget;
                if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
                  // Fade out to bottom
                  tweenTarget = { alpha: 0, x: spec.x - game.width };
                } else {
                  // Fade out to left
                  tweenTarget = { alpha: 0, y: spec.y + game.height };
                }

                lastTween = game.add.tween(spec)
                  .to(tweenTarget, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
                lastTween.onComplete.add(() => {
                  spec.destroy();
                });
              }
            });
            delayCounter++;
          });

          this._allSpecialDeals = [];
          game.time.events.add(delayCounter * 100, () => {
            if (this.game && lastTween) {
              lastTween.onComplete.add(() => {
                const chosenSpecialDeals = this._getSpecialDeals(this._currentViewMode.viewMode === VIEWMODE.complete); // Get deals

                let index = this._allSpecialDeals.length;
                delayCounter = 0;
                chosenSpecialDeals.forEach((spec) => { // Create new deals with new indexes
                  const specialDeal = this._createSpecialDeal(spec, index);
                  specialDeal.alpha = 0;
                  this._itemsContainer.addChild(specialDeal);
                  this._allSpecialDeals.push(specialDeal);
                  index++;
                });
                this._reorderSpecialDeals();

                this._repositionItems(true); // Position properly
                let totalX = 0;
                this._allSpecialDeals.forEach((specialDeal) => { // Fade them in
                  game.time.events.add(delayCounter * 250, () => {
                    if (specialDeal.game) {
                      let targetX = 0;
                      let tweenTarget;

                      if (this._orientation === ORIENTATION.horizontal) {
                        // Fade in from top
                        targetX = Math.round(totalX + (specialDeal.width / 2));
                        totalX += specialDeal.width + 35 * this._gameScale;
                        specialDeal.x = targetX;
                        const targetY = Math.round(specialDeal.y);
                        specialDeal.y = (targetY - game.height) / 2;
                        tweenTarget = { alpha: 1, y: targetY };
                      } else {
                        // Fade in from right
                        specialDeal.x = (targetX + game.width) / 2;
                        tweenTarget = { alpha: 1, x: targetX };
                      }
                      game.add.tween(specialDeal)
                        .to(tweenTarget, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
                    }
                  });
                  delayCounter++;
                });
              });
            }
          });
        }
      }
    });
  }

  /**
   * Removes the IAP Multiplier bonus, if there is one
   */
  _removeIAPmultiplierBonus() {
    if (this._cornerBanner && G.saveState.iapMultiplier === 1) {
      this.removeChild(this._cornerBanner);
    }
  }

  /**
   * On resize
   */
  onResize() {
    if (this._scroll) { // resize the scroll view
      const yOffset = 35;

      // not sure what needs to happen for different orientations ??
      if (this._orientation === ORIENTATION.horizontal) {
        this._scroll.resizeScrollArea(game.width, game.height - yOffset);
      } else {
        this._scroll.resizeScrollArea(game.width, game.height - this._header.height);
      }
    }

    this._repositionMainHeader(); // Header repositions
    this._repositionCornerBanner();
    this._repositionItems(); // Items repositioned
    if (this._button && this._orientation === ORIENTATION.vertical) { // Button is positioned if its there
      this._button.y = Math.round(Math.min(-10 + (game.height - this._button.height) / 2, this._dealContainer.y - 10 - this._button.height / 2 + this._dealContainer.height
        + (game.height - this._dealContainer.height - this._header.height - (this._headerCoinBar ? 0 : this._coinBar.height)) / 2));
      this.addChild(this._button);
    }
  }

  /**
   * Items are repositioned.
   * Only vertical mode is considered right now
   * @param {boolean} tweenIt
   */
  _repositionItems(tweenIt) {
    const tweenTime = 200;
    if (this._orientation === ORIENTATION.horizontal) {
      this._dealContainer.y = 70;
      this._dealContainer.x = 10 - game.width / 2;

      let specialDealHeight = 0;
      let nextX = 0;
      for (const spec of this._allSpecialDeals) {
        spec.x = Math.round(nextX + (spec.width / 2));
        nextX += this._orientation === ORIENTATION.horizontal
          ? spec.width + 35 * this._gameScale
          : spec.width + 35;
        specialDealHeight = Math.max(specialDealHeight, spec.height);
      }

      const originY = -specialDealHeight / 2;
      const allConsideringDeals = this._allRegularDeals.map((section) => section.slice()); // copy array
      if (this._button) {
        nextX -= 5;
        allConsideringDeals[REG_DEAL_PRIORITY.coins].push(this._button);
      }
      for (let i = 0; i < allConsideringDeals.length; i++) { // Order packs by section
        const section = allConsideringDeals[i];
        if (section.length > 0) {
          let nextY = originY;
          let maxWidth = 0;
          for (let j = 0; j < section.length; j++) { // Position all the packs
            const deal = section[j];
            const dealBounds = deal.calculatedBounds ? deal.calculatedBounds : deal;
            if (nextY + dealBounds.height > (game.height / 2)) { // If its too wide, go to next line
              nextY = originY;
              nextX += maxWidth + 5;
              maxWidth = 0;
            }
            deal.x = Math.round(nextX + dealBounds.width / 2);
            deal.y = Math.round(nextY + dealBounds.height / 2);

            nextY += this._orientation === ORIENTATION.horizontal
              ? 12 * this._gameScale + dealBounds.height
              : 12 + dealBounds.height;
            maxWidth = Math.max(maxWidth, dealBounds.width);
          }
          nextX += maxWidth + 5; // set position for next line
        }
      }
    } else {
      if (this._headerCoinBar) {
        this._dealContainer.y = this._header.y + (this._header.height / 2) + 40;
      } else {
        this._dealContainer.y = this._coinBar.y + 30 + this._coinBar.height / 2;
      }

      // Position special deals
      let specialDealWidth = 0;
      let nextY = 0;
      this._allSpecialDeals.forEach((spec) => {
        const targetY = Math.round(nextY + (spec.height / 2));
        if (tweenIt) {
          game.add.tween(spec)
            .to({ y: targetY }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
        } else {
          spec.y = targetY;
        }
        nextY += spec.height + 35;
        specialDealWidth = Math.max(specialDealWidth, spec.width);
      });

      // Position regular deals
      if (this._currentViewMode.viewMode === VIEWMODE.simplified) { // In simplified view, remove some padding
        nextY -= 20;
      }
      const originX = -specialDealWidth / 2;
      for (let i = 0; i < this._allRegularDeals.length; i++) { // Order packs by section
        const section = this._allRegularDeals[i];
        if (section.length > 0) {
          if (this._currentViewMode.viewMode === VIEWMODE.complete) { // If complete view, show the header before packs
            if (this._headers[i]) {
              const targetY = nextY + 5 + this._headers[i].height / 2;
              if (tweenIt) {
                game.add.tween(this._headers[i])
                  .to({ y: targetY }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
              } else {
                this._headers[i].y = targetY;
              }
              nextY += 25 + this._headers[i].height; // Do your best to space it evenly across everything
            }
          }
          let nextX = originX;
          let maxHeight = 0;
          for (let j = 0; j < section.length; j++) { // Position all the packs
            const deal = section[j];
            if (nextX + deal.width > specialDealWidth) { // If its too wide, go to next line
              nextX = originX;
              nextY += maxHeight + 5;
              maxHeight = 0;
            }
            const targetX = Math.round(nextX + deal.width / 2);
            const targetY = Math.round(nextY + deal.height / 2);
            if (tweenIt) {
              game.add.tween(deal)
                .to({ x: targetX, y: targetY }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
            } else {
              deal.x = targetX;
              deal.y = targetY;
            }

            nextX += 12 + deal.width;
            maxHeight = Math.max(maxHeight, deal.height);
          }
          nextY += maxHeight + 5; // set position for next line
        }
      }
    }
  }

  /**
   * Positions the main header, coin bar, and close button
   */
  _repositionMainHeader() {
    this._headerText.x = 0;
    if (this._orientation === ORIENTATION.horizontal) {
      this._header.scale.setTo(this._gameScale);
    }
    this._header.y = (this._header.height - game.height) / 2;

    this._closeBtn.x = this._header.x - 5 + (this._header.width - this._closeBtn.width) / 2;
    this._closeBtn.y = this._header.y + 5 - (this._header.height - this._closeBtn.height) / 2;

    this._coinBar.x = 0;
    if (this._headerCoinBar) {
      this._coinBar.y = this._header.y;
      this._coinBar.x = OMT.systemInfo.orientation === ORIENTATION.horizontal
        ? this._header.x - this._header.width / 4
        : this._header.x - (this._header.width - this._coinBar.width) / 2;
    } else {
      const yOffset = 10;
      this._coinBar.y = this._header.y + yOffset + (this._header.height + this._coinBar.height) / 2;
    }
  }

  /**
   * @returns {boolean} is the window fullscreen? (for low res landscape purposes)
   */
  get isFullscreen() {
    return true;
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.coinShop = Window_CoinShop3;
