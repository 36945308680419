import { Action } from './Action';

const MOVEMENT_SPEED = 0.055;
const ORIGINAL_SIZE = 1;
const MAX_SCALE_SIZE = 0.25;

/**
 * Class for the move reject animation that occurs when
 * - Trying to move a candy across a wall
 */
export class ActionMoveReject extends Action {
  /**
   * initialize the action.
   * @param {Candy} candy1
   * @param {Candy} candy2
   * @param {boolean} forceMove
   */
  _init(candy1, candy2, forceMove) {
    this._candy1 = candy1; // Candy that is trying to move
    this._candy1orgParent = this._candy1.parent; // Original candy parent
    this._deltaCandy1Movement = { // The end destination position
      x: (candy2.x - this._candy1.x) / 4,
      y: (candy2.y - this._candy1.y) / 4,
    };

    this._forceMove = forceMove; // Force move?
    this._reverse = false; // In reverse mode

    this._startAnimation();
  }

  /**
   * Update method, called by ActionManager
   */
  update() {
    this._updateAnimation(); // Update anim
    this.progress += MOVEMENT_SPEED * G.deltaTime; // Increase progress

    // move animation progress is 100% or more
    if (this.progress >= 1) {
      if (this._reverse) { // If we're back at 100% progress and in reverse, finish it
        this._finishAnimation();
        this.finish();
        return;
      }

      if (!this._reverse) { // If we're 100% and not in reverse, go do reverse anim
        this._reverse = true;
        this._returnToHome();
      }
    }
  }

  /**
   * Initalize variables needed for animation
   */
  _startAnimation() {
    const { gameHooks } = this._board;
    gameHooks.playSound('exchange');

    this._candy1anim = {
      startX: this._candy1.x,
      deltaX: this._deltaCandy1Movement.x,
      startY: this._candy1.y,
      deltaY: this._deltaCandy1Movement.y,
    };

    this._board.candiesLayer.movingCandyGroup.add(this._candy1);

    this._candy1.bringToTop();
    this.progress = G.IMMEDIATE ? 1 : 0;
  }

  /**
   * Returns the candy back to where it should be
   */
  _returnToHome() {
    this._candy1anim = {
      startX: this._candy1.x,
      deltaX: -this._deltaCandy1Movement.x,
      startY: this._candy1.y,
      deltaY: -this._deltaCandy1Movement.y,
    };

    this._board.candiesLayer.movingCandyGroup.add(this._candy1);

    this._candy1.bringToTop();
    this.progress = G.IMMEDIATE ? 1 : 0;
  }

  /**
   * Snaps the candy back to where it should be
   */
  _finishAnimation() {
    this._candy1.x = this._board.cellXToPxIn(this._candy1.cellX);
    this._candy1.y = this._board.cellYToPxIn(this._candy1.cellY);
    this._candy1.scale.setTo(ORIGINAL_SIZE);
    this._candy1orgParent.add(this._candy1);
  }

  /**
   * Updates animation based on progress time
   */
  _updateAnimation() {
    let animProgress;
    if (this._reverse) {
      animProgress = Phaser.Easing.Sinusoidal.InOut(this.progress);
      this._candy1.scale.setTo((ORIGINAL_SIZE + MAX_SCALE_SIZE) - (MAX_SCALE_SIZE * animProgress));
    } else {
      animProgress = Phaser.Easing.Bounce.Out(this.progress);
      this._candy1.scale.setTo(ORIGINAL_SIZE + (MAX_SCALE_SIZE * animProgress));
    }

    this._candy1.x = this._candy1anim.startX + (animProgress * this._candy1anim.deltaX);
    this._candy1.y = this._candy1anim.startY + (animProgress * this._candy1anim.deltaY);
  }

  /**
   * Destroy!
   */
  destroy() {
    super.destroy();
    this._candy1 = null;
    this._candy1orgParent = null;
    this._deltaCandy1Movement = null;
  }
}
