const FADE_DURATION = 250;

/**
 * class for the LoadingIndicatorDisplay
 */
export class UI_LoadingIndicatorDisplay extends Phaser.Group {
  /**
   * constructor
   */
  constructor() {
    super(game);

    this._initGraphics();

    this.visible = false;
    this.alpha = 0;
  }

  /**
   * init the loading indicator graphics
   */
  _initGraphics() {
    this._waitingIcon = G.makeImage(0, 0, 'waiting_icon', [0.5, 0.5], this);
    this.addChild(this._waitingIcon);

    const waitingIconAnimation = G.Animations.getWaitingIconAnimation(this._waitingIcon);
    this._waitingTween = waitingIconAnimation.animate(game, this);
    this._waitingTween.pause();
  }

  /**
   * show the indicator
   */
  show() {
    this.visible = true;
    this.alpha = 0;

    if (this._fadeTween) this._fadeTween.stop();
    this._fadeTween = game.add.tween(this);
    this._fadeTween.to({ alpha: 1 }, FADE_DURATION, Phaser.Easing.Quadratic.Out, false);
    this._fadeTween.start();

    this._waitingTween.resume();
  }

  /**
   * hide the indicator
   * @returns {Promise}
   */
  async hide() {
    return new Promise((resolve) => {
      if (this._fadeTween) this._fadeTween.stop();
      this._fadeTween = game.add.tween(this);
      this._fadeTween.to({ alpha: 0 }, FADE_DURATION, Phaser.Easing.Quadratic.Out, false);
      this._fadeTween.onComplete.addOnce(() => {
        this.visible = false;
        this._waitingTween.pause();
        resolve();
      });
      this._fadeTween.start();
    });
  }

  /**
   * destruction method
   */
  destroy() {
    if (this.parent) this.parent.removeChild(this);
    super.destroy();
  }
}
