import { TokenLayer } from './TokenLayer';

const SPREAD_DIRECTIONS = {
  UP: 1, DOWN: 2, LEFT: 3, RIGHT: 4, INCREASE: 5,
};

/**
 * Base TokenLayer for spreading type tokens.
 */
export class TokenLayer_Spreading extends TokenLayer {
  /**
   * constructor
   * @param {Board} board
   * @param {Object} config see TokenLayer comments for additional properties
   * @param {string} config.tokenHitEventId hit event id to listen for
   * @param {boolan} allowIncrease allow increase in hp of tokens
   */
  constructor(board, config, allowIncrease) {
    super(board, config);

    this._decreasedToken = false;
    this._addGlobalListeners(config.tokenHitEventId);

    this._spreadActions = [
      SPREAD_DIRECTIONS.UP,
      SPREAD_DIRECTIONS.DOWN,
      SPREAD_DIRECTIONS.LEFT,
      SPREAD_DIRECTIONS.RIGHT,
    ];
    if (allowIncrease) this._spreadActions.push(SPREAD_DIRECTIONS.INCREASE);
  }

  /**
   * set global event listeners
   * @param {string} tokenHitEventId
   */
  _addGlobalListeners(tokenHitEventId) {
    this._signalBindings = [
      G.sb(tokenHitEventId).add(this._onHitMatchingToken, this),
      G.sb('actionQueueEmptyAfterMove').add(this._onActionQueueEmptyAfterMove, this),
    ];
  }

  /**
   * remove global listeners set by _addGlobalListeners()
   */
  _removeGlobalListeners() {
    for (const signalBinding of this._signalBindings) signalBinding.detach();
    this._signalBindings.length = 0;
  }

  /**
   * on hit of matching token. Flag that the token was decreased.
   */
  _onHitMatchingToken() {
    this._decreasedToken = true;
  }

  /**
   * spread the related token if _onHitMatchingToken() was not called this move.
   */
  _onActionQueueEmptyAfterMove() {
    if (!this._decreasedToken) this._spread();
    this._decreasedToken = false;
  }

  /**
   * spread dirt to neighboring cells
   */
  _spread() {
    const actions = this._spreadActions;
    const len = this.children.length;
    const iRnd = game.rnd.between(0, len - 1);

    for (let i = 0; i < len; i++) {
      const token = this.children[(i + iRnd) % len];

      Phaser.ArrayUtils.shuffle(actions);
      for (let actionIndex = 0; actionIndex < actions.length; actionIndex++) {
        const action = actions[actionIndex];
        switch (action) {
          case SPREAD_DIRECTIONS.UP:
            if (this._isSpreadPossible(token.cellX, token.cellY - 1)) {
              this._spreadToken(token.cellX, token.cellY - 1);
              return;
            }
            break;
          case SPREAD_DIRECTIONS.DOWN:
            if (this._isSpreadPossible(token.cellX, token.cellY + 1)) {
              this._spreadToken(token.cellX, token.cellY + 1);
              return;
            }
            break;
          case SPREAD_DIRECTIONS.LEFT:
            if (this._isSpreadPossible(token.cellX - 1, token.cellY)) {
              this._spreadToken(token.cellX - 1, token.cellY);
              return;
            }
            break;
          case SPREAD_DIRECTIONS.RIGHT:
            if (this._isSpreadPossible(token.cellX + 1, token.cellY)) {
              this._spreadToken(token.cellX + 1, token.cellY);
              return;
            }
            break;
          case SPREAD_DIRECTIONS.INCREASE:
            if (token.hp < this._config.maxHp) {
              token.increaseHp();
              return;
            }
            break;
          default:
            break;
        }
      }
    }
  }

  /**
   * check if spreading is possible to the specified position.
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  _isSpreadPossible(cellX, cellY) {
    return this._board.isCellOnBoard(cellX, cellY) && !this.getToken(cellX, cellY);
  }

  /**
   * spread the related token to the specified position.
   * @param {number} cellX
   * @param {number} cellY
   */
  _spreadToken(cellX, cellY) {
    G.sb('onCollectableAdded').dispatch(this._config.collectableType);
    const token = this._createToken(cellX, cellY, 1);
    // scale in token on spread
    game.add.tween(token.scale).from({ x: 0, y: 0 }, 500, Phaser.Easing.Sinusoidal.Out, true);
  }
}
