/* eslint-disable no-self-assign */
/* eslint-disable no-use-before-define */

import RuntimeSpritesheetManager, { RUNTIME_SPRITESHEET_IDS } from '@omt-components/Imaging/Spritesheets/RuntimeSpritesheetManager';
import WorldMap2_Util from '../WorldMap2_Util';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

const propKeys = {
  LEFT_FILLER: 'leftFiller',
  RIGHT_FILLER: 'rightFiller',
  LEFT: 'left',
  RIGHT: 'right',
  BEHIND_RIVER: 'behindRiver',
  ABOVE_RIVER: 'aboveRiver',
};

export class WorldMapPropPoolGroup extends G.PoolGroup {
  /**
   * The class for WorldMapProps that extends G.PoolGroup. Used in the Prefab Editor
   * @param {Phaser.Group} aboveRiver
   * @param {Boolean} prefabEditor
   */
  constructor(aboveRiver, prefabEditor) {
    super(WorldMapDynamicAssetProp, [prefabEditor]);

    // Copies all functions (!constructor, !prototype, !name) from WorldMapProps to this class
    WorldMap2_Util.copyClassProperties(this, WorldMapProps);
    WorldMap2_Util.copyClassProperties(this, WorldMapProps.prototype);

    this._init(aboveRiver); // Do the init in WorldMapProps
  }
}

export class WorldMapPropBatchPoolGroup extends G.BatchPoolGroup {
  /**
   * The class for WorldMapProps that extends G.BatchPoolGroup. Used in the OMT games
   * @param {Phaser.Group} aboveRiver
   * @param {Boolean} prefabEditor
   */
  constructor(aboveRiver, prefabEditor) {
    super(WorldMapDynamicAssetProp, [prefabEditor]);

    // Copies all functions (!constructor, !prototype, !name) from WorldMapProps to this class
    WorldMap2_Util.copyClassProperties(this, WorldMapProps);
    WorldMap2_Util.copyClassProperties(this, WorldMapProps.prototype);

    this._init(aboveRiver); // Do the init in WorldMapProps
  }
}

/**
 * The main class for WorldMapProps. It is similar to all the other pool groups in WorldMap2
 * (It would be nice if JavaScript could do interfaces)
 */
export class WorldMapProps {
  /**
   * Figures out which class to use for WorldMapProps.
   * If the Canvas renderer is being used, it will use G.PoolGroup. If its WebGL, it will use G.BatchPoolGroup
   */
  static getWorldMapProps(prefabEditorMode) {
    const isWebGL = game.renderType === 2 && !prefabEditorMode;
    return isWebGL ? WorldMapPropBatchPoolGroup : WorldMapPropPoolGroup;
  }

  /**
   * The Pool Group for props.
   * aboveLayerRiver is for props that should be shown above the river layer.
   * @param {Phaser.Group} behindRiverLayer
   */
  _init(behindRiverLayer) {
    this._behindRiverLayer = behindRiverLayer; // Keep reference
    this._actives = [];
    // Prop data
    this._propLibrary = {};
    for (const key in propKeys) { // eslint-disable-line guard-for-in
      const value = propKeys[key];
      const propGroup = G.json['assets/worldMap/propLayout'][value];
      if (propGroup) {
        this._propLibrary[value] = propGroup;
      }
    }
  }

  /**
   * Returns a snapshot of which props are active at the time of calling
   * @returns {Array<WorldMapDynamicAssetProp>}
   */
  get actives() {
    return this._actives.concat([]);
  }

  /**
   * Tiles move by delta Y
   * @param {number} deltaY
   */
  moveTiles(deltaY) {
    // update active segments
    for (let i = this._actives.length - 1; i >= 0; i--) {
      this._actives[i].y += deltaY;
    }
  }

  /**
   * Kills the tile at index
   * @param {number} index
   */
  killTile(index) {
    const allIndex = this._actives.filter((prop) => prop.index === index);
    for (const prop of allIndex) {
      prop.kill();
      this._actives.splice(this._actives.indexOf(prop), 1);
    }
  }

  /**
   * Adds a tile at index
   * @param {number} index
   * @param {number} yPos
   * @param {number} textureIndex
   * @param {number} halfXPoint
   * @param {number} seed
   * @param {boolean} isRiver
   * @param {ORIENTATION} orientation
   * @returns {Array<WorldMapDynamicAssetProp>}
   */
  addTile(index, yPos, textureIndex, halfXPoint, seed, isRiver, orientation) {
    const rtrArr = []; // There could be multiple props
    const positions = G.OMTsettings.elements.worldMap.tilePropCenter[this._findOrientationKey(orientation)][textureIndex];

    for (let i = 0; i < positions.length; i++) {
      let targetLayer = this;
      const isAboveRiver = positions[i].pool.indexOf('aboveRiver') > -1;
      const targetPropData = this._determinePropTarget(positions[i].pool, isRiver);
      if (!targetPropData) { continue; }

      const prop = this.getFreeElement();
      const targetPropList = targetPropData.target; // Find out which pool to use
      const seedPrefab = (seed % targetPropList.length); // Determine prefab by seed
      const prefabData = targetPropList[seedPrefab]; // Prefab data
      if (isRiver) {
        if (!isAboveRiver) {
          targetLayer = this._behindRiverLayer; // Change targetLayer if behind river
        }
      }

      const textureId = `worldMap2_propPrefab_${targetPropData.name}_${targetPropList.indexOf(prefabData)}`;
      prop.init(index, i, textureId, { // init prefab with data
        group: targetPropList,
        prefab: prefabData,
        propName: targetPropData.name,
      });

      this._actives.push(prop); // Push to actives
      targetLayer.addChild(prop); // Add to group
      rtrArr.push(prop); // Add to return array
    }

    this.positionPropOnTile(rtrArr, textureIndex, yPos, halfXPoint, orientation);
    return rtrArr;
  }

  /**
   * Finds the orientation key so the correct positionings can be retrieved
   * @param {number} orientation
   * @returns {string}
   */
  _findOrientationKey(orientation) {
    for (const key in ORIENTATION) {
      if (orientation === ORIENTATION[key]) {
        return key;
      }
    }
    return 'vertical';
  }

  /**
   * Places the asset where they should be
   * @param {Array<WorldMapDynamicAssetProp} props
   * @param {number} textureIndex
   * @param {number} yPos
   * @param {number} halfXPoint
   * @param {ORIENTATION} orientation
   */
  positionPropOnTile(props, textureIndex, yPos, halfXPoint, orientation) {
    const positions = G.OMTsettings.elements.worldMap.tilePropCenter[this._findOrientationKey(orientation)][textureIndex];
    for (const prop of props) {
      let targetPosition;
      const propNameSegments = prop.textureId.split('_'); // Split the name
      for (let i = 0; i < positions.length && !targetPosition; i++) { // Find out which position to use
        const positionGroup = positions[i];
        const positionSections = positionGroup.pool.indexOf('|') > -1 ? positionGroup.pool.split('|') : [positionGroup.pool]; // Consolidate into array
        for (const sect of positionSections) {
          if (propNameSegments.find((propName) => propName === sect)) { // Find the exact match
            targetPosition = positionGroup;
            break;
          }
        }
      }

      // Position prefab
      const orientationAdjustment = orientation === ORIENTATION.vertical ? 1 : 1.3;
      prop.x = halfXPoint + targetPosition.x;
      prop.y = yPos + targetPosition.y;
      if (prop.x <= halfXPoint) { // Flip prefab to face the other side if not facing the correct side
        prop.scale.set(1 * orientationAdjustment, 1 * orientationAdjustment);
      } else {
        prop.scale.set(-1 * orientationAdjustment, 1 * orientationAdjustment);
      }
    }
  }

  /**
   * Determines which prop and name to use
   * @param {string} targetPool
   * @param {boolean} isRiver
   * @returns {Array<target:Array<Object>>, name:string}
   */
  _determinePropTarget(targetPool, isRiver) {
    let consideration;
    const nots = [];
    if (targetPool.toLowerCase().indexOf('!') > -1) {
      const notto = targetPool.split('!');
      for (let i = 1; i < notto.length; i++) { nots.push(notto[i]); }
    }
    if (targetPool.indexOf('|') > -1) {
      const pieces = targetPool.split('|');
      for (const piece of pieces) {
        if (piece && piece !== '' && !consideration && this._isPieceNotPartOfNot(nots, piece)) {
          const pieceIsRiver = piece.toLowerCase().indexOf('river') > -1;
          if ((isRiver && pieceIsRiver) || !(isRiver || pieceIsRiver)) {
            consideration = piece;
            break;
          }
        }
      }
    } else if (targetPool.toLowerCase().indexOf('filler') > -1) {
      if (this._isPieceNotPartOfNot(nots, targetPool)) {
        consideration = targetPool;
      }
    } else {
      const isPoolARiver = targetPool.toLowerCase().indexOf('river') > -1;
      if ((isRiver && isPoolARiver && this._isPieceNotPartOfNot(nots, targetPool)) || !(isRiver || isPoolARiver)) {
        consideration = targetPool;
      }
    }

    if (this._propLibrary[consideration]) {
      return { target: this._propLibrary[consideration], name: consideration };
    }
    return null;
  }

  /**
   * Checks if the word contains a piece that is in the not array.
   * If it is, it should not be there (false)
   * If it isn't, it'll be true
   * @param {Array<string>} notArr
   * @param {string} wordPiece
   * @returns {boolean}
   */
  _isPieceNotPartOfNot(notArr, wordPiece) {
    if (notArr.indexOf('river') > -1) {
      return wordPiece.toLowerCase().indexOf('river') === -1;
    }
    return true;
  }

  /**
   * Re-adds the given props to their parent, in order of generation id.
   * @param {Array<WorldMapDynamicAssetProp>} givenProps
   */
  reAddPropToLayer(givenProps) {
    const tileProps = givenProps.concat([]).sort((a, b) => a.propId - b.propId);
    for (const prop of tileProps) {
      if (prop.parent) {
        prop.parent.addChild(prop);
      }
    }
  }
}

class WorldMapDynamicAssetProp extends Phaser.Sprite {
  /**
   * The Image for the props
   */
  constructor(prefabEditorMode) {
    super(game);

    this._debugMode = G.saveState.sessionData.worldMapPropDebug; // Debug mode check
    this._prefabEditorMode = prefabEditorMode;

    if (!this._prefabEditorMode) {
      this._signalToken = G.sb('WorldMapPropDebug').addOnce(() => {
        this._debugMode = true;
        G.saveState.sessionData.worldMapPropDebug = true;
        this._activateDebug();
      });
    }
  }

  /**
   * Returns the container holding the prefab. Used in Prefab Editor only
   * @returns {(Phaser.Group|null)}
   */
  get props() {
    if (this._prefabEditorMode) {
      return this._prop;
    }
    return null;
  }

  /**
   * Returns the prefab data for this object. Prefab Editor only
   * @returns { group: Array<Object>, prefab: Array<{asset:string, x:number, y:number}>, propName:string }
   */
  get prefabData() {
    if (this._prefabEditorMode) {
      return this._prefabData;
    }
    return null;
  }

  /**
   * Returns the prop's textureid
   * @returns {string}
   */
  get textureId() {
    return this._textureId;
  }

  /**
   * Returns the prop index number
   * @returns {number}
   */
  get propId() {
    return this._propIndex || 0;
  }

  /**
   * Destroy!! Not the same as kill
   */
  destroy() {
    if (this._signalToken) {
      if (this._signalToken.detach) {
        this._signalToken.detach();
      }
      this._signalToken = null;
    }
    super.destroy();
  }

  /**
   * Init the prop based on texture id and prefab data
   * @param {number} index
   * @param {string} textureId
   * @param {{ group: Array<Object>, prefab: Array<{asset:string, x:number, y:number}>, propName: string }} prefabData
   */
  init(index, pId, textureId, prefabData) {
    this.index = index;
    this._propIndex = pId;
    this._drawProp(textureId, prefabData);
    this.revive();
  }

  /**
   * Consults the cache if the prop is already rendered. If not, it'll draw it and save it to cache
   * @param {string} textureId
   * @param {{ group: Array<Object>, prefab: Array<{asset:string, x:number, y:number}>, propName: string }} prefabData
   */
  _drawProp(textureId, prefabData) {
    this._prefabData = prefabData;
    this._textureId = textureId;
    if (!this._prefabEditorMode) { // OMT Mode
      if (this._debugMode) textureId += '-debug'; // alt texture name for debug

      // get / create texture for prop
      const runtimeSpritesheetManager = RuntimeSpritesheetManager.getInstance();
      let texture = runtimeSpritesheetManager.getTexture(textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP);
      if (!texture) {
        // create prefab instance and draw it to a spritesheet
        const { prop, anchorX, anchorY } = this.assemblePrefab(prefabData.prefab);
        if (this._debugMode) this._drawDebugRect(prop);
        runtimeSpritesheetManager.addSprite(prop, textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP);
        texture = runtimeSpritesheetManager.getTexture(textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP);
        // store the anchor in the frame data
        const { transform } = runtimeSpritesheetManager.getFrameData(textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP);
        transform.anchor = [anchorX, anchorY];
        // destroy template prop
        prop.destroy();
      }

      // update the texture and apply the stored transform data
      G.changeTexture(this, texture);
      const { transform } = runtimeSpritesheetManager.getFrameData(textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP);
      this.anchor.set(transform.anchor[0], transform.anchor[1]);
    } else { // Prefab editor mode
      const { prop } = this.assemblePrefab(prefabData.prefab);
      this.name = textureId;
      this._prop = prop;
      this.addChild(prop);
    }
  }

  /**
   * Assembles the prefab image based on whats in the JSON
   * @param {Array<{asset:string, x:number, y:number, scale:{x:number, y:number}}>} prefabData
   * @returns {prop: Phaser.Group, anchorX:number, anchorY:number}
   */
  assemblePrefab(prefabData) {
    const prop = new Phaser.Group(game, null);
    const offset = new Phaser.Group(game, prop);
    for (const data of prefabData) {
      const img = G.makeImage(data.x, data.y, `prefab-elements/${data.asset}`, 0, offset);
      if (data.scale) {
        img.scale.set(data.scale.x || 1, data.scale.y || 1);
      }
      if (this._prefabEditorMode) {
        img.prefabData = data;
      }
    }
    // calculate the bounds
    const bounds = offset.getBounds(offset);
    const anchorX = -bounds.x / bounds.width;
    const anchorY = -bounds.y / bounds.height;
    if (this._prefabEditorMode) {
      prop.container = offset;
    }
    return { prop, anchorX, anchorY };
  }

  /**
   * Kills the prop
   */
  kill() {
    this.clearProp();
    super.kill();
  }

  /**
   * Mostly used by prefab editor
   */
  clearProp() {
    if (this._prop) {
      this._removeDebugRect(this);
      this.removeChild(this._prop);
      this._prop.destroy();
    }
    this._prop = null;
    this._textureId = '';
  }

  /**
   * Toggles on the boundary on the prefab. Uses a specific colour if given
   * @param {boolean} b
   * @param {number} colour
   */
  toggleDebugBoundary(b, colour = 0) {
    if (this._prefabEditorMode) {
      if (b) {
        this._debugBoundary = this._drawDebugRect(this, colour);
      } else {
        this._removeDebugRect(this);
      }
    }
  }

  /**
   * draw debug bounds on the sprite
   * @param {Phaser.Sprite} sprite
   * @param {number} colour
   */
  _drawDebugRect(sprite, colour = 0) {
    let bounds;
    let target;
    if (this._prefabEditorMode) {
      let minX = 0;
      let minY = 0;
      for (const img of this._prop.container.children) { // Calculate bounds
        if (img.x < minX) minX = img.x;
        if (img.y < minY) minY = img.y;
      }

      bounds = { // If prefab editor mode, use calculations of prop.
        x: this._prop.x + minX,
        y: this._prop.y + minY,
        height: this._prop.height,
        width: this._prop.width,
      };
      target = this;
    } else {
      bounds = sprite.getBounds(); // If OMT mode, use sprite.getBounds()
      target = sprite;
    }

    const debugGroup = new Phaser.Group(game, target);
    debugGroup.x = bounds.x;
    debugGroup.y = bounds.y;
    const debugBoundary = new Phaser.Graphics(game);
    debugBoundary.lineStyle(2, colour);
    debugBoundary.beginFill(0, 0);
    debugBoundary.drawRect(0, 0, bounds.width, bounds.height);
    debugBoundary.endFill();
    debugGroup.addChild(debugBoundary);

    const debugText = new G.Text(0, 0, this._prefabData.group.indexOf(this._prefabData.prefab), {
      font: G.Text.defaultFont,
      fill: '#FFFFFF',
      fontSize: '30px',
      stroke: colour,
      strokeThickness: 5,
    }, 0.5);
    debugText.x = bounds.width / 2;
    debugText.y = bounds.height / 2;
    debugGroup.addChild(debugText);
    return debugGroup;
  }

  /**
   * Removes the bounding sprite if its there
   * @param {Phaser.Group} sprite
   */
  _removeDebugRect(sprite) {
    if (sprite && this._debugBoundary) {
      sprite.removeChild(this._debugBoundary);
      this._debugBoundary.destroy();
      this._debugBoundary = null;
    }
  }

  /**
   * Activates debug mode.
   * Draws the neccessary components and prop switch button effect
   */
  _activateDebug() {
    this._debugMode = true;
    this._drawProp(this._textureId, this._prefabData);

    // rotate textures on click
    this.inputEnabled = true;
    this.events.onInputDown.add(() => {
      const targetPrefab = WorldMap2_Util.getEntryInArrayLoop(this._prefabData.group, (this._prefabData.group.indexOf(this._prefabData.prefab) + 1));
      const textureId = `worldMap2_propPrefab_${this._prefabData.propName}_${this._prefabData.group.indexOf(targetPrefab)}`;
      this._drawProp(textureId, {
        group: this._prefabData.group,
        prefab: targetPrefab,
        propName: this._prefabData.propName,
      });
    });
  }
}
