import OMT_VILLAINS from '../../../../../OMT_UI/OMT_Villains';
import LevelNumberTabBase from '../base/LevelNumberTabBase';

export default class LevelNumberTab extends LevelNumberTabBase {
  /**
   * Draws the bg of the level number tab
   * @param {{parent:Phaser.DisplayObject, initialWidth:number, initialHeight:number, levelNumber:number, difficult:DIFFICULTY}} config
   */
  _drawBg(config) {
    super._drawBg(config);
    const { isHardLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();

    this._showHardLevel = isHardLevel;
    this._showSuperHardLevel = isSuperHardLevel;

    this._UI = G.UI;
    const getJson = function getJson(key) { return G.json[key]; };

    // json thing
    this._uiElement = this._UI.createUIElement(
      game,
      getJson('ui/board/levelNumberTab'),
      1, 1,
      getJson,
      this._filterVariant.bind(this),
    );

    config.parent.add(this._uiElement.rootElement);
    this._UI.resize(this._uiElement.rootElement, config.initialWidth, config.initialHeight);

    this._slidedIn = false;
    this._positionAnimator = this._doCreatePositionAnimator(
      this._uiElement.exports.getFirst('moveable').rootElement,
      this._uiElement.exports.getFirst('helper_animate_source_position').rootElement,
    );
  }

  /**
   * Draws the level number
   * @param {{levelNumber:number, difficult:DIFFICULTY}} config
   */
  _drawLevelNumber(config) {
    super._drawLevelNumber(config);
    this._setText(this._uiElement.exports, 'text_level', config.levelNumber.toString());
  }

  /**
   * Filter which level number tab graphic to use based on different statuses
   * This is now controlled by the Villains manager since its texture is only controlled by the level difficulty
   * @param {string} exportNames
   * @returns {boolean}
   */
  _filterVariant(exportNames) {
    return OMT_VILLAINS.filterVariant(exportNames);
  }

  /**
   * Sets the text of the level number into the level number
   * @param {Array} exports
   * @param {string} exportName
   * @param {string} textValue
   */
  _setText(exports, exportName, textValue) {
    exports.forEvery(exportName, (element) => {
      element.setText(textValue);
    });
  }

  /**
   * Creates the position animator used for elements made with the JSON ui elements
   * @param {any} moveable
   * @param {any} hiddenPosObject
   * @returns {positionAnimator}
   */
  _doCreatePositionAnimator(moveable, hiddenPosObject) {
    const { createPositionAnimator } = G.AnimationUtils;
    const positionAnimator = createPositionAnimator(game, moveable, true, 'visible');
    positionAnimator.addPosition('hidden', hiddenPosObject);
    positionAnimator.setPosition('hidden');
    return positionAnimator;
  }

  /**
   * Animates the level number tab in
   */
  animateSlideIn() {
    super.animateSlideIn();
    this._slidedIn = true;
    this._positionAnimator.animateToPosition('visible');
  }

  /**
   * Animates out the level number tab
   */
  animateSlideOut() {
    super.animateSlideOut();
    this._slidedIn = false;
    this._positionAnimator.animateToPosition('hidden');
  }


  /**
   * When resize hapens
   * @param {number} width
   * @param {number} height
   */
  resize(width, height) {
    super.resize();
    this._UI.resize(this._uiElement.rootElement, width, height);
    // HACK: resize resets position of rootElement which was previously set to 'hidden'
    if (!this._slidedIn) {
      this._positionAnimator.setPosition('hidden');
    }
  }
}
