import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

/**
 * Fullscreen toggle Button
 */
export default class OMT_UI_FullScreenButton extends UI_GButtonES6 {
  /**
   * constructor
   */
  constructor() {
    super(0, 0, 'small_button_yellow', () => { this._toggleFullscreen(); });
    this._icon = G.makeImage(0, 0, game.scale.isFullScreen ? 'fullscreen_exit_icon' : 'fullscreen_enter_icon', [0.5, 0.5]);
    this.addChild(this._icon);
    this._fullScreenState = game.scale.isFullScreen;
  }

  /**
     * toggle fullscreen
     */
  _toggleFullscreen() {
    if (game.scale.isFullScreen) {
      game.scale.stopFullScreen();
    } else {
      game.scale.startFullScreen(false);
    }
  }

  /**
     * phaser update method
     */
  update() {
    if (game.scale.isFullScreen !== this._fullScreenState) {
      this._icon.changeTexture(game.scale.isFullScreen ? 'fullscreen_exit_icon' : 'fullscreen_enter_icon');
      this._fullScreenState = game.scale.isFullScreen;
    }
  }
}
