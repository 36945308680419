/* eslint-disable no-restricted-globals */
/* eslint-disable import/extensions */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */

const { MILLISECONDS_IN_SEC } = require('@omt-components/Utils/TimeUtil');
const { GATE_COOLDOWN_KEY } = require('../OMT/dataTracking/gateManager/GateManager');
const { default: TargetedOfferDataManager } = require('../OMT/dataTracking/targetedOffer/TargetedOfferDataManager');

/**
* Class for capturing game progression, player characterization, and system environment data for all DDNA events
*/
class DDNADataCapture {
  /**
  * constructor
  * @param {number} jsInitTime timestamp of js init
  */
  constructor(jsInitTime) {
    this._sessionId = DDNA.utils.generateUniqueId();
    this._sessionData = null;
    this._systemParamObj = null;
    this._pcData = null;

    this._jsInitTime = jsInitTime;
    this._loadingTime = 0;
    this._timeUntilFirstUserInteractionPossible = 0;

    /*
    *  COOK-OMT:
    *  Primary ID = game backend ID if connected to game backend, FBIG ID if not
    *  Secondary ID = FBIG ID
    *
    *  Other OMT:
    *  Primary ID = FBIG ID
    *  Secondary ID = game backend ID if connected to game backend, '-1' if not
    *
    *  The primary ID is sent as the userId in all events,
    *  the secondary IDs is sent as a separate parameter in all events
    */

    this._isCOOK = G.BuildEnvironment.APP_ID.toUpperCase() === 'COOKIE-CRUSH-2';

    if (this._isCOOK) {
      this._primaryId = OMT.envData.gbUserId || OMT.envData.settings.user.userId;
      this._secondaryId = OMT.envData.settings.user.userId;
    } else {
      this._primaryId = OMT.envData.settings.user.userId;
      this._secondaryId = OMT.envData.gbUserId || '-1';
    }

    this.createDefaultPlayerCharacterizationSessionData();
  }

  /**
   * set the timestamp of when loading is done
   */
  setLoadingTime() {
    if (this._loadingTime > 0) return;
    this._loadingTime = Date.now() - this._jsInitTime;
  }

  /**
   * set the timestamp when first user interaction is possible
   */
  setFirstInteractionTime() {
    if (this._timeUntilFirstUserInteractionPossible > 0) return;
    this._timeUntilFirstUserInteractionPossible = Date.now() - this._jsInitTime;
    console.log(`[DEBUG] timeUntilFirstUserInteractionPossible = ${this._timeUntilFirstUserInteractionPossible}`);

    // Dispatch setFirstInteractionTime Signal
    G.sb('setFirstInteractionTime').dispatch();
  }

  /**
  * assign generic user data. userID, sessionID, etc
  * @param {Object} eventObj event object instance
  */
  assignUserData(eventObj) {
    eventObj.userID = this._primaryId;
    eventObj.sessionID = this._sessionId;
  }

  /**
  * create the environment paramObj to be included with all events
  */
  createEnvParamObj() {
    const date = new Date();

    // system / environment variables.
    this._systemParamObj = {
      userIDHash: OMT.crypt.sha3Hash(this._primaryId),
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      timezoneName: /\((.*)\)/.exec(date.toString())[1],
      timezoneOffset: DDNA.utils.getTimezoneOffsetString(date),
      operatingSystem: DDNA.utils.getOperatingSystem(),
      // gameName: OMT.envData.settings.game.slug, // this is no longer present in the BuildEnvironment file
      gameID: OMT.envData.settings.game.id,
      gameVersion: G.BuildEnvironment.version ? G.BuildEnvironment.version : '0.0',
      locale: OMT.envData.settings.env.locale,
      durationSinceJSEnabled: 0, // *** OMT-COOK Duplicate
      timeSinceJSEnabled: 0,
      loadingTime: 0,
      platform: OMT.envData.platform,
      isCookOMT: OMT.envData.settings.game.isCookOMT ? 1 : 0,
      browserName: OMT.systemInfo.browserName,
      browserVersion: OMT.systemInfo.browserVersion,
      iapTier: OMT.envData.settings.env.tier,
      fps: OMT.performanceMonitor.lastMinAverageFPS,
      lowFPSMins: 0,
      highFPSMins: 0,
      rendererType: game.renderType === 2 ? 'WEBGL' : 'CANVAS',
      operatingSystemVersion: OMT.systemInfo.osVersion,
    };

    if (window.abTestGroupManager) { // if AB test group present add the id to the data
      // disabled sending this parameter as COOK had a incorrect parameter type
      // this._systemParamObj.abTestVersion = window.abTestGroupManager.getPlayerTestGroup().groupID;
    }

    if (G.featureUnlock.preventMysteryGift) { // TOR-6736
      this._systemParamObj.abTestVersion = G.saveState.sessionData.ABCGroup === 'Control' ? 0 : 1;
    }

    if (this._isCOOK) {
      this._systemParamObj.facebookUserID = this._secondaryId;
      this._systemParamObj.facebookUserIDHash = OMT.crypt.sha3Hash(this._secondaryId);
    } else {
      this._systemParamObj.gbUserID = this._secondaryId;
      this._systemParamObj.gbUserIDHash = OMT.crypt.sha3Hash(this._secondaryId);
    }
  }

  /**
  * assign system / environment parameters to the paramObj.
  * @param {Object} paramObj event paramObj instance
  */
  assignEnvParams(paramObj) {
    if (!this._systemParamObj) this.createEnvParamObj();

    this._systemParamObj.durationSinceJSEnabled = Date.now() - this._jsInitTime;
    this._systemParamObj.timeSinceJSEnabled = this._systemParamObj.durationSinceJSEnabled; // *** COOK-OMT Duplicate
    this._systemParamObj.fps = OMT.performanceMonitor.lastMinAverageFPS;
    this._systemParamObj.lowFPSMins = OMT.performanceMonitor.lowFPSMinCount;
    this._systemParamObj.highFPSMins = OMT.performanceMonitor.highFPSMinCount;

    // set loading time
    if (this._loadingTime > 0) this._systemParamObj.loadingTime = this._loadingTime;

    // set time since first interaction
    if (this._timeUntilFirstUserInteractionPossible > 0) this._systemParamObj.timeUntilFirstUserInteractionPossible = this._timeUntilFirstUserInteractionPossible;

    Object.assign(paramObj, this._systemParamObj);
  }

  /**
   * Changes the AB Test group to the given group on the systemParamObj
   * Only works if theres was an abTestVersion param set already
   * Validation should be done outside
   * @param {string} group
   */
  changeABCTestingGroup(group) {
    if (!this._systemParamObj) this.createEnvParamObj();
    if (this._systemParamObj.abTestVersion) {
      this._systemParamObj.abTestVersion = group;
    }
  }

  /**
  * assign game progression parameters to the paramObj.
  * @param {Object} paramObj event paramObj instance
  */
  assignGameProgressionParams(paramObj) {
    if (!G.saveState || !G.saveState.data) return; // save data is not yet available

    // inventory values
    paramObj.lives = parseInt(G.saveState.getLives());
    paramObj.coins = parseInt(G.saveState.getCoins());
    paramObj.swapBooster = parseInt(G.saveState.getBoosterAmount(1));
    paramObj.removerBooster = parseInt(G.saveState.getBoosterAmount(2));
    paramObj.horizontalBooster = parseInt(G.saveState.getBoosterAmount(3));
    paramObj.verticalBooster = parseInt(G.saveState.getBoosterAmount(4));
    paramObj.extraMovesBooster = parseInt(G.saveState.getBoosterAmount(6));
    paramObj.specialTileBooster = parseInt(G.saveState.getBoosterAmount(7));
    paramObj.bombBooster = parseInt(G.saveState.getBoosterAmount(8));
    paramObj.unlimitedLivesMinutes = Math.ceil(G.saveState.getUnlimitedLivesSec() / 60);

    // progressions values
    const nextGateLevel = G.saveState.gateManager.getNextGateLevel();
    paramObj.levelsToNextGate = nextGateLevel - G.saveState.getLastPassedLevelNr();
    paramObj.starsToNextGate = G.saveState.gateManager.getStarRequirementForGate(nextGateLevel) - G.saveState.getAllStars();
    paramObj.highestLevel = G.saveState.getLastPassedLevelNr() + 1;
    paramObj.highestLevelID = G.json.levels.order[paramObj.highestLevel - 1];
    paramObj.dailyMissionCandy = this._getDailyMissionCandy();
    paramObj.treasureHuntGems = this._getTreasureHuntGems();
    paramObj.stars = G.saveState.getAllStars();

    const winStreak = G.saveState.mysteryGiftManager.getCurrentStreak();
    const winStreakGifts = G.saveState.mysteryGiftManager.getCurrentGifts();
    paramObj.winStreakProgress = G.saveState.mysteryGiftManager.isModeReady() ? winStreak : -1;
    paramObj.winStreakBombBooster = winStreakGifts.indexOf('spiral') >= 0 ? 1 : 0;
    paramObj.winStreakVertical = winStreakGifts.indexOf('vertical') >= 0 ? 1 : 0;
    paramObj.winStreakHorizontal = winStreakGifts.indexOf('horizontal') >= 0 ? 1 : 0;
    paramObj.winStreakCross = winStreakGifts.indexOf('cross') >= 0 ? 1 : 0;

    paramObj.consecutiveLoginDays = G.saveState.getLoginStats().consecutiveLoginDays;
    paramObj.dailyMissionProgress = G.dailyMissionsMgr.getProgressPercent();
    const treasureHuntArr = G.OMTsettings.treasureHuntSuper.reward.currencyUnlock;
    paramObj.treasureHuntProgress = Math.min(100, G.saveState.treasureHuntManager.currentTokens / treasureHuntArr[treasureHuntArr.length - 1]);

    // Special events
    if (OMT.feature.isTokenEventOn(true, false)) {
      paramObj.eventActive = G.OMTsettings.tokenEvent.DDNAEventID;
    } else {
      paramObj.eventActive = null;
    }

    paramObj.consecutiveWinCount = G.saveState.getCWinCount();
    paramObj.consecutiveWinHighest = G.saveState.getHighestCWinCount();
    paramObj.timerForGate = this._getTimerForGate();
    paramObj.timerForSpin = G.saveState.getSecUntilNextFreeSpin();
    paramObj.timerForNextLife = G.saveState.getSecUntilNextLifeRefill();
    paramObj.timerForGift = G.Helpers.dailyRewardMgr.getSecToNextDailyGift();

    paramObj.visitedFanPage = G.saveState.data.visitedFanPage || false;
    paramObj.sceneId = DDNA.utils.getSceneTrackingId();

    // Timed targeted offer
    const toDataMgr = TargetedOfferDataManager.getInstance();
    const timeLeft = toDataMgr.getCurrentTargetedOfferTimeRemaining();

    if (timeLeft <= 0) {
      paramObj.timedActiveOffer = null;
      paramObj.timedOfferTimeLeft = null;
      return;
    }

    const currentOffer = toDataMgr.getCurrentTargetedOffer();

    // Check if the current offer has a product id (pid) or an offer id (oid)
    // Convert it into a simple product id
    let simplePID = null;
    const { pid, oid } = currentOffer;
    const targetOfferObj = G.OMTsettings.targetedOffers[oid];

    if (pid) {
      simplePID = pid;
    } else if (oid && targetOfferObj) {
      simplePID = targetOfferObj.productID;
      if (!simplePID && targetOfferObj.targetProducts) {
        const product = toDataMgr.findProduct(oid, targetOfferObj.targetProducts);
        if (product) {
          simplePID = product.productID;
        }
      }
    }

    if (!simplePID) {
      paramObj.timedActiveOffer = null;
      paramObj.timedOfferTimeLeft = null;
      return;
    }

    paramObj.timedActiveOffer = simplePID;
    paramObj.timedOfferTimeLeft = Math.ceil(timeLeft / MILLISECONDS_IN_SEC);
  }

  /**
   * create an object with the initial values for player characterization parameters
   * @returns {object}
   */
  createDefaultPlayerCharacterizationParamObj() {
    return {
      realMoneySpent: 0,
      coinValueBoughtWithRealMoney: 0,
      realMoneyPurchasesMade: 0,
      coinsSpent: 0,
      coinPurchasesMade: 0,
      zeroLivesCoinPurchases: 0,
      zeroLivesRealMoneyPurchases: 0,
      daysFromFirstLogin: 0,
      daysInGame: 1,
      sessionNumber: 0,
      lastLevelAttempted: -1,
      lastLevelWon: -1,
      adsWatchedRewarded: 0,
      adsWatchedInterstitial: 0,
      adsLastTimeRewarded: -1,
      adsLastTimeShown: -1,
      numFriendsInApp: 0,
      usedCheatCodes: 0,
      // post card
      seenPostcardShareBtn: 0,
      usedPostcardShareBtn: 0,
      // friendship chest
      seenFriendshipChestPromo: 0,
      seenFriendshipChestShareBtn: 0,
      usedFriendshipChestShareBtn: 0,
      // daily reward
      seenDailyRewardShareBtn: 0,
      usedDailyRewardShareBtn: 0,
      // hard level
      seenHardLevelShareBtn: 0,
      usedHardLevelShareBtn: 0,
      // achievement gift
      seenAchievementGiftShareBtn: 0,
      usedAchievementGiftShareBtn: 0,
      // mission complete
      seenMissionCompleteShareBtn: 0,
      usedMissionCompleteShareBtn: 0,
      // treasure hunt
      seenTreasureHuntShareBtn: 0,
      usedTreasureHuntShareBtn: 0,
      // ad fallback
      seenAdFallbackInviteBtn: 0,
      usedAdFallbackInviteBtn: 0,
      // gate unlock
      seenGateUnlockInviteBtn: 0,
      usedGateUnlockInviteBtn: 0,
      // Fortune Cookies
      fortuneCookieTotalCategorySelected: 0,
      fortuneCookieTotalShared: 0,
      fortuneCookieTotalSpawned: 0,
      fortuneCookieTotalCollected: 0,
      fortuneCookieTotalDismissed: 0,
      fortuneCookieTotalEventsRejected: 0,
      // Real money replacement wheel
      eligibleReplacementMoneyWheel: 0,
      seenReplacementConversionMoneyWheel: 0,
      seenReplacementHighValueWheel: 0,
      purchasedReplacementConversionMoneyWheel: 0,
      purchasedReplacementHighValueWheel: 0,
      // Real money helper wheel
      purchasedHelperConversionMoneyWheel: 0,
      purchasedHelperHighValueWheel: 0,
      // Real money targeted offer wheel
      purchasedTargetedOfferConversionMoneyWheel: 0,
      purchasedTargetedOfferHighValueWheel: 0,
      // Real money payload wheel
      purchasedPayloadConversionMoneyWheel: 0,
      purchasedPayloadHighValueWheel: 0,
      // Chest shuffle
      chestShuffleClickedCount: 0,
      // mailbox
      mailboxClickedCount: 0,
      // TokenEvent events
      thanksgivingTokensCollected: 0,
      // Is Loyalty (OMT-4554)
      seenLoyaltyPopup: 0,
      addedHomeScreenShortcut: 0, // OMT-5047
      // Treasure Hunt
      treasureHuntLeaderboardHash: null,
      treasureHuntID: null,
      // Targeted Offer
      sessionsSinceLastTargetedOffer: 0,
    };
  }

  /**
  * set data referemce for player charecterization data
  * @param {Object} pcData player charecterization data object
  */
  async setPlayerCharacterizationData(pcData) {
    const paramObj = Object.assign(this.createDefaultPlayerCharacterizationParamObj(), pcData.params);
    paramObj.numFriendsInApp = (await OMT.friends.getFriendsList()).length; // update num friends
    this._pcData = pcData;
    this._pcData.params = paramObj;
  }

  /**
  * save the player charecterization data to the server
  */
  writePlayerCharacterizationData() {
    // DDNA.tracking.addToSaveData('pcData', this._pcData, true);
  }

  /**
  * create and set up new paramObj with the initial values for player characterization parameters
  */
  async createDefaultPlayerCharacterizationData() {
    const paramObj = this.createDefaultPlayerCharacterizationParamObj();
    try {
      paramObj.botMessageActive = G.saveState.getBotOptIn() ? 1 : 0;
    } catch (e) {
      paramObj.botMessageActive = 0;
    }

    // data object for tracking pc param value changes
    const todaysDate = new Date();
    const trackingData = {};
    trackingData.lastDate = todaysDate.getTime();

    await this.setPlayerCharacterizationData({ params: paramObj, trackingData });
    this.updatePlayerCharacterizationLoginParams(false);
    this.writePlayerCharacterizationData();
  }

  /**
  * create a session paramObj with the initial values for player characterization parameters
  */
  createDefaultPlayerCharacterizationSessionData() {
    this._sessionData = {
      params: {
        levelsPassedThisSession: 0,
        levelAttemptsThisSession: 0,
        inGameBoostersUsedThisSession: 0,
        mapBoostersUsedThisSession: 0,
        extraMovesRescueBoughtThisSession: 0,
        adsWatchedThisSessionRewarded: 0,
        adsWatchedThisSessionInterstitial: 0,
        coinsSpentThisSession: 0,
        coinsEarnedThisSession: 0,
        lossAversionActionsThisSession: 0,
        realMoneySpentThisSession: 0,
      },
    };
  }

  /**
  * update parameters related to login statistics
  * @param {boolean} save write data (optional)
  */
  updatePlayerCharacterizationLoginParams(save = true) {
    const paramObj = this._pcData.params;
    const { trackingData } = this._pcData;
    const todaysDate = new Date();
    const lastRecordedDate = new Date(trackingData.lastDate);

    // increment day count
    if (!DDNA.utils.isSameDate(lastRecordedDate, todaysDate)) {
      paramObj.daysInGame++;
    }

    // days since first login date
    if (G.saveState && G.saveState.data) {
      const loginStats = G.saveState.getLoginStats();
      const creationDate = new Date(loginStats.creation);
      paramObj.daysFromFirstLogin = DDNA.utils.getDateDayDifference(creationDate, todaysDate);
    }

    // store todays date
    trackingData.lastDate = todaysDate.getTime();

    // increment session number
    paramObj.sessionNumber = paramObj.sessionNumber ? paramObj.sessionNumber + 1 : 1;

    // Check milestones
    OMT.milestoneTracking.checkMilestoneCompletion();

    if (save) this.writePlayerCharacterizationData();
  }

  /**
  * set a player characterization parameter value
  * @param {string} key key / parameter name to assign
  * @param {*} value value to assign
  * @param {boolean} save write data (optional)
  */
  setPlayerCharacterizationParam(key, value, save = true) {
    if (this._pcData.params[key] === value) return; // value did not change
    this._pcData.params[key] = value;
    if (save) this.writePlayerCharacterizationData();
  }

  /**
   * get a player characterization parameter value
   * @param {string} key
   */
  getPlayerCharacterizationParam(key) {
    return this._pcData.params[key];
  }

  /**
  * add to a player characterization parameter value
  * @param {string} key key / parameter name to assign
  * @param {*} value value to assign
  * @param {boolean} save write data (optional)
  */
  addToPlayerCharacterizationParam(key, value, save = true) {
    this._pcData.params[key] += value;
    if (key === 'realMoneySpent') this._pcData.params[key] = Math.floor(this._pcData.params[key] * 100) / 100; // round to 2 digits
    if (save) this.writePlayerCharacterizationData();
  }

  /**
  * assign player characterization parameters to the paramObj.
  * @param {Object} paramObj paramObject to assign values to
  */
  assignPlayerCharacterizationParams(paramObj) {
    if (!this._pcData || !this._pcData.params) return;
    Object.assign(paramObj, this._pcData.params);

    // Special case for timeSinceZeroLives
    delete paramObj.lastTimeAtZeroLives;
    paramObj.timeSinceZeroLives = this._pcData.params.lastTimeAtZeroLives > -1
      ? Math.floor((Date.now() - this._pcData.params.lastTimeAtZeroLives) / 1000)
      : -1;
  }

  /**
  * set a session player characterization parameter value
  * @param {string} key key / parameter name to assign
  * @param {*} value value to assign
  * @param {boolean} save write data (optional)
  */
  setPlayerCharacterizationSessionParam(key, value) {
    if (this._sessionData.params[key] === value) return; // value did not change
    this._sessionData.params[key] = value;
  }

  /**
  * get a player characterization parameter value
  * @param {string} key
  */
  getPlayerCharacterizationSessionParam(key) {
    return this._sessionData.params[key];
  }

  /**
  * add to a player characterization parameter value
  * @param {string} key key / parameter name to assign
  * @param {*} value value to assign
  * @param {boolean} save write data (optional)
  */
  addToPlayerCharacterizationSessionParam(key, value) {
    // force 'coinsEarnedThisSession' there were some concatination errors on DDNA
    if (key === 'coinsEarnedThisSession') {
      if (isNaN(this._sessionData.params[key])) this._sessionData.params[key] = 0;
      this._sessionData.params[key] = parseInt(this._sessionData.params[key]) + parseInt(value);
    } else {
      this._sessionData.params[key] += value;
    }
    if (key === 'realMoneySpentThisSession') this._sessionData.params[key] = Math.floor(this._sessionData.params[key] * 100) / 100; // round to 2 digits
  }

  /**
  * assign player characterization session parameters to the paramObj.
  * @param {Object} paramObj paramObject to assign values to
  */
  assignPlayerCharacterizationSessionParams(paramObj) {
    if (!this._sessionData || !this._sessionData.params) return;
    Object.assign(paramObj, this._sessionData.params);
  }

  // Helper functions
  // TODO: move these to a helper class(es) later, maybe

  /**
   * get number of daily mission candies collected
   * @returns {number}
   */
  _getDailyMissionCandy() {
    const { goal } = G.dailyMissionsMgr.getProgress();
    return goal === 0 ? 0 : G.dailyMissionsMgr.getProgress().finished;
  }

  /**
   * get number of treasure hunt gems collected
   * @returns {number}
   */
  _getTreasureHuntGems() {
    return G.saveState.treasureHuntManager.currentTokens;
  }

  /**
   * get timer for first unopened gate
   * @returns {number}
   */
  _getTimerForGate() {
    const nextGate = G.saveState.gateManager.getNextGateLevel();
    const maxLevel = G.Helpers.levelDataMgr.getNumberOfLevels() + 1;
    if (nextGate >= maxLevel) return -1; // coming soon gate

    const gateData = G.saveState.gateManager.getGateObject(nextGate);
    G.saveState.gateManager.canGateBeOpened(gateData);
    const gateCooldown = G.saveState.getUserCooldownRemaining(`${GATE_COOLDOWN_KEY}${nextGate}`, '');
    return Math.ceil(Math.max(-1, gateCooldown / MILLISECONDS_IN_SEC));
  }

  /**
   * Gets player's real money wheel conversion status
   */
  getRealMoneyWheelConversionStatus() {
    const keysToCheck = [
      'purchasedReplacementConversionMoneyWheel',
      'purchasedReplacementHighValueWheel',
      'purchasedHelperConversionMoneyWheel',
      'purchasedHelperHighValueWheel',
      'purchasedTargetedOfferConversionMoneyWheel',
      'purchasedTargetedOfferHighValueWheel',
      'purchasedPayloadConversionMoneyWheel',
      'purchasedPayloadHighValueWheel',
    ];

    return keysToCheck.every((key) => this._pcData.params[key] < 1);
  }
}

// create global references
window.DDNADataCapture = DDNADataCapture;
