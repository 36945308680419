/* eslint-disable no-param-reassign */

import { OMT_DataCache } from '@omt-components/Services/Utils/OMT_DataCache';

/**
 * class for loading / managing JSON data at run-time
 */
export class OMT_JSONLoader {
  /**
   * constructor
   */
  constructor() {
    this._cache = new OMT_DataCache();
  }

  /**
   * load a JSON file
   * @param {string} url url of file to load
   * @param {string} dataKey optional data key, if not set filename will be used
   * @returns {Promise<Object>}
   */
  async loadJSON(url, dataKey = null) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.overrideMimeType('application/json');
      xhr.open('GET', url, true);

      xhr.onreadystatechange = () => {
        if (xhr.readyState > 3 && (xhr.status >= 200 && xhr.status < 300)) { // success
          const data = JSON.parse(xhr.response);
          if (!dataKey) dataKey = url.indexOf('/') !== -1 ? url.split('/').pop() : url;
          this._addDataToCache(dataKey, data);
          resolve(data);
        } else if (xhr.status === 404) { // 404 error
          resolve(null);
        }
      };
      xhr.send(null);
    });
  }

  /**
   * add JSON data to the cache
   * @param {string} dataKey
   * @param {Object} data
   */
  _addDataToCache(dataKey, data) {
    this._cache.setData(dataKey, data);
  }

  /**
   * get cached JSON data
   * @param {string} dataKey
   * @returns {Object}
   */
  getCachedData(dataKey) {
    return this._cache.getData(dataKey);
  }
}
