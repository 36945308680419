/* eslint-disable no-lonely-if */
/* eslint-disable import/no-cycle */
/* eslint-disable prefer-destructuring */

import ShopUtils, { REG_DEAL_PRIORITY, SHOP_DEAL_TYPES } from '../Shop/Shop_Utils';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import Shop3_PriceCutText from './Components/Shop3_PriceCutText';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';
import { TextTimer, TIMER_FORMAT } from '../../TextTimer';
import {
  MILLISECONDS_IN_DAY,
  MILLISECONDS_IN_HOUR,
  MILLISECONDS_IN_MIN,
  MILLISECONDS_IN_SEC,
} from '@omt-components/Utils/TimeUtil';
import AnnuityManager from '../../../Services/OMT/dataTracking/annuityManager/AnnuityManager';

/**
 * @typedef {object} ParsedShopData
 * @property {number} coins
 * @property {Array<{amount:number, asset:string, type:string}>} itemData
 * @property {{coins:number, days:number}} annuity
 * @property {string} [id]
 */
export default class Shop3_SpecialDeal extends Phaser.Group {
  /**
   * This is the special deals class.
   * @param {Object} dealData
   * @param {Object} dealData.deal The facebook deal data
   * @param {String} dealData.dealType
   * @param {number} dealData.slot
   * @param {Object} [dealData.fullPrice]
   * @param {Object} config
   * @param {number} config.index
   * @param {number} config.orientation
   * @param {Object} config.assetReplacement
   * @param {Array<string>} config.layout
   * @param {Phaser.Rectangle} config.dimensions
   * @param {{dimensions:{ x: number, y: number, width: number, height: number }, timeout: number}} config.timerData
   */
  constructor(dealData, config) {
    super(game, null);

    this._config = config;
    this._data = this._parseData(dealData);
    this._purchaseEnabled = true;
    this._gameScale = GameScaleController.getInstance().gameScale;
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;

    this.signals = {
      onClick: new Phaser.Signal(), // Signal for when the button is clicked
    };

    if (this._data.dealType === SHOP_DEAL_TYPES.ANNUITY) {
      this._determineAssetsForAnnuity();
      this._initForAnnuity();
    } else {
      this._determineAssets();
      this._init();
    }
  }

  /**
   * Returns the deal name for comparing
   * @returns {string}
   */
  get dealName() {
    return this._data.deal.productID;
  }

  get slot() {
    return this._data.slot;
  }

  get dealType() {
    return this._data.dealType;
  }

  /**
   * Returns the number of coins this special pack contains
   * @returns {number}
   */
  get dealCoins() {
    return this._data.coins || 0;
  }

  /**
   * Returns the frame width, not including anything else poking out
   * @returns {number}
   */
  get width() {
    return this._calculatedBounds.width;
  }

  /**
   * Returns the frame height, not including anything else poking out (like the ribbon)
   * @returns {number}
   */
  get height() {
    return this._calculatedBounds.height;
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._button) {
      this._button.destroy();
    }
    if (this.signals) {
      Object.keys(this.signals).forEach((key) => {
        if (this.signals[key].dispose) {
          this.signals[key].dispose();
        }
      });
      this.signals = null;
    }
    super.destroy();
  }

  /**
   * Parse the data from the deal string to an object type
   * @param {Object} dealData
   * @param {Object} dealData.deal The facebook deal data
   * @param {String} dealData.dealType
   * @param {number} dealData.slot
   * @param {Object} [dealData.fullPrice]
   * @returns {{deal:Object, dealType:string, slot:number, dealSegment:string, fullPrice?:string, parsed:ParsedShopData}}
   */
  _parseData(dealData) {
    const obj = {
      coins: 0,
      itemData: [],
    };

    const dealSnippets = dealData.deal.productID.split('_');
    obj.coins = parseInt(dealSnippets[2].replace('k', '000'));

    for (let i = 3; i < dealSnippets.length; i += 2) {
      let boosterAsset;
      let boosterAmount;
      let boosterType;
      if (dealSnippets[i].indexOf('b') > -1) {
        boosterAsset = `shop3_${ShopUtils.getBoosterAssetFromShopString(dealSnippets[i])}`;
        boosterAmount = dealSnippets[i + 1];
        boosterType = REG_DEAL_PRIORITY.boosters;
      } else if (dealSnippets[i].indexOf('il') > -1) {
        boosterAsset = 'shop3_heart_unlimited';
        boosterAmount = dealSnippets[i + 1];
        boosterType = REG_DEAL_PRIORITY.inflives;
      }
      if (boosterAsset && boosterAmount) {
        obj.itemData.push({
          type: boosterType,
          amount: boosterAmount,
          asset: boosterAsset,
        });
      }
    }
    obj.dealSegment = dealData.deal.productID.substr('t#_'.length);

    if (dealData.dealType === SHOP_DEAL_TYPES.ANNUITY) {
      obj.annuity = AnnuityManager.getAnnuityDetails(dealData.deal.productID, dealData.deal.description);
    }

    dealData.parsed = obj;
    return dealData;
  }

  _determineAssets() {
    const useBoldSchema = G.OMTsettings.elements.Window_shop3.useBoldSchema;
    const FTUIndex = useBoldSchema ? 0 : 3;
    let assetIndex = Math.min(2, this._config.index);
    let chestIndex = assetIndex;
    if (useBoldSchema) {
      const isBoldedDeal = this._config.layout.indexOf(this._data.parsed.dealSegment) > -1;
      assetIndex = isBoldedDeal ? 0 : 1;
      chestIndex = Math.min(5, this._config.index);
    }

    const targetChest = Number.isFinite(this._data.asset) ? this._data.asset : chestIndex;
    const specialDealTintData = G.OMTsettings.elements.Window_shop3.specialDealTintData[assetIndex];
    this._defaultAsset = {
      frame: `shop3_specialDealBg${assetIndex}`,
      ribbon: `shop3_dealRibbon${assetIndex}`,
      boxTint: specialDealTintData,
      chest: `shop3_specialDeal${targetChest}`,
      value: {
        base: 'shop3_sticker',
        textMultiplier: G.OMTsettings.elements.Window_shop3.tagBaseMultipler,
        tintColour: specialDealTintData.valueTagTint,
      },
      boosterBox: specialDealTintData.boosterBox,
    };

    this._asset = _.cloneDeep(this._defaultAsset);

    if (Object.keys(this._config.assetReplacement).length > 0) {
      this._asset = _.merge(this._asset, this._config.assetReplacement.specialDeal);
      if (this._asset.chest) {
        if (this.dealType.indexOf('first') > -1) {
          this._asset.chest += 1;
        } else {
          this._asset.chest += 0;
        }
      }
    } else if (this.dealType.indexOf('first') > -1) {
      const FTUDataSet = G.OMTsettings.elements.Window_shop3.specialDealTintData[FTUIndex];
      this._asset.frame = 'shop3_ftuBox';
      this._asset.ribbon = 'shop3_ftudealRibbon';
      this._asset.boosterBox = FTUDataSet.boosterBox;
      if (useBoldSchema) {
        this._asset.chest = G.OMTsettings.elements.Window_shop3.twoSlotChestAsset[OMT.systemInfo.orientationKey];
      } else {
        this._asset.chest = `shop3_specialDeal${assetIndex}`;
      }
      this._asset.value.tintColour = FTUDataSet.valueTagTint;
    }
  }

  /**
   * Determines assets used for annuity.
   * Drastically different from normal shop entries
   */
  _determineAssetsForAnnuity() {
    const annuityData = G.OMTsettings.elements.Window_shop3.specialDealTintData[5];
    this._asset = {
      frame: 'shop3_annuityBg',
      ribbon: 'shop3_dealRibbonAnnuity',
      boxTint: annuityData,
      chest: 'shop3_annuity',
      value: {
        base: null,
        textMultiplier: 1,
        tintColour: 0,
      },
      boosterBox: annuityData.boosterBox,
    };
  }

  /**
   * Initilize.
   * Depending on index, it uses a determined layout style or the FTUE layout style.
   */
  _init() {
    const assumedHeight = this._config.dimensions.height;
    const assumedWidth = this._config.dimensions.width;
    const textureHeight = this._isLandscape ? assumedHeight / this._gameScale : assumedHeight;
    const textureWidth = this._isLandscape ? assumedWidth / this._gameScale : assumedWidth;

    this._makeShopDealFrame(textureWidth, textureHeight);
    this._makeRibbonAndText(assumedWidth);
    this._makeOfferDetailsBox(assumedWidth);
    this._makeChest(assumedHeight);

    this._createButton();
    this._createFullPriceCut();
    this._fillBox(this._asset.boosterBox.coinBar);
    this._createCornerTag(this._asset.value.tintColour);
  }

  /**
   * Similar to init, but draws the asset differently
   */
  _initForAnnuity() {
    const assumedHeight = this._config.dimensions.height;
    const assumedWidth = this._config.dimensions.width;
    const textureHeight = this._isLandscape ? assumedHeight / this._gameScale : assumedHeight;
    const textureWidth = this._isLandscape ? assumedWidth / this._gameScale : assumedWidth;

    this._makeShopDealFrame(textureWidth, textureHeight);
    this._makeRibbonAndText(assumedWidth);
    this._makeOfferDetailsBox(assumedWidth);
    this._makeChest(assumedHeight);

    this._createBottomForAnnuity();
    this._fillBoxForAnnuity(this._asset.boosterBox.coinBar);
  }

  /**
   * Draws the shop frame
   * @param {number} textureWidth
   * @param {number} textureHeight
   * @returns {UI_NineSlice}
   */
  _makeShopDealFrame(textureWidth, textureHeight) {
    const frame = new UI_NineSlice(0, 0, this._asset.frame, textureWidth, textureHeight, G.OMTsettings.elements.Window_shop3.specialDealFrameSlice);
    frame.x = -Math.round(G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.offsetX / 2);
    frame.y = -Math.round(G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.offsetY / 2);
    frame.anchor.set(0.5);
    this.addChild(frame);
    this._calculatedBounds = frame.getBounds().clone();

    // Adjust bounds values in landscape to reflect actual width and height
    if (this._isLandscape) {
      frame.scale.setTo(this._gameScale);
      this._calculatedBounds.width *= this._gameScale;
      this._calculatedBounds.height *= this._gameScale;
    }

    this._calculatedBounds.height -= Math.round(G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.offsetY / 2);
    this._calculatedBounds.width -= Math.round(G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.offsetX / 2);
    frame.cacheAsBitmap = true;

    return frame;
  }

  /**
   * Draws the ribbon and text inside
   * @param {number} assumedWidth
   */
  _makeRibbonAndText(assumedWidth) {
    const ribbonGroup = new Phaser.Group(game);
    this.addChild(ribbonGroup);

    const textHeight = (G.OMTsettings.elements.Window_shop3.specialDealRibbonSlice.top
      + G.OMTsettings.elements.Window_shop3.specialDealRibbonSlice.bottom)
      * this._gameScale;
    const ribbonHeight = G.OMTsettings.elements.Window_shop3.specialDealRibbonSize.height;

    const ribbon = new UI_NineSlice(0, 0, this._asset.ribbon, (assumedWidth * 0.7) / this._gameScale,
      ribbonHeight, G.OMTsettings.elements.Window_shop3.specialDealRibbonSlice);
    ribbon.y = 5 - this.height / 2;
    ribbon.anchor.set(0.5);
    ribbon.scale.setTo(this._gameScale);
    ribbonGroup.addChild(ribbon);
    if (this._data.deal.title !== '') {
      const titleText = new G.Text(ribbon.x, ribbon.y, OMT.language.getText(this._data.deal.title), 'shop3-specialDealCoinText', 0.5, ribbon.width * 0.8, textHeight);
      if (G.OMTsettings.elements.Window_shop3.headerTextOffset) {
        titleText.y += G.OMTsettings.elements.Window_shop3.headerTextOffset.y || 0;
        titleText.x += G.OMTsettings.elements.Window_shop3.headerTextOffset.x || 0;
      }
      ribbonGroup.addChild(titleText);
    }
    ribbonGroup.cacheAsBitmap = true;
  }

  /**
   * Draws the offer box
   * @param {number} assumedWidth
   */
  _makeOfferDetailsBox(assumedWidth) {
    const boosterBoxLayout = { x: 0, y: 0 };
    if (this._config.orientation === ORIENTATION.horizontal) { // Horizontal mode
      boosterBoxLayout.width = assumedWidth * 0.935;
      boosterBoxLayout.height = this._data.slot === 1 ? this.height * 0.4 : this.height * 0.7;
    } else {
      boosterBoxLayout.width = this._data.slot === 1 ? assumedWidth * 0.41 : assumedWidth * 0.95;
      boosterBoxLayout.height = this._data.slot === 1 ? this.height - Math.max(15
        - (G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.topOfFrame - G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.offsetY), 65)
        : this.height * 0.68;
    }
    const boosterBox = this._boosterBox = new Phaser.Group(game, this);

    const whiteBaseWidth = this._isLandscape ? boosterBoxLayout.width / this._gameScale : boosterBoxLayout.width;
    const whiteBaseHeight = this._isLandscape ? boosterBoxLayout.height / this._gameScale : boosterBoxLayout.height;

    const whiteBase = new UI_NineSlice(0, 0, this._asset.boosterBox.asset,
      whiteBaseWidth, whiteBaseHeight, G.OMTsettings.elements.Window_shop3.specialDealBoosterBoxSlice);
    whiteBase.anchor.set(0.5);

    if (this._isLandscape) {
      whiteBase.scale.setTo(this._gameScale);
    }

    boosterBox.addChild(whiteBase);

    if (this._config.orientation === ORIENTATION.horizontal) {
      if (this._data.slot === 1) {
        boosterBoxLayout.y = ((this.height * 0.55) - G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.bottomOfFrame) / 2;
      }
      boosterBoxLayout.x = 10 - (this.width - boosterBox.width) / 2;
    } else {
      if (this._data.slot === 1) { // Single slot
        boosterBoxLayout.x = 30 - (this.width - boosterBox.width) / 2;
        boosterBoxLayout.y = 10 + G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.topOfFrame
          - (this.height / 2) - G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.offsetY + boosterBox.height / 2;
      } else {
        boosterBoxLayout.y = 15 + ((boosterBox.height - this.height) / 2)
          + G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.topOfFrame - G.OMTsettings.elements.Window_shop3.specialDealFrameExtra.offsetY / 2;
        boosterBoxLayout.x = 0;
      }
    }
    boosterBox.y = boosterBoxLayout.y;
    boosterBox.x = boosterBoxLayout.x;
    this._boosterBoxDimension = boosterBox.getBounds().clone();
    boosterBox.cacheAsBitmap = true;
  }

  /**
   * Draws the shiny chest
   * @param {number} assumedHeight
   */
  _makeChest(assumedHeight) {
    const chest = this._chest = G.makeImage(0, 0, this._asset.chest, 0.5, this);
    const baseScale = 280 / chest.height;
    if (this._config.orientation === ORIENTATION.horizontal) { // horizontal
      if (this._data.slot > 1) { // Horizontal 2+ slot
        chest.scale.set(baseScale * 1.85 * this._gameScale);
        chest.y = -30 / this._gameScale;
        chest.x = this.width / 8;
      } else { // Horizontal 1 slot
        chest.scale.set(baseScale * 1.11 * this._gameScale);
        chest.y = (-assumedHeight / 4) - 10;
      }
    } else { // Vertical orientation
      if (this._data.slot > 1) { // Vertical 2+ slot
        chest.scale.set(baseScale * 1.25);
        if (this._data.parsed.itemData.length > 3) { // Vertical 2+ lots of items
          chest.x = -10 + (this.width - chest.width) / 2;
          chest.y = -chest.height / 8;
        } else { // Vertical 2+ slot, less items
          chest.y = this._boosterBox.y - (this._boosterBoxDimension.height / 2) + chest.height / 3;
        }
      } else { // 1 slot
        chest.scale.set(baseScale);
        chest.x = this.width / 4;
      }
    }
    if (this._config.timerData) {
      const timerArea = { ...this._config.timerData.dimensions }; // Copy
      const chestScale = chest.scale.x;
      timerArea.x = Math.floor(timerArea.x * chestScale);
      timerArea.y = Math.floor(timerArea.y * chestScale);
      timerArea.width = Math.floor(timerArea.width * chestScale);
      timerArea.height = Math.floor(timerArea.height * chestScale);
      const chestPos = { x: chest.x - chest.width / 2, y: chest.y - chest.height / 2 };
      const { end } = ShopUtils.getStartAndEndTimeForWeekendDeal();
      const timeLeft = end.getTime() - Date.now();
      const timerFormat = timeLeft < MILLISECONDS_IN_HOUR ? TIMER_FORMAT.MS : TIMER_FORMAT.HM;
      const text = new TextTimer({
        x: chestPos.x + timerArea.x,
        y: chestPos.y + timerArea.y,
        style: 'shop3-weekendDealTimerText',
        anchor: 0.5,
        maxWidth: timerArea.width,
        timerFormat,
        maxHeight: timerArea.height,
      });
      text.setSecLeft(timeLeft / MILLISECONDS_IN_SEC);
      text.active = true;
      this.addChild(text);
      this._timer = text;
    }
  }

  /**
   * Fills the box with shop goodies
   * @param {Object} coinBarData
   */
  _fillBox(coinBarData) {
    // Creation of assets first
    // Coin bar
    let coinBar;
    if (this._data.parsed.coins > 0) {
      coinBar = new Phaser.Group(game, null);
      const bar = new Phaser.Group(game, coinBar);
      const barBase = G.makeImage(0, 0, 'shop3_specialDealCoinBar', 0.5, bar);
      barBase.tint = coinBarData.tint;
      const barHighlight = G.makeImage(0, 0, 'shop3_specialDealCoinBarHighlight', 0.5, bar); // highlight
      const coinImage = G.makeImage(0, 0, 'coin_3', 0.5, null);

      if (this._config.orientation === ORIENTATION.horizontal && this._data.slot === 1) { // horizontal and 1 slot
        const offsetY = 15;
        barBase.scale.setTo(1);
        barHighlight.scale.setTo(1);
        coinImage.scale.set(((bar.height * 2.5) / coinImage.height) * 1.15 * this._gameScale);
        coinImage.y = -offsetY;
        bar.y = coinImage.y + offsetY + (coinImage.height + bar.height) / 2;
        bar.scale.setTo(this._gameScale);
      } else {
        coinImage.x = (-bar.width / 2) * this._gameScale;
        if (this._config.orientation === ORIENTATION.horizontal) { // horizontal and 2 slots
          const offsetY = 0;
          coinImage.scale.set(((bar.height * 1.7) / coinImage.height) * this._gameScale);
          coinImage.y = -offsetY;
          bar.y = coinImage.y;
          bar.scale.setTo(this._gameScale);
        } else { // vertical
          coinImage.scale.set((bar.height * 1.5) / coinImage.height);
        }
      }
      const text = new G.Text(0, 2, OMT.language.toLocaleNumber(this._data.parsed.coins), 'shop3-specialDealCoinText', 0.5, coinBar.width, (coinBar.height * 1.35) / this._gameScale);
      coinBar.addChild(coinImage);
      bar.addChild(text);
    }

    // Creating boosters and amount text first
    const safeDistance = 30;
    let iconDimensions = 0;
    if (this._config.orientation === ORIENTATION.horizontal) { // Horizontal 1 slot
      const maxSize = this._data.slot === 2 ? 70 : 50;
      iconDimensions = Math.min((this._boosterBoxDimension.height - safeDistance) / this._data.parsed.itemData.length, maxSize);
    } else { // Vertical
      if (this._data.slot === 2) { // Vertical 2 slot
        iconDimensions = 80;
      } else { // Vertical one slot
        iconDimensions = Math.max(((this._boosterBoxDimension.height - (safeDistance * (this._data.parsed.itemData.length + 1))) / this._data.parsed.itemData.length) / 2, 50);
      }
    }
    const boosterDetails = [];
    this._data.parsed.itemData.forEach((dataSet) => {
      const boosterGroup = new Phaser.Group(game, null);
      const img = G.makeImage(0, 0, dataSet.asset, 0, null);
      img.height = img.width = iconDimensions;
      img.x = 50 - iconDimensions;
      let textString;
      switch (dataSet.type) {
        case REG_DEAL_PRIORITY.inflives: textString = `${dataSet.amount} h`; break;
        default: textString = `x ${dataSet.amount}`; break;
      }
      const text = new G.Text(0, 0, textString, { style: coinBarData.textStyle, fontSize: iconDimensions }, 0, iconDimensions, iconDimensions);
      text.x = Math.round(img.x + 5 + img.width);
      boosterGroup.addChild(img);
      boosterGroup.addChild(text);
      boosterDetails.push(boosterGroup);
    });

    // Layout is done from here
    let maxWidth = 0;
    let usedWidth = 0;
    let nextY = 0;
    // Coin bar first
    if (this._data.slot === 1) { // 1 slot layout
      if (coinBar) {
        if (this._config.orientation === ORIENTATION.horizontal) { // horizontal
          coinBar.scale.set(1);
          coinBar.x = Math.round(2 + (this._boosterBox.x - this._boosterBoxDimension.width / 4));
          coinBar.y = Math.round(this._boosterBox.y - 5);
          usedWidth += this._boosterBoxDimension.width + coinBar.x + coinBar.width;
        } else { // Vertical
          const midPoint = this._boosterBoxDimension.height * 0.15;
          coinBar.x = Math.round(5 + this._boosterBox.x);
          coinBar.y = Math.round(this._boosterBox.y - (this._boosterBoxDimension.height / 2) + midPoint);
          nextY += 20 + coinBar.height / 2;
        }
      }
      if (this._config.orientation === ORIENTATION.horizontal) { // Horizontal
        maxWidth = (this._boosterBoxDimension.width * 0.95) - usedWidth;
      } else {
        if (boosterDetails.length < 3) { // Vertical less than 3 items
          maxWidth = this._boosterBoxDimension.width / 2;
        } else { // Vertical more than 3 items
          maxWidth = this._boosterBoxDimension.width * 0.96;
        }
      }
    } else if (this._data.slot === 2) { // 2 slot layout
      if (coinBar) {
        coinBar.x = Math.round(this._chest.x);
        if (this._config.orientation === ORIENTATION.horizontal) { // Horizontal
          coinBar.scale.set(1.75);
          coinBar.y = Math.round(this._chest.y + this._chest.height * 0.47);
        } else { // Vertical
          coinBar.scale.set(1.3);
          coinBar.y = Math.round(15 + this._chest.y + this._chest.height * 0.35);
          nextY -= 5;
        }
      }
      if (this._config.orientation === ORIENTATION.horizontal) { // Horizontal
        maxWidth = this._boosterBoxDimension.width * 0.2;
      } else { // Vertical
        if (boosterDetails.length < 4) { // Vertical less than 4 items
          maxWidth = this._boosterBoxDimension.width * 0.95;
          nextY += 20;
        } else { // Vertical 4 or more items
          maxWidth = this._boosterBoxDimension.width * 0.25;
        }
      }
    }

    // Sorting the boosters into rows
    const padding = 15;
    const boosterGroup = new Phaser.Group(game, this);
    const allRows = [];
    const getNewRow = () => ({ element: [], width: 0, container: new Phaser.Group(game, boosterGroup) });
    let row = getNewRow();
    boosterDetails.forEach((box) => {
      if (row.width + box.width > maxWidth) {
        allRows.push(row);
        row = getNewRow();
      }
      row.element.push(box);
      row.width += box.width + padding;
    });
    const yPadding = ((this._boosterBoxDimension.height * 0.75) - nextY - (safeDistance * 1.5)) / (allRows.length + 1);
    allRows.push(row);

    // Laying out the boosters by rows
    let boosterY = 0;
    allRows.forEach((innerRow) => {
      let nextX = this._isLandscape && this._data.slot === 2 ? 10 : 0;
      innerRow.element.forEach((element) => {
        element.x = nextX;
        element.y = boosterY;

        innerRow.container.addChild(element);
        nextX += element.width + padding;
      });
      innerRow.container.x -= Math.round(nextX / 2);
      boosterY += innerRow.container.height + yPadding / 4;
    });

    // Positioning the boosters as a whole
    if (this._config.orientation === ORIENTATION.horizontal) { // Horizontal
      if (this._data.slot === 1) { // Horizontal 1 slot
        if (coinBar) {
          const safePoint = 15 + this._boosterBoxDimension.width - usedWidth;
          const pt = ((this._boosterBoxDimension.width / 2) + ((this._boosterBoxDimension.width - safePoint) / 2));
          boosterGroup.x = Math.round(25 + (pt - this._boosterBoxDimension.width) + iconDimensions / 2);
        }
        boosterGroup.y = Math.round(this._boosterBox.y + nextY - (this._boosterBoxDimension.height / 2) + ((this._boosterBoxDimension.height - nextY) - boosterGroup.height) / 2);
      } else { // Horizontal 2 slot
        boosterGroup.x = Math.round(this._boosterBox.x + 30 + (boosterGroup.width - this._boosterBoxDimension.width) / 2);
        boosterGroup.y = this._boosterBox.y + nextY - (this._boosterBoxDimension.height / 2) + ((this._boosterBoxDimension.height - nextY) - boosterGroup.height) / 2;
      }
    } else { // Vertical
      if (this._data.slot === 1) { // Vertical 1 slot
        boosterGroup.x = Math.round(this._boosterBox.x);
        boosterGroup.y = Math.round(this._boosterBox.y + nextY - (this._boosterBoxDimension.height / 2) + ((this._boosterBoxDimension.height - nextY) - boosterGroup.height) / 2);
      } else { // Vertical 2 slot
        if (boosterDetails.length < 4) { // Vertical 2 slot less than 4 items
          boosterGroup.x = Math.round(this._boosterBox.x + iconDimensions / 2.5);
          const offset = (coinBar ? coinBar.y + coinBar.height / 2 : this._chest.y + this._chest.height / 2);
          boosterGroup.y = Math.max(offset, ((((this._boosterBox.y - this._boosterBoxDimension.height / 2) - offset)) + this._boosterBoxDimension.height) / 2);
        } else { // Vertical 2 slot, 4 or more items
          boosterGroup.x = Math.round(this._boosterBox.x - (this._boosterBoxDimension.width / 2) + (boosterGroup.width / 1.5) + iconDimensions / 4);
          boosterGroup.y = Math.round(this._boosterBox.y + nextY - (this._boosterBoxDimension.height / 2) + ((this._boosterBoxDimension.height - nextY) - boosterGroup.height) / 2);
        }
      }
    }
    // cache boosters + coinbar
    coinBar.x -= boosterGroup.x;
    coinBar.y -= boosterGroup.y;
    boosterGroup.addChild(coinBar);
    // boosterGroup.cacheAsBitmap = true;
  }

  /**
   * Fills the box with shop goodies, but for annuity!
   * @param {Object} coinBarData
   */
  _fillBoxForAnnuity(coinBarData) {
    const coinBar = new Phaser.Group(game, null);
    const bar = new Phaser.Group(game, coinBar);
    const barScale = this._isLandscape ? 1.20 : 1.15;
    const barBase = G.makeImage(0, 0, 'shop3_specialDealCoinBar', 0.5, bar);
    barBase.tint = coinBarData.tint;
    G.makeImage(0, 0, 'shop3_specialDealCoinBarHighlight', 0.5, bar); // highlight
    bar.scale.setTo(barScale);
    const coinScaleMod = this._isLandscape ? 2.5 : 1.5;
    const coinImage = G.makeImage(0, 0, 'coin_3', 0.5, null);
    coinImage.scale.set(((bar.height * coinScaleMod) / coinImage.height) * this._gameScale);
    bar.x = 5 + coinImage.width / 8;
    if (this._isLandscape) {
      coinImage.x = bar.x + (-(bar.width + coinImage.width) / 2) * this._gameScale;
    } else {
      coinImage.x = bar.x - (bar.width / 2) * this._gameScale;
    }
    const text = new G.Text(Math.round(coinImage.width / 8), 2, OMT.language.getText('%COINS% Total').replace('%COINS%', OMT.language.toLocaleNumber(this._data.parsed.coins)),
      'shop3-specialDealCoinText', 0.5, (bar.width - (coinImage.width)) * 0.9, (bar.height * 1.5) / this._gameScale);
    coinBar.addChild(coinImage);
    bar.addChild(text);

    this.addChild(coinBar);
    if (this._isLandscape) {
      coinBar.y = Math.round(this._boosterBox.y + (coinBar.height / 1.5) - (this._boosterBoxDimension.height) / 2);
    }
    this._createAnnuityDescription();
    if (!this._isLandscape) { // Portrait, but after the annuity desc
      coinBar.x = Math.round(this._boosterBox.x);
      coinBar.y = Math.round(this._boosterBox.y + ((coinBar.height / 1.5) - (this._boosterBoxDimension.height) / 2) * this._gameScale);
    }
  }

  /**
   * Creates the description text on the annuity.
   * @param {boolean} fadeIn
   */
  _createAnnuityDescription(fadeIn = false) {
    if (this._annuityDescriptionText) {
      this.removeChild(this._annuityDescriptionText);
      this._annuityDescriptionText.destroy();
    }
    const offerData = this._data.parsed.annuity;
    let descText;
    const existingAnnuity = G.saveState.annuityManager.getAnnuityPack(this._data.parsed.dealSegment);
    if (existingAnnuity) {
      descText = OMT.language.getText('%COINS% coins collected');
      descText = descText.replace('%COINS%', Math.floor(existingAnnuity.tdc * existingAnnuity.cpd));
    } else {
      const totalCoins = this._data.parsed.annuity.coin;
      descText = OMT.language.getText('Collect %COINS% coins everyday for %DAYS% days');
      descText = descText.replace('%COINS%', OMT.language.toLocaleNumber(totalCoins)).replace('%DAYS%', offerData.days);
    }
    this._annuityDescriptionText = new G.Text(0, 0, descText, 'shop3-annuityInfoText', 0.5, this._boosterBoxDimension.width * 0.8, this._boosterBoxDimension.height * 0.45, true, 'center');

    if (this._isLandscape) {
      this._annuityDescriptionText.y = Math.round(this._boosterBox.y - (this._annuityDescriptionText.height / 1.25) + (this._boosterBoxDimension.height / 2));
    } else {
      this._annuityDescriptionText.y = Math.round(this._boosterBox.y + (this._annuityDescriptionText.height) / 3);
      this._annuityDescriptionText.x = Math.round(this._boosterBox.x);
    }

    if (fadeIn) {
      this._annuityDescriptionText.alpha = 0;
      game.add.tween(this._annuityDescriptionText).to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
    }
    this.addChild(this._annuityDescriptionText);
  }

  /**
   * Create the button used to buy things
   * @param {{coinsPerday:number}} [config] Currently only used for annuity
   */
  _createButton(config = {}) {
    const button = new G.Button(0, 0, null, this._onButtonClick.bind(this));
    this._button = button;
    const buttonDimension = {};
    const isAnnuity = this._data.dealType === SHOP_DEAL_TYPES.ANNUITY && config.coinsPerday;
    if (this._config.orientation === ORIENTATION.horizontal) {
      if (isAnnuity) {
        buttonDimension.width = this.width * 0.65;
        buttonDimension.height = this.height * 0.125;
      } else {
        buttonDimension.width = this.width * 0.45;
        buttonDimension.height = this.height * 0.125;
      }
    } else {
      if (isAnnuity) {
        buttonDimension.width = this.width * 0.45;
        buttonDimension.height = 70;
      } else {
        buttonDimension.width = this._data.slot === 1 ? 170 : this.width * 0.85;
        buttonDimension.height = this._data.slot === 1 ? buttonDimension.width * 0.48 : this.height * 0.175;
      }
    }
    const buttonContainer = new Phaser.Group(game, button); // The container to scale the button
    const buttonImage = new OMT_UI_SquareButton(0, 0, { // Without the nineslice affecting it
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: buttonDimension.width, // original button size
          height: buttonDimension.height,
        },
      },
      options: {
        clickFunction: {
          scaleOnClick: false,
        },
      },
    });
    buttonImage.inputEnabled = false; // So it won't eat inputs. BUTTONSTATE.disabled will automatically set the button to grey
    buttonContainer.addChild(buttonImage);
    const buttonModDimensions = { width: buttonImage.width, height: buttonImage.height }; // I don't want to use long version
    if (isAnnuity) {
      const buttonText = this._createAnnuityClaimText(buttonModDimensions.width, buttonModDimensions.height, config.coinsPerday);
      buttonContainer.addChild(buttonText);
    } else {
      const buttonText = this._createButtonText(buttonModDimensions.width, buttonModDimensions.height);
      buttonContainer.addChild(buttonText);
    }

    button.resetAnimation = () => { // Resets the animation on the button
      if (button.downTween) {
        button.downTween.stop();
      }
      button.downTween = game.add.tween(buttonContainer.scale).to({
        x: 1,
        y: 1,
      }, 50, Phaser.Easing.Quadratic.In, true);
    };
    button.down = () => { // Override function that happens when clickHandler has onDown triggered
      if (button.downTween) {
        button.downTween.stop();
      }
      button.downTween = game.add.tween(buttonContainer.scale).to({
        x: 0.9,
        y: 0.9,
      }, 100, Phaser.Easing.Quadratic.In, true);
    };
    button.cancel = () => { // Override function that happens when ClickHandler triggers cancel
      button.resetAnimation();
    };

    // Positioning
    if (this._data.slot === 1) { // One slot
      if (this._config.orientation === ORIENTATION.vertical) { // One slot, vertical
        button.x = (this.width / 2) - buttonModDimensions.width / 1.75;
      }
      button.y = (this.height / 2) - buttonModDimensions.height / 1.7;
    } else if (this._data.slot === 2) { // Two slot
      button.y = (this.height / 2) - buttonModDimensions.height / 1.7;
    }
    this.addChild(button);
  }

  /**
   * Creates the G.Text on the button
   * @param {number} w
   * @param {number} h
   * @returns {G.Text}
   */
  _createButtonText(w, h) {
    return new G.Text(0, 1, this._data.deal.price, 'shop3-SpecialOffer', 0.5, w * 0.9, h * 0.9, false, 'center', false);
  }

  /**
   * Creates the text on the button, but has coins for the Annuity
   * @param {number} w
   * @param {number} h
   * @param {number} cpd
   * @returns {G.LabelTextT}
   */
  _createAnnuityClaimText(w, h, cpd) {
    const targetStyle = this._config.orientation === ORIENTATION.horizontal ? 'shop3-annuityClaimTextHorizontal' : 'shop3-annuityClaimTextVertical';
    const claim = OMT.language.getText('Claim');
    return new G.LabelTextT(`${claim} @coin_1@${OMT.language.toLocaleNumber(cpd)}`, 0, 0, targetStyle, 0.5, w * 0.9);
  }

  /**
   * Creates the bottom half of the Annuity pack.
   * It could be a:
   * - Button with a price
   * - Button with Claim and coins on it
   * - A banner saying to come back in a few hours
   * @param {boolean} fadeIn
   */
  _createBottomForAnnuity(fadeIn) {
    const annuityMan = G.saveState.annuityManager;
    const existingAnnuity = annuityMan.getAnnuityPack(this._data.parsed.dealSegment);
    if (!existingAnnuity) {
      this._createButton();
      return;
    }

    const timeToReset = annuityMan.getTimeUntilReset(existingAnnuity, true);
    if (timeToReset > 0) {
      const daysToClaim = annuityMan.getNumberOfDaysSinceLastClaimed(existingAnnuity.id);
      this._createButton({ coinsPerday: Math.floor(existingAnnuity.cpd * daysToClaim) });
    } else {
      const daysLeft = existingAnnuity.d - existingAnnuity.tdc;
      const bannerGroup = new Phaser.Group(game, this);
      if (fadeIn) { bannerGroup.alpha = 0; }
      const banner = G.makeImage(0, 0, 'shop3_annuityBanner', 0.5, bannerGroup);
      const widthMulti = this._isLandscape ? 1.03 : 0.52; // Horizontal is bigger
      banner.scale.setTo((this._calculatedBounds.width * widthMulti) / banner.width);

      const activeText = new G.Text(0, 0, OMT.language.getText('Active: %DAYS% days left').replace('%DAYS%', daysLeft), 'shop3-annuityActiveDaysText', 0.5, banner.width * 0.8);

      let timeString = '';
      const exactTime = MILLISECONDS_IN_DAY - annuityMan.getTimeUntilReset(existingAnnuity, false);
      if (exactTime < MILLISECONDS_IN_HOUR) {
        timeString = `${Math.floor(exactTime / MILLISECONDS_IN_MIN)}m`;
      } else {
        timeString = `${Math.floor(exactTime / MILLISECONDS_IN_HOUR)}h`;
      }
      const nextRewardText = new G.Text(0, 12, OMT.language.getText('Claim again in %TIME%').replace('%TIME%', timeString), 'shop3-annuityNextRewardText', 0.5, banner.width * 0.95);
      activeText.y = Math.round(nextRewardText.y - (nextRewardText.height + (activeText.height / 2)) / 2);

      bannerGroup.addChild(activeText);
      bannerGroup.addChild(nextRewardText);

      if (!this._isLandscape) { // One slot, vertical
        bannerGroup.x = Math.round(((this._calculatedBounds.width - bannerGroup.width) / 2) - 5);
      }
      bannerGroup.y = Math.round((this.height / 2) - bannerGroup.height / 1.7);
      if (fadeIn) {
        game.add.tween(bannerGroup).to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
      }
    }
  }

  /**
   * (Supposedly) Draws a full price of the pack with a line crossed through it
   * Makes it look more like a deal!
   * Only works if theres actually a fullprice to it though
   */
  _createFullPriceCut() {
    if (!this._data.fullPrice) { return; }
    const { price } = this._data.fullPrice;
    const priceCutGroup = new Shop3_PriceCutText();
    priceCutGroup.x = -0.75 * (this._calculatedBounds.width / 2);
    priceCutGroup.y = 0.8 * (this._calculatedBounds.height / 2);
    priceCutGroup.angle = 15;
    priceCutGroup.setText(price);
    this.addChild(priceCutGroup);
  }

  /**
   * Creates the tag in the corner based on description
   * @param {number} assetIndex
   */
  _createCornerTag(assetIndex) {
    const tag = this._cornerTag = new Phaser.Group(game, this);
    const base = G.makeImage(0, 0, this._asset.value.base + assetIndex, 0.5, tag); // tint base
    const textPack = new Phaser.Group(game, tag);
    const regex = new RegExp('\\%(\\d+)\\%');
    const text = G.saveState.sessionData.shopTagText || this._data.deal.description;
    const regexValue = regex.exec(text);
    const value = regexValue ? regexValue[1] : '';
    const strCopy = text.replace(new RegExp('\\%\\d+\\%'), '%VALUE%');
    const targetText = OMT.language.doesTextExist(OMT.language.lang, strCopy) ? OMT.language.getText(strCopy) : strCopy;
    const wowText = new G.Text(1, 8,
      targetText.replace('%VALUE%', value).replace('<br>', '\n'), 'shop3-ValueDeal',
      0.5, base.width * this._asset.value.textMultiplier, base.height * this._asset.value.textMultiplier, true, 'center');
    textPack.addChild(wowText);

    textPack.x = 2;
    textPack.y = -1 - Math.round(textPack.height / 8);

    const tagOffset = this._config.orientation === ORIENTATION.vertical ? tag.width : tag.width / 2 - 25;
    tag.x = Math.round((this._calculatedBounds.width - tagOffset) / 2);
    tag.y = Math.round(((tag.height / 4) - this._calculatedBounds.height) / 2);

    if (this._config.orientation === ORIENTATION.horizontal) {
      // Multi-slot deals get a larger corner tag in landscape
      if (this._data.slot > 1) {
        tag.x = Math.round(tag.x - tag.width / 8);
        tag.scale.setTo(1.25 * this._gameScale);
      } else {
        tag.scale.setTo(this._gameScale);
      }
    }

    tag.angle = 35;
    // tag.cacheAsBitmap = true;
  }

  /**
   * Toggles if the signal for purchasing will go out or not
   * @param {boolean} b
   */
  toggleButton(b) {
    if (b === undefined) {
      b = !this._purchaseEnabled;
    }

    this._purchaseEnabled = b;
  }

  /**
   * Returns useful information about the annuity pack, if it is an annuity spec deal
   * @returns {ParsedShopData}
   */
  changeToWaitAnnuity() {
    if (!this._button) { return; }
    game.add.tween(this._annuityDescriptionText).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
    const tw = game.add.tween(this._button).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
    tw.onComplete.addOnce(() => {
      this._button.destroy();
      this._button = null;
      this._createBottomForAnnuity(true);
      this._createAnnuityDescription(true);
    });
  }

  /**
   * When the button is clicked
   */
  _onButtonClick() {
    if (!this._purchaseEnabled) { return; }
    this._button.resetAnimation();
    this.signals.onClick.dispatch(this._data.dealType, this._data.deal, this._button, this);
  }
}
