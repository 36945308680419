import LossAversionWheelGroup from '../IAP/LossAversionWheelGroup';

export default class TreasureHuntLossAversionWheelGroup extends LossAversionWheelGroup {
  /**
   * constructor
   * @param {Object} config
   */
  constructor(config) {
    super(config);
  }

  /**
   * create the title text
   */
  _createTitleText() {
    G.makeImage(0, -265, 'treasureHunt_banner', 0.5, this);
    this.titleTxt = new G.Text(0, -280, OMT.language.getText('Spin Wheel'), G.OMTsettings.elements.LossAversionWheelGroup.titleStyle, 0.5, 320);
    this.add(this.titleTxt);
  }
}
