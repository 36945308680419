import { OMT_UI_AvatarWithFrame } from './OMT_UI_AvatarWithFrame';

export default class OMT_UI_AvatarWithCustomFrame extends OMT_UI_AvatarWithFrame {
  /**
   * Creates an avatar with a custom frame. The frame asset string. Has extra params to modify the frame
   * @param {Object} config
   * @param {string} config.imageURL
   * @param {number} config.imageSize
   * @param {Array<String>} [config.defaultFrames]
   * @param {Phaser.Point} [config.frameOffset]
   * @param {string} config.frameAsset
   * @param {number} [config.frameMultSize] Modfies the frame size by a multiplicative number
   */
  constructor(config) {
    super(config.imageURL, config.imageSize, config.defaultFrames);

    this._initCustomAvatarFrame(config.frameAsset, config.frameOffset, config.frameMultSize);
  }

  /**
   * Creates a custom avatar. The frame is important but the rest are optional
   * @param {string} frameString
   * @param {Phaser.Point} [frameOffset]
   * @param {number} [frameMultSize]
   */
  _initCustomAvatarFrame(frameString, frameOffset, frameMultSize) {
    const frameSize = this._frameSize * (frameMultSize || 1);
    this._avatarFrame = new Phaser.Group(game, this);
    G.makeImage((frameOffset.x || 0) / 2, (frameOffset.y || 0) / 2, frameString, 0.5, this._avatarFrame);
    this._avatarFrame.width = this._avatarFrame.height = frameSize;
    this.addChild(this._avatarFrame);
  }

  _initAvatarFrame() {
    // Empty because I don't need this...
  }
}
