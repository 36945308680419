export default class UI_PointsCounterBase extends Phaser.Group {
  /**
   * The goal panel for when its collecting points rather than gems
   * @param {number} x
   * @param {number} y
   * @param {{points: (string|object), amount: (string|object)}} config
   */
  constructor(x, y, config) {
    super(game, null);

    this.x = Math.floor(x);
    this.y = Math.floor(y);

    this._points = 0;
    this._pointsTarget = 0;

    this._createPointsCounter(config);

    this._signalBinding = G.sb('onPointsChange').add(this._onPointsChange.bind(this));

    game.add.existing(this);
  }

  /**
   * get the current points
   * @returns {number}
   */
  get points() {
    return this._points;
  }

  get text() {
    return this._pointsCounter.text;
  }

  /**
   *
   * @param {{points: (string|object), amount: (string|object)}} config
   */
  _createPointsCounter(config) { // eslint-disable-line no-unused-vars
    // Override
  }

  _onPointsChange(amount) {
    this._pointsTarget = amount;
  }

  /**
   * Returns updated text on update
   * @returns {string}
   */
  _getUpdatedText() {
    return `${G.capitalize(OMT.language.getText('points'))}: ${this._points.toString()}`;
  }

  /**
   * Update
   */
  update() {
    super.update();
    if (this._points === this._pointsTarget) return;
    this._points += (game.math.clamp(Math.ceil((this._pointsTarget - this._points) * 0.2), 0, this._pointsTarget - this._points));
    this._pointsCounter.setText(this._getUpdatedText());
  }

  /**
   * destruction method
   */
  destroy() {
    if (this._signalBinding && this._signalBinding.detach) {
      this._signalBinding.detach();
    }
    this._signalBinding = null;
    super.destroy();
  }
}
