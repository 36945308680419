import UI_AnimationGroup from '@omt-components/UI/Animation/UI_AnimationGroup';

/**
 * TutorialGroup was moved away to UI_AnimationGroup because its functionality could be used elsewhere
 * TutorialGroup is now for tutorial messages and whatever functionality it can give to the tutorial groups
 * (Which just happen to be barely anything right now)
 *
 * @author Sandra Koo
 */
export default class TutorialGroup extends UI_AnimationGroup {
  constructor(inGame, inParent) {
    super(inGame, inParent);

    this.signals = { // Signals for Window_popUpTutorial to deal with
      onContinueClick: new Phaser.Signal(),
    };
  }
}
