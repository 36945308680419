/* eslint-disable object-curly-newline */

// LevelLeaderboard.js settings
export const HIDE_ANIMATION_DURATION = 500;
export const HIDE_OFFSET_Y = 300;
export const DUMMY_USER_DATA = { name: '-------', image: null, score: '' };
export const MIN_ENTRIES_LIST_LENGTH = 5;

// LevelLeaderboardScrollView.js settings
export const H_SCROLLER_RECT = new Phaser.Rectangle(0, 0, 470, 140);
export const H_SCROLLER_VIEW_SPACING = 145;
export const H_SCROLLER_POS = { x: -304, y: -110 };

// LevelLeaderboardUserView.js settings
export const USER_VIEW_RECT = new Phaser.Rectangle(0, 15, 143, 80);
export const USER_VIEW_AVATAR_SIZE = 54;
export const USER_VIEW_AVATAR_POS = { x: 37, y: 40 };
export const USER_VIEW_TEXT_MAX_WIDTH = 66;
export const USER_VIEW_USERNAME_TEXT_STYLE = { style: 'font-blue', fontSize: '20px', align: 'left' };
export const USER_VIEW_USERNAME_POS = { x: 71, y: 33 };
export const USER_VIEW_SCORE_TEXT_STYLE = { style: 'font-blue', fontSize: '23px', align: 'left' };
export const USER_VIEW_SCORE_POS = { x: 71, y: 53 };
export const USER_VIEW_BG_RECT = new Phaser.Rectangle(10, 12, 130, 56);

// LevelLeaderboardInviteButtons.js settings
export const INVITE_BUTTON_POS = { x: 235, y: -55 };
export const INVITE_BUTTON_TEXT_STYLE = { style: 'font-white', fontSize: '50px', lineSpacing: -10 };
export const INVITE_BUTTON_DIMENSIONS = { width: 132, height: 68 };
