/* eslint-disable no-use-before-define */
import { FX_ParticleEmitter } from './FX_ParticleEmitter';

const DEFAULT_BURST_COUNT = Infinity;
const MAX_POS_RANDOMIZATION_ATTEMPTS = 10;

/**
 * class for displaying / managing sequenced particle FX
 * @param {FX_ParticleEmitterConfig} emitterConfig
 */
export class FX_EmitterSequencer extends Phaser.Group {
  /**
   * constructor
   * @param {Object} emitterConfig
   * @param {string} soundFX (optional) sound fx id to play
   */
  constructor(emitterConfig) {
    super(game);

    this._emitterConfig = emitterConfig;
    this._emitters = [];
    this._emitPos = { x: 0, y: 0 };
    this._freeEmitters = [];
    this._burstsEmitted = 0;
  }

  /**
   * set the update timer
   * @param {number} delay delay between emitter creation
   * @param {number} randomX max randomized value applied to y-axis
   * @param {number} randomY max randomized value applied to x-axis
   * @param {number} minDistance (optional) minimum distance allowed between sequential bursts. 0 ignores this check.
   * @param {number} burstCount (optional) max amount of 'bursts' that will occur, default unlimited
   */
  startUpdateTimer(delay, randomX, randomY, minDistance = 0, burstCount = DEFAULT_BURST_COUNT) {
    this.stopUpdateTimer();
    this._timerEvent = game.time.events.add(delay, () => {
      const { x, y } = this._randomizeEmitPos(randomX, randomY, minDistance);
      this._addFireworkEmitter(x, y);
      if (this._burstsEmitted < burstCount) {
        this.startUpdateTimer(delay, randomX, randomY, minDistance, burstCount);
      }
    });
  }

  /**
   * randomize emitter position. enforce minimum distance
   * @param {number} randomX max randomized value applied to x-axis
   * @param {number} randomY max randomized value applied to y-axis
   * @param {number} minDistance minimum distance allowed between sequential bursts. 0 ignores this check.
   * @param {number} attempts to keep track of itteration
   */
  _randomizeEmitPos(randomX, randomY, minDistance, attempts = 0) {
    const emitX = (Math.random() * randomX * 2) - randomX;
    const emitY = (Math.random() * randomY * 2) - randomY;
    const distance = game.math.distance(this._emitPos.x, this._emitPos.y, emitX, emitY);
    if (minDistance > 0 && distance < minDistance && attempts < MAX_POS_RANDOMIZATION_ATTEMPTS) {
      return this._randomizeEmitPos(randomX, randomY, minDistance, attempts++);
    }
    this._emitPos.x = emitX; this._emitPos.y = emitY;
    return this._emitPos;
  }

  /**
   * stop the fireworks
   */
  stopUpdateTimer() {
    if (this._timerEvent) game.time.events.remove(this._timerEvent);
    this._timerEvent = null;
  }

  /**
   * add a firework emitter
   * @param {number} emitX
   * @param {number} emitY
   */
  _addFireworkEmitter(emitX, emitY) {
    const emitter = this._freeEmitters.length > 0 ? this._freeEmitters.pop() : new FX_ParticleEmitter(this._emitterConfig);
    emitter.resetSpawnTiming();
    emitter.x = emitX; emitter.y = emitY;
    this._emitters.push(emitter);
    this.addChild(emitter);
    this._burstsEmitted++;
  }

  /**
   * update particle emitter instances
   */
  _updateEmitters() {
    let emitter;
    for (let i = this._emitters.length - 1; i >= 0; i--) {
      emitter = this._emitters[i];
      emitter.update();
      // remove inactive emitters, and store for resuse
      if (emitter.activeParticleCount === 0) {
        this._emitters.splice(i, 1);
        this.removeChild(emitter);
        this._freeEmitters.push(emitter);
      }
    }
  }

  /**
   * phaser update method
   */
  update() {
    super.update();
    this._updateEmitters();
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this.stopUpdateTimer();
  }
}
