/* eslint-disable prefer-destructuring */
export default class CollectableAnimPart extends Phaser.Image {
  /**
   * Despite the world map having its particle in the same class as its layer,
   * THIS ONE ISNT!?
   * @param {G.Board} board
   */
  constructor(board) {
    super(game, null);
    this.kill();
    this.anchor.set(0.5);
    this._board = board;
  }

  /**
   * Particle changes texture to sprite and flies to goal panel for collecting
   * @param {string} type
   * @param {Candy} candy
   * @param {GoalPanel} target
   * @param {Sprite} sprite
   * @param {function} callback
   * @param {context} context
   */
  init(type, candy, target, sprite, callback, context) {
    this.revive();

    const pxOut = this._board.cellToPxOut([candy.cellX, candy.cellY]);

    this.x = pxOut[0];
    this.y = pxOut[1];
    this.scale.set(1);
    this.alpha = 1;

    G.changeTexture(this, sprite || G.json['configs/gameplayGoals'][type].sprite);

    const targetX = target.img.worldPosition.x + game.world.bounds.x;
    const targetY = target.img.worldPosition.y;

    game.add.tween(this.scale).to({ x: 1.2, y: 1.2 }, 250, Phaser.Easing.Sinusoidal.Out, true).onComplete.add(() => {
      // eslint-disable-next-line object-curly-newline
      game.add.tween(this).to({ x: targetX, y: targetY, width: target.img.width * target.scale.x, height: target.img.height * target.scale.y }, 500, Phaser.Easing.Sinusoidal.InOut, true)
        .onComplete.add(() => {
          if (callback) callback.call(context);

          game.add.tween(this).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true);
          game.add.tween(this.scale).to({ x: 2, y: 2 }, 300, Phaser.Easing.Sinusoidal.Out, true).onComplete.add(() => {
            this.kill();
          });
        });
    });
  }

  /**
   * @param {GoalPanel.getGoalPanel} panel Whatever that is
   */
  initNofly(panel) {
    this.revive();

    this.x = game.world.bounds.x + panel.img.worldPosition.x;
    this.y = panel.img.worldPosition.y;
    this.alpha = 1;

    G.changeTexture(this, G.json['configs/gameplayGoals'][panel.goalName].sprite);
    this.width = panel.img.width * panel.scale.x;
    this.height = panel.img.height * panel.scale.y;

    game.add.tween(this).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true);
    game.add.tween(this.scale).to({ x: 1.5, y: 1.5 }, 300, Phaser.Easing.Sinusoidal.Out, true).onComplete.add(() => {
      this.kill();
    });
  }
}
