/* eslint-disable import/no-unresolved */

const aesJs = require('../../../../node_modules/aes-js/index');
const md5 = require('../../../../node_modules/js-md5/src/md5');
const { keccak224 } = require('../../../../node_modules/js-sha3/build/sha3.min');

/**
 * Utility class for encryption related things
 */
export class OMT_Crypt {
  /**
   * generate a md5 hash
   * @param {string} text
   * @returns {string} md5 hash
   */
  static md5Hash(txt) {
    return md5(txt);
  }

  /**
   * generate a SHA-3 hash
   * @param {string} txt
   * @returns {string} md5 hash
   */
  static sha3Hash(txt) {
    return keccak224(txt);
  }

  /**
   * Encodes data with a modification of the given key
   * @param {string} key
   * @param {*} data
   * @return {string} - Encrypted HEX String of the given data
   */
  static encode(key, data) {
    const modifiedKey = OMT_Crypt.modifyKey(key);
    const json = JSON.stringify(data);
    const bytes = aesJs.utils.utf8.toBytes(json);
    // eslint-disable-next-line new-cap
    const aesCtr = new aesJs.ModeOfOperation.ctr(modifiedKey);
    const encryptedBytes = aesCtr.encrypt(bytes);
    return aesJs.utils.hex.fromBytes(encryptedBytes);
  }

  /**
   * Obscures encryption key. Used for encoding / decoding data.
   * @param {string} key
   * @return {Array.<number>}
   */
  static modifyKey(key) {
    const keyParts = Array.from(key).map((c) => c.charCodeAt(0));
    // eslint-disable-next-line no-bitwise
    return keyParts.map((n, i) => (n ^ (keyParts[i + 1] || keyParts[0])));
  }

  /**
   * Decodes AES encrypted data for a previously created key for the given id
   * @param {string} key
   * @param {string} encodedData - HEX string of the encoded data
   * @return {*}
   */
  static decode(key, encodedData) {
    const modifiedKey = OMT_Crypt.modifyKey(key);
    const encryptedBytes = aesJs.utils.hex.toBytes(encodedData);
    // eslint-disable-next-line new-cap
    const aesCtr = new aesJs.ModeOfOperation.ctr(modifiedKey);
    const decryptedBytes = aesCtr.decrypt(encryptedBytes);
    const decryptedText = aesJs.utils.utf8.fromBytes(decryptedBytes);
    return JSON.parse(decryptedText);
  }

  /**
   * generate AES encryption key for the game local storage
   * @param {string} gameId
   * @param {string} userId
   * @returns {string}
   */
  static generateLocalStorageCryptKey(gameId, userId, cKeyLength = 32) {
    let cKey = null;
    if (!gameId) throw new Error('Could not retrieve gameId');
    cKey = `tibor-${gameId}-rene-${userId}`.substr(0, cKeyLength);
    while (cKey.length < cKeyLength) cKey += 'X';
    return cKey;
  }
}
