import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { friendInviteFeedback } from '../../Elements/Helpers/friendInviteFeedback';

import {
  INVITE_BUTTON_POS,
  INVITE_BUTTON_TEXT_STYLE,
  INVITE_BUTTON_DIMENSIONS,
} from '../LevelLeaderboardSettings';

/**
 * class for the level leaderboard invite button
 */
export class LevelLeaderboardInviteButton extends OMT_UI_SquareButton {
  /**
   * constructor
   */
  constructor() {
    super(INVITE_BUTTON_POS.x, INVITE_BUTTON_POS.y, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: INVITE_BUTTON_DIMENSIONS,
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText('Invite!'),
        textStyle: INVITE_BUTTON_TEXT_STYLE,
      },
      options: {
        clickFunction: {
          onClick: () => { this._onInviteButtonClicked(); },
        },
      },
    });
  }

  /**
   * called when either of the invite buttons is clicked
   */
  _onInviteButtonClicked() {
    OMT.social.chooseContextAndInvite().then((result) => {
      if (result) {
        // show invite feedback ripped form old leaderboard
        // eslint-disable-next-line new-cap
        const feedback = new friendInviteFeedback(G.game, null);
        feedback.x = game.world.bounds.x + game.width / 2;
        feedback.y = feedback.height / 2;
        game.world.addChild(feedback);
        feedback.enterAndSelfDestroy();

        // tracking
        // DDNA.socialTracker.incrementParam('numFriendsInvites', 1);
        // DDNA.tracking.socialActionEvent('Invite', '', '', '');
        const inviteSourceId = OMT.platformTracking.Events.InviteFromLevelLeaderboard;
        OMT.platformTracking.logEvent(inviteSourceId);
      }
    });
  }
}
