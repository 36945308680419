/* eslint-disable func-names */
// Add permanents signal functionality
{
  /**
   * Add a permanent event listener to the signal.
   * @param  {any} args
   * @returns {Phaser.Signal.signalBinding} signalBinding
   */
  const addPermanent = function (...args) {
    const signal = this;

    const signalBinding = signal.add.bind(signal)(...args);
    signalBinding._PERMANENT = true;
    return signalBinding;
  };

  const addOncePermanent = function (...args) {
    const signal = this;

    const signalBinding = signal.addOnce.bind(signal)(...args);
    signalBinding._PERMANENT = true;
    return signalBinding;
  };

  /**
   * Remove all non-permanent bindings from the signal.
   */
  const removeNonPermanent = function () {
    const signal = this;

    if (!signal._bindings) return;

    for (let i = signal._bindings.length - 1; i > -1; i--) {
      if (!signal._bindings[i]._PERMANENT) {
        signal._bindings[i]._destroy();
        signal._bindings.splice(i, 1);
      }
    }
  };

  if (!Phaser.Signal.prototype.addPermanent) {
    Phaser.Signal.prototype.addPermanent = addPermanent;
    Phaser.Signal.prototype.addOncePermanent = addOncePermanent;
    Phaser.Signal.prototype.removeNonPermanent = removeNonPermanent;
  }
}
