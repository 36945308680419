import OMT_UI_DrawUtil from '../../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import OMT_UI_AvatarWithCustomFrame from '../../../OMT_UI/FriendsList/OMT_UI_AvatarWithFrameOffset';

export default class EventPostcard_Util {
  /**
   * Makes image + avatar frame specifically for eventPostcard.
   * It has offsets due to extra flourish on the frame
   * @param {string} avatarImg
   * @param {number} size
   * @returns {Phaser.Group}
   */
  static drawAvatarWithFrame(avatarImg, size) {
    const container = new Phaser.Group(game, null);
    const avatarFrame = new OMT_UI_AvatarWithCustomFrame({
      imageURL: avatarImg,
      imageSize: size,
      frameOffset: G.OMTsettings.postcardEvent.cardData.avatarFrameOffset,
      frameAsset: G.OMTsettings.postcardEvent.cardData.avatarFrame,
      frameMultSize: 1.18,
    });
    container.addChild(avatarFrame);
    const containerScale = size / container.height;
    container.scale.set(containerScale);
    container.angle = -5;
    return container;
  }

  /**
   * Makes a speech bubble using a nineslice method. This is specifically for eventPostcard
   * @param {number} postcardIndex
   * @param {Phaser.Group} avatar
   * @param {number} leftoverSpeechSpace
   * @returns {{text:Phaser.Text, bubble:Phaser.Group}}
   */
  static makeSpeechBubble(postcardIndex, avatar, leftoverSpeechSpace) {
    const postcardData = G.OMTsettings.postcardEvent.cardData.postcardData[postcardIndex];
    const container = new Phaser.Group(game, null);

    // Draw the speech bubble and text
    const speechSlice = G.OMTsettings.postcardEvent.cardData.speechBubble.slice;
    const speechConstraints = {
      x: 20,
      width: (leftoverSpeechSpace * 0.65) - 20,
    };
    const speechText = new G.Text(speechConstraints.x, 0, OMT.language.getText(postcardData.speechText),
      'eventPostcard-speechText', 0.5, speechConstraints.width, avatar.height * 0.8, true, 'center');
    const bubble = G.makeImage(0, 0, 'eventPostcardSpeech', 0, null);
    bubble.x -= bubble.width / 2;
    bubble.y -= bubble.height / 2;
    container.addChild(bubble);
    container.addChild(speechText);
    container.x = Math.round(avatar.x + (avatar.width + container.width) / 2);
    container.y = Math.round(avatar.y);

    return {
      text: speechText,
      bubble: container,
    };
  }

  /**
   * Draws the weird event postcard fullscreen header
   */
  static drawPostcardFrame() {
    // Top border
    const headerContainer = new Phaser.Group(game, null);

    this.redrawGradients(headerContainer);

    const titleAsset = G.makeImage(0, 0, G.OMTsettings.postcardEvent.titleAsset, 0.5, headerContainer);
    headerContainer.titleAsset = titleAsset;

    // OMT.language.getText()
    const text = new G.Text(0, 0, G.OMTsettings.postcardEvent.cardTitle, 'eventPostcard-title', 0.5, headerContainer.gradientData.titleBg.image.width * 0.9,
      headerContainer.gradientData.titleBg.image.height * 0.85, true, 'center');
    headerContainer.addChild(text);
    headerContainer.titleText = text;

    return headerContainer;
  }

  /**
   * Takes an object that is preferrably modified by redrawGradients.
   * Destroys the gradient data
   * @param {Phaser.Group} container
   */
  static clearGradientData(container) {
    if (container.gradientData) {
      for (const key in container.gradientData) {
        if (Object.prototype.hasOwnProperty.call(container.gradientData, key)) {
          const data = container.gradientData[key];
          data.image.destroy();
          data.bitmap.destroy();
        }
      }
      container.gradientData = null;
    }
  }

  /**
   * Redraws the gradients for the fullscreen header
   * BE SURE TO DELETE BITMAP DATA WHEN YOU'RE DONE
   * @param {Phaser.Group} container
   */
  static redrawGradients(container) {
    this.clearGradientData(container);

    // The top gradient
    const topGradientDimension = { width: game.width, height: 110 };
    const topBorderGradientPack = OMT_UI_DrawUtil.createGradientTexture(G.OMTsettings.postcardEvent.bannerColours, game, null, topGradientDimension, 'linear', [
      { x: topGradientDimension.width / 2, y: 0 }, // This is the positioning of the gradient points
      { x: topGradientDimension.width / 2, y: topGradientDimension.height },
    ]);
    topBorderGradientPack.bitmap.polygon([ // This draws the weird rectangle shape
      { x: 0, y: 0 },
      { x: game.width, y: 0 },
      { x: game.width, y: topBorderGradientPack.bitmap.height - 25 },
      { x: 0, y: topBorderGradientPack.bitmap.height },
      { x: 0, y: 0 },
    ], topBorderGradientPack.gradient);
    const pinkBg = new Phaser.Image(game, 0, 0, topBorderGradientPack.bitmap); // Put it into an image
    container.addChild(pinkBg);

    // The white text box
    const whiteTextBoxWidth = game.width * 0.7; // Width of the box
    const whiteTextBoxRadius = 20; // Radius of corners
    const whiteTextBoxHeight = 110; // Max height
    const whiteBgDimension = { width: whiteTextBoxWidth, height: 110 }; // Gradient dimensions for positioning
    const whiteGradientPack = OMT_UI_DrawUtil.createGradientTexture(G.OMTsettings.postcardEvent.titleColours, game, null, whiteBgDimension, 'linear', [
      { x: whiteBgDimension.width / 2, y: 0 },
      { x: whiteBgDimension.width / 2, y: whiteBgDimension.height },
    ]);
    whiteGradientPack.bitmap.polygon([ // Draws that terrible box
      { x: whiteTextBoxRadius * 1.5, y: whiteTextBoxRadius },
      { x: whiteTextBoxWidth - whiteTextBoxRadius, y: 0 },
      { x: whiteTextBoxWidth, y: whiteTextBoxRadius },
      { x: whiteTextBoxWidth, y: 90 - whiteTextBoxRadius },
      { x: whiteTextBoxWidth - whiteTextBoxRadius, y: 90 },
      { x: whiteTextBoxRadius * 2, y: whiteTextBoxHeight },
      { x: whiteTextBoxRadius, y: whiteTextBoxHeight - whiteTextBoxRadius + 5 },
      { x: whiteTextBoxRadius * 0.5, y: whiteTextBoxRadius * 2 },
      { x: whiteTextBoxRadius * 1.5, y: whiteTextBoxRadius },
    ], whiteGradientPack.gradient); // Below adds in the circle corners
    whiteGradientPack.bitmap.circle(whiteTextBoxWidth - whiteTextBoxRadius, whiteTextBoxRadius, whiteTextBoxRadius, whiteGradientPack.gradient);
    whiteGradientPack.bitmap.circle(whiteTextBoxWidth - whiteTextBoxRadius, 90 - whiteTextBoxRadius, whiteTextBoxRadius, whiteGradientPack.gradient);
    whiteGradientPack.bitmap.circle(whiteTextBoxRadius * 2, whiteTextBoxHeight - whiteTextBoxRadius, whiteTextBoxRadius, whiteGradientPack.gradient);
    whiteGradientPack.bitmap.circle(whiteTextBoxRadius * 1.5, whiteTextBoxRadius * 2, whiteTextBoxRadius, whiteGradientPack.gradient);
    const whiteBg = new Phaser.Image(game, 0, 0, whiteGradientPack.bitmap); // Stick it into an image
    whiteBg.x = (pinkBg.width - whiteBg.width) / 2; // Position it
    whiteBg.y = whiteTextBoxHeight * 0.25;
    container.addChild(whiteBg);

    container.gradientData = {
      backBg: { image: pinkBg, bitmap: topBorderGradientPack.bitmap },
      titleBg: { image: whiteBg, bitmap: whiteGradientPack.bitmap },
    };
  }
}
