/* eslint-disable operator-linebreak */
G.Input = G.Input || {};
G.Input.createCustomInputDragHandler = () => {
  // imports
  const { EventType } = G.Input;
  const { StateMachine } = G;

  const signals = {
    inputDown: new Phaser.Signal(),
    start: new Phaser.Signal(),
    update: new Phaser.Signal(),
    stop: new Phaser.Signal(),
    cancel: new Phaser.Signal(),
  };

  const State = {
    Idle: 'idle',
    Dragging: 'dragging',
    Cancel: 'cancel',
    DragFinish: 'confirm',
  };

  const doCreateStateMachine = () => {
    const transitions = {};
    transitions[State.Idle] = [State.Dragging];
    transitions[State.Dragging] = [
      State.Dragging,
      State.Cancel,
      State.DragFinish,
    ];
    transitions[State.Cancel] = [State.Idle];
    transitions[State.DragFinish] = [State.Idle];

    const stateMachine = new StateMachine(State.Idle, transitions);

    stateMachine.transitionSignal.add((newState, oldState, optPayload) => {
      switch (newState) {
        case State.Idle:
          break;

        case State.Dragging:
          if (oldState === State.Idle) {
            signals.start.dispatch(optPayload);
          } else if (oldState === State.Dragging) {
            signals.update.dispatch(optPayload);
          }
          break;

        case State.Cancel:
          stateMachine.transit(State.Idle);
          signals.cancel.dispatch(optPayload);
          break;

        case State.DragFinish:
          stateMachine.transit(State.Idle);
          signals.stop.dispatch(optPayload);
          break;

        default:
          break;
      }
    });

    return stateMachine;
  };

  const stateMachine = doCreateStateMachine();

  const handleEventShared = (eventType, pointer) => {
    if (eventType === EventType.InputDown) {
      signals.inputDown.dispatch();
      return false;
    }
    if (eventType === EventType.DragStart) {
      return stateMachine.transitIfPossible(State.Dragging, pointer);
    }

    if (
      eventType === EventType.DragUpdate ||
      eventType === EventType.DragStop
    ) {
      return stateMachine.isInState(State.Dragging);
    }

    return false;
  };

  const handleEventExclusive = (eventType, pointer) => {
    if (eventType === EventType.DragUpdate) {
      // This includes transition in place (State.Dragging -> State.Dragging)
      return stateMachine.transitIfPossible(State.Dragging, pointer);
    }
    if (eventType === EventType.DragStop) {
      return stateMachine.transitIfPossible(State.DragFinish, pointer);
    }
    return false;
  };

  const cancelEvent = () => {
    stateMachine.transitIfPossible(State.Cancel);
  };

  const customInput = {
    // custom input handler interface
    handleEventShared,
    handleEventExclusive,
    cancelEvent,

    // custom
    inputDownSignal: signals.inputDown,
    dragStartSignal: signals.start,
    dragUpdateSignal: signals.update,
    dragStopSignal: signals.stop,
    dragCancelSignal: signals.cancel,
  };

  return customInput;
};
