import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

/**
 * Sound mute button
 */
export default class OMT_UI_SoundButton extends UI_GButtonES6 {
  /**
   * constructor
   */
  constructor() {
    super(0, 0, 'small_button_yellow', () => { this._toggleSound(); });
    this._icon = G.makeImage(0, 0, 'fx_on_icon', [0.5, 0.5]);
    this.addChild(this._icon);
    this._updateIconTexture();
  }

  /**
   * update the icon texture based on mute state
   */
  _updateIconTexture() {
    const soundEnabled = G.soundManager.getSoundEnabled();
    this._icon.changeTexture(soundEnabled ? 'fx_on_icon' : 'fx_off_icon');
  }

  /**
   * toggle the mute state
   */
  _toggleSound() {
    const state = !G.soundManager.getSoundEnabled();
    G.soundManager.setSoundEnabled(G.MuteReason.UserChoice, state);
    this._updateIconTexture();
    G.saveState.data.muteFx = !state;
    G.saveState.save();

    OMT.platformTracking.logEvent(OMT.platformTracking.Events.SoundEffects, 1, {
      status: state ? 1 : 0,
    });
  }
}
