export const ACTION_TYPES = {
  BOOSTER_SWAP: 'boosterSwap',
  BOOSTER_MATCH: 'boosterMatch',
  MOVE: 'move',
  PROCESS_FALL: 'processFall',
  PROCESS_MATCH: 'processMatch',
  SHUFFLE: 'shuffle',
  START_BOOSTERS: 'startBoosterInit',
  OOM_BOOSTERS: 'oomBoosterInit',
  MOVE_REJECT: 'moveReject',
};

/**
 * Base class for board actions
 */
export class Action {
  /**
   * constructor
   * @param {Board} board
   * @param {ActionManager} actionManager
   * @param {Array} args arguement array
   */
  constructor(board, actionManager, args) {
    this._board = board;
    this._actionManager = actionManager;
    this._args = args;
    this._init(...args);
  }

  /**
   * initialize the action. The arguement array passed in the constructor will be applied to this function.
   */
  _init() {
    // override to implement
  }

  /**
   * update method
   */
  update() {
    // override to implement
  }

  /**
   * action finished. remove the action.
   */
  finish() {
    this._actionManager.removeAction(this);
  }

  /**
   * destruction method
   */
  destroy() {
    this._board = null;
    this._actionManager = null;
    this._args = null;
  }
}
