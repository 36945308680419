/* eslint-disable func-names */
const { ACTION_TYPES } = require('../../../Board/Actions/Action');

G.Booster = function (board, cellX, cellY, nr) {
  this.board = board;
  this.am = this.board.actionManager;
  this.cellX = cellX;
  this.cellY = cellY;

  Phaser.Image.call(
    this,
    game,
    this.board.cellXToPxIn(cellX),
    this.board.cellYToPxIn(cellY - 2),
  );

  this.anchor.setTo(0.5);

  this.boosterNr = nr;

  this.orgY = this.y;
  this.targetY = this.board.cellYToPxIn(cellY);

  G.changeTexture(this, `ui_booster_${nr}`);
  this.alpha = 0;
  this.scale.setTo(2);

  game.add.tween(this.scale).to({ x: 1, y: 1 }, 700, Phaser.Easing.Sinusoidal.Out, true);
  game.add.tween(this).to({ alpha: 1 }, 700, Phaser.Easing.Sinusoidal.Out, true).onComplete.add(function () {
    game.add.tween(this).to({ y: this.targetY }, 300, Phaser.Easing.Cubic.In, true).onComplete.add(function () {
      this.board.useBoosterOnCell(cellX, cellY);
      this.am.newAction(ACTION_TYPES.PROCESS_MATCH);
      this.am.removeAction();
      game.add.tween(this).to({ y: this.orgY, alpha: 0 }, 600, Phaser.Easing.Cubic.Out, true);
      game.time.events.add(600, this.destroy, this);
    }, this);
  }, this);
};

G.Booster.prototype = Object.create(Phaser.Image.prototype);
