import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

class Window_loyaltyMessage extends Window {
  /**
   * The pop up window that appears when the user enters the map, and they're a loyalty member!
   * @param {any} parent
   */
  constructor(parent) {
    super(parent);

    this._drawEverything();
  }

  /**
   * Draws the window
   */
  _drawEverything() {
    const safeWidth = 600;
    const backgroundContainer = new Phaser.Group(game, this); // Bg container
    const container = new Phaser.Group(game, this); // Contents

    // Title
    const titleContainer = new Phaser.Group(game, container);
    const titleImage = G.makeImage(0, 0, G.OMTsettings.elements.windowNineSlice.banner || 'daily_rewards_ribbon', 0.5, titleContainer);
    const title = new G.Text(0, 0, OMT.language.getText('Loyalty Club'),
      { style: G.OMTsettings.global.window.title.textStyle, fontSize: 62 }, 0.5, titleImage.width * 0.9, titleImage.height * 0.9, true, 'center');
    titleContainer.addChild(title);

    // Text and email
    const textContainer = new Phaser.Group(game, container);
    const stuffing = new G.Text(0, 0, `${OMT.language.getText('Thank you for your loyalty!')}`, 'font-blue', 0.5, safeWidth * 0.8, 400, true, 'center');
    const email = new G.Text(0, 0, 'loyalty.m3@softgames.com', 'msg-orangeFontStyle', 0.5, safeWidth * 0.8, 300, true, 'center');
    email.y = 20 + stuffing.y + (stuffing.height + email.height) / 2;
    textContainer.addChild(stuffing);
    textContainer.addChild(email);
    textContainer.y = titleContainer.y + (titleContainer.height + textContainer.height) / 2;

    // Button
    const button = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 166,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Thanks!'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: () => {
            G.saveState.loyaltyManager.firstTimePopup = true;
            this.closeWindow();
          },
        },
      },
    });
    button.y = textContainer.y + button.height + (textContainer.height / 2);
    container.addChild(button);

    // Bg is drawn using container's height
    const bg = new PhaserNineSlice.NineSlice(0, 0, 'popups', 'popup_generic', safeWidth,
      (container.height / 1.65) + (G.OMTsettings.elements.windowNineSlice.slice.bottom / 2) + titleContainer.height, G.OMTsettings.elements.windowNineSlice.slice);
    bg.anchor.set(0.5);
    backgroundContainer.addChild(bg);

    // Container is offset to be centered
    container.y = -(bg.height / 2);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.loyaltyMessage = Window_loyaltyMessage;
