import { WALL_DIRECTIONS } from './BoardConstants';

export class BoardWalls extends Phaser.Group {
  /**
   * A class that holds all the board walls in the board.
   * Walls are an extra thing on a layer that prevents gems from being swapped through.
   * Matches and explosions are ok though
   * For more information, please refer to OMT-4314
   *
   * @param {Board} board
   * @param {Object} lvlData
   */
  constructor(board, lvlData) {
    super(game);

    this.position = board.position;
    this.scale = board.scale;

    this._board = board;
    this._lvlData = lvlData;

    this._allWalls = [];

    if (this._lvlData.walls) {
      this._init(this._lvlData.walls);
    }
  }

  /**
   * Gets the number of active walls
   * @returns {number}
   */
  get wallCount() {
    return this._allWalls.length;
  }

  /**
   * Initialize
   * @param {Array<dir:number, x:number, y:number} givenWalls
   */
  _init(givenWalls) {
    const walls = G.Utils.defined(givenWalls, []); // I think this is to check if its defined?
    for (const wall of walls) { // Immediately add walls
      this._addWall(wall.x, wall.y, wall.dir);
    }
    // Add global listeners
    this._signalBindings = [
    ];
  }

  /**
   * hide the wall cells
   */
  _hide() {
    if (this.alpha !== 1) return;
    game.add.tween(this).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true);
  }

  /**
   * Adds a wall in the specific direction
   * @param {number} cellX
   * @param {number} cellY
   * @param {WALL_DIRECTIONS} dir
   */
  _addWall(cellX, cellY, dir) {
    let existingCellWall = this.getWallAtCell(cellX, cellY); // Get data on this cell
    if (!existingCellWall) { // If there isn't any
      existingCellWall = { // Create it
        container: new Phaser.Group(game, this),
        dir: [],
        x: cellX,
        y: cellY,
      };
      existingCellWall.container.x = cellX * this._board.tileSize;
      existingCellWall.container.y = cellY * this._board.tileSize;
      this._allWalls.push(existingCellWall); // Add it in
    }

    const existingDir = this._doesCellHaveWallAtDir(existingCellWall.dir, dir); // Check if the cell has a wall in that direction
    if (!existingDir) { // Wall direction does not exist on this cell
      // Create image
      const image = G.makeImage(0, 0, 'wallblocker', 0, existingCellWall.container);

      image.angle = 90 + (90 * dir); // Flip the wall in the given direction
      switch (dir) { // Place wall in proper direction
        case WALL_DIRECTIONS.NORTH: image.x = this._board.tileSize; break;
        case WALL_DIRECTIONS.EAST: image.y = this._board.tileSize; image.x = this._board.tileSize; break;
        case WALL_DIRECTIONS.SOUTH: image.y += this._board.tileSize; break;
        default: break;
      }
      existingCellWall.dir.push({ dir, img: image }); // Keep the wall for reference
    }
  }

  /**
   * Checks if the cell has a wall in a certain direction.
   * The paramters take numbers for cell
   * @param {number} cellX
   * @param {number} cellY
   * @param {WALL_DIRECTIONS} dir
   * @returns {boolean}
   */
  doesCellPosHaveWallAtDir(cellX, cellY, dir) {
    const wall = this.getWallAtCell(cellX, cellY);
    if (wall) {
      return this._doesCellHaveWallAtDir(wall.dir, dir);
    }
    return false;
  }

  /**
   * Checks if the cell has a wall in a direction.
   * The parameter already passes in the cell's data
   * @param {Array<dir:number, img:Phaser.DisplayObject>} directionObj
   * @param {WALL_DIRECTIONS} direction
   */
  _doesCellHaveWallAtDir(directionObj, direction) {
    return directionObj.find((cellDirection) => cellDirection.dir === direction);
  }

  /**
   * Checks if a move can happen between two cells.
   * The originCell is the gem trying to move to the direction of the movingToCell
   * @param {Array<number>} originCell
   * @param {Array<number>} movingToCell
   * @returns {boolean}
   */
  canMoveHappen(originCell, movingToCell) {
    const wallA = this.getWallAtCell(originCell[0], originCell[1]); // Gets the wall at originCell
    const wallB = this.getWallAtCell(movingToCell[0], movingToCell[1]); // Gets the wall at the movingToCell
    if (!(wallA || wallB)) { return true; } // Theres no walls between the 2 cells

    // Determine direction in relation to originCell
    let cellDirection = -1; // The wall direction from originCell to movingToCell
    let oppositeDir = -1; // The wall direction from movingToCell to originCell
    if (originCell[1] === movingToCell[1]) { // The cells are on the same Y row
      if (originCell[0] < movingToCell[0]) { // OriginCell is left of MovingToCell
        cellDirection = WALL_DIRECTIONS.EAST;
        oppositeDir = WALL_DIRECTIONS.WEST;
      } else { // MovingToCell is left of OriginCell
        cellDirection = WALL_DIRECTIONS.WEST;
        oppositeDir = WALL_DIRECTIONS.EAST;
      }
    } else { // Cells are on the same X column
      // eslint-disable-next-line no-lonely-if
      if (originCell[1] < movingToCell[1]) { // originCell is above the MovingToCell
        cellDirection = WALL_DIRECTIONS.SOUTH;
        oppositeDir = WALL_DIRECTIONS.NORTH;
      } else { // MovingToCell is above the OriginCell
        cellDirection = WALL_DIRECTIONS.NORTH;
        oppositeDir = WALL_DIRECTIONS.SOUTH;
      }
    }
    if (cellDirection === -1) { return true; } // Not sure what happened

    // If theres a wall on originCell that prevents it from going in the cellDirection wall
    if (wallA && this._doesCellHaveWallAtDir(wallA.dir, cellDirection)) {
      return false;
    }

    // If theres a wall on MovingToCell that prevents originCell from coming in in the oppositeDir way
    if (wallB && this._doesCellHaveWallAtDir(wallB.dir, oppositeDir)) {
      return false;
    }

    // Must be ok
    return true;
  }

  /**
   * Checks if a cell can move diagonally across.
   * The originCell is the gem trying to move to the direction of the movingToCell
   * @param {Array<number>} originCell
   * @param {Array<number>} movingToCell
   * @returns {boolean}
   */
  canCandyCrossDiagonally(originCell, movingToCell) {
    const direction = originCell[0] < movingToCell[0] ? 1 : -1;
    let nextX = originCell[0]; let nextY = originCell[1];
    let lastX = nextX; let lastY = nextY;

    do {
      if (!this._board.isCellInBoardArea(nextX, nextY)) return true;
      nextX += direction; nextY += 1;

      const nextCellTopBlocked = this.isWallOnTopOfCell(nextX, nextY);
      if (nextCellTopBlocked) {
        if (this.isWallOnTopOfCell(lastX, nextY)) return false;
        if (direction > 0 && this.isWallOnLeftOfCell(nextX, nextY)) return false;
        if (direction < 0 && this.isWallOnRightOfCell(nextX, nextY)) return false;
      }
      lastX = nextX; lastY = nextY;
    } while (!(lastX === movingToCell[0] && lastY === movingToCell[1]));
    return true;
  }

  /**
   * check if the top of a cell is blocked
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isWallOnTopOfCell(cellX, cellY) {
    return this.doesCellPosHaveWallAtDir(cellX, cellY - 1, WALL_DIRECTIONS.SOUTH) || this.doesCellPosHaveWallAtDir(cellX, cellY, WALL_DIRECTIONS.NORTH);
  }

  /**
   * check if the left of a cell is blocked
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isWallOnLeftOfCell(cellX, cellY) {
    return this.doesCellPosHaveWallAtDir(cellX + 1, cellY, WALL_DIRECTIONS.EAST) || this.doesCellPosHaveWallAtDir(cellX, cellY, WALL_DIRECTIONS.WEST);
  }

  /**
   * check if the right of a cell is blocked
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isWallOnRightOfCell(cellX, cellY) {
    return this.doesCellPosHaveWallAtDir(cellX + 1, cellY, WALL_DIRECTIONS.WEST) || this.doesCellPosHaveWallAtDir(cellX, cellY, WALL_DIRECTIONS.EAST);
  }

  /**
   * The code to break a wall, whenever that may happen
   * @param {number} cellX
   * @param {number} cellY
   * @param {WALL_DIRECTIONS} dir
   */
  breakWallAtCell(cellX, cellY, dir) {
    const wall = this.getWallAtCell(cellX, cellY); // Grabs wall
    if (wall) {
      const toRemove = [];
      for (const wallDir of wall.dir) { // Finds wall at that direction
        if (wallDir.dir === dir) {
          this._wallBreakAnim(wallDir);
          toRemove.push(wallDir);
        }
      }
      _.pull(wall.dir, toRemove); // Removes it out after
    }
  }

  /**
   * The animation for when a wall breaks.
   * After the animation is done, the wall will be destroyed
   * @param {img:Phaser.DisplayObject, dir:WALL_DIRECTIONS} wall
   */
  _wallBreakAnim(wall) {
    const tw = game.add.tween(wall.img).to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
    tw.onComplete.add(() => {
      this._destroyWall(wall);
    });
  }

  /**
   * Destroys the wall image
   * @param {img:Phaser.DisplayObject, dir:WALL_DIRECTIONS} wall
   */
  _destroyWall(wall) {
    if (wall.img.parent) {
      wall.img.parent.removeChild(wall.img);
    }
    wall.img.destroy();
  }

  /**
   * Gets the wall at the given cells
   * @param {number} cellX
   * @param {number} cellY
   * @returns {{container:Phaser.Group, dir:Array<{img:Phaser.DisplayObject, dir:WALL_DIRECTIONS}>, x:number, y:number}}
   */
  getWallAtCell(cellX, cellY) {
    return this._allWalls.find((wall) => wall.x === cellX && wall.y === cellY);
  }

  /**
   * Toggles the data on the currently cell to be on or off depending on the given wall direction.
   * Only used by the level editor
   * @param {number} cellX
   * @param {number} cellY
   * @param {WALL_DIRECTIONS} dir
   */
  editorToggleWallAt(cellX, cellY, dir) {
    const wall = this.getWallAtCell(cellX, cellY);
    if (wall) {
      const wallDir = this._doesCellHaveWallAtDir(wall.dir, dir);
      if (wallDir) {
        this._editorRemoveWall(wall, wallDir);
      } else {
        this._addWall(cellX, cellY, dir);
      }
    } else {
      this._addWall(cellX, cellY, dir);
    }
  }

  /**
   * Removes a wall of the given direction on a certain cell
   * Used by the level editor
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} dir
   */
  _editorRemoveWall(wall, wallDir) {
    wall.dir.splice(wall.dir.indexOf(wallDir), 1);
    this._destroyWall(wallDir);
  }

  /**
   * Exports data of all walls.
   * Used by the level editor
   * @returns {Array<x:number, y:number, dir:WALL_DIRECTIONS>}
   */
  editorExport() {
    if (this._allWalls.length === 0) return null;
    const exportArr = [];
    for (const wall of this._allWalls) {
      for (const wallDir of wall.dir) {
        exportArr.push({
          x: wall.x,
          y: wall.y,
          dir: wallDir.dir,
        });
      }
    }
    return exportArr;
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._signalBindings) {
      for (const signalBinding of this._signalBindings) signalBinding.detach();
      this._signalBindings.length = 0;
    }
    for (const cell in this._allWalls) {
      if (cell && cell.container) {
        cell.container.destroy();
      }
    }
    this._allWalls = null;
    this._board = null;
    this._lvlData = null;
    super.destroy();
  }
}
