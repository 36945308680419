import VillainsDataManager, { VillainsDataManagerKey } from '../OMT/dataTracking/villains/VillainsDataManager';
import { MysteryGiftManager } from '../OMT/dataTracking/mysteryGift/MysteryGiftManager';
import TokenEventManager from '../OMT/dataTracking/tokeEventManager/TokenEventManager';
import LoyaltyManager from '../OMT/dataTracking/loyaltyManager/LoyaltyManager';
import { FriendshipChest_DataManager } from '../../Elements/Windows/FriendshipChest/FriendshipChest_DataManager';
import { OMT_MilestoneTracking } from '../OMT/milestoneTracking/OMT_MilestoneTracking';
import HomescreenShortcutManager from '../OMT/dataTracking/homescreenShortcut/HomeScreenShortcutManager';
import TreasureHuntManager from '../OMT/dataTracking/treasureHuntManager/TreasureHuntManager'; // eslint-disable-line import/no-cycle
import BadgeManager from '../OMT/dataTracking/badgeManager/BadgeManager';
import TargetedOfferDataManager from '../OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import AnnuityManager from '../OMT/dataTracking/annuityManager/AnnuityManager';
// import { COMPARE_METHODS } from './SaveStateKeyCompareMethods';

/**
 * data keys bound to KEY_MAPPINGS
 */
export const DATA_KEYS = {
  LIVES: 'lives',
  BOOSTERS: 'boosters',
  COINS: 'coins',
  MYSTERY_GIFT: 'mysteryGift-manager',
  SPIN_RESULTS: 'savedSpinResults',
  REAL_MONEY_WHEEL: 'realMoneyWheel',
  FORTUNE_COOKIE: 'fortuneCookieData',
  TOKEN_EVENT: 'tokenEventManagerDataKey',
  LOYALTY_MANAGER: 'loyaltyManager',
  MILESTONE_TRACKING: 'milestoneTracking',
  HOMESCREEN_SHORTCUT: 'homescreenShortcut',
  TREASURE_HUNT: 'treasureHunt',
  BADGES: 'badges',
  NO_ADS_POPUP: 'noAdsPopup',
  TARGETED_OFFERS: 'targetedOffers',
  ANNUITY: 'annuity',
};

/**
 * KEY_MAPPINGS Object definition:
 *  required parameters
 *  - dataKey:string : key to be user in the userData, should be defined in DATA_KEYS
 *  optional parameters
 *  - defaultValue:* : default value to be applied if not set, otherwise it will be null.
 *  - legacyParam:string : legacy parameter from G.saveState.data[legacyParam] to migrate. legacyParam will be deleted upon migration.
 *  - legacyConvertFunc:Function : conversion function to run, if required, on the legacyParam. Should return transformed data.
 *  - delete:boolean : if true and legacyParam is set the legacyParam will be delted from G.saveState.data.
 *  - restoreFunc:Function : used to compare data states in OMT_UserData when checking fallback data. If not set server data will always be preferred.
 */

export const KEY_MAPPINGS = [
  {
    dataKey: DATA_KEYS.LIVES,
    defaultValue: G.json.settings.livesOnStart,
    legacyParam: 'lives',
    // restoreFunc: COMPARE_METHODS.GREATER_NUMERIC_VALUE, // now using default restore flags
  },
  {
    dataKey: DATA_KEYS.BOOSTERS,
    defaultValue: new Array(10).fill(G.json.settings.boostersOnStart),
    legacyParam: 'boosters',
    // restoreFunc: COMPARE_METHODS.GREATER_ARRAY_SUM, // now using default restore flags
  },
  {
    dataKey: DATA_KEYS.COINS,
    defaultValue: G.json.settings.coinsOnStart,
    legacyParam: 'coins',
    // restoreFunc: COMPARE_METHODS.GREATER_NUMERIC_VALUE,  // now using default restore flags
  },
  {
    dataKey: VillainsDataManagerKey,
    defaultValue: VillainsDataManager.getDefaultValues(),
  },
  {
    dataKey: 'friendshipChestData',
    defaultValue: FriendshipChest_DataManager.getBrandNewData(),
    legacyParam: 'friendshipChestData',
    delete: true,
  },
  {
    dataKey: DATA_KEYS.FORTUNE_COOKIE,
    defaultValue: {
      currentCategory: null,
      currentMsg: null,
      messagesSeen: [],
      timesCollected: 0,
      timesDismissed: 0,
      timesFullDismiss: 0,
      timesOpened: 0,
      timesShared: 0,
      timesSpawned: 0,
    },
    legacyParam: 'fortuneCookieData',
    delete: true,
  },
  {
    dataKey: DATA_KEYS.MYSTERY_GIFT,
    defaultValue: MysteryGiftManager.getDefaultData(),
    legacyParam: 'mysteryGift',
    delete: true,
    legacyConvertFunc: MysteryGiftManager.migrateData,
  },
  {
    dataKey: DATA_KEYS.SPIN_RESULTS,
    defaultValue: {},
    legacyParam: 'savedSpinResults',
    delete: true,
  },
  {
    dataKey: DATA_KEYS.REAL_MONEY_WHEEL,
    defaultValue: {
      unclaimedBoughtSpins: {
        conversion: [],
        highValue: [],
        platinum: [],
      },
      sessionsNotSeen: 0, // sessions not seen replacement wheel
    },
  },
  {
    dataKey: DATA_KEYS.TOKEN_EVENT,
    defaultValue: TokenEventManager.getDefaultValues(),
  },
  {
    dataKey: DATA_KEYS.LOYALTY_MANAGER,
    defaultValue: LoyaltyManager.getDefaultValues(),
  },
  {
    dataKey: DATA_KEYS.MILESTONE_TRACKING,
    defaultValue: OMT_MilestoneTracking.getDefaultValues(),
  },
  {
    dataKey: DATA_KEYS.HOMESCREEN_SHORTCUT,
    defaultValue: HomescreenShortcutManager.getDefaultValues(),
  },
  {
    dataKey: DATA_KEYS.TREASURE_HUNT,
    defaultValue: TreasureHuntManager.getDefaultValues(),
  },
  {
    dataKey: DATA_KEYS.BADGES,
    defaultValue: BadgeManager.getDefaultValues(),
  },
  {
    dataKey: DATA_KEYS.NO_ADS_POPUP,
    defaultValue: {
      sessionsNotSeen: 98, // now represents the number of interstitial ads instead of sessions
    },
  },
  {
    dataKey: DATA_KEYS.TARGETED_OFFERS,
    defaultValue: TargetedOfferDataManager.getDefaultValues(),
  },
  {
    dataKey: DATA_KEYS.ANNUITY,
    defaultValue: AnnuityManager.getDefaultValues(),
  },
];

/**
 * get a list of all keys from SaveStateKeyMappings KEY_MAPPINGS.
 * @returns {Array.<string>}
 */
export function getSaveStateDataKeys() {
  return KEY_MAPPINGS.map((mapping) => mapping.dataKey);
}

/**
 * get mapping as defined in KEY_MAPPINGS by its dataKey.
 * @param {string} dataKey
 * @returns {Object}
 */
export function getSaveStateKeyMappingByDataKey(dataKey) {
  for (const mapping of KEY_MAPPINGS) if (mapping.dataKey === dataKey) return mapping;
  return null;
}

/**
 * get restore comparison function for a dataKey if defined.
 * @param {string} dataKey
 * @returns {Function}
 */
export function getSaveStateKeyRestoreFunc(dataKey) {
  const mapping = getSaveStateKeyMappingByDataKey(dataKey);
  if (mapping && mapping.restoreFunc) return mapping.restoreFunc;
  return null;
}
