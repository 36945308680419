import { RMWHEEL_MODES } from '../rmWheelEnums';
import { LIGHTS_MODES } from './LightsConfig';

// Class for adding and managing the game's mascot
// to host the real money wheel
export default class MascotWheelHost extends Phaser.Group {
  /**
   * Constructor
   * @param {number} x
   * @param {number} y
   * @param {RMWHEEL_MODES} wheelMode
   * @param {boolean} speakAtStart
   * @param {LightsBorder} lightsBorder reference to lights border
   */
  constructor(x, y, wheelMode, speakAtStart, lightsBorder) {
    super(game, null);
    this.x = x;
    this.y = y;
    this._lightsBorder = lightsBorder;
    this._wheelMode = wheelMode;

    this._init(speakAtStart);
  }

  /**
   * Init
   * @param {boolean} speakAtStart
   */
  _init(speakAtStart) {
    this._makeGingy();
    this._makeSpeechBubble(speakAtStart);
  }

  /**
   * Creates mascot graphics
   */
  _makeGingy() {
    const gingyConfig = G.OMTsettings.elements.RealMoneyWheel.gingy;
    this._gingy = G.makeImage(gingyConfig.x, gingyConfig.y + 480, 'realMoney_fancy_gingy', 0.5, this);
    this._gingy.alpha = 0;

    const bobbingAnim = gingyConfig.bobbingAnim ? this._makeBobbingAnim() : () => {};

    game.add.tween(this._gingy).to({ y: gingyConfig.y, alpha: 1 }, 500, Phaser.Easing.Cubic.Out, true, 2300)
      .onComplete.add(bobbingAnim);
  }

  /**
   * Creates speech bubble and overlay
   * @param {boolean} speakAtStart
   */
  _makeSpeechBubble(speakAtStart) {
    // Speech bubble and text
    this._speechGroup = new Phaser.Group(game, this);
    G.makeImage(0, 0, 'realMoney_speech_bubble', 0.5, this._speechGroup);

    const speechStr = OMT.language.getText('Wow! You just won a chance to spin the %WHEELNAME%. Lucky you!')
      .replace('%WHEELNAME%', OMT.language.getText('Golden Wheel'));
    this._speechText = new G.Text(0, -18, speechStr, {
      style: 'realMoneyWheel-warning',
      fontSize: '36px',
    }, 0.5, 375, 135, true, 'center');

    const speechSuperStr = OMT.language.getText('Wow! You just unlocked the %WHEELNAME%!')
      .replace('%WHEELNAME%', OMT.language.getText('Super Golden Wheel'));
    this._speechSuperText = new G.Text(0, -18, speechSuperStr, {
      style: 'realMoneyWheel-warning',
      fontSize: '36px',
    }, 0.5, 375, 135, true, 'center');

    const speechPlatStr = OMT.language.getText('Wow! You just won a chance to spin the %WHEELNAME%. Lucky you!')
      .replace('%WHEELNAME%', OMT.language.getText('Platinum Wheel'));
    this._speechPlatText = new G.Text(0, -18, speechPlatStr, 'mascotWheelHost-speechPlatinum', 0.5, 375, 135, true, 'center');

    this._speechSuperText.visible = false;
    this._speechGroup.addChild(this._speechText);
    this._speechGroup.addChild(this._speechSuperText);
    this._speechGroup.addChild(this._speechPlatText);
    this._speechGroup.y = -50;
    this._speechGroup.alpha = 0;

    if (speakAtStart) {
      const delay = this._wheelMode === RMWHEEL_MODES.Platinum ? 5500 : 2500;
      game.time.events.add(delay, () => { this.toggleSpeech(true, this._wheelMode); });
    }

    game.time.events.add(3000, () => this._lightsBorder.changeLightsMode(LIGHTS_MODES.Off));

    // Speech bubble overlay
    this._speechOverlay = new G.Button(0, 0, null, () => {
      this.toggleSpeech(false, this._wheelMode);
    });
    this._speechOverlay.createClickShape(game.width, game.height, [0.5, 0.5], 0);
    this._speechOverlay.active = false;
    this.add(this._speechOverlay);
  }

  /**
   * Creates bobbing animation from tweens
   * @returns {Function} function that starts the animation
   */
  _makeBobbingAnim() {
    const gingyConfig = G.OMTsettings.elements.RealMoneyWheel.gingy;
    const animConfig = gingyConfig.bobbingAnimConfig;

    // Horizontal Movement
    const xMoves = [];
    xMoves.push(game.add.tween(this._gingy)
      .to({ x: gingyConfig.x - animConfig.hDisplacement }, animConfig.cycleLength / 2, Phaser.Easing.Sinusoidal.Out, false));

    xMoves.push(game.add.tween(this._gingy)
      .to({ x: gingyConfig.x + animConfig.hDisplacement }, animConfig.cycleLength, Phaser.Easing.Sinusoidal.InOut, false));

    xMoves.push(game.add.tween(this._gingy)
      .to({ x: gingyConfig.x - animConfig.hDisplacement }, animConfig.cycleLength, Phaser.Easing.Sinusoidal.InOut, false));

    xMoves[0].chain(xMoves[1], xMoves[2]);
    xMoves[2].chain(xMoves[1]);

    const yMoves = [];
    yMoves.push(game.add.tween(this._gingy)
      .to({ y: gingyConfig.y - animConfig.vDisplacement }, animConfig.cycleLength / 4, Phaser.Easing.Sinusoidal.Out, false));

    yMoves.push(game.add.tween(this._gingy)
      .to({ y: gingyConfig.y + animConfig.vDisplacement }, animConfig.cycleLength / 2, Phaser.Easing.Sinusoidal.InOut, false));

    yMoves.push(game.add.tween(this._gingy)
      .to({ y: gingyConfig.y - animConfig.vDisplacement }, animConfig.cycleLength / 2, Phaser.Easing.Sinusoidal.InOut, false));

    yMoves[0].chain(yMoves[1], yMoves[2]);
    yMoves[2].chain(yMoves[1]);

    return () => {
      xMoves[0].start();
      yMoves[0].start();
    };
  }

  /**
   * Toggles mascot's speech bubble
   * @param {boolean} visible
   * @param {RMWHEEL_MODES} mode
   */
  toggleSpeech(visible, mode) {
    if (!visible) {
      this._speechOverlay.active = false;
    } else {
      this._speechText.visible = false;
      this._speechSuperText.visible = false;
      this._speechPlatText.visible = false;

      switch (mode) {
        case RMWHEEL_MODES.Conversion:
          this._speechText.visible = true;
          break;

        case RMWHEEL_MODES.HighValue:
          this._speechSuperText.visible = true;
          break;

        case RMWHEEL_MODES.Platinum:
          this._speechPlatText.visible = true;
          break;
        default:
      }
    }

    game.add.tween(this._speechGroup).to({ alpha: visible ? 1 : 0 }, 500, Phaser.Easing.Cubic.Out, true)
      .onComplete.add(() => {
        this._speechOverlay.active = visible;
      });
  }
}
