import WorldMap2_Util, { levelNodeType, WorldMap2_globalSignals } from '../WorldMap2_Util';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import MapPlayerAvatar from '../Avatars/MapPlayerAvatar';
import ArrayUtil from '@omt-components/Utils/ArrayUtil';
import RuntimeSpritesheetManager, { RUNTIME_SPRITESHEET_IDS } from '@omt-components/Imaging/Spritesheets/RuntimeSpritesheetManager';
import OMT_VILLAINS from '../../../OMT_UI/OMT_Villains';

const levelNodeAsset = {
  SUPER_HARD: 'map-nodes/map_point_super_hard',
  HARD: 'map-nodes/map_point_hard',
  CURRENT: 'map-nodes/map_point_2',
  NORMAL: 'map-nodes/map_point',
};

const basicNodeType = {
  GATE: 'gate',
  LEVEL: 'level',
};

/* eslint-disable no-use-before-define */
/**
 * We want to dynamically uncompress JSONs and load them.
 * But how can we do that?
 */
export default class WorldMapLevelLayer extends G.PoolGroup {
  /**
   * The pool group for the level nodes.
   * `dummyNode` is set during prefabEditor. It will initialize nodes but they will not function normally
   * @param {{ maxLevel:number, dummyNode:boolean, aboveLayer:Phaser.DisplayObject, noGates:boolean, normalToLevel?:number }} configOverride
   */
  constructor(configOverride) {
    super({
      level: LevelNode,
      gate: GateNode,
    });

    this._configOverride = configOverride;
    this._actives = [];
    // Calculates how many level nodes there are on a tile
    this._normalLevelsUpTo = this._configOverride.normalToLevel || 0;
    this.totalLevelsPerFullTile = G.OMTsettings.elements.worldMap.levelNodePositions.reduce((total, section) => total += section.length, 0); // eslint-disable-line no-return-assign
    this.averageLevelsPerTile = this.totalLevelsPerFullTile / G.OMTsettings.elements.worldMap.levelNodePositions.length;
    this._levelLimit = -1; // Level limit will be initialized later externally
    this._gate = undefined;
    this._unlockButton = undefined;
    this._maxLevel = Number.isFinite(this._configOverride.maxLevel) ? this._configOverride.maxLevel : G.Helpers.levelDataMgr.getNumberOfLevels() + 1;
    this._oneTimeAssets = {};
    this._signalToken = G.sb(WorldMap2_globalSignals.recheckGate).add(this.recheckGate.bind(this));
    this._playerAvatarAlreadyAnimated = false;
    this._aboveNodeLayer = configOverride.aboveLayer || this;
    this._noGates = configOverride.noGates || false;

    this.signals = {
      onNodeClicked: new Phaser.Signal(),
      onGateClicked: new Phaser.Signal(),
      onComingSoonClicked: new Phaser.Signal(),
    };
  }

  /**
   * Returns all active level nodes
   * @returns {Array<BasicNode>}
   */
  get actives() {
    return this._actives;
  }

  /**
   * Destroys things
   * Then destroys /itself/
   */
  destroy() {
    if (this._signalToken.detach) {
      this._signalToken.detach();
    }
    this._signalToken = null;

    for (const key in this._oneTimeAssets) {
      if (this._oneTimeAssets[key].destroy) {
        this._oneTimeAssets[key].destroy();
      }
    }
    this._oneTimeAssets = {};
    super.destroy();
  }

  /**
   * Moves tiles based on deltaY
   * @param {number} deltaY
   */
  moveTiles(deltaY) {
    // update active segments
    for (let i = this._actives.length - 1; i >= 0; i--) {
      this._actives[i].y += deltaY;
    }

    if (this._oneTimeAssets.playerAvatar) {
      this._oneTimeAssets.playerAvatar.y += deltaY;
    }
  }

  /**
   * Returns the level limit for calculations
   * @returns {number}
   */
  get levelLimit() {
    return Math.min(this._levelLimit, this._maxLevel);
  }

  /**
   * Returns the max level that the pool group is keeping track of
   * @returns {number}
   */
  get maxLevel() {
    return this._maxLevel;
  }

  /**
   * Sets the level limit
   * @param {number} ll levelLimit
   */
  set levelLimit(ll) {
    if (this._noGates && !this._configOverride.dummyNode) {
      this._levelLimit = G.Helpers.levelDataMgr.getNumberOfLevels() + 1;
      return;
    }
    this._levelLimit = ll;
  }

  /**
   * Returns the level node that is this level
   * @param {number} level
   */
  getNodeAtLevel(level) {
    return this._actives.find((node) => node.levelIndex === level);
  }

  /**
   * Populates an array with level nodes until it reaches its limit
   * @param {number} index
   * @param {number} tilePosition
   * @param {boolean} isRiver
   * @returns {Array<LevelNode>}
   */
  addTile(index, tilePosition, isRiver) {
    if (index > 0) { // If the tile index is greater than 0, return empty array
      return [];
    }
    // Find the positioning of this tile
    let targetTilePosition = WorldMap2_Util.getEntryInArrayLoop(G.OMTsettings.elements.worldMap.levelNodePositions, index);
    if (isRiver) {
      targetTilePosition = G.OMTsettings.elements.worldMap.riverTileExtra.offset;
    }
    const absIndex = Math.abs(index);
    const flatIndex = absIndex % G.OMTsettings.elements.worldMap.levelNodePositions.length;
    const floorIndex = Math.floor(absIndex / G.OMTsettings.elements.worldMap.levelNodePositions.length);
    let lastLevel = 0;
    if (floorIndex > 0) { // Calculate bulk levels if the index is really far away
      lastLevel += (this.totalLevelsPerFullTile * (floorIndex));
    }
    lastLevel += WorldMap2_Util.getLevelCountAtTile(-flatIndex); // Negative because tiles are going negative
    lastLevel -= 1; // Because level indexes count from 0

    // The initializing part
    const rtrArr = [];
    for (let i = targetTilePosition.length - 1; i > -1; i--) {
      let basicNode;

      const assumedLevel = lastLevel - i; // Assumed level
      if (assumedLevel + 1 < this.levelLimit) { // If its within level limit
        basicNode = this.getFreeElement(basicNodeType.LEVEL);
        basicNode.init({
          tileIndex: index,
          levelIndex: assumedLevel,
          dummyNode: this._configOverride.dummyNode,
          overrideUnlock: this._normalLevelsUpTo,
        });
        // Can it be clicked?
        if (this._configOverride.dummyNode) {
          basicNode.onClick.add(() => {
            this.signals.onGateClicked.dispatch(basicNode);
          });
        } else {
          if (basicNode.active) { // eslint-disable-line no-lonely-if
            basicNode.onClick.add(() => {
              this.signals.onNodeClicked.dispatch(basicNode);
            });
          }
        }
      } else if (!this._gate) { // If its not its probably a gate, but, do we have a gate?
        const wasOpened = G.saveState.gateManager.hasGateBeenOpened(assumedLevel);
        const overTheMax = assumedLevel + 1 >= this.maxLevel;
        const isActiveGate = assumedLevel <= G.saveState.getLastPassedLevelNr() && !wasOpened && !overTheMax;
        basicNode = this.getFreeElement(basicNodeType.GATE);
        basicNode.init(index, assumedLevel, isActiveGate);
        if (this._configOverride.dummyNode) { // If its not a dummy node (which it shouldn't be in OMT)
          basicNode.onClick.add(() => {
            this.signals.onGateClicked.dispatch(basicNode);
          });
        } else {
          // Can it be clicked?
          if (basicNode.active) { // eslint-disable-line no-lonely-if
            this._addGateAssets(basicNode);
            basicNode.onClick.add(() => {
              this.signals.onGateClicked.dispatch(basicNode);
            });
          } else if (overTheMax) {
            this._addGateComingSoonAssets(basicNode);
          }
        }
        this._gate = basicNode;
      } else { // Note a valid node and gate already exists
        continue;
      }

      // Positioning
      basicNode.x = targetTilePosition[i].x;
      basicNode.y = tilePosition + targetTilePosition[i].y;

      this._actives.push(basicNode); // Push to actives
      this.addChild(basicNode); // Add to group
      rtrArr.push(basicNode); // Add to return array
    }

    return rtrArr;
  }

  /**
   * Kills all level nodes in the tile index
   * @param {number} index
   */
  killTile(index) {
    const allIndex = this._actives.filter((levelNode) => levelNode.tileIndex === index);
    for (const levelNode of allIndex) {
      for (const assetKey in this._oneTimeAssets) { // eslint-disable-line guard-for-in
        const asset = this._oneTimeAssets[assetKey];
        if (asset.parent === levelNode || (asset.levelNode && asset.levelNode === levelNode)) {
          asset.parent.removeChild(asset);
          asset.levelNode = null;
          if (asset.tween) {
            asset.tween.stop();
          }
        }
      }
      if (this._gate === levelNode) {
        this._gate = null;
      }
      levelNode.kill();
      this._actives.splice(this._actives.indexOf(levelNode), 1);
    }
  }

  /**
   * Adds the map player avatar next to the node.
   * In the case that there was a last level, it will temporarily set position to the previous node
   * Then World2 will call for the animation to happen later in the post level flow
   * @param {BasicNode} node
   */
  addPlayerAvatarAssets(node) {
    if (!this._oneTimeAssets.playerAvatar) {
      const playerAvatar = new MapPlayerAvatar();
      this._oneTimeAssets.playerAvatar = playerAvatar;
    }
    if (!node) {
      if (this._oneTimeAssets.playerAvatar.parent) {
        this._oneTimeAssets.playerAvatar.parent.removeChild(this._oneTimeAssets.playerAvatar);
      }
      return;
    }
    // Could the problem of OMT-4057 be that the node is half instantiated at some scroll?
    const nodeLevel = node.levelIndex || -1;
    const nodePos = {
      x: (node.x || 0) + node.playerAvatarOffset.x,
      y: (node.y || 0) + node.playerAvatarOffset.y,
    };
    const { lastLevelData } = game.state.getCurrentState();
    let setStaticPos = false;
    // last level data exists, last level is 1 behind node level, nodelevel > 0, and we haven't animated yet
    if (lastLevelData && (lastLevelData.lvlNr + 1) === nodeLevel && nodeLevel > 0 && !this._playerAvatarAlreadyAnimated) {
      const prevLevel = this.getNodeAtLevel(nodeLevel - 1);
      if (prevLevel) {
        const difference = {
          x: prevLevel.x,
          y: prevLevel.y,
        };
        this._oneTimeAssets.playerAvatar.temporarilySetPosition(difference, nodePos);
      } else {
        setStaticPos = true;
      }
    } else {
      setStaticPos = true;
    }

    if (setStaticPos) {
      this._oneTimeAssets.playerAvatar.setPositionToPos(nodePos);
    }

    this._oneTimeAssets.playerAvatar.levelNode = node;
    this._aboveNodeLayer.addChild(this._oneTimeAssets.playerAvatar);
  }

  /**
   * Immediately tweens the player avatar from the current position to the target position
   * Used for animation purposes and not for game flow
   * @param {{x:number, y:number}} curPos
   * @param {{x:number, y:number}} pos
   * @param {number} [tweenTime]
   * @returns {(Promise|null)}
   */
  immediatelyPanAvatarToPosition(curPos, pos, tweenTime) {
    if (this._oneTimeAssets.playerAvatar) {
      this._oneTimeAssets.playerAvatar.temporarilySetPosition(curPos, pos);
      return this._oneTimeAssets.playerAvatar.startAnimatePositionToPosition(tweenTime);
    }
    return null;
  }

  /**
   * Creates gate assets if needed and adds it on the gate.
   * Gate assets are the unlock button and the lock icon
   * @param {GateNode} gateNode
   */
  _addGateAssets(gateNode) {
    if (!this._oneTimeAssets.unlockButton) {
      const button = this._oneTimeAssets.unlockButton = new OMT_UI_SquareButton(0, 0, {
        button: {
          tint: BUTTONCOLOURS.orange,
          dimensions: {
            width: 138,
            height: 70,
          },
        },
        text: {
          string: OMT.language.getText('Unlock'),
          textStyle: { style: 'font-white', fontSize: 48 },
        },
        options: {
          pulse: 1.1,
        },
      });
      button.inputEnabled = false;
    }
    gateNode.addChild(this._oneTimeAssets.unlockButton);

    if (G.saveState.gateManager.canGateBeOpened(G.saveState.gateManager.getGateObject(gateNode.levelIndex)).result) {
      this._oneTimeAssets.unlockButton.pulse(1.1);
      this._oneTimeAssets.unlockButton.x = 0;
      if (this._oneTimeAssets.lockImage) {
        gateNode.removeChild(this._oneTimeAssets.lockImage);
      }
    } else {
      this._oneTimeAssets.unlockButton.stopPulse();
      this._oneTimeAssets.unlockButton.x = 10;
      if (!this._oneTimeAssets.lockImage) {
        const lockImage = this._oneTimeAssets.lockImage = G.makeImage(0, 0, 'lock', 0.5, gateNode);
        lockImage.y = this._oneTimeAssets.unlockButton.y;
        lockImage.x = this._oneTimeAssets.unlockButton.x - this._oneTimeAssets.unlockButton.width / 2;
      } else {
        gateNode.addChild(this._oneTimeAssets.lockImage);
      }
    }
  }

  /**
   * Creates the coming soon assets if its required. Otherwise just adds it in
   * @param {GateNode} gateNode
   */
  _addGateComingSoonAssets(gateNode) {
    if (!this._oneTimeAssets.comingSoon) {
      const comingSoonGroup = this._oneTimeAssets.comingSoon = new G.Button(0, 0, null, null);
      const bg = G.makeImage(0, 0, 'MysteryGift_header', 0.5, comingSoonGroup);
      bg.scale.set(0.75);
      const msgText = new G.Text(
        0, 0, OMT.language.getText('More levels coming soon...'), {
          style: 'font-blue',
          fontSize: 40,
        }, 0.5, bg.width,
      );
      comingSoonGroup.addChild(msgText);
      comingSoonGroup.y = -gateNode.height * 2.5;
      comingSoonGroup.onClick.add(() => this.signals.onComingSoonClicked.dispatch());
    }
    gateNode.addChild(this._oneTimeAssets.comingSoon);
    this._oneTimeAssets.comingSoon.tween = game.add.tween(this._oneTimeAssets.comingSoon)
      .to({ y: this._oneTimeAssets.comingSoon.y + 10 }, 1500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  }

  /**
   * Plays the animation for when a gate unlocks (with stars) and tweens it out
   * @param {LevelNode} levelNode
   */
  _playGateUnlockAnim(levelNode) {
    for (let i = 0; i < 10; i++) {
      G.sb('fxMap').dispatch('star', {
        x: levelNode.worldPosition.x,
        y: levelNode.worldPosition.y,
      });
    }
  }

  /**
   * Rechecks if the gate can be opened if there is a gate or not
   * Should be called by the G.sb signal
   */
  recheckGate() {
    if (this.gate && this.gate.active) {
      this._addGateAssets(this.gate);
    }
  }

  /**
   * Returns if the player avatar exists currently
   * @returns {boolean}
   */
  isPlayerAvatarActive() {
    return this._oneTimeAssets.playerAvatar && this._oneTimeAssets.playerAvatar.parent;
  }

  /**
   * Animates the player avatar to move during the post level flow
   * @returns {Promise}
   */
  animatePlayerAvatarIncrease() {
    if (this.isPlayerAvatarActive()) {
      this._playerAvatarAlreadyAnimated = true;
      return this._oneTimeAssets.playerAvatar.startAnimatePositionToPosition();
    }
    return null;
  }

  /**
   * Checks all nodes on the existing tiles and returns a number based on where the given level is
   * If the returned number is positive, then the current tiles are above the given level
   * If returned is negative, then the current tiles are below the given level
   * If 0, then the level is active
   * @param {numbers} level
   */
  getLevelDirectionFromCurrentNodes(level) {
    let result = 0;
    for (const active of this._actives) {
      if (active.levelIndex === level) {
        return 0;
      }

      if (active.levelIndex < level) {
        result = -1;
      } else if (active.levelIndex > level) {
        result = 1;
      }
    }
    return result;
  }

  /**
   * Returns the gate node
   * @returns {GateNode}
   */
  get gate() {
    return this._gate;
  }
}

class BaseNode extends G.Button {
  /**
   * This is the base node that the WorldMapLevelLayer uses
   * It gets extended into LevelNode (below this class) and GateNode (below LevelNodeClass)
   */
  constructor() {
    super(0, 0, null, null);

    this.tileIndex = 0;
    this._levelIndex = 0; // LevelIndex is the index of the level node, and not what the level node says on map
    this.basicNodeType = basicNodeType.LEVEL;
    this._playerAvatarOffset = {
      x: 0,
      y: 0,
    };

    this.active = false;
    this._baseNode = undefined;
  }

  /**
   * Returns the level index to modify
   * @returns {number}
   */
  get levelIndex() {
    return this._levelIndex;
  }

  get playerAvatarOffset() {
    return {
      x: this._playerAvatarOffset.x,
      y: this._playerAvatarOffset.y,
    };
  }

  /**
   * When a click happens, it first checks if its valid
   */
  click() {
    if (this._levelIndex < 0) { return; }
    super.click();
  }

  /**
   * Kills the level node
   */
  kill() {
    this._levelIndex = -1;
    this.onClick.removeAll();
    super.kill();
  }
}

class LevelNode extends BaseNode {
  /**
   * The Level Node
   */
  constructor() {
    super();
    this._naturalBaseNodeScale = 1;

    this._baseNode = G.makeImage(0, 0, null, 0.5, this);
    this._baseNode.scale.setTo(this._naturalBaseNodeScale);

    const maxTextWidth = game.cache.getBaseTexture('map-nodes/map_point').height * 1.5;
    const maxTextHeight = 100;
    this._levelText = new G.Text(0, 0, '1', G.OMTsettings.elements.worldMapLvlButton.lvlNrText.style, [0.5, 0.5], maxTextWidth, maxTextHeight, false, true, 'center');

    this._pulseTween = undefined;
    this.addChild(this._levelText);
  }

  /**
   * Init the level node. This is only for LEVELS of the sort
   * @param {{tileIndex:number, levelIndex:number, dummyNode:boolean, overrideUnlock:number}} tileIndex
   * @param {number} levelIndex
   * @param {boolean} dummyNode
   */
  init(config) {
    this.tileIndex = config.tileIndex;
    this._levelIndex = config.levelIndex;
    this.nodeType = levelNodeType.NORMAL;
    const curLevel = config.overrideUnlock || G.saveState.getLastPassedLevelNr();
    this.active = this._levelIndex <= curLevel;
    this.alpha = 1;

    // If its not a dummy node, check the level data and draw it accordingly
    if (!config.dummyNode) {
      this._determineLevelNodeImage(config.overrideUnlock);
    } else { // Just random it if its a dummy
      this._randomLevelNodeImage();
    }

    this._baseNode.y = 0;
    this.revive();
  }

  kill() {
    this._baseNode.removeChildren();
    super.kill();
  }

  attachToNode(obj) {
    this._baseNode.addChild(obj);
  }

  /**
   * Determines what asset to use for the level node
   * Also includes some setup for dynamic level loading, if it ever happens
   * @param {number} overrideUnlock
   */
  async _determineLevelNodeImage(overrideUnlock) {
    /**
     * If there was ever a need to wait for the async

     this._baseNode.changeTexture('map-nodes/map_point');
     this._baseNode.alpha = 0.5;
     this._waitingIcon = new G.WaitingIcon(0, 0, 'waiting_icon_white'); // Loading...
     this._baseNode.addChild(this._waitingIcon);

     // When finished loading
     this._baseNode.removeChild(this._waitingIcon);
     this._waitingIcon.destroy();
     */
    const curLevel = overrideUnlock || G.saveState.getLastPassedLevelNr(); // Find out the current level
    const thisNodeIsMaxLevel = curLevel === this._levelIndex;
    const levelData = await this._getLevelData(this._levelIndex); // Get Level data here

    const {
      isHardLevel,
      isSuperHardLevel,
    } = OMT_VILLAINS.getDifficulty(levelData);

    let _baseNodeTexture;
    if (thisNodeIsMaxLevel) {
      _baseNodeTexture = levelNodeAsset.CURRENT;
    } else if (isSuperHardLevel) {
      _baseNodeTexture = levelNodeAsset.SUPER_HARD;
    } else if (isHardLevel) {
      _baseNodeTexture = levelNodeAsset.HARD;
    } else {
      _baseNodeTexture = WorldMap2_Util.getNormalLevelNode(levelNodeAsset.NORMAL);
    }
    const starCount = G.saveState.getStars(this._levelIndex) || 0;
    // get / generate texture for this node
    const nodeTexture = this.getNodeTexture(_baseNodeTexture, starCount);
    this._baseNode.changeTexture(nodeTexture);
    // adjust node position as stars + map_point are now 1 texture
    const mapPointHeight = game.cache.getBaseTexture('map-nodes/map_point').height * this._naturalBaseNodeScale;
    const nodeOffset = this._baseNode.height - mapPointHeight;
    this._baseNode.y = nodeOffset / 2;

    if (this.active) {
      if (thisNodeIsMaxLevel) { // If its a current level node
        this._togglePulse(true);
      } else { // If its any other level node
        this._togglePulse(false);
      }
      this._baseNode.alpha = 1;
      this._levelText.setText(this._levelIndex + 1);
      this.addChild(this._levelText);
    } else { // If its an out of reach level node
      this._baseNode.alpha = 0.5;
      this._togglePulse(false);
      this.removeChild(this._levelText);
    }
  }

  /**
   * Randomly picks a node type and draws it
   */
  _randomLevelNodeImage() {
    if (!this._starImg) {
      this._starImg = G.makeImage(0, 0, null, 0.5, this);
    }
    this._baseNode.changeTexture(ArrayUtil.getRandomElement(Object.values(levelNodeAsset))); // eslint-disable-line no-nested-ternary
    if (this.active) {
      this._togglePulse(false);
      const starAmount = (Math.round(Math.random() * 1000) % 3) + 1;
      this._starImg.changeTexture(`map-nodes/map_star_${starAmount}`);
      this._starImg.y = this._baseNode.height * 0.4;
      this._levelText.setText(this._levelIndex + 1);
      this.addChild(this._levelText);
    } else { // If its an out of reach level node
      this._baseNode.alpha = 0.5;
      this._starImg.changeTexture(null);
      this._togglePulse(false);
      this.removeChild(this._levelText);
    }
  }

  /**
   * generates a single texture for the node stars / map and adds them to the map spritesheet
   * @param {string} baseNodeTexture
   * @param {string} starCount
   * @returns {PIXI.Texture}
   */
  getNodeTexture(baseNodeTexture, starCount) {
    const dynamicSheetManager = RuntimeSpritesheetManager.getInstance();
    const textureId = `worldMap2-${baseNodeTexture}-${starCount}`;
    // render texture if it is not set
    if (!dynamicSheetManager.getTexture(textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP)) {
      const nodeGroup = new Phaser.Group(game);
      const baseNodeImg = G.makeImage(0, 0, baseNodeTexture, 0.5, nodeGroup);
      baseNodeImg.scale.set(this._naturalBaseNodeScale);
      if (starCount > 0) {
        const starImg = G.makeImage(0, 0, `map-nodes/map_star_${starCount}`, 0.5, nodeGroup);
        starImg.y = baseNodeImg.height * 0.4;
      }
      dynamicSheetManager.addSprite(nodeGroup, textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP);
      nodeGroup.destroy();
    }
    return dynamicSheetManager.getTexture(textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP);
  }

  /**
   * Retrieve level data.
   * Is async for the day that dynamic level loading occurs
   * @param {number} level
   * @returns {Object}
   */
  async _getLevelData(level) {
    return G.Helpers.levelDataMgr.getLevelByIndex(level);
  }

  /**
   * Pulses the base node. If false, it stops the pulsing
   * @param {boolean} b
   */
  _togglePulse(b) {
    if (b === undefined) {
      b = !this._pulseTween;
    }

    if (b) {
      const targetScale = this._baseNode.scale.x;
      this._pulseTween = game.add.tween(this._baseNode.scale)
        .to({ x: targetScale * 1.1, y: targetScale * 1.1 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    } else if (!b && this._pulseTween) {
      this._pulseTween.stop();
      this._baseNode.scale.set(this._naturalBaseNodeScale);
      this._pulseTween = null;
    }
  }

  /**
   * Bounces the node, just once
   * @returns {Promise}
   */
  bounce() {
    return new Promise((resolve) => {
      const targetScale = this._baseNode.scale.x;
      const tw = game.add.tween(this._baseNode.scale)
        .to({ x: targetScale * 1.1, y: targetScale * 1.1 }, 75, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
      tw.onComplete.add(() => resolve());
    });
  }
}

class GateNode extends BaseNode {
  /**
   * The Gate Node
   */
  constructor() {
    super();
    this.basicNodeType = basicNodeType.GATE;
    this._baseNode = G.makeImage(0, 0, 'gate', 0.5, this);
    this._baseNode.y = -this._baseNode.height * (2.5 / 4);
    this.nodeType = levelNodeType.GATE;
    this._playerAvatarOffset.y = this._baseNode.y;
  }

  /**
   * Inits the level node as a gate
   * @param {number} tileIndex
   * @param {Object} gateLevel
   * @param {boolean} activeGate Data existing
   */
  init(tileIndex, gateLevel, activeGate) {
    this.tileIndex = tileIndex;
    this._levelIndex = gateLevel;
    this._baseNode.alpha = 1;
    this.alpha = 1;
    this.active = activeGate;
    this.revive();
  }
}
