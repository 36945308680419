import { OMT_SystemInfo, ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

export default class TutorialMsgText extends Phaser.Group {
  constructor(x, y) {
    super(game);

    this.position.setTo(x, y);

    this.onTextAnimationFinished = new Phaser.Signal();
  }

  /**
   * set text string to display
   * @param {String} text
   * @param {boolean} useOldTextObj (optional)
   * @param {boolean} doNotLocalize (optional)
   */
  displayText(text, useOldTextObj = false, doNotLocalize = false) {
    if (text === undefined) return;

    if (!useOldTextObj) {
      if (this.textObj) {
        const oldTextObj = this.textObj;
        oldTextObj.dontAnimate = true;
        game.add.tween(oldTextObj)
          .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true)
          .onComplete.add(() => {
            oldTextObj.destroy();
          });
      }

      const { fontSize, textObjWidth, textObjHeight } = OMT_Utils.generateConditionalObject({
        fontSize: G.OMTsettings.elements.tutorialMsg.fontSize,
        textObjWidth: 400,
        textObjHeight: 190,
      }, {
        condition: () => OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal,
        parameters: {
          fontSize: G.OMTsettings.elements.tutorialMsg.fontSize + 10,
          textObjWidth: 800,
          textObjHeight: 220,
        },
      });
      this.textObj = new G.Text(0, 0, doNotLocalize ? text : OMT.language.getText(text), {
        style: 'font-blue',
        fontSize,
        lineSpacing: G.OMTsettings.elements.tutorialMsg.lineSpacing,
      }, 0.5, textObjWidth, textObjHeight, true, 'center');
      this.add(this.textObj);
    } else {
      this.add(this.textObj);
      this.textObj.setText(text);
    }

    // Disable typewriter effect for Hindi, Devanagari script doesn't work with it
    if (OMT.language.lang !== 'hi') {
      this.animateText(this.textObj);
    } else {
      game.time.events.add(1000, () => {
        this.onTextAnimationFinished.dispatch();
      });
    }
  }

  /**
   * set a custom text Object to replace the build-in one
   * @param {G.Text} textObj
   */
  setCustomTextObj(textObj) {
    if (this.textObj) this.destroy();
    this.textObj = textObj;
    this.add(this.textObj);
  }

  /**
   * Same thing as above, but the textObj is already made externally
   * @param {G.Text} textObj
   */
  displayCustomText(textObj) {
    if (textObj === undefined) return;

    if (this.textObj) {
      const oldTextObj = this.textObj;
      oldTextObj.dontAnimate = true;
      game.add.tween(oldTextObj)
        .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true)
        .onComplete.add(() => {
          oldTextObj.destroy();
        });
    }

    this.textObj = textObj;
    this.add(this.textObj);

    // Disable typewriter effect for Hindi, Devanagari script doesn't work with it
    if (OMT.language.lang !== 'hi') {
      this.animateText(this.textObj);
    }
  }

  animateText(target) {
    const animationFinishSignal = this.onTextAnimationFinished;
    let animColorStopIndex = 0;

    function animate() {
      if (target.game === null || target.dontAnimate) {
        return;
      }

      // adding +1, because colors stops are changing
      // spaces between letters
      if (animColorStopIndex === target.text.length + 1) {
        animationFinishSignal.dispatch();
        return;
      }

      target.clearColors();
      target.addColor('rgba(0, 0, 0, 0)', animColorStopIndex);
      animColorStopIndex++;

      game.time.events.add(10, animate);
    }
    animate();
  }

  emptyText() {
    this.removeChild(this.textObj);
    this.textObj.destroy();
    this.textObj = null;
  }
}
