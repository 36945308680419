/**
 * A quick feedback for the leaderboard invite friends.
 * Create it, add it, and use enterAndSelfDestroy.
 * It'll pop down, stay for 3.5s, and remove itself
 * @author Sandra Koo
 */
export class friendInviteFeedback extends Phaser.Group {
  /**
   * Uses the same parameters for a Phaser.Group
   * @param {Phaser.Game} givenGame
   * @param {DisplayObject} parentWindow
   */
  constructor(givenGame, parentWindow) {
    super(givenGame, parentWindow);

    this.drawElements();
    this.hide();
  }

  /**
   * Drawing the elements required
   */
  drawElements() {
    this.displayContainer = new Phaser.Group(G.game, this); // Tween container
    const bg = G.makeImage(0, -1, 'leaderboard_ingame_panel', 0.5, null); // bg
    bg.angle = 180;
    bg.height *= 1.2;

    const avatarGroup = new Phaser.Group(G.game, null); // Avatars
    const avatarM = this.drawAvatar('avatar_m');
    const avatarF = this.drawAvatar('avatar_f');
    avatarM.x = avatarF.width / 4;
    avatarF.x = -avatarM.width / 4;
    avatarF.y = avatarM.height / 4;
    avatarM.y = -avatarF.height / 4;
    avatarGroup.addChild(avatarM);
    avatarGroup.addChild(avatarF);
    avatarGroup.scale.x = 0.8;
    avatarGroup.scale.y = 0.8;

    const txt = new G.Text(0, 0, OMT.language.getText('Friend request was sent!'), { style: 'font-blue', fontSize: 36 }, 0.5, bg.width * 0.8, bg.height * 0.95, true, 'center');

    const checkMark = G.makeImage(0, 0, 'task_complete', 0.5, null);
    checkMark.scale.x = 1.1;
    checkMark.scale.y = 1.1;

    const bgExtra = bg.height * 0.1; // Positioning to avoid Messenger's message
    avatarGroup.y = bgExtra - avatarGroup.height / 4;
    checkMark.y = bgExtra - checkMark.height / 4;
    txt.y = (bg.height * 0.15) - txt.height / 4;
    avatarGroup.x = (-txt.width - avatarGroup.width) / 2;
    checkMark.x = 5 + (checkMark.width + txt.width) / 2;

    this.displayContainer.addChild(bg);
    this.displayContainer.addChild(avatarGroup);
    this.displayContainer.addChild(txt);
    this.displayContainer.addChild(checkMark);
  }

  /**
   * Function for making avatar
   * @param {string} avatarUrl
   */
  drawAvatar(avatarUrl) {
    const avatar = new Phaser.Group(G.game, null);
    const img = G.makeImage(0, 0, avatarUrl, 0.5, null);
    const border = G.makeImage(0, 0, 'avatar_frame', 0.5, null);

    avatar.addChild(img);
    avatar.addChild(border);

    return avatar;
  }

  /**
   * Hides the tween container
   */
  hide() {
    this.displayContainer.alpha = 0;
    this.displayContainer.y = -this.displayContainer.height;
  }

  /**
   * Tweens in, stays, tweens out and destroy
   */
  enterAndSelfDestroy() {
    this.hide();

    const targetY = -this.displayContainer.height;
    const enterTween = game.add.tween(this.displayContainer)
      .to({ y: 0, alpha: 1 }, 200, Phaser.Easing.Sinusoidal.In, false);
    enterTween.onComplete.addOnce(() => {
      const awayTween = game.add.tween(this.displayContainer)
        .to({ y: targetY, alpha: 0 }, 200, Phaser.Easing.Sinusoidal.In, false, 2500);
      awayTween.onComplete.addOnce(() => {
        this.destroy();
      });
      awayTween.start();
    });

    enterTween.start();
  }
}
