import UI_PrizeWheel from '@omt-components/UI/PrizeWheel/UI_PrizeWheel';
import PrizeWheel_ItemData, { ItemDataType } from '@omt-components/UI/PrizeWheel/Components/PrizeWheel_ItemData';
import OMT_UI_DrawUtil from '../../OMT_UI/Drawing/OMT_UI_DrawUtil';

// merged with DEFAULT_CONFIG from PrizeWheel_Item for each item
const ITEM_CONFIGS = {
  COINS: {
    text: {
      style: {
        style: 'font-num-blue',
        fontSize: 29,
        scaleStroke: true,
        align: 'center',
        lineSpacing: 0,
      },
      offset: { x: 105, y: 0 },
    },
    icon: {
      asset: 'coin_1',
      anchor: [0.5, 0.5],
      scale: 0.6,
      offset: { x: 135, y: 0 },
      angle: 90,
    },
  },
  COINS_SPECIAL: {
    text: {
      style: {
        style: 'font-num-orange',
        fontSize: 27,
        scaleStroke: true,
        align: 'center',
        lineSpacing: 0,
      },
      offset: { x: 105, y: 0 },
    },
    icon: {
      asset: 'coin_1',
      anchor: [0.5, 0.5],
      scale: 0.6,
      offset: { x: 135, y: 0 },
    },
  },
  BOOSTER: {
    text: {
      style: {
        style: 'font-num-blue',
        fontSize: 29,
        scaleStroke: true,
        align: 'center',
        lineSpacing: 0,
      },
      offset: { x: 105, y: 0 },
    },
    icon: {
      asset: '', // asset will be inserted
      anchor: [0.5, 0.5],
      scale: 0.5,
      offset: { x: 130, y: 0 },
      angle: 90,
    },
  },
};

/**
 * base class for OMT Prize Wheels adds OMT specific logic to the prize wheel implementation
 */
export default class OMT_PrizeWheel extends UI_PrizeWheel {
  /**
   * constructor
   * @param {Object} config (optional) components configurations
   * @param {Object} config.spinner (optional) overrides for PrizeWheel_Spinner DEFAULT_CONFIG
   * @param {Object} config.pointer (optional) overrides for PrizeWheel_Pointer DEFAULT_CONFIG
   */
  constructor(config = {}) {
    super(config);

    this._rawPrizeTable = [];
  }

  /**
   * queue a predetermined prize by its index in the prizeTable
   * @param {number} index prize index, -1 = random
   */
  addPrizeToSpinQueueByIndex(index) {
    // Choose a random index if the index is -1
    if (index === -1) {
      index = G.Utils.getRandomInteger(0, this._rawPrizeTable.length - 1);
    }

    if (this._rawPrizeTable.length < index + 1) {
      console.error(`ERROR: OMT_PrizeWheel.addPrizeToSpinQueueByIndex(${index}) failed. index does not exist.`);
      return;
    }
    this.addItemToSpinQueueByReward(this._rawPrizeTable[index]);
  }

  /**
   * spin to next prize. uses prize queue, or random if no queue
   * @param {Function} callback (optional) spin complete callback function
   */
  spinToNextPrize(callback = null) {
    const prizeIndex = this._determinePrize();
    if (prizeIndex >= 0) this.addPrizeToSpinQueueByIndex(prizeIndex);
    super.spinToNextPrize(callback);
  }

  /**
   * convert prize data to PrizeWheel_ItemData instances
   * @param {Array.<Object>} prizeTable OMT formatted prize table data list
   * @param {Object} itemConfigsOverride (optional) override Object to merge with ITEM_CONFIGS
   * @returns {Array.<PrizeWheel_ItemData>}
   */
  _convertPrizeList(prizeTable, itemConfigsOverride = null) {
    let itemConfigs = ITEM_CONFIGS;
    if (itemConfigsOverride) itemConfigs = _.merge(_.cloneDeep(itemConfigs), itemConfigsOverride);
    this._rawPrizeTable = prizeTable;
    const itemDataList = [];
    let itemLabel;
    let itemConfig;
    let itemType;

    for (const prizeData of prizeTable) {
      if (prizeData.prize.indexOf('#R') >= 0) { // process random booster
        const giftData = G.gift.processGift([prizeData.prize, prizeData.amount]);
        [prizeData.prize] = giftData;
      }

      if (prizeData.prize === 'coin') { // prize is coins
        itemLabel = `${prizeData.amount}`;
        itemConfig = this._getItemConfig(itemConfigs, prizeData.special);
        itemType = ItemDataType.COIN;
      } else { // prize is a booster
        itemLabel = `${prizeData.amount}x`;
        itemConfig = _.cloneDeep(itemConfigs.BOOSTER);
        itemConfig.icon.asset = OMT_UI_DrawUtil.getGiftString(prizeData.prize);
        itemType = ItemDataType.BOOSTER;
      }

      // create prize ItemData
      const itemData = new PrizeWheel_ItemData(`${itemLabel}`, prizeData, itemType);
      itemData.config = itemConfig;
      itemDataList.unshift(itemData); // old wheel items sorted backwards?
    }
    return itemDataList;
  }

  /**
   * Determines prize based off of the chance parameter.
   * Returns the index of the prize
   * @returns {number}
   */
  _determinePrize() {
    let sumOfProb = 0;
    const probabilities = []; // Sort and modify by the highest chance first
    for (let i = 0; i < this._rawPrizeTable.length; i++) {
      probabilities.push({ index: i, prize: this._rawPrizeTable[i] });
      sumOfProb += this._rawPrizeTable[i].chance;
    }
    const rng = game.math.between(0, sumOfProb); // or sum of probabilities

    if (this._config.isFirstTimeSpinning) { // If its the first time, rig it so +3 move or horizontal line break is picked first || false
      // First find out if any of those are there in the prizeList
      const firstTimeSpinPrize = this._firstTimePrize(rng, sumOfProb);
      if (firstTimeSpinPrize != null) return firstTimeSpinPrize;
    }
    // If they're not there do default rolling
    probabilities.sort((a, b) => { // Sort from largest to smallest probability
      if (a.prize.chance < b.prize.chance) return 1;
      if (a.prize.chance >= b.prize.chance) return -1;
      return 0;
    });

    let probability = 0;
    for (let i = 0; i < probabilities.length; i++) { // Go through each probability and see where our rng number falls into
      probability += probabilities[i].prize.chance;
      if (rng < probability) return probabilities[i].index; // If rng is within this probability, return it
    }
    return -1; // nothing found
  }

  /**
   * Special algorithm for determining what prize to show if its the first time spinning
   * @param {number} rng given rng
   * @param {number} total given total of probabilities
   * @returns {number}
   */
  _firstTimePrize(rng, total) {
    const goodDeals = [];
    for (let i = 0; i < this._rawPrizeTable.length; i++) { // Look for all items with good deal flag
      const deal = this._rawPrizeTable[i].goodDeal;
      if (deal) goodDeals.push(i);
    }

    if (goodDeals.length > 0) { // Randomly pick one out
      const section = Math.floor(total / goodDeals.length);
      const chosenPrize = Math.floor(rng / section);
      return goodDeals[chosenPrize]; // Return it
    }

    return null; // Return nothing
  }

  /**
   * Determine config of prize item text
   * @param {Object} itemConfigs
   * @param {boolean} isSpecial
   */
  _getItemConfig(itemConfigs, isSpecial) {
    return isSpecial ? itemConfigs.COINS_SPECIAL : itemConfigs.COINS;
  }
}
