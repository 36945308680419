/* eslint-disable no-use-before-define */
export default class FxMapLayer extends G.BatchPoolGroup {
  /**
   * Batch pool group for some stars
   */
  constructor() {
    super(FxMapPart);
    this.fixedToCamera = true;
    this._signalToken = G.sb('fxMap').add(this.init, this);
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._signalToken) {
      if (this._signalToken.detach) {
        this._signalToken.detach();
      }
      this._signalToken = null;
    }
    super.destroy();
  }
}

class FxMapPart extends G.Image {
  /**
   * Some... particle for stars on the world map?
   */
  constructor() {
    super(game);
  }

  /**
   * An empty update to save on processing
   */
  emptyUpdate() { }

  /**
   * Resets the particle
   * @param {Object} obj
   */
  reset(obj) {
    this.x = obj.position ? obj.position.x : obj.x;
    this.y = obj.position ? obj.position.y : obj.y;

    this.anchor.setTo(0.5);
    this.scale.setTo(1);
    this.alpha = 1;
    this.angle = 0;
    this.blendMode = 0;
    this.changeTexture(null);
    this.visible = true;
    this.update = this.emptyUpdate;
    this.revive();
  }

  /**
   * Inits the particle
   * @param {string} effect
   * @param {Object} obj
   */
  init(effect, obj) {
    this.reset(obj);

    if (this[effect]) {
      this[effect].apply(this, arguments); /* eslint-disable-line prefer-rest-params, prefer-spread */
    } else {
      console.warn(`There is no ${effect} in G.FxPart`);
    }
  }

  /**
   * Inits a star-like particle
   * @param {string} fx
   * @param {Object} obj
   */
  star(fx, obj) {
    this.changeTexture('starPart');

    this.blendMode = 1;

    this.alpha = obj.alpha || 1;

    this._timer = obj.timer || game.rnd.between(20, 40);
    this.blendMode = 0;
    this.scale.setTo(obj.scale || 0.7);
    this._velX = obj.velX || game.rnd.realInRange(-10, 10);
    this._velY = obj.velY || game.rnd.realInRange(-20, -8);
    this._velAngle = game.rnd.realInRange(-5, 5);
    this.angle = game.rnd.realInRange(0, 360);

    this.update = this.starUpdate;
  }

  /**
   * Update function for the star
   */
  starUpdate() {
    this.x += G.deltaTime * this._velX;
    this.y += G.deltaTime * this._velY;

    this._velX -= G.deltaTime * (1 - 0.95);
    this._velY -= G.deltaTime * (1 - 0.95);

    this.angle += this._velAngle;

    if (this._timer-- < 0) {
      this.alpha -= G.deltaTime * 0.05;
      if (this.alpha <= 0) {
        this.kill();
      }
    }
  }
}
