import { BoardGameHooks, BOARD_FEATURE_KEYS } from '@omt-game-board/Board/BoardGameHooks';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import { OMT_SystemInfo } from '../Services/OMT/OMT_SystemInfo';
import OMT_VILLAINS from '../OMT_UI/OMT_Villains';
import { GameScaleController } from '../States/Scaling/GameScaleController';
import { SPECIAL_TYPES } from '@omt-game-board/Board/BoardConstants';

/**
 * OMT specific Board to Game hooks. All methods in this class should override methods in BoardGameHooks.
 */
export class OMT_BoardGameHooks extends BoardGameHooks {
  /**
   * constructor
   * @param {Object} levelData parsed level data
   */
  constructor(levelData) {
    super(levelData);

    this._state = game.state.getCurrentState();
    this._gameScale = GameScaleController.getInstance().gameScale;

    // add game customized feature checks
    Object.assign(this._featureChecks, {
      // treasure-hunt feature
      [BOARD_FEATURE_KEYS.TREASURE_HUNT]: () => this._state.mode === LevelType.TREASURE_HUNT && OMT.feature.isTreasureHuntOn(true, true, false, true),
      // fortune-cookie feature
      [BOARD_FEATURE_KEYS.FORTUNE_COOKIE]: () => {
        const fortuneCookieEventAtive = OMT.feature.getFortuneCookieEvent();
        const isTutorialLevel = this._levelData.tutID;
        const isTournamentLevel = this._state.mode === LevelType.TOURNAMENT;
        return fortuneCookieEventAtive && !isTutorialLevel && !isTournamentLevel;
      },
      // token-event feature
      [BOARD_FEATURE_KEYS.EVENT_TOKENS]: () => {
        const isEventLevel = this._state.mode === LevelType.COLLECT_EVENT;
        const isEventRunning = OMT.feature.isTokenEventOn(true);
        return isEventLevel && isEventRunning;
      },
    });
  }

  /**
   * get localized text
   * @param {string} key localization key, basically the english text.
   * @returns {string}
   */
  getText(key) { return OMT.language.getText(key); }

  /**
   * update players coins. override to implement.
   * @param {number} value value to modify coin count by
   * @returns {number} new coin value
   */
  changeCoins(value) {
    G.saveState.changeCoins(value);
    if (window.flushUserData){
      window.flushUserData();
    }
    return G.saveState.getCoins();
  }

  /**
   * triggers any external actions upon booster use.
   * @param {number} boosterNum
   * @param {number} unlimitedMode
   */
  onBoosterUse(boosterNum, unlimitedMode) {
    G.saveState.useBooster(boosterNum, this._levelData.index, unlimitedMode);
    // DDNA.missionTracker.onBoosterUse(boosterNum, false);
    if (window.flushUserData){
      window.flushUserData();
    }
  }

  /**
   * triggers any external actions upon start booster use.
   * @param {number} boosterNum
   * @param {number} boosterCount
   */
  onStartBoosterUse(boosterNum, boosterCount) {
    G.saveState.useStartBooster(boosterNum, boosterCount);
  }

  /**
   * triggers tracking of pre-level boosters on level start.
   */
  trackStartBoosters(startBoosterTrackingData) {
    if (startBoosterTrackingData.length > 0) {
      // // DDNA.transactionHelper.trackMultipleBoosterUse(startBoosterTrackingData);
    }
  }

  /**
   * triggers any external actions upon out-of-moves booster use.
   * @param {string} boosterType
   */
  onOOMBoosterUse(boosterType) {
    // DDNA.missionTracker.onOOMMovesBoosterUse(boosterType);
  }

  /**
   * increment the daily challenge win count.
   */
  incrementDailyChallengeWinCount() {
    if (!G.saveState.data.dailyBeaten) G.saveState.data.dailyBeaten = 0;
    G.saveState.data.dailyBeaten++;
    if (window.flushUserData){
      window.flushUserData();
    }
  }

  /**
   * Adds the token amount collected from the treasure hunt
   * @param {number} amount
   */
  collectTreasureHuntToken(amount) {
    G.saveState.treasureHuntManager.addToTempTokens(amount);
    if (window.flushUserData){
      window.flushUserData();
    }
  }

  /**
   * triggers when the level goal is reached
   */
  onGoalAchieved() {
    if (OMT.feature.isVillainsEnabled()) { // Villain enter angrily animation
      G.sb(OMT_VILLAINS.getInGameState('WIN_START')).dispatch(this);
    }
    if (window.flushUserData){
      window.flushUserData();
    }
  }

  /**
   * triggers when Board.deconstruct() is first called
   */
  onBoardDesconstruct() {
    if (OMT.feature.isVillainsEnabled()) { // Villain enter angrily animation
      G.sb(OMT_VILLAINS.getInGameState('WIN_END')).dispatch(this);
    }
  }

  /**
   * Get an array of objects representing temporary boosters for the start booster action. override to implement
   * @returns {{[key: string]: Array<SPECIAL_TYPES> | Array<number>}}
   */
  getTemporaryBoosters() {
    return {
      mystery_gift: this.getActiveMysteryGifts(),
      villains: this.getVillainsStartBoosters(),
    };
  }

  /**
   * Do cleanup regarding temporary boosters.
   */
  resolveTemporaryBoosters() {
    this.resolveVillainsStartBoosters();
  }


  /**
   * get icon asset for boosterType
   * @param {string} boosterType
   * @returns {String}
   */
  getIconForBoosterType(boosterType) {
    return G.gift.getIconForType(boosterType);
  }

  /**
    * get moves for a boosterType
    * @param {string} boosterType
    * @returns {number}
    */
  getMovesForBoosterType(boosterType) {
    return G.gift.getMovesFromBooster(boosterType);
  }

  /**
   * get array of active mystery gifts.
   * @returns {Array<SPECIAL_TYPES>}
   */
  getActiveMysteryGifts() {
    const notAnyOf = [LevelType.TOURNAMENT, LevelType.CHALLENGE, LevelType.TREASURE_HUNT];
    const gameModePreventsMysteryGifts = notAnyOf.indexOf(this._state.mode) > -1;
    const mysteryGiftsActive = G.saveState.mysteryGiftManager.inGame_hasMysteryGiftBeenInitialized();
    if (!mysteryGiftsActive || this._levelData.noPreBoosters || gameModePreventsMysteryGifts) return [];

    G.saveState.mysteryGiftManager.mysteryBoostersInitialized = true;
    return G.saveState.mysteryGiftManager.getCurrentGifts();
  }

  /**
   * Get array of temporary villains tutorial boosters
   */
  getVillainsStartBoosters() {
    return G.saveState.villainsDataManager.getStartBoosters();
  }

  /**
   * Reset the booster settings for villains temporary boosters
   */
  resolveVillainsStartBoosters() {
    G.saveState.villainsDataManager.resolveStartBoosters();
  }

  /**
   * true if discounted loss aversion can be shown.
   * @param {number} maxSpins
   * @returns {boolean}
   */
  canShowLossAversionDiscount(maxSpins) {
    const spinsLeft = Math.max(maxSpins - G.saveState.getLossAversionWheelSpinCount(), 0);
    return spinsLeft === 0 && G.saveState.canDiscountBeenSeen();
  }

  /**
   * log out inventory transactions with appropriate game systems.
   * @param {Array.<Object>} inventoryTransactions
   */
  logInventoryTransactions(inventoryTransactions) {
    if (inventoryTransactions.length === 0) return;
    OMT.transactionTracking.logInventoryTransactionBegin();
    for (const transaction of inventoryTransactions) {
      OMT.transactionTracking.addInventoryChange(
        transaction.action,
        transaction.boosterNum,
        transaction.amount,
      );
    }
    OMT.transactionTracking.logInventoryTransactionEnd();
  }

  /**
   * log a ftux event.
   * @param {number} stepNum
   * @param {string} ddnaEventName (optional)
   * @param {string} fbaEventName (optional)
   */
  logFTUXEvent(stepNum, ddnaEventName = null, fbaEventName = null) {
    // if (ddnaEventName != null) DDNA.tracking.ftuxEvent(stepNum, ddnaEventName);
    if (fbaEventName != null) OMT.platformTracking.logFTUEvent(fbaEventName);
  }

  /**
   * play sound by id
   * @param {string} soundId
   * @param {number} volume (optional)
   */
  playSound(soundId, volume = 1) {
    if (G.sfx[soundId] == null) return;
    G.sfx[soundId].play(volume);
  }

  /**
   * Starts cascade skip sequence after player clicks on Skip
   */
  startCascadeSkipSequence() {
    // DDNA.missionTracker.setMissionParameter('mSkippedWinSequence', 1);
    game.add.tween(this._state.pointsLayer).to({ alpha: 0 }, 750, Phaser.Easing.Sinusoidal.In, true);
  }

  /**
   * Calculates the final score after player skips final cascade sequence
   */
  calculateFinalScoreAfterCascadeSkip() {
    const currentScore = G.lvl.points;
    const averageScorePerMove = G.lvl.movesMade > 0
      ? G.lvl.points / G.lvl.movesMade
      : 0;

    const { specialCandyValues } = G.OMTsettings.cascadeSkipSettings;

    const movesLeft = G.lvl.moves;
    const numSpecialCandies = {};
    for (const candyType in SPECIAL_TYPES) {
      if (Object.prototype.hasOwnProperty.call(SPECIAL_TYPES, candyType)) {
        numSpecialCandies[SPECIAL_TYPES[candyType]] = 0;
      }
    }

    // Count special candies
    const specialCandies = this._state.board.candiesLayer.getAllSpecialCandies();
    for (const candy of specialCandies) {
      const type = candy.getSpecialType();
      numSpecialCandies[type]++;
    }

    // Calculate final score...
    let finalScore = currentScore;

    // Points from remaining moves
    finalScore += averageScorePerMove * (movesLeft + G.Utils.getRandomInteger(1, 5));
    // Points from special candies
    for (const candyType in SPECIAL_TYPES) {
      if (Object.prototype.hasOwnProperty.call(SPECIAL_TYPES, candyType)) {
        finalScore += specialCandyValues[SPECIAL_TYPES[candyType]] * numSpecialCandies[SPECIAL_TYPES[candyType]];
      }
    }

    // Round score down to avoid fractional points
    finalScore = Math.floor(finalScore);

    // Update final score on TopBar and level data
    G.sb('onPointsChange').dispatch(finalScore);
    G.lvl.points = finalScore;

    // Set moves to 0 on TopBar
    G.lvl.moves = 0;
    G.sb('changeMoveNumber').dispatch();
  }

  /** GETTER METHODS ********************************* */

  /** @returns {number} gets last passed level. override to implement. */
  get lastPassedLevelNum() { return G.saveState.getLastPassedLevelNr(); }

  /** @returns {boolean} check if hints are allowed. */
  get hintsAllowed() { return G.saveState.getAllowHints(); }

  /** @returns {TreasureHuntManager} */
  get treasureHuntManager() { return G.saveState.treasureHuntManager; }

  /** @returns {FortuneCookieDataManager} */
  get fortuneCookieDataManager() { return G.saveState.fortuneCookieDataManager; }

  /** @returns {number} some check for the fortune cookie drops, not really sure. */
  get fortuneCookieMovesLeft() { return G.featureUnlock.fortuneCookie.movesLeft; }

  /** @returns {TokenEventManager} */
  get tokenEventManager() { return G.saveState.tokenEventManager; }

  /** @returns {EventTokenCounter} get the token event coutner display */
  get eventTokenCounter() { return this._state.eventTokenCounter; }

  /** @returns {Point} get animation target for extra moves. */
  get extraMovesAnimationTarget() { return this._state.topBar.movesTxt; }

  /** @returns {Phaser.DisplayObject} display layer for booster FX. */
  get startBoosterFXLayer() { return this._state.startBoosterFXLayer; }

  /** @returns {UI_TopBar} display layer for booster FX. */
  get uiTopBar() { return this._state.topBar; }

  /** @returns {boolean} is the device desktop? */
  get deviceIsDesktop() { return OMT_SystemInfo.getInstance().deviceIsDesktop; }

  /** @returns {number} game orientation */
  get orientation() { return OMT_SystemInfo.getInstance().orientation; }

  /** @returns {number} game scale */
  get gameScale() { return this._gameScale; }

  /**
   * This should ONLY be true in OMT
   * @returns {boolean} has reduced landscape resolution
   */
  get hasLowResLandscape() { return true; }

  /** @returns {Object} config for skipping the final cascade */
  get cascadeSkipConfig() {
    return {
      enabled: true,
      buttonText: this.getText('Tap to Skip'),
      buttonStyle: {
        style: 'finalCascade-skipText',
        fontSize: '65px',
      },
    };
  }
}
