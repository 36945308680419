/**
* configuration class for FX_ParticleEmitter
*/
export class FX_ParticleEmitterConfig {
  /**
   *  constructor, set default parameters
   * @param {Object} config (optional) config to apply useing .applyConfigObject()
   */
  constructor(config = null) {
    // sprite sheet and frames to use for particles
    this.spritesheet = 'bursteffects';
    this.frames = ['cookie_match_0'];
    // if false scale and rotation will not be allowed
    this.allowTransforms = true;
    // maximum allowed particle instance count
    this.maxParticles = 100;
    // min / max spawn delay in ms
    this.minSpawnDelay = 25;
    this.maxSpawnDelay = 50;
    // min / max particle life in ms
    this.minParticleLife = 600;
    this.maxParticleLife = 900;
    // randomness of spawn location
    this.spawnRandomX = 10;
    this.spawnRandomY = 10;
    // min / max scale
    this.minParticleScale = 1.1;
    this.maxParticleScale = 1.6;
    // min / max velocity per sec
    this.minParticleVel = 10;
    this.maxParticleVel = 100;
    // emit angle + allowed variance
    this.emitAngle = 0;
    this.emitAngleVariance = 2 * Math.PI;
    // min / max particle rotation
    this.minParticleRotation = 0;
    this.maxParticleRotation = 2 * Math.PI;
    // rate particle fades out at per sec
    this.particleFadeRate = 1.5;
    // gravity / downforce to apply per sec
    this.gravity = 20;
    // friction to apply to velocity should be 0-1
    this.friction = 0;
    // max spawn duration
    this.maxSpawnDuration = Infinity;

    // apply the passed in config object
    if (config) this.applyConfigObject(config);
  }

  /**
   * apply a config object for emitter / particle params
   * @param {Object} config
   */
  applyConfigObject(config) {
    for (const key in config) {
      if (Object.prototype.hasOwnProperty.call(this, key)) {
        this[key] = config[key];
      } else {
        console.log(`FX_ParticleEmtter: '_${key}' is a invalid property`);
      }
    }
  }
}
