import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import OMT_VILLAINS from '../../../../OMT_UI/OMT_Villains';

/**
 * class for additional info panel added to OOMFailView
 */
export default class OutOfMovesInfoPanel extends Phaser.Group {
  /**
   * constructor
   * @param {number} x
   * @param {number} y
   */
  constructor(x, y) {
    super(game, null);

    const state = game.state.getCurrentState();
    this._isDailyChallenge = LvlDataManager.getInstance().isDailyChallengeLevel;

    this.position.set(x, y);
    if (G.MYSTERYGIFT && G.saveState.mysteryGiftManager.isModeReady()
      && G.saveState.mysteryGiftManager.getCurrentStreak() > 0
      && !G.lvlData.noPreBoosters
      && !this._isDailyChallenge) {
      this._initMysteryGiftPanelLayout(state);
    } else {
      this._initRegularPanelLayout(state);
    }
  }

  /**
   * init as basic / regular info panel
   * @param {Phaser.State} state reference to the game state
   */
  _initRegularPanelLayout(state) {
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const useSuperHardGraphics = isSuperHardLevel || this._isDailyChallenge; // Daily challenge
    // gingy
    const gingyAsset = state.removedLifeAtStart ? 'sad_gingy_heart_small' : 'failed_dimond';
    const gingy = G.makeImage(0, 0, gingyAsset, 0.5, this);

    // continue text
    let continueTextStyle = {
      style: 'font-blue',
      fontSize: 50,
    };
    if (isNotNormalLevel) {
      continueTextStyle = {
        style: useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_4') : OMT_VILLAINS.getPrefixedName('hard_4'),
      };
    }
    const continueText = new G.Text(0, 0, OMT.language.getText('Continue?'), continueTextStyle, 0.5, 400);
    continueText.y = gingy.y + (gingy.height + continueText.height) / 2;
    this.addChild(continueText);
  }

  /**
   * init as mystery gift info panel
   */
  _initMysteryGiftPanelLayout() {
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const useSuperHardGraphics = isSuperHardLevel || this._isDailyChallenge;
    // mystery gift booster rewards / status
    const iconsMap = {
      spiral: 'ui_booster_8',
      vertical: 'ui_booster_4',
      horizontal: 'ui_booster_3',
      cross: 'ui_booster_9',
    };
    const gifts = G.saveState.mysteryGiftManager.getCurrentGifts();
    for (let i = 0; i < 3; i++) {
      const icon = G.makeImage(
        -140 + (i * 140), 0,
        iconsMap[gifts[i]] || 'MysteryGift_EmptyTile', 0.5, this,
      );
      if (iconsMap[gifts[i]]) {
        G.makeImage(-30, 30, 'task_fail', 0.5, icon);
      }
    }

    // dont lose text
    let dontLoseTextStyle = {
      style: 'font-blue',
      fontSize: 50,
    };
    if (isNotNormalLevel) {
      dontLoseTextStyle = {
        style: useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_4') : OMT_VILLAINS.getPrefixedName('hard_4'),
      };
    }

    const dontLoseText = new G.Text(0, 95, OMT.language.getText("Don't lose your boost!"), dontLoseTextStyle, 0.5, 400);
    this.add(dontLoseText);
  }
}
