/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import OMT_UI_AvatarWithBadge from './OMT_UI_AvatarWithBadge';

const FADE_IN_TRANSITION_DURATION = 250;

/*
  generic friends list item / row
*/
export class OMT_UI_FriendsListItem extends Phaser.Group {
  /**
   * constructor
   * @param {Object} friendData
   * @param {number} itemWidth
   * @param {number} itemHeight
   * @param {number} bgColor
   */
  constructor(friendData, itemWidth, itemHeight, bgColor) {
    super(game);

    this.signals = {}; // add signals to this

    this._friendData = friendData;
    this._itemWidth = itemWidth;
    this._itemHeight = itemHeight;

    this._lineFadeInActive = false;

    // calculate positions based on itemWidth / itemHeight
    this._imageSize = Math.round(this._itemHeight * 0.85);
    this._imageX = Math.round(this._itemHeight * 0.1) + Math.round(this._imageSize / 2);
    this._nameLabelX = this._imageX + (this._imageSize / 2) + 10;
    this._nameLabelFontSize = `${Math.round(this._itemHeight * 0.5)}px`;
    this._buttonRightX = this._itemWidth;
    this._buttonHeight = Math.round(this._itemHeight * 0.8);

    this._initCacheableContentContainer();
    this._drawBackground(bgColor);
    this._initAvatar();
    this._initNameLabel();
  }

  /**
   * create a container for elements that can be cached
   */
  _initCacheableContentContainer() {
    this._cacheableContent = new Phaser.Group(game);
    this.addChild(this._cacheableContent);
  }

  /**
   * enable / disable content caching
   * @param {boolean} value
   */
  set cacheContent(value) {
    this._cacheableContent.cacheAsBitmap = value;
  }

  /**
   * get the relevant friend data Obejct
   * @returns {Object}
   */
  get friendData() {
    return this._friendData;
  }

  /**
   * draw the items background
   */
  _drawBackground(bgColor) {
    const graphics = new Phaser.Graphics(game);
    graphics.beginFill(bgColor);
    graphics.drawRect(0, 0, this._itemWidth, this._itemHeight);
    graphics.endFill();
    graphics.cacheAsBitmap = true;
    this.addChildAt(graphics, 0);
    this._bg = graphics;
  }

  /**
   * init the avatar image
   * @param {Class} altAvatarClass (optionial)
   */
  _initAvatar(altAvatarClass = null) {
    const AvatarClass = altAvatarClass || OMT_UI_AvatarWithBadge;
    this._avatarImage = new AvatarClass(this._friendData.image, this._friendData.badge, this._imageSize);
    this._avatarImage.x = this._imageX;
    this._avatarImage.y = this._itemHeight / 2;
    this._cacheableContent.addChild(this._avatarImage);
  }

  /**
   * init the users name label
   * @param {number} fontStyle (optional) optional font style override
   */
  _initNameLabel(fontStyle = null) {
    const style = fontStyle || { style: 'font-blue', fontSize: this._nameLabelFontSize, align: 'left' };
    this._nameLabel = new G.Text(0, 0, this._shortenName(this._friendData.name), style, [0, 0.5], 500, this._itemHeight, true);
    this._nameLabel.x = this._avatarImage.x + (this._imageSize / 2) + 10;
    this._nameLabel.y = this._itemHeight / 2;
    this._cacheableContent.addChild(this._nameLabel);
  }

  /**
   * shorten the users name
   * @param {string} fullName
   * @param {number} maxLength
   */
  _shortenName(fullName, maxLength = 13) {
    if (fullName.length > maxLength && fullName.length > 3) {
      fullName = `${fullName.substr(0, maxLength - 3)}...`;
    }
    return fullName;
  }

  /**
   * set the fade transition effect
   * @param {number} delay delay in milliseconds before starting animation
   * @returns {Phaser.Tween}
   */
  setLineFadeInTransition(delay) {
    if (this._fadeTween) this._fadeTween.stop(); // stop any previous tween
    this._lineFadeInActive = true;
    this.alpha = 0;
    this._fadeTween = game.add.tween(this);
    this._fadeTween.to({ alpha: 1 }, FADE_IN_TRANSITION_DURATION, Phaser.Easing.Quadratic.Out, false, delay);
    this._fadeTween.onComplete.addOnce(this._onlineFadeInComplete, this);
    this._fadeTween.start();
    return this._fadeTween;
  }

  /**
   * called when the line fade in transistion is complete
   */
  _onlineFadeInComplete() {
    this._lineFadeInActive = false;
  }

  /**
   * true if the line fade in transition is active
   * @returns {boolean}
   */
  get isLineFadeInActive() {
    return this._lineFadeInActive;
  }

  /**
   * set the lines alpha and stop any tween
   * @param {number} alpha 0-1
   */
  setContentAlpha(alpha) {
    if (this._fadeTween) this._fadeTween.stop(); // stop any previous tween
    this.alpha = alpha;
  }

  get height() {
    return this._itemHeight;
  }

  /**
   * destruction method
   */
  destroy() {
    if (this._fadeTween) this._fadeTween.stop();
    Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    super.destroy();
  }
}
