import OOMFailView from '../Windows/OOM/views/OOMFailView';

/**
 * Out Of Moves TokenEvent fail view
 */
export class TreasureHuntOOMFailView extends OOMFailView {
  /**
   * override init add token event diplays
   */
  _init() {
    super._init();
    this._initDisplay();
  }

  /**
   * overrides base class. custom event title banner.
   */
  _initTitleText() {
    // background banner
    const banner = G.makeImage(0, -265, 'treasureHunt_banner', 0.5, this);
    this.addChild(banner);

    // main title text
    const titleTxt = new G.Text(0, -267,
      OMT.language.getText('Out of moves!'), 'treasureHunt-headerTitleText', 0.5, 330, 125, true, 'center');
    this.addChild(titleTxt);
  }

  /**
   * init continue text field
   */
  _initDisplay() {
    // You win
    this.subTitle = new G.Text(0, -142, OMT.language.getText('You will lose:'), {
      style: 'treasureHunt-levelWindow',
      fontSize: 35,
    }, 0.5, 530);
    this.add(this.subTitle);

    const continueTxt = new G.Text(0, 57, OMT.language.getText('Continue?'), {
      style: 'treasureHunt-levelWindow',
      fontSize: 25,
    }, 0.5, 400);
    this.addChild(continueTxt);
  }

  /**
   * Creates a block where an image and a number sits.
   * Layout is same amonst all blocks
   * @param {{img:string, scale:number}} iconDetails
   * @param {number} number
   * @param {boolean} [isDoubling]
   */
  _createIconAndNumber(iconDetails, number, isDoubling) {
    const group = new Phaser.Group(game, this);
    const bg = G.makeImage(0, 0, 'treasureHunt_winNumberBg', 0.5, group);
    const img = G.makeImage(0, 0, iconDetails.img, 0.5, group);
    img.x = bg.x - (bg.width - img.width) / 2;
    const cross = G.makeImage(img.x, img.y, 'lossCross', 0.5, group);
    cross.scale.set(img.height / cross.height);
    const leftoverSpace = bg.width - img.width;
    const text = new G.Text(0, 0, number, 'treasureHunt-levelWindow', 0.5, leftoverSpace);
    text.x = bg.x + img.width / 2;
    group.addChild(text);

    if (isDoubling) {
      const twoTimesGroup = new Phaser.Group(game, group);
      const ttBg = G.makeImage(0, 0, 'treasureHunt_gameMultiplier', 0.5, twoTimesGroup);
      const ttText = new G.Text(0, 3, 'x2', {
        style: 'font-white',
        fontSize: ttBg.height * 0.8,
      }, 0.5, ttBg.width * 0.8);
      twoTimesGroup.addChild(ttText);

      twoTimesGroup.scale.set((bg.height * 0.8) / twoTimesGroup.height);
      twoTimesGroup.x = bg.x + bg.width / 2;
    }

    return {
      image: group,
      text,
    };
  }

  /**
   * overrides base class with customizations for the tokenEvent
   */
  _initInfoPanel() {
    // Tokens Collected
    if (game.state.getCurrentState().removedLifeAtStart) {
      this._life = this._createIconAndNumber({ img: 'heart', scale: 1 }, 1);
      this._life.image.y = -77;
    }
    this._tokens = this._createIconAndNumber({ img: 'treasureHunt_token', scale: 1 }, 0, G.saveState.treasureHuntManager.isDoubling);
    this._tokens.image.y = this._life ? 2 : -44;

    // Horizontal option, if there ever needs to be
    // this._life.image.x = -110;
    // this._tokens.image.x = this._life ? 110 : 0;
    // this._life.image.y = -72;
    // this._tokens.image.y = 15;
  }

  /**
   * Initalizes button and pushes it down
   */
  _initSpinButton() {
    super._initSpinButton();
    this._spinBtn.y += 10;
  }

  /**
   * Initalizes moves button, but at a different y position
   */
  _initBuyMovesBtn() {
    super._initBuyMovesBtn(0, 130);
  }

  /**
   * Sets the token number to be proper
   * @param {number} tokens
   */
  setTokens(tokens) {
    this._tokens.text.setText(tokens);
  }
}
