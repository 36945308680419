/* eslint-disable max-len */
G.featureUnlock = { // The rest of the settings are at other-match3-engine\js\Config\featureUnlockDefault.js
  useNoGates: true, // No gates for RAIN
  extraCoinsPerMove: { // Awards coins based off of extra moves left at EoL
    enabled: true,
  },
  useWebGLRenderer: true,
  friendshipChest: { // Friendship chest
    enabled: true,
    storyMode: true, // Have normal friendship chest or story mode
  },
  requestMoveHelp: true,
  eventPostcard: {
    enabled: false,
    range: {
      start: 1586304000000, // Apr 8, 2020 00:00:00 UTC
      end: 1587600000000, // Apr 23, 2020 00:00:00 UTC
    },
  },
  gameTriggeredMessages: { // Activates game-triggered bot messages
    botMessages: true,
  },
  transactionTracking: {
    track: { // Activates "high-value" transaction event tracking
      ss: false, // player inventory snapshots
      rm: true, // real-money transactions
      inv: false, // inventory transactions
      crmInv: false, // CRM inventory transactions, should always be false for now
    },
    purge: { // Determines whether the game should transaction histories
      ss: true,
      rm: false,
      inv: true,
      crmInv: false, // should always be false for now
    },
  },
  fortuneCookie: {
    enabled: true,
    dropChance: 20, // The base drop chance (%) of a fortune piece
    movesLeft: 15, // Piece drops at > movesLeft
    dismissLimit: 3, // The number of times you can dismiss the fortune cookie before cooldown
    activeDays: [5, 6, 0], // Active days are Fri, Sat, Sun, UTC
    resetDay: 1, // The day (Mon) dismiss is reset
  },
  achievementGifts: false,
  progressiveLevelReward: { enabled: true },
  freePrizeWheelSpin: true,
  freeFailFlowWheelSpin: false,
  multipleExtraMovesFailFlow: true,

  dailyReward: { // daily reward features
    allowTripleReward: true, // 3x reward allowed when doubling is enabled
  },

  // limit the number of times a wheel can be spun. Settings are in json/settings(IAP).js
  limitSpins: {
    prizeWheel: false,
    lossAversionWheel: false,
  },

  realMoneyPrizeWheel: true,
  realMoneyHelperWheel: true,
  realMoneyTargetedOfferWheel: true,

  unlockLevels: {
    coinDouble: 40,
  },
  weeklyTournament: { // weekly tournament settings
    enabled: true,
  },
  dailyChallenge: { // daily challenge settings
    enabled: true,
  },
  gateAlgorithm: 'conservative', // Algorithm for gate unlocking,
  villains: true, // Setting this to false disables super hard levels, window villain speech bubbles, tutorials and corruption
  delayedBoardAppereance: true, // Set this to enable delayed board appereance for hard levels.
  multiplePreLevelBoosters: true, // Set this to allow for multiple pre level boosters on hard and super hard levels.
  tournamentPromo: true, // Whether to show the tournament promotion window / button
  brag2: { enabled: false }, // brag 2.0 flow
  tokenEvent: {
    enabled: false,
  },
  multipleBoosterBuyingPanel: {
    enabled: false,
    multiAndOne: false,
  },
  additionalInterstitialRules: {
    initial: true, // Toggles addition interstitial display rules. This is the initial implementaton. Includes EveryN, Out of Moves, Win 2 in a row, Give up
    tournamentWin: {
      enabled: true,
    },
    giftAds: {
      enabled: true,
    },
  },
};
