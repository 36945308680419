/* eslint-disable prefer-destructuring */
import { Window } from '../../../00_IMMEDIATE/Window';
import { UI_LoadingIndicatorDisplay } from '@omt-components/UI/Loading/UI_LoadingIndicatorDisplay';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI_SquareButton';
import { SPECIALEVENT_LEADERBOARD_VIEWMODE } from './SpecialEvent_LeaderboardUserListPanel';
import { SpecialEvent_LeaderboardTabs } from './SpecialEvent_LeaderboardTabs';
import SpecialEvent_LeaderboardUserListPanelScrollable from './SpecialEvent_LeaderboardUserListPanelScrollable';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

const MIN_FRIENDS_FOR_FRIENDS_STATE = 3;
const MAX_USERS_TO_DISPLAY = 6;

const LOADING_INDICATOR_X = 320;
const LOADING_INDICATOR_X_HORIZONTAL = 450;
const UI_BUTTON_TEXT_STYLE = { style: 'font-white', fontSize: '70px' };

// this config will get merged with the config passed upon construction
const DEFAULT_CONFIG = {
  viewMode: SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL,
  leaderboard: {
    name: 'totalStars',
    instanceId: 'default',
    entryLimit: null, // Max views
  },
  tabs: {
    topOffset: 210,
    dividerStroke: { thickness: 4, color: 0xFB3489, alpha: 1 },
    buttonGraphics: { selected: 'event-leaderboard-tab-on', unselected: 'event-leaderboard-tab-off' },
    buttonSpacing: 20,
    textStyle: { ...UI_BUTTON_TEXT_STYLE, offsetX: 0, offsetY: 4 },
  },
  panel: {
    offsetX: -285,
    topOffset: 275,
    additionalDelay: 250,
    lineSpacing: 85,
    userView: {
      // class: SpecialEvent_LeaderboardUserView, // should always extend SpecialEvent_LeaderboardUserView, which is the default value
      icon: {
        asset: 'star',
        size: 70,
        offsetX: 10,
        offsetY: -4,
      },
      // merged with RANK_FIELD_CONFIG
      rankField: {
        fontStyle: {},
      },
      // merged with USERNAME_FIELD_CONFIG
      usernameField: {
        fontStylePlayer: {},
        fontStyleOther: {},
      },
      // merged with SCORE_FIELD_CONFIG override
      scoreField: {
        fontStyle: {},
      },
      // merged with AVATAR_CONFIG
      avatar: {},
    },
    userViewDivider: {
      asset: 'event-leaderboard-divider',
      paddingTop: 5,
      paddingBottom: 2,
    },
  },
  closeBtn: {
    common: {
      topOffset: 45, scale: 0.9, asset: 'btn_x',
    },
    vertical: { rightOffset: -40 },
    horizontal: { rightOffset: -40 },
  },
  playBtn: {
    topOffset: 900, dimensions: { width: 196, height: 80 }, tint: BUTTONCOLOURS.green, textStyle: UI_BUTTON_TEXT_STYLE, placementTopOffset: 800,
  },
};

// msg field default config will be merged with config passed on _initCollectMessageField()
const DEFAULT_MSG_FIELD_CONFIG = {
  topOffset: 805,
  maxWidth: 400,
  maxHeight: 120,
  anchor: [0.5, 0.5],
  fontStyle: {
    style: 'font-white',
    fontSize: '30px',
    lineSpacing: -10,
  },
};

// hack for no ranks > 100 available
const RANDOM_RANK_RANGE = [101, 150]; let randomRank = 0;

/**
 * Base window for special event leaderboards
 */
export class Window_SpecialEventLeaderboard extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} config (optional) overrides / merges with DEFAULT_CONFIG
   */
  constructor(parent, config = null) {
    super(parent, config);
    this._init(config);
  }

  /**
  * disabled due to loading delay. call super._setOpenWindowAnimation() when ready.
  */
  _setOpenWindowAnimation() {
    this.alpha = 0;
  }

  _createConfig(config) {
    this._config = _.merge(_.cloneDeep(DEFAULT_CONFIG), config);
  }

  /**
   * async initialization
   */
  async _init(config) {
    this._createConfig(config);
    this._initLoadingIndicator();
    this._loadingIndicator.show();

    // load leadboards and determine default
    // this._friendsEntries = await this._loadFriendsLeaderboardEntries(this._config.leaderboard);
    // this._globalEntries = await this._loadGlobalLeaderboardEntries(this._config.leaderboard);

    // this._friendsEntries.length = this._globalEntries.length = 0;
    const friendsIsDefault = this._friendsEntries.length >= MIN_FRIENDS_FOR_FRIENDS_STATE;
    const leaderboardEntries = friendsIsDefault ? this._friendsEntries : this._globalEntries;

    this._initPlayButton();
    this._initLeaderboardPanel(this._config.panel, leaderboardEntries, this._config.viewMode);
    this._initHeaderGroup();
    this._initCloseButton();
    this._initLeaderboardSelectTabs(friendsIsDefault ? 'Friends' : 'Global');
    this._loadingIndicator.hide();

    this._openWindow();
  }

  _openWindow() {
    super._setOpenWindowAnimation(true);
  }

  /**
   * initialize loading indicator component
   */
  _initLoadingIndicator() {
    this._loadingIndicator = new UI_LoadingIndicatorDisplay();
    this._loadingIndicator.x = OMT.systemInfo.orientation === ORIENTATION.horizontal ? LOADING_INDICATOR_X_HORIZONTAL : LOADING_INDICATOR_X;
    this._loadingIndicator.y = game.height / 2;
    game.world.addChild(this._loadingIndicator);
  }

  /**
   * create the close button
   */
  _initCloseButton() {
    this.addCloseButton(0, 0, undefined, undefined, undefined, this._config.closeBtn.common.asset || undefined);
    this.closeButton.scale.set(this._config.closeBtn.common.scale);
  }

  /**
   * load event FRIENDS leaderboard entries
   * @param {Object} leaderboardConfig
   */
  async _loadFriendsLeaderboardEntries(leaderboardConfig) {
    const leaderboardEntries = await OMT.leaderboards.getFriendsLeaderboardEntries(leaderboardConfig.name, leaderboardConfig.instanceId);
    await this._appendYourEntryAndLimitUsers(leaderboardConfig, leaderboardEntries);
    return leaderboardEntries;
  }

  /**
   * load event GLOBAL leaderboard entries
   * @param {Object} leaderboardConfig
   */
  async _loadGlobalLeaderboardEntries(leaderboardConfig) {
    const leaderboardEntries = (await OMT.leaderboards.getGlobalLeaderboardEntries(
      leaderboardConfig.name, leaderboardConfig.instanceId, leaderboardConfig.entryLimit,
    )).slice();
    await this._appendYourEntryAndLimitUsers(leaderboardConfig, leaderboardEntries);
    this._TEMP_fillNullNames(leaderboardEntries);
    this._injectFriendAvatars(leaderboardEntries);
    return leaderboardEntries;
  }

  /**
   * insert yourelf into the list if you are not already there and limit list size
   * @param {Object} leaderboardConfig
   * @param {Array} leaderboardEntries
   */
  async _appendYourEntryAndLimitUsers(leaderboardConfig, leaderboardEntries) {
    const playerUserId = OMT.envData.settings.user.userId;
    const yourEntries = leaderboardEntries.filter((entry) => entry.id === playerUserId);
    let yourEntry;

    if (leaderboardEntries.length > MAX_USERS_TO_DISPLAY) leaderboardEntries.splice(MAX_USERS_TO_DISPLAY);

    if (yourEntries.length === 0) {
      // yourEntry = await OMT.leaderboards.getYourLeaderboardEntry(leaderboardConfig.name, leaderboardConfig.instanceId);
      // yourEntry.rank = leaderboardEntries.length <= 1 ? '?' : this._TEMP_getRandomFakeRank();
      // leaderboardEntries.pop(); leaderboardEntries.push(yourEntry);
    } else { // inject avatar image into existing entry
      yourEntry = yourEntries[0];
      yourEntry.image = OMT.envData.settings.user.avatar;
      const entriesInFinalList = leaderboardEntries.filter((entry) => entry.id === playerUserId);
      if (entriesInFinalList.length === 0) {
        leaderboardEntries[leaderboardEntries.length - 1] = yourEntry;
      }
    }
  }

  /**
   * inject friend avatar images into the global list
   * @param {Array.<Object>} globalEntries
   */
  _injectFriendAvatars(globalEntries) {
    for (const globalEntry of globalEntries) {
      for (const friendEntry of this._friendsEntries) {
        if (globalEntry.id === friendEntry.id) {
          globalEntry.image = friendEntry.image;
        }
      }
    }
  }

  /**
   * create the header group containing the title
   */
  _initHeaderGroup() {
    // override to create header group to align to top of the window
  }

  /**
   * called when leaderboard entries are loaded / ready to display
   * @param {Object} panelConfig
   * @param {Array.<Object>} leaderboardEntries
   * @param {SPECIALEVENT_LEADERBOARD_VIEWMODE} viewmode
   */
  _initLeaderboardPanel(panelConfig, leaderboardEntries, viewmode = SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL) {
    const leaderboardPanel = new SpecialEvent_LeaderboardUserListPanelScrollable(panelConfig, viewmode);
    this._leaderboardPanel = leaderboardPanel;
    leaderboardPanel.x = panelConfig.offsetX;
    leaderboardPanel.y = -(game.height / 2) + panelConfig.topOffset;
    this.addChild(leaderboardPanel);
    leaderboardPanel.initUserViews(leaderboardEntries);
    const showLeaderboardImmediately = viewmode === SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL;
    if (showLeaderboardImmediately) { // If normal, immediately animate
      this._animateLeaderboard();
      this._insertYourScoreDivider(leaderboardEntries);
    } else {
      leaderboardPanel.alpha = 0;
    }
  }

  /**
   * Animates the leaderboard panel
   */
  _animateLeaderboard() {
    this._leaderboardPanel.alpha = 1;
    this._leaderboardPanel.animateIn(() => {
      if (this._config.viewMode !== SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL) {
        this._leaderboardPanel.animatePlayerToPlacement(this._onScoresAnimationComplete.bind(this));
      } else {
        this._onScoresAnimationComplete();
      }
    });
  }

  /**
   * insert the divider for your score
   */
  _insertYourScoreDivider(leaderboardEntries) {
    if (leaderboardEntries.length <= 1) return;
    const { userViewDivider } = this._config.panel;
    if (!userViewDivider) return;

    // only show if finalEntry rank is not in order
    const finalEntry = leaderboardEntries[leaderboardEntries.length - 1];
    if (finalEntry.rank === leaderboardEntries.length) return;

    const dividerInstance = G.makeImage(0, 0, userViewDivider.asset, [0, 0.5]);
    const index = leaderboardEntries.length - 1;
    this._leaderboardPanel.insertHorizontalDivider(index, dividerInstance, userViewDivider.paddingTop, userViewDivider.paddingBottom);
  }

  /**
   * create the play button
   */
  _initPlayButton() {
    const { tint, dimensions, textStyle } = this._config.playBtn;
    const buttonText = this._config.viewMode === SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL ? 'Play' : 'Cool';
    this._playBtn = this._initUIButton(
      0, 0, buttonText,
      { tint, dimensions, textStyle },
      () => { this.closeWindow(); },
      true,
    );
    this._playBtn.visible = false;
  }

  /**
   * create friends / global leaderboard select buttons
   * @param {string} defaultTabName
   */
  _initLeaderboardSelectTabs(defaultTabName) {
    this._leaderboardTabs = new SpecialEvent_LeaderboardTabs(this._config.tabs, defaultTabName);
    this._leaderboardTabs.signals.onTabClicked.add((tabName) => {
      const entries = (tabName === 'Global') ? this._globalEntries : this._friendsEntries;
      this._leaderboardPanel.animateOut(() => {
        this._leaderboardPanel.destroy();
        this._initLeaderboardPanel(this._config.panel, entries);
      });
    });
    this.addChild(this._leaderboardTabs);
  }

  /**
   * create the play button
   */
  _initUIButton(x, y, label, buttonConfig, onClick, disableAfterClick = false) {
    const btn = new OMT_UI_SquareButton(x, y, {
      button: buttonConfig,
      text: {
        string: OMT.language.getText(label),
        textStyle: buttonConfig.textStyle,
        dimensionMods: { width: 0.9, height: 0.9 },
      },
      options: {
        clickFunction: {
          onClick,
          disableAfterClick,
        },
        cacheButton: false,
      },
    });
    this.registerButtons(btn);
    return btn;
  }

  /**
   * create the collect message field
   * @param {string} msgText
   * @param {Object} fieldConfig (optioanl)
   */
  _initCollectMessageField(msgText, fieldConfig) {
    this._collectFieldConfig = { ...DEFAULT_MSG_FIELD_CONFIG, ...fieldConfig };
    this._collectField = new G.Text(
      0,
      0,
      msgText,
      this._collectFieldConfig.fontStyle,
      this._collectFieldConfig.anchor,
      this._collectFieldConfig.maxWidth,
      this._collectFieldConfig.maxHeight,
      true,
      'center',
    );
    this._collectField.visible = false;
    this.addChild(this._collectField);
  }

  /**
   * on score lines animation complete fade in other elements
   */
  _onScoresAnimationComplete() {
    if (!this.game) return;

    this._leaderboardPanel.enable();
    let delay = 0;
    const elementsToFadeIn = [this._collectField, this._playBtn];
    for (const element of elementsToFadeIn) {
      if (!element || element.visible) continue;
      element.visible = true;
      element.alpha = 0;
      game.add.tween(element).to({
        alpha: 1,
      }, 500, Phaser.Easing.Quadratic.Out, true, delay);
      delay += 250;
    }
  }

  /**
   * get a random rank for users where the rank cannot be found.
   * TODO: this function and its variables should be removed when proper rank is available
   * @returns {number}
   */
  _TEMP_getRandomFakeRank() {
    if (randomRank === 0) {
      randomRank = RANDOM_RANK_RANGE[0] + Math.round(Math.random() * (RANDOM_RANK_RANGE[1] - RANDOM_RANK_RANGE[0]));
    }
    return randomRank;
  }

  /**
   * insert random names if any names are null
   * TODO: this can probably be removed since we are calling setUserProfileNow() on the GBC, but left in for real-world testing
   * @param {Array} leaderboardEntries
   */
  _TEMP_fillNullNames(leaderboardEntries) {
    const { nullNameReplacements } = this._config;
    if (!Array.isArray(nullNameReplacements) || nullNameReplacements.length === 0) return;
    const nameList = nullNameReplacements.slice();
    for (const entry of leaderboardEntries) {
      if (entry.name != null) continue;
      entry.name = OMT.language.getText(nameList.shift());
      nameList.push(entry.name);
    }
  }

  /**
   * resize window
   */
  resize() {
    const {
      closeButton,
      _playBtn: playBtn,
      _headerGroup: headerGroup,
      _leaderboardPanel: leaderboardPanel,
      _config: config,
      _collectField: collectField,
      _leaderboardTabs: leaderboardTabs,
    } = this;

    const topY = this.toLocal({ x: 0, y: 0 }).y; // top of screen

    if (closeButton) {
      closeButton.x = (game.width / 2) + config.closeBtn[OMT.systemInfo.orientationKey].rightOffset;
      closeButton.y = topY + config.closeBtn.common.topOffset;
    }

    if (headerGroup) {
      headerGroup.y = topY;
      headerGroup.resize();
    }

    if (leaderboardTabs) leaderboardTabs.y = topY + config.tabs.topOffset;

    if (leaderboardPanel) {
      leaderboardPanel.y = topY + config.panel.topOffset;
    }

    if (playBtn) {
      if (this._config.viewMode === SPECIALEVENT_LEADERBOARD_VIEWMODE.PLACEMENT) {
        playBtn.y = topY + config.playBtn.placementTopOffset;
      } else {
        playBtn.y = topY + config.playBtn.topOffset;
      }
    }
    if (collectField) collectField.y = topY + this._collectFieldConfig.topOffset;
  }

  update() {
    super.update();
    this.resize();
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._loadingIndicator.destroy();
  }

  get isFullscreen() {
    return false;
  }
}

if (!window.Windows) window.Windows = {};
Windows.specialEventLeaderboard = Window_SpecialEventLeaderboard;
