/* eslint-disable no-use-before-define */
export default class WorldMapSocialLayer extends Phaser.Group {
  /**
   * The pool group for the social part of the world map.
   * Shows a little avatar icon of friends on whatever level they're on
   *
   * @param {{hideSocial:boolean}} configOverride
   */
  constructor(configOverride) {
    super(game, null);

    this.state = game.state.getCurrentState();

    this._queueCheckTimer = undefined;
    this._queues = [];
    this._friendInThisSession = [];
    this._deadObjects = [];
    this._actives = [];

    if (configOverride.hideSocial) {
      this._hideSocial = true;
      return;
    }
    this._initLabels();
  }

  /**
   * Destroy!!
   */
  destroy() {
    for (const obj of this._deadObjects) {
      if (obj && obj.destroy) {
        obj.destroy();
      }
    }
    if (this._queueCheckTimer && this._queueCheckTimer.destroy) { // Destroy timer
      this._queueCheckTimer.destroy();
    }
    super.destroy();
  }

  /**
   * Collects friend data into objects that can be used easily
   */
  async _initLabels() {
    const userList = await OMT.friends.getFriendsList();

    for (let i = 0; i < userList.length; i++) {
      const user = userList[i];
      if (user.id === OMT.envData.settings.user.userId || user.maxLevel === undefined || !user.image) { continue; }
      if (this._friendInThisSession.find((friend) => friend.id === user.id)) { continue; }

      const data = {
        id: user.id,
        avatar: user.image,
        level: user.maxLevel,
      };
      this._friendInThisSession.push(data);
    }
  }

  /**
   * Adds the tile. Called from WorldMap2
   * @param {number} index
   * @param {Array<LevelNode>} levelNodes
   * @returns {Array<MapLabel> | null}
   */
  addTile(index, levelNodes) {
    if (this._hideSocial) { return null; } // Hide social does not do this call
    if (index > 0) { return null; } // Positive tile index? No need for it
    if (this._friendInThisSession.length === 0) { // Friends list not ready yet.
      this._queues.push({ index, levelNodes }); // Push to queue
      if (!this._queueCheckTimer) {
        this._queueCheckTimer = game.time.events.repeat(1000, -1, this._checkQueues.bind(this)); // Start a queue checker
      }
      return null;
    }
    let maxLevel; // Checks which levels are part of this tile
    let minLevel;
    for (const node of levelNodes) { // eslint-disable-line guard-for-in
      maxLevel = !Number.isNaN(maxLevel) ? Math.max(node.levelIndex, maxLevel) : node.levelIndex; // eslint-disable-line no-restricted-globals
      minLevel = !Number.isNaN(minLevel) ? Math.min(node.levelIndex, minLevel) : node.levelIndex; // eslint-disable-line no-restricted-globals
    }

    const consideringPack = {}; // Creates an object that has friends in many levels
    for (const friend of this._friendInThisSession) {
      const adjustedFriendLevel = friend.level - 1; // Because level node displays are counted starting at 1
      if (adjustedFriendLevel >= minLevel && adjustedFriendLevel <= maxLevel) {
        if (!consideringPack[adjustedFriendLevel]) {
          consideringPack[adjustedFriendLevel] = [];
        }
        consideringPack[adjustedFriendLevel].push(friend);
      }
    }

    const rtrArr = []; // Return array
    for (const node of levelNodes) { // eslint-disable-line guard-for-in
      if (consideringPack[node.levelIndex]) {
        const element = this._getFreeElement();
        element.init(index, consideringPack[node.levelIndex]);
        element.x = node.x + node.width + 25;
        element.y = node.y;

        this.addChild(element);
        rtrArr.push(element);
        this._actives.push(element);
      }
    }

    return rtrArr;
  }

  /**
   * Checks queues if theres anything that should pop up for start up
   */
  _checkQueues() {
    if (!this.game) { this._removeQueues(); return; }
    if (this._hideSocial) { this._removeQueues(); return; }
    if (this._queues.length > 0 && this._friendInThisSession.length > 0) {
      for (const queueEntry of this._queues) {
        if (!queueEntry.levelNodes.find((node) => !node)) { // None of the nodes are nulled
          this.addTile(queueEntry.index, queueEntry.levelNodes);
        }
      }
      this._removeQueues();
    }
  }

  /**
   * Removes all queues
   */
  _removeQueues() {
    this._queues.length = 0;
    if (this._queueCheckTimer && this._queueCheckTimer.destroy) {
      this._queueCheckTimer.destroy();
    }
  }

  /**
   * Kills all level nodes in the tile index
   * @param {number} index
   */
  killTile(index) {
    const allIndex = this._actives.filter((mapLabel) => mapLabel.tileIndex === index);
    for (const mapLabel of allIndex) {
      mapLabel.kill();
      this._actives.splice(this._actives.indexOf(mapLabel), 1);
    }
  }

  /**
   * Moves tiles based on deltaY
   * @param {number} deltaY
   */
  moveTiles(deltaY) {
    // update active segments
    for (let i = this._actives.length - 1; i >= 0; i--) {
      this._actives[i].y += deltaY;
    }
  }

  /**
   * Returns a free element
   */
  _getFreeElement() {
    let element;

    if (this._deadObjects.length > 0) {
      element = this._deadObjects.pop();
    } else {
      element = new MapLabel();
      element.events.onKilled.add(this._onElementKilled, this);
    }

    this.add(element, true);
    return element;
  }

  /**
   * Kills the object and puts it in the dead array
   * @param {BasicNode} elem
   */
  _onElementKilled(elem) {
    if (this !== elem.parent) return;
    this._deadObjects.push(elem);
    this.removeChild(elem);
  }

  /**
   * Change the visibility of this pool group
   * @param {boolean} state The visibility state
   */
  setVisibility(state) {
    if (state) {
      this.alpha = 1;
    } else {
      this.alpha = 0;
    }
  }
}

class MapLabel extends Phaser.Image {
  /**
   * The Image group that has the avatars next to the level node
   */
  constructor() {
    super(game, null);
  }

  /**
   * Initlizes the node. Draws a bunch of avatars
   * @param {number} tileIndex
   * @param {Array<{avatar:string, id:string, level:number}>} friends
   */
  init(tileIndex, friends) {
    this.tileIndex = tileIndex;
    const targetWidth = 50;
    let offsetX = (targetWidth / 2) * Math.min(friends.length, 2);
    let friendCount = 0;
    for (const friend of friends) { // eslint-disable-line guard-for-in
      const avatarPack = new Phaser.Group(game, this);
      avatarPack.x = offsetX;
      avatarPack.scale.set(0);
      if (friendCount > 2) {
        const badge = new Phaser.Graphics(game);
        badge.beginFill(0xFFFFFF);
        badge.drawCircle(0, 0, targetWidth);
        avatarPack.addChild(badge);
        badge.x += (targetWidth * 2.5);
        const text = new G.Text(badge.x, 4, `+${(friends.length - 3)}`, 'font-blue', 0.5, targetWidth, targetWidth);
        avatarPack.addChild(text);
        game.add.tween(avatarPack.scale).to({ x: 1, y: 1 }, 500, Phaser.Easing.Elastic.Out, true);
        break;
      } else {
        G.makeExtImage(0, 0, friend.avatar, null, 0.5, avatarPack, false, (img) => {
          if (img && this.game) {
            img.width = targetWidth;
            img.height = targetWidth;
            game.add.tween(avatarPack.scale).to({ x: 1, y: 1 }, 500, Phaser.Easing.Elastic.Out, true);
          }
        }, 500, Phaser.Easing.Elastic.Out, true);
        G.makeImage(0, 0, 'avatar_frame', 0.5, avatarPack);
        friendCount++;
      }
      offsetX -= targetWidth / 2;
    }
    this.revive();
  }

  /**
   * Kill! And removes children
   */
  kill() {
    this.removeChildren();
    super.kill();
  }
}
