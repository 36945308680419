import { CollapsedViewBase } from '../../Components/CollapsedView/CollapsedViewBase';
import { LifeRequestCollapsedUserView, LIFE_REQUEST_STATUS } from './LifeRequestCollapsedUserView';

import {
  LIFE_PANEL_COLLAPSED_SCROLL_RECT,
  LIFE_PANEL_COLLAPSED_USER_VIEW_H_SPACING,
  LIFE_PANEL_COLLAPSED_OVERLAY_BG_RECT,
  LIFE_PANEL_COLLAPSED_OVERLAY_BG_COLOR,
} from '../../MainLeaderboardSettings';

/**
 * class for collapsed reuest / send lives user list
 */
export class LifeRequestCollapsed extends CollapsedViewBase {
  /**
   * main initialization function
   * @param {Array} userList friendData list
   * @returns {Promise}
   */
  async init(userList) {
    const scrollRect = new Phaser.Rectangle(0, 0, LIFE_PANEL_COLLAPSED_SCROLL_RECT.width, LIFE_PANEL_COLLAPSED_SCROLL_RECT.height);
    const hSpacing = LIFE_PANEL_COLLAPSED_USER_VIEW_H_SPACING;
    await super.init(userList, scrollRect, hSpacing);
    this._scrollArea.x = LIFE_PANEL_COLLAPSED_SCROLL_RECT.x;
    this._scrollArea.y = LIFE_PANEL_COLLAPSED_SCROLL_RECT.y;

    // draw the overlay bg for buttons etc
    this._drawOverlayBGRect(LIFE_PANEL_COLLAPSED_OVERLAY_BG_RECT, LIFE_PANEL_COLLAPSED_OVERLAY_BG_COLOR);

    this.disable();
  }

  /**
   * intialize UserView instance
   * @param {Object} friendData
   * @returns {Phaser.Group}
   */
  _initUserViewInstance(friendData) {
    const userView = new LifeRequestCollapsedUserView();
    userView.init(friendData);
    userView.signals.onLifeRequest.add(this._onCoinRequestStatusUpdate, this);
    return userView;
  }

  /**
   * lock the display while request is in progress
   * @param {FriendsScoresExpandedUserView} userView
   * @param {number} status
   */
  _onCoinRequestStatusUpdate(userView, status) {
    if (status === LIFE_REQUEST_STATUS.REQUESTED) {
      this.disable();
    } else if (status === LIFE_REQUEST_STATUS.SUCCESS) {
      this.enable();
    } else if (status === LIFE_REQUEST_STATUS.FAILED) {
      this.enable();
    }
  }
}
