/**
 * class for displaying manual candy selection. Used for swap action atm.
 */
export class CandySelectDisplay extends Phaser.Image {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    super(game, 0, 0, null, 0.5);

    this._board = board;
    this.alpha = 0;
    game.add.existing(this);
    this.scale = this._board.scale;

    this._initSelectHighlight();
  }

  /**
   * init the select highlight graphics
   */
  _initSelectHighlight() {
    this._selectHighlight = G.makeImage(0, 0, 'selected_cookie_new', 0.5, this);
    game.add.tween(this._selectHighlight.scale)
      .to({ x: 1.2, y: 1.2 }, 800, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  }

  /**
   * highlight / select the specified candy
   * @param {Candy} candy
   */
  selectCandy(candy) {
    this.alpha = 1;
    this.x = this._board.candiesLayer.x + (candy.x * this._board.scale.x);
    this.y = this._board.candiesLayer.y + (candy.y * this._board.scale.y);
  }

  /**
   * hide the display
   */
  hide() {
    this.alpha = 0;
  }
}
