import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { Window } from '../../00_IMMEDIATE/Window';
import TreasureHuntManager from '../../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';
import { OMT_AssetLoader } from '../../Services/OMT/OMT_AssetLoader';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

export default class Window_TreasureHuntWaiting extends Window {
  constructor(parent) {
    super(parent);

    this._init();
  }

  /**
   * Async init because we need assets to load before we can show this...
   */
  async _init() {
    const result = await OMT_AssetLoader.getInstance().waitOnSecondaryImages(['treasureHunt']);
    if (!result) { this.closeWindow(); }
    this.alpha = 0;
    this.onFinishedEnter.removeAll();
    this._drawWindow();
    this._drawContents();
    this._drawContinue();
    this._setOpenWindowAnimation();
  }

  /**
   * Draws the main window elements
   */
  _drawWindow() {
    this._container = new Phaser.Group(game, this);
    this._mascotContainer = new Phaser.Group(game, this._container); // Behind the bg
    this._bg = G.makeImage(0, 0, 'popup_background_treasureHunt', 0.5, this._container);

    // Has to be behind banner
    this._initMascot();

    // banner
    this._banner = G.makeImage(
      0, -265,
      'treasureHunt_banner',
      0.5,
      this._container,
    );

    // title text
    const mascot = G.saveState.treasureHuntManager.currentMascot;
    this._mascotName = TreasureHuntManager.getMascotName(mascot);
    this._titleTxt = new G.Text(0, -267, OMT.language.getText(`${this._mascotName}'s Treasure Hunt`), 'treasureHunt-headerTitleText', 0.5, 330, 125, true, 'center');
    this._container.add(this._titleTxt);
  }

  /**
   * Draws the mascot and positions according to orientation
   */
  _initMascot() {
    const mascot = new Phaser.Group(game, this._mascotContainer);
    const mascotNumber = G.saveState.treasureHuntManager.currentMascot;
    G.makeImage( // Mascot image
      G.OMTsettings.treasureHuntSuper.levelWindow.mascot.offsetX,
      G.OMTsettings.treasureHuntSuper.levelWindow.mascot.offsetY,
      `treasureHunt_prelevelMascot${mascotNumber}`, 0.5, mascot,
    );
    if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
      mascot.x += this._bg.x - (this._bg.width - mascot.width) / 2;
      this._mascotContainer.y = -400;
    } else {
      // Big, on the side
      const gingyData = G.OMTsettings.treasureHuntSuper.waitingWindow.gingyPos;
      mascot.scale.set(gingyData.scale);
      mascot.x = gingyData.x;
      mascot.y = gingyData.y;
    }
  }

  /**
   * Draws the contents of the window
   */
  _drawContents() {
    const timeLeft = G.saveState.treasureHuntManager.timeNextStart;
    const startDate = new Date(timeLeft);
    const now = new Date();
    const daysLeft = startDate.getDate() - now.getDate();
    const targetText = `Coming in %TIME% day${daysLeft === 1 ? '' : 's'}!`;
    const t_daysLeft = this._makeText(OMT.language.getText(targetText).replace('%TIME%', daysLeft));
    t_daysLeft.y = -134;
    this._container.addChild(t_daysLeft);

    const t_isComing = this._makeText(OMT.language.getText(`${this._mascotName}'s Treasure Hunt is almost here`));
    t_isComing.y = -40;
    this._container.addChild(t_isComing);

    const padding = 4;
    const layoutDetails = {
      totalWidth: 0,
      alignment: [],
    };
    this._makeAssetArrowHorizontal(['treasureHuntActive', 'treasureHunt_chestMedium2', `treasureHuntGold${this._mascotName}Full`], layoutDetails, padding);
    this._arrangeVisuals(layoutDetails, padding, -230);

    const t_subscribe = this._makeText(OMT.language.getText('Turn on messages to be notified when the Treasure Hunt starts.'), 150);
    t_subscribe.y = 223;
    this._container.addChild(t_subscribe);
  }

  /**
   * Makes a text.
   * They all use the same style and I was kinda lazy.
   * @param {string} text
   * @param {number} height
   * @returns {G.Text}
   */
  _makeText(text, height = null) {
    return new G.Text(0, 0, text, 'treasureHunt-hintWindowTitle', 0.5, this._bg.width * 0.8, height, true, 'center');
  }

  /**
   * Makes assets in a line with an arrow between it
   * @param {Array<String>} arr
   * @param {{totalWidth:number, alignment:Array<Phaser.DisplayObject>}} layoutDetails
   * @param {number} padding
   */
  _makeAssetArrowHorizontal(arr, layoutDetails, padding) {
    for (let i = 0; i < arr.length; i++) {
      const asset = G.makeImage(0, 0, arr[i], 0.5, this._container);
      if (asset.width > 120) {
        asset.scale.set(120 / asset.width);
      }
      layoutDetails.totalWidth += asset.width;
      layoutDetails.alignment.push(asset);

      if ((i + 1) < arr.length) {
        const arrow = G.makeImage(0, 0, 'treasureHunt_yellowArrow', 0.5, this._container);
        layoutDetails.totalWidth += arrow.width + padding * 2;
        layoutDetails.alignment.push(arrow);
      }
    }
  }

  /**
   * Lays out everything in horizontal alignment
   * @param {{totalWidth:number, alignment:Array<Phaser.DisplayObject>}} layoutDetails
   * @param {number} padding
   */
  _arrangeVisuals(layoutDetails, padding, startX) {
    let nextX = startX;
    for (const obj of layoutDetails.alignment) {
      obj.x = Math.round(nextX + padding + obj.width / 2);
      obj.y = 90;
      nextX += obj.width + padding;
    }
  }

  /**
   * Draws the continue button
   */
  _drawContinue() {
    this._continueButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 220,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Cool'),
        textStyle: {
          style: 'font-white',
          fontSize: 70,
        },
        dimensionMods: {
          width: 0.9,
          height: 0.9,
        },
      },
      options: {
        clickFunction: {
          onClick: this._closeWindow.bind(this),
          disableAfterClick: true,
        },
        cacheButton: false,
      },
    });
    this._continueButton.y = this._bg.y + this._bg.height / 2;
    this._container.addChild(this._continueButton);
    this._continueButton.visible = true;
  }

  /**
   * Close window, but not before attempting to subscribe!
   */
  async _closeWindow() {
    // await OMT.bots.checkStateAndAttemptSubcribe();
    this.closeWindow();
  }
}

if (!window.Windows) window.Windows = {};
Windows.treasureHuntWaiting = Window_TreasureHuntWaiting;
