import TutorialNewGingy from './G.TutorialNewGingy';
import ArrayUtil from '@omt-components/Utils/ArrayUtil';

const MIN_MATCH_DISPLAY_LENGTH = 6; // 3 sets of coordinates
const MIN_ROW_FOR_TOP_MSG = 1;
const MIN_GAME_HEIGHT_FOR_BTM_MSG = 1080;

export class TournamentFTUETutorial extends TutorialNewGingy {
  /**
   * constructor
   */
  constructor() {
    super();

    // find desired move to display
    const { board } = game.state.getCurrentState();
    const move = this._findMoveToDisplay(board);

    // fill in tutorial with dynamic data
    this.tutorialID = 'tournamentFTUETutorial';
    const tutorialData = G.Utils.clone(G.json.tutorials[this.tutorialID]);
    tutorialData.steps[0].handCells = move.slice();
    tutorialData.steps[0].inputCells = move.slice();

    // append resulting match to the clear cells for the tutorial
    const clearCells = move.slice();
    this._appendCellsForResultingMatch(board, move, clearCells);
    tutorialData.steps[0].overlayTask[0] = ['clearCells', clearCells];

    this._checkMessageAlignment(move, tutorialData);

    // intialize the tutorial
    this.initTutorial(tutorialData);
  }

  /**
   * checks alignment and shifts messages to bottom if it will overlap the board match
   * @param {Array} move
   * @param {Object} tutorialData
   */
  _checkMessageAlignment(move, tutorialData) {
    if ((move[1] <= MIN_ROW_FOR_TOP_MSG || move[3] <= MIN_ROW_FOR_TOP_MSG) && game.height < MIN_GAME_HEIGHT_FOR_BTM_MSG) {
      for (const step of tutorialData.steps) {
        if (step.msg) step.msg.position = 'bottom';
      }
    }
  }

  /**
   * append cells to cellList resulting from move / match
   * @param {Board} board
   * @param {Array} move
   * @param {Array} cellList
   */
  _appendCellsForResultingMatch(board, move, cellList) {
    const fromCandy = board.getCandy(move[0], move[1]);
    const toCandy = board.getCandy(move[2], move[3]);
    // temporarily swap candies and gather cells from resulting matches
    board.candiesLayer.swapCandies(fromCandy, toCandy);
    const horizontalMatches = board.matcher.getHorizontalMatchPos(fromCandy, true);
    const verticalMatches = board.matcher.getVerticalMatchPos(fromCandy, true);
    if (horizontalMatches.length >= MIN_MATCH_DISPLAY_LENGTH) cellList.push(...horizontalMatches);
    if (verticalMatches.length >= MIN_MATCH_DISPLAY_LENGTH) cellList.push(...verticalMatches);
    board.candiesLayer.swapCandies(fromCandy, toCandy);
  }

  /**
   * find a move to display for the tutorial
   * @param {Board} board
   * @returns {Array}
   */
  _findMoveToDisplay(board) {
    const possibleMoves = board.matcher.checkPossibleMoves();
    this._filterPossibleMoves(board, possibleMoves);
    const closestMoves = this._getMovesClosestScreenCenter(board, possibleMoves);
    const move = ArrayUtil.getRandomElement(closestMoves);
    return move;
  }

  /**
   * filters out move list to the most desireable for this tutorial
   * @param {Board} board
   * @param {Array} possibleMoves
   */
  _filterPossibleMoves(board, possibleMoves) {
    let fromCandy; let toCandy;
    const normalMoves = [];
    const movesWithSpecials = [];
    const movesWithBombs = [];
    for (const move of possibleMoves) {
      fromCandy = board.getCandy(move[0], move[1]);
      toCandy = board.getCandy(move[2], move[3]);
      // seperate moves into 3 lists
      if (fromCandy.hasSpecialType() || toCandy.hasSpecialType()) {
        if (fromCandy.getSpecialTypeName() === 'spiral' || toCandy.getSpecialTypeName() === 'spiral') { // moves with bombs
          movesWithBombs.push(move);
        } else { // moves with specials other then bombs
          movesWithSpecials.push(move);
        }
      } else { // normal moves, no specials
        normalMoves.push(move);
      }
    }
    // modify the passed in Array to only the filtered results
    possibleMoves.length = 0;
    if (normalMoves.length > 0) possibleMoves.push(...normalMoves);
    else if (movesWithSpecials.length > 0) possibleMoves.push(...movesWithSpecials);
    else if (movesWithBombs.length > 0) possibleMoves.push(...movesWithBombs);
  }

  /**
   * get a list of moves that are the closest to the screen center
   * @param {Board} board
   * @param {Array} possibleMoves
   * @returns {Array}
   */
  _getMovesClosestScreenCenter(board, possibleMoves) {
    const screenCenterX = game.width / 2;
    const screenCenterY = game.height / 2;

    let toCandy;
    const closestMoves = [];
    let distance = 0; let shortestDistance = Infinity;

    for (const move of possibleMoves) {
      toCandy = board.getCandy(move[2], move[3]);
      // check the moves disance to the screen center
      distance = Phaser.Math.distance(
        screenCenterX,
        screenCenterY,
        board.x + toCandy.x,
        board.y + toCandy.y,
      );
      distance = Math.round(distance);
      // if equal or less the the shortest distance store the move
      if (distance <= shortestDistance) {
        if (distance < shortestDistance) {
          shortestDistance = distance;
          closestMoves.length = 0;
        }
        closestMoves.push(move);
      }
    }
    return closestMoves;
  }
}
