import EventPostcard, { POSTCARD_STATE } from './EventPostcard';
import { Window } from '../../../00_IMMEDIATE/Window';
import OMT_UI_DrawUtil from '../../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import { WINDOW_LAYER_NAMES } from '../../WindowMgr';
import EventPostcard_Util from './EventPostcard_Util';

/**
 * A separate window class for the event Postcard promo.
 * Only activated at certain times. Please check featureUnlock.js or OMT.feature.getEventPostcardFeature()
 */
class Window_eventPostcardPromo extends Window {
  /**
   * @param {??} parent Default window args
   * @param {Object} returnArgs An object package that will re-open the a window that was given to it, as well as its args and layer
   * @param {string} returnArgs.windowName The window to re-open to
   * @param {Array<any>} returnArgs.args The args to go to that window
   * @param {string} returnArgs.layerName The layer for that window
   */
  constructor(parent, returnArgs) {
    super(parent);

    this._returnArgs = returnArgs || {}; // The return arguments for re-opening a window
    this._pushWindowAlreadyDispatched = false; // Flag to avoid pushing the window twice

    this._init();

    if (game.state.current === 'World' || this._returnArgs.layerName) {
      this.y -= G.WindowMgr.Constants.WorldVerticalOffset;
    }

    this._signalBinding = G.sb('onScreenResize').add(this._onResize, this);
    this._onResize();
  }

  /**
   * Re-opens the given window. Doesn't open it if another window has been queued already (from the same class)
   */
  closeWindow() {
    if (this._returnArgs && this._returnArgs.layerName && !this._pushWindowAlreadyDispatched) {
      G.sb('pushWindow').dispatch([this._returnArgs.windowName].concat(this._returnArgs.args), false, this._returnArgs.layerName);
    }
    super.closeWindow();
  }

  destroy() {
    if (this._signalBinding) {
      if (this._signalBinding.detach) {
        this._signalBinding.detach();
      }
      this._signalBinding = null;
    }
    super.destroy();
  }

  /**
   * Repositions everything
   */
  _onResize() {
    EventPostcard_Util.redrawGradients(this._headerContainer);

    this._headerContainer.titleAsset.x = this._headerContainer.gradientData.titleBg.image.x; // + this._headerContainer.gradientData.titleBg.image.width;
    this._headerContainer.titleAsset.y = this._headerContainer.gradientData.titleBg.image.y + this._headerContainer.gradientData.titleBg.image.height * 0.7;
    this._headerContainer.addChild(this._headerContainer.titleAsset);

    G.Text.setMaxWidth(this._headerContainer.titleText, this._headerContainer.gradientData.titleBg.image.width * 0.9);
    this._headerContainer.titleText.setText(this._headerContainer.titleText.text);
    this._headerContainer.titleText.x = this._headerContainer.gradientData.titleBg.image.x + this._headerContainer.gradientData.titleBg.image.width / 2;
    this._headerContainer.titleText.y = this._headerContainer.gradientData.titleBg.image.y + 5 + this._headerContainer.gradientData.titleBg.image.height / 2;
    this._headerContainer.addChild(this._headerContainer.titleText);

    this._headerContainer.x = -game.width / 2;
    this._headerContainer.y = -(game.height) / 2;

    this._closeBtn.x = -(this._closeBtn.width / 4) + (game.width - this._closeBtn.width) / 2;
    this._closeBtn.y = (this._closeBtn.height / 4) + (this._closeBtn.height - game.height) / 2;
  }

  /**
   * Initializes things
   */
  _init() {
    this._closeBtn = new G.Button(0, 0, 'btn_x', this.closeWindow.bind(this)); // Close button

    this._headerContainer = EventPostcard_Util.drawPostcardFrame();
    this.addChild(this._headerContainer);

    const targetWidth = 400; // Card width
    const imgContainer = new Phaser.Group(game, null); // Card image
    const promoBg = G.makeImage(0, 0, 'eventPostcard_PromoBg', 0.5, imgContainer);
    const gift = G.makeImage(0, ((targetWidth * 1.25) / 2) * 0.3, 'gift_bottom', 0.5, imgContainer);
    const prizeContainer = OMT_UI_DrawUtil.drawDynamicPrizes({
      iconDimension: {
        x: 0,
        y: 0,
        width: 75,
        height: 75,
      },
      width: promoBg.width,
      prizes: G.OMTsettings.postcardEvent.eventGift,
      textStyle: { style: 'eventPostcard-title', fontSize: 48 },
    });
    prizeContainer.y = 0; // Math.round(promoBg.y - promoBg.height * 0.4); // The target end position for the prize
    imgContainer.addChild(prizeContainer);
    const giftLid = G.makeImage(0, gift.y, 'gift_top', 0.5, imgContainer);
    const animateGifts = () => {
      const prizeY = prizeContainer.y;
      prizeContainer.scale.set(0.01);
      prizeContainer.y = gift.y;
      const normalGiftY = gift.y;
      const downGiftY = normalGiftY + 5;
      const targetLidY = giftLid.y - 200;
      const downLidY = giftLid.y + 5;
      const tw = game.add.tween(gift.scale)
        .to({ x: 1.1, y: 0.9 }, 200, Phaser.Easing.Sinusoidal.In, false); // Squash and squish
      tw.onStart.add(() => {
        game.add.tween(giftLid.scale)
          .to({ x: 1.1, y: 0.95 }, 200, Phaser.Easing.Sinusoidal.In, true); // Squash and squish
        game.add.tween(gift)
          .to({ y: downGiftY }, 200, Phaser.Easing.Sinusoidal.In, true); // Downed
        game.add.tween(giftLid)
          .to({ y: downLidY }, 200, Phaser.Easing.Sinusoidal.In, true); // Downed
      });
      tw.onComplete.add(() => {
        game.add.tween(gift)
          .to({ y: normalGiftY }, 1000, Phaser.Easing.Sinusoidal.Out, true); // To normal
        game.add.tween(gift.scale)
          .to({ x: 1, y: 1 }, 400, Phaser.Easing.Sinusoidal.Out, true);
        game.add.tween(prizeContainer)
          .to({ y: prizeY }, 1000, Phaser.Easing.Sinusoidal.InOut, true); // Tween out
        game.add.tween(prizeContainer.scale)
          .to({ x: 1, y: 1 }, 1000, Phaser.Easing.Sinusoidal.InOut, true); // Tween out
        game.add.tween(giftLid.scale)
          .to({ x: 0.85, y: 0.85 }, 1000, Phaser.Easing.Sinusoidal.InOut, true); // Tween out
        game.add.tween(giftLid)
          .to({ y: targetLidY, alpha: 0 }, 1000, Phaser.Easing.Sinusoidal.InOut, true) // Tween out
          .onComplete.add(() => {
            giftLid.visible = false;
          });
      });

      game.time.events.add(250, () => {
        if (tw) {
          tw.start();
        }
      });
    };

    this._card = new EventPostcard({ width: targetWidth, height: targetWidth * 1.25 }); // Card itself
    const cardData = {
      state: POSTCARD_STATE.CLAIM,
      index: 0,
      cardTitle: G.OMTsettings.postcardEvent.cardData.title,
      promo: {
        caption: G.OMTsettings.postcardEvent.cardData.promoCaption,
        image: imgContainer,
      },
    };
    this._card.fillCard(cardData);
    this.addChild(this._card);

    this._closeBtn = new G.Button(0, 0, 'white_x', this.closeWindow.bind(this));
    this.addChild(this._closeBtn);

    if (!this._card.signals.onClick.has(this._onButtonClick, this)) {
      this._card.signals.onClick.add(this._onButtonClick, this);
    }
    animateGifts();
  }

  /**
   * Claims the prize and moves on
   * @param {EventPostcard} card
   */
  _onButtonClick(card) { // eslint-disable-line no-unused-vars
    this._closeBtn.visible = false; // Not allowed to close

    // Yummy prize!
    const trackerArray = [];
    const prize = G.OMTsettings.postcardEvent.eventGift;
    let coinsPosition = null;
    try {
      coinsPosition = this.state.panel.coinsTxt;
    } catch (err) {
      // Probably not in the saga map state. Cannot find the coins text. Spoof in a location where the coins text would have been.
      // Also probably doesn't look good on wide screen tablets
      coinsPosition = {
        worldPosition: new Phaser.Point(game.world.bounds.x + game.width * 0.8, game.height * 0.05),
      };
    }

    for (let i = 0; i < prize.length; i++) {
      const curPrize = prize[i];
      trackerArray.push([curPrize.prize, curPrize.amount]); // Puts into an array for data tracking

      switch (curPrize.prize) {
        case 'coin':
          this.state.uiTargetParticles.createCoinBatch( // Show bling
            game.world.bounds.x + this.worldPosition.x,
            this.worldPosition.y,
            coinsPosition,
            curPrize.amount,
            false,
          );
          break;
        case 'life':
          G.saveState.addLife(curPrize.amount); // Add life
          break;
        default: { // Perhaps bad, but default is assuming booster
          const boosterIndex = parseInt(curPrize.prize[8], 10);
          G.saveState.changeBoosterAmount(boosterIndex, curPrize.amount); // Add in booster
          break;
        }
      }
    }

    // A flag for this is placed as an msg.
    G.saveState.markMsgAsRead('eventMsg', G.OMTsettings.postcardEvent.eventId); // Saves here
    // DDNA.transactionHelper.trackRewards(trackerArray, [], { // DDNA tracking
    //   transactionType: 'REWARD',
    //   tActionType: 'EVENT_REWARD',
    //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
    // });

    game.time.events.add(1500, () => {
      this._pushWindowAlreadyDispatched = true; // Prevents window from being pushed again
      const returnLayer = this._returnArgs.layerName ? this._returnArgs.layerName : WINDOW_LAYER_NAMES.Base;
      G.sb('pushWindow').dispatch(['eventPostcard', this._returnArgs], false, returnLayer);
      this.closeWindow();
    });
  }
}

// create global references
// if (!window.Windows) window.Windows = {};
// Windows.eventPostcardPromo = Window_eventPostcardPromo;
