/* eslint-disable operator-linebreak */
/* eslint-disable func-names */
if (typeof G === 'undefined') G = {};

G.ExtLoader = function () {
  Phaser.Loader.call(this, game);
  game.state.onStateChange.add(this.reset, this);
  this.imagesToRemoveOnStateChange = [];
  this.loadInfoPerUrl = {};
};

G.ExtLoader.prototype = Object.create(Phaser.Loader.prototype);

G.ExtLoader.prototype.reset = function (hard, clearEvents) {
  this.imagesToRemoveOnStateChange.forEach(function (key) {
    this.cache.removeImage(key);
  }, this);
  this.imagesToRemoveOnStateChange = [];

  Phaser.Loader.prototype.reset.call(this, hard, clearEvents);
};

G.ExtLoader.prototype.addToFileList = function (
  type,
  key,
  url,
  properties,
  overwrite,
  extension,
) {
  if (overwrite === undefined) {
    overwrite = false;
  }

  if (key === undefined || key === '') {
    console.warn(`Phaser.Loader: Invalid or no key given of type ${type}`);
    return this;
  }

  if (url === undefined || url === null) {
    if (extension) {
      url = key + extension;
    } else {
      console.warn(
        `Phaser.Loader: No URL given for file type: ${type} key: ${key}`,
      );
      return this;
    }
  }

  const file = {
    type,
    key,
    path: this.path,
    url,
    syncPoint: this._withSyncPointDepth > 0,
    data: null,
    loading: false,
    loaded: false,
    error: false,
  };

  if (properties) {
    for (const prop of Object.keys(properties)) {
      file[prop] = properties[prop];
    }
  }

  const fileIndex = this.getAssetIndex(type, key);

  if (overwrite && fileIndex > -1) {
    const currentFile = this._fileList[fileIndex];

    if (!currentFile.loading && !currentFile.loaded) {
      this._fileList[fileIndex] = file;
    } else {
      this._fileList.push(file);
      this._totalFileCount++;
    }
  } else if (fileIndex === -1) {
    this._fileList.push(file);
    this._totalFileCount++;
  }

  this.loadFile(this._fileList.shift());

  return this;
};

/** @override */
G.ExtLoader.prototype.asyncComplete = function (file, errorMessage) {
  if (errorMessage === undefined) {
    errorMessage = '';
  }

  file.loaded = true;
  file.error = !!errorMessage;

  if (errorMessage) {
    file.errorMessage = errorMessage;

    console.warn(`Phaser.Loader - ${file.type}[${file.key}]: ${errorMessage}`);

    // == custom code ==
    // HACK: Somehow phaser's loader doesn't dispatch error
    this.onFileError.dispatch(file.key, file);
    // =================
  }

  // == custom code ==
  // return;
  // =================

  // this.processLoadQueue();
};

G.ExtLoader.prototype.fileComplete = function (file, xhr) {
  // let loadNext = true;

  switch (file.type) {
    case 'packfile': {
      // Pack data must never be false-ish after it is fetched without error
      const data = JSON.parse(xhr.responseText);
      file.data = data || {};
      break;
    }

    case 'image':
      this.cache.addImage(file.key, file.url, file.data);
      break;

    case 'spritesheet':
      this.cache.addSpriteSheet(
        file.key,
        file.url,
        file.data,
        file.frameWidth,
        file.frameHeight,
        file.frameMax,
        file.margin,
        file.spacing,
      );
      break;

    case 'textureatlas':
      if (file.atlasURL == null) {
        this.cache.addTextureAtlas(
          file.key,
          file.url,
          file.data,
          file.atlasData,
          file.format,
        );
        //  Load the JSON or XML before carrying on with the next file
      } else if (
        file.format === Phaser.Loader.TEXTURE_ATLAS_JSON_ARRAY ||
        file.format === Phaser.Loader.TEXTURE_ATLAS_JSON_HASH ||
        file.format === Phaser.Loader.TEXTURE_ATLAS_JSON_PYXEL
      ) {
        this.xhrLoad(
          file,
          this.transformUrl(file.atlasURL, file),
          'text',
          this.jsonLoadComplete,
        );
      } else if (file.format === Phaser.Loader.TEXTURE_ATLAS_XML_STARLING) {
        this.xhrLoad(
          file,
          this.transformUrl(file.atlasURL, file),
          'text',
          this.xmlLoadComplete,
        );
      } else {
        throw new Error(
          `Phaser.Loader. Invalid Texture Atlas format: ${file.format}`,
        );
      }
      break;

    case 'bitmapfont':
      if (!file.atlasURL) {
        this.cache.addBitmapFont(
          file.key,
          file.url,
          file.data,
          file.atlasData,
          file.atlasType,
          file.xSpacing,
          file.ySpacing,
        );
      } else {
        //  Load the XML before carrying on with the next file
        // loadNext = false;
        this.xhrLoad(
          file,
          this.transformUrl(file.atlasURL, file),
          'text',
          function (_file, _xhr) {
            let json;

            try {
              // Try to parse as JSON, if it fails, then it's hopefully XML
              json = JSON.parse(_xhr.responseText);
            } catch (e) {
              //
            }

            if (json) {
              _file.atlasType = 'json';
              this.jsonLoadComplete(_file, _xhr);
            } else {
              _file.atlasType = 'xml';
              this.xmlLoadComplete(_file, _xhr);
            }
          },
        );
      }
      break;

    case 'video':
      if (file.asBlob) {
        try {
          file.data = xhr.response;
        } catch (e) {
          throw new Error(
            `Phaser.Loader. Unable to parse video file as Blob: ${file.key}`,
          );
        }
      }

      this.cache.addVideo(file.key, file.url, file.data, file.asBlob);
      break;

    case 'audio':
      if (this.game.sound.usingWebAudio) {
        file.data = xhr.response;

        this.cache.addSound(file.key, file.url, file.data, true, false);

        if (file.autoDecode) {
          this.game.sound.decode(file.key);
        }
      } else {
        this.cache.addSound(file.key, file.url, file.data, false, true);
      }
      break;

    case 'text':
      file.data = xhr.responseText;
      this.cache.addText(file.key, file.url, file.data);
      break;

    case 'shader':
      file.data = xhr.responseText;
      this.cache.addShader(file.key, file.url, file.data);
      break;

    case 'physics': {
      const _data = JSON.parse(xhr.responseText);
      this.cache.addPhysicsData(file.key, file.url, _data, file.format);
      break;
    }

    case 'script':
      file.data = document.createElement('script');
      file.data.language = 'javascript';
      file.data.type = 'text/javascript';
      file.data.defer = false;
      file.data.text = xhr.responseText;
      document.head.appendChild(file.data);
      if (file.callback) {
        file.data = file.callback.call(
          file.callbackContext,
          file.key,
          xhr.responseText,
        );
      }
      break;

    case 'binary':
      if (file.callback) {
        file.data = file.callback.call(
          file.callbackContext,
          file.key,
          xhr.response,
        );
      } else {
        file.data = xhr.response;
      }

      this.cache.addBinary(file.key, file.data);

      break;

    default:
      break;
  }

  this.onFileComplete.dispatch(0, file.key, !file.error);
};
