import TopFxLayer from './TopFxLayer';
import FxParticle from '../../FxParticle';

export default class UIFxLayer extends TopFxLayer {
  /**
   * Fx layer on top of the UI
   * @param {Board} board
   */
  constructor(board) {
    super(board);
  }

  _init() {
    this._signalBinding.push(G.sb('UIfx').add(this.initUIEffect, this));
  }

  /**
   * Grabs a free particle
   * @returns {FxParticle}
   */
  getFreeParticle() {
    // this.getFirstDead is a Phaser.Group thing
    return this.getFirstDead() || this.add(new FxParticle(this.board));
  }

  /**
   * Plays an effect
   * @param {number} x
   * @param {number} y
   * @param {string} effect
   */
  initUIEffect(x, y, effect) {
    const part = this.getFreeParticle();
    part[effect](x, y);

    return part;
  }
}
