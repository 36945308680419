/* eslint-disable no-unused-vars */

import { Action } from './Action';
import ArrayUtil from '@omt-components/Utils/ArrayUtil';
import { SPECIAL_TYPES } from '../BoardConstants';

const ICON_MAP = { // for getting UI icon type from board booster names
  spiral: 'ui_booster_8',
  vertical: 'ui_booster_4',
  horizontal: 'ui_booster_3',
  cross: 'ui_booster_9',
  random: 'ui_booster_7',
};

const BOOSTER_TYPES = {
  DEFAULT_MOVES: 'booster#5',
  SECONDARY_MOVES: 'booster#6',
};

const RANDOM_LINE_BOOSTERS = [SPECIAL_TYPES.HORIZONTAL, SPECIAL_TYPES.VERTICAL];
const INVENTORY_BOOSTERS = [5, 6, 7, 8]; // only log transactions for these booster #

const INSTANCE_COUNT_MULTIPLIER = {
  7: 3, // booster#7 (vertical / horizontal line) gets applied 3 times instead of
};

const INTIAL_DELAY = 500;
const DELAY_INCREMENT = 200;

// for randomization of start bubble intial placement
const BUBBLE_START_MIN_X = 0.15;
const BUBBLE_START_MAX_X = 0.85;
const BUBBLE_START_INCREMENT_X = 0.14;
const BUBBLE_START_MIN_Y = 0.15;
const BUBBLE_START_MAX_Y = 0.85;
const BUBBLE_START_INCREMENT_Y = 0.14;

/**
 * Action for handling board startBoosters
 */
export class ActionStartBoosters extends Action {
  /**
   * initialize the action.

   */
  _init() {
    this._boosters = [];
    this._popCounter = 0;
    this._startPositions = this._generateBubbleStartPositions();
    this._positionIndex = 0;
    this._delay = INTIAL_DELAY;

    const { lvlDataManager } = this._board;
    const startBoosters = _.cloneDeep(lvlDataManager.startBoosters);
    lvlDataManager.clearStartBoosters();

    // list of normal candies boosters can be applied to
    this._normals = this._getTargetCandies();
    this._normalsIndex = 0;

    // initialize start boosters
    this._initStartBoosters(startBoosters);

    const { gameHooks } = this._board;
    const temporaryBoosters = gameHooks.getTemporaryBoosters();
    this._initTemporaryBoosters(temporaryBoosters);
  }

  /**
   * get the type of booster as defined at
   * @param {number} boosterNum
   * @returns {string}
   */
  _getBoosterType(boosterNum) {
    if (boosterNum === 3) return SPECIAL_TYPES.HORIZONTAL;
    if (boosterNum === 4) return SPECIAL_TYPES.VERTICAL;
    if (boosterNum === 5) return BOOSTER_TYPES.DEFAULT_MOVES;
    if (boosterNum === 6) return BOOSTER_TYPES.SECONDARY_MOVES;
    if (boosterNum === 7) return SPECIAL_TYPES.RANDOM_LINE;
    if (boosterNum === 8) return SPECIAL_TYPES.SPIRAL;
    if (boosterNum === 9) return SPECIAL_TYPES.CROSS;
    return null;
  }

  /**
   * The reverse of get booster type - returns the booster num
   * @param {number} boosterType
   * @returns {number}
   */
  _getBoosterNum(boosterType) {
    if (boosterType === SPECIAL_TYPES.HORIZONTAL) return 3;
    if (boosterType === SPECIAL_TYPES.VERTICAL) return 4;
    if (boosterType === BOOSTER_TYPES.DEFAULT_MOVES) return 5;
    if (boosterType === BOOSTER_TYPES.SECONDARY_MOVES) return 6;
    if (boosterType === SPECIAL_TYPES.RANDOM_LINE) return 7;
    if (boosterType === SPECIAL_TYPES.SPIRAL) return 8;
    if (boosterType === SPECIAL_TYPES.CROSS) return 9;
    return null;
  }

  /**
   * İnitialize temporary boosters that won't be tracked
   * @param {{[key: string]: Array<SPECIAL_TYPES> | Array<number>}} boosters
   */
  _initTemporaryBoosters(boosters) {
    const { gameHooks } = this._board;
    const boostersArray = [];
    for (const value of Object.values(boosters)) {
      if (value.every((val) => typeof val === 'string')) {
        boostersArray.push(...value);
        continue;
      }
      for (let boosterNum = 0; boosterNum < value.length; boosterNum++) {
        const boosterType = this._getBoosterType(boosterNum);
        if (!boosterType) continue;
        const booster = value[boosterNum];
        const boosterMultiplier = INSTANCE_COUNT_MULTIPLIER[boosterNum.toString()] || 1;
        for (let i = 0; i < booster * boosterMultiplier; i++) {
          boostersArray.push(boosterType);
        }
      }
    }
    for (const boosterType of boostersArray) {
      this._createStartBoosterBubble(boosterType);
    }
    gameHooks.resolveTemporaryBoosters();
  }

  /**
   * intialize the start boosters
   * @param {Array} startBoosters
   * @param {string} gameArea (optional) game area boosters are applied
   */
  _initStartBoosters(startBoosters) {
    const { gameHooks } = this._board;
    const inventoryTransactions = []; // list for transaction logs
    const startBoosterTrackingData = []; // used for tracking

    // Loop through sets of boosters to apply
    const _applyBoosters = (boosterSet, isAdRewarded) => {
      for (let boosterNum = 0; boosterNum < boosterSet.length; boosterNum++) {
        const boosterType = this._getBoosterType(boosterNum);
        if (!boosterType || boosterSet[boosterNum] == null) continue;

        // applies single or multiple booster
        const instanceCountMulti = INSTANCE_COUNT_MULTIPLIER[boosterNum.toString()] || 1;
        const instanceCount = boosterSet[boosterNum] * instanceCountMulti;

        for (let i = 0; i < instanceCount; i++) this._createStartBoosterBubble(boosterType);

        // remove from inventory and track change
        gameHooks.onStartBoosterUse(boosterNum, boosterSet[boosterNum]);

        const isInventoryItem = INVENTORY_BOOSTERS.indexOf(boosterNum) >= 0;
        if (isInventoryItem) { // add list of inventory changes to log. only for inventory items!
          inventoryTransactions.push({
            action: 'boostersUsed',
            boosterNum,
            amount: boosterSet[boosterNum],
          });
        }

        // add tracking data
        const trackingData = {
          boosterCount: boosterSet[boosterNum],
          boosterNum,
          coinCost: 0,
        };

        startBoosterTrackingData.push(trackingData);
      }
    };

    // Apply booster sets
    for (const currentBoosterSet in startBoosters) {
      if (Object.prototype.hasOwnProperty.call(startBoosters, currentBoosterSet)) {
        const isAdRewarded = currentBoosterSet === 'adRewarded';
        _applyBoosters(startBoosters[currentBoosterSet], isAdRewarded);
      }
    }

    // Track pre-level boosters
    gameHooks.trackStartBoosters(startBoosterTrackingData);

    // log out inventory transactions to appropriate game hooks
    gameHooks.logInventoryTransactions(inventoryTransactions);
  }

  /**
   * create a start booster bubble of the specified type
   * @param {string} boosterType
   */
  _createStartBoosterBubble(boosterType) {
    const { lvlDataManager, gameHooks } = this._board;
    let bubbleTarget; let bubbleCallback; let bubbleIcon;
    const movesFromBooster = gameHooks.getMovesForBoosterType(boosterType);

    if (movesFromBooster) { // extra moves boosters
      bubbleTarget = gameHooks.extraMovesAnimationTarget;
      bubbleIcon = gameHooks.getIconForBoosterType(boosterType);
      bubbleCallback = () => {
        lvlDataManager.changeMoveNumber(movesFromBooster);
      };
    } else { // board modifier boosters
      if (boosterType === SPECIAL_TYPES.RANDOM_LINE) boosterType = ArrayUtil.getRandomElement(RANDOM_LINE_BOOSTERS);
      bubbleTarget = this._normals[this._normalsIndex];
      // Check to see if we really have a target candy
      if (!bubbleTarget) {
        this._normals = this._getTargetCandies();
        this._normalsIndex = 0;
        // We found a valid one
        if (this._normals[this._normalsIndex] !== undefined) {
          return this._createStartBoosterBubble(boosterType);
        }
        // TODO: Causes users to lose boosters without the effect
        // Expect this to be very rare
        return false;
      }
      bubbleIcon = ICON_MAP[boosterType].replace('%%', bubbleTarget.frameName);
      bubbleCallback = () => {
        bubbleTarget.changeInto(boosterType, false, true);
      };
      this._normalsIndex++;
    }

    // create booster bubble seen on board
    if (!this._startPositions[this._positionIndex]) {
      this._startPositions = this._generateBubbleStartPositions();
      this._positionIndex = 0;
    }
    const bubble = new G.StartBoosterBubble(
      this._startPositions[this._positionIndex],
      bubbleIcon,
      bubbleTarget, bubbleCallback,
    );
    this._positionIndex++;
    bubble.events.onDestroy.addOnce(this._onStartBoosterBubbleDestroyed, this);

    // animate bubble to target and set delays
    bubble.goToTarget(this._delay);
    this._delay += DELAY_INCREMENT;
    this._boosters.push(bubble);

    // add to the booster to UI FX layer
    const { startBoosterFXLayer } = gameHooks;
    if (startBoosterFXLayer) startBoosterFXLayer.addChild(bubble);

    // Operation successful
    return true;
  }

  /**
   * on start booster bubble destoryed
   */
  _onStartBoosterBubbleDestroyed(bubble) {
    bubble.events.onDestroy.removeAll(this);
    this._popCounter++;
  }

  /**
   * generate a list of randomized positions to animate from
   * @returns {Array.<{x,y}>}
   */
  _generateBubbleStartPositions() {
    const result = [];
    for (let x = BUBBLE_START_MIN_X; x <= BUBBLE_START_MAX_X; x += BUBBLE_START_INCREMENT_X) {
      for (let y = BUBBLE_START_MIN_Y; y <= BUBBLE_START_MAX_Y; y += BUBBLE_START_INCREMENT_Y) {
        result.push([x + game.rnd.realInRange(-0.02, 0.02), y + game.rnd.realInRange(-0.02, 0.02)]);
      }
    }
    return ArrayUtil.jumbleArray(result);
  }

  /**
   * get a list of candies to appl
   * @returns {Array.<Candy>}
   */
  _getTargetCandies() {
    // get list of normal candies with no blockers or specials
    const { lvlDataManager } = this._board;
    const normals = this._board.candiesLayer.getNormalCandies().filter((candy) => !lvlDataManager.isGoalType(candy.getBaseTypeSymbol()));
    ArrayUtil.jumbleArray(normals);

    // sort candies to be non-neighbors first
    const filtered = []; const neighbours = [];
    for (const candy1 of normals) {
      const isNeighbour = filtered.find((candy2) => this._board.candiesLayer.areCandiesNeighbours(candy1, candy2), this);
      if (isNeighbour) neighbours.push(candy1);
      else filtered.push(candy1);
    }
    return filtered.concat(neighbours);
  }

  /**
   * action update
   */
  update() {
    if (this._popCounter === this._boosters.length) {
      this.finish();
    }
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._normals.length = 0;
    this._startPositions.length = 0;
    this._boosters.length = 0;
  }
}
