import { MILLISECONDS_IN_DAY } from '@omt-components/Utils/TimeUtil';
import { RMWHEEL_EPS, RMWHEEL_EVENTS } from '../../../../Elements/SpinningWheels/RealMoneyWheel/rmWheelEnums';
import { isConversionToEnum } from '../../../../Elements/SpinningWheels/RealMoneyWheel/RealMoneyWheelHelpers';

export const ENTRY_POINTS = {
  GAME_STARTED: 'gameStarted',
};

export const TARGETED_OFFER_IDS = {
  NON_PAYER_OOM: 'NON_PAYER_OOM',
  NON_PAYER_FAIL: 'NON_PAYER_FAIL',
  NON_PAYER_FIRST_GATE: 'NON_PAYER_FIRST_GATE',
  NON_PAYER_LAST_X_SESSIONS: 'NON_PAYER_LAST_X_SESSIONS',
  PAYER_OOM: 'PAYER_OOM',
  PAYER_LAST_X_DAYS: 'PAYER_LAST_X_DAYS',
  PAYER_LAST_X_SESSIONS: 'PAYER_LAST_X_SESSIONS',
  NON_PAYER_TOKENEVENT: 'NON_PAYER_TOKENEVENT',
  PAYER_TOKENEVENT: 'PAYER_TOKENEVENT',
};

const DEFAULT_COOLDOWN = MILLISECONDS_IN_DAY;

let _instance = null; // singleton instance

/**
 * class for managing data and display logic related to showing targeted offers
 * note: offerData should look something like this. offerData is pulled from OMTSettingsDefault.js / OMTSettings/js
 * {
 *    productID:string
 *    closeCooldown:number: (optional) milliseconds
 *    purhcaseCooldown:number (optional) milliseconds
 *    maxSessionViewCount:number (optional)
 *    minLevel:number (optional)
 *    oomViewChance:Array.<number> (optional)
 *    daysSinceLastPurchaseChance:Array<{ days, probability }> (optional)
 *    coinChance:Array.<{ maxCoins, probability }> (optional)
 *    gameStartedChance:number (optional)
 * }
 */
export default class TargetedOfferDataManager {
  /**
   * get singleton instance
   */
  static getInstance() {
    if (_instance === null) _instance = new TargetedOfferDataManager();
    return _instance;
  }

  /**
   * Returns default data
   * @returns {{co:null, oq:Array}}
   */
  static getDefaultValues() {
    return {
      co: null, // current offer
      oq: [], // offer queue
      sn: 0, // sessions since last targeted offer
    };
  }

  /**
   * pass data from the saveState and initialize / save it.
   * @param {object} saveData reference to Object in userData
   */
  init(saveData) {
    this._saveData = saveData; // not actually used atm, but left in.

    this._activeOffers = { ...G.OMTsettings.targetedOffers };
    this._disabledOffers = [];
    this.resetMissionData();
    this._resetSessionData();
    this._markCurrentOfferAsSeen();
  }

  /**
   * Get player's targeted offer data
   * @returns {{co:Object, oq:Array}}
   */
  getTargetedOfferData() {
    return G.saveState.getTargetedOfferData();
  }

  /**
   * Get player's current targeted offer
   * @returns {Object}
   */
  getCurrentTargetedOffer() {
    const data = this.getTargetedOfferData();
    return data ? data.co : null;
  }

  /**
   * Manually override the currently shown targeted offer
   * @param {string} offerId
   */
  setCurrentTargetedOffer(offerId) {
    const offerSettings = G.OMTsettings.targetedOffers[offerId];
    if (!offerSettings) return; // return early if offerId is invalid

    const expiryTime = Date.now() + offerSettings.timelimit;

    const data = G.saveState.getTargetedOfferData();
    data.co = {
      oid: offerId,
      et: expiryTime,
    };
    G.saveState.setTargetedOfferData(data);
  }

  /**
   * Manually clear the currently shown targeted offer
   */
  clearCurrentTargetedOffer() {
    const data = this.getTargetedOfferData();
    data.co = null;
    G.saveState.setTargetedOfferData(data);
  }

  /**
   * Get the smount of time before current targeted offer expires
   * @return {number} time in ms
   */
  getCurrentTargetedOfferTimeRemaining() {
    const currentOffer = this.getCurrentTargetedOffer();
    if (!currentOffer) return 0;
    return currentOffer.et - Date.now();
  }

  /**
   * Add new targeted offer to queue
   * @param {string|Object} offerId
   * @param {string} offer.oid offer id OR
   * @param {string} offer.pid product id
   * @param {number} offer.et expiry time
   * @param {boolean} offer.im immediately show on game start if possible
   * @param {boolean} highPriority
   * @returns {Array}
   */
  enqueueTargetedOffer(offer, highPriority = false) {
    const data = G.saveState.getTargetedOfferData();

    if (typeof offer === 'string') {
      // offer is a string: use predefined offers
      const offerSettings = G.OMTsettings.targetedOffers[offer];
      if (!offerSettings) return null; // return early if offerId is invalid

      if (offerSettings.highPriority || highPriority) {
        data.oq.unshift(offer);
      } else {
        data.oq.push(offer);
      }
    } else if (typeof offer === 'object') {
      // offer is an object: custom offer (e.g. from entry point payloads)
      if (highPriority) {
        data.oq.unshift(offer);
      } else {
        data.oq.push(offer);
      }
    }

    G.saveState.setTargetedOfferData(data);
    return data.oq;
  }

  /**
   * Remove oldest targeted offer from queue and make it the current deal
   * @returns {{oid: string | pid: string, et: number}}
   */
  dequeueTargetedOffer() {
    const data = G.saveState.getTargetedOfferData();
    let newOffer = null;
    let offerSettings = null;

    // Find next valid offer
    while (!newOffer && !offerSettings && data.oq.length > 0) {
      newOffer = data.oq.shift();

      if (typeof newOffer === 'string') {
        offerSettings = G.OMTsettings.targetedOffers[newOffer];
      } else if (typeof newOffer === 'object') {
        offerSettings = newOffer;
      }
    }

    if (!newOffer) return null; // no offer queued
    if (!offerSettings) return null; // offerId is invalid

    if (typeof newOffer === 'string') {
      // offer is a string: use predefined offers
      const expiryTime = Date.now() + offerSettings.timeLimit;
      data.co = {
        oid: newOffer,
        et: expiryTime,
      };
    } else if (typeof newOffer === 'object') {
      // offer is an object: custom offer (e.g. from entry point payloads)
      data.co = newOffer;
    } else {
      return null;
    }

    G.saveState.setTargetedOfferData(data);
    return data.co;
  }

  /**
   * Gets number of sessions since last targeted offer
   */
  getSessionNumber() {
    const data = G.saveState.getTargetedOfferData();
    return data.sn;
  }

  /**
   * Sets number of sessions since last targeted offer
   */
  setSessionNumber(sessionNumber) {
    const data = G.saveState.getTargetedOfferData();
    data.sn = sessionNumber;
    G.saveState.setTargetedOfferData(data);
  }

  /**
   * Clears player's targeted offer data
   */
  clearTargetedOfferData() {
    G.saveState.setTargetedOfferData(TargetedOfferDataManager.getDefaultValues());
    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('sessionsSinceLastTargetedOffer', 0);
  }

  /**
   * disable a specific offer by its offerID
   * @param {string} offerID The ID of the targeted offer
   */
  disableOfferById(offerID) {
    if (this._disabledOffers.indexOf(offerID) === -1) this._disabledOffers.push(offerID);
    console.log(`TargetedOfferDataManager.disableOfferById(${offerID}): ${offerID} is now disabled and will not be shown.`);
  }

  /**
   * If we can show a given offer or not.
   * @param {string} offerID The ID of the targeted offer
   * @param {string} entryPointId (optional)
   * @returns {boolean}
   */
  canShowOffer(offerID, entryPointId = '') {
    if (!G.IAP || !G.featureUnlock.targetedOffers) return false;

    const offerData = this._activeOffers[offerID];
    if (!offerData) {
      console.error(`TargetedOfferDataManager.canShowOffer(${offerID}): offer not found`);
      return false; // no matching offerID found.
    }

    if (this._disabledOffers.indexOf(offerID) >= 0) {
      console.log(`TargetedOfferDataManager.canShowOffer(${offerID}): ${offerID} offer is currently disabled`);
      return false;
    }

    // startup offer set through setStartupOfferState()
    if (entryPointId === ENTRY_POINTS.GAME_STARTED && this._sessionData.startupOfferStates[offerID] != null) {
      return this._sessionData.startupOfferStates[offerID];
    }

    const offerConditions = [];

    // standard conditions
    this._checkOffer_cooldownRequirement(offerID, offerConditions);
    this._checkOffer_payerRequirement(offerID, offerConditions);

    // optional conditions
    this._checkOptionalRequirements({ offerID, offerData, offerConditions });

    // game started optional conditions
    if (entryPointId === ENTRY_POINTS.GAME_STARTED) {
      if ('gameStartedChance' in offerData) this._checkOffer_gameStartRequirement(offerData, offerConditions);
    }

    // log offer status if in dev environment
    if (!G.BuildEnvironment.production) this._debugLogOfferConditions(offerID, offerConditions);

    const failedConditions = offerConditions.filter((condition) => !condition.passed);
    return failedConditions.length === 0;
  }

  /**
   * Checks all optional requirements. Used multiple times
   * @param {{ offerID:string, offerData:Object, offerConditions:Array<{boolean}> }} offerConfig
   */
  _checkOptionalRequirements(offerConfig) {
    const { offerData, offerID, offerConditions } = offerConfig;
    if ('maxSessionViewCount' in offerData) this._checkOffer_sessionViewCountRequirement(offerID, offerData, offerConditions);
    if ('minLevel' in offerData) this._checkOffer_levelRequirement(offerData, offerConditions);
    if ('coinChance' in offerData) this._checkOffer_maxCoinsRequirement(offerData, offerConditions);
    if ('oomViewChance' in offerData) this._checkOffer_OOMRequirement(offerData, offerConditions);
    if ('daysSinceLastPurchase' in offerData) this._checkOffer_daysSincePurchaseRequirement(offerData, offerConditions);
    if ('sessionsSinceLastTargetedOffer' in offerData) this._checkOffer_sessionsSinceLastOfferRequirement(offerData, offerConditions);
    if ('boosterChance' in offerData) this._checkOffer_boosterRequirement(offerData, offerConditions);
    if ('preBoosterChance' in offerData) this._checkOffer_preBoosterRequirement(offerData, offerConditions);
    if ('purchaseAmount' in offerData) this._checkOffer_purchaseRequirement(offerData, offerConditions);
  }

  /**
   * Finds product in the case that the current offer data doesn't have a product ID.
   * Looks at an array with possible conditions for the product Id
   * TOR-7591
   */
  findProduct(offerID, targetProductIDs) {
    for (const targetProduct of targetProductIDs) {
      for (const conditionSet of targetProduct.conditions) {
        const conditions = [];
        this._checkOptionalRequirements({ offerID, offerData: conditionSet, offerConditions: conditions });
        const failedConditions = conditions.filter((condition) => !condition.passed);
        if (failedConditions.length === 0) {
          return targetProduct;
        }
      }
    }
    console.log(`Unable to find product Id for offerID: ${offerID}`);
    return undefined;
  }

  /**
   * log offer conditions state to the console
   * @param {string} offerID The ID of the targeted offer
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _debugLogOfferConditions(offerID, offerConditions) {
    let failedCount = 0;
    let output = '-------------------------------------';
    output += `\n TargetedOfferDataManager.canShowOffer(${offerID})`;
    for (const offerCondition of offerConditions) {
      if (!offerCondition.passed) failedCount++;
      output += `\n - requirement: ${offerCondition.conditionID}, passed: ${offerCondition.passed}`;
      if ('probability' in offerCondition) {
        const percentage = `${Math.round(offerCondition.probability * 100)}%`;
        output += `, probability: ${percentage}`;
      }
    }
    output += `\n * failed requirements: ${failedCount}`;
    output += '\n-------------------------------------';
    console.log(output);
  }

  /**
   * @param {string} offerID The ID of the targeted offer
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_cooldownRequirement(offerID, offerConditions) {
    const cooldown = G.saveState.getUserCooldownRemaining(`targetedOffer_${offerID}`, '');
    const conditionPassed = cooldown <= 0;
    offerConditions.push({ conditionID: 'cooldownRequirement', passed: conditionPassed });
  }

  /**
   * check payer offer condition
   * @param {string} offerID The ID of the targeted offer
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_payerRequirement(offerID, offerConditions) {
    const userIsPayer = this.isPayer();
    const isPayerOffer = offerID.toUpperCase().indexOf('PAYER') === 0;
    const conditionPassed = userIsPayer === isPayerOffer;
    offerConditions.push({ conditionID: 'payerRequirement', passed: conditionPassed });
  }

  /**
   * check payer offer condition
   * @param {string} offerID The ID of the targeted offer
   * @param {Object} offerData
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_sessionViewCountRequirement(offerID, offerData, offerConditions) {
    const { maxSessionViewCount } = offerData;
    const sessionViewCount = this._getOfferSessionViewCount(offerID);
    const conditionPassed = sessionViewCount < maxSessionViewCount;
    offerConditions.push({ conditionID: 'sessionViewCountRequirement', passed: conditionPassed });
  }

  /**
   * check minimum level offer condition
   * @param {Object} offerData
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_levelRequirement(offerData, offerConditions) {
    const conditionPassed = G.saveState.getLastPassedLevelNr() >= offerData.minLevel;
    offerConditions.push({ conditionID: 'levelRequirement', passed: conditionPassed });
  }

  /**
   * check session oom count offer condition
   * @param {Object} offerData
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_OOMRequirement(offerData, offerConditions) {
    const oomChanceList = offerData.oomViewChance;
    const oomChanceIndex = Math.max(0, Math.min(this._sessionData.outOfMovesCount, oomChanceList.length - 1));
    const probability = oomChanceList[oomChanceIndex];
    const conditionPassed = Math.random() <= probability;
    offerConditions.push({ conditionID: 'OOMRequirement', passed: conditionPassed, probability });
  }

  /**
   * check days since purchase offer condition
   * @param {Object} offerData
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_daysSincePurchaseRequirement(offerData, offerConditions) {
    let conditionPassed = false;
    let probability = 0;
    const { productIDFilters } = offerData.daysSinceLastPurchase;
    const daysSinceLastPurchase = this._getDaysSinceLastPurchase(productIDFilters || []);
    const daysChanceList = offerData.daysSinceLastPurchase.chance.slice();
    daysChanceList.sort((a, b) => a.days - b.days);

    for (let i = daysChanceList.length - 1; i >= 0; i--) {
      const daysChanceObj = daysChanceList[i];
      if (daysChanceObj.days <= daysSinceLastPurchase) {
        probability = daysChanceObj.probability;
        conditionPassed = Math.random() <= probability;
        break;
      }
    }
    offerConditions.push({ conditionID: 'daysSincePurchaseRequirement', passed: conditionPassed, probability });
  }

  /**
   * check sessions since last targeted offer offer condition
   * @param {Object} offerData
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_sessionsSinceLastOfferRequirement(offerData, offerConditions) {
    const sessionRequirement = offerData.sessionsSinceLastTargetedOffer;
    const sessionNumber = this.getSessionNumber();
    const conditionPassed = sessionNumber > sessionRequirement;

    offerConditions.push({ conditionID: 'sessionsSinceLastOfferRequirement', passed: conditionPassed });
  }

  /**
   * Checks how many (in game) boosters (total) the user as
   * @param {Object} offerData
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_boosterRequirement(offerData, offerConditions) {
    const requirement = offerData.boosterChance;
    const boosterArr = [1, 2, 3, 4];
    const sum = boosterArr.reduce((total, i) => total + G.saveState.getBoosterAmount(i), 0);

    const conditionPassed = sum <= requirement;
    offerConditions.push({ conditionID: 'boosterChance', passed: conditionPassed });
  }

  /**
   * Checks how many (pre-level) boosters (total) the user as
   * @param {Object} offerData
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_preBoosterRequirement(offerData, offerConditions) {
    const requirement = offerData.preBoosterChance;
    const movesBoosterType = G.IAP ? 6 : 5;
    const boosterArr = [movesBoosterType, 7, 8];
    const sum = boosterArr.reduce((total, i) => total + G.saveState.getBoosterAmount(i), 0);

    const conditionPassed = sum <= requirement;
    offerConditions.push({ conditionID: 'preBoosterChance', passed: conditionPassed });
  }

  /**
   * Checks how many times the user purchased something
   * @param {Object} offerData
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_purchaseRequirement(offerData, offerConditions) {
    const requirement = offerData.purchaseAmount;
    const conditionPassed = G.saveState.getIAPCount() >= requirement;
    offerConditions.push({ conditionID: 'purchaseAmount', passed: conditionPassed });
  }

  /**
   * check days since purchase offer condition
   * @param {Object} offerData
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_maxCoinsRequirement(offerData, offerConditions) {
    let conditionPassed = false;
    let probability = 0;
    const currentCoins = G.saveState.getCoins();
    const coinChanceList = offerData.coinChance.slice();
    coinChanceList.sort((a, b) => a.maxCoins - b.maxCoins);

    for (const coinChanceObj of coinChanceList) {
      if (coinChanceObj.maxCoins >= currentCoins) {
        probability = coinChanceObj.probability;
        conditionPassed = Math.random() <= probability;
        break;
      }
    }
    offerConditions.push({ conditionID: 'maxCoinsRequirement', passed: conditionPassed, probability });
  }

  /**
   * check game started offer condition
   * @param {Object} offerData
   * @param {Array.<{ conditionID, passed }>} offerConditions
   */
  _checkOffer_gameStartRequirement(offerData, offerConditions) {
    const probability = offerData.gameStartedChance;
    const conditionPassed = Math.random() <= probability;
    offerConditions.push({ conditionID: 'gameStartRequirement', passed: conditionPassed, probability });
  }

  /**
   * check if we should show an offer on startup + flag it to be show when entering the world map.
   * @param {string} offerID The ID of the targeted offer
   * @returns {boolean}
   */
  setStartupOfferState(offerID) {
    const showOffer = this.canShowOffer(offerID, ENTRY_POINTS.GAME_STARTED);
    this._sessionData.startupOfferStates[offerID] = showOffer;
    return showOffer;
  }

  /**
   * get day count since last logged transaction. if nothing logged will return Infinity.
   * @param {Array.<string>} productIDFilters IDs for filtering logs
   * @returns {number}
   */
  _getDaysSinceLastPurchase(productIDFilters = []) {
    let msSincePurchase = Infinity;
    let daysSinceLastPurchase = Infinity;

    if (productIDFilters.length === 0) { // no filter just get days since any purchase
      msSincePurchase = OMT.transactionTracking.getTimeSinceLastRealMoneyTransaction();
      daysSinceLastPurchase = Math.floor(msSincePurchase / MILLISECONDS_IN_DAY);
    } else { // get minimum days since purchase with matching produceID from filter
      for (const productID of productIDFilters) {
        const ms = OMT.transactionTracking.getTimeSinceLastRealMoneyTransaction(productID);
        if (ms < msSincePurchase) {
          msSincePurchase = ms; daysSinceLastPurchase = Math.floor(msSincePurchase / MILLISECONDS_IN_DAY);
        }
      }
    }
    return daysSinceLastPurchase;
  }

  /**
   * Show a targeted offer if possible
   * @param {string || Array} offerID The ID of the offer. If array, it will recurse over all elements in array
   * @param {string} entryPointId (optional)
   * @returns {boolean} show success
   */
  showPopupOfferIfPossible(offerID, entryPointId = '') {
    if (!G.IAP || !G.featureUnlock.targetedOffers) return false;

    if (Array.isArray(offerID)) {
      const offerIdList = offerID;
      for (let i = 0; i < offerIdList.length; i++) {
        offerID = offerIdList[i];
        if (this.showPopupOfferIfPossible(offerID, entryPointId)) return true;
      }
    } else if (this.canShowOffer(offerID, entryPointId)) {
      const offerData = this._activeOffers[offerID];
      if (!offerData) return false;
      this._sessionData.startupOfferStates[offerID] = null;
      this.setSessionNumber(0);

      /**
       * Give a chance to show the targeted offer Real Money Wheel instead if all the follow are true:
       * 1. The targeted offer wheel is active
       * 2. The real money wheel is not on cooldown
       * 3. The current targeted offer is not NON_PAYER_FIRST_GATE
       * 4. The player is converted (high value) OR hasn't seen the replacement wheel in the last 3 sessions
       */
      // const isConversion = DDNA.tracking.getDataCapture().getRealMoneyWheelConversionStatus();

      if (OMT.feature.getFeatureRealMoneyTargetedOfferWheel()
          && G.saveState.getRealMoneyWheelOnCoolDown() <= 0
          && offerID !== TARGETED_OFFER_IDS.NON_PAYER_FIRST_GATE) {
        // && (!isConversion || G.saveState.getRealMoneyWheelSessionsNotSeen() >= 3)) {
        const rollSuccessful = Math.random() < G.json.settings.realMoneyTargetedOfferWheel.appearanceChance / 100;

        // Track wheel sighting DDNA event
        // const wheelMode = isConversionToEnum(isConversion);

        // DDNA.transactionHelper.queueRealMoneyWheelEvent(
        //   RMWHEEL_EPS.TargetedOffer,
        //   RMWHEEL_EVENTS.Seen,
        //   wheelMode,
        //   rollSuccessful ? 1 : 0,
        // );

        if (rollSuccessful) {
          G.sb('pushWindow').dispatch(['realMoneyWheel', {
            entryPoint: RMWHEEL_EPS.TargetedOffer,
            predeterminedPrize: -1,
            freeSpin: false,
            worldState: game.state.current === 'World',
          }], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
          return true;
        }
      }

      // Show or queue the offer
      if (offerData.isTimedOffer) {
        // The offer is timed, enqueue offer
        this.enqueueTargetedOffer(offerID);

        // Try to show the offer if none are active currently
        const timeLeft = this.getCurrentTargetedOfferTimeRemaining();
        if (timeLeft <= 0) {
          this.dequeueTargetedOffer();

          const currentOffer = this.getTargetedOfferData().co;
          const currentOfferData = { ...this._activeOffers[currentOffer.oid] }; // copy it
          if (!currentOfferData.productID) { // Theres no product ID!? Probably a PAYER_LAST_X_DAYS offer (TOR-7591)
            const product = this.findProduct(offerID, currentOfferData.targetProducts);
            if (product) {
              currentOfferData.productID = product.productID;
            }
          }
          this.setTargetedOfferSeen(currentOffer.oid, currentOfferData.productID);
          this.showTimedPopupOfferWindow(currentOffer);

          // If on saga map, show the button back to the window
          if (game.state.current === 'World') {
            G.sb('showTimedTargetedOfferButton').dispatch(1000);
          }
        }
        return true;
      }

      // The offer is not timed (one-time), show it now
      this.setTargetedOfferSeen(offerID, offerData.productID);
      G.sb('pushWindow').dispatch(['oneTimeTargetedOffer', offerData.productID, offerID, offerData.windowCustomizations]);
      return true;
    }
    return false;
  }

  /**
   * set the save state to force a specific offer to show
   * @param {string} offer
   */
  setSaveStateToOffer(offer) {
    const settings = G.OMTsettings.targetedOffers;
    this._setOfferCooldown(offer, 0);
    this._sessionData.sessionViewCounts[offer] = 0;

    if (offer === TARGETED_OFFER_IDS.NON_PAYER_OOM) {
      console.log('setting save state to NON_PAYER_OOM');
      const coinProbs = settings[offer].coinChance;
      OMT.jaffles.unlockLevelsUpTo(settings[offer].minLevel + 1, 3, true);
      G.saveState.setCoins(coinProbs[coinProbs.length - 1].maxCoins - 1000);
      this._sessionData.outOfMovesCount = settings[offer].oomViewChance.length - 1;
      G.saveState.data.transactions = [];
    }
    if (offer === TARGETED_OFFER_IDS.NON_PAYER_FAIL) {
      console.log('setting save state to NON_PAYER_FAIL');
      const coinProbs = settings[offer].coinChance;
      G.saveState.setCoins(coinProbs[coinProbs.length - 1].maxCoins);
      G.saveState.data.transactions = [];
    }
    if (offer === TARGETED_OFFER_IDS.NON_PAYER_FIRST_GATE) {
      console.log('setting save state to NON_PAYER_FIRST_GATE');
      G.saveState.resetAllData();
      OMT.jaffles.unlockLevelsUpTo(20, 3, false);
    }
    if (offer === TARGETED_OFFER_IDS.PAYER_OOM) {
      console.log('setting save state to PAYER_OOM');
      const coinProbs = settings[offer].coinChance;
      console.log(settings[offer].coinChance);
      OMT.jaffles.unlockLevelsUpTo(settings[offer].minLevel + 1, 3, true);
      G.saveState.setCoins(coinProbs[coinProbs.length - 1].maxCoins - 1000);
      this._sessionData.outOfMovesCount = settings[offer].oomViewChance.length - 1;
      G.saveState.data.transactions = [{ timestamp: Date.now(), productID: '' }];
    }
    if (offer === TARGETED_OFFER_IDS.PAYER_LAST_X_DAYS) {
      console.log('setting save state to PAYER_LAST_X_DAYS');
      const dayProbs = settings[offer].daysSinceLastPurchase.chance;
      G.saveState.data.transactions = [{ timestamp: Date.now() - dayProbs[dayProbs.length - 1].days * MILLISECONDS_IN_DAY, productID: '' }];
    }

    G.saveState.save();
  }

  /**
   * force a specific offer to show as a timed offer
   * @param {string} currentOfferData
   */
  showTimedPopupOfferWindow(currentOfferData) {
    const offerID = currentOfferData.oid;
    const expiryTime = currentOfferData.et;

    if (offerID) {
      // Use offer ID
      const offerSettings = { ...this._activeOffers[offerID] }; // Copy it
      if (!offerSettings) {
        console.error(`TargetedOfferDataManager.showTimedPopupOfferWindow(${offerID}): offer not found`);
        return; // no matching offerID found.
      }
      if (!offerSettings.productID) {
        const product = this.findProduct(offerID, offerSettings.targetProducts);
        if (product) {
          offerSettings.productID = product.productID;
        }
      }
      G.sb('pushWindow').dispatch(['timedTargetedOffer', offerSettings.productID, offerID, expiryTime, offerSettings.windowCustomizations]);
      return;
    }

    // If no offer id is given, try using product ID
    const productId = currentOfferData.pid;

    if (productId) {
      G.sb('pushWindow').dispatch(['timedTargetedOffer', productId, '', expiryTime, {}]);
      return;
    }

    console.error('TargetedOfferDataManager.showTimedPopupOfferWindow no offer or product ID found');
  }

  /**
   * force a specific offer to show as a one-time offer
   * @param {string} offerID The ID of the targeted offer
   */
  showOneTimePopupOfferWindow(offerID) {
    const offerData = this._activeOffers[offerID];
    if (!offerData) {
      console.error(`TargetedOfferDataManager.showOneTimePopupOfferWindow(${offerID}): offer not found`);
      return; // no matching offerID found.
    }
    G.sb('pushWindow').dispatch(['oneTimeTargetedOffer', offerData.productID, offerID, offerData.windowCustomizations]);
  }

  /**
   * increment outOfMovesCounts
   */
  incrementOOM() {
    this._missionData.outOfMovesCount++;
    this._sessionData.outOfMovesCount++;
  }

  /**
   * reset the mission data object
   */
  resetMissionData() {
    if (!this._missionData) this._missionData = {};
    this._missionData.outOfMovesCount = 0;
    this._missionData.targetedOfferSeen = '';
  }

  /**
   * reset the session data object
   */
  _resetSessionData() {
    this._sessionData = {
      startupOfferStates: {},
      outOfMovesCount: 0,
      sessionViewCounts: {},
    };
  }

  /**
   * Mark current offer as seen if it hasn't expired yet
   */
  _markCurrentOfferAsSeen() {
    const timeLeft = this.getCurrentTargetedOfferTimeRemaining();
    if (timeLeft <= 0) return;
    const currentOffer = this.getCurrentTargetedOffer();
    if (!currentOffer) return;
    this._incrementOfferSessionViewCount(currentOffer.oid);
  }

  /**
   * set a targeted offer as seen
   * @param {string} offerID The ID of the targeted offer
   * @param {string} productID The Product ID of the offer
   */
  setTargetedOfferSeen(offerID, productID) {
    this._missionData.targetedOfferSeen = productID;
    this._incrementOfferSessionViewCount(offerID);
  }

  /**
   * increment session view count for a specific offerID
   * @param {string} offerID The ID of the targeted offer
   */
  _incrementOfferSessionViewCount(offerID) {
    const { sessionViewCounts } = this._sessionData;
    if (sessionViewCounts[offerID] == null) sessionViewCounts[offerID] = 0;
    sessionViewCounts[offerID]++;
  }

  /**
   * get session view count for a specific offerID
   * @param {string} offerID The ID of the targeted offer
   * @returns {number}
   */
  _getOfferSessionViewCount(offerID) {
    const { sessionViewCounts } = this._sessionData;
    return sessionViewCounts[offerID] || 0;
  }

  /**
   * set the cooldown for the given offer ID
   * @param {string} offerID The ID of the targeted offer
   * @param {number} duration duration of the cooldown in milliseconds
   */
  _setOfferCooldown(offerID, duration) {
    G.saveState.setUserCooldown(`targetedOffer_${offerID}`, '', duration);
  }

  /**
   * When the offer is purchased, set its cooldown to 24hours and mark it in the pcdata
   * @param {string} offerID The ID of the targeted offer
   */
  onOfferPurchased(offerID) {
    const offerData = this._activeOffers[offerID];
    let { purchaseCooldown } = offerData;
    if (!offerData.productID && offerData.targetProducts) { // purchase cooldown in target products
      const product = this.findProduct(offerID, offerData.targetProducts);
      if (product) {
        purchaseCooldown = product.closeCooldown || null;
      }
    }
    this._setOfferCooldown(offerID, purchaseCooldown || DEFAULT_COOLDOWN);
  }

  /**
   * When the offer is closed, we
   * @param {string} offerID The ID of the targeted offer
   */
  onOfferClosed(offerID) {
    if (!offerID) return;
    const offerData = this._activeOffers[offerID];
    let { closeCooldown } = offerData;
    if (!offerData.productID && offerData.targetProducts) { // Close cooldown in target products
      const product = this.findProduct(offerID, offerData.targetProducts);
      if (product) {
        closeCooldown = product.closeCooldown || null;
      }
    }
    if (closeCooldown) this._setOfferCooldown(offerID, closeCooldown);
  }

  /**
   * true if the current player has made any transactions.
   * @returns {boolean}
   */
  isPayer() {
    return G.saveState.getIAPCount() > 0;
  }

  /**
   * @returns {Object} object for mission tracking
   */
  get missionData() {
    return this._missionData;
  }
}

// global reference for testing
window.TargetedOfferDataManager = TargetedOfferDataManager;
