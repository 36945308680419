/* eslint-disable no-use-before-define */
import TutorialMsgText from './G.TutorialMsgText';
import GingyCharacterTutorial from './G.GingyCharacterTutorial';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { OMT_SystemInfo, ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../States/Scaling/GameScaleController';

/**
 * Contains possible tutorial positions that will be passed into the displayMsg data
 * bottom is preferred instead of bot for resizing purposes
 */
const TUTORIAL_POSITION = {
  top: 0.15,
  middle: 0.5,
  mid: 0.5,
  bottom: 0.9,
  bot: 0.93,
};

/**
 * This class handles the tutorial bar style conversations made by our heroes
 */
class GingyTutorialBar extends Phaser.Group {
  constructor(character) {
    super(game);

    this._gameScale = GameScaleController.getInstance().gameScale;
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    const barWidth = this._isLandscape ? game.width / this._gameScale : game.width;
    this.x = this._isLandscape ? 480 : 320;
    this.bar = new TutorialBarBackground(barWidth);
    this.addChild(this.bar);

    const { gingyXPos, msgXPos } = OMT_Utils.generateConditionalObject({
      gingyXPos: -220,
      msgXPos: 90,
    }, {
      condition: () => OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal,
      parameters: {
        gingyXPos: -570,
        msgXPos: 0,
      },
    });
    this.gingy = this.add(
      new GingyCharacterTutorial(
        gingyXPos,
        88 + G.OMTsettings.FTUXSettings.gingyPosOffset.y,
        character,
      ),
    );
    this.msg = this.add(new TutorialMsgText(msgXPos, 0));

    this.iconContainer = game.make.group(this);
    this.icon = G.makeImage(0, 160, null, 0.5, this.iconContainer);

    if (this._isLandscape) {
      this.scale.setTo(this._gameScale);
    }

    this.renderedBounds = this.getBounds();

    // change to onScreenResize
    this.pY = 0.12;
    this.hide(null, null, true);
  }

  /**
   * Update loop
   */
  update() {
    this.updatePYPosition(false);
  }

  /**
   * Displays a message, and shows itself if hidden
   * @param {{position:string, text:string, localize?:boolean}} data
   */
  displayMsg(data) {
    if (this.game === null) return;

    if (this.hidden) {
      if (data.position) {
        const position = this.parsePositionData(data.position);
        this.pY = TUTORIAL_POSITION[position];
        this.updatePYPosition(true);
      }
      this.show(() => {
        this.displayMsg(data);
      }, this);
    } else {
      if (data.position) {
        const position = this.parsePositionData(data.position);
        this.pY = TUTORIAL_POSITION[position];
      }
      this.gingy.talk();
      this.msg.onTextAnimationFinished.addOnce(() => {
        this.gingy.idle();
      }, this);
      this.msg.displayText(data.text, false, data.localize === false);

      if (data.gingy) {
        if (data.gingy.lookAt) this.gingy.lookAt(data.gingy.lookAt);
      }

      this.processIconData(data.icon);
    }
  }

  /**
   * Parses the position data and returns the correct one based on orientation if an object is passed
   * @param {object | string} position The position for the tutorial bar
   */
  parsePositionData(position) {
    if (typeof position === 'object') {
      if (OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal) {
        return position.landscape;
      }
      return position.portrait;
    }
    return position;
  }

  /**
   * Moves the bar to its new Y position
   * @param {boolean} optImmediate Do you want the animation to occur or want it instantly?
   */
  updatePYPosition(optImmediate) {
    if (optImmediate) {
      this.y = Math.floor(game.height * this.pY);
    } else {
      this.y = G.lerp(this.y, Math.floor(this.pY * game.height), 0.3, 1);
    }
  }

  /**
   * Processes icon data, changes it if needed and tweens it in
   * @param {object} iconData
   */
  processIconData(iconData) {
    if (iconData && iconData.sprite) {
      this.icon.scale.setTo(0);
      this.icon.changeTexture(iconData.sprite);
      this.icon.y = iconData.y || 160;
      game.add
        .tween(this.icon.scale)
        .to({ x: 1, y: 1 }, 300, Phaser.Easing.Sinusoidal.Out, true);
    } else {
      game.add
        .tween(this.icon.scale)
        .to({ x: 0, y: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true)
        .onComplete.add(() => {
          this.icon.changeTexture(null);
        }, this);
    }
  }

  /**
   * Hides the tutorial bar, optionally immediately
   * @param {Function} callback
   * @param {any} context
   * @param {boolean} immediate
   */
  hide(callback, context, immediate) {
    if (this.hidden) {
      if (callback) callback.call(context);
      return;
    }

    this.hidden = true;

    // move stuff to animation folder
    // for immediate - setProgressTo1()
    if (immediate) {
      this.msg.alpha = 0;
      this.gingy.scale.setTo(0);
      this.bar.scale.y = 0;
      this.iconContainer.scale.setTo(0);
      if (callback) callback.call(context);
    } else {
      game.add
        .tween(this.msg)
        .to({ alpha: 0 }, 150, Phaser.Easing.Sinusoidal.In, true);
      game.add
        .tween(this.gingy.scale)
        .to({ x: 0, y: 0 }, 150, Phaser.Easing.Sinusoidal.In, true);
      game.add
        .tween(this.bar.scale)
        .to({ y: 0 }, 200, Phaser.Easing.Sinusoidal.In, true, 150)
        .onComplete.add(() => {
          if (callback) callback.call(context);
        });
      game.add
        .tween(this.iconContainer.scale)
        .to({ x: 0, y: 0 }, 300, Phaser.Easing.Sinusoidal.In, true, 100);
    }
  }

  /**
   * Shows the tutorial bar, this always happens with the animation
   * @param {Function} callback
   * @param {any} context
   * @param {boolean} immediate
   */
  show(callback, context) {
    if (!this.hidden) {
      if (callback) callback.call(context);
      return;
    }

    this.hidden = false;

    this.bar.scale.y = 0;
    this.gingy.scale.setTo(0);

    game.add
      .tween(this.msg)
      .to({ alpha: 1 }, 150, Phaser.Easing.Sinusoidal.In, true);
    game.add
      .tween(this.bar.scale)
      .to({ y: 1 }, 400, Phaser.Easing.Elastic.Out, true);
    game.add
      .tween(this.gingy.scale)
      .to({ x: 1, y: 1 }, 800, Phaser.Easing.Elastic.Out, true, 100)
      .onComplete.add(() => {
        if (callback) callback.call(context);
      });
    game.add
      .tween(this.iconContainer.scale)
      .to({ x: 1, y: 1 }, 300, Phaser.Easing.Elastic.Out, true, 100);
  }
}
G.GingyTutorialBar = GingyTutorialBar;

/**
 * tiled background bar
 */
export class TutorialBarBackground extends Phaser.Group {
  /**
   * constructor
   * @param {number} minWidth minimum width of bar
   */
  constructor(minWidth) {
    super(game, null);
    this._draw(minWidth);
  }

  /**
   * draw tiled elements
   * @param {number} minWidth
   */
  _draw(minWidth) {
    const graphics = new Phaser.Group(game);
    this.addChild(graphics);

    while (graphics.width < minWidth) {
      G.makeImage(graphics.width, 0, 'tutorial_bar', [0, 0.5], graphics);
    }
    graphics.x -= graphics.width / 2;
  }
}
