import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

const AUTO_CLOSE_DELAY = 3000;

/**
 * popup telling you that you get a gift if you win the level
 */
class Window_MysteryGiftLevel extends Window {
  /**
   * constructor
   * @param {Object} parent
   */
  constructor(parent) {
    super(parent);

    this._initLayout();
    this._initButtons();

    this._timeEvent = game.time.events.add(AUTO_CLOSE_DELAY, () => {
      this.closeWindow();
    }, this);
  }

  /**
   * init layout items
   */
  _initLayout() {
    // background
    // this._gfx = this.add(game.add.graphics());
    // this._gfx.beginFill(0x000000, 0.8);
    // this._gfx.drawRect(
    //   -game.width * 0.6,
    //   game.height * -0.6,
    //   game.width * 1.2,
    //   game.height * 1.2,
    // );
    this.addBackground('popup_background_2');

    // game specific image
    this._img = G.makeImage(0, 95, 'MysteryGift_Cookie_boosts', 0.5, this);

    // title
    this._titleTxt = new G.Text(0, -272, OMT.language.getText('Mystery Gift Level'), G.OMTsettings.global.window.mediumTitle.textStyle, 0.5, 520);
    this._titleTxt.setShadow(2, 4, 'rgba(0,0,0,0.5)', 5);
    this.add(this._titleTxt);

    // body / description
    this._desc = new G.Text(0, -132, OMT.language.getText('Win this level now and get a cool mystery gift!'), {
      style: 'window-blue',
      fontSize: '40px',
    }, 0.5, 460, 120, true, 'center');
    this._desc.lineSpacing = -15;
    this.add(this._desc);
  }

  /**
   * init window buttons
   */
  _initButtons() {
    const onClick = () => {
      if (this._timeEvent) game.time.events.remove(this._timeEvent);
      this.closeWindow();
    };
    this._coolBtn = new OMT_UI_SquareButton(0, 312, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Cool'),
        textStyle: { style: 'font-white', fontSize: 50 },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });
    this.registerButtons(this._coolBtn);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.mysteryGiftLevel = Window_MysteryGiftLevel;
