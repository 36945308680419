import OMT_VILLAINS from '../../../../../OMT_UI/OMT_Villains';
import { OMT_SystemInfo, ORIENTATION } from '../../../../../Services/OMT/OMT_SystemInfo';
import { PROGRESS_MARKER_TYPE } from '../base/UI_ProgressBarUtils';
import UI_TopBarBase from '../base/UI_TopBarBase';
import UI_GoalPanelCollect from '../vertical/UI_GoalPanelCollect';
import UI_GoalPanelPointsHorizontal from './UI_GoalPanelPointsHorizontal';
import UI_GoalTournamentHorizontal from './UI_GoalTournamentHorizontal';
import UI_PointsCounterHorizontal from './UI_PointsCounterHorizontal';
import UI_ProgressBarHorizontal from './UI_ProgressBarHorizontal';
import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import { GameScaleController } from '../../../../../States/Scaling/GameScaleController';

const SIZE_SCALE = 0.90;
export default class UI_TopBarHorizontal extends UI_TopBarBase {
  /**
   * constructor
   */
  constructor(gameplayController, levelData, gameMode) {
    super(gameplayController, levelData, gameMode);
    this._gameScale = GameScaleController.getInstance().gameScale;
    this._scaleFactor = OMT.systemInfo.orientation === ORIENTATION.horizontal
      ? SIZE_SCALE * this._gameScale
      : SIZE_SCALE;
    // Adjust position and size
    // this.x = Math.round(-game.width / 2) + 772;
    // this.y = 75;
    // this.scale.setTo(0.89 * this._gameScale);
    this.scale.setTo(this._scaleFactor);
    this._signalBindings.push(G.sb('onScreenResize').add(this._onScreenResize.bind(this)));

    this._onScreenResize();
  }

  /**
   * Returns the moves group for positioning purposes
   * @returns {Phaser.Group}
   */
  get movesTxt() {
    return this._movesGroup;
  }

  /**
   * creat the goal panel
   * @param {Object} levelData
   * @param {string} gameMode
   */
  _createGoalLayout(levelData, gameMode) {
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const {
      isTokenEventLevel,
      isTournamentLevel,
      isDailyChallengeLevel,
      isTreasureHuntLevel,
    } = LvlDataManager.getInstance();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;

    // set the background type
    let topBarBackgroundTexture = 'horizontal-topBarNormal';
    if (isTournamentLevel) {
      topBarBackgroundTexture = 'horizontal-TopBarTournament';
    } else if (isNotNormalLevel) {
      topBarBackgroundTexture = useSuperHardGraphics ? 'horizontal-topBarSuperHard' : 'horizontal-topBarHard';
    } else if (isTokenEventLevel) {
      topBarBackgroundTexture = G.OMTsettings.tokenEvent.gameBoard.topbar.horizontal;
    } else if (isTreasureHuntLevel) {
      topBarBackgroundTexture = 'horizontal-topBarTreasureHunt';
    }
    this._createGameBG(topBarBackgroundTexture);

    // create moves display / counter
    this._createMovesGroup(gameMode);

    // progress bar + points counter
    // Don't track stars in event level
    let markerType = PROGRESS_MARKER_TYPE.STARS;
    if (isTokenEventLevel || isTreasureHuntLevel) markerType = PROGRESS_MARKER_TYPE.NONE;
    if (isDailyChallengeLevel) markerType = PROGRESS_MARKER_TYPE.COINS;

    // this.progressBar = new UI_ProgressBarHorizontal(-300, 205, markerType); // Accessed externally
    this.progressBar = new UI_ProgressBarHorizontal(3, -285, markerType); // Accessed externally

    // Determine styles for points counter
    let pointsCounterStyle;

    if (isTournamentLevel) {
      pointsCounterStyle = {
        points: 'UIPointsLabel-tournament-horizontal',
        amount: 'UIPointsAmount-tournament-horizontal',
      };
    } else if (isTreasureHuntLevel) {
      pointsCounterStyle = {
        points: 'UIPointsLabel-treasureHunt-horizontal',
        amount: 'UIPointsAmount-treasureHunt-horizontal',
      };
    } else if (isNotNormalLevel) {
      if (isSuperHardLevel) {
        pointsCounterStyle = {
          points: 'UIPointsLabel-superHard-horizontal',
          amount: 'UIPointsAmount-superHard-horizontal',
        };
      } else {
        pointsCounterStyle = {
          points: 'UIPointsLabel-hard-horizontal',
          amount: 'UIPointsAmount-hard-horizontal',
        };
      }
    } else {
      pointsCounterStyle = {
        points: 'UIPointsLabel-normal-horizontal',
        amount: 'UIPointsAmount-normal-horizontal',
      };
    }

    // this.pointsCounter = new UI_PointsCounterHorizontal(-303, 300, pointsCounterStyle); // Accessed externally
    this.pointsCounter = new UI_PointsCounterHorizontal(0, -190, pointsCounterStyle); // Accessed externally

    if (!isTournamentLevel) {
      let style;

      if (isTokenEventLevel) {
        style = 'UITopMovesLabel-tokenEvent-horizontal';
      } else if (isNotNormalLevel) {
        style = isSuperHardLevel ? OMT_VILLAINS.getPrefixedName('super_hard_2-horizontal') : OMT_VILLAINS.getPrefixedName('hard_2-horizontal');
      } else {
        style = 'UITopMovesLabel-normal-horizontal';
      }

      // const taskTxt = new G.Text(-303, 430, `${G.capitalize(OMT.language.getText('Task'))}:`, style, 0.5, 300);
      const taskTxt = new G.Text(0, -60, `${G.capitalize(OMT.language.getText('Task'))}:`, style, 0.5, 300);
      this.addChild(taskTxt);
      if (levelData.goal[0] === 'collect') {
        // this.goalPanel = new UI_GoalPanelCollect(-303, 625, gameMode); // Collecting gems
        this.goalPanel = new UI_GoalPanelCollect(0, 135, gameMode); // Collecting gems
      } else {
        // this.goalPanel = new UI_GoalPanelPointsHorizontal(-303, 625, gameMode); // Collecting points
        this.goalPanel = new UI_GoalPanelPointsHorizontal(0, 135, gameMode); // Collecting points
      }
    } else { // tournament play
      // this.goalPanel = new UI_GoalTournamentHorizontal(-303, 440); // Collecting points for tournament
      this.goalPanel = new UI_GoalTournamentHorizontal(0, -50); // Collecting points for tournament
      // hide the points counter and the progress bar
      this.progressBar.alpha = 0;
      // add tournament banner / streamer graphics
      this._createTournamentStreamer();
    }

    this._createTaskAreaGroup();

    this.addChild(this.progressBar);
    this.addChild(this.goalPanel);
    this.addChild(this.pointsCounter);
  }

  /**
   * init the bg graphics
   * @param {string} assetFrame
   */
  _createGameBG(assetFrame) {
    super._createGameBG();
    this.bg = G.makeImage( // Accessed externally
      0, 0,
      assetFrame,
      0.5,
      this,
    );
    this._bgWidth = this.bg.width * SIZE_SCALE; // Calculate actual panel width with scaling

    this._adjustBg();
  }

  /**
   * Function that adjusts the bg based on level mode
   */
  _adjustBg() {
    const { isTreasureHuntLevel, isTokenEventLevel } = LvlDataManager.getInstance();
    if (isTreasureHuntLevel) {
      const { offset } = G.OMTsettings.treasureHuntSuper.gameBoard.horizontal.topBar;
      this.bg.x += offset.x;
      this.bg.y += offset.y;
    } else if (isTokenEventLevel) {
      const offset = G.OMTsettings.tokenEvent.gameBoard.topbar.horizontalOffset;
      this.bg.x += offset.x;
      this.bg.y += offset.y;
    }
  }

  /**
   * Creates a group that has a rectangle encompassing the general tasks area
   */
  _createTaskAreaGroup() {
    // use this object to access the general task area, like highlighting
    if (OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal) {
      const { width, height, position } = this.bg;
      const { x, y } = position;
      const taskAreaDimensions = new Phaser.Group(game);
      taskAreaDimensions.width = (256 / 290) * width;
      taskAreaDimensions.height = (490 / 868) * height;
      taskAreaDimensions.x = x - width / 2 + (18 / 290) * width;
      taskAreaDimensions.y = y + (329 / 868) * height;
      this.taskAreaDimensions = taskAreaDimensions;
    } else {
      this.taskAreaDimensions = this.goalPanel;
    }
  }

  /**
   * create tournament banner / streamer image
   */
  _createTournamentStreamer() {
    super._createTournamentStreamer();
    this._tournamentBanner = G.makeImage(
      0, -25,
      'streamer_tournament',
      [0.5, 0],
      game.world, // add streamer to world to make centering it easier
    );

    this._tournamentBanner.scale.setTo(1.3 * this._gameScale);
    this._tournamentBanner.x = game.world.bounds.x + game.width / 2;
  }

  /**
   * create the moves display group
   * @param {LevelType} gameMode
   */
  _createMovesGroup(gameMode) {
    super._createMovesGroup(gameMode);
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const {
      isTokenEventLevel,
      isTournamentLevel,
      isDailyChallengeLevel,
      isTreasureHuntLevel,
    } = LvlDataManager.getInstance();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;
    this._movesGroup = new Phaser.Group(game);
    let movesX = -303;
    let movesY = -170;
    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      if (isTournamentLevel) {
        movesX = -300;
        movesY = 105;
      } else {
        movesX = -303;
        movesY = 150;
      }
    }
    this._movesGroup.x = movesX;
    this._movesGroup.y = movesY;
    this.add(this._movesGroup);

    let movesTxtStyle = 'UITopMovesLabel-normal-horizontal';
    let movesAmountTxtStyle = 'UITopMovesAmount-normal-horizontal';
    if (isTournamentLevel) {
      movesTxtStyle = 'UITopMovesLabel-tournament-horizontal';
      movesAmountTxtStyle = 'UITopMovesAmount-tournament-horizontal';
    } else if (isTokenEventLevel) {
      movesTxtStyle = 'UITopMovesLabel-tokenEvent-horizontal';
      movesAmountTxtStyle = 'UITopMovesAmount-tokenEvent-horizontal';
    } else if (isTreasureHuntLevel) {
      movesTxtStyle = 'UITopMovesLabel-treasureHunt-horizontal';
      movesAmountTxtStyle = 'UITopMovesAmount-treasureHunt-horizontal';
    } else if (isNotNormalLevel) {
      movesTxtStyle = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_2-horizontal') : OMT_VILLAINS.getPrefixedName('hard_2-horizontal');
      movesAmountTxtStyle = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_3-horizontal') : OMT_VILLAINS.getPrefixedName('hard_3-horizontal');
    }

    // Accessed externally
    // this._movesTxt = new G.Text(0, 0, OMT.language.getText('Moves'), movesTxtStyle, [0.5, 0], 160);
    this._movesTxt = new G.Text(303, -493, OMT.language.getText('Moves'), movesTxtStyle, [0.5, 0], 160);
    // moves counter
    // this._movesAmountTxt = new G.Text(0, 25, this._gameplayController.moves, movesAmountTxtStyle, [0.5, 0], 160);
    this._movesAmountTxt = new G.Text(303, -461, this._gameplayController.moves, movesAmountTxtStyle, [0.5, 0], 160);

    this._movesGroup.addChild(this._movesTxt);
    this._movesGroup.addChild(this._movesAmountTxt);
  }

  _onScreenResize() {
    this.x = game.world.bounds.x + (this._bgWidth * this._gameScale) / 2;
    this.y = game.world.bounds.y + game.height / 2 + 15;
  }
}
