/**
 * if the frame is infact a frame it will look up the baseTexture key. otherwise the frameName will be returned.
 * @param {string} frameName
 * @returns {string}
 */
function getBaseTexture(frameName) {
  if (game.cache.checkImageKey(frameName)) return frameName;
  return G.checkSheet(frameName);
}

/**
 * if the frame is actually a image key this will return null
 * @param {string} frameName
 * @returns string
 */
function getFrameName(frameName) {
  if (game.cache.checkImageKey(frameName)) return null;
  return frameName;
}

export class UI_NineSlice extends PhaserNineSlice.NineSlice {
  /**
   * A regular nine slice but with adjusted paramters to act like G.makeImage
   * To prevent issues when the spreadsheet is changed changed often
   * @param {number} xPos
   * @param {number} yPos
   * @param {string} frameName
   * @param {number} totalWidth
   * @param {number} totalHeight
   * @param {{top:number, bottom:number, left:number, right:number}} slice
   */
  constructor(xPos, yPos, frameName, totalWidth, totalHeight, slice) {
    super(xPos, yPos, getBaseTexture(frameName), getFrameName(frameName), totalWidth, totalHeight, slice);
  }
}
