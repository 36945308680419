/**
 * class for the token event leaderboard title / header
 */
export class TokenEvent_LeaderboardHeader extends Phaser.Group {
  /**
   * constructor
   * @param {Object} config will only render items passed
   * @param {Object} config.bg (optional)
   * @param {Object} config.titleBg (optional)
   * @param {Object} config.title (optional)
   * @param {Object} config.subTitleBg (optional)
   * @param {Object} config.subTitle (optional)
   * @param {Object} config.overlayImage (optional)
   */
  constructor(config) {
    super(game);

    this._config = config;

    this._initHeaderBG();
    this._initTitleGroup();
  }

  /**
   * create header bg graphics
   */
  _initHeaderBG() {
    if (this._config.bg) this._bg = G.makeImage(0, 0, this._config.bg.asset, [0.5, 0], this);
  }

  /**
   * init title text / graphics group
   */
  _initTitleGroup() {
    // creat title group
    const titleGroup = new Phaser.Group(game);
    this.addChild(titleGroup);
    if (this._config.scale) titleGroup.scale.set(this._config.scale);

    let titleBg; let title;
    let subTitleBg; let subTitle;
    let overlayImage;

    // main title bg
    const titleBgConfig = this._config.titleBg;
    if (titleBgConfig) {
      titleBg = G.makeImage(
        titleBgConfig.x,
        titleBgConfig.y,
        titleBgConfig.asset,
        [0.5, 0],
      );
      if (titleBgConfig.scale) titleBg.scale.set(titleBgConfig.scale);
    }
    // main title text
    const titleConfig = this._config.title;
    if (titleConfig) {
      const titleStyle = {
        ...G.Text.getStyle(titleConfig.baseStyle),
        fontSize: titleConfig.fontSize,
        align: 'center',
      };
      title = new G.Text(
        titleConfig.x, titleConfig.y,
        OMT.language.getText(titleConfig.text), titleStyle,
        [0.5, 0.5],
        titleConfig.maxWidth, titleConfig.maxHeight, false,
      );
    }

    // sub title bg
    const subTitleBgConfig = this._config.subTitleBg;
    if (subTitleBgConfig) {
      subTitleBg = G.makeImage(
        subTitleBgConfig.x,
        subTitleBgConfig.y,
        subTitleBgConfig.asset,
        [0.5, 0.5],
      );
    }
    // sub title text
    if (this._config.subTitle) {
      const subTitleStyle = {
        ...G.Text.getStyle(this._config.subTitle.baseStyle),
        fontSize: this._config.subTitle.fontSize,
        align: 'center',
      };
      subTitle = new G.Text(
        this._config.subTitle.x, this._config.subTitle.y,
        OMT.language.getText(this._config.subTitle.text), subTitleStyle,
        [0.5, 0.5],
        this._config.subTitle.maxWidth, this._config.subTitle.maxHeight, false,
      );
    }
    // overlay
    if (this._config.overlayImage) {
      overlayImage = G.makeImage(
        this._config.overlayImage.x,
        this._config.overlayImage.y,
        this._config.overlayImage.asset,
        [0.5, 0.5],
      );
    }

    // layering
    if (titleBg) titleGroup.addChild(titleBg);
    if (subTitleBg) titleGroup.addChild(subTitleBg);
    if (title) titleGroup.addChild(title);
    if (subTitle) titleGroup.addChild(subTitle);
    if (overlayImage) titleGroup.addChild(overlayImage);
    titleGroup.cacheAsBitmap = true;
  }

  /**
   * resize function
   */
  resize() {
    // Nothing for now
  }
}
