import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';
import { INTERSTITIAL_RULES } from '../Services/OMT/ads/OMT_InterstitialAdRules';
import { ORIENTATION } from '../Services/OMT/OMT_SystemInfo';
import { TextTimer } from './TextTimer';
import { GiftGraphic, GIFT_COLORS } from './Windows/GiftUI/GiftGraphic';

export default class MapGift extends Phaser.Group {
  /**
   * Config for the Map Gift shown on the side of the saga map
   * @param {{x:number, y:number}} config
   */
  constructor(config) {
    super(game, null);

    this._config = config || {};
    this._ignorePosition = !this._config.x && !this._config.y;
    if (!this._ignorePosition) {
      this.position.setTo(0, 240);
    }

    this._isMGUnlocked = OMT.feature.getFeature3hGiftActiveByLevel();

    this._msGiftCooldown = G.json.settings.mapGiftTimeMinutes * 60 * 1000;

    this.active = G.saveState.getUserCooldownRemaining('3h_gift', '') === 0;

    this._mapGiftContainer = new UI_GButtonES6(0, 0, null, this._open, this);

    const btnConfig = {
      giftType: 1,
      useFullGift: true,
    };
    const giftImage = new GiftGraphic(0, 20, btnConfig, this._mapGiftContainer);
    const giftImageDarkOverlay = this._btnDarkOverlay = new GiftGraphic(0, 20, { ...btnConfig, boxTint: GIFT_COLORS.Black, ribbonTint: GIFT_COLORS.Black }, this._mapGiftContainer);
    giftImageDarkOverlay.alpha = 0.5;

    giftImage.scale.setTo(0.5);
    giftImageDarkOverlay.scale.setTo(0.5);

    this.add(this._mapGiftContainer);

    if (this._isMGUnlocked) {
      this._addTimer();
      if (!this.active) {
        this._startTimer();
      }
      this._notificationBubble = new G.NotificationBubble(30, -25);
      this._mapGiftContainer.add(this._notificationBubble);
    } else {
      this._unlockTxt = new G.Text(0, 0,
        OMT.language.getText('Unlock at Level X').replace('X', G.featureUnlock.unlockLevels.isShowing3hGift + 1), 'ui-friendChest',
        0.5, 150, 150, true, 'center');
      this._unlockTxt.lineSpacing = -15;
      this._unlockTxt.setShadow(0, 0, 'black', 3);

      game.add.tween(this._unlockTxt.scale).to({ x: 0.9, y: 0.9 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

      this.add(this._unlockTxt);
    }

    if (!this._ignorePosition) {
      this._signalToken = G.sb('onScreenResize').add(this._onResize, this);
      this._onResize();
    }
  }

  destroy() {
    if (this._signalToken) {
      if (this._signalToken.detach) {
        this._signalToken.detach();
      }
      this._signalToken = null;
    }
    super.destroy();
  }

  update() {
    if (G.saveState.getUserCooldownRemaining('3h_gift', '') === 0) {
      this.active = true;
      this.ignoreChildInput = false;
    } else {
      if (this.active) {
        this._startTimer();
      }
      this.active = false;
      this.ignoreChildInput = true;
    }

    if (this._isMGUnlocked) {
      this._notificationBubble.setImportant(this.active);
      this.timer.visible = !this.active;
    }
    this._btnDarkOverlay.visible = !this.active;
    super.update();
  }

  _onResize() {
    const center = game.world.bounds.x + Math.floor(game.width * 0.5);
    this.x = center + G.OMTsettings.elements.mapGift.xCenterOffset;
    this.y = G.OMTsettings.elements.mapGift.y;
  }

  _open() {
    if (!this.active) { return; }
    if (!this._isMGUnlocked) { return; }
    if (OMT.feature.is3hGiftInShop()) {
      G.sb('pushWindow').dispatch(['coinShop', { freeGift: true }]);
    } else {
      const giftConfig = G.Utils.clone(G.json.settings.GingyGiftSettings);

      // Vertical settings are in json/settings(IAP).js
      // Horizontal settings are done outside here...!
      if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
        giftConfig.gingyOffset.x = 180;
        giftConfig.gingyOffset.y = 350;
        giftConfig.gingyAngle = 10;
        giftConfig.gingyScale = G.OMTsettings.elements.giftUI.sideGingy.gingyScale.horizontal;
      }

      const onClaim = () => {
        G.sb('mapGiftCollect').dispatch();
        G.saveState.setUserCooldown('3h_gift', '', 1000 * 60 * 60 * 3);
      };

      const getGifts = () => {
        if (G.IAP) {
          return [G.gift.getGift('3H_Gift')];
        }
        if (Math.random() < giftConfig.chanceToGetBooster) {
          return [G.gift.getGift('normalsGingyGift_boosters', true)];
        }
        return [G.gift.getGift('normalsGingyGift_coins', true)];
      };

      giftConfig.onClaim = onClaim;
      giftConfig.gifts = getGifts();
      giftConfig.placement = G.BuildEnvironment.adPlacements.double3hGift;
      if (!game.incentivised() || !G.featureUnlock.mapGiftDoubling) {
        giftConfig.monetiseType = false;
      }

      game.state.getCurrentState().checkForAds(() => {
        G.sb('pushWindow').dispatch(['gift', giftConfig], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
      }, INTERSTITIAL_RULES.GIFT_GAIN);
    }
  }

  _addTimer() {
    this.timer = new TextTimer({
      x: 0,
      y: G.OMTsettings.elements.mapGift.timer.y,
      style: {
        style: 'font-num-blue',
        fontSize: '30px',
      },
      anchor: 0.5,
      maxWidth: 130,
    });
    this.add(this.timer);
  }

  _startTimer() {
    const secLeft = G.saveState.getUserCooldownRemaining('3h_gift', '') / 1000;
    this.timer.setSecLeft(secLeft);
    this.timer.active = true;
  }

  addGlow() {
    this.glow = G.makeImage(0, 20, 'popup_lighht', 0.5, this);
    this.glow.alpha = 0.5;
    this.glow.scale.setTo(0.5);
    this.glow.blendMode = 1;
  }
}
