/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */

import { MainLeaderboardPanelTab } from './MainLeaderboardPanelTab';
import { UI_LoadingIndicatorDisplay } from '@omt-components/UI/Loading/UI_LoadingIndicatorDisplay';

import {
  TAB_POS, TAB_X_SPACING,
  PANEL_BG_FILL_COLOR, PANEL_BG_FILL_PADDING, PANEL_BG_MIDDLE_HEIGHT,
  PANEL_CONTENT_RECT,
  PANEL_LOADING_INDICATOR_COLLAPSED_Y, PANEL_LOADING_INDICATOR_EXPANDED_Y,
  PANEL_ERROR_TEXT_MAX_WIDTH, PANEL_ERROR_TEXT_STYLE, PANEL_ERROR_TEXT_COLLAPSED_OFFSET_X,
} from '../MainLeaderboardSettings';

const MAIN_CONTENT_FADE_IN_DURATION = 150;

/**
 * blank leaderboard base panel (used for friends, lives, etc)
 */
export class MainLeaderboardPanel extends Phaser.Group {
  /**
   * constructor
   * @param {string} panelName
   * @param {number} tabIndex
   */
  constructor(panelName, tabIndex) {
    super(game);

    this.signals = {
      onTabClicked: new Phaser.Signal(),
    };

    this._tabIndex = tabIndex;
    this._panelName = panelName;
    this._inFocus = false;
    this._isExpanded = false;
    this._expandRatio = 0;

    this._initContentBackground();
    this._initContentGroups();
    this._initContentMask();
    this._initContentOverlay();
    this._initPanelTab();

    this._initLoadingIndicator();
  }

  /**
   * get the panel name / id
   * @returns {string}
   */
  get panelName() {
    return this._panelName;
  }

  /**
   * initialize the content overlay layer
   */
  _initContentOverlay() {
    this._contentOverlay = new Phaser.Group(game);

    // create 4 piece border
    const topBorder = G.makeImage(0, 0, 'lb2_top_part_box', [0, 0], this._contentOverlay);
    const leftBorder = G.makeImage(0, 0, 'lb2_left_part_box', [0, 0], this._contentOverlay);
    const rightBorder = G.makeImage(0, 0, 'lb2_right_part_box', [0, 0], this._contentOverlay);
    const botBorder = G.makeImage(0, 0, 'lb2_bottom_part_box', [0, 0], this._contentOverlay);
    leftBorder.height = rightBorder.height = PANEL_BG_MIDDLE_HEIGHT;
    leftBorder.y = rightBorder.y = topBorder.height;
    rightBorder.x = topBorder.width - rightBorder.width;
    botBorder.y = topBorder.height + leftBorder.height;
    this.calculatedBounds = this._contentOverlay.getBounds().clone(); // Saves the bounds of the image. Used for positioning

    // add to stage and cache the image
    this.addChild(this._contentOverlay);
    this._contentOverlay.cacheAsBitmap = true;
  }

  /**
   * initialize the content background layer
   */
  _initContentBackground() {
    this._contentBackground = new Phaser.Graphics(game);
    const panelBgFillColor = this.getPanelBgFillColor();
    this._contentBackground.beginFill(panelBgFillColor, 1);
    this._contentBackground.drawRect(
      PANEL_CONTENT_RECT.x - PANEL_BG_FILL_PADDING, PANEL_CONTENT_RECT.y - PANEL_BG_FILL_PADDING,
      PANEL_CONTENT_RECT.width + (PANEL_BG_FILL_PADDING * 2), PANEL_CONTENT_RECT.height + (PANEL_BG_FILL_PADDING * 2),
    );
    this._contentBackground.endFill();
    this.addChildAt(this._contentBackground, 0);
  }

  /**
   * Returns the color of the content background
   * @returns {number}
   */
  getPanelBgFillColor() {
    if (OMT.feature.isTokenEventOn()) {
      const { PANEL_BG_FILL_COLOR: P_B_F_C } = G.OMTsettings.tokenEvent.mainLeaderboard;
      return P_B_F_C;
    }
    return PANEL_BG_FILL_COLOR;
  }

  /**
   * initialize panel select tab
   */
  _initPanelTab() {
    this._tab = new MainLeaderboardPanelTab(this._panelName);
    this._tab.x = TAB_POS.x + (this._tabIndex * TAB_X_SPACING);
    this._tab.y = TAB_POS.y;
    this.addChild(this._tab);
    this._tab.signals.onTabClicked.add(() => { this._onTabClick(); });
  }

  /**
   * on tab clicked
   */
  _onTabClick() {
    this.signals.onTabClicked.dispatch();
  }

  /**
   * initialize the content mask
   */
  _initContentMask() {
    const contentMask = new Phaser.Graphics(game);
    contentMask.beginFill(0xFF0000, 1);
    contentMask.drawRect(PANEL_CONTENT_RECT.x, PANEL_CONTENT_RECT.y, PANEL_CONTENT_RECT.width, PANEL_CONTENT_RECT.height);
    this.addChild(contentMask);
    this._mainContentGroup.mask = contentMask;
  }

  /**
   * initialize the exapnded / collapsed content groups
   */
  _initContentGroups() {
    this._mainContentGroup = new Phaser.Group(game, this);
    this._expandedContent = new Phaser.Group(game, this._mainContentGroup);
    this._collapsedContent = new Phaser.Group(game, this._mainContentGroup);
    this._expandedContent.alpha = 0;
    this._mainContentGroup.alpha = 0;
  }

  /**
   *  fade in the main content group
   * @returns {Promise}
   */
  async _fadeInMainContentGroupAsync() {
    return new Promise((resolve) => {
      this._mainContentTween = game.add.tween(this._mainContentGroup);
      this._mainContentTween.frameBased = true;
      this._mainContentTween.to({ alpha: 1 }, MAIN_CONTENT_FADE_IN_DURATION, Phaser.Easing.Quadratic.Out, false);
      this._mainContentTween.onComplete.addOnce(() => { resolve(); });
      this._mainContentTween.start();
    });
  }

  /**
   * get a reference to the expanded content group
   * @returns {Phaser.Group}
   */
  get expandedContent() {
    return this._expandedContent;
  }

  /**
   * get a reference to the collapsed content group
   * @returns {Phaser.Group}
   */
  get collapsedContent() {
    return this._collapsedContent;
  }

  /**
   * transition to the exanded state
   * @param {number} collapseDuration collapse duration
   * @param {number} expandFadeInDuration expanded content fade in duration
   * @param {number} collapseFadeOutDuration collapsed content fade in duration
   * @param {number} offsetY value panel will shift by
   */
  transitionToExpandedState(expandDuration, expandFadeInDuration, collapseFadeOutDuration, offsetY) {
    this._expandedContent.alpha = 0;
    this._expandedContent.visible = true;

    if (this._expandedContentTween) this._expandedContentTween.stop();
    this._expandedContentTween = game.add.tween(this._expandedContent);
    this._expandedContentTween.to({ alpha: 1 }, expandFadeInDuration, Phaser.Easing.Quadratic.Out, false, (expandDuration - expandFadeInDuration));
    this._expandedContentTween.start();

    if (this._collapsedContentTween) this._collapsedContentTween.stop();
    this._collapsedContentTween = game.add.tween(this._collapsedContent);
    this._collapsedContentTween.to({ alpha: 0 }, collapseFadeOutDuration, Phaser.Easing.Quadratic.Out, false);
    this._collapsedContentTween.start();
  }

  /**
   * transition to the collapsed state
   * @param {number} collapseDuration collapse duration
   * @param {number} expandFadeOutDuration expanded content fade out duration
   * @param {number} collapseFadeInDuration collapsed content fade in duration
   * @param {number} offsetY value panel will shift by
   */
  transitionToCollapsedState(collapseDuration, expandFadeOutDuration, collapseFadeInDuration, offsetY) {
    this._collapsedContent.alpha = 0;
    this._collapsedContent.visible = true;

    if (this._expandedContentTween) this._expandedContentTween.stop();
    this._expandedContentTween = game.add.tween(this._expandedContent);
    this._expandedContentTween.to({ alpha: 0 }, expandFadeOutDuration, Phaser.Easing.Quadratic.Out, false);
    this._expandedContentTween.start();

    if (this._collapsedContentTween) this._collapsedContentTween.stop();
    this._collapsedContentTween = game.add.tween(this._collapsedContent);
    this._collapsedContentTween.to({ alpha: 1 }, collapseFadeInDuration, Phaser.Easing.Quadratic.Out, false, collapseDuration);
    this._collapsedContentTween.start();
  }

  /**
   * called when the expand animation is complete
   */
  onExpandComplete() {
    this._isExpanded = true;
    this._collapsedContent.visible = false;
    this._updateContentState();
  }

  /**
   * called when the collapse animation is complete
   */
  onCollapseComplete() {
    this._isExpanded = false;
    this._expandedContent.visible = false;
    this._updateContentState();
  }

  /**
   * panel has gained focus
   */
  focus() {
    this._inFocus = true;
    this._tab.updateTabFrame(true);
    this.update();
    this._updateContentState();
  }

  /**
   * panel has glost focus
   */
  blur() {
    this._inFocus = false;
    this._tab.updateTabFrame(false);
    this._updateContentState();
  }

  /**
   * returns true if panel is the current focus
   * @returns {boolean}
   */
  get inFocus() {
    return this._inFocus;
  }

  /**
   * returns true if the panel is in the expanded state
   * @returns {boolean}
   */
  get isExpanded() {
    return this._isExpanded;
  }

  /**
   * update the content state. triggers on focus, blur, onCollapseComplete, onExpandComplete
   */
  _updateContentState() {
    if (this._inFocus) {
      this._contentOverlay.visible = true;
      this._contentBackground.visible = true;
      // show focussed content
      this._expandedContent.visible = this._isExpanded;
      this._collapsedContent.visible = !this._isExpanded;
    } else {
      this._contentOverlay.visible = false;
      this._contentBackground.visible = false;
      // hide blurred content
      this._expandedContent.visible = false;
      this._collapsedContent.visible = false;
    }
  }

  /**
   * initialize loading indicator
   */
  _initLoadingIndicator() {
    this._loadingIndicator = new UI_LoadingIndicatorDisplay();
    this.addChild(this._loadingIndicator);
    this._updateLoadingIndicatorPosition(this._expandedContent);
  }

  /**
   * update the loading indicators position
   * @param {number} expandRatio 0-1
   */
  _updateLoadingIndicatorPosition(expandRatio) {
    const expandedOffsetY = PANEL_LOADING_INDICATOR_EXPANDED_Y - PANEL_LOADING_INDICATOR_COLLAPSED_Y;
    this._loadingIndicator.x = PANEL_CONTENT_RECT.width / 2;
    this._loadingIndicator.y = PANEL_LOADING_INDICATOR_COLLAPSED_Y + (expandedOffsetY * expandRatio);
  }

  /**
   * shows the error text field
   */
  _showErrorText() {
    const errorText = `${OMT.language.getText('Leaderboards currently unavailable')}\n${OMT.language.getText('Will be back soon!')}`;
    this._errorField = new G.Text(
      0, 0, errorText, PANEL_ERROR_TEXT_STYLE, [0.5, 0.5],
      PANEL_ERROR_TEXT_MAX_WIDTH, null, true, 'center', true, true, true,
    );
    this._mainContentGroup.addChild(this._errorField);
    this._updateErrorTextPosition(this._expandRatio);
  }

  /**
   * update error text field position
   * @param {number} expandRatio 0-1
   */
  _updateErrorTextPosition(expandRatio) {
    const expandedOffsetY = PANEL_LOADING_INDICATOR_EXPANDED_Y - PANEL_LOADING_INDICATOR_COLLAPSED_Y;
    this._errorField.x = (PANEL_CONTENT_RECT.width / 2) + (PANEL_ERROR_TEXT_COLLAPSED_OFFSET_X * (1 - expandRatio));
    this._errorField.y = PANEL_LOADING_INDICATOR_COLLAPSED_Y + (expandedOffsetY * expandRatio);
  }

  /**
   * update the panel content expanded %
   * @param {number} expandRatio 0-1
   */
  updateExpandedRatio(expandRatio) {
    this._updateLoadingIndicatorPosition(expandRatio);
    if (this._errorField) this._updateErrorTextPosition(expandRatio);
    this._expandRatio = expandRatio;
  }

  /**
   * dont bubble update if not in focus
   */
  update() {
    if (this._inFocus) super.update();
  }

  /**
   * destruction method
   */
  destroy() {
    if (this._mainContentTween) this._mainContentTween.stop();
    Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    super.destroy();
  }
}
