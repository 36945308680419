import {
  getVersion as GBC_getVersion,
} from '@sgorg/game-backend-client';
import TimeUtil from '@omt-components/Utils/TimeUtil';

let _instance = null; // instance reference for singleton

const HIGH_FPS_MIN_CUTOFF = 30;

/**
 * class for performance monitoring
 */
export class OMT_PerformanceMonitor {
  /**
   * get singleton instance
   */
  static getInstance() {
    if (_instance === null) {
      _instance = new OMT_PerformanceMonitor();
    }
    return _instance;
  }

  /**
   * constuctor
   */
  constructor() {
    this._initalized = false;
  }

  /**
   * intialize the performance monitor
   */
  init() {
    if (this._initalized) return;
    this._initalized = true;

    // session tracking values
    this._totalSecsPassed = 0; // seconds passed since we started counting frames
    this._totalFrameCount = 0; // frame count for the session

    // seconds tracking values
    this._lastSecStart = -1; // Date.now() start of second tracking
    this._currentSecFrameCount = 0; // frame count for the current second

    // minute tracking values
    this._currentMinFrameCount = 0;
    this._highFPSMin = 0;
    this._lowFPSMin = 0;
    this._lastMinFPS = -1;

    // scene tracking values
    this._scene = game.state.current;
    this._sceneFrameCount = 0;
    this._sceneSecsPassed = 0;

    this.overlayVisible = false;
  }

  /**
   * detect if the scene changed, and reset related values if it did
   */
  _detectSceneChange() {
    const scene = game.state.current;
    const sceneChanged = this._scene !== scene;
    if (sceneChanged) { // reset scene tracking values
      this._scene = scene;
      this._sceneFrameCount = 0;
      this._sceneSecsPassed = 0;
    }
    return sceneChanged;
  }

  /**
   * record if the last minute was a high or low fps count
   * @param {number} fpsCount FPS for the last minute passed
   */
  _recordHighOrLowFPSMin(fpsCount) {
    if (fpsCount >= HIGH_FPS_MIN_CUTOFF) this._highFPSMin++;
    else this._lowFPSMin++;

    // G.Utils.SentryLog.setFPS(this.sessionAverageFPS);
    // console.log(`HIGH FPS MINS: ${this._highFPSMin}, LOW FPS MINS: ${this._lowFPSMin}`);
  }

  /**
   * get count of HIGH (fps >= HIGH_FPS_MIN_CUTOFF) fps minutes
   * @returns {number}
   */
  get highFPSMinCount() {
    return this._highFPSMin;
  }

  /**
   * get count of LOW (fps < HIGH_FPS_MIN_CUTOFF) fps minutes
   * @returns {number}
   */
  get lowFPSMinCount() {
    return this._lowFPSMin;
  }

  /**
   * get the current fps, sources from the Phaser engine
   * @returns {number}
   */
  get currentFPS() {
    return game.time.fps;
  }

  /**
   * get the averate FPS for the last minute
   * @returns {number}
   */
  get lastMinAverageFPS() {
    if (this._lastMinFPS === -1) return this.sessionAverageFPS;
    return this._lastMinFPS;
  }

  /**
   * get average FPS for the session
   * @returns {number}
   */
  get sessionAverageFPS() {
    if (this._totalSecsPassed === 0) return this.currentFPS; // no data yet get current fps
    const sessionAvgFPS = Math.floor(this._totalFrameCount / this._totalSecsPassed);
    return sessionAvgFPS;
  }

  /**
   * get average FPS for the scene
   * @returns {number}
   */
  get sceneAverageFPS() {
    if (this._sceneSecsPassed === 0) return this.currentFPS; // no data yet get current fps
    const sceneAvgFPS = Math.floor(this._sceneFrameCount / this._sceneSecsPassed);
    return sceneAvgFPS;
  }

  /**
   * init the HTML performance stats overlay
   */
  initStatsDisplay() {
    // init fps overlayElement HTML
    const overlayElement = document.createElement('div');
    overlayElement.style.top = '0%';
    overlayElement.style.left = '0%';
    overlayElement.style.position = 'fixed';
    overlayElement.style.width = '170px';
    overlayElement.style.height = '120px';
    overlayElement.style.color = 'rgb(0,255,0)';
    overlayElement.style.backgroundColor = 'rgba(0,0,0,0.6)';
    overlayElement.style.paddingTop = '10px';
    overlayElement.style.paddingLeft = '10px';
    overlayElement.style.fontSize = '15px';
    overlayElement.style.pointerEvents = 'none';
    document.body.appendChild(overlayElement);

    // renderer text
    const rendererTextElement = document.createElement('span');
    rendererTextElement.style.position = 'absolute';
    rendererTextElement.innerHTML = 'current fps: 0';
    rendererTextElement.style.top = '0px';
    overlayElement.appendChild(rendererTextElement);
    rendererTextElement.innerHTML = (game.renderType === Phaser.WEBGL) ? 'renderer: WEB-GL' : 'renderer: CANVAS';

    // sessionTime text
    const sessionTimeTextElement = document.createElement('span');
    sessionTimeTextElement.style.position = 'absolute';
    sessionTimeTextElement.innerHTML = 'current fps: 0';
    sessionTimeTextElement.style.top = '20px';
    overlayElement.appendChild(sessionTimeTextElement);
    sessionTimeTextElement.innerHTML = 'session: 0';

    // current fps text
    const curentFPSTextElement = document.createElement('span');
    curentFPSTextElement.style.position = 'absolute';
    curentFPSTextElement.innerHTML = 'current-fps: 0';
    curentFPSTextElement.style.top = '40px';
    overlayElement.appendChild(curentFPSTextElement);

    // scene fps text
    const sessionFPSTextElement = document.createElement('span');
    sessionFPSTextElement.style.position = 'absolute';
    sessionFPSTextElement.innerHTML = 'session-average-fps: 0';
    sessionFPSTextElement.style.top = '60px';
    overlayElement.appendChild(sessionFPSTextElement);

    // scene fps text
    const sceneFPSTextElement = document.createElement('span');
    sceneFPSTextElement.style.position = 'absolute';
    sceneFPSTextElement.innerHTML = 'scene-average-fps: 0';
    sceneFPSTextElement.style.top = '80px';
    overlayElement.appendChild(sceneFPSTextElement);

    // gbc version text
    const gbcVersionTextElement = document.createElement('span');
    gbcVersionTextElement.style.position = 'absolute';
    gbcVersionTextElement.innerHTML = `gbc-version: ${GBC_getVersion()}`;
    gbcVersionTextElement.style.top = '100px';
    overlayElement.appendChild(gbcVersionTextElement);

    // set references for fields that need updates
    this._overlayElement = overlayElement;
    this._sessionTimeTextElement = sessionTimeTextElement;
    this._curentFPSTextElement = curentFPSTextElement;
    this._sessionFPSTextElement = sessionFPSTextElement;
    this._sceneFPSTextElement = sceneFPSTextElement;
    this._gbcVersionTextElement = gbcVersionTextElement;

    this._updateStatsDisplay(); // force a update
    this.overlayVisible = true;
  }

  /**
   * removes the HTML performance stats overlay
   */
  removeStatsDisplay() {
    document.body.removeChild(this._overlayElement);
    this._curentFPSTextElement = null;
    this._sessionFPSTextElement = null;
    this._sceneFPSTextElement = null;
    this._gbcVersionTextElement = null;
    this._overlayElement = null;

    this.overlayVisible = false;
  }

  /**
   * update the HTML performance stats overlay
   */
  _updateStatsDisplay() {
    this._curentFPSTextElement.innerHTML = `current-fps: ${this.currentFPS}`;
    this._sessionFPSTextElement.innerHTML = `session-average-fps: ${this.sessionAverageFPS}`;
    this._sceneFPSTextElement.innerHTML = `scene-average-fps: ${this.sceneAverageFPS}`;
    this._sessionTimeTextElement.innerHTML = `session: ${TimeUtil.getTimeCode(this._totalSecsPassed * 1000)}`;
    // Note: gbcVersionTextElement doesn't need to be updated, GBC version only changes at buildtime
  }

  /**
   * update the performance monitor
   */
  update() {
    if (!this._initalized) return;

    // detect scene change
    this._detectSceneChange();

    const currentTime = Date.now();
    // a second has passed
    if (this._lastSecStart === -1 || currentTime >= this._lastSecStart + 1000) {
      this._lastSecStart = currentTime;
      this._currentSecFrameCount = 0;
      this._totalSecsPassed++;
      this._sceneSecsPassed++;
      // a minute has passed
      if (this._totalSecsPassed % 60 === 0) {
        this._lastMinFPS = Math.floor(this._currentMinFrameCount / 60);
        this._currentMinFrameCount = 0;
        this._recordHighOrLowFPSMin(this._lastMinFPS);
      }
      // update the HTML stats display if initialized
      if (this._overlayElement) this._updateStatsDisplay();
    }
    // update frame counters
    this._currentSecFrameCount++;
    this._currentMinFrameCount++;
    this._sceneFrameCount++;
    this._totalFrameCount++;
  }
}
