/* eslint-disable no-unused-vars */

/**
 * Enums for level node types
 */
export const levelNodeType = {
  NORMAL: 'normal',
  HARD: 'hard',
  SUPERHARD: 'superHard',
  GATE: 'gate',
};

/**
 * Enum for interation types
 */
export const interactionType = {
  mailbox: 'mailbox',
  chestMap: 'chestmap', // The normal saga map chest
  chestShuffle: 'chestshuffle',
};

export const WorldMap2_globalSignals = {
  recheckGate: 'worldmap2recheckGate',
  recheckInteractions: 'worldmap2recheckInteractions',
  mapClicked: 'worldmap2MapClicked',
};

export const WorldMapTwoMapInteractionLimit = 10; // The number of levels past that map interactions can be made

export default class WorldMap2_Util {
  /**
   * Gets tile sprite asset name in array form
   * @returns {Array<string>}
   */
  static getTileSprites() {
    return G.OMTsettings.elements.worldMap.tiles;
  }

  /**
   * Gets the background colour of the map
   * @returns {number}
   */
  static getMapBackgroundColour() {
    return G.OMTsettings.elements.worldMap.grassColour;
  }

  /**
   * Returns the river tint colours.
   * @returns {Array<number>}
   */
  static getRiverColours() {
    return G.OMTsettings.elements.worldMap.riverTint;
  }

  /**
   * Returns the string of the level node. Not working atm
   * @param {string} normal
   */
  static getNormalLevelNode(normal) {
    return normal;
  }

  /**
   * Returns array entry at index.
   * Numbers greater than the array length will loop around from the beginning.
   * Numbers less than 0 will loop around from the end.
   * @param {Array<any>} arr
   * @param {number} index
   */
  static getEntryInArrayLoop(arr, index) {
    return arr[(((index % arr.length)) + arr.length) % arr.length];
  }

  /**
   * With 2 random keys, this will generate a number seed that can be used
   * @param {any} x
   * @param {any} y
   */
  static getRNGSeedMap(x, y) {
    const rnX = Math.abs(parseInt(x.toString(), 36) % dynamicWorldSeedMap.length); // eslint-disable-line no-use-before-define
    const rnY = Math.abs(parseInt(y.toString(), 36) % dynamicWorldSeedMap.length); // eslint-disable-line no-use-before-define
    return dynamicWorldSeedMap[(rnX + dynamicWorldSeedMap[rnY]) % dynamicWorldSeedMap.length]; // eslint-disable-line no-use-before-define
  }

  /**
   * Calculates the number of levels that you would be at a certain tile index
   * @param {number} tile
   */
  static getLevelCountAtTile(tile) {
    let counter = 0;
    const isNeg = tile < 0 ? -1 : 1;
    for (let i = 0; i <= Math.abs(tile); i++) {
      counter += this.getEntryInArrayLoop(G.OMTsettings.elements.worldMap.levelNodePositions, i * isNeg).length;
    }
    return counter;
  }

  /**
   * Calculates what tile and node position targetLevel would be at
   * @param {number} targetLevel
   * @returns {{tile:number, nodeIndex:number}}
   */
  static findTileFromLevel(targetLevel) {
    const levelNodes = G.OMTsettings.elements.worldMap.levelNodePositions;

    let tileCount = 0;
    let levelIndex = 0;
    let levelCount = 0;
    while (levelCount < targetLevel) { // Counts up to the tile it needs to be
      const numOfLevelNodesHere = this.getEntryInArrayLoop(levelNodes, tileCount).length;
      if (levelCount + numOfLevelNodesHere < targetLevel) {
        levelCount += numOfLevelNodesHere;
        tileCount++; // Increment tile
      } else {
        levelIndex = targetLevel - levelCount - 1; // This is the index of the node on the tile. It starts from 0
        levelCount += numOfLevelNodesHere;
      }
    }
    return {
      tile: tileCount,
      nodeIndex: levelIndex,
    };
  }

  /**
   * Copies properties from `source` to `target`
   * Code taken from https://hacks.mozilla.org/2015/08/es6-in-depth-subclassing/
   * @param {any} target
   * @param {any} source
   */
  static copyClassProperties(target, source) {
    for (const key of Reflect.ownKeys(source)) {
      if (key !== 'constructor' && key !== 'prototype' && key !== 'name') {
        const desc = Object.getOwnPropertyDescriptor(source, key);
        Object.defineProperty(target, key, desc);
      }
    }
  }
}

const dynamicWorldSeedMap = [242, 219, 238, 30, 66, 230, 109, 51, 247, 27, 162, 69, 237, 47, 191, 13, 55, 54, 114,
  177, 211, 192, 29, 120, 163, 68, 16, 25, 35, 132, 223, 21, 149, 226, 101, 131, 41, 49,
  61, 162, 30, 111, 240, 174, 193, 208, 98, 145, 228, 56, 87, 117, 49, 201, 196, 62, 199,
  37, 200, 203, 160, 88, 77, 137, 101, 118, 155, 243, 232, 161, 187, 31, 7, 138, 144, 84,
  60, 109, 130, 124, 133, 98, 102, 202, 141, 97, 240, 252, 224, 72, 207, 40, 143, 63, 219,
  173, 104, 209, 61, 205, 212, 176, 96, 59, 123, 158, 227, 173, 11, 146, 132, 28, 183,
  236, 214, 239, 5, 52, 178, 151, 77, 217, 99, 102, 177, 152, 227, 247, 113, 15, 34, 203,
  154, 126, 79, 26, 156, 78, 245, 245, 135, 222, 111, 207, 221, 18, 22, 17, 72, 48, 195,
  75, 81, 90, 97, 232, 197, 103, 159, 217, 220, 250, 117, 189, 244, 180, 241, 250, 215, 136,
  185, 125, 155, 198, 42, 112, 90, 228, 0, 119, 20, 39, 243, 23, 178, 197, 147, 47, 87,
  204, 133, 172, 183, 165, 139, 71, 38, 29, 45, 179, 123, 224, 134, 128, 108, 225, 91, 84,
  142, 113, 13, 248, 32, 130, 64, 167, 122, 52, 38, 221, 115, 116, 67, 94, 246, 124, 246,
  181, 110, 74, 190, 254, 239, 93, 50, 36, 213, 107, 41, 186, 31, 4, 212, 171, 143, 57, 157,
  149, 248, 126, 145, 92, 37, 166, 86, 190, 116, 67, 168, 95, 86, 107, 235, 89, 180, 66, 236,
  160, 215, 119, 65, 118, 156, 202, 122, 45, 6, 7, 157, 213, 12, 179, 8, 21, 194, 100, 108,
  69, 238, 8, 188, 170, 164, 18, 2, 181, 189, 35, 234, 92, 27, 129, 229, 194, 10, 105, 2, 242,
  153, 166, 24, 76, 14, 252, 26, 150, 89, 153, 234, 95, 147, 163, 210, 184, 0, 71, 135, 82,
  105, 15, 151, 17, 82, 161, 170, 42, 68, 44, 209, 6, 1, 220, 144, 216, 198, 231, 210, 73,
  249, 152, 128, 3, 237, 182, 34, 53, 121, 5, 9, 73, 44, 172, 218, 140, 131, 32, 146, 83, 54,
  188, 138, 63, 253, 24, 191, 62, 216, 230, 16, 19, 106, 100, 3, 33, 120, 11, 60, 226, 70, 208,
  1, 159, 40, 171, 22, 129, 235, 93, 64, 150, 23, 187, 106, 192, 222, 175, 14, 201, 169, 70,
  164, 200, 4, 241, 175, 184, 94, 104, 233, 154, 174, 57, 125, 80, 249, 176, 168, 253, 244, 211,
  85, 25, 196, 127, 48, 231, 33, 185, 137, 46, 115, 134, 50, 205, 46, 36, 65, 141, 195, 28,
  74, 121, 55, 140, 251, 81, 193, 110, 206, 186, 78, 76, 254, 251, 79, 214, 165, 103, 39, 136,
  218, 51, 148, 182, 225, 96, 80, 19, 53, 142, 233, 112, 139, 206, 20, 204, 127, 91, 199, 169,
  58, 10, 148, 88, 85, 59, 9, 229, 167, 43, 56, 75, 114, 58, 99, 83, 158, 223, 12, 43];
