import OMT_UI_SquareButton, { BUTTONCOLOURS, BUTTONSTATE } from "../../OMT_UI/OMT_UI_SquareButton";

/**
 * button for buying time for automatic boosters
 */
export default class AutoBoosterBuyTimeBtn extends OMT_UI_SquareButton {
  constructor(x, y, price, label) {
    super(x, y, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 445,
          height: 100,
        },
      },
    });

    this.price = price;
    this.label = label;
    // this.IMMEDIATE = true;

    this.createLabelForButton();

    this.prevRefreshState = true;
    this.refreshState(true);
  }

  refreshState(force = false) {
    if (this.game === null || G.IAP) return; // we continue only for non IAP users

    const canAffordItem = G.saveState.getCoins() >= this.price;
    if (force || canAffordItem !== this.prevRefreshState) {
      this.prevRefreshState = canAffordItem;
      if (canAffordItem) {
        this.currentState = BUTTONSTATE.ENABLED;
      } else {
        this.currentState = BUTTONSTATE.DISABLED;
      }
    }
  }

  createLabelForButton() {
    const priceLabel = new G.LabelTextT(
      `${this.label}   ${OMT.language.toLocaleNumber(this.price)}@coin_1@`,
      0, 0, {
        style: 'font-white',
        fontSize: 30,
      }, 0.5, this.width,
    );
    this.addChild(priceLabel);
  }

  update() {
    this.refreshState();
  }
}
