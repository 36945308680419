/* eslint-disable operator-linebreak */
/* eslint-disable no-bitwise */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-prototype-builtins */
if (typeof G === 'undefined') G = {};
G.UI = G.UI || {};

// TODO: consider passing path to json instead of data directly
// so that jsons with child jsons can resolve path relative to their location
/**
 * @returns result {G.UI.UIElement}
 */
G.UI.createUIElement = function createUIElement(
  game,
  data,
  optScaleX,
  optScaleY,
  optGetJsonData,
  optFilterFunc,
) {
  // imports
  const {
    ElementType,
    ComponentType,
    ExportsManager,
    createUIComponent,
    addUIComponent,
  } = G.UI;

  const localize = G.txt;
  const { defined, clone } = G.Utils;
  const { Text, Button } = G;
  const makeImage = G.makeImage.bind(G);

  const makeGraphics = () => game.add.graphics();
  const makeGroup = () => game.add.group();
  const makeNineSlice = (cacheKey) => {
    const cacheKeySplit = cacheKey.split('/');
    const spritesheet = cacheKeySplit[cacheKeySplit.length - 2];
    const frame = cacheKeySplit[cacheKeySplit.length - 1];

    const assetsFolder = 'assets';
    const jsonKey = `${assetsFolder}/${cacheKey}`;

    if (G.json.hasOwnProperty(jsonKey)) {
      return G.Utils.NineSlice.fromJSON(jsonKey, 0, 0, 1, 1);
    }

    console.warn(`No JSON found for ${jsonKey} nine-slice!`);
    return new PhaserNineSlice.NineSlice(0, 0, spritesheet, frame, 1, 1, {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    });
  };

  const canProcessChildData = (childData) =>
    !optFilterFunc || optFilterFunc(childData.exportNames || []);

  const composeFuncs = (func1Ctx, func1, func2Ctx, func2) => {
    const combined = (...args) => {
      if (func1) {
        func1.apply(func1Ctx, args);
      }
      if (func2) {
        func2.apply(func2Ctx, args);
      }
    };

    return combined;
  };

  const getComponentData = (components, type) => {
    if (!components) return undefined;

    const found = components.find((component) => component.type === type);

    if (found) {
      return found.data;
    }

    return found;
  };

  const handleTransparencyComponent = (child, componentData) => {
    child.alpha = defined(componentData.alpha, 1.0);
  };

  const handleVisibilityComponent = (_child, componentData) => {
    if (componentData.hidden) {
      _child.visible = false;
      _child.exists = false;
    }
  };

  const handleInputComponent = (_child, componentData) => {
    if (componentData.enabled) {
      _child.inputEnabled = true;
    }
  };

  const createGraphics = (childData) => {
    const _data =
      getComponentData(childData.components, ComponentType.Rect) || {};
    const child = makeGraphics();
    child.beginFill(
      !_data.color
        ? 0x000000
        : (_data.color[0] << 16) |
            (_data.color[1] << 8) |
            (_data.color[2] << 0),
    );
    child.drawRect(0, 0, 16, 16);
    return child;
  };

  const getCacheKey = (url) => {
    if (!url) return url;
    const index = url.lastIndexOf('/');

    return url.substr(index + 1);
  };

  const createImage = (childData) => {
    const _data =
      getComponentData(childData.components, ComponentType.Image) || {};
    const child = makeImage(0, 0, getCacheKey(_data.cacheKey), 0, 0);
    if (!defined(_data.antialiasing, true)) {
      child.smoothed = false;
    }
    return child;
  };

  const createNineSlice = (childData) => {
    const _data =
      getComponentData(childData.components, ComponentType.Image) || {};
    const child = makeNineSlice(_data.cacheKey);
    return child;
  };

  const createGroup = () => {
    const child = makeGroup();
    return child;
  };

  const scaledTextStyle = (style, scale) => {
    let result = style;
    if (style && style.fontSize) {
      result = clone(style);
      result.fontSize = result.fontSize.replace(
        /(\d+(?:\.\d+)?)/,
        (str, strSize) => String(Number(strSize) * scale),
      );
      // TODO: consider scaling stroke width
    }

    return result;
  };

  const createText = (childData, scaleX, scaleY) => {
    const _data =
      getComponentData(childData.components, ComponentType.Text) || {};

    let textValue = _data.textLocalizationKey
      ? localize(_data.textLocalizationKey)
      : _data.textUnlocalized;
    textValue = textValue || '';

    let { maxWidth } = _data;
    if (maxWidth) maxWidth *= scaleX;

    let { maxHeight } = _data;
    if (maxHeight) maxHeight *= scaleY;

    const child = new Text(
      0,
      0,
      textValue,
      _data.style ? scaledTextStyle(_data.style, Math.min(scaleX, scaleY)) : {},
      0,
      maxWidth,
      maxHeight,
      _data.textWrap,
      _data.align,
      true,
      true,
      _data.scaleUniformly,
    );

    return child;
  };

  const createButton = (childData) => {
    const _data =
      getComponentData(childData.components, ComponentType.Image) || {};
    const child = new Button(0, 0, getCacheKey(_data.cacheKey));
    if (defined(_data.dontScaleOnClick)) {
      child.scaleOnClick = !_data.dontScaleOnClick;
    }
    child.anchor.setTo(0);

    return child;
  };

  const createChildBase = (childData, scaleX, scaleY) => {
    switch (childData.type) {
      case ElementType.Rect:
        return createGraphics(childData);
      case ElementType.Image:
        return createImage(childData);
      case ElementType.NineSlice:
        return createNineSlice(childData);
      case ElementType.Group:
        return createGroup();
      case ElementType.JSON:
        return createGroup();
      case ElementType.Text:
        return createText(childData, scaleX, scaleY);
      case ElementType.Button:
        return createButton(childData);

      default:
        console.error('Unsupported element type:', childData.type);
    }
    return null;
  };

  const handleJSONComponent = (
    child,
    componentData,
    desiredSize,
    getJsonData,
    exportFunc,
  ) => {
    if (!componentData || !componentData.cacheKey) return undefined;
    const grandChildData = getJsonData(componentData.cacheKey);
    // eslint-disable-next-line no-use-before-define
    const grandChildResult = createChild(
      grandChildData.rootElement,
      desiredSize,
      // current scaleX / scaleY already incorporated via desiredSize
      desiredSize.x / grandChildData.desiredSize[0],
      desiredSize.y / grandChildData.desiredSize[1],
      getJsonData,
      exportFunc,
    );

    return grandChildResult.rootElement;
  };

  const finishChildCreation = (
    _child,
    childData,
    parentSize,
    scaleX,
    scaleY,
    _optGetJsonData,
    exportFunc,
  ) => {
    if (childData.name) {
      _child.name = childData.name;
    }

    const layoutData = childData.layout || {};
    const uiComponent = createUIComponent(
      childData.type,
      parentSize.x,
      parentSize.y,
      layoutData,
      scaleX,
      scaleY,
    );

    addUIComponent(_child, uiComponent);

    if (childData.type === ElementType.Text) {
      // Text's width and height cannot be manipulated directly as it affects its scale
      // Hence using anchor for layouting
      _child.anchor.setTo(uiComponent.pivot.x, uiComponent.pivot.y);
    } else {
      _child.pivot.set(
        uiComponent.pivot.x * _child.width,
        uiComponent.pivot.y * _child.height,
      );
    }

    if (layoutData.angle) {
      _child.angle = layoutData.angle;
    }

    if (_optGetJsonData && childData.type === ElementType.JSON) {
      const grandChild = handleJSONComponent(
        _child,
        getComponentData(childData.components, ComponentType.JSON),
        uiComponent.desiredSize,
        _optGetJsonData,
        exportFunc,
      );
      if (grandChild) _child.addChild(grandChild);
    }

    if (childData.children) {
      childData.children.forEach((grandChildData) => {
        // eslint-disable-next-line no-use-before-define
        const grandChildResult = createChild(
          grandChildData,
          uiComponent.desiredSize,
          scaleX,
          scaleY,
          _optGetJsonData,
          exportFunc,
        );
        if (grandChildResult.rootElement) {
          _child.addChild(grandChildResult.rootElement);
        }
      });
    }

    handleTransparencyComponent(
      _child,
      getComponentData(childData.components, ComponentType.Transparency) || {},
    );

    handleVisibilityComponent(
      _child,
      getComponentData(childData.components, ComponentType.Visibility) || {},
    );

    handleInputComponent(
      _child,
      getComponentData(childData.components, ComponentType.Input) || {},
    );
  };

  const createChild = (
    childData,
    parentSize,
    scaleX,
    scaleY,
    _optGetJsonData,
    optParentExportFunc,
  ) => {
    const exportsManager = new ExportsManager();

    const exportFunc = composeFuncs(
      null,
      optParentExportFunc,
      exportsManager,
      exportsManager.exportElement,
    );

    const result = {
      rootElement: null,
      exports: exportsManager.result,
    };

    // TODO: filter in json (e.g. are ads supported)

    if (canProcessChildData(childData)) {
      const child = createChildBase(childData, scaleX, scaleY);

      if (child) {
        result.rootElement = child;

        if (childData.exportNames) {
          childData.exportNames.forEach((exportName) => {
            exportFunc(exportName, result);
          });
        }

        finishChildCreation(
          child,
          childData,
          parentSize,
          scaleX,
          scaleY,
          _optGetJsonData,
          exportFunc,
        );
      }
    }

    return result;
  };

  const child = createChild(
    data.rootElement,
    {
      x: data.desiredSize[0],
      y: data.desiredSize[1],
    },
    defined(optScaleX, 1),
    defined(optScaleY, 1),
    optGetJsonData,
  );

  return child;
};
