import { GameScaleController } from '../../States/Scaling/GameScaleController';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import Overlay2 from '../GameState/UI/Layers/Overlay2';

G.WorldSidePanel = function () {
  Phaser.Group.call(this, game);
  this.fixedToCamera = true;

  this._overlay = new Overlay2();
  this.add(this._overlay);
  this._overlay.pointerUpSignal.add(function () {
    this.sideGroup.toggle();
    this._hideOverlay();
  }, this);

  this.sideGroup = new G.WorldSidePanelGroup();
  this.add(this.sideGroup);
  this.sideGroup.onOpened.add(this._showOverlay, this);
  this.sideGroup.onClosed.add(this._hideOverlay, this);

  this.hideReasons = new G.Reasons(
    function (hide) { hide ? this._hide() : this._show(); },
    this,
  );

  if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
    this.scale.setTo(GameScaleController.getInstance().gameScale);
  }

  G.sb('onWindowOpened').add(this._onWindowOpened, this);
  G.sb('onAllWindowsClosed').add(this._onAllWindowsClosed, this);

};

G.WorldSidePanel.prototype = Object.create(Phaser.Group.prototype);

//

G.WorldSidePanel.prototype.toggleSidePanel = function () { this.sideGroup.toggle(); };
G.WorldSidePanel.prototype.getSidePanelState = function () { return this.sideGroup.opened; };

//

G.WorldSidePanel.prototype._showOverlay = function () { this._overlay.showOverlay(); };
G.WorldSidePanel.prototype._hideOverlay = function () { this._overlay.hideOverlay(); };

//

G.WorldSidePanel.prototype._onWindowOpened = function () {
  this.hideReasons.add('WindowOpen');
};

G.WorldSidePanel.prototype._onAllWindowsClosed = function () {
  this.hideReasons.remove('WindowOpen');
};

//

G.WorldSidePanel.prototype._hide = function _hide() {
  this._turnOffInput();
  this._animateFadeOut(function () {
    G.sb('worldSidePanelOnWindowHide').dispatch();
  });

  // this.cameraOffset.x = -1000;
  // this.visible = false;
};

G.WorldSidePanel.prototype._show = function _show() {
  this._turnOnInput();
  this._animateFadeIn();
};

//

G.WorldSidePanel.prototype._turnOffInput = function () { G.Utils.setIgnoreChildrenRec(this, true); };
G.WorldSidePanel.prototype._turnOnInput = function () { G.Utils.setIgnoreChildrenRec(this, false); };

// Animations

G.WorldSidePanel.prototype._animateFadeOut = function _animateFadeOut(callback) {
  G.stopTweens(this);
  game.add.tween(this)
    .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.In, true)
    .onComplete.add(callback);
};

G.WorldSidePanel.prototype._animateFadeIn = function _animateFadeIn() {
  G.stopTweens(this);
  game.add.tween(this)
    .to({ alpha: 1 }, 200, Phaser.Easing.Sinusoidal.Out, true);
};
