/* eslint-disable func-names */
if (typeof G === 'undefined') G = {};

G.SignalBox = (function () {
  const clearOnStageChange = false;
  const signals = {};

  function clearNonPermanent() {
    Object.keys(signals).forEach((signal) => {
      signals[signal].removeNonPermanent();
    });
  }

  function clearAll() {
    Object.keys(signals).forEach((signal) => {
      signals[signal].removeAll();
    });
  }

  function getSignal(signalName) {
    if (!clearOnStageChange) {
      game.state.onStateChange.add(clearNonPermanent, this);
    }

    if (!signals[signalName]) {
      signals[signalName] = new Phaser.Signal();
    }

    return signals[signalName];
  }

  getSignal.signals = signals;
  getSignal.clearNonPermanent = clearNonPermanent;
  getSignal.clearAll = clearAll;

  return getSignal;
}());
