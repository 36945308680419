import { TOKEN_TYPES, EDITOR_SYMBOLS } from '../BoardConstants';
import { TokenLayer_Spreading } from './TokenLayer_Spreading';
import { BoardToken_SpreadingDirt } from '../Tokens/BoardToken_SpreadingDirt';

/**
 * TokenLayer for BoardToken_SpreadingDirt instances.
 */
export class TokenLayer_SpreadingDirt extends TokenLayer_Spreading {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    super(board, {
      constructor: BoardToken_SpreadingDirt,
      maxHp: 3,
      hpToken: true,
      editorSymbol: EDITOR_SYMBOLS.DIRT_S,
      collectableType: TOKEN_TYPES.DIRT_S,
      tokenHitEventId: 'hitDirtS',
    }, false);
  }

  /**
   * check if spreading is possible to the specified position.
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  _isSpreadPossible(cellX, cellY) {
    if (this.board.dirtLayer.isToken(cellX, cellY)) return false;
    if (this.board.iceLayer.isToken(cellX, cellY)) return false;
    if (this.board.concreteLayer.isToken(cellX, cellY)) return false;
    return super._isSpreadPossible(cellX, cellY);
  }
}
