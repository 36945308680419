/**
 * Code pertaining to booster sharing in Window_gift.js
 */

if (typeof G === 'undefined') G = {};
G.GiftUI = G.GiftUI || {};
G.GiftUI.HelperFunctions = G.GiftUI.HelperFunctions || {};

G.GiftUI.HelperFunctions.animateBoosterSelection = function (target, targetHand, animations, isLeft) {
  game.add
    .tween(target)
    .to(
      {
        angle: (isLeft ? -15 : 15),
        x: target.x + (isLeft ? -25 : 25),
        y: target.y + 100,
      },
      900,
      Phaser.Easing.Cubic.Out,
      true,
    )
    .onComplete.add(() => {
      game.add
        .tween(targetHand)
        .to(
          {
            alpha: 1,
          },
          800,
          Phaser.Easing.Cubic.Out,
          true,
        );
      G.GiftUI.HelperFunctions.moveHandToTarget(targetHand, target)();
    });
  animations.cubicRescale.animate(game,
    target, 1.25);
};

G.GiftUI.HelperFunctions.giveOptionToSelectBooster = function (gift, animations, callback) {
  const hand1 = G.GiftUI.Elements.TutorialHand(gift.inside_dbl, false);
  hand1.visible = true;
  const hand2 = G.GiftUI.Elements.TutorialHand(gift.inside_dbl, false);
  hand2.visible = true;

  G.GiftUI.HelperFunctions.animateBoosterSelection(
    gift.inside_dbl.chestGifts[0],
    hand1,
    animations,
    true,
  );
  G.GiftUI.HelperFunctions.animateBoosterSelection(
    gift.inside_dbl.chestGifts[1],
    hand2,
    animations,
    false,
  );

  animations.cubicFadeOut.animate(game, gift.gift)
    .onComplete.add(() => {
      gift.gift.visible = false;

      for (let i = 0; i < gift.inside_dbl.chestGifts.length; i++) {
        const curr = gift.inside_dbl.chestGifts[i].boosterImg.children[0];
        curr._ind = i;
        curr.inputEnabled = true;
        curr.input.useHandCursor = true;
        curr.events.onInputDown.add(function () {
          hand1.destroy();
          hand2.destroy();
          callback(this._ind);
        }, curr);
      }
    }, this);

  gift.insideExtraTxt.setText(OMT.language.getText('Choose booster to share!'));
};
