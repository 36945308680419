import TutorialGrid from '../BoardAnim/G.TutorialGrid';
import TutorialGroup from '../BoardAnim/TutorialGroup';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';

const tileSize = 90;
const mechanicSprite = 'candy_goalCandy';

/**
 * Goal Drop Tutorial explaining things with strawberries
 * Please look at TutorialGroup to look at what those funky tweens are
 * @author Sandra Koo
 */
class GoalDropTutorial extends TutorialGroup {
  constructor() {
    super(game, null);

    this.currentPage = 0;
    this.pageOne = new Phaser.Group(game, null);
    this.gridBg = undefined;
    this.waffles = undefined;
    this.gems = undefined;
    this.fxEmitters = [];
    this.tutHand = undefined;
    this.description = undefined;
    this.continueButton = undefined;

    this.pageTwo = new Phaser.Group(game, null);
    this.goalIcon = undefined;
    this.goalNumber = undefined;

    this.drawEverything();
    this.showPageOne();
  }

  /**
   * Draws all elements
   * Initializes some of them
   */
  drawEverything() {
    const gridX = 4;
    const gridY = 2;
    const gridScale = 1.2;

    this.gridBg = new TutorialGrid(); // Grid bg. Has the background sprite
    this.gridBg.init(gridX, gridY, tileSize, 'tut_tile', gridScale);

    this.mechanic = new TutorialGrid(); // For the goalDrop

    this.gems = new TutorialGrid(); // For the gems

    this.drops = new TutorialGrid(); // For the drop markers at the bottom of the grid
    const dropData = [0, 0, 0, 0,
                      1, 1, 1, 1].map((num) => (num === 0 ? null : 'collect_cell_2')); // eslint-disable-line indent
    this.drops.init(gridX, gridY, tileSize, dropData, gridScale);
    for (let i = 0; i < this.drops.children.length; i++) { // Animates them forever
      const drop = this.drops.children[i];
      if (drop) {
        const dropY = drop.y + 5;
        this._everyTween.push(game.add.tween(drop)
          .to({ y: dropY }, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true));
      }
    }

    this.extras = new TutorialGrid(); // Extra grid for dropping down extra candy.
    const extraData = [0, 1, 3, 3,
                        0, 0, 0, 0].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.extras.init(gridX, gridY, tileSize, extraData, gridScale);

    for (let i = 0; i < 3; i++) { // Particle emitters
      const emitter = new FxParticle(); // For the sfx
      emitter.scale.setTo(gridScale); // It also grows big
      this.fxEmitters.push(emitter);
    }

    this.tutHand = new Phaser.Group(game);
    G.makeImage(0, 0, 'tut_hand', 0, this.tutHand); // Haaaand

    this.description = new G.Text(0, -150, ' ', { // The instructions
      style: 'font-blue',
      fontSize: 35,
      lineSpacing: -15,
    }, 0.5, 400, 200, true, 'center');

    this.goalIcon = G.makeImage(-15, 0, mechanicSprite, 0.5, null); // The goal Icon
    this.goalIcon.scale.setTo(0.8);

    this.goalNumber = new G.Text(32, 0, '1', { // The goal counter next to the goal
      style: 'font-blue',
      fontSize: 40,
    }, 0.5);

    this.continueButton = new OMT_UI_SquareButton(0, 260, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onContinueClick.bind(this),
        },
      },
    });
    this.addChild(this.continueButton);
  }

  /**
   * When continue is clicked
   */
  onContinueClick() {
    this.stopAllActions(); // Stop all tweens
    if (this.currentPage === 1) { // If page one, go to page 2
      this.removeChild(this.pageOne);
      this.showPageTwo();
    } else { // Otherwise close
      this.signals.onContinueClick.dispatch();
      this.destroy();
    }
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this.gems) {
      this.gems.destroy();
    }

    if (this.gridBg) {
      this.gridBg.destroy();
    }

    if (this.mechanic) {
      this.mechanic.destroy();
    }

    if (this.drop) {
      this.drops.destroy();
    }

    if (this.extras) {
      this.extras.destroy();
    }

    this.pageOne.destroy();
    this.pageTwo.destroy();

    super.destroy();
  }

  /**
   * Positions all grids
   * @param {number} targetX
   * @param {number} targetY
   */
  positionGrids(targetX, targetY) {
    this.gridBg.x = targetX;
    this.gridBg.y = targetY;

    this.mechanic.x = targetX;
    this.mechanic.y = targetY;

    this.gems.x = targetX;
    this.gems.y = targetY;

    this.drops.x = targetX;
    this.drops.y = targetY;

    this.extras.x = targetX;
    this.extras.y = targetY - tileSize; // Is a little offset above
  }

  /**
   * Shows the first part of the tutorial
   */
  showPageOne() {
    this.currentPage = 1;
    const gridX = 4;
    const gridY = 2;
    const gridScale = 1.2;

    this.description.setText(OMT.language.getText('%Strawberries% can’t be matched or crushed, but they can be collected!'));

    this.positionGrids(0, this.description.y + this.description.height + this.gridBg.height / 2);

    // init grids with candies
    const mechanicData = [0, 0, 0, 1,
                          0, 0, 0, 0].map((num) => (num === 0 ? null : mechanicSprite)); // eslint-disable-line indent
    this.mechanic.init(gridX, gridY, tileSize, mechanicData, gridScale); // Passes in an array of null/string to denote which parts have nothing and which have the icon

    const gemsData = [5, 5, 1, 0,
                      4, 4, 1, 5].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    this.tutHand.alpha = 0; // Hide this

    this.pageOne.addChild(this.description);
    this.pageOne.addChild(this.gridBg);
    this.pageOne.addChild(this.mechanic);
    this.pageOne.addChild(this.gems);
    this.pageOne.addChild(this.drops);
    this.pageOne.addChild(this.tutHand);
    this.addChild(this.pageOne);
    this.addChild(this.continueButton);

    this.animatePageOne();
  }

  /**
   * Animates page one
   */
  async animatePageOne() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 1; // Modifies the shouldEventComplete by checking the page

    const gem1 = this.gems.getSpriteByCell(2, 0); // Get whatever data is needed
    const goal = this.mechanic.getSpriteByCell(3, 0);
    const gem1Pos = this.gems.getPxPos(2, 0);
    const goalPos = this.mechanic.getPxPos(3, 0);

    this.tutHand.pivot.copyFrom(this.gems.pivot); // Copies and sets hand pivot to the grid

    const tweenGo = async () => { // Put in a function so it can be recalled by itself
      this.tutHand.position.set(this.gems.x + goalPos.x, this.gems.y + goalPos.y); // Positions hand on gem 2
      await this.wrapTween(this.tutHand, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand fades in
      await this.wait(tweenDelay / 2); // Wait
      await this.wrapTween(this.tutHand.scale, { x: 0.9, y: 0.9 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand does the click motion
      this.wrapTween(goal, gem1Pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The goal gems moves with the hand
      this.wrapTween(gem1, goalPos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The gem moves with the hand
      await this.wrapTween(this.tutHand, { x: this.gems.x + gem1Pos.x, y: this.gems.y + gem1Pos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand moves
      this.wrapTween(this.tutHand.scale, { x: 1, y: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand lets go
      this.wrapTween(gem1, gem1Pos, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // gems return to position
      await this.wrapTween(goal, goalPos, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(this.tutHand, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // hand fades out
      await this.wrapTween(goal, { angle: [-5, 5] }, tweenTime, Phaser.Easing.Back.InOut, 0, 1); // Goal wiggles
      await this.wait(tweenDelay); // Waits
      if (this.shouldEventComplete() && this.currentPage === 1) { // returns
        tweenGo();
      }
    };
    tweenGo(); // Start the tweens
  }

  /**
   * Shows page two
   */
  showPageTwo() {
    this.currentPage = 2;

    const gridX = 4;
    const gridY = 2;
    const gridScale = 1.2;

    this.description.setText(OMT.language.getText('To collect an %strawberry%, clear the path to one of the arrows at the bottom!'));

    this.goalNumber.setText('3');
    this.goalNumber.y = this.description.y + (this.description.height + (this.goalNumber.height * 1.5)) / 2;
    this.goalIcon.y = this.goalNumber.y;

    this.positionGrids(0, this.goalIcon.y + this.goalIcon.height + this.gridBg.height / 2);

    // Init grids
    const mechanicData = [0, 0, 0, 1,
                          0, 0, 0, 0].map((num) => (num === 0 ? null : mechanicSprite)); // eslint-disable-line indent
    this.mechanic.init(gridX, gridY, tileSize, mechanicData, gridScale); // Passes in an array of null/string to denote which parts have nothing and which have the icon

    const gemsData = [5, 5, 4, 0,
                      1, 4, 1, 4].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    this.tutHand.alpha = 0; // Hide these
    this.pageTwo.alpha = 0;

    this.addChild(this.description);
    this.pageTwo.addChild(this.goalNumber);
    this.pageTwo.addChild(this.goalIcon);
    this.pageTwo.addChild(this.gridBg);
    this.pageTwo.addChild(this.gems);
    this.pageTwo.addChild(this.extras);
    this.pageTwo.addChild(this.mechanic);
    this.pageTwo.addChild(this.drops);
    for (let i = 0; i < this.fxEmitters.length; i++) {
      this.pageTwo.addChild(this.fxEmitters[i]);
    }
    this.pageTwo.addChild(this.tutHand);
    this.addChild(this.pageTwo);
    this.addChild(this.continueButton);

    this.animatePageTwo();
  }

  /**
   * Animates page two
   */
  animatePageTwo() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 2; // Modifies shouldEventComplete by checking the page

    // Handy function because I'll need a lot of gems
    const getGemData = (board, cellX, cellY) => ({
      sprite: board.getSpriteByCell(cellX, cellY),
      pos: board.getPxPos(cellX, cellY),
    });

    const gem10 = getGemData(this.gems, 1, 0);
    const gem20 = getGemData(this.gems, 2, 0);
    const goal = getGemData(this.mechanic, 3, 0);
    const gem11 = getGemData(this.gems, 1, 1);
    const gem21 = getGemData(this.gems, 2, 1);
    const gem31 = getGemData(this.gems, 3, 1);
    const extra1 = getGemData(this.extras, 1, 0);
    const extra2 = getGemData(this.extras, 2, 0);
    const extra3 = getGemData(this.extras, 3, 0);
    const iconPos = this.gems.toLocal(this.goalIcon.position, this.pageTwo); // Position of the goal icon
    const fxGemTargets = [gem11, gem21, gem31]; // Target gems for the sfx

    this.tutHand.pivot.copyFrom(this.gems.pivot); // Copies and sets hand pivot to the grid

    const tweenGo = async () => {
      // Reset EVERYTHING
      this.tutHand.scale.set(1); // Hand is normal sized, in position, hidden
      this.tutHand.position.set(this.gems.x + gem20.pos.x, this.gems.y + gem20.pos.y);
      this.tutHand.alpha = 0;
      gem10.sprite.position.copyFrom(gem10.pos); // Gems are in their proper spot and not hidden
      gem20.sprite.position.copyFrom(gem20.pos);
      gem20.sprite.alpha = 1;
      goal.sprite.position.copyFrom(goal.pos);
      gem11.sprite.position.copyFrom(gem11.pos);
      gem11.sprite.alpha = 1;
      gem21.sprite.position.copyFrom(gem21.pos);
      gem31.sprite.position.copyFrom(gem31.pos);
      gem31.sprite.alpha = 1;
      goal.sprite.position.copyFrom(goal.pos);
      goal.sprite.scale.set(1.2); // is big too
      goal.sprite.alpha = 1;
      extra1.sprite.position.copyFrom(extra1.pos);
      extra1.sprite.alpha = 0;
      extra2.sprite.position.copyFrom(extra2.pos);
      extra2.sprite.alpha = 0;
      extra3.sprite.position.copyFrom(extra3.pos);
      extra3.sprite.alpha = 0;
      this.goalNumber.setText('3'); // Goal made to look normal

      await this.wrapTween(this.pageTwo, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page two fades in
      await this.wrapTween(this.tutHand, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand fades in
      await this.wait(tweenDelay / 2); // Wait
      await this.wrapTween(this.tutHand.scale, { x: 0.9, y: 0.9 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand clicks
      this.wrapTween(gem20.sprite, gem21.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The above moves down
      this.wrapTween(gem21.sprite, gem20.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The below moves up
      await this.wrapTween(this.tutHand, { x: this.gems.x + gem21.pos.x, y: this.gems.y + gem21.pos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand moves
      this.wrapTween(this.tutHand, { alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand fades out quick
      this.wrapTween(this.tutHand.scale, { scale: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand unclicks (but fades out before you can see)
      for (let i = 0; i < this.fxEmitters.length; i++) {
        this.fxEmitters[i].burstCandy(this.gems.x + fxGemTargets[i].pos.x - tileSize * 1.5, this.gems.y + fxGemTargets[i].pos.y - (tileSize / 2), 3); // sfx happens
      }
      this.wrapTween(gem11.sprite, { alpha: 0 }, tweenTime / 4, Phaser.Easing.Sinusoidal.InOut); // Gems disappear because match
      this.wrapTween(gem31.sprite, { alpha: 0 }, tweenTime / 4, Phaser.Easing.Sinusoidal.InOut);
      await this.wrapTween(gem20.sprite, { alpha: 0 }, tweenTime / 4, Phaser.Easing.Sinusoidal.InOut); // Dead
      // Big drop
      this.wrapTween(gem10.sprite, gem11.pos, tweenTime / 2, Phaser.Easing.Back.Out); // Gems drop down
      this.wrapTween(gem21.sprite, gem21.pos, tweenTime / 2, Phaser.Easing.Back.Out); // Gem from below returns below
      this.wrapTween(goal.sprite, gem31.pos, tweenTime / 2, Phaser.Easing.Back.Out);
      this.wrapTween(extra1.sprite, gem11.pos, tweenTime / 2, Phaser.Easing.Back.Out); // Extra gems drop down and fade in
      this.wrapTween(extra1.sprite, { alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(extra2.sprite, gem21.pos, tweenTime / 2, Phaser.Easing.Back.Out);
      this.wrapTween(extra2.sprite, { alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(extra3.sprite, gem31.pos, tweenTime / 2, Phaser.Easing.Back.Out);
      await this.wrapTween(extra3.sprite, { alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
      await this.wrapTween(goal.sprite.scale, { x: 1.5, y: 1.5 }, tweenTime, Phaser.Easing.Back.InOut); // Goal gem grows big for emphasis
      this.wrapTween(goal.sprite.scale, { x: 0.8, y: 0.8 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Goal gems moves to goal icon in position and size
      await this.wrapTween(goal.sprite, { x: iconPos.x, y: iconPos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      this.goalNumber.setText('2'); // Goal number updates
      this.wrapTween(goal.sprite.scale, { x: 2, y: 2 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Goal gem grows really big and fades out
      await this.wrapTween(goal.sprite, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wait(tweenDelay); // Wait
      await this.wrapTween(this.pageTwo, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page fades out

      if (this.shouldEventComplete() && this.currentPage === 2) { // Repeats
        tweenGo();
      }
    };
    tweenGo(); // Triggers tweens
  }
}

// create global references
G.GoalDropTutorial = GoalDropTutorial;
