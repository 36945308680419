/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/* eslint-disable radix */
/* eslint-disable func-names */

/**
* Utility Class for DDNA tracking implementation
*/
class DDNATrackingUtils {
  /**
  * format timezone offset to ISO string
  * @param {Object} date Date instance
  * @returns {string}
  */
  static getTimezoneOffsetString(date) {
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = (num) => {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
    return `${dif + pad(tzo / 60)}:${pad(tzo % 60)}`;
  }

  /**
  * get a integer value of currency string. expects 2 decimal places for proper format.
  * @param {string} currencyString currency string to clean
  * @returns {number}
  */
  static formatCurrencyValueAsInteger(currencyString) {
    const validChars = '0123456789';
    let currencyValueString = '';
    let char;
    for (let i = 0; i < currencyString.length; i++) {
      char = currencyString.charAt(i);
      if (validChars.indexOf(char) >= 0) {
        currencyValueString += char;
      }
    }
    return parseInt(currencyValueString);
  }

  /**
  * generate a unique client id
  * @returns {string}
  */
  static generateCliendId() {
    const { navigator } = window;
    let uid = navigator.mimeTypes.length;
    uid += navigator.userAgent.replace(/\D+/g, '');
    uid += navigator.plugins.length;
    return uid;
  }

  /**
  * generate unique ID based on the time
  * @returns {string}
  */
  static generateUniqueId() {
    let d = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  /**
  * check if two Date instances occur on the same date
  * @param {Object} d1 Date instance
  * @param {Object} d2 Date instance
  * @returns {boolean}
  */
  static isSameDate(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  }

  /**
  * get day difference between two Date instances
  * @param {Object} d1 Date instance
  * @param {Object} d2 Date instance
  * @returns {number}
  */
  static getDateDayDifference(d1, d2) {
    d1 = new Date(d1.getTime());
    d2 = new Date(d2.getTime());
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    const timeDiff = Math.abs(d2.getTime() - d1.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  }

  /**
  * return a timestamp in the format 2016-03-01 20:26:50.148 and in UTC
  * @param {Object} date Date instance (optional)
  * @returns {string}
  */
  static getTimestamp(date = null) {
    if (!date) date = new Date();
    // eslint-disable-next-line prefer-template
    return date.getUTCFullYear()
      + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate()
      + ' ' + date.getUTCHours() + ':' + date.getUTCMinutes()
      + ':' + date.getUTCSeconds() + '.' + date.getUTCMilliseconds();
  }

  /**
   * get the users operating system name
   * @returns {string}
   */
  static getOperatingSystem() {
    const { userAgent } = window.navigator;
    const { platform } = window.navigator;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = 'UNKNOWN';

    if (platform.indexOf('Mac') === 0) {
      os = 'MAC_OSX';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'IOS';
    } else if (platform.indexOf('Win') === 0) {
      os = 'WINDOWS';
    } else if (/Android/.test(userAgent)) {
      os = 'ANDROID';
    } else if (!os && /Linux/.test(platform)) {
      os = 'LINUX';
    }
    return os;
  }

  /**
   * get the scene/state tracking name
   * @returns {string}
   */
  static getSceneTrackingId() {
    const state = game.state.current;
    let sceneId = 'OTHER';
    if (state === 'Game') {
      sceneId = 'LEVEL';
    } else if (state === 'World') {
      sceneId = 'MAP';
    } else if (state === 'Preloader') {
      sceneId = 'PRELOADER';
    }
    return sceneId;
  }

  /**
   * recurssively delete null or undefined parameters from an event Object.
   * @param {Object} eventObj
   * @returns {Object}
   */
  static deleteNullParameters(eventObj) {
    const keys = Object.keys(eventObj);
    for (const key of keys) {
      const paramValue = eventObj[key];
      if (paramValue == null || Number.isNaN(paramValue)) {
        console.warn(`DDNA Event warning: ${key} is ${paramValue}, parameter was deleted.`);
        delete eventObj[key];
      } else if (typeof paramValue === 'object') {
        DDNATrackingUtils.deleteNullParameters(paramValue);
      }
    }
    return eventObj;
  }
}

// create global references
if (!window.DDNA) window.DDNA = {};
DDNA.utils = DDNATrackingUtils;
