/* eslint-disable no-new */

import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import OMT_UI_DrawUtil from '../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import { Window_FillRateFallback } from './Window_FillRateFallback';
import { Window } from '../../00_IMMEDIATE/Window';
import OMT_RequestHelpWheel from '../SpinningWheels/OMT_RequestHelpWheel';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

const prizeText = {
  style: 'font-num-blue',
  fontSize: 60,
  scaleStroke: true,
  align: 'center',
  lineSpacing: -10,
};

/**
 * Some functions are being used from Window_requestHelp.js
 * @author Sandra Koo
 *
 * rhm = RequestHelpMessage
 * rh = RequestHelp
 */
export class requestHelpMessage extends Window {
  constructor(parent) {
    super(parent);

    this.isWatchingAd = false; // Prevents buttons from working here

    // init wheel and start spinning
    this.rhm_wheel = new OMT_RequestHelpWheel({
      ...OMT_Utils.stringToReference('wheelConfigs.requestHelpPrizeWheel', G.OMTsettings.elements),
    });

    this.rhm_prizePack = this.prizeScreenInit(); // Draws prize screen, rhm style
    this.rhm_banner = this.createBanner({
      text: OMT.language.getText('Booster Wheel'),
      bannerImg: 'green_wheel_ribbon',
    });
    this.rhm_banner.y = -275;
    this.rhm_prizePack.display.visible = false;

    this.addChild(this.rhm_wheel);
    this.addChild(this.rhm_banner);

    this.enterTheWheel(); // Enter the wheel
    this.enterTheBanner(); // And the banner fro Window_requestHelp
  }

  /**
   * Draw the init screen
   */
  prizeScreenInit() {
    const mainDisplay = new Phaser.Group(G.game, this);
    const prizeScreen = new Phaser.Group(G.game, null);
    const shine1 = G.makeImage(0, 0, 'shine_godrays', 0.5);
    shine1.update = () => {
      shine1.angle += 0.01 * G.deltaTime;
      if (shine1.angle === 360) { shine1.angle = 0; }
    };

    prizeScreen.addChild(shine1);
    mainDisplay.addChild(prizeScreen);

    const watchAdBtn = new Phaser.Group(G.Game, null); // The watch ad button
    if (OMT.feature.rewardedAdsAvailable()) {
      const videoImg = G.makeImage(0, 0, 'btn-movie-icon', 0.5, null);
      const actualWatchAdBtn = new OMT_UI_SquareButton(0, 0, {
        button: {
          tint: BUTTONCOLOURS.purple,
          dimensions: {
            width: 309,
            height: 100,
          },
          extraDetail: false,
        },
        text: {
          string: OMT.language.getText('Try again'),
          textStyle: { style: 'font-white', fontSize: 48 },
          icon: {
            image: videoImg,
            offset: {
              x: videoImg.width / 4,
            },
            align: 'left',
          },
          offset: {
            x: videoImg.width / 4,
          },
        },
        options: {
          clickFunction: {
            onClick: this.watchAdClicked.bind(this),
          },
        },
      });
      watchAdBtn.addChild(actualWatchAdBtn);
    }

    // Claim reward button
    const onClick = () => {
      if (this.isWatchingAd) { return; } // Don't do anything if we're watching
      const chosenPrize = this._prizeData;
      OMT.transactionTracking.logInventoryTransactionBegin();

      if (chosenPrize.prize.indexOf('coin') > -1) { // Is coin?
        watchAdBtn.onChildInputDown.removeAll();

        this.state.uiTargetParticles.createCoinBatch( // Particles
          game.world.bounds.x + this.rhm_prizePack.prizeScreen.worldPosition.x,
          this.rhm_prizePack.prizeScreen.worldPosition.y,
          this.state.panel.coinsTxt,
          chosenPrize.amount,
          true,
        );

        OMT.transactionTracking.addInventoryChange('coins', 0, chosenPrize.amount);

        game.time.events.add(500, () => { // Wait for animation then close
          this.closeWindow();
        }, this);
      } else {
        G.gift.applyGift([chosenPrize.prize, chosenPrize.amount], false); // Get booster
        this.closeWindow();
      }

      OMT.transactionTracking.logInventoryTransactionEnd();
      // DDNA.transactionHelper.trackRewards([chosenPrize.prize, chosenPrize.amount], [], {
      //   transactionType: 'REWARD', tActionType: 'REQUEST_HELP_USE', tGameArea: 'MAP',
      // });
    };
    const claimRewardBtn = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Claim'),
        textStyle: { style: 'font-white', fontSize: 70 },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
          disableAfterClick: true,
        },
      },
    });
    watchAdBtn.y = 250 + watchAdBtn.height / 2; // Position
    claimRewardBtn.y = watchAdBtn.y + watchAdBtn.height + (claimRewardBtn.height / 2) + 40;

    claimRewardBtn.originalY = claimRewardBtn.y; // Keep track of these...
    watchAdBtn.originalY = watchAdBtn.y;

    mainDisplay.addChild(claimRewardBtn);
    mainDisplay.addChild(watchAdBtn);

    return {
      display: mainDisplay,
      prizeScreen,
      prize: null, // Phaser.Group, to be set later
      claimRewardBtn,
      watchAdBtn,
    };
  }

  /**
   * Creates the Request Help Banner
   * @param {Object} bannerInfo
   * @param {string} bannerInfo.bannerImg
   * @param {string} bannerInfo.text
   */
  createBanner(bannerInfo) {
    const bannerDisplay = new Phaser.Group(G.Game, this);
    const bannerImg = G.makeImage(0, 0, bannerInfo.bannerImg, 0.5, null);
    const bannerText = new G.Text(0, 0, bannerInfo.text, {
      style: 'font-white',
      fontSize: 36,
      align: 'center',
      lineSpacing: -10,
    }, 0.5, bannerImg.width, bannerImg.height, true, 'center');
    bannerText.y = 2 + bannerImg.y - bannerText.height / 4;
    bannerDisplay.addChild(bannerImg);
    bannerDisplay.addChild(bannerText);

    return bannerDisplay;
  }

  enterTheBanner() {
    this.rhm_banner.alpha = 0;
    this.rhm_banner.scale.x = 1.5;
    this.rhm_banner.scale.y = 0.5;
    this.rhm_banner.visible = true;
    const bannerEnter = game.add.tween(this.rhm_banner.scale)
      .to({ x: 1, y: 1 }, 250, Phaser.Easing.Sinusoidal.In); // 250 = Wheel_Base.enterTheWheel().tweenTime/2
    const bannerEnter2 = game.add.tween(this.rhm_banner)
      .to({ alpha: 1 }, 250, Phaser.Easing.Sinusoidal.In);
    bannerEnter.start();
    bannerEnter2.start();
  }

  /**
   * Plays tween for the wheel to enter
   */
  enterTheWheel() {
    let goodbyePrize; // Tweens outside so I can access
    let alphaPrize2;
    let goodbyeTryAgain;
    if (this.rhm_prizePack.display.visible) { // If the prize screen is here, make it go away
      this.rhm_prizePack.prizeScreen.scale.x = this.rhm_prizePack.prizeScreen.scale.y = 1;
      this.rhm_prizePack.prizeScreen.alpha = 1;
      const targetClaimY = this.rhm_prizePack.claimRewardBtn.originalY + 500;
      const targetVideoY = this.rhm_prizePack.watchAdBtn.originalY + 500;

      goodbyePrize = game.add.tween(this.rhm_prizePack.prizeScreen.scale)
        .to({ x: 0.01, y: 0.01 }, 200, Phaser.Easing.Sinusoidal.InOut, false);
      alphaPrize2 = game.add.tween(this.rhm_prizePack.prizeScreen)
        .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut, false);
      const goodbyeClaim = game.add.tween(this.rhm_prizePack.claimRewardBtn)
        .to({ y: targetClaimY, alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut);
      goodbyeTryAgain = game.add.tween(this.rhm_prizePack.watchAdBtn)
        .to({ y: targetVideoY, alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut);

      goodbyePrize.chain(goodbyeClaim);
      goodbyeClaim.chain(goodbyeTryAgain);
    }

    // Apply wheel
    this.rhm_wheel.scale.x = this.rhm_wheel.scale.y = 0.01;


    const onEnterTheWheelComplete = () => {
      this.rhm_wheel.spinToNextPrize((item) => {
        this.collectWheelPrizeVisually(item.data.reward);
      });
    };

    // Tween
    if (goodbyePrize) { // If the prize is tweening out
      goodbyeTryAgain.onComplete.addOnce(() => {
        this.rhm_prizePack.display.visible = false;
        this.isWatchingAd = false; // Not watching anymore! In case you might have been
        this.rhm_wheel.enterTheWheel(onEnterTheWheelComplete);
      });

      goodbyePrize.start(); // Tween out
      alphaPrize2.start();
    } else { // Or just start
      this.rhm_wheel.enterTheWheel(onEnterTheWheelComplete);
    }
  }

  /**
   * Get the prize!
   * @param {Object} prizeData
   */
  collectWheelPrizeVisually(prizeData) {
    this._prizeData = prizeData;
    if (this.rhm_prizePack.prize) { // Take out the old prize list
      const oldPrize = this.rhm_prizePack.prize;
      this.rhm_prizePack.prizeScreen.removeChild(oldPrize);
      oldPrize.destroy(true);
    }

    let giftString = OMT_UI_DrawUtil.getGiftString(prizeData.prize);
    const isCoin = giftString.indexOf('coin') > -1;
    if (isCoin) { giftString = giftString.replace('_1', '_3'); } // "coin_3", I don't want to modify the giftString func for this 2nd case
    // Avoid getting the pixely picture by using the bigger one, "coin_3"

    const prize = new Phaser.Group(G.Game, null);
    const giftImage = G.makeImage(0, 0, giftString, 0.5, null);
    giftImage.width = giftImage.height = 200;
    if (isCoin) { // Add text if coin
      const giftText = new G.Text(0, 0, prizeData.amount, prizeText, 0);
      giftText.x = 0 - giftText.width / 2;
      giftText.y = giftImage.y + giftImage.height / 2;

      prize.addChild(giftText);
    }
    prize.addChild(giftImage);
    this.rhm_prizePack.prizeScreen.addChild(prize);
    this.rhm_prizePack.prize = prize;

    // Setup properties of graphics
    this.rhm_prizePack.display.visible = false;
    this.rhm_prizePack.prizeScreen.scale.x = this.rhm_prizePack.prizeScreen.scale.y = 0.01;
    this.rhm_wheel.scale.x = this.rhm_wheel.scale.y = 1;
    this.rhm_prizePack.watchAdBtn.alpha = this.rhm_prizePack.claimRewardBtn.alpha = 0;

    const originalClaimY = this.rhm_prizePack.claimRewardBtn.originalY;
    const originalVideoY = this.rhm_prizePack.watchAdBtn.originalY;
    this.rhm_prizePack.claimRewardBtn.y = this.rhm_prizePack.claimRewardBtn.originalY + 500;
    this.rhm_prizePack.watchAdBtn.y = this.rhm_prizePack.claimRewardBtn.originalY + 500;

    // Tween
    const goodbyeWheel = game.add.tween(this.rhm_wheel.scale)
      .to({ x: 0.01, y: 0.01 }, 200, Phaser.Easing.Custom.SoftBounce);

    const comeInPrize = game.add.tween(this.rhm_prizePack.prizeScreen.scale)
      .to({ x: 1, y: 1 }, 200, Phaser.Easing.Sinusoidal.InOut, false);
    const alphaPrize = game.add.tween(this.rhm_prizePack.prizeScreen)
      .to({ alpha: 1 }, 200, Phaser.Easing.Sinusoidal.InOut, false);

    const comeClaim = game.add.tween(this.rhm_prizePack.claimRewardBtn)
      .to({ y: originalClaimY, alpha: 1 }, 200, Phaser.Easing.Sinusoidal.InOut);
    const comeTryAgain = game.add.tween(this.rhm_prizePack.watchAdBtn)
      .to({ y: originalVideoY, alpha: 1 }, 200, Phaser.Easing.Sinusoidal.InOut);

    goodbyeWheel.onComplete.add(() => { // When the wheel is gone, the rest can come in
      this.rhm_wheel.visible = false;
      this.rhm_prizePack.display.visible = true;
      alphaPrize.start();
      comeInPrize.start();
    });
    comeInPrize.chain(comeTryAgain);
    comeTryAgain.chain(comeClaim);
    goodbyeWheel.start();
  }

  /**
   * Event that happens when the watch ad is clicked
   */
  watchAdClicked() {
    if (this.isWatchingAd) { return; } // Don't double click

    this.isWatchingAd = true; // Its on

    G.sb('fillRateFallbackClosed').addOnce(this.onAdGiveUp, this); // In case they press the X button, the buttons must turn back on

    OMT.ads.showAd( // Show the ad
      G.BuildEnvironment.adPlacements.requestMoveHelpWheelSpin,
      this.onAdSuccess.bind(this),
      () => {
        if (!G.IAP) { // no IAPS dont use ad fallbacks
          new Window_FillRateFallback(undefined, {
            placement: G.BuildEnvironment.adPlacements.spinTheDailyWheel,
            trackingName: 'requestHelpAdFail',
            callback: this.onAdSuccess.bind(this),
            context: this,
          });
        } else { // ad fallback
          this.isWatchingAd = false;
          OMT.ads.showAdFallback(this.onAdSuccess.bind(this));
        }
      },
    );
  }

  /**
   * When the ad is successfully watched
   */
  onAdSuccess() {
    this.enterTheWheel(); // enterTheWheel will turn off isWatchingAd
  }

  /**
   * When they give up :(
   */
  onAdGiveUp() {
    if (this.game) {
      this.isWatchingAd = false;
    } else {
      G.sb('fillRateFallbackClosed').remove(this.onAdGiveUp, this);
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.requestHelpMessage = requestHelpMessage;
