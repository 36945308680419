/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import VillainsInGame from './VillainsInGame';

export default class VillainsInGameLandscape extends VillainsInGame {
  /**
   * Initiate all objects / revert them to their default states
   */
  init() {
    const { parent, villain_1, villain_2 } = this;

    const villainScaleCoeff = 0.55;
    const villainNudgeCoeff = 0.4;
    const villainJumpCoeff = 0.15;

    villain_1.scale.setTo(villainScaleCoeff);
    const { x: villain_1_origX } = parent.toLocal({ x: -villain_1.width * 0.5, y: 0 });
    villain_1.x = villain_1_origX;
    villain_1.y = parent.bg.y + parent.bg.height * 0.1 + villain_1.height * 0.5;
    villain_1.alpha = 0;
    const { x: villain_1_targetX } = parent.toLocal({ x: game.width * 0.2, y: 0 });
    const villain_1_nudgeX =
      villain_1_targetX + villain_1.width * villainNudgeCoeff;
    const villain_1_jumpY = villain_1.height * villainJumpCoeff;
    this._villain_1 = {
      index: 1,
      type: 'villain',
      object: villain_1,
      origX: villain_1_origX,
      targetX: villain_1_targetX,
      nudgeX: villain_1_nudgeX,
      origY: villain_1.y,
      jumpY: villain_1_jumpY,
    };

    if (villain_2) {
      villain_2.scale.setTo(villainScaleCoeff);
      const { x: villain_2_origX } = parent.toLocal({ x: game.width + villain_2.width * 0.5, y: 0 });
      villain_2.x = villain_2_origX;
      villain_2.y =
        villain_1.y + villain_1.height * 0.5 - villain_2.height * 0.5;
      villain_2.alpha = 0;
      const { x: villain_2_targetX } = parent.toLocal({ x: game.width * 0.8, y: 0 });
      const villain_2_nudgeX =
        villain_2_targetX - villain_2.width * villainNudgeCoeff;
      const villain_2_jumpY = villain_2.height * villainJumpCoeff;
      this._villain_2 = {
        index: 2,
        type: 'villain',
        object: villain_2,
        origX: villain_2_origX,
        targetX: villain_2_targetX,
        nudgeX: villain_2_nudgeX,
        origY: villain_2.y,
        jumpY: villain_2_jumpY,
      };
    }
    villain_1.scale.x *= -1;
  }
}
