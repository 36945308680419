// import ShareTickButton from '../ShareTickbutton';
import { Window_FillRateFallback } from './Window_FillRateFallback';
import LevelEconomyTracker from '../GameTracking/LevelEconomyTracker';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { Window } from '../../00_IMMEDIATE/Window';
import { BragRightsManager } from './Brag/BragRightsManager';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';
import { OMT_SessionUtil } from '../../Utils/OMT_SessionUtil';
import VillainsSpeech from '../../OMT_UI/Villains/VillainSpeech';
import { OMT_SystemInfo, ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { INTERSTITIAL_RULES } from '../../Services/OMT/ads/OMT_InterstitialAdRules';
// import { XPROMO_PLACEMENTS } from '../../Services/OMT/OMT_CrossPromo';
import TargetedOfferDataManager, { TARGETED_OFFER_IDS } from '../../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';

/* eslint-disable object-shorthand */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */

export class Window_Win extends Window {
  /**
   * The window that appears when the player beats a level.
   * @param {Object} parent
   * @param {Object} config
   * @param {number} config.lvlIndex
   * @param {number} config.points
   * @param {boolean} config.mysteryGiftStreakIncrease
   * @param {Object} config.treasureHunt
   * @param {number} config.treasureHunt.collectGems
   * @param {Object} config.globalScoreChange
   * @param {number} config.globalScoreChange.old
   * @param {number} config.globalScoreChange.new
   * @param {number} config.ignoreStars
   * @param {number} config.extraMoves
   * @memberof Window_Win
   */
  constructor(parent, config) {
    super(parent);
    this.state = game.state.getCurrentState();

    // imports
    const { lvlIndex } = config;
    const { points } = config;
    const { globalScoreChange } = config;

    // config passed when level started
    this.passedConfig = config.passedConfig;

    // config.mysteryGiftStreakIncrease is increased right before being sent to the window.
    this.increasedStreak = config.mysteryGiftStreakIncrease;

    OMT_VILLAINS.setLevelType(this.state.mode);
    const { isHardLevel, isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();

    this.isHardLevel = isHardLevel;
    this.isSuperHardLevel = isSuperHardLevel;
    this.isNotNormalLevel = isNotNormalLevel;
    const isDailyChallenge = this.state.mode === LevelType.CHALLENGE;

    // should we show the brag 2.0 popup?
    this.showBrag2Popup = OMT.feature.isBrag2Active() && (BragRightsManager.getInstance().isBragLevel(lvlIndex + 1) || isDailyChallenge) && this.passedConfig.tutorial !== 'brag_2.0';

    // if we're in a daily challenge, we want to switch to that kind of window.
    if (isDailyChallenge) {
      this.winChallenge(config);
      return;
    }

    // Show targeted offer
    TargetedOfferDataManager.getInstance().showPopupOfferIfPossible(
      [TARGETED_OFFER_IDS.NON_PAYER_LAST_X_SESSIONS, TARGETED_OFFER_IDS.PAYER_LAST_X_SESSIONS],
    );

    // the last level passed before we passed this one
    const lastPassedLevelPre = G.saveState.getLastPassedLevelNr();

    // if we forced a life loss at the beginning of the level, we add that back here.
    if (this.state.removedLifeAtStart) {
      G.saveState.addLife(1, false, true);
    }

    // if this level doesnt have result data set, set it here.
    if (!G.lvl.resultData) {
      let difficulty = LevelType.NORMAL;
      if (isSuperHardLevel) {
        difficulty = 'SUPER_HARD';
      } else if (isHardLevel) {
        difficulty = 'HARD';
      }
      G.lvl.oldStars = G.saveState.getStars(lvlIndex);
      G.lvl.resultData = G.saveState.passLevel({
        levelIndex: lvlIndex,
        newStars: Math.max(1, G.lvl.stars),
        newPoints: points,
        skipReward: true,
        dontSave: true,
        gameMode: LevelType.NORMAL,
        difficulty,
        extraMoves: config.extraMoves,
      });
    }

    // send automated message on close if in friend context
    OMT.social.sendClosedHelpRequest(() => {});

    // save the result
    this.result = G.lvl.resultData;

    // grabbin data from the level
    const starsAchieved = this.result.stars;
    this.oldStars = G.lvl.oldStars;

    // if we got three stars for the first time, count that as a win
    if (this.oldStars < 3 && this.result.stars === 3) {
      if (G.winsInRow === undefined) { G.winsInRow = 0; }
      if (lastPassedLevelPre >= G.featureUnlock.unlockLevels.achievementGifts && G.featureUnlock.achievementGifts) {
        G.winsInRow++;
        G.saveState.incrementAchievementWinCount();
      }
    }

    // if we're not a daily challenge, track the stars in the level economy tracker
    // (the finishLevel function doesn't actually do anything right now)
    if (this.state.mode !== LevelType.CHALLENGE) {
      LevelEconomyTracker.getInstance().finishLevel(starsAchieved);
    }

    // setting up layout
    if (this.isHardLevel) {
      this.bg = new UI_NineSlice(0, 0, OMT_VILLAINS.getPrefixedName('hard_popup'), 1340 * 0.4, 1534 * 0.4, G.OMTsettings.elements.superhard.window_level.hard_popup);
    } else if (this.isSuperHardLevel) {
      this.bg = new UI_NineSlice(0, 0, OMT_VILLAINS.getPrefixedName('super_hard_popup'), 1340 * 0.4, 1534 * 0.4, G.OMTsettings.elements.superhard.window_level.super_hard_popup);
    } else {
      this.addBackground('popup_background_2');
    }

    if (this.isNotNormalLevel) {
      this.bg.anchor.setTo(0.5);
      this.add(this.bg);
    }

    let bannerTexture = 'blue_banner';
    if (this.isHardLevel) {
      bannerTexture = OMT_VILLAINS.getPrefixedName('hard_header');
    } else if (this.isSuperHardLevel) {
      bannerTexture = OMT_VILLAINS.getPrefixedName('super_hard_header');
    }
    this.banner = G.makeImage(
      0, -295,
      bannerTexture,
      0.5, this,
    );
    if (this.isNotNormalLevel) {
      this.banner.scale.setTo(0.4 * 1.33);
    }

    this.levelTxt = new G.Text(0, -297, `${OMT.language.getText('Level')} ${lvlIndex + 1}`, {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, 330);
    if (this.isNotNormalLevel) {
      this.levelTxt.y += 15;
    }
    this.add(this.levelTxt);

    // styling for hard and super hard levels
    if (this.isNotNormalLevel) {
      let hardWarningTxtContent = OMT.language.getText('Hard');
      let hardWarningTxtStyle = OMT_VILLAINS.getPrefixedName('hard_1');
      if (this.isSuperHardLevel) {
        hardWarningTxtContent = OMT.language.getText('Super Hard');
        hardWarningTxtStyle = OMT_VILLAINS.getPrefixedName('super_hard_1');
      }
      this.hardWarningTxt = new G.Text(0, -330, hardWarningTxtContent, {
        style: hardWarningTxtStyle,
        fontSize: '30px',
      }, 0.5, this.bg.width, this.bg.height, true, 'center');
      this.add(this.hardWarningTxt);
    }

    let youWinTxtY = -70;
    let youWinTxtStyle = {
      style: 'font-blue',
      fontSize: '45px',
    };
    if (this.isNotNormalLevel) {
      if (this.isHardLevel) {
        youWinTxtStyle = {
          style: OMT_VILLAINS.getPrefixedName('hard_4'),
        };
      } else if (this.isSuperHardLevel) {
        youWinTxtStyle = {
          style: OMT_VILLAINS.getPrefixedName('super_hard_4'),
        };
      }
      youWinTxtY += 12;
    }
    this.youWinTxt = new G.Text(0, youWinTxtY, OMT.language.getText('You win!'), youWinTxtStyle, 0.5, 530);
    this.add(this.youWinTxt);

    if (this.isNotNormalLevel) {
      this.scoreBg = new UI_NineSlice(20, 20, OMT_VILLAINS.getPrefixedName('text_background'), 212, 54, {
        left: 30,
        right: 30,
        bottom: 15,
        top: 15,
      });
      this.scoreBg.anchor.setTo(0.5);
      if (this.isHardLevel) {
        this.scoreBg.tint = G.OMTsettings.elements.superhard.window_level.hard_tint;
      } else if (this.isSuperHardLevel) {
        this.scoreBg.tint = G.OMTsettings.elements.superhard.window_level.super_hard_tint;
      }
      this.add(this.scoreBg);
    } else {
      this.scoreBg = G.makeImage(20, 20, 'popup_text_backgr', 0.5, this);
    }
    this.scoreIcon = G.makeImage(-90, 20, 'score_icon', 0.5, this);
    this.scoreIcon.scale.setTo(1.2);

    // if we're getting coins as a reward, display them
    if (G.lvl.resultData.reward > 0) {
      G.saveState.changeCoins(G.lvl.resultData.reward, true);

      if (this.isNotNormalLevel) {
        this.coinBg = new UI_NineSlice(20, 120, OMT_VILLAINS.getPrefixedName('text_background'), 212, 54, {
          left: 30,
          right: 30,
          bottom: 15,
          top: 15,
        });
        this.coinBg.anchor.setTo(0.5);
        if (this.isHardLevel) {
          this.coinBg.tint = G.OMTsettings.elements.superhard.window_level.hard_tint;
        } else if (this.isSuperHardLevel) {
          this.coinBg.tint = G.OMTsettings.elements.superhard.window_level.super_hard_tint;
        }
        this.add(this.coinBg);
      } else {
        this.coinBg = G.makeImage(20, 120, 'popup_text_backgr', 0.5, this);
      }
      this.coinIco = G.makeImage(-90, 120, 'coin_1', 0.5, this);

      let amountTxtStyle = {
        style: 'font-blue',
        fontSize: '45px',
      };
      if (this.isHardLevel) {
        amountTxtStyle = {
          style: OMT_VILLAINS.getPrefixedName('hard_4'),
        };
      } else if (this.isSuperHardLevel) {
        amountTxtStyle = {
          style: OMT_VILLAINS.getPrefixedName('super_hard_4'),
        };
      }
      this.amountTxt = new G.TextCounter(25, 120, 0, amountTxtStyle, 0.5, 160);
      this.add(this.amountTxt);

      const levelCompleteRewardConfig = G.json.settings.levelCompleteReward;
      let doublingTurnOn = true;

      if (levelCompleteRewardConfig.active) {
        doublingTurnOn = levelCompleteRewardConfig.doubleCoinsButton;
      } else {
        doublingTurnOn = G.featureUnlock.starsForCoinsAlgorithm;
      }

      if (game.incentivised() && doublingTurnOn && OMT.feature.getDoubleCoinsAtEoL()) {
        this.addDoubleRewardBtn(150, 120, this.amountTxt, this.result.reward);
      }
    }

    let scoreTxtStyle = {
      style: 'font-blue',
      fontSize: '45px',
    };
    if (this.isHardLevel) {
      scoreTxtStyle = {
        style: OMT_VILLAINS.getPrefixedName('hard_4'),
      };
    } else if (this.isSuperHardLevel) {
      scoreTxtStyle = {
        style: OMT_VILLAINS.getPrefixedName('super_hard_4'),
      };
    }
    this.scoreTxt = new G.Text(32, 20, points.toString(), scoreTxtStyle, 0.5, 190);
    this.add(this.scoreTxt);

    // setting up buttons
    const onRetryClick = () => {
      OMT.milestoneTracking.checkMilestoneCompletion();
      G.sb('pushWindow').dispatch(['level', undefined, undefined, this.passedConfig]);
      this.closeWindow();
    };
    this.retryBtn = new OMT_UI_SquareButton(-120, 240, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText('Retry'),
        textStyle: {
          style: 'font-white',
          fontSize: 70,
        },
      },
      options: {
        clickFunction: {
          onClick: onRetryClick.bind(this),
        },
      },
    });
    this.registerButtons(this.retryBtn);

    const onContClick = () => {
      this.handleClose(lvlIndex, globalScoreChange);
    };
    this.continueBtn = new OMT_UI_SquareButton(120, 240, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: {
          style: 'font-white',
          fontSize: 70,
        },
        dimensionMods: {
          width: 0.9,
          height: 0.9,
        },
      },
      options: {
        clickFunction: {
          onClick: onContClick.bind(this),
          disableAfterClick: true,
        },
      },
    });
    this.registerButtons(this.continueBtn);

    // if it's a hard level, give the player the option to share their victory.
    if (this.isNotNormalLevel && !this.showBrag2Popup) {
      // show share
      // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenHardLevelShareBtn', 1);
      this.retryBtn.y = 205;
      this.retryBtn.scale.setTo(0.95, 0.95);
      this.continueBtn.y = 205;
      this.continueBtn.scale.setTo(0.95, 0.95);
      // this.shareUI = this.add(new ShareTickButton(0, 265, {
      //   style: 'font-white',
      //   fontSize: 36,
      //   lineSpacing: -10,
      // }, true));
      // this.shareUI.signals.onClick.addOnce(() => {
      //   G.GiftUI.HelperFunctions.showShareLoadingOverlay(this);

      //   let closed = false;

      //   const timeout = G.AsyncUtils.createScheduler(game);
      //   timeout.rescheduleAction(() => {
      //     closed = true;
      //     this.handleClose(lvlIndex, globalScoreChange);
      //   }, 5000);

      //   OMT.social.shareHardLevelCompleteMsg(lvlIndex + 1, () => {
      //     // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('usedHardLevelShareBtn', 1);
      //     if (closed) return;
      //     timeout.cancelSchedule();
      //     this.handleClose(lvlIndex, globalScoreChange);
      //   });
      // });
    }

    // make blank stars
    let sideStarY = -150;
    if (this.isNotNormalLevel) {
      sideStarY += 20;
    }
    this.blankStars = [
      G.makeImage(-100, sideStarY, 'star_blank', 0.5, this),
      G.makeImage(0, sideStarY - 25, 'star_blank', 0.5, this),
      G.makeImage(100, sideStarY, 'star_blank', 0.5, this),
    ];
    this.blankStars[0].scale.setTo(0.8);
    this.blankStars[2].scale.setTo(0.8);

    // replace blank stars with full stars based on how many stars the player achieved.
    this.stars = [
      G.makeImage(-100, sideStarY, starsAchieved >= 1 ? 'star' : 'star_blank', 0.5, this),
      G.makeImage(0, sideStarY - 25, starsAchieved >= 2 ? 'star' : 'star_blank', 0.5, this),
      G.makeImage(100, sideStarY, starsAchieved >= 3 ? 'star' : 'star_blank', 0.5, this),
    ];
    this.stars[0].scale.setTo(0.8);
    this.stars[2].scale.setTo(0.8);

    // bring in the stars
    this.stars.forEach(function (elem, index) {
      if (index + 1 <= starsAchieved) {
        const orgScale = elem.scale.x;
        elem.scale.setTo(0);
        const tween = game.add.tween(elem.scale).to({ x: orgScale, y: orgScale }, 300, Phaser.Easing.Bounce.Out, true, 800 + (index * 200));
        tween.onStart.add(function () {
          G.sfx.pop.play();
          G.sfx.explosion_subtle.play();
          this.add(new G.WinStarPart(elem.x, elem.y, true));
          this.add(new G.WinStarPart(elem.x, elem.y, true));
          this.add(new G.WinStarPart(elem.x, elem.y, true));
          this.add(new G.WinStarPart(elem.x, elem.y, true));
          this.add(new G.WinStarPart(elem.x, elem.y, true));
        }, this);
      } else {
        elem.visible = false;
      }
    }, this);

    game.time.events.add(1000, function () {
      if (this.result.reward > 0) {
        this.amountTxt.increaseAmount(this.result.reward);
      }
    }, this);

    G.sb('onLevelMoneyGain').dispatch(this.result.reward);


    // check if it is first time level 0
    // hide retry btn
    if (lastPassedLevelPre === 0 || G.lvl.stars === 3) {
      this.retryBtn.visible = false;
      this.continueBtn.x = 0;
      this.continueBtn.pulse(1.05);
    }

    if (isNotNormalLevel) {
      if (OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal) {
        this.onFinishedEnter.addOnce(() => {
          const state = game.state.getCurrentState();
          if (state && state._levelLeaderboard) {
            state._levelLeaderboard.hide();
          }
        });
      }

      if (OMT.feature.isVillainsEnabled()) {
        // Add the offset for the window
        this.y += OMT_VILLAINS.getNotNormalLevelOffset() * 0.9;

        let villainText = G.OMTsettings.elements.superhard.villain_speech.hard_win;
        let villainPos = 'right';
        let villainIndex = 1;
        let villainSubIndex = 3;

        if (isSuperHardLevel) {
          villainText = G.OMTsettings.elements.superhard.villain_speech.super_hard_win;
          villainPos = 'double';
          villainIndex = [2, 1];
          villainSubIndex = [3, 3];
        }

        this.villains = new VillainsSpeech(this, villainText, villainPos, villainIndex, villainSubIndex, undefined, 'top');
        const villainsClassContainer = this.villains.getClassContainer();

        // Reset villains scale in landscape
        if (this._isLandscape) {
          villainsClassContainer.scale.setTo(1);
        }

        this.onFinishedEnter.addOnce(() => {
          const yScale = this._isLandscape ? 0.45 : 0.7;
          villainsClassContainer.y = this.toLocal({
            x: 0,
            y: this.villains.getObjects({ type: 'speechBubble' })[0].height * yScale,
          }).y;
          this.villains.show();
        });
      }
    }

    const session = OMT_SessionUtil.getInstance();
    const sessionData = session.checkAndCreateWithKey(OMT_VILLAINS.getLevelTrackerKey());
    const levelSessionData = sessionData.checkAndCreateWithKey(lvlIndex);
    levelSessionData.setData('won', true);

    let difficulty = 'hard';
    if (isSuperHardLevel) {
      difficulty = 'super_hard';
    }
    const tutorialStatus = G.saveState.villainsDataManager.getTutorialStatus(difficulty, 'level_window');
    if (isNotNormalLevel && tutorialStatus) {
      G.saveState.villainsDataManager.setTutorialStatus(difficulty, 'game', true);
    }
    const SHTutorialStatus = G.saveState.villainsDataManager.getTutorialStatus(difficulty, 'game');
    if (isNotNormalLevel && SHTutorialStatus) {
      G.saveState.villainsDataManager.setTutorialStatus(difficulty, 'post_win_saga_map', true);
    }

    G.saveState.save();
    OMT_SessionUtil.getInstance().checkAndCreateWithKey('villains_poof_animation').setData('last_difficulty', difficulty);

    // G.Window.prototype.win_showScorePanel = function(perLevelPassResp){
    //   if(this.state.highscorePanel){
    //     this.state.highscorePanel.visible = true;
    //     return;
    //   }

    //   if (perLevelPassResp && perLevelPassResp.leaderboard) {
    //     this.state.highscorePanel = new G.HighscorePanelIngame();
    //     this.state.highscorePanel.injectLevelData(perLevelPassResp.leaderboard, perLevelPassResp.passed);
    //     this.state.highscorePanel.makeVisible();
    //   }
    // }
  }

  /**
   * Hide the score panel.
   *
   * @memberof Window_Win
   */
  hideScorePanel() {
    if (this.state.highscorePanel) {
      this.state.highscorePanel.visible = false;
    }
  }

  /**
   * When an ad was successfully completed.
   *
   * @param {Object} btn The btn that triggered this ad, so we can disable it.
   * @param {Object} counter The coin counter.
   * @param {number} amount The amount of coins we should increase the counter by..
   * @memberof Window_Win
   */
  adSuccess(btn, counter, amount) {
    // track the reward
    // DDNA.transactionHelper.trackRewards(['coin', amount], [], {
    //   transactionType: 'AD',
    //   tActionType: 'AD',
    //   tGameArea: 'LEVEL',
    //   tAdCoins: amount,
    //   tAdValue: amount,
    //   tAdRewardType: 'DOUBLE_VALUE',
    // });

    // log the ad watch
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.WatchAdToDoubleCoinsOnLevelComplete);

    // add the amount to the money gained of this level
    G.lvl.moneyGained += amount;
    counter.increaseAmount(amount);
    G.saveState.changeCoins(amount, false);

    // disable the ad button so they can watch an ad multiple times.
    btn.inputEnabled = false;

    // hide the button.
    game.add.tween(btn)
      .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true);
  }

  /**
   * Add the 'double reward' button to the window.
   *
   * @param {number} x The x position of the button.
   * @param {number} y The y position of the button.
   * @param {number} counter The coin counter.
   * @param {number} amount The amount of coins to add to the counter.
   * @returns The 'double reward' button.
   * @memberof Window_Win
   */
  addDoubleRewardBtn(x, y, counter, amount) {
    const onClick = () => {
      OMT.ads.showAd(
        G.BuildEnvironment.adPlacements.doubleLevelCoins,
        // on ad success
        () => {
          this.adSuccess(btn, counter, amount);
        },
        // on ad failure
        () => {
          if (!G.IAP) { // no IAPS dont use ad fallbacks
            // eslint-disable-next-line no-new
            new Window_FillRateFallback(undefined, {
              placement: G.BuildEnvironment.adPlacements.doubleLevelCoins,
              callback: function () {
                this.adSuccess(btn, counter, amount);
              },
              context: this,
            });
          } else { // open ad fallback
            OMT.ads.showAdFallback(this.adSuccess.bind(this, btn, counter, amount));
          }
        },
      );
    };
    // create the image for the button
    const img = G.makeImage(35, 0, 'btn-movie-icon', 0.5, null);
    img.scale.set(0.75);

    // create the button itself
    const btn = new OMT_UI_SquareButton(x, y, {
      button: {
        tint: BUTTONCOLOURS.purple,
        dimensions: {
          width: 147,
          height: 75,
        },
        extraDetail: false,
      },
      text: {
        string: 'x2',
        textStyle: { style: 'font-white', fontSize: 56 },
        icon: {
          image: img,
          offset: {
            x: img.width / 2,
          },
          align: 'left',
        },
        offset: {
          x: -img.width / 4,
        },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });

    this.registerButtons(btn);
    return btn;
  }

  /**
   * Close the window and return to the map scene with any rewards.
   *
   * @param {number} lvlIndex The index of the level we just beat.
   * @param {number} globalScoreChange The change in the global score.
   * @memberof Window_Win
   */
  handleClose(lvlIndex, globalScoreChange) {
    // track the completion of the first level
    if (lvlIndex === 0) {
      // DDNA.tracking.ftuxEvent(6, 'firstLevelEndContinue');
    }

    G.sb('onAllWindowsClosed').addOnce(() => {
      // grab the boosters the player had available for this level
      const level_assembleBoosters = (nextLevel) => { // Same function from window_level
        const startBoosters = {
          player: this.state.startBoosterConfig.getConfigForLevel(nextLevel),
        };
        return startBoosters;
      };


      // function to execute state change with possible delays, example brag 2.0
      const executeStateChange = (stateChangeFunc) => {
        // check if we need to show anything before changing states
        if (this.showBrag2Popup) { // brag level show popup first before closing
          this.hideScorePanel();
          G.sb('pushWindow').dispatch(['bragInitChallenge', lvlIndex + 1], false, G.WindowMgr.LayerNames.OverlayLayer);
          G.sb('onWindowClosed').addOnce(() => {
            stateChangeFunc();
          });
        } else { // normal use just change the state now
          stateChangeFunc();
        }
      };

      // if we are still in the FTUX flow continue to the next level immediately
      const isFTUXLevel = G.saveState.getLastPassedLevelNr() < G.OMTsettings.FTUXSettings.runLevelsUpTo;
      const skipFTUXForBrag = this.showBrag2Popup && !G.firstTime;
      if (OMT.feature.getUseNewFTUX() && isFTUXLevel && !skipFTUXForBrag) {
        const nextLevel = G.saveState.getLastPassedLevelNr();
        const levelData = {
          lvlIndex: nextLevel,
          debugMode: G.debugMode === true,
          startBoosters: level_assembleBoosters(nextLevel),
          challengeLvl: null,
          preLevelTrackingData: { },
        };
        // we update coins if we are not going back to the map
        executeStateChange(() => {
          OMT.milestoneTracking.checkMilestoneCompletion();
          G.sb('onCoinsChange').dispatch(G.saveState.getCoins());
          G.sb('onStateChange').dispatch('Game', levelData);
        });
      // otherwise, check for ads and go back to the map.
      } else {
        executeStateChange(() => {
          const ifLastLevel = G.saveState.getLastPassedLevelNr() === G.Helpers.levelDataMgr.getNumberOfLevels();
          this.state.endPointCheckForAds(() => {
            G.sb('onStateChange').dispatch('World', {
              lvlNr: lvlIndex,
              reward: G.lvl.moneyGained,
              starImprovement: G.lvl.resultData.starImprovement,
              mysteryGiftStreakIncrease: this.increasedStreak,
              globalScoreChange,
            }, () => {
              if (ifLastLevel) {
                // const crossPromoData = OMT.crossPromo.getPromoByPlacementId(XPROMO_PLACEMENTS.ENDOFCONTENT); // TODO: update placement id
                // OMT.crossPromo.showCrossPromoWindow('endofcontent', crossPromoData, null);
              }
            });
          }, INTERSTITIAL_RULES.EVERY_N);
        });
      }
    }, this);

    // if achievement gifts are unlocked and we've won three levels in a row, pop up the gifts
    if (G.featureUnlock.achievementGifts && lvlIndex >= G.featureUnlock.unlockLevels.achievementGifts && G.winsInRow >= 3) {
      G.winsInRow = 0;
      const getGifts = () => {
        if (G.IAP) {
          return [
            G.gift.getGift('WinningStreak_Gift'),
            G.gift.getGift('WinningStreak_Gift'),
            G.gift.getGift('WinningStreak_Gift'),
          ];
        }
        return [
          G.gift.getGift(Math.random() < 0.5 ? 'normalsGingyGift_boosters_achievement' : 'normalsGingyGift_coins_achievement', true),
          G.gift.getGift(Math.random() < 0.5 ? 'normalsGingyGift_boosters_achievement' : 'normalsGingyGift_coins_achievement', true),
          G.gift.getGift(Math.random() < 0.5 ? 'normalsGingyGift_boosters_achievement' : 'normalsGingyGift_coins_achievement', true),
        ];
      };
      G.sb('pushWindow').dispatch(['gift', {
        reason: G.GiftUI.Constants.GiftReasons.Achievement,
        placement: G.BuildEnvironment.adPlacements.achievementGift,
        monetiseType: game.incentivised() ? G.GiftUI.Constants.MonetiseOptions.OpenOtherGifts : false,
        showGingy: false,
        showTitleBar: true,
        shareable: false,
        incentivisedSharing: false,
        giftBoxType: G.GiftUI.Constants.GiftBoxTypes.Retro,
        twoFrameOpen: G.OMTsettings.elements.gifts.twoFrameOpen,
        adTracking: {
          requestEvent: 'VideoAdWatchAccomplish',
        },
        resourceTracking: {
          itemType: 'Reward',
          itemId: 'Achievement',
        },
        onOpen: () => {
          this.hideScorePanel();
        },
        offset: {
          x: 0,
          y: -90,
        },
        gingyOffset: {
          x: 0,
          y: -160,
        },
        gingyTitleOffset: {
          x: 0,
          y: -240,
        },
        giftOffset: {
          x: 0,
          y: 30,
        },
        gifts: getGifts(),
        lvlIndex: lvlIndex,
      }]);
    }

    const session = OMT_SessionUtil.getInstance();
    const sessionData = session.checkAndCreateWithKey(OMT_VILLAINS.getLevelTrackerKey());
    const levelSessionData = sessionData.checkAndCreateWithKey(lvlIndex);
    levelSessionData.setData('showPoof', true);

    this.closeWindow();
  }

  /**
   * Shows the villains when daily challenge is won
   */
  _showVilliansInChallenge() {
    if (OMT.feature.isVillainsEnabled()) {
      // Add the offset for the window
      this.y += OMT_VILLAINS.getNotNormalLevelOffset() * 0.9;

      const rng = Math.round(Math.random() * 1000) % 3;
      const villainText = G.OMTsettings.elements.superhard.villain_speech[`dailyChallengeWin${rng}`];
      const villainPos = 'double';
      const villainIndex = [2, 1];
      const villainSubIndex = [3, 3];

      this.villains = new VillainsSpeech(this, villainText, villainPos, villainIndex, villainSubIndex, undefined, 'top');
      const villainsClassContainer = this.villains.getClassContainer();

      // Reset villains scale in landscape
      if (this._isLandscape) {
        villainsClassContainer.scale.setTo(1);
      }

      this.onFinishedEnter.addOnce(() => {
        const yScale = this._isLandscape ? 0.2 : 0.3;
        villainsClassContainer.y = this.toLocal({
          x: 0,
          y: this.villains.getObjects({ type: 'speechBubble' })[0].height * yScale,
        }).y;
        this.villains.show();
      });
    }
  }

  /**
   * Change how the window is displayed, if the level is a challenge level.
   *
   * @param {Object} config The config for the new window display
   * @memberof Window_Win
   */
  winChallenge(config) {
    this._showVilliansInChallenge();
    const { globalScoreChange } = config;
    const starsAchieved = G.lvl.stars;
    const difference = Math.max(starsAchieved - G.saveState.getChallengeStars(), 0);

    const coinReward = G.saveState.computeDailyChallengeReward();
    console.log(`G.lvl.stars: ${G.lvl.stars}`);
    console.log(`G.saveState.getChallengeStars: ${G.saveState.getChallengeStars()}`);
    // changing state

    G.saveState.markChallengeCompleted();
    G.saveState.passExtraLevel(difference);
    G.saveState.accumulateChallengeStars(difference); // Track stars on today's challenge

    // Collect stars (oldLevelIndex, starCount)
    // G.sb('dailyMissionCollectStars').dispatch(null, difference); // OMT-4874 - Stars are not given in challenge

    // setting up layout
    this.bg = new UI_NineSlice(0, 0, OMT_VILLAINS.getPrefixedName('super_hard_popup'), 1340, 1534, G.OMTsettings.elements.superhard.window_level.super_hard_popup);
    this.bg.anchor.setTo(0.5);
    this.bg.scale.setTo(0.4);
    this.add(this.bg);
    this.banner = G.makeImage(0, -295, OMT_VILLAINS.getPrefixedName('super_hard_header'), 0.5, this);
    this.banner.scale.setTo(0.4 * 1.33);

    // for the header txt
    let textMaxWidthMultiplier = 1;

    if (G.OMTsettings.elements.superhard.window_levelFailed) {
      textMaxWidthMultiplier = G.OMTsettings.elements.superhard.window_levelFailed.bannerTextMaxWidthMultipler || textMaxWidthMultiplier;
    }

    this.levelTxt = new G.Text(0, -297, OMT.language.getText('Daily Challenge'), {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, this.banner.width * textMaxWidthMultiplier);
    this.add(this.levelTxt);

    this.youWinTxt = new G.Text(0, -58, OMT.language.getText('You win!'), OMT_VILLAINS.getPrefixedName('super_hard_4'), 0.5, 530);
    this.add(this.youWinTxt);

    this.scoreBg = new UI_NineSlice(20, 20, OMT_VILLAINS.getPrefixedName('text_background'), 212, 54, {
      left: 30,
      right: 30,
      bottom: 15,
      top: 15,
    });
    this.scoreBg.anchor.setTo(0.5);
    this.add(this.scoreBg);
    this.scoreBg.tint = G.OMTsettings.elements.superhard.window_level.super_hard_tint;
    this.scoreIcon = G.makeImage(-90, 20, 'score_icon', 0.5, this);
    this.scoreIcon.scale.setTo(1.2);

    if (coinReward > 0) {
      // displaying how many coins the player received
      this.coinBg = new UI_NineSlice(20, 120, OMT_VILLAINS.getPrefixedName('text_background'), 212, 54, {
        left: 30,
        right: 30,
        bottom: 15,
        top: 15,
      });
      this.coinBg.anchor.setTo(0.5);
      this.coinBg.tint = G.OMTsettings.elements.superhard.window_level.super_hard_tint;
      this.add(this.coinBg);
      this.coinIco = G.makeImage(-90, 120, 'coin_1', 0.5, this);
      this.amountTxt = new G.TextCounter(25, 120, 0, OMT_VILLAINS.getPrefixedName('super_hard_4'), 0.5, 160);
      this.add(this.amountTxt);
      G.saveState.changeCoins(coinReward, false);
      if (game.incentivised()) {
        this._doubleCoinAd = this.addDoubleRewardBtn(150, 120, this.amountTxt, coinReward);
      }
    }

    // setting up the slots for the stars to potentially fill
    /** Stars image disable due to low resolution coin images
    this.blankStars = [
      G.makeImage(-90, -140, 'coin_blank', 0.5, this),
      G.makeImage(0, -150, 'coin_blank', 0.5, this),
      G.makeImage(90, -140, 'coin_blank', 0.5, this),
    ];
    this.blankStars[0].scale.setTo(0.6);
    this.blankStars[1].scale.setTo(0.8);
    this.blankStars[2].scale.setTo(0.6);

    // displaying how many coins the player achieved in the level.
    const createCoinImage = (x, y, filled) => {
      const image = G.makeImage(x, y, filled ? 'coin_1' : 'coin_blank', 0.5, this);
      if (filled) {
        const container = G.makeImage(x, y, null, 0.5, this);
        image.position.set(0, 0);
        container.addChild(image);
        image.scale.set(G.OMTsettings.elements.dailyChallengeSettings.coinSize / image.width);
        return container;
      }
      return image;
    };
    this.stars = [
      createCoinImage(-90, -140, starsAchieved >= 1),
      createCoinImage(0, -150, starsAchieved >= 2),
      createCoinImage(90, -140, starsAchieved >= 3),
    ];
    this.stars[0].scale.setTo(0.6);
    this.stars[1].scale.setTo(0.8);
    this.stars[2].scale.setTo(0.6);

    // tween in the stars
    this.stars.forEach((elem, index) => {
      if (index + 1 <= starsAchieved) {
        const orgScale = elem.scale.x;
        elem.scale.setTo(0);
        const tween = game.add.tween(elem.scale).to({ x: orgScale, y: orgScale }, 300, Phaser.Easing.Bounce.Out, true, 800 + (index * 200));
        tween.onStart.add(() => {
          G.sfx.pop.play();
          G.sfx.explosion_subtle.play();
          this.add(new G.WinStarPart(elem.x, elem.y, true));
          this.add(new G.WinStarPart(elem.x, elem.y, true));
          this.add(new G.WinStarPart(elem.x, elem.y, true));
          this.add(new G.WinStarPart(elem.x, elem.y, true));
          this.add(new G.WinStarPart(elem.x, elem.y, true));
        }, this);
      } else {
        elem.visible = false;
      }
    }, this);
     */

    // display the score the player got
    this.scoreTxt = new G.Text(32, 20, G.lvl.points.toString(), OMT_VILLAINS.getPrefixedName('super_hard_4'), 0.5, 190);
    this.add(this.scoreTxt);

    // setting up button

    const onContinueClick = () => {
      this.state.endPointCheckForAds(() => {
        G.sb('onAllWindowsClosed').add(() => {
          // switch back to map, and let map know to give reward
          G.sb('onStateChange').dispatch('World', {
            lvlNr: G.lvl.levelIndex,
            reward: G.lvl.moneyGained,
            // star: coinReward,
            starImprovement: 0, // OMT-4874 - No stars are given
            challenge: true,
            globalScoreChange,
          });
        });
        this.closeWindow();
      }, INTERSTITIAL_RULES.EVERY_N);
    };

    this.continueBtn = new OMT_UI_SquareButton(0, 220, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: { style: 'font-white', fontSize: 70 },
      },
      options: {
        clickFunction: {
          onClick: onContinueClick.bind(this),
        },
      },
    });

    this.registerButtons(this.continueBtn);

    G.sb('onDailyChallengeFinished').dispatch();
    G.sb('onDailyChallengeWon').dispatch();

    game.time.events.add(1000, () => {
      if (coinReward > 0) {
        G.sb('onLevelMoneyGain').dispatch(coinReward);
        this.amountTxt.increaseAmount(coinReward);
      }
    }, this);

    const everything = [this.youWinTxt, this.scoreBg, this.scoreIcon, this.scoreTxt];
    if (this.coinBg) {
      everything.push(this.coinBg, this.amountTxt, this.coinIco);
      if (this._doubleCoinAd) {
        everything.push(this._doubleCoinAd);
      }
    }
    for (const thing of everything) {
      if (thing) {
        thing.y -= 50;
      }
    }
  }
}
// create global references
if (!window.Windows) window.Windows = {};
Windows.win = Window_Win;
