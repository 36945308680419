import TargetedOfferDataManager from '../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { MILLISECONDS_IN_SEC } from '@omt-components/Utils/TimeUtil';
import { TextTimer, TIMER_FORMAT } from './TextTimer';
import { ORIENTATION } from '../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../States/Scaling/GameScaleController';

const BUTTON_POS_Y = {
  vertical: 150,
  horizontal: 125,
};
const BUTTON_BG = 'IAP-mapicon';

const TITLE_TEXT = 'Graham\'s Bakery';
const TITLE_STYLE = 'font-white';
const TITLE_POS = { x: 50, y: -15 };

const TIMER_POS = { x: 50, y: 20 };
const TIMER_STYLE = 'font-white';

const FADE_TIME = 200;

export class UI_TimedTargetedOffer extends Phaser.Group {
  /**
   * constructor
   * @param {number} expiryTime
   */
  constructor(expiryTime) {
    super(game);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._initButton();
    this._initTitle();
    this._initTimer(expiryTime);

    this.x = this._isLandscape ? game.width / 2 - 50 : game.width / 2;
    this.y = BUTTON_POS_Y[OMT.systemInfo.orientationKey];
    this.scale.setTo(this._isLandscape ? 0.9 : 1);
    this.alpha = 0;
  }

  /**
   * Init button background
   */
  _initButton() {
    this._button = new G.Button(0, 0, BUTTON_BG, () => this._onClick(), this);
    this.add(this._button);
  }

  /**
   * Init title text
   */
  _initTitle() {
    this._title = new G.Text(TITLE_POS.x, TITLE_POS.y, OMT.language.getText(TITLE_TEXT), TITLE_STYLE, 0.5, this._button.width * 0.75);
    this.add(this._title);
  }

  /**
   * Init timer text
   * @param {number} expiryTime
   */
  _initTimer(expiryTime) {
    const timeLeft = (expiryTime - Date.now()) / MILLISECONDS_IN_SEC;
    this._timer = new TextTimer({
      x: TIMER_POS.x,
      y: TIMER_POS.y,
      style: TIMER_STYLE,
      anchor: 0.5,
      maxWidth: this._button.width * 0.75,
      timerFormat: TIMER_FORMAT.MS,
    });
    this._timer.start(timeLeft);
    this.add(this._timer);
  }

  /**
   * Click handler. Shows current targeted offer in window
   */
  _onClick() {
    const targetedOfferDataManager = TargetedOfferDataManager.getInstance();
    const currentOffer = targetedOfferDataManager.getCurrentTargetedOffer();
    if (!currentOffer) return;

    targetedOfferDataManager.showTimedPopupOfferWindow(currentOffer);
  }

  /**
   * Show the button
   */
  show() {
    this.alpha = 0;
    game.add.tween(this).to({ alpha: 1 }, FADE_TIME, Phaser.Easing.Sinusoidal.In, true);
  }

  /**
   * Hide the button
   */
  hide() {
    this.alpha = 1;
    game.add.tween(this).to({ alpha: 0 }, FADE_TIME, Phaser.Easing.Sinusoidal.In, true);
  }
}
