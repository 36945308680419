G.WorldSidePanelNoEvent = function() {
  Phaser.Group.call(this, game);

  this.bg = G.makeImage(0, 0, "completed_missions_area", [0.5, 0], this);
  this.bg.width = 350;
  this.bg.height = 80;
  this.text = this.add(new G.Text(0, 42, 
    OMT.language.getText("Thanks for playing!")+"\n"+OMT.language.getText("New Events coming soon!"), {
      style: "font-white",
      fontSize: 25,
      lineSpacing: -10,
    }, 0.5, 370, 70, true, "center"))
};

G.WorldSidePanelNoEvent.prototype = Object.create(Phaser.Group.prototype);

G.WorldSidePanelNoEvent.prototype.getContentHeight = function() {
  return 90;
};