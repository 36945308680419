import { BoardToken } from './BoardToken';
import { TOKEN_TYPES } from '../BoardConstants';

const TOKEN_TEXTURE = 'dirt_#';

/**
 * BoardToken for dirt
 */
export class BoardToken_Dirt extends BoardToken {
  /**
   * constructor
   * @param {TokenLayer_Dirt} layer
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} hp
   */
  constructor(layer, cellX, cellY, hp) {
    super(
      layer,
      {
        tokenType: TOKEN_TYPES.DIRT,
      },
      cellX,
      cellY,
      hp,
    );

    // set texture based on hp
    G.changeTexture(this, TOKEN_TEXTURE.replace('#', this._hp));
  }

  /**
   * on match action on the cell.
   */
  onMatch() {
    G.sb('fxTop').dispatch('burstDirtAnim', this, this);
    this._hp--;
    if (this._hp > 0) G.changeTexture(this, TOKEN_TEXTURE.replace('#', this._hp));
    else this.remove();
  }

  /**
   * on booster used on cell.
   * @returns {boolean}
   */
  onBooster() {
    this.onMatch();
    return false;
  }
}
