export default class TreasureHuntLeaderboard_Util {
  /**
   * Returns leaderboard entries from the given leaderboardkey
   * @param {string} leaderboardStorageKey
   * @param {boolean} centeredOnYou
   * @param {number} [maxUsers]
   * @param {boolean} [ensureYou] Ensures they you'll be in the listing
   * @returns {Array<{userId:string, score:number, rank:number, username:string, name:string, image:string, userbadge:string}>}
   */
  static async getLeaderboardEntries(leaderboardStorageKey, centeredOnYou, leaderboardId, maxUsers = 6, ensureYou = true) {
    const yourData = OMT.envData.settings.user;
    const friends = await OMT.friends.getFriendsList();
    const entries = await OMT.tierLeaderboard.getLeaderboard({
      storageKey: leaderboardStorageKey,
      userCentric: centeredOnYou,
      limit: maxUsers,
      id: OMT.envData.settings.user.userId,
      leaderboardId,
    });
    this._sortRank(entries);

    let yourEntry;
    for (const entry of entries) {
      for (const friendEntry of friends) {
        if (entry.userId === friendEntry.id) { // Use up to date image
          entry.image = friendEntry.image;
        }
      }
      if (entry.userId === yourData.userId) {
        entry.image = yourData.avatar; // Use up to date image
        yourEntry = entry;
      }
      entry.name = entry.username;
    }

    // Prioritize your score, because you da best!
    if (yourEntry) {
      const sameScoreAsYou = entries.filter((a) => a.score === yourEntry.score);
      const firstPlace = sameScoreAsYou.find((a) => a !== yourEntry && a.rank < yourEntry.rank); // Find someone that isn't you and has higher rank
      if (firstPlace) {
        const tempRank = yourEntry.rank;
        yourEntry.rank = firstPlace.rank;
        firstPlace.rank = tempRank;
        this._sortRank(entries);
      }
    }

    if (entries.length > maxUsers) entries.splice(maxUsers);
    if (yourEntry && entries.indexOf(yourEntry) === -1 && ensureYou) {
      entries.pop(); // Out you go!
      entries.push(yourEntry);
    }

    return entries;
  }

  /**
   * Sorts the given array by rank
   * @param {Array<{rank:number}>} entries
   */
  static _sortRank(entries) {
    entries.sort((a, b) => a.rank - b.rank);
  }
}
