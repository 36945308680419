/* eslint-disable wrap-iife */
/* eslint-disable func-names */
G.Helpers = G.Helpers || {};

G.Helpers.loadShoutouts = (function () {
  const supportedShoutouts = ['en', 'ru', 'de', 'es', 'fr', 'it', 'nl', 'pl', 'pt', 'tr', 'vi', 'ja'];
  let loadedLang = null;

  return function loadShoutouts(lang) {
    let realLang = lang;
    if (supportedShoutouts.indexOf(realLang) === -1) {
      realLang = 'en';
    }

    if (loadedLang !== realLang) {
      loadedLang = realLang;
      const assetsFolder = window.assetsFolder ? window.assetsFolder : './assets';
      G.extLoader.atlasJSONHash(
        'shoutouts',
        `${assetsFolder}/hd/langdependent/shoutouts-${realLang}.png`,
        undefined,
        G.json[`shoutouts/shoutouts-${realLang}`],
      );
    }
  };
})();
