import { Action, ACTION_TYPES } from './Action';

export class ActionProcessMatch extends Action {
  /**
   * initialize the action.
   */
  _init() {
    this.preFall = true;
    this.processed = false;
  }

  /**
   * action update method
   */
  update() {
    const board = this._board;

    let candiesMoving = board.candiesAreAnimating || board.candiesAreFalling;
    // If the board is in the final cascade sequence and the player skipped it,
    // don't process any more matches to avoid visual glitches
    // Final score will be calculated in BoardGameHooks
    if (!candiesMoving && !board.wasFinalCascadeSkipped) board.matcher.processMatchList();

    candiesMoving = board.candiesAreAnimating || board.candiesAreFalling;
    // when match / animation fully resolved set the fall action
    if (!candiesMoving && board.checkMatchList.length === 0 && board.checkSpecialMatchList.length === 0) {
      this._actionManager.newAction(ACTION_TYPES.PROCESS_FALL);
      this.finish();
    }
  }
}
