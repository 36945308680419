/* eslint-disable object-curly-newline */
/* eslint-disable no-undef */
import { Window } from '../../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import { BragRightsManager } from './BragRightsManager';
import { BragLayoutFactory } from './BragLayoutFactory';

export const BANNER_MAX_SCALE = 1.35;
export const DEFAULT_BRAG_BUTTON_TEXT = 'Brag!';
export const SUBTITLE_MAX_WIDTH = 620;
export const BANNER_Y_OFFSET_FULLSCREEN = 190;
export const BUTTON_Y_OFFSET_FULLSCREEN = 20;

const BG_SCALEMODES = {
  SCALE_TO_FIT: 'scaleToFit',
  SCALE_FROM_BOTTOM_CENTER: 'scaleFromBottomCenter',
};

/**
 * Base Window Class brag 2.0
 */
export class Window_BragBaseWindow extends Window {
  /**
   * constructor
   * @param {Object} parent parent display object
   * @param {Object} settingsObj settings object from G.OMTSettings
   * @param {number} levelNum level #
   */
  constructor(parent, settingsObj, levelNum) {
    super(parent);

    this._levelNum = levelNum;

    // store the setttings reference
    this._config = settingsObj;
    this._bgScaleMode = this._config.bgScaleMode || BG_SCALEMODES.SCALE_FROM_BOTTOM_CENTER;

    // for creating alternate content layouts
    this._layoutFactory = new BragLayoutFactory();

    // fetch the brag rights data for this level
    this._bragRightsData = BragRightsManager.getInstance().getBragLevelData(this._levelNum);
    if (this._bragRightsData) this._create();
    else this.closeWindow();

    if (game.state.current === 'World') this.y -= G.WindowMgr.Constants.WorldVerticalOffset;
  }

  /**
   * gets leaderboard entries here. Could take a while.
   * @returns {Promise}
   */
  async _getFriendData() {
    let friendData = await OMT.leaderboards.getFriendsLeaderboardEntries('default', `${this._levelNum}`);
    friendData = friendData.filter((friend) => friend.userId !== OMT.envData.settings.user.userId); // Filter yourself out
    friendData = ArrayUtil.jumbleArray(friendData); // Shuffle it now
    return friendData;
  }

  /**
   * create the individiual window elements
   */
  async _create() {
    // load friend data and show loading overlay
    OMT.social.showOverlay();
    // this._friendData = await this._getFriendData();
    OMT.social.hideOverlay();

    this._createBGAndLayoutContainer();
    this._createBanners();
    this._createContent();
    this._createBragButton();
    this._createCloseButton();

    G.sb('onScreenResize').add(this._onResize, this);
    this._onResize();
  }

  /**
   * create window custom content
   */
  _createContent() {
    // override to implement
  }

  /**
   * create the window background and set the layout container / rect
   */
  _createBGAndLayoutContainer() {
    this._layoutContainer = new Phaser.Group(game, this); // Main container

    this._bg = G.makeImage(0, 0, 'background_1', [0.5, 1], this._layoutContainer);
    this._layoutRect = new Phaser.Rectangle(0, 0, game.width, game.height);

    this._glowBorder = new PhaserNineSlice.NineSlice(0, 0, 'brag', 'brag_border', game.width, game.height, { left: 30, right: 30, top: 30, bottom: 30 }); // eslint-disable-line object-curly-newline
    this._glowBorder.anchor.set(0.5);
    this._glowBorder.alpha = 0.9;
    this._layoutContainer.addChild(this._glowBorder);
  }

  /**
   * create the close window button
   */
  _createCloseButton() {
    this._closeBtn = new G.Button(0, 0, 'white_x', this.closeWindow.bind(this));
    this._layoutContainer.addChild(this._closeBtn);
  }

  /**
   * create the various window banners, override to customize
   */
  _createBanners() {
    this._createTopBanner('main title');
    this._createBottomBanner('bottom banner');
    this._createSubTitles(['sub title']);
  }

  /**
   * create the banners and sub text at the bottom of the window
   * @param {string} titleString text for main title
   */
  _createTopBanner(titleString) {
    const bannerAssetString = this._config.bannerImage.fullscreen;
    const bannerSize = this._config.bannerImage.scale || 1;

    // main title text field + background
    const titleTextBoundaries = {
      y: 0,
      width: 0.9,
      height: 0.9,
    };
    if (this._config.bannerImage.text) {
      titleTextBoundaries.y = this._config.bannerImage.text.y || titleTextBoundaries.y;
      titleTextBoundaries.width = this._config.bannerImage.text.width || titleTextBoundaries.width;
      titleTextBoundaries.height = this._config.bannerImage.text.height || titleTextBoundaries.height;
    }

    // main title field + graphics
    this._titleBg = G.makeImage(0, 0, bannerAssetString, 0.5, this._layoutContainer); // And the title too
    const titleField = new G.Text(
      0, titleTextBoundaries.y, titleString, { style: 'font-white', fontSize: 72 }, 0.5,
      this._titleBg.width * titleTextBoundaries.width, this._titleBg.height * titleTextBoundaries.height, false, 'center',
    );
    this._titleBg.addChild(titleField);
    this._titleBg.scale.set(bannerSize);
  }

  /**
   * create sub title
   * @param {Array.<String>} subTitleList list of subtitle strings
   */
  _createSubTitles(subTitleList) {
    this._subTitleFields = [];
    this._subTitleContainer = new Phaser.Group(game);
    this._subTitleContainer.y = this._titleBg.y + (this._titleBg.height / 2);
    this._layoutContainer.addChild(this._subTitleContainer);
    let yPos = 10; // initial y offset for subtitles
    const subTitleRowSpacing = 20;

    for (let i = 0; i < subTitleList.length; i++) {
      const subTitleString = subTitleList[i];
      const subTitleField = new G.Text(
        0, 0, subTitleString, 'windowBrag-blue', 0,
        Math.min(SUBTITLE_MAX_WIDTH, this._layoutRect.width * 0.8),
        null, true, 'center',
      );
      this._subTitleContainer.addChild(subTitleField);
      subTitleField.y = yPos;
      subTitleField.x -= subTitleField.width / 2;
      yPos += this._subTitleContainer.height + subTitleRowSpacing;
      this._subTitleFields.push(subTitleField);
    }
  }

  /**
   * create the bottom banner
   * @param {string} bottomBannerString text for the bottom purple banner
   */
  _createBottomBanner(bottomBannerString) {
    this._banner = G.makeImage(0, 0, 'purple_ribbon', 0.5, this._layoutContainer);

    // create the banner text field
    const bannerTextRelativeScale = 0.65;
    this._bottomBannerField = new G.Text(
      Math.round(this._banner.x), Math.round(this._banner.y),
      bottomBannerString,
      'windowBrag-white', 0.5, this._banner.width * bannerTextRelativeScale, this._banner.height * bannerTextRelativeScale, true, 'center',
    );
    this._bottomBannerField.y -= Math.round(this._banner.height / 16);
    this._layoutContainer.addChild(this._bottomBannerField);
  }

  /**
   * create the brag button
   * @param buttonText text for tyhe button label
   */
  _createBragButton(buttonText = DEFAULT_BRAG_BUTTON_TEXT) {
    this._buttonBrag = new OMT_UI_SquareButton(0, 0, { // Brag button!
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: { width: 250, height: 100 },
      },
      text: {
        string: OMT.language.getText(buttonText),
        textStyle: { style: 'font-white', fontSize: 72 },
      },
      options: {
        clickFunction: { onClick: () => { this._onBragClicked(); } },
      },
    });
    this._layoutContainer.addChild(this._buttonBrag);
  }

  /**
   * on brag button clicked
   */
  async _onBragClicked() {
    await OMT.social.shareBrag(() => {
      this.closeWindow();
    }, this._levelNum, this._bragRightsData);
  }

  /**
   * init the avatar layout with friends + you
   * @param {string} friendsBannerMsgString
   */
  _initFriendsAvatarLayout(friendsBannerMsgString) {
    const layoutConfig = this._config.content.friendsLayout;
    const content = this._layoutFactory.initFriendsAvatarContent(
      layoutConfig.fullscreen,
      layoutConfig.playerAvatar,
      layoutConfig.friendAvatar,
      this._friendData,
      this._bottomBannerField,
      friendsBannerMsgString,
    );
    this._setLayoutContent(content, layoutConfig);
  }

  /**
   * init the solo avatar layout with your avatar only
   */
  _initSoloAvatarLayout() {
    const layoutConfig = this._config.content.soloLayout;
    const content = this._layoutFactory.createSoloAvatarContent(
      layoutConfig.fullscreen,
      layoutConfig.playerAvatar,
    );
    this._setLayoutContent(content, layoutConfig);
  }

  /**
   * init the avatar layout for skipping the tutorial
   */
  _initSkipTutorialAvatarLayout() {
    const layoutConfig = this._config.content.skipTutorialLayout;
    const content = this._layoutFactory.initSkipTutorialLayout(
      layoutConfig.fullscreen,
      layoutConfig.imageScale,
      layoutConfig.playerAvatar,
    );
    this._setLayoutContent(content, layoutConfig);
  }

  /**
   * set the content / layout Group
   * @param {Phaser.Group} content
   * @param {Object} layoutConfig
   */
  _setLayoutContent(content, layoutConfig) {
    if (!content || !layoutConfig) return;
    this._scaleLayoutContent(content, layoutConfig);
    if (this._layoutContainer.children.length > 0) this._layoutContainer.addChildAt(content, 1);
    else this._layoutContainer.addChild(content);
    this._content = content;
    this._layoutConfig = layoutConfig;
  }

  /**
   * scale the layout content group
   * @param {Phaser.Group} content
   * @param {Object} layoutConfig
   */
  _scaleLayoutContent(content, layoutConfig) {
    const contentWidth = Math.max(Math.min(layoutConfig.fullscreen.maxWidth, this._layoutRect.width), layoutConfig.fullscreen.minWidth);
    content.width = contentWidth;
    content.scale.y = content.scale.x;
  }

  /**
   * update the responsive fullscreen layout
   */
  _updateFullScreenLayout() {
    // update layout rect
    this._layoutRect = new Phaser.Rectangle(0, 0, game.width, game.height);
    // position / scale bg
    if (this._bgScaleMode === BG_SCALEMODES.SCALE_FROM_BOTTOM_CENTER) {
      this._bg.width = this._layoutRect.width;
      this._bg.scale.y = this._bg.scale.x;
      if (this._bg.height < this._layoutRect.height) this._bg.height = this._layoutRect.height;
      this._bg.y = this._layoutRect.height / 2;
    } else if (this._bgScaleMode === BG_SCALEMODES.SCALE_TO_FIT) {
      this._bg.width = this._layoutRect.width;
      this._bg.height = this._layoutRect.height;
      this._bg.y = this._layoutRect.height / 2;
    }
    // position bg glow border
    try {
      this._glowBorder.width = Math.round(this._layoutRect.width);
      this._glowBorder.height = Math.round(this._layoutRect.height);
      this._glowBorder.alpha = 0.5;
    } catch (e) {
      // console.log('ERROR: could not resize glow border.');
    }
    // position close button
    this._closeBtn.y = this._layoutRect.y + 10 - (Math.min(game.height, this._bg.height) - (this._closeBtn.height * 1.5)) / 2;
    this._closeBtn.x = this._layoutRect.x - 10 + (Math.min(game.width, this._bg.width) - (this._closeBtn.width * 1.5)) / 2;
    // position brag button
    if (this._buttonBrag) this._buttonBrag.y = (this._layoutRect.height / 2) - (this._buttonBrag.height / 2) - 20;
    // position banner
    if (this._banner) {
      let bannerScale = (this._layoutRect.width * 0.95) / (this._banner.width / this._banner.scale.x);
      if (bannerScale > BANNER_MAX_SCALE) bannerScale = BANNER_MAX_SCALE;
      this._banner.scale.set(bannerScale);
      this._banner.y = (this._layoutRect.height / 2) - BANNER_Y_OFFSET_FULLSCREEN;
      this._bottomBannerField.y = this._banner.y - Math.round(this._banner.height / 16);
      const bannerTextRelativeScale = 0.75;
      this._bottomBannerField.width = this._banner.width * bannerTextRelativeScale;
      this._bottomBannerField.scale.set(this._bottomBannerField.scale.x);
      // this._bottomBannerField.height = this._banner.height * bannerTextRelativeScale;
    }
    // position title
    if (this._titleBg) this._titleBg.y = Math.round(this._layoutRect.y - (this._layoutRect.height / 2) + this._titleBg.height * 0.75);
    // position sub titles
    if (this._subTitleContainer) this._subTitleContainer.y = this._titleBg.y + (this._titleBg.height / 2);
    // rescale layout
    this._setLayoutContent(this._content, this._layoutConfig);
  }

  /**
   * on screen resize function
   */
  _onResize() {
    if (this._layoutContainer) this._updateFullScreenLayout();
  }

  /**
   * remove screen resize event
   */
  destroy() {
    super.destroy();
    G.sb('onScreenResize').remove(this._onResize, this);
  }
}
