/* eslint-disable object-shorthand */
/* eslint-disable func-names */
G.dailyMissionsMgr = {
  signals: {
    onMainRewardClaim: new Phaser.Signal(),
  },

  _goals: [],
  _unsavedProgress: false,

  isFeatureUnlocked: function() {
    // imports
    var saveState = G.saveState;
    return saveState.getLastPassedLevelNr() >= G.featureUnlock.unlockLevels.globalGoals;
  },

  dayCheck: async function() {
    if (!this.isFeatureUnlocked()) {
      return;
    }

    OMT.platformTracking.logFTUEvent('FTUX_DailyMissionsUnlock', null);

    var saveData = G.saveState.data.dailyMissions;

    if (this._checkIfNewDay() || !saveData) {
      this._initNewDay(saveData);
    } else {
      this._loadDay(saveData);
    }
  },

  _initNewDay: async function (prevDayData) {
    this._goals.forEach((goal) => {
      goal.destroy();
    });
    this._goals = [];
    this.mainRewardClaimed = false;

    G.saveState.data.dailyMissionDate = Date.now();

    // take care of missions from previous day
    let prevMissionData = [];
    if (prevDayData) {
      prevMissionData = prevDayData.missions.filter((m) => {
        const data = G.DAILYMISSIONS.getMissionById(m.id);
        if (!data) return false;
        return !data.SPECIAL && m.current > 0 && m.status === 'active';
      });
    }

    const goalsAmount = this._getNumberOfGoals();
    let missionFilter = '';
    if (!OMT.feature.isGiftingSupported()) {
      missionFilter = 'sendLife';
    }

    const fixedSpecialMissions = G.DAILYMISSIONS.getSpecialMissions(missionFilter, true);
    // fixed special missions will be deducted from the randomized missions
    const randomSpecialCount = goalsAmount.special - fixedSpecialMissions.length;
    const randomizedSpecialMissions = Phaser.ArrayUtils.shuffle(
      G.DAILYMISSIONS.getSpecialMissions(missionFilter, false),
    ).slice(0, randomSpecialCount);

    const missionsSpecial = [...fixedSpecialMissions, ...randomizedSpecialMissions];
    const missionsRegular = Phaser.ArrayUtils.shuffle( // make sure that we are not doubling active missions from prev day
      G.DAILYMISSIONS.getRegularMissions().filter((data) => !prevMissionData.find((prevMission) => prevMission.id === data.id)),
    ).slice(0, Math.max(0, goalsAmount.regular - prevMissionData.length));

    missionsSpecial.concat(missionsRegular)
      .forEach((missionData) => {
        this._goals.push(new G.DailyMission(missionData));
      }, this);

    prevMissionData.forEach((missionSaveData) => {
      const missionData = G.DAILYMISSIONS.getMissionById(missionSaveData.id);
      const mission = new G.DailyMission(missionData);
      mission.import(missionSaveData);
      this._goals.push(mission);
    }, this);

    await OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, 'DailyMis', 24 * 60 * 60);
    this.saveData();
  },

  _loadDay: function (savedData) {
    if (this._goals) this._goals.forEach((mission) => { mission.destroy(); });
    this._goals = [];
    savedData.missions.forEach((missionSaveData) => {
      // if (!OMT.feature.isGiftingSupported() && missionSaveData.id === 'sendLife' && missionSaveData.status === 'active') {
      //   return;
      // }
      if (missionSaveData.id === 'dailyChallenge' && !G.featureUnlock.dailyChallenge.enabled) {
        return;
      }
      if (missionSaveData.id === 'tournament' && (!G.featureUnlock.weeklyTournament.enabled || OMT.platformTournaments.getCooldownTimeRemaining() > 0)) {
        return;
      }
      const missionData = G.DAILYMISSIONS.getMissionById(missionSaveData.id);
      if (missionData) {
        const mission = new G.DailyMission(missionData);
        mission.import(missionSaveData);
        this._goals.push(mission);
      } else {
        // fallback = random regular mission
        this._goals.push(new G.DailyMission(Phaser.ArrayUtils.shuffle(
          G.DAILYMISSIONS.getRegularMissions(),
        )[0]));
      }
    }, this);

    this.mainRewardClaimed = savedData.mainRewardClaimed;
  },

  claimMainReward: function () {
    this.mainRewardClaimed = true;
    G.sb('dailyMissionMgr-mainRewardClaimed').dispatch();
    OMT.platformTracking.logFTUEvent('FTUX_FirstDailyRewardMade');
    this.saveData();

    this.signals.onMainRewardClaim.dispatch();
  },

  /**
   * save updated to missions
   */
  saveData: function () {
    G.saveState.data.dailyMissions = {
      mainRewardClaimed: this.mainRewardClaimed,
      missions: this._goals.map(function (goal) {
        return goal.export();
      }),
    };
    G.saveState.save();
    this._unsavedProgress = false;
  },

  /**
   * tell the manager we have progress that needs to be saved
   */
  flagUnsavedMissionProgress: function () {
    this._unsavedProgress = true;
  },

  /**
   * checks if there is any unsaved mission progress and triggers a save if so
   * @returns {boolean}
   */
  checkUnsavedMissionProgress: function () {
    if (this._unsavedProgress) this.saveData();
  },

  _checkIfNewDay: function () {
    var lastTime = G.saveState.data.dailyMissionDate || 0;

    var dateNow = new Date();
    var lastTimeDate = new Date(lastTime);

    // New day is synced with the timer that you see on the side bar
    if (this.getSecToNextDaily() <= 0) {
      return true;
    } else {
      return false;
    }
  },

  getSecToNextDaily: function() {
    var date = new Date(G.saveState.data.dailyMissionDate);
    date.setHours(24); // 24 hours after
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    var toMidnight = Math.floor((date.getTime()-Date.now())/1000);
    var midnightOffset = G.json['configs/miniMissions'].midnightHoursOffset*3600;

    return toMidnight+midnightOffset;
  },

  _getNumberOfGoals: function() {
    var passedLevel = G.saveState.getLastPassedLevelNr();

    var amounts = G.Utils.defined(
      G.json['configs/miniMissions'].missionsAmounts,
      [{
        afterLevel: 0,
        special: 3,
        regular: 2,
      }]);

    amounts.sort(function(a, b) {
      return b.afterLevel > a.afterLevel;
    });

    return amounts.find(function(config) {
      return config.afterLevel <= passedLevel;
    });
  },

  getProgress: function() {
    var finished = this._goals
      .reduce(function(acc, goal) {
        return acc + (goal.status === 'claimed' ? 1 : 0);
      }, 0);

    return {
      finished: finished,
      goal: this._goals.length,
      mainRewardClaimed: this.mainRewardClaimed,
    };
  },

  getProgressPercent: function() {
    const progress = G.dailyMissionsMgr.getProgress(); //TODO: these scoping issues need to be fixed
    const progressPercent = Math.round(progress.finished / progress.goal * 100);
    return !(isNaN(progressPercent) || !isFinite(progressPercent) || progressPercent === null) ? progressPercent : 0;
  },

  getCollectionIcons: function() {
    var fullDaysSinceEpoch = Math.floor(Date.now()/8.64e7)+3;
    var icon = [
      'cupcake_purple',
      'lolly_purple',
      'donut_purple',
      'icecream_purple',
    ][fullDaysSinceEpoch % 4];

    var hole = [
      'cupcake_shape',
      'lolly_shape',
      'donut_shape',
      'icecream_shape',
    ][fullDaysSinceEpoch % 4];


    return {
      icon: icon,
      hole: hole,
    }
  },

  getGoals: function() {
    return this._goals;
  },

}; 
