/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */

import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

/* eslint-disable no-param-reassign */
const MASK_PADDING = 2;
const DEFAULT_WIDTH = 48;
const DEFAULT_HEIGHT = 48;
const DEFAULT_BUTTON_SPACING = 4;
const DEFAULT_BG_PADDING = 2;
const DEFAULT_TRIGGER_BUTTON_PADDING = 10;
const DEFAULT_EXPAND_DURATION = 333;

export const ICON_LAYOUT_STYLE = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
  INVERSE_HORIZONTAL: 'inverse_horizontal',
};

export default class OMT_UI_ExpandingButtonList extends Phaser.Group {
  /**
   * constructor
   * @param {number} buttonWidth
   * @param {number} buttonHeight
   * @param {number} buttonSpacing
   * @param {number} bgPadding
   * @param {number} triggerButtonPadding
   * @param {number} expandDuration
   */
  constructor(
    buttonWidth = DEFAULT_WIDTH,
    buttonHeight = DEFAULT_HEIGHT,
    buttonSpacing = DEFAULT_BUTTON_SPACING,
    bgPadding = DEFAULT_BG_PADDING,
    triggerButtonPadding = DEFAULT_TRIGGER_BUTTON_PADDING,
    expandDuration = DEFAULT_EXPAND_DURATION,
  ) {
    super(game);

    this.signals = {
      onExpand: new Phaser.Signal(),
      onContract: new Phaser.Signal(),
    };

    this._buttonWidth = buttonWidth;
    this._buttonHeight = buttonHeight;
    this._buttonSpacing = buttonSpacing;
    this._bgPadding = bgPadding;
    this._triggerButtonPadding = triggerButtonPadding;

    this._expandOriginY = 0;
    this._expandOriginX = 0;
    this._expandDuration = expandDuration;
    this._pointerDown = false;

    this._buttons = {};
  }

  /**
   * phaser update method
   */
  update() {
    super.update();
    this._checkPointerDownOutside();
  }

  /**
   * check if the pointer is down outside the menu area
   */
  _checkPointerDownOutside() {
    const isExpanded = this._expandTriggerButton.expandState;
    if (this._pointerDown !== game.input.activePointer.isDown) {
      this._pointerDown = game.input.activePointer.isDown;
      if (isExpanded && this._pointerDown) {
        const pointer = this.toLocal(game.input);
        const containsPointer = Phaser.Rectangle.containsPoint(this.getBounds(this), pointer);
        if (!containsPointer) this.expandState = false;
      }
    }
  }

  /**
   * call to initialize the button layout
   * @param {string} iconlayoutStyle
   * @param {Array} buttonList
   * @param {ExpandTriggerButton} triggerButton
   */
  initButtons(iconlayoutStyle, buttonList, triggerButton = null) {
    this._iconlayoutStyle = iconlayoutStyle;
    this._initExpandableContainer();
    this._initMask();
    this._initTriggerButton(triggerButton);

    for (let i = buttonList.length - 1; i >= 0; i--) {
      const button = buttonList[i];
      this._addExpandableButton(button);
    }
    this._initExpandableBackground();

    this._expandableContainer.mask.dirty = true;
  }

  /**
   * get the button height
   * @returns {number}
   */
  get buttonWidth() {
    return this._buttonWidth;
  }

  /**
   * get the button width
   * @returns {number}
   */
  get buttonHeight() {
    return this._buttonHeight;
  }

  /**
   * gett a button reference by its key
   * @param {string} key
   */
  getButtonByKey(key) {
    return this._buttons[key];
  }

  /**
   * init the expandable content container
   */
  _initExpandableContainer() {
    this._expandableContainer = new Phaser.Group(game);
    this._expandableContainer.visible = false;
    this.addChildAt(this._expandableContainer, 0);

    switch (this._iconlayoutStyle) {
      case ICON_LAYOUT_STYLE.VERTICAL:
        this._expandOriginY = this._buttonHeight;
        this._expandableContainer.y = this._expandOriginY;
        break;
      case ICON_LAYOUT_STYLE.HORIZONTAL:
        this._expandOriginX = this._buttonWidth;
        this._expandableContainer.x = this._expandOriginX;
        break;
      case ICON_LAYOUT_STYLE.INVERSE_HORIZONTAL:
        this._expandOriginX = this._buttonWidth;
        this._expandableContainer.x = this._expandOriginX;
        break;
      default: break;
    }
  }

  /**
   * init the content mask
   */
  _initMask() {
    this._maskGraphics = new Phaser.Graphics(game);
    this._maskGraphics.beginFill(0xFF0000);
    const padding = MASK_PADDING + this._bgPadding;

    switch (this._iconlayoutStyle) {
      case ICON_LAYOUT_STYLE.VERTICAL:
        this._maskGraphics.drawRect(0, 0, this._buttonWidth + (padding * 2), this._buttonHeight + padding);
        this._maskGraphics.x = -this._maskGraphics.width / 2;
        break;
      case ICON_LAYOUT_STYLE.HORIZONTAL:
        this._maskGraphics.drawRect(0, 0, this._buttonWidth + (padding * 2), this._buttonHeight + padding);
        this._maskGraphics.y = this._maskGraphics.width / 2;
        this._maskGraphics.rotation = -Math.PI / 2;
        break;
      case ICON_LAYOUT_STYLE.INVERSE_HORIZONTAL:
        this._maskGraphics.drawRect(0, -this._buttonWidth + (padding * 2), this._buttonWidth + (padding * 2), this._buttonHeight + padding);
        this._maskGraphics.y = this._maskGraphics.width / 2;
        this._maskGraphics.x = -this.buttonWidth;
        this._maskGraphics.rotation = -Math.PI / 2;
        break;
      default: break;
    }
    this._maskGraphics.endFill();

    this.addChild(this._maskGraphics);
    this._expandableContainer.mask = this._maskGraphics;
  }

  /**
   * init background for expandable content
   */
  _initExpandableBackground() {
    let width; let height;

    this._expandableBg = new PhaserNineSlice.NineSlice(
      0, 0, 'buttons', 'settings_area', this._buttonWidth, this._buttonHeight,
      {
        left: 12, right: 12, top: 12, bottom: 12,
      },
    );

    switch (this._iconlayoutStyle) {
      case ICON_LAYOUT_STYLE.VERTICAL:
        width = this._buttonWidth + (this._bgPadding * 2);
        height = this._expandableContainer.height + Math.abs(this._expandOriginY) + (this._bgPadding * 2);
        this._expandableBg.anchor.set(0.5, 1);
        this._expandableBg.y = -this._expandOriginY + (this._buttonHeight / 2) + this._bgPadding;
        break;
      case ICON_LAYOUT_STYLE.HORIZONTAL:
        width = this._expandableContainer.width + Math.abs(this._expandOriginX) + (this._bgPadding * 2);
        height = this._buttonHeight + (this._bgPadding * 2);
        this._expandableBg.anchor.set(1, 0.5);
        this._expandableBg.x = -this._expandOriginX + (this._buttonWidth / 2) + this._bgPadding;
        break;
      case ICON_LAYOUT_STYLE.INVERSE_HORIZONTAL:
        width = this._expandableContainer.width + Math.abs(this._expandOriginX) + (this._bgPadding * 2);
        height = this._buttonHeight + (this._bgPadding * 2);
        this._expandableBg.anchor.set(0, 0.5);
        this._expandableBg.x = -this._expandOriginX + (this._buttonWidth / 2) + this._bgPadding;
        break;
      default: break;
    }
    this._expandableBg.resize(width * 2, height * 2);
    this._expandableBg.scale.set(0.5);
    this._expandableContainer.addChildAt(this._expandableBg, 0);
  }

  /**
   * init the expand trigger button
   * @param {ExpandTriggerButton} button this should be a instance of ExpandTriggerButton
   */
  _initTriggerButton(button = null) {
    if (!button) button = new ExpandTriggerButton();
    button.width = this._buttonWidth + (this._triggerButtonPadding * 2);
    button.height = this._buttonHeight + (this._triggerButtonPadding * 2);
    button.signals.onExpand.add(() => { this._expand(); });
    button.signals.onContract.add(() => { this._contract(); });
    this.addChild(button);
    this._expandTriggerButton = button;
  }

  /**
   * add a buton the expandable content
   * @param {Phaser.DisplayObject} button
   */
  _addExpandableButton(buttonObject) {
    const button = buttonObject.obj;
    button.width = this._buttonWidth;
    button.height = this._buttonHeight;
    this._expandableContainer.addChild(button);
    this._buttons[buttonObject.key] = buttonObject.obj;
    const buttonLength = _.size(this._buttons);

    // adjust mask height
    this._expandableContainer.mask = null;
    switch (this._iconlayoutStyle) {
      case ICON_LAYOUT_STYLE.VERTICAL:
        button.y = -(this._buttonHeight + this._buttonSpacing) * buttonLength;
        this._maskGraphics.height = ((this._buttonHeight + this._buttonSpacing) * (buttonLength + 1)) + MASK_PADDING;
        break;
      case ICON_LAYOUT_STYLE.HORIZONTAL:
        button.x = -(this._buttonWidth + this._buttonSpacing) * buttonLength;
        this._maskGraphics.height = ((this._buttonHeight + this._buttonSpacing) * (buttonLength + 1)) + MASK_PADDING;
        break;
      case ICON_LAYOUT_STYLE.INVERSE_HORIZONTAL:
        button.x = (this._buttonWidth * (buttonLength - 1)) + (this._buttonSpacing * buttonLength);
        this._maskGraphics.height = ((this._buttonHeight + this._buttonSpacing) * (buttonLength + 1)) + MASK_PADDING;
        break;
      default: break;
    }
    this._expandableContainer.mask = this._maskGraphics;
  }

  /**
   * expand the menu
   */
  _expand() {
    this._expandableContainer.visible = true;
    this._toggleChildInput(false);
    const buttonLength = _.size(this._buttons);
    let tween;

    switch (this._iconlayoutStyle) {
      case ICON_LAYOUT_STYLE.VERTICAL: {
        const expandHeight = buttonLength * (this._buttonHeight + this._buttonSpacing);
        const toY = this._expandOriginY + expandHeight + this._triggerButtonPadding;
        tween = game.add.tween(this._expandableContainer).to({ y: toY }, this._expandDuration, Phaser.Easing.Quadratic.Out, false);
      }
        break;
      case ICON_LAYOUT_STYLE.HORIZONTAL: {
        const expandWidth = buttonLength * (this._buttonWidth + this._buttonSpacing);
        const toX = this._expandOriginX + expandWidth + this._triggerButtonPadding;
        tween = game.add.tween(this._expandableContainer).to({ x: toX }, this._expandDuration, Phaser.Easing.Quadratic.Out, false);
      }
        break;
      case ICON_LAYOUT_STYLE.INVERSE_HORIZONTAL: {
        const expandWidth = (buttonLength + 1) * (this._buttonWidth + this._buttonSpacing);
        const toX = this._expandOriginX - expandWidth - this._triggerButtonPadding;
        tween = game.add.tween(this._expandableContainer).to({ x: toX }, this._expandDuration, Phaser.Easing.Quadratic.Out, false);
      }
        break;
      default: break;
    }

    tween.onComplete.add(() => {
      this._toggleChildInput(true);
    });
    tween.start();
    this.signals.onExpand.dispatch();
  }

  /**
   * contract the menu
   */
  _contract() {
    let tween;
    this._toggleChildInput(false);

    switch (this._iconlayoutStyle) {
      case ICON_LAYOUT_STYLE.VERTICAL: {
        const toY = this._expandOriginY;
        tween = game.add.tween(this._expandableContainer).to({ y: toY }, this._expandDuration, Phaser.Easing.Quadratic.Out, false);
      }
        break;
      case ICON_LAYOUT_STYLE.HORIZONTAL: {
        const toX = this._expandOriginX;
        tween = game.add.tween(this._expandableContainer).to({ x: toX }, this._expandDuration, Phaser.Easing.Quadratic.Out, false);
      }
        break;
      case ICON_LAYOUT_STYLE.INVERSE_HORIZONTAL: {
        const toX = this._expandOriginX;
        tween = game.add.tween(this._expandableContainer).to({ x: toX }, this._expandDuration, Phaser.Easing.Quadratic.Out, false);
      }
        break;
      default: break;
    }

    tween.onComplete.add(() => {
      this._toggleChildInput(true);
      this._expandableContainer.visible = false;
    });
    tween.start();
    this.signals.onContract.dispatch();
  }

  /**
   * toggle child input states
   * @param {boolean} enableState
   */
  _toggleChildInput(enableState) {
    _.forEach(this._buttons, (button) => {
      button.input.enabled = enableState;
    });
  }

  /**
   * lock all input
   */
  lockInput() {
    this._expandTriggerButton.input.enabled = false;
    this._toggleChildInput(false);
  }

  /**
   * unlock all input
   */
  unlockInput() {
    this._expandTriggerButton.input.enabled = true;
    this._toggleChildInput(true);
  }

  /**
   * set the expanded state
   * @param {boolean} state
   */
  set expandState(state) {
    this._expandTriggerButton.expandState = state;
  }

  /**
   * get the bounds of the menu trigger button
   * @returns {Phaser.Rectangle}
   */
  getTriggerButtonBounds() {
    const tempBounds = this._expandTriggerButton.getBounds().clone();
    tempBounds.width = this._buttonWidth + (this._triggerButtonPadding * 2);
    tempBounds.height = this._buttonHeight + (this._triggerButtonPadding * 2);
    switch (this._iconlayoutStyle) {
      case ICON_LAYOUT_STYLE.INVERSE_HORIZONTAL:
        tempBounds.x = game.width - 80;
        tempBounds.y = game.height - 85;
        tempBounds.width = this._triggerButtonPadding * 4;
        tempBounds.height = this._triggerButtonPadding * 4;
        break;
      default: break;
    }
    if (G.IAP) {
      tempBounds.width = this._buttonWidth + (this._triggerButtonPadding * 2);
      tempBounds.height = this._buttonHeight + (this._triggerButtonPadding * 2);
    }
    return tempBounds;
  }

  /**
   * get the bounds of the expanded menu
   * @returns {Phaser.Rectangle}
   */
  getExpandedMenuBounds() {
    const tempBounds = this.getTriggerButtonBounds();
    tempBounds.width = tempBounds.width + _.size(this._buttons) * (this._buttonWidth + this._buttonSpacing) + (this._bgPadding * 2);
    switch (this._iconlayoutStyle) {
      case ICON_LAYOUT_STYLE.INVERSE_HORIZONTAL:
        // tempBounds.width -= (this._buttonWidth + this._buttonSpacing) / 2;
        // tempBounds.x -= tempBounds.width;
        tempBounds.x = game.width - 225;
        tempBounds.y = game.height - 85;
        tempBounds.width = this._triggerButtonPadding * 18;
        tempBounds.height = this._triggerButtonPadding * 4;
        break;
      default: break;
    }
    return tempBounds;
  }

  /**
   * destruction method
   */
  destroy() {
    Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    super.destroy();
  }
}

/**
 * base class for expand trigger button. extend to add more features/
 */
export class ExpandTriggerButton extends UI_GButtonES6 {
  /**
   * constructor
   */
  constructor() {
    super(0, 0, 'small_button_special_blue', () => { this._onToggled(); });
    this._expandState = false;

    this.signals = {
      onExpand: new Phaser.Signal(),
      onContract: new Phaser.Signal(),
    };
  }

  /**
   * on expand toggled
   */
  _onToggled() {
    // eslint-disable-next-line no-unused-expressions
    this._expandState ? this._onContract() : this._onExpand();
  }

  /**
   * on expand state
   */
  _onExpand() {
    this._expandState = true;
    this.signals.onExpand.dispatch();
  }

  /**
   * on contract state
   */
  _onContract() {
    this._expandState = false;
    this.signals.onContract.dispatch();
  }

  /**
   * get the expanded stat
   */
  get expandState() {
    return this._expandState;
  }

  /**
   * set the expanded state
   */
  set expandState(state) {
    if (this._expandState === state) return;
    this._onToggled();
  }
}
