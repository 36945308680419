/* eslint-disable no-param-reassign */
const ICON_SIZE = 130;
let crossPromoIconVisibleTracked = false;

/**
 * The circle button icon on the bottom left of the saga map (just above the leaderboard)
 * @author Sandra Koo
 */
export default class OMT_UI_CrossPromoMapButton extends Phaser.Group {
  /**
   * constructor
   * @param {boolean} entranceDirectionIsRight button should enter from right side.
   */
  constructor(entranceDirectionIsRight) {
    super(game);
    this.isRight = entranceDirectionIsRight;
    // this.drawWaitingIcon();
    this._waitingTimer = game.time.events.add(10000, this._onTimeout.bind(this));
  }

  /**
   * init the cross-promo button
   * @param {Object} crossPromoData
   * @param {string} iconURL
   */
  init(crossPromoData, iconURL) {
    this.removeWaitingIcon();
    this.currentPromo = crossPromoData;
    this.iconURL = iconURL;
    this.activeButton = true;
    this.drawButtonBase(); // Draw the base
    this.drawPromo(); // Draw the promo

    this._onCrossPromoSeen = () => {
      this.goToUnobtrusiveMode();
    };
    this._signalToken = G.sb('crossPromoSeen').add(this._onCrossPromoSeen, this);

    OMT.platformTracking.logEvent('xpromoIconVisible');
  }

  /**
   * destruction method
   */
  destroy() {
    this.activeButton = false;
    this.toggleButton(false);
    if (this._signalToken) {
      if (this._signalToken.detach) {
        this._signalToken.detach();
      }
      this._signalToken = null;
    }
    if (this.bounceAnimation) {
      this.bounceAnimation.stop();
      if (this.bounceAnimation.chainedTween) {
        this.bounceAnimation.chainedTween.stop();
      }
    }
    super.destroy();
  }

  drawWaitingIcon() {
    if (!this._waitingIcon) this._waitingIcon = new G.WaitingIcon(0, 0, 'waiting_icon_white'); // Loading...
    this.addChild(this._waitingIcon);
  }

  /**
   * Function that tweens out the icon
   * @param {boolean} sendTimeoutSignal
   */
  removeWaitingIcon(sendTimeoutSignal = false) {
    if (this._waitingTimer && this._waitingTimer.destroy) {
      this._waitingTimer.destroy();
    }
    this._waitingTimer = null;
    if (this._waitingIcon) {
      const tw = game.add.tween(this._waitingIcon)
        .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
      tw.onComplete.addOnce(() => {
        if (this._waitingIcon) {
          if (this._waitingIcon.parent) {
            this._waitingIcon.parent.removeChild(this._waitingIcon);
          }
          this._waitingIcon.destroy();
          if (sendTimeoutSignal) G.sb('xPromoTimeout').dispatch(this);
        }
        this._waitingIcon = null;
      });
    }
  }

  /**
   * Timeout callback
   */
  _onTimeout() {
    this.removeWaitingIcon(true);
  }

  /**
   * Was originally supposed to draw the border of the icon
   * But the icon came with it so now its just a mask
   */
  drawButtonBase() {
    this.button = new G.Button(0, 0, null);
    const buttonMask = new Phaser.Graphics(game);
    buttonMask.beginFill(0);
    buttonMask.drawCircle(0, 0, 129);
    buttonMask.endFill();

    this.addChild(this.button);
    // this.button.addChild(buttonMask);
    // this.mask = buttonMask;
  }

  /**
   * Loads the icon, fades it in
   */
  drawPromo() {
    G.makeExtImage(0, 1, this.iconURL, null, 0.5, this, true, (img) => {
      this.button.addChild(img);
      img.alpha = 0;
      game.add.tween(img).to({ alpha: 1 }, 250, Phaser.Easing.Quadratic.Out, true);
      this.toggleButton(true);
      img.width = ICON_SIZE;
      img.height = img.width;
    });
  }

  /**
   * Activates the button being on or not.
   * When its off, it'll fade out and destroy itself
   * @param {Boolean} isOn
   */
  toggleButton(isOn) {
    if (!this.button) return;
    if (isOn === undefined) {
      isOn = !this.inputEnabled;
    }

    this.button.active = isOn;
    if (isOn) {
      this.button.onClick.add(this.onButtonClick, this);
    } else {
      this.button.onClick.remove(this.onButtonClick, this);
      this.fadeOut();
    }
  }

  /**
   * set the button enter animation
   */
  enterButton() {
    if (this.button) {
      if (!crossPromoIconVisibleTracked) { // track cross promo icon visible / activated
        crossPromoIconVisibleTracked = true;
        const trackingEventName = OMT.platformTracking.Events.XPromoVisible.replace('%id%', 'map_icon');
        OMT.platformTracking.logEvent(trackingEventName);
      }

      if (OMT.crossPromo.isCrossPromoSeen()) { // If its already been seen, don't make it bounce
        this.goToUnobtrusiveMode();
        return;
      }

      const obj = {
        angle: 1080 * (this.isRight ? 1 : -1),
        x: 1000 * (this.isRight ? 1 : -1),
        alpha: 0,
      };
      this.button.angle = obj.angle;
      this.button.x = obj.x;

      const tw = game.add.tween(obj)
        .to({ x: 0, angle: 0, alpha: 0.5 }, 2000, Phaser.Easing.Sinusoidal.Out, true);
      tw.onUpdateCallback(() => {
        this.button.alpha = obj.alpha * 2;
        this.button.angle = obj.angle;
        this.button.x = obj.x;
      });
      tw.onComplete.add(() => {
        this.button.x = 0; // Ensures that the button params are set even if there was a lag spike
        this.button.angle = 0;
        this.button.alpha = 1;
        this.bounceButton(); // Bouncy!
      });
    }
  }

  /**
   * Bounce tween
   */
  bounceButton() {
    const riseAndFallTime = 750;
    const bigScale = 1.15;
    const delayTime = 2000;
    this.bounceAnimation = game.add.tween(this.button.scale) // Initial tween for the raising action
      .to({ x: [bigScale, 1], y: [bigScale, 1] }, riseAndFallTime, Phaser.Easing.Back.InOut, true);
    this.bounceAnimation.onStart.add(() => {
      game.add.tween(this.button)
        .to({ angle: [-10, 0] }, riseAndFallTime, Phaser.Easing.Back.InOut, true);
    });
    const subtlePause = game.add.tween(this.button) // Pause...
      .to({ y: 0 }, delayTime, Phaser.Easing.Linear.None);
    this.bounceAnimation.chain(subtlePause);
    subtlePause.chain(this.bounceAnimation);
    this.bounceAnimation.start();
  }

  /**
   * on button clicked
   */
  async onButtonClick() {
    const trackingEventName = OMT.platformTracking.Events.XPromoClicked.replace('%id%', 'map_icon');
    OMT.platformTracking.logEvent(trackingEventName);
    OMT.crossPromo.showCrossPromoWindow('map_picture', this.currentPromo, null, true);
  }

  /**
   * Fades out and destroys itself
   */
  fadeOut() {
    const tw = game.add.tween(this).to({ alpha: 0 }, 250, Phaser.Easing.Quadratic.InOut);
    tw.onComplete.add(() => { this.destroy(); });
    tw.start();
  }

  /**
   * Sets the button to not be bouncing.
   * Makes it smaller too
   */
  goToUnobtrusiveMode() {
    if (this.bounceAnimation) {
      this.bounceAnimation.stop();
      if (this.bounceAnimation.chainedTween) {
        this.bounceAnimation.chainedTween.stop();
      }
      this.bounceAnimation = null;
    }
    this.button.x = 0;
    this.button.alpha = 1;
    this.button.angle = 0;
    this.button.scale.set(0.85);
  }
}
