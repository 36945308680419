import { LIGHT_OFF_ASSETS, LIGHT_STYLE } from './LightsConfig';

export default class Light extends Phaser.Group {
  constructor(x, y, isOn = false, lightStyle = LIGHT_STYLE.NORMAL) {
    super(game, null);
    this.x = x;
    this.y = y;
    this.isOn = isOn;
    this.lightStyle = lightStyle;
    this.blinkLimit = -1;
    this.isLooping = true;
    this.offTime = 220;
    this.onTime = 220;
    this.scale.setTo(0.35);

    this._lightOff = G.makeImage(0, 0, LIGHT_OFF_ASSETS[this.lightStyle], 0.5, this);
    this._lightOff.visible = false;

    this._lightOn = G.makeImage(0, 0, 'realMoney_light_on', 0.5, this);
    this._scheduledEvent = null;

    this.setState(isOn);
  }

  /**
   * Start flashing animation
   * @param {number} delay
   * @param {boolean} reset hard-restart the animation
   */
  startAnim(delay, reset = false) {
    this.flipState();
    if (reset && this._scheduledEvent) {
      game.time.events.remove(this._scheduledEvent);
    }

    const waitTime = this.isOn ? this.onTime + delay : this.offTime + delay;
    if (this.isLooping) {
      this._scheduledEvent = game.time.events.add(waitTime, () => {
        this.startAnim(0);
      });
      if (this.blinkLimit !== -1) {
        if (this.blinkLimit > 0) {
          this.blinkLimit--;
        } else {
          this.isLooping = false;
        }
      }
    }
  }

  /**
   * Stop flashing animation
   * @param {boolean} isOn
   */
  stopAnim(isOn) {
    this.setState(isOn);
    if (this._scheduledEvent) {
      game.time.events.remove(this._scheduledEvent);
    }
  }

  /**
   * Set looping state
   * @param {boolean} isLooping
   */
  setLooping(isLooping) {
    this.isLooping = isLooping;
  }

  /**
   * set blink once state
   * @param {boolean} times -1 = unlimited
   */
  setBlinkLimit(times) {
    this.blinkLimit = times;
  }

  /**
   * Set blink times. Use null to not set a particular delay
   * @param {number} onTime
   * @param {number} offTime
   */
  setOnOffTime(onTime, offTime) {
    if (onTime != null) {
      this.onTime = onTime;
    }

    if (offTime != null) {
      this.offTime = offTime;
    }
  }

  /**
   * Flips the state of the light
   */
  flipState() {
    this.isOn = !this.isOn;
    this._lightOff.visible = !this.isOn;
    this._lightOn.visible = this.isOn;
  }

  /**
   * Sets the state of the light
   * @param {boolean} state
   */
  setState(state) {
    this.isOn = state;
    this._lightOff.visible = !this.isOn;
    this._lightOn.visible = this.isOn;
  }

  /**
   * Changes the light style
   * @param {LIGHT_STYLE} style
   */
  changeStyle(style) {
    if (this.lightStyle === style) return;
    this.lightStyle = style;
    G.changeTexture(this._lightOff, LIGHT_OFF_ASSETS[this.lightStyle]);
  }
}
