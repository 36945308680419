/**
 * view for individual competitors views during Tournament gameplay
 */
export class TournamentCompetitorScoreViewBase extends Phaser.Group {
  /**
   * constructor
   * @param {Object} entryData
   */
  constructor(entryData) {
    super(game);
    this._init(entryData);
  }

  _init(entryData) {
    this._entryData = entryData;

    this._initBg();
    this._adjustOffset();

    this._initAvatar();
    this._initRankDisplay();
    this._initScoreField();
  }

  /**
   * Adjusts an offset
   */
  _adjustOffset() {
    this._offsetX = 0;
  }

  /**
   * init the background image and set the offset for the background anchoring
   */
  _initBg() {
    // Override
  }

  /**
   * init the competitor avatar
   * @param {{x:number, y:class, Class:TournamentCompetitorAvatarBase}} config
   */
  _initAvatar(config) {
    this._avatar = new config.Class(this._entryData.image);
    this._avatar.x = config.x;
    this._avatar.y = config.y;
    this.addChild(this._avatar);
  }

  /**
   * init the competitor rank display / field
   * @param {{rankImages:Array<string>, image:{x:number, y:number}, text:{x:number, y:number, style:(Object|string)}, anchor:(number|Array<number>)}} config
   */
  _initRankDisplay(config) {
    const { rank } = this._entryData;
    if (rank > config.rankImages.length) { // use a text field to display the ranks with no images
      this._rankGraphic = new G.Text(
        this._offsetX + config.text.x,
        config.text.y,
        `#${rank}`,
        config.text.style,
        config.anchor,
        50,
      );
    } else { // rank has a specific image to use
      this._rankGraphic = G.makeImage(config.image.x, config.image.y, config.rankImages[rank - 1], config.anchor);
    }
    this.addChild(this._rankGraphic);
  }

  /**
   * init the competitor score field
   * @param {{x:number, y:number, style:(string|Object), anchor:number}} config
   */
  _initScoreField(config) {
    this._scoreText = new G.Text(
      config.x,
      config.y, `${this._entryData.score} ${OMT.language.getText('points')}`,
      config.style,
      config.anchor,
      200,
    );
    this.addChild(this._scoreText);
  }

  /**
   * get the related leaderboard entryData
   * @returns {Object}
   */
  get entryData() {
    return this._entryData;
  }

  /**
   * set the out transistion
   * @param {{x:number, y:number, angle:number, duration:number, delay:number, fadeDuration:number, fadeDelay:number}} config
   */
  animateOut(config) {
    // movement tween
    this._outMovementTween = game.add.tween(this).to(
      { x: config.x, y: config.y, angle: config.angle },
      config.duration,
      Phaser.Easing.Quadratic.In,
      true,
      config.delay,
    );
    // fade out tween
    this._fadeTween = game.add.tween(this).to(
      { alpha: 0 },
      config.fadeDuration,
      Phaser.Easing.Quadratic.Out,
      true,
      config.fadeDelay,
    );
    // remove / destroy instance on animated out
    this._fadeTween.onComplete.addOnce(() => {
      if (this.parent) this.parent.removeChild(this);
      this.destroy();
    });
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    if (this._outMovementTween) this._outMovementTween.stop();
    if (this._fadeTween) this._fadeTween.stop();
  }
}
