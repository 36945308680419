import { TextTimer } from '../TextTimer';

G.WorldSidePanelWidgetButtonTimer = function(x, y) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);

  this.bg = G.makeImage(0, 0, 'missions_map_banner_yellow_small', 0, this);

  this.bgTopFade = G.makeImage(0, 0, 'missions_map_banner_yellow_small', 0, this);
  this.bgTopFade.alpha = 0;

  this.text = new G.Text(100, 20, ' ', {
    style: 'font-white',
    fontSize: '25px',
  }, 0.5, 110);
  this.add(this.text);

  this.timer = new TextTimer({
    x: 100,
    y: 20,
    date: 0,
    style: {
      style: 'font-white',
      fontSize: '25px',
    },
    anchor: 0.5,
  });
  this.add(this.timer);

  this.alpha = 0;
};

G.WorldSidePanelWidgetButtonTimer.prototype = Object.create(Phaser.Group.prototype);

G.WorldSidePanelWidgetButtonTimer.prototype.showBanner = function(config) {
  if (this.game === null) return;

  if (this.alpha < 1) {
    G.stopTweens(this);
    game.add.tween(this)
      .to({alpha: 1}, 300, Phaser.Easing.Sinusoidal.Out, true);
  }

  if (config.bgSprite) {
    if (this.bg.frameName !== config.bgSprite) {
      G.changeTexture(this.bgTopFade, this.bg.frameName);
      this.bgTopFade.alpha = 1;
      game.add.tween(this.bgTopFade)
        .to({alpha: 0}, 300, Phaser.Easing.Sinusoidal.Out, true);
      G.changeTexture(this.bg, config.bgSprite);
    }
  }

  G.stopTweens(this.text);
  G.stopTweens(this.timer);

  if (config.text) {
    if (this.timer.alpha > 0) {
      game.add.tween(this.timer)
        .to({alpha: 0}, 300, Phaser.Easing.Sinusoidal.Out, true);
    }
    if (this.text.alpha < 1) {
      game.add.tween(this.text)
        .to({alpha: 1}, 300, Phaser.Easing.Sinusoidal.Out, true);
    }
    this.text.setText(config.text);

  } else if (config.timer) {

    if (this.text.alpha > 0) {
      game.add.tween(this.text)
        .to({alpha: 0}, 300, Phaser.Easing.Sinusoidal.Out, true);
    }
    if (this.timer.alpha < 1) {
      game.add.tween(this.timer)
        .to({alpha: 1}, 300, Phaser.Easing.Sinusoidal.Out, true);
    }
    this.timer.setSecLeft((config.endDate - Date.now())/1000);
    this.timer.start();
  }

};

G.WorldSidePanelWidgetButtonTimer.prototype.hideBanner = function() {
  if (this.alpha > 0) {
    G.stopTweens(this);
    game.add.tween(this)
      .to({alpha: 0}, 300, Phaser.Easing.Sinusoidal.Out, true);
  }
};