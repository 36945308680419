import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

/**
 * Hint button
 * @author Sandra Koo
 */
export default class OMT_UI_HintButton extends UI_GButtonES6 {
  /**
   * constructor
   */
  constructor() {
    super(0, 0, 'small_button_yellow', () => { this._toggleHints(); });
    this._icon = G.makeImage(0, 0, 'hint_on_icon', [0.5, 0.5]);
    this.addChild(this._icon);
    this._updateIconTexture();
  }

  /**
   * update the icon texture based on hint state
   */
  _updateIconTexture() {
    this._icon.changeTexture(G.saveState.data.allowHints ? 'hint_on_icon' : 'hint_off_icon');
  }

  /**
   * toggle the hints
   */
  _toggleHints() {
    G.saveState.toggleAllowHints();
    this._updateIconTexture();
  }
}
