/* eslint-disable no-unused-vars */
import { Window } from '../../00_IMMEDIATE/Window';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { OMT_SystemInfo, ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

class Window_FriendBeaten extends Window {
  constructor(parentWindow, lvlIndex, player, user1, user2, offset, leaderboard, headerTxtText) {
    super(parentWindow);
    if (offset) {
      this.x += offset.x;
      this.y += offset.y;
    }
    this.playerConfig = player;
    this.user1Config = user1;
    this.lvlIndex = lvlIndex;

    if (leaderboard) leaderboard.hide();

    const header = new Phaser.Group(game, this);
    header.y = -200;
    const headerBg = new UI_NineSlice(0, 0, 'Level_banner_9s', 600, 100, {
      left: 20,
      right: 20,
      top: 20,
      bottom: 20,
    });
    headerBg.x -= headerBg.width / 2;
    headerBg.y -= headerBg.height / 2;
    const headerString = headerTxtText
      ? OMT.language.getText(headerTxtText)
      : `${OMT.language.getText('Level')} ${lvlIndex}`;

    const headerTxt = new G.Text(0, 0, headerString, { style: 'font-white', fontSize: 64 }, 0.5);

    header.add(headerBg);
    header.add(headerTxt);

    this.playerBox = this.createUserBox(player, true);

    this.user1Box = this.createUserBox(user1);

    this.playerBox.y = this.user1Box.getBounds().height;

    this.add(this.playerBox);
    this.add(this.user1Box);

    this.user2Box = null;

    if (user2) {
      this.user2Box = this.createUserBox(user2, false);
      this.user2Box.y = this.user1Box.getBounds().height;
      this.playerBox.y = this.user2Box.y + this.user2Box.getBounds().height;
      this.add(this.user2Box);
    } else {
      this.playerBox.y += 50;
      this.user1Box.y += 50;
    }

    this.footerGroup = new Phaser.Group(game, this);
    const subText = new G.Text(0, 0, `Send a challenge to ${user1.name}?`, { style: 'font-white', fontSize: 64 }, 0.5, 500);
    const { footerGroupY } = OMT_Utils.generateConditionalObject({
      footerGroupY: 450,
    }, {
      condition: () => OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal,
      parameters: {
        footerGroupY: 420,
      },
    });
    this.footerGroup.y = footerGroupY;
    this.footerGroup.add(subText);
    this.setupButtons();
    this._noThanksButton.y = this._challengeButton.y = 70;
    this.setupAnimations();
  }

  createUserBox(userConfig, isPlayer) {
    const userBox = new Phaser.Group(game, this);
    const bg = new UI_NineSlice(0, 0, isPlayer ? 'beaten_user_box' : 'beaten_friend_box', 500, 100, {
      top: 24,
      left: 24,
      right: 24,
      bottom: 24,
    });
    bg.x -= bg.width / 2;
    bg.y -= bg.height / 2;
    userBox.add(bg);
    const avatarGroup = new Phaser.Group(game, userBox);
    const avatarFrame = new G.Image(0, 0, 'avatar_frame', 0.5, avatarGroup);
    const avatar = G.makeExtImage(0, 0, userConfig.image, 'avatar_m', 0, this, false, (img) => {
      avatarGroup.add(img);
      avatarGroup.add(avatarFrame);
      img.width = avatarFrame.width - 10;
      img.height = avatarFrame.height - 10;
      img.x -= img.width / 2;
      img.y -= img.height / 2;
      avatarGroup.x = -bg.width / 2 + avatarGroup.width / 2 + 20;
    });
    const nameTxt = new G.Text(0, 0, userConfig.name, { style: 'font-blue', fontSize: 46 }, [0, 0.5]);
    nameTxt.x -= bg.width / 2;
    nameTxt.x += avatarFrame.x + 90;
    userBox.add(nameTxt);
    const score = new Phaser.Group(game, userBox);
    const scoreBg = new G.Image(0, 0, 'lb2_points_box', 0.5, score);
    const scoreTxt = new G.Text(0, 0, userConfig.score.toString(), { style: 'font-blue', fontSize: 32 }, [0.5, 0.5], scoreBg.width - 20, 100, true, 'center');
    scoreTxt.y += 3;
    scoreTxt.x += 7;
    const trophy = new G.Image(0, 0, 'score_icon', 0.5, score);
    trophy.scale.set(0.75);
    trophy.x = -scoreBg.width / 2;
    score.x = bg.width / 2 - score.width / 2 - 20;
    score.add(scoreTxt);

    const arrow = new G.Image(0, 0, isPlayer ? 'beaten_green_arrow' : 'beaten_red_arrow', 0.5, userBox);
    arrow.x -= bg.getBounds().width / 2;
    arrow.y -= bg.getBounds().height / 2;
    arrow.scale.set(0.8);
    return userBox;
  }

  setupButtons() {
    this._noThanksButton = new OMT_UI_SquareButton(120, 0, {
      button: {
        tint: BUTTONCOLOURS.red,
        dimensions: {
          width: 166,
          height: 86,
        },
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText('No, thanks'),
        textStyle: {
          style: 'font-white',
          fontSize: 68,
        },
      },
      options: {
        clickFunction: {
          onClick: this.onNoThanksClicked.bind(this),
        },
      },
    });
    this._challengeButton = new OMT_UI_SquareButton(-140, 0, {
      button: {
        tint: BUTTONCOLOURS.blue,
        dimensions: {
          width: 166,
          height: 86,
        },
      },
      text: {
        string: OMT.language.getText('Challenge'),
        textStyle: {
          style: 'font-white',
          fontSize: 68,
        },
      },
      options: {
        clickFunction: {
          onClick: this.onChallengeClicked.bind(this),
        },
      },
    });
    this.footerGroup.add(this._noThanksButton);
    this.footerGroup.add(this._challengeButton);
  }

  setupAnimations() {
    this.footerGroup.alpha = 0;
    this._challengeButton.alpha = 0;
    this._noThanksButton.alpha = 0;

    const delay = G.AsyncUtils.createScheduler(game);
    delay.rescheduleAction(() => {
      // do animation
      game.add
        .tween(this.playerBox.scale)
        .to({ x: 1.2, y: 1.2 }, 600, Phaser.Easing.Sinusoidal.Out, true)
        .onComplete.add(() => {
          delay.rescheduleAction(() => {
            game.add
              .tween(this.playerBox)
              .to({ y: this.user1Box.y }, 500, Phaser.Easing.Circular.InOut, true)
              .onComplete.add(() => {
                delay.rescheduleAction(() => {
                  game.add
                    .tween(this.playerBox.scale)
                    .to({ x: 1, y: 1 }, 600, Phaser.Easing.Sinusoidal.Out, true)
                    .onComplete.add(() => {
                      game.add
                        .tween(this.footerGroup)
                        .to({ alpha: 1 }, 700, Phaser.Easing.Sinusoidal.Out, true);
                      game.add
                        .tween(this._challengeButton)
                        .to({ alpha: 1 }, 700, Phaser.Easing.Sinusoidal.Out, true);
                      game.add
                        .tween(this._noThanksButton)
                        .to({ alpha: 1 }, 700, Phaser.Easing.Sinusoidal.Out, true);
                    });
                }, 400);
              });

            if (this.user2Box != null) {
              game.add
                .tween(this.user1Box)
                .to({ y: this.user2Box.y }, 800, Phaser.Easing.Bounce.Out, true);
              game.add
                .tween(this.user2Box)
                .to({ y: this.playerBox.y }, 800, Phaser.Easing.Bounce.Out, true);
            } else {
              game.add
                .tween(this.user1Box)
                .to({ y: this.playerBox.y }, 800, Phaser.Easing.Bounce.Out, true);
            }
          }, 200);
        });
    }, 700);
  }

  onNoThanksClicked() {
    this.closeWindow();
  }

  onChallengeClicked() {
    this._challengeButton.inputEnabled = false;
    OMT.social.sendLevelHighscoreBeatMsg(
      this.playerConfig,
      this.user1Config,
      this.lvlIndex,
      (success) => {
        if (success) this.closeWindow();
        else {
          this._challengeButton.inputEnabled = true;
        }
      },
    );
  }
}
// create global references
if (!window.Windows) window.Windows = {};
Windows.friendBeaten = Window_FriendBeaten;
