import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import OMT_LossAversionPrizeWheel from '../SpinningWheels/OMT_LossAversionPrizeWheel';
import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';

/**
 * wheel UI group for loss aversio n / out of moves wheel
 */
export default class LossAversionWheelGroup extends Phaser.Group {
  /**
   * constructor
   * @param {Object} config
   */
  constructor(config) {
    super(game);

    this.config = config;
    this.state = game.state.getCurrentState();

    this._createTitleText();
    this._createPrizeWheel();

    this._spinBtn = this._addSpinBtn(0, 255);

    this.dontApplyPrize = config.dontApplyPrize;
    this.coinTargetObject = config.coinTargetObject;
    this.onPrizeWon = new Phaser.Signal();
    this.onBoosterWon = new Phaser.Signal();
    this.onCoinsWon = new Phaser.Signal();
  }

  /**
   * create the title text
   */
  _createTitleText() {
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const { isDailyChallengeLevel } = LvlDataManager.getInstance();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;
    let targetStyle = G.OMTsettings.elements.LossAversionWheelGroup.titleStyle;
    if (isNotNormalLevel && G.OMTsettings.elements.LossAversionWheelGroup.useVillainTitles) {
      targetStyle = {
        style: useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_5') : OMT_VILLAINS.getPrefixedName('hard_5'),
        fontSize: G.OMTsettings.elements.LossAversionWheelGroup.titleStyle.fontSize,
      };
    }
    this.titleTxt = new G.Text(0, -280, OMT.language.getText('Spin Wheel'), targetStyle, 0.5, 440);
    this.add(this.titleTxt);
  }

  /**
   * create the OMT_PrizeWheel instance
   */
  _createPrizeWheel() {
    const prizeWheel = new OMT_LossAversionPrizeWheel({
      isPremiumMode: !G.saveState.isFreeMoneySpinAvailable(),
      ...OMT_Utils.stringToReference('wheelConfigs.lossAversionPrizeWheel', G.OMTsettings.elements),
    });
    prizeWheel.y = 5;
    this.addChild(prizeWheel);
    this._activeWheel = prizeWheel;
  }

  /**
   * true if the wheel is spinning
   * @returns {boolean}
   */
  isDuringSpin() {
    if (!this._activeWheel) return false;
    return this._activeWheel.isSpinning;
  }

  /**
   * add the spin button to the display
   * @param {number} x
   * @param {number} y
   */
  _addSpinBtn(x, y) {
    const btn = new OMT_UI_SquareButton(x, y, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Spin'),
        textStyle: { style: 'font-white', fontSize: 50 },
      },
      options: {
        clickFunction: {
          onClick: this._onSpinBtnClicked.bind(this),
        },
      },
    });
    this.add(btn);
    return btn;
  }

  /**
   * on spin button clicked
   */
  _onSpinBtnClicked() {
    if (this.isDuringSpin()) return;
    const freeSpin = G.saveState.isFreeMoneySpinAvailable();

    if (freeSpin) {
      if (this.config.failFlow) {
        OMT.platformTracking.logFTUEvent('FTUX_FirstFFWheelSpin', null);
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.WatchAdToSpinFailFlowWheel);
      }
      G.saveState.markLastMoneySpin();
      G.sb('onFreeMoneySpinUsed').dispatch();
    }

    this._spinBtn.visible = false;

    // start spinning. then spin to prize.
    // this._activeWheel.setSpinningState(true);
    this._activeWheel.spinToNextPrize((item) => {
      this._onWheelFinish(item);
    });

    if (OMT.feature.lossAversionWheelIsLimited()) {
      // Handle cooldown
      if (!G.saveState.isLossAversionWheelOnCooldown()) {
        G.saveState.setLossAversionWheelOnCooldown();
      }
      G.saveState.incrementLossAversionWheelSpinCount(1);
      G.sb('onLossAversionWheelSpin').dispatch();
    }
  }

  /**
   * on wheel spin complete
   * @param {PrizeWheel_Item} item
   */
  _onWheelFinish(item) {
    const { reward } = item.data;
    const formattedPrize = [reward.prize, reward.amount];
    this.onPrizeWon.dispatch(formattedPrize);

    this._spinBtn.visible = true;
    this._spinBtn.scale.setTo(0);

    if (!this.dontApplyPrize) {
      this._applyPrize(item, formattedPrize, () => {
        game.add.tween(this._spinBtn.scale)
          .to({ x: 1, y: 1 }, 300, Phaser.Easing.Sinusoidal.In, true);
      }, this);
    }
  }

  /**
   * apply / claim prize
   * @param {PrizeWheel_Item} item
   * @param {Array} formattedPrize
   * @param {Function} callback
   * @param {any} context
   */
  _applyPrize(item, formattedPrize, callback, context) {
    // DDNA Tracking
    // const tGameArea = game.state.getCurrentState().key === 'Game' ? 'FAIL_FLOW' : 'MAP';
    // const extraMovesBoosters = G.gift.getExtraMoveBoosterIdList();
    // const prizeIsExtraMoves = extraMovesBoosters.indexOf(formattedPrize[0]) >= 0;

    // const tFailFlowExtraMoves = tGameArea === 'FAIL_FLOW' && prizeIsExtraMoves ? 1 : 0;
    // const tLevelExtraMoves = tFailFlowExtraMoves === 1;
    // DDNA.transactionHelper.trackRewards(formattedPrize, prizeIsExtraMoves ? formattedPrize : [], {
    //   transactionType: 'REWARD',
    //   tActionType: 'WHEEL_SPIN',
    //   tGameArea,
    //   tFailFlowExtraMoves,
    //   tLevelExtraMoves,
    // });

    // Transaction Tracking
    OMT.transactionTracking.logInventoryTransactionBegin();

    // Actual gift applying
    if (formattedPrize[0] === 'coin') {
      if (this.coinTargetObject && this.state.uiTargetParticles) {
        const offset = this.toGlobal(this._activeWheel);
        offset.y -= 80; // offset from center of wheel
        const batch = this.state.uiTargetParticles.createCoinBatch(
          game.world.bounds.x + this._activeWheel.x + offset.x,
          game.world.bounds.y + this._activeWheel.y + offset.y,
          this.coinTargetObject,
          formattedPrize[1],
        );
        OMT.transactionTracking.addInventoryChange('coins', 0, formattedPrize[1]);
        if (callback) batch.onFinish.add(callback, context);
      } else {
        G.saveState.changeCoins(formattedPrize[1]);
        if (callback) callback.call(context);
      }
      // if we have more than one extra moves option on the wheel, dont increase the chances
      if (!G.featureUnlock.multipleExtraMovesFailFlow) {
        this._activeWheel.modifyBoosterChance(20);
      }
      this.onCoinsWon.dispatch();
    } else {
      this.onBoosterWon.dispatch(formattedPrize[0]);

      if (formattedPrize[0].indexOf('!') === -1) { // booster is non-instant type
        const boosterNum = parseInt(formattedPrize[0].match(/\d+/)[0]);
        if (boosterNum) { // don't track +3 and +5 moves boosters
          OMT.transactionTracking.addInventoryChange('boostersReceived', boosterNum, 1);
          OMT.transactionTracking.addInventoryChange('boostersUsed', boosterNum, 1);
        }
      }
    }

    OMT.transactionTracking.logInventoryTransactionEnd();
  }
}
