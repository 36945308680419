import { TextTimer } from '../TextTimer';

G.SPDailyMissionContent = function() {
  Phaser.Group.call(this, game);
  this.state = game.state.getCurrentState();

  // imports 
  var Ribbon = G.WorldSidePanelRibbon;
  var CompleteAllPanel = G.SPDM_CompleteAllPanel;
  var CompletedSeparator = G.SPDM_CompletedSeparator;

  this.ribbon = new Ribbon(0, 15, {
    ribbon: "gold_wheel_ribbon_missions",
    text: OMT.language.getText("Daily Missions"),
  });
  this.ribbon.text.position.y = G.OMTsettings.elements.dailyMission.ribbonText.yPos;
  this.add(this.ribbon);

  this._completeAllPanel = new CompleteAllPanel(0, 200, this);
  this.add(this._completeAllPanel);

  this.activeGoals = [];

  this.completedSeparator = new CompletedSeparator(0, 450);
  this.add(this.completedSeparator);

  this.claimedGoals = [];

  this.widgetCollect = null;
  this.widgetNext = null;

  G.sb("dailyMissionMgr-mainRewardClaived").add(this.widgetCheck, this);
  G.sb("onWorldSidePanelOpen").add(function() {
    this.moveToProperPlacements(true);
  }, this);
  G.sb("worldSidePanelOnWindowHide").add(function() {
    this.moveToProperPlacements(true);
  }, this);

  this.init();
};

G.SPDailyMissionContent.prototype = Object.create(Phaser.Group.prototype);

G.SPDailyMissionContent.prototype.init = function() {
  G.dailyMissionsMgr.getGoals().forEach(function(goal) {
    var panel = this.add(new G.SPDM_MissionPanel(goal));
    if (goal.status === "claimed") {
      this.claimedGoals.push(panel);
    } else {
      this.activeGoals.push(panel);

      panel.onClaimed.add(this.moveToClaimed, this);
      panel.onCompleted.add(this.widgetCheck, this);
    }
  }, this);

  this.moveToProperPlacements(true);
};

G.SPDailyMissionContent.prototype.getContentHeight = function() {
  return (this.activeGoals.length + this.claimedGoals.length + 2) * 200;
};

G.SPDailyMissionContent.prototype.moveToClaimed = function(panel) {
  console.log("!! moveToClaimed");

  this.activeGoals.splice(this.activeGoals.indexOf(panel), 1);
  this.claimedGoals.unshift(panel);
  // panel.doScaleOnMove = true;
  panel.immediateY = true;

  game.add.tween(panel).to({x: -500}, 300, Phaser.Easing.Sinusoidal.Out, 1500, true)
  .onComplete.add(function() {
    game.add.tween(panel).to({x: 0}, 300, Phaser.Easing.Sinusoidal.Out, true, 500)
    .onComplete.add(function() {
      panel.immediateY = false;
    }, this);
    this.moveToProperPlacements();
  }, this);

  // this.moveToProperPlacements();

  var partTarget = this._completeAllPanel.getEmptyCupcake();
  partTarget.aimed = true;
  var part = this.state.uiTargetParticles.init(
    game.world.bounds.x + panel.cupcake.worldPosition.x,
    panel.cupcake.worldPosition.y,
    G.dailyMissionsMgr.getCollectionIcons().icon, partTarget);
  part.onStart.add(function() {
    game.add.tween(part.scale)
      .from({x: 1.8, y: 1.8}, 500, Phaser.Easing.Sinusoidal.Out, true);
  });
  part.onFinish.add(function(){
    this._completeAllPanel.fillCupcake(partTarget);
    game.sfx.line.play();
  }, this);
  part.start();
};

G.SPDailyMissionContent.prototype.widgetCheck = function() {
  var activeGoal = this.activeGoals.find(function(goal){
    return goal.mission.status === "active";
  });

  if (activeGoal) return;

  var mainRewardClaimed = G.dailyMissionsMgr.getProgress().mainRewardClaimed;

  if (mainRewardClaimed) {
    // show NEXT widget
    if (this.widgetCollect) {
      G.sb("removeSidePanelButtonWidget").dispatch(this.widgetCollect);
      this.widgetCollect = null;
    }
    if (!this.widgetNext) {
      this.widgetNext = this.addWidgetNext();
      G.sb("addSidePanelButtonWidget").dispatch(this.widgetNext);
    }
  } else {
    // show COLLECT widget
    if (!this.widgetCollect) {
      this.widgetCollect = this.addWidgetCollect();
      G.sb("addSidePanelButtonWidget").dispatch(this.widgetCollect);
    }
  }
};

G.SPDailyMissionContent.prototype.addWidgetNext = function() {
  var group = game.make.group();
  group.bannerConfig = {
    bgSprite: "missions_map_banner_yellow_small",
    text: OMT.language.getText("New Daily"),
  };
  group.getBannerConfig = function() {
    return this.bannerConfig;
  };

  group.img = createClock(group, 0, 5);
  group.img.scale.setTo(0.7);  

  group.timer = group.add(new TextTimer({
    x: 0,
    y: 40,
    date: 0,
    style: {
      style: 'font-blue',
      fontSize: 25,
    },
    anchor: 0.5,
    maxWidth: 110,
  }));
  group.timer.start(G.dailyMissionsMgr.getSecToNextDaily());

  return group;

  function createClock(parent, x, y) {

    var group = game.make.group(parent);

    var base = createBase(group);
    var pointer1 = createPointer(base, "pointer1");
    var pointer2 = createPointer(base, "pointer2");

    group.centerX = x;
    group.centerY = y;

    animatePointers(base);
    
    return group;

    function createBase(parent) {
      var anchorX = 31 / 60;
      var anchorY = 29 / 63;
      return G.makeImage(0, 0, "Clock_base", [anchorX, anchorY], parent);
    }

    function createPointer(parent, name) {
      var anchorX = 3 / 7;
      var anchorY = 19 / 22;
      var img = G.makeImage(0, 0, "Clock_pointer", [anchorX, anchorY], parent);
      img.name = name;
      return img;
    }

    function animatePointers(base) {
      var animation = G.Animations.getClockPointersAnimation();
      animation.animate(game, base);
    }
  }
};

G.SPDailyMissionContent.prototype.addWidgetCollect = function() {
  var group = game.make.group();
  group.bannerConfig = {
    bgSprite: "missions_map_banner_yellow_small",
    text: OMT.language.getText("Daily"),
  };
  group.getBannerConfig = function() {
    return this.bannerConfig;
  };

  group.txt = group.add(new G.Text(0, 14, OMT.language.getText("Claim Reward"), {
    style: "font-blue",
    fontSize: 30,
    lineSpacing: -15,
  }, 0.5, 110, 200, true, "center"));

  return group;
};

G.SPDailyMissionContent.prototype.moveToProperPlacements = function(immediate) {
  var initMargin = 370;
  var panelHeight = 160;

  this.activeGoals.forEach(function(panel, i) {
    panel.targetY = initMargin + (i * panelHeight);
  });

  this.completedSeparator.targetY = initMargin + ((this.activeGoals.length-1) * panelHeight)+panelHeight;
  this.completedSeparator.visible = this.claimedGoals.length > 0;

  this.claimedGoals.forEach(function(panel, i) {
    panel.targetY = this.completedSeparator.targetY + (panelHeight*0.7) + (i * panelHeight * 0.8);
  }, this);

  var allElements = this.activeGoals.concat([this.completedSeparator], this.claimedGoals);

  allElements.forEach(function(e) {
    if (e.tweenY) e.tweenY.stop();
    if (immediate || e.immediateY) {
      e.y = e.targetY;
    } else {
      if (e.y === e.targetY) return;

      this.bringToTop(e);
      e.tweenY = game.add.tween(e)
        .to({y: e.targetY}, 1000, Phaser.Easing.Sinusoidal.InOut, true);
      if (e.doScaleOnMove) {
        e.doScaleOnMove = false;
        game.add.tween(e.scale)
          .to({x: 1.2, y: 1.2}, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
      }
    }
  }, this);

  this.widgetCheck();
};