import { Window_FillRateFallback } from '../Windows/Window_FillRateFallback';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

export default class SPDM_MissionPanelBtn extends OMT_UI_SquareButton {
  constructor(x, y, mission, config) {
    super(x, y, config);

    this.mission = mission;
    this.refresh();
  }

  _drawButton() {
    this.adIcon = G.makeImage(0, 0, 'btn-movie-icon', 0.5, null);
    this.config.text.icon = {
      image: this.adIcon,
      align: 'left',
      offset: {
        x: this.adIcon.width / 4,
        y: 0,
      },
    };
    super._drawButton();
    this.adIcon.visible = false;
  }

  refresh() {
    this.signals.onClick.removeAll();
    this.visible = true;

    switch (this.mission.status) {
      case 'active':
        this.setAsActive();
        break;
      case 'completed':
        this.setAsClaim();
        break;
      case 'claimed':
        this.inputEnabled = false;
        this.visible = false;
        break;
      default: break;
    }
  }

  setAsClaim() {
    this.setColour(BUTTONCOLOURS.orange);
    this.adIcon.visible = false;
    this.scale.setTo(1);
    this.buttonText.anchor.x = 0.5;
    this.config.text.offset.x = 0;
    this.setText(OMT.language.getText('Claim'));

    this.scale.setTo(0.96, 1.04);
    game.add.tween(this.scale)
      .to({ x: 1.04, y: 0.96 }, 800, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

    this.signals.onClick.add(() => {
      this.mission.claim();
      this.inputEnabled = false;
      G.stopTweens(this);
      const tw = game.add.tween(this.scale)
        .to({ x: 0, y: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true);
      tw.onComplete.add(() => {
        this.visible = false;
      });
    });
  }

  setAsActive() {
    const specialAction = this.mission.getSpecialAction();
    if (specialAction) {
      this.setAsSpecial(specialAction);
    } else if (game.incentivised()) {
      this.setAsAdSkip();
    } else {
      this.visible = false;
    }
  }

  setAsAdSkip() {
    this.adIcon.visible = true;

    const increaser = this.mission.missionData.adIncreaser || Math.min(this.mission.target, 10);
    this.buttonText.anchor.x = 0;
    this.config.text.offset.x = -this.adIcon.width / 4;
    this.setText(`+${increaser}`);
    this.setColour(BUTTONCOLOURS.purple);

    this.signals.onClick.add(() => {
      OMT.ads.showAd(
        G.BuildEnvironment.adPlacements.skipMission,
        () => {
          this.onAdSuccess(increaser);
        },
        () => {
          if (!G.IAP) { // no IAPS dont use ad fallbacks
            // eslint-disable-next-line no-new
            new Window_FillRateFallback(undefined, {
              placement: G.BuildEnvironment.adPlacements.skipMission,
              callback: () => {
                this.onAdSuccess(increaser);
              },
              context: this,
            });
          } else { // ad fallback
            OMT.ads.showAdFallback(this.onAdSuccess.bind(this, increaser));
          }
        },
      );
    }, this);
  }

  setAsSpecial() {
    const btnSettings = this.mission.getButtonSettings();
    this.setColour(BUTTONCOLOURS[btnSettings.sprite]);
    this.adIcon.visible = false;
    if (btnSettings.dimensions) {
      this.resize(btnSettings.dimensions.x, btnSettings.dimensions.y);
    }
    this.setText(OMT.language.getText(btnSettings.label));

    this.signals.onClick.add(() => {
      this.mission.getSpecialAction().call(this);
    }, this);
  }

  onAdSuccess(increaser) {
    this.mission.increaseCurrent(increaser);
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.AdToProgressMiniMissionCompleted);
  }
}
