if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getBoxSmallJumpAnimation = function getBoxSmallJumpAnimation() {
  return { animate: animate };

  function animate(game, target) {
    var moveTweenA = game.add.tween(target).to({y: target.y-15},150,Phaser.Easing.Cubic.Out);
    var moveTweenB = game.add.tween(target).to({y: target.y},150,Phaser.Easing.Circular.In);
    moveTweenA.chain(moveTweenB);
    moveTweenA.start();

    var tweenAngleA = game.add.tween(target).to({angle: -3},100,Phaser.Easing.Cubic.InOut);
    var tweenAngleB = game.add.tween(target).to({angle: 3},170,Phaser.Easing.Sinusoidal.In);
    var tweenAngleC = game.add.tween(target).to({angle: 0},30,Phaser.Easing.Cubic.InOut);

    tweenAngleA.chain(tweenAngleB,tweenAngleC);
    tweenAngleA.start();
  };
}
