/* eslint-disable no-await-in-loop */
import OMT_UI_VerticalScrollBarConfig from '../Scroll/ScrollBarConfig/OMT_UI_VerticalScrollBarConfig';
import { OMT_UI_FriendsListItem } from './OMT_UI_FriendsListItem';
import OMT_UI_RoundedScrollArea from '../Scroll/OMT_UI_RoundedScrollArea';
import { EXPANDED_EVEN_ROW_COLOR, EXPANDED_ODD_ROW_COLOR } from '../../Leaderboards/MainLeaderboardSettings';

const FADE_IN_TRANSITION_DELAY = 100;
const FADE_IN_TRANSITION_DURATION = 200;

/*
  generic friends list view
*/
export class OMT_UI_FriendsListView extends Phaser.Group {
  /**
   * constructor
   */
  constructor() {
    super(game);

    this._enabled = true;
    this.signals = {}; // add signals to this
  }

  /**
   * main init function. call manually or from extending Class
   * @param {number} itemHeight
   * @param {Phaser.Rectangle} scrollRect
   * @param {number} oddRowBgColor
   * @param {number} eventRowBgColor
   * @param {OMT_UI_VerticalScrollBarConfig} scrollBarConfig (optional)
   * @param {number} initDelay (optional) delay init of multiple items
   * @returns {Promise}
   */
  async init(itemHeight, scrollRect, oddRowBgColor, evenRowBgColor, scrollBarConfig = null, initDelay = 0) {
    this._itemHeight = itemHeight;
    this._scrollRect = scrollRect;
    this.x = scrollRect.x;
    this.y = scrollRect.y;

    this._initScrollArea(scrollBarConfig);
    await this._initItems(itemHeight, oddRowBgColor, evenRowBgColor, initDelay);
  }

  /**
   * set a optional user list to skip loading
   * @returns {Promise<Array>}
   */
  setUserList(userList) {
    this._userList = userList;
  }

  /**
   * get the list of users to display
   * @returns {Promise<Array>}
   */
  async _getUserList() {
    const userList = await OMT.friends.getFriendsList();
    return userList;
  }

  /**
   * init the scroll area
   * @param {OMT_UI_VerticalScrollBarConfig} scrollBarConfig (optional)
   */
  _initScrollArea(scrollBarConfig = null) {
    const scrollRect = this._scrollRect.clone();
    scrollRect.x = 0; scrollRect.y = 0;
    if (!scrollBarConfig) scrollBarConfig = new OMT_UI_VerticalScrollBarConfig(12, 5, 1, 1, null, null, 'shop2', 'scrollbar_slide', true, null, false);
    this._scrollArea = new OMT_UI_RoundedScrollArea(scrollRect, 15, true, scrollBarConfig);
    this._scrollArea.signals.onScrolled.add(this._updateListItemsVisibility, this);
    this.addChild(this._scrollArea);
  }

  /**
   * get a reference to the scrollArea object
   * @returns {OMT_UI_ScrollArea}
   */
  get scrollArea() {
    return this._scrollArea;
  }

  /**
   * init list view items
   * @param {number} itemHeight
   * @param {number} oddRowBgColor
   * @param {number} eventRowBgColor
   * @param {number} initDelay (optional) delay init of multiple items
   */
  async _initItems(itemHeight, oddRowBgColor, evenRowBgColor, initDelay = 0) {
    const itemWidth = this._scrollRect.width;
    this._listItems = [];
    const userList = this._userList || await this._getUserList();

    // hide scroll area during intialization for throttling rendering
    const scrollAreaVisibile = this._scrollArea.visible;
    if (scrollAreaVisibile) this._scrollArea.visible = false;

    for (let i = 0; i < userList.length; i++) {
      // delay initialization for rendering throttling
      if (initDelay > 0) await new Promise((resolve) => { setTimeout(() => { resolve(); }, initDelay); });
      // continue item initialization
      const friendData = userList[i];
      const bgColor = i % 2 === 0 ? oddRowBgColor : evenRowBgColor;
      const listItem = this._createFriendListItem(friendData, itemWidth, itemHeight, bgColor);
      listItem.y = i * itemHeight;
      this._scrollArea.content.addChild(listItem);
      this._listItems.push(listItem);
    }
    if (scrollAreaVisibile) this._scrollArea.visible = true;
  }

  /**
   * create friendList item
   * @param {Object} friendData
   * @param {number} itemWidth
   * @param {number} itemHeight
   * @param {number} bgColor
   */
  _createFriendListItem(friendData, itemWidth, itemHeight, bgColor) {
    const listItem = new OMT_UI_FriendsListItem(friendData, itemWidth, itemHeight, bgColor);
    return listItem;
  }

  /**
   * check if item is in the visible scroll area
   * @param {number} scrolledY
   * @param {OMT_UI_FriendsListItem} listItem
   */
  _isItemVisibleArea(scrolledY, listItem) {
    const bottomEdge = this._scrollRect.height;
    const topEdge = 0;
    const topY = listItem.y + scrolledY;
    const bottomY = topY + listItem.height;
    return topY <= bottomEdge && bottomY > topEdge;
  }

  /**
   * update visibility of the items based on the scroll rect
   */
  _updateListItemsVisibility() {
    const scrolledY = this._scrollArea.content.y;
    let itemVisible;
    for (const listItem of this._listItems) {
      if (!listItem.isLineFadeInActive) {
        itemVisible = this._isItemVisibleArea(scrolledY, listItem);
        if (itemVisible && listItem.alpha === 0) listItem.alpha = 1;
        else if (!itemVisible && listItem.alpha === 1) listItem.alpha = 0;
      }
    }
  }

  /**
   * set the each lines alpha and stop any tween
   * @param {number} alpha 0-1
   */
  setContentAlpha(alpha) {
    // set scrollbar alpoha if applicable
    if (this._scrollArea.scrollBar) {
      if (this._scrollBarTween) this._scrollBarTween.stop();
      this._scrollArea.scrollBar.alpha = alpha;
    }
    // set line items alpha
    let listItem;
    for (let i = 0; i < this._listItems.length; i++) {
      listItem = this._listItems[i];
      listItem.setContentAlpha(alpha);
    }
  }

  /**
   * set the line by line fade transition effect
   * @param {Function} callback (optional)
   */
  setContentFadeInTransition(callback = null) {
    // animate scrollbar if applicable
    if (this._scrollArea.scrollBar) {
      if (this._scrollBarTween) this._scrollBarTween.stop();
      this._scrollBarTween = game.add.tween(this._scrollArea.scrollBar);
      this._scrollBarTween.to({ alpha: 1 }, FADE_IN_TRANSITION_DURATION, Phaser.Easing.Quadratic.Out, false);
      this._scrollBarTween.start();
    }
    // animate line items
    const scrolledY = this._scrollArea.content.y;
    let listItem; let itemVisible; let itemTween;
    for (let i = 0; i < this._listItems.length; i++) {
      listItem = this._listItems[i];
      itemVisible = this._isItemVisibleArea(scrolledY, listItem);
      if (itemVisible) itemTween = listItem.setLineFadeInTransition(i * FADE_IN_TRANSITION_DELAY);
    }
    // add callback if on final item animation start
    if (itemTween != null && callback != null) itemTween.onStart.addOnce(callback);
  }

  /**
   * Returns an array containing the alternating list color couple
   */
  getAlternatingRowColors() {
    if (OMT.feature.isTokenEventOn()) {
      const { EXPANDED_ODD_ROW_COLOR: E_O_R_C, EXPANDED_EVEN_ROW_COLOR: E_E_R_C } = G.OMTsettings.tokenEvent.mainLeaderboard;
      return [E_O_R_C, E_E_R_C];
    }
    return [EXPANDED_ODD_ROW_COLOR, EXPANDED_EVEN_ROW_COLOR];
  }

  /**
   * enable interactivity
   */
  enable() {
    this._enabled = true;
    this._scrollArea.enable();
  }

  /**
   * disable interactivity
   */
  disable() {
    this._enabled = false;
    this._scrollArea.disable();
  }

  /**
   * destruction method
   */
  destroy() {
    if (this._scrollBarTween) this._scrollBarTween.stop();
    Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    super.destroy();
  }
}
