/**
 * Enumerations related to the real money wheel
 */

// Conversion status
export const RMWHEEL_MODES = {
  Conversion: 'conversion',
  HighValue: 'highValue',
  Platinum: 'platinum',
  Unknown: 'unknown',
};

// Entry points: what triggered or how the wheel was triggered to appear
export const RMWHEEL_EPS = {
  Replacement: 'REPLACEMENT',
  Helper: 'HELPER',
  TargetedOffer: 'TARGETED-OFFER',
  EPPayload: 'EP-PAYLOAD',
  Unknown: 'UNKNOWN',
};

// Events for tracking player interaction with real money wheel
export const RMWHEEL_EVENTS = {
  Eligible: 'ELIGIBLE',
  Seen: 'SEEN',
  Opened: 'OPENED',
  Purchased: 'PURCHASED',
  Claimed: 'CLAIMED',
  Unknown: 'UNKNOWN',
};

export const RMWHEEL_PRIZETABLES = {
  [RMWHEEL_EPS.Replacement]: {
    [RMWHEEL_MODES.Conversion]: 'prizeWheelConversion',
    [RMWHEEL_MODES.HighValue]: 'prizeWheelHighValue',
    [RMWHEEL_MODES.Platinum]: null,
  },

  [RMWHEEL_EPS.Helper]: {
    [RMWHEEL_MODES.Conversion]: 'helperWheelConversion',
    [RMWHEEL_MODES.HighValue]: 'helperWheelHighValue',
    [RMWHEEL_MODES.Platinum]: null,
  },

  [RMWHEEL_EPS.TargetedOffer]: {
    [RMWHEEL_MODES.Conversion]: 'targetedOfferWheelConversion',
    [RMWHEEL_MODES.HighValue]: 'targetedOfferWheelHighValue',
    [RMWHEEL_MODES.Platinum]: 'targetedOfferWheelPlatinum',
  },

  [RMWHEEL_EPS.EPPayload]: {
    [RMWHEEL_MODES.Conversion]: 'payloadWheelConversion',
    [RMWHEEL_MODES.HighValue]: 'payloadWheelHighValue',
    [RMWHEEL_MODES.Platinum]: 'payloadWheelPlatinum',
  },
};

// Wheel enum for DDNA parameter
// If a use case does not apply, set the param name to null
export const RMWHEEL_DDNAPARAMS = {
  [RMWHEEL_EPS.Replacement]: {
    [RMWHEEL_EVENTS.Eligible]: {
      [RMWHEEL_MODES.Conversion]: 'tEligibleReplacementMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'tEligibleReplacementMoneyWheel',
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Seen]: {
      [RMWHEEL_MODES.Conversion]: 'tSeenReplacementConversionMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'tSeenReplacementHighValueWheel',
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Opened]: {
      [RMWHEEL_MODES.Conversion]: null,
      [RMWHEEL_MODES.HighValue]: null,
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Purchased]: {
      [RMWHEEL_MODES.Conversion]: 'tPurchasedReplacementConversionMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'tPurchasedReplacementHighValueWheel',
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Claimed]: {
      [RMWHEEL_MODES.Conversion]: null,
      [RMWHEEL_MODES.HighValue]: null,
      [RMWHEEL_MODES.Platinum]: null,
    },
  },

  [RMWHEEL_EPS.Helper]: {
    [RMWHEEL_EVENTS.Eligible]: {
      [RMWHEEL_MODES.Conversion]: 'mEligibleHelperMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'mEligibleHelperMoneyWheel',
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Seen]: {
      [RMWHEEL_MODES.Conversion]: 'mSeenHelperConversionMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'mSeenHelperHighValueWheel',
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Opened]: {
      [RMWHEEL_MODES.Conversion]: 'mOpenedHelperConversionMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'mOpenedHelperHighValueWheel',
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Purchased]: {
      [RMWHEEL_MODES.Conversion]: 'mPurchasedHelperConversionMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'mPurchasedHelperHighValueWheel',
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Claimed]: {
      [RMWHEEL_MODES.Conversion]: null,
      [RMWHEEL_MODES.HighValue]: null,
      [RMWHEEL_MODES.Platinum]: null,
    },
  },

  [RMWHEEL_EPS.TargetedOffer]: {
    [RMWHEEL_EVENTS.Eligible]: {
      [RMWHEEL_MODES.Conversion]: null,
      [RMWHEEL_MODES.HighValue]: null,
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Seen]: {
      [RMWHEEL_MODES.Conversion]: 'tSeenTargetedOfferConversionMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'tSeenTargetedOfferHighValueWheel',
      [RMWHEEL_MODES.Platinum]: 'tSeenTargetedOfferPlatinumWheel',
    },
    [RMWHEEL_EVENTS.Opened]: {
      [RMWHEEL_MODES.Conversion]: null,
      [RMWHEEL_MODES.HighValue]: null,
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Purchased]: {
      [RMWHEEL_MODES.Conversion]: 'tPurchasedTargetedOfferConversionMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'tPurchasedTargetedOfferHighValueWheel',
      [RMWHEEL_MODES.Platinum]: 'tPurchasedTargetedOfferPlatinumWheel',
    },
    [RMWHEEL_EVENTS.Claimed]: {
      [RMWHEEL_MODES.Conversion]: null,
      [RMWHEEL_MODES.HighValue]: null,
      [RMWHEEL_MODES.Platinum]: null,
    },
  },

  [RMWHEEL_EPS.EPPayload]: {
    [RMWHEEL_EVENTS.Eligible]: {
      [RMWHEEL_MODES.Conversion]: null,
      [RMWHEEL_MODES.HighValue]: null,
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Seen]: {
      [RMWHEEL_MODES.Conversion]: null,
      [RMWHEEL_MODES.HighValue]: null,
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Opened]: {
      [RMWHEEL_MODES.Conversion]: null,
      [RMWHEEL_MODES.HighValue]: null,
      [RMWHEEL_MODES.Platinum]: null,
    },
    [RMWHEEL_EVENTS.Purchased]: {
      [RMWHEEL_MODES.Conversion]: 'tPurchasedPayloadConversionMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'tPurchasedPayloadHighValueMoneyWheel',
      [RMWHEEL_MODES.Platinum]: 'tPurchasedPayloadPlatinumMoneyWheel',
    },
    [RMWHEEL_EVENTS.Claimed]: {
      [RMWHEEL_MODES.Conversion]: 'tClaimedPayloadConversionMoneyWheel',
      [RMWHEEL_MODES.HighValue]: 'tClaimedPayloadHighValueMoneyWheel',
      [RMWHEEL_MODES.Platinum]: 'tClaimedPayloadPlatinumMoneyWheel',
    },
  },
};

// Wheel enum for player characterization metrics
export const RMWHEEL_PCMETRICS = {
  [RMWHEEL_EPS.Replacement]: {
    [RMWHEEL_MODES.Conversion]: 'purchasedReplacementConversionMoneyWheel',
    [RMWHEEL_MODES.HighValue]: 'purchasedReplacementHighValueWheel',
    [RMWHEEL_MODES.Platinum]: null,
  },

  [RMWHEEL_EPS.Helper]: {
    [RMWHEEL_MODES.Conversion]: 'purchasedHelperConversionMoneyWheel',
    [RMWHEEL_MODES.HighValue]: 'purchasedHelperHighValueWheel',
    [RMWHEEL_MODES.Platinum]: null,
  },

  [RMWHEEL_EPS.TargetedOffer]: {
    [RMWHEEL_MODES.Conversion]: 'purchasedTargetedOfferConversionMoneyWheel',
    [RMWHEEL_MODES.HighValue]: 'purchasedTargetedOfferHighValueWheel',
    [RMWHEEL_MODES.Platinum]: 'purchasedTargetedOfferPlatinumWheel',
  },

  [RMWHEEL_EPS.EPPayload]: {
    [RMWHEEL_MODES.Conversion]: 'purchasedPayloadConversionMoneyWheel',
    [RMWHEEL_MODES.HighValue]: 'purchasedPayloadHighValueWheel',
    [RMWHEEL_MODES.Platinum]: 'purchasedPayloadPlatinumWheel',
  },
};

// Sort order for pending spins
export const RMWHEEL_PENDINGORDER = {
  free: 0,
  'iap-uc': 1,
  iap: 2,
};
