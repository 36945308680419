import ShareMsgBase from './A_G.ShareMsgBase';

class MsgRequestHelp extends ShareMsgBase {
  /**
   * The message that is sent when you request help through the Request Help feature or
   * Request Help bubble 2.0
   * @param {string} user1
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    this.avatar1 = this._addExtImage(83, 117, user1, 0, 132, null);
    this.bg = this._addExtImage(0, 0, 'img/Msg_Request_Help.png', 0, null, null);

    this.thanksforyourhelp = this.add(new G.Text(346, 50,
      OMT.language.getText(G.json.shareMessageText.sendReqHelpMsg[0]), {
        style: 'msg-white',
        fontSize: '40px',
      }, 0.5, 490));

    this._objectLoadComplete();
  }
}

G.MsgRequestHelp = MsgRequestHelp;
