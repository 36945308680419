import UI_ShopIcon from './UI_ShopIcon';

export default class UI_AnnuityReminderIcon extends UI_ShopIcon {
  /**
   * A button that brings the user to the coin shop (to redeem their annuity)
   */
  constructor(removeFunc) {
    super({ icon: 'annuityReminder' });

    this._signalTokens.push(G.sb('annuityClear').add(() => {
      if (removeFunc) {
        removeFunc();
        this.destroy();
      }
    }));
  }

  /**
   * Draws asset
   * @param {string} asset
   */
  _drawAsset(asset) {
    this._container = new G.Button(0, 0, null, this.openCoinShop, this);
    this._innerContainer = new Phaser.Group(game, this._container);
    this._image = G.makeImage(0, 0, asset, 0.5, this._innerContainer);
    const notification = new G.NotificationBubble(0, 0);
    notification.setImportant(true);
    this._innerContainer.addChild(notification);
    notification.x = this._image.width / 3;
    notification.y = -this._image.height / 3;
  }
}
