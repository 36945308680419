import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS, BUTTONSTATE } from '../../OMT_UI/OMT_UI_SquareButton';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import THProgressBar from './THProgressBar';
// import { ERROR_CODES } from '../../Services/OMT/OMT_TierLeaderboard';
import TreasureHuntManager from '../../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';

// Window for winning a special event level
export class Window_TreasureHuntWin extends Window {
  /**
   * Screen for when the treasure hunt is won
   * @param {Object} parent
   * @param {Object} config
   */
  constructor(parent, config) {
    super(parent);
    this.onFinishedEnter.addOnce(() => {
      this._ready.entering = true;
      this._attemptToPlayTokenAnimation();
    });
    this.state = game.state.getCurrentState();
    this._manager = G.saveState.treasureHuntManager;

    this._ready = {
      backEnd: false,
      entering: false,
    };

    // imports
    const { lvlIndex } = config;
    const { points } = config;

    // config passed when level started
    this.passedConfig = config.passedConfig;

    // if we forced a life loss at the beginning of the level, we add that back here.
    if (this.state.removedLifeAtStart) {
      G.saveState.addLife(1, false, true);
    }

    // send automated message on close if in friend context
    OMT.social.sendClosedHelpRequest(() => {});

    this._manageData(lvlIndex, points);
  }

  /**
   * Manages data.
   * Greatly requires the save call from the leaderboard to work.
   * If the leaderboard is closed when this call happens, all tokens are lost
   * @param {number} lvlIndex
   * @param {number} points
   */
  async _manageData(lvlIndex, points) {
    // Handle token collection
    // Save previous values for animation purposes later
    this._prevTokens = this._manager.currentTokens;
    this._increaseTokens = this._manager.tempTokens;
    this._isDoubling = this._manager.isDoubling; // Save state before it resets
    if (this._isDoubling) { // Doubled now to prevent issues when checking prize
      this._increaseTokens *= 2;
    }

    // const leaderboardTokenCount = await this._saveTokenCountForLeaderboard(this._prevTokens + this._increaseTokens);
    // if (leaderboardTokenCount === ERROR_CODES.SEND_INACTIVE) { // Leaderboard must be closed...
    //   this._increaseTokens = 0;
    //   this._manager.resetTempTokens();
    // }
    // In the case of disconnection, tokens are not reset, but saved. Will be sent later

    // if this level doesnt have result data set, set it here.
    if (!G.lvl.resultData) {
      G.lvl.resultData = G.saveState.passLevel({
        levelIndex: lvlIndex,
        newStars: Math.max(1, G.lvl.stars),
        newPoints: points,
        skipReward: true,
        dontSave: true,
        gameMode: LevelType.TREASURE_HUNT,
      });
    }
    // save the result
    this.result = G.lvl.resultData;

    // Save collected tokens now
    const possiblePrizes = this._manager.canGetAPrize(this._prevTokens, this._increaseTokens);
    if (possiblePrizes.length > 0) {
      for (const i of possiblePrizes) {
        this._manager.putPrizeIntoPending(i, false);
      }
      this._prizeToClaim = Boolean(this._manager.nextPrize);
    }
    this._manager.cashInTokens(false);
    this._manager.assignNewLevel(); // Save happens here
    G.saveState.save();

    this._initLayout();
    this._initButtons();

    this._ready.backEnd = true;
    this._attemptToPlayTokenAnimation();
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._progressBar) {
      this._progressBar.destroy();
      this._progressBar = null;
    }
    super.destroy();
  }

  /**
   * Checks if its ok to play animation
   * Redos the enter animation, but after removing all the signals
   * @returns {null}
   */
  _attemptToPlayTokenAnimation() {
    if (!(this._ready.backEnd && this._ready.entering) || this.animationIsPlaying) { return; }
    this.animationIsPlaying = true;
    this.onFinishedEnter.removeAll();
    this.onFinishedEnter.addOnce(this._playTokenAnimation.bind(this));
    this._setOpenWindowAnimation(); // Open again
  }

  /**
   * Plays an animation of getting tokens, if theres any
   * General flow is
   * - twoTimesTween starts (if there is) along with the text increase
   * - playTokenTween fires (if there is any)
   * - Progress bar updates as soon as first token flies in (if there isn't any, then it doesn't need to increase)
   */
  _playTokenAnimation() {
    // Make the tweens for tokens flying to the progress bar, if theres any
    const copiedTokens = [];
    const targetPos = { x: this._progressBar.x + this._progressBar.icon.x, y: this._progressBar.y + this._progressBar.icon.y };
    const maxTokens = Math.min(this._increaseTokens, 3);
    let lastTween;
    const delay = 1000 / (maxTokens + 1);
    for (let i = 0; i < maxTokens; i++) {
      const token = G.makeImage(this._tokenGroup.image.x + this._tokenGroup.icon.x, this._tokenGroup.image.y, 'treasureHunt_token', 0.5, this);

      const tw = lastTween = game.add.tween(token)
        .to({ x: targetPos.x, y: targetPos.y, alpha: [1, 1, 1, 1, 0] }, 500,
          Phaser.Easing.Sinusoidal.InOut, false, delay * i);
      tw.onComplete.add(() => {
        G.sfx.pop.play();
        if (token.parent) {
          token.parent.removeChild(token);
        }
        token.destroy();
      });
      copiedTokens.push(tw);
    }
    if (copiedTokens.length > 0) { // On the first token going in, increase the progress bar
      copiedTokens[0].onComplete.add(() => {
        this._progressBar.updateBar(this._manager.currentTokens);
      });
    }
    if (lastTween) {
      lastTween.onComplete.add(() => {
        this.continueBtn.currentState = BUTTONSTATE.ENABLED;
      });
    }

    // Function for the token tween, because it can be called in two places
    const playTokenTween = () => {
      for (const tw of copiedTokens) {
        tw.start();
      }
      if (!lastTween) {
        this.continueBtn.currentState = BUTTONSTATE.ENABLED;
      }
    };

    if (this._twoTimesGroup) { // If theres doubling, extra tweens are required
      // Tween the number on the text to increase
      const obj = { val: this._increaseTokens / 2 };
      const increaseTween = game.add.tween(obj)
        .to({ val: this._increaseTokens }, 500, Phaser.Easing.Sinusoidal.In, true, 1200);
      const setTextFunc = () => {
        this._tokenGroup.text.setText(Math.floor(obj.val));
      };
      increaseTween.onUpdateCallback(setTextFunc);
      increaseTween.onComplete.add(setTextFunc);

      // Move the x2 into the bar
      const twoTimesTween = game.add.tween(this._twoTimesGroup)
        .to({ x: 0, alpha: 0 }, 500, Phaser.Easing.Sinusoidal.In, true, 1200);
      twoTimesTween.onStart.add(() => {
        increaseTween.start();
      });
      twoTimesTween.onComplete.add(() => {
        this._twoTimesGroup.visible = false;
        playTokenTween();
      });
    } else {
      playTokenTween();
    }
  }

  /**
   * Initializes layout
   */
  _initLayout() {
    this.addBackground('popup_background_treasureHunt');

    this.banner = G.makeImage(
      0, -265,
      'treasureHunt_banner',
      0.5, this,
    );

    // Title
    this.titleTxt = new G.Text(0, -267, OMT.language.getText('Treasure Hunt Level'), 'treasureHunt-headerTitleText', 0.5, 330, 125, true, 'center');
    this.add(this.titleTxt);

    // You win
    this.youWinTxt = new G.Text(0, -130, OMT.language.getText('You win!'), {
      style: 'treasureHunt-levelWindow',
      fontSize: '45px',
    }, 0.5, 530);
    this.add(this.youWinTxt);

    // Progress Bar
    this._progressBar = new THProgressBar(this._prevTokens, true);
    this._progressBar.y = 200;
    this.add(this._progressBar);

    // Tokens Collected
    const pointsGroup = this._createIconAndNumber({ img: 'score_icon', scale: 1 }, this.result.points);
    pointsGroup.image.y = -40;
    const tokenGroup = this._tokenGroup = this._createIconAndNumber({ img: 'treasureHunt_token', scale: 1 }, this._increaseTokens, this._isDoubling);
    tokenGroup.image.y = 75;
  }

  /**
   * Creates an icon and anumber. Used to show points and tokens collected
   * @param {{img:string, scale:number}} iconDetails
   * @param {number} number
   * @param {boolean} isDoubling
   * @returns {{icon:Phaser.Image, image:Phaser.Group, text:G.Text}}
   */
  _createIconAndNumber(iconDetails, number, isDoubling) {
    const group = new Phaser.Group(game, this);
    const bg = G.makeImage(0, 0, 'treasureHunt_winNumberBg', 0.5, group);
    const img = G.makeImage(0, 0, iconDetails.img, 0.5, group);
    img.x = bg.x - (bg.width - img.width) / 2;
    const leftoverSpace = bg.width - img.width;
    const text = new G.Text(0, 0, number, 'treasureHunt-levelWindow', 0.5, leftoverSpace);
    text.x = bg.x + img.width / 2;
    group.addChild(text);

    if (isDoubling) { // If doubling is occuring, show the doubling
      text.setText(number / 2);

      const twoTimesGroup = this._twoTimesGroup = new Phaser.Group(game, group);
      const ttBg = G.makeImage(0, 0, 'treasureHunt_gameMultiplier', 0.5, twoTimesGroup);
      const ttText = new G.Text(0, 3, 'x2', {
        style: 'font-white',
        fontSize: ttBg.height * 0.8,
      }, 0.5, ttBg.width * 0.8);
      twoTimesGroup.addChild(ttText);

      twoTimesGroup.scale.set((bg.height * 0.8) / twoTimesGroup.height);
      twoTimesGroup.x = bg.x + bg.width / 2;
    }

    return {
      icon: img,
      image: group,
      text,
    };
  }

  /**
   * Initializes buttons
   */
  _initButtons() {
    this.continueBtn = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 254,
          height: 100,
        },
        isEnabled: false,
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: {
          style: 'font-white',
          fontSize: 70,
        },
        dimensionMods: {
          width: 0.9,
          height: 0.9,
        },
      },
      options: {
        clickFunction: {
          onClick: this._onContinueClick.bind(this),
          disableAfterClick: true,
        },
        cacheButton: false,
      },
    });
    this.continueBtn.y = this.bg.y + (this.bg.height - (this.continueBtn.height / 2)) / 2;

    this.registerButtons(this.continueBtn);
  }

  /**
   * When the continue button is clicked
   */
  _onContinueClick() {
    if (!G.saveState.treasureHuntManager.inActiveCycle) {
      this.handleClose();
    } else {
      this.closeWindow();
      this.state.endPointCheckForAds(() => {
        TreasureHuntManager.openTreasureHuntPopup(true);
      });
    }
  }

  /**
   * Saves the tokens onto the leaderboard
   */
  async _saveTokenCountForLeaderboard(tokens) {
    return this._manager.syncTokens(tokens);
  }

  /**
   * Close the window and return to the map scene with any rewards.
   */
  handleClose() {
    G.sb('onAllWindowsClosed').addOnce(() => {
      this.state.endPointCheckForAds(() => {
        G.sb('onStateChange').dispatch('World', undefined, this._checkTreasureGoals.bind(this));
      }, this);
    });
    this.closeWindow();
  }

  /**
   * Checks if theres treasure to claim
   */
  _checkTreasureGoals() {
    if (G.saveState.treasureHuntManager.nextPrize) {
      G.sb('pushWindow').dispatch('treasureHuntPrizeClaim');
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.treasureHuntWin = Window_TreasureHuntWin;
