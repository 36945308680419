/* eslint-disable no-unused-vars */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */

import { BUTTONCOLOURS, BUTTONSTATE } from '../../OMT_UI/OMT_UI_SquareButton';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import { TextTimer, TIMER_FORMAT } from '../TextTimer';
import Window_Level from '../Windows/Window_level';
import THProgressBar from './THProgressBar';
import TreasureHuntInfoWindow from './TreasureHuntInfoWindow';
import { OMT_AssetLoader } from '../../Services/OMT/OMT_AssetLoader';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

/**
 * Class for pre-level window for special event levels
 * Forked from Window_level with a sprinkle of Window_dailyChallenge
 */
export default class Window_TreasureHuntLevel extends Window_Level {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} retryConfig (optional) config passed for retrying level
   */
  constructor(parent, retryConfig) {
    super(parent, retryConfig);

    this.onFinishedEnter.addOnce(() => {
      this._windowIsInView = true;
    });
    // Move window up
    // if (game.state.current === 'Game') this.y -= 75;

    this._onResize();
    if (this._onResizeSB && this._onResizeSB.detach) this._onResizeSB.detach(); // Detach window_level resize token
    this._onResizeSB = G.sb('onScreenResize').add(this._onResize, this);
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._progressBar) {
      this._progressBar.destroy();
      this._progressBar = null;
    }
    if (this._helpInfo) {
      this._helpInfo.destroy();
      this._helpInfo = null;
    }
    super.destroy();
  }

  /**
   * Override the force fit on screen to use the level container instead of this
   */
  forceFitOnScreen() {
    const bounds = this._levelContainer.getBounds(game.stage);
    if (this._levelContainer.y < 0) {
      const newHeight = this._levelContainer.height + (this._levelContainer.y * 2);
      // enforce max scale down of 50%
      if (newHeight >= this._levelContainer.height / 2) {
        this._levelContainer.height = newHeight;
        this._levelContainer.scale.x = this._levelContainer.scale.y;
      }
    }
  }

  /**
   * Used for the signal when a level window opens.
   * Can be overwritten.
   * Mostly here because I can't get the timing right to NOT show the leaderboard...
   * @returns {boolean}
   */
  _showLeaderboard() {
    return false;
  }

  /**
   * Manage the mode tracker
   * this is needed for the levelWindowOpen signal and this function is run before thats dispatched in Window_level
   */
  _manageMode() {
    if (game.state.getCurrentState().mode === LevelType.NONE) { // Game state will manage but World state does not
      game.state.getCurrentState().mode = LevelType.TREASURE_HUNT;
    }
    this.gameMode = LevelType.TREASURE_HUNT;
  }

  /**
   * Init the booster, then override to move it down
   */
  _initBoosters(levelNumber, windowType, boosterTextStyle = null) {
    super._initBoosters(levelNumber, 'treasureHuntLevel', boosterTextStyle);
    for (const booster of this._boosters) {
      booster.y += 25;
    }
    this._buyTxt.y += 25;
  }

  /**
   * setup level data
   * @param {*} level
   */
  _manageData(level) {
    // fetch event level data again if needed
    if ((typeof level) !== 'object') level = G.saveState.treasureHuntManager.currentTreasureLevel;

    // set global level data
    if (level !== undefined) {
      // when we get level the first time, its an object, but if we go to the shop and come back, its a number, so we check for both.
      if (typeof level === 'number') {
        G.lvlData = G.Utils.clone(G.Helpers.levelDataMgr.getLevelByIndex(level));
        if (G.lvlData.tutID) G.lvlData.tutID = null;
      } else {
        G.lvlData = level;
      }
      this.levelData = G.lvlData;
      G.lvlNr = `${this.levelData.levelNumber}th`;
    }
    this._parentLevelName = 'treasureHuntLevel';

    // OMT_VILLAINS.setLevelType(this.gameMode);
  }

  /**
   * init the generic graphics layout
   */
  _initLayout() {
    const headerTxt = OMT.language.getText('Treasure Hunt Level');
    this._extraContainer = new Phaser.Group(game, this); // For some reason calling this.toLocal returns NaN but this.Group.toLocal is fine
    this._levelContainer = new Phaser.Group(game, this._extraContainer);
    this._topSection = new Phaser.Group(game, this._levelContainer);
    this.bg = G.makeImage(0, 0, 'popup_background_treasureHunt', 0.5, this._levelContainer);

    // Has to be behind banner
    this._initTopSection();

    // banner
    this._banner = G.makeImage(
      0, -265,
      'treasureHunt_banner',
      0.5,
      this._levelContainer,
    );

    // title text
    this._titleTxt = new G.Text(0, -267, headerTxt, {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, 330, 125, true, 'center');
    this._levelContainer.add(this._titleTxt);

    // Progress bar
    this._progressBar = new THProgressBar(G.saveState.treasureHuntManager.currentTokens, false);
    this._progressBar.y = -100;
    this._levelContainer.addChild(this._progressBar);

    // HelpButton
    this._progressBar.setClickFunc(this._onTreasureHuntInfo.bind(this));

    this._initArrangeButtons();
  }

  /**
   * Arranges the buttons to fit into view
   */
  _initArrangeButtons() {
    const layoutData = G.OMTsettings.treasureHuntSuper.levelWindow.buttons[OMT.systemInfo.orientationKey];

    // Badge and leaderboard buttons
    this._waitForBadges(layoutData.badge);

    this._leaderboardButton = new G.Button(layoutData.leaderboard.x, layoutData.leaderboard.y, 'treasureHunt_leaderboardButton', this._openLeaderboard.bind(this));
    const leaderboardText = new G.Text(0, 0, OMT.language.getText('Leaderboard'), 'treasureHunt-levelLeaderboardButton', 0.5);
    this._leaderboardButton.addChild(leaderboardText);
    this._extraContainer.addChild(this._leaderboardButton);
    G.sb('hideHighscoreBoard').dispatch(); // Triggers G.sb in World.js
  }

  /**
   * Badges need the RTSS to finish loading.
   * Will tween them in if it loads midway
   * Otherwise will show immediately
   * @param {{x:number, y:number}} layoutData
   */
  async _waitForBadges(layoutData) {
    const res = await OMT_AssetLoader.getInstance().waitOnSecondaryImages(['treasureHunt'], 90000);
    if (!res) { return; }
    this._badgeButton = new G.Button(layoutData.x, layoutData.y, 'treasureHunt_badgeButton', this._openBadges.bind(this));
    const badgeText = new G.Text(0, 0, OMT.language.getText('Badges'), {
      style: 'font-white',
      fontSize: 35,
    }, 0.5);
    this._badgeButton.addChild(badgeText);

    if (this._windowIsInView && game) {
      this._badgeButton.scale.set(0.01);
      game.add.tween(this._badgeButton.scale)
        .to({ x: 1, y: 1 }, 300, Phaser.Easing.Sinusoidal.Out, true);
    }
    this._extraContainer.addChild(this._badgeButton);
  }

  /**
   * Pops up a mini window telling you how to collect tokens
   */
  _onTreasureHuntInfo() {
    if (!this._helpInfo) {
      this._helpInfo = new TreasureHuntInfoWindow(game, null);
      this._helpInfo.setOnCloseFunc(this._onTreasureHuntInfoClose.bind(this));
    }
    this.addChild(this._helpInfo);
    this._helpInfo.showPage();
    this._levelContainer.visible = false; // Hides level container while open
    this._leaderboardButton.visible = false;
    this._timerGroup.visible = false;
    if (this._badgeButton) { this._badgeButton.visible = false; }
  }

  /**
   * Shows the level container again
   */
  _onTreasureHuntInfoClose() {
    this._levelContainer.alpha = 0;
    this._levelContainer.visible = true;
    this._leaderboardButton.visible = true;
    this._timerGroup.visible = true;
    if (this._badgeButton) { this._badgeButton.visible = true; }
    game.add.tween(this._levelContainer)
      .to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out, true);
  }

  /**
   * Overrides the close button. Makes it big actually.
   * @param {boolean} shouldBeSmall
   */
  _makeCloseButton(shouldBeSmall) {
    super._makeCloseButton();
    this._closeButton.scale.set(1.15);
    this._closeButton.x += 5;
  }

  /**
   * init task UI
   */
  _initTasks() {
    this._taskBg = G.makeImage(0, 40, this._getTaskBgName(), 0.5, this._levelContainer);

    const treasureHuntTextStyle = 'treasureHunt-levelWindow';
    this._taskTxt = new G.Text(0, -30, `${OMT.language.getText('Task')}:`, {
      style: treasureHuntTextStyle,
      fontSize: '45px',
    }, 0.5, 380);
    this._levelContainer.add(this._taskTxt);

    if (this.levelData.goal[0] === 'collect') {
      this._taskPanel = G.Helpers.createTaskCollectPanels(this.levelData.goal[1], {
        style: treasureHuntTextStyle,
        fontSize: '50px',
      });
      this._taskPanel.y = 35;
      this._levelContainer.add(this._taskPanel);
    } else {
      this._levelContainer.add(new G.Text(0, 35, `${OMT.language.getText('points').toUpperCase()}: ${this.levelData.goal[1]}`, {
        style: treasureHuntTextStyle,
        fontSize: '50px',
      }, 0.5, 380));
    }
  }

  /**
   * Initializes the top section with the timer and the mascot
   */
  _initTopSection() {
    this._topSection.y = -350;
    this._eventEndTime = G.saveState.treasureHuntManager.timeBeforeEnd;
    if (this._eventEndTime > -1) {
      this._timerGroup = new Phaser.Group(game, this._extraContainer);
      const bg = G.makeImage(0, 0, 'treasureHunt_timerBar', 0.5, this._timerGroup);
      const timer = this._makeTimerText(bg.width * 0.8);
      this._timerGroup.addChild(timer);
      this._timerGroup.x = this.bg.x + (this.bg.width / 4);
      this._timerGroup.y = -400;
    }

    const mascot = new Phaser.Group(game, this._topSection);
    const mascotNumber = G.saveState.treasureHuntManager.currentMascot;
    G.makeImage( // Mascot image
      G.OMTsettings.treasureHuntSuper.levelWindow.mascot.offsetX,
      G.OMTsettings.treasureHuntSuper.levelWindow.mascot.offsetY,
      `treasureHunt_prelevelMascot${mascotNumber}`, 0.5, mascot,
    );
    mascot.x += this.bg.x - (this.bg.width - (mascot.width / 1.5)) / 2;
  }

  /**
   * Makes a textTimer used for the treasure hunt
   *
   * Probably should refactor this one day
   * @param {number} inWidth
   * @returns {TextTimer}
   */
  _makeTimerText(inWidth) {
    const curTime = OMT.connect.getServerTimestampSync();
    const durationLeftSec = (this._eventEndTime - curTime) / 1000;
    let timeStyle = TIMER_FORMAT.MS;
    const shownEndTime = G.changeSecToDHMS(durationLeftSec);
    if (Number.parseInt(shownEndTime[0]) > 0) {
      timeStyle = TIMER_FORMAT.DH;
    } else if (Number.parseInt(shownEndTime[1]) > 0) {
      timeStyle = TIMER_FORMAT.HM;
    }
    const timer = new TextTimer({
      x: G.OMTsettings.treasureHuntSuper.levelWindow.timer.text.x,
      y: G.OMTsettings.treasureHuntSuper.levelWindow.timer.text.y,
      style: 'eventPostcard-mapIconTimer',
      anchor: 0.5,
      maxWidth: inWidth,
      timerFormat: timeStyle,
    });
    timer.setSecLeft(durationLeftSec);
    timer.active = true;
    return timer;
  }

  /**
   * Overrides text style
   * @param {boolean} isNotNormalLevel
   * @param {boolean} useSuperHardGraphics
   * @returns {string}
   */
  _getBuyTextStyle(isNotNormalLevel, useSuperHardGraphics) {
    return 'treasureHunt-levelWindow';
  }

  /**
   * Overrides text style
   * @param {boolean} isNotNormalLevel
   * @returns {string}
   */
  _getBgImageTexture(isNotNormalLevel) {
    return 'th_tutorial_box';
  }

  /**
   * Override booster background
   * @param {boolean} isNotNormalLevel
   * @param {boolean} useSuperHardGraphics
   * @param {boolean} isDailyChallenge
   * @returns {Phaser.Image}
   */
  _createBoosterBg(isNotNormalLevel, useSuperHardGraphics, isDailyChallenge) {
    this._boosterBg = G.makeImage(0, 195, 'treasureHunt_taskBox', 0.5, this._levelContainer);
  }

  /**
   * treasureHunt doesn't allow multi boosters
   * @param {boolean} andRequest
   * @returns {boolean}
   */
  _allowMultipleBoosters(andRequest) {
    return false; // OMT.feature.multiplePreLevelBoostersAllowed();
  }

  /**
   * Returns X position of boosters
   * @param {boolean} andRequest
   * @returns {number}
   */
  _calculateSideBoosterXValue(requiresExtraAdjustment) {
    return 175;
  }

  /**
   * Doesn't adjust booster size
   * @param {Phaser.DisplayObject} booster
   * @param {boolean} doesItReally
   * @returns {number}
   */
  _adjustBoosterSize(booster, doesItReally) {
  }

  /**
   * Finds proper asset for task bg
   * @returns {string}
   */
  _getTaskBgName() {
    return 'treasureHunt_taskBox';
  }

  /**
   * Initializes the continue button
   */
  _initButtons() {
    super._initButtons(BUTTONCOLOURS.green, this._onContinueClick, false);
    if (this._eventEndTime === -1) {
      this._continueBtn.currentState = BUTTONSTATE.DISABLED;
    }
  }

  /**
   * Set up the tournament promotion button that sits below the window.  Clicking it will lead to the tournament
   * not used in this class, but Window_Level still calls it
   */
  _setupTournamentPromoButton() {
    // unused
  }

  /**
   * display stars achived
   * not used in this class, but Window_Level still calls it
   */
  _initStars() {
    // unused
  }

  /**
   * Opens the leaderboard, and allows it to open the treasure hunt level window again
   */
  _openLeaderboard() {
    G.sb('pushWindow').dispatch(['treasureHuntLeaderboard', this._getReturnArgs()]);
    this.closeWindow();
  }

  /**
   * Opens the badge check, and returns back to the treasure hunt level window again
   */
  _openBadges() {
    G.sb('pushWindow').dispatch(['treasureHuntBadgeCheck', this._getReturnArgs()]);
    this.closeWindow();
  }

  /**
   * on continue button clicked
   * Goes to treasure hunt board
   */
  _onContinueClick() {
    // Note that the player participated in the current treasure hunt
    // and schedule a bot message for when it ends
    G.saveState.treasureHuntManager.setLastParticipationTime();
    G.saveState.treasureHuntManager.scheduleEndBotMessage();

    const levelData = {
      lvlIndex: this.levelData.levelNumber,
      debugMode: false,
      startBoosters: super._assembleLevelBoosters(`${this.levelData.levelNumber}th`),
      treasureHuntLevel: this.levelData,
      preLevelTrackingData: {},
    };
    G.sb('onStateChange').dispatch('Game', levelData);
  }

  /**
   * Returns return arguments when coming back from coin shop or something
   * @returns {{windowName:string, args:Array<any>, layerName:string}}
   */
  _getReturnArgs() {
    return {
      windowName: 'treasureHuntLevel',
      args: [this._retryConfig],
      layerName: this.parent.layerName,
    };
  }

  /**
   * Resize handler
   */
  _onResize() {
    if (this._helpInfo) {
      this._helpInfo.resize();
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.treasureHuntLevel = Window_TreasureHuntLevel;
