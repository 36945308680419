/* eslint-disable func-names */
import { EDITOR_SYMBOLS, TOKEN_TYPES } from './BoardConstants';
import { BOARD_FEATURE_KEYS } from './BoardGameHooks';
import CandyDataManager from './Candy/CandyDataManager';

/**
 * class for refilling board candies / tokens
 */
export class BoardRefiller {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    this._board = board;
    this._candiesLayer = board.candiesLayer;
    this._lvlDataManager = board.lvlDataManager;

    const {
      gameHooks,
      isTokenEventLevel,
      data: lvlData,
      isDailyChallengeLevel,
      isTreasureHuntLevel,
    } = this._lvlDataManager;

    this._goalCandyNoGoalLimit = lvlData.goalCandyNoLimit || false;
    this._goalCandyBoardLimit = lvlData.goalCandyBoardLimit || Infinity;
    this._chainLimit = Number.isFinite(lvlData.chainLimit) ? (lvlData.chainLimit > -1 ? lvlData.chainLimit : Infinity) : Infinity; // eslint-disable-line no-nested-ternary
    this._infectionLimit = lvlData.infectionLimit || Infinity;

    // clone drop lists from level data
    this._setDropLists(lvlData);

    // Veto treasure hunt and fortune cookies
    // if current level is an event level
    if (!(isTokenEventLevel || isDailyChallengeLevel || isTreasureHuntLevel)) {
      if (gameHooks.isFeatureActive(BOARD_FEATURE_KEYS.FORTUNE_COOKIE)) this._setFortuneCookieDrops();
    }
    if (gameHooks.isFeatureActive(BOARD_FEATURE_KEYS.EVENT_TOKENS)) this._setTokenEventDrops();

    // create chance table for regular candy types
    this.regularChanceTable = this._createRegularChanceTable(
      lvlData.nrOfTypes, this._drops,
    );
  }

  /**
   * set and configure drop lists based on level data
   * @param {Object} lvlData
   */
  _setDropLists(lvlData) {
    const drops = G.Utils.clone(lvlData.drops);
    this._goalDrops = lvlData._goalDrops ? G.Utils.clone(lvlData._goalDrops) : [];
    this._predefinedDrops = lvlData.predefinedDrops ? G.Utils.clone(lvlData.predefinedDrops) : [];
    if (drops.chest == null) drops.chest = 0;
    if (drops.infection == null) drops.infection = 0;
    if (drops.chain == null) drops.chain = 0;
    if (drops.goalCandy == null) drops.goalCandy = 0;
    if (drops.burntCandy == null) drops.burntCandy = 0;
    if (drops.predefHori == null) drops.predefHori = 0;
    if (drops.predefVert == null) drops.predefVert = 0;
    if (drops.predefCros == null) drops.predefCros = 0;
    if (drops.predefBomb == null) drops.predefBomb = 0;
    drops.chest *= this._lvlDataManager.coinChanceProb;
    drops.eventToken = 0;
    this._drops = drops;
  }

  /**
   * check and set fortune cookie drops
   */
  _setFortuneCookieDrops() {
    this._fortuneHasDropped = false;
    this._drops.fortune = this._lvlDataManager.getSpecialCandyDropChance(BOARD_FEATURE_KEYS.FORTUNE_COOKIE);
  }

  /**
   * Returns if a chest should drop or not
   * @returns {Boolean}
   */
  _getDropRateForRegularChest() {
    if (this._lvlDataManager.isTreasureHuntLevel) {
      return false;
    }
    return Math.random() < this._drops.chest / 100;
  }

  /**
   * Calculate rate of treasure chest dropping
   * @returns {number}
   */
  _getTreasureChestDropRate() {
    let rate = 0;
    if (this._lvlDataManager.isTreasureHuntLevel) {
      rate = Math.max(5 - this._board.getNumberOfTreasureChestsOnBoard(), 0);
    }
    return rate;
  }

  /**
   * check and set event token drops
   */
  _setTokenEventDrops() {
    this._drops.eventToken = this._lvlDataManager.getSpecialCandyDropChance(BOARD_FEATURE_KEYS.EVENT_TOKENS);
    this._drops.chest = 0; // disable regular chests
  }

  /**
   * create chance table for regular candy types
   * @param {number} nrOfTypes
   * @param {Object} additionalChances
   * @returns {Array}
   */
  _createRegularChanceTable(nrOfTypes, additionalChances) {
    const regularTypes = CandyDataManager.getTypesWithTag('regular').slice(0, nrOfTypes);
    const additionalChancesSum = Object.keys(additionalChances)
      .filter((key) => regularTypes.find((type) => type.editorSymbol === key))
      .reduce((acc, key) => acc + additionalChances[key], 0);

    const baseChance = (100 - additionalChancesSum) / nrOfTypes;
    let currentChanceStart = 0;

    const table = regularTypes.map((type) => {
      currentChanceStart += (baseChance + (additionalChances[type.editorSymbol] || 0)) / 100;
      const entry = [currentChanceStart, type];
      return entry;
    });
    return table;
  }

  /**
   * get a editor symbol from the chance table
   * @param {Array} chanceTable
   * @returns {string}
   */
  _getEditorSymbolFromChanceTable(chanceTable) {
    const rnd = game.rnd.rnd();
    for (let i = 0; i < chanceTable.length; i++) {
      if (rnd <= chanceTable[i][0]) return chanceTable[i][1].editorSymbol;
    }
    return null;
  }

  /**
   * get prefined drops if any remaninig for a column
   * @param {number} column
   * @returns {string}
   */
  _getPredifinedDrops(column) {
    if (this._predefinedDrops[column]) {
      return this._predefinedDrops[column].shift();
    }
    return null;
  }

  /**
   * get weighted random drop
   * @param {Array} elements
   * @returns {string}
   */
  _getWeightedRandomFromDrops(elements) {
    const drops = this._drops;
    return G.Utils.getRandomWeightedElement(
      elements,
      (elem) => drops[elem] || 0,
    );
  }

  /**
   * get token type to drop for a specific column based on drop rates
   * @param {number} column
   * @returns {string}
   */
  getTypeToDrop(column) {
    let pre = this._getPredifinedDrops(column);
    if (pre) { // level has predefined drop
      if (pre[0] === EDITOR_SYMBOLS.RANDOM) {
        pre = this._getEditorSymbolFromChanceTable(this.regularChanceTable) + pre.substr(1);
      }
      return pre;
    }

    this._substractGoalDropCounter();

    const goalDrop = this._checkGoalDropList();
    if (goalDrop) return goalDrop;

    const { gameHooks } = this._lvlDataManager;

    const fortuneDropChance = this._lvlDataManager.moves > gameHooks.fortuneCookieMovesLeft && !this._lvlDataManager.isGoalAchieved();
    const eventToken = Math.random() < this._drops.eventToken / 100;
    const goalCandy = Math.random() < this._drops.goalCandy / 100;
    const burntCandy = Math.random() < this._drops.burntCandy / 100;
    const chest = this._getDropRateForRegularChest();
    const treasureChest = Math.random() < this._getTreasureChestDropRate() / 100;
    const fortune = !this._fortuneHasDropped && Math.random() < (fortuneDropChance ? this._drops.fortune : 0) / 100;
    const chain = Math.random() < this._drops.chain / 100;
    const infection = this._getDropRateForInfection();
    const box = Math.random() < G.Utils.defined(this._drops.bx, 0) / 100;

    if (goalCandy && this._checkIfCanDropHardCandy()) return TOKEN_TYPES.GOAL_CANDY;
    if (burntCandy && this._checkIfCanDropHardCandy()) return TOKEN_TYPES.BURNT;
    if (eventToken) {
      return TOKEN_TYPES.EVENT_TOKEN;
    }
    if (fortune) { this._fortuneHasDropped = true; return TOKEN_TYPES.FORTUNE; }
    if (treasureChest) return TOKEN_TYPES.CHEST_TH;
    if (chest) return TOKEN_TYPES.CHEST;
    if (infection) return TOKEN_TYPES.INFECTION;

    let rndType = this._getEditorSymbolFromChanceTable(this.regularChanceTable);
    if (chain && this._checkIfCanDropChainCandy()) { // chain
      const chainLevel = this._getWeightedRandomFromDrops([
        `${EDITOR_SYMBOLS.CHAIN}|1`,
        `${EDITOR_SYMBOLS.CHAIN}|2`,
        `${EDITOR_SYMBOLS.CHAIN}|3`,
      ]);
      rndType = `${rndType}:${chainLevel}`;
    } else if (box) { // candy-box
      rndType = `${rndType}:${this._getCandyBoxColor()}`;
    } else { // Random special gem
      const isSpecialGem = this._checkIfCanDropRandomSpecial(rndType);
      if (isSpecialGem) {
        rndType = isSpecialGem;
      }
    }

    return rndType;
  }

  /**
   * get randomized candy-box token with color
   * @returns {string}
   */
  _getCandyBoxColor() {
    const drops = this._drops;
    return G.Utils.getRandomWeightedElement(
      [
        `${EDITOR_SYMBOLS.CANDY_BOX}|r`,
        `${EDITOR_SYMBOLS.CANDY_BOX}|1`,
        `${EDITOR_SYMBOLS.CANDY_BOX}|2`,
        `${EDITOR_SYMBOLS.CANDY_BOX}|3`,
        `${EDITOR_SYMBOLS.CANDY_BOX}|4`,
        `${EDITOR_SYMBOLS.CANDY_BOX}|5`,
        `${EDITOR_SYMBOLS.CANDY_BOX}|6`,
      ],
      (elem) => drops[elem] || 0,
    );
  }

  /**
   * check goal drops
   * @returns {string}
   */
  _checkGoalDropList() {
    for (let i = 0, len = this._goalDrops.length; i < len; i++) {
      if (this._goalDrops[i][1] <= 0) {
        const result = this._goalDrops[i][0];
        this._goalDrops.splice(i, 1);
        return result;
      }
    }
    return null;
  }

  /**
   * increment the goal drop counter
   */
  _substractGoalDropCounter() {
    for (let i = 0, len = this._goalDrops.length; i < len; i++) {
      this._goalDrops[i][1] -= 1;
    }
  }

  /**
   * check if its OK to drop a hard candy on the board
   * @returns {boolean}
   */
  _checkIfCanDropHardCandy() {
    const { goalManager } = this._lvlDataManager;
    const goalTarget = goalManager.getGoalLeftAmount(TOKEN_TYPES.GOAL_CANDY) || 0;
    const amountOfGoalCandies = this._candiesLayer.getAllTokensWithTag(TOKEN_TYPES.GOAL_CANDY).length;
    const amountOfBurntCandies = this._candiesLayer.getAllTokensWithTag(TOKEN_TYPES.BURNT).length;
    if (this._goalCandyNoGoalLimit) return (amountOfGoalCandies + amountOfBurntCandies) < this._goalCandyBoardLimit;
    return (amountOfGoalCandies + amountOfBurntCandies) < Math.min(goalTarget, this._goalCandyBoardLimit);
  }

  /**
   * check if its OK to drop a hard candy on the board
   * @returns {boolean}
   */
  _checkIfCanDropChainCandy() {
    const { goalManager } = this._lvlDataManager;
    let goalTarget = Infinity;
    if (goalManager.wasEverAGoal(TOKEN_TYPES.CHAIN)) {
      goalTarget = goalManager.getGoalLeftAmount(TOKEN_TYPES.CHAIN) || 0;
    }
    const amountOfChainCandy = this._candiesLayer.countEditorSymbolOccurrence(EDITOR_SYMBOLS.CHAIN);
    return amountOfChainCandy < Math.min(goalTarget, this._chainLimit);
  }

  /**
   * Checks if its ok to drop an infection source block.
   * If its not, it will not
   * @returns {boolean}
   */
  _getDropRateForInfection() {
    const { goalManager } = this._lvlDataManager;
    let goalTarget = Infinity;
    if (goalManager.wasEverAGoal(TOKEN_TYPES.INFECTION)) {
      goalTarget = goalManager.getGoalLeftAmount(TOKEN_TYPES.INFECTION) || 0;
    }
    const amountOfChainCandy = this._candiesLayer.getAllTokensWithTag(TOKEN_TYPES.INFECTION).length;
    if (amountOfChainCandy < Math.min(goalTarget, this._infectionLimit)) {
      return Math.random() < this._drops.infection / 100;
    }
    return false;
  }

  /**
   * Checks if its within chance to turn the given gem into a special type
   * @returns {(string|boolean)}
   */
  _checkIfCanDropRandomSpecial(gem) {
    if (this._lvlDataManager.isGoalAchieved()) { return false; }

    // Hori
    if (Math.random() < this._drops.predefHori / 100) {
      return `${gem}:H`;
    }
    // Vert
    if (Math.random() < this._drops.predefVert / 100) {
      return `${gem}:V`;
    }
    // Cross
    if (Math.random() < this._drops.predefCros / 100) {
      return `${gem}:C`;
    }
    // Bomb
    if (Math.random() < this._drops.predefBomb / 100) {
      return `${gem}:S`;
    }

    return false;
  }

  /**
   * destruction method
   */
  destroy() {
    this._board = null;
    this._candiesLayer = null;
    this._lvlDataManager = null;
  }
}
