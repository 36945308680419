import { Action, ACTION_TYPES } from './Action';
import { TOKEN_TYPES, SPECIAL_TYPES } from '../BoardConstants';
import CandyDataManager from '../Candy/CandyDataManager';

const MOVEMENT_SPEED = 0.075;

/**
 * class for board candy move / swap action
 */
export class ActionMove extends Action {
  /**
   * initialize the action.
   * @param {Candy} candy1
   * @param {Candy} candy2
   * @param {boolean} forceMove
   */
  _init(candy1, candy2, forceMove) {
    this._candy1 = candy1;
    this._candy1orgParent = this._candy1.parent;
    this._candy2 = candy2;
    this._candy2orgParent = this._candy2.parent;

    this._forceMove = forceMove;
    this._reverse = false;

    this._startAnimation();
  }

  /**
   * action update method
   */
  update() {
    const { lvlDataManager } = this._board;

    this._updateAnimation();
    this.progress += MOVEMENT_SPEED * G.deltaTime;

    // move animation progress is 100% or more
    if (this.progress >= 1) {
      this._finishAnimation();

      if (this._reverse) {
        this.finish();
        return;
      }

      this._candy1.movedWith(this._candy2);
      this._candy2.movedWith(this._candy1);

      // if one of the candies has a special type check for combos
      if (this._candy1.hasSpecialType() && this._candy2.hasSpecialType()) {
        // check if move results in a pre-defined special combos
        if (this._checkCombo(this._candy1, this._candy2)) {
          lvlDataManager.madeMove();
          this._actionManager.newAction(ACTION_TYPES.PROCESS_MATCH);
          this.finish();
          return;
        }

        // check for and activate spiral matches
        if (this._candy1.getSpecialTypeName() === SPECIAL_TYPES.SPIRAL || this._candy2.getSpecialTypeName() === SPECIAL_TYPES.SPIRAL) {
          const spiral = this._candy1.getSpecialTypeName() === SPECIAL_TYPES.SPIRAL ? this._candy1 : this._candy2;
          const other = this._candy1.getSpecialTypeName() !== SPECIAL_TYPES.SPIRAL ? this._candy1 : this._candy2;

          this._checkForCollectables(this._candy1, this._candy2);

          other.startAnimation('moveTo', [0, spiral.cellX, spiral.cellY]);
          spiral.specialType.setExe([[
            'changeTypeInto',
            other.getBaseTypeSymbol() >= 1 ? other.getBaseTypeSymbol() : game.rnd.between(1, this._board.MAX_NUMBER_OF_REGULAR_CANDY), other.getSpecialTypeName(),
          ]]);
          this._board.checkMatchList.push(spiral);

          lvlDataManager.madeMove();
          this._actionManager.newAction(ACTION_TYPES.PROCESS_MATCH);
          this._candy1.markSuccessfulMove(this._candy2);
          this._candy2.markSuccessfulMove(this._candy1);
          this.finish();
          return;
        }

        // if there is no combo, just activate both candies
        this._candy1.activatedByMove = true;
        this._candy2.activatedByMove = true;
        this._board.checkMatchList.push(this._candy1);
        this._board.checkMatchList.push(this._candy2);
        lvlDataManager.madeMove();
        this._actionManager.newAction(ACTION_TYPES.PROCESS_MATCH);
        this._candy1.markSuccessfulMove(this._candy2);
        this._candy2.markSuccessfulMove(this._candy1);
        this.finish();
        return;
      }

      // check if conditions for a normal match are satisfied
      if (this._additionalChecks(this._candy1, this._candy2)) {
        if (this._board.matcher.isMoveValid(this._candy1)) this._board.checkMatchList.push(this._candy1);
        if (this._board.matcher.isMoveValid(this._candy2)) this._board.checkMatchList.push(this._candy2);
      }

      // if a normal match was made finalize and trigger the processMatch action
      if (this._board.checkMatchList.length > 0) {
        this._candy1.movedWith(this._candy2);
        this._candy2.movedWith(this._candy1);
        if (!this._forceMove) lvlDataManager.madeMove();
        this._actionManager.newAction(ACTION_TYPES.PROCESS_MATCH);
        this._candy1.markSuccessfulMove(this._candy2);
        this._candy2.markSuccessfulMove(this._candy1);
        this.finish();
        return;
      }

      // finish or start animation back to original position
      if (this._reverse || this._forceMove) {
        this.finish();
      } else {
        this._reverse = true;
        this._startAnimation();
      }
    }
  }

  /**
   * check the 2 candies for collectables / combos
   * @param {Candy} candyA
   * @param {Candy} candyB
   */
  _checkForCollectables(candyA, candyB) {
    if (this._areCandiesSpecialsCombo(candyA, candyB, SPECIAL_TYPES.SPIRAL, SPECIAL_TYPES.CROSS)) {
      G.sb('onCollectableRemove').dispatch('bombCrossCombo');
    } else if (this._areCandiesSpecialsCombo(candyA, candyB, SPECIAL_TYPES.SPIRAL, SPECIAL_TYPES.HORIZONTAL)
      || this._areCandiesSpecialsCombo(candyA, candyB, SPECIAL_TYPES.SPIRAL, SPECIAL_TYPES.VERTICAL)) {
      G.sb('onCollectableRemove').dispatch('bombVertHorCombo');
    }
  }

  /**
   * check if 2 specific special candy types are being moves
   * @param {Candy} candyA
   * @param {Candy} candyB
   * @param {string} specialTypeA
   * @param {string} specialTypeB
   * @returns {boolean}
   */
  _areCandiesSpecialsCombo(candyA, candyB, specialTypeA, specialTypeB) {
    return (candyA.getSpecialTypeName() === specialTypeA && candyB.getSpecialTypeName() === specialTypeB)
      || (candyA.getSpecialTypeName() === specialTypeB && candyB.getSpecialTypeName() === specialTypeA);
  }

  /**
   * run additional checks to see if moving / swapping these to candies results in a valid match
   * @param {Candy} candy1
   * @param {Candy} candy2
   * @returns {boolean}
   */
  _additionalChecks(candy1, candy2) {
    const spiralBomb = [candy1, candy2].find((c) => c.getSpecialTypeName() === SPECIAL_TYPES.SPIRAL);
    if (!spiralBomb) return true;
    const goalCandy = [candy1, candy2].find((c) => c.getBaseTypeSymbol() === TOKEN_TYPES.GOAL_CANDY);
    const burntCandy = [candy1, candy2].find((c) => c.getBaseTypeSymbol() === TOKEN_TYPES.BURNT);
    const chest = [candy1, candy2].find((c) => c.getBaseTypeSymbol() === TOKEN_TYPES.CHEST || c.getBaseTypeSymbol() === TOKEN_TYPES.CHEST_TH);
    const cake = [candy1, candy2].find((c) => c.getBaseTypeSymbol().indexOf(TOKEN_TYPES.LAYER_CAKE) === 0);
    const fortune = [candy1, candy2].find((c) => c.getBaseTypeSymbol() === TOKEN_TYPES.FORTUNE);
    const eventToken = [candy1, candy2].find((c) => c.getBaseTypeSymbol() === TOKEN_TYPES.EVENT_TOKEN);
    const infection = [candy1, candy2].find((c) => c.getBaseTypeSymbol() === TOKEN_TYPES.INFECTION);
    if (goalCandy || burntCandy || infection || fortune || eventToken || chest || cake) return false;
    return true;
  }

  /**
   * check if swapping these 2 candies results in one of the pre-defined special combos
   * @param {Candy} candy1
   * @param {Candy} candy2
   * @returns {boolean}
   */
  _checkCombo(candy1, candy2) {
    const combos = CandyDataManager.getCombos();
    for (let i = 0; i < combos.length; i++) {
      const combo = combos[i];

      if (this._areCandiesSpecialsCombo(candy1, candy2, combo[0], combo[1])) {
        let moveRot = combo[3];
        // special case - order of candies (rotation of candy that doesnt move might be needed)
        if ((combo[0] === SPECIAL_TYPES.VERTICAL || combo[0] === SPECIAL_TYPES.HORIZONTAL) && combo[1] === SPECIAL_TYPES.CROSS) {
          if (candy1.getSpecialTypeName() === SPECIAL_TYPES.VERTICAL || candy1.getSpecialTypeName() === SPECIAL_TYPES.HORIZONTAL) {
            game.add.tween(candy1).to({ angle: combo[3] }, 300, Phaser.Easing.Sinusoidal.InOut, true);
            moveRot = 0;
          }
        }

        // Count towards goal before changing candy to some other special
        if (candy1.getSpecialTypeName()) { G.sb('onCollectableRemove').dispatch(candy1.getSpecialTypeName()); }
        if (candy2.getSpecialTypeName()) { G.sb('onCollectableRemove').dispatch(candy2.getSpecialTypeName()); }

        candy1.changeInto(combo[2]);
        candy2.changeInto('emptySpecial');
        candy2.detachFromGrid();
        candy2.startAnimation('moveToCombo', [candy1.cellX, candy1.cellY, moveRot]);

        candy1.addMatchFx(['dummyComboGrowAndFade', [candy2.frameName, moveRot]]);
        candy2.bringToTop();
        candy1.activatedByMove = true;
        this._board.checkMatchList.push(this._candy1);
        return true;
      }
    }
    return false;
  }

  /**
   * start the movement / candy swap animation
   */
  _startAnimation() {
    const { gameHooks, candiesLayer } = this._board;
    gameHooks.playSound('exchange');

    this._candy1anim = {
      startX: this._candy1.x,
      deltaX: this._candy2.x - this._candy1.x,
      startY: this._candy1.y,
      deltaY: this._candy2.y - this._candy1.y,
    };

    candiesLayer.movingCandyGroup.add(this._candy1);

    this._candy2anim = {
      startX: this._candy2.x,
      deltaX: this._candy1.x - this._candy2.x,
      startY: this._candy2.y,
      deltaY: this._candy1.y - this._candy2.y,
    };

    candiesLayer.movingCandyGroup.add(this._candy2);
    this._candy1.bringToTop();
    this.progress = 0;

    if (G.IMMEDIATE) this.progress = 1;
  }

  /**
   * finish the movement / candy swap animation
   */
  _finishAnimation() {
    this._board.swapCandies(this._candy1, this._candy2);
    this._candy1.x = this._board.cellXToPxIn(this._candy1.cellX);
    this._candy1.y = this._board.cellYToPxIn(this._candy1.cellY);
    this._candy1.scale.setTo(1);
    this._candy1orgParent.add(this._candy1);
    this._candy2.x = this._board.cellXToPxIn(this._candy2.cellX);
    this._candy2.y = this._board.cellYToPxIn(this._candy2.cellY);
    this._candy2orgParent.add(this._candy2);
  }

  /**
   * update the candy animation based on the current progress / position
   */
  _updateAnimation() {
    const animProgress = Phaser.Easing.Sinusoidal.InOut(this.progress);

    this._candy1.x = this._candy1anim.startX + (animProgress * this._candy1anim.deltaX);
    this._candy1.y = this._candy1anim.startY + (animProgress * this._candy1anim.deltaY);

    this._candy2.x = this._candy2anim.startX + (animProgress * this._candy2anim.deltaX);
    this._candy2.y = this._candy2anim.startY + (animProgress * this._candy2anim.deltaY);

    this._candy1.scale.setTo(2 - (Math.abs(0.5 - animProgress) * 2));
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._candy1 = null;
    this._candy1orgParent = null;
    this._candy2 = null;
    this._candy2orgParent = null;
  }
}
