/* eslint-disable no-await-in-loop */
import OMT_UI_HorizontalScrollArea from '../../../OMT_UI/Scroll/OMT_UI_HorizontalScrollArea';
import { CollapsedUserViewBase } from './CollapsedUserViewBase';

const FADE_IN_TRANSITION_DELAY = 100;
const DEFAULT_RENDER_ITEM_DELAY = 16;

/**
 * base class for collapsed User list views
 */
export class CollapsedViewBase extends Phaser.Group {
  /**
   * constructor
   */
  constructor() {
    super(game);
    this._enabled = true;
    this._collapsedHeight = 0;
    this.signals = {};
  }

  /**
   * main initialization function. call manually or from extending Class
   * @param {Array} userList friendData list
   * @param {Phaser.Rectangle} scrollRect rectangle of scrollable area
   * @param {number} hSpacing hortizontal spacing width for UserViews
   * @param {number} renderDelay (optional) if not passed default will be used
   * @returns {Promise}
   */
  async init(userList, scrollRect, hSpacing, renderDelay = DEFAULT_RENDER_ITEM_DELAY) {
    this._initScrollArea(scrollRect);
    await this._initUserViews(userList, hSpacing, renderDelay);
    this._updateUserViewsVisibility();
  }

  /**
   * initialize the horizontal scroll area
   */
  _initScrollArea(scrollRect) {
    this._scrollArea = new OMT_UI_HorizontalScrollArea(scrollRect, true);
    this._scrollArea.addScrollCondition(() => {
      try {
        const { windowMgr, worldSidePanel } = game.state.getCurrentState();
        return windowMgr.isQueueEmpty && !worldSidePanel.sideGroup.opened;
      } catch (error) {
        // some element was undefined
      }
      return false;
    });
    this._scrollArea.signals.onScrolled.add(this._updateUserViewsVisibility, this);
    this._scrollArea.x = scrollRect.x;
    this._scrollArea.y = scrollRect.y;
    this.addChild(this._scrollArea);
    this._scrollRect = scrollRect;
  }

  /**
   * get a reference to the scrollArea object
   * @returns {OMT_UI_HorizontalScrollArea}
   */
  get scrollArea() {
    return this._scrollArea;
  }

  /**
   * draw a overlay background for buttons or whatever
   * @param {Object} contentRect rectange should have x, y, width, height
   * @param {number} color color of overlay
   */
  _drawOverlayBGRect(contentRect, color) {
    const graphics = new Phaser.Graphics(game);
    graphics.beginFill(color, 1);
    graphics.drawRect(0, 0, contentRect.width, contentRect.height);
    graphics.endFill();
    graphics.x = contentRect.x;
    graphics.y = contentRect.y;
    this.addChild(graphics);

    this._collapsedHeight = contentRect.height;
  }

  /**
   * initialize the user views
   * @param {Array} userList friendData list
   * @param {number} hSpacing hortizontal spacing width
   * @returns {Promise}
   */
  async _initUserViews(userList, hSpacing, initDelay = 0) {
    let friendData;
    let userView;
    this._userViews = [];

    // hide scroll area during intialization for throttling rendering
    const scrollAreaVisibile = this._scrollArea.visible;
    if (scrollAreaVisibile) this._scrollArea.visible = false;

    for (let i = 0; i < userList.length; i++) {
      // delay initialization for rendering throttling
      if (initDelay > 0) await new Promise((resolve) => { setTimeout(() => { resolve(); }, initDelay); });
      // continue view initialization
      friendData = userList[i];
      userView = this._initUserViewInstance(friendData);
      this._userViews.push(userView);
      userView.x = i * hSpacing;
      this._scrollArea.content.addChild(userView);
    }

    if (scrollAreaVisibile) this._scrollArea.visible = true;
  }

  /**
   * intialize UserView instance
   * @param {Object} friendData
   * @returns {CollapsedUserViewBase}
   */
  _initUserViewInstance(friendData) {
    const userView = new CollapsedUserViewBase();
    userView.init(friendData);
    return userView;
  }

  /**
   * set the each UserViews alpha and stop any tween
   * @param {number} alpha 0-1
   */
  setContentAlpha(alpha) {
    let userView;
    for (let i = 0; i < this._userViews.length; i++) {
      userView = this._userViews[i];
      userView.setContentAlpha(alpha);
    }
  }

  /**
   * set the line by line fade transition effect
   */
  setContentFadeInTransition() {
    const scrolledX = this._scrollArea.content.x;
    let userView;
    for (let i = 0; i < this._userViews.length; i++) {
      userView = this._userViews[i];
      if (this._isUserViewInVisibleArea(scrolledX, userView)) {
        userView.setFadeInTransition(i * FADE_IN_TRANSITION_DELAY);
      } else {
        userView.isFadedIn = true;
      }
    }
  }

  /**
   * check if content is in the visible scroll area
   * @param {number} scrolledX
   * @param {CollapsedUserViewBase} userView
   */
  _isUserViewInVisibleArea(scrolledX, userView) {
    const rightEdge = this._scrollRect.width;
    const leftEdge = 0;
    const leftX = userView.x + scrolledX;
    const rightX = leftX + userView.width;
    return leftX <= rightEdge && rightX > leftEdge;
  }

  /**
   * update visibility of the userviews based on the scroll rect
   */
  _updateUserViewsVisibility() {
    const scrolledX = this._scrollArea.content.x;
    let contentVisible;
    for (const userView of this._userViews) {
      if (userView.isFadedIn) {
        contentVisible = this._isUserViewInVisibleArea(scrolledX, userView);
        if (contentVisible && userView.alpha === 0) userView.alpha = 1;
        else if (!contentVisible && userView.alpha === 1) userView.alpha = 0;
      }
    }
  }

  /**
   * main update method
   */
  update() {
    if (this._enabled) super.update();
  }

  /**
   * enable interactivity
   */
  enable() {
    this._enabled = true;
    this._scrollArea.enable();
  }

  /**
   * disable interactivity
   */
  disable() {
    this._enabled = false;
    this._scrollArea.disable();
  }

  /**
   * destruction method
   */
  destroy() {
    Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    super.destroy();
  }

  /** @returns {number} */
  get collapsedHeight() {
    return this._collapsedHeight;
  }
}
