import { BoardToken } from './BoardToken';
import { TOKEN_TYPES } from '../BoardConstants';

const PRIMARY_TOKEN_TEXTURE = 'ice_front';
const SECONDARY_TOKEN_TEXTURE = 'ice_crack_#';

const MAX_HP = 4;

/**
 * BoardToken for ice
 */
export class BoardToken_Ice extends BoardToken {
  /**
   * constructor
   * @param {TokenLayer_Ice} layer
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} hp
   */
  constructor(layer, cellX, cellY, hp) {
    super(
      layer,
      {
        tokenType: TOKEN_TYPES.ICE,
      },
      cellX,
      cellY,
      hp,
    );

    // set texture based on hp
    G.changeTexture(this, PRIMARY_TOKEN_TEXTURE);

    this._breakImg = G.makeImage(0, 0, null, 0.5, this);
    if (this._hp < MAX_HP) {
      G.changeTexture(this._breakImg, SECONDARY_TOKEN_TEXTURE.replace('#', this._hp));
    }
  }

  /**
   * on hit by adjacent match.
   */
  onHit() {
    const { gameHooks } = this._board;
    gameHooks.playSound('explosion_subtle');

    G.sb('fxTop').dispatch('burstIce', this, this);

    this._hp--;
    if (this._hp > 0) {
      G.changeTexture(this._breakImg, SECONDARY_TOKEN_TEXTURE.replace('#', this._hp));
    } else {
      G.sb('fx').dispatch('smallCircle', this);
      this.remove();
    }
  }

  /**
   * on booster used on cell.
   * @returns {boolean}
   */
  onBooster() {
    this.onHit();
    return false;
  }
}
