/* eslint-disable object-curly-newline */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import { Window } from '../../../00_IMMEDIATE/Window';
import ShopUtils from '../Shop/Shop_Utils';
import { UI_LoadingIndicatorDisplay } from '@omt-components/UI/Loading/UI_LoadingIndicatorDisplay';

import TargetedOfferDataManager from '../../../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { TargetedOffer_MultiplierGroup } from './Components/TargetedOffer_MultiplierGroup';
import { TargetedOffer_HeaderGroup } from './Components/TargetedOffer_HeaderGroup';
import { TargetedOffer_BackgroundGroup } from './Components/TargetedOffer_BackgroundGroup';
import { TargetedOffer_BoosterIcon, TargetedOffer_ULBoosterIcon } from './Components/TargetedOffer_BoosterIcons';
import { TargetedOffer_DealCoinbar } from './Components/TargetedOffer_DealCoinbar';
import { TargetedOffer_BuyButton } from './Components/TargetedOffer_BuyButton';
import { TargetedOffer_RefillText } from './Components/TargetedOffer_RefillText';
import { TargetedOffer_MascotGroup } from './Components/TargetedOffer_MascotGroup';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { OMT_AssetLoader } from '../../../Services/OMT/OMT_AssetLoader';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

const CONTENT_SCALE = 1.2;

const BUY_BUTTON_X_VERTICAL = 0;
const BUY_BUTTON_X_HORIZONTAL = 150;

const CLOSE_BUTTON_CONFIG_VERTICAL = { x: 285, y: -440, scale: 0.75 };
const CLOSE_BUTTON_CONFIG_HORIZONTAL = { x: 465, y: -210, scale: 1 };

const LOADING_INDICATOR_X = 320;
const MAX_ASPECT_RATIO = 1.775;

let BOOSTER_SCALE;
let BOOSTER_PADDING;

const DEAL_LAYOUT_CONFIGS = {
  vertical: {
    NO_BOOSTERS: {
      dealGraphic: { offsetX: 10, offsetY: 45, scale: 0.95 },
      dealCoinbar: { offsetX: 20, offsetY: -30, scale: 1.1 },
      assetKey: 'moneyOnly',
    },
    SINGLE_BOOSTERS: {
      dealGraphic: { offsetX: 0, offsetY: 50, scale: 0.95 },
      dealCoinbar: { offsetX: 10, offsetY: 25, scale: 1 },
      iconGroup: { offsetX: 20, offsetY: 0 },
      assetKey: 'fewBoosters',
    },
    FEW_BOOSTERS: {
      dealGraphic: { offsetX: 0, offsetY: 13, scale: 0.8 },
      dealCoinbar: { offsetX: 20, offsetY: -84, scale: 1 },
      iconGroup: { offsetX: 35, offsetY: -19 },
      assetKey: 'fewBoosters',
    },
    MANY_BOOSTERS: {
      dealGraphic: { offsetX: 80, offsetY: 30, scale: 0.8 },
      dealCoinbar: { offsetX: 90, offsetY: -55, scale: 1 },
      iconGroup: { offsetX: 10, offsetY: 30 },
      assetKey: 'manyBoosters',
    },
  },

  horizontal: {
    NO_BOOSTERS: {
      dealGraphic: { offsetX: 160, offsetY: 65, scale: 1.5 },
      dealCoinbar: { offsetX: -222, offsetY: -175, scale: 1.1 },
      assetKey: 'moneyOnly',
    },
    SINGLE_BOOSTERS: {
      dealGraphic: { offsetX: 160, offsetY: 65, scale: 1.5 },
      dealCoinbar: { offsetX: -222, offsetY: -15, scale: 1.1 },
      iconGroup: { offsetX: 15, offsetY: -218, scale: 1.5 },
      assetKey: 'fewBoosters',
    },
    FEW_BOOSTERS: {
      dealGraphic: { offsetX: 160, offsetY: 65, scale: 1.5 },
      dealCoinbar: { offsetX: -222, offsetY: -15, scale: 1.1 },
      iconGroup: { offsetX: 35, offsetY: -218, scale: 1.3 },
      assetKey: 'fewBoosters',
    },
    MANY_BOOSTERS: {
      dealGraphic: { offsetX: 160, offsetY: 65, scale: 1.5 },
      dealCoinbar: { offsetX: -222, offsetY: -15, scale: 1.1 },
      iconGroup: { offsetX: 35, offsetY: 2, scale: 1 },
      assetKey: 'manyBoosters',
    },
  },
};

/**
 * Window for one-time Targeted Offers
 */
class Window_OneTimeTargetedOffer extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {string} productID
   * @param {String} offerID
   * @param {Object} windowCustomizations customizations to merge with G.OMTsettings.elements.Window_oneTimeTargetedOffer
   */
  constructor(parent, productID, offerID, windowCustomizations = null) {
    super(parent);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;

    BOOSTER_SCALE = this._isLandscape ? 0.9 : 0.75;
    BOOSTER_PADDING = this._isLandscape ? 5 : 20;

    this._baseConfig = G.OMTsettings.elements.Window_oneTimeTargetedOffer[OMT.systemInfo.orientationKey];
    this._config = _.cloneDeep(this._baseConfig);
    if (windowCustomizations) { // merge in customizations to config object if any
      const customizationObj = OMT_Utils.stringToReference(windowCustomizations, window);
      this._config = _.merge(this._config, customizationObj);
    }
    this._productID = productID;
    this._offerID = offerID;
    this._currentDeal = null;
    this._closeClicks = 0;

    if (game.state.current === 'World') {
      this.y = -G.WindowMgr.Constants.WorldVerticalOffset;
    }

    if (this._isLandscape) {
      this.y -= 35;
    }

    this.visible = false;
    this._init(productID);
  }

  /**
   * async initialization method
   * @param {string} productID
   */
  async _init(productID) {
    this._initLoadingIndicator();
    this._loadingIndicator.show();

    const assetLoader = OMT_AssetLoader.getInstance();
    const assetsLoaded = await assetLoader.waitOnSecondaryImages(['shop/ui', 'shop/nine-slices', 'shop/deal-graphics']);
    if (assetsLoaded) {
      const offerData = await this._getOfferData(productID);
      if (offerData) {
        this._currentDeal = offerData;
        this._showOffer();
        this.visible = true;
      } else { // no offer found throw error
        this._logOfferCatalogError(productID);
        this.closeWindow();
      }
    } else {
      this.closeWindow();
    }
    this._loadingIndicator.hide();
  }

  /**
   * log a offer / catalog error to Sentry
   * @param {string} productID productID attempted
   */
  _logOfferCatalogError(productID) {
    const productIDList = OMT.payments.getProductIDList();
    const { paymentsReady } = OMT.payments;
    let errorString = `TargetedOffer Error: offer ${productID} not found in catalog!, \n`;
    errorString += `paymentsReady: ${paymentsReady}, \n`;
    errorString += `catalog: ${productIDList.join(', ')}`;
    // G.Utils.SentryLog.logError(errorString, {}, false);
    console.error(errorString);
  }

  /**
   * disabled due to loading delay. call super._setOpenWindowAnimation() when ready.
   */
  _setOpenWindowAnimation() {
    this.alpha = 0;
  }

  /**
   * initialize loading indicator component
   */
  _initLoadingIndicator() {
    this._loadingIndicator = new UI_LoadingIndicatorDisplay();
    this._loadingIndicator.x = LOADING_INDICATOR_X; this._loadingIndicator.y = game.height / 2;
    game.world.addChild(this._loadingIndicator);
  }

  /**
   * get the targeted offer data from the catalog
   * @returns {Object}
   */
  async _getOfferData(productID) {
    const catalog = await OMT.payments.getCatalogAsync();
    if (!catalog || catalog.length === 0) return null;
    const offerData = ShopUtils.getTargetedOfferByID(catalog, productID);
    return offerData;
  }

  /**
   * create the content group for offer layout
   */
  _initContentGroup() {
    this._contentGroup = new Phaser.Group(game);
    this._contentGroup.scale.x = this._contentGroup.scale.y = CONTENT_SCALE;
    this.addChild(this._contentGroup);
  }

  /**
   * show / draw the targeted offer once set
   */
  _showOffer() {
    const rewards = OMT.payments.parseRewards(this._currentDeal);
    const { coins, booster: boosters, infiniteLives: infiniteLivesMins } = rewards;
    const config = this._config;

    this._initContentGroup();
    this._drawBg(config.bg);
    this._drawHeaderGroup(config.header);
    this._drawPlayerCoinbar(config.playerCoinbar);
    if (!this._isLandscape) this._drawRefillText();
    this._drawDealMultiplierGroup(config.multiplier);
    this._drawMainDealGraphic(config.deal.assetsKeys);
    this._drawDealCoinbar(config.dealCoinbar, coins);
    this._initBoosterIcons(boosters, infiniteLivesMins);
    this._updateDealLayout(config.deal.assetsKeys);
    this._initMascotGroup();

    this._initBuyButton(config.buyButton.offsetY);
    this._initCloseButton();

    super._setOpenWindowAnimation();
  }

  /**
   * draw the 9-sliced background
   * @param {Object} bgConfig see TargetedOffer_BackgroundGroup constructor
   */
  _drawBg(bgConfig) {
    this._bg = new TargetedOffer_BackgroundGroup(bgConfig);
    this._contentGroup.addChildAt(this._bg, 0);
  }

  /**
   * Draw the players coinbar. Not part of the deal graphic.
   * @param {Object} coinbarConfig
   * @param {number} coinbarConfig.offsetY
   * @param {number} coinbarConfig.scale (optional)
   */
  _drawPlayerCoinbar(coinbarConfig) {
    this._coinbar = new G.CoinBar(0, coinbarConfig.offsetY, true, 'window_oneTimePopupOffer-coinbarTxt');
    if (coinbarConfig.scale != null) this._coinbar.scale.x = this._coinbar.scale.y = coinbarConfig.scale;
    this._contentGroup.addChild(this._coinbar);
  }

  /**
   * draw the refill text that appears above the coinbar
   */
  _drawRefillText() {
    this._refillText = new TargetedOffer_RefillText();
    this._contentGroup.addChild(this._refillText);
  }

  /**
   * draw / initialize the TargetedOffer_HeaderGroup instance
   * @param {Object} headerConfig
   * @param {number} headerConfig.offsetY
   * @param {number} headerConfig.textOffsetY
   * @param {number} headerConfig.ribbonOffsetY
   * @param {Object} headerConfig.ribbonConfig
   * @param {Object} headerConfig.textConfig
   */
  _drawHeaderGroup(headerConfig) {
    const { offsetY, textOffsetY, ribbonOffsetY, ribbonConfig, textConfig } = headerConfig;
    const headerGroup = new TargetedOffer_HeaderGroup(textOffsetY, ribbonOffsetY, ribbonConfig, textConfig);
    headerGroup.y = this._bg.outerBgY - (this._bg.outerHeight / 2) + offsetY;

    if (this._isLandscape) {
      headerGroup.scale.setTo(1.5);
    }

    this._contentGroup.addChild(headerGroup);
  }

  /**
   * draw / initialize the TargetedOffer_MultiplierGroup instance
   * @param {Object} multiConfig
   * @param {number} multiConfig.offsetX
   * @param {number} multiConfig.offsetY
   * @param {number} multiConfig.scale
   * @param {number} multiConfig.bgScale
   * @param {Object} multiConfig.txt
   * @param {number} multiConfig.txt.textOffsetX
   * @param {number} multiConfig.txt.textOffsetY
   * @param {string} multiConfig.altBgAsset
   */
  _drawDealMultiplierGroup(multiConfig) {
    const { offsetX, offsetY, scale } = multiConfig;
    const multiplier = new TargetedOffer_MultiplierGroup(multiConfig);
    multiplier.x = this._bg.x + (this._bg.outerWidth / 2) + offsetX;
    multiplier.y = this._bg.outerBgY - (this._bg.outerHeight / 2) + offsetY;
    multiplier.scale.set(scale);
    this._contentGroup.addChild(multiplier);
  }

  /**
   * draw the main deal graphic
   * @param {Object} assetsKeys { moneyOnly, fewBoosters, manyBoosters }
   */
  _drawMainDealGraphic(assetsKeys) {
    this._dealGraphic = G.makeImage(0, 0, assetsKeys.manyBoosters, 0.5);
    this._dealGraphic.scale.set(0.9);
    this._contentGroup.addChild(this._dealGraphic);
  }

  /**
   * draw the coinbar inside the deal layout
   * @param {Object} coinbarConfig { scale:number, fontSize:number, tint:number, offsetY:number }
   * @param {number} coins
   */
  _drawDealCoinbar(coinbarConfig, coins) {
    const { scale, fontSize, tint } = coinbarConfig;
    this._dealCoinbar = new TargetedOffer_DealCoinbar(coins, scale, fontSize, tint);
    this._contentGroup.addChild(this._dealCoinbar);
  }

  /**
   * setup booster icons
   * @param {Array.<{item:string, amount:number}>} boosters
   * @param {number} infiniteLivesMins
   */
  _initBoosterIcons(boosters, infiniteLivesMins) {
    this._iconGroup = new Phaser.Group(game);
    this._contentGroup.addChild(this._iconGroup);
    // normal boosters
    for (let i = 0; i < boosters.length; i++) {
      const { item: boosterID, amount: boosterCount } = boosters[i];
      const boosterIcon = new TargetedOffer_BoosterIcon(boosterID, boosterCount);
      boosterIcon.scale.set(BOOSTER_SCALE);
      this._iconGroup.addChild(boosterIcon);
    }
    // unlimited lives booster
    if (infiniteLivesMins > 0) {
      const ulBoosterIcon = new TargetedOffer_ULBoosterIcon(infiniteLivesMins);
      ulBoosterIcon.scale.set(BOOSTER_SCALE);
      this._iconGroup.addChild(ulBoosterIcon);
    }
  }

  /**
   * horizontally align booster icons
   * @param {Phaser.Group} iconGroup
   * @param {nuber} xPos (optional) initial x
   */
  _horizontalAlignBoosterIcons(iconGroup, xPos = 0) {
    for (const icon of iconGroup.children) { icon.x = xPos; xPos += icon.width + BOOSTER_PADDING; }
  }

  /**
   * vertically align booster icons
   * @param {Phaser.Group} iconGroup
   * @param {nuber} yPos (optional) initial y
   */
  _verticalAlignBoosterIcons(iconGroup, yPos = 0) {
    for (const icon of iconGroup.children) { icon.y = yPos; yPos += icon.height + BOOSTER_PADDING; }
  }

  /**
   * get the deal graphic asset key, look for overrides by productID
   * @param {Obejct} assetsKeys dictionary of asset keys
   * @param {Obejct} layoutConfig deal layout config
   * @returns {string}
   */
  _getDealGraphicAssetKey(assetsKeys, layoutConfig) {
    if (assetsKeys[this._offerID] != null) return assetsKeys[this._offerID]; // check if this offerID has a specific asset defined in the assetKeys
    return assetsKeys[layoutConfig.assetKey]; // use default asset from the layoutConfig
  }

  /**
   * update / rearrange deal layout for specific offers
   * @param {Object} assetsKey { moneyOnly, fewBoosters, manyBoosters }
   */
  _updateDealLayout(assetsKeys) {
    const {
      _iconGroup: iconGroup,
      _dealGraphic: dealGraphic,
      _dealCoinbar: dealCoinbar,
    } = this;

    let layoutConfig;
    const layoutConfigForOrientation = this._isLandscape
      ? DEAL_LAYOUT_CONFIGS.horizontal
      : DEAL_LAYOUT_CONFIGS.vertical;

    const innerBGOffset = { // issues ocurred when shifting frames for alternate layouts. so offsets were calculated.
      x: (this._config.bg.innerOffsetX || 0) - (this._baseConfig.bg.innerOffsetX || 0),
      y: (this._config.bg.innerOffsetY || 0) - (this._baseConfig.bg.innerOffsetY || 0),
    };

    if (iconGroup.length === 0) { // LAYOUT COINS ONLY
      layoutConfig = layoutConfigForOrientation.NO_BOOSTERS;
      G.changeTexture(dealGraphic, this._getDealGraphicAssetKey(assetsKeys, layoutConfig));
      dealGraphic.scale.set(layoutConfig.dealGraphic.scale);
      dealGraphic.x = layoutConfig.dealGraphic.offsetX + innerBGOffset.x;
      dealGraphic.y = layoutConfig.dealGraphic.offsetY + innerBGOffset.y;
      dealCoinbar.x = layoutConfig.dealCoinbar.offsetX + innerBGOffset.x;
      dealCoinbar.y = this._bg.innerBgY + (this._bg.innerHeight / 2) - (dealCoinbar.height / 2) + layoutConfig.dealCoinbar.offsetY;
      dealCoinbar.scale.set(layoutConfig.dealCoinbar.scale);
    } else if (!this._isLandscape && iconGroup.children.length === 1) { // LAYOUT SINGLE BOOSTERS FOR PORTRAIT
      layoutConfig = layoutConfigForOrientation.SINGLE_BOOSTERS;
      this._verticalAlignBoosterIcons(iconGroup);
      G.changeTexture(dealGraphic, this._getDealGraphicAssetKey(assetsKeys, layoutConfig));
      dealGraphic.scale.set(layoutConfig.dealGraphic.scale);
      dealGraphic.x = layoutConfig.dealGraphic.offsetX + innerBGOffset.x;
      dealGraphic.y = layoutConfig.dealGraphic.offsetY + innerBGOffset.y;
      dealCoinbar.x = -(iconGroup.width / 2) + layoutConfig.dealCoinbar.offsetX + innerBGOffset.x;
      dealCoinbar.y = dealGraphic.y + (dealGraphic.height / 2) + layoutConfig.dealCoinbar.offsetY;
      dealCoinbar.scale.set(layoutConfig.dealCoinbar.scale);
      iconGroup.x = dealCoinbar.x + (dealCoinbar.width / 2) + layoutConfig.iconGroup.offsetX;
      iconGroup.y = dealCoinbar.y + layoutConfig.iconGroup.offsetY;
    } else if (iconGroup.children.length < 3) { // LAYOUT 1-3 (LANDSCAPE) / 2-3 (PORTRAIT) BOOSTERS
      layoutConfig = iconGroup.children.length === 1
        ? layoutConfigForOrientation.SINGLE_BOOSTERS
        : layoutConfigForOrientation.FEW_BOOSTERS;
      this._verticalAlignBoosterIcons(iconGroup);
      G.changeTexture(dealGraphic, this._getDealGraphicAssetKey(assetsKeys, layoutConfig));
      dealGraphic.scale.set(layoutConfig.dealGraphic.scale);
      dealGraphic.x = layoutConfig.dealGraphic.offsetX + innerBGOffset.x;
      dealGraphic.y = innerBGOffset.y + layoutConfig.dealGraphic.offsetY;
      dealCoinbar.x = layoutConfig.dealCoinbar.offsetX + innerBGOffset.x;
      dealCoinbar.y = this._bg.innerBgY + (this._bg.innerHeight / 2) - (dealCoinbar.height / 2) + layoutConfig.dealCoinbar.offsetY;
      dealCoinbar.scale.set(layoutConfig.dealCoinbar.scale);
      iconGroup.x = this._bg.innerBgX + -(iconGroup.width / 2) + layoutConfig.iconGroup.offsetX;
      iconGroup.y = this._bg.innerBgY + (this._bg.innerHeight / 2) - (iconGroup.height / 2) + layoutConfig.iconGroup.offsetY;

      if (this._isLandscape) {
        iconGroup.scale.setTo(layoutConfig.iconGroup.scale);
      }
    } else { // LAYOUT 3 OR MORE BOOSTERS
      layoutConfig = layoutConfigForOrientation.MANY_BOOSTERS;
      this._verticalAlignBoosterIcons(iconGroup);
      G.changeTexture(dealGraphic, this._getDealGraphicAssetKey(assetsKeys, layoutConfig));
      dealGraphic.scale.set(layoutConfig.dealGraphic.scale);
      dealGraphic.x = layoutConfig.dealGraphic.offsetX + innerBGOffset.x;
      dealGraphic.y = innerBGOffset.y + layoutConfig.dealGraphic.offsetY;
      dealCoinbar.x = layoutConfig.dealCoinbar.offsetX + innerBGOffset.x;
      dealCoinbar.y = this._bg.innerBgY + (this._bg.innerHeight / 2) - (dealCoinbar.height / 2) + layoutConfig.dealCoinbar.offsetY;
      dealCoinbar.scale.set(layoutConfig.dealCoinbar.scale);
      iconGroup.x = this._bg.innerBgX + -(this._bg.innerWidth / 2) + (iconGroup.width / 2) + layoutConfig.iconGroup.offsetX;
      iconGroup.y = this._bg.innerBgY + -(iconGroup.height / 2) + layoutConfig.iconGroup.offsetY;

      if (this._isLandscape) {
        iconGroup.scale.setTo(layoutConfig.iconGroup.scale);
      }
    }
  }

  /**
   * init the group containing the mascot and speechbubble
   */
  _initMascotGroup() {
    const { mascot: mascotConfig, bubble: bubbleConfig } = this._config.exitConfirm;
    this._mascotGroup = new TargetedOffer_MascotGroup(this._bg, mascotConfig, bubbleConfig);
    this._contentGroup.addChild(this._mascotGroup);

    // If there is not enough horizontal space to fit the mascot group,
    // Move it above the window
    if (this._isLandscape) {
      const aspectRatio = game.width / game.height;

      if (aspectRatio < MAX_ASPECT_RATIO) {
        this._mascotGroup.scale.setTo(0.85);
        this._mascotGroup.x = -10;
        this._mascotGroup.y = -110;
      }
    }
  }

  /**
   * create the buy button
   * @param {number} offsetY
   */
  _initBuyButton(offsetY) {
    this._buyButton = new TargetedOffer_BuyButton(this._currentDeal.price);
    this._buyButton.signals.onClick.add(() => { this.onBuyClicked(); });
    this._buyButton.x = this._isLandscape ? BUY_BUTTON_X_HORIZONTAL : BUY_BUTTON_X_VERTICAL;
    this._buyButton.y = this._bg.outerBgY + (this._bg.outerHeight / 2) - (this._buyButton.height / 2) + offsetY;
    this._contentGroup.addChild(this._buyButton);
  }

  /**
   * When the player clicks on the buy button.
   */
  onBuyClicked() {
    if (this._offerID) TargetedOfferDataManager.getInstance().onOfferPurchased(this._offerID);
    this._purchaseItem(this._currentDeal);
  }

  /**
  * tries to purchase an item
  * @param {Object} item to buy
  */
  _purchaseItem(item) {
    ShopUtils.purchaseItem(item, this._buyButton, this._coinbar, true, () => {
      this.closeWindow();
    });
  }

  /**
   * create the clsoe button
   */
  _initCloseButton() {
    const targetConfig = this._isLandscape
      ? CLOSE_BUTTON_CONFIG_HORIZONTAL
      : CLOSE_BUTTON_CONFIG_VERTICAL;

    this.addCloseButton(targetConfig.x, targetConfig.y, null, null, this.onCloseClicked.bind(this));
    this.closeButton.scale.set(targetConfig.scale);
  }

  /**
   * If the close button is hit once, the character appears and asks if they really want to close
   * If it's hit twice, the window closes
   */
  onCloseClicked() {
    if (this._closeClicks === 0) { // 1st click
      this._closeClicks++;
      this._buyButton.disableAnimation();
      if (!this._isLandscape) this._refillText.hide();
      this._mascotGroup.animateIn();
    } else if (this._closeClicks === 1) { // 2nd click
      TargetedOfferDataManager.getInstance().onOfferClosed(this._offerID);
      this.closeWindow();
    }
  }

  /**
   * make sure the loading indicator is destroyed on close
   */
  closeWindow() {
    this._loadingIndicator.destroy();
    super.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.oneTimeTargetedOffer = Window_OneTimeTargetedOffer;
