if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getCoinShopHideAnimation = function getCoinShopHideAnimation() {
  var onComplete = new Phaser.Signal();

  return {
    animate: animate,
    onComplete: onComplete,
  };

  function animate(game, target) {
    var uiContainer = G.Utils.find1stObjectWithName(target, "uiContainer");

    if (uiContainer) {
      game.add.tween(uiContainer)
        .to({y: -2000}, 300, Phaser.Easing.Sinusoidal.In, true)
        .onComplete.addOnce(onComplete.dispatch, onComplete);
    }
  }
};