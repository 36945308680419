/* eslint-disable no-undef */

/**
 * used for cross filling game services for components. This should be used instead of g
 */
export default class ComponentGameHooks {
  /**
   * get a localized string / text
   * @param {string} key english string / localization key
   */
  static getLocalizedString(key) {
    if (OMT && OMT.language) return OMT.language.getText(key);
    return key;
  }

  /**
   * used to transform strings or numbers to proper notation based on
   * @param {string | number} text
   * @returns {string}
   */
  static toLocaleNumber(text) {
    if (OMT && OMT.language) return OMT.language.toLocaleNumber(text);
    return text;
  }

  /**
   * play a sound by soundId
   * @param {string} soundId
   * @param {number} volume
   */
  static playSoundById(soundId, volume = 1) {
    const sound = this.getSoundById(soundId);
    if (sound) sound.play(volume);
  }

  /**
   * get sound instance by id
   * @param {string} soundId
   * @returns {Sound}
   */
  static getSoundById(soundId) {
    if (G.sfx != null && G.sfx[soundId] != null) {
      return G.sfx[soundId];
    }
    return null;
  }
}
