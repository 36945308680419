/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
let _trackerInstance = null;

/**
 * class for tracking economic actions during a Level.
 */
export default class LevelEconomyTracker {
  /**
   * get singleton instance
   */
  static getInstance() {
    if (_trackerInstance === null) {
      _trackerInstance = new LevelEconomyTracker();
    }
    return _trackerInstance;
  }

  /**
   * constructor
   */
  constructor() {
    this._levelStats = null;
    this._initListeners();
  }

  /**
   * init global listeners.
   */
  _initListeners() {
    OMT.ads.signals.onAdSuccess.add(this.onAdSuccess.bind(this));
    OMT.payments.signals.onIAPConsumed.add(this.onIAPSuccess.bind(this));
    G.saveState.signals.onBoosterBought.add(this.onBoosterBought.bind(this));
  }

  /**
   * called when a level is started.
   * @param {number} levelIndex
   */
  onLevelStarted(levelIndex) {
    this._levelStats = {
      levelIndex,
      boosterCoinsSpend: 0,
      extraMovesCoinsSpend: 0,
      IAPs: 0,
      wheelSpins: 0,
      rewardedAdsWatched: 0,
      rewardDoubled: 0,
      zeroStarsLevel: G.saveState.getStars(levelIndex) === 0,
      boosterPurchases: {},
      lossAversionCoinsSpend: 0,
    };
  }

  /**
   * called when a booster is purchased during a level.
   * @param {number} boosterNumber
   * @param {number} price
   */
  onBoosterBought(boosterNumber, price) {
    // pre level boosters should be tracked with popup level number
    const boosterId = G.gift.getBoosterTrackingName(boosterNumber);
    // keep track of cost when moves are low
    if (boosterNumber <= 4) {
      const movesRemaining = G.lvl.moves;
      if (movesRemaining <= 3) {
        this._levelStats.lossAversionCoinsSpend += price;
      }
    }
    // in game boosters
    if (!this._levelStats) return;
    if (!this._levelStats.boosterPurchases[boosterId]) {
      this._levelStats.boosterPurchases[boosterId] = 1;
    } else {
      this._levelStats.boosterPurchases[boosterId]++;
    }
    this._levelStats.boosterCoinsSpend += price;
  }

  /**
   * called when the wheel is spun during a level.
   */
  onWheelSpin() {
    if (!this._levelStats) return;
    this._levelStats.wheelSpins++;
  }

  /**
   * called when a reward is doubled during a level.
   */
  onRewardDoubled() {
    if (!this._levelStats) return;
    this._levelStats.rewardDoubled++;
  }

  /**
   * called when a level is finished to track the stars.
   * @param {number} starsAmount
   */
  finishLevel(starsAmount) {
    // not currently doing anything with this event
  }

  /**
   * called when extra moves are bought
   * @param {number} price
   * @param {number} movesRemaining
   */
  onExtraMovesBought(price, movesRemaining) {
    if (!this._levelStats) return;
    this._levelStats.extraMovesCoinsSpend += price;

    // track purchases when few removes remain
    if (movesRemaining <= 3) {
      this._levelStats.lossAversionCoinsSpend += price;
    }
  }

  /**
   * called when a ad is completed successfully.
   * @param {Ojbect} placement
   */
  onAdSuccess(placement) {
    if (!this._levelStats) return;
    if (placement.rewarded) {
      this._levelStats.rewardedAdsWatched++;
    }
  }

  /**
   * called when a IAP is completed successfully
   * @param {Object} product
   */
  onIAPSuccess(product) {
    if (!this._levelStats) return;
    this._levelStats.IAPs++;
  }

  /**
   * get coins spent for the level
   */
  getLevelCoinsSpent() {
    try {
      return this._levelStats.boosterCoinsSpend + this._levelStats.extraMovesCoinsSpend;
    } catch (e) {
      console.log('ERROR: levelEconomyTracker.getLevelCoinsSpent() variables not defined');
      return 0;
    }
  }

  /**
   * get coins spent in a loss aversion flow for the level
   */
  getLossAversionCoinsSpent() {
    try {
      return this._levelStats.lossAversionCoinsSpend;
    } catch (e) {
      console.log('ERROR: levelEconomyTracker.getLevelCoinsSpent() variables not defined');
      return 0;
    }
  }

  /**
   * get number of wheel spins for the level
   */
  getWheelSpins() {
    try {
      return this._levelStats.wheelSpins;
    } catch (e) {
      console.log('ERROR: levelEconomyTracker.getWheelSpins() variables not defined');
      return 0;
    }
  }

  /**
   * get number of rewarded ads watched for the level
   */
  getRewardedAdsWatched() {
    try {
      return this._levelStats.rewardedAdsWatched;
    } catch (e) {
      console.log('ERROR: levelEconomyTracker.getRewardedAdsWatched() variables not defined');
      return 0;
    }
  }

  /**
   * get number of rewards dobuled from ads for the level
   */
  getRewardDoubled() {
    try {
      return this._levelStats.rewardDoubled;
    } catch (e) {
      console.log('ERROR: levelEconomyTracker.getRewardDoubled() variables not defined');
      return 0;
    }
  }
}

// create global reference for the LevelEconomyTracker
window.LevelEconomyTracker = LevelEconomyTracker;
