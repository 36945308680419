/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import { OMT_UI_AvatarWithFrame } from '../../../OMT_UI/FriendsList/OMT_UI_AvatarWithFrame';
import { BragFriendAvatarRotator } from './BragFriendAvatarRotator';
/**
 * Factory class for creating various brag layouts
 */
export class BragLayoutFactory {
  /**
   * Create solo avatar content
   * @param {Object} overlayConfig config for overlay image
   * @param {Object} playerAvatarConfig config for the players avatar
   * @returns {Phaser.Group}
   */
  createSoloAvatarContent(overlayConfig, playerAvatarConfig) {
    const contentContainer = new Phaser.Group(game);
    const overlayImage = G.makeImage(overlayConfig.x, overlayConfig.y, 'gingy_winSolo', 0.5, contentContainer);

    // create and position the avatar
    const playerAvatar = new OMT_UI_AvatarWithFrame(OMT.envData.settings.user.avatar, playerAvatarConfig.avatarSize, ['avatar_e']);
    contentContainer.addChildAt(playerAvatar, 0);
    playerAvatar.x = overlayImage.x + playerAvatarConfig.x;
    playerAvatar.y = overlayImage.y + playerAvatarConfig.y;
    playerAvatar.angle = playerAvatarConfig.angle;
    playerAvatar.hideFrame();

    return contentContainer;
  }

  /**
   * Init the avatar layout for skipping the tutorial
   * @param {Object} overlayConfig config for overlay image
   * @param {number} imageScale relative scale for level 1-X skip image
   * @param {Object} playerAvatarConfig config for the players avatar
   * @returns {Phaser.Group}
   */
  initSkipTutorialLayout(overlayConfig, imageScale, playerAvatarConfig) {
    const contentContainer = new Phaser.Group(game);
    const overlayImage = G.makeImage(overlayConfig.x, overlayConfig.y, 'tutorial_skip', 0.5, contentContainer);
    overlayImage.scale.set(imageScale);

    // create main player avatar
    const playerAvatar = new OMT_UI_AvatarWithFrame(OMT.envData.settings.user.avatar, playerAvatarConfig.avatarSize, ['avatar_e']);
    contentContainer.addChild(playerAvatar);
    playerAvatar.x = overlayImage.x + playerAvatarConfig.x;
    playerAvatar.y = overlayImage.y + playerAvatarConfig.y;
    playerAvatar.angle = playerAvatarConfig.angle;

    return contentContainer;
  }

  /**
   * Create double avatar layout content
   * @param {Object} overlayConfig config for overlay image
   * @param {Object} playerAvatarConfig config for the players avatar
   * @param {Phaser.DisplayObject} layoutRect
   * @param {Array.<Object>} friends
   * @param {Phaser.Text} bottomBannerField
   * @param {string} bottomBannerText
   * @returns {Phaser.Group}
   */
  initFriendsAvatarContent(overlayConfig, playerAvatarConfig, friendAvatarConfig, friends, bannerField, bannerText) {
    const contentContainer = new Phaser.Group(game);
    // create main image and adjust scale
    const overlayImage = G.makeImage(overlayConfig.x, overlayConfig.y, 'gingy_winFriend', 0.5, contentContainer);

    // create and position the avatar
    const playerAvatar = new OMT_UI_AvatarWithFrame(OMT.envData.settings.user.avatar, playerAvatarConfig.avatarSize, ['avatar_e']);
    contentContainer.addChildAt(playerAvatar, 0);
    playerAvatar.x = overlayImage.x + playerAvatarConfig.x;
    playerAvatar.y = overlayImage.y + playerAvatarConfig.y;
    playerAvatar.angle = playerAvatarConfig.angle;
    playerAvatar.hideFrame();

    // create friend player avatar
    const friendAvatar = new BragFriendAvatarRotator(friends, friendAvatarConfig.avatarSize, bannerField, bannerText);
    contentContainer.addChildAt(friendAvatar, 0);
    friendAvatar.x = overlayImage.x + friendAvatarConfig.x;
    friendAvatar.y = overlayImage.y + friendAvatarConfig.y;
    friendAvatar.angle = friendAvatarConfig.angle;
    friendAvatar.hideFrame();
    friendAvatar.startRotation();

    return contentContainer;
  }
}
