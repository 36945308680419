/* eslint-disable no-use-before-define */
if (typeof G === 'undefined') G = {};

G.StateMachine = function StateMachine(initialState, transitionsMap) {
  // imports
  const utils = G.Utils;

  //

  let currentState = initialState;
  const transitionSignal = new Phaser.Signal();

  return {
    isInState,
    transit,
    transitIfPossible,
    canTransitTo,
    transitionSignal,
  };

  function isInState(state) {
    return currentState === state;
  }

  function canTransitTo(newState) {
    const currentlyPossibleTransitions = transitionsMap[currentState];
    return (
      currentlyPossibleTransitions
      && currentlyPossibleTransitions.indexOf(newState) >= 0
    );
  }

  function transit(newState, optPayload) {
    if (!transitIfPossible(newState, optPayload)) {
      utils.debugError(`Transition not allowed: ${currentState} -> ${newState}`);
    }
  }

  function transitIfPossible(newState, optPayload) {
    if (canTransitTo(newState)) {
      changeState(newState, true, optPayload);
      return true;
    }
    return false;
  }

  function changeState(newState, optDispatch, optPayload) {
    const oldState = currentState;
    currentState = newState;
    if (optDispatch) {
      transitionSignal.dispatch(newState, oldState, optPayload);
    }
  }
};
