import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

export default class TutorialSkipBtn extends OMT_UI_SquareButton {
  constructor() {
    super(530, game.height * 0.9, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Skip'),
        textStyle: {
          style: 'font-white',
          fontSize: 60,
        },
      },
    });

    this.onResizeBinding = G.sb('onScreenResize').add(this.onResize, this);
  }

  onResize() {
    this.y = Math.floor(0.9 * game.height);
  }

  turnOff() {
    this.inputEnabled = false;
    game.add.tween(this)
      .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true)
      .onComplete.add( () => {
        this.destroy();
      });
  }

  destroy() {
    this.onResizeBinding.detach();
    super.destroy();
  }
}
