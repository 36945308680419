import { TournamentCompetitorScoreViewBase } from '../../base/Tournament/TournamentCompetitorScoreViewBase';
import { TournamentCompetitorAvatar } from './TournamentCompetitorAvatar';

const BG_IMAGE = 'vs_competitor_bar';
const BG_ANCHOR = [0.8, 0.5];

const AVATAR_POS = { x: 86, y: 0 };

const RANK_IMAGES = ['competitor_1st_ribbon', 'competitor_2nd_ribbon', 'competitor_3rd_ribbon'];
const RANK_TEXT_POS = { x: 34, y: 4 };
const RANK_TEXT_FONT_SIZE = '34px';
const RANK_IMAGE_POS = { x: 33, y: 0 };
const RANK_ANCHOR = [0.5, 0.5];

const SCORE_POS = { x: 225, y: 4 };
const SCORE_ANCHOR = [0.5, 0.5];

// animateOut() movement settings
const ANIMATE_OUT_MOVE_DURATION = 850;
const ANIMATE_OUT_MOVE_DELAY = 200;
const ANIMATE_OUT_MOVE_X = -20;
const ANIMATE_OUT_MOVE_Y = 200;
const ANIMATE_OUT_MOVE_ANGLE = -50;

// animateOut() fade out settings
const ANIMATE_OUT_FADE_DURATION = 200;
const ANIMATE_OUT_FADE_DELAY = 600;

/**
 * view for individual competitors views during Tournament gameplay
 */
export class TournamentCompetitorScoreView extends TournamentCompetitorScoreViewBase {
  /**
   * Adjusts an offset
   */
  _adjustOffset() {
    this._offsetX = -Math.round(this._bg.width * this._bg.anchor.x);
  }

  /**
   * init the background image and set the offset for the background anchoring
   */
  _initBg() {
    super._initBg();
    this._bg = G.makeImage(0, 0, BG_IMAGE, BG_ANCHOR);
    this.addChild(this._bg);
  }

  /**
   * init the competitor avatar
   */
  _initAvatar() {
    super._initAvatar({
      x: this._offsetX + AVATAR_POS.x,
      y: AVATAR_POS.y,
      Class: TournamentCompetitorAvatar,
    });
  }

  /**
   * set the out transistion
   */
  animateOut() {
    super.animateOut({
      x: this.x - ANIMATE_OUT_MOVE_X,
      y: this.y + ANIMATE_OUT_MOVE_Y,
      angle: this.angle + ANIMATE_OUT_MOVE_ANGLE,
      duration: ANIMATE_OUT_MOVE_DURATION,
      delay: ANIMATE_OUT_MOVE_DELAY,
      fadeDuration: ANIMATE_OUT_FADE_DURATION,
      fadeDelay: ANIMATE_OUT_FADE_DELAY,
    });
  }

  /**
   * init the competitor rank display / field
   */
  _initRankDisplay() {
    super._initRankDisplay({
      rankImages: RANK_IMAGES,
      image: {
        x: this._offsetX + RANK_IMAGE_POS.x,
        y: RANK_IMAGE_POS.y,
      },
      text: {
        x: this._offsetX + RANK_TEXT_POS.x,
        y: RANK_TEXT_POS.y,
        style: {
          style: 'UIGoalPanelPointAmount-tournament',
          fontSize: RANK_TEXT_FONT_SIZE,
        },
      },
      anchor: RANK_ANCHOR,
    });
  }

  /**
   * init the competitor score field
   */
  _initScoreField() {
    super._initScoreField({
      x: this._offsetX + SCORE_POS.x,
      y: SCORE_POS.y,
      style: 'UIGoalPanelPointAmount-tournament',
      anchor: SCORE_ANCHOR,
    });
  }
}
