export const ItemDataType = {
  BOOSTER: 'booster',
  COIN: 'coin',
};

/**
 * data model for PrizeWheel_Item(s)
 */

export default class PrizeWheel_ItemData {
  /**
   * constructor
   * @param {string} label item label string
   * @param {any} rewardData (optional) rewardData type is any as not sure what to expect.
   */
  constructor(label, reward = null, type = ItemDataType.COIN) {
    this._label = label;
    this._reward = reward;
    this._type = type;
  }

  /** @returns {string} get the item label */
  get label() { return this._label; }

  /** @returns {any} get reward data */
  get reward() { return this._reward; }

  /** @returns {Object} gets override config for PrizeWheel_Item DEFAULT_CONFIG */
  get config() { return this._config || {}; }

  /** @param {Object} value sets override config for PrizeWheel_Item DEFAULT_CONFIG */
  set config(value) { this._config = value; }

  /** @returns {ItemDataType} get item type */
  get type() { return this._type; }
}
