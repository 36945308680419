if (typeof G === 'undefined') G = {};
G.UI = G.UI || {};

G.UI.ComponentType = {
  Image: 'image',
  Rect: 'rect',
  Text: 'text',
  Transparency: 'transparency',
  Visibility: 'visibility',
  Input: 'input',
  JSON: 'json',
};
