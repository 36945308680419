/* eslint-disable wrap-iife */
G.Helpers = G.Helpers || {};

G.Helpers.dailyRewardMgr = (function () {
  let config = null;
  let saveStateMgr = null;

  function init(configData) {
    config = configData || config;
    saveStateMgr = G.saveState;
  }

  function daySinceEpoch(time) {
    return Math.floor(time / (24 * 60 * 60 * 1000));
  }

  function getDay(dayIndex) {
    const data = config.days[dayIndex];
    return {
      getGifts() {
        const gifts = G.Utils.clone(data.gifts);
        for (let i = 0; i < gifts[0].length; i++) {
          const prizeName = gifts[0][i];
          if (prizeName === 'booster#5' && G.IAP) {
            gifts[0][i] = 'booster#6';
          }
        }
        return gifts;
      },
      getBoxIconConfig() { return data.boxIcon; },
      isDoubleable() { return config.doubleable; },
      getDayIndex() { return dayIndex; },
    };
  }

  function getWeek() {
    const week = [];
    for (let i = 0; i < 7; i++) {
      week.push(getDay(i));
    }
    return week;
  }

  function getSecToNextDailyGift() {
    if (!saveStateMgr.data.dailyReward) {
      saveStateMgr.data.dailyReward = {};
      saveStateMgr.data.dailyReward.nextDaily = 0;
      saveStateMgr.data.dailyReward.nextDailyMs = 0;
      saveStateMgr.data.dailyReward.currentDay = 0;
    }

    if (!saveStateMgr.data.dailyReward.nextDailyMs) {
      saveStateMgr.data.dailyReward.nextDailyMs = saveStateMgr.data.dailyReward.nextDaily * (24 * 60 * 60 * 1000);
    }

    // returns -1 if nextDailyMs is not set
    return Math.max(Math.floor(saveStateMgr.data.dailyReward.nextDailyMs / 1000) - Date.now(), -1);
  }

  // return days to show
  async function reportVisit() {
    if (!saveStateMgr.data.dailyReward) {
      saveStateMgr.data.dailyReward = {};
      saveStateMgr.data.dailyReward.nextDaily = 0;
      saveStateMgr.data.dailyReward.nextDailyMs = 0;
      saveStateMgr.data.dailyReward.currentDay = 0;
    }

    const currentTime = Date.now();
    const currentDay = daySinceEpoch(currentTime);

    if (currentDay >= saveStateMgr.data.dailyReward.nextDaily) {
      const diff = saveStateMgr.data.dailyReward.nextDaily - currentDay;
      saveStateMgr.data.dailyReward.nextDaily = currentDay + 1;
      saveStateMgr.data.dailyReward.nextDailyMs = currentTime + (24 * 60 * 60 * 1000);
      await OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, 'DailReward', 24 * 60 * 60);

      if (diff === 0) {
        // streak
        saveStateMgr.data.dailyReward.currentDay++;
      } else {
        // streak broken
        saveStateMgr.data.dailyReward.currentDay = 0;
      }
      saveStateMgr.save();
      return getDay(saveStateMgr.data.dailyReward.currentDay % 7);
    }
    // no dialy
    return null;
  }

  return {
    init,
    getDay,
    getWeek,
    getSecToNextDailyGift,
    reportVisit,
  };
})();
