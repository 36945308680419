import { ICON_STATES } from './WorldMapButtonIconStates';

/**
 * config = {
 *  x: number,
 *  y: number
 * }
 */

/**
  * class for DailyChallenge icon / button
  */
export class UI_DailyChallengeIcon extends Phaser.Group {
  /**
   * cosntructor
   * @param {Object} config
   */
  constructor(config) {
    super(game);
    this.state = game.state.getCurrentState();
    this._iconState = null;

    // set unlock state
    const unlockAtLvl = G.featureUnlock.unlockLevels.dailyChallenge;
    this.unlocked = G.saveState.getLastPassedLevelNr() >= unlockAtLvl;

    // create content containers
    this._contentContainer = new Phaser.Group(game);
    this.add(this._contentContainer);
    this._buttonContainer = new Phaser.Group(game);
    this._contentContainer.add(this._buttonContainer);

    // set config and position
    this._config = config || {};
    this.ignorePosition = !(this._config.x || this._config.y);
    if (!this.ignorePosition) {
      this.x = Math.floor(this.x);
      this.y = Math.floor(this.y);
    }

    this._initBackgroundGlow();
    this._initButton();
    this._initLabelText();
    this._initAdIcon();
    this._checkAvailability();

    const { lastLevelData } = this.state;
    if (lastLevelData && lastLevelData.challenge) this._onDailyChallengeComplete(lastLevelData);

    if (!this.ignorePosition) {
      G.sb('onScreenResize').add(this._onResize, this);
      this._onResize();
    }
  }

  /**
   * Used externally
   */
  get icon() {
    return this._button;
  }

  /**
   * init the background rotating glow
   */
  _initBackgroundGlow() {
    this._glow = G.makeImage(0, 0, 'popup_lighht', 0.5);
    this._contentContainer.addChildAt(this._glow, 0);
    this._glow.alpha = 0.5;
    this._glow.scale.setTo(0.7);
    this._glow.blendMode = 1;
  }

  /**
   * init the button and its loop animation
   */
  _initButton() {
    // create the clickable button
    this._button = new G.Button(0, 0, 'btn_daily_challenge', this._onIconClicked, this);
    this._buttonContainer.add(this._button);
  }

  /**
   * create the daily challenge label text
   */
  _initLabelText() {
    // create label text field
    this._labelText = new G.Text(0, 40, OMT.language.getText('Daily Challenge'), 'ui-dailyChallenge', 0.5, 120, 120, true, 'center');
    this._labelText.inputEnabled = true;
    this._labelText.lineSpacing = -20;
    this._labelText.input.useHandCursor = true;
    this._labelText.events.onInputDown.add(this._onIconClicked, this);
    this._labelText.setShadow(0, 0, 'black', 3);
    this._buttonContainer.add(this._labelText);

    // set label text animation
    game.add.tween(this._labelText.scale).to({ x: 0.9, y: 0.9 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  }

  /**
   * Draws the little ad icon next on this
   */
  _initAdIcon() {
    // set button loop animation
    this._button.adIcon = G.makeImage(50, 20, 'video_wheel_menu', 0.5, this._button);
    game.add.tween(this._button.adIcon.scale)
      .to({ x: 1.2, y: 1.2 }, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    this._button.adIcon.angle = -4;
    game.add.tween(this._button.adIcon)
      .to({ angle: 4 }, 2000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  }

  /**
   * main update method
   */
  update() {
    this._glow.angle += G.deltaTime;
    this._checkAvailability();
  }

  /**
   * check availability of the challenge and update the button state
   */
  _checkAvailability() {
    if (this.postFlow) return;

    if (this._iconState !== ICON_STATES.PROMO) {
      if (this.unlocked && (OMT.feature.rewardedAdsAvailable() || G.saveState.isChallengeAvailable())) {
        this._setActiveState();
      } else if (this.visible) { // Don't spam this too much, coming from update
        this._setCooldownState();
      }
    }
  }

  /**
   * set state to cooldown
   */
  _setCooldownState() {
    this._iconState = ICON_STATES.COOLDOWN;
    this._labelText.visible = true;
    this._glow.visible = false;
    this._button.adIcon.visible = false;
    this.visible = false;
  }

  /**
   * set state to active
   */
  _setActiveState() {
    const challengeAvailable = G.saveState.isChallengeAvailable();
    this._iconState = ICON_STATES.ACTIVE;
    this._available = challengeAvailable;
    this._labelText.visible = true;
    this._glow.visible = challengeAvailable;
    this._button.adIcon.visible = !challengeAvailable;
    this.visible = true;
  }

  /**
  * set icon promotion state
  * @param {Function} promoClickedCallback (optional)
  */
  setPromoState(promoClickedCallback = null) {
    this._iconState = ICON_STATES.PROMO;
    this._button.alpha = 1;

    if (promoClickedCallback) this._button.enable();
    else this._button.disable();
    this._promoClickedCallback = promoClickedCallback;

    G.changeTexture(this._button, 'btn_daily_challenge');
  }

  /**
   * called on window resize
   */
  onResize() {
    const center = game.world.bounds.x + Math.floor(game.width * 0.5);
    this.x = center + G.OMTsettings.elements.dailyChallengeIcon.xCenterOffset;
    this.y = G.OMTsettings.elements.dailyChallengeIcon.y;
  }

  /**
   * create the particles batch animation
   * @param {Object} lastLevelData
   */
  _createParticlesBatch(lastLevelData) {
    if (lastLevelData.starImprovement > 0) {
      this._afterLvlPartBatch(lastLevelData.lvlNr, lastLevelData.starImprovement, 'stars');
    }

    if (lastLevelData.reward > 0) {
      this._afterLvlPartBatch(lastLevelData.lvlNr, lastLevelData.reward, 'coins');
    }
  }

  /**
   * more messy particle batch code... i dunno
   * @param {number} lvlNr
   * @param {number} amount
   * @param {string} objType
   */
  _afterLvlPartBatch(lvlNr, amount, objType) {
    // set the coin display to value before level reward was added
    let coinsAdded = 0;
    G.sb('onCoinsChange').dispatch(G.saveState.getCoins() - amount);

    const coins = objType === 'coins';
    const state = game.state.getCurrentState();

    const batch = this.state.uiTargetParticlesBW.createDividedBatch(
      game.world.bounds.x + this.worldPosition.x,
      this.worldPosition.y,
      coins ? 'coin_1' : 'map-nodes/map_star_1',
      coins ? this.state.panel.coinsTxt : this.state.panel.starsTxt,
      amount,
      coins ? 3 : 1,
    );

    batch.addOnPartStart((particle) => {
      if (coins) {
        particle.scale.setTo(0.9);
      } else {
        particle.scale.setTo(1.2);
      }
      particle.speedDelta = 0.5;
      particle.speedMax = 20;
      particle.vel.x = game.rnd.realInRange(-20, 20);
      particle.vel.y = game.rnd.realInRange(-20, 20);
    });

    batch.addOnPartFinish((particle) => {
      G.sfx.pop.play();
      if (coins) {
        coinsAdded += particle.carriedValue;
        // update the coin display, but dont actually add anything
        G.sb('onCoinsChange').dispatch((G.saveState.getCoins() - amount) + coinsAdded);
      } else {
        const { starsTxt } = state.panel;
        starsTxt.setText(parseInt(starsTxt.text) + 1);
      }
    });

    this.batchesWaitingForFinish++;

    batch.onFinish.add(() => {
      this.batchesWaitingForFinish--;
      if (this.batchesWaitingForFinish === 0) {
        // set coin display to the final value
        G.sb('onCoinsChange').dispatch(G.saveState.getCoins());
      }
    }, this);

    batch.start();
  }

  /**
   * called when the button / icon is clicked
   */
  async _onIconClicked() {
    if (this._iconState === ICON_STATES.ACTIVE) {
      game.input.enabled = false;
      await this.showDailyChallengeWindow();
      game.input.enabled = true;
    } else if (this._iconState === ICON_STATES.PROMO) {
      if (this._promoClickedCallback) this._promoClickedCallback();
      this._promoClickedCallback = null;
    }
  }

  /**
   * show the daily challenge window, or alternate windows if not accessible
   */
  showDailyChallengeWindow() {
    // console.log("ANOTHER CONSIDERATION ", this._iconState, OMT.feature.rewardedAdsAvailable());
    if (this._iconState !== ICON_STATES.COOLDOWN) {
      const noLife = G.saveState.getLives() === 0 && G.saveState.getUnlimitedLivesSec() === 0;
      if (OMT.feature.rewardedAdsAvailable() || !noLife) {
        G.sb('pushWindow').dispatch(['dailyChallenge', G.saveState.getDailyChallengeLevel()]);
      } else if (noLife) {
        G.sb('pushWindow').dispatch(['notEnoughLives'], false, G.WindowMgr.LayerNames.AboveHighScorePanel);
      } else { // Just in case
        G.sb('pushWindow').dispatch(['dailyChallenge', G.saveState.getDailyChallengeLevel()]);
      }
    } else if (OMT.feature.rewardedAdsAvailable()) {
      G.sb('pushWindow').dispatch(['dailyChallenge', G.saveState.getDailyChallengeLevel()]);
    }
  }

  /**
   * called when a daily challenge level was just completed
   * @param {Object} lastLevelData
   */
  _onDailyChallengeComplete(lastLevelData) {
    if (lastLevelData && lastLevelData.challenge) {
      this.visible = true;
      this._glow.visible = true;
      this.postFlow = true;
      this._button.inputEnabled = false;
      game.time.events.add(1000, () => {
        game.add.tween(this.scale)
          .to({ x: [0.8, 1], y: [0.8, 1] }, 300, Phaser.Easing.Sinusoidal.Out, true);
        this.batchesWaitingForFinish = 0;
        this._createParticlesBatch(lastLevelData);
        this._button.inputEnabled = true;
      }, this);

      OMT.transactionTracking.logInventoryTransactionBegin();
      OMT.transactionTracking.addInventoryChange('coins', 0, lastLevelData.reward);
      OMT.transactionTracking.logInventoryTransactionEnd();
    }
  }
}
