/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';

/* eslint-disable import/no-unresolved */
import hard_corruption_animation from '../../Animations/villains/superhard-hard_animation';
import hard_corruption_animation_2 from '../../Animations/villains/superhard-hard_animation_2';
import hard_post_corruption_animation from '../../Animations/villains/superhard-hard_animation_4';
import super_hard_corruption_animation from '../../Animations/villains/superhard-super_hard_animation';
import super_hard_corruption_animation_2 from '../../Animations/villains/superhard-super_hard_animation_2';
import super_hard_post_corruption_animation from '../../Animations/villains/superhard-super_hard_animation_4';
import hard_poof_animation from '../../Animations/villains/superhard-hard_animation_3';
import super_hard_poof_animation from '../../Animations/villains/superhard-super_hard_animation_3';
/* eslint-enable import/no-unresolved */

import OMT_StackManager from '../OMT_StackManager';
import OMT_TweenUtils from './OMT_TweenUtils';
import { OMT_SystemInfo, ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

const animations = {
  [OMT_VILLAINS.getPrefixedName(
    'hard_corruption_animation_window',
  )]: hard_corruption_animation,
  [OMT_VILLAINS.getPrefixedName(
    'super_hard_corruption_animation_window',
  )]: super_hard_corruption_animation,
  [OMT_VILLAINS.getPrefixedName(
    'hard_corruption_animation_game',
  )]: hard_corruption_animation_2,
  [OMT_VILLAINS.getPrefixedName(
    'super_hard_corruption_animation_game',
  )]: super_hard_corruption_animation_2,
  [OMT_VILLAINS.getPrefixedName(
    'hard_poof_animation',
  )]: hard_poof_animation,
  [OMT_VILLAINS.getPrefixedName(
    'super_hard_poof_animation',
  )]: super_hard_poof_animation,
  [OMT_VILLAINS.getPrefixedName(
    'hard_post_corruption_animation_game',
  )]: hard_post_corruption_animation,
  [OMT_VILLAINS.getPrefixedName(
    'super_hard_post_corruption_animation_game',
  )]: super_hard_post_corruption_animation,
};

export default class OMT_AnimationFactory {
  /**
   * Run an animation here
   * @param {string} name
   * @param {object} config
   */
  async runAnimation(name, config = {}) {
    this.objects = [];
    const stack = OMT_StackManager.getFreeStack();
    const subStacks = [];
    const animation = animations[name];

    const { parent, localOnly } = config;
    this.localOnly = localOnly;
    if (parent) {
      this.parent = parent;
    } else {
      this.parent = game.state.getCurrentState();
    }

    for (const {
      texture,
      destroyOnComplete,
      landscape,
      transforms,
    } of animation) {
      const _transforms = [...transforms];
      const baseObject = this._createBaseObject(texture);
      this.objects.push(baseObject);
      const subStack = OMT_StackManager.getFreeStack(() => {
        if (destroyOnComplete) {
          this.objects = this.objects.filter((object) => object !== baseObject);
          baseObject.destroy();
        }
      });
      const firstTransform = this._validateTransform(
        _transforms.shift(),
        landscape,
      );
      this._applyTransform(baseObject, firstTransform);
      firstTransform.callback(baseObject, config);

      for (const transform of _transforms) {
        const _transform = this._validateTransform(transform, landscape);
        const { duration, callback, wait } = _transform;

        if (wait) {
          subStack.wait(wait);
          subStack.addEvent(() => {
            callback(baseObject, config);
          });
        } else if (duration <= 0 || duration === undefined) {
          subStack.addEvent(() => {
            this._applyTransform(baseObject, _transform);
            callback(baseObject, config);
          });
        } else {
          subStack.addPromise(() =>
            OMT_TweenUtils.tweenMultiple({
              object: baseObject,
              duration,
              props: {
                ..._transform,
              },
              callback: () => {
                callback(baseObject, config);
              },
            }),
          );
        }
      }

      subStacks.push(subStack);
    }

    if (subStacks.length) {
      stack.addParallel(subStacks);
    }

    return stack.run();
  }

  /**
   * The base object for the animation step
   * @param {any} texture The texture key to create the object
   */
  _createBaseObject(texture) {
    const { parent } = this;
    const baseObject = G.makeImage(0, 0, texture, 0.5, parent);
    return baseObject;
  }

  /**
   * Convert percentage values of x and y to world coordinates and also does some validation
   * @param {any} transform The transform object holding information to convert
   * @param {{baseWidth: number; baseHeight: number;}} landscape Controls if the object position should be fixed for landscape
   */
  _validateTransform(transform, landscape) {
    const _transform = { ...transform };
    let { x, y } = transform;
    const { scale, scaleX, scaleY } = transform;
    const { callback } = transform;
    const { parent, localOnly } = this;

    if (this._varianceObject) {
      this._applyVariance(_transform);
    }

    if (x !== undefined) {
      if (landscape && OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal) {
        const { baseWidth, baseHeight } = landscape;
        if (baseWidth !== undefined && baseHeight !== undefined) {
          const portraitLikeWidth = (baseWidth * game.height * 1.3) / baseHeight;
          const leftBorder = (game.width - portraitLikeWidth) / 2;
          const newXPos = (leftBorder + (portraitLikeWidth * x) / 100) / game.width;
          x = newXPos * 100;
        }
      }
      let _x;
      if (!localOnly) {
        _x = parent.toLocal({
          x: (game.width * x) / 100,
          y: 0,
        }).x;
      } else {
        _x = (parent.width * x) / 100;
      }
      _transform.x = _x;
    }

    if (y !== undefined) {
      if (landscape && OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal) {
        const { baseWidth, baseHeight } = landscape;
        if (baseWidth !== undefined && baseHeight !== undefined) {
          y *= 0.9;
        }
      }
      let _y;
      if (!localOnly) {
        _y = parent.toLocal({
          x: 0,
          y: (game.height * y) / 100,
        }).y;
      } else {
        _y = (parent.height * y) / 100;
      }
      _transform.y = _y;
    }

    if (scale !== undefined) {
      if (landscape && OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal) {
        const { scaleCoeff } = landscape;
        if (scaleCoeff && scaleCoeff.scale) {
          _transform.scale *= scaleCoeff.scale;
        }
      }
    }

    if (scaleX !== undefined) {
      if (landscape && OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal) {
        const { scaleCoeff } = landscape;
        if (scaleCoeff && scaleCoeff.scaleX) {
          _transform.scaleX *= scaleCoeff.scaleX;
        }
      }
    }

    if (scaleY !== undefined) {
      if (landscape && OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal) {
        const { scaleCoeff } = landscape;
        if (scaleCoeff && scaleCoeff.scaleY) {
          _transform.scaleY *= scaleCoeff.scaleY;
        }
      }
    }

    if (!callback) {
      _transform.callback = () => Promise.resolve();
    }

    return _transform;
  }

  /**
   * Instantly set a transform to the object
   * @param {any} object The object to set the transform to
   * @param {any} transform The transform object holding information for the initial transform
   */
  _applyTransform(object, transform) {
    const { alpha, angle, x, y, scale, scaleX, scaleY } = transform;

    if (alpha !== undefined) {
      object.alpha = alpha;
    }
    if (angle !== undefined) {
      object.angle = angle;
    }
    if (x !== undefined) {
      object.x = x;
    }
    if (y !== undefined) {
      object.y = y;
    }
    if (scale !== undefined) {
      object.scale.setTo(scale);
    }
    if (scaleX !== undefined) {
      object.scale.x = scaleX;
    }
    if (scaleY !== undefined) {
      object.scale.y = scaleY;
    }
  }

  /**
   * Apply variance to parameters in a transform
   * @param {object} transform
   */
  _applyVariance(transform) {
    const { _varianceObject } = this;

    for (const [key, value] of Object.entries(_varianceObject)) {
      const _value = transform[key];
      if (_value) {
        transform[key] = _value * (1 - value) + Math.random() * _value * value * 2;
      }
    }
  }

  /**
   * Add variance to selected transform properties of this animation
   * @param {object} variance
   */
  addVariance(variance) {
    this._varianceObject = variance;
  }

  /**
   * Animate out each object registered to this animation
   */
  async hide() {
    const { objects } = this;
    const stack = OMT_StackManager.getFreeStack();
    const subStacks = [];

    objects.forEach((object) => {
      const subStack = OMT_StackManager.getFreeStack();

      subStack.addPromise(() =>
        OMT_TweenUtils.changeAlphaTo({
          object,
          alpha: 0,
          duration: 500,
        }),
      );

      subStacks.push(subStack);
    });

    if (subStacks.length) {
      stack.addParallel(subStacks);
    }

    return stack.run();
  }

  /**
   * Animate in each object registered to this animation
   */
  async show() {
    const { objects } = this;
    const stack = OMT_StackManager.getFreeStack();
    const subStacks = [];

    objects.forEach((object) => {
      const subStack = OMT_StackManager.getFreeStack();

      subStack.addPromise(() =>
        OMT_TweenUtils.changeAlphaTo({
          object,
          alpha: 1,
          duration: 500,
        }),
      );

      subStacks.push(subStack);
    });

    if (subStacks.length) {
      stack.addParallel(subStacks);
    }

    return stack.run();
  }
}
