import { MILLISECONDS_IN_SEC } from '@omt-components/Utils/TimeUtil';
import { SpecialEvent_LeaderboardUserView } from './SpecialEvent_LeaderboardUserView';

export const SPECIALEVENT_LEADERBOARD_VIEWMODE = {
  NORMAL: 0,
  PLACEMENT: 1,
  ENDING: 2,
};

const DEFAULT_LINE_SPACING = 75;

const ANIMATE_IN_DELAY = 200;
const ANIMATE_IN_DELAY_INCREMENT = 150;
const ANIMATE_IN_DURATION = 400;
const ANIMATE_OUT_DURATION = 500;
const SCALE_IN_FROM = 0.8;
const SCALE_IN_DURATION = 1100;

/**
 * class for tokenEvent event leaderboard panel
 */
export class SpecialEvent_LeaderboardUserListPanel extends Phaser.Sprite {
  /**
   * constructor
   * @param {{offsetX: number, topOffset: number, additionalDelay: number, lineSpacing: number, userView: Object, userViewDivider: Object}} config
   * @param {SPECIALEVENT_LEADERBOARD_VIEWMODE} viewMode
   */
  constructor(config, viewMode) {
    super(game);

    this._leaderboardEntries = null;
    this._config = config;
    this._viewMode = viewMode;
    this._userViews = [];
    this._tweens = [];
    this._limit = Infinity;
    if (this._viewMode !== SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL) {
      this._placementData = {
        playerObj: null,
        usersAfterPlayer: [],
      };
    }
  }

  /**
   * Returns the line spacing, but it does the checking for you
   * @returns {number}
   */
  get _lineSpacing() {
    return this._config.lineSpacing || DEFAULT_LINE_SPACING;
  }

  /**
   * init user view lines
   * @param {Array.<Object>} leaderboardEntries
   */
  initUserViews(leaderboardEntries) {
    for (const entryData of leaderboardEntries) {
      this._createUserViewInstance(entryData, this._config.userView);
    }
    if (this._placementData && this._placementData.playerObj) {
      this._placementData.playerObj.pack.toggleCache(false);
      const targetUser = this._userViews[this._userViews.length - 1]; // It was just you in the leaderboard...
      if (targetUser) {
        this._placementData.playerObj.fakeRank = targetUser.rank;
        this._placementData.playerObj.pack.updateRank(this._placementData.playerObj.fakeRank);
        this._placementData.playerObj.pack.updateScore(0);
        for (const player of this._placementData.usersAfterPlayer) {
          if (player.rank > this._placementData.playerObj.rank && player.rank <= this._placementData.playerObj.fakeRank) {
            player.updateRank(player.rank - 1); // Rank lowered to make the leaderboard look more seamless before smushing the player in
          } else {
            player.y += this._lineSpacing;
          }
        }
        this._placementData.playerObj.pack.y = targetUser.y + this._lineSpacing;
      }
      this.addChild(this._placementData.playerObj.pack);
      this._userViews.push(this._placementData.playerObj.pack);
      this._userViews.sort((a, b) => a.rank - b.rank);
    }
    this._leaderboardEntries = leaderboardEntries;
  }

  /**
   * create a user view instance
   * @param {Object} entryData
   * @param {Object} userViewConfig
   * @returns {SpecialEvent_LeaderboardUserView}
   */
  _createUserViewInstance(entryData, userViewConfig) {
    const UserViewClass = userViewConfig.class || SpecialEvent_LeaderboardUserView;
    const userView = new UserViewClass(entryData, userViewConfig);
    const lineSpacing = this._lineSpacing;
    const targetY = Math.round(lineSpacing * this._userViews.length);
    if (userView.isPlayer && this._placementData) {
      this._placementData.playerObj = {
        y: targetY,
        pack: userView,
        rank: userView.rank,
        fakeRank: userView.rank, // Will be figured out later in flow, in initUserViews()
        score: entryData.score,
      };
    } else {
      userView.y = targetY;
      if (this._placementData && this._placementData.playerObj) {
        this._placementData.usersAfterPlayer.push(userView);
        userView.toggleCache(false);
      }
      this.addChild(userView);
      this._userViews.push(userView);
    }
    return userView;
  }

  /**
   * insert a horizontal divider graphic at the specified index
   * @param {Number} index
   * @param {Phaser.DisplayObject} dividerInstance
   * @param {Number} paddingTop
   * @param {Number} paddingBottom
   */
  insertHorizontalDivider(index, dividerInstance, paddingTop, paddingBottom) {
    if (index === 0 || this._userViews.length < index + 1) return;
    const userView = this._userViews[index];
    const lineSpacing = this._lineSpacing;
    userView.insertHorizontalDivider(dividerInstance, lineSpacing, paddingTop, paddingBottom);
  }

  /**
   * animate in user views
   * @param {Function} onComplete called on animation complete
   */
  animateIn(onComplete = null, startY = 0, visibleHeight = 0) {
    const additionalDelay = this._config.additionalDelay || 0;
    let delay = ANIMATE_IN_DELAY + additionalDelay;
    let lastTween;
    for (const userView of this._userViews) {
      const targetY = startY + userView.y + userView.height;
      if ((targetY <= 0 || targetY >= visibleHeight + userView.height)) {
        // console.log('notShowing', userView.rank);
        userView.alpha = 1;
        userView.scale.y = 1;
        continue;
      }
      userView.alpha = 0;
      userView.scale.y = SCALE_IN_FROM;
      // scale
      const scaleTween = game.add.tween(userView.scale).to({ y: 1 }, SCALE_IN_DURATION, Phaser.Easing.Elastic.Out, true, delay);
      // alpha / position in
      const tween = lastTween = game.add.tween(userView).to({ alpha: 1 }, ANIMATE_IN_DURATION, Phaser.Easing.Quadratic.Out, true, delay);
      // increment animation delay
      delay += ANIMATE_IN_DELAY_INCREMENT;
      this._tweens.push(tween, scaleTween);
    }
    // add callback if last line
    if (onComplete && lastTween) {
      lastTween.onComplete.addOnce(onComplete);
    }
    // nothing to show
    if (this._userViews.length === 0 && onComplete != null) onComplete();
  }

  /**
   * animate out panel
   * @param {Function} onComplete called on animation complete
   */
  animateOut(onComplete = null) {
    const tween = game.add.tween(this).to({ alpha: 0 }, ANIMATE_OUT_DURATION, Phaser.Easing.Quadratic.Out, true);
    if (onComplete) tween.onComplete.addOnce(onComplete);
  }

  /**
   * Animates the player to the target rank
   * Could probably use that stack manager but... not sure how to use it! :D
   * @param {Function} onComplete called on animation complete
   */
  animatePlayerToPlacement(onComplete, alongWithMainTween) {
    if (this._placementData.playerObj) {
      if (this._placementData.playerObj.fakeRank === this._placementData.playerObj.rank) { // If the fake rank ends up being your real rank anyways...
        if (onComplete) {
          onComplete();
        }
        return;
      }
      // Set up the tween for the player going into ranking spot
      const rankObj = {
        rank: this._placementData.playerObj.fakeRank,
        score: 0,
      };
      const delayedTweens = [];
      const rankDifference = Math.abs(this._placementData.playerObj.fakeRank - this._placementData.playerObj.rank);
      const userTargetEase = this._determineTweenEasing(rankDifference);
      const animationTime = Math.max(MILLISECONDS_IN_SEC, MILLISECONDS_IN_SEC * (rankDifference / 10));
      const mainPlayerTween = game.add.tween(this._placementData.playerObj.pack).to({ y: this._placementData.playerObj.y }, animationTime, userTargetEase, false);
      const rankTween = game.add.tween(rankObj).to({
        rank: this._placementData.playerObj.rank, score: this._placementData.playerObj.score,
      }, animationTime, userTargetEase, false);
      const triggerUserTween = () => { // Triggers user tween to move. Initialized way below
        const targetTweens = delayedTweens.filter((twData) => twData.user.rank >= this._placementData.playerObj.pack.rank);
        for (const userData of targetTweens) {
          delayedTweens.splice(delayedTweens.indexOf(userData), 1);
          userData.tween.start();
          userData.user.updateRank(Number.parseInt(userData.user.rank, 10) + 1);
        }
      };
      const updateFunc = () => { // Changes the text on the player rank
        const floorRank = Math.round(rankObj.rank);
        if (this._placementData.playerObj.pack.rank !== floorRank) {
          this._placementData.playerObj.pack.updateRank(floorRank);
          G.sfx.pop.play();
          triggerUserTween(); // Triggers the user tween to move when it comes by
        }

        const floorScore = Math.round(rankObj.score);
        if (this._placementData.playerObj.pack.score !== floorScore) {
          this._placementData.playerObj.pack.updateScore(floorScore);
        }
      };
      rankTween.onUpdateCallback(updateFunc.bind(this));
      rankTween.onComplete.add(updateFunc.bind(this));
      rankTween.onComplete.add(() => {
        triggerUserTween(); // Triggers the last user tween to move
        this._placementData.playerObj.pack.toggleCache(true);
      });
      if (onComplete) mainPlayerTween.onComplete.addOnce(onComplete);

      // Set up the other users before the player's ranking spot. They must move down
      const lineSpacing = this._lineSpacing;
      for (let i = 0; i < this._placementData.usersAfterPlayer.length; i++) {
        const user = this._placementData.usersAfterPlayer[i];
        const targetY = Math.round(user.y + lineSpacing);
        const userTween = game.add.tween(user).to({ y: targetY }, 100 * Math.max(1, ((user.rank * 0.75) / rankDifference)), Phaser.Easing.Sinusoidal.InOut, false);
        userTween.onComplete.add(() => {
          user.toggleCache(true);
        });
        delayedTweens.push({ tween: userTween, user });
      }

      if (alongWithMainTween) {
        const withMainTween = () => {
          alongWithMainTween(this._placementData.playerObj.pack.y);
        };
        mainPlayerTween.onUpdateCallback(withMainTween.bind(this));
        mainPlayerTween.onComplete.add(withMainTween.bind(this));
      }
      mainPlayerTween.start();
      rankTween.start();
    }
  }

  /**
   * Determines the easing used for animating the user in placement.
   * @param {number} difference
   * @returns {Phaser.Easing}
   */
  _determineTweenEasing(difference) {
    if (difference < 10) {
      return Phaser.Easing.Cubic.InOut;
    }
    if (difference < 40) {
      return Phaser.Easing.Quadratic.InOut;
    }
    return Phaser.Easing.Quartic.InOut;
  }

  /**
   * get list of set leaderboard entries
   * @returns {Array.<Object>}
   */
  get leaderboardEntries() {
    return this._leaderboardEntries;
  }

  /**
   * Returns the user's rank
   * @returns {{score:number, rank:number}}
   */
  get userData() {
    if (this._placementData) {
      return this._placementData.playerObj;
    }
    return { score: -1, rank: 50 };
  }

  /**
   * destruction method
   */
  destroy() {
    for (const tween of this._tweens) game.tweens.remove(tween);
    this._tweens.length = 0;
    super.destroy();
  }
}
