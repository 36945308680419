G.gift = (function () {

  var giftsDataConfig = null;
  var saveStateMgr = null;

  var GiftContentType = {
    Coins: 'coin',
    Life: "life",
    UnlimitedLife: 'lifeUnlimited',
    RandomBooster: 'booster#R',
    RandomPreLevelBooster: 'booster#RPreLevel',
    RandomInGameBooster: 'booster#RInGame',
    BoosterSwap: 'booster#1',
    BoosterRemove: 'booster#2',
    BoosterHorizontal: 'booster#3',
    BoosterVertical: 'booster#4',
    StartBoosterDefaultMoves: 'booster#5',
    StartBoosterDefaultMovesInstant: 'booster!mapExtraMoves',
    StartBoosterSecondaryMoves: 'booster#6',
    StartBoosterSecondaryMovesInstant: 'booster!levelExtraMoves',
    StartBooster1MoveInstant: 'booster!oneExtraMove',
    StartBooster2MovesInstant: 'booster!twoExtraMoves',
    StartBoosterLines: 'booster#7',
    StartBoosterSpiralBomb: 'booster#8',
  };

  var ItemType = {
    Reward: 'Reward',
    GetCoinsSpin: 'GetCoinsSpin',
  };

  var ItemId = {
    ContextSwitch: 'ContextSwitch',
    DailyReward: 'DailyReward',
    ShareBooster: 'ShareBooster',
    Map: 'Map',
    InGame: 'InGame',
  };

  var Boosters = [];

  var BoostersInGame = [];

  var BoostersPreLevel = [];

  return {
    ItemType: ItemType,
    ItemId: ItemId,
    GiftContentType: GiftContentType,

    init: init,
    getGift: getGift,
    getLabelString: getLabelString,
    getIcon: getIcon,
    getIconForType: getIconForType,
    applyGift: applyGift,
    getGiftTotalCoinValue: getGiftTotalCoinValue,
    processGift: processGift,
    getMovesFromBooster: getMovesFromBooster,
    getExtraMoveBoosterIdList: getExtraMoveBoosterIdList,
    getBoosterTrackingName: getBoosterTrackingName,
    getAvailablePreLevelBoosters: getAvailableRandomPreLevelBoosters,
    getAvailableInGameBoosters: getAvailableRandomInGameBoosters,
    getRequestHelpMessengerGifts: getRequestHelpMessengerGifts,
    getRequestHelpSenderGifts: getRequestHelpSenderGifts,
    getMapChestFormulaGifts: getMapChestFormulaGifts,
    getUnlimitedLivesTimeLabel: getUnlimitedLivesTimeLabel,
  }

  function init(data) {
    giftsDataConfig = data || giftsDataConfig;

    Boosters = data.giftsGroups.Boosters;
    BoostersInGame = data.giftsGroups.BoostersInGame;
    BoostersPreLevel = data.giftsGroups.BoostersPreLevel;

    RequestHelpSender = data.requestHelp.option1;
    RequestHelpMessenger = data.requestHelp.messenger;

    MapChestFormulaGifts = data.mapChestGiftPrizeTable;

    saveStateMgr = G.saveState;
  };


  function getGift(giftsGroup) {
    var giftsGroup = giftsGroup || 'normals';
    var giftsObj = G.Utils.defined(giftsDataConfig[giftsGroup], giftsDataConfig.normals);

    giftsObj = G.Utils.clone(giftsObj);

    var possibleGifts = giftsObj.list.map(function (gift) {
      var processedGift = processGift(gift, true);
      if (processedGift) {
        // add weight
        processedGift[2] = processedGift[2] || 1;
        return processedGift;
      } else {
        return null;
      }
    }).filter(function (gift) {
      return gift;
    });

    if (possibleGifts.length === 0) {
      return giftsDataConfig.fallbackGift;
    }

    return G.Utils.getRandomWeightedElement(
      possibleGifts,
      function (gift) {
        return gift[2];
      }
    );
  };

  function getLabelString(giftData, imgScale) {
    if (Array.isArray(giftData[0])) {
      return giftData
        .map(function (gift) {
          return getLabelString(gift, imgScale);
        })
        .join(" ");
    } else {
      var icon = getIcon(giftData);
      var imgScale = imgScale ? '*' + imgScale + '*' : '';
      var imgTag = "@" + imgScale + icon + "@";

      switch (giftData[0]) {
        case "coin":
          return `${OMT.language.toLocaleNumber(giftData[1])}${imgTag}`;
        case "lifeUnlimited":
          var time = getUnlimitedLivesTimeLabel(giftData);
          return time + imgTag;
        default:
          return giftData[1] + "x" + imgTag;
      }
    }
  };

  function getUnlimitedLivesTimeLabel(giftData) {
    var time;
    var hours = Math.floor(giftData[1] / 60);
    var min = Math.floor(giftData[1] % 60);
    if (hours && min) {
      time = hours + "h " + min + "min";
    } else if (hours) {
      time = hours + "h";
    } else {
      time = min + "min";
    }
    return time;
  };

  function applyGift(giftData, skipSave, optItemType, optItemId) {
    if (Array.isArray(giftData[0])) {
      giftData.forEach((gift) => {
        applyGift(gift, true, optItemType, optItemId);
      });
      saveStateMgr.save();
    } else {

      giftData[1] = parseInt(giftData[1]); // force to integeer

      switch (giftData[0]) {
        case "coin":
          saveStateMgr.changeCoins(giftData[1], skipSave);
          OMT.transactionTracking.addInventoryChange('coins', 0, giftData[1]);
          break;
        case "life":
          saveStateMgr.addLife(giftData[1], skipSave);
          break;
        case "lifeUnlimited":
          saveStateMgr.addUnlimitedLivesTimeMin(giftData[1], skipSave);
          break;
        case "booster":
        default:
          saveStateMgr.changeBoosterAmount(parseInt(giftData[0][8]), giftData[1]);

          const inGameBoosterId = parseInt(giftData[0][8]);

          if (inGameBoosterId) {
            OMT.transactionTracking.addInventoryChange('boostersReceived', inGameBoosterId, giftData[1]);
            const boosterTrackName = getBoosterTrackingName(inGameBoosterId);

            OMT.platformTracking.logEvent(OMT.platformTracking.Events.BoosterIn, giftData[1], {
              booster_id: boosterTrackName,
              source: 'InGame',
              sum: giftData[1],
            });
          }
      }
    }
  };


  // get the total coin value of a gift
  function getGiftTotalCoinValue(giftData) {

    let giftDataList = !Array.isArray(giftData[0]) ? [giftData] : giftData;
    let totalCoinValue = 0;

    for (let i = 0; i < giftDataList.length; i++) {

      giftData = giftDataList[i];

      if (giftData[0] == "coin") {
        totalCoinValue += parseInt(giftData[1]);
      } else if (giftData[0].indexOf("booster") == 0) {
        let boosterNum = parseInt(giftData[0].split("#")[1]);
        totalCoinValue += parseInt(G.json.settings['priceOfBooster' + boosterNum]);
      }
    }
    return totalCoinValue;
  }

  function isBoosterUnlocked(boosterId) {
    if (boosterId.length < 9) {
      // G.Utils.SentryLog.logError("BoosterID invalid!", {});
      return;
    }

    // for fail flow
    if (boosterId === GiftContentType.StartBoosterSecondaryMoves) return true;

    return saveStateMgr.isBoosterUnlocked(parseInt(boosterId[8]));
  }

  function getIcon(giftData) {
    let icon = giftsDataConfig.icons[giftData[0]];
    // show correct extra moves type
    let prevIcon = icon;
    if (G.IAP && icon === 'ui_booster_5') icon = 'ui_booster_6';
    else if (!G.IAP && icon === 'ui_booster_6') icon = 'ui_booster_5';
    // console.log(prevIcon + ' --> ' + icon);
    return icon;
  }

  function getIconForType(type) {
    return giftsDataConfig.icons[type];
  }

  function getAvailableRandomBoosters() {
    return Boosters.filter(function (boosterId) {
      return isBoosterUnlocked(boosterId);
    });
  }

  function getAvailableRandomPreLevelBoosters() {
    return BoostersPreLevel.filter(function (boosterId) {
      return isBoosterUnlocked(boosterId);
    });
  }

  function getAvailableRandomInGameBoosters() {
    return BoostersInGame.filter(function (boosterId) {
      return isBoosterUnlocked(boosterId);
    });
  }

  function processGift(gift, optDontDoFallback) {
    if (isGiftBooster(gift)) {
      var result = processBoosterGift(gift);
      if (!result && optDontDoFallback) {
        return null;
      }

      if (result) {
        gift[0] = result[0];
        gift[1] = result[1];
      } else {
        gift[0] = giftsDataConfig.fallbackGift[0];
        gift[1] = giftsDataConfig.fallbackGift[1];
      }
    }

    return gift;
  }

  function processBoosterGift(gift) {
    var result = null;
    if (isGiftRandomBooster(gift)) {
      result = processRandomBoosterGift(gift);
    } else {
      if (isBoosterUnlocked(gift[0])) {
        result = gift;
      } else {
        result = processLockedBoosterGift(gift);
      }
    }

    return result;
  }

  function isGiftBooster(gift) {
    return G.GiftUI.HelperFunctions.getGiftTypeFromString(gift[0]) === G.GiftUI.Constants.GiftTypes.Booster;
  }

  function isGiftRandomBooster(gift) {
    return gift === GiftContentType.RandomBooster
      || gift === GiftContentType.RandomInGameBooster
      || gift === GiftContentType.RandomPreLevelBooster;
  }

  function processLockedBoosterGift(gift) {
    var result = null;

    if (BoostersInGame.indexOf(gift[0]) !== -1) {
      gift[0] = GiftContentType.RandomInGameBooster;
    } else if (BoostersPreLevel.indexOf(gift[0]) !== -1) {
      gift[0] = GiftContentType.RandomPreLevelBooster;
    }
    result = processRandomBoosterGift(gift);

    return result;
  }

  function processRandomBoosterGift(gift) {
    var newBoosterType = null;

    var booster = game.rnd.pick(getAvailableRandomBoosters());
    var inGameBooster = game.rnd.pick(getAvailableRandomInGameBoosters());
    var preLevelBooster = game.rnd.pick(getAvailableRandomPreLevelBoosters());

    switch (gift[0]) {
      case GiftContentType.RandomBooster:
        newBoosterType = booster;
        break;
      case GiftContentType.RandomInGameBooster:
        newBoosterType = inGameBooster || booster;
        break;
      case GiftContentType.RandomPreLevelBooster:
        newBoosterType = preLevelBooster || booster;
        break;
      default:
        // not a booster
        return gift;
    }
    if (newBoosterType) {
      return [newBoosterType, gift[1]];
    } else {
      return null;
    }
  }

  function getMovesFromBooster(boosterType) {
    if (boosterType === GiftContentType.StartBoosterDefaultMoves || boosterType === GiftContentType.StartBoosterDefaultMovesInstant) {
      return 5;
    } else if (boosterType === GiftContentType.StartBoosterSecondaryMoves || boosterType === GiftContentType.StartBoosterSecondaryMovesInstant) {
      return 3;
    } else if (boosterType === GiftContentType.StartBooster1MoveInstant) {
      return 1;
    } else if (boosterType == GiftContentType.StartBooster2MovesInstant) {
      return 2;
    }
  }

  function getBoosterTrackingName(booster) {
    var nr;
    if (typeof booster === "number") {
      nr = booster;
    } else if (typeof booster === "string") {
      nr = parseInt(booster[8])
    }

    return [null, 'SWAP', 'REMOVE', 'HORIZONTAL', 'VERTICAL', 'MOVES', 'MOVESSPEC', 'VERHOR', 'COLOR'][nr] || null;
  }

  function getExtraMoveBoosterIdList() {
    return [
      GiftContentType.StartBoosterDefaultMoves,
      GiftContentType.StartBoosterDefaultMovesInstant,
      GiftContentType.StartBoosterSecondaryMoves,
      GiftContentType.StartBoosterSecondaryMovesInstant,
      GiftContentType.StartBooster1MoveInstant,
      GiftContentType.StartBooster2MovesInstant,
    ];
  }

  function getRequestHelpSenderGifts() {
    return RequestHelpSender;
  }

  function getRequestHelpMessengerGifts() {
    return RequestHelpMessenger;
  }

  function getMapChestFormulaGifts() {
    return MapChestFormulaGifts;
  }

})();