/* eslint-disable operator-linebreak */
G.Input = G.Input || {};
G.Input.createCustomInputClickHandler = () => {
  // imports
  const { EventType } = G.Input;
  const { StateMachine } = G;

  const signals = {
    down: new Phaser.Signal(),
    click: new Phaser.Signal(),
    cancel: new Phaser.Signal(),
  };

  const State = {
    Idle: 'idle',
    Down: 'down',
    Cancel: 'cancel',
    Confirm: 'confirm',
  };

  const doCreateStateMachine = () => {
    const transitions = {};
    transitions[State.Idle] = [State.Down];
    transitions[State.Down] = [State.Cancel, State.Confirm];
    transitions[State.Cancel] = [State.Idle];
    transitions[State.Confirm] = [State.Idle];

    const stateMachine = new StateMachine(State.Idle, transitions);

    stateMachine.transitionSignal.add((newState) => {
      switch (newState) {
        case State.Idle:
          break;

        case State.Down:
          signals.down.dispatch();
          break;

        case State.Cancel:
          stateMachine.transit(State.Idle);
          signals.cancel.dispatch();
          break;

        case State.Confirm:
          stateMachine.transit(State.Idle);
          signals.click.dispatch();
          break;

        default:
          break;
      }
    });

    return stateMachine;
  };

  const stateMachine = doCreateStateMachine();

  const handleEventShared = (eventType) => {
    if (eventType === EventType.InputDown) {
      return stateMachine.transitIfPossible(State.Down);
    }
    if (eventType === EventType.DragStart || eventType === EventType.InputOut) {
      stateMachine.transitIfPossible(State.Cancel);
      return false;
    }

    return (
      eventType === EventType.InputUp &&
      stateMachine.canTransitTo(State.Confirm)
    );
  };

  const handleEventExclusive = (eventType) => {
    if (
      eventType === EventType.InputUp &&
      stateMachine.canTransitTo(State.Confirm)
    ) {
      stateMachine.transit(State.Confirm);
      return true;
    }
    return false;
  };

  const cancelEvent = () => {
    stateMachine.transitIfPossible(State.Cancel);
  };

  const customInput = {
    // custom input handler interface
    handleEventShared,
    handleEventExclusive,
    cancelEvent,

    // custom
    downSignal: signals.down,
    clickSignal: signals.click,
    cancelSignal: signals.cancel,
  };

  return customInput;
};
