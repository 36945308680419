import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { Window_LevelFailed } from '../Windows/Window_levelFailed';
import THProgressBar from './THProgressBar';

/**
 * Level failed window for special event levels
 */
class Window_TreasureHuntFailed extends Window_LevelFailed {
  constructor(parent) {
    super(parent);
    this._initProgressBar();
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._progressBar) {
      this._progressBar.destroy();
      this._progressBar = null;
    }
    super.destroy();
  }

  /**
   * init window layout
   */
  _initLayout() {
    // background
    this.addBackground('popup_background_treasureHunt');

    this._banner = G.makeImage(
      0, -265,
      'treasureHunt_banner',
      0.5,
      this,
    );

    // level title text
    this.levelTxt = new G.Text(0, -267, OMT.language.getText('Treasure Hunt Level'), {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, 330, 125, true, 'center');
    this.add(this.levelTxt);

    // level goal
    if (G.lvl.goalManager.isPointBased()) {
      this.add(new G.Text(0, 100, `${OMT.language.getText('points').toUpperCase()}:\n${G.lvl.points}/${G.lvl.goalManager.getPointTarget()}`,
        'treasureHunt-failGoalPointStyle', 0.5, 380));
    } else {
      this._makeLevelFailedTaskCollectPanels(95, 'treasureHunt_taskBox');
    }
  }

  /**
   * init window buttons
   */
  _initButtons() {
    // close button
    this.closeButton = new G.Button(250, -270, 'btn_x', () => {
      this.state.endPointCheckForAds(() => {
        G.sb('onStateChange').dispatch('World', undefined, () => {
          G.saveState.disableDiscount(); // Discount?
        });
      });
    }, this);
    this.registerButtons(this.closeButton);

    // retry buttons
    this.retryBtn = new OMT_UI_SquareButton(5, 310, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText('Retry'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: this._onRetryClick.bind(this),
        },
      },
    });
    this.registerButtons(this.retryBtn);
  }

  /**
   * When Retry is clicked
   */
  _onRetryClick() {
    this.closeWindow();
    this.state.endPointCheckForAds(() => {
      const layerName = game.state.current === 'World' ? G.WindowMgr.LayerNames.AboveHighScorePanel : G.WindowMgr.LayerNames.Base;
      G.sb('pushWindow').dispatch('treasureHuntLevel', false, layerName);
    });
  }

  /**
   * display event progress
   */
  _initProgressBar() {
    this._progressBar = new THProgressBar(G.saveState.treasureHuntManager.currentTokens, false);
    this._progressBar.y = -50;
    this.add(this._progressBar);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.treasureHuntFailed = Window_TreasureHuntFailed;
