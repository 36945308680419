import { CollapsedViewBase } from '../Components/CollapsedView/CollapsedViewBase';
import { LevelLeaderboardUserView } from './LevelLeaderboardUserView';
import OMT_UI_HorizontalScrollArea from '../../OMT_UI/Scroll/OMT_UI_HorizontalScrollArea';

import {
  USER_VIEW_RECT,
  H_SCROLLER_RECT,
  H_SCROLLER_VIEW_SPACING,
  H_SCROLLER_POS,
} from '../LevelLeaderboardSettings';

/**
 * class for the level leaderboard scroller view
 */
export class LevelLeaderboardScrollView extends CollapsedViewBase {
  /**
   * main initialization function. call manually or from extending Class
   * @param {Array} userList friendData list
   * @returns {Promise}
   */
  async init(userList) {
    this.x = H_SCROLLER_POS.x; this.y = H_SCROLLER_POS.y;
    await super.init(userList, H_SCROLLER_RECT, H_SCROLLER_VIEW_SPACING, 0);
  }

  /**
   * initialize the horizontal scroll area
   */
  _initScrollArea(scrollRect) {
    this._scrollArea = new OMT_UI_HorizontalScrollArea(scrollRect, true);
    this._scrollArea.signals.onScrolled.add(this._updateUserViewsVisibility, this);
    this._scrollArea.x = scrollRect.x;
    this._scrollArea.y = scrollRect.y;
    this.addChild(this._scrollArea);
    this._scrollRect = scrollRect;
  }

  /**
   * intialize UserView instance
   * @param {Object} friendData
   * @returns {Phaser.Group}
   */
  _initUserViewInstance(friendData) {
    const userView = new LevelLeaderboardUserView(friendData);
    userView.x += USER_VIEW_RECT.x;
    userView.y += USER_VIEW_RECT.y;
    userView.init(friendData);
    return userView;
  }
}
