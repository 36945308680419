G.BuildEnvironment = {
  production: false,
  version: 'DEV',
  APP_ID: 'candy-rain-7',
  /*
  RAVEN_DSN: "https://4fcf77bb0b8f462883be3da572af5dfc@sentry.io/234027",
  SENTRY_RELEASE_CFG: {
    org:"softgames-h5",
    projectId:"candy-rain",
    authToken:"26f15499faf44aa8829b6ff9d19676e8941ccea910b548e59d49d45fb3139a7c"
  },
  */
  GAGK: 'a13b2ebb022f484b46d90a69e1f39f4e',
  GASK: '46afb55dc99052fee19c338ffeaca0a1d7506c51',
  isCookOMT: false,

  adPlacementIDs: {
    REWARDED: '226466194579810_226494314576998',
    INTERSTITIAL: '226466194579810_226494224577007',
  },

  adPlacements: {
    skipMission: { adType: 'REWARDED', g_adName: 'skipMission' },
    spinTheDailyWheel: { adType: 'REWARDED', g_adName: 'spinTheDailyWheel' },
    requestMoveHelpWheelSpin: { adType: 'REWARDED', g_adName: 'requestMoveHelpWheelSpin' },
    claimExtraGift: { adType: 'REWARDED', g_adName: 'claimExtraGift' },
    claimAllGifts: { adType: 'REWARDED', g_adName: 'claimAllGifts' },
    achievementGift: { adType: 'REWARDED', g_adName: 'achievementGift' },
    doubleDailyReward: { adType: 'REWARDED', g_adName: 'doubleDailyReward' },
    preLevelBooster: { adType: 'REWARDED', g_adName: 'preLevelBooster' },
    retryChallenge: { adType: 'REWARDED', g_adName: 'retryChallenge' },
    addLife: { adType: 'REWARDED', g_adName: 'addLife' },
    spinTheLevelLostWheel: { adType: 'REWARDED', g_adName: 'spinTheLevelLostWheel' },
    double3hGift: { adType: 'REWARDED', g_adName: 'double3hGift' },
    doubleLevelCoins: { adType: 'REWARDED', g_adName: 'doubleLevelCoins' },
    rewarded: { adType: 'REWARDED', g_adName: 'rewarded' },
    redoMission: { adType: 'REWARDED', g_adName: 'redoMission' },
    chestShuffleRetry: { adType: 'REWARDED', g_adName: 'chestShuffle' },
    firstInterstitialInSession: { adType: 'INTERSTITIAL', g_adName: 'firstInterstitialInSession' },
    secondInterstitialInSession: { adType: 'INTERSTITIAL', g_adName: 'secondInterstitialInSession' },
    interstitial: { adType: 'INTERSTITIAL', g_adName: 'interstitial' },
  },
};
