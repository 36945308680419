import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

export const TokenEventKeys = {
  thanksgiving2020: 'thanksgiving2020',
  christmas2020: 'christmas2020',
  valentines2021: 'valentines2021',
  stPatricks2021: 'stPatricks2021',
  summer2021: 'summer2021',
  oktoberfest2021: 'oktoberfest2021',
  thanksgiving2021: 'thanksgiving2021',
  christmas2021: 'christmas2021',
  valentines2022: 'valentines2022',
  easter2022: 'easter2022',
};

const currentEvent = TokenEventKeys.easter2022;

/**
 * Class for the Token event manager... Temporarily
 */
export default class TokenEventManager {
  /**
   * Returns the default values for this manager
   * @returns {{ce:string, pa:Array<Boolean>}}
   */
  static getDefaultValues() {
    return {
      ce: currentEvent, // [C]urrent[E]vent
      pa: [], // [P]ostcard[A]warded
      FTUE: { // [F]irst[T]ime[U]ser[E]xperience
        to: true, // [T]argetted [O]ffer
        gt: true, // [G]ingy [T]utorial
        ee: true, // [E]vent [E]ntry window must be shown
      },
      tc: 0, // [T]okens[C]ollected
      lc: [], // [L]evels[C]hosen
    };
  }

  constructor() {
    this.save = () => {}; // This will be replaced externally
    this._levelTokensCollected = 0; // Tokens collected per level
    this._postcardGoals = null; // Number of tokens required for next postcard
    this.currentRewardTier = 0; // Which reward is the player currently going for
  }

  /**
   * The object is created in saveState. This class modifies the object reference.
   *
   * @param {Object} data
   */
  init(data) {
    this.dataReference = data;
    this._pcParamName = G.OMTsettings.tokenEvent.eventTokens.pcParam;

    OMT_Utils.mergeMissingObject(this.dataReference, TokenEventManager.getDefaultValues());
    this._checkData();
    this._modifyPrize();
  }

  /**
   * Checks the data. If the current event does not match whats in the data, it'll clean itself out
   */
  _checkData() {
    if (this.dataReference.ce !== currentEvent) {
      for (const key in this.dataReference) { // eslint-disable-line guard-for-in
        delete this.dataReference[key]; // Delete everything in the object but keep object reference
      }
      OMT_Utils.mergeMissingObject(this.dataReference, TokenEventManager.getDefaultValues());
      this.save();
    }

    this._postcardGoals = G.OMTsettings.postcardEvent.unlockData.currencyUnlock;

    // Calculate current reward tier
    let nextTokenReq = this._postcardGoals[this.currentRewardTier];

    while (this.dataReference.tc >= nextTokenReq && this.currentRewardTier < this._postcardGoals.length) {
      this.currentRewardTier++;
      nextTokenReq = this._postcardGoals[this.currentRewardTier];
    }
  }

  /**
   * Modifies the prize to be IAP friendly
   */
  _modifyPrize() {
    /* eslint-disable guard-for-in */
    for (const i in G.OMTsettings.postcardEvent.unlockData.unlockSendGift) {
      const gift = G.OMTsettings.postcardEvent.unlockData.unlockSendGift[i];
      for (const j in gift) {
        const spec = gift[j];
        if (spec.prize === 'booster#5' && G.IAP) {
          spec.prize = 'booster#6';
        }
      }
    }
    /* eslint-enable guard-for-in */
  }

  // Token Collection

  /**
   * Collect token from board
   */
  collectLevelToken() {
    this._levelTokensCollected++;
  }

  /**
   * Permenantly collect tokens for event
   */
  cashInLevelTokens() {
    this.dataReference.tc += this._levelTokensCollected;
    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam(this._pcParamName, this.dataReference.tc);

    this.resetLevelTokens();
    let hasEnough = this.hasEnoughTokens();

    // Determine current reward tier based on tokens collected
    while (hasEnough) {
      this.currentRewardTier++;
      hasEnough = this.hasEnoughTokens();
    }
    this.save();
  }

  /**
   * Check to see if player has enough tokens
   * @returns {boolean}
   */
  hasEnoughTokens() {
    if (this.currentRewardTier < this._postcardGoals.length) {
      return this.dataReference.tc >= this._postcardGoals[this.currentRewardTier];
    }

    return false;
  }

  /**
   * Clear level data
   */
  resetLevelTokens() {
    this._levelTokensCollected = 0;
  }

  /**
   * Clear event data
   */
  resetTotalEventTokens() {
    this.dataReference.tc = 0;
    this.dataReference.pa.length = 0;
    this.currentRewardTier = 0;

    while (this.hasEnoughTokens()) {
      this.currentRewardTier++;
    }

    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam(this._pcParamName, 0);
    this.save();
  }

  /**
   * Reset FTUE
   */
  resetFTUE() {
    this.dataReference.FTUE.to = true;
    this.dataReference.FTUE.gt = true;
    this.dataReference.FTUE.ee = true;
    this.save();
  }

  /**
   * Get number of tokens collected during the current level
   * @returns {number}
   */
  get levelTokensCollected() {
    return this._levelTokensCollected;
  }

  /**
   * Get number of tokens collected for this tier's postcard
   * @returns {number}
   */
  get tierTokensForPostcard() {
    if (this.currentRewardTier < this._postcardGoals.length) {
      const previousGoal = this._postcardGoals[this.currentRewardTier - 1] || 0;
      return this.dataReference.tc - previousGoal;
    }

    // If all postcards have been unlocked (i.e. this.currentRewardTier == this._postcardGoals.length),
    // Just return the max number of tokens from the final tier
    return (this._postcardGoals[this._postcardGoals.length - 1] - this._postcardGoals[this._postcardGoals.length - 2]) || 0;
  }

  /**
   * Get number of tokens required for the next tier's postcard
   * @returns {number}
   */
  get tierTokensRequiredForNextPostcard() {
    if (this.currentRewardTier < this._postcardGoals.length) {
      const previousGoal = this._postcardGoals[this.currentRewardTier - 1] || 0;
      return this._postcardGoals[this.currentRewardTier] - previousGoal;
    }

    // If all postcards have been unlocked (i.e. this.currentRewardTier == this._postcardGoals.length),
    // Just return the number of tokens required for the final tier
    return (this._postcardGoals[this._postcardGoals.length - 1] - this._postcardGoals[this._postcardGoals.length - 2]) || 1;
  }

  /**
   * Get TOTAL number of tokens collected
   * @returns {number}
   */
  get totalTokensCollected() {
    return this.dataReference.tc;
  }

  /**
   * Get TOTAL number of tokens for the next postcard
   * @returns {number}
   */
  get totalTokensRequiredForPostcard() {
    if (this.currentRewardTier < this._postcardGoals.length) {
      return this._postcardGoals[this.currentRewardTier];
    }

    return -1;
  }

  get allPostCardsCollected() {
    return this.currentRewardTier === this._postcardGoals.length;
  }

  // Postcards

  /**
   * Sets the postcard index as rewarded
   * @param {number} index
   */
  setPostcardIndexAsRewarded(index) {
    this.dataReference.pa[index] = true;
  }

  /**
   * Returns if the given index is rewarded or not
   * @param {number} index
   * @returns {Boolean}
   */
  getPostcardIndexRewardedState(index) {
    return Boolean(this.dataReference.pa[index]);
  }

  /**
   * @returns {number}
   */
  getPostcardLastOpenedIndex() {
    return this.dataReference.pa.length;
  }

  /**
   * @returns {{to:boolean, gt:boolean, ee:boolean}}
   */
  get FTUE() {
    return this.dataReference.FTUE;
  }

  // Event Level Choices

  /**
   * Choose next event level number, or start over if all of them were chosen
   * @returns {number}
   */
  chooseNextLevel() {
    const { levelList } = G.OMTsettings.tokenEvent;
    if (this._allLevelsChosen()) {
      this.resetLevelsChosen(false);
    }

    const filteredLevelList = levelList.filter((levelNum) => !this.dataReference.lc.includes(levelNum));
    const chosenLevel = filteredLevelList[game.rnd.between(0, filteredLevelList.length - 1)];
    const chosenIndex = chosenLevel - 1;
    this.dataReference.lc.push(chosenLevel);
    this.save();

    return chosenIndex;
  }

  /**
   * Have all levels been chosen?
   * @returns {boolean}
   */
  _allLevelsChosen() {
    return this.dataReference.lc.length >= G.OMTsettings.tokenEvent.levelList.length;
  }

  /**
   * Reset chosen event levels list
   * @param {boolean} saveNow
   */
  resetLevelsChosen(saveNow) {
    this.dataReference.lc = [];
    if (saveNow) this.save();
  }

  /**
   * Get current event level
   * @returns {number}
   */
  get chosenLevel() {
    if (this.dataReference.lc.length === 0) {
      this.chooseNextLevel();
    }

    return this.dataReference.lc[this.dataReference.lc.length - 1] - 1;
  }
}
