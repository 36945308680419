/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import { Window } from '../../00_IMMEDIATE/Window';
import { Window_FillRateFallback } from './Window_FillRateFallback';
import OMT_UI_SquareButton, { BUTTONCOLOURS, BUTTONSTATE } from '../../OMT_UI/OMT_UI_SquareButton';
// import { XPROMO_PLACEMENTS } from '../../Services/OMT/OMT_CrossPromo';
import OMT_UI_TournamentPromoButton from '../../OMT_UI/Buttons/OMT_UI_TournamentPromoButton';
import ShopUtils from './Shop/Shop_Utils';
import { TextTimer } from '../TextTimer';

class Window_notEnoughLives extends Window {
  constructor(parentWindow, config) {
    super(parentWindow);

    const lifePrice = G.json.settings.buyLifePrice;
    const hasEnoughForLife = G.saveState.isEnoughToBuy(lifePrice);
    let buyLife = OMT.feature.outOfLivesCanBuyLives();
    const group = new Phaser.Group(game);

    /**
     * @property openLevelPopUp       [Boolean]   Should the window show a level popup when closed?
     * @property lvlIndex             [Number]    What state is the game currently in?
     * @property levelPopUpLayerName  [String]    Should the window show a level popup when closed?
     * @type {Object}
     */
    this.config = config || {};
    this._unlimitedLivesOffer = null;
    this._unlimitedLivesIAPOn = false;

    if (OMT.feature.outOfLivesCanBuyUnlimitedLives()) {
      this._unlimitedLivesSettings = G.json.settings.buyUnlimitedLivesSettings;
      if (G.IAP) this._getUnlimitedLivesOffer(G.json.settings.buyUnlimitedLivesSettings.productIdSuffix);
    } else {
      this._unlimitedLivesSettings = null;
    }

    if (G.BuildEnvironment.production && OMT.systemInfo.deviceIsDesktop) {
      buyLife = true;
    }

    this.add(group);

    const windowBg = G.makeImage(0, 33, 'popup_background_2', 0.5, group);
    windowBg.width = 460;
    windowBg.height = 540;
    this._windowBg = windowBg;

    const livesBanner = G.makeImage(7, -155, 'gold_wheel_ribbon', 0.5, group);
    livesBanner.width = windowBg.width * 1.35;
    livesBanner.scale.y = livesBanner.scale.x;
    const heartImage = G.makeImage(0, -63, 'heart', 0.5, group);
    heartImage.scale.set(1.3);
    G.makeImage(-7, 13, 'timer_bar', 0.5, group);

    this.noMoreLivesTxt = new G.Text(0, livesBanner.y - livesBanner.height * 0.1, OMT.language.getText('No more lives!'), {
      style: 'font-white',
      fontSize: '50px',
    }, 0.5, 350);
    group.add(this.noMoreLivesTxt);

    this.livesNrTxt = new G.Text(G.OMTsettings.elements.Window_notEnoughLives.livesNrTxt.x, G.OMTsettings.elements.Window_notEnoughLives.livesNrTxt.y, '0', {
      style: 'font-white',
      fontSize: '40px',
    }, 0.5, 150);
    group.add(this.livesNrTxt);

    this.timerTxt = new TextTimer({
      x: 20,
      y: 15,
      date: 0,
      style: {
        style: 'font-white',
        fontSize: '35px',
      },
      anchor: 0.5,
      maxWidth: 140,
      timerFormat: 'ms',
    });
    group.add(this.timerTxt);

    this.coinsBg = G.makeImage(0, -285, 'top-panel-coinsBg', 0.5, group);
    this.coinsTxt = new G.Text(
      G.OMTsettings.elements.Window_notEnoughLives.coinsTxt.x,
      G.OMTsettings.elements.Window_notEnoughLives.coinsTxt.y,
      OMT.language.toLocaleNumber(G.saveState.getCoins()),
      G.OMTsettings.elements.Window_notEnoughLives.coinsTxt.style,
      0.5,
      110,
    );
    group.add(this.coinsTxt);

    this.closeButton = new G.Button(240, -240, 'btn_x', function () {
      if (game.state.current !== 'World') {
        G.sb('onStateChange').dispatch('World');
      } else {
        this._closeWindow(() => {
          // const crossPromoData = OMT.crossPromo.getPromoByPlacementId(XPROMO_PLACEMENTS.NO_LIVES);
          // OMT.crossPromo.showCrossPromoWindow('no_lives', crossPromoData);
        });
      }
    }, this);
    this.closeButton.scale.setTo(0.6);
    group.add(this.closeButton);

    // Slot 1: Show buy life button or earn life (from ad) button
    this._createBuyLifeBtn(hasEnoughForLife, lifePrice, group);
    this._createEarnLifeBtn(group);

    if (buyLife) {
      this._setBuyLifeBtnVisible(true);
    } else {
      this._setEarnLifeBtnVisible(true);
    }

    // Slot 2: Show buy unlimited lives button or ask friend button
    if (OMT.feature.outOfLivesCanBuyUnlimitedLives()) {
      this._createBuyUnlimitedLivesBtn(group);
    } else if (OMT.feature.isGiftingSupported()) {
      this._createAskFriendButton(group);
    }

    G.Utils.cleanUpOnDestroy(this,
      G.sb('onLifeTimerUpdate').add(this.timerTxt.setSecLeft, this.timerTxt));

    G.Utils.cleanUpOnDestroy(this,
      G.saveState.signals.onLifeAmountChanged.add((amount) => {
        if (amount > 0) {
        // window was triggering twice
        // this._onLifeAdded();
        }
      }, this));

    this._setupTournamentPromo();
  }

  /**
   * Get unlimited lives IAP offer from catalog and activates if if player meets conditions
   */
  async _getUnlimitedLivesOffer() {
    const catalog = await OMT.payments.getCatalogAsync();
    const wasPayer = DDNA.tracking.getDataCapture().getPlayerCharacterizationParam('realMoneyPurchasesMade');
    const lowOnCoins = G.saveState.getCoins() <= this._unlimitedLivesSettings.nonPayerCoinThreshold;
    [this._unlimitedLivesOffer] = ShopUtils.getNMLUnlimitedLivesOffer(catalog, this._unlimitedLivesSettings.productIdSuffix);

    // If player meets conditions and the is an offer, replace offer for coins with IAP offer
    if (this._unlimitedLivesOffer && !wasPayer && lowOnCoins) {
      this._setUnlimitedLivesIAPVisible(true);
    }
  }

  /**
   * Create buy 1 life button
   * @param {boolean} hasEnoughForLife
   * @param {number} lifePrice
   * @param {Pahser.Group} parent
   */
  _createBuyLifeBtn(hasEnoughForLife, lifePrice, parent) {
    const onClick = () => {
      if (hasEnoughForLife) {
        // DDNA.transactionHelper.trackRewards([['life', 1]], [], {
        //   transactionType: 'COIN_PURCHASE',
        //   tActionType: 'LIFE_PURCHASE',
        //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
        //   tCoinsUsed: lifePrice,
        // });

        OMT.transactionTracking.logInventoryTransactionBegin();
        OMT.transactionTracking.addInventoryChange('coins', 0, -lifePrice);
        OMT.transactionTracking.logInventoryTransactionEnd();

        G.saveState.changeCoins(-lifePrice);
        G.saveState.addLife();
        this._checkTriggerPopUpConfig();
        this._closeWindow();
      } else if (G.IAP) {
        // coin shop
        this.closeWindow();
        G.Helpers.pushOutOfCoinsPopUp(false, {
          event: OMT.platformTracking.Events.OpenShopLives,
        });
        G.sb('pushWindow').dispatch(['notEnoughLives', this.config]);
      }
    };
    this.buyLifeButton = new OMT_UI_SquareButton(0, 105, {
      button: {
        tint: BUTTONCOLOURS.blue,
        dimensions: {
          width: 309,
          height: 100,
        },
        isEnabled: (hasEnoughForLife || (G.IAP && !hasEnoughForLife)),
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });

    parent.add(this.buyLifeButton);

    this.buyLifeButton_text = new G.Text(-125, 105, OMT.language.getText('Buy a life'), {
      style: 'font-white',
      fontSize: '40px',
    }, 0, 135);
    this.buyLifeButton_text.anchor.setTo(0, 0.5);
    parent.add(this.buyLifeButton_text);

    this.buyLifeButton_coin = G.makeImage(33, 103, 'currency', 0.5, parent);
    this.buyLifeButton_coin.scale.setTo(0.9);

    this.buyLifeButton_coinTxt = new G.Text(135, 105, lifePrice.toString(), G.OMTsettings.elements.Window_notEnoughLives.buyLifeButton_coinTxt.style, 0, 200);
    this.buyLifeButton_coinTxt.anchor.setTo(1, 0.5);
    parent.add(this.buyLifeButton_coinTxt);

    this._setBuyLifeBtnVisible(false);
  }

  /**
   * Create buy unlimited lives button
   * @param {Phaser.Group} parent
   */
  _createBuyUnlimitedLivesBtn(parent) {
    const unlimitedLivesSettings = G.json.settings.buyUnlimitedLivesSettings;
    const price = unlimitedLivesSettings.coinPrice;
    const { durationText } = unlimitedLivesSettings;
    const hasEnoughForPurchase = G.saveState.isEnoughToBuy(price);

    const onClick = () => {
      if (this._unlimitedLivesIAPOn) {
        this._unlimitedLivesIAPPurchase();
      } else {
        this._unlimitedLivesCoinPurchase();
      }
    };

    // Button
    this.buyUnlimitedLivesButton = new OMT_UI_SquareButton(0, 210, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
        extraDetail: false,
        isEnabled: hasEnoughForPurchase || G.IAP,
      },
      options: {
        clickFunction: {
          onClick,
        },
      },
    });

    parent.add(this.buyUnlimitedLivesButton);

    // Heart
    this.buyUnlimitedLivesButton_heart = G.makeImage(-115, 208, 'heart_unlimited', 0.5, parent);
    this.buyUnlimitedLivesButton_heart.scale.setTo(0.9);

    // Duration
    const localizedDurationText = durationText
      .replace('%HOUR%', OMT.language.getText('hour'));

    this.buyUnlimitedLivesButton_text = new G.Text(-37, 210, localizedDurationText, {
      style: 'font-white',
      fontSize: '40px',
    }, 0.5, 80);

    parent.add(this.buyUnlimitedLivesButton_text);

    // Coin
    this.buyUnlimitedLivesButton_coin = G.makeImage(33, 208, 'currency', 0.5, parent);
    this.buyUnlimitedLivesButton_coin.scale.setTo(0.9);

    // Price Text
    this.buyUnlimitedLivesButton_priceTxt = new G.Text(140, 210, price.toString(),
      G.OMTsettings.elements.Window_notEnoughLives.buyLifeButton_coinTxt.style,
      0, 80);

    this.buyUnlimitedLivesButton_priceTxt.anchor.setTo(1, 0.5);
    parent.add(this.buyUnlimitedLivesButton_priceTxt);
  }

  _createAskFriendButton(parent) {
    const onFriendClick = () => {
      if (game.state.current === 'Game') {
        G.sb('onStateChange').dispatch('World', undefined, () => {
          G.sb('openLifeTransactionsMenu').dispatch();
        });
      } else {
        this._closeWindow();
        G.sb('openLifeTransactionsMenu').dispatch();
      }
    };

    this.askFriendsButton = new OMT_UI_SquareButton(0, 210, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText('Ask Friend!'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: onFriendClick.bind(this),
        },
      },
    });

    parent.add(this.askFriendsButton);
  }

  /**
   * Purchases unlimited lives with coins
   */
  _unlimitedLivesCoinPurchase() {
    const price = this._unlimitedLivesSettings.coinPrice;
    const hasEnoughForPurchase = G.saveState.isEnoughToBuy(price);
    const { duration } = this._unlimitedLivesSettings;

    if (hasEnoughForPurchase) {
      // DDNA.transactionHelper.trackRewards([], [], {
      //   transactionType: 'COIN_PURCHASE',
      //   tActionType: 'UNL_LIFE_PURCHASE',
      //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
      //   tCoinsUsed: price,
      //   tUnlimitedLivesMinutesAdded: duration,
      // });

      OMT.transactionTracking.logInventoryTransactionBegin();
      OMT.transactionTracking.addInventoryChange('coins', 0, -price);
      OMT.transactionTracking.logInventoryTransactionEnd();

      G.saveState.changeCoins(-price);
      G.saveState.addUnlimitedLivesTimeMin(duration, false);
      this._checkTriggerPopUpConfig();
      this._closeWindow();
    } else if (G.IAP) {
      // coin shop
      this.closeWindow();
      G.Helpers.pushOutOfCoinsPopUp(false, {
        event: OMT.platformTracking.Events.OpenShopLives,
      });
      G.sb('pushWindow').dispatch(['notEnoughLives', this.config]);
    }
  }

  /**
   * Purchases unlimited lives with real money
   */
  _unlimitedLivesIAPPurchase() {
    if (!this._unlimitedLivesOffer) return;
    // ShopUitls.purchaseItem handles adding unlimited lives minutes automatically from offer data
    ShopUtils.purchaseItem(this._unlimitedLivesOffer, null, null, true, () => {
      this._checkTriggerPopUpConfig();
      this._closeWindow();
    });
  }

  /**
   * Not used
   */
  _onLifeAdded() {
    this.closeWindow();
    this._checkTriggerPopUpConfig();
  }

  /**
   * Triggers level window pop up
   */
  _checkTriggerPopUpConfig() {
    this._closeWindow();
    if (this.config.openLevelPopUp) {
      const layerName = game.state.current === 'World' ? G.WindowMgr.LayerNames.AboveHighScorePanel : G.WindowMgr.LayerNames.Base;
      G.sb('pushWindow').dispatch(
        ['level', this.config.lvlIndex],
        false,
        layerName,
      );
    }
  }

  /**
   * Shows/hides buy life button
   * @param {boolean} visible
   */
  _setBuyLifeBtnVisible(visible) {
    this.buyLifeButton.visible = visible;
    this.buyLifeButton_text.visible = visible;
    this.buyLifeButton_coin.visible = visible;
    this.buyLifeButton_coinTxt.visible = visible;
  }

  /**
   * Shows/hides earn life button
   * @param {boolean} visible
   */
  _setEarnLifeBtnVisible(visible) {
    this.earnLifeButton.visible = visible;
  }

  _setUnlimitedLivesIAPVisible(visible) {
    this._unlimitedLivesIAPOn = visible;
    this.buyUnlimitedLivesButton_coin.visible = !visible;
    if (visible) this.buyUnlimitedLivesButton.currentState = BUTTONSTATE.ENABLED;
    this.buyUnlimitedLivesButton_priceTxt.text = visible
      ? this._unlimitedLivesOffer.price
      : this._unlimitedLivesSettings.coinPrice;
    this.buyUnlimitedLivesButton_text.x = visible ? -20 : -37;
  }

  /**
   * Creates earn life button
   * TODO: is this ever used? If not we need to remove it and test
   * @param {Phaser.Group} parent
   */
  _createEarnLifeBtn(parent) {
    const onClick = () => {
      this.earnLifeButton.inputEnabled = false;

      OMT.ads.showAd(
        G.BuildEnvironment.adPlacements.addLife,
        this._adSuccess.bind(this),
        () => {
          if (!G.IAP) { // no IAPS dont use ad fallbacks
            // eslint-disable-next-line no-new
            new Window_FillRateFallback(undefined, {
              placement: G.BuildEnvironment.adPlacements.addLife,
              callback: this._adSuccess,
              context: this,
            });

            this._setBuyLifeBtnVisible(true);
            this._setEarnLifeBtnVisible(false);
          } else { // open ad fallback
            OMT.ads.showAdFallback(this._adSuccess.bind(this));
          }
        },
      );
    };
    const img = G.makeImage(95, 103, 'btn-movie-icon', 0.5, null);
    this.earnLifeButton = new OMT_UI_SquareButton(0, 105, {
      button: {
        tint: BUTTONCOLOURS.purple,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Earn a life'),
        textStyle: { style: 'font-white', fontSize: 40 },
        icon: {
          image: img,
          align: 'right',
          offset: {
            x: -img.width / 2,
          },
        },
        offset: {
          x: -img.width / 4,
        },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });
    parent.add(this.earnLifeButton);

    this._setEarnLifeBtnVisible(false);
  }

  /**
   * On ad success
   */
  _adSuccess() {
    if (game.state.current === 'World') {
      this._addLife();
    } else {
      G.saveState.addLife(G.json.settings.livesForAd);
      this._closeWindow();
    }

    this._checkTriggerPopUpConfig();
  }

  /**
   * Adds life
   */
  _addLife() {
    let batch;

    if (game.state.current === 'World') {
      batch = this.state.uiTargetParticles.createDividedBatch(
        game.world.bounds.x + this.worldPosition.x,
        this.worldPosition.y,
        'heart',
        this.state.panel.lifeUI.lifeIcon,
        1,
        1,
      );

      batch.addOnPartFinish(() => {
        G.saveState.addLife(1);
      });
      batch.start();
    } else {
      G.saveState.addLife(1);
    }

    this._closeWindow();
  }

  /**
   * Sets up the tournament promotion button that sits below the window.
   */
  _setupTournamentPromo() {
    if (!G.featureUnlock.tournamentPromo || OMT.platformTournaments.getCooldownTimeRemaining() > 0) return;
    if (G.saveState.getTournamentLastPlayed() === OMT.platformTournaments.getTournamentLevelId()) return;

    // create tournament button instance
    const tournamentBtn = new OMT_UI_TournamentPromoButton();
    tournamentBtn.onClick.addOnce(async () => {
      await tournamentBtn.showTournamentWindow();
      this.closeWindow();
    });
    this.addChild(tournamentBtn);

    // align tournament button
    const offsetY = 75;
    tournamentBtn.y = this._windowBg.y + (this._windowBg.height / 2) + (tournamentBtn.height / 2) + offsetY;
    tournamentBtn.scale.set(0.9);
  }

  /**
   * Closes this window
   * @param {function} callback
   */
  _closeWindow(callback = null) {
    G.sb('onLifeTimerUpdate').remove(this.timerTxt.setSecLeft, this.timerTxt);
    this.closeWindow(callback);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.notEnoughLives = Window_notEnoughLives;
