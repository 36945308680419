/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

import { SaveStateFixer } from '../Services/SaveState/SaveStateFixer';
import { SaveStateUtils } from '../Services/SaveState/SaveStateUtils';
import { SaveStateKeyValueManager } from '../Services/SaveState/SaveStateKeyValueManager';
import { FriendshipChest_DataManager, FRIENDSHIP_CHEST_SAVE_KEY } from './Windows/FriendshipChest/FriendshipChest_DataManager';
import MapChestDataManager, { SAGA_MAP_CHEST_MANAGER_DATA_KEY } from '../Services/OMT/dataTracking/mapChest/MapChestDataManager';
import FortuneCookieDataManager from '../Services/OMT/dataTracking/fortuneCookie/FortuneCookieDataManager';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import TargetedOfferDataManager from '../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { MILLISECONDS_IN_MIN } from '@omt-components/Utils/TimeUtil';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { OMT_Debug } from '../Debug/OMT_Debug';
import ChestShuffleDataManager, { CHESTSHUFFLE_MANAGER_DATA_KEY } from '../Services/OMT/dataTracking/chestShuffle/ChestShuffleDataManager';
import GateManager, { GATE_MANAGER_DATA_KEY } from '../Services/OMT/dataTracking/gateManager/GateManager';
import { MailboxManager, MAILBOX_MANAGER_DATA_KEY } from '../Services/OMT/dataTracking/mailbox/MailboxManager';
import { MysteryGiftManager } from '../Services/OMT/dataTracking/mysteryGift/MysteryGiftManager';
import { RMWHEEL_MODES } from './SpinningWheels/RealMoneyWheel/rmWheelEnums';
import { sortPendingSpins } from './SpinningWheels/RealMoneyWheel/RealMoneyWheelHelpers';
import { DATA_KEYS } from '../Services/SaveState/SaveStateKeyMappings';
import VillainsDataManager, { VillainsDataManagerKey } from '../Services/OMT/dataTracking/villains/VillainsDataManager';
import TokenEventManager from '../Services/OMT/dataTracking/tokeEventManager/TokenEventManager';
import LoyaltyManager from '../Services/OMT/dataTracking/loyaltyManager/LoyaltyManager';
import OMT_VILLAINS from '../OMT_UI/OMT_Villains';
import HomescreenShortcutManager from '../Services/OMT/dataTracking/homescreenShortcut/HomeScreenShortcutManager';
import TreasureHuntManager, { TREASURE_HUNT_TIME_RECHECK } from '../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';
import BadgeManager, { PUBLIC_USER_BADGE_KEY } from '../Services/OMT/dataTracking/badgeManager/BadgeManager';
import WallClockTimer from '../Utils/Timers/WallClockTimer';
import AnnuityManager from '../Services/OMT/dataTracking/annuityManager/AnnuityManager';

const DATA_KEY = 'gmdatastring-cc2';

/*
   _____                  _____ _        _
  / ____|                / ____| |      | |
 | (___   __ ___   _____| (___ | |_ __ _| |_ ___
  \___ \ / _` \ \ / / _ \\___ \| __/ _` | __/ _ \
  ____) | (_| |\ V /  __/____) | || (_| | ||  __/
 |_____/ \__,_| \_/ \___|_____/ \__\__,_|\__\___|
*/

/**
 * Save State Class
 */
class SaveState {
  /**
   * constructor
   */
  constructor() {
    this._keyValueManager = new SaveStateKeyValueManager();
    this._saveStateFixer = new SaveStateFixer();
    this.friendshipChestDataManager = new FriendshipChest_DataManager(); // Is accessed outside of SaveState. Do not change
    this.fortuneCookieDataManager = FortuneCookieDataManager.getInstance();
    this.targetedOffersDataManager = TargetedOfferDataManager.getInstance();
    this.mapChestDataManager = new MapChestDataManager();
    this.chestShuffleDataManager = new ChestShuffleDataManager();
    this.mailboxManager = new MailboxManager();
    this.gateManager = new GateManager();
    this.mysteryGiftManager = new MysteryGiftManager();
    this.villainsDataManager = new VillainsDataManager();
    this.tokenEventManager = new TokenEventManager();
    this.loyaltyManager = new LoyaltyManager();
    this.homescreenShortcutManager = new HomescreenShortcutManager();
    this.treasureHuntManager = new TreasureHuntManager(); // initialized in preloader
    this.badgeManager = new BadgeManager();
    this.annuityManager = new AnnuityManager();
    this._progressWasRestored = false;
    this._runningCoinTotal = 0;
    this._refillRate = Math.floor(G.json.settings.refillRateMin * 60000);
    this._wheelData = this.loadWheelData();
    this._defineSingals();
  }

  /**
   * get the data key
   * @returns {string} userData key
   */
  get dataKey() { return DATA_KEY; }

  /**
   * define event signals
   */
  _defineSingals() {
    this.signals = {
      onGateOpened: new Phaser.Signal(),
      onLifeAmountChanged: new Phaser.Signal(),
      onBoosterBought: new Phaser.Signal(),
      onBoosterUsed: new Phaser.Signal(),
    };
  }

  /**
   * make new / default data Object
   */
  _makeNewDataObject() {
    const obj = {
      // coins: G.json.settings.coinsOnStart, // migrated to key value
      // lives: G.json.settings.livesOnStart, // migrated to key value
      lastRefillDate: Date.now(),
      lastDaily: Date.now(),
      freeSpin: true,
      levels: [],
      points: [],
      retryCounts: {},
      // boosters: [], // migrated to key value
      finishedTutorials: [],
      chestReferals: [],
      startBoosterAnim: [true, true, true, true],
      mute: false,
      muteFx: false,
      cWinCount: 0, // consecutive wins in general
      highestCWinCount: 0,
      agWinCount: 0, // achievement gift consecutive wins
      version: 1,
      // player ids for OMT games will get appended to and passed with xpromos
      omtPlayerIDs: { [G.BuildEnvironment.APP_ID]: OMT.envData.settings.user.userId },
      // Saved results from real money payload wheel, to prevent exploits
      // savedSpinResults: {}, // migrated to key value
      dailyChallengeFTUEChat: false,
      iapMultiplier: 1,
    };
    // for (let i = 0; i < 10; i++) obj.boosters[i] = G.json.settings.boostersOnStart; // migrated to key value
    return obj;
  }

  /**
   * Initialize the save state
   * @param {Object} data
   * @returns {Promise}
   */
  async _init(userData) {
    // keep these as != dont change to !==
    const dataAvailable = userData != null && userData.levels != null;
    this.data = dataAvailable ? Object.assign(this._makeNewDataObject(), userData) : this._makeNewDataObject();
    this._deleteObsoleteData();

    // run migration process splitting the data blob into key value pairs.
    const legacyParamsDeleted = this._keyValueManager.runLegacyMigrationProcess(this.data);

    this.sessionData = {}; // Unsaved session data.

    if (dataAvailable) { // returning user
      if (this.getLastPassedLevelNr() > 3) {
        this.data.sawDailyTut = true;
      }
    } else if (G.firstTime !== false) { // flag as a first time user if value not already set.
      G.firstTime = true;
    }

    this._initBadgeManager();
    this._initSpecialEventData();
    this._initVillainsData();
    this._initMapChestData();
    this._initGateManager();
    this._initMysteryGift();
    this._initMailboxData();
    this._enforceBoosterCaps();
    this._initLoginStatsTracker();
    this._checkCWinCountData();
    this._checkAchievementWinCountData();
    this._initWallClockEvents();
    this._initChestShuffleData();
    this._fixSagaMapIssue();
    this._initRealMoneyWheelSeen();
    this._initHomescreenShortcutManager();
    this._initTreasureHuntManager();
    this._initAnnuityManager();
    this._checkPendingSpinData();

    G.soundManager.setSoundEnabled(G.MuteReason.UserChoice, !this.data.muteFx);
    G.soundManager.setMusicEnabled(G.MuteReason.UserChoice, !this.data.mute);

    this.data.starsAtSessionStart = this.getAllStars();

    if (this.data.allowHints === undefined || G.OMTsettings.FTUXSettings === undefined) {
      this.data.allowHints = true;
    }

    this.friendshipChest_initData();

    await this.fortuneCookie_initData(); // Will init if the event is true
    this.targetedOffers_initData();
    this._initTargetedOfferSeen();

    // track new player
    if (G.firstTime) {
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.NewUserSource, 1, {
        source: OMT.envData.entryPoint,
        entry_point: OMT.envData.entryPoint,
      });
    }

    // if parameters were deleted during key value migration trigger a save now.
    if (legacyParamsDeleted.length > 0) {
      // OMT_Utils.stylizedLog(`** LEGACY PARAMS DELETED: ${legacyParamsDeleted.join(', ')}`, '#FFFF00');
      this.save();
    }
  }

  /**
   * Oct 1, 2020, FISH released with Saga Map 2.0. Unfortunately there was a bug that didn't migrate gates over properly.
   * People were sent to level 20 with the gates locked, and they could manually open each gate up to their level
   * Because each map interaction spawned with a gate unlocking, they now have a lot of stuff.
   *
   * This function is to fix that issue so that they can keep their map interactions less than 40 levels of their current level
   */
  _fixSagaMapIssue() {
    const curLevel = this.getLastPassedLevelNr();
    const nextGate = this.gateManager.getNextGateLevel();
    if (curLevel <= nextGate) { return; }/* eslint-disable-line no-useless-return */ // Probably not affected
    this.gateManager.fixGatesUpToLevel(curLevel);
    const mapInteractionLimit = curLevel - 40;
    this.mailboxManager.removeAllMailboxesUpToLevel(mapInteractionLimit);
    this.chestShuffleDataManager.removeAllChestsUpToLevel(mapInteractionLimit);
    this.mapChestDataManager.removeAllChestsUpToLevel(mapInteractionLimit);
  }

  /**
   * clean out old junk from the data, if you have old data to delete. Do it here.
   */
  _deleteObsoleteData() {
    delete this.data.wheelData;
    delete this.data.resourceSentTimestamp;
    delete this.data.a2uSchedule;
    delete this.data.mapChestData;
    delete this.data.lastBotOptInGiftGiven;
    delete this.data.botOptIn;
    delete this.data.showOptinIcon;
    delete this.data.isHomeScreenPopupSeen;
    delete this.data.isHomeScreenIconAdded;
  }

  /**
   * init the wall clock event + set signals. //FIXME: this really should be managed elsewhere since it is not related to save data.
   */
  _initWallClockEvents() {
    G.sb('onWallClockTimeUpdate').addPermanent(this._onTick, this, 99);
    WallClockTimer.start();
  }

  /**
   * init gate data manager
   */
  _initGateManager() {
    const data = OMT.userData.getUserData(GATE_MANAGER_DATA_KEY);
    if (!data) {
      this.sessionData[GATE_MANAGER_DATA_KEY] = {};
    } else {
      this.sessionData[GATE_MANAGER_DATA_KEY] = data;
    }
    this.gateManager.init(this.sessionData[GATE_MANAGER_DATA_KEY]);
  }

  /**
   * init dynamic map chest data / chest migration.
   */
  _initMapChestData() {
    const data = OMT.userData.getUserData(SAGA_MAP_CHEST_MANAGER_DATA_KEY);
    if (!data) {
      this.sessionData[SAGA_MAP_CHEST_MANAGER_DATA_KEY] = {};
    } else {
      this.sessionData[SAGA_MAP_CHEST_MANAGER_DATA_KEY] = data;
    }
    this.mapChestDataManager.init(this.sessionData[SAGA_MAP_CHEST_MANAGER_DATA_KEY]);
    if (this.data.mapChestCap) {
      delete this.data.mapChestCap;
    }
    if (this.data.mapChests) {
      delete this.data.mapChests;
    }
  }

  /**
   * Initializes the chest shuffle data used on the saga map
   */
  _initChestShuffleData() {
    const data = OMT.userData.getUserData(CHESTSHUFFLE_MANAGER_DATA_KEY);
    if (!data) {
      this.sessionData[CHESTSHUFFLE_MANAGER_DATA_KEY] = {};
    } else {
      this.sessionData[CHESTSHUFFLE_MANAGER_DATA_KEY] = data;
    }
    this.chestShuffleDataManager.init(this.sessionData[CHESTSHUFFLE_MANAGER_DATA_KEY]);
  }

  /**
   * Initializes real money wheel seen state (repalcement wheel only)
   */
  _initRealMoneyWheelSeen() {
    this.incrementRealMoneyWheelSessionsNotSeen();
  }

  /**
   * Initializes targeted offer seen state
   */
  _initTargetedOfferSeen() {
    const sessionNumber = this.targetedOffersDataManager.getSessionNumber() || 0;
    this.targetedOffersDataManager.setSessionNumber(sessionNumber + 1);
  }

  /**
   * Initializes the badge manager
   */
  _initBadgeManager() {
    const data = this._keyValueManager.getData(DATA_KEYS.BADGES);
    this.badgeManager.save = () => {
      this._keyValueManager.setData(DATA_KEYS.BADGES, data);
      OMT.userData.writeUserData(PUBLIC_USER_BADGE_KEY, data.ab);
    };
    this.badgeManager.init(data);
  }

  /**
   * Initializes the homescreen shortcut manager
   */
  _initHomescreenShortcutManager() {
    const data = this._keyValueManager.getData(DATA_KEYS.HOMESCREEN_SHORTCUT);
    this.homescreenShortcutManager.save = () => {
      this._keyValueManager.setData(DATA_KEYS.HOMESCREEN_SHORTCUT, data);
    };
    this.homescreenShortcutManager.init(data);
  }

  /**
   * Initializes the manager that keeps track of loyalty and other rewards
   * Called in preloader.js
   */
  initLoyaltyData() {
    const data = this._keyValueManager.getData(DATA_KEYS.LOYALTY_MANAGER);
    this.loyaltyManager.save = () => {
      this._keyValueManager.setData(DATA_KEYS.LOYALTY_MANAGER, data);
    };
    this.loyaltyManager.init(data);
  }

  /**
   * Initalizes the special event manager (for postcards)
   */
  _initSpecialEventData() {
    const data = this._keyValueManager.getData(DATA_KEYS.TOKEN_EVENT);
    this.tokenEventManager.save = () => {
      this._keyValueManager.setData(DATA_KEYS.TOKEN_EVENT, data);
    };
    this.tokenEventManager.init(data);
  }

  /**
   * Initalizes the villains data manager
   */
  _initVillainsData() {
    const data = this._keyValueManager.getData(VillainsDataManagerKey);
    this.villainsDataManager.save = () => {
      this._keyValueManager.setData(VillainsDataManagerKey, data);
    };
    this.villainsDataManager.init(data);
  }

  /**
   * Initializes the chest shuffle data used on the saga map
   */
  _initTreasureHuntManager() {
    const data = this._keyValueManager.getData(DATA_KEYS.TREASURE_HUNT);
    this.treasureHuntManager.save = () => {
      this._keyValueManager.setData(DATA_KEYS.TREASURE_HUNT, data);
    };
    this.treasureHuntManager.init(data);
  }

  /**
   * Initalizes the special event manager (for postcards)
   */
  _initAnnuityManager() {
    const data = this._keyValueManager.getData(DATA_KEYS.ANNUITY);
    this.annuityManager.save = () => {
      this._keyValueManager.setData(DATA_KEYS.ANNUITY, data);
    };
    this.annuityManager.init(data);
  }


  /**
   * iniitialize and load data
   * @returns {Promise}
   */
  async initLoadData() {
    const data = OMT.userData.getUserData(DATA_KEY);
    await this._init(data);
  }

  /**
   * Reset services, such as DDNA, tracking, and scheduled bot messages
   * @returns {Promise}
   */
  async _resetServices() {
    // DDNA.tracking.clearQueueFromLocalStorage();
    OMT.platformTracking.clearFTUData();
    await OMT.notifications.unscheduleAllGameTriggeredMessages('bot');
  }

  /**
   * reset OMT game data for the user
   * @returns {Promise}
   */
  async resetOMTData() {
    await this._resetServices();
    await OMT.userData.clearAllData(false);
  }

  /**
   * reset OMT + Legacy COOK data if it exists
   * @returns {Promise}
   */
  async resetAllData() {
    await this._resetServices();
    await OMT.userData.clearAllData(true);
  }

  /**
   * for externally setting data on the save data
   * @param {string} key
   * @param {*} value
   */
  setGameData(key, value) {
    if (!this.data.gameData) {
      this.data.gameData = {};
    }

    this.data.gameData[key] = value;
    this.save();
  }

  /**
   * get externally saved game data by key
   * @param {string} key
   */
  getGameData(key) {
    if (!this.data.gameData) {
      this.data.gameData = {};
    }
    return this.data.gameData[key];
  }

  /**
   * set the debug mode, requires game reload
   * @param {number} debugMode 0) no debug displays, 1) FPS overlay only, 2) Phaser.Debug plugin only, 3) FPS Overlay + Phaser.Debug plugin
   */
  async setDebugMode(debugMode) {
    if (!OMT_Debug.isValidMode(debugMode)) return;
    this.data.debugMode = debugMode;
    await this.save();
    OMT.platformFunctions.restartGame();
    console.warn('DEBUG MODE CHANGED - this requires a restart.');
  }

  /**
   * get the debug mode
   * @returns {number}
   */
  get debugMode() {
    return this.data.debugMode || 0;
  }

  /**
   * append player IDs from other games pased from xpromo
   * @param {Object} omtPlayerIDs
   */
  appendOMTPlayerIDs(omtPlayerIDs) {
    Object.assign(this.data.omtPlayerIDs, omtPlayerIDs);
    this.save();
  }

  /**
   * get a reference to the omtPlayerIDs Object
   * @returns {Object}
   */
  getOMTPlayerIDs() {
    return this.data.omtPlayerIDs;
  }

  /**
   * check if the daily challenge is currently availabe to the user
   */
  isChallengeAvailable() {
    if (this.data.lastChallengeTry === undefined) this.data.lastChallengeTry = 0;
    const dateNow = new Date();
    const lastTryDate = new Date(this.data.lastChallengeTry);
    let result = false;
    // in case of tempering with data
    if (dateNow.getTime() > lastTryDate.getTime() && dateNow.toDateString() !== lastTryDate.toDateString()) {
      this.data.challengeCompleted = false;
      result = true;
    }
    if (this.data.rewardedChallenge && !this.data.challengeCompleted) result = true;
    if (result) this.data.dailyChalStars = 0;
    return result;
  }

  /**
   * get total daily challenge stars
   * @param {number} st
   * @returns {number}
   */
  getChallengeStars() {
    if (!this.data.dailyChalStars) this.data.dailyChalStars = 0;
    return this.data.dailyChalStars;
  }

  /**
   * add to daily challenge stars
   * @param {number} st
   */
  accumulateChallengeStars(st) {
    if (!this.data.dailyChalStars) this.data.dailyChalStars = 0;
    this.data.dailyChalStars += st;
    if (this.data.dailyChalStars > 3) this.data.dailyChalStars = 3;
  }

  /**
   * start daily challenge
   */
  startChallenge() {
    if (this.data.rewardedChallenge) this.data.rewardedChallenge = false;
    else this.data.lastChallengeTry = Date.now();
    this.save();
  }

  /**
   * mark the daily challenge completed
   */
  markChallengeCompleted() {
    this.data.challengeCompleted = true;
  }

  /**
   * check if challenge is completed
   * @returns {boolean}
   */
  isChallengeCompleted() {
    return this.data.challengeCompleted;
  }

  /**
   * gives the player an additional Chance to do the daily challenge
   */
  enableRewardedChallenge() {
    this.data.rewardedChallenge = true;
    this.save();
  }

  /**
   * get time to next daily challenge
   * @returns {number} time in milliseconds
   */
  getTimeToNextChallenge() {
    if (this.data.lastChallengeTry === undefined) this.data.lastChallengeTry = 0;
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const nextDateStamp = date.getTime() + 24 * 60 * 60 * 1000;
    return (nextDateStamp - Date.now());
  }

  /**
   * get lvl Object for next daily challenge
   * @returns {Object} lvl Object
   */
  getDailyChallengeLevel() {
    // seeding the rng with the current day.
    game.rnd.sow([Math.floor(Date.now() / (1000 * 60 * 60 * 24))]);

    // go through all the levels and pick out the hard ones
    const hardLevels = [];
    for (let i = 0; i < G.Helpers.levelDataMgr.getNumberOfLevels(); i++) {
      const lvl = G.Helpers.levelDataMgr.getLevelByIndex(i);
      const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty(lvl);
      if (isNotNormalLevel) {
        hardLevels.push(i);
      }
    }

    // pick a random lvl from those hard levels (from the rng seeded with the current day)
    const lvlIndex = game.rnd.between(0, hardLevels.length - 1);

    // clone the level so we only affect the daily challenge version
    const lvl = G.Utils.clone(G.Helpers.levelDataMgr.getLevelByIndex(hardLevels[lvlIndex]));
    lvl.lvlNumber = lvlIndex + 1;
    lvl.moves -= 3;
    // remove tutorial as it can crash when booster is not unlocked
    if (lvl.tutID) lvl.tutID = null;
    return lvl;
  }

  /**
   * get lvl Object for next special event level
   * @param {boolean} isComplete is the current level finished?
   * @returns {Object} lvl Object
   */
  getEventLevel(isComplete) {
    // Pick a random level number with TokenEventManager
    const lvlIndex = isComplete ? this.tokenEventManager.chooseNextLevel() : this.tokenEventManager.chosenLevel;

    // clone the level so we only affect the daily challenge version
    const lvl = G.Utils.clone(G.Helpers.levelDataMgr.getLevelByIndex(lvlIndex));
    // remove tutorial as it can crash when booster is not unlocked
    if (lvl.tutID) lvl.tutID = null;
    return lvl;
  }

  /**
   * get the time to the next special deal
   * @returns {number} time in milliseconds
   */
  getTimeToNextSpecialDeal() {
    if (!this.data.lastSpecialDeal) {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      this.data.lastSpecialDeal = date.getTime();
    }
    const n = 48; // 48 hours
    const milliHour = 1000 * 60 * 60 * n; // 48 hours in milli

    const now = new Date().getTime();
    const lastTime = now - this.data.lastSpecialDeal; // Time to last deal
    if (lastTime >= milliHour) { // If past the 48 hour mark
      const offset = Math.floor(lastTime / milliHour) * milliHour; // Boost it to the next 48 hours
      this.data.lastSpecialDeal += offset;
      this.initNewShopSeed(); // Get a new seed when the time ticks over
    }
    const retValue = this.data.lastSpecialDeal + milliHour; // This is the amount of time left to next special deal
    return retValue;
  }

  /**
   * Creates a new shop seed.
   * shopSeed can also be initialized at Shop_Util.getSpecialDeal. That one has more priority but only happens once
   * shopSeed is not important enough to be saved in data, but it is still required, so saved in sessionData
   * NOT USED ANYMORE 19/06/20
   */
  initNewShopSeed() {
    const day = new Date(this.data.lastSpecialDeal);
    this.sessionData.shopSeed = parseInt(`${day.getFullYear()}${day.getMonth()}${day.getDay()}`);
  }

  /**
   * check if tutorial a tutorial has been finished
   * @param {string} tutId tutorial ID
   * @returns {boolean}
   */
  isFinishedTutorial(tutId) {
    return this.data.finishedTutorials.indexOf(tutId) >= 0;
  }

  /**
   * check if tutorial popup has already been seen
   * @param {string} tutId tutorial ID
   * @returns {boolean}
   */
  isPopUpTutorialPassed(tutId) {
    if (!this.data.popUpTutorials) {
      this.data.popUpTutorials = [];
    }
    return this.data.popUpTutorials.indexOf(tutId) !== -1;
  }

  /**
   * flag tutorial popup as seen
   * @param {string} tutId tutorial ID
   */
  passPopUpTutorial(tutId) {
    if (!this.data.popUpTutorials) {
      this.data.popUpTutorials = [];
    }

    if (!this.isPopUpTutorialPassed(tutId)) {
      this.data.popUpTutorials.push(tutId);
    }
  }

  /**
   * on Challenge Level passed
   * @param {number} extraStars amount of extra stars received
   */
  passExtraLevel(extraStars) {
    if (!this.data.extraStars) this.data.extraStars = 0;
    this.data.extraStars += extraStars;
    this.save();
  }

  /**
   * On Level passed
   * @param {number} config.levelIndex index of level passed
   * @param {number} config.newStars new stars acheived
   * @param {number} config.newPoints new points acheived
   * @param {bolean} config.skipReward skip reward application
   * @param {GameEnum} config.gameMode Challenge or normal mode. Used to be from G.mode
   * @param {string} config.difficulty Difficulty of level
   * @param {boolean} config.dontSave
   * @param {number} config.extraMoves
   */
  passLevel(config) {
    const {
      levelIndex,
      newStars,
      newPoints,
      skipReward,
      dontSave,
    } = config;
    let { gameMode, difficulty, extraMoves } = config;
    if (!gameMode) {
      gameMode = LevelType.NORMAL;
    }
    if (!difficulty) {
      difficulty = 'NORMAL';
    }
    if (!extraMoves) {
      extraMoves = 0;
    }

    const recordProgress = [LevelType.COLLECT_EVENT, LevelType.TREASURE_HUNT].indexOf(gameMode) === -1;

    // Pass a non-special event level
    if (recordProgress) {
      if (this.getStars(levelIndex) === 0 && this.mysteryGiftManager.isModeReady()) {
        this.mysteryGiftManager.endOfLastStreak = 0;
      }

      const oldStars = this.getStars(levelIndex);
      const oldPoints = this.getPoints(levelIndex);

      const result = {
        highscore: false,
        points: newPoints,
        reward: SaveStateUtils.computeReward(levelIndex, oldStars, newStars, extraMoves, difficulty),
        stars: newStars,
        passedFriend: false,
        starImprovement: Math.max(0, newStars - oldStars),
      };

      if (oldPoints < newPoints) {
        this.data.points[levelIndex] = newPoints;
        result.highscore = true;
      }

      if (newStars > oldStars) this.data.levels[levelIndex] = newStars;
      if (!skipReward) this.changeCoins(result.reward);

      // tracking
      if (levelIndex === 1) OMT.platformTracking.logFTUEvent('FTUX_Lvl02C');
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.LevelEnd);
      if (gameMode === LevelType.CHALLENGE) OMT.platformTracking.logEvent(OMT.platformTracking.Events.DailyChallengeLevelEnd);

      // Collect stars (oldLevelIndex, starCount)
      G.sb('dailyMissionCollectStars').dispatch(null, result.starImprovement);

      // DDNA.tracking.getDataCapture().addToPlayerCharacterizationSessionParam('levelsPassedThisSession', 1);
      // DDNA.transactionHelper.queueLevelCoinReward(result.reward, 'LevelEnd');
      G.sb('onLevelFinished').dispatch(levelIndex, newStars, newPoints);
      // DDNA.transactionHelper.sendQueuedLevelCoinRewards();

      if (!dontSave) this.save();
      return result;
    }

    // Pass a special event level
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.LevelEnd);
   // const dataCapture = DDNA.tracking.getDataCapture();
   // dataCapture.addToPlayerCharacterizationSessionParam('levelsPassedThisSession', 1, true);
    // if (recordProgress) DDNA.transactionHelper.queueLevelCoinReward(result.reward, 'LevelEnd');
    G.sb('onLevelFinished').dispatch(levelIndex, newStars, newPoints);
    // if (recordProgress) DDNA.transactionHelper.sendQueuedLevelCoinRewards();

    return {
      highscore: false,
      points: newPoints,
      reward: 0,
      stars: 0,
      passedFriend: false,
      starImprovement: 0,
    };
  }

  /**
   * Takes the regular daily challenge reward and passes it through the progressive stars function and returns it.
   */
  computeDailyChallengeReward() {
    if (G.featureUnlock.progressiveLevelReward) {
      return SaveStateUtils.computeRewardByStarsProgressive(G.saveState.getChallengeStars(), G.lvl.stars, G.json.settings.challengeCoinsForStarTotal);
    }
    return G.json.settings.coinsForStar[G.lvl.stars - 1];
  }

  /**
   * call when a level is retried
   * @param {number} lvl_nr Level #
   */
  onLevelRetry(lvl_nr) {
    const levelId = `l_${lvl_nr}`;
    if (this.data.retryCounts == null) this.data.retryCounts = {};
    if (this.data.retryCounts[levelId] == null) this.data.retryCounts[levelId] = lvl_nr === 'tournament' ? 1 : 0;
    else this.data.retryCounts[levelId]++;
    this.save();
  }

  /**
   * reset the number of retries for tournament levels
   */
  resetTournamentRetries() {
    if (this.data.retryCounts == null) this.data.retryCounts = {};
    else delete this.data.retryCounts.l_tournament;
  }

  /**
   * get the level retry count
   * @param {number} lvl_nr Level #
   */
  getLevelRetries(lvl_nr) {
    const levelId = `l_${lvl_nr}`;
    if (!this.data.retryCounts || !this.data.retryCounts[levelId]) return 0;
    return this.data.retryCounts[levelId];
  }

  /**
   * Resets the retry count on a particular level, if it exists
   * @param {number} lvl_nr
   */
  resetLevelRetries(lvl_nr) {
    const levelId = `l_${lvl_nr}`;
    if (this.data.retryCounts == null) this.data.retryCounts = {};
    if (this.data.retryCounts[levelId]) {
      delete this.data.retryCounts[levelId];
    }
  }

  /**
   * get the last passed level #
   * @return {number}
   */
  getLastPassedLevelNr() {
    if (this.data !== null && this.data.levels !== null && Object.prototype.hasOwnProperty.call(this.data, 'levels')) {
      return this.data.levels.length;
    }
    return 0;
  }

  /**
   * Is the specified level currently playable?
   * @param {number} lvlNr level #
   * @returns {boolean}
   */
  isLevelAvailable(lvlNr) {
    return lvlNr <= this.data.levels.length;
  }

  /**
   * get points for a level
   * @param {number} lvl_nr Level #
   * @returns {number}
   */
  getPoints(lvl_nr) {
    return this.data.points[lvl_nr] ? this.data.points[lvl_nr] : 0;
  }

  /**
   * get stars for a level
   * @param {number} lvl_nr Level #
   */
  getStars(lvl_nr) {
    return this.data.levels[lvl_nr] ? this.data.levels[lvl_nr] : 0;
  }

  /**
   * get total stars earned
   * @returns {number}
   */
  getAllStars() {
    let val = 0;
    for (let i = 0, len = this.data.levels.length; i < len; i++) {
      val += this.data.levels[i] || 0;
    }

    if (this.data.extraStars === undefined) {
      this.data.extraStars = 0;
    }
    val += this.data.extraStars;
    return val;
  }

  /**
   * get stars the player had when starting the session
   * @returns {number}
   */
  getStarsAtSessionStart() {
    return this.data.starsAtSessionStart;
  }

  /**
   * get current coin count
   * @returns {number}
   */
  getCoins() {
    return parseInt(this._keyValueManager.getData(DATA_KEYS.COINS));
  }

  /**
   * set current coin count.
   * If changing a users coins you should use the .changeCoins() method since that has tracking and this does not.
   * @param {number} coins
   * @param {boolean} dispatchChangeEvent (optional) true if we want to dispatch the change event to update fields
   */
  setCoins(coins, dispatchChangeEvent = true) {
    this._keyValueManager.setData(DATA_KEYS.COINS, Math.max(0, parseInt(coins)));
    if (dispatchChangeEvent) {
      G.sb('onCoinsChange').dispatch(coins);
    }
    this.refreshAllBoosterAmounts();
  }

  /**
   * change the user coin amount
   * @param {number} amount amount of coins
   * @param {boolean} dontSave (deprecated, left in due to ongoing refactoring)
   * @param {boolean} dispatchIncrease (optional) dispatch an increase event for record keeping (so far, only daily missions)
   * @param {boolean} trackDDNA (optional) Track data for DDNA
   * @param {boolean} dispatchChangeEvent (optional) true if we want to dispatch the change event to update fields
   */
  changeCoins(amount, dontSave, dispatchIncrease = true, trackDDNA = true, dispatchChangeEvent = true) {
    if (isNaN(amount)) throw new Error('Error : cannot modfiy coints amount is NaN.');
    // update coins
    const coins = Math.max(0, this.getCoins() + parseInt(amount));
    this.setCoins(coins, dispatchChangeEvent);

    // Tracking begins here
   // const dataCapture = DDNA.tracking.getDataCapture();
    if (amount > 0) {
      if (dispatchIncrease) { // Increase signal fired here
        G.sb('onCoinsIncrease').dispatch(amount);
      }
      /*if (trackDDNA && dataCapture) { // Tracking?
        dataCapture.addToPlayerCharacterizationSessionParam('coinsEarnedThisSession', amount);
      }*/
    } else if (amount < 0) {
      // track that coins were spent
     /* if (trackDDNA) {
        const absAmount = Math.abs(amount);
        dataCapture.addToPlayerCharacterizationParam('coinsSpent', absAmount, false);
        dataCapture.addToPlayerCharacterizationSessionParam('coinsSpentThisSession', absAmount);
        if (this.getLives() === 0) {
          dataCapture.addToPlayerCharacterizationParam('zeroLivesCoinPurchases', 1, false);
        }
        dataCapture.addToPlayerCharacterizationParam('coinPurchasesMade', 1, true);
      }*/
    }

    this._runningCoinTotal += amount;

   /* if (trackDDNA) { // More tracking
      // track coin delta
      if (this._runningCoinTotal > 0) {
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.CoinsIn, this._runningCoinTotal, { amount: this._runningCoinTotal });
      } else if (this._runningCoinTotal < 0) {
        const absAmount = Math.abs(this._runningCoinTotal);
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.CoinsOut, absAmount, { amount: absAmount });
      }
    }*/
    this._runningCoinTotal = 0;

  }

  /**
   * Migration: add platinum spin data array if its missing
   */
  _checkPendingSpinData() {
    const data = this._keyValueManager.getData(DATA_KEYS.REAL_MONEY_WHEEL);
    if (!data.unclaimedBoughtSpins.platinum) {
      data.unclaimedBoughtSpins.platinum = [];
      this._keyValueManager.setData(DATA_KEYS.REAL_MONEY_WHEEL, data);
    }
  }

  /**
   * Get list of pending real money wheel spins
   * Coming from payloads, delayed purchases, or unclaimed spins
   */
  getPendingRealMoneyWheelSpins() {
    const data = this._keyValueManager.getData(DATA_KEYS.REAL_MONEY_WHEEL);
    return data.unclaimedBoughtSpins;
  }

  /**
   * Sort all pending real money spins according to RMWHEEL_PENDINGORDER
   * Used before resolving those spins in the order of: free -> IAP paid -> IAP not paid
   */
  sortPendingRealMoneyWheelSpins() {
    const data = this._keyValueManager.getData(DATA_KEYS.REAL_MONEY_WHEEL);
    const spinData = data.unclaimedBoughtSpins;

    spinData.conversion = sortPendingSpins(spinData.conversion);
    spinData.highValue = sortPendingSpins(spinData.highValue);
    spinData.platinum = sortPendingSpins(spinData.platinum);

    this._keyValueManager.setData(DATA_KEYS.REAL_MONEY_WHEEL, data);
  }

  /**
   * Add a new pending real money wheel spin
   * @param {RMWHEEL_MODES} wheelMode
   * @param {string} id
   */
  addPendingRealMoneySpin(wheelMode, id) {
    const data = this._keyValueManager.getData(DATA_KEYS.REAL_MONEY_WHEEL);
    const spinData = data.unclaimedBoughtSpins;

    if (wheelMode === RMWHEEL_MODES.Conversion) {
      spinData.conversion.push(id);
    } else if (wheelMode === RMWHEEL_MODES.HighValue) {
      spinData.highValue.push(id);
    } else if (wheelMode === RMWHEEL_MODES.Platinum) {
      spinData.platinum.push(id);
    } else {
      console.warn(`addPendingRealMoneySpin: invalid wheel mode: ${wheelMode}`);
      return;
    }

    this._keyValueManager.setData(DATA_KEYS.REAL_MONEY_WHEEL, data);
  }

  /**
   * Remove a pending real money wheel spin
   * @param {RMWHEEL_MODES} wheelMode
   * @param {string} id
   */
  removePendingRealMoneySpin(wheelMode, id) {
    const data = this._keyValueManager.getData(DATA_KEYS.REAL_MONEY_WHEEL);
    let spinArray;

    // Validate wheelMode and select appropriate array
    if (wheelMode === RMWHEEL_MODES.Conversion) {
      spinArray = data.unclaimedBoughtSpins.conversion;
    } else if (wheelMode === RMWHEEL_MODES.HighValue) {
      spinArray = data.unclaimedBoughtSpins.highValue;
    } else if (wheelMode === RMWHEEL_MODES.Platinum) {
      spinArray = data.unclaimedBoughtSpins.platinum;
    } else {
      console.warn(`removePendingRealMoneySpin: invalid wheel mode: ${wheelMode}`);
      return;
    }

    // Search and remove id
    const index = spinArray.indexOf(id);

    if (index !== -1) {
      spinArray.splice(index, 1);
      this._keyValueManager.setData(DATA_KEYS.REAL_MONEY_WHEEL, data);
    } else {
      console.warn(`removePendingRealMoneySpin: no unclaimed spin of id ${id} for ${wheelMode} wheel found`);
    }
  }

  /**
   * Reset list of pending real money spins
   */
  resetPendingRealMoneyWheelSpins() {
    this._keyValueManager.setData(DATA_KEYS.REAL_MONEY_WHEEL, this._keyValueManager.getDefaultValue(DATA_KEYS.REAL_MONEY_WHEEL));
  }

  /**
   * Returns how many consecutive sessions has it been since the player last saw the real money wheel
   * Should only apply to the replacement wheel
   */
  getRealMoneyWheelSessionsNotSeen() {
    const data = this._keyValueManager.getData(DATA_KEYS.REAL_MONEY_WHEEL);
    return data.sessionsNotSeen || 0;
  }

  /**
   * Increments number of sessions has it been since the player last saw the real money wheel
   * Should only apply to the replacement wheel
   */
  incrementRealMoneyWheelSessionsNotSeen() {
    const data = this._keyValueManager.getData(DATA_KEYS.REAL_MONEY_WHEEL);
    data.sessionsNotSeen = (data.sessionsNotSeen || 0) + 1;
    this._keyValueManager.setData(DATA_KEYS.REAL_MONEY_WHEEL, data);
    return data.sessionsNotSeen;
  }

  /**
   * Resets number of sessions has it been since the player last saw the real money wheel
   */
  resetRealMoneyWheelSessionsNotSeen() {
    const data = this._keyValueManager.getData(DATA_KEYS.REAL_MONEY_WHEEL);
    data.sessionsNotSeen = 0;
    this._keyValueManager.setData(DATA_KEYS.REAL_MONEY_WHEEL, data);
    return data.sessionsNotSeen;
  }

  /**
   * add minutes to unlimited lifes timer
   * @param {number} min minutes to add to the timer
   * @param {boolean} skipSave skip saving
   */
  addUnlimitedLivesTimeMin(min, skipSave) {
    if (this.data.unlimitedLives !== undefined) {
      if (this.data.unlimitedLives > 0) {
        const difference = Math.max(this.data.unlimitedLives - (this.data.unlimitedLivesStart || Date.now()), 0);
        this.setUserCooldown('unlimitedLives', '', difference);
      }
      delete this.data.unlimitedLives;
      delete this.data.unlimitedLivesStart;
    }

    let currentAmount = this.getUserCooldownRemaining('unlimitedLives', '');
    currentAmount += (min * 60000);
    this.setUserCooldown('unlimitedLives', '', currentAmount);

    G.sb('onUnlimitedLivesChanged').dispatch(this.getUnlimitedLivesSec());
    this.save();
  }

  /**
   * get the unlimited lives seconds remaining
   * @returns {number}
   */
  getUnlimitedLivesSec() {
    if (this.data.unlimitedLives !== undefined) {
      if (this.data.unlimitedLives > 0) {
        const difference = Math.max(this.data.unlimitedLives - (this.data.unlimitedLivesStart || Date.now()), 0);
        this.setUserCooldown('unlimitedLives', '', difference);
      }
      delete this.data.unlimitedLives;
      delete this.data.unlimitedLivesStart;
    }
    // if (!this.data.unlimitedLives) {
    //   this.data.unlimitedLives = 0;
    // }

    // temporary PARTIAL fix for going back in time for unlimited lives
    // if (this.data.unlimitedLivesStart && this.data.unlimitedLivesStart > Date.now()) {
    //   this.data.unlimitedLives = 0;
    //   this.data.unlimitedLivesStart = 0;
    //   this.save();
    //   return 0;
    // }

    const maxMilli = (G.OMTsettings.unlimitedLivesMinCap) * 60000;
    const remaining = this.getUserCooldownRemaining('unlimitedLives', '');
    if (remaining >= maxMilli) {
      this.setUserCooldown('unlimitedLives', '', Math.max(Math.min(remaining, maxMilli), 0));
    }
    let sec = Math.round(this.getUserCooldownRemaining('unlimitedLives', '') / 1000);
    sec = Math.max(0, sec);
    return sec;
  }

  /**
   * called when the player loses a life
   * @returns {Promise<number>}
   */
  async loseLife() {
    if (!G.LIVES) return 0;
    if (this.getUnlimitedLivesSec() > 0) return 0;

    let lives = this.getLives();
    if (lives <= 0) return 0;
    lives = this.setLives(lives - 1);
    this.signals.onLifeAmountChanged.dispatch(lives);

    if (lives <= 0) {
      // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('lastTimeAtZeroLives', Date.now(), true);
    }

    return lives;
  }

  /**
   * add a life for the user
   * @param {number} nr
   * @param {boolean} skipSave
   * @param {boolean} skipReminder if true, don't schedule game-triggered messages
   * @returns {Promise<number>}
   */
  async addLife(nr = 1, skipSave = false, skipReminder = false) {
    const scheduledLivesMessages = await OMT.notifications.findGameTriggeredMessages('FullLives');
    let lives = this.getLives();
    // If the number of lives is more than the max,
    // don't change it and just cancel any FullLives messages
    if (lives >= G.json.settings.livesMax) {
      if (scheduledLivesMessages.length > 0) {
        await OMT.notifications.removeGameTriggeredMessage('FullLives');
      }
      return lives;
    }
    // Add lives, make sure nr is always at least 1
    lives = this.setLives(lives + Math.max(nr, 1));

    this.signals.onLifeAmountChanged.dispatch(lives);
    G.sb('onLifeAdded').dispatch(lives);

    // Cancel pending FullLives message if lives are full
    // Update pending FullLives message timer otherwise (unless skipped)
    if (scheduledLivesMessages.length > 0) {
      if (lives === G.json.settings.livesMax) {
        await OMT.notifications.removeGameTriggeredMessage('FullLives');
      } else if (!skipReminder) {
        // Calculate time to 5 lives in seconds
        const timeToFullLives = this.getSecUntilFullLives();
        await OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, 'FullLives', timeToFullLives, false);
      }
    }

    return lives;
  }

  /**
   * get current lives
   * @returns {number}
   */
  getLives() {
    return parseInt(this._keyValueManager.getData(DATA_KEYS.LIVES));
  }

  /**
   * set current lives
   * @param {number} lives
   * @returns {number}
   */
  setLives(lives) {
    lives = Math.max(Math.min(parseInt(lives), G.json.settings.livesMax), 0);
    this._keyValueManager.setData(DATA_KEYS.LIVES, lives);
    return lives;
  }

  /**
   * check if the user has enough coins to buy a item
   * @returns {boolean}
   */
  isEnoughToBuy(amount) {
    const coins = this.getCoins();
    return coins >= amount;
  }

  /**
   * get list of all booster counts.
   * @returns Array.<number>
   */
  getBoosterArray() {
    return this._keyValueManager.getData(DATA_KEYS.BOOSTERS);
  }

  /**
   * set and save the list of booster counts/
   * @param {Array.<number>} boosters
   */
  setBoosterArray(boosters) {
    this._keyValueManager.setData(DATA_KEYS.BOOSTERS, boosters);
  }

  /**
   * check if the user has enough coins to buy a booster
   * @param {number} boosterNum booster #
   * @returns {boolean}
   */
  isEnoughToBuyBooster(boosterNum) {
    const coins = this.getCoins();
    if (coins >= G.json.settings[`priceOfBooster${boosterNum}`]) {
      return true;
    }
    return false;
  }

  /**
   * check if the user has enough coins to buy three boosters
   * @param {number} boosterNum booster #
   * @param {number} [discount] - Optional, defaults to 0
   * @returns {boolean}
   */
  isEnoughToBuyThreeBoosters(boosterNum, discount = 0) {
    const coins = this.getCoins();
    if (coins >= G.json.settings[`priceOfBooster${boosterNum}`] * 3 * (1 - discount)) {
      return true;
    }
    return false;
  }

  /**
   * get amount of boosters by booster #
   * @param {number} boosterNum booster #
   * @returns {number}
   */
  getBoosterAmount(boosterNum) {
    const boosters = this.getBoosterArray();
    const boosterAmount = parseInt(boosters[boosterNum]);
    if (isNaN(boosterAmount)) {
      return 0;
    }
    return boosterAmount;
  }

  /**
   * refill locked boosters or invalid booster counts to default count
   * @param {number} refillCount
   * @param {string} reason (optional) reason for log
   */
  refillLockedOrInvalidBoosters(refillCount, reason = '') {
    const boosters = this.getBoosterArray();
    let boostersFilled = 0;
    for (let i = 1; i < boosters.length; i++) {
      if (boosters[i] == null) { // invalid
        boosters[i] = refillCount; boostersFilled++;
      } else if (this.isBoosterUnlocked(i) === false && boosters[i] < refillCount) { // locked
        boosters[i] = refillCount; boostersFilled++;
      }
    }
    if (boostersFilled > 0) {
      this.setBoosterArray(boosters);
      OMT.userData.logUserDataStatus(`boosters refilled - ${reason}`, `boosters result: ${JSON.stringify(boosters)}`);
    }
  }

  /**
   * check if a booster has been unlocked
   * @param {number} boosterNum booster #
   * @returns {boolean}
   */
  isBoosterUnlocked(boosterNum) {
    const lastPassedLevelNr = this.getLastPassedLevelNr();
    return lastPassedLevelNr + 1 >= G.featureUnlock.unlockLevels.boosters[boosterNum];
  }

  /**
   * attempt to buy a booster with coins.
   * @param {number} boosterNum booster #
   * @param {number} optLevelIndexTracking associated level
   * @param {boolean} skipSave
   */
  buyBooster(boosterNum, optLevelIndexTracking, skipSave = false) {
    const price = G.json.settings[`priceOfBooster${boosterNum}`];
    const coins = this.getCoins();
    if (coins >= price) {
      this.changeCoins(-price, skipSave);
      this.changeBoosterAmount(boosterNum, 1);
      this.signals.onBoosterBought.dispatch(boosterNum, price, optLevelIndexTracking);
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.BoosterIn, 1, {
        booster_id: boosterNum,
        source: 'InGame',
        sum: 1,
      });
      return true;
    }
    return false;
  }

  /**
   * Change inventory count for booster
   * @param {number} boosterNum booster #
   * @param {number} amount amount to increase
   */
  changeBoosterAmount(boosterNum, amount) {
    if (!Number.isInteger(boosterNum) || !Number.isInteger(amount)) {
      // G.Utils.SentryLog.logError(`Error: changeBoosterAmount(${boosterNum}:${typeof boosterNum}, ${amount}:${typeof amount})`);
      return;
    }
    const boosters = this.getBoosterArray();
    let boosterAmount = (this.getBoosterAmount(boosterNum) * 1) + (amount * 1);
    if (boosterAmount < 0) boosterAmount = 0;
    // for extra moves we set both values together
    if (boosterNum === 5 || boosterNum === 6) {
      boosters[5] = boosters[6] = boosterAmount;
    } else {
      boosters[boosterNum] = boosterAmount;
    }
    this.setBoosterArray(boosters);
    G.sb('refreshBoosterAmount').dispatch(boosterNum);
  }

  /**
   * Trigger a refresh on all boosters
   */
  refreshAllBoosterAmounts() {
    for (let i = 1; i < 10; i++) {
      G.sb('refreshBoosterAmount').dispatch(i);
    }
  }

  /**
   * fix for users with bugged booster counts. we should probably set a more reasonable cap later.
   */
  _enforceBoosterCaps() {
    const boosters = this.getBoosterArray();
    const maxBoosterCount = 10000;
    let boosterErrorCount = 0;
    for (let i = 0; i < boosters.length; i++) {
      if (boosters[i] * 1 > maxBoosterCount) {
        boosters[i] = maxBoosterCount;
        boosterErrorCount++;
      // eslint-disable-next-line no-restricted-globals
      } else if (isNaN(boosters[i]) || boosters[i] * 1 < 0) {
        boosters[i] = 0;
        boosterErrorCount++;
      }
    }
    // correct booster counts for extra move boosters
    const extraMoveBoosterCount = Math.max(parseInt(boosters[5]), parseInt(boosters[6]));
    boosters[5] = boosters[6] = extraMoveBoosterCount;
    if (boosterErrorCount > 0) this.setBoosterArray(boosters);
  }

  /**
   * use a booster from user inventory
   * @param {number} boosterNum booster #
   * @param {number} optLevelIndexTracking associated level
   */
  useBooster(boosterNum, optLevelIndexTracking, unlimitedMode) {
    // Transaction Tracking
    OMT.transactionTracking.logInventoryTransactionBegin();

    unlimitedMode = unlimitedMode != undefined ? unlimitedMode : false;

    if (this.getBoosterAmount(boosterNum) <= 0) {
      // DDNA.transactionHelper.trackBoosterUse(boosterNum, G.json.settings[`priceOfBooster${boosterNum}`]);
      this.buyBooster(boosterNum, optLevelIndexTracking, true);
      G.sfx.cash_register.play();
      OMT.transactionTracking.addInventoryChange('boostersReceived', boosterNum, 1);
      OMT.transactionTracking.addInventoryChange('coins', 0, -G.json.settings[`priceOfBooster${boosterNum}`]);
    } else {
      // DDNA.transactionHelper.trackBoosterUse(boosterNum, 0);
    }

    this.changeBoosterAmount(boosterNum, unlimitedMode ? 0 : -1);
    this.signals.onBoosterUsed.dispatch(boosterNum);
    // DDNA.tracking.getDataCapture().addToPlayerCharacterizationSessionParam('inGameBoostersUsedThisSession', 1);
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.BoosterOut, 1, {
      booster_id: boosterNum,
      source: 'InGame',
      sum: 1,
    });

    OMT.transactionTracking.addInventoryChange('boostersUsed', boosterNum, 1);
    OMT.transactionTracking.logInventoryTransactionEnd();

    G.sb('onBoosterUsed').dispatch(boosterNum);
    // DDNA.tracking.ftuxEvent(8, 'firstBoosterUse');
  }

  /**
   * use a pre-level booster from user inventory
   * @param {number} boosterNum booster #
   * @param {number} boostersUsed number of boosters used
   */
  useStartBooster(boosterNum, boostersUsed = 1) {
    const boosters = this.getBoosterArray();
    if (!boosters[boosterNum]) return;
    // DDNA.tracking.getDataCapture().addToPlayerCharacterizationSessionParam('mapBoostersUsedThisSession', boostersUsed);
    this.changeBoosterAmount(boosterNum, -boostersUsed);
  }

  /**
   * Track multiple boosters being bought at once
   * @param {object} config
   */
  // eslint-disable-next-line object-curly-newline
  trackMultipleBoosterBought({ boosterNum, quantity, coinCost, lvlIndex = -1 }) {
    if ([boosterNum, quantity, coinCost].some((param) => param === undefined)) {
      console.log('ERROR: Invalid booster bought tracking parameter.');
      console.log(`ERROR: boosterNum: ${boosterNum} quantity: ${quantity} coinCose: ${coinCost}`);
      return false;
    }

    // DDNA.transactionHelper.trackBoosterInventoryPurchase(boosterNum, quantity, coinCost, lvlIndex + 1);

    OMT.transactionTracking.logInventoryTransactionBegin();
    OMT.transactionTracking.addInventoryChange('boostersReceived', boosterNum, quantity);
    OMT.transactionTracking.addInventoryChange('coins', 0, -coinCost);
    OMT.transactionTracking.logInventoryTransactionEnd();

    this.changeCoins(-coinCost);
    this.signals.onBoosterBought.dispatch(boosterNum, coinCost, lvlIndex);
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.BoosterIn, quantity, {
      booster_id: boosterNum,
      source: 'InGame',
      sum: quantity,
    });

    return true;
  }

  /**
   * check consecutive win count for achievement gifts
   */
  _checkAchievementWinCountData() {
    if (!this.data.agWinCount) this.data.agWinCount = 0;

    // someone quit during level
    if (this.data.agWinCount > 0 && this.mysteryGiftManager.duringLevel) {
      this.resetAchievementWinCount();
    }
  }

  /**
   * increment the consecutive win count for achievement gifts
   */
  incrementAchievementWinCount() {
    this.data.agWinCount++;
  }

  /**
   * reset the consecutive win count for achievement gifts
   */
  resetAchievementWinCount() {
    this.data.agWinCount = 0;
  }

  /**
   * get the consecutive win count for achievement gifts
   * @returns {number}
   */
  getAchievementWinCount() {
    return this.data.agWinCount;
  }

  /**
   * check consecutive win count in general
   */
  _checkCWinCountData() {
    if (!this.data.cWinCount) this.data.cWinCount = 0;
    if (!this.data.highestCWinCount) this.data.highestCWinCount = 0;

    // someone quit during level
    if (this.data.cWinCount > 0 && this.mysteryGiftManager.duringLevel) {
      this.resetCWinCount();
    }
  }

  /**
   * increment the consecutive win count in general
   */
  incrementCWinCount() {
    this.data.cWinCount++;
    if (this.data.cWinCount > this.data.highestCWinCount) {
      this.data.highestCWinCount = this.data.cWinCount;
    }
  }

  /**
   * reset the consecutive win count in general
   */
  resetCWinCount() {
    this.data.cWinCount = 0;
  }

  /**
   * get the consecutive win count in general
   * @returns {number}
   */
  getCWinCount() {
    return this.data.cWinCount;
  }

  /**
   * get the highest consecutive win count in general
   * @returns {number}
   */
  getHighestCWinCount() {
    return this.data.highestCWinCount;
  }

  /**
   * create / update the login stats object
   */
  _initLoginStatsTracker() {
    const loginStats = this.getLoginStats();
    const todayDate = new Date();
    const creationDate = new Date(loginStats.creation);
    const lastLoginDate = new Date(loginStats.lastDate);
    const yesterdaysDate = new Date();
    const daysSinceLastLogin = Math.floor((Date.now() - lastLoginDate) / (1000 * 60 * 60 * 24));
    yesterdaysDate.setDate(todayDate.getDate() - 1);

    // track days since last login
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.DaysSinceLastLogin, 1, {
      source: OMT.envData.entryPoint,
      entry_point: OMT.envData.entryPoint,
      daysSinceLastLogin,
    });

    // check if we are on a diffrent day then the last login
    if (!loginStats.consecutiveLoginDays) loginStats.consecutiveLoginDays = 1;
    if (!SaveStateUtils.isSameDay(todayDate, lastLoginDate)) {
      // increment consecutive login if they logged in yesterday
      if (SaveStateUtils.isSameDay(yesterdaysDate, lastLoginDate)) {
        loginStats.consecutiveLoginDays++;
      } else {
        loginStats.consecutiveLoginDays = 1;
      }
    }
    loginStats.firstDay = creationDate.toDateString() === todayDate.toDateString();
    loginStats.count++;
    if (todayDate.toDateString() !== lastLoginDate.toDateString()) {
      loginStats.dailyCount = 0;
    }
    loginStats.dailyCount++;
    loginStats.lastDate = Date.now();

    this.save();
  }

  /**
   * get login stats object
   * @returns {{creation:number, count:number, dailyCount:number, consecutiveLoginDays:number, lastDate:number}}
   */
  getLoginStats() {
    if (!this.data.loginTrack) {
      this.data.loginTrack = {
        creation: Date.now(),
        count: 0,
        dailyCount: 0,
        consecutiveLoginDays: 1,
        lastDate: Date.now(),
      };
    }
    if (this.data.loginTrack.creation === 0) {
      this.data.loginTrack.creation = Date.now();
    }
    return this.data.loginTrack;
  }

  /**
   * get time in seconds until next free spin
   * @returns {number}
   */
  getSecUntilNextFreeSpin() {
    if (this.data.freeSpin) return 0;
    return Math.floor(((this.data.lastDaily + 86400000) - Date.now()) / 1000);
  }

  /**
   * get time in seconds until next life refill
   * @returns {number}
   */
  getSecUntilNextLifeRefill() {
    if (this.getLives() >= G.json.settings.livesMax) return 0;
    return Math.floor((this._refillRate - (Date.now() - this.data.lastRefillDate)) / 1000);
  }

  /**
   * Calculate time to full lives in seconds
   * @returns {number}
   */
  getSecUntilFullLives() {
    const numLivesMissing = G.json.settings.livesMax - this.getLives();
    return (numLivesMissing - 1) * (this._refillRate / 1000) + this.getSecUntilNextLifeRefill();
  }

  /**
   * skip the ftue tutorials
   */
  skipFTUETutorials() {
    // add a 1000 coins, user may have IAPS
    // DDNA.tracking.ftuxEvent(-1, 'bragSkipTutorial');
    this.changeCoins(1000);
    this.refillLockedOrInvalidBoosters(2, 'skip tutorial brag 2.0');
    this.debugStarsUpTo(21, 1, false);
    this.save();
  }

  /**
   * debug / unlock levels on the map
   * @param {number} lvlNr level passed #
   * @param {number} starNr (optional) total stars #
   * @param {boolean} goToWorldState (optional)
   */
  debugStarsUpTo(lvlNr, starNr = 1, goToWorldState = true) {
    this.data.levels = [];
    while (lvlNr--) {
      this.data.levels.push(starNr || 3);
      if (this.gateManager.isLevelPotentiallyAGate(lvlNr)) {
        this.gateManager.openGate(lvlNr);
      }
    }
    G.saveState.chestShuffleDataManager.save();
    G.saveState.mapChestDataManager.save();
    G.saveState.mailboxManager.save();
    G.saveState.gateManager.save();
    this.save();
    if (goToWorldState) game.state.start('World');
  }

  /**
   * unlock all gatess
   * @param {number} upTo (optional)
   * @param {boolean} goToWorldState (optional)
   */
  debugUnlockAllGate(upTo = -2, goToWorldState = true) {
    console.warn('DEPRECATED FUNCTION: Please use debugStarsUpTo instead');
  }

  /**
   * debug / activate mystery gift
   * @param {number} streak streak length
   * @param {number} timeLeft duration of mystery gift in seconds
   */
  debugActivateMysteryGift(streak, timeLeft) {
    this.mysteryGiftManager.debugActivateMysteryGift(streak, timeLeft);
    G.sb('pushWindow').dispatch('mysteryGiftStreakIncrese');
  }

  /**
   * debug / give daily reward
   * @param {number} dayNumber number of consecutive days (1-7)
   */
  debugGiveDailyReward(dayNumber) {
    this.data.dailyReward = {
      nextDaily: Math.floor(Date.now() / (24 * 60 * 60 * 1000)),
      currentDay: Math.max(1, Math.min(dayNumber, 7)) - 2,
    };
    this.save();
  }

  /**
   * mark first-time wheel spin
   * @param {boolean} saveNow
   */
  markWheelAsSpun(saveNow = true) {
    this.data.firstTimeWheelSpinner = true;
    if (saveNow) {
      this.save();
    }
  }

  /**
   * check if a message is read
   * @param {string} type typ of message
   * @param {string} id message ID #
   * @returns {boolean}
   */
  isMsgRead(id) {
    const msgTypes = ['payloadGifts', 'acceptedStateChange', 'acceptedLives', 'acceptedLivesRequests', 'restore',
      'acceptedGateRequests', 'thanksMsg', 'quiz', 'promoReward', 'requestHelpMsg',
      'friendshipChestInvite', 'friendshipChestJoin', 'eventMsg', 'fortuneCookieMsg', 'realMoneyWheelSpin'];
    let type;

    for (let i = 0; i < msgTypes.length; i++) {
      type = msgTypes[i];
      if (!this.data[type]) this.data[type] = [];
      if (this.data[type].indexOf(id) >= 0) return true;
    }
    return false;
  }

  /**
   * mark a message as read
   * @param {string} type typ of message
   * @param {string} id message ID #
   */
  markMsgAsRead(type, id) {
    if (!this.data[type]) this.data[type] = [];
    this.data[type].push(id);

    // Limit message id array to number of entries defined in OMT settings
    if (this.data[type].length > G.OMTsettings.maxPayloadIdsSavedPerType) {
      this.data[type] = this.data[type].slice(-G.OMTsettings.maxPayloadIdsSavedPerType);
    }

    this.save();
  }

  /**
   * Check if a message is meant for the current user
   * @param {Object} payload
   */
  isMsgForUser(payload) {
    // If no FBUserID is provided, the message is valid for everyone
    if (!payload.FBUserID) return true;
    return payload.FBUserID === OMT.envData.settings.user.userId;
  }

  /**
   * Saves the result of a real money payload spin
   * @param {string} spinId
   * @param {number} prizeId
   */
  saveRealMoneySpinResult(spinId, prizeId) {
    if (spinId == null || prizeId == null) {
      console.warn('saveRealMoneySpinResult: invalid parameters');
      return;
    }
    const data = this._keyValueManager.getData(DATA_KEYS.SPIN_RESULTS);
    data[spinId] = prizeId;
    this._keyValueManager.setData(DATA_KEYS.SPIN_RESULTS, data);
  }

  /**
   * Fetches a previous real money payload spin result based on spinId
   * @param {string} spinId
   */
  getRealMoneySpinResult(spinId) {
    const data = this._keyValueManager.getData(DATA_KEYS.SPIN_RESULTS);
    if (data[spinId] == null) {
      return null;
    }
    return data[spinId];
  }

  /**
   * Fetches all previous real money payload spin results
   */
  getAllRealMoneySpinResults() {
    const data = this._keyValueManager.getData(DATA_KEYS.SPIN_RESULTS);
    return data;
  }

  /**
   * Removes a previous real money payload spin result
   * @param {string} spinId
   */
  removeRealMoneySpinResult(spinId) {
    const data = this._keyValueManager.getData(DATA_KEYS.SPIN_RESULTS);
    if (data[spinId] == null) {
      console.warn(`removeRealMoneySpinResult: could not find real money wheel spin result with id ${spinId}`);
      return;
    }
    delete data[spinId];
    this._keyValueManager.setData(DATA_KEYS.SPIN_RESULTS, data);
  }

  /**
   * Removes all previous real money payload spin results
   */
  resetRealMoneySpinResults() {
    const data = this._keyValueManager.getDefaultValue(DATA_KEYS.SPIN_RESULTS);
    this._keyValueManager.setData(DATA_KEYS.SPIN_RESULTS, data);
  }

  /* Mystery Gifts */

  /**
   * Inits the mystery gift manager
   */
  _initMysteryGift() {
    this.sessionData[DATA_KEYS.MYSTERY_GIFT] = this._keyValueManager.getData(DATA_KEYS.MYSTERY_GIFT, true);
    this.mysteryGiftManager.save = () => { this._keyValueManager.setData(DATA_KEYS.MYSTERY_GIFT, this.sessionData[DATA_KEYS.MYSTERY_GIFT]); };
    this.mysteryGiftManager.init(this.sessionData[DATA_KEYS.MYSTERY_GIFT]);
  }

  /* Start of REQUEST HELP */

  /**
   * initializes request help data.
   * This is not used anymore as of OMT-1852, but save data was requested to stay
   * @param {boolean} saveNow
   */
  requestHelp_initFriendData(saveNow = false) {
    if (!this.data.requestHelpData) {
      this.data.requestHelpData = {
        friendHelp: 0, // Number of times used
        // friendData: { // Cooldown data managed by user cooldown
        //   SG_bot: this.requestHelp_makeFriendData(),
        // },
        friendInviteSent: new Date(0), // Last time friend invite was sent
        friendAmount: -1, // Last snapshot of how many friends were on the list
        tutorialData: { // Tutorial data
          completions: 0, // The number of times the first step steps are seen
          appearance: 0, // -1 is never, 0 is show now, > 0 is number of levels until it shows
          step: 0, // There are two steps, counting from 0
        },
        uniqueFriends: [], // Unique friends list for tracking on DeltaDNA
        numberOpened: 0, // The number of times Request Help was opened
      };
    }
    if (saveNow) {
      this.save();
    }
  }
  // End of Request Help

  // Start of Friendship Chest
  /**
   * Cards are displayed in order of [Claimed batch] [Claimable Batch] [Friend has joined batch] [Pending Batch] [Invite Friends card] and the last card (which doesn't count)
   * Opened should always be +1 higher than the sum of everything else, unless limit...
   * Think of another way to keep track of things without making it too big of a JSON
   *
   * @param {boolean} saveNow
   */
  friendshipChest_initData(saveNow = false) {
    this.sessionData[FRIENDSHIP_CHEST_SAVE_KEY] = this._keyValueManager.getData(FRIENDSHIP_CHEST_SAVE_KEY, true);
    this.friendshipChestDataManager.save = () => { this._keyValueManager.setData(FRIENDSHIP_CHEST_SAVE_KEY, this.sessionData[FRIENDSHIP_CHEST_SAVE_KEY]); };
    this.friendshipChestDataManager.initData(this.sessionData[FRIENDSHIP_CHEST_SAVE_KEY]);
    if (saveNow) {
      this.friendshipChestDataManager.save();
    }
  }

  /**
   * reset friendshipChest data
   */
  friendshipChest_reset() {
    this._keyValueManager.setData(FRIENDSHIP_CHEST_SAVE_KEY, FriendshipChest_DataManager.getBrandNewData());
    this.friendshipChest_initData(true);
  }

  // End of Friendship Chest

  /**
   * Creates the fortune cookie data
   * @returns {Promise}
   */
  async fortuneCookie_initData() {
    if (OMT.feature.getFortuneCookieEvent(false)) {
      const fortuneCookieData = this._keyValueManager.getData(DATA_KEYS.FORTUNE_COOKIE);
      this.fortuneCookieDataManager.initData(fortuneCookieData);
    }
  }

  /**
   * Sets the fortune cookie data with the key-value manager
   * @param {Object} data from the fortune cookie manager
   */
  fortuneCookie_setData(data) {
    this._keyValueManager.setData(DATA_KEYS.FORTUNE_COOKIE, data);
  }

  /**
   * Initialize the data for the TargetedOfferDataManager
   */
  targetedOffers_initData() {
    // if (!this.data.targetedOffers) this.data.targetedOffers = {};
    this.targetedOffersDataManager.init(this._keyValueManager);
  }

  /**
   * get the current ask count for the ask friends dialog
   * @returns {number}
   */
  adFallback_askFriends_getAskCount() {
    if (this.data.adFallbackAsk === undefined || this.data.adFallbackAsk.asks === undefined) this.data.adFallbackAsk = { asks: 0 };
    return this.data.adFallbackAsk.asks;
  }

  /**
   * set the ask count for the ask for friends dialog
   * @param {number} count
   */
  adFallback_askFriends_setAskCount(count) {
    if (this.data.adFallbackAsk === undefined || this.data.adFallbackAsk.asks === undefined) this.data.adFallbackAsk = { asks: 0 };
    this.data.adFallbackAsk.asks = count;
    this.save();
  }

  /**
   * set cool down time for a user for adFallback
   * @param {string} cooldownId
   * @param {string} userId
   * @param {string} duration duration in milliseconds
   */
  setUserCooldown(cooldownId, userId, duration) {
    if (!this.data.cooldownData) this.data.cooldownData = {};
    const { cooldownData } = this.data;
    const storageId = `${cooldownId}_${userId}`;
    cooldownData[storageId] = Date.now() + duration;
    this.save();
  }

  /**
   * get users cooldown time remaining. If no cool down value will be 0
   * @param {string} cooldownId
   * @param {string} userId
   * @param {boolean} allowNegative allow time remaining to go below 0
   * @param {boolean} cleanUpExpiredKeys delete keys will cooldown time remaining <= 0
   * @returns {number} duration in milliseconds
   */
  getUserCooldownRemaining(cooldownId, userId, allowNegative = false, cleanUpExpiredKeys = true) {
    if (!this.data.cooldownData) this.data.cooldownData = {};
    const { cooldownData } = this.data;
    const storageId = `${cooldownId}_${userId}`;
    if (!cooldownData[storageId]) return 0;

    const currentTime = Date.now();
    const cooldownEnd = parseInt(cooldownData[storageId]);
    const timeRemaining = allowNegative ? cooldownEnd - currentTime : Math.max(cooldownEnd - currentTime, 0);
    if (cleanUpExpiredKeys && timeRemaining <= 0) {
      this.deleteCooldown(cooldownId, userId);
    }
    return timeRemaining;
  }

  /**
   * Deletes the cooldown
   * @param {string} cooldownId
   * @param {string} userId
   */
  deleteCooldown(cooldownId, userId) {
    const storageId = `${cooldownId}_${userId}`;
    // Checking very thoroughly
    if (this.data && this.data.cooldownData && Number.isFinite(this.data.cooldownData[storageId])) {
      delete this.data.cooldownData[storageId];
    }
  }

  /**
   * get seed for a named cooldown
   * @param {string} cooldownId
   * @param {string} duration duration in milliseconds
   * @returns {number}
   */
  getCooldownSeed(cooldownId, duration) {
    if (!this.data.cooldownSeedData) this.data.cooldownSeedData = {};
    const {
      cooldownSeedData,
    } = this.data;
    const storageId = cooldownId;
    if (!cooldownSeedData[storageId]) cooldownSeedData[storageId] = {};
    if (cooldownSeedData[storageId].date < new Date()) {
      cooldownSeedData[storageId].date = Date.now() + duration;
      cooldownSeedData[storageId].seed = Math.random().toString(36).replace(/[^a-z]+/g, '');
    }
    this.save();
    return cooldownSeedData[storageId].seed;
  }

  /**
   * get date of last money spin
   * @return {number}
   */
  getLastMoneySpin() {
    if (this.data.lastMoneySpin === undefined) this.data.lastMoneySpin = 0;
    return this.data.lastMoneySpin;
  }

  /**
   * mark date of last money spin
   */
  markLastMoneySpin() {
    this.data.lastMoneySpin = Date.now();
  }

  /**
   * check if a free money spin is available
   * @returns {boolean}
   */
  isFreeMoneySpinAvailable() {
    return Date.now() - this.getLastMoneySpin() > G.json.settings.freeMoneySpinMinutes * 60000;
  }

  /**
   * set of the last tournament level id played
   */
  setTournamentLastPlayed() {
    this.data.lastTournamentPlayed = OMT.platformTournaments.getTournamentLevelId();
    this.save();
  }

  /**
   * get last tournament level id played
   * @returns {string}
   */
  getTournamentLastPlayed() {
    return this.data.lastTournamentPlayed || null;
  }

  /**
   * flag the tournament promo as seen
   */
  setTournamentPromoSeen() {
    if (this.data.tournamentPromoSeen === true) return;
    this.data.tournamentPromoSeen = true;
    this.save();
  }

  /**
   * check if the tournament promo was seen
   * @returns {boolean}
   */
  getTournamentPromoSeen() {
    return this.data.tournamentPromoSeen || false;
  }

  /**
   * flag the daily challenge promo as seen
   */
  setDailyChallengePromoSeen() {
    if (this.data.dailyChallengePromoSeen === true) return;
    this.data.dailyChallengePromoSeen = true;
    this.save();
  }

  /**
   * check if the daily challenge promo was seen
   * @returns {boolean}
   */
  getDailyChallengePromoSeen() {
    return this.data.dailyChallengePromoSeen || false;
  }

  /**
   * Initializes discount data that keeps track if it is showing
   */
  initDiscountData() {
    if (!this.sessionData.discountData) {
      this.sessionData.discountData = {
        visible: true,
      };
    }
  }

  /**
   * Checks if the discount is initalized or it is and its visible
   */
  canDiscountBeenSeen() {
    return (!this.sessionData.discountData) || (this.sessionData.discountData && this.sessionData.discountData.visible);
  }

  /**
   * Disables the discount if it was showing and as been seen already
   */
  disableDiscount() {
    if (this.sessionData.discountData && this.sessionData.discountData.visible) {
      this.sessionData.discountData.visible = false;
    }
  }

  /**
   * Re-enables the discount viewing
   */
  reEnableDiscount() {
    if (this.sessionData.discountData && !this.sessionData.discountData.visible) {
      this.sessionData.discountData.visible = true;
    }
  }

  /**
   * set the users bot opt-in state
   * @param {boolean} state
   * @returns {boolean} true if state changed
   */
  setBotOptIn(state) {
    if (this.data.botOptIn == null) this.data.botOptIn = false;
    const stateChanged = state !== this.data.botOptIn;
    this.data.botOptIn = state;
    if (stateChanged) this.save();
    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('botMessageActive', state ? 1 : 0, true);
    return stateChanged;
  }

  /**
   * get the users bot opt-in state
   * @returns {boolean}
   */
  getBotOptIn() {
    if (this.data.botOptIn == null) this.data.botOptIn = false;
    return this.data.botOptIn;
  }

  /**
   * get the users IAP purchase count
   * @returns {number}
   */
  getIAPCount() {
    if (!this.data.IAPCount) this.data.IAPCount = 0;
    return this.data.IAPCount;
  }

  /**
   * increment the users IAP purchase count
   * @returns {number} current IAP count
   */
  incrementIAPCount() {
    if (!this.data.IAPCount) this.data.IAPCount = 0;
    this.data.IAPCount++;
    this.save();
    G.saveState.sessionData.shopSpecialType = null;
    return this.data.IAPCount;
  }

  /**
   * update save state on tick
   * @param {number} currentTime
   */
  _onTick(currentTime) {
    // Free spin refresh
    if (Date.now() - this.data.lastDaily >= 86400000) {
      this.data.lastDaily = Date.now();
      this.data.freeSpin = true;
      this.save();
      G.sb('onDailyFreeSpinGain').dispatch();
    }

    // Prize wheel spin quota refill
    if (OMT.feature.prizeWheelIsLimited() && this.isPrizeWheelOnCooldown()) {
      const cooldownRemaining = this.getUserCooldownRemaining('prizeWheelSpins', '');
      if (cooldownRemaining === 0) {
        this.resetPrizeWheelSpinCount();
        G.sb('onPrizeWheelSpinRefill').dispatch();
      }
    }

    // Loss aversion wheel spin quota refill
    if (OMT.feature.lossAversionWheelIsLimited() && this.isLossAversionWheelOnCooldown()) {
      let cooldownRemaining = this.getUserCooldownRemaining('lossAversionWheelSpins', '', true);
      if (cooldownRemaining <= 0) {
        this.reEnableDiscount();
        const refillTimeInMs = G.json.settings.lossAversionWheelLimits.refillTime * MILLISECONDS_IN_MIN;

        // Give back spins based on how long it has been since the last check
        do {
          this.decrementLossAversionWheelSpinCount(1);
          cooldownRemaining += refillTimeInMs;
        } while (cooldownRemaining <= -refillTimeInMs);

        if (this._wheelData.lossAversionWheelSpins === 0) {
          G.sb('onLossAversionSpinRefill').dispatch(true);
        } else {
          // Calculate time offset for next cooldown
          cooldownRemaining %= refillTimeInMs;
          cooldownRemaining -= refillTimeInMs;

          this.setLossAversionWheelOnCooldown(cooldownRemaining);
          G.sb('onLossAversionSpinRefill').dispatch(false);
        }
      }
    }

    // Life refill
    const lives = this.getLives();
    if (lives >= G.json.settings.livesMax) this.data.lastRefillDate = Date.now();
    if (lives < G.json.settings.livesMax) {
      if (this.data.lastRefillDate && this.data.lastRefillDate > currentTime) { // Prevents people from going back in time
        this.data.lastRefillDate = currentTime;
      }
      const diff = currentTime - this.data.lastRefillDate;
      const nrOfLivesToAdd = Math.floor(diff / this._refillRate);
      if (nrOfLivesToAdd > 0) {
        this.data.lastRefillDate += nrOfLivesToAdd * this._refillRate;
        this.addLife(nrOfLivesToAdd, false, true);
      }
      // Just in case going back in time wasn't the issue
      const secLeft = Math.min(Math.round((this._refillRate - (currentTime - this.data.lastRefillDate)) / 1000), (this._refillRate / 1000));
      G.sb('onLifeTimerUpdate').dispatch(secLeft);
    }

    if (this.treasureHuntManager && OMT.feature.isTreasureHuntOn(true, false, false, true)) {
      if (this.getUserCooldownRemaining(TREASURE_HUNT_TIME_RECHECK, '') === 0) {
        this.treasureHuntManager.recalculateTime(true);
      }
    }
  }

  /**
   * restore user progress to a specified level
   * @param {number} levelsCompleted amount of levels completed
   * @param {number} coins
   * @returns {boolean} success state, always seems to be true?
   */
  restoreProgress(levelsCompleted, coins, starsPerLevel) {
    const saveData = this.data;
    levelsCompleted = Math.max(0, Math.min(levelsCompleted || 0, G.Helpers.levelDataMgr.getNumberOfLevels()));
    this.changeCoins(coins);

    const localLevels = saveData.levels;
    if (levelsCompleted > localLevels.length) {
      this._saveStateFixer.fixSaveData(saveData, levelsCompleted, 'remoteLevelsHigherThanLocal', false, starsPerLevel);
    }
    this._progressWasRestored = true;
    this.save();
    return true;
  }

  /**
   * apply a user state change
   * @param {number} levelsCompleted
   * @param {number} coins
   * @param {number} boosters
   * @param {number} unlimitedLives
   */
  applyStateChange(levelsCompleted, coins, boosters, unlimitedLives) {
    const saveData = this.data;
    const currentBoosters = this.getBoosterArray();
    if (coins) this.setCoins(coins);
    if (boosters && boosters.length === currentBoosters.length) this.setBoosterArray(boosters);
    if (levelsCompleted) {
      // ensures the levelsCompleted is between 0 and the max number of levels
      levelsCompleted = Math.max(0, Math.min(levelsCompleted || 0, G.Helpers.levelDataMgr.getNumberOfLevels()));
      const localLevels = saveData.levels.length;
      if (levelsCompleted > localLevels) {
        this._saveStateFixer.fixSaveData(saveData, levelsCompleted, '', true);
      }
    }
    if (unlimitedLives) {
      this.setUserCooldown('unlimitedLives', '', 0);
      this.addUnlimitedLivesTimeMin(unlimitedLives, false);
    }
    this.save();
    return true;
  }

  /**
   * true if progress was restored this session
   * @returns {boolean}
   */
  get progressWasRestored() {
    return this._progressWasRestored;
  }

  /**
   * toggle whether hints should be displayed
   * @param {boolean} isOn
   */
  toggleAllowHints(isOn) {
    if (isOn === undefined) {
      isOn = !this.data.allowHints;
    }
    this.data.allowHints = isOn;
    G.sb('stateHintToggled').dispatch(this.data.allowHints);
    this.save();
  }

  /**
   * Get current allowHints state
   */
  getAllowHints() {
    return this.data.allowHints;
  }

  /**
   * Loads wheel data from local storage
   */
  loadWheelData() {
    let wheelDataStr;
    try {
      wheelDataStr = localStorage.getItem('wheelData');
    } catch (e) {
      wheelDataStr = null;
    }
    if (!wheelDataStr) {
      const newWheelData = {
        prizeWheelSpins: 0,
        lossAversionWheelSpins: 0,
      };
      try {
        localStorage.setItem('wheelData', JSON.stringify(newWheelData));
      } catch (e) {
        OMT_Utils.stylizedLog('Unable to save local storage key \'wheelData\'', '#FFFF00');
      }
      return newWheelData;
    }

    const wheelData = JSON.parse(wheelDataStr);
    return wheelData;
  }

  /**
   * Gets prize wheel spin counter
   */
  getPrizeWheelSpinCount() {
    return this._wheelData.prizeWheelSpins;
  }

  /**
   * Increments prize wheel spin counter
   * @param {number} value should be an integer
   */
  incrementPrizeWheelSpinCount(value) {
    this._wheelData.prizeWheelSpins += value;
    try {
      localStorage.setItem('wheelData', JSON.stringify(this._wheelData));
    } catch (e) {
      OMT_Utils.stylizedLog('Could not get local storage key \'wheelData\'', '#FFFF00');
    }
    return this._wheelData.prizeWheelSpins;
  }

  /**
   * Resets prize wheel spin counter
   */
  resetPrizeWheelSpinCount() {
    this._wheelData.prizeWheelSpins = 0;
    try {
      localStorage.setItem('wheelData', JSON.stringify(this._wheelData));
    } catch (e) {
      OMT_Utils.stylizedLog('Could not get local storage key \'wheelData\'', '#FFFF00');
    }
    return this._wheelData.prizeWheelSpins;
  }

  /**
   * Check if prize wheel is on cooldown
   * @returns {boolean}
   */
  isPrizeWheelOnCooldown() {
    return this._wheelData.prizeWheelSpins >= G.json.settings.prizeWheelLimits.numSpins;
  }

  /**
   * Sets the prize wheel on cooldown
   */
  setPrizeWheelOnCooldown() {
    this.setUserCooldown(
      'prizeWheelSpins',
      '',
      G.json.settings.prizeWheelLimits.refillTime * MILLISECONDS_IN_MIN,
    );
  }

  /**
   * Gets loss aversion wheel spin counter
   */
  getLossAversionWheelSpinCount() {
    return this._wheelData.lossAversionWheelSpins;
  }

  /**
   * Increments loss aversion wheel spin counter
   * @param {number} value should be an integer
   */
  incrementLossAversionWheelSpinCount(value) {
    this._wheelData.lossAversionWheelSpins += value;
    try {
      localStorage.setItem('wheelData', JSON.stringify(this._wheelData));
    } catch (e) {
      OMT_Utils.stylizedLog('Could not get local storage key \'wheelData\'', '#FFFF00');
    }
    return this._wheelData.lossAversionWheelSpins;
  }

  /**
   * Decrements loss aversion wheel spin counter
   * @param {number} value should be an integer
   */
  decrementLossAversionWheelSpinCount(value) {
    const maxSpins = Math.min(
      this._wheelData.lossAversionWheelSpins,
      G.json.settings.lossAversionWheelLimits.numSpins,
    );
    this._wheelData.lossAversionWheelSpins = Math.max(maxSpins - value, 0);
    try {
      localStorage.setItem('wheelData', JSON.stringify(this._wheelData));
    } catch (e) {
      OMT_Utils.stylizedLog('Could not get local storage key \'wheelData\'', '#FFFF00');
    }
    return this._wheelData.lossAversionWheelSpins;
  }

  /**
   * Resets loss aversion wheel spin counter
   */
  resetLossAversionWheelSpinCount() {
    this._wheelData.lossAversionWheelSpins = 0;
    try {
      localStorage.setItem('wheelData', JSON.stringify(this._wheelData));
    } catch (e) {
      OMT_Utils.stylizedLog('Could not get local storage key \'wheelData\'', '#FFFF00');
    }
    return this._wheelData.lossAversionWheelSpins;
  }

  /**
   * Check if loss aversion wheel is on cooldown
   * @returns {boolean}
   */
  isLossAversionWheelOnCooldown() {
    return this._wheelData.lossAversionWheelSpins > 0;
  }

  /**
   * Sets the loss aversion wheel on cooldown
   * @param {number} timeOffset offset cooldown time in milliseconds
   */
  setLossAversionWheelOnCooldown(timeOffset = 0) {
    this.setUserCooldown(
      'lossAversionWheelSpins',
      '',
      G.json.settings.lossAversionWheelLimits.refillTime * MILLISECONDS_IN_MIN + timeOffset,
    );
  }

  /*
   * Mailbox functions
   */
  _initMailboxData() {
    const data = OMT.userData.getUserData(MAILBOX_MANAGER_DATA_KEY);
    if (!data) {
      this.sessionData[MAILBOX_MANAGER_DATA_KEY] = {};
    } else {
      this.sessionData[MAILBOX_MANAGER_DATA_KEY] = data;
    }
    this.mailboxManager.init(this.sessionData[MAILBOX_MANAGER_DATA_KEY]);
  }

  /**
   * check if real money wheel is on cooldown
   * @returns {boolean}
   */
  getRealMoneyWheelOnCoolDown() {
    return this.getUserCooldownRemaining('realMoneyWheel', '');
  }

  /**
   * set the real money wheel cooldown
   * @param {boolean} isConversion
   */
  setRealMoneyWheelOnCoolDown(isConversion) {
    const cooldown = isConversion
      ? G.json.settings.realMoneyPrizeWheel.conversionCooldown
      : G.json.settings.realMoneyPrizeWheel.highValueCooldown;

    this.setUserCooldown(
      'realMoneyWheel',
      '',
      cooldown * 60 * 1000,
    );
  }

  /**
   * Track a sent gate invite
   * @param {number} gateId
   */
  trackSentGateInvite(gateId) {
    this.gateManager.increaseInvitesOutForGate(gateId);
    this.gateManager.save();
  }

  /**
   * Loads milestone tracking data from key value manager
   * @returns {Object}
   */
  getMilestoneTrackingData() {
    return this._keyValueManager.getData(DATA_KEYS.MILESTONE_TRACKING);
  }

  /**
   * Saves milestone tracking data from key value manager
   * @param {Object} data
   */
  saveMilestoneTrackingData(data) {
    this._keyValueManager.setData(DATA_KEYS.MILESTONE_TRACKING, data);
  }

  /**
   * Returns data used for the tier leaderboard. Has to be the correct datakey
   * @param {string} dataKey
   * @returns {{instanceId:string, rating:number, instanceSlug:string}}
   */
  getTierLeaderboardData(dataKey) {
    switch (dataKey) {
      case DATA_KEYS.TREASURE_HUNT: return this.treasureHuntManager.leaderboardData;
      default: return {};
    }
  }

  /**
   * Saves the data from a tier leaderboard. HAs to be correct data key
   * @param {string} dataKey
   * @param {{instanceId:string, rating:number, instanceSlug:string}}
   */
  saveTierLeaderboardData(dataKey, data) {
    switch (dataKey) {
      case DATA_KEYS.TREASURE_HUNT: this.treasureHuntManager.saveLeaderboardState(data); break;
      default: break;
    }
  }

  /**
   * Gets number of ads (formerly sessions) since the last time the no ads popup appeared
   * @returns {number}
   */
  getAdsSinceLastNoAdsPopup() {
    return this._keyValueManager.getData(DATA_KEYS.NO_ADS_POPUP).sessionsNotSeen;
  }

  /**
   * Sets the number of ads (formerly sessions) since the last time the no ads popup appeared
   * @param {number} number
   */
  setAdsSinceLastNoAdsPopup(number) {
    const data = this._keyValueManager.getData(DATA_KEYS.NO_ADS_POPUP);
    data.sessionsNotSeen = number;
    this._keyValueManager.setData(DATA_KEYS.NO_ADS_POPUP, data);
  }

  /**
   * Changes the multiplier of all IAP purchases.
   * Very tricky. Should be 1 at all times unless gifted
   * @param {number} i
   */
  changeIAPMultiplier(i) {
    if (Number.isFinite(i)) {
      this.data.iapMultiplier = Math.max(1, i);
    }
  }

  /**
   * @returns {number}
   */
  get iapMultiplier() {
    return this.data.iapMultiplier || 1;
  }

  /**
   * Get player's targeted offer data
   * @returns {{co:Object, oq:Array}}
   */
  getTargetedOfferData() {
    return this._keyValueManager.getData(DATA_KEYS.TARGETED_OFFERS);
  }

  /**
   * Set player's targeted offer data
   * @param {Object} data
   */
  setTargetedOfferData(data) {
    this._keyValueManager.setData(DATA_KEYS.TARGETED_OFFERS, data);
  }

  /**
   * save changes to the save state
   * @returns {Promise}
   */
  async save(saveId = '') {
    if (saveId !== '') console.log(`G.saveState.save(${saveId})`);
    OMT.userData.writeUserData(DATA_KEY, JSON.stringify(SaveStateUtils.addTimestampToObj(this.data)));
  }
}

// create global reference
G.saveState = new SaveState();
