import WorldMap2_Util, { interactionType, WorldMap2_globalSignals } from '../WorldMap2_Util';

export const LAYOUT_TYPE = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  NONE: 'NONE',
};

const LAYOUT_STYLE = {
  TOP: {
    rotation: 0,
    labelOffset: { x: 0, y: 50 },
    offsetY: {
      vertical: 160,
      horizontal: 160,
    },
  },
  BOTTOM: {
    rotation: 0,
    labelOffset: { x: 0, y: 43 },
    offsetY: {
      vertical: 290,
      horizontal: 230,
    },
  },
};

const IMAGE_SIZE = 85;
const POINTER_IMAGE_OFFSET = -11;
const INTERACTION_CHECK_TIMER = 4000;

/**
 * class for quick pan avatar button in the WorldMap
 */
export default class MapInteractionPanButton extends G.Button {
  /**
   * A saga map pointer that shows what interactions are available.
   * Only shows up on the bottom of the map
   * @param {Function} levelCalculations
   */
  constructor(levelCalculations) {
    super(0, 0, null);

    this._worldLevelRangeCalculations = levelCalculations;
    this._signalToken = G.sb(WorldMap2_globalSignals.recheckInteractions).add(this._assembleData.bind(this));

    this._assembleData();
    this._initFrame();
    this._initLoopTween();
    this._initStates(LAYOUT_TYPE.NONE);
  }

  /**
   * Destroy!
   */
  destroy() {
    this._killAllTweens();
    if (this._signalToken) {
      if (this._signalToken.detach) {
        this._signalToken.detach();
      }
      this._signalToken = null;
    }
    if (this._interactionImages) {
      for (const key in this._interactionImages) {
        if (this._interactionImages[key].destroy) {
          this._interactionImages[key].destroy();
        }
      }
    }
    if (this._currentState) {
      this._states[this._currentState].onExit();
      this._currentState = null;
    }
    this.stopScaleTween();
    super.destroy();
  }

  /**
   * Stops all fade tweens for interactions
   */
  _killAllTweens() {
    for (const tween of this._allTweens) if (tween.stop) { tween.stop(); }
    this._allTweens.length = 0;
  }

  /**
   * Initializes states for the button.
   * @param {LAYOUT_TYPE} initialState
   */
  _initStates(initialState) {
    this._states = {};
    this._states[LAYOUT_TYPE.NONE] = {
      onEnter: this._stateTypeNoneEnter.bind(this),
      onExit: this._stateTypeNoneExit.bind(this),
    };
    this._states[LAYOUT_TYPE.TOP] = {
      onEnter: this._stateTypeTopEnter.bind(this),
      onExit: this._stateTypeTopExit.bind(this),
    };
    this._states[LAYOUT_TYPE.BOTTOM] = {
      onEnter: this._stateTypeBottomEnter.bind(this),
      onExit: this._stateTypeBottomExit.bind(this),
    };
    if (initialState) {
      this.layoutState = initialState;
    }
  }

  /**
   * State type NONE enter
   */
  _stateTypeNoneEnter() {
    this.animateOut();
    this._currentInteraction = null;
  }

  /**
   * State type NONE exit
   */
  _stateTypeNoneExit() {
    // Nothing
  }

  /**
   * State type TOP enter
   */
  _stateTypeTopEnter() {
    this._setLayoutType(this._currentState);
    this.animateIn();
  }

  /**
   * State type TOP exit
   */
  _stateTypeTopExit() {
    // Nothing
  }

  /**
   * State type BOTTOM enter
   */
  _stateTypeBottomEnter() {
    this._setLayoutType(this._currentState);
    this.animateIn();
  }

  /**
   * State type BOTTOM exit
   */
  _stateTypeBottomExit() {
    // Nothing
  }

  /**
   * Sets the layout state to what was given
   * @param {LAYOUT_TYPE} newState
   */
  set layoutState(newState) {
    if (this._currentState !== newState) {
      if (this._currentState) {
        this._states[this._currentState].onExit();
      }
      this._currentState = newState;
      this._states[this._currentState].onEnter();
    }
  }

  /**
   * Returns the current layout state
   * @returns {LAYOUT_STYLE}
   */
  get layoutState() {
    return this._currentState;
  }

  /**
   * Returns the style of the pan button
   * @returns {LAYOUT_STYLE}
   */
  get currentInteraction() {
    return this._currentInteraction;
  }

  /**
   * Collects a copy of all interactions that can be considered and keeps it locally to prevent it from
   * querying the managers all the time
   */
  _assembleData() {
    this._awaitInteractions = true;
    this._interactions = [];
    this._allTweens = [];
    if (this._interactionImages) {
      for (const key in this._interactionImages) {
        if (this._interactionImages[key].destroy) {
          this._interactionImages[key].destroy();
        }
      }
    }
    this._interactionImages = {};

    for (const key in interactionType) { // eslint-disable-line guard-for-in
      switch (interactionType[key]) {
        // case interactionType.mailbox: // Mailbox
        //   this._setupMailboxAssets();
        //   break;
        case interactionType.chestMap: // Saga chest map
          this._setupSagaMapChestAssets();
          break;
        case interactionType.chestShuffle: // Chest shuffle entries
          this._setupChestShuffleAssets();
          break;
        default: continue;
      }
    }
    this._interactions.sort((a, b) => { // Sorts each entry by level
      if (a.level < b.level) { return 1; }
      if (a.level > b.level) { return -1; }
      return 0;
    });
    this._awaitInteractions = false;
  }

  /**
   * Builds assets and assembles data for mailbox interaction
   */
  _setupMailboxAssets() {
    const key = interactionType.mailbox;
    const unopened = G.saveState.mailboxManager.getAllExistingMailboxLevels().sort(); // Grabs all mailboxes and sorts by level
    if (unopened.length > 0) { // If there is a mailbox, create the image
      const img = this._interactionImages[key] = G.makeImage(-5, 0, 'map_mailbox_open', 0.5, null);
      img.scale.set(Math.min(IMAGE_SIZE / img.width, IMAGE_SIZE / img.height));

      for (const level of unopened) {
        this._interactions.push({
          key,
          level,
        });
      }
    }
  }

  /**
   * Builds assets and assembles data for the saga map chest
   */
  _setupSagaMapChestAssets() {
    const key = interactionType.chestMap;
    const unopened = G.saveState.mapChestDataManager.getAllEligibleUnopenedChests().sort(); // Gets all unopened chests and sorts by level
    if (unopened.length > 0) { // Create image if there are any
      const img = this._interactionImages[key] = G.makeImage(0, 0, 'chest', 0.5, null);
      img.scale.set(Math.min(IMAGE_SIZE / img.width, IMAGE_SIZE / img.height));

      for (const level of unopened) {
        this._interactions.push({
          key,
          level,
        });
      }
    }
  }

  /**
   * Builds asset assembles data for the chest shuffle interaction
   */
  _setupChestShuffleAssets() {
    const key = interactionType.chestShuffle;
    const unopened = G.saveState.chestShuffleDataManager.getAllExistingChestLevel().sort(); // Collects all unopened chests and sort
    if (unopened.length > 0) { // Create image
      const img = this._interactionImages[key] = G.makeImage(0, 0, 'chestShuffleGingyFull', 0.5, null); // Fancy Gingy only
      img.scale.set(Math.min(IMAGE_SIZE / img.width, IMAGE_SIZE / img.height));

      for (const level of unopened) {
        this._interactions.push({
          key,
          level,
        });
      }
    }
  }

  /**
   * init the frame overlay for the avatar
   */
  _initFrame() {
    this._pointer = new Phaser.Group(game, this);
    G.makeImage(0, 0, 'map_interaction_pointer', 0.5, this._pointer);
    this._interactionContainer = new Phaser.Group(game, this._pointer);
    const buttonMask = new Phaser.Graphics(game);
    buttonMask.beginFill(0);
    buttonMask.drawCircle(0, 0, IMAGE_SIZE);
    buttonMask.endFill();
    this._interactionContainer.mask = buttonMask;

    buttonMask.y = this._interactionContainer.y = POINTER_IMAGE_OFFSET;
    this._pointer.addChild(buttonMask);
  }

  /**
   * set the layout type / style
   * @param {string} layoutType as defined in LAYOUT_TYPE
   */
  _setLayoutType(layoutType) {
    const layoutStyle = LAYOUT_STYLE[layoutType];
    if (!layoutStyle) return;

    this.angle = layoutStyle.rotation;

    switch (layoutStyle) {
      case LAYOUT_STYLE.TOP: this.y = LAYOUT_STYLE[this._currentState].offsetY[OMT.systemInfo.orientationKey]; break;
      case LAYOUT_STYLE.BOTTOM: this.y = game.height - LAYOUT_STYLE[this._currentState].offsetY[OMT.systemInfo.orientationKey]; break;
      default: break;
    }
  }

  /**
   * Resizes accordingly
   */
  resize() {
    if (this._currentState !== null) this._setLayoutType(this._currentState);
  }

  /**
   * animateIn quick pan
   */
  animateIn() {
    this.visible = true;
    this.scale.set(0);
    this.stopScaleTween();
    this._scaleTween = game.add.tween(this.scale).to({ x: 1, y: 1 }, 250, Phaser.Easing.Sinusoidal.Out, true);
    this._scaleTween.onComplete.add(this._resumeLoopTween, this);
  }

  /**
   * animateIn quick pan
   */
  animateOut() {
    this.stopScaleTween();
    this._scaleTween = game.add.tween(this.scale).to({ x: 0, y: 0 }, 250, Phaser.Easing.Sinusoidal.Out, true);
    this._scaleTween.onComplete.add(this._onAnimateOutComplete, this);
  }

  /**
   * on animateOut animation completed
   */
  _onAnimateOutComplete() {
    this.visible = false;
    this._pauseLoopTween();
  }

  /**
   * init attract / loop animation
   */
  _initLoopTween() {
    this._loopTween = game.add.tween(this.pivot);
    this._loopTween.to({ y: 10 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    this._loopTween.pause();
  }

  /**
   * resume loop animation
   */
  _resumeLoopTween() {
    this._loopTween.resume();
  }

  /**
   * pause loop animation
   */
  _pauseLoopTween() {
    this._loopTween.resume();
  }

  /**
   * Tweens the x position of this button
   * @param {number} pos
   */
  tweenXPositionTo(pos) {
    if (pos !== this.x && !this._xTween) {
      this._xTween = game.add.tween(this)
        .to({ x: pos }, 200, Phaser.Easing.Linear.None, true);
      this._xTween.onComplete.add(this._nullXTween.bind(this));
    }
  }

  /**
   * Nulls the xtween when complete
   */
  _nullXTween() {
    this._xTween = null;
  }

  /**
   * Starts the timer for interaction checking
   */
  startInteractionCheck() {
    if (!this._interactionTimer) {
      // TODO: Maybe use a debounce
      this._interactionTimer = game.time.events.loop(INTERACTION_CHECK_TIMER, this._checkInteraction.bind(this));
      this._checkInteraction();
    }
  }

  /**
   * Checks interactions that can be displayed and displays the next interaction
   */
  _checkInteraction() {
    if (this._interactions.length === 0 && !this._awaitInteractions) {
      this.killTimer();
      this.layoutState = LAYOUT_TYPE.NONE;
      return;
    }

    const currentLevels = this._worldLevelRangeCalculations(); // Determines what level the world map is on right now
    const consideringInteractions = this._interactions.filter((interaction) => interaction.level < currentLevels.min); // Filters interactions that are less than current level
    if (consideringInteractions.length > 0) {
      this.layoutState = LAYOUT_TYPE.BOTTOM; // Switches state to bottom
      // Attempt to find index of current interaction. If there is none, it'll be 0 or 1
      const currentIndex = this._currentInteraction // eslint-disable-line no-restricted-globals
        ? consideringInteractions.findIndex((interaction) => interaction.key === this._currentInteraction.key && interaction.level === this._currentInteraction.level)
        : 0;

      // Selects the next interaction, looping around the array if needed
      const targetInteraction = WorldMap2_Util.getEntryInArrayLoop(consideringInteractions, currentIndex + 1);

      // Shows the interaction (if theres a current interaction and target is different, or there is no interaction)
      if (
        (this._currentInteraction && !(targetInteraction.key === this.currentInteraction.key && targetInteraction.level === this.currentInteraction.level))
        || !this.currentInteraction
      ) {
        this._currentInteraction = targetInteraction;
        this._fillCenterWithInteraction(this._currentInteraction);
      }
    } else { // If no interaction, null, and hide the pointer
      this._currentInteraction = null;
      this.layoutState = LAYOUT_TYPE.NONE;
    }
  }

  /**
   * Removes the interactionTimer
   */
  killTimer() {
    if (this._interactionTimer) {
      game.time.events.remove(this._interactionTimer);
      this._interactionTimer = null;
    }
  }

  /**
   * Fills the pointer with the image of the interction
   * @param {{key: string, level:number}} interaction
   */
  _fillCenterWithInteraction(interaction) {
    this._killAllTweens();
    this._interactionContainer.alpha = 1;
    const tw1 = game.add.tween(this._interactionContainer).to({ alpha: 0 }, 250, Phaser.Easing.Sinusoidal.In);
    tw1.onComplete.add(() => {
      this._interactionContainer.removeChildren();
      this._interactionContainer.addChild(this._interactionImages[interaction.key]);
    });
    const tw2 = game.add.tween(this._interactionContainer).to({ alpha: 1 }, 250, Phaser.Easing.Sinusoidal.Out);

    tw1.chain(tw2);
    tw1.start();
    this._allTweens.push([tw1, tw2]);
  }

  /**
   * Checks and stops scale tween
   */
  stopScaleTween() {
    if (this._scaleTween && this._scaleTween.stop) this._scaleTween.stop();
  }
}
