/* eslint-disable no-multi-spaces */
/* eslint-disable prefer-object-spread */

/**
* class for helping form social events
*/
class DDNASocialTracker {
  /**
   * constructor
   */
  constructor() {
    this._data = null;
  }

  /**
   * set a social parameter value
   * @param {string} paramName
   * @param {*} value
   */
  setParam(paramName, value) {
    this._data[paramName] = value;
    this.writeData();
  }

  /**
   * increment (or decrement) a parameter value. If parameter does not exists, a new parameter is created with the value specified
   * @param {string} paramName
   * @param {number} relativeValue use negative value to decrement
   */
  incrementParam(paramName, relativeValue) {
    if (Object.prototype.hasOwnProperty.call(this._data, paramName)) {
      this._data[paramName] += relativeValue;
    } else {
      this._data[paramName] = relativeValue;
    }
    this.writeData();
  }

  /**
   * add friend ID to interacted-with list
   * @param {string} friendId
   */
  markFriendInteraction(friendId) {
    if (!this._data.friendsInteractedWith.includes(friendId)) {
      this._data.friendsInteractedWith.push(friendId);
    }
    this.writeData();
  }

  /**
   * get an object with all social parameters for DDNA tracking
   * @returns {object}
   */
  async getParamObject() {
    if (!this._data) return {};

    const paramObj = Object.assign({}, this._data);
    paramObj.numFriendsInteractedWith = paramObj.friendsInteractedWith.length;
    delete paramObj.friendsInteractedWith;

    try {
      const friendsList = await OMT.friends.getFriendsList();
      paramObj.numFriendsInApp = friendsList.length;
    } catch {
      paramObj.numFriendsInApp = -1;
    }

    return paramObj;
  }

  /**
   * gets data object
   * @returns {object}
   */
  _getData() {
    return this._data;
  }

  /**
   * creates new data object
   */
  createDefaultData() {
    this._data = {
      numSocialShares: 0,
      numFriendsChallengeSent: 0,
      numFriendsChallengeReceived: 0,
      numLivesRequested: 0,
      numFriendsInvites: 0,
      numSendLives: 0,
      friendsInteractedWith: [],
      numCoinSends: 0,               // Total number of coins
      numFriendsSentCoins: 0,        // Number of transactions
      // numFriendsInApp tracked using friends list
    };

    this.writeData();
  }

  /**
   * saves data object to data store
   */
  writeData() {
    // DDNA.tracking.addToSaveData('socialData', this._data, true);
  }

  /**
   * sets data object from data store
   */
  setData(data) {
    this._data = data;
  }
}

// create global references
if (!window.DDNA) window.DDNA = {};
DDNA.socialTracker = new DDNASocialTracker();
