G.Input = G.Input || {};
G.Input.createCustomBlockInputHandler = () => {
  const signal = new Phaser.Signal();

  const handleEventShared = () => true;

  const handleEventExclusive = (eventType) => {
    signal.dispatch(eventType);
    return true;
  };

  const cancelEvent = () => {};

  const customInput = {
    // custom input handler interface
    handleEventShared,
    handleEventExclusive,
    cancelEvent,

    // custom
    signal,
  };

  return customInput;
};
