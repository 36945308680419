G.Helpers = G.Helpers || {};

G.Helpers.createTaskCollectPanels = (goals, style = G.OMTsettings.elements.taskCollectPanels.amount.style) => {
  const group = game.make.group();
  const settings = G.json['configs/gameplayGoals'];

  const tasks = [];

  // create icon / labels for each goal
  goals.forEach((goal) => {
    const labelSpacing = 20;
    const task = game.make.group();

    const icon = G.makeImage(0, 0, settings[goal[0]].sprite, 0, null);
    icon.anchor.y = 0.5;
    icon.scale.setTo(0.8);

    const amount = new G.Text(0, 0, goal[1].toString(), style, 0, 85);
    amount.x = icon.width + labelSpacing;
    amount.anchor.y = 0.45;

    task.addMultiple([icon, amount]);
    task.totalWidth = icon.width + amount.getBounds().width + labelSpacing;
    tasks.push(task);
  });

  group.addMultiple(tasks);

  const maxWidth = 480;
  const minItemSpacing = 40;
  const maxItempacing = 60;
  G.centerElementsInContainer(group, minItemSpacing, maxItempacing, maxWidth);

  return group;
};
