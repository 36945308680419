export default class Sparkle extends Phaser.Group {
  constructor(x, y, initialScale) {
    super(game);
    this.x = x;
    this.y = y;
    this._sparkle = G.makeImage(0, 0, 'realMoney_sparkle', 0.5, this);
    this._sparkle.scale.setTo(initialScale);
    this._sparkle.alpha = 0.5;
    this.alpha = 0;

    this._groupFadeInAnim = game.add.tween(this)
      .to({ alpha: 1 }, 500, Phaser.Easing.Linear.None, false, 0, 0, false);
    this._groupFadeInAnim.onComplete.add(() => this.startTwinkle());

    this._fadeAnim = game.add.tween(this._sparkle)
      .to({ alpha: 1 }, 1000, Phaser.Easing.Sinusoidal.InOut, false, 0, -1, true);
    this._pulseAnim = game.add.tween(this._sparkle.scale)
      .to({ x: initialScale * 0.75, y: initialScale * 0.75 }, 1000, Phaser.Easing.Sinusoidal.InOut, false, 0, -1, true);
  }

  startFadeIn() {
    this._groupFadeInAnim.start();
  }

  startTwinkle() {
    this._fadeAnim.start();
    this._pulseAnim.start();
  }

  stopAnims() {
    this._groupFadeInAnim.stop();
    this._fadeAnim.stop();
    this._pulseAnim.stop();
  }
}
