/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */

import OMT_UI_HorizontalScrollBarConfig from '../ScrollBarConfig/OMT_UI_HorizontalScrollBarConfig';
import OMT_UI_ScrollBarBase from './OMT_UI_ScrollBarBase';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';

/**
 * scroll bar for the scroll window
 */
export default class OMT_UI_HorizontalScrollBar extends OMT_UI_ScrollBarBase {
  /**
   * constructor
   * @param {Phaser.Rectangle} scrollRect
   * @param {OMT_UI_HorizontalScrollBarConfig} scrollBarConfig
   */
  constructor(scrollRect, scrollBarConfig) {
    super(scrollRect, scrollBarConfig);

    this._autoHide = this._config.autoHide;

    // adjust the position, width, bounds of the scrollbar
    this.y = this._config.overlapScrollArea ? this._scrollRect.height - this._config.height : this._scrollRect.height;
    this.x = (((1 - this._config.widthRatio) * this._scrollRect.width) / 2) + this._config.offsetX;
    this._scrollRect.width *= this._config.widthRatio;
  }

  /**
   * on drag update
   * @param {Phaser.Sprite} sprite
   * @param {Object} pointer
   * @param {number} dragX
   * @param {number} dragY
   * @param {Object} snapPoint
   */
  _dragUpdate(sprite, pointer, dragX, dragY, snapPoint) {
    this._scrollKnob.y = 0;

    const knobWidth = this._knobWidth;
    const maxX = this._scrollRect.width - knobWidth;
    const minX = 0;

    if (this._scrollKnob.x > maxX) this._scrollKnob.x = maxX;
    else if (this._scrollKnob.x < minX) this._scrollKnob.x = minX;

    this.signals.onScrollChange.dispatch();
  }

  /**
   * update the scrollKnob graphics
   * @param {number} viewPercent 0-1
   */
  updateScrollKnobGraphics(viewPercent) {
    viewPercent = Math.min(Math.max(0, viewPercent), 1);
    this._viewPercent = viewPercent;

    const knobWidth = this._scrollRect.width * viewPercent;
    const { cornerSize } = this._config;

    this._scrollKnob.scale.y = 1;
    const minGraphicsHeight = (cornerSize * 2) + this._config.minMiddleHeight;
    const graphicsHeight = this._config.height > minGraphicsHeight ? this._config.height : minGraphicsHeight;

    if (this._scrollKnob.graphics) { // resize existing 9-slice
      this._scrollKnob.graphics.resize(knobWidth, graphicsHeight);
      if (this._scrollKnobBackground) {
        this._scrollKnobBackground.resize(this._scrollRect.width, graphicsHeight);
      }
    } else { // create new 9 slice graphics
      if (this._config.scrollBackground) {
        this._scrollKnobBackground = new UI_NineSlice(
          0, 0, this._config.scrollBackground, this._scrollRect.width, graphicsHeight,
          {
            left: cornerSize, right: cornerSize, top: cornerSize, bottom: cornerSize,
          },
        );
        this._scrollKnobBackground.height = this._config.height; // ??? Why is this height potentially different from what is set!?
        this.addChild(this._scrollKnobBackground);
      }

      this._scrollKnob.graphics = new UI_NineSlice(
        0, 0, this._config.knobAssetFrame, knobWidth, graphicsHeight,
        {
          left: cornerSize, right: cornerSize, top: cornerSize, bottom: cornerSize,
        },
      );
      this._scrollKnob.addChild(this._scrollKnob.graphics);
    }
    this._scrollKnob.graphics.tint = this._config.knobTint;
    this._scrollKnob.graphics.height = this._config.height;

    // update knob width for other functions
    this._knobWidth = knobWidth;
  }

  /**
   * set the scroll percent / position
   * @param {number} percent 0-1
   * @param {boolean} dispatchEvent
   */
  setScrollPercent(percent, dispatchEvent = true) {
    if (isNaN(percent)) percent = 0;
    percent = Math.min(Math.max(0, percent), 1);
    const knobWidth = this._knobWidth;
    const scrollWidth = this._scrollRect.width - knobWidth;
    this._scrollKnob.x = scrollWidth * percent;
    if (dispatchEvent) this.signals.onScrollChange.dispatch();
  }

  /**
   * get the scroll percent / position
   * @returns {number} 0-1
   */
  getScrollPercent() {
    const knobWidth = this._knobWidth;
    const scrollWidth = this._scrollRect.width - knobWidth;
    const knobY = this._scrollKnob.x;
    return knobY / scrollWidth;
  }

  /**
   * phaser update method
   */
  updateEnabledState() {
    const state = this._knobWidth + 0.01 < this._scrollRect.width;
    if (this._scrollKnob.inputEnabled !== state) {
      this._scrollKnob.inputEnabled = state;
      if (this._autoHide) this.visible = state;
    }
  }
}
