const pausedTweens = [];

/**
 * class for pausing / resuming tweens
 */
export class OMT_TweenPauseUtil {
  /**
   * pause all tweens
   */
  static pauseAllTweens() {
    const activeTweens = game.tweens.getAll();
    for (const tween of activeTweens) {
      if (!tween.isPaused && !tween.unpauseable) {
        pausedTweens.push(tween);
        tween.pause();
      }
    }
  }

  /**
   * resume all tweens
   */
  static resumeAllTweens() {
    for (const tween of pausedTweens) {
      if (tween.isPaused) {
        tween.resume();
      }
    }
    pausedTweens.length = 0;
  }
}
