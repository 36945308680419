import { MILLISECONDS_IN_HOUR, MILLISECONDS_IN_SEC } from '@omt-components/Utils/TimeUtil';
import {
  TIME_PERIODS,
  QUOTA_MILESTONE_CONFIG,
  PLAYTIME_MILESTONE_CONFIG,
  FIRST_SESSION_MILESTONE_CONFIG,
  USER_TYPE,
} from './milestonesConfig';

let _instance = null; // singleton instance

/**
 * Tracks certain player milestones with a certain time period
 * e.g. X levels completed within the first Y hours of gameplay
 */

export class OMT_MilestoneTracking {
  /**
   * return singleton instance
   * @returns {OMT_MilestoneTracking}
   */
  static getInstance() {
    if (!_instance) _instance = new OMT_MilestoneTracking();
    return _instance;
  }

  /**
   * Builds default milestone data object
   * @returns {Object}
   */
  static getDefaultValues() {
    const defaultMilestones = {
      startTime: Date.now(),
      levelAttempts: 0,
      sessions: 0,
      adsWatched: 0,
      userType: null,
      milestones: {},
      playtimeMilestonesCompleted: 0,
      sessionMilestonesCompleted: 0,
    };

    // Init quota milestones
    for (const type in QUOTA_MILESTONE_CONFIG) {
      if (Object.prototype.hasOwnProperty.call(QUOTA_MILESTONE_CONFIG, type)) {
        defaultMilestones.milestones[type] = [];

        for (const quota of QUOTA_MILESTONE_CONFIG[type].quotas) {
          defaultMilestones.milestones[type].push({
            goal: quota,
            completed: false,
            timePeriodCompleted: 0,
          });
        }
      }
    }

    return defaultMilestones;
  }

  /**
   * constructor
   */
  constructor() {
    this._isEligible = true;
    this._currentTimePeriod = 0;
    this._milestoneStatus = null;
  }

  /**
   * Init milestone tracker
   */
  init() {
    if (OMT.feature.isMilestoneTrackingOn()) {
      this._milestoneStatus = Object.assign(OMT_MilestoneTracking.getDefaultValues(), G.saveState.getMilestoneTrackingData());

      // if (this._milestoneStatus.userType == null) {
      //   this._milestoneStatus.userType = DDNA.tracking.getDataCapture().getPlayerCharacterizationParam('sessionNumber') === 1
      //     ? USER_TYPE.NEW_USER
      //     : USER_TYPE.RETURNING_USER;
      // }

      this.checkEligibilityByTime();
    }
  }

  /**
   * Check if player is still eligible for milestone tracking, and if so,
   * which time period is the player in
   */
  checkEligibilityByTime() {
    if (this._milestoneStatus == null || !OMT.feature.isMilestoneTrackingOn()) return; // Check if tracker is ready yet

    const currentTime = Date.now();
    const timeSinceFirstLogin = currentTime - this._milestoneStatus.startTime;

    const maxTimePeriod = TIME_PERIODS.reduce((a, b) => Math.max(a, b)) * MILLISECONDS_IN_HOUR;
    this._isEligible = maxTimePeriod > timeSinceFirstLogin;

    for (let i = 0; i < TIME_PERIODS.length; i++) {
      if (TIME_PERIODS[i] * MILLISECONDS_IN_HOUR > timeSinceFirstLogin) {
        this._currentTimePeriod = TIME_PERIODS[i];
        break;
      }
    }
  }

  /**
   * Increases level attempt number
   */
  incrementLevelAttempts() {
    if (!OMT.feature.isMilestoneTrackingOn() || !this._isEligible) return;
    this._milestoneStatus.levelAttempts++;
    this.save();
  }

  /**
   * Increases sessions played
   */
  incrementSessions() {
    if (!OMT.feature.isMilestoneTrackingOn() || !this._isEligible) return;
    this._milestoneStatus.sessions++;
    this.save();
  }

  /**
   * Increases ads watched
   */
  incrementAdsWatched() {
    if (!OMT.feature.isMilestoneTrackingOn() || !this._isEligible) return;
    this._milestoneStatus.adsWatched++;
    this.save();
  }

  /**
   * Checks playtime milestones
   */
  _checkPlaytimeMilestones() {
    const { playtimePeriods } = PLAYTIME_MILESTONE_CONFIG;
    const playtimeElapsed = (Date.now() - this._milestoneStatus.startTime) / MILLISECONDS_IN_SEC;
    let currentPlaytimeMilestone = playtimePeriods[this._milestoneStatus.playtimeMilestonesCompleted];

    while (this._milestoneStatus.playtimeMilestonesCompleted < playtimePeriods.length
      && playtimeElapsed >= currentPlaytimeMilestone) {
      const eventName = PLAYTIME_MILESTONE_CONFIG.eventName
        .replace('%USERTYPE%', this._milestoneStatus.userType)
        .replace('%TIME%', currentPlaytimeMilestone);

      OMT.platformTracking.logEvent(eventName);
      this._milestoneStatus.playtimeMilestonesCompleted++;
      currentPlaytimeMilestone = playtimePeriods[this._milestoneStatus.playtimeMilestonesCompleted];
    }
  }

  /**
   * Checks first session milestones
   * @private
   */
  _checkFirstSessionMilestones() {
    if (this._milestoneStatus.sessions > 1) return;
    const { levels } = FIRST_SESSION_MILESTONE_CONFIG;
    const currentLevel = G.saveState.getLastPassedLevelNr();
    let currentMilestone = this._milestoneStatus.sessionMilestonesCompleted;

    while (currentMilestone < levels.length) {
      const currentLevelMilestone = levels[this._milestoneStatus.sessionMilestonesCompleted];

      if (currentLevel >= currentLevelMilestone) {
        const eventName = FIRST_SESSION_MILESTONE_CONFIG.eventName
          .replace('%USERTYPE%', this._milestoneStatus.userType)
          .replace('%LEVELS%', currentLevelMilestone);

        OMT.platformTracking.logEvent(eventName);
        this._milestoneStatus.sessionMilestonesCompleted++;
      }

      currentMilestone++;
    }
  }

  /**
   * Check all milestones for completion, send FB Analytics events if they get completed
   */
  checkMilestoneCompletion() {
    if (this._milestoneStatus == null) return; // Check if tracker is ready yet
    console.log('Checking milestones...');

    // Check playtime and first session milestones
    this._checkPlaytimeMilestones();
    this._checkFirstSessionMilestones();

    // Check quota milestones
    this.checkEligibilityByTime();
    if (!OMT.feature.isMilestoneTrackingOn() || !this._isEligible) return;

    for (const type in this._milestoneStatus.milestones) {
      if (Object.prototype.hasOwnProperty.call(this._milestoneStatus.milestones, type)) {
        let metric;

        // Determine which metric to use
        switch (type) {
          case 'levels':
            metric = this._milestoneStatus.levelAttempts;
            break;

          case 'tutorial':
            metric = G.saveState.getLastPassedLevelNr();
            break;

          case 'sessions':
            metric = this._milestoneStatus.sessions;
            break;

          case 'rewardedAds':
            metric = this._milestoneStatus.adsWatched;
            break;

          default:
            metric = null;
        }

        // Check quotas
        for (const quota of this._milestoneStatus.milestones[type]) {
          const eventName = QUOTA_MILESTONE_CONFIG[type].eventName
            .replace('%USERTYPE%', this._milestoneStatus.userType)
            .replace('%MILESTONE%', quota.goal.toString())
            .replace('%TIME%', this._currentTimePeriod);

          if (!quota.completed && metric >= quota.goal) {
            quota.completed = true;
            quota.timePeriodCompleted = this._currentTimePeriod;
            OMT.platformTracking.logEvent(eventName);
          }
        }
      }
    }

    this.save();
  }

  /**
   * Saves milestone tracking data
   */
  save() {
    G.saveState.saveMilestoneTrackingData(this._milestoneStatus);
  }

  /**
   * @returns {Object}
   */
  get milestoneStatus() {
    return this._milestoneStatus;
  }
}

// this will get overriden later by OMT_Init. This was done for earlier access
if (!window.OMT) window.OMT = {};
OMT.milestoneTracking = OMT_MilestoneTracking.getInstance();
