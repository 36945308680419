/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */

import { RenderTextureGenerator } from '@omt-components/Imaging/RenderTextures/RenderTextureGenerator';
// import RuntimeSpritesheetManager, { RUNTIME_SPRITESHEET_IDS } from '@omt-components/Utils/Spritesheets/RuntimeSpritesheetManager';
import WorldMap2_Util from '../WorldMap2_Util';

export default class WorldMapRiverTile extends G.BatchPoolGroup {
  /**
   * Pool group for the river
   */
  constructor() {
    super(WorldMapRiver);

    this._actives = [];
  }

  /**
   * Moves tiles by deltaY
   * @param {number} deltaY
   */
  moveTiles(deltaY) {
    // update active segments
    for (let i = this._actives.length - 1; i >= 0; i--) {
      this._actives[i].y += deltaY;
    }
  }

  /**
   * Kills tile at index
   * @param {number} index
   */
  killTile(index) {
    const tile = this._actives.find((seg) => seg.index === index);
    if (tile) {
      tile.kill();
      this._actives.splice(this._actives.indexOf(tile), 1);
    }
  }

  /**
   * Adds the river tile
   * @param {number} index
   * @param {number} yPos
   * @returns {BackgroundTile}
   */
  addTile(index, yPos, seed) {
    const river = this.getFreeElement();
    river.y = yPos;
    river.init(index, seed);
    this._actives.push(river);
    this.addChild(river);
    return river;
  }
}

class WorldMapRiver extends Phaser.Sprite {
  /**
   * The river image
   */
  constructor() {
    super(game, null);
    this._textureId = null;
  }

  /**
   * t
   * @param {number} tint
   */
  _createRiverRTSprite(tint) {
    /*
    // method using spritehssets. disabled for now.
    const textureId = `worldMap2River_${tint}`;
    let texture = RuntimeSpritesheetManager.getInstance().getTexture(textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP);
    if (!texture) { // create asset group to transform to render to a runtime spritesheet
      const assetGroup = new Phaser.Group(game);
      G.makeImage(0, 0, 'worldMap2_riverBase', [0.5, 0], assetGroup);
      const tintImg = G.makeImage(0, 0, 'worldMap2_riverBase', [0.5, 0], assetGroup); // The colour of the river
      tintImg.tint = tint;
      tintImg.blendMode = PIXI.blendModes.NORMAL;
      G.makeImage(0, 0, 'worldMap2_riverTop', [0.5, 0], assetGroup);
      RuntimeSpritesheetManager.getInstance().addSprite(assetGroup, textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP);
      texture = RuntimeSpritesheetManager.getInstance().getTexture(textureId, RUNTIME_SPRITESHEET_IDS.WORLD_MAP);
      assetGroup.destroy();
    }
    G.changeTexture(this, texture);
    this.anchor.set(0.5, 0);
    */

    // switched back to using indiviual RenderTextures
    const textureId = `worldMap2River_${tint}`;
    let texture = RenderTextureGenerator.getTexture(textureId);
    if (!texture) { // create asset group to transform to render to a RenderTexture
      const assetGroup = new Phaser.Group(game);
      G.makeImage(0, 0, 'prefab-elements/worldMap2_riverBase', [0.5, 0], assetGroup);
      const tintImg = G.makeImage(0, 0, 'prefab-elements/worldMap2_riverBase', [0.5, 0], assetGroup); // The colour of the river
      tintImg.tint = tint;
      tintImg.blendMode = PIXI.blendModes.NORMAL;
      G.makeImage(0, 0, 'prefab-elements/worldMap2_riverTop', [0.5, 0], assetGroup);
      texture = RenderTextureGenerator.generateRenderTexture(assetGroup, textureId);
      assetGroup.destroy();
    }

    G.changeTexture(this, texture);
    this._textureId = textureId;
    this.anchor.set(0.5, 0);
  }

  /**
   * Tints the river based on seed
   * @param {numiber} index
   * @param {number} seed
   */
  init(index, seed) {
    this.index = index;
    const tintPool = WorldMap2_Util.getRiverColours();
    const tint = tintPool[seed % tintPool.length];
    this._createRiverRTSprite(tint);
    this.revive();
  }

  /**
   * kill the texture, de-reference
   */
  killTexture() {
    if (this._textureId != null) RenderTextureGenerator.modifyReferenceCount(this._textureId, -1);
    this._textureId = null;
  }

  /**
   * sprite kill method
   */
  kill() {
    super.kill();
    this.killTexture();
    G.changeTexture(this, null);
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this.killTexture();
  }
}
