/* eslint-disable no-trailing-spaces */
/**
 * A strange class that tracks the start boosters on a level and before level
 */
export default class StartBoosterConfig {
  constructor() {
    // Initialize initial values
    this._setInitialValues();
  }

  /**
   * Easily import data instead of accessing it and changing it
   * @param {Array<number>} data
   * @param {array<number>} preLevel
   * @param {Array<number>} requestHelp
   * @param {boolean} reset
   */
  importData(data, preLevel, requestHelp, levelNumber, reset) {
    if (data) {
      this.data[levelNumber] = data;
    }

    if (preLevel) {
      this.preLevelAdBooster = preLevel;
    }

    if (requestHelp) {
      this.requestHelpBoosters = requestHelp;
    }

    if (!reset) {
      if (!this.initState) {
        this.savedDataIndex = 0;
        this.initState = [];
      }
  
      this.savedDataIndex++;
      this.initState[levelNumber] = {
        savedDataIndex: this.savedDataIndex,
        data,
        preLevel,
        requestHelp,
        levelNumber,
      };
    }
  }

  /**
   * Send variables to recycler
   */
  destroy() {
    this.data = null;
    this.preLevelAdBooster = null;
    this.requestHelpBoosters = null;
  }

  /**
   * Sets that booster to selected
   * @param {number} lvlNr
   * @param {number} boosterNr
   */
  increment(lvlNr, boosterNr) {
    if (!this.data[lvlNr]) {
      this.data[lvlNr] = [];
    }
    if (this.data[lvlNr][boosterNr] === undefined) {
      this.data[lvlNr][boosterNr] = 1;
    } else {
      this.data[lvlNr][boosterNr]++;
    }

    G.sb('onStartBoosterConfigChange').dispatch();
  }

  /**
   * Sets that booster to deselected
   * @param {number} lvlNr
   * @param {number} boosterNr
   */
  decrement(lvlNr, boosterNr) {
    if (!this.data[lvlNr]) {
      this.data[lvlNr] = [];
    }
    if (this.data[lvlNr][boosterNr] === undefined) {
      this.data[lvlNr][boosterNr] = 0;
    } else if (this.data[lvlNr][boosterNr] > 0) {
      this.data[lvlNr][boosterNr]--;
    }

    G.sb('onStartBoosterConfigChange').dispatch();
  }

  /**
   * Set the initial values of the class
   */
  _setInitialValues() {
    this.data = [];
    this.preLevelAdBooster = [];
    this.requestHelpBoosters = [];
  }

  /**
   * Get the state data that was saved
   * @returns {any[]} initStateData
   */
  _getInitStateData() {
    if (!this.initState) return [];
    return this.initState.sort((a, b) => a.savedDataIndex - b.savedDataIndex);
  }

  /**
   * Reset all booster parameters to their initial values
   */
  reset() {
    this._setInitialValues();
  }

  /**
   * Check if that booster is selected in that level
   * @param {number} lvlNr
   * @param {number} boosterNr
   * @return {boolean}
   */
  isSelected(lvlNr, boosterNr) {
    if (!this.data[lvlNr]) {
      return false;
    }
    return this.data[lvlNr][boosterNr] && this.data[lvlNr][boosterNr] > 0;
  }

  /**
   * Get boosters that are selected for that level
   * @param {number} lvlNr
   * @returns {Array<number>}
   */
  getConfigForLevel(lvlNr) {
    return this.data[lvlNr] || [];
  }

  /**
   * Returns the number of the booster
   * @param {number} lvlNr
   * @returns {boolean}
   */
  getPreLevelAdBoosterNr(lvlNr) {
    if (!this.preLevelAdBooster[lvlNr]) {
      this.preLevelAdBooster[lvlNr] = game.rnd.pick([5, 7, 8]);
    }
    return this.preLevelAdBooster[lvlNr];
  }
}
