import { SPECIAL_TYPES } from '../BoardConstants';
import { ACTION_TYPES } from './Action';
import { ActionMove } from './ActionMove';
import { ActionProcessMatch } from './ActionProcessMatch';
import { ActionBoosterMatch } from './ActionBoosterMatch';
import { ActionBoosterSwap } from './ActionBoosterSwap';
import { ActionProcessFall } from './ActionProcessFall';
import { ActionShuffle } from './ActionShuffle';
import { ActionStartBoosters } from './ActionStartBooster';
import { ActionOOMBoosters } from './ActionOOMBoosters';
import { ActionMoveReject } from './ActionMoveReject';

const HINT_IDLE_FRAME_DELAY = 160;

/**
 * Manages Action instanecs for the game board
 */
export class ActionManager {
  /**
   * constructor
   * @param {Board} board instance of active game board
   */
  constructor(board) {
    this._board = board;

    this._defineSignals();
    this._addGlobalListeners();

    this._actionList = [];

    this._madeMove = false;

    this._availableActions = {
      [ACTION_TYPES.MOVE]: ActionMove,
      [ACTION_TYPES.PROCESS_MATCH]: ActionProcessMatch,
      [ACTION_TYPES.PROCESS_FALL]: ActionProcessFall,
      [ACTION_TYPES.BOOSTER_MATCH]: ActionBoosterMatch,
      [ACTION_TYPES.BOOSTER_SWAP]: ActionBoosterSwap,
      [ACTION_TYPES.SHUFFLE]: ActionShuffle,
      [ACTION_TYPES.START_BOOSTERS]: ActionStartBoosters,
      [ACTION_TYPES.OOM_BOOSTERS]: ActionOOMBoosters,
      [ACTION_TYPES.MOVE_REJECT]: ActionMoveReject,
    };

    this._idleFrames = 0;

    this._shakingCandies = [];
  }

  /**
   * define signals
   */
  _defineSignals() {
    this.onActionQueueEmpty = new Phaser.Signal();
  }

  /**
   * remove signals
   */
  _removeSignals() {
    this.onActionQueueEmpty.dispose();
  }

  /**
   * add global event listeners
   */
  _addGlobalListeners() {
    this._signalBindings = [
      G.sb('madeMove').add(this._onMoveMade, this),
      G.sb('onBoosterSelect').add(this._onBoosterSelect, this),
      G.sb('onBoosterDeselect').add(this._onBoosterDeselect, this),
    ];
  }

  /**
   * remove global listeners set by _addGlobalListeners()
   */
  _removeGlobalListeners() {
    for (const signalBinding of this._signalBindings) signalBinding.detach();
    this._signalBindings.length = 0;
  }

  /**
   * listener for 'madeMove' global event
   */
  _onMoveMade() { this._madeMove = true; }

  /**
   * listener for 'onBoosterSelect' global event
   * @param {Object} boosterData
   */
  _onBoosterSelect(boosterData) {
    if (boosterData.boosterNr === 1) this.newAction(ACTION_TYPES.BOOSTER_SWAP, boosterData);
    else this.newAction(ACTION_TYPES.BOOSTER_MATCH, boosterData);
  }

  /**
   * listener for 'onBoosterDeselect' global event
   */
  _onBoosterDeselect() {
    if (this._actionList.length === 1) this._actionList[0].finish();
  }

  /**
   * main update method
   */
  update() {
    if (this._actionList.length === 0) { // no active actions
      this._idleFrames++;
      // show delayed hint while idling
      const { gameHooks } = this._board;
      if (this._idleFrames > HINT_IDLE_FRAME_DELAY && gameHooks.hintsAllowed) {
        this._idleFrames = 0;
        this._shakePossibleMoves();
      }
      this._updateShakes();
    } else { // update active action
      this._idleFrames = 0;
      this._actionList[0].update();
    }
  }

  /**
   * add to the list of shaking candies.
   * @param {Candy} candy
   */
  _shakeCandy(candy) {
    this._shakingCandies.push({
      candy,
      orgX: candy.x,
      orgY: candy.y,
      dt: 0,
      wave: 5,
    });
  }

  /**
   * halt the shaking candy animations and reset their positions.
   */
  _breakShakes() {
    this._shakingCandies.forEach((shakeObj) => {
      shakeObj.candy.x = shakeObj.orgX;
      shakeObj.candy.y = shakeObj.orgY;
    });
    this._shakingCandies = [];
  }

  /**
   * update candy shake animations
   */
  _updateShakes() {
    for (let i = this._shakingCandies.length; i--;) {
      const shakeObj = this._shakingCandies[i];
      const { candy } = shakeObj;

      shakeObj.dt += 0.04 * G.deltaTime;

      // apply sine wave shake x axis
      candy.x = shakeObj.orgX + Math.sin(shakeObj.dt * (Math.PI * 4)) * shakeObj.wave;

      if (shakeObj.dt >= 1) {
        candy.x = shakeObj.orgX;
        candy.y = shakeObj.orgY;
        this._shakingCandies.pop();
      }
    }
  }

  /**
   * shake / show hint for one of the possible moves
   */
  _shakePossibleMoves() {
    if (G.tutorialOpened) return;

    const possibleMoves = this._board.matcher.checkPossibleMoves();
    Phaser.ArrayUtils.shuffle(possibleMoves);
    if (possibleMoves.length === 0) return;

    const moveToShow = possibleMoves[0];
    this._shakeCandy(this._board.getCandy(moveToShow[0], moveToShow[1]));
    this._shakeCandy(this._board.getCandy(moveToShow[2], moveToShow[3]));
  }

  /**
   * Add new action to the actionList of the specified type
   * @param {string} type
   * @param {...any} args
   */
  newAction(type, ...args) {
    this._breakShakes();
    // console.log(`** NEW BOARD ACTION : ${type}`);
    const actionInstance = new this._availableActions[type](this._board, this, args);
    this._actionList.push(actionInstance);
  }

  /**
   * remove the specified action from the actionList
   * @param {Action} action
   */
  removeAction(action) {
    const { lvlDataManager } = this._board;
    const index = this._actionList.indexOf(action);

    if (index !== -1) this._actionList.splice(index, 1);
    else this._actionList.splice(0, 1);
    if (action && action.destroy) action.destroy();

    if (this._actionList.length === 0) { // action list is empty
      lvlDataManager.endCombo();
      // the level goal was reached
      if (lvlDataManager.goalAchieved) {
        if (lvlDataManager.moves > 0) { // player has > 0 moves remaining
          this._onLevelGoalReachedWithMoves();
        } else { // player has 0 moves remaining
          this._onLevelGoalReachedNoMoves();
        }
        return;
      }

      this._possibleMoves = this._board.matcher.checkPossibleMoves();
      const { allowShuffle } = this._board.shuffler;
      if (this._possibleMoves.length === 0 && allowShuffle) {
        this.newAction(ACTION_TYPES.SHUFFLE);
        return;
      }

      // dispatch empty queue events
      this.onActionQueueEmpty.dispatch();
      G.sb('actionQueueEmpty').dispatch();
      if (this._madeMove) {
        this._madeMove = false;
        G.sb('actionQueueEmptyAfterMove').dispatch();
      }
    }
  }

  /**
   * level goal was reached with > 0 moves remaining.
   */
  _onLevelGoalReachedWithMoves() {
    const { lvlDataManager, gameHooks } = this._board;
    const normals = this._board.candiesLayer.getNormalCandies();
    Phaser.ArrayUtils.shuffle(normals);

    const len = Math.min(lvlDataManager.moves, normals.length, 15);
    for (let i = 0; i < len; i++) {
      const candy = normals[i];
      candy.changeInto(Math.random() < 0.5 ? SPECIAL_TYPES.HORIZONTAL : SPECIAL_TYPES.VERTICAL);
      candy.activatedByMove = true;
      lvlDataManager.changePointsNumber(lvlDataManager.pointsForMovesLeft);
      const pxOut = this._board.cellToPxOut([candy.cellX, candy.cellY]);
      G.sb('displayPoints').dispatch(pxOut[0], pxOut[1], lvlDataManager.pointsForMovesLeft);
      lvlDataManager.madeMove();
      this._board.checkSpecialMatchList.push(candy);
    }

    gameHooks.playSound('booster');
    game.time.events.add(800, () => {
      this.newAction(ACTION_TYPES.PROCESS_MATCH);
    }, this);
  }

  /**
   * level goal was reached with NO moves remaining.
   */
  _onLevelGoalReachedNoMoves() {
    const specialCandies = this._board.candiesLayer.getAllSpecialCandies();
    const { lvlDataManager, gameHooks } = this._board;
    // explode remaining special candies on board
    if (specialCandies.length > 0) {
      specialCandies.forEach((candy) => {
        candy.activatedByMove = true;
        this._board.checkSpecialMatchList.push(candy);
      }, this);

      if (G.IMMEDIATE) {
        this.newAction(ACTION_TYPES.PROCESS_MATCH);
      } else {
        game.time.events.add(G.IMMEDIATE ? 1 : 300, () => {
          this.newAction(ACTION_TYPES.PROCESS_MATCH);
        }, this);
      }
    } else {
      if (lvlDataManager.isDailyChallengeLevel) {
        gameHooks.incrementDailyChallengeWinCount();
      }

      if (!this._board.wasFinalCascadeSkipped) {
        this._board.deconstruct();
      }
    }
  }

  /**
   * start the tournament end sequence, alternate flow then normal level end
   * @param {Function} onComplete callback for when the colapse sequence is completed
   */
  startTournamentEndSequence(onComplete) {
    if (this._board.selectShade) this._board.selectShade.alpha = 0;
    if (this._board.tileShade) this._board.tileShade.alpha = 0;
    this._onTournamentEndSequenceComplete = onComplete;
    this.removeAction = this.continueTournamentEndSequence; // override removeAction function
    this.continueTournamentEndSequence();
  }

  /**
   * continue the tournament end sequence, replaces removeAction
   */
  continueTournamentEndSequence(action) {
    const index = this._actionList.indexOf(action);
    if (index !== -1) this._actionList.splice(index, 1);
    else this._actionList.splice(0, 1);

    // continue collapse as long as specials are found
    if (this._actionList.length === 0) {
      const { lvlDataManager } = this._board;
      lvlDataManager.endCombo();
      const specialCandies = this._board.candiesLayer.getAllSpecialCandies();
      if (specialCandies.length > 0) {
        specialCandies.forEach((candy) => {
          candy.activatedByMove = true;
          this._board.checkSpecialMatchList.push(candy);
        }, this);

        if (G.IMMEDIATE) {
          this.newAction(ACTION_TYPES.PROCESS_MATCH);
        } else {
          game.time.events.add(G.IMMEDIATE ? 1 : 300, () => {
            this.newAction(ACTION_TYPES.PROCESS_MATCH);
          }, this);
        }
      } else {
        this._onTournamentEndSequenceComplete();
      }
    }
  }

  /**
   * destruction method
   */
  destroy() {
    // console.log('*** ActionManager manager destroyed');
    for (const action of this._actionList) action.destroy();
    this._removeGlobalListeners();
    this._removeSignals();
  }

  /** GETTER METHODS ********************************* */

  /** @returns {Action} get the first / active action */
  get activeAction() {
    if (this._actionList.length === 0) return null;
    return this._actionList[0];
  }

  /** @returns {boolean} true if the _actionList has queued actions */
  get hasActiveAction() {
    return this._actionList.length > 0;
  }
}
