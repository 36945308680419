/* eslint-disable func-names */
G.addTextStyles = function () {
  // font styles
  G.Text.addStyle('font-dark-blue-white-shadow', {
    font: G.Text.defaultFont,
    fill: '#006687',
    shadow: [2, 3, 'rgba(255,255,255,0.7)', 0],
  });

  G.Text.addStyle('font-dark-purple-white-shadow', {
    font: G.Text.defaultFont,
    fill: '#500082',
    shadow: [2, 3, 'rgba(255,255,255,0.7)', 0],
  });

  G.Text.addStyle('font-white', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '30px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('font-wood', {
    font: G.Text.defaultFont,
    fill: '#A6590C',
    fontSize: '30px',
    shadow: [2, 3, 'rgba(255,255,255,0.7)', 0],
  });

  G.Text.addStyle('font-white-blue-out', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '30px',
    stroke: '#008aca',
    strokeThickness: 5,
  });

  G.Text.addStyle('font-pink-white-out', {
    font: G.Text.defaultFont,
    fill: '#F923A2',
    fontSize: '30px',
    stroke: 'white',
    strokeThickness: 5,
  });

  G.Text.addStyle('font-blue', {
    font: G.Text.defaultFont,
    fill: '#008aca',
    fontSize: '30px',
  });

  G.Text.addStyle('font-orange', {
    font: G.Text.defaultFont,
    fill: '#ff7200',
    fontSize: '30px',
  });

  G.Text.addStyle('font-darkBlue', {
    font: G.Text.defaultFont,
    fill: '#006A8F',
    fontSize: '30px',
  });


  G.Text.addStyle('font-darkBlueRubik', {
    font: G.Text.defaultFont,
    fill: '#006A8F',
    fontSize: '30px',
  });

  G.Text.addStyle('font-num-blue', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '30px',
    stroke: '#3d95ea',
    strokeThickness: 3,
  });

  G.Text.addStyle('font-num-orange', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '30px',
    stroke: '#ff7200',
    strokeThickness: 3,
  });

  G.Text.addStyle('font-score-0', {
    font: G.Text.defaultFont,
    fontSize: '50px',
    stroke: 'white',
    strokeThickness: 8,
    fill: '#eea1c2',
  });

  G.Text.addStyle('font-score-1', {
    font: G.Text.defaultFont,
    fontSize: '50px',
    stroke: 'white',
    strokeThickness: 8,
    fill: '#c52216',
  });

  G.Text.addStyle('font-score-2', {
    font: G.Text.defaultFont,
    fontSize: '50px',
    stroke: 'white',
    strokeThickness: 8,
    fill: '#1eb3e5',
  });

  G.Text.addStyle('font-score-3', {
    font: G.Text.defaultFont,
    fontSize: '50px',
    stroke: 'white',
    strokeThickness: 8,
    fill: '#eac867',
  });

  G.Text.addStyle('font-score-4', {
    font: G.Text.defaultFont,
    fontSize: '50px',
    stroke: 'white',
    strokeThickness: 8,
    fill: '#e34bbc',
  });

  G.Text.addStyle('font-score-5', {
    font: G.Text.defaultFont,
    fontSize: '50px',
    stroke: 'white',
    strokeThickness: 8,
    fill: '#7adc2c',
  });

  G.Text.addStyle('font-score-6', {
    font: G.Text.defaultFont,
    fontSize: '50px',
    stroke: 'white',
    strokeThickness: 8,
    fill: '#e79909',
  });

  G.Text.addStyle('rubikWB', {
    font: G.Text.defaultFont,
    fontSize: '40px',
    stroke: '#49abe8',
    strokeThickness: 6,
    fill: 'white',
  });

  G.Text.addStyle('rubikPW', {
    font: G.Text.defaultFont,
    fontSize: '40px',
    stroke: 'white',
    strokeThickness: 6,
    fill: '#EC048D',
  });

  G.Text.addStyle('font-grey', {
    font: G.Text.defaultFont,
    fontSize: 40,
    fill: '#686F70',
  });

  G.Text.addStyle('editor', {
    font: 'Verdana',
    fontWeight: 'bold',
    fontSize: 40,
    fill: 'white',
    stroke: 'black',
    strokeThickness: 5,
  });

  G.Text.addStyle('editor-aquamarine', {
    font: 'Verdana',
    fontWeight: 'bold',
    fontSize: 40,
    fill: '#a8dbc6',
    stroke: 'black',
    strokeThickness: 5,
  });

  G.Text.addStyle('msg-white', {
    font: G.Text.defaultFont,
    fontSize: '25px',
    fill: 'white',
  });

  G.Text.addStyle('msg-black', {
    font: G.Text.defaultFont,
    fontSize: '64px',
    fill: 'black',
  });

  G.Text.addStyle('msg-yellow', {
    font: G.Text.defaultFont,
    fontSize: '40px',
    fill: '#ffe715',
  });

  G.Text.addStyle('msg-yellow-2', {
    fill: '#fdff36',
    font: G.Text.defaultFont,
    fontSize: '42px',
    align: 'center',
  });

  G.Text.addStyle('msg-fuschia', {
    font: G.Text.defaultFont,
    fontSize: '35px',
    fill: '#de2289',
    stroke: 'white',
    strokeThickness: 8,
  });

  G.Text.addStyle('msg-purpleFontStyle', {
    fill: '#de03ff',
    font: G.Text.defaultFont,
    fontSize: '42px',
    align: 'center',
    stroke: '#ffffff',
    strokeThickness: 10,
  });

  G.Text.addStyle('msg-blueFontStyle', {
    fill: '#0788b9',
    font: G.Text.defaultFont,
    fontSize: '42px',
    align: 'center',
    stroke: '#ffffff',
    strokeThickness: 10,
  });

  G.Text.addStyle('msg-orangeFontStyle', {
    fill: '#fb8013',
    font: G.Text.defaultFont,
    fontSize: '42px',
    align: 'center',
    stroke: '#ffffff',
    strokeThickness: 10,
  });

  G.Text.addStyle('msg-friendChest', {
    font: G.Text.defaultFont,
    fill: 'yellow',
    stroke: '#e08900',
    strokeThickness: 10,
  });

  G.Text.addStyle('msg-friendChestJoined', {
    font: G.Text.defaultFont,
    fontSize: '120px',
    fill: '#ffdeae',
    stroke: '#ce3030',
    strokeThickness: 10,
  });

  G.Text.addStyle('msg-help', {
    font: G.Text.defaultFont,
    fontSize: 26,
    fill: '#D500B9',
  });

  G.Text.addStyle('window-white', {
    fill: 'white',
    font: G.Text.defaultFont,
    fontSize: '40px',
  });

  G.Text.addStyle('window-brown', {
    font: G.Text.defaultFont,
    fontSize: '22px',
    fill: '#ab6420',
    lineSpacing: 0,
  });

  G.Text.addStyle('window-blue', {
    fill: '#0089D4',
    font: G.Text.defaultFont,
    fontSize: '35px',
    stroke: '#0089D4',
    lineSpacing: -15,
  });

  G.Text.addStyle('window-blue-2', {
    fill: '#008aca',
    font: G.Text.defaultFont,
    fontSize: '35px',
  });

  G.Text.addStyle('window-starReq', {
    font: G.Text.defaultFont,
    fontSize: '40px',
    fill: '#FFA90F',
    stroke: '#A24A00',
    strokeThickness: 3,
  });

  G.Text.addStyle('mysteryGift-top', {
    fill: '#0089D4',
    font: G.Text.defaultFont,
    fontSize: '30px',
    lineSpacing: -10,
  });

  G.Text.addStyle('mysteryGift-topBig', {
    fill: '#0089D4',
    font: G.Text.defaultFont,
    fontSize: '56px',
    lineSpacing: -10,
  });

  G.Text.addStyle('mysteryGift-bottom', {
    fill: '#0089D4',
    font: G.Text.defaultFont,
    fontSize: '24px',
    lineSpacing: -10,
  });

  G.Text.addStyle('mysteryGift-timer-yellow', {
    fill: '#F2A03C',
    font: G.Text.defaultFont,
    fontSize: '35px',
    stroke: '#0089D4',
    lineSpacing: -15,
  });

  G.Text.addStyle('mysteryGift-timer-red', {
    fill: '#EA3526',
    font: G.Text.defaultFont,
    fontSize: '35px',
    stroke: '#EA3526',
    lineSpacing: -15,
  });

  G.Text.addStyle('ui-dailyChallenge', {
    fill: 'white',
    font: G.Text.defaultFont,
    fontSize: '30px',
    stroke: '#c0317d',
    strokeThickness: 5,
  });

  G.Text.addStyle('ui-dailyIcon', {
    fill: 'white',
    font: G.Text.defaultFont,
    fontSize: '30px',
    stroke: 'black',
    strokeThickness: 1,
  });

  G.Text.addStyle('ui-tournament', {
    fill: 'white',
    font: G.Text.defaultFont,
    fontSize: '23px',
  });

  G.Text.addStyle('ui-tournament-timecode', {
    fill: 'white',
    font: G.Text.defaultFont,
    fontSize: '23px',
  });

  G.Text.addStyle('ui-tournament-promo', {
    fill: '#d20c5b',
    font: G.Text.defaultFont,
    lineSpacing: -10,
  });

  // Wheel Styles

  G.Text.addStyle('font-num-realMoneyWheel', {
    font: G.Text.defaultFont,
    fill: '#D80000',
    fontSize: '30px',
    stroke: '#ffffff',
    strokeThickness: 3,
  });

  G.Text.addStyle('realMoneyWheel-coinTextGold', {
    style: 'font-num-blue',
    fontSize: 36,
    fill: '#AB0011',
    strokeThickness: 0,
    align: 'center',
    lineSpacing: -10,
  });

  G.Text.addStyle('realMoneyWheel-coinTextSpecialGold', {
    style: 'font-num-orange',
    fontSize: 36,
    fill: 'white',
    strokeThickness: 0,
    align: 'center',
    lineSpacing: -10,
  });

  G.Text.addStyle('ui-friendChest', {
    fill: 'white',
    font: G.Text.defaultFont,
    fontSize: '25px',
    stroke: 'black',
    strokeThickness: 1,
  });

  G.Text.addStyle('world-sidePanelLockedWidget', {
    fill: 'white',
    font: G.Text.defaultFont,
    fontSize: '25px',
    stroke: 'black',
    strokeThickness: 1,
  });

  G.Text.addStyle('gifts-shareTickbox', {
    fill: '#ffffff',
    font: G.Text.defaultFont,
    fontSize: 26,
    lineSpacing: -10,
  });

  G.Text.addStyle('gifts-achievement', {
    fill: '#0788b9',
    font: G.Text.defaultFont,
    fontSize: '54px',
  });

  G.Text.addStyle('modifyAnimation', {
    font: 'Verdana',
    fontSize: 13,
    fontWeight: 'bold',
  });

  // -- Game-specfic styles

  G.Text.addStyle('DailyRewardTitle', {
    fill: '#FFA249',
    font: G.Text.defaultFont,
    fontSize: '55px',
  });

  G.Text.addStyle('WorldMapLvlNrBtn', {
    font: G.Text.defaultFont,
    fill: '#ffffff',
    fontSize: '50px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('worldMap-interactionFailText', {
    style: 'font-blue',
    lineSpacing: -15,
  });

  G.Text.addStyle('lvlButtonCloseMessage', {
    font: G.Text.defaultFont,
    fill: '#389904',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '150px',
  });

  G.Text.addStyle('UIPointsLabel-normal', {
    style: 'font-white',
    fontSize: '35px',
  });

  G.Text.addStyle('UIPointsLabel-normal-horizontal', {
    font: G.Text.defaultFont,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '35px',
  });

  G.Text.addStyle('UIPointsAmount-normal-horizontal', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '45px',
  });

  G.Text.addStyle('UIPointsLabel-hard-horizontal', {
    font: G.Text.defaultFont,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '35px',
  });

  G.Text.addStyle('UIPointsAmount-hard-horizontal', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '45px',
  });

  G.Text.addStyle('UIPointsLabel-superHard-horizontal', {
    font: G.Text.defaultFont,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '35px',
  });

  G.Text.addStyle('UIPointsAmount-superHard-horizontal', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '45px',
  });

  G.Text.addStyle('UIPointsLabel-tournament-horizontal', {
    font: G.Text.defaultFont,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '35px',
  });

  G.Text.addStyle('UIPointsAmount-tournament-horizontal', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '45px',
  });

  G.Text.addStyle('UITopMovesLabel-normal', {
    style: 'font-blue',
    fontSize: '25px',
  });

  G.Text.addStyle('UITopMovesAmount-normal', {
    style: 'font-blue',
    fontSize: '30px',
  });

  G.Text.addStyle('UILevelNumber-normal', {
    font: G.Text.defaultFont,
    fill: '#008aca',
    fontSize: '30px',
  });

  G.Text.addStyle('UITopMovesLabel-normal-horizontal', {
    style: 'font-blue',
    fontSize: '35px',
  });

  G.Text.addStyle('UITopMovesAmount-normal-horizontal', {
    style: 'font-blue',
    fontSize: '60px',
  });

  G.Text.addStyle('UIGoalPanelPointAmount-tournament', {
    font: G.Text.defaultFont,
    fill: '#c9005f',
    shadow: [2, 3, 'rgba(255,255,255,0.7)', 0],
    fontSize: '40px',
  });

  G.Text.addStyle('UIGoalPanelPointText-tournament-horizontal', {
    font: G.Text.defaultFont,
    fill: '#c9005f',
    shadow: [2, 3, 'rgba(255,255,255, 0.7)', 0],
    fontSize: '40px',
    align: 'center',
  });

  G.Text.addStyle('UIGoalPanelPointAmount-tournament-horizontal', {
    font: G.Text.defaultFont,
    fill: '#c9005f',
    shadow: [2, 3, 'rgba(255,255,255, 0.7)', 0],
    fontSize: '50px',
    align: 'center',
  });

  G.Text.addStyle('UITopMovesLabel-tournament', {
    font: G.Text.defaultFont,
    fill: '#c9005f',
    shadow: [2, 3, 'rgba(255,255,255,0.7)', 0],
    fontSize: '34px',
    align: 'center',
  });

  G.Text.addStyle('UITopMovesAmount-tournament', {
    font: G.Text.defaultFont,
    fill: '#c9005f',
    shadow: [2, 3, 'rgba(255,255,255,0.7)', 0],
    fontSize: '58px',
    align: 'center',
  });

  G.Text.addStyle('UITopMovesLabel-tournament-horizontal', {
    font: G.Text.defaultFont,
    fill: '#c9005f',
    shadow: [2, 3, 'rgba(255,255,255,0.7)', 0],
    align: 'center',
    fontSize: '40px',
  });

  G.Text.addStyle('UITopMovesAmount-tournament-horizontal', {
    font: G.Text.defaultFont,
    fill: '#c9005f',
    shadow: [2, 3, 'rgba(255,255,255,0.7)', 0],
    align: 'center',
    fontSize: '90px',
  });

  G.Text.addStyle('mysteryGift-Title', {
    font: G.Text.defaultFont,
    fill: 'white',
    shadow: [2, 4, 'rgba(0,0,0,0.5)', 5],
    fontSize: '60px',
    lineSpacing: -15,
  });

  G.Text.addStyle('shop2-Entry', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '128px',
    shadow: [0, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop2-Buy', {
    font: G.Text.defaultFont,
    fill: 'white',
    stroke: '#2d6128',
    fontSize: '30px',
    strokeThickness: 2,
    shadow: [1, 2, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop2-CoinText', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '36px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop2-FreeClaim', {
    font: G.Text.defaultFont,
    fill: 'white',
    stroke: '#b53700',
    fontSize: '30px',
    strokeThickness: 2,
    shadow: [1, 2, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop2-BestValue', {
    font: G.Text.defaultFont,
    stroke: '#58057f',
    fill: '#ffffff',
    fontSize: 48,
    strokeThickness: 4,
  });

  G.Text.addStyle('shop2-MostPopular', {
    font: G.Text.defaultFont,
    stroke: '#a52626',
    fill: '#ffffff',
    fontSize: 48,
    strokeThickness: 4,
  });

  G.Text.addStyle('shop2-SpecialOffer', {
    font: G.Text.defaultFont,
    fill: '#ffffff',
    stroke: '#2d6128',
    strokeThickness: 4,
    fontSize: 98,
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop2-FakeOffer', {
    font: G.Text.defaultFont,
    fill: '#ffffff',
    // stroke: '#2d6128',
    // strokeThickness: 4,
    fontSize: 68,
    shadow: [0, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop2-ValueDeal', {
    font: G.Text.defaultFont,
    stroke: '#2d6128',
    fontSize: 68,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop2-EndsIn', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '18px',
    shadow: [0, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop2-boosterAmount', {
    font: G.Text.defaultFont,
    fill: '#911C24',
    fontSize: 35,
  });

  G.Text.addStyle('mockFacebook-Gray', {
    fill: '#949494',
    fontSize: 24,
  });

  G.Text.addStyle('mockFacebook-Black', {
    fill: '#000',
    fontSize: 24,
  });

  G.Text.addStyle('mockFacebook-Blue', {
    fill: '#3578E5',
    fontSize: 24,
  });

  G.Text.addStyle('loading', {
    font: G.Text.defaultFont,
    fill: '#D700A7',
    fontSize: '80px',
    stroke: '#ffffff',
    strokeThickness: 8,
    shadow: [0, 4, 'rgba(0,0,0,0.3)', 5],
  });

  G.Text.addStyle('window_popupOffer-titleTxt', {
    style: 'font-white',
    fontSize: '42px',
    lineSpacing: -7,
  });

  G.Text.addStyle('window_popupOffer-headerTxt', {
    style: 'font-white',
    fontSize: '26px',
  });

  G.Text.addStyle('window_popupOffer-multiplierTxt', {
    style: 'font-white',
    fontSize: '46px',
    shadow: [2, 2, 'rgba(0,0,0,0.3)', 5],
  });

  G.Text.addStyle('window_popupOffer-coinbarTxt', {
    style: 'font-white',
    fill: '#AB3309',
    fontSize: '40px',
  });

  G.Text.addStyle('window_popupOffer-boosterTxt', {
    style: 'font-white',
    fontSize: '50px',
    fill: '#D72800',
  });

  G.Text.addStyle('window_popupOffer-mascotTxt', {
    style: 'font-blue',
    fill: '#D72800',
    fontSize: 44,
    lineSpacing: -4,
    fontWeight: 'bold',
  });

  G.Text.addStyle('eventPostcard-caption', {
    fill: 'white',
    fontSize: '48px',
  });

  G.Text.addStyle('eventPostcard-speechText', {
    font: G.Text.defaultFont,
    fill: '#1382F4',
    fontSize: '36px',
  });

  G.Text.addStyle('eventPostcard-title', {
    fill: '#D217B5',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '48px',
    align: 'center',
  });

  G.Text.addStyle('eventPostcard-offerTitle', {
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.5)', 0],
    fontSize: '64px',
    align: 'center',
  });

  G.Text.addStyle('windowBrag-blue', {
    font: G.Text.defaultFont,
    lineSpacing: -8,
    fill: '#003997',
    fontSize: '40px',
  });

  G.Text.addStyle('windowBrag-white', {
    font: G.Text.defaultFont,
    lineSpacing: -8,
    fill: 'white',
    fontSize: '72px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('msgBrag-bubble', {
    fill: '#0788b9',
    font: 'Lobster',
    fontSize: '55px',
    align: 'center',
  });

  G.Text.addStyle('msgBrag-banner', {
    fill: '#FFFFFF',
    font: 'Lobster',
    fontSize: '50px',
    align: 'center',
  });

  G.Text.addStyle('eventPostcard-offerCaption', {
    fill: '#D217B5',
    fontSize: '36px',
  });

  G.Text.addStyle('fortuneCookie-gameBoardWhite', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '30px',
    shadow: [0, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('fortuneCookie-shareStyle', {
    font: 'Verdana',
    style: 'msg-white',
    fontSize: '48px',
    fontWeight: 600,
    fill: '#B0001D',
  });

  G.Text.addStyle('friendshipChest-title', {
    style: 'font-blue',
    fontSize: 64,
    lineSpacing: -15,
  });

  G.Text.addStyle('friendshipChest-subTitle', {
    style: 'font-white',
    fontSize: 48,
    lineSpacing: -5,
  });

  G.Text.addStyle('friendshipChest-sentSubText', {
    style: 'font-white',
    fill: '#71BBFF',
  });

  G.Text.addStyle('friendshipChest-prizeSection', {
    style: 'font-white',
    fontSize: 64,
    shadow: [0, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('friendshipChest-friendText', {
    style: 'font-blue',
    fontSize: 24,
  });

  G.Text.addStyle('friendshipChest-redText', {
    style: 'font-white',
    fill: 'red',
    fontSize: 48,
  });

  G.Text.addStyle('shop3-boosterTextStyle0', {
    font: G.Text.defaultFont,
    fill: '#FF2400',
    fontSize: '48px',
  });

  G.Text.addStyle('shop3-boosterTextStyle1', {
    font: G.Text.defaultFont,
    fill: '#101BE9',
    fontSize: '48px',
  });

  G.Text.addStyle('shop3-boosterTextStyle2', {
    font: G.Text.defaultFont,
    fill: '#B42589',
    fontSize: '48px',
  });

  G.Text.addStyle('shop3-boosterTextStyle3', {
    font: G.Text.defaultFont,
    fill: '#D53528',
    fontSize: '48px',
  });

  G.Text.addStyle('shop3-specialDealCoinText', {
    style: 'msg-white',
    fontSize: '64px',
  });

  G.Text.addStyle('shop3-SpecialOffer', {
    style: 'font-white',
    fontSize: 98,
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop3-moreOffer', {
    style: 'font-white',
    fontSize: 98,
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop3-regularValue', {
    font: G.Text.defaultFont,
    fill: '#205CFD',
    fontSize: 36,
  });

  G.Text.addStyle('shop3-headerTitle', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '64px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop3-ValueDeal', {
    font: G.Text.defaultFont,
    stroke: '#2d6128',
    fontSize: 68,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    lineSpacing: -22,
  });

  G.Text.addStyle('eventPostcard-caption', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '48px',
  });

  G.Text.addStyle('eventPostcard-speechText', {
    font: G.Text.defaultFont,
    fill: '#B93318',
    fontSize: '36px',
  });

  G.Text.addStyle('eventPostcard-title', {
    fill: 'white',
    fontSize: '48px',
    align: 'center',
  });

  G.Text.addStyle('eventPostcard-offerTitle', {
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.5)', 0],
    fontSize: '64px',
    align: 'center',
  });

  G.Text.addStyle('eventPostcard-offerCaption', {
    fill: '#AE000B',
    fontSize: '36px',
  });

  G.Text.addStyle('eventPostcard-mapIconTimer', {
    style: 'font-white',
    fontSize: '24px',
    fill: '#FFCBEE',
  });

  G.Text.addStyle('eventPostcard-eventLevelTimer', {
    style: 'font-white',
    fill: 'white',
  });

  G.Text.addStyle('eventPostcard-tutorialText', {
    font: G.Text.defaultFont,
    fill: '#B93318',
    fontSize: '36px',
  });

  G.Text.addStyle('eventPostcard-shopCoinText', {
    font: G.Text.defaultFont,
    fill: '#E31568',
    fontSize: '60px',
  });

  G.Text.addStyle('tokenEvent-eventLevelTitle', {
    style: 'font-white',
    fill: 'white',
    fontSize: '60px',
    align: 'center',
    lineSpacing: -25,
  });

  G.Text.addStyle('tokenEvent-inGameTokenCounter', {
    style: 'font-white',
    fill: 'white',
    fontSize: '48px',
  });

  G.Text.addStyle('UIGoalPanelNr-tokenEvent', {
    font: G.Text.defaultFont,
    fill: '#9C1603',
    fontSize: '60px',
  });

  G.Text.addStyle('UITopMovesLabel-tokenEvent', {
    font: G.Text.defaultFont,
    fontSize: '22px',
    fill: '#9C1603',
    align: 'center',
  });

  G.Text.addStyle('UITopMovesLabel-tokenEvent-horizontal', {
    font: G.Text.defaultFont,
    fill: '#9C1603',
    align: 'center',
    fontSize: '35px',
  });

  G.Text.addStyle('UITopMovesAmount-tokenEvent', {
    font: G.Text.defaultFont,
    fontSize: '28px',
    fill: '#9C1603',
    align: 'center',
  });

  G.Text.addStyle('UITopMovesAmount-tokenEvent-horizontal', {
    font: G.Text.defaultFont,
    fill: '#9C1603',
    align: 'center',
    fontSize: '60px',
  });

  G.Text.addStyle('UIGoalPanelPointsLabel-tokenEvent', {
    font: G.Text.defaultFont,
    fill: '#9C1603',
    fontSize: '40px',
  });

  G.Text.addStyle('UIGoalPanelPointAmount-tokenEvent', {
    font: G.Text.defaultFont,
    fill: '#9C1603',
    fontSize: '40px',
  });

  G.Text.addStyle('UILifeUnlimited', {
    font: G.Text.defaultFont,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '35px',
    stroke: '#a41d02',
    strokeThickness: 5,
  });

  G.Text.addStyle('UILifeTimerTxt', {
    font: G.Text.defaultFont,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '35px',
  });

  G.Text.addStyle('UILifeTimerMax', {
    font: G.Text.defaultFont,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '35px',
  });

  G.Text.addStyle('UIMapStarsTxt', {
    font: G.Text.defaultFont,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '35px',
  });

  G.Text.addStyle('UIMapCoinsTxt', {
    font: G.Text.defaultFont,
    fill: 'white',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    fontSize: '35px',
  });

  G.Text.addStyle('chestShuffleTutorial', {
    font: G.Text.defaultFont,
    fill: '#A92000',
    fontSize: '50px',
    lineSpacing: -20,
  });

  G.Text.addStyle('chestShuffleGift', {
    style: 'font-white',
    shadow: [3, 3, 'rgba(0,0,0,0.7)', 0],
    font: G.Text.defaultFont,
    fill: '#ffffff',
  });

  G.Text.addStyle('mailbox-shareStyle', {
    style: 'font-blue',
    fontSize: '36px',
  });

  G.Text.addStyle('mailbox-shareButton', {
    style: 'font-white',
    fontSize: '80px',
  });

  G.Text.addStyle('shop3-priceCut', {
    font: G.Text.defaultFont,
    fill: '#C33700',
    fontSize: '40px',
    strikeThroughThickness: 3,
    strikeThroughColor: '#C33700',
  });

  G.Text.addStyle('shop3-discountTag', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '48px',
    shadow: [1, 2, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('shop3-saleBanner', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '36px',
    lineSpacing: -10,
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('soloInstance-coinBar', {
    font: G.Text.defaultFont,
    fill: '#AB3309',
    fontSize: '35px',
  });

  G.Text.addStyle('lossAversionTimer', {
    font: G.Text.defaultFont,
    fill: '#982FC3',
    fontSize: '32px',
  });

  G.Text.addStyle('realMoneyWheel-jackpot', {
    font: G.Text.defaultFont,
    fill: '#FFE050',
    fontSize: '32px',
    shadow: [0, 5, 'rgba(0,0,0,0.75)', 0],
  });

  G.Text.addStyle('realMoneyWheel-warning', {
    font: G.Text.defaultFont,
    fill: '#D43808',
    fontSize: '40px',
  });

  G.Text.addStyle('shareMessageText', {
    font: G.Text.defaultFont,
    fontSize: '38px',
    fill: 'white',
    stroke: '#3d95ea',
    strokeThickness: 0,
  });

  G.Text.addStyle('tournamentTaunt-skipText', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '50px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('tournamentTaunt-text', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '50px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    stroke: '#7b92b2',
    strokeThickness: 3,
  });

  G.Text.addStyle('finalCascade-skipText', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '65px',
    stroke: '#B0B0B0',
    strokeThickness: '5',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('realMoneyWheel-warning', {
    font: G.Text.defaultFont,
    fill: '#D43808',
    fontSize: '40px',
  });

  G.Text.addStyle('realMoneyWheel-coinTextPlat', {
    style: 'font-num-blue',
    fontSize: 36,
    fill: '#10821a',
    strokeThickness: 0,
    align: 'center',
    lineSpacing: -10,
  });

  G.Text.addStyle('realMoneyWheel-coinTextSpecialPlat', {
    style: 'font-num-orange',
    fontSize: 36,
    fill: 'white',
    strokeThickness: 0,
    align: 'center',
    lineSpacing: -10,
  });

  G.Text.addStyle('realMoneyWheel-giftGfxPlat', {
    style: 'font-num-realMoneyWheel',
    fill: '#11770d',
    fontSize: 110,
    scaleStroke: true,
  });

  G.Text.addStyle('realMoneyWheel-platinumBanner', {
    style: 'font-white',
    fontSize: '47px',
    fill: '#11770d',
  });

  G.Text.addStyle('mascotWheelHost-speechPlatinum', {
    style: 'realMoneyWheel-warning',
    fontSize: '36px',
    fill: '#10821a',
  });

  G.Text.addStyle('realMoneyWheel-warningPlat', {
    style: 'realMoneyWheel-warning',
    fontSize: '55px',
    fill: '#10821a',
  });

  // Super hard update font styles

  // Window_level, Window_levelFailed, Window_win
  G.Text.addStyle('superhard-hard_1', {
    font: G.Text.defaultFont,
    fill: '#F99BFCFF',
    fontSize: '30px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });
  // UI_TopBar
  G.Text.addStyle('superhard-hard_2', {
    font: G.Text.defaultFont,
    fill: '#9400C5FF',
    fontSize: '20px',
  });
  G.Text.addStyle('superhard-hard_2-horizontal', {
    font: G.Text.defaultFont,
    fill: '#9400C5FF',
    fontSize: '35px',
  });
  // UI_TopBar, Window_level, levelNumberTab
  G.Text.addStyle('superhard-hard_3', {
    font: G.Text.defaultFont,
    fill: '#9400C5FF',
    fontSize: '30px',
  });
  G.Text.addStyle('superhard-hard_3-horizontal', {
    font: G.Text.defaultFont,
    fill: '#9400C5FF',
    fontSize: '60px',
  });
  // eslint-disable-next-line max-len
  // UI_GoalPanelCollect, UI_GoalPanelPoints, Window_level, Window_levelFailed, Window_taskSlider, Window_win
  G.Text.addStyle('superhard-hard_4', {
    font: G.Text.defaultFont,
    fill: '#9400C5FF',
    fontSize: '50px',
  });
  // UI_BoosterButton
  G.Text.addStyle('superhard-hard_5', {
    font: G.Text.defaultFont,
    fill: '#9400C5FF',
    fontSize: '25px',
  });
  G.Text.addStyle('superhard-hard_6', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '30px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });
  G.Text.addStyle('superhard-super_hard_1', {
    font: G.Text.defaultFont,
    fill: '#FBBAB8FF',
    fontSize: '30px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });
  G.Text.addStyle('superhard-super_hard_2', {
    font: G.Text.defaultFont,
    fill: '#CE033BFF',
    fontSize: '20px',
  });
  G.Text.addStyle('superhard-super_hard_3', {
    font: G.Text.defaultFont,
    fill: '#CE033BFF',
    fontSize: '30px',
  });
  G.Text.addStyle('superhard-super_hard_2-horizontal', {
    font: G.Text.defaultFont,
    fill: '#CE033BFF',
    fontSize: '30px',
  });
  G.Text.addStyle('superhard-super_hard_3-horizontal', {
    font: G.Text.defaultFont,
    fill: '#CE033BFF',
    fontSize: '40px',
  });
  G.Text.addStyle('superhard-super_hard_4', {
    font: G.Text.defaultFont,
    fill: '#CE033BFF',
    fontSize: '50px',
  });
  G.Text.addStyle('superhard-super_hard_5', {
    font: G.Text.defaultFont,
    fill: '#CE033BFF',
    fontSize: '25px',
  });
  G.Text.addStyle('superhard-super_hard_6', {
    font: G.Text.defaultFont,
    fill: 'white',
    fontSize: '30px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });
  // Styles for Gingy speech, villains laugh etc.
  G.Text.addStyle('superhard-villain_1', {
    font: G.Text.defaultFont,
    fill: '#FFFFFF',
    fontSize: '25px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });
  // speech bubble styles
  G.Text.addStyle('superhard-villain_2', {
    font: G.Text.defaultFont,
    fill: '#FFFFFF',
    fontSize: '50px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
    lineSpacing: -10,
  });
  G.Text.addStyle('superhard-villain_2_gingy', {
    style: 'superhard-villain_2',
    fill: '#FFFFFF',
  });
  G.Text.addStyle('superhard-villain_2_hard', {
    style: 'superhard-villain_2',
    fill: '#FFFFFF',
  });
  G.Text.addStyle('superhard-villain_2_superhard', {
    style: 'superhard-villain_2',
    fill: '#FFFFFF',
  });
  G.Text.addStyle('superhard-villain_3', {
    font: G.Text.defaultFont,
    fill: '#FFFFFF',
    fontSize: '60px',
    shadow: [2, 3, 'rgba(0,0,0,0.4)', 0],
  });
  G.Text.addStyle('window_itemshop-boosterQuantity', {
    font: G.Text.defaultFont,
    fill: '#008aca',
    fontSize: '70px',
    shadow: [2, 3, 'rgba(0,0,0,0.3)', 0],
  });

  G.Text.addStyle('noAdsPopup-title', {
    style: 'font-white',
    fill: 'white',
    fontSize: '72px',
    align: 'center',
  });

  G.Text.addStyle('noAdsPopup-message', {
    font: G.Text.defaultFont,
    fill: '#883E14',
    fontSize: 36,
    align: 'center',
    lineSpacing: -5,
  });
};
