G.PurchaseMsg = function (config) {
  Phaser.Image.call(this, game);

  this.config = config || {};

  this.bg = G.makeImage(0, 0, 'text_shade_bg', 0.5, this);

  let msg;

  if (config.success) {
    msg = `@task_complete@ ${OMT.language.getText('Purchase successful!')}`;
  } else {
    msg = `@task_fail@ ${OMT.language.getText('Purchase failed!')}`;
  }

  let width;
  let height;

  this.mainMsg = new G.LabelTextT(msg, 0, 0, {
    style: 'font-white',
    fontSize: 70,
  }, 0.5, 600);
  this.addChild(this.mainMsg);
  width = this.mainMsg.width + 100;
  height = 140;

  // remove 0 coins from reward
  if (config.reward) {
    config.reward = config.reward.slice();
    for (let i = config.reward.length - 1; i >= 0; i--) {
      if (config.reward[i][0] === 'coin' && config.reward[i][1] === 0) {
        config.reward.splice(i, 1);
      }
    }
  }

  if (config.success) {
    if (config.reward) {
      this.mainMsg.y = -50;
      this.rewardMsg = new G.LabelTextT(G.gift.getLabelString(config.reward, 1), 0, 50, {
        style: 'font-white',
        fontSize: 70,
      }, 0.5, 600);
      this.addChild(this.rewardMsg);
      width = Math.max(width, this.rewardMsg.width + 100);
    }
    height = 300;

    if (G.saveState.iapMultiplier !== 1) { // Purchase happened and we need to reset this
      G.saveState.changeIAPMultiplier(1);
    }
  }

  this.bg.y = 10;
  this.bg.width = width;
  this.bg.height = height;

  this.fixedToCamera = true;

  this.cameraOffset.x = game.width * 0.5;
  this.cameraOffset.y = game.height * 0.5;
  this.onFinished = new Phaser.Signal();

  game.add.tween(this).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true, this.config.duration || 2500).onComplete.add(function () {
    this.destroy();
  }, this);
  game.add.tween(this).from({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true);
  game.add.existing(this);
  this.events.onDestroy.add(function () {
    this.onFinished.dispatch();
  }, this);
};

G.PurchaseMsg.prototype = Object.create(Phaser.Image.prototype);

G.PurchaseMsg.prototype.update = function () {
  this.cameraOffset.x = game.width * 0.5;
  this.cameraOffset.y = game.height * 0.5;
};
