/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */

import {
  getFriendsScores as GBC_getFriendsScores,
  getTopScores as GBC_getTopScores,
  syncFriendListByPlatformPlayerId as GBC_syncFriendListByPlatformPlayerId,
  postScore as GBC_postScore,
  getUsersScores as GBC_getUsersScores,
} from '@sgorg/game-backend-client';

import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { OMT_DataCacheWithIO } from '@omt-components/Services/Utils/OMT_DataCacheWithIO';

const STARS_LEADERBOARD = {
  name: 'totalStars',
  instanceId: 'default',
};

const OFFLINE_STORES_STORAGE_KEY = 'offline-scores';

/**
 *  class for handling data related to SoftGames leaderboards
 */
export class OMT_Leaderboards {
  /**
   * constructor
   * @param {number} dataExpiry expiry time in seconds
   * @param {OMT_DataCache} cache OMT_DataCache instance
   */
  constructor(dataExpiry, cache) {
    this._dataExpiry = dataExpiry;
    this._cache = cache;
    this._offlineScoresCache = new OMT_DataCacheWithIO(OFFLINE_STORES_STORAGE_KEY);
  }

  /**
   * load friends leaderboard entry list from the game-backend
   * @param {string} leaderboardName
   * @param {string} instanceId
   * @returns {Promise<Array>}
   */
  async _loadFriendsLeaderboardEntries(leaderboardName, instanceId) {
    return new Promise((resolve, reject) => {
      GBC_getFriendsScores({ leaderboardName, instanceId }).then((leaderboardEntries) => {
        resolve(leaderboardEntries);
      }).catch((error) => {
        resolve([]);
      });
    });
  }

  /**
   * get friends leaderboard entry list
   * @param {string} leaderboardName leaderboard name
   * @param {string} instanceId leaderboard instance id
   * @param {boolean} useCache use cached version if available and not expired
   * @param {boolean} allowExpired allow fetching of expired data
   * @returns {Promise<Array>}
   */
  async getFriendsLeaderboardEntries(leaderboardName, instanceId, useCache = true, allowExpired = false) {
    const cacheKey = `leaderboard_${leaderboardName}_${instanceId}`;
    try { // attempt to get leaderboard data
      if (useCache && (!this._cache.isDataExpired(cacheKey, this._dataExpiry) || allowExpired)) return this._cache.getData(cacheKey);
      if (!OMT.connect.isConnected()) return this._getOfflineScores(cacheKey);

      const friendsList = await OMT.friends.getFriendsList();
      const entryDataList = await this._loadFriendsLeaderboardEntries(leaderboardName, instanceId);
      const leaderboardFriendList = []; let leaderboardFriendData;

      // propagate leaderboard list with merged entryData / friendData
      for (const entryData of entryDataList) {
        for (let i = 0; i < friendsList.length; i++) {
          const friendData = friendsList[i];
          if (friendData.id === entryData.user.playerId) { // add matching friends data to the list
            leaderboardFriendData = this._createLeaderboardFriendData(entryData, friendData);
            leaderboardFriendList.push(leaderboardFriendData);
            break;
          }
        }
        // merge in YOUR data to the list
        if (entryData.user.playerId === OMT.envData.settings.user.userId) {
          leaderboardFriendData = this._createLeaderboardFriendData(entryData, {
            id: OMT.envData.settings.user.userId,
            name: OMT.envData.settings.user.name,
            image: OMT.envData.settings.user.avatar,
          });
          leaderboardFriendList.push(leaderboardFriendData);
        }
      }
      // set the cache data
      this._cache.setData(cacheKey, leaderboardFriendList);
      OMT_Utils.stylizedLog(`OMT_Leaderboards: leaderboard (${leaderboardName},${instanceId}) updated`);
      return leaderboardFriendList;
    } catch (error) { // could not get leaderboard data use offline data
      return this._getOfflineScores(cacheKey);
    }
  }

  /**
   * create a leaderboard data object with a users friend data merged in
   * @param {Object} entryData data from the leaderboard
   * @param {Object} friendData data from the friendslist
   * @returns {Object}
   */
  _createLeaderboardFriendData(entryData, friendData) {
    const leaderboardFriendData = {};
    leaderboardFriendData.id = leaderboardFriendData.userId = friendData.id;
    leaderboardFriendData.name = friendData.name;
    leaderboardFriendData.image = friendData.image;
    leaderboardFriendData.rank = entryData.rank;
    leaderboardFriendData.score = entryData.score;
    leaderboardFriendData.isCurrentUser = friendData.id === OMT.envData.settings.user.userId;
    return leaderboardFriendData;
  }

  /**
   * load global scores leaderboard entry list from the game-backend
   * @param {string} leaderboardName
   * @param {string} instanceId
   * @param {number} limit
   * @returns {Promise<Array>}
   */
  async _loadGlobalLeaderboardEntries(leaderboardName, instanceId, limit) {
    return new Promise((resolve, reject) => {
      GBC_getTopScores({ leaderboardName, instanceId, limit }).then((leaderboardEntries) => {
        resolve(leaderboardEntries);
      }).catch((error) => {
        resolve([]);
      });
    });
  }

  /**
   * get leaderboard entries for ALL users, will not container the users image
   * @param {string} leaderboardName leaderboard name
   * @param {string} instanceId leaderboard instance id
   * @param {boolean} useCache use cached version if available and not expired
   * @param {boolean} allowExpired allow fetching of expired data
   * @returns {Promise<Array>}
   */
  async getGlobalLeaderboardEntries(leaderboardName, instanceId, limit, useCache = true, allowExpired = false) {
    const cacheKey = `global_leaderboard_${leaderboardName}_${instanceId}`;
    try { // attempt to get leaderboard data
      if (useCache && (!this._cache.isDataExpired(cacheKey, this._dataExpiry) || allowExpired)) return this._cache.getData(cacheKey);
      if (!OMT.connect.isConnected()) return this._getOfflineScores(cacheKey);

      const entryDataList = await this._loadGlobalLeaderboardEntries(leaderboardName, instanceId, limit);
      const globalEntryList = [];

      for (const entryData of entryDataList) {
        const globalUserData = this._createGlobalUserDataInstance(entryData);
        globalEntryList.push(globalUserData);
      }

      // set the cache data
      this._cache.setData(cacheKey, globalEntryList);
      OMT_Utils.stylizedLog(`OMT_Leaderboards: global-leaderboard (${leaderboardName},${instanceId}) updated`);
      return globalEntryList;
    } catch (error) { // could not get leaderboard data use offline data
      return [];
    }
  }

  /**
   * create a user-data Obejct for scores from global leaderboards.
   * @param {Object} entryData data from the leaderboard
   * @param {Object} friendData data from the friendslist
   * @returns {Object}
   */
  _createGlobalUserDataInstance(entryData) {
    const leaderboardFriendData = {};
    leaderboardFriendData.id = entryData.user.playerId;
    leaderboardFriendData.name = entryData.user.handle;
    leaderboardFriendData.rank = entryData.rank;
    leaderboardFriendData.score = entryData.score;
    return leaderboardFriendData;
  }

  /**
   * get you leaderboard entry from a specific leaderboard instance
   * @param {string} leaderboardName
   * @param {string} instanceId
   */
  async getYourLeaderboardEntry(leaderboardName, instanceId) {
    let entryData = null;
    try {
      entryData = (await GBC_getUsersScores({ leaderboardName, instanceId, players: [OMT.envData.settings.user.userId] }))[0] || null;
    } catch (error) {
      //
    }
    const leaderboardFriendData = this._createLeaderboardFriendData(entryData || { score: 0, rank: 1 }, {
      id: OMT.envData.settings.user.userId,
      name: OMT.envData.settings.user.name,
      image: OMT.envData.settings.user.avatar,
    });
    return leaderboardFriendData;
  }

  /**
   * calls getLeaderboardEntries() for the STARS_LEADERBOARD, but merges in ALL friends even if they have no score.
   * @returns {Promise<Array>}
   */
  async getTotalStarsLeaderboardEntries() {
    // copy leaderboardFriendList we dont want to manipulate the original array
    const leaderboardFriendList = (await this.getFriendsLeaderboardEntries(STARS_LEADERBOARD.name, STARS_LEADERBOARD.instanceId)).slice();
    const friendIdsWithScores = leaderboardFriendList.map((leaderboardFriendData) => leaderboardFriendData.id);
    const friendsList = await OMT.friends.getFriendsList();
    for (const friendData of friendsList) {
      // skip if already in the leaderboardFriendList
      if (friendIdsWithScores.indexOf(friendData.id) >= 0) continue;
      // create dummy leaderboard entry for untracked friend
      const leaderboardFriendData = this._createLeaderboardFriendData({ rank: leaderboardFriendList.length + 1, score: 0 }, friendData);
      leaderboardFriendList.push(leaderboardFriendData);
    }
    return leaderboardFriendList;
  }

  /**
   * post a score to a leaderboard
   * @param {string} leaderboardName leaderboard name
   * @param {string} instanceId leaderboard instance id
   * @param {number} score
   * @param {boolean} updateCache (optional) if true will load / update the cache for friends data
   * @returns {Promise}
   */
  async postScoreToLeaderboard(leaderboardName, instanceId, score) {
    const postObj = { leaderboardName, instanceId, score };
    const cacheKey = `leaderboard_${leaderboardName}_${instanceId}`;

    if (!OMT.connect.isConnected()) { // game-backend is not connected
      this._updateOfflineScores(cacheKey, postObj);
      return;
    }

    if (this._cache.getData(cacheKey) == null) await this.getFriendsLeaderboardEntries(leaderboardName, instanceId, false);
    this._updateCachedLeaderboard(cacheKey, score);

    try { // try to post the score to the game-backend
      await GBC_postScore(postObj);
    } catch (error) {
      this._updateOfflineScores(cacheKey, postObj);
    }
  }

  /**
   * update score in cached leaderboard data
   * @param {string} leaderboardName
   * @param {string} instanceId
   * @param {number} score
   */
  _updateCachedLeaderboard(cacheKey, score) {
    if (this._cache.hasKey(cacheKey)) { // update the score value in the cached data
      let leaderboardFriendList = this._cache.getData(cacheKey);
      // eslint-disable-next-line arrow-body-style
      let yourData = leaderboardFriendList.find((leaderboardData) => { return leaderboardData.isCurrentUser; });
      if (yourData) { // update score
        yourData.score = parseInt(score);
      } else { // insert new data if you are not in this list
        yourData = {};
        yourData.id = yourData.userId = OMT.envData.settings.user.userId;
        yourData.name = OMT.envData.settings.user.name;
        yourData.score = parseInt(score);
        yourData.image = OMT.envData.settings.user.avatar;
        yourData.isCurrentUser = true;
        leaderboardFriendList.push(yourData);
      }
      leaderboardFriendList = leaderboardFriendList.sort((a, b) => b.score - a.score);
      for (let i = 0; i < leaderboardFriendList.length; i++) leaderboardFriendList[i].rank = i + 1;
      this._cache.setData(cacheKey, leaderboardFriendList, false);
    }
  }

  /**
   * get friend map positions stored as leaderboard data
   * @returns {Promise<Array>}
   */
  async getFriendMapPositionEntries() {
    const mapPosistionEntries = await this.getFriendsLeaderboardEntries(G.OMTsettings.global.leaderboardName, 'default');
    return mapPosistionEntries;
  }

  /**
   * post a map position update, this is tracked as a leaderboard
   * @param {number} levelNum
   * @returns {Promise}
   */
  async postMapPositionUpdate(levelNum, instanceId = 'default') {
    const cacheKey = 'friendsMapPositions';
    const postObj = {
      leaderboardName: G.OMTsettings.global.leaderboardName,
      instanceId,
      score: levelNum,
    };

    if (!OMT.connect.isConnected()) { // game-backend is not connected
      this._updateOfflineScores(cacheKey, postObj);
      return;
    }

    try { // try to post the score to the game-backend
      await GBC_postScore(postObj);
    } catch (error) {
      this._updateOfflineScores(cacheKey, postObj);
    }
  }

  /**
   * update offline score if we could not post to the leaderboard
   * @param {string} cacheKey
   * @param {Object} postObj score post object
   */
  _updateOfflineScores(cacheKey, postObj) {
    // look for previous posts in the cache
    const prevPostObj = this._offlineScoresCache.getData(cacheKey);
    // dont store if previous score is higher
    if (prevPostObj && prevPostObj.score > postObj.score) return;
    // update post to cache
    this._offlineScoresCache.setData(cacheKey, postObj);
    this._offlineScoresCache.writeToLocalStorage();
  }

  /**
   * get offline scores for when leaderboard data cannot be fetched, contains only your data
   * @param {string} cacheKey
   * @returns {Array.<Object>}
   */
  _getOfflineScores(cacheKey) {
    // look for previous posts in the cache
    const prevPostObj = this._offlineScoresCache.getData(cacheKey);
    if (!prevPostObj) return [];

    const leaderboardFriendData = {};
    leaderboardFriendData.id = leaderboardFriendData.userId = OMT.envData.settings.user.userId;
    leaderboardFriendData.name = OMT.envData.settings.user.name;
    leaderboardFriendData.rank = 1;
    leaderboardFriendData.score = prevPostObj.score;
    leaderboardFriendData.image = OMT.envData.settings.user.avatar;
    leaderboardFriendData.isCurrentUser = true;
    return [leaderboardFriendData];
  }

  /**
   * post any offline scores that were recorded
   */
  async postOfflineScores() {
    const keyList = this._offlineScoresCache.getKeys();
    for (const cacheKey of keyList) {
      try {
        const prevPostObj = this._offlineScoresCache.getData(cacheKey);
        await GBC_postScore(prevPostObj);
        OMT_Utils.stylizedLog(`OMT_Leaderboards: offline score (${cacheKey}) posted succesfully`);
      } catch (error) {
        OMT_Utils.stylizedLog('OMT_Leaderboards: failed to post offline score to game-backend', '#FFFF00');
      }
    }
    this._offlineScoresCache.clearAllData();
    this._offlineScoresCache.writeToLocalStorage();
  }
}
