/* eslint-disable operator-linebreak */
/* eslint-disable func-names */
//
// $ - text from json
// @ - img
// % - variable
// ^ - text as it is
//

const { default: ComponentGameHooks } = require('@omt-components/ComponentGameHooks');

G.LabelParser = {
  specialChars: ['$', '@', '%', '^'],

  changeIntoTagArray(str, propObj) {
    const result = [];

    let i = 0;

    while (str.length > 0) {
      if (i++ > 20) break;

      const firstTag = this.findFirstSpecialChar(str);

      if (firstTag === -1) {
        result.push(str);
        break;
      } else {
        if (firstTag[0] > 0) {
          result.push(str.slice(0, firstTag[0]));
          str = str.slice(firstTag[0]);
        }
        str = this.cutOffTag(str, result, firstTag[1]);
      }
    }

    //
    // change strings into objects
    //
    const processedResult = [];
    for (let j = 0; j < result.length; j++) {
      processedResult.push(this.processTag(result[j], propObj));
    }

    //
    // merge texts obj
    //
    //
    return this.mergeTextTagsInArray(processedResult);
  },

  mergeTextTagsInArray(tagArray) {
    const mergedArray = [];

    let startIndex = null;

    let i;
    for (i = 0; i < tagArray.length; i++) {
      if (tagArray[i].type !== 'text') {
        if (startIndex !== null) {
          mergedArray.push(this.mergeTextTags(tagArray, startIndex, i));
          startIndex = null;
        }

        mergedArray.push(tagArray[i]);
      } else if (startIndex === null) {
        startIndex = i;
      }
    }

    if (startIndex !== null) {
      mergedArray.push(this.mergeTextTags(tagArray, startIndex, i));
    }

    return mergedArray;
  },

  mergeTextTags(array, startIndex, endIndex) {
    const newObj = { type: 'text', content: [] };

    for (; startIndex < endIndex; startIndex++) {
      newObj.content.push(array[startIndex].content);
    }

    newObj.content = newObj.content.join(' ');

    return newObj;
  },

  processTag(elem, propObj) {
    if (elem[0] === '@') {
      let scale = 1;

      if (elem[1] === '*' && elem.indexOf('*', 2)) {
        scale = parseFloat(
          elem.slice(elem.indexOf('*') + 1, elem.indexOf('*', 2)),
        );
        elem = elem.slice(elem.indexOf('*', 2));
      }

      return {
        type: 'img',
        content: elem.slice(1, -1),
        scale,
      };
    }

    if (elem[0] === '%') {
      return {
        type: 'text',
        content: propObj[elem.slice(1, -1)],
      };
    }

    if (elem[0] === '$') {
      return {
        type: 'text',
        content: ComponentGameHooks.getLocalizedString(elem.slice(1, -1)),
      };
    }

    if (elem[0] === '^') {
      return {
        type: 'text',
        content: elem.slice(1, -1),
      };
    }

    if (this.isStringJustSpaces(elem)) {
      return {
        type: 'separator',
        content: elem,
        length: elem.length,
      };
    }

    return {
      type: 'text',
      content: elem,
    };
  },

  isStringJustSpaces(elem) {
    for (let i = 0; i < elem.length; i++) {
      if (elem[i] !== ' ') return false;
    }

    return true;
  },

  cutOffTag(str, result, tag) {
    const startIndex = str.indexOf(tag);
    const endIndex = str.indexOf(tag, startIndex + 1);

    result.push(str.slice(startIndex, endIndex + 1));

    return str.slice(0, startIndex) + str.slice(endIndex + 1);
  },

  findFirstSpecialChar(str) {
    let smallest = Infinity;
    let foundedChar = false;

    this.specialChars.forEach((char) => {
      const index = str.indexOf(char);

      if (index > -1 && smallest > index) {
        foundedChar = char;
        smallest = Math.min(index, smallest);
      }
    });

    if (smallest === Infinity) return -1;

    return [smallest, foundedChar];
  },

  createLabel(
    string,
    propObj,
    x,
    y,
    font,
    fontSize,
    anchorX,
    anchorY,
    distanceBetween,
    maxWidth,
  ) {
    this.changeIntoTagArray(string, propObj);

    const group = new G.LabelGroup(
      x,
      y,
      fontSize,
      distanceBetween,
      anchorX,
      anchorY,
      maxWidth,
    );

    return group;
  },
};

G.LabelGroup = function (
  str,
  x,
  y,
  font,
  fontSize,
  anchorX,
  anchorY,
  maxWidth,
) {
  Phaser.Group.call(this, game);

  this.fontData = game.cache.getBitmapFont(font).font;
  this.fontBaseSize = this.fontData.size;
  this.fontSpaceOffset =
    this.fontData.chars['32'].xOffset + this.fontData.chars['32'].xAdvance;

  this.str = str;
  this.tagArray = G.LabelParser.changeIntoTagArray(str);

  this.x = typeof x === 'undefined' ? 0 : Math.floor(x);
  this.y = typeof y === 'undefined' ? 0 : Math.floor(y);
  this.font = font;
  this.fontSize = typeof fontSize === 'undefined' ? 30 : Math.floor(fontSize);
  this.distanceBetween = 0;

  this.anchorX = typeof anchorX === 'undefined' ? 0.5 : anchorX;
  this.anchorY = typeof anchorY === 'undefined' ? 0.5 : anchorY;

  this.maxWidth = maxWidth || 0;

  this.processTagArray();
};

G.LabelGroup.prototype = Object.create(Phaser.Group.prototype);

G.LabelGroup.prototype.processTagArray = function () {
  for (let i = 0; i < this.tagArray.length; i++) {
    if (this.tagArray[i].type === 'img') {
      const img = G.makeImage(0, 0, this.tagArray[i].content, 0, this);
      img.tagScale = this.tagArray[i].scale;
    } else if (this.tagArray[i].type === 'separator') {
      const img = G.makeImage(0, 0, null, 0, this);
      img.SEPARATOR = true;
      img.SEP_LENGTH = this.tagArray[i].length;
    } else {
      this.add(
        game.add.bitmapText(
          0,
          0,
          this.font,
          this.tagArray[i].content,
          this.fontSize,
        ),
      );
    }
  }

  this.refresh();
};

G.LabelGroup.prototype.refresh = function () {
  this.applySizeAndAnchor();

  if (this.maxWidth > 0 && this.getWholeWidth() > this.maxWidth) {
    while (this.getWholeWidth() > this.maxWidth) {
      this.distanceBetween *= 0.9;
      this.fontSize *= 0.9;
      this.applySizeAndAnchor();
    }
  }

  this.spreadElements();
};

G.LabelGroup.prototype.applySizeAndAnchor = function () {
  this.children.forEach(function (e) {
    e.anchor.setTo(this.anchorX, this.anchorY);

    if (e.fontSize) {
      e.fontSize = this.fontSize;
      e.updateText();
    } else {
      e.height = this.fontSize * (e.tagScale || 1);
      e.scale.x = e.scale.y;
    }

    if (e.SEPARATOR) {
      e.width =
        (this.fontSize / this.fontBaseSize) *
        this.fontSpaceOffset *
        e.SEP_LENGTH;
    }
  }, this);
};

G.LabelGroup.prototype.getWholeWidth = function () {
  const allDistanceBetween = (this.children.length - 1) * this.distanceBetween;
  let widthOfAllElements = 0;
  this.children.forEach((e) => {
    widthOfAllElements += e.width;
  });

  return allDistanceBetween + widthOfAllElements;
};

G.LabelGroup.prototype.spreadElements = function () {
  const startX = this.getWholeWidth() * this.anchorX * -1;

  this.children.forEach(function (e, index, array) {
    e.left =
      index === 0 ? startX : array[index - 1].right + this.distanceBetween;
  }, this);
};
