/**
 * The hand that appears on the world map and points at the bubble gifts or level nodes
 */
export default class MapTutHand extends G.Image {
  /**
   * MapTutHand is created first to secure layer order
   * @param {WorldMap} map
   */
  constructor(map) {
    super(0, 0, 'tut_hand', 0);
    this.map = map;
    this.positionIsSet = false;
    this.visible = false;

    game.add
      .tween(this.anchor) // Pointy
      .to(
        { x: -0.1, y: -0.1 },
        500,
        Phaser.Easing.Sinusoidal.InOut,
        true,
        0,
        -1,
        true,
      );

    game.add.existing(this);
  }

  /**
   * Repositioning happens here as it calculates where it should go
   */
  reposition() {
    const lastPassed = G.saveState.getLastPassedLevelNr();
    const levelData = G.Helpers.levelDataMgr.getLevelByIndex(lastPassed);
    if (levelData) {
      this._levelPos = {
        // Level pos is set to the level node
        x: levelData.mapX,
        y: levelData.mapY,
      };
    }
    // Gets Gate position (into levelPos);
    if (lastPassed === G.saveState.gateManager.getNextGateLevel()) {
      this._levelPos.y += 35;
    }
    this.visible = true;
    if (this._levelPos) {
      this.position.setTo(this._levelPos.x, this._levelPos.y);
    } else {
      this.visible = false;
    }
    this.positionIsSet = true;
  }
}
