import { ProgressBar } from '../../ProgressBar';

export class EventLevelProgression extends Phaser.Group {
  constructor(x, y, initialValue, initialMax) {
    super(game);

    this.x = x;
    this.y = y;
    this._specialEventMgr = G.saveState.tokenEventManager;

    this._initProgressBar(initialValue, initialMax);
  }

  /**
   * Initializes progress bar with some value
   * @param {number} initialValue
   * @param {number} initialMax
   */
  _initProgressBar(initialValue, initialMax) {
    this._progressBar = new ProgressBar(game, {
      x: -3,
      y: 0,
      atlas: 'tokenEvent',
      bgSprite: 'tokenEvent_pumpkin_scale_empty',
      fgSprite: 'tokenEvent_pumpkin_scale_full',
      width: 325,
      height: 30,
      value: initialValue != null ? initialValue : this._specialEventMgr.tierTokensForPostcard,
      valueMax: initialMax != null ? initialMax : this._specialEventMgr.tierTokensRequiredForNextPostcard,
      isAnimated: true,
      fillSfx: 'transition',
      fullSfx: 'match_5',
    }, this);

    this._graphicLeft = G.makeImage(-190, 0, G.OMTsettings.tokenEvent.boardTokenAsset, [0.5, 0.5], this);
    this._graphicLeft.scale.set(89 / this._graphicLeft.width);
    this._graphicRight = G.makeImage(190, 0, 'tokenEvent_closed_envelope', [0.5, 0.5], this);
  }

  /**
   * Update progress bar
   * @param {boolean} useManagerValues if true, pull value from special event manager
   * @param {number} value if useManagerValue is false, use this value instead
   * @param {function} onFinish function that runs where animation is finished
   */
  updateProgress(useManagerValue, value, onFinish) {
    const newValue = useManagerValue ? this._specialEventMgr.tierTokensForPostcard : value;
    this._progressBar.updateValue(newValue, null, onFinish);
  }
}
