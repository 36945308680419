import ShareMsgBase from './A_G.ShareMsgBase';


export default class MsgEventSharePostcard extends ShareMsgBase {
  /**
   * The message that is shared when you share an event postcard image
   * @param {string} txt
   * @param {string} category
   * @param {function} onAllImagesReadyCallback
   */
  constructor(cardIndex, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);
    const avatar = G.makeExtImage(143, 294, OMT.envData.settings.user.avatar, 'avatar_m', 0.5, this, false, (img) => {
      img.width = 88;
      img.height = 88;

      this._objectLoadComplete();
    });
    avatar.angle = -6;

    // TokenEvent images are prebaked so it must used a different image
    const cardData = G.OMTsettings.postcardEvent.cardData.postcardData[cardIndex];
    this.img = this._addExtImage(0, 0, cardData.shareImage, 0, this._idealDimensions.width, this._idealDimensions.height);

    this.txt = new G.Text(358, 304, OMT.language.getText(cardData.speechText), 'eventPostcard-speechText', 0.5, 262, 55, true, 'center');
    this.add(this.txt);

    this._objectLoadComplete();
  }
}

G.MsgEventPostcard = MsgEventSharePostcard;
