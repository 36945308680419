/* eslint-disable no-restricted-globals */
/* eslint-disable no-use-before-define */
import WorldMapBackgroundTiles2 from './Layers/WorldMapBackgroundTiles2';
import WorldMapRiverTile from './Layers/WorldMapRiverLayer';
import WorldMap2_Util, {
  levelNodeType,
  interactionType,
  WorldMapTwoMapInteractionLimit,
  WorldMap2_globalSignals,
} from './WorldMap2_Util';
import WorldMapLevelLayer from './Layers/WorldMapLevelLayer';
import WorldMapChestShuffleEntry from './Layers/WorldMapChestShuffleEntry';
// import WorldMapMailboxEntry from './Layers/WorldMapMailboxEntry';
import WorldMapSagaMapChestEntry from './Layers/WorldMapSagaMapChestEntry';
import WorldMapCloudLayer from './Layers/WorldMapCloudLayer';
import { MILLISECONDS_IN_MIN } from '@omt-components/Utils/TimeUtil';
import WorldMapSocialLayer from './Layers/WorldMapSocialLayer';
import MapQuickPanButton, { LAYOUT_TYPE as QUICK_PAN_LAYOUT_TYPE } from './Avatars/MapPlayerAvatarPanButton';

import WorldMapInteractionNGPool from './Layers/WorldMapInteractionNGPool';
import MapInteractionPanButton, { LAYOUT_TYPE as MAP_INTERACTION_LAYOUT_TYPE } from './Avatars/MapInteractionPanButton';
// import { GATE_COOLDOWN_KEY, GATE_OPEN_REASON } from '../../Services/OMT/dataTracking/gateManager/GateManager';
import WorldMapVillains from './Layers/WorldMapVillains';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { WorldMapProps } from './Layers/WorldMapProps';
import { SaveStateUtils } from '../../Services/SaveState/SaveStateUtils';
import { GameScaleController } from '../../States/Scaling/GameScaleController';

const velocityEpsilon = 0.5;
const c_magicRiverNumber = 7; // Magic number that helps make rivers be more rare
const c_magicTileToRiverScaleNumber = { x: 0.29, y: 0.2 }; // Magic number tha offsets river onto the path
const c_halfMapPoint = 320;

export default class WorldMap2 extends Phaser.Group {
  /**
   * This is the world map container for the World state.
   * Unfortunately, its not possible to avoid going in a negative number for scrolling due to the way the map scrolls.
   * WorldMap2 should only have stuff related to rendering and organizing.
   * Any calls because of things happening should be done outside...
   *
   * @param {{
   *    maxLevel:number,
   *    prefabEditor:boolean,
   *    orientation: number
   *    animateSkipTo: { level: number, fxFunc:Function }}} configOverride
   */
  constructor(configOverride = {}) {
    super(game, null);

    this._configOverride = configOverride; // Override for external use. Not in OMT!
    this._assembleData();
    this._setupAssets();
    this._startMap();

    // Downscale on landscape
    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      const { gameScale } = GameScaleController.getInstance();

      // this.x = (this.width * (1 - gameScale)) * 0.5 * gameScale;
      // this.y = (this.height * (1 - gameScale)) * 0.5 * gameScale;
      this.x = (window.innerWidth * (1 - gameScale)) * 0.5 * gameScale;
      this.y = (window.innerHeight * (1 - gameScale)) * 0.5 * gameScale;
      this.scale.setTo(gameScale);
    }
  }

  async _startMap() {
    let lastLevel = G.saveState.getLastPassedLevelNr();
    if (Number.isNaN(lastLevel)) {
      lastLevel = this._pools.levelNode.levelLimit;
    }
    await this.panToLevelNode(Math.max(lastLevel, 0), true);
    if (this._mapInteractionPanButton) {
      this._mapInteractionPanButton.startInteractionCheck(this.signals.onMapScrolled);
    }
    this.signals.mapIsReady.dispatch();
  }

  /**
   * Returns if its prefab editor mode or not
   * @returns {Boolean}
   */
  get _prefabEditorMode() {
    return Boolean(this._configOverride.prefabEditor);
  }

  get width() {
    if (this._prefabEditorMode && this._pools.bgTiles) {
      return this._pools.bgTiles.averageTileMapWidth;
    }
    return super.width;
  }

  /**
   * Returns the max number of levels on the map
   */
  get maxMapLevel() {
    if (this._pools.levelNode) {
      return this._pools.levelNode.levelLimit;
    }
    return 0;
  }

  /**
   * Returns all active props
   * @returns {Array<WorldMapProps>}
   */
  get allActiveProps() {
    if (this._prefabEditorMode && this._pools.props) {
      return this._pools.props.actives;
    }
    return [];
  }

  /**
   * Returns all active segments.
   * Only in prefab editor
   */
  get allActiveSegments() {
    if (this._prefabEditorMode && this._pools.bgTiles) {
      return this._activeSegments;
    }
    return [];
  }

  /**
   * Grabs all the active tiles that will be used for considering level nodes.
   * Only in prefab editor
   */
  get consideringTiles() {
    if (this._prefabEditorMode && this._pools.bgTiles) {
      const maxPossibleTiles = WorldMap2_Util.getTileSprites().length;
      const tileArr = [];
      const activeTiles = this._activeSegments.slice().sort((a, b) => b.index - a.index);
      const startingTile = Math.max(0, Math.min(activeTiles.length, 1)); // Attempt to get the tiles in the middle and not edges
      const limitTile = Math.min(activeTiles.length, maxPossibleTiles) + startingTile;
      for (let i = startingTile; i < limitTile; i++) {
        const tile = activeTiles[i];
        const textureIndex = this._pools.bgTiles.getTextureIndexOfIndex(tile.index);
        tileArr[textureIndex] = tile;
      }
      return tileArr;
    }
    return [];
  }

  /**
   * Returns an object that is used for the prefab editor.
   * Collects positions of level nodes and assembles them into new spots
   * @returns {{river:Array<{x:number, y:number}>, path:Array<Array<{x:number, y:number}>>}}
   */
  assembleLevelNodesIntoTilePositions() {
    if (this._prefabEditorMode && this._pools.bgTiles) {
      const maxPossibleTiles = WorldMap2_Util.getTileSprites().length;
      const newlyConsideredNodes = [];
      const tileArr = this.consideringTiles;
      for (const tile of tileArr) {
        for (const levelNode of tile.levelNodes) { // eslint-disable-line guard-for-in
          newlyConsideredNodes.push(levelNode);
        }
      }
      const newLevelNodePosArr = {
        river: [],
        path: [],
      };
      for (const node of newlyConsideredNodes) {
        for (let i = 0; i < tileArr.length; i++) {
          if (!newLevelNodePosArr.path[i]) { newLevelNodePosArr.path[i] = []; }
          let targetArr = newLevelNodePosArr.path[i];
          const tile = tileArr[i];
          if (tile.river) {
            targetArr = newLevelNodePosArr.river;
          }

          if (tile && Phaser.Rectangle.intersects(node.getBounds(), tile.mapTile.getBounds()) && (node.y - tile.mapTile.y) >= 0) {
            const obj = {
              x: Number.parseFloat((node.x).toFixed(2)),
              y: Number.parseFloat((node.y - tile.mapTile.y).toFixed(2)),
            };
            targetArr.push(obj);
            break;
          }
        }
      }

      for (let i = 0; i < maxPossibleTiles; i++) {
        let sect = newLevelNodePosArr.path[i];
        if (!sect || (sect && sect.length === 0)) {
          newLevelNodePosArr.path[i] = G.OMTsettings.elements.worldMap.levelNodePositions[i];
        }
        sect = sect.sort((a, b) => a.y - b.y);
      }
      if (newLevelNodePosArr.river.length === 0) {
        newLevelNodePosArr.river = G.OMTsettings.elements.worldMap.riverTileExtra.offset.slice();
      }
      newLevelNodePosArr.river = newLevelNodePosArr.river.sort((a, b) => a.y - b.y);
      return newLevelNodePosArr;
    }
    return [];
  }

  /**
   * Externally set input enabled
   * @param {Boolean}
   */
  set inputEnabled(b) {
    this._inputEnabled = b;
  }

  /**
   * Returns all active level nodes
   * @returns {Array<WorldMapBasicNode>}
   */
  get allLevelNodes() {
    if (this._prefabEditorMode && this._pools.levelNode) {
      return this._pools.levelNode.actives;
    }
    return [];
  }

  /**
   * Returns the level node's world position
   * @param {number} level The index of the level node
   */
  getWorldPositionOfLevelNodeAt(level) {
    const node = this._pools.levelNode.getNodeAtLevel(level);
    if (node) {
      return {
        x: node.worldPosition.x,
        y: node.worldPosition.y,
      };
    }
    return null;
  }

  /*
   * Returns the level node object of the given index
   * @param {number} level The index of the level node
   */
  getLevelNodeAt(level) {
    const node = this._pools.levelNode.getNodeAtLevel(level);
    if (node) {
      return node;
    }
    return null;
  }

  /**
   * Returns the villain wrapper object of the given index
   * @param {number} level The index of the level node
   */
  getVillainWrapperAt(level) {
    const wrapper = this._pools.villains.getNodeAtLevel(level);
    if (wrapper) {
      return wrapper;
    }
    return null;
  }

  /**
   * Changes the orientation of the map. Only possible in preview editor
   * @param {number} orientation
   */
  changeOrientation(orientation) {
    if (this._prefabEditorMode && this._pools.props) {
      if (Number.isFinite(orientation)) {
        this._mapOrientation = Math.max(ORIENTATION.vertical, Math.min(ORIENTATION.horizontal, orientation));
        this._calculateOrientationAdjustments();
        this._updateMapScale();
        this._onOrientationChange();
      }
    }
  }

  /**
   * Assembles data together
   */
  _assembleData() {
    this._activeSegments = [];
    this._existingTiles = []; // Temp array to hold existing tiles. Gets cleaned often
    this._scrollIgnore = []; // Array to hold functions that returns rectangles. Will ignore scroll input if within these rectangles

    // Values
    this._inputEnabled = true;
    this._allowXMovement = false;
    this._tweenTime = this._configOverride.animateSkipTo ? this._configOverride.animateSkipTo.tweenTime : 1300;
    this._ignoreRatioTween = false;
    this._mapOrientation = Number.isFinite(this._configOverride.orientation) ? this._configOverride.orientation : OMT.systemInfo.orientation;
    this._calculateOrientationAdjustments();

    // Signals
    this.signals = {
      mapIsReady: new Phaser.Signal(),
      onMapScrolled: new Phaser.Signal(),
      onGateOpen: new Phaser.Signal(),
      onComingSoonClicked: new Phaser.Signal(),
    };

    // Tokens from G.sb
    this._signalTokens = [
      G.sb('onScreenResize').add(this._onResize, this),
      G.sb('mouseWheelEvent').add(this._onMouseWheel, this),
    ];
  }

  /**
   * Assets for the map are initialized here
   */
  _setupAssets() {
    this._mapStage = new Phaser.Group(game, this); // The stage of the map

    // Layer ordering of stuff
    this._layers = {
      bgTiles: new Phaser.Group(game, this._mapStage),
      propsBelowRiver: new Phaser.Group(game, this._mapStage),
      river: new Phaser.Group(game, this._mapStage),
      props: new Phaser.Group(game, this._mapStage),
      clouds: new Phaser.Group(game, this._mapStage),
      scrollInput: G.makeImage(0, 0, null, this._mapStage),
      interactiveLayer: new Phaser.Group(game, this._mapStage),
      levelNode: new Phaser.Group(game, this._mapStage),
      aboveLevelNode: new Phaser.Group(game, this._mapStage),
    };

    // Pool group initializing
    this._tileCheckRequired = false; // For when a new tile is made, the z layer needs to be checked
    const PropClass = WorldMapProps.getWorldMapProps(this._prefabEditorMode);
    this._pools = {
      bgTiles: new WorldMapBackgroundTiles2(),
      river: new WorldMapRiverTile(),
      props: new PropClass(this._layers.propsBelowRiver, this._prefabEditorMode),
      chestShuffle: new WorldMapChestShuffleEntry(),
      levelNode: new WorldMapLevelLayer({
        normalToLevel: this._configOverride.animateSkipTo ? this._configOverride.animateSkipTo.level : 0,
        maxLevel: this._configOverride.maxLevel,
        dummyNode: this._prefabEditorMode,
        aboveLayer: this._layers.aboveLevelNode,
        noGates: G.featureUnlock.useNoGates,
      }),
      socialFriends: new WorldMapSocialLayer({ hideSocial: this._prefabEditorMode }),
      // mailbox: new WorldMapMailboxEntry(),
      sagaMapChest: new WorldMapSagaMapChestEntry(),
      clouds: new WorldMapCloudLayer(),
      openError: new WorldMapInteractionNGPool(),
      villains: new WorldMapVillains(),
    };
    // Each pool group goes into a layer of the same key
    for (const key in this._pools) {
      if (this._layers[key]) {
        this._layers[key].addChild(this._pools[key]);
      }
    }

    // Manually adding groups to layers
    this._layers.interactiveLayer.addChild(this._pools.chestShuffle);
    // this._layers.interactiveLayer.addChild(this._pools.mailbox);
    this._layers.interactiveLayer.addChild(this._pools.sagaMapChest);
    this._layers.aboveLevelNode.addChild(this._pools.socialFriends);
    this._layers.aboveLevelNode.addChild(this._pools.openError);
    this._layers.aboveLevelNode.addChild(this._pools.villains);

    // Offset layers
    this._layers.river.x += this._pools.bgTiles.averageTileMapWidth / 4.5;
    this._layers.clouds.x += this._pools.bgTiles.averageTileMapWidth / 1.5;

    this._updateMapScale();

    // Initialize scroll limits
    this._scrollLimits = {
      min: game.height - this._pools.bgTiles.averageTileMapHeight * this._orientationAdjustments.minScroll,
      max: Infinity,
    };
    this._scrollY = this._scrollLimits.min;

    // Pool group signal setup
    this._pools.levelNode.signals.onNodeClicked.add(this._onLevelNodeClick.bind(this));
    this._pools.levelNode.signals.onGateClicked.add(this._onGateNodeClick.bind(this));
    this._pools.levelNode.signals.onComingSoonClicked.add(this._onComingSoonClick.bind(this));
    if (!this._prefabEditorMode) {
      this._pools.chestShuffle.signals.onChestClicked.add(this._onChestShuffleClick.bind(this));
      this._pools.chestShuffle.signals.onChestError.add(this._onInteractioEntryFail.bind(this));
      // this._pools.mailbox.signals.onMailboxClick.add(this._onMailboxClick.bind(this));
      // this._pools.mailbox.signals.onMailboxError.add(this._onInteractioEntryFail.bind(this));
      this._pools.sagaMapChest.signals.onChestClicked.add(this._onSagaMapChestClick.bind(this));
    } else {
      this._pools.chestShuffle.signals.onChestClicked.add(this._rotateInteraction.bind(this));
      this._pools.chestShuffle.signals.onChestError.add(this._rotateInteraction.bind(this));
      // this._pools.mailbox.signals.onMailboxClick.add(this._rotateInteraction.bind(this));
      // this._pools.mailbox.signals.onMailboxError.add(this._rotateInteraction.bind(this));
      this._pools.sagaMapChest.signals.onChestClicked.add(this._rotateInteraction.bind(this));
    }

    // Determine level limit by where the gate is
    const closestGate = G.saveState.gateManager.getNextGateLevel();
    this._pools.levelNode.levelLimit = closestGate + 1;
    this._currentScrolledLevel = 0;

    this._setupDragInput(); // Initialize drag stuff
    if (!(this._prefabEditorMode || this._configOverride.animateSkipTo)) {
      this._setupAvatars();
      this._setupMapInteractionPointer();
    }

    // Creating the beginning
    const mapSegment = this.addTile(0); // Add SOMETHING to the active segments so the scroll function doesn't break
    this._activeSegments.push(mapSegment);
    this._updateScrollLimits(); // Update the scroll limits
  }

  _calculateOrientationAdjustments() {
    this._orientationAdjustments = {
      upper: this._mapOrientation === ORIENTATION.vertical ? 1 : 2,
      lower: this._mapOrientation === ORIENTATION.vertical ? 0 : 1,
      minScroll: this._mapOrientation === ORIENTATION.vertical ? 2 : 2.3,
      panScroll: this._mapOrientation === ORIENTATION.vertical ? 2 : 3.5,
    };

    if (this._scrollLimits) { // If it exists, update it. Otherwise it will get to it later
      this._scrollLimits.min = game.height - this._pools.bgTiles.averageTileMapHeight * this._orientationAdjustments.minScroll;
    }
  }

  /**
   * setup avatars that get overlayed on the map
   */
  _setupAvatars() {
    // button / avatar when map avatar is off screen
    this._playerAvatarPanButton = new MapQuickPanButton();
    this._playerAvatarPanButton.x = c_halfMapPoint;
    this._layers.aboveLevelNode.addChild(this._playerAvatarPanButton);
    this._playerAvatarPanButton.onClick.add(this._onPlayerAvatarClicked.bind(this));
  }

  _setupMapInteractionPointer() {
    this._mapInteractionPanButton = new MapInteractionPanButton(this.calculateLevelRange.bind(this));
    this._mapInteractionPanButton.x = c_halfMapPoint;
    this._layers.aboveLevelNode.addChild(this._mapInteractionPanButton);
    this._mapInteractionPanButton.onClick.add(this._onTargetInteractionClicked.bind(this));
  }

  /**
   * @returns {{max: number, min: number}}
   */
  calculateLevelRange() {
    const allLevelNodes = _.flatten(this._activeSegments.map((seg) => seg.levelNodes));
    let maxLevel; // Checks which levels are part of this tile
    let minLevel;
    for (const node of allLevelNodes) {
      if (this._pools.levelNode.gate !== node) {
        maxLevel = maxLevel ? Math.max(node.levelIndex, maxLevel) : node.levelIndex;
        minLevel = minLevel ? Math.min(node.levelIndex, minLevel) : node.levelIndex;
      }
    }

    return {
      max: maxLevel,
      min: minLevel,
    };
  }

  /**
   * Changes the scale of the map
   */
  _updateMapScale() {
    if (this._mapOrientation === ORIENTATION.horizontal) {
      if (this._prefabEditorMode) {
        this._mapStage.scale.set(1.1);
      } else {
        this._mapStage.scale.set(1.3);
      }
    } else {
      this._mapStage.scale.set(1);
    }
  }

  /**
   * Externally changes the scale of the map
   * @param {number} s
   */
  externallyUpdateMapScale(s) {
    if (!s) {
      this._updateMapScale();
    } else {
      this._mapStage.scale.set(s);
      this._orientationAdjustments = {
        upper: 1,
        lower: 1,
        minScroll: 2.3,
        panScroll: 3.5,
      };

      if (this._scrollLimits) { // If it exists, update it. Otherwise it will get to it later
        this._scrollLimits.min = game.height - this._pools.bgTiles.averageTileMapHeight * this._orientationAdjustments.minScroll;
      }
    }
  }

  /**
   * Update the various pointers that direct user to places
   */
  _updatePointers() {
    // Player Avatar
    const isPlayerAvatarActive = this._pools.levelNode.isPlayerAvatarActive();
    if (isPlayerAvatarActive) {
      this._playerAvatarPanButton.layoutState = QUICK_PAN_LAYOUT_TYPE.NONE;
    } else if (!isPlayerAvatarActive && this._playerAvatarPanButton.layoutState === QUICK_PAN_LAYOUT_TYPE.NONE) {
      const direction = this._pools.levelNode.getLevelDirectionFromCurrentNodes(G.saveState.getLastPassedLevelNr());
      if (direction < 0) {
        this._playerAvatarPanButton.x = c_halfMapPoint;
        this._playerAvatarPanButton.layoutState = QUICK_PAN_LAYOUT_TYPE.TOP;
      } else if (direction > 0) {
        this._playerAvatarPanButton.layoutState = QUICK_PAN_LAYOUT_TYPE.BOTTOM;
      }
    }

    // Map interaction
    if (this._playerAvatarPanButton.layoutState !== QUICK_PAN_LAYOUT_TYPE.NONE) {
      if (this._playerAvatarPanButton.layoutState === QUICK_PAN_LAYOUT_TYPE.BOTTOM) {
        this._playerAvatarPanButton.tweenXPositionTo(c_halfMapPoint - (this._mapInteractionPanButton.width * 2)); // Player pan avatar.width is 0???
        this._mapInteractionPanButton.tweenXPositionTo(c_halfMapPoint + (this._mapInteractionPanButton.width * 2));
      } else {
        this._mapInteractionPanButton.tweenXPositionTo(c_halfMapPoint);
      }
    } else {
      this._mapInteractionPanButton.tweenXPositionTo(c_halfMapPoint);
    }
  }

  /**
   * Ensures you can only drag up to there. The farthest tile
   */
  _updateScrollLimits() {
    const farthestTile = this._pools.levelNode.levelLimit / 4;
    this._scrollLimits.max = farthestTile * this._pools.bgTiles.averageTileMapHeight;
  }

  /**
   * Initializes the required stuff for drag input
   */
  _setupDragInput() {
    this._prevPos = { x: 0, y: 0 };
    this._vel = { x: 0, y: 0 };
    const dragHandler = G.Input.createCustomInputDragHandler();
    dragHandler.inputDownSignal.add(this._stopDragInertia, this);
    dragHandler.dragUpdateSignal.add(this._startDrag, this);
    dragHandler.dragStopSignal.add(this._stopDrag, this);
    dragHandler.dragCancelSignal.add(this._stopDrag, this);

    G.Input.initializeCustomInput(this._layers.scrollInput);
    this._layers.scrollInput.customInput.addHandler(dragHandler);
    this._resizeHitArea();
  }

  /**
   * When drag starts
   * @param {any} pointer
   */
  _startDrag(pointer) {
    this._activePointer = pointer;
  }

  /**
   * When drag stops
   */
  _stopDrag() {
    this._activePointer = null;
  }

  /**
   * Kills drag inertia
   */
  _stopDragInertia() {
    this._vel.x = 0;
    this._vel.y = 0;
    G.sb(WorldMap2_globalSignals.mapClicked).dispatch();
  }

  /**
   * Update!
   */
  update() {
    if (this._inputEnabled) {
      this.calculateMapMovement();
    }
    super.update();
  }

  /**
   * Calculates how much of the map moved based on velocities
   */
  calculateMapMovement() {
    const { _activePointer } = this;
    if (_activePointer) {
      if (this._prevPos.y != null) {
        this._vel.y = (_activePointer.y - this._prevPos.y);
      }
      this._prevPos.y = _activePointer.y;

      if (this._prevPos.x != null) {
        this._vel.x = (_activePointer.x - this._prevPos.x);
      }
      this._prevPos.x = _activePointer.x;
    } else {
      this._prevPos.y = null;
      this._prevPos.x = null;
    }
    this._vel.x -= this._vel.x * 0.05 * G.deltaTime;
    this._vel.y -= this._vel.y * 0.05 * G.deltaTime;
    if (Math.abs(this._vel.y) > velocityEpsilon) {
      this.onScroll(this._vel.y);
    }
  }

  /**
   * The main scrolling function.
   * updates scrollY and all tiles. Then checks if the tile should be seen or not based on upper/lower index
   * @param {number} deltaY
   */
  onScroll(deltaY) {
    // Ensure that you never scroll past the limits
    if (this._scrollY + deltaY < this._scrollLimits.min) { deltaY = this._scrollLimits.min - this._scrollY; }
    if (this._scrollY + deltaY > this._scrollLimits.max) { deltaY = this._scrollLimits.max - this._scrollY; }
    this._scrollY += deltaY;

    // Find the upper and lower index limits
    const upperIndex = -Math.floor(this._scrollY / this._pools.bgTiles.averageTileMapHeight) - this._orientationAdjustments.upper;
    const lowerIndex = upperIndex + Math.ceil(game.height / this._pools.bgTiles.averageTileMapHeight) + this._orientationAdjustments.lower;

    // Check active segments
    this._existingTiles.length = 0;

    // Update active segments
    for (const poolKey in this._pools) { // eslint-disable-line guard-for-in
      if (this._pools[poolKey].moveTiles) {
        this._pools[poolKey].moveTiles(deltaY);
      }
    }

    // Kills any tiles that are not within the segments
    for (let i = this._activeSegments.length - 1; i >= 0; i--) {
      const activeIndex = this._activeSegments[i].index;
      if (activeIndex < upperIndex || activeIndex > lowerIndex) {
        this.killTile(activeIndex);
      } else {
        this._existingTiles.push(activeIndex);
      }
    }
    _.remove(this._activeSegments, this._removeNonExistingTiles.bind(this)); // Filter out any segments not there anymore from actives
    if (this._activeSegments.length === 0) { // If theres no active segments because they're not all there, add in a lower limit tile
      const tilePack = this.addTile(lowerIndex);
      this._activeSegments.push(tilePack);
      this._tileCheckRequired = true;
    }

    // insert missing segments on the top
    let segmentIndex;
    while (this._activeSegments[0].index > upperIndex) {
      segmentIndex = this._activeSegments[0].index - 1;
      const tilePack = this.addTile(segmentIndex);
      this._activeSegments.unshift(tilePack);
    }

    // insert missing segments on the bottom
    while (this._activeSegments[this._activeSegments.length - 1].index < lowerIndex) {
      segmentIndex = this._activeSegments[this._activeSegments.length - 1].index + 1;
      const tilePack = this.addTile(segmentIndex);
      this._activeSegments.push(tilePack);
      this._tileCheckRequired = true;
    }

    if (this._tileCheckRequired) {
      this._checkTiles();
      this._tileCheckRequired = false;
    }
    if (!(this._prefabEditorMode || this._configOverride.animateSkipTo)) {
      this._updatePointers();
    }
    this.signals.onMapScrolled.dispatch(this._scrollY, deltaY);
  }

  /**
   * Checks the z-index for the props and reassigns them appropriately
   */
  _checkTiles() {
    const copyArr = this._activeSegments.concat([]).sort((a, b) => b.index - a.index);
    for (const seg of copyArr) {
      this._pools.props.reAddPropToLayer(seg.props);
    }
  }

  /**
   * A function used by onScroll to stop creating functions
   * @param {{index:number}} seg
   * @returns {boolean}
   */
  _removeNonExistingTiles(seg) {
    return this._existingTiles.indexOf(seg.index) === -1;
  }

  /**
   * Kills the tile on that index
   * @param {number} index
   */
  killTile(index) {
    for (const poolKey in this._pools) { /* eslint-disable-line guard-for-in */ // Kills all assets on that tile from their pools
      this._pools[poolKey].killTile(index);
    }
  }

  /**
   * Adds a tile based on index number
   * @param {number} index
   * @returns {{ index: number, mapTile: BackgroundTile, river: (WorldMapRiver | null), levelNodes: Array<LevelNode>,
      props: WorldMapDynamicAssetProp, mapInteraction: any, cloud: (WorldMapClouds | null) }}
   */
  addTile(index) {
    const tile = this._createBgTile(index);

    const riverTile = this._createRiverTile(index, tile);
    const isRiver = Boolean(riverTile);

    const levelNodes = this._createLevelNodesTile(index, tile, isRiver);

    this._createSocialTags(index, tile, levelNodes);

    const villains = this._createVillains(index, tile, levelNodes);

    const propArr = this._createPropTile(index, tile, isRiver);

    const cloud = this._createCloudTile(index, tile, levelNodes);

    const mapInteraction = this._determineMapInteraction(index, tile, levelNodes); // Determine the map interaction

    return { // Returns as a pack of stuff
      index,
      mapTile: tile,
      river: riverTile,
      levelNodes,
      villains,
      props: propArr,
      mapInteraction,
      cloud,
    };
  }

  /**
   * Creates a background tile based on index
   * @param {number} index
   * @returns {BackgroundTile}
   */
  _createBgTile(index) {
    const tilePosition = (index * this._pools.bgTiles.averageTileMapHeight) + this._scrollY; // Determine tile position
    return this._pools.bgTiles.addTile(index, tilePosition); // Background tile
  }

  /**
   * Creates a river asset if there is one. Possibly may return null
   * @param {number} index
   * @param {BackgroundTile} tile
   * @returns {(WorldMapRiver | null)}
   */
  _createRiverTile(index, tile) {
    const spriteTexture = this._pools.bgTiles.getTextureStringOfIndex(index); // Get the string of the tile
    const riverSeedCheck = WorldMap2_Util.getRNGSeedMap(index, spriteTexture) % c_magicRiverNumber === 0;
    if (this._pools.bgTiles.isIndexARiver(index) && riverSeedCheck) { // Generate river if it is a river
      const river = this._pools.river.addTile(index, tile.y + tile.height * c_magicTileToRiverScaleNumber.y, WorldMap2_Util.getRNGSeedMap(index, spriteTexture));
      river.x = tile.width * c_magicTileToRiverScaleNumber.x;
      return river;
    }
    return null;
  }

  /**
   * Creates an array of level nodes
   * @param {number} index
   * @param {BackgroundTile} tile
   * @param {boolean} isRiver
   * @returns {Array<LevelNode>}
   */
  _createLevelNodesTile(index, tile, isRiver) {
    const levelNodes = this._pools.levelNode.addTile(index, tile.y, isRiver); // Create level nodes for this tile
    // if (!(this._prefabEditorMode || this._configOverride.animateSkipTo)) {
    this._determinePlayerAvatarLocation(levelNodes);
    // }
    return levelNodes;
  }

  /**
   * Creates social avatars of friends on specific nodes
   * @param {number} index
   * @param {BackgroundTile} tile
   * @param {Array<LevelNode>} levelNodes
   * @returns {Array<MapLabel | null>}
   */
  _createSocialTags(index, tile, levelNodes) {
    if (levelNodes.indexOf(this._pools.levelNode.gate) > -1) { return null; } // No friends on gates!
    const socialTags = this._pools.socialFriends.addTile(index, levelNodes);
    return socialTags;
  }

  /**
   * Creates villains on specific nodes
   * @param {number} index
   * @param {BackgroundTile} tile
   * @param {Array<LevelNode>} levelNodes
   */
  _createVillains(index, tile, levelNodes) {
    if (this._prefabEditorMode) { return null; }
    this._pools.villains.bindLevelNodesPool(this._pools.levelNode);
    const villain = this._pools.villains.addTile(index, tile, levelNodes);
    return villain;
  }

  /**
   * Creates the world map dynamic prop
   * @param {number} index
   * @param {BackgroundTile} tile
   * @param {boolean} isRiver
   * @returns {WorldMapDynamicAssetProp}
   */
  _createPropTile(index, tile, isRiver) {
    const textureIndex = this._pools.bgTiles.getTextureIndexOfIndex(index); // Get the index of the bg tile
    // Create the prefab prop
    return this._pools.props.addTile(
      index,
      tile.y + tile.height / 2,
      textureIndex,
      this._pools.bgTiles.averageTileMapWidth / 2,
      WorldMap2_Util.getRNGSeedMap(Math.abs(index), `${index}_${isRiver}`),
      isRiver,
      this._mapOrientation,
    );
  }

  /**
   * Creates the cloud tiles or not
   * @param {number} index
   * @param {BackgroundTile} tile
   * @param {Array<LevelNode>} levelNodes
   * @returns {(WorldMapClouds | null)}
   */
  _createCloudTile(index, tile, levelNodes) {
    const gateIndex = levelNodes.indexOf(this._pools.levelNode.gate); // Find out if theres a gate
    if (gateIndex > -1) { // If there is, there must be a cloud
      return this._pools.clouds.addTile(index, tile.y);
    }
    return null;
  }

  /**
   * Determines if the given level nodes come with the player avatar
   * @param {Array<BasicNodes>} levelNodes
   */
  _determinePlayerAvatarLocation(levelNodes) {
    let nodeAvatarLevel = this._configOverride.animateSkipTo ? this._configOverride.animateSkipTo.level - 1 : G.saveState.getLastPassedLevelNr();
    if (nodeAvatarLevel >= this._pools.levelNode.maxLevel - 1) {
      nodeAvatarLevel -= 1;
    }
    for (const node of levelNodes) {
      if (node.levelIndex === nodeAvatarLevel) {
        this._pools.levelNode.addPlayerAvatarAssets(node);
        break;
      }
    }
  }

  /**
   * Determines map interaction based on the given data.
   * Check function for more details
   * @param {number} index
   * @param {Phaser.Image} tile
   * @param {Array<LevelNode>} levelNodes
   * @returns {Object|null}
   */
  _determineMapInteraction(index, tile, levelNodes) {
    let maxLevel = 0; // Find out the highest level on this node
    let minLevel = this._pools.levelNode.levelLimit; // Find out the lowest level on this node
    for (const lvlNode of levelNodes) {
      maxLevel = lvlNode.nodeType !== levelNodeType.GATE ? Math.max(lvlNode.levelIndex, maxLevel) : 0;
      minLevel = lvlNode.nodeType !== levelNodeType.GATE ? Math.min(lvlNode.levelIndex, maxLevel) : 0;
    }
    if (isNaN(maxLevel) || maxLevel <= 0 || maxLevel > this._pools.levelNode.levelLimit) { return null; }
    if (maxLevel >= this._pools.levelNode.levelLimit) { return null; } // If the max level is within the level limit
    if (minLevel < WorldMapTwoMapInteractionLimit) { return null; } // If min level is within the map interaction limit

    // Determine which fun to use based on what is in G.OMTsettings
    let interactionsArr = G.OMTsettings.elements.worldMap.rewardPerTile;
    if (this._prefabEditorMode) { // If its the prefab editor,
      interactionsArr = _.uniq(_.compact(interactionsArr)); // Remove nulls, make it unique
    }
    const whichFunToday = WorldMap2_Util.getEntryInArrayLoop(interactionsArr, Math.abs(index));
    if (!whichFunToday || whichFunToday === '') { return null; } // If no fun, return nothing

    const textureIndex = this._pools.bgTiles.getTextureIndexOfIndex(index); // Used for determining which position on the texture it should be on
    const chestUnlockLevel = maxLevel - 2; // Unlock levels for chest
    if (maxLevel >= this._pools.levelNode.levelLimit || chestUnlockLevel >= this._pools.levelNode.levelLimit) { return null; }
    switch (whichFunToday.toLowerCase()) {
      // case interactionType.mailbox: // Mailbox
      //   return this._createMailboxInteraction(index, maxLevel, tile.y, textureIndex, this._prefabEditorMode);
      case interactionType.chestMap: // Saga map chest
        return this._createSagaMapChestInteraction(index, chestUnlockLevel, maxLevel, tile.y, textureIndex, this._prefabEditorMode);
      case interactionType.chestShuffle: // Chest shuffle game
        if (this._prefabEditorMode || G.saveState.chestShuffleDataManager.doesChestAtLevelExist(chestUnlockLevel)) {
          return this._createChestShuffleInteraction(index, chestUnlockLevel, maxLevel, tile.y, textureIndex, this._prefabEditorMode);
        }
        return null;
      default: return null;
    }
  }

  /**
   * Creates and adds a mailbox to the given tile index
   * @param {number} index
   * @param {number} maxLevel
   * @param {number} startY
   * @param {number} tileIndex
   * @param {Boolean} dummy
   */
  _createMailboxInteraction(index, maxLevel, startY, tileIndex, dummy) {
    return this._pools.mailbox.addTile({
      index,
      unlockLevel: maxLevel,
      seed: WorldMap2_Util.getRNGSeedMap(index, `mailbox${maxLevel}`),
      startY,
      tileIndex,
      dummy,
    });
  }

  /**
   * Creates and adds a saga map chest to the given tile index
   * @param {number} index
   * @param {number} unlockLevel
   * @param {number} maxLevel
   * @param {number} startY
   * @param {number} tileIndex
   * @param {Boolean} dummy
   */
  _createSagaMapChestInteraction(index, unlockLevel, maxLevel, startY, tileIndex, dummy) {
    return this._pools.sagaMapChest.addTile({ // Instance check is done inside
      index,
      unlockLevel,
      seed: WorldMap2_Util.getRNGSeedMap(index, `chestmMap${maxLevel}`),
      startY,
      tileIndex,
      dummy,
    });
  }

  /**
   * Creates and adds a chest shuffle interaction to the given tile index
   * @param {number} index
   * @param {number} unlockLevel
   * @param {number} maxLevel
   * @param {number} startY
   * @param {number} tileIndex
   * @param {Boolean} dummy
   */
  _createChestShuffleInteraction(index, unlockLevel, maxLevel, startY, tileIndex, dummy) {
    return this._pools.chestShuffle.addTile({
      index,
      unlockLevel,
      seed: WorldMap2_Util.getRNGSeedMap(`chest${maxLevel}`, index),
      startY,
      tileIndex,
      dummy,
    });
  }

  /**
   * When a GateNode is clicked
   * Returns true if the gate could be unlocked.
   * Returns false if the gate could not be unlocked
   * @param {GateNode} levelNode
   * @returns {boolean}
   */
  _onGateNodeClick(levelNode) {
    if (!this._inputEnabled) { return false; }
    if (this._prefabEditorMode) { return false; }
    const gateData = G.saveState.gateManager.getGateObject(levelNode.levelIndex);
    const canGateBeUnlocked = G.saveState.gateManager.canGateBeOpened(gateData);
    if (canGateBeUnlocked.result) {
      this._unlockGate(levelNode, gateData.gateLevel, canGateBeUnlocked.reason, canGateBeUnlocked.immediate);
      return true;
    }
    G.sb('pushWindow').dispatch(['gateNew', {
      gateObject: gateData,
      onOpenCallback: (gateLevel, gateReason) => {
        this._unlockGate(levelNode, gateData.gateLevel, gateReason, false);
      },
    }]);
    this._scheduleGateReminder(gateData);
    return false;
  }

  /**
   * When the coming soon icon is clicked
   * @returns {null}
   */
  _onComingSoonClick() {
    if (!this._inputEnabled) { return; }
    if (this._prefabEditorMode) { return; }
    this.signals.onComingSoonClicked.dispatch();
  }

  /**
   * When a level node is clicked
   * Returns true if the level could be opened.
   * Returns false if the level could not be opened (due to no lives, or something)
   * @param {LevelNode} levelNode
   * @returns {boolean}
   */
  _onLevelNodeClick(levelNode) {
    if (!this._inputEnabled) { return false; }
    if (this._prefabEditorMode) { return false; }
    G.lvlNr = levelNode.levelIndex;
    G.lvlData = G.Helpers.levelDataMgr.getLevelByIndex(levelNode.levelIndex);
    if (G.saveState.getLives() === 0 && G.saveState.getUnlimitedLivesSec() === 0) {
      G.sb('pushWindow').dispatch(['notEnoughLives', {
        openLevelPopUp: true,
        lvlIndex: levelNode.levelIndex,
        levelPopUpLayerName: G.WindowMgr.LayerNames.AboveHighScorePanel,
      }], false, G.WindowMgr.LayerNames.AboveHighScorePanel);
      return false;
    }
    G.sb('pushWindow').dispatch(['level', levelNode.levelIndex], false, G.WindowMgr.LayerNames.Base);
    return true;
  }

  _onOrientationChange() {
    // eslint-disable-next-line guard-for-in
    for (const tile of this._activeSegments) {
      const { props } = tile;
      if (props) {
        const textureIndex = this._pools.bgTiles.getTextureIndexOfIndex(tile.index); // Get the index of the bg tile
        this._pools.props.positionPropOnTile(props, textureIndex, tile.mapTile.y + tile.mapTile.height / 2, this._pools.bgTiles.averageTileMapWidth / 2, this._mapOrientation);
      }
    }
  }

  /**
   * Schedules a game triggered message for the gate
   * @param {{gateLevel:number, starReq:number, coinReq?:number, timeReq?:number, inviteReq?:number}} gateData
   */
  async _scheduleGateReminder(gateData) {
    // Schedule bot message to remind when the gate is opening
    const scheduledGateMessages = await OMT.notifications.findGameTriggeredMessages('GateOpen');
    if (scheduledGateMessages.length === 0) {
      const seconds = (gateData.timeReq * MILLISECONDS_IN_MIN) / 1000;
      await OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, 'GateOpen', seconds, null);
    }
  }

  /**
   * Removes the scheduled game trigger message for the gate
   */
  async _removeGateReminder() {
    const scheduledGateMessages = await OMT.notifications.findGameTriggeredMessages('GateOpen');
    if (scheduledGateMessages.length > 0) {
      await OMT.notifications.removeGameTriggeredMessage('GateOpen');
    }
  }

  /**
   * Unlocks the gate and does clean up
   * @param {LevelNode} levelNode
   * @param {number} gateLevel
   * @param {string} gateReason
   * @param {boolean} immediateUnlock was the gate immediately unlocked (bypassing cooldown)?
   */
  _unlockGate(levelNode, gateLevel, gateReason, immediateUnlock) {
    const levelNodeIndex = levelNode.tileIndex;
    this._inputEnabled = false;

    this._generateDataWhenGateOpen(gateLevel, gateReason, immediateUnlock); // Generates data for when a gate opens
    this.signals.onGateOpen.dispatch(gateLevel);
    this._pools.levelNode.levelLimit += G.saveState.gateManager.levelGap; // Increase level limit
    this._updateScrollLimits(); // Increase scroll limits
    const futureSegs = this._activeSegments.filter((active) => active.index <= levelNodeIndex); // Find all future segments
    for (const active of futureSegs) { /* eslint-disable-line guard-for-in */ // Update them
      this._pools.levelNode.killTile(active.index);
      active.levelNodes = this._createLevelNodesTile(active.index, active.mapTile, Boolean(active.river));

      if (active.villains) {
        this._pools.villains.killTile(active.index);
      }
      active.villains = this._createVillains(active.index, active.mapTile, active.levelNodes);

      if (active.mapInteraction) {
        active.mapInteraction.killTile(active.index);
      }
      active.mapInteraction = this._determineMapInteraction(active.index, active.mapTile, active.levelNodes);

      if (active.social) {
        active.social.killTile(active.index);
      }
      active.social = this._createSocialTags(active.index, active.mapTile, active.levelNodes);
    }
    this._pools.levelNode._playGateUnlockAnim(levelNode); // Plays the star animation for when a gate opens
    this._pools.clouds.fadeAway(levelNodeIndex); // Clouds fade away
    game.time.events.add(1000, () => {
      try {
        this._inputEnabled = true;
      } catch (e) {
        console.warn('WorldMap has been destroyed');
      }
    });

    this._removeGateReminder();
  }

  /**
   * Generates data for save state when a gate is opened.
   * Right now it only generates data for the saga map chest and shuffle chest
   * @param {number} gateLevel
   * @param {GATE_OPEN_REASON} gateReason
   * @param {boolean} immediateUnlock was the gate immediately unlocked (bypassing cooldown)?
   */
  _generateDataWhenGateOpen(gateLevel, gateReason, immediateUnlock) {
    // const gateInviteData = G.saveState.gateManager.getInviteDataOf(gateLevel);
    G.saveState.gateManager.openGate(gateLevel);

    // Gate Open
    // const gateObject = G.saveState.gateManager.getGateObject(gateLevel, true);
    // Actual gate cooldown (allows negative numbers, used for gateTimeOpen)
    // const gateCooldown = G.saveState.getUserCooldownRemaining(`${GATE_COOLDOWN_KEY}${gateObject.gateLevel}`, '', true, true);
    // Gate cooldown with minimum of zero (used for timeLeft)
    // const gateCooldownNonNegative = Math.max(0, gateCooldown);
    // const timeReqInMS = gateObject.timeReq * MILLISECONDS_IN_MIN;
    // DDNA.tracking.gateOpenEvent({
    //   gateId: gateObject.gateLevel,
    //   gatePrice: gateObject.coinReq,
    //   gateCooldown,
    //   gateOpenMethod: gateReason,
    //   gateTimeOpen: immediateUnlock ? 0 : Math.floor((timeReqInMS - gateCooldown) / MILLISECONDS_IN_MIN),
    //   invitesSent: gateInviteData.out,
    //   invitesClaimed: gateInviteData.claimed,
    //   inviteDiff: gateInviteData.out - gateObject.inviteReq,
    //   starDiff: G.saveState.getAllStars() - gateObject.starReq,
    //   coinDiff: gateReason === GATE_OPEN_REASON.coins ? G.saveState.getCoins() : G.saveState.getCoins() - gateObject.coinReq,
    //   timeLeft: immediateUnlock ? 0 : Math.ceil(gateCooldownNonNegative / MILLISECONDS_IN_MIN),
    // });

    G.saveState.gateManager.save();
    G.saveState.mapChestDataManager.save();
    G.saveState.chestShuffleDataManager.save();
    G.saveState.mailboxManager.save();
  }

  /**
   *
   * @param {Phaser.Image} node
   * @param {string} str
   */
  _onInteractioEntryFail(node, str) {
    this._pools.openError.addTile(node, str);
  }

  /**
   * Rotate map interactions when clicked on in prefab editor mode only
   * @param {(WorldMapSagaMapChestEntry|WorldMapChestShuffleEntry|WorldMapMailboxEntry)} node
   */
  _rotateInteraction(node) {
    if (!this._prefabEditorMode) { return null; }

    const curTile = this._activeSegments.find((active) => active.index === node.index); // Find all future segments
    if (curTile) {
      if (curTile.mapInteraction) { // Removes current one
        curTile.mapInteraction.killTile(curTile.index);
      }

      const maxLevel = 0; // Find out the highest level on this index

      // Determine which fun to use based on what is in G.OMTsettings
      const allInteractions = Object.values(interactionType); // All interactions are mapped to an array
      const whichFunToday = WorldMap2_Util.getEntryInArrayLoop(allInteractions, allInteractions.indexOf(node.interactionType) + 1);

      const textureIndex = this._pools.bgTiles.getTextureIndexOfIndex(curTile.index); // Used for determining which position on the texture it should be on
      const chestUnlockLevel = maxLevel - 2; // Unlock levels for chest
      if (maxLevel >= this._pools.levelNode.levelLimit || chestUnlockLevel >= this._pools.levelNode.levelLimit) { return null; }
      switch (whichFunToday.toLowerCase()) {
        // case interactionType.mailbox: // Mailbox
        //   curTile.mapInteraction = this._createMailboxInteraction(curTile.index, maxLevel, curTile.mapTile.y, textureIndex, this._prefabEditorMode);
        //   break;
        case interactionType.chestMap: // Saga map chest
          curTile.mapInteraction = this._createSagaMapChestInteraction(curTile.index, chestUnlockLevel, maxLevel, curTile.mapTile.y, textureIndex, this._prefabEditorMode);
          break;
        case interactionType.chestShuffle: // Chest shuffle game
          if (this._prefabEditorMode || G.saveState.chestShuffleDataManager.doesChestAtLevelExist(chestUnlockLevel)) {
            curTile.mapInteraction = this._createChestShuffleInteraction(curTile.index, chestUnlockLevel, maxLevel, curTile.mapTile.y, textureIndex, this._prefabEditorMode);
          }
          break;
        default: break;
      }
    }
    return null;
  }

  /**
   * When a saga map chest is clicked
   * @param {WorldMapSagaMapChest} chest
   */
  _onSagaMapChestClick(chest) {
    if (!this._inputEnabled) { return; }
    this._pools.sagaMapChest.onOpened(chest);
    G.sb(WorldMap2_globalSignals.recheckInteractions).dispatch();
  }

  /**
   * When a chest shuffle is clicked
   * @param {WorldMapChests} chest
   */
  _onChestShuffleClick(chest) {
    if (!this._inputEnabled) { return; }
    this._pools.chestShuffle.playOutro(chest);
    G.sb('pushWindow').dispatch(['chestShuffle', { charKey: chest.charKey }]);
    G.sb(WorldMap2_globalSignals.recheckInteractions).dispatch();
  }

  /**
   * When a mailbox is clicked
   * @param {WorldMapMailbox} mailbox
   */
  _onMailboxClick(mailbox) {
    if (!this._inputEnabled) { return; }
    G.sb('pushWindow').dispatch(['mailbox', () => {
      mailbox.setToOpened();
      G.sb(WorldMap2_globalSignals.recheckInteractions).dispatch();
    }]);
  }

  /**
   * Opens gate by code
   */
  externallyOpenGate() {
    const gateNode = this._pools.levelNode.gate;
    if (gateNode) {
      return this._onGateNodeClick(gateNode);
    }
    return false;
  }

  /**
   * Animate the player avatar to move to the next node
   */
  async animatePlayerAvatarIncrease() {
    if (this._pools.levelNode.isPlayerAvatarActive()) {
      await this._pools.levelNode.animatePlayerAvatarIncrease();
    }
  }

  /**
   * Resize hit area
   */
  _resizeHitArea() {
    // hit area is also within the phaser game window!!
    const { gameScale } = GameScaleController.getInstance();

    const hX = -game.width / gameScale / 2;
    const hWidth = (game.width / gameScale) * 2;
    const hHeight = game.height / gameScale - 200;
    if (this._layers.scrollInput.hitArea) { // Avoid creating one again
      this._layers.scrollInput.hitArea.x = hX;
      this._layers.scrollInput.hitArea.y = 0;
      this._layers.scrollInput.hitArea.width = hWidth;
      this._layers.scrollInput.hitArea.height = hHeight;
    } else {
      this._layers.scrollInput.hitArea = new Phaser.Rectangle(hX, 0, hWidth, hHeight);
    }
  }

  /**
   * When drag starts to happen
   * @param {Object} pointer
   */
  startDragging(pointer) {
    if (this._inputEnabled) {
      this.activePointer = pointer;
    }
  }

  /**
   * When drag stops
   */
  stopDragging() {
    this.activePointer = null;
  }

  /**
   * When a click happens and all inertia should stop
   */
  stopDragInertia() {
    this._vel.x = 0;
    this._vel.y = 0;
  }

  /**
   * A function for external scrolling
   * @param {number} deltaY
   */
  externalScroll(deltaY) {
    if (!this._inputEnabled) { return; }
    this.onScroll(deltaY);
  }

  /**
   * A function that is called when the player avatar is clicked
   */
  async _onPlayerAvatarClicked() {
    this._inputEnabled = false;
    this._mapInteractionPanButton.layoutState = MAP_INTERACTION_LAYOUT_TYPE.NONE;
    await this.panToLevelNode(Math.max(G.saveState.getLastPassedLevelNr(), 0));
    this._inputEnabled = true;
  }

  async _onTargetInteractionClicked() {
    const curInteraction = this._mapInteractionPanButton.currentInteraction;
    if (curInteraction) {
      this._mapInteractionPanButton.layoutState = MAP_INTERACTION_LAYOUT_TYPE.NONE;
      this._inputEnabled = false;
      await this.panToLevelNode(curInteraction.level);
      this._inputEnabled = true;
    }
  }

  /**
   * Pans to a level node, immediately or not
   * @param {number} desiredLevel
   * @param {boolean} instant
   * @return {Promise}
   */
  panToLevelNode(desiredLevel, instant) {
    const nodeData = WorldMap2_Util.findTileFromLevel(Math.min(Math.max(desiredLevel - 1, 0), this._pools.levelNode.levelLimit));
    const targetY = this._pools.bgTiles.averageTileMapHeight * nodeData.tile;
    const node = WorldMap2_Util.getEntryInArrayLoop(G.OMTsettings.elements.worldMap.levelNodePositions, nodeData.tile)[nodeData.nodeIndex];
    const targetPositioningY = targetY + node.y + game.height / this._orientationAdjustments.panScroll;
    if (instant) {
      return this.instantPanToPosition(0, targetPositioningY);
    } else { // eslint-disable-line no-else-return
      return this.tweenPanToPosition(0, targetPositioningY);
    }
  }

  /**
   * Pans to a position. X panning not working yet
   * @param {number} targetX
   * @param {number} targetY
   * @returns {Promise}
   */
  tweenPanToPosition(targetX, targetY) {
    return new Promise((resolve) => {
      targetX = parseInt(targetX);
      targetY = parseInt(targetY);
      if (!isNaN(targetX) && this._allowXMovement) {
        // something
      }

      if (!isNaN(targetY)) { // If its a number
        this._stopDragInertia(); // Stop the drag
        this._inputEnabled = false; // Stop inputs
        const obj = { cur: this._scrollY, target: targetY, lastValue: this._scrollY }; // Tween obj
        const ratioToTarget = this._ignoreRatioTween ? 1 : 1 - Math.min((obj.cur / obj.target) * 2, 1);
        const tw = game.add.tween(obj)
          .to({ cur: obj.target }, (this._tweenTime * ratioToTarget), Phaser.Easing.Sinusoidal.InOut, true);
        tw.onUpdateCallback(() => { // On each update
          const diff = obj.cur - obj.lastValue;
          obj.lastValue = obj.cur;
          this.onScroll(diff);
          this._inputEnabled = true; // Bring back input
        });
        tw.onComplete.add(() => { // When tween complete make sure its actually scrolled
          this.onScroll(obj.target - obj.lastValue);
          resolve();
        });
      }
    });
  }

  /**
   * Instantly jumps to a certain position.
   * X jumping doesn't work yet
   * @param {number} targetX
   * @param {number} targetY
   * @returns {Promise}
   */
  instantPanToPosition(targetX, targetY) {
    return new Promise((resolve) => {
      targetX = parseInt(targetX);
      targetY = parseInt(targetY);
      if (!isNaN(targetX) && this._allowXMovement) {
        // something
        resolve();
      }

      if (!isNaN(targetY)) {
        this._stopDragInertia(); // Stop inertia
        this.onScroll(targetY - this._scrollY); // Jump
        resolve();
      }
    });
  }

  /**
   * resize method
   */
  _onResize() {
    if (this._playerAvatarPanButton) {
      this._playerAvatarPanButton.resize();
    }
    this.onScroll(0); // force scroll update to fix map cropping
    this._resizeHitArea();
  }

  /**
   * on mouse wheel moved
   * @param {Object} event
   * @param {number} event.deltaY
   */
  _onMouseWheel(event) {
    if (!this._inputEnabled) { return; }
    if (this._configOverride.animateSkipTo) { return; }
    const pointer = game.world.toLocal(game.input);
    const noGoods = this._scrollIgnore.filter((deadSpot) => Phaser.Rectangle.containsPoint(deadSpot(), pointer));
    if (noGoods.length > 0) { return; }
    this.onScroll(event.deltaY * -0.7);
  }

  /**
   * Adds dead rectangle areas for the world map scrolling to not scroll at (from mouse wheel)
   *
   * Passed in function must return rectangle.
   * @param {Function} func
   */
  setScrollDeadSpot(func) {
    this._scrollIgnore.push(func);
  }

  /**
   * Forcibly adds player avatar in
   * @param {number} level
   */
  forceAddPlayerAvatar(level) {
    const node = this._pools.levelNode.getNodeAtLevel(level);
    this._pools.levelNode.addPlayerAvatarAssets(node);
  }

  /**
   * Sequentially unlocks up to a level
   * @param {number} level
   */
  async sequentiallyUnlockAndScrollToLevel(level) {
    const startingLevel = 0;
    const targetLevel = level;
    const stars = 2;
    let totalStars = 0;
    let coinCount = G.saveState.getCoins();
    // let curNode = this._pools.levelNode.getNodeAtLevel(startingLevel);
    // this._pools.levelNode.addPlayerAvatarAssets(curNode);
    // const oriTweenTime = this._tweenTime;
    for (let curLevel = startingLevel; curLevel < targetLevel; curLevel++) {
      totalStars += stars;
      coinCount += SaveStateUtils.computeReward(curLevel, 0, stars, 0, null);
    }
    await this.panToLevelNode(targetLevel);
    const curNode = this._pools.levelNode.getNodeAtLevel(targetLevel - 1);
    await this._unlockLevelNode(curNode, coinCount, totalStars);
    /* eslint-disable no-await-in-loop */
    // for (let curLevel = startingLevel; curLevel < targetLevel; curLevel++) {
    //   this._tweenTime = oriTweenTime * ((1 ** 3) / Math.min(25, Math.max(curLevel - 3, 1)));
    //   this._ignoreRatioTween = curLevel > 5;
    //   if (curLevel !== startingLevel) {
    //     curNode = await this._animatePanToNode(curNode, curLevel);
    //   }
    //   if (!curNode) { continue; }
    //   coinCount = await this._unlockLevelNode(curNode, coinCount, curLevel === targetLevel - 1);
    // }
    /* eslint-enable no-await-in-loop */
    await this._animatePanToNode(curNode, targetLevel);
    return coinCount;
  }

  /**
   * Pans to a node, the avatar moves too. Returns the last node
   * @param {LevelNode} lastNode
   * @param {number} level
   * @returns {(LevelNode|null)}
   */
  async _animatePanToNode(lastNode, level) {
    await this.panToLevelNode(level);
    const targetNode = this._pools.levelNode.getNodeAtLevel(level);
    if (!targetNode) return null;
    this._pools.levelNode.addPlayerAvatarAssets(lastNode);
    await this._pools.levelNode.immediatelyPanAvatarToPosition(lastNode, targetNode);
    return targetNode;
  }

  /**
   * Plays an unlock level node animation
   * @param {LevelNode} levelNode
   */
  async _unlockLevelNode(levelNode, coinCount, totalStars) {
    levelNode.bounce();
    // const stars = 2;
    // const coins = SaveStateUtils.computeReward(levelNode.levelIndex, 0, stars, 0, null);
    // const targetCoins = coinCount + coins;
    this._configOverride.animateSkipTo.fxFunc({
      lastLevelData: { starImprovement: totalStars, reward: coinCount },
      coinCounter: { coins: G.saveState.getCoins() },
      levelPosition: this.getWorldPositionOfLevelNodeAt(levelNode.levelIndex),
      finalCoins: coinCount,
    });
    return coinCount;
  }

  /**
   * destruction method
   */
  destroy() {
    // detach external bindings
    for (const signal of this._signalTokens) if (signal) signal.detach();
    this._signalTokens.length = 0;
    // dispose internal signals
    for (const signal of Object.values(this.signals)) signal.dispose();

    this._scrollIgnore.length = 0;

    super.destroy();
  }

  get mapInteractionPanButton() { return this._mapInteractionPanButton; }

  get socialFriendsLayer() { return this._pools.socialFriends; }
}

// Used externally for the PrefabEditor
if (typeof G === 'undefined') G = {};
if (typeof G.worldMap2 === 'undefined') G.worldMap2 = {};
G.worldMap2.map = WorldMap2;
