/* eslint-disable no-use-before-define */
export default class WorldMapCloudLayer extends G.PoolGroup {
  /**
   * The Pool group for the clouds
   */
  constructor() {
    super(WorldMapClouds);

    this._actives = [];
  }

  /**
   * Moves tiles by deltaY
   * @param {number} deltaY
   */
  moveTiles(deltaY) {
    // update active segments
    for (let i = this._actives.length - 1; i >= 0; i--) {
      this._actives[i].y += deltaY;
    }
  }

  /**
   * Kills the tile at index
   * @param {number} index
   */
  killTile(index) {
    const tile = this._actives.find((seg) => seg.index === index);
    if (tile) {
      tile.kill();
      this._actives.splice(this._actives.indexOf(tile), 1);
    }
  }

  /**
   * Adds the tile at index
   * @param {number} index
   * @param {number} posOfNode
   * @returns {BackgroundTile}
   */
  addTile(index, posOfNode) {
    const clouds = this.getFreeElement();
    clouds.init(index, posOfNode);
    this._actives.push(clouds);
    this.addChild(clouds);
    return clouds;
  }

  /**
   * Fades away the clouds at index. onComplete is fired when the clouds fully disappear
   * @param {number} index
   * @param {Function} onComplete
   */
  fadeAway(index, onComplete) {
    for (const cloud of this._actives) { // There should only be one anyways
      if (cloud.index === index) {
        cloud.fadeAway(() => {
          if (onComplete) {
            onComplete();
          }
        });
      }
    }
  }
}

class WorldMapClouds extends Phaser.Image {
  /**
   * The cloud images
   */
  constructor() {
    super(game, null);

    this._cloudTweens = undefined;
    this._fading = false;

    // Cloud data at rest
    this._cloudData = [
      /* eslint-disable object-curly-newline */
      { x: -450, y: -40, scaleX: 2, scaleY: 2, tweenX: 2.1, tweenY: 2.1, tweenTime: 4000, timelineDT: 2000 },
      { x: -20, y: -250, scaleX: 2, scaleY: 2, tweenX: 2.1, tweenY: 2.1, tweenTime: 8000, timelineDT: 3000 },
      { x: 450, y: -40, scaleX: -2, scaleY: 2, tweenX: -2.1, tweenY: 2.1, tweenTime: 6000, timelineDT: 1500 },
      /* eslint-enable object-curly-newline */
    ];

    // Creates a cloud for each data
    this._cloudArr = [];
    this._cloudData.forEach((data) => {
      const cloud = G.makeImage(data.x, data.y, 'cloud_1', 0.5, this);
      cloud.scale.set(data.scaleX, data.scaleY);
      cloud.alpha = 0.95;
      const tween = game.add.tween(cloud.scale).to({ x: data.tweenX, y: data.tweenY }, data.tweenTime, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
      tween.timeline[0].dt = data.timelineDT;
      this._cloudArr.push({
        cloud,
        tween,
      });
    });
  }

  /**
   * Init the cloud
   * @param {number} index
   * @param {number} posOfNode position of the Gate Node
   */
  init(index, posOfNode) {
    if (this._fadeTweens) { // Kills all fade tweens if they're going
      this._fadeTweens.forEach((tw) => {
        tw.stop();
      });
      this._fadeTweens = null;
    }
    this.index = index;
    this._cloudData.forEach((data, dataIndex) => { // Initialize each cloud at position
      const cloudPack = this._cloudArr[dataIndex];
      cloudPack.cloud.alpha = 0.95;
      cloudPack.cloud.x = data.x;
      cloudPack.cloud.y = data.y;
      if (cloudPack.tween.isPaused) {
        cloudPack.tween.resume();
      }
    });
    this.y = posOfNode - this.height; // Positions the whole image based on gate node position
    this.alpha = 1;
    this.revive();
  }

  /**
   * Clouds fade away. When the tween finishes. onComplete is fired
   * @param {Function} onComplete
   */
  fadeAway(onComplete) {
    if (this._fading) return; // If its already fading don't...

    this._fading = true;
    this._fadeTweens = [];
    const fadeData = [
      { x: -900, y: -50 },
      { alpha: 0 },
      { x: 900, y: -50 },
    ];
    this._cloudArr.forEach((cloudPack, cloudIndex) => {
      const tw = game.add.tween(cloudPack.cloud).to(fadeData[cloudIndex], 3000, Phaser.Easing.Sinusoidal.Out, true);
      this._fadeTweens.push(tw);
    });
    const lastTween = game.add.tween(this).to({ alpha: 0 }, 2000, Phaser.Easing.Sinusoidal.In, true, 1000);
    lastTween.onComplete.addOnce(() => {
      this._fading = false;
      if (onComplete) {
        onComplete();
      }
    });
    this._fadeTweens.push(lastTween);
  }

  /**
   * Kills cloud
   */
  kill() {
    this._fading = false;
    this._cloudArr.forEach((cloud) => {
      cloud.tween.pause();
    });
    super.kill();
  }
}
