let _instance = null;

/**
 * Shop coinbar for during the game scene
 */
export class Shop_CoinBar extends Phaser.Group {
  /**
   * get singleton instance
   * @returns {Shop_CoinBar}
   */
  static getInstance() {
    if (_instance === null) {
      _instance = new Shop_CoinBar();
    }
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    super(game);
    this._hidden = false;
    this.hide(false);
    this._initLayoutElements();

    G.sb('onScreenResize').add(this._onResize, this);
  }

  /**
   * init layout elemenets
   */
  _initLayoutElements() {
    this._coinsBg = G.makeImage(0, 0, 'top-panel-coinsBg', 0.5, this);
    this._coinsTxt = new G.Text(
      20, 4,
      OMT.language.toLocaleNumber(G.saveState.getCoins().toString()),
      'soloInstance-coinBar',
      0.5, 110,
    );
    this.addChild(this._coinsTxt);
  }

  /**
   * get G.Text reference
   */
  get coinsTxt() {
    return this._coinsTxt;
  }

  /**
   * on game resized
   */
  _onResize() {
    this.x = game.width / 2;
    this.y = 30;
  }

  /**
   * hide the CoinBar now
   * @param {boolean} animate optional animation flag
   */
  hide(animate = true) {
    if (this._hidden) return;
    this._killTweens();
    this._hidden = true;

    if (!animate) { // show no animation
      this.alpha = 0;
      this.visible = false;
      if (this.parent) this.parent.removeChild(this);
    } else { // animate out after 1 a second
      this.tween = game.add.tween(this);
      this.tween.to({ alpha: 0 }, 250, Phaser.Easing.Quadratic.Out, true, 750);
      this.tween.onComplete.addOnce(() => {
        this._killTweens();
        this.visible = false;
        if (this.parent) this.parent.removeChild(this);
      });
      this.tween.start();
    }
  }

  /**
   * show the CoinBar now
   * @param {boolean} animate optional animation flag
   */
  show(animate = true) {
    if (!this._hidden) return;
    this._killTweens();
    this._hidden = false;
    this._coinsTxt.setText(OMT.language.toLocaleNumber(G.saveState.getCoins().toString()));

    if (!animate) { // show no animation
      this.alpha = 1;
      this.visible = true;
    } else { // animate in
      this.alpha = 0;
      this.visible = true;
      this.tween = game.add.tween(this);
      this.tween.to({ alpha: 1 }, 250, Phaser.Easing.Quadratic.Out);
      this.tween.onComplete.addOnce(() => {
        this._killTweens();
      });
      this.tween.start();
    }
    // adjust position and add to screen
    if (!this.parent) game.state.getCurrentState().stage.addChild(this);
    this._onResize();
  }

  /**
   * kill show / hide tweens
   */
  _killTweens() {
    if (!this.tween) return;
    this.tween.stop();
    this.tween = null;
  }
}
