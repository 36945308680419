/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */

import LevelEconomyTracker from '../../Elements/GameTracking/LevelEconomyTracker';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import { BragRightsManager } from '../../Elements/Windows/Brag/BragRightsManager';
import FortuneCookieDataManager from '../OMT/dataTracking/fortuneCookie/FortuneCookieDataManager';
import TargetedOfferDataManager from '../OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { RMWHEEL_MODES, RMWHEEL_DDNAPARAMS } from '../../Elements/SpinningWheels/RealMoneyWheel/rmWheelEnums';
import { isConversionToEnum } from '../../Elements/SpinningWheels/RealMoneyWheel/RealMoneyWheelHelpers';
import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';

/* eslint-disable prefer-destructuring */
/* eslint-disable no-useless-constructor */

/**
* Class for tracking mission event data for DDNA analytics
*/
class DDNAMissionTracker {
  /**
  * init mission parameters objects and set initial values/
  * @param {string} gameMode game mode CHALLENGE / NORMAL
  * @param {Object} extraData Extra data that needs to be added before event is sent
  */
  init(gameMode, extraData) {
    const levNum = gameMode === LevelType.NORMAL || LevelType.COLLECT_EVENT ? G.lvl.data.levelNumber : -1;
    let levID = '';

    if (gameMode === LevelType.NORMAL || gameMode === LevelType.COLLECT_EVENT) {
      levID = G.json.levels.order[G.lvl.lvlIndex];
    } else if (gameMode === LevelType.TOURNAMENT) {
      levID = OMT.platformTournaments.getTournamentLevelId();
    }

    FortuneCookieDataManager.getInstance().resetMissionData();
    TargetedOfferDataManager.getInstance().resetMissionData();

    /**
     * MISSION PARAMETERS GO LIKE THIS
     * Start + StartBase = Start Event
     * StartBase + EndBase + Fail = Fail Event
     * StartBase + EndBase + Win = Win Event
     */
    this._missionStartBaseParams = {
      missionName: `Level-${levNum}`,
      mGameMode: gameMode,
      mLevel: levNum,
      mLevelID: levID,
      mLevelDifficulty: G.lvl.data.difficulty * 1,
      mLevelHard: (G.lvl.data.showAsHard || G.lvl.data.showAsSuperHard) ? 1 : 0,
      mRetryNum: G.saveState.getLevelRetries(G.lvl.data.levelIndex),
      mIsSuccessRetry: G.saveState.getStars(G.lvl.data.levelIndex) > 0 ? 1 : 0,
      mLevelMoves: G.lvl.moves,
      mSpecialTileUsed: 0,
      mExtraMovesUsed: 0,
      mBombBoosterUsed: 0,
      isBragLevel: BragRightsManager.getInstance().isBragLevel(levNum) ? 1 : 0,
    };

    // tournament only parameters
    if (gameMode === LevelType.TOURNAMENT) {
      const { tournamentContextId, contextPlayerCount } = OMT.platformTournaments;
      if (tournamentContextId) this._missionStartBaseParams.mTournamentId = tournamentContextId;
      this._missionStartBaseParams.mTournamentPlayerCount = contextPlayerCount;
    }

    if (gameMode === LevelType.NORMAL) {
      this._missionStartBaseParams.mLevelDifficultyType = OMT_VILLAINS.getMLevelDifficultyType(G.lvl.data);
    }

    // parameters for START only
    this._missionStartParams = {
      // Empty
    };

    // Track tSeenTargetedOfferHighValueWheel and tSeenTargetedOfferConversionMoneyWheel params
    // from real money wheel tracker (OMT-4155)
    const realMoneyWheelTrackerParams = DDNA.transactionHelper.getRealMoneyWheelTracker().getParamObj();
    this._missionStartParams.tSeenTargetedOfferConversionMoneyWheel = realMoneyWheelTrackerParams.tSeenTargetedOfferConversionMoneyWheel;
    this._missionStartParams.tSeenTargetedOfferHighValueWheel = realMoneyWheelTrackerParams.tSeenTargetedOfferHighValueWheel;
    // DDNA.transactionHelper.resetQueuedRealMoneyWheelEvents();

    /**
     * MISSION PARAMETERS GO LIKE THIS
     * StartBase + EndBase + Fail = Fail event
     * StartBase + EndBase + Win = Win event
     */
    this._missionEndBaseParams = {
      mSwapBoosterUsed: 0,
      mRemoverBoosterUsed: 0,
      mHorizontalBoosterUsed: 0,
      mVerticalBoosterUsed: 0,
      mLevelExtraMovesUsed: 0,
      mCoinsSpent: 0,
      mCoinsBoughtWithRealMoney: 0,
      mBoostersBoughtWithRealMoney: 0,
      mNumBoostersUsedLossAversion: 0,
      mLossAversionCoinsSpent: 0,
      mMovesMade: 0,
      mMovesLeft: 0,
      mStars: 0,
      mScoreBeforeEndSequence: 0,
      mScore: 0,
      mCoinsLevelEnd: 0,
      mCoinsChests: 0,
      mAdsWatchedWheel: 0,
      mAdsWatchedDoubleReward: 0,
      mNumWheelSpins: 0,
      mFortuneCookieCategoryRejected: 0,
      mFortuneCookieEventRejected: 0,
      mFortuneCookieCategorySelected: 0,
      mFortuneCookieOpened: 0,
      mFortuneCookieShared: 0,
      mFortuneCookieTokenSpawn: 0,
      mFortuneCookieTokenCollected: 0,
      mFortuneCookieMsgId: '',
      mFortuneCookieCatId: '',
      mEligibleHelperMoneyWheel: 0,
      mSeenHelperConversionMoneyWheel: 0,
      mSeenHelperHighValueWheel: 0,
      mOpenedHelperConversionMoneyWheel: 0,
      mOpenedHelperHighValueWheel: 0,
      mPurchasedHelperConversionMoneyWheel: 0,
      mPurchasedHelperHighValueWheel: 0,
      mOutOfMovesCount: 0,
      mTargetedOfferSeen: '',
      mEventTokensCollected: 0,
    };

    // parameters for WIN only
    this._missionWinParams = {
      mSkippedWinSequence: 0,
      mTreasureHuntTokensCollected: 0,
    };

    // parameters for FAIL only
    this._missionFailParams = {
      // Empty
    };

    // listen for game events
    G.sb('onGoalAchieved').addOnce(() => { this.onGoalAchieved(); });
    G.sb('onLevelFinished').addOnce((levelIndex, newStars, newPoints) => { this.onLevelFinished(newStars, newPoints); });
    G.sb('onDailyChallengeWon').addOnce(() => { this.onLevelFinished(0); });
    G.sb('onLevelFailed').addOnce(() => { this.onLevelFailed(); });
    G.sb('onHandlePurchase').add((product, productData) => {
      this.handlePurchase(product, productData);
    });

    if (extraData) {
      if (extraData.startBoosters) {
        if (extraData.startBoosters.player) {
          const startBoosters = extraData.startBoosters.player;
          const movesBooster = startBoosters[5] || startBoosters[6] || 0;
          if (movesBooster) {
            this.addToMissionParameter('mExtraMovesUsed', movesBooster);
          }
          const specialBooster = startBoosters[7] || 0;
          if (specialBooster) {
            this.addToMissionParameter('mSpecialTileUsed', specialBooster);
          }
          const bombBooster = startBoosters[8] || 0;
          if (bombBooster) {
            this.addToMissionParameter('mBombBoosterUsed', bombBooster);
          }
        }
        if (extraData.startBoosters.adRewarded) {
          const startBoosters = extraData.startBoosters.adRewarded;
          // Ad rewards don't give moves boosters
          const specialBooster = startBoosters[7] || 0;
          if (specialBooster) {
            this.addToMissionParameter('mSpecialTileUsed', 1);
          }
          const bombBooster = startBoosters[8] || 0;
          if (bombBooster) {
            this.addToMissionParameter('mBombBoosterUsed', 1);
          }
        }
      }
    }

    // track missionStarted event
    // DDNA.tracking.collectEvent('missionStarted', this.getMissionStartParams());
  }

  /**
  * get the mission started base paramters
  * @returns {Object}
  */
  getMissionStartBaseParams() {
    const paramObj = {};
    Object.assign(paramObj, this._missionStartBaseParams);
    return paramObj;
  }

  /**
  * get the mission started paramters
  * @returns {Object}
  */
  getMissionStartParams() {
    const paramObj = this.getMissionStartBaseParams();
    Object.assign(paramObj, this._missionStartParams);
    return paramObj;
  }

  /**
  * get the mission failed paramters
  * @returns {Object}
  */
  getMissionFailParams() {
    const paramObj = this.getMissionStartBaseParams();
    Object.assign(paramObj, this._missionEndBaseParams);
    Object.assign(paramObj, this._missionFailParams);
    return paramObj;
  }

  /**
   * get the mission won paramters
   * @returns {Object}
   */
  getMissionWinParams() {
    const paramObj = this.getMissionStartBaseParams();
    Object.assign(paramObj, this._missionEndBaseParams);
    Object.assign(paramObj, this._missionWinParams);
    return paramObj;
  }

  /**
  * get the associated parameters object by parameter name
  * @param {string} paramName name of parameter to lookup
  * @returns {Object}
  */
  getParamObjByParamName(paramName) {
    if (this._missionStartBaseParams[paramName] != null) return this._missionStartBaseParams;
    if (this._missionEndBaseParams[paramName] != null) return this._missionEndBaseParams;
    if (this._missionFailParams[paramName] != null) return this._missionFailParams;
    if (this._missionWinParams[paramName] != null) return this._missionWinParams;
    return null;
  }

  /**
  * set a mission parameter value, looks up correct paramObj
  * @param {string} paramName name of parameter to lookup
  * @param {*} value value to assign
  */
  setMissionParameter(paramName, value) {
    const paramObj = this.getParamObjByParamName(paramName);
    paramObj[paramName] = value;
  }

  /**
  * add to a mission parameter value, looks up correct paramObj
  * @param {string} paramName name of parameter to lookup
  * @param {*} value value to assign
  */
  addToMissionParameter(paramName, value) {
    const paramObj = this.getParamObjByParamName(paramName);
    paramObj[paramName] += value;
  }

  /**
   * track real money wheel mission param
   * @param {RMWHEEL_EPS} wheelEP
   * @param {RMWHEEL_EVENTS} wheelEvent
   * @param {boolean} isConversion
   * @param {number} value
   * @param {boolean} increment set (false) or increment (true) the parameter
   */
  trackWheelEvent(wheelEP, wheelEvent, isConversion, value, increment) {
    const wheelMode = isConversionToEnum(isConversion);
    const paramName = RMWHEEL_DDNAPARAMS[wheelEP][wheelEvent][wheelMode];

    if (paramName) {
      if (increment) {
        this.addToMissionParameter(paramName, value);
      } else {
        this.setMissionParameter(paramName, value);
      }
    }
  }

  /**
  * track booster use
  * @param {number} boostNum id # of booster used
  * @param {boolean} isPreBooster is a prelevel booster
  * @param {number} boosterCount the number of boosters used
  */
  onBoosterUse(boostNum, isPreBooster, boosterCount = 1) {
    if (!isPreBooster) { // in level boosters
      if (boostNum === 1) {
        this.addToMissionParameter('mSwapBoosterUsed', boosterCount);
      } else if (boostNum === 2) {
        this.addToMissionParameter('mRemoverBoosterUsed', boosterCount);
      } else if (boostNum === 3) {
        this.addToMissionParameter('mHorizontalBoosterUsed', boosterCount);
      } else if (boostNum === 4) {
        this.addToMissionParameter('mVerticalBoosterUsed', boosterCount);
      } else if (boostNum === 5 || boostNum === 6) {
        this.addToMissionParameter('mLevelExtraMovesUsed', boosterCount);
      }

      // track booster use when low on moves
      if (G.lvl.moves <= 3) {
        this.addToMissionParameter('mNumBoostersUsedLossAversion', boosterCount);
      }
    } else { // pre boosters
      // eslint-disable-next-line no-lonely-if
      if (boostNum === 5 || boostNum === 6) {
        this.addToMissionParameter('mExtraMovesUsed', boosterCount);
      } else if (boostNum === 7) {
        this.addToMissionParameter('mSpecialTileUsed', boosterCount);
      } else if (boostNum === 8) {
        this.addToMissionParameter('mBombBoosterUsed', boosterCount);
      }
    }
  }

  /**
   * on extra moves booster used from out of moves flow
   * @param {string} boosterId
   */
  onOOMMovesBoosterUse(boosterId) {
    this.addToMissionParameter('mLevelExtraMovesUsed', 1);
  }

  /**
  * handle in level purchases
  * @param {Object} product product Object
  * @param {Object} productData productData Object
  */
  handlePurchase(product, productData) {
    if (!productData || !productData.giftData) return;

    // check all gifts received in the purchase
    let giftType; let giftAmount;
    for (let i = 0; i < productData.giftData.length; i++) {
      giftType = productData.giftData[i][0];
      giftAmount = productData.giftData[i][1];

      if (giftType === 'coin') {
        this.addToMissionParameter('mCoinsBoughtWithRealMoney', giftAmount);
      } else if (giftType.indexOf('booster') === 0) {
        this.addToMissionParameter('mBoostersBoughtWithRealMoney', giftAmount);
      }
    }
  }

  /**
  * listen for goal achieved event and update related parameters
  */
  onGoalAchieved() {
    // console.log("DDNA MISSION onGoalAchieved()");
    this.setMissionParameter('mMovesMade', G.lvl.movesMade);
    this.setMissionParameter('mMovesLeft', G.lvl.moves);
    this.setMissionParameter('mScoreBeforeEndSequence', G.lvl.points);
  }

  /**
  * listen for level won event
  * @param {number} newStars
  * @param {number} newPoints
  */
  onLevelFinished(newStars, newPoints) {
    const levelEconTracker = LevelEconomyTracker.getInstance();
    const fortuneCookieMissionData = FortuneCookieDataManager.getInstance().missionData;
    const targetedOfferMissionData = TargetedOfferDataManager.getInstance().missionData;
    const { tokenEventManager, treasureHuntManager } = G.saveState;
    const treasureTokens = treasureHuntManager.tempTokens * (treasureHuntManager.isDoubling ? 2 : 1);

    this.setMissionParameter('mCoinsSpent', levelEconTracker.getLevelCoinsSpent());
    this.setMissionParameter('mStars', newStars);
    this.setMissionParameter('mScore', newPoints);
    this.setMissionParameter('mLossAversionCoinsSpent', levelEconTracker.getLossAversionCoinsSpent());
    this.setMissionParameter('mCoinsLevelEnd', DDNA.transactionHelper.getCoinsBySource('LevelEnd'));
    this.setMissionParameter('mCoinsChests', DDNA.transactionHelper.getCoinsBySource('GameplayChest'));
    this.setMissionParameter('mAdsWatchedWheel', levelEconTracker.getRewardedAdsWatched());
    this.setMissionParameter('mAdsWatchedDoubleReward', levelEconTracker.getRewardDoubled());
    this.setMissionParameter('mNumWheelSpins', levelEconTracker.getWheelSpins());
    this.setMissionParameter('mFortuneCookieTokenSpawn', fortuneCookieMissionData.timesSpawned);
    this.setMissionParameter('mFortuneCookieTokenCollected', fortuneCookieMissionData.timesCollected);
    this.setMissionParameter('mFortuneCookieCategoryRejected', fortuneCookieMissionData.timesDismissed);
    this.setMissionParameter('mFortuneCookieEventRejected', fortuneCookieMissionData.timesFullDismiss);
    this.setMissionParameter('mFortuneCookieCategorySelected', fortuneCookieMissionData.timesSelected);
    this.setMissionParameter('mFortuneCookieOpened', fortuneCookieMissionData.timesOpened);
    this.setMissionParameter('mFortuneCookieShared', fortuneCookieMissionData.timesShared);
    this.setMissionParameter('mFortuneCookieMsgId', fortuneCookieMissionData.currentMsg);
    this.setMissionParameter('mFortuneCookieCatId', fortuneCookieMissionData.currentCategory);
    this.setMissionParameter('mEventTokensCollected', tokenEventManager.levelTokensCollected);
    this.setMissionParameter('mTreasureHuntTokensCollected', treasureTokens);

    this.setMissionParameter('mOutOfMovesCount', targetedOfferMissionData.outOfMovesCount);
    this.setMissionParameter('mTargetedOfferSeen', targetedOfferMissionData.targetedOfferSeen);

    FortuneCookieDataManager.getInstance().resetMissionData();
    TargetedOfferDataManager.getInstance().resetMissionData();

    // track missionCompleted event
    // DDNA.tracking.collectEvent('missionCompleted', this.getMissionWinParams());
  }

  /**
  * listen for level failed event
  */
  onLevelFailed() {
    const levelEconTracker = LevelEconomyTracker.getInstance();
    const fortuneCookieMissionData = FortuneCookieDataManager.getInstance().missionData;
    const targetedOfferMissionData = TargetedOfferDataManager.getInstance().missionData;

    this.setMissionParameter('mMovesMade', G.lvl.movesMade);
    this.setMissionParameter('mMovesLeft', G.lvl.moves);
    this.setMissionParameter('mCoinsSpent', levelEconTracker.getLevelCoinsSpent());
    this.setMissionParameter('mStars', 0);
    this.setMissionParameter('mScore', G.lvl.points);
    this.setMissionParameter('mLossAversionCoinsSpent', levelEconTracker.getLossAversionCoinsSpent());

    this.setMissionParameter('mFortuneCookieTokenSpawn', fortuneCookieMissionData.timesSpawned);
    this.setMissionParameter('mFortuneCookieTokenCollected', fortuneCookieMissionData.timesCollected);
    this.setMissionParameter('mFortuneCookieCategoryRejected', fortuneCookieMissionData.timesDismissed);
    this.setMissionParameter('mFortuneCookieEventRejected', fortuneCookieMissionData.timesFullDismiss);
    this.setMissionParameter('mFortuneCookieCategorySelected', fortuneCookieMissionData.timesSelected);
    this.setMissionParameter('mFortuneCookieOpened', fortuneCookieMissionData.timesOpened);
    this.setMissionParameter('mFortuneCookieShared', fortuneCookieMissionData.timesShared);
    this.setMissionParameter('mFortuneCookieMsgId', fortuneCookieMissionData.currentMsg);
    this.setMissionParameter('mFortuneCookieCatId', fortuneCookieMissionData.currentCategory);

    this.setMissionParameter('mOutOfMovesCount', targetedOfferMissionData.outOfMovesCount);
    this.setMissionParameter('mTargetedOfferSeen', targetedOfferMissionData.targetedOfferSeen);

    FortuneCookieDataManager.getInstance().resetMissionData();
    TargetedOfferDataManager.getInstance().resetMissionData();

    // track missionFailed event
    // DDNA.tracking.collectEvent('missionFailed', this.getMissionFailParams());
  }
}

// create global references
if (!window.DDNA) window.DDNA = {};
DDNA.missionTracker = new DDNAMissionTracker();
