import SPDM_MissionPanelBtn from './G.SPDM_MissionPanelBtn';
import { BUTTONCOLOURS, BUTTONSTATE } from '../../OMT_UI/OMT_UI_SquareButton';

G.SPDM_MissionPanel = function(mission) {
  this.state = game.state.getCurrentState();
  this.mission = mission;

  var config = {
    value: mission.getCurrentVal(),
    target: mission.getTarget(),
    valueTarget: mission.getCurrentVal(),
    progressBar: {
      y: -10,
    },
  };

  G.PFEventPanel.call(this, config);
  this.bg.width = 350;
  this.bg.inputEnabled = false;

  this.icon = this._addIcon(-180, 0, mission);
  this.desc = this._addDescription(0, -25, mission);
  this.reward = this._addReward(-120, 30, mission);
  this.checkMark = G.makeImage(149, -10, "task_complete", 0.5, this);
  this.checkMark.scale.setTo(0);

  this.cupcake = G.makeImage(154, -6,
    G.dailyMissionsMgr.getCollectionIcons().icon, 0.5, this);
  this.cupcake.scale.setTo(1.37);

  this.actionBtn = new SPDM_MissionPanelBtn(90, 60, mission, {
    button: {
      tint: BUTTONCOLOURS.orange,
      dimensions: {
        width: 138,
        height: 70,
      },
      extraDetail: false,
    },
    text: {
      string: '',
      textStyle: {
        style: 'font-white',
        fontSize: 25,
      },
    },
    options: {
      cacheButton: false,
    },
  });
  this.add(this.actionBtn);


  if (mission.status === "completed" || mission.status === "claimed") {
    this.bg.changeTexture("score_popup_green");
  }

  if (mission.status === "completed") {
    G.sb("widgetButtonAddNotification").dispatch();
  }

  if (mission.status === "claimed") {
    this.checkMark.scale.setTo(1.1);
    this.reward.alpha = 0;
    this.cupcake.visible = false;
  }


  this.bindings = [
    mission.onCompleted.add(this._changeToCompleted, this),
    mission.onClaimed.add(this._claimReward, this),
    mission.onProgress.add(function(newValue) {
      this.valueObj.valueTarget = newValue;
      game.time.events.add(100, this.showProgress, this);
    }, this),
  ];

  this.onClaimed = new Phaser.Signal();
  this.onCompleted = new Phaser.Signal();

  this.onDestroy.add(function(){
    this.bindings.forEach(function(binding) {
      binding.detach();
    });
  }, this);

  this._initWidget();
};

G.SPDM_MissionPanel.prototype = Object.create(G.PFEventPanel.prototype);

G.SPDM_MissionPanel.prototype._addIcon = function(x, y, mission) {
  var icon = null;

  if (mission.getIcon()) {
    icon = G.makeImage(x, y, mission.getIcon(), [0, 0.5], this);
    icon.width = icon.height = 60;
    icon.scale.setTo(Math.min(icon.scale.x, icon.scale.y));
    this.add(icon);

    if (this.mission.missionData.iconSize) {
      icon.width = this.mission.missionData.iconSize.width;
      icon.height = this.mission.missionData.iconSize.height;
    }   
  }

  return icon;
};

G.SPDM_MissionPanel.prototype._addDescription = function(x, y, mission) {
  var desc = null;

  if (mission.getDescription()) {
    desc = this.add(new G.Text(x, y, mission.getDescription(), {
      style: "font-white",
      fontSize: 25,
    }, 0.5, 300));
  }

  return desc;
};

G.SPDM_MissionPanel.prototype._addReward = function(x, y, mission) {
  var reward = null;

  if (mission.getReward()) {
    reward = this.add(new G.LabelTextT(
      G.gift.getLabelString(mission.getReward()), x, y, {
        style: "font-white",
        fontSize: 25,
      }, [0, 0.5], 150));
  }

  return reward;
};

G.SPDM_MissionPanel.prototype._changeToCompleted = function() {
  this.onCompleted.dispatch();
  G.sb("widgetButtonAddNotification").dispatch();
  this.actionBtn.refresh();
  this.bg.changeTexture("score_popup_green");
  if (this.widget) {
    G.sb("removeSidePanelButtonWidget").dispatch(this.widget);
    this.widget.destroy();
  }
};

G.SPDM_MissionPanel.prototype._claimReward = function() {
  OMT.transactionTracking.logInventoryTransactionBegin();

  G.sb("widgetButtonRemoveNotification").dispatch();
  game.sfx.xylophone_positive_12.play();
  this.bg.changeTexture("score_popup_green");
  this.actionBtn.inputEnabled = false;

  if (this.mission.reward[0] === "coin") {
    for (var i = 0; i < 8; i++) {
      this.state.uiFxLayer.initUIEffect(
        game.world.bounds.x + this.reward.worldPosition.x + this.reward.width*0.5,
        this.reward.worldPosition.y, "burstingCoin");
    }
    // DDNA.transactionHelper.trackRewards(this.mission.reward, [], {
    //   transactionType:"REWARD", tActionType:"DAILY_MISSION", tGameArea:"SIDE_SCREEN"
    // });
    G.gift.applyGift(this.mission.reward, false);
  } else {
    // DDNA.transactionHelper.trackRewards(this.mission.reward, [], {
    //   transactionType:"REWARD", tActionType:"DAILY_MISSION", tGameArea:"SIDE_SCREEN"
    // });
    G.gift.applyGift(this.mission.reward, false);
  }

  OMT.platformTracking.logEvent(OMT.platformTracking.Events.MiniMissionCompleted);
  OMT.transactionTracking.logInventoryTransactionEnd();

  game.add.tween(this.reward)
    .to({alpha: 0}, 500, Phaser.Easing.Sinusoidal.Out, true);

  game.add.tween(this.cupcake.scale)
    .to({x: 1.8, y: 1.8}, 600, Phaser.Easing.Sinusoidal.Out, true)
    .onComplete.add(function() {
      game.add.tween(this.checkMark.scale)
      .to({x: 1.1, y: 1.1}, 500, Phaser.Easing.Elastic.Out, true, 400);
      this.cupcake.visible = false;
      this.onClaimed.dispatch(this);
    }, this);
  
};

G.SPDM_MissionPanel.prototype._initWidget = function() {
  if (this.mission.status !== "active") return;
  if (!this.mission.missionData.widget) return;

  var group = game.make.group();

  this.mission.missionData.widget.forEach(function(elem) {
    var gameObj;
    if (elem.type === "img") {
      gameObj = G.makeImage(0, 0, elem.sprite, 0.5, group);
      gameObj.width = gameObj.height = 55;
      gameObj.scale.setTo(Math.min(gameObj.scale.x, gameObj.scale.y));
    } else if (elem.type === "txt") {
      gameObj = group.add(new G.Text(
        0, 0, OMT.language.getText(elem.txt), elem.style || {
          style: "font-blue",
          fontSize: 25,
          lineSpacing: -10,
        }, 0.5, 110, 200, elem.multiline, "center")
      );
    }

    if (elem.props) {
      Object.assign(gameObj, elem.props);
    }
  });

  group.getBannerConfig = function() {
    return this.bannerConfig;
  };

  group.bannerConfig = {
    bgSprite: "missions_map_banner_yellow_small",
    text: OMT.language.getText("Daily"),
  };

  this.widget = group;
  G.sb("addSidePanelButtonWidget").dispatch(this.widget);
};