import { GameScaleController } from '../../../../../States/Scaling/GameScaleController';
import LevelNumberTabBase, { DIFFICULTY } from '../base/LevelNumberTabBase';
import { ORIENTATION } from '../../../../../Services/OMT/OMT_SystemInfo';

const SIZE_SCALE = 0.90;
export default class LevelNumberTabHorizontal extends LevelNumberTabBase {
  constructor(gameplayController, levelData, gameMode) {
    super(gameplayController, levelData, gameMode);
    this._signalBindings = [];
    this._signalBindings.push(G.sb('onScreenResize').add(this._onScreenResize.bind(this)));

    this._onScreenResize();
  }

  /**
   * Draws the background of the level number tab.
   * @param {{parent:Phaser.DisplayObject, initialWidth:number, initialHeight:number, levelNumber:number, difficult:DIFFICULTY}} config
   */
  _drawBg(config) {
    super._drawBg();
    this._gameScale = GameScaleController.getInstance().gameScale;
    this._scaleFactor = OMT.systemInfo.orientation === ORIENTATION.horizontal
      ? SIZE_SCALE * this._gameScale
      : SIZE_SCALE;
    this._fadeTween = undefined;
    this._container = new Phaser.Group(game);
    this._container.x = 25 + ((1137 - game.width) / 177) * 90;
    this._container.y = 71;
    // this._container.scale.setTo(0.9 * GameScaleController.getInstance().gameScale);
    this._container.scale.setTo(this._scaleFactor);
    this._tabContainer = new Phaser.Group(game, this._container);
    let targetAsset = '';
    switch (config.difficult) {
      case DIFFICULTY.HARD: targetAsset = 'horizontal-levelTabHard'; break;
      case DIFFICULTY.SUPERHARD: targetAsset = 'horizontal-levelTabSuperHard'; break;
      default: targetAsset = 'horizontal-levelTabNormal'; break;
    }
    G.makeImage(0, 0, targetAsset, 0.5, this._tabContainer);
    this._bgWidth = this._container.width;
  }

  /**
   * Draws the level number of the level number tab.
   * @param {{levelNumber:number, difficult:DIFFICULTY}} config
   */
  _drawLevelNumber(config) {
    super._drawLevelNumber();
    let textStyle = '';
    switch (config.difficult) {
      case DIFFICULTY.HARD: textStyle = 'superhard-hard_3'; break;
      case DIFFICULTY.SUPERHARD: textStyle = 'superhard-hard_3'; break;
      default: textStyle = 'UILevelNumber-normal'; break;
    }
    this._text = new G.Text(0, 2, config.levelNumber, { style: textStyle, fontSize: 40 }, 0.5);
    this._tabContainer.addChild(this._text);
  }

  /**
   * Stops the tween
   */
  _stopTween() {
    if (this._fadeTween) {
      this._fadeTween.stop();
    }
  }

  /**
   * Animates the level number tab in
   */
  animateSlideIn() {
    super.animateSlideIn();
    this._stopTween();
    this._fadeTween = game.add.tween(this._tabContainer).to({ y: 0, alpha: 1 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
  }

  /**
   * Animates out the level number tab
   */
  animateSlideOut() {
    super.animateSlideOut();
    this._stopTween();
    this._fadeTween = game.add.tween(this._tabContainer).to({ y: -game.height, alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
  }

  _onScreenResize() {
    this._container.x = game.world.bounds.x + (this._bgWidth * this._gameScale) / 2 + 60;
    this._container.y = game.world.bounds.y + game.height / 2 - 275;
  }
}
