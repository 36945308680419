/* eslint-disable no-use-before-define */

import { OMT_UI_FriendsListItem } from '../../../OMT_UI/FriendsList/OMT_UI_FriendsListItem';
import { OMT_UI_ButtonWithCoolDown } from '../../../OMT_UI/Buttons/OMT_UI_ButtonWithCooldown';
import { LeaderboardAvatar } from '../../Components/Avatars/LeaderboardAvatar';

import {
  LIFE_SEND_COOLDOWN,
  LIFE_REQUEST_COOLDOWN,
  LIFE_PANEL_EXPANDED_BTN_ICON_SCALE,
  LIFE_PANEL_EXPANDED_BTN_TEXT_OFFSET_Y,
  LIFE_PANEL_EXPANDED_REQUEST_BTN_X,
  LIFE_PANEL_EXPANDED_SEND_BTN_X,
  LIFE_PANEL_EXPANDED_FONT_STYLE,
} from '../../MainLeaderboardSettings';
import { RenderTextureGenerator } from '@omt-components/Imaging/RenderTextures/RenderTextureGenerator';

export const LIFE_REQUEST_STATUS = {
  REQUESTED: 0,
  SUCCESS: 1,
  FAILED: 2,
};

/**
 * expanded user view / list item for sending or requesting lives from the leaderboard
 */
export class LifeRequestExpandedUserView extends OMT_UI_FriendsListItem {
  /**
   * constructor
   * @param {Object} friendData
   * @param {number} itemWidth
   * @param {number} itemHeight
   * @param {number} bgColor
   */
  constructor(friendData, itemWidth, itemHeight, bgColor) {
    super(friendData, itemWidth, itemHeight, bgColor);

    this.signals.onSendLife = new Phaser.Signal();
    this.signals.onRequestLife = new Phaser.Signal();

    this._initButtons();

    // cache content container
    this.cacheContent = true;
  }

  /**
   * init the avatar image
   */
  _initAvatar() {
    const textureId = `avatar-rank-0-${this._friendData.userId}-${this._imageSize}`;
    const anchor = [0.5, 0.5];
    this._avatarImage = RenderTextureGenerator.getRenderTextureSprite(textureId, anchor);
    if (!this._avatarImage) {
      const avatarGraphics = new LeaderboardAvatar(this._friendData, this._imageSize);
      this._avatarImage = RenderTextureGenerator.generateRenderTextureSprite(avatarGraphics, textureId, anchor);
      avatarGraphics.destroy();
    }
    this._avatarImage.x = this._imageX;
    this._avatarImage.y = this._itemHeight / 2;
    this._cacheableContent.addChild(this._avatarImage);
  }

  /**
   * init the users name label
   */
  _initNameLabel() {
    super._initNameLabel(LIFE_PANEL_EXPANDED_FONT_STYLE);
  }

  /**
   * init the send / request life buttons
   */
  _initButtons() {
    // request life button
    const requestButton = new RequestLifeButton(this._friendData.userId,
      (button) => {
        this._onCooldownButtonClicked(button, this.signals.onRequestLife, OMT.social.sendRequestLifeMessage);
      });
    requestButton.x = LIFE_PANEL_EXPANDED_REQUEST_BTN_X;
    requestButton.y = this._itemHeight / 2;
    this.addChild(requestButton);

    // send life button
    const sendButton = new SendLifeButton(this._friendData.userId,
      (button) => {
        this._onCooldownButtonClicked(button, this.signals.onSendLife, OMT.social.sendLifeMessage);
      });
    sendButton.x = LIFE_PANEL_EXPANDED_SEND_BTN_X;
    sendButton.y = this._itemHeight / 2;
    this.addChild(sendButton);

    // set button references
    this._requestLifeButton = requestButton;
    this._sendLifeButton = sendButton;
  }

  /**
   * called when either request / send life is clicked
   * @param {OMT_UI_ButtonWithCoolDown} button
   * @param {Phaser.Signal} signal signal to dispatch on status change
   * @param {Function} socialFunction send / or reqeuest lifes reference
   */
  async _onCooldownButtonClicked(button, signal, socialFunction) {
    signal.dispatch(this, LIFE_REQUEST_STATUS.REQUESTED);
    socialFunction.call(OMT.social, this._friendData, (result) => {
      if (result) {
        button.startCooldown();
        signal.dispatch(this, LIFE_REQUEST_STATUS.SUCCESS);
      } else {
        signal.dispatch(this, LIFE_REQUEST_STATUS.FAILED);
      }
    });
  }

  /**
   * enable interactivity
   */
  enable() {
    this._requestLifeButton.enable();
    this._sendLifeButton.enable();
  }

  /**
   * disable interactivity
   */
  disable() {
    this._requestLifeButton.disable();
    this._sendLifeButton.disable();
  }
}

/**
 * Button class for requesting a life
 */
class RequestLifeButton extends OMT_UI_ButtonWithCoolDown {
  /**
   * constructor
   * @param {string} friendId userId of friend
   * @param {Function} callback onClick callback
   */
  constructor(friendId, callback) {
    super('Request', LIFE_REQUEST_COOLDOWN.id, LIFE_REQUEST_COOLDOWN.duration, friendId, 140, 60, callback);
  }

  /**
   * init the button for the active state
   * @param {number} width button width
   * @param {number} height button height
   * @param {string} fontStyle font style id
   */
  _initActiveStateButton(width, height, fontStyle) {
    const iconGroup = new Phaser.Group(game);
    const icon = G.makeImage(0, 0, 'lb2_send_lives_button', [0.5, 0.5]);
    icon.scale.set(LIFE_PANEL_EXPANDED_BTN_ICON_SCALE);
    icon.scale.x *= -1;
    iconGroup.addChild(icon);
    super._initActiveStateButton(width, height, fontStyle, iconGroup);
  }

  /**
   * init the button / graphics for the cooldown state
   * @param {number} width button width
   * @param {number} height button height
   * @param {string} fontStyle font style id
   */
  _initCooldownStateButton(width, height, fontStyle) {
    const iconGroup = new Phaser.Group(game);
    const icon = G.makeImage(0, 0, 'lb2_send_lives_button_inactive', [0.5, 0.5]);
    icon.scale.set(LIFE_PANEL_EXPANDED_BTN_ICON_SCALE);
    icon.scale.x *= -1;
    iconGroup.addChild(icon);
    super._initCooldownStateButton(width, height, fontStyle, iconGroup);
  }
}

/**
 * Button class for sending a life
 */
class SendLifeButton extends OMT_UI_ButtonWithCoolDown {
  /**
   * constructor
   * @param {string} friendId userId of friend
   * @param {Function} callback onClick callback
   */
  constructor(friendId, callback) {
    super('Send', LIFE_SEND_COOLDOWN.id, LIFE_SEND_COOLDOWN.duration, friendId, 140, 60, callback);
  }

  /**
   * init the button for the active state
   * @param {number} width button width
   * @param {number} height button height
   * @param {string} fontStyle font style id
   */
  _initActiveStateButton(width, height, fontStyle) {
    const icon = G.makeImage(0, 0, 'lb2_send_lives_button', [0.5, 0.5]);
    icon.scale.set(LIFE_PANEL_EXPANDED_BTN_ICON_SCALE);
    super._initActiveStateButton(width, height, fontStyle, icon, LIFE_PANEL_EXPANDED_BTN_TEXT_OFFSET_Y);
  }

  /**
   * init the button / graphics for the cooldown state
   * @param {number} width button width
   * @param {number} height button height
   * @param {string} fontStyle font style id
   */
  _initCooldownStateButton(width, height, fontStyle) {
    const icon = G.makeImage(0, 0, 'lb2_send_lives_button_inactive', [0.5, 0.5]);
    icon.scale.set(LIFE_PANEL_EXPANDED_BTN_ICON_SCALE);
    super._initCooldownStateButton(width, height, fontStyle, icon, LIFE_PANEL_EXPANDED_BTN_TEXT_OFFSET_Y);
  }
}
