import { Window_Generic, GENERIC_WINDOW_STYLE } from '../Window_Generic';
import { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
/**
 * notification window for like us on facebook for no ads. Uses generic window.
 */
class Window_NoAdsOpenCoinShop extends Window_Generic {
  /**
   * constructor
   * @param {Object} parent
   * @param {Function} onRetry
   */
  // eslint-disable-next-line no-unused-vars
  constructor(parent, onRetry) {
    super(parent, { onRetry });
  }

  /**
   * apply the generic config
   * @param {Object} passedConfig
   */
  applyGenericConfig(passedConfig) {
    this._onRetry = passedConfig.onRetry;
    const config = this._getGenericWindowConfig();
    super.applyGenericConfig(config);
  }

  /**
   * get window configuration
   * @returns {Object}
   */
  _getGenericWindowConfig() {
    const config = {
      windowStyle: GENERIC_WINDOW_STYLE,
      template: {
        title: {
          text: OMT.language.getText('No ads available'),
          textStyle: { style: 'font-white', fontSize: 40 },
        },
        image: {
          image: this._makeImageContent(),
        },
        text: {
          text: OMT.language.getText('No ads available'),
          textStyle: 'font-blue',
          boundaries: new Phaser.Rectangle(0, 0, 500, 500),
        }/*,
        button1: {
          colour: BUTTONCOLOURS.green,
          text: OMT.language.getText('Coin Shop'),
          textStyle: { style: 'font-white', lineSpacing: -10 },
          boundaries: new Phaser.Rectangle(0, 50, 300, 100),
          onClick: this._onButton1Clicked.bind(this),
        }*/,
        button2: {
          colour: BUTTONCOLOURS.red,
          text: this._onRetry ? OMT.language.getText('Retry') : 'Ok',
          textStyle: { style: 'font-white', lineSpacing: -10 },
          boundaries: new Phaser.Rectangle(0, 180, 166, 86),
          onClick: this._onButton2Clicked.bind(this),
        },
        close: {
          onClick: this._onCloseClicked.bind(this),
        },
      },
    };
    return config;
  }

  /**
   * make the window image c ontent
   */
  _makeImageContent() {
    const imageContent = new Phaser.Group(game);
    const bgImage = G.makeImage(0, 0, 'reward_BG', [0.5, 0]);
    const coinImage = G.makeImage(0, 0, 'coin_3', [0.5]);
    coinImage.scale.set(2);
    coinImage.y = bgImage.height / 2;
    imageContent.addChild(bgImage);
    imageContent.addChild(coinImage);
    return imageContent;
  }

  /**
   * close button clicked
   */
  _onCloseClicked() {
    // not doing anything yet
  }

  /**
   * button1 button clicked
   */
  /*_onButton1Clicked() {
    game.state.getCurrentState().windowMgr.closeAllWindows(() => {
      G.sb('pushWindow').dispatch(['coinShop', {}]);
    });
  }*/

  /**
   * button2 No Thanks / Retry
   */
  _onButton2Clicked() {
    const allowRetry = this._onRetry !== null;
    if (allowRetry) { // retry ad
      this.closeWindow(() => {
        this._onRetry();
      });
    } else { // no thanks, close
      this.closeWindow();
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.NoAdsOpenCoinShop = Window_NoAdsOpenCoinShop;
