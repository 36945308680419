/* eslint-disable no-unused-vars */
/* eslint-disable no-continue */
/* eslint-disable key-spacing */

import { BOT_MESSAGE_TYPE } from '../OMT/OMT_Bots';

/**
* class for helping gather entry point data for DDNA events
*/
class DDNAEntryPointDataHelper {
  /**
  * constructor
  */
  constructor() {
    // booster param name maps
    this._itemNameMap = {
      coins               : 'epCoins',
      lifeUnlimited       : 'epUnlimitedLives',
      life                : 'epLives',
      'booster#1'         : 'epSwap',
      'booster#2'         : 'epRemover',
      'booster#3'         : 'epHorizontal',
      'booster#4'         : 'epVertical',
      'booster#5'         : 'epExtra5Moves',
      'booster#6'         : 'epExtra3Moves',
      'booster#7'         : 'epSpecialTile',
      'booster#8'         : 'epBomb',
    };
  }

  /**
   * get entry point tracking data
   */
  getEntryPointTrackingParams() {
    // state of user sending the data
    const { payload } = OMT.envData;
    const entryPoint = OMT.envData.entryPoint ? OMT.envData.entryPoint : 'none';
    const entryPointData = OMT.envData.entryPointData || {};

    // Check if entry point was from an ad
    const isAdEP = entryPoint === 'ad' || entryPoint === 'adCampaign';

    // create end point param object
    const epParams = {
      entryPoint,
      epContextType : OMT.social.getCurrentContextType(),
      epOrigin: isAdEP ? 'marketing' : 'organic',
    };

    // check the entryPoint payload
    if (payload) {
      const payloadMsgId = G.WorldMapAppearFlow.payloadChecker.getMsgId(payload);
      // dont process payload it has already been processed in a previous session
      if (payloadMsgId) {
        if (G.saveState.isMsgRead(payloadMsgId)) {
          console.log('DDNAEntryPointDataHelper: PREVIOUSLY READ PAYLOAD DETECTED');
          return epParams;
        }

        if (!G.saveState.isMsgForUser(payload)) {
          console.log('DDNAEntryPointDataHelper: PAYLOAD IS NOT FOR THIS USER');
          return epParams;
        }
      }

      const { sendParams, sbsTracking } = payload;
      // create gifts array
      let gifts = [];
      if (payload.gift && payload.gift.giftData) {
        gifts = payload.gift.giftData;
        // translate array format gift to object format. multiple types were being recieved.
        if (typeof gifts[0] === 'string') {
          gifts = [{ itemId:gifts[0], amount:gifts[1] }];
        }
        if (!Array.isArray(gifts)) gifts = [gifts];
      }

      // handle user messages with sender data attached
      if (sendParams) {
        // checks for null and undefined senderId
        const senderId = sendParams.senderId == null ? '' : sendParams.senderId;
        Object.assign(epParams, {
          epMsgID : sendParams.msgID.toString(),
          epMsgName : sendParams.msgName,
          epMsgType : sendParams.msgType,
          epMsgLocale : sendParams.msgLocale,
          epSenderId : sendParams.senderId,
          epSenderIdHash : OMT.crypt.sha3Hash(senderId),
          epSenderLevel : sendParams.senderLevel,
          epSenderDaysInGame : sendParams.senderDaysInGame,
          epSenderSessionNumber : sendParams.senderSessionNumber,
          epSenderCoins : sendParams.senderCoins,
          epSenderIsPayer : sendParams.senderIsPayer,
          epTimestampUnified: sendParams.timestamp,
        });
        // append epSocialActionLevel if defined
        if (sendParams.epSocialActionLevel != null) {
          epParams.epSocialActionLevel = parseInt(sendParams.epSocialActionLevel);
        }
      } else if (OMT.bots.entryPointIsBotMsg !== BOT_MESSAGE_TYPE.NOT_A_BOT_MESSAGE) { // handle bot messages
        const { gamePayload } = entryPointData;
        Object.assign(epParams, {
          epMsgID : gamePayload && gamePayload.gift ? gamePayload.gift.id.toString() : '',
          epMsgType : 'BOT',
          epMsgCategory : entryPointData.messageCategory,
          epMsgContentID : entryPointData.messageId,
        });
      } else if (isAdEP) { // handle ad campaigns
        if (entryPointData) {
          Object.assign(epParams, {
            epAdId : entryPointData.fb_instant_game_ad_id ? entryPointData.fb_instant_game_ad_id.toString() : null,
            epAdsetId : entryPointData.fb_instant_game_adset_id ? entryPointData.fb_instant_game_adset_id.toString() : null,
            epCampaignId : entryPointData.fb_instant_game_campaign_id ? entryPointData.fb_instant_game_campaign_id.toString() : null,
          });
        }
      } else if (entryPoint === 'game_switch' || entryPointData.originGameId !== undefined) { // cross-promo game switching
        // extra detection was added here as entryPoint 'game_switch' was unreliable. The entryPoint is forced to 'game_switch' in this case.
        Object.assign(epParams, { epCrossPromoSourceID: entryPointData.originGameId });
        if (entryPointData.crossPromoReward) {
          let resultStr = '';
          const appendStr = (str, app) => {
            if (str !== '') {
              str += ', ';
            }
            str += app;
            return str;
          };
          if (entryPointData.crossPromoReward.iapBonus) {
            resultStr = appendStr(resultStr, `iapBonus:${entryPointData.crossPromoReward.iapBonus}`);
          }
          if (entryPointData.crossPromoReward.reward) {
            const items = JSON.stringify(entryPointData.crossPromoReward.reward).replace('\\"', '"');
            resultStr = appendStr(resultStr, `reward:${items}`);
          }
          if (entryPointData.crossPromoReward.skipTutorial) {
            resultStr = appendStr(resultStr, `skipTutorial:${entryPointData.crossPromoReward.skipTutorial}`);
          }
          Object.assign(epParams, { xPromoBonus: resultStr });
        }
      }

      if (payload.fortuneCookie) {
        const fortuneCookiePayload = payload.fortuneCookie;
        // earlier payloads wont include the ID, so dont do anything if it doesnt
        if (fortuneCookiePayload.categoryData) {
          Object.assign(epParams, {
            epFortuneCookieMsgId: fortuneCookiePayload.categoryData.id + fortuneCookiePayload.msgData.id,
            epFortuneCookieCatId: fortuneCookiePayload.categoryData.id,
          });
        }
      }

      this._addItemParams(epParams, gifts);
      // append any items not formatted as gifts
      if (payload.life) epParams.epLives += 1;
    }
    // console.log("ENDPOINT TRACKING EPPARAMS = ", epParams);
    return epParams;
  }

  /**
   * add item param counts to the epParam Object
   * @param {Object} epParams
   * @param {Array} gifts
   */
  _addItemParams(epParams, gifts) {
    // create params for each item
    epParams.epCoins = 0;
    /* eslint-disable-next-line guard-for-in */ /* eslint-disable-next-line prefer-const */
    for (let i in this._itemNameMap) {
      epParams[this._itemNameMap[i]] = 0;
    }

    // assign item values
    for (let i = 0; i < gifts.length; i++) {
      const gift = gifts[i];
      if (gift.itemId === 'coin') {
        epParams.epCoins += parseInt(gift.amount);
        continue;
      } else if (this._itemNameMap[gift.itemId]) {
        epParams[this._itemNameMap[gift.itemId]] += parseInt(gift.amount);
      }
    }
  }
}

// create global references
if (!window.DDNA) window.DDNA = {};
DDNA.entryPointDataHelper = new DDNAEntryPointDataHelper();
