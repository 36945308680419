import TimeUtil from '@omt-components/Utils/TimeUtil';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../OMT_UI_SquareButton';

const ICON_PADDING = 5;

/**
 * Button for handling cooldown events with timers
 */
export class OMT_UI_ButtonWithCoolDown extends Phaser.Group {
  /**
   * constructor
   * @param {string} buttonName name / label
   * @param {string} cooldownId string
   * @param {number} cooldownDuration milliseconds
   * @param {string} friendId userId of friend
   * @param {number} width button width
   * @param {number} height button height
   * @param {Function} callback onClick callback
   * @param {string} fontStyle font style id
   */
  constructor(buttonName, cooldownId, cooldownDuration, friendId, width, height, callback, fontStyle = 'font-white', activeIcon = null, inactiveIcon = null) {
    super(game);

    this._activeButtonName = buttonName;
    this._cooldownId = cooldownId;
    this._cooldownDuration = cooldownDuration;
    this._friendId = friendId;
    this._callback = callback;

    this._initActiveStateButton(width, height, fontStyle, activeIcon);
    this._initCooldownStateButton(width, height, fontStyle, inactiveIcon);

    this._updateCooldownState(true);
  }

  /**
   * init the button for the active state
   * @param {number} width button width
   * @param {number} height button height
   * @param {string} fontStyle font style id
   * @param {Phaser.DisplayObject} icon (optionial) icon to display left of the text
   * @param {number} textOffsetY (optionial) vertical offset for text
   */
  _initActiveStateButton(width, height, fontStyle, icon = null, textOffsetY = 0) {
    // config for OMT_UI_SquareButton
    const buttonConfig = {
      button: {
        tint: BUTTONCOLOURS.blue,
        dimensions: { width, height },
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText(this._activeButtonName),
        textStyle: { style: fontStyle, fontSize: 25, align: 'center' },
        offset: { y: textOffsetY },
      },
      options: {
        clickFunction: {
          onClick: () => { this._callback(this); },
        },
      },
    };
    // add the icon to the config if passed
    if (icon) {
      buttonConfig.text.icon = {
        image: icon,
        offset: { x: ICON_PADDING },
        align: 'left',
      };
    }
    // create the button
    this._activeButton = new OMT_UI_SquareButton(0, 0, buttonConfig);
    this._activeButton.x = -width / 2;
    this.addChild(this._activeButton);
  }

  /**
   * init the button / graphics for the cooldown state
   * @param {number} width button width
   * @param {number} height button height
   * @param {string} fontStyle font style id
   * @param {Phaser.DisplayObject} icon (optionial) icon to display left of the text
   * @param {number} textOffsetY (optionial) vertical offset for text
   */
  _initCooldownStateButton(width, height, fontStyle, icon = null, textOffsetY = 0) {
    // create group for cooldown graphics
    const cooldownGroup = new Phaser.Group(game, this);
    cooldownGroup.x = -width / 2;

    // config for OMT_UI_SquareButton
    const buttonConfig = {
      button: {
        tint: BUTTONCOLOURS.gray,
        dimensions: { width, height },
        extraDetail: false,
      },
      text: {
        string: TimeUtil.getTimeCode(0),
        textStyle: { style: fontStyle, fontSize: 30, align: 'center' },
        offset: { y: textOffsetY },
      },
    };
    // add the icon to the config if passed
    if (icon) {
      buttonConfig.text.icon = {
        image: icon,
        offset: { x: ICON_PADDING },
        align: 'left',
      };
    }
    // create the button
    const button = new OMT_UI_SquareButton(0, 0, buttonConfig);
    button.input.enabled = false;
    cooldownGroup.addChild(button);
    this._cooldownButton = button;
    this._cooldownField = button.buttonText;
  }

  /**
   * toggle the visibility of the buttons based on the cooldown state
   * @param {boolean} isOnCooldown
   */
  _updateButtonState(isOnCooldown) {
    this._activeButton.visible = !isOnCooldown;
    this._cooldownButton.visible = isOnCooldown;
  }

  /**
   * start a cooldown
   */
  startCooldown() {
    G.saveState.setUserCooldown(this._cooldownId, this._friendId, this._cooldownDuration);
    this._updateCooldownState(true);
  }

  /**
   * set the button active state
   */
  _setActiveState() {
    this._isOnCooldown = false;
    this._cooldownButton.visible = false;
    this._activeButton.visible = true;
  }

  /**
   * set the button cooldown state
   */
  _setCooldownState() {
    this._isOnCooldown = true;
    this._cooldownButton.visible = true;
    this._activeButton.visible = false;
  }

  /**
   * update the cooldown state / timer
   * @param {boolean} forceUpdate (optional) default false
   */
  _updateCooldownState(forceUpdate = false) {
    const cooldownTimeRemaining = G.saveState.getUserCooldownRemaining(this._cooldownId, this._friendId);
    if ((forceUpdate || this._isOnCooldown) && cooldownTimeRemaining === 0) {
      this._setActiveState();
    } else if ((forceUpdate || !this._isOnCooldown) && cooldownTimeRemaining > 0) {
      this._setCooldownState();
    }

    if (this._isOnCooldown) {
      this._cooldownField.text = TimeUtil.getTimeCode(cooldownTimeRemaining);
    }
  }

  /**
   * enable interactivity
   */
  enable() {
    this._activeButton.enable();
  }

  /**
   * disable interactivity
   */
  disable() {
    this._activeButton.disable();
  }

  /**
   * main update function
   */
  update() {
    super.update();
    this._updateCooldownState();
  }

  /**
   * get the button name
   * @returns {string}
   */
  get buttonName() {
    return this._activeButtonName;
  }

  /**
   * get the cooldownId
   * @returns {string}
   */
  get cooldownId() {
    return this._cooldownId;
  }
}
