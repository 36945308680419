/**
 * class containing runtime spritesheet instances / drawing
 */
export default class RuntimeSpritesheet {
  /**
   * constructor
   * @param {string} spriteSheetId
   * @param {number} textureSize
   * @param {number} padding
   */
  constructor(spriteSheetId, textureSize = 4096, padding = 4) {
    this._spriteSheetId = spriteSheetId;
    this._textureSize = textureSize;
    this._initSpriteSheet();

    this._frameDataTable = {};
    this._padding = padding;
    this._drawX = this._padding;
    this._drawY = this._padding;
    this._maxDrawnY = 0;
  }

  /**
   * intialize the spritesheet RenderTexture
   */
  _initSpriteSheet() {
    this._renderTexture = new Phaser.RenderTexture(game, this._textureSize, this._textureSize, this._spriteSheetId);
  }

  /**
   * draw a frame to the RenderTexture and store its frameData
   * @param {string} frameId
   * @param {Phaser.Sprite} sourceSprite
   * @returns {Object} frameData Object
   */
  addFrame(frameId, sourceSprite) {
    // check if we need to go to the next row
    const padding = this._padding;
    if (this._drawX + sourceSprite.width >= this._textureSize) {
      this._drawX = padding;
      this._drawY = this._maxDrawnY + padding;
    }

    // framing parameters
    const bounds = sourceSprite.getBounds();
    const frameWidth = Math.ceil(sourceSprite.width);
    const frameHeight = Math.ceil(sourceSprite.height);
    const frameX = this._drawX;
    const frameY = this._drawY;

    // does not fit in texture
    if (frameX + frameWidth > this._textureSize || frameY + frameHeight > this._textureSize) {
      return null;
    }

    // draw the sprite to the RenderTexture
    this._renderTexture.renderRawXY(sourceSprite, frameX - bounds.x, frameY - bounds.y);

    if (this._drawY + frameHeight > this._maxDrawnY) this._maxDrawnY = this._drawY + frameHeight;
    this._drawX += frameWidth + padding;

    // create sub-texture to contain framed RenderTexture
    const texture = new PIXI.Texture(this._renderTexture);

    // create frame for RuntimeSpritesheetSprite sprite
    const frame = new Phaser.Frame(
      0,
      frameX,
      frameY,
      frameWidth + Math.ceil(padding / 2),
      frameHeight + Math.ceil(padding / 2),
      frameId,
    );

    // create frameData for RuntimeSpritesheetSprite sprite
    const frameData = {
      frameId,
      texture,
      frame,
      transform: {
        x: sourceSprite.x,
        y: sourceSprite.y,
        angle: sourceSprite.angle,
        scale: [sourceSprite.scale.x, sourceSprite.scale.y],
        pivot: sourceSprite.pivot ? [sourceSprite.pivot.x, sourceSprite.pivot.x] : null,
        anchor: sourceSprite.anchor ? [sourceSprite.anchor.x, sourceSprite.anchor.y] : null,
      },
    };
    this._frameDataTable[frameId] = frameData;
    return frameData;
  }

  /**
   * get frame data for a drawn frame
   * @param {string} frameId
   * @returns {Object}
   */
  getFrameData(frameId) {
    return this._frameDataTable[frameId];
  }

  /**
   * destruction method
   */
  destroy() {
    this._renderTexture.destroy();
  }
}
