/* eslint-disable no-param-reassign */
// import { EXTRA_PAYLOADS_REWARDS } from '../../../Services/OMT/OMT_CrossPromo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';
import { Window } from '../../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

const WINDOW_OFFSET_Y = 70;

const CLOSE_BUTTON_X = 0;
const CLOSE_BUTTON_Y = 0;
const CLOSE_BUTTON_ROTATION = 0;

const PLAY_BUTTON_X = 0;
const PLAY_BUTTON_Y = 0;

/**
 * Class for fullscreen cross promo overlay
 */
class Window_CrossPromoOverlay extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} crossPromoData
   * @param {Object} assetsData
   * @param {string} trackingName
   * @param {Function} callback
   */
  constructor(parent, crossPromoData, assetsData, trackingName, callback = null) {
    super(parent);

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._crossPromoData = crossPromoData;
    this._targetedAppID = crossPromoData.targetedApp.id;
    this._trackingName = trackingName;
    this._callback = callback;
    this._extraGoodies = undefined;

    this._initLayout();

    this._loadPromoAssets(assetsData);

    this._initButtons();

    if (this._crossPromoData.extraPayload) {
      this._drawExtraPayloadData(this._crossPromoData.extraPayload);
    }
  }

  /**
   * init window layout items
   */
  _initLayout() {
    // offset for world scene only
    if (game.state.getCurrentState().key === 'World') this.y += WINDOW_OFFSET_Y;
  }

  /**
   * init window buttons
   */
  _initButtons() {
    // close button
    this._closeBtn = new G.Button(CLOSE_BUTTON_X, CLOSE_BUTTON_Y, 'btn_x', () => {
      this.closeWindow(() => {
        if (this._callback) this._callback();
      });
    }, this);
    this._closeBtn.y = (this._closeBtn.height * (3 / 4)) - (game.height / 2);

    if (this._isLandscape) {
      this._closeBtn.scale.setTo(this._gameScale);
    }

    if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
      this._closeBtn.x = (game.width / 2) - (this._closeBtn.width * (3 / 4));
    } else {
      this._closeBtn.x = 500 - (this._closeBtn.width * (3 / 4));
    }
    this.registerButtons(this._closeBtn);
    this._closeBtn.rotation = CLOSE_BUTTON_ROTATION;

    // play promo game buttons
    const onCrossAccept = () => {
      this._onCrossPromoAccept();
    };
    this._promoBtn = new OMT_UI_SquareButton(PLAY_BUTTON_X, PLAY_BUTTON_Y, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: this._isLandscape ? 196 * this._gameScale : 196,
          height: this._isLandscape ? 100 * this._gameScale : 100,
        },
      },
      text: {
        string: OMT.language.getText('Play'),
        textStyle: { style: 'font-white', fontSize: 48 },
      },
      options: {
        clickFunction: {
          onClick: onCrossAccept.bind(this),
        },
        pulse: 1.1,
      },
    });
    this._promoBtn.y = ((game.height * 0.9) - this._promoBtn.height) / 2;

    this.addChild(this._promoBtn);
    this.registerButtons(this._promoBtn);
  }

  /**
   * load the cross promo assets
   * @param {Object} assetsData
   */
  _loadPromoAssets(assetsData) {
    this._img = G.makeExtImage(0, 0, assetsData.src, '', 0.5, this, false, (img) => {
      const landscapeScaleFactor = this._isLandscape ? 0.85 : 1;
      let desiredScale = (game.height / img.height) * landscapeScaleFactor;
      img.scale.set(desiredScale);
      if (img.width > game.width) {
        img.scale.set(landscapeScaleFactor);
        desiredScale = (game.width / img.width);
        img.scale.set(desiredScale);
      }

      img.alpha = 0;
      this._drawExtraText();
      game.add.tween(img).to({ alpha: 1 }, 250, Phaser.Easing.Quadratic.Out, true);
    });
    this._img.alpha = 0;

    this.addChildAt(this._img, 1);
  }

  /**
   * on cross promo user activation
   */
  async _onCrossPromoAccept() {
    // track promo clicked
    const trackingEventName = OMT.platformTracking.Events.XPromoClicked.replace('%id%', this._trackingName);
    OMT.platformTracking.logEvent(trackingEventName);

    this._promoBtn.stopPulse();
    this.showOverlay();

    // eslint-disable-next-line no-unused-vars
    await OMT.crossPromo.switchToAnotherGame(this._crossPromoData, this._extraGoodies);
    this.hideOverlay();
  }

  /**
   * Draws stuff that corresponds to the type of extra payload data coming in.
   * LanguageObject of the text is an object with the same keys as the localization file
   * @param {{type:string, text:LanguageObject, position:{x:number, y:number}, targetGame:string, reward:Array<{itemId:string, amount:number}>, iapBonus?:number}} data
   */
  _drawExtraPayloadData(data) {
    // const payloadTypeIsActive = Object.keys(EXTRA_PAYLOADS_REWARDS).filter((key) => EXTRA_PAYLOADS_REWARDS[key] === data.type);
    // if (payloadTypeIsActive.length === 0) { return; }

    const givenText = JSON.parse(data.text);
    const pos = JSON.parse(data.position) || { x: 0, y: 0 };
    let textString = givenText[OMT.language.lang];
    if (!textString) {
      textString = givenText.en;
    }
    this._textData = {
      x: pos.x,
      y: pos.y,
      text: textString.replace('%TARGETGAME%', data.targetGame),
    };
    this._drawExtraText();

    this._extraGoodies = {};
    switch (data.type) {
      // case EXTRA_PAYLOADS_REWARDS.IAPBONUS: this._extraGoodies.iapBonus = data.iapBonus; break;
      // case EXTRA_PAYLOADS_REWARDS.REWARD: this._extraGoodies.reward = JSON.parse(data.reward); break;
      // case EXTRA_PAYLOADS_REWARDS.SKIPTUTORIAL: this._extraGoodies.skipTutorial = 60; break;
      default: break;
    }
  }

  /**
   * Draws the extra text
   */
  _drawExtraText() {
    if (!this._textData) { return; }
    if (this._text) {
      if (this._text.parent) {
        this._text.parent.removeChild(this._text);
      }
      this._text.destroy();
    }
    this._text = new G.Text(this._textData.x, this._textData.y, this._textData.text, 'crossPromoTextStyle', 0.5, this._img.width * 0.8, 320, true, 'center');
    this._img.addChild(this._text);
  }

  /**
   * @returns {boolean} is the window fullscreen? (for low res landscape purposes)
   */
  get isFullscreen() {
    return true;
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.crossPromoOverlay = Window_CrossPromoOverlay;
