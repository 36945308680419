/**
 * class for controlling frame animation playback.
 */
export default class FrameAnimationController {
  /**
   * constructor
   */
  constructor() {
    this._active = false;
    this._currentIndex = 0;
    this._currentTimer = 0;
    this._timer = 3;
    this._loop = 0;
    this._maxFrame = 0;
    this._gfxName = '';
    this._onFrameCallback = {};
  }

  /**
   * init a frame animation
   * @param {Phaser.Sprite} target
   * @param {string} gfxName
   * @param {number} maxFrame
   * @param {number} timer in delta time seconds
   * @param {number} loop
   * @param {number} startingIndex
   * @param {Function} onComplete (optional)
   */
  init(target, gfxName, maxFrame, timer, loop, startingIndex, onComplete = null) {
    this._active = true;
    this._target = target;
    this._currentIndex = startingIndex || 0;
    this._currentTimer = 0;
    this._timer = timer;
    this._gfxName = gfxName;
    this._maxFrame = maxFrame || this._calculateMaxFrame(this._gfxName) - 1;
    this._loop = loop || 0;
    this._onComplete = null;
    G.changeTexture(this._target, gfxName + this._currentIndex);
    this._onComplete = onComplete;
  }

  /**
   * Inits the frame animation, but its async
   * @param {Phaser.Sprite} target
   * @param {string} gfxName
   * @param {number} maxFrame
   * @param {number} timer in delta time seconds
   * @param {number} loop
   * @param {number} startingIndex
   * @param {Function} onComplete (optional)
   */
  asyncInit(target, gfxName, maxFrame, timer, loop, startingIndex, onComplete = null) {
    return new Promise((resolve) => {
      const onCompleteResolving = () => {
        if (onComplete) {
          onComplete();
        }
        resolve();
      };
      this.init(target, gfxName, maxFrame, timer, loop, startingIndex, onCompleteResolving);
    });
  }

  /**
   * Updates the timer. Used for speeding things up
   * @param {number} newTimer
   */
  updateTimer(newTimer) {
    this._timer = newTimer;
  }

  /**
   * update frame animations
   */
  update() {
    if (!this._active) return;
    this._currentTimer += G.deltaTime;
    if (this._currentTimer >= this._timer) {
      this._currentIndex++;
      this._currentTimer -= this._timer;

      if (this._currentIndex > this._maxFrame) {
        if (this._loop === 0) {
          this._active = false;
          this._onFrameSequenceComplete();
          return;
        }
        this._loop--;
        this._currentIndex = 0;
      }
      G.changeTexture(this._target, this._gfxName + this._currentIndex);
      if (this._onFrameCallback[this._currentIndex]) {
        this._onFrameCallback[this._currentIndex]();
      }
    }
  }

  /**
   * on sequence complete
   */
  _onFrameSequenceComplete() {
    if (this._onComplete == null) return;
    const onComplete = this._onComplete;
    this._onComplete = null;
    this._target = null;
    onComplete();
  }

  /**
   * Stores the function to play on a specific frame
   * @param {number} frame
   * @param {Function} callback
   */
  addCallbackOnFrame(frame, callback) {
    this._onFrameCallback[frame] = callback;
  }

  /**
   * is this animation currently playing
   * @returns {boolean}
   */
  get isPlaying() {
    return this._active;
  }

  /**
   * Checks the cache for the total amount of sprites to the spritesheet
   * @param {string} assetName
   * @returns {number}
   */
  _calculateMaxFrame(assetName) {
    let len = 0;
    for (let i = 0; i < 1000; i++) {
      if (G.isImageInCache(`${assetName}${i}`)) {
        len++;
      } else {
        break;
      }
    }
    return len;
  }
}
