/* eslint-disable no-use-before-define */
import ComponentGameHooks from '../../ComponentGameHooks';
import { FX_EmitterSequencer } from '../particles/FX_EmitterSequencer';
import { FX_ParticleEmitterConfig } from '../particles/FX_ParticleEmitterConfig';

const DEFAULT_EMITTER_CONFIG = new FX_ParticleEmitterConfig({
  allowTransforms: false,
  maxParticles: 120,
  spawnRandomX: 5,
  spawnRandomY: 5,
  spritesheet: 'bursteffects',
  frames: ['glitter_1', 'glitter_2', 'glitter_3', 'glitter_4', 'glitter_5', 'glitter_6'],
  emitAngle: 0,
  emitAngleVariance: 2 * Math.PI,
  gravity: 6,
  friction: 0.6,
  minParticleVel: 25,
  maxParticleVel: 75,
  minSpawnDelay: 4,
  maxSpawnDelay: 6,
  minParticleLife: 2000,
  maxParticleLife: 3500,
  maxSpawnDuration: 1500,
});

const DEFAULT_SFX = 'boom';
const DEFAULT_SFX_VOLUME_RANGE = [0.005, 0.02];

/**
 * class for displaying / managing fireworks bursts. mostly an example for FX_EmitterSequence
 * @param {FX_ParticleEmitterConfig} emitterConfig
 */
export class FX_Fireworks extends FX_EmitterSequencer {
  /**
   * constructor
   * @param {Object} configOverride (optional) parameter object overwrites for DEFAULT_EMITTER_CONFIG
   * @param {string} soundFX sound fx id to play
   * @param {Array} sfxVolumeRange range for random sfx volume [min, max]
   */
  constructor(configOverride = {}, soundFX = DEFAULT_SFX, sfxVolumeRange = DEFAULT_SFX_VOLUME_RANGE) {
    super({ ...DEFAULT_EMITTER_CONFIG, configOverride });
    this._soundFX = soundFX;
    this._sfxVolumeRange = sfxVolumeRange;
  }

  /**
   * add a firework emitter
   * @param {number} emitX
   * @param {number} emitY
   */
  _addFireworkEmitter(emitX, emitY) {
    super._addFireworkEmitter(emitX, emitY);
    this._playSoundFX();
  }

  /**
   * play the soundFX for the burst if defined
   */
  _playSoundFX() {
    const soundFX = this._soundFX;

    if (soundFX != null) {
      const volume = this._sfxVolumeRange[0] + (Math.random() * (this._sfxVolumeRange[1] - this._sfxVolumeRange[0]));
      ComponentGameHooks.playSoundById(soundFX, volume);
    }
  }
}
