import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';

const width = 640;
const height = 1242;

const convertToPercentageX = (x) => (x / width) * 100;
const convertToPercentageY = (y) => (y / height) * 100;

const yOffset = 52;

export default [
  {
    texture: OMT_VILLAINS.getPrefixedName('villain_1_1'),
    destroyOnComplete: true,
    landscape: {
      baseWidth: width,
      baseHeight: height,
      scaleCoeff: {
        scale: 1.5,
      },
    },
    transforms: [
      // Initial transform
      {
        alpha: 1,
        x: convertToPercentageX(91),
        y: convertToPercentageY(186 + yOffset),
        callback: (object) => {
          object.scale.setTo(0.45);
          object.scale.x *= -1;
        },
      },
      {
        wait: 1500,
        callback: () => {
          G.sfx.exchange.play();
        },
      },
      {
        x: convertToPercentageX(270),
        y: convertToPercentageY(133 + yOffset),
        duration: 100,
      },
      {
        x: convertToPercentageX(450),
        y: convertToPercentageY(186 + yOffset),
        duration: 100,
      },
      {
        x: convertToPercentageX(718),
        y: convertToPercentageY(136 + yOffset),
        duration: 100,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('hard_corruption'),
    destroyOnComplete: true,
    landscape: {
      scaleCoeff: {
        scaleX: 3,
        scaleY: 2,
      },
    },
    transforms: [
      // Initial transform
      {
        x: convertToPercentageX(320),
        y: convertToPercentageY(564),
        scaleX: 1,
        scaleY: 1.4,
      },
      {
        y: convertToPercentageY(824),
        scaleY: 0.78,
        duration: 200,
      },
      {
        y: convertToPercentageY(1215),
        alpha: 0,
        scaleY: 0.45,
        duration: 300,
      },
    ],
  },
];
