/* eslint-disable no-unused-vars */

/**
 * Base scroll bar for the scroll window
 */
export default class OMT_UI_ScrollBarBase extends Phaser.Sprite {
  /**
   * constructor
   * @param {Phaser.Rectangle} scrollRect
   */
  constructor(scrollRect, scrollBarConfig) {
    super(game);

    this.signals = {
      onScrollChange: new Phaser.Signal(),
    };

    this._scrollRect = scrollRect.clone();
    this._config = scrollBarConfig;

    this._viewPercent = 1;

    // make knob / graphics
    this._scrollKnob = new Phaser.Sprite(game, null);
    this.updateScrollKnobGraphics(1);
    this.addChild(this._scrollKnob);

    //  Input Enable the sprite
    this._scrollKnob.inputEnabled = true;
    this._scrollKnob.input.useHandCursor = true;
    this._scrollKnob.input.enableDrag();
    this._scrollKnob.events.onDragStart.add(this._dragStart.bind(this));
    this._scrollKnob.events.onDragStop.add(this._dragStop.bind(this));
    this._scrollKnob.events.onDragUpdate.add(this._dragUpdate.bind(this));
    this._dragActive = false;
  }

  /**
    * get the scrollbar config
    * @returns {OMT_UI_ScrollBarConfigBase}
    */
  get config() {
    return this._config;
  }

  /**
   * on drag start
   */
  _dragStart() {
    this._dragActive = true;
  }

  /**
   * on drag end
   */
  _dragStop() {
    this._dragActive = false;
  }

  /**
   * on drag update
   * @param {Phaser.Sprite} sprite
   * @param {Object} pointer
   * @param {number} dragX
   * @param {number} dragY
   * @param {Object} snapPoint
   */
  _dragUpdate(sprite, pointer, dragX, dragY, snapPoint) {
    // override
  }

  /**
   * check if dragging is currently active.
   * @returns {boolean}
   */
  get dragActive() {
    return this._dragActive;
  }

  /**
   * update the scrollKnob graphics
   * @param {number} viewPercent 0-1
   */
  updateScrollKnobGraphics(viewPercent) {
    // override
  }

  /**
   * set the scroll percent / position
   * @param {number} percent 0-1
   * @param {boolean} dispatchEvent
   */
  setScrollPercent(percent, dispatchEvent = true) {
    // override
  }

  /**
   * get the scroll percent / position
   * @returns {number} 0-1
   */
  getScrollPercent() {
    // override
  }

  /**
   * phaser update method
   */
  updateEnabledState() {
    // override
  }

  /**
   * enable / disable auto hiding of the scrollbar
   * @param {boolean} value
   */
  set autoHide(value) {
    this._autoHide = value;
  }

  /**
  * destruction method
  */
  destroy() {
    this.signals.onScrollChange.dispose();

    this._scrollKnob.input.destroy();
    this._scrollKnob.events.onDragStart.dispose();
    this._scrollKnob.events.onDragUpdate.dispose();

    this._scrollKnob.graphics.destroy();
    super.destroy();
    this.removeChildren();
  }
}
