/* eslint-disable no-unused-vars */
import { CandyType_Basic } from './CandyType_Basic';

/**
 * class for TreasureHunt CandyType
 */
export class CandyType_TreasureHunt extends CandyType_Basic {
  /**
   * on candy hit by adjacent match.
   * @returns {boolean}
   */
  onHit() {
    this._onSuccessfulBreak();
  }

  /**
   * on booster used on candy.
   */
  onBooster() {
    this._gameHooks.playSound('boom');
  }

  /**
   * Animation plays on successful break
   * @returns {null}
   */
  _onSuccessfulBreak() {
    const state = game.state.getCurrentState();
    if (!state.treasureHuntCounter) { console.warn('Level is not Treasure Hunt'); return; }
    const { board } = this._candy;
    board.gameHooks.collectTreasureHuntToken(1);
    // this._fortuneCookieDataManager.incrementCollected(); // Tracking

    const pxBoard = board.cellToPxOut([this._candy.cellX, this._candy.cellY]);
    const copyCandy = G.makeImage(pxBoard[0], pxBoard[1], 'treasureHunt_token', 0.5); // The fortune cookie asset that flies away
    const originScale = board.scale.x;
    copyCandy.scale.set(originScale);
    const targetPos = copyCandy.parent.toLocal(state.treasureHuntCounter.icon, state.treasureHuntCounter.icon.parent);
    const targetEndLayer = board.candiesLayer.fxTopGroup; // The target end layer to go into
    const targetFortuneScale = 0.9;
    const modPointDrop = game.height / 2; // Mid point drop for bezier

    // Copying Taylor's amazing bezier path swoop aninmation
    const tweenTime = 1000;
    const m1 = { x: copyCandy.x, y: copyCandy.y + modPointDrop };
    const m2 = { x: targetPos.x, y: targetPos.y + modPointDrop };
    G.sb('fxTop').dispatch('burstConcreteAnim', this._candy, this._candy); // Break fx
    this.signals.removeCandy.dispatch(); // Cookie gone
    game.add.tween(copyCandy)
      .to({
        x: [copyCandy.x, m1.x, m2.x, targetPos.x], // Bezier Tweeeeeeeeeeeeeeeeeeeeen
        y: [copyCandy.y, m1.y, m2.y, targetPos.y],
      },
      tweenTime, Phaser.Easing.Sinusoidal.InOut, true).interpolation((v, k) => Phaser.Math.bezierInterpolation(v, k))
      .onComplete.add(() => {
        game.add.tween(copyCandy) // fade out
          .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
        this._gameHooks.playSound('pop');
        state.treasureHuntCounter.updateCounter();
        copyCandy.parent.removeChild(copyCandy);
        copyCandy.destroy();
      });
    game.add.tween(copyCandy.scale)
      .to({
        x: [copyCandy.scale.x, 2.5 * targetEndLayer.scale.x, targetFortuneScale * targetEndLayer.scale.x],
        y: [copyCandy.scale.y, 2.5 * targetEndLayer.scale.y, targetFortuneScale * targetEndLayer.scale.y],
      }, tweenTime, Phaser.Easing.Sinusoidal.InOut).start()
      .onComplete.add(() => { copyCandy.scale.set(targetFortuneScale); });
  }
}
