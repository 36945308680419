import OMT_VILLAINS from '../../../../../OMT_UI/OMT_Villains';
import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import { ORIENTATION } from '../../../../../Services/OMT/OMT_SystemInfo';

export default class UI_GoalPanelCollect extends Phaser.Group {
  /**
   * The panel for the goal screen when collecting a number of gems
   * @param {number} x
   * @param {number} y
   */
  constructor(x, y) {
    super(game);

    this.x = Math.floor(x);
    this.y = Math.floor(y);

    this.panels = []; // Accessed externally

    const collectGoals = G.Utils.clone(LvlDataManager.getInstance().collectGoals);
    this._makePanels(collectGoals);

    this._signalBindings = [
      G.sb('onTaskAmountChanged').add(this._updateDisplay, this),
      G.sb('onScreenResize').add(this._onScreenResize, this),
    ];
    this._onScreenResize();
  }

  /**
   * Destroy!
   */
  destroy() {
    for (const binding of this._signalBindings) binding.detach();
    this._signalBindings.length = 0;
    this.panels.forEach((panel) => {
      panel.destroy();
    });
    super.destroy();
  }

  /**
   * On resize
   */
  _onScreenResize() {
    const horizontal = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    if (horizontal) {
      this._refreshPanelsHorizontalPositions();
    } else {
      let width = 260;

      if (this.panels.length === 2) {
        width = 130;
      } else if (this.panels.length === 3) {
        width = 210;
      }

      let distance = 0;
      if (this.panels.length - 1) {
        distance = width / (this.panels.length - 1);
      }
      const startX = width * Math.sign(distance) * -0.5;

      this.panels.forEach((child, index) => {
        child.x = startX + (index * distance);
        child.y = 0;
        child.scale.setTo(0.5);
      });
    }

    this.panels.forEach((panel) => {
      if (horizontal) {
        panel.turnHorizontal();
      } else {
        panel.turnVertical();
      }
    });
  }

  /**
   * Gets a segment of the goal panel
   * @param {string} goalName
   */
  getGoalPanel(goalName) {
    for (let i = 0, len = this.panels.length; i < len; i++) {
      if (this.panels[i].goalName === goalName) {
        return this.panels[i];
      }
    }
    return null;
  }

  /**
   * Updates the goal number
   * @param {string} type
   * @param {number} change
   */
  _updateDisplay(type, change) {
    const panel = this.getGoalPanel(type);
    if (!panel.nr.alive) return;
    if (panel.nr.alive) {
      const newValue = parseInt(panel.nr.text) + change;
      panel.nr.setText(newValue);
      if (newValue === 0 && panel.nr.alive) {
        panel.checkmark.visible = true;
        panel.nr.destroy();
      }
    }
  }

  /**
   * Makes panel for goals
   * @param {number} x
   * @param {number} y
   * @param {string} name
   * @param {number} number
   * @param {number} scale
   */
  _makePanel(x, y, name, number, scale) {
    const gfxName = G.json['configs/gameplayGoals'][name].sprite;

    const panel = game.make.group();
    panel.x = Math.floor(x);
    panel.y = Math.floor(y);

    panel.scale.setTo(scale);
    panel.goalName = name;
    panel.amount = number;

    const panelNrStyle = this._getTextStyle();
    panel.nr = panel.add(new G.Text(38, 0, number.toString(), panelNrStyle, 0.5, 85));

    panel.img = G.makeImage(-40, 0, gfxName, 0.5, panel);
    panel.imgFade = G.makeImage(-40, 0, gfxName, 0.5, this);
    panel.imgFade.alpha = 0;

    panel.checkmark = G.makeImage(panel.nr.x, panel.nr.y, 'task_complete', [1, 0.5], panel);
    panel.checkmark.position = panel.nr.position;
    panel.checkmark.anchor = panel.nr.anchor;
    panel.checkmark.visible = false;

    panel.turnHorizontal = () => {
      panel.img.x = -40;
      panel.nr.x = 38;
      panel.nr.y = 0;
      panel.nr.anchor.setTo(0.5);
      panel.nr.cacheAsBitmap = false;
    };

    panel.turnVertical = () => {
      panel.img.x = -40;
      panel.nr.x = 38;
      panel.nr.y = 0;
      panel.nr.anchor.setTo(0.5);
      panel.nr.cacheAsBitmap = false;
    };

    panel.fadeAnim = () => {
      G.stopTweens(panel.imgFade);
      panel.imgFade.scale.setTo(0);
      panel.imgFade.alpha = 1;
      game.add.tween(panel.imgFade).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true);
      game.add.tween(panel.imgFade.scale).to({ x: 2, y: 2 }, 300, Phaser.Easing.Sinusoidal.Out, true);
    };

    this.add(panel);
    this.panels.push(panel);
  }

  _getTextStyle() {
    let panelNrStyle = G.OMTsettings.elements.UI_GoalPanelCollect.panelNr.style;
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const { isTokenEventLevel, isDailyChallengeLevel, isTreasureHuntLevel } = LvlDataManager.getInstance();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;

    if (isTokenEventLevel) {
      panelNrStyle = 'UIGoalPanelNr-tokenEvent';
    } else if (isTreasureHuntLevel) {
      panelNrStyle = 'UIGoalPanelNr-treasureHunt';
    } else if (isNotNormalLevel) {
      panelNrStyle = {
        style: useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_2') : OMT_VILLAINS.getPrefixedName('hard_2'),
        fontSize: 50,
      };
    }

    return panelNrStyle;
  }

  /**
   * Makes the panels based on number of goals
   * Highly inefficient
   * @param {Array<Array<string, number>>} objects
   */
  _makePanels(objects) {
    if (objects.length === 1) {
      if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
        this._makePanel(0, -25, objects[0][0], objects[0][1], 0.8);
      } else {
        this._makePanel(0, 0, objects[0][0], objects[0][1], 0.8);
      }
    }
    if (objects.length === 2) {
      this._makePanel(-30, 0, objects[0][0], objects[0][1], 0.6);
      this._makePanel(30, 0, objects[1][0], objects[1][1], 0.6);
    }
    if (objects.length === 3) {
      this._makePanel(-60, 0, objects[0][0], objects[0][1], 0.6);
      this._makePanel(0, 0, objects[1][0], objects[1][1], 0.6);
      this._makePanel(60, 0, objects[2][0], objects[2][1], 0.6);
    }
    if (objects.length === 4) {
      this._makePanel(-90, 0, objects[0][0], objects[0][1], 0.6);
      this._makePanel(-30, 0, objects[1][0], objects[1][1], 0.6);
      this._makePanel(30, 0, objects[2][0], objects[2][1], 0.6);
      this._makePanel(90, 0, objects[3][0], objects[3][1], 0.6);
    }
  }

  /**
   * Refreshes layout
   */
  _refreshPanelsHorizontalPositions() {
    const startingY = 20;
    const incrementsY = 90;

    if (this.panels.length === 1) {
      this.panels[0].x = 0;
      this.panels[0].y = 0;
      this.panels[0].scale.setTo(1);
    } else if (this.panels.length === 2) {
      this.panels[0].x = 0;
      this.panels[0].y = startingY - incrementsY / 2;
      this.panels[0].scale.setTo(1);
      this.panels[1].x = 0;
      this.panels[1].y = startingY + incrementsY / 2;
      this.panels[1].scale.setTo(1);
    } else if (this.panels.length === 3) {
      this.panels[0].x = 0;
      this.panels[0].y = startingY - incrementsY;
      this.panels[0].scale.setTo(1);
      this.panels[1].x = 0;
      this.panels[1].y = startingY;
      this.panels[1].scale.setTo(1);
      this.panels[2].x = 0;
      this.panels[2].y = startingY + incrementsY;
      this.panels[2].scale.setTo(1);
    } else if (this.panels.length === 4) {
      this.panels[0].x = 0;
      this.panels[0].y = startingY - (incrementsY * 1.5);
      this.panels[0].scale.setTo(1);
      this.panels[1].x = 0;
      this.panels[1].y = startingY - incrementsY / 2;
      this.panels[1].scale.setTo(1);
      this.panels[2].x = 0;
      this.panels[2].y = startingY + incrementsY / 2;
      this.panels[2].scale.setTo(1);
      this.panels[3].x = 0;
      this.panels[3].y = startingY + (incrementsY * 1.5);
      this.panels[3].scale.setTo(1);
    }
  }
}
