import { TournamentCompetitorAvatarBase } from '../../base/Tournament/TournamentCompetitorAvatarBase';

const AVATAR_SIZE = 100;
const AVATAR_FRAME_SIZE = 104;
const AVATAR_FRAME = 'competitor_frame';
const AVATAR_FRAME_POS = { x: 0, y: 0 };
const AVATAR_FRAME_ANCHOR = [0.5, 0.5];

/**
 * Custom Avatar for the leadboards
 */
export class TournamentCompetitorAvatarHorizontal extends TournamentCompetitorAvatarBase {
  /**
   * constructor
   * @param {string} imageURL
   * @param {number} imageSize (optional)
   */
  constructor(imageURL, imageSize = AVATAR_SIZE) {
    super(imageURL, imageSize);
  }

  /**
   * Sets the scale of the image
   * @param {number} imageSize
   */
  _setScale(imageSize) {
    this.scale.set(imageSize / AVATAR_SIZE);
  }

  /**
   * init the avatar frame
   */
  _initAvatarFrame() {
    super._initAvatarFrame({
      x: AVATAR_FRAME_POS.x,
      y: AVATAR_FRAME_POS.y,
      img: AVATAR_FRAME,
      anchor: AVATAR_FRAME_ANCHOR,
      size: AVATAR_FRAME_SIZE,
    });
  }
}
