G.HeartImage = function(x,y,count,infinite) {

  let group = game.make.group();
  group.x = x;
  group.y = y;

  let txt;
  if(infinite){

    let str = ``;
    let m = count % 60;
    let h = Math.floor(count / 60);
    if (h > 0) {
      str += `${h}h`;
    }
    if (h > 0 && m > 0) str += ' ';
    if (m > 0) {
      str += `${m}m`;
    }
    // draw infinite icon and text below about how long it lasts
    let heart = G.makeImage(0,0,'heart_unlimited',0.5,group);
    txt = new G.Text(-4, heart.height * 0.75,str, {
      style: "font-white",
      fontSize: "24px",
      stroke: "black",
      strokeThickness: 2
    }, 0.5);
    heart.scale.setTo(1.25);
  } else {
    let heart = new Phaser.Group(G.game, group);
    let shadowDistance = 3;
    let heartShadow = G.makeImage(shadowDistance, shadowDistance, "heart", 0.5, heart);
    heartShadow.tint = 0;
    heartShadow.alpha = 0.5;
    G.makeImage(0,0,'heart',0.5,heart);
    let lifeIconScale = 52/heart.height;
    heart.scale.set(lifeIconScale, lifeIconScale);
    txt = new G.Text(-2, 2,"+" + count.toString(), {
      style: "font-white",
      fontSize: "34px"
    }, 0.5, 50, 90, "center");
  }
  group.add(txt);

  return group;
};