G.WorldSidePanelMissionPanel = function(x, y, goalObj) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);

  this.bg = G.makeImage(0, 0, "score_popup", 0.5, this);

  this.icon = G.makeImage(0, 0, "candy_spiral", 0.5, this);
  this.desc = new G.Text(0, 0, "10/20 open", {
    style: "font-white",
    fontSize: 25,
  }, 0.5, this);
  this.add(this.desc);

  this.goalObj = goalObj;
};

G.WorldSidePanelMissionPanel.prototype = Object.create(Phaser.Group.prototype);

G.WorldSidePanelMissionPanel.prototype.injectGoal = function() {

};