/**
 * Sprite container for cropped render texture
 */
export default class RuntimeSpritesheetSprite extends Phaser.Sprite {
  /**
   * constructor
   * @param {Object} frameData
   */
  constructor(frameData) {
    super(game, 0, 0, frameData.texture);
    this.setFrame(frameData.frame);
    this._applyTransform(frameData.transform);
  }

  /**
   * apply transform Object passed in the frameData
   * @param {Object} transform
   */
  _applyTransform(transform) {
    this.x = transform.x;
    this.y = transform.y;
    this.scale.set(transform.scale[0], transform.scale[1]);
    if (transform.pivot) this.pivot.set(transform.pivot[0], transform.pivot[1]);
    if (transform.anchor) this.anchor.set(transform.anchor[0], transform.anchor[1]);
    this.angle = transform.angle;
  }
}
