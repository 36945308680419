/* eslint-disable prefer-arrow-callback */
G.DailyRewardWeek = function (x, y, currentDayIndex, anim, callback) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);

  this.days = [];

  this.init(currentDayIndex, anim, callback);
};

G.DailyRewardWeek.prototype = Object.create(Phaser.Group.prototype);

G.DailyRewardWeek.prototype.init = function (currentDayIndex, anim, callback) {
  const week = G.Helpers.dailyRewardMgr.getWeek();

  for (var i = 0; i < 7; i++) {
    let bgSprite;
    if (i === currentDayIndex) {
      bgSprite = 'current_day_box';
    } else if (i < currentDayIndex) {
      bgSprite = 'passed_day_box';
    } else {
      bgSprite = 'future_day_box';
    }
    bgSprite += i === 6 ? '_big' : '';

    const day = new G.DailyRewardDay(
      -140 + (i % 3) * 140,
      -135 + (Math.floor(i / 3) * 135),
      {
        gifts: week[i].getGifts(),
        boxIconConfig: week[i].getBoxIconConfig(),
        dayNr: i + 1,
        past: i < currentDayIndex,
        today: i === currentDayIndex,
        bgSprite,
        giftsVisible: i <= currentDayIndex,
      },
    );

    day.scale.setTo(0);
    game.add.tween(day.scale)
      .to({ x: 1, y: 1 }, 200, Phaser.Easing.Sinusoidal.Out, true, 50 + (i * 50));

    if (i === 6) day.x += 140;
    this.add(day);
    this.days.push(day);
  }

  game.time.events.add(50 + i * 50, function () {
    this.days.forEach(function (day) { day.checkedIconAnimation(); });
    if (callback) callback();
  }, this);
};
