import { CollapsedViewBase } from '../../Components/CollapsedView/CollapsedViewBase';
import { FriendsScoresCollapsedUserView, COIN_REQUEST_STATUS } from './FriendsScoresCollapsedUserView';

import {
  FRIENDS_PANEL_COLLAPSED_SCROLL_RECT,
  FRIENDS_PANEL_COLLAPSED_USER_VIEW_H_SPACING,
  FRIENDS_PANEL_COLLAPSED_OVERLAY_BG_RECT,
  FRIENDS_PANEL_COLLAPSED_OVERLAY_BG_COLOR,
} from '../../MainLeaderboardSettings';

/**
 * class for collapsed friends scores user list
 */
export class FriendsScoresCollapsed extends CollapsedViewBase {
  /**
   * main initialization function
   * @param {Array} userList friendData list
   * @returns {Promise}
   */
  async init(userList) {
    this.signals.onCoinRequestSuccess = new Phaser.Signal();

    // pass the userList and the configuration for the scroll area to super.init()
    const scrollRect = new Phaser.Rectangle(0, 0, FRIENDS_PANEL_COLLAPSED_SCROLL_RECT.width, FRIENDS_PANEL_COLLAPSED_SCROLL_RECT.height);
    const hSpacing = FRIENDS_PANEL_COLLAPSED_USER_VIEW_H_SPACING;
    await super.init(userList, scrollRect, hSpacing);
    this._scrollArea.x = FRIENDS_PANEL_COLLAPSED_SCROLL_RECT.x;
    this._scrollArea.y = FRIENDS_PANEL_COLLAPSED_SCROLL_RECT.y;

    // draw the overlay bg for buttons etc
    this._drawOverlayBGRect(FRIENDS_PANEL_COLLAPSED_OVERLAY_BG_RECT, FRIENDS_PANEL_COLLAPSED_OVERLAY_BG_COLOR);

    this.disable();
  }

  /**
   * intialize UserView instance
   * @param {Object} friendData
   * @returns {Phaser.Group}
   */
  _initUserViewInstance(friendData) {
    const userView = new FriendsScoresCollapsedUserView();
    userView.init(friendData);
    userView.signals.onCoinRequest.add(this._onCoinRequestStatusUpdate, this);
    return userView;
  }

  /**
   * lock the display while request is in progress
   * @param {FriendsScoresExpandedUserView} userView
   * @param {number} status
   */
  _onCoinRequestStatusUpdate(userView, status) {
    if (status === COIN_REQUEST_STATUS.REQUESTED) {
      this.disable();
    } else if (status === COIN_REQUEST_STATUS.SUCCESS) {
      this.enable();
      this.signals.onCoinRequestSuccess.dispatch(userView);
    } else if (status === COIN_REQUEST_STATUS.FAILED) {
      this.enable();
    }
  }
}
