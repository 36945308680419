/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */

import { Window_FillRateFallback } from './Window_FillRateFallback';
import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import TargetedOfferDataManager from '../../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';
import VillainsSpeech from '../../OMT_UI/Villains/VillainSpeech';
import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import { INTERSTITIAL_RULES } from '../../Services/OMT/ads/OMT_InterstitialAdRules';
// import { XPROMO_PLACEMENTS } from '../../Services/OMT/OMT_CrossPromo';

/**
 * Level failed window
 */
export class Window_LevelFailed extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} msg_payload
   */
  constructor(parent) {
    super(parent);

    OMT_VILLAINS.setLevelType(this.state.mode);
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const isDailyChallenge = this.state.mode === LevelType.CHALLENGE;
    const isTreasureHunt = this.state.mode === LevelType.TREASURE_HUNT;

    G.sb('onLevelFailed').dispatch();

    const shouldLoseMysteryStreak = [LevelType.CHALLENGE, LevelType.TREASURE_HUNT].indexOf(this.state.mode) === -1; // If not these
    if (G.MYSTERYGIFT && !G.lvlData.noPreBoosters && shouldLoseMysteryStreak) {
      G.saveState.mysteryGiftManager.finishStreak(G.lvlNr === G.saveState.getLastPassedLevelNr());
    }
    if (this.state.mode === LevelType.CHALLENGE) G.sb('onDailyChallengeFinished').dispatch();

    // Schedule a bot message to remind player when they get full lives if they don't have
    // unlimited lives or full lives
    if (G.saveState.getUnlimitedLivesSec() <= 0 && G.saveState.getLives() < G.json.settings.livesMax) {
      // Calculate time to 5 lives in seconds
      const timeToFullLives = G.saveState.getSecUntilFullLives();

      OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, 'FullLives', timeToFullLives, false);
    }

    this._initLayout();
    this._initButtons();

    if ((isNotNormalLevel || isDailyChallenge) && OMT.feature.isVillainsEnabled()) {
      let villainText = G.OMTsettings.elements.superhard.villain_speech.hard_lose;
      let villainPos = 'right';
      let villainIndex = 1;
      let villainSubIndex = 5;

      if (isSuperHardLevel || isDailyChallenge) {
        if (isDailyChallenge) {
          const rng = Math.round(Math.random() * 1000) % 3;
          villainText = G.OMTsettings.elements.superhard.villain_speech[`dailyChallengeFail${rng}`];
        } else {
          villainText = G.OMTsettings.elements.superhard.villain_speech.super_hard_lose;
        }
        villainPos = 'double';
        villainIndex = [2, 1];
        villainSubIndex = [5, 5];
      }

      this.villains = new VillainsSpeech(this, villainText, villainPos, villainIndex, villainSubIndex, undefined, 'bottom');
      const villainsClassContainer = this.villains.getClassContainer();

      // Reset villains scale in landscape
      if (this._isLandscape) {
        villainsClassContainer.scale.setTo(1);
      }

      this.onFinishedEnter.addOnce(() => {
        const yScale = this._isLandscape ? 0.4 : 0.6;
        villainsClassContainer.y = this.toLocal({
          x: 0,
          y: game.height - this.villains.getTallestObject({ type: 'villain' }).height * yScale,
        }).y;
        this.villains.show();
      });
    }
  }

  /**
   * init window layout
   */
  _initLayout() {
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const isDailyChallenge = LvlDataManager.getInstance().isDailyChallengeLevel;
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallenge;

    // background
    if (isNotNormalLevel || isDailyChallenge) {
      const targetWidth = (useSuperHardGraphics
        ? G.OMTsettings.elements.superhard.window_levelFailed.superHardWidth
        : G.OMTsettings.elements.superhard.window_levelFailed.hardWidth) * 0.4;
      const targetHeight = 1534 * 0.4;
      const targetImage = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_popup') : OMT_VILLAINS.getPrefixedName('hard_popup');
      const targetSlice = useSuperHardGraphics ? G.OMTsettings.elements.superhard.window_level.super_hard_popup : G.OMTsettings.elements.superhard.window_level.hard_popup;
      this.bg = new UI_NineSlice(0, 0, targetImage, targetWidth, targetHeight, targetSlice);
    } else {
      this.addBackground('popup_background_2');
    }

    if (isNotNormalLevel || isDailyChallenge) {
      this.bg.anchor.setTo(0.5);
      this.add(this.bg);

      const bannerTexture = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_header') : OMT_VILLAINS.getPrefixedName('hard_header');
      this.banner = G.makeImage(
        0, -275,
        bannerTexture,
        0.5, this,
      );
      this.banner.scale.setTo(0.4 * 1.33);
    }

    // level title text
    let levelTxtStyle = G.OMTsettings.global.window.mediumTitle.textStyle;
    let textMaxWidthMultiplier = 1;

    if (isNotNormalLevel || isDailyChallenge) {
      if (G.OMTsettings.elements.superhard.window_levelFailed) {
        if (G.OMTsettings.elements.superhard.window_levelFailed.levelTxtStyle) {
          levelTxtStyle = G.OMTsettings.elements.superhard.window_levelFailed.levelTxtStyle;
        }

        if (G.OMTsettings.elements.superhard.window_levelFailed.bannerTextMaxWidthMultipler) {
          textMaxWidthMultiplier = G.OMTsettings.elements.superhard.window_levelFailed.bannerTextMaxWidthMultipler;
        }
      }
    }

    const targetWidth = this.banner ? this.banner.width * textMaxWidthMultiplier : 380;
    this.levelTxt = new G.Text(0, -272,
      this.state.mode === LevelType.NORMAL ? (`${OMT.language.getText('Level')} ${G.lvl.lvlIndex + 1}`) : OMT.language.getText('Daily Challenge'),
      levelTxtStyle, 0.5, targetWidth);
    this.add(this.levelTxt);

    // styling for hard and super hard levels
    if (isNotNormalLevel && !isDailyChallenge) {
      this.levelTxt.y += 15;

      let hardWarningTxtContent = OMT.language.getText('Hard');
      let hardWarningTxtStyle = OMT_VILLAINS.getPrefixedName('hard_1');
      if (isSuperHardLevel) {
        hardWarningTxtContent = OMT.language.getText('Super Hard');
        hardWarningTxtStyle = OMT_VILLAINS.getPrefixedName('super_hard_1');
      }
      this.hardWarningTxt = new G.Text(0, -300, hardWarningTxtContent, {
        style: hardWarningTxtStyle,
        fontSize: '30px',
      }, 0.5, this.bg.width, this.bg.height, true, 'center');
      this.add(this.hardWarningTxt);
    }

    // failed graphic
    if (G.LIVES && this.state.mode === LevelType.NORMAL && this.state.removedLifeAtStart) {
      this.diamond = G.makeImage(0, -75, 'sad_gingy_heart_small', 0.5, this);
    } else {
      this.diamond = G.makeImage(0, -75, 'failed_dimond', 0.5, this);
    }

    // level goal
    let pointsTextStyle = {
      style: 'font-blue',
      fontSize: '50px',
    };
    if (isNotNormalLevel || isDailyChallenge) {
      if (useSuperHardGraphics) {
        pointsTextStyle = {
          style: OMT_VILLAINS.getPrefixedName('super_hard_4'),
        };
      } else { // Hard Level
        pointsTextStyle = {
          style: OMT_VILLAINS.getPrefixedName('hard_4'),
        };
      }
    }

    const { goalManager } = G.lvl;
    if (goalManager.isPointBased()) {
      this.add(new G.Text(0, 100, `${OMT.language.getText('points').toUpperCase()}:\n${G.lvl.points}/${goalManager.getPointTarget()}`, pointsTextStyle, 0.5, 380));
    } else {
      this._makeLevelFailedTaskCollectPanels(95);
    }
  }

  /**
   * init window buttons
   */
  _initButtons() {
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();

    // close button
    this.closeButton = new G.Button(250, -270, 'btn_x', () => {
      this.state.endPointCheckForAds(() => {
        G.sb('onStateChange').dispatch('World', undefined, () => {
          // this._showCrossPromo();
          G.saveState.disableDiscount();
          if (G.saveState.getLives() === 0 && G.saveState.getUnlimitedLivesSec() === 0) {
            G.sb('pushWindow').dispatch(['notEnoughLives'], false, G.WindowMgr.LayerNames.AboveHighScorePanel);
          }
        });
      }, INTERSTITIAL_RULES.EVERY_N);
    });

    if (isNotNormalLevel) {
      this.closeButton.x -= 30;
      this.closeButton.y += 30;
    }

    this.registerButtons(this.closeButton);

    // retry buttons
    if (this.state.mode === LevelType.NORMAL) {
      const onClick = () => {
        G.winsInRow = 0;
        G.saveState.resetAchievementWinCount();
        G.saveState.resetCWinCount();

        if (G.saveState.getLives() > 0 || G.saveState.getUnlimitedLivesSec() > 0) {
          this.closeWindow();
          this.state.endPointCheckForAds(() => {
            // this._showCrossPromo();
            const layerName = game.state.current === 'World' ? G.WindowMgr.LayerNames.AboveHighScorePanel : G.WindowMgr.LayerNames.Base;
            G.sb('pushWindow').dispatch(['level', G.lvl.lvlIndex, { showPointer: Math.random() < 0.5 }], false, layerName);
          }, INTERSTITIAL_RULES.EVERY_N);
        } else {
          this.closeWindow();
          this.state.endPointCheckForAds(() => {
            // this._showCrossPromo();
            G.sb('pushWindow').dispatch(['notEnoughLives', {
              openLevelPopUp: true,
              lvlIndex: G.lvl.lvlIndex,
              levelPopUpLayerName: G.WindowMgr.LayerNames.Base,
            }]);
          }, INTERSTITIAL_RULES.EVERY_N);
        }
      };
      this.retryBtn = new OMT_UI_SquareButton(5, 250, {
        button: {
          tint: BUTTONCOLOURS.orange,
          dimensions: {
            width: 196,
            height: 100,
          },
          extraDetail: false,
        },
        text: {
          string: OMT.language.getText('Retry'),
          textStyle: {
            style: 'font-white',
            fontSize: 50,
          },
        },
        options: {
          clickFunction: {
            onClick: onClick.bind(this),
          },
        },
      });

      if (isNotNormalLevel) {
        this.retryBtn.y -= 30;
      }

      this.registerButtons(this.retryBtn);
    } else {
      const continueClick = () => {
        this.state.endPointCheckForAds(() => {
          G.sb('onStateChange').dispatch('World', undefined, () => {
            // this._showCrossPromo();
          });
        }, INTERSTITIAL_RULES.EVERY_N);
      };
      this.continueBtn = new OMT_UI_SquareButton(0, 230, {
        button: {
          tint: BUTTONCOLOURS.green,
          dimensions: {
            width: 196,
            height: 100,
          },
        },
        text: {
          string: OMT.language.getText('Continue'),
          textStyle: {
            style: 'font-white',
            fontSize: 70,
          },
        },
        options: {
          clickFunction: {
            onClick: continueClick.bind(this),
          },
        },
      });
      this.registerButtons(this.continueBtn);

      if (isNotNormalLevel) {
        this.continueBtn.y -= 30;
      }

      if (game.incentivised()) {
        this.retryBtn = this._initRetryAdButton(120, this.continueBtn.y);
        this.continueBtn.x = this.retryBtn.x - (this.continueBtn.width) + (this.continueBtn.width / 8) - (this.retryBtn.width / 2);
      }
    }
  }

  /**
   * make level task panels
   * @param {number} y
   * @param {string} bgImage
   */
  _makeLevelFailedTaskCollectPanels(y, bgImage) {
    const { isHardLevel, isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const isDailyChallenge = this.state.mode === LevelType.CHALLENGE;

    let posX = [
      [0],
      [-85, 85],
      [-170, 0, 170],
      [-205, -65, 65, 205],
    ];

    if (isNotNormalLevel || isDailyChallenge) {
      posX = [
        [0],
        [-65, 65],
        [-150, 0, 150],
        [-185, -45, 45, 185],
      ];
    }

    if (isNotNormalLevel || isDailyChallenge) {
      this.taskBg = new UI_NineSlice(0, y, OMT_VILLAINS.getPrefixedName('text_background'), this.bg.width - 105, 75, {
        left: 30,
        right: 30,
        bottom: 15,
        top: 15,
      });
      this.taskBg.anchor.setTo(0.5);
      if (isHardLevel) {
        this.taskBg.tint = G.OMTsettings.elements.superhard.window_level.hard_tint;
      } else if (isSuperHardLevel || isDailyChallenge) {
        this.taskBg.tint = G.OMTsettings.elements.superhard.window_level.super_hard_tint;
      }
      this.add(this.taskBg);
    } else {
      const taskBgTexture = bgImage || 'popup_bigtext_backgr';
      this.taskBg = G.makeImage(0, y, taskBgTexture, 0.5, this);
    }

    this.panels = [];
    const goals = G.lvl.goal[1];
    const len = goals.length;
    for (let i = 0; i < len; i++) {
      if (this.state.topBar.goalPanel.panels[i].amount > 0) {
        const gfxName = G.json['configs/gameplayGoals'][goals[i][0]].sprite;
        const panel = G.makeImage(0, y,
          gfxName,
          0.5, this);
        G.makeImage(70, 0, 'task_fail', 0.5, panel);
        this.panels.push(panel);
      }
    }
    const nrOfPanels = this.panels.length;
    this.panels.forEach((panel, index) => {
      panel.x = Math.floor(posX[nrOfPanels - 1][index] - 25);
    });
  }

  /**
   * on retry ad shown
   */
  _retryAdSuccess() {
    if (this.state.mode === LevelType.CHALLENGE) {
      G.saveState.enableRewardedChallenge();
    }
    G.sb('pushWindow').dispatch(['dailyChallenge', G.saveState.getDailyChallengeLevel(), { showPointer: Math.random() < 0.5 }], false, 'retryLevel');
    this.closeWindow();
  }

  /**
   * init the retry ad button
   * @param {number} x
   * @param {number} y
   */
  _initRetryAdButton(x, y) {
    const onClick = () => {
      OMT.ads.showAd(
        G.BuildEnvironment.adPlacements.retryChallenge,
        this._retryAdSuccess.bind(this),
        () => {
          if (!G.IAP) { // no IAPS dont use ad fallbacks
            // eslint-disable-next-line no-new
            new Window_FillRateFallback(undefined, {
              placement: G.BuildEnvironment.adPlacements.retryChallenge,
              callback: this._retryAdSuccess.bind(this),
              context: this,
            });
          } else { // open ad fallback
            OMT.ads.showAdFallback(this._retryAdSuccess.bind(this));
          }
        },
      );
    };
    const group = this.add(game.make.group());
    group.position.setTo(x, y);
    group.btn = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.purple,
        dimensions: {
          width: 196,
          height: 100,
        },
        extraDetail: false,
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });
    group.add(group.btn);
    group.icon = G.makeImage(0, 0, 'btn-movie-icon', 0.5, this);
    group.add(group.icon);
    group.text = new G.Text(0, 0, OMT.language.getText('Retry'), 'window-white', 0.5, 150);
    group.text.setShadow(2, 3, 'rgba(0,0,0,0.5)', 0);
    group.add(group.text);
    G.centerElements2([group.icon, group.text], 5, -5);
    group.btn.width = group.icon.width + group.text.width + 5 + 80;
    group.btn.scale.x = Math.max(1, group.btn.scale.x);
    return group;
  }

  /**
   * Checks and see if the cross promo should show up
   */
  _showCrossPromo() {
    if (G.saveState.getLevelRetries(G.lvl.lvlIndex) % 3 === 0) {
      // const crossPromoData = OMT.crossPromo.getPromoByPlacementId(XPROMO_PLACEMENTS.AFTER_GAME_LOST);
      // OMT.crossPromo.showCrossPromoWindow('afterGameLosts', crossPromoData, null, true);
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.levelFailed = Window_LevelFailed;
