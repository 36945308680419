import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

class Window_ThanksForHelp extends Window {
  /**
   * constructor
   * @param {Object} layer
   * @param {Object} data
   */
  constructor(parent, data) {
    super(parent);

    this.data = data || {};

    this._initLayout();
  }

  /**
   * Creating and positioning layout elements.
   */
  _initLayout() {
    this.addBackground('popup_background_2');

    this.successTxt = new G.Text(0, -272, OMT.language.getText('You helped your friend'), {
      style: 'globalTitleStyle',
      fontSize: '50px',
    }, 0.5, 550);
    this.add(this.successTxt);

    this.shineBg = G.makeImage(0, 0, 'reward_BG', 0.5, this);

    this.thanksBubble = G.makeImage(0, -65, 'thanks_bubble', [0.5, 1], this);

    this.thanksTxt = new G.Text(0, -110, OMT.language.getText('Thanks!'), {
      style: 'font-white',
      fontSize: 35,
    }, 0.5, 180);
    this.add(this.thanksTxt);

    this.avatarFrame = G.makeImage(0, 20, 'friend_frame', 0.5, null);

    if (this.data.senderAvatar) {
      this.avatar = G.makeExtImage(0, 20, this.data.senderAvatar, 'avatar_m', 0.5, this, false, (image) => {
        image.width = this.avatarFrame.width;
        image.height = this.avatarFrame.height;
      });
      this.avatar.width = this.avatarFrame.width;
      this.avatar.height = this.avatarFrame.height;
    }
    this.addChild(this.avatarFrame);

    this.continueBtn = new OMT_UI_SquareButton(0, 235, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Great!'),
        textStyle: {
          style: 'font-white',
          fontSize: 45,
        },
      },
      options: {
        clickFunction: {
          onClick: () => { this.closeWindow(); },
        },
      },
    });

    this.registerButtons(this.continueBtn);
  }
}
// create global references
if (!window.Windows) window.Windows = {};
Windows.thanksForHelp = Window_ThanksForHelp;
