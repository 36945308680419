import UI_PointsCounterBase from '../base/UI_PointsCounterBase';

export default class UI_PointsCounterHorizontal extends UI_PointsCounterBase {
  /**
   *
   * @param {{points: (string|object), amount: (string|object)}} config
   */
  _createPointsCounter(config) {
    const pointTxt = new G.Text(0, 0, `${G.capitalize(OMT.language.getText('points'))}:`, config.points, 0.5, 200);
    this._pointsCounter = new G.Text(0, 40, '0', config.amount, 0.5, 200,
      null, true, 'center');
    this.add(pointTxt);
    this.add(this._pointsCounter);
  }

  /**
   * Returns updated text on update
   * @returns {string}
   */
  _getUpdatedText() {
    return OMT.language.toLocaleNumber(this._points);
  }

  /**
   * Repositions layout
   */
  _onScreenResize() {
    this._pointsCounter.updateText();
    this._pointsCounter.updateCache();
  }
}
