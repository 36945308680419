/* eslint-disable no-unused-vars */

import { MILLISECONDS_IN_DAY } from '@omt-components/Utils/TimeUtil';
import ShopUtils from '../../Elements/Windows/Shop/Shop_Utils';
import { FORTUNE_COOLDOWN_KEY } from './dataTracking/fortuneCookie/FortuneCookieDataManager';

/**
 * Class For checking feature status / flags
 */
export class OMT_FeatureFlags {
  /**
   * constructor
   */
  constructor() {
    this._feature_RequestHelp = false;
    this._feature_RequestMoveHelp = false;
    this._feature_giftingSupported = !OMT.systemInfo.deviceIsIOS;
    this._feature_FriendshipChest = G.featureUnlock.friendshipChest && G.featureUnlock.friendshipChest.enabled !== undefined ? G.featureUnlock.friendshipChest.enabled : false;
    this._asset_friendshipChest = {
      complete: !this.getFriendshipChestStory(),
      highPrio: !this.getFriendshipChestStory(),
    };
    this._feature_postcard = false;
    this._feature_newFTUX = G.featureUnlock.FTUE5_0 !== undefined ? G.featureUnlock.FTUE5_0 : true;
    this._feature_askForBotOptInAtLoad = G.featureUnlock.botOptInOnLoad !== undefined ? G.featureUnlock.botOptInOnLoad : false;
    this._feature_doubleCoinsAtEoL = false;
    this._feature_3hGift = true;
    this._feature_gameTriggeredMessages = {
      bot: G.featureUnlock.gameTriggeredMessages.botMessages || false,
    };
    this._feature_transactionTracking = {
      ...G.featureUnlock.transactionTracking,
    };
    this._asset_eventPostcard = false;
    this._feature_freePrizeWheelSpin = G.featureUnlock.freePrizeWheelSpin;
    this._feature_freeFailFlowWheelSpin = G.featureUnlock.freeFailFlowWheelSpin;
    this._feature_prizeWheelLimited = G.featureUnlock.limitSpins.prizeWheel;
    this._feature_lossAversionWheelLimited = G.featureUnlock.limitSpins.lossAversionWheel;
    this._feature_realMoneyPrizeWheel = G.featureUnlock.realMoneyPrizeWheel;
    this._feature_realMoneyHelperWheel = G.featureUnlock.realMoneyHelperWheel;
    this._feature_realMoneyTargetedOfferWheel = G.featureUnlock.realMoneyTargetedOfferWheel;
    this._feature_showBotOptInAfterTournament = G.featureUnlock.weeklyTournament.enabled;
    this._feature_multiplePreLevelBoosters = G.featureUnlock.multiplePreLevelBoosters;
    this._feature_villains = G.featureUnlock.villains;
    this._feature_delayedBoardAppereance = G.featureUnlock.delayedBoardAppereance;
    this._feature_milestoneTracking = G.featureUnlock.milestoneTracking;
    this._feature_multipleBoosterBuyingPanel = G.featureUnlock.multipleBoosterBuyingPanel;
    this._feature_tournamentPromo = G.featureUnlock.tournamentPromo;
    this._feature_dailyChallengePromo = G.featureUnlock.dailyChallengePromo;
    this._feature_additionalInterstitialRules = G.featureUnlock.additionalInterstitialRules.initial;
    this._feature_boosterLimitOn = G.featureUnlock.boosterLimiter;
    this._feature_noAdsPopup = G.featureUnlock.noAdsPopup;
    this._feature_annuity = G.featureUnlock.annuityPacks.enabled;
  }

  /**
   * Called at World.js to determine if requestHelp is active or not
   */
  checkFeatureRequestHelp() {
    return false;
  }

  /**
   * Called at various places to see if RequestHelp is active
   * @returns {boolean}
   */
  getFeatureRequestHelp() {
    return this._feature_RequestHelp;
  }

  /**
   * Called at World.js to determine if requestMoveHelp is active or not.
   */
  checkFeatureRequestMoveHelp() {
    if (OMT.systemInfo.deviceIsIOS) { this._feature_RequestMoveHelp = false; return; }
    if (G.featureUnlock.requestMoveHelp && G.featureUnlock.unlockLevels.requestMoveHelp) { // Checking necessary jsons exists
      // is the feature unlocked, and if we're passed the minimum required level number.
      this._feature_RequestMoveHelp = G.featureUnlock.requestMoveHelp && G.saveState.getLastPassedLevelNr() >= G.featureUnlock.unlockLevels.requestMoveHelp;
    }
  }

  /**
   * is request move help enabled
   * @returns {boolean}
   */
  getFeatureRequestMoveHelp() {
    return this._feature_RequestMoveHelp;
  }

  /**
   * check if 3 hour gift is enabled
   * @returns {Boolean}
   */
  getFeature3hGift() {
    return this._feature_3hGift;
  }

  /**
   * check if game-triggered bot messages are enabled
   */
  getFeatureGameTriggeredBotMessages() {
    return this._feature_gameTriggeredMessages.bot;
  }

  /**
   * check if transaction tracking is enabled for a particular list
   * if no list is specified, then checks if any of them are enabled
   * @param {string} list 'ss', 'rm', or 'inv'
   */
  getFeatureTransactionTracking(list) {
    if (!list) {
      return this._feature_transactionTracking.track.ss
      || this._feature_transactionTracking.track.rm
      || this._feature_transactionTracking.track.inv;
    }

    return this._feature_transactionTracking.track[list];
  }

  /*
  * check if transaction purging is enabled for a particular list
  * if no list is specified, then checks if any of them are enabled
  * @param {string} list 'ss', 'rm', 'inv', or 'crmInv'
  */
  getFeatureTransactionPurge(list) {
    if (!list) {
      return this._feature_transactionTracking.purge.ss
      || this._feature_transactionTracking.purge.rm
      || this._feature_transactionTracking.purge.inv
      || this._feature_transactionTracking.purge.crmInv;
    }

    return this._feature_transactionTracking.purge[list];
  }

  /**
   * true if the 3 hour gift should appear in the shop
   * @returns {Boolean}
   */
  is3hGiftInShop() {
    return G.OMTsettings.elements.Window_shop2 && G.OMTsettings.elements.Window_shop2.isShowing3hGift && G.IAP && !OMT.systemInfo.deviceIsIOS;
  }

  /**
   * check if the 3 hour gift is active for the current level state
   * @returns {boolean}
   */
  getFeature3hGiftActiveByLevel() {
    let result = false;
    if (this.getFeature3hGift()) {
      const unlockLevel = G.featureUnlock.unlockLevels.isShowing3hGift || Infinity;
      result = G.saveState.getLastPassedLevelNr() >= unlockLevel;
    }
    return result;
  }

  /**
   * checks if gifting is supported
   * @returns {boolean}
   */
  isGiftingSupported() {
    return this._feature_giftingSupported;
  }

  /**
   * checks if rewarded ads are available
   * @returns {boolean}
   */
  rewardedAdsAvailable() {
    return FBInstant.getSupportedAPIs().includes('getRewardedVideoAsync') && !OMT.systemInfo.browserIsIE;
  }

  /**
   * checks if interstitial ads are available
   * @returns {boolean}
   */
  interstitialAdsAvailable() {
    return FBInstant.getSupportedAPIs().includes('getInterstitialAdAsync') && !OMT.systemInfo.browserIsIE;
  }

  /**
   * checks if friendship chest is available
   * @returns {boolean}
   */
  getFeatureFriendshipChest(checkiOS = true) {
    if (checkiOS && OMT.systemInfo.deviceIsIOS) return false;
    return this._feature_FriendshipChest;
  }

  /**
   * Returns whether or not the friendship chest has the storymode feature or not
   * @returns {boolean}
   */
  getFriendshipChestStory() {
    return G.featureUnlock.friendshipChest.storyMode || false;
  }

  /**
   * get the friendship chest asset status
   * @returns {{complete:boolean, highPrio:boolean}} { complete, highPrio }
   */
  getFriendshipChestAssetStatus() {
    return {
      complete: this._asset_friendshipChest.complete,
      highPrio: this._asset_friendshipChest.highPrio,
    };
  }

  /**
   * set the friend chest asset status
   * @param {boolean} highPrioStat
   * @param {boolean} completeStat
   */
  setFriendshipChestAssetStatus(highPrioStat, completeStat = false) {
    this._asset_friendshipChest.complete = completeStat;
    this._asset_friendshipChest.highPrio = highPrioStat;
  }

  /**
   * check if we need to ask for bot-opt-in at game load
   * @returns {boolean}
   */
  getAskForBotOptInAtLoad() {
    return this._feature_askForBotOptInAtLoad;
  }

  /**
   * check if we need to ask for bot-opt-in after tournament
   * @returns {boolean}
   */
  getFTUBotOptInAtTournament() {
    const { isFirstTimeTournamentEntry } = OMT.platformTournaments;
    return isFirstTimeTournamentEntry && this._feature_showBotOptInAfterTournament;
  }

  /**
   * check if we are using the new FTUX
   * @returns {boolean}
   */
  getUseNewFTUX() {
    return this._feature_newFTUX;
  }

  /**
   * check if the friendship chest is unlocked for the current level state
   * @returns {boolean}
   */
  getFeatureFriendshipChestActiveByLevel(checkiOS = true) {
    let result = false;
    if (this.getFeatureFriendshipChest(checkiOS)) {
      const unlockLevel = G.featureUnlock.unlockLevels.friendshipChest || Infinity;
      result = G.saveState.getLastPassedLevelNr() >= unlockLevel;
    }
    return result;
  }

  /**
   * check if we should have the option to get double coins at the end of a level
   * @returns {boolean}
   */
  getDoubleCoinsAtEoL() {
    this._feature_doubleCoinsAtEoL = G.saveState.getLastPassedLevelNr() >= (G.featureUnlock.unlockLevels.coinDouble || 0);
    return this._feature_doubleCoinsAtEoL;
  }

  /**
   * check if fullscreen is supported, will only be true for OK.ru atm
   * @returns {boolean}
   */
  isFullScreenSupported() {
    return false;
  }

  /**
   * Check if we can show that extra continue button on Levels to give them a booster
   * in that level if they do
   */
  canLevelShowAdContinueBooster() {
    const boostersActive = G.saveState.mysteryGiftManager.getCurrentGifts().length === 0;
    const over40 = G.saveState.getLastPassedLevelNr() >= (G.featureUnlock.unlockLevels.preLevelBoosterAd || 40);
    const hasBoosters = G.saveState.getBoosterAmount(7) === 0 && G.saveState.getBoosterAmount(8) === 0;
    return boostersActive && over40 && hasBoosters;
  }

  /**
   * check if FTUX is enabled for the user
   * @returns {boolean}
   */
  isFTUXEnabled() {
    if (!this.getUseNewFTUX() || !G.OMTsettings.FTUXSettings.levelCutOff) return true;
    return G.saveState.getLastPassedLevelNr() < G.OMTsettings.FTUXSettings.levelCutOff;
  }

  /**
   * Checks if Mystery gift is available based on levels completed.
   * equalLevel will check equal to or higher than level, instead of just higher
   * @param {boolean} equalLevel
   * @returns {boolean}
   */
  getMysteryGiftUnlockByLevel(equalLevel = false) {
    if (equalLevel) return G.saveState.data.levels.length >= G.featureUnlock.unlockLevels.mysteryGift;
    return G.saveState.data.levels.length > G.featureUnlock.unlockLevels.mysteryGift;
  }

  /**
   * Returns boolean if the mystery gift is allowed to continue
   * @returns {Boolean}
   */
  isMysteryGiftIsAllowed() {
    return G.saveState.sessionData.ABCGroup ? G.saveState.sessionData.ABCGroup === 'Control' : true;
  }

  /**
   * Checks:
   * 1. Is the event enabled?
   * 2. Is there a time range to this event? If there is, is it within the event time?
   * @param {boolean} checkLevel
   * @returns {Promise<boolean>}
   */
  getEventPostcardFeature(checkLevel) {
    let event = G.featureUnlock.eventPostcard.enabled;
    if (G.featureUnlock.eventPostcard.range && event) {
      const { start } = G.featureUnlock.eventPostcard.range;
      const { end } = G.featureUnlock.eventPostcard.range;
      try {
        const today = OMT.connect.getServerTimestampSync();
        event = today > start && today < end;
      } catch (err) {
        event = false;
      }
    }
    if (checkLevel && event) {
      event = G.saveState.getLastPassedLevelNr() >= G.featureUnlock.unlockLevels.eventPostcard;
    }
    this._feature_postcard = event;
    return this._feature_postcard;
  }

  /**
   * Gets the status of the assets relating to the postcards
   * @returns {boolean}
   */
  getEventPostcardAssetStatus() {
    return this._asset_eventPostcard;
  }

  /**
   * Sets the status of the assets relating to the postcards
   * @param {boolean} complete
   */
  setEventPostcardAssetStatus(complete) {
    this._asset_eventPostcard = complete;
  }

  /**
   * Check if the fortune cookie event is currently active
   * @param {boolean} checkActiveTime
   */
  getFortuneCookieEvent(checkActiveTime = true) {
    let result = G.featureUnlock.fortuneCookie.enabled;
    if (result && checkActiveTime) {
      result = G.saveState.getUserCooldownRemaining(FORTUNE_COOLDOWN_KEY.COOLDOWN, '') === 0;
      if (result) {
        const today = new Date(OMT.connect.getServerTimestampSync());
        result = G.featureUnlock.fortuneCookie.activeDays.indexOf(today.getUTCDay()) > -1;
        if (result && G.featureUnlock.fortuneCookie.range) {
          const time = today.getTime();
          const start = G.featureUnlock.fortuneCookie.range.start || 0;
          const end = G.featureUnlock.fortuneCookie.range.end || 0;
          result = time > start && time < end;
        }
      }
    }
    return result;
  }

  /**
   * true if brag 2.0 flow is active
   * @returns {boolean}
   */
  isBrag2Active() {
    return G.featureUnlock.brag2.enabled;
  }

  /*
   * Returns whether the prize wheel has limited spins
   * @returns {boolean}
   */
  prizeWheelIsLimited() {
    return this._feature_prizeWheelLimited;
  }

  /**
   * Returns whether the loss aversion wheel has limited spins
   * @returns {boolean}
   */
  lossAversionWheelIsLimited() {
    return this._feature_lossAversionWheelLimited;
  }

  /**
   * Returns whether the prize wheel has free spins
   * @returns {boolean}
   */
  prizeWheelFreeSpins() {
    return this._feature_freePrizeWheelSpin;
  }

  /**
   * Returns whether the loss aversion wheel has free spins
   * @returns {boolean}
   */
  failFlowWheelFreeSpin() {
    return this._feature_freeFailFlowWheelSpin;
  }

  /**
   * Returns whether the real money replacement wheel is active
   */
  getFeatureRealMoneyReplacementWheel() {
    return this._feature_realMoneyPrizeWheel;
  }

  /**
   * Returns whether the real money helper wheel is active
   */
  getFeatureRealMoneyHelperWheel() {
    return this._feature_realMoneyHelperWheel;
  }

  /**
   * Returns whether the real money targeted offer wheel is active
   */
  getFeatureRealMoneyTargetedOfferWheel() {
    return this._feature_realMoneyTargetedOfferWheel;
  }

  /**
   * Checks if special deals for the shop is on.
   * It would be on if
   * - Its enabled
   * - The utc current day is the day its supposed to be active
   * - The utc current day is within the time range
   * @param {boolean} checkActiveTime
   */
  isSpecialShopDealsOn(checkActiveTime) {
    let result = G.featureUnlock.specialShopDeal.enabled;
    if (result && checkActiveTime) {
      const today = new Date(OMT.connect.getServerTimestampSync());
      result = G.featureUnlock.specialShopDeal.activeDays.indexOf(today.getUTCDay()) > -1;
      if (result && G.featureUnlock.specialShopDeal.range) {
        const time = today.getTime();
        const start = G.featureUnlock.specialShopDeal.range.start || 0;
        const end = G.featureUnlock.specialShopDeal.range.end || 0;
        result = time > start && time < end;
      }
    }
    return result;
  }

  /**
   *  Checks if player can buy lives when out of moves
   */
  outOfLivesCanBuyLives() {
    return G.featureUnlock.outOfLives_buyLife;
  }

  /**
   *  Checks if player can buy unlimited lives time when out of moves
   */
  outOfLivesCanBuyUnlimitedLives() {
    return G.featureUnlock.outOfLives_buyUnlimitedLives;
  }

  /**
   * Checks if multiple pre-level boosters are allowed on hard and super hard levels
   */
  multiplePreLevelBoostersAllowed() {
    return this._feature_multiplePreLevelBoosters;
  }

  /**
   * Checks whether super hard update is enabled
   * @returns {boolean} result
   */
  isVillainsEnabled() {
    return this._feature_villains;
  }

  /**
   * Checks if the delayed board appereance feature is enabled on hard and super hard levels
   */
  delayedBoardAppereanceEnabled() {
    return this._feature_delayedBoardAppereance;
  }

  /**
   * Checks if the token event is on.
   * Token event consists of:
   * - Token event level with a collectable token with leaderboard
   * - Unlockable postcards that are unlocked through collection of leaderboard
   * - Maybe shop
   * - Definitely a reskin
   *
   * This function checks:
   * 1. Is the event enabled?
   * 2. Is there a time range to this event? If there is, is it within the event time?
   * 3. Is it supposed to be unlocked through levels yet?
   * @param {boolean} checkRange
   * @param {boolean} checkLevel
   * @returns {boolean}
   */
  isTokenEventOn(checkRange, checkLevel) {
    let event = G.featureUnlock.tokenEvent.enabled;
    if (G.featureUnlock.tokenEvent.range && event && checkRange) {
      const { start } = G.featureUnlock.tokenEvent.range;
      const { end } = G.featureUnlock.tokenEvent.range;
      try {
        const today = OMT.connect.getServerTimestampSync();
        event = today > start && today < end;
      } catch (err) {
        event = false;
      }
    }
    if (event && checkLevel && checkRange) { // If ranged was checked and past level
      event = G.saveState.getLastPassedLevelNr() >= G.featureUnlock.unlockLevels.tokenEvent;
    }
    return event;
  }

  /**
   * Checks if the loyalty support is available
   * @returns {Boolean}
   */
  isLoyaltySupportAvailable() {
    return G.featureUnlock.allowLoyaltySupport;
  }

  /**
   * Checks if milestone tracking is on
   * @returns {Boolean}
   */
  isMilestoneTrackingOn() {
    return this._feature_milestoneTracking;
  }

  /**
   * Checks if tournament promo is on
   * @returns {boolean}
   */
  isTournamentPromoEnabled() {
    return this._feature_tournamentPromo;
  }

  /**
   * Checks if daily challenge promo is on
   * @returns {boolean}
   */
  isDailyChallengePromoEnabled() {
    return this._feature_dailyChallengePromo;
  }

  /**
   * Checks if the daily challenge is enabled
   * @param {boolean} checkVillians
   * @return {Boolean}
   */
  isDailyChallengeEnabled(checkVillians) {
    let res = G.featureUnlock.dailyChallenge.enabled;
    if (checkVillians) {
      res = res && G.saveState.villainsDataManager.getTutorialStatus('super_hard', 'post_win_saga_map');
    }
    return res;
  }

  /**
   * Checks whether the multiple booster panel is enabled
   * @returns {boolean}
   */
  isMultipleBoosterBuyingPanelEnabled() {
    return this._feature_multipleBoosterBuyingPanel.enabled;
  }

  /**
   * Checks if addition insterstitial ad rules are on
   * Includes EveryN, Out of Moves, Win 2 in a row, Give up
   * @returns {boolean}
   */
  useAdditionalInterstitialAdRules() {
    return this._feature_additionalInterstitialRules;
  }

  /**
   * Checks if the treasure hunt event is on
  * @param {boolean} checkActive
  * @param {boolean} checkLevel
  * @returns {boolean}
  */
  isTreasureHuntOn(checkLevel, checkActive, checkPrize, checkTokenEvent) {
    let result = G.featureUnlock.treasureHunt.enabled;
    if (checkPrize) {
      const awaitingLeaderboard = G.saveState.treasureHuntManager.lastExpiredLeaderboard;
      const awaitingPrize = G.saveState.treasureHuntManager.nextPrize;
      if (awaitingLeaderboard || awaitingPrize) {
        return true; // Don't check time or level. Theres prizes to be had!
      }
    }
    if (checkTokenEvent && this.isTokenEventOn(true)) {
      result = false;
    }
    if (result && checkLevel) {
      result = G.saveState.getLastPassedLevelNr() > G.featureUnlock.unlockLevels.treasureHunt;
    }
    if (result && checkActive) {
      result = result && G.saveState.treasureHuntManager.inActiveCycle;
    }
    return result;
  }

  /**
   * Checks if additional interstitial ad rules are on for
   * leaving the the tournament win screen
   * @returns {Boolean}
   */
  useAdditionalInterstitialTournamentWin() {
    let showAd = G.featureUnlock.additionalInterstitialRules.tournamentWin.enabled;
    const firstDay = G.saveState.getLoginStats();
    const day = Date.now() - firstDay.creation;

    showAd = showAd && (G.saveState.getLastPassedLevelNr() >= G.featureUnlock.additionalInterstitialRules.tournamentWin.level
        || Math.floor(day / MILLISECONDS_IN_DAY) > G.featureUnlock.additionalInterstitialRules.tournamentWin.playedFor);

    return showAd;
  }

  /**
   * Checks if additional interstitial ad rules are on for
   * picking up hourly gift, chest map gifts, chest shuffle gifts, saga map mailboxes gifts
   * @returns {Boolean}
   */
  useAdditionalInterstitialGiftAds() {
    let showAd = G.featureUnlock.additionalInterstitialRules.giftAds.enabled;
    const firstDay = G.saveState.getLoginStats();
    const day = Date.now() - firstDay.creation;

    showAd = showAd && (G.saveState.getLastPassedLevelNr() >= G.featureUnlock.additionalInterstitialRules.giftAds.level
        || Math.floor(day / MILLISECONDS_IN_DAY) > G.featureUnlock.additionalInterstitialRules.giftAds.playedFor);

    return showAd;
  }

  /**
   * Checks if booster limiter (excess boosters from chests -> coins) is on
   */
  isBoosterLimitOn() {
    return this._feature_boosterLimitOn;
  }

  /**
   * Checks if the no ads popup can be displayed
   */
  isNoAdsPopupOn() {
    return this._feature_noAdsPopup;
  }

  /**
   * Checks if the weekend deal is on.
   * Will always check the range
   * @returns {boolean}
   */
  isWeekendDealOn() {
    let result = G.featureUnlock.weekendShopDeal.enabled;
    if (result) {
      const { start: startDate, end: endDate } = ShopUtils.getStartAndEndTimeForWeekendDeal();
      const now = Date.now();
      result = result && now > startDate.getTime() && now < endDate.getTime();
    }
    return result;
  }

  /**
   * Returns true if annuity is enabled
   * @returns {boolean}
   */
  isAnnuityEnabled() {
    return this._feature_annuity;
  }
}
