/* eslint-disable no-unused-vars */
import { CandyType_Basic } from './CandyType_Basic';

/**
 * class collectable chest CandyType
 */
export class CandyType_Chest extends CandyType_Basic {
  /**
   * constructor
   * @param {BoardGameHooks} gameHooks
   */
  constructor(gameHooks) {
    super(gameHooks);
  }

  /**
   * on candy hit by adjacent match.
   * @returns {boolean}
   */
  onHit() {
    G.sb('onChestOpen').dispatch(this._candy, this.getEditorSymbol());
    this.signals.removeCandy.dispatch();
    return false;
  }
}
