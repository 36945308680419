import OMT_UI_ScrollBarConfigBase from '@omt-components/UI/Scroll/ScrollBarConfig/UI_ScrollBarConfigBase';

const DEFAULT_HEIGHT = 15;
const DEFAULT_MIN_MIDDLE_HEIGHT = 0;
const DEFAULT_WIDTH_RATIO = 1;
const DEFAULT_OFFSET_X = 0;

/**
 * class for configuring OMT_UI_HorizontalScrollBar
 */
export default class OMT_UI_HorizontalScrollBarConfig extends OMT_UI_ScrollBarConfigBase {
  /**
   * constructor
   * @param {number} height total height of scrollbar. result will be scaled to fit.
   * @param {number} cornerSize 9-scale corner size
   * @param {number} minMiddleHeight minimum height for middle for 9-scale. width will still be enforced by scale.
   * @param {number} widthRatio percentage should be 0-1
   * @param {number} offsetX value to offset the scrollbars x position, default 0
   * @param {number} tint tint to apply to the knob
   * @param {string} knobAssetFrame frame for knob asset
   * @param {boolean} overlapScrollArea set to false if you want to overlap the scrollbar in the content area
   * @param {string} scrollBackground some background used
   * @param {boolean} autoHide set if the scrollbar should auto hide content height is less then the view height
   */
  constructor(height, cornerSize, minMiddleHeight, widthRatio, offsetX, tint, depreciated_knobAssetSheet, knobAssetFrame, overlapScrollArea, scrollBackground, autoHide) {
    super(cornerSize, tint, depreciated_knobAssetSheet, knobAssetFrame, overlapScrollArea, scrollBackground, autoHide);
    this._height = height || DEFAULT_HEIGHT;
    this._minMiddleHeight = minMiddleHeight || DEFAULT_MIN_MIDDLE_HEIGHT;
    this._widthRatio = widthRatio || DEFAULT_WIDTH_RATIO;
    this._offsetX = offsetX || DEFAULT_OFFSET_X;
  }

  /**
   * set scroll bar height
   * @param {number} value
   */
  set height(value) {
    this._height = value;
  }

  /**
   * get scroll bar height
   * @returns {number}
   */
  get height() {
    return this._height;
  }

  /**
   * set minimum middle height
   * @param {number} value
   */
  set minMiddleHeight(value) {
    this._minMiddleHeight = value;
  }

  /**
   * get minimum middle height
   * @returns {number}
   */
  get minMiddleHeight() {
    return this._minMiddleHeight;
  }

  /**
   * set the % width of the scrollbar in relation to the scrollArea width
   * @param {number} value
   */
  set widthRatio(value) {
    this._widthRatio = value;
  }

  /**
   * get the % width of the scrollbar in relation to the scrollArea width
   * @returns {number} should be 0-1
   */
  get widthRatio() {
    return this._widthRatio;
  }

  /**
   * set the x offset value for the scrollbar
   * @param {number} value
   */
  set offsetX(value) {
    this._offsetX = value;
  }

  /**
   * get the x offset value for the scrollbar
   * @returns {number} can be negative or positive
   */
  get offsetX() {
    return this._offsetX;
  }
}
