import { SPECIAL_TYPES } from '../../BoardConstants';

/**
 * class for handling special types / modifier of candies.
 * These are children of the Candies essenitally.
 */
export class SpecialType {
  /**
   * constructor
   */
  constructor() {
    this._candy = null;
    this._config = null;

    this._defineSignals();
  }

  /**
   * init phaser signals
   */
  _defineSignals() {
    this.signals = {
      removeCandy: new Phaser.Signal(),
      changeCandyTexture: new Phaser.Signal(),
    };
  }

  /**
   * main intialization method
   * @param {Candy} candy
   * @param {Object} config
   * @param {boolean} imported (optional) created by import() function
   */
  init(candy, config, imported = false) {
    this._candy = candy;
    this._config = config;

    // update candy to special texture
    if (config.texture) {
      const spriteName = config.texture.replace('%CANDYTYPE%', this._candy.getBaseTypeSymbol());
      this.signals.changeCandyTexture.dispatch(spriteName);
    }

    // assign candy type from config
    if (config.candyType) {
      if (config.candyType === 'RANDOM') this._reportedCandyType = Math.random();
      else this._reportedCandyType = config.candyType;
    }

    this._tags = G.Utils.defined(config.tags, []);

    if (config.onMatchFx) this._onMatchFx = G.Utils.clone(config.onMatchFx);
    this._specialType = G.Utils.defined(config.specialType, null);

    this._activatedByMove = G.Utils.defined(config.activatedByMove, false);

    // handle collect event on special transformation
    if (imported && this._specialType === SPECIAL_TYPES.SPIRAL) {
      this._candy.baseType.markAsCollected();
    } else if (config.collectBaseType && !this._candy.baseType.isAlreadyCollected()) {
      G.sb('onCollectableRemove').dispatch(this._candy.getBaseTypeSymbol(), false);
      this._candy.baseType.markAsCollected();
    }

    // assign special execution type
    if (config.exe) this._exe = G.Utils.clone(config.exe);
  }

  /**
   * get type of special/
   * @returns {string}
   */
  getSpecialType() {
    return this._specialType;
  }

  /**
   * is blocking move for parent candy.
   * @returns {boolean}
   */
  isBlockingMove() {
    return false;
  }

  /**
   * is matchable along with parent.
   * @returns {boolean}
   */
  isMatchable() {
    return true;
  }

  /**
   * is the special activated by a move? (spiral for example).
   * @returns {boolean}
   */
  isActivatedByMove() {
    return this._activatedByMove;
  }

  /**
   * on parent candy moved.
   * @returns {boolean}
   */
  onMove() {
    return true;
  }

  /**
   * on hit by adjacent match.
   * @returns {boolean}
   */
  onHit() {
    return true;
  }

  /**
   * on parent candy part of a match.
   * @returns {boolean}
   */
  onMatch() {
    return true;
  }

  /**
   * start the match FX animation.
   */
  startMatchFx() {
    if (this._onMatchFx) {
      this._onMatchFx.forEach((child) => {
        G.sb('fx').dispatch(child[0], this._candy, child[1], this._candy);
      }, this);
    }
  }

  /**
   * return a match token override.
   * @param {string} token
   * @returns {string}
   */
  changeMatchToken(token) {
    return this._reportedCandyType || token;
  }

  /**
   * inherit matchable from candy.
   * @param {boolean} matchable
   * @returns {boolean}
   */
  passMatchable(matchable) {
    return matchable;
  }

  /**
   * get special execution chain
   * @returns {Array}
   */
  getExe() {
    return this._exe;
  }

  /**
   * sets the special execution chain
   * @param {Array} exe
   */
  setExe(exe) {
    this._exe = exe;
  }

  /**
   * get the special editor symbol.
   * @returns {string}
   */
  getEditorSymbol() {
    return this._editorSymbol;
  }

  /**
   * exports editor data.
   * @returns {string}
   */
  export() {
    return this._config.editorSymbol;
  }

  /**
   * Check if special has tag.
   * @param {string} tag
   * @returns {boolean}
   */
  hasTag(tag) {
    return this._tags.indexOf(tag) !== -1;
  }

  /**
   * destruction method
   */
  destroy() {
    Object.keys(this.signals).forEach((key) => { this.signals[key].dispose(); });
    this._candy = null;
  }
}
