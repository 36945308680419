import { DeconstructMsg } from './DeconstructMsg';
import { FX_Confetti } from '@omt-components/FX/confetti/FX_Confetti';

const SCORE_DURATION_PER_1000_POINTS = 25;
const SCORE_DURATION_BASE = 1500;
const SCORE_DURATION_MAX = 5000;
const PAUSE_DURATION = 3000;

/**
 * Tournament High Score text, shows on board.deconstruct()
 */
export class DeconstructMsgTournament extends DeconstructMsg {
  /**
   * constructor
   * @param {LvlDataManager} lvlDataManager
   * @param {number} score
   * @param {UI_ShoutOuts} shoutoutManager
   * @param {number} confettiLayerIndex
   */
  constructor(lvlDataManager, score, shoutoutManager, confettiLayerIndex) {
    super(lvlDataManager);

    this._shoutoutManager = shoutoutManager;
    this._shoutout = 'tier1_1'; // outstanding shoutout

    this._scoreValue = 0;
    this._scoreTargetValue = score;
    this._scoreAnimationDuration = SCORE_DURATION_BASE + Math.round((score / 1000) * SCORE_DURATION_PER_1000_POINTS);
    if (this._scoreAnimationDuration > SCORE_DURATION_MAX) this._scoreAnimationDuration = SCORE_DURATION_MAX;
    this._pauseDuration = PAUSE_DURATION + this._scoreAnimationDuration;

    this._initDeconstructShoutout();
    this._initTitleField();
    this._initScoreField(this._scoreValue);
    this._initConfetti(confettiLayerIndex);
  }

  /**
   * get the score animation duration
   * @returns {number}
   */
  get scoreAnimationDuration() {
    return this._scoreAnimationDuration;
  }

  /**
   * animate in the msg
   */
  animateIn() {
    super.animateIn();

    this._allTweens.push(game.add.tween(this.glowImg).to({ alpha: 0.2 }, 300, Phaser.Easing.Sinusoidal.Out, true));
    this.wellDoneTxt.visible = true;
    this.wellDoneTxt.scale.setTo(0);
    this._allTweens.push(game.add.tween(this.wellDoneTxt.scale).to({ x: 1, y: 1 }, 500, Phaser.Easing.Elastic.Out, true));

    // animate the score ticker
    if (this._scoreTween) this._scoreTween.stop();
    this._scoreTween = game.add.tween(this);
    this._scoreTween.to({ _scoreValue: this._scoreTargetValue }, this._scoreAnimationDuration, Phaser.Easing.Quadratic.InOut);
    this._scoreTween.onUpdateCallback(this._updateScore, this);
    this._scoreTween.onComplete.addOnce(this._updateScore, this);
    this._allTweens.push(this._scoreTween);
    this._scoreTween.start();

    this._shoutoutManager.playShoutoutAudio(this._shoutout);
  }

  _initDeconstructShoutout() {
    this.glowImg = G.makeImage(0, 0, 'popup_lighht', 0.5, this);
    this.glowImg.blendMode = 1;
    this.glowLoopTween = game.add.tween(this.glowImg).to({ angle: 360 }, 6000, Phaser.Easing.Linear.None, true).loop();
    this._allTweens.push(this.glowLoopTween);
    this.glowImg.alpha = 0;
    this.wellDoneTxt = game.add.image(0, 0, 'shoutouts', this.shoutout);
    this.wellDoneTxt.anchor.setTo(0.5);
    this.add(this.wellDoneTxt);
    this.wellDoneTxt.visible = false;
    this.wellDoneTxt.y -= 170;
    this.glowImg.y -= 170;
  }

  /**
   * init title field
   */
  _initTitleField() {
    this._titleField = new G.Text(0, -90, this._gameHooks.getText('New Highscore'), {
      style: 'font-dark-purple-white-shadow',
      fontSize: '50px',
    }, 0.5, 600, 200, false, 'center');
    this.addChild(this._titleField);
  }

  /**
   * init the score field
   * @param {number} score
   */
  _initScoreField(score) {
    this._scoreField = new G.Text(0, -20, score, {
      style: 'font-white',
      fontSize: '90px',
    }, 0.5, 500, 200, false, 'center');
    this.addChild(this._scoreField);
  }

  /**
   * @param {number} confettiLayerIndex
   */
  _initConfetti(confettiLayerIndex) {
    // celebration confetti particles
    const confetti = new FX_Confetti();
    confetti.x = 320; confetti.y = 120;
    game.world.addChildAt(confetti, confettiLayerIndex);
    this.signals.onAnimateInStart.add(() => { confetti.spawnActive = true; });
    this.signals.onAnimateOutStart.add(() => { confetti.spawnActive = false; });
  }

  /**
   * update the score display value
   */
  _updateScore() {
    this._scoreField.setText(Math.round(this._scoreValue));
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    if (this._scoreTween) this._scoreTween.stop();
  }
}
