import { ORIENTATION } from '../Services/OMT/OMT_SystemInfo';

G.UI_CoinCounter = function() {

	Phaser.Group.call(this,game);
	this.state = game.state.getCurrentState();

	if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
		this.bg = G.makeImage(0, 0, 'top-panel-coinsBg', 0.5, this);
		this.bg.scale.setTo(1.1);
		this.text = new G.TextCounter(25, 3, G.saveState.getCoins(), {
			style: "soloInstance-coinBar",
			fontSize: "40px"
		}, [0.5,0.5], 200);
		this.add(this.bg);
		this.add(this.text);
	} else {
		this.text = new G.TextCounter(0, 0, G.saveState.getCoins(), {
			style: "font-white",
			fontSize: "40px"
		}, [1,0.5], 200);
		this.add(this.text);
		this.ico = G.makeImage(0,0,'currency',[0,0.5],this);
	}

	this.alpha = 0;

	G.sb("onScreenResize").add(this.resize,this);
	this.resize();

	G.sb("onBoosterSelect").add(this.init,this);
	G.sb("closeOverlay").add(this.hide,this);

	G.sb("onCoinsChange").add(this.text.setAmount,this.text);

};

G.UI_CoinCounter.prototype = Object.create(Phaser.Group.prototype);

G.UI_CoinCounter.prototype.resize = function () {};

G.UI_CoinCounter.prototype.init = function (boosterData) {
  const { boosterNr } = boosterData;
  if (G.saveState.getBoosterAmount(boosterNr) <= 0) {
    this.alpha = 0;
    G.stopTweens(this);
    game.add
      .tween(this)
      .to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.Out, true);
    this.resize();
  }
};

G.UI_CoinCounter.prototype.hide = function () {
  G.stopTweens(this);
  game.add
    .tween(this)
    .to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.Out, true);
};
