import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';
import OMT_UI_VerticalScrollArea from '../../Scroll/OMT_UI_VerticalScrollArea';
import { SpecialEvent_LeaderboardUserListPanel, SPECIALEVENT_LEADERBOARD_VIEWMODE } from './SpecialEvent_LeaderboardUserListPanel';

const contentPadding = 45;

export default class SpecialEvent_LeaderboardUserListPanelScrollable extends SpecialEvent_LeaderboardUserListPanel {
  /**
   * constructor
   * @param {{offsetX: number, topOffset: number, additionalDelay: number, lineSpacing: number, userView: Object, userViewDivider: Object}} config
   * @param {SPECIALEVENT_LEADERBOARD_VIEWMODE} viewMode
   */
  constructor(config, viewMode) {
    super(config, viewMode);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._container = new Phaser.Group(game, null);
    while (this.children.length !== 0) {
      const child = this.children[0];
      this._container.addChild(child);
    }

    const gameHeight = this._isLandscape ? game.height / this._gameScale : game.height;
    this._scrollRect = new Phaser.Rectangle(0, 15, game.width, gameHeight - 385);
    this._scroll = new OMT_UI_VerticalScrollArea(this._scrollRect, true);
    this._scroll.extraHeight = contentPadding;
    this._scroll.allowOverScroll = true;
    super.addChild(this._scroll);
    this._container.y = contentPadding;
    this._scroll.content.addChild(this._container);
    this.disable();
  }

  /**
   * Circumvent the adding from the super into the container here
   * @param {Phaser.DisplayObject} obj
   */
  addChild(obj) {
    if (this._container) {
      this._container.addChild(obj);
    } else {
      super.addChild(obj);
    }
  }

  /**
   * Inits the leaderboards and/or sets up things for placement viewing
   * @param {Array} leaderboardEntries
   * @returns {null}
   */
  initUserViews(leaderboardEntries) {
    super.initUserViews(leaderboardEntries);

    if (this._viewMode !== SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL) {
      const lastEntry = this._userViews[this._userViews.length - 1];
      if (!lastEntry) return;

      // Prevent container size from shrinking when the last entry's tween is happening because the container technically shrunk
      const g = new Phaser.Graphics(game);
      g.beginFill(0, 0);
      g.drawRect(0, 0, lastEntry.width, lastEntry.height);
      g.endFill();
      g.x = lastEntry.x;
      g.y = lastEntry.y - lastEntry.height / 2;
      this._container.addChild(g);
    }

    this._visibleAmount = 0;
    for (const uv of this._userViews) {
      const targetY = this._scroll.content.y + uv.y;
      if (targetY > 0 && targetY < this._scrollRect.height) {
        this._visibleAmount++;
      }
    }
    this._visibleAmount = Math.ceil(this._visibleAmount / 2);
  }

  /**
   * FIX ME
   * Some reason this class's update is not being called!
   */
  update() {
    super.update();
    if (this._scroll) {
      this._scroll.update();
    }
  }

  /**
   * Enables scrolling
   */
  enable() {
    this._scroll.enable();
  }

  /**
   * Disables scrolling
   */
  disable() {
    this._scroll.disable();
  }

  /**
   * Scrolls the content to a specific rank.
   * The tweening is done when duration > 0. But it doesn't seem to work too well
   * @param {number} rank
   * @param {number} duration
   * @param {Phaser.Easing} ease
   */
  scrollToRank(rank, duration, ease) {
    const rankPos = Math.max(0, rank - this._visibleAmount) * this._lineSpacing;
    if (duration === 0) {
      this._scroll.jumpToContentPosition(rankPos);
    } else {
      const obj = {
        cur: this._scroll.content.y,
        val: rankPos,
      };
      const tw = game.add.tween(obj).to({ cur: obj.val }, duration, ease, true);
      const updateFunc = () => {
        this._scroll.jumpToContentPosition(obj.cur);
      };
      tw.onUpdateCallback(updateFunc.bind(this));
      tw.onComplete.add(updateFunc.bind(this));
    }
  }

  /**
   * Scrolls the panel to a specific Y pos
   * @param {number} y
   */
  _scrollToY(y) {
    this._scroll.jumpToContentPosition(y - (this._visibleAmount * this._lineSpacing));
  }

  /**
   * Initalizes the container to be centered to where we want to stay, before animating the users in
   * @param {Function} onComplete
   */
  animateIn(onComplete) {
    // let playerEntryStart = 0;
    if (this._viewMode === SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL) {
      const playerEntry = this._userViews.find((uv) => uv.isPlayer);
      this.scrollToRank(playerEntry.rank, 0);
    } else {
      this.scrollToRank(this._placementData.playerObj.fakeRank, 0);
      this._scroll.allowOverScroll = false; // Avoid clipping issue by turning it off
    }
    super.animateIn(onComplete, this._scroll.content.y + contentPadding, this._scrollRect.height + contentPadding);
  }

  /**
   * Wraps the oncomplete function in a function that turns overscroll back on.
   * Mostly done to prevent weird clipping issues
   * @param {Function} onComplete
   */
  animatePlayerToPlacement(onComplete) {
    const onAnimationFinish = () => {
      onComplete();
      this._scroll.allowOverScroll = true;
    };
    super.animatePlayerToPlacement(onAnimationFinish, this._scrollToY.bind(this));
  }
}
