/* eslint-disable no-unused-vars */
import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';
import { GameScaleController } from '../States/Scaling/GameScaleController';
import OMT_UI_SettingsMenu from '../OMT_UI/Menus/Settings/OMT_UI_SettingsMenu';
import UI_Life from './G.UI_Life';

export default class UI_MapPanel extends Phaser.Group {
  /**
   * The UI top bar that is shown on the top of the saga map
   * @param {boolean} orientationIsVertical
   */
  constructor(orientationIsVertical) {
    super(game);

    this._orientationIsVertical = orientationIsVertical;
    this.fixedToCamera = true;
    this.cameraOffset.x = Math.floor(game.width * 0.5);
    this._state = game.state.getCurrentState();
    this._signalTokens = [];

    this._topBarContainer = new Phaser.Group(game, this);
    this._drawBackground();
    this._drawStars();
    this._drawCoins();
    this._drawLifeUI();

    // init the settings menu
    this.initSettingsMenu();

    this._signalTokens.push(
      G.sb('onScreenResize').add(this.onResize, this),
      G.sb('onWindowOpened').add(this.lockInput, this),
      G.sb('onAllWindowsClosed').add(this.unlockInput, this),
      G.sb('onStateChange').add(this.lockInput, this),
      G.sb('onCoinsChange').add((coins) => {
        this.coinsTxt.setText(OMT.language.toLocaleNumber(coins));
      }, this),
    );

    // Downscale in landscape
    if (!orientationIsVertical) {
      this.scale.setTo(GameScaleController.getInstance().gameScale);
    }

    this.onResize();
  }

  destroy() {
    if (this._signalTokens) {
      for (const sig of this._signalTokens) {
        if (sig && sig.detach) { sig.detach(); }
      }
      this._signalTokens.length = 0;
    }
    super.destroy();
  }

  /**
   * Draws the background of the top bar container
   */
  _drawBackground() {
    this.bg = G.makeImage(0, 0, 'top-panel-bg', [0.5, 0], this._topBarContainer);

    this.bg.inputEnabled = true;

    if (!this._orientationIsVertical) { // Its horizontal
      this.bg.scale.set(1022 / this.bg.width, 103 / this.bg.height);
    }
  }

  /**
   * Draws the stars and text
   */
  _drawStars() {
    const starData = G.OMTsettings.elements.UI_MapPanel.starData[OMT.systemInfo.orientationKey];
    const starsAmount = (G.saveState.getAllStars() - (this._state.lastLevelData ? this._state.lastLevelData.starImprovement : 0));

    // Container that holds star icon
    this.starsIcon = new Phaser.Group(G.game, this._topBarContainer);

    // Container that holds the star + star shadow. Resized down to proper size
    const starContainer = new Phaser.Group(G.game, this.starsIcon);
    const shadowDistance = 6;
    const starShadow = G.makeImage(shadowDistance, shadowDistance, 'star', 0.5, starContainer);
    starShadow.tint = 0;
    starShadow.alpha = 0.3;
    G.makeImage(0, 0, 'star', 0.5, starContainer);
    const starScale = 50 / this.starsIcon.height;
    starContainer.scale.set(starScale, starScale);

    this.starsIcon.scale.set(starData.icon.size / this.starsIcon.height); // positioning and size
    this.starsIcon.x = starData.icon.x;
    this.starsIcon.y = starData.icon.y;

    // Stars text
    this.starsTxt = new G.Text(0, starData.text.y,
      starsAmount.toString(), {
        style: starData.text.style,
        fontSize: starData.text.size,
      }, [0, 0.5], 150);
    this.starsTxt.currentVal = (G.saveState.getAllStars() - (this._state.lastLevelData ? this._state.lastLevelData.starImprovement : 0));
    this._topBarContainer.addChild(this.starsTxt);

    // Alignment
    if (G.LIVES) {
      this._moveStarsTo(starData.xPos);
    } else {
      this._moveStarsTo(G.OMTsettings.elements.UI_MapPanel.moveTo.ifNotLifes.stars);
    }
  }

  /**
   * Draws the coins
   */
  _drawCoins() {
    const coinData = G.OMTsettings.elements.UI_MapPanel.coinData[OMT.systemInfo.orientationKey];

    this._coinShopButton = new UI_GButtonES6(coinData.position.x, coinData.position.y, null, () => {
      if (G.IAP) G.Helpers.pushOutOfCoinsPopUp(false, { event: OMT.platformTracking.Events.OpenShopInventory }, true);
    }, this);
    this._coinShopButton.scaleOnClick = false;
    this._coinShopButton.inputEnabled = G.IAP;
    this._topBarContainer.addChild(this._coinShopButton);

    // The coins image
    this.coinsBg = G.makeImage(0, 0, 'top-panel-coinsBg', 0.5, this._coinShopButton);
    this.coinsBg.scale.set(coinData.image.size / this.coinsBg.height);

    // Coins text
    this.coinsTxt = new G.Text(this.coinsBg.centerX + coinData.text.x + 15, coinData.text.y,
      OMT.language.toLocaleNumber(G.saveState.getCoins()), {
        style: coinData.text.style,
        fontSize: coinData.text.size,
      }, 0.5, coinData.text.maxWidth);
    this.coinsTxt.currentVal = G.saveState.getCoins() - (this._state.lastLevelData ? this._state.lastLevelData.reward : 0);
    this._coinShopButton.addChild(this.coinsTxt);

    // Plus button
    if (G.IAP) this._drawPlusIcon(coinData.plusIcon);
  }

  /**
   * Draws the plus icon that shows the shop
   * @param {{x: number, y: number, size: number}} iconData
   */
  _drawPlusIcon(iconData) {
    // Plus button image
    this.plusIcon = G.makeImage(iconData.x, iconData.y, 'btn_plus', 0.5, this);
    this.plusIcon.scale.setTo(iconData.size / this.plusIcon.height);
    this._coinShopButton.addChild(this.plusIcon);

    if (!game.incentivised() && !G.IAP) {
      this.plusIcon.visible = false;
      this._coinShopButton.disable();
    }

    this._signalTokens.push(G.sb('turnOnIAP').add(() => {
      this.plusIcon.visible = true;
      this._coinShopButton.enable();
    }, this));
  }

  /**
   * Draws the life part of the top bar
   */
  _drawLifeUI() {
    const lifeUIData = this._orientationIsVertical ? G.OMTsettings.elements.UI_Life.position.vertical : G.OMTsettings.elements.UI_Life.position.horizontal;
    this.lifeUI = new UI_Life(lifeUIData.x, lifeUIData.y, this._orientationIsVertical);
    this._topBarContainer.addChild(this.lifeUI);

    if (!G.LIVES) {
      this.lifeUI.visible = false;
    }
  }

  /**
   * initialize the expandable settings menu
   */
  initSettingsMenu() {
    const settingsData = G.OMTsettings.elements.UI_MapPanel.settingsMenu[OMT.systemInfo.orientationKey];
    const settingsMenu = new OMT_UI_SettingsMenu();
    settingsMenu.x = settingsData.x;
    settingsMenu.y = settingsData.y;
    settingsMenu.scale.set(settingsData.size / settingsMenu.height);
    this._topBarContainer.addChild(settingsMenu);
  }

  /**
   * Locks input
   */
  lockInput() {
    this.ignoreChildInput = true;
  }

  /**
   * Unlocks input
   */
  unlockInput() {
    this.ignoreChildInput = false;
  }

  /**
   * When the camera moves, it moves!
   */
  onResize() {
    this.cameraOffset.x = Math.floor(game.width * 0.5);
  }

  /**
   * Moves stars next to each other
   * @param {number} x
   */
  _moveStarsTo(x) {
    this.starsIcon.x = x;
    this.starsTxt.x = this.starsIcon.right + 8;
  }
}
