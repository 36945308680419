import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';
import { RenderTextureGenerator } from '@omt-components/Imaging/RenderTextures/RenderTextureGenerator';

export const GIFT_COLORS = {
  None: 0xFFFFFF,
  Black: 0,
  Green: 0x24FF18,
  Teal: 0x03FFA7,
  Purple: 0xC923E9,
  Red: 0xFF1646,
  Light_Blue: 0x02BBFF,
  Dark_Blue: 0x025DFF,
  Orange: 0xFF8502,
  Pink: 0xFF2FE9,
  Yellow: 0xfed500,
  Fish_Pink: 0xFB3DD7,
  Fish_Blue: 0x337DFF,
  Fish_Green: 0x00BEA8,
  Tales_Purple: 0x814CFF,
  Tales_Blue: 0x0C23FC,
  Tales_Light_Blue: 0x48A6FF,
  Tales_Pink: 0xfb3f99,
  Tales_Green: 0x0CFC33,
};

export class GiftGraphic extends UI_GButtonES6 {
  /**
   * A class to display a gift with configurable box and ribbon tint.
   @param {number} x
   @param {number} y
   * @param {{
    * giftType: 1 | 2,
    * boxTint:number,
    * ribbonTint:number,
    * highlight:boolean
    * isButton:boolean
    * callback:any
    * callbackContext:any,
    * useFullGift:boolean
   * }} config The config for the gift graphic.
   * @param {Phaser.Group} groupToAdd The group to add this graphic to.
   */

  constructor(x, y, config, groupToAdd) {
    super(x, y, null, config.callback, config.callbackContext);
    groupToAdd.addChild(this);

    this.config = config;
    this.anchor = { x: 0.5, y: 0.5 };

    // disable interactivity if not set as a button
    if (!this.config.isButton) this.disable();

    // create a container for all the elements
    this._buttonGraphics = new Phaser.Group(game, this);

    const boxTint = Number.isInteger(this.config.boxTint) ? this.config.boxTint : 0xFFFFFF;
    const ribbontTint = Number.isInteger(this.config.ribbonTint) ? this.config.ribbonTint : 0xFF0000;
    const fullGift = this.config.useFullGift ? 'full' : '';
    const modifier = this.config.giftType === 1 ? '' : '2';
    const textId = `giftGraphic${fullGift}-${modifier}${boxTint}${ribbontTint}`;
    let giftImage = RenderTextureGenerator.getRenderTextureSprite(textId);
    if (!giftImage) {
      const img = new Phaser.Group(game, null);
      const boxAssetStr = this.config.useFullGift ? 'gift_full' : `gift_box${modifier}`;
      const boxImg = new G.Image(0, 0, boxAssetStr, 0, img);
      boxImg.tint = boxTint;

      if (this.config.giftType === 1 && !this.config.useFullGift && G.OMTsettings.elements.gifts.useGiftTrim) {
        const extraDetailImg = new G.Image(0, 0, 'gift_trim', 0, img);
        extraDetailImg.tint = 0xFFA56E;
      }

      if (!this.config.useFullGift) {
        this.ribbonImg = new G.Image(0, 0, `gift_ribbon${modifier}`, 0, img);
        this.ribbonImg.tint = ribbontTint;

        if (this.config.highlight) {
          this.highlightImg = new G.Image(0, 0, `gift_highlight${modifier}`, 0, img);
        }
      }
      giftImage = RenderTextureGenerator.generateRenderTextureSprite(img, textId);
      img.destroy();
    }
    giftImage.anchor.set(0.5);
    this._buttonGraphics.addChild(giftImage);
  }
}
