/* eslint-disable no-undef */
import { OMT_DataCache } from './OMT_DataCache';
import { OMT_Utils } from './OMT_Utils';

export class OMT_DataCacheWithIO extends OMT_DataCache {
  /**
   * constructor
   */
  constructor(storageKey) {
    super();
    this._storageKey = storageKey;
    this._asyncWriteStatus = 0;
    this.restoreFromLocalStorage();
  }

  /**
   * clear all data and delete local storage
   */
  clearAllData() {
    super.clearAllData();
    this._clearFromLocalStorage();
  }

  /**
  * serialize data and write to local storage
  * @returns {boolean} success
  */
  writeToLocalStorage() {
    if (!this._storageKey) return false;
    try {
      localStorage.setItem(this._storageKey, JSON.stringify(this._cache));
      OMT_Utils.stylizedLog(`OMT_DataCache: [${this._storageKey}] written to local-storage`, '#00FF00');
      return true;
    } catch (e) {
      OMT_Utils.stylizedLog('OMT_DataCache: could not write data to the local-storage', '#FFFF00');
    }
    return false;
  }

  /**
  * restore data from local storage
  */
  restoreFromLocalStorage() {
    if (!this._storageKey) return;
    try {
      if (localStorage.getItem(this._storageKey) != null) this._cache = JSON.parse(localStorage.getItem(this._storageKey));
    } catch (e) { OMT_Utils.stylizedLog(`OMT_DataCache: could get local storage key '${this._storageKey}'`, '#FFFF00'); }
  }

  /**
  * clear local storage data
  */
  _clearFromLocalStorage() {
    if (!this._storageKey) return;
    try {
      localStorage.removeItem(this._storageKey);
    } catch (e) { OMT_Utils.stylizedLog(`OMT_DataCache: could not clear local storage key '${this._storageKey}'`, '#FFFF00'); }
  }
}
