import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';

export const BUTTONSTATE = { // The states for the button
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  DOWN: 'DOWN',
  UP: 'UP',
};

export const BUTTONCOLOURS = { // Available button colours for the square button. Use this instead of G.OMTsettings
  green: G.OMTsettings.elements.buttonTintColours.green,
  blue: G.OMTsettings.elements.buttonTintColours.blue,
  disabled: G.OMTsettings.elements.buttonTintColours.gray,
  gray: G.OMTsettings.elements.buttonTintColours.gray,
  orange: G.OMTsettings.elements.buttonTintColours.orange,
  purple: G.OMTsettings.elements.buttonTintColours.purple,
  red: G.OMTsettings.elements.buttonTintColours.red,
  lightGreen: G.OMTsettings.elements.buttonTintColours.lightGreen,
};

const allStates = [BUTTONSTATE.ENABLED, BUTTONSTATE.DISABLED, BUTTONSTATE.DOWN, BUTTONSTATE.UP]; // Possible states for the button to enter into
const tweenTime = 200; // Time for animations

/**
 * This is the Flexible Nine Slice Button made to replace all buttons in OMT
 * It was made because G.Button was difficult to use.
 * Hopefully this will be easier.
 * @author Sandra Koo
 */
export default class OMT_UI_SquareButton extends Phaser.Button { // eslint-disable-line camelcase
  /**
   * You're free to add your onClick functions to the signal after creation
   *
   * config = {
   *  button: {                                         // Button data
   *    dimensions: {width: number, height: number},    // The dimensions of the BUTTON and only the BUTTON
   *    tint: BUTTONCOLOURS,                            // The colour of the button indicated by BUTTONCOlOURS
   *    isEnabled: boolean,                             // Can it be clicked?
   *    extraDetail: boolean,                           // Should it show extra detail on the button?
   *  },
   *  text: {                                           // Text data in button
   *    string: string,                                 // String for the text
   *    textStyle: string || object,                    // The text style
   *    icon: {                                         // Icon data if you wish to put an image in the button. This doesn't work very well and needs lots of support with offset
   *      image: Phaser.DisplayObject,                  // The display of the icon
   *      align: "right" || "left",                     // The alignment the icon will be placed at
   *      offset: {x: number, y: number}                // Offset position for icon.
   *    },
   *    offset: {x: number, y: number},                 // Offset position for text.
   *    dimensionMods: {width: number, height: number},      // Modifies textwidth and height in relation to the button dimensions.
   *  },
   *  sound: {                                          // Sound data
   *    onClick: Sound                                  // The sound that will play
   *  }
   *  options: {                                        // Extra options
   *    clickFunction: {                                // Click data
   *      onClick:Function,                             // The important function
   *      disableAfterClick: boolean,                   // Will the button be disabled after click?
   *      scaleOnClick: boolean                         // Will the button grow when clicked?
   *    },
   *    pulse: number,                                  // If pulsing, pass in how large it'll grow
   *    cacheButton: boolean,                           // If true, the button is static
   *  }
   * }
   * @param {Object} config
   */
  constructor(x, y, config) {
    super(game, x, y, null);

    this.config = this.mergeConfig(config); // Merges passed in data with a default config
    this.button = undefined; // Supposed to be the button sprite
    this.buttonGroup = []; // All the button images together
    this.buttonImage = undefined; // The button image (with the tinting)
    this.buttonText = undefined; // The text on the button
    this.buttonIcon = undefined; // The icon on the button
    this.pulsing = false; // Pulsing state
    this.pulsingTween = undefined; // The tween for pulsing
    this.clickOutTimer = undefined; // The timer that triggers after a click
    this.buttonStateFuncs = []; // Button states
    this._currentSlice = undefined; // The slice used
    this._currentState; /* eslint-disable-line no-unused-expressions */ // Please don't touch- current state
    this.currentState; /* eslint-disable-line no-unused-expressions */ // The public version of current state
    this.signals = { // Signals
      onClick: new Phaser.Signal(),
    };

    if (this.config.options.clickFunction.onClick) { // If you passed in a func, I'll add it in
      if (this.config.options.clickFunction.context) {
        this.signals.onClick.add(this.config.options.clickFunction.onClick, this.config.options.clickFunction.context);
      } else {
        this.signals.onClick.add(this.config.options.clickFunction.onClick);
      }
    }

    this.initStates(); // Init button states
    this._drawButton(); // Draw it
    this._initClickHandler();
    this.currentState = this.config.button.isEnabled ? BUTTONSTATE.ENABLED : BUTTONSTATE.DISABLED; // Currently its...
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this.clickOutTimer) {
      game.time.events.remove(this.clickOutTimer);
      this.clickOutTimer = null;
    }
    if (this.pulsingTween) {
      this.pulsingTween.stop();
      this.pulsingTween = null;
    }

    for (const key in this.signals) {
      if (Object.hasOwnProperty.call(this.signals, key)) {
        this.signals[key].dispose();
      }
    }
    this.events.onInputDown.removeAll();

    super.destroy();
  }

  /**
   * Merges the config into another config with default values.
   * Default looks like this (copy and un-minify it)
   {button:{dimensions:{width:100,height:100},tint:BUTTONCOLOURS.disabled,isEnabled:true,extraDetail:true,},text:{string::'',textStyle:'font-white',icon:{image:null,align:'center',
   offset:{x:0,y:0,},},offset:{x:0,y:0,},dimensionMods:{width:0.7,height:0.7,},anchor:0.5,}, sound:{onClick:G.sfx.pop,},options:{clickFunction:{onClick:null,disableAfterClick:false,
   scaleOnClick:true,context:null,},pulse:0,cacheButton:true,},};
   * @param {Object} inConfig
   */
  mergeConfig(inConfig) {
    /* eslint-disable max-len */
    const isNotEmpty = (obj) => !(obj === null || obj === undefined);
    const defaultConfig = {
      button: {
        dimensions: (inConfig.button && inConfig.button.dimensions) ? { width: inConfig.button.dimensions.width, height: inConfig.button.dimensions.height } : { width: 100, height: 100 },
        tint: (inConfig.button && inConfig.button.tint) ? inConfig.button.tint : BUTTONCOLOURS.disabled,
        isEnabled: (inConfig.button && isNotEmpty(inConfig.button.isEnabled)) ? inConfig.button.isEnabled : true,
        extraDetail: (inConfig.button && isNotEmpty(inConfig.button.extraDetail)) ? inConfig.button.extraDetail : true,
      },
      // Text later, I need the height of the button first
      sound: {
        onClick: (inConfig.sound && inConfig.sound.onClick) ? inConfig.sound.onClick : G.sfx.pop,
      },
      options: {
        clickFunction: {
          onClick: (inConfig.options && inConfig.options.clickFunction && inConfig.options.clickFunction.onClick) ? inConfig.options.clickFunction.onClick : null,
          disableAfterClick: (inConfig.options && inConfig.options.clickFunction && isNotEmpty(inConfig.options.clickFunction.disableAfterClick)) ? inConfig.options.clickFunction.disableAfterClick : false,
          scaleOnClick: (inConfig.options && inConfig.options.clickFunction && isNotEmpty(inConfig.options.clickFunction.scaleOnClick)) ? inConfig.options.clickFunction.scaleOnClick : true,
          context: (inConfig.options && inConfig.options.clickFunction && inConfig.options.clickFunction.context) ? inConfig.options.clickFunction.context : null,
        },
        pulse: (inConfig.options && inConfig.options.pulse) ? inConfig.options.pulse : 0,
        cacheButton: (inConfig.options && isNotEmpty(inConfig.options.cacheButton)) ? inConfig.options.cacheButton : true,
      },
    };

    defaultConfig.text = {
      string: (inConfig.text && inConfig.text.string) ? inConfig.text.string : '',
      textStyle: (inConfig.text && inConfig.text.textStyle) ? inConfig.text.textStyle : { style: 'font-white', fontSize: Math.floor(defaultConfig.button.dimensions.height / 2) },
      icon: {
        image: (inConfig.text && inConfig.text.icon && inConfig.text.icon.image) ? inConfig.text.icon.image : null,
        align: (inConfig.text && inConfig.text.icon && inConfig.text.icon.align) ? inConfig.text.icon.align : 'center',
        offset: {
          x: (inConfig.text && inConfig.text.icon && inConfig.text.icon.offset && inConfig.text.icon.offset.x) ? inConfig.text.icon.offset.x : 0,
          y: (inConfig.text && inConfig.text.icon && inConfig.text.icon.offset && inConfig.text.icon.offset.y) ? inConfig.text.icon.offset.y : 0,
        },
      },
      offset: {
        x: (inConfig.text && inConfig.text.offset && inConfig.text.offset.x) ? inConfig.text.offset.x : 0,
        y: (inConfig.text && inConfig.text.offset && inConfig.text.offset.y) ? inConfig.text.offset.y : 0,
      },
      dimensionMods: {
        width: (inConfig.text && inConfig.text.dimensionMods && inConfig.text.dimensionMods.width) ? inConfig.text.dimensionMods.width : 0.7,
        height: (inConfig.text && inConfig.text.dimensionMods && inConfig.text.dimensionMods.height) ? inConfig.text.dimensionMods.height : 0.7,
      },
      anchor: 0.5,
    };

    return defaultConfig;
    /* eslint-enable max-len */
  }

  /**
   * Initializes the state functions
   */
  initStates() {
    this.buttonStateFuncs[BUTTONSTATE.ENABLED] = { // When the button is enabled
      onEnter: async () => {
        if (!this.game) { return; } // Return if game doesn't exist
        this.setColour(this.config.button.tint); // Set its colour
        if (this.config.options.pulse) { // Do the pulse?
          this.pulse(this.config.options.pulse);
        }

        this.inputEnabled = true; // Cursor things
        this.input.useHandCursor = true;
      },
      onExit: () => {
      },
    };
    this.buttonStateFuncs[BUTTONSTATE.DISABLED] = { // When the button is disabled
      onEnter: () => {
        if (!this.game) { return; } // Return if game doesn't exist
        this.adjustColour(BUTTONCOLOURS.disabled);
        if (this.pulsing) { // Stop pulsing
          this.stopPulse(1);
        }
        this.inputEnabled = false; // No cursor
      },
      onExit: () => {
      },
    };
    this.buttonStateFuncs[BUTTONSTATE.DOWN] = { // When the button was just clicked
      onEnter: () => {
        if (!this.game) { return; } // Return if game doesn't exist
        this.signals.onClick.dispatch(this); // Dispatch
        if (this.config.sound.onClick) {
          this.config.sound.onClick.play(); // Play sound
        }
        const returnState = this.config.options.clickFunction.disableAfterClick ? BUTTONSTATE.DISABLED : BUTTONSTATE.ENABLED; // Find out where it goes after
        if (this.config.options.clickFunction.scaleOnClick && !this.pulsing) {
          this.growOnClick(0.9, () => { // Shrinks and returns
            this.currentState = returnState;
          });
        } else {
          this.clickOutTimer = game.time.events.add(tweenTime, () => { // A timeout before inputs are being accepted again
            this.currentState = returnState;
          }, this);
        }
      },
      onExit: () => {
      },
    };
  }

  /**
   * Returns current state
   */
  get currentState() {
    return this._currentState;
  }

  /**
   * Sets the current state, but not without calling proper functions
   */
  set currentState(newState) {
    if (!this.game) { return; } // Return if game doesn't exist
    if (this._currentState === newState) { return; } // Return if we're trying to enter the same state again
    if (allStates.indexOf(newState) === -1) { return; } // Return if the given state does not exist
    if (this._currentState !== undefined) {
      this.buttonStateFuncs[this._currentState].onExit(); // Exit the previous state
    }
    this._currentState = newState;
    this.buttonStateFuncs[this._currentState].onEnter(); // Enter the state
  }

  /**
   * Set up custom click handler using G.Input
   */
  _initClickHandler() {
    const clickHandler = G.Input.createCustomInputClickHandler();
    clickHandler.clickSignal.add(this.onClickDown.bind(this));

    G.Input.initializeCustomInput(this);
    this.customInput.addHandler(clickHandler);
  }

  /**
   * do a check to block clicks if a element is not visible or the alpha is not 1.
   */
  _isValidClick() {
    let element = this;
    while (element.parent) {
      if (element.alpha < 1 || element.visible === false) return false;
      element = element.parent;
    }
    return true;
  }

  /**
   * DO NOT CALL. ITS NOT FOR YOU.
   * Draws the button
   */
  _drawButton() {
    this.button = new Phaser.Group(game, this);
    this.addChild(this.button);

    this.determineSlice();
    this.setColour(this.config.button.tint);

    const iconOffset = { width: 0 };
    if (this.config.text) {
      this._resizeText(iconOffset); // Text positioning here
    }
    this.hitArea = new Phaser.Rectangle(-this.config.button.dimensions.width / 2, -this.config.button.dimensions.height / 2, this.config.button.dimensions.width, this.config.button.dimensions.height);
  }

  /**
   * Creates a nine slice of the specific sheet
   * @param {string} sheet
   */
  makeButtonImage(sheet) {
    const img = new UI_NineSlice(0, 0, sheet, this.config.button.dimensions.width, this.config.button.dimensions.height, this._currentSlice);
    img.anchor.set(0.5);
    this.button.addChild(img);
    return img;
  }

  /**
   * Creates the button visuals
   * @param {number || Object} color
   */
  makeNewButton(color) {
    if (color === undefined || color === null) { // Use given colours if none are given
      color = this.config.button.tint; // eslint-disable-line no-param-reassign
    }
    if (this.config.options.cacheButton) { // Turn off cache if we are caching it
      this.button.cacheAsBitmap = null;
    }
    if (this.buttonImage) {
      _.forEach(this.buttonGroup, (child) => { // Remove all old button images (but not the text) if they're there
        if (child.parent) {
          child.parent.removeChild(child);
        }
        child.destroy();
      });
    }
    const isNumberColor = typeof color === 'number'; // Is the colour a number or object?
    const buttonLayerOrder = isNumberColor ? G.OMTsettings.elements.buttonNineSlice.layers : color; // Determine which slice to use if any
    this.shadow = this.makeButtonImage(buttonLayerOrder.shadow); // Make shadow
    this.buttonImage = this.makeButtonImage(buttonLayerOrder.base); // make Base
    this.buttonGroup = [this.shadow, this.buttonImage];
    if (isNumberColor) { this.buttonImage.tint = color; } // Tint the button if it isn't an object
    const details = []; // Hold the extra details
    if (this.config.button.extraDetail) {
      _.forEach(buttonLayerOrder.largeDetail, (detail) => {
        details.push(this.makeButtonImage(detail)); // Make the detail 9-slices and hold it
      });
    }
    if (buttonLayerOrder.highlights) {
      this.highlight = this.makeButtonImage(buttonLayerOrder.highlights); // Make highlight
      this.buttonGroup.push(this.highlight);
    }
    this.buttonGroup = this.buttonGroup.concat(details); // Save all these
    if (this.config.options.cacheButton) { // Cache the button if we are
      this.button.cacheAsBitmap = true;
    }
  }

  /**
   * Compares the slices, if they have the same value on each key.
   * If any one of them are different, it returns false
   * @param {Object} inSlice
   * @param {Object} compareSlice
   */
  compareSlice(inSlice, compareSlice) {
    let isSame = true;
    _.forEach(inSlice, (value, key) => { // eslint-disable-line consistent-return
      if (inSlice[key] !== compareSlice[key]) {
        isSame = false;
        return false; // Early loop exit
      }
    });
    return isSame;
  }

  /**
   * Determines which slice to use
   */
  determineSlice() {
    if (this.config.button.extraDetail) { // If there are extra details, it will always have the large slice
      this._currentSlice = JSON.parse(JSON.stringify(G.OMTsettings.elements.buttonNineSlice.largeDetail));
    } else {
      if (!this._currentSlice) { this._currentSlice = G.OMTsettings.elements.buttonNineSlice.largeDetail; } // No slice? Set one for now
      const sumTB = this._currentSlice.top + this._currentSlice.bottom; // Finds the sum
      const isLargeSlice = this.compareSlice(this._currentSlice, G.OMTsettings.elements.buttonNineSlice.largeDetail); // Is this the large slice?
      if ((this.config.button.dimensions.height < sumTB && isLargeSlice) // If the height is less than the sum of large slice
        || (this.config.button.dimensions.height > sumTB && this.compareSlice(this._currentSlice, G.OMTsettings.elements.buttonNineSlice.smallSlice))
      ) { // If the height is more than the sum of small slice
        if (isLargeSlice) {
          this._currentSlice = JSON.parse(JSON.stringify(G.OMTsettings.elements.buttonNineSlice.smallSlice)); // Change to small slice
        } else {
          this._currentSlice = JSON.parse(JSON.stringify(G.OMTsettings.elements.buttonNineSlice.largeDetail)); // Change to large slice
        }
      }
    }
  }

  /**
   * Sets the text style for after initialization
   * @param {Object || String} textStyle
   */
  setTextStyle(textStyle) {
    this.config.text.textStyle = textStyle;
  }

  /**
   * Sets the text on the button
   * @param {string} newText
   */
  setText(newText) {
    if (!this.game) { return; }
    if (this.buttonText) {
      this.config.text.string = newText;
      this._resizeText();
    }
  }

  /**
   * Returns text
   */
  get text() {
    return this.config.text.string;
  }

  /**
   * Sets the icon after initialization.
   * @param {Phaser.DisplayObject} image
   * @param {string} align
   * @param {Phaser.Point} offset
   */
  setIcon(image, align, offset) {
    if (image) {
      this.config.text.icon.image = image;
    }
    if (align) {
      this.config.text.icon.align = align;
    }
    if (offset) {
      if (offset.x) {
        this.config.text.icon.offset.x = offset.x;
      }
      if (offset.y) {
        this.config.text.icon.offset.y = offset.y;
      }
    }
    this._resizeText();
  }

  /**
   * Permamently modifies colour of the button
   * @param {number} color
   */
  setColour(color) {
    this.config.button.tint = color;
    this.adjustColour(color);
  }

  /**
   * Temporarily changes colour of the button
   * @param {string} color
   */
  adjustColour(color) {
    const isNumberColor = typeof color === 'number';
    // If the adjusted colour is not a number, or there is no button yet, or the current colour is a number but now switching to an object as the colour
    if (!isNumberColor || this.buttonImage === undefined || (typeof this.button.currentColor !== 'number' && isNumberColor)) {
      this.makeNewButton(color);
    } else {
      this.buttonImage.tint = color; // Its a number, just tint it
      if (this.config.options.cacheButton) { // Update cache if the button is cached
        this.button.updateCache();
      }
    }
    this.button.currentColor = color; // Sets it for comparisons
  }

  /**
   * Returns the calculated height of the button (assuming no icon peeks out)
   */
  get height() {
    return this.config.button.dimensions.height;
  }

  /**
   * Returns the calculate width of the button (assuming no icon peeks out)
   */
  get width() {
    return this.config.button.dimensions.width;
  }

  /**
   * Sets the width using resize
   */
  set width(newWidth) {
    this.resize(newWidth, this.height);
  }

  /**
   * Sets the height using resize
   */
  set height(newHeight) {
    this.resize(this.width, newHeight);
  }

  /**
   * Resizes the button with fancy 9-slice skills.
   * Also adjusts text inside the button if there is any
   * @param {number} nWidth
   * @param {number} nHeight
   */
  resize(nWidth, nHeight) {
    if (!this.game) { return; } // Don't resize is game is gone
    let validChange = false;
    if (this.width !== nWidth || this.height !== nHeight) {
      validChange = true;
    }
    if (!validChange) { return; }

    /**
     * Phaser Nineslice noted that the new width/height should not be smaller than the sum of the two opposite slices. So stay within it!
     */
    const oldSlice = this._currentSlice;
    this.config.button.dimensions.width = nWidth;
    this.config.button.dimensions.height = nHeight;
    this.determineSlice();
    const isSameSlice = this.compareSlice(oldSlice, this._currentSlice);
    if (!isSameSlice) {
      this.makeNewButton(null, true);
    } else {
      _.forEach(this.buttonGroup, (nineSliceButton) => {
        nineSliceButton.resize(nWidth, nHeight);
      });
    }

    const iconOffset = { width: 0 };
    if (this.buttonText) {
      this._resizeText(iconOffset); // Resize
    }
    if (this.config.options.cacheButton) {
      this.button.updateCache();
    }
    this.hitArea.x = -nWidth / 2;
    this.hitArea.y = -nHeight / 2;
    this.hitArea.width = nWidth;
    this.hitArea.height = nHeight;
  }

  /**
   * DO NOT CALL ITS NOT FOR YOU
   * Resizes the text and positions it based on icon or not
   */
  _resizeText() {
    if (!this.buttonImage) { return; }
    if (!this.game) { return; }
    if (this.config.text) {
      let textPosition = 0; // Default pos
      let textWidth = this.config.text.icon.offset.x + this.buttonImage.width * this.config.text.dimensionMods.width; // Default width
      if (this.config.text.icon && this.config.text.icon.image && this.config.text.icon.image.visible) { // Theres an icon!
        this.buttonIcon = this.config.text.icon.image; // Save the icon reference
        this.buttonIcon.y = this.config.text.icon.offset.y;

        switch (this.config.text.icon.align) { // Where does it go?
          case 'right': // Position it to the right and push the text back to the left
            this.buttonIcon.x = this.config.text.icon.offset.x + (this.config.button.dimensions.width / 2) - this.buttonIcon.width / 2;
            this.buttonIcon.y = 0 + this.config.text.icon.offset.y;
            textWidth -= this.config.text.icon.offset.x + this.buttonIcon.width / 2; // Makes the width smaller so it fits in the button
            textPosition = this.buttonIcon.x - (this.buttonIcon.width / 2) - (textWidth * 0.5);
            break;
          case 'left': // Position it to the left and push the text to the right
            this.buttonIcon.x = this.config.text.icon.offset.x - (this.config.button.dimensions.width / 2) + this.buttonIcon.width / 2;
            this.buttonIcon.y = 0 + this.config.text.icon.offset.y;
            textWidth -= this.buttonIcon.width / 2; // Makes the width smaller so it fits in the button
            textPosition = this.buttonIcon.x + (this.buttonIcon.width / 2) + (textWidth * 0.5);
            break;
          default: // For all else, or center
            break;
        }
        this.addChild(this.buttonIcon);
      }
      const textHeight = this.buttonImage.height * this.config.text.dimensionMods.height; // Height isn't used anyways in G.Text but...
      if (this.buttonText) { // If it already exists
        if (this.buttonText.parent) {
          this.buttonText.removeChild(this.buttonText.parent); // Remove itself from the parent
        }
        this.buttonText.destroy(); // Destroy
      }
      // Make new text
      this.buttonText = new G.Text(0, Math.round(this.config.text.offset.y), this.config.text.string, this.config.text.textStyle, this.config.text.anchor, textWidth, textHeight, true, 'center');
      this.buttonText.x = Math.round(this.config.text.offset.x + textPosition);
      this.addChild(this.buttonText);
    }
  }

  /**
   * Changes state if its enabled to
   */
  onClickDown() {
    if (this.currentState === BUTTONSTATE.ENABLED && this._isValidClick()) {
      this.currentState = BUTTONSTATE.DOWN;
    }
  }

  /**
   * The max size the pulse grows to be
   * @param {number} maxScale
   */
  pulse(maxScale) {
    if (this.pulsingTween) {
      this.stopPulse(1);
    }
    const targetScale = maxScale || 1.1;
    this.pulsing = true;
    this.pulsingTween = game.add.tween(this.scale).to({ x: targetScale, y: targetScale }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    if (!this.config.options) {
      this.config.options = {
        pulse: maxScale,
      };
    } else {
      this.config.options.pulse = targetScale;
    }
  }

  /**
   * Returns the size to 1 (or the size you give it) and stops the pulse
   * @param {number} maxScale
   */
  stopPulse(maxScale) {
    if (this.pulsingTween) {
      this.pulsingTween.stop();
      this.pulsingTween = null;
    }

    const targetScale = maxScale || 1;
    this.pulsingTween = game.add.tween(this.scale).to({ x: targetScale, y: targetScale }, 100, Phaser.Easing.Sinusoidal.InOut, true, 0, 0);
    this.pulsingTween.onComplete.add(() => {
      this.pulsingTween = null;
    });
    this.pulsing = false;
    this.config.options.pulse = targetScale;
  }

  /**
   * Shrinks the button to maxScale (or 0.9) and returns.
   * Calls the onComplete when done
   * Supposed to be for BUTTONSTATE.DOWN but theres other uses too.
   * @param {number} maxScale
   * @param {function} onComplete
   */
  growOnClick(maxScale, onComplete) {
    if (this.pulsingTween) {
      this.pulsingTween.stop();
      this.pulsingTween = null;
    }

    this.pulsingTween = game.add.tween(this.scale).to({ x: maxScale || 0.9, y: maxScale || 0.9 }, 150, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
    if (onComplete) {
      this.pulsingTween.onComplete.add(onComplete);
    }
  }

  /**
  * enable interactivity, if previously disabled
  * State of the button can already be changed outside of this class.
  * This is just a shortcut!!
  */
  enable() {
    this.inputEnabled = true;
    this.input.useHandCursor = true;
    // this.currentState = BUTTONSTATE.ENABLED;
  }

  /**
  * disable interactivity
  * State of the button can already be changed outside of this class.
  * This is just a shortcut!!
  */
  disable() {
    this.inputEnabled = false;
    // this.currentState = BUTTONSTATE.DISABLED;
  }
}
