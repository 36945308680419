import OMT_UI_AvatarWithBadge from '../../../OMT_UI/FriendsList/OMT_UI_AvatarWithBadge';

const AVATAR_SIZE = 60;

const LEVEL_NODE_OFFSET = { x: -90, y: 0 };
const LEVEL_NODE_ANIMATION_DURATION = 1000;

/**
 * class for YOUR avatar on the WorldMap
 */
export default class MapPlayerAvatar extends Phaser.Group {
  /**
   * constructor
   */
  constructor() {
    super(game);

    this.signals = {
      onAnimFinished: new Phaser.Signal(),
    };
    this._signalTokens = [
      G.sb('pauseAllUpdate').add(this._pauseAnimation.bind(this)),
      G.sb('resumeAllUpdate').add(this._resumeAnimation.bind(this)),
      G.sb('worldMapRedrawBadge').add(this._initAvatar.bind(this)),
    ];

    // Init avatar
    this._initAvatar();
  }

  /**
   * Saves level node, used for comparing against which node its at
   */
  set levelNode(ln) {
    this._levelNode = ln;
  }

  /**
   * Returns the saved level node
   */
  get levelNode() {
    return this._levelNode;
  }

  /**
   * Draws the avatar image and frame.
   * Can have custom frames
   */
  _initAvatar() {
    if (this._avatar) {
      this._avatar.destroy();
    }
    this._avatar = new OMT_UI_AvatarWithBadge(OMT.envData.settings.user.avatar, G.saveState.badgeManager.currentBadge, AVATAR_SIZE);
    this.addChild(this._avatar);
  }

  /**
   * Returns the position the avatar should be if it was on a position
   * @param {{x:number, y:number}} pos
   * @returns {{x:number, y:number}}
   */
  getAvatarPositionAtPosition(pos) {
    return { x: pos.x + LEVEL_NODE_OFFSET.x, y: pos.y + LEVEL_NODE_OFFSET.y };
  }

  /**
   * set avatar position to 0, 0
   */
  setPositionToPos(pos) {
    const position = this.getAvatarPositionAtPosition(pos);
    this.x = position.x;
    this.y = position.y;
    this._setLoopAnimation();

    this.signals.onAnimFinished.dispatch();
  }

  temporarilySetPosition(position, targetPos) {
    const pos = this.getAvatarPositionAtPosition(position);
    this.x = pos.x;
    this.y = pos.y;
    this._targetPos = targetPos;
    this._setLoopAnimation();
  }

  /**
   * animate avatar position from and to a specific level
   */
  startAnimatePositionToPosition(tweenTime = LEVEL_NODE_ANIMATION_DURATION) {
    return new Promise((resolve) => {
      if (!this._targetPos) { resolve(); return; }
      const target = {
        x: this._targetPos.x + LEVEL_NODE_OFFSET.x,
        y: this._targetPos.y + LEVEL_NODE_OFFSET.y,
      };
      const tw = game.add.tween(this)
        .to({ x: target.x, y: target.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      tw.onComplete.add(() => { // When tween complete make sure its actually scrolled
        this.x = target.x;
        this.y = target.y;
        this.signals.onAnimFinished.dispatch();
        resolve();
      });
    });
  }

  /**
   * set intro animation sequence
   */
  _setLoopAnimation() {
    this.pivot.x = 0;
    this.tween = game.add.tween(this.pivot);
    this.tween.to({ x: 10 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  }

  /**
   * destruction method
   */
  destroy() {
    for (const signal of Object.values(this.signals)) signal.dispose();
    for (const signal of this._signalTokens) if (signal) signal.detach();
    this._signalTokens.length = 0;
    this.tween.stop();
    super.destroy();
  }

  /**
   * Sets the animation flag to off
   */
  _pauseAnimation() {
    if (this.tween && !this.tween.pendingDelete) {
      this.tween.pause();
    }
  }

  /**
   * Sets the animation flag to on
   */
  _resumeAnimation() {
    if (this.tween && !this.tween.pendingDelete) {
      this.tween.resume();
    }
  }
}
