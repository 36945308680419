import { TournamentCompetitorScoreViewBase } from '../../base/Tournament/TournamentCompetitorScoreViewBase';
import { TournamentCompetitorAvatarHorizontal } from './TournamentCompetitorAvatar';

const AVATAR_POS = { x: 22, y: 77 };

const RANK_IMAGES = ['competitor_1st_ribbon', 'competitor_2nd_ribbon', 'competitor_3rd_ribbon'];
const RANK_TEXT_POS = { x: -67, y: 84 };
const RANK_TEXT_FONT_SIZE = '48px';
const RANK_IMAGE_POS = { x: -67, y: 74 };
const RANK_ANCHOR = [0.5, 0.5];

const SCORE_POS = { x: 4, y: 168 };
const SCORE_ANCHOR = [0.5, 0.5];

// animateOut() movement settings
const ANIMATE_OUT_MOVE_DURATION = 850;
const ANIMATE_OUT_MOVE_DELAY = 200;
const ANIMATE_OUT_MOVE_X = -20;
const ANIMATE_OUT_MOVE_Y = 200;
const ANIMATE_OUT_MOVE_ANGLE = 20;

// animateOut() fade out settings
const ANIMATE_OUT_FADE_DURATION = 200;
const ANIMATE_OUT_FADE_DELAY = 600;

/**
 * view for individual competitors views during Tournament gameplay
 */
export class TournamentCompetitorScoreViewHorizontal extends TournamentCompetitorScoreViewBase {
  /**
   * Adjusts an offset
   */
  _adjustOffset() {
    this._offsetX = -5;
  }

  /**
   * init the competitor avatar
   */
  _initAvatar() {
    super._initAvatar({
      x: AVATAR_POS.x,
      y: AVATAR_POS.y,
      Class: TournamentCompetitorAvatarHorizontal,
    });
  }

  /**
   * set the out transistion
   */
  animateOut() {
    super.animateOut({
      x: this.x - ANIMATE_OUT_MOVE_X,
      y: this.y + ANIMATE_OUT_MOVE_Y,
      angle: this.angle + ANIMATE_OUT_MOVE_ANGLE,
      duration: ANIMATE_OUT_MOVE_DURATION,
      delay: ANIMATE_OUT_MOVE_DELAY,
      fadeDuration: ANIMATE_OUT_FADE_DURATION,
      fadeDelay: ANIMATE_OUT_FADE_DELAY,
    });
  }

  /**
   * init the competitor rank display / field
   */
  _initRankDisplay() {
    super._initRankDisplay({
      rankImages: RANK_IMAGES,
      image: {
        x: RANK_IMAGE_POS.x,
        y: RANK_IMAGE_POS.y,
      },
      text: {
        x: RANK_TEXT_POS.x,
        y: RANK_TEXT_POS.y,
        style: {
          style: 'UIGoalPanelPointAmount-tournament-horizontal',
          fontSize: RANK_TEXT_FONT_SIZE,
        },
      },
      anchor: RANK_ANCHOR,
    });
  }

  /**
   * init the competitor score field
   */
  _initScoreField() {
    super._initScoreField({
      x: SCORE_POS.x,
      y: SCORE_POS.y,
      style: 'UIGoalPanelPointAmount-tournament',
      anchor: SCORE_ANCHOR,
    });
  }
}
