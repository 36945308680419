import ShareMsgBase from './A_G.ShareMsgBase';

class MsgOvertaken extends ShareMsgBase {
  /**
   * The message that gets sent when you pass a friend in levels
   * @param {Object} user1
   * @param {Object} user2
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1, user2, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    if (!user1.avatar) user1.avatar = user1.image;
    if (!user2.avatar) user2.avatar = user2.image;

    this.avatar1 = this._addExtImage(150, 96, user2.avatar, 0, 72);
    this.avatar2 = this._addExtImage(413, 96, user1.avatar, 0, 128);
    this.bg = this._addExtImage(0, 0, 'img/Bot_Level_Passed.png', 0);
  }
}

G.MsgOvertaken = MsgOvertaken;
