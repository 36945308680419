import Friendship_Chestv2, { FRIENDSHIPCHESTCARD_STATUS } from './Friendship_Chestv2';
import OMT_UI_DrawUtil from '../../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import ClotheslineMenu from '../../../OMT_UI/Menus/ClotheslineMenu';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

export default class Friendship_Chestv2_FTUX extends Friendship_Chestv2 {
  /**
   * An extension of Friendship_Chestv2.
   * This only has the stuff for FTUX cards
   * @param {Object} config
   * @param {Window} config.window
   * @param {Phaser.Group} config.container
  */
  constructor(config) {
    super(config);

    if (this._isLandscape) {
      this.displayContainer.scale.setTo(this._gameScale);
    }
  }

  _init() {
    if (!this.parentWindow.game) { return; } // If the game is still here...

    this._prizeTable = G.json.settings.friendshipChest_prizes.invitedTable;

    this._drawInitialHeader();

    // Creates polaroid images and attaches it on the clothesline menu
    this._allPolaroids = this._makeImages(1);
    this._clothesline = new ClotheslineMenu({
      game,
      parent: this.displayContainer,
      images: this._allPolaroids,
      startingIndex: 0,
      onReadyCallback: () => { // When clothesline is ready
        this._readyFlags.async = true;
        this._postSyncInit();
      },
      distance: () => (OMT.systemInfo.orientation === ORIENTATION.vertical ? game.width / 2 : game.width / 3),
    });
    this._clothesline.toggleLock(true);

    // Rest of init will happen when clothesline is ready
    this._readyFlags.reg = true;
    this._postSyncInit();
  }

  /**
   * Part 2 of init. Needed because things after require clotheslineMenu to be initialized
   */
  _postSyncInit() {
    if (!(this._readyFlags.async && this._readyFlags.reg)) { return; }

    // Resize
    this._resizeToken = G.sb('onScreenResize').add(this._onResize.bind(this));
    this._generatePrizeSection();

    // Check the current index
    const cardData = this._getCurrentPolaroidStatus();
    this._updateButtonAreaByStatus(cardData);
    this._onResize();
    this._readyClaim((goods) => {
      this._claimReady = goods;
      this._readyFlags.claimReady = true;
      this._attemptToAnimate();
    });

    this.signals.onReady.dispatch(); // Ready!
  }

  _getCurrentPolaroidStatus() {
    return FRIENDSHIPCHESTCARD_STATUS.FTUX;
  }

  animateClaim() {
    this._readyFlags.animateGo = true;
    this._attemptToAnimate();
  }

  _attemptToAnimate() {
    if (!(this._readyFlags.animateGo && this._readyFlags.claimReady)) { return; }
    this._animateClaim(this._claimReady);
  }

  /**
   * Slightly similar to the same one in Friendship_Chest.js but has a spot for the friend
   */
  async _readyClaim(onComplete) { // eslint-disable-line consistent-return
    const container = new Phaser.Group(G.game, null);

    const center = this._clothesline.y + this.currentPolaroid.height / 4;// + this._clothesline.height / 2; // Finds the location of the center of the chest in the card
    const shine = G.makeImage(0, center, 'shine_godrays', 0.5, container);
    shine.scale.set(1.5, 1.5);
    shine.update = () => {
      if (container.visible && shine.alpha > 0) {
        shine.angle += 0.25;
      }
    };
    shine.alpha = 0;

    const chestOpen = G.makeImage(0, center, 'friendship_chest_full', 0.5, container);
    chestOpen.alpha = 0;
    chestOpen.scale.set(1.5, 1.5);

    const chestClosed = new Phaser.Group(G.game, container);
    G.makeImage(0, 0, 'friendship_chest_closed', 0.5, chestClosed);
    chestClosed.y = center;

    // Generates the prize section again from the card
    const prizeData = this._generatePrizeSection({ style: 'font-white', fontSize: Math.round(Math.max(64, Math.min(128, game.height / 18))) }, false);
    const prizeSection = prizeData.visual;
    const prize = prizeData.data;

    prizeSection.y = center + 20 + (chestOpen.height + prizeSection.height) / 2;
    container.addChild(prizeSection);

    const applyGifts = () => {
      const trackerArray = [];
      OMT.transactionTracking.logInventoryTransactionBegin();

      for (let i = 0; i < prize.length; i++) {
        const curPrize = prize[i];
        trackerArray.push([curPrize.prize, curPrize.amount]); // Puts into an array for data tracking

        switch (curPrize.prize) {
          case 'coin':
            this.parentWindow.state.uiTargetParticles.createCoinBatch( // Show bling
              game.world.bounds.x + prizeSection.worldPosition.x,
              prizeSection.worldPosition.y,
              this.parentWindow.state.panel.coinsTxt,
              curPrize.amount,
              false,
            );
            OMT.transactionTracking.addInventoryChange('coins', 0, curPrize.amount);
            break;
          case 'life':
            G.saveState.addLife(curPrize.amount); // Add life
            break;
          default: { // Perhaps bad, but default is assuming booster
            const boosterIndex = parseInt(curPrize.prize[8], 10);
            G.saveState.changeBoosterAmount(boosterIndex, curPrize.amount); // Add in booster
            OMT.transactionTracking.addInventoryChange('boostersReceived', boosterIndex, curPrize.amount);
            break;
          }
        }
      }

      // DDNA.transactionHelper.trackRewards(trackerArray, [], { // DDNA tracking
      //   transactionType: 'REWARD',
      //   tActionType: 'FRIENDSHIPCHEST_JOINED',
      //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
      // });
      OMT.transactionTracking.logInventoryTransactionEnd();
      G.saveState.friendshipChestDataManager.triggerPromoInitial(); // Save!
    };

    const targetFriend = G.saveState.friendshipChestDataManager.getInvitedData();
    const friends = await OMT.friends.getFriendsList(); // Might need to not use cache since new friends
    const friendData = friends.filter((fr) => fr.id === targetFriend)[0];
    const avatarSize = 88;
    const avatar = new Phaser.Group(G.game, null);
    let avatarImage;
    if (!friendData || (friendData && (!friendData.image || friendData.image === ''))) { // If theres no friend data, some how. Or the image is not there, or blank
      avatarImage = G.makeImage(0, 0, 'avatar_e', 0.5, avatar);
      avatarImage.width = avatarImage.height = 88;
    } else {
      avatarImage = G.makeExtImage(0, 0, friendData.image, 'avatar_e', 0.5, avatar, null, function resizeAvatar() {
        this.width = this.height = 88;
      });
    }
    avatarImage.width = avatarImage.height = avatarSize;
    const avatarFrame = G.makeImage(0, 0, 'avatar_frame', 0.5, avatar);
    avatarFrame.width = avatarFrame.height = avatarImage.height * 1.15;
    const bubblePack = new Phaser.Group(G.game, container);
    const bubble = G.makeImage(0, 0, 'thanks_bubble', 0.5, bubblePack);
    bubble.scale.set(1.5, 1.5);
    const bubbleText = new G.Text(0, 0, G.txt('Thanks for joining the game!'), 'font-white', 0.5, bubble.width * 0.8, bubble.height * 0.6, true, 'center');
    bubbleText.y = -bubbleText.height / 4;
    bubblePack.addChild(bubbleText);
    bubblePack.y = prizeSection.y + prizeSection.height / 2 + bubblePack.height * 0.6;
    avatar.y = bubblePack.y + (bubblePack.height + avatar.height) / 2;
    container.addChild(bubblePack);
    container.addChild(avatar);

    const returnedGoods = {
      container,
      prizeData: prize,
      shine,
      chestOpen,
      chestClosed,
      prizeDisplay: prizeSection,
      applyGifts,
      friend: {
        bubble: bubblePack,
        avatar,
      },
    };

    if (onComplete) { onComplete(returnedGoods); } else { return returnedGoods; }
  }

  /**
   * Lots of tweens for animating the claim. Most of it is similar to Friendship_Chest.js
   * @param {Object} claimDisplay
   */
  _animateClaim(claimDisplay) {
    const tweenTime = 500;

    this.hideUI();
    this._hideBottomSide();
    this._UIAlpha_tween.onComplete.add(() => {
      this._UIAlpha_tween = null;
    });

    const cardOut = game.add.tween(this._clothesline) // tweening out the card
      .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);

    // Applying and saving positions
    this.displayContainer.addChild(claimDisplay.container);
    const chestY = claimDisplay.chestClosed.y;
    claimDisplay.prizeDisplay.alpha = 0;
    const prizeY = claimDisplay.prizeDisplay.y;
    claimDisplay.prizeDisplay.y = chestY;
    claimDisplay.chestClosed.scale.set(0.3, 0.3);
    claimDisplay.chestClosed.y = chestY - 20;
    claimDisplay.shine.alpha = 0;
    const friendY = claimDisplay.friend.avatar.y;
    claimDisplay.friend.avatar.y = chestY;
    claimDisplay.friend.avatar.alpha = 0;
    const bubbleY = claimDisplay.friend.bubble.y;
    claimDisplay.friend.bubble.y = friendY;
    claimDisplay.friend.bubble.alpha = 0;


    // Is this too much tweening...?
    const prizeIn = game.add.tween(claimDisplay.container) // The claim container tweens in (with whatever is visible)
      .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    prizeIn.onStart.addOnce(() => { // At the same time...
      game.add.tween(claimDisplay.chestClosed) // The closed chest hops in
        .to({ y: [chestY - 150, chestY] }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      const claimChest = game.add.tween(claimDisplay.chestClosed.scale) // The closed chest grows from tiny to big
        .to({ x: claimDisplay.chestOpen.scale.x, y: claimDisplay.chestOpen.scale.y }, tweenTime, Phaser.Easing.Sinusoidal.Out, true);

      claimChest.onComplete.addOnce(() => { // Once the chest hops in,
        const shakeTween1 = game.add.tween(claimDisplay.chestClosed) // It'll shake twice
          .to({ angle: [10, -10, 0] }, 250, Phaser.Easing.Sinusoidal.InOut, true, tweenTime);
        const shakeTween2 = game.add.tween(claimDisplay.chestClosed) // copy of shake one but I need a longer delay here
          .to({ angle: [10, -10, 0] }, 250, Phaser.Easing.Sinusoidal.InOut, false, tweenTime * 1.5);
        shakeTween1.chain(shakeTween2);
        shakeTween2.onComplete.addOnce(() => { // Once its done shaking...
          game.time.events.add(tweenTime * 2, () => { // The suspense is killing me!
            game.add.tween(claimDisplay.shine) // The shine tweens in
              .to({ alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true);
            game.add.tween(claimDisplay.chestOpen) // The opened chest tweens in (making it look like it opened)
              .to({ alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true);
            game.add.tween(claimDisplay.chestClosed) // The closed chest tweens out
              .to({ alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true);
            game.time.events.add(tweenTime * 1.5, () => { // Then a delay...
              const prizeOut = game.add.tween(claimDisplay.prizeDisplay) // The prize drops out
                .to({ alpha: 1, y: prizeY }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
              const avatarOut = game.add.tween(claimDisplay.friend.avatar) // The avatar drops out too!
                .to({ alpha: 1, y: friendY }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
              const bubbleOut = game.add.tween(claimDisplay.friend.bubble) // The bubble pops out too!
                .to({ alpha: 1, y: bubbleY }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
              const jumpHeight = friendY - 15;
              const avatarShake = game.add.tween(claimDisplay.friend.avatar) // The avatar hops
                .to({ y: jumpHeight }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, false, 0, 1, true);
              prizeOut.chain(avatarOut);
              avatarOut.chain(bubbleOut);
              bubbleOut.chain(avatarShake);
              bubbleOut.onComplete.addOnce(() => { // When done, it applies the gifts
                claimDisplay.applyGifts();
                game.time.events.add(2000, () => { // Wait some more...
                  this._outroSequence(claimDisplay); // Outro (as another function)
                }, this);
              }, this);
              prizeOut.start();
            });
          });
        });
      });
    });

    cardOut.start();
    prizeIn.start();
  }

  /**
   * Outro is not with the big tween to keep it separate
   */
  _outroSequence(claimElements) {
    const tweenTime = 500;

    const outroTween = game.add.tween(claimElements.container) // Tweens the container to disappear
      .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    outroTween.onComplete.addOnce(() => { // When its done
      claimElements.shine.update = () => {}; // Destroy the claim elements
      if (OMT.feature.getFeatureFriendshipChest()) {
        G.sb('pushWindow').dispatch('friendshipChestPromo'); // Open the promo card
      } else {
        G.saveState.friendshipChestDataManager.triggerPromoInitial(); // Trigger it even though it actually never shows. The steps to appear will make it show
      }
      this.signals.onCloseClicked.dispatch(); // Close this...
    });
    outroTween.start();
  }

  /**
   * Hides the bottom half of the card
   */
  _hideBottomSide() {
    const obj = { cur: 1, target: 0 };
    this._UIAlpha_tween = game.add.tween(obj)
      .to({ cur: obj.target }, 200, Phaser.Easing.Sinusoidal.InOut, false);
    this._UIAlpha_tween.onUpdateCallback(() => {
      this._prizeSection.alpha = obj.cur;
    });
    this._UIAlpha_tween.start();
  }

  /**
   * Reposition UI elements on resize
   */
  _onResize() {
    OMT_UI_DrawUtil.redrawGradients(this._header, this._gradientData.upperGradient, this._gradientData.titleGradient);
    G.Text.setMaxWidth(this._header.titleText, this._header.gradientData.titleBg.image.width * 0.9);
    this._header.titleText.setText(this._header.titleText.text);
    this._header.titleText.x = Math.round(this._header.gradientData.titleBg.image.x + this._header.gradientData.titleBg.image.width / 2);
    this._header.titleText.y = Math.round(this._header.gradientData.titleBg.image.y + 5 + this._header.gradientData.titleBg.image.height / 2);
    this._header.addChild(this._header.titleText);

    this._header.x = -game.width / 2;
    this._header.y = -(game.height) / 2;

    const subTextScale = this._isLandscape ? this._gameScale : 1;

    G.Text.setMaxWidth(this._subText, (game.width * 0.95) / subTextScale);
    this._subText.setText(this._subText.text);
    if (this._isLandscape) {
      this._subText.scale.setTo(1 / subTextScale);
    }

    this._subText.y = Math.round((this._header.y + 10 + this._header.height + this._subText.height / 2) / subTextScale);
    this._clothesline.y = Math.max(this._subText.y + 60 + (this._subText.height / 2), -20 - game.height / 6);

    this._prizeSection.y = Math.round(Math.max(this._clothesline.y + this.currentPolaroid.y + (this.currentPolaroid.calculatedBounds.height + this._prizeSection.height) / 2,
      (game.height + this._prizeSection.height) / 4));
  }
}
