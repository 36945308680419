G.DailyRewardDay = function (x, y, config) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);

  this.bg = G.makeImage(0, 0, config.bgSprite, 0.5, this);

  this.addRibbon(0, this.bg.height * -0.5 + 10, config);

  if (config.giftsVisible) {
    if (config.gifts[0][0].indexOf('booster') === 0) {
      this.icon = G.GiftUI.Elements.GiftImageFromType(
        G.GiftUI.HelperFunctions.getGiftTypeFromString(config.gifts[0][0]),
        config.gifts[0],
        this,
        true,
      );
      this.icon.y = 15;
    } else {
      this.icon = this.add(new G.DailyRewardGiftIcon(0, 10, config.gifts));
    }
  } else {
    this.icon = this.add(new G.DailyRewardBoxIcon(0, 10, config.boxIconConfig));
  }

  this.icon.height = Math.min(this.icon.height, this.bg.height * 0.9);
  this.icon.scale.setTo(this.icon.scale.y);

  if (config.past) {
    this.checkedIconPast = G.makeImage(G.OMTsettings.elements.checkedIconPast.x, G.OMTsettings.elements.checkedIconPast.y, 'task_complete', 0.5, this);
  }

  if (config.today) {
    this.checkedIcon = G.makeImage(G.OMTsettings.elements.checkedIcon.x, G.OMTsettings.elements.checkedIcon.y, 'task_complete', 0.5, this);
    this.checkedIcon.alpha = 0;
    this.checkedIcon.scale.setTo(2);
  }
};

G.DailyRewardDay.prototype = Object.create(Phaser.Group.prototype);

G.DailyRewardDay.prototype.checkedIconAnimation = function () {
  if (!this.checkedIcon) return;
  game.add.tween(this.checkedIcon)
    .to({ alpha: 1 }, 400, Phaser.Easing.Cubic.In, true);
  game.add.tween(this.checkedIcon.scale)
    .to({ x: 1, y: 1 }, 400, Phaser.Easing.Cubic.In, true);
};

G.DailyRewardDay.prototype.addRibbon = function (x, y, config) {
  this.dayRibbon = G.makeImage(x, y,
    config.today ? 'selected_day' : 'unselected_day',
    0.5, this);

  this.dayText = new G.Text(x, y,
    config.today ? OMT.language.getText('Today') : OMT.language.getText('Day %NR%').replace('%NR%', config.dayNr), {
      style: 'msg-white',
      fontSize: '30px',
    }, 0.5, 76);
  this.add(this.dayText);
};
