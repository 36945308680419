import { TokenLayer } from './TokenLayer';
import { TOKEN_TYPES, EDITOR_SYMBOLS } from '../BoardConstants';
import { BoardToken_Ice } from '../Tokens/BoardToken_Ice';

const ICE_ALPHA = 0.8;

/**
 * TokenLayer for BoardToken_Ice instances.
 */
export class TokenLayer_Ice extends TokenLayer {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    super(board, {
      constructor: BoardToken_Ice,
      maxHp: 4,
      hpToken: true,
      editorSymbol: EDITOR_SYMBOLS.ICE,
      collectableType: TOKEN_TYPES.ICE,
      blockMove: true,
      blockMatch: true,
      blockBoosterChange: true,
      stopHitPropagation: true,
    });

    this.alpha = ICE_ALPHA;
  }
}
