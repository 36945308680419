export const TIME_PERIODS = [24, 48, 72]; // hours

export const USER_TYPE = {
  NEW_USER: 'NU',
  RETURNING_USER: 'RU',
};

export const QUOTA_MILESTONE_CONFIG = {
  sessions: {
    quotas: [3, 5, 8],
    eventName: 'marketing-%USERTYPE%-%MILESTONE%sessions-%TIME%h',
  },
  levels: {
    quotas: [10, 15, 20, 25],
    eventName: 'marketing-%USERTYPE%-%MILESTONE%levels-%TIME%h',
  },
  rewardedAds: {
    quotas: [3, 6, 9, 12],
    eventName: 'marketing-%USERTYPE%-%MILESTONE%rewarded-%TIME%h',
  },
  tutorial: {
    quotas: [44], // level
    eventName: 'marketing-%USERTYPE%-tutorialDone-%TIME%h',
  },
};

export const FIRST_SESSION_MILESTONE_CONFIG = {
  levels: [10, 15, 18],
  eventName: 'marketing-%USERTYPE%-%LEVELS%LevelsDoneIn1stSession',
};

export const PLAYTIME_MILESTONE_CONFIG = {
  playtimePeriods: [60, 120, 240], // seconds
  eventName: 'marketing-%USERTYPE%-playtime-%TIME%s',
};
