import { LevelType } from '@omt-game-board/Managers/GameEnums';

/* eslint-disable no-unused-vars */

/**
* Class for capturing game progression / enironment data for all DDNA events
*/
class DDNAAdEventHelper {
  /**
  * constructor
  */
  constructor() {
    // used to track ids and link request / shown events
    this._adIdTable = {};
  }

  /**
  * track an ad. this requires a event name to be passed.
  * @param {string} eventName name of event
  * @param {Object} placementData placement data for advertisement
  * @param {string} adID id of advertisement
  * @param {boolean} adSuccessful was the ad filled?
  * @param {{ code:string, isPreloadError:boolean }} error error passed
  */
  trackAd(eventName, placementData, adID, adSuccessful, error = null) {
    if (!placementData.g_adName || !placementData.adType) {
      console.log('!! DDNA adEvent ingored malformed ad data.');
      return;
    }

    const paramObj = {
      adType: placementData.adType,
      adID,
      placementID: G.BuildEnvironment.adPlacementIDs ? G.BuildEnvironment.adPlacementIDs[placementData.adType] : '',
      adName: placementData.g_adName,
      adGameArea: this.getGameArea(placementData),
      adContext: this.getAdContext(placementData),
      adRewardType: this.getRewardType(placementData),
      adSuccess: adSuccessful ? 1 : 0,
      adGameMode: G.gameMode,
    };
    // append the passed error message
    if (error) {
      paramObj.adFailReason = error.code;
      paramObj.preloadError = error.isPreloadError ? 1 : 0;
    }

    // assign the missionID for LEVEL gameArea
    if (paramObj.gameArea === 'LEVEL' && G.lvlNr) {
      if (G.gameMode === LevelType.CHALLENGE) paramObj.adMissionID = -1;
      else paramObj.adMissionID = G.lvlNr + 1;
    }

    // DDNA.tracking.collectEvent(eventName, paramObj);
  }

  /**
  * track ad request sent.
  * @param {Object} placementData placement data for advertisementt
  * @param {*} error error passed
  */
  trackAdRequest(placementData, error = null) {
    // get a unique id for the add and store it for the shown event
    const adId = DDNA.utils.generateUniqueId();
    this._adIdTable[placementData.g_adName] = adId;

    const isRewarded = placementData.adType === 'REWARDED';
    OMT.platformTracking.logEvent(isRewarded ? 'RewardedAdRequested' : 'InterstitialAdRequested');
  }

  /**
  * track ad shown successfully
  * @param {Object} placementData placement data for advertisementt
  * @param {boolean} adSuccessful was ad filled?
  * @param {{ code:string, isPreloadError:boolean }} error error passed
  */
  trackAdShown(placementData, adSuccessful, error = null) {
    // get the unique id stored on trackAdRequest
    /* const adId = this._adIdTable[placementData.g_adName];
    if (!adId) console.log(`ERROR could not find adId for ad ${placementData.g_adName}`);

    this.trackAd('adRequest', placementData, adId, adSuccessful, error);

    // track ad types seen
    const dataCapture = DDNA.tracking.getDataCapture();
    const isRewarded = placementData.adType === 'REWARDED';

    dataCapture.addToPlayerCharacterizationParam(isRewarded ? 'adsWatchedRewarded' : 'adsWatchedInterstitial', 1, true);
    dataCapture.addToPlayerCharacterizationSessionParam(isRewarded ? 'adsWatchedThisSessionRewarded' : 'adsWatchedThisSessionInterstitial', 1);
    if (isRewarded) {
      dataCapture.setPlayerCharacterizationParam('adsLastTimeRewarded', Date.now(), false);
    }
    dataCapture.setPlayerCharacterizationParam('adsLastTimeShown', Date.now(), true);

    OMT.platformTracking.logEvent(isRewarded ? 'RewardedAdDisplayed' : 'InterstitialAdDisplayed');

    // Check milestones
    OMT.milestoneTracking.incrementAdsWatched();
    OMT.milestoneTracking.checkMilestoneCompletion(); */
  }

  /**
  * get the game area the ad was shown
  * @param {Object} placementData placement data for advertisement
  * @returns {string}
  */
  getGameArea(placementData) {
    if (placementData.g_adName === 'preLevelBooster') return 'LEVEL';
    const gameState = game.state.getCurrentState().key;
    return gameState === 'Game' ? 'LEVEL' : 'MAP';
  }

  /**
  * get the context in which the ad was shown
  * @param {Object} placementData placement data for advertisement
  * @returns {string}
  */
  getAdContext(placementData) {
    switch (placementData.g_adName) {
      case 'retryChallenge': return 'RETRY_CHALLENGE';
      case 'redoMission': return 'REDO_MISSION';
      case 'skipMission': return 'DAILY_MISSION';
      case 'preLevelBooster': return 'BOOSTER_SELECT';
      case 'spinTheDailyWheel': return 'MAP_WHEEL';
      case 'requestMoveHelpWheelSpin': return 'HELPERS_RESPIN';
      case 'spinTheLevelLostWheel': return 'LOSS_AVERSION';
      case 'doubleLevelCoins': return 'WIN_SEQUENCE';
      case 'doubleDailyReward': return 'DAILY_GIFT';
      case 'double3hGift': return 'DOUBLE_GIFT';
      case 'achievementGift': return 'WIN_SEQUENCE';
      case 'chestShuffle': return 'CHEST_SHUFFLE';
      default: return 'NONE';
    }
  }

  /**
  * get the rewardType for the ad
  * @param {Object} placementData placement data for advertisement
  * @returns {string}
  */
  getRewardType(placementData) {
    switch (placementData.g_adName) {
      case 'retryChallenge': return 'RETRY_CHALLENGE';
      case 'redoMission': return 'RETRY_CHALLENGE';
      case 'skipMission': return 'MISSION_PROGRESS';
      case 'preLevelBooster': return 'EXTRA_GIFTS';
      case 'spinTheDailyWheel': return 'EXTRA_SPIN';
      case 'spinTheLevelLostWheel': return 'EXTRA_SPIN';
      case 'requestMoveHelpWheelSpin': return 'EXTRA_SPIN';
      case 'doubleLevelCoins': return 'COINS';
      case 'doubleDailyReward': return 'EXTRA_GIFTS';
      case 'double3hGift': return 'EXTRA_GIFTS';
      case 'achievementGift': return 'EXTRA_GIFTS';
      case 'chestShuffle': return 'CHEST_SHUFFLE_RETRY';
      default: return 'NONE';
    }
  }
}

// create global references
if (!window.DDNA) window.DDNA = {};
DDNA.adEventHelper = new DDNAAdEventHelper();
