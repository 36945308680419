import { TournamentCompetitorTopScoreViewBase } from '../../base/Tournament/TournamentCompetitorTopScoreViewBase';
import { TournamentCompetitorAvatarHorizontal } from './TournamentCompetitorAvatar';

const PRIMARY_AVATAR_POS = { x: 2, y: 46 };

const TROPHY_IMAGE = 'horizontal-trophy';
const TROPHY_ANCHOR = [0.5, 0.5];
const TROPHY_IMAGE_OFFSET = { x: -52, y: 40 };
const TROPHY_ANGLE = -15;

const RANK_IMAGE = 'competitor_1st_ribbon';
const RANK_IMAGE_POS = { x: -47, y: -5 };
const RANK_IMAGE_ANCHOR = [0.5, 0.5];

const COMPETITOR_AVATARS_LIMIT = 5;
const COMPETITOR_AVATARS_CENTER_POS = { x: 31, y: 170 };
const COMPETITOR_AVATARS_SPACING = 20;

// animateIn() movement settings
const ANIMATE_IN_DURATION = 500;
const ANIMATE_IN_DELAY = 800;
const ANIMATE_IN_FROM_Y = -200;

const PLAYER_COUNTER_X = 55;
const PLAYER_COUNTER_Y = 170;
const PLAYER_COUNTER_ANCHOR = [0.5, 0.5];
const PLAYER_COUNTER_FONT_STYLE = { style: 'font-white', fontSize: '24px', align: 'left' };
const PLAYER_COUNTER_TEXT_POS = { x: 0, y: 4 };
const PLAYER_COUNTER_TEXT_MARGIN = 0;

/**
 * view for when you overtake the top score
 */
export class TournamentCompetitorTopScoreViewHorizontal extends TournamentCompetitorTopScoreViewBase {
  /**
   * init your avatar
   */
  _initYourAvatar() {
    super._initYourAvatar({
      avatar: {
        x: PRIMARY_AVATAR_POS.x,
        y: PRIMARY_AVATAR_POS.y,
      },
      trophy: {
        img: TROPHY_IMAGE,
        anchor: TROPHY_ANCHOR,
        x: TROPHY_IMAGE_OFFSET.x,
        y: TROPHY_IMAGE_OFFSET.y,
        angle: TROPHY_ANGLE,
      },
      AvatarClass: TournamentCompetitorAvatarHorizontal,
    });
  }

  /**
   * init your rank icon (1st place)
   */
  _initYourRankIcon() {
    super._initYourRankIcon({
      x: RANK_IMAGE_POS.x,
      y: RANK_IMAGE_POS.y,
      img: RANK_IMAGE,
      anchor: RANK_IMAGE_ANCHOR,
    });
  }

  /**
   * init the comptetior avatars in order of rank
   * @param {Array} leaderboardEntrie
   */
  _initCompetitorAvatars(leaderboardEntries) {
    const competitorAvatarGroup = super._initCompetitorAvatars({
      limit: COMPETITOR_AVATARS_LIMIT,
      center: {
        x: COMPETITOR_AVATARS_CENTER_POS.x - ((leaderboardEntries.length - 1) * COMPETITOR_AVATARS_SPACING),
        y: COMPETITOR_AVATARS_CENTER_POS.y,
      },
      entries: leaderboardEntries,
    });
    competitorAvatarGroup.cacheAsBitmap = true;
  }

  /**
   * init the + players display group
   * @param {number} playerCount
   */
  _initPlusPlayersIcon(playerCount) {
    super._initPlusPlayersIcon({
      playerCount,
      counter: {
        x: PLAYER_COUNTER_X,
        y: PLAYER_COUNTER_Y,
        anchor: PLAYER_COUNTER_ANCHOR,
      },
      text: {
        x: PLAYER_COUNTER_TEXT_POS.x,
        y: PLAYER_COUNTER_TEXT_POS.y,
        font: PLAYER_COUNTER_FONT_STYLE,
        margin: PLAYER_COUNTER_TEXT_MARGIN,
      },
    });
  }

  /**
   * set the in transistion
   */
  animateIn() {
    super.animateIn({
      tweenData: { y: this.y + ANIMATE_IN_FROM_Y, alpha: 0 },
      duration: ANIMATE_IN_DURATION,
      delay: ANIMATE_IN_DELAY,
    });
  }
}
