import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

/**
 * Support Button
 */
export default class OMT_UI_SupportButton extends UI_GButtonES6 {
  /**
   * constructor
   */
  constructor() {
    super(0, 0, 'small_button_yellow', () => { this._openSupport(); });
    this._icon = G.makeImage(0, 0, 'support_icon', [0.5, 0.5]);
    this.addChild(this._icon);
  }

  /**
   * open the support window
   */
  _openSupport() {
    if (G.IAP) FBInstant.showSupportPopup();
    else G.sb('pushWindow').dispatch('support');
  }
}
