/* eslint-disable func-names */
if (typeof G === 'undefined') G = {};
G.UI = G.UI || {};

{
  let PIVOT_TOP_LEFT;
  let PIVOT_BOTTOM_RIGHT;

  const initStaticStuff = () => {
    PIVOT_TOP_LEFT = new G.Math.Vector2(0, 0);
    PIVOT_BOTTOM_RIGHT = new G.Math.Vector2(1, 1);
  };

  let initStaticStuffFunc = initStaticStuff;

  /**
   * Anchored UI layouting. For understanding concept check unity's UIBasicLayout or unreal's UMG:
   * https://docs.unity3d.com/Manual/UIBasicLayout.html
   * https://docs.unrealengine.com/en-us/Engine/UMG/UserGuide/Anchors
   */
  G.UI.AnchoredLayoutComponent = function () {
    if (initStaticStuffFunc) {
      initStaticStuffFunc();
      initStaticStuffFunc = null;
    }

    // 0 means left / top edge, 1 means right / bottom edge
    this.anchors = {
      start: new G.Math.Vector2(0, 0),
      end: new G.Math.Vector2(1, 1),
    };

    // Offsets from anchor positions in absolute units. Positive means towards inside
    this.cornerOffsets = {
      fromTop: 0,
      fromLeft: 0,
      fromBottom: 0,
      fromRight: 0,
    };
  };

  G.UI.AnchoredLayoutComponent.prototype.calculateOffsetsFor = function (
    parentWidth,
    parentHeight,
    left,
    top,
    width,
    height,
  ) {
    const { anchors, cornerOffsets } = this;

    const right = left + width;
    const bottom = top + height;

    cornerOffsets.fromLeft = this._localPositionToOffset(
      parentWidth,
      anchors.start.x,
      left,
    );
    cornerOffsets.fromTop = this._localPositionToOffset(
      parentHeight,
      anchors.start.y,
      top,
    );
    cornerOffsets.fromRight = -this._localPositionToOffset(
      parentWidth,
      anchors.end.x,
      right,
    );
    cornerOffsets.fromBottom = -this._localPositionToOffset(
      parentHeight,
      anchors.end.y,
      bottom,
    );
  };

  G.UI.AnchoredLayoutComponent.prototype.calculateAnchorsFor = function calculateAnchorsFor(
    parentWidth,
    parentHeight,
    left,
    top,
    width,
    height,
  ) {
    const { cornerOffsets } = this;

    this.anchors.start.x = this._toAnchor(
      parentWidth,
      left,
      cornerOffsets.fromLeft,
    );
    this.anchors.start.y = this._toAnchor(
      parentHeight,
      top,
      cornerOffsets.fromTop,
    );
    this.anchors.end.x = this._toAnchor(
      parentWidth,
      left + width,
      -cornerOffsets.fromRight,
    );
    this.anchors.end.y = this._toAnchor(
      parentHeight,
      top + height,
      -cornerOffsets.fromBottom,
    );
  };

  G.UI.AnchoredLayoutComponent.prototype.getLocalPosition = function (
    parentWidth,
    parentHeight,
    optPivot,
    optOutVector,
  ) {
    const { lerp } = G;
    const { anchors, cornerOffsets } = this;

    const result = optOutVector || new G.Math.Vector2();

    result.x = lerp(
      this._offsetToLocalPosition(
        parentWidth,
        anchors.start.x,
        cornerOffsets.fromLeft,
      ),
      this._offsetToLocalPosition(
        parentWidth,
        anchors.end.x,
        -cornerOffsets.fromRight,
      ),
      (optPivot && optPivot.x) || 0,
    );
    result.y = lerp(
      this._offsetToLocalPosition(
        parentHeight,
        anchors.start.y,
        cornerOffsets.fromTop,
      ),
      this._offsetToLocalPosition(
        parentHeight,
        anchors.end.y,
        -cornerOffsets.fromBottom,
      ),
      (optPivot && optPivot.y) || 0,
    );

    return result;
  };

  G.UI.AnchoredLayoutComponent.prototype.getLocalSize = function (
    parentWidth,
    parentHeight,
  ) {
    const topLeft = this.getLocalPosition(
      parentWidth,
      parentHeight,
      PIVOT_TOP_LEFT,
    );
    const bottomRight = this.getLocalPosition(
      parentWidth,
      parentHeight,
      PIVOT_BOTTOM_RIGHT,
    );

    return new G.Math.Vector2(
      bottomRight.x - topLeft.x,
      bottomRight.y - topLeft.y,
    );
  };

  G.UI.AnchoredLayoutComponent.prototype.clone = function () {
    const result = new G.UI.AnchoredLayoutComponent();

    result.anchors.start.copyFrom(this.anchors.start);
    result.anchors.end.copyFrom(this.anchors.end);

    result.cornerOffsets.fromTop = this.cornerOffsets.fromTop;
    result.cornerOffsets.fromLeft = this.cornerOffsets.fromLeft;
    result.cornerOffsets.fromBottom = this.cornerOffsets.fromBottom;
    result.cornerOffsets.fromRight = this.cornerOffsets.fromRight;

    return result;
  };

  G.UI.AnchoredLayoutComponent.prototype._localPositionToOffset = (
    parentSize,
    anchor,
    localPosition,
  ) => localPosition - parentSize * anchor;

  G.UI.AnchoredLayoutComponent.prototype._offsetToLocalPosition = (
    parentSize,
    anchor,
    cornerOffset,
  ) => parentSize * anchor + cornerOffset;

  G.UI.AnchoredLayoutComponent.prototype._toAnchor = (
    parentSize,
    localPosition,
    cornerOffset,
  ) => (localPosition - cornerOffset) / parentSize;
}
