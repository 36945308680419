import { GameModePromo_HeaderBase } from './GameModePromo_HeaderBase';

const HEADER_TEXT_STYLE = { style: 'ui-tournament-promo', fontSize: 54 };
const HEADER_TEXT_BG_PADDING = { horizontal: 50, vertical: 20 };
const HEADER_TEXT_BG_SLICE_DATA = {
  left: 15, right: 15, top: 15, bottom: 22,
};

/**
 * Class for Treasure Hunt Promotion Header
 */
export class TreasureHuntPromo_Header extends GameModePromo_HeaderBase {
  static getHeaderText() {
    const mascot = G.saveState.treasureHuntManager.currentMascot;
    const mascotName = G.OMTsettings.treasureHuntSuper.mascotOrder[mascot];
    return `${mascotName}'s Treasure Hunt`;
  }

  /**
   * constructor
   */
  constructor() {
    super(TreasureHuntPromo_Header.getHeaderText(), 'tournament_promo_header_bg', HEADER_TEXT_STYLE);
  }

  /**
   * draw header text + text bg
   * @param {string} textKey
   * @param {string} bgAsset
   * @param {Object} headerTextStyle
   */
  _drawHeaderText(textKey, bgAsset, headerTextStyle) {
    const headerGroup = new Phaser.Group(game, this);
    const headerTxt = new G.Text(0, 0, OMT.language.getText(textKey), headerTextStyle, 0.5, game.width * 0.8, null, true, 'center');
    const headerTxtBgWidth = headerTxt.width + HEADER_TEXT_BG_PADDING.horizontal;
    const headerTxtBgHeight = headerTxt.height + HEADER_TEXT_BG_PADDING.vertical;
    const headerTxtBG = new PhaserNineSlice.NineSlice(
      0, 0, 'popups', bgAsset, headerTxtBgWidth, headerTxtBgHeight, HEADER_TEXT_BG_SLICE_DATA,
    );
    headerTxtBG.x -= headerTxtBG.width / 2; headerTxtBG.y -= headerTxtBG.height / 2;
    headerGroup.addChild(headerTxtBG);
    headerGroup.addChild(headerTxt);
    headerGroup.y += headerGroup.height / 4;
  }

  get height() {
    if (!this._gradientBg) { return super.height; }
    return this._gradientBg.height;
  }
}
