/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-object-spread */
/* eslint-disable no-param-reassign */
import { Events, FTUXEvents, RTUXEvents } from './OMT_PlatformTrackingEvents';

const IGNORED_PARAMS = ['webGLInfo'];

let _instance = null; // singleton instance

/**
 * (FACEBOOK) social media platform specific tracking
 */
export class OMT_PlatformTracking {
  /**
   * return singleton instance
   * @returns {OMT_PlatformTracking}
   */
  static getInstance() {
    if (!_instance) _instance = new OMT_PlatformTracking();
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    this._ftuLocalStorageKey = 'fbFTUXData';
    this._isReturningUser = this._hasFTUData();
    this._Events = Events;
    this._FTUXEvents = FTUXEvents;
    this._RTUXEvents = RTUXEvents;

    // Booster ids
    this._boosterID = {
      Swap: 1,
      Wand: 2,
      HorizontalLine: 3,
      VerticalLine: 4,
      PreLevel1ExtraMoves1: 5,
      PreLevel2RandomLines: 6,
      PreLevel3ColorBomb: 7,
    };

    this._ftuParams = { FTUXVersion: 3 };
    this._rtuParams = {};

    // use to track RTU events seen this session
    this._rtuEventsSeen = {};
  }

  /**
   * get a reference to the event name list
   */
  get Events() {
    return this._Events;
  }

  /**
   * get a reference to the FTUX event name list
   */
  get FTUXEvents() {
    return this._FTUXEvents;
  }

  /**
   * get a reference to the RTUX event name list
   */
  get RTUXEvents() {
    return this._RTUXEvents;
  }

  /**
   * log an event to facebook
   * @param {string} eventName
   * @param {*} value
   * @param {Object} paramObj
   */
  logEvent(eventName, value = null, paramObj = null, includeSystemParams = true) {
    // create eventData Object and assign any passed parameters
    const eventData = {};

    if (includeSystemParams) { // assign system env / status
      for (const paramName in OMT.systemInfo.systemParams) {
        if (!IGNORED_PARAMS.includes(paramName)) {
          eventData[paramName] = OMT.systemInfo.systemParams[paramName];
        }
      }

      eventData.isGBSynced = !OMT.connect ? false : OMT.connect.isConnected();
      eventData.jsInitTime = this.timeSinceJSInit;
    }

    if (paramObj !== null) { // assign passed params
      Object.assign(eventData, paramObj);
    }

    try {
      FBInstant.logEvent(eventName, value, eventData);
      // console.log(`@@ FBInstant Event -- eventName: ${eventName}, value: ${value}, eventData: ${JSON.stringify(eventData)}`);
    } catch (error) {
      // nothing specific happening here atm
    }
  }

  /**
   * log a first time user event
   * @param {string} ftuEventName
   */
  logFTUEvent(ftuEventName, rtuEventName = null, paramObj = null) {
    // if is a returning user track that instead if defined, if not ignore event
    if (this._isReturningUser) {
      if (rtuEventName !== null) this.logRTUEvent(rtuEventName, paramObj);
      return;
    }

    try {
      let fbFTUXData = localStorage.getItem(this._ftuLocalStorageKey);
      fbFTUXData = fbFTUXData === null ? {} : JSON.parse(fbFTUXData);
      if (fbFTUXData[ftuEventName]) return; // event already triggered

      paramObj = paramObj !== null ? Object.assign({}, paramObj, this._ftuParams) : Object.assign({}, this._ftuParams);
      this.logEvent(ftuEventName, this.timeSinceJSInit, paramObj);
      fbFTUXData[ftuEventName] = 1;
      localStorage.setItem(this._ftuLocalStorageKey, JSON.stringify(fbFTUXData));
    } catch (error) {
      console.warn('failed to log ftux event to Facebook due to local storage restrictions');
    }
  }

  /**
   * log a returning user event
   * @param {string} rtuEventName
   */
  logRTUEvent(rtuEventName, paramObj = null) {
    if (!this._isReturningUser || this._rtuEventsSeen[rtuEventName]) return;
    paramObj = paramObj !== null ? Object.assign({}, paramObj, this._rtuParams) : Object.assign({}, this._rtuParams);
    this.logEvent(rtuEventName, this.timeSinceJSInit, paramObj);
    this._rtuEventsSeen[rtuEventName] = 1;
  }

  /**
   * clear FTU data from local storage
   */
  clearFTUData() {
    try {
      localStorage.removeItem(this._ftuLocalStorageKey);
    } catch (error) {
      console.warn('failed to clear FTU data');
    }
  }

  /**
   * log session start event
   */
  logSessionStartEvent() {
    this.logEvent(
      this.Events.SessionStart,
      1,
      {
        first_login: G.saveState.getLoginStats().dailyCount === 1 ? 1 : 0,
        level: G.saveState.getLastPassedLevelNr() + 1,
        lifetime: this._msToFullDays(Date.now() - G.saveState.getLoginStats().creation),
        coins: G.saveState.getCoins(),
        booster1: this._getBoosterAmountForTracking(this._boosterID.Swap),
        booster2: this._getBoosterAmountForTracking(this._boosterID.Wand),
        booster3: this._getBoosterAmountForTracking(this._boosterID.HorizontalLine),
        booster4: this._getBoosterAmountForTracking(this._boosterID.VerticalLine),
        pre_booster1: this._getBoosterAmountForTracking(this._boosterID.PreLevel1ExtraMoves1),
        pre_booster2: this._getBoosterAmountForTracking(this._boosterID.PreLevel2RandomLines),
        pre_booster3: this._getBoosterAmountForTracking(this._boosterID.PreLevel3ColorBomb),
        rewarded_ad_available: game.incentivised() ? 'yes' : 'no',
      },
    );
  }

  /**
   * log music / SFX settings
   */
  logSoundStatusEvent() {
    this.logEvent(
      this.Events.SoundStatus,
      1,
      {
        music: G.soundManager.getMusicEnabled() ? 1 : 0,
        effects: G.soundManager.getSoundEnabled() ? 1 : 0,
      },
    );
  }

  /**
   * logs marketing ad campaign payload if user entered game via an ad
   */
  logMarketingCampaignEvent() {
    if (!OMT.envData.entryPointData) return;

    // state of user sending the data
    const { entryPoint, entryPointData } = OMT.envData;

    // Check if entry point was from an ad
    const isAdEP = entryPoint === 'ad' || entryPoint === 'adCampaign';

    if (isAdEP) {
      if (!entryPointData) return;

      this.logEvent(
        this.Events.MarketingCampaign,
        1,
        {
          mAdID: entryPointData.fb_instant_game_ad_id,
          mAdsetID: entryPointData.fb_instant_game_adset_id,
          mCampaignID: entryPointData.fb_instant_game_campaign_id,
        },
      );
    }
  }

  /**
   * track to FBA the size of the localStorage in use
   */
  trackLocalStorageSize() {
    try {
      let lsTotal = 0; let xLen; let x;
      for (x in localStorage) {
        if (!localStorage.hasOwnProperty(x)) continue;
        xLen = ((localStorage[x].length + x.length) * 2);
        lsTotal += xLen;
      }
      const lsTotalKB = (lsTotal / 1024).toFixed(2);
      this.logEvent(OMT.platformTracking.Events.LocalStorageSize, parseFloat(lsTotalKB));
    } catch (error) {
      console.warn('could not track users local storage size');
    }
  }

  /**
   * check if ftux data is present
   * @returns {boolean}
   */
  _hasFTUData() {
    try {
      return localStorage.getItem(this._ftuLocalStorageKey) != null;
    } catch (error) {
      //
    }
    return false;
  }

  /**
   * get booster inventory count for tracking
   * @param {string} boosterId
   */
  _getBoosterAmountForTracking(boosterId) {
    if (!G.saveState.isBoosterUnlocked(boosterId)) return 0;
    return G.saveState.getBoosterAmount(boosterId);
  }

  /**
   * conver ms to days
   * @param {number} ms
   */
  _msToFullDays(ms) {
    return Math.floor(ms / (1000 * 60 * 60 * 24));
  }

  /**
   * true if user is a returning use
   * @returns {boolean}
   */
  get isReturningUser() {
    return this._isReturningUser;
  }

  /**
   * get time since js initialization
   */
  get timeSinceJSInit() {
    return Math.floor(Date.now() - jsInitTime);
  }
}

// this will get overriden later by OMT_Init. This was done for earlier access
if (!window.OMT) window.OMT = {};
OMT.platformTracking = OMT_PlatformTracking.getInstance();
