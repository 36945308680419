import { FX_ParticleEmitter } from '../particles/FX_ParticleEmitter';
import { FX_ParticleEmitterConfig } from '../particles/FX_ParticleEmitterConfig';

export class FX_Confetti extends FX_ParticleEmitter {
  /**
   * constructor
   */
  constructor() {
    super(new FX_ParticleEmitterConfig({
      allowTransforms: false,
      maxParticles: 60,
      spawnRandomX: 280,
      spawnRandomY: 0,
      frames: ['tri_1', 'tri_2', 'tri_3', 'tri_4', 'tri_5', 'tri_6'],
      emitAngle: Math.PI / 2,
      emitAngleVariance: Math.PI / 12,
      gravity: 40,
      minParticleVel: 20,
      maxParticleVel: 50,
      minSpawnDelay: 40,
      maxSpawnDelay: 80,
      minParticleLife: 2500,
      maxParticleLife: 5500,
    }));
    this.spawnActive = false;
  }

  /**
   * start the emitter
   */
  startTheParty() {
    this.spawnActive = true;
  }

  /**
   * stop the emitter
   */
  stopTheParty() {
    this.spawnActive = false;
  }
}
