/* eslint-disable no-case-declarations */
import TutorialSkipBtn from './G.TutorialSkipBtn';
import { BUTTON_TYPES } from '../../OMT_UI/Menus/Settings/OMT_UI_SettingsMenu';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../States/Scaling/GameScaleController';

const HAND_ANGLES = {
  n: 0, // n
  tr: 50, // top right
  br: 180, // bottom right
  bl: 230, // bottom left
};

export default class TutorialNewGingy extends Phaser.Group {
  /**
   * constructor
   * @param {string} tutorialID
   * @param {Array} textReplacements (optional) example: [{token:'%string_1%', value:'some text 1'},{token:'%string_2%', value:'some text 2'}]
   */
  constructor(tutorialID, textReplacements = []) {
    super(game);

    G.tutorialOpened = true;
    this.tutorialID = tutorialID;
    this.textReplacements = textReplacements;
    this.skipped = false;
    this.msgBar = undefined;
    this.overlay = undefined;
    this.board = undefined;
    this.boosterPanel = undefined;
    this.actionText = undefined;
    this.scheduler = undefined;
    this.finished = false;
    this.hand = undefined;
    this.tutorialSteps = undefined;
    this.state = undefined;
    this.skipBtn = undefined;
    this.tutorialData = undefined;

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    if (this.tutorialID) {
      const tutorialData = G.Utils.clone(G.json.tutorials[this.tutorialID]);
      this.initTutorial(tutorialData);
    }
  }

  /**
   * initialize the tutorial
   * @param {Object} tutorialData tutorial settings obejct
   */
  initTutorial(tutorialData) {
    this.tutorialData = tutorialData;
    if (parseInt(this.tutorialID, 10) === 1) OMT.platformTracking.logFTUEvent('FTUFirstTutorialDisplayed');

    this.msgBar = new G.GingyTutorialBar();
    this.add(this.msgBar);
    this.state = game.state.getCurrentState();
    this.overlay = this.state.overlay;
    this.board = this.state.board;
    this.boosterPanel = this.state.boosterPanel;
    this.tutorialSteps = this.tutorialData.steps;

    const boardGroup = game.add.group(this);
    boardGroup.position = this.board.position;
    boardGroup.scale = this.board.scale;

    this.scheduler = this.createScheduler(game);

    if (this.tutorialData.skipBtn) {
      this.skipBtn = new TutorialSkipBtn(this.tutorialData.skipConfig);
      this.skipBtn.signals.onClick.add(() => {
        this.skipped = true;
        this.finishTutorial();
      });
      this.add(this.skipBtn);
    }

    // for collection points above overlay
    if (this.tutorialData['SC-arrowsOverOverlay']) {
      game.world.setChildIndex(this.board.boardCollectCells, 37);
    }

    this.hand = new G.TutorialHand(this.board);
    boardGroup.add(this.hand);

    this.setBoardCandyInput([]);
    this.doNextStep();

    G.sb('onGoalAchieved').add(this.finishTutorial, this);
  }

  doNextStep() {
    const step = this.tutorialSteps.shift();
    this.state.lockInput();
    if (!step) return this.finishTutorial();

    if (step.type === 'booster') {
      this.doBoosterStep(step);
    } else {
      this.doRegularStep(step);
    }

    if (step.unlockButtons) {
      this.unlockSpecificButtons(step.unlockButtons);
    }
    return null;
  }

  doRegularStep(step) {
    this.overlay.set(step.overlayTask);

    if (step.actionText) {
      const position = this.board.cellToPxOut([
        step.actionText.position[0] - 1,
        step.actionText.position[1] - 1,
      ]);
      this.updateActionText(position[0], position[1], step.actionText.text);
    }

    this.hand.setCells(step.handCells);
    this.hand.angle = HAND_ANGLES[step.handRotation] || step.handRotation || 0;
    if (step.handTarget) {
      let target;
      const lowerHandTarget = step.handTarget.toLowerCase();
      _.forEach(BUTTON_TYPES, (key) => {
        if (key.toLowerCase() === lowerHandTarget) {
          target = {
            obj: this.state.settingsMenu,
            key,
          };
        }
      });

      if (!target) { // Wasn't a button
        if (['points', 'goal', 'moves'].indexOf(lowerHandTarget) > -1) {
          target = {
            obj: this.state.topBar,
            key: lowerHandTarget,
          };
        }
      }

      if (target) {
        target.obj.showSuggestion(target.key);
        this.handTarget = target.obj;
      }
    }
    this.setBoardCandyInput(step.inputCells);
    if (step.msg) this.msgBar.displayMsg({ ...step.msg, text: this.getStepText(step.msg.text), localize: false });

    switch (step.type.toLowerCase()) {
      case 'move':
        this.scheduler.scheduleByEvent('madeMove', () => {
          this.preFinishStep();
          this.scheduler.scheduleByEvent('actionQueueEmpty', () => {
            this.finishStep();
          });
        });
        break;
      case 'wait':
        this.scheduler.scheduleByTime(step.waitMs, () => {
          this.preFinishStepAndFinishStep();
        });
        break;
      case 'notification':
        if (step.waitMs) {
          this.scheduler.scheduleByTimeOrEvent(step.waitMs, 'madeMove', () => {
            this.preFinishStepAndFinishStep();
          });
        } else {
          this.scheduler.scheduleByEvent('madeMove', () => {
            this.preFinishStepAndFinishStep();
          });
        }
        break;
      case 'clicktocontinue':
        const clicktocontinuespr = this.makeClickOverlay();
        this.overlay.showContinuePrompt();
        clicktocontinuespr.events.onInputDown.add(() => {
          this.overlay.removeContinuePrompt();
          this.preFinishStepAndFinishStep();
          clicktocontinuespr.destroy();
        }, this);
        game.world.addChild(clicktocontinuespr);
        break;
      case 'waitorclick':
        const waitorclickspr = this.makeClickOverlay();
        this.overlay.showContinuePrompt();
        waitorclickspr.events.onInputDown.add(() => {
          G.sb('tutorialGingyOverlayClick').dispatch();
        }, this);
        this.scheduler.scheduleByTimeOrEvent(step.waitMs, 'tutorialGingyOverlayClick', () => {
          this.overlay.removeContinuePrompt();
          this.preFinishStepAndFinishStep();
          waitorclickspr.destroy();
        });
        game.world.addChild(waitorclickspr);
        break;
      case 'listenfor':
        /**
         * Listenfor type is only for listening to special events. Don't use it for events like madeMove or booster things... Maybe booster things
         * TO unlock specific buttons, check out unlockSpecificButtons() in this class.
         */
        this.scheduler.scheduleByEvent(step.listenForSignal, () => {
          this.preFinishStepAndFinishStep();
        });
        break;
      default: break;
    }
  }

  doBoosterStep(step) {
    if (step.msg) this.msgBar.displayMsg(step.msg);
    this.setBoardCandyInput([]);

    if (step.boosterLabelPivotY !== undefined) {
      this.overlay.boosterLabel.pivotYSettings = step.boosterLabelPivotY;
    }

    if (G.saveState.getBoosterAmount(step.boosterNr) === 0) G.saveState.changeBoosterAmount(step.boosterNr, 1);

    const boosterBtn = this.boosterPanel.boostersBtn[step.boosterNr - 1];
    boosterBtn.unlock();
    boosterBtn.showSuggestion();
    boosterBtn.showTutorialHighlight();
    boosterBtn.setUnlimitedMode();

    this.overlay.set([]);
    this.overlay.moveToAboveGroup(boosterBtn, 'boosterGroup', 0.6667);

    this.scheduler.scheduleByEvent('onBoosterSelect', () => {
      boosterBtn.hideTutorialHighlight();
      this.overlay.set(step.overlayTask);
      this.hand.setCells(step.handCells);
      boosterBtn.hideSuggestion();

      const action = this.state.board.actionManager.activeAction;
      if (step.boosterNr === 1) {
        action.availableCandies = [
          this.board.getCandy(step.inputCells[0], step.inputCells[1]),
        ];
        this.scheduler.scheduleByEvent('onBoosterSwapCandySelect', () => {
          action.availableCandies = [
            this.board.getCandy(step.inputCells[2], step.inputCells[3]),
          ];
        });
        this.preFinishStepAndFinishStep();
      } else {
        action.availableCandies = this.cellsToCandies(step.inputCells);
        this.scheduler.scheduleByEvent('onBoosterUsed', () => {
          this.finishStep();
        });
      }
    });
  }

  makeClickOverlay() {
    const spr = G.makeImage(0, 0, null, 0.5, null);
    spr.inputEnabled = true;
    spr.hitArea = new Phaser.Rectangle(-2000, -2000, 4000, 4000);
    return spr;
  }

  unlockSpecificButtons(buttonList) {
    for (let i = 0; i < buttonList.length; i++) {
      const target = buttonList[i];
      let buttonTarget;
      switch (target.toLowerCase()) {
        case 'menu':
          this.state.settingsMenu.unlockInput();
          break;
        case 'hint':
          this.state.settingsMenu.keepOpen = true;
          buttonTarget = this.state.settingsMenu.getButtonByKey(BUTTON_TYPES.HINT);
          if (buttonTarget) {
            buttonTarget.input.enabled = true;
          }
          break;
        default: break;
      }
    }
  }

  preFinishStepAndFinishStep() {
    this.preFinishStep();
    this.finishStep();
  }

  preFinishStep() {
    this.hideActionText();
    this.overlay.set();
    this.hand.setCells();
    if (this.handTarget) {
      this.handTarget.hideSuggestion();
      this.handTarget = null;
    }
  }

  finishStep() {
    this.overlay.boosterLabel.pivot.y = 0;
    this.doNextStep();
  }

  finishTutorial() {
    if (this.finished) return;
    this.finished = true;
    this.scheduler.cleanup();

    if (this.tutorialData['SC-arrowsOverOverlay']) {
      game.world.setChildIndex(this.board.boardCollectCells, 37);
    }

    if (this.skipBtn) this.skipBtn.turnOff();
    this.overlay.set();
    this.hand.setCells();
    this.state.unlockInput();
    this.setBoardCandyInput();

    G.tutorialOpened = false;
    G.saveState.data.finishedTutorials.push(this.tutorialID);
    G.saveState.save();

    G.sb('onTutorialFinishDisplay').dispatch();
    G.sb('onTutorialFinish').dispatch();

    this.msgBar.hide(() => {
      this.destroy();
    });
  }

  setBoardCandyInput(lockedCells) {
    if (lockedCells === undefined) {
      // unlock input
      this.board.inputController.possibleCandies = [];
    } else if (lockedCells.length === 0) {
      // lock input
      this.board.inputController.possibleCandies = [{}];
    } else {
      this.board.inputController.possibleCandies = this.cellsToCandies(lockedCells);
    }
  }

  cellsToCandies(cells) {
    const result = [];
    for (let i = 0; i < cells.length; i += 2) {
      result.push(this.board.getAllTokensInCell(cells[i], cells[i + 1]));
    }

    return ArrayUtil.flatten(result);
  }

  updateActionText(x, y, text) {
    text = this.getStepText(text);
    if (!this.actionText) {
      this.actionText = new G.Text(x, y, text, {
        style: 'font-white',
        fontSize: 40,
      }, 0.5, 400);
      this.add(this.actionText);
    }
    this.actionText.x = x;
    this.actionText.y = y;
    this.actionText.setText(text);
    this.showActionText();
    return this.actionText;
  }

  /**
   * get translated step text with raplacements
   * @param {string} stepText
   * @returns {string}
   */
  getStepText(stepText) {
    let output = OMT.language.getText(stepText);
    this.textReplacements.forEach((replacementData) => {
      output = output.replace(replacementData.token, replacementData.value);
    });
    return output;
  }

  hideActionText() {
    if (this.actionText) {
      this.actionText.visible = false;
      this.actionText.exists = false;
    }
  }

  showActionText() {
    if (this.actionText) {
      this.actionText.exists = true;
      this.actionText.visible = true;
    }
  }

  // Scheduler

  createScheduler(game) {
    const addBinding = (eventName, func) => {
      const binding = G.sb(eventName).addOnce(func);
      return binding;
    };
    const removeBinding = (binding) => {
      binding.detach();
    };
    const bindings = G.AsyncUtils.createTrackingScheduler(addBinding, removeBinding);

    const addTimeEvent = (delayMs, func) => {
      const timeEvent = game.time.events.add(delayMs, func);
      return timeEvent;
    };
    const removeTimeEvent = (timeEvent) => {
      game.time.events.remove(timeEvent);
    };
    const timeEvents = G.AsyncUtils.createTrackingScheduler(addTimeEvent, removeTimeEvent);

    const scheduleByTime = (delayMs, func) => {
      let cleanedUp = false;
      const cleanupAndCallBack = (timeEvent) => {
        if (!cleanedUp) {
          cleanedUp = true;
          timeEvents.remove(timeEvent);
          func();
        }
      };
      const timeEvent = timeEvents.add(delayMs, () => {
        cleanupAndCallBack(timeEvent);
      });
    };

    function scheduleByEvent(eventName, func) {
      let cleanedUp = false;
      const cleanupAndCallBack = (binding) => {
        if (!cleanedUp) {
          cleanedUp = true;
          bindings.remove(binding);
          func();
        }
      };
      const binding = bindings.add(eventName, () => {
        cleanupAndCallBack(binding);
      });
    }

    function scheduleByTimeOrEvent(delayMs, eventName, func) {
      let cleanedUp = false;
      const cleanupAndCallBack = ({ binding, timeEvent }) => {
        if (!cleanedUp) {
          cleanedUp = true;
          bindings.remove(binding);
          timeEvents.remove(timeEvent);
          func();
        }
      };
      const binding = bindings.add(eventName, () => {
        cleanupAndCallBack({ binding });
      });
      const timeEvent = timeEvents.add(delayMs, () => {
        cleanupAndCallBack({ timeEvent });
      });
    }

    const cleanup = () => {
      bindings.removeAll();
      timeEvents.removeAll();
    };

    return {
      scheduleByTime,
      scheduleByEvent,
      scheduleByTimeOrEvent,

      cleanup,
    };
  }
}
