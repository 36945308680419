/* eslint-disable no-unused-vars */
/* eslint-disable no-new */

const CONTEXT_FILTER = {
  NEW_CONTEXT_ONLY: 'NEW_CONTEXT_ONLY',
  INCLUDE_EXISTING_CHALLENGES: 'INCLUDE_EXISTING_CHALLENGES',
  NEW_PLAYERS_ONLY: 'NEW_PLAYERS_ONLY',
};

export const SOCIAL_STATUS = { // Not actually your social status!
  PASS: 'PASS',
  FAIL: 'FAIL',
  USER_INPUT: 'USER_INPUT',
  SAME_CONTEXT: 'SAME_CONTEXT',
};

/**
 * class for social interactions
 */
export class OMT_Social {
  /**
   * constructor
   */
  constructor() {
    this._didSendMsg = false;
  }

  /**
   * true if the user has sent a messag
   * @returns {boolean}
   */
  get didSendMsg() { return this._didSendMsg; }

  /**
   * get the current context type
   * @returns {string}
   */
  getCurrentContextType() {
    return FBInstant.context.getType();
  }

  /**
   * track admin message sent
   * @param {string} messageName
   */
  sendMessageSentToFBA(updateOptions) {
    const messageName = updateOptions.data.sendParams ? updateOptions.data.sendParams.msgName : updateOptions.data.inGameMsg;
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.AdminMessageSent, 1, { messageName });
  }

  /**
   * track admin message click
   * @param {string} messageName
   */
  sendMessageClickToFBA(messageName) {
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.AdminMessageClick, 1, { messageName });
  }

  /**
   * Opens FB dialog to pick a friend to switch the context to
   * and updates that player with a message
   * @param {Object} updateOptions
   * @param {Object} filterArray
   * @returns {boolean}
   */
  async chooseContextAndUpdate(updateOptions, filterArray) {
    this.showOverlay();
    try {
      await FBInstant.context.chooseAsync({ filters: filterArray });
      await FBInstant.updateAsync(updateOptions);
      this._didSendMsg = true;
      this.hideOverlay();
      this.sendMessageSentToFBA(updateOptions);
      return true;
    } catch (e) {
      this.hideOverlay();
      if (e.code === 'USER_INPUT') {
        // console.log('chooseContextAndUpdate() user aborted');
      } else if (e.code === 'SAME_CONTEXT') {
        // console.log('chooseContextAndUpdate() user is already in the same context');
        return true;
      } else {
        console.error(e);
      }
      return false;
    }
  }

  /**
   * Creates a context with given user and updates with a message
   * @param {string} userId
   * @param {Object} updateOptions
   * @returns {boolean}
   */
  async createContextAndUpdate(userId, updateOptions) {
    this.showOverlay();
    try {
      const inUserThread = await this.checkIfInUserThreadContext(userId);
      if (!inUserThread) await FBInstant.context.createAsync(userId);
      await FBInstant.updateAsync(updateOptions);
      this._didSendMsg = true;
      this.sendMessageSentToFBA(updateOptions);
      this.hideOverlay();
      return true;
    } catch (e) {
      this.hideOverlay();
      if (e.code === 'USER_INPUT') {
        // console.log('createContextAndUpdate() user aborted');
        return false;
      }
      if (e.code === 'SAME_CONTEXT') {
        // console.log('createContextAndUpdate() user is already in the same context');
        return true;
      }
      console.error(e);
      return false;
    }
  }

  /**
   * check if the user is already in a context with this user
   * @param {string} userId
   * @returns {boolean}
   */
  async checkIfInUserThreadContext(userId) {
    if (FBInstant.context.getType() !== 'THREAD') return false;
    const playersList = await FBInstant.context.getPlayersAsync();
    let playerObj;
    for (let i = 0; i < playersList.length; i++) {
      // eslint-disable-next-line dot-notation
      playerObj = playersList[i]['$1'];
      if (playerObj.id === userId) return true;
    }
    return false;
  }

  /**
   * get a list of players in the current context
   * @returns {Array}
   */
  async getPlayersInContext() {
    const players = await FBInstant.context.getPlayersAsync();
    return players;
  }

  /**
   * Opens FB dialog to pick a friend to switch the context to
   * but goes through an async function to check if its ok to proceed
   * before updating.
   * Returns status instead of boolean
   * @param {Object} updateOptions
   * @param {Function} check
   * @param {Object} filterArray
   * @returns {string}
   */
  async createCheckUpdateContext(updateOptions, check, filterArray) {
    this.showOverlay();

    try {
      await FBInstant.context.chooseAsync({ filters: filterArray });
      const okToProceed = await check();
      if (okToProceed) {
        await FBInstant.updateAsync(updateOptions);
        this.sendMessageSentToFBA(updateOptions);
        this._didSendMsg = true;
      }
      this.hideOverlay();
      return okToProceed ? SOCIAL_STATUS.PASS : SOCIAL_STATUS.FAIL;
    } catch (e) {
      this.hideOverlay();
      switch (e.code) {
        case SOCIAL_STATUS.USER_INPUT: return SOCIAL_STATUS.USER_INPUT;
        case SOCIAL_STATUS.SAME_CONTEXT: return SOCIAL_STATUS.SAME_CONTEXT;
        default: return e.code;
      }
    }
  }

  /**
   * choose context and invite user
   * @returns {Promise}
   */
  async chooseContextAndInvite() {
    const inGameMsg = 'Invite';
    this.sendMessageClickToFBA(inGameMsg);
    return new Promise((resolve, reject) => {
      new G.MsgInvite(OMT.envData.settings.user.avatar, async (msgObj) => {
        const img = OMT.utils.msgToBase64(msgObj);
        msgObj.destroy();
        const result = await this.chooseContextAndUpdate({
          action: 'CUSTOM',
          image: img,
          text: { // set proper text
            default: OMT.language.getText("Hey! Let's play %GameName% together!"),
            localizations: OMT.language.generateFBLocalization("Hey! Let's play %GameName% together!", '%GameName%', G.OMTsettings.global.gameName),
          },
          template: 'INVITE', // what should be here????? 🤔
          data: {
            inGameMsg,
            sendParams: this._createMsgSendParams(inGameMsg, 'SHARE'),
          },
        }, [CONTEXT_FILTER.NEW_PLAYERS_ONLY]);
        resolve(result);
      });
    });
  }

  /**
   * send global high score beat message to another user
   * NOTE: This is currently unused. Was previously handled by A2U.
   * @param {Object} currentUser
   * @param {Object} otherUser
   * @param {Function} callback
   */
  sendHighscoreBeatMsg(currentUser, otherUser, callback) {
    const inGameMsg = 'BeatHighScore';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendHighscoreBeatMsg');
    new G.MsgHighscoreBeaten(currentUser, otherUser, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      const updateOptions = {
        action: 'CUSTOM',
        image: img,
        text: {
          default: txt.replace('%NAME%', currentUser.name),
          localizations: OMT.language.generateFBLocalization(txt, '%NAME%', currentUser.name),
        },
        template: 'INVITE',
        data: {
          inGameMsg,
          sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
        },
      };

      this.createContextAndUpdate(otherUser.id, updateOptions)
        .then((success) => {
          if (success) {
            // DDNA.socialTracker.incrementParam('numFriendsChallengeSent', 1);
            // DDNA.socialTracker.markFriendInteraction(otherUser.id);
            // DDNA.tracking.socialActionEvent('BeatHighScore', '', otherUser.userId, '');
          }
          if (callback) callback(success);
        });
    });
  }

  /**
   * send level high score beat message to another user
   * @param {Object} currentUser
   * @param {Object} otherUser
   * @param {Function} callback
   */
  sendLevelHighscoreBeatMsg(currentUser, otherUser, lvlIndex, callback) {
    const inGameMsg = 'BeatLvlHighScore';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendLevelHighscoreBeatMsg');
    const scoreDifference = currentUser.score - otherUser.score;
    new G.MsgBeaten(currentUser, otherUser, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      const updateOptions = {
        action: 'CUSTOM',
        image: img,
        text: {
          default: txt.replace('%NAME%', currentUser.name).replace('%POINTS DIFFERENCE%', scoreDifference.toString()),
          localizations: OMT.language.generateFBLocalization(txt, ['%NAME%', '%POINTS DIFFERENCE%'], [currentUser.name, scoreDifference.toString()]),
        },
        template: 'INVITE',
        data: {
          inGameMsg,
          sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
          command: { goToLevel: { levelIndex: lvlIndex } },
        },
      };

      this.createContextAndUpdate(otherUser.id, updateOptions)
        .then((success) => {
          if (success) {
            // DDNA.socialTracker.incrementParam('numFriendsChallengeSent', 1);
            // DDNA.socialTracker.markFriendInteraction(otherUser.id);
            // DDNA.tracking.socialActionEvent('BeatLvlHighScore', '', otherUser.id, '');
          }
          if (callback) callback(success);
        });
    });
  }

  /**
   * send a gate response message to a user.
   * @param {string} friendId
   * @param {string} gateId
   */
  sendGateMsg(friendId, gateId) {
    const inGameMsg = 'GateMsg';
    this.sendMessageClickToFBA(inGameMsg);
    return new Promise((resolve, reject) => {
      const txt = this._pickMessage('sendGateMsg');
      new G.MsgGate(OMT.envData.settings.user.avatar, async (msgObj) => {
        const img = OMT.utils.msgToBase64(msgObj);
        msgObj.destroy();
        const updateOptions = {
          action: 'CUSTOM',
          image: img,
          text: { default: txt, localizations: OMT.language.generateFBLocalization(txt) },
          template: 'INVITE',
          data: {
            inGameMsg,
            sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
            gate: {
              sender: OMT.envData.settings.user.userId,
              forPlayer: friendId,
              senderAvatar: OMT.envData.settings.user.avatar,
              gateId,
              timestamp: Date.now(),
            },
          },
        };
        const result = await OMT.social.createContextAndUpdate(friendId, updateOptions);
        if (result) {
          // DDNA.socialTracker.markFriendInteraction(friendId);
          // DDNA.tracking.socialActionEvent('GateMsg', '', friendId, '');
        }
        resolve(result);
      });
    });
  }

  /**
   * send a gate open request message
   * @param {string} friendId
   * @param {string} gateId
   * @param {Function} callback
   */
  requestGateMsg(friendId, gateId, callback) {
    const inGameMsg = 'GateReqMsg';
    this.sendMessageClickToFBA(inGameMsg);
    return new Promise((resolve, reject) => {
      const txt = this._pickMessage('requestGateMsg');
      new G.MsgGateRequest(async (msgObj) => {
        const img = OMT.utils.msgToBase64(msgObj);
        msgObj.destroy();
        const updateOptions = {
          action: 'CUSTOM',
          image: img,
          text: { default: txt, localizations: OMT.language.generateFBLocalization(txt) },
          template: 'INVITE',
          data: {
            inGameMsg,
            sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
            gateRequest: {
              sender: OMT.envData.settings.user.userId,
              senderAvatar: OMT.envData.settings.user.avatar,
              gbUserId: OMT.envData.gbUserId,
              gateId,
              timestamp: Date.now(),
            },
          },
        };
        const result = await OMT.social.createContextAndUpdate(friendId, updateOptions);
        if (result) {
          // DDNA.socialTracker.markFriendInteraction(friendId);
          // DDNA.tracking.socialActionEvent('GateReqMsg', '', friendId, '');
        }
        resolve(result);
      });
    });
  }

  /**
   * send a message to a friend giving them coins
   * @param {string} friendId
   * @param {Function} callback
   */
  sendCoinsMessage(friendId, callback) {
    const inGameMsg = 'SEND_COIN';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendCoinsMsg');
    new G.MsgCoins(OMT.envData.settings.user.avatar, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      this.createContextAndUpdate(friendId, {
        action: 'CUSTOM',
        image: img,
        text: { default: txt, localizations: OMT.language.generateFBLocalization(txt) },
        template: 'INVITE',
        data: {
          inGameMsg,
          sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
        },
      })
        .then((success) => {
          if (callback) callback(success);
        });
    });
  }

  /**
   * send a message when a unfufilled request help scenario occurrs
   * @param {Function} callback
   */
  sendClosedHelpRequest(callback) {
    if (this._didSendMsg || OMT.envData.entryPointData === null) return;
    const inGameMsg = 'closedHelpRequest';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendClosedHelpReq', 1);
    const ctatxt = 'Play now!';
    new G.MsgHelpClosed(OMT.envData.settings.user.avatar, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      FBInstant.updateAsync({
        action: 'CUSTOM',
        cta: { default: ctatxt, localizations: OMT.language.generateFBLocalization(ctatxt) },
        image: img,
        text: {
          default: txt.replace('%GameName%', G.OMTsettings.global.gameName),
          localizations: OMT.language.generateFBLocalization(txt, '%GameName%', G.OMTsettings.global.gameName),
        },
        template: 'INVITE',
        strategy: 'LAST',
        data: {
          inGameMsg,
          sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
        },
      }).then((success) => {
        // DDNA.tracking.socialActionEvent('closedHelpRequest', '', '', '');
        if (callback) callback(success);
      });
    });
  }

  /**
   * send a message to a friend giving them a life
   * @param {Object} otherUser
   * @param {Function} callback
   */
  sendLifeMessage(otherUser, callback) {
    const inGameMsg = 'SEND_LIFE';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendLifeMsg');
    new G.MsgLives(OMT.envData.settings.user.avatar, otherUser.image, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      this.createContextAndUpdate(otherUser.userId, {
        action: 'CUSTOM',
        image: img,
        text: {
          default: txt,
          localizations: OMT.language.generateFBLocalization(txt),
        },
        template: 'INVITE',
        data: {
          inGameMsg,
          sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
          life: {
            sender: OMT.envData.settings.user.userId,
            timestamp: Date.now(),
          },
        },
      })
        .then((success) => {
          if (success) {
            // DDNA.socialTracker.incrementParam('numSendLives', 1);
            // DDNA.socialTracker.markFriendInteraction(otherUser.userId);
            // DDNA.tracking.socialActionEvent('SEND_LIFE', '', otherUser.userId, '');
            OMT.platformTracking.logEvent(OMT.platformTracking.Events.LifeSentFromUser);
            // dispatch the event to complete the send life daily mission
            G.sb('lifeMsgSent').dispatch();
          }
          callback(success);
        });
    });
  }

  /**
   * send a request life message to a friend
   * @param {Object} otherUser
   * @param {Function} callback
   */
  sendRequestLifeMessage(otherUser, callback) {
    const inGameMsg = 'REQUEST_LIFE';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendReqLifeMsg');
    new G.MsgLivesRequest(OMT.envData.settings.user.avatar, otherUser.image, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      this.createContextAndUpdate(otherUser.userId, {
        action: 'CUSTOM',
        image: img,
        text: {
          default: txt,
          localizations: OMT.language.generateFBLocalization(txt),
        },
        template: 'INVITE',
        data: {
          inGameMsg,
          sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
          lifeRequest: {
            sender: OMT.envData.settings.user.userId,
            senderAvatar: OMT.envData.settings.user.avatar,
            timestamp: Date.now(),
            context: FBInstant.context.getID(), // Todo: is this realy being used?
          },
        },
      })
        .then((success) => {
          if (success) {
            // DDNA.socialTracker.incrementParam('numLivesRequested', 1);
            // DDNA.socialTracker.markFriendInteraction(otherUser.userId);
            // DDNA.tracking.socialActionEvent('REQUEST_LIFE', '', otherUser.userId, '');
            OMT.platformTracking.logEvent(OMT.platformTracking.Events.LifeRequestedFromUser);
          }
          callback(success);
        });
    });
  }

  /**
   * send map overtake message to a friend
   * NOTE: This is currently unused. Was previously handled by A2U.
   * @param {Object} currentUser
   * @param {Object} otherUser
   * @param {Function} callback
   */
  sendOvertakenMsg(currentUser, otherUser, callback) {
    const inGameMsg = 'OvertakenMsg';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendOvertakenMsg');
    const levelNum = G.saveState.getLastPassedLevelNr() + 1;
    new G.MsgOvertaken(currentUser, otherUser, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      const updateOptions = {
        action: 'CUSTOM',
        image: img,
        text: {
          default: txt.replace('%NAME%', currentUser.name).replace('%LVLNR%', levelNum),
          localizations: OMT.language.generateFBLocalization(txt, ['%NAME%', '%LVLNR%'], [currentUser.name, levelNum]),
        },
        template: 'INVITE',
        data: {
          inGameMsg,
          sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
        },
      };
      this.createContextAndUpdate(otherUser.id, updateOptions)
        .then((success) => {
          if (success) {
            // DDNA.socialTracker.incrementParam('numFriendsChallengeSent', 1);
            // DDNA.socialTracker.markFriendInteraction(otherUser.id);
            // DDNA.tracking.socialActionEvent('OvertakenMsg', '', otherUser.userId, '');
          }
          if (callback) callback(success);
        });
    });
  }

  /**
   * Send a Request Help message to a friend
   * @param {Object} otherUser
   * @returns {Promise}
   */
  async sendRequestHelpMessage(otherUser, fromHelper = false) {
    const inGameMsg = 'requestHelpMsg';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendReqHelpMsg', 1);
    return new Promise((resolve, reject) => {
      new G.MsgRequestHelp(OMT.envData.settings.user.avatar, async (msgObj) => {
        const img = OMT.utils.msgToBase64(msgObj);
        msgObj.destroy();
        const updateOptions = {
          action: 'CUSTOM',
          image: img,
          text: { // set proper text
            default: txt,
            localizations: OMT.language.generateFBLocalization(txt),
          },
          data: {
            sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
            inGameMsg,
            requestHelp: {
              otherUserId: otherUser.id,
              fromHelper,
            },
          },
          template: 'INVITE', // what should be /here/!????? 🤔
        };

        const result = await OMT.social.createContextAndUpdate(otherUser.id, updateOptions);
        if (result) {
          // DDNA.socialTracker.markFriendInteraction(otherUser.id);
          // DDNA.tracking.socialActionEvent('requestHelpMsg', '', otherUser.userId, '');
        }
        resolve(result);
      });
    });
  }

  /**
   * Send a Ads fallback spin message to a friend
   * @param {Object} otherUser
   * @returns {Promise}
   */
  async sendAdsFallbackMessage(otherUser) {
    const inGameMsg = 'adsFallbackMsg';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendAdsFallbackMsg');
    return new Promise((resolve, reject) => {
      new G.MsgAdsFallback(OMT.envData.settings.user.avatar, async (msgObj) => {
        const img = OMT.utils.msgToBase64(msgObj);
        msgObj.destroy();
        const updateOptions = {
          action: 'CUSTOM',
          image: img,
          text: { // set proper text
            default: txt,
            localizations: OMT.language.generateFBLocalization(txt),
          },
          data: {
            sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
          },
          template: 'INVITE', // what should be /here/!????? 🤔
        };

        const result = await OMT.social.createContextAndUpdate(otherUser.id, updateOptions);
        if (result) {
          // DDNA.socialTracker.markFriendInteraction(otherUser.id);
          // DDNA.tracking.socialActionEvent('adsFallbackMsg', '', otherUser.userId, '');
        }
        resolve(result);
      });
    });
  }

  /**
   * Send a friendship chest invite message
   * @param {function} checkFunction
   */
  sendFriendshipChestInvite(checkFunction) {
    const inGameMsg = 'friendshipChestInvite';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendFriendChestInvite');
    return new Promise((resolve, reject) => {
      new G.MsgFriendshipChestInvite(OMT.envData.settings.user.avatar, async (msgObj) => {
        const img = OMT.utils.msgToBase64(msgObj);
        msgObj.destroy();
        const result = await this.createCheckUpdateContext({
          action: 'CUSTOM',
          image: img,
          text: { // set proper text
            default: txt,
            localizations: OMT.language.generateFBLocalization(txt),
          },
          template: 'INVITE', // what should be here????? 🤔
          strategy: 'IMMEDIATE',
          data: {
            sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
            inGameMsg,
            friendshipChestInvite: {
              inviterFbId: OMT.envData.settings.user.userId,
              inviterAvatar: OMT.envData.settings.user.avatar,
              inviterGbId: `${OMT.envData.gbUserId}`, // Has to be GB id
            },
          },
        }, checkFunction, [CONTEXT_FILTER.NEW_PLAYERS_ONLY]);
        resolve(result);
      });
    });
  }

  /**
   * Send a Friendship chest I have joined message
   * @param {Object} otherUser
   */
  sendFriendshipChestJoined(otherUser) {
    const inGameMsg = 'friendshipChestJoin';
    this.sendMessageClickToFBA(inGameMsg);
    const txt = this._pickMessage('sendFriendChestJoined');
    return new Promise((resolve, reject) => {
      const playerName = OMT.envData.settings.user.name;
      new G.MsgFriendshipChestJoined(OMT.envData.settings.user.avatar, otherUser.image, async (msgObj) => {
        const img = OMT.utils.msgToBase64(msgObj);
        msgObj.destroy();
        const updateOptions = {
          action: 'CUSTOM',
          image: img,
          text: { // set proper text)
            default: txt,
            localizations: OMT.language.generateFBLocalization(txt),
          },
          data: {
            sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
            inGameMsg,
            friendshipChestJoin: {},
          },
          template: 'INVITE', // what should be /here/!????? 🤔
          strategy: 'IMMEDIATE',
        };

        const result = await OMT.social.createContextAndUpdate(otherUser.userId, updateOptions);
        if (result) {
          // DDNA.socialTracker.markFriendInteraction(otherUser.userId);
          // DDNA.tracking.socialActionEvent('friendshipChestJoin', '', otherUser.userId, '');
        }
        resolve(result);
      });
    });
  }

  /**
   * send an event postcard
   * @param {number} cardIndex index of the card to display
   */
  sendEventPostCard(cardIndex) {
    const inGameMsg = 'POSTCARD_MSG';
    this.sendMessageClickToFBA(inGameMsg);
    return new Promise((resolve, reject) => {
      new G.MsgEventPostcard(cardIndex, async (msgObj) => {
        const img = OMT.utils.msgToBase64(msgObj);
        msgObj.destroy();

        const result = await this.chooseContextAndUpdate({
          action: 'CUSTOM',
          image: img,
          text: { // set proper text
            default: G.OMTsettings.postcardEvent.messageSubtext,
            localizations: OMT.language.generateFBLocalization(G.OMTsettings.postcardEvent.messageSubtext),
          },
          template: 'INVITE', // what should be here????? 🤔
          strategy: 'IMMEDIATE',
          data: {
            sendParams: this._createMsgSendParams(inGameMsg, 'ADMIN'),
            inGameMsg,
            postcardEvent: {
              eventId: G.OMTsettings.postcardEvent.eventId,
              cardIndex,
            },
          },
        }, []);
        resolve(result);
      });
    });
  }

  /**
   * Opens a FB dialog to share a message.
   * Waits for promise to come back
   * If user cancels the share, it gets caught
   * @param {string} text
   * @param {string} base64image
   * @param {Object} payload
   * @param {{type:string, additionalParam:Object}} trackingData
   */
  async share(text, base64image, payload = null, trackingData) {
    this.showOverlay();
    try {
      await FBInstant.shareAsync({
        image: base64image,
        text,
        data: payload,
        switchContext: true,
      });
      this.hideOverlay();
      // DDNA.socialTracker.incrementParam('numSocialShares', 1);
      // DDNA.tracking.socialActionEvent(trackingData.type, '', '', '', trackingData.additionalParam || {});
      return true;
    } catch (e) {
      this.hideOverlay();
      if (e.code === 'USER_INPUT') {
        // console.log('User cancelled the share');
      } else {
        console.error(e);
      }
      return false;
    }
  }

  /**
   * share daily reward message
   * @param {Function} [callback]
   */
  shareDailyRewardGetMsg(callback) {
    new G.MsgDailyRewardGet(OMT.envData.settings.user.avatar, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      this.share('', img, {
        sendParams: this._createMsgSendParams('DAILY_REWARD', 'SHARE'),
      }, { type: 'DAILY_REWARD' })
        .then(callback);
    });
  }

  /**
   * share hard level complete message
   * @param {number} lvlIndex
   * @param {Function} [callback]
   */
  shareHardLevelCompleteMsg(lvlIndex, callback) {
    new G.MsgHardLevelComplete(OMT.envData.settings.user.avatar, OMT.envData.settings.user.name, lvlIndex, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      const txt = OMT.language.getText('I just beat this hard level! Can you?');
      this.share(txt, img, {
        sendParams: this._createMsgSendParams('HARD_LEVEL', 'SHARE'),
      }, { type: 'HARD_LEVEL' })
        .then(callback);
    });
  }

  /**
   * share winning three times in a row message
   * @param {number} lvlIndex
   * @param {Function} [callback]
   */
  shareWonThreeTimes(lvlIndex, callback) {
    new G.MsgWonThreeTimes(OMT.envData.settings.user.avatar, OMT.envData.settings.user.name, lvlIndex, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      const txt = OMT.language.getText('I got a bunch of coins for winning 3 levels in a row! Try it out!');
      this.share(txt, img, {
        sendParams: this._createMsgSendParams('ACHIEVEMENT_GIFT', 'SHARE'),
      }, { type: 'ACHIEVEMENT_GIFT' })
        .then(callback);
    });
  }

  /**
   * share mission complete message
   * @param {Function} [callback]
   */
  async shareMissionCompleteMsg(callback) {
    new G.MsgMissionsComplete(OMT.envData.settings.user.avatar, async (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      const txt = OMT.language.getText('I got unlimited lives! Finish all your Daily Missions!');
      await this.share(txt, img, {
        sendParams: this._createMsgSendParams('MISSION_COMPLETE', 'SHARE'),
      }, { type: 'MISSION_COMPLETE' });
      if (callback) callback();
    });
  }

  /**
   * share treasure hunt complete message
   * @param {Function} [callback]
   */
  async shareTreasureHuntCompleteMsg(callback) {
    new G.MsgTreasureHuntComplete(OMT.envData.settings.user.avatar, async (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      const txt = OMT.language.getText('I beat Treasure Hunt and got lots of awesome boosters! Get yours too!');
      await this.share(txt, img, {
        sendParams: this._createMsgSendParams('TREASURE_HUNT', 'SHARE'),
      }, { type: 'TREASURE_HUNT' });
      if (callback) callback();
    });
  }

  /**
   * share a brag 2.0 message
   * @param {Function} callback
   * @param {number} level level #
   * @param {Object} bragRightsData object from braggingRights
   */
  async shareBrag(callback, level, bragRightsData) {
    let bannerText = OMT.language.getText('Only %percent%% of players win this level. Can you?');
    bannerText = bannerText.replace('%percent%', bragRightsData.percentage);
    const bubbleText = OMT.language.getText('Can you beat it?');

    new G.MsgBrag(OMT.envData.settings.user.avatar, bannerText, bubbleText, (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      const txt = bannerText;
      this.share(txt, img, {
        sendParams: Object.assign(this._createMsgSendParams('BRAG', 'SHARE'), { epSocialActionLevel: level }),
      }, { type: 'BRAG' })
        .then(callback);
    });
  }

  /**
   * share a fortune cookie message
   * @param {Function} callback
   * @param {string} imgID message image id ??
   * @param {string} msg message text
   * @param {string} category message category
   */
  shareFortuneCookieMsg(callback, imgID, msgData, categoryData) {
    new G.MsgFortuneCookie(imgID, msgData, categoryData, async (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      await this.share(msgData.msg, img, {
        fortuneCookie: {
          categoryData,
          msgData,
        },
        sendParams: this._createMsgSendParams('FORTUNE_COOKIE', 'SHARE'),
      }, { type: 'FORTUNE_COOKIE' });
      if (callback) callback();
    });
  }

  /**
   * share a mailbox message
   * @param {Function} callback
   * @param {string} imgID message image id ??
   * @param {string} msgData message text
   * @param {string} categoryData message category
   * @param {Object} avatarData avatar position
   */
  shareMailboxMsg(callback, imgID, msgData, categoryData, avatarData, additionalTracking) {
    new G.MsgMailbox(imgID, msgData, avatarData, async (msgObj) => {
      const img = OMT.utils.msgToBase64(msgObj);
      msgObj.destroy();
      await this.share(msgData.msg, img, {
        mailbox: {
          categoryData,
          msgData,
        },
        sendParams: this._createMsgSendParams('MAILBOX', 'SHARE'),
      }, { type: 'MAILBOX', additionalParam: additionalTracking });
      if (callback) callback();
    });
  }

  /**
   * share an event postcard
   * @param {number} cardIndex index of the card to display
   */
  async shareEventPostcard(cardIndex) {
    const inGameMsg = 'EVENT_SHARE';

    return new Promise((resolve, reject) => {
      const postcardText = OMT.language.getText(G.OMTsettings.postcardEvent.cardData.postcardData[cardIndex].speechText);
      new G.MsgEventPostcard(cardIndex, async (msgObj) => {
        const img = OMT.utils.msgToBase64(msgObj);
        msgObj.destroy();

        const result = await this.share(postcardText, img, {
          postcardEvent: {
            eventId: G.OMTsettings.postcardEvent.eventId,
            cardIndex,
          },
          sendParams: this._createMsgSendParams(inGameMsg, 'SHARE'),
        }, { type: inGameMsg });
        resolve(result);
      });
    });
  }

  /**
   * share an event postcard
   * @param {number} cardIndex index of the card to display
   */
  async shareTreasureHuntBrag(badgeType, score) {
    const inGameMsg = 'TREASURE_HUNT_BRAG';

    return new Promise((resolve, reject) => {
      const text = OMT.language.getText('%NAME% collected').replace('%NAME%', OMT.envData.settings.user.name);
      new G.MsgTreasureHuntBrag(badgeType, text, score, async (msgObj) => {
        const img = OMT.utils.msgToBase64(msgObj);
        msgObj.destroy();

        const result = await this.share(text, img, {
          treasureHunt: {
            inviter: OMT.envData.settings.user.userId,
          },
          sendParams: this._createMsgSendParams(inGameMsg, 'SHARE'),
        }, { type: inGameMsg });
        resolve(result);
      });
    });
  }

  /**
   * get the msg send param object for sending along with user messages
   * @param {string} msgId id / name of message
   * @returns {object}
   */
  _createMsgSendParams(msgName, msgType) {
    const stateObj = {
      senderId: OMT.envData.settings.user.userId,
      senderName: OMT.envData.settings.user.name,
      senderLevel: G.saveState.getLastPassedLevelNr(),
      senderDaysInGame: DDNA.tracking.getDataCapture().getPlayerCharacterizationParam('daysFromFirstLogin'),
      senderSessionNumber: DDNA.tracking.getDataCapture().getPlayerCharacterizationParam('sessionNumber'),
      senderCoins: G.saveState.getCoins(),
      senderIsPayer: G.saveState.getIAPCount() > 0 ? 1 : 0,
      timestamp: DDNA.utils.getTimestamp(),
      // epContextType : ?????
      // epPromoSourceId : crosspromo id,
      msgType,
      // epMsgContentID : ????
      msgLocale: OMT.envData.settings.env.locale,
      msgID: game.rnd.uuid().split('-').pop(),
      msgName,
    };
    return stateObj;
  }

  /**
   * Randomly picks a message from a given key from G.json.shareMessageText
   * Can optionally force a choice
   * @param {string} key
   * @param {number} index force function to pick a particular message (optional)
   * @returns {string}
   */
  _pickMessage(key, index) {
    if (Array.isArray(G.json.shareMessageText[key])) {
      if (index != null) return G.json.shareMessageText[key][index];
      return game.rnd.pick(G.json.shareMessageText[key]);
    }
    return G.json.shareMessageText[key];
  }

  /**
   * show the overlay / interactive event blocker
   */
  showOverlay() {
    this.hideOverlay();
    this._overlay = new G.AdLoadOverlay();
  }

  /**
   * hide the overlay / interactive event blocker
   */
  hideOverlay() {
    if (!this._overlay) return;
    this._overlay.destroy();
    this._overlay = null;
  }

  /**
   * true if the overlay is active
   * @returns {boolean}
   */
  get isOverlayVisible() {
    return !(this._overlay === null || this._overlay === undefined);
  }
}
