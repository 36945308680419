/* eslint-disable no-useless-constructor */
/* eslint-disable import/no-unresolved */

import { trackBotMessageConversion as GBC_trackBotMessageConversion } from '@sgorg/game-backend-client';

export const CAN_SUBSCRIBE_BOT_FEEDBACK = {
  YES: 'YES', // Yes you can subscribe
  NO: 'NO', // No you cannot subscribe
  ALREADY: 'ALREADY', // Like already subscribed
};

export const BOT_MESSAGE_TYPE = {
  NOT_A_BOT_MESSAGE: 'notABotMessage',
  WITH_PAYLOAD: 'withPayload',
  WITHOUT_PAYLOAD: 'withoutPayload',
};

/**
* class for handling things related to bots / and bot messaging
*/
export class OMT_Bots {
  /**
   * constructor
   */
  constructor() {
    //
  }

  /**
   * check the opt-in state and attempt to subscribe
   */
  async checkStateAndAttemptSubcribe() {
    const result = await this.canSubscribeToBot();
    if (result === CAN_SUBSCRIBE_BOT_FEEDBACK.YES) {
      try {
        await this.subscribeToBot();
      } catch (err) {
        //
      }
    }
  }

  /**
   * attempt to subscribe to the game bot
   */
  async subscribeToBot() {
    const status = await FBInstant.player.subscribeBotAsync();
    return status;
  }

  /**
   * check if the user can subscribe to the game bot
   * Returns 3 strings of YES, NO, and ALREADY
   * @returns {Promise<string>} CAN_SUBSCRIBE_BOT_FEEDBACK
   */
  async canSubscribeToBot() {
    let result = CAN_SUBSCRIBE_BOT_FEEDBACK.NO;
    try {
      const facebookSays = await FBInstant.player.canSubscribeBotAsync();
      if (facebookSays) {
        result = CAN_SUBSCRIBE_BOT_FEEDBACK.YES;
      }
    } catch (err) {
      if (err) {
        if (err.code === 'INVALID_OPERATION') {
          const lowerMessage = err.message.toLowerCase();
          const alreadySubscribed = lowerMessage.indexOf('already subscribe') > -1;
          if (alreadySubscribed) {
            result = CAN_SUBSCRIBE_BOT_FEEDBACK.ALREADY;
          }
        } else if (err.code === 'RATE_LIMITED') {
          result = CAN_SUBSCRIBE_BOT_FEEDBACK.NO;
        }
      }
    }
    return result;
  }

  /**
   * detect and track a bot-optIn state change
   * @returns {Promise}
   */
  async detectBotOptInStateChange() {
    const canSubscribe = await this.canSubscribeToBot();
    if (canSubscribe === CAN_SUBSCRIBE_BOT_FEEDBACK.ALREADY) {
      const stateChanged = G.saveState.setBotOptIn(true);
      if (stateChanged) {
        // DDNA.tracking.collectEvent('botOptionsChanged', { botSubscribtionStatus: 1, botSubscriptionChangeGameArea: 'Manual' });
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.BotOptIn);
      } else {
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.BotAlreadySubscribed);
      }
    } else if (canSubscribe === CAN_SUBSCRIBE_BOT_FEEDBACK.YES) {
      const stateChanged = G.saveState.setBotOptIn(false);
      if (stateChanged) {
        // DDNA.tracking.collectEvent('botOptionsChanged', { botSubscribtionStatus: 0, botSubscriptionChangeGameArea: 'Manual' });
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.BotOptOut);
        OMT.notifications.unscheduleAllGameTriggeredMessages('bot');
      }
    } else { // canSubscribe == CAN_SUBSCRIBE_BOT_FEEDBACK.NO user is no longer able to subscribe through the game
      G.saveState.setBotOptIn(false);
    }
  }

  /**
   * check if entryPoint was through a bot message
   * @returns {BOT_MESSAGE_TYPE}
   */
  get entryPointIsBotMsg() {
    // Old-style bot message with payload (pre OMT-4677)
    const {
      messageId,
      messageCategory,
      gamePayload,
    } = OMT.envData.entryPointData;

    if (messageId != null && messageCategory != null && gamePayload != null) {
      return BOT_MESSAGE_TYPE.WITH_PAYLOAD;
    }

    // New-style bot message without payload (post OMT-4677)
    const {
      editorDefinedEntryPoint,
    } = OMT.envData.entryPointData;

    if (editorDefinedEntryPoint === 'botMsgWithoutPayload' && messageId != null) {
      return BOT_MESSAGE_TYPE.WITHOUT_PAYLOAD;
    }

    return BOT_MESSAGE_TYPE.NOT_A_BOT_MESSAGE;
  }

  /**
   * track bot message coversation in game-backend
   * @returns {Promise}
   */
  async trackBotConversion() {
    if (!OMT.connect.isConnected()) return;
    try {
      const { entryPointData } = OMT.envData;
      await GBC_trackBotMessageConversion({ messageId: entryPointData.messageId });
      console.log('GB bot message tracked');
    } catch (error) {
      console.log('Error message', error.message);
      console.log('Error code', error.code);
    }
  }

  get scheduler() {
    return this._scheduler;
  }
}
