/* eslint-disable operator-linebreak */
/* eslint-disable func-names */
// OVERWRITES

/**
 * Overwritten group destroy function.
 * Sets alive to false.
 * @param {boolean} destroyChildren
 * @param {boolean} soft
 */
Phaser.Group.prototype.destroy = function (
  destroyChildren = true,
  soft = false,
) {
  if (this.game === null || this.ignoreDestroy) {
    return;
  }

  this.onDestroy.dispatch(this, destroyChildren, soft);

  this.removeAll(destroyChildren);

  this.cursor = null;
  this.filters = null;
  this.alive = false;
  this.pendingDestroy = false;

  if (!soft) {
    if (this.parent) {
      this.parent.removeChild(this);
    }

    this.game = null;
    this.exists = false;
  }

  this._destroyCachedSprite();
};

{
  /**
   * Exports children of an object to string representation.
   * @param {any} obj
   * @returns {any[]} result
   */
  const exportChildren = (obj) => {
    const result = [];

    for (let i = 0; i < obj.children.length; i++) {
      const child = obj.children[i];
      if (child.exportToString) {
        result.push(child.exportToString());
      }
    }

    return result;
  };

  /**
   * Overwritten group exportToString function.
   * @returns {object} exportObj
   */
  Phaser.Group.prototype.exportToString = function () {
    const exportObj = {
      type: 'GROUP',
      x: this.x,
      y: this.y,
      scale: [this.scale.x, this.scale.y],
      angle: this.angle,
      children: exportChildren(this),
    };

    return exportObj;
  };

  /**
   * Overwritten image exportToString function.
   * @returns {object} exportObj
   */
  Phaser.Image.prototype.exportToString = function () {
    const exportObj = {
      type: 'IMG',
      x: this.x,
      y: this.y,
      frame: this.frameName,
      anchor: [this.anchor.x, this.anchor.y],
      scale: [this.scale.x, this.scale.y],
      angle: this.angle,
      children: exportChildren(this),
    };

    return exportObj;
  };
}

{
  const orgDestroy = Phaser.Image.prototype.destroy;
  /**
   * Overwritten image destroy function.
   * @param {boolean} destroyChildren
   * @param {boolean} destroyTexture
   */
  Phaser.Image.prototype.destroy = function (destroyChildren, destroyTexture) {
    if (this.tintedTexture) {
      Phaser.CanvasPool.removeByCanvas(this.tintedTexture);
      this.tintedTexture = null;
    }
    orgDestroy.bind(this)(destroyChildren, destroyTexture);
  };
}

//

G.phaserStageVisibilityChanged = new Phaser.Signal();

/**
 * Overwritten stage visibilityChange function.
 * @param {any} event
 */
Phaser.Stage.prototype.visibilityChange = function (event) {
  if (event.type === 'pagehide' || event.type === 'blur') {
    this.game.focusLoss(event);
    return;
  }

  if (event.type === 'pageshow' || event.type === 'focus') {
    this.game.focusGain(event);
    return;
  }

  const paused =
    document.hidden ||
    document.mozHidden ||
    document.msHidden ||
    document.webkitHidden ||
    event.type === 'pause';

  G.phaserStageVisibilityChanged.dispatch(paused);
};

//

G.phaserStageVisibilityChanged.add((paused) => {
  if (paused) G.Utils.resetInput(game);
});

//
/**
 * Overwritten text updateText function.
 */
Phaser.Text.prototype.updateText = function () {
  this.texture.baseTexture.resolution = this._res;
  this.context.font = this.style.font;
  let outputText = this.text;

  if (
    this.characterLimitSize > -1 &&
    this.characterLimitSize < outputText.length
  ) {
    outputText =
      this.text.substring(0, this.characterLimitSize) +
      this.characterLimitSuffix;
  }

  if (this.style.wordWrap) {
    outputText = this.runWordWrap(this.text);
  }

  //  Split text into lines
  const lines = outputText.split(this.splitRegExp);

  //  Calculate text width
  const { tabs } = this.style;
  const lineWidths = [];
  let maxLineWidth = 0;
  const fontProperties = this.determineFontProperties(this.style.font);

  let drawnLines = lines.length;

  if (this.style.maxLines > 0 && this.style.maxLines < lines.length) {
    drawnLines = this.style.maxLines;
  }

  this._charCount = 0;

  for (let i = 0; i < drawnLines; i++) {
    let lineWidth = this.style.strokeThickness + this.padding.x;
    if (tabs === 0) {
      //  Simple layout (no tabs)

      if (
        this.colors.length > 0 ||
        this.strokeColors.length > 0 ||
        this.fontWeights.length > 0 ||
        this.fontStyles.length > 0
      ) {
        lineWidth += this.measureLine(lines[i]);
      } else {
        lineWidth += this.context.measureText(lines[i]).width;
      }

      // Adjust for wrapped text
      if (this.style.wordWrap) {
        lineWidth -= this.context.measureText(' ').width;
      }
    } else {
      //  Complex layout (tabs)
      const line = lines[i].split(/(?:\t)/);
      lineWidth = this.padding.x + this.style.strokeThickness;

      if (Array.isArray(tabs)) {
        let tab = 0;

        for (let c = 0; c < line.length; c++) {
          let section = 0;

          if (
            this.colors.length > 0 ||
            this.strokeColors.length > 0 ||
            this.fontWeights.length > 0 ||
            this.fontStyles.length > 0
          ) {
            section = this.measureLine(line[c]);
          } else {
            section = Math.ceil(this.context.measureText(line[c]).width);
          }

          if (c > 0) {
            tab += tabs[c - 1];
          }

          lineWidth = tab + section;
        }
      } else {
        for (let c = 0; c < line.length; c++) {
          //  How far to the next tab?
          if (
            this.colors.length > 0 ||
            this.strokeColors.length > 0 ||
            this.fontWeights.length > 0 ||
            this.fontStyles.length > 0
          ) {
            lineWidth += this.measureLine(line[c]);
          } else {
            lineWidth += Math.ceil(this.context.measureText(line[c]).width);
          }

          const diff = this.game.math.snapToCeil(lineWidth, tabs) - lineWidth;

          lineWidth += diff;
        }
      }
    }

    lineWidths[i] = Math.ceil(lineWidth);
    maxLineWidth = Math.max(maxLineWidth, lineWidths[i]);
  }

  this.canvas.width = maxLineWidth * this._res;
  if (this.canvas.width % 2 === 1) {
    this.canvas.width++;
  }

  //  Calculate text height
  const lineHeight =
    fontProperties.fontSize + this.style.strokeThickness + this.padding.y;
  let height = lineHeight * drawnLines;
  let lineSpacing = this._lineSpacing;

  if (lineSpacing < 0 && Math.abs(lineSpacing) > lineHeight) {
    lineSpacing = -lineHeight;
  }

  //  Adjust for line spacing
  if (lineSpacing !== 0) {
    height +=
      lineSpacing > 0
        ? lineSpacing * lines.length
        : lineSpacing * (lines.length - 1);
  }

  this.canvas.height = height * this._res;
  if (this.canvas.height % 2 === 1) {
    this.canvas.height++;
  }

  this.context.scale(this._res, this._res);

  if (navigator.isCocoonJS) {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  if (this.style.backgroundColor) {
    this.context.fillStyle = this.style.backgroundColor;
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  this.context.fillStyle = this.style.fill;
  this.context.font = this.style.font;
  this.context.strokeStyle = this.style.stroke;
  this.context.textBaseline = 'alphabetic';

  this.context.lineWidth = this.style.strokeThickness;
  this.context.lineCap = 'round';
  this.context.lineJoin = 'round';

  let linePositionX;
  let linePositionY;

  this._charCount = 0;

  //  Draw text line by line
  for (let i = 0; i < drawnLines; i++) {
    //  Split the line by

    linePositionX = this.style.strokeThickness / 2;
    linePositionY =
      this.style.strokeThickness / 2 + i * lineHeight + fontProperties.ascent;

    if (i > 0) {
      linePositionY += lineSpacing * i;
    }

    if (this.style.align === 'right') {
      linePositionX += maxLineWidth - lineWidths[i];
    } else if (this.style.align === 'center') {
      linePositionX += (maxLineWidth - lineWidths[i]) / 2;
    }

    if (this.autoRound) {
      linePositionX = Math.round(linePositionX);
      linePositionY = Math.round(linePositionY);
    }

    if (
      this.colors.length > 0 ||
      this.strokeColors.length > 0 ||
      this.fontWeights.length > 0 ||
      this.fontStyles.length > 0
    ) {
      this.updateLine(lines[i], linePositionX, linePositionY);
    } else {
      if (this.style.stroke && this.style.strokeThickness) {
        this.updateShadow(this.style.shadowStroke);
        if (tabs === 0) {
          this.context.strokeText(lines[i], linePositionX, linePositionY);
        } else {
          this.renderTabLine(lines[i], linePositionX, linePositionY, false);
        }
      }

      if (this.style.fill) {
        this.updateShadow(this.style.shadowFill);
        if (tabs === 0) {
          this.context.fillText(lines[i], linePositionX, linePositionY);
        } else {
          this.renderTabLine(lines[i], linePositionX, linePositionY, true);
        }
      }
    }
  }
  this.updateTexture();
  this.dirty = false;
};
