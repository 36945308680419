/* eslint-disable no-undef */

import { CandyType_Basic } from './CandyType_Basic';
import { ACTION_TYPES } from '../../Actions/Action';
import { ORIENTATION } from '@omt-game-board/Managers/GameEnums';

const timerStart = 5;

/**
 * This is the Fortune Cookie piece that shows up on the game board.
 * Luckily all about it is in this class (not sure if that's good) (Unlike the treasure chest)
 * @author Sandra Koo
 */
export class CandyType_FortuneCookie extends CandyType_Basic {
  /**
   * constructor
   * @param {BoardGameHooks} gameHooks
   */
  constructor(gameHooks) {
    super(gameHooks);
    this._fortuneCookieDataManager = gameHooks.fortuneCookieDataManager;
  }

  /**
   * Init happens every time a candy comes in.
   * The candy's basetype (this thing) always changes and re-inits when the candy is reinitialized
   * @param {Candy.js} candy
   * @param {Object} config
   */
  init(candy, config) {
    super.init(candy, config);

    const { board } = this._candy;
    const { lvlDataManager } = board;
    this._timerConfig = lvlDataManager.getLayoutConfig('fortuneCookieTimer') || { x: 0, y: 0 };

    // Fortune cookie has been init and spawned. Used for tracking
    this._fortuneCookieDataManager.incrementSpawned();

    // The fortune cookie's timer (as number)
    this._timerTime = timerStart;

    // Tween holder
    this._glowTween = undefined;
    this._timerGlowTween = undefined;

    // Assets
    this._glow = G.makeImage(0, 0, 'candy_fortune_glow', 0.5, this._candy); // The glow that makes the fortune cookie brighter
    this._glow.alpha = 0;
    this._glow.visible = false;
    this._timer = G.makeImage(0, 0, 'candy_fortune_timer', 0.5, this._candy); // The timer part
    this._text = new G.Text(0, 8, this._timerTime.toString(), { style: 'font-blue', fill: 0 }, 0.5, this._timer.width * 1.5, this._timer.height * 1.5, true, 'center');
    this._timer.addChild(this._text); // The text
    this._timer.x = Math.round(this._timerConfig.x);
    this._timer.y = Math.round(this._timerConfig.y);

    this._timerGlow = G.makeImage(0, 0, 'candy_fortune_timer_glow', 0.5, this._timer); // The glow on the timer
    this._timerGlow.alpha = 0;
    this._timerGlow.visible = false;

    /**
     * actionQueueEmptyAfterMove gets called after the init, so the fortune cookie unfortunately drops in, and the signal
     * is called, reducing the counter by 1. The variable is here to prevent that
     */
    this._ignoreEntryTick = true;
    this._trigger = G.sb('actionQueueEmptyAfterMove').add(() => {
      if (this._ignoreEntryTick) {
        this._ignoreEntryTick = false;
      } else {
        this._tickTimer();
      }
    });
  }

  /**
   * When its successfully exploded
   */
  onHit() {
    this._successfulBreak();
  }

  /**
   * Clears up everything unique to the fortune cookie and nulls it.
   * And then removes it
   */
  _clearCookie() {
    if (this._trigger) {
      this._trigger.detach();
      this._trigger = null;
    }
    if (this._glow) {
      this._glow.destroy();
      this._glow = null;
    }
    if (this._timer) {
      this._timer.destroy();
      this._timer = null;
    }
    if (this._timerGlowTween) {
      this._timerGlowTween.stop();
      this._timerGlowTween = null;
    }
    if (this._glowTween) {
      this._glowTween.stop();
      this._glowTween = null;
    }
    if (this._candy) this._candy.remove(); // And then removes it
  }

  /**
   * Each move ticks the timer
   */
  _tickTimer() {
    if (!this._timer) { return; }
    const { board } = this._candy;
    if (!board.fortuneCookie.collected) { // Do not tick if the cookie is already collected
      this._timerTime--; // Decrease timer

      if (this._timerTime <= 0) { // Is breaking now?
        this._toggleInput(true);
        this._failedBreak(); // blow up sadly
      }

      const tweenTime = 250;
      const tw = game.add.tween(this._timer.scale) // Grow big
        .to({ x: 1.2, y: 1.2 }, tweenTime, Phaser.Easing.Sinusoidal.In, false);
      tw.onComplete.addOnce(() => {
        game.add.tween(this._timer.scale) // Grow small
          .to({ x: 1, y: 1 }, tweenTime, Phaser.Easing.Sinusoidal.Out, true)
          .onComplete.add(() => {
            if (this._glow) {
              this._handleGlow();
            }
          });
        this._text.setText(this._timerTime.toString()); // Change timer
      });
      tw.start();
    }
  }

  /**
   * Changes the inputController on the board directly.
   * I'm not sure how to change it indirectly.
   * @param {Boolean} value
   */
  _toggleInput(value) {
    const { board } = this._candy;
    if (!board.fortuneCookie.collected) {
      board.inputController.locked = value;
    }
  }

  /**
   * At certain moments, a glowing emphasis is visible on the cookie.
   * This handles it
   */
  _handleGlow() {
    const stopTween = () => { // Stop current tweens
      if (this._glow) {
        this._glow.visible = false;
      }
      if (this._timerGlow) {
        this._timerGlow.visible = false;
      }
      if (this._timerGlowTween) {
        this._timerGlowTween.stop();
        this._timerGlowTween = null;
      }
      if (this._glowTween) {
        this._glowTween.stop();
        this._glowTween = null;
      }
    };
    switch (this._timerTime) {
      case 2:
        stopTween();
        this._glow.visible = true;
        this._timerGlow.visible = true;
        // Glows perpetually at 1000ms blinks
        this._timerGlowTween = game.add.tween(this._timerGlow)
          .to({ alpha: 1 }, 1000, Phaser.Easing.Linear.None, true, 0, -1, true);
        this._glowTween = game.add.tween(this._glow)
          .to({ alpha: 1 }, 1000, Phaser.Easing.Linear.None, true, 0, -1, true);
        break;
      case 1:
        stopTween();
        this._glow.visible = true;
        this._timerGlow.visible = true;
        // Glows perpetually at 500ms blinks
        this._timerGlowTween = game.add.tween(this._timerGlow)
          .to({ alpha: 1 }, 500, Phaser.Easing.Linear.None, true, 0, -1, true);
        this._glowTween = game.add.tween(this._glow)
          .to({ alpha: 1 }, 500, Phaser.Easing.Linear.None, true, 0, -1, true);
        break;
      default: stopTween(); // Hides it otherwise
    }
  }

  /**
   * When the cookie runs out of time, it goes away
   */
  _failedBreak() {
    const { board } = this._candy;
    const tweenTime = 1000;

    game.time.events.add(500, () => { // Approx delay for timer to finish scaling from above
      if (!board.fortuneCookie.collected) { // Double checking if the cookie is still there
        game.add.tween(this._candy.scale) // Shrinks away
          .to({ x: 0.01, y: 0.01 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true)
          .onComplete.add(() => {
            G.sb('fx').dispatch('burstCandy', this._candy, this._candy); // Cookie pops
            this._clearCookie(); // Its gone
            board.actionManager.newAction(ACTION_TYPES.PROCESS_FALL); // Other pieces fall
            this._toggleInput(false); // Input is returned
            this._candy.scale.set(1); // The candy scale is reset so the piece can be reused again
          });
      }
    });
  }

  /**
   * When the piece successfully breaks before time goes out
   */
  _successfulBreak() {
    const { board } = this._candy;
    const { uiTopBar } = this._gameHooks;

    board.fortuneCookie.collected = true; // Set this to be true NOW so the timer doesn't screw up!
    this._fortuneCookieDataManager.incrementCollected(); // Tracking
    const fadeoutDelay = 2500; // The time before the indicators fade out

    const pxBoard = board.cellToPxOut([this._candy.cellX, this._candy.cellY]);
    const newFortune = G.makeImage(pxBoard[0], pxBoard[1], 'candy_fortune', 0.5); // The fortune cookie asset that flies away
    board.fortuneCookie.icon = newFortune;
    const originScale = board.scale.x;
    newFortune.scale.set(originScale);
    const targetEndLayer = board.candiesLayer.fxTopGroup; // The target end layer to go into
    const targetFortuneScale = 0.9;
    const modPointDrop = game.height / 2; // Mid point drop for bezier

    let targetPos;
    let maxTextWidth = 0;
    if (this._gameHooks.orientation === ORIENTATION.vertical) {
      const topBarBG = uiTopBar.bg;
      targetPos = {
        x: topBarBG.x + topBarBG.width * 0.5 - (newFortune.width * targetFortuneScale) / 2,
        y: ((newFortune.height * targetFortuneScale) / 2) + topBarBG.y + topBarBG.height * 0.8,
      };
      maxTextWidth = topBarBG.x;
    } else {
      targetPos = {
        x: game.world.bounds.x + game.width - (newFortune.width / 2) - 5,
        y: game.world.bounds.y + 5 + newFortune.height / 2,
      };
      maxTextWidth = game.width / 2;
    }

    // Fancy text You collected!
    const textContainer = new Phaser.Group(game, null);
    const textPos = targetEndLayer.toLocal(new Phaser.Point(targetPos.x - newFortune.width / 2, targetPos.y), board.parent);
    textContainer.x = Math.round(textPos.x) + 100;
    textContainer.y = Math.round(textPos.y);

    const textBGContainer = new Phaser.Group(game, null);
    const newFortuneText = new G.Text(0, 0, this._gameHooks.getText('Fortune Cookie collected'), 'fortuneCookie-gameBoardWhite', [0, 0.5],
      maxTextWidth, (newFortune.height / originScale) * targetFortuneScale);
    newFortuneText.y = Math.round(newFortuneText.height / 16);

    const textBG = G.makeImage(-20, -newFortuneText.height / 2 - 1, 'horizontal-fortuneCookieBanner', null);
    textBG.scale.setTo(0.75);

    const textMask = new Phaser.Graphics(game); // Fancy text mask
    textMask.beginFill(0);
    textMask.drawRect(0, 0, newFortuneText.width * 1.5, newFortuneText.height);
    textMask.endFill();
    textMask.x = -textMask.width - 57;
    textMask.y = -textMask.height / 2;
    textMask.dirty = true;

    const targetTextX = Math.round(-newFortuneText.width) - 80;

    textBGContainer.mask = textMask;
    textBGContainer.scale.setTo(0.9);

    textBGContainer.addChild(textBG);
    textBGContainer.addChild(newFortuneText);
    textContainer.addChild(textMask);
    textContainer.addChild(textBGContainer);
    targetEndLayer.addChild(textContainer);

    // Copying Taylor's amazing bezier path swoop aninmation
    const tweenTime = 1000;
    const m1 = { x: newFortune.x, y: newFortune.y + modPointDrop };
    const m2 = { x: targetPos.x, y: targetPos.y + modPointDrop };
    G.sb('fxTop').dispatch('burstConcreteAnim', this._candy, this._candy); // Break fx
    this._clearCookie(); // Cookie gone
    game.add.tween(newFortune)
      .to({
        x: [newFortune.x, m1.x, m2.x, targetPos.x], // Bezier Tweeeeeeeeeeeeeeeeeeeeen
        y: [newFortune.y, m1.y, m2.y, targetPos.y],
      },
      tweenTime, Phaser.Easing.Sinusoidal.InOut, true).interpolation((v, k) => Phaser.Math.bezierInterpolation(v, k))
      .onComplete.add(() => {
        const pos = targetEndLayer.toLocal(newFortune.position, newFortune.parent); // Throw it into a layer underneath the overlay
        targetEndLayer.addChild(newFortune);
        newFortune.x = pos.x;
        newFortune.y = pos.y;
        game.add.tween(textBGContainer)
          .to({ x: targetTextX }, tweenTime, Phaser.Easing.Back.Out, true);

        _.delay(() => { // After 5 seconds, the cookie and text fades out
          if (textContainer) {
            game.add.tween(textContainer) // fade out
              .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true)
              .onComplete.add(() => {
                textContainer.destroy(); // Destroy!
              });
          }
        }, fadeoutDelay);
      });
    game.add.tween(newFortune.scale)
      .to({
        x: [newFortune.scale.x, 2.5 * targetEndLayer.scale.x, targetFortuneScale * targetEndLayer.scale.x],
        y: [newFortune.scale.y, 2.5 * targetEndLayer.scale.y, targetFortuneScale * targetEndLayer.scale.y],
      }, tweenTime, Phaser.Easing.Sinusoidal.InOut).start()
      .onComplete.add(() => { newFortune.scale.set(targetFortuneScale); });
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._clearCookie();
  }
}
