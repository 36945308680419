/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable func-names */
import { Window } from '../../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS, BUTTONSTATE } from '../../../OMT_UI/OMT_UI_SquareButton';
import { LIGHTS_MODES } from '../../SpinningWheels/RealMoneyWheel/Props/LightsConfig';
import OMT_RealMoneyPrizeWheel from '../../SpinningWheels/RealMoneyWheel/OMT_RealMoneyPrizeWheel';
import {
  RMWHEEL_MODES,
  RMWHEEL_EVENTS,
  RMWHEEL_EPS,
  RMWHEEL_PCMETRICS,
} from '../../SpinningWheels/RealMoneyWheel/rmWheelEnums';
import { createRealMoneySpinId, isConversionToEnum } from '../../SpinningWheels/RealMoneyWheel/RealMoneyWheelHelpers';
import ShopUtils from '../Shop/Shop_Utils';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { realMoneyWheelResponsiveConfig as RESPONSIVE_CONFIG } from './PrizeWheelWindowConfig';

/**
 * Window Class that only contains the real money wheel
 *
 * Config:
 * afterQuit: {Function} callback that is called when this window is closed.
 *            this._isUnavailable is passed to this function (optional)
 * wheelModeOverride: {RMWHEEL_MODE} overrides usual conversion status check to show conversion or high-value wheel (optional)
 * entryPoint: {RMWHEEL_EPS} the entry point for this window (required)
 * freeSpin: {boolean} the first spin is free (optional)
 * predeterminedPrize: {number} the prize to given on the first spin, -1 for a random prize (optional)
 * worldState: {boolean} is the player on the saga map? (required)
 * resolvePendingSpins: {boolean} check for pending spins and handle them first before allowing more purchases
 */
class Window_RealMoneyWheel extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} config
   */
  constructor(parent, config) {
    super(parent);

    // Assign config params to internal params
    this._defaultEntryPoint = config.entryPoint;
    this._afterQuit = config.afterQuit;
    this._worldState = config.worldState;
    this._resolvingPendingSpins = config.resolvePendingSpins || false;
    // Note: The follow params could be automatically overriden when resolving pending spins
    this._entryPoint = config.entryPoint;
    this._freeSpin = config.freeSpin || false;
    this._predeterminedPrize = config.predeterminedPrize || -1;
    this._responsiveSettings = G.OMTsettings.elements.Window_RealMoneyWheel; // use the same responsive settings as the daily wheel

    //this._dataCapture = DDNA.tracking.getDataCapture();

   /* if (config.wheelModeOverride) {
      this._isConversion = config.wheelModeOverride === RMWHEEL_MODES.Conversion;
    } else {
      this._isConversion = this._dataCapture.getRealMoneyWheelConversionStatus();
    }*/
    this._isConversion = false;

    this._isPlatinum = false;
    this._platinumWheelOverride = this._entryPoint === RMWHEEL_EPS.TargetedOffer && !this._isConversion;

    // Wheel error flag (e.g. is missing price, in non-IAP env)
    // Mostly used to determine the behaviour of quit button
    this._isUnavailable = false;

    this._firstPriceFetch = true;

    // Id used for tracking spin purchases, claims, and results
    this._spinId = null;

    this._currentBannerGroup = null;

    // Adjust window position and coin bar visibility depending on world state
    this.y = this._worldState ? -50 : -50 + G.WindowMgr.Constants.WorldVerticalOffset;
    if (!this._worldState) {
      this._coinBar = this.add(new G.CoinBar(0, -195, true));
    }

    if (this._isLandscape) {
      this.y += 50;
    }

    // Update real money replacement wheel seen states if not resolving pending spins
    if (!this._resolvingPendingSpins && this._defaultEntryPoint === RMWHEEL_EPS.Replacement) {
      G.saveState.resetRealMoneyWheelSessionsNotSeen();
    }

    this._initBanner();
    this._initWheel(this._isConversion);
    this._initButtons();
    this._initPostSpinElements();

    this._giftGroup = this.add(game.make.group());
    this._giftGroup.y = 80;

    this._onResize();
    this._onResizeSB = G.sb('onScreenResize').add(this._onResize, this);

    this._getWheelPrice();
  }

  /**
   * Screen resize listener callback
   */
  _onResize() {
    const {
      screenWidth,
      screenHeight,
      bannerConfig,
      coinBarConfig,
      spinButtonConfig,
      mascotHostConfig,
    } = RESPONSIVE_CONFIG;
    const lerpFactor = {
      x: G.lerpFactor(game.width, screenWidth.min, screenWidth.max),
      y: G.lerpFactor(game.height, screenHeight.min, screenHeight.max),
    };

    const worldYOffset = this._worldState ? 75 : 0;
    const parentBannerY = lerpFactor.y * bannerConfig.parentOffset.y + worldYOffset;
    const { elemOffsets: bannerOffsets } = bannerConfig;

    // Position banner
    this._bannerGroup.y = parentBannerY + bannerOffsets.bannerGroup.y;
    this._superBannerGroup.y = parentBannerY + bannerOffsets.bannerGroup.y;
    this._platBannerGroup.y = parentBannerY + bannerOffsets.bannerGroup.y;

    // Position coin bar
    if (this._coinBar) {
      this._coinBar.y = G.lerp(coinBarConfig.y.min, coinBarConfig.y.max, lerpFactor.y, 0);
    }

    // Position quit button
    this._quitButton.x = G.lerp(bannerOffsets.quitButton.x.min, bannerOffsets.quitButton.x.max, lerpFactor.x, 0);
    this._quitButton.y = parentBannerY + bannerOffsets.bannerGroup.y;

    // Position spin button
    const { elemOffsets: spinBtnOffsets } = spinButtonConfig;
    const parentSpinBtnY = G.lerp(
      spinButtonConfig.parentOffset.y.min,
      spinButtonConfig.parentOffset.y.max,
      lerpFactor.y,
      0,
    );

    this._spinBtn.y = parentSpinBtnY + G.lerp(
      spinBtnOffsets.realMoneySpinBtn.y.min,
      spinBtnOffsets.realMoneySpinBtn.y.max,
      lerpFactor.y,
    );

    // Position Mascot Host
    if (this._wheel && this._wheel.gingy) {
      this._wheel.gingy.x = G.lerp(
        mascotHostConfig.offset.x.min,
        mascotHostConfig.offset.x.max,
        lerpFactor.x,
      );
    }
  }

  /**
   * Get price for real money wheel
   */
  async _getWheelPrice() {
    const catalog = await OMT.payments.getCatalogAsync();
    const wheelMode = this._isPlatinum || this._platinumWheelOverride
      ? RMWHEEL_MODES.Platinum
      : isConversionToEnum(this._isConversion);
    const realMoneyOffers = ShopUtils.getRealMoneyWheelOffer(catalog, this._entryPoint, wheelMode);
    // eslint-disable-next-line prefer-destructuring
    this._realMoneyWheelOffer = realMoneyOffers[0];

    // Check if the wheel flow should continue based on IAP and IAP catalog status
    if (!G.IAP || !this._realMoneyWheelOffer) {
      this._isUnavailable = true;
      this._wheel.toggleUnavailableNotice(true);
      this._quitButton.visible = true;
      this._quitButton.currentState = BUTTONSTATE.ENABLED;
      return;
    }

    this._updateSpinBtnLabel();

    if (this._firstPriceFetch) {
      this._firstPriceFetch = false;
      this._startInitialAnimation(this._freeSpin);
    }
  }

  /**
   * Updates spin button label
   */
  _updateSpinBtnLabel() {
    if (this._freeSpin) {
      this._spinBtn.button.text = OMT.language.getText('Spin');
    } else {
      if (G.IAP && this._realMoneyWheelOffer) {
        this._spinBtn.buttonText.text = this._realMoneyWheelOffer.price;
        this._spinBtn.currentState = BUTTONSTATE.ENABLED;
      } else {
        this._spinBtn.buttonText.text = '---';
        this._spinBtn.currentState = BUTTONSTATE.DISABLED;
      }
    }
  }

  /**
   * start the intro animation
   */
  _startInitialAnimation() {
    G.sb('hideHighscoreBoard').dispatch(); // Hide the leaderboard

    game.add.tween(this._wheel)
      .to({ y: this._wheel.y }, 500, Phaser.Easing.Circular.Out, true);

    if (!this._resolvingPendingSpins) {
      // If the wheel needs to be changed to platinum first, change it
      // and wait for an extra 3 seconds before continuing with the flow
      if (this._isPlatinum || this._platinumWheelOverride) {
        game.time.events.add(3000, () => {
          this._changeWheelMode();
        });
        game.time.events.add(6000, this._checkFreeSpin.bind(this));
      } else {
        game.time.events.add(3000, this._checkFreeSpin.bind(this));
      }
    }
  }

  /**
   * Check for pending spins and reformat its data
   */
  _findPendingSpin() {
    const pendingSpins = G.saveState.getPendingRealMoneyWheelSpins();
    const result = {
      spinId: '',
      hasPendingSpins: true,
      isConversion: null,
      type: null,
      entryPoint: null,
      freeSpin: false,
      wasPaidFor: false,
    };

    if (pendingSpins.conversion.length > 0) {
      result.isConversion = true;
      result.type = RMWHEEL_MODES.Conversion;
    } else if (pendingSpins.highValue.length > 0) {
      result.isConversion = false;
      result.type = RMWHEEL_MODES.HighValue;
    } else if (pendingSpins.platinum.length > 0) {
      result.isConversion = false;
      result.type = RMWHEEL_MODES.Platinum;
    } else {
      result.hasPendingSpins = false;
    }

    if (result.hasPendingSpins) {
      /* eslint-disable-next-line prefer-destructuring */
      result.spinId = pendingSpins[result.type][0];
      const spinIdParts = result.spinId.split('_');
      result.freeSpin = spinIdParts[0] !== 'iap'; // 'free' and 'iap-uc' are true, 'iap' is false
      result.wasPaidFor = spinIdParts[0] === 'iap-uc';
      /* eslint-disable-next-line prefer-destructuring */
      result.entryPoint = spinIdParts[1]; // will be one of RMWHEEL_EPS
    }

    return result;
  }

  /**
   * init the prize wheel instance
   * @param {boolean} isConversion
   */
  _initWheel(isConversion) {
    if (this._resolvingPendingSpins) {
      // Check for pending spins
      const result = this._findPendingSpin();
      if (result.hasPendingSpins) {
        this._spinId = result.spinId;
        this._entryPoint = result.entryPoint;
        this._freeSpin = result.freeSpin;
        this._isConversion = result.isConversion;
        this._isPlatinum = result.type === RMWHEEL_MODES.Platinum;
        this._platinumWheelOverride = !this._isConversion && this._entryPoint === RMWHEEL_EPS.TargetedOffer;

        if (result.wasPaidFor) {
          // const wheelMode = isConversionToEnum(this._isConversion);
          // DDNA.transactionHelper.queueRealMoneyWheelEvent(
          //   this._entryPoint,
          //   RMWHEEL_EVENTS.Purchased,
          //   wheelMode,
          //   1,
          // );
        }

        // Check if prize was already determined
        const savedResult = G.saveState.getRealMoneySpinResult(result.spinId);
        this._predeterminedPrize = savedResult != null ? savedResult : -1;

        if (this._isPlatinum || this._platinumWheelOverride) {
          game.time.events.add(3000, () => {
            this._changeWheelMode();
          });
          game.time.events.add(6000, this._checkFreeSpin.bind(this));
        } else {
          // Start spinning the wheel if the spin is free or paid for,
          // or else show the buy spin button
          game.time.events.add(1500, this._checkFreeSpin.bind(this));
        }
      } else {
        // If there aren't any more pending spins, continue with normal flow
        this._resolvingPendingSpins = false;
        this._entryPoint = this._defaultEntryPoint;
        this._isConversion = isConversion;
        this._getWheelPrice();
      }
    }

    this._wheel = new OMT_RealMoneyPrizeWheel({
      wheelMode: isConversionToEnum(this._isConversion),
      entryPoint: this._entryPoint,
      freeSpinMode: this._freeSpin,
      ...OMT_Utils.stringToReference('wheelConfigs.realMoneyPrizeWheel', G.OMTsettings.elements),
    });

    this._wheel.y = 100;
    this.add(this._wheel);

    // Show banner
    if (this._isConversion) {
      this._currentBannerGroup = this._bannerGroup;
    } else {
      this._currentBannerGroup = this._superBannerGroup;
    }

    this._currentBannerGroup.visible = true;
    this._currentBannerGroup.x = 1000;
    this._currentBannerGroup.alpha = 0;
    game.add.tween(this._currentBannerGroup).to({ x: 0, alpha: 1 }, 600, Phaser.Easing.Circular.Out, true);
  }

  /**
   * Init the real money banner
   */
  _initBanner() {
    // Regular Banner
    this._bannerGroup = new Phaser.Group(game, this);
    this._banner = G.makeImage(0, 0, 'realMoney_wheel_banner', 0.5, this._bannerGroup);
    this._txt = new G.Text(0, 0, OMT.language.getText('Golden Wheel'), {
      style: 'font-white',
      fontSize: '47px',
    }, 0.5, 400);
    this._bannerGroup.add(this._txt);
    this._bannerGroup.y = -285;
    this._bannerGroup.visible = false;

    // Super Banner
    this._superBannerGroup = new Phaser.Group(game, this);
    this._superBanner = G.makeImage(0, 0, 'realMoney_wheel_banner', 0.5, this._superBannerGroup);
    this._superTxt = new G.Text(0, 0, OMT.language.getText('Super Golden Wheel'), {
      style: 'font-white',
      fontSize: '47px',
    }, 0.5, 400);
    this._superBannerGroup.add(this._superTxt);
    this._superBannerGroup.y = -285;
    this._superBannerGroup.visible = false;

    // Platinum Banner
    this._platBannerGroup = new Phaser.Group(game, this);
    this._platBanner = G.makeImage(0, 0, 'realMoney_wheel_banner_plat', 0.5, this._platBannerGroup);
    this._platTxt = new G.Text(0, 15, OMT.language.getText('Platinum Wheel'), 'realMoneyWheel-platinumBanner', 0.5, 400);
    this._platBannerGroup.add(this._platTxt);
    this._platBannerGroup.y = -285;
    this._platBannerGroup.visible = false;
  }

  /**
   * init window buttons
   */
  _initButtons() {
    // stay button
    this._stayButton = new OMT_UI_SquareButton(130, 90, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 230,
          height: 100,
        },
        isEnabled: false,
      },
      text: {
        string: OMT.language.getText('Stay'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: this._onStayButtonPressed.bind(this),
        },
      },
    });
    this.add(this._stayButton);
    this._stayButton.visible = false;
    this._stayButton.currentState = BUTTONSTATE.DISABLED;

    // leave button
    this._leaveButton = new OMT_UI_SquareButton(-130, 90, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 230,
          height: 100,
        },
        isEnabled: false,
      },
      text: {
        string: OMT.language.getText('Leave'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: this._onLeaveButtonPressed.bind(this),
        },
      },
    });
    this.add(this._leaveButton);
    this._leaveButton.visible = false;
    this._leaveButton.currentState = BUTTONSTATE.DISABLED;

    // buy spin button
    this._spinBtn = new OMT_UI_SquareButton(0, 505, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Spin'),
        textStyle: {
          style: 'font-white',
          fontSize: 40,
        },
        dimensionMods: {
          width: 0.6,
        },
      },
      options: {
        clickFunction: {
          onClick: this._buySpin.bind(this),
        },
        cacheButton: false,
      },
    });
    this.add(this._spinBtn);
    this._spinBtn.visible = false;

    // stop button
    this._stopBtn = new OMT_UI_SquareButton(0, 505, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Stop'),
        textStyle: {
          style: 'font-white',
          fontSize: 49,
        },
      },
      options: {
        clickFunction: {
          onClick: this._stopSpinningWheel.bind(this),
        },
        cacheButton: false,
      },
    });
    this.add(this._stopBtn);
    this._stopBtn.visible = false;

    this._quitButton = new G.Button(260, -300, 'btn_x', this._showQuitDialog, this);
    this.add(this._quitButton);
    this._quitButton.visible = false;
    this._quitButton.currentState = BUTTONSTATE.DISABLED;
  }

  /**
   * init the post spin / reward elements
   */
  _initPostSpinElements() {
    this._youWinTxt = new G.Text(0, 0, '', {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, 500);
    this.add(this._youWinTxt);
    this._youWinTxt.visible = false;

    this._claimButton = new OMT_UI_SquareButton(0, 505, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Claim'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: this._claimBtnAction.bind(this),
        },
      },
    });

    this.registerButtons(this._claimButton);
    const tw = game.add.tween(this._claimButton).to({ alpha: 0 }, 150, Phaser.Easing.Sinusoidal.InOut, true);
    tw.onComplete.add(() => {
      this._claimButton.visible = false;
    });
  }

  /**
   * Purchase or redeem a free or previously purchased spin
   */
  _buySpin() {
    const wheelMode = isConversionToEnum(this._isConversion);

    if (this._freeSpin) {
      this._startSpinningWheel();
    } else {
      if (!this._realMoneyWheelOffer) return;
      ShopUtils.purchaseItem(this._realMoneyWheelOffer, null, null, false, () => {
        // Helper wheel purchases are tracked in mission tracker instead of transaction tracker
        if (this._entryPoint === RMWHEEL_EPS.Helper) {
          // DDNA.missionTracker.trackWheelEvent(this._entryPoint, RMWHEEL_EVENTS.Purchased, this._isConversion, 1, true);
        } else {
          // DDNA.transactionHelper.queueRealMoneyWheelEvent(
          //   this._entryPoint,
          //   RMWHEEL_EVENTS.Purchased,
          //   wheelMode,
          //   1,
          // );
        }

        // If the current spin is a pending IAP spin that was
        // not paid for, it is now after payment.
        // Convert it into an unclaimed IAP spin
        if (this._resolvingPendingSpins) {
          if (this._spinId.includes('iap') && !this._spinId.includes('-uc')) {
            const oldSpinId = this._spinId;
            this._spinId = this._spinId.replace('iap', 'iap-uc');
            const spinResult = G.saveState.getRealMoneySpinResult(oldSpinId);

            G.saveState.addPendingRealMoneySpin(wheelMode, this._spinId);
            G.saveState.saveRealMoneySpinResult(this._spinId, spinResult);
            G.saveState.removePendingRealMoneySpin(wheelMode, oldSpinId);
            G.saveState.removeRealMoneySpinResult(oldSpinId);
          }
        }

        this._startSpinningWheel();
      });
    }
  }

  /**
   * Check to see if the wheel can be spun for free, otherwise, show buy and quit butttons
   */
  _checkFreeSpin() {
    // Check if prize was already determined
    const pendingSpin = this._findPendingSpin();
    const predeterminedPrize = G.saveState.getRealMoneySpinResult(pendingSpin.spinId);

    if (this._freeSpin) {
      if (predeterminedPrize != null) {
        this._wheel.addPrizeToSpinQueueByIndex(predeterminedPrize);
      }
      this._startSpinningWheel();
    } else {
      this._spinBtn.visible = true;
      this._quitButton.visible = true;
      this._quitButton.currentState = BUTTONSTATE.ENABLED;
    }
  }

  /**
   * start spinning wheel
   */
  _startSpinningWheel() {
    // If the wheel is out of order, don't spin it to avoid errors in the flow
    if (this._isUnavailable) return;

    const wheelMode = isConversionToEnum(this._isConversion);
    /* if (!this._freeSpin || this._entryPoint === RMWHEEL_EPS.EPPayload) {
      this._dataCapture.addToPlayerCharacterizationParam(RMWHEEL_PCMETRICS[this._entryPoint][wheelMode], 1);
    }*/

    if (!this._resolvingPendingSpins) {
      this._spinId = createRealMoneySpinId(null, this._entryPoint, this._freeSpin, true);
      G.saveState.addPendingRealMoneySpin(wheelMode, this._spinId);
    }

    this._freeSpin = false;
    this._wheel.spinId = this._spinId;
    this._wheel.setSpinningState();
    this._wheel.gingy.toggleSpeech(false, isConversionToEnum(this._isConversion));

    // Move mascot host down only if in portrait mode
    if (game.width <= RESPONSIVE_CONFIG.screenWidth.min) {
      this._wheel.moveGingy(true);
    }

    this._wheel.lights.changeLightsMode(LIGHTS_MODES.Alternating);
    this._spinBtn.visible = false;
    this._quitButton.visible = false;
    this._quitButton.currentState = BUTTONSTATE.DISABLED;

    game.time.events.add(1400, () => {
      this._stopBtn.visible = true;
    });
  }

  /**
   * stop spinning real money wheel
   */
  async _stopSpinningWheel() {
    // spin to next prize
    this._wheel.spinToNextPrize((item) => {
      const isJackpot = item.data.reward.jackpot;
      this._showPrize(item.data.reward, isJackpot, false);
    });

    G.sb('wheelSpin').dispatch();

    this._stopBtn.visible = false;
  }

  /**
   * on close button pressed
   */
  _closeButtonPressed() {
    this._onResizeSB.detach();
    if (this._afterQuit) {
      this._afterQuit(this._isUnavailable);
    }
    this.closeWindow();
  }

  /**
   * Show quit confirmation dialog
   * or close the window immediately if _isUnavailable is true
   */
  _showQuitDialog() {
    if (this._isUnavailable) {
      this._onLeaveButtonPressed();
    } else {
      this._wheel.gingy.toggleSpeech(false, isConversionToEnum(this._isConversion));
      this._wheel.toggleQuitBanner(true);
      this._quitButton.visible = false;
      this._quitButton.currentState = BUTTONSTATE.DISABLED;
      this._spinBtn.visible = false;
      this._stayButton.visible = true;
      this._stayButton.currentState = BUTTONSTATE.ENABLED;
      this._leaveButton.visible = true;
      this._leaveButton.currentState = BUTTONSTATE.ENABLED;
    }
  }

  /**
   * stay button pressed action
   */
  _onStayButtonPressed() {
    this._wheel.toggleQuitBanner(false);
    this._quitButton.visible = true;
    this._quitButton.currentState = BUTTONSTATE.ENABLED;
    this._spinBtn.visible = true;
    this._stayButton.visible = false;
    this._stayButton.currentState = BUTTONSTATE.DISABLED;
    this._leaveButton.visible = false;
    this._leaveButton.currentState = BUTTONSTATE.DISABLED;
  }

  /**
   * leave button pressed action
   */
  _onLeaveButtonPressed() {
    // If the wheel is in an error state, don't activate cooldown
    if (!this._isUnavailable) {
      G.saveState.setRealMoneyWheelOnCoolDown(this._isConversion);
    }
    this._wheel.destroy();
    this._closeButtonPressed();
  }

  /**
   * on claim button clicked
   */
  _claimBtnAction() {
    G.sfx.chain_rattle.play();

    const oldIsConversion = this._isConversion;
    let wheelMode = this._isPlatinum || this._platinumWheelOverride
      ? RMWHEEL_MODES.Platinum
      : isConversionToEnum(oldIsConversion);

    // Mark current spin as claimed
    if (this._spinId) {
      if (this._platinumWheelOverride) {
        G.saveState.removePendingRealMoneySpin(RMWHEEL_MODES.HighValue, this._spinId);
      } else {
        G.saveState.removePendingRealMoneySpin(wheelMode, this._spinId);
      }
      G.saveState.removeRealMoneySpinResult(this._spinId);
      this._spinId = null;
    }

    if (this._resolvingPendingSpins) {
      if (this._entryPoint === RMWHEEL_EPS.EPPayload) {
        // Track real money wheel claimed. This will be sent
        // with the next transaction event sent in _applyPrize
        // DDNA.transactionHelper.queueRealMoneyWheelEvent(
        //   this._entryPoint,
        //   RMWHEEL_EVENTS.Claimed,
        //   wheelMode,
        //   1,
        // );
      }
    } else {
      this._isConversion = false; //this._dataCapture.getRealMoneyWheelConversionStatus();
      this._entryPoint = this._defaultEntryPoint;
      this._platinumWheelOverride = !this._isConversion && this._entryPoint === RMWHEEL_EPS.TargetedOffer;
      this._getWheelPrice();
      wheelMode = isConversionToEnum(this._isConversion);

      if (this._entryPoint !== RMWHEEL_EPS.Helper) {
        // Track if player is eligible for seeing the real money helper wheel
        if (G.IAP && G.saveState.getRealMoneyWheelOnCoolDown() <= 0) {
          // DDNA.transactionHelper.queueRealMoneyWheelEvent(
          //   this._entryPoint,
          //   RMWHEEL_EVENTS.Eligible,
          //   wheelMode,
          //   1,
          // );
        }

        // Track real money wheel sighting. This will be sent
        // with the next transaction event sent in _applyPrize
        // DDNA.transactionHelper.queueRealMoneyWheelEvent(
        //   this._entryPoint,
        //   RMWHEEL_EVENTS.Seen,
        //   wheelMode,
        //   1,
        // );
      }
    }

    this._applyPrize(this._wonPrize, this._wheel.giftGfx, oldIsConversion);
    this._wonPrize = false;

    this._claimButton.visible = false;
  }

  /**
   * show / animate in the users prize for the real money wheel
   * @param {Object} prize
   * @param {boolean} isJackpot
   * @param {boolean} autoClaim
   */
  _showPrize(prize, isJackpot, autoClaim) {
    this._wheel.toggleWinText(true, prize, isJackpot);
    this._wheel.moveGingy(false);
    this._wonPrize = prize;

    game.time.events.add(1000, () => {
      if (autoClaim) {
        this._claimBtnAction();
      } else {
        this._claimButton.alpha = 1;
        this._claimButton.scale.setTo(0);
        game.add.tween(this._claimButton.scale).to({ x: 1, y: 1 }, 600, Phaser.Easing.Elastic.Out, true).onComplete.add(() => {
          if (this.game === null) return; // eslint-disable-line no-useless-return
        }, this);
        this._claimButton.visible = true;
      }
    }, this);
  }

  /**
   * apply the prize and set the window to reset
   * @param {Array} prize
   * @param {Object} sourceObj from which object the coins are flying from
   * @param {boolean} isConversion
   */
  _applyPrize(prize, sourceObj, isConversion) {
    OMT.transactionTracking.logInventoryTransactionBegin();
    this._wonPrize = null;

    // const formattedGift = [prize.prize, prize.amount];
    this.state.uiTargetParticles.createCoinBatch(
      game.world.bounds.x + sourceObj.worldPosition.x,
      sourceObj.worldPosition.y,
      this._worldState ? this.state.panel.coinsTxt : this._coinBar,
      prize.amount,
    );
    OMT.transactionTracking.addInventoryChange('coins', 0, prize.amount);

    OMT.transactionTracking.logInventoryTransactionEnd();
    // DDNA.transactionHelper.trackWheelReward(
    //   formattedGift,
    //   true,
    //   isConversion,
    //   this._isPlatinum || this._platinumWheelOverride,
    // );

    game.time.events.add(1000, this._restart, this);
  }

  /**
   * Handles wheel mode change animations
   * @returns {boolean} whether a mode changed happened
   */
  _changeWheelMode() {
    // If the wheel is out of order, don't chage it to avoid errors in flow
    if (this._isUnavailable) return false;
    const wheelMode = this._isPlatinum || this._platinumWheelOverride
      ? RMWHEEL_MODES.Platinum
      : isConversionToEnum(this._isConversion);
    const changed = this._wheel.changeWheelMode(wheelMode, !this._resolvingPendingSpins);

    if (changed) {
      let incomingGroup;
      if (this._isPlatinum || this._platinumWheelOverride) {
        incomingGroup = this._platBannerGroup;
      } else {
        incomingGroup = this._isConversion ? this._bannerGroup : this._superBannerGroup;
      }

      const outgoingGroup = this._currentBannerGroup;

      incomingGroup.visible = true;
      incomingGroup.x = 500;
      incomingGroup.alpha = 0;
      game.add.tween(incomingGroup).to({ alpha: 1 }, 250, Phaser.Easing.Quintic.Out, true, 1250);
      game.add.tween(incomingGroup).to({ x: 0 }, 750, Phaser.Easing.Quintic.Out, true, 1250)
        .onComplete.add(() => {
          this._spinBtn.visible = !this._resolvingPendingSpins;
          this._quitButton.visible = true;
          this._quitButton.currentState = BUTTONSTATE.ENABLED;
        });

      outgoingGroup.visible = true;
      outgoingGroup.x = 0;
      outgoingGroup.alpha = 1;
      game.add.tween(outgoingGroup).to({ alpha: 0 }, 250, Phaser.Easing.Quintic.In, true, 500);
      game.add.tween(outgoingGroup).to({ x: -500 }, 750, Phaser.Easing.Quintic.In, true)
        .onComplete.add(() => {
          outgoingGroup.visible = false;
        });
    } else {
      // no wheel change, show spin and quit buttons again
      game.time.events.add(1000, () => {
        this._spinBtn.visible = !this._resolvingPendingSpins;
        this._quitButton.visible = true;
        this._quitButton.currentState = BUTTONSTATE.ENABLED;
      });
    }

    return changed;
  }

  /**
   * restart the prize wheel flow
   */
  _restart() {
    game.add.tween(this._claimButton).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true);

    game.add.tween(this._wheel).to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.InOut, true, 400).onComplete.add(async () => {
      if (this.game === null) return;
      await this._getWheelPrice();

      // Checking for pending spins
      if (this._resolvingPendingSpins) {
        const result = this._findPendingSpin();
        if (result.hasPendingSpins) {
          this._spinId = result.spinId;
          this._entryPoint = result.entryPoint;
          this._freeSpin = result.freeSpin;
          this._isConversion = result.isConversion;
          this._isPlatinum = result.type === RMWHEEL_MODES.Platinum;
          this._platinumWheelOverride = !this._isConversion && this._entryPoint === RMWHEEL_EPS.TargetedOffer;

          if (result.wasPaidFor) {
            // const wheelMode = isConversionToEnum(this._isConversion);
            // DDNA.transactionHelper.queueRealMoneyWheelEvent(
            //   this._entryPoint,
            //   RMWHEEL_EVENTS.Purchased,
            //   wheelMode,
            //   1,
            // );
          }

          // Change wheel mode if necessary
          const wheelModeChanged = this._changeWheelMode(false);
          const delay = wheelModeChanged ? 3000 : 1500;

          // Start spinning the wheel if the spin is free or paid for,
          // or else show the buy spin button
          game.time.events.add(delay, this._checkFreeSpin.bind(this));
        } else {
          // If there aren't any more pending spins, continue with normal flow
          this._resolvingPendingSpins = false;
          this._isConversion = false;//this._dataCapture.getRealMoneyWheelConversionStatus();
          this._entryPoint = this._platinumWheelOverride ? RMWHEEL_EPS.TargetedOffer : this._defaultEntryPoint;
          await this._getWheelPrice();
        }
      }

      // Normal Flow
      if (!this._resolvingPendingSpins) {
        this._changeWheelMode();

        // Track wheel mode seen in mission params
        if (this._entryPoint === RMWHEEL_EPS.Helper) {
          // DDNA.missionTracker.trackWheelEvent(this._entryPoint, RMWHEEL_EVENTS.Seen, this._isConversion, 1, false);
        }

        if (this._wheel.lights.lightsMode !== LIGHTS_MODES.Single) {
          this._wheel.lights.changeLightsMode(LIGHTS_MODES.Off);
        }
      }
    }, this);

    game.add.tween(this._youWinTxt.scale).to({ x: 0, y: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);

    game.add.tween(this._giftGroup).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true).onComplete.add(() => {
      if (this.game === null) return;
      this._giftGroup.destroy();
      this._giftGroup = this.add(game.make.group());
      this._giftGroup.y = 80;
    }, this);

    this._wheel.toggleWinText(false, null, null);
  }
}

// create global references
// if (G.IAP) {
if (!window.Windows) window.Windows = {};
Windows.realMoneyWheel = Window_RealMoneyWheel;
// }
