/* eslint-disable no-case-declarations */
/* eslint-disable prefer-object-spread */

import {
  fetchMessages as GBC_fetchMessages,
  sendMessage as GBC_sendMessage,
  acknowledgeAllMessages as GBC_acknowledgeAllMessages,
} from '@sgorg/game-backend-client';
import { WorldMap2_globalSignals } from '../../Elements/WorldMap2/WorldMap2_Util';

/**
 * Class for handling messages sent through the game-backend.
 */
export class OMT_MessageService {
  /**
   * constructor
   */
  constructor() {
    //
  }

  /**
   * get messages from the game-backend
   * @returns {Promise}
   */
  async loadMessages() {
    if (!OMT.connect.isConnected()) return;
    try {
      const messages = await GBC_fetchMessages();
      // console.log('MESSAGES RECEIVED : ', messages);
      messages.forEach((message) => {
        this.handleMessage(message);
      });
      // asknowledege messages if there were any
      if (messages.length > 0) GBC_acknowledgeAllMessages();
    } catch (e) {
      console.error('OMT_MessageService: Failed to get messages from the game-backend.');
    }
  }

  /**
   * handle individual messages
   * @param {Object} message
   */
  handleMessage(message) {
    if (!message.payload || !message.payload.messageType) return;
    const { messageType } = message.payload;
    switch (messageType) {
      case 'friendJoined':
        G.saveState.friendshipChestDataManager.setFriendCard(message.senderUserId, message.payload);
        break;
      case 'gateHereKey':
        const gatePayload = message.payload;
        G.saveState.gateManager.addInviteToGate(gatePayload.gateId);
        G.sb(WorldMap2_globalSignals.recheckGate).dispatch();
        break;
      default:
        break;
    }
  }

  /**
   * send a message / payload to the specified userId
   * @param {string} userId
   * @param {string} messageType
   * @param {Object} payload
   * @returns {Promise}
   */
  async sendMessage(userId, messageType, payload) {
    // clone the payload object and assign the message type and FB userdata
    payload = Object.assign({
      messageType,
      senderUserData: OMT.envData.settings.user,
    }, payload);

    try {
      await GBC_sendMessage({ userId, payload });
    } catch (error) {
      console.error('OMT_MessageService: Failed to send message to game-backend.');
    }
  }
}
