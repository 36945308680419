// Properties for the speech bubble in SagaMapPromo
export const speechProps = {
  x: 300,
  y: -80,
  width: 450,
  height: 112,
  cornerRadius: 22,
  padding: 7.5,
  fontSize: 34,
  lineSpacing: -6,
};

// Properties for the hand in SagaMapPromo
export const handProps = {
  x: 210,
  y: 230,
  tweenDelta: {
    x: 20,
    y: -50,
  },
};

// Gingy's lines in SagaMapPromo
export const gingyLines = [
  'Hey, are you up for a new challenge? Click "%PLAYSAGA%" and have fun!',
  'Click "%PLAYSAGA%" and earn booster to get higher scores!',
  '%PERCENT%% of players that click "%PLAYSAGA%" improve their scores in tournaments. Try it!',
  'Do you want new levels? Click "%PLAYSAGA%" to play more than 1000 unique levels!',
];

// Chances of the SagaMapPromo appearing based on number of attempts
export const sagaPromoChance = [
  100,
  75,
  50,
];

export const leaderboardHeightLimit = 1139;
