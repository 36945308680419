import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';
import { OMT_SystemInfo } from '../Services/OMT/OMT_SystemInfo';

let _instance = null; // singleton instance

/**
 * Class for Vorlon debuggin
 */
export class OMT_DebugVorlonLoader {
  /**
   * return singleton instance
   * @returns {OMT_DebugVorlonLoader}
   */
  static getInstance() {
    if (!_instance) _instance = new OMT_DebugVorlonLoader();
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    this._isLoaded = false;
  }

  /**
   * start loading the remote debugger
   */
  loadRemoteDebugger() {
    const vorlonScript = document.createElement('script');
    vorlonScript.async = true;
    vorlonScript.src = './js/vorlon.js';
    document.head.append(vorlonScript);
    // vorlonScript.remove();
    this._isLoaded = true;
  }

  /**
   * check if vorlon is already loaded.
   * @returns {true}
   */
  isLoaded() {
    return this._isLoaded;
  }
}

/**
 * Class for vorlon.js click to activate button
 */
export class OMT_VorlonActivateButton extends UI_GButtonES6 {
  /**
   * constructor
   * @param {number} x
   * @param {number} y
   * @param {Phaser.DisplayObject} clickTarget (optional)
   * @param {number} clickTarget (optional) tint to apply to clickTarget
   */
  constructor(x, y, clickTarget, activatedTint) {
    super(x, y, undefined,
      () => {
        this._onClicked();
      });

    this._clickTargetTint = activatedTint;
    this._clicksToActivate = 6;
    this._clickExpiry = 10000;
    this._firstClickTime = 0;
    this.scaleOnClick = false;
    this.input.useHandCursor = false;

    if (clickTarget !== undefined) this.setClickTarget(clickTarget);

    // disable if vorlon.js already active or on desktop
    const systemInfo = OMT_SystemInfo.getInstance();
    if (OMT_DebugVorlonLoader.getInstance().isLoaded() || !(systemInfo.deviceIsAndroid || systemInfo.deviceIsIOS)) {
      this.disable();
    }
  }

  /**
   * add click target object to the button.
   * @param {Phaser.DisplayObject} clickTarget
   */
  setClickTarget(clickTarget) {
    if (this._clickTarget) this.removeChild(this._clickTarget);
    this._clickTarget = clickTarget;
    this._clicked = 0;
    this._clickTarget.x = 0;
    this._clickTarget.y = 0;
    this.addChild(clickTarget);

    // enable if vorlon is not already set
    if (OMT_DebugVorlonLoader.getInstance().isLoaded()) {
      if (this._clickTargetTint) this._clickTarget.tint = this._clickTargetTint;
    }
  }

  /**
   * on button clicked
   */
  _onClicked() {
    // update click counts and timings
    const currentTime = Date.now();
    if (currentTime > this._firstClickTime + this._clickExpiry) {
      this._clicked = 0;
      this._firstClickTime = currentTime;
    }
    this._clicked++;

    // activate when after multiple clicks
    if (this._clicked === this._clicksToActivate) {
      OMT_DebugVorlonLoader.getInstance().loadRemoteDebugger();
      if (this._clickTarget && this._clickTargetTint) this._clickTarget.tint = this._clickTargetTint;
      this.disable();
      console.log('vorlon.js remote debugger activated');
    }
  }
}
