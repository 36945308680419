import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

/**
 * class for animated request coins button
 */
export class AnimatedCoinButton extends UI_GButtonES6 {
  /**
   * constructor
   * @param {Function} onClicked on clicked callback
   */
  constructor(onClicked) {
    super(0, 0, null, onClicked);
    this._initCoinImage();
  }

  /**
   * create the coin animated image
   */
  _initCoinImage() {
    const coinImage = G.makeImage(0, 0, 'lb2_coins_icon', [0.5, 0.5], this);
    const animation = G.Animations.Generic.getSquishSquashAnimation(coinImage.scale.x);
    this._coinTween = animation.animate(game, coinImage);
    this._coinTween.pause();
    this._coinImage = coinImage;
    this.addChild(coinImage);
  }

  /**
   * get a reference to the coin image
   * @returns {Phaser.Sprite}
   */
  get image() {
    return this._coinImage;
  }

  /**
   * pause animation
   */
  pause() {
    if (this._coinTween) this._coinTween.pause();
  }

  /**
   * resume animation
   */
  resume() {
    if (this._coinTween) this._coinTween.resume();
  }

  /**
   * destruction method
   */
  destroy() {
    if (this._coinTween) this._coinTween.stop();
    super.destroy();
  }
}
