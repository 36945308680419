import { FX_Particle } from './FX_Particle';
import { FX_ParticleEmitterConfig } from './FX_ParticleEmitterConfig';
import ArrayUtil from '../../Utils/ArrayUtil';

/**
 * Particle emitter class
 */
export class FX_ParticleEmitter extends Phaser.SpriteBatch {
  /**
   * constructor
   * @param {FX_ParticleEmitterConfig} config (option
   */
  constructor(config) {
    super(game);

    this._config = config || new FX_ParticleEmitterConfig();
    this._emitTime = 0;
    this._nextSpawnAt = 0;
    this._spawnActive = true;
    this._spawnUntil = this._emitTime + this._config.maxSpawnDuration;

    this._activeParticles = [];
    this._inactiveParticles = [];

    // set the first spawn timing
    this.setNextSpawnTime();
  }

  /**
   * create or get an inactive particle instance
   * @returns {FX_Particle}
   */
  getParticle() {
    let particle;
    if (this._inactiveParticles.length > 0) {
      particle = this._inactiveParticles.pop();
    } else {
      particle = new FX_Particle(this._config.spritesheet, ArrayUtil.getRandomElement(this._config.frames));
    }
    return particle;
  }

  /**
   * spawn / configure a particle instance
   */
  spawnParticle() {
    // particle life
    const pLife = this._config.minParticleLife + Math.random() * (this._config.maxParticleLife - (this._config.minParticleLife));
    // particle scale
    const pScale = this._config.allowTransforms ? this._config.minParticleScale + (Math.random() * (this._config.maxParticleScale - this._config.minParticleScale)) : 1;
    // x, y offsets
    const xOffset = this._config.spawnRandomX - (Math.random() * 2 * this._config.spawnRandomX);
    const yOffset = this._config.spawnRandomY - (Math.random() * 2 * this._config.spawnRandomY);
    // emission angle
    const emitAngle = this._config.emitAngle + (this._config.emitAngleVariance - (Math.random() * 2 * this._config.emitAngleVariance));
    // emission velocity
    const velolcity = this._config.minParticleVel + Math.random() * (this._config.maxParticleVel - this._config.minParticleVel);
    const velX = Math.cos(emitAngle) * velolcity;
    const velY = Math.sin(emitAngle) * velolcity;
    // particle rotation
    const rotation = this._config.allowTransforms ? this._config.minParticleRotation + Math.random() * (this._config.maxParticleRotation - this._config.minParticleRotation) : 0;
    // particle frame
    const frame = ArrayUtil.getRandomElement(this._config.frames);
    // fetch / init the particle instance
    const particle = this.getParticle();
    particle.init(frame, this._config.allowTransforms, pLife, pScale, xOffset, yOffset, velX, velY, rotation, this._config.particleFadeRate);
    this.addChild(particle);
    this._activeParticles.push(particle);
  }

  /**
   * set the next particle spawn time
   */
  setNextSpawnTime() {
    const spawnDelay = this._config.minSpawnDelay + Math.random() * (this._config.maxSpawnDelay - this._config.minSpawnDelay);
    this._nextSpawnAt = this._emitTime + spawnDelay;
  }

  /**
   * reset spawn timing values
   */
  resetSpawnTiming() {
    this._emitTime = 0;
    this.setNextSpawnTime();
  }

  /**
   * update loop
   */
  update() {
    const dtSec = (1 / 60) * G.deltaTime;
    const dtMs = dtSec * 1000;
    this._emitTime += dtMs;

    if (this._spawnActive && this._emitTime <= this._config.maxSpawnDuration) { // update spawning
      while (this._emitTime >= this._nextSpawnAt && this._activeParticles.length < this._config.maxParticles) {
        this.spawnParticle();
        this.setNextSpawnTime(this._emitTime);
      }
    } else { // spawning now active
      this._nextSpawnAt = this._emitTime;
    }

    let particle;
    const gravity = this._config.gravity * dtSec;
    const friction = Math.min(Math.max(this._config.friction * dtSec, 0), 1);

    for (let i = this._activeParticles.length - 1; i >= 0; i--) {
      // update particles
      particle = this._activeParticles[i];
      particle.update(dtMs, dtSec);

      // apply gravity
      particle.y += gravity;
      particle.velocity.x -= particle.velocity.x * friction;
      particle.velocity.y -= particle.velocity.y * friction;

      // remove expired particles
      if (particle.isExpired()) {
        this.removeChild(particle);
        this._activeParticles.splice(i, 1);
        this._inactiveParticles.push(particle);
      }
    }
    // console.log(`activeParticles = ${this._activeParticles.length}`);
  }

  /**
   * enable / disable particle spawning
   * @param {boolean} value
   */
  set spawnActive(value) {
    this._spawnActive = value;
  }

  /**
   * check if spawning is currently active
   * @returns {boolean}
   */
  get spawnActive() {
    return this._spawnActive;
  }

  /**
   * get count of active particles
   * @returns {number}
   */
  get activeParticleCount() {
    return this._activeParticles.length;
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._activeParticles.length = 0;
    this._inactiveParticles.length = 0;
  }
}
