import TopFxLayer from '../../Elements/GameState/UI/Layers/TopFxLayer';
import ArrayUtil from '@omt-components/Utils/ArrayUtil';
import { TOKEN_TYPES, EDITOR_SYMBOLS } from '../BoardConstants';
import { Candy } from './Candy';
import CandyDataManager from './CandyDataManager';

// loop and order for infection spread
const INFECTION_SPREAD_COORDINATES = [[-1, 0], [1, 0], [0, -1], [0, -1], [-1, -1], [-1, -1], [1, -1], [1, 1]];

/**
 * class for displaying board candies
 */
export class CandyLayer extends Phaser.Group {
  /**
   * constructor
   * @param {Board} board
   * @param {Object} data
   * @param {Object} lvl
   * @param {number} regularTypesNumber
   * @param {boolean} editorMode
   */
  constructor(board, data, lvl, regularTypesNumber, editorMode) {
    super(game);

    this._board = board;

    this._editorMode = editorMode;
    this._postRandomPhase = false;

    this.position = board.position;
    this.scale = board.scale;

    this._regularTypesNumber = regularTypesNumber;

    this._boardData = data;

    this._infectionSources = [];
    this._infectionSuperSources = [];
    this._removedInfectionSource = false;
    this._infectionToMakeStep = 0;

    this._grid = new G.GridArray(this._boardData.width, this._boardData.height, false);

    this._createDisplayGroups();
    this._addGlobalListeners();
  }

  /**
   * create display group layers
   */
  _createDisplayGroups() {
    this._deadGroup = game.add.group();
    this._deadGroup.visible = false;

    const primaryCandyGroup = game.add.group();
    const movingCandyGroup = game.add.group();

    this._fxGroup = new TopFxLayer(this._board);

    this._boosterFxGroup = game.add.group();

    const specialCandyGroup = game.add.group();

    this._fxTopGroup = this._fxGroup.aboveThirdFloorLayer = game.add.group();

    if (G.IMMEDIATE) {
      this._deadGroup.visible = primaryCandyGroup.visible = movingCandyGroup.visible = this._fxGroup.visible = specialCandyGroup.visible = this._fxTopGroup.visible = false;
    }

    // set layers position / scale to match
    primaryCandyGroup.position = movingCandyGroup.position = this._fxGroup.position = this._fxTopGroup.position = this._boosterFxGroup.position = specialCandyGroup.position = this.position;
    primaryCandyGroup.scale = movingCandyGroup.scale = this._fxGroup.scale = this._fxTopGroup.scale = this._boosterFxGroup.scale = specialCandyGroup.scale = this.scale;

    this._primaryCandyGroup = primaryCandyGroup;
    this._movingCandyGroup = movingCandyGroup;
    this._specialCandyGroup = specialCandyGroup;
  }

  /**
   * add global event listeners
   */
  _addGlobalListeners() {
    this._signalBindings = [
      G.sb('onCandyInfect').add(this._onCandyInfect, this),
      G.sb('onCandyInfectionRemove').add(this._onCandyInfectionRemove, this),
      G.sb('actionQueueEmptyAfterMove').add(this._onActionQueueEmptyAfterMove, this),
    ];
  }

  /**
   * remove global listeners set by _addGlobalListeners()
   */
  _removeGlobalListeners() {
    for (const signalBinding of this._signalBindings) signalBinding.detach();
    this._signalBindings.length = 0;
  }

  /**
   * listener for onCandyInfect global event
   * @param {Candy} candy
   */
  _onCandyInfect(candy) {
    this.addInfectionSource(candy, this._infectionSources);
  }

  /**
   * listener for onCandyInfectionRemove global event
   * @param {Candy} candy
   */
  _onCandyInfectionRemove(candy) {
    this.removeInfectionSource(candy, this._infectionSources);
  }

  /**
   * listener for actionQueueEmptyAfterMove global event, spread the infection after a move.
   */
  _onActionQueueEmptyAfterMove() {
    // spread infection after move
    if (!this._removedInfectionSource && this._infectionSuperSources.length > 0) {
      const infectionSpread = this.spreadInfection(this._infectionSuperSources);
      if (!infectionSpread) this.spreadInfection(this._infectionSources);
    }
    this._removedInfectionSource = false;
  }

  /**
   * get or create free Candy instance
   * @returns {Candy}
   */
  getFreeInstanceOfCandy() {
    let candy;
    if (this._deadGroup.children[0]) [candy] = this._deadGroup.children;
    else candy = new Candy(this._board, this._grid, this._postRandomPhase);
    return candy;
  }

  /**
   * add new candy instance to the board
   * @param {number} x
   * @param {number} y
   * @param {string} type
   */
  newCandy(x, y, type) {
    const candy = this.getFreeInstanceOfCandy();
    this._primaryCandyGroup.add(candy);

    candy.init(x, y, type || game.rnd.between(1, this._board.MAX_NUMBER_OF_REGULAR_CANDY));
    this._grid.set(x, y, candy);

    // add infection source if infection
    if (type === TOKEN_TYPES.INFECTION) {
      this.addInfectionSource(candy, this._infectionSuperSources);
    }
    return candy;
  }

  /**
   * get candy at specified postion
   * @param {number} cellX
   * @param {number} cellY
   * @returns {Candy}
   */
  getCandy(cellX, cellY) {
    return this._grid.get(cellX, cellY);
  }

  /**
   * just a proxy for getCandy..
   * @param {..any} args
   * @returns {Candy}
   */
  getToken(...args) {
    return this.getCandy(...args);
  }

  /**
   * get all active candies
   * @returns {Array.<Candy>}
   */
  getAllCandies() {
    const result = [];
    this._grid.loop((candy) => { if (candy) result.push(candy); });
    return result;
  }

  /**
   * get a list of candies with a specific tag
   * @param {string} tag
   * @returns {Array.<Candy>}
   */
  getAllTokensWithTag(tag) {
    return this.getAllCandies().filter((candy) => candy.hasTag(tag));
  }

  /**
   * get list of normal candies with randomized order
   * @returns {Array.<Candy>}
   */
  getNormalCandies() {
    const children = this._primaryCandyGroup.children.concat(this._movingCandyGroup.children);
    const childCount = children.length;
    const normalCandies = [];
    if (childCount === 0) return normalCandies;
    for (let i = 0; i < childCount; i++) {
      const child = children[i];
      if (this._grid.get(child.cellX, child.cellY) === child) {
        if (child.isNormalCandy()) normalCandies.push(child);
      }
    }
    return ArrayUtil.jumbleArray(normalCandies);
  }

  /**
   * get all special candy instances
   * @returns {Array.<Candy>}
   */
  getAllSpecialCandies() {
    const result = [];
    this._grid.loop((candy) => {
      if (candy && candy.hasSpecialType()) result.push(candy);
    });
    return result;
  }

  /**
   * replace random candy / tokens
   */
  replaceRandomCandies() {
    // can this be replaced with a funcion from the BoardMatcher?
    // eslint-disable-next-line arrow-body-style
    const checkIfThereIsMatch = (candy, x, y) => {
      return (this.isCandyMatchableWith(x - 2, y, candy) && this.isCandyMatchableWith(x - 1, y, candy)) // 1 1 [x]
        || (this.isCandyMatchableWith(x, y - 2, candy) && this.isCandyMatchableWith(x, y - 1, candy)) // 1 1 [x] (vertically)
        || (this.isCandyMatchableWith(x - 1, y, candy) && this.isCandyMatchableWith(x - 1, y - 1, candy) && this.isCandyMatchableWith(x, y - 1, candy)) // ??
        || (this.isCandyMatchableWith(x - 1, y, candy) && this.isCandyMatchableWith(x + 1, y, candy)) // 1 [x] 1
        || (this.isCandyMatchableWith(x, y - 1, candy) && this.isCandyMatchableWith(x, y + 1, candy)) // 1 [x] 1 (vertically)
        || (this.isCandyMatchableWith(x + 1, y, candy) && this.isCandyMatchableWith(x + 2, y, candy)) // [x] 1 1
        || (this.isCandyMatchableWith(x, y + 1, candy) && this.isCandyMatchableWith(x, y + 2, candy)); // [x] 1 1 (vertically)
    };

    const types = CandyDataManager.getTypesWithTag('regular').slice(0, this._regularTypesNumber);
    // attempt to randomize with no matches
    this._grid.loop((candy, x, y) => {
      if (!candy) return;
      if (!candy.isRandom()) return;

      let timeoutCounter = 0; let isSet = false;
      while (!isSet && timeoutCounter < 300) {
        const rndIndexOffset = game.rnd.between(0, this._regularTypesNumber - 1);
        const chosenGem = types[rndIndexOffset % types.length];
        candy.setBaseType(chosenGem);
        if (!checkIfThereIsMatch(candy, x, y)) isSet = true;
        timeoutCounter++;
      }
    }, this);

    // re-randomize is candy is box status.. not sure what that is.
    this._grid.loop((candy, x, y) => {
      if (!candy) return;
      if (candy.hasStatus() && candy.candyStatus.getEditorSymbol() === EDITOR_SYMBOLS.CANDY_BOX) {
        if (candy.candyStatus.boxColor === 'r') {
          const rndIndexOffset = game.rnd.between(0, this._regularTypesNumber - 1);
          for (let i = 0; i <= this._regularTypesNumber; i++) {
            candy.candyStatus.setMatchToken(types[(rndIndexOffset + i) % types.length].candyType);
            if (checkIfThereIsMatch(candy, x, y)) continue;
            else break;
          }
        }
      }
      candy.markPostRandomPhase();
    }, this);
  }

  /**
   * always false, just for compatibility
   * @returns {boolean}
   */
  isMoveBlocked() {
    return false;
  }

  /**
   * check if a match is blocked
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isMatchBlocked(cellX, cellY) {
    const candy = this.getCandy(cellX, cellY);
    if (candy) return !candy.isMatchable();
    return true;
  }

  /**
   * check if candy booster change is blocked
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isBoosterChangeBlocked(cellX, cellY) {
    const candy = this.getCandy(cellX, cellY);
    if (candy) return candy.hasSpecialType() || candy.hasStatus();
    return false;
  }

  /**
   * check with candy is matchable with another candy (matching tokens)
   * @param {number} cellX
   * @param {number} cellY
   * @param {Candy} optCandy
   */
  isCandyMatchableWith(cellX, cellY, optCandy) {
    const candy = this.getCandy(cellX, cellY);
    if (candy) return candy.isMatchableWith(optCandy);
    return false;
  }

  /**
   * check if 2 candies are neighbors
   * @param {Candy} candy
   * @param {Candy} candy2
   * @returns {boolean}
   */
  areCandiesNeighbours(candy, candy2) {
    if (!candy || !candy2) return false;
    return (Math.abs(candy.cellX - candy2.cellX) + Math.abs(candy.cellY - candy2.cellY)) === 1;
  }

  /**
   * swap candies positions
   * @param {Candy} c1
   * @param {Candy} c2
   */
  swapCandies(c1, c2) {
    this._grid.set(c1.cellX, c1.cellY, c2);
    this._grid.set(c2.cellX, c2.cellY, c1);
    const tmpX = c1.cellX; const tmpY = c1.cellY;
    c1.cellX = c2.cellX; c1.cellY = c2.cellY;
    c2.cellX = tmpX; c2.cellY = tmpY;
  }

  /**
   * on candy match
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} delay
   * @param {number} moveCellX
   * @param {number} moveCellY
   * @returns {boolean}
   */
  onMatch(cellX, cellY, delay, moveCellX, moveCellY) {
    const candy = this.getCandy(cellX, cellY);
    if (candy) candy.match(delay, moveCellX, moveCellY);
    return true;
  }

  /**
   * on candy hit
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  onHit(cellX, cellY) {
    const candy = this.getCandy(cellX, cellY);
    if (candy) return candy.hit();
    return true;
  }

  /**
   * on candy booster used
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  onBooster(cellX, cellY) {
    const candy = this.getCandy(cellX, cellY);
    if (candy) return candy.onBooster();
    return true;
  }

  /**
   * Used only in the level editor. Does the same thing as removeCandy
   * @param  {...any} args
   */
  removeToken(...args) {
    this.removeCandy(args);
  }

  /**
   * remove a candy from the grid / board
   * @param  {...any} args
   */
  removeCandy(...args) {
    let candy;
    let skipCollectableRemove = false;
    if (typeof args[0] === 'object') {
      if (Array.isArray(args[0])) { // passed cellX and cellY as array
        candy = this.getCandy(args[0][0], args[0][1]);
      } else { // passed candy
        [candy] = args;
      }
      [, skipCollectableRemove] = args;
    } else {
      // passed cellX and cellY
      candy = this.getCandy(args[0], args[1]);
      [, , skipCollectableRemove] = args;
    }
    if (candy) {
      this.removeInfectionSource(candy, this._infectionSuperSources);
      this._grid.set(candy.cellX, candy.cellY, false);
      if (!skipCollectableRemove) candy.dispatchCollectables();
      this._setCandyRemoveVFX(candy);
      candy.kill();
      this._deadGroup.add(candy);
    }
  }

  /**
   * set VFX animation for candy removal if any.
   * @param {Candy} candy
   */
  _setCandyRemoveVFX(candy) {
    if (candy.isBurntCandy()) G.sb('fx').dispatch('burstConcreteAnim', candy, candy);
  }

  /**
   * add infection source
   * @param {Candy} candy
   * @param {Array.<Candy>} sourcesArray
   */
  addInfectionSource(candy, sourcesArray) {
    if (sourcesArray.indexOf(candy) === -1) sourcesArray.push(candy);
  }

  /**
   * remove infection source
   * @param {Candy} candy
   * @param {Array.<Candy>} sourcesArray
   */
  removeInfectionSource(candy, sourcesArray) {
    const index = sourcesArray.indexOf(candy);
    if (index !== -1) {
      sourcesArray.splice(index, 1);
      this._removedInfectionSource = true;
    }
  }

  /**
   * spread a infection on neighboring candies
   * @param {Array.<Candy>} sourcesArray infection source array
   * @returns {boolean} success
   */
  spreadInfection(sourcesArray) {
    if (sourcesArray.length === 0) return false;
    // get randomized infection source to spread from
    const source = ArrayUtil.getRandomElement(sourcesArray);

    for (let i = 0; i < INFECTION_SPREAD_COORDINATES.length; i++) {
      const coords = INFECTION_SPREAD_COORDINATES[i];
      const spreadX = source.cellX + coords[0];
      const spreadY = source.cellY + coords[1];

      const candyToInfect = this.getCandy(spreadX, spreadY);
      if (!candyToInfect) continue;

      if (!this._board.isMoveable(spreadX, spreadY)) continue;
      if (!this._board.isCellMatchable(spreadX, spreadY)) continue;
      if (candyToInfect.hasStatus()) continue;
      if (candyToInfect.hasSpecialType()) continue;
      candyToInfect.setStatus(CandyDataManager.getStatusByEditorSymbol('I'));
      return true;
    }
    return false;
  }

  /**
   * animated board deconstuct
   * @param {number} duration
   * @param {number} delayIcrement
   */
  deconstruct(duration = 300, delayIcrement = 40) {
    let delay = 0;
    for (let i = 0; i <= 20; i++) {
      let cellX = 0;
      for (let cellY = i; cellY >= 0; cellY--) {
        if (this._grid.get(cellX, cellY)) {
          game.add.tween(this._grid.get(cellX, cellY).scale).to({ x: 0, y: 0 }, duration, Phaser.Easing.Sinusoidal.InOut, true, delay);
        }
        cellX++;
      }
      delay += delayIcrement;
    }
  }

  /**
   * Loops all cells and calls the callback with them
   * @param {(cell) => void} callback Function to call on each cell
   */
  loopAllCells(callback) {
    for (let i = 0; i <= 20; i++) {
      let cellX = 0;
      for (let cellY = i; cellY >= 0; cellY--) {
        const cell = this._grid.get(cellX, cellY);
        if (cell) {
          callback.bind(this)(cell);
        }
        cellX++;
      }
    }
  }

  /**
   * Changes all cells' alpha to 0 in given time
   * @param {number} ms The duration of hiding animation
   */
  hideAllCells(ms = 0) {
    this.loopAllCells((cell) => {
      if (ms <= 0) {
        cell.alpha = 0;
      } else {
        game.add.tween(cell).to({ alpha: 0 }, ms, Phaser.Easing.Sinusoidal.Out, true);
      }
    });
  }

  /**
   * Changes all cells' alpha to 1 in given time
   * @param {number} ms The duration of showing animation
   */
  showAllCells(ms = 0) {
    this.loopAllCells((cell) => {
      if (ms <= 0) {
        cell.alpha = 1;
      } else {
        game.add.tween(cell).to({ alpha: 1 }, ms, Phaser.Easing.Sinusoidal.Out, true);
      }
    });
  }

  /**
   * get a list of all layers
   * @returns {Array.<Phaser.Group>}
   */
  getAllLayers() {
    return [
      this._deadGroup,
      this._primaryCandyGroup,
      this._movingCandyGroup,
      this._boosterFxGroup,
      this._specialCandyGroup,
      this._fxTopGroup,
      this._fxGroup,
    ];
  }

  /**
   * get editor symbol occurrence count
   * @param {string} symbol
   * @returns {number}
   */
  countEditorSymbolOccurrence(symbol) {
    return this.getAllCandies().filter((candy) => candy.hasEditorSymbol(symbol)).length;
  }

  /**
   * get export string occurrence count
   * @param {string} str
   * @returns {number}
   */
  countExportStringOccurrence(str) {
    return this.getAllCandies().filter((candy) => candy.hasExportString(str)).length;
  }

  /**
   * import candy data to specified postion
   * @param {number} cellX
   * @param {number} cellY
   * @param {Object} data
   */
  import(cellX, cellY, data) {
    this.newCandy(cellX, cellY, data);
  }

  /**
   * export candy data at specified postion
   * @param {number} cellX
   * @param {number} cellY
   * @returns {Object}
   */
  export(cellX, cellY) {
    const candy = this.getCandy(cellX, cellY);
    if (candy) return candy.export();
    return null;
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._removeGlobalListeners();

    this._board = null;
    this._boardData = null;
    this._grid.destroy(); this._grid = null;
    this._infectionSources = null;
    this._infectionSuperSources = null;
  }

  /** GETTER METHODS ********************************* */

  /** @returns {GridArray} candy GridArray instance */
  get grid() { return this._grid; }

  /** @returns {TopFxLayer} get the fx layer */
  get fxGroup() { return this._fxGroup; }

  /** @returns {Phaser.Group} get the overlay fx layer */
  get fxTopGroup() { return this._fxTopGroup; }

  /** @returns {Phaser.Group} get the booster fx layer */
  get boosterFxGroup() { return this._boosterFxGroup; }

  /** @returns {Phaser.Group} primary / normal candy layer */
  get primaryCandyGroup() { return this._primaryCandyGroup; }

  /** @returns {Phaser.Group} moving candy layer */
  get movingCandyGroup() { return this._movingCandyGroup; }

  /** @returns {Phaser.Group} special overlay candy layer */
  get specialCandyGroup() { return this._specialCandyGroup; }
}
