import { RenderTextureGenerator } from '@omt-components/Imaging/RenderTextures/RenderTextureGenerator';
import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_VerticalScrollArea from '../../OMT_UI/Scroll/OMT_UI_VerticalScrollArea';
import { TokenEvent_LeaderboardHeader } from '../Windows/tokenEvent/Leaderboard/Components/TokenEvent_LeaderboardHeader';

export default class Window_TreasureHuntBadgeCheck extends Window {
  constructor(parent, returnArgs) {
    super(parent);

    if (game.state.current === 'Game') this.y -= 75;

    this._badgeDimensions = 200; // The size of the badge, not including the counter
    this._badgeGroupHeight = 250; // The height of the badge group, including the counter
    this._returnArgs = returnArgs;
    this._badges = Object.keys(G.OMTsettings.elements.badges).filter((a) => a.indexOf('treasureHunt') > -1);

    this._drawEverything();
  }

  /**
   * Draws everything!
   */
  _drawEverything() {
    // Header
    this._drawMainHeader();
    this._addCloseButton();
    // content container
    this._badgeContainer = new Phaser.Group(game, null);

    // Badges
    this._drawAvailableBadges();

    // Scroll container
    this._initScrollContainer();
  }

  /**
   * Draws main header
   */
  _drawMainHeader() {
    this._headerGroup = new TokenEvent_LeaderboardHeader(G.OMTsettings.treasureHuntSuper.badgeWindow.headerConfig);
    const baseY = ((this._headerGroup.height / 2) - game.height) / 2;
    this._headerGroup.y = this._isLandscape ? baseY / this._gameScale : baseY;
    this.addChild(this._headerGroup);
  }

  /**
   * Modifies close button
   */
  _addCloseButton() {
    this.addCloseButton((game.width / 2) - 40, 0);
    this.closeButton.scale.set(0.9);
    this.closeButton.y = this._headerGroup.y + this.closeButton.height;
    this.addChild(this.closeButton);
  }

  /**
   * Draws all the badges that can be obtained
   * Badges that are not yet obtained are greyed out
   */
  _drawAvailableBadges() {
    let grandY = 0;
    const characters = G.OMTsettings.treasureHuntSuper.mascotOrder;
    const badgeLevels = ['Basic', 'Bronze', 'Silver', 'Gold'];
    const badgePaddings = G.OMTsettings.treasureHuntSuper.badgeWindow.badgeExtraPadding;
    this._allBadgesGroups = {};
    const padding = 30;
    const oriX = -((padding * 1.5) + this._badgeDimensions) / 2;
    for (const char of characters) {
      const badgeGroup = new Phaser.Group(game, this._badgeContainer);

      const header = this._drawBadgeHeaders();
      const headerDimensions = game.cache.getFrameByName(G.checkSheet('treasureHunt_badgeHeader'), 'treasureHunt_badgeHeader');
      const headerPos = G.OMTsettings.treasureHuntSuper.badgeWindow.miniHeader.position;
      const headerText = new G.Text(headerPos.x, headerPos.y, OMT.language.getText(`${char}'s Treasure Hunt`), 'font-white', 0.5, headerDimensions.width, headerDimensions.height);
      header.addChild(headerText);
      badgeGroup.addChild(header);

      let nextX = oriX;
      let nextY = (header.y + header.height / 2) + (padding + this._badgeDimensions) / 2;
      for (let i = 0; i < badgeLevels.length; i++) {
        const curBadge = badgeLevels[i];
        const targetBadge = `treasureHunt${curBadge}${char}`;
        const badgeAsset = G.makeImage(nextX, nextY, `${targetBadge}Full`, 0.5, badgeGroup);
        badgeAsset.scale.set((this._badgeDimensions + (this._badgeDimensions * badgePaddings[i])) / badgeAsset.width);

        const badgeAmount = G.saveState.badgeManager.getNumberOfBadgeObtained(targetBadge);
        const actualBadgeNumber = badgeAmount > -1 ? badgeAmount : 0;
        if (badgeAmount === -1) {
          G.makeImage(nextX, nextY, 'lock', 0.5, badgeGroup);
          badgeAsset.tint = Phaser.Color.hexToRGB('#555555');
        }

        const counterBg = G.makeImage(nextX, nextY + this._badgeDimensions / 2, G.OMTsettings.treasureHuntSuper.badgeWindow.counterBg, 0.5, badgeGroup);
        counterBg.scale.set(100 / counterBg.width);
        counterBg.y += counterBg.height / 4;
        this._counterText = new G.Text(0, 0, `x${actualBadgeNumber}`, 'treasureHunt-badgeCounter', 0.5, counterBg.width * 0.8);
        counterBg.addChild(this._counterText);

        nextX += this._badgeDimensions + padding;
        if (i > 0 && i % 2 === 1) {
          nextY += this._badgeGroupHeight + padding;
          nextX = oriX;
        }
      }

      badgeGroup.y = grandY;
      grandY += badgeGroup.height + padding;
      this._allBadgesGroups[char] = badgeGroup;
    }
  }

  _drawBadgeHeaders() {
    const textId = 'treasureHuntBadgeHeader';
    const anchor = [0.5, 0.5];
    let rtrHeader = RenderTextureGenerator.getRenderTextureSprite(textId, anchor);
    if (!this._avatar) {
      const header = new Phaser.Group(game, null);
      const headerTab = G.makeImage(0, 0, 'treasureHunt_badgeHeader', 0.5, header);
      const strokeData = G.OMTsettings.treasureHuntSuper.badgeWindow.dividerStroke;
      const strokeLine = new Phaser.Graphics(game);
      const strokeWidth = this._isLandscape ? game.width / this._gameScale : game.width;
      strokeLine.beginFill(strokeData.color, strokeData.alpha || 1);
      strokeLine.drawRect(0, 0, strokeWidth, strokeData.thickness);
      strokeLine.endFill();
      strokeLine.x = -strokeWidth / 2;
      strokeLine.y = headerTab.y + headerTab.height / 2;
      header.addChild(strokeLine);
      rtrHeader = RenderTextureGenerator.generateRenderTextureSprite(header, textId, anchor);
      header.destroy();
    }
    return rtrHeader;
  }

  _initScrollContainer() {
    const contentPadding = (this._badgeDimensions * 0.75) / 2;
    const scrollWidth = this._isLandscape ? game.width / this._gameScale : game.width;
    const scrollHeight = this._isLandscape ? game.height / this._gameScale : game.height;

    const scrollRect = new Phaser.Rectangle(-scrollWidth / 2, -scrollHeight / 2, scrollWidth, scrollHeight);
    const headingPadding = this._isLandscape ? 30 : 0;
    const yOffset = this._headerGroup.height + headingPadding;
    scrollRect.y += yOffset;
    scrollRect.height -= yOffset - contentPadding;
    this._scroll = new OMT_UI_VerticalScrollArea(scrollRect, true);
    this._scroll.extraHeight = contentPadding;
    this._scroll.allowOverScroll = true;
    this.addChild(this._scroll);
    this._badgeContainer.x = scrollWidth / 2;
    this._badgeContainer.y = contentPadding;
    this._scroll.content.addChild(this._badgeContainer);
  }

  /**
   * Checks if there was any args that was given. If there it, it will re-open the window (assuming nothing is in queue)
   * then continues to close itself
   */
  closeWindow() {
    if (this._returnArgs && this._returnArgs.layerName) {
      G.sb('pushWindow').dispatch([this._returnArgs.windowName].concat(this._returnArgs.args), false, this._returnArgs.layerName);
    }
    super.closeWindow();
  }

  get isFullscreen() {
    return false;
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.treasureHuntBadgeCheck = Window_TreasureHuntBadgeCheck;
