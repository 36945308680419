/* eslint-disable no-unused-vars */
import { CandyType_Basic } from './CandyType_Basic';

/**
 * class collectable chest CandyType
 */
export class CandyType_EventToken extends CandyType_Basic {
  /**
   * constructor
   * @param {BoardGameHooks} gameHooks
   */
  constructor(gameHooks) {
    super(gameHooks);
  }

  /**
   * Init happens every time a candy comes in.
   * The candy's basetype (this thing) always changes and re-inits when the candy is reinitialized
   * @param {Candy.js} candy
   * @param {Object} config
   */
  init(candy, config) {
    this._specialEventMgr = this._gameHooks.tokenEventManager;
    super.init(candy, config);
  }

  /**
   * on candy hit by adjacent match.
   * @returns {boolean}
   */
  onHit() {
    this._successfulBreak();
  }

  /**
   * Clears up everything unique to the fortune cookie and nulls it.
   * And then removes it
   */
  _clearCookie() {
    if (this._candy) this._candy.remove(); // And then removes it
  }

  /**
   * When the piece successfully breaks before time goes out
   */
  _successfulBreak() {
    const { board } = this._candy;
    const { eventTokenCounter } = this._gameHooks;
    const { lvlDataManager } = board;
    this._specialEventMgr.collectLevelToken();

    const pxBoard = board.cellToPxOut([this._candy.cellX, this._candy.cellY]);

    const layoutConfig = lvlDataManager.getLayoutConfig('tokenEvent') || {};
    const { boardTokenAsset } = layoutConfig;

    const newToken = G.makeImage(pxBoard[0], pxBoard[1], boardTokenAsset, 0.5); // The token asset that flies away
    const originScale = board.scale.x;
    newToken.scale.set(originScale);
    const targetEndLayer = board.candiesLayer.fxTopGroup; // The target end layer to go into
    const targetFortuneScale = 1;
    const midPointDrop = game.height / 2; // Mid point drop for bezier
    const targetPos = {
      x: eventTokenCounter != null ? eventTokenCounter.x : 0,
      y: eventTokenCounter != null ? eventTokenCounter.y : 0,
    };

    // Copying Taylor's amazing bezier path swoop aninmation
    const tweenTime = 1000;
    const m1 = { x: newToken.x, y: newToken.y + midPointDrop };
    const m2 = { x: targetPos.x, y: targetPos.y + midPointDrop };
    G.sb('fxTop').dispatch('burstConcreteAnim', this._candy, this._candy); // Break fx
    this._clearCookie(); // Cookie gone
    game.add.tween(newToken)
      .to({
        x: [newToken.x, m1.x, m2.x, targetPos.x], // Bezier Tweeeeeeeeeeeeeeeeeeeeen
        y: [newToken.y, m1.y, m2.y, targetPos.y],
      },
      tweenTime, Phaser.Easing.Sinusoidal.InOut, true).interpolation((v, k) => Phaser.Math.bezierInterpolation(v, k))
      .onComplete.add(() => {
        // Destroy!
        newToken.destroy();
        this._gameHooks.playSound('game.sfx.xylophone_positive_12');
        // Update event token counter
        if (eventTokenCounter) {
          eventTokenCounter.updateCount(1);
        }
      });
    game.add.tween(newToken.scale)
      .to({
        x: [newToken.scale.x, 2.5 * targetEndLayer.scale.x, targetFortuneScale * targetEndLayer.scale.x],
        y: [newToken.scale.y, 2.5 * targetEndLayer.scale.y, targetFortuneScale * targetEndLayer.scale.y],
      }, tweenTime, Phaser.Easing.Sinusoidal.InOut).start();
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._clearCookie();
  }
}
