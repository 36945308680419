/* eslint-disable no-useless-constructor */

/*
  class for dealing with platform specific functions that dont really fit elsewhere
*/
export class OMT_PlatformFunctions {
  /**
   * constructor
   */
  constructor() {
    //
  }

  /**
   * set platform loading display progress
   * @param {number} progress
   */
  setLoadingProgress(progress) {
    FBInstant.setLoadingProgress(progress);
  }

  /**
   * check if the user can create a shortcut
   * @returns {Promise}
   */
  async canCreateShortcutAsync() {
    return FBInstant.canCreateShortcutAsync();
  }

  /**
   * create the game shortcut
   * @returns {Promise}
   */
  async createShortcutAsync() {
    return FBInstant.createShortcutAsync();
  }

  /**
   * restart game by reloading the window
   */
  restartGame() {
    window.location.reload();
  }

  /**
   * quit the game if the platform allows
   */
  quitGame() {
    FBInstant.quit();
    if (G.BuildEnvironment.version === 'DEV') {
      window.location.reload();
    }
  }
}
