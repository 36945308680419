import { Window } from '../../00_IMMEDIATE/Window';
import { OMT_UI_GiftChest } from '../../OMT_UI/OMT_UI_GiftChest';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { Window_FillRateFallback } from './Window_FillRateFallback';
import GingyCharacterTutorial, { CHARACTER_KEYS } from '../GingyTutorial/G.GingyCharacterTutorial';
import { INTERSTITIAL_RULES } from '../../Services/OMT/ads/OMT_InterstitialAdRules';

const saveStateChestShuffleKey = 'chestShuffle';

export default class Window_ChestShuffleGame extends Window {
  /**
   * The Chest Shuffle game. Played when the player clicks on the mascot on the saga map.
   * @param {object} parent The parent this window should be added to.
   * @param {object} config The config for this window.
   * @param {CHARACTER_KEYS} config.charKey
   */
  constructor(parent, config = {}) {
    super(parent);

    const giftData = G.OMTsettings.elements.chestShuffle.gifts;
    this.firstTime = config.firstTime || G.saveState.data.finishedTutorials.indexOf(saveStateChestShuffleKey) === -1;
    let charKey = this.firstTime ? CHARACTER_KEYS.fancyGingy : config.charKey;
    if ((charKey === null || charKey === undefined)) {
      charKey = '';
      const rng = Math.floor(Math.random() * 10000) % 100;
      let totalPossibilities = 0;
      const GShuffleRate = G.OMTsettings.elements.chestShuffle.rates;
      for (let i = 0; i < GShuffleRate.length; i++) {
        const rateData = GShuffleRate[i];
        totalPossibilities += rateData.rate;
        if (rng < totalPossibilities) {
          charKey = rateData.char;
          break;
        }
      }
      if (charKey === '') {
        charKey = CHARACTER_KEYS.fancyGingy;
      }
    }

    G.saveState.chestShuffleDataManager.incrementOpened();
    // if ( DDNA.tracking.enabled) {
    //   DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('chestShuffleClickedCount', G.saveState.chestShuffleDataManager.opened);
    // }

    const giftProbabilities = giftData[charKey];

    [this.infiniteLivesGift] = this.pickFromArrayWithProbabilities(giftProbabilities.infiniteLives);
    [this.livesGift] = this.pickFromArrayWithProbabilities(giftProbabilities.lives);
    [this.coinsGift] = this.pickFromArrayWithProbabilities(giftProbabilities.coins);

    if (this.firstTime) this.infiniteLivesGift = 'lifeUnlimited_15';

    this.gifts = [
      this.coinsGift,
      this.livesGift,
      this.infiniteLivesGift,
    ];

    this.steps = [
      this.playStep1,
      this.playStep2,
      this.playStep3,
      this.playStep4,
    ];

    this.currentStep = 0;
    this.currentTweens = [];

    // used at the end when a chest is picked
    // slowly rotated in update
    this.shineBG = new G.Image(0, 0, 'shine_godrays', 0.5, this);
    this.shineBG.alpha = 0;
    this.shineBG.scale.set(1.5);

    this.topBar = new G.Image(0, 0, 'chest_shuffle_top_bar', 0.5, null);
    this.topBar.width = this._isLandscape
      ? parseInt(game.width / this._gameScale)
      : parseInt(game.width);
    this.topBar.height = 170;
    this.topBar.y = -250;

    this.gingyTutorial = new GingyCharacterTutorial(-220, 0, charKey);
    this.gingyTutorial.scale.set(G.OMTsettings.elements.chestShuffle.charSizeScale || 1);
    this.gingyTutorial.talk();
    this.gingyTutorial.y = this.topBar.y + this.topBar.height / 2;

    this.tutorialTxt = new G.Text(this.topBar.x + 100, this.topBar.y + 5, 'Testing', 'chestShuffleTutorial', 0.5, 400, this.topBar.height, true, 'center', true, false);

    const gameWidth = parseInt(game.renderer.width);
    const gameHeight = parseInt(game.renderer.height);

    this.skipClickArea = new Phaser.Graphics(game, 0, 0);

    this.skipClickArea.beginFill(0x000000);
    this.skipClickArea.drawRect(-gameWidth / 2, -gameHeight / 2 + 60, gameWidth, gameHeight);
    this.skipClickArea.endFill();

    this.skipClickArea.alpha = 0;
    this.skipClickArea.inputEnabled = true;

    // When the skip area is clicked, we stop all tweens and call their onCompletes.
    this.skipClickArea.events.onInputDown.add(() => {
      if (this.currentStep <= 1) {
        // we're going through this currentTweens array backwards
        // because we're removing tweens as we go.
        for (let i = this.currentTweens.length - 1; i >= 0; i--) {
          const tween = this.currentTweens[i];
          tween.stop(true);
        }
        this.currentTweens = [];
      }
    });
    this.add(this.skipClickArea);

    this.chests = [];
    const increaseScaleBy = 1.4;

    for (let i = 0; i < 3; i++) {
      const chest = this.chests[i] = new OMT_UI_GiftChest(game, this, this.gifts[i].split('_'));
      chest.scale.set(increaseScaleBy);

      // a spotlight that comes in when the player needs to pick a chest
      const spotlight = new G.Image(0, 0, 'spotlight', [0.5, 0], this);
      spotlight.scale.set(increaseScaleBy);
      spotlight.alpha = 0;
      chest.spotlight = spotlight;
      chest.alpha = 0;
    }

    // adding these elements here so they're on top of the spotlights
    this.add(this.topBar);
    this.add(this.gingyTutorial);
    this.add(this.tutorialTxt);

    // positioning chests and spotlights
    const chestPaddingX = 180;
    const chestPaddingY = 250;

    this.chests[0].y = 50;
    this.chests[1].x -= chestPaddingX;
    this.chests[1].y += chestPaddingY;
    this.chests[2].x += chestPaddingX;
    this.chests[2].y += chestPaddingY;

    this.chests[0].spotlight.position.set(-450, 5);
    this.chests[0].spotlight.angle = -45;
    this.chests[1].spotlight.position.set(-15, -170);
    this.chests[2].spotlight.position.set(450, -15);
    this.chests[2].spotlight.angle = 45;

    // we're going to be tweening the chests later, so save this so we can tween them
    this.chests.forEach((c) => {
      c.originalPos = c.position.clone();
    });

    this.cloud = new G.Image(650, 150, 'cloud_1', 0.5, this);
    this.cloud.scale.set(increaseScaleBy);
    this.cloud.x = game.width * 0.5 + this.cloud.width;

    // setup the claim and try again buttons that appear when a chest is selected
    this.setupButtons();

    // each frame automatically moves to the next when its done, so we just need to setup frame 1 to start the chain
    this.playStep1();
  }

  closeWindow() {
    G.saveState.save();
    // if ( DDNA.tracking.enabled) {
    //    DDNA.tracking.getDataCapture().writePlayerCharacterizationData();
    // }
    super.closeWindow(() => {
      this.state.checkForAds(() => {}, INTERSTITIAL_RULES.GIFT_GAIN);
    });
  }

  /**
   * The intro to the game.
   */
  async playStep1() {
    this.tutorialTxt.setText(OMT.language.getText("Hey, let's play a game!"));
    await this.sleep(2000);
    this.advance();
  }

  /**
   * The chests appear, open to reveal their rewards, close, and then begin to shuffle.
   */
  async playStep2() {
    this.tutorialTxt.setText(OMT.language.getText("I've hidden 3 gifts in these chests."));

    this.gingyTutorial.eyes.y = this.gingyTutorial.eyesDefaultPos.y + 8;

    this.chests.forEach((c) => {
      this.addTween(c).to({ alpha: 1 }, 300).start();
    });

    await this.sleep(700);

    this.chests.forEach((c) => {
      // making sure that if the player skipped thru
      // the chest appearing anim, the chests arent semi-transparent
      c.alpha = 1;
      c.open(false, 75);
    });

    await this.sleep(2000);

    this.gingyTutorial.eyes.y = this.gingyTutorial.eyesDefaultPos.y;
    this.gingyTutorial.idle();

    this.chests.forEach((c) => {
      c.close();
    });

    await this.sleep(500);

    this.chests.forEach((c) => {
      this.addTween(c).to({ x: 0, y: 150 }, 800, Phaser.Easing.Exponential.Out).start();
    });

    await this.sleep(200);

    this.addTween(this.cloud).to({ x: 0 }, 1200, Phaser.Easing.Exponential.Out).start();

    this.chestShakeTween = this.addTween({}).to({}, 5000, Phaser.Easing.Default, true, 450).onUpdateCallback(() => {
      const chestRotation = Math.sin(Date.now() * 0.01);
      this.chests[0].img.angle = this.map(chestRotation, -1, 1, -20, 20);
    });

    const startXPositions = [90, 0, 90];
    const tweenXPositions = [160, 0, -160];
    this.bounceTweens = [];
    for (let i = 0; i < startXPositions.length; i++) {
      const bounceTween = this.addTween(this.chests[0].img, 1);
      this.bounceTweens.push(bounceTween);
      bounceTween.to({ x: tweenXPositions[i], y: -150 }, 360, Phaser.Easing.Linear.None, true, i * 720 + 450, 0, true);

      bounceTween.onStart.add(() => {
        this.chests[0].img.x = startXPositions[i];
      });

      if (i === startXPositions.length - 1) {
        bounceTween.onComplete.add(async () => {
          this.addTween(this.cloud, 1).to({ x: -game.width * 0.5 - this.cloud.width }, 1200, Phaser.Easing.Exponential.Out).start().onComplete.add(() => { this.cloud.alpha = 0; });
          this.advance();
        });
      }
    }
  }

  /**
   * The animation after the shuffle.
   * The cloud is removed, the chests return to their original position, a light is shone on them, and the player picks one.
   */
  async playStep3() {
    if (this.chestShakeTween) this.chestShakeTween.stop();
    this.chests[0].img.x = 0;
    this.chests[0].img.y = 0;
    this.chests[0].img.angle = 0;
    this.chests.forEach((c) => {
      this.addTween(c).to({ x: c.originalPos.x, y: c.originalPos.y }, 800, Phaser.Easing.Exponential.Out).start();
    });

    await this.sleep(200);

    this.tutorialTxt.setText(OMT.language.getText('Pick a chest to claim your prize!'));

    this.chests.forEach((c) => {
      this.addTween(c.glow).to({ alpha: 1 }, 300).start();
      this.addTween(c.spotlight).to({ alpha: 1 }, 400, Phaser.Easing.Default, true);
    });
    this.gingyTutorial.eyes.y = this.gingyTutorial.eyesDefaultPos.y;
    this.gingyTutorial.talk();

    this.chests.forEach((c) => {
      c.img.inputEnabled = true;
      c.img.input.useHandCursor = true;
      c.img.events.onInputDown.add(() => {
        this.openGift(c);
      });
    });

    await this.sleep(1100);

    this.gingyTutorial.idle();
  }

  /**
   * Opens the chest and puts it front and center.  Also shows the 'Claim' and 'Try Again' buttons.
   * @param {object} giftChest The chest the player selected.
   */
  async openGift(giftChest) {
    this.selectedChest = giftChest;
    if (this.firstTime) { G.saveState.data.finishedTutorials.push(saveStateChestShuffleKey); }
    this.chests.forEach((c) => {
      c.spotlight.alpha = 0;

      c.img.inputEnabled = false;
      c.img.input.useHandCursor = false;
      game.tweens.removeFrom([c, c.img]);

      if (c !== giftChest) {
        this.addTween(c).to({ alpha: 0 }, 300).start();
      } else {
        const realGift = this.pickFromArrayWithProbabilities([
          [this.infiniteLivesGift, 0.15],
          [this.coinsGift, 0.35],
          [this.livesGift, 0.5],
        ]);

        this.giftClaimed = {
          object: c,
          gift: (this.firstTime ? 'lifeUnlimited_15' : realGift[0]).split('_'),
        };

        c.setupGift(this.giftClaimed.gift);

        this.addTween(c).to({ x: 0, y: 200 }, 600, Phaser.Easing.Exponential.Out).start();
        this.addTween(c.img.scale).to({ x: 0.45, y: 0.45 }, 300, Phaser.Easing.Default, true, 500);
        const shakeTween = this.addTween({}).to({}, 400, Phaser.Easing.Default, true, 500).onUpdateCallback(() => {
          const chestRotation = Math.sin(Date.now() * 0.05);
          c.img.angle = this.map(chestRotation, -1, 1, -5, 5);
        }).start();

        shakeTween.onComplete.add(async () => {
          c.img.angle = 0;
          c.open(true);
          this.addTween(c.img.scale).to({ x: 1, y: 1 }, 500, Phaser.Easing.Back.Out).start();
          this.shineBG.alpha = 1;
          this.shineBG.x = c.x;
          this.shineBG.y = c.y;
          await this.sleep(500);
          this.advance();
        });
      }
    });
  }

  /**
   * The animation played when the player selects a chest.
   */
  async playStep4() {
    this.tutorialTxt.setText(OMT.language.getText('Nice job!'));
    this.gingyTutorial.talk();

    this.addTween(this.claimRewardBtn).to({ alpha: 1 }, 300).start();
    if (!this.firstTime) {
      this.addTween(this.tryAgainBtn).to({ alpha: 1 }, 300).start();
    }


    await this.sleep(1000);
    this.gingyTutorial.idle();
  }

  /**
   * Sets up the 'Claim' and 'Try Again' buttons
   */
  setupButtons() {
    this.claimRewardBtn = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
        extraDetail: true,
      },
      text: {
        string: OMT.language.getText('Claim'),
        textStyle: { style: 'font-white', fontSize: 70 },
      },
      options: {
        clickFunction: {
          onClick: this.onClaimClick.bind(this),
          disableAfterClick: true,
        },
      },
    });
    this.tryAgainBtn = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 309,
          height: 100,
        },
        extraDetail: true,
      },
      text: {
        string: OMT.language.getText('Try again'),
        textStyle: { style: 'font-white', fontSize: 70 },
        icon: {
          image: new G.Image(0, 0, 'video_wheel_menu', 0.5, this),
          align: 'left',
          offset: { x: 30, y: 0 },
        },
      },
      options: {
        clickFunction: {
          onClick: this.onTryAgainClick.bind(this),
          disableAfterClick: true,
        },
      },
    });
    this.tryAgainBtn.y = 400;
    this.claimRewardBtn.y = this.tryAgainBtn.y + this.tryAgainBtn.height + 5;
    this.add(this.tryAgainBtn);
    this.add(this.claimRewardBtn);
    this.tryAgainBtn.alpha = 0;
    if (this.firstTime) this.tryAgainBtn.visible = false;
    this.claimRewardBtn.alpha = 0;
  }

  /**
   * If the player clicks the claim button, we apply the gift and close the window.
   */
  onClaimClick() {
    const giftStuff = G.gift.processGift(this.giftClaimed.gift);
    this._trackRewards(this.giftClaimed.object, giftStuff);
    this.closeWindow();
  }

  /**
   *
   * @param {Phaser.Image} chest
   * @param {Array<String, number>} giftStuff
   */
  _trackRewards(chest, giftStuff) {
    OMT.transactionTracking.logInventoryTransactionBegin();

    switch (giftStuff[0]) {
      case 'coin':
        this.state.uiTargetParticles.createCoinBatch( // Show bling
          game.world.bounds.x + chest.worldPosition.x,
          chest.worldPosition.y,
          this.state.panel.coinsTxt,
          giftStuff[1],
          false,
        );
        OMT.transactionTracking.addInventoryChange('coins', 0, giftStuff[1]);
        break;
      case 'life':
        G.saveState.addLife(giftStuff[1]); // Add life
        break;
      case 'lifeUnlimited':
        G.saveState.addUnlimitedLivesTimeMin(giftStuff[1]);
        break;
      default: { // Perhaps bad, but default is assuming booster
        const boosterIndex = parseInt(giftStuff[0][8], 10);
        G.saveState.changeBoosterAmount(boosterIndex, giftStuff[1]); // Add in booster
        OMT.transactionTracking.addInventoryChange('boostersReceived', boosterIndex, giftStuff[1]);
        break;
      }
    }

    // DDNA.transactionHelper.trackRewards(giftStuff, [], { // DDNA tracking
    //   transactionType: 'REWARD',
    //   tActionType: 'CHEST_SHUFFLE',
    //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
    // });
    OMT.transactionTracking.logInventoryTransactionEnd();
  }

  /**
   * If the player clicks try again, we give them an ad.  If the ad is successful, we re-shuffle the chests (function below)
   */
  onTryAgainClick() {
    OMT.ads.showAd(
      G.BuildEnvironment.adPlacements.chestShuffleRetry,
      () => {
        this.onAdSuccess();
      },
      () => {
        if (!G.IAP) { // no IAPS dont use ad fallbacks
          // eslint-disable-next-line no-new
          new Window_FillRateFallback(undefined, {
            placement: G.BuildEnvironment.adPlacements.chestShuffleRetry,
            callback: () => {
              this.onAdSuccess();
            },
            context: this,
          });
        } else { // ad fallback
          OMT.ads.showAdFallback(this.onAdSuccess.bind(this));
        }
      },
    );
  }

  /**
   * If the ad is successful, we reset the game and shuffle the chests again.
   */
  onAdSuccess() {
    // we dont want to show them the 15min infinite lives again.
    this.firstTime = false;

    // the player can only try again once.
    this.tryAgainBtn.visible = false;

    // if they successfully try again, reset the chests and run the shuffle again
    this.chests.forEach((c) => {
      c.reset();
    });

    for (let i = 0; i < this.gifts.length; i++) {
      const gift = this.gifts[i];
      this.chests[i].setupGift(gift.split('_'));
    }

    this.claimRewardBtn.alpha = 0;
    this.shineBG.alpha = 0;
    this.cloud.alpha = 1;
    this.cloud.x = game.width * 0.5 + this.cloud.width * 0.6;

    // play shuffle animation again
    this.currentStep = 1;
    this.playStep2();
  }

  /**
   * Function that runs every frame.  Use for cloud animation and shine background rotation.
   */
  update() {
    const cloudScaleX = Math.sin(Date.now() * 0.004);
    const cloudScaleY = Math.cos(Date.now() * 0.004);
    this.cloud.scale.x = this.map(cloudScaleX, -1, 1, 1, 1.1);
    this.cloud.scale.y = this.map(cloudScaleY, -1, 1, 1, 1.1);
    this.shineBG.angle += 0.1;
  }

  /**
   * Move on to the next step of the animation.
   */
  advance() {
    this.currentStep++;
    if (this.currentStep < this.steps.length) {
      this.steps[this.currentStep].bind(this)();
    }
  }

  /**
   * To be used in place of game.add.tween.  Adds the tween to a list so we can skip it when we need to fast-forward.
   * @param {object} target The target of the tween
   */
  addTween(target) {
    const t = game.add.tween(target);
    this.currentTweens.push(t);
    t.onComplete.add(() => {
      this.currentTweens.splice(this.currentTweens.indexOf(t), 1);
    });
    return t;
  }

  /**
   * A simple function that transforms an empty tween into a promise
   * @param {number} ms The milliseconds to sleep for
   */
  sleep(ms) {
    return new Promise((resolve) => {
      const empty = {};
      const sleepTween = this.addTween(empty).to({}, ms).start();
      sleepTween.onComplete.add(() => {
        resolve();
      });
    });
  }

  /**
   * Map a value from one range to another range. e.g. map(5, 1, 10, 1, 20) would return 10.
   * @param {number} val The value in the given range
   * @param {number} in_min The minimum number of the first range.
   * @param {number} in_max The maximum number of the first range.
   * @param {number} out_min The minimum number of the range to be mapped to.
   * @param {number} out_max The maximum number of the range to be mapped to.
   */
  map(val, in_min, in_max, out_min, out_max) {
    return (val - in_min) * ((out_max - out_min) / (in_max - in_min)) + out_min;
  }

  /**
   * Picks from an array of value-probability pairs with probability applied
   * @param {Array} array The array to pick the element from.  In the form [[value:any, probability:number]]
   */
  pickFromArrayWithProbabilities(array) {
    const probabilities = [];
    for (let i = 0; i < array.length; i++) {
      const el = array[i];
      probabilities.push(el[1]);
    }

    // construct a sum list from given probabilities
    const sumOfProbabilities = [];

    // sumOfProbabilities[i] holds sum of all probability[j] for 0 <= j <=i
    // array destructuring rules makes this hard to read; we're taking element 0 of probabilities and assigning it to probSum[0]
    [sumOfProbabilities[0]] = probabilities;
    for (let i = 1; i < array.length; i++) {
      sumOfProbabilities[i] = sumOfProbabilities[i - 1] + probabilities[i];
    }

    // generate a random integer from 1 to 100
    // and check where it lies in sumOfProbabilities[]
    const r = game.rnd.realInRange(0, 1);

    // based on the comparison result, return corresponding
    // element from the input list

    if (r <= sumOfProbabilities[0]) { // handle 0'th index separately
      return array[0];
    }

    for (let i = 1; i < array.length; i++) {
      if (r > sumOfProbabilities[i - 1] && r <= sumOfProbabilities[i]) {
        return array[i];
      }
    }

    return -1;
  }
}
// create global references
if (!window.Windows) window.Windows = {};
Windows.chestShuffle = Window_ChestShuffleGame;
