G.Helpers = G.Helpers || {};

G.Helpers.pushOutOfCoinsPopUp = function (forced, eventConfig, freeGift = false, layerOverride = null) {
  if (G.IAP) {
    G.sb('pushWindow').dispatch(
      [
        'coinShop',
        { forced, freeGift },
      ],
      forced,
      layerOverride || G.WindowMgr.LayerNames.Base,
    );
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.OpenShop);
    if (eventConfig) {
      OMT.platformTracking.logEvent(eventConfig.event, eventConfig.payload);
    }
    return true;
  }
  if (game.incentivised()) {
    if (!(OMT.feature.prizeWheelIsLimited() && G.saveState.isPrizeWheelOnCooldown())) {
      G.sb('pushWindow').dispatch(['dailyWheel', {
        worldState: game.state.current === 'World',
        asMoreMoney: true,
      }]);
      return true;
    }
    return false;
  }
  return false;
};
