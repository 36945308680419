G.NoMoreAds = function(config) {

	Phaser.Image.call(this,game);

  this.config = config || {};

	this.bg = G.makeImage(0,0,'text_shade_bg',0.5,this);

	this.txt = new G.Text(0, 0,this.config.txt || OMT.language.getText('Something went wrong! Please, try again.'), {
		style: "font-white",
		fontSize: "70px"
	}, 0.5, 600, 300, true, "center");
	this.addChild(this.txt);
	this.bg.width = this.txt.width+100;
	this.bg.height = this.txt.height+100;

	this.fixedToCamera = true;

	this.cameraOffset.x = game.width*0.5;
	this.cameraOffset.y = game.height*0.5;



	game.add.tween(this).to({alpha:0},500,Phaser.Easing.Sinusoidal.InOut,true,this.config.duration || 2500).onComplete.add(function() {
		this.destroy();
	},this);

	game.add.tween(this).from({alpha:0},500,Phaser.Easing.Sinusoidal.InOut,true);

	game.add.existing(this);

};

G.NoMoreAds.prototype = Object.create(Phaser.Image.prototype);

G.NoMoreAds.prototype.update = function() {
	
	this.cameraOffset.x = game.width*0.5;
	this.cameraOffset.y = game.height*0.5; 

}