export const MILLISECONDS_IN_SEC = 1000;
export const MILLISECONDS_IN_MIN = 60 * MILLISECONDS_IN_SEC;
export const MILLISECONDS_IN_HOUR = 60 * MILLISECONDS_IN_MIN;
export const MILLISECONDS_IN_DAY = 24 * MILLISECONDS_IN_HOUR;
export const MILLISECONDS_IN_WEEKS = 7 * MILLISECONDS_IN_DAY;

/*
  Utility Class for time functions
*/
export default class TimeUtil {
  /**
   * get a time code for countdowns etc.
   * @param {number} time time in millisconds
   */
  static getTimeCode(time) {
    const hours = Math.floor(time / MILLISECONDS_IN_HOUR);
    time -= hours * MILLISECONDS_IN_HOUR;
    const mins = Math.floor(time / MILLISECONDS_IN_MIN);
    time -= mins * MILLISECONDS_IN_MIN;
    const secs = Math.floor(time / MILLISECONDS_IN_SEC);
    return `${TimeUtil.formatTimeToTwoDigit(hours)}:${TimeUtil.formatTimeToTwoDigit(mins)}:${TimeUtil.formatTimeToTwoDigit(secs)}`;
  }

  /**
   * Add the leading 0 if time is too short
   * @param {number}
   * @returns {string}
   */
  static formatTimeToTwoDigit(time) {
    if (time.toString().length === 1) {
      return `0${time}`;
    }
    return time.toString();
  }

  /**
   * get days since date time
   * @param {Date} date
   */
  static getDaysSinceDate(date) {
    const startTime = date.getTime();
    const deltaTime = Date.now() - startTime;
    const diff = Math.floor(deltaTime / MILLISECONDS_IN_DAY);
    return diff;
  }

  /**
   * calculate intervals passed between two time stamps
   * @param {number} originTime unix timestamp to start calculations from
   * @param {number} currentTime unix timestamp current time
   * @param {number} intervalDuration duration of interval in milliseconds
   * @returns {number}
   */
  static calculateIntervalsElpased(originTime, currentTime, intervalDuration) {
    const deltaTime = currentTime - originTime;
    const intervalsElapsed = Math.abs(Math.floor(deltaTime / intervalDuration));
    return intervalsElapsed;
  }
}

window.TimeUtil = TimeUtil;
