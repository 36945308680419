import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import JSONUtil from '@omt-components/Utils/JsonUtil';
import TimeUtil, { MILLISECONDS_IN_HOUR } from '@omt-components/Utils/TimeUtil';

const LEADERBOARD_ID = 'weeklyTournament';

/**
 *  class for handling FB Tournament
 */
export class OMT_PlatformTournaments {
  /**
   * constructor
   */
  constructor() {
    this._contextId = null;
    this._contextualTournamentId = null;
    this._contextData = null;
    this._contextPlayerCount = 1;
    this._levelOverrideId = null;
    this._lastFetchedTournamentEntries = [];
    this._isFirstTimeTournamentEntry = false;
    this._settings = G.OMTsettings.tournaments;
  }

  /**
   * check if the user entered from a tournament entryPoint and if so return the contextId
   * @returns {string} should be null if user didnt enter from a tournament context
   */
  detectTournamentContextId() {
    if (!G.featureUnlock.weeklyTournament.enabled) return null;
    const { payload } = OMT.envData;
    let contextId = null;

    if (OMT.social.getCurrentContextType() === 'THREAD' && !payload) { // user enterted from a tournament thread / share post
      contextId = FBInstant.context.getID();
    } else if (payload && payload.tournamentContextId) { // user entered from  a tournament share link
      contextId = payload.tournamentContextId;
    }

    // flag if this is a first time user playing a tournament
    this._isFirstTimeTournamentEntry = G.firstTime === true && contextId != null;

    // contextId = '3791911987573052'; '4218762968186891';
    return contextId;
  }

  /**
   * true if this user is a first time user and entred by a tournament post / share link
   * @returns {boolean}
   */
  get isFirstTimeTournamentEntry() {
    return this._isFirstTimeTournamentEntry;
  }

  /**
   * get the context id last set through updateLeaderboardContext()
   * @returns {string}
   */
  get tournamentContextId() {
    return this._contextId;
  }

  /**
   * get the tournament context data for the active tournament
   * @returns {Object}
   */
  get tournamentContextData() {
    return this._contextData;
  }

  /**
   * get level id for the active tournament
   * @param {Date} date (optional)
   * @returns {string}
   */
  getTournamentLevelId(date = null) {
    // user level override if one was set for debugging
    if (this._levelOverrideId) return this._levelOverrideId;
    // use the tournament level retrieved from the context
    if (this._contextData) return this._contextData.level;
    // new tournament get level from rotation
    const { levelLoop } = this._settings;
    const levelIndex = this.getTournamentLevelIndex(levelLoop.length, date);
    return levelLoop[levelIndex];
  }

  /**
   * get the LevelData Obejct of the currently active tournament
   * @returns {Object}
   */
  getTournamentLevelData() {
    const levelId = OMT.platformTournaments.getTournamentLevelId();
    const levelData = G.Helpers.levelDataMgr.getLevelById(levelId);
    // configure the level data for tournament play
    levelData.goal = ['points', -1];
    levelData.starsReq = [0, 0, 0];
    return levelData;
  }

  /**
   * overrides the tournament id from the rotation or the contextData
   * @param {string} levelId id of level
   */
  setLevelOverride(levelId) {
    this._levelOverrideId = levelId;
  }

  /**
   * get time remainingin ms for the current tournament rotation
   * @param {Date} date (optional) otherwise use OMT.connect.getServerTimestampSync()
   * @returns {number}
   */
  getTournamentTimeRemaining(date = null) {
    const currentTime = date !== null ? date.getTime() : OMT.connect.getServerTimestampSync();
    const { rotationOriginTimestamp, rotationIntervalHrs } = this._settings;
    const rotationInterval = rotationIntervalHrs * MILLISECONDS_IN_HOUR;
    const rotationsElapsed = TimeUtil.calculateIntervalsElpased(rotationOriginTimestamp, currentTime, rotationInterval);
    const nextRotationStartsAt = (rotationOriginTimestamp + ((rotationsElapsed + 1) * rotationInterval));
    const tournamentTimeRemaining = nextRotationStartsAt - currentTime;
    return tournamentTimeRemaining;
  }

  /**
   * get current cooldown time remaining in ms, 0 if tournament is not on cooldown
   * @param {Date} date (optional) otherwise use OMT.connect.getServerTimestampSync()
   * @returns {number}
   */
  getCooldownTimeRemaining(date = null) {
    const currentTime = date !== null ? date.getTime() : OMT.connect.getServerTimestampSync();
    const { rotationOriginTimestamp, rotationIntervalHrs, cooldownDurationHrs } = this._settings;
    const rotationInterval = rotationIntervalHrs * MILLISECONDS_IN_HOUR;
    const cooldownDuration = cooldownDurationHrs * MILLISECONDS_IN_HOUR;
    const rotationsElapsed = TimeUtil.calculateIntervalsElpased(rotationOriginTimestamp, currentTime, rotationInterval);
    const rotationStartedAt = (rotationOriginTimestamp + (rotationsElapsed * rotationInterval));
    let cooldownRemaining = cooldownDuration - (currentTime - rotationStartedAt);
    if (cooldownRemaining < 0) cooldownRemaining = 0;
    return cooldownRemaining;
  }

  /**
   * get the levelIndex of the current tournament
   * @param {number} totalLevels levels available
   * @param {Date} date (optional) otherwise use OMT.connect.getServerTimestampSync()
   * @returns {number}
   */
  getTournamentLevelIndex(totalLevels, date = null) {
    const currentTime = date !== null ? date.getTime() : OMT.connect.getServerTimestampSync();
    const { rotationOriginTimestamp, rotationIntervalHrs } = this._settings;
    const rotationInterval = rotationIntervalHrs * MILLISECONDS_IN_HOUR;
    const rotationsElapsed = TimeUtil.calculateIntervalsElpased(rotationOriginTimestamp, currentTime, rotationInterval);
    const levelIndex = rotationsElapsed % totalLevels;
    return levelIndex;
  }

  /**
   * update the context for the leaderboard. sync context data from leaderborads.
   * @param {string} contextId (optional) pass in a context id for testing
   * @param {Object} contextData (optional) tournament contextual data
   * @returns {Promise}
   */
  async updateLeaderboardContext(contextId = null, contextData = null) {
    if (!contextId) { // no id passed we get it from FB
      this._contextId = FBInstant.context.getID();
    } else { // contextId was passed in, we set the context
      this._contextId = contextId;
    }
    this._contextualTournamentId = this._contextId != null ? `${LEADERBOARD_ID}.${this._contextId}` : null;

    // update the Tournament context data
    if (!contextData) this._contextData = await this._loadTournamentContextData();
    else this._contextData = contextData;

    // update the context player count
    await this.updateContextPlayerCount();

    OMT_Utils.stylizedLog(`OMT_PlatformTournaments: context id set to ${this._contextId}`, '#FF0000');
  }

  /**
   * update the count of players in the tournament context
   */
  async updateContextPlayerCount() {
    if (!this._contextId) {
      this._contextPlayerCount = 1; return;
    }
    let playerCount = 0;
    try {
      playerCount = (await FBInstant.context.getPlayersAsync()).length;
    } catch (error) {
      console.log(error);
    }
    this._contextPlayerCount = playerCount > 0 ? playerCount : 1;
  }

  /**
   * get the count of players in the tournament context
   * @returns {Number}
   */
  get contextPlayerCount() {
    return this._contextPlayerCount;
  }

  /**
   * attempts to switch back the the previously set tournament context id
   * @param {boolean} clearContextIdOnFail if set to true the tournament context id will be cleared on switchAsync fail
   */
  async switchToTournamentContext(clearContextIdOnFail = false) {
    if (this._levelOverrideId) return;
    if (!this._contextId || this._contextId === FBInstant.context.getID()) return; // context id not set do nothing
    try {
      await FBInstant.context.switchAsync(this._contextId);
    } catch (error) {
      OMT_Utils.stylizedLog(`OMT_PlatformTournaments: could not switch to context id ${this._contextId}`, '#FFFF00');
      if (clearContextIdOnFail) { // optional clear context id on switch fail
        this._contextId = null;
        this._contextualTournamentId = null;
      }
    }
  }

  /**
   * load contextual data for the tournament. This is stored in the contextual leaderboards.
   * @returns {Promise<Object>}
   */
  async _loadTournamentContextData() {
    // attempt to get context data from the the contextual leaderboard
    const fallbackLevel = this.getTournamentLevelId();
    let contextData;
    const entries = await OMT.platformLeaderboards.getLeaderboardEntries(this._contextualTournamentId, 1);
    if (entries.length > 0) {
      const entryData = entries[0];
      if (entryData.extraData && JSONUtil.isJson(entryData.extraData)) { // has context data
        contextData = JSON.parse(entryData.extraData);
        if (!contextData.level) contextData.level = fallbackLevel;
      }
    }
    // could not find data, use fallback data
    if (!contextData) contextData = { level: fallbackLevel, started: 0 };
    return contextData;
  }

  /**
   * post a score for the session, should only be done internally
   * @param {number} score
   */
  async postSessionScore(score) {
    if (this._levelOverrideId) return; // dont post a score if we overrode the level
    try {
      await FBInstant.postSessionScoreAsync(score);
      console.log(`SESSION SCORE POSTED ${score}`);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Get a your highest tournament score from the leaderboard
   * @returns {Promise<Number>} your high score
   */
  async getTournamentHighScore() {
    if (!this._contextualTournamentId || this._levelOverrideId) return 0;
    const entryData = await OMT.platformLeaderboards.getLeaderboardEntryData(this._contextualTournamentId);
    if (!entryData) return 0;
    return entryData.getScore();
  }

  /**
   * get a list of tournament leaderboard entries
   * @returns {Promise<Array>}
   */
  async getTournamentEntries() {
    let entries;
    if (!this._contextualTournamentId || this._levelOverrideId) {
      entries = [];
    } else {
      entries = await OMT.platformLeaderboards.getLeaderboardEntries(this._contextualTournamentId);
    }
    this._lastFetchedTournamentEntries = entries;
    return entries;
  }

  /**
   * get the last fetched list of tournament leaderboard entries by getTournamentEntries()
   * @returns {Array}
   */
  get lastFetchedTournamentEntries() {
    return this._lastFetchedTournamentEntries;
  }

  /**
   * Post a tournament score to the tournament leaderboard
   * @param {number} score
   * @returns {Promise<Number>} your high score
   */
  async postTournamentLeaderboardScore(score) {
    if (this._levelOverrideId) return 0; // dont post a score if we overrode the level
    if (!this._contextualTournamentId || this._contextId !== FBInstant.context.getID()) return 0; // check valid context
    const highScore = await OMT.platformLeaderboards.postLeaderboardScore(this._contextualTournamentId, score, JSON.stringify(this._contextData));
    return highScore;
  }

  /**
   * mock entry list shown upon tournament creation so we dont need to sync data
   * @param {number} score
   * @param {number} rank
   */
  getMockTournamentEntries(score, rank) {
    const leaderboardEntryData = {};
    leaderboardEntryData.id = leaderboardEntryData.userId = OMT.envData.settings.user.userId;
    leaderboardEntryData.name = OMT.envData.settings.user.name;
    leaderboardEntryData.image = OMT.envData.settings.user.avatar;
    leaderboardEntryData.rank = rank;
    leaderboardEntryData.score = score;
    leaderboardEntryData.isCurrentUser = true;
    leaderboardEntryData.extraData = { level: this.getTournamentLevelId() };
    const entries = [leaderboardEntryData];
    return entries;
  }

  /**
   * set a callback for when a tournament context is created, changing states will cancel this
   * @param callback
   */
  setTournamentCreatedCallback(callback) {
    // set a interval to check fo a context change
    const prevContextId = this._contextId;
    const intervalId = setInterval(() => {
      // execute the callback and cancel the interval if context changed
      if (prevContextId !== FBInstant.context.getID()) {
        clearInterval(intervalId);
        // DDNA.tracking.socialActionEvent('TOURNAMENT_START', '', '', '');
        // const contextData = { level: this.getTournamentLevelId(), started: OMT.connect.getServerTimestampSync() };
        // this.updateLeaderboardContext(FBInstant.context.getID(), contextData).then(() => {
        //   callback();
        // });
      }
    }, 100);
    // cancel the interval is state changes
    G.sb('onStateChange').addOnce(() => {
      clearInterval(intervalId);
    });
  }
}
