G.LevelGenerator = {};

G.LevelGenerator.generate = function(config) {

	var lvl = {
		mapX : -200+(Math.random()*400),
		mapY : Math.random()*-400,
		moves: config.movesNr,
		nrOfTypes: config.typesOfCandy,
		goal: ['collect',[]],
		bgImg: config.bgImg,
		starsReq : [3000,5000,7000],
		drops: {
			chest: config.chestDrop,
			chain: config.chainDrop,
			infection: config.infectionDrop
		},


	};

	var width = 8;
	var height = 8;

	var board = new G.GridArray(width,height);
	board.loop(function(elem,coll,row,array) {
		array[coll][row] = [];
	});


	var pickedBlockers = this.pickBlockers(lvl,config);

	this.putBlockers(board,config,pickedBlockers);

	lvl.levelData = board.data;

	lvl.goal[1] = this.makeGoal(board,config,lvl,pickedBlockers);

	/*
	//cages
	//this.putInLines(board,config.maxCage,'cg',['X','cg']);
	this.putSymmetrical(board,'cg',this.getRandomEvenInRange(config.cage[0],config.cage[1]),['X','cg','ice1','ice2'],2);

	//chocolate
	//this.putInLines(board,config.maxChocolate,Math.random() < 0.2 ? 'cho2' : 'cho1',['X','cg','cho2','cho1'])
	this.putSymmetrical(board,[true,'cho1','cho2'],this.getRandomEvenInRange(config.chocolate[0],config.chocolate[1]),['X','cg','cho2','cho1'],3);

	//wrapped
	this.putWrapped(board,config);

	*/

	this.fillWithRandom(board,config);

	return lvl;

};

G.LevelGenerator.putBlockers = function(board,config,pickedBlockers) {


	//['concrete','ice','chain','dirt','infection']

	for (var i = 0; i < pickedBlockers.length; i++) {
		switch (pickedBlockers[i]) {
			case 'concrete':
				this.putSymmetrical(board,[false,'cn3','cn2','cn1'],this.getRandomEvenInRange(config.concrete[0],config.concrete[1]),['dirt3','dirt2','dirt1','cn3','cn2','cn1','infection'],2);
				break;
			case 'ice':
				this.putSymmetrical(board,'ice',this.getRandomEvenInRange(config.ice[0],config.ice[1]),['ice','dirt3','dirt2','dirt1','infection'],3);
				break;
			case 'chain':
				this.putWrapped(board,config);
				break;
			case 'dirt':
				this.putSymmetrical(board,['dirt3','dirt2','dirt1'],this.getRandomEvenInRange(config.dirt[0],config.dirt[1]),['ice','dirt3','dirt2','dirt1','cn3','cn2','cn1'],0);
				break;
			case 'infection':
				this.putSymmetrical(board,'infection',this.getRandomEvenInRange(config.infection[0],config.infection[1]),['infection','cn3','cn2','cn1','ice','W1','W2','W3','W4','W5','W6'],0);
				break;
		}
	}

};


G.LevelGenerator.pickBlockers = function(lvl,config) {

	var blockersAvailable = [];

	var allBlockers = ['concrete','ice','chain','dirt','infection'].forEach(function(blocker) {
		if (config[blocker][1] > 0) {
			blockersAvailable.push(blocker);
		}
	});

	Phaser.ArrayUtils.shuffle(blockersAvailable);

 	var picked = [];
 	var nrToPick = game.rnd.between(config.blockersTypes[0],config.blockersTypes[1]);

 	for (var i = 0; i < Math.min(blockersAvailable.length,nrToPick); i++) {
 		picked.push(blockersAvailable[i]);
 	}

 	return picked;

};


G.LevelGenerator.putWrapped = function(board,config) {

	var markList = [false];
	for (var i = 1; i <= config.typesOfCandy;i++) {
		markList.push('W'+i.toString());
	}
	this.putSymmetrical(board,markList,this.getRandomEvenInRange(config.chain[0],config.chain[1]),['infection','W1','W2','W3','W4','W5','W6']);

};

G.LevelGenerator.fillWithRandom = function(board,config) {

	var avoid = ['W1','W2','W3','W4','W5','W6','infection'];

	board.loop(function(elem,x,y) {

		if (!this.shouldAvoidCell(board,x,y,avoid)) {
			elem.unshift('r');
		}

	},this);

};

G.LevelGenerator.getRandomEven = function(maxNr) {

	var result = game.rnd.between(0,maxNr);
	if (result % 2 == 1) {
		if (result < maxNr) {
			result++;
		}else {
			result--;
		} 
	}

	return result;
};

G.LevelGenerator.getRandomEvenInRange = function(minNr,maxNr) {

	var result = game.rnd.between(minNr,maxNr);
	if (result % 2 == 1) {
		if (result < maxNr) {
			result++;
		}else {
			result--;
		} 
	}
	return result;

};



G.LevelGenerator.makeGoal = function(board,config,lvl,pickedBlockers) {

	var possibleGoals = [];

	for (var i = 1; i <= config.typesOfCandy; i++) {
		possibleGoals.push([
			i.toString(), Math.ceil(game.rnd.between(config.normReq[0],config.normReq[1])/5)*5
		]);
	}

	var lookUpMarks = {
		'concrete' : ['cn3','cn2','cn1'],
		'ice' : ['ice'],
		'chain' : ['W1','W2','W3','W4','W5','W6'],
		'dirt' : ['dirt3','dirt2','dirt1'],
		'infection' : ['infection']
	}

	for (var i = 0; i < pickedBlockers.length; i++) {
		possibleGoals.push([pickedBlockers[i],this.countOnBoard(board,lookUpMarks[pickedBlockers[i]])]);
	}

	var goalNr = game.rnd.between(config.goalRange[0],config.goalRange[1]);

	Phaser.ArrayUtils.shuffle(possibleGoals);

	return possibleGoals.splice(0,goalNr);


};


G.LevelGenerator.countEmptySpaces = function(board) {

	return this.countOnBoard(board,'X');

};

G.LevelGenerator.countOnBoard= function(board,lookFor) {

	var result = 0;

	if (!Array.isArray(lookFor)) lookFor = Array.prototype.slice.call(arguments).splice(1);

	for (var i = 0; i <lookFor.length;i++) {
		var currentLookFor = lookFor[i];
		board.loop(function(elem,x,y) {
			if (elem.indexOf(currentLookFor) !== -1) result++;
		});
	}

	return result;

};


//
// mark - string or array [keepSymetry, elems...]
// startFrom Y CELL - some blockers should not be placed on very top
//
G.LevelGenerator.putSymmetrical = function(board,mark,nrOfElements,avoid,startFrom) {

	startFrom = startFrom || 0;

	if (Array.isArray(mark)) {
		var markList = mark;
		var keepMarkSymmetry = markList.shift();
	}

	console.log("PUT SYMETRIC: "+mark+' x '+nrOfElements);

	if (nrOfElements == 0) return;

	var twoLines = Math.random() < 0.5;

	console.log(twoLines);

	var maxWidthIndex = Math.ceil(board.width*0.5);
	var maxHeightIndex = twoLines ? Math.ceil(board.height*0.5) : board.height;
	var pairs = [];
		
	var attempts = 0;

	while (nrOfElements > 0) {

		if (attempts++ == 400) return;

		if (markList && keepMarkSymmetry) mark = markList[Math.floor(Math.random()*markList.length)];

		pairs = [];

		var xx = Math.floor(Math.random()*maxWidthIndex);
		var yy = Math.floor(Math.random()*maxHeightIndex);
		var xxR = (board.width-1)-xx;
		var yyR = (board.height-1)-yy;


		if (!this.shouldAvoidCell(board,xx,yy,avoid) && nrOfElements > 0 && yy >= startFrom) {
			console.log("PUT: "+xx+'x'+yy);
			if (markList && !keepMarkSymmetry) mark = markList[Math.floor(Math.random()*markList.length)];
			board.data[xx][yy].push(mark);
			nrOfElements--;
			pairs.push(true);
			//remove extra element if it is in the middle (so there will be symetry)
		}


		if (!this.shouldAvoidCell(board,xxR,yy,avoid) && nrOfElements > 0 && yy >= startFrom) {
			console.log("PUT XR: "+xxR+'x'+yy);
			if (markList && !keepMarkSymmetry) mark = markList[Math.floor(Math.random()*markList.length)];
			board.data[xxR][yy].push(mark);
			nrOfElements--;
			pairs.push(true);
		}

		if (twoLines) {

			if (!this.shouldAvoidCell(board,xx,yyR,avoid) && nrOfElements > 0&& yyR >= startFrom) {
				if (markList && !keepMarkSymmetry) mark = markList[Math.floor(Math.random()*markList.length)];
				board.data[xx][yyR].push(mark);
				console.log("PUT YR: "+xx+'x'+yyR);
				nrOfElements--
				pairs.push(true);
			}

			if (!this.shouldAvoidCell(board,xxR,yyR,avoid) && nrOfElements > 0&& yyR >= startFrom) {
				if (markList && !keepMarkSymmetry) mark = markList[Math.floor(Math.random()*markList.length)];
				board.data[xxR][yyR].push(mark);
				console.log("PUT XR YR: "+xxR+'x'+yyR);
				nrOfElements--;
				pairs.push(true);

			}

		}

		if (pairs.length % 2 == 1) {
			nrOfElements--;
		}

	}	

};



G.LevelGenerator.shouldAvoidCell = function(board,x,y,avoid) {

	var cell = board.data[x][y];

	for (var i = 0; i < avoid.length; i++) {
		if (cell.indexOf(avoid[i]) !== -1) {
			return true;
		}

	}

	return false;

};

