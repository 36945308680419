/* eslint-disable no-use-before-define */
if (typeof G === 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getGameplayChestGiftRevealAnimation = function getGameplayChestGiftRevealAnimation() {
  const onComplete = new Phaser.Signal();
  return { animate, onComplete };

  function animate(game, target) {
    target.scale.setTo(0);
    target.angle = -10;
    target.y = -10;

    game.add.tween(target.scale)
      .to({ x: 1, y: 1 }, 600, Phaser.Easing.Bounce.Out, true);

    const moveTween = game.add.tween(target)
      .to({ y: -40 }, 400, Phaser.Easing.Sinusoidal.InOut)
      .to({ y: -35 }, 1100, Phaser.Easing.Sinusoidal.Out);
    moveTween.start();

    game.add.tween(target)
      .to({ angle: 10 }, 1500, Phaser.Easing.Sinusoidal.InOut, true)
      .onComplete.add(onComplete.dispatch, onComplete);
  }
};
