/* eslint-disable no-unused-vars */

import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

/**
 * Window for receiving life from a friend
 */
class Window_LifeReceived extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} msg_payload
   */
  constructor(parent, msg_payload) {
    super(parent);
    this._initLayout();
    this._initButtons();
  }

  /**
   * init window layout
   */
  _initLayout() {
    // background
    this.addBackground('popup_background_2');

    // title text
    this.receivedTxt = new G.Text(0, -272, OMT.language.getText('You received Lives!'), G.OMTsettings.global.window.smallTitle.textStyle, 0.5, 450);
    this.add(this.receivedTxt);

    // heart graphics
    this.bigHeart = G.makeImage(15, 30, 'bigheart', 0.5, this);
    this.amountTxt = new G.Text(0, 30, '+1', {
      style: 'window-white',
      fontSize: '120px',
      stroke: '#720e07',
      strokeThickness: 0,
    }, 0.5, 150);
    this.add(this.amountTxt);

    // hearts rewarded text
    this.youHaveTxt = new G.Text(0, -140, OMT.language.getText('You have +1 Hearts!'), {
      style: 'window-blue-2',
      fontSize: '50px',
    }, 0.5, 470);
    this.add(this.youHaveTxt);
  }

  /**
   * init window buttons
   */
  _initButtons() {
    const onClick = () => {
      // DDNA.transactionHelper.trackRewards(['life', 1], [], {
      //   transactionType: 'REWARD', tActionType: 'SOCIAL', tGameArea: 'MAP',
      // });
      G.saveState.addLife();
      this.closeWindow();
    };
    this.claimBtn = new OMT_UI_SquareButton(0, 245, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Claim'),
        textStyle: { style: 'font-white', fontSize: 45 },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });

    this.registerButtons(this.claimBtn);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.lifeReceived = Window_LifeReceived;
