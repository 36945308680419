let _instance = null; // singleton instance

const NA_TEXT = 'not available'; // default text for parameter not available

const REQUIRED_GL_TEXTURE_SIZE = 4096;

export const ORIENTATION = { // Orientation of the game
  vertical: 0,
  horizontal: 1,
};

/**
 * class for accessing system information
 */
export class OMT_SystemInfo {
  /**
   * return singleton instance
   * @returns {OMT_SystemInfo}
   */
  static getInstance() {
    if (!_instance) _instance = new OMT_SystemInfo();
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    // instance of https://github.com/faisalman/ua-parser-js
    // eslint-disable-next-line no-undef
    this._uaParser = new UAParser();
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl');

    this._systemParams = {
      gameVersion: G.BuildEnvironment.version,
      osName: this._uaParser.getOS().name || NA_TEXT,
      osVersion: this._uaParser.getOS().version || NA_TEXT,
      browserName: this._uaParser.getBrowser().name || NA_TEXT,
      browserVersion: this._uaParser.getBrowser().version || NA_TEXT,
      deviceModel: this._uaParser.getDevice().model || NA_TEXT,
      deviceVendor: this._uaParser.getDevice().vendor || NA_TEXT,
      browserEngineName: this._uaParser.getEngine().name || NA_TEXT,
      browserEngineVersion: this._uaParser.getEngine().version || NA_TEXT,
      isCookOMT: G.BuildEnvironment.isCookOMT ? 1 : 0,

      webGLInfo: {
        maxTextureSize: gl ? gl.getParameter(gl.MAX_TEXTURE_SIZE) : NA_TEXT,
        maxCombinedTexImageUnits: gl ? gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS) : NA_TEXT,
        maxRenderBufferSize: gl ? gl.getParameter(gl.MAX_RENDERBUFFER_SIZE) : NA_TEXT,
        maxVertexUniformVectors: gl ? gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS) : NA_TEXT,
        maxFragmentUniformVectors: gl ? gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS) : NA_TEXT,
      },
    };

    canvas.remove();
  }

  /**
   * get a reference to the system params object
   */
  get systemParams() {
    return this._systemParams;
  }

  /**
   * get the game version
   * @returns {string}
   */
  get gameVersion() {
    return this._systemParams.gameVersion;
  }

  /**
   * get the operating system name
   * @returns {string}
   */
  get osName() {
    return this._systemParams.osName;
  }

  /**
   * get the operating system version
   * @returns {string}
   */
  get osVersion() {
    return this._systemParams.osVersion;
  }

  /**
   * get the browser name
   * @returns {string}
   */
  get browserName() {
    return this._systemParams.browserName;
  }

  /**
   * get the browser version
   * @returns {string}
   */
  get browserVersion() {
    return this._systemParams.browserVersion;
  }

  /**
   * get the device model
   * @returns {string}
   */
  get deviceModel() {
    return this._systemParams.deviceModel;
  }

  /**
   * get the device vendor / manufacturer
   * @returns {string}
   */
  get deviceVendor() {
    return this._systemParams.deviceVendor;
  }

  /**
   * get WebGL info
   * @returns {Object}
   */
  get webGLInfo() {
    return this._systemParams.webGLInfo;
  }

  /**
   * Previously known as deviceIsDesktop, but it wasn't actually accurate
   * Now returns if device is using FB SDK 6.3+ for data collection purposes on startup
   * @returns {boolean}
   */
  get deviceIsUsingFBSixPointThreePlus() {
    return (FBInstant && FBInstant.getPlatform() === 'WEB') || !(this.deviceIsAndroid && this.deviceIsIOS);
  }

  /**
   * Returns true if desktop or false if not
   * @returns {boolean}
   */
  get deviceIsDesktop() {
    return game.device.desktop;
  }

  /**
   * true if the device is iOS
   * @returns {boolean}
   */
  get deviceIsIOS() {
    // UA parser doesn't seem to have iPadOS detection atm, reverted to checking FBInstant
    return FBInstant && FBInstant.getPlatform() === 'IOS';
  }

  /**
   * true if the device is Android
   * @returns {boolean}
   */
  get deviceIsAndroid() {
    const { osName } = this._systemParams;
    const isAndroid = osName.toLowerCase() === 'android';
    return isAndroid;
  }

  /**
   * true if the device is Android 5.x or lower
   * @returns {boolean}
   */
  get deviceIsAndroid5() {
    const { osName, osVersion } = this._systemParams;
    const isAndroid5 = osName.toLowerCase() === 'android' && parseInt(osVersion.split('.')[0]) <= 5;
    return isAndroid5;
  }

  /**
   * true if the device is Android 6.x
   * @returns {boolean}
   */
  get deviceIsAndroid6() {
    const { osName, osVersion } = this._systemParams;
    const isAndroid6 = osName.toLowerCase() === 'android' && parseInt(osVersion.split('.')[0]) === 6;
    return isAndroid6;
  }

  /**
   * Checks if this is a iOS device > then iOS 15.1. Those devices were getting poor WEB GL performance.
   * @returns {boolean}
   */
  get isCanvasPreferredIOS() {
    if (!this.deviceIsIOS) return false;
    try {
      const { osVersion } = this._systemParams;
      const [majorVersion = '15'] = osVersion.split('.');
      if (parseInt(majorVersion) >= 15) return true;
    } catch (error) {
      //
    }
    return false;
  }

  /**
   * true if browser is IE 11 or lower
   * @returns {boolean}
   */
  get browserIsIE() {
    const { browserName } = this._systemParams;
    return browserName.toUpperCase() === 'IE';
  }

  /**
   * check for incompatible platforms
   * @returns {boolean}
   */
  get isCompatiblePlatform() {
    return !this.browserIsIE;
  }

  /**
   * check if the current devices should be using Web-GL
   * @returns {boolean}
   */
  get isSupportedWebGLDevice() {
    if (this.deviceIsAndroid5 || this.deviceIsAndroid6 || this.isCanvasPreferredIOS) return false;
    // create a canvas to extract GL support
    try {
      const { maxTextureSize } = this._systemParams.webGLInfo;
      return typeof maxTextureSize === 'number' && maxTextureSize >= REQUIRED_GL_TEXTURE_SIZE;
    } catch (error) {
      // could not create GL context.
    }
    return false;
  }

  /**
   * Returns the orientation of the game
   * @returns {number}
   */
  get orientation() {
    if (game) {
      // Galaxy Fold specific check
      const isFold = /SM-F916B/i.test(navigator.userAgent);
      if (isFold) {
        return ORIENTATION.vertical;
      }

      if (game.width / game.height > 1) {
        return ORIENTATION.horizontal;
      }
    }
    return ORIENTATION.vertical;
  }

  /**
   * Returns the orientation as a string. Used for accessing JSON objects
   * @returns {string}
   */
  get orientationKey() {
    return this.orientation === ORIENTATION.vertical ? 'vertical' : 'horizontal';
  }

  /**
   * Returns a human-readable device identifier based on the user agent
   * @returns {string} the device identifier, or "not avaiable"
   */
  get friendlyDeviceIdentifier() {
    let name = '';

    // Try to get vendor and model names
    if (this.deviceVendor !== NA_TEXT) {
      name = this.deviceVendor;
    }

    if (this.deviceModel !== NA_TEXT) {
      if (name !== '') name += ' ';
      name += `${this.deviceModel}`;
    }

    // If there are no vendor and model names, try to get the OS name
    if (name === '') {
      if (this.osName !== NA_TEXT) {
        name = this.osName;

        if (this.osVersion !== NA_TEXT) {
          name += ` ${this.osVersion}`;
        }
      }
    }

    return name === '' ? NA_TEXT : name;
  }
}

// this will get overriden later by OMT_Init. This was done for earlier access
if (!window.OMT) window.OMT = {};
OMT.systemInfo = OMT_SystemInfo.getInstance();
