/* eslint-disable prefer-object-spread */
/* eslint-disable no-new */

import { omtServicesStartGame, sortIntoABCGroups } from '../Services/OMT/OMT_Init';
import { FadeLayer } from '../Elements/FadeLayer';
import { InitFlowStatusTracker } from '../InitFlowStatusTracker';
import { OMT_AssetLoader } from '../Services/OMT/OMT_AssetLoader';
// import { OMT_PerformanceMonitor } from '../Services/OMT/OMT_PerformanceMonitor';
import { DEBUG_MODES, OMT_Debug } from '../Debug/OMT_Debug';
import FortuneCookieDataManager from '../Services/OMT/dataTracking/fortuneCookie/FortuneCookieDataManager';
import TargetedOfferDataManager, { TARGETED_OFFER_IDS } from '../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { RUNTIME_SPRITESHEET_IDS } from '@omt-components/Imaging/Spritesheets/RuntimeSpritesheetManager';
import CandyDataManager from '@omt-game-board/Board/Candy/CandyDataManager';
import WallClockTimer from '../Utils/Timers/WallClockTimer';
/**
 * class for handling preloading and game initialization
 */
export class Preloader {
  /**
   * Phaser state init
   */
  init() {
    this._updateLoadingProgress(0);

    // set debug methods for IAP state
    G._debugChangeToIAP = () => {
      G.IAP = true; this._updateConfigsToIAP();
      console.log("DEBUG: Your have switched to 'IAP' settings");
    };
    G._debugChangeToNONIAP = () => {
      G.IAP = false; this._updateConfigToNONIAP();
      console.log("DEBUG: Your have switched to 'NO IAP' settings");
    };
  }

  /**
   * begin preloading
   */
  preload() {
    this.load.onFileComplete.add((progress) => {
      this._updateLoadingProgress(progress);
    });

    OMT_AssetLoader.getInstance().loadPrimaryAssets(G.soundManager, () => {
      this._onAssetsLoaded();
    });
  }

  /**
   * update loading progress in FB Instant overlay
   * @param {number} progress
   */
  _updateLoadingProgress(progress) {
    const adjustedProgress = progress < 5 ? 5 : Math.round(progress);
    OMT.platformFunctions.setLoadingProgress(adjustedProgress);
  }

  /**
   * called on all assets loaded
   */
  async _onAssetsLoaded() {
    // OMT.platformTracking.logFTUEvent('FTUAssetLoadingDone', 'RTUAssetLoadingDone');
    this.load.onFileComplete.removeAll();
    G.addTextStyles();
    InitFlowStatusTracker.getInstance().setAssetsLoaded(); // flag assets loaded
    this._checkIfReadyToInitGame();
  }

  /**
   * check if everything is loaded / initialized for launching the game
   */
  _checkIfReadyToInitGame() {
    // check if we are ready to continue with the initializing the game
    let checkInitStateIntervalId = -1;
    const checkInitState = () => {
      if (InitFlowStatusTracker.getInstance().isReadyToStartupGame()) {
        if (checkInitStateIntervalId !== -1) clearInterval(checkInitStateIntervalId);
        // continue on once init conditions met
        game.resizeGame();
        this._callLoadingCompleteTrackingEvents();

        if (OMT.systemInfo.isCompatiblePlatform) this._initGameDataAndRunGame();
        window.addEventListener('orientationchange', this.pauseUnpauseGame);

        FBInstant.setPauseHandler(() => {
          this._pauseGame();
        });

        FBInstant.setUnpauseHandler(() => {
          if (document.hasFocus()) {
            this._resumeGame();
            return;
          }

          const iID = setInterval(() => {
            if (document.hasFocus()) {
              this._resumeGame();
              clearInterval(iID);
            }
          }, 200);
        });

        return true;
      }
      return false;
    };
    // check init state now, if not ready keep checking
    if (!checkInitState()) checkInitStateIntervalId = setInterval(checkInitState, 10);
  }

  _pauseGame() {
    WallClockTimer.stop();
    G.soundManager.pause(true); // pause sound during ads
    // this.adOverlay = new G.AdLoadOverlay();
  }

  /**
   * resume game / sounds context if lost
   */
  _resumeGame() {
    // if (this.adOverlay) this.adOverlay.destroy();
    WallClockTimer.start();
    G.soundManager.resume(true); // resume sound after ads

    setTimeout(() => {
      try {
        if (game.sound.context.state !== 'running') {
          game.sound.context.resume()
            .then(() => {
              // console.log('ac resumed');
            })
            .catch(() => {
              // console.log('ac not resumed');
            });
        }
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }

  pauseUnpauseGame() {
    if (window.orientation === 0) {
      WallClockTimer.start();
      G.soundManager.resume(true);

      setTimeout(() => {
        try {
          if (game.sound.context.state !== 'running') {
            game.sound.context.resume()
              .then(() => {
                // console.log('ac resumed');
              })
              .catch(() => {
                // console.log('ac not resumed');
              });
          }
        } catch (e) {
          console.log(e);
        }
      }, 1000);
    } else {
      WallClockTimer.stop();
      G.soundManager.pause(true);
    }
  }

  /**
   * update loop
   */
  update() {
    // not doing anything with update atm
    // OMT_PerformanceMonitor.getInstance().update();
  }

  /**
   * tracking events to fire on asset loading complete. NOTE: DDNA is not ready at this point
   */
  _callLoadingCompleteTrackingEvents() {
    OMT.platformTracking.logFTUEvent('FTULoadingComplete', 'RTULoadingComplete');
    OMT.platformTracking.logFTUEvent('FTUX_LoadingComplete', null);
  }

  /**
   * initialize game data and launch the game now that loading is complete
   */
  async _initGameDataAndRunGame() {
    // set fade overlay for transition
    G.fadeLayer = new FadeLayer();
    // fortune cookie stat tracker
    this.fortuneCookieDataManager = FortuneCookieDataManager.getInstance();
    // finalize OMT service initialization
    await omtServicesStartGame();
    // load language file(s)
    await this._loadLanguageJSON();
    // intialize G.saveState data
    await G.saveState.initLoadData();
    // Initialize ad system. This was moved until after initializing the saveState for new preloading rules.
    OMT.ads.init();
    // init the x-promo module
    // OMT.crossPromo.init();

    if (G.featureUnlock.preventMysteryGift) {
      sortIntoABCGroups();
    }

    // init game configs and related helpers
    G.Helpers.levelDataMgr.init(G.json.levels);
    G.Helpers.gatesMgr.init(G.json['configs/gates']);
    G.Helpers.dailyRewardMgr.init(G.OMTsettings.elements.dailyRewardCalendar);
    CandyDataManager.init(G.json.candies);
    G.json.settingsNOIAP = G.json.settings;
    G.json['configs/spinWheelsNOIAP'] = G.json['configs/spinWheels'];

    // Post json loading initialization
    // But theres nothing right now

    // sync total stars, we can probably remove this once more data is propagated
    // if (OMT.connect.isConnected()) await OMT.leaderboards.postScoreToLeaderboard('totalStars', 'default', G.saveState.getAllStars());

    await OMT.notifications.init(); // Init the message scheduler

    // set the IAP / settings state
    if (G.IAP) this._updateConfigsToIAP();
    else this._updateConfigToNONIAP();

    /* await this._initTrackingSystems(); // init non-FBA tracking systems */
    // this._callGameStartTrackingEvents(); // tracking calls for entryPoint / gameStart
    OMT.milestoneTracking.init(); // Init the milestone tracker

    // Check session milestone
    OMT.milestoneTracking.incrementSessions();
    OMT.milestoneTracking.checkMilestoneCompletion();

    this._loadSecondaryAssets(); // start pre-loading secondary assets

    await this._handlePayload(); // Payload check happens here

    this._checkDebugMode(); // Check debug mode and activate debug overlay

    this._switchToFirstGameScene(); // switch the user to World or Game state depending on
    

    // check gift as the game starts
    if (FBInstant.getSupportedAPIs().indexOf("payments.purchaseAsync") !== -1) {
      FBInstant.setIAPGiftCallback((gift) => {
        gift = gift.content;
        if (gift.boosters) {
          for (let i = 0; i < gift.boosters.length; i++) {
            G.saveState.changeBoosterAmount(i, gift.boosters[i]);
          }
        }
  
        if (gift.infiniteLives) {  //* 60, 30 or 15???
          if (gift.infiniteLives > 0) {
            G.saveState.addUnlimitedLivesTimeMin(gift.infiniteLives, false);
          }
        }
        if (gift.coins) {
          G.saveState.changeCoins(gift.coins, false);
        }
        if (gift.level) {
          if (gift.level > G.saveState.getLastPassedLevelNr() + 1) {
            G.saveState.data.levels = [];
            for (let i = 0; i < gift.level; i++) {
              G.saveState.data.levels.push(3);
            }
            const levelData = {
              lvlIndex: gift.level - 1,
              debugMode: false,
            };
            game.state.start('Game', true, false, levelData);
          }
        }
  
        if (window.flushUserData) {
          window.flushUserData();
        }
  
      });
    }

  }

  /**
   * init DDNA + transaction tracker
   */
  async _initTrackingSystems() {
    // initialize transaction tracker
    await OMT.transactionTracking.init();

    // initialize DDNA tracking and restore incomplete level rewards
    /* await DDNA.tracking.init(G.OMTsettings.DDNA, jsInitTime);
    DDNA.tracking.getDataCapture().setLoadingTime();
    DDNA.transactionHelper.restoreQueuedLevelCoinRewards(); */
    // this.fortuneCookieDataManager.initDataCapture(); // init DDNA fortune cookie tracking
    G.saveState.initLoyaltyData(); // init Loyalty data manager
  }

  /**
   * call startup tracking events
   */
  _callGameStartTrackingEvents() {
    const entryPointParams = DDNA.entryPointDataHelper.getEntryPointTrackingParams();
    // track bot messages on FBA
    const { entryPointData } = OMT.envData;
    if (entryPointData && entryPointData.entryPoint === 'botMessage') {
      const [type, version] = entryPointData.messageId.split('###');
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.BotMessage, 1, { type, version });
    }

    // device identifiers and properties
    // const deviceParams = {
    //   deviceName: OMT.systemInfo.friendlyDeviceIdentifier,
    //   devicePixelRatio: window.devicePixelRatio,
    // };

    // if first-time user trigger the new player event
    // if (G.firstTime) DDNA.tracking.collectEvent('newPlayer', Object.assign({}, entryPointParams, deviceParams), 0, null, false);

    // append targeted offer seen if we are going to show one on startup
    let showTargetedOffer;
    if (OMT.feature.isTokenEventOn(true, false) && G.OMTsettings.tokenEvent.popupOfferLayout.showTargetOffer) { // override for token event
      // disable default offer
      TargetedOfferDataManager.getInstance().disableOfferById(TARGETED_OFFER_IDS.PAYER_LAST_X_DAYS);
      // show alternate event offer
      const isPayer = TargetedOfferDataManager.getInstance().isPayer();
      const eventOfferID = isPayer ? TARGETED_OFFER_IDS.PAYER_TOKENEVENT : TARGETED_OFFER_IDS.NON_PAYER_TOKENEVENT;
      showTargetedOffer = TargetedOfferDataManager.getInstance().setStartupOfferState(eventOfferID);
      if (showTargetedOffer) entryPointParams.targetedOfferSeen = eventOfferID;
    } else { // normal flow
      showTargetedOffer = TargetedOfferDataManager.getInstance().setStartupOfferState(TARGETED_OFFER_IDS.PAYER_LAST_X_DAYS);
      if (showTargetedOffer) entryPointParams.targetedOfferSeen = TARGETED_OFFER_IDS.PAYER_LAST_X_DAYS;
    }

    // Track player IDs from other OMT games
    // const omtPlayerIDs = G.saveState.getOMTPlayerIDs();

    // Convert omtPlayerIDs to custom string format: "game-slug-1:id-1,game-slug-2,id-2,..."
    // const omtPlayerIDsPairs = Object.entries(omtPlayerIDs).map(([slug, id]) => `${slug}:${id}`);
    // const omtPlayerIDsParams = {
    //   omtPlayerIDs: omtPlayerIDsPairs.join(','),
    // };

    /* DDNA.tracking.collectEvent('gameStarted', Object.assign({}, entryPointParams, deviceParams, omtPlayerIDsParams), 0);
    DDNA.tracking.ftuxEvent(2, 'loadingDone');
    DDNA.tracking.checkClientDeviceStatus(); // check if the client is using a new device */

    // Add setFirstInteractionTime Signal to SignalBox as a one-time permanent event
    G.sb('setFirstInteractionTime').addOncePermanent(() => {
      // This is a new event that is added to DDNA
      /* DDNA.tracking.collectEvent('gameLoaded', Object.assign({}, entryPointParams), 0); */
    });

    // Facebook analytics tracking
    OMT.platformTracking.logSessionStartEvent();
    OMT.platformTracking.logSoundStatusEvent();
    OMT.platformTracking.logMarketingCampaignEvent();
  }

  /**
   * load the language JSON
   */
  async _loadLanguageJSON() {
    await OMT.language.loadPrimaryLanguage().catch(() => {
      console.error('ERROR: Could not load primary language json');
    });
  }

  /**
   * update game configs to IAP settings
   */
  _updateConfigsToIAP() {
    G.json.settings = G.json.settingsIAP;
    G.json['configs/spinWheels'] = G.json['configs/spinWheelsIAP'];
    G.gift.init(G.json['configs/giftsIAP']);
    G.Helpers.dailyRewardMgr.init(G.OMTsettings.elements.dailyRewardCalendar);
    G.saveState.refillLockedOrInvalidBoosters(G.json.settings.boostersOnStart, 'update configs to IAP');
  }

  /**
   * update game configs to NO IAP settings
   */
  _updateConfigToNONIAP() {
    G.json.settings = G.json.settingsNOIAP;
    G.json['configs/spinWheels'] = G.json['configs/spinWheelsNOIAP'];
    G.gift.init(G.json['configs/gifts']);
    G.Helpers.dailyRewardMgr.init(G.OMTsettings.elements.dailyRewardCalendar);
    G.saveState.refillLockedOrInvalidBoosters(G.json.settings.boostersOnStart, 'update configs to NON-IAP');
  }

  /**
   * switch users to the 'Game' or 'World' depending if they are new / returning users
   */
  async _switchToFirstGameScene() {
    if (!G.music.isPlaying) {
      // Audiosprites don't allow you to set their initial volume in play()
      G.music.play('', 0, 1, true);
      G.music.volume = 0.75;
    }

    // attempt to detect a tournament thread / share
    const tournamentContextId = OMT.platformTournaments.detectTournamentContextId();
    if (tournamentContextId !== null) await OMT.platformTournaments.updateLeaderboardContext(tournamentContextId);

    // check if we want to show the bot opt-in prompt and wait if so
    await this._checkBotOptIn();

    // if a starup scene was passed in to init we shortcut there
    if (window.startupState) {
      const { state, data } = window.startupState;
      if (data) G.sb('onStateChange').dispatch(state, data);
      else G.sb('onStateChange').dispatch(state);
      return;
    }

    // Schedule treadure hunt start bot message
    if (OMT.feature.isTreasureHuntOn(true, false, false, true)) {
      await G.saveState.treasureHuntManager.scheduleStartBotMessage();
    }

    const startInGameState = G.firstTime === true;
    const isRestoringProgress = OMT.envData.isRestoreProgressPayload || OMT.envData.isStateChangePayload;
    // const crossPromoRestoreProgress = Boolean(G.saveState.sessionData.crossPromoSkipTutorial);
    // if (startInGameState && !isRestoringProgress && !crossPromoRestoreProgress) { // first time user
    if (startInGameState && !isRestoringProgress) { // first time user
      if (tournamentContextId != null) { // tournament FTUE
        const levelData = OMT.platformTournaments.getTournamentLevelData(); G.lvlNr = '--';
        G.sb('onStateChange').dispatch('Game', {
          tournamentLvl: levelData,
          prevHighScore: 0,
        });
      } else { // normal FTUE
        G.lvlNr = 0;
        G.sb('onStateChange').dispatch('Game', { lvlIndex: 0 });
      }
    } else { // returning user
      G.sb('onStateChange').dispatch('World');
    }
  }

  /**
   * load the secondary assets not required for startup.
   * If you want to await these assets elsewhere use await OMT_AssetLoader.getInstance().waitOnSecondaryImages
   */
  async _loadSecondaryAssets() {
    const assetLoader = OMT_AssetLoader.getInstance();
    // const { payload } = OMT.envData;

    // if (payload && payload.fortuneCookie) { // Entry point is from fortune cookie
    //   await assetLoader.loadSecondaryImages('fortuneCookie', true, RUNTIME_SPRITESHEET_IDS.FORTUNECOOKIE);
    // }

    // if (G.saveState.friendshipChestDataManager.getInvitedData() && !G.saveState.friendshipChestDataManager.getPromoInitial()) { // Is this FTU entry flow?
    //   Load FTU assets first
    //   await assetLoader.loadSecondaryImages('friendshipChest/ftu', true, RUNTIME_SPRITESHEET_IDS.FRIENDSHIPCHEST);
    // }
    await assetLoader.loadSecondaryImages('fortuneCookie', true, RUNTIME_SPRITESHEET_IDS.FORTUNECOOKIE);
    await assetLoader.loadSecondaryImages('shop/ui', true, RUNTIME_SPRITESHEET_IDS.SHOP);
    await assetLoader.loadSecondaryImages('shop/nine-slices', true);
    await assetLoader.loadSecondaryImages('shop/deal-graphics', true, RUNTIME_SPRITESHEET_IDS.SHOP);
    await assetLoader.loadSecondaryImages('shop/timed-iap', true, RUNTIME_SPRITESHEET_IDS.TIMEDIAP);

    // if (!assetLoader.areSecondaryImagesLoaded(['friendshipChest/ftu'])) { // If not FTU entry flow, load as normal
    //   await assetLoader.loadSecondaryImages('friendshipChest/ftu', true, RUNTIME_SPRITESHEET_IDS.FRIENDSHIPCHEST);
    // }
    // await assetLoader.loadSecondaryImages('friendshipChest/ui', true, RUNTIME_SPRITESHEET_IDS.FRIENDSHIPCHEST);
    // await assetLoader.loadSecondaryImages('friendshipChest/postcards', true, RUNTIME_SPRITESHEET_IDS.FRIENDSHIPCHEST);

    if (OMT.feature.getFortuneCookieEvent(true) && !assetLoader.areSecondaryImagesLoaded(['fortuneCookie'])) { // Otherwise
      await assetLoader.loadSecondaryImages('fortuneCookie', true, RUNTIME_SPRITESHEET_IDS.FORTUNECOOKIE);
    }

    if (OMT.feature.isTreasureHuntOn(false, false, true)) {
      await assetLoader.loadSecondaryImages('treasureHunt', true, RUNTIME_SPRITESHEET_IDS.TREASUREHUNT);
    }
  }

  /**
   * check bot optin on game start
   */
  async _checkBotOptIn() {
    if (OMT.feature.getAskForBotOptInAtLoad() && !OMT.feature.getFTUBotOptInAtTournament()) {
      // await OMT.bots.checkStateAndAttemptSubcribe();
    }
    // await OMT.bots.detectBotOptInStateChange();
  }

  /**
   * check the payload. mostly just for tracking purposes at this point.
   */
  async _handlePayload() {
    const { payload } = OMT.envData;
    const { entryPoint } = OMT.envData;

    console.log('ENTRY POINT = ', JSON.stringify(entryPoint));
    console.log('PAYLOAD = ', JSON.stringify(payload));

    if (!payload) return; // exit if no payload to check

    // this should still be only used for things related to the WorldMap
    G.WorldMapAppearFlow.payloadChecker.checkPayloadAtPreloader(payload);

    // progress is being restored so we dont want to boot as a first time user
    if (payload.additions && payload.additions.restoreProgression) G.restoreProgress = true;

    // append OMT game player ids to save data if passed from x-promo
    if (payload.omtPlayerIDs) G.saveState.appendOMTPlayerIDs(payload.omtPlayerIDs);
  }

  /**
   * Checks debug mode in save state and activates debug overlay
   */
  _checkDebugMode() {
    if (!G.BuildEnvironment.production && G.saveState.debugMode === DEBUG_MODES.DISABLED) {
      console.log('Warning: Game in dev env with no default debug overlay active, defaulting to debug overlay 1');
      OMT_Debug.setDebugMode(1);
    } else {
      OMT_Debug.setDebugMode(G.saveState.debugMode); // apply the debug mode set by G.saveState.setDebugMode();
    }
  }
}
