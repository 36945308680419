import TutorialGrid from '../BoardAnim/G.TutorialGrid';
import TutorialGroup from '../BoardAnim/TutorialGroup';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';
/**
 * Waffle Jelly tutorial explained (???) by Sandra!
 * @author ???
 */
class WaffleLayersTutorial extends TutorialGroup {
  /**
   * Remember that this module/tutorial thing is just a Phaser Group
   * with things inside. It can display anything really
   */
  constructor() {
    super(game, null);

    this.tileSize = 90; // The size of the tiles on the mini-fake board

    this.pageOneContainer = new Phaser.Group(game, this);
    this.pageTwoContainer = new Phaser.Group(game, this);

    // shows the progression of the dirt layers
    this.diagram = new Phaser.Group(game, this.pageOneContainer);

    // a mini grid to help with positioning
    this.gridBg = new TutorialGrid();

    this.gems = new TutorialGrid();

    this.dirt = new TutorialGrid();

    // gems that aren't on the grid but drop in after a match is made
    this.gemsToDropIn = new TutorialGrid();

    // for when we need to emit particles when the dirt breaks
    this.emitter = new FxParticle();

    this.tutHand = G.makeImage(0, 0, 'tut_hand', 0);

    // The description at the top
    this.description = new G.Text(0, -150, ' ', {
      style: 'font-blue',
      fontSize: 35,
      lineSpacing: -15,
    }, 0.5, 400, 200, true, 'center');

    // we configure our own continue button because we need to do stuff when its clicked.
    this.continueButton = new OMT_UI_SquareButton(0, 270, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onContinueClick.bind(this),
        },
      },
    });
    this.addChild(this.continueButton);

    // #region global setup
    this.add(this.description);
    this.add(this.continueButton);
    this.currentPage = 1;
    // #endregion

    // #region page one setup
    const dirt3 = G.makeImage(0, 0, 'dirt_3', 0, this.diagram);
    const dirt2 = G.makeImage(0, 0, 'dirt_2', 0, this.diagram);
    const dirt1 = G.makeImage(0, 0, 'dirt_1', 0, this.diagram);

    const gem1 = G.makeImage(0, 0, 'candy_4', 0, this.diagram);
    const gem2 = G.makeImage(0, 0, 'candy_4', 0, this.diagram);
    const gem3 = G.makeImage(0, 0, 'candy_4', 0, this.diagram);

    const arrow1 = G.makeImage(0, 0, 'arrow_right', 0, this.diagram);
    const arrow2 = G.makeImage(0, 0, 'arrow_right', 0, this.diagram);

    this.tutHand.alpha = 0; // we dont use the hand in this step, so just hiding it for now

    // the arrow asset is quite small
    arrow1.scale.set(2);
    arrow2.scale.set(2);

    // centering the arrows with the gems
    arrow1.y = gem1.getBounds().height / 2 - arrow1.getBounds().height; // not dividing arrow height by 2 because we just scaled arrow by 2
    arrow2.y = gem1.getBounds().height / 2 - arrow2.getBounds().height;

    const padding = 20; // padding between elements declared above

    // throwing all this stuff into an array so we can use a for loop to position it all
    const elements = [gem1, arrow1, gem2, arrow2, gem3];

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (i > 0) {
        element.x = elements[i - 1].x + elements[i - 1].getLocalBounds().width * elements[i - 1].scale.x + padding;
      }
    }

    dirt1.position.copyFrom(gem1.position);
    dirt2.position.copyFrom(gem2.position);
    dirt3.position.copyFrom(gem3.position);

    this.pageOneContainer.add(this.diagram);

    // centering the group horizontally
    this.diagram.x -= this.diagram.getLocalBounds().width / 2;

    // tweens
    const duration = 500;
    const arrowDistanceToTravel = 10;

    arrow1.x -= arrowDistanceToTravel / 2;
    this.game.add.tween(arrow1)
      .to({ x: arrow1.x + arrowDistanceToTravel }, duration, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

    arrow2.x -= arrowDistanceToTravel / 2;
    this.game.add.tween(arrow2)
      .to({ x: arrow2.x + arrowDistanceToTravel }, duration, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    // #endregion

    // #region page two setup

    this.pageTwoContainer.add(this.gridBg);
    this.pageTwoContainer.add(this.dirt);
    this.pageTwoContainer.add(this.gems);
    this.pageTwoContainer.add(this.gemsToDropIn);
    this.pageTwoContainer.add(this.emitter);
    this.pageTwoContainer.add(this.tutHand);

    this.gridBg.init(4, 3, this.tileSize, 'tut_tile', 1.2);

    this.pageTwoContainer.y += 60;
    // #endregion

    this.pageOneContainer.alpha = 0;
    this.pageTwoContainer.alpha = 0;

    this.displayPageOne();
  }

  async displayPageOne() {
    this.description.setText(OMT.language.getText('Some %gems% have multiple layers of %dirt% underneath them.'));
    await this.wrapTween(this.pageOneContainer, { alpha: 1 }, 500, Phaser.Easing.Sinusoidal.In);
  }

  async displayPageTwo() {
    this.description.setText(OMT.language.getText('Match %gems% on top of %dirt% to crush the different layers!'));

    // #region initializing grids
    const dirtData = [0, 0, 0, 0, 3, 2, 1, 0, 0, 0, 0, 0]
      .map((num) => (num === 0 ? null : `dirt_${num}`));
    this.dirt.init(4, 3, this.tileSize, dirtData, 1.2);

    const gemsData = [3, 5, 4, 2, 4, 4, 5, 1, 5, 3, 1, 3]
      .map((num) => `candy_${num}`);
    this.gems.init(4, 3, this.tileSize, gemsData, 1.2);

    const gemsToDropInData = [4, 2, 4]
      .map((num) => `candy_${num}`);
    this.gemsToDropIn.init(3, 1, this.tileSize, gemsToDropInData, 1.2);

    this.gemsToDropIn.pivot.copyFrom(this.gems.pivot);
    this.gemsToDropIn.position.copyFrom(this.gems.getPxPos(0, -1));
    this.gemsToDropIn.alpha = 0;

    // #endregion

    // we want the hand to tween in, not be visible right away
    this.tutHand.alpha = 0;
    this.tutHand.scale.set(1);
    this.tutHand.pivot.set(0.5);

    // tweening in the whole container
    await this.wrapTween(this.pageTwoContainer, { alpha: 1 }, 300, Phaser.Easing.Sinusoidal.In);

    const gem1 = this.gems.getSpriteByCell(2, 0);
    const gem2 = this.gems.getSpriteByCell(2, 1);

    const gem1Pos = this.gems.getPxPos(2, 0);
    const gem2Pos = this.gems.getPxPos(2, 1);

    // here, we're swapping the sprites internally so when we later try to reference them, they're in the right spots in the data.
    this.gems.swapSprites(2, 0, 2, 1);

    this.tutHand.x = gem1Pos.x - this.gems.pivot.x;
    this.tutHand.y = gem1Pos.y - this.gems.pivot.y;

    await this.wait(100);

    this.wrapTween(this.tutHand, { alpha: 1 }, 100);

    // moving the hand to the swap position
    this.wrapTween(this.tutHand, { x: gem2Pos.x - this.gems.pivot.x, y: gem2Pos.y - this.gems.pivot.y }, 600, Phaser.Easing.Sinusoidal.InOut);
    this.wrapTween(this.tutHand.scale, { x: 0.8, y: 0.8 }, 200, Phaser.Easing.Sinusoidal.InOut);

    // swapping the two gems
    this.wrapTween(gem1, gem2Pos, 600, Phaser.Easing.Sinusoidal.InOut);
    this.wrapTween(gem2, gem1Pos, 600, Phaser.Easing.Sinusoidal.InOut);

    // scaling the gem to accentuate it as it moves
    await this.wrapTween(gem1.scale, { x: 1.4, y: 1.4 }, 300);
    await this.wrapTween(gem1.scale, { x: 1, y: 1 }, 300);

    // we aren't moving the pieces any more, so lets remove the hand.
    this.wrapTween(this.tutHand, { alpha: 0 }, 200);

    // this is also to make sure the emitter emits in the correct position
    this.emitter.pivot.copyFrom(this.gems.pivot);

    // because we want the animation to go from right to left, we iterate backwards.
    // eslint apparently doesnt like this, so we turn it off
    // eslint-disable-next-line for-direction
    for (let i = 2; i >= 0; i--) {
      const dirtPos = this.gems.getPxPos(i, 1);
      this.emitter.burstDirtAnim(dirtPos.x, dirtPos.y);

      const dirt = this.dirt.getSpriteByCell(i, 1);

      // removing one layer of dirt
      // this could be done in a simpler way, but because theres so few places i figured itd be more readable
      // to just do it manually.
      if (dirt) {
        if (dirt.frameName === 'dirt_1') {
          dirt.alpha = 0;
        } else if (dirt.frameName === 'dirt_2') {
          dirt.frameName = 'dirt_1';
        } else if (dirt.frameName === 'dirt_3') {
          dirt.frameName = 'dirt_2';
        }
      }

      // removing the sprite at this position because its been matched.
      const gem = this.gems.getSpriteByCell(i, 1);
      if (gem) {
        gem.alpha = 0;
      }

      // we need there to be a small delay between explosions, so the animation actually moves
      // and doesnt just happen all at once.
      // eslint-disable-next-line no-await-in-loop
      await this.wait(100);
    }

    await this.wait(100);

    // grabbing the gems to drop down.
    const dropGem1 = this.gems.getSpriteByCell(0, 0);
    const dropGem2 = this.gems.getSpriteByCell(1, 0);
    const dropGem3 = this.gems.getSpriteByCell(2, 0);

    // we put this into an array and iterate over it just so we dont have to do the same command three times.
    const gemsToDrop = [dropGem1, dropGem2, dropGem3];
    for (let i = 0; i < gemsToDrop.length; i++) {
      const gem = gemsToDrop[i];
      // this.wrapTween(gem, { y: gem.y + this.tileSize }, 300, Phaser.Easing.Back.Out);
    }

    this.wrapTween(this.gemsToDropIn, { alpha: 1 }, 500);
    // we drop in our three-gem grid from above.
    await this.wrapTween(this.gemsToDropIn, { y: 0 }, 300, Phaser.Easing.Back.Out);

    // wait a sec so the player can see the result.
    await this.wait(1000);

    // tween out the whole container
    await this.wrapTween(this.pageTwoContainer, { alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out);

    this.stopAllActions();
    this.displayPageTwo();
  }

  async onContinueClick() {
    this.stopAllActions(); // Stop all tweens
    if (this.currentPage === 1) { // If page one, go to page 2
      // prevent spam clicking
      this.continueButton.active = false;

      // tween out the first page container
      await this.wrapTween(this.pageOneContainer, { alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out); // tweens away after waiting 2000ms

      this.continueButton.active = true;

      this.displayPageTwo();
      this.currentPage++;
    } else { // Otherwise close
      this.signals.onContinueClick.dispatch();
      this.destroy();
    }
  }
}

// create global references
G.WaffleLayersTutorial = WaffleLayersTutorial;
