if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getTickBtnEnterAnimation = function getTickBtnEnterAnimation() {
  return { animate: animate };

  function animate(game, target) {
    target.scale.setTo(0);
    game.add.tween(target.scale)
      .to({x: 1, y: 1}, 300, Phaser.Easing.Cubic.Out, true, 400);
  };
}
