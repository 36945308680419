/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import { Shop_CoinBar } from './Shop_CoinBar';
import { RMWHEEL_EPS, RMWHEEL_MODES } from '../../SpinningWheels/RealMoneyWheel/rmWheelEnums';
import AnnuityManager from '../../../Services/OMT/dataTracking/annuityManager/AnnuityManager';

/**
 * @typedef {object} Layout_Data
 * @property {Array<String>} simplified
 * @property {Array<string>} simplifiedSpecDeal
 * @property {Array<String>} specialDeals
 * @property {Array<string>} discount
 * @property {Array<string>} freeGift
 * @property {Array<string>} specDealBold
 * @property {Array<string>} weekend
 * @property {Array<string>} annuity
 */

export const SHOP_EVENT_KEYS = {
  POSTCARD: 'POSTCARD',
  SPECIALDEALS: 'SPECIALDEAL',
  TOKEN_EVENT: 'TOKEN_EVENT',
  WEEKENDDEAL: 'WEEKENDDEAL',
};

export const SHOP_DEAL_TYPES = {
  FREE: 'free',
  FIRST: 'first',
  SPECIAL: 'special',
  WEEKEND: 'weekend',
  ANNUITY: 'annuity',
};

export const REG_DEAL_PRIORITY = { // The order regular packs are shown in the shop. Used for organizing
  coins: 0,
  inflives: 1,
  boosters: 2,
};

export default class ShopUtils extends Phaser.Group {
  /**
   * filters all items from the catalog matching the current tier
   * @param {Array.<Object>} catalog
   * @returns {Array<{productID:string, title:string, price:string, description:string}>}
   */
  static filterCurrentTier(catalog) {
    const targetTier = G.OMTsettings.elements.Window_shop3.overrideTier || OMT.envData.settings.env.tier;
    const tierString = `t${targetTier}`;
    const availableDeals = catalog.filter((gift) => {
      const splitID = gift.productID.split('_');
      if (splitID[0] !== tierString) return false;
      return true;
    });

    // availableDeals.sort(
    //   (a, b) => parseFloat(a.price.replace(/^\D*/g, '').replace(',', '')) - parseFloat(b.price.replace(/^\D*/g, '').replace(',', '')),
    // );
    return availableDeals;
  }

  /**
   * filter deals containing only coins
   * @param {Array} catalog
   * @returns {Array.<Object>}
   */
  static filterCoinDeals(catalog) {
    const availableDeals = catalog.filter((gift) => {
      const splitID = gift.productID.split('_');
      if (splitID[1] === 'coins') return true;
      return false;
    }, this);
    return availableDeals;
  }

  /**
   * get targeted offer data by its product id
   * @param {Array.<Object>} catalog
   * @param {string} productID
   * @returns {Object}
   */
  static getTargetedOfferByID(catalog, productID) {
    const targetTier = G.OMTsettings.elements.Window_shop3.overrideTier || OMT.envData.settings.env.tier;
    const tierString = `t${targetTier}_`;
    for (const catalogItem of catalog) {
      try {
        if (catalogItem.productID.indexOf(tierString) !== 0) continue; // filter offer tier
        const itemID = catalogItem.productID.substr(3); // check itemID
        if (itemID === productID) return catalogItem;
      } catch (error) {
        // catalog parsing issue. error is logged elsewhere.
      }
    }
    return null;
  }

  /**
   * Get the price of a real money spin
   * @param {Array} catalog
   * @param {RMWHEEL_EPS} entryPoint
   * @param {RMWHEEL_MODES} wheelMode
   */
  static getRealMoneyWheelOffer(catalog, entryPoint, wheelMode) {
    if (!catalog || catalog.length === 0) return [];
    catalog = ShopUtils.filterCurrentTier(catalog);

    // Translate ID components
    let wheelModeID;

    switch (wheelMode) {
      case RMWHEEL_MODES.Conversion:
        wheelModeID = 'convwheel';
        break;

      case RMWHEEL_MODES.HighValue:
        wheelModeID = 'hivalwheel';
        break;

      case RMWHEEL_MODES.Platinum:
        wheelModeID = 'platwheel';
        break;

      default:
        wheelModeID = 'error';
    }

    let wheelType = '';

    switch (entryPoint) {
      case RMWHEEL_EPS.Replacement:
        wheelType = 'prize';
        break;

      case RMWHEEL_EPS.Helper:
        wheelType = 'helper';
        break;

      case RMWHEEL_EPS.TargetedOffer:
        wheelType = 'targeted';
        break;

      case RMWHEEL_EPS.EPPayload:
        wheelType = 'payload';
        break;

      default:
        wheelType = 'error';
    }

    const wheelOffers = catalog.filter((offer) => {
      const splitID = offer.productID.split('_');
      return splitID[1] === wheelType && splitID[2] === wheelModeID;
    });

    return wheelOffers;
  }

  /**
   * Get the price of unlimited lives when in no more lives situation
   * @param {Array} catalog
   * @param {string} productIdSuffix the final part of the product ID (tX_nmlinflives_??)
   */
  static getNMLUnlimitedLivesOffer(catalog, productIdSuffix) {
    if (!catalog || catalog.length === 0) return [];
    catalog = ShopUtils.filterCurrentTier(catalog);

    const unlLivesOffer = catalog.filter((offer) => {
      const splitID = offer.productID.split('_');
      return splitID[1] === 'nmlinflives' && splitID[2] === productIdSuffix;
    });

    return unlLivesOffer;
  }

  /**
   * tries to purchase an item
   * @param {Object} item to buy
   * @param {Phaser.Group} button
   * @param {Phaser.Group} [coinbar]
   * @param {boolean} showMessageOnSuccess
   * @param {function} onSuccess callback to run after a successful purchase
   */
  static purchaseItem(item, button, coinbar, showMessageOnSuccess, onSuccess) {
    const wasPayer = true;//DDNA.tracking.getDataCapture().getPlayerCharacterizationParam('realMoneyPurchasesMade');

    OMT.platformTracking.logEvent(OMT.platformTracking.Events.IAPRequest, null, {
      price: item.price,
      productID: item.productID,
      priceCurrencyCode: item.priceCurrencyCode,
    });

    // DDNA.transactionHelper.trackCoinShopState({
    //   transactionState: 'REQUEST',
    //   transactionFailReason: '',
    //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
    //   transactionID: '',
    // }, item);

    OMT.payments.buyAndConsumeProduct(item, showMessageOnSuccess, (product, purchase, success) => {
      if (product === null) {
        return;
      }
      if (success) {
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.IAPSuccess, null, {
          price: item.price,
          productID: item.productID,
          priceCurrencyCode: item.priceCurrencyCode,
        });

        OMT.platformTracking.logEvent(OMT.platformTracking.Events.IAPurchase, item.price, {
          package_id: item.productID,
          value: item.price,
          conversion: wasPayer ? 0 : 1,
        });

        // Determine tActionType
        let tActionType = 'INVENTORY_PURCHASE'; // this is the default
        const productIdName = item.productID.split('_')[1];
        if (productIdName === 'nmlinflives') {
          // Note: only "nmlinflives" products use the tActionType UNL_LIFE_PURCHASE, "inflives" does NOT.
          tActionType = 'UNL_LIFE_PURCHASE';
        }

        this.processPurchase(product, purchase, tActionType, button, coinbar);

        if (onSuccess) {
          onSuccess();
        }
      } else {
        const transactionID = purchase && purchase.paymentID ? purchase.paymentID : '';

        OMT.platformTracking.logEvent(OMT.platformTracking.Events.IAPFail, null, {
          price: item.price,
          productID: item.productID,
          priceCurrencyCode: item.priceCurrencyCode,
          reason: 'Item purchase was unsuccessful.',
        });
      }
    });
  }

  /**
   * process purchase
   * @param {Object} product product data
   * @param {Object} purchase purchase data
   * @param {string} actionType type of purchase (tActionType on DDNA)
   * @param {G.Button} button
   * @param {Phaser.Group} [coinbar]
   */
  static processPurchase(product, purchase, actionType, button, coinbar) {
    const reward = OMT.payments.parseRewards(product);
    if (product.productID.includes('annuity')) { // If its an annuity, get the details and set the reward as the normal claim coins, rather than total
      const annuityDetails = AnnuityManager.getAnnuityDetails(product.productID, product.description);
      reward.coins = annuityDetails.coin;
    }

    G.saveState.incrementIAPCount();

    const transactionID = purchase && purchase.paymentID ? purchase.paymentID : '';

    // capture player stats
    //const dataCapture = DDNA.tracking.getDataCapture();

    /*if (G.saveState.getLives() === 0) dataCapture.addToPlayerCharacterizationParam('zeroLivesRealMoneyPurchases', 1, false);
    dataCapture.addToPlayerCharacterizationParam('realMoneySpent', DDNA.utils.formatCurrencyValueAsInteger(product.price) / 100, false);
    dataCapture.addToPlayerCharacterizationSessionParam('realMoneySpentThisSession', DDNA.utils.formatCurrencyValueAsInteger(product.price) / 100);
    dataCapture.addToPlayerCharacterizationParam('coinValueBoughtWithRealMoney', reward.coins, false);
    dataCapture.addToPlayerCharacterizationParam('realMoneyPurchasesMade', 1, true);*/

    // track the transaction
    // DDNA.transactionHelper.trackCoinShopPurchase(OMT.payments.convertToOldProductData(reward).giftData, {
    //   transactionType: 'PURCHASE',
    //   tActionType: actionType,
    //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
    //   transactionID,
    // }, product);

    // dispatch a event to notify other game areas of purchase
    G.sb('onHandlePurchase').dispatch(product, OMT.payments.convertToOldProductData(reward));
    G.sfx.cash_register.play();

    // const button = this.findButtonWithID(product.productID);
    let x = 0;
    let y = 0;
    if (button !== null) {
      x = game.world.bounds.x + button.worldPosition.x;
      y = button.worldPosition.y;
    }

    for (let i = 0; i < reward.booster.length; i++) {
      G.gift.applyGift([reward.booster[i].item, reward.booster[i].amount], false); // Get booster
    }

    if (reward.infiniteLives > 0) {
      G.gift.applyGift([G.gift.GiftContentType.UnlimitedLife, reward.infiniteLives], false);
    }

    if (reward.coins > 0) {
      this.createCoinParticles(x, y, reward.coins, coinbar);
    }
  }

  /**
   * Create coin particles for the given amount
   * @param {number} x
   * @param {number} y
   * @param {number} coins
   * @param {DisplayObject} [coinbar]
   */
  static createCoinParticles(x, y, coins, coinbar = null) {
    const state = game.state.getCurrentState();
    if (coinbar) {
      state.uiTargetParticles.createCoinBatch( // Particles
        x,
        y,
        coinbar,
        coins,
        true,
        null,
        coinbar.coinsTxt,
      );
    } else if (state.key === 'World') { // we need to animate coins in the wold scene
      state.uiTargetParticles.createCoinBatch( // Particles
        x,
        y,
        state.panel.coinsTxt,
        coins,
        true,
        null,
        state.panel.coinsTxt,
      );
    } else { // other scenes we need to show the coinbar overlay as it is not part of the scene
      Shop_CoinBar.getInstance().show(true);
      state.uiTargetParticles.createCoinBatch( // Particles
        x,
        y,
        Shop_CoinBar.getInstance().coinsTxt,
        coins,
        true, // skip adding coins
        () => {
          // we want to dispatch the change event only after the coins have animated
          Shop_CoinBar.getInstance().hide(true);
        },
        Shop_CoinBar.getInstance().coinsTxt,
      );
    }
  }

  /**
   * Takes the catalog and filtures it for special deal packs
   * Ignores packs that are in the exluded keys, or a popup, or the full price packs
   * @param {Array} catalog
   * @param {Array<string>} excludeKeys
   * @return {Array<{deal:Object, dealType:string, fullPrice?:Object}>}
   */
  static organizeSpecialDeals(catalog, excludeKeys) {
    const organizedSpecialDeals = [];
    const fullPrices = {};
    catalog.forEach((deal) => {
      const splitId = deal.productID.split('_')[1];
      if (excludeKeys.indexOf(splitId) === -1 && splitId.indexOf('popup') === -1 && splitId.indexOf('fullprice') === -1 && splitId.indexOf('layout') === -1) {
        organizedSpecialDeals.push({
          dealType: splitId,
          deal,
        });
      } else if (splitId.indexOf('fullprice') > -1) {
        fullPrices[splitId] = deal;
      }
    });
    organizedSpecialDeals.sort((a, b) => {
      if (a.dealType > b.dealType) { return 1; }
      if (a.dealType < b.dealType) { return -1; }
      return 0;
    });
    Object.keys(fullPrices).forEach((key) => {
      const deal = organizedSpecialDeals.find((specDeal) => {
        const adjustedKey = key.replace('fullprice', '');
        return adjustedKey === specDeal.dealType;
      });
      deal.fullPrice = fullPrices[key];
    });
    return organizedSpecialDeals;
  }

  /**
   * Takes in the catalog and returns an object of arrays.
   * The object keys correspond to the targetKeys given.
   * The arrays hold the FB Catalog object that is used to purchase stuff.
   *
   * For example, if targetKeys was [coins],
   * then the output would be { coins: [ { type: coins, deal: {catalogObject}}] }
   * @param {any} catalog
   * @param {Array<string>} targetKeys
   * @returns {Object<T:Array<{type:string, deal:FBCatalog}>}
   */
  static organizeRegularDeals(catalog, targetKeys) {
    const regDeals = {
    };
    targetKeys.forEach((key) => {
      regDeals[key] = [];
    });
    catalog.forEach((deal) => {
      const dealType = deal.productID.split('_')[1];
      if (Object.hasOwnProperty.call(regDeals, dealType)) { // packs preferrably named like regDeals but improvements can be made
        regDeals[dealType].push({
          type: deal.productID.substring(3),
          deal,
        });
      }
    });

    return regDeals;
  }

  /**
   * Finds a pack called Layout and filters it to show which specific layout to use in the shop
   * @param {FB Full Catalog Data} catalog
   * @param {Array<String>} fallback
   * @returns {Layout_Data}
   */
  static getShopLayoutData(catalog, fallback) {
    // Defaults
    const dataOut = {
      simplified: fallback,
      simplifiedSpecDeal: [],
      specialDeals: [],
      firstSpecialDeals: [],
      discount: [],
      specDealBold: [],
      freeGift: [],
      weekend: [],
      annuity: [],
    };

    // Use debug layout if we're not in production.
    const debugAddition = G.BuildEnvironment.production ? '' : 'debug';
    let allLayoutData = catalog.filter((entry) => entry.productID.indexOf(`_${debugAddition}layout`) > -1);
    if (allLayoutData.length === 0) { // If debug netted nothing, use the production
      allLayoutData = catalog.filter((entry) => entry.productID.indexOf('_layout') > -1);
    }

    const findTier = (tierNum) => allLayoutData.find((entry) => {
      const splitID = entry.productID.split('_');
      return splitID[0] === `t${tierNum}`;
    });
    let targetLayoutData = findTier(OMT.envData.settings.env.tier);
    if (!targetLayoutData) {
      targetLayoutData = findTier(1);
    }

    if (targetLayoutData) {
      const getLayoutPiecesFor = (str) => {
        const layoutPieces = str.split('-');
        _.remove(layoutPieces, (deal) => !deal);
        return layoutPieces;
      };

      const layoutInfoSegments = targetLayoutData.description.split(' ');
      for (const seg of layoutInfoSegments) { // Looks at each section of data in the layout pack
        const segSplit = seg.split(':'); // Split it on the :
        const title = segSplit[0];
        const data = segSplit[1];

        const layoutPieces = getLayoutPiecesFor(data); // Finds out the packs
        if (layoutPieces.length === 0) { continue; }
        if (!dataOut[title]) { dataOut[title] = []; } // Make an array if it doesn't exist
        const curSegment = dataOut[title];
        for (let i = 0; i < layoutPieces.length; i++) {
          const curLayoutData = layoutPieces[i];
          if (curLayoutData && curLayoutData !== '') {
            if (curSegment[i]) {
              curSegment[i] = curLayoutData; // Replace fall back or
            } else {
              curSegment.push(curLayoutData); // Push directly in
            }
          }
        }
      }
    }

    // If its a non payer and theres actually a different layout
    if (G.saveState.getIAPCount() <= 0 && dataOut.firstSpecialDeals.length > 0) {
      dataOut.specialDeals = dataOut.firstSpecialDeals;
    }
    delete dataOut.firstSpecialDeals;

    return dataOut;
  }

  /**
   * Filters the catalog and organizes special deals into categories, then picks them from the categories
   * Will not pick deals that are already chosen
   * @param {Object} config
   * @param {Array<{deal:{price:string, productId: string}}>} config.catalog
   * @param {number} config.maxSlot
   * @param {Array<Shop3_SpecialDeal>} config.existingDeals
   * @param {Object} config.runningEvent
   * @param {Array<string>} config.prioritySpecialDeals
   * @param {Array<string>} config.assetOrder
   * @param {boolean} config.weekendIsOn
   * @param {boolean} config.annuityIsOn
   * @returns {Array<{deal:Object, dealType:string, slot:number}>}
   */
  static getSpecialDeals(config) {
    const existingCopy = [];
    const existingTypes = [];
    let existingTwoSlot = false;
    let usedSlots = 0;
    config.existingDeals.forEach((deal) => {
      existingCopy.push(deal.dealName);
      existingTypes.push(deal.dealType);
      if (deal.slot >= 2) {
        existingTwoSlot = true;
      }
      usedSlots += deal.slot;
    });
    const newDeals = [];
    const timeout = 5000;
    const annuityCatalog = [];
    const eventCatalog = [];
    const regularCatalog = [];
    const emergencyCatalog = [];
    let weekendCatalog;

    if (usedSlots < config.maxSlot) {
      let eventKey;
      let regularKey;
      const IAPCount = G.saveState.getIAPCount(); // Check how many times they've bought
      if (G.saveState.sessionData.shopSpecialType) { /** Cheat code override */
        regularKey = G.saveState.sessionData.shopSpecialType;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (IAPCount > 0 || existingTwoSlot) {
          if (config.runningEvent && !existingTwoSlot) {
            eventKey = config.runningEvent.packageId.toLowerCase();
          }
          regularKey = SHOP_DEAL_TYPES.SPECIAL;
          weekendCatalog = regularCatalog;
        } else {
          if (config.runningEvent) {
            eventKey = config.runningEvent.firstTimeBuyerId.toLowerCase();
          }
          regularKey = SHOP_DEAL_TYPES.FIRST;
          weekendCatalog = emergencyCatalog;
        }
      }

      // Separating into sections
      config.catalog.forEach((specDeal) => {
        switch (specDeal.dealType) {
          case eventKey:
            eventCatalog.push(specDeal);
            break;
          case regularKey:
            regularCatalog.push(specDeal);
            break;
          case SHOP_DEAL_TYPES.WEEKEND:
            if (config.weekendIsOn) {
              weekendCatalog.push(specDeal);
            }
            break;
          case SHOP_DEAL_TYPES.SPECIAL:
            emergencyCatalog.push(specDeal);
            break;
          case SHOP_DEAL_TYPES.ANNUITY:
            annuityCatalog.push(specDeal);
            break;
          default: break;
        }
      });
      const sortAndPrune = (arr) => {
        _.remove(arr, (deal) => existingCopy.indexOf(deal.deal.productID) > -1);
        arr.sort((a, b) => {
          if (a.deal.price > b.deal.price) { return 1; }
          if (a.deal.price < b.deal.price) { return -1; }
          return 0;
        });
      };
      sortAndPrune(eventCatalog);
      sortAndPrune(regularCatalog);
      sortAndPrune(emergencyCatalog);

      let specialDealCounter = 0; // Smaller incrementing counter supposed to find out which special deal to use
      let counter = 0;
      let priorityDealCounter = 0; // Another counter that looks for priority deals
      while (counter < timeout && usedSlots < config.maxSlot) {
        let chosenArray;
        let chosenKey;
        if (eventCatalog.length > 0) {
          chosenArray = eventCatalog;
          chosenKey = eventKey;
        } else if (config.annuityIsOn && annuityCatalog.length > 0) { // We want to see Annuity!
          chosenArray = annuityCatalog;
          chosenKey = SHOP_DEAL_TYPES.ANNUITY;
        } else if (regularCatalog.length > 0) {
          chosenArray = regularCatalog;
          chosenKey = regularKey;
        } else {
          chosenArray = emergencyCatalog;
          chosenKey = SHOP_DEAL_TYPES.SPECIAL;
        }
        let chosenDeal;
        if (chosenKey === SHOP_DEAL_TYPES.SPECIAL && config.prioritySpecialDeals.length > 0 && priorityDealCounter < config.prioritySpecialDeals.length) {
          chosenDeal = chosenArray.find((traverseDeal) => { // eslint-disable-line no-loop-func
            if (traverseDeal.deal.productID.indexOf(config.prioritySpecialDeals[priorityDealCounter]) > -1) { // Search through each of the prioritySpecialDeals to find the first one matched
              return true;
            }
            return false;
          });
          priorityDealCounter++; // If it found one or never found one at all, increment
        } else if (chosenKey === SHOP_DEAL_TYPES.SPECIAL && specialDealCounter < config.assetOrder.length) { // Follow order determined by asset
          chosenDeal = chosenArray.find((traverseDeal) => { // eslint-disable-line no-loop-func
            if (traverseDeal.deal.productID.indexOf(config.assetOrder[specialDealCounter]) > -1) { // Search through each of the prioritySpecialDeals to find the first one matched
              return true;
            }
            return false;
          });
          specialDealCounter++;
        } else {
          chosenDeal = chosenArray[(specialDealCounter + counter) % chosenArray.length]; // rng show a deal for now
          specialDealCounter++;
        }
        if (chosenDeal) {
          if (existingCopy.indexOf(chosenDeal.deal.productID) === -1) { // If it hasn't been found
            const slotCount = this._getSlotByDealType(chosenKey);
            if (usedSlots + slotCount <= config.maxSlot) {
              const newDealPack = { // Push in the details
                deal: chosenDeal.deal,
                dealType: chosenDeal.dealType,
                slot: slotCount,
              };
              if (chosenDeal.fullPrice) {
                newDealPack.fullPrice = chosenDeal.fullPrice;
              }
              newDeals.push(newDealPack);
              existingCopy.push(chosenDeal.deal.productID);
              chosenArray.splice(chosenArray.indexOf(chosenDeal), 1);
              usedSlots += slotCount;
              counter = 0; // reset
            } else {
              chosenArray.splice(chosenArray.indexOf(chosenDeal), 1); // Take it out of consideration
            }
          }
        }

        if (usedSlots < config.maxSlot) {
          counter++;
        } else {
          counter = timeout + 1;
        }
      }
    }
    return this._assignAssetAgainstSpecialPacks(config.assetOrder.filter((as) => as.indexOf(SHOP_DEAL_TYPES.SPECIAL) > -1), newDeals);
  }

  /**
   * Assigns asset packs with asset indexes based off of assetOrder.
   * If the asset does not exist in assetOrder, the individual special deal packs have a back up
   * @param {Array<string>} assetOrder
   * @param {{deal: FBShopPack, dealType: string, slot: number}} outgoingPacks
   * @returns {{deal: FBShopPack, dealType: string, slot: number, [asset]:string}}
   */
  static _assignAssetAgainstSpecialPacks(assetOrder, outgoingPacks) {
    for (let i = 0; i < assetOrder.length; i++) {
      const specDeal = assetOrder[i];
      for (const targetPack of outgoingPacks) {
        const { productID } = targetPack.deal;
        if (productID.indexOf(specDeal) > -1) {
          targetPack.asset = Math.min(i, 6);
          break;
        }
      }
    }
    return outgoingPacks;
  }

  /**
   * Returns the number of slots a certain deal type takes
   * @param {string} type
   * @returns {number}
   */
  static _getSlotByDealType(type) {
    switch (type) {
      case SHOP_DEAL_TYPES.WEEKEND: return 1;
      case SHOP_DEAL_TYPES.SPECIAL: return 1;
      case SHOP_DEAL_TYPES.FIRST: return 2;
      case SHOP_DEAL_TYPES.ANNUITY: return 1;
      default: return 2;
    }
  }

  /**
   * Gets the asset string from shop product ID strings
   * @param {string} str
   * @param {string}
   */
  static getBoosterAssetFromShopString(str) {
    const boosterNumber = str.replace('b', '');
    return G.gift.getIconForType(`booster#${boosterNumber}`);
  }

  /**
   * Returns the timestamp of the day the sale ends within the active days
   * or the date of when the sale ends
   * Whichever is closer
   * @returns {number}
   */
  static determineSpecialDealsTimeOut() {
    const today = new Date(OMT.connect.getServerTimestampSync());
    today.setUTCHours(0, 0, 0, 0); // Today's UTC date at 00:00:00

    // Check how many days away in active days till the end
    const differenceAway = (G.featureUnlock.specialShopDeal.activeDays.length - G.featureUnlock.specialShopDeal.activeDays.indexOf(today.getUTCDay()));
    today.setUTCHours(differenceAway * 24); // Multiply the distance by 24 hours

    // Return the timestamp of the day the sale ends within the active days, or the date of the end of the sale
    // Whichever is closer
    return Math.min(today.getTime(), G.featureUnlock.specialShopDeal.range.end);
  }

  /**
   * Returns the start and end Date for the weekend deal
   * @returns {{start:Date, end:Date}}
   */
  static getStartAndEndTimeForWeekendDeal() {
    const startDate = new Date(); // Starting date
    const time = G.featureUnlock.weekendShopDeal.startingFullHour;
    startDate.setUTCHours(time[0], time[1], time[2], time[3]);
    startDate.setUTCDate((startDate.getUTCDate() - (startDate.getUTCDay() || 7)) + G.featureUnlock.weekendShopDeal.startingDay); // The Friday

    const endDate = new Date(startDate); // Ending date
    endDate.setMilliseconds(G.featureUnlock.weekendShopDeal.saleTime);

    return {
      start: startDate,
      end: endDate,
    };
  }
}
