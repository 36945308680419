import { Window_BragBaseWindow } from './Window_BragBaseWindow';

const PERCENT_TOKEN = '%PERCENT%';
const BANNER_TITLE_STRING = 'Did you know?';
const BANNER_SUBTITLE_STRING = `Only ${PERCENT_TOKEN}% of players manage to beat this level.`;
const BANNER_BOTTOM_FRIENDS_STRING = 'And you and your friend %FRIEND% did it like pros!';

/**
 * Window for intiating a Brag 2.0 challenge
 */
class Window_BragInitChallenge extends Window_BragBaseWindow {
  /**
   * constructor
   * @param {object} parent
   * @param {number} levelNum
   */
  constructor(parent, levelNum) {
    super(parent, G.OMTsettings.elements.Window_Brag, levelNum);
  }

  /**
   * override customize the various window banners
   */
  _createBanners() {
    this._createTopBanner(OMT.language.getText(BANNER_TITLE_STRING));
    this._createSubTitles([OMT.language.getText(BANNER_SUBTITLE_STRING).replace(PERCENT_TOKEN, this._bragRightsData.percentage)]);
    if (this._friendData.length > 0) this._createBottomBanner(OMT.language.getText(BANNER_BOTTOM_FRIENDS_STRING));
  }

  /**
   * create the specific window content
   */
  _createContent() {
    super._createContent();

    // init the playing with friends or solo avatar layout
    if (this._friendData.length > 0) { // playing solo
      this._initFriendsAvatarLayout(BANNER_BOTTOM_FRIENDS_STRING);
    } else { // playing with friends
      this._initSoloAvatarLayout();
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.bragInitChallenge = Window_BragInitChallenge;
