/* eslint-disable no-use-before-define */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable wrap-iife */
G.Helpers = G.Helpers || {};
// eslint-disable-next-line max-len

G.Helpers.levelDataMgr = (function () {
  let DATA = null;

  return {
    init,
    getLevelByNumber,
    getLevelByIndex,
    getLevelWithTutorialId,
    getLevelWithEditorSymbol,
    getNumberOfLevels,
    getMapPositionOfLevelIndex: getMapPosition,
    getMaxLevelIndex,

    toggleLevelDifficulty,
    getRawLevelById,
    replaceRawLevelById,
    prepareLevel,
    getLevelById,
    copyLevel,
    createNewLevel,
    changeLevelsOrder,
    getIndexOfLevelId,
    exportLevels,
    removeLevelsFromPath,
    addLevelsToPath,
    saveLevel,
    getIdsOfBacklogLevels,
    getNextLevelId,
    getPreviousLevelId,
    getMapPosition,
    getTilemapHeight,
    getLevelIdsFromLevelNumbers,
    getLevelNumbersFromLevelIds,
    extractLevelParams,
  };

  function init(levelData) {
    DATA = levelData;
  }


  /**
   * log csv of level parameters to the console
   * @param {Array.<string>} paramList list of parameters to extract
   * @param {number} rangeStart (optional)
   * @param {rangeEnd} rangeEnd (optional)
   * @param {Function} filterFunc (optional) data filter
   */
  function extractLevelParams(paramList, rangeStart = 0, rangeEnd = Infinity, filterFunc = null) {
    rangeStart = Math.max(rangeStart, 0);
    rangeEnd = Math.min(DATA.order.length, rangeEnd);

    // set column names
    const rows = [[]];
    for (const paramName of paramList) rows[0].push(`"${paramName}"`);

    // fill rows with levelNum + params from paramList
    for (let index = rangeStart; index < rangeEnd; index++) {
      const data = getLevelByIndex(index);
      const meetsFilter = filterFunc == null ? true : filterFunc(data);
      if (!meetsFilter) continue;

      const row = []; rows.push(row);
      for (const paramName of paramList) {
        if (typeof data[paramName] === 'number') {
          row.push(data[paramName]);
        } else if (typeof data[paramName] === 'string') {
          row.push(JSON.stringify(data[paramName]));
        } else {
          row.push(`"${JSON.stringify(data[paramName])}"`);
        }
      }
    }

    // generate and log output
    let output = '';
    for (const row of rows) {
      const rowOutput = row.join(',');
      output += `${rowOutput}\n`;
    }
    console.log('\n\n\n');
    console.log(output);
    console.log('\n\n\n');
  }

  function getLevelByNumber(number, altData = null) {
    const data = altData || DATA;
    const lvlId = data.order[number - 1];
    return prepareLevel(getRawLevelById(lvlId, data));
  }

  function getLevelByIndex(index, altData = null) {
    const data = altData || DATA;
    const lvlId = data.order[index];
    return prepareLevel(getRawLevelById(lvlId, data));
  }

  function prepareLevel(lvl) {
    if (!lvl) return null;

    const lvlIndex = getIndexOfLevelId(lvl.id);
    const mapPosition = getMapPosition(lvlIndex);

    const clonedLvl = G.Utils.clone(lvl);

    clonedLvl.levelIndex = lvlIndex;
    clonedLvl.levelNumber = lvlIndex + 1;
    clonedLvl.mapX = mapPosition.x;
    clonedLvl.mapY = mapPosition.y;

    return clonedLvl;
  }

  function getMapPosition(lvlIndex) {
    if (lvlIndex === -1) {
      return { x: 0, y: 0 };
    }

    const { nodes } = DATA.levelsMapPositions;
    const { repeatOffset } = DATA.levelsMapPositions;

    const offset = Math.floor(lvlIndex / nodes.length) * repeatOffset;
    const node = nodes[lvlIndex % nodes.length];

    return {
      x: node.x,
      y: node.y + offset,
    };
  }

  function getLevelWithTutorialId(tutorialId) {
    return prepareLevel(DATA.levels.find(function (lvl) {
      return (lvl.tutID === tutorialId
        && getIndexOfLevelId(lvl.id) !== -1);
    }));
  }

  function getNumberOfLevels() {
    return DATA.order.length;
  }

  function getMaxLevelIndex() {
    return getNumberOfLevels() - 1;
  }

  function getLevelWithEditorSymbol(symbol) {
    const level = DATA.levels.find(function (lvl) {
      return (JSON.stringify(lvl.levelData).indexOf(symbol) !== -1
        && getIndexOfLevelId(lvl.id) !== -1);
    });

    if (level) {
      return prepareLevel(level);
    }
    return null;
  }

  /**
   * convert a list of level numbers into level ids
   * @param {Array.<number>} levNumList
   * @returns {Array.<string>}
   */
  function getLevelIdsFromLevelNumbers(levNumList) {
    const idList = [];
    for (const levNum of levNumList) idList.push(getLevelByNumber(levNum).id);
    return idList;
  }

  /**
   * convert a list of level numbers into level ids
   * @param {Array.<string>} levIdList
   * @returns {Array.<number>}
   */
  function getLevelNumbersFromLevelIds(levNumList) {
    const lvlNumList = [];
    for (const levId of levNumList) lvlNumList.push(getLevelById(levId).levelNumber);
    return lvlNumList;
  }

  function toggleLevelDifficulty(lvlId) {
    const level = getRawLevelById(lvlId);
    if (level.showAsHard) {
      delete level.showAsHard;
    } else if (level.showAsSuperHard) {
      delete level.showAsSuperHard;
    } else {
      level.showAsHard = true;
    }
  }

  function getRawLevelById(lvlId, altData = null) {
    const data = altData || DATA;
    return data.levels.find((lvl) => lvl.id === lvlId);
  }

  function replaceRawLevelById(lvlId, replacementData) {
    const levelData = DATA.levels.find((lvl) => lvl.id === lvlId);
    const index = DATA.levels.indexOf(levelData);
    if (index === -1) return;
    DATA.levels[index] = replacementData;
  }

  function getLevelById(lvlId, altData = null) {
    const data = altData || DATA;
    return prepareLevel(getRawLevelById(lvlId, data));
  }

  function copyLevel(lvlId) {
    const level = G.Utils.clone(getRawLevelById(lvlId));
    level.id = Date.now().toString();
    DATA.levels.push(level);
    DATA.order.push(level.id);
    return level.id;
  }

  function createNewLevel(defaultLevelJSON) {
    const level = JSON.parse(defaultLevelJSON);
    level.id = Date.now().toString();
    DATA.levels.push(level);
    DATA.order.push(level.id);
    return level.id;
  }

  function getIndexOfLevelId(lvlId) {
    return DATA.order.indexOf(lvlId);
  }

  function changeLevelsOrder(lvlIds, newIndex) {
    // remove ids
    lvlIds.forEach(function (id) {
      const index = getIndexOfLevelId(id);
      if (index >= 0) {
        DATA.order.splice(index, 1);
      }
    });

    DATA.order.splice.apply(DATA.order, [newIndex, 0].concat(lvlIds));
  }

  function exportLevels(originalData) {
    // look for modified levels
    const changedLevelIds = [];
    DATA.levels.forEach((levelData) => {
      const modifiedLevel = getRawLevelById(levelData.id);
      const originalLevel = getRawLevelById(levelData.id, originalData);
      if (originalLevel) {
        const hasChanges = JSON.stringify(modifiedLevel) !== JSON.stringify(originalLevel);
        if (hasChanges) changedLevelIds.push(levelData.id);
      } else if (!originalLevel) {
        changedLevelIds.push(levelData.id);
      }
    });
    // return;
    // load zip script then save
    if (!window.JSZip) {
      G.Utils.loadScript('https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.5/jszip.min.js')
        .then(function() {
          saveZip(changedLevelIds);
        });
    } else {
      saveZip(changedLevelIds);
    }

    function saveZip(levelIdList) {
      console.log(`saveZip(), changedLevelIds: ${levelIdList}`);
      const zip = new JSZip();
      levelIdList.forEach(function (lvlId) {
        zip.file(`level_${lvlId}.json`, JSON.stringify(getRawLevelById(lvlId), null, '\t'));
      });

      const pathCopy = G.Utils.clone(DATA);
      delete pathCopy.levels;
      zip.file('PATH.js', JSON.stringify(pathCopy, null, '\t'));

      zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          const date = new Date();
          saveAs(content, `CC2 EXPORT - ${G.PerSessionSettings.sessionId} -${date.toUTCString()}`);
        });
    }
  }

  function saveLevel(lvlId, lvlData) {
    const lvlOrg = getRawLevelById(lvlId);

    if (!deepEqual(lvlOrg, lvlData)) {
      console.log('there is difference', lvlId);
      DATA.levels.splice(DATA.levels.indexOf(lvlOrg), 1);
      DATA.levels.push(lvlData);
    } else {
      console.log('no difference');
    }

    function deepEqual(x, y) {
      if (x === y) {
        return true;
      }
      if ((typeof x === 'object' && x !== null) && (typeof y === 'object' && y !== null)) {
        if (Object.keys(x).length !== Object.keys(y).length) {
          return false;
        }

        for (const prop in x) {
          if (y.hasOwnProperty(prop)) {
            if (!deepEqual(x[prop], y[prop])) {
              return false;
            }
          } else {
            return false;
          }
        }

        return true;
      }
      return false;
    }
  }

  function removeLevelsFromPath(lvlIds) {
    lvlIds.forEach(function (lvlId) {
      const index = getIndexOfLevelId(lvlId);
      if (index >= 0) {
        DATA.order.splice(index, 1);
      }
    });
  }

  function addLevelsToPath(lvlIds) {
    lvlIds.forEach(function (lvlId) {
      const index = getIndexOfLevelId(lvlId);
      if (index === -1) {
        DATA.order.push(lvlId);
      }
    });
  }

  function getIdsOfBacklogLevels() {
    return DATA.levels
      .filter(function (lvl) {
        return DATA.order.indexOf(lvl.id) === -1;
      })
      .map(function (lvl) {
        return lvl.id;
      });
  }

  function getNextLevelId(lvlId) {
    return getNeighbourId(lvlId, 1);
  }

  function getPreviousLevelId(lvlId) {
    return getNeighbourId(lvlId, -1);
  }

  function getNeighbourId(lvlId, offset) {
    let currentIndex = DATA.order.indexOf(lvlId);
    let nextIndex;

    if (currentIndex >= 0) {
      nextIndex = game.math.clamp(currentIndex + offset, 0, getMaxLevelIndex());
      return DATA.order[nextIndex];
    }
    // backlog level
    const backlogIds = getIdsOfBacklogLevels();
    currentIndex = backlogIds.indexOf(lvlId);
    nextIndex = game.math.clamp(currentIndex + offset, 0, backlogIds.length - 1);
    return backlogIds[nextIndex];
  }

  function getTilemapHeight() {
    return Math.abs(DATA.levelsMapPositions.repeatOffset);
  }
})();
