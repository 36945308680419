if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getHideGingyAnimation = function getHideGingyAnimation() {
  return { animate: animate };

  function animate(gingy, callback) {
    gingy.update = function() {};
    game.add
      .tween(gingy)
      .to(
        { x: gingy.x - 1000 },
        600,
        Phaser.Easing.Bounce.Out,
        true
      )
      .onComplete.add(function() {
        gingy.visible = false;
        if(callback) callback();
      }, this);
  };
}
