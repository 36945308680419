/* eslint-disable no-multi-assign */
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import OMT_UI_DrawUtil from '../../../OMT_UI/Drawing/OMT_UI_DrawUtil';

export const FRIENDSHIPCHEST_BUTTONMODE = {
  CLAIM: 'CLAIM',
  INVITE: 'INVITE',
};

export const FRIENDSHIPCHESTCARD_STATUS = {
  OPEN: 0, //  Invite friend button
  PENDING: 1, //  Friend invitation is sent
  FRIEND: 2, //  Showing the friend with the shine and the stars
  UNCLAIMED: 3, //  Friend has joined! or Claim button
  CLAIMED: 4, //  Claimed checkmark card
  END: 5, //  [?] card. For very end cards
  PROMO: 6, //  For details about the chest and telling you how it works
  FTUX: 7, //  For the First Time User Experience
};

const TEXTSTYLE_title = {
  style: 'font-blue',
  fontSize: 64,
  lineSpacing: -15,
};

const TEXTSTYLE_subText = {
  style: 'font-blue',
  fontSize: 24,
};

const TEXTSTYLE_questionMark = {
  style: 'font-white',
  fontSize: 60,
};

const TEXTSTYLE_amountText = {
  style: 'font-blue',
  fontSize: 48,
};

const TEXTSTYLE_redText = {
  style: 'font-white',
  fill: 'red',
  fontSize: 48,
};
/**
 * The card that is used in Friendship Chests and promo cards.
 * 15/04/20 SK: This FriendshipChest_Card is not used anymore except fr showing the promo card to the Friendship Chest
 * If you're looking for the individual cards for the v2 Friendship Chest, they've been split between Friendship_Chestv2 and
 * FriendshipChest_Polaroid. Most of the stuff here is not used anymore...
 * @author Sandra Koo
 */
export class FriendshipChest_Card extends Phaser.Group {
  constructor(inGame, inParent, index) {
    super(inGame, inParent);

    this.fillBackground = G.OMTsettings.friendshipChest.layoutData.cardBackground;

    this.signals = {
      onClick: new Phaser.Signal(),
      onCardReady: new Phaser.Signal(),
      onRejectClick: null, // Only for promo card
    };

    this.rays = undefined; // Some rays behind the card
    this.card = undefined; // The card itself
    this.fill = undefined; // The inner of the card
    this.subFill = undefined; // The inner inner of the card
    this.status = FRIENDSHIPCHESTCARD_STATUS.OPEN; // Its status
    this.prize = undefined; // Array of prizes
    this.prizeIndex = undefined; // The index of the chart that the card is holding
    this.index = index; // Index of the card. Gets changed a bit
    this.positionals = {}; // Object. Holds numbers of things
    this.nextCard = null; // The reference to the card next to it
    this.prevCard = null; // The reference to the card prev to it
    this.border = undefined; // The border
    this.innerDimensions = undefined; // Rect from bgFill
    this.chestImage = undefined; // Holds the group that shows the story chest. Used for animation
    this.storyChestPrev = undefined; // Used to hold info about the previous story chest. Used for animation
    this.claimed = undefined; // Container for the claimed part

    // Things to draw and manipulate
    this.button = undefined; // The big green button
    this.friend = undefined; // The friend screen
    this.sent = undefined; // The invitation is sent banner and message
    this.error = undefined; // The big red error message screen

    this.drawCard(); // Draws the border for now...

    this.inviteButtonHeight = 100; // 100 is the height of long_button_green
    this.invitePosition = (this.card.height / 2) - this.inviteButtonHeight * 0.8;
  }

  /**
   * Deeply destroys the card
   */
  destroyCard() {
    this.signals.onClick.dispose();
    this.signals.onCardReady.dispose();
    if (this.signals.onRejectClick) {
      this.signals.onRejectClick.dispose();
    }
    if (this.button) {
      this.button.destroy();
    }
    if (this.sent) {
      if (this.sent.pulse) {
        game.time.events.remove(this.sent.pulse);
      }
      this.sent.destroy();
    }
    if (this.error) {
      this.error.destroy();
    }
    this.destroy();
    this.nextCard = null;
    this.prevCard = null;
  }

  /**
   * Draws the border and the fill
   */
  drawCard() {
    this.rays = new Phaser.Group(G.game, this);
    const card = new Phaser.Group(G.game, this);

    const cardFill = new Phaser.Group(G.game, null);
    const border = new Phaser.Group(G.game, null);
    const bgTop = G.makeImage(0, 0, 'lb2_top_part_box', 0.5, border);
    const bgLeft = G.makeImage(0, 0, 'lb2_left_part_box', 0.5, border);
    const bgRight = G.makeImage(0, 0, 'lb2_right_part_box', 0.5, border);
    const bgBot = G.makeImage(0, 0, 'lb2_top_part_box', 0.5, border);
    bgTop.width = bgBot.width = bgTop.width * 0.8;
    bgLeft.width = bgRight.width = bgLeft.width * 0.8;
    bgBot.angle = 180;
    bgTop.y = -bgLeft.height / 2;
    bgLeft.x = bgTop.x - (bgTop.width - bgLeft.width) / 2;
    bgLeft.y = -1 + bgTop.y + (bgLeft.height + bgTop.height) / 2;
    bgBot.y = -4 + bgLeft.y + (bgLeft.height + bgBot.height) / 2;
    bgBot.x = -1;
    bgRight.x = bgTop.x + (bgTop.width - bgRight.width) / 2;
    bgRight.y = -1 + bgTop.y + (bgRight.height + bgTop.height) / 2;

    const bgFill = new Phaser.Graphics(game);
    bgFill.beginFill(this.fillBackground);
    bgFill.drawRoundedRect(0, 0, border.width - bgRight.width / 2, border.height - bgBot.height / 2, 25);
    bgFill.endFill();
    bgFill.x = bgTop.x - bgTop.width / 2;
    bgFill.y = bgTop.y;
    this.innerDimensions = new Phaser.Rectangle(bgFill.x, bgFill.y, bgFill.width, bgFill.height);

    card.addChild(bgFill);
    card.addChild(cardFill);
    card.addChild(border);

    const cardMask = new Phaser.Graphics(game);
    cardMask.beginFill(0, 0.5);
    cardMask.drawRoundedRect(bgTop.x - bgTop.width / 2, bgTop.y - bgTop.height / 2, card.width, card.height, 25);
    cardMask.endFill();

    card.addChild(cardMask);
    card.mask = cardMask;

    this.card = card;
    this.fill = cardFill;
    this.border = border;

    this.subFill = new Phaser.Group(G.game, this.fill);
  }

  /**
   * Sets its status. Used mostly for when drawing initially
   * @param {FRIENDSHIPCHESTCARD_STATUS} stat
   */
  setStatus(stat) {
    this.status = stat;
  }

  /**
   * Cleans the fill. Doesn't seem to be used...
   */
  cleanOutFill() {
    for (let i = 0; i < this.fill.children.length; i++) {
      this.fill.children[i].destroy();
    }
    this.fill.removeChildren();
  }

  /**
   * Fills the card with data from the FriendshipChest_DataManager and the prize table
   * @param {{i:number, s:number, pi:number}} cardData
   * @param {Object} prizeTable
   * @param {Object} tutorialOverride
   */
  fillCard(cardData, prizeTable, tutorialOverride) {
    const isEndCard = !cardData; // End cards come with null data...
    this.setStatus(isEndCard ? FRIENDSHIPCHESTCARD_STATUS.END : cardData.s);
    const friendshipChestText = this.drawFriendshipChestTitle(); // Draws the title

    const subTextText = OMT.language.getText('Invite Friends to %GameName% and earn rewards when they join! Your friend needs to click your message!');
    const subText = new G.Text(0, 0, subTextText, TEXTSTYLE_subText, 0.5, this.card.width * 0.9, 160, true, 'center');
    subText.y = friendshipChestText.y + (friendshipChestText.height + subText.height) / 2;

    const chestSafeY = OMT.feature.getFriendshipChestStory() ? 25 : 45;
    const chestImage = this.drawChestArea(this.index);
    this.chestImage = chestImage;
    chestImage.y = chestSafeY + subText.y + (subText.height + chestImage.renderedHeight) / 2;

    const chestSafeSpot = (chestImage.y + chestImage.renderedHeight / 2);
    const spaceLeft = (this.card.height - chestSafeSpot - this.inviteButtonHeight / 2) - this.card.height / 2; // The prize is displayed inbetween the chest and the button

    let prizeSection;
    const prizePadding = OMT.feature.getFriendshipChestStory() ? -10 : 0;
    if (isEndCard) {
      prizeSection = this.generateQuestionMark();
    } else {
      this.prizeIndex = Math.min(cardData.pi, prizeTable.length - 1);
      let targetPrize = prizeTable[this.prizeIndex];
      if (!targetPrize) { console.log('Invalid Prize!'); targetPrize = []; } // Somehow...
      this.prize = targetPrize;
      prizeSection = this.generatePrizeSection(targetPrize, TEXTSTYLE_amountText);
    }
    prizeSection.y = prizePadding + chestSafeSpot + (spaceLeft - prizeSection.height) / 2;
    this.subFill.addChild(friendshipChestText);
    this.subFill.addChild(subText);
    this.subFill.addChild(chestImage);
    this.subFill.addChild(prizeSection);
    this.fill.addChild(this.subFill);

    this.positionals.chest = chestImage.y;
    this.positionals.prizeSection = prizeSection;
    this.positionals.friendshipChestTitle = friendshipChestText.y;

    if (isEndCard) { // If end card
      this.drawEndCard();
      this.fill.cacheAsBitmap = true;
    } else { // Otherwise
      switch (cardData.s) { // What kind of card is it?
        case FRIENDSHIPCHESTCARD_STATUS.CLAIMED:
          this.drawClaimed();
          this.fill.cacheAsBitmap = true;
          break;
        case FRIENDSHIPCHESTCARD_STATUS.FRIEND:
          this.drawFriendCard(() => {
            this.drawButtonCard(FRIENDSHIPCHESTCARD_STATUS.UNCLAIMED);
            this.button.enabled = false;
            this.button.visible = false;
            this.subFill.alpha = 0;
            this.button.alpha = 0;
            this.fill.addChild(this.friend); // Add on top again
            this.signals.onCardReady.dispatch(this); // Dispatches onCardReady so Friendship_Chest.js can trigger animateOutFriend
          }, tutorialOverride);
          break;
        case FRIENDSHIPCHESTCARD_STATUS.PENDING:
          this.drawInvitationSent();
          this.fill.addChild(this.sent);
          this.subFill.cacheAsBitmap = true;
          break;
        case FRIENDSHIPCHESTCARD_STATUS.OPEN: // Otherwise!
        case FRIENDSHIPCHESTCARD_STATUS.FTUX:
        case FRIENDSHIPCHESTCARD_STATUS.UNCLAIMED:
          this.drawButtonCard(cardData.s);
          break;
        default: break;
      }
    }
  }

  /**
   * Draws the title. Gets drawn a lot...
   */
  drawFriendshipChestTitle() {
    return new G.Text(0, -this.card.height * 0.4, OMT.language.getText('Friendship Chest'), TEXTSTYLE_title, 0.5, this.card.width * 0.9, 80, true, 'center');
  }

  /**
   * Draws the chest area picture
   * Though if we're in story mode, it'll do some extra stuff
   * like polaroids and specific images etc
   * @param {number} index
   */
  drawChestArea(index) {
    const img = new Phaser.Group(game, null);
    let targetHeight = 300;
    let targetImage = 'friendship_chest_full';
    let targetY = 0;
    let chest;
    if (OMT.feature.getFriendshipChestStory() && this.status !== FRIENDSHIPCHESTCARD_STATUS.FTUX) { // Is story mode and not FTUX?
      if (this.status === FRIENDSHIPCHESTCARD_STATUS.END) { // Is end card?
        const polaroid = G.makeImage(0, 0, 'friendshipPolaroidBlk', 0.5, img);
        const num = G.makeImage(0, 0, 'friendshipNumber8', 0.5, img); // Add the heart
        num.y = polaroid.y + (polaroid.height / 2) - num.height * 0.90;
        num.x = polaroid.x + polaroid.width / 4;
        G.makeImage(0, -20, 'friendshipQuestion', 0.5, img);
        img.renderedHeight = polaroid.height;
        return img; // Return the image. No more fancy stuff!
      }
      const { storyChests } = G.OMTsettings.friendshipChest;
      targetImage = storyChests[Math.min(index, storyChests.length - 1)].asset;
      if (G.OMTsettings.friendshipChest.layoutData.polaroidImageOffsetY !== undefined) {
        targetY = G.OMTsettings.friendshipChest.layoutData.polaroidImageOffsetY;
      } else {
        targetY = -35;
      }
      let polaroid;
      if (G.OMTsettings.friendshipChest.polaroids) {
        const cappPoloroid = Math.max(0, Math.min(this.index, G.OMTsettings.friendshipChest.polaroids.length - 1));
        polaroid = G.makeExtImage(0, 0, G.OMTsettings.friendshipChest.polaroids[cappPoloroid], null, 0.5, img);
      } else {
        polaroid = G.makeImage(0, 0, 'friendshipPolaroidBg', 0.5, img); // Regular polaroid
      }
      const polaroidHeight = 320;
      polaroid.scale.set(polaroidHeight / polaroid.height);
      const cappedIndex = Math.min(this.index, (storyChests.length)); // Capped index to keep showing the extra garnish on the polaroid
      const num = G.makeImage(0, 0, `friendshipNumber${(cappedIndex + 1)}`, 0.5, img);
      num.y = polaroid.y + (polaroid.height / 2) - num.height * 0.90;
      num.x = polaroid.x + ((polaroid.width * (cappedIndex % 2 === 0 ? -1 : 1)) / 4); // Even index? Go to the left.

      chest = G.makeExtImage(0, targetY, targetImage, null, 0.5, img);
      img.renderedHeight = polaroid.height;
      targetHeight = 300;
    } else {
      chest = G.makeImage(0, targetY, targetImage, 0.5, img);
    }
    const scaleHeight = targetHeight / chest.height;
    chest.scale.set(scaleHeight, scaleHeight);
    if (!img.renderedHeight) {
      img.renderedHeight = chest.height;
    }
    return img;
  }

  /**
   * Draws the Claimed *checkmark* image
   */
  drawClaimed(visibleCheckMark = true) {
    const claimedGroup = new Phaser.Group(G.game, null);
    const claimedText = new G.Text(0, 0, OMT.language.getText('Claimed'), TEXTSTYLE_title, [0, 0.5], this.card.width * 0.9, (this.card.height / 2) - this.inviteButtonHeight, true, 'center');
    const checkMark = G.makeImage(10 + claimedText.width, 0, 'task_complete', [0, 0.5], null);
    checkMark.y -= checkMark.height / 4;
    checkMark.visible = visibleCheckMark; // Might want to hide it for animation

    claimedGroup.addChild(claimedText);
    claimedGroup.addChild(checkMark);
    claimedGroup.x -= claimedGroup.width / 2;
    claimedGroup.y = this.invitePosition;
    this.fill.addChild(claimedGroup);

    this.claimed = claimedGroup;
    this.claimed.checkMark = checkMark;
  }

  /**
   * Tweens in the checkmark. Such style!
   */
  animateClaimed() {
    const { checkMark } = this.claimed;
    const claimedMask = new Phaser.Graphics(game);
    claimedMask.beginFill(0, 1);
    claimedMask.drawCircle(0, 0, checkMark.width * 1.5);
    claimedMask.x = checkMark.x - claimedMask.width * 0.75;
    checkMark.mask = claimedMask;
    checkMark.visible = true;

    this.claimed.addChild(claimedMask);
    const tw = game.add.tween(claimedMask) // Mask slides in
      .to({ x: checkMark.x + claimedMask.width / 2 }, 2500, Phaser.Easing.Sinusoidal.InOut, true);
    tw.onComplete.addOnce(() => {
      checkMark.mask = null;
      claimedMask.destroy();
      this.fill.cacheAsBitmap = true; // Save redrawing
    });
  }

  /**
   * Draws the friend card in async. Requires await for the friend list data
   * @param {function} onComplete
   */
  async drawFriendCard(onComplete, tutorialOverride) {
    let targetFriend;
    if (tutorialOverride) {
      targetFriend = 'GINGY';
    } else {
      targetFriend = G.saveState.friendshipChestDataManager.getFriend(); // Grabs a friend in the pending array
    }
    this.friend = new Phaser.Group(G.game, null);
    if (targetFriend === undefined) { console.log('NO FRIENDS INVITED'); return; } // Cries

    // Makes a fake fill to tween away for the real card's fill
    const bg = new Phaser.Graphics(game);
    bg.beginFill(this.fillBackground);
    bg.drawRoundedRect(this.innerDimensions.x, this.innerDimensions.y, this.innerDimensions.width, this.innerDimensions.height, 25);
    bg.endFill();

    // Make these again... :'D
    const friendshipChestText = this.drawFriendshipChestTitle();

    const subTextText = OMT.language.getText('Invite Friends to %GameName% and earn rewards when they join! Your friend needs to click your message!');
    const subText = new G.Text(0, 0, subTextText, TEXTSTYLE_subText, 0.5, this.card.width * 0.9, 160, true, 'center');
    subText.y = friendshipChestText.y + (friendshipChestText.height + subText.height) / 2;

    const shine = G.makeImage(0, 0, 'reward_BG', 0.5, null);
    shine.y = subText.y + (subText.height + shine.height) / 2;

    let friendName;
    let friendImage;
    let friendText = '%Name% joined the game!';
    if (tutorialOverride) {
      friendName = tutorialOverride.name;
    } else {
      const friends = await OMT.friends.getFriendsList(); // Might need to not use cache since new friends
      const friendData = friends.filter((fr) => fr.id == targetFriend)[0]; // eslint-disable-line eqeqeq
      friendName = (friendData && friendData.name !== '') ? friendData.name : '';
      friendImage = (friendData && friendData.image) ? friendData.image : null;
    }

    if (friendName === '') {
      friendText = 'Your friend joined the game!';
    }

    const avatar = new Phaser.Group(game, null);
    let avatarImage;
    if (tutorialOverride) {
      avatarImage = tutorialOverride.image;
      avatar.addChild(avatarImage);
    } else if (!friendImage) {
      avatarImage = G.makeImage(0, 0, 'avatar_e', 0.5, avatar);
    } else {
      avatarImage = G.makeExtImage(0, 0, friendImage, 'avatar_e', 0.5, avatar, false, function manipulateThis() {
        this.width = this.height = 100;
      });
    }
    const frame = G.makeImage(0, 0, 'avatar_frame_big', 0.5, avatar);
    avatarImage.width = avatarImage.height = 100;
    frame.width = frame.height = avatarImage.width * 1.1;
    avatar.y = shine.y;

    const text = OMT.language.getText(friendText).replace('%Name%', friendName);
    const joinedTheGameText = new G.Text(0, -this.card.height * 0.4, text, TEXTSTYLE_title, 0.5, this.card.width * 0.85, 250, true, 'center');
    joinedTheGameText.y = this.invitePosition - joinedTheGameText.height / 4;

    this.friend.addChild(bg);
    this.friend.addChild(friendshipChestText);
    this.friend.addChild(subText);
    this.friend.addChild(shine);
    this.friend.addChild(avatar);
    this.friend.addChild(joinedTheGameText);

    // Friend animation. Is used in Friendship_Chest.js
    let pulseTween; // I need a reference to the beginning tween
    let lastTween; // I also need the last tweek
    for (let i = 0; i < 4; i++) { // Yoyo effects with chaining doesn't work very well
      const tw = game.add.tween(avatar.scale) // normal size
        .to({ x: 1, y: 1 }, 500, Phaser.Easing.Sinusoidal.In, false);
      const tw2 = game.add.tween(avatar.scale) // Big size
        .to({ x: 1.2, y: 1.2 }, 500, Phaser.Easing.Sinusoidal.Out, false);
      if (!lastTween) { // Get reference and chain
        pulseTween = tw;
        tw.chain(tw2);
      } else {
        lastTween.chain(tw, tw2); // Otherwise chain
      }
      lastTween = tw2;
    }
    const enterAnimation = game.add.tween(avatar.scale) // The enter animation, used when card is entering or tweening from tutotirla
      .to({ x: 1.2, y: 1.2 }, 500, Phaser.Easing.Sinusoidal.Out, false);
    avatar.scale.set(0.5);
    this.friend.animation = { // A reference for accessing outside
      enter: enterAnimation, // The enter tween
      exit: {
        start: () => { // Spoof the tween call! :'D
          if (enterAnimation.isRunning) { // Chain the pulse tween to start if enterAnimation is running
            enterAnimation.chain(pulseTween);
          } else {
            pulseTween.start(); // Otherwise just start it yourself
          }
        },
      },
    };

    if (onComplete) { onComplete(); }
  }

  /**
   * Draws a fake fill for the big red error screen. Fades away after a bit if nothing changed
   * @param {Object} friendInfo
   */
  drawErrorCard(friendInfo) {
    const bg = new Phaser.Graphics(game);
    bg.beginFill(this.fillBackground);
    bg.drawRoundedRect(this.innerDimensions.x, this.innerDimensions.y, this.innerDimensions.width, this.innerDimensions.height, 25);
    bg.endFill();

    const friendshipChestTitle = this.drawFriendshipChestTitle();

    const text = OMT.language.getText('Your friend %Name% already plays %GameName%...')
      .replace('%Name%', friendInfo.name);
    const errorText = new G.Text(0, 0, text, TEXTSTYLE_redText, 0.5, this.card.width * 0.7, 200, true, 'center');
    errorText.y = friendshipChestTitle.y + friendshipChestTitle.height + errorText.height / 2;

    const avatar = new Phaser.Group(G.game, null);
    let avatarImage;
    avatarImage = G.makeExtImage(0, 0, friendInfo.photo, 'avatar_e', 0.5, avatar, null, (image) => { // eslint-disable-line prefer-const
      image.width = image.height = 120; // Sometimes I don't have access to the image at this time!?
    });
    avatarImage.width = avatarImage.height = this.inviteButtonHeight * 1.2;
    const avatarFrame = G.makeImage(0, 0, 'avatar_frame', 0.5, avatar);
    avatarFrame.width = avatarFrame.height = avatarImage.height * 1.15;

    const tryInvitingNewFriend = new G.Text(0, 0, OMT.language.getText('Try inviting a new friend!'), TEXTSTYLE_amountText, 0.5, this.card.width * 0.8, 250, true, 'center');
    tryInvitingNewFriend.y = this.button.y - (this.button.height / 2) - tryInvitingNewFriend.height;

    const leftoverSpace = ((tryInvitingNewFriend.y - tryInvitingNewFriend.height / 2) - (errorText.y + errorText.height / 2)) / 2;
    avatar.y = errorText.y + errorText.height / 2 + leftoverSpace;

    // Save the button from destruction
    if (this.button.parent) {
      this.button.parent.removeChild(this.button);
    }
    if (this.error) { // Purged filled
      for (let i = 0; i < this.error.children.length; i++) {
        this.error.children[i].destroy();
      }
      this.error.removeChildren();
    } else { // Or make a new one
      this.error = new Phaser.Group(G.game, null);
    }

    this.error.addChild(bg);
    this.error.addChild(friendshipChestTitle);
    this.error.addChild(errorText);
    this.error.addChild(avatar);
    this.error.addChild(tryInvitingNewFriend);
    this.fill.addChild(this.error);
    this.fill.addChild(this.button); // Add the button

    if (this.error && this.error.fadeOut) {
      this.error.fadeOut.stop();
      this.error.fadeOut = null;
    }
    if (this.nextCard || this.prevCard) { // If the card is still active (otherwise the links around it is gone)
      this.animateOutError(500);
    }
  }

  /**
   * Draws the invitation sent message but doesn't add it
   */
  drawInvitationSent(customAvatar) {
    const set = new Phaser.Group(G.game, null);
    const waitingText = new G.Text(0, 0, OMT.language.getText('Waiting for friend to join the game'), 'font-blue', 0.5, this.card.width * 0.8, 50, true, 'center');
    const friendInviteSent = new Phaser.Group(G.game, null);
    const friendImage = new Phaser.Group(G.game, friendInviteSent);
    let friendAvatar;
    if (customAvatar) {
      friendAvatar = customAvatar;
      friendImage.addChild(friendAvatar);
    } else {
      friendAvatar = G.makeImage(0, 0, 'avatar_e', 0.5, friendImage);
    }
    const friendFrame = G.makeImage(0, 0, 'avatar_frame', 0.5, friendImage);
    friendAvatar.height = friendAvatar.width = 60;
    friendFrame.height = friendFrame.width = friendAvatar.height * 1.15;
    friendImage.x = -this.card.width * 0.35;
    friendImage.y = -5;
    const friendInviteBg = new Phaser.Graphics(game);
    friendInviteBg.beginFill(G.OMTsettings.friendshipChest.layoutData.messageHardBackground);
    friendInviteBg.drawRect(0, 0, this.card.width * 0.95, 100);
    friendInviteBg.endFill();
    friendInviteBg.x -= friendInviteBg.width / 2;
    friendInviteBg.y -= friendInviteBg.height / 2;
    const friendInviteText = new G.Text(0, 0, OMT.language.getText('Friend invitation sent!'), 'font-white', 0.5, this.card.width * 0.7, 100, true, 'center');
    friendInviteText.x = friendImage.x + friendImage.width / 2 + (this.card.width * 0.7) / 2;
    friendInviteSent.addChild(friendInviteBg);
    friendInviteSent.addChild(friendImage);
    friendInviteSent.addChild(friendInviteText);

    friendInviteSent.y = (this.card.height / 2) - friendInviteSent.height / 2;
    waitingText.y = friendInviteSent.y - (friendInviteSent.height + waitingText.height) / 2;

    set.addChild(waitingText);
    set.addChild(friendInviteSent);

    const blinkTime = 3000;
    const pulse = game.time.events.loop(blinkTime, () => { // Instance of the event so it can be removed when the card is removed
      if (this.fill.visible) {
        game.add.tween(waitingText)
          .to({ alpha: 0.2 }, blinkTime / 2, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
      }
    }, this);

    set.pulse = pulse;
    this.sent = set;
  }

  /**
   * Draws the last bit of the end card
   */
  drawEndCard() {
    const chestTextText = OMT.language.getText('Keep inviting new friends to play %GameName% and unlock new Friendship Chests');
    const chestText = new G.Text(0, 0, chestTextText, TEXTSTYLE_subText, 0.5, this.card.width * 0.9, (this.card.height / 2) - this.inviteButtonHeight, true, 'center');
    chestText.y = this.invitePosition;
    this.fill.addChild(chestText);
    this.fill.cacheAsBitmap = true;
  }

  /**
   * Draws the button card depending on the assumed status the button will be in when it is seen
   * @param {FRIENDSHIPCHESTCARD_STATUS} assumedStatus
   */
  drawButtonCard(assumedStatus) {
    const targetButton = BUTTONCOLOURS.green;
    //  const isWithinLimit = G.saveState.friendshipChestDataManager.getCardCount() <= G.OMTsettings.friendshipChest.maxCards;
    //  if (!isWithinLimit) {
    //    targetButton = 'medium_button_gray';
    //  }
    const isClaimCard = assumedStatus === FRIENDSHIPCHESTCARD_STATUS.UNCLAIMED; // Is claim?

    const inviteButton = new Phaser.Group(G.game, null);
    this.button = inviteButton;
    if (assumedStatus !== FRIENDSHIPCHESTCARD_STATUS.FTUX) { // Don't draw the button if FTUX
      const inviteButtonImage = new OMT_UI_SquareButton(0, 0, {
        button: {
          tint: targetButton,
          dimensions: {
            width: 445,
            height: this.inviteButtonHeight,
          },
          isEnabled: isClaimCard ? true : OMT.feature.getFeatureFriendshipChest(),
        },
        options: {
          cacheButton: false,
        },
      });
      this.button.buttonState = inviteButtonImage.currentState;
      const targetText = isClaimCard ? OMT.language.getText('Claim') : OMT.language.getText('Invite friends!');
      const inviteText = new G.Text(0, 0, targetText, 'font-white', 0.5, inviteButtonImage.width, inviteButtonImage.height, true, 'center');
      inviteButtonImage.addChild(inviteText);

      if (!isClaimCard) {
        inviteText.x = -inviteButtonImage.width * 0.05; // Offset text if not a claim card
        const inviteAvatar = new Phaser.Group(G.game, null); // Draw the avatar too
        const avatar = this.drawRotatingAvatar(this.inviteButtonHeight * 0.9);
        inviteAvatar.addChild(avatar);
        const frame = G.makeImage(0, 0, 'avatar_frame', 0.5, inviteAvatar);
        frame.height = frame.width = avatar.width * 1.15;
        inviteAvatar.x = (inviteButtonImage.width - inviteAvatar.width) / 2;
        inviteAvatar.y = -2;
        inviteButtonImage.addChild(inviteAvatar);
      }
      inviteButton.addChild(inviteButtonImage);
    }

    inviteButton.y = this.invitePosition;

    this.fill.addChild(inviteButton);
    this.button.buttonMode = isClaimCard ? FRIENDSHIPCHEST_BUTTONMODE.CLAIM : FRIENDSHIPCHEST_BUTTONMODE.INVITE; // FTUX doesnt have user interactibility so it should be ok!
    this.button.enabled = false;
    this.setListenerOnCard();
    this.fill.cacheAsBitmap = null;
  }

  /**
   * Draws the [?] questionmark and returns
   */
  generateQuestionMark() {
    const prizeSection = new Phaser.Group(G.game, null);
    const iconDimensions = { // Barebones rect
      x: 0,
      y: 0,
      width: 75,
      height: 75,
    };

    const container = new Phaser.Group(G.game, null);
    const blankBox = new Phaser.Graphics(game);
    blankBox.beginFill(0xffa200);
    blankBox.drawRoundedRect(-iconDimensions.width / 2, -iconDimensions.height / 2, iconDimensions.width, iconDimensions.height, 15);
    blankBox.endFill();

    const text = new G.Text(0, 1, '?', TEXTSTYLE_questionMark, 0.5, iconDimensions.height, iconDimensions.width);

    container.addChild(blankBox);
    container.addChild(text);
    prizeSection.addChild(container);

    return prizeSection;
  }

  /**
   * Draws the prize section of a card and does its layout.
   * Also used in the claim screen
   * @param {Array<Object>} targetPrize
   * @param {Object} amountTextStyle
   */
  generatePrizeSection(targetPrize, amountTextStyle) {
    const givenWidth = this.card.width * 0.8;
    const iconDimensions = {
      x: 0,
      y: 0,
      width: 75,
      height: 75,
    };
    const prizeSection = OMT_UI_DrawUtil.drawDynamicPrizes({
      iconDimension: iconDimensions,
      width: givenWidth,
      prizes: targetPrize,
      textStyle: amountTextStyle,
    });
    return prizeSection;
  }

  /**
   * Sets the card to Claim status
   */
  setToClaimed() {
    if (this.button) { // Destroys the button. No longer needed
      this.fill.remove(this.button);
      this.button.destroy();
      this.button = null;
    }
    this.setStatus(FRIENDSHIPCHESTCARD_STATUS.CLAIMED);
    this.drawClaimed(false);
  }

  /**
   * Big function that does the rotating avatar between avatar_m and avatar_f
   * Taken from Request Help
   * @param {number} size
   */
  drawRotatingAvatar(size) {
    const avatar = new Phaser.Group(G.Game, null);
    const avatarBelow = new Phaser.Graphics(game);
    avatarBelow.beginFill(0xbd5728);
    avatarBelow.drawRect(0, 0, 1, 1);
    avatarBelow.endFill();
    const avatarA = G.makeImage(0, 0, 'avatar_m', 0.5);
    const avatarB = G.makeImage(0, 0, 'avatar_f', 0.5);
    let avatarCounter = 0;
    const avatarList = ['avatar_f', 'avatar_m'];
    const avatarScale = size;
    avatar.addChild(avatarBelow);
    avatar.addChild(avatarB);
    avatar.addChild(avatarA);
    avatarBelow.height = avatarA.height = avatarB.height = avatarScale;
    avatarBelow.width = avatarA.width = avatarB.width = avatarScale;
    avatarBelow.x = avatarBelow.y = -avatarBelow.height / 2;
    let avatarChangeDelay;
    // eslint-disable-next-line prefer-const
    avatarChangeDelay = game.time.events.repeat(3000, Infinity, () => { // Changes every 3 seconds
      if (avatar.game) { // This is similar to Window_Level.rh_changeAvatar()
        if (!this.fill.visible) { return; }
        const nextTexture = avatarList[avatarCounter % avatarList.length];
        avatarB.changeTexture(nextTexture);
        avatarB.width = avatarB.height = avatarBelow.height;
        avatarB.alpha = 1;
        avatar.addChildAt(avatarB, 1);
        const disappear = game.add.tween(avatarA)
          .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.In);

        disappear.onComplete.addOnce(() => {
          avatarA.changeTexture(nextTexture);
          avatarA.width = avatarA.height = avatarBelow.height;
          avatarA.alpha = 1;
          avatarB.alpha = 0;
        });

        disappear.start();
      } else {
        game.time.events.remove(avatarChangeDelay); // Removes itself if the game isn't there anymore
      }
      avatarCounter++;
    }, this);

    return avatar;
  }

  /**
   * Fills the card, not normally like a friendship chest card, but as a promo
   * Contains info that gives an incentive to use friendship chest
   */
  fillCardAsPromo() {
    this.setStatus(FRIENDSHIPCHESTCARD_STATUS.PROMO);
    // Start of Promo drawing
    const friendshipChestText = new G.Text(0, -this.card.height * 0.38, OMT.language.getText('Friendship Chest'), TEXTSTYLE_title, 0.5, this.card.width * 0.92, 250, false, 'center', false, true);
    this.fill.addChild(friendshipChestText);

    const chest = new Phaser.Group(G.game, this.fill);
    const bottomHalf = G.makeImage(0, 0, 'friendship_chest_bottom', 0.5, chest);
    bottomHalf.width = 128;
    bottomHalf.height = 93;
    const shine = G.makeImage(0, -35, 'reward_BG', 0.5, null);
    const openedLid = G.makeImage(0, -40, 'friendship_chest_top', 0.5, chest);
    openedLid.width = 130;
    openedLid.height = 79;

    //  openedLid.angle = -5;
    shine.height = shine.width = chest.height * 2;
    chest.addChild(shine);
    chest.addChild(openedLid);

    const avatarAndArrow = new Phaser.Group(G.game, this.fill);
    const bg = G.makeImage(0, 0, 'daily_prize_panel_bg', 0.5, avatarAndArrow);
    bg.width = this.card.width * 0.8;
    const arrow = G.makeImage(0, 0, 'beaten_green_arrow', [0.5, 1], avatarAndArrow);
    arrow.angle = 180;
    arrow.scale.set(1.5, 1.5);
    const drawAvatar = (avatarURL, size) => {
      const avatarContainer = new Phaser.Group(G.game, null);
      const avatar = G.makeImage(0, 0, avatarURL, 0.5, avatarContainer);
      const avatarFrame = G.makeImage(0, 0, 'avatar_frame', 0.5, avatarContainer);
      avatar.width = avatar.height = size;
      avatarFrame.width = avatarFrame.height = avatar.width * 1.15;

      return avatarContainer;
    };
    const avatarF = drawAvatar('avatar_f', bg.height * 0.6);
    const avatarM = drawAvatar('avatar_m', bg.height * 0.6);
    avatarF.x = avatarF.width - (bg.width / 2);
    avatarM.x = (bg.width / 2) - avatarM.width;
    const plus = G.makeImage(0, 0, 'btn_plus', 0.5, avatarAndArrow);
    plus.width = plus.height = bg.height * 0.8;
    arrow.y = bg.height - arrow.height + plus.height * 0.1;
    avatarAndArrow.addChild(avatarF);
    avatarAndArrow.addChild(avatarM);
    avatarAndArrow.y = friendshipChestText.y + (avatarAndArrow.height / 2) + friendshipChestText.height * 0.3;
    chest.y = avatarAndArrow.y + avatarAndArrow.height * 1.25;

    this.signals.onRejectClick = new Phaser.Signal();
    const redButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.red,
        dimensions: {
          width: 166,
          height: 86,
        },
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText('No, thanks'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onButtonReject.bind(this),
        },
      },
    });
    redButton.y = -20 + (this.card.height - redButton.height) / 2;

    const greenButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: this.inviteButtonHeight,
        },
      },
      text: {
        string: OMT.language.getText('I want rewards!'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onButtonClick.bind(this),
        },
      },
    });
    greenButton.y = redButton.y - (redButton.height + greenButton.height) / 2;

    const leftoverSpace = ((greenButton.y - greenButton.height / 2) - (chest.y + chest.height / 2)) * 2;
    const subTextText = OMT.language.getText('Invite new friends to %GameName% and earn rewards when they join!');
    const subText = new G.Text(0, 0, subTextText, TEXTSTYLE_subText, 0.5, this.card.width * 0.92, leftoverSpace, true, 'center');
    subText.y = chest.y + chest.height / 2 - subText.height / 4;

    const boosterInfo = [ // Relative to being in shine. Added last to avoid messing around with layouting
      /* eslint-disable object-curly-newline */
      { img: 'ui_booster_1', x: -170, y: -140, scale: 1 },
      { img: 'ui_booster_8', x: -185, y: 10, scale: 1.45 },
      { img: 'ui_booster_3', x: 195, y: -85, scale: 1.2 },
      { img: 'ui_booster_7', x: 190, y: 50, scale: 1.35 },
      /* eslint-enable object-curly-newline */
    ];
    for (let i = 0; i < boosterInfo.length; i++) {
      const info = boosterInfo[i];
      const booster = G.makeImage(info.x, info.y, info.img, 0.5, shine);
      booster.scale.set(info.scale, info.scale);
    }

    this.fill.addChild(subText);
    this.fill.addChild(greenButton);
    this.fill.addChild(redButton);
  }

  fillCardAsStoryPromo() {
    this.setStatus(FRIENDSHIPCHESTCARD_STATUS.PROMO);
    // Start of Promo drawing
    const friendshipChestText = new G.Text(0, -this.card.height * 0.38, OMT.language.getText('Friendship Chest'), TEXTSTYLE_title, 0.5, this.card.width * 0.92, 250, false, 'center', false, true);
    this.fill.addChild(friendshipChestText);

    const chest = G.makeImage(0, 0, 'friendshipChestPromo', 0.5, this.fill);
    const chestScale = 220 / chest.height;
    chest.scale.set(chestScale);

    const avatarAndArrow = new Phaser.Group(G.game, this.fill);
    const bg = G.makeImage(0, 0, 'daily_prize_panel_bg', 0.5, avatarAndArrow);
    bg.width = this.card.width * 0.8;
    const arrow = G.makeImage(0, 0, 'beaten_green_arrow', [0.5, 1], avatarAndArrow);
    arrow.angle = 180;
    arrow.scale.set(1.5, 1.5);
    const drawAvatar = (avatarURL, size) => {
      const avatarContainer = new Phaser.Group(G.game, null);
      const avatar = G.makeImage(0, 0, avatarURL, 0.5, avatarContainer);
      const avatarFrame = G.makeImage(0, 0, 'avatar_frame', 0.5, avatarContainer);
      avatar.width = avatar.height = size;
      avatarFrame.width = avatarFrame.height = avatar.width * 1.15;

      return avatarContainer;
    };
    const avatarF = drawAvatar('friendshipSpike', bg.height * 0.6);
    const avatarM = drawAvatar('friendshipGingy', bg.height * 0.6);
    avatarF.x = avatarF.width - (bg.width / 2);
    avatarM.x = (bg.width / 2) - avatarM.width;
    const plus = G.makeImage(0, 0, 'btn_plus', 0.5, avatarAndArrow);
    plus.width = plus.height = bg.height * 0.8;
    arrow.y = bg.height - arrow.height + plus.height * 0.1;
    avatarAndArrow.addChild(avatarF);
    avatarAndArrow.addChild(avatarM);
    avatarAndArrow.y = friendshipChestText.y + (avatarAndArrow.height / 2) + friendshipChestText.height * 0.3;
    chest.y = avatarAndArrow.y + avatarAndArrow.height;

    this.signals.onRejectClick = new Phaser.Signal();
    const redButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.red,
        dimensions: {
          width: 166,
          height: 86,
        },
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText('No, thanks'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onButtonReject.bind(this),
        },
      },
    });
    redButton.y = -20 + (this.card.height - redButton.height) / 2;

    const greenButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: this.inviteButtonHeight,
        },
      },
      text: {
        string: OMT.language.getText('I want rewards!'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onButtonClick.bind(this),
        },
      },
    });
    greenButton.y = redButton.y - (redButton.height + greenButton.height) / 2;

    const leftoverSpace = ((greenButton.y - greenButton.height / 2) - (chest.y + chest.height / 2)) * 2;
    const subTextText = OMT.language.getText('Invite new friends to %GameName% and earn rewards when they join!');
    const subText = new G.Text(0, 0, subTextText, TEXTSTYLE_subText, 0.5, this.card.width * 0.92, leftoverSpace, true, 'center');
    subText.y = (greenButton.y - greenButton.height / 2) - leftoverSpace / 4;

    this.fill.addChild(subText);
    this.fill.addChild(greenButton);
    this.fill.addChild(redButton);
  }

  /**
   * Animates out the button and tweens in the sent banner
   */
  animateInSentBanner() {
    this.fill.cacheAsBitmap = null;
    this.fill.addChild(this.sent);
    const oriBannerY = this.sent.y;
    const targetButtonY = this.border.y + this.border.height + this.button.height;
    // Apply
    this.sent.alpha = 0;
    this.sent.y = oriBannerY + this.sent.height;

    const step1 = game.add.tween(this.button)
      .to({ alpha: 0, y: targetButtonY }, 200, Phaser.Easing.Sinusoidal.In);
    step1.onComplete.add(() => {
      if (this.error) { this.animateOutError(200); } // If the error card is showing, hide it immediately!!
      this.button.destroy();
      this.button = null;
    });
    const step2 = game.add.tween(this.sent)
      .to({ alpha: 1, y: oriBannerY }, 200, Phaser.Easing.Sinusoidal.In);

    step1.chain(step2);

    step1.start();
  }

  /**
   * Lots of checks because it could be possible that an earlier call may have
   * @param {number} tweenTime
   */
  animateOutError(tweenTime) {
    if (this.error) {
      this.error.alpha = 1;
      this.error.fadeOut = game.add.tween(this.error)
        .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, false, 5000);
      this.error.fadeOut.onComplete.add(() => {
        if (this.error) {
          this.error.visible = false;
          this.error.destroy();
          this.error = null;
        }
      });
      this.error.fadeOut.start();
    }
  }

  /**
   * Animates out the friend screen and showing the claim card. Called from Friendship_Chest.js
   * @param {function} onComplete
   */
  animateOutFriend(onComplete) {
    this.friend.alpha = 1;
    const outTween = game.add.tween(this.friend)
      .to({ alpha: 0 }, 1000, Phaser.Easing.Sinusoidal.In);
    outTween.onStart.addOnce(() => {
      game.add.tween(this.subFill)
        .to({ alpha: 1 }, 1000, Phaser.Easing.Sinusoidal.In, true);
      this.button.visible = true;
      game.add.tween(this.button)
        .to({ alpha: 1 }, 1000, Phaser.Easing.Sinusoidal.In, true);
    });
    outTween.onComplete.addOnce(() => {
      this.button.enabled = true;
      this.friend.visible = false;
      this.friend.destroy();
      this.friend = null; // Button should be drawn already
      if (onComplete) { onComplete(); }
    });
    outTween.start();
  }

  /**
   * The part where you watch your friend join the game
   * when technically they can't...
   * @returns {Phaser.Tween}
   */
  animateTutorialFriend() {
    const tweenTime = 1000;

    const oriBannerY = (this.card.height / 2) - this.sent.height / 2;
    const polaroidOriY = this.chestImage.y;
    const polaroidPeakY = this.chestImage.y - this.chestImage.height / 4;

    const fadeOut = game.add.tween(this.fill) // To be returned for onComplete in the tutorial. THIS IS THE LAST STEP IN THE TWEEN
      .to({ alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, false);

    game.add.tween(this.sent) // Sent banner leaves
      .to({ y: oriBannerY }, tweenTime, Phaser.Easing.Sinusoidal.In, true);
    game.add.tween(this.chestImage) // Chest Image bounces away
      .to({ y: [polaroidPeakY, polaroidOriY] }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
    game.add.tween(this.chestImage.scale)
      .to({ x: 0.1, y: 0.1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
    game.time.events.add(tweenTime / 2, () => {
      fadeOut.start(); // LAST STEP HAPPENS HERE
    });

    return fadeOut;
  }

  /**
   * Draws the assets required for the story chest animation
   */
  readyStoryChest() {
    if (this.storyChestPrev) {
      this.subFill.removechild(this.storyChestPrev);
      this.storyChestPrev.destroy();
      this.storyChestPrev = null;
    }

    const rays = G.makeImage(0, 0, 'shine_godrays', 0.5, this.rays);
    const rayScale = (game.height * 1.5) / rays.height;
    rays.scale.set(rayScale);
    this.rays.alpha = 0;
    this.rays.update = () => {
      if (game && this.rays.alpha > 0 && this.rays.visible) {
        rays.angle += 0.5;
      }
    };
    this.rays.ray = rays;

    this.storyChestPrev = new Phaser.Group(game, this.subFill);
    G.makeImage(0, 0, 'friendshipPolaroidBlk', 0.5, this.storyChestPrev);
    this.storyChestPrev.x = this.chestImage.x;
    this.storyChestPrev.y = this.chestImage.y;
    this.chestImage.alpha = 0;
  }

  /**
   * Animates the story chest transition to a new card
   * @returns {Phaser.Tween}
   */
  animateStoryChest() {
    const tweenTime = 500;

    const rtrTween = game.add.tween(this.rays) // This is for returning to the tutorial for onComplete. This is the LAST STEP
      .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, false, tweenTime); // Is delayed by tweenTime
    rtrTween.onComplete.addOnce(() => { // Clean up
      this.rays.ray.destroy();
      this.rays.ray = null;
      this.rays.removeChildren();
    });

    game.time.events.add(tweenTime, () => { // Pauses for 500ms
      const prevChest = game.add.tween(this.storyChestPrev) // Black polaroid fades away
        .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      game.add.tween(this.chestImage) // New image fades in
        .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      game.add.tween(this.rays) // Rays fade in
        .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      prevChest.chain(rtrTween); // Rays fade out when black polaroid fades. LAST STEP HAPPENS AT THE END OF HERE

      prevChest.onComplete.addOnce(() => { // Clean up
        this.subFill.removeChild(this.storyChestPrev);
        this.storyChestPrev.destroy();
        this.storyChestPrev = null;
      });
    });

    return rtrTween;
  }

  /**
   * Sets listeners for the one button on the card.
   */
  setListenerOnCard() {
    if (!this.button) { return; }
    if (!this.button.onChildInputDown.has(this.onButtonClick, this)) {
      this.button.onChildInputDown.add(this.onButtonClick, this);
      this.button.enabled = true;
    }
  }

  /**
   * Sends the card itself
   */
  onButtonClick() {
    this.signals.onClick.dispatch(this);
  }

  /**
   * Only for the Promo card. Sends a reject signal.
   */
  onButtonReject() {
    if (this.signals.onRejectClick) {
      this.signals.onRejectClick.dispatch(this);
    }
  }
}
