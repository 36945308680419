import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../../States/Scaling/GameScaleController';
import OMT_UI_DrawUtil from '../../../../OMT_UI/Drawing/OMT_UI_DrawUtil';

const HEADER_HEIGHT = 120;
const CLOSE_BUTTON_OFFSET = { x: -40, y: -10 };
const GRADIENT_POLYGON_Y_DIFF = -25;
const HEADER_TEXT_STYLE = { style: 'ui-tournament-promo', fontSize: 54 }; // this can be override by child classes
const HEADER_TEXT_BG_PADDING = { horizontal: 50, vertical: 20 };
const HEADER_TEXT_BG_SLICE_DATA = {
  left: 15, right: 15, top: 15, bottom: 22,
};

/**
 * Generic class for Game Mode Promotion Header
 */
export class GameModePromo_HeaderBase extends Phaser.Group {
  /**
   * constructor
   */
  constructor(textKey, bgAsset, headerTextStyle) {
    super(game);

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this.onCloseClicked = new Phaser.Signal();

    this._headerWidth = game.width;
    this._headerHeight = HEADER_HEIGHT;

    this._drawGradientBG();
    this._drawHeaderText(textKey, bgAsset, headerTextStyle || HEADER_TEXT_STYLE);
    this._initCloseButton();

    if (this._isLandscape) {
      this.scale.setTo(this._gameScale);
    }
  }

  /**
   * draw the gradient background image
   */
  _drawGradientBG() {
    const gradPack = OMT_UI_DrawUtil.createGradientTexture(
      G.OMTsettings.tournamentPromo.bannerGradient,
      game, '', { width: this._headerWidth, height: this._headerHeight },
      'linear', [ // gradient config
        { x: this._headerWidth / 2, y: 0 },
        { x: this._headerWidth / 2, y: this._headerHeight },
      ],
    );

    gradPack.bitmap.polygon([ // draws polygon shape
      { x: 0, y: 0 },
      { x: game.width, y: 0 },
      { x: game.width, y: gradPack.bitmap.height + GRADIENT_POLYGON_Y_DIFF },
      { x: 0, y: gradPack.bitmap.height },
      { x: 0, y: 0 },
    ], gradPack.gradient);

    const gradientBg = new Phaser.Image(game, 0, 0, gradPack.bitmap);
    gradientBg.x = -this._headerWidth / 2;
    gradientBg.y = -gradientBg.height / 2;
    this.addChild(gradientBg);

    this._gradPack = gradPack;
    this._gradientBg = gradientBg;
  }

  /**
   * draw header text + text bg
   * @param {string} textKey
   * @param {string} bgAsset
   * @param {Object} headerTextStyle
   */
  _drawHeaderText(textKey, bgAsset, headerTextStyle) {
    const headerTxt = new G.Text(0, 0, OMT.language.getText(textKey), headerTextStyle, 0.5);
    const headerTxtBgWidth = headerTxt.width + HEADER_TEXT_BG_PADDING.horizontal;
    const headerTxtBgHeight = headerTxt.height + HEADER_TEXT_BG_PADDING.vertical;
    const headerTxtBG = new PhaserNineSlice.NineSlice(
      0, 0, 'popups', bgAsset, headerTxtBgWidth, headerTxtBgHeight, HEADER_TEXT_BG_SLICE_DATA,
    );
    headerTxtBG.x -= headerTxtBG.width / 2; headerTxtBG.y -= headerTxtBG.height / 2;
    this.addChild(headerTxtBG);
    this.addChild(headerTxt);
  }

  /**
   * intialize the close button text
   */
  _initCloseButton() {
    const closeTxt = G.makeImage(0, 0, 'white_x', 0.5, this);
    closeTxt.inputEnabled = true;
    closeTxt.events.onInputDown.add(() => {
      this.onCloseClicked.dispatch();
    });
    closeTxt.x = (this._headerWidth / 2) + CLOSE_BUTTON_OFFSET.x;
    closeTxt.y = CLOSE_BUTTON_OFFSET.y;
    this._closeTxt = closeTxt;
  }

  /**
   * detruction method
   */
  destroy() {
    this.onCloseClicked.dispose();
    // destroy bitmapdata generated by OMT_UI_DrawUtil.createGradientTexture
    if (this._gradPack) this._gradPack.bitmap.destroy();
    super.destroy();
  }

  /**
   * resize content
   */
  resize() {
    this._headerWidth = this._isLandscape ? game.width / this._gameScale : game.width;
    // close button position
    this._closeTxt.x = (this._headerWidth / 2) + CLOSE_BUTTON_OFFSET.x;
    // background box sizing
    this._gradientBg.width = this._headerWidth;
    this._gradientBg.x = -this._headerWidth / 2;
  }
}
