import { CollapsedUserViewBase } from '../../Components/CollapsedView/CollapsedUserViewBase';
import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

import {
  LIFE_PANEL_COLLAPSED_USER_VIEW_RECT,
  LIFE_PANEL_COLLAPSED_USERNAME_POS,
  LIFE_PANEL_COLLAPSED_USERNAME_MAX_WIDTH,
  LIFE_PANEL_COLLAPSED_USERNAME_STYLE,
  LIFE_PANEL_COLLAPSED_AVATAR_SIZE,
  LIFE_PANEL_COLLAPSED_AVATAR_POS,
  LIFE_PANEL_COLLAPSED_ICON_SCALE,
  LIFE_PANEL_COLLAPSED_ICON_POS,
  LIFE_SEND_COOLDOWN,
} from '../../MainLeaderboardSettings';

export const LIFE_REQUEST_STATUS = {
  REQUESTED: 0,
  SUCCESS: 1,
  FAILED: 2,
};

/**
 * collapsed user view for friends highscores and requesting coins
 */
export class LifeRequestCollapsedUserView extends CollapsedUserViewBase {
  /**
   * main initialization method
   * @param {Object} friendData friendData to display
   */
  init(friendData) {
    // create signals
    this.signals.onLifeRequest = new Phaser.Signal();

    // init user view displays
    super.init(friendData, LIFE_PANEL_COLLAPSED_USER_VIEW_RECT);
    this._initNameField();
    this._initSendLifeIcon();
    this._initSendLifeButton();

    // set cooldown variables
    this._setCoolDownParamsAndUpdate(LIFE_SEND_COOLDOWN.id, LIFE_SEND_COOLDOWN.duration);

    // cache content container
    this.cacheContent = true;
  }

  /**
   *initialize the users avatar
   */
  _initAvatar() {
    super._initAvatar(0, 0, LIFE_PANEL_COLLAPSED_AVATAR_SIZE);
    this._avatar.x = LIFE_PANEL_COLLAPSED_AVATAR_POS.x;
    this._avatar.y = LIFE_PANEL_COLLAPSED_AVATAR_POS.y;
  }

  /**
   * init the send life button
   */
  _initSendLifeButton() {
    const sendLifeButton = new UI_GButtonES6(0, 0, null, () => { this._onLifeRequestClicked(); });
    sendLifeButton.createClickShape(LIFE_PANEL_COLLAPSED_AVATAR_SIZE, LIFE_PANEL_COLLAPSED_AVATAR_SIZE);
    sendLifeButton.x = LIFE_PANEL_COLLAPSED_AVATAR_POS.x;
    sendLifeButton.y = LIFE_PANEL_COLLAPSED_AVATAR_POS.y;
    this.addChild(sendLifeButton);
    this._sendLifeButton = sendLifeButton;
  }

  /**
   * add the sned life icon to the avatar group
   */
  _initSendLifeIcon() {
    this._sendLifeIcon = G.makeImage(0, 0, 'lb2_send_lives_button', [0.5, 0.5]);
    this._sendLifeIcon.x = LIFE_PANEL_COLLAPSED_ICON_POS.x;
    this._sendLifeIcon.y = LIFE_PANEL_COLLAPSED_ICON_POS.y;
    this._sendLifeIcon.scale.set(LIFE_PANEL_COLLAPSED_ICON_SCALE);
    this.addChild(this._sendLifeIcon);
  }

  /**
   * updae the send life icon texture
   */
  _updateSendLifeIconTexture() {
    G.changeTexture(this._sendLifeIcon, !this._isOnCooldown ? 'lb2_send_lives_button' : 'lb2_send_lives_button_inactive');
    super.update();
  }

  /**
   * initialize the user name field
   */
  _initNameField() {
    this._nameField = new G.Text(
      LIFE_PANEL_COLLAPSED_USERNAME_POS.x,
      LIFE_PANEL_COLLAPSED_USERNAME_POS.y,
      this._shortenName(this._friendData.name),
      LIFE_PANEL_COLLAPSED_USERNAME_STYLE,
      [0.5, 0.5], // anchor
      LIFE_PANEL_COLLAPSED_USERNAME_MAX_WIDTH,
    );
    this._cacheableContent.addChild(this._nameField);
  }

  /**
   * called the send life / avatar button group is clicked
   * @returns {Promise}
   */
  async _onLifeRequestClicked() {
    if (this._isOnCooldown) return;
    this.signals.onLifeRequest.dispatch(this, LIFE_REQUEST_STATUS.REQUESTED);
    OMT.social.sendLifeMessage(this._friendData, (result) => {
      if (result) {
        G.saveState.setUserCooldown(this._cooldownId, this._friendData.userId, this._cooldownDuration);
        this._setCooldownState();
        this.signals.onLifeRequest.dispatch(this, LIFE_REQUEST_STATUS.SUCCESS);
      } else {
        this.signals.onLifeRequest.dispatch(this, LIFE_REQUEST_STATUS.FAILED);
      }
    });
  }

  /**
   * set the active / normal state
   */
  _setActiveState() {
    super._setActiveState();
    this._updateSendLifeIconTexture();
    this._sendLifeButton.enable();
  }

  /**
   * set the cooldown state
   */
  _setCooldownState() {
    super._setCooldownState();
    this._updateSendLifeIconTexture();
    this._sendLifeButton.disable();
  }

  /**
   * main update method
   */
  update() {
    super.update();
    this._updateCooldownState();
  }
}
