import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';

export default class UI_ExtraMovesBuyButton extends Phaser.Group {
  static willItShow() {
    const lvlDataManager = LvlDataManager.getInstance();
    if (lvlDataManager.goalAchieved) return false;
    if (!G.featureUnlock.extraMovesBubble) return false;

    const config = G.json.settings.extraMovesBubble;
    if (lvlDataManager.moves === config.showAtMoveNumber) return true;

    return false;
  }

  /**
   * This is the extra moves buy button. The one with the booster and the button to buy with a cost
   * Not to be confused with helper 2.0
   * This is the older one
   */
  constructor() {
    super(game);

    const config = G.json.settings.extraMovesBubble;

    this.targetY = 0;

    this.state = game.state.getCurrentState();
    this._lvlDataManager = LvlDataManager.getInstance();
    this._gameHooks = this._lvlDataManager.gameHooks;

    this.hl = G.makeImage(0, 0, 'popup_lighht', 0.5, this);
    this.hl.alpha = 0.2;
    this.hl.scale.setTo(0.6);
    this.hl.blendMode = 1;

    this.floating = { offset: -10 };
    this._tweenObj = game.add.tween(this.floating).to({ offset: 10 }, 700, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

    this.btn = new G.Button(0, 0, 'ui_booster_5', this.btnClick, this);

    this.btn.sfx = 'cash_register';
    this.btn.addTerm(() => this._lvlDataManager.moves < 5);
    this.add(this.btn);

    this.labelBg = G.makeImage(0, 45, 'move_extra_label', 0.5, this);

    this._signalBindings = [];
    this._signalBindings.push(G.sb('madeMove').add(() => {
      if (UI_ExtraMovesBuyButton.willItShow()) {
        this.show();
      }
    }, this));


    this._signalBindings.push(G.sb('onWindowOpened').add(() => {
      this.hide();
    }, this));

    this._signalBindings.push(G.sb('onWindowClosed').add(() => {
      if (!this.visible) {
        if (config.active
          && G.saveState.getCoins() >= this._lvlDataManager.getPriceOfExtraMovesForBubble()
          && this._lvlDataManager.moves <= config.showAtMoveNumber
          && this._lvlDataManager.moves > 0) {
          this.show();
        }
      }
    }, this));

    this.scale.setTo(0);
    this.visible = false;

    this._signalBindings.push(G.sb('onGoalAchieved').add(this.hide, this));
  }

  destroy() {
    for (const binding of this._signalBindings) binding.detach();
    this._signalBindings.length = 0;
    if (this._tweenObj) {
      this._tweenObj.stop();
      this._tweenObj = null;
    }
    super.destroy();
  }

  update() {
    this.y = this.targetY + this.floating.offset;
    this.hl.angle += G.deltaTime;
  }

  btnClick() {
    if (G.saveState.getCoins() >= this._lvlDataManager.getPriceOfExtraMovesForBubble()) {
      const wp = this.worldPosition;
      for (let i = 0; i < 4; i++) {
        G.sb('UIfx').dispatch(wp.x + game.world.bounds.x, wp.y, 'whiteStarPart');
      }

      this._lvlDataManager.buyExtraMoves();

      this.hide();
    } else {
      G.Helpers.pushOutOfCoinsPopUp(true, { event: OMT.platformTracking.Events.OpenShopMoves });

      this._signalBindings.push(G.sb('onAllWindowsClosed').addOnce(() => {
        // purchase failed
        if (this._lvlDataManager.moves < 5) {
          this.show();
        }
      }, this));
    }
  }

  show() {
    // dont show when no money and !incentivised
    if (!game.incentivised() && !G.IAP && G.saveState.getCoins() < this._lvlDataManager.getPriceOfExtraMovesForBubble()) return;

    if (this.priceTxt) this.priceTxt.destroy();

    this.priceTxt = new G.LabelTextT(
      // eslint-disable-next-line prefer-template
      '$+5 moves$ ' + OMT.language.toLocaleNumber(this._lvlDataManager.getPriceOfExtraMovesForBubble()) + '@coin_1@',
      5, 45, {
        style: 'font-white',
        fontSize: '30px',
      }, 0.5, 180,
    );
    this.add(this.priceTxt);
    this.priceTxt.addOffsetYToText(-10);

    this.visible = true;
    G.stopTweens(this);
    this.scale.setTo(0);
    game.add.tween(this.scale).to({ x: 1, y: 1 }, 2000, Phaser.Easing.Elastic.Out, true);
  }

  hide() {
    G.stopTweens(this);
    game.add.tween(this.scale).to({ x: 0, y: 0 }, 400, Phaser.Easing.Cubic.Out, true).onComplete.add(() => {
      this.visible = false;
    }, this);
  }
}
