/* eslint-disable import/no-unresolved */

import {
  syncFriendListByPlatformPlayerId as GBC_syncFriendListByPlatformPlayerId,
} from '@sgorg/game-backend-client';

import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

/**
 *  class for handling data related to SoftGames leaderboards
 */
export class OMT_Friends {
  /**
   * constructor
   * @param {number} dataExpiry expiry time in seconds
   * @param {Object} cache OMT_DataCache instance
   */
  constructor(dataExpiry, cache) {
    this._dataExpiry = dataExpiry;
    this._cache = cache;
  }

  /**
   * call to intialize friends list / sync to the game-backend
   */
  async init() {
    await this.getFriendsList();
  }


  /**
   * sync the users connected players with the game-backend
   */
  async _syncConnectedPlayersToGB(FBfriendsDataList) {
    const idList = [];
    for (let i = 0; i < FBfriendsDataList.length; i++) {
      idList.push(FBfriendsDataList[i].getID());
    }
    await GBC_syncFriendListByPlatformPlayerId({ friendList: idList });
  }

  /**
   * get data for your friends playing this game
   * @returns {Promise<Array>}
   */
  async _getFBConnectedPlayers() {
    let FBfriendsDataList;
    try {
      FBfriendsDataList = await FBInstant.player.getConnectedPlayersAsync();
    } catch (error) {
      OMT_Utils.stylizedLog('OMT_Friends: !!ERROR could not get friends from Facebook', '#FFFF00');
      FBfriendsDataList = [];
    }
    return FBfriendsDataList;
  }

  /**
   * get the users friends list
   * @param {boolean} useCache
   * @returns {Promise<Array>}
   */
  async getFriendsList(useCache = true) {
    const cacheKey = 'friendsMapPositions';
    if (useCache && !this._cache.isDataExpired(cacheKey, this._dataExpiry)) return this._cache.getData(cacheKey);

    this._cache.clearAllData(); // clear the cache for friends + leaderboards as we have a new friend list

    // sync connected users to the game-backend
    const FBfriendsDataList = await this._getFBConnectedPlayers();
    try {
      await this._syncConnectedPlayersToGB(FBfriendsDataList);
    } catch (error) {
      OMT_Utils.stylizedLog('OMT_Friends: !!ERROR could not sync friends list to game-backend', '#FFFF00');
    }

    const friendsList = [];
    let friendData;

    for (let i = 0; i < FBfriendsDataList.length; i++) {
      friendData = {
        id: FBfriendsDataList[i].getID(), // for compatibility set both id and userId
        userId: FBfriendsDataList[i].getID(),
        image: FBfriendsDataList[i].getPhoto(),
        name: FBfriendsDataList[i].getName(),
      };
      friendsList.push(friendData);
    }
    // set cache object
    this._cache.setData(cacheKey, friendsList);
    // update map friend positions
    await this._updateFriendMapPositions();

    OMT_Utils.stylizedLog('OMT_Friends: friendsList updated');
    return friendsList;
  }

  /**
   * update data for friends map positions
   * @returns {Promise}
   */
  async _updateFriendMapPositions() {
    const friendsList = await this.getFriendsList();
    const mapPositions = {};
    let entriesArray;

    // get leaderboard data
    try {
      entriesArray = await OMT.leaderboards.getFriendMapPositionEntries();
    } catch (error) {
      entriesArray = [];
    }

    // translate score to max level
    let entry;
    for (let i = 0; i < entriesArray.length; i++) {
      entry = entriesArray[i];
      mapPositions[entry.id] = {
        maxLevel: Math.min(G.json.levels.order.length, entry.score + 1),
      };
    }
    // apply max level to friends list
    let friendData;
    for (let i = 0; i < friendsList.length; i++) {
      friendData = friendsList[i];
      if (mapPositions[friendData.id]) {
        friendData.maxLevel = mapPositions[friendData.id].maxLevel;
      } else {
        friendData.maxLevel = 1;
      }
    }
  }

  /**
   * get a users avatar url
   * @param {string} userId
   * @returns {Promise<string>}
   */
  async getUserAvatarURL(userId) {
    const friendsList = await this.getFriendsList();
    let friendData;
    for (let i = 0; i < friendsList.length; i++) {
      friendData = friendsList[i];
      if (userId === friendData.id) {
        return friendData.image;
      }
    }
    return null;
  }
}
