import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import TreasureHuntManager from '../../Services/OMT/dataTracking/treasureHuntManager/treasureHuntManager';
import { OMT_AssetLoader } from '../../Services/OMT/OMT_AssetLoader';

export default class Window_TreasureHuntPreEvent extends Window {
  /**
   * PreEvent window that shows up only once?
   * Shows you what the treasure hunt level is
   * @param {Object} parent
   */
  constructor(parent) {
    super(parent);

    this._container = new Phaser.Group(game, this);
    this._drawBg();
    this._drawContents();
    this._drawContinue();
  }

  /**
   * Draws background and title, and close button
   */
  _drawBg() {
    this._bg = G.makeImage(0, 0, 'popup_background_treasureHunt', 0.5, this._container);

    this._titleHeader = new Phaser.Group(game, this._container);
    G.makeImage(0, -265, 'treasureHunt_banner', 0.5, this._titleHeader);

    // Title
    const mascot = G.saveState.treasureHuntManager.currentMascot;
    const mascotName = TreasureHuntManager.getMascotName(mascot);
    this._titleTxt = new G.Text(0, -267, OMT.language.getText(`${mascotName}'s Treasure Hunt`), 'treasureHunt-headerTitleText', 0.5, 330, 125, true, 'center');
    this._titleHeader.add(this._titleTxt);

    this._closeBtn = new G.Button(235, -257, 'btn_x', this._onClosePressed.bind(this));
    this._titleHeader.add(this._closeBtn);
  }

  /**
   * Draws the bigger contents. The stuff inside the window
   */
  _drawContents() {
    this._page = new Phaser.Group(game, this);

    const title = new G.Text(0, -100, OMT.language.getText('Collect Treasure Hunt Tokens to get amazing gifts!'), 'treasureHunt-hintWindowTitle', 0.5, this._bg.width * 0.8, null, true, 'center');
    this._page.addChild(title);

    const padding = 4;
    const layoutDetails = {
      totalWidth: 0,
      alignment: [],
    };
    this._showChests(layoutDetails, padding);
    this._addBadge(layoutDetails, padding);
    this._arrangeVisuals(layoutDetails, padding);
  }

  /**
   * Shows whats in the chest. The stuff in layoutDetails is used outside of this function in arranging visuals
   * @param {{totalWidth:number, alignment:Array<Phaser.DisplayObject>}} layoutDetails
   * @param {number} padding
   */
  _showChests(layoutDetails, padding) {
    for (let i = 0; i < G.OMTsettings.treasureHuntSuper.reward.currencyUnlock.length; i++) {
      const chest = this._drawChestCombo(i);
      layoutDetails.totalWidth += chest.width;
      layoutDetails.alignment.push(chest);

      if ((i + 1) < G.OMTsettings.treasureHuntSuper.reward.currencyUnlock.length) {
        const arrow = G.makeImage(0, 0, 'treasureHunt_yellowArrow', 0.5, this._page);
        layoutDetails.totalWidth += arrow.width + padding * 2;
        layoutDetails.alignment.push(arrow);
      }
    }
  }

  /**
   * Adds the badge portion of the prelevel
   */
  _addBadge() {
    const mascot = G.saveState.treasureHuntManager.currentMascot;
    const mascotName = TreasureHuntManager.getMascotName(mascot);
    const targetBadge = `treasureHuntGold${mascotName}`;
    const badgeSize = 100;
    this._extraText = new G.Text(0, 180, OMT.language.getText('Complete the Treasure Hunt to win Special Badges'), 'treasureHunt-hintWindowSubtitle', 0.5, this._bg.width * 0.8);
    this._page.addChild(this._extraText);
    let badgeAsset;
    if (OMT_AssetLoader.getInstance().areSecondaryImagesLoaded(['treasureHunt'])) {
      badgeAsset = G.makeImage(0, 240, `${targetBadge}Full`, 0.5, this._page);
    } else {
      badgeAsset = G.makeExtImage(0, 240, `${targetBadge}Full`, targetBadge, 0.5, this._page, null, (asset) => {
        asset.scale.set(badgeSize / asset.width);
      });
    }
    badgeAsset.scale.set(badgeSize / badgeAsset.width);
  }

  /**
   * Lays out everything in horizontal alignment
   * @param {{totalWidth:number, alignment:Array<Phaser.DisplayObject>}} layoutDetails
   * @param {number} padding
   */
  _arrangeVisuals(layoutDetails, padding) {
    let nextX = -(padding * 2) + ((layoutDetails.alignment[0].width / 4) - layoutDetails.totalWidth) / 2;
    for (const obj of layoutDetails.alignment) {
      obj.x = Math.round(nextX + padding + obj.width / 2);
      if (this._extraText) {
        obj.y = 60;
      } else {
        obj.y = 90;
      }
      nextX += obj.width + padding;
    }
  }

  /**
   * Draws a chest with its value under it
   * @param {number} chestNum
   * @returns {Phaser.Group}
   */
  _drawChestCombo(chestNum) {
    const tokenNumber = G.OMTsettings.treasureHuntSuper.reward.currencyUnlock[chestNum];
    const group = new Phaser.Group(game, this._page);
    const offsetGroup = new Phaser.Group(game, group);
    G.makeImage(0, 0, `treasureHunt_chestMedium${chestNum}`, [0.5, 1], offsetGroup); // chest

    const coinGroup = new Phaser.Group(game, offsetGroup);
    const coinBg = G.makeImage(0, 0, 'treasureHunt_winNumberBg', 0.5, coinGroup);
    coinBg.scale.set(100 / coinBg.width);
    const img = G.makeImage(0, 0, 'treasureHunt_token', 0.5, coinGroup);
    img.scale.set((coinBg.height * 1.1) / img.height);
    img.x = coinBg.x - (coinBg.width - img.width) / 2;
    const leftoverSpace = coinBg.width - img.width;
    const text = new G.Text(0, 3, tokenNumber, {
      style: 'treasureHunt-levelWindow',
      fontSize: coinBg.height * 0.9,
    }, 0.5, leftoverSpace);
    text.x = coinBg.x + img.width / 2;
    coinGroup.addChild(text);
    coinGroup.y = 30;

    offsetGroup.y = 50;
    return group;
  }

  /**
   * Draws the continue button
   */
  _drawContinue() {
    this._continueButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 220,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Play'),
        textStyle: {
          style: 'font-white',
          fontSize: 70,
        },
        dimensionMods: {
          width: 0.9,
          height: 0.9,
        },
      },
      options: {
        clickFunction: {
          onClick: this._sendScore.bind(this),
          disableAfterClick: true,
        },
        cacheButton: false,
      },
    });
    this._continueButton.y = this._bg.y + this._bg.height / 2;
    this._container.addChild(this._continueButton);
    this._continueButton.visible = true;
  }

  /**
   * Closes the window
   */
  _onClosePressed() {
    this.closeWindow();
  }

  /**
   * Sends score, sets flags, then closes the window
   */
  async _sendScore() {
    await G.saveState.treasureHuntManager.syncTokens(0);
    TreasureHuntManager.trackDDNAStats();
    TreasureHuntManager.openTreasureHuntPopup();
    G.saveState.treasureHuntManager.setContentEntrySeen(); // Save call in here
    this.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.treasureHuntPreEvent = Window_TreasureHuntPreEvent;
