import { Window } from '../../../00_IMMEDIATE/Window';
import { OMT_UI_InviteMoreFriendsButton } from '../../../OMT_UI/Buttons/OMT_UI_InviteMoreFriendsButton';
import { GateFriendListView } from './GateFriendListView';
import { friendInviteFeedback } from '../../Helpers/friendInviteFeedback';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import OMT_UI_DrawUtil from '../../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import { TextTimer } from '../../TextTimer';
import { MILLISECONDS_IN_SEC } from '@omt-components/Utils/TimeUtil';
import { GATE_COOLDOWN_KEY, GATE_OPEN_REASON } from '../../../Services/OMT/dataTracking/gateManager/GateManager';

const COOLDOWN_ID = 'gateNewAskFriend';

class Window_GateNew extends Window {
  /**
   * @param {Phaser.DisplayObject} parent
   * @param {Object} config
   * @param {{gateLevel:number, starReq:number, coinReq?:number, timeReq?:number, inviteReq?:number}} config.gateObject
   */
  constructor(parent, config) {
    super(parent);

    this.config = config || {};
    const { gateObject } = config;

    // right now its just hardcoded from the sizes of the images this was using before
    let offsetY = 0;
    if (G.OMTsettings.elements.Window_Gate && G.OMTsettings.elements.Window_Gate.offset) {
      offsetY = G.OMTsettings.elements.Window_Gate.offset.y || 0;
    }
    this.bg = this.addGeneric9SliceBackground(550, 660);
    this.bg.y -= G.OMTsettings.elements.windowNineSlice.whiteSpace.top / 2;
    this.bg.y += offsetY;

    // this.bg = G.makeImage(0, -340, 'popup_bg_top', [0.5, 0], this);
    // this.bgBottom = G.makeImage(0, -150, 'popup_bg_bottom', [0.5, 0], this);


    this.closeButton = new G.Button(260, -300, 'btn_x', function clicky() {
      this.closeWindow();
    }, this);
    this.registerButtons(this.closeButton);

    this.title = this.addTitleTimer(0, -295, gateObject);
    this.title.scale.set(0.85);
    this.innerContent = new Phaser.Group(G.Game, this);
    const innerMask = new Phaser.Graphics(game);
    innerMask.beginFill(0);
    innerMask.drawRect(0, 0, 430, 510);
    innerMask.endFill();
    innerMask.x -= innerMask.width / 2;
    innerMask.y = innerMask.y - (innerMask.height / 2) + 20;
    this.innerContent.addChild(innerMask);
    this._maskBoundings = innerMask.getBounds().clone();
    this.innerContent.mask = innerMask;

    this.pageOne = new Phaser.Group(G.Game, this.innerContent);
    this.createPageOne(gateObject);

    this.pageTwo = new Phaser.Group(G.Game, this.innerContent);
    this.pageTwo.visible = false;
    this.createPageTwo(gateObject); // Not required to wait for this

    if (config.hasFriends) {
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.GatePopUpWithFriendOpened);
    }
  }

  /**
   * @param {{gateLevel:number, starReq:number, coinReq?:number, timeReq?:number, inviteReq?:number}} gateObject
   */
  createPageOne(gateObject) {
    const padding = 10;
    this.orUnlockNow = this.pageOne.addChild(new G.Text(0, -185, OMT.language.getText('Or unlock Now'), {
      style: 'font-darkBlue',
      fontSize: '35px',
    }, 0.5, 430));

    this.stars = this.addStarsPanel(0, 0, gateObject.starReq);
    this.stars.y = this.orUnlockNow.y + padding + (this.orUnlockNow.height + this.stars.height) / 2;

    this.orTwo = this.pageOne.addChild(new G.Text(0, 0, OMT.language.getText('or'), {
      style: 'font-darkBlue',
      fontSize: '35px',
    }, 0.5, 430));
    this.orTwo.y = this.stars.y + padding + (this.stars.height + this.orTwo.height) / 2;

    this.coins = this.addCoinsPanel(0, 0, gateObject);
    this.coins.y = this.orTwo.y + padding + (this.orTwo.height + this.coins.height) / 2;

    this.orThree = this.pageOne.addChild(new G.Text(0, 0, OMT.language.getText('or'), {
      style: 'font-darkBlue',
      fontSize: '35px',
    }, 0.5, 430));
    this.orThree.y = this.coins.y + padding + (this.coins.height + this.orThree.height) / 2;

    this.friend = this.addFriendAsks(gateObject);
    this.friend.y = this.orThree.y + padding + (this.orThree.height + this.friend.height) / 2;
  }

  /**
   * @param {number} x
   * @param {number} y
   * @param {{gateLevel:number, starReq:number, coinReq?:number, timeReq?:number, inviteReq?:number}} gateObject
   */
  addTitleTimer(x, y, gateObject) {
    const group = new Phaser.Group(G.Game, this);
    group.position.setTo(x, y);

    group.timerBg = G.makeImage(0, 20, 'timer_box', 0.5, group);

    group.timer = new TextTimer({
      x: 0,
      y: 24,
      date: 0,
      style: {
        style: 'font-white',
        fontSize: '40px',
      },
      anchor: 0.5,
      maxWidth: 400,
    });

    const timeLeft = Math.ceil(G.saveState.getUserCooldownRemaining(`${GATE_COOLDOWN_KEY}${gateObject.gateLevel}`, '') / MILLISECONDS_IN_SEC);
    group.timer.setSecLeft(timeLeft);
    group.timer.start();
    group.add(group.timer);

    group.title = new G.Text(0, -20, OMT.language.getText('New levels in'), {
      style: 'font-white',
      fontSize: '40px',
    }, 0.5, 400);
    group.add(group.title);

    return group;
  }

  addStarsPanel(x, y, starReq) {
    const allStars = G.saveState.getAllStars();

    const group = new Phaser.Group(G.Game, this.pageOne);
    group.position.setTo(x, y);
    group.bg = G.makeImage(0, 0, 'options_box', 0.5, group);

    group.collectMoreStars = group.add(new G.Text(105, 0,
      OMT.language.getText('Collect more stars!'), {
        style: 'font-darkBlue',
        fontSize: '30px',
        lineSpacing: -20,
      }, 0.5, 150, 80, true, 'center'));

    group.starIcon = G.makeImage(-147, -3, 'star', 0.5, group);
    group.starIcon.scale.setTo(0.6);

    group.starsAmount = group.add(new G.Text(-100, 0,
      allStars + '/' + starReq, 'window-starReq', [0, 0.5], 130)); // eslint-disable-line prefer-template

    return group;
  }

  /**
   *
   * @param {number} x
   * @param {number} y
   * @param {{gateLevel:number, starReq:number, coinReq?:number, timeReq?:number, inviteReq?:number}} gateObject
   */
  addCoinsPanel(x, y, gateObject) {
    const group = new Phaser.Group(G.Game, this.pageOne);
    group.position.setTo(x, y);
    group.bg = G.makeImage(0, 0, 'options_box', 0.5, group);

    group.coinIcon = G.makeImage(-145, 0, 'coin_3', 0.5, group);
    group.coinIcon.scale.setTo(0.6);

    group.amount = group.add(new G.Text(-100, 0, 'x' + gateObject.coinReq, { // eslint-disable-line prefer-template
      style: 'font-darkBlue',
      fontSize: 40,
    }, [0, 0.5], 130));

    const btnContainer = new Phaser.Group(G.game, null);
    const clicky2 = () => {
      if (G.saveState.gateManager.hasGateBeenOpened(gateObject.gateLevel)) { return; }
      const price = gateObject.coinReq;

      if (G.saveState.isEnoughToBuy(price)) {
        // DDNA Tracking
        // Transaction
        // DDNA.transactionHelper.trackRewards([], [], {
        //   transactionType: 'COIN_PURCHASE', tActionType: 'GATE', tGameArea: 'MAP', tCoinsUsed: price,
        // });

        // Transaction logging
        OMT.transactionTracking.logInventoryTransactionBegin();
        OMT.transactionTracking.addInventoryChange('coins', 0, -price);
        OMT.transactionTracking.logInventoryTransactionEnd();

        G.saveState.changeCoins(-price);
        if (this.config.onOpenCallback) {
          this.config.onOpenCallback(gateObject.gateLevel, GATE_OPEN_REASON.coins);
        }
        this.closeWindow();
      } else {
        if (G.Helpers.pushOutOfCoinsPopUp(true, { // eslint-disable-line no-lonely-if
          event: OMT.platformTracking.Events.OpenShopGate,
        })) {
          G.sb('pushWindow').dispatch(['gateNew', this.config]);
          this.closeWindow();
        } else {
          group.buyBtn.alpha = 0.5;
          group.buyBtn.button.inputEnabled = false;
        }
      }
    };
    const btn = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 149,
          height: 77,
        },
      },
      options: {
        clickFunction: {
          onClick: clicky2.bind(this),
          disableAfterClick: true,
        },
      },
    });
    btnContainer.addChild(btn);
    btnContainer.button = btn;
    btnContainer.x = 105;
    group.buyBtn = btnContainer;
    group.add(btnContainer);

    if (G.saveState.gateManager.hasGateBeenOpened(gateObject.gateLevel)) {
      group.buyBtn.alpha = 0.5;
      group.buyBtn.button.inputEnabled = false;
    }

    group.buyBtnLabel = new G.LabelTextT(
      '$Open$@coin_1@', 2, -5, {
        style: 'font-white',
        fontSize: '40px',
      }, 0.5, Math.floor(btn.width * 0.9),
    );
    btn.addChild(group.buyBtnLabel);

    return group;
  }

  /**
   * @param {{gateLevel:number, starReq:number, coinReq?:number, timeReq?:number, inviteReq?:number}} gateObject
   */
  addFriendAsks(gateObject) {
    const group = new Phaser.Group(G.Game, this.pageOne);
    const bg = G.makeImage(0, 0, 'options_box', 0.5, group);

    const avatarHeight = bg.height * 0.8;
    const avatar = new Phaser.Group(G.Game, bg); // Avatar group base for now
    const avatarBelow = new Phaser.Graphics(game); // The background of the Avatars
    avatarBelow.beginFill(0xbd5728);
    avatarBelow.drawRect(0, 0, avatarHeight, avatarHeight);
    avatarBelow.endFill();
    avatarBelow.x = -avatarBelow.height / 2;
    avatarBelow.y = -avatarBelow.height / 2;
    avatar.addChild(avatarBelow);
    const avatarA = G.makeImage(0, 0, 'avatar_m', 0.5, avatar);
    const avatarB = G.makeImage(0, 0, 'avatar_f', 0.5, avatar);
    const avatarFrame = G.makeImage(0, 0, 'avatar_frame', 0.5, bg);
    avatarFrame.width = avatarBelow.height * 1.15;
    avatarFrame.height = avatarBelow.height * 1.15;
    avatarB.alpha = 0;

    const doTheThing = async () => {
      const potentialFriends = await OMT.friends.getFriendsList(true); // thread gets stalled here for this
      for (let i = potentialFriends.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = potentialFriends[i];
        potentialFriends[i] = potentialFriends[j];
        potentialFriends[j] = temp;
      }
      let avatarCounter = 0;
      let avatarList;
      const isSolo = potentialFriends.length === 0;
      if (!isSolo) {
        avatarList = potentialFriends.map((a) => a.image); // grabs avatar urls if not solo
      } else {
        avatarList = ['avatar_f', 'avatar_m'];
      }
      avatarA.width = avatarHeight;
      avatarA.height = avatarHeight;
      avatarB.width = avatarHeight;
      avatarB.height = avatarHeight;

      const avatarRotation = () => {
        if (!group.parent.visible) { return; }
        const nextAvatarUrl = avatarList[avatarCounter % avatarList.length];
        if (isSolo) {
          OMT_UI_DrawUtil.rotateAvatar(avatarA, avatarB, avatar, nextAvatarUrl, avatarHeight);
        } else {
          G.getExtTexture(nextAvatarUrl, (textureName) => { OMT_UI_DrawUtil.rotateAvatar(avatarA, avatarB, avatar, textureName, avatarHeight); });
        }
        avatarCounter++;
      };
      let avatarChangeDelay;
      avatarChangeDelay = game.time.events.repeat(3000, Infinity, () => { /* eslint-disable-line prefer-const */ // Rotates avatar every 3s
        if (avatar.game) {
          avatarRotation();
        } else {
          game.time.events.remove(avatarChangeDelay);
        }
      }, this);
      // Try to immediately change or load to the first friend
      avatarRotation();
    };
    doTheThing(); // Keep going

    avatarFrame.x = bg.x - (bg.width / 2) + avatarBelow.width;
    avatar.x = bg.x - (bg.width / 2) + avatarBelow.width;

    const click1 = () => {
      if (G.saveState.gateManager.hasGateBeenOpened(gateObject.gateLevel)) {
        return;
      }
      this.askFriendClick();
    };
    const btn = new OMT_UI_SquareButton(this.coins.buyBtn.x, 0, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 149,
          height: 77,
        },
      },
      text: {
        string: OMT.language.getText('Ask'),
        textStyle: { style: 'font-white', fontSize: 40 },
      },
      options: {
        clickFunction: {
          onClick: click1.bind(this),
        },
        cacheButton: false,
      },
    });
    group.addChild(btn);

    const midpoint = (btn.x + avatar.x) / 2;

    const texty = new G.Text(0, 0,
      `x${gateObject.inviteReq}`, {
        style: 'font-darkBlue',
        fontSize: 40,
        lineSpacing: -20,
      }, 0.5, 200, 200, true, 'center');
    texty.x = midpoint - texty.width / 2;

    group.addChild(texty);

    return group;
  }

  async createPageTwo() {
    const title = new G.Text(0, 0, OMT.language.getText('Ask a friend to unlock'), { style: 'font-darkBlue', fontSize: 36 }, 0.5, 430, 430, true, 'center');
    const subTitle = new G.Text(0, 0, OMT.language.getText('Your friend needs to click your message to unlock the gate.'), { style: 'font-darkBlue', fontSize: 18 }, 0.5, 430, 430, true, 'center');
    title.y = this.bg.y + G.OMTsettings.elements.windowNineSlice.inner.top - (this.bg.height - title.height) / 2;
    subTitle.y = title.y + (title.height + subTitle.height) / 2;

    const inviteButton = new OMT_UI_InviteMoreFriendsButton(() => { this.inviteFriends(); });
    inviteButton.scale.set(0.9, 0.9);

    const backButton = new G.Button(0, 0, null);
    backButton.onClick.add(this.backClick, this);

    const backText = new G.Text(0, 0, `<< ${OMT.language.getText('Back')}`, { style: 'font-darkBlue', fontSize: 18 }, 0.5, 430, 430, true, 'center');
    const underline = new Phaser.Graphics(game);
    underline.lineStyle(2, 0x006A8F);
    underline.lineTo(backText.width, 0);
    underline.y = backText.y - 5 + backText.height / 2;
    underline.x -= underline.width / 2;
    backButton.addChild(backText);
    backButton.addChild(underline);

    backButton.y = this._maskBoundings.y + (this._maskBoundings.height / 2);
    inviteButton.y = backButton.y - (backButton.height + inviteButton.height + 5);

    const friendListBoundings = new Phaser.Rectangle(-215, subTitle.y + 10 + subTitle.height / 2, Math.max(430, inviteButton.y + (subTitle.y + subTitle.height / 2)), 225);
    this.friendsListView = new GateFriendListView(60, friendListBoundings, 600000, COOLDOWN_ID);
    this.friendsListView.signals.onClick.add(this.onFriendAsk, this);

    this.pageTwo.addChild(title);
    this.pageTwo.addChild(subTitle);
    this.pageTwo.addChild(this.friendsListView);
    this.pageTwo.addChild(inviteButton);
    this.pageTwo.addChild(backButton);
  }

  async onFriendAsk(friendItem) {
    this.friendsListView.disable();
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.GatePopUpTapChallengeFriend);

    const result = await OMT.social.requestGateMsg(friendItem.friendId, this.config.gateObject.gateLevel);
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.GatePopUpSwitchContextSuccess);
    if (result) {
      friendItem.origin.setOnCooldown();
      G.saveState.trackSentGateInvite(this.config.gateObject.gateLevel);
    } else {
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.GatePopUpSwitchContextFail);
    }
    this.friendsListView.enable();
  }

  /**
   * Event that happens when the Invite Friends button is pressed
   */
  async inviteFriends() {
    let result = false;

    result = await OMT.social.chooseContextAndInvite(); // Waiting for status...

    if (result) {
      // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('usedGateUnlockInviteBtn', 1);
      // DDNA.socialTracker.incrementParam('numFriendsInvites', 1);
      // DDNA.tracking.socialActionEvent('INVITE', 'UNLOCK_GATE', '', '');
      G.saveState.gateManager.increaseInvitesOutForGate(this.config.gateObject.gateLevel);

      const feedback = new friendInviteFeedback(G.game, null); // eslint-disable-line new-cap
      feedback.x = game.world.bounds.x + game.width / 2;
      feedback.y = feedback.height / 2;
      game.world.addChild(feedback);

      feedback.enterAndSelfDestroy();
    }
  }

  askFriendClick() {
    this.tweenPages(true);
  }

  backClick() {
    this.tweenPages(false);
  }

  tweenPages(openPageTwo) {
    const showPosition = 0;
    const awayPosition = showPosition - 430;
    const incomingPosition = showPosition + 430;
    const tweenTime = 200;
    if (openPageTwo) {
      this.pageOne.x = showPosition;
      this.pageOne.alpha = 1;
      this.pageTwo.x = incomingPosition;
      this.pageTwo.alpha = 0;
      this.pageTwo.visible = true;

      // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenGateUnlockInviteBtn', 1);

      const p1 = game.add.tween(this.pageOne)
        .to({ x: awayPosition, alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, false);
      p1.onComplete.add(() => {
        this.pageOne.visible = false;
      });
      const p2 = game.add.tween(this.pageTwo)
        .to({ x: showPosition, alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, false);

      p1.start();
      p2.start();
    } else { // Closing page 2
      this.pageOne.x = awayPosition;
      this.pageOne.alpha = 0;
      this.pageOne.visible = true;
      this.pageTwo.x = showPosition;
      this.pageTwo.alpha = 1;
      const p1 = game.add.tween(this.pageOne)
        .to({ x: showPosition, alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, false);
      const p2 = game.add.tween(this.pageTwo)
        .to({ x: incomingPosition, alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, false);
      p2.onComplete.add(() => {
        this.pageTwo.visible = false;
      });

      p1.start();
      p2.start();
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.gateNew = Window_GateNew;
