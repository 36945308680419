import { ORIENTATION } from '../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../States/Scaling/GameScaleController';
import { TextTimer } from './TextTimer';

export default class MysteryGiftHeader extends Phaser.Group {
  constructor(peak, textContent) {
    super(game, null);
    this.fixedToCamera = true;
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    let txt;
    if (textContent !== undefined) {
      txt = OMT.language.getText(textContent);
    } else if (G.saveState.mysteryGiftManager.getCurrentStreak() >= 3) {
      txt = OMT.language.getText('Continue winning with one try to keep your boosters!');
    } else if (G.saveState.mysteryGiftManager.getCurrentStreak() < 3 && peak) {
      txt = OMT.language.getText('Win this level now and get a cool mystery gift!');
    } else {
      txt = OMT.language.getText('Finish with one try');
    }

    // no peak, and no streak so far, so there is no risk
    if (!peak && G.saveState.mysteryGiftManager.getCurrentStreak() === 0) {
      this.visible = false;
    }

    this.msg = new Phaser.Group(game, this);
    this.msg.name = 'message';
    this.msg.y = -200;


    this.bg = G.makeImage(0, 0, 'First_try_bar', [0.5, 0], this.msg);
    this.bg.width = 640;

    this.text = new G.Text(0, 37, txt, {
      style: 'window-blue',
      fontSize: '30px',
      lineSpacing: -5,
    }, 0.5, 425, 75, true, 'center');
    this.msg.add(this.text);

    // Add timer
    const style = {
      style: G.saveState.mysteryGiftManager.getRemainingActiveTime() > 0 ? 'mysteryGift-timer-yellow' : 'mysteryGift-timer-red',
      fontSize: '24px',
    };
    this.timer = new TextTimer({
      x: 300,
      y: 72,
      style,
      anchor: [1, 1],
      maxWidth: 120,
      timerFormat: 'ms',
    });
    this.msg.add(this.timer);

    const secLeft = Math.floor(G.saveState.mysteryGiftManager.getRemainingActiveTime() / 1000);
    this.timer.setSecLeft(secLeft);
    this.timer.active = true;

    G.Utils.cleanUpOnDestroy(this, G.sb('onScreenResize').add(this.onResize, this));

    if (this._isLandscape) {
      this.scale.setTo(this._gameScale);
    }

    this.onResize();
  }

  onResize() {
    this.cameraOffset.x = Math.floor(game.width * 0.5);
    this.cameraOffset.y = 0;
  }

  /**
   * Stops ongoing tween, if it is going
   */
  _stopTween() {
    if (this._goingTween) {
      this._goingTween.stop();
      this._goingTween = null;
    }
  }

  show() {
    this._stopTween();
    this.msg.y = -200;
    this._goingTween = game.add.tween(this.msg)
      .to({ y: 0 }, 200, Phaser.Easing.Sinusoidal.Out, true);
  }

  hide(optOnComplete) {
    this._stopTween();
    this._goingTween = game.add.tween(this.msg)
      .to({ y: -200 }, 200, Phaser.Easing.Sinusoidal.In, true);
    if (optOnComplete) {
      this._goingTween.onComplete.add(optOnComplete);
    }
  }
}
