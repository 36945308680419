if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getShineAnimation = function getShineAnimation() {
  return { animate: animate };

  function animate(game, target) {
    var shine = G.Utils.find1stObjectWithName(target, "shine");

    if (shine) {
      game.add.tween(shine)
        .to({
          angle: -360,
        }, 20000, Phaser.Easing.Linear.None, true, 0, -1)
    }
  };
}
