let _instance; // singleton instance

/**
 * class that allows loading of external images to the texture cache
 */
export class OMT_TexturePreloader {
  /**
   * get singleton instance
   * @returns {OMT_TexturePreloader}
   */
  static getInstance() {
    if (!_instance) _instance = new OMT_TexturePreloader();
    return _instance;
  }

  /**
   * preloads image URLs in the passed list to the texture cachce
   * @param {Array} imageURLList
   * @param {number} delayInterval (optional) for throttling loading
   * @returns {Promise<boolean>}
   */
  loadTexturesAsync(urlList, delayInterval = 0) {
    let delay = 0;
    return new Promise((resolve) => {
      const preloadPromises = [];
      for (const imageURL of urlList) {
        preloadPromises.push(this.loadTextureAsync(imageURL, delay));
        delay += delayInterval;
      }
      Promise.all(preloadPromises).then(() => {
        resolve(true);
      }).catch(() => {
        resolve(false);
      });
    });
  }

  /**
   * preload a single image URL to the texture cachce
   * @param {string} imageURL
   * @param {number} delay (optional) for throttling loading
   * @returns {Promise<Object>}
   */
  loadTextureAsync(imageURL, delay = 0) {
    return new Promise((resolve) => {
      if (delay === 0) {
        G.getExtTexture(imageURL, () => { resolve(); }).catch(() => { resolve(); });
      } else {
        setTimeout(() => {
          G.getExtTexture(imageURL, () => { resolve(); }).catch(() => { resolve(); });
        }, delay);
      }
    });
  }
}
