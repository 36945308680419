/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable func-names */

import { Window } from '../../00_IMMEDIATE/Window';
import { FriendshipChest_Countdown } from './FriendshipChest/FriendshipChest_Countdown';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { Window_FillRateFallback } from './Window_FillRateFallback';
import OMT_UI_TournamentPromoButton from '../../OMT_UI/Buttons/OMT_UI_TournamentPromoButton';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import MysteryGiftHeader from '../G.MysteryGiftHeader';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';
import OMT_AnimationFactory from '../../Utils/Animation/OMT_AnimationFactory';
import { OMT_SessionUtil } from '../../Utils/OMT_SessionUtil';
import OMT_StackManager from '../../Utils/OMT_StackManager';
import OMT_TweenUtils from '../../Utils/Animation/OMT_TweenUtils';
import VillainsConversation from '../../OMT_UI/Villains/VillainsConversation';
import { temporaryBoosterCap } from '../../Services/OMT/dataTracking/villains/VillainsDataManager';
import { OMT_SystemInfo, ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { UI_StartBoosterButton } from '../G.UI_StartBoosterButton';

const disabledBoosterMsgSettings = {
  minScreenHeight: 960,
  maxScreenHeight: 1080,
  yRange: 60,
};

/**
 * Class for pre-level screen
 * Some functionality is also being used by Window_dailyChallenge. Please watch out for that
 */
export default class Window_Level extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {number} optLvlIndex (optional)
   * @param {Object} retryConfig (optional) config passed for retrying level
   */
  constructor(parent, optLvlIndex, retryConfig) {
    super(parent);

    this._manageMode();
    this._mysteryGift = false;
    this._villainsAreTalking = false;
    this._retryConfig = retryConfig;

    this._construct(optLvlIndex);

    /* notify the game the level window is open */ // Just in case
    // G.sb('levelWindowOpen').dispatch(this.levelData, this.gameMode, this._showLeaderboard());
    this._onResizeSB = G.sb('onScreenResize').add(this._onResize, this);
  }

  /**
   * Used for the signal when a level window opens.
   * Can be overwritten.
   * Mostly here because I can't get the timing right to NOT show the leaderboard...
   * @returns {boolean}
   */
  _showLeaderboard() {
    return true;
  }

  /**
   * Manage the mode tracker
   */
  _manageMode() {
    if (game.state.getCurrentState().mode === LevelType.NONE) { // Game state will manage but World state does not
      game.state.getCurrentState().mode = LevelType.NORMAL;
    }
    this.gameMode = LevelType.NORMAL;
  }

  /**
   * Construct the window and create all functionality attached to it
   * @param {number} optLvlIndex Optional level index to create the level window and also set the G.lvl data accordingly
   */
  _construct(optLvlIndex) {
    // the message we display to the player when they click on a disabled booster.
    this.disabledBoosterMessage = new Phaser.Group(this);
    // group that contains disabledBoosterMessage used for responsiveness purposes
    this.disabledBoosterMsgContainer = new Phaser.Group(this);

    this._manageData(optLvlIndex);
    this._initLayout();
    this._checkVillainsTutorialBoosters();
    this._initButtons();
    this._initStars();
    this._initTasks();
    this._initBoosters();

    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();

    // If villains are active, we only need to do these if the level is normal difficulty
    // Otherwise, we always do these
    if (!OMT.feature.isVillainsEnabled() || !isNotNormalLevel) {
      if (OMT.feature.getFeatureFriendshipChest(false)) this._initFriendshipChestBanner();

      const topBannerPresent = Boolean(this._mysteryGift) || Boolean(this._friendshipChestBanner);
      if (!topBannerPresent) this._setupTournamentPromoButton();

      this._setupPostcardEvent();

      if (topBannerPresent && game.state.current === 'Game') {
        this.y -= 75;
      } else if (game.state.current === 'World') {
        this.y = -G.WindowMgr.Constants.WorldVerticalOffset;
      }
    }

    this.disabledBoosterMsgContainer.add(this.disabledBoosterMessage);
    this._levelContainer.add(this.disabledBoosterMsgContainer);

    this._onResize();

    this.forceFitOnScreen();

    if (this.gameMode === LevelType.NORMAL) {
      this._setupVillains();
    }
  }

  /**
   * we want to force the window to fit
   */
  update() {
    super.update();
    // We are calling this once now since adding more elements to the window that start
    // out of the screen messes with the window entirely
    // this.forceFitOnScreen();
  }

  /**
   * On resize callback
   */
  _onResize() {
    const { minScreenHeight, maxScreenHeight, yRange } = disabledBoosterMsgSettings;
    const lerpFactor = Math.min(Math.max((game.height - minScreenHeight) / (maxScreenHeight - minScreenHeight), 0), 1);
    this.disabledBoosterMsgContainer.y = this.bg.height / 2 + 30 + (yRange * lerpFactor);
  }

  /**
   * setup level data
   * @param {*} level
   */
  _manageData(optLvlIndex) {
    this._parentLevelName = 'level';

    // set global level data
    if (optLvlIndex !== undefined) {
      G.lvlNr = optLvlIndex;
      G.lvlData = G.Helpers.levelDataMgr.getLevelByIndex(optLvlIndex);
    }
    this.levelData = G.lvlData;
    this._lvlIndex = G.lvlNr;

    OMT_VILLAINS.setLevelType(this.gameMode);
  }

  /**
   * init the generic graphics layout\
   * @param {*} headerTxt The text the header should have
   */
  _initLayout(headerTxt, isDailyChallenge) {
    headerTxt = headerTxt || `${OMT.language.getText('Level')} ${G.lvlNr + 1}`;
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const useSuperHardGraphics = isDailyChallenge || isSuperHardLevel;
    this._extraContainer = new Phaser.Group(game, this); // For some reason calling this.toLocal returns NaN but this.Group.toLocal is fine
    this._levelContainer = new Phaser.Group(game, this._extraContainer);

    if (isNotNormalLevel || isDailyChallenge) {
      const targetWidth = (useSuperHardGraphics ? G.OMTsettings.elements.superhard.window_level.superHardWidth : G.OMTsettings.elements.superhard.window_level.hardWidth) * 0.4;
      const targetHeight = 1534 * 0.4;
      const targetImage = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_popup') : OMT_VILLAINS.getPrefixedName('hard_popup');
      const targetSlice = useSuperHardGraphics ? G.OMTsettings.elements.superhard.window_level.super_hard_popup : G.OMTsettings.elements.superhard.window_level.hard_popup;
      this.bg = new UI_NineSlice(0, 0, targetImage, targetWidth, targetHeight, targetSlice);
    } else {
      this.bg = G.makeImage(0, 0, 'popup_background_2', 0.5, this._levelContainer);
    }

    if (isNotNormalLevel) {
      this.bg.anchor.setTo(0.5);
      this._levelContainer.add(this.bg);
    }

    let bannerTexture = 'blue_banner';
    let blueBannerYOffset = 0;
    if (isNotNormalLevel) {
      const tex = useSuperHardGraphics ? 'super_hard_header' : 'hard_header';
      bannerTexture = OMT_VILLAINS.getPrefixedName(tex);
      blueBannerYOffset = isDailyChallenge
        ? G.OMTsettings.elements.superhard.window_dailyChallenge.bannerYOffset || 0
        : G.OMTsettings.elements.superhard.window_level.bannerYOffset || 0;
    }

    // banner for normal / hard level
    this._blue_banner = G.makeImage(
      0, -295 + blueBannerYOffset,
      bannerTexture,
      0.5,
      this._levelContainer,
    );

    if (isNotNormalLevel) {
      this._blue_banner.scale.setTo(0.4 * 1.33);
    }

    // level title text
    const titleTxtMaxWidthMultiplier = isDailyChallenge
      ? G.OMTsettings.elements.superhard.window_dailyChallenge.titleMaxWidthMultipler
      : 0.9;

    this._levelTxt = new G.Text(0, -297, (headerTxt), {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, this._blue_banner.width * titleTxtMaxWidthMultiplier);
    if (isDailyChallenge) {
      this._levelTxt.y += 5;
    } else if (isNotNormalLevel) {
      this._levelTxt.y += 15;
    }
    this._levelContainer.add(this._levelTxt);

    // styling for hard and super hard levels
    if (isNotNormalLevel && !isDailyChallenge) {
      if (!OMT.feature.isVillainsEnabled()) { // Villains will enable it later
        this._checkMysteryGiftHeader();
      }
      let _hardWarningTxtContent = OMT.language.getText('Hard');
      let _hardWarningTxtStyle = OMT_VILLAINS.getPrefixedName('hard_1');
      if (isSuperHardLevel) {
        _hardWarningTxtContent = OMT.language.getText('Super Hard');
        _hardWarningTxtStyle = OMT_VILLAINS.getPrefixedName('super_hard_1');
      }
      this._hardWarningTxt = new G.Text(0, -330, _hardWarningTxtContent, {
        style: _hardWarningTxtStyle,
        fontSize: '30px',
      }, 0.5, this.bg.width, this.bg.height, true, 'center');
      this._levelContainer.add(this._hardWarningTxt);
    } else {
      this._checkMysteryGiftHeader();
    }


    const orientationSettings = G.OMTsettingsDefault.elements.Window_level[OMT.systemInfo.orientationKey];
    this._levelContainer.scale.set(orientationSettings.scale);
    this._levelContainer.y += orientationSettings.yOffset;
  }

  /**
   * init window buttons
   * @param {Nmber} buttonColor The color the continue button should be.
   * @param {Function} onContinueClick the function that should run when the continue button is clicked.
   * @param {boolean} allowRewardedContinue
   */
  _initButtons(buttonColor = BUTTONCOLOURS.green, onContinueClick, allowRewardedContinue = true) {
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();

    // continue button
    const onClick = () => {
      const levelData = {
        lvlIndex: this._lvlIndex,
        debugMode: false,
        startBoosters: this._assembleLevelBoosters(this._lvlIndex),
        challengeLvl: null,
        preLevelTrackingData: {},
      };
      // assign any additional paramters passed on retry
      if (this._retryConfig) Object.assign(levelData, this._retryConfig);
      G.saveState.disableDiscount();
      G.sb('onStateChange').dispatch('Game', levelData);// G.lvlNr,false, temp, null, preLevelData);
    };

    onContinueClick = onContinueClick || onClick;
    // close button
    this._makeCloseButton(isNotNormalLevel);
    this.registerButtons(this._closeButton);
    this._levelContainer.add(this._closeButton);

    this._continueBtn = new OMT_UI_SquareButton(0, 300, {
      button: {
        tint: buttonColor,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: { style: 'font-white', fontSize: 70 },
      },
      options: {
        clickFunction: {
          onClick: onContinueClick.bind(this),
        },
      },
    });
    this.registerButtons(this._continueBtn);
    this._levelContainer.add(this._continueBtn);

    // using this.dailyChallengeLevel to ensure we don't run this when we're a daily challenge
    if (OMT.feature.canLevelShowAdContinueBooster() && allowRewardedContinue && !this.levelData.noPreBoosters) {
      this._makeAdContinueButton();
      if (this._adContBoosterButton) {
        this._adContBoosterButton.pulse(1.1);
      }
    } else {
      this._continueBtn.pulse(1.1);
    }
  }

  /**
   * Makes the close button on the level popup
   * @param {boolean} shouldBeSmall
   */
  _makeCloseButton(shouldBeSmall) {
    this._closeButton = new G.Button(235, -257, 'btn_x', this._closeLevel, this);
    if (shouldBeSmall) {
      this._closeButton.scale.setTo(0.8);
      this._closeButton.x -= 20;
      this._closeButton.y += 12;
    }
  }

  /**
   * create continue with booster for add button
   */
  _makeAdContinueButton() {
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const allowMultiple = OMT.feature.multiplePreLevelBoostersAllowed() && isNotNormalLevel;
    const onAdClicked = () => {
      const giveExtraBooster = (index, assembledBoosters) => {
        if (allowMultiple || !assembledBoosters.player[index]) {
          assembledBoosters.adRewarded[index] = true;
        } else {
          // Track booster as a reward since it is no longer applied immediately
          // DDNA.transactionHelper.trackRewards([`booster#${index}`, 1], [], {
          //   transactionType: 'REWARD', tActionType: 'AD', tGameArea: 'MAP',
          // });
        }
        G.saveState.changeBoosterAmount(index, 1, true); // Give one before you take one
      };

      const onAdSuccess = () => {
        const assembledBoosters = this._assembleLevelBoosters(this._lvlIndex);
        if (assembledBoosters.player[7] && !assembledBoosters.player[8]) { // Fixed to spiral bomb
          giveExtraBooster(8, assembledBoosters);
        } else if (assembledBoosters.player[8] && !assembledBoosters.player[7]) { // Fixed to special gems
          giveExtraBooster(7, assembledBoosters);
        } else { // Rng
          const rng = Math.random();
          if (rng < 0.5) {
            giveExtraBooster(7, assembledBoosters);
          } else {
            giveExtraBooster(8, assembledBoosters);
          }
        }

        const levelData = {
          lvlIndex: this._lvlIndex,
          debugMode: false,
          startBoosters: assembledBoosters,
          challengeLvl: null,
          preLevelTrackingData: {},
        };
        G.sb('onStateChange').dispatch('Game', levelData);// G.lvlNr,false, temp, null, preLevelData);
      };
      OMT.ads.showAd(
        G.BuildEnvironment.adPlacements.preLevelBooster,
        onAdSuccess,
        () => {
          if (!G.IAP) { // no IAPS dont use ad fallbacks
            // eslint-disable-next-line no-new
            new Window_FillRateFallback(undefined, {
              placement: G.BuildEnvironment.adPlacements.preLevelBooster,
              callback: onAdSuccess,
              onExit: () => {},
              context: this,
            });
          } else { // open ad fallback
            OMT.ads.showAdFallback(() => {
              onAdSuccess();
            });
          }
        },
      );
    };
    this._adContBoosterButton = new OMT_UI_SquareButton(0, 300, {
      button: {
        tint: BUTTONCOLOURS.purple,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      options: {
        clickFunction: {
          onClick: onAdClicked.bind(this),
        },
        pulse: 1.1,
      },
    });

    const booster = new Phaser.Group(game, this._adContBoosterButton);
    const booster1 = G.makeImage(0, 0, G.gift.getIconForType(G.gift.GiftContentType.StartBoosterLines), 0.5, booster);
    const booster2 = G.makeImage(0, 0, G.gift.getIconForType(G.gift.GiftContentType.StartBoosterSpiralBomb), 0.5, booster);
    game.add.tween(booster1)
      .to({ alpha: 0 }, 1700, Phaser.Easing.Exponential.In, true, 500, null, true);
    booster2.alpha = 0;
    game.add.tween(booster2)
      .to({ alpha: 1 }, 1700, Phaser.Easing.Exponential.In, true, 500, null, true);
    booster.height = this._adContBoosterButton.height * 0.6;
    booster.width = this._adContBoosterButton.height * 0.6;
    const video = G.makeImage(booster.width * 0.6, booster.height / 2, 'btn-movie-icon', 0.5, booster);
    video.angle = -15;
    booster.x = (booster.width / 2) + 10 - (this._adContBoosterButton.width / 2);
    booster.y = -booster.height / 16;
    const leftoverSpace = (this._adContBoosterButton.width / 2) - (booster.x + booster.width) + 10;
    const continueText = new G.Text(0, 0, OMT.language.getText('Continue'), { style: 'font-white', fontSize: 70 }, 0.5, leftoverSpace, this._adContBoosterButton.height, true, 'center');
    continueText.x = booster.x + (booster.width * 0.65) + continueText.width / 2;
    this._adContBoosterButton.addChild(continueText);

    this.registerButtons(this._adContBoosterButton);
    this._levelContainer.add(this._adContBoosterButton);

    const centeringObjects = [this._continueBtn, this._adContBoosterButton];
    G.centerElements2(centeringObjects, 15);
    centeringObjects.forEach((obj) => { // Centers objects more a little bit
      obj.x += obj.width / 2;
    });
  }

  /**
   * display stars achived
   * @param {number} starsAchieved how many stars the player has achieved in that level.
   */
  _initStars(starsAchieved) {
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();

    if (starsAchieved == null) {
      starsAchieved = G.saveState.getStars(G.lvlNr);
    }

    let sideStarY = -150;
    if (isNotNormalLevel) {
      sideStarY += 20;
    }
    this._stars = [
      G.makeImage(-100, sideStarY, starsAchieved >= 1 ? 'star' : 'star_blank', 0.5, this._levelContainer),
      G.makeImage(0, sideStarY - 25, starsAchieved >= 2 ? 'star' : 'star_blank', 0.5, this._levelContainer),
      G.makeImage(100, sideStarY, starsAchieved >= 3 ? 'star' : 'star_blank', 0.5, this._levelContainer),
    ];
    this._stars[0].scale.setTo(0.8);
    this._stars[2].scale.setTo(0.8);
  }

  /**
   * init task UI
   */
  _initTasks(levelType) {
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const isDailyChallenge = levelType === LevelType.CHALLENGE;
    const useSuperHardGraphics = isDailyChallenge || isSuperHardLevel;

    if (isNotNormalLevel) {
      this._taskBg = new UI_NineSlice(0, 5, OMT_VILLAINS.getPrefixedName('text_background'), this.bg.width - 75, 75, {
        left: 30,
        right: 30,
        bottom: 15,
        top: 15,
      });
      this._taskBg.anchor.setTo(0.5);
      this._taskBg.tint = useSuperHardGraphics ? G.OMTsettings.elements.superhard.window_level.super_hard_tint : G.OMTsettings.elements.superhard.window_level.hard_tint;
      this._levelContainer.add(this._taskBg);
    } else {
      this._taskBg = G.makeImage(0, 5, this._getTaskBgName(), 0.5, this._levelContainer);
    }
    let taskTxtStyle = 'font-blue';
    let taskTxtY = -70;
    if (isNotNormalLevel) {
      taskTxtStyle = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_4') : OMT_VILLAINS.getPrefixedName('hard_4');
      taskTxtY += 10;
    }
    this._taskTxt = new G.Text(0, taskTxtY, `${OMT.language.getText('Task')}:`, {
      style: taskTxtStyle,
      fontSize: '45px',
    }, 0.5, 380);
    this._levelContainer.add(this._taskTxt);

    let taskPanelStyle;
    if (isNotNormalLevel) {
      taskPanelStyle = {
        style: taskTxtStyle,
      };
    }
    if (this.levelData.goal[0] === 'collect') {
      this._taskPanel = G.Helpers.createTaskCollectPanels(this.levelData.goal[1], taskPanelStyle);
      this._taskPanel.y = 5;
      this._levelContainer.add(this._taskPanel);
    } else {
      this._levelContainer.add(new G.Text(0, 5, `${OMT.language.getText('points').toUpperCase()}: ${this.levelData.goal[1]}`, {
        style: taskTxtStyle,
        fontSize: '50px',
      }, 0.5, 380));
    }
  }

  /**
   * init booster and booster UI
   * @param {number} optLvlIndex
   * @param {string} windowType
   */
  _initBoosters(levelNumber, windowType = 'level', boosterTextStyle = null) {
    const isDailyChallenge = windowType === 'dailyChallenge';
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const useSuperHardGraphics = isDailyChallenge || isSuperHardLevel;
    const lvlNumber = levelNumber || G.lvlNr;
    const saveStateMgr = G.saveState;
    const buyTxtStyle = this._getBuyTextStyle(isNotNormalLevel, useSuperHardGraphics);
    this._buyTxt = new G.Text(0, 90, ':', {
      style: buyTxtStyle,
      fontSize: '35px',
    }, 0.5, 510);

    const bgImageTexture = this._getBgImageTexture(isNotNormalLevel);
    const bgImageWidth = this.bg.width;
    this.disableText = (new G.Text(0, 0, OMT.language.getText('This booster is disabled for this level.'), buyTxtStyle));
    this.bgImage = new UI_NineSlice(0, 0, bgImageTexture, bgImageWidth, this.disableText.height + 30, {
      left: 30,
      right: 30,
      bottom: 25,
      top: 25,
    });
    if (isNotNormalLevel) {
      this.bgImage.tint = useSuperHardGraphics ? G.OMTsettings.elements.superhard.window_level.super_hard_tint : G.OMTsettings.elements.superhard.window_level.hard_tint;
    }
    this.bgImage.x = -this.bgImage.width / 2;
    this.bgImage.y = -this.bgImage.height / 2;
    this.disabledBoosterMessage.add(this.bgImage);
    this.disabledBoosterMessage.add(this.disableText);

    this.disableText.width = this.bg.width - 50;
    this.disableText.scale.y = this.disableText.scale.x;

    this.bgImage.width = bgImageWidth;
    this.bgImage.height = 60;

    this.disableText.x = -this.disableText.width / 2;
    this.disableText.y = -this.disableText.height / 2;

    this.disabledBoosterMessage.alpha = 0;

    const checkText = () => { // in scope of this._buyTxt
      if (!this.alive) return;
      const bst = saveStateMgr.getBoosterArray();
      const allActivate = bst[5] > 0 && bst[7] > 0 && bst[8] > 0;
      const allZero = bst[5] === 0 && bst[7] === 0 && bst[8] === 0;
      let newTxt;
      if (allActivate) {
        newTxt = `${OMT.language.getText('Activate boosters')}:`;
      } else if (allZero) {
        newTxt = `${OMT.language.getText('Buy some boosters')}:`;
      } else {
        newTxt = `${OMT.language.getText('Buy or activate boosters')}:`;
      }
      if (this._buyTxt.text !== newTxt) this._buyTxt.setText(newTxt);
    };

    this._buyTxt.checkText = checkText.bind(this._buyTxt);

    this._buyTxt.checkText();
    this._levelContainer.add(this._buyTxt);

    this._buyTxtBinding = G.sb('onStartBoosterSelectBounce').add(function () {
      G.stopTweens(this._buyTxt);
      this._buyTxt.checkText();
      this._buyTxt.scale.setTo(1);
      game.add.tween(this._buyTxt.scale)
        .to({ x: 1.2, y: 1.2 }, 200, Phaser.Easing.Custom.SoftBounce, true, 0, 0, true);
    }, this);

    this._buyTxt.events.onDestroy.add(function () {
      if (this._buyTxtBinding && this._buyTxtBinding.detach) {
        this._buyTxtBinding.detach();
      }
    }, this);

    this._createBoosterBg(isNotNormalLevel, useSuperHardGraphics, isDailyChallenge);

    let movesBoosterType = 5;
    if (G.IAP) {
      movesBoosterType = 6;
    }

    // if we dont have any boosters left, make sure theyre not selected in the startBoosterConfig
    if (this.state.startBoosterConfig.data[lvlNumber]) {
      if (saveStateMgr.getBoosterAmount(movesBoosterType) === 0) this.state.startBoosterConfig.data[lvlNumber][movesBoosterType] = 0;
      if (saveStateMgr.getBoosterAmount(7) === 0) this.state.startBoosterConfig.data[lvlNumber][7] = 0;
      if (saveStateMgr.getBoosterAmount(8) === 0) this.state.startBoosterConfig.data[lvlNumber][8] = 0;
    }

    const allowMultiple = this._allowMultipleBoosters((isNotNormalLevel || isDailyChallenge));
    const sideBoosterX = this._calculateSideBoosterXValue((isNotNormalLevel || isDailyChallenge));
    this._boosters = [
      new G.UI_StartBoosterButton(this, -sideBoosterX, 170, movesBoosterType, lvlNumber, windowType, { layerName: this.parent.layerName, args: [lvlNumber] }, false, allowMultiple, boosterTextStyle),
      new G.UI_StartBoosterButton(this, 0, 170, 7, lvlNumber, windowType, { layerName: this.parent.layerName, args: [lvlNumber] }, this.levelData.noPreBoosters, allowMultiple, boosterTextStyle),
      new G.UI_StartBoosterButton(this, sideBoosterX, 170, 8, lvlNumber, windowType, { layerName: this.parent.layerName, args: [lvlNumber] },
        this.levelData.noPreBoosters, allowMultiple, boosterTextStyle),
    ];

    for (const booster of this._boosters) {
      booster.setMultipleSelectionImmediate(true);
    }

    if (this.levelData.noPreBoosters) {
      for (let i = 0; i < this._boosters.length; i++) {
        const booster = this._boosters[i];
        booster.onSelected = this._onBoosterSelected.bind(this);

        this._adjustBoosterSize(booster, (isNotNormalLevel || isDailyChallenge) && i > 0);
      }
      this.y -= 20;
    }

    this._levelContainer.addMultiple(this._boosters);
  }

  /**
   * Returns the font style used for the buy text
   * @param {boolean} isNotNormalLevel
   * @param {boolean} useSuperHardGraphics
   * @returns {string}
   */
  _getBuyTextStyle(isNotNormalLevel, useSuperHardGraphics) {
    let buyTxtStyle = 'font-blue';
    if (isNotNormalLevel) {
      buyTxtStyle = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_3') : OMT_VILLAINS.getPrefixedName('hard_3');
    }
    return buyTxtStyle;
  }

  /**
   * Returns the background asset used for the pop up
   * @param {boolean} isNotNormalLevel
   * @returns {string}
   */
  _getBgImageTexture(isNotNormalLevel) {
    let bgImageTexture = 'th_tutorial_box';
    if (isNotNormalLevel) {
      bgImageTexture = OMT_VILLAINS.getPrefixedName('text_background');
    }
    return bgImageTexture;
  }

  /**
   * Creates the booster bg used for boosters
   * @param {boolean} isNotNormalLevel
   * @param {boolean} useSuperHardGraphics
   * @param {boolean} isDailyChallenge
   */
  _createBoosterBg(isNotNormalLevel, useSuperHardGraphics, isDailyChallenge) {
    if ((isNotNormalLevel || isDailyChallenge)) {
      this._boosterBg = new UI_NineSlice(0, 170, OMT_VILLAINS.getPrefixedName('text_background'), this.bg.width - 105, 75, {
        left: 30,
        right: 30,
        bottom: 15,
        top: 15,
      });
      this._boosterBg.anchor.setTo(0.5);
      if (isNotNormalLevel) {
        this._boosterBg.tint = useSuperHardGraphics ? G.OMTsettings.elements.superhard.window_level.super_hard_tint : G.OMTsettings.elements.superhard.window_level.hard_tint;
      }
      this._levelContainer.add(this._boosterBg);
    } else {
      this._boosterBg = G.makeImage(0, 170, this._getTaskBgName(), 0.5, this._levelContainer);
    }
  }

  /**
   * Checks if multi boosters is allowed
   * @param {boolean} andRequest
   * @returns {boolean}
   */
  _allowMultipleBoosters(andRequest) {
    return OMT.feature.multiplePreLevelBoostersAllowed() && andRequest;
  }

  /**
   * Returns X position of boosters
   * @param {boolean} andRequest
   * @returns {number}
   */
  _calculateSideBoosterXValue(requiresExtraAdjustment) {
    let sideBoosterX = 195;
    if (requiresExtraAdjustment) {
      sideBoosterX -= 20;
    }
    return sideBoosterX;
  }

  /**
   * Adjusts the size of the boosters
   * @param {Phaser.DisplayObject} booster
   * @param {boolean} doesItReally Only adjust on certain conditions
   * @returns {number}
   */
  _adjustBoosterSize(booster, doesItReally) {
    if (doesItReally) {
      booster.scale.setTo(0.75);
    }
  }

  /**
   * when a booster has been selected
   */
  _onBoosterSelected(boosterBtn) {
    this.disabledBoosterMessage.y = 40;
    this.disabledBoosterMessage.alpha = 0;
    if (boosterBtn.disabled) {
      // display the disabledBoosterMessage
      game.add.tween(this.disabledBoosterMessage).to({ y: 0, alpha: 1 }, 200, Phaser.Easing.Sinusoidal.Out, true);
    }
  }

  /**
   * Set up the tournament promotion button that sits below the window.  Clicking it will lead to the tournament
   */
  _setupTournamentPromoButton() {
    if (!G.featureUnlock.tournamentPromo || OMT.platformTournaments.getCooldownTimeRemaining() > 0) return;
    if (G.saveState.getTournamentLastPlayed() === OMT.platformTournaments.getTournamentLevelId()) return;
    if ((game.height * window.devicePixelRatio) <= 1100) return; // this might need to be scaled?

    const tournamentBtn = new OMT_UI_TournamentPromoButton(this._levelContainer, this);
    tournamentBtn.onClick.addOnce(async () => {
      G.sb('hideHighscoreBoard').dispatch(); // Triggers G.sb in World.js

      await tournamentBtn.showTournamentWindow();

      this._closeLevelTemporarily();
      this.closeWindow();
    });

    this._levelContainer.addChild(tournamentBtn);
    this._levelContainer.y -= 42;
    tournamentBtn.scale.setTo(0.815);
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const offsetY = !OMT.feature.isVillainsEnabled() && isNotNormalLevel ? 92 : 62;
    tournamentBtn.y = this.bg.y + (this.bg.height / 2) + tournamentBtn.height / 2 + offsetY;

    // shift up if during game to not interfere with leaderboards on portrait
    if (game.state.current === 'Game' && OMT.systemInfo.orientation === ORIENTATION.vertical) {
      this.y -= 65;
    }
  }

  /**
   * init the friendship chest banner
   */
  _initFriendshipChestBanner() {
    const predictedShownLevel = G.featureUnlock.unlockLevels.friendshipChest - G.saveState.getLastPassedLevelNr();
    if (!G.saveState.friendshipChestDataManager.getPromoInitial() && G.saveState.friendshipChestDataManager.getInvitedData() && predictedShownLevel > 0) {
      this._friendshipChestBanner = new FriendshipChest_Countdown(G.game, this);
      this._friendshipChestBanner.x = this._isLandscape ? 480 : 320;
      this._friendshipChestBanner.visible = false;
      this._friendshipChestBanner.toggleVisibility(true);
      const goodPos = this._extraContainer.toLocal(this._friendshipChestBanner.position, this._extraContainer);

      const levelYScale = this._isLandscape ? this._gameScale / 2 : 1;
      this._levelContainer.y = (goodPos.y + 10 + this._friendshipChestBanner.renderedBounds.height / 2) * levelYScale;
    }
  }

  /**
   * Check and add the mystery gift header
   */
  _checkMysteryGiftHeader() {
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    if (G.MYSTERYGIFT
      && G.saveState.mysteryGiftManager.isModeReady()
      && G.saveState.mysteryGiftManager.getRemainingActiveTime() > 0
      && !this._villainsAreTalking) {
      this._mysteryGift = true;
      if (!isNotNormalLevel) {
        this.y += game.state.current === 'Game' ? 0 : 45;
      }
      this._addMysteryGiftHeader();
    }
  }

  /**
   * add the myster gift header
   */
  _addMysteryGiftHeader() {
    const headerText = !this.levelData.noPreBoosters ? undefined : 'Mystery Gift is unavailable for this level';
    const mysteryGiftHeader = new MysteryGiftHeader(this._lvlIndex === G.saveState.getLastPassedLevelNr(), headerText);
    game.world.addChild(mysteryGiftHeader);
    mysteryGiftHeader.show();

    this.onClose.add(() => {
      mysteryGiftHeader.hide(() => {
        mysteryGiftHeader.destroy();
      });
    });
  }

  /**
   * Hides extra UI but doesn't close the window itself
   */
  _closeLevelTemporarily() {
    if (this._friendshipChestBanner) this._friendshipChestBanner.toggleVisibility(false, true);

    this._boosters.forEach((btn) => {
      if (btn.signalBinding) btn.signalBinding.detach();
    });
  }

  /**
   * close the level popup
   */
  _closeLevel() {
    this._closeLevelTemporarily();

    if (game.state.current === 'World') { // Revert it back when you're done
      game.state.getCurrentState().mode = LevelType.NONE;
    } else if (game.state.current === 'Game') {
      G.sb('onStateChange').dispatch('World');
      G.saveState.disableDiscount();
      return;
    }
    this.closeWindow();
    G.sb('levelWindowClose').dispatch();
  }

  /**
   * Close window
   */
  closeWindow(callback, context, scaleAmount) {
    // Reset start booster array, since the window is closed now
    game.state.getCurrentState().startBoosterConfig.reset();
    // Resolve any temporary booster given by and villains tutorial flow
    G.saveState.villainsDataManager.resolveTemporaryBoosters(true);
    OMT_VILLAINS.resetLevelType();

    super.closeWindow(callback, context, scaleAmount);
  }

  /**
   * setup boosters to apply to the level
   * @param {number} level
   */
  _assembleLevelBoosters(level) {
    const startBoosters = {
      player: this.state.startBoosterConfig.getConfigForLevel(level), // State is world
      adRewarded: [],
    };
    return startBoosters;
  }

  /**
   * Checks if the postcard event is active, and then creates a button next to the level banner in the window
   */
  _setupPostcardEvent() {
    const featureOk = OMT.feature.getEventPostcardFeature(true);
    if (OMT.feature.getEventPostcardAssetStatus()) {
      if (featureOk) {
        const onPostcardClicked = () => {
          const returnArgs = { windowName: 'level', args: [this._lvlIndex], layerName: this.parent.layerName };
          if (G.saveState.isMsgRead(G.OMTsettings.postcardEvent.eventId)) {
            G.sb('pushWindow').dispatch(['eventPostcard', returnArgs], false, this.parent.layerName);
          } else {
            G.sb('pushWindow').dispatch(['eventPostcardPromo', returnArgs], false, this.parent.layerName);
          }
          G.sb('hideHighscoreBoard').dispatch(); // Triggers G.sb in World.js
          this._closeLevelTemporarily();
          this.closeWindow();
        };

        this._postcardButton = new G.Button(0, 0, null, onPostcardClicked, this);
        this.registerButtons(this._postcardButton);

        G.makeImage(0, 0, 'eventPostcardIcon', 0.5, this._postcardButton);
        this._postcardButton.x = Math.round(this._blue_banner.x - this._postcardButton.width - (this._blue_banner.width / 2));
        this._postcardButton.y = this._blue_banner.y;
        this._postcardButton.scale.setTo(0.01);

        this._levelContainer.addChild(this._postcardButton);

        const tw = game.add.tween(this._postcardButton.scale)
          .to({ x: 1, y: 1 }, 200, Phaser.Easing.Back.Out, true);
        const shake = game.add.tween(this._postcardButton)
          .to({ angle: [0, -5, 5, 0] }, 1000, Phaser.Easing.Sinusoidal.In, true, 0, 0, true);
        const subtlePause = game.add.tween(this._postcardButton)
          .to({ angle: 0 }, 500, Phaser.Easing.Sinusoidal.In);
        tw.chain(shake);
        shake.chain(subtlePause);
        subtlePause.chain(shake);
      }
    }
  }

  /**
   * Sets up everything regarding the villains
   * Corruption, tutorial and everything else
   */
  _setupVillains() {
    const { _lvlIndex } = this;
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();

    if (isNotNormalLevel && OMT.feature.isVillainsEnabled()) {
      // Add the offset for the window
      this.y += OMT_VILLAINS.getNotNormalLevelOffset() * 2.1;

      const session = OMT_SessionUtil.getInstance();
      const sessionData = session.checkAndCreateWithKey(OMT_VILLAINS.getLevelTrackerKey());
      const levelSessionData = sessionData.checkAndCreateWithKey(_lvlIndex);
      const corruptionPlayedThisSession = levelSessionData.getData('corruption_level_window');

      if (corruptionPlayedThisSession) {
        this._addVillains();
        this._checkMysteryGiftHeader();
      } else {
        this._createVisualCorruptionElements();
        this.onFinishedEnter.addOnce(() => {
          this._setupCorruptionAndVillainsTutorial().run();
        });
      }
    }
  }

  /**
   * Adds villain images to the window if the corruption animation already played this session
   */
  _addVillains() {
    const { isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const texture1 = OMT_VILLAINS.getPrefixedName('villain_1_1');
    const texture2 = OMT_VILLAINS.getPrefixedName('villain_2_1');
    const villainScaleCoeff = 0.45;
    const { villainsXOffset, villainsYOffset } = G.OMTsettings.elements.superhard.window_level;

    const villain_1 = G.makeImage(
      0,
      0,
      texture1,
      0.5,
      this._levelContainer,
    );
    villain_1.scale.setTo(villainScaleCoeff);
    villain_1.x = -this.bg.width * 0.5 + villain_1.width * 0.35 + villainsXOffset;
    villain_1.y = -this.bg.height * 0.5 - villain_1.height * 0.35 + villainsYOffset;
    villain_1.scale.x *= -1;
    this._levelContainer.addChildAt(villain_1, 0);

    if (isSuperHardLevel) {
      const villain_2 = G.makeImage(
        0,
        0,
        texture2,
        0.5,
        this._levelContainer,
      );
      villain_2.scale.setTo(villainScaleCoeff);
      villain_2.x = this.bg.width * 0.5 - villain_2.width * 0.35 - villainsXOffset;
      villain_2.y = -this.bg.height * 0.5 - villain_2.height * 0.35 + villainsYOffset;
      this._levelContainer.addChildAt(villain_2, 0);
    }
  }

  /**
   * Setup the window to look like normal, only to be changed / corrupted afterwards
   */
  _createVisualCorruptionElements() {
    const { _levelContainer } = this;
    const normalWindow = this._createNormalWindowGroup();
    normalWindow.scale.setTo(0.9);
    this.add(normalWindow);
    _levelContainer.alpha = 0;

    this._corruptWindow = G.sb('corruptWindow').addOnce(() => {
      const stack = OMT_StackManager.getFreeStack();

      const subStack1 = OMT_StackManager.getFreeStack();
      subStack1.addPromise(() => OMT_TweenUtils.changeAlphaTo({
        object: normalWindow,
        alpha: 0,
        duration: 500,
      }));
      subStack1.addEvent(() => {
        normalWindow.destroy();
      });

      const subStack2 = OMT_StackManager.getFreeStack();
      subStack2.wait(250);
      subStack2.addPromise(() => OMT_TweenUtils.changeAlphaTo({
        object: _levelContainer,
        alpha: 1,
        duration: 250,
      }));

      stack.addParallel([subStack1, subStack2]);
      stack.run();
    });
  }

  /**
   * Sets up the corruption and villains tutorial flow
   * @returns {OMT_StackManager}
   */
  _setupCorruptionAndVillainsTutorial() {
    const { _lvlIndex } = this;
    const { isHardLevel, isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const curLevel = G.saveState.getLastPassedLevelNr();
    const session = OMT_SessionUtil.getInstance();
    const sessionData = session.checkAndCreateWithKey(OMT_VILLAINS.getLevelTrackerKey());
    const levelSessionData = sessionData.checkAndCreateWithKey(_lvlIndex);
    const gameCorruptionSessionData = levelSessionData.checkAndCreateWithKey('corruption_game');
    const gameCorruptionPlayedThisSession = gameCorruptionSessionData.getData('done');

    if (!gameCorruptionPlayedThisSession) {
      this._continueBtn.signals.onClick.removeAll();
      this._continueBtn.signals.onClick.addOnce(() => {
        const randomLevelData = Math.floor(Math.random() * 10);
        const randomLevelSessionData = sessionData.checkAndCreateWithKey(randomLevelData);
        const randomGameCorruptionSessionData = randomLevelSessionData.checkAndCreateWithKey('corruption_game');
        const currentLevelData = {
          index: _lvlIndex,
          isHardLevel,
          isSuperHardLevel,
          isNotNormalLevel,
          startBoosters: this._assembleLevelBoosters(_lvlIndex),
        };
        randomGameCorruptionSessionData.setData('source', currentLevelData);

        const levelData = {
          lvlIndex: randomLevelData,
          debugMode: false,
          startBoosters: { player: [] },
          challengeLvl: null,
          preLevelTrackingData: {},
        };
        G.saveState.disableDiscount();
        G.saveState.villainsDataManager.normalizeTemporaryBoosters(currentLevelData.startBoosters.player);
        currentLevelData.startBoosters.player = G.saveState.villainsDataManager.normalizeStartBoosters(currentLevelData.startBoosters.player);
        G.saveState.villainsDataManager.resolveTemporaryBoosters(true);
        G.sb('onStateChange').dispatch('Game', levelData);
      });
    }

    const stack = OMT_StackManager.getFreeStack();
    let animationName = OMT_VILLAINS.getPrefixedName('hard_corruption_animation_window');
    if (isSuperHardLevel) {
      animationName = OMT_VILLAINS.getPrefixedName('super_hard_corruption_animation_window');
    }
    const animationFactory = new OMT_AnimationFactory();

    stack.addEvent(() => {
      game.input.enabled = false;
    });
    stack.addPromise(() => animationFactory.runAnimation(animationName, { parent: this }));
    stack.addEvent(() => {
      levelSessionData.setData('corruption_level_window', true);
    });

    let difficulty = 'hard';
    if (isSuperHardLevel) {
      difficulty = 'super_hard';
    }
    const villainsLevelWindowTutorial = G.saveState.villainsDataManager.getTutorialStatus(difficulty, 'level_window');

    if (!villainsLevelWindowTutorial && _lvlIndex === curLevel) {
      // const leaderboard = game.state.getCurrentState()._levelLeaderboard;
      this._continueBtn.visible = false;
      if (this._adContBoosterButton) {
        this._adContBoosterButton.visible = false;
      }

      const villainsConversation = new VillainsConversation(this._levelContainer, {
        pointer: true,
        highlight: 0.01,
      });
      this._villainsAreTalking = true;
      const highlight = villainsConversation.getHighlight();
      highlight.scale.setTo(1 / this._levelContainer.scale.x);

      stack.addEvent(() => {
        G.saveState.villainsDataManager.setTutorialStatus(difficulty, 'level_window', true);
        this._closeButton.onClick.addOnce(() => {
          stack.stop();
        });
        G.sb('onWindowClosed').addOnce(() => {
          stack.stop();
        });
        highlight.toggleInput(false);
      });
      stack.addEvent(() => {
        // leaderboard.hide();
      });

      villainsConversation.fixGingyToPosition((gingy) => {
        gingy.getClassContainer().scale.setTo(1);
        const gingyYScale = this._isLandscape ? 105 : 100;

        villainsConversation._positionClass(
          gingy,
          50,
          ((game.height - gingy.getClassContainer().height * 0.31) / game.height) * gingyYScale,
        );
      });

      villainsConversation.fixVillainToPosition((villain) => {
        villain.getObjects({ type: 'villain' }).forEach((object) => {
          object.visible = false;
        });
        let xPos = 47;
        let yPosCoeff = 0.6;
        let bubbleScale = 1;
        if (isSuperHardLevel) {
          xPos = 50;
        }
        if (OMT_SystemInfo.getInstance().orientation === ORIENTATION.vertical) {
          if (isSuperHardLevel) {
            yPosCoeff += 0.05;
          }
        } else if (isHardLevel) {
          yPosCoeff -= 0.07;
          bubbleScale = 0.8;
        }
        villainsConversation._positionClass(
          villain,
          xPos,
          ((villain.getClassContainer().height * yPosCoeff) / game.height) * 100,
        );
        villainsConversation._scaleClass(
          villain,
          bubbleScale,
        );
      });

      stack.addEvent(() => {
        game.input.enabled = true;

        const selectors = this._getBoosterSelectors();
        for (const selector of selectors) {
          this._toggleObjectInput(selector, false);
        }
      });
      stack.addPromise(() => villainsConversation.createTutorial(difficulty, 'level_window'));

      if (isHardLevel) {
        stack.addEvent(() => {
          highlight.disableRoundHighlight(true);
          highlight.maskAlpha = 0.6;
          highlight.resize();
          highlight.alpha = 0;
        });
        stack.addEvent(() => {
          const { _boosterBg } = this;
          const { width, height } = _boosterBg;
          const { x, y } = _boosterBg.worldPosition;
          const highlightArea = {
            worldPosition: {
              x,
              y: y + height * 0.1,
            },
            width: width * 1.1,
            height: height * 1.9,
          };

          if (this._isLandscape) {
            highlightArea.worldPosition.x /= this._gameScale;
            highlightArea.worldPosition.y /= this._gameScale;
          }
          highlight.highlight(highlightArea);
        });
        stack.addPromise(() => highlight.show());

        const handAnimationStack = this._animateHandForBoosterTutorial(villainsConversation);
        handAnimationStack.repeat(-1);
        stack.addEvent(() => {
          const selectors = this._getBoosterSelectors();
          for (const selector of selectors) {
            this._toggleObjectInput(selector, true);
          }

          this._selectorClickedBinding = G.sb('startBoosterMultipleSelectorClicked').add((selector) => {
            if (!this._selectorClickCount) {
              this._selectorClickCount = 1;
            }

            if (this._selectorClickCount < 3) {
              this._handleMultipleSelectorClick({ villainsConversation, handAnimationStack, selector }).run();
            }
          });
          this._closeButton.onClick.addOnce(() => {
            handAnimationStack.stop();
          });
          G.sb('onWindowClosed').addOnce(() => {
            handAnimationStack.stop();
          });
        });
        stack.addPromise(() => handAnimationStack.run());
      } else {
        stack.addEvent(() => {
          highlight.destroy();
          this._continueBtn.visible = true;
          if (this._adContBoosterButton) {
            this._adContBoosterButton.visible = true;
          }

          const selectors = this._getBoosterSelectors();
          for (const selector of selectors) {
            this._toggleObjectInput(selector, true);
          }
        });
      }
    } else {
      stack.addPromise(() => this._createVillainTauntBubble().run());
      stack.addEvent(() => {
        game.input.enabled = true;
      });
    }

    stack.addEvent(() => {
      this._checkMysteryGiftHeader();
    });
    return stack;
  }

  /**
   * Animate the pointer from villains conversation for booster tutorial
   * @param {VillainsConversation} villainsConversation
   * @returns {OMT_StackManager}
   */
  _animateHandForBoosterTutorial(villainsConversation) {
    const pointer = villainsConversation.getPointer();
    const { hand } = pointer;
    const { width, height } = hand;
    hand.scale.setTo(1);
    hand.anchor.setTo(0.5);
    hand.position.setTo(width * 0.25, height * 0.25);
    const stack = OMT_StackManager.getFreeStack();
    const targetList = this._getBoosterSelectors();

    {
      const firstPoint = targetList.shift();
      const { x: firstPointX, y: firstPointY } = pointer.toLocal(firstPoint.tutorialPoint());

      stack.addEvent(() => {
        pointer.position.setTo(firstPointX, firstPointY);
        pointer.targetObject = firstPoint;
      });
    }

    stack.addPromise(() => pointer.show());

    for (const area of targetList) {
      stack.addEvent(() => {
        const { targetObject } = pointer;
        this._toggleObjectInput(targetObject, false);
      });
      stack.addPromise(() => this._simulateHandClick(hand, () => {
        const { targetObject } = pointer;
        if (!this._selectorClickCount) {
          targetObject.tutorialAction();
        }
      }).repeat(1).run());
      stack.addEvent(() => {
        const { targetObject } = pointer;
        this._toggleObjectInput(targetObject, true);
      });

      const { x: areaX, y: areaY } = pointer.toLocal(area.tutorialPoint());
      stack.addPromise(() => OMT_TweenUtils.translateTo({
        object: pointer,
        duration: 200,
        ease: Phaser.Easing.Sinusoidal.InOut,
        x: areaX,
        y: areaY,
        callback: () => {
          pointer.targetObject = area;
        },
      }));
      stack.wait(300);
    }

    stack.addPromise(() => this._simulateHandClick(hand, () => {
      const { targetObject } = pointer;
      if (!this._selectorClickCount) {
        targetObject.tutorialAction();
      }
    }).repeat(1).run());

    stack.addPromise(() => pointer.hide());

    return stack;
  }

  /**
   * Animate hand as if it was clicking on an object
   * @param {any} hand
   * @param {() => void} callback
   * @returns {OMT_StackManager}
   */
  _simulateHandClick(hand, callback) {
    const stack = OMT_StackManager.getFreeStack().setReusable(true);

    stack.addPromise(() => OMT_TweenUtils.changeAngleTo({
      object: hand,
      duration: 200,
      ease: Phaser.Easing.Sinusoidal.InOut,
      angle: -10,
    }));
    stack.wait(100);
    if (callback) {
      stack.addEvent(() => {
        callback();
      });
    }
    stack.addPromise(() => OMT_TweenUtils.changeAngleTo({
      object: hand,
      duration: 200,
      ease: Phaser.Easing.Sinusoidal.InOut,
      angle: 0,
    }));

    return stack;
  }

  /**
   * Handle a click on the booster multiple selector
   */
  _handleMultipleSelectorClick({ villainsConversation, handAnimationStack, selector }) {
    const stack = OMT_StackManager.getFreeStack();
    const pointer = villainsConversation.getPointer();
    const highlight = villainsConversation.getHighlight();
    const { gingy } = villainsConversation;
    const pointerClickLoop = this._simulateHandClick(pointer.hand).repeat(-1);
    const selectors = this._getBoosterSelectors();
    const otherSelectors = selectors.filter((area) => area !== selector);

    const difficulty = 'hard';

    if (this._selectorClickCount === 1) {
      selectors.forEach((area) => {
        this._toggleObjectInput(area, false);
      });

      stack.addEvent(() => {
        this._boosters.forEach((booster) => {
          booster.refreshBoosterVisuals();
        });
      });
      stack.addPromise(() => pointer.hide());
      stack.addPromise(() => highlight.hide());
      stack.addEvent(() => {
        handAnimationStack.setCallback(() => {
          pointer.position.setTo(0, 0);
          const { x: targetX, y: targetY } = pointer.toLocal(selector.tutorialPoint());
          pointer.position.setTo(targetX, targetY);
        });
        handAnimationStack.stop();

        const targetBooster = this._boosters.find((booster) => {
          const { minusHitArea, plusHitArea } = booster;
          return minusHitArea === selector || plusHitArea === selector;
        });

        const { width, height, worldPosition } = targetBooster;
        const { x, y } = worldPosition;
        const highlightArea = {
          worldPosition: {
            x,
            y: y + height * 0.07,
          },
          width: width * 1.1,
          height: height * 1.07,
        };

        if (this._isLandscape) {
          highlightArea.worldPosition.x /= this._gameScale;
          highlightArea.worldPosition.y /= this._gameScale;
        }
        highlight.highlight(highlightArea);

        this._closeButton.onClick.addOnce(() => {
          handAnimationStack.stop();
          pointerClickLoop.stop();
        });
        G.sb('onWindowClosed').addOnce(() => {
          handAnimationStack.stop();
          pointerClickLoop.stop();
        });
      });
      stack.wait(300);
      stack.addPromise(() => highlight.show());
      stack.addPromise(() => gingy.setText("Let's try using more than one!"));
      stack.addPromise(() => pointer.show());
      stack.addEvent(() => {
        this._toggleObjectInput(selector, true);
      });
      stack.addPromise(() => pointerClickLoop.run());
    } else if (this._selectorClickCount === 2) {
      stack.addEvent(() => {
        pointerClickLoop.stop();
        pointer.destroy();

        otherSelectors.forEach((area) => {
          this._toggleObjectInput(area, true);
        });

        highlight.destroy();

        this._continueBtn.visible = true;
      });
      stack.addPromise(() => gingy.setText('Good luck!'));
    }

    this._selectorClickCount++;

    return stack;
  }

  /**
   * Get an array of multiple booster selectors
   * The minus hit areas are reverse for animation purposes, this can be changed later
   * @returns {any[]}
   */
  _getBoosterSelectors() {
    const selectors = [];
    const plusHitAreas = [];
    const minusHitAreas = [];

    for (const booster of this._boosters) {
      const { minusHitArea, plusHitArea } = booster;
      plusHitAreas.push(plusHitArea);
      minusHitAreas.push(minusHitArea);
    }

    selectors.push(...plusHitAreas, ...minusHitAreas.reverse());

    return selectors;
  }

  /**
   * Toggles the input of object safely
   * Even if the object was destroyed, this should produce no errors
   * @param {any} target
   * @param {boolean} state
   */
  _toggleObjectInput(target, state) {
    const { input } = target;
    if (input) {
      input.enabled = state;
    } else {
      target.inputEnabled = state;
    }
  }

  /**
   * Creates the villain taunt bubble for post corruption flow
   * @returns {OMT_StackManager}
   */
  _createVillainTauntBubble() {
    const { isHardLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const stack = OMT_StackManager.getFreeStack();
    const villainsConversation = new VillainsConversation(this._levelContainer);
    this._villainsAreTalking = true;

    villainsConversation.fixGingyToPosition((gingy) => {
      villainsConversation._positionClass(
        gingy,
        50,
        ((game.height - gingy.getClassContainer().height * 0.31) / game.height) * 100,
      );
    });

    villainsConversation.fixVillainToPosition((villain) => {
      villain.getObjects({ type: 'villain' }).forEach((object) => {
        object.visible = false;
      });
      let xPos = 47;
      let yPosCoeff = 0.6;
      let bubbleScale = 1;
      if (isSuperHardLevel) {
        xPos = 50;
      }
      if (OMT_SystemInfo.getInstance().orientation === ORIENTATION.vertical) {
        if (isSuperHardLevel) {
          yPosCoeff += 0.05;
        }
      } else if (isHardLevel) {
        yPosCoeff -= 0.07;
        bubbleScale = 0.8;
      }
      villainsConversation._positionClass(
        villain,
        xPos,
        ((villain.getClassContainer().height * yPosCoeff) / game.height) * 100,
      );
      villainsConversation._scaleClass(
        villain,
        bubbleScale,
      );
    });

    let difficulty = 'hard';
    if (isSuperHardLevel) {
      difficulty = 'super_hard';
    }

    stack.addPromise(() => villainsConversation.createTutorial(difficulty, 'post_corruption_level_window'));

    return stack;
  }

  /**
   * Create a group that copies the normal level window's looks
   */
  _createNormalWindowGroup() {
    const _extraLevelContainer = new Phaser.Group(game);

    G.makeImage(0, 0, 'popup_background_2', 0.5, _extraLevelContainer);

    G.makeImage(
      0, -295,
      'blue_banner',
      0.5,
      _extraLevelContainer,
    );

    const _levelTxt = new G.Text(0, -297, `${OMT.language.getText('Level')} ${G.lvlNr + 1}`, {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, 330);
    _extraLevelContainer.add(_levelTxt);

    const sideStarY = -150;
    const starsAchieved = G.saveState.getStars(G.lvlNr);
    const _stars = [
      G.makeImage(-100, sideStarY, starsAchieved >= 1 ? 'star' : 'star_blank', 0.5, _extraLevelContainer),
      G.makeImage(0, sideStarY - 25, starsAchieved >= 2 ? 'star' : 'star_blank', 0.5, _extraLevelContainer),
      G.makeImage(100, sideStarY, starsAchieved >= 3 ? 'star' : 'star_blank', 0.5, _extraLevelContainer),
    ];
    _stars[0].scale.setTo(0.8);
    _stars[2].scale.setTo(0.8);

    G.makeImage(0, 5, this._getTaskBgName(), 0.5, _extraLevelContainer);

    const taskTxtStyle = 'font-blue';
    const taskTxtY = -70;
    const _taskTxt = new G.Text(0, taskTxtY, `${OMT.language.getText('Task')}:`, {
      style: taskTxtStyle,
      fontSize: '45px',
    }, 0.5, 380);
    _extraLevelContainer.add(_taskTxt);

    if (this.levelData.goal[0] === 'collect') {
      const _taskPanel = G.Helpers.createTaskCollectPanels(this.levelData.goal[1]);
      _taskPanel.y = 5;
      _extraLevelContainer.add(_taskPanel);
    } else {
      const _taskPanel = new G.Text(0, 5, `${OMT.language.getText('points').toUpperCase()}: ${this.levelData.goal[1]}`, {
        style: taskTxtStyle,
        fontSize: '50px',
      }, 0.5, 380);
      _extraLevelContainer.add(_taskPanel);
    }

    G.makeImage(0, 170, this._getTaskBgName(), 0.5, _extraLevelContainer);

    let movesBoosterType = 5;
    if (G.IAP) {
      movesBoosterType = 6;
    }
    const sideBoosterX = 195;
    const lvlNumber = G.lvlNr;
    const windowType = 'normal';
    const _boosters = [
      new UI_StartBoosterButton(this, -sideBoosterX, 170, movesBoosterType, lvlNumber, windowType, { layerName: this.parent.layerName, args: [lvlNumber] }, false),
      new UI_StartBoosterButton(this, 0, 170, 7, lvlNumber, windowType, { layerName: this.parent.layerName, args: [lvlNumber] }, this.levelData.noPreBoosters),
      new UI_StartBoosterButton(this, sideBoosterX, 170, 8, lvlNumber, windowType, { layerName: this.parent.layerName, args: [lvlNumber] }, this.levelData.noPreBoosters),
    ];
    _extraLevelContainer.addMultiple(_boosters);
    _extraLevelContainer.ignoreChildInput = true;

    return _extraLevelContainer;
  }

  /**
   * Returns proper asset used for the task bg
   * @returns {string}
   */
  _getTaskBgName() {
    return 'popup_bigtext_backgr';
  }

  /**
   * Check and see if we need to give the player any temporary boosters for the villains tutorial flow
   */
  _checkVillainsTutorialBoosters() {
    const { _lvlIndex } = this;
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    G.saveState.villainsDataManager.resolveTemporaryBoosters(true);
    if (!isNotNormalLevel || !OMT.feature.isVillainsEnabled()) return;
    const curLevel = G.saveState.getLastPassedLevelNr();
    const villainsLevelWindowTutorial = G.saveState.villainsDataManager.getTutorialStatus('hard', 'level_window');
    if (!villainsLevelWindowTutorial && _lvlIndex === curLevel) {
      let movesBoosterType = 5;
      if (G.IAP) {
        movesBoosterType = 6;
      }
      const boosters = G.saveState.getBoosterArray();

      const movesBoosterCount = boosters[movesBoosterType];
      let movesBoosterDiff;
      const secondBoosterCount = boosters[7];
      let secondBoosterDiff;
      const thirdBoosterCount = boosters[8];
      let thirdBoosterDiff;

      const _boosters = [...boosters];
      if (movesBoosterCount < temporaryBoosterCap) {
        _boosters[movesBoosterType] = temporaryBoosterCap;
        movesBoosterDiff = temporaryBoosterCap - movesBoosterCount;
      }
      if (secondBoosterCount < temporaryBoosterCap) {
        _boosters[7] = temporaryBoosterCap;
        secondBoosterDiff = temporaryBoosterCap - secondBoosterCount;
      }
      if (thirdBoosterCount < temporaryBoosterCap) {
        _boosters[8] = temporaryBoosterCap;
        thirdBoosterDiff = temporaryBoosterCap - thirdBoosterCount;
      }

      if (movesBoosterDiff || secondBoosterDiff || thirdBoosterDiff) {
        if (movesBoosterDiff) {
          G.saveState.villainsDataManager.setTemporaryBoosterCount(movesBoosterType, movesBoosterDiff);
        }
        if (secondBoosterDiff) {
          G.saveState.villainsDataManager.setTemporaryBoosterCount(7, secondBoosterDiff);
        }
        if (thirdBoosterDiff) {
          G.saveState.villainsDataManager.setTemporaryBoosterCount(8, thirdBoosterDiff);
        }
        G.saveState.villainsDataManager.setTemporaryBoosters([...boosters]);
        G.saveState.setBoosterArray(_boosters);
      }
    }
  }

  /**
   * Destroy
   */
  destroy() {
    if (this._onResizeSB && this._onResizeSB.detach) {
      this._onResizeSB.detach();
    }
    if (this._corruptWindow && this._corruptWindow.detach) {
      this._corruptWindow.detach();
    }
    if (this._selectorClickedBinding && this._selectorClickedBinding.detach) {
      this._selectorClickedBinding.detach();
    }
    super.destroy();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.level = Window_Level;
