import OMT_VILLAINS from '../../../OMT_UI/OMT_Villains';
import UI_BoosterPanel from './Boosters/UI_BoosterPanel';
import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';

const SIZE_SCALE = 0.90;
export class UI_BoosterButtonHorizontal extends UI_BoosterPanel {
  /**
   * The booster panel on the bottom of the game screen
   * @param {Board} board
   * @param {string} gameMode
   */
  constructor(board, gameMode) {
    super(board, gameMode);

    this._gameScale = GameScaleController.getInstance().gameScale;
    this._scaleFactor = OMT.systemInfo.orientation === ORIENTATION.horizontal
      ? SIZE_SCALE * this._gameScale
      : SIZE_SCALE;

    this.scale.setTo(this._scaleFactor);
  }

  /**
   * Draws everything
   */
  _drawEverything() {
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();

    const {
      isTokenEventLevel,
      isTournamentLevel,
      isDailyChallengeLevel,
      isTreasureHuntLevel,
    } = LvlDataManager.getInstance();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;

    let _bgAssetName = 'horizontal-boosterbgNormal';
    if (isTournamentLevel) {
      _bgAssetName = 'horizontal-boosterbgTournament';
    } else if (isTokenEventLevel) {
      _bgAssetName = G.OMTsettings.tokenEvent.gameBoard.boosterBar.horizontal;
    } else if (isTreasureHuntLevel) {
      _bgAssetName = 'horizontal-boosterbgTreasureHunt';
    } else if (isNotNormalLevel) {
      _bgAssetName = useSuperHardGraphics ? 'horizontal-boosterbgSuperHard' : 'horizontal-boosterbgHard';
    }

    this._bg = G.makeImage(0, 0, _bgAssetName, 0.5, this);
    this._bgWidth = this._bg.width * SIZE_SCALE; // Calculate actual panel width with scaling

    const startingY = -250;
    const incrementY = 165;
    this.boostersBtn = [];
    for (let i = 0; i < 4; i++) {
      const yPos = startingY + (incrementY * i);
      const booster = this._makeBoosterBtn(0, yPos, i + 1, isTournamentLevel);
      booster.orgScaleX = 1.55;
      booster.orgScaleY = 1.55;
      booster.scale.set(1.55);
      booster._boosterActiveOffset = 0;
      booster.orgX = 0;
      booster.orgY = yPos;

      booster.select = () => {
        G.sb('startOverlay').dispatch([
          ['clearBoard'],
          ['moveToAboveGroup', booster, 'boosterGroup', this._scaleFactor],
        ]);

        booster._selected = true;
        game.add.tween(booster).to({ angleMulti: 1 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
      };

      // const shadow = G.makeImage(booster.x, booster.y + 20, 'bottom_ui_shadow', 0.5, this._bg),
      this.boostersBtn.push(booster);
    }

    this._shadows = [
      G.makeImage(0, -20, 'bottom_ui_shadow', 0.5, null),
      G.makeImage(0, -20, 'bottom_ui_shadow', 0.5, null),
      G.makeImage(0, -20, 'bottom_ui_shadow', 0.5, null),
      G.makeImage(0, -20, 'bottom_ui_shadow', 0.5, null),
      G.makeImage(0, -20, 'bottom_ui_shadow', 0.5, null),
    ];

    // Accessed externally
    // this.boostersBtn = [
    //   this._makeBoosterBtn(290, -64, 1, isTournamentLevel),
    //   this._makeBoosterBtn(480, -64, 2, isTournamentLevel),
    //   this._makeBoosterBtn(860, -64, 3, isTournamentLevel),
    //   this._makeBoosterBtn(670, -64, 4, isTournamentLevel),
    // ];
  }

  /**
   * Hides away
   */
  animatePositionToHidden() {
    const durationMs = 333;
    const hideDistance = this._bgWidth;
    game.add.tween(this).to({ x: game.world.bounds.x + game.width - (this._bgWidth * this._gameScale) / 2 + hideDistance }, durationMs, Phaser.Easing.Cubic.Out, true);
  }

  /**
   * Shows up
   */
  animatePositionToVisible() {
    const durationMs = 333;
    game.add.tween(this).to({ x: game.world.bounds.x + game.width - (this._bgWidth * this._gameScale) / 2 }, durationMs, Phaser.Easing.Cubic.Out, true);
  }

  /**
   * Repositions accordingly to the center
   */
  _onScreenResize() {
    this.x = game.world.bounds.x + game.width - (this._bgWidth * this._gameScale) / 2;
    this.y = game.world.bounds.y + game.height / 2 + 15;
  }
}
