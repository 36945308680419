G.WinStarPart = function(x,y,autostart) {

	Phaser.Image.call(this,game,x,y);
	G.changeTexture(this,'starPart');
	this.anchor.setTo(0.5);
	this.visible = false;

	this.scale.setTo(1.5);

	this.grav = 0.75;

	if (autostart) {
		this.start();
	}else {
		this.visible = false;
	}

};

G.WinStarPart.prototype = Object.create(Phaser.Image.prototype);

G.WinStarPart.prototype.start = function() {
	this.visible = true;
	this.spdX = (Math.random()*25)-12.5;
	this.spdY = (Math.random()*-15)-5;
	this.angle = Math.random()*360;
};

G.WinStarPart.prototype.update = function() {
	
	if (this.visible) {
		this.x += G.deltaTime * this.spdX;
		this.y += G.deltaTime * this.spdY;
		this.spdX -= G.deltaTime * (1 - 0.98);
		this.angle += G.deltaTime * this.spdX;
		this.spdY += G.deltaTime * this.grav;
		this.alpha -= G.deltaTime * 0.02;
		if (this.alpha <= 0) {
			this.destroy();
		}
	}


};