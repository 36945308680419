import { TokenLayer } from './TokenLayer';
import { TOKEN_TYPES, EDITOR_SYMBOLS } from '../BoardConstants';
import { BoardToken_Dirt } from '../Tokens/BoardToken_Dirt';

/**
 * TokenLayer for BoardToken_Dirt instances.
 */
export class TokenLayer_Dirt extends TokenLayer {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    super(board, {
      constructor: BoardToken_Dirt,
      maxHp: 3,
      hpToken: true,
      editorSymbol: EDITOR_SYMBOLS.DIRT,
      collectableType: TOKEN_TYPES.DIRT,
    });
  }
}
