import { TOKEN_TYPES } from '../BoardConstants';

/**
 * Cutomized grid away with checks for temporary match tokens
 */
export class MatcherGridArray extends G.GridArray {
  /**
   * alternate set function, not sure if its really needed
   * @param {number} x
   * @param {number} y
   * @param {any} val
   */
  set(x, y, val) {
    if (this.isInGrid(x, y)) {
      if (!this.data[x][y]) {
        this.data[x][y] = val;
      } else if (this.data[x][y] === TOKEN_TYPES.TEMP_MATCH && val !== TOKEN_TYPES.TEMP_MATCH) {
        this.data[x][y] = val;
      }
    }
  }
}
