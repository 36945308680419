/* eslint-disable no-return-assign */
import WorldMap2_Util from '../WorldMap2_Util';

export default class WorldMapBackgroundTiles2 extends G.BatchPoolGroup {
  /**
   * The poolgroup for the background tiles
   * (Not really a tile since its just a piece of a path)
   */
  constructor() {
    // eslint-disable-next-line no-use-before-define
    super(BackgroundTile);

    this._backgroundSprites = WorldMap2_Util.getTileSprites(); // Gets string array of tile sprites

    // Calculates average height and width of tile sprites for external calculations. Collects it from cache
    this._totalMapHeight = this._backgroundSprites.reduce((total, sprite) => total += game.cache.getFrameByName('mapsheet', sprite).height, 0);
    this._averageMapTileHeight = Math.round(this._totalMapHeight / this._backgroundSprites.length);
    this._averageMapTileWidth = Math.round(this._backgroundSprites.reduce((total, sprite) => total += game.cache.getFrameByName('mapsheet', sprite).width, 0) / this._backgroundSprites.length);
    this._activeSegments = [];
  }

  /**
   * @returns {Array<BackgroundTile>}
   */
  get actives() {
    return this._activeSegments;
  }

  /**
   * Returns average tile map height
   * @returns {number}
   */
  get averageTileMapHeight() {
    return this._averageMapTileHeight;
  }

  /**
   * Returns average tile map width
   * @returns {number}
   */
  get averageTileMapWidth() {
    return this._averageMapTileWidth;
  }

  /**
   * Moves tiles based on deltaY
   * @param {number} deltaY
   */
  moveTiles(deltaY) {
    // update active segments
    for (let i = this._activeSegments.length - 1; i >= 0; i--) {
      this._activeSegments[i].y += deltaY;
    }
  }

  /**
   * Kills tile at index
   * @param {number} index
   */
  killTile(index) {
    const tile = this._activeSegments.find((seg) => seg.index === index);
    if (tile) {
      tile.kill();
      this._activeSegments.splice(this._activeSegments.indexOf(tile), 1);
    }
  }

  /**
   * Adds tile at position index and places it on the yPos position
   * @param {number} index
   * @param {number} yPos
   * @returns {BackgroundTile}
   */
  addTile(index, yPos) {
    const chosenSprite = this.getTextureStringOfIndex(index);
    const mapSegment = this.getFreeElement();
    mapSegment.init(index, chosenSprite);
    mapSegment.y = yPos;
    this._activeSegments.push(mapSegment);
    this.addChild(mapSegment);
    return mapSegment;
  }

  /**
   * Returns texture index number based on tile index
   * @param {number} index
   * @returns {number}
   */
  getTextureIndexOfIndex(index) {
    return (((index % this._backgroundSprites.length)) + this._backgroundSprites.length) % this._backgroundSprites.length;
  }

  /**
   * Returns texture string of tile at index
   * @param {number} index
   * @returns {number}
   */
  getTextureStringOfIndex(index) {
    return this._backgroundSprites[this.getTextureIndexOfIndex(index)];
  }

  /**
   * Checks if the tile on index could be a river
   * @param {number} index
   */
  isIndexARiver(index) {
    return this.getTextureStringOfIndex(index) === this._backgroundSprites[G.OMTsettings.elements.worldMap.riverTileExtra.index];
  }
}

class BackgroundTile extends Phaser.Sprite {
  /**
   * The Background tile image asset
   */
  constructor() {
    super(game);
    this._height = 0;
    this._stitchingOffset = 0.6;
  }

  /**
   * init / re-init tile
   * @param {number} index
   * @param {string} textureId
   */
  init(index, textureId) {
    this.scale.y = 1;

    this.index = index;
    G.changeTexture(this, textureId);
    this.revive();

    // force a height update with stitching adjustment.
    this.height = super.height;
  }

  /**
   * set y value, but round drawing
   * @param {number} value
   */
  set y(value) {
    this._yPos = value;
    super.y = this._yPos - (this._stitchingOffset / 2);
  }

  /**
   * get y value
   */
  get y() {
    return this._yPos;
  }

  /**
   * get original height value non-adjusted
   * @returns {number}
   */
  get height() {
    return this._height;
  }

  /**
   * set height value. adjusted for tile stitching issues
   * @param {number} value
   */
  set height(value) {
    this._height = value;
    super.height = value + this._stitchingOffset;
  }
}
