const DEFAULT_CONFIG = {
  wheelRotation: 0,
  wheelSegments: 24,
  bgAsset: 'gold_wheel',
  bgOffsetAngle: 0,
  bgScale: { x: 1, y: 1 },
};

export const SPIN_STATES = {
  IDLE: 0,
  SPINNING: 1,
  SPIN_TO_PRIZE: 2,
};

const DEG_360 = 360;

const MINIMUM_SPIN_FULL_ROTATIONS = 1;
const TIME_FOR_FULL_ROTATION = 800;
const MAX_SPIN_VELOCITY = DEG_360 * ((1000 / TIME_FOR_FULL_ROTATION) / 60);
const MIN_SPIN_VELOCITY = 0.01;
const BOUNCE_ELASTICITY = 0.6;

const SPIN_TO_PRIZE_MAX_TIMESTEP = 0.5;
const SPIN_TO_PRIZE_FRICTION = 0.98;
const IDLE_FRICTION = 0.93;
const SPINNING_ACCELERATION = 0.15;

const OVERLAY_OFFSET_ANGLE = -90;

/**
 * Class for the spinning wheel component of UI_PrizeWheel
 */
export default class PrizeWheel_Spinner extends Phaser.Group {
  /**
   * constructor
   *  @param {Object} config (optional) overrides for DEFAULT_CONFIG
   */
  constructor(config) {
    super(game);

    this._config = _.merge(_.cloneDeep(DEFAULT_CONFIG), config);

    this._state = SPIN_STATES.IDLE;
    this._spinVelocity = 0;

    const { wheelSegments, wheelRotation } = this._config;
    this._wheelRotation = wheelRotation;
    this._segmentSize = DEG_360 / wheelSegments;

    this._createWheelBackground();

    this.setIdleState();

    this.signals = {
      onHitPeg: new Phaser.Signal(),
      onSpinToPrizeCompleted: new Phaser.Signal(),
      onMaxSpinVelocityReached: new Phaser.Signal(),
    };

    this.update();
  }

  /**
   * create wheel background graphics.
   */
  _createWheelBackground() {
    const bg = G.makeImage(0, 0, this._config.bgAsset, 0.5, null);
    this.addChildAt(bg, 0);
    this._bg = bg;

    const { bgOffsetAngle, bgScale } = this._config;
    bg.angle += bgOffsetAngle;
    bg.scale.x = bgScale.x;
    bg.scale.y = bgScale.y;
  }

  /**
   * get the active state as defined in SPIN_STATES
   * @returns {Number}
   */
  get state() {
    return this._state;
  }

  /**
   * set SPIN_STATES.IDLE
   */
  setIdleState() {
    this._state = SPIN_STATES.IDLE;
  }

  /**
   * update SPIN_STATES.IDLE
   */
  _updateIdleState() {
    this._spinVelocity -= (1 - IDLE_FRICTION) * this._spinVelocity * G.deltaTime;
    this._wheelRotation += this._spinVelocity;
  }

  /**
   * set SPIN_STATES.SPINNING
   */
  setSpinningState() {
    if (this._state !== SPIN_STATES.IDLE) return;
    this._state = SPIN_STATES.SPINNING;
  }

  /**
   * update SPIN_STATES.SPINNING
   */
  _updateSpinningState() {
    const prevVelocity = this._spinVelocity;
    this._spinVelocity = Math.min(prevVelocity + (SPINNING_ACCELERATION * G.deltaTime), MAX_SPIN_VELOCITY);
    this._checkIfPegHit(this._wheelRotation, this._spinVelocity);
    this._wheelRotation += this._spinVelocity;

    // dispatch an event when max velocity is reached
    if (prevVelocity < MAX_SPIN_VELOCITY && this._spinVelocity >= MAX_SPIN_VELOCITY) {
      this.signals.onMaxSpinVelocityReached.dispatch(this._spinVelocity);
    }
  }

  /**
   * set SPIN_STATES.SPIN_TO_PRIZE
   * @param {number} itemAngle angle of prize in degrees
   */
  setSpinToPrizeState(itemAngle) {
    this._state = SPIN_STATES.SPIN_TO_PRIZE;

    // itemAngle / bounceAngle are untranslated and do not match the current wheel rotation
    const bounceAngle = Math.floor(itemAngle / this._segmentSize) * this._segmentSize;

    // calculate where we want to spin to accounting for current rotation.
    const minFullRotations = Math.ceil(this._wheelRotation / DEG_360) + MINIMUM_SPIN_FULL_ROTATIONS;
    this._spinTargetAngle = (minFullRotations * DEG_360) + (DEG_360 - itemAngle);
    this._spinBounceAngle = (minFullRotations * DEG_360) + (DEG_360 - bounceAngle);

    // we add some randomized distance to the spin target allowing it to overshoot its target
    this._spinToAngle = this._spinTargetAngle + ((this._segmentSize * Math.random()));

    // calculate how much distance we need to stop the wheel based on current velocity.
    this._toSpinDistance = this._spinToAngle - this._wheelRotation;
    this._rotationForFullStop = this._calcRequiredRotationToFullStop(1, SPIN_TO_PRIZE_FRICTION);
  }

  /**
   * update SPIN_STATES.SPIN_TO_PRIZE
   */
  _updateSpinToPrizeState() {
    const { deltaTime } = G;
    let timePassed = 0; let timeStep;
    let deltaAngle; let nextWheelRotation;

    // we step through time gradually to not skip a beat at high deltaTimes.
    do {
      timeStep = Math.min(deltaTime - timePassed, SPIN_TO_PRIZE_MAX_TIMESTEP);
      timePassed += timeStep;
      deltaAngle = this._spinVelocity * timeStep;
      nextWheelRotation = this._wheelRotation + deltaAngle;
      this._toSpinDistance -= Math.abs(deltaAngle);

      // bounce if goal overshot, this happens for visual flare not a bug
      if (nextWheelRotation > this._spinBounceAngle && this._spinVelocity > 0) {
        deltaAngle = this._spinBounceAngle - nextWheelRotation;
        nextWheelRotation = this._spinBounceAngle + deltaAngle;
        this._spinVelocity *= -BOUNCE_ELASTICITY;
        this._onPegHit(deltaAngle);
      }

      // check for when the pegs on the wheel hit pointer
      this._checkIfPegHit(this._wheelRotation, deltaAngle);
      // update wheel rotation
      this._wheelRotation = nextWheelRotation;

      // apply friction to stop at desired location
      if (this._toSpinDistance <= this._rotationForFullStop) {
        this._spinVelocity -= (1 - SPIN_TO_PRIZE_FRICTION) * deltaAngle;
      }
    } while (timePassed < deltaTime);

    // wheel has stopped at desired location
    if (Math.abs(this._spinVelocity) < MIN_SPIN_VELOCITY) {
      this._spinVelocity = 0;
      this.setIdleState();
      this.signals.onSpinToPrizeCompleted.dispatch();
    }
  }

  /**
   * calculate the rotation for the wheel to come to a full stop.
   * @param {number} timeStep
   * @param {number} friction
   */
  _calcRequiredRotationToFullStop(timeStep, friction) {
    let rotation = 0;
    let spinVelocity = this._spinVelocity;
    while (spinVelocity > MIN_SPIN_VELOCITY) {
      spinVelocity -= (1 - friction) * spinVelocity * timeStep;
      rotation += spinVelocity;
    }
    return rotation;
  }

  /**
   * check if a peg is hit between to angles and dispatch a signal if so.
   * @param {number} fromAngle
   * @param {number} deltaAngle
   */
  _checkIfPegHit(fromAngle, deltaAngle) {
    const fromSegement = Math.floor(fromAngle / this._segmentSize);
    const toSegement = Math.floor((fromAngle + deltaAngle) / this._segmentSize);
    if (toSegement !== fromSegement) this._onPegHit(deltaAngle);
  }

  /**
   * dispatch a signal when a peg is hit
   * @param {number} deltaAngle
   */
  _onPegHit(deltaAngle) {
    this.signals.onHitPeg.dispatch(deltaAngle);
  }

  /**
   * Phaser update method.
   */
  update() {
    super.update();

    if (this._state === SPIN_STATES.IDLE) {
      this._updateIdleState();
    } else if (this._state === SPIN_STATES.SPINNING) {
      this._updateSpinningState();
    } else if (this._state === SPIN_STATES.SPIN_TO_PRIZE) {
      this._updateSpinToPrizeState();
    }

    this.angle = this._wheelRotation;
  }

  /**
   * add a overlay to spin with the wheel.
   * @param {Phaser.DisplayObject}
   */
  addOverlay(overlay) {
    if (this._overlay != null) {
      this.removeChild(this._overlay);
    }
    overlay.angle = OVERLAY_OFFSET_ANGLE;
    this.addChild(overlay);
    this._overlay = overlay;
    // this._overlay.cacheAsBitmap = true;
  }

  /**
   * Changes wheel background asset
   * @param {string} asset key of new wheel background
   */
  changeWheelBackground(asset) {
    G.changeTexture(this._bg, asset);
  }

  /**
   * destruciton method
   */
  destroy() {
    super.destroy();
    for (const signal of Object.values(this.signals)) signal.dispose();
  }
}
