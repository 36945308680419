import { StarDisplayGroup } from '../../Components/Displays/StarDisplayGroup';
import { CollapsedUserViewBase } from '../../Components/CollapsedView/CollapsedUserViewBase';
import { AnimatedCoinButton } from '../../Components/Buttons/AnimatedCoinButton';
import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

import {
  FRIENDS_PANEL_COLLAPSED_USER_VIEW_RECT,
  FRIENDS_PANEL_COLLAPSED_USERNAME_POS,
  FRIENDS_PANEL_COLLAPSED_USERNAME_MAX_WIDTH,
  FRIENDS_PANEL_COLLAPSED_USERNAME_STYLE,
  FRIENDS_PANEL_COLLAPSED_AVATAR_SIZE,
  FRIENDS_PANEL_COLLAPSED_AVATAR_POS,
  FRIENDS_PANEL_COLLAPSED_SCORE_POS,
  FRIENDS_PANEL_COLLAPSED_COIN_SCALE,
  FRIENDS_PANEL_COLLAPSED_COIN_POS,
  COIN_REQUEST_COOLDOWN,
} from '../../MainLeaderboardSettings';

export const COIN_REQUEST_STATUS = {
  REQUESTED: 0,
  SUCCESS: 1,
  FAILED: 2,
};

/**
 * collapsed user view for friends highscores and requesting coins
 */
export class FriendsScoresCollapsedUserView extends CollapsedUserViewBase {
  /**
   * main initialization method
   * @param {Object} friendData friendData to display
   */
  init(friendData) {
    // create signals
    this.signals.onCoinRequest = new Phaser.Signal();

    // init user view displays
    super.init(friendData, FRIENDS_PANEL_COLLAPSED_USER_VIEW_RECT);
    this._initStarDisplayGroup();
    this._initNameField();

    // set coin request variables and buttons
    const { deviceIsIOS } = OMT.systemInfo;
    const isYourUserId = friendData.userId === OMT.envData.settings.user.userId;
    this._coinRequestEnabled = !isYourUserId && !deviceIsIOS;
    if (this._coinRequestEnabled) {
      this._initCoinRequestButton();
      this._initCoinAnimation();
      this._setCoolDownParamsAndUpdate(COIN_REQUEST_COOLDOWN.id, COIN_REQUEST_COOLDOWN.duration);
    }

    // cache content container
    this.cacheContent = true;
  }

  /**
   *initialize the user avatar group inside a button so its clickable
   */
  _initAvatar() {
    super._initAvatar(0, 0, FRIENDS_PANEL_COLLAPSED_AVATAR_SIZE, this._friendData.rank);
    this._avatar.x = FRIENDS_PANEL_COLLAPSED_AVATAR_POS.x;
    this._avatar.y = FRIENDS_PANEL_COLLAPSED_AVATAR_POS.y;
  }

  /**
   * initialize the score field and related graphics
   */
  _initStarDisplayGroup() {
    this._starGroup = new StarDisplayGroup(this._friendData.score);
    this._starGroup.width = FRIENDS_PANEL_COLLAPSED_USER_VIEW_RECT.width;
    this._starGroup.scale.y = this._starGroup.scale.x;
    this._starGroup.x = FRIENDS_PANEL_COLLAPSED_SCORE_POS.x;
    this._starGroup.y = FRIENDS_PANEL_COLLAPSED_SCORE_POS.y;
    this._cacheableContent.addChild(this._starGroup);
  }

  /**
   * initialize the user name field
   */
  _initNameField() {
    this._nameField = new G.Text(
      FRIENDS_PANEL_COLLAPSED_USERNAME_POS.x,
      FRIENDS_PANEL_COLLAPSED_USERNAME_POS.y,
      this._shortenName(this._friendData.name),
      FRIENDS_PANEL_COLLAPSED_USERNAME_STYLE,
      [0.5, 0.5], // anchor
      FRIENDS_PANEL_COLLAPSED_USERNAME_MAX_WIDTH,
    );
    this._cacheableContent.addChild(this._nameField);
  }

  /**
   * init the coin animation display
   */
  _initCoinAnimation() {
    const coinAnimation = new AnimatedCoinButton(() => {});
    coinAnimation.inputEnabled = false;
    coinAnimation.scale.set(FRIENDS_PANEL_COLLAPSED_COIN_SCALE);
    coinAnimation.x = FRIENDS_PANEL_COLLAPSED_AVATAR_POS.x + FRIENDS_PANEL_COLLAPSED_COIN_POS.x;
    coinAnimation.y = FRIENDS_PANEL_COLLAPSED_AVATAR_POS.y + FRIENDS_PANEL_COLLAPSED_COIN_POS.y;
    this.addChild(coinAnimation);
    this._coinAnimation = coinAnimation;
  }

  /**
   * init the coin request button, _avatarGroup is used for the actual click for this view
   */
  _initCoinRequestButton() {
    const coinRequestButton = new UI_GButtonES6(0, 0, null, () => { this._onCoinRequestClicked(); });
    coinRequestButton.createClickShape(FRIENDS_PANEL_COLLAPSED_AVATAR_SIZE, FRIENDS_PANEL_COLLAPSED_AVATAR_SIZE);
    coinRequestButton.x = FRIENDS_PANEL_COLLAPSED_AVATAR_POS.x;
    coinRequestButton.y = FRIENDS_PANEL_COLLAPSED_AVATAR_POS.y;
    this.addChild(coinRequestButton);
    this._coinRequestButton = coinRequestButton;
  }

  /**
   * get the position the coin reward animation should start from
   * @returns {Object}
   */
  getCoinAnimateFromPos() {
    return this.toGlobal({ x: this._coinRequestButton.x, y: this._coinRequestButton.y });
  }

  /**
   * called the coin request button is clicked
   * @returns {Promise}
   */
  async _onCoinRequestClicked() {
    if (this._isOnCooldown) return;
    this.signals.onCoinRequest.dispatch(this, COIN_REQUEST_STATUS.REQUESTED);
    OMT.social.sendCoinsMessage(this._friendData.userId, (result) => {
      if (result) {
        G.saveState.setUserCooldown(this._cooldownId, this._friendData.userId, this._cooldownDuration);
        this._setCooldownState();
        this.signals.onCoinRequest.dispatch(this, COIN_REQUEST_STATUS.SUCCESS);
      } else {
        this.signals.onCoinRequest.dispatch(this, COIN_REQUEST_STATUS.FAILED);
      }
    });
  }

  /**
   * set the active / normal state
   */
  _setActiveState() {
    super._setActiveState();
    this._coinAnimation.resume();
    this._coinAnimation.visible = true;
    this._coinRequestButton.enable();
  }

  /**
   * set the cooldown state
   */
  _setCooldownState() {
    super._setCooldownState();
    this._coinAnimation.pause();
    this._coinAnimation.visible = false;
    this._coinRequestButton.disable();
  }

  /**
   * main update method
   */
  update() {
    super.update();
    if (this._coinRequestEnabled) this._updateCooldownState();
  }
}
