import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';

const REFILL_TEXT = 'Refill your coins with this exclusive offer!';
const REFILL_TEXT_STYLE = 'window_popupOffer-titleTxt';
const REFILL_TEXT_X = 0;
const REFILL_TEXT_Y_VERTICAL = -340;
const REFILL_TEXT_Y_HORIZONTAL = -320;
const REFILL_MAX_WIDTH_VERTICAL = 410;
const REFILL_MAX_HEIGHT = 130;

/**
 * class for TargetedOffer refill text display.
 */
export class TargetedOffer_RefillText extends Phaser.Group {
  /**
   * constructor
   */
  constructor() {
    super(game);
    this._drawText();
  }

  /**
   * draw the text field
   */
  _drawText() {
    let targetWidth = REFILL_MAX_WIDTH_VERTICAL;
    let targetY = REFILL_TEXT_Y_VERTICAL;
    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      targetWidth = game.width * 0.7;
      targetY = REFILL_TEXT_Y_HORIZONTAL;
    }

    this._refillText = new G.Text(
      REFILL_TEXT_X, targetY,
      OMT.language.getText(REFILL_TEXT),
      REFILL_TEXT_STYLE,
      0.5,
      targetWidth,
      REFILL_MAX_HEIGHT,
      true, 'center', true, true, true,
    );
    this.addChild(this._refillText);
  }

  /**
   * hide the text field
   */
  hide() {
    this.visible = false;
  }
}
