import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { Window } from '../../00_IMMEDIATE/Window';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

class Window_noAdsPopup extends Window {
  constructor(parentWindow) {
    super(parentWindow);
    this._config = OMT.systemInfo.orientation === ORIENTATION.horizontal
      ? G.OMTsettings.elements.Window_noAdsPopup.horizontal
      : G.OMTsettings.elements.Window_noAdsPopup.vertical;

    this.x = this._config.window.x;
    this.y = this._config.window.y;

    if (game.state.current === 'Game') {
      this.y -= 100;
    }

    this.addTitle(this._config.title.text);
    this._initLayout();
    this._initButtons();
  }

  addTitle(text) {
    this._title = new G.Text(
      this._config.title.x, this._config.title.y,
      OMT.language.getText(text),
      this._config.title.style,
      0.5,
      this._config.title.maxWidth,
    );

    this.add(this._title);
    window.dyk = this._title;
  }

  _initLayout() {
    this.addGeneric9SliceBackground(this._config.background.width, this._config.background.height);

    this._deal = G.makeImage(this._config.dealChest.x, this._config.dealChest.y, 'shop3_specialDeal1', 0.5, this);
    this._deal.scale.setTo(this._config.dealChest.scale);

    this._gingy = G.makeImage(this._config.gingy.x, this._config.gingy.y, 'gingy_noAds', 0.5, this);
    this._gingy.scale.setTo(this._config.gingy.scale);

    this._adCrossed = G.makeImage(this._config.adCrossed.x, this._config.adCrossed.y, 'adCrossed', 0.5, this);
    this._adCrossed.scale.setTo(this._config.adCrossed.scale);

    this._message = new G.Text(
      this._config.message.x, this._config.message.y,
      OMT.language.getText(this._config.message.text),
      this._config.message.style,
      [0.5, 0], this._config.message.maxWidth, this._config.message.maxHeight, true, 'center',
    );
    this.addChild(this._message);
  }

  _initButtons() {
    this._continueButton = new OMT_UI_SquareButton(this._config.continueButton.x, this._config.continueButton.y, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: this._config.continueButton.width,
          height: this._config.continueButton.height,
        },
      },
      text: {
        string: OMT.language.getText(this._config.continueButton.text),
        textStyle: this._config.continueButton.style,
      },
      options: {
        clickFunction: {
          onClick: () => this.closeWindow(null, null),
        },
        pulse: this._config.continueButton.pulse || null,
      },
    });

    this.registerButtons(this._continueButton);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.noAdsPopup = Window_noAdsPopup;
