import ShareMsgBase from './A_G.ShareMsgBase';

class MsgFriendshipChestInvite extends ShareMsgBase {
  /**
   * The message that is sent when you sent an invite to a friend to make them join through friendship chest
   * @param {string} user1AvatarUrl
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1AvatarUrl, onAllImagesReadyCallback = null) {
    super(2, onAllImagesReadyCallback);

    this.avatar1 = this._addExtImage(53, 109, user1AvatarUrl, 0, 132, null);
    this.bg = this._addExtImage(0, 0, 'img/Msg_FriendChest_Invite.png', 0, null, null);
  }
}

G.MsgFriendshipChestInvite = MsgFriendshipChestInvite;
