import MockInviteFriend from '../../../../OMT_UI/Menus/MockFacebookUI/InviteFriend/MockInviteFriend';
import { FRIENDSHIPCHESTCARD_STATUS } from '../FriendshipChest_Card';
import { SagaMap_Tutorial } from '../../../GingyTutorial/SagaMap_Tutorial';
import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../../States/Scaling/GameScaleController';

/**
 * @author Sandra Koo
 * This is a manager that handles the tutorial that shows up in the Friendship Chest
 * Its a different file to not mingle with the chest or the window
 * Though it does affect the things in there quite heavily
 */
export default class FriendshipChest_Tutorial_Manager {
  /**
   * @param {Object} config
   * @param {Window} config.window
   * @param {boolean} config.showGingy
   * @param {Friendship_Chestv2} config.module
   */
  constructor(config) {
    this.window = config.window;
    this.module = config.module;

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this.gingy = new SagaMap_Tutorial({ game, scale: 0.65 });
    if (!config.showGingy) this.gingy.visible = false;

    this._needToRedrawInv = false;

    // Set state of tutorial based on the state of the current card
    // The steps are not saved because if the card does not sync up, there can be issues
    this.curStep = -1;
    const moduleState = G.saveState.friendshipChestDataManager.findCardByIndex(this.module.currentPolaroid.index, true).s;
    switch (moduleState) {
      case FRIENDSHIPCHESTCARD_STATUS.OPEN: {
        if (this.module.currentPolaroid.index === 0) {
          this.curStep = 0;
        } else {
          this.curStep = 6;
        }
        break;
      }
      case FRIENDSHIPCHESTCARD_STATUS.PENDING: {
        this.curStep = 3;
        break;
      }
      case FRIENDSHIPCHESTCARD_STATUS.FRIEND: {
        this.curStep = 3;
        break;
      }
      case FRIENDSHIPCHESTCARD_STATUS.UNCLAIMED: {
        this.curStep = 4;
        break;
      }
      case FRIENDSHIPCHESTCARD_STATUS.CLAIMED: {
        this.curStep = 6;
        break;
      }
      default:
        this.curStep = -1;
        break;
    }
    if (this.curStep > -1) {
      this.showStep(this.curStep);
    }
  }

  /**
   * Destroy!!
   */
  destroy() {
    if (this.mockFB) {
      this.mockFB.destroy();
    }
    this.gingy.destroy();
  }

  /**
   * Shows the tutorial at a current step (currently assumes that its going in order)
   * specificFriend in step 2 is a MockFriendEntry
   * But in step 3 its an object of { image:Image, name: string } (that was made in MockFriendEntry)
   * @param {number} step
   * @param {MockFriendEntry} specificFriend
   */
  showStep(step, specificFriend) {
    // console.log('SHOW STEP', step);
    switch (step) {
      case 0: { // step 1 of JIRA
        this.gingy.displayMsg(G.json.tutorials.friendshipChestTutorial.step1);
        if (this.module.currentPolaroid.button) {
          this.gingy.setTarget(
            {
              display: this.module.currentPolaroid,
              target: this.module.button,
              offset: { x: 0, y: -this.module.button.height / 2 },
            },
            true,
          );
        }
        break;
      }
      case 1: { // Step 2 of JIRA
        const rtrTween = this.showFakebookFriendList();
        const firstButton = this.mockFB.getFirstEntryButton();
        this.mockFB.signals.onClick.addOnce(this.progressTutorial.bind(this)); // Tutorial progresses from input in the mockFB
        if (firstButton) {
          const theActualButton = firstButton.playButton;
          rtrTween.onComplete.addOnce(() => { // After the Fakebook comes in, THEN we set the hand/message
            this.gingy.setTarget({
              target: theActualButton,
              display: theActualButton.parent,
              offset: { x: -theActualButton.width / 2, y: -theActualButton.height / 2 },
            }, true);
            this.gingy.displayMsg(G.json.tutorials.friendshipChestTutorial.step2);
          });
        }
        break;
      }
      case 2: { // step 3 of JIRA
        const friend = specificFriend.copyImportantStuff();
        friend.image.parent.removeChild(friend.image); // Remove this. We'll need it.
        const tw = this.hideFakebookFriendList(); // Fakebook gets destroyed here
        this.gingy.setTarget(null); // No hand
        this.gingy.displayMsg(''); // No text
        this.gingy.toggleVisibility(false); // Disappear
        if (tw) { // When Fakebook disappears
          tw.onComplete.addOnce(() => {
            this.module.hideUI();
            this.module.drawInvitationSent(friend.image);
            this._needToRedrawInv = true;
            this.module.swapToPending();
            friend.image.visible = true; // Bring this back
            game.time.events.add(2200, () => { // 2secs + 200ms for animation
              this.progressTutorial(friend);
            }, this);
          });
        }
        break;
      }
      case 3: { // step 4 of JIRA
        this.animateStep3(specificFriend);
        break;
      }
      case 4: { // step 5 of JIRA
        if (this.module.currentPolaroid.button) {
          this.gingy.setTarget(
            {
              display: this.module.currentPolaroid,
              target: this.module.button,
              offset: { x: 0, y: -this.module.button.height / 2 },
            },
            true,
          );
        }
        break;
      }
      case 5: { // step 6 of JIRA
        this.gingy.setTarget(null);
        break;
      }
      case 6: { // Step (7 + 8 +) 9 of JIRA
        // New card transition finishes
        this.module.tutorialMode = false;
        G.saveState.friendshipChestDataManager.setTutorialDone();
        if (this._needToRedrawInv) {
          this.module.drawInvitationSent();
        }
        this.gingy.setTarget(null);

        game.time.events.add(700, () => {
          this.gingy.displayMsg(G.json.tutorials.friendshipChestTutorial.stepEnd);

          game.time.events.add(5000, () => {
            if (game) {
              this.gingy.toggleVisibility(false);
            }
          });
        });
        break;
      }
      default: this.gingy.displayMsg(G.json.tutorials.friendshipChestTutorial.step1); break;
    }
  }

  /**
   * Progresses the tutorial by 1 step
   * specificData is anything that needs to be passed into showStep.
   * specificData is either a MockFriendEntry or { image:Image, name: string } (that was made in MockFriendEntry)
   * specificData can also be a string from the Frienship_Chestv2
   * @param {Object} specificData
   */
  progressTutorial(specificData) {
    if (specificData === 'doneMoving' && this.curStep !== 5) return;
    if (specificData === 'finishedRequestStep') {
      G.saveState.friendshipChestDataManager.setTutorialRequestFinished();
    } else {
      G.saveState.friendshipChestDataManager.setTutorialStarted();
      this.curStep++;
      this.showStep(this.curStep, specificData);
    }
  }

  /**
   * Creates the MockInviteFriend (Fakebook) display and animates it in
   * Returns the tween so that the things outside of it can attach stuff to its onComplete
   * @returns {Phaser.Tween}
   */
  showFakebookFriendList() {
    if (!this.mockFB) {
      this.mockFB = new MockInviteFriend({
        friendCount: 6,
        friends: G.OMTsettings.elements.mockFacebookFriends.friends,
      });
    }
    this.mockFB.alpha = 0;
    let oriY = this.mockFB.friendBg.y - 110; // 130 = header height (50) + approx height of 2 entries (50) + padding
    this.mockFB.friendBg.y = game.height + this.mockFB.friendBg.height;
    if (this._isLandscape) {
      this.mockFB.scale.setTo(this._gameScale);
      oriY += 30;
    }

    this.window.addChild(this.mockFB);
    game.add.tween(this.mockFB)
      .to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.InOut, true);
    return game.add.tween(this.mockFB.friendBg)
      .to({ y: oriY }, 500, Phaser.Easing.Sinusoidal.InOut, true);
  }

  /**
   * Hides the Fakebook and destroys it once its gone
   * Returns the tween so that the things outside of it can attach stuff to its onComplete
   * Or nothing at all if mockFB wasn't there
   * @returns {Phaser.Tween}
   */
  hideFakebookFriendList() {
    if (this.mockFB) {
      const tw = game.add.tween(this.mockFB.friendBg)
        .to({ y: game.height + this.mockFB.friendBg.height }, 500, Phaser.Easing.Sinusoidal.InOut, true);
      tw.onComplete.addOnce(() => {
        const tw2 = game.add.tween(this.mockFB)
          .to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true);
        tw2.onComplete.addOnce(() => {
          this.window.removeChild(this.mockFB);
          this.mockFB.destroy();
          this.mockFB = null;
        });
      });
      return tw;
    }
    return null;
  }

  /**
   * Animates and modifies the card in step 3 so that we can see the card change state
   * in a nice tween instead of a jarring face change
   * @param {Object} specificFriend
   * @param {Image} specificFriend.image
   * @param {string} specificFriend.name
   */
  animateStep3(specificFriend) {
    const cardData = G.saveState.friendshipChestDataManager.findCardByIndex(this.module.currentPolaroid.index);
    cardData.s = FRIENDSHIPCHESTCARD_STATUS.FRIEND;
    this.module.aniamteTutorialFriendIn({
      name: specificFriend.name,
      image: specificFriend.image,
    });
  }
}
