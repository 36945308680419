import { EDITOR_SYMBOLS, WALL_DIRECTIONS } from './BoardConstants';

const DEFAULT_REFILL_DATA = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1];

/**
 * class for managing falling columns and candies
 */
export class BoardFallManager {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    this._board = board;
    this._boardData = board.boardGridData;
    this._refiller = board.refiller;
    this._refillData = DEFAULT_REFILL_DATA.slice();
  }

  /**
   * set all columns to fall
   */
  setAllColumnsFall() {
    this._refillData = DEFAULT_REFILL_DATA.slice();
    for (let i = 0; i < this._boardData.width; i++) this._setColumnFall(i);
  }

  /**
   * set single column to fall
   * @param {number} col
   */
  _setColumnFall(col) {
    const board = this._board;
    const boardData = this._boardData;
    const { walls } = this._board;

    for (let row = boardData.height - 1; row >= 0; row--) {
      // check if this cell is in board
      if (boardData.get(col, row) === EDITOR_SYMBOLS.BLANK) continue;
      // if there is no candy on cell
      if (!board.getCandy(col, row) && !board.iceLayer.isToken(col, row)) {
        let candyToFall;
        for (let rowCheck = row; rowCheck >= 0; rowCheck--) { // try to find candy above
          candyToFall = board.getCandy(col, rowCheck);
          // check for walls blocking above if no candy present
          if (!candyToFall && (walls.doesCellPosHaveWallAtDir(col, rowCheck - 1, WALL_DIRECTIONS.SOUTH) || walls.doesCellPosHaveWallAtDir(col, rowCheck, WALL_DIRECTIONS.NORTH))) {
            candyToFall = true; break;
          }
          // falling is blocked
          if (board.isMoveBlocked(col, rowCheck) || (candyToFall && candyToFall.isFallingBlocked())) {
            candyToFall = true; break;
          }
          // falling is OK set the fall desitnation
          if (candyToFall && board.isMoveable(col, rowCheck)) {
            candyToFall.fallTo(col, row); break;
          }
        }
        // no candy to fall add a refill
        if (!candyToFall) board.newFallingCandy(col, row, this._refiller.getTypeToDrop(col), this._refillData[col]--);
      }
    }
  }

  /**
   * set cross column / diagnal fall
   * @returns {boolean} success
   */
  setCrossColumnFall() {
    let wasAnyCandyMoved = false;
    let val = 1;
    for (let row = this._boardData.height - 1; row >= 0; row--) {
      for (let col = 0; col < this._boardData.width; col++) {
        if (this._isSpaceFreeAndCanMovedTo(col, row)) {
          if (this._isCellSolid(col, row + 1)) {
            for (let rowToPull = row - 1; rowToPull >= 0; rowToPull--) {
              if (this._tryToPullCandyFromTopSide(col, row, val, rowToPull - row) || this._tryToPullCandyFromTopSide(col, row, -val, rowToPull - row)) {
                wasAnyCandyMoved = true; break;
              }
              if (!this._isSpaceFreeAndCanMovedTo(col, rowToPull)) break;
            }
            val *= -1; // alternates pull direction
          }
        }
      }
    }
    return wasAnyCandyMoved;
  }

  /**
   * check if a cell is solid / can't be fallen through
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  _isCellSolid(cellX, cellY) {
    if (cellY === this._boardData.height || this._boardData.get(cellX, cellY) === EDITOR_SYMBOLS.BLANK) return true;
    return this._board.getCandy(cellX, cellY);
  }

  /**
   * check if space is free and available to move a candy to
   * @param {number} x
   * @param {number} y
   * @returns {boolean}
   */
  _isSpaceFreeAndCanMovedTo(x, y) {
    return this._board.isCellOnBoard(x, y) && !this._isCellSolid(x, y) && !this._board.isMoveBlocked(x, y);
  }

  /**
   * try to pull a candy diagnally to fall
   * @param {number} x
   * @param {number} y
   * @param {number} side should be -1 left or 1 right
   * @param {number} verticalOffset
   * @returns {boolean} success
   */
  _tryToPullCandyFromTopSide(x, y, side, verticalOffset) {
    const board = this._board;
    const candy = board.getCandy(x + side, y + verticalOffset);

    if (!candy) return false;
    if (!board.isMoveable(x + side, y + verticalOffset)) return false;
    if (candy.isFallingBlocked(false)) return false;

    // const canFallThrough = board.walls.canCandyCrossDiagonally([x + side, y + verticalOffset], [x, y]);
    // if (!canFallThrough) return false;

    // ok to pull candy and fall
    candy.fallTo(x, y);
    this._setColumnFall(x + side);
    return true;
  }

  /**
   * destruction method
   */
  destroy() {
    this._board = null;
    this._boardData = null;
    this._refiller = null;
    this._refillData = null;
  }
}
