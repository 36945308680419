import { GiftGraphic, GIFT_COLORS } from "../Windows/GiftUI/GiftGraphic";
G.DailyRewardBoxIcon = function (x, y, iconData) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);

  iconData.forEach(function (obj) {
    const pos = obj.pos || [0, 0];
    const scale = obj.scale || [1, 1];
    const giftType = obj.giftType || 1;
    const ribbonTint = obj.ribbonTint || 'Pink';
    const boxTint = obj.boxTint || 'Pink';
    const angle = obj.angle || 0;
    // const img = G.makeImage(pos[0], pos[1], sprite, 0.5, this);
    const img = new GiftGraphic(pos[0], pos[1], { 
      giftType,
      ribbonTint: GIFT_COLORS[ribbonTint],
      boxTint: GIFT_COLORS[boxTint],
      highlight: true,
      useFullGift: obj.useFullGift || false,
    }, this);
    img.angle = angle;

    const scaleMultiplier = obj.useFullGift ? 0.36 : 0.26;
    // doing this because these assets are bigger than the previous ones
    img.scale.setTo(scale[0] * scaleMultiplier, scale[1] * scaleMultiplier);
  }, this);
};

G.DailyRewardBoxIcon.prototype = Object.create(Phaser.Group.prototype);
