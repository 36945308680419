import ShareMsgBase from './A_G.ShareMsgBase';
import EventPostcardAssetManager from '../Windows/eventPostcard/EventPostcardAssetManager';
import EventPostcard_Util from '../Windows/eventPostcard/EventPostcard_Util';

// Unused right now - Nov 16, 2020
class MsgEventPostcard extends ShareMsgBase {
  /**
   * The message that is sent when you send through the postcard event
   * @param {number} index
   * @param {string} onAllImagesReadyCallback
   */
  constructor(index, onAllImagesReadyCallback = null) {
    super(5, onAllImagesReadyCallback);

    // Container
    const container = new Phaser.Group(game, this);

    // Background
    const bg = this._addExtImage(0, 0, EventPostcardAssetManager.getPostcardBackgroundString(), 0, this._idealDimensions.width, this._idealDimensions.height);
    container.addChild(bg);

    // Postcard
    const postcardBodyDimensions = {
      width: 370,
      height: 295,
    };
    const postcardGroup = new Phaser.Group(game, container);
    const postcardEnvelope = new PhaserNineSlice.NineSlice(0, 25, 'eventPostcard', 'eventPostcard_letter', postcardBodyDimensions.width, postcardBodyDimensions.height + 25, {
      top: 96,
      left: 87,
      right: 90,
      bottom: 98,
    });
    postcardEnvelope.anchor.set(0.5);
    postcardEnvelope.angle = 10;
    const postCardBody = this._addExtImage(0, 0, EventPostcardAssetManager.getPostcardBodyString(), 0.5, postcardBodyDimensions.width, postcardBodyDimensions.height);
    const postcardData = G.OMTsettings.postcardEvent.cardData.postcardData[index];
    const postcardImage = this._addExtImage(0, 0, postcardData.image, 0.5, null, null, (img) => {
      img.scale.set(1);
      const widthScale = (this._idealDimensions.height * 0.9) / img.height;
      img.scale.set(widthScale);
      this._objectLoadComplete();
    });
    postcardGroup.addChild(postcardEnvelope);
    postcardGroup.addChild(postCardBody);
    postcardGroup.addChild(postcardImage);
    postcardGroup.x = this._idealDimensions.width / 2;
    postcardGroup.y = (this._idealDimensions.height / 2) - 25;

    // Player avatar
    const profileContainer = EventPostcard_Util.drawAvatarWithFrame(OMT.envData.settings.user.avatar, 90);
    profileContainer.x = this._idealDimensions.width / 5;
    profileContainer.y = this._idealDimensions.height - 5 - (profileContainer.height / 2);
    container.addChild(profileContainer);

    // Speech bubble and text
    const speech = new Phaser.Group(game, container);
    const bubble = new PhaserNineSlice.NineSlice(0, 0, 'eventPostcard', 'eventPostcard_MessageBubble', this._idealDimensions.width / 2, 60, {
      top: 15,
      left: 15,
      right: 15,
      bottom: 15,
    });
    bubble.anchor.set(0.5);
    const speechText = new G.Text(0, 0, OMT.language.getText(postcardData.speechText),
      'eventPostcard-speechText', 0.5, (bubble.width * 0.65), bubble.height, true, 'center');
    speech.addChild(bubble);
    speech.addChild(speechText);
    speech.x = profileContainer.x + (profileContainer.width + speech.width) / 2;
    speech.y = profileContainer.y + (profileContainer.height - speech.height) / 2;
    container.addChild(speech);

    this._objectLoadComplete();
  }
}
