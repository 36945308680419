G.DailyMission = function (missionData) {
  this.missionData = missionData;

  this.reward = G.json['configs/miniMissions'].missions[missionData.id].reward;

  this.status = 'active';

  // console.log(`DAILY MIS : ${missionData.listener}`, missionData);
  this._listenerBinding = G.sb(missionData.listener).addPermanent(this._onListener, this);

  this.current = 0;
  this.target = missionData.getTarget();

  this.terms = missionData.terms ? G.Utils.clone(missionData.terms) : false; // array with terms
  this.increaser = missionData.increaser;

  this.onCompleted = new Phaser.Signal();
  this.onProgress = new Phaser.Signal();
  this.onClaimed = new Phaser.Signal();
};


G.DailyMission.prototype.getDescription = function () {
  return OMT.language.getText(this.missionData.description);
};


G.DailyMission.prototype.export = function () {
  const obj = {
    id: this.missionData.id,
    status: this.status,
    current: this.current,
    target: this.target,
  };

  return obj;
};

G.DailyMission.prototype.import = function (obj) {
  this.status = obj.status;
  this.current = obj.current;
  this.target = obj.target;
};

G.DailyMission.prototype.getProgress = function () {
  return Math.min(this.current, this.target) / this.target;
};

G.DailyMission.prototype.getCurrentVal = function () {
  return this.current;
};

G.DailyMission.prototype.getReward = function () {
  return this.reward;
};

G.DailyMission.prototype.getTarget = function () {
  return this.target;
};

G.DailyMission.prototype.getStatus = function () {
  return this.status;
};

G.DailyMission.prototype.getButtonSettings = function () {
  return this.missionData.btnSetting;
};

G.DailyMission.prototype.getLeft = function () {
  return Math.max(0, this.target - this.current);
};

G.DailyMission.prototype.getIcon = function () {
  return this.missionData.icon;
};

G.DailyMission.prototype.getSpecialAction = function () {
  return this.missionData.specialAction;
};

G.DailyMission.prototype.claim = function () {
  if (this.status === 'completed') {
    this.status = 'claimed';
    this.onClaimed.dispatch();
    G.dailyMissionsMgr.saveData();
  }
};

G.DailyMission.prototype.finish = function () {
  if (this.status !== 'active') return;

  this._listenerBinding.detach();
  this.status = 'completed';
  if (this.current !== this.target) {
    this.current = this.target;
    this.onProgress.dispatch(this.current);
  }
  this.onCompleted.dispatch(this.status);

  G.dailyMissionsMgr.saveData();
};

G.DailyMission.prototype._checkTerms = function (args) {
  if (this.terms) {
    for (let i = 0, len = this.terms.length; i < len; i++) {
      const term = this.terms[i];
      if ((Array.isArray(term) && !this._checkArrayTerm(args[i], term)) || !this._checkTerm(args[i], term)) {
        return false;
      }
    }
  }
  return true;
};

G.DailyMission.prototype._checkArrayTerm = function (arg, term) {
  for (let j = 0; j < term.length; j++) {
    if (!this._checkTerm(arg, term[j])) return false;
  }
  return true;
};

G.DailyMission.prototype._checkTerm = function (arg, term) {
  if (term === false) return true;
  if (typeof term === 'string' && term[0] === '!') {
    return arg !== term.slice(1);
  }
  return arg === term;
};

G.DailyMission.prototype._processIncrease = function (args) {
  const initalCount = this.current;
  if (typeof this.increaser === 'number') {
    this.current += this.increaser;
  } else if (Array.isArray(this.increaser)) {
    for (let j = 0, len = this.increaser.length; j < len; j++) {
      if (this.increaser[j]) {
        if (typeof this.increaser[j] === 'number') {
          this.current += this.increaser[j] * 1;
        } else {
          this.current += args[j] * 1;
        }
      }
    }
  }
  this.current = Math.min(this.current, this.target); // enforce max value
  if (this.current !== initalCount) { // only save if a change occurred
    // console.log(`id: ${this.missionData.id} increaseAmount:${initalCount - this.current} target:${this.target}`);

    // we dont want to save candies collected unti the end of a move, so we flag that we need to save later
    if (this.missionData.listener !== 'onCollectableRemove') G.dailyMissionsMgr.saveData();
    else G.dailyMissionsMgr.flagUnsavedMissionProgress();

    this.onProgress.dispatch(this.current);
  }
};

// should only be called outside gameplay
G.DailyMission.prototype.increaseCurrent = function (amount) {
  this.current = Math.min(this.current + amount, this.target);
  this.onProgress.dispatch(this.current);

  if (this.current >= this.target) {
    this.current = this.target;
    this.finish();
  }
  G.dailyMissionsMgr.saveData();
};

G.DailyMission.prototype.destroy = function () {
  this._listenerBinding.detach();
};

G.DailyMission.prototype._onListener = function (...args) {
  if (this.status !== 'active') return;
  if (this._checkTerms(args)) {
    this._processIncrease(args);
    if (this.missionData.afterIncreaseCallback) {
      this.missionData.afterIncreaseCallback.apply(this, args);
    }
  }

  if (this.current >= this.target) {
    this.finish();
  }
};
