/* eslint-disable no-unused-vars */

import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

/**
 * Window for confirming life sent
 */
class Window_LifeSentSuccessfully extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} msg_payload
   */
  constructor(parent, msg_payload) {
    super(parent);
    this._initLayout();
    this._initButtons();
  }

  /**
   * init window layout
   */
  _initLayout() {
    this.addBackground('popup_background_2');

    this.successTxt = new G.Text(0, -272, OMT.language.getText('Success!'), {
      style: 'font-white',
      fontSize: '50px',
    }, 0.5, 550);
    this.add(this.successTxt);

    this.bigHeart = G.makeImage(-30, 25, 'bigheart', 0.5, this);
    this.bigHeart.scale.setTo(0.85);

    this.taskComplete = G.makeImage(66, 73, 'task_complete', 0.5, this);
    this.taskComplete.scale.setTo(3.2);

    this.lifeSentTxt = new G.Text(0, -140, OMT.language.getText('Lives were successfully sent!'), {
      style: 'window-blue-2',
      fontSize: '50px',
    }, 0.5, 470);
    this.add(this.lifeSentTxt);
  }

  /**
   * init window buttons
   */
  _initButtons() {
    const onClick = () => {
      this.closeWindow();
    };
    this.claimBtn = new OMT_UI_SquareButton(0, 245, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Great!'),
        textStyle: {
          style: 'font-white',
          fontSize: 45,
        },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
        pulse: 1.1,
      },
    });

    this.registerButtons(this.claimBtn);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.lifeSentSuccessfully = Window_LifeSentSuccessfully;
