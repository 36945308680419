import OMT_VILLAINS from '../../../OMT_UI/OMT_Villains';

const USERS_COMPLETED_RANGE = { min: 3, max: 10 }; // random range used for % of users completing level

let _instance = null; // singleton instance

/**
 * class for managing / checking bragging rights for specific levels
 */
export class BragRightsManager {
  /**
   * return singleton instance
   * @returns {BragRightsManager}
   */
  static getInstance() {
    if (!_instance) _instance = new BragRightsManager();
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    //
  }

  /**
   * get brag level data by level #
   * @param {number} levelNum
   * @returns {Object}
   */
  getBragLevelData(levelNum) {
    if (levelNum === -1) return null;
    const levelData = G.Helpers.levelDataMgr.getLevelByIndex(levelNum - 1);
    // fake value for user completed %. uses the level id as a seed.
    const randomDataGenerator = new Phaser.RandomDataGenerator([levelData.id]);
    const randomPercentage = Math.round(randomDataGenerator.realInRange(USERS_COMPLETED_RANGE.min, USERS_COMPLETED_RANGE.max) * 10) / 10;
    return {
      level: levelNum,
      percentage: randomPercentage,
    };
  }

  /**
   * check if a specific level is a brag level
   * @param {number} levelNum
   * @returns {boolean}
   */
  isBragLevel(levelNum) {
    if (!OMT.feature.isBrag2Active()) return false;
    if (levelNum === -1) return false;
    const levelData = G.Helpers.levelDataMgr.getLevelByIndex(levelNum - 1);
    if (levelData == null) return false;
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty(levelData);
    return isNotNormalLevel;
  }
}
