import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';

const width = 640;
const height = 1242;

const convertToPercentageX = (x) => (x / width) * 100;
const convertToPercentageY = (y) => (y / height) * 100;

const yOffset = 52;

export default [
  {
    texture: OMT_VILLAINS.getPrefixedName('villain_1_1'),
    landscape: {
      baseWidth: width * 1.08,
      baseHeight: height,
    },
    transforms: [
      // Initial transform
      {
        x: convertToPercentageX(-70),
        y: convertToPercentageY(114 + yOffset),
        callback: (object) => {
          object.scale.setTo(0.45);
          object.scale.x *= -1;
        },
      },
      {
        x: convertToPercentageX(90),
        y: convertToPercentageY(186 + yOffset),
        duration: 170,
        callback: (object) => {
          object.changeTexture(OMT_VILLAINS.getPrefixedName('villain_1_2'));
          G.sfx.whoosh.play();
        },
      },
      {
        wait: 160,
        callback: (object) => {
          object.changeTexture(OMT_VILLAINS.getPrefixedName('villain_1_1'));
        },
      },
      {
        wait: 1270,
        callback: () => {
          G.sfx.xylophone_positive_12.play();
        },
      },
      {
        y: convertToPercentageY(21 + yOffset),
        duration: 100,
        callback: (object, config) => {
          const { parent } = config;
          parent.addChildAt(object, 0);
        },
      },
      {
        y: convertToPercentageY(95 + yOffset),
        duration: 60,
      },
      {
        y: convertToPercentageY(145 + yOffset),
        duration: 170,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('hard_corruption'),
    destroyOnComplete: true,
    landscape: {
      scaleCoeff: {
        scaleX: 1.2,
        scaleY: 1.1,
      },
    },
    transforms: [
      // Initial transform
      {
        x: convertToPercentageX(320),
        y: convertToPercentageY(0),
        alpha: 0,
        scaleX: 1,
        scaleY: 0.06,
      },
      {
        wait: 335,
      },
      {
        y: convertToPercentageY(75),
        alpha: 1,
        scaleY: 0.18,
        duration: 120,
      },
      {
        y: convertToPercentageY(564),
        scaleY: 1,
        duration: 435,
        callback: () => {
          G.sb('corruptWindow').dispatch();
        },
      },
      {
        y: convertToPercentageY(824),
        scaleY: 0.78,
        duration: 200,
      },
      {
        y: convertToPercentageY(1215),
        alpha: 0,
        scaleY: 0.45,
        duration: 300,
      },
    ],
  },
];
