import OMT_UI_DrawUtil from '../../../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../../OMT_UI/OMT_UI_SquareButton';
import LabelTextT from '../../../G.LabelTextT';
import GingyCharacterTutorial, { CHARACTER_KEYS } from '../../../GingyTutorial/G.GingyCharacterTutorial';
import { EventLevelProgression } from '../../tokenEvent/EventLevelProgression';
import EventPostcard from '../EventPostcard';
import EventPostcard_Util from '../EventPostcard_Util';

export const POSTCARD_STATE = {
  LOCKED: 0,
  UNLOCKED: 1,
  ENDCARD: 2,
};

/**
 * The individual post cards that are shown in window_eventPostcard.
 * Its totally not like a friendship chest card.
 * @author Sandra Koo
 */
export default class UnlockablePostcard extends EventPostcard {
  /**
   * Dessssssstroy!
   */
  destroy() {
    super.destroy();
  }

  /**
   * Returns bounds of the card instead of anything else
   * @returns {Phaser.Rectangle}
   */
  getBounds() {
    return this._card.getBounds();
  }

  _getLetterBackground(cardDimensions) {
    const img = new PhaserNineSlice.NineSlice(0, 0, 'eventPostcard', 'eventPostcard_letter', cardDimensions.width, cardDimensions.height + 25, {
      top: 43,
      left: 44,
      right: 41,
      bottom: 43,
    });
    img.angle = 5;
    return img;
  }

  _drawBorder(cardDimensions) {
    const border = new PhaserNineSlice.NineSlice(0, 0, 'eventPostcard', 'eventPostcard_bg', cardDimensions.width, cardDimensions.height, {
      top: 22,
      left: 22,
      right: 22,
      bottom: 22,
    });
    border.anchor.set(0.5);
    this._subFill.addChild(border);
    return border;
  }

  /**
   * Fills the card with the given data. Much simpler than friendship chest card
   * A resize function is attached at the end
   * @param {Object} cardData
   * @param {POSTCARD_STATE} cardData.state The state of the card
   * @param {number} cardData.index  The index of the card
   * @param {string} cardData.speechText The string for the speech bubble in the post card avatar
   * @param {string} cardData.cardCaption The caption just above the button
   * @param {Object} cardData.promo Object for promo card
   * @param {string} cardData.promo.caption The caption for the promo card
   * @param {Phaser.DisplayObject} cardData.promo.image The image for the promo card. Supposed to be drawn externally
   * @param {string} avatarImg The address of the user's avatar image
   */
  fillCard(cardData, avatarImg) {
    this._state = cardData.state;
    this.index = cardData.index;

    // Draws card based on state
    switch (this._state) {
      case POSTCARD_STATE.LOCKED:
        this._fillAsLocked(cardData);
        break;
      case POSTCARD_STATE.UNLOCKED:
        this._fillAsCard(cardData, avatarImg);
        break;
      case POSTCARD_STATE.ENDCARD:
        this._fillAsEnd(cardData);
        break;
      default: break;
    }

    this._signalBinding = G.sb('onScreenResize').add(this.onResize, this);
  }

  /**
   * Draws the card with the given data
   * @param {Object} cardData
   * @param {POSTCARD_STATE} cardData.state The state of the card
   * @param {number} cardData.index  The index of the card
   * @param {string} cardData.cardCaption The caption just above the button
   * @param {string} cardData.cardTitle The title of the card
   * @param {string} avatarImg The address of the user's avatar image
   */
  _fillAsCard(cardData, avatarImg) {
    this._border.y = -25;
    this._envelope.y = -25;
    if (G.OMTsettings.postcardEvent.cardData.extraAssets) {
      for (const extraData of G.OMTsettings.postcardEvent.cardData.extraAssets) {
        this._assembleExtraAsset(extraData);
      }
    }

    const postcardData = G.OMTsettings.postcardEvent.cardData.postcardData[this.index];
    // Generate postcard image
    const postcardImage = G.makeExtImage(
      this._border.x + G.OMTsettings.postcardEvent.cardData.imageOffset.x || 0,
      this._border.y + G.OMTsettings.postcardEvent.cardData.imageOffset.y || 0,
      postcardData.image, 'waiting_icon_white', 0.5, this._subFill, null, (image) => {
        image.scale.set(1);
        const widthScale = (this._border.width * 0.9) / image.width;
        image.scale.set(widthScale);
      },
    );
    const widthScale = (this._border.width * 0.9) / postcardImage.width;
    postcardImage.scale.set(widthScale);

    // Draw the avatar
    const profileContainer = EventPostcard_Util.drawAvatarWithFrame(avatarImg, 150);
    this._subFill.addChild(profileContainer);

    // Draw the big button
    const theBigButton = this._makeButton('Send');
    theBigButton.y = this._isLandscape
      ? (game.height / this._gameScale - theBigButton.height) / 2
      : (game.height - theBigButton.height) / 2;
    this._button = theBigButton;

    // With the leftover space, make it for the caption
    const caption = new G.Text(0, 0, OMT.language.getText(cardData.cardCaption), 'eventPostcard-caption', 0.5, game.width * 0.95, game.height * 0.1, true, 'center');
    caption.y = Math.round(theBigButton.y - (theBigButton.height + caption.height) / 2);

    this._subFill.addChild(caption);
    this._subFill.addChild(theBigButton);

    this._subFill.positionSenitive = {
      avatar: profileContainer,
      caption,
      /** This exists, but not right now
      speechBubble: {
        text: bubbleData.text,
        bubble: bubbleData.bubble,
      },
      */
    };
    this.onResize();
  }

  _fillAsLocked() {
    this._border.y = -55;
    this._envelope.y = -55;

    const rewardBg = G.makeImage(0, 0, 'tokenEventRewardBg', 0.5, this._border);
    rewardBg.width = this._border.width * 0.94;
    const rewardImages = OMT_UI_DrawUtil.drawDynamicCustomPrize({
      iconDimension: new Phaser.Rectangle(0, 0, 92, 92),
      prizes: G.OMTsettings.postcardEvent.unlockData.unlockSendGift[this.index],
      width: rewardBg.width * 0.95,
      textStyle: 'font-white',
    });
    rewardImages.y = 65 - rewardImages.height / 2;
    const rewardText = new G.Text(0, 0, OMT.language.getText('Reward:'), {
      style: 'eventPostcard-caption',
      fill: '#B12A42',
    }, 0.5, 150 * rewardBg.scale.x, 100 * rewardBg.scale.y, false, 'center');
    rewardText.y = ((rewardText.height / 2) - rewardBg.height / 2);
    rewardBg.addChild(rewardImages);
    rewardBg.addChild(rewardText);
    rewardBg.y = this._border.y + (this._border.height - (rewardBg.height / 2)) / 2;

    // Generate postcard image
    const postcardImage = G.makeImage(
      this._border.x,
      0,
      G.OMTsettings.postcardEvent.unlockData.lockedImage, 0.5, this._border,
    );
    const widthScale = (this._border.width * 0.9) / postcardImage.width;
    postcardImage.scale.set(widthScale);
    postcardImage.y = (this._border.y - rewardBg.y) + (this._border.height - rewardBg.height) / 2; // Calculating space
    this._border.addChild(rewardBg); // Keeping this on top

    // Draw the big button
    const theBigButton = this._makeButton('Play');

    theBigButton.y = this._isLandscape
      ? (game.height / this._gameScale - theBigButton.height) / 2
      : (game.height - theBigButton.height) / 2;
    this._button = theBigButton;

    // With the leftover space, make it for the caption
    const caption = new G.Text(0, 0, OMT.language.getText(G.OMTsettings.postcardEvent.unlockData.lockHint).replace('%CURRENCY%', OMT.language.getText('Pumpkins')),
      'eventPostcard-caption', 0.5, game.width * 0.9, game.height * 0.15, true, 'center');
    caption.y = Math.round(theBigButton.y - 5 - (theBigButton.height + caption.height) / 2);

    // Progress
    this._progressBar = new EventLevelProgression(0, 0);
    this._progressBar.y = caption.y - (caption.height + this._progressBar.height) / 2;
    this._subFill.addChild(this._progressBar);

    this._subFill.addChild(caption);
    this._subFill.addChild(theBigButton);

    this._subFill.positionSenitive = {
      caption,
    };
    this.onResize();
  }

  _fillAsEnd() {
    this._border.y = -55;
    this._envelope.y = -55;

    const bigWords = new G.Text(0, 0, OMT.language.getText('You are amazing!'), {
      style: 'eventPostcard-caption',
      fontSize: '64px',
      fill: G.OMTsettings.postcardEvent.cardData.endCardTextColor,
    }, 0.5, this._border.width * 0.8, 150, true, 'center');

    const tokenText = new LabelTextT(
      `${OMT.language.toLocaleNumber(G.saveState.tokenEventManager.totalTokensCollected)}@${G.OMTsettings.tokenEvent.boardTokenAsset}@`,
      0, 0,
      { style: 'eventPostcard-caption', fontSize: '72px', fill: G.OMTsettings.postcardEvent.cardData.endCardTextColor },
      0.5, this._border.width * 0.8, 5,
    );

    const smallWords = new G.Text(0, 0, OMT.language.getText('Collected'), {
      style: 'eventPostcard-caption',
      fontSize: '48px',
      fill: G.OMTsettings.postcardEvent.cardData.endCardTextColor,
    }, 0.5, this._border.width * 0.8, 150, true, 'center');

    const padding = 5;
    const totalHeight = bigWords.height + tokenText.height + smallWords.height + (padding * 2);
    const wordsContainer = new Phaser.Group(game, this._border);
    bigWords.y = -(totalHeight / 2) + bigWords.height / 4;
    tokenText.y = bigWords.y + padding + (bigWords.height + tokenText.height) / 2;
    smallWords.y = tokenText.y + padding + (tokenText.height + smallWords.height) / 2;
    wordsContainer.addChild(bigWords);
    wordsContainer.addChild(tokenText);
    wordsContainer.addChild(smallWords);
    wordsContainer.y = this._border.y + (this._border.height - wordsContainer.height) / 2;

    // Draw the big button
    const theBigButton = this._makeButton('Play');

    theBigButton.y = this._isLandscape
      ? (game.height / this._gameScale - theBigButton.height) / 2
      : (game.height - theBigButton.height) / 2;
    this._button = theBigButton;
    this._button = theBigButton;

    // With the leftover space, make it for the caption
    const caption = new G.Text(0, 0, OMT.language.getText('You collected all the postcards. Keep collecting to beat the community'),
      'eventPostcard-caption', 0.5, game.width * 0.9, game.height * 0.15, true, 'center');
    caption.y = Math.round(theBigButton.y - 5 - (theBigButton.height + caption.height) / 2);

    this._subFill.addChild(theBigButton);
    this._subFill.addChild(caption);
    this.onResize();
  }

  /**
   *
   * @param {{asset:string, anchor:string, offset:{x:number, y:number}}} data
   */
  _assembleExtraAsset(data) {
    const anchorSpots = data.anchor.toLowerCase().split('_');
    const img = G.makeImage(0, 0, data.asset, 0.5, this._border);
    if (anchorSpots.indexOf('bottom') > -1) {
      img.y = data.offset.y + (this._border.height - img.height) / 2;
    } else if (anchorSpots.indexOf('top') > -1) {
      img.y = data.offset.y + (img.height - this._border.height) / 2;
    }
    if (anchorSpots.indexOf('left') > -1) {
      img.x = data.offset.x + (img.width - this._border.width) / 2;
    } else if (anchorSpots.indexOf('right') > -1) {
      img.x = data.offset.x + (this._border.width - img.width) / 2;
    }
  }

  /**
   * Repositions everything when screen resizes
   */
  onResize() {
    if (!this.fillContainer.visible) { return; }
    if (this._state !== POSTCARD_STATE.UNLOCKED) { return; }

    this._subFill.positionSenitive.avatar.x = Math.max(-this._border.width / 2, 25 + (this._subFill.positionSenitive.avatar.width - game.width) / 2);
    this._subFill.positionSenitive.avatar.y = this._border.y + (this._border.height * (0.99 / 2));

    if (this._subFill.positionSenitive.speechBubble) {
      this._subFill.positionSenitive.speechBubble.bubble.destroy();
      this._subFill.positionSenitive.speechBubble.text.destroy();
    }
    const speechSlice = G.OMTsettings.postcardEvent.cardData.speechBubble.slice;
    const leftoverSpeechSpace = Math.min(this._border.width * 1.2, game.width - (this._subFill.positionSenitive.avatar.x + (game.width / 2)) - speechSlice.left);
    const bubbleData = EventPostcard_Util.makeSpeechBubble(this.index, this._subFill.positionSenitive.avatar, leftoverSpeechSpace);
    this._subFill.addChild(bubbleData.bubble);
    this._subFill.positionSenitive.speechBubble = {
      text: bubbleData.text,
      bubble: bubbleData.bubble,
    };

    G.Text.setMaxWidth(this._subFill.positionSenitive.caption, game.width * 0.95);
    this._subFill.positionSenitive.caption.setText(this._subFill.positionSenitive.caption.text);
  }

  /**
   * Function to create the button.
   * @param {string} text
   * @returns {OMT_UI_SquareButton}
   */
  _makeButton(text) {
    const theBigButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 200,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText(text),
        textStyle: { style: 'font-white', fontSize: 48 },
      },
      options: {
        clickFunction: {
          onClick: this.onButtonClick.bind(this),
          disableAfterClick: this._state === POSTCARD_STATE.CLAIM, // Claim cards are disabled after click
        },
      },
    });
    return theBigButton;
  }

  /**
   * Signal is dispatched when the button is clicked
   */
  onButtonClick() {
    this.signals.onClick.dispatch(this);
  }

  /**
   * Animates the sequence for applying gifts. Only a temporary thing.
   * @param {Array<{prize:string, amount:number}>} prizes
   * @param {Function} applyGifts
   */
  animateThanksSequence(prizes, applyGifts) {
    return new Promise((resolve) => {
      const textCaption = this._subFill.positionSenitive.caption;
      const button = this._button;

      const thanksCaption = new G.Text(textCaption.x, textCaption.y, OMT.language.getText('Your reward:'), 'eventPostcard-caption', 0.5, game.width * 0.9, 150, true, 'center');
      const prizeContainer = OMT_UI_DrawUtil.drawDynamicCustomPrize({
        iconDimension: new Phaser.Rectangle(0, 0, 92, 92),
        prizes: G.OMTsettings.postcardEvent.unlockData.unlockSendGift[this.index],
        width: game.width * 0.9,
        textStyle: 'font-white',
      });
      prizeContainer.y = Math.round(thanksCaption.y + (thanksCaption.height + prizeContainer.height) / 2);
      thanksCaption.alpha = 0;
      prizeContainer.alpha = 0;
      this._subFill.addChild(thanksCaption);
      this._subFill.addChild(prizeContainer);

      G.sfx.xylophone_positive6.play();
      game.add.tween(textCaption).to({ alpha: 0 }, 400, Phaser.Easing.Sinusoidal.InOut, true);
      const tw1 = game.add.tween(button).to({ alpha: 0 }, 400, Phaser.Easing.Sinusoidal.InOut, true);
      tw1.onComplete.addOnce(() => {
        game.add.tween(thanksCaption).to({ alpha: 1 }, 400, Phaser.Easing.Sinusoidal.InOut, true);
        const tw2 = game.add.tween(prizeContainer).to({ alpha: 1 }, 400, Phaser.Easing.Sinusoidal.InOut, true);
        tw2.onComplete.addOnce(() => {
          game.add.tween(prizeContainer).to({ alpha: 1 }, 500, Phaser.Easing.Linear.None, true)
            .onComplete.addOnce(() => { applyGifts(prizes); });
          game.add.tween(thanksCaption).to({ alpha: 0 }, 400, Phaser.Easing.Sinusoidal.InOut, true, 3000);
          const tw3 = game.add.tween(prizeContainer).to({ alpha: 0 }, 400, Phaser.Easing.Sinusoidal.InOut, true, 3000);
          tw3.onComplete.addOnce(() => {
            this._subFill.removeChild(thanksCaption);
            this._subFill.removeChild(prizeContainer);
            thanksCaption.destroy();
            prizeContainer.destroy();
            game.add.tween(textCaption).to({ alpha: 1 }, 400, Phaser.Easing.Sinusoidal.InOut, true);
            const tw4 = game.add.tween(button).to({ alpha: 1 }, 400, Phaser.Easing.Sinusoidal.InOut, true);
            tw4.onComplete.addOnce(() => {
              textCaption.alpha = 1;
              button.alpha = 1;
              resolve();
            });
          });
        });
      });
    });
  }

  /**
   * Animates the sequence for showing tutorial Gingy.
   */
  animateGingySequence() {
    const screenHeight = this._isLandscape ? game.height / this._gameScale : game.height;

    return new Promise((resolve) => {
      const textCaption = this._subFill.positionSenitive.caption;
      const button = this._button;

      const gingyContainer = new Phaser.Group(game, null);
      const gingy = new GingyCharacterTutorial(0, 0, CHARACTER_KEYS.gingy);
      gingyContainer.addChild(gingy);
      gingy.lookAt({ x: 0, y: -1 });
      const speechBubble = G.makeImage(gingy.x + gingy.width / 2, 0, 'tokenEvent_tutorial_bubble', [0, 0.5], gingyContainer);
      const text = new G.Text(20 + speechBubble.width / 2, 0,
        OMT.language.getText('Unlock exclusive postcards by collecting %CURRENCY% in levels!').replace('%CURRENCY%', OMT.language.getText('Pumpkins')),
        'eventPostcard-tutorialText', 0.5, speechBubble.width * 0.9, speechBubble.height * 0.9, true, 'center');
      speechBubble.addChild(text);
      gingyContainer.y = gingyContainer.height + (screenHeight) / 2;
      gingyContainer.x = -game.width / 3;
      speechBubble.y = -20 - speechBubble.height / 2;
      speechBubble.scale.set(0);
      this._subFill.addChild(gingyContainer);

      const cancelAnim = this.cancelAnim = () => { // Cancel anim is also called externally when the card is no longer visible
        this.cancelAnim = null;
        if (gingyContainer) {
          this._subFill.removeChild(gingyContainer);
          gingyContainer.destroy();
        }
        textCaption.alpha = 1;
        button.alpha = 1;
        resolve();
      };

      game.add.tween(textCaption).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true); // Fade out
      const tw1 = game.add.tween(button).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true);
      tw1.onComplete.addOnce(() => {
        if (this.cancelAnim) {
          const tw2 = game.add.tween(gingyContainer).to({ y: 40 + screenHeight / 2 }, 750, Phaser.Easing.Elastic.Out, true); // Gingy pops in
          tw2.onComplete.addOnce(() => {
            if (this.cancelAnim) {
              gingy.talk();
              const tw3 = game.add.tween(speechBubble.scale).to({ x: 1, y: 1 }, 500, Phaser.Easing.Elastic.Out, true); // Speech in
              tw3.onComplete.addOnce(() => {
                if (this.cancelAnim) {
                  const tw4 = game.add.tween(speechBubble.scale).to({ x: 0, y: 0 }, 500, Phaser.Easing.Elastic.In, true, 5000); // Speech away
                  tw4.onStart.addOnce(() => {
                    gingy.idle(); // Gingy shuts his mouth!
                    gingy.lookAt({ x: 0, y: 0 });
                  });
                  tw4.onComplete.addOnce(() => {
                    if (this.cancelAnim) { // Gingy out
                      const tw5 = game.add.tween(gingyContainer).to({ y: (screenHeight + gingyContainer.height) / 2 }, 750, Phaser.Easing.Elastic.In, true);
                      tw5.onComplete.addOnce(() => {
                        if (this.cancelAnim) {
                          this._subFill.removeChild(gingyContainer); // Clean up
                          gingyContainer.destroy();
                          this.cancelAnim = null;
                          game.add.tween(textCaption).to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.InOut, true); // Regular stuff back in
                          const tw6 = game.add.tween(button).to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.InOut, true);
                          tw6.onComplete.addOnce(() => {
                            textCaption.alpha = 1; // Just in case
                            button.alpha = 1;
                            resolve();
                          });
                        } else { cancelAnim(); }
                      });
                    } else { cancelAnim(); }
                  });
                } else { cancelAnim(); }
              });
            } else { cancelAnim(); }
          });
        } else { cancelAnim(); }
      });
    });
  }
}
