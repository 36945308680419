if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};
G.Animations.Generic = G.Animations.Generic || {};

G.Animations.Generic.getSquishSquashAnimation = function getSquishSquashAnimation(optScale) {

  var scale = G.Utils.defined(optScale, 1);
  var scaleMin = scale * 0.95;
  var scaleMax = scale * 1.05;
  var period = 1600;
  var easing = Phaser.Easing.Sinusoidal.InOut;

  return {
    animate: animate,
  };

  function animate(game, target) {
    target.scale.setTo(scaleMax, scaleMin);
    var tween = game.add.tween(target.scale).to(
      {
        x: scaleMin,
        y: scaleMax
      },
      0.5 * period,
      easing,
      true, 0, -1, true
    );
    // Randomize starting point
    tween.updateTweenData('dt', period * Math.random(), -1);
    return tween;
  }
};
