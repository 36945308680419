/* eslint-disable operator-linebreak */
/* eslint-disable func-names */

const { default: ComponentGameHooks } = require('../../ComponentGameHooks');

if (typeof G === 'undefined') G = {};

G.ButtonLegacy = function (x, y, sprite, callback, context) {
  Phaser.Button.call(this, game, Math.floor(x), Math.floor(y), null);

  this.state = game.state.getCurrentState();

  G.changeTexture(this, sprite);
  this.anchor.setTo(0.5);

  this.sfx = 'pop';

  this.active = true;

  this.onClick = new Phaser.Signal();
  if (callback) {
    this.onClick.add(callback, context || this);
  }

  this._addClickListener();

  this.terms = [];

  this.IMMEDIATE = false;

  this.scaleOnClick = true;

  this.refractorPeriod = 200;
  this.scaleChange = 0.1;

  this.pulsing = false;
};

G.ButtonLegacy.prototype = Object.create(Phaser.Button.prototype);
G.ButtonLegacy.constructor = G.ButtonLegacy;

G.ButtonLegacy.prototype._addClickListener = function _addClickListener() {
  const clickHandler = G.Input.createCustomInputClickHandler();
  clickHandler.clickSignal.add(this.click, this);

  G.Input.initializeCustomInput(this);
  this.customInput.addHandler(clickHandler);
};

G.ButtonLegacy.prototype.pulse = function (maxScale) {
  this.pulsing = true;
  this.pulsingTween = game.add
    .tween(this.scale)
    .to(
      { x: maxScale || 1.1, y: maxScale || 1.1 },
      500,
      Phaser.Easing.Sinusoidal.InOut,
      true,
      0,
      -1,
      true,
    );
};

G.ButtonLegacy.prototype.stopPulse = function (maxScale) {
  if (this.pulsingTween) this.pulsingTween.stop();
  this.scale.setTo(maxScale || 1);
  this.pulsing = false;
};

G.ButtonLegacy.prototype.click = function () {
  if (!this.active) return;

  if (!this._checkTerms()) return;

  this.active = false;
  this.onClick.dispatch();

  if (this.sfx) ComponentGameHooks.playSoundById(this.sfx);

  const orgScaleX = this.scale.x;
  const orgScaleY = this.scale.y;

  if (this.IMMEDIATE) {
    this.active = true;
  } else if (this.pulsing || !this.scaleOnClick) {
    game.time.events.add(
      this.refractorPeriod,
      function () {
        this.active = true;
      },
      this,
    );
  } else {
    game.add
      .tween(this.scale)
      .to(
        {
          x: orgScaleX + this.scaleChange,
          y: orgScaleY + this.scaleChange,
        },
        this.refractorPeriod,
        Phaser.Easing.Quadratic.Out,
        true,
        0,
        0,
        true,
      )
      .onComplete.add(function () {
        this.active = true;
      }, this);
  }
};

G.ButtonLegacy.prototype._checkTerms = function () {
  for (let i = 0; i < this.terms.length; i++) {
    if (!this.terms[i][0].call(this.terms[i][1])) {
      return false;
    }
  }

  return true;
};

G.ButtonLegacy.prototype.addTerm = function (callback, context) {
  this.terms.push([callback, context]);
};

G.ButtonLegacy.prototype.addImageLabel = function (image) {
  this.label = game.make.image(0, 0, 'ssheet', image);
  this.label.anchor.setTo(0.5);
  this.addChild(this.label);
};

G.ButtonLegacy.prototype.addTextLabel = function (
  font,
  text,
  size,
  x,
  y,
  maxWidth,
) {
  const multi = 1;

  x = typeof x === 'undefined' ? -2 : x;
  y = typeof y === 'undefined' ? -2 : y;
  maxWidth =
    typeof maxWidth === 'undefined' ? this.width * multi * 0.7 : maxWidth;

  this.label = new G.Text(
    x,
    y,
    text,
    {
      style: font,
      fontSize: size || Math.floor(this.height * multi * 0.7),
    },
    0.5,
    maxWidth,
  );
  this.label.hitArea = new Phaser.Rectangle(0, 0, 0, 0);
  this.addChild(this.label);
};

G.ButtonLegacy.prototype.addTextLabelMultiline = function (font, text) {
  this.label = new G.Text(
    0,
    0,
    text,
    {
      style: font,
      fontSize: Math.floor(this.height * 0.5),
    },
    0.5,
    this.width * 0.7,
    this.height * 0.7,
    true,
    'center',
  );
  this.addChild(this.label);
};

G.ButtonLegacy.prototype.stopTweens = function () {
  G.stopTweens(this);
};

G.ButtonLegacy.prototype.changeTexture = function (image) {
  G.changeTexture(this, image);
};

G.ButtonLegacy.prototype.add = function (obj) {
  return this.addChild(obj);
};

G.ButtonLegacy.prototype.updateChildren = function () {
  for (let i = this.children.length - 1; i >= 0; i--) {
    this.children[i].update();
  }
};
