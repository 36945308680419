import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import OMT_UI_DrawUtil from '../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import { OMT_AssetLoader } from '../../Services/OMT/OMT_AssetLoader';
import { INTERSTITIAL_RULES } from '../../Services/OMT/ads/OMT_InterstitialAdRules';

/**
 * Mailbox window container that pops up when you click a mailbox on the saga map
 */
class Window_Mailbox extends Window {
  /**
   * constructor
   * @param {Object} parent
   */
  constructor(parent, updateMapFunc) {
    super(parent);
    this.y = 50;
    this._state = game.state.getCurrentState();
    this._updateMapFunc = updateMapFunc;

    this._init();
  }

  /**
   * Init the mailbox window, but await the assets
   */
  async _init() {
    const assetLoader = OMT_AssetLoader.getInstance();
    await assetLoader.waitOnSecondaryImages(['mailbox'], 9000000);
    this._prizeList = G.saveState.mailboxManager.getNextReward();
    this._messageData = G.saveState.mailboxManager.getRandomMail();

    this._initGlow();
    this._initButtons();
    this._initPostcard();
    this._initEnvelope();
    this._initText();
    this._initGift();

    this._initAnimations();
    this._initGfxStates();

    // Increment number of times mailbox has been opened
    if (this._updateMapFunc) {
      this._updateMapFunc(); // Updates the map
    }
    G.saveState.mailboxManager.incrementSeenMail();
    // if ( DDNA.tracking.enabled) {
    //   DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('mailboxClickedCount', G.saveState.mailboxManager.opened);
    // }
  }

  /**
   * Creates large godray effect graphic
   */
  _initGlow() {
    this._glow = G.makeImage(0, 0, 'shine_godrays', 0.5, this);
    this._glow.scale = { x: 1.5, y: 1.5 };
    game.add.tween(this._glow).to({ angle: 360 }, 60000, Phaser.Easing.Linear.None, true, 0, -1, false);
  }

  /**
   * Creates gift graphics
   */
  _initGift() {
    this._giftGroup = new Phaser.Group(game);
    this._giftGroup.scale = { x: 1, y: 1 };
    this._giftGroup.y = 360;
    this.add(this._giftGroup);

    this._giftGlow = G.makeImage(0, 0, 'popup_lighht', 0.5, this._giftGroup);
    this._giftGlow.scale = { x: 1.25, y: 1.25 };
    this._giftGlow.alpha = 0.25;
    game.add.tween(this._giftGlow).to({ angle: 360 }, 60000, Phaser.Easing.Linear.None, true, 0, -1, false);

    this._giftStep = 0;
    this._giftBoxGroup = new Phaser.Group(game);
    this._giftGroup.add(this._giftBoxGroup);
    this._giftShake = game.add.tween(this._giftBoxGroup).to({ angle: [0, -3, 3, 0] }, 1000, Phaser.Easing.Sinusoidal.In, true, 0, 0, true);
    this._giftPause = game.add.tween(this._giftBoxGroup).to({ angle: 0 }, 500, Phaser.Easing.Sinusoidal.In);
    this._giftShake.chain(this._giftPause);
    this._giftPause.chain(this._giftShake);

    this._giftOpen = G.makeImage(0, 0, 'gift_bottom', 0.5, this._giftBoxGroup);
    this._giftClosed = G.makeImage(0, 0, 'gift_full', 0.5, this._giftBoxGroup);
    this._giftClosed.inputEnabled = true;
    this._giftClosed.input.useHandCursor = true;
    this._giftClosed.events.onInputDown.add(() => {
      this._onGiftClick();
    }, this);

    // Convert prize list to format drawDynamicPrizes understands
    const convertedPrizes = [];

    // eslint-disable-next-line guard-for-in
    for (const prize in this._prizeList) {
      if (this._prizeList[prize] > 0) {
        convertedPrizes.push({
          prize,
          amount: this._prizeList[prize],
        });
      }
    }

    this._giftPrizes = OMT_UI_DrawUtil.drawDynamicPrizes({
      iconDimension: {
        x: 0,
        y: 0,
        width: 75,
        height: 75,
      },
      width: 400,
      prizes: convertedPrizes,
      textStyle: {
        style: 'font-white',
        fontSize: '48px',
      },
    });
    this._giftBoxGroup.add(this._giftPrizes);
  }

  /**
   * Creates buttons and other clickables
   */
  _initButtons() {
    this._shareButton = new OMT_UI_SquareButton(0, 330, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Share'),
        textStyle: 'mailbox-shareButton',
      },
      options: {
        clickFunction: {
          onClick: () => {
            const localLines = this._messageData.lines.map((line) => OMT.language.getText(line));

            OMT.social.shareMailboxMsg(
              () => this._startAnimSequence(this._continueAnims),
              this._messageData.photo,
              { msg: localLines.join('-') },
              this._messageData.category,
              this._messageData.avatar,
              { mailBoxCatId: this._messageData.category },
            );
          },
          disableAfterClick: false,
          scaleOnClick: true,
        },
      },
    });
    this.add(this._shareButton);

    this._continueButton = new G.Text(0, 435, OMT.language.getText('Not Now'), {
      style: 'font-white',
      fontSize: '48px',
    }, 0.5);
    this.add(this._continueButton);
    this._continueButton.inputEnabled = true;
    this._continueButton.input.useHandCursor = true;
    this._continueButton.events.onInputDown.add(() => {
      G.sfx.pop.play();
      this._startAnimSequence(this._continueAnims);
    }, this);

    this._claimButton = new OMT_UI_SquareButton(0, 330, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Claim'),
        textStyle: 'mailbox-shareButton',
      },
      options: {
        clickFunction: {
          onClick: () => {
            this._claimSuccess(this._messageData, this._claimGiftAnims);
          },
          disableAfterClick: true,
          scaleOnClick: true,
        },
      },
    });
    this.add(this._claimButton);

    this._coolButton = new OMT_UI_SquareButton(0, 330, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Cool'),
        textStyle: 'mailbox-shareButton',
      },
      options: {
        clickFunction: {
          onClick: () => { this.closeWindow(); },
          disableAfterClick: false,
          scaleOnClick: true,
        },
      },
    });
    this.add(this._coolButton);
  }

  /**
   * Creates text elements
   */
  _initText() {
    const allowedTextWidth = game.width;
    const secondLineOffset = 50;
    const padding = 10;

    this._secondLine = new G.Text(0, -325, OMT.language.getText(this._messageData.lines[1]), {
      style: 'font-white',
      fontSize: '60px',
    }, [0, 0.5], allowedTextWidth - secondLineOffset - padding);
    this._secondLine.x -= this._secondLine.width / 2; // Aligning center
    this.add(this._secondLine);

    const firstLineX = Math.max(padding - (game.width / 2), this._secondLine.x - secondLineOffset);
    this._firstLine = new G.Text(firstLineX, -400, OMT.language.getText(this._messageData.lines[0]), {
      style: 'font-white',
      fontSize: '48px',
    }, [0, 0.5], allowedTextWidth);
    this.add(this._firstLine);

    this._gotMailText = new G.Text(0, -337.5, OMT.language.getText('You got mail!'), {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, allowedTextWidth, null, true, 'center');
    this.add(this._gotMailText);

    this._awesomeText = new G.Text(0, -375, OMT.language.getText('Awesome!'), {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, allowedTextWidth, 900, true, 'center');
    this.add(this._awesomeText);

    this._myGiftText = new G.Text(0, -300, OMT.language.getText('Here\'s my gift'), {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, allowedTextWidth, 900, true, 'center');
    this.add(this._myGiftText);

    this._funText = new G.Text(0, -337.5, OMT.language.getText('Let\'s get back to the fun!'), {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, allowedTextWidth, 900, true, 'center');
    this.add(this._funText);
  }

  /**
   * Creates envelope elements
   */
  _initEnvelope() {
    this._envelopeGroup = new Phaser.Group(game);
    this._envelopeGroup.angle = 0;
    this._envelopeGroup.alpha = 1;
    this._envelopeShake = game.add.tween(this._envelopeGroup).to({ angle: [0, -3, 3, 0] }, 1000, Phaser.Easing.Sinusoidal.In, true, 0, 0, true);
    this._envelopePause = game.add.tween(this._envelopeGroup).to({ angle: 0 }, 500, Phaser.Easing.Sinusoidal.In);
    this._envelopeShake.chain(this._envelopePause);
    this._envelopePause.chain(this._envelopeShake);

    this.add(this._envelopeGroup);

    // Cook is -2, -233
    this._envelopeFlap = G.makeImage(0, 0, 'envelope_flap', [0.5, 1], this._envelopeGroup);
    this._envelopeFlap.scale.y = 0;
    this._envelopeFlap.angle = 0.25;

    this._envelopeBody = G.makeImage(0, 0, 'envelope_body', 0.5, this._envelopeGroup);
    this._envelopeFlap.y = -this._envelopeBody.height / 2.05;
    this._envelopeBody.inputEnabled = true;
    this._envelopeBody.input.useHandCursor = true;
    this._envelopeBody.events.onInputDown.add(() => {
      G.sfx.pop.play();
      this._startAnimSequence(this._startAnims);
      this._envelopeBody.inputEnabled = false;
    }, this);

    this._fromText = new G.Text(0, 0, `${OMT.language.getText('From:')} ${OMT.language.getText('Gingy')}`, {
      style: 'font-white',
      fontSize: '64px',
    }, 0.5);

    this._envelopeGroup.add(this._fromText);
  }

  /**
   * Creates postcard elements
   */
  _initPostcard() {
    this._postcardGroup = new Phaser.Group(game);
    this._postcardGroup.angle = 0;
    this.add(this._postcardGroup);

    this._postcard = G.makeImage(0, 0, 'mail_postcard', 0.5, this._postcardGroup);

    this._photoGroup = new Phaser.Group(game);
    this._photoGroup.y = -18;
    this._photoGroup.angle = 4;
    this._postcardGroup.add(this._photoGroup);

    const avatarData = this._messageData.avatar;
    this._avatar = G.makeExtImage(0, 0, OMT.envData.settings.user.avatar, 'avatar_m', 0.5, this._photoGroup, false, (img) => {
      img.x = avatarData.x;
      img.y = avatarData.y;
      img.width = avatarData.width;
      img.height = avatarData.height;
      img.angle = avatarData.angle;
    });

    this._photo = G.makeImage(0, 0, this._messageData.photo, 0.5, this._photoGroup);
  }

  /**
   * Initializes states of graphical elements
   */
  _initGfxStates() {
    this._postcardGroup.visible = false;
    this._photoGroup.visible = false;
    this._firstLine.renderable = false;
    this._secondLine.renderable = false;
    this._shareButton.inputEnabled = false;
    this._shareButton.renderable = false;
    this._continueButton.inputEnabled = false;
    this._continueButton.renderable = false;
    this._claimButton.inputEnabled = false;
    this._claimButton.renderable = false;
    this._awesomeText.renderable = false;
    this._myGiftText.renderable = false;
    this._giftGroup.visible = false;
    this._giftOpen.visible = false;
    this._giftPrizes.visible = false;
    this._coolButton.inputEnabled = false;
    this._coolButton.renderable = false;
    this._funText.renderable = false;
  }

  /**
   * Creates tweens and chains them together to make animation sequences
   */
  _initAnimations() {
    // Plays after you click on the envelope
    this._startAnims = [
      { // Fade You've got mail text
        tween: game.add.tween(this._gotMailText).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In),
        sideEffects: () => {
          this._envelopeShake.stop();
          this._envelopePause.stop();
          this._envelopeGroup.angle = 0;
          this._postcardGroup.visible = true;
        },
      },

      // Open flap
      { tween: game.add.tween(this._envelopeFlap.scale).to({ y: 0.62 }, 250, Phaser.Easing.Sinusoidal.Out) },

      { // Remove postcard from envelope
        tween: game.add.tween(this._postcardGroup).to({ y: -1000 }, 750, Phaser.Easing.Sinusoidal.In),
        sideEffects: () => {
          game.add.tween(this._postcardGroup).to({ alpha: 0 }, 250, Phaser.Easing.Sinusoidal.In, true, 500);
          G.sfx.whoosh.play();
        },
      },

      { // Fade out envelope and glow, prepare graphic state for second phase
        tween: game.add.tween(this._glow).to({ alpha: 0 }, 125, Phaser.Easing.Linear.None),
      },

      {
        tween: game.add.tween(this._envelopeGroup).to({ alpha: 0 }, 125, Phaser.Easing.Linear.None),
        sideEffects: () => {
          this._postcardGroup.angle = -3;
          this._photoGroup.visible = true;
          this._firstLine.renderable = true;
          this._secondLine.renderable = true;
          this._shareButton.renderable = true;
          this._continueButton.renderable = true;
          this._firstLine.alpha = 0;
          this._secondLine.alpha = 0;
          this._shareButton.alpha = 0;
          this._continueButton.alpha = 0;
        },
      },

      { // Fade in first line
        tween: game.add.tween(this._firstLine).to({ alpha: 1, y: -375 }, 500, Phaser.Easing.Sinusoidal.Out, false, 300),
        sideEffects: () => {
          this._envelopeGroup.visible = false;
        },
      },

      { // Fade in second line
        tween: game.add.tween(this._secondLine).to({ alpha: 1, y: -300 }, 500, Phaser.Easing.Sinusoidal.Out, false, 300),
        sideEffects: () => {
          this._glow.y = -1000;
        },
      },

      { // Drop postcard and glow
        tween: game.add.tween(this._postcardGroup).to({ y: 0 }, 1000, Phaser.Easing.Cubic.Out, false, 300),
        sideEffects: () => {
          G.sfx.whoosh.play();
          game.add.tween(this._postcardGroup).to({ alpha: 1 }, 250, Phaser.Easing.Sinusoidal.In, true);
          game.add.tween(this._glow).to({ y: 0 }, 1000, Phaser.Easing.Cubic.Out, true, 300);
          game.add.tween(this._glow).to({ alpha: 1 }, 250, Phaser.Easing.Sinusoidal.In, true);
        },
      },

      { // Fade glow Fade in buttons
        tween: game.add.tween(this._shareButton).to({ alpha: 1 }, 150, Phaser.Easing.Sinusoidal.In, false, 500),
        sideEffects: () => {
          G.sfx.stone_impact_3.play();
          game.add.tween(this._continueButton).to({ alpha: 1 }, 150, Phaser.Easing.Sinusoidal.In, true, 500);
          game.add.tween(this._glow).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.In, true, 500);
          this._shareButton.inputEnabled = true;
          this._continueButton.inputEnabled = true;
        },
      },
    ];

    this._chainTweens(this._startAnims);

    // Plays after clicking "Not now" or after sharing
    this._continueAnims = [
      { // Fade out buttons, first and second line
        tween: game.add.tween(this._firstLine).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.In),
        sideEffects: () => {
          this._shareButton.inputEnabled = false;
          this._continueButton.inputEnabled = false;
          this._awesomeText.renderable = true;
          this._awesomeText.alpha = 0;
          this._myGiftText.renderable = true;
          this._myGiftText.alpha = 0;
          game.add.tween(this._secondLine).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.In, true);
          game.add.tween(this._shareButton).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.In, true);
          game.add.tween(this._continueButton).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.In, true);
        },
      },

      { // Fade in awesome text
        tween: game.add.tween(this._awesomeText).to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.In),
        sideEffects: () => {
          game.add.tween(this._myGiftText).to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.In, true);
          this._giftGroup.visible = true;
          this._giftGroup.alpha = 0;
        },
      },

      { // Fade in gift
        tween: game.add.tween(this._giftGroup).to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.In),
      },
    ];

    this._chainTweens(this._continueAnims);

    // Plays after clicking on gift
    this._showGiftAnims = [
      { // Move gift up, fade out postcard
        tween: game.add.tween(this._giftGroup).to({ y: 0 }, 750, Phaser.Easing.Sinusoidal.Out),
        sideEffects: () => {
          this._giftShake.stop();
          this._giftPause.stop();
          this._giftBoxGroup.angle = 0;
          this._giftOpen.visible = true;
          this._giftPrizes.visible = true;
          this._giftPrizes.alpha = 0;
          this._giftPrizes.scale = { x: 0.8, y: 0.8 };
          game.add.tween(this._giftGroup.scale).to({ x: 1.25, y: 1.25 }, 750, Phaser.Easing.Sinusoidal.Out, true);
          game.add.tween(this._postcardGroup).to({ alpha: 0 }, 250, Phaser.Easing.Linear.None, true);
        },
      },

      { // Shake gift one more time
        tween: game.add.tween(this._giftBoxGroup).to({ angle: -5 }, 75, Phaser.Easing.Sinusoidal.Out),
      },

      {
        tween: game.add.tween(this._giftBoxGroup).to({ angle: 5 }, 150, Phaser.Easing.Sinusoidal.InOut),
      },

      {
        tween: game.add.tween(this._giftBoxGroup).to({ angle: 0 }, 75, Phaser.Easing.Sinusoidal.Out),
      },

      { // open gift
        tween: game.add.tween(this._giftClosed).to({ alpha: 0 }, 250, Phaser.Easing.Linear.None, false, 700),
        sideEffects: () => {
          this._claimButton.renderable = true;
          this._claimButton.alpha = 0;
          game.add.tween(this._giftPrizes).to({ y: -70, alpha: 1 }, 250, Phaser.Easing.Sinusoidal.Out, true, 1000);
          game.add.tween(this._giftPrizes.scale).to({ x: 1, y: 1 }, 250, Phaser.Easing.Sinusoidal.Out, true, 1000);
        },
      },

      { // Show claim button
        tween: game.add.tween(this._claimButton).to({ alpha: 1 }, 250, Phaser.Easing.Linear.None, 1000),
        sideEffects: () => {
          G.sfx.line.play();
          this._claimButton.inputEnabled = true;
        },
      },
    ];

    this._chainTweens(this._showGiftAnims);

    // Plays after clicking on claim button
    this._claimGiftAnims = [
      { // Fade out claim button, awesome text, add coins
        tween: game.add.tween(this._giftGroup).to({ alpha: 0 }, 500, Phaser.Easing.Linear.None),
        sideEffects: () => {
          game.add.tween(this._claimButton).to({ alpha: 0 }, 500, Phaser.Easing.Linear.None, true);
          game.add.tween(this._awesomeText).to({ alpha: 0 }, 500, Phaser.Easing.Linear.None, true);
          game.add.tween(this._myGiftText).to({ alpha: 0 }, 500, Phaser.Easing.Linear.None, true);
          OMT.transactionTracking.logInventoryTransactionBegin();
          const giftStuff = [];
          if (this._prizeList.coin > 0) {
            this._state.uiTargetParticles.createCoinBatch(
              game.world.bounds.x + this._giftGroup.worldPosition.x,
              this._giftGroup.worldPosition.y,
              game.state.getCurrentState().panel.coinsTxt,
              this._prizeList.coin,
              false,
            );
            giftStuff.push(['coin', this._prizeList.coin]);
            OMT.transactionTracking.addInventoryChange('coins', 0, this._prizeList.coin);
          }

          if (this._prizeList.life > 0) {
            G.saveState.addLife(this._prizeList.life, true, false);
            giftStuff.push(['life', this._prizeList.life]);
            OMT.transactionTracking.addInventoryChange('lives', 0, this._prizeList.life);
          }

          // DDNA.transactionHelper.trackRewards(giftStuff, [], { // DDNA tracking
          //   transactionType: 'REWARD',
          //   tActionType: 'MAILBOX',
          //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
          // });
          OMT.transactionTracking.logInventoryTransactionEnd();
          G.saveState.save();

          this._coolButton.renderable = true;
          this._coolButton.alpha = 0;
          this._funText.renderable = true;
          this._funText.alpha = 0;
        },
      },

      { // Fade in cool button, fun text
        tween: game.add.tween(this._postcardGroup).to({ alpha: 1 }, 500, Phaser.Easing.Linear.None, false, 250),
        sideEffects: () => {
          game.add.tween(this._coolButton).to({ alpha: 1 }, 500, Phaser.Easing.Linear.None, true, 250);
          game.add.tween(this._funText).to({ alpha: 1 }, 500, Phaser.Easing.Linear.None, true, 250);

          this._coolButton.inputEnabled = true;
          this._claimButton.renderable = false;
          this._awesomeText.renderable = false;
          this._myGiftText.renderable = false;
        },
      },
    ];

    this._chainTweens(this._claimGiftAnims);
  }

  /**
   * Chain tweens together to create an animation sequence
   * @param {Array} tweenArray
   */
  _chainTweens(tweenArray) {
    for (let i = 0; i < tweenArray.length - 1; i++) {
      tweenArray[i].tween.onComplete.add(() => {
        if (tweenArray[i + 1].sideEffects) {
          tweenArray[i + 1].sideEffects();
        }
        tweenArray[i + 1].tween.start();
      });
    }
  }

  /**
   * Starts an animation sequence (an array of chained tween and their side effects)
   * @param {Array} tweenArray
   */
  _startAnimSequence(tweenArray) {
    if (tweenArray[0].sideEffects) {
      tweenArray[0].sideEffects();
    }
    tweenArray[0].tween.start();
  }

  /**
   * Handle gift click
   */
  _onGiftClick() {
    if (this._giftStep === 0) {
      G.sfx.pop.play();
      this._startAnimSequence(this._showGiftAnims);
      this._giftClosed.inputEnabled = false;
      this._giftStep++;
    }
  }

  _claimSuccess(messageData, anims) {
    this._startAnimSequence(anims);
  }

  /**
   * Check interstitial after leaving
   */
  closeWindow() {
    super.closeWindow(() => {
      this.state.checkForAds(() => {}, INTERSTITIAL_RULES.GIFT_GAIN);
    });
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.mailbox = Window_Mailbox;
