/*
  Utility Class for Array Functions
*/
export default class ArrayUtil {
  // eslint-disable-next-line no-useless-constructor
  constructor() { } // eslint-disable-line no-empty-function

  /**
  * get a random index from an array
  * @param {Array} arr
  * @returns {number}
  */
  static getRandomIndex(arr) {
    return Math.floor(arr.length * Math.random());
  }

  /**
  * get a random element from an array
  * @param {Array} arr
  * @returns {*}
  */
  static getRandomElement(arr) {
    return arr[ArrayUtil.getRandomIndex(arr)];
  }

  /**
   * removes a random element from an array
   * @param {Array} arr
   * @returns {*}
   */
  static removeRandomElement(arr) {
    return arr.splice(ArrayUtil.getRandomIndex(arr), 1)[0];
  }

  /**
  * return a jumbled dulpicate of an array
  * @param {Array} arr
  * @returns {Array}
  */
  static jumbleArray(arr) {
    const copyArr = ArrayUtil.clone(arr);
    const jumbledArr = [];
    while (copyArr.length > 0) {
      jumbledArr.push(ArrayUtil.removeRandomElement(copyArr));
    }
    return jumbledArr;
  }

  /**
   * clone an array
   * @param {Array} arr
   */
  static clone(arr) {
    return arr.slice();
  }

  /**
   * flatten an array
   * @param {Array} arr
   * @param {number} maxDepth
   * @returns {Array}
   */
  static flatten(arr, maxDepth = Infinity) {
    const workingArray = [];
    let currentDepth = 0;

    const flattenSubArray = (subArr) => {
      currentDepth++;
      subArr.forEach((elem) => {
        // Check to see if elem is an array
        if (currentDepth < maxDepth && Object.prototype.toString.call(elem) === '[object Array]') {
          flattenSubArray(elem);
        } else {
          workingArray.push(elem);
        }
      });
      currentDepth--;
      return workingArray;
    };
    return flattenSubArray(arr);
  }
}

window.ArrayUtil = ArrayUtil;
