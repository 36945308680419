/**
 * class for Vector Math functions
 */
export default class VectorMathUtil {
  /**
   * convert radians to degrees
   * @param {number} rads
   * @returns {number}
   */
  static radsToDegrees(rads) {
    return (180 * rads) / Math.PI;
  }

  /**
   * convert degrees to radians
   * @param {number} degrees
   * @returns {number}
   */
  static degreesToRads(degrees) {
    return (degrees * Math.PI) / 180;
  }

  /**
   * gets the point on the circles circumference at the given angle.
   * @param {number} center
   * @param {number} centerY
   * @param {number} radius
   * @param {number} angle
   * @param {Object} out
   * @returns {Object} { x, y }
   */
  static getPointOnCircle(centerX, centerY, radius, angle, out = {}) {
    out.x = centerX + radius * Math.cos(angle);
    out.y = centerY + radius * Math.sin(angle);
    return out;
  }

  /**
   * get unit vector from vector
   * @param {number} x
   * @param {number} y
   * @param {number} out
   * @returns {Object} { x, y }
   */
  static getUnitVector(x, y, out = {}) {
    const length = this.getVectorLength(x, y);
    out.x = x / length;
    out.y = y / length;
    return out;
  }

  /**
   * get length of a vector
   * @param {number} x
   * @param {number} y
   * @returns {number}
   */
  static getVectorLength(x, y) {
    return Math.sqrt(x * x + y * y);
  }

  /**
   * get angle between from one vector to another
   * @param {number} v1x
   * @param {number} v1y
   * @param {number} v2x
   * @param {number} v2y
   * @returns {number}
   */
  static getAngleToVector(v1x, v1y, v2x, v2y) {
    return Math.atan2(v2y - v1y, v2x - v1x);
  }
}
