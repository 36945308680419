export default class PointsLayer extends Phaser.Group {
  /**
   * The text of the points that fly from the broken candy to the points counter
   * @param {DisplayObject} pointsCounter
   */
  constructor(pointsCounter) {
    super(game);

    this.pointsCounter = pointsCounter;

    this._signalBinding = G.sb('displayPoints').add(this._onPointMade, this);

    this.deadArray = [];
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._signalBinding) {
      if (this._signalBinding.detach) {
        this._signalBinding.detach();
      }
      this._signalBinding = null;
    }
    this.deadArray.forEach((child) => {
      child.destroy();
    });
    super.destroy();
  }

  /**
   * Gets a text particle
   */
  _getFreeText() {
    let part;

    if (this.deadArray.length > 0) {
      part = this.deadArray.pop();
    } else {
      part = new G.Text(0, 0, '0', {
        style: 'font-blue',
        fontSize: '50px',
      }, 0.5, 500);
      part.events.onKilled.add(this._onElemKilled, this);
    }

    this.add(part);
    return part;
  }

  /**
   * When the point text is done
   * @param {G.Text} elem
   */
  _onElemKilled(elem) {
    if (this !== elem.parent) return;
    this.deadArray.push(elem);
    this.removeChild(elem);
  }

  /**
   * When points are made on the game board, they generate and fly to somewhere
   * @param {number} x
   * @param {number} y
   * @param {number} amount
   * @param {string} color
   */
  _onPointMade(x, y, amount, color) {
    const txt = this._getFreeText();
    txt.revive();
    txt.target = this.pointsCounter;

    let style;
    if (color) {
      style = G.Text.styles[`font-score-${color}`] ? `font-score-${color}` : 'font-score-0';
    } else {
      style = 'font-score-0';
    }

    txt.setStyle(style, false);
    txt.x = x;
    txt.y = y;
    txt.scale.setTo(1);
    txt.alpha = 1;
    txt.setText(`+${amount.toString()}`);

    game.add.tween(txt.scale).from({ x: 0, y: 0 }, 300, Phaser.Easing.Bounce.InOut, true).onComplete.add(() => {
      const targetX = txt.target.worldPosition.x + game.world.bounds.x;
      const targetY = txt.target.worldPosition.y;

      const time = 500;

      game.add.tween(txt).to({ x: targetX, y: targetY }, time, Phaser.Easing.Sinusoidal.InOut, true);
      game.add.tween(txt.scale).to({ x: 0, y: 0 }, 300, Phaser.Easing.Cubic.In, true, time).onComplete.add(() => {
        txt.kill();
      });
    });
  }
}
