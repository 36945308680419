import WorldMap2_Util, { interactionType } from '../WorldMap2_Util';
import { CHARACTER_KEYS } from '../../GingyTutorial/G.GingyCharacterTutorial';

/* eslint-disable no-use-before-define */
export default class WorldMapChestShuffleEntry extends G.PoolGroup {
  /**
   * This is the poolgroup for the World Map 2.0's chest shuffle entry button.
   * Clicking on it should open the chest shuffle window (in WorldMap2.js)
   */
  constructor() {
    super(WorldMapChests);

    this._interactionType = interactionType.chestShuffle; // Interaction type is used externally
    this._actives = [];
    this.signals = {
      onChestClicked: new Phaser.Signal(),
      onChestError: new Phaser.Signal(),
    };
  }

  /**
   * Moves all active assets by deltaY
   * @param {number} deltaY
   */
  moveTiles(deltaY) {
    // update active segments
    for (let i = this._actives.length - 1; i >= 0; i--) {
      this._actives[i].y += deltaY;
    }
  }

  /**
   * Kills all assets in this index
   * @param {number} index
   */
  killTile(index) {
    const tile = this._actives.find((seg) => seg.index === index);
    if (tile) {
      tile.kill();
      this._actives.splice(this._actives.indexOf(tile), 1);
    }
  }

  /**
   * Adds a tile of chestShuffle based on the given config
   * @param {{index: number, unlockLevel:number, seed:number, startY:number, tileIndex:number, dummy:boolean}} config
   * @returns {(WorldMapChestShuffleEntry | null)}
   */
  addTile(config) {
    if (config.index > 0) { return null; } // Will not generate chests on tiles that are positive
    const chest = this.getFreeElement();
    chest.interactionType = this._interactionType;

    // Determine positioning
    const positoning = WorldMap2_Util.getEntryInArrayLoop(WorldMap2_Util.getEntryInArrayLoop(G.OMTsettings.elements.worldMap.chestShufflePositions, config.tileIndex), config.seed);
    chest.x = positoning.x;
    chest.y = positoning.y + config.startY;

    // Init chest
    chest.init(config.index, config.unlockLevel, config.seed, config.dummy);
    chest.setChestScale(positoning.scale); // Set facing
    chest.onClick.add(() => { // Set on click
      if (chest.interactive) {
        this.signals.onChestClicked.dispatch(chest);
      } else {
        this.signals.onChestError.dispatch(chest, OMT.language.getText('Win level %LEVEL%').replace('%LEVEL%', chest.unlockLevel + 1));
      }
    });

    this._actives.push(chest); // Push into actives
    this.addChild(chest); // Add to display
    return this;
  }

  /**
   * Plays the outro fade of a chest when its clicked.
   * Kills the chest after
   * @param {WorldMapChest} chest
   */
  playOutro(chest) {
    G.saveState.chestShuffleDataManager.markChestAsSeen(chest.unlockLevel);
    game.add.tween(chest).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 1000).onComplete.add(() => {
      this.killTile(chest.index);
      chest.alpha = 1;
    });
  }
}

class WorldMapChests extends G.Button {
  /**
   * The Chest Shuffle chest asset and button
   */
  constructor() {
    super(0, 0, null);
    this.scaleOnClick = false;
    this._mascotScale = new Phaser.Group(game, this);
    this._shadow = G.makeImage(0, 0, 'chestShuffleShadow', 0.5, this._mascotScale);
    this._allTweens = [];
    this._signalTokens = [
      G.sb('pauseAllUpdate').add(this._pauseAnimation.bind(this)),
      G.sb('resumeAllUpdate').add(this._resumeAnimation.bind(this)),
    ];
    this._resumeAnimation();
  }

  /**
   * Destroy!
   */
  destroy() {
    for (const signal of this._signalTokens) if (signal) signal.detach();
    this._signalTokens.length = 0;
    super.destroy();
  }

  /**
   *
   * @param {number} index
   * @param {number} unlockLevel
   * @param {number} seed
   * @param {boolean} dummy
   */
  init(index, unlockLevel, seed, dummy) {
    this._killTweens(); // Kills all tweens running if there is
    this.index = index;
    this.unlockLevel = unlockLevel;
    // this.alpha = 1; // Reset alpha
    const curLevel = G.saveState.getLastPassedLevelNr();
    this.interactive = curLevel >= this.unlockLevel;

    const firstTime = dummy || G.saveState.data.finishedTutorials.indexOf('chestShuffle') === -1; // First time for chest shuffle?
    let charKey = firstTime ? CHARACTER_KEYS.fancyGingy : null; // If it is, index 0, if not, null
    if (charKey === null) {
      charKey = '';
      let totalPossibilities = 0;
      const GShuffleRate = G.OMTsettings.elements.chestShuffle.rates;
      for (let i = 0; i < GShuffleRate.length; i++) { // Determine which character it is through rates based on seed
        const rateData = GShuffleRate[i];
        totalPossibilities += Math.round(rateData.rate * 2.54); // 254 is the seed map max number
        if (seed < totalPossibilities) {
          charKey = rateData.char;
          break;
        }
      }
      if (charKey === '') { // Unable to get index
        charKey = CHARACTER_KEYS.fancyGingy;
      }
    }
    this._char = charKey; // Set char
    this._drawCharacter();
    this._shadow.alpha = 1;
    this._shadow.scale.set(1);
    this._shadow.y = -this._mascot.height * 0.025;

    // Check if can be clicked
    // this._mascot.alpha = this.interactive ? 1 : 0.3 + (curLevel / this.unlockLevel) * 0.5; // Closer to unlock, the more un-alpha'd it is
    if (this.interactive) {
      if (!this._exclaim) { // Make exclaim bubble if can be interacted with
        this._exclaim = G.makeImage(0, 0, 'chestShuffleExclaim', 0, this._mascotScale);
      } else {
        this._mascotScale.addChild(this._exclaim);
      }
      this._exclaim.x = this._mascot.x + this._mascot.width * 0.4;
      this._exclaim.y = -this._mascot.height - this._exclaim.height / 2;
    }
    this.revive();
  }

  /**
   * Draw character based on char index
   */
  _drawCharacter() {
    const mascotData = G.OMTsettings.elements.characterAssets[this._char];
    this._mascot = new Phaser.Group(game, this._mascotScale);
    const offsetGroup = new Phaser.Group(game, this._mascot);
    G.makeImage(0, 0, mascotData.fullBody, [0.5, 1], offsetGroup);
    if (this.interactive) {
      this._loopTimer = game.time.events.loop(2000, this.jump.bind(this));
    }
  }

  /**
   * Set facing of character
   * @param {number} sc
   */
  setChestScale(sc) {
    this._mascotScale.scale.x = sc;
  }

  /**
   * Returns the char index number
   * @returns {CHARACTER_KEYS}
   */
  get charKey() {
    return this._char;
  }

  /**
   * Kills asset.
   */
  kill() {
    if (this._loopTimer) {
      game.time.events.remove(this._loopTimer);
      this._loopTimer = null;
    }
    this._killTweens();
    this.onClick.removeAll();
    this._mascot.destroy();
    this._mascot = null;
    if (this._exclaim) {
      this._mascotScale.removeChild(this._exclaim);
    }
    super.kill();
  }

  /**
   * Kills all tweens on this asset
   */
  _killTweens() {
    this._allTweens.forEach((tw) => {
      if (tw.stop) {
        tw.stop();
      }
    });
    this._allTweens = [];
  }

  /**
   * Creates the jumping asset.
   * Uses onComplete instead of chain so it cna be stopped easier
   */
  jump() {
    this._killTweens();
    this._mascot.scale.set(1);
    this._mascot.y = 0;
    if (!this._isTweenAllowedToRun()) { return; }
    const squish = game.add.tween(this._mascot.scale).to({ x: [1.1, 0.9], y: [0.9, 1.1] }, 1000, Phaser.Easing.Cubic.In);
    const bend = game.add.tween(this._mascot).to({ y: [0, -50] }, 1000, Phaser.Easing.Cubic.In);
    const shadowTweenA = game.add.tween(this._shadow).to({ alpha: [1, 0.3] }, 1000, Phaser.Easing.Cubic.In);
    const shadowTweenC = game.add.tween(this._shadow.scale).to({ x: [1, 1.15], y: [1, 1.15] }, 1000, Phaser.Easing.Cubic.In);
    squish.onComplete.addOnce(() => {
      if (this._isTweenAllowedToRun()) {
        const pull = game.add.tween(this._mascot.scale).to({ x: [0.9, 1.3, 1], y: [1.1, 0.7, 1] }, 1000, Phaser.Easing.Cubic.Out);
        const jumping = game.add.tween(this._mascot).to({ y: [0, 0] }, 1000, Phaser.Easing.Cubic.Out);
        const shadowTweenB = game.add.tween(this._shadow).to({ alpha: [1, 1] }, 1000, Phaser.Easing.Cubic.Out);
        const shadowTweenD = game.add.tween(this._shadow.scale).to({ x: [1, 1], y: [1, 1] }, 1000, Phaser.Easing.Cubic.Out);
        this._allTweens.push([pull, jumping, shadowTweenB, shadowTweenD]);
        pull.start();
        jumping.start();
        shadowTweenB.start();
        shadowTweenD.start();
      }
    });

    this._allTweens.push([squish, bend, shadowTweenA, shadowTweenC]);
    squish.start();
    bend.start();
    shadowTweenA.start();
    shadowTweenC.start();
  }

  /**
   * Sets the animation flag to off
   */
  _pauseAnimation() {
    this._animate = false;
  }

  /**
   * Sets the animation flag to on
   */
  _resumeAnimation() {
    this._animate = true;
  }

  /**
   * Checks if this is allowed to animate
   */
  _isTweenAllowedToRun() {
    return this._animate && this._mascot && this.alive;
  }
}
