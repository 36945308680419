const VS_FADE_OUT_DURATION = 333;
const VS_FADE_OUT_DELAY = 200;

/**
 * class for showing / managing tournament social activity during gameplay
 */
export class TournamentSocialDisplayBase extends Phaser.Group {
  /**
   * constructor
   * @param {BoardGameHooks}
   */
  constructor(gameHooks) {
    super(game);

    this._gameHooks = gameHooks;
    this._competitorEntryData = null;
    this._competitorView = null;

    // get / clone the last fetched leadboard entries
    this._leaderboardEntries = OMT.platformTournaments.lastFetchedTournamentEntries.slice();
    this._leaderboardEntries = this._leaderboardEntries.filter((entryData) => entryData.userId !== OMT.envData.settings.user.userId);

    // if we have leaderboard entries initialize visual elements
    if (this._leaderboardEntries.length > 0) {
      this._initBg();
      this._initVsIcon();
    }
  }

  /**
   * Returns the number of people in this tournament context
   * @returns {number}
   */
  get numberOfParticipants() {
    return this._leaderboardEntries.length;
  }

  /**
   * init the bg image
   */
  _initBg() {
    // Override
  }

  /**
   * init the VS icon overlay
   */
  _initVsIcon() {
    // Override
  }

  /**
   * hide the VS icon
   */
  _hideVsIcon() {
    game.add.tween(this._vsIcon).to({ alpha: 0 }, VS_FADE_OUT_DURATION, Phaser.Easing.Quadratic.Out, true, VS_FADE_OUT_DELAY);
  }

  /**
   * set the current points, update the competitor view if rank changed
   * @param {number} points
   */
  setPoints(points) {
    // only show competitors if we have leaderboard entries and you did not acheive top score this play.
    if (this._topScoreView != null || this._leaderboardEntries.length === 0) return;
    const entryData = this._findNextHighestEntryData(points);
    const isCurrentCompetitor = this._competitorView != null && this._competitorView.entryData === entryData;
    if (!isCurrentCompetitor) {
      // animate out the previous competitor
      if (this._competitorView) this._animateOutPrevCompetitorView(this._competitorView);
      // show next competitor or the top score view
      if (entryData) {
        this._showNextCompetitorView(entryData);
      } else {
        this._competitorView = null;
        this._showTopScoreView();
      }
    }
  }

  /**
   * set the current points, update the competitor view if rank changed
   * @param {number} points
   * @returns {Object}
   */
  _findNextHighestEntryData(points) {
    let entryData;
    for (let i = this._leaderboardEntries.length - 1; i >= 0; i--) {
      entryData = this._leaderboardEntries[i];
      if (entryData.score > points) return entryData;
    }
    return null;
  }

  /**
   * show the next competitor view
   * @param {Object} entryData
   * @param {{Class:TournamentCompetitorScoreViewBase, x:number, y:number, entryData:{imaage:string, rank:number, score:number}}} config
   * @returns {Promise}
   */
  _showNextCompetitorView(config) {
    const competitorView = new config.Class(config.entryData);
    competitorView.x = config.x;
    competitorView.y = config.y;
    return competitorView;
  }

  /**
   * animate out the previous competitor view
   * @param {TournamentCompetitorScoreView} competitorView
   */
  _animateOutPrevCompetitorView(competitorView) {
    competitorView.animateOut();
  }

  /**
   * show the view for overtaking #1 on leaderboard
   * @param {{Class:TournamentCompetitorTopScoreViewBase, x:number, y:number}} config
   */
  _showTopScoreView(config) {
    const topScoreView = new config.Class(this._leaderboardEntries);
    topScoreView.x = config.x;
    topScoreView.y = config.y;
    return topScoreView;
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._gameHooks = null;
  }
}
