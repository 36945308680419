import { GameScaleController } from '../States/Scaling/GameScaleController'

/**
 * Class for loading and transition screen
 */
export class FadeLayer extends Phaser.Group {
  constructor(noFadeLayer) {
    super(game, null, 'FadeLayer', false, false, false);
    this._fadeLayerConfig = G.json['ui/fadeLayer'];
    const { gameScale } = GameScaleController.getInstance();

    this._center = {
      x: game.world.bounds.x + game.width / 2,
      y: game.world.bounds.y + game.height / 2,
    };

    // Background
    this.background = game.add.graphics(0, 0);
    this.background.beginFill(this._fadeLayerConfig.backgroundColour[0]);
    this.background.drawRect(game.world.bounds.x, game.world.bounds.y, game.width, game.height);
    this.background.endFill();
    this.addChild(this.background);

    // Scalable Elements
    this.scalableGroup = game.add.group();
    this.scalableGroup.scale.setTo(1);
    this.addChild(this.scalableGroup);

    // Godrays
    this.godrays = G.makeImage(this._center.x + this._fadeLayerConfig.godRays.x, this._center.y + this._fadeLayerConfig.godRays.y, 'shine_godrays', 0.5, this.scalableGroup);
    this.godrays.scale.setTo(this._fadeLayerConfig.godRays.scale, this._fadeLayerConfig.godRays.scale);

    // Cloud Group (Cloud, Stars, Text)
    this.cloudGroup = game.add.group();
    this.cloudGroup.x = this._center.x + this._fadeLayerConfig.cloudGroup.x;
    this.cloudGroup.y = this._center.y + this._fadeLayerConfig.cloudGroup.y + this._fadeLayerConfig.cloudGroup.yBobbing;

    // Cloud and Text
    this.cloud = G.makeImage(this._fadeLayerConfig.cloudGroup.cloud.x, this._fadeLayerConfig.cloudGroup.cloud.y, 'cloud_1', 0.5, this.cloudGroup);
    this.text = new G.Text(this._fadeLayerConfig.cloudGroup.text.x, this._fadeLayerConfig.cloudGroup.text.y, 'Loading...', this._fadeLayerConfig.cloudGroup.text.style, 0.5, 440, 200, true);
    this.cloudGroup.addChild(this.text);

    // Stars
    const starsConfig = this._fadeLayerConfig.cloudGroup.stars;

    for (let i = 0; i < starsConfig.length; i++) {
      const star = G.makeImage(starsConfig[i].x, starsConfig[i].y, 'star', 0.5, this.cloudGroup);
      star.scale = { x: starsConfig[i].scale, y: starsConfig[i].scale };
      star.rotation = starsConfig[i].rotation;
    }

    this.scalableGroup.addChild(this.cloudGroup);

    // Map Points
    this.nodeXStart = this._fadeLayerConfig.mapPoints.x - this._fadeLayerConfig.mapPoints.spacing * ((this._fadeLayerConfig.mapPoints.numNodes - 1) / 2);
    this.mapPoints = [];
    this.onMapPoints = [];

    for (let i = 0; i < this._fadeLayerConfig.mapPoints.numNodes; i++) {
      const mapPointGroup = game.add.group();
      mapPointGroup.x = this._center.x + this.nodeXStart + i * this._fadeLayerConfig.mapPoints.spacing;
      mapPointGroup.y = this._center.y + this._fadeLayerConfig.mapPoints.y;
      this.scalableGroup.addChild(mapPointGroup);

      G.makeImage(0, 0, this._fadeLayerConfig.mapPoints.spriteOff, 0.5, mapPointGroup);
      const mapPointOn = G.makeImage(0, 0, this._fadeLayerConfig.mapPoints.spriteOn, 0.5, mapPointGroup);

      mapPointGroup.scale.setTo(this._fadeLayerConfig.mapPoints.scale, this._fadeLayerConfig.mapPoints.scale);

      this.mapPoints.push(mapPointGroup);
      this.onMapPoints.push(mapPointOn);
    }

    this._resetAllTweens();

    // Fade everything out
    if (game.state.current !== 'Preloader') {
      if (!noFadeLayer) {
        const screenTween = game.add.tween(this).to({ alpha: 0 }, this._fadeLayerConfig.fadeOutTime, Phaser.Easing.Linear.None, true);
        screenTween.onComplete.addOnce(() => {
          // sets the first interaction time if not already set
          // DDNA.tracking.getDataCapture().setFirstInteractionTime();
          this.visible = false;
        }, this);
      } else {
        // sets the first interaction time if not already set
        // DDNA.tracking.getDataCapture().setFirstInteractionTime();
        this.visible = false;
      }
    }
    G.sb('onStateChange').add(this.setupChange, this);
    game.add.existing(this);
  }

  /**
   * Transitions between game states
   * @param {*} changeLevel
   * @param {*} arg1
   * @param {*} arg2
   * @param {*} arg3
   * @param {*} arg4
   */
  setupChange(changeLevel, arg1, arg2, arg3, arg4) {
    const includeDelay = false; // !G.BuildEnvironment.production // Set it to this if you wish to test the loading screen
    if (arg1 && typeof arg1 === 'object') {
      const { noFadeLayer } = arg1;
      if (noFadeLayer) {
        this._changeState(includeDelay, changeLevel, arg1, arg2, arg3, arg4);
        return;
      }
    }
    game.world.bringToTop(this);

    if (game.state.current !== 'Preloader') {
      this._resetAllTweens();

      // Fade everything in
      this.visible = true;
      this.alpha = 0;
      const screenTween = game.add.tween(this).to({ alpha: 1 }, this._fadeLayerConfig.fadeInTime, Phaser.Easing.Linear.None, true);

      // Change game state fading in and optional delay in dev environment
      screenTween.onComplete.addOnce(() => this._changeState(includeDelay, changeLevel, arg1, arg2, arg3, arg4), this);
    } else {
      // Change game state after optional delay in dev environment
      this._changeState(includeDelay, changeLevel, arg1, arg2, arg3, arg4);
    }

    let logStr = `Switching state!\nname: ${changeLevel}`;
    if (arg1) logStr += `\ndata: ${JSON.stringify(arg1)}`;
    console.log(logStr);
    G.sfx.transition.play();
  }

  /**
   * Resets all animations back to their starting state
   */
  _resetAllTweens() {
    G.stopTweens(this.godrays);
    this.godrays.rotation = 0;
    game.add.tween(this.godrays).to({ rotation: this._fadeLayerConfig.godRays.rotation }, this._fadeLayerConfig.godRays.time, Phaser.Easing.Linear.None, true, 0, -1, false);

    G.stopTweens(this.cloudGroup);
    this.cloudGroup.x = this._center.x + this._fadeLayerConfig.cloudGroup.x;
    this.cloudGroup.y = this._center.y + this._fadeLayerConfig.cloudGroup.y + this._fadeLayerConfig.cloudGroup.yBobbing;
    game.add
      .tween(this.cloudGroup)
      .to({
        y: this._center.y + this._fadeLayerConfig.cloudGroup.y - this._fadeLayerConfig.cloudGroup.yBobbing,
      }, this._fadeLayerConfig.cloudGroup.time, Phaser.Easing.Linear.None, true, 0, -1, true);

    for (let i = 0; i < this.mapPoints.length; i++) {
      G.stopTweens(this.mapPoints[i]);
      G.stopTweens(this.onMapPoints[i]);
      this.mapPoints[i].x = this._center.x + this.nodeXStart + i * this._fadeLayerConfig.mapPoints.spacing;
      this.mapPoints[i].y = this._center.y + this._fadeLayerConfig.mapPoints.y;
      this.mapPoints[i].scale.setTo(this._fadeLayerConfig.mapPoints.scale, this._fadeLayerConfig.mapPoints.scale);
      this.onMapPoints[i].alpha = 0;
      this._setupMapPointTweenSequence(this.mapPoints[i], this.onMapPoints[i], i);
    }
  }

  /**
   * Sets up map point tween sequence
   * @param {Phaser.Group} mapPointGroup
   * @param {Phaser.Image} fadingPoint
   * @param {number} index
   */
  _setupMapPointTweenSequence(mapPointGroup, fadingPoint, index) {
    // Tween Sequence:
    // Up off delayed -> Down on -> Pause on -> Up on -> Down off -> Pause off -> Up off -> Down on -> ... ad infinitum

    // Up off delayed
    const tweenUpOffDelayed = game.add.tween(mapPointGroup)
      .to({
        y: this._center.y + this._fadeLayerConfig.mapPoints.y - this._fadeLayerConfig.mapPoints.jumpHeight,
      }, this._fadeLayerConfig.mapPoints.time, Phaser.Easing.Quintic.Out, true, index * this._fadeLayerConfig.mapPoints.delay, 0, false);

    tweenUpOffDelayed.onStart.add(() => this._animateMapPoint(mapPointGroup, fadingPoint, true));

    // Up off
    const tweenUpOff = game.add.tween(mapPointGroup)
      .to({ y: this._center.y + this._fadeLayerConfig.mapPoints.y - this._fadeLayerConfig.mapPoints.jumpHeight }, this._fadeLayerConfig.mapPoints.time, Phaser.Easing.Quintic.Out, false, 0, 0, false);

    tweenUpOff.onStart.add(() => this._animateMapPoint(mapPointGroup, fadingPoint, true));

    // Down on
    const tweenDownOn = game.add.tween(mapPointGroup)
      .to({ y: this._center.y + this._fadeLayerConfig.mapPoints.y }, this._fadeLayerConfig.mapPoints.time, Phaser.Easing.Quintic.In, false, 0, 0, false);

    // Pause on
    const pauseOn = game.add.tween(mapPointGroup)
      .to({ y: mapPointGroup.y }, this._fadeLayerConfig.mapPoints.time * 2, Phaser.Easing.Linear.None, false, 0, 0, false);

    pauseOn.onStart.add(() => this._jiggleOnPause(mapPointGroup));

    // Up on
    const tweenUpOn = game.add.tween(mapPointGroup)
      .to({ y: this._center.y + this._fadeLayerConfig.mapPoints.y - this._fadeLayerConfig.mapPoints.jumpHeight }, this._fadeLayerConfig.mapPoints.time, Phaser.Easing.Quintic.Out, false, 0, 0, false);

    tweenUpOn.onStart.add(() => this._animateMapPoint(mapPointGroup, fadingPoint, false));

    // Down off
    const tweenDownOff = game.add.tween(mapPointGroup)
      .to({ y: this._center.y + this._fadeLayerConfig.mapPoints.y }, this._fadeLayerConfig.mapPoints.time, Phaser.Easing.Quintic.In, false, 0, 0, false);

    // Pause off
    const pauseOff = game.add.tween(mapPointGroup)
      .to({ y: mapPointGroup.y }, this._fadeLayerConfig.mapPoints.time * 2, Phaser.Easing.Linear.None, false, 0, 0, false);

    pauseOff.onStart.add(() => this._jiggleOnPause(mapPointGroup));

    tweenUpOffDelayed.chain(tweenDownOn);
    tweenDownOn.chain(pauseOn);
    pauseOn.chain(tweenUpOn);
    tweenUpOn.chain(tweenDownOff);
    tweenDownOff.chain(pauseOff);
    pauseOff.chain(tweenUpOff);
    tweenUpOff.chain(tweenDownOn);
  }

  /**
   * Adds squash-and-stretch and fading animations to map point
   * @param {Phaser.Group} mapPointGroup
   * @param {Phaser.Image} fadingPoint
   * @param {boolean} fadeIn
   */
  _animateMapPoint(mapPointGroup, fadingPoint, fadeIn) {
    const duration = this._fadeLayerConfig.mapPoints.time / 2;

    const squash = game.add.tween(mapPointGroup.scale)
      .to({ x: this._fadeLayerConfig.mapPoints.scale, y: this._fadeLayerConfig.mapPoints.jumpYScale }, duration, Phaser.Easing.Linear.None, true, duration, 0, false);

    const stretch = game.add.tween(mapPointGroup.scale)
      .to({ x: this._fadeLayerConfig.mapPoints.scale, y: this._fadeLayerConfig.mapPoints.scale }, duration, Phaser.Easing.Linear.None, true, 0, 0, false);

    squash.chain(stretch);
    game.add.tween(fadingPoint)
      .to({ alpha: fadeIn ? 1 : 0 }, duration, Phaser.Easing.Linear.None, true, duration, 0, false);
  }

  /**
   * Adds jiggle animation to map point upon landing
   * @param {Phase.Group} mapPointGroup
   */
  _jiggleOnPause(mapPointGroup) {
    // eslint-disable-next-line no-param-reassign
    mapPointGroup.scale.y = this._fadeLayerConfig.mapPoints.landYScale;
    game.add
      .tween(mapPointGroup.scale)
      .to({ x: this._fadeLayerConfig.mapPoints.scale, y: this._fadeLayerConfig.mapPoints.scale }, this._fadeLayerConfig.mapPoints.time, Phaser.Easing.Bounce.Out, true, 0, 0, false);
  }

  /**
   * Change game state, adding an optional delay (set in config)
   * @param {boolean} addDelay
   * @param {*} changeLevel
   * @param {*} arg1
   * @param {*} arg2
   * @param {*} arg3
   * @param {*} arg4
   */
  _changeState(addDelay, changeLevel, arg1, arg2, arg3, arg4) {
    if (addDelay) {
      setTimeout(() => {
        game.state.start(changeLevel, true, false, arg1, arg2, arg3, arg4);
      }, this._fadeLayerConfig.waitDelay);
    } else {
      game.state.start(changeLevel, true, false, arg1, arg2, arg3, arg4);
    }
  }
}
