/* eslint-disable func-names */

import Window_Level from './Window_level';
import { Window_FillRateFallback } from './Window_FillRateFallback';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';
import VillainsConversation from '../../OMT_UI/Villains/VillainsConversation';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

/**
 * Class for pre-level screen for the daily challenge
 */
class Window_dailyChallenge extends Window_Level {
  /**
   * Manage the mode tracker
   */
  _manageMode() {
    if (game.state.getCurrentState().mode === LevelType.NONE) { // Game state will manage but World state does not
      game.state.getCurrentState().mode = LevelType.CHALLENGE;
    }
    this.gameMode = LevelType.CHALLENGE;
  }

  /**
   * Construct the window and create all functionality attached to it
   * @param {number} optLvlIndex Optional level index to create the level window and also set the G.lvl data accordingly
   */
  _construct(optLvlIndex) {
    super._construct(optLvlIndex);

    this.onFinishedEnter.addOnce(() => {
      if (game.state.current === 'World' && OMT.feature.isVillainsEnabled()) {
        this._setupVillains();
      }
    });

    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) { // Might be not be good to keep this
      this.y += 60;
    }
  }

  /**
   * display stars achived
   * @param {number} starsAchieved how many stars the player has achieved in that level.
   */
  _initStars(starsAchieved) {
    // No stars, apparetly
    /**
    starsAchieved = G.saveState.getChallengeStars();

    const sideStarY = -140;
    const createCoinImage = (x, y, filled) => {
      const image = G.makeImage(x, y, filled ? 'coin_1' : 'coin_blank', 0.5, this._levelContainer);
      if (filled) {
        const container = G.makeImage(x, y, null, 0.5, this);
        container.addChild(image);
        image.position.set(0, 0);
        image.scale.set(G.OMTsettings.elements.dailyChallengeSettings.coinSize / image.width);
        return container;
      }
      return image;
    };
    this._stars = [
      createCoinImage(-90, sideStarY, starsAchieved >= 1),
      createCoinImage(0, sideStarY - 10, starsAchieved >= 2),
      createCoinImage(90, sideStarY, starsAchieved >= 3),
    ];
    this._stars[0].scale.setTo(0.6);
    this._stars[1].scale.setTo(0.8);
    this._stars[2].scale.setTo(0.6);
    */
  }

  /**
   * setup level data
   * @param {*} level
   */
  // the level passed here is actually the data, not just the level index (different from Window_level)
  _manageData(level) {
    // this is needed for the levelWindowOpen signal and this function is run before thats dispatched in Window_level
    this._parentLevelName = 'dailyChallenge';
    // set global level data
    if (level !== undefined) {
      // when we get level the first time, its an object, but if we go to the shop and come back, its a number, so we check for both.
      if (typeof level === 'number') {
        G.lvlData = G.Helpers.levelDataMgr.getLevelByIndex(level);
      } else {
        G.lvlData = level;
      }
      this.levelData = G.lvlData;
      G.lvlNr = this.levelData.levelNumber;
    }
    this._parentLevelName = 'level';
    this.dailyChallengeLevel = this.levelData.levelIndex;

    OMT_VILLAINS.setLevelType(this.gameMode);
  }

  /**
   * init the generic graphics layout
   */
  _initLayout() {
    this.rewardedPlayActive = G.saveState.data.rewardedChallenge;
    super._initLayout(OMT.language.getText('Daily Challenge'), true);
    this.y += 70;
  }

  _checkMysteryGiftHeader() {
  }

  /**
   * init window buttons
   */
  _initButtons() {
    super._initButtons(BUTTONCOLOURS.orange, this._onContinueClick, false);

    // _continueBtn is defined in super._initButtons
    this._continueBtn.visible = G.saveState.isChallengeAvailable() || this.rewardedPlayActive;

    this._makeAdContinueButton();
  }

  _makeAdContinueButton() {
    const onAdSuccess = () => {
      G.saveState.enableRewardedChallenge();
      this.adBtn.visible = false;
      this._continueBtn.visible = true;
      this.rewardedPlayActive = true;
    };
    // this.adBtn = new Phaser.Group(G.Game, null);
    // this.adBtn.y = 300;
    const adClick = () => {
      if (!this.adBtn.input.enabled) { return; }
      // do something
      OMT.ads.showAd(
        G.BuildEnvironment.adPlacements.retryChallenge,
        onAdSuccess,
        () => {
          if (!G.IAP) { // no IAPS dont use ad fallbacks
            // eslint-disable-next-line no-new
            new Window_FillRateFallback(undefined, {
              placement: G.BuildEnvironment.adPlacements.retryChallenge,
              callback: onAdSuccess,
              onExit: () => {},
              context: this,
            });
          } else { // open ad fallback
            OMT.ads.showAdFallback(() => {
              onAdSuccess();
            });
          }
        },
      );
    };
    const adImg = G.makeImage(0, 0, 'btn-movie-icon', 0.5, null);
    this.adBtn = new OMT_UI_SquareButton(0, this._continueBtn.y, {
      button: {
        tint: BUTTONCOLOURS.purple,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: { style: 'font-white', fontSize: 40, align: 'center' },
        icon: {
          image: adImg,
          offset: {
            x: adImg.width / 2,
          },
          align: 'left',
        },
      },
      options: {
        clickFunction: {
          onClick: adClick.bind(this),
        },
        pulse: 1.1,
      },
    });
    this.registerButtons(this.adBtn);
    this._levelContainer.add(this.adBtn);
    this.adBtn.visible = !G.saveState.isChallengeAvailable() && (OMT.feature.rewardedAdsAvailable() && !this.rewardedPlayActive);
  }

  /**
   * init task UI
   */
  _initTasks() {
    super._initTasks(LevelType.CHALLENGE);
    const everything = [this._taskBg, this._taskTxt, this._taskPanel];

    for (const thing of everything) {
      thing.y -= 80;
    }
  }

  /**
   * init booster and booster UI
   */
  _initBoosters() {
    super._initBoosters(this.dailyChallengeLevel, 'dailyChallenge');
    const everything = [this.disabledBoosterMessage, this._buyTxt, this._boosterBg].concat(this._boosters);

    for (const thing of everything) {
      thing.y -= 50;
    }
  }

  /**
   * on continue button clicked
   */
  _onContinueClick() {
    this.rewardedPlayActive = false;

    const dateNow = new Date();
    const lastTryDate = new Date(G.saveState.data.lastChallengeTry);
    if (dateNow.toDateString() !== lastTryDate.toDateString()) {
      OMT.notifications.scheduleGameTriggeredMessage(
        OMT.envData.settings.user.userId,
        'DailyChallenge',
        G.saveState.getTimeToNextChallenge() / 1000,
        false,
      );
    }

    G.saveState.startChallenge();
    G.sb('onDailyChallengeStart').dispatch();
    const levelData = {
      // removed this because it was just undefined
      // lvlIndex: this.dailyChallengeLevel,
      lvlIndex: this.dailyChallengeLevel,
      debugMode: false,
      startBoosters: super._assembleLevelBoosters(this.dailyChallengeLevel),
      challengeLvl: this.levelData,
      preLevelTrackingData: {},
    };
    G.sb('onStateChange').dispatch('Game', levelData);
  }

  /**
   * Adds villain images to the window if the corruption animation already played this session
   */
  _addVillains() {
    const villainArr = G.OMTsettings.elements.superhard.tutorialSpeech.dailyChallenge.sagaMap[0].index.concat([]);
    if (villainArr.length < 2) {
      villainArr.splice(0, villainArr.length, 1, 2);
    }
    const texture1 = OMT_VILLAINS.getPrefixedName(`villain_${villainArr[0]}_1`);
    const texture2 = OMT_VILLAINS.getPrefixedName(`villain_${villainArr[1]}_1`);

    const villainScaleCoeff = 0.45;
    const { villainsXOffset, villainsYOffset } = G.OMTsettings.elements.superhard.window_dailyChallenge;

    const villain_1 = G.makeImage(
      0,
      0,
      texture1,
      0.5,
      this._levelContainer,
    );
    villain_1.scale.setTo(villainScaleCoeff);
    villain_1.x = -this.bg.width * 0.6 + villain_1.width * 0.35 + villainsXOffset;
    villain_1.y = -this.bg.height * 0.5 - villain_1.height * 0.05 + villainsYOffset;
    villain_1.scale.x *= -1;
    this._levelContainer.addChildAt(villain_1, 0);

    const villain_2 = G.makeImage(
      0,
      0,
      texture2,
      0.5,
      this._levelContainer,
    );
    villain_2.scale.setTo(villainScaleCoeff);
    villain_2.x = this.bg.width * 0.6 - villain_2.width * 0.35 - villainsXOffset;
    villain_2.y = -this.bg.height * 0.5 - villain_2.height * 0.05 + villainsYOffset;
    this._levelContainer.addChildAt(villain_2, 0);

    return {
      left: {
        obj: villain_1,
        vIndex: villainArr[0],
      },
      right: {
        obj: villain_2,
        vIndex: villainArr[1],
      },
    };
  }

  /**
   * Sets up everything regarding the villains
   * Corruption, tutorial and everything else
   */
  _setupVillains() {
    const villiansSpeechContainer = new Phaser.Group(game, this._levelContainer);
    const villainsConversation = new VillainsConversation(villiansSpeechContainer);
    const { left, right } = this._addVillains();

    villainsConversation.fixVillainToPosition((villain) => {
      // Reset villains scale in landscape
      if (this._isLandscape) {
        const villainsClassContainer = villain.getClassContainer();
        villainsClassContainer.scale.setTo(1);
      }

      villain.getObjects({ type: 'villain' }).forEach((object) => {
        object.visible = false;
      });
      const xPos = 50;
      let yPosCoeff = 0.65;
      if (villain.tipPos === 'double') {
        yPosCoeff = 0.75;
      }
      villainsConversation._positionClass(
        villain,
        xPos,
        ((villain.getClassContainer().height * yPosCoeff) / game.height) * 100,
      );
    });
    if (G.saveState.data.lastChallengeTry === 0 && !G.saveState.data.dailyChallengeFTUEChat) { // First ever
      villainsConversation.addStepFunctions({
        2: {
          start: () => { right.obj.changeTexture(`superhard-villain_${right.vIndex}_4`); },
          end: () => { right.obj.changeTexture(`superhard-villain_${right.vIndex}_1`); },
        },
        5: {
          start: () => { right.obj.changeTexture(`superhard-villain_${right.vIndex}_4`); left.obj.changeTexture(`superhard-villain_${left.vIndex}_4`); },
          end: () => { },
        },
      });
      G.saveState.data.dailyChallengeFTUEChat = true;
      villainsConversation.createTutorial('dailyChallenge', 'sagaMap');
    } else {
      const dateNow = new Date();
      villainsConversation.createTutorial('dailyChallenge', `sagaMapRetry${dateNow.getUTCDate() % 3}`);
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.dailyChallenge = Window_dailyChallenge;
