import OMT_UI_SquareButton, { BUTTONCOLOURS } from "../../OMT_UI/OMT_UI_SquareButton";

G.Window.prototype.newsUpdate = function (newsData) {
  this.gift = ['coin', 500];
  this.y = -40;
  this.bg = this.add(new G.PopUpTileBg());
  this.bg.changeSize(900, true);
  this.blue_banner = G.makeImage(0, -295, 'blue_banner', 0.5, this);
  this.title = new G.Text(0, -297, OMT.language.getText('Good News!'), {
    style: 'font-white',
    fontSize: '60px',
  }, 0.5, 330);
  this.add(this.title);
  this.star1 = G.makeImage(250, -286, 'star', 0.5, this);
  this.star1.angle = 29;
  this.star2 = G.makeImage(-250, -286, 'star', 0.5, this);
  this.star2.angle = -29;

  this.gingy = G.makeImage(0, -80, 'gate_unlock_pic', 0.5, this);
  this.gingy.scale.setTo(0.7);

  this.newFeaturesGroup = this.newsUpdate_addNewFeaturesGroup(0, 80, newsData.bulletPoints);

  this.thanksRewardGroup = this.newsUpdate_addThanksRewardGroup(0, 258);

  G.sb('hideMainHighscorePanel').dispatch();
};

G.Window.prototype.newsUpdate_claim = function () {
  G.gift.applyGift(this.gift);
  this.closeWindow();
  G.sb('showMainHighscorePanel').dispatch();
};

G.Window.prototype.newsUpdate_addNewFeaturesGroup = function (x, y, content) {
  const group = game.make.group();
  group.position.setTo(x, y);

  group.header = group.add(new G.Text(
    0, 0, OMT.language.getText('New Features'), {
      style: 'font-blue',
      fontSize: 45,
    }, 0.5, 500,
  ));

  content.forEach(function (bulletpoint, i) {
    group.add(new G.Text(0, 45 + 40 * i, `- ${OMT.language.getText(bulletpoint)}`, {
      style: 'font-blue',
      fontSize: 30,
    }, 0.5, 500));
  });

  this.add(group);

  return group;
};

G.Window.prototype.newsUpdate_addThanksRewardGroup = function (x, y) {
  const group = game.make.group();
  group.position.setTo(x, y);

  group.header = group.add(new G.Text(
    0, 0, OMT.language.getText('...and a small thank you!'), {
      style: 'font-blue',
      fontSize: 45,
    }, 0.5, 500,
  ));

  group.text = group.add(new G.Text(
    0, 55, OMT.language.getText('Thanks for sticking around with us! Have fun with %GameName%! - Softgames Team'), {
      style: 'font-blue',
      fontSize: 25,
      lineSpacing: -10,
    }, 0.5, 500, 100, true, 'center',
  ));

  group.rewardBg = G.makeImage(0, 125, 'popup_text_backgr', 0.5, group);
  group.rewardBg.scale.setTo(1.3);

  group.reward = group.add(new G.LabelTextT(
    `${OMT.language.getText('Gift')}: @*1.2*coin_1@ 500`, 0, group.rewardBg.y, {
      style: 'font-blue',
      fontSize: 35,
    }, 0.5, 240,
  ));
  group.add(group.reward);

  const butt = new OMT_UI_SquareButton(0, 220, {
    button: {
      tint: BUTTONCOLOURS.green,
      dimensions: {
        width: 196,
        height: 100,
      },
    },
    text: {
      string: OMT.language.getText('Claim'),
      textStyle: { style: 'font-white', fontSize: 40 },
    },
    options: {
      clickFunction: {
        onClick: this.newsUpdate_claim.bind(this),
      },
    },
  });
  group.claimBtn = group.add(butt);

  this.add(group);
  return group;
};
