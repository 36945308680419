export default class TutorialGrid extends Phaser.Group {
  constructor() {
    super(game, null);
  }

  init(w, h, tileSize, spriteData, objScale) {
    this.removeAll(true);

    this.tileSize = tileSize;
    this.w = w;
    this.h = h;

    for (let y = h - 1; y >= 0; y--) { // Goes from bottom right to top left, in hopes of messing around with layer order
      for (let x = w - 1; x >= 0; x--) { // I don't think it worked. Meh.
        let sprite;
        if (typeof spriteData === 'string') {
          sprite = spriteData;
        } else if (Array.isArray(spriteData)) {
          sprite = spriteData[(x + (w * y)) % spriteData.length];
        }

        if (sprite === null) continue; // eslint-disable-line no-continue

        const element = G.makeImage(x * tileSize, y * tileSize, sprite, 0.5, this);
        element.scale.setTo(objScale || 1);
        element.cellX = x;
        element.cellY = y;
      }
    }

    this.setCenterPivot();
  }

  setCenterPivot() {
    this.pivot.x = ((this.w * 0.5) - 0.5) * this.tileSize;
    this.pivot.y = ((this.h * 0.5) - 0.5) * this.tileSize;
  }

  getSpriteByCell(cellX, cellY) {
    return this.children.find((child) => child.cellX === cellX && child.cellY === cellY);
  }

  getPxPos(cellX, cellY) {
    return {
      x: this.tileSize * cellX,
      y: this.tileSize * cellY,
    };
  }

  swapSprites(x1, y1, x2, y2) {
    const element1 = this.getSpriteByCell(x1, y1);
    const element2 = this.getSpriteByCell(x2, y2);

    const tempCoords = { x: element1.cellX, y: element1.cellY };

    element1.cellX = element2.cellX;
    element1.cellY = element2.cellY;

    element2.cellX = tempCoords.x;
    element2.cellY = tempCoords.y;
  }
}
