import OMT_UI_AvatarWithBadge from '../../OMT_UI/FriendsList/OMT_UI_AvatarWithBadge';
import ShareMsgBase from './A_G.ShareMsgBase';

class MsgTreasureHuntBrag extends ShareMsgBase {
  /**
   * The message that is sent when you got the key from a friend from answering your request for help
   * @param {function} onAllImagesReadyCallback
   */
  constructor(badgeType, bannerText, score, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);
    this.bg = this._addExtImage(0, 0, 'img/Msg_Treasure_Hunt_Brag.png', 0);
    this._addExtImage(65, 118, `img/treasureHunt/${badgeType}Full.png`, 0, 174); // badge
    const tOne = new G.Text(338, 39, bannerText, 'treasureHunt-shareMsgRegularText', 0.5, 461); // eslint-disable-line no-new
    const tTwo = new G.Text(373, 106, score, 'treasureHunt-shareMsgTokenText', 0.5, 102); // eslint-disable-line no-new
    this.addChild(tOne);
    this.addChild(tTwo);
    const avatar = new OMT_UI_AvatarWithBadge(OMT.envData.settings.user.avatar, badgeType, 125);
    avatar.x = 546;
    avatar.y = 205;
    this.addChild(avatar);
    this._objectLoadComplete();
  }
}

G.MsgTreasureHuntBrag = MsgTreasureHuntBrag;
