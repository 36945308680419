/* eslint-disable operator-linebreak */
/* eslint-disable func-names */
if (typeof G === 'undefined') G = {};
G.UI = G.UI || {};

{
  const ExportsManagerResult = function (arrayByName) {
    this._arrayByName = arrayByName;
  };

  G.UI.ExportsManager = function () {
    this._arrayByName = {};
    this.result = new ExportsManagerResult(this._arrayByName);
  };

  G.UI.ExportsManager.prototype.exportElement = function (name, element) {
    if (!this._arrayByName[name]) {
      this._arrayByName[name] = [];
    }
    this._arrayByName[name].push(element);
  };

  ExportsManagerResult.prototype.getArrayByName = function () {
    return this._arrayByName;
  };

  ExportsManagerResult.prototype.getAmountOf = function (name) {
    const array = this._arrayByName[name];

    return array ? array.length : 0;
  };

  const _forEvery = function (customArrayByName, name, action) {
    const array = customArrayByName[name];
    if (array && array.length > 0) {
      array.forEach((arrayElement, index) => {
        action(arrayElement.rootElement, index, arrayElement.exports);
      });

      return true;
    }

    return false;
  };

  const defaultValue = function (value, itsDefaultValue) {
    return value === undefined || value === null ? itsDefaultValue : value;
  };

  /** @param {boolean|"throw"|"warn"|Function} [optUnavailableAction] = "throw" */
  const handleUnavailable = function (optUnavailableAction, name) {
    const message = `Element ${name} not found`;
    if (optUnavailableAction === undefined || optUnavailableAction === null) {
      throw message;
    }
    if (typeof optUnavailableAction === 'function') {
      optUnavailableAction(name);
    } else if (typeof optUnavailableAction === 'string') {
      if (
        optUnavailableAction.length > 0 &&
        optUnavailableAction[0].toLowerCase() === 'w'
      ) {
        console.warn(message);
      } else {
        throw message;
      }
    } else if (optUnavailableAction !== false) {
      throw message;
    }
  };

  /** @param {boolean|"throw"|"warn"|Function} [optUnavailableAction] = "throw" */
  ExportsManagerResult.prototype.getFirst = function (
    name,
    optUnavailableAction,
  ) {
    const unavailableAction = defaultValue(optUnavailableAction, 'throw');
    if (this.getAmountOf(name) > 0) {
      return this._arrayByName[name][0];
    }

    handleUnavailable(unavailableAction, name);

    return {
      rootElement: null,
      exports: new ExportsManagerResult({}),
    };
  };

  /** @param {boolean|"throw"|"warn"|Function} [optUnavailableAction] = "warn" */
  ExportsManagerResult.prototype.forFirst = function (
    name,
    action,
    optUnavailableAction,
  ) {
    const unavailableAction = defaultValue(optUnavailableAction, 'warn');
    if (this.getAmountOf(name) > 0) {
      const element = this.getFirst(name);
      action(element.rootElement, element.exports);
    } else {
      handleUnavailable(unavailableAction, name);
    }
  };

  /** @param {boolean|"throw"|"warn"|Function} [optUnavailableAction] = "warn" */
  ExportsManagerResult.prototype.forEvery = function (
    name,
    action,
    optUnavailableAction,
  ) {
    const unavailableAction = defaultValue(optUnavailableAction, 'warn');
    if (!_forEvery(this._arrayByName, name, action)) {
      handleUnavailable(unavailableAction, name);
    }
  };
}
