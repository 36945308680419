/* eslint-disable no-undef */
import { GameScaleController } from '../../../../States/Scaling/GameScaleController';
import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';

const BG_Y = 100;

const DEFAULT_OUTER_BG_ASSET = {
  vertical: 'IAP-store-Booth-body',
  horizontal: 'IAP-store-horizontal-booth',
};

/**
 * class for TimedTargetedOffer background
 */
export class TimedTargetedOffer_Background extends Phaser.Group {
  /**
   * constructor
   * @param {Object} bgConfig
   * @param {number} bgConfig.height
   */
  constructor(bgConfig) {
    super(game);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance()._gameScale;

    const windowHeight = this._isLandscape ? 600 : 720;
    this._drawBG(windowHeight, bgConfig.outerBgAsset);
  }

  /**
   * draw basic background with no 9-scale
   * @param {number} height
   * @param {string} outerBgAsset (optional)
   */
  _drawBG(height, outerBgAsset) {
    if (!outerBgAsset) {
      outerBgAsset = DEFAULT_OUTER_BG_ASSET[OMT.systemInfo.orientationKey];
    }
    this._bg = G.makeImage(0, BG_Y, outerBgAsset);
    this._bg.height = height;
    this._bg.scale.x = this._bg.scale.y;
    this._bg.anchor.set(0.5);

    this.addChild(this._bg);
  }

  /** @returns {number} get width of outer bg */
  get outerWidth() { return this.width; }

  /** @returns {number} get height of outer bg */
  get outerHeight() { return this.height; }

  /** @returns {number} get x position of outer bg */
  get outerBgX() { return super.x + this.x; }

  /** @returns {number} get y position of outer bg */
  get outerBgY() { return super.y + this.y; }

  /** @returns {number} get width of inner bg */
  get innerWidth() { return this.width; }

  /** @returns {number} get height of inner bg */
  get innerHeight() { return this.height; }

  /** @returns {number} get x position of inner bg */
  get innerBgX() { return super.x + this.x; }

  /** @returns {number} get y position of inner bg */
  get innerBgY() { return super.y + this.y; }
}
