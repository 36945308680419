import OMT_UI_SquareButton, { BUTTONCOLOURS, BUTTONSTATE } from '../../OMT_UI/OMT_UI_SquareButton';
import { TextTimer } from '../TextTimer';
import { GiftGraphic } from '../Windows/GiftUI/GiftGraphic';

G.SPDM_CompleteAllPanel = function(x, y, missionContent) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);
  var bindings = [];

  this._missionContent = missionContent;

  this.onDestroy.add(function() {
    bindings.forEach(function(binding) {
      binding.detach();
    });
  }, this);

  G.makeImage(0, 0, "Missions_completed_box", 0.5, this);
  var completeAllTxt = new G.Text(0, -38, OMT.language.getText("Complete all Daily Missions"), {
    style: "font-white",
    fontSize: 25,
  }, 0.5, 310);
  this.add(completeAllTxt);

  var arrow = G.makeImage(80, 7, "arrow_right", 0.5, this);
  // var giftIcon = G.makeImage(130, 0, "prezent", 0.5, this);
  var giftIcon = new GiftGraphic(130, 0, {giftType: 1, useFullGift: true}, this);
  var animation = G.Animations.Generic.getSquishSquashAnimation(0.36);
  animation.animate(game, giftIcon);

  this._progressTxt = new G.Text(-70, 42, " ", {
    style: "font-white",
    fontSize: 20,
  }, 0.5, 170);
  this.add(this._progressTxt);

  var progress = G.dailyMissionsMgr.getProgress();
  this._claimBtn = new OMT_UI_SquareButton(90, 70, {
    button: {
      tint: BUTTONCOLOURS.orange,
      dimensions: {
        width: 138,
        height: 70,
      },
      isEnabled: false,
      extraDetail: false,
    },
    text: {
      string: OMT.language.getText('Claim'),
      textStyle: {
        style: 'font-white',
        fontSize: 25,
      },
    },
    options: {
      clickFunction: {
        onClick: this._onClaimBtnPressed.bind(this),
        disableAfterClick: true,
      },
    },
  });
  this.add(this._claimBtn);

  this._checkMark = G.makeImage(170, 30, "task_complete", 0.5, this);
  this._checkMark.scale.setTo(0);

  this._cupcakes = [];

  this._initCupcakes(-145, 3, G.dailyMissionsMgr.getProgress());
  this._checkWin();

  if (progress.mainRewardClaimed) {
    this._claimBtn.visible = false;
    this._checkMark.scale.setTo(1);
    this.y = this.y+50;
    this._addTimer();
  }
};

G.SPDM_CompleteAllPanel.prototype = Object.create(Phaser.Group.prototype);

G.SPDM_CompleteAllPanel.prototype._addTimer = function() {
  var timerGroup = game.add.group();
  timerGroup.y = -120;
  this.add(timerGroup);
  timerGroup.newMissionsTxt = new G.Text(0, 0, OMT.language.getText("New Missions in:"), {
    style: "font-grey",
    fontSize: 27,
  }, 0.5, 300);
  timerGroup.add(timerGroup.newMissionsTxt);
  timerGroup.timer = new TextTimer({
    x: 0,
    y: 30,
    date: 0,
    style: {
      style: "font-grey",
      fontSize: 27,
    },
    anchor: 0.5,
    maxWidth: 300,
  });
  timerGroup.timer.start(G.dailyMissionsMgr.getSecToNextDaily());
  timerGroup.add(timerGroup.timer);

  game.add.tween(timerGroup)
    .from({alpha: 0}, 300, Phaser.Easing.Sinusoidal.Out, true, 200);
};

G.SPDM_CompleteAllPanel.prototype._initCupcakes = function(x, y, progress) {
  var maxWidth = 225;

  var allWidth = progress.goal * 34;
  var spaceDiff = (maxWidth-allWidth)/Math.max(1, progress.goal-1);
  var offsetX = Math.min(70, 34+spaceDiff);

  for (var i = 0; i < progress.goal; i++) {
    this._cupcakes[i] = new G.SPDM_CupcakeIcon(x+(i*offsetX), y, progress.finished > i);
  }

  this._progressFinished = progress.finished;
  this._progressGoal = progress.goal;

  this.addMultiple(this._cupcakes);

  var txt = OMT.language.getText("%CURRENT%/%TARGET% Completed")
    .replace("%CURRENT%", this._progressFinished)
    .replace("%TARGET%", this._progressGoal);

  this._progressTxt.setText(txt);
};

G.SPDM_CompleteAllPanel.prototype.getEmptyCupcake = function() {
  return this._cupcakes.find(function(cupcake) {
    return cupcake.isEmpty() && !cupcake.aimed;
  });
};

G.SPDM_CompleteAllPanel.prototype.fillCupcake = function(cupcake) {
  cupcake.fill();
  this._progressFinished++;
  var txt = OMT.language.getText("%CURRENT%/%TARGET% Completed")
    .replace("%CURRENT%", this._progressFinished)
    .replace("%TARGET%", this._progressGoal);
  this._progressTxt.setText(txt);
  game.tweens.removeFrom(this._progressTxt.scale);
  this._progressTxt.scale.setTo(1);
  game.add.tween(this._progressTxt.scale)
    .to({x: 1.2, y: 1.2}, 300, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);

  this._checkWin();
};

G.SPDM_CompleteAllPanel.prototype._checkWin = function(skipAnimations) {
  var progress = G.dailyMissionsMgr.getProgress();
  if (progress.finished === progress.goal) {
    this._claimBtn.currentState = BUTTONSTATE.ENABLED;
    this._claimBtn.scale.setTo(0.96, 1.04)  //(138*0.96/196, 70*1.04/100)
    game.add.tween(this._claimBtn.scale)
      .to({x: 1.04, y: 0.96}, 800, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  }
};

G.SPDM_CompleteAllPanel.prototype._onClaimBtnPressed = function() {
  const progress = G.dailyMissionsMgr.getProgress();
  if (!(progress.finished === progress.goal && !progress.mainRewardClaimed)) { return; }
  G.dailyMissionsMgr.claimMainReward();
  G.sb("pushWindow").dispatch("dailyMissionMainReward");
  this._missionContent.widgetCheck();
  G.sb("toggleWorldSidePanel").dispatch();

  game.sfx.line.play();
  game.sfx.match_5.play();

  game.add.tween(this._claimBtn)
    .to({alpha: 0}, 300, Phaser.Easing.Sinusoidal.Out, true);
  game.add.tween(this._checkMark.scale)
    .to({x: 1, y: 1}, 300, Phaser.Easing.Elastic.Out, true);
  game.add.tween(this)
    .to({y: this.y + 50}, 300, Phaser.Easing.Sinusoidal.Out, true);
  this._addTimer();
};