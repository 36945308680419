/* eslint-disable func-names */
import AutoBoosterBuyTimeBtn from '../IAP/G.AutoBoosterBuyTimeBtn';
import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { GiftGraphic } from './GiftUI/GiftGraphic';
import { TextTimer } from '../TextTimer';

export class Window_MysteryGiftStreakIncrease extends Window {
  /**
   * constructor
   * @param {Object} parent
   */
  constructor(parent) {
    super(parent);

    this._gifts = G.saveState.mysteryGiftManager.getCurrentGifts();
    this._cooldown = G.saveState.mysteryGiftManager.getRemainingActiveTime();

    this._initLayout();

    this._addTimer();
    this._startTimer();
  }

  /**
   * init window layout
   */
  _initLayout() {
    if (this._isLandscape) {
      this.y = 50;
    }

    // bg
    this.addBackground('popup_background_2');
    // title
    this._titleTxt = new G.Text(0, -272 + (G.OMTsettings.elements.Window_mysteryGift.titleOffsetY || 0), OMT.language.getText('A Mystery Gift'), 'mysteryGift-Title', 0.5, 440, 100, true, 'center');
    this.add(this._titleTxt);

    if (this._cooldown > 0) this._initGiftsActiveLayout();
    else this._initTimesUpLayout();
  }

  /**
   * gifts active content
   */
  _initGiftsActiveLayout() {
    this._desc = new G.Text(
      0, -125,
      OMT.language.getText('You won the level with one try! Here is your Mystery Gift!'),
      'mysteryGift-top', 0.5, 460, 120, true, 'center',
    );
    this.add(this._desc);

    this._desc2 = new G.Text(0, 208,
      OMT.language.getText('For the next %TIME% minutes the levels will start with this booster if you keep winning!').replace('%TIME%', G.json.settings.mysteryGift.giftCooldownMin),
      {
        style: 'mysteryGift-bottom',
        fontSize: '24px',
      }, 0.5, 460, 120, true, 'center');
    this.add(this._desc2);

    const onClick = () => {
      this.closeWindow();
    };
    this._coolBtn = new OMT_UI_SquareButton(0, 312, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 186,
          height: 96,
        },
      },
      text: {
        string: OMT.language.getText("Let's Go!"),
        textStyle: { style: 'font-white', fontSize: 50 },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });
    this.registerButtons(this._coolBtn);

    if (this._gifts.length === 1) {
      this._addGiftBox(0, -14, this._gifts);
    } else if (this._gifts.length > 1) {
      this._addSlots(0, -14, this._gifts);
    }
  }

  /**
   * times up layout
   */
  _initTimesUpLayout() {
    this.addCloseButton(null, null, () => {
      G.saveState.mysteryGiftManager.resetProgress(false, false);
      G.sb('mysteryGiftWindowDispatched').dispatch(false);
    }, G.saveState);

    this._desc = new G.Text(0, -125, OMT.language.getText('Time is up!'), 'mysteryGift-topBig', 0.5, 460, 120, true, 'center');
    this.add(this._desc);

    this._desc2 = new G.Text(0, 208,
      OMT.language.getText('Your Mystery Gift expired but you can reactivate them for another %TIME% minutes!')
        .replace('%TIME%', G.json.settings.mysteryGift.giftCooldownMin),
      {
        style: 'mysteryGift-bottom',
        fontSize: '24px',
      }, 0.5, 460, 120, true, 'center');
    this.add(this._desc2);

    const priceForGoods = 500 + (500 * Math.max(0, Math.min(G.saveState.mysteryGiftManager.streak, 3)));
    this._buyBtn = new AutoBoosterBuyTimeBtn(0, 312, priceForGoods, OMT.language.getText('Buy +%TIME% minutes').replace('%TIME%', G.json.settings.mysteryGift.giftCooldownMin));
    this._buyBtn.signals.onClick.add(() => this._onBuyClicked(priceForGoods, this));
    this.add(this._buyBtn);

    this._streakIncreseFail(0, -14, this._gifts);
  }

  /**
   * on buy time clicked
   * @param {number} price
   * @param {Object} windowContext
   */
  _onBuyClicked(price, windowContext) {
    if (this._clicked) return;
    this._clicked = true;

    if (G.saveState.getCoins() >= price) {
      OMT.transactionTracking.logInventoryTransactionBegin();
      OMT.transactionTracking.addInventoryChange('coins', 0, -price);
      OMT.transactionTracking.logInventoryTransactionEnd();

      G.saveState.changeCoins(-price);
      G.saveState.mysteryGiftManager.setGiftActiveTime();
      G.sb('mysteryGiftWindowDispatched').dispatch(false);
      G.sb('pushWindow').dispatch('mysteryGiftStreakIncrese');
      G.sfx.cash_register.play();

      // DDNA.transactionHelper.trackRewards([], [], {
      //   transactionType: 'COIN_PURCHASE',
      //   tActionType: 'EXTEND_MYSTERY_GIFT',
      //   tGameArea: 'MAP',
      //   tCoinsUsed: price,
      // });
      windowContext.closeWindow();
    } else if (G.IAP) {
      windowContext._goToShopCoin(true);
    }
  }

  /**
   * add a gift box to the display
   * @param {number} x
   * @param {number} y
   * @param {number} gifts
   * @returns {Phaser.Group}
   */
  _addGiftBox(x, y, gifts) {
    const glow = G.makeImage(x, y, 'popup_lighht', 0.5, this);
    glow.update = function () { this.angle += G.deltaTime; };
    glow.tint = 0xFDF19A;
    glow.alpha = 0;

    const group = this.add(game.make.group());
    group.position.setTo(x, y);

    const icon = {
      spiral: 'ui_booster_8',
      vertical: 'ui_booster_4',
      horizontal: 'ui_booster_3',
      cross: 'ui_booster_9',
    }[gifts[0]];

    group._giftIcon = G.makeImage(0, 0, icon, 0.5, group);
    group._giftIcon.scale.setTo(0);
    group.box = new GiftGraphic(0, 0, { giftType: 1, useFullGift: true }, group);
    group.box.scale.setTo(0.8, 0.8);
    // group.box = G.makeImage(0, 0, 'gift', 0.5, group);

    game.add.tween(group.box).to({
      width: group.box.width * 1.2,
      height: group.box.height * 1.2,
      alpha: 0,
    }, 300, Phaser.Easing.Sinusoidal.InOut, true, 1000);
    game.add.tween(glow).to({
      alpha: 1,
    }, 500, Phaser.Easing.Sinusoidal.Out, true, 1200);
    game.add.tween(group._giftIcon.scale).to({
      x: 1,
      y: 1,
    }, 700, Phaser.Easing.Elastic.Out, true, 1200);
    return group;
  }

  /**
   * add slots for more mystery gifts
   * @param {number} x
   * @param {number} y
   * @param {number} gifts
   */
  _addSlots(x, y, gifts) {
    const glow = G.makeImage(x, y, 'popup_lighht', 0.5, this);
    glow.update = () => { glow.angle += G.deltaTime; };
    glow.tint = 0xFDF19A;
    glow.alpha = 0;

    for (let i = 0; i < 3; i++) {
      this.add(this._addSlot(
        -140 + (i * 140), y, gifts[i], i === gifts.length - 1,
      ));
      if (i === gifts.length - 1) {
        glow.position.setTo(-140 + (i * 140), y);
      }
    }
    game.add.tween(glow).to({
      alpha: 1,
    }, 500, Phaser.Easing.Sinusoidal.Out, true, 1000);
  }

  /**
   * add a slot to the display
   * @param {number} x
   * @param {number} y
   * @param {string} giftType
   * @param {boolean} toReveal
   * @returns {Phaser.Image}
   */
  _addSlot(x, y, giftType, toReveal) {
    const icon = {
      spiral: 'ui_booster_8',
      vertical: 'ui_booster_4',
      horizontal: 'ui_booster_3',
      cross: 'ui_booster_9',
    }[giftType];
    const slot = G.makeImage(x, y, icon || 'MysteryGift_EmptyTile', 0.5);
    if (toReveal) {
      slot.hiddenPanel = G.makeImage(0, 0, 'MysteryGift_EmptyTile', 0.5, slot);
      game.add.tween(slot.hiddenPanel).to({
        width: slot.hiddenPanel.width * 1.2,
        height: slot.hiddenPanel.height * 1.2,
        alpha: 0,
      }, 400, Phaser.Easing.Sinusoidal.InOut, true, 1000);
    }
    return slot;
  }

  /**
   * failed to increase win streak
   * @param {*} x
   * @param {*} y
   * @param {*} gifts
   */
  _streakIncreseFail(x, y, gifts) {
    const iconsMap = {
      spiral: 'ui_booster_8',
      vertical: 'ui_booster_4',
      horizontal: 'ui_booster_3',
      cross: 'ui_booster_9',
    };

    for (let i = 0; i < 3; i++) {
      const icon = G.makeImage(
        -140 + (i * 140), y,
        iconsMap[gifts[i]] || 'MysteryGift_EmptyTile', 0.5, this,
      );
      if (iconsMap[gifts[i]]) {
        G.makeImage(-30, 30, 'task_fail', 0.5, icon);
      }
    }
  }

  /**
   * go to coinshop if not enough $$$
   */
  _goToShopCoin() {
    G.sb('pushWindow').dispatch(['coinShop']);
    G.sb('pushWindow').dispatch('mysteryGiftStreakIncrese');
    this.closeWindow();
  }

  /**
   * add timer text
   */
  _addTimer() {
    const style = G.saveState.mysteryGiftManager.getRemainingActiveTime() > 0 ? 'mysteryGift-timer-yellow' : 'mysteryGift-timer-red';
    this._timer = new TextTimer({
      x: 0,
      y: 128,
      style,
      anchor: 0.5,
      maxWidth: 480,
      timerFormat: 'ms',
      wrapperText: OMT.language.getText('Ends in %TIME%'),
    });
    this.add(this._timer);
  }

  /**
   * start the timer
   */
  _startTimer() {
    const secLeft = Math.floor(G.saveState.mysteryGiftManager.getRemainingActiveTime() / 1000);
    this._timer.setSecLeft(secLeft);
    this._timer.active = true;
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.mysteryGiftStreakIncrese = Window_MysteryGiftStreakIncrease;
