/* eslint-disable no-param-reassign */
import { GameScaleController } from '../../States/Scaling/GameScaleController';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { TutorialBarBackground } from './G.GingyTutorialBar';

/**
 * Previously known as the RequestHelp_Tutorial
 */
export class SagaMap_Tutorial {
  /**
   * @param {{game:Phaser.Game, scale:number, character:string}} config
   */
  constructor(config) {
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    if (!config.scale) {
      config.scale = 0.65;
    }

    this.rh_levelTutorialGingy; // eslint-disable-line no-unused-expressions
    this.rh_levelTutorialGingy = new G.GingyTutorialBar(config.character);

    if (this._isLandscape) {
      this.rh_levelTutorialGingy.scale.set(config.scale * this._gameScale);
    } else {
      this.rh_levelTutorialGingy.scale.set(config.scale);
    }

    // Giving a proper bar
    const barIndex = this.rh_levelTutorialGingy.getChildIndex(this.rh_levelTutorialGingy.bar);

    const barWidth = this._isLandscape
      ? game.width / (config.scale * this._gameScale)
      : game.width / config.scale;

    const bar = new TutorialBarBackground(barWidth);
    this.rh_levelTutorialGingy.removeChild(this.rh_levelTutorialGingy.bar);
    this.rh_levelTutorialGingy.bar.destroy();
    this.rh_levelTutorialGingy.bar = bar;
    this.rh_levelTutorialGingy.addChildAt(bar, barIndex);

    this.renderedBounds = this.rh_levelTutorialGingy.getBounds().clone();

    this.rh_levelTutorialHand = new Phaser.Group(config.game, game.world);
    this.tutorialHandTarget; // eslint-disable-line no-unused-expressions
    const tutorialHandImg = G.makeImage(0, 20, 'tut_hand', 0, this.rh_levelTutorialHand); // Taken from G.DailyIcon
    game.add.tween(tutorialHandImg).to({ x: 20, y: 50 }, 300, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

    this.rh_levelTutorialGingy.hide(null, null, true);
    this.rh_levelTutorialHand.visible = false;
  }

  /**
   * target = {
   *  display: Phaser.Group,
   *  target: Phaser.Group,
   *  offset: Phaser.Point,
   * }
   * @param {Object} target
   * @param {boolean} autoVisOn
   */
  setTarget(target, autoVisOn = false) {
    this.tutorialHandTarget = target;

    if (this.tutorialHandTarget) {
      // The pointing motion
      this.rh_levelTutorialHand.update = () => {
        if (this.tutorialHandTarget) { // incase it destroys itself or something
          const goodPos = game.world.toLocal(this.tutorialHandTarget.target, this.tutorialHandTarget.display);
          goodPos.x += this.tutorialHandTarget.offset.x;
          goodPos.y += this.tutorialHandTarget.offset.y;
          const inPosition = this.rh_levelTutorialHand.position.equals(goodPos);

          if (!inPosition) {
            this.rh_levelTutorialHand.position.set(goodPos.x, goodPos.y);
          } else if (inPosition && autoVisOn && !this.rh_levelTutorialHand.visible) {
            this.rh_levelTutorialHand.visible = true;
          }
          if (autoVisOn) {
            this.rh_levelTutorialHand.visible = true;
          }
        } else {
          this.rh_levelTutorialHand.visible = false;
          this.rh_levelTutorialHand.update = () => {};
        }
      };
    } else {
      this.rh_levelTutorialHand.visible = false;
      this.rh_levelTutorialHand.update = () => {};
    }
  }

  /**
   * Displays text
   * @param {{position:string, text:string, localize?:boolean}} text
   */
  displayMsg(text) {
    if (!text || text === '') {
      this.rh_levelTutorialGingy.msg.emptyText();
    }
    this.rh_levelTutorialGingy.displayMsg(text);
  }

  /**
   * Display text, but async
   * @param {{position:string, text:string, localize?:boolean}} text
   * @returns {Promise}
   */
  async displayMsgAsync(text) {
    return new Promise((resolve) => {
      this.displayMsg(text);
      if (text === '') { resolve(); }
      this.rh_levelTutorialGingy.msg.onTextAnimationFinished.addOnce(() => {
        resolve();
      });
    });
  }

  destroy() {
    this.rh_levelTutorialGingy.destroy();
    this.rh_levelTutorialHand.update = () => {};
    this.rh_levelTutorialHand.destroy();
  }

  close() {
    this.rh_levelTutorialGingy.msg.onTextAnimationFinished.dispatch();
    this.rh_levelTutorialHand.visible = false;
    return new Promise((resolve) => {
      this.rh_levelTutorialGingy.hide(() => {
        this.destroy();
        resolve();
      });
    });
  }

  toggleVisibility(vis) {
    if (vis) {
      this.rh_levelTutorialGingy.show();
    } else {
      this.rh_levelTutorialGingy.hide();
    }
    this.rh_levelTutorialHand.visible = vis;
  }

  getLevelWindowSpecificOffset() {
    return (this.rh_levelTutorialHand.height - this.renderedBounds.height) / 2;
  }

  /**
   * Hard sets the visibility of gingy.
   * Does not set the hand though
   */
  set visible(b) {
    this.rh_levelTutorialGingy.visible = b;
    this.rh_levelTutorialGingy.bar.visible = b;
  }
}
