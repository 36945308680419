import TutorialGrid from '../BoardAnim/G.TutorialGrid';
import TutorialGroup from '../BoardAnim/TutorialGroup';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';
/**
 * Waffle Jelly tutorial explained (???) by Sandra!
 * @author ???
 */
class RockBreakTutorial extends TutorialGroup {
  /**
   * Remember that this module/tutorial thing is just a Phaser Group
   * with things inside. It can display anything really
   */
  constructor() {
    super(game, null);

    this.tileSize = 75; // The size of the tiles on the mini-fake board

    this.grids = new Phaser.Group(game, this);
    // a mini grid to help with positioning
    this.gridBg = new TutorialGrid();

    this.gems = new TutorialGrid();

    this.concrete = new TutorialGrid();

    this.gemsToDrop = G.makeImage(0, 0, 'candy_3', null);
    this.gemsToDrop.alpha = 0;

    // for when we need to emit particles
    this.rockEmitter = new FxParticle();
    this.gemEmitter = new FxParticle();

    this.tutHand = G.makeImage(0, 0, 'tut_hand', 0);

    // The description at the top
    this.description = new G.Text(0, -140, ' ', {
      style: 'font-blue',
      fontSize: 35,
      lineSpacing: -15,
    }, 0.5, 400, 200, true, 'center');

    // we configure our own continue button because we need to do stuff when its clicked.
    this.continueButton = new OMT_UI_SquareButton(0, 270, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onContinueClick.bind(this),
        },
      },
    });
    this.addChild(this.continueButton);

    // #region setup
    this.grids.add(this.gridBg);
    this.grids.add(this.gems);
    this.grids.add(this.concrete);
    this.grids.add(this.rockEmitter);
    this.grids.add(this.gemEmitter);
    this.grids.add(this.tutHand);
    this.grids.add(this.gemsToDrop);
    this.add(this.description);
    this.add(this.continueButton);
    this.add(this.grids);
    this.currentPage = 1;


    this.grids.y = 80;
    // #endregion


    this.displayPageOne();
  }

  async displayPageOne() {
    this.description.setText(OMT.language.getText('%Gems% trapped under %rocks% cannot be moved.'));

    this.tutHand.alpha = 0;
    // #region grids setup
    this.gridBg.init(3, 2, this.tileSize * 1.2, 'tut_tile', 1.2);

    const gemsData = [2, 1, 4, 3, 3, 5]
      .map((num) => `candy_${num}`);
    this.gems.init(3, 2, this.tileSize * 1.2, gemsData, 1.2);

    const concreteData = [1, 2, 2, 1, 1, 1]
      .map((num) => `concrete_${num}`);
    this.concrete.init(3, 2, this.tileSize * 1.2, concreteData, 1.2);
    // #endregion

    this.wrapTween(this.grids, { alpha: 1 }, 500, Phaser.Easing.Sinusoidal.Out);
  }

  async displayPageTwo() {
    this.description.setText(OMT.language.getText('Match a %gem% under a %rock% to crush the %gem% and the %rock%!'));

    // #region initializing grids

    this.gridBg.init(3, 3, this.tileSize, 'tut_tile', 1);

    const gemsData = [4, 1, 2, 1, 1, 3, 5, 4, 1]
      .map((num) => `candy_${num}`);
    this.gems.init(3, 3, this.tileSize, gemsData, 1);

    const concreteData = [2, 1, 0, 1, 1, 0, 1, 0, 0]
      .map((num) => (num === 0 ? null : `concrete_${num}`));
    this.concrete.init(3, 3, this.tileSize, concreteData, 1);

    // #endregion

    // we want the hand to tween in, not be visible right away
    this.tutHand.alpha = 0;
    this.tutHand.scale.set(1);
    this.tutHand.pivot.set(0.5);

    this.gemsToDrop.alpha = 0;
    this.gemsToDrop.pivot.copyFrom(this.gems.pivot);
    this.gemsToDrop.position.copyFrom(this.gems.getPxPos(2, -2));

    this.gemsToDrop.x -= this.tileSize / 2;
    this.gemsToDrop.y += this.tileSize / 2;

    // tweening in the whole container
    await this.wrapTween(this.grids, { alpha: 1 }, 300, Phaser.Easing.Sinusoidal.In);

    const gem1 = this.gems.getSpriteByCell(2, 2);
    const gem2 = this.gems.getSpriteByCell(2, 1);

    const gem1Pos = this.gems.getPxPos(2, 2);
    const gem2Pos = this.gems.getPxPos(2, 1);

    // here, we're swapping the sprites internally so when we later try to reference them, they're in the right spots in the data.
    this.gems.swapSprites(2, 2, 2, 1);

    this.tutHand.x = gem1Pos.x - this.gems.pivot.x;
    this.tutHand.y = gem1Pos.y - this.gems.pivot.y;

    await this.wait(100);

    this.wrapTween(this.tutHand, { alpha: 1 }, 100);

    // moving the hand to the swap position
    this.wrapTween(this.tutHand, { x: gem2Pos.x - this.gems.pivot.x, y: gem2Pos.y - this.gems.pivot.y }, 600, Phaser.Easing.Sinusoidal.InOut);
    this.wrapTween(this.tutHand.scale, { x: 0.8, y: 0.8 }, 200, Phaser.Easing.Sinusoidal.InOut);

    // swapping the two gems
    this.wrapTween(gem1, gem2Pos, 600, Phaser.Easing.Sinusoidal.InOut);
    this.wrapTween(gem2, gem1Pos, 600, Phaser.Easing.Sinusoidal.InOut);

    // scaling the gem to accentuate it as it moves
    await this.wrapTween(gem1.scale, { x: 1.4, y: 1.4 }, 300);
    await this.wrapTween(gem1.scale, { x: 1, y: 1 }, 300);

    // we aren't moving the pieces any more, so lets remove the hand.
    this.wrapTween(this.tutHand, { alpha: 0 }, 200);

    // this is also to make sure the emitter emits in the correct position
    this.rockEmitter.pivot.copyFrom(this.gems.pivot);
    this.gemEmitter.pivot.copyFrom(this.gems.pivot);

    // because we want the animation to go from right to left, we iterate backwards.
    // eslint apparently doesnt like this, so we turn it off
    // eslint-disable-next-line for-direction
    for (let i = 2; i >= 0; i--) {
      const pos = this.gems.getPxPos(i, 1);

      if (i < 2) this.rockEmitter.burstConcreteAnim(pos.x - 3, pos.y - 3, 3);
      const concrete = this.concrete.getSpriteByCell(i, 1);

      if (concrete != null) concrete.alpha = 0;

      // removing the sprite at this position because its been matched.
      if (i === 2) {
        const spr = this.gems.getSpriteByCell(i, 1);
        if (spr) spr.alpha = 0;
      }
      this.gemEmitter.burstCandy(pos.x + this.tileSize / 2, pos.y + this.tileSize / 2, 3);


      // we need there to be a small delay between explosions, so the animation actually moves
      // and doesnt just happen all at once.
      // eslint-disable-next-line no-await-in-loop
      await this.wait(100);
    }


    const gem = this.gems.getSpriteByCell(2, 0);
    if (gem) {
      this.wrapTween(gem, { y: gem.y + this.tileSize }, 300, Phaser.Easing.Back.Out);
    }


    this.wrapTween(this.gemsToDrop, { alpha: 1 }, 300);
    await this.wrapTween(this.gemsToDrop, { y: this.gemsToDrop.y + this.tileSize }, 300, Phaser.Easing.Back.Out);

    // wait a sec so the player can see the result.
    await this.wait(1000);

    // tween out the whole container
    await this.wrapTween(this.grids, { alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out);

    this.stopAllActions();
    this.displayPageTwo();
  }

  async onContinueClick() {
    this.stopAllActions(); // Stop all tweens
    if (this.currentPage === 1) { // If page one, go to page 2
      // prevent spam clicking
      this.continueButton.active = false;

      // tween out the first page container
      await this.wrapTween(this.grids, { alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out); // tweens away after waiting 2000ms

      this.continueButton.active = true;

      this.displayPageTwo();

      this.currentPage++;
    } else { // Otherwise close
      this.signals.onContinueClick.dispatch();
      this.destroy();
    }
  }
}

// create global references
G.RockBreakTutorial = RockBreakTutorial;
