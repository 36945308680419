import OMT_UI_SquareButton, { BUTTONCOLOURS, BUTTONSTATE } from '../../../OMT_UI/OMT_UI_SquareButton';
import { Window } from '../../../00_IMMEDIATE/Window';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import { EventLevelProgression } from './EventLevelProgression';
import { INTERSTITIAL_RULES } from '../../../Services/OMT/ads/OMT_InterstitialAdRules';

// Window for winning a special event level
export class Window_EventWin extends Window {
  /**
   * @param {Object} parent
   * @param {Object} config
   */
  constructor(parent, config) {
    super(parent);
    this.state = game.state.getCurrentState();
    this._specialEventMgr = G.saveState.tokenEventManager;

    // imports
    const { lvlIndex } = config;
    const { points } = config;

    // config passed when level started
    this.passedConfig = config.passedConfig;

    // if we forced a life loss at the beginning of the level, we add that back here.
    if (this.state.removedLifeAtStart) {
      G.saveState.addLife(1, false, true);
    }

    // if this level doesnt have result data set, set it here.
    if (!G.lvl.resultData) {
      G.lvl.resultData = G.saveState.passLevel({
        levelIndex: lvlIndex,
        newStars: Math.max(1, G.lvl.stars),
        newPoints: points,
        skipReward: true,
        dontSave: true,
        gameMode: LevelType.COLLECT_EVENT,
      });
    }

    // send automated message on close if in friend context
    OMT.social.sendClosedHelpRequest(() => {});

    // save the result
    this.result = G.lvl.resultData;

    // Handle token collection
    // Save previous values for animation purposes later
    this._prevTier = this._specialEventMgr.currentRewardTier;
    this._prevLevelTokens = this._specialEventMgr.levelTokensCollected;
    this._prevTierTokens = this._specialEventMgr.tierTokensForPostcard;
    this._prevTokensNeeded = this._specialEventMgr.tierTokensRequiredForNextPostcard;

    // Save collected tokens now
    this._specialEventMgr.cashInLevelTokens();
    // this._saveTokenCount();

    this._initLayout();
    this._initButtons();

    G.saveState.save();
  }

  _initLayout() {
    this.addBackground('tokenEvent_popup_background');

    this.banner = G.makeImage(
      0, -295,
      'tokenEvent_banner',
      0.5, this,
    );

    // Title
    this.titleTxt = new G.Text(
      G.OMTsettings.tokenEvent.windowHeader.text.x,
      G.OMTsettings.tokenEvent.windowHeader.text.y,
      OMT.language.getText('tokenEvent Level'), 'tokenEvent-eventLevelTitle', 0.5, G.OMTsettings.tokenEvent.windowHeader.text.width, 125, true, 'center',
    );
    this.add(this.titleTxt);

    // You win
    this.youWinTxt = new G.Text(0, -100, OMT.language.getText('You win!'), {
      style: 'font-blue',
      fontSize: '45px',
      fill: G.OMTsettings.tokenEvent.styling.windowTextFill,
    }, 0.5, 530);
    this.add(this.youWinTxt);

    // Progress Bar
    this.progress = new EventLevelProgression(0, 100, this._prevTierTokens, this._prevTokensNeeded);
    this.add(this.progress);

    // Tokens Collected
    this.tokensCollectedGroup = new Phaser.Group(game, null);
    this.tokensCollectedGroup.y = 0;
    this.tokenIcon = G.makeImage(50, 0, G.OMTsettings.tokenEvent.boardTokenAsset, 0.5, this.tokensCollectedGroup);
    this.tokenIcon.scale.set(89 / this.tokenIcon.width);
    this.tokenAmount = new G.Text(-50, 0, `+${this._prevLevelTokens}`, {
      style: 'font-white',
      fontSize: '48px',
      fill: G.OMTsettings.tokenEvent.styling.windowTextFill,
    }, 0.5);

    this.tokensCollectedGroup.add(this.tokenIcon);
    this.tokensCollectedGroup.add(this.tokenAmount);
    this.add(this.tokensCollectedGroup);

    const buttonAnims = () => {
      this.continueBtn.scale.setTo(0);
      this.continueBtn.visible = true;
      this.continueBtn.currentState = BUTTONSTATE.ENABLED;
      game.add.tween(this.continueBtn.scale).to({ x: 1, y: 1 }, 500, Phaser.Easing.Elastic.Out, true);
      this.closeButton.visible = true;
      this.closeButton.currentState = BUTTONSTATE.ENABLED;
    };

    // Animate tokens collected
    game.add.tween(this.tokensCollectedGroup)
      .to({ y: 100, alpha: 0 }, 500, Phaser.Easing.Quadratic.In, true, 500)
      .onComplete.add(() => {
        // Update event progress in UI
        if (this._prevLevelTokens > 0) {
          this.progress.updateProgress(false, this._prevTierTokens + this._prevLevelTokens, buttonAnims);
        } else {
          buttonAnims();
        }
      });
    game.add.tween(this.tokensCollectedGroup.scale)
      .to({ x: 0, y: 0 }, 500, Phaser.Easing.Quadratic.In, true, 500);
  }

  _initButtons() {
    // close button
    this.closeButton = new G.Button(250, -270, 'btn_x', this.handleClose, this);
    this.registerButtons(this.closeButton);
    this.closeButton.currentState = BUTTONSTATE.DISABLED;
    this.closeButton.visible = false;

    // Continue
    const onContClick = () => {
      const nextLevel = G.saveState.getEventLevel(true);
      this.closeWindow();
      this.state.endPointCheckForAds(() => {
        if (this._specialEventMgr.currentRewardTier > this._prevTier) {
          G.sb('pushWindow').dispatch(['eventPostcard', {
            windowName: 'eventLevel',
            args: [nextLevel],
            layerName: G.WindowMgr.LayerNames.Base,
          },
          {
            startIndex: this._specialEventMgr.getPostcardLastOpenedIndex(),
          }]);
        } else {
          G.sb('pushWindow').dispatch(['eventLevel', nextLevel], false, G.WindowMgr.LayerNames.Base);
        }
      }, INTERSTITIAL_RULES.EVERY_N);
    };

    this.continueBtn = new OMT_UI_SquareButton(0, 240, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: {
          style: 'font-white',
          fontSize: 70,
        },
        dimensionMods: {
          width: 0.9,
          height: 0.9,
        },
      },
      options: {
        clickFunction: {
          onClick: onContClick.bind(this),
          disableAfterClick: true,
        },
      },
    });

    this.registerButtons(this.continueBtn);
    this.continueBtn.currentState = BUTTONSTATE.DISABLED;
    this.continueBtn.visible = false;
  }

  _saveTokenCount() {
    const { name, instanceId } = G.OMTsettings.tokenEvent.leaderboardConfig.leaderboard;
    OMT.leaderboards.postScoreToLeaderboard(name, instanceId, this._specialEventMgr.totalTokensCollected);
  }

  /**
   * Close the window and return to the map scene with any rewards.
   */
  handleClose() {
    G.saveState.getEventLevel(true);
    const checkPostcardGoals = () => {
      if (this._specialEventMgr.currentRewardTier > this._prevTier) {
        G.sb('pushWindow').dispatch(['eventPostcard', {
          windowName: 'eventLevel',
          args: [G.saveState.getEventLevel(true)],
          layerName: G.WindowMgr.LayerNames.Base,
        },
        {
          startIndex: this._specialEventMgr.getPostcardLastOpenedIndex(),
        }]);
      }
    };

    G.sb('onAllWindowsClosed').addOnce(() => {
      this.state.endPointCheckForAds(() => {
        G.sb('onStateChange').dispatch('World', undefined, checkPostcardGoals);
      }, INTERSTITIAL_RULES.EVERY_N);
    });
    this.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.eventWin = Window_EventWin;
