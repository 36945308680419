/* eslint-disable no-param-reassign */

import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

/**
 * Home Button
 */
export default class OMT_UI_HomeButton extends UI_GButtonES6 {
  /**
   * constructor
   * @param {OMT_UI_SettingsMenu} settingsMenu
   */
  constructor(settingsMenu) {
    super(0, 0, 'small_button_red', () => { this._homeButtonClick(settingsMenu); });
    this._icon = G.makeImage(0, 0, 'home_icon', [0.5, 0.5]);
    this.addChild(this._icon);
    this._onClicked = null; // callback to execute on click
  }

  /**
   * on home button clicked
   * @param {OMT_UI_SettingsMenu} settingsMenu
   */
  _homeButtonClick(settingsMenu) {
    settingsMenu.expandState = false;
    G.sb('pushWindow').dispatch(['giveUp', () => {
      if (this._onClicked) this._onClicked();
      else this._navigateHome();
    }]);
  }

  /**
   * set a alternate click function
   * @param {Function} onClicked
   */
  setAlternateClickFunction(onClicked) {
    this._onClicked = onClicked;
  }

  /**
   * navigate to map from game
   * @param {OMT_UI_SettingsMenu} settingsMenu
   */
  _navigateHome() {
    G.sb('onStateChange').dispatch('World', undefined, () => {
      // Schedule a bot message to remind player when they get full lives if they don't have
      // unlimited lives or full lives
      if (G.saveState.getUnlimitedLivesSec() <= 0 && G.saveState.getLives() < G.json.settings.livesMax) {
        // Calculate time to 5 lives in hours
        const timeToFullLives = G.saveState.getSecUntilFullLives();

        OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, 'FullLives', timeToFullLives, false);
      }

      if (G.saveState.getLives() === 0 && G.saveState.getUnlimitedLivesSec() === 0) {
        G.sb('pushWindow').dispatch(
          ['notEnoughLives'],
          false,
          G.WindowMgr.LayerNames.AboveHighScorePanel,
        );
      }
    });
  }
}
