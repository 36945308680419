/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import OMT_TweenUtils from '../Utils/Animation/OMT_TweenUtils';
import OMT_StackManager from '../Utils/OMT_StackManager';

export default class OMT_UI_TutorialHand extends Phaser.Group {
  constructor() {
    super(game);

    const hand = G.makeImage(-10, 0, 'tut_hand', 0, this);
    hand.scale.setTo(1.5);
    this.hand = hand;

    this.alpha = 0;
  }

  /**
   * Animate in
   */
  async show() {
    if (this.alpha === 1) return Promise.resolve();

    const stack = OMT_StackManager.getFreeStack();

    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: this,
        alpha: 1,
        duration: 300,
      }),
    );

    return stack.run();
  }

  /**
   * Animate out
   */
  async hide() {
    if (this.alpha === 0) return Promise.resolve();

    const stack = OMT_StackManager.getFreeStack();

    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: this,
        alpha: 0,
        duration: 300,
      }),
    );

    return stack.run();
  }

  /**
   * Makes the hand move between the objects given in the first parameter
   * The callbacks is an array of functions that are called at the same index of the objects
   * Meaning, callbacks[0] will be called when the pointer shows the first object, and callbacks[1] on the second object and so on..
   * @param {any[]} objects
   * @param {Function[]} callbacks
   */
  async moveBetween(objects, callbacks = []) {
    const { hand } = this;
    const stack = OMT_StackManager.getFreeStack();

    stack.addEvent(() => {
      const firstObject = objects[0];
      const { x, y } = this.toLocal(firstObject.worldPosition);
      hand.position.setTo(x, y);
      if (callbacks[0]) {
        callbacks[0]();
      }
    });
    stack.wait(1000);

    const otherObjects = objects.slice(1);
    for (let i = 0; i < otherObjects.length; i++) {
      const object = otherObjects[i];
      stack.addPromise(() => {
        const { x: _x, y: _y } = this.toLocal(object.worldPosition);
        return OMT_TweenUtils.translateTo({
          object: hand,
          duration: 500,
          x: _x,
          y: _y,
          ease: Phaser.Easing.Sinusoidal.InOut,
        });
      });
      stack.addEvent(() => {
        if (callbacks[i + 1]) {
          callbacks[i + 1]();
        }
      });
      stack.addEvent(() => {
        this.targetObject = object;
      });

      stack.wait(1000);
    }

    return stack.run();
  }

  /**
   * Add a pulsing tween to the hand for more static experiences
   */
  _addTween() {
    const { hand } = this;

    this.tween = game.add
      .tween(hand.position)
      .to(
        { x: hand.width * 0.5, y: hand.height * 0.5 },
        500,
        Phaser.Easing.Sinusoidal.InOut,
        true,
        0,
        -1,
        true,
      );
  }

  /**
   * Toggle the pulsing tween on/off
   * @param {boolean} state
   */
  toggleTween(state) {
    const { tween, hand } = this;

    if (state && !tween) {
      this._addTween();
    } else if (tween) {
      tween.stop();
      hand.position.setTo(0);
      this.tween = null;
    }
  }
}
