import ShareMsgBase from './A_G.ShareMsgBase';

class MsgFriendshipChestJoined extends ShareMsgBase {
  /**
   * The message that is sent to the inviter when the invitee joins the game
   * @param {string} user1AvatarUrl
   * @param {string} user2AvatarUrl
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1AvatarUrl, user2AvatarUrl, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    this.avatar1 = this._addExtImage(53, 109, user1AvatarUrl, 0, 132, null);
    this.avatar2 = this._addExtImage(497, 109, user2AvatarUrl, 0, 132, null);
    this.bg = this._addExtImage(0, 0, 'img/Msg_FriendChest_Joined.png', 0, null, null);
  }
}

G.MsgFriendshipChestJoined = MsgFriendshipChestJoined;
