import { TournamentCompetitorTopScoreViewBase } from '../../base/Tournament/TournamentCompetitorTopScoreViewBase';
import { TournamentCompetitorAvatar } from './TournamentCompetitorAvatar';

const BG_IMAGE = 'vs_competitor_bar';
const BG_ANCHOR = [0.8, 0.5];

const PRIMARY_AVATAR_POS = { x: 96, y: 0 };

const TROPHY_IMAGE = 'competitor_trophy';
const TROPHY_ANCHOR = [0.5, 0.5];
const TROPHY_IMAGE_OFFSET = { x: -20, y: 15 };

const RANK_IMAGE = 'competitor_1st_ribbon';
const RANK_IMAGE_POS = { x: 38, y: 0 };
const RANK_IMAGE_ANCHOR = [0.5, 0.5];

const COMPETITOR_AVATARS_LIMIT = 5;
const COMPETITOR_AVATARS_CENTER_POS = { x: 300, y: 0 };
const COMPETITOR_AVATARS_SPACING = 20;

// animateIn() movement settings
const ANIMATE_IN_DURATION = 500;
const ANIMATE_IN_DELAY = 800;
const ANIMATE_IN_FROM_X = 200;

const PLAYER_COUNTER_X = 330;
const PLAYER_COUNTER_ANCHOR = [0.5, 0.5];
const PLAYER_COUNTER_FONT_STYLE = { style: 'font-white', fontSize: '24px', align: 'left' };
const PLAYER_COUNTER_TEXT_POS = { x: 0, y: 4 };
const PLAYER_COUNTER_TEXT_MARGIN = 0;

/**
 * view for when you overtake the top score
 */
export class TournamentCompetitorTopScoreView extends TournamentCompetitorTopScoreViewBase {
  /**
   * init the background image and set the offset for the background anchoring
   */
  _initBg() {
    super._initBg();
    this._bg = G.makeImage(0, 0, BG_IMAGE, BG_ANCHOR);
    this.addChild(this._bg);
  }

  /**
   * init your avatar
   */
  _initYourAvatar() {
    super._initYourAvatar({
      avatar: {
        x: this._offsetX + PRIMARY_AVATAR_POS.x,
        y: PRIMARY_AVATAR_POS.y,
      },
      trophy: {
        img: TROPHY_IMAGE,
        anchor: TROPHY_ANCHOR,
        x: TROPHY_IMAGE_OFFSET.x,
        y: TROPHY_IMAGE_OFFSET.y,
        angle: 0,
      },
      AvatarClass: TournamentCompetitorAvatar,
    });
  }

  /**
   * init your rank icon (1st place)
   */
  _initYourRankIcon() {
    super._initYourRankIcon({
      x: this._offsetX + RANK_IMAGE_POS.x,
      y: RANK_IMAGE_POS.y,
      img: RANK_IMAGE,
      anchor: RANK_IMAGE_ANCHOR,
    });
  }

  /**
   * init the comptetior avatars in order of rank
   * @param {Array} leaderboardEntrie
   */
  _initCompetitorAvatars(leaderboardEntries) {
    const competitorAvatarGroup = super._initCompetitorAvatars({
      limit: COMPETITOR_AVATARS_LIMIT,
      center: {
        x: this._offsetX + COMPETITOR_AVATARS_CENTER_POS.x - ((leaderboardEntries.length - 1) * COMPETITOR_AVATARS_SPACING),
        y: COMPETITOR_AVATARS_CENTER_POS.y,
      },
      entries: leaderboardEntries,
    });
    competitorAvatarGroup.cacheAsBitmap = true;
  }

  /**
   * init the + players display group
   * @param {number} playerCount
   */
  _initPlusPlayersIcon(playerCount) {
    super._initPlusPlayersIcon({
      playerCount,
      counter: {
        x: PLAYER_COUNTER_X,
        y: 0,
        anchor: PLAYER_COUNTER_ANCHOR,
      },
      text: {
        x: PLAYER_COUNTER_TEXT_POS.x,
        y: PLAYER_COUNTER_TEXT_POS.y,
        font: PLAYER_COUNTER_FONT_STYLE,
        margin: PLAYER_COUNTER_TEXT_MARGIN,
      },
    });
  }

  /**
   * set the in transistion
   */
  animateIn() {
    super.animateIn({
      tweenData: { x: this.x + ANIMATE_IN_FROM_X, alpha: 0 },
      duration: ANIMATE_IN_DURATION,
      delay: ANIMATE_IN_DELAY,
    });
  }
}
