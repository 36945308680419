import { ACTION_TYPES } from './Actions/Action';

const MAX_SHUFFLE_ATTEMPTS = 60;
const MIN_COLOR_MOD_ATTEMPTS = 20;

/**
 * class used for shuffling candies on the Board
 */
export class BoardShuffler {
  /**
   * constructor
   * @param {Board} board active Board reference
   */
  constructor(board) {
    this._board = board;
    this._allowShuffle = true;
  }

  /**
   * start shuffle process on the current board
   * @param {boolean} immediate (optional) instant shuffle
   */
  shuffleCandies(immediate = false) {
    const { lvlDataManager } = this._board;
    if (this._allowShuffle === false) return;
    let attempts = 0;
    const candiesToShuffle = this._getCandiesToShuffle();
    // ensures at least 1 match is possible
    this._ensureMatchIsPossible(candiesToShuffle);

    // shuffle loop
    let hasPossibleMoves; let hasMatchingCandies;
    do {
      attempts++;

      // shuffle fail deconstruct and send error
      if (attempts > MAX_SHUFFLE_ATTEMPTS) {
        this._allowShuffle = false;
        // G.Utils.SentryLog.logError('Failed to shuffle board', { tags: { level: lvlDataManager.lvlIndex + 1 } });
        game.time.events.add(1, this._board.deconstruct, this);
        game.time.events.add(1, () => {
          this._board.deconstruct();
        });
        return;
      }

      // shuffle keeps failing start inserting more colors to match
      if (attempts > MIN_COLOR_MOD_ATTEMPTS) {
        const colorModSuccess = this._attemptToModifyCandyColors(candiesToShuffle);
        if (!colorModSuccess) attempts = MAX_SHUFFLE_ATTEMPTS;
      }

      // perform candy shuffle
      this._shuffle(candiesToShuffle, immediate);

      // update status of shuffle
      hasPossibleMoves = this._board.matcher.checkPossibleMoves().length > 0;
      hasMatchingCandies = this._getMatchingCandies().length > 0;
    } while (!hasPossibleMoves || (attempts < MIN_COLOR_MOD_ATTEMPTS && hasMatchingCandies));

    const { gameHooks } = this._board;
    gameHooks.playSound('whoosh_short_1'); // shuffle fx

    if (!immediate) this._animateToShuffledPositions(candiesToShuffle);
    this._handlePossibleMatches();
  }

  /**
   * extract a list of shufflable candies from the board
   * @returns {Array.<Candy>}
   */
  _getCandiesToShuffle() {
    const candies = [];
    this._board.candiesLayer.grid.loop((elem, x, y) => {
      if (elem
        && this._board.isMoveable(x, y)
        && this._board.isCellMatchable(x, y)
        && !elem.isGoalCollectType()
        && elem.isShuffleable()) {
        candies.push(elem);
      }
    });
    return candies;
  }

  /**
   * shuffle candies in the list
   * @param {Array.<Candy>} candiesToShuffle
   * @param {boolean} immediate (optional) instant shuffle
   */
  _shuffle(candiesToShuffle, immediate) {
    const shuffledArray = Phaser.ArrayUtils.shuffle(candiesToShuffle.slice());
    candiesToShuffle.forEach((candy, i) => {
      const candyToSwapWith = shuffledArray[i];
      if (candy !== candyToSwapWith) {
        if (immediate) this._board.swapCandiesWithPosition(candy, candyToSwapWith);
        else this._board.swapCandies(candy, candyToSwapWith);
      }
    });
  }

  /**
   * get a list of matching candies
   * @returns {Array.<Candy>}
   */
  _getMatchingCandies() {
    const result = [];
    this._board.candiesLayer.grid.loop((elem, x, y) => {
      if (!elem) return;
      if (!this._board.isCellMatchable(x, y)) return;
      if (this._board.matcher.quickMatchCheck(elem)) result.push(elem);
    });
    return result;
  }

  /**
   * Makes sure a match is possible by forcing at least 3 of the most common type.
   * @param {Array.<Candy>} candies
   */
  _ensureMatchIsPossible(candies) {
    const matchMap = this._createMatchMap(candies);
    const mostCommonEntry = Object.entries(matchMap).reduce((acc, entry) => (acc[1] > entry[1] ? acc : entry));
    for (let i = mostCommonEntry[1]; i < 3; i++) {
      const candyToModify = candies.find((candy) => candy.getMatchToken() !== mostCommonEntry[0]);
      candyToModify.import(mostCommonEntry[0]);
    }
  }

  /**
   * creates an object with counts of each token type in the candies list.
   * @param {Array.<Candy>} candies
   * @returns {Object}
   */
  _createMatchMap(candies) {
    const matchMap = {};
    candies.forEach((candy) => {
      const matchToken = candy.getMatchToken();
      if (!matchMap[matchToken]) matchMap[matchToken] = 0;
      matchMap[matchToken]++;
    });
    return matchMap;
  }

  /**
   * attempts to modify candy colors if shuffle is still not successfull
   * @param {Array.<Candy>} candies
   * @returns {boolean} success
   */
  _attemptToModifyCandyColors(candies) {
    const tokenToChangeInto = '1';
    const candyToChange = candies.find((candy) => candy.getMatchToken() !== tokenToChangeInto);
    if (candyToChange) {
      candyToChange.init(candyToChange.cellX, candyToChange.cellY, tokenToChangeInto);
      return true;
    }
    return false;
  }

  /**
   * handle any possible matches on the board after the shuffle process
   */
  _handlePossibleMatches() {
    const matches = this._getMatchingCandies();
    if (matches.length > 0) {
      this._board.checkMatchList = matches;
      this._board.actionManager.newAction(ACTION_TYPES.PROCESS_MATCH);
    }
  }

  /**
   * animate candied to their new shuffled positions
   * @param {Array.<Candy>} candies
   */
  _animateToShuffledPositions(candies) {
    candies.forEach((candy) => {
      candy.shuffleMoveToOwnCell();
    });
  }

  /**
   * @returns {boolean} are shuffles allowed
   */
  get allowShuffle() {
    return this._allowShuffle;
  }

  /**
   * destruction method
   */
  destroy() {
    this._board = null;
  }
}
