import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { DATA_KEYS } from '../../../SaveState/SaveStateKeyMappings'; // eslint-disable-line import/no-cycle
import { SPECIALEVENT_LEADERBOARD_VIEWMODE } from '../../../../OMT_UI/SpecialEvents/Leaderboard/SpecialEvent_LeaderboardUserListPanel';
import OMT_TierLeaderboardUtil from '../../../../Utils/OMT_TierLeaderboardUtil';
import _ from 'lodash';
// import { ERROR_CODES } from '../../OMT_TierLeaderboard';
import { MILLISECONDS_IN_DAY, MILLISECONDS_IN_WEEKS } from '@omt-components/Utils/TimeUtil';

export const TREASURE_HUNT_TIME_READY = 'treasureHuntTimeReady';
export const TREASURE_HUNT_TIME_RECHECK = 'treasureHuntTimeRecheck';
export const TREASURE_HUNT_BOT_MSG_START = 'THuntStart';
export const TREASURE_HUNT_BOT_MSG_END = 'THuntEnd';

/**
 * Please check out some text here
 * https://confluence.softgames.de/pages/viewpage.action?pageId=53577021
 *
 * @author Sandra Koo
 */
export default class TreasureHuntManager {
  /**
   * Returns default data
   * @returns {{cl:number,
   *           t:number,
   *           ll:Array<number>,
   *           cw:number,
   *           la:number,
   *           le:number,
   *           ps:boolean,
   *           ftue:{ap:boolean, p:boolean, fb:boolean, uo:boolean, ce:boolean},
   *           pp:Array<{cr:number, r:number, id:string}>
   *           l:{ls:boolean, id:string, is: number, r:number, pp:Array<{cw:number, r:number, id:string}>}}
   *           d:{lb:string}}
   */
  static getDefaultValues() {
    return {
      cl: 0, // [C]urrent [L]evel to show during the treasure hunt
      t: 0, // [T]okens on hand
      ll: [], // [L]ast[L]evel list
      lp: 0, // [L]ast [P]articipation time
      le: 0, // [L]ast [E]ntry time.
      cw: -1, // [C]urrent [W]eek. Used to determine Mascot
      la: 0, // [L]evel [A]ttempts for the current level
      ps: false, // [P]ending [S]end. Tokan count was unavailable and it needs to update soon
      ftue: {
        p: false, // [p]romo seen
        ap: false, // [a]fter [p]rize things are seen. Gets reset too
        fb: false, // [f]irst [b]adge. At least from the treasure hunt
        uo: false, // [U]pdate message [O]ne. To show the first update message
        ce: false, // [C]ontent [E]ntry. Another flag pop up for telling you to play TH
      },
      pp: [], // [P]rize [P]ending. Unclaimed prizes. Includes badges
      l: { // [L]eaderboard
        ls: false, // [L]eaderboard [S]een
        id: null, // [ID] of the instance they're in
        is: 0, // [I]nstance [S]lug. Used by OMT_TierLeaderboard
        r: 0, // [R]ating. Used by OMT_TierLeaderboard
        pp: [], // [P]rize [P]ending. Another one, but this one has leaderboard badges, etc
      },
      d: { // [D]ebug
        lb: null, // [L]eader[Board]. Sets leaderboard into save state so you don't have to spam it
      },
    };
  }

  /**
   * Applys the prize from the treasure hunt to the user
   * @param {{prize:string, amount:number}} prize
   * @param {Function} [coinBatchFunc]
   */
  static applyPrizes(prize, coinBatchFunc) {
    const trackerArray = [];
    for (let i = 0; i < prize.length; i++) {
      const curPrize = prize[i];
      const oldStyle = [curPrize.prize, curPrize.amount];

      switch (curPrize.prize.toLowerCase()) {
        case 'coin':
          if (coinBatchFunc) {
            coinBatchFunc(curPrize.amount);
          }
          OMT.transactionTracking.addInventoryChange('coins', 0, curPrize.amount);
          break;
        case 'life':
          G.saveState.addLife(curPrize.amount); // Add life
          break;
        case 'lifeunlimited':
          G.saveState.addUnlimitedLivesTimeMin(curPrize.amount); // Add unlimited lives
          break;
        case 'badge':
          G.saveState.badgeManager.setBadge(curPrize.amount);
          // if (DDNA.tracking.enabled) {
          //   const { badgeType, mascotName } = this.extractBadgeInfo(curPrize.amount);
          //   DDNA.tracking.collectEvent('treasureHuntBadgeCollected', {
          //     treasureHuntCharacter: mascotName,
          //     treasureHuntBadgeTier: badgeType,
          //     treasureHuntID: curPrize.treasureHuntId || this.getDataCollectionWeek(),
          //   });
          // }
          break;
        default: { // Maybe a booster!?
          const chosenGift = G.gift.processGift(oldStyle);
          if (chosenGift) {
            if (oldStyle[0] !== chosenGift[0]) {
              oldStyle[0] = chosenGift[0]; // eslint-disable-line prefer-destructuring
            }
            const boosterIndex = parseInt(oldStyle[0].substring(oldStyle[0].length - 1), 10);
            G.saveState.changeBoosterAmount(boosterIndex, curPrize.amount); // Add in booster
            OMT.transactionTracking.addInventoryChange('boostersReceived', boosterIndex, curPrize.amount);
          }
          break;
        }
      }
      trackerArray.push(oldStyle); // Puts into an array for data tracking
    }
  }

  /**
   * Extracts the needed info for DDNA tracking
   * @param {string} badgeName
   * @returns {{badgeType:string, mascotName:string}}
   */
  static extractBadgeInfo(badgeName) {
    const mascotNames = G.OMTsettings.treasureHuntSuper.mascotOrder.join('|'); // gingy|ginger|nutmeg|chip|cookie|graham
    const regex = new RegExp(`treasureHunt(\\w+)(${mascotNames})`);
    const regexInfo = regex.exec(badgeName);
    if (regexInfo) {
      let badgeTier;
      switch (regexInfo[1]) {
        case 'Bronze': badgeTier = 2; break;
        case 'Silver': badgeTier = 3; break;
        case 'Gold': badgeTier = 4; break;
        default: badgeTier = 1; break;
      }
      return {
        badgeType: badgeTier,
        mascotName: regexInfo[2],
      };
    }
    return {
      badgeType: null,
      mascotName: null,
    };
  }

  /**
   * Determines everything vague from the prize pool into something more concrete.
   * Random boosters are processed into proper boosters
   * Badges are determined to be the badge that it was earned on (info on that is placed into amount)
   *
   * @param {Array{prize:string, amount:number}} prize
   * @returns {Array<{prize:string, amount:(string|number), treasureHuntId:number}>}
   */
  static cleanPrizes(prize) {
    const copiedPrize = [];
    for (let i = 0; i < prize.length; i++) {
      const curPrize = {
        prize: prize[i].prize,
        amount: prize[i].amount,
      };

      if (curPrize.prize.toLowerCase().indexOf('badge') > -1) {
        const { currentMascot } = G.saveState.treasureHuntManager;
        const mascotName = TreasureHuntManager.getMascotName(currentMascot);
        const targetBasicBadge = `treasureHuntBasic${mascotName}`;
        curPrize.prize = 'badge';
        curPrize.amount = targetBasicBadge;
      } else if (curPrize.prize.toLowerCase().indexOf('#R')) { // Its a random booster!
        const chosenGift = G.gift.processGift([curPrize.prize, curPrize.amount]);
        if (chosenGift) {
          if (curPrize.prize !== chosenGift[0]) {
            curPrize.prize = chosenGift[0]; // eslint-disable-line prefer-destructuring
          }
        }
      }
      curPrize.treasureHuntId = this.getDataCollectionWeek();
      copiedPrize.push(curPrize);
    }
    return copiedPrize;
  }

  /**
   * Opens the treasure hunt pop up (or the pre level pop up)
   * Used in various places regarding the treasure hunt event
   */
  static async openTreasureHuntPopup(showPlacement) {
    if (G.saveState.treasureHuntManager.nextPrize) { // Get your prize first
      G.sb('pushWindow').dispatch('treasureHuntPrizeClaim');
    } else if (G.saveState.treasureHuntManager.inActiveCycle && showPlacement && game.state.current === 'Game') {
      G.sb('pushWindow').dispatch(['treasureHuntLeaderboard', null, SPECIALEVENT_LEADERBOARD_VIEWMODE.PLACEMENT]);
    } else if (G.saveState.treasureHuntManager.lastExpiredLeaderboard && game.state.current === 'World') { // Show leaderboard, on saga map only
      G.sb('pushWindow').dispatch(['treasureHuntLeaderboard', null,
        G.saveState.treasureHuntManager.lastExpiredLeaderboard ? SPECIALEVENT_LEADERBOARD_VIEWMODE.ENDING : SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL]);
    } else if (!(G.saveState.treasureHuntManager.inActiveCycle && OMT.feature.isTreasureHuntOn(false, false, false, true))) {
      // Do NOTHING. We don't show anything!
    } else if (!G.saveState.treasureHuntManager.leaderboardData.instanceId) { // Show prelevel if hasn't been seen yet
      if (G.saveState.treasureHuntManager.promoSeen) {
        if (!G.saveState.treasureHuntManager.contentEntry) {
          G.sb('pushWindow').dispatch('treasureHuntActiveNotice');
        } else {
          G.sb('pushWindow').dispatch('treasureHuntPreEvent');
        }
      }
    } else { // All else
      G.sb('pushWindow').dispatch('treasureHuntLevel');
    }
  }

  /**
   * Returns mascot name from string
   * @param {number} mascot
   * @returns {string}
   */
  static getMascotName(mascot) {
    if (!Number.isFinite(mascot)) {
      mascot = this.currentMascot;
    }
    const mascotName = G.OMTsettings.treasureHuntSuper.mascotOrder[mascot];
    return mascotName;
  }

  /**
   * Returns the week number from the first day of the year, to the nearest Thursday
   * @returns {number}
   */
  static getWeek(date) {
    if (!date) { date = Date.now(); }
    const yearStart = new Date();
    yearStart.setUTCHours(0, 0, 0);
    yearStart.setUTCFullYear(yearStart.getUTCFullYear(), 0, 1);
    yearStart.setUTCDate((yearStart.getUTCDate() - (yearStart.getUTCDay() || 7)) + 3); // The closest wednesday

    const now = new Date(date);
    const days = Math.floor((now - yearStart) / MILLISECONDS_IN_DAY);
    const weekNo = Math.floor(days / 7);
    return weekNo;
  }

  /**
   * Returns a week index from the start of the year
   * Used for splitting treasure hunts
   * @returns {number}
   */
  static getDataCollectionWeek() {
    const now = new Date(OMT.connect.getServerTimestampSync());
    const weekNo = TreasureHuntManager.getWeek(now);
    const extraZero = weekNo < 10;
    const fullYear = `${now.getFullYear()}${extraZero ? 0 : ''}`;
    return Number.parseInt(`${fullYear}${weekNo}`);
  }

  /**
   * Creates a data collection day from the given date
   * @param {Date} date
   * @return {number}
   */
  static createDataCollectionWeek(date) {
    const day = new Date(date);
    const weekNo = TreasureHuntManager.getWeek(day);
    const extraZero = weekNo < 10;
    const fullYear = `${day.getFullYear()}${extraZero ? 0 : ''}`;
    return Number.parseInt(`${fullYear}${weekNo}`);
  }

  /**
   * Does the DDNA calls for tracking
   */
  static trackDDNAStats() {
    // if ( DDNA.tracking.enabled) {
    //   DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('treasureHuntID', this.getDataCollectionWeek());
    //   if (G.saveState.treasureHuntManager.leaderboardData.instanceId) {
    //     DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('treasureHuntLeaderboardHash', G.saveState.treasureHuntManager.leaderboardData.instanceId);
    //   }
    // }
  }

  /**
   * Nothing here
   */
  constructor() {
    // shrug
  }

  /**
   * Inits the data
   * @param {{cl:number,
   *           t:number,
   *           ll:Array<number>,
   *           cw:number,
   *           la:number,
   *           le:number,
   *           ps:boolean,
   *           ftue:{ap:boolean, p:boolean, fb:boolean, uo:boolean, ce:boolean},
   *           pp:Array<{cr:number, r:number, id:string}>
   *           l:{ls:boolean, id:string, is: number, r:number, pp:Array<{cw:number, r:number, id:string}>}
   *           d:{lb:string}}} dataRef
   */
  init(dataRef) {
    G.saveState.deleteCooldown(TREASURE_HUNT_TIME_RECHECK, ''); // Clear it, if it is
    this.dataReference = dataRef;
    this._currentMascot = -1;
    this._tempTokens = 0;
    this._doubleTokens = false;
    this.sessionData = {
      reminderPopup: false,
      onLoadPopupPending: false,
    };
    this.leaderboardSlug = G.OMTsettings.treasureHuntSuper.leaderboardConfig.leaderboard.name;
    this._recheckDelay = 1000;

    OMT_Utils.mergeMissingObject(this.dataReference, TreasureHuntManager.getDefaultValues());

    this._leaderboardSlug = this.dataReference.d.lb || G.OMTsettings.treasureHuntSuper.leaderboardConfig.leaderboard.name;
    this._lastParticipationTime = this.dataReference.lp || 0;
    if (this.dataReference.le === 0) {
      this.dataReference.le = this._lastParticipationTime;
    }

    // OMT.tierLeaderboard.initalizeClient(this._checkData.bind(this));
  }

  /**
   * Sets the debug leaderboard slug
   */
  set debugLeaderboardSlug(s) {
    this.dataReference.d.lb = s;
  }

  /**
   * Sets the leaderboard slug so I don't have to keep referring to it with long name
   */
  set leaderboardSlug(s) {
    if (!s) {
      s = G.OMTsettings.treasureHuntSuper.leaderboardConfig.leaderboard.name;
    }
    this._leaderboardSlug = s;
  }

  /**
   * Gets the leaderboard slug
   * @return {string}
   */
  get leaderboardSlug() {
    return this._leaderboardSlug;
  }

  /**
  * Returns the index of the current mascot. If there isn't any, sets it too.
  * @returns {number}
  */
  get currentMascot() {
    if (this._currentMascot === -1) {
      this._determineNextMascot();
    }
    return this._currentMascot;
  }

  /**
   * Returns the time left before the end of the event.
   * If the time is actually over, it'll recalculate the time, but still send back the proper time left
   */
  get timeBeforeEnd() {
    const timeOver = this._timeBeforeEnd;
    const isTimeOver = this._timeBeforeEnd - OMT.connect.getServerTimestampSync() < 0;
    if (isTimeOver) {
      this.recalculateTime(true);
    }
    return timeOver;
  }

  /**
   * Returns the time the next session starts. Does not include current session
   * @returns {number}
   */
  get timeNextStart() {
    return this._timeNextStart;
  }

  /**
   * Returns true if the time was ever checked
   * @returns {Boolean}
   */
  get isTimeReady() {
    return this._timeIsReady || false;
  }

  /**
   * Returns true if the time was ever checked
   * @returns {Boolean}
   */
  get inActiveCycle() {
    return this._inActiveCycle || false;
  }

  /**
   * Returns the last time the player participated in the treasure hunt
   * @returns {number}
   */
  get lastParticipationTime() {
    return this._lastParticipationTime || 0;
  }

  /**
   * Checks data
   */
  async _checkData() {
    let doASave = await this._checkServerRelated();

    const savedWeek = this.dataReference.cw;
    if (savedWeek !== this.currentMascot) { // currentMascot will be initialized first when called
      this.dataReference.ftue.ce = false; // Content Entry flags reset each treasure hunt
      this.assignNewLevel(false);
      doASave = true;
    }

    if (TreasureHuntManager.getDataCollectionWeek() === 202133
      && G.OMTsettings.treasureHuntSuper.levels.useList
      && G.OMTsettings.treasureHuntSuper.levels.levelList.indexOf(this.dataReference.cl) === -1) {
      this.assignNewLevel(false);
      doASave = true;
    }

    if (doASave) { this.save(); }
  }

  /**
   * Resets the current tokens on hand. Works on new weeks
   */
  resetTreasureHuntTokenCount(saveNow) {
    this.dataReference.t = 0;
    this.dataReference.la = 0;
    if (saveNow) {
      this.save();
    }
  }

  /**
   * Resets everything
   */
  resetEverything() {
    this.resetTreasureHuntTokenCount(false);
    this.dataReference.ll.length = 0;
    this.dataReference.ftue.p = false;
    this.dataReference.ftue.fb = false;
    this.dataReference.ftue.ap = false;
    this.dataReference.ftue.uo = false;
    this.dataReference.pp.length = 0;
    // const data = OMT.tierLeaderboard.assembleInstanceData({ // Reset your saved leaderboardState
    //   storageKey: DATA_KEYS.TREASURE_HUNT,
    //   instanceId: null,
    //   rating: 0,
    // }, false);
    this.saveLeaderboardState({
      instanceId: null,
      instanceSlug: 0,
      rating: 0,
    }, false);
    this.save();
  }

  /**
   * Does everything related to the backend
   * @returns {Boolean}
   */
  async _checkServerRelated() {
    let doASave = false;
    const curLeaderboardId = this.dataReference.l.id;

    // Check connection
    doASave = await this.recalculateTime(false);
    if (!this._timeIsReady) {
      G.saveState.setUserCooldown(TREASURE_HUNT_TIME_RECHECK, '', 60000);
    }

    doASave = doASave || await this._storePastLeaderboards();
    // If pending send, and the current leaderboard is correct
    if (this.pendingSend && curLeaderboardId && curLeaderboardId === this.dataReference.l.id) {
      const curToken = this.dataReference.t;
      const ldbTokens = await this.syncTokens();
      if (ldbTokens >= 0 && curToken !== ldbTokens) {
        this.dataReference.t = ldbTokens;
        doASave = true;
      }
    } else {
      this.resetPending();
    }

    if (this.dataReference.ll.length <= 1) { // No data ever!?
      this.dataReference.ftue.uo = true; // Don't need to show update... ever
      this.assignNewLevel(false);
      doASave = true;
    } else {
      TreasureHuntManager.trackDDNAStats();
    }

    // Remove every stored leaderboard if they're not here anymore.
    return doASave;
  }

  /**
   * Store leaderboards if they're not active anymore
   * @returns {null}
   */
  async _storePastLeaderboards() {
    let doASave = false;
    // Store
    const leaderboardId = this.dataReference.l.id;
    const leaderboardData = await OMT.tierLeaderboard.getLeaderboardInfo({ storageKey: DATA_KEYS.TREASURE_HUNT, leaderboardId });
    const now = OMT.connect.getServerTimestampSync();
    if (!leaderboardData || (leaderboardData && now > leaderboardData.endDate.getTime())) { // this closed a long time ago...
      // Determine badges won, if any
      const leaderboardStartDate = leaderboardData ? leaderboardData.startDate : null;
      const treasureHuntId = leaderboardStartDate
        ? TreasureHuntManager.createDataCollectionWeek(leaderboardStartDate)
        : TreasureHuntManager.getDataCollectionWeek(); // Figure out which treasure hunt week it is
      const entry = await OMT.tierLeaderboard.getYourEntry({ storageKey: DATA_KEYS.TREASURE_HUNT, leaderboardId });
      const yourEntry = entry || { rank: 50 };
      const yourRank = yourEntry.rank; // Either a nice rank, your last saved rank, or dead last
      this.dataReference.l.pp.push({
        cw: this.dataReference.cw,
        r: yourRank,
        id: this.dataReference.l.id,
        tid: treasureHuntId,
      });

      const { rating, instanceSlug } = OMT.tierLeaderboard.calculateNewRatingAndSlug(this.dataReference.l.r, yourRank);
      this.saveLeaderboardState({
        instanceId: null,
        instanceSlug: OMT_TierLeaderboardUtil.getInstanceSlugNumberFromString(instanceSlug),
        rating,
      }, false);

      // Automatically saves new rating into save state
      this.resetTreasureHuntTokenCount();
      this.dataReference.ftue.ap = false; // Reset after prize flag

      doASave = true;
    }

    // Check
    if (this.dataReference.l.pp.length > 0) {
      const toRemove = [];
      for (const entry of this.dataReference.l.pp) {
        if (this._isValidLeaderboardData(entry)) {
          const leaderboardEntry = await OMT.tierLeaderboard.getYourEntry({ // eslint-disable-line no-await-in-loop
            storageKey: DATA_KEYS.TREASURE_HUNT,
            leaderboardId: entry.id,
          });
          if (!leaderboardEntry) {
            toRemove.push(entry);
          }
        } else {
          toRemove.push(entry);
        }
      }
      if (toRemove.length > 0) {
        _.remove(this.dataReference.l.pp, (entry) => toRemove.indexOf(entry) > -1);
        doASave = true;
      }
    }
    return doASave;
  }

  /**
   * Checks if the leaderboard data passed in is a valid one
   * @param {{cw:number, r:number, id:string}} leaderboardData
   * @returns {boolean}
   */
  _isValidLeaderboardData(leaderboardData) {
    return Number.isFinite(leaderboardData.cw) && Number.isFinite(leaderboardData.r) && leaderboardData.id;
  }

  /**
   * Recalculates time by external call
   * @param {Boolean} checkLeaderboard
   */
  async recalculateTime(checkLeaderboard, force) {
    if (!force && G.saveState.getUserCooldownRemaining(TREASURE_HUNT_TIME_RECHECK, '') > 0) { return; }
    // let doASave = false;
    // const timePack = await OMT.tierLeaderboard.getCycleInfo(this._leaderboardSlug);
    // this._timeBeforeEnd = timePack.currentCycleEnd;
    // this._timeNextStart = timePack.nextCycleStart;
    // this._inActiveCycle = timePack.inActiveCycle;
    this._timeIsReady = this._timeBeforeEnd > -1;
    // const { timeFailed } = timePack;
    // if (timeFailed) {
    //   this._recheckDelay = Math.min(this._recheckDelay * 2, 30000);
    //   G.saveState.setUserCooldown(TREASURE_HUNT_TIME_RECHECK, '', this._recheckDelay);
    //   console.log(`[Treasure Hunt] Unable to check leaderboard ${this._leaderboardSlug}. Will try again in ${this._recheckDelay / 1000}s`)
    //   return false;
    // }

    // if (checkLeaderboard) {
    //   doASave = await this._storePastLeaderboards();
    //   if (doASave && checkLeaderboard) { this.save(); }
    //   this._timeBeforeEnd = this._inActiveCycle ? timePack.currentCycleEnd : timePack.nextCycleEnd;
    // }

    const nowTime = OMT.connect.getServerTimestampSync();
    const timeCheck = (this._timeBeforeEnd < 0 ? this._timeNextStart : this._timeBeforeEnd) - nowTime;
    G.saveState.setUserCooldown(TREASURE_HUNT_TIME_RECHECK, '', timeCheck);
    if (this._timeIsReady) {
      G.sb(TREASURE_HUNT_TIME_READY).dispatch();
    }

    // return doASave;
  }

  /**
   * Assigns a new level for the treasure hunt
   */
  assignNewLevel(forceAndSave = true) {
    const { startingLevel } = G.OMTsettings.treasureHuntSuper.levels;
    let chosenLevel;
    if (this.dataReference.ll.length <= 1 && !forceAndSave) {
      if (this.dataReference.cl !== startingLevel) {
        this._pushLevelIntoLL(startingLevel);
        this.dataReference.la = 0;
        this.dataReference.cl = startingLevel;
      }
      return;
    }
    if (G.OMTsettings.treasureHuntSuper.levels.useList) { // Pick from list
      const { levelList } = G.OMTsettings.treasureHuntSuper.levels;

      let counter = 0;
      const counterMax = this.dataReference.ll.length + 1;
      let okToLeave = false;
      while (!okToLeave && counter < counterMax) {
        const filteredLevelList = levelList.filter((levelNum) => !this.dataReference.ll.includes(levelNum)); // Find level from filtered list
        if (filteredLevelList.length > 0) {
          chosenLevel = filteredLevelList[game.rnd.between(0, filteredLevelList.length - 1)];
          okToLeave = true;
        } else {
          this.dataReference.ll.shift();
        }
        counter++;
      }
      if (!chosenLevel) { // If we didn't find a level in the end...
        chosenLevel = levelList[game.rnd.between(0, levelList.length - 1)]; // Pick random
      }
    } else { // Use algorithm to determine level
      const curLevel = G.saveState.getLastPassedLevelNr();
      const totalLevel = G.Helpers.levelDataMgr.getNumberOfLevels();
      if (curLevel < 100) {
        chosenLevel = this._pickRandomLevelBetween(50, 150);
      } else if (totalLevel - curLevel < 50) {
        chosenLevel = this._pickRandomLevelBetween(curLevel, curLevel - 100);
      } else {
        chosenLevel = this._pickRandomLevelBetween(Math.max(100, curLevel - 50), Math.min(curLevel + 50, totalLevel));
      }
    }
    this._pushLevelIntoLL(chosenLevel);
    this.dataReference.la = 0;
    this.dataReference.cl = chosenLevel;
    if (forceAndSave) {
      this.save();
    }
  }

  /**
   * Schedules bot message reminding the player that the treasure hunt will start
   */
  async scheduleStartBotMessage() {
    const currentTime = Date.now();
    const { maxIdleTime } = G.OMTsettings.treasureHuntSuper;

    if (this._lastParticipationTime === 0 || currentTime - this._lastParticipationTime < maxIdleTime) {
      const startMessage = await OMT.notifications.findGameTriggeredMessages(TREASURE_HUNT_BOT_MSG_START);

      if (startMessage.length === 0) {
        const delay = (this.timeNextStart - currentTime) / 1000; // delay in seonds
        OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, TREASURE_HUNT_BOT_MSG_START, delay, false);
      }
    }
  }

  /**
   * Schedules bot message reminding the player that the treasure hunt has ended
   * Tries to schedule it at the same current time of day after the actual treasure hunt end time
   */
  async scheduleEndBotMessage() {
    // Check if bot message was already scheduled
    const endMessage = await OMT.notifications.findGameTriggeredMessages(TREASURE_HUNT_BOT_MSG_END);
    if (endMessage.length === 0) {
      const currentDate = new Date();
      const endDate = new Date(this.timeBeforeEnd);

      // Set the end time of day to the current time of day
      const newEndDate = new Date(endDate.getTime());
      newEndDate.setHours(currentDate.getHours());
      newEndDate.setMinutes(currentDate.getMinutes());
      newEndDate.setSeconds(currentDate.getSeconds());

      // If the treasure hunt isn't finished before the end date at the current time of day,
      // Move the scheduled date later by one day
      if (newEndDate < endDate) {
        newEndDate.setDate(newEndDate.getDate() + 1);
      }

      const delay = Math.floor((newEndDate - currentDate) / 1000);
      OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, TREASURE_HUNT_BOT_MSG_END, delay, false);
    }
  }

  /**
   * Picks a random level between a and b that is not inside of the last level list
   * @param {number} a
   * @param {number} b
   * @returns {number}
   */
  _pickRandomLevelBetween(a, b) {
    let timeout = 0;
    let chosenLevel = 0;
    do {
      chosenLevel = game.rnd.between(a, b);
      timeout++;
    } while (this.dataReference.ll.includes(chosenLevel) && timeout < 200);
    return chosenLevel;
  }

  /**
   * Pushes the number into the last level.
   * Shifts to make sure the length is equal to 50
   * @param {number} level
   */
  _pushLevelIntoLL(level) {
    this.dataReference.ll.push(level);
    while (this.dataReference.ll.length > 50) {
      this.dataReference.ll.shift();
    }
  }

  /**
   * Thanks https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
   */
  _determineNextMascot() {
    const weekNo = TreasureHuntManager.getWeek();
    // Assign week
    if (weekNo !== this.dataReference.cw) {
      this.dataReference.cw = weekNo % G.OMTsettings.treasureHuntSuper.mascotOrder.length;
      this.save();
    }
    this._currentMascot = this.dataReference.cw;
  }

  /**
   * Changes the current mascot, temporarily.
   * For debug purposes
   * @param {string} m
   */
  changeMascot(m) {
    this._currentMascot = this.dataReference.cw = m;
  }

  /**
   * Increments the number of temp tokens
   * @param {number} number
   */
  addToTempTokens(number) {
    this._tempTokens += number;
  }

  /**
   * Resets temp tokens. Turns off the doubling flag
   */
  resetTempTokens() {
    this._tempTokens = 0;
    this._doubleTokens = false;
  }

  /**
   * Resets the FTUE flags
   */
  resetFTUE() {
    this.dataReference.ftue.ap = false;
    this.dataReference.ftue.p = false;
    this.dataReference.ftue.fb = false;
    this.dataReference.ftue.ce = false;
    this.save();
  }

  /**
   * Increments the level attempts on this level
   */
  incrementLevelAttempts() {
    this.dataReference.la++;
    this.save();
  }

  /**
   * Turns on the doubling tokens flag
   */
  activateDoubleToken() {
    this._doubleTokens = true;
  }

  /**
   * Sets the flag for the after prize screen to be seen
   */
  afterPrizeIsSeen() {
    this.dataReference.ftue.ap = true;
    this.save();
  }

  /**
   * Sets a flag for the promo being seen
   */
  setPromoSeen() {
    this.dataReference.ftue.p = true;
    this.save();
  }

  /**
   * Sets a flag for the first badge being seen
   */
  setFirstBadgeSeen() {
    this.dataReference.ftue.fb = true;
  }

  /**
   * Sets the update message seen to true
   */
  setUpdateMessageSeen() {
    this.dataReference.ftue.uo = true;
    this.save();
  }

  /**
   * Returns the flag for if the update message was seen
   * @returns {boolean}
   */
  get updateMessageSeen() {
    return this.dataReference.ftue.uo;
  }

  /**
   * Sets the content entry flag to true
   */
  setContentEntrySeen() {
    this.dataReference.ftue.ce = true;
    this.save();
  }

  /**
   * Returns the flag for the content entry
   * @returns {boolean}
   */
  get contentEntry() {
    return this.dataReference.ftue.ce;
  }

  /**
   * Sets the leaderboard seen flag to true
   */
  leaderboardPlacementIsSeen() {
    this.dataReference.l.pp.shift();
    this.save();
  }

  /**
   * Saves the leaderboard state
   * @param {{instanceId:string, instanceSlug:INSTANCE_SLUG, rating:number}} state
   * @param {Boolean} saveNow
   */
  saveLeaderboardState(state, saveNow) {
    this.dataReference.l.id = state.instanceId;
    this.dataReference.l.is = OMT_TierLeaderboardUtil.getInstanceSlugNumberFromString(state.instanceSlug);
    this.dataReference.l.r = state.rating;
    if (saveNow) { this.save(); }
  }

  /**
   * Adds in temp tokens to the user's state. Will double if its supposed to be doubled
   * @param {boolean} [saveNow]
   */
  cashInTokens(saveNow = true) {
    let { tempTokens } = this;
    if (this._doubleTokens) {
      tempTokens *= 2;
    }
    this.dataReference.t += tempTokens;
    this.resetTempTokens();
    if (saveNow) {
      this.save();
    }
  }

  /**
   * Syncs tokens if needed
   *
   * Two calls use this.
   * TreasureHuntWin = Save call will be done later in the line
   * this.checkServerRelated = Save call will be done later in the line
   * @param {number} [tokens]
   * @returns {Boolean}
   */
  async syncTokens(tokens) {
    this.resetPending();
    const tokenToSend = Number.isFinite(tokens) ? tokens : this.dataReference.t;
    const res = await OMT.tierLeaderboard.sendScore({
      storageKey: DATA_KEYS.TREASURE_HUNT,
      slug: this._leaderboardSlug,
      score: tokenToSend,
    });
    // if (res === ERROR_CODES.SEND_GEN_FAIL) {
    //   this.setPendingSend();
    // }
    if (res) {
      this.dataReference.le = OMT.connect.getServerTimestampSync();
    }
    return res;
  }

  /**
   * Resets the flag that the score needs to send soon
   */
  resetPending(saveNow = false) {
    this.dataReference.ps = false;
    if (saveNow) { this.save(); }
  }

  /**
   * Score needs to send soon
   */
  setPendingSend() {
    this.dataReference.ps = true;
  }

  get pendingSend() {
    return this.dataReference.ps;
  }

  /**
   * Puts the prize into a pending array.
   * @param {number} prize index
   * @param {boolean} [saveNow]
   */
  putPrizeIntoPending(prizeIndex, saveNow = true) {
    if (prizeIndex > -1 && this.dataReference.pp.filter((obj) => obj.i === prizeIndex).length === 0) {
      const prizeData = TreasureHuntManager.cleanPrizes(G.OMTsettings.treasureHuntSuper.reward.prizes[prizeIndex]);
      this.dataReference.pp.push({
        p: prizeData,
        i: prizeIndex,
      });
      if (saveNow) {
        this.save();
      }
    }
  }

  /**
   * Places a badge into the prize pending.
   * Kinda ends up circumventing the cleanPrizes function but this is ONLY FOR BADGES
   * @param {number} badgeIndex AKA the user's rank
   * @param {Boolean} saveNow
   */
  putBadgeIntoPending(mascotNumber, badgeIndex, tid, saveNow = true) {
    const cap = G.OMTsettings.treasureHuntSuper.reward.prizes.length;
    const prizeIndex = cap + badgeIndex;
    if (prizeIndex > -1 && this.dataReference.pp.filter((obj) => obj.i === prizeIndex).length === 0) {
      let targetBadge = '';
      switch (badgeIndex) {
        case 0: targetBadge = 'Gold'; break;
        case 1: targetBadge = 'Silver'; break;
        case 2: targetBadge = 'Bronze'; break;
        default: targetBadge = 'Basic'; break;
      }

      const mascotName = TreasureHuntManager.getMascotName(mascotNumber);
      const targetBasicBadge = `treasureHunt${targetBadge}${mascotName}`;
      this.dataReference.pp.push({
        p: [{
          prize: 'badge',
          amount: targetBasicBadge,
          treasureHuntId: tid,
        }],
        i: 3 + badgeIndex,
      });
      if (saveNow) {
        this.save();
      }
    }
  }

  /**
   * Removes the prize by index
   * @param {number}
   */
  removePrizeByIndex(i) {
    _.remove(this.dataReference.pp, (obj) => obj.i === i);
    this.save();
  }

  /**
   * Checks immediately if a prize can be won before cashing in tokens.
   * Returns the prize index if so
   * @param {number} wasCurrent
   * @param {number} increase
   * @returns {number}
   */
  canGetAPrize(wasCurrent, increase) {
    const currencyUnlocks = G.OMTsettings.treasureHuntSuper.reward.currencyUnlock;
    const possiblePrizes = [];
    for (let i = 0; i < currencyUnlocks.length; i++) {
      const chosenTier = currencyUnlocks[i];
      if (wasCurrent < chosenTier) { // Originally less than
        const newSum = wasCurrent + increase;
        if (newSum >= chosenTier) { // Did we JUST past that tier threshold?
          possiblePrizes.push(i); // Send the index
        }
      }
    }
    return possiblePrizes;
  }

  /**
   * Sets the last time the player participated in the treasure hunt
   * @param {number} timestamp
   */
  setLastParticipationTime(timestamp = this.timeBeforeEnd) {
    this._lastParticipationTime = timestamp;
    this.dataReference.lp = timestamp;
    this.save();
  }

  /**
   * Returns the current treasure hunt level
   * @returns {number}
   */
  get currentTreasureLevel() {
    return this.dataReference.cl;
  }

  /**
   * Returns the number of tokens on hand
   * @returns {number}
   */
  get currentTokens() {
    return this.dataReference.t;
  }

  /**
   * Returns boolean if you got all the prizes or not (Supposedly)
   * @returns {boolean}
   */
  eligibleForAllPrizes(tokens) {
    return tokens >= G.OMTsettings.treasureHuntSuper.reward.currencyUnlock[G.OMTsettings.treasureHuntSuper.reward.currencyUnlock.length - 1];
  }

  /**
   * Does a bunch of checks to see if the user is eligible for a reminder to play the treasure hunt
   * @returns {boolean}
   */
  eligibleForReminder() {
    const isLevelEligible = OMT.feature.isTreasureHuntOn(true, true, false, true);
    const pastThreeWeeks = (Date.now() - this.dataReference.le) >= MILLISECONDS_IN_WEEKS * 3;
    const noLeaderboard = this.lastExpiredLeaderboard === null;
    const noActiveInstance = this.leaderboardData.instanceId === null;
    const reminderWasYetMade = !this.sessionData.reminderPopup;
    return pastThreeWeeks && noLeaderboard && noActiveInstance && isLevelEligible && reminderWasYetMade;
  }

  /**
   * Returns the number of temp tokens
   * @returns {number}
   */
  get tempTokens() {
    return this._tempTokens;
  }

  /**
   * Returns the number of level attempts
   * @returns {number}
   */
  get levelAttempts() {
    return this.dataReference.la;
  }

  /**
   * Returns the state of the doubling tokens
   * @returns {boolean}
   */
  get isDoubling() {
    return this._doubleTokens;
  }

  /**
   * Returns the state of seeing what happens after the prize
   * @returns {boolean}
   */
  get afterPrizeSeen() {
    return this.dataReference.ftue.ap;
  }

  /**
   * Returns the next prize in pending
   * @returns {(Array<{p:{amount:number, prize:string}, i:number}> | null)}
   */
  get nextPrize() {
    if (this.dataReference.pp.length > 0) {
      return this.dataReference.pp[0];
    }
    return null;
  }

  /**
   * Returns the state of the promo being seen or not
   * @returns {boolean}
   */
  get promoSeen() {
    return this.dataReference.ftue.p;
  }

  /**
   * Returns the state of the first badge being seen or not
   * @returns {boolean}
   */
  get firstBadgeSeen() {
    return this.dataReference.ftue.fb;
  }

  /**
   * Returns usable leaderboard data
   * @returns {{instanceId:string, instanceSlug:INSTANCE_SLUG, rating:number}}
   */
  get leaderboardData() {
    return {
      instanceId: this.dataReference.l.id,
      instanceSlug: OMT_TierLeaderboardUtil.getInstanceSlugStringFromNumber(this.dataReference.l.is),
      rating: this.dataReference.l.r,
    };
  }

  /**
   * Returns the last leaderboard that was available
   * @returns {({cw:number, r:number, id:string, tid:number} | null)}
   */
  get lastExpiredLeaderboard() {
    if (this.dataReference.l.pp.length > 0) {
      return this.dataReference.l.pp[0];
    }
    return null;
  }
}
