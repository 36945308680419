import { GameScaleController } from '../../States/Scaling/GameScaleController';

G.WorldSidePanelGroupContent = function(x, y) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);

  this._eventManagers = [];

  // TODO: change initialization
  // so events can be added at any time
  this._ribbonEvent = null;
  this._noEventPanel = null;

  this._contentHeight = 0;

  this._dailyMissions = null;

  this._init();
};

G.WorldSidePanelGroupContent.prototype = Object.create(Phaser.Group.prototype);

G.WorldSidePanelGroupContent.prototype._init = function() {
  // imports
  var settings = G.json.settings;
  var saveState = G.saveState;
  var dailyMissionsMgr = G.dailyMissionsMgr;
  var DailyMissionContent = G.SPDailyMissionContent;
  var NoEventContent = G.WorldSidePanelNoEvent;
  var RibbonContent = G.WorldSidePanelRibbon;

  // imports mgrs
  // Fancy events with banners and being on top should be here
  // using _addEventManager

  var groupContent = this;

  this._ribbonEvent = this.add(new RibbonContent(0, 20, {
      ribbon: "gold_wheel_ribbon",
      text: OMT.language.getText("Events"),
    }));

  this._noEventPanel = this.add(new NoEventContent());

  if (dailyMissionsMgr.isFeatureUnlocked()) {
    this._dailyMissions = this.add(new DailyMissionContent());
  }

  this._updateEventsView();
};

/**
 * @param {Function<boolean>} eventCheck
 * @param {() => {... Phaser.DisplayObject, getContentHeight: () => Number}} panelConstructor
 */
G.WorldSidePanelGroupContent.prototype._addEventManager = function (eventCheck, panelConstructor, optPriority) {
  this._eventManagers.push({
    eventCheck,
    panelConstructor,
    panelInstance: null,
    priority: G.Utils.defined(optPriority, 0),
  });

  this._eventManagers.sort((a, b) => b.priority - a.priority);
};

G.WorldSidePanelGroupContent.prototype._updateEventsView = function () {
  // console.log("TH update event view");

  this._eventManagers.forEach((managerEntry) => {
    const { eventCheck } = managerEntry;
    const PanelConstructor = managerEntry.panelConstructor;

    if (eventCheck()) {
      if (managerEntry.panelInstance === null) {
        managerEntry.panelInstance = new PanelConstructor();
        this.add(managerEntry.panelInstance);
      }
    } else if (managerEntry.panelInstance) {
      managerEntry.panelInstance.destroy();
      managerEntry.panelInstance = null;
    }
  });
  this._refreshElementsPlacement();
};


G.WorldSidePanelGroupContent.prototype.getContentHeight = function() {
  return this._contentHeight;
};

G.WorldSidePanelGroupContent.prototype._getContentElementsList = function() {
  var contentElements = [];

  var anyEventUnlocked = this._eventManagers.find(function(entry) {
    return entry.eventCheck();
  });
  var eventPanels = this._eventManagers
    .map(function(entry) {return entry.panelInstance})
    .filter(function (panelInstance) {return panelInstance})

  if (anyEventUnlocked) {
    contentElements.push(this._ribbonEvent);
    this._ribbonEvent.visible = true;
    if (eventPanels.length == 0) {
      this._noEventPanel.visible = true;
      contentElements.push(this._noEventPanel);
    } else {
      this._noEventPanel.visible = false;
      contentElements = contentElements.concat(eventPanels);
    }
  } else {
    this._noEventPanel.visible = false;
    this._ribbonEvent.visible = false;
  }

  if (this._dailyMissions) {
    contentElements.push(this._dailyMissions);
  }

  return contentElements;
};

G.WorldSidePanelGroupContent.prototype._refreshElementsPlacement = function() {
  var contentList = this._getContentElementsList();

  if (contentList.length == 0) return;

  var lastY = contentList[0].y;
  contentList.forEach(function(element, i) {
    element.x = 235;
    element.y = lastY;
    lastY += element.getContentHeight();
  });

  this._contentHeight = this._countContentHeight(contentList);
};

G.WorldSidePanelGroupContent.prototype._countContentHeight = function(contentList) {
  const { gameScale } = GameScaleController.getInstance();
  return contentList.reduce(function(acc, element) {
    return acc + element.getContentHeight() * gameScale;
  }, 0)
};
