import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../OMT_UI/OMT_UI_SquareButton';

export default class ShareTickButton extends Phaser.Group {
  constructor(x, y, fontStyle, animate, shareTickType) {
    super(game, null);

    this.signals = {
      onClick: new Phaser.Signal(),
    };

    this.x = x;
    this.y = y;

    const button = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Brag!'),
        textStyle: fontStyle,
      },
    });
    button.signals.onClick.add(() => {
      this.signals.onClick.dispatch();
    });
    button.y += button.height / 2;
    this.addChild(button);

    if (animate) {
      const tickBtnEnterAnim = G.Animations.getTickBtnEnterAnimation();
      tickBtnEnterAnim.animate(game, button);
    }
  }

  destroy() {
    this.signals.onClick.dispose();
    super.destroy();
  }
}
