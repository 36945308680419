import { Action, ACTION_TYPES } from './Action';

/**
 * class for handling / managing falling candies action
 */
export class ActionProcessFall extends Action {
  /**
   * initialize the action.
   */
  _init() {
    this._madeCrossColumn = false;
    this._board.fallManager.setAllColumnsFall();
  }

  /**
   * action update method
   */
  update() {
    const board = this._board;
    if (!board.candiesAreAnimating && !board.candiesAreFalling) {
      // process cross-column candy falls, resolve thes before checking matches
      if (!this._madeCrossColumn) {
        while (board.fallManager.setCrossColumnFall()) this._madeCrossColumn = true;
        if (this._madeCrossColumn) {
          this.update(); return;
        }
      }

      // check if candies that have fallen are making matches
      board.processFallCheckList();

      if (this._madeCrossColumn) { // if a cross column fall ocurred re-trigger the fall process
        this._actionManager.newAction(ACTION_TYPES.PROCESS_FALL);
        this.finish();
      } else {
        board.checkGoalCandy();
        this.finish();
      }
    }
  }
}
