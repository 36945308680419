import TreasureHuntManager from '../../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';
import { Window_EventEntry } from '../Windows/tokenEvent/Window_EventEntry';
import { OMT_AssetLoader } from '../../Services/OMT/OMT_AssetLoader';

/**
 * Class that shows the treasure hunt is active, again
 */
export class Window_TreasureHuntActiveNotice extends Window_EventEntry {
  /**
   * Assigns flags when needed
   */
  _doBookkeeping() {
    G.saveState.treasureHuntManager.setContentEntrySeen();

    this._mascot = G.saveState.treasureHuntManager.currentMascot;
    this._mascotName = TreasureHuntManager.getMascotName(this._mascot);
  }

  /**
   * Grabs the config needed for this window.
   * @returns {{
   *  bgAsset: string,
   *  headerData: {
   *    asset: { x: number, y: number, assetString: string },
   *    text: { x: number, y: number, text:String, textStyle: string },
   *  }
   *  bannerData: {
   *    asset: { x: number, y: number, assetString: string },
   *    badge: { x: number, y: number, size: number },
   *  },
   *  textData: {
   *    debug: boolean,
   *    text: Array<{ x: number, y: number, string: string, textStyle: string }>,
   *  },
   *  playButton: { x: number, y: number, text: string },
   *  mascot: {
   *    assetString: string,
   *    offset: { x: number, y: number },
   *    scale: { horizontal: { x: number, y: number }, vertical: { x: number, y: number }},
   *    pos: { horizontal: { x: number, y: number }, vertical: { x: number, y: number }}}
   */
  _getConfig() {
    const config = JSON.parse(JSON.stringify(G.OMTsettings.treasureHuntSuper.eventReminder));
    config.headerData.text.text = this._replaceCommonWords(config.headerData.text.text);
    for (let i = 0; i < config.textData.text.length; i++) {
      config.textData.text[i].string = this._replaceCommonWords(config.textData.text[i].string);
    }
    config.mascot.assetString += this._mascot;
    return config;
  }

  /**
   * Draws the asset banner inside the window
   */
  _drawBanner() {
    super._drawBanner();

    const badgeDetails = this._config.bannerData.badge;
    const targetBadge = `treasureHuntGold${this._mascotName}`;
    const badgeSize = badgeDetails.size;
    let badgeAsset;
    if (OMT_AssetLoader.getInstance().areSecondaryImagesLoaded(['treasureHunt'])) {
      badgeAsset = G.makeImage(badgeDetails.x, badgeDetails.y, `${targetBadge}Full`, 0.5, this._banner);
    } else {
      badgeAsset = G.makeExtImage(badgeDetails.x, badgeDetails.y, `${targetBadge}Full`, targetBadge, 0.5, this._banner, null, (asset) => {
        asset.scale.set(badgeSize / asset.width);
      });
    }
    badgeAsset.scale.set(badgeSize / badgeAsset.width);
  }

  /**
   * Replaces common words such as the mascot name or tokens
   * @param {string} str
   * @returns {string}
   */
  _replaceCommonWords(str) {
    return str.replace('%MASCOT%', this._mascotName);
  }

  /**
   * When the play button is clicked
   */
  async _onPlayClicked() {
    await G.saveState.treasureHuntManager.syncTokens(0);
    TreasureHuntManager.trackDDNAStats();
    TreasureHuntManager.openTreasureHuntPopup();
    G.saveState.treasureHuntManager.save();
    this.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.treasureHuntActiveNotice = Window_TreasureHuntActiveNotice;
