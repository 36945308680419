import { Window } from '../../../00_IMMEDIATE/Window';
import OMT_UI_HorizontalCardMenu from '../../../OMT_UI/Menus/slidingCardMenu/OMT_UI_HorizontalCardMenu';
import EventPostcard, { POSTCARD_STATE } from './EventPostcard';
import EventPostcard_Util from './EventPostcard_Util';

/**
 * Welcome to event postcard! This is activated between certain times.
 * Please check featureUnlock.js or OMT.feature.getEventPostcardFeature()
 */
export class Window_eventPostcard extends Window {
  /**
   * @param {??} parent Default window args
   * @param {Object} returnArgs An object package that will re-open the a window that was given to it, as well as its args and layer
   * @param {string} returnArgs.windowName The window to re-open to
   * @param {Array<any>} returnArgs.args The args to go to that window
   * @param {string} returnArgs.layerName The layer for that window
   * @param {Object} config Some configs
   * @param {number} config.startIndex The index to start at
   */
  constructor(parent, returnArgs, config) {
    super(parent);

    this._horizontalPositioner = undefined; // Util for tweening
    this._returnArgs = returnArgs; // Saving for later
    this._config = this._mergeConfigs(config);
    if (game.state.current === 'World') {
      this.y = -G.WindowMgr.Constants.WorldVerticalOffset;
    }

    this._init();
    this._onResize();
  }

  /**
   * Merges given config and fills in holes with defaults
   * @param {Object} config
   * @returns {{startIndex:number}}
   */
  _mergeConfigs(config) {
    const conf = {
      startIndex: (config && config.startIndex) ? config.startIndex : 0,
    };
    return conf;
  }

  /**
   * DESTROY!!
   */
  destroy() {
    if (this._horizontalPositioner) {
      this._horizontalPositioner.destroy();
      this._horizontalPositioner = null;
    }

    if (this._signalBinding) {
      if (this._signalBinding.detach) {
        this._signalBinding.detach();
      }
      this._signalBinding = null;
    }

    EventPostcard_Util.clearGradientData(this._headerContainer);
    this._clearAllCards();

    super.destroy();
  }

  /**
   * @returns {number}
   */
  get cardLimit() {
    return G.OMTsettings.postcardEvent.cardData.postcardData.length - 1;
  }

  /**
   * Checks if there was any args that was given. If there it, it will re-open the window (assuming nothing is in queue)
   * then continues to close itself
   */
  closeWindow() {
    if (this._returnArgs && this._returnArgs.layerName) {
      G.sb('pushWindow').dispatch([this._returnArgs.windowName].concat(this._returnArgs.args), false, this._returnArgs.layerName);
    }
    super.closeWindow();
  }

  /**
   * Initilizing things
   */
  _init() {
    this._maxCardsLinkedDistance = 2; // Max card distance of 2

    this._horizontalPositioner = new OMT_UI_HorizontalCardMenu(); // Start the util
    this._horizontalPositioner.signals.onLeftClick.add(this.onLeftClick, this);
    this._horizontalPositioner.signals.onRightClick.add(this.onRightClick, this);

    this._signalBinding = G.sb('onScreenResize').add(this._onResize, this);

    // Draw UI
    this._headerContainer = this._drawHeader();
    this.addChild(this._headerContainer);
    this._arrows = this._drawArrows();
    this._closeBtn = this._drawCloseButton();

    const upperCardLimit = this.cardLimit;
    this._currentCard = this._createCard(Math.min(this._config.startIndex, upperCardLimit)); // Create a card
    this.addChild(this._currentCard);
    this._cardDimensions = this._currentCard.getBounds().clone(); // Get its boundaries

    this.prevCardPosition = -(this._cardDimensions.width * 0.4) - (game.width / 2); // Figure out the positions
    this.nextCardPosition = (game.width / 2) + (this._cardDimensions.width * 0.4);

    if (this._currentCard.index > 0) {
      this._displayPrevCard(this._currentCard.index - 1); // Display prev card if not at index 0
    }
    if (this._currentCard.index < upperCardLimit) {
      this._displayNextCard(this._currentCard.index + 1); // Display the next card
    }
    this._positionCloseButton();
    this._checkCard(); // Do UI checks

    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenPostcardShareBtn', 1);
  }

  _onResize() {
    this._repositionFrame();
    this._checkCard();
    this._positionCloseButton();
  }

  _repositionFrame() {
    EventPostcard_Util.redrawGradients(this._headerContainer);

    this._headerContainer.titleAsset.x = this._headerContainer.gradientData.titleBg.image.x; // + this._headerContainer.gradientData.titleBg.image.width;
    this._headerContainer.titleAsset.y = this._headerContainer.gradientData.titleBg.image.y + this._headerContainer.gradientData.titleBg.image.height * 0.7;
    this._headerContainer.addChild(this._headerContainer.titleAsset);

    G.Text.setMaxWidth(this._headerContainer.titleText, this._headerContainer.gradientData.titleBg.image.width * 0.9);
    this._headerContainer.titleText.setText(this._headerContainer.titleText.text);
    this._headerContainer.titleText.x = this._headerContainer.gradientData.titleBg.image.x + this._headerContainer.gradientData.titleBg.image.width / 2;
    this._headerContainer.titleText.y = this._headerContainer.gradientData.titleBg.image.y + 5 + this._headerContainer.gradientData.titleBg.image.height / 2;
    this._headerContainer.addChild(this._headerContainer.titleText);

    this._headerContainer.x = -game.width / 2;
    this._headerContainer.y = -(game.height) / 2;
  }

  /**
   * Creates a postcard with the given index. Everything else is determined by G.OMTSettings
   * @param {number} index
   * @returns {EventPostcard}
   */
  _createCard(index) {
    const { avatar } = OMT.envData.settings.user;
    const targetWidth = 425;
    const card = new EventPostcard({ width: targetWidth, height: targetWidth * 1.25 });
    const cardData = {
      state: POSTCARD_STATE.SEND, // Its always a send from here
      index,
      cardCaption: G.OMTsettings.postcardEvent.cardData.cardCaption,
    };
    card.fillCard(cardData, avatar);

    if (!card.signals.onClick.has(this._onButtonClick, this)) { // Hook up listener
      card.signals.onClick.add(this._onButtonClick, this);
    }

    return card;
  }

  /**
   * Check the UI using the util
   */
  _checkCard() {
    this._horizontalPositioner.checkUI({
      current: this._currentCard, // Current
      nextCard: this._currentCard.nextCard, // next card
      prevCard: this._currentCard.prevCard, // prev card
      left: this._arrows.left, // The left arrow
      right: this._arrows.right, // right arrow
      leftPosition: -(this._currentCard.renderedWidth / 2) - 40,
      rightPostion: (this._currentCard.renderedWidth / 2) + 40,
      container: this, // where to add
      prevPosition: this.prevCardPosition, // prev position
      nextPosition: this.nextCardPosition, // next postion
      closeBtn: this._closeBtn, // the close button
    });
  }

  /**
   * Positions and adds it on top
   */
  _positionCloseButton() {
    const scaleFactor = this._isLandscape ? this._gameScale : 1;

    this._closeBtn.x = -(this._closeBtn.width / 4) + (game.width / scaleFactor - this._closeBtn.width) / 2;
    this._closeBtn.y = (this._closeBtn.height / 4) + (this._closeBtn.height - game.height / scaleFactor) / 2;
    this.addChild(this._closeBtn);
  }

  /**
   * Draws the header
   */
  _drawHeader() {
    return EventPostcard_Util.drawPostcardFrame();
  }

  /**
   * Draws the close button
   */
  _drawCloseButton() {
    return new G.Button(0, 0, G.OMTsettings.postcardEvent.xButton || 'white_x', this.closeWindow.bind(this));
  }

  /**
   * Draws arrows
   * @returns {Object}
   */
  _drawArrows() {
    const leftArrow = this._drawArrow(false);
    const rightArrow = this._drawArrow(true);

    return {
      left: leftArrow,
      right: rightArrow,
    };
  }

  /**
   * Draws a singular arrow. If its not a right arrow, it flips it around.
   * @param {boolean} isRight
   * @param {G.Button}
   */
  _drawArrow(isRight) {
    const arrowGroup = new G.Button(0, 0, null);
    const arrow = G.makeImage(0, 0, 'eventPostcardArrow', 0.5, null);
    arrow.scale.set(1.25, 1.25);
    if (!isRight) {
      arrow.scale.x *= -1;
    }

    arrowGroup.addChild(arrow);

    return arrowGroup;
  }

  /**
   * Displays the next card on the right. Hides the fill to avoid redrawing
   * @param {number} index
   * @returns {EventPostcard}
   */
  _displayNextCard(index) {
    const card = this._createCard(index);
    card.fillContainer.visible = false;

    card.x = this.nextCardPosition;
    this.addChild(card);

    this._currentCard.nextCard = card;
    card.prevCard = this._currentCard;

    return card;
  }

  /**
   * Draws the card, fills it, hides the fill, and sticks it to the left
   * @param {number} index
   * @returns {EventPostcard}
   */
  _displayPrevCard(index) {
    const card = this._createCard(index);
    card.fillContainer.visible = false;

    card.x = this.prevCardPosition;
    this.addChild(card);

    this._currentCard.prevCard = card;
    card.nextCard = this._currentCard;

    return card;
  }

  /**
   * Function for when the left button is clicked
   * (Taken from friendship chest)
   */
  onLeftClick(force = false) {
    if (!this._arrows.left.enabled && !force) { return; }
    if (this._currentCard.prevCard) { // If theres a previous card
      this._tweenCards(this._currentCard, false, this._afterLeftTween.bind(this));
    }
  }

  /**
   * Function called after the left tween is done
   */
  _afterLeftTween() {
    if (!this.game) { return; }
    // Tween it and...
    this._currentCard = this._currentCard.prevCard; // Fix links
    this._currentCard.nextCard.fillContainer.visible = false; // Hide the far card

    if (this._currentCard.index > 0 && !this._currentCard.prevCard) { // If theres supposed to be a previous card and its not in memory
      this._displayPrevCard(this._currentCard.index - 1); // Show it
      this._positionCloseButton(); // Keep close button on top
    } else {
      // eslint-disable-next-line no-lonely-if
      if (this._currentCard.prevCard) { // Bring the card over
        this._currentCard.prevCard.x = this.prevCardPosition;
        this._currentCard.prevCard.visible = true;
      }
    }
    this._cleanUpCardDistance(); // Clean up cards
    this._checkCard(); // Check for animations
  }

  /**
   * Function for when the right button is clicked
   * (Taken from friendship chest)
   */
  onRightClick(force = false) {
    if (!this._arrows.right.enabled && !force) { return; }
    if (this._currentCard.nextCard) { // If theres a next card
      this._tweenCards(this._currentCard, true, this._afterRightTween.bind(this));
    }
  }

  /**
   * Function is called after the right tween is done
   */
  _afterRightTween() {
    if (!this.game) { return; }
    // Tween it and...
    this._currentCard = this._currentCard.nextCard; // Fix links
    this._currentCard.prevCard.fillContainer.visible = false; // Hide the far card
    const cardCount = this.cardLimit;
    if (this._currentCard.index < cardCount && !this._currentCard.nextCard) { // If theres no card next in memory
      this._displayNextCard(this._currentCard.index + 1); // Make card
      this._positionCloseButton(); // Keep close button on top
    } else {
      // eslint-disable-next-line no-lonely-if
      if (this._currentCard.nextCard) { // Bring the card over
        this._currentCard.nextCard.x = this.nextCardPosition;
        this._currentCard.nextCard.visible = true;
      }
    }
    this._cleanUpCardDistance(); // Clean up cards
    this._checkCard(); // Check for animations
  }

  /**
   * Tweens the cards in the direction dictated by isRight.
   * Calls onComplete when finished tweening
   * Uses the util to do the tweening, but some complicated stuff is passed in.
   * @param {FriendshipChest_Card} current
   * @param {boolean} isRight
   * @param {function} onComplete
   */
  _tweenCards(current, isRight, onComplete) {
    const tweenTime = 200;
    const prev = current.prevCard;
    const next = current.nextCard;
    this._horizontalPositioner.tweenObjects({
      current,
      prev,
      next,
      objectDimension: this._cardDimensions,
      nextPosition: this.nextCardPosition,
      prevPosition: this.prevCardPosition,
      isRight,
      onComplete,
      tweenTime,
      onPrevRightStart: () => { // Do this function if we are tweening to the right
        prev.fillContainer.alpha = 0;
        prev.fillContainer.visible = true;
        prev.onResize();
        game.add.tween(prev.fillContainer) // Tween in the fill if its supposed to be in
          .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.In, true);
      },
      onPrevComplete: () => { // Do this function when tweening to the right finishes
        prev.fillContainer.visible = false;
      },
      onNextRightStart: () => { // Do this function if we are tweening to the right
        next.fillContainer.alpha = 0;
        next.fillContainer.visible = true;
        next.onResize();
        game.add.tween(next.fillContainer) // Tween in the fill
          .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.In, true);
      },
      onNextComplete: () => { // Do this function when tween to the right finishes
        next.fillContainer.visible = false;
      },
    });
  }

  /**
   * Looks at living cards and destroys them
   */
  async _clearAllCards() {
    let curCard = this._currentCard;
    const allCards = [];
    while (curCard.prevCard) {
      curCard = curCard.prevCard;
      allCards.push(curCard);
    }

    curCard = this._currentCard;
    while (curCard.nextCard) {
      curCard = curCard.nextCard;
      allCards.push(curCard);
    }

    allCards.push(this._currentCard);

    allCards.forEach((card) => {
      card.destroy();
    });
  }

  /**
   * Checks distance of card from currentCard and destroys cards that are too far
   */
  async _cleanUpCardDistance() {
    let curCard = this._currentCard;
    let cardDistanceCounter = 0;
    while (cardDistanceCounter < this._maxCardsLinkedDistance) {
      curCard = curCard.prevCard;
      if (!curCard) {
        cardDistanceCounter = this._maxCardsLinkedDistance + 1;
      } else {
        cardDistanceCounter++;
      }
    }
    if (curCard && curCard !== this._currentCard) { // Juuuust in case
      curCard.nextCard.prevCard = null;
      curCard.destroy();
    }

    cardDistanceCounter = 0;
    curCard = this._currentCard;
    while (cardDistanceCounter < this._maxCardsLinkedDistance) {
      curCard = curCard.nextCard;
      if (!curCard) {
        cardDistanceCounter = this._maxCardsLinkedDistance + 1;
      } else {
        cardDistanceCounter++;
      }
    }
    if (curCard && curCard !== this._currentCard) {
      curCard.prevCard.nextCard = null;
      curCard.destroy();
    }
  }

  /**
   * Sends a message with the given postcard
   * @param {EventPostcard} postcard
   */
  async _onButtonClick(postcard) {
    // Something
    const result = await OMT.social.sendEventPostCard(postcard.index);
    if (result) {
      // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('usedPostcardShareBtn', 1);

      // Heart pulsing feedback anim
      const heart1 = G.makeImage(0, postcard.y, 'eventPostcard_PromoBg', 0.5, null);
      const heart2 = G.makeImage(0, postcard.y, 'eventPostcard_PromoBg', 0.5, null);
      heart1.scale.set(0.01);
      heart2.scale.set(0.01);
      heart1.visible = false;
      heart2.visible = false;
      postcard.addChild(heart1);
      postcard.addChild(heart2);

      const tweenTime = 1000;
      game.add.tween(heart1)
        .to({ alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true, tweenTime / 2);
      const tw1 = game.add.tween(heart1.scale)
        .to({ x: 1.5, y: 1.5 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      tw1.onStart.addOnce(() => {
        heart1.visible = true;
      });
      game.add.tween(heart1)
        .to({ alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true, tweenTime * 0.7);
      const tw2 = game.add.tween(heart1.scale)
        .to({ x: 1.5, y: 1.5 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true, tweenTime * 0.2);
      tw2.onStart.addOnce(() => {
        heart2.visible = true;
      });
      tw1.onComplete.addOnce(() => {
        heart1.visible = false;
        if (heart1.parent) {
          heart1.destroy();
        }
      });
      tw2.onComplete.addOnce(() => {
        heart2.visible = false;
        if (heart2.parent) {
          heart2.destroy();
        }
      });
    }
  }
}

/**
 * Global reference given to Window_unlockPostcard
 */
// create global references
// if (!window.Windows) window.Windows = {};
// Windows.eventPostcard = Window_eventPostcard;
