G.NotificationBubble = function(x, y) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);

  this.amount = 0;
  this.important = false;

  this.bg = G.makeImage(0, 0, "reddot", 0.5, this);
  this.amountTxt = new G.Text(1, 4, "0", {
    style: "font-white",
    fontSize: 25,
  }, 0.5);
  this.add(this.amountTxt);
  this.alpha = 0;
};

G.NotificationBubble.prototype = Object.create(Phaser.Group.prototype);

G.NotificationBubble.prototype.setImportant = function(val) {
  if (this.important === val) return;

  this.important = val;

  if (this.important) {
    this.tweenAlpha(1);
  } else if (this.amount === 0) {
    this.tweenAlpha(0);
  }

  this.setTextAmount(this.amount);
}

G.NotificationBubble.prototype.addNotification = function() {
  this.amount++;

  this.tweenAlpha(1);
  this.setTextAmount(this.amount);
};

G.NotificationBubble.prototype.removeNotification = function() {
  this.amount = Math.max(this.amount-1, 0);

  if (this.amount == 0) {
    this.tweenAlpha(0);
  }

  this.setTextAmount(this.amount);
};

G.NotificationBubble.prototype.tweenAlpha = function(newAlpha) {
  if (this.alpha === newAlpha) return;

  if (this.alphaTween) {
    this.alphaTween.stop();
    this.alphaTween = null;
  }

  this.alphaTween = game.add.tween(this) 
    .to({alpha:  newAlpha}, 300, Phaser.Easing.Sinusoidal.Out, true);
};

G.NotificationBubble.prototype.setTextAmount = function(amount) {
  if (this.important) {
    this.amountTxt.setText("!");
  } else {
    if (amount == 0) {
      this.amountTxt.setText("");
    } else {
      this.amountTxt.setText(amount.toString());
    }
  }

  if (this.scaleTween) {
    this.scaleTween.stop();
    this.scaleTween = null;
  }

  this.scale.setTo(1);
  this.scaleTween = game.add.tween(this.scale)
    .to({x: 1.2, y: 1.2}, 300, Phaser.Easing.Sinusoidal.Out, true, 0, 0, true);

};