/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import EventPostcardAssetManager from '../../Elements/Windows/eventPostcard/EventPostcardAssetManager';
import { FORTUNE_COOLDOWN_KEY } from './dataTracking/fortuneCookie/FortuneCookieDataManager';
import TargetedOfferDataManager from './dataTracking/targetedOffer/TargetedOfferDataManager';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { OMT_Debug } from '../../Debug/OMT_Debug';
import { UserDataBackup } from './cheats/UserDataBackup';
import { RMWHEEL_EPS, RMWHEEL_MODES } from '../../Elements/SpinningWheels/RealMoneyWheel/rmWheelEnums';
import { OMT_MilestoneTracking } from './milestoneTracking/OMT_MilestoneTracking';
import { createRealMoneySpinId, isConversionToEnum } from '../../Elements/SpinningWheels/RealMoneyWheel/RealMoneyWheelHelpers';
import { OMT_AssetLoader } from './OMT_AssetLoader';
import { RUNTIME_SPRITESHEET_IDS } from '@omt-components/Imaging/Spritesheets/RuntimeSpritesheetManager';
import { DATA_KEYS } from '../SaveState/SaveStateKeyMappings';
import { MILLISECONDS_IN_MIN, MILLISECONDS_IN_SEC } from '@omt-components/Utils/TimeUtil';
import { FIRST_SESSION_MILESTONE_CONFIG, PLAYTIME_MILESTONE_CONFIG } from './milestoneTracking/milestonesConfig';

const userDataBackup = new UserDataBackup();

export default class OMT_Cheats {
  /**
   * Note: Any new cheats should call this.setAsTester(true) if they successfully grant
   *       any advantage beyond what the game can normally give. When adding the cheat to
   *       the Cheat Codes Confluence document, the "Flags Player as Cheater" column
   *       should be marked "Yes"
   */

  /**
   * Sets the current player's "cheater" state. If you are a "cheater", all DDNA events will
   * be marked to indicate that (i.e. usedCheatCodes = 1). This cheat can be manually called by
   * testers to reset their "cheater" state.
   * @param {boolean} state Mark current player's account as a cheater
   */
  static setAsTester(state) {
    /*const dataCapture = DDNA.tracking.getDataCapture();
    const currentCheaterState = dataCapture.getPlayerCharacterizationParam(
      'usedCheatCodes',
    );
    const newCheaterState = state ? 1 : 0;

    dataCapture.setPlayerCharacterizationParam(
      'usedCheatCodes',
      newCheaterState,
    );

    // Warn of cheater state change if any
    if (currentCheaterState !== newCheaterState) {
      if (newCheaterState === 1) {
        OMT_Utils.stylizedLog(
          '[CHEAT] WARNING: A cheat was used! This account will be flagged as being a cheater.',
          '#FFA040',
        );
      } else {
        OMT_Utils.stylizedLog(
          '[CHEAT] WARNING: This account is no longer considered a cheater.',
          '#FFA040',
        );
      }
    }*/
  }

  /**
   * Checks cheater state
   * @returns {boolean}
   */
  static amIACheater() {
    const dataCapture = DDNA.tracking.getDataCapture();
    const cheaterState = dataCapture.getPlayerCharacterizationParam(
      'usedCheatCodes',
    );

    if (cheaterState === 1) {
      console.log('[CHEAT] Yes, this account is flagged as being a cheater.');
      return true;
    }

    console.log('[CHEAT] No, this account is not flagged as being a cheater.');
    return false;
  }

  /**
   * Adjusts which package of the given shop deals are shown
   * @param {number} day
   */
  static adjustShopDeal(day) {
    console.log('[CHEAT] Is not working anymore.');
  }

  /**
   * Shows specific shop packages for the special deal.
   * Currently known types are 'first', and 'special', and valentine keys
   * @param {string} type
   */
  static showSpecialDeals(type) {
    this.setAsTester(true);
    if (!type) {
      G.saveState.sessionData.shopSpecialType = null;
    } else {
      G.saveState.sessionData.shopSpecialType = type;
    }
    console.log(`[CHEAT] Shop will now display packages of type ${type}`);
  }

  /**
   * Activates the moves helpers on the next turn.
   */
  static activateMovesHelpers() {
    if (game.state.current === 'Game') {
      this.setAsTester(true);
      G.lvl.activateMovesHelperOverride = true;
      console.log('[CHEAT] Moves helper will show up on the next turn');
    } else {
      console.log('[CHEAT] This cheat can only be used in a level.');
    }
  }

  /**
   * Forcefully activates the eventPostcard event
   */
  static activateEventPostcard(start, end) {
    this.setAsTester(true);
    let range = null;
    const startIsGiven = start !== undefined;
    const endIsGiven = end !== undefined;
    if (startIsGiven || endIsGiven) {
      let featureStart = 0;
      let featureEnd = Infinity;

      if (startIsGiven) {
        featureStart = start;
      }
      if (endIsGiven) {
        featureEnd = end;
      }

      range = {
        start: featureStart,
        end: featureEnd,
      };
    }

    G.featureUnlock.eventPostcard = {
      enabled: true,
      range,
    };
    EventPostcardAssetManager.loadAssets();
  }

  /**
   * Activates the fortune cookie event by replacing the active days
   * 0 = Sunday, 6 = Saturday
   * Also removes the timeout cooldown
   * @param {Array<number>} daysActive
   */
  static async activateFortuneCookie(daysActive) {
    this.setAsTester(true);
    G.featureUnlock.fortuneCookie.activeDays = daysActive;
    if (G.saveState.getUserCooldownRemaining(FORTUNE_COOLDOWN_KEY.COOLDOWN, '') > 0) {
      G.saveState.setUserCooldown(FORTUNE_COOLDOWN_KEY.COOLDOWN, '', 0);
    }
    G.saveState.fortuneCookie_initData();
    const assetLoader = OMT_AssetLoader.getInstance();
    if (!assetLoader.areSecondaryImagesLoaded(['fortuneCookie'])) {
      console.log('Loading fortune cookie assets on demand');
      await assetLoader.loadSecondaryImages('fortuneCookie', true, RUNTIME_SPRITESHEET_IDS.FORTUNECOOKIE);
    }
    console.log('[CHEAT] Fortune Cookie activated for the given days');
  }

  /**
   * Sets the fortune cookie to be active within a range
   * @param {number} [start]
   * @param {number} [end]
   */
  static activateFortuneCookieRange(start, end) {
    this.setAsTester(true);
    G.featureUnlock.fortuneCookie.range = {
      start: start || 0,
      end: end || Infinity,
    };
    console.log(
      '[CHEAT] Fortune Cookie is now activated within the given range (but still factors in the activated days)',
    );
  }

  /**
   * go to a specific level
   * @param {number} lvl level #
   */
  static goToLevel(lvl) {
    this.setAsTester(true);
    G.saveState.data.levels = [];
    G.saveState.data.finishedTutorials = [];
    G.saveState.setBoosterArray([null, 30, 30, 30, 30, 30, 30, 30, 30]);
    for (let i = 0; i < lvl; i++) {
      G.saveState.data.levels.push(3);
    }
    // FIXME: the arguments are not valid
    const levelData = {
      lvlIndex: lvl - 1,
      debugMode: true,
    };
    game.state.start('Game', true, false, levelData);
  }

  /**
   * Unlock levels and gates up to and including given level
   * @param {number} lvl level #
   * @param {number} stars (optional) stars to award 1-3, default 1.
   * @param {boolean} save (optional) save progress to backend
   */
  static unlockLevelsUpTo(lvl, stars = 1, save = true) {
    this.setAsTester(true);

    G.saveState.debugStarsUpTo(Math.max(parseInt(lvl), 0), stars, true);
    if (save) G.saveState.save();
  }

  /**
   * win the current board
   * @param {number} points (optional)
   * @param {number} movesLeft (optional)
   */
  static winBoard(points = 0, movesLeft = 0) {
    this.setAsTester(true);
    if (game.state.current === 'Game') {
      const gameState = game.state.getCurrentState(); // game.js, hopefully
      const lvlDataManager = G.lvl;
      lvlDataManager.points = points;
      if (!movesLeft) {
        gameState.board.onBoardDeconstructed.dispatch(); // The old way
        return;
      }

      // The moves left and get points for having so many moves left over!
      lvlDataManager.moves = movesLeft;
      if (lvlDataManager.goalManager.POINTS) {
        lvlDataManager.goalManager.onPointsChange(
          lvlDataManager.goalManager.pointsTarget + 1,
        );
      } else {
        for (let i = 0; i < lvlDataManager.goalManager.tasks.length; i++) {
          const t = lvlDataManager.goalManager.tasks[i];
          t.uiAnimation = false;
          t.completed = true;
          t.target = 0;
          if (lvlDataManager.goalManager.areAllCompleted()) {
            lvlDataManager.goalManager.goalAchieved();
          }
        }
      }
      gameState.board.actionManager.removeAction();
    }
  }

  /**
   * lose the current board
   */
  static loseBoard() {
    this.setAsTester(true);
    if (game.state.current === 'Game') {
      G.lvl.moves = 1; // game._lvlDataManager
      console.log('[CHEAT] You will now lose in one more move');
    }
  }

  /**
   * add unlimited lives minutes
   * @param {number} minutes
   */
  static addUnlimitedLives(minutes) {
    this.setAsTester(true);
    G.saveState.addUnlimitedLivesTimeMin(minutes);
  }

  /**
   * player a tournament level by id, you will not be able to post scores / create a new tournament post
   * @param {string} levelId
   */
  static playTournamentLevelById(levelId) {
    this.setAsTester(true);
    if (!G.Helpers.levelDataMgr.getLevelById(levelId)) {
      console.warn(
        `[CHEAT] Could not set tournament level override to ${levelId}. No level with that id exists.`,
      );
      return;
    }
    OMT.platformTournaments.setLevelOverride(levelId);
    G.sb('pushWindow').dispatch(['tournament', 0]);
  }

  /**
   * Immediately refill prize wheel spins
   */
  static refillPrizeWheelSpins() {
    this.setAsTester(true);
    G.saveState.resetPrizeWheelSpinCount();
    G.saveState.setUserCooldown('prizeWheelSpins', '', 0);
  }

  /**
   * Immediately refill loss aversion wheel spins
   */
  static refillLossAversionWheelSpins() {
    this.setAsTester(true);
    G.saveState.resetLossAversionWheelSpinCount();
    G.saveState.setUserCooldown('lossAversionWheelSpins', '', 0);
  }

  static activateWorldMapPropDebug() {
    G.sb('WorldMapPropDebug').dispatch();
  }

  /**
   * set the save state to the offer state
   * @param {Object} offer
   */
  static setSaveStateToOffer(offer) {
    this.setAsTester(true);
    TargetedOfferDataManager.getInstance().setSaveStateToOffer(offer);
  }

  /**
   * show the specified timed targeted offer
   * @param {Object} offer
   */
  static showTimedTargetedOffer(offer) {
    this.setAsTester(true);
    const offerData = {
      oid: offer,
      et: Date.now() + 15 * MILLISECONDS_IN_MIN,
    };
    TargetedOfferDataManager.getInstance().showTimedPopupOfferWindow(offerData);
  }

  /**
   * show the specified one-time targeted offer
   * @param {Object} offer
   */
  static showOneTimeTargetedOffer(offer) {
    this.setAsTester(true);
    TargetedOfferDataManager.getInstance().showOneTimePopupOfferWindow(offer);
  }


  /**
   * Prints targeted offer data (current offer, queued offers, session number)
   */
  static debugTargetedOfferData() {
    console.log(TargetedOfferDataManager.getInstance().getTargetedOfferData());
  }

  /**
   * Clears current and all queued targeted offers
   */
  static clearTargetedOfferData() {
    TargetedOfferDataManager.getInstance().clearTargetedOfferData();
  }

  /**
   * Clears current targeted offer
   */
  static clearCurrentTargetedOffer() {
    this.setAsTester(true);
    TargetedOfferDataManager.getInstance().clearCurrentTargetedOffer();
  }

  /**
   * Queues up a new targeted offer
   * @param {string|Object} offer
   * @param {string} offer.oid offer id OR
   * @param {string} offer.pid product id
   * @param {number} offer.et expiry time
   * @param {boolean} highPriority
   */
  static enqueueTargetedOffer(offer, highPriority) {
    this.setAsTester(true);
    TargetedOfferDataManager.getInstance().enqueueTargetedOffer(offer, highPriority);
  }

  /**
   * Dequeue and show next targeted offer, even if the current offer has not expired
   */
  static dequeueAndShowTargetedOffer() {
    this.setAsTester(true);
    const dataMgr = TargetedOfferDataManager.getInstance();
    dataMgr.dequeueTargetedOffer();

    const currentOffer = dataMgr.getCurrentTargetedOffer();
    dataMgr.showTimedPopupOfferWindow(currentOffer);

    // If on saga map, show the button back to the window
    if (game.state.current === 'World') {
      G.sb('showTimedTargetedOfferButton').dispatch(1000);
    }
  }

  /**
   * Sets the number of sessions since the last targeted offer was offered
   * @param {number} number
   */
  static setSessionsSinceLastTargetedOffer(number) {
    this.setAsTester(true);
    TargetedOfferDataManager.getInstance().setSessionNumber(number);
  }

  /**
   * Override bot subscription status to false independent of FB setting
   * @param {boolean} override
   */
  static forceBotsUnsubscribed(override) {
    OMT.notifications._forceBotsUnsubscribed = override;
    const status = OMT.notifications._canUseBotService() ? 'ON' : 'OFF';
    if (override) {
      console.log(
        `[CHEAT] Bot unsubscribe override is now ON. Bot subscription status is now ${status}.`,
      );
    } else {
      console.log(
        `[CHEAT] Bot unsubscribe override is now OFF. Bot subscription status is now ${status}.`,
      );
    }
  }

  /**
   * Print list of all scheduled bot messages
   * Gets local copy of schedule if "true" is passed,
   * Gets schedule from game backend if "false" is passed
   * @param {boolean} local
   */
  static async printMessageSchedule(local) {
    if (local) {
      console.log(OMT.notifications._localMessages);
    } else {
      console.log(await OMT.notifications.findGameTriggeredMessages('', true));
    }
    return true;
  }

  /**
   * Manually schedule a bot message
   * @param {string} recipientId pass an empty string ('') to send to self
   * @param {string} messageType see OMT_GameMessageConfig.js for details
   * @param {number} delay in seconds. must be at least 1
   * @param {boolean} force if true, always schedule the message, regardless of priority
   */
  static scheduleBotMessage(recipientId, messageType, delay, force) {
    this.setAsTester(true);
    if (recipientId === '') {
      recipientId = OMT.envData.settings.user.userId;
    }
    OMT.notifications.scheduleGameTriggeredMessage(
      recipientId,
      messageType,
      delay,
      force,
    );
  }

  /**
   * Clear all scheduled bot messages
   */
  static unscheduleAllScheduledBotMessages() {
    this.setAsTester(true);
    OMT.notifications.unscheduleAllGameTriggeredMessages('bot');
  }

  /**
   * Activate Impatient Mode for bot message scheduler
   * @param {boolean} status
   */
  static setMessageSchedulerImpatientMode(status) {
    this.setAsTester(true);
    OMT.notifications._impatientMode = status;
  }

  /**
   * Resets real money replacement wheel cooldown
   */
  static resetRealMoneyWheelCooldown() {
    this.setAsTester(true);
    G.saveState.setUserCooldown('realMoneyWheel', '', 0);
  }

  /**
   * Resets real money wheel conversion status
   */
  static resetRealMoneyWheelConversionStatus() {
    this.setAsTester(true);
    const dataCapture = DDNA.tracking.getDataCapture();
    dataCapture.setPlayerCharacterizationParam(
      'purchasedReplacementConversionMoneyWheel',
      0,
      false,
    );
    dataCapture.setPlayerCharacterizationParam(
      'purchasedReplacementHighValueWheel',
      0,
      false,
    );
    dataCapture.setPlayerCharacterizationParam(
      'purchasedHelperConversionMoneyWheel',
      0,
      false,
    );
    dataCapture.setPlayerCharacterizationParam(
      'purchasedHelperHighValueWheel',
      0,
      false,
    );
    dataCapture.setPlayerCharacterizationParam(
      'purchasedTargetedOfferConversionMoneyWheel',
      0,
      false,
    );
    dataCapture.setPlayerCharacterizationParam(
      'purchasedTargetedOfferHighValueWheel',
      0,
      false,
    );
    dataCapture.setPlayerCharacterizationParam(
      'purchasedPayloadConversionMoneyWheel',
      0,
      false,
    );
    dataCapture.setPlayerCharacterizationParam(
      'purchasedPayloadHighValueWheel',
      0,
      true,
    );
    console.log('[CHEAT] Conversion status reset');
  }

  /**
   * Change real money replacement wheel to appearance odds
   */
  static setRealMoneyReplacementAppearance(chance) {
    this.setAsTester(true);
    G.json.settings.realMoneyPrizeWheel.appearanceChance = [chance];
    console.log(
      `[CHEAT] Real money replacement wheel appearance rate set to ${chance}%`,
    );
  }

  /**
   * Change real money helper wheel to appearance odds
   */
  static setRealMoneyHelperAppearance(chance) {
    this.setAsTester(true);
    G.json.settings.realMoneyHelperWheel.appearanceChance = chance;
    console.log(
      `[CHEAT] Real money helper wheel appearance rate set to ${chance}%`,
    );
  }

  /**
   * Force the real money helper wheel to appear
   */
  static forceRealMoneyHelperAppearance() {
    if (game.state.current === 'Game') {
      this.setAsTester(true);
      G.lvl.activateRealMoneyHelperWheelOverride = true;
      console.log('[CHEAT] Helpers will show up on the next turn');
    } else {
      console.log('[CHEAT] This cheat can only be used in a level.');
    }
  }

  /**
   * Change real money targeted offer wheel to appearance odds
   */
  static setRealMoneyTargetedOfferAppearance(chance) {
    G.json.settings.realMoneyTargetedOfferWheel.appearanceChance = chance;
    console.log(
      `Real money targeted offer wheel appearance rate set to ${chance}%`,
    );
  }

  /**
   * Push real money targeted offer wheel to appear
   */
  static forceRealMoneyTargetedOfferWheelAppearance() {
    G.sb('pushWindow').dispatch(['realMoneyWheel', {
      entryPoint: RMWHEEL_EPS.TargetedOffer,
      predeterminedPrize: -1,
      freeSpin: false,
      worldState: game.state.current === 'World',
    }], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
    this.setAsTester(true);
  }

  /**
   * Add an unconsumed real money spin
   * @param {RMWHEEL_EPS} entryPoint
   * @param {number} prizeIndex
   * @param {RMWHEEL_MODES} conversionState
   * @param {boolean} isFree
   * @param {boolean} wasPaidFor
   */
  static addUnconsumedRealMoneySpin(entryPoint, prizeIndex = -1, conversionState, isFree, wasPaidFor) {
    // Validate entryPoint and conversionState to prevent invalid values
    if (!Object.values(RMWHEEL_MODES).includes(conversionState)) {
      console.warn('[CHEAT] addUnconsumedRealMoneySpin error: invalid conversionState');
      return;
    }
    if (!Object.values(RMWHEEL_EPS).includes(entryPoint)) {
      console.warn('[CHEAT] addUnconsumedRealMoneySpin error: invalid entryPoint');
      return;
    }

    const spinId = createRealMoneySpinId(`jaffles-${Date.now()}`, entryPoint, isFree, wasPaidFor);
    G.saveState.addPendingRealMoneySpin(conversionState, spinId);
    G.saveState.saveRealMoneySpinResult(spinId, prizeIndex);
    this.setAsTester(true);
  }

  /**
   * Removes all unconsumed real money spins
   */
  static clearUnconsumedRealMoneySpins() {
    G.saveState.resetPendingRealMoneyWheelSpins();
  }

  /**
   * Enables the special shop deals within the given range.
   * Days is defaulted to the whole week.
   * @param {number} start
   * @param {number} end
   * @param {Array<number>} days
   */
  static enableSpecialShopDeals(start, end, days = [0, 1, 2, 3, 4, 5, 6]) {
    this.setAsTester(true);
    let range = null;
    const startIsGiven = !(start === undefined || start === null);
    const endIsGiven = !(end === undefined || end === null);
    let featureStart = 0;
    let featureEnd = Infinity;

    if (startIsGiven) {
      featureStart = start;
    }
    if (endIsGiven) {
      featureEnd = end;
    }

    range = {
      start: featureStart,
      end: featureEnd,
    };

    G.featureUnlock.specialShopDeal = {
      enabled: true,
      activeDays: days,
      range,
    };
    console.log('[CHEAT] Special Shop Deals are now activated');
  }

  /**
   * Sets the starting day for the weekend sale to a day between 0 (Sun) and 6 (Sat)
   * @param {number} day
   */
  static setWeekendDealAt(day) {
    this.setAsTester(true);
    G.featureUnlock.weekendShopDeal.startingDay = Math.max(0, Math.min(6, day));
    console.log('[CHEAT] Weekend deal will now show up for 24 hours on day', day);
  }

  /**
   * Reset all data for the current user
   */
  static resetData() {
    G.saveState.resetAllData();
  }

  /**
   * Sets the user's life to the given amount.
   * This change is effective immediately and the life is updated in the UI at the map
   * @param {number} amount The number of lives to set
   */
  static setLives(amount) {
    this.setAsTester(true);

    G.saveState.setLives(amount);
  }

  /**
   * Sets the user's coins to the given amount.
   * This change is effective immediately and the life is updated in the UI at the map
   * @param {number} amount The number of coins to set
   */
  static setCoins(amount) {
    this.setAsTester(true);

    G.saveState.setCoins(amount);
    G.sb('onCoinsChange').dispatch(amount);
  }

  /**
   * Sets the specified booster count to the amount
   * @param {number} boosterNum The number code for the booster. It can be looked up at the Confluence page
   * @param {number} amount The amount of boosters to set
   */
  static setBoosterCount(boosterNum, amount) {
    this.setAsTester(true);

    const savedAmount = G.saveState.getBoosterAmount(boosterNum);
    const diff = amount - savedAmount;
    G.saveState.changeBoosterAmount(boosterNum, diff, true);
  }

  /**
   * Sets all boosters to the specified amounts
   * @param {number[]} boosterArray The amounts of boosters to set
   */
  static setAllBoosters(boosterArray) {
    for (let i = 0; i < boosterArray.length; i++) {
      this.setBoosterCount(i, boosterArray[i]);
    }
  }

  /*
   * Sets event token drop rate
   * @param {number} chance 0-100
   */
  static setEventTokenDropRate(chance) {
    G.OMTsettings.tokenEvent.eventTokens.dropChance = chance;
    console.log(`[CHEAT] Event token drop rate set to ${chance}% for subsequent levels`);
    this.setAsTester(true);
  }

  /**
   * Starts a level as an event level
   * @param {number} levelNumber
   */
  static startEventLevel(levelNumber) {
    const lvlData = G.Helpers.levelDataMgr.getLevelByNumber(levelNumber);
    if (lvlData) {
      G.sb('pushWindow').dispatch(['eventLevel', lvlData]);
      this.setAsTester(true);
    } else {
      console.log('[CHEAT] Error: Cannot start event level: invalid level number');
    }
  }

  /**
   * Get daily reward.
   * The game needs to be restarted after using this function
   * @param {number} dayNumber The number of consecutive days (1-7)
   */
  static getDailyReward(dayNumber) {
    this.setAsTester(true);
    G.saveState.debugGiveDailyReward(dayNumber);
  }

  /**
   * Get daily reward as a new user
   */
  static getDailyRewardAsNewUser() {
    this.setAsTester(true);

    G.Helpers.dailyRewardMgr.reportVisit();
    const day = G.Helpers.dailyRewardMgr.getDay(0);
    G.sb('pushWindow').dispatch(['dailyReward', day, () => {}]);
  }

  /**
   * Change the language of in-game text
   * @param {string} code The language code to change the language.
   * To see the codes: http://www.lingoes.net/en/translator/langcode.htm
   */
  static changeLanguage(code) {
    OMT.language._primaryLanguage = code;
  }

  /**
   * Get the user ID
   */
  static getUserID() {
    return FBInstant.player.getID();
  }

  /**
   * Refresh the world
   */
  static refreshWorld() {
    this.setAsTester(true);

    game.state.start('World');
  }

  /**
   * Reset 3 hour gift of the player
   */
  static reset3HGift() {
    this.setAsTester(true);

    G.saveState.setUserCooldown('3h_gift', '', 0);
  }

  /**
   * Reset free spin
   */
  static resetFreeSpin() {
    this.setAsTester(true);

    G.saveState.data.freeSpin = true;
  }

  /**
   * Activates mystery gift with given streak length and expiration duration
   * @param {number} streakLength Length of the win streak
   * @param {*} expirationDuration Expiration duration in seconds
   */
  static activateMysteryGift(streakLength, expirationDuration) {
    this.setAsTester(true);

    G.saveState.mysteryGiftManager.debugActivateMysteryGift(streakLength, expirationDuration);
    G.saveState.resetLevelRetries(G.saveState.getLastPassedLevelNr());
  }

  /**
   * Readies up the mystery gift counter
   */
  static readyMysteryGift() {
    this.setAsTester(true);

    G.saveState.mysteryGiftManager.debugActivateMysteryGift(0, 0);
    G.saveState.resetLevelRetries(G.saveState.getLastPassedLevelNr());
  }

  /**
   * Shows mystery gift window
   */
  static showMysteryGift() {
    this.setAsTester(true);

    G.sb('pushWindow').dispatch('mysteryGiftStreakIncrese');
  }

  /**
   * Refreshes the daily missions and the world afterwards
   */
  static refreshDailyMissions() {
    this.setAsTester(true);

    G.dailyMissionsMgr._initNewDay();
    game.state.start('World');
  }

  /**
   * Shows daily missions reward window
   */
  static showDailyMissions() {
    this.setAsTester(true);

    G.sb('pushWindow').dispatch('dailyMissionMainReward');
  }

  /**
   * Shows daily missions reward window
   */
  static showAddToHomescreen() {
    this.setAsTester(true);

    G.sb('pushWindow').dispatch('newsHomeScreen');
  }

  /**
   * Scrolls the map to the designated level
   * @param {number} level The level to scroll to
   */
  static jumpToLevel(level) {
    game.state
      .getCurrentState()
      .map.panToLevelNode(level - 6, true)
      .then(() => {
        console.log(`[CHEAT] Scrolled to level ${level}`);
      });
  }

  /**
   * Sets a levels move count to the specified amount
   * @param {number} amount The amount of moves to set
   */
  static setMoves(amount) {
    this.setAsTester(true);

    G.lvl.moves = amount;
    G.sb('changeMoveNumber').dispatch(amount);
  }

  /**
   * Sets a levels point count to the specified amount
   * @param {number} amount The amount of points to set
   */
  static setPoints(amount) {
    this.setAsTester(true);

    G.lvl.points = amount;
    G.sb('onPointsChange').dispatch(amount);
  }

  /**
   * Activate the helper wheel
   */
  static activateHelpersWheel() {
    this.setAsTester(true);

    G.sb('pushWindow').dispatch(['requestHelpMessage', { fromHelpers: true }]);
  }

  /**
   * Completes the treasure hunt and gains the specified amount of gems
   * @param {number} amount The amount of gems you want to get
   */
  static setTreasureHuntGems(amount = 1) {
    this.setAsTester(true);
    if (game.state.current === 'Game') {
      G.saveState.treasureHuntManager.addToTempTokens(amount);
      game.state.getCurrentState().treasureHuntCounter.updateCounter();
      OMT.jaffles.winBoard(G.lvl.points, 1);
    }
  }

  /**
   * Resets everything about your treasure hunt data
   */
  static resetTreasureHuntData() {
    this.setAsTester(true);
    G.saveState.treasureHuntManager.resetEverything();
  }

  /**
   * Changes the treasure hunt character to the mascot, based on mascot index
   * @param {number} m Mascot index
   */
  static changeTreasureHuntCharacter(m) {
    this.setAsTester(true);
    const mascotList = G.OMTsettings.treasureHuntSuper.mascotOrder;
    const mascot = m % mascotList.length;
    if (Number.isFinite(mascot)) {
      G.saveState.treasureHuntManager.changeMascot(mascot);
      console.log(`[CHEAT] Mascot changed to ${mascotList[mascot]}`);
    }
  }

  /**
   * Display the fortune cookie selection window
   */
  static displayFortuneCookieSelectionWindow() {
    this.setAsTester(true);

    G.sb('pushWindow').dispatch([
      'fortuneCookie',
      { playOpenAnim: true, hideNotNow: false },
    ]);
  }

  /**
   * Set to In-app purchase mode
   */
  static setToIAP() {
    this.setAsTester(true);

    G._debugChangeToIAP();
  }

  /**
   * Set to non in-app purchase mode
   */
  static setToNONIAP() {
    this.setAsTester(true);

    G._debugChangeToNONIAP();
  }

  /**
   * Enable purchasing without spending
   * @param {boolean} state State to enable/disable test purchasing
   */
  static enablePurchaseWithoutSpending(state) {
    this.setAsTester(true);

    G.BuildEnvironment.testPurchaseSdk = !state;
    G.BuildEnvironment.production = !state;
  }

  /**
   * Get shop seed
   */
  static getShopSeed() {
    return G.saveState.sessionData.shopSeed;
  }

  /**
   * Get shop deal types
   */
  static getShopDealTypes() {
    return G.saveState.sessionData.shopSpecialType;
  }

  /**
   * Display popup offer
   */
  static displayPopupOffer() {
    console.log(
      '[CHEAT] This cheat is deprecated in favor of showTargetedOffer',
    );

    // Removed for now, will see if the function is needed.
    // this.setAsTester(true);
    // G.sb('pushWindow').dispatch('popupOffer');
  }

  /**
   * Display map cross promo
   */
  static displayMapXPromo() {
    this.setAsTester();

    G.saveState.data.crossPromoCounter = -1;
    G.saveState.save();
  }

  /**
   * Display the transaction log
   */
  static displayTransactionLog() {
    return OMT.transactionTracking.transactions;
  }

  /**
   * Change user tier
   * @param {1 | 2 | 3} tier Tier number
   */
  static changeUserTier(tier) {
    this.setAsTester(true);

    OMT.envData.settings.env.tier = tier;
  }

  /**
   * Force an ad failure
   * @param {boolean} state The state to set to force ad failure
   */
  static forceAdFailure(state) {
    this.setAsTester(true);

    OMT.ads.forceAdFail = state;
  }

  /**
   * Print scheduled messages
   */
  static printAllScheduledMessages() {
    return OMT.notifications._localMessages;
  }

  /**
   * Clear all scheduled messages of the specified type
   * @param {string} type The type of messages to clear
   */
  static clearAllScheduledMessages(type = 'bot') {
    this.setAsTester(true);

    OMT.notifications.unscheduleAllGameTriggeredMessages(type);
  }

  /**
   * Send messages immediately
   */
  static activateImpatientMode() {
    this.setAsTester(true);

    OMT.notifications._impatientMode = true;
  }

  /**
   * Schedule a message with the given parameters
   * @param {string} recipientId Facebook ID of the recipient player
   * @param {string} messageType Type of message
   * @param {number} delay Time to wait before sending the message (in seconds)
   * @param {object} additionalParams Additional parameters need for app-to-user notifications
   * @param {boolean} force Schedule the message regardless of its priority
   */
  static scheduleMessages(
    recipientId,
    messageType,
    delay,
    additionalParams,
    force,
  ) {
    this.setAsTester(true);

    OMT.notifications.scheduleGameTriggeredMessage(
      recipientId,
      messageType,
      delay,
      additionalParams,
      force,
    );
  }

  /**
   * Find all scheduled messages
   */
  static findAllScheduledMessages() {
    return OMT.notifications.findGameTriggeredMessages('', true);
  }

  /*
   * Set amount of event tokens collected for current level
   * @param {*} amount
   */
  static setLevelEventTokens(amount) {
    if (game.state.current === 'Game') {
      G.saveState.tokenEventManager._levelTokensCollected = amount;
      game.state.getCurrentState().eventTokenCounter.updateCount(null);
      this.setAsTester(true);
    } else {
      console.log('[CHEAT] This cheat can only be used in a level.');
    }
  }

  /**
   * Reset all collected event tokens and reward tier level
   */
  static resetTotalEventTokens() {
    G.saveState.tokenEventManager.resetTotalEventTokens();
  }

  /**
   * Shows/hides user data backup tool
   * @param {boolean} visible
   */
  static showUserDataBackupTool(visible) {
    if (visible) userDataBackup.initOverlay();
    else userDataBackup.removeOverlay();
  }

  /**
   * Sets the default debug mode in the save state and restarts the game
   * @param {number} mode 0 = disable, 1 = FPS overlay, 2 = performance monitor
   */
  static setDefaultDebugMode(mode) {
    G.saveState.setDebugMode(mode);
  }

  /**
   * Sets the current debug mode without restarting the game
   * Note: once the performance monitor is added to the game, it can't be removed
   * without restarting the game
   * @param {number} mode 0 = hide FPS overlay, 1 = show FPS overlay, 2 = show performance monitor
   */
  static overrideDebugMode(mode) {
    OMT_Debug.setDebugMode(mode);
  }

  /**
   * Manually sets the milestone tracker's start time
   * @param {number} time the new start time in milliseconds
   */
  static overrideMilestoneStartTrackingTime(time) {
    OMT.milestoneTracking.milestoneStatus.startTime = time;
    OMT.milestoneTracking.save();
  }

  /**
   * Prints milestone tracker status in console
   */
  static logMilestoneStatus() {
    const { milestoneStatus } = OMT.milestoneTracking;
    if (!milestoneStatus) {
      console.log('No milestone status data found');
      return;
    }

    const { playtimeMilestonesCompleted, startTime, milestones } = milestoneStatus;
    const startTimeFormatted = new Date(startTime);

    console.log(`Start time: ${startTimeFormatted}`);
    console.log(`User type: ${milestoneStatus.userType}`);
    console.log(`Is eligible: ${OMT.milestoneTracking._isEligible}`);
    console.log(`Current time period: ${OMT.milestoneTracking._currentTimePeriod} hours`);

    // Playtime
    console.log('Playtime\n--------');
    console.log(`Playtime elapsed: ${(Date.now() - startTime) / MILLISECONDS_IN_SEC}s`);
    const { playtimePeriods } = PLAYTIME_MILESTONE_CONFIG;
    const nextPlaytimeMilestone = playtimeMilestonesCompleted < playtimePeriods.length
      ? `${playtimePeriods[playtimeMilestonesCompleted]}s`
      : 'All Completed';
    console.log(`Next milestone: ${nextPlaytimeMilestone}`);

    // First Session
    console.log('First Session\n--------');
    const { sessions, sessionMilestonesCompleted } = milestoneStatus;
    const levelsBeaten = G.saveState.getLastPassedLevelNr();
    console.log(`Current session: ${sessions}`);
    console.log(`Levels beaten: ${levelsBeaten}`);
    const { levels } = FIRST_SESSION_MILESTONE_CONFIG;

    let nextFirstSessionMilestone;

    if (sessions > 1) {
      nextFirstSessionMilestone = 'Not in first session';
    } else if (sessionMilestonesCompleted < levels.length) {
      nextFirstSessionMilestone = `${levels[sessionMilestonesCompleted]} levels`;
    } else {
      nextFirstSessionMilestone = 'All Completed';
    }

    console.log(`Next milestone: ${nextFirstSessionMilestone}`);

    for (const type in milestones) {
      if (Object.prototype.hasOwnProperty.call(milestones, type)) {
        console.log(`${type}\n${'-'.repeat(type.length)}`);

        // Determine which metric to use
        let metric;

        switch (type) {
          case 'levels':
            metric = milestoneStatus.levelAttempts;
            break;

          case 'tutorial':
            metric = G.saveState.getLastPassedLevelNr();
            break;

          case 'sessions':
            metric = milestoneStatus.sessions;
            break;

          case 'rewardedAds':
            metric = milestoneStatus.adsWatched;
            break;

          default:
            metric = null;
        }

        console.log(`Current value: ${metric}`);
        console.table(milestones[type]);
      }
    }
  }

  /**
   * Resets milestone tracker
   */
  static resetMilestoneStatus() {
    OMT.milestoneTracking._milestoneStatus = OMT_MilestoneTracking.getDefaultValues();
    OMT.milestoneTracking.save();
    OMT.milestoneTracking.checkEligibilityByTime();
  }

  /**
   * Resets all tutorials for the villains
   */
  static resetVillainsTutorialStatus() {
    this.setAsTester(true);
    G.saveState.villainsDataManager.resetTutorialStatus();
    G.saveState.data.dailyChallengePromoSeen = false;
    G.saveState.save();
  }

  /**
   * Completes all tutorials for the villains
   */
  static completeVillainsTutorial(onlyHard) {
    this.setAsTester(true);

    const hard = {
      pre_win_saga_map: true,
      level_window: true,
      game: true,
      post_win_saga_map: true,
    };
    const superHard = {
      pre_win_saga_map: true,
      level_window: true,
      game: true,
      post_win_saga_map: true,
    };
    G.saveState.villainsDataManager.dataReference.tutorialStatus.hard = hard;
    if (!onlyHard) {
      G.saveState.villainsDataManager.dataReference.tutorialStatus.super_hard = superHard;
    }
    G.saveState.villainsDataManager.save();
  }

  /*
   * Modifies the token event range.
   * @param {number} start = 0
   * @param {number} end = infinity
   */
  static setTokenEventRange(start, end) {
    this.setAsTester(true);
    G.featureUnlock.tokenEvent.range.start = start || 0;
    G.featureUnlock.tokenEvent.range.end = end || Infinity;
  }

  /**
   * Sets ABC group tyoe as the type given.
   * Case sensative and verifies against all possible keys
   * @param {String} type
   */
  static setABCGroup(type) {
    this.setAsTester(true);
    const jsonData = G.json['configs/userABC'];
    if (jsonData) {
      const keys = Object.keys(jsonData);
      if (keys.indexOf(type) > -1) {
        G.saveState.sessionData.ABCGroup = type;
        // DDNA.tracking.getDataCapture().changeABCTestingGroup(type);
        G.sb('onStateChange').dispatch('World');
      }
    }
  }

  /**
   * Sets the number of ads (formerly sessions) since the last time the no ads popup appeared
   * @param {number} number
   */
  static setAdsSinceLastNoAdsPopup(number) {
    G.saveState.setAdsSinceLastNoAdsPopup(number);
    console.log(`[CHEAT] Ads since last no ads popups: ${number}`);
  }

  /**
   * Lists the system parameters from OMT_SystemInfo in the console
   */
  static displaySystemInfo() {
    console.log(OMT.systemInfo.systemParams);
  }

  /**
   * Manually triggers an error on Sentry
   * @param {string} message the description of the test error. Defaults to 'Sentry error test' if none is given
   */
  static testSentryError(message = 'Sentry error test') {
    // G.Utils.SentryLog.logError(message);
  }

  /**
   * Adjusts all shop tags with the given string `t`
   * Enter null to reset
   * @param {string} t
   */
  static adjustShopTag(t) {
    this.setAsTester(true);
    G.saveState.sessionData.shopTagText = t;
  }

  /**
   * Changes the leaderboard slug of the treasure hunt to the given leaderboard slug
   * @param {string} leaderboardSlug
   * @returns {null}
   */
  static async enterDebugTreasureHunt(leaderboardSlug) {
    this.setAsTester(true);

    if (G.BuildEnvironment.production) {
      console.warn('Not allowed to enter debug treasure hunt.');
      return;
    }

    if (OMT.feature.isTreasureHuntOn(false, false)) {
      if (!leaderboardSlug) {
        leaderboardSlug = null;
      }
      G.saveState.treasureHuntManager.debugLeaderboardSlug = leaderboardSlug;
      G.saveState.treasureHuntManager.leaderboardSlug = leaderboardSlug;
      G.saveState.treasureHuntManager.save();
      console.log('Treasure Hunt is now switching... Please wait.');
      await G.saveState.treasureHuntManager.recalculateTime(true, true);
      console.log(`Leaderboard slug is now ${leaderboardSlug}`);
      console.log(`Treasure hunt is currently ${G.saveState.treasureHuntManager.inActiveCycle ? '[ON]' : '[OFF]'}`);
    }
  }
}
