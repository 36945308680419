import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';
import FortuneCookieDataManager from '../../Services/OMT/dataTracking/fortuneCookie/FortuneCookieDataManager';
import ArrayUtil from '@omt-components/Utils/ArrayUtil';
import { OMT_AssetLoader } from '../../Services/OMT/OMT_AssetLoader';
// import { GameScaleController } from '../../States/Scaling/GameScaleController';
// import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

export class Window_FortuneCookie extends Window {
  /**
   *Creates an instance of Window_FortuneCookie.
   * @param {*} parent The parent this window should be added to
   * @param {*} config The config of the window
   * @memberof Window_FortuneCookie
   */
  constructor(parent, config) {
    super(parent);
    this.NOFADEINOUT = true;
    this.config = config;
    this.fortuneCookieDataManager = FortuneCookieDataManager.getInstance();
    // how long the hand rests on a given option
    this.handMoveInterval = 140;
    // a timer for the previous interval
    this.handMoveTimer = 0;
    // whether to start the hand timer.
    this.startHandTimer = false;
    // the option the hand is currently pointing to; indexes into this.fortunes
    this.currentHandIndex = 0;
    // how many times we can be dismissed before we disable ourselves entirely.
    this.dismissLimit = 3;
    // if we are disabled entirely, how many days are we disabled for
    this.dismissTimeInDays = 3;
    // this is an object of categories mapped to their messages
    this.msgData = G.json.fortuneCookieMessages;
    // getting a list of categories
    this.categories = Object.keys(this.msgData.categories);
    // a reference to the tween that shakes the fortune when the hand goes over it
    this.currentFortuneShake = null;
    // flag to indicate whether a fortune has been opened or not
    this.fortuneOpened = false;
    // coin rewards
    this.coinRewards = G.OMTsettings.elements.Window_FortuneCookie.coinRewards;
    // the colors for the fortune glow, based on category
    this.glowColors = G.OMTsettings.elements.Window_FortuneCookie.glowColors;
    // element layout properties
    this.elementLayouts = G.OMTsettings.elements.Window_FortuneCookie.elementLayouts;

    this._init();
  }

  /**
   * Async init call for the await
   */
  async _init() {
    if (!this._waitingIcon) this._waitingIcon = new G.WaitingIcon(0, 0, 'waiting_icon_white'); // Loading...
    this.parent.addChild(this._waitingIcon);
    const assetLoader = OMT_AssetLoader.getInstance();
    const result = await assetLoader.waitOnSecondaryImages(['fortuneCookie']);
    if (this._waitingIcon) {
      if (this._waitingIcon.parent) {
        this._waitingIcon.parent.removeChild(this._waitingIcon);
      }
      this._waitingIcon.destroy();
    }
    this._waitingIcon = null;
    if (!result) {
      this.closeWindow();
    }

    this.initCookieSelectScreen();
    this.initShareScreen();

    // figuring out the entry point
    if (!this.config || this.config.playOpenAnim) {
      // without this delay the ui icon acts strangely before tweening.
      _.delay(() => { this.playOpenAnim(); }, 300);
    }
    if (this.config && this.config.showMessage) {
      this.showMessage(this.config.categoryData, this.config.msgData);
    }

    this.onResize();
    this.onResizeSB = G.sb('onScreenResize').add(this.onResize, this);
  }

  initCookieSelectScreen() {
    this.coinReward = ArrayUtil.getRandomElement(this.coinRewards);
    this.coinRewardMsg = new Phaser.Group(game, this);
    this.coinRewardMsg.coin = G.makeImage(0, 0, 'coin_1', 0.5, this.coinRewardMsg);
    // we put spaces around the text so the shadow has space to render
    this.coinRewardMsg.text = new G.Text(0, 0, `+${this.coinReward}`, {
      style: 'font-white',
      fontSize: '48px',
    }, 0.5);
    this.coinRewardMsg.coin.x = this.coinRewardMsg.text.width / 2 + this.coinRewardMsg.coin.width / 2;
    this.coinRewardMsg.add(this.coinRewardMsg.text);
    this.coinRewardMsg.y = 300;
    this.coinRewardMsg.x -= this.coinRewardMsg.coin.x / 2;
    this.coinRewardMsg.alpha = 0;
    if (!this.config.coinReward) this.coinRewardMsg.visible = false;

    // setting up visual stuff
    this.messages = new Phaser.Group(G.game, this);
    this.hand = G.makeImage(0, 0, 'tut_hand', 0.5, this.messages);
    // makin the hand point
    game.add.tween(this.hand.anchor).to({ x: 0.4, y: 0.4 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    this.hand.alpha = 0;

    // the array for the three fortunes we show at the beginning
    this.fortunes = [];

    // for the cookie breaking particles
    this.emitter = new FxParticle();
    this.emitter.scale.set(2);
    this.add(this.emitter);

    // setting up the banner that appears with the three fortunes
    this.bannerGroup = new Phaser.Group(game, this);
    const banner = G.makeImage(0, 0, 'text-banner', 0.5, this);
    this.bannerGroup.add(banner);
    const headerText = new G.Text(0, -15, OMT.language.getText('You won a fortune cookie!'), {
      style: 'msg-purpleFontStyle',
      fontSize: '38px',
      fontWeight: 600,
    }, 0.5, 540, 420, false, 'center', true, true, true);
    this.bannerGroup.addChild(headerText);
    this.bannerGroup.addChild(new G.Text(0, 23, OMT.language.getText('Choose one from below:'), {
      style: 'msg-purpleFontStyle',
      fontSize: '28px',
      fontWeight: 600,
    }, 0.5, 520, 420, true, 'center'));

    banner.width = 640;
    this.bannerGroup.x = -640;
    this.bannerGroup.y = this._isLandscape
      ? -game.height / 2 + 50
      : -game.height / 2 + 220;
    this.bannerGroup.alpha = 0;

    this.notNowBtn = new G.Text(0, 0, OMT.language.getText('Not Now'), {
      style: 'font-blue',
      fontSize: '32px',
      fontWeight: 600,
    }, 0.5, 420, 420, true, 'center');

    this.notNowBtn.y = this._isLandscape
      ? game.height / 2 + 100
      : game.height / 2 - 50;

    this.add(this.notNowBtn);

    this.notNowBtn.inputEnabled = true;
    this.notNowBtn.input.useHandCursor = true;
    this.notNowBtn.visible = false;
  }

  /**
   * Initializes share window and banner
   */
  initShareScreen() {
    this.shareWindow = new Phaser.Group(game, this);
    window.shareWindow = this.shareWindow;
    // for some reason when this is activated on the game board its way lower down than on the world map
    if (game.state.getCurrentState().key === 'World') this.shareWindow.y -= G.WindowMgr.Constants.WorldVerticalOffset;

    // Banner Group
    // NOTE: Banner
    const { bannerTextOffset } = this.elementLayouts;
    this.shareWindow.shareBannerGroup = new Phaser.Group(game, this.shareWindow);
    this.shareWindow.banner = G.makeImage(0, 0, 'fortuneCookie_banner', 0.5, this.shareWindow.shareBannerGroup);
    this.shareWindow.banner.scale.set(0.42);
    this.shareWindow.bannerText = new G.Text(bannerTextOffset.x, bannerTextOffset.y, OMT.language.getText('Fortune Cookie'), {
      style: 'msg-white',
      fontSize: '44px',
      fontWeight: 600,
      shadow: [2, 4, 'rgba(0,0,0,0.3)', 0],
    }, 0.5, this.shareWindow.banner.width - 270, null, false, 'center', true, true, true);
    this.shareWindow.shareBannerGroup.add(this.shareWindow.bannerText);

    // Message Group
    this.shareWindow.msgGroup = new Phaser.Group(game, this.shareWindow);

    // NOTE: MsgBg
    this.shareWindow.msgBG = G.makeImage(-5, 0, 'fortuneCookie_paper', 0.5, this.shareWindow.msgGroup);
    this.shareWindow.msgBG.scale.set(0.5);
    this.shareWindow.bringChildToTop(this.shareWindow.shareBannerGroup);

    // NOTE: MsgText
    this.shareWindow.msgText = new G.Text(0, -10, '', {
      font: 'Verdana',
      style: 'font-white',
      fontSize: '26px',
      fontWeight: 600,
      fill: '#B0001D',
      lineSpacing: -10,
    }, 0.5, this.shareWindow.msgBG.width - 220, this.shareWindow.msgBG.height - 20, true, 'center', true, true, true);
    this.shareWindow.msgText.setShadow(0, 0, 0);
    this.shareWindow.msgText.angle = -1;
    this.shareWindow.msgGroup.add(this.shareWindow.msgText);

    const closeBtnY = this._isLandscape ? -15 : -55;
    this.shareWindow.closeBtn = new G.Button(265, closeBtnY, 'btn_x', () => {
      this.closeWindow();
    }, this.shareWindow);
    this.buttonsList.push(this.shareWindow.closeBtn);
    this.shareWindow.shareBannerGroup.add(this.shareWindow.closeBtn);

    this.shareWindow.scale.set(0);
  }

  /**
   * Initializes message image, avatar, message icons, and share button
   * @param {Object} categoryData
   * @param {Object} messageData
   */
  initPostChoiceElements(categoryData, messageData) {
    this.shareWindow.msgImgGroup = new Phaser.Group(game, this.shareWindow);

    // NOTE: Avatar
    // this.avatarLayout = G.OMTsettings.elements.Window_FortuneCookie.avatarLayout[categoryData.category.toLowerCase()];
    // const avatar = G.makeExtImage(0, 0, OMT.envData.settings.user.avatar, 'avatar_m', 0.5, this.shareWindow.msgImgGroup, false, (img) => {
    //   img.x = this.avatarLayout.x;
    //   img.y = this.avatarLayout.y;
    //   img.width = this.avatarLayout.width;
    //   img.height = this.avatarLayout.height;
    //   img.angle = this.avatarLayout.angle;
    // });
    // window.avatar = avatar;

    // NOTE: msgImg
    G.makeImage(0, 0, `Msg_FortuneCookie_${categoryData.category}`, 0.5, this.shareWindow.msgImgGroup);

    // NOTE: msgIcon
    const msgIconLeft = G.makeImage(-230, -5, `fortuneCookie-category-${categoryData.category.toLowerCase()}`, 0.5, this.shareWindow);
    const msgIconRight = G.makeImage(230, -15, `fortuneCookie-category-${categoryData.category.toLowerCase()}`, 0.5, this.shareWindow);
    msgIconLeft.scale.setTo(2);
    msgIconRight.scale.setTo(2);
    this.shareWindow.msgGroup.add(msgIconLeft);
    this.shareWindow.msgGroup.add(msgIconRight);
    // window.msgIconLeft = msgIconLeft;
    // window.msgIconRight = msgIconRight;

    // NOTE: Share button
    // this.shareBtn = new OMT_UI_SquareButton(0, 0, {
    //   button: {
    //     tint: BUTTONCOLOURS.green,
    //     dimensions: {
    //       width: 315,
    //       height: 130,
    //     },
    //   },
    //   text: {
    //     string: OMT.language.getText('Share'),
    //     textStyle: {
    //       style: 'gifts-shareTickbox',
    //       fontSize: 70,
    //       shadow: [2, 4, 'rgba(0,0,0,0.3)', 0],
    //     },
    //   },
    //   options: {
    //     clickFunction: {
    //       onClick: () => {
    //         // use msg object with id and category object with id
    //         this.fortuneCookieDataManager.incrementShared();
    //         OMT.social.shareFortuneCookieMsg(this.closeWindow.bind(this), `Msg_FortuneCookie_${categoryData.category}`, messageData, categoryData);
    //         // DDNA.tracking.socialActionEvent('FORTUNE_COOKIE', '', '', '');
    //       },
    //       disableAfterClick: true,
    //     },
    //   },
    // });
    // this.shareWindow.add(this.shareBtn);
    // window.shareBtn = this.shareBtn;
    // this.shareBtn.scale.set(0);

    this.fortuneOpened = true;
    this.onResize();
  }

  /**
   * Window resize handler
   */
  onResize() {
    const { lerp } = G.Utils;
    const { windowHeight, yPos, scaling } = this.elementLayouts;
    const lerpWeight = Math.max(Math.min((game.height - windowHeight.min) / (windowHeight.max - windowHeight.min), 1), 0);
    this.shareWindow.shareBannerGroup.y = lerp(yPos.shareBannerGroup.min, yPos.shareBannerGroup.max, lerpWeight);
    this.shareWindow.msgGroup.y = lerp(yPos.msgGroup.min, yPos.msgGroup.max, lerpWeight);
    this.shareWindow.msgGroup.scale.setTo(lerp(scaling.msgGroup.min, scaling.msgGroup.max, lerpWeight));

    if (this.fortuneOpened) {
      this.shareWindow.msgImgGroup.y = lerp(yPos.msgImgGroup.min, yPos.msgImgGroup.max, lerpWeight);
      this.shareWindow.msgImgGroup.scale.setTo(lerp(scaling.msgImgGroup.min, scaling.msgImgGroup.max, lerpWeight));
      // this.shareBtn.y = lerp(yPos.shareBtn.min, yPos.shareBtn.max, lerpWeight);
      if (this._coinBar) {
        this._coinBar.y = lerp(yPos.coinBar.min, yPos.coinBar.max, lerpWeight);
      }
    }
  }

  /**
   * Runs every frame, mostly used for hand animation
   */
  update() {
    if (!this.emitter) return;
    this.emitter.update();
    // moving the hand between all three options
    if (this.startHandTimer) {
      this.handMoveTimer += G.deltaTime;
      if (this.handMoveTimer >= this.handMoveInterval) {
        this.handMoveTimer = 0;
        this.currentHandIndex++;
        if (this.currentHandIndex > 2) this.currentHandIndex = 0;
        const fortune = this.fortunes[this.currentHandIndex];
        this.currentFortuneShake = game.add.tween(fortune.pivot)
          .to({ x: -7 }, 80)
          .chain(game.add.tween(fortune.pivot)
            .to({ x: 7 }, 80)
            .chain(game.add.tween(fortune.pivot)
              .to({ x: 0, y: 0 }, 80)))
          .delay(400)
          .start();
      }
    }
    const pos = this.fortunes[this.currentHandIndex];
    if (pos) {
      this.hand.x = G.lerp(this.hand.x, pos.x + this.hand.width / 2, 0.1);
      this.hand.y = G.lerp(this.hand.y, pos.y + this.hand.height / 2, 0.1);
    }
  }

  closeWindow() {
    if (this.config.onClose) {
      this.config.onClose();
    }
    G.sb('fortuneCookieWindowClose').dispatch();
    super.closeWindow();
  }

  /**
   * This plays the animation of the single fortune cookie breaking apart.
   * @memberof Window_FortuneCookie
   */
  playOpenAnim() {
    this.hand.visible = false;
    // the cookie that will shake and break open
    let cookieOpener;
    if (this.config.uiIcon) {
      cookieOpener = this.config.uiIcon;
      // using a group because Window.toLocal gives NaN no matter what, lmao
      const iconGroup = new Phaser.Group(game, this);
      // the ui icon is in a completely different coordinate space, so we gotta convrt
      const pos = iconGroup.toLocal(cookieOpener.position, cookieOpener.parent);
      iconGroup.addChild(cookieOpener);
      cookieOpener.x = pos.x;
      cookieOpener.y = pos.y;

      // these are used for the bezier curve later; basically just so that we have like a swooping-down animation
      const m1 = { x: cookieOpener.x, y: 40 };
      const m2 = { x: 0, y: 40 };

      game.add.tween(cookieOpener)
        .to({ x: [cookieOpener.x, m1.x, m2.x, 0], y: [cookieOpener.y, m1.y, m2.y, 0] }, 700, Phaser.Easing.Sinusoidal.Out, true)
        .chain(this.makeShakeTween(cookieOpener))
        .interpolation((v, k) => Phaser.Math.bezierInterpolation(v, k));
      game.add.tween(cookieOpener.scale)
        .to({ x: 2, y: 2 }, 700, Phaser.Easing.Sinusoidal.Out, true);
    } else {
      cookieOpener = G.makeImage(0, 0, 'fortuneCookie', 0.5, this);
      const tween = this.makeShakeTween(cookieOpener);
      tween.delay(100).start();
    }
  }

  makeShakeTween(cookieOpener) {
    const shakeTween = game.add.tween(cookieOpener)
      .to({ x: -10 }, 100, Phaser.Easing.Linear.None, false, 0, 3, 3)
      .chain(game.add.tween(cookieOpener)
        .to({ x: 10 }, 100, Phaser.Easing.Linear.None, false))
      .delay(100);
    shakeTween.onComplete.add(() => {
      this.emitter.burstConcreteAnim(0, 0);
      cookieOpener.visible = false;
      this.hand.visible = true;
      this.showFortunes.bind(this)();
    });
    return shakeTween;
  }

  /**
   * Generates the fortunes with the random categories and animates them to their positions.
   */
  showFortunes() {
    const padding = 140;
    const totalWidth = 640 - padding * 2;
    const pickedNums = [];

    // Add disabled categories to pickedNums so they won't get picked
    const categoryConfig = G.OMTsettings.elements.Window_FortuneCookie.categories;
    for (const category in categoryConfig) {
      if (!categoryConfig[category].enabled) {
        pickedNums.push(categoryConfig[category].index);
      }
    }

    let lastTween;
    game.add.tween(this.coinRewardMsg).to({ alpha: 1 }, 300).start();
    for (let i = 0; i < 3; i++) {
      // bubble is a placeholder
      const categoryNumber = this.getRandomInRangeExclusive(0, this.categories.length - 1, pickedNums);
      pickedNums.push(categoryNumber);
      const category = this.categories[categoryNumber];

      const fortuneGroup = new Phaser.Group(G.game, this.messages);
      const glow = G.makeImage(-5, 0, 'fortuneCookie-glow', 0.5, fortuneGroup);
      glow.tint = this.glowColors[category.toLowerCase()];
      const shadow = G.makeImage(0, 2, 'fortuneCookie-shadow', 0.5, fortuneGroup);
      shadow.alpha = 0.5;
      const fortune = G.makeImage(0, 0, 'fortuneCookie', 0.5, fortuneGroup);

      // Tag
      const { tagSymbolOnly, tagPosition } = G.OMTsettings.elements.Window_FortuneCookie;
      if (!tagSymbolOnly) {
        G.makeImage(tagPosition.x, tagPosition.y, 'fortuneCookie-tag', 0.5, fortuneGroup);
      }
      G.makeImage(tagPosition.x, tagPosition.y, `fortuneCookie-category-${category.toLowerCase()}`, 0.5, fortuneGroup);
      fortuneGroup.fortune = fortune;
      this.fortunes.push(fortuneGroup);


      const dest = { x: -totalWidth / 2 + (i * (totalWidth / 2)), y: i === 1 ? -70 : 130 };

      // Saving to last tween so it can be modified outside of the loop
      lastTween = game.add.tween(fortuneGroup)
        .to(dest, 1200, Phaser.Easing.Exponential.Out, true);
      lastTween.onComplete.add(() => {
        this.startHandTimer = true;
        // we're enabling input here so that the fortunes can fully tween to their positions
        // before the user can interact.  otherwise, theres a chance the user could click the
        // cookie before it was done tweening, which could result in a slightly strange visual bug
        fortune.inputEnabled = true;
        fortune.input.useHandCursor = true;
        fortune.events.onInputDown.add(() => {
          game.tweens.remove(this.currentFortuneShake);
          this.onFortuneDown.bind(this)(fortuneGroup, category);
        });
      });
      game.add.tween(this.hand)
        .to({ alpha: 1 }, 300)
        .delay(800)
        .start();
    }
    if (lastTween) {
      lastTween.onComplete.add(() => {
        // Not now button will appear when all tweens are done to avoid issue when tweens are not yet finished
        if (!(this.config && this.config.hideNotNow)) this.notNowBtn.visible = true;
        this.notNowBtn.events.onInputDown.add(() => {
          this.coinReward = 0;
          this.fortuneCookieDataManager.triggerDismiss();
          this.closeWindow();
        }, this);
      });
    }

    this.messages.bringChildToTop(this.hand);

    game.add.tween(this.bannerGroup).to({ x: 0, alpha: 1 }, 400, Phaser.Easing.Exponential.Out).start();
  }

  /**
   * For when the fortune cookie is clicked.
   * This picks an unused message from the category, and saves it in an array in the save state.
   * It then calls showMessage with that unused message and the category
   * @param {Image} fortune
   * @param {string} category
   */
  onFortuneDown(fortune, category) {
    this.notNowBtn.visible = false;
    game.add.tween(this.coinRewardMsg).to({ alpha: 0 }, 300).start();
    for (let i = 0; i < this.fortunes.length; i++) {
      this.fortunes[i].fortune.inputEnabled = false;
    }
    // sorry for all the big variable names, it just makes it a bit easier to parse.

    // Tracking
    this.fortuneCookieDataManager.incrementSelected();

    // Turn off hand
    this.startHandTimer = false;

    // this is from the json, its all the messages in the category.
    const allMessagesInThisCategory = this.msgData.categories[category].messages;

    // this will be filled with the indices of the messages we've seen in this category.
    // indexed from allMessagesInThisCategory
    let usedMessageIndicesInThisCategory = [];

    // this is from the save state; its all messages that we've seen before.
    // the message we're about to pick will be pushed to this array.
    const allMessagesSeen = this.fortuneCookieDataManager.getMessages();

    // Filtering through all seen messages into the category, and then taking the unique ids
    usedMessageIndicesInThisCategory = allMessagesSeen.filter((msgObj) => msgObj.c === category).map((msgObj) => msgObj.i);

    // if we've seen all messages in this category, we clear out any seen messages of this category
    if (usedMessageIndicesInThisCategory.length >= allMessagesInThisCategory.length) {
      usedMessageIndicesInThisCategory = [];
      this.fortuneCookieDataManager.clearMessageCategory(category);
    }
    const messageIndex = this.getRandomInRangeExclusive(0, allMessagesInThisCategory.length - 1, usedMessageIndicesInThisCategory);
    const messageData = allMessagesInThisCategory[messageIndex];
    const message = messageData.msg;
    this.fortuneCookieDataManager.setCurrentMsg(this.msgData.categories[category].id + messageData.id);
    this.fortuneCookieDataManager.setCurrentCategory(this.msgData.categories[category].id);

    this.fortuneCookieDataManager.setMessageSeen({ c: category, i: messageData.id });
    for (let i = 0; i < this.fortunes.length; i++) {
      const element = this.fortunes[i];
      if (element !== fortune) {
        game.add.tween(element).to({ alpha: 0 }, 200).start();
      }
    }
    this.hand.visible = false;
    // game.add.tween(this.messages).to({ alpha: 0 }, 300).start().onComplete.add(this.showMessage.bind(this))
    // TODO: utilize the await tweening functionality seen in the popup tutorials
    // this is a big old mess and i apologize
    game.add.tween(fortune)
      .to({ x: 0, y: 0 }, 300, Phaser.Easing.Exponential.Out)
      .start()
      .onComplete.add(() => {
        game.add.tween(fortune)
          .to({ x: this.x - 10 }, 100, Phaser.Easing.Linear.None, true, 0, 3, 3)
          .chain(game.add.tween(fortune)
            .to({ x: this.x + 10 }, 100))
          .start()
          .onComplete.add(() => {
            this.emitter.burstConcreteAnim(0, 0);
            fortune.visible = false;
            this.showMessage.bind(this)({ category, id: this.msgData.categories[category].id }, { msg: message, id: messageData.id });
          });
      });
  }

  /**
   * @param {string} category The category of the message
   * @param {string} message The message. This should be a key into the language json
   */
  showMessage(categoryData, messageData) {
    if (this.config.coinReward) {
      this._coinBar = this.add(new G.CoinBar(0, -435, true));
      window.coinBar = this._coinBar;
      _.delay(() => {
        this.state.uiTargetParticles.createCoinBatch(
          game.world.bounds.x + game.width / 2,
          game.world.bounds.y + game.height / 2,
          this._coinBar,
          this.coinReward,
          false,
        );

        // DDNA.transactionHelper.trackRewards([G.gift.GiftContentType.Coins, this.coinReward], [], {
        //   transactionType: 'REWARD', tActionType: 'FORTUNE_COOKIE', tGameArea: 'LEVEL',
        // });
      }, 300);
    }

    this.shareWindow.msgText.text = OMT.language.getText(messageData.msg).replace('<<name>>', OMT.envData.settings.user.name);
    this.bannerGroup.visible = false;

    this.initPostChoiceElements(categoryData, messageData);

    game.add.tween(this.shareWindow.scale).to({ x: 1, y: 1 }, 500, Phaser.Easing.Back.Out).start();
    // game.add.tween(this.shareBtn.scale).to({ x: 1, y: 1 }, 400, Phaser.Easing.Back.Out, false, 200).start();

    // Tracking
    this.fortuneCookieDataManager.incrementOpened();
  }

  /**
   * Helper function. Will keep picking random numbers in the given range until it finds one not in the exclude array
   *
   * @param {number} start
   * @param {number} end
   * @param {Array} exclude
   * @returns A random number in the given range that is not in the exclude array
   * @memberof Window_FortuneCookie
   */
  getRandomInRangeExclusive(start, end, exclude) {
    let num = game.rnd.between(start, end);
    let panic = 1000; // ensure no infinite loops
    while (exclude.indexOf(num) !== -1 && panic > 0) {
      num = game.rnd.between(start, end);
      panic--;
    }
    if (panic === 0) console.log('getRandomInRangeExclusive: could not find exclusive number.');
    return num;
  }

  destroy() {
    super.destroy();
    this.onResizeSB.detach();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.fortuneCookie = Window_FortuneCookie;
