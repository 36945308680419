/* eslint-disable func-names */
const { ACTION_TYPES } = require('../../../Board/Actions/Action');

G.BoosterHorizontal = function (board, cellX, cellY, nr) {
  this.board = board;
  this.am = this.board.actionManager;
  this.cellX = cellX;
  this.cellY = cellY;

  Phaser.Image.call(
    this,
    game,
    this.board.cellXToPxIn(-0.5),
    this.board.cellYToPxIn(cellY),
  );

  this.anchor.setTo(0.5);

  this.oldCellX = -1;
  this.boosterNr = nr;
  this.active = false;

  this.orgY = this.y;
  this.targetX = this.board.cellYToPxIn(this.board.boardGridData.width) + 30;

  G.changeTexture(this, `ui_booster_${nr}`);
  this.alpha = 0;
  this.scale.setTo(2);

  game.add.tween(this.scale).to({ x: 1, y: 1 }, 1000, Phaser.Easing.Sinusoidal.Out, true);
  game.add.tween(this).to({ alpha: 1 }, 1000, Phaser.Easing.Sinusoidal.Out, true).onComplete.add(function () {
    this.active = true;
  }, this);
};

G.BoosterHorizontal.prototype = Object.create(Phaser.Image.prototype);

G.BoosterHorizontal.prototype.update = function () {
  if (!this.active) return;

  this.x += 10 * G.deltaTime;

  const cellX = this.board.pxInToCellX(this.x);

  if (cellX !== this.oldCellX) {
    this.oldCellX = cellX;
    this.board.useBoosterOnCell(cellX - 1, this.cellY);
  }

  if (this.x >= this.targetX && !this.board.candiesAreAnimating) {
    this.active = false;
    if (this.board.checkSpecialMatchList.length === 0) {
      this.am.newAction(ACTION_TYPES.PROCESS_FALL);
    } else {
      this.am.newAction(ACTION_TYPES.PROCESS_MATCH);
    }
    G.sb('onBoosterActionFinished').dispatch();
    this.am.removeAction();
    this.destroy();
  }
};
