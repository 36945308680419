/**
 * Base class for board tokens.
 * These are on the grid, but exist independently of Candies.
 */
export class BoardToken extends Phaser.Image {
  /**
   * constructor
   * @param {TokenLayer} layer
   * @param {Object} config
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} hp
   */
  constructor(layer, config, cellX, cellY, hp) {
    super(game);

    this.x = layer.board.cellXToPxIn(cellX);
    this.y = layer.board.cellYToPxIn(cellY);
    this.anchor.setTo(0.5);

    this._board = layer.board;
    this._config = config;
    this._layer = layer;

    this._cellX = cellX;
    this._cellY = cellY;
    this._hp = parseInt(hp);
  }

  /**
   * on match action on the cell.
   */
  onMatch() {
    // override to implement
  }

  /**
   * on hit by adjacent match.
   */
  onHit() {
    // override to implement
  }

  /**
   * on booster used on cell.
   * @returns {boolean}
   */
  onBooster() {
    return false;
  }

  /**
   * remove the token from the containing layer.
   */
  remove() {
    this._layer.removeToken(this._cellX, this._cellY);
  }

  /**
   * animation for token destroyed. default just destroys.
   */
  deathAnimation() {
    this.destroy();
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();

    this._board = null;
    this._config = null;
    this._layer = null;
  }

  /** GETTER / SETTER METHODS ********************************* */

  /** @returns {Object} get config passed in on construction */
  get config() { return this._config; }

  /** @returns {TokenLayer} TokenLayer instance passed in on construction */
  get layer() { return this._layer; }

  /** @returns {number} get token grid x */
  get cellX() { return this._cellX; }

  /** @param {number} value set token grid x */
  set cellX(value) { this._cellX = value; }

  /** @returns {number} get token grid y */
  get cellY() { return this._cellY; }

  /** @param {number} value set token grid y */
  set cellY(value) { this._cellY = value; }

  /** @returns {number} get token hitpoints */
  get hp() { return this._hp; }

  /** @param {number} value set token hitpoints */
  set hp(value) { this._hp = value; }
}
