import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { MILLISECONDS_IN_WEEKS } from '@omt-components/Utils/TimeUtil';

const DEFAULT = 'default';
export const PUBLIC_USER_BADGE_KEY = 'userbadge';
const NOT_COLLECTABLE_BADGES = ['default', 'leaderboardPlayer', 'leaderboardOther'];

export default class BadgeManager {
  /**
   * Returns default data
   * @returns {{ab:string, ub:Object}}
   */
  static getDefaultValues() {
    return {
      ab: DEFAULT, // [A]ctive [B]adge
      ub: {}, // [U]nlocked [B]adge
    };
  }

  /**
   * Nothing here
   */
  constructor() {
    // Nothin
  }

  /**
   * Inits the data
   * @param {{ab:string, ub:Object}} dataRef
   */
  init(dataRef) {
    this.dataReference = dataRef;
    this._availableBadges = Object.keys(G.OMTsettings.elements.badges);
    this._currentBadge = null;

    OMT_Utils.mergeMissingObject(this.dataReference, BadgeManager.getDefaultValues());

    this.checkBadgeExpiry();
  }

  /**
   * Returns the current badge
   * @returns {(string | null)}
   */
  get currentBadge() {
    return this._currentBadge;
  }

  /**
   * Checking data.
   */
  checkBadgeExpiry() {
    if (this.dataReference.ab) {
      if (this.dataReference.ab !== DEFAULT) {
        const activeBadgeTimeLeft = G.saveState.getUserCooldownRemaining(this.dataReference.ab, '');
        if (activeBadgeTimeLeft > 0) {
          this._currentBadge = this.dataReference.ab;
        } else {
          this.removeBadge(false);
          this.save();
        }
      }
    } else { // Some reason missing
      this.removeBadge(false);
      this.save();
    }
  }

  /**
   * Collects badge into list of collected badge
   * @param {string} badge
   * @param {boolean} saveNow
   */
  _collectBadge(badge, saveNow) {
    if (this._availableBadges.indexOf(badge) > -1 && NOT_COLLECTABLE_BADGES.indexOf(badge) === -1) {
      if (!this.dataReference.ub[badge]) { this.dataReference.ub[badge] = 0; }
      this.dataReference.ub[badge]++;

      if (saveNow) {
        this.save();
      }
    }
  }

  /**
   *
   * @param {string} badge
   * @returns {boolean}
   */
  getNumberOfBadgeObtained(badge) {
    return this.dataReference.ub[badge] || -1;
  }

  /**
   * Removes badge and sets it to default
   */
  removeBadge(saveNow = true) {
    if (this._currentBadge) {
      G.saveState.setUserCooldown(this._currentBadge, '', 0);
      this._setBadgeInData(DEFAULT);
      if (saveNow) {
        this.save();
      }
    }
  }

  /**
   * Sets the badge to the given string, only if it exists
   * @param {string} b
   * @param {Boolean} saveNow
   */
  setBadge(b, saveNow = true) {
    let saveRequired = false;
    if (this._availableBadges.indexOf(b) > -1) {
      this.removeBadge(false);
      this._setBadgeInData(b);
      if (this._currentBadge !== DEFAULT) {
        G.saveState.setUserCooldown(this._currentBadge, '', MILLISECONDS_IN_WEEKS);
      }
    }
    this._collectBadge(this._currentBadge, false);
    saveRequired = true;

    if (saveRequired && saveNow) {
      this.save();
    }
  }

  /**
   * Actually sets the badge. Used in setting and removing
   * @param {string} b
   */
  _setBadgeInData(b) {
    this._currentBadge = b;
    this.dataReference.ab = this._currentBadge;
    G.sb('worldMapRedrawBadge').dispatch();
  }
}
