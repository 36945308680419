const facebookBlue = 0x3578E5;

/**
 * This is the display group for the mock Facebook invite friends list
 * Used by MockInviteFriend.js
 * @author Sandra K
 */
export default class MockFriendEntry extends Phaser.Group {
  /**
   * @param {Object} config
   * @param {number} config.avatarSize
   * @param {number} config.totalWidth
   * @param {string} config.image
   * @param {string} config.name
   */
  constructor(config) {
    super(game, null);

    this.config = _.merge({ // Merges in the config to the default config
      avatarSize: 55,
      totalWidth: 0,
      image: null,
      name: '',
    }, config);

    this.signals = {
      onClick: new Phaser.Signal(),
    };

    this.drawEverything();
  }

  destroy() {
    this.signals.onClick.dispose();
    super.destroy();
  }

  /**
   * Returns an object of the important things
   */
  copyImportantStuff() {
    return {
      image: this.friendImage,
      name: this.config.name,
    };
  }

  /**
   * Draws everything required
   */
  drawEverything() {
    const padding = 10;
    const container = new Phaser.Group(game, this);
    const avatar = new Phaser.Group(game, container); // Avatar
    this.friendImage = G.makeImage(0, 0, this.config.image, 0.5, avatar);
    this.friendImage.width = this.config.avatarSize;
    this.friendImage.height = this.config.avatarSize;
    const friendMask = new Phaser.Graphics(game); // Make mask
    friendMask.beginFill(0, 1);
    friendMask.drawCircle(0, 0, this.config.avatarSize);
    friendMask.endFill();
    avatar.addChild(friendMask);
    avatar.mask = friendMask;
    avatar.addChild(this.friendImage);
    avatar.x += 25 + avatar.width / 2;

    // The blue play button
    this.playButton = new G.Button(0, 0, null, this.onClick.bind(this));
    const playRect = new Phaser.Graphics(game);
    playRect.lineStyle(1, facebookBlue);
    playRect.drawRoundedRect(0, 0, 65, 30, 5);
    playRect.x -= Math.round(playRect.width / 2);
    playRect.y -= Math.round(playRect.height / 2);
    const playText = new G.Text(-1, 1, OMT.language.getText('PLAY'), 'mockFacebook-Blue', 0.5, playRect.width * 0.8, playRect.height * 0.8, false, 'center');
    this.playButton.addChild(playRect);
    this.playButton.addChild(playText);
    this.playButton.x = Math.round(this.config.totalWidth - 25 - (avatar.width / 2));
    container.addChild(this.playButton);

    // The name of the friend
    const targetWidth = (this.playButton.x - this.playButton.width / 2) - (avatar.x + avatar.width / 2) - (padding * 4); // 4 x padding for each side of the things around
    const friendName = new G.Text(0, 0, this.config.name, 'mockFacebook-Black', [0, 0.5], targetWidth, avatar.height);
    friendName.x = Math.round(avatar.x + (avatar.width / 2) + padding);
    friendName.y += Math.round(avatar.y + friendName.height / 8);
    container.addChild(friendName);
  }

  /**
   * When the play button is clicked
   */
  onClick() {
    this.signals.onClick.dispatch(this);
  }
}
