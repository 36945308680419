import ShareMsgBase from './A_G.ShareMsgBase';

class MsgHighscoreBeaten extends ShareMsgBase {
  /**
   * The message that is sent when you beat all your friends in high score
   * @param {string} user1
   * @param {string} user2
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1, user2, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    if (!user1.avatar) user1.avatar = user1.image;
    if (!user2.avatar) user2.avatar = user2.image;

    this.avatar1 = this._addExtImage(193, 74, user1.avatar, 0, 125, null);
    this.avatar2 = this._addExtImage(372, 100, user2.avatar, 0, 124, null);
    this.bg = this._addExtImage(0, 0, 'img/Bot_Overall_Score_Beaten.png', 0, null, null);
  }
}

G.MsgHighscoreBeaten = MsgHighscoreBeaten;
