import GetGameplayChestOpenAnimation from '@omt-game-board/Animations/gameplay/chestOpen';
import { TreasureChestTypes } from '@omt-game-board/Managers/GameEnums';

export default class GameplayChest extends G.Image {
  /**
   * The treasure chest that appears on the game board
   */
  constructor() {
    super();

    this.base = G.makeImage(G.OMTsettings.elements.chestPositioning.inGame.bottom.x, G.OMTsettings.elements.chestPositioning.inGame.bottom.y, null, 0.5, this);
    this.base.name = 'base';
    this.cover = G.makeImage(G.OMTsettings.elements.chestPositioning.inGame.top.x, G.OMTsettings.elements.chestPositioning.inGame.top.y, null, [0, 1], this);
    this.cover.name = 'cover';
    this.light = G.makeImage(G.OMTsettings.elements.chestPositioning.inGame.glow.x, G.OMTsettings.elements.chestPositioning.inGame.glow.y, 'popup_lighht', 0.5, this);
    this.light.name = 'light';
    this.light.scale.setTo(0.5);
    this.light.blendMode = 1;

    this.gift = G.makeImage(0, 0, null, 0.5, this);
    this.gift.name = 'gift';

    this.onGiftShown = new Phaser.Signal();

    this.kill();
  }

  /**
   * G.ChestLayer calls the init
   * @param {number} x
   * @param {number} y
   * @param {string} type 'chest'
   */
  init(x, y, type) {
    const giftImg = this.gift;
    const { onGiftShown } = this;
    const giftMgr = G.gift;
    let giftData = null;

    // for sorting on parent side
    this.orgY = y;

    this.position.setTo(x, y);
    this.revive();

    let mainAnimation;
    let giftAnimation;
    // TODO: change those types to some ids or whatever longer
    // replace 'chest' right now would be a disaster :)
    // check G.Input.EventType
    if (type === 'chest') {
      giftData = this.setupGift(giftImg, giftMgr);
      mainAnimation = new GetGameplayChestOpenAnimation();
      giftAnimation = G.Animations.getGameplayChestGiftRevealAnimation();
    }

    G.sfx.match_5.play();

    if (mainAnimation) {
      mainAnimation.animate(this.game, this);
      mainAnimation.onComplete.add(this.kill, this);
    }

    if (giftAnimation) {
      giftAnimation.animate(this.game, giftImg);
      giftAnimation.onComplete.add(() => {
        onGiftShown.dispatch(giftData);
      }, this);
    }
  }

  /**
   * Unsure.
   * @param {G.Image} giftImg
   * @param {G.gift} giftMgr
   */
  setupGift(giftImg, giftMgr) {
    const giftData = giftMgr.getGift('ingamechests');
    giftImg.changeTexture(giftMgr.getIcon(giftData));

    // replace icon in case of coins
    // probably should be handled by giftMgr
    // so lets leave it for now
    if (giftData[0] === 'coin') {
      const iconSet = [
        'coin_package_icon_0',
        'coin_package_icon_1',
        'coin_package_icon_2',
      ];
      const newIcon = iconSet[giftData[1] - 1];
      if (newIcon) {
        G.changeTexture(giftImg, newIcon);
      }
    }
    return giftData;
  }

  /**
   * @returns {G.Image} gift
   */
  getGiftObject() {
    return this.gift;
  }
}

// G.GameplayChest = GameplayChest; // Just in case other parts of the board uses it
