const DEFAULT_STRIKETHROUGH_COLOR = 0x000000;
const DEFAULT_STRIKETHROUGH_THICKNESS = 4;

/**
 * class for price cut display
 */
export default class Shop3_PriceCutText extends Phaser.Group {
  /**
   * constructor
   * @param {string} priceText
   * @param {number} angle
   */
  constructor(priceText = '') {
    super(game);

    // create price cut text instance
    this._initTextField();
    if (priceText !== '') this.setText(priceText);
  }

  /**
   * create price cut text instance
   */
  _initTextField() {
    const priceCutTextStyle = G.Text.getStyle('shop3-priceCut');
    const priceCutText = new G.Text(0, 0, '', priceCutTextStyle, 0.5, 140);
    this.addChild(priceCutText);
    this._priceCutText = priceCutText;
  }

  /**
   * draw text strikethrough
   */
  _drawStrikeThrough() {
    const priceCutTextStyle = G.Text.getStyle('shop3-priceCut');
    let { strikeThroughThickness, strikeThroughColor } = priceCutTextStyle;
    if (strikeThroughThickness == null) strikeThroughThickness = DEFAULT_STRIKETHROUGH_THICKNESS;
    if (strikeThroughColor == null) strikeThroughColor = DEFAULT_STRIKETHROUGH_COLOR;

    const strikeThroughGraphic = this._strikeThroughGraphic || new Phaser.Graphics(game);
    strikeThroughGraphic.clear();
    strikeThroughGraphic.lineStyle(strikeThroughThickness, Phaser.Color.hexToRGB(strikeThroughColor), 1);
    strikeThroughGraphic.lineTo(this._priceCutText.width, 0);
    strikeThroughGraphic.x -= this._priceCutText.width / 2;
    strikeThroughGraphic.y -= Math.round(strikeThroughThickness / 2) + 1;
    this.addChild(strikeThroughGraphic);
    this._strikeThroughGraphic = strikeThroughGraphic;
  }

  /**
   * set the text field content
   * @param {string} priceText
   */
  setText(priceText) {
    // this.cacheAsBitmap = false;
    this._priceCutText.setText(priceText);
    this._drawStrikeThrough();
    // this.cacheAsBitmap = true;
  }
}
