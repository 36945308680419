import { LeaderboardAvatar } from '../Avatars/LeaderboardAvatar';
import { RenderTextureGenerator } from '@omt-components/Imaging/RenderTextures/RenderTextureGenerator';

const DEFAULT_AVATAR_X = 50;
const DEFAULT_AVATAR_Y = 50;
const DEFAULT_AVATAR_SIZE = 100;

const FADE_IN_TRANSITION_DURATION = 250;

/**
 * base class for collapsed UserViews
 */
export class CollapsedUserViewBase extends Phaser.Group {
  constructor(friendData) {
    super(game);

    this.signals = {};

    this._friendData = friendData;
    this._fadedIn = false;
  }

  /**
   * get the relevant friend data Obejct
   * @returns {Object}
   */
  get friendData() {
    return this._friendData;
  }

  /**
   * main initialization function. call manually or from extending Class
   * @param {Object} friendData friendData to display
   * @param {Object} contentRect (optional) bg rect shape wont be drawn if not passed
   */
  init(friendData, contentRect = null) {
    this._friendData = friendData;
    this._initCacheableContentContainer();
    this._initAvatar();
    if (contentRect) this._drawBGRect(contentRect);
  }

  /**
   * create a container for elements that can be cached
   */
  _initCacheableContentContainer() {
    this._cacheableContent = new Phaser.Group(game);
    this.addChild(this._cacheableContent);
  }

  /**
   * enable / disable content caching
   * @param {boolean} value
   */
  set cacheContent(value) {
    this._cacheableContent.cacheAsBitmap = value;
  }

  /**
   * draw the bg rect for Phaser to detect the bounds correctly and help with layout
   * @param {Phaser.Rectangle} contentRect
   * @param {number} alpha (optional)
   * @param {number} cornerSize (optional)
   * @param {number} color (optional)
   */
  _drawBGRect(contentRect, alpha = 0, cornerSize = 0, color = 0xFF0000) {
    const graphics = new Phaser.Graphics(game);
    graphics.beginFill(color, 1);
    if (cornerSize === 0) graphics.drawRect(0, 0, contentRect.width, contentRect.height);
    else graphics.drawRoundedRect(0, 0, contentRect.width, contentRect.height, cornerSize);
    graphics.endFill();
    this.addChildAt(graphics, 0);
    graphics.alpha = alpha;
  }

  /**
   * initialize the users avatar
   * @param {number} x x position
   * @param {number} y y position
   * @param {number} size width/ height
   * @param {number} rank (optional)
   */
  _initAvatar(x = DEFAULT_AVATAR_X, y = DEFAULT_AVATAR_Y, size = DEFAULT_AVATAR_SIZE, rank = 0) {
    const textureId = `avatar-rank-${rank}-${this._friendData.userId}-${size}`;
    const anchor = [0.5, 0.5];
    this._avatar = RenderTextureGenerator.getRenderTextureSprite(textureId, anchor);
    if (!this._avatar) {
      const avatarGraphics = new LeaderboardAvatar(this._friendData, size, rank);
      this._avatar = RenderTextureGenerator.generateRenderTextureSprite(avatarGraphics, textureId, anchor);
      avatarGraphics.destroy();
    }
    this._avatar.x = x; this._avatar.y = y;
    this._cacheableContent.addChild(this._avatar);
  }

  /**
   * set the fade transition effect
   * @param {number} delay delay in milliseconds before starting animation
   */
  setFadeInTransition(delay) {
    if (this._fadeTween) this._fadeTween.stop(); // stop any previous tween
    this.alpha = 0;
    this._fadeTween = game.add.tween(this);
    this._fadeTween.to({ alpha: 1 }, FADE_IN_TRANSITION_DURATION, Phaser.Easing.Quadratic.Out, false, delay);
    this._fadeTween.onComplete.addOnce(this._onFadeInComplete, this);
    this._fadeTween.start();
    this._fadedIn = false;
  }

  /**
   * called when the fade in animation is locked
   */
  _onFadeInComplete() {
    this._fadedIn = true;
  }

  /**
   * true if the content has been faded in or the value has been set externally
   * @returns {boolean}
   */
  get isFadedIn() {
    return this._fadedIn;
  }

  /**
   * set the faded in value
   * @returns {boolean}
   */
  set isFadedIn(value) {
    this._fadedIn = value;
  }

  /**
   * set the content alpha
   * @param {number} alpha 0-1
   */
  setContentAlpha(alpha) {
    if (this._fadeTween) this._fadeTween.stop(); // stop any previous tween
    this._fadedIn = alpha === 1;
    this.alpha = alpha;
  }

  /**
   * set cooldown variables abd force a state updated
   * @param {string} cooldownId id of the cooldown
   * @param {number} cooldownDuration duration of cooldown in milliseconds
   */
  _setCoolDownParamsAndUpdate(cooldownId, cooldownDuration) {
    this._cooldownId = cooldownId;
    this._cooldownDuration = cooldownDuration;
    this._updateCooldownState(true);
  }

  /**
   * set the active / normal state
   */
  _setActiveState() {
    this._isOnCooldown = false;
  }

  /**
   * set the cooldown state
   */
  _setCooldownState() {
    this._isOnCooldown = true;
  }

  /**
   * update cool down state
   * @param {boolean} forceUpdate (optional) default false
   */
  _updateCooldownState(forceUpdate = false) {
    if (!this._cooldownId) return; // no cooldown was set
    const cooldownTime = G.saveState.getUserCooldownRemaining(this._cooldownId, this._friendData.userId);
    if ((forceUpdate || this._isOnCooldown) && cooldownTime === 0) {
      this._setActiveState();
    } else if ((forceUpdate || !this._isOnCooldown) && cooldownTime > 0) {
      this._setCooldownState();
    }
  }

  /**
   * shorten the users name
   * @param {string} fullName
   * @param {number} maxLength
   */
  _shortenName(fullName, maxLength = 13) {
    if (fullName.length > maxLength && fullName.length > 3) {
      fullName = `${fullName.substr(0, maxLength - 3)}...`;
    }
    return fullName;
  }

  /**
   * main update method
   */
  update() {
    super.update();
    this._updateCooldownState();
  }

  /**
   * destruction method
   */
  destroy() {
    Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    super.destroy();
  }
}
