G.BoosterTutorialText = function() {

	Phaser.Group.call(this,game);

	this.x = 480;
	this.y = game.height*0.8;

	this.alpha = 0;

	this.bg = G.makeImage(0,0,'text_shade_bg',0.5,this);
	this.bg.alpha = 0;

	G.sb("onBoosterUse").add(function(nr) {
		this.alpha = 1;

		if (nr == 1) {
			this.makeNewText("CANDY SWIPER CHANGES THE PLACE OF TWO CANDIES");		
		}
		if (nr == 2) {
			this.makeNewText("SWEET APPLE CRUSHES ONE CANDY. TAP ON CANDY YOU WANT TO CRUSH");
		}
		if (nr == 3 || nr == 4) {
			this.makeNewText("THE ROLLING PIN CAN CLEAR WHOLE ROW OR COLUMN");
		}

	},this);


	G.sb("onBoosterUsed").add(function() {
		game.add.tween(this).to({alpha:0},500,Phaser.Easing.Sinusoidal.Out,true).onComplete.add(this.hide,this);
	},this);


};

G.BoosterTutorialText.prototype = Object.create(Phaser.Group.prototype);

G.BoosterTutorialText.prototype.makeNewText = function(txt) {

	this.txt = new G.Text(0, 0, OMT.language.getText(txt), {
		style: "font-white",
		fontSize: "50px"
	}, 0.5, 940, 400, "center");
	this.txt.alpha = 0;
	this.add(this.txt);
	game.add.tween(this.txt).to({alpha: 1}, 500, Phaser.Easing.Sinusoidal.Out,true);
	this.bg.width = this.txt.width+100;
	this.bg.height = this.txt.height+100;
	game.add.tween(this.bg).to({alpha:0.7},500,Phaser.Easing.Sinusoidal.Out,true);

};


G.BoosterTutorialText.prototype.changeText = function(txt) {

	var currentTxt = this.txt;
	game.add.tween(currentTxt).to({alpha:0},500,Phaser.Easing.Sinusoidal.Out,true).onComplete.add(currentTxt.destroy,currentTxt);
	this.makeNewText(txt);

};

G.BoosterTutorialText.prototype.hide = function() {

	if (!this.txt) return;

	var currentTxt = this.txt; 

	game.add.tween(this).to({alpha:0},500,Phaser.Easing.Sinusoidal.Out,true).onComplete.add(function() {
		this.bg.alpha = 0;
		currentTxt.destroy();
	},this);

};