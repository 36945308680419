/* eslint-disable no-use-before-define */
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import ShopUtils from '../../../../Elements/Windows/Shop/Shop_Utils';

const loyaltyValues = { // Values that the user must exceed in to be a loyal member
  MONEY: 100, // in USD
  ADS: 20000, // Sum of interstitial and rewarded ads
};

export default class LoyaltyManager {
  /**
   * Returns default values for the loyalty manager
   */
  static getDefaultValues() {
    return {
      l: false, // [L]oyal. If its true, they're a loyalty member!
      ft: false, // [F]irst[Time] seen the pop up that shows you're a loyalty member!
    };
  }

  /**
   * Constructor!
   */
  constructor() {
    this.save = () => {}; // This will be replaced externally
  }

  /**
   * Init call starts from in Preloader.js
   * @param {any} data
   */
  init(data) {
    this.dataReference = data;

    OMT_Utils.mergeMissingObject(this.dataReference, LoyaltyManager.getDefaultValues());
    this._checkData();
  }

  /**
   * Checks if the user is a loyal member.
   * Skips check if they already are
   */
  _checkData() {
    if (this.dataReference.l) {
      // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenLoyaltyPopup', 1);
      return;
    }

    const moneySpent = DDNA.tracking.getDataCapture().getPlayerCharacterizationParam('realMoneySpent');
    if (G.IAP) {
      const examplePack = ShopUtils.filterCurrentTier(OMT.payments.getCatalogSync()).find((pack) => pack.productID.indexOf('layout') > -1);
      if (examplePack) {
        const currency = examplePack.priceCurrencyCode;
        if (OMT.payments.convertPriceToAnotherCurrency(moneySpent, currency, 'USD') >= loyaltyValues.MONEY) {
          // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenLoyaltyPopup', 1);
          this.dataReference.l = true;
          this.save();
          return;
        }
      }
    }

    const adsWatched = Number.parseInt(DDNA.tracking.getDataCapture().getPlayerCharacterizationParam('adsWatchedRewarded')) || 0;
    const adsWatched2 = Number.parseInt(DDNA.tracking.getDataCapture().getPlayerCharacterizationParam('adsWatchedInterstitial')) || 0;
    if (adsWatched + adsWatched2 >= loyaltyValues.ADS) {
      // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenLoyaltyPopup', 1);
      this.dataReference.l = true;
      this.save();
    }
  }

  /**
   * Sets the status of the first time pop up
   * @param {Boolean} b
   */
  set firstTimePopup(b) {
    if (this.dataReference.ft !== b) {
      this.dataReference.ft = b;
      this.save();
    }
  }

  /**
   * Returns statue of seeing the first time pop up
   * @returns {Boolean}
   */
  get firstTimePopup() {
    if (this.dataReference) {
      return this.dataReference.ft;
    }
    return false;
  }

  /**
   * Returns a boolean if this user is a loyalty member!
   * @returns {Boolean}
   */
  get isLoyal() {
    if (this.dataReference) {
      return this.dataReference.l;
    }
    return false;
  }
}
