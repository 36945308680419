import { createLeaderboardClient } from '@sgorg/leaderboard-client';
import { getLeaderboardPostProcessorForUserData } from '@sgorg/game-backend-client';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import OMT_TierLeaderboardUtil, { INSTANCE_SLUG } from '../../Utils/OMT_TierLeaderboardUtil';

export const ERROR_CODES = {
  SEND_GEN_FAIL: -1, // General failure during sending
  SEND_INACTIVE: -2, // Leaderboard is inactive during sending
};

/**
 * Class for the Tiered leaderboard.
 * Code originally written by Rene.
 * Thanks Rene!
 *
 * Please check out some text here
 * https://confluence.softgames.de/pages/viewpage.action?pageId=53577021
 *
 * @author Sandra Koo
 */
export default class OMT_TierLeaderboard {
  constructor() {
    this._getLeaderboardPostPorcessorForUserData = getLeaderboardPostProcessorForUserData({
      userDataMapping: {
        username: 'username', // String denotes what key to use from public data key
        image: 'useravatar',
        userbadge: 'userbadge',
      },
    });
    this._inactiveKeyWords = ['leaderboard', 'cycle', 'inactive'];
  }

  /**
   * If multiple things call to init the client, will only do it once.
   *
   * Some visuals that are reliant on this may have issues
   * @param {Function} onComplete
   */
  initalizeClient(onComplete) {
    if (this._client) { onComplete(); return; }
    try {
      this._getClient().then(() => {
        OMT_Utils.stylizedLog('OMT_TierLeaderboard: Connected');
        onComplete();
      }).catch((error) => {
        onComplete(error);
      });
    } catch (err) {
      onComplete(err);
    }
  }

  /**
   * Returns the client
   * @returns {Promise<(client | null)>}
   */
  async _getClient() {
    if (!this._client) {
      const playerInfo = await FBInstant.player.getSignedPlayerInfoAsync();
      // const signature = playerInfo.getSignature();
      const signature = 'signature';
      this._client = createLeaderboardClient({ // Getting the client
        gameSlug: G.BuildEnvironment.APP_ID,
        postProcessor: this._getLeaderboardPostPorcessorForUserData,
        signature,
      });
    }
    return this._client;
  }

  /**
   * Checks if the given config object has the id
   * @param {{ storageKey:string, userCentric?:boolean, limit:number, id?:string, leaderboardId?:string}} config
   * @returns {{ storageKey:string, userCentric?:boolean, limit:number, id:string, leaderboardId?:string}}
   */
  _decorateConfig(config) {
    return {
      id: OMT.envData.settings.user.userId,
      ...config,
    };
  }

  /**
   * Returns the instance data.
   * @param {{storageKey:string, [id:string]}} config
   * @returns {{instanceId:string, instanceSlug:INSTANCE_SLUG, rating:number}}
   */
  async _getInstanceData(config) {
    const data = G.saveState.getTierLeaderboardData(config.storageKey);
    return data;
  }

  /**
   * Calculates new rating and new slug to go to. Happens at the end of a leaderboard cycle
   * @param {number} oldRating
   * @param {number} finalRank
   * @returns {{rating:number, instanceSlug:string}}
   */
  calculateNewRatingAndSlug(oldRating, finalRank) {
    const newRating = Math.min(Math.max(oldRating + this._determineRatingPointsByRank(finalRank), -Number.MAX_SAFE_INTEGER), Number.MAX_SAFE_INTEGER);
    const newInstanceSlug = newRating > 0 ? INSTANCE_SLUG.POSITIVE : INSTANCE_SLUG.NEGATIVE;
    return {
      rating: newRating,
      instanceSlug: newInstanceSlug,
    };
  }

  /**
   * Determines your rating based on your rank
   * @param {number} rank
   * @returns {number}
   */
  _determineRatingPointsByRank(rank) {
    if (!Number.isFinite(rank)) return 0;
    if (rank <= 10) return 2;
    if (rank <= 20) return 1;
    if (rank <= 30) return 0;
    if (rank <= 40) return -1;
    return -2;
  }

  /**
   * Sends a score to the leaderboard of the given storage key and slug.
   * @param {{storageKey:string, slug:string, [id:string], score:number}} inConfig
   * @returns {number} This number is either the best/last sent score, determined by the backend and leaderboard set up
   */
  async sendScore(inConfig) {
    const config = this._decorateConfig(inConfig);
    const isYourOwnData = Number.parseInt(config.id) === Number.parseInt(OMT.envData.settings.user.userId);

    if (!isYourOwnData && G.BuildEnvironment.production) {
      console.warn(`Unable to send score for ${config.id}. You are not this person!`);
      return 0;
    }

    const client = await this._getClient();
    if (!client) { return 0; }

    const { instanceId, instanceSlug, rating } = await this._getInstanceData(config);
    let data;
    try {
      data = await client.postScore({
        leaderboardSlug: config.slug,
        instanceSlug,
        userId: config.id,
        score: config.score,
      });
    } catch (e) { // Unable to send score. Leaderboard must be closed.
      console.error(e);
      let leaderboardInactive = true;
      const errorString = e.toString();
      for (const word of this._inactiveKeyWords) {
        leaderboardInactive = leaderboardInactive && errorString.indexOf(word) > -1;
      }
      if (leaderboardInactive) {
        return ERROR_CODES.SEND_INACTIVE;
      }
      return ERROR_CODES.SEND_GEN_FAIL;
    }

    // Record keeping
    const { instanceId: newInstanceId, score: currentLeaderboardScore } = data;
    if (isYourOwnData && instanceId !== newInstanceId) {
      try {
        G.saveState.saveTierLeaderboardData(config.storageKey, {
          instanceId: newInstanceId,
          instanceSlug: OMT_TierLeaderboardUtil.getInstanceSlugNumberFromString(instanceSlug),
          rating,
        });
      } catch (e) {
        console.error('Failed to save new instanceId', e);
      }
    }
    return currentLeaderboardScore;
  }

  /**
   * Gets the leaderboard of the given leaderboard storage key
   * @param {{ storageKey:string, userCentric?:boolean, limit:number, id?:string, leaderboardId?:string}} inConfig
   * @returns {Array<{userId:string, score:number, rank:number, username:string, userbadge:string}>}
   */
  async getLeaderboard(inConfig) {
    const config = this._decorateConfig(inConfig);
    let instanceId = config.leaderboardId;
    if (!instanceId) {
      const instanceData = await this._getInstanceData(config);
      instanceId = instanceData.instanceId;
      if (!instanceId) { // Still nothing?
        throw new Error('You must have set a score before you can fetch entries');
      }
    }
    const client = await this._getClient();
    if (!client) { return []; }

    let data;
    try {
      data = await client.getEntries({
        userCentric: config.userCentric || false,
        userId: config.id,
        leaderboardInstanceId: instanceId,
        limit: config.limit,
      });
    } catch (e) {
      data = [];
      console.warn('Something went wrong in retrieving leaderboard instances');
      console.error(e);
    }
    return data;
  }

  /**
   * All the time calls in one
   * @param {string} leaderboardSlug
   * @returns {{currentCycleEnd:number, nextCycleStart:number, nextCycleEnd:number, inActiveCycle:boolean, timeFailed?:boolean}}
   */
  async getCycleInfo(leaderboardSlug) {
    const client = await this._getClient();
    if (!client) {
      return {
        timeFailed: true,
        currentCycleEnd: -1,
        nextCycleStart: -1,
        nextCycleEnd: -1,
        inActiveCycle: false,
      };
    }

    let leaderboardData;
    try {
      leaderboardData = await client.getCycles({ leaderboardSlug, cycleIndexes: [0, 1, 2] });
    } catch (e) {
      console.warn('Error in getting leaderboard cycles');
      return {
        timeFailed: true,
        currentCycleEnd: -1,
        nextCycleStart: -1,
        nextCycleEnd: -1,
        inActiveCycle: false,
      };
    }
    const ldbOfConsideration = [];
    for (const lbdKey in leaderboardData) { // eslint-disable-line guard-for-in
      const cycle = leaderboardData[lbdKey];
      if (cycle) {
        ldbOfConsideration.push(cycle);
      }

      if (ldbOfConsideration.length === 2) {
        break;
      }
    }
    const currentCycleEndTime = ldbOfConsideration[0].endDate.getTime();

    const curTime = OMT.connect.getServerTimestampSync();
    let nextCycleStartTime = curTime;
    let nextCycleEndTime = curTime;
    for (const ldb of ldbOfConsideration) {
      const cycleStart = ldb.startDate.getTime();
      if (curTime < cycleStart) {
        nextCycleStartTime = cycleStart;
        nextCycleEndTime = ldb.endDate.getTime();
        break;
      }
    }

    const inActiveCycle = curTime > ldbOfConsideration[0].startDate.getTime() && curTime < currentCycleEndTime;

    return {
      currentCycleEnd: currentCycleEndTime,
      nextCycleStart: nextCycleStartTime,
      nextCycleEnd: nextCycleEndTime,
      inActiveCycle,
    };
  }

  /**
   * Gets YOUR entry in the leaderboard with the given config
   * @param {{storageKey:string, leaderboardId?:string}} config
   * @returns {({userId:string, score:number, rank:number, username:string, userbadge:string} | null)}
   */
  async getYourEntry(config) {
    try {
      const res = await this.getLeaderboard({
        ...config,
        userCentric: true,
        limit: 1,
        id: OMT.envData.settings.user.userId,
      });
      if (res && res[0]) {
        return res[0];
      }
    } catch (e) { /* do nothing */ }
    return null;
  }

  /**
   * Returns info about the leaderboard id.
   * If leaderboardId is not given, storageKey is required
   * @param {{storageKey?:string, leaderboardId:string}} config
   * @returns {({cycleId:number, startDate:Date, endDate:Date, groupSlug:string, id:string, instanceSlug:string, leaderboardId:string}|null)}
   */
  async getLeaderboardInfo(config) {
    let instanceId = config.leaderboardId;
    if (!instanceId) {
      const instanceData = await this._getInstanceData(config);
      instanceId = instanceData.instanceId;
      if (!instanceId) { // Still nothing?
        return null;
      }
    }
    const client = await this._getClient();
    if (!client) { return null; }

    try {
      const leaderboard = await client.getLeaderboardInstanceById({
        instanceId,
      });
      return leaderboard;
    } catch (e) {
      return null;
    }
  }
}
