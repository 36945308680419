import { Action } from './Action';
import { ShuffleMsg } from '../Msgs/ShuffleMsg';

/**
 * Action for Board shuffling
 */
export class ActionShuffle extends Action {
  /**
   * initialize the action.
   */
  _init() {
    this._updateActive = false;
    this._showShuffleMsg();
  }

  /**
   * action update method
   */
  update() {
    const board = this._board;
    if (this._updateActive) {
      if (!board.candiesAreAnimating && !board.candiesAreFalling) {
        this._updateActive = false;
        if (board.checkMatchList.length === 0) {
          this.finish();
        } else if (G.IMMEDIATE) {
          this.finish();
        } else {
          game.time.events.add(300, this.finish, this);
        }
      }
    }
  }

  /**
   * show the shuffle msg
   */
  _showShuffleMsg() {
    const board = this._board;
    const shuffleText = new ShuffleMsg(board.gameHooks);
    shuffleText.sprite.x = board.x + (board.width - board.tileSize * 2) * 0.5;
    shuffleText.sprite.y = board.y + (board.height - board.tileSize * 2) * 0.45;

    // add to the booster to UI FX layer
    const { gameHooks } = this._board;
    const { startBoosterFXLayer } = gameHooks;
    if (startBoosterFXLayer) startBoosterFXLayer.addChild(shuffleText.sprite);

    // animate in / out the display
    shuffleText.animateInAndOut(() => {
      board.shuffler.shuffleCandies();
      this._updateActive = true;
      shuffleText.destroy();
    });
  }
}
