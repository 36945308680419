/* eslint-disable key-spacing */
export const Events = {
  SessionStart: 'session_start',
  FBIGInit: 'FBIGInit',
  LoadingAssetFailed: 'loadingAssetFailed',
  LevelDataUnpacked: 'LevelDataUnpacked',
  // LocalStorageSize: 'localStorageSize',

  MarketingCampaign: 'marketing_campaign',

  AdMoreCoinsWheelSpin: 'VideoAdWatchGetCoinsSpin',
  AdWheelSpin: 'VideoAdWatchSpinAgain',
  SendLivesHeartClicked: 'SendLivesHeartClicked',
  RequestLivesHeartClicked: 'RequestLivesHeartClicked',
  LifeSentToUser: 'LifeSentToUser',
  LifeSentFromUser: 'LifeSentFromUser',
  LifeRequestedFromUser: 'LifeRequestedFromUser',
  LBCoinsGathered: 'LBCoinsGathered',
  InviteMessageSent: 'PlayFromInvitePopUp',
  RewardedAdRequested: 'RewardedAdRequested',
  InterstitialAdRequested: 'InterstitialAdRequested',
  AdFillrateError: 'Video-AdCannotBeDisplayedFillrate',
  RewardedAdDisplayed: 'RewardedAdDisplayed',
  InterstitialAdDisplayed: 'InterstitialAdDisplayed',
  WatchAdToDoubleCoinsOnLevelComplete: 'VideoAdWatchx2CoinLvlComplete',
  WatchAdToSpinFailFlowWheel: 'VideoAdWatchFFWheel',
  MiniMissionCompleted: 'DailyMissionCompleted',
  AdToProgressMiniMissionCompleted: 'VideoAdWatchCompleteMission',
  HomeScreenIconAccepted: 'HomeScreenIconAccept',
  HomeScreenIconReject: 'HomeScreenIconReject',
  // ! WatchedAdForPreLevelBoost: 'VideoAdWatchCompletePreLevelBoost',
  WatchedAdToOpen1stAccomplishGift: 'VideoAdWatchAccomplish1st',
  WatchedAdToOpen2ndAccomplishGift: 'VideoAdWatchAccomplish2nd',
  WatchedAdAccomplishGift: 'VideoAdWatchAccomplish',
  WatchedAdDailyReward: 'VideoAdWatchDailyReward',
  VideoAdWatch3HGIFT: 'VideoAdWatch3HGIFT',
  // X VideoAdStarRequested: 'ad_star_request',
  // X VideoAdStarGranted: 'ad_star_granted',

  InviteFromLevelLeaderboard: 'Entry_InviteLevelLB',
  InviteFromLeaderboardCollapsed: 'Entry_InviteLBC',
  InviteFromLeaderboardExpanded: 'Entry_InviteLBX',
  InviteFromReferral: 'Entry_InviteReferral',
  // X CrossPromoNewUser: 'XPromo_new_user',
  // X CrossPromoOldUser: 'XPromo_old_user',

  BotOptOut: 'Bot_Deny',
  BotOptIn: 'Bot_Confirm',
  BotAlreadySubscribed: 'Bot_Subscribed',

  // ? ReferralInviteClicked       : 'ReferralInviteClicked',
  // ? ReferralRewardCollected     : 'ReferralRewardCollected',
  // ? ReferralIconClickedFirstTime: 'ReferralIconClickedFirstTime',
  // ? ReferralIconClicked         : 'ReferralIconClicked',

  OpenShop: 'open_shop',
  OpenShopInventory: 'open_shop_inventory',
  OpenShopLvlStart: 'open_shop_lvl_start',
  OpenShopBoosters: 'open_shop_boosters',
  OpenShopGate: 'open_shop_gate',
  OpenShopMoves: 'open_shop_moves',
  OpenShopLives: 'open_shop_lives',
  CloseShop: 'close_shop',

  // ? XPromoRewardPayloadDetected: 'og_botopt_reward_payload',
  // ? XPromoRewardPayloadShown   : 'og_botopt_reward_open',
  // ? XPromoRewardPayloadClaimed : 'og_botopt_reward_collected',

  // X DebugShowAdAttemptRejectedAlreadyRunning: 'DebugShowAdAlreadyRunning',
  // X DebugShowAdFailsafeActivated            : 'DebugShowAdFailsafeActivated',

  // X EventOfferOpen       : 'event_offer_open',
  // X EventOfferClose      : 'event_offer_close',
  // X EventOfferTapBuy     : 'event_offer_tap_buy',
  // X EventMapSwitch       : 'event_map_switch',
  // X EventLevelStart      : 'event_level_start',
  // X EventLevelEnd        : 'event_level_end',
  // X EventCollectionUnlock: 'event_collection_unlock',
  // X EventWindowOpen      : 'event_window_open',
  // X EventWindowClose     : 'event_window_close',
  // X EventPurchase        : 'event_purchase',
  // X EventBragTap         : 'event_brag_tap',

  LevelStart  : 'level_start',
  LevelEnd    : 'level_end',
  DailyChallengeLevelStart: 'level_start_dc',
  DailyChallengeLevelEnd: 'level_end_dc',

  BoosterIn: 'booster_in',
  BoosterOut: 'booster_out',

  CoinsIn: 'coins_in',
  CoinsOut: 'coins_out',

  IAPRequest: 'IAPRequest',
  IAPSuccess: 'IAPSuccess',
  IAPFail: 'IAPFail',
  IAPurchase: 'sg_purchase',

  SoundStatus : 'sound_status',
  Music       : 'music',
  SoundEffects: 'sound_effects',

  GatePopUpWithFriendOpened  : 'ctx_sw_gate_available',
  GatePopUpTapChallengeFriend: 'ctx_sw_gate_tap',
  GatePopUpSwitchContextSuccess   : 'ctx_sw_gate_switch',
  GatePopUpSwitchContextFail      : 'ctx_sw_gate_deny',

  // X FriendBubbleAppears  : 'ctx_sw_ingame_help',
  // X FriendBubbleFTUE     : 'ctx_sw_ingame_ftue',
  // X FriendBubbleTap      : 'ctx_sw_ingame_tap',
  // X FriendBubbleSuccess  : 'ctx_sw_ingame_change',
  // X FriendBubbleFail     : 'ctx_sw_ingame_deny',

  TreasureHuntPanelVisible      : 'ctx_sw_trhunt_visible',
  // X TreasureHuntInviteTap         : 'ctx_sw_trhunt_tap',
  // X TreasureHuntInviteSuccess     : 'ctx_sw_trhunt_switch',
  // X TreasureHuntInviteFail        : 'ctx_sw_trhunt_deny',
  // X TreasureHuntMultiplierIncrease: 'ctx_sw_trhunt_multiplier',
  // X TreasureHuntNoFriendsVisible  : 'ctx_sw_trhunt_nofriends',
  // X TreasureHuntNoFriendsInvite   : 'ctx_sw_trhunt_tap_invite',
  // X TreasureHuntNoFriendsOK       : 'ctx_sw_trhunt_tap_ok',

  // X FortuneCookiePopUpVisible     : 'fortune_open',
  // X FortuneCookieShare            : 'fortune_tap_share',
  // X FortuneCookieShareEntryNewUser: 'fortune_nu_share',
  // X FortuneCookieSendEntryNewUser : 'fortune_nu_cs',
  // X FortuneCookieShareEntryOldUser: 'fortune_ou_share',
  // X FortuneCookieSendEntryOldUser : 'fortune_ou_cs',

  // ? LoadingTime: 'loading_times',

  NewUserSource: 'nu_source',
  DaysSinceLastLogin: 'nu_days',

  BotMessage: 'BotMessage',

  XPromoTap: 'XPromo_tap',
  XPromoCancel: 'XPromo_cancel',
  XPromoVisible: 'XPromo_%id%_visible',
  XPromoClicked: 'XPromo_%id%_click',

  AdminMessageSent: 'adminMessageSent',
  AdminMessageClick: 'adminMessageClick',

  PaymentsReady: 'paymentsReady',
  PaymentsReadyTimedOut: 'paymentsReadyTimedOut',
};

export const FTUXEvents = {
  AssetLoadingDone: 'FTUAssetLoadingDone',
  FacebookSDKInit: 'FTUFacebookSDKInit',
  FacebookSDKLoaded: 'FTUFacebookSDKLoaded',
  GBSyncDone: 'FTUGBSyncDone',
  LoadingComplete: 'FTULoadingComplete',
  SagaMapVisible: 'FTUSagaMapVisible',
  StartGameAsync: 'FTUStartGameAsync',

  SdkInitializationComplete : 'FTUX_SDKINT',
  ConnectionEstablished     : 'FTUX_ConnectionEst',
  LoadingCompleted          : 'FTUX_LoadingComplete',
  FirstMatchWithTutorialOn  : 'FTUX_TutOn_FirstMatch',
  SecondMatchWithTutorialOn : 'FTUX_TutOn_SecondMatch',
  ThirdMatchWithTutorialOn  : 'FTUX_TutOn_ThirdMatch',
  DailyChallengeUnlocked    : 'FTUX_DailyChallengeUnlock',
  DailyMissionUnlocked      : 'FTUX_DailyMissionsUnlock',
  SpinWheelUnlocked         : 'FTUX_SpinWheelUnlock',
  FirstFailFlowWheelSpin    : 'FTUX_FirstFFWheelSpin',
  FirstAllMissionsReward    : 'FTUX_FirstDailyRewardMade',
  FirstWheelSpin            : 'FTUX_FirstSpinMade',
  FirstWheelAdSpin          : 'FTUX_FirstAdSpinMade',
  SecondLevelFinished       : 'FTUX_Lvl02C',
  WorldMapVisible           : 'FTUX_SagaMapVis',
};

export const RTUXEvents = {
  AssetLoadingDone: 'RTUAssetLoadingDone',
  FacebookSDKInit: 'RTUFacebookSDKInit',
  FacebookSDKLoaded: 'RTUFacebookSDKLoaded',
  GBSyncDone: 'RTUGBSyncDone',
  LoadingComplete: 'RTULoadingComplete',
  SagaMapVisible: 'RTUSagaMapVisible',
  StartGameAsync: 'RTUStartGameAsync',
};
