import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

export class EventTokenCounter extends Phaser.Group {
  constructor(x) {
    super(game);
    this._responsiveSettings = G.OMTsettings.elements.EventTokenCounter;
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;

    this._specialEventMgr = G.saveState.tokenEventManager;
    this.x = x;
    this.y = 0;

    // Local token count
    // Due to the animations that play when a token is collected, the number on this counter
    // will be a bit behind the actual count in the special event manager
    this._count = this._specialEventMgr.levelTokensCollected;

    this.init();
    this._onResize();
    this._onResizeSB = G.sb('onScreenResize').add(this._onResize, this);
  }

  /**
   * Init counter
   */
  init() {
    this._bg = G.makeImage(0, 0, 'tokenEvent_leaderboard_pumpkincount_bar', 0.5, null);
    this._tokenIcon = G.makeImage(50, 0, G.OMTsettings.tokenEvent.boardTokenAsset, 0.5, null);
    this._tokenIcon.scale.set(89 / this._tokenIcon.width);
    this._text = new G.Text(-28, 0, '0', 'tokenEvent-inGameTokenCounter', 0.5);

    this.add(this._bg);
    this.add(this._tokenIcon);
    this.add(this._text);
  }

  /**
   * Updates displayed token count
   * @param {number} amount
   */
  updateCount(amount = null) {
    if (amount == null) {
      this._count = G.saveState.tokenEventManager.levelTokensCollected;
    } else {
      this._count += amount;
    }
    this._text.setText(this._count);
    game.add.tween(this._text.scale).to({ x: 1.5, y: 1.5 }, 100, Phaser.Easing.Linear.None, true, 0, 0, true);
  }

  /**
   * Resize handler
   */
  _onResize() {
    if (this._isLandscape) {
      this.x = game.width / 2 + this._responsiveSettings.landscapeXOffset;
      this.y = this._responsiveSettings.yPos[OMT.systemInfo.orientationKey];
      this.scale.setTo(this._responsiveSettings.scale[OMT.systemInfo.orientationKey]);
    } else {
      const yData = this._responsiveSettings.yPos;
      const scale = this._responsiveSettings.scale[OMT.systemInfo.orientationKey];
      const yPos = yData[OMT.systemInfo.orientationKey];
      const yLerpFactor = Math.min(Math.max((game.height - yData.minScreenHeight) / (yData.maxScreenHeight - yData.minScreenHeight), 0), 1);
      this.y = yPos.min + (yPos.max - yPos.min) * yLerpFactor;

      const scaleLerpFactor = Math.min(Math.max((game.height - scale.minScreenHeight) / (scale.maxScreenHeight - scale.minScreenHeight), 0), 1);
      this.scale.setTo(scale.min + (scale.max - scale.min) * scaleLerpFactor);
    }
  }

  destroy() {
    super.destroy();
    this._onResizeSB.detach();
  }
}
