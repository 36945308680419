import ShareMsgBase from './A_G.ShareMsgBase';

class MsgInvite extends ShareMsgBase {
  /**
   * The message that gets sent when you try to invite a friend
   * @param {string} user1
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    this.bg = this._addExtImage(0, 0, 'img/Msg_Wow.jpg', 0, null, null);

    this.friendsBeaten = this.add(new G.Text(336, 39,
      OMT.language.getText('Hey! Let\'s play %GameName% together!'), {
        style: 'msg-white',
        fontSize: '40px',
      }, 0.5, 420));

    this.score1 = this.add(new G.Text(336, 295, OMT.envData.settings.user.name, {
      style: 'msg-yellow',
      fontSize: '65px',
      stroke: '#0088d1',
      strokeThickness: 8,
    }, 0.5, 420));

    this.avatar1 = this._addExtImage(266, 103, user1, 0, 139, null);
    this._objectLoadComplete();

  }
}

G.MsgInvite = MsgInvite;
