import ShareMsgBase from './A_G.ShareMsgBase';

class MsgGate extends ShareMsgBase {
  /**
   * The message that is sent when you got the key from a friend from answering your request for help
   * @param {string} user1AvatarUrl
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1AvatarUrl, onAllImagesReadyCallback = null) {
    super(2, onAllImagesReadyCallback);
    this.avatar1 = this._addExtImage(305, 62, user1AvatarUrl, 0, 96);
    this.bg = this._addExtImage(0, 0, 'img/Msg_Gate_Key.png', 0);
  }
}

G.MsgGate = MsgGate;
