/* eslint-disable no-param-reassign */
/* eslint-disable max-len */

/**
 * utility class
 */
export class OMT_Utils {
  /**
   * stylized log output
   * @param {string} msg msg to log
   * @param {string} color foreground color
   * @param {string} bgColor foreground color
   */
  static stylizedLog(msg, color = '#00FF00', bgColor = '#222') {
    console.log(`%c ${msg} `, `background: ${bgColor}; color: ${color}`);
  }

  // TODO: reconsider naming to avoid confusion
  /**
   * Creates base64 image of selected message object
   * @param {Object} msgObj
   * @returns {string}
   */
  static msgToBase64(msgObj) {
    if (!G._msgBmp) G._msgBmp = game.make.bitmapData(672, 354);

    msgObj.x = game.world.bounds.x;
    msgObj.y = game.world.bounds.y;
    msgObj.updateTransform();

    G._msgBmp.clear();
    G._msgBmp.drawGroup(msgObj);

    msgObj.destroy();
    const dataURL = G._msgBmp.canvas.toDataURL();
    // G._msgBmp.destroy();
    return dataURL;
  }

  /**
   * get reference from a string
   * @param {string} refernceString dot notation refernece. example G.OMTSettings.tournaments
   * @param {Object} rootReference Object to start reference chain from
   * @returns {*}
   */
  static stringToReference(refernceString, rootReference = window) {
    try {
      return refernceString.split('.').reduce((o, i) => o[i], rootReference);
    } catch (error) {
      return undefined;
    }
  }

  /**
   * merge settings objects using lodash. this has a customizer to not merge arrays, but prefer the b state array.
   * @param {Object} objA
   * @param {Object} objB
   * @returns {Object}
   */
  static mergeSettingsObjects(objA, objB) {
    objA = _.cloneDeep(objA);
    objB = _.cloneDeep(objB);
    const customizer = (a, b) => {
      if (Array.isArray(a) && Array.isArray(b)) return b;
      return undefined;
    };
    _.mergeWith(objA, objB, customizer);
    return objA;
  }

  /**
  * merge returning users chest data
  * @param {Object} returning
  * @param {Object} incoming
  */
  static mergeMissingObject(returning, incoming) {
    for (const key in incoming) {
      if (Object.prototype.hasOwnProperty.call(incoming, key) && !Object.prototype.hasOwnProperty.call(returning, key)) returning[key] = incoming[key];
    }
    return returning;
  }

  /**
   * Converted from the ab-test-group-manager.js that Jeff made for AB COOK
   * @author Jeff (Original), Sandra Koo
   * @param {Array<{groupId:string, weight:number}>} groupList
   * @param {number} playerID
   * @param {boolean} outputConsole Outputs console log messages
   * @returns {groupId:string, weight:number}
   */
  static getPlayerTestGroup(groupList, playerID, outputConsole = true) {
    let targetGroup; // Target group for player

    let totalWeight = 0; // Get total weights
    groupList.forEach((groupData) => { totalWeight += groupData.weight; });

    const playerGroupVal = 1 + ((playerID * 1) % totalWeight); // Find where the player sits
    let groupStartVal = 0;
    let groupEndVal = 0;
    // Find which test group this ID belongs to based on group weights
    for (let i = 0; i < groupList.length; i++) {
      targetGroup = groupList[i];
      groupEndVal += targetGroup.weight;
      if (playerGroupVal > groupStartVal && playerGroupVal <= groupEndVal) {
        break;
      }
      groupStartVal = groupEndVal;
    }
    if (outputConsole) console.log(`(playerID: ${playerID}) TEST GROUP ID = ${targetGroup.groupId}`);
    return targetGroup;
  }

  /**
   * Returns an object after checking certain conditions and applies parameters given to it
   * @param { object } primaryObject the primary object to change
   * @param { { condition: () => boolean, parameters: { [key: string]: any }} | { condition: () => boolean, parameters: { [key: string]: any }}[] } conditions An object that contain conditions and parameters to change
   */
  static generateConditionalObject(primaryObj, conditions) {
    let result = { ...primaryObj };
    conditions = this.arrayifyObject(conditions);
    for (const conditionObject of conditions) {
      const { condition, parameters } = conditionObject;
      if (condition()) {
        result = {
          ...result,
          ...parameters,
        };
      }
    }
    return result;
  }

  static arrayifyObject(object) {
    if (!Array.isArray(object)) {
      object = [object];
    }

    return object;
  }
}
