if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getResetTransformAnimation = function getResetTransformAnimation() {
  return { animate: animate };

  function animate(game, target, duration) {
    return game.add
      .tween(target)
      .to(
        { x: 0, y: 0, angle: 0 },
        duration || 500,
        Phaser.Easing.Cubic.Out,
        true
      );
  };
}
