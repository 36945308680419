/* /// //////////////////////////////////////////////////////////////////////////////
// SETTINGS FOR LEADERBOARD COMPONENTS //////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////// */

// leaderboardAvatar.js settings
export const LEADERBOARD_AVATAR_FONT_FACE = 'font-white';
export const LEADERBOARD_AVATAR_TOP3_POS = { x: 9, y: 12 };
export const LEADERBOARD_AVATAR_TOP3_FONT_SIZE = '24px';
export const LEADERBOARD_AVATAR_OTHER_POS = { x: 12, y: 13 };
export const LEADERBOARD_AVATAR_OTHER_FONT_SIZE = '18px';
export const LEADERBOARD_AVATAR_FRAME_CORNER_SIZE = 23;
export const LEADERBOARD_AVATAR_CROWN_OFFSET = { x: -5, y: -1 };
export const LEADERBOARD_AVATAR_CROWN_ANGLE = 30;
export const LEADERBOARD_AVATAR_CROWN_SCALE = 0.7;

// for layout of StarDisplayGroup.js used in multiple leaderboards
export const STAR_GROUP_FONT_STYLE = { style: 'font-blue', fontSize: '30px' };
export const STAR_GROUP_BG_SCALE = 0.95;
export const STAR_GROUP_TEXT_WIDTH_RATIO = 0.7; // % of bg image with
export const STAR_GROUP_STAR_SCALE = 0.375;
export const STAR_GROUP_STAR_ANGLE = 0;
export const STAR_GROUP_TEXT_OFFSET = { x: 6, y: 3 }; // relative the star group
