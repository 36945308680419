if (typeof G === 'undefined') G = {};
G.UI = G.UI || {};

G.UI.ElementType = {
  Image: 'image',
  Rect: 'rect',
  NineSlice: 'nineslice',
  Group: 'group',
  Text: 'text',
  Button: 'button',
  JSON: 'json',
};
