/**
 * Constants/enumerators used in all code related to gift reward spaces & Window_gift.js
 */

if (typeof G === 'undefined') G = {};
G.GiftUI = G.GiftUI || {};
G.GiftUI.Constants = {
  GiftReasons: {
    Gift: 'gift',
    Chest: 'chest',
    Achievement: 'achievement',
    ThreeStars: '3stars',
    DailyReward: 'dailyReward',
  },

  GiftBoxImages: {
    Default: {
      opened: 'gift_bottom',
      closed: 'gift_full', // unused
      lid: 'gift_top',
    },
    Retro: {
      opened: 'gift_bottom',
      closed: 'gift_full',
      lid: 'gift_top',
    },
  },

  TitleBarText: {
    Gift: '',
    Chest: '',
    Achievement: 'You won three in a row!',
    ThreeStars: 'Nice job! You earned 3 stars! Enjoy your gift!',
  },

  MonetiseOptions: {
    None: false,
    OpenOtherGifts: 'otheropen',
    DoubleReward: 'double',
    TripleReward: 'triple',
  },

  GiftBoxTypes: {
    Default: false, // new giftbox
    Retro: 'retro', // old giftbox for the daily reward
  },

  GiftTypes: {
    Coins: 'coin',
    Booster: 'booster',
    Heart: 'life',
    InfiniteLives: 'lifeUnlimited',
  },

  GiftBoxTypeOffsets: {
    Default: {
      lid: {
        x: 0,
        y: 0,
      },
    },
    Retro: {
      lid: {
        x: 0,
        y: 0,
      },
    },
  },

  GiftTypeChecks: {
    Coins: function (str) { return str.indexOf(G.GiftUI.Constants.GiftTypes.Coins) === 0; },
    Booster: function (str) { return str.indexOf(G.GiftUI.Constants.GiftTypes.Booster) === 0; },
    Heart: function (str) { return str === G.GiftUI.Constants.GiftTypes.Heart; },
    InfiniteLives: function (str) { return str === G.GiftUI.Constants.GiftTypes.InfiniteLives; },
  },
};
