import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

/**
 * class for quiz incorrect answer
 */
class Window_IncorrectAnswer extends Window {
  /**
   * cosntructor
   * @param {Object} parent
   * @param {Object} config
   */
  constructor(parent, config) {
    super(parent);
    this._initUIElements(config);
    G.sb('hideMainHighscorePanel').dispatch();
  }

  /**
   * init UI elements
   * @param {Object} config
   */
  _initUIElements(config) {
    const uiElement = G.UI.createUIElement(game, G.json['ui/popUps/incorrectAnswer'], 1, 1);
    this.add(uiElement.rootElement);

    uiElement.exports.forFirst('titleBarGroup', (element) => {
      if (config.titleTextBar) {
        G.GiftUI.Elements.TitleTextBar(element, config.titleTextBar);
      }
    });
    uiElement.exports.getFirst('buttonLabel').rootElement.destroy();
    uiElement.exports.forFirst('button', (button) => {
      const onClick = () => {
        this.closeWindow();
        G.sb('showMainHighscorePanel').dispatch();
      };
      const { parent } = button;
      const pos = button.position;
      button.destroy();
      const button2 = new OMT_UI_SquareButton(pos.x, pos.y, { // Hijack
        button: {
          tint: BUTTONCOLOURS.green,
          dimensions: {
            width: 309,
            height: 100,
          },
        },
        text: {
          string: OMT.language.getText('Continue'),
          style: {
            style: 'font-white',
            fontSize: 45,
          },
        },
        options: {
          clickFunction: {
            onClick: onClick.bind(this),
          },
        },
      });
      button2.x += button2.width / 2;
      button2.y += button2.height / 2;
      parent.removeChild(button);
      parent.addChild(button2);
    });

    G.UI.resize(uiElement.rootElement, 640, 960);
    uiElement.rootElement.x = -320;
    uiElement.rootElement.y = -400;
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.incorrectAnswer = Window_IncorrectAnswer;
