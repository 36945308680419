G.DailyRewardGiftIcon = function (x, y, gifts) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);

  if (gifts.length === 1) {
    this._addIcon(0, 0, gifts[0]);
  } else {
    this._addIcon(20, 20, gifts[0]);
    this._addIcon(-20, -20, gifts[1]);
  }
};

G.DailyRewardGiftIcon.prototype = Object.create(Phaser.Group.prototype);


G.DailyRewardGiftIcon.prototype._addIcon = function (x, y, gift) {
  const icon = G.makeImage(x, y, G.gift.getIcon(gift), 0.5, this);
  if (gift[1] > 1) {
    icon.amountTxt = new G.Text(0, 30, `x${gift[1]}`, {
      style: 'font-blue',
      fontSize: '35px',
    }, 0.5);
    icon.addChild(icon.amountTxt);
  }
  if (gift[0] === 'coin') {
    icon.y -= 10;
    icon.amountTxt.y += 10;
  }
};
