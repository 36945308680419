import { TOKEN_TYPES, SPECIAL_TYPES } from './BoardConstants';
import { MatcherGridArray } from './Utils/CustomGridArrays';
import { Candy } from './Candy/Candy';
import CandyDataManager from './Candy/CandyDataManager';

const POSSIBLE_MOVES_DOWN = [[-1, 1, 1, 1], [1, 1, 2, 1], [-2, 1, -1, 1], [0, 2, 0, 3]];
const POSSIBLE_MOVES_UP = [[-1, -1, 1, -1], [1, -1, 2, -1], [-2, -1, -1, -1], [0, -3, 0, -2]];
const POSSIBLE_MOVES_RIGHT = [[2, 0, 3, 0], [1, 1, 1, 2], [1, -1, 1, 1], [1, -2, 1, -1]];
const POSSIBLE_MOVES_LEFT = [[-3, 0, -2, 0], [-1, -2, -1, -1], [-1, -1, -1, 1], [-1, 1, -1, 2]];

const HORIZONTAL_COORDINATES = [[-1, 0, 1, 0], [-2, 0, -1, 0], [1, 0, 2, 0]];
const VERTICAL_COORDINATES = [[0, -1, 0, 1], [0, -1, 0, -2], [0, 1, 0, 2]];

/**
 * class for handling board matching
 */
export class BoardMatcher {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    this._board = board;
    this._specialsCoordinates = CandyDataManager.getSpecialPatterns();

    const { boardGridData } = this._board;
    this._grid = new MatcherGridArray(boardGridData.width, boardGridData.height, false);
    this._tempGrid = new G.GridArray(boardGridData.width, boardGridData.height, false);
    this._hitGrid = new G.GridArray(boardGridData.width, boardGridData.height, false);

    this._candiesToProcess = [];
    this._specialCandiesToProcess = [];
  }

  /**
   * check if a candy has valid moves
   * @param {Candy} candy
   * @returns {boolean}
   */
  isMoveValid(candy) {
    const { cellX, cellY } = candy;
    if (!this._board.isCellMatchable(cellX, cellY)) return false;
    if (candy.hasSpecialType() && candy.isSpecialActivatedByMove()) return true;
    if (this.quickCheckCoords(candy, HORIZONTAL_COORDINATES, false)) return true;
    if (this.quickCheckCoords(candy, VERTICAL_COORDINATES, false)) return true;
    return false;
  }

  /**
   * similar to isMoveValid, but does not check specials
   * @param {Candy} candy
   * @returns {boolean}
   */
  quickMatchCheck(candy) {
    if (!candy) return false;
    const { cellX, cellY } = candy;
    if (!this._board.isCellMatchable(cellX, cellY)) return false;
    if (this.quickCheckCoords(candy, HORIZONTAL_COORDINATES, false)) return true;
    if (this.quickCheckCoords(candy, VERTICAL_COORDINATES, false)) return true;
    return false;
  }

  /**
   * get a list of possible moves on the current board
   * @returns {Array}
   */
  checkPossibleMoves() {
    const possibleMoves = [];
    this._board.candiesLayer.grid.loop((elem, x, y) => {
      this._checkPossibleMovesForCandy(elem, x, y, possibleMoves);
    }, this);
    return possibleMoves;
  }

  /**
   * get a list of possible moves for a specific candy on the board
   * @param {Candy} candy
   * @param {number} x
   * @param {number} y
   * @param {Array} possibleMoves (optional) list of moves to append to
   * @returns {Array}
   */
  _checkPossibleMovesForCandy(candy, x, y, possibleMoves = []) {
    if (!candy) return possibleMoves;
    const { cellX, cellY } = candy;
    if (!this._board.isMoveable(cellX, cellY) || !this._board.isCellMatchable(cellX, cellY)) return possibleMoves;
    if (this._checkMove(candy, x + 1, y, POSSIBLE_MOVES_RIGHT)) possibleMoves.push([x, y, x + 1, y]); // check moves RIGHT
    if (this._checkMove(candy, x - 1, y, POSSIBLE_MOVES_LEFT)) possibleMoves.push([x, y, x - 1, y]); // check moves LEFT
    if (this._checkMove(candy, x, y - 1, POSSIBLE_MOVES_UP)) possibleMoves.push([x, y, x, y - 1]); // check moves UP
    if (this._checkMove(candy, x, y + 1, POSSIBLE_MOVES_DOWN)) possibleMoves.push([x, y, x, y + 1]); // check moves DOWN
    return possibleMoves;
  }

  /**
   * check if a candy can be moved to the target x,y
   * @param {Candy} candy
   * @param {number} x
   * @param {number} y
   * @param {number} possibleMoves
   * @returns {boolean}
   */
  _checkMove(candy, x, y, possibleMoves) {
    if (!candy) return false;
    if (!this._board.isMoveable(x, y)) return false;
    const candy2 = this._board.getCandy(x, y);

    // if we're checking on the spiral candy, and the other candy is a burnt / goal candy, DO NOT consider it a match
    if (!this._checkBombCanMatchWith(candy, candy2)) return false;

    if (!this._board.walls.canMoveHappen([candy.cellX, candy.cellY], [x, y])) { return false; }

    return (candy.isSpecialActivatedByMove(candy2.baseTypeData.id)
      || CandyDataManager.getTypesCombo(candy.getSpecialTypeName(), candy2.getSpecialTypeName())
      || this.quickCheckCoords(candy, possibleMoves, false));
  }

  /**
   * Checks if the `currentCandy` is a bomb and if it should be matched with specific types
   * Returns `false` if it SHOULDN'T MATCH with the other types
   * Returns `true` if ts SHOULD match
   * @param {Candy} currentCandy
   * @param {Candy} theOtherCandy
   * @returns {boolean}
   */
  _checkBombCanMatchWith(currentCandy, theOtherCandy) {
    if (currentCandy.getSpecialType() === SPECIAL_TYPES.SPIRAL) { // Spirals should not
      return !(theOtherCandy.isGoalCollectType() // NOT MATCH with burnt/goals
        || theOtherCandy.isCandyInfected() // NOT MATCH with infected source
        || theOtherCandy.isLayeredCakeCandy() // NOT MATCH with layered cake
        || theOtherCandy.isChestOrFortune() // NOT MATCH with chest or fortune
      );
    }
    return true;
  }

  /**
   * process list of potential matching candies that have been collected
   * First fills matchGird with TOKEN_TYPES.TEMP_MATCH. Then fills and process hit grid.
   */
  processMatchList() {
    if (this._board.checkMatchList.length === 0 && this._board.checkSpecialMatchList.length === 0) return;

    const { lvlDataManager, gameHooks } = this._board;
    lvlDataManager.increaseCombo();

    const soundId = `match_${game.math.clamp((lvlDataManager.combo || 1), 1, 5)}`;
    gameHooks.playSound(soundId);

    // clear change grid
    this._candiesToProcess = this._board.checkMatchList;
    this._specialCandiesToProcess = this._board.checkSpecialMatchList;

    for (let i = 0; i < this._candiesToProcess.length; i++) {
      if (this._grid.get(this._candiesToProcess[i].cellX, this._candiesToProcess[i].cellY)) continue;
      if (this._candiesToProcess[i].hasSpecialType() && this._candiesToProcess[i].isSpecialActivatedByMove()) {
        this._specialCandiesToProcess.push(this._candiesToProcess[i]);
      } else {
        this._processTemp(this._candiesToProcess[i]);
      }
    }

    this._inflateHitGrid(); // inflate before specials process

    for (let j = 0; j < this._specialCandiesToProcess.length; j++) {
      this._processTempSpecial(this._specialCandiesToProcess[j]);
    }

    this._board.checkMatchList = [];
    this._board.checkSpecialMatchList = [];

    this._processGrid();
    this._processHitGrid();

    this._grid.clear();
    this._hitGrid.clear();
  }

  /**
   * inflate the hit grid. expand near elements with TOKEN_TYPES.TEMP_HIT
   */
  _inflateHitGrid() {
    this._grid.loop((elem, x, y) => {
      if (elem) {
        this._hitGrid.set(x - 1, y, TOKEN_TYPES.TEMP_HIT);
        this._hitGrid.set(x + 1, y, TOKEN_TYPES.TEMP_HIT);
        this._hitGrid.set(x, y - 1, TOKEN_TYPES.TEMP_HIT);
        this._hitGrid.set(x, y + 1, TOKEN_TYPES.TEMP_HIT);
      }
    }, this);
  }

  /**
   * process the hit grid.
   */
  _processHitGrid() {
    this._hitGrid.loop((elem, x, y) => {
      if (elem) this._board.hitCell(x, y);
    }, this);
  }

  /**
   * clear matches and create specials from matches
   */
  _processGrid() {
    const changedIntoSpecials = [];
    this._grid.loop((elem, x, y) => {
      if (elem) {
        if (elem === TOKEN_TYPES.TEMP_MATCH) {
          this._board.matchCell(x, y);
        } else {
          if (elem[0] === 'change') {
            if (this._board.getCandy(x, y)) { this._board.getCandy(x, y).changeInto(elem[1]); }
            // without that, if change is in cage, cage will be intact
            this._board.matchCellExceptCandy(x, y);
            changedIntoSpecials.push(this._board.getCandy(x, y));
          }
          if (elem[0] === 'match-move') this._board.matchCell(x, y, elem[1], elem[2], elem[3]);
        }
      }
    }, this);

    // check if candy change into special is able to match again
    // in case of special that was created from candies under concrete
    changedIntoSpecials.forEach((candy) => {
      if (this.quickMatchCheck(candy)) {
        this._board.checkMatchList.push(candy);
      }
    }, this);
  }

  /**
   * process special candies
   * @param {Object} processData
   */
  _processTempSpecial(processData) {
    let exes; let candy;
    if (processData instanceof Candy) {
      candy = processData;
      exes = candy.getSpecialExe();
    } else {
      candy = this._board.getCandy(processData.cellX, processData.cellY);
      exes = processData.exe;
    }
    if (!exes) return;
    for (let i = 0; i < exes.length; i++) {
      const currentExe = exes[i];
      if (currentExe[0] === 'loop') { this._processSpecialExeLoop(candy, currentExe[1]); }
      if (currentExe[0] === 'specific') { this._processSpecialExeSpecific(candy, currentExe[1]); }
      if (currentExe[0] === 'matchType') { this._processSpecialExeMatchType(candy, currentExe[1]); }
      if (currentExe[0] === 'changeTypeInto') { this._processSpecialExeChangeTypeInto(candy, currentExe[1], currentExe[2]); }
      if (currentExe[0] === 'perform') { this._processSpecialExePerform(candy, currentExe[1]); }
      if (currentExe[0] === 'superSpiral') { this._processSpecialExeSuperSpiral(candy, currentExe[1]); }
    }
    this._copyTempGridToMatchGrid();
  }

  /**
   * looks for and marks specials that need processing
   * @param {Candy} candy
   * @param {Object<{x,y}>} posObj
   */
  _processSpecialExeLoop(candy, posObj) {
    const { gameHooks } = this._board;
    gameHooks.playSound('line');

    let { cellX, cellY } = candy;

    while (this._board.isCellInBoardArea(cellX, cellY)) {
      // check if cell is matchable and not marked for match on matchgrid
      this._tempCheckAndMark(cellX, cellY);
      cellX += posObj.x; cellY += posObj.y;
    }
  }

  /**
   * execute specific special candy processes
   * @param {Candy} candy
   * @param {String} name name of process to execute
   */
  _processSpecialExePerform(candy, name) {
    candy[name]();
  }

  /**
   * mark specific specials that need processing
   * @param {Candy} candy
   * @param {Array} posArray
   */
  _processSpecialExeSpecific(candy, posArray) {
    const { gameHooks } = this._board;
    gameHooks.playSound('boom');

    const { cellX, cellY } = candy;
    G.sb('fx').dispatch('explosion', candy);
    for (let i = 0; i < posArray.length; i += 2) {
      this._tempCheckAndMark(cellX + posArray[i], cellY + posArray[i + 1]);
    }
  }

  /**
   * process match type specials
   * @param {Candy} candy
   * @param {string} exeType
   */
  _processSpecialExeMatchType(candy, exeType) {
    const { gameHooks } = this._board;
    gameHooks.playSound('lightning');

    // if LASTMOVEDWITH get type of last candy that was moved with, if not pick random type.
    if (exeType === 'LASTMOVEDWITH') {
      if (candy.lastMovedWith) {
        exeType = candy.lastMovedWith.getMatchToken();
      } else {
        exeType = game.rnd.between(1, this._board.MAX_NUMBER_OF_REGULAR_CANDY);
      }
    }
    if (exeType === 'CANDYTYPE') {
      exeType = candy.getMatchToken();
    }

    // if candy is still on board, match it cell (it can be out if it is daleyed, that why there is if)
    if (this._board.getCandy(candy.cellX, candy.cellY) === candy) {
      this._tempGrid.set(candy.cellX, candy.cellY, TOKEN_TYPES.TEMP_MATCH);
    }

    this._board.candiesLayer.grid.loop((elem, x, y) => {
      if (elem && elem.getMatchToken() === exeType) {
        if (this._tempCheckAndMark(x, y, true)) {
          G.sb('fx').dispatch('lightning', candy, [x, y]);
        }
      }
    }, this);
  }

  /**
   * process changeInto type specials
   * @param {Candy} candy
   * @param {string} exeType
   */
  _processSpecialExeChangeTypeInto(candy, changeTarget, changeInto) {
    // if LASTMOVEDWITH get type of last candy that was moved with, if not pick random type
    if (changeTarget === 'CANDYTYPE') changeTarget = candy.getMatchToken();
    if (changeInto === 'SPECIALLASTMOVED') changeInto = candy.lastMovedWith.getSpecialTypeName();

    // if candy is still on board, match it cell (it can be out if it is delayed, that why there is if)
    if (this._board.getCandy(candy.cellX, candy.cellY) === candy) {
      this._tempGrid.set(candy.cellX, candy.cellY, TOKEN_TYPES.TEMP_MATCH);
    }

    this._board.candiesLayer.grid.loop((elem, x, y) => {
      if (elem && elem.getMatchToken() === changeTarget && !elem.hasSpecialType() && elem !== candy) {
        if (this._board.isCellMatchable(x, y) && this._board.isMoveable(x, y)) {
          this._board.checkAfterFall.push(elem);
          elem.changeInto(changeInto);
          G.sb('fx').dispatch('lightning', candy, [x, y]);
        }
      }
    }, this);
  }

  /**
   * process super spiral type specials
   */
  _processSpecialExeSuperSpiral() {
    this._board.boardGridData.loop((val, x, y) => {
      if (this._board.isCellOnBoard(x, y)) {
        this._tempCheckAndMark(x, y);
      }
    }, this);
  }

  /**
   * mark cells for matching / procesing in the temp grids
   * @param {number} cellX
   * @param {number} cellY
   * @param {boolean} hitOnlyIfMatch
   */
  _tempCheckAndMark(cellX, cellY, hitOnlyIfMatch) {
    if (!hitOnlyIfMatch) this._hitGrid.set(cellX, cellY, true);
    if (this._board.isCellMatchable(cellX, cellY) && !this._grid.get(cellX, cellY)) {
      const candy = this._board.getCandy(cellX, cellY);
      if (candy.hasSpecialType()) {
        this._specialCandiesToProcess.push(candy);
        this._tempGrid.set(cellX, cellY, TOKEN_TYPES.TEMP_MATCH_SPECIAL);
        this._hitGrid.set(cellX, cellY, true);
        return true;
      }

      this._tempGrid.set(cellX, cellY, TOKEN_TYPES.TEMP_MATCH);
      this._hitGrid.set(cellX, cellY, true);
      return true;
    }
    return false;
  }

  /**
   * process temp.. does something temporary and copies it to the match grid
   * @param {Candy} candy
   */
  _processTemp(candy) {
    const candiesInMatch = [candy];
    let currentCandy; let currentMatchCandy;
    let horPos; let vertPos; let allPos;

    // check candies that makes matches, and push them to candies in match
    for (let i = 0; i < candiesInMatch.length; i++) {
      currentCandy = candiesInMatch[i];
      allPos = []; // set all matches position to one array
      horPos = this.getHorizontalMatchPos(currentCandy, this.quickCheckCoords(currentCandy, HORIZONTAL_COORDINATES, false));
      vertPos = this.getVerticalMatchPos(currentCandy, this.quickCheckCoords(currentCandy, VERTICAL_COORDINATES, false));
      allPos = [...horPos, ...vertPos];
      // check if candy form position is already in candiesInMatch. if not - push it.
      for (let j = 0; j < allPos.length; j += 2) {
        currentMatchCandy = this._board.getCandy(allPos[j], allPos[j + 1]);
        if (candiesInMatch.indexOf(currentMatchCandy) === -1) {
          candiesInMatch.push(currentMatchCandy);
        }
      }
    }
    // use temp grid to mark all matches
    for (const matchedCandy of candiesInMatch) {
      if (matchedCandy.hasSpecialType()) {
        this._tempGrid.set(matchedCandy.cellX, matchedCandy.cellY, TOKEN_TYPES.TEMP_MATCH);
        this._specialCandiesToProcess.push(matchedCandy);
      } else {
        this._tempGrid.set(matchedCandy.cellX, matchedCandy.cellY, TOKEN_TYPES.TEMP_MATCH);
      }
    }
    // check if marks on tempGrid creates any special candy.
    // special candies have priorities, so we dont block more powerfull with less powerfull
    this._searchAndProcessSpecialsInTemp(candiesInMatch[0]);
    // copy the temp grid changes to the match grid
    this._copyTempGridToMatchGrid();
  }

  /**
   * search for and process specials in the temp grid
   * @param {Candy} priorityCandy
   */
  _searchAndProcessSpecialsInTemp(priorityCandy) {
    for (let specialIndex = 0; specialIndex < this._specialsCoordinates.length; specialIndex++) {
      for (let specialCoordIndex = 0; specialCoordIndex < this._specialsCoordinates[specialIndex][1].length; specialCoordIndex++) {
        const pattern = this._tempGrid.findPattern(this._specialsCoordinates[specialIndex][1][specialCoordIndex], TOKEN_TYPES.TEMP_MATCH);
        if (pattern) {
          if (this._pushSpecialToTempGrid(pattern, this._specialsCoordinates[specialIndex][0], priorityCandy)) {
            specialCoordIndex--;
          }
        }
      }
    }
  }

  /**
   * push a special to the temp grid
   * @param {Array} coords
   * @param {Array} special
   * @param {Candy} priorityCandy
   * @returns {boolean}
   */
  _pushSpecialToTempGrid(coords, special, priorityCandy) {
    const changeArray = ['change', special];
    let moveToX = coords[0]; let moveToY = coords[1];
    let markedChange = false; let anyChanges = false;

    // special appears at position of moved candy
    if (priorityCandy) {
      for (let i = 0; i < coords.length; i += 2) {
        if (coords[i] === priorityCandy.cellX && coords[i + 1] === priorityCandy.cellY
          && !this._board.isBoosterChangeBlocked(coords[i], coords[i + 1])) {
          markedChange = true;
          moveToX = coords[i];
          moveToY = coords[i + 1];
          this._tempGrid.set(coords[i], coords[i + 1], changeArray);
          anyChanges = true;
          break;
        }
      }
    }

    for (let i = 0; i < coords.length; i += 2) {
      if (i === 0 && !markedChange && !this._board.isBoosterChangeBlocked(coords[i], coords[i + 1])) { // change candy into special one
        this._tempGrid.set(coords[i], coords[i + 1], changeArray);
        anyChanges = true;
      } else if (this._tempGrid.get(coords[i], coords[i + 1]) !== changeArray // mark animation for merging candies
        && !this._board.getCandy(coords[i], coords[i + 1]).hasStatus()
        && !this._board.concreteLayer.isToken(coords[i], coords[i + 1])) {
        this._tempGrid.set(coords[i], coords[i + 1], ['match-move', 0, moveToX, moveToY]);
        anyChanges = true;
      }
    }
    return anyChanges;
  }

  /**
   * copy the temp grid to the match grid
   */
  _copyTempGridToMatchGrid() {
    const { lvlDataManager } = this._board;
    let nrOfElements = 0;
    let totalX = 0; let totalY = 0;
    const colors = []; let expColor = false;

    this._tempGrid.loop((elem, x, y) => {
      if (elem) {
        nrOfElements++; totalX += x; totalY += y;
        const candy = this._board.getCandy(x, y);
        if (candy && colors.indexOf(candy.getMatchToken()) === -1) colors.push(candy.getMatchToken());
        if (elem === TOKEN_TYPES.TEMP_MATCH_SPECIAL) this._grid.set(x, y, TOKEN_TYPES.TEMP_MATCH);
        else this._grid.set(x, y, elem);
      }
    }, this);
    if (colors.length === 1) [expColor] = colors;
    if (nrOfElements > 0) {
      lvlDataManager.processMatch(this._board, nrOfElements, totalX / nrOfElements, totalY / nrOfElements, expColor);
    }
    this._tempGrid.clear();
  }

  /**
   * quick check to see if neighbors with offsets are matchable with given candy ( max length 3 )
   * @param {Candy} candy
   * @param {Array} offsetList list of offset [x,y] to check
   * @returns {boolean}
   */
  quickCheckCoords(candy, offsetList) {
    const { cellX, cellY } = candy;
    let test;
    for (const offset of offsetList) {
      test = true;
      for (let j = 0; j < offset.length; j += 2) {
        if (!this._board.isCellMatchable(cellX + offset[j], cellY + offset[j + 1], candy)) {
          test = false; break;
        }
      }
      if (test) return true;
    }
    return false;
  }

  /**
   * get the full Array of coordinates of matching horizontal cells
   * @param {Candy} candy
   * @param {boolean} match true if a match was detected
   * @returns {Array}
   */
  getHorizontalMatchPos(candy, match) {
    const result = [];
    const { cellX, cellY } = candy;
    if (!match) return result;

    // gather horizontal matching cells and append to the result
    let left = cellX; let right = cellX;
    result.push(cellX, cellY);

    while (this._board.isCellMatchable(--left, cellY, candy) && !this._grid.get(left, cellY)) {
      result.push(left, cellY);
    }
    while (this._board.isCellMatchable(++right, cellY, candy) && !this._grid.get(right, cellY)) {
      result.push(right, cellY);
    }
    return result;
  }

  /**
   * get the full Array of coordinates of matching vertical cells
   * @param {Candy} candy
   * @param {boolean} match true if a match was detected
   * @returns {Array}
   */
  getVerticalMatchPos(candy, match) {
    const result = [];
    const { cellX, cellY } = candy;
    if (!match) return result;

    // gather vertical matching cells and append to the result
    let up = cellY; let down = cellY;
    result.push(cellX, cellY);

    while (this._board.isCellMatchable(cellX, --up, candy) && !this._grid.get(cellX, up)) {
      result.push(cellX, up);
    }
    while (this._board.isCellMatchable(cellX, ++down, candy) && !this._grid.get(cellX, down)) {
      result.push(cellX, down);
    }
    return result;
  }

  /**
   * destruction mthod
   */
  destroy() {
    this._board = null;
    this._specialsCoordinates = null;
    this._grid = null;
    this._tempGrid.destroy(); this._tempGrid = null;
    this._hitGrid.destroy(); this._hitGrid = null;
    this._candiesToProcess = null;
    this._specialCandiesToProcess = null;
  }
}
