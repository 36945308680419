import { UI_TournamentIcon } from '../../Elements/UI_TournamentIcon';
import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

const BTN_MAIN_POS = { x: -45, y: 0 };
const BTN_MAIN_TEXT_STYLE = { style: 'font-white', fontSize: 60 };

const BTN_SUB_TEXT_OFFSET = { x: 0, y: 20 };
const BTN_SUB_TEXT_STYLE = { style: 'font-white', fontSize: 20 };

const MIN_FRIENDS_IN_TOURNAMENT = 3; // minimum friends to show button sub-text.

/**
 * Button for pushing the tournament promo
 */
export default class OMT_UI_TournamentPromoButton extends UI_GButtonES6 {
  /**
   * A button so the player can easily navigate to the tournament.  Appears on Window_level and Window_noMoreLives
   */
  constructor() {
    super(0, 0, null);

    this._initBannerBg();
    this._initButtonText();
    this._initTournamentPromoIcon();
    this._initCompeteSubText();
  }

  /**
   * create the banner background graphic
   */
  _initBannerBg() {
    const bg = new G.Image(0, 0, 'tournament_promo_banner', 0.5);
    bg.scale.set(0.75);
    this.addChild(bg);
    this._bg = bg;
  }

  /**
   * create the UI_TournamentIcon in PROMO state
   */
  _initTournamentPromoIcon() {
    const icon = new UI_TournamentIcon();
    icon.setPromoState();
    icon.x = this._bg.width / 2 - icon.width / 2 + 50;
    icon.y = -7;
    this.addChild(icon);
    this._promoIcon = icon;
  }

  /**
   * create main button text field
   */
  _initButtonText() {
    const buttonTextField = new G.Text(BTN_MAIN_POS.x, BTN_MAIN_POS.y, OMT.language.getText('Tournament'), BTN_MAIN_TEXT_STYLE, 0.5);
    this.addChild(buttonTextField);
    this._buttonTextField = buttonTextField;
  }

  /**
   * create the sub-text under the button
   */
  async _initCompeteSubText() {
    // check how many friends are playing the tournament
    const friends = await OMT.friends.getFriendsList();
    const friendsPlayingTourney = friends.length;
    if (friendsPlayingTourney < MIN_FRIENDS_IN_TOURNAMENT) return;

    const competeString = OMT.language.getText('Compete against %number% friends');
    const competeTxt = new G.Text(BTN_SUB_TEXT_OFFSET.x, 0, competeString.replace('%number%', friendsPlayingTourney), BTN_SUB_TEXT_STYLE, 0.5);
    competeTxt.y = (this._bg.height / 2) + BTN_SUB_TEXT_OFFSET.y;
    this.addChild(competeTxt);
  }

  /**
   * proxy for showTournament in UI_TournamentIcon
   */
  async showTournamentWindow() {
    await this._promoIcon.showTournamentWindow();
  }
}
