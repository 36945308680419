export default class FxParticle extends Phaser.Image {
  /**
   * An fx particle that does most of all the particle effects on the game state (and tutorials and fortune cookie)
   * But it doesn't really use an animated sprite sheet...
   * It uses some weird update function that ticks on every update that progresses through the spritesheet.
   *
   * Most functions are accessed externally and play the effect that the function name says.
   * So I will leave it at that.
   * @param {Board} [board]
   * @param {any} [fxGroup]
   */
  constructor(board, fxGroup) {
    super(game);

    this._board = board;
    this._fxGroup = fxGroup;
    this.anchor.setTo(0.5);
    this.kill();

    this._animationData = {
      currentIndex: 0,
      currentTimer: 0,
      timer: 3,
      loop: 0,
      maxFrame: 0,
      gfxName: '',
    };
  }

  destroy() {
    super.destroy();
  }

  /**
   * Positions the particle at x, y and revives itself. Called by other parts of the code
   * @param {number} x
   * @param {number} y
   */
  init(x, y) {
    this.x = x;
    this.y = y;
    this.blendMode = 0;
    this.alpha = 1;
    this.angle = 0;
    this.scale.setTo(1);
    this._updateFunc = this.emptyFunc;
    this.anchor.setTo(0.5);
    this.revive();
  }

  getOther() {
    return this.parent.getFreeParticle();
  }

  update() {
    if (!this.alive) return;

    this._updateFunc();
  }

  updateAnimation() {
    this._animationData.currentTimer += G.deltaTime;

    if (this._animationData.currentTimer >= this._animationData.timer) {
      this._animationData.currentIndex++;
      this._animationData.currentTimer -= this._animationData.timer;

      if (this._animationData.currentIndex > this._animationData.maxFrame) {
        if (this._animationData.loop === 0) {
          this.kill();
          return;
        }
        this._animationData.loop--;
        this._animationData.currentIndex = 0;
      }
      G.changeTexture(this, this._animationData.gfxName + this._animationData.currentIndex);
    }
  }

  initAnimation(gfxName, maxFrame, timer, loop, startingIndex) {
    this._animationData.currentIndex = startingIndex || 0;
    this._animationData.currentTimer = 0;
    this._animationData.timer = timer;
    this._animationData.gfxName = gfxName;
    this._animationData.maxFrame = maxFrame;
    this._animationData.loop = loop || 0;
    G.changeTexture(this, gfxName + this._animationData.currentIndex);
    this._updateFunc = this.updateAnimation;
  }

  /**
   * Used for replacing this._updateFunc with nothing
   */
  emptyFunc() {}

  explosion(x, y) {
    this.init(x, y);
    this.initAnimation('cookie_match_', 10, 2, 0, 1);
  }

  spiral(x, y) {
    this.init(x, y);
    this.initAnimation('candy_spiral_explode_', 13, 2);
  }

  dummyFadeOut(x, y, args) {
    this.init(x, y);
    G.changeTexture(this, args);
    game.add.tween(this).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true).onComplete.add(this.kill, this);
  }

  dummyFadeOutScaleIn(x, y, args) {
    this.init(x, y);
    G.changeTexture(this, args);
    game.add.tween(this.scale).to({ x: 0, y: 0 }, 300, Phaser.Easing.Sinusoidal.In, true).onComplete.add(this.kill, this);
  }

  dummyComboGrowAndFade(x, y, args) {
    this._fxGroup.aboveThirdFloorLayer.add(this);
    this.init(x, y);
    G.changeTexture(this, args[0]);
    this.angle = args[1]; // eslint-disable-line prefer-destructuring
    this.alpha = 0.8;

    const scaleTween = game.add.tween(this.scale).to({ x: 2.5, y: 2.5 }, 200, Phaser.Easing.Sinusoidal.In, true);
    game.add.tween(this).to({ alpha: 0 }, 100, Phaser.Easing.Sinusoidal.In, true, 100).onComplete.add(() => {
      scaleTween.stop();
      this._fxGroup.add(this);
      this.kill();
    });
  }

  electricCircle(x, y) {
    this.init(x, y);
    this.blendMode = 1;
    G.loadTexture(this, 'circle');
    game.add.tween(this).to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.Out, true, 300).onComplete.add(this.kill, this);
    this._updateFunc = this.electricCircleUpdate;

    this.other = this.getOther();
    this.other.blendMode = 1;
    G.loadTexture(this.other, 'circle');
    this.other.updateFunc = this.other.electricCircleUpdate;
    game.add.tween(this.other).to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.Out, true, 300).onComplete.add(this.other.kill, this.other);
  }

  burstingCoin(x, y) {
    this.init(x, y);
    G.changeTexture(this, 'coin_1');

    this._orgY = y;
    this.scale.setTo(0.5);
    this._velX = game.rnd.realInRange(-3, 3);
    this._velY = game.rnd.realInRange(-10, -5);
    this._grav = 0.5;
    this._timer = game.rnd.between(40, 60);
    this._updateFunc = this.burstingCoinUpdate;
  }

  burstingCoinUpdate() {
    this.x += this._velX * G.deltaTime;
    this._velX -= this._velX * 0.02 * G.deltaTime;
    this.y += this._velY * G.deltaTime;

    if (this.y > this._orgY) {
      this.y = this._orgY;
      this._velY *= -1;
    }
    this._velY += this._grav * G.deltaTime;
    this._timer--;

    if (this._timer < 0) {
      this.alpha -= 0.05 * G.deltaTime;
      if (this.alpha <= 0) {
        this.kill();
      }
    }
  }

  electricCircleUpdate() {
    this.scale.setTo(1 + Math.random() * 0.5);
  }

  smallCircle(x, y) {
    this.init(x, y);
    this.blendMode = 1;
    G.loadTexture(this, 'circle');
    this.scale.setTo(0);
    this.alpha = 0.5;
    game.add.tween(this.scale).to({ x: 0.5, y: 0.5 }, 150, Phaser.Easing.Cubic.Out, true);
    game.add.tween(this).to({ alpha: 0 }, 150, Phaser.Easing.Cubic.Out, true, 200).onComplete.add(this.kill, this);
  }

  lightCircle(x, y) {
    this.init(x, y);
    this.blendMode = 1;
    G.loadTexture(this, 'circle');
    this.scale.setTo(0);
    game.add.tween(this.scale).to({ x: 1.5, y: 1.5 }, 500, Phaser.Easing.Cubic.Out, true);
    game.add.tween(this).to({ alpha: 0 }, 300, Phaser.Easing.Cubic.Out, true, 200).onComplete.add(this.kill, this);
  }

  lightCircleFast(x, y) {
    this.init(x, y);
    this.blendMode = 1;
    G.loadTexture(this, 'circle');
    this.scale.setTo(0);
    game.add.tween(this.scale).to({ x: 1.5, y: 1.5 }, 300, Phaser.Easing.Cubic.Out, true);
    game.add.tween(this).to({ alpha: 0 }, 200, Phaser.Easing.Cubic.Out, true, 100).onComplete.add(this.kill, this);
  }

  changeCircle(x, y) {
    this.init(x, y);
    this.blendMode = 1;
    G.loadTexture(this, 'circle');
    this.scale.setTo(0.6);
    game.add.tween(this.scale).to({ x: 1.5, y: 1.5 }, 600, Phaser.Easing.Cubic.Out, true);
    game.add.tween(this).to({ alpha: 0 }, 600, Phaser.Easing.Cubic.Out, true).onComplete.add(this.kill, this);
  }

  initStroke(x, y, candy, angle) {
    this.init(x, y);
    const parsetType = parseInt(candy.candyType);
    let sprite = `line_effect_${game.rnd.between(1, 6)}`;

    if (parsetType >= 1 && parsetType <= 6) {
      sprite = `line_effect_${parsetType}`;
    }

    G.changeTexture(this, sprite);
    this.angle = angle || 0;
    game.add.tween(this.scale).to({ y: 15 }, 500, Phaser.Easing.Sinusoidal.Out, true);
    game.add.tween(this).to({ alpha: 0 }, 100, Phaser.Easing.Cubic.In, true, 400).onComplete.add(this.kill, this);
  }

  strokeH(x, y, args, candy) {
    this.initStroke(x, y, candy, 90);
  }

  strokeV(x, y, args, candy) {
    this.initStroke(x, y, candy, 0);
  }

  strokeDR(x, y, args, candy) {
    this.initStroke(x, y, candy, -45);
  }

  strokeDF(x, y, args, candy) {
    this.initStroke(x, y, candy, 45);
  }

  lightning(x, y, args) {
    this.init(x, y);

    G.changeTexture(this, 'lightning');
    this.anchor.setTo(0.5, 0);

    const x2 = this._board.cellXToPxIn(args[0]);
    const y2 = this._board.cellYToPxIn(args[1]);

    this.height = game.math.distance(x, y, x2, y2);
    this.rotation = game.math.angleBetween(x, y, x2, y2);
    this.angle -= 90;
    this._timer = 0;

    this._updateFunc = this.lightningUpdate;

    game.add.tween(this).to({ alpha: 0 }, 500, Phaser.Easing.Cubic.In, true).onComplete.add(this.kill, this);
  }

  lightningUpdate() {
    this._timer += 1 * G.deltaTime;
    if (this._timer > 2) {
      this.scale.x *= -1;
      this._timer = 0;
    }
  }

  chocolatePart(x, y) {
    this.init(x, y);
    this.x += Math.floor((Math.random() * 40) - 20);
    this.y += Math.floor((Math.random() * 40) - 20);
    G.changeTexture(this, 'chocolatePiece');
    this.scale.setTo(0.8);
    this.angle = Math.random() * 360;
    this._velX = Math.random() * -12 + 6;
    this._velY = Math.random() * -6 - 4;
    this._gravity = 0.6;
    this._updateFunc = this.fallingPartUpdate;
  }

  chocolatePartW(x, y) {
    this.init(x, y);
    this.x += Math.floor((Math.random() * 40) - 20);
    this.y += Math.floor((Math.random() * 40) - 20);
    G.changeTexture(this, 'chocolatePieceW');
    this.scale.setTo(0.8);
    this.angle = Math.random() * 360;
    this._velX = Math.random() * -12 + 6;
    this._velY = Math.random() * -6 - 4;
    this._gravity = 0.6;
    this._updateFunc = this.fallingPartUpdate;
  }

  burstConcrete(x, y, offsetX, offsetY, gfx) {
    this.init(x + Math.floor(offsetX), y + Math.floor(offsetY));

    G.changeTexture(this, gfx);

    this.burstConcreteVelX = Math.sign(offsetX) * (2 + Math.random() * 3);
    this.burstConcreteVelY = -3 + (Math.random() * -3);
    this.burstConcreteGrav = 0.6;
    this._updateFunc = this.burstConcreteUpdate;
  }

  burstConcreteUpdate() {
    this.x += this.burstConcreteVelX * G.deltaTime;
    this.y += this.burstConcreteVelY * G.deltaTime;

    this.angle += this.burstConcreteVelX * 2 * G.deltaTime;

    this.burstConcreteVelX -= this.burstConcreteVelX * (1 - 0.98) * G.deltaTime;
    this.burstConcreteVelY += this.burstConcreteGrav * G.deltaTime;

    this.alpha -= 0.03 * G.deltaTime;
    this.scale.setTo(this.scale.x + 0.01);
    if (this.alpha <= 0) {
      this.kill();
    }
  }

  burstCandy(x, y) {
    this.init(x, y);

    this.alpha = 1;
    this.scale.setTo(1.5);

    this.initAnimation('cookie_match_', 10, 2, 0, 1);
  }

  burstIce(x, y) {
    this.init(x, y);

    this.alpha = 1;
    this.scale.setTo(1);

    this.initAnimation('ice_part_', 13, 2, 0, 1);
  }

  burstConcreteAnim(x, y) {
    this.init(x, y);

    this.alpha = 1;
    this.scale.setTo(1);

    this.initAnimation('concrete_part_', 17, 2, 0, 0);
  }

  burstDirtAnim(x, y) {
    this.init(x, y);

    this.alpha = 1;
    this.scale.setTo(1);

    this.initAnimation('dirt_part_', 16, 2, 0, 0);
  }

  burstInfectionAnim(x, y) {
    this.init(x, y);

    this.alpha = 1;
    this.scale.setTo(1);

    this.initAnimation('infection_part_', 18, 2, 0, 0);
  }

  burstChainAnim(x, y) {
    this.init(x, y);

    this.alpha = 1;
    this.scale.setTo(1);

    this.initAnimation('unwrap_part_', 14, 2, 0, 0);
  }

  whiteStarPart(x, y) {
    this.init(x, y);
    G.changeTexture(this, 'starPart');
    this.blendMode = 1;

    this.angle = Math.random() * 360;
    this._velX = Math.random(20) * -20 + 10;
    this._velY = Math.random() * -9 - 3;
    this._gravity = 0.5;
    this._updateFunc = this.fallingPartUpdate;
  }

  fallingPartUpdate() {
    this.x += this._velX * G.deltaTime;
    this.y += this._velY * G.deltaTime;
    this.angle += this._velX * 0.1 * G.deltaTime;
    this._velX -= this._velX * (1 - 0.99) * G.deltaTime;
    this._velY += this._gravity * G.deltaTime;
    this.alpha -= 0.02 * G.deltaTime;

    if (this.alpha <= 0) this.kill();
  }

  whiteStarPartFast(x, y) {
    this.init(x, y);
    G.changeTexture(this, 'starPart');
    this.blendMode = 1;

    this.angle = Math.random() * 360;
    this._velX = Math.random(20) * -20 + 10;
    this._velY = Math.random() * -9 - 3;
    this._gravity = 0.25;
    this._updateFunc = this.fallingPartUpdate;
  }
}
