/**
 * No moves shuffling message
 */
export class ShuffleMsg {
  /**
   * constructor
   * @param {BoardGameHooks} gameHooks
   */
  constructor(gameHooks) {
    this._gameHooks = gameHooks;
    this._initShuffleText();
  }

  /**
   * intialize the shuffle msg text
   */
  _initShuffleText() {
    this._shuffleText = new G.Text(0, 0, this._gameHooks.getText('No possible match - shuffling'), {
      style: 'font-white',
      fontSize: '70px',
    }, 0.5, 620);
  }

  /**
   * animate the message
   */
  animateInAndOut(callback) {
    // animate in
    this._shuffleText.scale.setTo(0);
    game.add.tween(this._shuffleText.scale)
      .to({ x: 1, y: 1 }, 400, Phaser.Easing.Elastic.Out, true);

    // delayed animate out
    game.add.tween(this._shuffleText)
      .to({ alpha: 0 }, G.IMMEDIATE ? 1 : 300, Phaser.Easing.Sinusoidal.Out, true, G.IMMEDIATE ? 10 : 2000)
      .onComplete.add(callback);
  }

  /**
   * destruction method
   */
  destroy() {
    if (!this._shuffleText) return;
    if (this._shuffleText.parent) {
      this._shuffleText.parent.removeChild(this._shuffleText);
    }
    this._shuffleText.destroy();
    this._shuffleText = null;
  }

  /**
   * get a reference to the relevant sprite
   * @returns {Phaser.Sprite}
   */
  get sprite() {
    return this._shuffleText;
  }
}
