import { MainLeaderboardPanel } from '../MainLeaderboardPanel';
import { FriendsScoresExpanded } from './FriendsScoresExpanded';
import { FriendsScoresCollapsed } from './FriendsScoresCollapsed';
import { OMT_TexturePreloader } from '../../../Utils/OMT_TexturePreloader';

import {
  EXPANDED_LIST_ROW_HEIGHT,
  PANEL_CONTENT_RECT,
} from '../../MainLeaderboardSettings';
import OMT_TierLeaderboardUtil from '../../../Utils/OMT_TierLeaderboardUtil';
import { PUBLIC_USER_BADGE_KEY } from '../../../Services/OMT/dataTracking/badgeManager/BadgeManager';

const PANEL_NAME = 'Friends';

/**
 * friends scores leaderboard panel
 */
export class FriendsScoresPanel extends MainLeaderboardPanel {
  /**
   * constructor
   * @param {number} tabIndex
   * @param {Function} rewardCoinsCallback
   */
  constructor(tabIndex, rewardCoinsCallback) {
    super(PANEL_NAME, tabIndex);

    this._rewardCoinsCallback = rewardCoinsCallback;

    this._initialized = false;
    // this._initAsync();
  }

  /**
   * async initialization function
   * @returns {Promise}
   */
  async _initAsync() {
    this._loadingIndicator.show();
    const userList = await OMT.leaderboards.getTotalStarsLeaderboardEntries();
    if (userList.length > 0) {
      await OMT_TexturePreloader.getInstance().loadTexturesAsync(userList.map((userData) => userData.image));
      // await OMT_TierLeaderboardUtil.getBadgesForUsers(userList, PUBLIC_USER_BADGE_KEY);
      await this._initExpandedContent(userList);
      await this._initCollapsedContent(userList);
      await this._loadingIndicator.hide();
      this._initialized = true;
    } else {
      await this._loadingIndicator.hide();
      this._showErrorText();
    }

    // refresh the content state
    await this._fadeInMainContentGroupAsync();
    this._updateContentState();
  }

  /**
   * initialize the expanded content
   * @param {Array} userList
   * @returns {Promise}
   */
  async _initExpandedContent(userList) {
    const scrollRect = new Phaser.Rectangle(0, 0, PANEL_CONTENT_RECT.width, PANEL_CONTENT_RECT.height);
    this._expandedView = new FriendsScoresExpanded();
    this._expandedView.setUserList(userList);
    await this._expandedView.init(EXPANDED_LIST_ROW_HEIGHT, scrollRect);
    this._expandedView.setContentAlpha(0);
    this._expandedView.x = PANEL_CONTENT_RECT.x; this._expandedView.y = PANEL_CONTENT_RECT.y;
    this._expandedView.signals.onCoinRequestSuccess.add(this._onCoinRequestSuccess, this);
    this._expandedContent.addChild(this._expandedView);
  }

  /**
   * initialize the collapsed content
   * @param {Array} userList
   * @returns {Promise}
   */
  async _initCollapsedContent(userList) {
    this._collapsedView = new FriendsScoresCollapsed();
    await this._collapsedView.init(userList);
    this._collapsedView.setContentAlpha(0);
    this._collapsedView.x = PANEL_CONTENT_RECT.x; this._collapsedView.y = PANEL_CONTENT_RECT.y;
    this._collapsedView.signals.onCoinRequestSuccess.add(this._onCoinRequestSuccess, this);
    this._collapsedContent.addChild(this._collapsedView);
  }

  /**
   * called on a successfull coin request action
   * @param {FriendsScoresCollapsedUserView || FriendsScoresExpandedUserView} userView
   */
  _onCoinRequestSuccess(userView) {
    this._rewardCoinsCallback(userView.friendData.userId, userView.getCoinAnimateFromPos());
  }

  /**
   * update the content state. triggers on focus, blur, onCollapseComplete, onExpandComplete
   */
  _updateContentState() {
    super._updateContentState();
    if (!this._initialized) return;

    this._expandedView.scrollArea.jumpToContentPosition(0);
    this._collapsedView.scrollArea.jumpToContentPosition(0);

    if (this.isExpanded) { // expanded
      if (this.inFocus) { // in focus
        this._expandedView.setContentFadeInTransition(() => {
          if (this.isExpanded && this.inFocus) this._expandedView.enable();
        });
      } else { // blurred
        this._expandedView.disable();
      }
      this._collapsedView.setContentAlpha(0);
      this._collapsedView.disable();
    } else { // collapsed
      if (this.inFocus) { // in focus
        this._collapsedView.enable();
        this._collapsedView.setContentFadeInTransition();
      } else { // blurred
        this._collapsedView.disable();
      }
      this._expandedView.setContentAlpha(0);
      this._expandedView.disable();
    }
  }

  /** @returns {number} */
  get collapsedHeight() {
    if (this._collapsedView) {
      return this._collapsedView.collapsedHeight;
    }
    return 0;
  }
}
