import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

class Window_EraseProgress extends Window {
  /**
   * constructor
   * @param {Object} parentWindow
   */
  constructor(parentWindow) {
    super(parentWindow);

    // bg black overlay
    this.add(G.GiftUI.Elements.Overlay());

    // create content group
    this._group = new Phaser.Group(G.game, this);
    this.add(this._group);

    this._initLayout();
    this._initButtons();

    // reposition group after content is added
    // this._group.y -= this._group.height / 3;
  }

  /**
   * init layout
   */
  _initLayout() {
    const title = new G.Text(0, 0, OMT.language.getText('Warning!'), { style: 'font-white', fontSize: 105 }, 0.5, 640, 400, true, 'center');
    const safeSpot = title.y + title.height;
    const text = new G.Text(0, safeSpot, OMT.language.getText('This will delete all your progress and automatically close the game!'),
      { style: 'font-blue', fontSize: 24 }, 0.5, 420, 640, true, 'center');

    this._bg = new PhaserNineSlice.NineSlice(0, text.y, 'buttons', 'settings_area', text.width * 1.2, text.height * 2, { top: 25 }); // 25 for all corners
    this._bg.anchor.set(0.5);

    text.y += this._bg.height / 4;
    this._bg.y += this._bg.height / 4;

    this._group.addChild(this._bg);
    this._group.addChild(text);
    this._group.addChild(title);
  }

  /**
   * init buttons
   */
  _initButtons() {
    const onClick = async () => {
      await G.saveState.resetAllData();

      // quit game, add a small delay in dev environment to make sure button can't be pressed twice
      const delay = G.BuildEnvironment.version === 'DEV' ? 2000 : 0;
      setTimeout(OMT.platformFunctions.quitGame, delay);
    };

    const deleteButton = new OMT_UI_SquareButton(0, this._bg.y + this._bg.height, {
      button: {
        tint: BUTTONCOLOURS.red,
        dimensions: {
          width: 166,
          height: 86,
        },
      },
      text: {
        string: OMT.language.getText('Delete'),
        textStyle: { style: 'font-white', fontSize: 120 },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
          disableAfterClick: true,
        },
      },
    });

    const closeButton = new G.Button(this._bg.x + this._bg.width / 2, this._bg.y - this._bg.height / 2, 'btn_x', () => {
      this.closeWindow();
    });
    this._group.addChild(deleteButton);
    this._group.addChild(closeButton);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.eraseProgress = Window_EraseProgress;
