/* eslint-disable wrap-iife */
G.Helpers = G.Helpers || {};

G.Helpers.levelThumbnailGenerator = (function () {
  let bitmapData = null;

  const lookUpObject = {
    1: 'candy_1',
    2: 'candy_2',
    3: 'candy_3',
    4: 'candy_4',
    5: 'candy_5',
    6: 'candy_6',
    r: 'candy_r',
    goalCandy: 'candy_goalCandy',
    cn1: 'concrete_1',
    cn2: 'concrete_2',
    cn3: 'concrete_3',
    dirt1: 'dirt_1',
    dirt2: 'dirt_2',
    dirt3: 'dirt_3',
    ice1: 'ice_front',
    ice2: 'ice_front',
    ice3: 'ice_front',
    chest: 'candy_chest',
    infection: 'candy_infection',
    dS1: 'dirt_s_1',
    dS2: 'dirt_s_2',
    dS3: 'dirt_s_3',
    jam1: 'jam_1',
    jam2: 'jam_2',
    jam3: 'jam_3',
  };

  return {
    generateRT,
    generateImage,
  };

  function generateImage(lvl, optWidth, optHeight) {
    return game.add.image(0, 0, generateRT(lvl, optWidth, optHeight));
  }

  function generateRT(lvl, optWidth, optHeight) {
    if (!bitmapData) bitmapData = createBitmapData();
    bitmapData.clear();
    const sprite = game.add.image(0, 0);

    const pxWidth = G.Utils.defined(optWidth, 400);
    const pxHeight = G.Utils.defined(optHeight, 400);
    const boardWidth = lvl.levelData.length;
    const boardHeight = lvl.levelData[0].length;
    const cellWidthPx = pxWidth / boardWidth;
    const cellHeightPx = pxHeight / boardHeight;
    const cellSize = Math.min(cellWidthPx, cellHeightPx);

    bitmapData.resize(pxWidth, pxHeight);

    for (let coll = 0; coll < boardWidth; coll++) {
      for (let row = 0; row < boardHeight; row++) {
        const cell = lvl.levelData[coll][row];

        if (cell[0] === 'X') {
          G.changeTexture(sprite, 'dark_screen');
          sprite.tint = 0x000000;
          bitmapData.draw(sprite, coll * cellSize, row * cellSize, cellSize, cellSize);
          sprite.tint = 0xffffff;
          continue;
        } else {
          G.changeTexture(sprite, 'tile_1');
          bitmapData.draw(sprite, coll * cellSize, row * cellSize, cellSize, cellSize);

          for (let elemI = 0; elemI < cell.length; elemI++) {
            const elem = cell[elemI];

            if (elem[2] === 'S') {
              G.changeTexture(sprite, 'candy_spiral');
              bitmapData.draw(sprite, coll * cellSize, row * cellSize, cellSize, cellSize);
              continue;
            } else if (elem[2] === 'H' || elem[2] === 'V' || elem[2] === 'C') {
              const texture = `candy_${elem[0]}_bonus_${[0, 'H', 'V', 'C'].indexOf(elem[2])}`;
              G.changeTexture(sprite, texture);
              bitmapData.draw(sprite, coll * cellSize, row * cellSize, cellSize, cellSize);
              continue;
            }

            if (elem[2] === 'W') {
              G.changeTexture(sprite, lookUpObject[elem[0]]);
              bitmapData.draw(sprite, coll * cellSize, row * cellSize, cellSize, cellSize);
              G.changeTexture(sprite, 'status_chain_2');
              sprite.alpha = 0.5;
              bitmapData.draw(sprite, coll * cellSize, row * cellSize, cellSize, cellSize);
              sprite.alpha = 1;
            } else if (lookUpObject[elem]) {
              G.changeTexture(sprite, lookUpObject[elem]);
              bitmapData.draw(sprite, coll * cellSize, row * cellSize, cellSize, cellSize);
            }
          }
        }
      }
    }

    const rt = game.make.renderTexture(pxWidth, pxHeight);

    const img = bitmapData.addToWorld();
    rt.render(img);

    img.destroy();
    sprite.destroy();

    return rt;
  }

  function createBitmapData() {
    return game.make.bitmapData(1, 1);
  }
})();
