import { TOKEN_TYPES } from './BoardConstants';

/**
 * class for displaying and managing board collect cells
 */
export class BoardCollectCells extends Phaser.Group {
  /**
   * constructor
   * @param {Board} board
   * @param {Object} lvlData
   */
  constructor(board, lvlData) {
    super(game);

    this.position = board.position;
    this.scale = board.scale;

    this._board = board;
    this._lvlData = lvlData;
    this._collectCells = [];

    if (this._lvlData.collectCells) {
      this._init(this._lvlData.collectCells);
    }
  }

  /**
   * intialize the collect cells
   * @param {Array} optCollectCells
   */
  _init(optCollectCells) {
    // add the collect cells
    const collectCells = G.Utils.defined(optCollectCells, []);
    for (const collectCell of collectCells) {
      this._addCollectCells(collectCell.x, collectCell.y);
    }
    // add global listeners
    this._addGlobalListeners();
  }

  /**
   * add global event listeners
   */
  _addGlobalListeners() {
    this._signalBindings = [
      G.sb('onCollectableTaskFinished').add(this._onCollectableTaskFinished, this),
      G.sb('onGoalAchieved').add(this._hide, this),
    ];
  }

  /**
   * remove global listeners set by _addGlobalListeners()
   */
  _removeGlobalListeners() {
    if (this._signalBindings == null) return;
    for (const signalBinding of this._signalBindings) signalBinding.detach();
    this._signalBindings.length = 0;
  }

  /**
   * global event when a collectable task is finished
   * @param {string} type
   */
  _onCollectableTaskFinished(type) {
    if (type === TOKEN_TYPES.GOAL_CANDY) this._hide();
  }

  /**
   * hide the collect cells
   */
  _hide() {
    if (this.alpha !== 1) return;
    game.add.tween(this).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true);
  }

  /**
   * add a collect cell at the specified position
   * @param {number} cellX
   * @param {number} cellY
   */
  _addCollectCells(cellX, cellY) {
    if (this.getCollectCell(cellX, cellY)) return;
    const x = cellX * this._board.tileSize; const y = cellY * this._board.tileSize;
    const collectCell = G.makeImage(x, y, 'collect_cell_2', 0, this);
    collectCell.cellX = cellX; collectCell.cellY = cellY;
    this._collectCells.push(collectCell);
  }

  /**
   * editor toggle collect cell at the specified position
   * @param {number} cellX
   * @param {number} cellY
   */
  editorChangeCC(cellX, cellY) {
    const collectCell = this.getCollectCell(cellX, cellY);
    if (collectCell) this.removeCollectCell(cellX, cellY);
    else this.addCollectCells(cellX, cellY);
    this._lvlData.collectCells = this.editorExport();
  }

  /**
   * get collect cell at the specified position
   * @param {number} cellX
   * @param {number} cellY
   * @returns {Phaser.Image}
   */
  getCollectCell(cellX, cellY) {
    return this._collectCells.find((collectCell) => collectCell.cellX === cellX && collectCell.cellY === cellY);
  }

  /**
   * remove all collect cells and update level data
   */
  removeAllCollectCells() {
    while (this._collectCells.length > 0) {
      const cellToRemove = this._collectCells[0];
      this.removeCollectCell(cellToRemove.cellX, cellToRemove.cellY);
    }
    this._lvlData.collectCells = this.editorExport();
  }

  /**
   * remove collect cell at the specified position
   * @param {number} cellX
   * @param {number} cellY
   */
  removeCollectCell(cellX, cellY) {
    const cellToRemove = this.getCollectCell(cellX, cellY);
    if (!cellToRemove) return;
    cellToRemove.destroy();
    this._collectCells.splice(this._collectCells.indexOf(cellToRemove), 1);
  }

  /**
   * export editor data
   * @returns {Array}
   */
  editorExport() {
    if (this._collectCells.length === 0) return null;
    return this._collectCells.map((collectCell) => ({ x: collectCell.cellX, y: collectCell.cellY }));
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._removeGlobalListeners();

    this._board = null;
    this._lvlData = null;
    this._collectCells = null;
  }
}
