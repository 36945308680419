/**
 * Class for managing Candy data / type definitions
 */
export default class CandyDataManager {
  /**
   * init with candy configurations
   * @param {Object} data
   */
  static init(data) {
    this._data = data;
  }

  /**
   * get BaseType data by id
   * @param {string} id
   * @returns {Object}
   */
  static getBaseData(id) {
    return this._data.baseTypes.find((type) => type.id === id);
  }

  /**
   * get SpecialType data by id
   * @param {string} id
   * @returns {Object}
   */
  static getSpecialData(id) {
    return this._data.specialTypes.find((type) => type.name === id);
  }

  /**
   * get CandyStatus data by id
   * @param {string} id
   * @returns {Object}
   */
  static getStatusData(id) {
    return this._data.statuses.find((status) => status.id === id);
  }

  /**
   * get BaseTypes with tags
   * @param {string} id
   * @returns {Array.<Object>}
   */
  static getTypesWithTag(tag) {
    return this._data.baseTypes.filter((type) => type.tags && type.tags.indexOf(tag) !== -1);
  }

  /**
   * get SpecialTypes with tags
   * @param {string} id
   * @returns {Array.<Object>}
   */
  static getSpecialTypesWithTag(tag) {
    return this._data.specialTypes.filter((type) => type.tags && type.tags.indexOf(tag) !== -1);
  }

  /**
   * gets BaseTypes data list
   * @returns {Array.<Object>}
   */
  static getAllBaseTypes() {
    return this._data.baseTypes;
  }

  /**
   * gets SpecialTypes data list
   * @returns {Array.<Object>}
   */
  static getAllSpecialTypes() {
    return this._data.specialTypes;
  }

  /**
   * gets CandyStatus data list
   * @returns {Array.<Object>}
   */
  static getAllStatuses() {
    return this._data.statuses;
  }

  /**
   * check if id is a SpecialType
   * @param {string} id
   * @returns {boolean}
   */
  static isTypeSpecial(id) {
    return Boolean(this._data.specialTypes.find((obj) => obj.name === id));
  }

  /**
   * get BaseType data by its editor symbol
   * @param {string} editorSymbol
   * @returns {Object}
   */
  static getBaseTypeByEditorSymbol(editorSymbol) {
    return this._data.baseTypes.find((obj) => obj.editorSymbol === editorSymbol);
  }

  /**
   * get SpecialType data by its editor symbol
   * @param {string} editorSymbol
   * @returns {Object}
   */
  static getSpecialTypeByEditorSymbol(editorSymbol) {
    return this._data.specialTypes.find((obj) => obj.editorSymbol === editorSymbol);
  }

  /**
   * get CandyStatus data by its editor symbol
   * @param {string} editorSymbol
   * @returns {Object}
   */
  static getStatusByEditorSymbol(editorSymbol) {
    return this._data.statuses.find((obj) => obj.editorSymbol === editorSymbol);
  }

  /**
   * get combo definition data
   * @returns {Array.<Object>}
   */
  static getCombos() {
    return G.Utils.defined(this._data.combos, []);
  }

  /**
   * get special pattern definition data
   * @returns {Array.<Object>}
   */
  static getSpecialPatterns() {
    return this._data.specialTypes
      .filter((type) => type.patterns)
      .map((type) => [type.name, type.patterns]);
  }

  /**
   * get combo type data for 2 specialType ids
   * @param {string} specialTypeA
   * @param {string} specialTypeB
   * @returns {Object}
   */
  static getTypesCombo(specialTypeA, specialTypeB) {
    return (
      this.getCombos().find(
        ([comboA, comboB]) => (specialTypeA === comboA && specialTypeB === comboB) || (specialTypeA === comboB && specialTypeB === comboA),
      ) || null
    );
  }
}
