/* eslint-disable func-names */
if (typeof G === 'undefined') G = {};
G.UI = G.UI || {};

G.UI.createGroup = (game, width, height, optName) => {
  // imports
  const { ElementType, createUIElement } = G.UI;

  return createUIElement(game, {
    version: 1,
    desiredSize: [width, height],
    rootElement: {
      type: ElementType.Group,
      name: optName || 'rootElement',
    },
  }).rootElement;
};

/**
 * @param {Array<String>} arr
 * @returns {function(Array<String>)}
 */
G.UI.createFilterToSkipExportNames = (exportNamesToSkip) => {
  const filter = (exportNames) => exportNames.every((e) => !exportNamesToSkip.includes(e));

  return filter;
};
