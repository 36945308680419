const FRAME_TARGET_TIME = 1000 / 60;

/**
 * class for stringing together update looops
 */
export default class UpdateSequenceController {
  /**
   * constructor
   */
  constructor() {
    this._reset();
  }

  /**
   * reset parameters
   */
  _reset() {
    this._target = null;
    this._updateSteps = null;
    this._activeStep = null;
    this._stepTime = 0;
    this._stepDuration = 0;
    this._onComplete = null;
  }

  /**
   * set a update sequence
   * @param {Obejct} target update target
   * @param {Array.<Object>} updateSteps update step definitions
   * @param {Function} onComplete (optional)
   */
  setUpdateSequence(target, updateSteps, onComplete = null) {
    this._target = target;
    this._onComplete = onComplete;
    this._updateSteps = updateSteps.slice();
    this._nextUpdateSequence();
  }

  /**
   * set the next update sequence
   */
  _nextUpdateSequence() {
    if (this._updateSteps.length === 0) {
      this._activeStep = null;
      const onComplete = this._onComplete;
      this._reset();
      if (onComplete !== null) onComplete();
      return;
    }

    const step = this._updateSteps.shift();
    this._stepTime = 0;
    this._prevPos = 0;
    this._stepDuration = step.duration;
    this._activeStep = step;
    this._activeStep.update(this._target, 0, 0);
  }

  /**
   * update loop
   */
  update() {
    if (this._activeStep === null) return;
    this._stepTime += G.deltaTime * FRAME_TARGET_TIME;
    const newPos = Math.min(this._stepTime / this._activeStep.duration, 1);
    this._activeStep.update(this._target, newPos, this._prevPos);
    this._prevPos = newPos;
    if (newPos >= 1) this._nextUpdateSequence(this._updateSteps);
  }

  /**
   * destruction method
   */
  destroy() {
    this._reset();
  }
}
