/* eslint-disable no-param-reassign */
import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

/**
 * class for display of friend over taken on the map
 */
class Window_FriendOvertaken extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} user2
   */
  constructor(parent, user2) {
    super(parent);

    const user1 = OMT.envData.settings.user;

    this._initLayout();
    this._initButtons(user1, user2);
    this._initAvatars(user1, user2);
  }

  /**
   * init window layout
   */
  _initLayout() {
    this.addBackground('popup_background_2');

    // title
    this._titleTxt = new G.Text(0, -272, OMT.language.getText('One step ahead!'), G.OMTsettings.elements.Window_friendOvertaken.titleTxt.style, 0.5, 420);
    this.add(this._titleTxt);

    // sub-title
    this._subtitle = this.add(new G.Text(0, 160, `${OMT.language.getText("You've overtaken your friend")}!`, {
      style: 'font-blue',
      fontSize: 40,
    }, 0.5, 480));

    this._img = G.makeImage(0, -50, 'Beat_Map_Position_Asset', 0.5, this);
  }

  /**
   * init window buttons
   * @param {Object} user1
   * @param {Object} user2
   */
  _initButtons(user1, user2) {
    // generic close button
    this.addCloseButton();

    // continue button
    const onClick = () => {
      OMT.social.sendOvertakenMsg(user1, user2, (success) => {
        if (success) this.closeWindow();
      }, this);
    };
    this._continueBtn = new OMT_UI_SquareButton(0, 300, {
      button: {
        tint: BUTTONCOLOURS.blue,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Challenge (name)').replace('(name)', user2.name),
        textStyle: {
          style: 'font-white',
          fontSize: '40px',
          lineSpacing: -15,
        },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });
    this.registerButtons(this._continueBtn);
  }

  /**
   * init avatars
   * @param {Object} user1
   * @param {Object} user2
   */
  _initAvatars(user1, user2) {
    this._avatar1 = G.makeExtImage(-8, -102, user1.image || user1.avatar, null, 0, this, false, (image) => {
      image.width = 114;
      image.height = 114;
    });
    this._avatar2 = G.makeExtImage(-140, -75, user2.image || user2.avatar, null, 0, this, false, (image) => {
      image.width = 68;
      image.height = 68;
    });
    this.addChildAt(this._avatar1, 1);
    this.addChildAt(this._avatar2, 1);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.friendOvertaken = Window_FriendOvertaken;
