if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getBtnFadeInAnimation = function getBtnFadeInAnimation() {
  return { animate: animate };

  function animate(game, target) {
    target.visible = true;
    target.alpha = 0;
    return game.add
      .tween(target)
      .to(
        { alpha: 1, y: target.y - 15 },
        500,
        Phaser.Easing.Sinusoidal.Out,
        true
      );
  };
}
