/*
  Utility Class for handling JSON
*/
export default class JSONUtil {
  /*
    check if str is json
  */
  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}

window.JSONUtil = JSONUtil;
