G.Helpers = G.Helpers || {};

G.Helpers.callOnThreshold = function (threshold, callback, context) {
  let initVal = null;

  return function callOnThreshold(value) {
    if (initVal === null || Math.abs(initVal - value) > threshold) {
      initVal = value;
      callback.call(context);
    }
  };
};
