/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

import { OMT_UI_FriendsListView } from '../../../../OMT_UI/FriendsList/OMT_UI_FriendsListView';
import { AskFriendsListItem, ASK_FRIEND_STATUS } from './AskFriendsListItem';

/*
  freinds list view for Window_AdFallback_AskFriends
*/
export class AskFriendsListView extends OMT_UI_FriendsListView {
  /**
   * constructor
   * @param {number} itemHeight
   * @param {Phaser.Rectangle} scrollRect
   * @param {number} cooldownDuration time in milliseconds
   * @param {string} cooldownId string for saveState identifier
   */
  constructor(itemHeight, scrollRect, cooldownDuration, cooldownId) {
    super();

    this.signals.onAskFriend = new Phaser.Signal();

    this._cooldownDuration = cooldownDuration;
    this._cooldownId = cooldownId;
    this.init(itemHeight, scrollRect, G.OMTsettings.adFallback_AskFriend.graphics.oddColourBg, G.OMTsettings.adFallback_AskFriend.graphics.evenColourBg);
  }

  /**
   * create friendList item
   * @param {Object} friendData
   * @param {number} itemWidth
   * @param {number} itemHeight
   * @param {number} bgColor
   */
  _createFriendListItem(friendData, itemWidth, itemHeight, bgColor) {
    const listItem = new AskFriendsListItem(friendData, itemWidth, itemHeight, bgColor, this._cooldownDuration, this._cooldownId);
    listItem.signals.onAskFriend.add(this.onAskFriendStatusUpdate.bind(this));
    return listItem;
  }

  /**
   * called when a child items ask friend status is updated
   * @param {FriendListItem} item
   * @param {number} status
   */
  onAskFriendStatusUpdate(item, status) {
    if (status === ASK_FRIEND_STATUS.REQUESTED) {
      this.disable();
    } else if (status === ASK_FRIEND_STATUS.SUCCESS) {
      this.enable();
      this.signals.onAskFriend.dispatch();
    } else if (status === ASK_FRIEND_STATUS.FAILED) {
      this.enable();
    }
  }

  /**
   * enable interactivity
   */
  enable() {
    this._scrollArea.enable();
  }

  /**
   * disable interactivity
   */
  disable() {
    this._scrollArea.disable();
  }
}
