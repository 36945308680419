/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

// eslint-disable-next-line import/extensions
import geoClient from '../../../../node_modules/@sgorg/geo-client/dist/index';

// fallbacks for user data if for some reason Facebook data is null
const DEFAULT_NAME = '...';
const DEFAULT_AVATAR = 'img/fallback/avatar_e.png';
const DEAULT_SLUG = '';

/**
 *  class for interfacing with and storing environment data
 */
export class OMT_EnvData {
  /**
   * constructor
   */
  constructor() {
    this._gbUserProfileData = null;
    this._entryPoint = null;
    this._entryPointData = null;
    this._payload = null;
    this._settings = null;
  }

  /**
   * set the user profile data
   * @param {Object} gbUserProfileData
   */
  setGBUserProfileData(gbUserProfileData) {
    this._gbUserProfileData = gbUserProfileData;
  }

  /**
   * get the game-backend user id
   * @returns {string}
   */
  get gbUserId() {
    if (!this._gbUserProfileData) return null;
    return this._gbUserProfileData.id;
  }

  /**
   * get the game-backend user handle
   * @returns {string}
   */
  get gbUserHandle() {
    if (!this._gbUserProfileData) return null;
    return this._gbUserProfileData.handle;
  }

  /**
   * define the settings / environment vars
   */
  defineSettings() {
    const locale = FBInstant.getLocale();
    const lang = locale.indexOf('_') > 0 ? locale.split('_')[0] : locale;

    this._settings = {
      env: {
        platform: FBInstant.getPlatform(),
        country: '',
        tier: 1,
        locale,
        lang,
      },
      user: {
        userId: FBInstant.player.getID(),
        name: FBInstant.player.getName() || DEFAULT_NAME,
        avatar: FBInstant.player.getPhoto() || DEFAULT_AVATAR,
        locale,
      },
      game: {
        id: G.BuildEnvironment.APP_ID,
        slug: G.BuildEnvironment.slug || DEAULT_SLUG,
        isCookOMT: G.BuildEnvironment.isCookOMT,
      },
    };
    // this._defineCountryAndTier();
    // disabled to reduce sentry spam
    // this._logUserDataErrors();
  }

  /**
   * check if the settings.user Object is valid. If not log a sentry error.
   */
  _logUserDataErrors() {
    const { user } = this._settings;
    const userIdSet = user.userId != null;
    const userNameSet = user.name !== DEFAULT_NAME;
    const userAvatarSet = user.avatar !== DEFAULT_AVATAR;
    if (!userIdSet || !userNameSet || !userAvatarSet) {
      const errorString = `OMT_EnvData Error: { userIdSet: ${userIdSet}, userNameSet: ${userNameSet}, userAvatarSet: ${userAvatarSet} }`;
      // G.Utils.SentryLog.logError(errorString, {}, false);
      console.error(errorString);
    }
  }

  /**
   * load country and catalog data and set the users country / IAP tier
   */
  _defineCountryAndTier() {
    Promise.all([geoClient.getCountry(), OMT.payments.getCatalogAsync()]).then((values) => {
      if (typeof values[0] === 'object') {
        this._settings.env.country = values[0].country;
        this._settings.env.tier = G.json['configs/countries'][this._settings.env.country].tier;
      } else if (typeof values[1][0].priceCurrencyCode === 'string') {
        const currency = values[1][0].priceCurrencyCode;
        this._settings.env.tier = G.json['configs/currency'][currency].tier;
      }
      // G.Utils.SentryLog.setIAPTier();
      // G.Utils.SentryLog.setUserCountry();
    }).catch((error) => {
      console.error('Error: could not get geolocation data.');
    });
  }

  /**
   * get a reference to the settings / environment vars
   * @returns {Object}
   */
  get settings() {
    if (!this._settings) return null;

    // if for some reason the Facebook user data could not be fetched earlier try to fill it.
    const { user } = this._settings;
    if (user.name === DEFAULT_NAME) user.name = FBInstant.player.getName() || DEFAULT_NAME;
    if (user.avatar === DEFAULT_AVATAR) user.avatar = FBInstant.player.getPhoto() || DEFAULT_AVATAR;

    return this._settings;
  }

  /**
   * define entry point data
   * @returns {Promise}
   */
  async defineEntryPoint() {
    this._entryPoint = await FBInstant.getEntryPointAsync();
    this._entryPointData = FBInstant.getEntryPointData();

    // this._entryPoint = 'other';
    // this._entryPointData = {
    //   "targetedOffer": {
    //     "productId": "target_12k_b8_2_b1_2",
    //     "timeLimitInMins": 30,
    //     "highPriority": true
    //   }
    // };

    // test payload for state change
    // this._entryPointData = JSON.parse('{"stateChange":{"coins":6310,"levelsCompleted":328, "unlimitedLives":44, "boosters":[0, 0, 1, 1, 0, 1, 1, 1, 0, 0]}, "id":"40472d"}');

    // test payload for gifts
    // eslint-disable-next-line max-len
    // this._entryPointData = JSON.parse('{"gamePayload":{"gift":{"giftData":[{"amount":99,"itemId":"booster#3"},{"amount":2,"itemId":"booster#3"},{"amount":1,"itemId":"coin"}],"id":12345},"payloadId":960525637,"timeSent":1606225089828,"userId":"2165623810166284"},"entryPoint":"botMessage","messageCategory":"oneTimeGift","messageId":"userThanks###unlimLives###en"}');

    // test payload for brag 2.0
    // eslint-disable-next-line max-len
    // this._entryPointData = JSON.parse('{"sendParams":{"senderId":"2366682466699666","senderName":"Jeff","senderLevel":485,"senderDaysInGame":3,"senderSessionNumber":31,"senderCoins":2242,"senderIsPayer":1,"timestamp":"2020-12-3 15:56:10.429","msgType":"SHARE","msgLocale":"en_US","msgID":"59b9ff82e407","msgName":"BRAG","epSocialActionLevel":485}}');
  }

  /**
   * get the entryPoint name
   * @returns {string}
   */
  get entryPoint() {
    return this._entryPoint;
  }

  /**
   * get the entryPoint data
   * @returns {Object}
   */
  get entryPointData() {
    return this._entryPointData;
  }

  /**
   * define the payload data
   */
  definePayload() {
    if (this._entryPointData) {
      // Bot messages payload data is in the gamePayload property
      if (this._entryPointData.gamePayload) {
        this._payload = this._entryPointData.gamePayload;
      } else {
        this._payload = this._entryPointData;
      }
    }
  }

  /**
   * reject / clear the current payload
   */
  rejectPayload() {
    this._payload = null;
  }

  /**
   * get the payload data
   * @returns {Object}
   */
  get payload() {
    return this._payload;
  }

  /**
   * check if we have a restore progress payload
   * @returns {boolean}
   */
  get isRestoreProgressPayload() {
    return this._payload && this._payload.additions && this._payload.additions.restoreProgression;
  }

  /**
   * check if we have a state change payload
   * @returns {boolean}
   */
  get isStateChangePayload() {
    return this._payload && this._payload.stateChange;
  }

  /**
   * get the platform
   * @returns {string}
   */
  get platform() {
    return this._settings.env.platform;
  }
}
