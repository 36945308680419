import { ProgressBar } from '../ProgressBar';

const BAR_WIDTH = 325;
const TEXTSTRING = '%current%/%total% treasure tokens';

export default class THProgressBar extends Phaser.Group {
  /**
   * The progress bar that shows for the treasure hunt
   * @param {number} initialValue
   * @param {boolean} animateBar
   */
  constructor(initialValue, animateBar) {
    super(game, null);

    this._container = new Phaser.Group(game, this);
    this._container.x = -20;
    this._animate = Boolean(animateBar);
    this._shakingChests = [];
    this._lastValue = initialValue;
    this._initialTokens = initialValue;
    this._collectData();
    this._initProgressBar(this.calculateValues(initialValue));
    this._drawSpokes();
    this._drawBigChest();
    this._drawText();
    this._updateLabel(initialValue);
  }

  /**
   * Destroy!
   */
  destroy() {
    for (const shake of this._shakingChests) {
      if (shake && shake.stop) {
        shake.stop();
      }
    }
    if (this._textTween && this._textTween.stop) {
      this._textTween.stop();
    }
    if (this._progressBar) {
      this._progressBar.stop();
    }
    super.destroy();
  }

  /**
   * Finds the parts where the small chests are at
   */
  _collectData() {
    this._allPrizes = G.OMTsettings.treasureHuntSuper.reward.currencyUnlock;
    const currenyArr = this._allPrizes.concat([]);
    this._totalTokens = currenyArr.splice(currenyArr.length - 1);
    this._spokes = currenyArr;

    this._currentValue = 0;
  }

  /**
   * Initializes progress bar with some value
   * @param {number} initialValue
   */
  _initProgressBar(initialValue) {
    this._initialValue = initialValue;
    this._progressBar = new ProgressBar(game, {
      x: -3,
      y: 0,
      atlas: 'popups',
      bgSprite: 'treasureHunt_progressBg',
      fgSprite: 'treasureHunt_progressFill',
      width: BAR_WIDTH,
      height: 30,
      value: initialValue,
      valueMax: 100,
      isAnimated: this._animate,
      fillSfx: 'transition',
      fullSfx: 'match_5',
      hasLabel: false,
    }, this._container);

    const token = this._token = G.makeImage(0, 0, 'treasureHunt_token', 0.5, this._container);
    token.x = this._progressBar.x - (this._progressBar.width * 0.53);
  }

  /**
   * Draws the spike with the chest on top.
   * Placed a little skewedly because of the gap between prizes is \large/
   */
  _drawSpokes() {
    this._chestImages = [];
    for (let i = 0; i < this._spokes.length; i++) {
      const pos = this._progressBar.x - 40 - (this._progressBar.width / 2) + (this._progressBar.width * ((i + 1) / (this._spokes.length + 1)));
      const spokeGroup = new Phaser.Group(game, this._container);
      const spokeImage = G.makeImage(0, 0, 'treasureHunt_progressSpoke', 0.5, spokeGroup);
      const chest = G.makeImage(0, 0, `treasureHunt_chestMini${i}`, 0.5, spokeGroup);
      chest.y = spokeImage.y - (spokeImage.height + chest.height) / 2;

      spokeGroup.x = pos;
      this._chestImages.push(chest);
    }
  }

  /**
   * Draws the big chest at the end of the bar
   */
  _drawBigChest() {
    this._bigChest = new G.Button(0, 0, `treasureHunt_chestMini${this._spokes.length}`);
    this._container.addChild(this._bigChest);
    this._bigChest.scaleOnClick = false;
    this._bigChest.disable();
    this._bigChest.x = this._progressBar.x + (this._progressBar.width / 2) + (this._bigChest.width * 0.3);
    this._chestImages.push(this._bigChest);
  }

  /**
   * Draws the text underneath the bar
   */
  _drawText() {
    this._label = new G.Text(this._progressBar.x - this._container.x, 0, OMT.language.getText(TEXTSTRING), { // 20 from external offset
      style: 'treasureHunt-levelWindow',
      fontSize: 20,
    }, 0.5, this._progressBar.width);
    this._label.y = this._progressBar.y + (this._progressBar.height + this._label.height) / 2;
    this._container.addChild(this._label);
  }

  /**
   * Updates the text label
   * @param {number} value
   */
  _updateLabel(value) {
    if (!Number.isFinite(value)) {
      value = G.saveState.treasureHuntManager.currentTokens;
    }
    this._label.setText(OMT.language.getText(TEXTSTRING).replace('%current%', value).replace('%total%', this._totalTokens));
    this._currentValue = value;
  }

  /**
   * Takes in token number, and spits out a number that is skewed to the progress bar
   * Number coming out is 0~100
   * @param {number} value
   * @returns {number}
   */
  calculateValues(value) {
    // value is the amount of tokens
    const firstSpokePoint = 24;
    if (value <= this._spokes[0]) {
      return Math.floor((firstSpokePoint * value) / this._spokes[0]);
    }
    const secondTotalSpoke = 34;
    if (value <= this._spokes[1]) {
      return Math.floor(((secondTotalSpoke * (value - this._spokes[0])) / (this._spokes[1] - this._spokes[0])) + firstSpokePoint);
    }
    const sum = firstSpokePoint + secondTotalSpoke + 3;
    const thirdTotalSpoke = 100 - sum;
    const firstTwo = this._spokes[0] + this._spokes[1];
    return Math.floor(Math.min(((thirdTotalSpoke * (value - firstTwo)) / (this._totalTokens - firstTwo)) + sum, 100));
  }

  /**
   * Updates the bar.
   * Animates the bar.
   * Updates the text on the bar
   * @param {number} value Should the the number of tokens
   */
  updateBar(value) {
    this._progressBar.updateValue(this.calculateValues(value), null, null, Phaser.Easing.Quadratic.InOut);
    if (this._textTween) {
      this._textTween.stop();
    }
    this._tempValue = this._currentValue;
    this._lastValue = this._tempValue;
    if (this._animate) {
      this._textTween = game.add.tween(this).to({ _tempValue: value }, 1000, Phaser.Easing.Quadratic.InOut, true);
      this._textTween.onUpdateCallback(this._updateLabelFunc.bind(this));
      this._textTween.onComplete.add(this._updateLabelFunc.bind(this));
    } else {
      this._updateLabel(value);
    }
  }

  /**
   * Function called from tween
   */
  _updateLabelFunc() {
    if (Math.floor(this._tempValue) !== Math.floor(this._lastValue)) {
      this._lastValue = this._tempValue;
      const val = Math.floor(this._tempValue);
      this._updateLabel(val);
      this._checkShake(val);
    }
  }

  /**
   * Checks whether or not the chest can shake
   */
  _checkShake(val) {
    for (let i = 0; i < this._allPrizes.length; i++) {
      if (this._initialTokens < this._allPrizes[i] && val >= this._allPrizes[i] && !this._shakingChests[i]) {
        this._shakingChests[i] = game.add.tween(this._chestImages[i]).to({ angle: [10, -10, 0] }, 750, Phaser.Easing.Linear.None, true, 0, -1);
      }
    }
  }

  /**
   * Takes in a function and turns the big chest into a workable button.
   * @param {function} func
   */
  setClickFunc(func) {
    if (!this._infoIcon) {
      this._infoIcon = G.makeImage(0, 0, 'treasureHunt_info', 0.5, this._bigChest);
      this._infoIcon.x = (this._bigChest.width * 0.3);
      this._infoIcon.y = -(this._bigChest.height * 0.3);
      this._bigChest.scaleOnClick = true;
      this._bigChest.enable();
    }
    this._bigChest.onClick.add(func);
  }

  /**
   * Gets the icon object
   * @returns {Phaser.Image}
   */
  get icon() {
    return this._token;
  }
}
