import { DeconstructMsg } from './DeconstructMsg';
import { MILLISECONDS_IN_SEC } from '@omt-components/Utils/TimeUtil';

/**
 * The deconstructed message that appears at the end of a tournament level
 * Taunts you to doing better
 */
export class DeconstructMsgTournamentTaunt extends DeconstructMsg {
  /**
   * tournamentOrder is an array of FB player data, in order of best to worse.
   * One of the entries must be the current user
   * @param {LvlDataManager}
   * @param {Array<image:string>} tournamentOrder
   */
  constructor(lvlDataManager, tournamentOrder) {
    super(lvlDataManager);

    this._pauseDuration = 4000;
    this._friends = tournamentOrder; // Data

    // tournamentTaunt
    this._layoutConfig = lvlDataManager.getLayoutConfig('tournamentTaunt');
    if (this._layoutConfig) this._initScene();
  }

  /**
   * Init all the drawing
   */
  _initScene() {
    this._playerIndex = this._friends.indexOf(this._friends.find((data) => data.userId === this._lvlDataManager.user.userId)); // Player index

    // Drawing
    const imgStringBase = 'img/tournamentTaunt/';
    this._lastFriend = this._drawImageWithAvatarFrame(this, imgStringBase, 'tournamentTauntLastFriend.png', this._layoutConfig.lastFriend.avatar, this._friends[2]);
    this._midFriend = this._drawImageWithAvatarFrame(this, imgStringBase, 'tournamentTauntMidFriend.png', this._layoutConfig.midFriend.avatar, this._friends[1]);
    this._aheadFriend = this._drawImageWithAvatarFrame(this, imgStringBase, 'tournamentTauntAheadFriend.png', this._layoutConfig.aheadFriend.avatar, this._friends[0]);
    const trophyGroup = new Phaser.Group(game, this);
    this._rays = G.makeExtImage(0, 0, `${imgStringBase}tournamentTauntTrophyRay.png`, 'waiting_icon', 0.5, trophyGroup);
    const trophy = G.makeExtImage(0, 0, `${imgStringBase}tournamentTauntTrophy.png`, 'waiting_icon', 0.5, trophyGroup);
    this._layoutConfig.sparkles.map((spark) => {
      const sparkle = G.makeExtImage(0, 0, `${imgStringBase}tournamentTauntSparkle.png`, 'waiting_icon', 0.5, trophyGroup);
      this._applyPositioning(sparkle, spark);
      return sparkle;
    });
    let textString = '';
    if (this._playerIndex === 0) {
      textString = 'You are ahead of your friends, for now...';
    } else {
      textString = 'You are doing great, but your friends are doing even better!';
    }
    this._text = new G.Text(0, 0, this._gameHooks.getText(textString), 'tournamentTaunt-text', 0.5, game.width * 0.7, 500, true, 'center');
    this.addChild(this._text);

    // Positioning and animation
    this._applyPositioning(this._text, this._layoutConfig.text);
    this._applyPositioning(this._aheadFriend, this._layoutConfig.aheadFriend);
    this._applyPositioning(this._midFriend, this._layoutConfig.midFriend);
    this._applyPositioning(this._lastFriend, this._layoutConfig.lastFriend);
    this._applyPositioning(trophyGroup, this._layoutConfig.trophy);
    this._applyWiggle(this._aheadFriend);
    this._applyWiggle(this._midFriend);
    this._applyWiggle(this._lastFriend);
    this._applyWiggle(trophy);
  }

  /**
   * Update
   */
  update() {
    if (this._rays) this._rays.angle += 0.05 * G.deltaTime;
    super.update();
  }

  /**
   * Draws the mascot friend with real friend's character frame on top, if there is a friend
   * @param {Phaser.Group} targetStage
   * @param {string} imgBase
   * @param {string} character
   * @param {Object} avatarLayout
   * @param {Object} friendData
   */
  _drawImageWithAvatarFrame(targetStage, imgBase, character, avatarLayout, friendData) {
    const img = new Phaser.Group(game, targetStage);
    G.makeExtImage(0, 0, `${imgBase}${character}`, 'waiting_icon', 0.5, img); // mascot
    if (friendData) { // Real friend
      const avatarGroup = new Phaser.Group(game, img);
      const frameWidth = 85;
      const frame = G.makeExtImage(0, 0, `${imgBase}tournamentTauntAvatarFrame.png`, 'waiting_icon', 0.5, null, null, (image) => {
        image.scale.set(frameWidth / image.width);
      });
      const avatar = G.makeExtImage(0, 0, `${friendData.image}`, 'avatar_e', 0.5, avatarGroup, null, (image) => {
        image.width = image.height = frameWidth * 0.86;
      });
      avatar.width = avatar.height = frameWidth * 0.86;
      avatarGroup.addChild(frame);
      this._applyPositioning(avatarGroup, avatarLayout);
    }

    return img;
  }

  /**
   * Applies positioning data to image object
   * @param {Phaser.Group} image
   * @param {Object} pos
   */
  _applyPositioning(image, pos) {
    image.x = pos.x || 0;
    image.y = pos.y || 0;
    image.angle = pos.angle || 0;
    image.scale.set(pos.scale || 1);
  }

  /**
   * Applies a wiggle effect to the image after a slightly delay
   * @param {image} image
   */
  _applyWiggle(image) {
    const rng = (Math.round((Math.random() * 1000)) / 1000) * MILLISECONDS_IN_SEC;
    const a = game.time.events.add(rng / 2, () => {
      const oriAngle = image.angle;
      const tw = game.add.tween(image)
        .to({ angle: [oriAngle + 5, oriAngle, oriAngle - 5, oriAngle] }, 1000, Phaser.Easing.Sinusoidal.InOut, true)
        .repeat(-1, rng * 3);
      this._allTweens.push(tw);
    });
    this._allWaits.push(a);
  }

  /**
   * Applies a pulsing effect after a slight delay
   * @param {Phaser.Group} image
   */
  _applyPulse(image) {
    const rng = (Math.round((Math.random() * 1000)) / 1000) * MILLISECONDS_IN_SEC;
    const fat = 1.5;
    const skinny = 0.8;
    if (rng % 2 === 0) {
      image.scale.set(skinny, fat);
    } else {
      image.scale.set(fat, skinny);
    }
    const a = game.time.events.add(rng, () => {
      let target;
      if (rng % 2 === 0) {
        target = { x: fat, y: skinny };
      } else {
        target = { x: skinny, y: fat };
      }
      const tw = game.add.tween(image.scale)
        .to({ x: target.x, y: target.y }, 1000, Phaser.Easing.Linear.None, true, 0, -1, true);
      this._allTweens.push(tw);
    });
    this._allWaits.push(a);
  }

  /**
   * Animate in! And show everything with style!
   */
  animateIn() {
    // Preset things
    const everything = [this._aheadFriend, this._midFriend, this._lastFriend];
    const oriY = everything.map((thing) => {
      const ori = thing.y;
      thing.alpha = 0;
      thing.y = -thing.height;
      return ori;
    });
    this._text.alpha = 0;
    const oriTextY = this._text.y;
    this._text.y = -this._text.height;

    super.animateIn(); // everything fades in

    // Animate in things with delay
    const delayTime = 200;
    const tweenTime = 400;
    let delayCounter = 0;
    everything.forEach((thing, index) => {
      this._allWaits.push(game.time.events.add(delayCounter * delayTime, () => {
        this._allTweens.push(game.add.tween(thing)
          .to({ y: oriY[index], alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true));
      }));
      delayCounter++;
    });
    this._allWaits.push(game.time.events.add(this._playerIndex * delayTime, () => {
      this._allTweens.push(game.add.tween(this._text)
        .to({ alpha: 1, y: oriTextY }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true));
    }));
  }
}
