import {
  UI_ScrollAreaBase,
  LOCK_INPUT_VELOCITY,
  MAX_OVERSCROLL,
} from './UI_ScrollAreaBase';

/**
 * simpler scroll area of horizontal scrolling, with no scrollbars
 */
export default class UI_HorizontalScrollArea extends UI_ScrollAreaBase {
  /**
   * constructor
   * @param {Phaser.Rectangle} scrollRect Rectangle for scrollable area
   * @param {boolean} maskContent do you want to apply a mask to the content ?
   */
  constructor(scrollRect, maskContent = true) {
    super(scrollRect);

    // init scroll physics params
    this._contentDragStartX = 0;
    this._contentDragVelX = 0;

    this._initContentContainer();
    if (maskContent) this._initScrollMask();
    this._setPointerMovedCallback();

    this.addScrollCondition(() => this._enabled);
  }

  /**
   * on pointer moved callback
   */
  _onPointerMoved() {
    try {
      const localPointer = this._getLocalPointer();
      const containsPointer = this.scrollConditionsOK && Phaser.Rectangle.containsPoint(this._scrollRect, localPointer);
      const velocityLock = Math.abs(this._contentDragVelX) > LOCK_INPUT_VELOCITY;
      this._updateInputState(this._content, containsPointer && !velocityLock);
    } catch (error) {
      this._deletePointerMovedCallback();
    }
  }

  /**
   * main update function
   */
  update() {
    super.update();

    const localPointer = this._getLocalPointer();
    const containsPointer = this.scrollConditionsOK && Phaser.Rectangle.containsPoint(this._scrollRect, localPointer);
    this._updateContentDragging(containsPointer);

    const velocityLock = Math.abs(this._contentDragVelX) > LOCK_INPUT_VELOCITY;
    this._updateInputState(this._content, containsPointer && !velocityLock);
  }

  /**
   * update content dragging
   * @param {boolean} containsPointer
   */
  _updateContentDragging(containsPointer) {
    // no scrolling if width is smaller then the scroll rect width
    if (this._content.width < this._scrollRect.width) return;
    const lastX = this._content.x;

    // capture the pointer down state
    if (!this._pointerDown && game.input.activePointer.isDown) {
      this._pointerDown = true;
      this._pointerDownInContent = containsPointer;
      this._contentDragStartX = game.input.x;
    } else if (this._pointerDown && !game.input.activePointer.isDown) {
      this._pointerDown = false;
      this._pointerDownInContent = false;
    }

    const contentLeftOverflow = (this._content.x + this._content.width) - this._scrollRect.width;
    const contentRightOverflow = this._content.x;

    if (this._pointerDownInContent) { // drag active
      const overflow = Math.max(-contentLeftOverflow, contentRightOverflow, 0);
      const overScrollResistance = Math.min(overflow / MAX_OVERSCROLL, 1);
      this._contentDragVelX = (this._contentDragStartX - game.input.x) * (1 - overScrollResistance);
      this._contentDragStartX = game.input.x;
    } else if (contentLeftOverflow < 0) { // drag inactive, bounce back to left limit
      this._contentDragVelX = 0;
      this._content.x -= contentLeftOverflow * this._elasticity;
    } else if (contentRightOverflow > 0) { // drag inactive, bounce back to right limit
      this._contentDragVelX = 0;
      this._content.x -= contentRightOverflow * this._elasticity;
    }

    // scroll as long as we have velocity
    if (Math.abs(this._contentDragVelX) > 0) {
      // update content position
      this._content.x -= this._contentDragVelX * G.deltaTime;
      // apply friction to drag velocity
      this._contentDragVelX -= this._contentDragVelX * (1 - this._friction) * G.deltaTime;
    }

    // dispatch the onScrolled event
    if (this._content.x !== lastX) this.signals.onScrolled.dispatch();
  }

  /**
   * jump to content position
   * @param {number} xPos
   */
  jumpToContentPosition(xPos) {
    this._contentDragVelX = 0;
    if (this._content.width > this._scrollRect.width) {
      this._content.x = xPos;
    } else {
      this._content.x = 0;
    }
    this.signals.onScrolled.dispatch();
  }

  /**
   * resize the scroll rect / view
   * @param {number} width
   * @param {number} height
   */
  resizeScrollArea(width, height) {
    super.resizeScrollArea(width, height);
    this.jumpToContentPosition(-this._content.x);
  }
}
