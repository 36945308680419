import OOMFailView from '../../../OOM/views/OOMFailView';
import { TokenEventOOMInfoPanel } from '../components/TokenEventOOMInfoPanel';

/**
 * Out Of Moves TokenEvent fail view
 */
export class TokenEventOOMFailView extends OOMFailView {
  /**
   * constructor
   * @param {Array} additionalBoosterData
   */
  constructor(additionalBoosterData) {
    super(additionalBoosterData);
  }

  /**
   * override init add token event diplays
   */
  _init() {
    super._init();
    this._initTitleText();
    this._initContinueText();
    this._initOutOfMovesText();
  }

  /**
   * overrides base class. custom event title banner.
   */
  _initTitleText() {
    // background banner
    const banner = G.makeImage(0, -295, 'tokenEvent_banner', 0.5, this);
    this.addChild(banner);

    // main title text
    const titleTxt = new G.Text(
      G.OMTsettings.tokenEvent.windowHeader.text.x,
      G.OMTsettings.tokenEvent.windowHeader.text.y,
      OMT.language.getText('tokenEvent Level'), 'tokenEvent-eventLevelTitle', 0.5, G.OMTsettings.tokenEvent.windowHeader.text.width, 125, true, 'center',
    );
    this.addChild(titleTxt);
  }

  /**
   * init continue text field
   */
  _initContinueText() {
    const continueTxt = new G.Text(0, 35, OMT.language.getText('Continue?'), {
      style: 'font-blue',
      fontSize: 50,
      fill: G.OMTsettings.tokenEvent.styling.windowTextFill,
    }, 0.5, 400);
    this.addChild(continueTxt);
  }

  /**
   * init out of moves text field
   */
  _initOutOfMovesText() {
    const oomTxt = new G.Text(0, -145, OMT.language.getText('Out of moves!'), {
      ...G.OMTsettings.global.window.outOfMovesTitle.textStyle,
      fill: G.OMTsettings.tokenEvent.styling.windowTextFill,
    }, 0.5, 420);
    this.addChild(oomTxt);
  }

  /**
   * overrides base class with customizations for the tokenEvent
   */
  _initInfoPanel() {
    const tokenGroup = new TokenEventOOMInfoPanel();
    this.addChild(tokenGroup);
  }
}
