import TimeUtil from '@omt-components/Utils/TimeUtil';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import { OMT_UI_FriendsListItem } from '../../../OMT_UI/FriendsList/OMT_UI_FriendsListItem';

/* eslint-disable no-use-before-define */


/**
 * list item for GateFriendListView
 */
export class GateFriendListItem extends OMT_UI_FriendsListItem {
  /**
   * constructor
   * @param {Object} friendData
   * @param {number} itemWidth
   * @param {number} itemHeight
   * @param {number} bgColor
   * @param {number} cooldownDuration time in milliseconds
   * @param {number} cooldownId string for saveState identifier
   */
  constructor(friendData, itemWidth, itemHeight, bgColor, cooldownDuration, cooldownId) {
    super(friendData, itemWidth, itemHeight, bgColor);

    this.signals.onClick = new Phaser.Signal();

    this._cooldownDuration = cooldownDuration;
    this._cooldownId = cooldownId;
    this._isOnCooldown = G.saveState.getUserCooldownRemaining(this._cooldownId, this._friendData.userId) > 0;

    this._initDrawButton();
    this._initCoolDownGraphics();

    if (this._isOnCooldown) this._setCooldownState();
    this.update(); // force a update

    // cache content container
    this.cacheContent = true;
  }

  /**
   * init the ask button
   */
  _initDrawButton() {
    // button label
    const style = { style: 'font-white', fontSize: 50, lineSpacing: -10 };
    // button / container
    this._askButton = new Phaser.Group(game, this);
    const button = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.blue,
        dimensions: {
          width: 196,
          height: 100,
        },
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText('Ask'),
        textStyle: style,
      },
      options: {
        clickFunction: {
          onClick: this.onClick.bind(this),
        },
      },
    });
    this._askButton.addChild(button);
    const scale = this._buttonHeight / button.height;
    this._askButton.height *= scale;
    this._askButton.width *= scale;
    this._askButton.y = this._itemHeight / 2;
    this._askButton.x = this._buttonRightX - (button.width / 2);
  }

  onClick() {
    this.signals.onClick.dispatch({
      origin: this,
      friendId: this._friendData.userId,
    });
  }

  setOnCooldown() {
    G.saveState.setUserCooldown(this._cooldownId, this._friendData.userId, this._cooldownDuration);
    this._setCooldownState();
  }

  /**
   * initialize the graphics for the cool down counter
   */
  _initCoolDownGraphics() {
    // create cool down graphics group
    const coolDownGroup = new Phaser.Group(game);
    coolDownGroup.x = this._askButton.x;
    coolDownGroup.y = this._itemHeight / 2;
    coolDownGroup.visible = false;
    this.addChild(coolDownGroup);

    // grey btn bg
    const button = new Phaser.Group(game);
    const bgImage = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.gray,
        dimensions: {
          width: 196,
          height: 100,
        },
        extraDetail: false,
        isEnabled: false,
      },
    });
    button.addChild(bgImage);
    const scale = this._buttonHeight / bgImage.height;
    button.height *= scale;
    button.width *= scale;
    coolDownGroup.addChild(button);

    // count down text
    const style = { style: 'font-white', fontSize: 17 };
    const countText = new G.Text(0, 0, '00:00:00', style, [0.5, 0.5], (bgImage.width * scale) * 0.9, (bgImage.height * scale) * 0.9, true);
    coolDownGroup.addChild(countText);

    this._coolDownGroup = coolDownGroup;
    this._coolDownCountText = countText;
  }

  /**
   * set the active / normal state
   */
  _setActiveState() {
    this._coolDownGroup.visible = false;
    this._askButton.visible = true;
    this._isOnCooldown = false;
  }

  /**
   * set the cooldown state
   */
  _setCooldownState() {
    this._coolDownGroup.visible = true;
    this._askButton.visible = false;
    this._isOnCooldown = true;
  }

  /**
   * update cool down state
   */
  _updateCooldownState() {
    if (!this._isOnCooldown) return;
    const cooldownTime = G.saveState.getUserCooldownRemaining(this._cooldownId, this._friendData.userId);
    if (cooldownTime === 0) { // deactivate cooldown
      this._setActiveState();
    } else { // update cooldown counter
      this._coolDownCountText.text = TimeUtil.getTimeCode(cooldownTime);
    }
  }

  /**
   * main update method
   */
  update() {
    super.update();
    this._updateCooldownState();
  }
}
