/**
 * A panel for Window_EventLevel that shows the total collected tokens for the user
 */
export class EventLevelCollection extends Phaser.Group {
  constructor(x, y, style = {
    style: 'font-white',
    fontSize: 35,
  }) {
    super(game);

    this.x = x;
    this.y = y;
    this._specialEventMgr = G.saveState.tokenEventManager;
    this._tokenTextStyle = style;

    this._initCollectionPanel();
  }

  _initCollectionPanel() {
    const { _specialEventMgr, _tokenTextStyle } = this;
    const totalCollected = _specialEventMgr.totalTokensCollected;
    const spaceBetween = 5;

    const tokenIcon = G.makeImage(0, 0, G.OMTsettings.tokenEvent.boardTokenAsset, 0.5, this);
    tokenIcon.scale.set(89 / tokenIcon.width);
    tokenIcon.anchor.setTo(1, 0.5);

    const tokenText = new G.Text(0, 0, `${totalCollected} ${OMT.language.getText('Collected')}`, _tokenTextStyle, 0.5, 380);
    tokenText.anchor.setTo(0, 0.5);
    this.add(tokenText);

    tokenIcon.x -= (spaceBetween + tokenText.width - tokenIcon.width) / 2;
    tokenText.x = tokenIcon.x + spaceBetween;
  }
}
