/* eslint-disable import/no-cycle */
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import { SHOP_DEAL_TYPES } from '../Shop/Shop_Utils';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import { RenderTextureGenerator } from '@omt-components/Imaging/RenderTextures/RenderTextureGenerator';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';

export default class Shop3_RegularDeal extends Phaser.Group {
  /**
   * This is the regular deal in the shop
   * @param {{type:string, deal:FBDeal}} deal
   * @param {Object} config
   * @param {number} config.index
   * @param {Phaser.Rectangle} config.dimensions
   */
  constructor(deal, config) {
    super(game, null);
    this._gameScale = GameScaleController.getInstance().gameScale;
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;

    this.reInit(deal, config);
  }

  /**
   * Returns the FB catalog object
   * @returns {{type:string, deal:FBDeal}}
   */
  get dealData() {
    return this._data;
  }

  /**
   * Returns the frame width and nothing poking out of it
   * @returns {number}
   */
  get width() {
    return this._calculatedBounds.width;
  }

  /**
   * Returns the frame height and nothing poking out of it
   * @returns {number}
   */
  get height() {
    return this._calculatedBounds.height;
  }

  /**
   * Destroy!
   */
  destroy() {
    this._clearEverything();
    super.destroy();
  }

  /**
   * clears all tweens and signals
   */
  _clearEverything() {
    if (this._allTweens) {
      this._allTweens.forEach((tw) => {
        tw.stop();
      });
    }
    if (this.signals) {
      Object.keys(this.signals).forEach((key) => {
        if (this.signals[key].dispose) {
          this.signals[key].dispose();
        }
      });
      this.signals = null;
    }
  }

  /**
   * Reinit the thing. Might not be used.
   * @param {{type:string, deal:FBDeal}} deal
   * @param {Object} config
   * @param {number} config.index
   * @param {Phaser.Rectangle} config.dimensions
   */
  reInit(deal, config) {
    this.children.forEach((child) => {
      child.destroy();
    });
    this.removeChildren();
    this._clearEverything();

    this._config = config;
    this._data = deal;

    this._allTweens = [];
    this.signals = {
      onClick: new Phaser.Signal(), // Signal for when the button is clicked
    };
    this._init();
  }

  /**
   * Initialize
   */
  _init() {
    const assumedHeight = this._config.dimensions.height;
    const assumedWidth = this._config.dimensions.width;
    const textureHeight = this._isLandscape ? assumedHeight / this._gameScale : assumedHeight;
    const textureWidth = this._isLandscape ? assumedWidth / this._gameScale : assumedWidth;

    // Frame
    const textureId = `shop3_regularDealFrame_${textureWidth}_${textureHeight}`;
    let frame = RenderTextureGenerator.getRenderTextureSprite(textureId);
    if (!frame) {
      const frameGraphic = new UI_NineSlice(0, 0, 'shop3_smallDeal', textureWidth, textureHeight, G.OMTsettings.elements.Window_shop3.regularDealFrameSlice);
      frame = RenderTextureGenerator.generateRenderTextureSprite(frameGraphic, textureId);
      frameGraphic.destroy();
    }
    frame.anchor.set(0.5);

    this.addChild(frame);
    this._calculatedBounds = frame.getBounds().clone();

    // Adjust bounds values in landscape to reflect actual width and height
    if (this._isLandscape) {
      frame.scale.setTo(this._gameScale);
      this._calculatedBounds.width *= this._gameScale;
      this._calculatedBounds.height *= this._gameScale;
    }

    // Shop asset based on goods
    if (this._data.type === SHOP_DEAL_TYPES.FREE) {
      const gift = G.makeImage(0, 0, 'gift_full', [0.5, 0.75], this);
      gift.scale.set((assumedHeight * 0.6) / gift.height);
      this._animateShopIcon(gift);
    } else {
      const dealData = this._data.type.split('_');
      switch (dealData[0]) {
        case 'coins': { // Coins
          const img = G.makeImage(0, 0, `shop_deal_${this._config.index + 1}`, [0.5, 0.75], this);
          if (this._isLandscape) {
            img.scale.setTo(this._gameScale);
          }
          break;
        }
        case 'inflives': {
          const img = G.makeImage(0, 0, 'shop3_heart_unlimited', [0.5, 1], this);
          if (this._isLandscape) {
            img.scale.setTo((100 / img.width) * this._gameScale);
          } else {
            img.scale.set(100 / img.width);
          }
          break;
        }
        default: break;
      }
    }

    // Button
    const button = new G.Button(0, 0, null, this._onButtonClick.bind(this));
    this._button = button;
    const buttonAnimation = new Phaser.Group(game, button);
    const buttonContainer = new Phaser.Group(game, buttonAnimation); // The container to scale the button
    const buttonImage = new OMT_UI_SquareButton(0, 0, { // Without the nineslice affecting it
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 300, // original button size
          height: 83,
        },
        extraDetail: false,
      },
      options: {
        clickFunction: {
          scaleOnClick: false,
        },
      },
    });
    buttonImage.inputEnabled = false; // So it won't eat inputs. BUTTONSTATE.disabled will automatically set the button to grey
    buttonContainer.addChild(buttonImage);
    const buttonScale = (assumedWidth * 0.75) / buttonImage.width; // Find the size it needs to be
    buttonContainer.scale.set(buttonScale);

    const buttonModDimensions = {
      width: (buttonImage.width * buttonContainer.scale.x),
      height: (buttonImage.height * buttonContainer.scale.y),
    }; // I don't want to use long version

    if (this._isLandscape) {
      buttonModDimensions.width /= this._gameScale / 0.8;
      buttonModDimensions.height /= this._gameScale / 0.8;
    }

    const buttonText = new G.Text(0, 1, this._data.deal.price, 'shop3-SpecialOffer', 0.5, buttonModDimensions.width, buttonModDimensions.height, false, 'center', false);
    if (this._isLandscape) {
      buttonText.y += 3;
    }
    buttonAnimation.addChild(buttonText);

    button.resetAnimation = () => { // Resets the animation on the button
      if (button.downTween) {
        button.downTween.stop();
      }
      button.downTween = game.add.tween(buttonAnimation.scale).to({
        x: 1,
        y: 1,
      }, 50, Phaser.Easing.Quadratic.In, true);
    };
    button.down = () => { // Override function that happens when clickHandler has onDown triggered
      if (button.downTween) {
        button.downTween.stop();
      }
      button.downTween = game.add.tween(buttonAnimation.scale).to({
        x: 0.9,
        y: 0.9,
      }, 100, Phaser.Easing.Quadratic.In, true);
    };
    button.cancel = () => { // Override function that happens when ClickHandler triggers cancel
      button.resetAnimation();
    };
    button.calculatedBounds = { width: buttonContainer.with, height: buttonContainer.height };

    const buttonYOffset = this._isLandscape ? -5 : 0;
    button.y = Math.round((this.height / 2) - button.calculatedBounds.height * 2 + buttonYOffset);
    this.addChild(button);

    this._makeValueBar();
    if (this._data.deal.description && this._data.deal.description !== '' && this._data.deal.description !== '-') {
      this._createCornerTag(this._data.deal.description);
    }
  }

  /**
   * Value bar is the bar underneath that provides a short desciption of the product.
   * Like for Coins is how many they get
   * Or lives it would be how long it'll be
   */
  _makeValueBar() {
    if (this._data.type === SHOP_DEAL_TYPES.FREE) { // Free items don't have anything
      // Don't do anything actually...
    } else { // For everything else
      const valueBar = new Phaser.Group(game, this);
      const bar = G.makeImage(0, 0, 'shop3_dealCoinBar', 0.5, valueBar); // Bar
      let textStyle;

      const dealData = this._data.type.split('_');

      // Determine coin bar size based on orientation
      if (this._isLandscape) {
        bar.scale.setTo(1.25);
        if (dealData[0] === 'inflives') {
          valueBar.scale.setTo(0.75);
        }
        textStyle = {
          style: 'shop3-regularValue',
          fontSize: 48,
        };
      } else {
        textStyle = 'shop3-regularValue';
      }

      switch (dealData[0]) {
        case 'coins': { // Coins
          const valueText = new G.Text(0, 2, OMT.language.toLocaleNumber(dealData[1].replace('k', '000')), textStyle, 0.5, valueBar.width * 1.5, valueBar.height * 1.5);
          valueBar.addChild(valueText);
          break;
        }
        case 'inflives': {
          const valueText = new G.Text(0, 2, dealData[1], textStyle, 0.5, valueBar.width * 1.5, valueBar.height * 1.5);
          valueBar.addChild(valueText);
          break;
        }
        default: break;
      }

      if (this._isLandscape) {
        valueBar.scale.setTo(this._gameScale * 0.9);
        valueBar.y = Math.round(this._button.y - (20 * this._gameScale) - (this._button.calculatedBounds.height + valueBar.height) / 2);
      } else {
        valueBar.y = Math.round(this._button.y - 20 - (this._button.calculatedBounds.height + valueBar.height) / 2);
      }
      // valueBar.cacheAsBitmap = true;
    }
  }

  /**
   * Creates the tag in the corner based on description
   */
  _createCornerTag(tagText) {
    const text = G.saveState.sessionData.shopTagText || tagText;
    const tag = this._cornerTag = new Phaser.Group(game, this);
    const base = G.makeImage(0, 0, `shop3_sticker${G.OMTsettings.elements.Window_shop3.regularDealTagIndex}`, 0.5, tag); // tint base
    const textPack = new Phaser.Group(game, tag);
    const regex = new RegExp('\\%(\\d+)\\%');
    const regexValue = regex.exec(text);
    const value = regexValue ? regexValue[1] : '';
    const strCopy = text.replace(new RegExp('\\%\\d+\\%'), '%VALUE%');
    const targetText = OMT.language.doesTextExist(OMT.language.lang, strCopy) ? OMT.language.getText(strCopy) : strCopy;
    const wowText = new G.Text(1, 8,
      targetText.replace('%VALUE%', value).replace('<br>', '\n'), 'shop3-ValueDeal',
      0.5, base.width * G.OMTsettings.elements.Window_shop3.tagBaseMultipler, base.height * G.OMTsettings.elements.Window_shop3.tagBaseMultipler, true, 'center');
    textPack.addChild(wowText);

    textPack.x = 2;
    textPack.y = -1 - Math.round(textPack.height / 8);

    const tagOffset = tag.width * 0.75;

    if (this._isLandscape) {
      tag.x = Math.round((this._calculatedBounds.width - tagOffset * this._gameScale) / 2);
      tag.y = Math.round((tag.height * this._gameScale - this._calculatedBounds.height) / 2);

      // Multi-slot deals get a larger corner tag in landscape
      if (this._data.slot > 1) {
        tag.x = Math.round(tag.x - tag.width / 8);
        tag.scale.setTo(1.25 * this._gameScale);
      } else {
        tag.scale.setTo(this._gameScale);
      }
    } else {
      tag.x = Math.round((this._calculatedBounds.width - tagOffset) / 2);
      tag.y = Math.round((tag.height - this._calculatedBounds.height) / 2);
    }

    tag.angle = 35;
    // tag.cacheAsBitmap = true;
  }


  /**
   * animate a icon with a pulse animation
   * @param {Object} icon
   */
  _animateShopIcon(icon) {
    const animTime = 900;
    const delayTime = 1000 - animTime;
    const originalScale = icon.scale.x;
    const bigScale = originalScale + 0.05;
    const bounceAnim = game.add.tween(icon.scale) // Initial tween for the raising action
      .to({ x: [bigScale, originalScale], y: [bigScale, originalScale] }, animTime, Phaser.Easing.Back.InOut, true);
    const subtlePause = game.add.tween(icon) // Pause...
      .to({ y: icon.y }, delayTime, Phaser.Easing.Linear.None);
    bounceAnim.chain(subtlePause);
    subtlePause.chain(bounceAnim);

    this._allTweens.push(bounceAnim);
    this._allTweens.push(subtlePause);
  }

  /**
   * When purchasing is desired
   */
  _onButtonClick() {
    this._button.resetAnimation();
    this.signals.onClick.dispatch(this._data.deal, this._button);
  }

  /**
   * add a discount tag to the deal. align it to the top-left.
   * @param {Phaser.Group} tag
   */
  addDiscountTag(tag) {
    tag.x = (-this._calculatedBounds.width / 2);
    tag.y = (-this._calculatedBounds.height / 2);
    this.addChild(tag);
    this._tag = tag;
  }
}
