/* eslint-disable no-use-before-define */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable wrap-iife */
G.Helpers = G.Helpers || {};

// TODO: refactor this to properly handle diffrent scopes. Currently this will not work in all scopes

G.Helpers.gatesMgr = (function () {
  let gatesData = null;
  let saveStateMgr = null;
  let levelDataMgr = null;

  const signals = {
    onGateOpened: new Phaser.Signal(),
    onGateActivated: new Phaser.Signal(),
  };

  const instances = {};

  return {
    signals,
    instances,

    init,
    getGateObject,
    getGateDimensionForLevelIndex,
    getLastGate,
  };


  function getGateObject(gateId) {
    const gateConfig = getGateConfig(gateId);
    const saveData = getSaveData(gateId);
    return createGateObject(gateConfig, saveData);
  }

  function createGateObject(data, saveData) {
    const mapPosition = levelDataMgr.getMapPosition(getLevelNumber() - 1);

    return {
      getId,
      getTimeRequirementInMinutes,
      getStarsRequirement,
      getPriceOfOpening,
      getLevelNumber,
      getLevelIndex,
      getMapPosition,
      isOpen,
      isReadyToOpen,
      getTimeToOpeningInSeconds,
      getInvitesRequirement,
      getInvitesList,
      getInvitesAvatarsList,
    };

    function getId() {
      return data.id;
    }

    function getMapPosition() {
      return mapPosition;
    }

    function getLevelNumber() {
      return data.lvlNr;
    }

    function getLevelIndex() {
      return getLevelNumber() - 1;
    }

    function getTimeRequirementInMinutes() {
      return data.req.timeMinutes;
    }

    function getTimeToOpeningInSeconds() {
      return Math.ceil(
        (
          saveStateMgr.getUserCooldownRemaining(`gateId_${saveData.gateId}`, '')
        ) / 1000,
      );
    }

    function getStarsRequirement() {
      return data.req.stars;
    }

    function getInvitesRequirement() {
      return data.req.invites || 100;
    }

    function getPriceOfOpening() {
      return data.req.coins;
    }

    function getInvitesList() {
      return saveData.invitesList || [];
    }

    function getInvitesAvatarsList() {
      return saveData.avatarsList || [];
    }

    function isOpen() {
      return saveData.open;
    }

    function isReadyToOpen() {
      return saveData.readyToOpen;
    }
  }

  function init(data) {
    saveStateMgr = G.saveState;
    levelDataMgr = G.Helpers.levelDataMgr;
    gatesData = data || gatesData;
  }

  function getGateConfig(gateId) {
    return gatesData.find(function (gate) {
      return gate.id === gateId;
    });
  }

  function getSaveData(gateId) {
    if (!saveStateMgr.data.gates[gateId]) {
      saveStateMgr.data.gates[gateId] = {
        open: false,
        openMethod: '',
        timerStartedAt: -1,
        gateActiveAt: -1,
        invites: 0,
        invitesSent: 0,
        gateId,
      };
    }

    return saveStateMgr.data.gates[gateId];
  }

  function getGateDimensionForLevelIndex(levelIndex) {
    const gatesLvlNr = [0].concat(gatesData.map(function (gate) {
      return gate.lvlNr;
    }));

    let i = 0; // i needs to be referenced outside of loop
    for (i = 0; i < gatesLvlNr.length; i++) {
      if (levelIndex < gatesLvlNr[i] - 1) {
        return i - 1;
      }
    }

    return i - 1;
  }

  function getLastGate() {
    let lastGate;
    for (let i = gatesData.length - 1; i > -1; i--) {
      const curGate = gatesData[i];
      if (!lastGate || (lastGate && lastGate.id < curGate.id)) {
        lastGate = curGate;
      }
    }
    return lastGate;
  }
})();
