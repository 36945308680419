/* eslint-disable no-param-reassign */
import { FriendshipChest_Card, FRIENDSHIPCHEST_BUTTONMODE, FRIENDSHIPCHESTCARD_STATUS } from './FriendshipChest_Card';
import { SOCIAL_STATUS } from '../../../Services/OMT/OMT_Social';
import OMT_BgLoadingUtil from '../../../Utils/OMT_BgLoadingUtil'; // eslint-disable-line import/no-cycle
import OMT_UI_HorizontalCardMenu from '../../../OMT_UI/Menus/slidingCardMenu/OMT_UI_HorizontalCardMenu';
import { BUTTONSTATE } from '../../../OMT_UI/OMT_UI_SquareButton';
import { OMT_AssetLoader } from '../../../Services/OMT/OMT_AssetLoader';

/**
 * Friendship chest! (May be abbreviated as FC)
 * 15/04/20 SK: This particular Friendship Chest class was remade as Friendship Chest v2.
 * However this Friendship Chest class is still being used as the promo card for the Friendship Chest!
 * So, if you're looking for Friendship Chest code, please head to Friendship_Chestv2(_FTUX).js
 * @author Sandra Koo
 */
export class Friendship_Chest {
  /**
   * @param {Object} config
   * @param {Window} config.window
   * @param {Phaser.Group} config.container
   * @param {boolean} tutorialMode
   */
  constructor(config) {
    this.parentWindow = config.window; // This isn't a window class but it still needs one.
    this.displayContainer = config.container; // The group where the display will go
    this.tutorialMode = config.tutorialMode; // There is potential for there to be a tutorial
    this.isStoryMode = OMT.feature.getFriendshipChestStory(); // Is this the story mode version?

    this.signals = {
      onCloseClicked: new Phaser.Signal(), // Signal to the window to close everything
      onReady: new Phaser.Signal(),
      onTutorialProgress: new Phaser.Signal(), // Signal to progress the tutorial, if there is one.
    };

    this.maxCardsLinkedDistance = 2; // Number of cards that can be linked and kept in memory or else it'll be destroyed
    this.cardsBeforeEndCardIsRevealed = 1; // Number of cards that have to be opened before the [?] card is shown
    this.cardDimensions = undefined; // Rect, filled when a card is made
    this.prizeTable = undefined; // collected in assembleData;
    this.closeBtn = this.makeCloseButton(); // Creates the close button

    this.currentCard = this.drawCard(0); // Draw blank card first, with index 0. It'll change when data is retrieved
    this.displayContainer.addChild(this.currentCard);

    this.prevCardPosition = -(this.cardDimensions.width * 0.4) - (game.width / 2);
    this.nextCardPosition = (game.width / 2) + (this.cardDimensions.width * 0.4);
    this.arrows = this.drawArrows(); // Draw and position side buttons

    this.perpetualHand = undefined;

    G.saveState.friendshipChestDataManager.incrementOpened(); // Increment the number of times this has opened. Only happens once per chest window

    this.horizontalCardPositioner = new OMT_UI_HorizontalCardMenu({
      left: this.arrows.left,
      right: this.arrows.right,
      close: this.closeBtn,
    });
    this.horizontalCardPositioner.signals.onLeftClick.add(this.onLeftClick, this);
    this.horizontalCardPositioner.signals.onRightClick.add(this.onRightClick, this);

    this.init();
  }

  /**
   * Async init because the assembling data part could take a long time
   */
  async init() {
    let waitingIcon = new G.WaitingIcon(0, 0, 'waiting_icon'); // Loading...
    this.displayContainer.addChild(waitingIcon);
    let initTimer = game.time.events.add(2000, () => { // Timeout close button option
      if (waitingIcon.parent) { // Still there
        this.closeBtn.visible = false;
        this.positionCloseButton();
        this.showPerpetualHand();
        this.horizontalCardPositioner.blinkInUIElements({
          left: null,
          right: null,
          close: this.closeBtn,
          tweenTime: 200,
        });
      }
    });
    const initData = await this.assembleData(); // This will take a loooooong time!
    if (initTimer) { // If data is assembled before timer fired, remove it
      game.time.events.remove(initTimer);
      initTimer = null;
    }
    if (waitingIcon.parent) { // Remove the icon
      waitingIcon.parent.removeChild(waitingIcon);
      waitingIcon.destroy();
      waitingIcon = null;
    }

    if (!this.parentWindow.game) { return; } // If the game is still here...

    const needsFriendAnimation = initData.unclaimed ? initData.unclaimed.s === FRIENDSHIPCHESTCARD_STATUS.FRIEND : false; // Need to show the intro?
    const rewardsAvailable = initData.unclaimed || false; // Are there rewards?
    if (rewardsAvailable) {
      this.currentCard.index = initData.unclaimed.i; // This will be the first claimable card
      if (needsFriendAnimation) {
        this.currentCard.alpha = 0;
      }
    } else {
      this.currentCard.index = initData.count - 1; // Last card
      const cardData = G.saveState.friendshipChestDataManager.findCardByIndex(this.currentCard.index, true);
      if (cardData.s !== FRIENDSHIPCHESTCARD_STATUS.OPEN) { // In case somehow the data corrupted and the last card is not an opened card
        G.saveState.friendshipChestDataManager.openNewCard(this.currentCard.index + 1);
        initData.count += 1;
        this.currentCard.index += 1;
      }
    }
    this.fillCard(this.currentCard, this.prizeTable);

    if (this.currentCard.index > 0) { // Show card behind
      this.displayPrevCard(this.currentCard.index - 1);
    }

    if (initData.count > this.cardsBeforeEndCardIsRevealed || this.currentCard.index < (initData.count - 1)) { // Show the end card if we opened > 1 cards
      this.displayNextCard(this.currentCard.index + 1);
    }

    if (!needsFriendAnimation) { // If we're not doing animation, check the card
      this.checkCard();
    }
    this.signals.onReady.dispatch();

    // if (!this.tutorialMode) DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenFriendshipChestShareBtn', 1);
  }

  /**
   * Destroys async because this queue could be long
   */
  async destroy() {
    const queue = [this.currentCard];
    while (queue.length > 0) {
      const card = queue.pop();
      if (card.nextCard) {
        queue.push(card.nextCard);
      }
      if (card.prevCard) {
        queue.push(card.prevCard);
      }

      card.destroyCard();
    }

    if (this.perpetualHand) {
      this.perpetualHand.tween.stop();
      this.perpetualHand.destroy();
    }
    this.horizontalCardPositioner.destroy();

    this.signals.onCloseClicked.dispose();
    this.signals.onReady.dispose();
    this.signals.onTutorialProgress.dispose();
  }

  /**
   * Assembling data.
   */
  async assembleData() {
    G.saveState.friendshipChestDataManager.sortCards(); // This might take a butt long time!

    this.prizeTable = G.json.settings.friendshipChest_prizes.prizeTable;

    const data = {
      count: G.saveState.friendshipChestDataManager.getCardCount(),
      unclaimed: G.saveState.friendshipChestDataManager.getUnclaimedCard(), // Unclaimed or ready to be claimed cards
    };

    return data;
  }

  /**
   * A function for itself in case there are other things to include
   */
  makeCloseButton() {
    return new G.Button(0, 0, 'btn_x', () => { this.signals.onCloseClicked.dispatch(); });
  }

  /**
   * Positions and adds it on top
   */
  positionCloseButton() {
    this.closeBtn.x = (this.cardDimensions.width - this.closeBtn.width / 4) / 2;
    this.closeBtn.y = -(this.cardDimensions.height - this.closeBtn.height / 4) / 2;
    this.displayContainer.addChild(this.closeBtn);
  }

  /**
   * Draws the card, and fills based on index
   * @param {number} index
   */
  displayCard(index) {
    const card = this.drawCard(index);
    this.fillCard(card, this.prizeTable);

    this.displayContainer.addChild(card);

    return card;
  }

  /**
   * Draws the card, fills it, hides the fill, and sticks it to the side
   * @param {number} index
   */
  displayPrevCard(index) {
    const card = this.drawCard(index);
    this.fillCard(card, this.prizeTable);
    card.fill.visible = false;

    card.x = this.prevCardPosition;
    this.displayContainer.addChild(card);

    this.currentCard.prevCard = card;
    card.nextCard = this.currentCard;

    return card;
  }

  /**
   * Draws the card, fills it (maybe), hides the fill, and sticks it to the side.
   * @param {number} index
   */
  displayNextCard(index) {
    // if (G.saveState.friendshipchest.friendshipChestDataManager.getCardCount() >= G.OMTsettings.friendshipChest.maxCards) {
    //   console.log('Too many cards...!');
    //   return this.currentCard;
    // }
    const card = this.drawCard(index);
    this.fillCard(card, this.prizeTable);
    card.fill.visible = false;

    card.x = this.nextCardPosition;
    this.displayContainer.addChild(card);

    this.currentCard.nextCard = card;
    card.prevCard = this.currentCard;

    return card;
  }

  /**
   * Adds a new card to the right and adds it in view
   * @param {number} index
   */
  displayAndInsertNextCard(index) {
    if (this.currentCard.nextCard) { // If card
      if (this.currentCard.nextCard.status === FRIENDSHIPCHESTCARD_STATUS.END) { // Is the end card?
        G.saveState.friendshipChestDataManager.openNewCard(index); // Open new card
        const lastCard = this.currentCard.nextCard; // Bump the end card index to be farther
        lastCard.index += 1;
        lastCard.x = this.nextCardPosition + lastCard.width; // Make it go away.
        lastCard.visible = false;
        const newCard = this.displayNextCard(index); // Makes the new next card
        this.currentCard.nextCard = newCard; // Fix links
        lastCard.prevCard = newCard;
        newCard.prevCard = this.currentCard;
        newCard.nextCard = lastCard;

        return newCard;
      }
      return null; // Next card is not an end card
    }
    // There is no card? Make a new one
    G.saveState.friendshipChestDataManager.openNewCard(index);
    return this.displayNextCard(index);
  }

  /**
   * Draws the card base
   * @param {number} index
   */
  drawCard(index) {
    const card = new FriendshipChest_Card(G.game, null, index);

    if (!this.cardDimensions) { // If this isn't set, set it now. All cards are like this now
      this.cardDimensions = card.getBounds().clone();
    }

    return card;
  }

  /**
   * Sets listeners on card and fills the inside
   * @param {FriendshipChest_Card} card
   */
  fillCard(card) {
    this.setListenerOnCard(card);
    card.fillCard(G.saveState.friendshipChestDataManager.findCardByIndex(card.index, true), this.prizeTable);
  }

  /**
   * Checks if the card is the friend card and requires special animation before doing things
   * @param {boolean} doNotShowUI // Shows UI or not
   */
  checkCard(doNotShowUI = false) {
    if (this.currentCard.friend) {
      this.hidePerpetualHand(100);
      this.horizontalCardPositioner.blinkOutUIElements({
        left: this.arrows.left,
        right: this.arrows.right,
        close: this.closeBtn,
        tweenTime: 100,
      });
      this.animateOutFriend(this.currentCard); // animate out the friend
    } else if (!doNotShowUI) { // Look natural!
      this.positionCloseButton();
      if (this.perpetualHand) {
        this.displayContainer.addChild(this.perpetualHand);
      }
      if (this.currentCard.button) {
        this.showPerpetualHand();
      } else {
        this.hidePerpetualHand(100);
      }
      this.horizontalCardPositioner.checkUI({
        current: this.currentCard,
        nextCard: this.currentCard.nextCard,
        prevCard: this.currentCard.prevCard,
        left: this.arrows.left,
        right: this.arrows.right,
        leftPosition: ((this.prevCardPosition + this.cardDimensions.width / 2) - (this.cardDimensions.width / 2)) / 2,
        rightPostion: ((this.nextCardPosition - this.cardDimensions.width / 2) + (this.cardDimensions.width / 2)) / 2,
        container: this.parentWindow,
        prevPosition: this.prevCardPosition,
        nextPosition: this.nextCardPosition,
        closeBtn: this.closeBtn,
      });
    }
  }

  /**
   * Sets the signals on the card
   * @param {FriendshipChest_Card} card
   */
  setListenerOnCard(card) {
    // onCardReady is only for cards that show the friend because of async calls. Lots of checks to avoid adding the event twice, for some reason.
    if (!card.signals.onCardReady.has(this.animateFriendCard, this) && ((this.currentCard && this.currentCard.index === card.index) || !this.currentCard)) {
      card.signals.onCardReady.add(this.animateFriendCard, this);
    }
    if (!card.signals.onClick.has(this.onButtonClick, this)) {
      card.signals.onClick.add(this.onButtonClick, this);
    }
  }

  /**
   * Signal from FriendshipChest_Card gets fired into here.
   * @param {FriendshipChest_Card} card
   */
  async onButtonClick(card) {
    if (!card.fill.visible) { return; } // If it wasn't supposed to be seen
    if (!card.button.enabled) { return; } // If it wasn't supposed to be clicked
    if (this.tutorialMode && card.index === 0 && card.button.buttonMode === FRIENDSHIPCHEST_BUTTONMODE.INVITE) { // Hijack the button click for tutorial
      this.signals.onTutorialProgress.dispatch();
      return;
    }

    card.button.enabled = false;

    // You need to make a loading screen or overlay...
    if (card.button.buttonMode === FRIENDSHIPCHEST_BUTTONMODE.INVITE) { // If the button is INVITE FRIENDS
      let result = false; // Result of adding friends
      let triedToInvite = null; // The ID of a friend user tried to invite
      const findOtherPlayer = async () => {
        const FBdata = await OMT.social.getPlayersInContext(); // Checking context for other players
        if (Array.isArray(FBdata)) { // Just in case something weird comes back
          result = FBdata.length < 2; // Might be just you here
          if (!result) { // Back to false?
            for (let i = 0; i < FBdata.length; i++) {
              const player = FBdata[i].$1;
              if (player.id !== OMT.envData.settings.user.userId) {
                triedToInvite = player; // Found out who you tried to invite
                return;
              }
            }
          }
        }
      };
      result = await OMT.social.sendFriendshipChestInvite(async () => { // Waiting for status...
        await findOtherPlayer();
        return !triedToInvite;
      });
      if (result === SOCIAL_STATUS.SAME_CONTEXT) {
        await findOtherPlayer();
        if (!triedToInvite) { // Acts similarily to trying to switch context to someone whom you're with already
          result = SOCIAL_STATUS.PASS;
        }
      }

      if (result === SOCIAL_STATUS.PASS) {
        // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenFriendshipChestShareBtn', 1);
        // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('usedFriendshipChestShareBtn', 1);
        // DDNA.socialTracker.incrementParam('numFriendsInvites', 1);
        // DDNA.tracking.socialActionEvent('friendshipChestInvite', '', '', '');
        G.saveState.friendshipChestDataManager.setPending(card.index);
        card.setStatus(FRIENDSHIPCHESTCARD_STATUS.PENDING);
        G.saveState.friendshipChestDataManager.setNumberOfLevelsToAppear(40, true);
        card.drawInvitationSent();
        card.animateInSentBanner();
        this.hidePerpetualHand();
        this.horizontalCardPositioner.blinkOutUIElements({
          right: this.arrows.right,
          left: this.arrows.left,
          close: this.closeBtn,
          tweenTime: 200,
        });
        game.time.events.add(2200, () => { // 2secs + 200ms for animation
          this.addNewEndCardAndTransitionRight();
        }, this);
      } else { // !result
        if (triedToInvite) { /* eslint-disable-line no-lonely-if */ // check again
          // Show error card
          card.drawErrorCard(triedToInvite);
          card.button.enabled = true;
        } else { // General failure
          card.button.enabled = true;
        }
      }
    } else if (card.button.buttonMode === FRIENDSHIPCHEST_BUTTONMODE.CLAIM) { // Button is CLAIM instead
      // Do fancy animation
      if (this.tutorialMode) { // Progress tutorial too
        this.signals.onTutorialProgress.dispatch();
      }
      this.readyClaim(card, (goods) => { // Draw everything
        this.animateClaim(card, goods); // Go time!
      });
    }
  }

  /**
   * Draws arrows
   */
  drawArrows() {
    const leftArrow = this.drawArrow(false);
    const rightArrow = this.drawArrow(true);

    return {
      left: leftArrow,
      right: rightArrow,
    };
  }

  /**
   * Draws a singular arrow. If its not a right arrow, it flips it around.
   * @param {boolean} isRight
   */
  drawArrow(isRight) {
    const arrowGroup = new G.Button(0, 0, null);
    const arrow = G.makeImage(0, 0, 'friendshipArrow', 0.5, null);
    if (isRight) {
      arrow.scale.x *= -1;
    }

    arrowGroup.addChild(arrow);

    return arrowGroup;
  }

  /**
   * Function for when the left button is clicked
   */
  onLeftClick(force = false) {
    if (!this.arrows.left.enabled && !force) { return; }
    if (this.currentCard.prevCard) { // If theres a previous card
      this.tweenCards(this.currentCard, false, () => { // Tween it and...
        this.currentCard = this.currentCard.prevCard; // Fix links
        this.currentCard.nextCard.fill.visible = false; // Hide the far card

        if (this.currentCard.index > 0 && !this.currentCard.prevCard) { // If theres supposed to be a previous card and its not in memory
          this.displayPrevCard(this.currentCard.index - 1); // Show it
          this.positionCloseButton(); // Keep close button on top
        } else {
          // eslint-disable-next-line no-lonely-if
          if (this.currentCard.prevCard) { // Bring the card over
            this.currentCard.prevCard.x = this.prevCardPosition;
            this.currentCard.prevCard.visible = true;
          }
        }
        this.cleanUpCardDistance(); // Clean up cards
        this.checkCard(); // Check for animations
      });
    }
  }

  /**
   * Function for when the right button is clicked
   * @param {boolean} force
   * @param {boolean} doNotShowUI // Mostly used for tutorial
   * @param {function} onComplete // Mostly used for tutorial
   */
  onRightClick(force = false, doNotShowUI = false, onComplete = null) {
    if (!this.arrows.right.enabled && !force) { return; }
    if (this.currentCard.nextCard) { // If theres a next card
      this.tweenCards(this.currentCard, true, () => { // Tween it and...
        this.currentCard = this.currentCard.nextCard; // Fix links
        this.currentCard.prevCard.fill.visible = false; // Hide the far card
        const cardCount = G.saveState.friendshipChestDataManager.getCardCount();
        if (this.currentCard.index < cardCount && cardCount > this.cardsBeforeEndCardIsRevealed && !this.currentCard.nextCard) { // If theres no card next in memory
          this.displayNextCard(this.currentCard.index + 1); // Make card
          if (!this.tutorialMode) {
            this.positionCloseButton(); // Keep close button on top
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (this.currentCard.nextCard) { // Bring the card over
            this.currentCard.nextCard.x = this.nextCardPosition;
            this.currentCard.nextCard.visible = true;
          }
        }
        this.cleanUpCardDistance(); // Clean up cards
        this.checkCard(doNotShowUI); // Check for animations

        if (onComplete) {
          onComplete();
        }
      });
    }
  }

  /**
   * Checks distance of card from currentCard and destroys cards that are too far
   */
  async cleanUpCardDistance() {
    let curCard = this.currentCard;
    let cardDistanceCounter = 0;
    while (cardDistanceCounter < this.maxCardsLinkedDistance) {
      curCard = curCard.prevCard;
      if (!curCard) {
        cardDistanceCounter = this.maxCardsLinkedDistance + 1;
      } else {
        cardDistanceCounter++;
      }
    }
    if (curCard && curCard !== this.currentCard) { // Juuuust in case
      curCard.nextCard.prevCard = null;
      curCard.destroyCard();
    }

    cardDistanceCounter = 0;
    curCard = this.currentCard;
    while (cardDistanceCounter < this.maxCardsLinkedDistance) {
      curCard = curCard.nextCard;
      if (!curCard) {
        cardDistanceCounter = this.maxCardsLinkedDistance + 1;
      } else {
        cardDistanceCounter++;
      }
    }
    if (curCard && curCard !== this.currentCard) {
      curCard.prevCard.nextCard = null;
      curCard.destroyCard();
    }
  }

  /**
   * Tweens the cards in the direction dictated by isRight.
   * Calls onComplete when finished tweening
   * @param {FriendshipChest_Card} current
   * @param {boolean} isRight
   * @param {function} onComplete
   */
  tweenCards(current, isRight, onComplete) {
    const tweenTime = 200;
    const prev = current.prevCard;
    const next = current.nextCard;
    this.horizontalCardPositioner.tweenObjects({
      current,
      prev,
      next,
      objectDimension: this.cardDimensions,
      nextPosition: this.nextCardPosition,
      prevPosition: this.prevCardPosition,
      isRight,
      onComplete,
      tweenTime,
      onPrevRightStart: () => {
        prev.fill.alpha = 0;
        prev.fill.visible = true;
        game.add.tween(prev.fill) // Tween in the fill if its supposed to be in
          .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.In, true);
      },
      onPrevComplete: () => {
        prev.fill.visible = false;
      },
      onNextRightStart: () => {
        next.fill.alpha = 0;
        next.fill.visible = true;
        game.add.tween(next.fill) // Tween in the fill
          .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.In, true);
      },
      onNextComplete: () => {
        next.fill.visible = false;
      },
    });
  }

  /**
   * Adds a new card to the right and simulates a right button click (forcefully)
   */
  addNewEndCardAndTransitionRight() {
    let onTransitionComplete; // A callback for the story chest
    const next = this.displayAndInsertNextCard(this.currentCard.index + 1);
    if (next) {
      next.fill.alpha = 0;
      if (this.isStoryMode) {
        next.readyStoryChest();
        onTransitionComplete = () => {
          this.animateStoryCard(next);
        };
      }
    } else {
      onTransitionComplete = () => { // Do a check card at the end
        if (this.currentCard.status !== FRIENDSHIPCHESTCARD_STATUS.FRIEND) {
          this.checkCard();
        }
      };
    }
    this.onRightClick(true, this.isStoryMode, onTransitionComplete);
  }

  /**
   * Spins the card in (first time only really), and animates out the friend
   * @param {FriendshipChest_Card} card
   */
  animateFriendCard(card) {
    const enterTween = game.add.tween(card) // Tween is initialized to add things if anything
      .to({ alpha: 1, angle: 0 }, 300, Phaser.Easing.Sinusoidal.Out, false);

    if (!this.tutorialMode) { // If not in tutorial mode, we'll animate as if friend has joined
      card.angle = -30;
    } else { // In tutorial mode we'll fade in the fill
      enterTween.onStart.addOnce(() => {
        game.add.tween(card.fill)
          .to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
      });
    }
    if (card.friend.animation) { // Start animation if there is one
      card.friend.animation.enter.start();
    }

    enterTween.onComplete.addOnce(() => { // Friend is animated out on complete
      this.animateOutFriend(card);
    });
    enterTween.start();
  }

  /**
   * Fades the friend info out before enabling the claim button
   * @param {FriendshipChest_Card} card
   */
  animateOutFriend(card) {
    if (card.friend.animation) { // Start the exit animation if there is one
      card.friend.animation.exit.start();
    }
    game.time.events.add(3000, () => { // Waits 3s before fading out the friend
      card.animateOutFriend(() => {
        G.saveState.friendshipChestDataManager.setClaimable(card.index); // Sets the card to its new state
        card.setStatus(FRIENDSHIPCHESTCARD_STATUS.UNCLAIMED);
        this.closeBtn.visible = false;
        this.positionCloseButton();
        this.showPerpetualHand();
        this.horizontalCardPositioner.checkUI({
          current: this.currentCard,
          nextCard: this.currentCard.nextCard,
          prevCard: this.currentCard.prevCard,
          left: this.arrows.left,
          right: this.arrows.right,
          leftPosition: ((this.prevCardPosition + this.cardDimensions.width / 2) - (this.cardDimensions.width / 2)) / 2,
          rightPostion: ((this.nextCardPosition - this.cardDimensions.width / 2) + (this.cardDimensions.width / 2)) / 2,
          dimensions: this.cardDimensions,
          container: this.parentWindow,
          prevPosition: this.prevCardPosition,
          nextPosition: this.nextCardPosition,
          closeBtn: this.closeBtn,
        });
        if (this.tutorialMode) { // Progress tutorial when friend leaves
          this.signals.onTutorialProgress.dispatch();
        }
      });
    }, this);
  }

  /**
   * Draws everything needed for the claim animation and returns it or calls onComplete with the info
   * @param {FriendshipChest_Card} card
   * @param {function} onComplete
   * @returns {
   *  container: Phaser.Group,
   *  prizeData: Object,
   *  banner: Phaser.Group,
   *  shine: Phaser.Image,
   *  chestOpen: Phaser.Group,
   *  chestClosed : Phaser.Group,
   *  prizeDisplay: Phaser.Group,
   *  applyGifts: function
   * }
   */
  readyClaim(card, onComplete) { // eslint-disable-line consistent-return
    const container = new Phaser.Group(G.game, null);
    const { prize } = card;

    const center = this.parentWindow.toLocal({ x: 0, y: card.positionals.chest / this.displayContainer.scale.y }, card.fill).y; // Finds the location of the center of the chest in the card
    const shine = G.makeImage(0, center, 'shine_godrays', 0.5, container);
    shine.scale.set(1.5, 1.5);
    shine.update = () => {
      if (container.visible && shine.alpha > 0) {
        shine.angle += 0.25;
      }
    };
    shine.alpha = 0;

    const titleBanner = new Phaser.Group(G.game, container);
    const purple = G.makeImage(0, 0, 'purple_banner', 0.5, titleBanner);
    const purpleBannerText = new G.Text(0, 0, OMT.language.getText('Friendship Chest'), { style: 'font-white', fontSize: 48 }, 0.5, purple.width * 0.95, purple.height, true, 'center');
    titleBanner.addChild(purpleBannerText);
    titleBanner.y = this.parentWindow.toLocal({ x: 0, y: card.positionals.friendshipChestTitle / this.displayContainer.scale.y }, card.fill).y;

    const prizeSection = card.generatePrizeSection(prize, { style: 'font-white', fontSize: 64 }); // Generates the prize section again from the card
    prizeSection.y = (this.parentWindow.toLocal(card.positionals.prizeSection, card.positionals.prizeSection.parent).y / this.displayContainer.scale.y) - this.displayContainer.y;
    container.addChild(prizeSection);

    const chestOpen = G.makeImage(0, center, 'friendship_chest_full', 0.5, container);
    chestOpen.alpha = 0;
    chestOpen.scale.set(1.8, 1.8);

    const chestClosed = new Phaser.Group(G.game, container);
    G.makeImage(0, 0, 'friendship_chest_closed', 0.5, chestClosed);
    chestClosed.y = center;

    const applyGifts = () => {
      const trackerArray = [];
      OMT.transactionTracking.logInventoryTransactionBegin();

      for (let i = 0; i < prize.length; i++) {
        const curPrize = prize[i];
        trackerArray.push([curPrize.prize, curPrize.amount]); // Puts into an array for data tracking

        switch (curPrize.prize) {
          case 'coin':
            this.parentWindow.state.uiTargetParticles.createCoinBatch( // Show bling
              game.world.bounds.x + prizeSection.worldPosition.x,
              prizeSection.worldPosition.y,
              this.parentWindow.state.panel.coinsTxt,
              curPrize.amount,
              false,
            );
            OMT.transactionTracking.addInventoryChange('coins', 0, curPrize.amount);
            break;
          case 'life':
            G.saveState.addLife(curPrize.amount); // Add life
            break;
          default: { // Perhaps bad, but default is assuming booster
            const boosterIndex = parseInt(curPrize.prize[8], 10);
            G.saveState.changeBoosterAmount(boosterIndex, curPrize.amount); // Add in booster
            OMT.transactionTracking.addInventoryChange('boostersReceived', boosterIndex, curPrize.amount);
            break;
          }
        }
      }

      // DDNA.transactionHelper.trackRewards(trackerArray, [], { // DDNA tracking
      //   transactionType: 'REWARD',
      //   tActionType: 'FRIENDSHIPCHEST_REWARD',
      //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
      //   mFriendshipChestOpened: G.saveState.friendshipChestDataManager.getOpened(),
      //   mFriendshipChestSocialRewardId: card.prizeIndex,
      // });
      OMT.transactionTracking.logInventoryTransactionEnd();
      G.saveState.save(); // Save!
    };

    const returnedGoods = {
      container,
      prizeData: prize,
      banner: titleBanner,
      shine,
      chestOpen,
      chestClosed,
      prizeDisplay: prizeSection,
      applyGifts,
    };

    if (onComplete) { onComplete(returnedGoods); } else { return returnedGoods; }
  }

  /**
   * Animates the claim animation with a ton of tweens
   * @param {FriendshipChest_Card} card
   * @param {Object} claimDisplay
   */
  animateClaim(card, claimDisplay) {
    this.horizontalCardPositioner.blinkOutUIElements({
      left: this.arrows.left,
      right: this.arrows.right,
      close: this.closeBtn,
      tweenTime: 200,
    }); // UI OUT!
    this.hidePerpetualHand(200);

    const tweenTime = 500;

    const cardOut = game.add.tween(card) // Current card fades out
      .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);

    // Get positions and sets the beginning displays of things
    this.displayContainer.addChild(claimDisplay.container);
    claimDisplay.banner.scale.set(1.5, 0.5);
    claimDisplay.banner.alpha = 0;
    const chestY = claimDisplay.chestClosed.y;
    claimDisplay.prizeDisplay.alpha = 0;
    const prizeY = claimDisplay.prizeDisplay.y;
    claimDisplay.prizeDisplay.y = chestY;
    claimDisplay.chestClosed.scale.set(0.3, 0.3);
    claimDisplay.chestClosed.y = chestY - 20;
    claimDisplay.shine.alpha = 0;

    let outro;
    // Is this too much tweening...?
    const prizeIn = game.add.tween(claimDisplay.container) // The claim display container fades in (with whatever isn't hidden)
      .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    prizeIn.onStart.addOnce(() => { // At the same time
      game.add.tween(claimDisplay.banner.scale) // The banner squeezes in
        .to({ x: 1, y: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      game.add.tween(claimDisplay.banner) // And fades in
        .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);

      game.add.tween(claimDisplay.chestClosed) // The closed chest hops out
        .to({ y: [chestY - 150, chestY] }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      const claimChest = game.add.tween(claimDisplay.chestClosed.scale) // The chest also grows from tiny to big
        .to({ x: claimDisplay.chestOpen.scale.x, y: claimDisplay.chestOpen.scale.y }, tweenTime, Phaser.Easing.Sinusoidal.Out, true);

      claimChest.onComplete.addOnce(() => { // When its done hopping
        const shakeTween1 = game.add.tween(claimDisplay.chestClosed) // It'll shake twice
          .to({ angle: [10, -10, 0] }, 250, Phaser.Easing.Sinusoidal.InOut, true, tweenTime);
        const shakeTween2 = game.add.tween(claimDisplay.chestClosed) // copy of shake one but I need a longer delay here
          .to({ angle: [10, -10, 0] }, 250, Phaser.Easing.Sinusoidal.InOut, false, tweenTime * 1.5);
        shakeTween1.chain(shakeTween2);
        shakeTween2.onComplete.addOnce(() => { // Before showing...
          game.time.events.add(tweenTime * 2, () => { // The suspense is killing me!
            game.add.tween(claimDisplay.shine) // The shine in the background
              .to({ alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true);
            game.add.tween(claimDisplay.chestOpen) // The opened chest fades in (looking like it opened)
              .to({ alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true);
            game.add.tween(claimDisplay.chestClosed) // The closed chest fades away
              .to({ alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true);
            game.time.events.add(tweenTime * 1.5, () => { // A delay...
              const prizeOut = game.add.tween(claimDisplay.prizeDisplay) // Before the prize drops out of the chest
                .to({ alpha: 1, y: prizeY }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
              prizeOut.onComplete.addOnce(() => { // When it drops out...
                G.saveState.friendshipChestDataManager.setClaimed(card.index); // Card is now claimed
                G.saveState.friendshipChestDataManager.setNumberOfLevelsToAppear(40);
                card.setToClaimed();
                claimDisplay.applyGifts(); // Gifts are applied

                game.time.events.add(2000, () => { // Wait some more...
                  outro.start(); // Outro
                }, this);
              }, this);
            }, this);
          });
        });
      });
    });

    // Outro is outside so it can be found easier
    outro = game.add.tween(claimDisplay.container) // Claim display container fades away
      .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    outro.onStart.addOnce(() => { // At the same time
      const cardTween = game.add.tween(card) // Card comes fades back in
        .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      cardTween.onComplete.addOnce(() => {
        card.animateClaimed(); // Animate the check mark
        claimDisplay.shine.update = () => {}; // Claim animation elements are cleaned up
        claimDisplay.container.destroy();
        if (claimDisplay.container.parent) {
          claimDisplay.container.parent.removeChild(claimDisplay.container);
        }
        claimDisplay = null; // Discarded

        game.time.events.add(3000, () => { // Add new card or transition out.
          this.addNewEndCardAndTransitionRight();
        }, this);
      });
    });

    // Tweens start
    cardOut.start();
    prizeIn.start();
  }

  /**
   * Creates a perpetual hand which will be like a new UI element. Sometimes.
   * If the tutorial is going on this isn't created.
   * Otherwise it is and will point at the button. Always!
   */
  readyPerpetualHand() {
    if (!this.perpetualHand) {
      this.perpetualHand = new Phaser.Group(game, this.displayContainer);
      const hand = G.makeImage(0, 20, 'tut_hand', 0, this.perpetualHand); // Taken from G.DailyIcon
      this.perpetualHand.tween = game.add.tween(hand).to({ x: 20, y: 50 }, 300, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
      this.perpetualHand.lockVisible = !this.closeBtn.visible;
      hand.update = () => {
        if (this.currentCard && this.currentCard.button && (this.currentCard.button.buttonState === BUTTONSTATE.ENABLED)) {
          const goodPos = this.displayContainer.toLocal(this.currentCard.button, this.currentCard.button.parent);
          goodPos.y -= this.currentCard.button.height / 2;
          if (!this.perpetualHand.position.equals(goodPos)) {
            this.perpetualHand.x = goodPos.x;
            this.perpetualHand.y = goodPos.y;
          }
          if (!this.perpetualHand.visible && !this.perpetualHand.lockVisible) {
            this.perpetualHand.visible = true;
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (this.perpetualHand.visible && !this.perpetualHand.lockVisible) {
            this.perpetualHand.visible = false;
          }
        }
      };
      if (this.currentCard.button) {
        const goodPos = this.displayContainer.toLocal(this.currentCard.button, this.currentCard.button.parent);
        this.perpetualHand.x = goodPos.x;
        this.perpetualHand.y = goodPos.y - this.currentCard.button.height / 2;
      }
    }
  }

  /**
   * Hides the perpetual pointy hand
   * @param {number} tweenTime
   */
  hidePerpetualHand(tweenTime = 200) {
    let perpetualHand;
    if (this.perpetualHand && this.perpetualHand.visible) {
      this.perpetualHand.lockVisible = true;
      perpetualHand = game.add.tween(this.perpetualHand)
        .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.In);
      perpetualHand.onComplete.addOnce(() => {
        this.perpetualHand.visible = false;
      });
    }
    if (perpetualHand) { perpetualHand.start(); }
  }

  /**
   * Reveals the perpetual pointy hand
   * @param {number} tweenTime
   */
  showPerpetualHand(tweenTime = 200) {
    let perpetualHand;
    if (this.perpetualHand && !this.perpetualHand.visible) {
      this.perpetualHand.lockVisible = false;
      this.perpetualHand.visible = true;
      perpetualHand = game.add.tween(this.perpetualHand)
        .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.Out);
    }
    if (perpetualHand) { perpetualHand.start(); }
  }

  /**
   * Animates the story chest. When the animation is done, the perpetual hand is init (if it didn't)
   * checks card, and progress tutorial
   * @param {FriendshipChest_Card} card
   */
  animateStoryCard(card) {
    const rtrTween = card.animateStoryChest();
    rtrTween.onComplete.addOnce(() => {
      this.readyPerpetualHand();
      this.checkCard();
      if (this.tutorialMode) { // Progress tutorial if there is one
        this.signals.onTutorialProgress.dispatch();
      }
    });
  }

  /**
   * Checks if the ftu assets of the friendship chest are loaded
   * @returns {boolean}
   */
  static async areFTUAssetsReady() {
    if (OMT.feature.getFriendshipChestAssetStatus().complete) { return true; }

    const assetLoader = OMT_AssetLoader.getInstance();
    const result = await assetLoader.waitOnSecondaryImages(['friendshipChest/ftu'], 9000000);
    if (result) {
      OMT.feature.setFriendshipChestAssetStatus(true);
    }
    return result;
  }

  /**
   * Checks if the friendship chest assets are completely loaded
   */
  static async areAssetsReady(onComplete) {
    if (OMT.feature.getFriendshipChestAssetStatus().complete) { return; }

    const assetLoader = OMT_AssetLoader.getInstance();
    const result = await assetLoader.waitOnSecondaryImages(['friendshipChest/ftu', 'friendshipChest/ui', 'friendshipChest/postcards'], 9000000);
    if (result) {
      OMT.feature.setFriendshipChestAssetStatus(true, true);
      if (onComplete) {
        onComplete();
      }
    }
  }
}
