/* eslint-disable no-await-in-loop */
import { ACTION_TYPES } from './Actions/Action';
import { EDITOR_SYMBOLS, TOKEN_TYPES, TILE_SIZE } from './BoardConstants';
import { InputController } from './Input/InputController';
import { ActionManager } from './Actions/ActionManager';
import { BoardShuffler } from './BoardShuffler';
import { BoardMatcher } from './BoardMatcher';
import { BoardBackground } from './BoardBackground';
import { BoardCollectCells } from './BoardCollectCells';
import { BoardFallManager } from './BoardFallManager';
import { BoardRefiller } from './BoardRefiller';
import { CandyLayer } from './Candy/CandyLayer';
import { TokenLayer_SpreadingJam } from './TokenLayers/TokenLayer_SpreadingJam';
import { TokenLayer_Ice } from './TokenLayers/TokenLayer_Ice';
import { TokenLayer_Concrete } from './TokenLayers/TokenLayer_Concrete';
import { TokenLayer_SpreadingDirt } from './TokenLayers/TokenLayer_SpreadingDirt';
import { TokenLayer_Dirt } from './TokenLayers/TokenLayer_Dirt';
import { CandySelectDisplay } from './Input/CandySelectDisplay';
import { DeconstructMsgDefault } from './deconstructMsg/DeconstructMsgDefault';
import { BoardWalls } from './BoardWalls';
import { CascadeSkipButton } from './Cascade/CascadeSkipButton';

/**
 * Main class for the game board
 */
export class Board extends Phaser.Group {
  /**
   * constructor
   * @param {LvlDataManager} lvlDataManager
   * @param {boolean} editorMode (optional) is the board in edit mode?
   */
  constructor(lvlDataManager, editorMode = false) {
    super(game);

    this._lvlDataManager = lvlDataManager;
    this._gameHooks = lvlDataManager.gameHooks;
    this._levelData = this._lvlDataManager.data;
    this._editorMode = editorMode;

    // TODO: clean these up
    this.MAX_NUMBER_OF_REGULAR_CANDY = this._levelData.nrOfTypes;
    this._collectCells = this._levelData.collectCells || null;
    this.tileSize = TILE_SIZE;

    // parameters used for board pixel offset.
    this.offsetX = 0; this.offsetY = 0;

    // parameters used for matching and falling candies.
    // TODO: clean up these should not be accessed directly externally
    this.checkMatchList = []; this.checkSpecialMatchList = [];
    this.checkAfterFall = []; this.fallCheckList = [];
    this._candiesAnimating = 0; this._candiesFalling = 0;

    this._initTileShades();

    this._initBoardDataGrids();
    this._initCandyAndTokenLayers();
    this._initCandySelectDisplay();

    this._initInputController();
    this._initActionManager();

    // instances used for managing / updating specific board actions.
    this._matcher = new BoardMatcher(this);
    this._refiller = new BoardRefiller(this);
    this._fallManager = new BoardFallManager(this);
    this._shuffler = new BoardShuffler(this);

    this._defineSignals();
    this._addGlobalListeners();

    // import / render levelData to the current board
    this.import(this._levelGridData);

    this._initBoardBackground();

    // set bottom row y value for each available column
    this._lastRowInColumns = this._getLastRowInColumn();

    // start of Events flags
    this._fortuneCookie = {
      collected: false, icon: null, isSeen: false, spawned: false,
    };
    this._wasFinalCascadeSkipped = false;
    // end of Event Flags

    this._setIntroAnimation();
  }

  /**
   * define signals
   */
  _defineSignals() {
    this.onBoardDeconstructed = new Phaser.Signal();
    this.onActionQueueEmpty = new Phaser.Signal();
  }

  /**
   * remove signals
   */
  _removeSignals() {
    this.onBoardDeconstructed.dispose();
    this.onActionQueueEmpty.dispose();
  }

  /**
   * set global event listeners
   */
  _addGlobalListeners() {
    this._signalBindings = [
      G.sb('onCandyFallStart').add(this._onCandyFallStart, this),
      G.sb('onCandyFallFinish').add(this._onCandyFallFinish, this),
      G.sb('onCandyAnimationStart').add(this._onCandyAnimationStart, this),
      G.sb('onCandyAnimationFinish').add(this._onCandyAnimationFinish, this),
      G.sb('onGoalAchieved').add(this._onGoalAchieved, this),
    ];
  }

  /**
   * remove global listeners set by _addGlobalListeners()
   */
  _removeGlobalListeners() {
    for (const signalBinding of this._signalBindings) signalBinding.detach();
    this._signalBindings.length = 0;
  }

  /** on candy animation started */
  _onCandyAnimationStart() { this._candiesAnimating++; }

  /** on candy animation finished */
  _onCandyAnimationFinish() { this._candiesAnimating--; }

  /** on candy fall started */
  _onCandyFallStart() { this._candiesFalling++; }

  /** on candy fall finished */
  _onCandyFallFinish(candy) {
    this._candiesFalling--;
    this.pushToFallCheckList(candy);
  }

  /** on level goal acheived event */
  _onGoalAchieved() {
    this._selectShade.visible = false;
    this._gameHooks.onGoalAchieved();

    // Add final cascade skip button
    const { cascadeSkipConfig } = this._gameHooks;

    if (cascadeSkipConfig.enabled) {
      this._cascadeSkipButton = new CascadeSkipButton(this, {
        text: cascadeSkipConfig.buttonText,
        style: cascadeSkipConfig.buttonStyle,
        orientation: this._gameHooks.orientation,
        hasLowResLandscape: this._gameHooks.hasLowResLandscape,
        gameScale: this._gameHooks.gameScale,
      });

      game.world.addChild(this._cascadeSkipButton);
    }
  }

  /**
   * initialize the input controller. This handles board user input.
   */
  _initInputController() {
    this._inputController = new InputController(this);
  }

  /**
   * initialize the board GridArray instances.
   */
  _initBoardDataGrids() {
    this._levelGridData = new G.GridArray(this._levelData.levelData);
    this._boardGridData = new G.GridArray(this._levelGridData.width, this._levelGridData.height);
  }

  /**
   * initialize the action manager. This managed board events / actions.
   */
  _initActionManager() {
    this._actionManager = new ActionManager(this);
    this._actionManager.onActionQueueEmpty.add(() => {
      this.onActionQueueEmpty.dispatch();
    }, this);
  }

  /**
   * create the board background display
   */
  _initBoardBackground() {
    this._boardBackground = new BoardBackground(this);
    this._background = game.make.image(0, 0, this._boardBackground.renderTexture);
    this._background.x = -this.tileSize; this._background.y = -this.tileSize;
    this.addChildAt(this._background, 0);
    this._boardBackground.redraw();
  }

  /**
   * create tile select / swap shade graphics.
   */
  _initTileShades() {
    this._tileShade = G.makeImage(0, 0, 'tile_shade', 0.5, this);
    this._tileShade.visible = false;
    this._selectShade = G.makeImage(0, 0, 'tile_shade', 0.5, this);
    this._selectShade.visible = false;
  }

  /**
   * initialize the candy selector UI used for swap boosters, and potentially other actions.
   */
  _initCandySelectDisplay() {
    this._candySelectDisplay = new CandySelectDisplay(this);
  }

  /**
   * initialize the board candy and token layers
   */
  _initCandyAndTokenLayers() {
    // layers created in displayList order
    this._dirtLayer = new TokenLayer_Dirt(this);
    this._spreadingDirtLayer = new TokenLayer_SpreadingDirt(this);
    this._candiesLayer = new CandyLayer(
      this,
      this._boardGridData,
      this._levelData,
      this._levelData.nrOfTypes,
      this._editorMode || false,
    );
    this._spreadingJamLayer = new TokenLayer_SpreadingJam(this);
    this._iceLayer = new TokenLayer_Ice(this);
    this._concreteLayer = new TokenLayer_Concrete(this);

    // initialize board collect cells
    this._boardCollectCells = new BoardCollectCells(this, this._levelData);
    this._boardWalls = new BoardWalls(this, this._levelData);

    // adjust candy fx / animation layer depths
    this._candiesLayer.movingCandyGroup.parent.bringToTop(this._candiesLayer.movingCandyGroup);
    this._candiesLayer.fxGroup.parent.bringToTop(this._candiesLayer.fxGroup);
    this._candiesLayer.boosterFxGroup.parent.bringToTop(this._candiesLayer.boosterFxGroup);
    this._candiesLayer.specialCandyGroup.parent.bringToTop(this._candiesLayer.specialCandyGroup);
    this._candiesLayer.fxTopGroup.parent.bringToTop(this._candiesLayer.fxTopGroup);

    // used to iterate through all layers including candies
    this._layers = [
      this._dirtLayer,
      this._spreadingDirtLayer,
      this._candiesLayer,
      this._spreadingJamLayer,
      this._concreteLayer,
      this._iceLayer,
    ];

    // used to iterate through all layers excluding candies
    this._layersNoCandies = [
      this._dirtLayer,
      this._spreadingDirtLayer,
      this._spreadingJamLayer,
      this._concreteLayer,
      this._iceLayer,
    ];
  }

  /**
   * get list of tokens in a cell
   * @param {number} cellX
   * @param {number} cellY
   * @returns {Array}
   */
  getAllTokensInCell(cellX, cellY) {
    const tokens = [];
    for (let i = this._layers.length - 1; i >= 0; i--) {
      const token = this._layers[i].getToken(cellX, cellY);
      if (token) tokens.push(token);
    }
    return tokens;
  }

  /**
   * get the bottom most cellY in the specified column
   * @param {number} cellX
   */
  _getLastCellInColumn(cellX) {
    for (let cellY = this._boardGridData.height - 1; cellY >= 0; cellY--) {
      if (this.isCellOnBoard(cellX, cellY)) return cellY;
    }
    return 0;
  }

  /**
   * get a list of the last available row y in each column
   * @returns {Array}
   */
  _getLastRowInColumn() {
    const result = [];
    for (let cellX = 0; cellX < this._boardGridData.width; cellX++) {
      result.push(this._getLastCellInColumn(cellX));
    }
    return result;
  }

  /**
   * checks if there are goal candies in the last row in each column that can be removed
   * @returns {boolean} returns true if any candies were removed
   */
  checkGoalCandy() {
    let removed = false;
    for (let cellX = 0; cellX < this._boardGridData.width; cellX++) {
      for (let cellY = this._boardGridData.height - 1; cellY >= 0; cellY--) {
        const candy = this.getCandy(cellX, cellY);
        if (!candy) continue;
        if (!candy.isGoalCollectType()) continue;

        const viableToRemove = this._isCandyOnCollectCell(candy) && !this.isMoveBlocked(cellX, cellY);
        if (viableToRemove) {
          this._candiesLayer.removeCandy(cellX, cellY);
          if (candy.isBurntCandy()) {
            this._gameHooks.playSound('stone_impact_2');
          } else {
            this._gameHooks.playSound('xylophone_positive6');
          }
          removed = true;
        }
      }
    }
    // if any candies were removed create a new fall action
    if (removed) this._actionManager.newAction(ACTION_TYPES.PROCESS_FALL);
    return removed;
  }

  /**
   * checks if a candy is on a collect cell
   * @param {Candy} candy
   * @returns {boolean}
   */
  _isCandyOnCollectCell(candy) {
    if (this._collectCells == null) { // no collect cells allowed, allow removal in bottom row.
      return this._lastRowInColumns[candy.cellX] === candy.cellY;
    }
    // The candy is directly on a collect cell
    const directCollectCheck = this._boardCollectCells.getCollectCell(candy.cellX, candy.cellY) != null;
    // The candy is on top of a blank cell that is a collect cell
    const onTopOfEmptyCollectCheck = !this.isCellOnBoard(candy.cellX, candy.cellY + 1) && this._boardCollectCells.getCollectCell(candy.cellX, candy.cellY + 1) != null;
    return directCollectCheck || onTopOfEmptyCollectCheck;
  }

  /**
   * creates an action to make a move / swap 2 candies
   * @param {Candy} candy1 first candy being swapped
   * @param {Candy} candy2 second candy being swapped
   * @param {boolean} force force the swap action
   */
  makeMove(candy1, candy2, force) {
    if (this.inputController.canMoveHappen([candy1.cellX, candy1.cellY], [candy2.cellX, candy2.cellY])) { // Theres no wall
      this._actionManager.newAction(ACTION_TYPES.MOVE, candy1, candy2, force);
    } else { // There is a wall and reject move
      this._actionManager.newAction(ACTION_TYPES.MOVE_REJECT, candy1, candy2, force);
    }
  }

  /**
   * cell hit action. targets top most layer.
   * @param {number} cellX
   * @param {number} cellY
   */
  hitCell(cellX, cellY) {
    for (let i = this._layers.length - 1; i >= 0; i--) {
      if (!this._layers[i].onHit(cellX, cellY)) {
        return; // stopped propagation
      }
    }
  }

  /**
   * use booster on cell. targets top most layer.
   * @param {number} cellX
   * @param {number} cellY
   */
  useBoosterOnCell(cellX, cellY) {
    for (let i = this._layers.length - 1; i >= 0; i--) {
      if (!this._layers[i].onBooster(cellX, cellY)) {
        return; // stopped propagation
      }
    }
  }

  /**
   * check if a candy / cell is moveable
   * @param {number} x
   * @param {number} y
   * @returns {boolean}
   */
  isMoveable(x, y) {
    if (!this.isCellOnBoard(x, y) || this.isMoveBlocked(x, y)) return false;
    const candy = this.getCandy(x, y);
    if (!candy) return false;
    return true;
  }

  /**
   * loops through layers in a cell looking for blockers
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isMoveBlocked(cellX, cellY) {
    for (let i = this._layers.length - 1; i >= 0; i--) {
      if (this._layers[i].isMoveBlocked(cellX, cellY)) return true;
    }
    return false;
  }

  /**
   * loops through layers to check if booster change is blocked.
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isBoosterChangeBlocked(cellX, cellY) {
    for (let i = this._layers.length - 1; i >= 0; i--) {
      if (this._layers[i].isBoosterChangeBlocked(cellX, cellY)) return true;
    }
    return false;
  }

  /**
   * loops through layers to check if matching is blocked.
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isMatchBlocked(cellX, cellY) {
    for (let i = this._layers.length - 1; i >= 0; i--) {
      if (this._layers[i].isMatchBlocked(cellX, cellY)) return true;
    }
    return false;
  }

  /**
   * check if cell is matchable.
   * @param {number} x
   * @param {number} y
   * @param {Candy} optCandy (optional) candy type
   * @returns {boolean}
   */
  isCellMatchable(x, y, optCandy) {
    if (!this.isCellOnBoard(x, y) || this._iceLayer.isToken(x, y) || this.isMatchBlocked(x, y)) return false;
    if (optCandy) return this.getCandy(x, y).isMatchableWith(optCandy);
    return true;
  }

  /**
   * for handling changes inside blockers.
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  matchCellExceptCandy(cellX, cellY) {
    for (let i = this._layersNoCandies.length - 1; i >= 0; i--) {
      if (!this._layersNoCandies[i].onMatch(cellX, cellY)) {
        return; // stopped propagation
      }
    }
  }

  /**
   * match 2 cells with a delay
   * @param {number} cellX from cell x
   * @param {number} cellY from cell y
   * @param {number} delay delay time ms
   * @param {number} moveCellX to cell x
   * @param {number} moveCellY to cell y
   */
  matchCell(cellX, cellY, delay, moveCellX, moveCellY) {
    for (let i = this._layers.length - 1; i >= 0; i--) {
      if (!this._layers[i].onMatch(cellX, cellY, delay, moveCellX, moveCellY)) {
        return; // stopped propagation
      }
    }
  }

  /**
   * check if a cell is within the board area
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isCellInBoardArea(cellX, cellY) {
    return cellX < this._boardGridData.width && cellX >= 0 && cellY >= 0 && cellY < this._boardGridData.height;
  }

  /**
   * check if a cell is within the board and is not disabled
   * @param {number} x
   * @param {number} y
   * @returns {boolean}
   */
  isCellOnBoard(x, y) {
    if (x < 0 || x >= this._boardGridData.width || y < 0 || y >= this._boardGridData.height) return false;
    return this._boardGridData.get(x, y) !== EDITOR_SYMBOLS.BLANK;
  }

  /**
   * add a candy to the list of potential falling items to check
   * @param {Candy} candy
   */
  pushToFallCheckList(candy) {
    if (this.fallCheckList.indexOf(candy) === -1) this.fallCheckList.push(candy);
  }

  /**
   * check the lists of fallen candies for matches and trigger an action if required.
   */
  processFallCheckList() {
    for (const candy of this.fallCheckList) {
      if (this.matcher.quickMatchCheck(candy)) this.checkMatchList.push(candy);
    }
    for (const candy of this.checkAfterFall) {
      this.checkMatchList.push(candy);
    }
    this.fallCheckList.length = 0; this.checkAfterFall.length = 0;

    if (this.checkMatchList.length > 0) { // process any matches that resulted from the fall
      this._actionManager.newAction(ACTION_TYPES.PROCESS_MATCH);
    }
  }

  /**
   * check if a cell is within the board and is not disabled
   * @param {number} x
   * @param {number} y
   * @returns {Candy}
   */
  getCandy(x, y) {
    return this._candiesLayer.getCandy(x, y);
  }

  /**
   * check if candy of a specific
   * @param {string} specificType
   * @returns {boolean}
   */
  isAnyInBoard(specificType) {
    let result = false;
    switch (specificType) {
      case TOKEN_TYPES.DIRT_S: result = this._spreadingDirtLayer.getAll().length > 0; break;
      case TOKEN_TYPES.ICE: result = this._iceLayer.getAll().length > 0; break;
      case TOKEN_TYPES.CONCRETE: result = this._concreteLayer.getAll().length > 0; break;
      case TOKEN_TYPES.DIRT: result = this._dirtLayer.getAll().length > 0; break;
      case TOKEN_TYPES.GOAL_CANDY:
        for (const candy of this._candiesLayer.getAllCandies()) {
          if (candy.isGoalCandy()) { result = true; break; }
        }
        break;
      case TOKEN_TYPES.JAM: return this._spreadingJamLayer.getAll().length > 0;
      case TOKEN_TYPES.LAYER_CAKE:
        result = this._candiesLayer.countEditorSymbolOccurrence(EDITOR_SYMBOLS.LAYER_CAKE) > 0;
        break;
      case TOKEN_TYPES.CHAIN:
        result = this._candiesLayer.countEditorSymbolOccurrence(EDITOR_SYMBOLS.CHAIN) > 0;
        break;
      case TOKEN_TYPES.BURNT:
        for (const candy of this._candiesLayer.getAllCandies()) {
          if (candy.isBurntCandy()) { result = true; break; }
        }
        break;
      case TOKEN_TYPES.FORTUNE:
        result = this._candiesLayer.countEditorSymbolOccurrence(EDITOR_SYMBOLS.FORTUNE) > 0;
        break;
      case EDITOR_SYMBOLS.WALL:
        result = this._boardWalls.wallCount > 0;
        break;
      default: break;
    }
    return result;
  }

  /**
   * check if candy of a specific type is present in the game, including drops
   * @param {string} specificType
   * @returns {boolean}
   */
  isAnyInLevel(specificType) {
    const dropAmount = this._refiller._drops[specificType];
    return dropAmount || this.isAnyInBoard(specificType);
  }

  /**
   * Returns how many treasure chests are on the board
   * @returns {number}
   */
  getNumberOfTreasureChestsOnBoard() {
    return this._candiesLayer.getAllTokensWithTag(TOKEN_TYPES.CHEST_TH);
  }

  /**
   * converts x axis screen coordinates to grid cell x
   * @param {number} screenX
   * @returns {number}
   */
  pxInToCellX(screenX) {
    return Math.floor(screenX / this.tileSize);
  }

  /**
   * converts y axis screen coordinates to grid cell y
   * @param {number} screenY
   * @returns {number}
   */
  pxInToCellY(screenY) {
    return Math.floor(screenY / this.tileSize);
  }

  /**
   * converts cell x to screen x value
   * @param {number} cellX
   * @returns {number}
   */
  cellXToPxIn(cellX) {
    return cellX * this.tileSize + (this.tileSize * 0.5);
  }

  /**
   * converts cell y to screen y value
   * @param {number} cellY
   * @returns {number}
   */
  cellYToPxIn(cellY) {
    return cellY * this.tileSize + (this.tileSize * 0.5);
  }

  /**
   * converts cell x,y to pixel coordinates
   * @param {Object} cell {x, y}
   * @returns {Array} [x, y]
   */
  cellToPxOut(cell) {
    const tileSize = this.tileSize * this.scale.x;
    return [
      this.x + this.offsetX + (tileSize * (cell[0])) + (tileSize * 0.5),
      this.y + this.offsetY + (tileSize * (cell[1])) + (tileSize * 0.5),
    ];
  }

  /**
   * swap 2 candies on the board
   * @param {Candy} c1
   * @param {Candy} c2
   */
  swapCandies(c1, c2) {
    this._candiesLayer.swapCandies(c1, c2);
  }

  /**
   * swap 2 candies on the board (alternate function, only seems to be for the shuffler)
   * @param {Candy} c1
   * @param {Candy} c2
   */
  swapCandiesWithPosition(c1, c2) {
    this._candiesLayer.grid.set(c1.cellX, c1.cellY, c2);
    this._candiesLayer.grid.set(c2.cellX, c2.cellY, c1);

    const tmpCellX = c1.cellX; const tmpCellY = c1.cellY;
    const tmpX = c1.x; const tmpY = c1.y;

    c1.x = c2.x; c1.y = c2.y;
    c1.cellX = c2.cellX; c1.cellY = c2.cellY;

    c2.x = tmpX; c2.y = tmpY;
    c2.cellX = tmpCellX; c2.cellY = tmpCellY;
  }

  /**
   * remove a candy from the board
   * @param  {...any} args arguments that will be applied to removeCandy
   */
  removeCandy(...args) {
    this._candiesLayer.removeCandy.apply(this._candiesLayer, ...args);
  }

  /**
   * create a new candy to fall in from offscreen
   * @param {number} cellX
   * @param {number} cellY
   * @param {string} type
   * @param {number} fromCellY
   */
  newFallingCandy(cellX, cellY, type, fromCellY) {
    const newCandy = this._candiesLayer.newCandy(cellX, cellY, type);
    newCandy.y = this.cellYToPxIn(fromCellY);
    newCandy.fallTo(cellX, cellY);
    newCandy.alpha = 0;
  }

  /**
   * clear the board
   */
  clearBoard() {
    for (const layer of this._layers) if (layer.clearLayer) layer.clearLayer();
    this._boardCollectCells.removeAllCollectCells();
  }

  /**
   * main update method
   */
  update() {
    this._actionManager.update();
  }

  /**
   * set the intro animation sequence
   */
  _setIntroAnimation() {
    this._boardCollectCells.pivot.y = 2;
    game.add.tween(this._boardCollectCells.pivot).to({ y: -4 }, 600, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  }

  /**
   * Starts cascade skip sequence after player clicks on Skip
   */
  startCascadeSkipSequence() {
    this._wasFinalCascadeSkipped = true;
    this._gameHooks.startCascadeSkipSequence();
    this._gameHooks.calculateFinalScoreAfterCascadeSkip();

    // Fade out Board layers
    const candyLayers = this._candiesLayer.getAllLayers();
    for (const layer of candyLayers) {
      game.add.tween(layer).to({ alpha: 0 }, 750, Phaser.Easing.Sinusoidal.In, true);
    }

    this.deconstruct();
  }

  /**
   * Hides the board in given time
   * @param {number} ms The duration of hiding animation
   */
  async hide(ms = 0) {
    return new Promise((resolve) => {
      // Lock game input so mouseover won't trigger
      game.input.enabled = false;
      this._inputController.locked = true;

      this._tileShade.alpha = 0;
      this._selectShade.visible = false;
      this._candiesLayer.hideAllCells(ms);
      for (const boardLayer of [...this._layersNoCandies, this._boardCollectCells, this._boardWalls]) {
        if (ms === 0) {
          boardLayer.alpha = 0;
        } else {
          game.add.tween(boardLayer).to({ alpha: 0 }, ms, Phaser.Easing.Sinusoidal.Out, true);
        }
      }
      if (ms === 0) {
        this._background.alpha = 0;
        resolve();
      } else {
        game.add.tween(this._background).to({ alpha: 0 }, ms, Phaser.Easing.Sinusoidal.InOut, true)
          .onComplete.add(() => {
            resolve();
          });
      }
    });
  }

  /**
   * Shows the board in given time with spinning animation
   * @param {number} ms The duration of showing animation
   */
  async showWithAnimation() {
    await this._animateInBackground();
    await this._yieldForMs(200);
    this._candiesLayer.showAllCells(100);
    await this._yieldForMs(100);
    await this._animateInBoardLayers();

    // Unlock game input
    game.input.enabled = true;
    this._inputController.locked = false;
    this._tileShade.alpha = 1;
  }

  /**
   * animate in the board background
   * @returns {Promise}
   */
  async _animateInBackground() {
    return new Promise((resolve) => {
      this._background.x += this._background.width * 0.5;
      this._background.y += this._background.height * 0.5;
      this._background.anchor.setTo(0.5);
      this._background.scale.setTo(0, 0);
      this._background.angle = -70;
      this._background.alpha = 1;
      game.add.tween(this._background.scale).to({ x: 1, y: 1 }, 250, Phaser.Easing.Sinusoidal.InOut, true);
      game.add.tween(this._background).to({ angle: 0 }, 250, Phaser.Easing.Sinusoidal.InOut, true)
        .onComplete.add(() => {
          this._background.x -= this._background.width * 0.5;
          this._background.y -= this._background.height * 0.5;
          this._background.anchor.setTo(0);
          resolve();
        });
    });
  }

  /**
   * animate in individual board layers
   * @returns {Promise}
   */
  async _animateInBoardLayers() {
    return new Promise((resolve) => {
      const layers = [...this._layersNoCandies, this._boardCollectCells];
      for (let i = 0; i < layers.length; i++) {
        const boardLayer = layers[i];
        game.add.tween(boardLayer).to({ alpha: 1 }, 100, Phaser.Easing.Sinusoidal.In, true)
          .onComplete.add(() => {
            if (i === layers.length - 1) {
              resolve();
            }
          });
      }
    });
  }

  /**
   * board deconstruction / animate out
   * @param {Array.<DeconstructMsg>} deconstructMsgs sequence of messages to show along with the well done message
   */
  deconstruct(deconstructMsgs = []) {
    if (this._cascadeSkipButton) {
      game.world.remove(this._cascadeSkipButton);
      this._cascadeSkipButton.destroy();
      this._cascadeSkipButton = null;
    }

    // lock game input
    game.input.enabled = true; // workaround to avoid issue in OMT-6052
    this._inputController.locked = true;
    this._inputController.stopTweens();
    G.sb('disableGameBottomBar').dispatch();

    this._gameHooks.onBoardDesconstruct(); // external hooks must be set here

    this._deconstructing = true;
    if (deconstructMsgs.length === 0) deconstructMsgs.push(new DeconstructMsgDefault(this._lvlDataManager));

    this._background.x += this._background.width * 0.5; this._background.y += this._background.height * 0.5;
    this._background.anchor.setTo(0.5);
    this._selectShade.visible = false;

    game.time.events.add(200, this._candiesLayer.deconstruct, this._candiesLayer);
    for (const boardLayer of [...this._layersNoCandies, this._boardWalls]) {
      game.add.tween(boardLayer).to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.In, true);
    }

    game.time.events.add(900, () => {
      game.add.tween(this._background.scale).to({ x: 0, y: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true);
      game.add.tween(this._background).to({ angle: 70 }, 500, Phaser.Easing.Sinusoidal.InOut, true)
        .onComplete.add(() => {
          // Hide every layer, candy, collect cells, etc
          this._layers.concat(this._candiesLayer.getAllLayers(), this._boardCollectCells).forEach((layer) => {
            if (layer.parent) layer.parent.removeChild(layer);
          });
          game.input.enabled = true; // Bring back input on the game
        });
    }, this);

    // show the deconstruct message now if one was passed in
    game.time.events.add(900, async () => {
      if (deconstructMsgs.length > 0) {
        let deconstructCounter = 0;
        const showAllDeconstructs = async () => {
          let targetDecon = deconstructMsgs[deconstructCounter];
          while (targetDecon) {
            const deconPromise = await this._showDeconstructMsg(targetDecon);
            if (!deconPromise) {
              if (deconPromise > 0) await this._yieldForMs(deconPromise);
              deconstructCounter++;
              targetDecon = deconstructMsgs[deconstructCounter];
            } else {
              targetDecon = null;
            }
          }
        };
        await showAllDeconstructs();
        this._showEndScreen();
      } else {
        game.time.events.add(1200, this._showEndScreen.bind(this));
      }
    }, this);
  }

  /**
   * deconstruction complete dispatch events for final screens.
   */
  _showEndScreen() {
    this.onBoardDeconstructed.dispatch();
  }

  /**
   * add the deconstructMsg graphics and set their transitions
   * @param {Phaser.Group} deconstructMsg graphic to display along with the well done message
   * @returns {Promise}
   */
  async _showDeconstructMsg(deconstructMsg) {
    if (!this._shouldAnimationContinue()) return null;
    return new Promise((resolve) => {
      this._deconstructMsg = deconstructMsg;
      deconstructMsg.x = this._background.x; deconstructMsg.y = this._background.y;
      deconstructMsg.signals.onAnimateOutFinish.addOnce(() => {
        this._deconstructMsg = null;
        const { afterDelay } = deconstructMsg;
        deconstructMsg.destroy();
        if (this._shouldAnimationContinue()) {
          resolve(afterDelay); return;
        }
        resolve(null);
      });
      deconstructMsg.animateIn(this);
      this.add(deconstructMsg);
    });
  }

  /**
   * for pausing animation during the deconstruct sequence.
   * @returns {boolean}
   */
  _shouldAnimationContinue() {
    return true; // add conditions for pausing animation here.
  }

  /**
   * used for halting animation for a specific time duration.
   * @param {number} ms milliseconds to pause
   * @returns {Promise}
   */
  async _yieldForMs(ms) {
    if (!this._shouldAnimationContinue()) return null;
    return new Promise((resolve) => {
      game.time.events.add(ms, () => {
        if (this._shouldAnimationContinue()) resolve();
      });
    });
  }

  /**
   * export the current board state to a stringified JSON
   * @returns {string}
   */
  export() {
    const result = new G.GridArray(this._boardGridData.width, this._boardGridData.height);
    result.loop((elem, x, y, data) => {
      const cell = [];
      if (this._boardGridData.get(x, y) === EDITOR_SYMBOLS.BLANK) cell.push(EDITOR_SYMBOLS.BLANK);
      this._layers.forEach((layer) => {
        const exp = layer.export(x, y);
        if (exp) cell.push(exp);
      });
      data[x][y] = cell;
    }, this);
    return JSON.stringify(result.data);
  }

  /**
   * import / apply level data Object
   * @param {GridArray} levelGridData
   */
  import(levelGridData) {
    levelGridData.loop((elem, x, y) => {
      for (let i = 0; i < elem.length; i++) {
        elem[i] = elem[i].toString();
        // old version ?? fix formatting.
        if (elem[i][0] === EDITOR_SYMBOLS.CHAIN) elem[i] = `${elem[i][1]}:${elem[i][0]}`;
        // import cell / layer data
        if (elem[i] === EDITOR_SYMBOLS.BLANK) this._boardGridData.set(x, y, EDITOR_SYMBOLS.BLANK);
        else {
          let imported = false;
          this._layersNoCandies.forEach((layer) => {
            const layerWasImported = layer.import(x, y, elem[i]);
            if (!imported && layerWasImported) imported = true;
          });
          if (!imported) this._candiesLayer.import(x, y, elem[i]);
        }
      }
    }, this);

    // replace random candies and shuffle if required
    if (!this._editorMode) {
      this._candiesLayer.replaceRandomCandies();
      if (this._matcher.checkPossibleMoves().length === 0) {
        this._shuffler.shuffleCandies(true);
      }
    }
  }

  /**
   * destruction method
   */
  destroy() {
    // console.log('*** Board destroyed');
    super.destroy();
    this._removeGlobalListeners();
    this._removeSignals();

    this._actionManager.destroy();
    this._matcher.destroy();
    this._refiller.destroy();
    this._fallManager.destroy();
    this._shuffler.destroy();

    this._levelGridData.destroy();
    this._boardGridData.destroy();
  }

  /** GETTER METHODS ********************************* */

  /** @returns {LvlDataManager} */
  get lvlDataManager() { return this._lvlDataManager; }

  /** @returns {BoardGameHooks} */
  get gameHooks() { return this._gameHooks; }

  /** @returns {Refiller} */
  get refiller() { return this._refiller; }

  /** @returns {BoardFallManager} */
  get fallManager() { return this._fallManager; }

  /** @returns {BoardShuffler} */
  get shuffler() { return this._shuffler; }

  /** @returns {ActionManager} */
  get actionManager() { return this._actionManager; }

  /** @returns {BoardMatcher} */
  get matcher() { return this._matcher; }

  /** @returns {InputController} */
  get inputController() { return this._inputController; }

  /** @returns {CandySelectDisplay} get a reference the candy selector display */
  get candySelectDisplay() { return this._candySelectDisplay; }

  /** @returns {Phaser.Image} get a reference to the tile shade image */
  get tileShade() { return this._tileShade; }

  /** @returns {Phaser.Image} get a reference to the select shade image */
  get selectShade() { return this._selectShade; }

  /** @returns {GridArray} */
  get levelGridData() { return this._levelGridData; }

  /** @returns {GridArray} */
  get boardGridData() { return this._boardGridData; }

  /** @returns {CandyLayer} */
  get candiesLayer() { return this._candiesLayer; }

  /** @returns {TokenLayer_Dirt} */
  get dirtLayer() { return this._dirtLayer; }

  /** @returns {TokenLayer_SpreadingDirt} */
  get spreadingDirtLayer() { return this._spreadingDirtLayer; }

  /** @returns {TokenLayer_SpreadingJam} */
  get spreadingJamLayer() { return this._spreadingJamLayer; }

  /** @returns {TokenLayer_Concrete} */
  get concreteLayer() { return this._concreteLayer; }

  /** @returns {TokenLayer_Ice} */
  get iceLayer() { return this._iceLayer; }

  /** @returns {BoardCollectCells} */
  get boardCollectCells() { return this._boardCollectCells; }

  /** @returns {BoardWalls} */
  get walls() { return this._boardWalls; }

  /** @returns {boolean} true if candies are currently falling */
  get candiesAreFalling() { return this._candiesFalling > 0; }

  /** @returns {boolean} true if candies are currently animating */
  get candiesAreAnimating() { return this._candiesAnimating > 0; }

  /** @returns {Object} fortune cookie event / token data */
  get fortuneCookie() { return this._fortuneCookie; }

  /** @returns {number} */
  get innerWidth() {
    const { tileSize, _boardGridData } = this;
    return tileSize * _boardGridData.width;
  }

  /** @returns {number} */
  get innerHeight() {
    const { tileSize, _boardGridData } = this;
    return tileSize * _boardGridData.height;
  }

  /** @returns {boolean} has the final cascade sequence been skipped by the player? */
  get wasFinalCascadeSkipped() { return this._wasFinalCascadeSkipped; }
}
