/* eslint-disable operator-linebreak */

import { LevelType } from '@omt-game-board/Managers/GameEnums';

// Prefix for assets added in super hard update
const superHardTexturePrefix = 'superhard-';
// There will be an offset in non-normal mode to leave space for the villains
const isNotNormalLevelOffset = 42;

const supportedLevelTypes = [
  LevelType.NORMAL,
  LevelType.CHALLENGE,
];

export default class OMT_VILLAINS {
  /**
   * Returns the current level's difficulty OR current selected node's difficulty
   * @param {object} levelData
   * @returns {{isHardLevel:boolean, isSuperHardLevel:boolean, isNotNormalLevel:boolean}} result
   */
  static getDifficulty(levelData) {
    if (!this.levelType) {
      this.resetLevelType();
    }

    let data;
    const { lvlData } = G;

    if (levelData) {
      data = levelData;
    } else if (lvlData) {
      data = lvlData;
    }

    const result = this.getBaseDifficultyObject();

    // We don't want hard levels on levels that are not normal
    if (!this._isLevelTypeSupported(this.levelType)) {
      return result;
    }

    if (data) {
      let { showAsHard, showAsSuperHard } = data;
      const tutorialStatus = G.saveState.villainsDataManager.getTutorialStatus('hard', 'post_win_saga_map');
      if ((!OMT.feature.isVillainsEnabled() || !tutorialStatus) && showAsSuperHard) {
        showAsHard = true;
        showAsSuperHard = false;
      }
      const isNotNormalLevel = showAsHard || showAsSuperHard;

      result.isHardLevel = showAsHard;
      result.isSuperHardLevel = showAsSuperHard;
      result.isNotNormalLevel = isNotNormalLevel;
    }

    return result;
  }

  /**
   * Returns True/False if the current level type is supported
   * @param {LevelType} levelType
   * @returns {Boolean}
   */
  static _isLevelTypeSupported(levelType) {
    return supportedLevelTypes.indexOf(levelType) > -1;
  }

  /**
   * Returns the base difficulty object structure for the villains class
   */
  static getBaseDifficultyObject() {
    return {
      isHardLevel: false,
      isSuperHardLevel: false,
      isNotNormalLevel: false,
    };
  }

  /**
   * Filters game top bar level number ui panel variant based on difficulty
   * @param {Array} exportNames
   * @returns {boolean}
   */
  static filterVariant(exportNames) {
    const { isHardLevel, isSuperHardLevel } = this.getDifficulty();

    if (isHardLevel && exportNames.includes('variant_normal')) {
      return false;
    }
    if (isSuperHardLevel && exportNames.includes('variant_normal')) {
      return false;
    }
    if (!isHardLevel && exportNames.includes('variant_hard')) {
      return false;
    }
    if (isSuperHardLevel && exportNames.includes('variant_super_hard')) {
      return false;
    }
    return true;
  }

  /**
   * Prefixes given string with the super hard update texture prefix
   * @param {string} name
   * @returns {string} prefixedName
   */
  static getPrefixedName(name) {
    return `${superHardTexturePrefix}${name}`;
  }

  /**
   * Returns the offset for hard and super hard levels window offset
   * @returns {number} offset
   */
  static getNotNormalLevelOffset() {
    return isNotNormalLevelOffset;
  }

  /**
   * Get a state from inGameStates enum
   * @param {string} state
   */
  static getInGameState(state) {
    // In-game states
    const inGameStates = {
      WIN_START: OMT_VILLAINS.getPrefixedName('in_game_win_start'),
      WIN_END: OMT_VILLAINS.getPrefixedName('in_game_win_end'),
    };

    return inGameStates[state];
  }

  /**
   * Returns the level tracker key for session data
   */
  static getLevelTrackerKey() {
    return this.getPrefixedName('levelPlayTracker');
  }

  /**
   * Returns the valid DDNA parameter enum for mLevelDifficultyType parameter based on level data
   * @param {object} levelData
   */
  static getMLevelDifficultyType(levelData) {
    const mLevelDifficultyTypeEnum = ['NORMAL', 'HARD', 'SUPER_HARD'];
    const { isHardLevel, isSuperHardLevel, isNotNormalLevel } = this.getDifficulty(levelData);
    if (isNotNormalLevel) {
      if (isHardLevel) {
        return mLevelDifficultyTypeEnum[1];
      }

      if (isSuperHardLevel) {
        return mLevelDifficultyTypeEnum[2];
      }
    }
    return mLevelDifficultyTypeEnum[0];
  }

  /**
   * Set the current level type, such as NORMAL or COLLECT_EVENT
   * @param {string} type
   */
  static setLevelType(type) {
    if (!type) {
      this.resetLevelType();
    } else {
      this.levelType = type;
    }
  }

  /**
   * Resets the level type
   */
  static resetLevelType() {
    this.levelType = LevelType.NORMAL;
  }
}
