import { Window } from '../../../00_IMMEDIATE/Window';
import FriendshipChest_Tutorial_Manager from './FriendshipChestTutorial/FriendshipChest_Tut_Manager';
import Friendship_Chestv2 from './Friendship_Chestv2';

/**
 * Friendship chest window container that pops up when you click on the icon
 * Actually uses another class that manages everything, and isn't G.Window
 * @author Sandra Koo
 */
class Window_friendshipChest extends Window {
  /**
   * constructor
   * @param {Object} parent
   */
  constructor(parent) {
    super(parent);

    let thereIsTutorial = false;
    let requestFinished = false;

    if (OMT.feature.getFriendshipChestStory()) {
      const tutorialState = G.saveState.friendshipChestDataManager.shouldTutorialCommence();
      thereIsTutorial = tutorialState.thereIsTutorial;
      requestFinished = tutorialState.requestFinished;

      // Failsafe for users with older save states without the requestFinished key
      if (requestFinished === undefined) {
        requestFinished = !tutorialState.thereIsTutorial;
      }
    }
    this.friendshipChestContainer = new Phaser.Group(game, this);
    this.friendshipChestManager = new Friendship_Chestv2({ // Avoiding G.Window (though its not so bad anymore!?)
      window: this,
      container: this.friendshipChestContainer,
      tutorialMode: thereIsTutorial,
    });
    this.friendshipChestManager.signals.onCloseClicked.addOnce(() => { this.closeWindow(); });
    if (thereIsTutorial) { // If theres a tutorial!?
      this.friendshipChestManager.signals.onReady.addOnce(() => this.readyTutorial(!requestFinished)); // When the friendship chest is ready, ready the tutorial
      this.friendshipChestManager.signals.onTutorialProgress.add(this.onTutorialButtonClick.bind(this)); // Hook the listener
    }

    if (game.state.current === 'World') {
      this.y = -G.WindowMgr.Constants.WorldVerticalOffset;
    }
  }

  closeWindow() {
    if (G.saveState.friendshipChestDataManager.getNumberOfLevelsToAppear() <= 0) {
      G.saveState.friendshipChestDataManager.setNumberOfLevelsToAppear(20);
    }
    G.saveState.save();
    G.sb('FriendshipChestCheck').dispatch();
    super.closeWindow();
  }

  /**
   * destroy override
   */
  destroy() {
    if (this.friendshipChestManager) {
      this.friendshipChestManager.destroy();
      this.friendshipChestManager = null;
    }
    if (this.tutorial) {
      this.tutorial.destroy();
      this.tutorial = null;
    }
    super.destroy();
  }

  /**
   * Creates the tutorial manager
   * Makes the cards appear smaller to allow for Gingy to talk
   * @param {boolean} showGingy
   */
  readyTutorial(showGingy) {
    this.friendshipChestContainer.scale.set(0.80);
    this.friendshipChestContainer.y -= 35;
    this.tutorial = new FriendshipChest_Tutorial_Manager({ // steps will be managed here
      window: this,
      module: this.friendshipChestManager,
      showGingy,
    });
  }

  /**
   * Progresses tutorial
   * @param {any} state
   */
  onTutorialButtonClick(state) {
    if (!G.saveState.friendshipChestDataManager.getTutorialEnded()) {
      this.tutorial.progressTutorial(state);
    }
  }

  /**
   * @returns {boolean} is the window fullscreen? (for low res landscape purposes)
   */
  get isFullscreen() {
    return true;
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.friendshipChest = Window_friendshipChest;
