import UI_AnimationGroup from '@omt-components/UI/Animation/UI_AnimationGroup';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';
import TutorialGrid from '../PopUpTutorials/BoardAnim/G.TutorialGrid';
import UI_EventBlocker from '@omt-components/UI/EventBlocker/UI_EventBlocker';
import TreasureHuntManager from '../../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';

const tileSize = 72;

export default class TreasureHuntInfoWindow extends UI_AnimationGroup {
  /**
   * Shows a tutorial of how the treasure hunt even works. Kinda
   * @param {Game} game
   * @param {Phaser.DisplayObject} parent
   */
  constructor(game, parent) {
    super(game, parent);

    this._delayTimers = []; // Array for delays
    this._tweenTime = 0; // Tween time, for tweens
    this._tweenDelay = 0; // Tween delays
    this._totalPages = []; // Total pages drawn
    this._drawEventBlocker();
    this._container = new Phaser.Group(game, this); // Main display container
    this._drawBg();
    this._drawPageOne();
    this._drawPageTwo(); // Badges not in yet

    this._drawArrows();
    this._closeBtn = new G.Button(235, -257, 'btn_x', this._onClose.bind(this));
    this._container.addChild(this._closeBtn);
  }

  /**
   * Draws an event blocker to eat inputs
   */
  _drawEventBlocker() {
    this._eventBlocker = new UI_EventBlocker();
    this._eventBlocker.x = -game.width / 2;
    this._eventBlocker.y = -game.height / 2;
    this.addChild(this._eventBlocker);
    this._eventBlocker.show(0, 0.3);
  }

  /**
   * Destroy!!
   */
  destroy() {
    for (const page of this._totalPages) {
      if (page) {
        page.destroy();
      }
    }
    this._totalPages.length = 0;
    this._eventBlocker.destroy();
    this._onCloseFunc = null;
    super.destroy();
  }

  /**
   * Repositions blocker if game resizes
   */
  resize() {
    if (this._eventBlocker) {
      this._eventBlocker.x = -game.width / 2;
      this._eventBlocker.y = -game.height / 2;
    }
  }

  /**
   * Draws the background. Doesn't change much
   */
  _drawBg() {
    this._bg = G.makeImage(0, 0, 'popup_background_treasureHunt', 0.5, this._container);

    this._titleHeader = new Phaser.Group(game, this._container);
    G.makeImage(0, -265, 'treasureHunt_banner', 0.5, this._titleHeader);
  }

  /**
   * Some reason the text is messed up if you just try to change setText
   * @param {string} text
   */
  _redrawTitleText(text) {
    if (this._titleTxt) {
      this._titleHeader.removeChild(this._titleTxt);
      this._titleTxt.destroy();
    }
    this._titleTxt = new G.Text(0, -267, OMT.language.getText(text), 'treasureHunt-headerTitleText', 0.5, 330, 125, true, 'center');
    this._titleHeader.add(this._titleTxt);
  }

  /**
   * Draws the first page and initializes all elements that it uses
   */
  _drawPageOne() {
    this._pageOne = new Phaser.Group(game, null);
    this._totalPages.push(this._pageOne);

    // Subtitle text explanation
    const title = new G.Text(0, -100, OMT.language.getText('How to collect treasure hunt tokens'), 'treasureHunt-hintWindowTitle', 0.5, this._bg.width * 0.8, null, true, 'center');

    // Grid
    const gridX = 3;
    const gridY = 3;
    const gridScale = 1;
    this._grid = new TutorialGrid(); // Grid bg. Has the background sprite
    this._grid.init(gridX, gridY, tileSize, 'tut_tile', gridScale);
    this._gems = new TutorialGrid(); // For the gems
    const gemsData = [3, 5, 'treasureHunt_token',
                      1, 3, 5, // eslint-disable-line indent
                      2, 3, 4].map((num) => (Number.isFinite(num) ? `candy_${num}` : num)); // eslint-disable-line indent
    this._gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    // fx emitters for grid
    this._fxEmitters = [];
    for (let i = 0; i < 4; i++) { // Particle emitters
      const emitter = new FxParticle(); // For the sfx
      emitter.scale.setTo(gridScale); // It also grows big
      this._fxEmitters.push(emitter);
    }

    // Tutorial hand
    this._tutHand = new Phaser.Group(game, null);
    G.makeImage(0, 0, 'tut_hand', 0, this._tutHand); // Haaaand
    this._tutHand.alpha = 0;

    // Add it all together!
    this._pageOne.addChild(title);
    this._pageOne.addChild(this._grid);
    this._pageOne.addChild(this._gems);
    this._pageOne.addChild(this._tutHand);
    for (let i = 0; i < this._fxEmitters.length; i++) {
      this._pageOne.addChild(this._fxEmitters[i]);
    }

    this._positionGrids(0, 100);
  }

  /**
   * Positions all the grids on top of each other
   * @param {number} inX
   * @param {number} inY
   */
  _positionGrids(inX, inY) {
    this._grid.x = inX;
    this._grid.y = inY;

    this._gems.x = inX;
    this._gems.y = inY;
  }

  /**
   * Draws an arrow if theres reason to
   */
  _drawArrows() {
    if (this._totalPages.length > 1) {
      const arrowData = [{
        func: this._onPrevArrowClick.bind(this),
        scale: -1,
      }, {
        func: this._onNextArrowClicked.bind(this),
        scale: 1,
      }];
      this._arrows = [];
      for (const arrowDat of arrowData) {
        const arrow = new G.Button(0, 0, 'treasureHunt_arrow', arrowDat.func);
        if (arrowDat.scale === 1) {
          arrow.x = this._bg.x + (this._bg.width * 0.4) - (arrow.width / 2);
        } else {
          arrow.x = this._bg.x - (this._bg.width * 0.4) + (arrow.width / 2);
        }
        arrow.scale.set(arrowDat.scale, 1);
        arrow.y = 50;
        this._arrows.push(arrow);
      }
    }
  }

  /**
   * Shows the first page. Sets it up for viewing
   */
  async showPage() {
    this._container.alpha = 0;
    this._container.scale.set(0, 1);
    this.visible = true;
    for (const page of this._totalPages) {
      this._container.removeChild(page);
    }
    this._showPageOne();
    this._showArrow();

    // Sets up page one for looping
    const gem00 = this._getGemData(this._gems, 0, 0);
    const gem10 = this._getGemData(this._gems, 1, 0);
    const gem11 = this._getGemData(this._gems, 1, 1);
    const gem12 = this._getGemData(this._gems, 1, 2);
    const token = this._getGemData(this._gems, 2, 0);
    const animationPackage = {
      gem00,
      gem10,
      gem11,
      gem12,
      token,
      fxGemTargets: [gem10, gem11, gem12], // Target gems for the sfx
      fadeGemTargets: [gem00, gem11, gem12],
    };
    this._quickReset(animationPackage);

    // Simulate window entry
    this.wrapTween(this._container, { alpha: 1 }, 300, Phaser.Easing.Sinusoidal.In);
    await this.wrapTween(this._container.scale, { x: 1 }, 400, Phaser.Easing.Elastic.Out);

    // Sets tween times and go!
    this._tweenTime = 500;
    this._tweenDelay = 1500;
    this._animate(animationPackage);
  }

  /**
   * Goes to page two, if we're on page one
   * @returns {null}
   */
  _onPrevArrowClick() {
    if (this._currentPage !== 2) { return; }
    for (const page of this._totalPages) {
      this._container.removeChild(page);
    }
    this._showPageOne();
    this._showArrow();
  }

  /**
   * Goes to page two, if we're on page one
   * @returns {null}
   */
  _onNextArrowClicked() {
    if (this._currentPage !== 1) { return; }
    for (const page of this._totalPages) {
      this._container.removeChild(page);
    }
    this._showPageTwo();
    this._showArrow();
  }

  /**
   * Shows arrow if theres pages ahead to show
   */
  _showArrow() {
    for (const arrow of this._arrows) {
      this._container.removeChild(arrow);
    }
    if (this._currentPage === 2) {
      this._container.addChild(this._arrows[0]);
    } else if (this._currentPage === 1) {
      this._container.addChild(this._arrows[1]);
    }
  }

  /**
   * Draws page two.
   * Supposed to show info on badges.
   * Somewhat built
   */
  _drawPageTwo() {
    this._pageTwo = new Phaser.Group(game, null);
    this._totalPages.push(this._pageTwo);

    const title = new G.Text(0, -95, OMT.language.getText('Play the Treasure Hunt to win Badges'), 'treasureHunt-hintWindowTitle', 0.5, this._bg.width * 0.79, 300, true, 'center');
    const subtitle = new G.Text(0, 30, OMT.language.getText('Win special badges by climbing the leaderboards!'), 'treasureHunt-hintWindowSubtitle', 0.5, this._bg.width * 0.60, 300, true, 'center');

    // Badgey
    const mascot = TreasureHuntManager.getMascotName(G.saveState.treasureHuntManager.currentMascot);
    const targetBadge = `img/treasureHunt/treasureHuntGold${mascot}Full.png`;
    G.makeExtImage(0, 170, targetBadge, `treasureHuntGold${mascot}`, 0.5, this._pageTwo);

    this._pageTwo.addChild(title);
    this._pageTwo.addChild(subtitle);
  }

  /**
   * Shows the first page. Sets up the title
   */
  _showPageOne() {
    this._currentPage = 1;
    this._redrawTitleText('Treasure Hunt Tokens');
    this._container.addChild(this._pageOne);
  }

  /**
   * Gets data used on the given board for animation
   * @param {G.Grid} board
   * @param {number} cellX
   * @param {number} cellY
   * @returns {{sprite: Phaser.Sprite, pos:{x:number, y:number}}}
   */
  _getGemData(board, cellX, cellY) {
    return {
      sprite: board.getSpriteByCell(cellX, cellY),
      pos: board.getPxPos(cellX, cellY),
    };
  }

  /**
   * Resets the elements for animation
   * @param {Object} config
   * @param {{sprite: Phaser.Sprite, pos:{x:number, y:number}}} config.gem00
   * @param {{sprite: Phaser.Sprite, pos:{x:number, y:number}}} config.gem10
   * @param {{sprite: Phaser.Sprite, pos:{x:number, y:number}}} config.gem11
   * @param {{sprite: Phaser.Sprite, pos:{x:number, y:number}}} config.gem12
   * @param {{sprite: Phaser.Sprite, pos:{x:number, y:number}}} config.token
   */
  _quickReset(config) {
    const {
      gem00,
      gem10,
      gem11,
      gem12,
      token,
    } = config;

    this._gems.alpha = 0;
    this._tutHand.scale.set(1); // Hand is normal sized, in position, hidden
    this._tutHand.position.set(this._gems.x + gem00.pos.x - tileSize, this._gems.y + gem00.pos.y - tileSize);
    this._tutHand.alpha = 0;
    gem00.sprite.position.copyFrom(gem00.pos);
    gem00.sprite.alpha = 1;
    gem10.sprite.position.copyFrom(gem10.pos);
    gem11.sprite.alpha = 1;
    gem12.sprite.alpha = 1;
    token.sprite.position.copyFrom(token.pos);
    token.sprite.alpha = 1;
    token.sprite.scale.set(1);
  }

  /**
   * Animates the instructions for the treasure hunt with the given config
   * @param {Object} config
   * @param {{sprite: Phaser.Sprite, pos:{x:number, y:number}}} config.gem00
   * @param {{sprite: Phaser.Sprite, pos:{x:number, y:number}}} config.gem10
   * @param {{sprite: Phaser.Sprite, pos:{x:number, y:number}}} config.gem11
   * @param {{sprite: Phaser.Sprite, pos:{x:number, y:number}}} config.gem12
   * @param {{sprite: Phaser.Sprite, pos:{x:number, y:number}}} config.token
   * @param {Array<{sprite: Phaser.Sprite, pos:{x:number, y:number}}>} config.fxGemTargets
   * @param {Array<{sprite: Phaser.Sprite, pos:{x:number, y:number}}>} config.fadeGemTargets
   */
  async _animate(config) {
    const {
      gem00,
      gem10,
      token,
      fxGemTargets,
      fadeGemTargets,
    } = config;

    // Reset EVERYTHING
    this._quickReset(config);

    await this.wrapTween(this._gems, { alpha: 1 }, this._tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page one fades in
    await this.wrapTween(this._tutHand, { alpha: 1 }, this._tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand fades in
    await this.wait(this._tweenDelay / 2); // Wait
    await this.wrapTween(this._tutHand.scale, { x: 0.9, y: 0.9 }, this._tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand clicks
    this.wrapTween(gem00.sprite, gem10.pos, this._tweenTime, Phaser.Easing.Sinusoidal.InOut); // Moves left
    this.wrapTween(gem10.sprite, gem00.pos, this._tweenTime, Phaser.Easing.Sinusoidal.InOut); // Moves Right
    await this.wrapTween(this._tutHand, { x: this._gems.x + gem10.pos.x - tileSize, y: this._gems.y + gem10.pos.y - tileSize }, this._tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand moves
    this.wrapTween(this._tutHand, { alpha: 0 }, this._tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand fades out quick
    this.wrapTween(this._tutHand.scale, { scale: 1 }, this._tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand unclicks (but fades out before you can see)
    for (let i = 0; i < fxGemTargets.length; i++) {
      if (!this.shouldEventComplete()) { return; }
      const del = _.delay(() => { // eslint-disable-line no-loop-func
        if (i < 3) {
          this._fxEmitters[i].burstCandy(this._gems.x + fxGemTargets[i].pos.x - tileSize, this._gems.y + fxGemTargets[i].pos.y - tileSize, 3); // gem sfx happens
          this.wrapTween(fadeGemTargets[i].sprite, { alpha: 0 }, this._tweenTime / 4, Phaser.Easing.Sinusoidal.InOut);
        }
      }, i * (this._tweenTime / 4));
      this._delayTimers.push(del);
    }
    await this.wait((this._tweenTime * 2) / 4); // After waiting for the second sfx happening
    this.wrapTween(token.sprite.scale, { x: 1.5, y: 1.5 }, this._tweenTime, Phaser.Easing.Bounce.InOut);
    this._fxEmitters[3].burstConcreteAnim(this._gems.x + token.pos.x - tileSize, this._gems.y + token.pos.y - tileSize, 3); // rock break sfx happens
    await this.wrapTween(token.sprite, { y: -200, alpha: 0 }, this._tweenTime, Phaser.Easing.Sinusoidal.InOut); // Token flies off
    await this.wait(this._tweenDelay);
    await this.wrapTween(this._gems, { alpha: 0 }, this._tweenTime, Phaser.Easing.Sinusoidal.InOut); // Fades out

    if (this.shouldEventComplete() && this._currentPage === 1) { // Repeats
      this._animate(config);
    }
  }

  /**
   * Shows page two
   */
  async _showPageTwo() {
    this._pageTwo.alpha = 0;
    this._currentPage = 2;
    this._redrawTitleText('Unlock Badges');
    this._container.addChild(this._pageTwo);

    // Just tweens in
    this._tweenTime = 500;
    await this.wrapTween(this._pageTwo, { alpha: 1 }, this._tweenTime, Phaser.Easing.Sinusoidal.InOut);
  }

  /**
   * When we're leaving
   */
  async _onClose() {
    this._container.alpha = 1;
    this._container.scale.set(1, 1);
    if (this._onCloseFunc) { // Does call back
      this._onCloseFunc();
    }
    // Simulate window out
    this.wrapTween(this._container, { alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out);
    await this.wrapTween(this._container.scale, { x: 2 }, 400, Phaser.Easing.Sinusoidal.In);
    if (this.parent) {
      this.parent.removeChild(this); // Removes itself
    }
    this.visible = false;
    // Resets itself
    this._tweenTime = 0.1;
    this._tweenDelay = 0.1;
    this.stopAllActions();
  }

  /**
   * Stops all actions and delays
   */
  stopAllActions() {
    for (const delays of this._delayTimers) {
      clearTimeout(delays);
    }
    this._delayTimers.length = 0;
    super.stopAllActions();
  }

  /**
   * Sets the function with what was given
   * @param {Function} func
   */
  setOnCloseFunc(func) {
    this._onCloseFunc = func;
  }
}
