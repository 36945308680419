import { TokenLayer } from './TokenLayer';
import { TOKEN_TYPES, EDITOR_SYMBOLS } from '../BoardConstants';
import { BoardToken_Concrete } from '../Tokens/BoardToken_Concrete';

/**
 * TokenLayer for BoardToken_Concrete instances.
 */
export class TokenLayer_Concrete extends TokenLayer {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    super(board, {
      constructor: BoardToken_Concrete,
      maxHp: 3,
      hpToken: true,
      editorSymbol: EDITOR_SYMBOLS.CONCRETE,
      blockMove: true,
      blockBoosterChange: true,
      collectableType: TOKEN_TYPES.CONCRETE,
      stopMatchPropagation: true,
      stopHitPropagation: true,
    });
  }
}
