G.PopUpTileBg = function () {
  Phaser.Group.call(this, game);

  this.bgTop = G.makeImage(0, 0, 'popup_top', [0.5, 0], this);
  this.bgCenter = game.make.tileSprite(0, this.bgTop.bottom, 582, 392, 'popups', 'popup_center');
  this.bgCenter.anchor.x = 0.5;
  this.pivot.y = 333;
  this.add(this.bgCenter);
  this.bgBottom = G.makeImage(0, this.bgCenter.bottom, 'popup_bottom', [0.5, 0], this);
};

G.PopUpTileBg.prototype = Object.create(Phaser.Group.prototype);

G.PopUpTileBg.prototype.changeSize = function (newHeight, skipAnim) {
  newHeight -= this.bgTop.height + this.bgBottom.height;

  if (skipAnim) {
    this.bgBottom.pivot.y = 0;
    this.bgCenter.height = newHeight;
    this.bgBottom.y = this.bgTop.height + newHeight;
  } else {
    game.add.tween(this.bgCenter)
      .to({ height: newHeight }, 300, Phaser.Easing.Sinusoidal.Out, true);
    this.bgBottom.pivot.y = 1;
    game.add.tween(this.bgBottom)
      .to({ y: this.bgTop.height + newHeight }, 300, Phaser.Easing.Sinusoidal.Out, true)
      .onComplete.add(function () {
        this.bgBottom.pivot.y = 0;
      }, this);
  }
};
