/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import OMT_TweenUtils from '../../Utils/Animation/OMT_TweenUtils';
import OMT_StackManager from '../../Utils/OMT_StackManager';
import OMT_VILLAINS from '../OMT_Villains';
import VillainsBaseClass from './VillainsBaseClass';

export default class VillainsInGame extends VillainsBaseClass {
  /**
   * Creates the villains and registers their in game animation signals
   * @param {Phaser.State} parent
   * @param {boolean} forceSecondVillan
   */
  constructor(parent, forceSecondVillan) {
    super(parent);
    this.parent = parent;

    this.objectPrefix = '_villain';

    const villain_1 = G.makeImage(
      0,
      0,
      OMT_VILLAINS.getPrefixedName('villain_1_2'),
      0.5,
      parent,
    );
    this.villain_1 = villain_1;

    const { isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    if (isSuperHardLevel || forceSecondVillan) {
      const villain_2 = G.makeImage(
        0,
        0,
        OMT_VILLAINS.getPrefixedName('villain_2_2'),
        0.5,
        parent,
      );
      this.villain_2 = villain_2;
    }

    this.signalList = {
      WIN_START: this.onWinStart,
      WIN_END: this.onWinEnd,
    };
    this.registerEvents();

    this.init();
  }

  /**
   * Initiate all objects / revert them to their default states
   */
  init() {
    const { parent, villain_1, villain_2 } = this;

    const villainScaleCoeff = 0.35;
    const villainMiddleDistanceCoeff = 0.425;
    const villainNudgeCoeff = 0.4;
    const villainJumpCoeff = 0.15;

    villain_1.scale.setTo(villainScaleCoeff);
    const villain_1_origX =
      parent.bg.x - parent.bg.width * 0.5 - villain_1.width * 0.5;
    villain_1.x = villain_1_origX;
    villain_1.y = parent.bg.y + parent.bg.height + villain_1.height * 0.5;
    villain_1.alpha = 0;
    const villain_1_targetX =
      parent.bg.x -
      parent.bg.width * villainMiddleDistanceCoeff +
      villain_1.width * 0.5;
    const villain_1_nudgeX =
      villain_1_targetX + villain_1.width * villainNudgeCoeff;
    const villain_1_jumpY = villain_1.height * villainJumpCoeff;
    this._villain_1 = {
      index: 1,
      type: 'villain',
      object: villain_1,
      origX: villain_1_origX,
      targetX: villain_1_targetX,
      nudgeX: villain_1_nudgeX,
      origY: villain_1.y,
      jumpY: villain_1_jumpY,
    };

    if (villain_2) {
      villain_2.scale.setTo(villainScaleCoeff);
      const villain_2_origX =
        parent.bg.x + parent.bg.width * 0.5 + villain_2.width * 0.5;
      villain_2.x = villain_2_origX;
      villain_2.y =
        villain_1.y + villain_1.height * 0.5 - villain_2.height * 0.5;
      villain_2.alpha = 0;
      const villain_2_targetX =
        parent.bg.x +
        parent.bg.width * villainMiddleDistanceCoeff -
        villain_2.width * 0.5;
      const villain_2_nudgeX =
        villain_2_targetX - villain_2.width * villainNudgeCoeff;
      const villain_2_jumpY = villain_2.height * villainJumpCoeff;
      this._villain_2 = {
        index: 2,
        type: 'villain',
        object: villain_2,
        origX: villain_2_origX,
        targetX: villain_2_targetX,
        nudgeX: villain_2_nudgeX,
        origY: villain_2.y,
        jumpY: villain_2_jumpY,
      };
    }
    villain_1.scale.x *= -1;
  }

  /**
   * Villains enter animation
   */
  async villainEnter() {
    const villains = this.getObjects(
      {
        type: 'villain',
      },
      (object) => object,
    );
    const easeIn = Phaser.Easing.Circular.In;
    const stack = OMT_StackManager.getFreeStack();
    const subStacks = [];

    for (let i = 0; i < villains.length; i++) {
      const villain = villains[i];
      const { object } = villain;
      const subStack = OMT_StackManager.getFreeStack();

      subStack.addPromise(() =>
        OMT_TweenUtils.tweenMultiple({
          object,
          duration: this.randomizeValue(250, 0.1),
          ease: easeIn,
          props: {
            x: villain.targetX,
            alpha: 1,
          },
        }),
      );

      subStacks.push(subStack);
    }

    if (subStacks.length) {
      stack.addParallel(subStacks);
    }

    return stack.run();
  }

  /**
   * Villains jump animation
   */
  async villainJump() {
    const villains = this.getObjects(
      {
        type: 'villain',
      },
      (object) => object,
    );
    const easeIn = Phaser.Easing.Circular.In;
    const bounceInOut = Phaser.Easing.Bounce.InOut;
    const stack = OMT_StackManager.getFreeStack(() => {
      if (!this.stopped) {
        this.villainJump();
      } else {
        this.villainLeave();
      }
    });
    const subStacks = [];

    for (let i = 0; i < villains.length; i++) {
      const villain = villains[i];
      const { object } = villain;
      const subStack = OMT_StackManager.getFreeStack();

      subStack.addPromise(() =>
        OMT_TweenUtils.tweenMultiple({
          object,
          duration: this.randomizeValue(250, 0.9),
          ease: easeIn,
          props: {
            y: object.y - villain.jumpY,
            angle: this.randomizeValue(-5, 0.5),
          },
        }),
      );
      subStack.addPromise(() =>
        OMT_TweenUtils.tweenMultiple({
          object,
          duration: this.randomizeValue(250, 0.9),
          ease: easeIn,
          props: {
            y: object.y - villain.jumpY,
            angle: this.randomizeValue(5, 0.5),
          },
        }),
      );
      subStack.addPromise(() =>
        OMT_TweenUtils.changeAngleTo({
          object,
          angle: this.randomizeValue(-2.5, 0.5),
          duration: this.randomizeValue(250, 0.9),
          ease: bounceInOut,
        }),
      );
      subStack.addPromise(() =>
        OMT_TweenUtils.tweenMultiple({
          object,
          duration: this.randomizeValue(250, 0.9),
          ease: bounceInOut,
          props: {
            y: villain.origY,
            angle: 0,
          },
        }),
      );

      subStacks.push(subStack);
    }

    if (subStacks.length) {
      stack.addParallel(subStacks);
    }

    return stack.run();
  }

  /**
   * Villains leave animation
   */
  async villainLeave() {
    const villains = this.getObjects(
      {
        type: 'villain',
      },
      (object) => object,
    );
    const easeIn = Phaser.Easing.Circular.In;
    const easeOut = Phaser.Easing.Circular.Out;
    const stack = OMT_StackManager.getFreeStack();
    const subStacks = [];

    for (let i = 0; i < villains.length; i++) {
      const villain = villains[i];
      const { object } = villain;
      const subStack = OMT_StackManager.getFreeStack();

      subStack.addEvent(() => {
        object.scale.x *= -1;
      });
      subStack.wait(this.randomizeValue(200, 0.9));
      subStack.addPromise(() =>
        OMT_TweenUtils.translateTo({
          object,
          x: villain.nudgeX,
          duration: 100,
          ease: easeIn,
        }),
      );
      subStack.addPromise(() =>
        OMT_TweenUtils.tweenMultiple({
          object,
          duration: 300,
          ease: easeOut,
          callback: () => {
            object.destroy();
          },
          props: {
            alpha: 0,
            x: villain.origX,
          },
        }),
      );

      subStacks.push(subStack);
    }

    if (subStacks.length) {
      stack.addParallel(subStacks);
    }

    stack.addEvent(() => {
      this.stopped = false;
      this.init();
    });
    return stack.run();
  }

  /**
   * On board cascade animation start
   */
  async onWinStart() {
    const stack = OMT_StackManager.getFreeStack();

    stack.addPromise(() => this.villainEnter());
    stack.addPromise(() => this.villainJump());

    return stack.run();
  }

  /**
   * On board deconstruct start
   */
  async onWinEnd() {
    this.stopped = true;
  }
}
