/* eslint-disable operator-linebreak */
/* eslint-disable no-use-before-define */
import OMT_VILLAINS from '../../../OMT_UI/OMT_Villains';
import VillainsLevelNode from '../../../OMT_UI/Villains/VillainsLevelNode';
import { OMT_SessionUtil } from '../../../Utils/OMT_SessionUtil';

export default class WorldMapVillains extends Phaser.Group {
  /**
   * World map villain images pool
   */
  constructor() {
    super(game, null);
    this._actives = [];
    this._deadObjects = [];
  }

  /**
   * Returns a free element
   */
  _getFreeElement() {
    let wrapper;

    if (this._deadObjects.length > 0) {
      wrapper = this._deadObjects.pop();
    } else {
      wrapper = new LevelNodeVillainWrapper(this);
    }
    return wrapper;
  }

  /**
   * Kills the element and puts it in the proper dead array
   * @param {BasicNode} elem
   */
  _onElementKilled(elem) {
    this._deadObjects.push(elem);
    if (elem.parent) {
      elem.parent.removeChild(elem);
    }
  }

  /**
   * Kills tile at index
   * @param {number} index
   */
  killTile(index) {
    const elements = this._actives.filter(
      (wrapper) => wrapper._tileIndex === index,
    );
    for (const element of elements) {
      element.kill();
      this._actives.splice(this._actives.indexOf(element), 1);
    }
  }

  /**
   * Adds the tile
   * @param {number} index
   * @param {BackgroundTile} tile
   * @param {Array<LevelNode>} levelNodes
   * @returns {NoGoodBubble}
   */
  addTile(index, tile, levelNodes) {
    const elements = [];
    for (const node of levelNodes) {
      const wrapper = this._getFreeElement();
      wrapper.updateAllComponents(node, index).then(() => {
        const { x, y } = node;
        wrapper.position.setTo(x, y);
        elements.push(wrapper);
        this._actives.push(wrapper);
        this.add(wrapper);
      });
    }

    return elements;
  }

  /**
   * Moves tiles based on deltaY
   * @param {number} deltaY
   */
  moveTiles(deltaY) {
    // update active segments
    for (let i = this._actives.length - 1; i >= 0; i--) {
      this._actives[i].y += deltaY;
    }
  }

  /**
   * Destroy!!
   */
  destroy() {
    for (const obj of this._deadObjects) {
      if (obj && obj.destroy) {
        obj.destroy();
      }
    }
    super.destroy();
  }

  /**
   * Returns the level node that is this level
   * @param {number} level
   */
  getNodeAtLevel(level) {
    return this._actives.find((wrapper) => wrapper._levelIndex === level);
  }

  /**
   * Link the level node pool to the villains pool
   * @param {WorldMapLevelLayer} levelNodePool
   */
  bindLevelNodesPool(levelNodePool) {
    this._pool_levelNode = levelNodePool;
  }
}

class LevelNodeVillainWrapper extends Phaser.Group {
  constructor(parentTile) {
    super(game);
    this._parentTile = parentTile;
  }

  /**
   * Update all visual components of the group based on the node and tile index
   * @param {LevelNode} node
   * @param {number} index
   */
  async updateAllComponents(node, index) {
    this._node = node;
    this._baseNode = node._baseNode;
    this._levelIndex = node._levelIndex;
    this._tileIndex = index;
    const curLevel = G.saveState.getLastPassedLevelNr(); // Find out the current level
    const levelData = await G.Helpers.levelDataMgr.getLevelByIndex(
      this._levelIndex,
    ); // Get Level data here
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty(levelData);
    const previousLevelData = await G.Helpers.levelDataMgr.getLevelByIndex(
      this._levelIndex - 1,
    );
    const previousNodeIsMaxLevel = curLevel === this._levelIndex - 1;
    let previousNodeDifficulty = OMT_VILLAINS.getDifficulty(previousLevelData);
    if (this._levelIndex === 0) {
      previousNodeDifficulty = OMT_VILLAINS.getBaseDifficultyObject();
    }
    const {
      isSuperHardLevel: previousSuperHard,
      isNotNormalLevel: previousNotNormal,
    } = previousNodeDifficulty;

    if (isNotNormalLevel || previousNotNormal) {
      this._createVillains();
    }

    if (this.villains) {
      this.villains.hide();
    }

    if (this.villains && OMT.feature.isVillainsEnabled()) {
      if (previousNotNormal && previousNodeIsMaxLevel) {
        this.villains.show(1);
        if (previousSuperHard) {
          this.villains.show(2);
        }
      }
    }
    this.revive();

    if (this.villains) {
      await this._startAnimations();
    }
  }

  /**
   * Create the level node villains here
   */
  _createVillains() {
    if (this.villains) return;
    this.villains = new VillainsLevelNode(this);
  }

  /**
   * Starts poof animation if needed
   */
  async _startAnimations() {
    const { _levelIndex } = this;
    const session = OMT_SessionUtil.getInstance();
    const sessionData = session.checkAndCreateWithKey(
      OMT_VILLAINS.getLevelTrackerKey(),
    );
    const previousLevelSessionData = sessionData.checkAndCreateWithKey(
      _levelIndex - 1,
    );
    const showPoof = previousLevelSessionData.getData('showPoof');
    if (showPoof) {
      const levelData = await G.Helpers.levelDataMgr.getLevelByIndex(
        this._levelIndex - 1,
      ); // Get Level data here
      const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty(levelData);
      if (isNotNormalLevel) {
        this.villains.explodePoofReady = true;
      }
      previousLevelSessionData.setData('showPoof', false);
    }
  }

  /**
   * Kills the tile, not the villains
   */
  kill() {
    const { _parentTile } = this;
    _parentTile._onElementKilled(this);
    super.kill();
  }
}
