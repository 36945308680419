import { EDITOR_SYMBOLS } from '../BoardConstants';
import { Action } from './Action';

/**
 * Action for all boosters other then the swap booster
 */
export class ActionBoosterMatch extends Action {
  /**
   * initialize the action.
   */
  _init(boosterData) {
    this._boosterData = boosterData;
    this._clickedCandy = null;
    this._availableCandies = [];

    this._signalBinding = game.input.onDown.add(this._onPointerDown, this);
  }

  /**
   * on pointer down signal
   * @param {Object} pointer
   */
  _onPointerDown(pointer) {
    const { inputController } = this._board;
    const cell = inputController.pointerToCell(pointer);
    if (!cell) return;

    const cellX = cell[0]; const cellY = cell[1];
    const candy = this._board.getCandy(cellX, cellY);
    const tokens = this._board.getAllTokensInCell(cellX, cellY);

    if (candy) { // candy found trigger booster if not blocked
      const candyAvailable = !(this._availableCandies.length > 0 && this._availableCandies.indexOf(candy) === -1);
      const candyIsValidType = candy.baseType.getEditorSymbol() !== EDITOR_SYMBOLS.LAYER_CAKE && !candy.isGoalCollectType();
      const candyBlockedByToken = (tokens.length === 1 || tokens[0] === candy) && !candyIsValidType;
      if (candyAvailable && !candyBlockedByToken) {
        this._triggerBooster(cellX, cellY, this._boosterData);
      }
    } else if (tokens.length > 0) { // candy not found, but tokens are. If a legal move use the booster.
      const isLegalMove = this._availableCandies.length > 0 ? tokens.reduce((legal, token) => this._availableCandies.indexOf(token) !== -1 || legal, false) : true;
      if (isLegalMove) {
        this._triggerBooster(cellX, cellY, this._boosterData);
      }
    }
  }

  /**
   * trigger the booster on the specified cell X,Y
   * @param {number} cellX
   * @param {number} cellY
   * @param {Object} boosterData
   */
  _triggerBooster(cellX, cellY, boosterData) {
    const { boosterNr, unlimitedMode } = boosterData;
    const { gameHooks } = this._board;
    gameHooks.onBoosterUse(boosterNr, unlimitedMode);
    gameHooks.playSound('pop');
    this._signalBinding.detach();

    // TODO: booster types should be a constant somewhere
    if (boosterNr === 3) {
      this._board.candiesLayer.boosterFxGroup.add(new G.BoosterHorizontal(this._board, cellX, cellY, boosterNr));
    } else if (boosterNr === 4) {
      this._board.candiesLayer.boosterFxGroup.add(new G.BoosterVertical(this._board, cellX, cellY, boosterNr));
    } else {
      this._board.candiesLayer.boosterFxGroup.add(new G.Booster(this._board, cellX, cellY, boosterNr));
    }
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._signalBinding.detach();
    this._availableCandies.length = 0;
    this._boosterData = null;
  }

  /** GETTER / SETTER METHODS ********************************* */

  /** @param {Array.<Candy>} candyList set by tutorial to limit possible candies for booster */
  set availableCandies(candyList) { this._availableCandies = candyList; }
}
