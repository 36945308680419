export class OMT_UI_GiftChest extends Phaser.Group {
  /**
   * A standalone gift chest. This was made for Window_ChestShuffleGame and doesnt yet have a lot of customization options, but feel free to add them as necessary.
   * @param {object} game The game.
   * @param {object} parent The parent this should be added to.
   * @param {Array<string, number>} gift The gift config.
   */
  constructor(game, parent, gift) {
    super(game, parent);
    this.glow = new G.Image(0, 0, 'prize_glow', 0.5, this);
    this.glow.scale.set(1.2);
    this.glow.alpha = 0;

    const chestImg = new G.Image(0, 0, 'chestshuffle_closed', 0.5, this);
    this.img = chestImg;

    this.gift = new Phaser.Group(game, this);
    const prizeGlow = new G.Image(0, 0, 'prize_glow', 0.5, this.gift);
    prizeGlow.scale.set(1.5);
    this.giftImg = new G.Image(0, 0, 'heart_unlimited', 0.5, this.gift);
    this.giftTxt = new G.Text(0, 0, '', { style: 'chestShuffleGift', fontSize: '50px' }, 0.5);
    this.gift.add(this.giftTxt);
    this.giftImg.scale.set(2);
    this.giftTxt.y = this.giftImg.height / 2;
    this.gift.scale.set(0.7);

    this.setupGift(gift);
  }

  /**
   * Opens the gift chest to reveal its gift.
   * @param {boolean} fullScale Whether the chest should grow to its full size while opening
   * @param {number} jumpDist The distance the gift travels above the chest
   */
  open(fullScale = true, jumpDist = 120) {
    this.img.changeTexture('chestshuffle_open');
    this.gift.alpha = 1;
    game.add.tween(this.gift).to({ y: -jumpDist }, 500, Phaser.Easing.Exponential.Out).start();
    if (fullScale) game.add.tween(this.gift.scale).to({ x: 1, y: 1 }, 500, Phaser.Easing.Back.Out).start();
  }

  /**
   * Closes the gift chest, hiding its gift
   */
  close() {
    game.add.tween(this.gift).to({ y: 0 }, 150, Phaser.Easing.Default).start().onComplete.add(() => {
      this.img.changeTexture('chestshuffle_closed');
      this.gift.alpha = 0;
    });
  }

  /**
   * Reset the chest back to its initial state, and closing it.
   */
  reset() {
    this.alpha = 1;
    this.x = this.originalPos.x;
    this.y = this.originalPos.y;
    this.glow.alpha = 0;
    this.gift.scale.set(0.7);
    this.close();
  }

  /**
   * Constructing an image out of the gift config.
   * @param {Array<string, number>} gift The gift config
   */
  setupGift(giftArr) {
    this.giftImg.changeTexture(G.gift.getIcon(giftArr));
    let giftAmt = giftArr[1];
    if (giftArr[0] === 'life') giftAmt = `x${giftAmt}`;
    if (giftArr[0] === 'lifeUnlimited') giftAmt = G.gift.getUnlimitedLivesTimeLabel(giftArr);
    this.giftTxt.setText(giftAmt);
    this.gift.alpha = 0;
    this.giftData = giftArr;
  }
}
