/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */

/* /// //////////////////////////////////////////////////////////////////////////////
// SETTINGS FOR MAIN LEADERBOARD ////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////// */

import { MILLISECONDS_IN_DAY, MILLISECONDS_IN_HOUR, MILLISECONDS_IN_MIN } from '@omt-components/Utils/TimeUtil';
import OMT_UI_VerticalScrollBarConfig from '../OMT_UI/Scroll/ScrollBarConfig/OMT_UI_VerticalScrollBarConfig';

// expanded panel shared view settings
export const EXPANDED_LIST_ROWS = 6;
export const EXPANDED_LIST_ROW_HEIGHT = 80;
export const EXPANDED_LIST_SCROLL_CONFIG = new OMT_UI_VerticalScrollBarConfig(12, 5, 8, 0.99, null, null, 'leaderboard', 'lb_scroll_knob_vertical', true, null, true);
export const EXPANDED_ODD_ROW_COLOR = 0xe5f8fb;
export const EXPANDED_EVEN_ROW_COLOR = 0xd1f8fe;

// cool down ids + duration
export const COIN_REQUEST_COOLDOWN = { id: 'RequestCoins', duration: 48 * MILLISECONDS_IN_HOUR };
export const LIFE_REQUEST_COOLDOWN = { id: 'RequestLife', duration: 48 * MILLISECONDS_IN_HOUR };
export const LIFE_SEND_COOLDOWN = { id: 'SendLife', duration: 48 * MILLISECONDS_IN_HOUR };

// MainLeaderboard.js settings
export const ACTIVE_TABS = ['Friends', 'Lives']; // available tabs are 'Friends' and 'Lives'
export const DEAULT_TAB = 'Friends'; // should be listed in ACTIVE_TABS
export const COLLAPSED_Y_OFFSET = -150;
export const COLLAPSE_ANIMATION_DURATION = 500;
export const EXPANDED_Y_OFFSET = -80 - (EXPANDED_LIST_ROWS * EXPANDED_LIST_ROW_HEIGHT);
export const EXPAND_ANIMATION_DURATION = 500;
export const HIDE_ANIMATION_DURATION = 500; // used for both hide and show
export const EXPANDED_STATE_FADE_OUT_DURATION = 250;
export const EXPANDED_STATE_FADE_IN_DURATION = 250;
export const COLLAPSED_STATE_FADE_OUT_DURATION = 100;
export const COLLAPSED_STATE_FADE_IN_DURATION = 250;
export const COLLAPSED_STATE_EVENT_BLOCKER_Y = -60; // offset to acount for tabs and buttons on top of the panel

// MainLeaderboardExpandButton.js settings
export const EXPAND_BTN_POS = { x: 416, y: -5 };
export const EXPAND_BTN_TEXT_POS = { x: 85, y: 0 };
export const EXPAND_BTN_TEXT_MAX_WIDTH = 140;
export const EXPAND_BTN_TEXT_MAX_HEIGHT = 40;
export const EXPAND_BTN_TEXT_STYLE = { style: 'font-white', fontSize: '30px' };
export const EXPAND_BTN_EXPAND_TEXT = 'Show more';
export const EXPAND_BTN_COLLAPSE_TEXT = 'Show less';
export const EXPAND_BTN_ARROW_POS = { x: 185, y: -2 };

// MainLeaderboardPanel.js settings, for all leaderboard panels
export const PANEL_BG_FILL_COLOR = 0xe5f8fb;
export const PANEL_BG_FILL_PADDING = 5;
export const PANEL_BG_MIDDLE_HEIGHT = (EXPANDED_LIST_ROW_HEIGHT * EXPANDED_LIST_ROWS) - 10;
export const PANEL_CONTENT_RECT = new Phaser.Rectangle(19, 20, 600, PANEL_BG_MIDDLE_HEIGHT + 10);
export const PANEL_LOADING_INDICATOR_COLLAPSED_Y = 80;
export const PANEL_LOADING_INDICATOR_EXPANDED_Y = 260;
export const PANEL_ERROR_TEXT_MAX_WIDTH = 450;
export const PANEL_ERROR_TEXT_STYLE = { style: 'font-blue', fontSize: '28px' };
export const PANEL_ERROR_TEXT_COLLAPSED_OFFSET_X = 80;

// MainLeaderboardPanelTab.js settings
export const TAB_POS = { y: 18, x: 0 }; // tab origin, will shift by TAB_X_SPACING
export const TAB_TEXT_WIDTH_RATIO = 0.75;
export const TAB_TEXT_HEIGHT_RATIO = 0.75;
export const TAB_TEXT_STYLE = { style: 'font-white', fontSize: '36px' };
export const TAB_X_SPACING = 205;

// Invite button panel settings
export const INVITE_PANEL_TRANSITION_DURATION = 500;
export const INVITE_PANEL_TRANSITION_FADE_DURATION = 250;
export const INVITE_PANEL_EXPANDED_BUTTON_POS = { x: 320, y: -30 };
export const INVITE_PANEL_EXPANDED_BUTTON_DIMENSIONS = { width: 200, height: 55 };
export const INVITE_PANEL_EXPANDED_BUTTON_TEXT_STYLE = { style: 'font-white', fontSize: 30 };
export const INVITE_PANEL_COLLAPSED_BUTTON_POS = { x: 90, y: -62 };
export const INVITE_PANEL_COLLAPSED_BUTTON_TEXT_POS = { x: 0, y: -22 };
export const INVITE_PANEL_COLLAPSED_BUTTON_TEXT_STYLE = { style: 'font-white', fontSize: '50px', lineSpacing: -10 };
export const INVITE_PANEL_MASCOT_POS = { x: -150, y: 10 };
export const INVITE_PANEL_MASCOT_SCALE = 0.3;
export const INVITE_PANEL_MASCOT_FRAME = 'gingerbread_man_large';
export const INVITE_PANEL_MASCOT_SHOW_DURATION = 400;
export const INVITE_PANEL_MASCOT_SHOW_DELAY = 300;
export const INVITE_PANEL_MASCOT_HIDE_DURATION = 200;

// expanded friends panel settings
export const FRIENDS_PANEL_EXPANDED_FONT_STYLE = { style: 'font-blue', fontSize: '34px' };
export const FRIENDS_PANEL_EXPANDED_RANK_X = 28;
export const FRIENDS_PANEL_EXPANDED_AVATAR_X = 50;
export const FRIENDS_PANEL_EXPANDED_COIN_X = 340;
export const FRIENDS_PANEL_EXPANDED_COIN_SCALE = 0.9;
export const FRIENDS_PANEL_EXPANDED_SCORE_X = 440;

// collapsed friends panel settings
export const FRIENDS_PANEL_COLLAPSED_OVERLAY_BG_RECT = new Phaser.Rectangle(0, 0, 142, 250);
export const FRIENDS_PANEL_COLLAPSED_OVERLAY_BG_COLOR = 0xA5F4FE;
export const FRIENDS_PANEL_COLLAPSED_SCROLL_RECT = new Phaser.Rectangle(142, 5, 460, 140);
export const FRIENDS_PANEL_COLLAPSED_USER_VIEW_RECT = new Phaser.Rectangle(0, 0, 90, 120);
export const FRIENDS_PANEL_COLLAPSED_USER_VIEW_H_SPACING = 96;
export const FRIENDS_PANEL_COLLAPSED_AVATAR_SIZE = 58;
export const FRIENDS_PANEL_COLLAPSED_AVATAR_POS = { x: 47, y: 38 };
export const FRIENDS_PANEL_COLLAPSED_USERNAME_POS = { x: 47, y: 86 };
export const FRIENDS_PANEL_COLLAPSED_USERNAME_MAX_WIDTH = 80;
export const FRIENDS_PANEL_COLLAPSED_USERNAME_STYLE = { style: 'font-blue', fontSize: '20px' };
export const FRIENDS_PANEL_COLLAPSED_SCORE_POS = { x: 15, y: 108 };
export const FRIENDS_PANEL_COLLAPSED_COIN_SCALE = 0.5;
export const FRIENDS_PANEL_COLLAPSED_COIN_POS = { x: 23, y: 21 };

// expanded lives panel settings
export const LIFE_PANEL_EXPANDED_FONT_STYLE = { style: 'font-blue', fontSize: '34px' };
export const LIFE_PANEL_EXPANDED_BTN_ICON_SCALE = 0.9; // scale of heart icons on send / request life
export const LIFE_PANEL_EXPANDED_BTN_TEXT_OFFSET_Y = 3; // vertical adjustment for text centering
export const LIFE_PANEL_EXPANDED_SEND_BTN_X = 420;
export const LIFE_PANEL_EXPANDED_REQUEST_BTN_X = 580;

// collapsed friends panel settings
export const LIFE_PANEL_COLLAPSED_OVERLAY_BG_RECT = new Phaser.Rectangle(0, 0, 142, 250);
export const LIFE_PANEL_COLLAPSED_OVERLAY_BG_COLOR = 0xA5F4FE;
export const LIFE_PANEL_COLLAPSED_SCROLL_RECT = new Phaser.Rectangle(142, 5, 460, 140);
export const LIFE_PANEL_COLLAPSED_USER_VIEW_RECT = new Phaser.Rectangle(0, 0, 90, 120);
export const LIFE_PANEL_COLLAPSED_USER_VIEW_H_SPACING = 93;
export const LIFE_PANEL_COLLAPSED_AVATAR_SIZE = 58;
export const LIFE_PANEL_COLLAPSED_AVATAR_POS = { x: 45, y: 38 };
export const LIFE_PANEL_COLLAPSED_USERNAME_POS = { x: 45, y: 88 };
export const LIFE_PANEL_COLLAPSED_USERNAME_MAX_WIDTH = 80;
export const LIFE_PANEL_COLLAPSED_USERNAME_STYLE = { style: 'font-blue', fontSize: '20px' };
export const LIFE_PANEL_COLLAPSED_ICON_SCALE = 0.7;
export const LIFE_PANEL_COLLAPSED_ICON_POS = { x: 20, y: 21 };
