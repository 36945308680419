/* eslint-disable no-use-before-define */
import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';

/**
 * container / controller for leaderboard select tabs
 */
export class SpecialEvent_LeaderboardTabs extends Phaser.Group {
  /**
   * container / controller for leadeboard select tabs
   * @param {Object} config
   * @param {Object} config.buttonGraphics
   * @param {number} config.buttonSpacing
   * @param {Object} config.textStyle
   * @param {Object} config.dividerStroke
   * @param {string} defaultTabName
   */
  constructor(config, defaultTabName = null) {
    super(game);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._config = config;

    this.signals = {
      onTabClicked: new Phaser.Signal(),
    };

    this._drawTabsHR(config.dividerStroke);
    this._initTabs();

    if (defaultTabName) this.updateTabsSelection(defaultTabName);
  }

  /**
   * draw horitonal lin across panel
   * @param {Object} stroke
   * @param {number} stroke.thickness
   * @param {number} stroke.color
   * @param {number} stroke.alpha
   */
  _drawTabsHR(stroke) {
    const width = this._isLandscape ? game.width / this._gameScale : game.width;
    const tabLines = game.add.graphics();
    tabLines.beginFill(stroke.color, stroke.alpha || 1);
    tabLines.drawRect(0, 0, width, stroke.thickness);
    tabLines.endFill();
    tabLines.x = -tabLines.width / 2;
    this.addChild(tabLines);
  }

  /**
   * init leaderboard select tabs
   */
  _initTabs() {
    const {
      buttonGraphics, buttonSpacing, textStyle,
    } = this._config;

    this._friendsTab = new SpecialEvent_LeaderboardTab(buttonGraphics, 'Friends', textStyle, this._onTabClicked.bind(this));
    this._friendsTab.x = -(this._friendsTab.width / 2) - (buttonSpacing / 2);
    this._friendsTab.y = -this._friendsTab.height / 2;

    this._globalTab = new SpecialEvent_LeaderboardTab(buttonGraphics, 'Global', textStyle, this._onTabClicked.bind(this));
    this._globalTab.x = (this._globalTab.width / 2) + (buttonSpacing / 2);
    this._globalTab.y = -this._globalTab.height / 2;

    this.addChild(this._friendsTab);
    this.addChild(this._globalTab);
  }

  /**
   * on leaderboard tab clicked
   * @param {SpecialEvent_LeaderboardTab} tab
   */
  _onTabClicked(tab) {
    const tabLabel = tab.labelString;
    this.signals.onTabClicked.dispatch(tabLabel);
    this.updateTabsSelection(tabLabel);
  }

  /**
   * update the select state of tabs by their label
   * @param {string} activeTabLabel
   */
  updateTabsSelection(activeTabLabel) {
    this._friendsTab.updateSelectState(this._friendsTab.labelString === activeTabLabel);
    this._globalTab.updateSelectState(this._globalTab.labelString === activeTabLabel);
  }

  /**
   * destruction method
   */
  destroy() {
    this.signals.onTabClicked.dispose();
    super.destroy();
  }
}

/**
 * leaderboard tab button class
 */
class SpecialEvent_LeaderboardTab extends UI_GButtonES6 {
  /**
   * constructor
   * @param {Object} buttonGraphics
   * @param {string} buttonGraphics.selected
   * @param {string} buttonGraphics.unselected
   * @param {string} labelString
   * @param {Object} textStyle
   * @param {Function} callback
   */
  constructor(buttonGraphics, labelString, textStyle, callback) {
    super(0, 0, buttonGraphics.selected, callback);
    this._buttonGraphics = buttonGraphics;
    this._initLabelField(labelString, textStyle);
  }

  /**
   * init the label field
   * @param {string} labelString
   * @param {Object} textStyle
   */
  _initLabelField(labelString, textStyle) {
    const labelField = new G.Text(
      textStyle.offsetX,
      textStyle.offsetY,
      OMT.language.getText(labelString),
      textStyle,
      0.5,
      this.width * 0.9,
      this.height,
      false,
    );
    this.addChild(labelField);
    this._labelString = labelString;
  }

  /**
   * get the label string
   */
  get labelString() {
    return this._labelString;
  }

  /**
   * update button state
   * @param {boolean} active
   */
  updateSelectState(active) {
    if (active) {
      G.changeTexture(this, this._buttonGraphics.selected);
      this.disable();
    } else {
      G.changeTexture(this, this._buttonGraphics.unselected);
      this.enable();
    }
  }
}
