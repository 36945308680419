import { BoardToken } from './BoardToken';
import { TOKEN_TYPES } from '../BoardConstants';

const TOKEN_TEXTURE = 'jam_#';

/**
 * BoardToken for spreading jam
 */
export class BoardToken_SpreadingJam extends BoardToken {
  /**
   * constructor
   * @param {TokenLayer_SpreadingJam} layer
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} hp
   */
  constructor(layer, cellX, cellY, hp) {
    super(
      layer,
      {
        tokenType: TOKEN_TYPES.JAM,
      },
      cellX,
      cellY,
      hp,
    );

    // set texture based on hp
    G.changeTexture(this, TOKEN_TEXTURE.replace('#', this._hp));
  }

  /**
   * on hit by adjacent match.
   */
  onHit() {
    G.sb('hitJam').dispatch(this);
    this._hp--;

    const { gameHooks } = this._board;
    gameHooks.playSound('explosion_subtle');

    if (this._hp === 0) {
      this.remove();
      this.board.removeCandy(this._cellX, this._cellY, true);
    } else {
      G.changeTexture(this, TOKEN_TEXTURE.replace('#', this._hp));
    }
  }

  /**
   * on booster used on cell.
   * @returns {boolean}
   */
  onBooster() {
    this.onMatch();
    return false;
  }

  /**
   * increase token hp
   */
  increaseHp() {
    this._hp++;
    G.changeTexture(this, `${TOKEN_TEXTURE}_${this._hp}`);
  }
}
