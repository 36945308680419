import {
  EXPAND_BTN_TEXT_POS,
  EXPAND_BTN_TEXT_MAX_WIDTH,
  EXPAND_BTN_TEXT_MAX_HEIGHT,
  EXPAND_BTN_TEXT_STYLE,
  EXPAND_BTN_EXPAND_TEXT,
  EXPAND_BTN_COLLAPSE_TEXT,
  EXPAND_BTN_ARROW_POS,
} from '../MainLeaderboardSettings';

/**
 * class for leaderboard expand / collapse button
 */
export class MainLeaderboardExpandButton extends Phaser.Group {
  /**
   * constructor
   */
  constructor() {
    super(game);

    this.signals = {
      onClicked: new Phaser.Signal(),
    };

    this._initButtonImage();
    this._initInputEvents();
    this._initButtonLabel('');
    this._initToggleArrow();
    this.setCollapsedState();
  }

  /**
   * initialize the button image
   */
  _initButtonImage() {
    this._btnImage = G.makeImage(0, 0, 'lb2_expand_button', [0, 1], this);
  }

  /**
   * initialize the toggle arrow image
   */
  _initToggleArrow() {
    this._toggleArrowImage = G.makeImage(EXPAND_BTN_ARROW_POS.x, (-this._btnImage.height / 2) + EXPAND_BTN_ARROW_POS.y, 'lb2_expand_icon', [0.5, 0.5], this);
  }

  /**
   * initialize tab input events
   */
  _initInputEvents() {
    this._btnImage.inputEnabled = true;
    this._btnImage.input.useHandCursor = true;
    this._btnImage.events.onInputDown.add(this._onClick, this);
  }

  /**
   * initialize the button label
   * @param {string} labelText panel name / label
   */
  _initButtonLabel(labelText) {
    this._labelField = new G.Text(
      EXPAND_BTN_TEXT_POS.x,
      (-this._btnImage.height / 2) + EXPAND_BTN_TEXT_POS.y,
      OMT.language.getText(labelText),
      EXPAND_BTN_TEXT_STYLE,
      [0.5, 0.5], // anchor
      EXPAND_BTN_TEXT_MAX_WIDTH,
      EXPAND_BTN_TEXT_MAX_HEIGHT,
      false, // no wrap
    );
    this.addChild(this._labelField);
  }

  /**
   * set the collapsed sta
   */
  setCollapsedState() {
    this._labelField.setText(OMT.language.getText(EXPAND_BTN_EXPAND_TEXT));
    this._toggleArrowImage.scale.y = -1;
  }

  /**
   * set the expanded
   */
  setExpandedState() {
    this._labelField.setText(OMT.language.getText(EXPAND_BTN_COLLAPSE_TEXT));
    this._toggleArrowImage.scale.y = 1;
  }

  /**
   * click event
   */
  _onClick() {
    this.signals.onClicked.dispatch();
  }

  /**
   * destruction method
   */
  destroy() {
    Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    super.destroy();
  }
}
