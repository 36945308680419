// import { OMT_PerformanceMonitor } from '../Services/OMT/OMT_PerformanceMonitor';

export const DEBUG_MODES = {
  DISABLED: 0,
  FPS_OVERLAY: 1,
  CANVAS_DEBUGGGER: 2,
};

/**
 * class for setting debug modes
 */
export class OMT_Debug {
  constructor() {
    this._pluginInstance = null;
  }

  /**
   * set the debug state, this should be done on game startup
   * @param {number} debugMode 0-3
   */
  static setDebugMode(debugMode) {
    // const perfMon = OMT_PerformanceMonitor.getInstance();

    if (debugMode === DEBUG_MODES.DISABLED) {
      // if (perfMon.overlayVisible) perfMon.removeStatsDisplay();

      // Note: This will cause an error due to a bug with phaser-debug
      // if (this._pluginInstance) {
      //   game.plugins.remove(this._pluginInstance, true);
      // }
    } else if (debugMode === DEBUG_MODES.FPS_OVERLAY) {
      // if (!perfMon.overlayVisible) perfMon.initStatsDisplay();
    } else if (debugMode === DEBUG_MODES.CANVAS_DEBUGGGER) {
      if (!this._pluginInstance) {
        this._pluginInstance = game.add.plugin(Phaser.Plugin.Debug);
      }
    }
  }

  /**
   * check if debug mode # is valid
   * @param {number} debugMode
   */
  static isValidMode(debugMode) {
    for (const i in DEBUG_MODES) if (DEBUG_MODES[i] === debugMode) return true;
    OMT_Debug.logInvalidModeWarning();
    return false;
  }

  /**
   * log invalid mode warning
   */
  static logInvalidModeWarning() {
    let warningText = 'INVALID DEBUG MODE: valid types are listed below.\n';
    warningText += `${DEBUG_MODES.DISABLED}) disabled,\n`;
    warningText += `${DEBUG_MODES.FPS_OVERLAY}) FPS overlay only\n`;
    warningText += `${DEBUG_MODES.CANVAS_DEBUGGGER}) CANVAS DEBUGGER\n`;
    console.warn(warningText);
  }
}
