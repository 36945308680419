/* eslint-disable func-names */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-eval */
/* eslint-disable prefer-spread */
/* eslint-disable no-empty */
/* eslint-disable consistent-return */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
const { default: ComponentGameHooks } = require('@omt-components/ComponentGameHooks');
const { default: RuntimeSpritesheetManager } = require('@omt-components/Imaging/Spritesheets/RuntimeSpritesheetManager');

if (typeof G === 'undefined') G = {};

Math.sign = Math.sign || function (x) {
  x = +x; // convert to a number
  if (x === 0 || isNaN(x)) {
    return x;
  }
  return x > 0 ? 1 : -1;
};

G.pad = function pad(n, width, z) {
  z = z || '0';
  n += '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

G.isImageInCache = function (frameName, checkRuntimeSpritesheets = false) {
  const spritesheet = this.checkSheet(frameName, checkRuntimeSpritesheets);
  if (spritesheet !== '') return true;
  return game.cache.checkImageKey(frameName);
};

G.checkSheet = function (frame, checkRuntimeSpritesheets = true) {
  let sheetId = '';
  if (G.spritesheetMap) {
    sheetId = G.spritesheetMap[frame] || '';
  } else {
    sheetId = this.checkSheetOld();
  }

  // still nothing check dynamic sheets
  if (sheetId === '' && checkRuntimeSpritesheets) {
    const dynamicSheetManager = RuntimeSpritesheetManager.getInstance();
    const dynamicSheetId = dynamicSheetManager.findSpritesheetIdByFrame(frame);
    if (dynamicSheetId) sheetId = dynamicSheetId;
  }
  return sheetId || '';
};

G.checkSheetOld = function () {
  for (let i = 0, len = G.ASSETS.spritesheets.length; i < len; i++) {
    if (game.cache.checkImageKey(G.ASSETS.spritesheets[i]) && game.cache.getFrameData(G.ASSETS.spritesheets[i]).getFrameByName(frame)) {
      return G.ASSETS.spritesheets[i];
    }
  }
  return '';
};

G.lerp = function (valCurrent, valTarget, lerp, snapRange) {
  if (snapRange && Math.abs(valCurrent - valTarget) <= snapRange) {
    return valTarget;
  }

  return valCurrent + lerp * (valTarget - valCurrent);
};

/**
 * Returns the how far along value is between minValue and maxValue
 * @param {number} value
 * @param {number} minValue
 * @param {number} maxValue
 */
G.lerpFactor = function (value, minValue, maxValue) {
  if (maxValue - minValue === 0) return 0;
  return Math.min(Math.max((value - minValue) / (maxValue - minValue), 0), 1);
};

G.rl = function (value) {
  return Math.floor(value);
};

G.rnd = function (min, max) {
  return game.rnd.realInRange(min || 0, max || 1);
};

G.rndInt = function (min, max) {
  return game.rnd.between(min, max);
};

G.changeTexture = function (obj, image) {
  if (obj.game === null) return; // check for destroyed
  if (typeof image !== 'string') {
    obj.loadTexture(image); return;
  }

  const ssheet = this.checkSheet(image);
  const dynamicSheetManager = RuntimeSpritesheetManager.getInstance();
  if (ssheet === '') {
    obj.loadTexture(image);
  } else if (dynamicSheetManager.isRuntimeSpritesheet(ssheet)) { // this is a runtime spritesheet
    const texture = dynamicSheetManager.getTexture(image, ssheet);
    obj.loadTexture(texture);
  } else {
    obj.loadTexture(ssheet, image);
  }
};

/**
 * deprecated, left in as a few references still existed
 */
G.txt = function (text) {
  return ComponentGameHooks.getLocalizedString(text);
};

G.deltaTime = 1;

G.delta = function () {
  G.deltaTime = Math.min(2, game.time.elapsedMS / 16.666);
};

G.rotatePositions = function (positions) {
  const result = [];

  for (let i = 0, len = positions.length; i < len; i += 2) {
    result.push(
      positions[i + 1] * -1,
      positions[i],
    );
  }

  return result;
};

G.loadTexture = G.changeTexture;

G.makeImage = function (x, y, frame, anchor, groupToAdd) {
  let image;
  const ssheet = this.checkSheet(frame);
  const dynamicSheetManager = RuntimeSpritesheetManager.getInstance();
  if (dynamicSheetManager.isRuntimeSpritesheet(ssheet)) { // this is a runtime spritesheet
    image = dynamicSheetManager.getSprite(frame, ssheet);
    image.x = Math.floor(x); image.y = Math.floor(y);
  } else if (ssheet === '') {
    image = game.make.image(Math.floor(x), Math.floor(y), frame);
  } else {
    image = game.make.image(Math.floor(x), Math.floor(y), ssheet, frame);
  }

  if (anchor) {
    if (typeof anchor === 'number') {
      image.anchor.setTo(anchor);
    } else {
      image.anchor.setTo(anchor[0], anchor[1]);
    }
  }

  if (groupToAdd) {
    (groupToAdd.add || groupToAdd.addChild).call(groupToAdd, image);
  } else if (groupToAdd !== null) {
    game.world.add(image);
  }
  return image;
};

G.capitalize = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

G.lengthDirX = function (angle, length, rads) {
  rads = rads || false;
  if (rads) {
    return Math.cos(angle) * length;
  }
  return Math.cos(game.math.degToRad(angle)) * length;
};

G.lengthDirY = function (angle, length, rads) {
  rads = rads || false;
  if (rads) {
    return Math.sin(angle) * length;
  }
  return Math.sin(game.math.degToRad(angle)) * length;
};

G.stopTweens = function (obj) {
  game.tweens._add.forEach((tween) => {
    if (obj.scale && tween.target === obj.scale) tween.stop();
    if (tween.target === obj) tween.stop();
  });

  game.tweens._tweens.forEach((tween) => {
    if (obj.scale && tween.target === obj.scale) tween.stop();
    if (tween.target === obj) tween.stop();
  });
};

/**
 * Loads a texture from given url and saves it to cache, or returns the cached instance.
 * Providing `optDoneSyncCallback` will *not* trigger `optDoneCallback` if the task was completed
 * synchronously
 */
G.getExtTexture = function getExtTexture(
  url,
  optDoneCallback, optFailCallback,
  optAsyncStartCallback, optDoneSyncCallback,
  unusedArgument,
) {
  return act();

  function act() {
    const flow = G.AsyncUtils.promisifyCallbacks(
      optDoneCallback, optFailCallback,
      optAsyncStartCallback, optDoneSyncCallback,
    );

    ensureExtLoaderCreated();

    let textureName = null;

    const cached = getCached(url);
    if (cached) {
      if (cached.futurePromise.isSettled()) {
        if (cached.futurePromise.isSucceed()) {
          return flow.finishSync(cached.futurePromise.getValue());
        }
        textureName = cached.textureName;
      } else {
        return flow.startAsync(cached.futurePromise.promise);
      }
    }

    if (textureName === null) {
      textureName = createExtTextureName();
      // FIXME: This is not supported by current `createExtTextureName` implementation
      // var removeFromCacheOnStateChange = unusedArgument;
      // if (removeFromCacheOnStateChange) {
      //   G.extLoader.imagesToRemoveOnStateChange.push(name);
      // }
    }

    const promise = flow.startAsync(
      cache(
        url, textureName,
        extLoad(textureName, url).then(() => textureName),
      ).futurePromise.promise,
    );

    return promise;
  }

  function ensureExtLoaderCreated() {
    if (!G.extLoader) {
      G.extLoader = new G.ExtLoader(game);
      G.extLoader.crossOrigin = 'anonymous';
    }
  }

  function createExtTextureName() {
    if (!G.extImagesKeys) {
      G.extImagesKeys = [];
    }
    const name = `extImgBlankName${G.extImagesKeys.length}`;
    G.extImagesKeys.push(name);
    return name;
  }

  function getCached(url) {
    return G.extLoader.loadInfoPerUrl[url];
  }

  function cache(url, textureName, promise) {
    const futurePromise = G.AsyncUtils.createFuturePromise();
    const loadInfo = {
      textureName,
      futurePromise: futurePromise.read,
    };
    G.extLoader.loadInfoPerUrl[url] = loadInfo;
    promise.then(futurePromise.control.fulfill, futurePromise.control.reject);
    return loadInfo;
  }

  function extLoad(key, url) {
    return new Promise(((fulfill, reject) => {
      const bindings = {
        success: null,
        failure: null,
      };

      act();

      function act() {
        bindings.success = G.extLoader.onFileComplete.add(
          (progress, otherKey, success) => {
            if (key === otherKey) {
              // FIXME: if this can happen synchronously, binding will still be undefined at this point
              detachBindings();
              success ? fulfill() : reject();
            }
          },
        );

        bindings.failure = G.extLoader.onFileError.add(
          (otherKey, file) => {
            if (key === otherKey) {
              // FIXME: if this can happen synchronously, binding will still be undefined at this point
              detachBindings();
              reject();
            }
          },
        );

        G.extLoader.image(key, url, true);
      }

      function detachBindings() {
        if (bindings.success) {
          bindings.success.detach();
          bindings.success = null;
        }
        if (bindings.failure) {
          bindings.failure.detach();
          bindings.failure = null;
        }
      }
    }));
  }
};

G.makeExtImage = function (x, y, url, waitImg, anchor, groupToAdd, tmp, func, callbackOnFail = false) {
  let img;

  G.getExtTexture(
    url, onAsyncDone, null, onGoesAsync, onDoneSynchronously, tmp,
  ).catch((error) => {
    console.warn(`Failed to make ext image from "${url}":`, error);
    if (callbackOnFail && func) {
      func(null);
    }
  });

  function onDoneSynchronously(textureName) {
    img = G.makeImage(x, y, textureName, anchor, groupToAdd);
    func && func.call(img, img);
  }

  function onGoesAsync() {
    img = G.makeImage(x, y, waitImg, anchor, groupToAdd);
  }

  function onAsyncDone(textureName) {
    // in case img was destroyed
    if (img.game !== null) {
      G.changeTexture(img, textureName);
      func && func.call(img, img);
    }
  }

  return img;
};

G.drawCircleSegment = function (gfx, x, y, radius, angleStart, angleFinish, segments) {
  if (angleStart === angleFinish) {
    return gfx;
  }

  if (segments === undefined) { segments = 10; }

  const angleDiff = angleFinish - angleStart;
  const segDiff = angleDiff / segments;

  gfx.moveTo(x, y);
  const { points } = gfx.currentPath.shape;

  for (; angleStart <= angleFinish; angleStart += segDiff) {
    points.push(
      Math.floor(x + G.lengthDirX(angleStart, radius, false)),
      Math.floor(y + G.lengthDirY(angleStart, radius, false)),
    );
  }

  points.push(
    Math.floor(x + G.lengthDirX(angleFinish, radius, false)),
    Math.floor(y + G.lengthDirY(angleFinish, radius, false)),
  );

  gfx.dirty = true;
  gfx._boundsDirty = true;

  return gfx;
};

G.centerElements = function (list, distanceList, center) {
  if (center === undefined) center = 0;
  if (distanceList === undefined) distanceList = [];

  let wholeWidth = 0;
  const isDistArray = Array.isArray(distanceList);

  list.forEach((e, i) => {
    wholeWidth += e.width;
    if (isDistArray ? distanceList[i - 1] : distanceList !== undefined) {
      wholeWidth += Math.floor(isDistArray ? distanceList[i - 1] : distanceList);
    }
  });

  let currentX = center + (wholeWidth * -0.5);

  list.forEach((e, i, a) => {
    e.x = currentX;
    e.x += e.width * e.anchor.x;

    currentX += e.width;
    if (isDistArray ? distanceList[i - 1] : distanceList !== undefined) {
      currentX += Math.floor(isDistArray ? distanceList[i] : distanceList);
    }
  });
};

G.centerElements2 = function (list, distance, center) {
  if (center === undefined) center = 0;
  if (distance === undefined) distance = 0;

  let wholeWidth = 0;

  list.forEach((e, i) => {
    wholeWidth += e.width;
  });

  wholeWidth += distance * (list.length - 1);

  list.forEach((e, i, l) => {
    if (i === 0) {
      e.left = center + (wholeWidth * -0.5);
    } else {
      e.left = l[i - 1].right + distance;
    }
  });
};

/**
 * new centering call for OMT
 * @param {Array} childList
 * @param {number} minItempacing
 * @param {number} maxItempacing
 * @param {number} maxWidth
 */
G.centerElementsInContainer = function (container, minItempacing, maxItempacing, maxWidth, scaleToFit = true) {
  const childList = container.children;
  let child; const
    childCount = childList.length;
  let totalItemsWidth = 0;

  // get total task item with
  for (let i = 0; i < childCount; i++) {
    child = childList[i];
    if (child.totalWidth) {
      totalItemsWidth += child.totalWidth;
    } else {
      totalItemsWidth = child.getBounds().width;
    }
  }

  // calculate item spread distance
  let distanceBetweenTasks = childCount === 0 ? 0 : Math.max((maxWidth - totalItemsWidth) / (childCount - 1), minItempacing);
  if (distanceBetweenTasks > maxItempacing) distanceBetweenTasks = maxItempacing;

  let xPos = 0;
  for (let i = 0; i < childCount; i++) {
    child = childList[i];
    child.x = xPos;
    if (child.totalWidth) {
      xPos += child.totalWidth;
    } else {
      xPos += child.getBounds().width;
    }
    xPos += distanceBetweenTasks;
  }

  // scale elements to fit within maxWidth
  if (scaleToFit) {
    if (container.width > maxWidth) container.width = maxWidth;
    container.x -= container.width / 2;
    container.scale.y = container.scale.x;
  }
};

G.makeMover = function (obj) {
  if (G.activeMover !== undefined) {
    G.activeMover.destroy();
    G.activeMover.eKey.onDown.removeAll();
  }

  G.activeMover = game.add.image();
  G.activeMover.obj = obj;
  G.activeMover.cursors = game.input.keyboard.createCursorKeys();
  G.activeMover.shiftKey = game.input.keyboard.addKey(Phaser.Keyboard.SHIFT);
  G.activeMover.eKey = game.input.keyboard.addKey(Phaser.Keyboard.E);
  G.activeMover.eKey.onDown.add(() => {
  }, G.activeMover);

  G.activeMover.update = function () {
    const moveVal = this.shiftKey.isDown ? 10 : 2;

    if (this.cursors.down.isDown) {
      obj.y += moveVal;
    }

    if (this.cursors.up.isDown) {
      obj.y -= moveVal;
    }

    if (this.cursors.left.isDown) {
      obj.x -= moveVal;
    }

    if (this.cursors.right.isDown) {
      obj.x += moveVal;
    }
  };
};

G.makeLineEditor = function (interpolation) {
  const be = game.add.group();

  be.interpolation = interpolation || 'linear';
  be.pointsX = [0];
  be.pointsY = [0];

  be.gfx = be.add(game.make.graphics());

  be.shiftKey = game.input.keyboard.addKey(Phaser.Keyboard.SHIFT);

  be.wKey = game.input.keyboard.addKey(Phaser.Keyboard.W);
  be.wKey.onDown.add(function () {
    let xx; let
      yy;

    if (this.children.length > 2) {
      xx = this.children[this.children.length - 1].x;
      yy = this.children[this.children.length - 1].y;
    } else {
      xx = 0;
      yy = 0;
    }

    const newPoint = G.makeImage(xx, yy, 'candy_1');
    newPoint.anchor.setTo(0.5);
    newPoint.scale.setTo(0.1);
    this.add(newPoint);
    this.activeObject = newPoint;
    this.changed = true;
  }, be);

  be.qKey = game.input.keyboard.addKey(Phaser.Keyboard.Q);
  be.qKey.onDown.add(function () {
    if (this.children.length <= 2) return;
    this.removeChildAt(this.children.length - 1);
    if (this.children.length > 3) {
      this.activeObject = this.children[this.children.length - 1];
    } else {
      this.activeObject = null;
    }
    this.changed = true;
  }, be);

  be.aKey = game.input.keyboard.addKey(Phaser.Keyboard.A);
  be.aKey.onDown.add(function () {
    if (!this.activeObject) return;
    const index = this.getChildIndex(this.activeObject);
    if (index === 2) return;
    this.activeObject = this.getChildAt(index - 1);
  }, be);

  be.sKey = game.input.keyboard.addKey(Phaser.Keyboard.S);
  be.sKey.onDown.add(function () {
    if (!this.activeObject) return;
    const index = this.getChildIndex(this.activeObject);
    if (index === this.children.length - 1) return;
    this.activeObject = this.getChildAt(index + 1);
  }, be);

  be.eKey = game.input.keyboard.addKey(Phaser.Keyboard.E);
  be.eKey.onDown.add(function () {
    console.log(JSON.stringify([this.pointsX, this.pointsY]));
  }, be);

  be.cursors = game.input.keyboard.createCursorKeys();

  be.activeObject = null;

  be.preview = G.makeImage(0, 0, 'candy_2', 0.5, be);
  be.preview.width = 8;
  be.preview.height = 8;
  be.preview.progress = 0;

  be.update = function () {
    if (this.activeObject === null) return;

    this.forEach(function (e) {
      if (e === this.activeObject) {
        e.alpha = 1;
      } else {
        e.alpha = 0.5;
      }
    }, this);

    if (this.children.length === 0) return;

    const moveVal = this.shiftKey.isDown ? 3 : 1;

    if (this.cursors.down.isDown) {
      this.activeObject.y += moveVal;
      this.changed = true;
    }
    if (this.cursors.up.isDown) {
      this.activeObject.y -= moveVal;
      this.changed = true;
    }
    if (this.cursors.left.isDown) {
      this.activeObject.x -= moveVal;
      this.changed = true;
    }
    if (this.cursors.right.isDown) {
      this.activeObject.x += moveVal;
      this.changed = true;
    }

    be.preview.progress += 0.01;
    if (be.preview.progress > 1) be.preview.progress = 0;
    be.preview.x = game.math[`${this.interpolation}Interpolation`](this.pointsX, be.preview.progress);
    be.preview.y = game.math[`${this.interpolation}Interpolation`](this.pointsY, be.preview.progress);

    if (this.changed) {
      const pointsX = [];
      const pointsY = [];
      this.pointsX = pointsX;
      this.pointsY = pointsY;
      this.children.forEach((e, index) => {
        if (index <= 1) return;
        pointsX.push(e.x);
        pointsY.push(e.y);
      });

      this.gfx.clear();
      this.gfx.beginFill(0xff0000, 1);
      for (let i = 0; i < 200; i++) {
        this.gfx.drawRect(
          game.math[`${this.interpolation}Interpolation`](pointsX, i / 200),
          game.math[`${this.interpolation}Interpolation`](pointsY, i / 200),
          3, 3,
        );
      }
    }
  };

  return be;
};

G.lineUtils = {
  getWholeDistance(pointsX, pointsY) {
    let wholeDistance = 0;
    for (let i = 1; i < pointsX.length; i++) {
      wholeDistance += game.math.distance(pointsX[i - 1], pointsY[i - 1], pointsX[i], pointsY[i]);
    }
    return wholeDistance;
  },

  findPointAtDitance(pointsX, pointsY, dist) {
    let soFar = 0;
    for (let i = 1; i < pointsX.length; i++) {
      const currentDistance = game.math.distance(pointsX[i - 1], pointsY[i - 1], pointsX[i], pointsY[i]);
      if (currentDistance + soFar > dist) {
        const angle = game.math.angleBetween(pointsX[i - 1], pointsY[i - 1], pointsX[i], pointsY[i]);
        return [
          pointsX[i - 1] + G.lengthDirX(angle, dist - soFar, true),
          pointsY[i - 1] + G.lengthDirY(angle, dist - soFar, true),
        ];
      }
      soFar += currentDistance;
    }
    return [pointsX[pointsX.length - 1], pointsY[pointsY.length - 1]];
  },

  spreadAcrossLine(pointsX, pointsY, elementsList, propName1, propName2) {
    const wholeDistance = this.getWholeDistance(pointsX, pointsY);
    const every = wholeDistance / (elementsList.length - 1);

    for (let i = 0; i < elementsList.length; i++) {
      const point = this.findPointAtDitance(pointsX, pointsY, every * i);
      elementsList[i][propName1 || 'x'] = point[0];
      elementsList[i][propName2 || 'y'] = point[1];
    }
  },

  spreadOnNodes(pointsX, pointsY, elementsList, propName1, propName2) {
    for (let i = 0; i < pointsX.length; i++) {
      if (typeof elementsList[i] === 'undefined') return;
      elementsList[i][propName1 || 'x'] = pointsX[i];
      elementsList[i][propName2 || 'y'] = pointsY[i];
    }
  },
};

G.changeSecToTimerFormat = function (sec, forceFormat) {
  const sec_num = parseInt(sec, 10); // don't forget the second param

  const fD = forceFormat ? forceFormat.toUpperCase().indexOf('D') !== -1 : false;
  const fH = forceFormat ? forceFormat.toUpperCase().indexOf('H') !== -1 : false;

  const days = Math.floor(sec_num / 86400);
  const hours = Math.floor((sec_num - (days * 86400)) / 3600);
  const minutes = Math.floor((sec_num - (days * 86400) - (hours * 3600)) / 60);
  const seconds = sec_num - (days * 86400) - (hours * 3600) - (minutes * 60);

  let result = `${G.zeroPad(minutes)}:${G.zeroPad(seconds)}`;

  if (hours > 0 || days > 0 || fH) {
    result = `${G.zeroPad(hours)}:${result}`;
  }

  if (days > 0 || fD) {
    result = `${G.zeroPad(days)}:${result}`;
  }

  return result;
};

G.changeSecToDHMS = function (sec) {
  const secNum = parseInt(sec, 10); // don't forget the second param
  const days = Math.floor(secNum / 86400);
  const hours = Math.floor((secNum - (days * 86400)) / 3600);
  const minutes = Math.floor((secNum - (days * 86400) - (hours * 3600)) / 60);
  const seconds = secNum - (days * 86400) - (hours * 3600) - (minutes * 60);

  return [days, // OMT-6435
    this.zeroPad(hours),
    this.zeroPad(minutes),
    this.zeroPad(seconds)];
};

G.changeSecToHMS = function (sec) {
  const secNum = parseInt(sec, 10); // don't forget the second param
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor((secNum - (hours * 3600)) / 60);
  const seconds = secNum - (hours * 3600) - (minutes * 60);

  return [
    this.zeroPad(hours),
    this.zeroPad(minutes),
    this.zeroPad(seconds)];
};

G.changeSecToMS = function (sec) {
  const secNum = parseInt(sec, 10); // don't forget the second param
  const minutes = Math.floor(secNum / 60);
  const seconds = secNum - (minutes * 60);

  return [
    this.zeroPad(minutes),
    this.zeroPad(seconds)];
};

G.zeroPad = function (number) {
  return number < 10 ? `0${number}` : number;
};

G.makeTextButton = function (x, y, text, style, func, context) {
  const txt = game.make.text(x, y, text, style);
  txt.inputEnabled = true;
  txt.input.useHandCursor = true;
  txt.hitArea = new Phaser.Rectangle(0, 0, txt.width, txt.height);
  txt.events.onInputDown.add(func, context || null);

  return txt;
};

if (typeof G === 'undefined') G = {};

G.Utils = {
  wrapFunction: function wrapFunction(
    obj,
    funcName,
    optCustomFuncBefore,
    optCustomFuncAfter,
    optCustomFunctionInstead,
  ) {
    let preFunc = optCustomFuncBefore;
    let insteadFunc = optCustomFunctionInstead;
    let postFunc = optCustomFuncAfter;
    const originalFunc = obj[funcName];
    obj[funcName] = wrapped;

    return revoke;

    function wrapped(/* arguments */) {
      preFunc && preFunc.apply(this, arguments);

      let result;
      if (insteadFunc) {
        result = insteadFunc.apply(
          this,
          [originalFunc].concat(Array.prototype.slice.call(arguments)),
        );
      } else {
        result = originalFunc.apply(this, arguments);
      }

      postFunc && postFunc.apply(
        this,
        [result, overrideResult].concat(Array.prototype.slice.call(arguments)),
      );

      return result;

      function overrideResult(newResult) {
        result = newResult;
      }
    }

    function revoke() {
      if (obj[funcName] === wrapped) {
        obj[funcName] = originalFunc;
      } else {
        console.warn('Unable to properly revoke function wrapper from %O ("%s")', obj, funcName);
        preFunc = null;
        insteadFunc = null;
        postFunc = null;
      }
    }
  },

  debugPrompt: function utilsPrompt(message) {
    return new Promise(((resolve, reject) => {
      const blocker = document.createElement('div');
      blocker.style.position = 'fixed';
      blocker.style.width = '100%';
      blocker.style.height = '100%';
      blocker.style.top = '0%';
      blocker.style.left = '0%';
      blocker.style.opacity = 0.5;
      blocker.style.backgroundColor = '#000000';

      const popup = document.createElement('div');
      popup.style.position = 'fixed';
      popup.style.width = '300px';
      popup.style.height = '100px';
      popup.style.backgroundColor = 'white';
      popup.style.top = '50%';
      popup.style.left = '50%';
      popup.style.marginLeft = '-100px';
      popup.style.marginTop = '-50px';
      popup.style.padding = '10px';

      const msg = document.createElement('div');
      const txt = document.createTextNode(message);
      msg.appendChild(txt);
      popup.appendChild(msg);
      msg.style.left = '5%';

      const input = document.createElement('input');
      input.style.left = '5%';
      input.style.width = '280px';
      input.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          resolve(input.value);
          destroyDiv();
        } else if (event.key === 'Escape') {
          resolve('');
          destroyDiv();
        }
        event.stopPropagation();
      });
      popup.appendChild(input);

      let btn = document.createElement('button');
      btn.innerHTML = 'cancel';
      btn.style.fontSize = '1.1em';
      // btn.style.top = '45%';
      btn.style.left = '9px';
      btn.style.width = '90px';
      // btn.style.position = 'absolute';
      popup.appendChild(btn);
      btn.onclick = (function () {
        popup.parentElement.removeChild(popup);
        blocker.parentElement.removeChild(blocker);
        resolve('');
      });

      btn = document.createElement('button');
      btn.innerHTML = 'done';
      btn.style.fontSize = '1.1em';
      // btn.style.top = '45%';
      btn.style.left = '104px';
      btn.style.width = '90px';
      btn.style.position = 'absolute';
      popup.appendChild(btn);
      btn.onclick = (function () {
        resolve(input.value);
        destroyDiv();
      });

      document.body.appendChild(blocker);
      document.body.appendChild(popup);
      input.focus();

      function destroyDiv() {
        popup.parentElement.removeChild(popup);
        blocker.parentElement.removeChild(blocker);
      }
    }));
  },

  /**
   * create a yes / no confirm promp
   */
  confirmPrompt: function confirmPrompt(message, callback) {
    return new Promise((resolve) => {
      const blocker = document.createElement('div');
      blocker.style.position = 'fixed';
      blocker.style.width = '100%';
      blocker.style.height = '100%';
      blocker.style.top = '0%';
      blocker.style.left = '0%';
      blocker.style.opacity = 0.5;
      blocker.style.backgroundColor = '#000000';

      const popup = document.createElement('div');
      popup.style.position = 'fixed';
      popup.style.width = '200px';
      popup.style.height = '100px';
      popup.style.backgroundColor = 'white';
      popup.style.top = '50%';
      popup.style.left = '50%';
      popup.style.marginLeft = '-100px';
      popup.style.marginTop = '-50px';
      popup.style.padding = '10px';

      const msg = document.createElement('div');
      const txt = document.createTextNode(message);
      msg.style.top = '10%';
      msg.style.left = '10%';
      msg.style.position = 'absolute';
      msg.style.width = '80%';
      msg.style.textAlign = 'center';
      msg.appendChild(txt);
      popup.appendChild(msg);

      let btn = document.createElement('button');
      btn.innerHTML = 'NO';
      btn.style.fontSize = '1.2em';
      btn.style.top = '60%';
      btn.style.left = '23%';
      btn.style.position = 'absolute';
      popup.appendChild(btn);
      btn.onclick = (function () {
        popup.parentElement.removeChild(popup);
        blocker.parentElement.removeChild(blocker);
        resolve(false);
      });

      btn = document.createElement('button');
      btn.innerHTML = 'YES';
      btn.style.fontSize = '1.2em';
      btn.style.top = '60%';
      btn.style.right = '26%';
      btn.style.position = 'absolute';
      btn.onclick = (function () {
        popup.parentElement.removeChild(popup);
        blocker.parentElement.removeChild(blocker);
        resolve(true);
      });
      popup.appendChild(btn);

      document.body.appendChild(blocker);
      document.body.appendChild(popup);
    });
  },

  /**
   * create a mock alert
   */
  alertPrompt: function alertPrompt(message, callback) {
    return new Promise((resolve) => {
      const blocker = document.createElement('div');
      blocker.style.position = 'fixed';
      blocker.style.width = '100%';
      blocker.style.height = '100%';
      blocker.style.top = '0%';
      blocker.style.left = '0%';
      blocker.style.opacity = 0.5;
      blocker.style.backgroundColor = '#000000';

      const popup = document.createElement('div');
      popup.style.position = 'fixed';
      popup.style.width = '300px';
      popup.style.height = '100px';
      popup.style.backgroundColor = 'white';
      popup.style.top = '50%';
      popup.style.left = '50%';
      popup.style.marginLeft = '-100px';
      popup.style.marginTop = '-50px';
      popup.style.padding = '10px';

      const msg = document.createElement('div');
      const txt = document.createTextNode(message);
      msg.style.top = '10%';
      msg.style.left = '10%';
      msg.style.position = 'absolute';
      msg.style.width = '80%';
      msg.style.textAlign = 'center';
      msg.appendChild(txt);
      popup.appendChild(msg);

      btn = document.createElement('button');
      btn.innerHTML = 'OK';
      btn.style.fontSize = '1.2em';
      btn.style.top = '60%';
      btn.style.right = '50%';
      btn.style.transform = 'translateX(50%)';
      btn.style.position = 'absolute';
      btn.onclick = (function () {
        popup.parentElement.removeChild(popup);
        blocker.parentElement.removeChild(blocker);
        resolve(true);
      });
      popup.appendChild(btn);

      document.body.appendChild(blocker);
      document.body.appendChild(popup);
    });
  },

  resetInput: function resetInput(game) {
    game.input.pointers.forEach((pointer) => {
      pointer.active = false;
    });
  },

  /**
   * @param {Array<T>} arr Array of elements to randomly return
   * @param {(element: T) => Number} getWeightFunc
   * @returns {T}
   */
  getRandomWeightedElement: function getRandomWeightedElement(arr, getWeightFunc) {
    const sum = arr.reduce((total, element) => total + getWeightFunc(element), 0);

    let rnd = game.rnd.realInRange(0, 1) * sum;

    for (let index = 0, len = arr.length; index < len; index++) {
      rnd -= getWeightFunc(arr[index]);
      if (rnd <= 0) {
        return arr[index];
      }
    }
  },

  /**
   * @param {number} min
   * @param {number} max
   * @returns {number} an integer between min and max inclusive
   */
  getRandomInteger: function getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  },

  setIgnoreChildrenRec: function setIgnoreChildrenRec(obj, ignore) {
    obj.ignoreChildInput = ignore;
    if (obj.children) {
      obj.children.forEach((child) => { setIgnoreChildrenRec(child, ignore); });
    }
  },

  warnDeprecationUsage: function warnDeprecationUsage(message) {
    // eslint-disable-next-line no-debugger
    debugger;
    console.error(message);
    // try { G.Utils.SentryLog.logError(message); } catch (e) {}
  },

  loadScript: function loadScript(url) {
    return new Promise(((fulfill, reject) => {
      const script = document.createElement('script');
      script.onload = fulfill;
      script.onerror = reject;
      script.src = url;
      document.head.appendChild(script);
    }));
  },

  clone: function clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  },

  // detach bindings when object is destroyed
  cleanUpOnDestroy: function cleanUpOnDestroy(obj, binding) {
    let onDestroySignal;

    if (obj instanceof Phaser.Group) {
      onDestroySignal = obj.onDestroy;
    } else {
      onDestroySignal = obj.events.onDestroy;
    }

    onDestroySignal.addOnce(binding.detach, binding);

    return binding;
  },

  cacheText(cacheLabel, txt, font, fontSize, tint) {
    const txtFld = game.make.bitmapText(0, 0, font, txt, fontSize);
    txtFld.tint = tint || 0xffffff;
    txtFld.updateCache();

    const rt = game.make.renderTexture(txtFld.width, txtFld.height, cacheLabel, true);
    rt.render(txtFld);

    txtFld.destroy();
  },

  cacheGText(cacheLabel, txt, style) {
    const txtFld = new G.Text(0, 0, txt, style, 0);
    const rt = game.make.renderTexture(txtFld.width, txtFld.height, cacheLabel, true);
    rt.render(txtFld);
    txtFld.destroy();
  },

  lerp(valCurrent, valTarget, lerp, snapRange) {
    if (snapRange && Math.abs(valCurrent - valTarget) <= snapRange) {
      return valTarget;
    }
    return valCurrent + lerp * (valTarget - valCurrent);
  },

  copyToClipboard(text) {
    if (!this.copyArea) {
      this.copyArea = document.createElement('textarea');
      this.copyArea.style.positon = 'fixed';
      this.copyArea.style.opacity = 0;
      document.body.appendChild(this.copyArea);
    }

    this.copyArea.value = text;
    this.copyArea.select();
    document.execCommand('copy');
  },

  replaceAll(string, search, replacement) {
    return string.split(search).join(replacement);
  },

  getParentsScaleX(obj, rec) {
    if (obj === game.stage) {
      return 1;
    }
    return G.Utils.getParentsScaleX(obj.parent, true) * (!rec ? 1 : obj.scale.x);
  },

  getParentsScaleY(obj, rec) {
    if (obj === game.stage) {
      return 1;
    }
    return G.Utils.getParentsScaleY(obj.parent, true) * (!rec ? 1 : obj.scale.y);
  },

  makeTextButton(x, y, label, func, context, style) {
    const txt = game.add.text(x, y, label, style);
    txt.inputEnabled = true;
    txt.input.useHandCursor = true;
    txt.hitArea = new Phaser.Rectangle(0, 0, txt.width, txt.height);
    txt.events.onInputDown.add(func, context);
    return txt;
  },

  injectCSS(css) {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = css;
    document.getElementsByTagName('head')[0].appendChild(style);
  },

  toClientX(ingameX) {
    const marginLeft = parseInt(game.canvas.style.marginLeft) || 0;
    return marginLeft + (ingameX / game.width) * game.canvas.clientWidth;
  },

  toClientY(ingameY) {
    const marginTop = parseInt(game.canvas.style.marginTop) || 0;
    return marginTop + (ingameY / game.height) * game.canvas.clientHeight;
  },

  clientXToWorldX(clientX) {
    const marginLeft = parseInt(game.canvas.style.marginLeft) || 0;

    clientX -= marginLeft;
    const canvasStyleWidth = parseInt(game.canvas.style.width);
    const canvasStyleHeight = parseInt(game.canvas.style.height);
    const canvasContextWidth = parseInt(game.canvas.width);
    const canvasContextHeight = parseInt(game.canvas.height);

    const ratio = canvasContextWidth / canvasStyleWidth;

    return clientX * ratio;
  },

  clientYToWorldY(clientY) {
    const marginTop = parseInt(game.canvas.style.marginTop) || 0;

    clientY -= marginTop;
    const canvasStyleWidth = parseInt(game.canvas.style.width);
    const canvasStyleHeight = parseInt(game.canvas.style.height);
    const canvasContextWidth = parseInt(game.canvas.width);
    const canvasContextHeight = parseInt(game.canvas.height);

    const ratio = canvasContextHeight / canvasStyleHeight;

    return clientY * ratio;
  },

  isWorldVisible: function isWorldVisible(object) {
    let temp = object;
    while (temp) {
      if (!temp.visible) return false;
      temp = temp.parent;
    }
    return true;
  },

  getImageURI(img) {
    if (!this._bmpMarker) this._bmpMarker = G.makeImage(0, 0, null, 0, null);
    if (!this._bmp) this._bmp = game.make.bitmapData();

    this._bmp.clear();
    G.changeTexture(this._bmpMarker, img);
    this._bmp.resize(this._bmpMarker.width, this._bmpMarker.height);
    this._bmp.draw(this._bmpMarker);
    return this._bmp.canvas.toDataURL();
  },

  getRT(rtName) {
    return game.cache.getRenderTexture(rtName).texture;
  },

  find1stObjectWithName(parent, name) {
    if (!parent) return null;
    if (parent.name === name) return parent;
    if (parent.children) {
      for (let i = 0; i < parent.children.length; ++i) {
        const result = G.Utils.find1stObjectWithName(parent.children[i], name);
        if (result) return result;
      }
    }
    return null;
  },

  debugError: function debugError(/* arguments */) {
    console.error.apply(console, arguments);
    let localhost = false;
    if (window && window.location && window.location.hostname === 'localhost') {
      localhost = true;
    }
    if (localhost) {
      eval('debugger');
    }
  },

  defined: function defined(/* arguments */) {
    for (let i = 0; i < arguments.length; ++i) {
      if (arguments[i] !== undefined) return arguments[i];
    }
  },
};

G.lineCircleColl = function (LINE, C, point) {
  const A = LINE.start;
  const B = LINE.end;

  const LAB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));

  const Dx = (B.x - A.x) / LAB;
  const Dy = (B.y - A.y) / LAB;

  const t = Dx * (C.x - A.x) + Dy * (C.y - A.y);

  const Ex = t * Dx + A.x;
  const Ey = t * Dy + A.y;

  const LEC = Math.sqrt(Math.pow(Ex - C.x, 2) + Math.pow(Ey - C.y, 2));

  if (LEC < C.radius) {
    const dt = Math.sqrt((C.radius * C.radius) - (LEC * LEC));

    const Fx = (t - dt) * Dx + A.x;
    const Fy = (t - dt) * Dy + A.y;

    const Gx = (t + dt) * Dx + A.x;
    const Gy = (t + dt) * Dy + A.y;

    const FtoLength = game.math.distance(A.x, A.y, Fx, Fy);
    const GtoLength = game.math.distance(A.x, A.y, Gx, Gy);

    if (FtoLength < GtoLength) {
      if (LINE.length > FtoLength) {
        point.setTo(Fx, Fy);
        return point;
      }
      return false;
    }
    if (LINE.length > GtoLength) {
      point.setTo(Gx, Gy);
      return point;
    }
    return false;
  }
  return false;
};

G.getRT = function (rtName) {
  const rt = game.cache.getRenderTexture(rtName);
  if (!rt) return null;
  return rt.texture;
};

G.numberDot = function (price) {
  price = price.toString();
  let result = '';

  let n = 0;
  for (let i = price.length - 1; i >= 0; i--) {
    result = price[i] + result;
    n++;
    if (n === 3 && i !== 0) {
      result = `.${result}`;
      n = 0;
    }
  }

  return result;
};

G.guid = function () {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${
    s4()}-${s4()}${s4()}${s4()}`;
};
