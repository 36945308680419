/* eslint-disable guard-for-in */
/* eslint-disable no-use-before-define */
/* eslint-disable object-curly-newline */

import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { OMT_TransformAnimationController } from '@omt-components/Utils/Animation/OMT_TransformAnimationController';
import { friendInviteFeedback } from '../../Elements/Helpers/friendInviteFeedback';
import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

import {
  INVITE_PANEL_TRANSITION_DURATION,
  INVITE_PANEL_TRANSITION_FADE_DURATION,
  INVITE_PANEL_EXPANDED_BUTTON_POS,
  INVITE_PANEL_EXPANDED_BUTTON_DIMENSIONS,
  INVITE_PANEL_EXPANDED_BUTTON_TEXT_STYLE,
  INVITE_PANEL_COLLAPSED_BUTTON_POS,
  INVITE_PANEL_COLLAPSED_BUTTON_TEXT_STYLE,
  INVITE_PANEL_COLLAPSED_BUTTON_TEXT_POS,
  INVITE_PANEL_MASCOT_POS,
  INVITE_PANEL_MASCOT_SCALE,
  INVITE_PANEL_MASCOT_FRAME,
  INVITE_PANEL_MASCOT_SHOW_DURATION,
  INVITE_PANEL_MASCOT_SHOW_DELAY,
  INVITE_PANEL_MASCOT_HIDE_DURATION,
} from '../MainLeaderboardSettings';


/**
 * Class containing the leaderboard invite buttons and handling invite button state transistions
 */
export class MainLeaderboardInviteButtonsPanel extends Phaser.Group {
  /**
   * constructor
   */
  constructor() {
    super(game);

    this._collapsedInviteButton = new CollapsedInviteButton(() => { this._onInviteButtonClicked(); });
    this._collapsedInviteButton.x = INVITE_PANEL_COLLAPSED_BUTTON_POS.x; this._collapsedInviteButton.y = INVITE_PANEL_COLLAPSED_BUTTON_POS.y;
    this.addChild(this._collapsedInviteButton);

    this._expandedInviteButton = new ExpandedInviteButton(() => { this._onInviteButtonClicked(); });
    this._expandedInviteButton.x = INVITE_PANEL_EXPANDED_BUTTON_POS.x; this._expandedInviteButton.y = INVITE_PANEL_EXPANDED_BUTTON_POS.y;
    this._expandedInviteButton.visible = true;
    this._expandedInviteButton.alpha = 0;
    this.addChild(this._expandedInviteButton);

    this._collapsedActiveTransform = this._collapsedInviteButton.animationController.getTranformSnapshot(['x', 'y', 'width', 'height']);
    this._expandedActiveTransform = this._expandedInviteButton.animationController.getTranformSnapshot(['x', 'y', 'width', 'height']);
  }

  /**
   * called when either of the invite buttons is clicked
   */
  _onInviteButtonClicked() {
    OMT.social.chooseContextAndInvite().then((result) => {
      if (result) {
        // show invite feedback ripped form old leaderboard
        // eslint-disable-next-line new-cap
        const feedback = new friendInviteFeedback(G.game, null);
        feedback.x = game.world.bounds.x + game.width / 2;
        feedback.y = feedback.height / 2;
        game.world.addChild(feedback);
        feedback.enterAndSelfDestroy();

        // tracking
        // DDNA.socialTracker.incrementParam('numFriendsInvites', 1);
        // DDNA.tracking.socialActionEvent('Invite', '', '', '');
        const inviteSourceId = this._collapsedInviteButton ? OMT.platformTracking.Events.InviteFromLeaderboardCollapsed : OMT.platformTracking.Events.InviteFromLeaderboardExpanded;
        OMT.platformTracking.logEvent(inviteSourceId);
      }
    });
  }

  /**
   * animate to the collapsed state
   */
  animateToCollapsedState() {
    const animationDuration = INVITE_PANEL_TRANSITION_DURATION;
    const fadeDuration = INVITE_PANEL_TRANSITION_FADE_DURATION;

    this._collapsedInviteButton.visible = true;
    this._collapsedInviteButton.alpha = 0;
    this._collapsedInviteButton.animationController.stopAllTweens();
    this._collapsedInviteButton.animationController.applyTransform(this._expandedActiveTransform);
    this._collapsedInviteButton.animationController.animateToSnapshot(this._collapsedActiveTransform, Phaser.Easing.Custom.SoftBounce, animationDuration, 0);
    this._collapsedInviteButton.animationController.animateToSnapshot({ alpha: 1 }, Phaser.Easing.Quadratic.Out, fadeDuration, 0);

    this._expandedInviteButton.animationController.stopAllTweens();
    this._expandedInviteButton.animationController.animateToSnapshot(this._collapsedActiveTransform, Phaser.Easing.Custom.SoftBounce, animationDuration, 0);
    this._expandedInviteButton.animationController.animateToSnapshot({ alpha: 0 }, Phaser.Easing.Quadratic.Out, fadeDuration, 0, () => { this._expandedInviteButton.visible = false; });
    this._expandedInviteButton.pauseLoopAnimation();
    this._expandedInviteButton.hideMascot();
  }

  /**
   * animate to the expanded state
   */
  animateToExpandedState() {
    const animationDuration = INVITE_PANEL_TRANSITION_DURATION;
    const fadeDuration = INVITE_PANEL_TRANSITION_FADE_DURATION;

    this._expandedInviteButton.visible = true;
    this._expandedInviteButton.alpha = 0;
    this._expandedInviteButton.animationController.stopAllTweens();
    this._expandedInviteButton.animationController.applyTransform(this._collapsedActiveTransform);
    this._expandedInviteButton.animationController.animateToSnapshot(this._expandedActiveTransform, Phaser.Easing.Custom.SoftBounce, animationDuration, 0);
    this._expandedInviteButton.animationController.animateToSnapshot({ alpha: 1 }, Phaser.Easing.Quadratic.Out, fadeDuration, 0);
    this._expandedInviteButton.resumeLoopAnimation();
    this._expandedInviteButton.showMascot();

    this._collapsedInviteButton.animationController.stopAllTweens();
    this._collapsedInviteButton.animationController.animateToSnapshot(this._expandedActiveTransform, Phaser.Easing.Custom.SoftBounce, animationDuration, 0);
    this._collapsedInviteButton.animationController.animateToSnapshot({ alpha: 0 }, Phaser.Easing.Quadratic.Out, fadeDuration, 0, () => { this._collapsedInviteButton.visible = false; });
  }
}

/**
 * Class for the collapsed view invite button
 */
export class CollapsedInviteButton extends UI_GButtonES6 {
  /**
   * constructor
   * @param {Function} onClicked on clicked callback
   */
  constructor(onClicked) {
    super(0, 0, null, onClicked);
    this._initBgImage();
    this._initButtonLabel();
    this._initAnimationController();
  }

  /**
   * init the animation controller
   */
  _initAnimationController() {
    this._animationController = new OMT_TransformAnimationController(this);
  }

  /**
   * get the animation controller
   * @returns {OMT_TransformAnimationController}
   */
  get animationController() {
    return this._animationController;
  }

  /**
   * init the button background
   */
  _initBgImage() {
    this._bgImage = G.makeImage(0, 0, 'btn_start_goal', [0.5, 0.5], this);
    this._bgImage.scale.set(0.7);
    this.addChild(this._bgImage);
  }

  /**
   * init the button label
   */
  _initButtonLabel() {
    const maxWidth = this._bgImage.width * 0.8;
    const maxHeight = this._bgImage.height / 2;
    this._buttonLabel = new G.Text(0, 0, OMT.language.getText('Add Friends'), INVITE_PANEL_COLLAPSED_BUTTON_TEXT_STYLE, [0.5, 0.5], maxWidth, maxHeight, true, 'center');
    this._buttonLabel.x = INVITE_PANEL_COLLAPSED_BUTTON_TEXT_POS.x;
    this._buttonLabel.y = INVITE_PANEL_COLLAPSED_BUTTON_TEXT_POS.y;
    this.addChild(this._buttonLabel);
  }

  /**
   * set the width
   * @param {number} value
   */
  set width(value) {
    this.scale.x = value / this.width;
  }

  /**
   * get the width
   * @returns {number}
   */
  get width() {
    return this._bgImage.width;
  }

  /**
   * set the height
   * @param {number} value
   */
  set height(value) {
    this.scale.y = value / this.height;
  }

  /**
   * get the height
   * @returns {number}
   */
  get height() {
    return this._bgImage.height;
  }

  /**
   * destruction method
   */
  destroy() {
    this._animationController.destroy();
    super.destroy();
  }
}

/**
 * Class for the expanded view invite button
 */
export class ExpandedInviteButton extends Phaser.Group {
  /**
   * constructor
   * @param {Function} onClicked on clicked callback
   */
  constructor(onClicked) {
    super(game);
    this._initButtonWithLoopAnimation(onClicked);
    this._initAnimationController();
    this._initMascotImage();
  }

  /**
   * init button with looped attract animation
   * @param {Function} onClicked on clicked callback
   */
  _initButtonWithLoopAnimation(onClicked) {
    // create 9 scale button assets
    const buttonScale = 2;
    this._button = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.lightGreen,
        dimensions: {
          width: INVITE_PANEL_EXPANDED_BUTTON_DIMENSIONS.width * buttonScale,
          height: INVITE_PANEL_EXPANDED_BUTTON_DIMENSIONS.height * buttonScale,
        },
      },
      text: {
        string: OMT.language.getText('Add More Friends'),
        style: INVITE_PANEL_EXPANDED_BUTTON_TEXT_STYLE,
      },
      options: {
        cacheButton: false,
        clickFunction: {
          scaleOnClick: false,
          onClick: onClicked,
        },
      },
    });
    this._button.scale.set(1 / buttonScale);
    this.addChild(this._button);

    // set button loop animation
    const animation = G.Animations.Generic.getSquishSquashAnimation(this._button.scale.x);
    this._loopTween = animation.animate(game, this._button);
  }

  /**
   * pause loop animation
   */
  pauseLoopAnimation() {
    this._loopTween.pause();
  }

  /**
   * resume loop animation
   */
  resumeLoopAnimation() {
    this._loopTween.resume();
  }

  /**
   * init the animation controller
   */
  _initAnimationController() {
    this._animationController = new OMT_TransformAnimationController(this);
  }

  /**
   * get the animation controller
   * @returns {OMT_TransformAnimationController}
   */
  get animationController() {
    return this._animationController;
  }

  /**
   * init the mascot image or any other fluff
   */
  _initMascotImage() {
    this._mascotImage = G.makeImage(0, 0, INVITE_PANEL_MASCOT_FRAME, [0.5, 0.5]);
    this._mascotImage.x = INVITE_PANEL_MASCOT_POS.x;
    this._mascotImage.y = INVITE_PANEL_MASCOT_POS.y + this._mascotImage.height;
    this._mascotImage.scale.set(INVITE_PANEL_MASCOT_SCALE);
    this.addChildAt(this._mascotImage, 0);
  }

  /**
   * show the mascot
   */
  showMascot() {
    if (this._mascotTween) this._mascotTween.stop();
    this._mascotTween = game.add.tween(this._mascotImage);
    this._mascotTween.to({ y: INVITE_PANEL_MASCOT_POS.y }, INVITE_PANEL_MASCOT_SHOW_DURATION, Phaser.Easing.Custom.SoftBounce, false, INVITE_PANEL_MASCOT_SHOW_DELAY);
    this._mascotTween.start();
  }

  /**
   * hide the mascot
   */
  hideMascot() {
    if (this._mascotTween) this._mascotTween.stop();
    this._mascotTween = game.add.tween(this._mascotImage);
    this._mascotTween.to({ y: INVITE_PANEL_MASCOT_POS.y + this._mascotImage.height }, INVITE_PANEL_MASCOT_HIDE_DURATION, Phaser.Easing.Quadratic.Out, false);
    this._mascotTween.start();
  }

  /**
   * set the width
   * @param {number} value
   */
  set width(value) {
    this.scale.x = value / this.width;
  }

  /**
   * get the width
   * @returns {number}
   */
  get width() {
    return this._button.config.button.dimensions.width * this._button.scale.x;
  }

  /**
   * set the height
   * @param {number} value
   */
  set height(value) {
    this.scale.y = value / this.height;
  }

  /**
   * get the height
   * @returns {number}
   */
  get height() {
    return this._button.config.button.dimensions.height * this._button.scale.y;
  }

  /**
   * destruction method
   */
  destroy() {
    if (this._loopTween) this._loopTween.stop();
    if (this._mascotTween) this._mascotTween.stop();
    this._animationController.destroy();
    super.destroy();
  }
}
