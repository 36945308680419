import { Window } from '../../../../00_IMMEDIATE/Window';
import { AskFriendsListView } from './AskFriendsListView';
import { AskFriendsStatusHeader } from './AskFriendsStatusHeader';
import { ASK_FRIENDS_REQUIRED_FRIENDS, ASK_FRIENDS_COOLDOWN_ID } from '../../../../Services/OMT/ads/OMT_Ads';
import { OMT_UI_InviteMoreFriendsButton } from '../../../../OMT_UI/Buttons/OMT_UI_InviteMoreFriendsButton';
import { OMT_UI_AvatarWithFrame } from '../../../../OMT_UI/FriendsList/OMT_UI_AvatarWithFrame';
import { friendInviteFeedback } from '../../../Helpers/friendInviteFeedback';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../../OMT_UI/OMT_UI_SquareButton';

const BG_WIDTH = 580;
const BG_HEIGHT = 690;

const SCROLL_AREA_RECT = new Phaser.Rectangle(-250, -95, 500, 300);
const ITEM_HEIGHT = SCROLL_AREA_RECT.height / 4;

const STATUS_HEADER_SEGMENT_WIDTH = 140;
const STATUS_HEADER_INDICATOR_RADIUS = 20;
const STATUS_HEADER_Y = -135;
const STATUS_HEADER_CONNECT_COLOR = 0x008aca;
const STATUS_HEADER_INACTIVE_CONNECT_COLOR = 0xb7bdb9;
const STATUS_HEADER_LABEL_Y = STATUS_HEADER_Y - 70;

const TITLE_Y = -320;
const NO_THANKS_BUTTON_Y = 410;
const CLOSE_BUTTON_X = 260;
const CLOSE_BUTTON_Y = -315;
const CLOSE_BUTTON_ROTATION = 0;
const INVITE_BUTTON_Y = 260;

const COOL_DOWN_DURATION = 5 * 60 * 1000;
const SUCCESS_EXECUTE_DELAY = 500;

const DISABLED_MSG_Y = -90;
const DISABLED_FRIEND_STATUS_Y = 200;
const DISABLED_AVATAR_Y = 30;
const DISABLED_AVATAR_SIZE = 80;
const DISABLED_AVATAR_SPACING = 110;

/**
 * class for ask friends rewarded ad fallback
 */
class Window_FallbackAskFriends extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Function} onSuccess
   * @param {Function} onRetry
   */
  constructor(parent, onSuccess, onRetry) {
    super(parent);

    this._onSuccess = onSuccess;
    this._onRetry = onRetry;

    this._init();
  }

  /**
   * init is async as we need the friends list
   */
  async _init() {
    this._requiredAsks = ASK_FRIENDS_REQUIRED_FRIENDS;
    // check if we have enough friends to properly use this feature.
    // const friendsList = [(await OMT.friends.getFriendsList())[0]];
    const friendsList = await OMT.friends.getFriendsList();
    const totalFriends = friendsList.length;
    const featureIsActive = totalFriends >= this._requiredAsks;

    this.addGeneric9SliceBackground(BG_WIDTH, BG_HEIGHT);
    this._initTitleGroup();

    if (featureIsActive) {
      this._initAskFriendsStatusHeader();
      this._initStatusHeaderLabel();
      this._initFriendsListView();
      const askCount = G.saveState.adFallback_askFriends_getAskCount();
      this._statusHeader.setActiveIndicators(askCount);
    } else {
      this._initFeatureDisabledContent(friendsList);
    }

    this._initCloseButton();
    this._initNoThanksButton();
    this._initInviteButton();
  }

  /**
   * init title content group
   */
  _initTitleGroup() {
    const titleConfig = G.OMTsettings.adFallback_AskFriend && G.OMTsettings.adFallback_AskFriend.title ? G.OMTsettings.adFallback_AskFriend.title : {};
    const titleGroup = new Phaser.Group(game);
    titleGroup.y = TITLE_Y;
    const style = titleConfig.style || { style: 'font-white', fontSize: 40 };
    const titleImage = G.makeImage(0, 0, 'daily_rewards_ribbon', 0.5, null);
    titleImage.scale.set(0.85);
    const titleTxt = OMT.language.getText('No ads available');
    const title = new G.Text(0, 0, titleTxt, style, 0.5, titleImage.width * 0.9, titleImage.height * 0.9, true, 'center');
    title.y += titleConfig.offsetY || 0;
    titleGroup.addChild(titleImage);
    titleGroup.addChild(title);
    this.addChildAt(titleGroup, 1);
  }

  /**
   * init the header lobel text
   */
  _initStatusHeaderLabel() {
    const style = { style: 'font-blue', fontSize: 35 };
    const txt = OMT.language.getText(unescape('But don\'t worry, ask %Number% friends for help to collect your reward.')).replace('%Number%', this._requiredAsks);
    const title = new G.Text(0, STATUS_HEADER_LABEL_Y, txt, style, [0.5, 0.5], 450, 70, true, 'center');
    //this.addChild(title);
  }

  /**
   * set the alternate content / layout for when you have less then the required friend amount.
   * @param {Array.<Object>} friendsList
   */
  _initFeatureDisabledContent(friendsList) {
    const totalFriends = friendsList.length;
    let style;
    let txt;

    // notification text
    style = { style: 'font-orange', fontSize: 27 };
    //txt = OMT.language.getText('You need at least %Number% friends to ask for help to collect your reward.').replace('%Number%', this._requiredAsks);
    txt = OMT.language.getText("No ads available");
    const disabledMsgText = new G.Text(0, DISABLED_MSG_Y, txt, style, [0.5, 0.5], 450, 80, true, 'center');
    this.addChild(disabledMsgText);

    // status text
    style = { style: 'font-blue', fontSize: 27 };
    txt = OMT.language.getText('Friends playing %GameName%:');
    txt += ` ${totalFriends}/${this._requiredAsks}`;
    const friendStatusText = new G.Text(0, DISABLED_FRIEND_STATUS_Y, txt, style, [0.5, 0.5], 450, 60, true, 'center');
    //this.addChild(friendStatusText);

    // avatar preview
    const avatarGroup = new Phaser.Group(game);
    let avatar;
    let avatarImg;
    for (let i = 0; i < this._requiredAsks; i++) {
      avatarImg = friendsList.length >= i + 1 ? friendsList[i].image : null;
      avatar = new OMT_UI_AvatarWithFrame(avatarImg, DISABLED_AVATAR_SIZE);
      avatar.x = DISABLED_AVATAR_SPACING * i;
      avatarGroup.addChild(avatar);
    }
    avatarGroup.x = -(avatarGroup.width / 2) + (DISABLED_AVATAR_SIZE / 2);
    avatarGroup.y = DISABLED_AVATAR_Y;
   // this.addChild(avatarGroup);
  }

  /**
   * init the close button
   */
  _initCloseButton() {
    this._closeButton = new G.Button(CLOSE_BUTTON_X, CLOSE_BUTTON_Y, 'btn_x', () => {
      this.disableAllInteractivity();
      this.closeWindow();
    }, this);
    this.registerButtons(this._closeButton);
    this._closeButton.rotation = CLOSE_BUTTON_ROTATION;
  }

  /**
   * init the no thanks / retry button
   */
  _initNoThanksButton() {
    const allowRetry = this._onRetry !== null;

    const onClick = () => {
      this.disableAllInteractivity();
      if (allowRetry) { // is retry
        this.closeWindow(() => {
          this._onRetry();
        });
      } else { // is no thanks
        this.closeWindow();
      }
    };
    this._noThanksButton = new OMT_UI_SquareButton(0, NO_THANKS_BUTTON_Y, {
      button: {
        tint: allowRetry ? BUTTONCOLOURS.orange : BUTTONCOLOURS.red,
        dimensions: {
          width: 176,
          height: 90,
        },
      },
      text: {
        string: OMT.language.getText(allowRetry ? 'Retry Ad' : 'No, thanks'),
        textStyle: {
          style: 'font-white',
          fontSize: 25,
          lineSpacing: -10,
        },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
          disableAfterClick: true,
        },
      },
    });

    this.addChild(this._noThanksButton);
    this.registerButtons(this._noThanksButton);
  }

  /**
   * init the invite button
   */
  _initInviteButton() {
    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenAdFallbackInviteBtn', 1);
    const inviteButton = new OMT_UI_InviteMoreFriendsButton(async () => {
      const res = await OMT.social.chooseContextAndInvite();
      if (res) {
        // DDNA.socialTracker.incrementParam('numFriendsInvites', 1);
        // DDNA.tracking.socialActionEvent('Invite', '', '', '');
        // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('usedAdFallbackInviteBtn', 1);
        // eslint-disable-next-line new-cap
        const feedback = new friendInviteFeedback(G.game, null);
        feedback.x = game.world.bounds.x + (game.width / 2);
        feedback.y = feedback.height / 2;
        game.world.addChild(feedback);

        feedback.enterAndSelfDestroy();
      }
    });
    inviteButton.scale.set(0.85);
    inviteButton.x = -10;
    inviteButton.y = INVITE_BUTTON_Y;
   // this.addChild(inviteButton);
    this._inviteButton = inviteButton;
  }

  /**
   * init the status header for asking friends
   */
  _initAskFriendsStatusHeader() {
    const headerWidth = STATUS_HEADER_SEGMENT_WIDTH * (this._requiredAsks - 1);
    this._statusHeader = new AskFriendsStatusHeader(
      this._requiredAsks, headerWidth, STATUS_HEADER_INDICATOR_RADIUS,
      STATUS_HEADER_CONNECT_COLOR, STATUS_HEADER_INACTIVE_CONNECT_COLOR,
    );
    if (this._requiredAsks > 1) this._statusHeader.x = -headerWidth / 2;
    this._statusHeader.y = STATUS_HEADER_Y;
    this.addChild(this._statusHeader);
  }

  /**
   * init the friends list view
   */
  _initFriendsListView() {
    const cooldownDuration = COOL_DOWN_DURATION;
    this._friendsListView = new AskFriendsListView(ITEM_HEIGHT, SCROLL_AREA_RECT, cooldownDuration, ASK_FRIENDS_COOLDOWN_ID);
    this._friendsListView.signals.onAskFriend.add(this.onAskFriend.bind(this));
    //this.addChild(this._friendsListView);
  }

  /**
   * on friend asked for help from AskFriendsListView
   */
  onAskFriend() {
    return;
    const askCount = G.saveState.adFallback_askFriends_getAskCount() + 1;
    if (askCount > this._requiredAsks) return;

    this._statusHeader.setActiveIndicators(askCount);

    if (askCount === this._requiredAsks) {
      G.saveState.adFallback_askFriends_setAskCount(0);
      this._onFallbackSuccess();
    } else {
      G.saveState.adFallback_askFriends_setAskCount(askCount);
    }
  }

  /**
   * on fallback success close dialog and continue
   */
  _onFallbackSuccess() {
    this.disableAllInteractivity();
    setTimeout(() => {
      this.closeWindow(() => {
        if (this._onSuccess) this._onSuccess();
      });
    }, SUCCESS_EXECUTE_DELAY);
  }

  /**
   * disable all user interactivity
   */
  disableAllInteractivity() {
    this._closeButton.active = false;
    this._inviteButton.active = false;
    if (this._friendsListView) this._friendsListView.disable();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.FallbackAskFriends = Window_FallbackAskFriends;
