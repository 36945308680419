/**
 * The type of level it is
 */
export const LevelType = {
  NONE: 'NONE',
  NORMAL: 'NORMAL',
  CHALLENGE: 'CHALLENGE',
  TOURNAMENT: 'TOURNAMENT',
  COLLECT_EVENT: 'COLLECT_EVENT',
  TREASURE_HUNT: 'TREASURE_HUNT',
};

/**
 * The type of treasure chest
 */
export const TreasureChestTypes = {
  TREASURE_HUNT: 'THGEM',
  CHEST: 'CHEST',
};

export const ORIENTATION = { // Orientation of the game
  vertical: 0,
  horizontal: 1,
};
