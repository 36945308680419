import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

export const VillainsDataManagerKey = 'villains';
export const temporaryBoosterCap = 2;

/**
 * Class for the villains update
 * Holds if the user has seen the villains tutorial or not
 */
export default class VillainsDataManager {
  /**
   * Returns the default values for this manager
   * @returns {object}
   */
  static getDefaultValues() {
    return {
      tutorialStatus: this._getDefaultTutorialStatus(),
      temporaryBoosters: this._getDefaultTemporaryBoosterObject(),
    };
  }

  /**
   * The default tutorial object
   */
  static _getDefaultTutorialStatus(status = false) {
    return {
      hard: {
        pre_win_saga_map: status,
        level_window: status,
        game: status,
        post_win_saga_map: status,
      },
      super_hard: {
        pre_win_saga_map: status,
        level_window: status,
        game: status,
        post_win_saga_map: status,
      },
    };
  }

  /**
   * The default temporary booster object
   */
  static _getDefaultTemporaryBoosterObject() {
    return {
      boosters: [],
      boosterCount: [],
      startBoosters: [],
      resolved: true,
    };
  }

  constructor() {
    this.save = () => {}; // This will be replaced externally
  }

  /**
   * The object is created in saveState. This class modifies the object reference.
   *
   * @param {Object} data
   */
  init(data) {
    this.dataReference = data;

    OMT_Utils.mergeMissingObject(
      this.dataReference,
      VillainsDataManager.getDefaultValues(),
    );
  }

  /**
   * Reset tutorial status function for Jaffles
   */
  resetTutorialStatus() {
    this.dataReference.tutorialStatus = VillainsDataManager._getDefaultTutorialStatus();
    this.save();
  }

  /**
   * Complete villains tutorial function for Jaffles
   */
  completeTutorial() {
    this.dataReference.tutorialStatus = VillainsDataManager._getDefaultTutorialStatus(
      true,
    );
    this.save();
  }

  /**
   * Set the state of a particular tutorial stage
   * @param {'hard'|'super_hard'} difficulty The difficulty setting for the tutorial
   * @param {'pre_win_saga_map'|'level_window'|'game'|'post_win_saga_map'} stage The stage of the completed tutorial
   * @param {boolean} value The state of the tutorial
   */
  setTutorialStatus(difficulty, stage, value) {
    this.dataReference.tutorialStatus[difficulty][stage] = value;
    this.save();
  }

  /**
   * Get the state of a particular tutorial stage
   * @param {'hard'|'super_hard'} difficulty The difficulty setting for the tutorial
   * @param {'pre_win_saga_map'|'level_window'|'game'|'post_win_saga_map'} stage The stage of the completed tutorial
   * @returns {Boolean}
   */
  getTutorialStatus(difficulty, stage) {
    if (!OMT.feature.isVillainsEnabled()) return true;
    return this.dataReference.tutorialStatus[difficulty][stage];
  }

  /**
   * Saves the users booster array before temporarily giving some things
   * @param {number[]} boosters
   */
  setTemporaryBoosters(boosters) {
    this.dataReference.temporaryBoosters.boosters = boosters;
    this.dataReference.temporaryBoosters.resolved = false;
    this.save();
  }

  /**
   * Keep track of the number of temporary boosters given
   * @param {number} boosterNum
   * @param {number} count
   */
  setTemporaryBoosterCount(boosterNum, count) {
    this.dataReference.temporaryBoosters.boosterCount[boosterNum] = count;
    this.save();
  }

  /**
   * Revert user's boosters to their initial states if they were not resolved
   * The resolution is done either by entering a level after the tutorial, or closing the level window without playing
   * @param {boolean} revertBoosters
   */
  resolveTemporaryBoosters(revertBoosters) {
    if (this.dataReference.temporaryBoosters.resolved) return;
    if (revertBoosters) {
      G.saveState.setBoosterArray(
        this.dataReference.temporaryBoosters.boosters,
      );
    }
    this.dataReference.temporaryBoosters.boosters = [];
    this.dataReference.temporaryBoosters.boosterCount = [];
    this.dataReference.temporaryBoosters.resolved = true;

    this.save();
  }

  /**
   * Normalize user's boosters according to the temporary boosters that were given
   * @param {number[]} boosters
   */
  normalizeTemporaryBoosters(boosters) {
    if (this.dataReference.temporaryBoosters.resolved) return;
    for (let i = 0; i < boosters.length; i++) {
      const booster = boosters[i];
      const tempBooster = this.dataReference.temporaryBoosters.boosters[i];
      const tempBoosterCount = this.dataReference.temporaryBoosters.boosterCount[i];
      if (booster === undefined || tempBooster === undefined || tempBoosterCount === undefined) {
        continue;
      }
      this.dataReference.temporaryBoosters.boosters[i] = Math.min(temporaryBoosterCap - booster, tempBooster);
    }
    this.save();
  }

  /**
   * Separate temporary boosters to actual boosters used so that they are not tracked
   * @param {number[]} boosters
   */
  normalizeStartBoosters(boosters) {
    if (this.dataReference.temporaryBoosters.resolved) return boosters;
    const _boosters = [...boosters];
    for (let i = 0; i < _boosters.length; i++) {
      const startBoosterCount = _boosters[i];
      const tempBoosterCount = this.dataReference.temporaryBoosters.boosterCount[i];
      if (startBoosterCount === undefined || tempBoosterCount === undefined) continue;
      const diff = Math.min(startBoosterCount, tempBoosterCount);
      this.dataReference.temporaryBoosters.startBoosters[i] = diff;
      _boosters[i] = startBoosterCount - diff;
    }
    if (_boosters.every((booster) => booster === undefined)) return [];
    const result = new Array(_boosters.length);
    for (let i = 0; i < _boosters.length; i++) {
      const booster = _boosters[i];
      if (booster) result[i] = booster;
    }
    return result;
  }

  /**
   * Returns the current start booster config on temporary boosters side
   */
  getStartBoosters() {
    return this.dataReference.temporaryBoosters.startBoosters;
  }

  /**
   * Resets the temporary start booster config to its initial state
   */
  resolveStartBoosters() {
    this.dataReference.temporaryBoosters.startBoosters = [];
    this.save();
  }
}
