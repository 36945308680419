/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_DrawUtil from '../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import OMT_UI_SquareButton, {
  BUTTONCOLOURS,
} from '../../OMT_UI/OMT_UI_SquareButton';
import { OMT_AssetLoader } from '../../Services/OMT/OMT_AssetLoader';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

export const Window_ItemShop_ITEM_DETAILS = {
  BOOSTERS: {
    keyBase: 'ui_booster_',
    1: {
      name: 'Swap',
      description: 'Use the swap booster to change the place of two cookies!',
    },
    2: {
      name: 'Hammer',
      description: 'Use the star wand to crush one cookie!',
    },
    3: {
      name: 'Horizontal Breaker',
      description: 'Use the horizontal pin to clear the whole row!',
    },
    4: {
      name: 'Vertical Breaker',
      description: 'Use the vertical pin to clear the whole column!',
    },
    5: {
      name: 'Extra Moves',
      description: 'Use it to add extra moves for this level.',
    },
    6: {
      name: 'Extra Moves',
      description: 'Use it to add extra moves for this level.',
    },
    7: {
      name: 'Special Tokens',
      description: 'Use it to add 3 special tokens on the board.',
    },
    8: {
      name: 'Color Bomb',
      description: 'Use it to add a Color Bomb on the board.',
    },
  },
};

/**
 * The item group to be displayed in the window
 * A.K.A the Item Frame!
 */
export class OMT_ItemShop_ItemFrameBase extends Phaser.Group {
  /**
   * @param {{image:{texture:string,scale:number}, quantity:{text:string,style:object}, description:{text:string,style:object}}} config
   */
  constructor(config = {}) {
    super(game);

    this.config = config;
    this._initElements();
  }

  /**
   * Initiate elements of the item frame
   */
  _initElements() {
    const { image = {}, quantity = {}, description = {} } = this.config;

    this.addBackground(this.config);

    const { texture, scale } = image;
    this.addIcon({ texture, scale });

    {
      const { text, style } = quantity;
      this.addQuantity({ text, style });
    }

    {
      const { text, style } = description;
      this.addDescription({ text, style });
    }
  }

  /**
   * Add background (god shine and stars!)
   */
  addBackground() {
    // Override
  }

  /**
   * Add item icon to the frame
   * @param {object} config
   * @param {string} config.texture The texture key for the item icon
   * @param {string} config.scale The scale to set the icon to
   */
  addIcon({ texture, scale }) { // eslint-disable-line no-unused-vars
    // Override
  }

  /**
   * Add quantity text to the frame
   * @param {object} config
   * @param {string} config.text The text of the quantity text
   * @param {string} config.style The style to set to the text, it resembles a normal text style
   */
  addQuantity({ text, style }) { // eslint-disable-line no-unused-vars
    // Override
  }

  /**
   * Add description text to the frame
   * @param {object} config
   * @param {string} config.text The text of the description text
   * @param {string} config.style The style to set to the text, it resembles a normal text style
   */
  addDescription({ text, style }) { // eslint-disable-line no-unused-vars
    // Override
  }
}

/**
 * An item frame that places everything. It takes up the entire window
 */
export class OMT_ItemShop_ItemFrameSingle extends OMT_ItemShop_ItemFrameBase {
  /**
   * Add background (god shine and stars!)
   */
  addBackground() {
    const background = new G.Image(0, 0, 'reward_BG', 0.5, this);
    background.scale.setTo(0.9);
    this.background = background;
    this.add(background);
  }

  /**
   * Add item icon to the frame
   * @param {object} config
   * @param {string} config.texture The texture key for the item icon
   * @param {string} config.scale The scale to set the icon to
   */
  addIcon({ texture = 'ui_booster_1_locked', scale = 1 }) {
    const icon = new G.Image(0, 0, texture, 0.5, this);
    icon.scale.setTo(scale);
    this.icon = icon;
    this.add(icon);
  }

  /**
   * Add quantity text to the frame
   * @param {object} config
   * @param {string} config.text The text of the quantity text
   * @param {string} config.style The style to set to the text, it resembles a normal text style
   */
  addQuantity({ text = 'x1', style = { style: 'font-blue', fontSize: 70 } }) {
    const quantity = new G.Text(0, 0, text, style, 0.5, 440);
    const { icon } = this;
    quantity.x = icon.x + icon.width * 0.5 + quantity.width * 0.55;
    this.quantity = quantity;
    this.add(quantity);
  }

  /**
   * Add description text to the frame
   * @param {object} config
   * @param {string} config.text The text of the description text
   * @param {string} config.style The style to set to the text, it resembles a normal text style
   */
  addDescription({
    text = '',
    style = { style: 'font-blue', fill: '#111111', fontSize: 40, lineSpacing: -15 },
  }) {
    const description = new G.Text(
      0,
      0,
      text,
      style,
      0.5,
      460,
      75,
      true,
      'center',
      false,
      true,
      true,
    );
    const { background } = this;
    description.y = background.y + background.height * 0.5 + 10;
    this.description = description;
    this.add(description);
  }
}

/**
 * An item frame, that comes with a background! Generally thinner than the single
 */
export class OMT_ItemShop_ItemFrameSingleThin extends OMT_ItemShop_ItemFrameBase {
  /**
   * Add background (god shine and stars!)
   * @param {{image:{value:boolean}}} config
   */
  addBackground(config) {
    const highValue = config.image.value;
    const assetString = highValue ? 'mutliBoosterBg_value' : 'mutliBoosterBg_single';
    const background = G.makeImage(0, 0, assetString, 0.5, this);
    this.background = background;
    this.add(background);

    if (config.image.value && OMT_AssetLoader.getInstance().areSecondaryImagesLoaded(['shop/ui'])) {
      const stickerGroup = new Phaser.Group(game, this);
      const sticker = G.makeImage(0, 0, 'shop3_sticker2', 0.5, stickerGroup);
      sticker.scale.set(120 / sticker.width);

      const text = new G.Text(0, 0, '-10%', {
        style: 'font-white',
        fontSize: 40,
      }, 0.5, sticker.width, sticker.height);
      stickerGroup.add(text);

      stickerGroup.x = background.x + (background.width - stickerGroup.width / 2) / 2;
      stickerGroup.y = background.y - (background.height - stickerGroup.height / 2) / 2;
      stickerGroup.angle = 30;
    }
  }

  /**
   * Add item icon to the frame
   * @param {object} config
   * @param {string} config.texture The texture key for the item icon
   * @param {string} config.scale The scale to set the icon to
   */
  addIcon({ texture = 'ui_booster_1_locked', scale = 1 }) {
    const icon = new G.Image(-32, -40, texture, 0.5, this);
    icon.scale.setTo(scale);
    this.icon = icon;
    this.add(icon);
    OMT_UI_DrawUtil.debugPlacement([icon]);
  }

  /**
   * Add quantity text to the frame
   * @param {object} config
   * @param {string} config.text The text of the quantity text
   * @param {string} config.style The style to set to the text, it resembles a normal text style
   */
  addQuantity({ text = 'x1', style = 'window_itemshop-boosterQuantity' }) {
    const quantity = new G.Text(0, 0, text, style, 0.5, 440);
    const { icon } = this;
    quantity.y = icon.y;
    quantity.x = icon.x + icon.width * 0.5 + quantity.width * 0.55;
    this.quantity = quantity;
    this.add(quantity);
  }

  /**
   * Add description text to the frame
   */
  addDescription() {
    // No description
  }
}

export class Window_ItemShop extends Window {
  /**
   * Create a window that is used to sell multiple copies of an item or lives
   * @param {any} parent
   * @param {{title:string,item:{image:{texture:string,scale:number,},price:{single:number, multi?:number},quantity:{text:string,style:object,},description:{text:string,style:object,}},details:{icon:{texture:string,},onBuyComplete:Function,onWindowClose:Function,},extraOverlay:boolean}} config
   */
  constructor(parent, config = {}) {
    super(parent);

    this.config = config;
    this._initLayout();
  }

  /**
   * Init the layout of the window
   */
  _initLayout() {
    const { title, item = {}, details = {}, extraOverlay } = this.config;

    const layoutData = {};
    if (G.featureUnlock.multipleBoosterBuyingPanel.multiAndOne) {
      if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
        layoutData.backgroundDimensions = { width: 550, height: 720 };
        layoutData.titlePos = { x: 0, y: -318 };
        layoutData.closeButtonX = 220;
        layoutData.normalPos = { x: 0, y: -76 };
        layoutData.valuePos = { x: 0, y: 192 };
        layoutData.descPosition = { x: 0, y: -238 };
        layoutData.normalButtonPos = { x: 0, y: -8 };
        layoutData.valueButtonPos = { x: 0, y: 259 };
      } else {
        layoutData.backgroundDimensions = { width: 1000, height: 500 };
        layoutData.titlePos = { x: 0, y: -207 };
        layoutData.closeButtonX = 450;
        layoutData.normalPos = { x: -228, y: 65 };
        layoutData.valuePos = { x: 233, y: 65 };
        layoutData.descPosition = { x: 0, y: -113 };
        layoutData.normalButtonPos = { x: -233, y: 130 };
        layoutData.valueButtonPos = { x: 233, y: 130 };
      }
    } else {
      layoutData.titlePos = { x: 0, y: -260 };
      layoutData.closeButtonX = 220;
    }

    if (extraOverlay) {
      // This overlay is for forced window push on level window
      const overlay = G.GiftUI.Elements.Overlay();
      this.addChildAt(overlay, 0);
    }

    const multiLayout = G.featureUnlock.multipleBoosterBuyingPanel.multiAndOne;
    if (multiLayout) {
      this.bg = this.addGeneric9SliceBackground(layoutData.backgroundDimensions.width, layoutData.backgroundDimensions.height);
      this.addChild(this.bg);
    } else {
      this.addBackground('popup_background_2');
    }

    this.addTitle(title, layoutData.titlePos.x, layoutData.titlePos.y);

    const { onWindowClose } = details;
    this.addCloseButton(onWindowClose, layoutData.closeButtonX);

    const { image, quantity, description, price } = item;
    const { icon, onBuyComplete } = details;
    if (multiLayout) {
      this.setMutliItem({ image, quantity, description, descPosition: layoutData.descPosition, normalPosition: layoutData.normalPos, valuePosition: layoutData.valuePos });
      this.addBuyButton({ pos: layoutData.normalButtonPos, height: 70, icon, price: price.single, amount: 1, onBuyComplete });
      this.addBuyButton({ pos: layoutData.valueButtonPos, height: 70, icon, price: price.multi, amount: 3, onBuyComplete });
    } else {
      this.setItem({ image, quantity, description });
      this.addBuyButton({ icon, price: price.single, amount: 1, onBuyComplete });
    }

    this.addCoinBar();
  }

  /**
   * Add a title to the window
   * @param {string} text Text of the title
   * @param {number} x Position of the title
   * @param {number} y Position of the title
   */
  addTitle(text = '', x = 0, y = 0) {
    // title
    const titleText = new G.Text(
      x,
      y,
      text,
      G.OMTsettings.elements.Window_ItemShop.titleTextStyle,
      0.5,
      440,
    );
    this.titleText = titleText;
    this.add(titleText);
  }

  /**
   * Add close button to the window
   * @param {Function} onWindowClose A function that is triggered when the close button is pressed
   * @param {number} x Position of the close button
   */
  addCloseButton(onWindowClose = async () => Promise.resolve(), x = 220) {
    // close button
    const closeButton = new G.Button(
      x,
      this.titleText.y,
      'btn_x',
      () => {
        this.closeWindow();
        onWindowClose(this);
      },
      this,
    );
    closeButton.scale.setTo(0.8);
    this.closeButton = closeButton;
    this.registerButtons(closeButton);
  }

  /**
   * Add the buy button to the window
   * @param {object} config
   * @param {{x: number, y:number}} config.pos The position of the button
   * @param {number} config.width The width of the button
   * @param {number} config.height The height of the button
   * @param {object} config.icon The currency icon to be used
   * @param {number} config.price The price of the item
   * @param {number} config.amount The amount of boosters being bought
   * @param {Function} config.onBuyComplete This is called when the user buys the booster. Also add the required animations with this function too
   */
  addBuyButton({
    pos = { x: 0, y: 0 },
    height = 100,
    width = 300,
    icon = {},
    price = 1000,
    amount = 1,
    onBuyComplete = async () => Promise.resolve(),
  }) {
    const mainButtonClick = () => {
      this.closeWindow();
      onBuyComplete(price, amount);
    };

    const { texture = 'currency' } = icon;
    const buttonIcon = new G.Image(0, 0, texture, 0.5, this);
    const buyButton = new OMT_UI_SquareButton(pos.x, pos.y, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width,
          height,
        },
      },
      text: {
        string: price, // OMT.language.getText('4500'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
        icon: {
          image: buttonIcon,
          align: 'left',
          offset: { x: 60, y: 0 },
        },
        offset: { x: -60, y: 0 },
        dimensionMods: {
          height: height * 0.8,
        },
      },
      options: {
        clickFunction: {
          onClick: mainButtonClick.bind(this),
          scaleOnClick: 1.1,
        },
      },
    });
    this.registerButtons(buyButton);
  }

  /**
   * Create the item frame and add it to the window
   * @param {object} config
   * @param {object} config.image
   * @param {object} config.quantity
   * @param {object} config.description
   */
  setItem({ image, quantity, description }) {
    if (this.itemFrame) this.itemFrame.destroy();

    const itemFrame = new OMT_ItemShop_ItemFrameSingle({
      image,
      quantity,
      description,
    });
    itemFrame.y = -50;
    this.itemFrame = itemFrame;
    this.add(itemFrame);
  }


  /**
   * Creates two item frames and puts them on the window
   * @param {object} config
   * @param {object} config.image
   * @param {object} config.quantity
   * @param {object} config.description
   * @param {{x:number, y:number}} [descPosition]
   * @param {{x:number, y:number}} [normalPosition]
   * @param {{x:number, y:number}} [valuePosition]
   */
  setMutliItem({ image, quantity, description, descPosition, normalPosition, valuePosition }) {
    if (this.itemFrame) this.itemFrame.destroy();

    const descTextStyle = description.style || { style: 'font-blue', fill: '#111111', fontSize: 40, lineSpacing: -15 };
    const descText = new G.Text(descPosition.x || 0, descPosition.y || 0, description.text, descTextStyle, 0.5, this.bg.width * 0.8, 70, true, 'center');

    const itemFrame = new OMT_ItemShop_ItemFrameSingleThin({
      image: {
        ...image,
        value: false,
      },
      quantity: {
        ...quantity,
        text: 'x1',
      },
      description,
    });
    const valueItemFrame = new OMT_ItemShop_ItemFrameSingleThin({
      image: {
        ...image,
        value: true,
      },
      quantity,
      description,
    });

    this.itemFrame = {
      destroy: () => {
        itemFrame.destroy();
        valueItemFrame.destroy();
      },
    };

    itemFrame.position.set(normalPosition.x || 0, normalPosition.y || 0);
    valueItemFrame.position.set(valuePosition.x || 0, valuePosition.y || 0);
    this.add(descText);
    this.add(itemFrame);
    this.add(valueItemFrame);
  }

  /**
   * Adds a coinbar on top
   */
  addCoinBar() {
    const coinbar = new G.CoinBar(0, 0, true, 'window_oneTimePopupOffer-coinbarTxt');
    coinbar.y -= this.bg.height * 0.5 + coinbar.height;
    this.coinbar = coinbar;
    this.add(coinbar);
  }

  /**
   * Get the default config object to make changes to
   * This is here to guide other devs about the possible config to this window
   * @returns config
   */
  static getDefaultConfigObject() {
    return {
      title: '',
      item: {
        image: {
          texture: 'ui_booster_1_locked',
          scale: 1.75,
        },
        quantity: {
          text: 'x1',
          style: 'window_itemshop-boosterQuantity',
        },
        description: {
          text: '',
          style: {
            style: 'font-blue',
            fontSize: 40,
            fill: '#e2853b',
            lineSpacing: -10,
          },
        },
        price: {
          single: 1000,
          multi: 1000,
        },
      },
      details: {
        icon: { texture: 'currency' },
        onBuyComplete: async () => Promise.resolve(),
        onWindowClose: async () => Promise.resolve(),
      },
      extraOverlay: false,
    };
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.itemShop = Window_ItemShop;

// Reference window config
// G.sb('pushWindow').dispatch([
//   'itemShop',
//   {
//     title: 'Hammer',
//     item: {
//       image: {
//         texture: 'ui_booster_2',
//         scale: 1.5,
//       },
//       quantity: {
//         text: 'x3',
//         style: {
//           style: 'font-blue',
//           fontSize: 70,
//         },
//       },
//       description: {
//         text: 'Use the Hammer to clear a tile.',
//         style: {
//           style: 'font-blue',
//           fill: '#e2853b',
//           fontSize: 40,
//         },
//       },
//       price: 4500,
//     },
//     details: {
//       icon: { texture: 'currency' },
//       onBuyComplete: async () => Promise.resolve(),
//       onWindowClose: async () => Promise.resolve(),
//     },
//   },
// ]);
