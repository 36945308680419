import UI_AnimationGroup from '@omt-components/UI/Animation/UI_AnimationGroup';
import FrameAnimationController from '@omt-components/Utils/Animation/FrameAnimationController';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../States/Scaling/GameScaleController';
import OMT_UI_AvatarWithCustomFrame from '../../OMT_UI/FriendsList/OMT_UI_AvatarWithFrameOffset';
import OMT_UI_SquareButton, { BUTTONCOLOURS, BUTTONSTATE } from '../../OMT_UI/OMT_UI_SquareButton';
import TreasureHuntManager from '../../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';
import { SagaMap_Tutorial } from '../GingyTutorial/SagaMap_Tutorial';

const IDEAL_PRIZE_DIMENSIONS = { width: 115, height: 115 };
export default class TreasureHuntPrizeGroup extends UI_AnimationGroup {
  /**
   * A group that uses the animation group for easier animations.
   * @param {Window_TreasureHuntPrizeClaim} parent
   * @param {{prizeIndex?:number, chestIndex?:number, closeWindow:function, podium:TreasureHuntPodiumGroup}} config
   */
  constructor(parent, config) {
    super(game, parent);

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._podium = config.podium;
    const pendingPrize = G.saveState.treasureHuntManager.nextPrize;
    this._badgeAnimationTweenTime = 1000;
    this._chestAnimationSpeed = (24 / 60) * 5;
    this._prizeData = pendingPrize.p;
    this._prizeIndex = config.prizeIndex || pendingPrize.i;
    this._chestIndex = config.chestIndex || this._prizeIndex;
    this._closeWindow = config.closeWindow;
    this._chestAsset = `treasureHuntChest${this._chestIndex}_`;
    if (!this._prizeData) {
      if (this._closeWindow) { this._closeWindow(); }
      console.warn('No prize queued');
      return;
    }
    this._drawEverything();
  }

  /**
   * Removes gingy, and then closes
   */
  _cleanUpAndClose() {
    if (this._gingyTutorial) {
      this._gingyTutorial.close();
      this._gingyTutorial = null;
    }

    this._closeWindow();
  }

  /**
   * Draws everything required
   */
  _drawEverything() {
    this._chestImage = G.makeImage(0, 0, `${this._chestAsset}0`, 0.5, this); // Doesn't work. Need to go to decoupled
    this._chestImageController = new FrameAnimationController();
    this._chestImageController.addCallbackOnFrame(G.OMTsettings.treasureHuntSuper.reward.sfxFrameTiming[this._chestIndex], G.sfx.chest_open.play.bind(this));
    this._chestImage.scale.set(0.1);
    this._chestImage.targetScale = 1 / 0.7;

    this._prizeContainer = new Phaser.Group(game, this);
    const prizeList = [];
    this._badgeObtained = null;
    for (const prizeObj of this._prizeData) {
      const prizeGroup = new Phaser.Group(game, this._prizeContainer);

      if (prizeObj.prize === 'badge') {
        this._badgeObtained = prizeObj.amount;
        this._badgePreview = G.makeImage(0, 0, this._badgeObtained, 0.5, prizeGroup);
      } else {
        const image = G.makeImage(0, 0, G.gift.getIcon([prizeObj.prize]), 0.5, prizeGroup);
        const xPrefix = prizeObj.prize.toLowerCase().indexOf('coin') > -1 ? '' : 'x '; // draw X if not coin
        const text = new G.Text(0, 0, `${xPrefix}${prizeObj.amount}`, 'font-white-blue-out', 0.5, image.width);
        image.y -= image.height / 2;
        text.y = image.y + (image.height + text.height) / 2;

        if (prizeObj.prize.toLowerCase().indexOf('coin') > -1) {
          this._coinAnchor = { image, amount: prizeObj.amount }; // If we have coins, we need to do special coin animations
        }

        prizeGroup.addChild(text);
      }
      prizeList.push(prizeGroup);
    }

    if (this._badgeObtained) {
      if (!G.saveState.treasureHuntManager.firstBadgeSeen) {
        this._initGingyTutorial();
      }
      this._readyBadgeProcess();
      if (this._podium) {
        this._podium.gainedBadge(this._badgeObtained);
      }
    }
    this._positionPrizes(prizeList); // Position prizes accordingly

    if (!this._gingyTutorial) {
      this._initSkipHitbox();
    }

    this._claimButton = new OMT_UI_SquareButton(0, 300, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 254,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Cool'),
        textStyle: {
          style: 'font-white',
          fontSize: 70,
        },
        dimensionMods: {
          width: 0.9,
          height: 0.9,
        },
      },
      options: {
        clickFunction: {
          onClick: this._onClaimClicked.bind(this),
          disableAfterClick: true,
        },
        cacheButton: false,
      },
    });
    this._claimButton.visible = false;
    this.addChild(this._claimButton);
  }

  /**
   * Creates the gingy tutorial
   */
  _initGingyTutorial() {
    const { mascotName } = TreasureHuntManager.extractBadgeInfo(this._badgeObtained);
    this._gingyTutorial = new SagaMap_Tutorial({ game, scale: 1, character: `treasureHunt${(mascotName || 'gingy')}` });
    this._gingyTutorial.visible = false;
  }

  /**
   * Creates the assets for the badge
   */
  _readyBadgeProcess() {
    // You always get a basic badge at this point
    const avatarSize = 125;
    const baseAvatarConfig = {
      imageURL: OMT.envData.settings.user.avatar,
      imageSize: avatarSize,
      frameAsset: 'avatar_frame_big',
      frameOffset: {},
      frameMultSize: 1,
    };
    const curAvatarConfig = {
      ...baseAvatarConfig,
    };
    const curFrame = G.saveState.badgeManager.currentBadge;
    const curFrameData = G.OMTsettings.elements.badges[curFrame];
    if (curFrame) {
      curAvatarConfig.frameAsset = curFrameData.asset;
      curAvatarConfig.frameOffset = curFrameData;
      curAvatarConfig.frameMultSize = curFrameData.scale;
    }
    this._yourAvatar = new OMT_UI_AvatarWithCustomFrame(curAvatarConfig);

    this._glow = G.makeImage(0, 0, 'shine_godrays', 0.5, null);
    this._glow.scale.set(500 / this._glow.height);

    const badgeData = G.OMTsettings.elements.badges[this._badgeObtained];
    this._targetBadgeScale = badgeData.scale;
    this._yourAfterAvatar = new OMT_UI_AvatarWithCustomFrame({
      ...baseAvatarConfig,
      frameAsset: badgeData.asset,
      frameOffset: badgeData,
      frameMultSize: this._targetBadgeScale,
    });

    const everything = [this._yourAvatar, this._glow, this._yourAfterAvatar];
    for (const thing of everything) {
      thing.alpha = 0;
      this.addChild(thing);
    }
  }

  /**
   * Arranges assets around depending on amount
   * @param {Array<Phaser.Group>} prizeList
   */
  _positionPrizes(prizeList) {
    // Prize length of 1 just stays where it is
    if (prizeList.length === 2) {
      let nextX = -IDEAL_PRIZE_DIMENSIONS.width;
      for (const prize of prizeList) {
        prize.x = nextX;
        nextX += IDEAL_PRIZE_DIMENSIONS.width * 2;
      }
    } else if (prizeList.length === 3) {
      let nextX = -IDEAL_PRIZE_DIMENSIONS.width * 1;
      for (let i = 0; i < prizeList.length; i++) {
        const prize = prizeList[i];
        prize.x = nextX;
        prize.y = (i % 2) * -(IDEAL_PRIZE_DIMENSIONS.height * 0.75);
        nextX += IDEAL_PRIZE_DIMENSIONS.width * 1;
      }
    } else if (prizeList.length === 4) {
      let nextY = -IDEAL_PRIZE_DIMENSIONS.height / 2;
      let nextX = -IDEAL_PRIZE_DIMENSIONS.width / 2;
      const originX = nextX;
      let highestHeight = 0;
      for (let i = 0; i < prizeList.length; i++) {
        const prize = prizeList[i];
        prize.x = nextX;
        prize.y = nextY;
        highestHeight = Math.max(IDEAL_PRIZE_DIMENSIONS.height, highestHeight);
        if (i % 2 === 0) {
          nextX = originX;
          nextY += highestHeight;
          highestHeight = 0;
        } else {
          nextX += IDEAL_PRIZE_DIMENSIONS.width / 2;
        }
      }
    }
  }

  /**
   * Creates a hitbox for the skip button
   */
  _initSkipHitbox() {
    this._skipButton = G.makeImage(0, 0, null, 0, null);
    this._skipButton.inputEnabled = true;
    this._skipButton.input.useHandCursor = true;
    this._skipButton.hitArea = new Phaser.Rectangle(-2000, -2000, 4000, 4000);

    const text = new G.Text(0, 0, OMT.language.getText('Skip'), 'skipText', 0.5, game.width * 0.7, 500, true, 'center');
    const blinkTime = 1000;
    const pulse = game.time.events.loop(blinkTime, () => { // Instance of the event so it can be removed when the card is removed
      if (this._skipButton && this._skipButton.visible && this._skipButton.parent) {
        game.add.tween(text)
          .to({ alpha: 0.4 }, blinkTime / 2, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
      } else {
        game.time.events.remove(pulse);
        this._skipButton.pulse = null;
      }
    }, this);
    const gameHeight = this._isLandscape ? game.height / this._gameScale : game.height;

    text.y = -130 - (text.height - gameHeight) / 2;
    this._skipButton.addChild(text);
    game.add.tween(text) // initial blink
      .to({ alpha: 0.4 }, blinkTime / 2, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);

    this._skipButton.pulse = pulse;

    this._skipButton.events.onInputDown.add((this._onSkipClick.bind(this)));
    this.addChild(this._skipButton);
  }

  /**
   * Animates the prizes up
   * @returns {null}
   */
  async animate() {
    if (!this._prizeContainer) { return; }
    this._chestImage.alpha = 0;
    this._claimButton.alpha = 0;
    this._claimButton.visible = false;
    this._prizeContainer.y = 0;
    this._prizeContainer.alpha = 0;
    this._prizeContainer.scale.set(0.5);

    if (this._badgeObtained) {
      this._yourAvatar.alpha = 0;
      this._yourAfterAvatar.alpha = 0;
      this._yourAvatar.x = game.width;
      this._glow.y = this._yourAfterAvatar.y = this._yourAvatar.y = 100;
      this._yourAfterAvatar.x = 0;
      this._glow.alpha = 0;
    }

    await this.wait(300);
    this.wrapTween(this._chestImage, { alpha: 1 }, this._badgeAnimationTweenTime / 4, Phaser.Easing.Sinusoidal.InOut);
    await this.wrapTween(this._chestImage.scale, { x: this._chestImage.targetScale, y: this._chestImage.targetScale }, this._badgeAnimationTweenTime / 4, Phaser.Easing.Sinusoidal.InOut);
    await this._chestImageController.asyncInit(this._chestImage, this._chestAsset, null, this._chestAnimationSpeed, 0, 0);
    this.destroySkip();
    this.wrapTween(this._prizeContainer.scale, { x: 1.15, y: 1.15 }, this._badgeAnimationTweenTime * 0.35, Phaser.Easing.Sinusoidal.Out);
    this._claimButton.visible = true;
    await this.wrapTween(this._prizeContainer, { y: -200, alpha: 1 }, this._badgeAnimationTweenTime * 0.35, Phaser.Easing.Sinusoidal.Out);
    if (this._gingyTutorial) {
      this._gingyTutorial.visible = true;
      await this._gingyTutorial.displayMsgAsync(G.json.tutorials.treasureHunt.firstBadge);
    }
    await this.wrapTween(this._claimButton, { alpha: 1 }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.InOut);
  }

  /**
   * Animates the badge into the player avatar. No saving done here
   */
  async _animateBadge() {
    this.wrapTween(this._yourAvatar, { alpha: 1 }, this._badgeAnimationTweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
    await this.wrapTween(this._yourAvatar, { x: 0 }, this._badgeAnimationTweenTime / 2, Phaser.Easing.Elastic.Out);
    this.wrapTween(this._badgePreview.scale, { x: this._targetBadgeScale, y: this._targetBadgeScale }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.Out);
    await this.wrapTween(this._badgePreview, { y: 100 }, this._badgeAnimationTweenTime, Phaser.Easing.Elastic.In);
    G.sfx.lightning.play();
    this._badgePreview.alpha = 0;
    this._yourAvatar.alpha = 0;
    this._yourAfterAvatar.alpha = 1;
    this.wrapTween(this._glow, { alpha: 1 }, this._badgeAnimationTweenTime / 4, Phaser.Easing.Sinusoidal.InOut);
    await this.wait(this._badgeAnimationTweenTime);
    if (this._podium) {
      G.sfx.xylophone_positive6.play();
      if (this._gingyTutorial) {
        await this._gingyTutorial.displayMsgAsync(G.json.tutorials.treasureHunt.oneWeekBadge);
        await this.wait(3000);
        this._gingyTutorial.close();
      }
      this.wrapTween(this._yourAfterAvatar, { y: -game.height }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wrapTween(this._glow, { y: -game.height }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.InOut);
      this._glow.destroy();
      this._glow = null;
      this._podium.animate();
      this.visible = false;
      return;
    } // NO LONGER CONTNUES IF WE HAVE A PODIUM
    G.sfx.xylophone_positive6.play();
    this.wrapTween(this._yourAfterAvatar, { y: -50 }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.InOut);
    this.wrapTween(this._glow, { y: -50 }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.InOut);
    if (this._gingyTutorial) {
      await this._gingyTutorial.displayMsgAsync(G.json.tutorials.treasureHunt.oneWeekBadge);
    }
    await this.wait(this._badgeAnimationTweenTime);
    this._claimButton.visible = true;
    this._claimButton.currentState = BUTTONSTATE.ENABLED;
    await this.wrapTween(this._claimButton, { alpha: 1 }, this._badgeAnimationTweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
    this._claimButton.alpha = 1;
  }

  /**
   * When claim is clicked
   */
  _onClaimClicked() {
    if (this._exitWindow) { this._cleanUpAndClose(); return; }
    const coinFunction = !this._coinAnchor ? null : (amount) => { // If there is no coins, this function does /not/ get called!
      let coinTarget = null;
      const state = game.state.getCurrentState();
      try {
        coinTarget = state.panel.coinsTxt;
      } catch (e) { /* Nothing */ }
      if (!coinTarget) {
        G.saveState.changeCoins(amount, false, false, false, false);
        this._afterClaimClicked();
        return;
      }
      state.uiTargetParticles.createCoinBatch( // Show bling
        game.world.bounds.x + this._coinAnchor.image.worldPosition.x,
        this._coinAnchor.image.worldPosition.y,
        coinTarget,
        amount,
        false,
        this._afterClaimClicked.bind(this),
      );
    };
    TreasureHuntManager.applyPrizes(this._prizeData, coinFunction);
    if (this._badgeObtained && !G.saveState.treasureHuntManager.firstBadgeSeen) { G.saveState.treasureHuntManager.setFirstBadgeSeen(); }
    if (!this._coinAnchor) {
      this._afterClaimClicked();
    }
  }

  /**
   * When the skip button is clicked
   */
  _onSkipClick() {
    this._badgeAnimationTweenTime = 200;
    this._tweenTimeScale = 2;
    this._chestAnimationSpeed /= 2;
    this._everyTween.forEach((tw) => { tw.timeScale = this._tweenTimeScale; });
    this._everyTimer.forEach((w) => { w.delay /= 2; });
    this._chestImageController.updateTimer(this._chestAnimationSpeed);
    this.destroySkip();
  }

  /**
   * Destroy the skip button and pulse
   */
  destroySkip() {
    if (this._skipButton) {
      if (this._skipButton.pulse) {
        game.time.events.remove(this._skipButton.pulse);
        this._skipButton.pulse = null;
      }
      this.removeChild(this._skipButton);
      this._skipButton.destroy();
      this._skipButton = null;
    }
  }

  /**
   * When the claim is clicked, show the badge animation (if there is one)
   */
  async _afterClaimClicked() {
    G.saveState.treasureHuntManager.removePrizeByIndex(this._prizeIndex);
    if (this._badgeObtained) {
      const targetPos = this.toLocal(this._badgePreview.worldPosition);
      this._badgePreview.position.set(targetPos.x, targetPos.y);
      this._badgePreview.scale.set(this._badgePreview.worldScale.x / this.scale.x, this._badgePreview.worldScale.y / this.scale.y);
      this.addChild(this._badgePreview);
      this.wrapTween(this._badgePreview, { x: 0 }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(this._prizeContainer, { alpha: 0 }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(this._chestImage, { alpha: 0 }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wrapTween(this._claimButton, { alpha: 0 }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.InOut);
      this._animateBadge();
      this._exitWindow = true;
    } else {
      this._cleanUpAndClose();
    }
  }

  /**
   * Update!
   */
  update() {
    super.update();
    if (this._chestImageController) {
      this._chestImageController.update();
    }

    if (this._glow && this._glow.alpha > 0) {
      this._glow.angle += 0.05;
    }
  }
}
