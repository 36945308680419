import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';

/**
 * The class that shows the shoutout
 */
export default class UI_ShoutOuts extends Phaser.Group {
  /**
   * @param {Board} board
   */
  constructor(board) {
    super(game);

    this._board = board;

    this._glowImg = G.makeImage(0, 0, 'popup_lighht', 0.5, this);
    this._glowImg.alpha = 0.5;
    this._glowImg.visible = false;

    this._shoutOut = G.makeImage(0, 0, null, 0.5, this);
    this._shoutOut.visible = false;

    this._combo = 0;
    this._signalBindings = [];
    this._signalBindings.push(G.sb('onComboIncrease').add(this._increaseCombo, this));
    this._signalBindings.push(G.sb('onComboBreak').add(this._breakCombo, this));

    this._signalBindings.push(G.sb('onGoalAchieved').add(this._cookieCrush, this));

    this._signalBindings.push(G.sb('madeMove').add(() => {
      const lvlDataManager = LvlDataManager.getInstance();
      if (lvlDataManager.goalAchieved) return;

      if (lvlDataManager.moves === 10) {
        this._lockedShoutOut('10_moves_left');
        this.playShoutoutAudio('10_moves_left');
      }
      if (lvlDataManager.moves === 5) {
        this._lockedShoutOut('5_moves_left');
        this.playShoutoutAudio('5_moves_left');
      }
    }));

    this._locked = false;

    this.x = this._board.x + (this._board.width - this._board.tileSize * 2) * 0.5;
    this.y = this._board.y + (this._board.height - this._board.tileSize * 2) * 0.45;
  }

  /**
   * Destroy!
   */
  destroy() {
    G.stopTweens(this._shoutOut);
    if (this._signalBindings) {
      this._signalBindings.forEach((binding) => {
        if (binding.detach) {
          binding.detach();
        }
      });
      this._signalBindings = null;
    }
    super.destroy();
  }

  /**
   * Update func
   */
  update() {
    this.x = this._board.x + (this._board.width - this._board.tileSize * 2) * 0.5;
    this.y = this._board.y + (this._board.height - this._board.tileSize * 2) * 0.45;

    this._glowImg.angle += G.deltaTime;
  }

  /**
   * @param {number} newAmount
   */
  _increaseCombo(newAmount) {
    if (!this._isTextureAvailable()) return;

    if (this._locked) return;
    if (LvlDataManager.getInstance().goalAchieved) return;
    this._combo = newAmount;

    let txt = false;

    if (this._combo === 3 || this._combo === 5 || this._combo === 7) {
      txt = `tier0_${Math.floor(Math.random() * 3)}`;
    } else if (this._combo === 9 || this._combo === 11) {
      txt = `tier1_${Math.floor(Math.random() * 2)}`;
    }


    if (!txt) return;

    G.stopTweens(this._shoutOut);

    this._shoutOut.visible = true;

    this._shoutOut.alpha = 1;
    this.playShoutoutAudio(txt);
    this._shoutOut.loadTexture('shoutouts', txt);
    this._shoutOut.scale.setTo(0);


    game.add.tween(this._shoutOut.scale).to({ x: 1, y: 1 }, 700, Phaser.Easing.Elastic.Out, true);
    game.add.tween(this._shoutOut).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true, 1000).onComplete.add(() => {
      this._shoutOut.visible = false;
    });
  }

  /**
   * @param {any} sprite
   */
  _lockedShoutOut(sprite) {
    if (!this._isTextureAvailable()) return;

    this._locked = true;

    G.stopTweens(this._shoutOut);
    this._shoutOut.visible = true;

    this._shoutOut.alpha = 1;
    this._shoutOut.loadTexture('shoutouts', sprite);
    this._shoutOut.scale.setTo(0);
    game.add.tween(this._shoutOut.scale).to({ x: 1, y: 1 }, 700, Phaser.Easing.Elastic.Out, true);
    game.add.tween(this._shoutOut).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true, 1500).onComplete.add(() => {
      this._shoutOut.visible = false;
      this._locked = false;
    });
  }

  /**
   * Plays the shoutout audio if there is one, gets accessed externally
   * @param {string} name
   */
  playShoutoutAudio(name) {
    if (!G.featureUnlock.shoutoutAudio || this._isAnyShoutoutPlaying()) return;
    const key = `shoutout_${OMT.language.lang}_${name}`;

    try {
      G.sfx[key].play();
    } catch (e) {
      try {
        G.sfx[`shoutout_en_${name}`].play();
      } catch (err) {
        console.warn(`${key} is no sound file.`);
      }
    }
  }

  /**
   * Checks to see if any shoutout is currently playing
   */
  _isAnyShoutoutPlaying() {
    const keys = Object.getOwnPropertyNames(G.sfx).filter((s) => s.indexOf('shoutout_') !== -1);
    for (const soundName of keys) {
      if (G.sfx[soundName].isPlaying) {
        return true;
      }
    }
    return false;
  }

  // TODO: this function is bugged and not currently used
  _stopAllShoutOuts(exceptionName) {
    const keys = Object.getOwnPropertyNames(G.sfx).filter((s) => s.indexOf('shoutout_') !== -1);
    keys.forEach((soundName) => {
      if (soundName.indexOf(exceptionName) !== -1) return;
      try {
        if (G.sfx[soundName].stop) G.sfx[soundName].stop();
      } catch (e) {
        console.error(soundName, 'sound not stoppable cause non existent');
      }
    });
  }

  /**
   * Shouts the final win
   */
  _cookieCrush() {
    if (!this._isTextureAvailable()) return;

    this._glowImg.scale.setTo(0);
    this._glowImg.visible = true;

    game.add.tween(this._glowImg.scale).to({ x: 1.5, y: 1.5 }, 500, Phaser.Easing.Elastic.Out, true);
    game.add.tween(this._glowImg).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true, 1500);

    G.stopTweens(this._shoutOut);
    this._shoutOut.visible = true;

    this._shoutOut.alpha = 1;
    this._shoutOut.loadTexture('shoutouts', 'final_win');
    // G._stopAllShoutOuts();  // This function is bugged
    this.playShoutoutAudio('final_win');

    this._shoutOut.scale.setTo(0);
    game.add.tween(this._shoutOut.scale).to({ x: 1, y: 1 }, 700, Phaser.Easing.Elastic.Out, true);
    game.add.tween(this._shoutOut).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true, 1500).onComplete.add(() => {
      this._shoutOut.visible = false;
    });

    for (let i = 0; i < 10; i++) {
      G.sb('UIfx').dispatch(this.x - 100 + (i * 20), this.y, 'whiteStarPart');
    }
  }

  /**
   * Ruins combos
   */
  _breakCombo() {
    this._combo = 0;
  }

  /**
   * Checks if texture is there
   */
  _isTextureAvailable() {
    return game.cache.getFrame('shoutouts') !== null;
  }
}
