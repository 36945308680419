import ShareMsgBase from './A_G.ShareMsgBase';

// Class for creating mailbox share messages
export class MsgMailbox extends ShareMsgBase {
  /**
   * The message that is shared when you share a fortune cookie message
   * @param {string} imgID
   * @param {string} txt
   * @param {function} onAllImagesReadyCallback
   */
  constructor(imgID, txt, avatarConfig, onAllImagesReadyCallback = null) {
    super(5, onAllImagesReadyCallback);
    const {
      background: bgConfig,
      postcard: postcardConfig,
      photo: photoConfig,
      text: textConfig,
    } = G.OMTsettings.elements.MsgMailbox;

    this.background = this._addExtImage(bgConfig.x, bgConfig.y, 'img/mailbox/shareimage_bg.png', 0, bgConfig.width, bgConfig.height);
    this.postcard = this._addExtImage(postcardConfig.x, postcardConfig.y, 'img/mailbox/mail_postcard.png', 0.5);
    this.postcard.scale = { x: postcardConfig.scale, y: postcardConfig.scale };
    this._photoGroup = new Phaser.Group(game, this);
    this._photoGroup.x = photoConfig.x;
    this._photoGroup.y = photoConfig.y;
    this._photoGroup.scale = { x: photoConfig.scale, y: photoConfig.scale };
    this._photoGroup.angle = photoConfig.angle;

    this.photo = this._addExtImage(0, 0, `img/mailbox/${imgID}.png`, 0.5);
    this._photoGroup.addChild(this.photo);
    this.txt = new G.Text(textConfig.x, textConfig.y, txt.msg.replace('-', ' '), 'mailbox-shareStyle', 0.5, textConfig.maxWidth, textConfig.maxHeight, false, 'center');
    this.add(this.txt);

    const avatar = G.makeExtImage(
      avatarConfig.x,
      avatarConfig.y,
      OMT.envData.settings.user.avatar,
      'avatar_m',
      0.5,
      this._photoGroup,
      false,
      (img) => {
        img.width = avatarConfig.width;
        img.height = avatarConfig.height;

        this._objectLoadComplete();
      },
    );

    avatar.angle = avatarConfig.angle;

    this._objectLoadComplete();
  }
}
G.MsgMailbox = MsgMailbox;
