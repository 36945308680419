import UI_AnimationGroup from '@omt-components/UI/Animation/UI_AnimationGroup';
import { GameScaleController } from '../../States/Scaling/GameScaleController';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

export default class TreasureHuntCounter extends UI_AnimationGroup {
  /**
   * The counter on the game board that counts the number of tokens collected
   * @param {Game} parent
   */
  constructor(parent) {
    super(game, parent);

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._isVertical = OMT.systemInfo.orientation === ORIENTATION.vertical;
    this._isDoubling = G.saveState.treasureHuntManager.isDoubling;
    if (this._isDoubling) {
      this._drawTextBanner();
      this._resizeBinding = G.sb('onScreenResize').add(this._onScreenResize.bind(this));
    }
    this._drawCounter();
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._bannerGroup) {
      this._bannerGroup.image.destroy();
      this._bannerGroup = null;
    }
    this._removeResizeBinding();
    super.destroy();
  }

  /**
   * Removes the resizing binding only
   */
  _removeResizeBinding() {
    if (this._resizeBinding) {
      if (this._resizeBinding.detach) {
        this._resizeBinding.detach();
      }
      this._resizeBinding = null;
    }
  }

  /**
   * Draws the counter for visualization
   */
  _drawCounter() {
    this._container = new Phaser.Group(game, this);
    const bg = G.makeImage(0, 0, 'treasureHunt_gameCounterBg', 0.5, this._container);

    const { tokenOffset } = G.OMTsettings.treasureHuntSuper.gameBoard.counter;
    const token = this._token = G.makeImage(0, tokenOffset.y, 'treasureHunt_token', 0.5, this._container);
    token.x = tokenOffset.x + bg.x + (bg.width / 2);

    if (this._isDoubling) {
      const doublingOffset = G.OMTsettings.treasureHuntSuper.gameBoard.counter.doublingOfset;
      const twoTimesGroup = new Phaser.Group(game, this._container);
      const ttBg = G.makeImage(0, 0, 'treasureHunt_gameMultiplier', 0.5, twoTimesGroup);
      const ttText = new G.Text(0, 3, 'x2', {
        style: 'font-white',
        fontSize: ttBg.height * 0.8,
      }, 0.5, ttBg.width * 0.8);
      twoTimesGroup.addChild(ttText);

      twoTimesGroup.x = doublingOffset.x + bg.x - (bg.width - twoTimesGroup.width / 2) / 2;
      twoTimesGroup.y = doublingOffset.y + bg.y - (bg.height - twoTimesGroup.height / 2) / 2;
    }

    const counterTextPos = G.OMTsettings.treasureHuntSuper.gameBoard.counter.textOffset;
    this._counterText = new G.Text(counterTextPos.x, counterTextPos.y, '0', 'treasureHunt-gameBoardCounter', 0.5, bg.width * 0.8);
    this._container.addChild(this._counterText);

    if (this._isLandscape) {
      this._container.scale.setTo(this._gameScale);
    }
  }

  /**
   * Draws a text banner explaining why theres a 2x bonus
   */
  _drawTextBanner() {
    const group = new Phaser.Group(game, this);
    const bg = G.makeImage(0, 0, 'treasureHunt_textBanner', 0.5, group);
    if (this._isVertical) {
      bg.width = game.width;
    } else {
      bg.width = game.width / 3;
      const bgCap = G.makeImage(bg.x - bg.width / 2.1, 0, 'treasureHunt_textBannerCap', [1, 0.5], group);
      for (const child of group.children) {
        child.x += bgCap.width / 2;
      }
    }
    const text = new G.Text(10, 0, OMT.language.getText('Win on your first try to get a 2x bonus!'), 'treasureHunt-infoTextBanner', 0.5, bg.width, bg.height * 0.98);
    group.addChild(text);
    group.alpha = 0;

    if (this._isLandscape) {
      group.scale.setTo(this._gameScale);
    }

    this._bannerGroup = {
      image: group,
      bg,
    };
  }

  /**
   * Keeps the banner wide
   */
  _onScreenResize() {
    if (this._bannerGroup && this._isVertical) {
      this._bannerGroup.bg.width = game.width;
    }
  }

  /**
   * Counter gets updated when token comes in
   */
  updateCounter() {
    this._counterText.setText(G.saveState.treasureHuntManager.tempTokens);
  }

  /**
   * When doubling is active, fade in the banner, move the counter.
   * Then move the counter back when done
   * @returns {null}
   */
  async show() {
    if (!this._isDoubling && !this._bannerGroup) { return; }

    this._onScreenResize();

    if (this._isVertical) {
      await this.showVertical();
    } else {
      await this.showHorizontal();
    }
  }

  /**
   * Shows the banner in vertical mode
   */
  async showVertical() {
    const tweenTime = 500;
    const targetY = this._container.y + this._container.height / 2;
    const bannerY = this._bannerGroup.image.y - this._bannerGroup.image.height / 2;
    const originY = this._bannerGroup.image.y = bannerY - this._container.height / 2;
    this._bannerGroup.image.alpha = 0;

    this.wrapTween(this._container, { y: targetY }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    this.wrapTween(this._bannerGroup.image, { y: bannerY, alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    await this.wait(3000);
    this.wrapTween(this._container, { y: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    await this.wrapTween(this._bannerGroup.image, { y: originY, alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    this._bannerGroup.image.destroy();
    this._bannerGroup = null;
    this._removeResizeBinding();
  }

  /**
   * Shows the banner in horizontal mode
   */
  async showHorizontal() {
    const tweenTime = 500;
    this._bannerGroup.image.y = this._container.y;
    const originX = this._bannerGroup.image.x = (game.width + this._bannerGroup.image.width) / 2;// + this._bannerGroup.image.width / 2;
    const bannerX = originX - this._bannerGroup.image.width;
    this._bannerGroup.image.alpha = 0;

    this.wrapTween(this._bannerGroup.image, { x: bannerX, alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    await this.wait(3000);
    await this.wrapTween(this._bannerGroup.image, { x: originX, alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    this._bannerGroup.image.destroy();
    this._bannerGroup = null;
    this._removeResizeBinding();
  }

  /**
   * Returns the token on the right side. For positioning purposes
   * @returns {Phaser.Image}
   */
  get icon() {
    return this._token;
  }
}
