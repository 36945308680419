export default class ShareMsgBase extends Phaser.Group {
  /**
   * The base class for all Share Messages.
   * Generally, when your last line of the drawing code is not addExtImage or anything that can be held up
   * by a promise, you will want to:
   * 1. Increment the number of ext images by 1
   * 2. Call this._objectLoadComplete() at the end of the drawing code
   *
   * This is to ensure that the rest of the drawing will be applied to the image and not miss
   * its departure time because the other extImages have finished loading
   * @param {number} nrOfExtImgs
   * @param {function} onAllImagesReadyCallback
   */
  constructor(nrOfExtImgs, onAllImagesReadyCallback) {
    super(game, null);

    this._idealDimensions = { // Ideal message dimensions on Facebook
      width: 668,
      height: 354,
    };

    this._objectToLoad = nrOfExtImgs; // Total amount of objects that need to be loaded
    this._objectedLoaded = 0; // The current amount of objects in this image that is loaded
    this._onAllImagesReadyCallback = onAllImagesReadyCallback;

    this.onReady = new Phaser.Signal();
    this.ready = false;
  }

  /**
   * Creates an external image on this display object
   * When external image finishes loading, the objectsLoaded counter will increment.
   * @param {number} x
   * @param {number} y
   * @param {string} url
   * @param {number || Array<number>} anchor
   * @param {number} width
   * @param {number} height
   * @param {function} callback
   */
  _addExtImage(x, y, url, anchor, width, height, callback = null) {
    const placeholder = G.makeExtImage(x, y, url, null, anchor, this, false, (img) => {
      // make fallback image if failed
      if (img === null) {
        img = G.makeImage(x, y, 'avatar_m', anchor, this);
        // console.log("IMAGE MAKE FAIL, URL: " + url);
      } else {
        // console.log("IMAGE MAKE SUCCESS, URL: " + url);
      }

      if (width || height) {
        img.width = width;
        img.height = height || width;
      }

      this._objectLoadComplete();

      if (callback) callback(img);
    }, true);

    if (width || height) {
      placeholder.width = width;
      placeholder.height = height || width;
    }

    return placeholder;
  }

  /**
   * Increment the number of objects loaded.
   * May be called from extended classes
   */
  _objectLoadComplete() {
    this._objectedLoaded++;
    if (this._objectToLoad === this._objectedLoaded) {
      this.ready = true;
      game.time.events.add(100, this.onReady.dispatch, this.onReady);
      if (this._onAllImagesReadyCallback) this._onAllImagesReadyCallback(this);
    }
  }
}
