import { OMT_DataCache } from '@omt-components/Services/Utils/OMT_DataCache';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { KEY_MAPPINGS, getSaveStateDataKeys, DATA_KEYS } from './SaveStateKeyMappings';

/**
 * class for migrating and managing data from the legacy format to individual keys.
 */
export class SaveStateKeyValueManager {
  /**
   * constructor
   */
  constructor() {
    this._cache = new OMT_DataCache();
    this._validKeys = getSaveStateDataKeys();
  }

  /**
   * run the data migration for legacy parameters as defined in KEY_MAPPINGS.
   * @param {Object} legacyData
   * @returns {Array.<string>} list of legacy parameters deleted.
   */
  runLegacyMigrationProcess(legacyData) {
    const legacyParamsDeleted = [];
    for (const mapping of KEY_MAPPINGS) {
      // if no legacyParam to migrate continue to next mapping
      if (!mapping.legacyParam) continue;

      // migrate legacy parameter if necessary
      this._migrateData(legacyData, mapping.dataKey, mapping.legacyParam, mapping.legacyConvertFunc);

      // delete legacy parameter if flagged and present
      this._deleteLegacyParameter(legacyData, mapping.legacyParam, legacyParamsDeleted);
    }
    return legacyParamsDeleted;
  }

  /**
   * get data by key from user data / cache
   * @param {string} dataKey
   * @param {boolean} useDefaultValue (optional) default true
   * @returns {*}
   */
  getData(dataKey, useDefaultValue = true) {
    if (!this._checkIfValidKey(dataKey)) return null;
    // check for cached value first
    const cachedData = this._cache.getData(dataKey);
    if (cachedData != null) return cachedData;
    // nothing cached get value from user data
    let data = OMT.userData.getUserData(dataKey);
    // nothing in userData look up default value
    if (data == null && useDefaultValue) {
      data = this.getDefaultValue(dataKey);
      this.setData(dataKey, data); // cache and save default
      if (dataKey === DATA_KEYS.BOOSTERS) this._logBoosterStatus(); // TODO: remove logging when enough data gathered
    } else {
      this._cache.setData(dataKey, data); // cache data retreived for the dataKey
    }
    return data;
  }

  /**
   * set data by key from user data / cache
   * @param {string} dataKey
   * @param {*} data
   */
  setData(dataKey, data) {
    if (!this._checkIfValidKey(dataKey)) return;
    this._cache.setData(dataKey, data);
    OMT.userData.writeUserData(dataKey, data);
  }

  /**
   * get the default value for a dataKey
   * @param {string} dataKey
   * @returns {*}
   */
  getDefaultValue(dataKey) {
    for (const mapping of KEY_MAPPINGS) {
      if (dataKey === mapping.dataKey && mapping.defaultValue !== undefined) {
        return mapping.defaultValue;
      }
    }
    return null;
  }

  /**
   * check if a dataKey is valid and defined in KEY_MAPPINGS>
   * @param {string} dataKey
   * @returns {boolean}
   */
  _checkIfValidKey(dataKey) {
    const isValidKey = this._validKeys.indexOf(dataKey) >= 0;
    if (!isValidKey) console.warn(`dataKey:'${dataKey} is not defined in KEY_MAPPINGS'`);
    return isValidKey;
  }

  /**
   * migrate data from G.saveState.data -> userData key value.
   * @param {Object} legacyData
   * @param {string} dataKey
   * @param {string} legacyParam
   * @param {Function} legacyConvertFunc (optional) function to transform legacy data.
   */
  _migrateData(legacyData, dataKey, legacyParam, legacyConvertFunc = null) {
    // migrate old parameter to key value
    let legacyValue = legacyData[legacyParam];
    if (legacyValue != null) {
      if (legacyConvertFunc != null) legacyValue = legacyConvertFunc(legacyValue);
      OMT.userData.writeUserData(dataKey, legacyValue);
      this._cache.setData(dataKey, legacyValue);
      OMT_Utils.stylizedLog(`SaveStateKeyValueManager: legacy params migrated '${legacyParam}': ${legacyValue} to key value '${dataKey}'`, '#FFFF00');
    }
  }

  /**
   * delete legacy paramter from save data.
   * @param {Object} legacyData
   * @param {string} legacyParamName
   * @param {Array} legacyParamsDeleted list of keys deleted from legacy data.
   */
  _deleteLegacyParameter(legacyData, legacyParamName, legacyParamsDeleted) {
    if (legacyData[legacyParamName] == null) return;
    delete legacyData[legacyParamName];
    legacyParamsDeleted.push(legacyParamName);
  }

  /**
   * log for debugging booster issues
   */
  _logBoosterStatus() {
    if (G.saveState.getLastPassedLevelNr() > 40) {
      OMT.userData.logUserDataStatus('default value applied');
    }
  }
}
