import MysteryGiftHeader from './G.MysteryGiftHeader';

export default class MysteryGiftHeaderInGame extends MysteryGiftHeader {
  constructor(peak) {
    super(peak);

    this.state = game.state.getCurrentState();
    this.board = this.state.board;

    this.x = this.board.x + (this.board.width - this.board.tileSize * 2) * 0.5;
    this.y = -300;

    game.add.tween(this).to({ y: 80 }, 600, Phaser.Easing.Sinusoidal.Out, true, 0)
      .onComplete.add(this._tweenComplete.bind(this));
  }

  _tweenComplete() {
    game.add.tween(this).to({ y: -300 }, 600, Phaser.Easing.Sinusoidal.In, true, 2500);
  }
}
