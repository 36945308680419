/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
G.Reasons = function Reasons(optChangeListener, optChangeListenerContext) {
  const hasAnyChangedSignal = new Phaser.Signal();
  const reasons = new Map();

  optChangeListener && hasAnyChangedSignal.add(optChangeListener, optChangeListenerContext);

  return {
    add,
    remove,
    setPresence,
    hasAnyChangedSignal,
    has,
    hasAny,
    hasNone,
  };

  function setPresence(reason, present) {
    if (present) {
      add(reason);
    } else {
      remove(reason);
    }
  }

  function add(reason, optIncrement) {
    const hadAny = hasAny();
    reasons.set(
      reason,
      optIncrement ? (reasons.get(reason) || 0) + 1 : (reasons.get(reason) || 1),
    );
    if (!hadAny && hasAny()) {
      hasAnyChangedSignal.dispatch(true);
    }
  }

  function remove(reason, optDecrement) {
    const hadAny = hasAny();
    if (optDecrement) {
      const newCount = (reasons.get(reason) || 0) - 1;
      reasons.set(reason, newCount);
    } else {
      reasons.delete(reason);
    }
    if (hadAny && !hasAny()) {
      hasAnyChangedSignal.dispatch(false);
    }
    return !has(reason);
  }

  function has(reason) {
    return reasons.has(reason) && reasons.get(reason) > 0;
  }

  function hasAny() {
    return !hasNone();
  }

  function hasNone() {
    return getActiveReasonsCount() === 0;
  }

  function toString() {
    return (
      Array.from(reasons.keys())
    ).map((reason) => reason.toString()).join(', ');
  }

  function getActiveReasonsCount() {
    let count = 0;
    reasons.forEach((value) => {
      if (value > 0) {
        ++count;
      }
    });
    return count;
  }
};
