import TutorialGrid from '../BoardAnim/G.TutorialGrid';
import TutorialGroup from '../BoardAnim/TutorialGroup';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';

const tileSize = 90;
const mechanicSprite = 'status_chain_1';
const gridScale = 1.2;

/**
 * RopeBreakTutorial explaining things with ropes!
 * Please look at TutorialGroup to look at what those funky tweens are
 * @author Sandra Koo
 */
class RopeBreakTutorial extends TutorialGroup {
  constructor() {
    super(game, null);

    this.currentPage = 0;
    this.pageOne = new Phaser.Group(game, null);
    this.gridBg = undefined;
    this.gems = undefined;
    this.gemEmitter = [];
    this.ropeEmitter = [];
    this.tutHand = undefined;
    this.description = undefined;
    this.continueButton = undefined;

    this.pageTwo = new Phaser.Group(game, null);
    this.goalIcon = undefined;
    this.goalNumber = undefined;

    this.drawEverything();
    this.showPageOne();
  }

  /**
   * Draws all elements
   * Initializes some of them
   */
  drawEverything() {
    this.gridBg = new TutorialGrid(); // Grid bg. Has the background sprite

    this.mechanic = new TutorialGrid(); // For the ropes

    this.gems = new TutorialGrid(); // For the gems

    this.extras = new TutorialGrid(); // Extra grid for dropping down extra candy.

    for (let i = 0; i < 4; i++) { // Particle emitters for the gems
      const emitter = new FxParticle(); // For the sfx
      emitter.scale.setTo(gridScale); // It also grows big
      this.gemEmitter.push(emitter);
    }

    for (let i = 0; i < 2; i++) { // Particle emitters for the ropes
      const emitter = new FxParticle();
      emitter.scale.setTo(gridScale);
      this.ropeEmitter.push(emitter);
    }

    this.tutHand = new Phaser.Group(game);
    G.makeImage(0, 0, 'tut_hand', 0, this.tutHand); // Haaaand

    this.description = new G.Text(0, -150, ' ', { // The instructions
      style: 'font-blue',
      fontSize: 35,
      lineSpacing: -15,
    }, 0.5, 400, 200, true, 'center');

    this.goalIcon = G.makeImage(-15, 0, 'status_chain_2', 0.5, null); // The goal Icon
    this.goalIcon.scale.setTo(0.8);

    this.goalNumber = new G.Text(32, 0, '1', { // The goal counter next to the goal
      style: 'font-blue',
      fontSize: 40,
    }, 0.5);

    this.continueButton = new OMT_UI_SquareButton(0, 260, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onContinueClick.bind(this),
        },
      },
    });
    this.addChild(this.continueButton);
  }

  /**
   * When continue is clicked
   */
  onContinueClick() {
    this.stopAllActions(); // Stop all tweens
    if (this.currentPage === 1) { // If page one, go to page 2
      this.removeChild(this.pageOne);
      this.showPageTwo();
    } else { // Otherwise close
      this.signals.onContinueClick.dispatch();
      this.destroy();
    }
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this.gems) {
      this.gems.destroy();
    }

    if (this.gridBg) {
      this.gridBg.destroy();
    }

    if (this.mechanic) {
      this.mechanic.destroy();
    }

    if (this.extras) {
      this.extras.destroy();
    }

    this.pageOne.destroy();
    this.pageTwo.destroy();

    super.destroy();
  }

  /**
   * Positions all grids
   * @param {number} targetX
   * @param {number} targetY
   */
  positionGrids(targetX, targetY) {
    this.gridBg.x = targetX;
    this.gridBg.y = targetY;

    this.mechanic.x = targetX;
    this.mechanic.y = targetY;

    this.gems.x = targetX;
    this.gems.y = targetY;

    this.extras.x = targetX;
    this.extras.y = targetY - tileSize; // Is a little offset above
  }

  /**
   * Shows the first part of the tutorial
   */
  showPageOne() {
    this.currentPage = 1;
    const gridX = 3;
    const gridY = 2;

    this.description.setText(OMT.language.getText('Match %gems% tied with %ropes% to collect the %rope%!'));

    this.goalNumber.setText('3');
    this.goalNumber.y = this.description.y + (this.description.height + (this.goalNumber.height * 1.5)) / 2;
    this.goalIcon.y = this.goalNumber.y;

    this.gridBg.init(gridX, gridY, tileSize, 'tut_tile', gridScale);

    // init grids with candies
    const mechanicData = [0, 0, 1,
                          0, 1, 0].map((num) => (num === 0 ? null : mechanicSprite)); // eslint-disable-line indent
    this.mechanic.init(gridX, gridY, tileSize, mechanicData, gridScale); // Passes in an array of null/string to denote which parts have nothing and which have the icon

    const gemsData = [5, 5, 2,
                      2, 2, 1].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    this.positionGrids(0, this.goalIcon.y + this.goalIcon.height + this.gridBg.height / 2);

    this.tutHand.alpha = 0; // Hide this
    this.pageOne.alpha = 0;

    this.addChild(this.description);
    this.pageOne.addChild(this.goalNumber);
    this.pageOne.addChild(this.goalIcon);
    this.pageOne.addChild(this.gridBg);
    this.pageOne.addChild(this.gems);
    this.pageOne.addChild(this.mechanic);
    for (let i = 0; i < 2; i++) { // I only need 2 gem emitters
      this.pageOne.addChild(this.gemEmitter[i]);
    }
    for (let i = 0; i < this.ropeEmitter.length; i++) {
      this.pageOne.addChild(this.ropeEmitter[i]);
    }
    this.pageOne.addChild(this.tutHand);
    this.addChild(this.pageOne);
    this.addChild(this.continueButton);

    this.animatePageOne();
  }

  /**
   * Animates page one
   */
  async animatePageOne() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 1; // Modifies the shouldEventComplete by checking the page

    // Handy function because I'll need a lot of gems
    const getGemData = (board, cellX, cellY) => ({
      sprite: board.getSpriteByCell(cellX, cellY),
      pos: board.getPxPos(cellX, cellY),
    });

    const gem20 = getGemData(this.gems, 2, 0);
    const gem21 = getGemData(this.gems, 2, 1);
    const rope20 = getGemData(this.mechanic, 2, 0);
    const rope11 = getGemData(this.mechanic, 1, 1);
    const iconPos = this.gems.toLocal(this.goalIcon.position, this.pageOne); // Position of the goal icon
    const fxGemTargets = [getGemData(this.gems, 1, 1), gem21]; // Target gems for the sfx

    this.tutHand.pivot.copyFrom(this.gems.pivot); // Copies and sets hand pivot to the grid

    const tweenGo = async () => {
      // Reset EVERYTHING
      this.tutHand.scale.set(1); // Hand is normal sized, in position, hidden
      this.tutHand.position.set(this.gems.x + gem20.pos.x, this.gems.y + gem20.pos.y);
      this.tutHand.alpha = 0;
      gem20.sprite.position.copyFrom(gem20.pos);
      gem21.sprite.position.copyFrom(gem21.pos);
      rope20.sprite.position.copyFrom(rope20.pos);
      rope20.sprite.alpha = 1;
      rope20.sprite.scale.set(gridScale);
      rope11.sprite.position.copyFrom(rope11.pos);
      rope11.sprite.alpha = 1;
      rope11.sprite.scale.set(gridScale);
      this.goalNumber.setText('3'); // Goal made to look normal

      await this.wrapTween(this.pageOne, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page one fades in
      await this.wrapTween(this.tutHand, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand fades in
      await this.wait(tweenDelay / 2); // Wait
      await this.wrapTween(this.tutHand.scale, { x: 0.9, y: 0.9 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand clicks
      this.wrapTween(gem20.sprite, gem21.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The above moves down
      this.wrapTween(gem21.sprite, gem20.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The below moves up
      this.wrapTween(rope20.sprite, gem21.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The above moves down
      await this.wrapTween(this.tutHand, { x: this.gems.x + gem21.pos.x, y: this.gems.y + gem21.pos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand moves
      this.wrapTween(this.tutHand, { alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand fades out quick
      this.wrapTween(this.tutHand.scale, { scale: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand unclicks (but fades out before you can see)
      for (let i = 0; i < fxGemTargets.length; i++) {
        this.gemEmitter[i].burstCandy(this.gems.x + fxGemTargets[i].pos.x - tileSize, this.gems.y + fxGemTargets[i].pos.y - tileSize / 2, 2); // sfx happens
      }
      await this.wait(tweenTime * 0.5);
      this.wrapTween(rope20.sprite.scale, { x: 1.5, y: 1.5 }, tweenTime, Phaser.Easing.Back.InOut); // ropes grow big
      await this.wrapTween(rope11.sprite.scale, { x: 1.5, y: 1.5 }, tweenTime, Phaser.Easing.Back.InOut);
      await this.wait(tweenTime);
      this.wrapTween(rope20.sprite.scale, { x: 0.8, y: 0.8 }, tweenTime, Phaser.Easing.Back.InOut); // ropes go to the goal icon
      this.wrapTween(rope11.sprite.scale, { x: 0.8, y: 0.8 }, tweenTime, Phaser.Easing.Back.InOut);
      this.wrapTween(rope11.sprite, { x: iconPos.x, y: iconPos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wrapTween(rope20.sprite, { x: iconPos.x, y: iconPos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      this.goalNumber.setText('1'); // Goal number updates
      this.wrapTween(rope20.sprite.scale, { x: 2, y: 2 }, tweenTime, Phaser.Easing.Back.InOut); // ropes woosh out into inexistance
      this.wrapTween(rope11.sprite.scale, { x: 2, y: 2 }, tweenTime, Phaser.Easing.Back.InOut);
      this.wrapTween(rope20.sprite, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wrapTween(rope11.sprite, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wait(tweenDelay); // Wait
      await this.wrapTween(this.pageOne, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page fades out

      if (this.shouldEventComplete() && this.currentPage === 1) { // Repeats
        tweenGo();
      }
    };
    tweenGo(); // Triggers tweens
  }

  /**
   * Shows page two
   */
  showPageTwo() {
    this.currentPage = 2;

    const gridX = 4;
    const gridY = 2;

    this.description.setText(OMT.language.getText('%Ropes% can also be collected by using Line Blasts!'));

    this.goalNumber.setText('3');
    this.goalNumber.y = this.description.y + (this.description.height + (this.goalNumber.height * 1.5)) / 2;
    this.goalIcon.y = this.goalNumber.y;

    this.gridBg.init(gridX, gridY, tileSize, 'tut_tile', gridScale);

    // Init grids
    const mechanicData = [0, 0, 0, 1,
                          0, 0, 0, 0].map((num) => (num === 0 ? null : mechanicSprite)); // eslint-disable-line indent
    this.mechanic.init(gridX, gridY, tileSize, mechanicData, gridScale); // Passes in an array of null/string to denote which parts have nothing and which have the icon

    const gemsData = ['5_bonus_1', '5', '3', '1',
                      '2', '2', '5', '3'].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    const extraData = [1, 2, 3, 0,
                       0, 0, 0, 0].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.extras.init(gridX, gridY, tileSize, extraData, gridScale);

    this.positionGrids(0, this.goalIcon.y + this.goalIcon.height + this.gridBg.height / 2);

    this.tutHand.alpha = 0; // Hide these
    this.pageTwo.alpha = 0;

    this.addChild(this.description);
    this.pageTwo.addChild(this.goalNumber);
    this.pageTwo.addChild(this.goalIcon);
    this.pageTwo.addChild(this.gridBg);
    this.pageTwo.addChild(this.gems);
    this.pageTwo.addChild(this.extras);
    this.pageTwo.addChild(this.mechanic);
    for (let i = 0; i < this.gemEmitter.length; i++) {
      this.pageTwo.addChild(this.gemEmitter[i]);
    }
    for (let i = 0; i < 1; i++) { // I only need 1 rope emitter
      this.pageTwo.addChild(this.ropeEmitter[i]);
    }
    this.pageTwo.addChild(this.tutHand);
    this.addChild(this.pageTwo);
    this.addChild(this.continueButton);

    this.animatePageTwo();
  }

  /**
   * Animates page two
   */
  animatePageTwo() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 2; // Modifies shouldEventComplete by checking the page

    // Handy function because I'll need a lot of gems
    const getGemData = (board, cellX, cellY) => ({
      sprite: board.getSpriteByCell(cellX, cellY),
      pos: board.getPxPos(cellX, cellY),
    });

    const gem00 = getGemData(this.gems, 0, 0);
    const gem10 = getGemData(this.gems, 1, 0);
    const gem20 = getGemData(this.gems, 2, 0);
    const gem30 = getGemData(this.gems, 3, 0);
    const gem01 = this.gems.getPxPos(0, 1);
    const gem11 = this.gems.getPxPos(1, 1);
    const gem21 = getGemData(this.gems, 2, 1);
    const rope30 = getGemData(this.mechanic, 3, 0);
    const extra00 = getGemData(this.extras, 0, 0);
    const extra10 = getGemData(this.extras, 1, 0);
    const extra20 = getGemData(this.extras, 2, 0);
    const iconPos = this.gems.toLocal(this.goalIcon.position, this.pageTwo); // Position of the goal icon
    const fxGemTargets = [gem00, gem10, gem20, gem30]; // Target gems for the sfx
    const fadeOutGems = [gem00, gem10, gem21];

    this.tutHand.pivot.copyFrom(this.gems.pivot); // Copies and sets hand pivot to the grid

    const tweenGo = async () => {
      // Reset EVERYTHING
      this.tutHand.scale.set(1); // Hand is normal sized, in position, hidden
      this.tutHand.position.set(this.gems.x + gem21.pos.x, this.gems.y + gem21.pos.y);
      this.tutHand.alpha = 0;
      gem00.sprite.position.copyFrom(gem00.pos);
      gem00.sprite.alpha = 1;
      gem10.sprite.position.copyFrom(gem10.pos);
      gem10.sprite.alpha = 1;
      gem20.sprite.position.copyFrom(gem20.pos);
      gem30.sprite.position.copyFrom(gem30.pos);
      gem30.sprite.alpha = 1;
      gem21.sprite.position.copyFrom(gem21.pos);
      gem21.sprite.alpha = 1;
      rope30.sprite.position.copyFrom(rope30.pos);
      rope30.sprite.alpha = 1;
      rope30.sprite.scale.set(gridScale);
      extra00.sprite.position.copyFrom(extra00.pos);
      extra00.sprite.alpha = 0;
      extra10.sprite.position.copyFrom(extra10.pos);
      extra10.sprite.alpha = 0;
      extra20.sprite.position.copyFrom(extra20.pos);
      extra20.sprite.alpha = 0;
      this.goalNumber.setText('3'); // Goal made to look normal

      await this.wrapTween(this.pageTwo, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page two fades in
      await this.wrapTween(this.tutHand, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand fades in
      await this.wait(tweenDelay / 2); // Wait
      await this.wrapTween(this.tutHand.scale, { x: 0.9, y: 0.9 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand clicks
      this.wrapTween(gem20.sprite, gem21.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The above moves down
      this.wrapTween(gem21.sprite, gem20.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The below moves up
      await this.wrapTween(this.tutHand, { x: this.gems.x + gem20.pos.x, y: this.gems.y + gem20.pos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand moves
      this.wrapTween(this.tutHand, { alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand fades out quick
      this.wrapTween(this.tutHand.scale, { scale: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand unclicks (but fades out before you can see)
      for (let i = 0; i < fxGemTargets.length; i++) {
        // eslint-disable-next-line no-loop-func
        _.delay(() => {
          if (i === 3) { // On the last gem target, the rope is there
            this.ropeEmitter[0].burstChainAnim(this.gems.x + fxGemTargets[i].pos.x - tileSize * 1.5, this.gems.y + fxGemTargets[i].pos.y - tileSize / 2); // rope breaks
          }
          this.gemEmitter[i].burstCandy(this.gems.x + fxGemTargets[i].pos.x - tileSize * 1.5, this.gems.y + fxGemTargets[i].pos.y - tileSize / 2, 5); // sfx happens
          if (fadeOutGems[i]) {
            this.wrapTween(fadeOutGems[i].sprite, { alpha: 0 }, tweenTime / 4, Phaser.Easing.Sinusoidal.InOut); // Disappearing tween also happens if it should disappear
          }
        }, i * (tweenTime / 4));
      }
      await this.wait(tweenTime * 1.25);
      // Gems drop down
      this.wrapTween(extra00.sprite, gem01, tweenTime / 2, Phaser.Easing.Back.Out);
      this.wrapTween(extra00.sprite, { alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(extra10.sprite, gem11, tweenTime / 2, Phaser.Easing.Back.Out);
      this.wrapTween(extra10.sprite, { alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
      this.wrapTween(extra20.sprite, gem21.pos, tweenTime / 2, Phaser.Easing.Back.Out);
      this.wrapTween(extra20.sprite, { alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
      await this.wrapTween(rope30.sprite.scale, { x: 1.5, y: 1.5 }, tweenTime, Phaser.Easing.Back.InOut); // Rope grows big
      await this.wait(tweenTime);
      this.wrapTween(rope30.sprite, { x: iconPos.x, y: iconPos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Rope goes to goal icon
      await this.wrapTween(rope30.sprite.scale, { x: 0.8, y: 0.8 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      this.goalNumber.setText('2'); // Goal number updates
      this.wrapTween(rope30.sprite.scale, { x: 2, y: 2 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Goal gem grows really big and fades out
      await this.wrapTween(rope30.sprite, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wait(tweenDelay); // Wait
      await this.wrapTween(this.pageTwo, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page fades out

      if (this.shouldEventComplete() && this.currentPage === 2) { // Repeats
        tweenGo();
      }
    };
    tweenGo(); // Triggers tweens
  }
}

// create global references
G.RopeBreakTutorial = RopeBreakTutorial;
