import { RMWHEEL_EPS } from '../SpinningWheels/RealMoneyWheel/rmWheelEnums';
import TargetedOfferDataManager, {
  ENTRY_POINTS as TARGETED_OFFER_ENTRY_POINTS,
  TARGETED_OFFER_IDS,
} from '../../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { BOT_MESSAGE_TYPE } from '../../Services/OMT/OMT_Bots';
import TreasureHuntManager, { TREASURE_HUNT_BOT_MSG_END, TREASURE_HUNT_BOT_MSG_START } from '../../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';

G.WorldMapAppearFlow = G.WorldMapAppearFlow || {};

let flowCheckCount = 0; // running count of how many times the flow has been checked

export default class FlowManager {
  constructor() {
    this.payloadChecker = null;
    this.saveStateMgr = null;
    this.initialized = false;
    this.doneChecks = {
      daily: false,
      payload: false,
    };
    this.onPopUpRequested = new Phaser.Signal();
    this.minutesUntilPayloadExpires = 72 * 60;
  }

  /**
   * Initializing some of our properties.
   */
  init() {
    // fill imports
    this.payloadChecker = G.WorldMapAppearFlow.payloadChecker;
    this.saveStateMgr = G.saveState;
    this.initialized = true;
  }

  /**
   * Start the game flow. This happens every time the player enters the saga map.
   * We check entry point payloads, and then update certain things like daily missions, events, and the mystery gift.
   * @param {object} lastLevelData The data of whichever level the player was last in. Can be null
   * @returns {Promise}
   */
  async startFlow(lastLevelData) {
    if (!this.initialized) this.init();

    flowCheckCount++; // increment the flow checked count, this is done first as returns may break flow
    // eslint-disable-next-line no-unused-vars
    const { payload, entryPoint } = OMT.envData;

    // check for tournament entry and show popup if required, skip other checks
    const ftuStartInGameState = G.firstTime === true;
    if (flowCheckCount === 1 && OMT.platformTournaments.tournamentContextId !== null && !ftuStartInGameState) {
      await OMT.platformTournaments.switchToTournamentContext(true);
      const score = await OMT.platformTournaments.getTournamentHighScore();
      G.sb('pushWindow').dispatch(['tournament', score, G.firstTime === true]);
      return; // we dont want to activate other events / windows
    }

    let continueDaily = true;
    if (payload) { // check game payloads
      // linking the payload checker's pop up signal to ours, so we only have to add the listener to the flowMgr and it triggers both.
      this.payloadChecker.onPopUpRequested.add(this.onPopUpRequested.dispatch, this.onPopUpRequested);

      const currentTime = new Date().getTime();
      const expiryTime = this._getBotMsgExpiryTime(payload);
      // convert to ms since thats what Date.getTime uses
      const expiryTimeInMs = (expiryTime * 60) * 1000;
      // if the time between now and when the payload is sent exceeds the current time, its expired.
      // however, we don't care about this if its not a bot message
      const hasExpired = currentTime - payload.timeSent > expiryTimeInMs
        // && OMT.bots.entryPointIsBotMsg !== BOT_MESSAGE_TYPE.NOT_A_BOT_MESSAGE
        && !(payload.additions && payload.additions.restoreProgression);

      if (hasExpired) {
        // clear the current payload
        OMT.envData.rejectPayload();
        // if its expired, we push a window to tell the player it has expired.
        G.sb('pushWindow').dispatch(['BotRewardExpired', expiryTime], null, null, true);
      } else if (!this.doneChecks.payload) {
        continueDaily = continueDaily && (await this.payloadChecker.checkPayloadAtWorld(payload) || true);
        continueDaily = continueDaily && (this.payloadChecker.checkBotMsgAtWorld() || true);
        this.doneChecks.payload = true;
      }
    }

    if (continueDaily) {
      let checkTHAgain = this._checkReoccuringTreasureHunt();
      this.checkDaily();
      if (checkTHAgain) { checkTHAgain = this._checkReoccuringTreasureHunt(true); }
      this.checkMysteryGift(lastLevelData);
      this.checkEvents();
      this.checkLoyalty();
      this.checkPendingSpins();
      this.checkEventsLast({ treasureHunt: checkTHAgain });
    }
  }

  /**
   * Goes through a lot of checking to return the time the expiry time of the bot message
   * @param {{additions?:{expireTime?:number}}} payload
   * @returns {number}
   */
  _getBotMsgExpiryTime(payload) {
    // if the payload has a prop for this, use it; otherwise use fallback
    if (payload.additions && payload.additions.expireTime) {
      return payload.additions.expireTime;
    }

    const entryPointDataMessageId = OMT.envData.entryPointData.messageId;
    if (entryPointDataMessageId && ((entryPointDataMessageId.includes(TREASURE_HUNT_BOT_MSG_START) || entryPointDataMessageId.includes(TREASURE_HUNT_BOT_MSG_END)))) {
      // Its a treasure hunt bot message...
      return 2 * 7 * 24 * 60; // 2 weeks in minutes
    }

    return this.minutesUntilPayloadExpires;
  }

  /**
   * Cleaning up signal listeners, letting the payload checker do the same
   */
  cleanup() {
    this.onPopUpRequested.removeAll();
    if (this.payloadChecker) {
      this.payloadChecker.cleanup();
    }
  }

  /**
   * Check to see if we should show the mystery gift window
   * @param {object} lastLevelData The data of the level the player was just in.
   */
  checkMysteryGift(lastLevelData) {
    /* mystery gift window pops up on either:
    *  1) the player has mystery gifts (streak > 0) and they have expired, or
    *  2) building a streak that is < 4
    */
    if (!G.MYSTERYGIFT) return;
    // if mystery streak is enabled, and not on cooldown, and we're beginning a streak, show the window.
    // this seems to be overridden if the last level has data for it
    if (this.saveStateMgr.mysteryGiftManager.getCurrentStreak() > 0
      && !this.saveStateMgr.mysteryGiftManager.isModeReady()
      && this.saveStateMgr.mysteryGiftManager.getRemainingActiveTime() === 0) {
      this.onPopUpRequested.dispatch('mysteryGiftStreakIncrese');
    } else if (lastLevelData && lastLevelData.mysteryGiftStreakIncrease && this.saveStateMgr.mysteryGiftManager.getCurrentStreak() < 4) {
      this.onPopUpRequested.dispatch('mysteryGiftStreakIncrese');
    }
  }

  /**
   * Check to see if we should show the daily gift window.
   */
  async checkDaily() {
    // ensure we only check the daily rewards once, not every time the player returns to the saga map.
    if (this.doneChecks.daily) return;

    const loginStats = this.saveStateMgr.getLoginStats();
    if (!loginStats.firstDay) {
      this.doneChecks.daily = true;
      const dayToShow = await G.Helpers.dailyRewardMgr.reportVisit();
      if (dayToShow !== null) {
        let extraText = '';
        let extraTextDoubled = '';

        const dailyGifts = dayToShow.getGifts();

        if (dailyGifts[0][0].indexOf('coin') === 0) {
          extraText = OMT.language.getText('More Coins!');
          extraTextDoubled = OMT.language.getText('Even more Coins!');
        } else {
          // eslint-disable-next-line no-lonely-if
          if (
            dailyGifts[0][1] > 1
            || dailyGifts.length > 1
          ) {
            extraText = (`${OMT.language.getText('%#% Boosters').replace('%#%', '2')}!`);
            extraTextDoubled = (`${OMT.language.getText('%#% Boosters').replace('%#%', '4')}!`);
          } else {
            extraText = `${OMT.language.getText('1 Booster')}!`;
            extraTextDoubled = (`${OMT.language.getText('%#% Boosters').replace('%#%', '2')}!`);
          }
        }
        if (dailyGifts) {
          this.onPopUpRequested.dispatch(['dailyReward', dayToShow, () => {
            this.onPopUpRequested.dispatch(['gift', {
              reason: G.GiftUI.Constants.GiftReasons.DailyReward,
              placement: G.BuildEnvironment.adPlacements.doubleDailyReward,
              monetiseType: game.incentivised() && dayToShow.isDoubleable() ? 'double' : false,
              adTracking: {
                requestEvent: 'VideoAdWatchDailyReward',
              },
              twoFrameOpen: G.OMTsettings.elements.gifts.twoFrameOpen,
              resourceTracking: {
                itemType: 'Reward',
                itemId: 'NewDailyReward',
              },
              showGingy: false,
              showTitleBar: false,
              giftBoxType: 'retro',
              customExtraText: extraText,
              customExtraTextDoubled: extraTextDoubled,
              shareable: true,
              autoOpen: true,
              overlay: true,
              autoOpenDelay: 1600,
              coinFeedback: false,
              incentivisedSharing: false,
              skipGiftProcessing: true,
              onlyOneBox: true,
              hideShowMainHighscorePanel: false,
              buttonsOffset: {
                x: 0,
                y: -55,
              },
              gifts: G.Utils.clone(dailyGifts),
            }], undefined, G.WindowMgr.LayerNames.Base, true);
          }], undefined, G.WindowMgr.LayerNames.Base);
        }
      }
    }
  }

  /**
   * Checks events and whether or not it needs to push a window
   */
  checkEvents() {
    if (OMT.feature.getEventPostcardFeature(true)) {
      if (!G.saveState.data.eventMsg || G.saveState.data.eventMsg.indexOf(G.OMTsettings.postcardEvent.eventId) === -1) {
        G.sb('pushWindow').dispatch(['eventPostcardPromo'], null, G.WindowMgr.LayerNames.Base);
      }
    } else if (OMT.feature.isTokenEventOn(true, false)) {
      if (G.OMTsettings.tokenEvent.popupOfferLayout.showTargetOffer) {
        TargetedOfferDataManager.getInstance().showPopupOfferIfPossible(
          [TARGETED_OFFER_IDS.NON_PAYER_TOKENEVENT, TARGETED_OFFER_IDS.PAYER_TOKENEVENT], TARGETED_OFFER_ENTRY_POINTS.GAME_STARTED,
        );

        if (OMT.feature.isTokenEventOn(true, true) && G.saveState.tokenEventManager.FTUE.to) {
          G.saveState.tokenEventManager.FTUE.to = false;
          G.sb('pushWindow').dispatch('eventPostcard', {
            startIndex: G.saveState.tokenEventManager.getPostcardLastOpenedIndex(),
          });
        }
      } else if (G.saveState.tokenEventManager.FTUE.ee) {
        G.sb('pushWindow').dispatch('eventEntry');
      }
    }
  }

  /**
   * Checks events, but only as a last window
   * @param {{ treasureHunt:boolean }} checkFlags
   */
  checkEventsLast(checkFlags) {
    if (OMT.feature.isTokenEventOn(true, false, false, true) && G.saveState.tokenEventManager.FTUE.ee) {
      G.sb('pushWindow').dispatch('eventEntry');
    } else if (OMT.feature.isTreasureHuntOn(true, true, false, true)
      && checkFlags.treasureHunt
      && !G.saveState.treasureHuntManager.contentEntry
      && !G.saveState.treasureHuntManager.sessionData.onLoadPopupPending) {
      G.saveState.treasureHuntManager.sessionData.onLoadPopupPending = true;
      G.sb('pushWindow').dispatch('treasureHuntActiveNotice');
    }
  }

  /**
   * Checks for real money wheel spins that were bought or given previously
   * that the player never claimed
   */
  checkPendingSpins() {
    const unclaimedSpins = G.saveState.getPendingRealMoneyWheelSpins();

    let hasUnclaimedSpins = false;
    for (const type in unclaimedSpins) {
      if (Object.prototype.hasOwnProperty.call(unclaimedSpins, type)) {
        hasUnclaimedSpins = hasUnclaimedSpins || unclaimedSpins[type].length > 0;
      }
    }

    if (hasUnclaimedSpins) {
      G.saveState.sortPendingRealMoneyWheelSpins();
      G.sb('pushWindow').dispatch(['realMoneyWheel', {
        entryPoint: RMWHEEL_EPS.EPPayload,
        freeSpin: false,
        resolvePendingSpins: true,
        worldState: true,
      }], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
    }
  }

  /**
   * Checks if the first time pop up for loyalty support is available for the user
   */
  checkLoyalty() {
    if (!OMT.feature.isLoyaltySupportAvailable()) { return; } // No support, not pop up

    // Is loyal and hasn't seen the pop up
    if (G.saveState.loyaltyManager.isLoyal && !G.saveState.loyaltyManager.firstTimePopup) {
      G.sb('pushWindow').dispatch('loyaltyMessage');
    }
  }

  /**
   * Tries to get the user to play the treasure hunt some more.
   * Checks if they played or not to show the proper window
   * @returns {Boolean}
   */
  _checkReoccuringTreasureHunt(checkWeek) {
    if (!OMT.feature.isTreasureHuntOn(false, false, false, true)) return false;
    if (!G.saveState.treasureHuntManager.inActiveCycle) return false;

    const lastParticipation = G.saveState.treasureHuntManager.lastParticipationTime;
    if (!G.saveState.treasureHuntManager.updateMessageSeen && lastParticipation > 0 && G.featureUnlock.treasureHunt.showUpdate) {
      G.sb('pushWindow').dispatch('treasureHuntUpdate');
      return false;
    }
    if (checkWeek && G.saveState.treasureHuntManager.eligibleForReminder() && !G.saveState.treasureHuntManager.sessionData.onLoadPopupPending) {
      G.saveState.treasureHuntManager.sessionData.onLoadPopupPending = true;
      G.saveState.treasureHuntManager.sessionData.reminderPopup = true;
      TreasureHuntManager.openTreasureHuntPopup();
      return false;
    }
    return true;
  }
}

G.WorldMapAppearFlow.flowMgr = new FlowManager();
