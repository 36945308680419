/* eslint-disable no-unused-vars */
import { BoardGameHooks } from '../../BoardGameHooks';

/**
 * base class for Candy status effects Chains, infections, etc.
 * These are children of the Candies essenitally.
 */
export class CandyStatus {
  /**
   * constructor
   * @param {BoardGameHooks} gameHooks
   */
  constructor(gameHooks) {
    this._gameHooks = gameHooks;
    this._wrapperImg = null;
    this._hp = null;

    this._defineSignals();
  }

  /**
   * define phaser signals
   */
  _defineSignals() {
    this.signals = {
      onRemove: new Phaser.Signal(),
      onHpChange: new Phaser.Signal(),
      checkIfIsMatching: new Phaser.Signal(),
    };
  }

  /**
   * main intialization method
   * @param {Candy} candy
   * @param {Object} config
   */
  init(candy, config) {
    this._config = config;
    this._candy = candy;
    this._postRandomPhase = candy.postRandomPhase;
    this._editorSymbol = G.Utils.defined(config.editorSymbol, '');
  }

  /**
   * check if status should block a move.
   * @returns {boolean}
   */
  isBlockingMove() {
    return true;
  }

  /**
   * check if status should block a match.
   * @returns {boolean}
   */
  isBlockingMatch() {
    return false;
  }

  /**
   * on parent candy part of a match.
   * @returns {boolean}
   */
  onMatch() {
    return true;
  }

  /**
   * on hit by adjacent match.
   * @returns {boolean}
   */
  onHit() {
    return true;
  }

  /**
   * return a match token override.  Override to implement.
   * @param {string} token
   * @returns {string}
   */
  changeMatchToken(token) {
    return token;
  }

  /**
   * check if this status has hp / steps.
   * @returns {boolean}
   */
  isHpBased() {
    return this._config.hpMax || false;
  }

  /**
   * change / update the hp of the status effect
   * @param {number} newHp
   */
  changeHp(newHp) {
    const clampedHp = game.math.clamp(newHp, 0, this._config.hpMax);
    if (this._hp === clampedHp) return;
    const oldHp = this._hp;
    this._hp = clampedHp;

    if (this._hp === 0) {
      this.signals.onRemove.dispatch();
      this.remove();
    } else {
      this.signals.onHpChange.dispatch(this._hp, oldHp);
    }
  }

  /**
   * remove the status. dispatches onRemove signal.
   */
  remove() {
    this.onRemove.dispatch();
  }

  /**
   * mark initial random phase is complete.
   */
  markPostRandomPhase() {
    this._postRandomPhase = true;
  }

  /**
   * inherit matchable from candy.
   * @param {boolean} matchable
   * @returns {boolean}
   */
  passMatchable(matchable) {
    return matchable;
  }

  /**
   * Check if status has tag. Override to implement.
   * @param {string} tag
   * @returns {boolean}
   */
  hasTag(tag) {
    return false;
  }

  /**
   * get the status editor symbol.
   * @returns {string}
   */
  getEditorSymbol() {
    return this._editorSymbol;
  }

  /**
   * loops through status hp stages for the editor.
   */
  changeForEditor() {
    if (!this.isHpBased()) return;
    // console.log('hp', this._hp, 'maxHp', this._config.hpMax);
    if (this._hp === this._config.hpMax) {
      this.changeHp(0);
    } else {
      this.changeHp(this._hp + 1);
    }
  }

  /**
   * export editor data
   * @returns {string}
   */
  export() {
    if (this.isHpBased()) return `${this._editorSymbol}|${this._hp}`;
    return this._editorSymbol;
  }

  /**
   * destruction method
   */
  destroy() {
    Object.keys(this.signals).forEach((key) => { this.signals[key].dispose(); });
    this._candy = null;
    this._gameHooks = null;
  }

  /** GETTER METHODS ********************************* */

  /** @returns {Phaser.Image} candy wrapper / status image */
  get wrapperImg() { return this._wrapperImg; }

  /** @returns {number} get status hp */
  get hp() { return this._hp; }
}
