import { UI_GButtonES6 } from '@omt-components/UI/Buttons/UI_Button.ES6';

/**
 * Music mute button
 */
export default class OMT_UI_MusicButton extends UI_GButtonES6 {
  /**
   * constructor
   */
  constructor() {
    super(0, 0, 'small_button_yellow', () => { this._toggleMusic(); });
    this._icon = G.makeImage(0, 0, 'music_on_icon', [0.5, 0.5]);
    this.addChild(this._icon);
    this._updateIconTexture();
  }

  /**
   * update the icon texture based on mute state
   */
  _updateIconTexture() {
    const musicEnabled = G.soundManager.getMusicEnabled();
    this._icon.changeTexture(musicEnabled ? 'music_on_icon' : 'music_off_icon');
  }

  /**
   * toggle the mute state
   */
  _toggleMusic() {
    const state = !G.soundManager.getMusicEnabled();
    G.soundManager.setMusicEnabled(G.MuteReason.UserChoice, state);
    this._updateIconTexture();
    G.saveState.data.mute = !state;
    G.saveState.save();

    OMT.platformTracking.logEvent(OMT.platformTracking.Events.Music, 1, {
      status: state ? 1 : 0,
    });
  }
}
