/* eslint-disable no-unused-vars */
import { CandyStatus } from './CandyStatus';
import { TOKEN_TYPES, EDITOR_SYMBOLS } from '../../BoardConstants';

/**
 * class for infected / infection candy status.
 */
export class CandyStatus_Infected extends CandyStatus {
  /**
   * constructor
   * @param {BoardGameHooks} gameHooks
   */
  constructor(gameHooks) {
    super(gameHooks);
  }

  /**
   * main intialization method
   * @param {Candy} candy
   * @param {Object} config
   */
  init(candy, config) {
    super.init(candy, config);

    // set wrapper image and animate it in
    this._wrapperImg = G.makeImage(0, 0, 'infection_front', 0.5, candy);
    game.add.tween(this._wrapperImg)
      .from({ alpha: 0, width: 0, height: 0 }, 250, Phaser.Easing.Sinusoidal.Out, true);

    G.sb('onCandyInfect').dispatch(this._candy);
  }

  /**
   * on parent candy part of a match.
   * @returns {boolean}
   */
  onMatch() {
    this.signals.onRemove.dispatch();
    this.remove();
    return false;
  }

  /**
   * remove the status and animate it out.
   */
  remove() {
    G.sb('fxTop').dispatch('burstInfectionAnim', this._candy, this._candy);

    G.stopTweens(this._wrapperImg);
    game.add.tween(this._wrapperImg)
      .to({ alpha: 0 }, 250, Phaser.Easing.Sinusoidal.Out, true)
      .onComplete.add(() => {
        this._wrapperImg.destroy();
      }, this);

    G.sb('onCandyInfectionRemove').dispatch(this._candy);
  }
}
