/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-multi-assign */

import { InitFlowStatusTracker } from './InitFlowStatusTracker';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { omtServicesInit } from './Services/OMT/OMT_Init';
import { Boot } from './States/Boot';
import { Preloader } from './States/Preloader';
import Game from './States/Game';
import { World2 } from './States/World2';
// import { OMT_UserData } from './Services/OMT/OMT_UserData';

/**
 * class for intializing the game / Phaser
 */
class GameLauncher {
  /**
  * first initialization of game systems
  */
  async init() {
    // merge OMTSettings files
    G.featureUnlock = OMT_Utils.mergeSettingsObjects(G.featureUnlockDefault, G.featureUnlock);
    G.OMTsettings = OMT_Utils.mergeSettingsObjects(G.OMTsettingsDefault, G.OMTsettings);
    G.Debug.OutOfMemoryOnBitmapsReporter.register();
    G.sb = G.SignalBox;

    G.Text.setDefaultFont(G.OMTsettings.global.defaultFont);

    this._decompressLevelData();
    await omtServicesInit();
    this._startGame(); // game loading will begin while remaining services initialize

    try { // attempt to connect to game-backend
      await OMT.connect.init();
    } catch (error) {
      console.warn('Could not connect to game-backend.');
    }

    // flag OMT services started
    InitFlowStatusTracker.getInstance().setOMTServicesStarted();
  }

  /**
   * decompress the level data asyncronously. This is required befor the gameplay starts.
   */
  _decompressLevelData() {
    const decompressStartTime = Date.now();
    LZUTF8.decompressAsync(unescape(G.json.levels), { inputEncoding: 'Base64', outputEncoding: 'String' }, (output) => {
      G.json.levels = JSON.parse(output);
      InitFlowStatusTracker.getInstance().setLevelDataReady(); // flag level data ready
      const decompressTime = (Date.now() - decompressStartTime);
      console.log(`LevelDataUnpacked in ${decompressTime} ms`);
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.LevelDataUnpacked, decompressTime);
    });
  }

  /**
   * start phaser and set the game states
   */
  _startGame() {
    const { isSupportedWebGLDevice } = OMT.systemInfo;
    const useWebGL = G.featureUnlock.useWebGLRenderer && isSupportedWebGLDevice;

    let gameWidth = 640;
    let gameHeight = 960;
    if (FBInstant.deviceDectorFunctions().isMobileOrTab()) {
      gameWidth = window.innerWidth;
      gameHeight = window.innerHeight;
    }
    const config = {
      // width: 640,
      // height: 960,
      width: gameWidth,
      height: gameHeight,
      renderer: useWebGL ? Phaser.AUTO : Phaser.CANVAS,
      // antialias: true,
      // multiTexture: false,
      transparent: false,
      enableDebug: true,
      powerPreference: 'high-performance',
      mouseWheel: true, // needed since Phaser CE 2.16
    };
    const game = window.game = new Phaser.Game(config);

    game.state.onStateChange.add(() => {
      game.input.interactiveItems.removeAll();
    });

    game.state.add('Boot', Boot);
    game.state.add('Preloader', Preloader);
    game.state.add('World', World2);
    game.state.add('Game', Game);

    // Now start the Boot state.
    game.state.start('Boot');

    // bodge for 0% error, need to investigate more
    setTimeout(() => {
      if (game.state._pendingState === 'Boot' && game.paused) {
        game.paused = false;
      }
    }, 2000);
  }
}

// set global reference
const gameLauncher = new GameLauncher();
window.initGame = () => {
  gameLauncher.init();
};
