import ShopUtils, { SHOP_EVENT_KEYS } from './Shop_Utils';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import { TextTimer } from '../../TextTimer';

export default class SpecialDeal extends Phaser.Group {
  /**
   * The special deal entry in the coin shop that has the pictures and chest and all the fancy things!
   * @param {Phaser.Game} game
   * @param {Object} config
   * @param {Object} config.catalog
   * @param {string} config.event
   * @param {string} config.event.key
   * @param {string} config.event.string
   */
  constructor(game, config) {
    super(game, null);
    this._currentDeal = config.catalog;
    this._runningEvent = config.event;
    this.rewards = OMT.payments.parseRewards(this._currentDeal);
    this._drawEverything();
  }

  /**
   * Destroy!
   */
  destroy() {
    this.button.onClick.dispose(); // Properly remove signal
    super.destroy();
  }

  /**
   * When the button is clicked
   */
  async _onButtonClick() {
    if (!game) { return; }
    ShopUtils.purchaseItem(this._currentDeal, this, null, true);
  }

  /**
   * Draws all the visuals and layouts them together.
   */
  _drawEverything() {
    const assetList = {};
    if (this._runningEvent) { // event assets indicated by GOMTSettings
      if (this._runningEvent.key === SHOP_EVENT_KEYS.POSTCARD) {
        assetList.background = G.OMTsettings.postcardEvent.shop.background;
        assetList.coinbar = G.OMTsettings.postcardEvent.shop.coinbar;
        assetList.chest = G.OMTsettings.postcardEvent.shop.chest;
      }
    } else { // default regular shop assets
      assetList.background = 'special_deal_bg';
      assetList.coinbar = 'shop2Coinbar';
      assetList.chest = 'special_deal_chest';
    }

    // Background and container
    this.container = new Phaser.Group(game, this);
    const { container } = this;
    const background = G.makeImage(0, 0, assetList.background, 0.5, container);
    background.scale.set(410 / background.width);

    // Setting calculated height and width for layouting OUTSIDE of this entry
    this.calculatedHeight = background.height;
    this.calculatedWidth = background.width;

    const thereAreCoins = this.rewards.coins > 0;
    // The paper tape with the boosters
    const tapeBanner = this._makeBanner();
    tapeBanner.x = -background.width / 4;
    if (thereAreCoins) {
      tapeBanner.y = background.height * 0.15;
    } else {
      tapeBanner.scale.set(1.15);
    }
    container.addChild(tapeBanner);

    // The chest and Wow factor
    const chest = G.makeImage(background.x + background.width / 6, background.y - background.height / 8, assetList.chest, 0.5, container);
    this.chest = chest;
    const chestScale = (background.height * 0.9) / chest.height;
    chest.scale.set(chestScale);
    if (this._currentDeal.description !== '') {
      const value = this._makeWowFactor();
      value.x = Math.round(chest.x + (chest.width / 2));
      value.y = Math.round(chest.y - (chest.height / 2) + value.height / 4);
    }

    // The bar for the number of coins given
    let coinBanner;
    if (thereAreCoins) {
      coinBanner = new Phaser.Group(game, container);
      const banner = G.makeImage(0, 0, assetList.coinbar, 0.5, coinBanner);
      banner.scale.set(0.9);
      const coin = G.makeImage(banner.x - banner.width / 2, -2, 'coin_1', 0.5, coinBanner);
      const coinScale = (banner.height * 1.2) / coin.height;
      coin.scale.set(coinScale);
      const coinText = new G.Text(0, 3, OMT.language.toLocaleNumber(this.rewards.coins), 'shop2-CoinText', 0.5, banner.width - coin.width, null, false, 'center', false);
      coinText.x = Math.round(banner.x + coin.width / 8);
      coinBanner.addChild(coinText);
      coinBanner.x = Math.round(tapeBanner.x + coin.width / 8);
      coinBanner.y = Math.round(tapeBanner.y - (tapeBanner.height + coinBanner.height) / 2);
    }

    // The button
    const button = new G.Button(0, 0, null, this._onButtonClick.bind(this));
    this.button = button;
    const buttonContainer = new Phaser.Group(game, button); // The container to scale the button
    const buttonImage = new OMT_UI_SquareButton(0, 0, { // Without the nineslice affecting it
      button: {
        tint: BUTTONCOLOURS.lightGreen,
        dimensions: {
          width: 196, // original button size
          height: 100,
        },
      },
      options: {
        clickFunction: {
          scaleOnClick: false,
        },
      },
    });
    buttonImage.inputEnabled = false; // So it won't eat inputs. BUTTONSTATE.disabled will automatically set the button to grey
    buttonContainer.addChild(buttonImage);
    const buttonScale = (background.height / 3) / buttonImage.height; // Find the size it needs to be
    buttonContainer.scale.set(buttonScale);
    const buttonModDimensions = { width: (buttonImage.width * buttonScale), height: (buttonImage.height * buttonScale) }; // I don't want to use long version
    const buttonText = new G.Text(0, 1, this._currentDeal.price, 'shop2-SpecialOffer', 0.5, buttonModDimensions.width * 0.8, null, false, 'center', false);
    button.addChild(buttonText);
    button.x = background.x + (background.width / 2) - buttonModDimensions.width / 1.75;
    button.y = background.y + (background.height / 2) - buttonModDimensions.height / 1.6;
    container.addChild(button);

    // Strikeout price
    const strikePrice = new Phaser.Group(game, container);
    this.strikePrice = strikePrice;
    const strikeText = new G.Text(0, 0, this.rewards.fullPrice, 'shop2-FakeOffer', 0.5, buttonModDimensions.width * 0.8, buttonModDimensions.height * 0.8, false, 'center', false);
    const stroke = new Phaser.Graphics(window.game);
    stroke.lineStyle(4, 0x911C24);
    stroke.lineTo(strikeText.width + 5, 0);
    stroke.x = Math.round(2 - stroke.width / 2);
    stroke.y -= 2;
    strikePrice.addChild(strikeText);
    strikePrice.addChild(stroke);
    strikePrice.x = Math.round(button.x - (buttonModDimensions.width + strikePrice.width) / 2);
    strikePrice.y = Math.round(button.y + buttonModDimensions.height / 4);

    // The timer at the top
    let expireTime = G.saveState.getTimeToNextSpecialDeal();
    let timeoutTime = 0;
    if (this._runningEvent) {
      expireTime = null; // Do not set a date
      timeoutTime = this._runningEvent.timeout; // Instead set the sec left
    }
    this.timer = new TextTimer(0, 0, expireTime, 'shop2-EndsIn', 0.5, 210, 'hms', OMT.language.getText('Ends in %TIME%'));
    if (timeoutTime > 0) {
      this.timer.setSecLeft(timeoutTime);
      this.timer.active = true;
    }
    container.addChild(this.timer);
    this.timer.x = Math.round(background.x + 40 - (background.width - this.timer.width) / 2);
    this.timer.y = Math.round(background.y + 5 - (background.height - this.timer.height) / 2);

    // Caching
    background.cacheAsBitmap = true;
    chest.cacheAsBitmap = true;
    // strikePrice.cacheAsBitmap = true;
  }

  /**
   * Makes the booster banner paper tape
   */
  _makeBanner() {
    const paper = G.makeImage(0, 0, 'booster_paper', 0.5, this.container);

    const moreThanTwoBoosters = this.rewards.booster.length > 2;
    const iconDimensions = {
      x: 0,
      y: 0,
      width: moreThanTwoBoosters ? 50 : 55,
      height: moreThanTwoBoosters ? 50 : 55,
    }; // Barebones rect

    let totalWidth = 0;
    const parsedDeal = [];
    for (let i = 0; i < this.rewards.booster.length; i++) {
      const dataPack = {
        prize: this.rewards.booster[i].item.replace('booster#', 'ui_booster_'),
        amount: this.rewards.booster[i].amount,
      };

      const imagePack = new Phaser.Group(game, paper);
      G.makeImage(0, 0, dataPack.prize, 0.5, imagePack); // booster image. Linting doesn't want me to assign variable
      imagePack.width = iconDimensions.width; // Resize the booster image to the correct dimensions
      imagePack.height = iconDimensions.height;
      const amountBg = G.makeImage(0, 0, 'shop2BoosterAmount', 0.5, imagePack); // Then add the extra bit. This is to provide proper layout
      amountBg.x = -((amountBg.width / 2) + iconDimensions.width) / 2;
      amountBg.y = -((amountBg.height / 2) + iconDimensions.height) / 2;
      const text = new G.Text(1, 2, dataPack.amount, 'shop2-boosterAmount', 0.5, amountBg.width, amountBg.height, false, 'center', false); // Text in the center
      amountBg.addChild(text);
      dataPack.image = imagePack;

      parsedDeal.push(dataPack);
      totalWidth += iconDimensions.width;
    }

    const horizontalSpacing = 10;

    // Now positioning elements
    const calculatedWidth = totalWidth + (horizontalSpacing * (parsedDeal.length - 1));
    let nextX = (moreThanTwoBoosters ? -10 : 0) - (calculatedWidth / 2);
    for (let i = 0; i < parsedDeal.length; i++) { // Dynamically placed so 3 or 2 boosters will be equal
      const element = parsedDeal[i];
      element.image.x = nextX + element.image.width / 2;

      nextX += horizontalSpacing + iconDimensions.width;
    }

    paper.angle = -7;
    return paper;
  }

  /**
   * Makes the X# value button on the top right
   */
  _makeWowFactor() {
    const value = G.makeImage(0, 0, 'shop2x2', 0.5, this.container);

    const textPack = new Phaser.Group(game, value);
    const wowText = new G.Text(
      1,
      0,
      this._currentDeal.description.toUpperCase(), 'shop2-ValueDeal',
      0.5,
      value.width * 0.55,
      value.height * 0.55,
      true,
      'center',
    );
    textPack.addChild(wowText);

    const wowValue = new G.Text(
      0,
      Math.round(wowText.height / 2),
      OMT.language.getText('Value'), 'shop2-ValueDeal',
      0.5,
      value.width * 0.7,
      value.height * 0.5,
      true,
      'center',
    );
    textPack.addChild(wowValue);
    textPack.x = 2;
    textPack.y = -1 - Math.round(textPack.height / 8);
    textPack.angle = 15;

    return value;
  }
}
