/**
 * A utility class which holds session data of the player
 */
export class OMT_SessionUtil {
  constructor() {
    this.data = {};
  }

  /**
   * Updates a data in the session
   * @param {object} config An object with key value pairs to update the data
   * @returns {OMT_SessionUtil}
   */
  updateData(config) {
    for (const [key, value] of Object.entries(config)) {
      this.setData(key, value);
    }
    return this;
  }

  /**
   * Creates a sub session data for the given key
   * @param {string} key The key of the new data
   */
  createData(key) {
    const subData = new OMT_SessionUtil();
    this.data[key] = subData;
    return subData;
  }

  /**
   * Gets a value from the session data based on the key provided
   * @param {string} key The key to get the value of
   * @returns {any} value
   */
  getData(key) {
    return this.data[key];
  }

  setData(key, value) {
    this.data[key] = value;
    return this;
  }

  /**
   * Checks whether a key is present in the data
   * @param {string} key The key to check
   * @returns {boolean} result
   */
  checkKey(key) {
    return this.getData(key) !== undefined;
  }

  /**
   * Checks whether a data key is present and creates it with given value if it is not present
   * @param {string} key The key to check
   */
  checkAndCreateWithKey(key) {
    if (this.checkKey(key)) {
      return this.getData(key);
    }

    return this.createData(key);
  }

  /**
   * Get static instance
   */
  static getInstance() {
    if (!this.instance) {
      this.instance = new OMT_SessionUtil();
    }

    return this.instance;
  }
}
