/* eslint-disable operator-linebreak */
class CheckPopUpTutorials {
  static check(state) {
    if (!G.json.tutorials.POPUPTUTORIALS) return;
    if (!state) return;

    _.forEach(G.json.tutorials.POPUPTUTORIALS, (tutData, tutId) => {
      // console.log('tutId', tutId);

      const curLevel = G.saveState.getLastPassedLevelNr() + 1; // Levels starts counting at 0
      if (curLevel >= G.OMTsettings.FTUXSettings.disablePopUpTutorial && !tutData.alwaysOn) return;
      if (G.saveState.isPopUpTutorialPassed(tutId)) return;
      if (tutData.terms) {
        if (tutData.terms.disabled) return;
        if (tutData.terms.pastLevel && curLevel < tutData.terms.pastLevel) return;
        if (tutData.terms.isAnyInLayer && (
          game.state.current !== 'Game' ||
          !state.board.isAnyInBoard(tutData.terms.isAnyInLayer)
        )) return;
        if (tutData.terms.isAnyInLevel && (
          game.state.current !== 'Game' ||
          !state.board.isAnyInLevel(tutData.terms.isAnyInLevel)
        )) return;
      }

      G.sb('pushWindow').dispatch(['popUpTutorial', tutId]);
    });
  }
}

// create global references
G.checkPopUpTutorials = CheckPopUpTutorials.check;
