G.DAILYMISSIONS = {
  missions: [
    // {
    //   id: 'sendLife',
    //   SPECIAL: true,
    //   icon: 'heart',
    //   description: 'Send a Life',
    //   listener: 'lifeMsgSent',
    //   increaser: 1,
    //   btnSetting: {
    //     sprite: 'green',
    //     label: 'Send',
    //     dimensions: {x: 138, y: 70},
    //     fontSize: 36
    //   },
    //   widget: [
    //     {
    //       type: 'txt',
    //       txt: 'Send',
    //       props: {y: -8},
    //     },
    //     {
    //       type: 'img',
    //       sprite: 'heart',
    //       props: {y: 27},
    //     }
    //   ],
    //   getTarget: function() {
    //     return G.json['configs/miniMissions'].missions.sendLife.target;
    //   },
    //   filterFunction: function() {
    //     return true;
    //   },
    //   specialAction: function() {
    //     G.sb('openLifeTransactionsMenu').dispatch();
    //   },
    // },

    {
      id: 'spinWheel',
      SPECIAL: true,
      icon: 'new_wheel_menu',
      iconSize: {
        width: 60,
        height: 60,
      },
      description: 'Spin the Wheel',
      listener: 'wheelSpin',
      increaser: 1,
      btnSetting: {
        sprite: 'green',
        label: 'Spin',
        dimensions: {x: 138, y: 70},
        fontSize: 36
      },
      widget: [
        {
          type: 'txt',
          txt: 'Spin',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'new_wheel_menu',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.spinWheel.target;
      },
      filterFunction: function() {
        return true;
      },
      specialAction: function() {
        if (game.incentivised() || G.saveState.data.freeSpin) {
          G.sb('pushWindow').dispatch(['dailyWheel', {worldState: true, asMoreMoney: false}], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
          G.sb('hideMainHighscorePanel').dispatch();
        }
      },
    },

    {
      id: 'dailyChallenge',
      SPECIAL: true,
      icon: 'score_icon',
      description: 'Play Daily Challenge',
      listener: 'onDailyChallengeFinished',
      increaser: 1,
      btnSetting: {
        sprite: 'green',
        label: 'Play',
        dimensions: { x: 138, y: 70 },
        fontSize: 36,
      },
      widget: [
        {
          type: 'txt',
          txt: 'Play',
          props: { y: -8 },
        },
        {
          type: 'img',
          sprite: 'score_icon',
          props: { y: 27 },
        },
      ],
      getTarget: () => G.json['configs/miniMissions'].missions.dailyChallenge.target,
      filterFunction: () => {
        const result = G.saveState.getLastPassedLevelNr() >= G.featureUnlock.unlockLevels.dailyChallenge
          && G.featureUnlock.dailyChallenge.enabled
          && G.saveState.villainsDataManager.getTutorialStatus('super_hard', 'post_win_saga_map');
        return result;
      },
      specialAction: async () => {
        const dailyIconAnotherConsideration = () => {
          const noLife = G.saveState.getLives() === 0 && G.saveState.getUnlimitedLivesSec() === 0;
          if (OMT.feature.rewardedAdsAvailable() || !noLife) {
            G.sb('pushWindow').dispatch(['dailyChallenge', G.saveState.getDailyChallengeLevel()]);
          } else if (noLife) {
            G.sb('pushWindow').dispatch(['notEnoughLives'], false, G.WindowMgr.LayerNames.AboveHighScorePanel);
          } else { // Just in case
            G.sb('pushWindow').dispatch(['dailyChallenge', G.saveState.getDailyChallengeLevel()]);
          }
        };
        dailyIconAnotherConsideration();
      },
    },

    {
      id: 'tournament',
      SPECIAL: true,
      alwaysShow: true,
      icon: 'score_icon',
      description: 'Play the Tournament',
      listener: 'onTournamentFinished',
      increaser: 1,
      btnSetting: {
        sprite: 'green',
        label: 'Play',
        dimensions: { x: 138, y: 70 },
        fontSize: 36,
      },
      widget: [
        {
          type: 'txt',
          txt: 'Play',
          props: { y: -8 },
        },
        {
          type: 'img',
          sprite: 'score_icon',
          props: { y: 27 },
        },
      ],
      getTarget: () => G.json['configs/miniMissions'].missions.tournament.target,
      filterFunction: () => {
        if (!G.featureUnlock.weeklyTournament.enabled) return false;
        const cooldownTimeRemaining = OMT.platformTournaments.getCooldownTimeRemaining();
        return cooldownTimeRemaining === 0;
      },
      specialAction: async () => {
        OMT.platformTournaments.setLevelOverride(null);
        await OMT.platformTournaments.switchToTournamentContext();
        const score = await OMT.platformTournaments.getTournamentHighScore();
        G.sb('pushWindow').dispatch(['tournament', score]);
      },
    },

    // {
    //   id: "collectGift",
    //   SPECIAL: true,
    //   icon: "gift",
    //   description: "Open Daily Gift",
    //   listener: "mapGiftCollect",
    //   increaser: 1,
    //   btnSetting: {
    //     sprite: "btn_chest_gate",
    //     label: "Claim",
    //   },
    //   widget: [
    //     {
    //       type: "txt",
    //       txt: "Collect",
    //       props: {y: -8},
    //     },
    //     {
    //       type: "img",
    //       sprite: "gift",
    //       props: {y: 27},
    //     }
    //   ],
    //   getTarget: function() {
    //     return 1;
    //   },
    //   filterFunction: function() {
    //     return true;
    //   },
    //   specialAction: function() {
    //     if (!G.saveState.data.lastMapGiftOpenTime) G.saveState.data.lastMapGiftOpenTime = 0;
    //     var msGiftCooldown = G.json.settings.mapGiftTimeMinutes * 60 * 1000;
    //     var active = Date.now() - G.saveState.data.lastMapGiftOpenTime > msGiftCooldown;
    //     console.log("specialAction collectGift ", active);
    //     if (active) {
    //       G.sb("pushWindow").dispatch("mapGift");
    //     }
    //   },
    // },
    // commenting this because its confusing whether we mean map chests or board chests


    {
      id: 'collectCoinsl',
      icon: 'coin_2',
      description: 'Collect Coins',
      listener: 'onCoinsIncrease',
      increaser: [true],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'coin_2',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectCoinsl.target;
      },
      filterFunction: function() {
        return true;
      },
    },


    {
      id: 'collectRed',
      icon: 'candy_1',
      description: 'Collect Cherry Pies',
      listener: 'onCollectableRemove',
      increaser: 1,
      terms: ['1'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'candy_1',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectRed.target;
      },
      filterFunction: function() {
        return true;
      },
    },

    {
      id: 'collectBlue',
      icon: 'candy_2',
      description: 'Collect Blue Doughnuts',
      listener: 'onCollectableRemove',
      increaser: 1,
      terms: ['2'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'candy_2',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectBlue.target;
      },
      filterFunction: function() {
        return true;
      },
    },

    {
      id: 'collectWhite',
      icon: 'candy_3',
      description: 'Collect Cheesecakes',
      listener: 'onCollectableRemove',
      increaser: 1,
      terms: ['3'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'candy_3',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectWhite.target;
      },
      filterFunction: function() {
        return true;
      },
    },

    {
      id: 'collectPink',
      icon: 'candy_4',
      description: 'Collect Pink Pies',
      listener: 'onCollectableRemove',
      increaser: 1,
      terms: ['4'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'candy_4',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectPink.target;
      },
      filterFunction: function() {
        return true;
      },
    },

    {
      id: 'collectGreen',
      icon: 'candy_5',
      description: 'Collect Green Cookies',
      listener: 'onCollectableRemove',
      increaser: 1,
      terms: ['5'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'candy_5',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectGreen.target;
      },
      filterFunction: function() {
        return true;
      },
    },

    {
      id: 'collectYellow',
      icon: 'candy_6',
      description: 'Collect Star Treats',
      listener: 'onCollectableRemove',
      increaser: 1,
      terms: ['6'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'candy_6',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectYellow.target;
      },
      filterFunction: function() {
        return true;
      },
    },  

    {
      id: 'collectStars',
      icon: 'map-nodes/map_star_3',
      description: 'Collect Stars',
      listener: 'dailyMissionCollectStars',
      increaser: [false, true],
      adIncreaser: 1,
      terms: [],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'map-nodes/map_star_3',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectStars.target;
      },
      filterFunction: function() {
        return true;
      },
      afterIncreaseCallback: function(lvlNr) {
        /*if (!this.terms) {
          this.terms = [[]]
        };
        this.terms[0].push('!'+lvlNr);*/
      },
    },

    {
      id: 'collectWaffles',
      icon: 'dirt_1',
      description: 'Collect Waffles',
      listener: 'onCollectableRemove',
      increaser: 1,
      terms: ['dirt'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'dirt_1',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectWaffles.target;
      },
      filterFunction: function() {
        return G.saveState.getLastPassedLevelNr() > G.DAILYMISSIONS.findLevelIndexWithToken('dirt');
      },
    },

    {
      id: 'collectJellyWaffles',
      icon: 'dirt_s_3',
      iconSize: {
        width: 50,
        height: 50
      },
      description: 'Collect Jelly Waffles',
      listener: 'onCollectableRemove',
      increaser: 1,
      terms: ['dirtS'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'dirt_s_3',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectJellyWaffles.target;
      },
      filterFunction: function() {
        return G.saveState.getLastPassedLevelNr() > G.DAILYMISSIONS.findLevelIndexWithToken('dS');
      },
    },

    {
      id: 'collectIce',
      icon: 'ice_front',
      description: 'Break Ice',
      listener: 'onCollectableRemove',
      increaser: 1,
      terms: ['ice'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'ice_front',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectIce.target;
      },
      filterFunction: function() {
        return G.saveState.getLastPassedLevelNr() > G.DAILYMISSIONS.findLevelIndexWithToken('ice');
      },
    },

    {
      id: 'collectRopes',
      icon: 'status_chain_2',
      description: 'Break Ropes',
      listener: 'onCollectableRemove',
      increaser: 1,
      terms: ['chain'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'status_chain_2',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectRopes.target;
      },
      filterFunction: function() {
        return G.saveState.getLastPassedLevelNr() > G.DAILYMISSIONS.findLevelIndexWithToken(':W');
      },
    },

    {
      id: 'collectsRock',
      icon: 'concrete_3',
      description: 'Destroy Rocks',
      listener: 'onCollectableRemove',
      increaser: 1,
      adIncreaser: 5,
      terms: ['concrete'],
      widget: [
        {
          type: 'txt',
          txt: 'Collect',
          props: {y: -8},
        },
        {
          type: 'img',
          sprite: 'concrete_3',
          props: {y: 27},
        }
      ],
      getTarget: function() {
        return G.json['configs/miniMissions'].missions.collectsRock.target;
      },
      filterFunction: function() {
        return G.saveState.getLastPassedLevelNr() > G.DAILYMISSIONS.findLevelIndexWithToken('cn');
      },
    },

    // {
    //   id: "collectPoints",
    //   icon: null,
    //   description: "Collect Points",
    //   listener: "onPointsAdded",
    //   increaser: [true],
    //   widget: [
    //     {
    //       type: "txt",
    //       txt: "Collect Points",
    //       multiline: true,
    //       props: {y: 14},
    //     },
    //   ],
    //   getTarget: function() {
    //     return 3000;
    //   },
    //   filterFunction: function() {
    //     return true;
    //   },
    // },
  ],

  getSpecialMissions: function(idsToFilter, alwaysShow = false) {
    idsToFilter = idsToFilter || [];

    return this.missions
      .filter(function(mission) {
        return mission.SPECIAL
          && mission.filterFunction()
          && idsToFilter.indexOf(mission.id) === -1
          && ((!alwaysShow && !mission.alwaysShow) || (alwaysShow && mission.alwaysShow));
      });
  },

  getRegularMissions: function(idsToFilter) {
    idsToFilter = idsToFilter || [];

    return this.missions
      .filter(function(mission) {
        return !mission.SPECIAL
          && mission.filterFunction()
          && idsToFilter.indexOf(mission.id) === -1
      });
  },

  getMissionById: function(id) {
    return this.missions.find(function(mission) {
      return mission.id === id;
    });
  },

  findLevelIndexWithToken: function(token){
    var level = G.Helpers.levelDataMgr.getLevelWithEditorSymbol(token);
    return level ? level.levelIndex : -1;
  },
};
