import TutorialGrid from '../BoardAnim/G.TutorialGrid';
import TutorialGroup from '../BoardAnim/TutorialGroup';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';

const tileSize = 90;
const gridScale = 1.2;

/**
 * @author Sandra Koo
 */
class IceBreakerTutorial extends TutorialGroup {
  constructor() {
    super(game, null);

    this.currentPage = 0;
    this.pageOne = new Phaser.Group(game, null);
    this.description = undefined; // Text
    this.iceImage = undefined; // The ice imagery

    this.pageTwo = new Phaser.Group(game, null);
    this.gridBg = undefined; // Grid background
    this.ice = undefined; // ice fronts
    this.cracks = undefined; // cracks
    this.gems = undefined; // gems
    this.tutHand = undefined; // Haaaaaand
    this.fxEmitters = []; // sfx
    this.continueButton = undefined; // Button

    this.drawEverything();
    this.showPageOne();
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this.gridBg) {
      this.gridBg.destroy();
    }

    if (this.ice) {
      this.ice.destroy();
    }

    if (this.cracks) {
      this.cracks.destroy();
    }

    if (this.gems) {
      this.gems.destroy();
    }

    this.pageOne.destroy();
    this.pageTwo.destroy();

    super.destroy();
  }

  /**
   * Draws all elements
   * Initializes some of them
   */
  drawEverything() {
    const gridX = 4;
    const gridY = 3;
    const maxDimension = 70 * 1.2;

    const makeGemIced = (iceCracks) => { // Makes crushed ice ;)
      const pack = new Phaser.Group(game, null);
      G.makeImage(0, 0, 'candy_5', 0.5, pack);
      G.makeImage(0, 0, 'ice_front', 0.5, pack);
      G.makeImage(0, 0, `ice_crack_${iceCracks}`, 0.5, pack);
      pack.width = maxDimension;
      pack.height = maxDimension;
      return pack;
    };

    const makeArrowImage = () => { // Makes arrow and makes it big
      const pack = new Phaser.Group(game, null);
      G.makeImage(0, 0, 'arrow_right', 0.5, pack);
      pack.width = maxDimension / 1.5;
      pack.height = maxDimension / 1.5;
      return pack;
    };

    this.iceImage = new Phaser.Group(game, null);
    const iceImages = [makeGemIced('3'), makeArrowImage(), makeGemIced('2'), makeArrowImage(), makeGemIced('1')]; // Makes all the ice images
    const distance = 10;
    const totalWidth = (maxDimension * iceImages.length) + (distance * (iceImages.length - 1)); // Calculates total width
    let nextX = (maxDimension - totalWidth) / 2; // Sets it back
    for (let i = 0; i < iceImages.length; i++) { // Centers accordingly
      const img = iceImages[i];
      img.x = nextX;
      this.iceImage.addChild(img);
      nextX += maxDimension + distance;
    }

    this.gridBg = new TutorialGrid(); // Grid bg. Has the background sprite
    this.gridBg.init(gridX, gridY, tileSize, 'tut_tile', gridScale);

    this.ice = new TutorialGrid(); // For the Ice
    this.cracks = new TutorialGrid(); // For the cracks

    this.gems = new TutorialGrid(); // For the gems

    for (let i = 0; i < 3; i++) { // Particle emitters
      const emitter = new FxParticle(); // For the sfx
      emitter.scale.setTo(gridScale); // It also grows big
      this.fxEmitters.push(emitter);
    }

    this.tutHand = new Phaser.Group(game, null);
    G.makeImage(0, 0, 'tut_hand', 0, this.tutHand); // Haaaand

    this.description = new G.Text(0, -150, ' ', { // The instructions
      style: 'font-blue',
      fontSize: 35,
      lineSpacing: -15,
    }, 0.5, 400, 200, true, 'center');

    this.continueButton = new OMT_UI_SquareButton(0, 260, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onContinueClick.bind(this),
        },
      },
    });
    this.addChild(this.continueButton);
  }

  /**
   * When continue is clicked
   */
  onContinueClick() {
    this.stopAllActions(); // Stop all tweens
    if (this.currentPage === 1) { // If page one, go to page 2
      this.removeChild(this.pageOne);
      this.showPageTwo();
    } else { // Otherwise close
      this.signals.onContinueClick.dispatch();
      this.destroy();
    }
  }

  showPageOne() {
    this.currentPage = 1;
    this.description.setText(OMT.language.getText('Some %gems% are trapped under multiple layers of %ice%.'));

    this.iceImage.y = this.description.y + (this.description.height / 2) + (this.iceImage.height * 1.5); // Puts it down in the middle

    this.pageOne.addChild(this.description);
    this.pageOne.addChild(this.iceImage);

    this.addChild(this.pageOne);
    this.addChild(this.continueButton);
  }

  showPageTwo() {
    this.currentPage = 2;
    const gridX = 4;
    const gridY = 3;

    this.description.setText(OMT.language.getText('Break through each layer of %ice% to free the trapped gems!'));

    // init grids with candies
    const mechanicData = [0, 1, 0, 1,
                          3, 2, 1, 0, // eslint-disable-line indent
                          0, 0, 0, 0]; // eslint-disable-line indent
    this.cracks.init(gridX, gridY, tileSize, mechanicData.map((num) => (num === 0 ? null : `ice_crack_${num}`)), gridScale); // Passes in an array of null/string to denote which
    this.ice.init(gridX, gridY, tileSize, mechanicData.map((num) => ((num === 0) ? null : 'ice_front')), gridScale); // parts have nothing and which have the icon

    const gemsData = [4, 5, 1, 5,
                      5, 3, 5, 4, // eslint-disable-line indent
                      5, 4, 4, 1].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    this.gridBg.y = this.description.y + (this.description.height + this.gridBg.height) / 2;
    this.ice.y = this.gridBg.y;
    this.cracks.y = this.gridBg.y;
    this.gems.y = this.gridBg.y;

    this.pageTwo.alpha = 0;
    this.tutHand.alpha = 0;

    this.addChild(this.description);
    this.pageTwo.addChild(this.gridBg);
    this.pageTwo.addChild(this.gems);
    this.pageTwo.addChild(this.ice);
    this.pageTwo.addChild(this.cracks);
    for (let i = 0; i < this.fxEmitters.length; i++) {
      this.pageTwo.addChild(this.fxEmitters[i]);
    }
    this.pageTwo.addChild(this.tutHand);
    this.addChild(this.pageTwo);
    this.addChild(this.continueButton);

    this.animatePageTwo();
  }

  animatePageTwo() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 2; // Modifies shouldEventComplete by checking the page

    // Handy function because I'll need a lot of gems
    const getGemData = (board, cellX, cellY) => ({
      sprite: board.getSpriteByCell(cellX, cellY),
      pos: board.getPxPos(cellX, cellY),
    });

    const gem31 = getGemData(this.gems, 3, 1);
    const gem32 = getGemData(this.gems, 3, 2);
    const gem22 = getGemData(this.gems, 2, 2);
    const gem12 = getGemData(this.gems, 1, 2);
    const ice21 = getGemData(this.ice, 2, 1);
    const crack21 = getGemData(this.cracks, 2, 1);
    const crack11 = getGemData(this.cracks, 1, 1);
    const fxGemTargets = [gem32, gem22, gem12]; // Target gems for the sfx
    const fadeOutTargets = [gem31, gem22, gem12]; // Target gems for disappearing when sfx happens

    this.tutHand.pivot.copyFrom(this.gems.pivot); // Copies and sets hand pivot to the grid

    const tweenGo = async () => {
      // Reset EVERYTHING
      this.tutHand.scale.set(1); // Hand is normal sized, in position, hidden
      this.tutHand.position.set(this.gems.x + gem31.pos.x, this.gems.y + gem31.pos.y);
      this.tutHand.alpha = 0;
      gem31.sprite.position.copyFrom(gem31.pos);
      gem32.sprite.position.copyFrom(gem32.pos);
      gem22.sprite.alpha = 1;
      gem12.sprite.alpha = 1;
      gem31.sprite.alpha = 1;
      crack11.sprite.changeTexture('ice_crack_2'); // crack11 is a G.Sprite, and its texture will change
      ice21.sprite.alpha = 1;
      crack21.sprite.alpha = 1;

      await this.wrapTween(this.pageTwo, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page two fades in
      await this.wrapTween(this.tutHand, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand fades in
      await this.wait(tweenDelay / 2); // Wait
      await this.wrapTween(this.tutHand.scale, { x: 0.9, y: 0.9 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand clicks
      this.wrapTween(gem31.sprite, gem32.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The above moves down
      this.wrapTween(gem32.sprite, gem31.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The below moves up
      await this.wrapTween(this.tutHand, { x: this.gems.x + gem32.pos.x, y: this.gems.y + gem32.pos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand moves
      this.wrapTween(this.tutHand, { alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand fades out quick
      this.wrapTween(this.tutHand.scale, { scale: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand unclicks (but fades out before you can see)
      for (let i = 0; i < this.fxEmitters.length; i++) {
        _.delay(() => {
          this.fxEmitters[i].burstCandy(this.gems.x + fxGemTargets[i].pos.x - tileSize * 1.5, this.gems.y + fxGemTargets[i].pos.y - tileSize, 3); // sfx happens
          this.wrapTween(fadeOutTargets[i].sprite, { alpha: 0 }, tweenTime / 4, Phaser.Easing.Sinusoidal.InOut); // Disappearing tween also happens
        }, i * (tweenTime / 4));
      }
      this.wrapTween(crack21.sprite, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, tweenTime / 2); // Crack sprite disappears
      this.wrapTween(ice21.sprite, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, tweenTime / 2); // With ice
      await this.wait(tweenTime / 4); // After waiting for the first sfx happening
      crack11.sprite.changeTexture('ice_crack_1'); // The crack texture changes
      await this.wait(tweenDelay);
      await this.wrapTween(this.pageTwo, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Fades out

      if (this.shouldEventComplete() && this.currentPage === 2) { // Repeats
        tweenGo();
      }
    };
    tweenGo();
  }
}
// create global references
G.IceBreakerTutorial = IceBreakerTutorial;
