/* eslint-disable no-use-before-define */

const referenceCounts = {};

const IMAGE_PADDING = 4;

/**
 * class for easy creation of render textures, and ARC handling.
 */
export class RenderTextureGenerator {
  /**
   * generate a OMT_RenderTextureSprite instance
   * @param {Phaser.DisplayObject} sourceSprite displayObject to copy
   * @param {string} textureId texture id for cache
   * @param {Array} anchor [x, y]
   * @returns {OMT_RenderTextureSprite}
   */
  static generateRenderTextureSprite(sourceSprite, textureId, anchor = [0, 0], padding = IMAGE_PADDING) {
    // create / get the RenderTexture instance
    let texture = RenderTextureGenerator.getTexture(textureId);
    if (!texture) { // new texture
      const matrix = new Phaser.Matrix();
      matrix.tx = padding + (anchor[0] * sourceSprite.width);
      matrix.ty = padding + (anchor[1] * sourceSprite.height);
      texture = new Phaser.RenderTexture(game, sourceSprite.width + (IMAGE_PADDING * 2), sourceSprite.height + (IMAGE_PADDING * 2), textureId);
      texture.render(sourceSprite, matrix);
      // console.log(`RenderTextureGenerator: generated texture ${textureId}`);
    }
    // create the sprite instance
    const rtSprite = new OMT_RenderTextureSprite(texture, textureId);
    rtSprite.anchor.set(anchor[0], anchor[1]);
    return rtSprite;
  }

  /**
   * get a OMT_RenderTextureSprite instance from the cache
   * @param {string} textureId texture id for cache
   * @param {Array} anchor [x, y]
   * @returns {OMT_RenderTextureSprite}
   */
  static getRenderTextureSprite(textureId, anchor = [0, 0]) {
    if (referenceCounts[textureId]) {
      // console.log(`RenderTextureGenerator: used cached texture ${textureId}`);
      // create the sprite instance
      const rtSprite = new OMT_RenderTextureSprite(referenceCounts[textureId].texture, textureId);
      rtSprite.anchor.set(anchor[0], anchor[1]);
      return rtSprite;
    }
    return null;
  }

  /**
   * generate a Phaser.RenderTexture. This should only be used when you only need a texture and not a Sprite.
   * Texture needs to be manually disposed calling RenderTextureGenerator.modifyReferenceCount(textureId, -1).
   * @param {Phaser.DisplayObject} sourceSprite displayObject to copy
   * @param {string} textureId texture id for cache
   * @returns {Phaser.RenderTexture}
   */
  static generateRenderTexture(sourceSprite, textureId) {
    // create / get the RenderTexture instance
    let texture = RenderTextureGenerator.getTexture(textureId);
    if (!texture) { // new texture
      const bounds = sourceSprite.getBounds();
      // draw the sprite to the RenderTexture
      texture = new Phaser.RenderTexture(game, bounds.width, bounds.height, textureId);
      texture.renderRawXY(sourceSprite, -bounds.x, -bounds.y);
      RenderTextureGenerator.modifyReferenceCount(textureId, 1, texture);
    }
    return texture;
  }

  /**
   * get a texture that has already been cached
   * @param {string} textureId
   * @returns {Phaser.RenderTexture}
   */
  static getTexture(textureId) {
    if (!referenceCounts[textureId]) return null;
    return referenceCounts[textureId].texture;
  }

  /**
   * modify a textures reference count
   * @param {string} textureId cache id
   * @param {number} increment 1 or -1
   * @param {Phaser.RenderTexture} (optional) only needed when setting the reference
   */
  static modifyReferenceCount(textureId, increment, texture = null) {
    if (!referenceCounts[textureId] && increment > 0) referenceCounts[textureId] = { refCount: 0, texture };
    const referenceCounter = referenceCounts[textureId];
    if (!referenceCounter) return;
    referenceCounter.refCount = Math.max(referenceCounter.refCount + increment, 0);
    // destroy texture instance if no longer referenced
    if (referenceCounts[textureId].refCount === 0) {
      // console.log(`RenderTextureGenerator: destroyed texture ${textureId}`, referenceCounts[textureId]);
      referenceCounts[textureId].texture.destroy();
      delete referenceCounts[textureId];
    }
  }
}

/**
 * Sprite wrapper class for the render texture
 */
class OMT_RenderTextureSprite extends Phaser.Sprite {
  /**
   * constructor
   * @param {Phaser.RenderTexture} texture
   * @param {string} textureId cache id
   */
  constructor(texture, textureId) {
    super(game, 0, 0, texture);
    this._texture = texture;
    this._textureId = textureId;
    this.smoothed = true;
    RenderTextureGenerator.modifyReferenceCount(this._textureId, 1, this._texture);
  }

  /**
   * destruction method, modify the reference count
   */
  destroy() {
    super.destroy();
    RenderTextureGenerator.modifyReferenceCount(this._textureId, -1, this._texture);
  }
}
