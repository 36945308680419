import PrizeWheel_Item from '@omt-components/UI/PrizeWheel/Components/PrizeWheel_Item';
import { ItemDataType } from '@omt-components/UI/PrizeWheel/Components/PrizeWheel_ItemData';
import OMT_PrizeWheel from './OMT_PrizeWheel';

// used to configure this class + elements of UI_PrizeWheel
const DEFAULT_CONFIG = {
  spinner: {
    wheelRotation: 120,
    wheelSegments: 12,
    bgAsset: 'gold_wheel',
    bgOffsetAngle: 180,
  },
  bg: {
    asset: 'shine_godrays', scale: 1, x: 0, y: 0, anchor: [0.5, 0.5], alpha: 1, rotationSpeed: 0.01,
  },
};

// merged with ITEM_CONFIGS from OMT_PrizeWheel + DEFAULT_CONFIG from PrizeWheel_Item
const ITEM_CONFIGS = {
  COINS: {
    text: {
      style: {
        fontSize: 29,
      },
      offset: { x: 72, y: 0 },
      anchor: [0.5, 0.5],
      angle: 90,
    },
    icon: {
      asset: 'coin_1',
      anchor: [0.5, 0.5],
      scale: 0.75,
      offset: { x: 115, y: 0 },
      angle: 90,
    },
  },
  BOOSTER: {
    text: {
      visible: false,
    },
    icon: {
      scale: 0.75,
      offset: { x: 115, y: 0 },
    },
  },
};

/**
 * daily / daily premium prize wheel
 */
export default class OMT_RequestHelpWheel extends OMT_PrizeWheel {
  /**
   * constructor
   * @param {Object} config (optional) components configurations
   * @param {Object} config.spinner (optional) overrides for PrizeWheel_Spinner DEFAULT_CONFIG
   * @param {Object} config.pointer (optional) overrides for PrizeWheel_Pointer DEFAULT_CONFIG
   * @param {Object} config.itemConfigsOverride (opional) overrides for ITEM_CONFIGS from OMT_PrizeWheel
   */
  constructor(config = {}) {
    super(_.merge(_.cloneDeep(DEFAULT_CONFIG), config));

    this._createBackground(this._config.bg);

    // convert prize data and intialize items
    const { itemConfigOverrides } = this._config;
    const prizeTable = this._getPrizeTable();
    const itemDatalist = this._convertPrizeList(prizeTable, _.merge(_.cloneDeep(ITEM_CONFIGS), itemConfigOverrides));
    this.initPrizeItems(itemDatalist);
  }

  /**
   * Makes the container that holds the prize on the wedge of the wheel
   * @param {PrizeWheel_ItemData} itemData
   * @param {number} startAngle
   * @param {number} endAngle
   */
  _makePrizeItem(itemData, startAngle, endAngle) {
    const item = new PrizeWheel_Item(itemData);
    if (item.itemType === ItemDataType.BOOSTER) item.hidePrizeText();
    item.setRotation(startAngle, endAngle);
    return item;
  }

  /**
   * Gets a list of wheel prizes (free and ad-supported)
   * @returns {Array.<Object>}
   */
  _getPrizeTable() {
    const theList = G.gift.getRequestHelpMessengerGifts();
    const prizeList = [];
    for (let i = 0; i < theList.length; i++) {
      const prize = theList[i];
      prizeList.push({ ...prize });
    }

    for (let i = 0; i < prizeList.length; i++) {
      const curPrize = prizeList[i];
      let getBooster = curPrize.prize === 'booster'; // Is it just a selection booster?
      if (curPrize.prize === 'prebooster') { // Or prelevel?
        const preBoosters = G.gift.getAvailablePreLevelBoosters(); // Is there any prelevel boosters you can get?
        if (preBoosters.length > 0) {
          const rng = game.math.between(0, preBoosters.length - 1);
          curPrize.prize = preBoosters[rng];
          continue; // Stop here and resume loop
        } else { // No there wasn't any... just get a selection booster instead
          getBooster = true;
        }
      }

      if (getBooster) { // A selection booster?
        const boosters = G.gift.getAvailableInGameBoosters();
        if (boosters.length > 0) { // Can you get any?
          const rng = game.math.between(0, boosters.length - 1);
          curPrize.prize = boosters[rng];
        } else {
          curPrize.prize = 'booster#1'; // Just take it...
        }
      }
    }
    return _.cloneDeep(prizeList);
  }

  /**
   * some enter animation, converted from the legacy old code, it was very messy
   * @param {Function} callback onComplete callback
   */
  enterTheWheel(callback = null) {
    this.visible = true;
    this.scale.x = this.scale.y = 1;
    this._wheelDisplay.y = -game.height - this._wheelDisplay.height;
    this._bgImage.alpha = 0;
    this._bgImage.visible = true;

    const tweenTime = 500;
    const entersy = game.add.tween(this._wheelDisplay)
      .to({ y: 0 }, tweenTime, Phaser.Easing.Sinusoidal.In);
    entersy.onComplete.addOnce(() => {
      game.add.tween(this._bgImage).to({ alpha: 1 }, tweenTime).start();
      if (callback) callback();
    });
    entersy.start();
  }
}
