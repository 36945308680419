G.PFProgressBar = function(config) {
  this.config = Object.assign({
    x: -150,
    y: -4,
    bgSprite: "goalPanel_bar_empty",
    barSprite: "goalPanel_bar_full",
    value: 0,
    valueMax: 0,
    label: true,
    overlaySprite: false,
  }, config);

  Phaser.Group.call(this, game);
  this.position.copyFrom(this.config);

  this.value = this.config.value;
  this.valueMax = this.config.valueMax;

  this.barBg = G.makeImage(0,0,'goalPanel_bar_empty',0,this);
  this.bar = G.makeImage(0,0,'goalPanel_bar_full',0,this);
  this.barFullWidth = this.bar.width;

  var cropRect = new Phaser.Rectangle(0,0,
    this.barFullWidth*(this.value/this.valueMax),
    this.bar.height);

  if (this.config.overlaySprite) {
    this.barOverlay = G.makeImage(0, 0, this.config.overlaySprite, 0, this);
    this.barOverlay.name = "barOverlay";
    this.barOverlay.alpha = 0;
    this.barOverlay.blendMode = 1;
    this.barOverlay.cropRect = cropRect;
  }

  this.bar.cropRect = cropRect;
  this.updateBarCrop();

  this.label = null;

  if (this.config.label) {
    this.addLabel(this.config.labelConfig || {});
  }
}

G.PFProgressBar.prototype = Object.create(Phaser.Group.prototype);

G.PFProgressBar.prototype.updateBarCrop = function() {
  this.bar.updateCrop();
  if (this.barOverlay) this.barOverlay.updateCrop();
};

G.PFProgressBar.prototype.startProgress = function() {
  var animation = G.Animations.getProgressBarOverlayAlphaAnimation(1);
  animation.animate(this.game, this);
};

G.PFProgressBar.prototype.setProgress = function(progress) {
  if (this.game === null) return;

  this.bar.cropRect.width = game.math.clamp(
    Math.round(this.barFullWidth*progress),
    0,
    500);
  this.updateBarCrop();

  this.value = progress*this.valueMax;

  if (this.label) {
    this.updateLabelValue();
  }
};

G.PFProgressBar.prototype.finishProgress = function() {
  var animation = G.Animations.getProgressBarOverlayAlphaAnimation(0);
  animation.animate(this.game, this);
};

G.PFProgressBar.prototype.addLabel = function(labelConfig) {
  var defaultStyle = {
    style: "font-white",
    fontSize: this.barBg.height,
  }

  this.label = new G.Text(
    labelConfig.x || 150,
    labelConfig.y || 15,
    this.value + "/" + this.valueMax,
    labelConfig.style || defaultStyle, 0.5);
  this.add(this.label);
};

G.PFProgressBar.prototype.updateLabelValue = function() {
  var str = Math.floor(this.value) + "/" + this.valueMax;
  if (str !== this.label.text) {
    this.label.setText(str);
  }
};