import UI_PointsCounterBase from '../base/UI_PointsCounterBase';

export default class UI_PointsCounter extends UI_PointsCounterBase {
  /**
   *
   * @param {{points: (string|object), amount: (string|object)}} [config]
   */
  _createPointsCounter(config) {
    this._pointsCounter = new G.Text(0, 0,
      `${G.capitalize(OMT.language.getText('points'))}: 0`, config.points, 0.5, 200, null, true, 'center');
    this.add(this._pointsCounter);
  }

  /**
   * Returns updated text on update
   * @returns {string}
   */
  _getUpdatedText() {
    return `${G.capitalize(OMT.language.getText('points'))}: ${this._points.toString()}`;
  }
}
