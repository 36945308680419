import { TournamentSocialDisplayBase } from '../../base/Tournament/TournamentSocialDisplayBase';
import { TournamentCompetitorScoreViewHorizontal } from './TournamentCompetitorScoreViewHorizontal';
import { TournamentCompetitorTopScoreViewHorizontal } from './TournamentCompetitorTopScoreViewHorizontal';

const COMPETITOR_POS = { x: 0, y: 0 };

/**
 * class for showing / managing tournament social activity during gameplay
 */
export class TournamentSocialDisplayHorizontal extends TournamentSocialDisplayBase {
  /**
   * init the VS icon overlay
   */
  _initVsIcon() {
    super._initVsIcon();
    this._vsIcon = new G.Text(0, 0, `${G.capitalize(OMT.language.getText('Score to Beat'))}:`, 'UIGoalPanelPointAmount-tournament', 0.5, 200);
    this.addChild(this._vsIcon);
  }

  /**
   * show the next competitor view
   * @param {{imaage:string, rank:number, score:number}} entryData
   * @returns {Promise}
   */
  _showNextCompetitorView(entryData) {
    const competitorView = super._showNextCompetitorView({
      Class: TournamentCompetitorScoreViewHorizontal,
      x: COMPETITOR_POS.x,
      y: COMPETITOR_POS.y,
      entryData,
    });
    this.addChildAt(competitorView, 0);
    this._competitorView = competitorView;
  }

  /**
   * show the view for overtaking #1 on leaderboard
   */
  _showTopScoreView() {
    const topScoreView = super._showTopScoreView({
      Class: TournamentCompetitorTopScoreViewHorizontal,
      x: COMPETITOR_POS.x,
      y: COMPETITOR_POS,
    });
    this.addChildAt(topScoreView, 0);
    this._topScoreView = topScoreView;
    this._topScoreView.animateIn();

    this._hideVsIcon();
  }
}
