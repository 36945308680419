import UI_AnimationGroup from '@omt-components/UI/Animation/UI_AnimationGroup';
import TreasureHuntManager, { TREASURE_HUNT_TIME_READY } from '../../../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';
import { TextTimer, TIMER_FORMAT } from '../../TextTimer';

const ASSET_ACTIVE = 'treasure_hunt_active_base';
const ASSET_INACTIVE = 'treasureHunt_inactiveIcon';

export default class TreasureHuntIcon extends UI_AnimationGroup {
  /**
   * The icon on the world map for eventPostcard that brings you to the shop
   *
   * @param {function} cleanUpFunc Callback when the icon destroys itself
   */
  constructor(cleanUpFunc) {
    super(game, null);

    this._cleanUpFunc = cleanUpFunc;
    this._ready = {
      drawing: false,
      text: false,
    };

    this._allAnimations = [];
    this._signalTokens = [];

    // Determine range
    this._waitingIcon = new G.WaitingIcon(0, 0, 'waiting_icon_white');
    // this.addChild(this._waitingIcon);
    this._init();

    // If the event is not active, and there is no past leaderboards to look at
    if (this.removedItself) {
      return;
    }

    this._animate = true;
    this._signalTokens.push(G.sb('pauseAllUpdate').add(() => {
      this._animate = false;
      for (const anim of this._allAnimations) {
        if (anim.tween) {
          anim.tween.pause();
        }
      }
    }));
    this._signalTokens.push(G.sb('resumeAllUpdate').add(() => {
      this._animate = true;
      for (const anim of this._allAnimations) {
        if (anim.tween) {
          anim.tween.resume();
        }
      }
    }));
    this._signalTokens.push(G.sb('onWallClockTimeUpdate').add(() => this._checkState(false)));
    this._signalTokens.push(G.sb('forceTreasureHuntIconRefresh').add(() => this._checkState(true)));
  }

  /**
   * Initing. Needs time for the await time
   */
  async _init() {
    if (G.saveState.treasureHuntManager.isTimeReady) {
      this._drawAsset();
      this._makeTimerText();
      this._checkState();

      this._ready.drawing = true;
      this.show();
    } else {
      this._signalTokens.push(G.sb(TREASURE_HUNT_TIME_READY).addOnce(this._init.bind(this)));
    }
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._signalTokens) {
      for (const sig of this._signalTokens) {
        if (sig && sig.detach) { sig.detach(); }
      }
      this._signalTokens.length = 0;
    }
    this._removeAllAnimations();
    super.destroy();
  }

  /**
   * Removes all looping animations
   */
  _removeAllAnimations() {
    for (const animData of this._allAnimations) {
      if (animData.loop) {
        game.time.events.remove(animData.loop);
      }
      if (animData.tween && animData.tween.stop) {
        animData.tween.stop();
      }
      animData.loop = null;
      animData.tween = null;
    }
    this._allAnimations.length = 0;
  }


  /**
   * Checks how much time is left, and flips the active flag
   */
  _checkEventActive() {
    this._timeLeft = G.saveState.treasureHuntManager.timeBeforeEnd;
  }

  /**
   * Checks the state of the timer every tick
   * Changes it to be active or not if it is.
   */
  _checkState() {
    if (!this._innerContainer) { return; } // Not ready yet
    const lastState = this._iconState;
    this._iconState = Boolean(G.saveState.treasureHuntManager.inActiveCycle && OMT.feature.isTreasureHuntOn(true, false, false, true));
    this._checkIfStillVisible();
    if ((this._iconState !== lastState || (this._timer && this._timer.secLeft < 0)) && !this.removedItself) {
      this._updateIcon(this._iconState);
      if (this._iconState) {
        this._recalculateTimeLeft();
      }
    }
  }

  /**
   * Checks if the icon is even visible
   */
  _checkIfStillVisible() {
    const istherePrizeToBeShown = G.saveState.treasureHuntManager.lastExpiredLeaderboard || G.saveState.treasureHuntManager.nextPrize;
    if (!(this._iconState || istherePrizeToBeShown)) {
      this.destroy();
      this.removedItself = true;
      if (this._cleanUpFunc) {
        this._cleanUpFunc(this);
      }
    }
  }

  /**
   * Recalculates time left. Changes the timer if its there
   * @returns {number}
   */
  _recalculateTimeLeft() {
    this._checkEventActive();
    const curTime = OMT.connect.getServerTimestampSync();
    const durationLeftSec = (this._timeLeft - curTime) / 1000;
    if (this._timer) {
      this._timer.changeTimerFormat(this._getDesiredTimerFormat(durationLeftSec));
      this._timer.setSecLeft(durationLeftSec);
    }
    return durationLeftSec;
  }

  /**
   * Draws Asset
   */
  _drawAsset() {
    this._container = new G.Button(0, 0, null, this.onClick, this);
    this._innerContainer = new Phaser.Group(game, this._container);
  }

  /**
   * Redraws the icon if needed
   * @param {Boolean} active
   */
  _updateIcon(active) {
    this._removeAllAnimations();
    if (this._icon) {
      this._innerContainer.removeChild(this._icon);
      this._icon.destroy();
      this._icon = null;
    }
    this._icon = G.makeImage(0, 0, active ? ASSET_ACTIVE : ASSET_INACTIVE, 0.5, this._innerContainer);
    if (active) {
      const iconAnimation = G.OMTsettings.treasureHuntSuper.worldMapIcon.animation;
      for (let i = 0; i < iconAnimation.length; i++) {
        const data = iconAnimation[i];
        const img = G.makeImage(data.x, data.y, data.asset, 0.5, this._icon);
        img.scale.set(data.scale);

        const animPack = {
          loop: null,
          tween: null,
          lastState: {},
        };
        animPack.loop = game.time.events.loop(data.time + data.delay, () => { // Instance of the event so it can be removed when the anim is removed
          this._loopAnimateTween(animPack, data, img);
        }, this);
        animPack.tween = this._createAnimateTween(data, animPack.lastState, img);
        this._allAnimations.push(animPack);
      }
      G.makeImage(G.OMTsettings.treasureHuntSuper.worldMapIcon.timerPos.x, G.OMTsettings.treasureHuntSuper.worldMapIcon.timerPos.y, 'treasure_hunt_active_timer', 0.5, this._icon);
      this._innerContainer.addChild(this._timer);
    } else {
      const downText = new G.Text(0,
        G.OMTsettings.treasureHuntSuper.worldMapIcon.timerText.y, OMT.language.getText('Ended, results'), 'eventPostcard-mapIconTimer', 0.5, this._innerContainer.width * 0.8);
      this._icon.addChild(downText);
      this._innerContainer.removeChild(this._timer);
    }
  }

  /**
   * Creates an animation tween
   * @param {{x:number, y:number, tween:Object, time:number, delay:number}} data
   * @param {{x:number, y:number}} lastState
   * @param {Phaser.DisplayObject} img
   * @returns {Phaser.Tween}
   */
  _createAnimateTween(data, lastState, img) {
    if (data.scaleTween) {
      const scaleTween = game.add.tween(img.scale) // Tween properly
        .to({ ...data.scaleTween }, data.time / 2, Phaser.Easing.Sinusoidal.InOut, true, data.delay, 0, true);
      if (data.tween) {
        scaleTween.onStart.addOnce(() => {
          game.add.tween(img) // Instant start tween properly
            .to({ ...data.tween }, data.time / 2, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
        });
      }
      return scaleTween;
    }

    if (this._checkImgToState(lastState, img)) {
      return game.add.tween(img) // Go back to position
        .to({ y: data.y, x: data.x }, data.time / 8, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
    }
    return game.add.tween(img) // Tween properly
      .to({ ...data.tween }, data.time / 2, Phaser.Easing.Sinusoidal.InOut, true, data.delay, 0, true);
  }

  /**
   * Stops the previous animation tween, makes a new one, and pauses it if it should be
   * @param {{tween:Phaser.Tween, loop:Phaser.Timer}} animPack
   * @param {{x:number, y:number, tween:Object, time:number, delay:number}} data
   * @param {Phaser.DisplayObject} img
   */
  _loopAnimateTween(animPack, data, img) {
    if (animPack.tween) {
      animPack.tween.stop();
    }
    animPack.tween = this._createAnimateTween(data, animPack.lastState, img);
    if (!this._canAnimationProceed(img)) {
      animPack.tween.pause();
    }
  }

  /**
   * Check tween data with state and img and if its stuck do something
   * @param {{x:number, y:number}} lastState
   * @param {Phaser.DisplayObject} img
   * @returns {Boolean}
   */
  _checkImgToState(lastState, img) {
    const res = Math.abs(lastState.x - img.x) > 2 && Math.abs(lastState.y === img.y) > 2;
    lastState.x = img.x;
    lastState.y = img.y;
    return res;
  }

  /**
   * A bunch of checks if an animation should go
   * @param {Phaser.DisplayObject} img
   * @returns {Boolean}
   */
  _canAnimationProceed(img) {
    return img && img.visible && img.parent && this._animate;
  }

  /**
   * Timer text is made for the event postcard
   *
   * Probably should refactor this one day
   */
  _makeTimerText() {
    const durationLeftSec = this._recalculateTimeLeft();
    const timeStyle = this._getDesiredTimerFormat(durationLeftSec);
    if (this._timer) {
      this._timer.destroy();
    }
    this._timer = this._makeTimerObject(timeStyle);
    this._timer.setSecLeft(durationLeftSec);
    this._timer.active = true;
    this._innerContainer.addChild(this._timer);
    this._ready.text = true;
    this.show();
  }

  /**
   * Returns the format used for the time left
   * @param {number} duration
   * @returns {TIMER_FORMAT}
   */
  _getDesiredTimerFormat(duration) {
    let timeStyle = TIMER_FORMAT.MS;
    const shownEndTime = G.changeSecToDHMS(duration);
    if (Number.parseInt(shownEndTime[0]) > 0) {
      timeStyle = TIMER_FORMAT.DH;
    } else if (Number.parseInt(shownEndTime[1]) > 0) {
      timeStyle = TIMER_FORMAT.HM;
    }
    return timeStyle;
  }

  /**
   * Creates the timer object. Can be overwritten
   */
  _makeTimerObject(timeStyle) {
    return new TextTimer({
      x: G.OMTsettings.treasureHuntSuper.worldMapIcon.timerText.x,
      y: G.OMTsettings.treasureHuntSuper.worldMapIcon.timerText.y,
      style: 'eventPostcard-mapIconTimer',
      anchor: 0.5,
      maxWidth: this._innerContainer.width,
      timerFormat: timeStyle,
    });
  }

  /**
   * Icon is ready and will show
   */
  show() {
    if (this._ready.drawing && this._ready.text) {
      this.removeChild(this._waitingIcon);
      this._waitingIcon.destroy();
      this._waitingIcon = null;

      this._container.scale.set(0.01);
      this.addChild(this._container);
      game.add.tween(this._container.scale).to({ x: 1, y: 1 }, 250, Phaser.Easing.Sinusoidal.InOut, true);
    }
  }

  /**
   * When the button is clicked
   */
  onClick() {
    TreasureHuntManager.openTreasureHuntPopup();
  }

  /**
  * Turns the icon into promo mode
  * @param {Function} promoClickedCallback (optional)
  */
  setPromoState(promoClickedCallback = null) {
    this.alpha = 1;

    if (this._signalTokens) {
      for (const sig of this._signalTokens) {
        if (sig && sig.detach) { sig.detach(); }
      }
      this._signalTokens.length = 0;
    }
    this._container.onClick.removeAll();
    if (promoClickedCallback) {
      this._container.onClick.add(promoClickedCallback);
    } else {
      this._updateIcon(true); // Update icon only if no callback
    }
  }
}
