if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getCoinShopShowAnimation = function getCoinShopShowAnimation() {

  return {
    animate: animate,
  };

  function animate(game, target) {
    var uiContainer = G.Utils.find1stObjectWithName(target, "uiContainer");

    if (uiContainer) {
      uiContainer.y = -2000;
      game.add.tween(uiContainer)
        .to({y: 0}, 300, Phaser.Easing.Sinusoidal.Out, true);
    }
  }
};