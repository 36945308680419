import GingyCharacterTutorial from '../../GingyTutorial/G.GingyCharacterTutorial';
import TutorialMsgText from '../../GingyTutorial/G.TutorialMsgText';
import {
  speechProps,
  handProps,
  gingyLines,
  leaderboardHeightLimit,
} from './SagaMapPromoConfig';

/**
 * Tutorial for new users to play the sage from the Tournament
 */
export class SagaMapPromo extends Phaser.Group {
  /**
   * constructor
   * @param {number} x
   * @param {number} y
   * @param {number} attempts
   */
  constructor(x, y, attempts) {
    super(game);

    this.x = x;
    this.y = y;

    this._init(attempts);

    this._onResize();
    this._onResizeSB = G.sb('onScreenResize').add(this._onResize, this);
  }

  /**
   * initialization method
   * @param {number} attempts
   */
  _init(attempts) {
    this._bottomGroup = new Phaser.Group(game, null);
    this._bottomGroup.x = -234;
    window.bottom = this._bottomGroup;
    this.add(this._bottomGroup);

    this._gingy = new GingyCharacterTutorial(0, 0);
    this._gingy.scale.setTo(0.75, 0.75);
    this._bottomGroup.add(this._gingy);
    this._gingy.talk();

    this._hand = G.makeImage(handProps.x, handProps.y, 'tut_hand', 0.5, this);
    this._hand.scale.setTo(1, -1);
    game.add.tween(this._hand)
      .to({ x: handProps.x + handProps.tweenDelta.x, y: handProps.y + handProps.tweenDelta.y },
        300,
        Phaser.Easing.Sinusoidal.InOut,
        true, 0, -1, true);
    window.hand = this._hand;

    this._speechGroup = new Phaser.Group(game, null);
    this._speechGroup.x = speechProps.x;
    this._speechGroup.y = speechProps.y;
    this._bottomGroup.add(this._speechGroup);

    this._speechBg = new Phaser.Graphics(game);
    this._speechBg.beginFill(0xffffff);
    this._speechBg.drawRoundedRect(0, 0, speechProps.width, speechProps.height, speechProps.cornerRadius);
    this._speechBg.endFill();
    this._speechBg.x = -speechProps.width / 2;
    this._speechBg.y = -speechProps.height / 2;
    this._speechGroup.add(this._speechBg);

    const textIndex = attempts < 2 ? 0 : G.Utils.getRandomInteger(1, gingyLines.length - 1);
    let textString = OMT.language.getText(gingyLines[textIndex]);

    textString = textString.replace('%PLAYSAGA%', OMT.language.getText('Play Saga'));
    textString = textString.replace('%PERCENT%', G.Utils.getRandomInteger(70, 95));

    const textObj = new G.Text(0, 0, textString, {
      style: 'font-blue',
      fontSize: speechProps.fontSize,
      lineSpacing: speechProps.lineSpacing,
    }, 0.5, speechProps.width - speechProps.padding / 2, speechProps.height - speechProps.padding / 2, true, 'center');

    this._speechText = new TutorialMsgText(0, 0);
    this._speechText.displayCustomText(textObj);
    this._speechText.onTextAnimationFinished.add(() => {
      this._gingy.idle();
    });
    this._speechGroup.add(this._speechText);
  }

  /**
   * Screen resize listener callback
   */
  _onResize() {
    if (game.height < leaderboardHeightLimit) {
      this._bottomGroup.y = game.height / 2 + 50;
    } else {
      this._bottomGroup.y = game.height / 2 - 63;
    }
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._onResizeSB.detach();
  }
}
