export default class HomescreenShortcutManager {
  /**
   * Returns the default values for this manager
   * @returns {{F:boolean, I:number, A:boolean, NH:number}}
   */
  static getDefaultValues() {
    return {
      F: false, // [F]irst Time User Seen Popup
      I: 0, // [I]nterval
      A: false, // [A]dded homescreen
      NH: 0, // [N]ot [H]ome entry point
    };
  }

  constructor() {
    // Nothing
  }

  /**
   * Initializes data based with given data
   * @param {{F:boolean, I:number, A:boolean, NH:number}} data
   */
  init(data) {
    this.dataReference = data;
    this._showPopup = false;
    this._oncePerSessionFlag = {
      general: false, // Like, in general. At least past level 10
      entryPoint: false, // Only in entry point
    };

    this._showBecauseEntryPoint = false; // Force show entry point even if added

    // Because manager is new, gotta check if its a returning user or a new one
    if (!this.dataReference.F && this._passedShowAtPoint()) {
      this.dataReference.F = true; // Actually a returning user
    }

    // If it was already added, check entry point
    if (this.dataReference.A) {
      this.checkEntryPoint();
    }

    let saveHappened = false; // Flag for if saving happened

    // If its a new user, check FTU stuff
    if (!this.dataReference.F) {
      saveHappened = this._checkFTU(); // checkFTU can save in here
    } else {
      this._checkInterval(); // No save occurs in here, to reduce save calls when checking entry point as well
    }

    if (!saveHappened) {
      this.save(); // The save happens
    }
  }

  /**
   * Checks the entry point if its from the home screen shortcut or not.
   * Forcibly shows the home screen shortcut if it was added previously and user hasn't used it in the last 5 logins
   * If they did, or they breached the limit, the counter resets.
   * Save occurs later on
   * @returns {null}
   */
  checkEntryPoint() {
    if (this._oncePerSessionFlag.entryPoint) { return; }
    this._oncePerSessionFlag.entryPoint = true;
    const entryPoint = OMT.envData.entryPoint;
    const sessionsWentWithoutEnteringProperly = 5;
    if (entryPoint !== 'home_screen_shortcut') {
      this.dataReference.NH++;
      if (this.dataReference.NH > sessionsWentWithoutEnteringProperly) {
        this._showPopup = true;
        this._showBecauseEntryPoint = true;
        this.dataReference.NH = 0;
      }
    } else {
      this.dataReference.NH = 0;
    }
  }

  /**
   * Checks if the user is past the show at point (level 10)
   * @returns {boolean}
   */
  _passedShowAtPoint() {
    return G.saveState.getLastPassedLevelNr() >= G.featureUnlock.homescreenShortcut.showAt;
  }

  /**
   * Checks if the pop up can show for FTUsers
   * @returns {boolean}
   */
  _checkFTU() {
    const loginCount = G.saveState.getLoginStats().count;
    if (loginCount === 2) { // Exactly the 2nd login
      this._showPopup = true;
    } else if (this._passedShowAtPoint()) { // User is new and just got to the showAt point
      this._showPopup = true;
    }

    // If we're showing it because of any of the two above were true, this user isn't FTU anymore
    if (this._showPopup) {
      this.dataReference.F = true;
      this.save();
      return true;
    }
    return false;
  }

  /**
   * For returning users.
   * Increments the number of logins. At every 10 logins, it will show the pop up and reset the counter
   */
  _checkInterval() {
    if (this.dataReference.I > 0 && this.dataReference.I % 10 === 0) {
      this._showPopup = true;
      this.dataReference.I = 0;
    } else {
      this.dataReference.I++;
    }
  }

  /**
   * Shows the pop up if everything is allowed
   * @returns {boolean}
   */
  get showPopup() {
    if (!this.dataReference.F) { // Check the FTU state if they are FTUsers.
      this._checkFTU();
    }
    let res = this._showPopup; // Show the pop up?
    if (this._oncePerSessionFlag.general) { res = false; } // We already showed it!
    if (this.dataReference.A && !this._showBecauseEntryPoint) { res = false; } // They already added it! And not because they didn't use it for a while

    return res;
  }

  /**
   * Toggles the once per session flag if the user is past the showAt point
   */
  toggleOncePerSession() {
    this._oncePerSessionFlag.general = this._passedShowAtPoint();
  }

  /**
   * Shortcut is added
   */
  onShortcutAdded() {
    this.dataReference.A = true;
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.HomeScreenIconAccepted);
    // if (DDNA.tracking.enabled) {
    //   const dataCapture = DDNA.tracking.getDataCapture();
    //   if (dataCapture.getPlayerCharacterizationParam('addedHomeScreenShortcut') === 0) {
    //     dataCapture.setPlayerCharacterizationParam('addedHomeScreenShortcut', 1);
    //   }
    // }
    this.save();
  }

  /**
   * Save is set externally in G.SaveState before it becomes relevant in this class
   */
  // save() {
  // }
}
