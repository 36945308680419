/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */

import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../States/Scaling/GameScaleController';
import OMT_TweenUtils from '../../Utils/Animation/OMT_TweenUtils';
import OMT_StackManager from '../../Utils/OMT_StackManager';
import OMT_VILLAINS from '../OMT_Villains';
import VillainsBaseClass from './VillainsBaseClass';
import VillainsBaseContainer from './VillainsBaseContainer';
import VillainsSpeechBubble from './VillainSpeechBubble';

export default class VillainsSpeech extends VillainsBaseClass {
  /**
   * Main villain speech bubble generator class
   * @param {any} parent Parent object to add the speech to
   * @param {string} text The text of the speech bubble
   * @param {'left'|'top-left'|'top-right'|'right'|'double'} villainPos Position of the villain
   * @param {number|number[]} villainIndex The villain to use
   * @param {number|number[]} villainSubIndex The villain expression to use
   * @param {'left'|'top-left'|'top-right'|'right'|'double'} tipPos Position of the bubble tip
   * @param {'top'|'center'|'bottom'} alignment Alignment of villains in relation to the speech bubble
   */
  constructor(
    parent,
    text,
    villainPos,
    villainIndex,
    villainSubIndex,
    tipPos,
    alignment,
  ) {
    super(parent);

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this.objectPrefix = '_villain';

    const classContainer = new VillainsBaseContainer(this);
    this._villain_container = {
      type: 'group',
      object: classContainer,
    };

    this.savePositions(villainPos, tipPos, alignment);

    const villainIndexIsArray = Array.isArray(villainIndex);
    const isMultiple = villainIndexIsArray && villainIndex.length > 1;
    if (!villainIndexIsArray) {
      villainIndex = [villainIndex];
      villainSubIndex = [villainSubIndex];
    }

    const villain_1 = G.makeImage(
      0,
      0,
      OMT_VILLAINS.getPrefixedName(
        `villain_${villainIndex[0]}_${villainSubIndex[0]}`,
      ),
      0.5,
      classContainer,
    );
    this.villain_1 = villain_1;

    if (isMultiple) {
      const villain_2 = G.makeImage(
        0,
        0,
        OMT_VILLAINS.getPrefixedName(
          `villain_${villainIndex[1]}_${villainSubIndex[1]}`,
        ),
        0.5,
        classContainer,
      );
      this.villain_2 = villain_2;
    }

    let speechBubbleVillainIndex = villainIndex[0];
    if (isMultiple) {
      speechBubbleVillainIndex = 2;
    }
    const speechBubble = new VillainsSpeechBubble(
      classContainer,
      speechBubbleVillainIndex,
      this.tipPos,
      text,
    );
    this.speechBubble = speechBubble;
    const speechBubble_object = speechBubble.getClassContainer();
    this.speechBubble_object = speechBubble_object;

    classContainer.add(speechBubble_object);

    if (this._isLandscape) {
      classContainer.scale.setTo(this._gameScale);
    }

    this.init(villainPos);

    parent.add(classContainer);
  }

  /**
   * Saves positions for the villains to be able to init later
   * @param {'left'|'top-left'|'top-right'|'right'|'double'} villainPos Position of the villain
   * @param {'left'|'top-left'|'top-right'|'right'|'double'} tipPos Position of the bubble tip
   * @param {'top'|'center'|'bottom'} alignment Alignment of villains in relation to the speech bubble
   */
  savePositions(villainPos, tipPos, alignment) {
    this.villainPos = villainPos;
    this.tipPos = tipPos || villainPos;
    this.alignment = alignment || 'center';
  }

  /**
   * Reinitialize the objects based on the saved positions
   */
  init() {
    const { villain_1, villain_2, speechBubble, speechBubble_object } = this;

    villain_1.scale.setTo(0.4);
    villain_1.alpha = 0;
    this._villain_1 = {
      index: 1,
      type: 'villain',
      object: villain_1,
    };

    if (villain_2) {
      villain_2.scale.setTo(0.4);
      villain_2.alpha = 0;
      this._villain_2 = {
        index: 2,
        type: 'villain',
        object: villain_2,
      };
    }

    speechBubble_object.alpha = 0;
    this._villain_speechBubble = {
      type: 'speechBubble',
      object: speechBubble_object,
      objectParent: speechBubble,
    };

    this.positionObjects();
  }

  /**
   * Position the villains and the speech bubble based on the given configuration
   */
  positionObjects() {
    const { villainPos, alignment } = this;
    const classContainer = this.getClassContainer();
    const villainParents = this.getObjects(
      {
        type: 'villain',
      },
      (object) => object,
    );
    const [villain_1_parent, villain_2_parent] = villainParents;
    const [villain_1, villain_2] = villainParents.map(
      (object) => object.object,
    );
    const speechBubble = this.getObjects({ type: 'speechBubble' })[0];

    const refPoint = { x: 0, y: 0 };
    if (villainPos === 'double') {
      refPoint.x = -villain_1.width * 0.5;
      villain_1.x = classContainer.toLocal(refPoint).x;
      villain_1_parent.targetX =
        speechBubble.x - speechBubble.width * 0.45 - villain_1.width * 0.5;

      refPoint.x = game.width + villain_2.width * 0.5;
      villain_2.x = classContainer.toLocal(refPoint).x;
      villain_2_parent.targetX =
        speechBubble.x + speechBubble.width * 0.45 + villain_2.width * 0.5;

      this._setYAlignment(villain_1, alignment);
      this._setYAlignment(villain_2, alignment);

      if (alignment === 'top') {
        speechBubble.y += speechBubble.height * 0.2;
      }
    } else {
      const xOffset = speechBubble.width * 0.1;

      if (villainPos === 'left') {
        refPoint.x = -villain_1.width * 0.5;
        villain_1.x = classContainer.toLocal(refPoint).x;
        speechBubble.x += xOffset;
      } else if (villainPos === 'right') {
        refPoint.x = game.width + villain_1.width * 0.5;
        villain_1.x = classContainer.toLocal(refPoint).x;
        speechBubble.x -= xOffset;
      }

      let villainX = speechBubble.x;
      let villainY = speechBubble.y;
      const { villainYOffsetMultipler } = G.OMTsettings.elements.superhard.villain_speech;

      switch (villainPos) {
        case 'top-right':
          villainX += speechBubble.width * 0.45 - villain_1.width * 0.5;
          villainY -= villain_1.height * villainYOffsetMultipler;
          speechBubble.y += speechBubble.height * 0.1;
          villain_1.y = villainY;
          break;
        case 'top-left':
          villainX -= speechBubble.width * 0.45 - villain_1.width * 0.5;
          villainY -= villain_1.height * villainYOffsetMultipler;
          speechBubble.y += speechBubble.height * 0.1;
          villain_1.y = villainY;
          break;
        case 'right':
          villainX += speechBubble.width * 0.5 + villain_1.width * 0.4;
          break;
        case 'left':
          villainX -= speechBubble.width * 0.5 + villain_1.width * 0.4;
          break;
        default:
          break;
      }

      if (['right', 'left'].includes(alignment)) {
        this._setYAlignment(villain_1, alignment);
        if (alignment === 'top') {
          speechBubble.y += speechBubble.height * 0.2;
        }
      }

      villain_1_parent.targetX = villainX;
    }

    if (['double', 'top-left', 'left'].includes(villainPos)) {
      villain_1.scale.x *= -1;
    }
  }

  /**
   * Aligns the villains vertically based on their preferred alignment
   * @param {*} villain The villain sprite to position
   * @param {'top'|'center'|'bottom'} alignment Alignment of villains in relation to the speech bubble
   */
  _setYAlignment(villain, alignment) {
    const speechBubble = this.getObjects({ type: 'speechBubble' })[0];

    villain.y = speechBubble.y;
    const villain_y_offset = (speechBubble.height - villain.height) * 0.5;
    if (alignment === 'top') {
      villain.y -= villain_y_offset;
    } else if (alignment === 'bottom') {
      villain.y += villain_y_offset;
    }
  }

  /**
   * Villains appear from the center
   */
  async villainEnter() {
    this.init();

    const villains = this.getObjects(
      {
        type: 'villain',
      },
      (object) => object,
    );
    const stack = OMT_StackManager.getFreeStack();
    const subStacks = [];

    for (let i = 0; i < villains.length; i++) {
      const villain = villains[i];
      const { object } = villain;
      const subStack = OMT_StackManager.getFreeStack();

      subStack.addPromise(() =>
        OMT_TweenUtils.tweenMultiple({
          object,
          duration: 300,
          ease: Phaser.Easing.Circular.Out,
          props: {
            x: villain.targetX,
            alpha: 1,
          },
        }),
      );

      subStacks.push(subStack);
    }

    if (subStacks.length) {
      stack.addParallel(subStacks);
    }

    return stack.run();
  }

  /**
   * The overall show function for the villains and the speech bubble
   */
  async show() {
    const stack = OMT_StackManager.getFreeStack();

    stack.addPromise(() => this.villainEnter());
    const speechBubble = this.getObjects(
      { type: 'speechBubble' },
      (object) => object,
    )[0];
    stack.addPromise(() => speechBubble.objectParent.show());

    return stack.run();
  }

  /**
   * Hides the class completely
   */
  async hide() {
    const classContainer = this.getClassContainer();
    const stack = OMT_StackManager.getFreeStack();

    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: classContainer,
        alpha: 0,
        duration: 300,
      }),
    );

    return stack.run();
  }

  /**
   * Hides the speech bubble
   */
  async hideBubble() {
    const stack = OMT_StackManager.getFreeStack();

    const speechBubble = this.getObjects(
      { type: 'speechBubble' },
      (object) => object,
    )[0];

    stack.addPromise(() => speechBubble.objectParent.hide());

    return stack.run();
  }

  /**
   * Sets the text of the speech bubble
   * @param {string} text the text to set for the speech
   */
  async setText(text) {
    const speechBubble = this.getObjects(
      { type: 'speechBubble' },
      (object) => object,
    )[0].objectParent;

    return speechBubble.setText(text);
  }

  /**
   * Sets the text of the speech bubble instantly
   * @param {string} text the text to set for the speech
   */
  setTextSync(text) {
    const speechBubble = this.getObjects(
      { type: 'speechBubble' },
      (object) => object,
    )[0].objectParent;

    speechBubble.setTextSync(text);
  }
}
