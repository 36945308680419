import { RMWHEEL_MODES, RMWHEEL_PENDINGORDER } from './rmWheelEnums';

/**
 * Helper function that converts boolean value into RMWHEEL_MODES
 * @param {boolean} isConversion
 * @returns {RMWHEEL_MODES}
 */
export const isConversionToEnum = (isConversion) => (isConversion ? RMWHEEL_MODES.Conversion : RMWHEEL_MODES.HighValue);

/**
 * Creates a unique id for a real money spin
 * @param {string} id if null, the current time is used instead
 * @param {RMWHEEL_EPS} entryPoint
 * @param {boolean} isFree is the spin free (true) or bought as an IAP (false)
 * @param {boolean} wasPaidFor was the spin already paid for (not relevant to free spins)
 */
export const createRealMoneySpinId = (id, entryPoint, isFree, wasPaidFor) => {
  let iapPart;
  if (isFree) {
    iapPart = 'free'; // free spin
  } else if (wasPaidFor) {
    iapPart = 'iap-uc'; // IAP spin paid for, but not claimed
  } else {
    iapPart = 'iap'; // IAP spin not paid for yet
  }

  if (id == null) {
    id = Date.now();
  }

  return `${iapPart}_${entryPoint}_${id}`;
};

/**
 * Sort pending spin array by each spin's IAP part using RMWHEEL_PENDINGORDER
 * @param {Array} spinArray
 */
export const sortPendingSpins = (spinArray) => spinArray.sort((a, b) => {
  const spinIdPartsA = a.split('_');
  const spinIdPartsB = b.split('_');

  return RMWHEEL_PENDINGORDER[spinIdPartsA[0]] - RMWHEEL_PENDINGORDER[spinIdPartsB[0]];
});
