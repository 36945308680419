/* eslint-disable no-param-reassign */
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';

/**
 * Static methods for doing things in Window_gift.js
 */

if (typeof G === 'undefined') G = {};
G.GiftUI = G.GiftUI || {};
G.GiftUI.HelperFunctions = {};

/**
 * Checks config to see if it is even possible to double your reward
 * @param {Object} config
 */
G.GiftUI.HelperFunctions.canDouble = function (config) {
  return !(
    config.gifts[0][0] === G.GiftUI.Constants.GiftTypes.Heart
      || config.gifts[0][0] === G.GiftUI.Constants.GiftTypes.InfiniteLives
      || (config.gifts[1]
    && (config.gifts[1][0] === G.GiftUI.Constants.GiftTypes.Heart
      || config.gifts[1][0] === G.GiftUI.Constants.GiftTypes.InfiniteLives))
      || (config.gifts[2]
    && (config.gifts[2][0] === G.GiftUI.Constants.GiftTypes.Heart
      || config.gifts[2][0] === G.GiftUI.Constants.GiftTypes.InfiniteLives))
  );
};

G.GiftUI.HelperFunctions.getGiftBoxTypeFromType = function (type) {
  switch (type) {
    case G.GiftUI.Constants.GiftBoxTypes.Default:
    default:
      return G.GiftUI.Constants.GiftBoxTypes.Default;
    case G.GiftUI.Constants.GiftBoxTypes.Retro:
      return G.GiftUI.Constants.GiftBoxTypes.Retro;
  }
};


/**
 * @param {G.GiftUI.Constants.GiftBoxTypes} type
 */
G.GiftUI.HelperFunctions.getGiftboxImage = function (type) {
  switch (type) {
    case G.GiftUI.Constants.GiftBoxTypes.Default:
      return G.GiftUI.Constants.GiftBoxImages.Default;
    case G.GiftUI.Constants.GiftBoxTypes.Retro:
      return G.GiftUI.Constants.GiftBoxImages.Retro;
    default: break;
  }
};

/**
 * @static
 * @param {Object} giftConfig
 * @param {Boolean} evenMore
 */
G.GiftUI.HelperFunctions.getGiftExtraText = function (giftConfig, evenMore) {
  let winTxt = '';
  const type = G.GiftUI.HelperFunctions.getGiftTypeFromString(giftConfig[0]);

  switch (type) {
    case G.GiftUI.Constants.GiftTypes.Coins:
      if (evenMore) {
        winTxt = OMT.language.getText('Even more Coins!');
      } else {
        winTxt = OMT.language.getText('More Coins!');
      }
      break;
    case G.GiftUI.Constants.GiftTypes.Heart:
      if (giftConfig[1] > 1) {
        winTxt = OMT.language.getText('N Hearts!');
      } else {
        winTxt = OMT.language.getText('A Heart!');
      }
      break;
    case G.GiftUI.Constants.GiftTypes.InfiniteLives:
      winTxt = OMT.language.getText('Unlimited Lives!');
      break;
    case G.GiftUI.Constants.GiftTypes.Booster:
      if (giftConfig[1] > 1) {
        winTxt = `${OMT.language.getText('%#% Boosters').replace('%#%', giftConfig[1].toString())}!`;
      } else {
        winTxt = `${OMT.language.getText('1 Booster')}!`;
      }
      break;
    default: break;
  }
  return winTxt;
};

G.GiftUI.HelperFunctions.getGiftTypeFromString = function (str) {
  for (const k in G.GiftUI.Constants.GiftTypeChecks) {
    if (G.GiftUI.Constants.GiftTypeChecks[k](str)) {
      return G.GiftUI.Constants.GiftTypes[k];
    }
  }
  return G.GiftUI.Constants.GiftTypes.Coins;
};

/**
 * Combines the passed config with the default config and sets properties on targetObj
 * @param {Object} defaultConfi
 * @param {Object} config
 * @param {G.Window} targetObj
 */
G.GiftUI.HelperFunctions.processConfig = function (defaultConfig, config, targetObj) {
  const DefaultConfig = G.Utils.clone(defaultConfig);
  if (config) {
    targetObj._config = config;
    for (const key in DefaultConfig) {
      if (targetObj._config.hasOwnProperty(key)) continue;
      else {
        targetObj._config[key] = DefaultConfig[key];
      }
    }

    if (targetObj._config.gifts.length === 0) {
      targetObj._config.gifts.push(G.gift.getGift('normals'));
    }

    if (
      targetObj._config.reason === G.GiftUI.Constants.GiftReasons.Achievement
      && targetObj._config.gifts.length < 3
    ) {
      while (targetObj._config.gifts.length < 3) {
        targetObj._config.gifts.push(G.gift.getGift('normals'));
      }
    }
  } else {
    targetObj._config = DefaultConfig;
    targetObj._configs.gifts.push(G.gift.getGift('normals'));
  }
};

G.GiftUI.HelperFunctions.getShareButtonText = function (isOnTimer, config) {
  if (isOnTimer) {
    let txt = `${OMT.language.getText('Brag!')} `;
    txt += G.changeSecToHMS(
      Math.floor(
        (1000 * 60 * 60 * 24 // 24 hr
          + G.saveState.data.lastGiftShareTime
          - Date.now())
          / 1000,
      ),
    ).join(':');
    return txt;
  }

  const type = G.GiftUI.HelperFunctions.getGiftTypeFromString(config.gifts[0][0]);
  switch (type) {
    // case G.GiftUI.Constants.GiftTypes.Booster:
    // return OMT.language.getText('Share a Booster');
    // case G.GiftUI.Constants.GiftTypes.Heart:
    // return OMT.language.getText('Share a Heart');
    // case G.GiftUI.Constants.GiftTypes.InfiniteLives:
    // return OMT.language.getText('Share with friends');
    // case G.GiftUI.Constants.GiftTypes.Coins:
    default:
      // console.error('Trying to get string for unsharable gift type!');
      return OMT.language.getText('Brag!');
  }
};

G.GiftUI.HelperFunctions.canShare = function (config) {
  let share = false;

  if (!config.shareable) return false;

  for (let i = 0; i < config.gifts.length; i++) {
    const curr = config.gifts[i];
    const type = G.GiftUI.HelperFunctions.getGiftTypeFromString(curr[0]);

    if (
      type === G.GiftUI.Constants.GiftTypes.Booster
      || (type === G.GiftUI.Constants.GiftTypes.Heart || type === G.GiftUI.Constants.GiftTypes.InfiniteLives)
    ) {
      share = true;
    } else {
      return false;
    }
  }

  return share;
};

G.GiftUI.HelperFunctions.showShareLoadingOverlay = function (parent) {
  const isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
  const gameScale = isLandscape ? GameScaleController.getInstance().gameScale : 1;
  const yOffset = isLandscape ? -50 : 0;

  const fadeImg = game.add.graphics(
    -(game.width * 0.5) / gameScale,
    -(game.height * 0.5) / gameScale + yOffset,
    parent,
  );

  fadeImg.clear();
  fadeImg.beginFill(0x000000, 0.8);
  fadeImg.drawRect(0, 0, (game.width * 2) / gameScale, (game.height * 2) / gameScale - yOffset);
  fadeImg.endFill();

  const loadIcon = G.makeImage(0, 80, 'waiting_icon_white', 0.5, parent);
  loadIcon.update = function () {
    this.angle += 1;
  };

  return {
    fadeImg,
    loadIcon,
  };
};

G.GiftUI.HelperFunctions.removeShareLoadingOverlay = function (parent, overlay) {
  parent.remove(overlay.fadeImg, true);
  parent.remove(overlay.loadIcon, true);
};

G.GiftUI.HelperFunctions.openPresentWithAnimation = function (target, config, callback) {
  return game.add
    .tween(target.scale)
    .to(
      { x: target.scale.x * 1.0, y: target.scale.y * 1.0 },
      600,
      Phaser.Easing.Bounce.Out,
      true,
    )
    .onComplete.add(() => {
      target.inside.visible = true;
      // TURN OFF BECAUSE THERE IS NO LOCALIZATION
      target.insideExtraTxt.visible = false;
      target.insideExtraTxt.scale.setTo(0);
      game.add
        .tween(target.insideExtraTxt.scale)
        .to({ x: 1, y: 1 }, 1000, Phaser.Easing.Bounce.Out, true);
      // Remove lid
      if (!config.twoFrameOpen) {
        game.add.tween(target.giftLid).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.Out, true);
      } else {
        target.gift.frameName = 'gift_open';
      }
      if (target.giftGlow) game.add.tween(target.giftGlow).to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.Out, true);
      if (target.openLid) {
        target.openLid.visible = true;
        game.add.tween(target.openLid).to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.Out, true);
      }

      if (target.isChest) {
        game.sfx.chest_open.play();
      }

      // show prize
      game.add
        .tween(target.inside.scale)
        .to({ x: 1.2, y: 1.2 }, 500, Phaser.Easing.Sinusoidal.Out, true);

      if (config.reason === G.GiftUI.Constants.GiftReasons.Chest) {
        game.add
          .tween(target.light)
          .to(
            { y: target.light.y - 100 },
            500,
            Phaser.Easing.Sinusoidal.Out,
            true,
          );
      }
      let giftRise = 100;
      if (config === G.GiftUI.Constants.GiftReasons.Chest) giftRise = 200;
      if (config.twoFrameOpen) {
        giftRise = 100;
      }
      game.add
        .tween(target.inside)
        .to(
          {
            alpha: 1,
            y:
                target.inside.y
                - (giftRise),
          },
          500,
          Phaser.Easing.Sinusoidal.Out,
          true,
        )
        .onComplete.add(callback);
    });
};

G.GiftUI.HelperFunctions.moveHandToTarget = function (hand, target) {
  return function () {
    hand.x = target.x + 40;
    hand.y = target.y + 100;
    return game.add
      .tween(hand)
      .to(
        {
          x: target.x + 20,
          y: target.y + 50,
        },
        900,
        Phaser.Easing.Cubic.Out,
        true,
        0,
        -1,
      );
    // .onComplete.add(G.GiftUI.HelperFunctions.moveHandToTarget(hand, target));
  };
};

G.GiftUI.HelperFunctions.playBoosterSplitAnimation = function (gift, config, selectedBooster, animations, callback) {
  const curr = gift;
  const target = curr.inside_dbl || curr.inside;
  if (selectedBooster === -1 || selectedBooster === undefined) selectedBooster = 0;
  const selectedConfig = config.gifts[selectedBooster];

  if (target.boosterImg) {
    target.boosterImg.setCount(selectedConfig[1] - 1);
  } else if (target.chestGifts) {
    target.chestGifts[selectedBooster].boosterImg.setCount(
      selectedConfig[1] - 1,
    );
  }

  animations.cubicRescale.animate(game, target, 1, 900);

  // duplicate gift image
  const duplicate = G.GiftUI.Elements.GiftImageFromType(
    target.giftType || G.GiftUI.Constants.GiftTypes.Booster,
    selectedConfig,
    curr,
  );
  duplicate.x = target.x;
  duplicate.y = target.y;
  duplicate.scale.setTo(target.scale.x);
  if (duplicate.boosterImg) {
    duplicate.boosterImg.setCount(1);
  }
  curr.add(duplicate);

  if (
    G.GiftUI.HelperFunctions.canShare(config)
    && config.shareable
    && config.incentivisedSharing
  ) {
    const shareReward = new G.LabelTextT(
      G.gift.getLabelString(['coin', 100]),
      0,
      75,
      {
        style: 'font-white',
        fontSize: '32px',
      },
      0.5,
      150,
    );
    shareReward.alpha = 0;
    duplicate.add(shareReward);
    game.add
      .tween(shareReward)
      .to({ alpha: 1 }, 800, Phaser.Easing.Quartic.Out, true);
  }

  game.add
    .tween(target)
    .to({ y: -65 }, 1200, Phaser.Easing.Quartic.Out, true);

  game.add
    .tween(duplicate)
    .to({ y: 65 }, 1200, Phaser.Easing.Quartic.Out, true)
    .onComplete.add(function () {
      // close window
      game.add
        .tween(duplicate)
        .to({ y: 1000 }, 800, Phaser.Easing.Quartic.Out, true);

      game.add
        .tween(target)
        .to({ y: -1000 }, 800, Phaser.Easing.Quartic.Out, true)
        .onComplete.add(function () {
          callback();
        });
    });
};

G.GiftUI.HelperFunctions.getSharingInsideExtraTextFromType = function (type) {
  switch (type) {
    case G.GiftUI.Constants.GiftTypes.Booster:
      return (OMT.language.getText('Sharing booster...'));
    case G.GiftUI.Constants.GiftTypes.Heart:
      return (OMT.language.getText('Sharing Heart...'));
    case G.GiftUI.Constants.GiftTypes.InfiniteLives:
      return (OMT.language.getText('Sharing Unlimited Lives...'));
    default:
      return ('Sharing...');
  }
};

/**
 * @static Not entirely; depends on savedata being initialized
 * @param {Object} config
 */
G.GiftUI.HelperFunctions.checkShareTimer = function (config) {
  if (
    config.shareable
    && config.incentivisedSharing
    && Date.now() - G.saveState.data.lastGiftShareTime < G.json.settings.giftShareTime
  ) {
    return true;
  }
  return false;
};

/**
 * Must be .bind()'d to the Window_gift window
 */
G.GiftUI.HelperFunctions.updateShareTimer = function () {
  if (this.closed) return;
  if (G.GiftUI.HelperFunctions.checkShareTimer(this._config)) {
    this.shareBoosterBtn._txtObj.setText(G.GiftUI.HelperFunctions.getShareButtonText(true, this._config));
    game.time.events.add(1000, G.GiftUI.HelperFunctions.updateShareTimer.bind(this));
  } else {
    this.shareBoosterBtn.active = true;
    this.shareBoosterBtn.setColour(BUTTONCOLOURS.blue);
    this.shareBoosterBtn.width = 196;
    this.shareBoosterBtn.height = 100;
    this.shareBoosterBtn._txtObj.setText(G.GiftUI.HelperFunctions.getShareButtonText(false, this._config));
  }
};

G.GiftUI.HelperFunctions.createButtons = function (
  targetWindow,
  config,
  premiumBtnClaimAllClicked,
  claimAllBtnClicked,
  shareBoosterBtnClicked,
  noThanksBtnClicked,
) {
  targetWindow.claimAllBtn_Premium = new OMT_UI_SquareButton(
    0 + config.buttonsOffset.x,
    350 + config.buttonsOffset.y,
    {
      button: {
        tint: BUTTONCOLOURS.purple,
        dimensions: {
          width: 309,
          height: 100,
        },
        extraDetail: false,
      },
      options: {
        clickFunction: {
          onClick: premiumBtnClaimAllClicked,
        },
      },
    },
  );
  const ico = G.makeImage(-110, 0, 'btn-movie-icon', 0.5, null);

  if (config.monetiseType === G.GiftUI.Constants.MonetiseOptions.OpenOtherGifts) {
    targetWindow.claimAllBtn_Premium.setTextStyle({ style: 'font-white', fontSize: 60 });
    targetWindow.claimAllBtn_Premium.setText(OMT.language.getText('Claim all!'));
    targetWindow.claimAllBtn_Premium.setIcon(ico, 'left', { x: ico.width / 4 });
  } else if (config.monetiseType === G.GiftUI.Constants.MonetiseOptions.DoubleReward) {
    targetWindow.claimAllBtn_Premium.setTextStyle({ style: 'font-white', fontSize: 40, lineSpacing: -20 });
    targetWindow.claimAllBtn_Premium.setText(OMT.language.getText('Double Reward'));
    targetWindow.claimAllBtn_Premium.setIcon(ico, 'left', { x: ico.width / 4 });
  }

  targetWindow.claimAllBtn_Premium.alpha = 0;
  targetWindow.claimAllBtn_Premium.visible = false;
  targetWindow.add(targetWindow.claimAllBtn_Premium);

  targetWindow.claimAllBtn = new OMT_UI_SquareButton(
    0 + config.buttonsOffset.x,
    350 + config.buttonsOffset.y,
    {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Claim'),
        textStyle: { style: 'font-white', fontSize: 60 },
      },
      options: {
        clickFunction: {
          onClick: claimAllBtnClicked,
        },
      },
    },
  );
  targetWindow.claimAllBtn.alpha = 1;
  targetWindow.claimAllBtn.visible = false;
  targetWindow.add(targetWindow.claimAllBtn);

  const shareOnTimer = G.GiftUI.HelperFunctions.checkShareTimer(config);

  targetWindow.shareBoosterBtn = new OMT_UI_SquareButton(
    0 + config.buttonsOffset.x,
    460 + config.buttonsOffset.y,
    {
      button: {
        tint: BUTTONCOLOURS.blue,
        dimensions: {
          width: 188,
          height: 96,
        },
        isEnabled: shareOnTimer,
      },
      options: {
        clickFunction: {
          onClick: shareBoosterBtnClicked,
        },
      },
    },
  );
  targetWindow.shareBoosterBtn._txtObj = new G.Text(
    0,
    0,
    G.GiftUI.HelperFunctions.getShareButtonText(shareOnTimer, config),
    {
      style: 'font-white',
      fontSize: 28,
      lineSpacing: -20,
    },
    0.5,
    145,
    85,
    true,
    'center',
  );
  targetWindow.shareBoosterBtn.addChild(targetWindow.shareBoosterBtn._txtObj);
  targetWindow.shareBoosterBtn.visible = false;

  if (
    G.GiftUI.HelperFunctions.canShare(config)
    && config.incentivisedSharing
    && config.shareable
    && shareOnTimer
  ) {
    game.time.events.add(1000, G.GiftUI.HelperFunctions.updateShareTimer.bind(targetWindow));
    targetWindow.shareBoosterBtn.active = false;
    targetWindow.shareBoosterBtn._txtObj.scale.setTo(0.8);
  }

  if (
    config.incentivisedSharing
    && config.shareable
    && !shareOnTimer
  ) {
    targetWindow.shareRewardGroup = new Phaser.Group(game, targetWindow.shareBoosterBtn);
    targetWindow.shareRewardText = new G.Text(
      0,
      0,
      OMT.language.getText('Reward: '),
      {
        style: 'font-white',
        fontSize: 24,
      },
      0.5,
    );
    targetWindow.shareRewardLabel = new G.LabelTextT(
      G.gift.getLabelString(['coin', 100]),
      targetWindow.shareRewardText.width - 5,
      0,
      {
        style: 'font-white',
        fontSize: '24px',
      },
      0.5,
      150,
    );
    targetWindow.shareRewardGroup.add(targetWindow.shareRewardText);
    targetWindow.shareRewardGroup.add(targetWindow.shareRewardLabel);
    targetWindow.shareRewardGroup.x = targetWindow.shareRewardText.width * -0.5 + 10;
    targetWindow.shareRewardGroup.y = 120 + targetWindow._config.buttonsOffset.y;
    targetWindow.shareBoosterBtn.addChild(targetWindow.shareRewardGroup);
  }
  targetWindow.add(targetWindow.shareBoosterBtn);

  targetWindow.noThanksBtn = G.GiftUI.Elements.NoThanksButton(
    0 + config.buttonsOffset.x,
    460 + config.buttonsOffset.y,
    noThanksBtnClicked,
  );
  targetWindow.add(targetWindow.noThanksBtn);
};
