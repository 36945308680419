import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { Window } from '../../00_IMMEDIATE/Window';
import { OMT_DebugVorlonLoader, OMT_VorlonActivateButton } from '../../Debug/OMT_DebugVorlonLoader';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

class Window_Support extends Window {
  /**
   * constructor
   * @param {Object} parentWindow
   */
  constructor(parentWindow) {
    super(parentWindow);
    this.canShowFanBtn = G.OMTsettings.fanPageButton && G.OMTsettings.fanPageButton.enabled && OMT.envData.platform === 'ANDROID';
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._initLayout();
    this._initButtons();
  }

  /**
   * init window layout
   */
  _initLayout() {
    if (this._isLandscape) {
      this.y += 50;
    }

    // window background
    this.addBackground('popup_background_2');

    // title text
    this._initClickableTitle();

    // body text
    let email = 'support@softgames.de';
    if (OMT.feature.isLoyaltySupportAvailable() && G.saveState.loyaltyManager.isLoyal) {
      email = 'loyalty.m3@softgames.com';
    }
    this._support_forAnyHelp = new G.Text(0, 0, `${OMT.language.getText('For feedback or technical issues, contact our support at:')} ${email}`, {
      style: 'font-blue',
      fontSize: this.canShowFanBtn ? 32 : 64,
      align: 'center',
      lineSpacing: -5,
    }, [0.5, 0], this.bg.width * 0.8, 240, true, 'center');
    this._support_forAnyHelp.y = -150;
    this.addChild(this._support_forAnyHelp);

    // player id text
    this._playerID = new G.Text(0, 0, `Player ID: ${this._getPlayerId()}`, 'font-blue', 0.5, 364, 38);
    this.addChild(this._playerID);
    this._playerID.y = this.bg.height / 2 - this._playerID.height - 30;
  }

  /**
   * init the clickable title. clicking 6 times will load vorlon.js
   */
  _initClickableTitle() {
    // title text field
    const supportTitleText = new G.Text(
      0, 0,
      OMT.language.getText('Support'),
      G.OMTsettings.global.window.title.textStyle,
      0.5,
      350,
    );
    // create vorlon button
    const vorlonActivateButton = new OMT_VorlonActivateButton(0, -260, supportTitleText, 0x00FF00);
    this.addChild(vorlonActivateButton);
  }

  /**
   * init window buttons
   */
  _initButtons() {
    // delete progress button
    const onDeleteClick = () => {
      G.sb('pushWindow').dispatch(['eraseProgress'], undefined, G.WindowMgr.LayerNames.Base, true);
    };
    const goToFanPage = () => {
      const pageName = G.OMTsettings.global.gameName.replace(' ', '');
      window.location.href = `https://www.facebook.com/${pageName}Instant`;
    };
    const onFanPageClick = () => {
      if (!G.saveState.data.visitedFanPage) {
        G.saveState.data.visitedFanPage = true;
        G.saveState.save().then(goToFanPage);
      } else {
        goToFanPage();
      }
    };


    this._deleteProgressButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.red,
        dimensions: {
          width: 260,
          height: 80,
        },
      },
      text: {
        string: OMT.language.getText('Delete Progress'),
        textStyle: { style: 'font-white', fontSize: 40 },
      },
      options: {
        clickFunction: {
          onClick: onDeleteClick.bind(this),
        },
      },
    });

    this._fanPageButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 260,
          height: 80,
        },
      },
      text: {
        string: OMT.language.getText('Fan Page'),
        textStyle: { style: 'font-white', fontSize: 40 },
      },
      options: {
        clickFunction: {
          onClick: onFanPageClick.bind(this),
        },
      },
    });

    this._deleteProgressButton.y = this._playerID.y - this._deleteProgressButton.height;
    this._fanPageButton.y = this._deleteProgressButton.y - this._fanPageButton.height - 10;
    this.add(this._deleteProgressButton);
    if (this.canShowFanBtn) this.add(this._fanPageButton);

    // close button
    this.addCloseButton();
  }

  /**
   * get the player id
   * @returns {string}
   */
  _getPlayerId() {
    return (
      (typeof OMT.envData.settings.user.userId === 'number')
        ? OMT.envData.settings.user.userId.toString()
        : OMT.envData.settings.user.userId
    );
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.support = Window_Support;
