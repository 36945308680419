/* eslint-disable func-names */
G.BoosterSelection = function (board, cellX, cellY, follow) {
  this.board = board;
  this.am = this.board.actionManager;
  this.cellX = cellX;
  this.cellY = cellY;

  Phaser.Image.call(
    this,
    game,
    this.board.cellXToPxIn(cellX),
    this.board.cellYToPxIn(cellY),
  );

  this.alpha = 0;

  this.follow = follow;

  this.anchor.setTo(0);

  G.changeTexture(this, 'tut_hand');

  this.offsetTween = 0;

  game.add.tween(this).to({ offsetTween: 20 }, 300, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

  this.alphaTween = game.add.tween(this).to({ alpha: 0.8 }, 300, Phaser.Easing.Sinusoidal.Out, true);
};

G.BoosterSelection.prototype = Object.create(Phaser.Image.prototype);

G.BoosterSelection.prototype.update = function () {
  this.x = this.follow.x + this.offsetTween;
  this.y = this.follow.y + this.offsetTween;
};

G.BoosterSelection.prototype.hide = function () {
  this.alphaTween.stop();

  game.add.tween(this).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true, 200).onComplete.add(function () {
    this.destroy();
  }, this);
};
