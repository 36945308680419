export default class BatchPoolGroup extends Phaser.SpriteBatch {
  /**
   * same as G.PoolGroup but uses a SpriteBatch
   * @param {Class<T>} elementConstructor
   * @param {Array} argumentsArray
   * @param {Phaser.Signal} signal
   * @param {Array<elementConstructor>} initFill
   */
  constructor(elementConstructor, argumentsArray, signal, initFill) {
    super(game, null);

    this._deadArray = [];
    this._elementConstructor = elementConstructor;
    this._argumentsArray = argumentsArray || [];
    this._argumentsArray.unshift(null);

    if (signal) {
      this._signalToken = G.sb(signal).add(this.init, this);
    }

    if (initFill) {
      for (let i = 0; i < initFill; i++) {
        const element = new (Function.prototype.bind.apply(this._elementConstructor, this._argumentsArray))();
        this.add(element);
        element.events.onKilled.add(this._onElementKilled, this);
        element.kill();
      }
    }
  }

  destroy() {
    if (this._signalToken) {
      if (this._signalToken.detach) {
        this._signalToken.detach();
      }
      this._signalToken = null;
    }
    this._deadArray.forEach((child) => {
      child.destroy();
    });
    super.destroy();
  }

  getFreeElement() {
    let element;

    if (this._deadArray.length > 0) {
      element = this._deadArray.pop();
    } else {
      element = new (Function.prototype.bind.apply(this._elementConstructor, this._argumentsArray))();
      element.events.onKilled.add(this._onElementKilled, this);
    }

    this.add(element, true);
    return element;
  }

  _onElementKilled(elem) {
    if (this !== elem.parent) return;
    this._deadArray.push(elem);
    this.removeChild(elem);
  }

  init() {
    const elem = this.getFreeElement();
    elem.init.apply(elem, arguments); // eslint-disable-line prefer-spread, prefer-rest-params

    return elem;
  }

  initBatch(nr) {
    for (let i = 0; i < nr; i++) {
      this.init.apply(this, [].slice.call(arguments, 1)); // eslint-disable-line prefer-spread, prefer-rest-params
    }
  }
}

G.BatchPoolGroup = BatchPoolGroup;
