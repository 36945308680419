import OMT_UI_DrawUtil from '../../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import { Window } from '../../../00_IMMEDIATE/Window';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

export class Window_EventEntry extends Window {
  /**
   * The window that shows up to remind you that a collection event is going on!
   *
   * Most details about what is drawn is set by G.OMTSettings. More details in _getConfig();
   * @param {DisplayObject} parent
   */
  constructor(parent) {
    super(parent);

    this._doBookkeeping();
    this._config = this._getConfig();
    this._drawEverything();
  }

  /**
   * Assigns flags when needed
   */
  _doBookkeeping() {
    G.saveState.tokenEventManager.FTUE.ee = false;
    G.saveState.tokenEventManager.save();
  }

  /**
   * Grabs the config needed for this window.
   * @returns {{
   *  bgAsset: string,
   *  headerData: {
   *    asset: { x: number, y: number, assetString: string },
   *    text: { x: number, y: number, text:String, textStyle: string, width: number, height: number },
   *  }
   *  bannerData: {
   *    asset: { x: number, y: number, assetString: string },
   *  },
   *  textData: {
   *    debug: boolean,
   *    text: Array<{ x: number, y: number, string: string, textStyle: string, bullet?:boolean }>,
   *  },
   *  playButton: { x: number, y: number, text: string },
   *  mascot: {
   *    assetString: string,
   *    offset: { x: number, y: number },
   *    scale: { horizontal: { x: number, y: number }, vertical: { x: number, y: number }},
   *    pos: { horizontal: { x: number, y: number }, vertical: { x: number, y: number }}}
   */
  _getConfig() {
    return G.OMTsettings.tokenEvent.windowEventEntry;
  }

  /**
   * Draws everything
   */
  _drawEverything() {
    this._container = new Phaser.Group(game, this);
    // shift window in game scene
    if (game.state.current === 'World' && OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      this._container.y = -G.WindowMgr.Constants.WorldVerticalOffset;
    }
    this.addBackground();
    this._drawHeader();
    this._drawBanner();
    this._drawText();
    this._drawButtons();
    this._drawMascot();
  }

  /**
   * Adds the background and puts it in the container
   */
  addBackground() {
    super.addBackground(this._config.bgAsset);
    this._container.addChild(this.bg);
  }

  /**
   * Draws the header on the bg
   */
  _drawHeader() {
    const headerDetails = this._config.headerData;
    const img = G.makeImage(headerDetails.asset.x, headerDetails.asset.y, headerDetails.asset.assetString, 0.5, this._container);
    const text = new G.Text(headerDetails.text.x, headerDetails.text.y,
      OMT.language.getText(headerDetails.text.text), headerDetails.text.textStyle, 0.5, img.width * headerDetails.text.width, img.height * headerDetails.text.height, true, 'center');
    img.addChild(text);
  }

  /**
   * Draws the asset banner inside the window
   */
  _drawBanner() {
    const bannerDetails = this._config.bannerData.asset;
    this._banner = G.makeImage(bannerDetails.x, bannerDetails.y, bannerDetails.assetString, 0.5, this._container);
  }

  /**
   * Draws text.
   */
  _drawText() {
    this._text = [];
    for (const textDetail of this._config.textData.text) {
      const prefix = textDetail.bullet ? '• ' : '';
      const text = new G.Text(textDetail.x, textDetail.y, `${prefix}${OMT.language.getText(textDetail.string)}`, textDetail.textStyle, 0.5, this.bg.width * 0.8, 85, true, 'center');
      this._text.push(text);
      this._container.addChild(text);
    }

    if (this._config.textData.debug) {
      OMT_UI_DrawUtil.debugPlacement(this._text);
    }
  }

  /**
   * Draws the close button and the play button
   */
  _drawButtons() {
    this.addCloseButton();
    this._container.addChild(this.closeButton);

    const playButtonDetails = this._config.playButton;
    this._playButton = new OMT_UI_SquareButton(playButtonDetails.x, playButtonDetails.y, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 220,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText(playButtonDetails.text),
        textStyle: {
          style: 'font-white',
          fontSize: 70,
        },
        dimensionMods: {
          width: 0.9,
          height: 0.9,
        },
      },
      options: {
        clickFunction: {
          onClick: this._onPlayClicked.bind(this),
          disableAfterClick: true,
        },
        cacheButton: false,
      },
    });
    this._playButton.y += this.bg.y + this.bg.height / 2;
    this._container.addChild(this._playButton);
    this._playButton.visible = true;
  }

  /**
   * Draws the mascot on the side
   */
  _drawMascot() {
    const mascotData = this._config.mascot;
    const mascotPos = mascotData.pos[OMT.systemInfo.orientationKey];
    const mascotImg = G.makeImage(mascotPos.x + mascotData.offset.x, mascotPos.y + mascotData.offset.y, mascotData.assetString, 0.5, this);
    const targetScale = mascotData.scale[OMT.systemInfo.orientationKey];
    mascotImg.scale.set(targetScale);
  }

  /**
   * When the play button is clicked
   */
  _onPlayClicked() {
    G.sb('pushWindow').dispatch(['eventPostcard',
      {
        windowName: 'eventLevel',
        args: [G.saveState.getEventLevel(false)],
        layerName: this.parent.layerName,
      },
      {
        startIndex: Math.max(G.saveState.tokenEventManager.getPostcardLastOpenedIndex(), 0),
      }]);
    this.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.eventEntry = Window_EventEntry;
