/* eslint-disable no-unused-vars */
import { Window } from './Window';

/**
 * Window class with child view swtiching
 */
export default class WindowWithViewSwitching extends Window {
  /**
   * Shows when the player runs out of moves in a level.
   * @param {Object} parent
   * @param {Object} popUpConfig
   * @param {Function} levelFailedCallback (optional) function to call instead of 'levelFailed'
   * @memberof Window_OutOfMovesIAP
   */
  constructor(parent, popUpConfig, levelFailedCallback) {
    super(parent);

    this._views = [];
    this._currentView = null;
  }

  /**
   * add a view to the view list
   * @param {string} viewId
   * @param {Phaser.DisplayObject} view
   */
  _addView(viewId, view) {
    view.ignoreChildInput = true;
    view.alpha = 0;
    const viewConfig = { viewId, view };
    this._views.push(viewConfig);
    this.addChild(view);
  }

  /**
   * get a registered view by id
   * @param {string} viewId
   * @returns {Phaser.DisplayObject}
   */
  _getViewById(viewId) {
    for (const viewConfig of this._views) {
      if (viewConfig.viewId === viewId) return viewConfig.view;
    }
    return null;
  }

  /**
   * show / switch views
   * @param {string} viewId
   */
  _showView(viewId) {
    const viewToShow = this._getViewById(viewId);
    if (!viewToShow) return;
    if (this._currentView === viewToShow) return;

    this._currentView = viewToShow;

    // disable input of not-shown views and hide them, and enable input on/show the current view.
    for (const { view } of this._views) {
      if (view === viewToShow) { // active view
        view.ignoreChildInput = false;
        view.y = 0;
        game.add.tween(view)
          .to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.In, true);
      } else { // inactive views
        view.ignoreChildInput = true;
        if (view.alpha !== 0) {
          game.add.tween(view)
            .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true)
            .onComplete.add(() => {
              view.y = -9999;
            });
        } else {
          view.y = -9999;
        }
      }
    }
  }
}
