G.WaitingIcon = function(x, y, optSprite) {
  Phaser.Image.call(this, game, x, y);
  G.changeTexture(this, optSprite || "waiting_icon");
  this.name = "waitingIcon";
  this.anchor.setTo(0.5, 0.5);

  var waitingIconAnimation = G.Animations.getWaitingIconAnimation();
  waitingIconAnimation.animate(this.game, this);
};

G.WaitingIcon.prototype = Object.create(Phaser.Image.prototype);