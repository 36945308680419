/* eslint-disable no-undef */
import {
  getUserData as GBC_getUserData,
  setUserData as GBC_setUserData,
} from '@sgorg/game-backend-client';

import { OMT_Crypt } from './OMT_Crypt';

// we wait a short duration before writing data to resolve multiple calls to the same dataKey.

/**
 * class for interfacing with and storing saved user data
 */
export class OMT_UserData {
  /**
   * constructor
   */
  constructor() {
    // parameters for managing game-backend data
    this._gbUserData = null;
    this._gbDataSynced = false;
    this._gbWriteQueue = [];

    // parameters for local-storage data
    this._lsUserData = null;
    this._lsWriteQueue = [];

    this._cachedData = {};

    this._defaultToFallback = false;

    this._wasSavedModified = true;
    
    window.flushUserData = () => {
      if (!this._wasSavedModified) return;
      FBInstant.player.setDataAsync(this._cachedData);
      this._wasSavedModified = false;
      console.log("saved user data...")
    };
    window.setInterval(()=> {
      window.flushUserData();
    }, 5000);

  }

  /**
   * initialize and load user data
   * @returns {Promise}
   */
  async init() {
    // generate localStorage storageKey and cryptKey
    this._lsCryptKey = OMT_Crypt.generateLocalStorageCryptKey(G.BuildEnvironment.APP_ID, OMT.envData.settings.user.userId);
    this._lsStorageKey = OMT_Crypt.md5Hash(`${G.BuildEnvironment.APP_ID}-${OMT.envData.settings.user.userId}`);
    // console.log('+++++this._lsStorageKey', this._lsStorageKey);

    this._cachedData = [];
    // force load of local-storage data
    await this.getUserData2();
  }

  /**
   * get user data once loaded, this is only updated on game load.
   * @param {string} dataKey
   * @returns {Object}
   */
  getUserData(dataKey) {
    return this._cachedData[dataKey];
  }

  async getUserData2() {
    const d = await FBInstant.player.getDataAsync();
    this._cachedData = d;
    return this._cachedData;
  }

  /**
   * write user data.
   * @param {string} dataKey storage key
   * @param {Object} data data to write to storage
   * @returns {Promise}
   */
  async writeUserData(dataKey, data) {
    const isJSON = (typeof data === 'string') && JSONUtil.isJson(data);
    if (isJSON) data = JSON.parse(data);

    if (this._cachedData[dataKey] !== data){
      this._cachedData[dataKey] = data;
       this._wasSavedModified = true;
    }
  }

}
