import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

/**
 * THIS SHOULD NO LONGER BE USED - Sandra Koo 09/4/2019
 */
G.Tutorial = function (tutorialID) {
  Phaser.Group.call(this, game);

  this.tutorialID = tutorialID;

  G.tutorialOpened = true;

  this.boardGroup = game.add.group();
  this.add(this.boardGroup);

  this.state = game.state.getCurrentState();

  this.overlay = this.state.overlay;
  this.board = this.state.board;

  this.boardGroup.x = this.board.x;
  this.boardGroup.y = this.board.y;

  this.tutData = G.json.tutorials[tutorialID];

  if (this.tutData.booster) {
    this.makeBoosterTutorial(this.tutData);
  } else if (G.GINGY_TUTORIAL) {
    this.makeGingyTutorial(this.tutData);
  } else {
    this.makeStandardTutorial(this.tutData);
  }

  if (!this.tutData.booster) {
    const butt = new OMT_UI_SquareButton(530, game.height * 0.9, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Skip'),
        textStyle: {
          style: 'font-white',
          fontSize: 60,
        },
      },
      options: {
        clickFunction: {
          onClick: this.skip.bind(this),
          disableAfterClick: true,
        },
      },
    });
    this.skipBtn = this.add(butt);
    // this.skipBtn.addTextLabel('font-white', OMT.language.getText('Skip'), 60);
    // this.skipBtn.scale.setTo(1.5);
    game.add.tween(this.skipBtn).from({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true);
    this.skipBtn.pY = 0.9;
  }

  game.add.tween(this.boardGroup).from({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
};

G.Tutorial.prototype = Object.create(Phaser.Group.prototype);


G.Tutorial.prototype.update = function () {
  if (this.skipBtn) {
    this.skipBtn.y = this.skipBtn.pY * game.height;
  }

  this.boardGroup.x = this.board.x;
  this.boardGroup.y = this.board.y;
  this.boardGroup.update();
};

G.Tutorial.prototype.makeGingyTutorial = function (tutData) {
  this.gingy = new G.Gingy();

  if (tutData.overlayTask) {
    this.overlay.start(tutData.overlayTask);
  }

  if (tutData.handCells) {
    this.makeHandAnim(tutData.handCells);
  }

  if (tutData.inputCells) {
    this.setBoardCandyInput(tutData.inputCells);
  }

  if (tutData.msg) {
    this.gingy.showMsg(OMT.language.getText(tutData.msg.text), 0.3);
    // this.gingy.makeMsg(tutData.msg.text,tutData.msg.position)
  }

  G.sb('madeMove').addOnce(this.finish, this);

  this.state.lockInput();

  this.afterMsg = function (text, position, timer) {
    if (this.hand) game.add.tween(this.hand).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
    G.sb('actionQueueEmpty').addOnce(function () {
      if (G.lvl.goalAchieved || this.skipped) return;

      this.gingy.showMsg(OMT.language.getText(text), position);
      game.time.events.add(timer, function () {
        this.gingy.hide();
        G.sb('onTutorialFinishDisplay').dispatch();
      }, this);
    }, this);
  };
};

G.Tutorial.prototype.makeStandardTutorial = function (tutData) {
  if (tutData.overlayTask) {
    this.overlay.start(tutData.overlayTask);
  }

  if (tutData.handCells) {
    this.makeHandAnim(tutData.handCells);
  }

  if (tutData.inputCells) {
    this.setBoardCandyInput(tutData.inputCells);
  }

  if (tutData.msg) {
    this.makeMsg(tutData.msg.text, tutData.msg.position);
  }

  G.sb('madeMove').addOnce(this.finish, this);

  this.state.lockInput();
};

G.Tutorial.prototype.makeBoosterTutorial = function (tutData) {
  /*
  if (tutData.overlayTask) {
    this.overlay.start(tutData.overlayTask);
  };

  /*if (tutData.handCells) {
    this.makeHandAnim(tutData.handCells);
  }
  */

  if (G.saveState.getBoosterAmount(tutData.boosterNr) === 0) G.saveState.changeBoosterAmount(tutData.boosterNr, 1);

  if (tutData.msg) {
    this.makeMsg(tutData.msg.text, tutData.msg.position, true);
  }

  this.lockBoard();

  this.state.lockInput();
  this.state.boosterPanel.boostersBtn[tutData.boosterNr - 1].unlock();
  this.state.boosterPanel.boostersBtn[tutData.boosterNr - 1].showSuggestion();

  G.sb('onBoosterSelect').addOnce(function () {
    if (tutData.overlayTask) {
      this.overlay.start(tutData.overlayTask);
    }

    this.makeHandAnim(this.tutData.handCells);

    this.state.boosterPanel.boostersBtn[tutData.boosterNr - 1].hideSuggestion();
    this.hideMsg();

    const { activeAction } = this.state.board.actionManager;
    if (!activeAction) return;
    activeAction.availableCandies = this.inputCellsToCandies(this.tutData.inputCells);

    if (this.tutData.boosterNr === 1) {
      activeAction.availableCandies = [
        this.board.getCandy(this.tutData.inputCells[0], this.tutData.inputCells[1]),
      ];
      G.sb('onBoosterSwapCandySelect').addOnce(function () {
        this.hand.destroy();
        this.makeHandAnim([this.tutData.inputCells[2], this.tutData.inputCells[3]]);
        // check
        activeAction.availableCandies = [this.board.getCandy(this.tutData.inputCells[2], this.tutData.inputCells[3])];
      }, this);
    }
  }, this);

  G.sb('onBoosterUsed').addOnce(this.finish, this);
};

G.Tutorial.prototype.makeMsg = function (text, position, shade) {
  if (shade) {
    this.msgShade = G.makeImage(0, 0, 'text_shade_bg', 0.5);
    this.msgShade.alpha = 0.7;
  }

  this.msg = new G.Text(0, 0, OMT.language.getText(text), {
    style: 'font-white',
    fontSize: '40px',
  }, 0.5, 580, 200, true, 'center');
  this.msg.x = (this.board.width - this.board.tileSize * 2) * 0.5;
  this.msg.y = (this.board.height - this.board.tileSize * 2) * (position || 0.7);

  if (shade) {
    this.msgShade.width = this.msg.width + 80;
    this.msgShade.height = this.msg.height + 60;
    this.msgShade.position = this.msg.position;
    this.boardGroup.add(this.msgShade);
  }

  this.boardGroup.add(this.msg);
};

G.Tutorial.prototype.hideMsg = function () {
  if (this.msgShade) game.add.tween(this.msgShade).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
  if (this.msg) game.add.tween(this.msg).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
  this.msg = false;
  this.msgShade = false;
};


G.Tutorial.prototype.afterMsg = function (text, position) {
  if (!text) return;

  if (this.msg) game.add.tween(this.msg).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
  if (this.msgShade) game.add.tween(this.msgShade).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
  if (this.hand) game.add.tween(this.hand).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);

  this.msgShade = G.makeImage(0, 0, 'text_shade_bg', 0.5);
  this.boardGroup.add(this.msgShade);

  this.afterMsg = new G.Text(0, 0, OMT.language.getText(text), {
    style: 'font-white',
    fontSize: '40px',
  }, 0.5, 580, 200, true, 'center');
  this.afterMsg.x = (this.board.width - this.board.tileSize * 2) * 0.5;
  this.afterMsg.y = (this.board.height - this.board.tileSize * 2) * (position || 0.7);
  this.boardGroup.add(this.afterMsg);

  game.add.tween(this.afterMsg).from({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);

  this.msgShade.width = this.afterMsg.width + 80;
  this.msgShade.height = this.afterMsg.height + 60;
  this.msgShade.position = this.afterMsg.position;
  this.msgShade.alpha = 0.7;

  game.add.tween(this.msgShade).from({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);

  game.add.tween(this).to({ alpha: 0 }, 400, Phaser.Easing.Sinusoidal.Out, true, this.tutData.afterMsgTime || 2500).onComplete.add(function () {
    this.destroy();
    G.sb('onTutorialFinishDisplay').dispatch();
  }, this);
};


G.Tutorial.prototype.makeHandAnim = function (array) {
  this.hand = G.makeImage(0, 0, 'tut_hand', 0, this);
  this.hand.alpha = 1;
  this.boardGroup.add(this.hand);

  const tileSize = this.board.tileSize * this.board.scale.x;

  this.hand.x = (tileSize * array[0]) + (tileSize * 0.7);
  this.hand.y = (tileSize * array[1]) + (tileSize * 0.7);

  let toX;
  let toY;

  if (array.length === 2) {
    toX = this.hand.x + 15;
    toY = this.hand.y + 15;
    game.add.tween(this.hand).to({
      x: toX,
      y: toY,
    }, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  } else {
    toX = (tileSize * array[2]) + (tileSize * 0.7);
    toY = (tileSize * array[3]) + (tileSize * 0.7);
    game.add.tween(this.hand).to({
      x: toX,
      y: toY,
    }, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1);
  }
};

G.Tutorial.prototype.addInputCells = function (inputCells) {
  if (!inputCells) return;

  this.board.inputController.possibleCandies = [];
  for (let i = 0; i < this.tutData.inputCells.length; i += 2) {
    this.board.inputController.possibleCandies.push(this.board.getCandy(inputCells[i], inputCells[i + 1]));
  }
};

G.Tutorial.prototype.skip = function () {
  G.sb('onTutorialFinishDisplay').dispatch();
  this.skipped = true;
  this.finish();
};

G.Tutorial.prototype.finish = function () {
  if (this.skipBtn) {
    this.skipBtn.inputEnabled = false;
    game.add.tween(this.skipBtn).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true);
  }
  if (this.gingy) {
    this.gingy.hide();
  }
  this.overlay.hideAndClear();
  this.state.unlockInput();
  G.saveState.data.finishedTutorials.push(this.tutorialID);
  G.saveState.save();


  if (this.tutData.afterMsg && !this.skipped) {
    this.afterMsg(this.tutData.afterMsg, 0.85, this.tutData.afterMsgTime || 3500);
  } else {
    game.add.tween(this).to({ alpha: 0 }, 400, Phaser.Easing.Sinusoidal.Out, true).onComplete.add(function () {
      this.destroy();
    }, this);
    game.time.events.add(this.tutData.afterMsgTime || 3500, function () {
      G.sb('onTutorialFinishDisplay').dispatch();
    }, this);
  }

  this.clearBoardCandyInput();

  G.sb('onTutorialFinish').dispatch();

  G.tutorialOpened = false;
};


G.Tutorial.prototype.lockBoard = function () {
  this.state.board.inputController.possibleCandies = [{}];
};


G.Tutorial.prototype.setBoardCandyInput = function (cells) {
  this.state.board.inputController.possibleCandies = [];

  for (let i = 0; i < cells.length; i += 2) {
    this.state.board.inputController.possibleCandies.push(this.state.board.getCandy(cells[i], cells[i + 1]));
  }
};

G.Tutorial.prototype.clearBoardCandyInput = function () {
  this.state.board.inputController.possibleCandies = [];
};


G.Tutorial.prototype.inputCellsToCandies = function (cells) {
  const result = [];

  for (let i = 0; i < cells.length; i++) {
    result.push(this.board.getCandy(cells[i], cells[i + 1]));
  }

  return result;
};
