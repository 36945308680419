import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

G.Window.prototype.newsHomeScreen = function () {
  G.sb('hideMainHighscorePanel').dispatch();

  this.bg = this.addGeneric9SliceBackground(582, 660);
  // this.blue_banner = G.makeImage(0, -295, 'blue_banner', 0.5, this);
  // this.title = new G.Text(0, -297, OMT.language.getText('Free Coins!'), {
  //   style: 'font-white',
  //   fontSize: '60px',
  // }, 0.5, 330);
  // this.add(this.title);

  this.star1 = G.makeImage(250, -286, 'star', 0.5, this);
  this.star1.angle = 29;
  this.star2 = G.makeImage(-250, -286, 'star', 0.5, this);
  this.star2.angle = -29;

  this.img = G.makeImage(0, -65, 'gate_unlock_pic', 0.5, this);
  this.img.scale.setTo(0.85);

  this.likeTxt = this.add(new G.Text(0, 105, OMT.language.getText('Like %GameName%?'), {
    style: 'font-blue',
    fontSize: 40,
  }, 0.5, 510));

  this.addAppTxt = this.add(new G.Text(0, 145, OMT.language.getText('Add %GameName% to your Home Screen!'), {
    style: 'font-blue',
    fontSize: 20,
  }, 0.5, 510));

  // const { uiTargetParticles } = game.state.getCurrentState();

  const addToHome = () => {
    OMT.platformFunctions.createShortcutAsync()
      .then(() => {
        // Shortcut created
        G.sb('showMainHighscorePanel').dispatch();
        G.saveState.homescreenShortcutManager.onShortcutAdded();
      })
      .catch((e) => {
        // Shortcut not created
        G.sb('showMainHighscorePanel').dispatch();
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.HomeScreenIconReject);
        this.closeWindow();
      });
  };
  this.button = new OMT_UI_SquareButton(G.OMTsettings.elements.Window_newsHomeScreen.button.x, G.OMTsettings.elements.Window_newsHomeScreen.button.y, {
    button: {
      tint: BUTTONCOLOURS.green,
      dimensions: {
        width: 445,
        height: 100,
      },
    },
    text: {
      string: OMT.language.getText('Add to Home Screen'),
      textStyle: { style: 'font-white', fontSize: 40 },
    },
    options: {
      clickFunction: {
        onClick: addToHome.bind(this),
      },
    },
  });

  if (G.OMTsettings.elements.Window_newsHomeScreen.button.shouldPulse) this.button.pulse(1.1);
  this.add(this.button);

  const scaleMultiplier = 0.5;
  const onClick = () => {
    G.sb('showMainHighscorePanel').dispatch();
    this.closeWindow();
  };
  this.continueBtn = new Phaser.Group(game, this);
  const butt = new OMT_UI_SquareButton(0, 0, {
    button: {
      tint: BUTTONCOLOURS.orange,
      dimensions: {
        width: 196 / scaleMultiplier,
        height: 100,
      },
      extraDetail: false,
    },
    options: {
      clickFunction: {
        onClick: onClick.bind(this),
      },
    },
  });
  this.continueBtn.addChild(butt);
  this.continueBtn.scale.set(scaleMultiplier);
  this.continueBtn.x = G.OMTsettings.elements.Window_newsHomeScreen.continueBtn.x;
  this.continueBtn.y = G.OMTsettings.elements.Window_newsHomeScreen.continueBtn.y;
  this.add(this.continueBtn);

  this.continueBtnTxt = new G.Text(G.OMTsettings.elements.Window_newsHomeScreen.continueBtnTxt.x, G.OMTsettings.elements.Window_newsHomeScreen.continueBtnTxt.y, OMT.language.getText('No, thanks'), {
    style: 'font-white',
    fontSize: '35px',
    lineSpacing: -15,
  }, 0.5, (butt.width * scaleMultiplier) * 0.9, (butt.height * scaleMultiplier) * 0.9, true, 'center');
  this.add(this.continueBtnTxt);
};
