/* eslint-disable prefer-destructuring */
export default class LvLGoalMgr {
  /**
   * cosntructor
   * @param {Array} goalData
   * @param {Object} gameplayGoals
   */
  constructor(goalData, gameplayGoals) {
    this.onGoalAchieved = new Phaser.Signal();
    this.onGoalNumberChanged = new Phaser.Signal();

    this.goalsSettings = gameplayGoals;

    this._bindings = [];

    if (goalData[0] === 'collect') {
      this.COLLECT = true;
      this.tasks = this.processCollectGoals(goalData[1]); // Accessed externally
      this._bindings.push(
        G.sb('onCollectableRemove').add(this.onCollectableRemove, this),
        G.sb('onCollectableAdded').add(this.onCollectableAdded, this),
      );
    } else {
      this.POINTS = true;
      this.pointsTarget = goalData[1];
      this._bindings.push(
        G.sb('onPointsChange').add(this.onPointsChange, this),
      );
    }
  }

  destroy() {
    this.emptyBindings();
    if (this.onGoalAchieved) {
      this.onGoalAchieved.dispose();
      this.onGoalAchieved = null;
    }
    if (this.onGoalNumberChanged) {
      this.onGoalNumberChanged.dispose();
      this.onGoalNumberChanged = null;
    }
  }

  emptyBindings() {
    if (this._bindings) {
      this._bindings.forEach((binding) => {
        if (binding.detach) {
          binding.detach();
        }
      });
      this._bindings = null;
    }
  }

  isPointBased() {
    return this.POINTS;
  }

  getPointTarget() {
    return this.pointsTarget;
  }

  /**
   * Checks if the task was ever part of the goal, regardless if its completed or not
   * @param {string} type
   * @returns {boolean}
   */
  wasEverAGoal(type) {
    if (typeof this.tasks === 'undefined') return false;
    const matchingTask = this.tasks.find((task) => task.isInCollectables(type));
    return Boolean(matchingTask);
  }

  isGoal(type) {
    if (typeof this.tasks === 'undefined') return false;
    const matchingTask = this.tasks.find((task) => task.isInCollectables(type) && !task.completed);
    return Boolean(matchingTask);
  }

  getGoalLeftAmount(type) {
    const task = this._getTaskByType(type);
    if (task) {
      return task.target;
    }
    return null;
  }

  goalAchieved() {
    this.emptyBindings();
    this.onGoalAchieved.dispatch();
  }

  // POINTS
  onPointsChange(newAmount) {
    if (newAmount >= this.pointsTarget && this.pointsTarget !== -1) {
      this.goalAchieved();
    }
  }

  // COLLECT
  processCollectGoals(collectGoals) {
    const result = [];
    collectGoals.forEach((goal) => {
      const type = goal[0];
      const target = goal[1];
      const config = this.goalsSettings[type];
      const collectables = G.Utils.defined(this.goalsSettings[type].collectables, [type]);

      const goalObj = {
        isInCollectables: (collectable) => collectables.indexOf(collectable) !== -1,
        id: type,
        target,
        dynamic: config.dynamic || false,
        uiAnimation: config.toUIAnimation,
        completed: false,
      };
      result.push(goalObj);
    }, this);
    return result;
  }

  onCollectableAdded(type) {
    const task = this._getTaskByType(type);
    if (task && task.dynamic && !task.completed) {
      task.target++;
      G.sb('onTaskAmountChanged').dispatch(type, 1);
    }
  }

  _getTaskByType(type) {
    return this.tasks.find((task) => task.isInCollectables(type));
  }

  onCollectableRemove(type, elem, sprite) {
    const task = this._getTaskByType(type);

    if (task && !task.completed) {
      if (task.uiAnimation) {
        G.sb('onCandyToUIAnim').dispatch(type, elem, sprite, () => {
          G.sb('onTaskAmountChanged').dispatch(task.id, -1);
        });
      } else {
        G.sb('onTaskAmountChanged').dispatch(task.id, -1);
      }

      task.target--;
      if (task.target === 0) {
        G.sb('onCollectableTaskFinished').dispatch(task.id);
        task.completed = true;
        if (this.areAllCompleted()) {
          this.goalAchieved();
        }
      }
    }
  }

  areAllCompleted() {
    let allCompleted = true;
    this.tasks.forEach((task) => {
      if (!task.completed) allCompleted = false;
    }, this);

    return allCompleted;
  }
}
