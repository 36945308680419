import { Window_OverlayReward } from './Window_overlayReward';

/**
 * Class for optional blocked daily challenge Opt-In prompt.
 */
class Window_DailyMissionMainReward extends Window_OverlayReward {
  /**
   * constructor
   * @param {Object} parent
   */
  constructor(parent) {
    super(parent);
    this.overlayReward(this._getRewardConfig());
    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenMissionCompleteShareBtn', 1);
  }

  /**
   * get config for the reward overlay
   * @returns {Object}
   */
  _getRewardConfig() {
    const { dailyMissionsMgr } = G;
    const rewardSettings = G.json['configs/miniMissions'].mainReward;
    const { icon } = dailyMissionsMgr.getCollectionIcons();

    const boosters = {
      cupcake_purple: rewardSettings.boosters.cupcake,
      lolly_purple: rewardSettings.boosters.lolly,
      donut_purple: rewardSettings.boosters.donut,
      icecream_purple: rewardSettings.boosters.icecream,
    }[icon];

    for (let i = 0; i < boosters.length; i++) {
      const gifto = boosters[i][0];
      if (gifto === 'booster#5' && G.IAP) {
        boosters[i][0] = 'booster#6';
      }
    }

    const config = {
      unlimitedLivesMin: G.OMTsettings.unlimitedLivesMinDefaultReward,
      gifts: boosters,
      ddnaEventData: { transactionType: 'REWARD', tActionType: 'DAILY_MISSION', tGameArea: 'SIDE_SCREEN' },
      endMsg: OMT.language.getText('You got unlimited lives for 15 minutes!'),
      showShare: true,
      shareFunction: (callback) => {
        OMT.social.shareMissionCompleteMsg(() => {
          // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('usedMissionCompleteShareBtn', 1);
          if (callback) callback();
        });
      },
      tweensSettings: [
        { x: 10, y: -266, angle: -3 },
        { x: 133, y: -499, angle: 15 },
        { x: -133, y: -425, angle: -10 },
      ],
    };
    return config;
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.dailyMissionMainReward = Window_DailyMissionMainReward;
