import OOMSpinBtn from '../components/OOMSpinBtn';
import OutOfMovesInfoPanel from '../components/OOMInfoPanel';
import { BUTTONCOLOURS } from '../../../../OMT_UI/OMT_UI_SquareButton';
import { Window_FillRateFallback } from '../../Window_FillRateFallback';
import OOMBuyMovesButton from '../components/OOMBuyMovesBtn';
import OMT_VILLAINS from '../../../../OMT_UI/OMT_Villains';
import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';

/**
 * Out Of Moves base fail view
 */
export default class OOMFailView extends Phaser.Group {
  /**
   * constructor
   * @param {Array} additionalBoosterData
   */
  constructor(additionalBoosterData) {
    super(game);

    this._signalBindings = [];
    this._additionalBoosterData = additionalBoosterData || null;

    this._init();
  }

  /**
   * main intialization method
   */
  _init() {
    this._initSignals();
    this._initTitleText();
    this._initInfoPanel();
    this._initSpinButton();
    this._initBuyMovesBtn(0, 115);
  }

  /**
   * initialize signal Object / Signals
   */
  _initSignals() {
    this.signals = {
      onSpinSuccess: new Phaser.Signal(),
      onResolveOutOfMoves: new Phaser.Signal(),
      onGoToCoinShop: new Phaser.Signal(),
    };
  }

  /**
   * add a signal binding
   * @param {Phaser.SignalBinding} binding
   */
  _addSignalBinding(binding) {
    this._signalBindings.push(binding);
  }

  /**
   * detach global listeners and det
   */
  _killSignalsAndBindings() {
    for (const signal of Object.values(this.signals)) signal.dispose();
    for (const signalToken of this._signalBindings) signalToken.detach();
    this._signalBindings.length = 0;
  }

  /**
   * init the title text
   */
  _initTitleText() {
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const { isDailyChallengeLevel } = LvlDataManager.getInstance();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;
    let titleTxtStyle = G.OMTsettings.global.window.outOfMovesTitle.textStyle;
    if (isNotNormalLevel) {
      titleTxtStyle = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_4') : OMT_VILLAINS.getPrefixedName('hard_4');
    }
    const titleTxt = new G.Text(0, -187, OMT.language.getText('Out of moves!'), titleTxtStyle, 0.5, 420);
    this.addChild(titleTxt);
  }

  /**
   * init the info panel
   */
  _initInfoPanel() {
    const infoPanel = new OutOfMovesInfoPanel(0, -80);
    this.addChild(infoPanel);
  }

  /**
   * init the spin button
   */
  _initSpinButton() {
    const onSuccess = () => {
      this.signals.onSpinSuccess.dispatch();
    };

    const spinBtn = new OOMSpinBtn(0, 220, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: { width: 445, height: 100 },
        extraDetail: false,
      },
      text: {
        string: '',
        textStyle: { style: 'font-white', fontSize: 50 },
      },
      options: { cacheButton: false },
    });

    this._addSignalBinding(spinBtn.signals.onClick.add(() => {
      if (G.saveState.isFreeMoneySpinAvailable() && OMT.feature.failFlowWheelFreeSpin()) {
        onSuccess.call(this);
      } else if (game.incentivised()) {
        OMT.ads.showAd(
          G.BuildEnvironment.adPlacements.spinTheLevelLostWheel,
          onSuccess.bind(this),
          // on ad fail
          () => {
            if (!G.IAP) { // no IAPS dont use ad fallbacks
              // eslint-disable-next-line no-new
              new Window_FillRateFallback(undefined, {
                placement: G.BuildEnvironment.adPlacements.spinTheLevelLostWheel,
                callback: onSuccess.bind(this),
                context: this,
              });
            } else { // open ad fallback
              OMT.ads.showAdFallback(onSuccess.bind(this));
            }
          },
        );
      }
      // DDNA.tracking.getDataCapture().addToPlayerCharacterizationSessionParam('lossAversionActionsThisSession', 1);
    }));
    this.addChild(spinBtn);
    this._spinBtn = spinBtn;
  }

  /**
   * init the button to buy moves
   * @param {number} x The x position of the button
   * @param {number} y The y position of the button
   */
  _initBuyMovesBtn(x, y) {
    const boosterType = G.gift.GiftContentType.StartBoosterDefaultMovesInstant;
    let boosterRewardType = boosterType;
    if (boosterType.toLowerCase().indexOf('instant') > -1) { // For rewards DDNA purposes, I need the non-instant version
      boosterRewardType = boosterType.substr(0, G.gift.GiftContentType.StartBoosterDefaultMoves.length);
    }
    const { failFlowConfig } = G.json.settings;
    const currentPurchaseIndex = G.lvl.numOfExtraMovesBought;
    let price = G.lvl.getPriceOfExtraMoves();
    // use old way multiplier calculate the price
    if (!failFlowConfig) price = Math.round(price * 1.1);
    let additionalBoosterData = null;

    if (failFlowConfig && currentPurchaseIndex >= failFlowConfig.additionalBooster.unlockAtPurchaseIndex) {
      // use previous settings if passed on construction
      additionalBoosterData = this._additionalBoosterData || G.gift.processGift(G.Utils.clone(failFlowConfig.additionalBooster.gift));
    }

    const onBuyButtonClicked = (button) => {
      if (this.alpha < 1) { return; }
      if (G.saveState.getCoins() >= price) {
        button.input.enabled = false;
        const rewards = [[boosterRewardType, 1]]; // list for merging to 1 tracking event for ddna
        if (additionalBoosterData) rewards.push(additionalBoosterData);

        G.saveState.changeCoins(-price);
        G.sfx.cash_register.play();
        this.signals.onResolveOutOfMoves.dispatch();
        G.lvl.markExtraMovesPurchase(price);

        //const dataCapture = DDNA.tracking.getDataCapture();
       // dataCapture.addToPlayerCharacterizationSessionParam('extraMovesRescueBoughtThisSession', 1);
      //  dataCapture.addToPlayerCharacterizationSessionParam('lossAversionActionsThisSession', 1);

        // DDNA.transactionHelper.trackRewards(rewards, rewards[0], {
        //   transactionType: 'COIN_PURCHASE',
        //   tActionType: 'BOOSTER_USE',
        //   tGameArea: 'FAIL_FLOW',
        //   tCoinsUsed: price,
        //   tFailFlowExtraMoves: 1,
        //   tLevelExtraMoves: true, // *** COOK-OMT Duplicate ***
        // });

        OMT.transactionTracking.logInventoryTransactionBegin();
        OMT.transactionTracking.addInventoryChange('coins', 0, -price);

        if (boosterType.indexOf('!') === -1) { // booster is non-instant type
          const boosterNum = parseInt(boosterType.match(/\d+/)[0]);
          if (boosterNum) {
            OMT.transactionTracking.addInventoryChange('boostersReceived', boosterNum, 1);
            OMT.transactionTracking.addInventoryChange('boostersUsed', boosterNum, 1);
          }
        }
        if (additionalBoosterData) G.gift.applyGift(additionalBoosterData, false, 'Gameplay', 'FailFlowPurchase');

        OMT.transactionTracking.logInventoryTransactionEnd();
      } else if (G.IAP) {
        this.signals.onGoToCoinShop.dispatch();
      }
    };

    // create the buy button instance
    const buyButton = new OOMBuyMovesButton(x, y, price, additionalBoosterData, boosterType);
    this._addSignalBinding(buyButton.signals.onClick.add(onBuyButtonClicked.bind(this), buyButton));
    this.addChild(buyButton);
    this._additionalBoosterData = additionalBoosterData;
    if (!G.IAP && !game.incentivised() && price > G.saveState.getCoins()) buyButton.visible = false;
  }

  /**
   * get additional booster if set
   * @returns {Object}
   */
  get additionalBoosterData() {
    return this._additionalBoosterData;
  }

  /**
   * desruction method
   */
  destroy() {
    this._killSignalsAndBindings();
    super.destroy();
  }
}
