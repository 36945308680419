G.TutorialHand = function (board) {
  G.Image.call(this, 0, 0, 'tut_hand', 0);

  this.board = board;

  this.alpha = 0;
  this.alphaTween = null;
  this.moveTween = null;
};

G.TutorialHand.prototype = Object.create(G.Image.prototype);

G.TutorialHand.prototype.setCells = function (cells) {
  if (cells === undefined) return this.hide();

  this.show();

  if (this.moveTween) {
    this.moveTween.stop();
    this.moveTween = null;
  }

  const { tileSize } = this.board;

  this.x = (cells[0] * tileSize) + (tileSize * 0.7);
  this.y = (cells[1] * tileSize) + (tileSize * 0.7);

  let toX;
  let toY;
  let yoyo;

  if (cells.length === 2) {
    toX = this.x + 15;
    toY = this.y + 15;
    yoyo = true;
  } else {
    toX = (cells[2] * tileSize) + (tileSize * 0.7);
    toY = (cells[3] * tileSize) + (tileSize * 0.7);
    yoyo = false;
  }

  this.moveTween = game.add.tween(this)
    .to({
      x: toX,
      y: toY,
    }, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, yoyo);
};

G.TutorialHand.prototype.show = function () {
  this.startAlphaTween(1);
};

G.TutorialHand.prototype.hide = function () {
  this.startAlphaTween(0);
};

G.TutorialHand.prototype.startAlphaTween = function (alphaTarget) {
  if (this.alphaTween) {
    this.alphaTween.stop();
    this.alphaTween = null;
  }
  
  if (this.alpha === alphaTarget) return;

  this.alphaTween = game.add.tween(this)
    .to({ alpha: alphaTarget }, 300, Phaser.Easing.Sinusoidal.Out, true);
};
