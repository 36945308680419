if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getAtoBAnimation = function getAtoBAnimation() {
  return { animate: animate };

  function animate(game, target, targetPos, duration) {
    return game.add
      .tween(target)
      .to(
        { x: targetPos.x, y: targetPos.y },
        duration || 500,
        Phaser.Easing.Sinusoidal.Out,
        true
      );
  };
}
