import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import EventPostcard_Util from './EventPostcard_Util';

export const POSTCARD_STATE = {
  CLAIM: 0,
  SEND: 1,
};

/**
 * The individual post cards that are shown in window_eventPostcard.
 * Its totally not like a friendship chest card.
 * @author Sandra Koo
 */
export default class EventPostcard extends Phaser.Group {
  constructor(cardDimensions) {
    super(game, null);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this.signals = {
      onClick: new Phaser.Signal(),
    };
    const dimensions = {
      width: cardDimensions.width || 500,
      height: this._isLandscape
        ? (cardDimensions.height || 800) / this._gameScale
        : cardDimensions.height || 800,
    };

    this.nextCard = undefined; // Keep track of the card next of it
    this.prevCard = undefined; // Keep track of the card before it
    this.index = 0; // The index of the card
    this.fillContainer = undefined; // The fill container that hides and shows often
    this._button = undefined; // The button that gets clicked
    this._state = undefined; // The current state of the card. Doesn't change much
    this._card = undefined; // The card asset that isn't this
    this._subFill = undefined; // The container inside the fill container
    this._border = undefined; // The card border background

    this._drawCard(dimensions);
  }

  /**
   * Returns the state of the postcard
   * @returns {POSTCARD_STATE}
   */
  get state() {
    return this._state;
  }

  /**
   * Dessssssstroy!
   */
  destroy() {
    this.signals.onClick.dispose();

    if (this._button) {
      this._button.destroy();
    }

    if (this._signalBinding) {
      if (this._signalBinding.detach) {
        this._signalBinding.detach();
      }
      this._signalBinding = null;
    }

    super.destroy();
  }

  /**
   * Returns bounds of the card instead of anything else
   * @returns {Phaser.Rectangle}
   */
  getBounds() {
    return this._card.getBounds();
  }

  /**
   * Draws the bare minimum to be called a postcard card
   */
  _drawCard(cardDimensions) {
    // visual container
    this._card = new Phaser.Group(game, this);

    // The container that holds stuff
    this.fillContainer = new Phaser.Group(game, this._card);
    this._subFill = new Phaser.Group(game, this.fillContainer);
    this._subFill.y -= 35;

    // Background
    this._envelope = this._getLetterBackground(cardDimensions);
    this._envelope.anchor.set(0.5);
    this._subFill.addChild(this._envelope);

    this._border = this._drawBorder(cardDimensions);
    this.renderedWidth = cardDimensions.width;

    // A mask to keep assets within the card
    const cardMask = new Phaser.Graphics(game);
    cardMask.beginFill(0, 0.5);
    cardMask.drawRect(-this._border.width / 2, -this._border.height / 2, this._border.width, this._border.height);
    cardMask.endFill();

    this._card.addChild(cardMask);
    cardMask.mask = cardMask;
  }

  _getLetterBackground(cardDimensions) {
    const img = new PhaserNineSlice.NineSlice(0, 25, 'eventPostcard', 'eventPostcard_letter', cardDimensions.width, cardDimensions.height + 25, {
      top: 96,
      left: 87,
      right: 90,
      bottom: 98,
    });
    img.angle = 10;
    return img;
  }

  _drawBorder(cardDimensions) {
    const img = G.makeImage(0, 0, 'eventPostcard_bg', 0.5, this._subFill);
    img.width = cardDimensions.width;
    img.height = cardDimensions.height;
    return img;
  }

  /**
   * Fills the card with the given data. Much simpler than friendship chest card
   * A resize function is attached at the end
   * @param {Object} cardData
   * @param {POSTCARD_STATE} cardData.state The state of the card
   * @param {number} cardData.index  The index of the card
   * @param {string} cardData.speechText The string for the speech bubble in the post card avatar
   * @param {string} cardData.cardCaption The caption just above the button
   * @param {Object} cardData.promo Object for promo card
   * @param {string} cardData.promo.caption The caption for the promo card
   * @param {Phaser.DisplayObject} cardData.promo.image The image for the promo card. Supposed to be drawn externally
   * @param {string} avatarImg The address of the user's avatar image
   */
  fillCard(cardData, avatarImg) {
    this._state = cardData.state;
    this.index = cardData.index;

    // Draws card based on state
    switch (this._state) {
      case POSTCARD_STATE.CLAIM:
        this._fillAsPromo(cardData);
        break;
      case POSTCARD_STATE.SEND:
        this._fillAsCard(cardData, avatarImg);
        break;
      default: break;
    }

    this._signalBinding = G.sb('onScreenResize').add(this.onResize, this);
  }

  /**
   * Draws the promo card using the given data
   * @param {Object} cardData
   * @param {Object} cardData.promo Object for promo card
   * @param {string} cardData.promo.caption The caption for the promo card
   * @param {Phaser.DisplayObject} cardData.promo.image The image for the promo card. Supposed to be drawn externally
   */
  _fillAsPromo(cardData) {
    if (!cardData.promo) { return; }

    // Button made first and positioned
    const theBigButton = this._makeButton('Claim');
    theBigButton.y = (game.height - (theBigButton.height / 2)) / 2;
    this._button = theBigButton;
    this._subFill.addChild(theBigButton);

    // Image is positioned next
    const img = cardData.promo.image;
    // img.y = this._border.y + ((this._border.height - img.height) / 2) - this._border.height * 0.1;
    img.y = this._border.y;
    this._subFill.addChild(img);

    const caption = new G.Text(0, 0, OMT.language.getText(cardData.promo.caption), 'eventPostcard-caption', 0.5, game.width * 0.95, 100, true, 'center');
    caption.y = Math.round(theBigButton.y - (theBigButton.height / 2) - (caption.height));
    this._subFill.addChild(caption);

    this._subFill.y -= 25;
  }

  /**
   * Draws the card with the given data
   * @param {Object} cardData
   * @param {POSTCARD_STATE} cardData.state The state of the card
   * @param {number} cardData.index  The index of the card
   * @param {string} cardData.cardCaption The caption just above the button
   * @param {string} cardData.cardTitle The title of the card
   * @param {string} avatarImg The address of the user's avatar image
   */
  _fillAsCard(cardData, avatarImg) {
    const postcardData = G.OMTsettings.postcardEvent.cardData.postcardData[this.index];
    // Generate postcard image
    const postcardImage = G.makeExtImage(0, 0, postcardData.image, 'eventPostcard_PromoBg', 0.5, this._subFill, null, (image) => {
      image.scale.set(1);
      const widthScale = (this._border.width * 0.9) / image.width;
      image.scale.set(widthScale);
    });
    const widthScale = (this._border.width * 0.9) / postcardImage.width;
    postcardImage.scale.set(widthScale);

    // Draw the avatar
    const profileContainer = EventPostcard_Util.drawAvatarWithFrame(avatarImg, 150);
    this._subFill.addChild(profileContainer);

    // Draw the big button
    const theBigButton = this._makeButton('Send');
    theBigButton.y = (game.height - theBigButton.height) / 2;
    this._button = theBigButton;

    // With the leftover space, make it for the caption
    const caption = new G.Text(0, 0, OMT.language.getText(cardData.cardCaption), 'eventPostcard-caption', 0.5, game.width * 0.95, 50);
    caption.y = Math.round(theBigButton.y - (theBigButton.height + caption.height) / 2);

    this._subFill.addChild(caption);
    this._subFill.addChild(theBigButton);

    this._subFill.positionSenitive = {
      avatar: profileContainer,
      /** This exists, but not right now
      speechBubble: {
        text: bubbleData.text,
        bubble: bubbleData.bubble,
      },
      */
      caption,
    };
    this.onResize();
  }

  /**
   * Repositions everything when screen resizes
   */
  onResize() {
    if (!this.fillContainer.visible) { return; }
    if (this._state !== POSTCARD_STATE.SEND) { return; }

    this._subFill.positionSenitive.avatar.x = Math.max(-this._border.width, 25 + (this._subFill.positionSenitive.avatar.width - game.width) / 2);
    this._subFill.positionSenitive.avatar.y = this._border.y + (this._border.height * (0.99 / 2));

    if (this._subFill.positionSenitive.speechBubble) {
      this._subFill.positionSenitive.speechBubble.bubble.destroy();
    }
    const speechSlice = G.OMTsettings.postcardEvent.cardData.speechBubble.slice;
    const leftoverSpeechSpace = Math.min(this._border.width * 2, game.width - (this._subFill.positionSenitive.avatar.x + (game.width / 2)) - speechSlice.left);
    const bubbleData = EventPostcard_Util.makeSpeechBubble(this.index, this._subFill.positionSenitive.avatar, leftoverSpeechSpace);
    this._subFill.addChild(bubbleData.bubble);
    this._subFill.positionSenitive.speechBubble = {
      text: bubbleData.text,
      bubble: bubbleData.bubble,
    };

    G.Text.setMaxWidth(this._subFill.positionSenitive.caption, game.width * 0.95);
    this._subFill.positionSenitive.caption.setText(this._subFill.positionSenitive.caption.text);
  }

  /**
   * Function to create the button.
   * @param {string} text
   * @returns {OMT_UI_SquareButton}
   */
  _makeButton(text) {
    const theBigButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 200,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText(text),
        textStyle: { style: 'font-white', fontSize: 48 },
      },
      options: {
        clickFunction: {
          onClick: this.onButtonClick.bind(this),
          disableAfterClick: this._state === POSTCARD_STATE.CLAIM, // Claim cards are disabled after click
        },
      },
    });
    return theBigButton;
  }

  /**
   * Signal is dispatched when the button is clicked
   */
  onButtonClick() {
    this.signals.onClick.dispatch(this);
  }
}
