
import { Window_BragBaseWindow, BUTTON_Y_OFFSET_FULLSCREEN } from './Window_BragBaseWindow';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';

const BANNER_TITLE_STRING = 'Congrats!';
const BANNER_SUBTITLE_STRINGS = ['You played like a pro!', 'Would you like to skip the tutorial?'];

const BUTTONS_HORIZONTAL_OFFSET = 11;

/**
 * Window for first time users to skip the FTUX tutorials
 */
class Window_BragSkipTutorial extends Window_BragBaseWindow {
  /**
   * constructor
   * @param {object} parent
   * @param {number} levelNum
   */
  constructor(parent, levelNum) {
    super(parent, G.OMTsettings.elements.Window_Brag, levelNum);
  }

  /**
   * override create method add new buttons
   */
  async _create() {
    await super._create();
    this._createSkipButton();
    this._createPlayButton();
    this._onResize();
  }

  /**
   * override customize the various window banners
   */
  _createBanners() {
    this._createTopBanner(OMT.language.getText(BANNER_TITLE_STRING));
    this._createSubTitles(OMT.language.getTextList(BANNER_SUBTITLE_STRINGS));
  }

  /**
   * create the specific window content
   */
  _createContent() {
    super._createContent();
    this._initSkipTutorialAvatarLayout();
  }

  /**
   * override we dont want a brag button
   */
  _createBragButton() {}

  /**
   * create the skip button
   */
  _createSkipButton() {
    this._buttonSkip = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: { width: 250, height: 100 },
      },
      text: {
        string: OMT.language.getText('Skip'),
        textStyle: { style: 'font-white', fontSize: 72 },
      },
      options: {
        clickFunction: {
          onClick: () => {
            G.saveState.skipFTUETutorials();
            this.closeWindow();
          },
        },
      },
    });
    this._layoutContainer.addChild(this._buttonSkip);
  }

  /**
   * create the play button
   */
  _createPlayButton() {
    this._buttonPlay = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: { width: 250, height: 100 },
      },
      text: {
        string: OMT.language.getText('Play'),
        textStyle: { style: 'font-white', fontSize: 72 },
      },
      options: {
        clickFunction: {
          onClick: () => {
            this.closeWindow();
          },
        },
      },
    });
    this._layoutContainer.addChild(this._buttonPlay);
  }

  /**
   * update responsive fullscreen layout
   */
  _updateFullScreenLayout() {
    super._updateFullScreenLayout();
    if (this._buttonPlay && this._buttonSkip) { // adjust button positions
      this._buttonPlay.x = -(this._buttonPlay.width / 2) - BUTTONS_HORIZONTAL_OFFSET;
      this._buttonPlay.y = (this._layoutRect.height / 2) - (this._buttonPlay.height / 2) - BUTTON_Y_OFFSET_FULLSCREEN;
      this._buttonSkip.x = (this._buttonSkip.width / 2) + BUTTONS_HORIZONTAL_OFFSET;
      this._buttonSkip.y = (this._layoutRect.height / 2) - (this._buttonSkip.height / 2) - BUTTON_Y_OFFSET_FULLSCREEN;
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.bragSkipTutorial = Window_BragSkipTutorial;
