/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import { ORIENTATION } from '../Services/OMT/OMT_SystemInfo'
import { GameScaleController } from '../States/Scaling/GameScaleController';
import OMT_TweenUtils from '../Utils/Animation/OMT_TweenUtils';
import OMT_StackManager from '../Utils/OMT_StackManager';

export default class OMT_UI_HighlightBackground extends Phaser.Group {
  constructor(config) {
    super(game);
    const { alpha, isFullscreen } = config;
    this.maskAlpha = alpha;

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;
    this._isFullscreen = isFullscreen;

    const maskBitmapData = game.make.bitmapData(game.width + 1, game.height + 1);
    this._maskBitmapData = maskBitmapData;

    const maskImage = game.add.image(0, 0, maskBitmapData);
    maskImage.anchor.setTo(0.5);
    maskImage.inputEnabled = true;
    this.add(maskImage);
    this.maskImage = maskImage;

    const roundHighlight = G.makeImage(0, 0, 'round_highlight', 0);
    roundHighlight.inputEnabled = true;
    roundHighlight.visible = false;
    roundHighlight.alpha = 0;
    this.roundHighlight = roundHighlight;
  }

  /**
   * Resize the background based on the game width and height and also refocuses on an object if any
   */
  resize() {
    const { maskAlpha, targetObject, _maskBitmapData, roundHighlight } = this;

    if (game.width >= 4000 || game.height >= 4000) return;
    const maskWidth = Math.floor(this._isLandscape && !this._isFullscreen ? game.width / this._gameScale : game.width);
    const maskHeight = Math.floor(this._isLandscape && !this._isFullscreen ? game.height / this._gameScale : game.height);

    _maskBitmapData.resize(maskWidth + 1, maskHeight + 1);
    _maskBitmapData.cls();
    _maskBitmapData.fill(0, 0, 0, maskAlpha);
    if (targetObject) {
      const { worldPosition, width, height } = targetObject;
      const { x, y } = worldPosition;
      if (!this.roundHighlightDisabled) {
        roundHighlight.alpha = maskAlpha;
        const size = Math.floor(Math.max(width + 25, height + 25) / 2) * 2;
        const bitmapX = Math.floor(x - size * 0.5);
        const bitmapY = Math.floor(y - size * 0.5);
        _maskBitmapData.clear(bitmapX, bitmapY, size, size);
        _maskBitmapData.draw(
          roundHighlight,
          bitmapX,
          bitmapY,
          size,
          size,
          undefined,
          true,
        );
      } else {
        const bitmapX = Math.floor(x - width * 0.5);
        const bitmapY = Math.floor(y - height * 0.5);
        _maskBitmapData.clear(bitmapX, bitmapY, width, height);
      }
    } else {
      roundHighlight.alpha = 0;
    }
    _maskBitmapData.update();
  }

  /**
   * Any Phaser object or an object looking like a Phaser object can be passed here
   * It just needs width, height, and a worldPosition containing x and y values
   * @param {any} object
   */
  highlight(object) {
    this.targetObject = object;

    this.resize();
  }

  /**
   * Clears the area around the object based on the world position and dimensions
   * @param {Phaser.DisplayObject} object
   */
  clearObject(object) {
    const { _maskBitmapData } = this;
    const { worldPosition, width, height } = object;
    const { x, y } = worldPosition;
    const bitmapX = Math.floor(x - width * 0.5);
    const bitmapY = Math.floor(y - height * 0.5);
    _maskBitmapData.clear(bitmapX, bitmapY, width, height);
  }

  /**
   * Toggle round highlight on of off
   * @param {boolean} state
   */
  disableRoundHighlight(state) {
    this.roundHighlightDisabled = state;
  }

  /**
   * Animate in
   */
  async show() {
    const { maskAlpha } = this;
    const stack = OMT_StackManager.getFreeStack();

    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: this,
        alpha: maskAlpha,
        duration: 300,
      }),
    );

    return stack.run();
  }

  /**
   * Animate out
   */
  async hide() {
    const stack = OMT_StackManager.getFreeStack();

    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: this,
        alpha: 0,
        duration: 300,
      }),
    );

    return stack.run();
  }

  /**
   * Toggles if this component blocks input or not
   * @param {boolean} state
   */
  toggleInput(state) {
    const { maskImage, roundHighlight } = this;
    maskImage.inputEnabled = state;
    roundHighlight.inputEnabled = state;
  }

  /**
   * Destroy the object while also destroying the bitmap data (this is important since it is not destroyed / recycled automatically!)
   */
  destroy() {
    const { _maskBitmapData } = this;
    _maskBitmapData.destroy();
    super.destroy();
  }
}
