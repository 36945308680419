import { Window } from '../../../00_IMMEDIATE/Window';

/**
 * Generic class for game move promotion window.
 */
export class Window_GameModePromoBase extends Window {
  /**
  * constuctor
   * @param {Object} parent
   * @param {Object} config { icon:UI_TournamentIcon }
   */
  constructor(parent, config) {
    super(parent);

    this._mapIcon = config.icon;
    this._tutorialTextKey = 'Umm...'; // override

    this._initDisplayElements();

    this.onFinishedEnter.addOnce(() => {
      this._mapIcon.alpha = 0;
      this._startPromoAnimation();
    });

    G.sb('onScreenResize').add(this._onResize, this);
    this._onResize();
  }

  /**
   * animate the window in. override and turn off scaling.
   * @param {boolean} isFullscreen
   */
  _setOpenWindowAnimation(isFullscreen) {
    super._setOpenWindowAnimation(isFullscreen);
  }

  /**
   * initialize display elements
   */
  _initDisplayElements() {
    // overload
  }

  /**
   * create promo header group
   * @param {constructor} HeaderType
   */
  _initHeaderGroup(HeaderType) {
    const headerGroup = new HeaderType();
    headerGroup.onCloseClicked.addOnce(() => {
      this.closeWindow(null, null, 1.2);
      this._mapIcon.alpha = 1;
    });
    this.addChild(headerGroup);
    this._headerGroup = headerGroup;
  }

  /**
   * create a sidebar icon in PROMO state
   * @param {constructor} IconType
   */
  _initGamePromoIcon(IconType) {
    const iconLocalPos = this.parent.toLocal(this._mapIcon, this._mapIcon.parent);
    const icon = new IconType();
    icon.setPromoState();
    icon.x = iconLocalPos.x;
    icon.y = iconLocalPos.y + (icon.height / 2);
    icon.scale.setTo(this._gameScale);
    this.addChild(icon);
    this._promoIcon = icon;
  }

  /**
   * create tutorial text display with mascot.
   * @param {constructor} TextType
   */
  _initTutorialText(TextType) {
    const tutorialTextDisplay = new TextType({
      style: 'ui-tournament-promo', fontSize: 45,
    });

    this.addChild(tutorialTextDisplay);
    this._tutorialTextDisplay = tutorialTextDisplay;
  }

  /**
   * Returns the target position the xpromo should go to.
   * @returns {{x: number, y: number}}
   */
  _getTargetPromoPosition() {
    return { x: 0, y: 0 };
  }

  /**
   * start the animation for the promo icon / tutorial text.
   */
  _startPromoAnimation() {
    const timedEvent = game.time.events.add(1000, () => {
      const finalPromoIconScale = this._isLandscape ? 2 * this._gameScale : 2;
      game.add.tween(this._promoIcon).to(this._getTargetPromoPosition(), 1000, Phaser.Easing.Exponential.Out, true, 2000);
      game.add.tween(this._promoIcon.scale).to({ x: finalPromoIconScale, y: finalPromoIconScale }, 1000, Phaser.Easing.Exponential.Out, true, 2000)
        .onComplete.addOnce(() => { // set button callback once animation complete only
          if (this.parent) this._promoIcon.setPromoState(() => { this._showWindow(); });
        });
      this._startTutorialText();
    });
    this._registerTimedEvent(timedEvent);
  }

  /**
   * start the tutorial text / mascot talking animation.
   */
  _startTutorialText() {
    const textLines = [OMT.language.getText(this._tutorialTextKey)];
    this._tutorialTextDisplay.displayTextLines(textLines);
  }

  /**
   * show the tournament window after clicking on the icon
   */
  async _showWindow() {
    // overload
  }

  /**
   * resize method
   */
  _onResize() {
    // re-position / resize header
    this._headerGroup.y = this._isLandscape
      ? (-game.height / 2) + (this._headerGroup.height / this._gameScale) - 10
      : (-game.height / 2) + this._headerGroup.height;
    this._headerGroup.resize();
    // re-position / resize tutorial text
    this._tutorialTextDisplay.resize();
    this._tutorialTextDisplay.y = this._isLandscape
      ? game.height / 2 + 40
      : game.height / 2;
  }

  /**
   * @returns {boolean} is the window fullscreen? (for low res landscape purposes)
   */
  get isFullscreen() {
    return true;
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._promoIcon.destroy();
    G.sb('onScreenResize').remove(this._onResize, this);
  }
}
