/* eslint-disable implicit-arrow-linebreak */
/**
 * The beginnings of an async stack manager for timed operations!
 * The idea is that you can create a "stack" where you can add operations to run sequentially
 * After you add all the different operations, just run `stack.run()` to run the stack
 */
export default class OMT_StackManager {
  /**
   * Creates and returns a new instance of the stack manager
   * @param {() => void)} callback An optional callback to call after the stack resolves
   */
  constructor(callback = () => Promise.resolve()) {
    /**
     * @type {(() => Promise<any>)[]}
     */
    this.tasks = [];
    /**
     * @type {() => void}
     */
    this.callback = callback;
    /**
     * @type {OMT_StackManager[] | false}
     */
    this.currentParallelStack = false;
  }

  /**
   * Add a normal function call to the stack
   * @param {() => any} func - A function that runs any sort of operation within itself. Can also be async, which is awaited within the event
   * @returns {OMT_StackManager}
   */
  addEvent(func) {
    const pFunc = async () => {
      await func();
    };
    return this.addPromise(pFunc);
  }

  /**
   * Add a function that returns a promise to the stack
   * @param {() => Promise<any>} pFunc - The function that returns a promise
   * @returns {OMT_StackManager}
   */
  addPromise(pFunc) {
    this.tasks.push(pFunc);
    return this;
  }

  /**
   * If you want to run operations in parallel, then you can use this function
   * @param {OMT_StackManager[]} stacks - An array of StackManager objects which own their own sequence
   * @returns {OMT_StackManager}
   */
  addParallel(stacks) {
    const pFunc = async () => {
      this.currentParallelStack = stacks;
      return Promise.all(stacks.map((stack) => stack.run())).then(() => {
        this.currentParallelStack = false;
      });
    };
    return this.addPromise(pFunc);
  }

  /**
   * Add a console output to the stack. The value function is run and its result is logged into the console
   * @param {() => Promise<any>} value
   * @returns {OMT_StackManager}
   */
  addConsole(value) {
    this.addEvent(async () => {
      console.log(await value());
    });
    return this;
  }

  /**
   * Adds a step that waits for the given amount
   * @param {number} [ms] - Optional, the number in milliseconds to wait
   * @returns {OMT_StackManager}
   */
  wait(ms = 1000) {
    this.addPromise(() => {
      if (ms === 0) return Promise.resolve();
      return new Promise((resolve) => {
        game.time.events.add(ms, resolve);
      });
    });
    return this;
  }

  /**
   * Adds a step that waits until the resolve function is called or the given amount of time has passed
   * @param {number} [ms] - Optional, the number in milliseconds to wait, waits indefinitely if undefined
   * @param {() => void} [timeoutFunc] - Optional, the timeout function that will be called when the timer runs out
   * @returns {() => void}
   */
  waitUntil(ms, timeoutFunc) {
    const futurePromise = this.createFuturePromise();
    const { promise, resolve } = futurePromise;
    let resolved = false;

    this.addPromise(() => {
      if (ms !== undefined) {
        game.time.events.add(ms, () => {
          if (resolved) return;

          if (timeoutFunc) {
            timeoutFunc();
          }
          resolve();
        });
      }
      return promise;
    });

    return () => {
      resolved = true;
      resolve.bind(promise)();
    };
  }

  /**
   * Creates a future promise
   * @returns {{resolve: (value: any) => void; promise: Promise<any>}}
   */
  createFuturePromise() {
    const result = {};

    result.promise = new Promise((resolve, reject) => {
      result.resolve = resolve;
      result.reject = reject;
    });

    return result;
  }

  /**
   * Changes whether the stack will repeat or not
   * You can use this to stop the repeat of an already running stack
   * @param {number} [repeatCount] - Optional
   * @returns {OMT_StackManager}
   */
  repeat(repeatCount = -1) {
    this.startingRepeatCounter = repeatCount;
    this.repeatCounter = repeatCount;
    this.repeating = !!repeatCount;
    return this;
  }

  /**
   * Changes a stack to be reusable, meaning that its tasks won't get cleared upon completion and won't be recycled
   * Don't forget to change it back to be unusuble after you are done with it to enable recycling
   * @param {boolean} state
   * @returns {OMT_StackManager}
   */
  setReusable(state) {
    this.reusable = state;
    return this;
  }

  /**
   * Set the callback of this stack
   * @param {() => void} callback
   * @returns {OMT_StackManager}
   */
  setCallback(callback = () => Promise.resolve()) {
    this.callback = callback;
    return this;
  }

  /**
   * Run the stack. It returns a promise so it itself can be chained
   * @returns {Promise<any>}
   */
  run() {
    this.running = true;

    const taskPromise = this.tasks.reduce(
      (prev, next) => prev.then(() => {
        if (this.cancelled) return Promise.resolve();
        return next();
      }),
      Promise.resolve(),
    );

    return taskPromise.then(() => this.afterRun());
  }

  /**
   * This code runs after the stack completes its tasks
   * @returns {Promise<any>}
   */
  afterRun() {
    if (this.repeating) {
      if (this.repeatCounter > 0) {
        this.repeatCounter--;
        return this.run();
      }
      if (this.startingRepeatCounter === -1) {
        return this.run();
      }
      this.repeating = false;
    }
    this.running = false;
    const { callback } = this;
    this.clear();
    callback();
    return Promise.resolve();
  }

  /**
   * Is this stack currently running?
   * @returns {boolean}
   */
  isRunning() {
    return this.running;
  }

  /**
   * Stop the execution of this stack
   * Note that this functionality may span over multiple frames
   * @returns {OMT_StackManager}
   */
  stop() {
    if (!this.isRunning()) {
      this.running = false;
      const { callback } = this;
      this.clear();
      callback();
      return this;
    }
    this.cancelled = true;
    this.repeat(0);
    if (this.currentParallelStack) {
      for (const stack of this.currentParallelStack) {
        stack.stop();
      }
    }
    return this;
  }

  /**
   * Clear the stack
   * @returns {OMT_StackManager}
   */
  clear() {
    if (this.isRunning()) {
      this.stop();
      return this;
    }
    this.cancelled = false;
    this.repeat(0);
    if (this.reusable) return this;
    this.tasks = [];
    this.setCallback();
    OMT_StackManager.recycleStack(this);
    return this;
  }

  /**
   * Get a free stack that's done running
   * @param {Function} callback The callback for the end of the stack
   * @returns {OMT_StackManager}
   */
  static getFreeStack(callback) {
    if (!this.deadStacks) {
      this.deadStacks = [];
    }
    if (this.deadStacks.length > 0) {
      return this.deadStacks.pop().setCallback(callback);
    }
    return new OMT_StackManager(callback);
  }

  /**
   * Recycle a stack by adding it to the deadstacks
   * @param {OMT_StackManager} stack
   */
  static recycleStack(stack) {
    if (!this.deadStacks.includes(stack)) {
      this.deadStacks.push(stack);
    }
    return this;
  }
}
