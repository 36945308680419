import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../../OMT_UI/OMT_UI_SquareButton';

/**
 * button for buying moves on out of moves screen
 */
export default class OOMBuyMovesButton extends OMT_UI_SquareButton {
  constructor(x, y, price, additionalBoosterData, boosterType) {
    super(x, y, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: additionalBoosterData ? 445 : 309,
          height: 100,
        },
      },
      options: {
        cacheButton: false,
      },
    });
    this.boosterType = boosterType;
    this.price = price;
    this.createLabelForButton(additionalBoosterData);

    this.prevRefreshState = true;
    this.refreshState(true);
  }

  onClickDown() {
    // allow clicks always for IAP and only if the user can afford for NO IAPs
    if (G.IAP || G.saveState.getCoins() >= this.price) {
      super.onClickDown();
    }
  }

  refreshState(force = false) {
    if (this.game === null || G.IAP) return; // we continue only for non IAP users
    const canAffordItem = G.saveState.getCoins() >= this.price;
    if (force || canAffordItem !== this.prevRefreshState) {
      this.prevRefreshState = canAffordItem;
      if (canAffordItem) {
        this.setColour(BUTTONCOLOURS.green);
        if (this.iconSprite) this.iconSprite.alpha = 1;
      } else {
        this.setColour(BUTTONCOLOURS.gray);
        if (this.iconSprite) this.iconSprite.alpha = 0.5;
      }
    }
  }

  createLabelForButton(additionalBoosterData) {
    // booster for out of moves will always be + 5 now
    // const extraMovesBoosterType = !G.IAP ? 'booster#5' : 'booster#6';
    const extraMovesBoosterType = 'booster!mapExtraMoves';
    const movesSprite = G.gift.getIconForType(extraMovesBoosterType);
    this._priceLabel = new G.LabelTextT(
      `${OMT.language.toLocaleNumber(this.price)}@coin_1@`,
      0, -6, {
        style: 'font-white',
        fontSize: 50,
      }, 0.5, 160,
    );
    this.addChild(this._priceLabel);

    if (additionalBoosterData) {
      const boosterSprite = G.gift.getIcon(additionalBoosterData);
      const icons = new G.LabelTextT(
        `@${movesSprite}@+@${boosterSprite}@`,
        -100, 0, {
          style: 'font-white',
          fontSize: 70,
        }, 0.5, 180,
      );
      this._priceLabel.x = 110;
      this.addChild(icons);
      this.iconSprite = icons;
    } else {
      const movesIcon = G.makeImage(-100, 0, movesSprite, 0.5, this);
      movesIcon.scale.setTo(0.9);
      this._priceLabel.x = 40;
      this.addChild(movesIcon);
      this.iconSprite = movesIcon;
    }
  }

  updatePrice(newPrice) {
    this.price = newPrice;
    this._priceLabel.updateText(`${OMT.language.toLocaleNumber(this.price)}@coin_1@`);
  }

  update() {
    this.refreshState();
  }
}
