// config:
// value
// target
// valueTarget
// progressBar {x, y, bgSprite, barSprite, label}


G.PFEventPanel = function(config) {
  this.config = config;

  Phaser.Group.call(this, game);

  this.onClick = new Phaser.Signal();

  this.valueObj = {
    value: config.value,
    target: config.target,
    valueTarget: config.valueTarget === undefined ? config.value : config.valueTarget,
  }; 

  this.bg = G.makeImage(0, 0, "score_popup", 0.5, this);
  this.bg.inputEnabled = true;
  this.bg.events.onInputDown.add(this.onClick.dispatch, this.onClick);

  this.onValueChanged = new Phaser.Signal();
  this.onShowProgressStart = new Phaser.Signal();
  this.onShowProgressFinish = new Phaser.Signal();

   if (this.config.progressBar) {
    this.addProgressBar(this.config.progressBar);
  }

  this.onShowProgressFinish.add(function() {
    this.showingProgress = false;
  }, this);
  this.showingProgress = false;
};

G.PFEventPanel.prototype = Object.create(Phaser.Group.prototype);

G.PFEventPanel.prototype.showProgress = function() {
  // imports
  var animations = G.Animations;
  var utils = G.Utils;

  // if (this.showingProgress) return;

  // this.showingProgress = true;
  if (this.valueObj.value === this.valueObj.valueTarget) {
    this.onShowProgressFinish.dispatch();
  } else {
    var valueFrom = this.valueObj.value;
    var valueTo = this.valueObj.valueTarget;

    var animation = animations.getProgressionFlowPanelProgressionAnimation();

    this.onShowProgressStart.dispatch(valueFrom, valueTo);

    animation.onComplete.add(
      this.onShowProgressFinish.dispatch, this.onShowProgressFinish);
    animation.onUpdate.add(function(animationProgress) {
      this.onValueChanged.dispatch(
        utils.lerp(valueFrom, valueTo, animationProgress));
    }, this);

    animation.animate(this.game, this);
  };
};

G.PFEventPanel.prototype.addProgressBar = function(config) {

  config.value = this.valueObj.value;
  config.valueMax = this.valueObj.target;

  this.progressBar = new G.PFProgressBar(config, this.valueObj, this);

  this.onShowProgressStart.add(function() {
    this.progressBar.startProgress();
  }, this);

  this.onValueChanged.add(function(value) {
    this.progressBar.setProgress(value/this.valueObj.target);
  }, this);

  this.onShowProgressFinish.add(function() {
    this.progressBar.finishProgress();
  }, this);
  this.add(this.progressBar);
};

G.PFEventPanel.prototype.changeValue = function(val, noAnim) {

};


