import OMT_UI_ScrollBarConfigBase from '@omt-components/UI/Scroll/ScrollBarConfig/UI_ScrollBarConfigBase';

const DEFAULT_WIDTH = 15;
const DEFAULT_MIN_MIDDLE_WIDTH = 0;
const DEFAULT_HEIGHT_RATIO = 1;
const DEFAULT_OFFSET_Y = 0;

/**
 * class for configuring OMT_UI_VerticalScrollBar
 */
export default class OMT_UI_VerticalScrollBarConfig extends OMT_UI_ScrollBarConfigBase {
  /**
   * constructor
   * @param {number} width total width of scrollbar. result will be scaled to fit.
   * @param {number} cornerSize 9-scale corner size
   * @param {number} minMiddleWidth minimum width for middle for 9-scale. width will still be enforced by scale.
   * @param {number} heightRatio percentage should be 0-1
   * @param {number} offsetY value to offset the scrollbars y position, default 0
   * @param {number} tint tint to apply to the knob
   * @param {string} knobAssetFrame frame for knob asset
   * @param {boolean} overlapScrollArea set to false if you want to overlap the scrollbar in the content area
   * @param {string} scrollBackground some background used
   * @param {boolean} autoHide set if the scrollbar should auto hide content height is less then the view height
   */
  constructor(width, cornerSize, minMiddleWidth, heightRatio, offsetY, tint, depreciated_knobAssetSheet, knobAssetFrame, overlapScrollArea, scrollBackground, autoHide) {
    super(cornerSize, tint, depreciated_knobAssetSheet, knobAssetFrame, overlapScrollArea, scrollBackground, autoHide);
    this._width = width || DEFAULT_WIDTH;
    this._minMiddleWidth = minMiddleWidth || DEFAULT_MIN_MIDDLE_WIDTH;
    this._heightRatio = heightRatio || DEFAULT_HEIGHT_RATIO;
    this._offsetY = offsetY || DEFAULT_OFFSET_Y;
  }

  /**
   * set scroll bar width
   * @param {number} value
   */
  set width(value) {
    this._width = value;
  }

  /**
   * get scroll bar width
   * @returns {number}
   */
  get width() {
    return this._width;
  }

  /**
   * set minimum middle width
   * @param {number} value
   */
  set minMiddleWidth(value) {
    this._minMiddleWidth = value;
  }

  /**
   * get minimum middle width
   * @returns {number}
   */
  get minMiddleWidth() {
    return this._minMiddleWidth;
  }

  /**
   * set the % height of the scrollbar in relation to the scrollArea height
   * @param {number} value
   */
  set heightRatio(value) {
    this._heightRatio = value;
  }

  /**
   * get the % height of the scrollbar in relation to the scrollArea height
   * @returns {number} should be 0-1
   */
  get heightRatio() {
    return this._heightRatio;
  }

  /**
   * set the y offset value for the scrollbar
   * @param {number} value
   */
  set offsetY(value) {
    this._offsetY = value;
  }

  /**
   * get the y offset value for the scrollbar
   * @returns {number} can be negative or positive
   */
  get offsetY() {
    return this._offsetY;
  }
}
