import { GameScaleController } from '../../../../States/Scaling/GameScaleController';

export default class Overlay2 extends Phaser.Group {
  /**
   * Overlay class that shows up... on some occasions
   * Seems to do far more simplier things than Overlay1
   *
   * Not to be confused with Overlay1
   * @param {boolean} optFixedToCamera Fixed to camera or not
   * @param {number} optTargetAlpha The target alpha of the overlay
   */
  constructor(optFixedToCamera, optTargetAlpha) {
    super(game);
    this._init(optFixedToCamera, optTargetAlpha);
  }

  destroy() {
    this.pointerUpSignal.dispose();
    if (this._signalBinding) {
      this._signalBinding.detach();
      this._signalBinding = null;
    }
    super.destroy();
  }

  /**
   * Init function.
   * @param {boolean} optFixedToCamera
   * @param {number} optTargetAlpha
   */
  _init(optFixedToCamera, optTargetAlpha) {
    if (optFixedToCamera !== undefined) {
      this.fixedToCamera = optFixedToCamera;
    }

    this._targetAlpha = G.Utils.defined(optTargetAlpha, 0.5);

    this.pointerUpSignal = new Phaser.Signal();

    this.overlay = this.add(game.make.graphics());
    this.overlay.clear();
    this.overlay.beginFill(0x000000, 1);
    this.overlay.drawRect(0, 0, 16, 16);

    this._signalBinding = undefined;
    const createBlockInputPointerUpHandler = (listener) => {
      const handler = G.Input.createCustomBlockInputHandler();
      this._signalBinding = handler.signal.add((eventType) => {
        if (eventType === G.Input.EventType.InputUp) {
          listener();
        }
      });
      return handler;
    };
    G.Input.initializeCustomInput(this.overlay);
    // eslint-disable-next-line no-unused-vars
    this.overlay.customInput.addHandler(createBlockInputPointerUpHandler((eventType) => { this.pointerUpSignal.dispatch(); }));

    G.sb('onScreenResize').add(this._redrawOverlay, this);
    this._redrawOverlay();

    this.overlay.alpha = 0;
    this.overlay.inputEnabled = false;
  }

  /**
   * Show overlay
   */
  showOverlay() {
    this.overlay.inputEnabled = true;
    this._animateFadeIn(this.overlay);
  }

  /**
   * Hides overlay
   */
  hideOverlay() {
    this.overlay.inputEnabled = false;
    this._animateFadeOut(this.overlay);
  }

  /**
   * Redraw
   */
  _redrawOverlay() {
    const { gameScale } = GameScaleController.getInstance();
    this.overlay.width = game.width / gameScale;
    this.overlay.height = game.height / gameScale;
  }

  /**
   * Tweens the object in (usually the overlay)
   * @param {DisplayObject} target
   */
  _animateFadeIn(target) {
    G.stopTweens(target);
    game.add.tween(target).to({ alpha: this._targetAlpha }, 400, Phaser.Easing.Sinusoidal.Out, true);
  }

  /**
   * Tweens the object out (usually the overlay)
   * @param {DisplayObject} target
   */
  _animateFadeOut(target) {
    G.stopTweens(target);
    game.add.tween(target).to({ alpha: 0 }, 400, Phaser.Easing.Sinusoidal.Out, true);
  }
}
