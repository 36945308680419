if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getProgressBarOverlayAlphaAnimation = function getProgressBarOverlayAlphaAnimation(alphaTarget) {

  var onStart = new Phaser.Signal();

  return {
    animate: animate,
  };

  function animate(game, target) {
    var overlay = G.Utils.find1stObjectWithName(target, "barOverlay");

    if (overlay) {
      game.tweens.removeFrom(overlay);
      game.add.tween(overlay)
        .to({alpha: alphaTarget}, 300, Phaser.Easing.Sinusoidal.Out, true);
    }
  }

};