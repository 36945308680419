import { BoardToken } from './BoardToken';
import { TOKEN_TYPES } from '../BoardConstants';

const TOKEN_TEXTURE = 'concrete_#';

/**
 * BoardToken for concrete
 */
export class BoardToken_Concrete extends BoardToken {
  /**
   * constructor
   * @param {TokenLayer_Concrete} layer
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} hp
   */
  constructor(layer, cellX, cellY, hp) {
    super(
      layer,
      {
        tokenType: TOKEN_TYPES.CONCRETE,
      },
      cellX,
      cellY,
      hp,
    );

    // set texture based on hp
    G.changeTexture(this, TOKEN_TEXTURE.replace('#', this._hp));
  }

  /**
   * on match action on the cell.
   */
  onMatch() {
    const { gameHooks } = this._board;
    G.sb('fx').dispatch('burstConcrete', this, this._hp);
    gameHooks.playSound('explosion_subtle');
    this._hp--;

    G.sb('fxTop').dispatch('burstConcreteAnim', this, this);

    gameHooks.playSound('brick_break');
    if (this._hp === 0) this.remove();
    else G.changeTexture(this, TOKEN_TEXTURE.replace('#', this._hp));
  }

  /**
   * on booster used on cell.
   * @returns {boolean}
   */
  onBooster() {
    this.onMatch();
    return false;
  }
}
