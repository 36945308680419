import { OMT_UI_AvatarRotator } from '../../../OMT_UI/FriendsList/OMT_UI_AvatarRotator';

const ROTATION_FADE_DURATION = 500;
const ROTATION_DELAY = 3000;
const DEFAULT_FRAMES = ['avatar_e'];
const FRIEND_STRING_TOKEN = '%FRIEND%';
const BG_COLOR = 0xFFFFFF;

/**
 * Class for rotating brag friend avatars and updating their info
 */
export class BragFriendAvatarRotator extends OMT_UI_AvatarRotator {
  /**
   * @param {Array.<Object>} friends
   * @param {number} imageSize
   * @param {Phaser.Text} bannerField
   * @param {string} bannerText
   */
  constructor(friends, imageSize, bannerField, bannerText) {
    super(friends, imageSize, ROTATION_DELAY, ROTATION_FADE_DURATION, DEFAULT_FRAMES);
    this._bannerField = bannerField;
    this._bannerField.alpha = 0;
    this._bannerText = bannerText;
    this._rotationIndex = 0;
  }

  /**
   * override and set new bg color
   */
  _initBackgroundImage() {
    super._initBackgroundImage(BG_COLOR);
  }

  /**
   * set the active friend load their Avatar image + text
   * @param {Objecty} friendData
   */
  _setFriend(friendData) {
    this._updateBannerField(friendData);
    super._setFriend(friendData);
  }

  /**
   * animate the avatar + banner in then set the out timer
   */
  _animateInThenOut() {
    super._animateInThenOut();
    const fieldTween = game.add.tween(this._bannerField);
    fieldTween.to({ alpha: 1 }, ROTATION_FADE_DURATION, Phaser.Easing.Quadratic.Out, true);
  }

  /**
   * animate out the avatar + banner
   */
  _animateOut() {
    super._animateOut();
    const fieldTween = game.add.tween(this._bannerField);
    fieldTween.to({ alpha: 0 }, ROTATION_FADE_DURATION, Phaser.Easing.Quadratic.In, true);
  }

  /**
   * update the banner field
   * @param {Object} friendData
   */
  _updateBannerField(friendData) {
    const scale = this._bannerField.scale.x;
    this._bannerField.setText(OMT.language.getText(this._bannerText).replace(FRIEND_STRING_TOKEN, friendData.name));
    this._bannerField.scale.set(scale);
  }
}
