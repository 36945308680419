import TimeUtil from '@omt-components/Utils/TimeUtil';
import { ICON_STATES } from './WorldMapButtonIconStates';

/**
  * class for WorldMap weekly tournament icon / button
  */
export class UI_TournamentIcon extends Phaser.Group {
  /**
   * cosntructor
   */
  constructor() {
    super(game);

    this._iconState = null;

    // create content containers
    this._contentContainer = new Phaser.Group(game);
    this.add(this._contentContainer);
    this._buttonContainer = new Phaser.Group(game);
    this._contentContainer.add(this._buttonContainer);

    this._initBackgroundGlow();
    this._initButton();
    this._initActiveLabelText();
    this._initInactiveLabelText();
    this._initTimeCodeText();

    this.setActiveState();

    this._skipUpdate = false;
    this._signalTokens = [];
    this._signalTokens.push(G.sb('pauseAllUpdate').add(() => { this._skipUpdate = true; }));
    this._signalTokens.push(G.sb('resumeAllUpdate').add(() => { this._skipUpdate = false; }));

    this.update();
  }

  /**
   * init the background rotating glow
   */
  _initBackgroundGlow() {
    this._godRays = G.makeImage(0, 0, 'popup_lighht', 0.5);
    this._contentContainer.addChildAt(this._godRays, 0);
    this._godRays.alpha = 0.5;
    this._godRays.scale.setTo(0.7);
    this._godRays.blendMode = 1;
  }

  /**
   * init the button and its loop animation
   */
  _initButton() {
    this._button = new G.Button(0, 20, 'tournament_icon', this._onIconClicked, this);
    this._button.scaleOnClick = false;
    this._buttonContainer.add(this._button);
  }

  /**
   * called when the button / icon is clicked
   */
  async _onIconClicked() {
    if (this._iconState === ICON_STATES.ACTIVE) {
      game.input.enabled = false;
      await this.showTournamentWindow();
      game.input.enabled = true;
    } else if (this._iconState === ICON_STATES.PROMO) {
      if (this._promoClickedCallback) this._promoClickedCallback();
      this._promoClickedCallback = null;
    }
  }

  /**
   * show the tournament window
   */
  async showTournamentWindow() {
    OMT.platformTournaments.setLevelOverride(null);
    await OMT.platformTournaments.switchToTournamentContext();
    const score = await OMT.platformTournaments.getTournamentHighScore();
    G.sb('pushWindow').dispatch(['tournament', score, false, false, 'continue']);
  }

  /**
   * active start label text
   */
  _initActiveLabelText() {
    this._activeLabelText = new G.Text(0, 36, OMT.language.getText('Tournament'), 'ui-tournament', 0.5, 140, 150, false, 'center');
    this._activeLabelText.angle = -4;
    this._button.add(this._activeLabelText);

    // check if we have some offsets set for adjusting the label
    const iconSettings = G.OMTsettings.tournaments.worldMapIcon;
    if (iconSettings && iconSettings.activeLabelOffset) {
      this._activeLabelText.x += iconSettings.activeLabelOffset.x;
      this._activeLabelText.y += iconSettings.activeLabelOffset.y;
    }
  }

  /**
   * inactive start label text
   */
  _initInactiveLabelText() {
    this._inactiveLabelText = new G.Text(0, 10, OMT.language.getText('New level in:'), 'ui-tournament', 0.5, 140, 150, false, 'center');
    this._button.add(this._inactiveLabelText);
  }

  /**
   * create the text field for displaying the cooldown timecode
   */
  _initTimeCodeText() {
    this._timeText = new G.Text(0, 50, '00:00:00', 'ui-tournament-timecode', 0.5, 140, 150, false, 'center');
    this.add(this._timeText);
  }

  /**
   * set the active state
   */
  setActiveState() {
    this._iconState = ICON_STATES.ACTIVE;
    this._button.enable();
    this._button.alpha = 1;
    this._godRays.visible = true;
    this._activeLabelText.visible = true;
    this._inactiveLabelText.visible = false;
    this._timeText.visible = false;
    G.changeTexture(this._button, 'tournament_icon');
  }

  /**
   * update the active state
   */
  _updateActiveState() {
    this._godRays.angle += G.deltaTime;
  }

  /**
   * set the cooldown state
   */
  setCooldownState() {
    this._iconState = ICON_STATES.COOLDOWN;
    this._button.disable();
    this._godRays.visible = false;
    this._activeLabelText.visible = false;
    this._inactiveLabelText.visible = true;
    this._timeText.visible = true;
    G.changeTexture(this._button, 'tournament_cooldown_icon');
  }

  /**
   * update the cooldown state
   * @param {number} cooldownTimeRemaining
   */
  _updateCooldownState(cooldownTimeRemaining) {
    this._timeText.setText(TimeUtil.getTimeCode(cooldownTimeRemaining));
  }

  /**
  * set icon promotion state
  * @param {Function} promoClickedCallback (optional)
  */
  setPromoState(promoClickedCallback = null) {
    this._iconState = ICON_STATES.PROMO;
    this._button.alpha = 1;
    this._godRays.visible = false;
    this._activeLabelText.visible = true;
    this._inactiveLabelText.visible = false;
    this._timeText.visible = false;

    if (promoClickedCallback) this._button.enable();
    else this._button.disable();
    this._promoClickedCallback = promoClickedCallback;

    G.changeTexture(this._button, 'tournament_icon');
  }

  /**
   * main update method
   */
  update() {
    super.update();
    if (this._skipUpdate) return;

    const cooldownTimeRemaining = OMT.platformTournaments.getCooldownTimeRemaining();

    // check if we need to change sates
    if (this._iconState === ICON_STATES.ACTIVE && cooldownTimeRemaining > 0) {
      this.setCooldownState();
    } else if (this._iconState === ICON_STATES.COOLDOWN && cooldownTimeRemaining <= 0) {
      this.setActiveState();
    }

    // update the active state
    if (this._iconState === ICON_STATES.COOLDOWN) this._updateCooldownState(cooldownTimeRemaining);
    else if (this._iconState === ICON_STATES.ACTIVE) this._updateActiveState();
  }

  /**
   * destruction method
   */
  destroy() {
    this._promoCallback = null;
    for (const signal of this._signalTokens) if (signal) signal.detach();
    this._signalTokens.length = 0;
    super.destroy();
  }
}
