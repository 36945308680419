import UI_BoosterButton from './UI_BoosterButton';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import OMT_VILLAINS from '../../../../OMT_UI/OMT_Villains';
import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';

/* eslint-disable new-cap */
export default class UI_BoosterPanel extends Phaser.Group {
  /**
   * The booster panel on the bottom of the game screen
   * @param {Board} board
   */
  constructor(board) {
    super(game);

    this._board = board;
    this.y = game.height;

    this._drawEverything();

    // Signals
    this._signalBindings = [];
    this._signalBindings.push(G.sb('onWindowOpened').add(this.lockInput, this));
    this._signalBindings.push(G.sb('onAllWindowsClosed').add(this.unlockInput, this));
    this._signalBindings.push(G.sb('onStateChange').add(this.lockInput, this));
    this._signalBindings.push(G.sb('actionQueueEmpty').add(() => {
      if (LvlDataManager.getInstance().goalAchieved) return;
      this._checkSuggestions();
    }));
    this._signalBindings.push(G.sb('onGoalAchieved').add(() => {
      this.boostersBtn.forEach((e) => { e.suggested = false; });
      this.lockInput();
    }));
    this._signalBindings.push(G.sb('disableGameBottomBar').add(() => {
      this.boostersBtn.forEach((e) => { e.suggested = false; });
      this.lockInput();
    }));

    this._signalBindings.push(G.sb('onScreenResize').add(this._onScreenResize, this));
    this._onScreenResize();
  }

  /**
   * Draws everything
   * @param {LevelType} gameMode
   */
  _drawEverything() {
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const {
      isTokenEventLevel,
      isTournamentLevel,
      isDailyChallengeLevel,
      isTreasureHuntLevel,
    } = LvlDataManager.getInstance();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;
    const assetsFolder = window.assetsFolder ? window.assetsFolder : 'assets';

    let _bgAssetName = `${assetsFolder}/board/gameplay_bottom_bar_normal`;
    if (isTournamentLevel) {
      _bgAssetName = `${assetsFolder}/board/gameplay_bottom_bar_tournament`;
    } else if (isTokenEventLevel) {
      _bgAssetName = `${assetsFolder}/tokenEvent/tokenEvent_gameplay_bottom_bar`;
    } else if (isNotNormalLevel) {
      _bgAssetName = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_gameplay_bottom_bar') : OMT_VILLAINS.getPrefixedName('hard_gameplay_bottom_bar');
    } else if (isTreasureHuntLevel) {
      _bgAssetName = `${assetsFolder}/board/gameplay_bottom_bar_treasureHunt`;
    }

    if (isNotNormalLevel) {
      this._bg = new UI_NineSlice(7, 0, _bgAssetName, 627, 31, {
        left: 37,
        right: 38,
        top: 0,
        bottom: 0,
      });
    } else {
      this._bg = new G.Utils.NineSlice.fromJSON(_bgAssetName, 7, 0, 627, 31);
    }
    this.add(this._bg);
    this._bg.anchor.setTo(0, 1);

    this._shadows = [
      G.makeImage(0, -20, 'bottom_ui_shadow', 0.5, this._bg),
      G.makeImage(0, -20, 'bottom_ui_shadow', 0.5, this._bg),
      G.makeImage(0, -20, 'bottom_ui_shadow', 0.5, this._bg),
      G.makeImage(0, -20, 'bottom_ui_shadow', 0.5, this._bg),
      G.makeImage(0, -20, 'bottom_ui_shadow', 0.5, this._bg),
    ];

    // Accessed externally
    this.boostersBtn = [
      this._makeBoosterBtn(290, -64, 1, isTournamentLevel),
      this._makeBoosterBtn(480, -64, 2, isTournamentLevel),
      this._makeBoosterBtn(860, -64, 3, isTournamentLevel),
      this._makeBoosterBtn(670, -64, 4, isTournamentLevel),
    ];
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._signalBindings) {
      this._signalBindings.forEach((binding) => {
        if (binding.detach) {
          binding.detach();
        }
      });
      this._signalBindings = null;
    }
    super.destroy();
  }

  /**
   * Hides away
   */
  animatePositionToHidden() {
    const durationMs = 333;
    const hideDistance = this.height;
    game.add.tween(this).to({ y: game.height + hideDistance }, durationMs, Phaser.Easing.Cubic.Out, true);
  }

  /**
   * Shows up
   */
  animatePositionToVisible() {
    const durationMs = 333;
    game.add.tween(this).to({ y: game.height }, durationMs, Phaser.Easing.Cubic.Out, true);
  }

  /**
   * Repositions accordingly to the center
   */
  _onScreenResize() {
    this.x = 0;
    this.y = game.height;

    this._shadows[0].x = 65;

    this.boostersBtn.forEach((e, i) => {
      e.y = e.orgY = -64;
      e.x = e.orgX = 180 + (125 * i);

      this._shadows[i + 1].x = e.x - 7;
    });

    if (!FBInstant.deviceDectorFunctions().isMobileOrTab()) {
      this.x = game.width / 6;
    }
  }

  /**
   * Lock it so it cannot be clicked
   */
  lockInput() {
    this.boostersBtn.forEach((child) => {
      if (child.lock) child.lock();
    });
  }

  /**
   * Unlock to be clicked
   */
  unlockInput() {
    this.boostersBtn.forEach((child) => {
      if (child.unlock) {
        child.unlock();
      }
    });
  }

  /**
   * Popuplates the bar with the booster
   * @param {number} x
   * @param {number} y
   * @param {number} nr
   * @param {boolean} forceUnlocked
   */
  _makeBoosterBtn(x, y, nr, forceUnlocked = false) {
    if (game.state.getCurrentState().isBrag2Level || G.saveState.isBoosterUnlocked(nr) || forceUnlocked) {
      const btn = new UI_BoosterButton(this._board, x, y, nr);
      return this.add(btn);
    }
    return G.makeImage(x, y, `ui_booster_${nr}_locked`, 0.5, this);
  }

  /**
   * Check if the booster has the hand image. If it does, show it!
   * Otherwise don't
   */
  _checkSuggestions() {
    this.boostersBtn.forEach((elem, index) => {
      if (!LvlDataManager.getInstance().goalAchieved && this[`checkBooster${(index + 1)}Suggestion`]()) {
        if (elem.showSuggestion) {
          elem.showSuggestion();
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (elem.hideSuggestion) elem.hideSuggestion();
      }
    });
  }

  /**
   * Called by the function above... But why?
   */
  checkBooster1Suggestion() {
    return false;
  }

  checkBooster2Suggestion() {
    return false;
  }

  checkBooster3Suggestion() {
    return false;
  }

  checkBooster4Suggestion() {
    return false;
  }
}
