import { OMT_UI_AvatarWithFrame } from '../../../../../../OMT_UI/FriendsList/OMT_UI_AvatarWithFrame';


/**
 * Custom Avatar for the leadboards
 */
export class TournamentCompetitorAvatarBase extends OMT_UI_AvatarWithFrame {
  /**
   * constructor
   * @param {string} imageURL
   * @param {number} imageSize (optional)
   */
  constructor(imageURL, imageSize) {
    super(imageURL, imageSize);
    this._setScale(imageSize);
  }

  /**
   * Sets the scale of the image
   * @param {number} imageSize
   */
  _setScale(imageSize) { // eslint-disable-line no-unused-vars
    // Override
  }

  /**
   * init the avatar frame
   * @param {{x:number, y:number, img:string, anchor:(number|Array<number>), size:number}} config
   */
  _initAvatarFrame(config) {
    this._avatarFrame = G.makeImage(config.x, config.y, config.img, config.anchor, null);
    this._avatarFrame.width = this._avatarFrame.height = config.size;
    this.addChild(this._avatarFrame);
  }

  /**
   * show dash image, used for when the user acheieves a top score
   */
  showDashLines() {
    // Override
  }
}
