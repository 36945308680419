import ShareTickButton from '../../ShareTickbutton';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';

/**
 * Static methods for creating UI elements used in Window_gift.js
 */

if (typeof G === 'undefined') G = {};
G.GiftUI = G.GiftUI || {};
G.GiftUI.Elements = {};

G.GiftUI.Elements.TitleBar = function (parent, reason) {
  const title = new Phaser.Group(game, parent);

  const isAchievement = (reason === G.GiftUI.Constants.GiftReasons.Achievement);

  title._titleTxtBg = G.makeImage(0, -300, 'tutorial_bar', 0.5, title);
  title._titleTxtBg.update = function () {
    if (this.width !== game.width) this.width = game.width;
  };
  title._titleTxtBg.height = 135;

  title.titleTxt = new G.Text(
    0, -320,
    `${isAchievement ? OMT.language.getText('Achievement gift') : OMT.language.getText('Gift')}!`,
    'gifts-achievement', 0.5, 400,
  );
  title.add(title.titleTxt);

  let txt = '';
  if (reason === G.GiftUI.Constants.GiftReasons.ThreeStars) {
    txt = OMT.language.getText(G.GiftUI.Constants.TitleBarText.ThreeStars);
  } else if (G.GiftUI.Constants.GiftReasons.Achievement) {
    txt = OMT.language.getText(G.GiftUI.Constants.TitleBarText.Achievement);
  }

  const explanationTxt = new G.Text(
    0, -265,
    txt,
    {
      style: 'font-blue',
      fontSize: '24px',
    }, 0.5, 450, 140, true, 'center',
  );
  title.add(explanationTxt);

  return title;
};

G.GiftUI.Elements.TitleTextBar = function (parent, config) {
  const textTitleGroup = new Phaser.Group(game, parent);

  textTitleGroup.position.setTo(
    G.Utils.defined(config.x, 0),
    G.Utils.defined(config.y, -240),
  );

  if (config.titleTxt) {
    const titleTxt = new G.Text(0, 0, config.titleTxt, {
      style: 'font-white',
      fontSize: '50px',
    }, 0.5, 450);
    textTitleGroup.add(titleTxt);
  }

  if (config.subtitleTxt) {
    const subtitleTxt = new G.Text(0, 60, config.subtitleTxt, {
      style: 'font-white',
      fontSize: '40px',
    }, 0.5, 450);
    textTitleGroup.add(subtitleTxt);
  }

  return textTitleGroup;
};

G.GiftUI.Elements.GiftImageFromType = function (type, giftConfig, parent, flip) {
  const group = new Phaser.Group(game, parent);
  group.giftType = type;

  switch (group.giftType) {
    case G.GiftUI.Constants.GiftTypes.Booster:
      var temp = G.GiftUI.Elements.BoosterImage(
        giftConfig[0],
        giftConfig[1],
        group,
        flip,
      );
      group.boosterImg = temp;
      group.add(temp);
      break;

    case G.GiftUI.Constants.GiftTypes.Heart:
      group.add(new G.HeartImage(0, 0, giftConfig[1]));
      break;

    case G.GiftUI.Constants.GiftTypes.InfiniteLives:
      group.add(new G.HeartImage(0, 0, giftConfig[1], true));
      break;

    case G.GiftUI.Constants.GiftTypes.Coins:
    default:
      group.add(
        new G.LabelTextT(
          G.gift.getLabelString(giftConfig),
          0,
          0,
          {
            style: 'font-white',
            fontSize: '60px',
          },
          0.5,
          150,
        ),
      );
      break;
  }

  return group;
};

G.GiftUI.Elements.Overlay = function () {
  const isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
  const { gameScale } = GameScaleController.getInstance();

  const fadeImg = game.add.graphics(0, 0);
  fadeImg.alpha = 1;
  fadeImg.visible = true;
  G.Input.initializeCustomInput(fadeImg);
  fadeImg.customInput.addHandler(G.Input.createCustomBlockInputHandler());

  fadeImg.clear();
  fadeImg.beginFill(0x000000, 0.6);

  if (isLandscape) {
    fadeImg.drawRect(
      -game.width * (0.5 / gameScale),
      -game.height * (0.5 / gameScale),
      game.width * (2 / gameScale),
      game.height * (2 / gameScale),
    );
  } else {
    fadeImg.drawRect(
      -game.width * 0.5,
      -game.height * 0.5,
      game.width * 2,
      game.height * 2,
    );
  }
  fadeImg.endFill();

  return fadeImg;
};

G.GiftUI.Elements.Light = function (x, y, scale, parent, spriteKey) {
  const light = G.makeImage(x, y, spriteKey || 'popup_lighht', 0.5, parent);
  light.scale.setTo(scale);
  light.shouldRotate = true;
  light.update = function () {
    if (this.shouldRotate) {
      this.angle += 0.5;
    }
  };
  light.alpha = 0.1;
  light.blendMode = 1;
  return light;
};

G.GiftUI.Elements.GiftBox = function (x, y, giftConfig, giftBoxType, config) {
  const group = game.make.group();
  group.position.setTo(x, y);
  group.isChest = false;

  // if is array of gifts then must be a single gift
  const singleGift = Array.isArray(giftConfig[0]);

  const giftType = giftBoxType;
  const giftBoxImages = G.GiftUI.HelperFunctions.getGiftboxImage(giftType);
  let offsets;
  switch (giftType) {
    case G.GiftUI.Constants.GiftBoxTypes.Default:
    default:
      offsets = G.GiftUI.Constants.GiftBoxTypeOffsets.Default;
      break;
    case G.GiftUI.Constants.GiftBoxTypes.Retro:
      offsets = G.GiftUI.Constants.GiftBoxTypeOffsets.Retro;
      break;
  }

  group.light = G.GiftUI.Elements.Light(0, -20, 1.5, group);

  group.gift = null;
  if (!G.OMTsettings.elements.gifts.twoFrameOpen) {
    group.gift = G.makeImage(0, 0, giftBoxImages.opened, 0.5, group);
  } else {
    group.gift = G.makeImage(0, 0, giftBoxImages.closed, G.OMTsettings.elements.gifts.twoFrameOpen ? [0.5, 0.75] : 0.5, group)
  }
  group.giftLid = null;
  if (!G.OMTsettings.elements.gifts.twoFrameOpen) {
    group.giftLid = G.makeImage(
      0 + offsets.lid.x,
      offsets.lid.y,
      giftBoxImages.lid,
      0.5,
      group.gift,
    );
  }

  if (G.OMTsettings.elements.gifts.shineEnabled) {
    const omtGiftSettings = G.OMTsettings.elements.gifts;
    const giftGlow = G.makeImage(omtGiftSettings.shineOffsetX, omtGiftSettings.shineOffsetY, 'gift_open_glow', 0.5, group.gift);
    group.giftGlow = giftGlow;
    giftGlow.alpha = 0;
  }
  group.gift.scale.set(1.3);

  if (singleGift) {
    group.inside = G.GiftUI.Elements.MultiGiftInside(giftConfig, group);
  } else {
    group.inside = G.GiftUI.Elements.GiftImageFromType(
      G.GiftUI.HelperFunctions.getGiftTypeFromString(giftConfig[0]),
      giftConfig,
      group,
    );
  }

  group.inside.alpha = 0;
  group.inside.visible = false;

  let winTxt;
  if (config && config.customExtraText) {
    winTxt = config.customExtraText;
  } else {
    winTxt = G.GiftUI.HelperFunctions.getGiftExtraText(giftConfig);
  }
  group.insideExtraTxt = new G.Text(
    0,
    -200,
    winTxt,
    {
      style: 'font-white',
      fontSize: '40px',
    },
    0.5,
    500,
  );
  group.add(group.insideExtraTxt);
  group.insideExtraTxt.visible = false;

  group.gift.stopShake = false;
  group.onOpen = new Phaser.Signal();
  group.gift.inputEnabled = true;
  group.gift.input.useHandCursor = true;
  group.gift.events.onInputDown.add(function () {
    group.onOpen.dispatch();
  });

  group.gift.boxJump = G.Animations.getBoxJumpAnimation();
  group.gift.boxJump.animate(group.gift);

  return group;
};

G.GiftUI.Elements.MultiGiftInside = function (giftConfig, parent) {
  const group = new Phaser.Group(game, parent);

  group.chestGifts = [];

  for (let i = 0; i < (giftConfig.length > 3 ? 3 : giftConfig.length); i++) {
    const currConfig = giftConfig[i];

    group.chestGifts.push(
      G.GiftUI.Elements.GiftImageFromType(
        G.GiftUI.HelperFunctions.getGiftTypeFromString(currConfig[0]),
        currConfig,
        group,
      ),
    );

    if (giftConfig.length === 3) {
      group.chestGifts[i].x = -135 + 135 * i;
    } else if (giftConfig.length === 2) {
      group.chestGifts[i].x = 67.5 * (i === 0 ? -1 : 1);
    }
    group.add(group.chestGifts[i]);
  }

  return group;
};

G.GiftUI.Elements.ShareTickbox = function (x, y, type) {
  // const shareUI = new ShareTickButton(
  //   x,
  //   y,
  //   'gifts-shareTickbox',
  //   true,
  //   type,
  // );
  // return shareUI;
};

G.GiftUI.Elements.GingyTextGroup = function (parent, config) {
  const gingyTxtGroup = new Phaser.Group(game, parent);
  gingyTxtGroup.y += config.gingyTitleOffset.y; // X is applied elsewhere, with anim
  const sideGingy_subtxt = new G.Text(
    0, 0, OMT.language.getText('Choose your gift!'),
    {
      style: 'font-white',
      fontSize: '32px',
    },
    0.5, 450, 140, true, 'left',
  );
  gingyTxtGroup.add(sideGingy_subtxt);
  if (config.gifts.length <= 1) {
    sideGingy_subtxt.visible = false;
  }

  if (config.showGingyGiftBar) {
    const sideGingy_txtBg = G.makeImage(
      0,
      0,
      'purple_banner',
      0.5,
      gingyTxtGroup,
    );
    sideGingy_txtBg.scale.setTo(G.OMTsettings.elements.giftUI.sideGingy.txtBg.scale.x, G.OMTsettings.elements.giftUI.sideGingy.txtBg.scale.y);

    const sideGingy_txt = new G.Text(
      0, 0, OMT.language.getText('Gingy Gift'),
      {
        lineSpacing: G.OMTsettings.elements.giftUI.sideGingy.txt.lineSpacing,
        style: 'font-white',
        fontSize: G.OMTsettings.elements.giftUI.sideGingy.txt.fontSize,
      },
      0.5,
      sideGingy_txtBg.width * sideGingy_txtBg.scale.x,
      140, true, 'center',
    );
    gingyTxtGroup.add(sideGingy_txt);

    gingyTxtGroup.sideGingy_txt = sideGingy_txt;
    gingyTxtGroup.sideGingy_subtxt = sideGingy_subtxt;
    gingyTxtGroup.sideGingy_txtBg = sideGingy_txtBg;
  }
  return gingyTxtGroup;
};

G.GiftUI.Elements.Gingy = function (parent, config) {
  const gingyGroup = new Phaser.Group(game, parent);

  gingyGroup.gingyTxtGroup = G.GiftUI.Elements.GingyTextGroup(gingyGroup, config);

  gingyGroup._sideGingy = G.makeImage(
    0,
    -80,
    'gingerbread_man_large',
    0.5,
    gingyGroup,
  );
  gingyGroup._sideGingy.hugLeft = false;
  gingyGroup._sideGingy.angle = config.gingyAngle || 45;
  const gingyScale = config.gingyScale || G.OMTsettings.elements.giftUI.sideGingy.gingyScale.vertical;
  gingyGroup._sideGingy.scale.setTo(gingyScale);

  gingyGroup.hideGingy = G.Animations.getHideGingyAnimation();
  gingyGroup.showGingy = G.Animations.getShowGingyAnimation();

  return gingyGroup;
};

G.GiftUI.Elements.NoThanksButton = function (x, y, onClick) {
  const btn = new OMT_UI_SquareButton(x, y, {
    button: {
      tint: BUTTONCOLOURS.red,
      dimensions: {
        width: 166,
        height: 86,
      },
    },
    text: {
      string: OMT.language.getText('No, thanks'),
      textStyle: { style: 'font-white', fontSize: 40 },
    },
    options: {
      clickFunction: {
        onClick,
      },
    },
  });
  btn.alpha = 0;
  btn.visible = false;
  return btn;
};

G.GiftUI.Elements.BoosterImage = function (boosterId, count, parent, flip) {
  const imgId = G.gift.getIconForType(boosterId);

  const group = new Phaser.Group(game, parent);

  group.setCount = function (numOfBoosters) {
    this.countTxt.setText(numOfBoosters.toString());
  };

  group.img = G.makeImage(0, 0, imgId, 0.5, group);

  let xPos = group.img.width * 0.3;
  const yPos = group.img.height * 0.3;
  if (flip) {
    xPos *= -1;
  }

  group.countBg = G.makeImage(xPos, yPos, 'checkbox', 0.5, group);
  group.countTxt = new G.Text(
    xPos, yPos + 3,
    count.toString(),
    {
      style: 'font-blue',
      fontSize: '22px',
    },
    0.5, 300, 300, false, 'center',
  );
  group.add(group.countTxt);

  return group;
};

G.GiftUI.Elements.Chest = function (x, y, config) {
  const group = game.make.group();
  group.position.setTo(x, y);
  group.isChest = true;
  group.gift = null;
  group.giftLid = null;
  const giftConfig = config.gifts;

  group.light = G.GiftUI.Elements.Light(0, -20, 1.1, group, 'shine_godrays');
  group.light.visible = true;


  const giftBottomPos = G.OMTsettings.elements.chestPositioning.opened.bottom;
  const gift = G.makeImage(giftBottomPos.x, giftBottomPos.y, 'chest_open_bottom', 0.5, group);
  group.gift = gift;

  const giftCloseTopPos = G.OMTsettings.elements.chestPositioning.closed.top;
  const giftLid = G.makeImage(giftCloseTopPos.x, giftCloseTopPos.y, 'chest_closed_lid', 0.5, gift);
  group.giftLid = giftLid;

  const giftTopPos = G.OMTsettings.elements.chestPositioning.opened.top;
  const giftOpenLid = G.makeImage(giftTopPos.x, giftTopPos.y, 'chest_open_lid', 0.5, gift);
  giftOpenLid.visible = false;
  group.openLid = giftOpenLid;

  const giftGlowPos = G.OMTsettings.elements.chestPositioning.opened.glow;
  const giftGlow = G.makeImage(giftGlowPos.x, giftGlowPos.y, 'chest_glow', 0.5, gift);
  giftGlow.alpha = 0;
  group.giftGlow = giftGlow;

  gift.scale.setTo(1.75);

  group.inside = G.GiftUI.Elements.MultiGiftInside(giftConfig, group);
  group.inside.alpha = 0;
  group.inside.visible = false;

  const winTxt = OMT.language.getText('You unlocked a Chest!');
  group.insideExtraTxt = new G.Text(
    0,
    -400,
    winTxt,
    {
      style: 'font-white',
      fontSize: '56px',
    },
    0.5,
    500,
  );
  group.add(group.insideExtraTxt);
  group.insideExtraTxt.visible = false;

  gift.stopShake = false;
  group.onOpen = new Phaser.Signal();
  if (!config.autoOpen) {
    gift.inputEnabled = true;
    gift.input.useHandCursor = true;
    gift.events.onInputDown.add(function () {
      group.onOpen.dispatch();
    });
  }

  group.gift.boxJump = G.Animations.getBoxJumpAnimation();
  group.gift.boxJump.animate(group.gift);

  return group;
};

G.GiftUI.Elements.TutorialHand = function (parent, visible) {
  const hand = G.makeImage(0, 0,
    'tut_hand',
    0.5,
    parent);
  hand.alpha = visible === true ? 1 : 0;
  hand.visible = visible || false; // incase undefined
  hand.scale.setTo(0.6);

  return hand;
};
