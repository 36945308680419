if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getCubicRescaleAnimation = function getCubicRescaleAnimation() {
  return { animate: animate };

  function animate(game, target, scale, duration) {
    if(!target.visible) target.visible = true;
    return game.add
      .tween(target.scale)
      .to({x: scale, y: scale}, duration || 500, Phaser.Easing.Sinusoidal.Out, true);
  };
}