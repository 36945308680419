/* eslint-disable func-names */
G.Image = function (x, y, frame, anchor, groupToAdd) {
  Phaser.Image.call(this, game, Math.floor(x), Math.floor(y), null);
  this.state = game.state.getCurrentState();

  this.changeTexture(frame);

  if (anchor) {
    if (typeof anchor === 'number') {
      this.anchor.setTo(anchor);
    } else {
      this.anchor.setTo(anchor[0], anchor[1]);
    }
  }

  if (groupToAdd) {
    (groupToAdd.add || groupToAdd.addChild).call(groupToAdd, this);
  } else if (groupToAdd !== null) {
    game.world.add(this);
  }
};

G.Image.prototype = Object.create(Phaser.Image.prototype);

G.Image.prototype.stopTweens = function () {
  G.stopTweens(this);
};

G.Image.prototype.changeTexture = function (image) {
  G.changeTexture(this, image);
};

Phaser.Image.prototype.changeTexture = function (image) {
  G.changeTexture(this, image);
};

G.Image.prototype.add = function (obj) {
  return this.addChild(obj);
};
