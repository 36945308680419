import OMT_BgLoadingUtil from '../../../Utils/OMT_BgLoadingUtil'; // eslint-disable-line import/no-cycle

export default class EventPostcardAssetManager {
  /**
   * Puts into queue the required assets for Friendship chest story mode
   */
  static loadAssets() {
    if (OMT.feature.getEventPostcardAssetStatus()) { return; }

    // Load the rest of the assets
    const postCardImages = G.OMTsettings.postcardEvent.cardData.postcardData.map((data) => data.image);
    const shareImages = G.OMTsettings.postcardEvent.cardData.postcardData.map((data) => data.shareImage);
    OMT_BgLoadingUtil.getInstance().addBatchToQueue(
      postCardImages.concat(shareImages),
      (loadData) => {
        if (loadData.failed === 0) {
          OMT.feature.setEventPostcardAssetStatus(true);
          console.log('[ASSET] Event Postcard complete');
        }
      },
    );
  }

  /**
   * Gets the asset path for the message background
   * @returns {string}
   */
  static getPostcardBackgroundString() {
    return 'img/eventPostcard/eventPostcard_MessageBg.png';
  }

  /**
   * Gets the asset path for the body
   * @returns {string}
   */
  static getPostcardBodyString() {
    return 'img/eventPostcard/eventPostcard_MessageBody.png';
  }
}
