import { GameScaleController } from '../../States/Scaling/GameScaleController';

G.WorldSidePanelGroup = function () {
  Phaser.Group.call(this, game);
  this.x = 30;

  this.onOpened = new Phaser.Signal();
  this.onClosed = new Phaser.Signal();

  const mainButton = new G.WorldSidePanelWidgetButton(-55, 100);
  mainButton.onClicked.add(function () {
    this.toggle();
  }, this);
  this.add(mainButton);

  const { gameScale } = GameScaleController.getInstance();

  this.bg = G.makeImage(-500, 0, 'background_1', 0, null);
  this.bg.cropRect = new Phaser.Rectangle(0, 0, 430, 960);
  this.bg.updateCrop();
  this.bg.contentMaxWidth = 430;
  this.bg.contentMaxHeight = game.height / gameScale;

  // create scroll input
  this.scrollInput = new G.ScrollInput(
    this.bg.x, this.bg.y, 430, game.height / gameScale,
  );
  this.add(this.scrollInput);

  this.content = new G.WorldSidePanelGroupContent(-500, 0);
  // because groups are automatically added to the world
  this.content.parent.removeChild(this.content);

  this.border = G.makeImage(-70, 0, 'border_greenbush', 0, this);

  this.opened = false;
  this.openOffset = 0;

  this.onDestroy.add(function () {
    this.content.destroy();
    this.bg.destroy();
  }, this);

  G.sb('toggleWorldSidePanel').add(this.toggle, this);
  G.sb('onScreenResize').add(this.onResize, this);
  this.onResize();

  // blocks clicks through the background element
  const clickHandler = G.Input.createCustomInputClickHandler();
  clickHandler.clickSignal.add(() => {
    // console.log('BLOCK CLICK');
  });
  G.Input.initializeCustomInput(this.bg);
  this.bg.customInput.addHandler(clickHandler);
};

G.WorldSidePanelGroup.prototype = Object.create(Phaser.Group.prototype);

G.WorldSidePanelGroup.prototype.onResize = function () {
  const { gameScale } = GameScaleController.getInstance();

  this.border.height = Math.max(1400, game.height / gameScale);
  this.bg.height = game.height / gameScale;
  this.scrollInput.resizeArea(430, game.height / gameScale);
};

G.WorldSidePanelGroup.prototype.update = function () {
  Phaser.Group.prototype.update.call(this);

  this.content.visible = this.x !== 30;
  this.content.y -= this.scrollInput.getVelY();
  this.content.y = game.math.clamp(
    this.content.y,
    Math.max(0, (this.content.getContentHeight() - game.height)) * -1, 0,
  );
};

G.WorldSidePanelGroup.prototype.toggle = function () {
  this.opened = !this.opened;

  if (this.opened) {
    if (!this.content.parent) {
      this.add(this.bg);
      this.add(this.content);
    }
    game.sfx.transition.play();
    this.content.y = 0;
    this.scrollInput.setVelToZero();
    G.sb('onWorldSidePanelOpen').dispatch();
    this.onOpened.dispatch();
  } else {
    game.sfx.whoosh.play();
    this.onClosed.dispatch();
  }

  G.stopTweens(this);
  game.add.tween(this).to({
    x: this.opened ? 460 : 30,
  }, 400, Phaser.Easing.Custom.SoftBounce, true).onComplete.add(function () {
    if (!this.opened) {
      this.removeChild(this.content);
      this.removeChild(this.bg);
    }
  }, this);
};
