G.Input = G.Input || {};
G.Input.initializeCustomInput = (element, optMinDragDistance) => {
  // imports
  const { EventType } = G.Input;
  const utils = G.Utils;
  const storageUtils = G.StorageUtils;

  const createCustomInput = () => {
    const handlers = [];

    const handleEventShared = (eventType, pointer) => {
      let some = false;
      for (let i = 0; i < handlers.length; ++i) {
        some = handlers[i].handleEventShared(eventType, pointer) || some;
      }
      return some;
    };

    const handleEventExclusive = (eventType, pointer) => {
      let consumed = false;
      for (let i = 0; i < handlers.length; ++i) {
        if (!consumed) {
          // TODO: what if it triggers callback that modifiers the `handlers` array?
          consumed = handlers[i].handleEventExclusive(eventType, pointer);
        } else {
          handlers[i].cancelEvent(eventType);
        }
      }
      return consumed;
    };

    const cancelEvent = (eventType) => {
      for (let i = 0; i < handlers.length; ++i) {
        handlers[i].cancelEvent(eventType);
      }
    };

    // FIXME:
    /* Using phaser's input events doesn't work well when you end interaction outside the element
     * E.g. a scrollable popup with button inside. Scrolling it by starting drag on button
     * and ending outside the popup will trigger no events */
    /**
     * @param handler {Object} a handler that implements custom input handler interface
     * @param handler.handleEventShared {(G.Input.EventType, Phaser.Poitner) => boolean}
     * @param handler.handleEventExclusive {(G.Input.EventType, Phaser.Poitner) => boolean}
     * @param handler.cancelEvent {(G.Input.EventType) => void}
     */
    const addHandler = (handler) => {
      handlers.push(handler);
    };

    return {
      // custom input handler interface
      handleEventShared,
      handleEventExclusive,
      cancelEvent,

      // custom
      addHandler,
    };
  };

  const initPhaserDrag = (inputHandler, minDragDistance) => {
    inputHandler.draggable = true;

    // Prevent phaser from actually dragging the element - we only need events
    inputHandler.allowHorizontalDrag = false;
    inputHandler.allowVerticalDrag = false;

    inputHandler.dragDistanceThreshold = minDragDistance;
  };

  const enableInputEvents = (_element, _minDragDistance) => {
    // enable onInput* events
    _element.inputEnabled = true;

    // enable onDrag* events
    initPhaserDrag(_element.input, _minDragDistance);
  };

  const addCustomInput = (_element) => {
    _element.customInput = createCustomInput();
  };

  const hasCustomInput = (_element) => Boolean(_element.customInput);

  const handleCustomInput = (sourceElement, pointer, eventType) => {
    const interestedCandidates = [];

    pointer.interactiveCandidates.forEach((inputHandler) => {
      const _element = inputHandler.sprite;

      if (_element && hasCustomInput(_element)) {
        if (_element.customInput.handleEventShared(eventType, pointer)) {
          interestedCandidates.push(_element);
        }
      }
    });

    // Prepare priority based on render order
    interestedCandidates.sort((a, b) => {
      // TODO: test with groups
      if (a.renderOrderID === b.renderOrderID) {
        return 0;
      }
      if (a.renderOrderID < b.renderOrderID) {
        return 1;
      }

      return -1;
    });

    // TODO: test inputEnabled manipulation

    // Prioritize the source element before anything else
    storageUtils.moveArrayElementToBeFirst(interestedCandidates, sourceElement);

    // handle event considering consumption
    let eventConsumed = false;
    for (let i = 0; i < interestedCandidates.length; ++i) {
      const { customInput } = interestedCandidates[i];
      if (eventConsumed) {
        customInput.cancelEvent(eventType);
      } else {
        eventConsumed = customInput.handleEventExclusive(eventType, pointer);
      }
    }
  };

  const addInputEventListeners = (events) => {
    const forwardSignals = (tuple) => {
      const { signal, eventType } = tuple;
      signal.add((sourceElement, pointer) => {
        handleCustomInput(sourceElement, pointer, eventType);
      });
    };

    [
      { signal: events.onInputOver, eventType: EventType.InputOver },
      { signal: events.onInputOut, eventType: EventType.InputOut },
      { signal: events.onInputDown, eventType: EventType.InputDown },
      { signal: events.onInputUp, eventType: EventType.InputUp },
      { signal: events.onDragStart, eventType: EventType.DragStart },
      { signal: events.onDragUpdate, eventType: EventType.DragUpdate },
      { signal: events.onDragStop, eventType: EventType.DragStop },
    ].forEach((tuple) => {
      forwardSignals(tuple);
    });
  };

  /**
   * @param element {Phaser.Sprite}
   * @param optMinDragDistance {number = 15}
   */
  const doInitializeCustomInput = (_element, _optMinDragDistance) => {
    addCustomInput(_element);
    enableInputEvents(_element, utils.defined(_optMinDragDistance, 15));
    addInputEventListeners(_element.events);
  };

  return doInitializeCustomInput(element, optMinDragDistance);
};
