import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';

const width = 360;
const height = 230;

const convertToPercentageX = (x) => (x / width) * 100;
const convertToPercentageY = (y) => (y / height) * 100;

export default [
  {
    texture: OMT_VILLAINS.getPrefixedName('villain_1_3'),
    destroyOnComplete: true,
    transforms: [
      // Initial transform
      {
        x: convertToPercentageX(182 - width / 2),
        y: convertToPercentageY(135 - height / 2),
        callback: (object) => {
          object.scale.setTo(0.266);
        },
      },
      {
        wait: 1470,
      },
      {
        alpha: 0,
        scale: 0.13,
        duration: 30,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('poof'),
    destroyOnComplete: true,
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(186 - width / 2),
        y: convertToPercentageY(203 - height / 2),
        scaleX: 0.6,
        scaleY: 0.22,
      },
      {
        wait: 1070,
      },
      {
        alpha: 1,
        callback: () => {
          G.sfx.boom.play();
          G.sfx.line.play();
        },
      },
      {
        x: convertToPercentageX(220 - width / 2),
        y: convertToPercentageY(188 - height / 2),
        scaleX: 0.9,
        scaleY: 0.39,
        duration: 265,
      },
      {
        alpha: 0,
        x: convertToPercentageX(250 - width / 2),
        y: convertToPercentageY(176 - height / 2),
        scaleX: 1.16,
        scaleY: 0.53,
        duration: 235,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('poof'),
    destroyOnComplete: true,
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(186 - width / 2),
        y: convertToPercentageY(203 - height / 2),
        scaleX: 0.6,
        scaleY: 0.22,
      },
      {
        wait: 1070,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(140 - width / 2),
        y: convertToPercentageY(193 - height / 2),
        scaleX: 0.84,
        scaleY: 0.36,
        duration: 265,
      },
      {
        alpha: 0,
        x: convertToPercentageX(99 - width / 2),
        y: convertToPercentageY(183 - height / 2),
        scaleX: 1.05,
        scaleY: 0.48,
        duration: 235,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('poof'),
    destroyOnComplete: true,
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(186 - width / 2),
        y: convertToPercentageY(201 - height / 2),
        scaleX: 0.56,
        scaleY: 0.25,
      },
      {
        wait: 1070,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(175 - width / 2),
        y: convertToPercentageY(113 - height / 2),
        scaleX: 1.28,
        scaleY: 1.21,
        duration: 365,
      },
      {
        alpha: 0.6,
        x: convertToPercentageX(174 - width / 2),
        y: convertToPercentageY(119 - height / 2),
        scaleX: 1.32,
        scaleY: 1.15,
        duration: 270,
      },
      {
        alpha: 0,
        x: convertToPercentageX(173 - width / 2),
        y: convertToPercentageY(126 - height / 2),
        scaleX: 1.38,
        scaleY: 1.07,
        duration: 340,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('poof_particle_2'),
    destroyOnComplete: true,
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(197 - width / 2),
        y: convertToPercentageY(158 - height / 2),
        angle: -36,
      },
      {
        wait: 1270,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(252 - width / 2),
        y: convertToPercentageY(101 - height / 2),
        angle: -44,
        duration: 300,
      },
      {
        alpha: 0.53,
        x: convertToPercentageX(264 - width / 2),
        y: convertToPercentageY(89 - height / 2),
        angle: -45,
        duration: 65,
      },
      {
        alpha: 0,
        duration: 65,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('poof_particle_2'),
    destroyOnComplete: true,
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(149 - width / 2),
        y: convertToPercentageY(159 - height / 2),
        angle: -113,
      },
      {
        wait: 1270,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(105 - width / 2),
        y: convertToPercentageY(101 - height / 2),
        duration: 300,
      },
      {
        alpha: 0.53,
        x: convertToPercentageX(96 - width / 2),
        y: convertToPercentageY(89 - height / 2),
        duration: 65,
      },
      {
        alpha: 0,
        duration: 65,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('poof_particle_2'),
    destroyOnComplete: true,
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(174 - width / 2),
        y: convertToPercentageY(154 - height / 2),
        angle: -85,
      },
      {
        wait: 1270,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(175 - width / 2),
        y: convertToPercentageY(90 - height / 2),
        angle: -89,
        duration: 300,
      },
      {
        alpha: 0.46,
        y: convertToPercentageY(77 - height / 2),
        angle: -90,
        duration: 65,
      },
      {
        alpha: 0,
        duration: 65,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('poof_particle_1'),
    destroyOnComplete: true,
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(161 - width / 2),
        y: convertToPercentageY(164 - height / 2),
        angle: -114,
      },
      {
        wait: 1270,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(113 - width / 2),
        y: convertToPercentageY(134 - height / 2),
        angle: -141,
        duration: 165,
      },
      {
        x: convertToPercentageX(98 - width / 2),
        y: convertToPercentageY(130 - height / 2),
        angle: -149,
        duration: 65,
      },
      {
        alpha: 0.24,
        x: convertToPercentageX(97 - width / 2),
        y: convertToPercentageY(121 - height / 2),
        angle: -163,
        duration: 100,
      },
      {
        alpha: 0,
        duration: 35,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('poof_particle_1'),
    destroyOnComplete: true,
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(198 - width / 2),
        y: convertToPercentageY(173 - height / 2),
        angle: -75,
      },
      {
        wait: 1270,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(255 - width / 2),
        y: convertToPercentageY(139 - height / 2),
        angle: -30,
        duration: 165,
      },
      {
        x: convertToPercentageX(282 - width / 2),
        y: convertToPercentageY(135 - height / 2),
        angle: -9,
        duration: 65,
      },
      {
        alpha: 0.5,
        x: convertToPercentageX(307 - width / 2),
        y: convertToPercentageY(132 - height / 2),
        angle: -13,
        duration: 65,
      },
      {
        alpha: 0,
        duration: 70,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('poof_particle_1'),
    destroyOnComplete: true,
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(176 - width / 2),
        y: convertToPercentageY(147 - height / 2),
        angle: -94,
      },
      {
        wait: 1270,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(199 - width / 2),
        y: convertToPercentageY(91 - height / 2),
        angle: -62,
        duration: 165,
      },
      {
        x: convertToPercentageX(217 - width / 2),
        y: convertToPercentageY(71 - height / 2),
        angle: -56,
        duration: 65,
      },
      {
        alpha: 0.5,
        x: convertToPercentageX(231 - width / 2),
        y: convertToPercentageY(55 - height / 2),
        angle: -51,
        duration: 65,
      },
      {
        alpha: 0,
        duration: 65,
      },
    ],
  },
];
