import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

const genericWidth = 600; // Predetermined width of popup_generic
const genericSafeWidth = 480; // Predetermined inner width of popup_generic

/**
 * Window style for the generic window. Either completely custom or followed by template
 */
export const GENERIC_WINDOW_STYLE = {
  TEMPLATE: 'template',
  I_LEAVE_IT_TO_YOU: 'i_leave_it_to_you',
};

/**
 * A generic window for generic messages
 * Provides fully custom windows or a very strict, yet flexible template!
 * @author Sandra Koo
 */
export class Window_Generic extends Window {
  /**
   *
   * Using Window_Generic
   *----------------------
   *
   * Window_Generic allows you to open a blank window. The developer has full responsibility on what goes into the window.
   * There are 2 modes for  Window_Generic.
   * The first mode is TEMPLATE, where you fill out an Object of strict values and it'll produce a window with the given parameters.
   * The second mode is I_LEAVE_IT_TO_YOU (I leave it to you), where the window expects the developer to pass in a Phaser.Group to add in and it'll display it.
   * It does not assist the developer in drawing anything.
   *
   * There are template examples at the bottom of this file
   *
   * The passed in config object looks like this:
   * config = {
   *  windowStyle: GENERIC_WINDOW_STYLE,  //State your style
   *  custom?: Phaser.Group,            //The displayObject you wish to show if a ILEAVEITUPTOYOU window
   *  template?: {                      //Or follow the template if you're using TEMPLATE
   *    title: {                        //Title data
    *    text: string,                  //The text
    *    textStyle: string || object,   //Its textstyle
    *    offset?: Phaser.Point          //Offset from 0, 0
    *  }
    *  image: {                           //Image data
    *    image: Phaser.DisplayObject,     //The DisplayObject image. Make it yourself!
    *    offset?: Phaser.Point            //Offset from 0, title.y + title.height/2 || 0
    *  },
    *  text: {                            //Text data
    *    text: string,                    //Its string
    *    textStyle: string || object,     //Its text style
    *    boundaries?: Phaser.Rectangle,   //The width and height, more importantly
    *    offset?: Phaser.Point            //Offset from 0, image.y + image.height/2 || 0
    *  },
    *  button1: {                         //Button 1
    *    image: string,                   //Image
    *    colour: number,                  //Its colour (when 9SliceFlex comes in)
    *    text: string,                    //The text on it
    *    textStyle: string || object,     //The text style
    *    boundaries?: Phaser.Rectangle    //The dimensions of it, and positioning (uses x,y)
    *    onClick?: function               //The function that calls when it gets clicked
    *  },
    *  button2: {                         //Same thing as button 1
    *    image: string,                   //But Button2 does not have the extra detailing effect on buttons
    *    colour: number,
    *    text: string,
    *    textStyle: string || object,
    *    boundaries: Phaser.Rectangle
    *    onClick: function
    *  },
    * close: {                            //The close button
    *    onClick:function                 //The function that gets called when clicked
    *  }
   *  }
   * }
   * @param {any} parent
   * @param {Object} config
   */
  constructor(parent, config) {
    super(parent, config);
    this.applyGenericConfig(config);
  }

  /**
   * init the window with its configuration
   * @param {Object} config
   */
  applyGenericConfig(config) {
    this.genericConfig = config;
    this.isWorldState = game.state.current === 'World';

    if (this.genericConfig.windowStyle === GENERIC_WINDOW_STYLE.I_LEAVE_IT_TO_YOU) {
      this.drawWindow();
    } else {
      this.drawTemplateWindow();
    }

    if (this.isWorldState) { // Is in the world?
      this.y -= G.WindowMgr.Constants.WorldVerticalOffset; // Move that offset
    }
  }

  /**
   * For when the window is provided by whoever sends it
   */
  drawWindow() {
    const windowContainer = new Phaser.Group(G.game, this);
    windowContainer.addChild(this.genericConfig.custom);
  }

  /**
   * For when the window follows the template
   */
  drawTemplateWindow() {
    const conf = this.genericConfig.template;

    const windowContainer = new Phaser.Group(G.game, this);
    const backgroundContainer = new Phaser.Group(G.game, windowContainer); // Background first
    const contentContainer = new Phaser.Group(G.game, windowContainer); // Then the content
    const innerContainer = new Phaser.Group(G.game, contentContainer); // Actual inner content
    let titleContainer; // The title

    if (conf.title) {
      titleContainer = new Phaser.Group(G.game, contentContainer);
      const titleImage = G.makeImage(0, 0, G.OMTsettings.elements.windowNineSlice.banner || 'daily_rewards_ribbon', 0.5, titleContainer);
      const title = new G.Text(0, 0, conf.title.text, conf.title.textStyle, 0.5, titleImage.width * 0.9, titleImage.height * 0.9, true, 'center');
      title.x += (conf.title.offset ? (conf.title.offset.x || 0) : 0);
      title.y += (conf.title.offset ? (conf.title.offset.y || 0) : 0);
      titleContainer.addChild(title);

      this.y += titleImage.height / 4;
    }

    let nextDimension = {
      x: 0, y: 0, width: 0, height: 0,
    }; // Used for default positioning
    if (conf.image) {
      const img = conf.image.image;
      innerContainer.addChild(img);
      img.x = (conf.image.offset ? (conf.image.offset.x || 0) : 0);
      img.y = (conf.image.offset ? (conf.image.offset.y || 0) : 0);

      nextDimension = {
        x: img.x, y: img.y + img.height / 2, width: img.width, height: img.height,
      };
    }

    if (conf.text) {
      const stuffingBoundaries = {
        x: conf.text.boundaries.x || 0,
        y: conf.text.boundaries.y || nextDimension.y + nextDimension.height / 2,
        width: conf.text.boundaries.width || genericSafeWidth,
        height: Math.min(390, conf.text.boundaries.height),
      };
      const stuffing = new G.Text(0, 0, conf.text.text, conf.text.textStyle, 0.5, stuffingBoundaries.width, stuffingBoundaries.height, true, 'center');
      stuffing.x = stuffingBoundaries.x + (conf.text.offset ? (conf.text.offset.x || 0) : 0);
      stuffing.y = stuffingBoundaries.y + (conf.text.offset ? (conf.text.offset.y || 0) : 0) + stuffing.height / 2;
      innerContainer.addChild(stuffing);

      nextDimension = {
        x: stuffing.x, y: stuffing.y, width: stuffing.width, height: stuffing.height,
      };
    }

    const buttonDimensions = {
      x: 0, y: nextDimension.y + nextDimension.height / 2, width: 200, height: 100,
    }; // Default button dimensions
    if (conf.button1) {
      const button1Dimensions = {
        x: buttonDimensions.x + (conf.button1.boundaries.x || 0),
        y: buttonDimensions.y + (conf.button1.boundaries.y || 0),
        width: conf.button1.boundaries.width || buttonDimensions.width,
        height: conf.button1.boundaries.height || buttonDimensions.height,
      };
      const button1 = new OMT_UI_SquareButton(button1Dimensions.x, button1Dimensions.y, {
        button: {
          tint: conf.button1.colour,
          dimensions: {
            width: button1Dimensions.width,
            height: button1Dimensions.height,
          },
        },
      });
      if (conf.button1.onClick) {
        button1.signals.onClick.add(conf.button1.onClick);
      }
      if (conf.button1.text && conf.button1.textStyle) {
        button1.setTextStyle(conf.button1.textStyle);
        button1.setText(conf.button1.text);
      }
      innerContainer.addChild(button1);
    }

    if (conf.button2) {
      const button2Dimensions = {
        x: buttonDimensions.x + (conf.button2.boundaries.x || 0),
        y: buttonDimensions.y + (conf.button2.boundaries.y || 0),
        width: conf.button2.boundaries.width || buttonDimensions.width,
        height: conf.button2.boundaries.height || buttonDimensions.height,
      };
      const button2 = new OMT_UI_SquareButton(button2Dimensions.x, button2Dimensions.y, {
        button: {
          tint: conf.button2.colour,
          dimensions: {
            width: button2Dimensions.width,
            height: button2Dimensions.height,
          },
          extraDetail: false,
        },
      });
      if (conf.button2.onClick) {
        button2.signals.onClick.add(conf.button2.onClick);
      }
      if (conf.button2.text && conf.button2.textStyle) {
        button2.setTextStyle(conf.button2.textStyle);
        button2.setText(conf.button2.text);
      }
      innerContainer.addChild(button2);
    }
    const bannerHeight = G.OMTsettings.elements.windowNineSlice.bannerHeight || 50;
    const bg = new PhaserNineSlice.NineSlice(0, 0, 'popups', 'popup_generic', genericWidth,
      innerContainer.height + (G.OMTsettings.elements.windowNineSlice.slice.bottom / 2) + (titleContainer ? titleContainer.height : bannerHeight), G.OMTsettings.elements.windowNineSlice.slice);
    bg.anchor.set(0.5);
    backgroundContainer.addChild(bg);

    if (conf.close) {
      const closeButton = new G.Button(0, 0, null, () => { if (conf.close.onClick) { conf.close.onClick(); } this.closeWindow(); });
      const closeImg = G.makeImage(0, 0, 'btn_x', 0.5, closeButton);
      closeButton.x = bg.x + (bg.width - (closeImg.width / 2)) / 2;
      closeButton.y = bg.y - bg.height / 2;
      contentContainer.addChild(closeButton);
    }

    innerContainer.y = bg.y - (bg.height / 2) + bannerHeight * 0.9;

    if (titleContainer) {
      titleContainer.y = bg.y - bg.height / 2;
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.Generic = Window_Generic;

export const templates = {
  base: () => { // eslint-disable-line arrow-body-style
    return {
      windowStyle: GENERIC_WINDOW_STYLE.TEMPLATE,
      template: {
        title: {
          text: 'Generic Window',
          textStyle: { style: 'font-white', fontSize: 80 },
        },
        image: {
          image: G.makeImage(0, 0, 'task_complete', [0.5, 0], null),
        },
        text: {
          text: 'Generic Window',
          textStyle: 'font-blue',
          boundaries: { height: 400 },
          offset: { y: 10 },
        },
        button1: {
          colour: BUTTONCOLOURS.green,
          text: 'Ok',
          textStyle: 'font-white',
          boundaries: {
            x: -100, y: 30, width: 166, height: 100,
          },
          onClick: () => { console.log('Green is pressed'); },
        },
        button2: {
          colour: BUTTONCOLOURS.red,
          text: 'No',
          textStyle: 'font-white',
          boundaries: {
            x: 100, y: 30, width: 166, height: 100,
          },
          onClick: () => { console.log('Red is pressed'); },
        },
        close: {
          onClick: () => { console.log('Goodbye!'); },
        },
      },
    };
  },
  temp: () => {
    const generateImage = () => {
      const img = G.makeImage(0, 0, 'MysteryGift_Cookie_boosts', [0.5, 0], null);
      img.width = 386;
      img.height = 279;
      return img;
    };
    return {
      windowStyle: GENERIC_WINDOW_STYLE.TEMPLATE,
      template: {
        title: {
          text: 'Generic Window',
          textStyle: { style: 'font-white', fontSize: 80 },
        },
        image: {
          image: generateImage(),
        },
        text: {
          // eslint-disable-next-line max-len
          text: 'Swap: Once selected this booster allows the player to choose one piece on the board and swap it with another one. Trident: Once selected this booster allows the players to choose one piece on the board to be destroyed Horizontal Line: Once selected this booster allows the player to choose a line on the board to be destroyed. Each cell of that line will be attacked from left to right. The emptied cells will only start filling up once the line has been attacked. Vertical Line: Once selected this booster allows the player to choose a column on the board to be destroyed.',
          textStyle: 'font-blue',
          boundaries: { height: 400 },
          offset: { y: 10 },
        },
        button1: {
          colour: BUTTONCOLOURS.green,
          text: 'Yay',
          textStyle: 'font-white',
          boundaries: {
            x: -100, y: 30, width: 309, height: 100,
          },
          onClick: () => { console.log('Green is pressed'); },
        },
        button2: {
          colour: BUTTONCOLOURS.red,
          text: 'Nay',
          textStyle: 'font-white',
          boundaries: {
            x: 150, y: 30, width: 166, height: 100,
          },
          onClick: () => { console.log('Red is pressed'); },
        },
        close: {
          onClick: () => { console.log('Goodbye!'); },
        },
      },
    };
  },
  demoLarge: () => {
    const generateImage = () => {
      // Start of Promo drawing
      const container = new Phaser.Group(G.game, null);
      const img = new Phaser.Group(G.game, container);
      const chest = new Phaser.Group(G.game, img);
      const bottomHalf = G.makeImage(0, 0, 'friendship_chest_bottom', 0.5, chest);
      bottomHalf.width = 128;
      bottomHalf.height = 93;
      const shine = G.makeImage(0, -35, 'reward_BG', 0.5, null);
      const openedLid = G.makeImage(0, -40, 'friendship_chest_top', 0.5, chest);
      openedLid.width = 130;
      openedLid.height = 79;
      shine.height = chest.height * 2;
      shine.width = chest.height * 2;
      chest.addChild(shine);
      chest.addChild(openedLid);

      const avatarAndArrow = new Phaser.Group(G.game, img);
      const bg = G.makeImage(0, 0, 'daily_prize_panel_bg', 0.5, avatarAndArrow);
      const arrow = G.makeImage(0, 0, 'beaten_green_arrow', [0.5, 1], avatarAndArrow);
      arrow.angle = 180;
      arrow.scale.set(1.5, 1.5);
      const drawAvatar = (avatarURL, size) => {
        const avatarContainer = new Phaser.Group(G.game, null);
        const avatar = G.makeImage(0, 0, avatarURL, 0.5, avatarContainer);
        const avatarFrame = G.makeImage(0, 0, 'avatar_frame', 0.5, avatarContainer);
        avatar.width = size;
        avatar.height = size;
        avatarFrame.width = avatar.width * 1.15;
        avatarFrame.height = avatar.width * 1.15;

        return avatarContainer;
      };
      const avatarF = drawAvatar('avatar_f', bg.height * 0.6);
      const avatarM = drawAvatar('avatar_m', bg.height * 0.6);
      avatarF.x = avatarF.width - (bg.width / 2);
      avatarM.x = (bg.width / 2) - avatarM.width;
      const plus = G.makeImage(0, 0, 'btn_plus', 0.5, avatarAndArrow);
      plus.width = bg.height * 0.8;
      plus.height = bg.height * 0.8;
      arrow.y = bg.height - arrow.height + plus.height * 0.1;
      avatarAndArrow.addChild(avatarF);
      avatarAndArrow.addChild(avatarM);
      avatarAndArrow.y = avatarAndArrow.height / 2;
      chest.y = avatarAndArrow.y + avatarAndArrow.height * 1.25;

      const boosterInfo = [ // Relative to being in shine. Added last to avoid messing around with layouting
        {
          img: 'ui_booster_1', x: -170, y: -140, scale: 1,
        },
        {
          img: 'ui_booster_8', x: -185, y: 10, scale: 1.45,
        },
        {
          img: 'ui_booster_3', x: 195, y: -85, scale: 1.2,
        },
        {
          img: 'ui_booster_7', x: 190, y: 50, scale: 1.35,
        },
      ];
      for (let i = 0; i < boosterInfo.length; i++) {
        const info = boosterInfo[i];
        const booster = G.makeImage(info.x, info.y, info.img, 0.5, shine);
        booster.scale.set(info.scale, info.scale);
      }
      return container;
    };
    return {
      windowStyle: GENERIC_WINDOW_STYLE.TEMPLATE,
      template: {
        title: {
          text: 'Friendship Chest',
          textStyle: { style: 'font-white', fontSize: 80 },
        },
        image: {
          image: generateImage(),
        },
        text: {
          text: OMT.language.getText('Invite new friends to %GameName% and earn rewards when they join!'),
          textStyle: 'font-blue',
          boundaries: { height: 400 },
          offset: { y: 10 },
        },
        button1: {
          colour: BUTTONCOLOURS.green,
          text: 'I want rewards!',
          textStyle: 'font-white',
          boundaries: {
            x: 0, y: 10, width: 309, height: 100,
          },
          onClick: () => { console.log('Green is pressed'); },
        },
        button2: {
          colour: BUTTONCOLOURS.red,
          text: 'No, thanks',
          textStyle: 'font-white',
          boundaries: {
            x: 0, y: 110, width: 166, height: 100,
          },
          onClick: () => { console.log('Red is pressed'); },
        },
        close: {
          onClick: () => { console.log('Goodbye!'); },
        },
      },
    };
  },
  demoCross: () => {
    const generateImage = () => {
      const crossPromoData = OMT.crossPromo.getNextPromo();
      const imgr = G.makeExtImage(0, -50, crossPromoData.img, null, [0.5, 0], null, true, (img) => {
        img.alpha = 0; // eslint-disable-line no-param-reassign
        game.add.tween(img).to({ alpha: 1 }, 250, Phaser.Easing.Quadratic.Out, true);
      });
      return imgr;
    };
    return {
      windowStyle: GENERIC_WINDOW_STYLE.TEMPLATE,
      template: {
        title: {
          text: 'Try Playing...',
          textStyle: { style: 'font-white', fontSize: 80 },
        },
        image: {
          image: generateImage(),
          offset: { y: 20 },
        },
        button1: {
          colour: BUTTONCOLOURS.green,
          text: 'Play Now!',
          textStyle: 'font-white',
          boundaries: {
            x: 0, y: 30, width: 309, height: 100,
          },
          onClick: () => { console.log('Green is pressed'); },
        },
        button2: {
          colour: BUTTONCOLOURS.red,
          text: 'No, thanks',
          textStyle: 'font-white',
          boundaries: {
            x: 0, y: 135, width: 166, height: 100,
          },
          onClick: () => { console.log('Red is pressed'); },
        },
        close: {
          onClick: () => { console.log('Goodbye!'); },
        },
      },
    };
  },
  demoShop: () => {
    const generateImage = () => {
      const img = new Phaser.Group(game, null);

      const inner = new Phaser.Group(game, img);
      const bg = G.makeImage(0, 0, 'reward_BG', 0.5, inner);
      const coins = G.makeImage(0, 0, 'coin_3', 0.5, inner);
      coins.width = bg.width * 0.5;
      coins.height = bg.width * 0.5;

      const coinCounter = new Phaser.Group(game, inner);
      const text = new G.TextCounter(0, 0, G.saveState.getCoins(), {
        style: 'font-white',
        fontSize: '40px',
      }, 0.5, 200);
      const coinImg = G.makeImage(0, 0, 'coin_1', 0.5, null);
      coinImg.height = text.height;
      coinImg.width = text.height;
      const textBG = new Phaser.Graphics(game);
      textBG.beginFill(0xFF9525);
      textBG.drawRoundedRect(0, 0, text.width + coinImg.width, text.height, text.height * 4);
      textBG.y -= textBG.height / 2;
      textBG.x -= textBG.width / 2;
      coinImg.x = textBG.x;
      text.x = coinImg.x + 5 + (coinImg.width + text.width) / 2;
      text.y += 2;

      coinCounter.add(textBG);
      coinCounter.add(coinImg);
      coinCounter.add(text);
      coinCounter.x += coinImg.width / 4;
      coinCounter.y = bg.height / 2;

      inner.y += bg.height / 2;
      return img;
    };
    return {
      windowStyle: GENERIC_WINDOW_STYLE.TEMPLATE,
      template: {
        title: {
          text: 'Try Playing...',
          textStyle: { style: 'font-white', fontSize: 80 },
        },
        image: {
          image: generateImage(),
          offset: { y: 20 },
        },
        button1: {
          colour: BUTTONCOLOURS.green,
          text: '$2.99',
          textStyle: 'font-white',
          boundaries: {
            x: 0, y: 30, width: 309, height: 100,
          },
          onClick: () => { console.log('Green is pressed'); },
        },
        button2: {
          colour: BUTTONCOLOURS.red,
          text: 'No, thanks',
          textStyle: 'font-white',
          boundaries: {
            x: 0, y: 135, width: 166, height: 100,
          },
          onClick: () => { console.log('Red is pressed'); },
        },
        close: {
          onClick: () => { console.log('Goodbye!'); },
        },
      },
    };
  },
};
