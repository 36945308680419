G.TitleScreenGemsThrower = function() {
	
	Phaser.Group.call(this,game);

	G.sb("onScreenResize").add(this.onScreenResize,this);
	this.onScreenResize();

	this.chanceForShoot = 0.1;

	this.horizontal = false;

	for (var i = 0; i < 20; i++) {
		this.addChild(new G.TitleScreenGem());
	}
 
}

G.TitleScreenGemsThrower.prototype = Object.create(Phaser.Group.prototype);

G.TitleScreenGemsThrower.prototype.onScreenResize = function() {
	
	this.x = game.world.bounds.x;
	this.y = 0;

};

G.TitleScreenGemsThrower.prototype.throwGem = function() {
	
	var gem = this.getFreeGem();
	if (gem == null) return;

	var xx, yy, velX, velY;

	if (this.horizontal) {

		xx = Math.random() < 0.5 ? game.world.bounds.x - 50 : -game.world.bounds.x+game.width+50;
		yy = (game.height * 0.5) + (game.height*0.5*Math.random());
		velX = Math.floor(3+Math.random()*6)*Math.sign(xx)*-1;
		velY = Math.floor(-2+Math.random()*-2);

	}else {
		
		xx = Math.random()*game.width;
		yy = game.height+50;
		velX = Math.floor(1+Math.random()*-2);
		velY = Math.floor(-1+Math.random()*-3);

	}

	gem.init(xx,yy,velX,velY); 

};

G.TitleScreenGemsThrower.prototype.getFreeGem = function() {

	return this.getFirstDead();

};


G.TitleScreenGemsThrower.prototype.update = function() {

	if (Math.random() < this.chanceForShoot) {
		this.throwGem();
	}

	for (var i = 0; i < this.children.length; i++) {
		this.children[i].update();
	} 

};

G.TitleScreenGem = function() {
	
	Phaser.Image.call(this,game,0,0);
	this.anchor.setTo(0.5);
	this.grav = 0.02;
	this.kill();


};

G.TitleScreenGem.prototype = Object.create(Phaser.Image.prototype);

G.TitleScreenGem.prototype.init = function(x,y,velX,velY) {

	G.changeTexture(this,'candy_'+game.rnd.between(1,6));

	this.x = x;
	this.y = y;
	this.velX = velX;
	this.velX *= 0.99;
	this.velY = velY;
	this.angleSpeed = -1.5+Math.random()*3
	this.revive();


};

G.TitleScreenGem.prototype.update = function() {

	if (!this.alive) return;

	this.x += this.velX * G.deltaTime;
	this.angle += this.angleSpeed * G.deltaTime;
	this.y += this.velY * G.deltaTime;
	this.velY += this.grav * G.deltaTime;

	if (this.y > game.height+100) {
		this.kill();
	}


};
