if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};
G.Animations.Generic = G.Animations.Generic || {};

G.Animations.Generic.createTranslateXYCubicOutAnimation = (
  function createTranslateXYCubicOutAnimation(targetX, targetY, duration) {
    return { animate: animate };
    function animate(game, target) {
      game.add.tween(target).to(
        {
          x: targetX,
          y: targetY
        },
        duration, Phaser.Easing.Cubic.Out, true
      );
    }
  }
);
