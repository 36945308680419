import { Window_Generic, GENERIC_WINDOW_STYLE } from '../Window_Generic';
import { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
/**
 * notification window for like us on facebook for no ads. Uses generic window.
 */
class Window_NoAdsLikeOnFB extends Window_Generic {
  /**
   * constructor
   * @param {} parent
   */
  constructor(parent) {
    super(parent, {});
  }

  /**
   * apply the generic config
   */
  applyGenericConfig() {
    const config = this._getGenericWindowConfig();
    super.applyGenericConfig(config);
  }

  /**
   * get window configuration
   * @returns {Object}
   */
  _getGenericWindowConfig() {
    const config = {
      windowStyle: GENERIC_WINDOW_STYLE,
      template: {
        title: {
          text: OMT.language.getText('No ads available'),
          textStyle: { style: 'font-white', fontSize: 40 },
        },
        image: {
          image: G.makeImage(0, 0, 'MysteryGift_Cookie_boosts', [0.5, 0]),
        },
        text: {
          text: OMT.language.getText('We don\'t have an ad to show you right now, but you can ask for a spin from your friends!'),
          textStyle: 'font-blue',
          boundaries: new Phaser.Rectangle(0, 0, 500, 500),
        },
        button1: {
          colour: BUTTONCOLOURS.green,
          text: OMT.language.getText('Like us on Facebook'),
          textStyle: { style: 'font-white', lineSpacing: -10 },
          boundaries: new Phaser.Rectangle(0, 50, 300, 100),
          onClick: this._onButton1Clicked.bind(this),
        },
        close: {
          onClick: this._onCloseClicked.bind(this),
        },
      },
    };
    return config;
  }

  /**
   * close button clicked
   */
  _onCloseClicked() {
    // not doing anything yet
  }

  /**
   * button1 button clicked
   */
  _onButton1Clicked() {
    // transistion to ask friend window
    this.closeWindow(() => {
      window.location = G.OMTsettings.global.gamePage;
    });
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.NoAdsLikeOnFB = Window_NoAdsLikeOnFB;
