import PrizeWheel_Item from '@omt-components/UI/PrizeWheel/Components/PrizeWheel_Item';
import { ItemDataType } from '@omt-components/UI/PrizeWheel/Components/PrizeWheel_ItemData';
import OMT_PrizeWheel from './OMT_PrizeWheel';

// used to configure this class + elements of UI_PrizeWheel
const DEFAULT_CONFIG = {
  spinner: {
    wheelRotation: 120,
    wheelSegments: 24,
    bgAssetNormal: 'prize_wheel_2',
    bgAssetPremium: 'gold_wheel',
    bgOffsetAngle: 180,
  },
  isPremiumMode: false,
};

// merged with ITEM_CONFIGS from OMT_PrizeWheel + DEFAULT_CONFIG from PrizeWheel_Item
const ITEM_CONFIGS = {
  COINS: {
    text: {
      style: {
        fontSize: 29,
      },
      offset: { x: 72, y: 0 },
      anchor: [0.5, 0.5],
      angle: 90,
    },
    icon: {
      asset: 'coin_1',
      anchor: [0.5, 0.5],
      scale: 0.75,
      offset: { x: 115, y: 0 },
      angle: 90,
    },
  },
  BOOSTER: {
    text: {
      visible: false,
    },
    icon: {
      scale: 0.75,
      offset: { x: 115, y: 0 },
    },
  },
};

/**
 * daily / daily premium prize wheel
 */
export default class OMT_LossAversionPrizeWheel extends OMT_PrizeWheel {
  /**
   * constructor
   * @param {Object} config (optional) components configurations
   * @param {Object} config.spinner (optional) overrides for PrizeWheel_Spinner DEFAULT_CONFIG
   * @param {Object} config.pointer (optional) overrides for PrizeWheel_Pointer DEFAULT_CONFIG
   * @param {Object} config.itemConfigsOverride (opional) overrides for ITEM_CONFIGS from OMT_PrizeWheel
   * @param {boolean} config.isPremiumMode (optional) premium mode effects prize table
   */
  constructor(config = {}) {
    super(_.merge(_.cloneDeep(DEFAULT_CONFIG), config));

    // convert prize data and intialize items
    const { itemConfigOverrides } = this._config;
    const prizeTable = this._getPrizeTable();
    const itemDatalist = this._convertPrizeList(prizeTable, _.merge(_.cloneDeep(ITEM_CONFIGS), itemConfigOverrides));
    this.initPrizeItems(itemDatalist);
  }

  /**
   * Makes the container that holds the prize on the wedge of the wheel
   * @param {PrizeWheel_ItemData} itemData
   * @param {number} startAngle
   * @param {number} endAngle
   */
  _makePrizeItem(itemData, startAngle, endAngle) {
    const item = new PrizeWheel_Item(itemData);
    if (item.itemType === ItemDataType.BOOSTER) item.hidePrizeText();
    item.setRotation(startAngle, endAngle);
    return item;
  }

  /**
   * create spinning wheel
   * @param {Object} config overrides for PrizeWheel_Spinner DEFAULT_CONFIG
   */
  _createSpinner(config) {
    const { isPremiumMode } = this._config;
    const { bgAssetPremium, bgAssetNormal } = config;
    config.bgAsset = isPremiumMode ? bgAssetPremium : bgAssetNormal;
    super._createSpinner(config);
  }

  /**
   * Gets a list of wheel prizes (free and ad-supported)
   * @returns {Array.<Object>}
   */
  _getPrizeTable() {
    const { isPremiumMode } = this._config;
    let prizeTable;
    if (!G.featureUnlock.multipleExtraMovesFailFlow) { // no extra moves
      if (G.IAP || isPremiumMode) prizeTable = G.json['configs/spinWheels'].failFlowGold;
      else prizeTable = G.json['configs/spinWheels'].failFlowNormal;
    } else if (G.IAP || isPremiumMode) {
      prizeTable = G.json['configs/spinWheels'].failFlowExtraMovesGold;
    } else {
      prizeTable = G.json['configs/spinWheels'].failFlowExtraMovesNormal;
    }
    return _.cloneDeep(prizeTable);
  }

  /**
   * Redistributes the chance of getting a booster from other prizes that are not boosters
   * @param {number} amount
   */
  modifyBoosterChance(amount) {
    let boosterCount = 0;
    for (let i = 0; i < this._rawPrizeTable.length; i++) {
      const pack = this._rawPrizeTable[i];
      if (pack.prize.indexOf('booster') > -1) { // Increase chances for boosters
        pack.chance += amount;
        boosterCount++;
      }
    }

    for (let i = 0; i < this._rawPrizeTable.length; i++) { // decrease chances for not boosters
      const pack = this._rawPrizeTable[i];
      if (pack.prize.indexOf('booster') === -1) {
        pack.chance = Math.max(0, pack.chance - Math.floor((amount / (this._rawPrizeTable.length - boosterCount))));
      }
    }
  }
}
