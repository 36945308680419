import CollectableAnimPart from './CollectableAnimPart';

export default class CollectableAnimLayer extends Phaser.Group {
  /**
   * Manages particles flying to the goal panel when collection is made
   * @param {G.Board} board
   * @param {GoalPanel} goalPanel
   */
  constructor(board, goalPanel) {
    super(game);

    this._board = board;
    this._goalPanel = goalPanel;

    if (G.IMMEDIATE) return;

    this.signalBinding = G.sb('onCandyToUIAnim').add((type, elem, sprite, callback, context) => {
      // In case of booster or whatever
      if (!elem) {
        callback.call(context);
        return;
      }
      const goalType = this._goalPanel.getGoalPanel(type);
      this.getFreeParticle().init(type, elem, goalType, sprite, callback, context);
    });
  }

  /**
   * Clean up
   */
  destroy() {
    if (this.signalBinding) {
      if (this.signalBinding.detach) {
        this.signalBinding.detach();
      }
      this.signalBinding = null;
    }
    super.destroy();
  }

  /**
   * Free PArticle
   */
  getFreeParticle() {
    return this.getFirstDead() || this.add(new CollectableAnimPart(this._board));
  }

  /**
   * Not sure
   * @param {GoalPanel} panel
   */
  initNofly(panel) {
    this.getFreeParticle().initNofly(panel);
  }
}
