import ShareMsgBase from './A_G.ShareMsgBase';

class MsgTreasureHuntComplete extends ShareMsgBase {
  /**
   * The message that gets sent when you finish the treasure hunt and you want to brag
   * @param {string} user1AvatarURL
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1AvatarURL, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    this.avatar1 = this._addExtImage(490, 94, user1AvatarURL, 0, 134, null);
    this.bg = this._addExtImage(0, 0, 'img/Share_TreasureHunt_Complete.png', 0, null, null);

    const str = OMT.language.getText(G.json.shareMessageText.shareTreasureHuntCompleteMsg);
    this.txt = new G.Text(343, 330, str, 'shareMessageText', 0.5, 650);
    this.add(this.txt);

    this._objectLoadComplete();
  }
}

G.MsgTreasureHuntComplete = MsgTreasureHuntComplete;
