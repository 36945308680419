import ShareMsgBase from './A_G.ShareMsgBase';

export class MsgFortuneCookie extends ShareMsgBase {
  /**
   * The message that is shared when you share a fortune cookie message
   * @param {string} imgID
   * @param {string} txt
   * @param {string} category
   * @param {function} onAllImagesReadyCallback
   */
  constructor(imgID, txt, category, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);
    const avatarConfig = G.OMTsettings.elements.MsgFortuneCookie.avatarLayout[category.category.toLowerCase()];
    const avatar = G.makeExtImage(avatarConfig.x, avatarConfig.y, OMT.envData.settings.user.avatar, 'avatar_m', 0, this, false, (img) => {
      img.width = avatarConfig.width;
      img.height = avatarConfig.height;

      this._objectLoadComplete();
    });
    avatar.angle = avatarConfig.angle;
    this.img = this._addExtImage(0, 0, `img/${imgID}.png`, 0, 672, 354);
    this.txt = new G.Text(310, 89, OMT.language.getText(txt.msg).replace('<<name>>', FBInstant.player.getName()), 'fortuneCookie-shareStyle', 0.5, 330, 120, true, 'center');
    this.txt.angle = -1.75;
    this.add(this.txt);

    this._objectLoadComplete();
  }
}
G.MsgFortuneCookie = MsgFortuneCookie;
