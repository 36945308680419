/* eslint-disable no-use-before-define */

import { OMT_UI_FriendsListItem } from '../../../OMT_UI/FriendsList/OMT_UI_FriendsListItem';
import { StarDisplayGroup } from '../../Components/Displays/StarDisplayGroup';
import { AnimatedCoinButton } from '../../Components/Buttons/AnimatedCoinButton';
import { LeaderboardAvatar } from '../../Components/Avatars/LeaderboardAvatar';

import {
  COIN_REQUEST_COOLDOWN,
  FRIENDS_PANEL_EXPANDED_FONT_STYLE,
  FRIENDS_PANEL_EXPANDED_COIN_X,
  FRIENDS_PANEL_EXPANDED_COIN_SCALE,
  FRIENDS_PANEL_EXPANDED_AVATAR_X,
  FRIENDS_PANEL_EXPANDED_RANK_X,
  FRIENDS_PANEL_EXPANDED_SCORE_X,
} from '../../MainLeaderboardSettings';
import { RenderTextureGenerator } from '@omt-components/Imaging/RenderTextures/RenderTextureGenerator';

export const COIN_REQUEST_STATUS = {
  REQUESTED: 0,
  SUCCESS: 1,
  FAILED: 2,
};

/**
 * expanded user view / list item for friends highscores and requesting coins
 */
export class FriendsScoresExpandedUserView extends OMT_UI_FriendsListItem {
  /**
   * constructor
   * @param {Object} friendData
   * @param {number} itemWidth
   * @param {number} itemHeight
   * @param {number} bgColor
   */
  constructor(friendData, itemWidth, itemHeight, bgColor) {
    super(friendData, itemWidth, itemHeight, bgColor);

    this.signals.onCoinRequest = new Phaser.Signal();

    this._initRankLabel();
    this._initStarDisplayGroup();

    // set coin request variables and buttons
    const { deviceIsIOS } = OMT.systemInfo;
    const isYourUserId = friendData.userId === OMT.envData.settings.user.userId;
    this._coinRequestEnabled = !isYourUserId && !deviceIsIOS;
    if (this._coinRequestEnabled) {
      this._cooldownDuration = COIN_REQUEST_COOLDOWN.duration;
      this._cooldownId = COIN_REQUEST_COOLDOWN.id;
      this._initCoinRequestButton();
      this._updateCooldownState(true);
    }

    // cache content container
    this.cacheContent = true;
  }

  /**
   * init the avatar image
   */
  _initAvatar() {
    const textureId = `avatar-rank-0-${this._friendData.userId}-${this._imageSize}`;
    const anchor = [0.5, 0.5];
    this._avatarImage = RenderTextureGenerator.getRenderTextureSprite(textureId, anchor);
    if (!this._avatarImage) {
      const avatarGraphics = new LeaderboardAvatar(this._friendData, this._imageSize);
      this._avatarImage = RenderTextureGenerator.generateRenderTextureSprite(avatarGraphics, textureId, anchor);
      avatarGraphics.destroy();
    }
    this._avatarImage.x = this._imageX + FRIENDS_PANEL_EXPANDED_AVATAR_X;
    this._avatarImage.y = this._itemHeight / 2;
    this._cacheableContent.addChild(this._avatarImage);
  }

  /**
   * init the users name label
   */
  _initNameLabel() {
    super._initNameLabel(FRIENDS_PANEL_EXPANDED_FONT_STYLE);
  }

  /**
   * init the users # rank label
   */
  _initRankLabel() {
    const rankText = Math.round() < 0.3 ? `#1${this._friendData.rank}` : `#${this._friendData.rank}`;
    const rankLabel = new G.Text(0, 0, rankText, FRIENDS_PANEL_EXPANDED_FONT_STYLE, [0.5, 0.5], 50, this._itemHeight, false);
    rankLabel.x = FRIENDS_PANEL_EXPANDED_RANK_X; // align center wasnt working
    rankLabel.y = this._itemHeight / 2;
    this._cacheableContent.addChild(rankLabel);
  }

  /**
   * init the score field and related graphics
   */
  _initStarDisplayGroup() {
    this._starGroup = new StarDisplayGroup(this._friendData.score);
    this._starGroup.x = FRIENDS_PANEL_EXPANDED_SCORE_X;
    this._starGroup.y = this._itemHeight / 2;
    this._cacheableContent.addChild(this._starGroup);
  }

  /**
   * init the coin request button
   */
  _initCoinRequestButton() {
    const coinButton = new AnimatedCoinButton(() => { this._onCoinRequestClicked(); });
    coinButton.scale.set(FRIENDS_PANEL_EXPANDED_COIN_SCALE);
    coinButton.x = FRIENDS_PANEL_EXPANDED_COIN_X;
    coinButton.y = this._itemHeight / 2;
    this.addChild(coinButton);
    this._coinRequestButton = coinButton;
  }

  /**
   * get the position the coin reward animation should start from
   * @returns {Object}
   */
  getCoinAnimateFromPos() {
    return this.toGlobal({ x: this._coinRequestButton.x, y: this._coinRequestButton.y });
  }

  /**
   * called the coin request button is clicked
   * @returns {Promise}
   */
  async _onCoinRequestClicked() {
    this.signals.onCoinRequest.dispatch(this, COIN_REQUEST_STATUS.REQUESTED);
    OMT.social.sendCoinsMessage(this._friendData.userId, (result) => {
      if (result) {
        G.saveState.setUserCooldown(this._cooldownId, this._friendData.userId, this._cooldownDuration);
        this._setCooldownState();
        this.signals.onCoinRequest.dispatch(this, COIN_REQUEST_STATUS.SUCCESS);
      } else {
        this.signals.onCoinRequest.dispatch(this, COIN_REQUEST_STATUS.FAILED);
      }
    });
  }

  /**
   * set the active / normal state
   */
  _setActiveState() {
    this._coinRequestButton.resume();
    this._coinRequestButton.visible = true;
    this._isOnCooldown = false;
  }

  /**
   * set the cooldown state
   */
  _setCooldownState() {
    this._coinRequestButton.pause();
    this._coinRequestButton.visible = false;
    this._isOnCooldown = true;
  }


  /**
   * update cool down state
   * @param {boolean} forceUpdate (optional) default false
   */
  _updateCooldownState(forceUpdate = false) {
    const cooldownTime = G.saveState.getUserCooldownRemaining(this._cooldownId, this._friendData.userId);
    if ((forceUpdate || this._isOnCooldown) && cooldownTime === 0) {
      this._setActiveState();
    } else if ((forceUpdate || !this._isOnCooldown) && cooldownTime > 0) {
      this._setCooldownState();
    }
  }

  /**
   * main update method
   */
  update() {
    super.update();
    if (this._coinRequestEnabled) this._updateCooldownState();
  }

  /**
   * enable interactivity
   */
  enable() {
    if (this._coinRequestButton) this._coinRequestButton.enable();
  }

  /**
   * disable interactivity
   */
  disable() {
    if (this._coinRequestButton) this._coinRequestButton.disable();
  }
}
