import { ORIENTATION } from '@omt-game-board/Managers/GameEnums';

/**
 * Shows on board.deconstruct(). Has no graphics by default add them externally or extend.
 */
export class DeconstructMsg extends Phaser.Group {
  /**
   * Welcome the Deconstruct Message Base
   * Heres some tips:
   * - The DeconstructMsg is a group. You can use update()
   * - Put all tweens into this._allTweens, and all waits into this._allwaits.
   * - The DeconstructMsg base comes with a skip functionality that allows the user to speed through it quick.
   * - Everything in this._allTweens and this._allWaits will be sped up when the skip button is clicked.
   * - Various signals are available so you can have the proper timing
   * - this._afterDelay is the amount of seconds the game will wait after the deconstructMsg fades away
   * @param {LvlDataManager} gameHooks
   */
  constructor(lvlDataManager) {
    super(game);

    this._lvlDataManager = lvlDataManager;
    this._gameHooks = lvlDataManager.gameHooks;

    this.alpha = 0;
    this._pauseDuration = 4000; // The duration of waiting on the screen before it fades out
    this._tweenTimeScale = 1; // The current speed of tweens
    this._afterDelay = 0; // The amount of ms to wait after the msg fades out
    this._shoutout = 'well_done';
    this._pauseDelay = undefined;

    this.signals = { // Signals for timing. Accessed externally too
      onAnimateInStart: new Phaser.Signal(),
      onAnimateInFinish: new Phaser.Signal(),
      onAnimateOutStart: new Phaser.Signal(),
      onAnimateOutFinish: new Phaser.Signal(),
    };

    this._allTweens = []; // All tweens
    this._allWaits = []; // All waits
    this._initSkipHitbox();
  }

  /**
   * get the shoutout text to display
   * @returns {string}
   */
  get shoutout() {
    return this._shoutout;
  }

  /**
   * get the duration in milliseconds to pause on the message for
   * @returns {number}
   */
  get pauseDuration() {
    return this._pauseDuration;
  }

  /**
   * Returns the ms that should delay after this message disappears before continuing
   * @returns {number}
   */
  get afterDelay() {
    return this._afterDelay;
  }

  /**
   * Creates an click-able hit area on the entire screen that lets you speed up the deconstruct message
   */
  _initSkipHitbox() {
    this._skipButton = G.makeImage(0, 0, null, 0, null);
    this._skipButton.inputEnabled = true;
    this._skipButton.input.useHandCursor = true;
    this._skipButton.hitArea = new Phaser.Rectangle(-2000, -2000, 4000, 4000);

    const text = new G.Text(0, 0, this._gameHooks.getText('Skip'), 'tournamentTaunt-skipText', 0.5, game.width * 0.7, 500, true, 'center');
    const blinkTime = 1000;
    const pulse = game.time.events.loop(blinkTime, () => { // Instance of the event so it can be removed when the card is removed
      if (this._skipButton && this._skipButton.visible && this._skipButton.parent) {
        game.add.tween(text)
          .to({ alpha: 0.4 }, blinkTime / 2, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
      }
    }, this);
    text.y = -130 - (text.height - game.height) / 2;
    this._skipButton.addChild(text);
    game.add.tween(text) // initial blink
      .to({ alpha: 0.4 }, blinkTime / 2, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);

    this._skipButton.pulse = pulse;

    // Move skip button down if game is running in reduced landscape resolution (e.g. OMT)
    if (this._gameHooks.hasLowResLandscape && this._gameHooks.orientation === ORIENTATION.horizontal) {
      this._skipButton.y = 150;
    }

    this._skipButton.events.onInputDown.add((this._onSkipClick.bind(this)));
  }

  /**
   * animate in the msg
   */
  animateIn() {
    this.alpha = 0;
    if (this._fadeTween) this._fadeTween.stop();
    this.signals.onAnimateInStart.dispatch();
    if (this._skipButton) {
      this.addChild(this._skipButton);
    }
    this._fadeTween = game.add.tween(this);
    this._fadeTween.to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out);
    this._fadeTween.timeScale = this._tweenTimeScale;
    this._fadeTween.start();
    this._fadeTween.onComplete.add(() => { this.signals.onAnimateInFinish.dispatch(); });
    this.signals.onAnimateInFinish.addOnce(() => {
      const pauseTween = game.add.tween(this) // The pause delay is a tween so its speed can be adjusted
        .to({ alpha: 1 }, this._pauseDuration, Phaser.Easing.Linear.None);
      pauseTween.onComplete.addOnce(() => {
        this._animateOut();
      });
      pauseTween.timeScale = this._tweenTimeScale;
      pauseTween.start();
      this._allTweens.push(pauseTween);
    });
  }

  /**
   * animate out the msg
   * @param {number} duration
   */
  _animateOut() {
    if (this._fadeTween) this._fadeTween.stop();
    this.signals.onAnimateOutStart.dispatch();
    this._fadeTween = game.add.tween(this);
    this._fadeTween.to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out);
    this._fadeTween.timeScale = this._tweenTimeScale;
    this._fadeTween.start();
    this._fadeTween.onComplete.add(() => { this.signals.onAnimateOutFinish.dispatch(); });
  }

  /**
   * When the skip button is pressed:
   * - All tweens will double their speed
   * - All wait events will cut their wait times in half.
   * - Future pause duration will cut their wait time by half
   * - Future tweens will go at 2x speed
   * - Skip button is destroyed properly
   */
  _onSkipClick() {
    this._tweenTimeScale = 2;
    this._pauseDuration /= 2;
    this._fadeTween.timeScale = this._tweenTimeScale;
    this._allTweens.forEach((tw) => { tw.timeScale = this._tweenTimeScale; });
    this._allWaits.forEach((w) => { w.delay /= 2; });
    if (this._skipButton.pulse && this._skipButton.pulse.destroy) {
      game.time.events.remove(this._skipButton.pulse);
      this._skipButton.pulse = null;
    }
    this.destroySkip();
  }

  destroySkip() {
    this.removeChild(this._skipButton);
    this._skipButton.destroy();
    this._skipButton = null;
  }

  /**
   * destruction method
   */
  destroy() {
    if (this._fadeTween) this._fadeTween.stop(); // Stop the fade tween
    if (this.signals) { // Remove all signals attached to signal
      Object.keys(this.signals).forEach((key) => {
        if (this.signals[key]) {
          this.signals[key].removeAll();
        }
      });
      this.signals = null;
    }
    this._allTweens.forEach((tw) => { // Stop all tweens
      if (tw.stop) {
        tw.stop();
      }
    });
    this._allTweens = []; // But keep an empty Array in case of race conditions
    this._allWaits.forEach((wa) => { // Stop all delays
      if (wa.destroy) {
        wa.destroy();
      }
    });
    this._allWaits = []; // But keep an empty array in case of race conditions
    if (this._skipButton) { // Destroy skip button
      if (this._skipButton.pulse && this._skipButton.pulse.destroy) {
        this._skipButton.pulse.destroy();
      }
      this._skipButton.destroy();
      this._skipButton = null;
    }
    this._gameHooks = null;
    super.destroy();
  }
}
