import { GameScaleController } from '../../../States/Scaling/GameScaleController';
import { OMT_UI_AvatarWithFrame } from '../../../OMT_UI/FriendsList/OMT_UI_AvatarWithFrame';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

export class HelperMoves extends Phaser.Group {
  /**
   * Constructor.
   * @param {*} game
   * @param {*} parent The object this should be added to.
   * @param {*} gameManager The Game.js instance.  Used to position the helper based on other elements in the scene.
   */
  constructor(game, state, gameManager) {
    super(game);

    // referencing this because we need to position ourselves based on the top bar and the board
    this._state = state; // Game.js
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;
    this.gm = gameManager;

    // The sort of booster we will give
    this.movesToGive = G.gift.GiftContentType.StartBoosterSecondaryMovesInstant;

    // used in update for animating the profile pic and the bubble in their idle positions
    this.timer = 0;
    this.isVisible = false;

    // used for the up-and-down idle animations
    this.waveSpeedDivisor = 400;
    this.waveHeight = 100;
  }

  update() {
    this.timer += game.time.elapsedMS;

    // animations to slowly move the avatar/arrow/gift up and down, just to give some extra life.
    if (this.avatar) {
      this.avatar.y = Math.round(Math.sin(this.timer / this.waveSpeedDivisor) * 15);
    }
    if (this.arrow) {
      this.arrow.y = Math.round(Math.sin(this.timer / this.waveSpeedDivisor) * 15);
    }
    if (this.giftGroup) {
      // we want to slow our movement as we get closer to our destination; so we want to wait until we're actually tweening to start reducing the waveHeight
      if (this.isVisible) {
        this.waveHeight -= 1;
      }
      if (this.waveHeight <= 15) this.waveHeight = 15;

      // the bubble is floating, so lets make it look more floaty
      this.giftGroup.y = Math.cos(this.timer / this.waveSpeedDivisor) * this.waveHeight;
    }
  }

  /**
   * Init function, where we get any necessary data and create/position the display elements.
   * @returns {boolean} whether the init process was successful (false if there is no friend data)
   */
  async init() {
    // getting a random friend, if any
    this.friendData = await this.getFriend();

    this.userHasFriend = this.friendData !== null;
    if (!this.userHasFriend) return false;

    this.timesFailedLevel = 0;

    // arrow that points from the profile pic to the gift
    this.arrow = new G.Image(0, 0, 'requestHelp_arrow', 0.5, this);
    this.arrow.alpha = 0;

    // we save out the size we pass to the avatar, because its useful for positioning later
    const avatarSize = 100;
    this.avatar = new OMT_UI_AvatarWithFrame(this.friendData.image, avatarSize);
    this.add(this.avatar);
    this.avatar.scale.set(2);
    this.avatar.alpha = 0;

    // we save this here because the gift needs to position itself where the arrow is going to end up.
    this.arrowDestX = avatarSize + this.arrow.width / 2 + 10;

    this.giftGroup = new Phaser.Group(game, this);
    this.giftGroup.alpha = 0;

    this.gift = new G.Image(0, 0, 'requestHelp_gift', 0.5, this.giftGroup);
    this.gift.scale.setTo(2);

    this.bubble = new G.Button(0, 0, 'requestHelp_bubble', () => this.onBubbleClicked());
    this.bubble.scale.setTo(2);
    this.giftGroup.add(this.bubble);
    this.bubble.inputEnabled = false;

    // image for when we animate the bubble popping
    this.bubble_popped = new G.Image(0, 0, 'requestHelp_pop_sprite', 0.5, this);
    this.bubble_popped.alpha = 0;
    this.bubble_popped.scale.setTo(2, 2);

    // we save what we want the speech bubble scale to be because we tween the bubble later
    this.speechBubbleScale = 0.5;
    this.speechBubble = new G.Image(0, 0, 'requestHelp_speechBubble', [0.5, 1], this.avatar);
    this.speechBubble.scale.x = this.speechBubbleScale;

    // puts the speech bubble just above the profile pic
    this.speechBubble.y = -avatarSize / 2;

    // the icon to indicate the reward
    this.extraMoves = new G.Image(0, 0, G.gift.getIconForType(this.movesToGive), 0.5, this.gift);
    // this.extraMoves.scale.set(2);
    this.extraMoves.y = 13;

    // the hand to indicate to the user to poke on the gift
    this.hand = new G.Image(0, 0, 'tut_hand', 0, this.giftGroup);
    this.hand.scale.set(2);
    this.hand.alpha = 0;

    // how much to animate the hand back and forth
    this.handMovement = 10;

    // moving this back so that we can animate a poking motion
    this.hand.x = this.handMovement;
    this.hand.y = this.handMovement;

    const emojiScale = 0.9;
    // all emojis are at the same yposition, so we just save it here.
    const emojiY = -(this.speechBubble.height / 2 + 15); // the +15 is for the stem at the bottom

    // the emoji we use for inside the speech bubble
    this.pointEmoji = new G.Image(0, 0, 'requestHelp_emoji_point', 0.5, this.speechBubble);
    this.pointEmoji.scale.set(emojiScale);
    this.pointEmoji.x = -(this.pointEmoji.width / 2 + 10);
    this.pointEmoji.y = emojiY;

    this.giftEmoji = new G.Image(0, 0, 'requestHelp_emoji_gift', 0.5, this.speechBubble);
    this.giftEmoji.scale.set(emojiScale);
    this.giftEmoji.x = this.giftEmoji.width / 2 + 10;
    this.giftEmoji.y = emojiY;

    this.thumbsUpEmoji = new G.Image(0, 0, 'requestHelp_emoji_thumbsup', 0.5, this.speechBubble);
    this.thumbsUpEmoji.scale.set(emojiScale);
    this.thumbsUpEmoji.y = emojiY;
    this.thumbsUpEmoji.visible = false;

    this.speechBubble.scale.y = 0;

    // where the gift bubble is going to end up after tweening
    this.giftDestPos = this._isLandscape
      ? this.arrowDestX + this.arrow.width / 2 + this.gift.width / 2 + 60
      : this.arrowDestX + this.arrow.width / 2 + this.gift.width / 2 + 10;

    this._orientationSettings = this._getOrientationSettings();

    // we call this here because it does all the positioning we need to do
    this.onScreenResize();

    G.sb('onScreenResize').add(() => { this.onScreenResize(); });
    return true;
  }

  /**
   * Returns an object that is used for layout purposes
   * @returns {{ yPos: number, scale:number }}
   */
  _getOrientationSettings() {
    if (!this._isLandscape) {
      return {
        yPos: this._state.topBar.y + this._state.topBar.bg.getBounds().height + this.height / 2 - 30,
        scale: Math.max(Math.min(this.scale.y, 0.35), 0.25),
      };
    }

    // Horizontal
    return {
      yPos: 70,
      scale: Math.max(Math.min(this.scale.y, 0.35), 0.30) * this._gameScale,
    };
  }

  /**
   * Get a random friend from the player's friends list who isnt on cooldown
   */
  async getFriend() {
    const friendData = await OMT.friends.getFriendsList(true); // thread gets stalled here for this
    const isSolo = friendData.length === 0;
    if (!isSolo) {
      // grab avatar url if not solo
      const friendToReturn = friendData[Math.round(Math.random() * (friendData.length - 1))];
      return friendToReturn;
    }
    return null;
  }

  /**
   * Position elements on screen resize
   */
  onScreenResize() {
    // adjusting our height so we fit in the space between the board and the top bar.
    this.height = this._state.board.y - (this._state.topBar.y + this._state.topBar.getBounds().height) + 30;
    this.scale.x = this.scale.y;

    this._orientationSettings = this._getOrientationSettings();

    // making sure we're not too big or too small
    this.scale.set(this._orientationSettings.scale);

    // positioning everything so we can get our actual bounds
    this.avatar.x = 0;
    this.giftGroup.x = this.giftDestPos;
    this.giftGroup.y = this._isLandscape;
    this.speechBubble.scale.y = this.speechBubbleScale;

    // positioning it below the top bar and centered with the board
    this.x = game.world.bounds.x + game.width / 2 - (this.width / 2) * this.scale.x - 10;
    this.y = this._orientationSettings.yPos;
  }

  /**
   * Tween in the helper.
   */
  startTweens() {
    this.isVisible = true;
    // just off-screen
    this.offset = ((game.world.bounds.x + game.width - this.x) / this.scale.x) + this.speechBubble.width;

    // setting all props to prep for the tweens
    this.avatar.x = this.offset;
    this.giftGroup.x = this.offset;
    this.avatar.alpha = 1;
    this.giftGroup.alpha = 1;
    this.speechBubble.scale.y = 0;
    this.arrow.alpha = 0;
    this.bubble.inputEnabled = true;

    // tweening in the avatar
    const avatarTween = game.add.tween(this.avatar).to({ x: 0 }, 600, Phaser.Easing.Back.Out, true);

    // tweening up the speech bubble
    const speechBubbleUpTween = game.add.tween(this.speechBubble.scale).to({ y: this.speechBubbleScale }, 200, Phaser.Easing.Back.Out, false, 100);

    // tweening in the gift bubble
    const giftBubbleTween = game.add.tween(this.giftGroup)
      .to({ x: this.giftDestPos }, 1500, Phaser.Easing.Sinusoidal.Out, true, 300);

    // bringing in the arrow
    game.add.tween(this.arrow)
      .to({ x: this.arrowDestX, alpha: 1 }, 300, Phaser.Easing.Back.Out, true, 1500);

    // fading in the hand that gestures to touch the bubble
    const handFadeInTween = game.add.tween(this.hand).to({ alpha: 1 }, 300, Phaser.Easing.Linear.None, false);

    // adding the hand poking gesture
    const handMoveTween = game.add.tween(this.hand)
      .to({ x: -this.handMovement, y: -this.handMovement }, 300, Phaser.Easing.Sinusoidal.In, false, 0, -1, true);

    // chaining all the tweens together in the right order
    avatarTween.chain(speechBubbleUpTween);
    speechBubbleUpTween.chain(giftBubbleTween);
    handFadeInTween.chain(handMoveTween);
  }

  /**
   * When the player clicks the bubble, we initiate the context switch and, if it isnt cancelled, we finish the interaction.
   */
  async onBubbleClicked() {
    this.hand.alpha = 0;
    const helpWanted = await OMT.social.sendRequestHelpMessage(this.friendData, true);
    if (helpWanted) {
      G.saveState.sessionData.levelsFailedSinceUsingHelper[this.gm.lvlIndex] = 0;
      // get the position of the "moves" text object because we're gonna tween to it
      const moveTxtPosLocal = this.giftGroup.toLocal(this._state.topBar.movesTxt.position, this._state.topBar);
      moveTxtPosLocal.x += this._state.topBar.movesTxt.width / 2;
      moveTxtPosLocal.y += this._state.topBar.movesTxt.height + 30;
      this.bubble.inputEnabled = false;

      // inflating the bubble
      game.add.tween(this.bubble.scale)
        .to({ x: 3.4, y: 3.4 }, 500, Phaser.Easing.Linear.None, true)
        .onComplete.add(() => {
          // the bubble pops
          this.bubble.alpha = 0;
          this.bubble_popped.alpha = 1;
          this.bubble_popped.x = this.giftGroup.x;
          this.bubble_popped.y = this.giftGroup.y;
          this.bubble_popped.scale.set(2.4);

          game.add.tween(this.bubble_popped.scale)
            .to({ x: 12, y: 12 }, 200, Phaser.Easing.Linear.None, true);
          game.add.tween(this.bubble_popped)
            .to({ alpha: 0 }, 200, Phaser.Easing.Linear.None, true)
            .onComplete.add(() => {
              // this controls how far the bezier curve sweeps down.
              const modPointDrop = game.height / 2;
              // animating the gift to the moves counter in a swooping motion, to show the player getting extra moves
              const m1 = { x: this.gift.x, y: this.gift.y + modPointDrop };
              const m2 = { x: moveTxtPosLocal.x, y: moveTxtPosLocal.y + modPointDrop };
              game.add.tween(this.gift)
                .to({
                  x: [this.gift.x, m1.x, m2.x, moveTxtPosLocal.x],
                  y: [this.gift.y, m1.y, m2.y, moveTxtPosLocal.y],
                },
                600, Phaser.Easing.Sinusoidal.InOut, true, 300).interpolation((v, k) => Phaser.Math.bezierInterpolation(v, k))
                .onComplete.add(() => {
                  G.lvl.changeMoveNumber(G.gift.getMovesFromBooster(this.movesToGive));
                });

              // shrinking the gift as it moves toward the moves counter
              game.add.tween(this.gift.scale)
                .to({ x: 0, y: 0 }, 600, Phaser.Easing.Linear.None, true, 400);

              // tweening out arrow and avatar
              game.add.tween(this.arrow).to({ alpha: 0 }, 300, Phaser.Easing.Linear.None, true);
              game.add.tween(this.avatar).to({ x: this.offset }, 500, Phaser.Easing.Sinusoidal.Out, true)
                .onComplete.add(() => { this.avatar.alpha = 0; });
            });
        });

      // quickly tweening the speech bubble up and down to show the thumbs up emoji
      game.add.tween(this.speechBubble.scale).to({ y: 0 }, 100, Phaser.Easing.Back.Out, true)
        .onComplete.add(() => {
          this.thumbsUpEmoji.visible = true;
          this.giftEmoji.visible = false;
          this.pointEmoji.visible = false;
          game.add.tween(this.speechBubble.scale).to({ y: this.speechBubbleScale }, 100, Phaser.Easing.Back.Out, true);
        });
    } else {
      game.add.tween(this).to({ x: this.x + 10 }, 75, Phaser.Easing.Linear.None, true, 0, 1, true);
    }
  }

  cancelHelp() {
    this.isVisible = false;
    this.bubble.inputEnabled = false;
    game.add.tween(this).to({ x: this.offset }, 600, Phaser.Easing.Sinusoidal.In, true);
  }
}
