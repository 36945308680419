/**
 * A class that acts like a Phaser.Group but is supposed to help manage tweens and animations
 * Instead of making a huge tween.onComplete.add(()=>{}) pyramid, HOPEFULLY(?) this manager thing
 * will help in making the tweens look better as a line by line thing instead of a gigantic pyramid
 * You can find an example of a gigantic tween pyramid example at Friendship_Chest.animateClaim();
 * You can find an example of a not-gigantic tween pyramid in GoalDropTutorial.animatePageTwo();
 *
 * I also took this idea from COOK's tutorial thing.
 * I also don't know if it's a good idea to do so or if this will cause memory problems for having so many promises or tween references
 * Either way I hope its a step in the right direction to avoiding gigantic tween pyramids!?
 *
 * @author Sandra Koo
 */
export default class UI_AnimationGroup extends Phaser.Group {
  constructor(inGame, inParent) {
    super(inGame, inParent);

    this._tweenTimeScale = 1; // The current speed of tweens

    this._everyTween = []; // Like \EVERY/ tween. Its possible to add tweens into this without adding it in action!
    this._everyTimer = [];

    this._actionTween = []; // Only for the current animation that needs to be stopped on a dime!
    this._actionTimer = [];
  }

  /**
   * A promised wrapper that holds a tween. When the tween finishes the promise resolves
   * Use this for your tweens. Add an `await` on the line that you call one of these to make the function stall
   * and wait for the tween to finish before moving on in the function (the function must also be an async func)
   * Not having an await will fire the tween and continue on (sort of like simultaneous things going off)
   * @param {Object} obj
   * @param {Object} tweenData
   * @param {number} tweenTime
   * @param {Phaser.Easing} easing
   * @param {number} repeat
   */
  wrapTween(obj, tweenData, tweenTime, easing, delay = 0, repeat = 0) {
    if (!this.shouldEventComplete()) { return null; }
    return new Promise((resolve) => {
      if (!obj) { resolve(); }
      const tw = game.add.tween(obj)
        .to(tweenData, tweenTime, easing, true, delay, repeat);
      tw.onComplete.addOnce(() => {
        resolve();
      });
      tw.timeScale = this._tweenTimeScale;
      this._everyTween.push(tw); // Pushes in every and action
      this._actionTween.push(tw);
    });
  }

  /**
   * Same thing as wrapTween but this one is a delay timer.
   * When the timer is complete, it'll resolve and continue.
   * Adding an `await` in front of one of these calls will stall the function until the timer resolves
   * Not having an await in front of it is... pretty pointless
   * @param {number} ms
   */
  wait(ms) {
    if (!this.shouldEventComplete()) { return null; }
    return new Promise((resolve) => {
      const ti = game.time.events.add(ms, () => {
        resolve();
      });
      this._everyTimer.push(ti);
      this._actionTimer.push(ti);
    });
  }

  /**
   * A function that gets called before a stalled tween/timer is made and just before it resolves.
   * Its supposed to check if the tween/timer should continue its functionality before/after it happens
   * Its supposed to be helpful, such that the tweens don't fire in case an object gets removed by Garbage Collection because the class
   * just got destroyed. Or/And then error because whatever it tried to tween/do is now undefined or something.
   * Feel free to replace this or add more to it
   */
  shouldEventComplete() {
    return this.game;
  }

  /**
   * Stops every action tween and timer.
   * Also removes them out from the everyTween/Timer array
   */
  stopAllActions() {
    if (this._actionTween) {
      for (let i = 0; i < this._actionTween.length; i++) {
        if (this._actionTween[i].stop) {
          this._actionTween[i].stop();
        }
      }
    }
    _.pull(this._everyTween, this._actionTween);
    this._actionTween.length = 0;

    if (this._actionTimer) {
      for (let i = 0; i < this._actionTimer.length; i++) {
        if (this._actionTimer[i].destroy) {
          this._actionTimer[i].destroy();
        }
      }
    }
    _.pull(this._everyTimer, this._actionTimer);
    this._actionTimer.length = 0;
  }

  /**
   * Stops every tween/timer and removes it
   */
  destroy() {
    if (this._everyTween) {
      for (let i = 0; i < this._everyTween.length; i++) {
        if (this._everyTween[i].stop) {
          this._everyTween[i].stop();
        }
      }
    }
    this._everyTween = null;

    if (this._everyTimer) {
      for (let i = 0; i < this._everyTimer.length; i++) {
        if (this._everyTimer[i].destroy) {
          this._everyTimer[i].destroy();
        }
      }
    }
    this._everyTimer = null;

    super.destroy();
  }
}
