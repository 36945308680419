import ShareMsgBase from './A_G.ShareMsgBase';

class MsgAdsFallback extends ShareMsgBase {
  /**
   * The message that gets sent when you send a message to a friend to watch an ad
   * @param {string} user1
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1, onAllImagesReadyCallback = null) {
    super(2, onAllImagesReadyCallback);

    this.avatar1 = this._addExtImage(85, 120, user1, 0, 132, null);
    this.bg = this._addExtImage(0, 0, 'img/Msg_Ads_Fallback.png', 0, null, null);
  }
}

// create global references
G.MsgAdsFallback = MsgAdsFallback;
