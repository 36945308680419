const BOOSTER_IMAGE_OFFSET_Y = -10;
const LIVES_IMAGE_OFFSET_Y = -25;
const BG_SCALE = 1.5;

/**
 * class for standard booster icons for timed targeted offers
 */
export class TimedTargetedOffer_BoosterIcon extends Phaser.Group {
  /**
   * constructor
   * @param {string} boosterID
   * @param {number} boosterCount
   * @param {Object} config
   */
  constructor(boosterID, boosterCount, config) {
    super(game);
    this._config = config;

    this._drawBoosterBg();
    this._drawBoosterImage(boosterID);
    this._drawBoosterLabel(boosterCount);
  }

  /**
   * init / draw the booster background
   */
  _drawBoosterBg() {
    this._bg = G.makeImage(0, 0, 'IAP-store-tile', 0.5);
    this._bg.scale.setTo(BG_SCALE);
    this.addChild(this._bg);
  }

  /**
   * init / draw the booster image
   * @param {string} boosterID
   */
  _drawBoosterImage(boosterID) {
    const boosterNum = boosterID.split('#')[1];
    this._img = G.makeImage(0, BOOSTER_IMAGE_OFFSET_Y, `ui_booster_${boosterNum}`, 0.5);
    this.addChild(this._img);
  }

  /**
   * init / draw the booster label
   * @param {number} boosterCount
   */
  _drawBoosterLabel(boosterCount) {
    const textY = this._img.height / 2;
    this._label = new G.Text(0, 0, `x${boosterCount}`, 'window_timedPopupOffer-boosterTxt', 0.5, 0, 0, 0, 'center');
    this._label.y = textY + (this._label.getBounds().height / 2) + this._config.labelPaddingY + BOOSTER_IMAGE_OFFSET_Y;
    this.addChild(this._label);
  }
}

/**
 * class for unlimited lives booster icons
 */
export class TimedTargetedOffer_ULBoosterIcon extends Phaser.Group {
  /**
   * constructor
   * @param {number} infiniteLivesMins
   * @param {Object} config
   */
  constructor(infiniteLivesMins, config) {
    super(game);
    this._config = config;

    this._drawBoosterBg();
    this._drawBoosterImage();
    this._drawBoosterLabel(infiniteLivesMins);
  }

  /**
   * init / draw the booster background
   */
  _drawBoosterBg() {
    this._bg = G.makeImage(0, 0, 'IAP-store-tile', 0.5);
    this._bg.scale.setTo(BG_SCALE);
    this.addChild(this._bg);
  }

  /**
   * init / draw the booster image
   * @param {string} boosterID
   */
  _drawBoosterImage() {
    this._img = G.makeImage(0, LIVES_IMAGE_OFFSET_Y, 'heart_unlimited', 0.5);
    this.addChild(this._img);
  }

  /**
   * init / draw the booster label
   * @param {number} infiniteLivesMins
   */
  _drawBoosterLabel(infiniteLivesMins) {
    const infiniteLivesHrs = Math.round((infiniteLivesMins / 60) * 10) / 10;
    const textY = this._img.height / 2;
    this._label = new G.Text(0, 0, `${infiniteLivesHrs}h`, 'window_timedPopupOffer-boosterTxt', 0.5, 0, 0, 0, 'center');
    this._label.y = textY + (this._label.getBounds().height / 2) + this._config.labelPaddingY + LIVES_IMAGE_OFFSET_Y;
    this.addChild(this._label);
  }
}
