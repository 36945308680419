import ArrayUtil from '@omt-components/Utils/ArrayUtil';
import { Window_eventPostcard } from '../Window_eventPostcard';
import UnlockablePostcard, { POSTCARD_STATE } from './UnlockablePostcard';

const c_headerTextUnderSize = 0.8;
const stretchTitleBar = false;

class Window_unlockPostcard extends Window_eventPostcard {
  constructor(parent, returnArgs, config) {
    super(parent, returnArgs, config);

    this._allTweens = [];
  }

  /**
   * Creates the friend banner
   * Then the event postcard init
   * Then moves the X button a bit
   */
  _init() {
    this._createFriendBanner();
    super._init();
    this._arrows.right.originX = this._arrows.right.x;

    if (OMT.feature.isTokenEventOn(true)) {
      if (G.saveState.tokenEventManager.FTUE.gt) {
        this._showGingy = true;
        G.saveState.tokenEventManager.FTUE.gt = false;
        G.saveState.tokenEventManager.save();
      }
    }
    this.checkCardGifts();
  }

  _positionCloseButton() {
    super._positionCloseButton();
    this._closeBtn.y += 12;
  }

  /**
   * Draws the arrow, but moves it up
   * @param {boolean} isRight
   */
  _drawArrow(isRight) {
    const arrow = super._drawArrow(isRight);
    arrow.y = -75;
    arrow.scale.set(0.75);
    return arrow;
  }

  /**
   * Creates the banner assets that says a friend has unlocked this card
   */
  async _createFriendBanner() {
    this._friendData = await OMT.friends.getFriendsList();
    if (this._friendData.length === 0) { return; }

    const avatarGroup = new Phaser.Group(game, null);
    this._friendBannerData = {
      bg: G.makeImage(0, 0, 'eventPostcardFriendsBanner', [0, 0.5], this),
      avatarGroup,
      avatar: G.makeImage(0, 0, null, 0.5, avatarGroup),
      frame: G.makeImage(0, 0, 'avatar_frame', 0.5, avatarGroup),
      text: null,
    };
    this._friendBannerData.text = new G.Text(0, 0, '', 'font-white', [0, 0.5], this._friendBannerData.bg.width * 0.8, this._friendBannerData.bg.height * 0.98);
    this._friendBannerData.bg.addChild(avatarGroup);
    this._friendBannerData.bg.addChild(this._friendBannerData.text);
    avatarGroup.scale.set((this._friendBannerData.bg.height * 0.7) / this._friendBannerData.frame.height);
    this._refillBanner();
    this._repositionFriendBanner();
    if (this._currentCard && this._currentCard.state !== POSTCARD_STATE.UNLOCKED) {
      this._showBanner();
    } else {
      this._hideBanner();
    }
  }

  /**
   * Replaces the eventPostcard header with a custom header
   */
  _drawHeader() {
    const container = new Phaser.Group(game, null);
    this._header = {
      container,
      titleAsset: G.makeImage(0, 0, G.OMTsettings.postcardEvent.titleAsset, 0.5, container),
    };

    if (Array.isArray(G.OMTsettings.postcardEvent.bannerColours)) {
      // EventPostcard_Util.redrawGradients(this._headerContainer); // Doesn't actually work
      // this._header.banner =
    } else {
      this._header.banner = G.makeImage(0, 0, G.OMTsettings.postcardEvent.bannerColours, [0.5, 0], container);
    }

    if (Array.isArray(G.OMTsettings.postcardEvent.titleColours)) {
      // this._header.title =
    } else {
      this._header.title = G.makeImage(0, 0, G.OMTsettings.postcardEvent.titleColours, 0.5, container);
    }
    const titleOffset = G.OMTsettings.postcardEvent.title.imageOffset;
    this._header.title.y = (titleOffset.y || 0) + this._header.banner.y + (this._header.banner.height * 0.8);
    this._header.title.x = titleOffset.x || 0;


    this._header.headerTitleRatio = this._header.title.width / this._header.banner.width;

    const offset = G.OMTsettings.postcardEvent.title.textOffset;
    this._header.titleText = new G.Text(offset.x || 0, this._header.title.y + (offset.y || 0), OMT.language.getText(G.OMTsettings.postcardEvent.cardTitle), 'eventPostcard-title', 0.5,
      this._header.title.width * c_headerTextUnderSize, this._header.title.height * c_headerTextUnderSize, true, 'center');
    container.addChild(this._header.titleText);

    return this._header.container;
  }

  /**
   * Overrides eventPostcard's reposition header, because the make up of the object is different
   */
  _repositionFrame() {
    this._header.banner.width = this._isLandscape ? game.width / this._gameScale : game.width;
    this._header.banner.y = G.OMTsettings.tokenEvent.postcard.header.banner.offsetY;
    if (stretchTitleBar) {
      this._header.title.width = game.width * this._header.headerTitleRatio;
    }
    this._header.titleAsset.x = this._header.title.x - this._header.title.width / 2;
    this._header.titleAsset.y = this._header.title.y;
    this._header.container.addChild(this._header.titleAsset);
    G.Text.setMaxWidth(this._header.titleText, this._header.title.width * c_headerTextUnderSize);
    this._header.titleText.setText(OMT.language.getText(G.OMTsettings.postcardEvent.cardTitle));

    this._header.container.y = this._isLandscape
      ? G.OMTsettings.tokenEvent.postcard.header.title.offsetY - (game.height / this._gameScale) / 2
      : G.OMTsettings.tokenEvent.postcard.header.title.offsetY - game.height / 2;
  }

  /**
   * super reposition, and repositions the friend banner
   */
  _onResize() {
    super._onResize();
    this._repositionFriendBanner();
  }

  /**
   * Repositions the friend banner to be somewhat up there
   */
  _repositionFriendBanner() {
    if (!this._friendBannerData) { return; }

    this._friendBannerData.bg.x = this._isLandscape
      ? (-game.width / this._gameScale) / 2
      : -game.width / 2;
    if (this._header && this._header.container) {
      this._friendBannerData.bg.y = this._header.container.y + this._header.container.height + 100;
    } else {
      this._friendBannerData.bg.y = this._isLandscape
        ? (-(game.height / 2) + 200) / this._gameScale
        : -(game.height / 2) + 200;
    }
  }

  /**
   * Check the UI using the util
   */
  _checkCard() {
    super._checkCard();
    this.addChild(this._header.container);
    this.addChild(this._closeBtn);
    if (this._arrows.right.pointerTween && this._arrows.right.pointerTween.stop) {
      this._arrows.right.pointerTween.stop();
      this._arrows.right.x = this._arrows.right.originX;
      this._arrows.right.pointerTween = null;
    }
  }

  /**
   * Give the gifts
   */
  async checkCardGifts() {
    if (this._currentCard.state === POSTCARD_STATE.UNLOCKED && !G.saveState.tokenEventManager.getPostcardIndexRewardedState(this._currentCard.index)) {
      this._horizontalPositioner.blinkOutUIElements({ // Hides UI
        left: this._arrows.left,
        right: this._arrows.right,
        close: this._closeBtn,
        tweenTime: 100,
      });

      // Award them with gifts
      const gifts = G.OMTsettings.postcardEvent.unlockData.unlockSendGift[this._currentCard.index];
      await this._currentCard.animateThanksSequence(gifts, this._applyGifts.bind(this));
      this._checkCard();
      this._promptNextClick(); // If theres a reason to go next, prompt it
    } else if (this._showGingy && this._currentCard.state === POSTCARD_STATE.LOCKED && this._currentCard.index === 1) {
      this._showGingy = false;
      this._currentCard.animateGingySequence();
    }
  }

  /**
   * If there is reason to, animate the right arrow to point ->
   */
  _promptNextClick() {
    if (this._currentCard.nextCard // Exists
      && !G.saveState.tokenEventManager.getPostcardIndexRewardedState(this._currentCard.nextCard.index) // Not redeemed
      && this._currentCard.nextCard.state === POSTCARD_STATE.UNLOCKED // Is unlocked
      && !this._arrows.right.pointerTween) { // Tween isn't running
      this._arrows.right.pointerTween = game.add.tween(this._arrows.right).to({ x: this._arrows.right.originX - 10 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    }
  }

  /**
   * Stops all friend banner tweens
   */
  _stopAllFriendTweens() {
    for (const tw in this._allTweens) {
      if (tw && tw.stop) {
        tw.stop();
      }
    }
    this._allTweens.length = 0;
  }

  /**
   * Fades out banner, refils it, and fades it in
   */
  _cycleNewBanner() {
    this._stopAllFriendTweens();
    if (!this._friendBannerData) { return; }
    const tw1 = game.add.tween(this._friendBannerData.bg).to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
    tw1.onComplete.addOnce(this._refillBanner.bind(this));

    const tw2 = game.add.tween(this._friendBannerData.bg).to({ alpha: 1 }, 200, Phaser.Easing.Sinusoidal.InOut, false);
    tw2.onComplete.addOnce(() => { this._friendBannerData.bg.alpha = 1; });

    tw1.chain(tw2);
    this._allTweens.push(tw1, tw2);
  }

  /**
   * Fades out the banner
   */
  _hideBanner() {
    this._stopAllFriendTweens();
    if (!this._friendBannerData) { return; }
    const tw = game.add.tween(this._friendBannerData.bg).to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
    this._allTweens.push(tw);
  }

  /**
   * Fades in the friend banner
   */
  _showBanner() {
    this._stopAllFriendTweens();
    if (!this._friendBannerData) { return; }
    const tw2 = game.add.tween(this._friendBannerData.bg).to({ alpha: 1 }, 200, Phaser.Easing.Sinusoidal.InOut, false);
    tw2.onComplete.addOnce(() => { this._friendBannerData.bg.alpha = 1; });
    this._allTweens.push(tw2);
  }

  /**
   * Refils the friend banner
   */
  _refillBanner() {
    const newFriend = ArrayUtil.getRandomElement(this._friendData); // Gets new friend
    G.getExtTexture(newFriend.image, this._reapplyFriendAvatar.bind(this)); // Swaps avatar group with new friend avatar

    this._friendBannerData.avatarGroup.x = this._friendBannerData.bg.width * 0.08;
    this._friendBannerData.avatarGroup.y = -5; // Shadow pixel height / 2

    // Determine text
    let targetText = '%FRIEND% and +%INDEX% friends unlocked this postcard'; // Assuming locked card
    if (this._currentCard.state === POSTCARD_STATE.ENDCARD) {
      targetText = '%FRIEND% and +%INDEX% friends unlocked all postcards'; // Actually end card
      if (this._friendData.length < this._currentCard.index) {
        targetText = '%FRIEND% has unlocked all postcard'; // Actually no friends
      }
    } else if (this._friendData.length < this._currentCard.index) { // Locked card and no friends
      targetText = '%FRIEND% has unlocked this postcard';
    }
    targetText = OMT.language.getText(targetText);
    targetText = targetText.replace('%FRIEND%', newFriend.name); // Replace accordingly
    // Mininum is the friend length or current index. Then pixed the min or the friend length - current card index
    targetText = targetText.replace('%INDEX%', Math.max(Math.min(this._friendData.length, this._currentCard.index), this._friendData.length - this._currentCard.index));
    this._friendBannerData.text.setText(targetText);
    this._friendBannerData.text.x = this._friendBannerData.avatarGroup.x + 5 + this._friendBannerData.avatarGroup.width / 2;
  }

  /**
   * Applies texture to the image
   * @param {PIXI.Texture} imageTexture
   */
  _reapplyFriendAvatar(imageTexture) {
    G.changeTexture(this._friendBannerData.avatar, imageTexture);
    this._friendBannerData.avatar.scale.set(1);
    this._friendBannerData.avatar.scale.set((this._friendBannerData.frame.width / 1.15) / this._friendBannerData.avatar.width);
  }

  /**
   * Does the left tween functionality but also cycles in the friend banner or not
   */
  _afterLeftTween() {
    if (this._currentCard.cancelAnim) { this._currentCard.cancelAnim(); }
    super._afterLeftTween();
    this.checkCardGifts();
    if (this._currentCard.state !== POSTCARD_STATE.UNLOCKED) {
      if (this._friendBannerData) {
        this._cycleNewBanner();
        this.addChild(this._friendBannerData.bg); // Keep it on top of the card
      }
    } else {
      this._hideBanner();
    }
    this.addChild(this._header.container);
    this.addChild(this._closeBtn);
  }

  /**
   * Does the right tween functionality but also cycles in the friend banner or not
   */
  _afterRightTween() {
    if (this._currentCard.cancelAnim) { this._currentCard.cancelAnim(); }
    super._afterRightTween();
    this.checkCardGifts();
    if (this._currentCard.state !== POSTCARD_STATE.UNLOCKED) {
      if (this._friendBannerData) {
        this._cycleNewBanner();
        this.addChild(this._friendBannerData.bg); // Keep it on top of the card
      }
    } else {
      this._hideBanner();
    }
  }

  /**
   * Overrides the card limit. Determines the max unlocked card limit
   */
  get cardLimit() {
    return Math.min(G.saveState.tokenEventManager.currentRewardTier, G.OMTsettings.postcardEvent.unlockData.currencyUnlock.length - 1);
  }

  /**
   * Overrides the original event Postcard with the unlockable post card.
   * @param {number} index
   * @returns {UnlockablePostcard}
   */
  _createCard(index) {
    const { avatar } = OMT.envData.settings.user;
    let postcardState = POSTCARD_STATE.LOCKED;
    if (index === this.cardLimit) {
      if (G.saveState.tokenEventManager.totalTokensRequiredForPostcard < 1) {
        postcardState = POSTCARD_STATE.ENDCARD;
      } else {
        postcardState = POSTCARD_STATE.LOCKED;
      }
    } else {
      postcardState = POSTCARD_STATE.UNLOCKED;
    }
    const targetHeight = postcardState === POSTCARD_STATE.UNLOCKED ? game.height * 0.53 : game.height * 0.49;
    const card = new UnlockablePostcard({ width: 480, height: targetHeight });
    const cardData = {
      state: postcardState,
      index,
      cardCaption: G.OMTsettings.postcardEvent.cardData.cardCaption,
    };
    card.fillCard(cardData, avatar);

    if (!card.signals.onClick.has(this._onButtonClick, this)) { // Hook up listener
      card.signals.onClick.add(this._onButtonClick, this);
    }

    return card;
  }

  /**
   * Does different stuff when the button is clicked, depending on card state
   * @param {UnlockablePostcard} postcard
   */
  async _onButtonClick(postcard) {
    if (postcard.state !== POSTCARD_STATE.UNLOCKED) {
      if (!this._returnArgs) {
        G.sb('pushWindow').dispatch(['eventLevel', G.saveState.getEventLevel(false)]);
      }
      this.closeWindow();
    } else {
      // Something
      this._horizontalPositioner.blinkOutUIElements({ // Hides UI
        left: this._arrows.left,
        right: this._arrows.right,
        close: this._closeBtn,
        tweenTime: 100,
      });
      const result = await OMT.social.shareEventPostcard(postcard.index);
      if (result) {
        // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('usedPostcardShareBtn', 1);

        const pause = 500;
        game.time.events.add(pause, () => {
          if (this._currentCard.nextCard) {
            this.onRightClick(true);
          } else {
            this._checkCard();
          }
        });
      } else {
        this._checkCard();
      }
    }
  }

  /**
   * Applies gifts
   * @param {Array<{prize:string, amount:number}>} prize
   */
  _applyGifts(prize) {
    const trackerArray = [];
    OMT.transactionTracking.logInventoryTransactionBegin();

    let coinsPosition = null;
    try {
      coinsPosition = this.state.panel.coinsTxt;
    } catch (err) {
      // Probably not in the saga map state. Cannot find the coins text. Spoof in a location where the coins text would have been.
      // Also probably doesn't look good on wide screen tablets
      coinsPosition = {
        worldPosition: new Phaser.Point(game.world.bounds.x + game.width * 0.8, game.height * 0.05),
      };
    }

    for (let i = 0; i < prize.length; i++) {
      const curPrize = prize[i];
      trackerArray.push([curPrize.prize, curPrize.amount]); // Puts into an array for data tracking

      switch (curPrize.prize.toLowerCase()) {
        case 'coin':
          this.state.uiTargetParticles.createCoinBatch( // Show bling
            game.world.bounds.x + this._currentCard.worldPosition.x,
            this._currentCard.worldPosition.y,
            coinsPosition,
            curPrize.amount,
            false,
          );
          OMT.transactionTracking.addInventoryChange('coins', 0, curPrize.amount);
          break;
        case 'life':
          G.saveState.addLife(curPrize.amount); // Add life
          break;
        case 'lifeunlimited':
          G.saveState.addUnlimitedLivesTimeMin(curPrize.amount); // Add unlimited lives
          break;
        default: { // Perhaps bad, but default is assuming booster
          const boosterIndex = parseInt(curPrize.prize[8], 10);
          G.saveState.changeBoosterAmount(boosterIndex, curPrize.amount); // Add in booster
          OMT.transactionTracking.addInventoryChange('boostersReceived', boosterIndex, curPrize.amount);
          break;
        }
      }
    }

    // DDNA.transactionHelper.trackRewards(trackerArray, [], { // DDNA tracking
    //   transactionType: 'REWARD',
    //   tActionType: 'EVENT_REWARD',
    //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
    // });
    OMT.transactionTracking.logInventoryTransactionEnd();
    G.saveState.tokenEventManager.save();
    G.saveState.save(); // Save!
    G.saveState.tokenEventManager.setPostcardIndexAsRewarded(this._currentCard.index);
  }

  /**
   * Resumes calls on the map then closes itself
   */
  closeWindow() {
    if (this._currentCard.cancelAnim) { this._currentCard.cancelAnim(); }
    super.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.eventPostcard = Window_unlockPostcard;
