import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';

const width = 640;
const height = 1242;

const convertToPercentageX = (x) => (x / width) * 100;
const convertToPercentageY = (y) => (y / height) * 100;

const yOffset = 52;

export default [
  {
    texture: OMT_VILLAINS.getPrefixedName('villain_1_1'),
    landscape: {
      baseWidth: width,
      baseHeight: height,
      scaleCoeff: {
        scale: 1.5,
      },
    },
    destroyOnComplete: true,
    transforms: [
      // Initial transform
      {
        alpha: 1,
        x: convertToPercentageX(91),
        y: convertToPercentageY(186 + yOffset),
        callback: (object) => {
          object.scale.setTo(0.45);
          object.scale.x *= -1;
        },
      },
      {
        wait: 1000,
        callback: () => {
          G.sfx.exchange.play();
        },
      },
      {
        x: convertToPercentageX(270),
        y: convertToPercentageY(133 + yOffset),
        duration: 100,
      },
      {
        x: convertToPercentageX(450),
        y: convertToPercentageY(186 + yOffset),
        duration: 100,
      },
      {
        x: convertToPercentageX(718),
        y: convertToPercentageY(136 + yOffset),
        duration: 100,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('villain_2_1'),
    landscape: {
      baseWidth: width,
      baseHeight: height,
      scaleCoeff: {
        scale: 1.5,
      },
    },
    destroyOnComplete: true,
    transforms: [
      // Initial transform
      {
        alpha: 1,
        x: convertToPercentageX(544),
        y: convertToPercentageY(148 + yOffset),
        callback: (object) => {
          object.scale.setTo(0.45);
        },
      },
      {
        wait: 1000,
        callback: (object) => {
          object.scale.x *= -1;
        },
      },
      {
        x: convertToPercentageX(600),
        y: convertToPercentageY(94 + yOffset),
        duration: 100,
      },
      {
        x: convertToPercentageX(718),
        y: convertToPercentageY(148 + yOffset),
        duration: 100,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('super_hard_corruption'),
    destroyOnComplete: true,
    landscape: {
      scaleCoeff: {
        scale: 1.6,
      },
    },
    transforms: [
      {
        x: convertToPercentageX(336.5),
        y: convertToPercentageY(663),
        scale: 4,
      },
      {
        x: convertToPercentageX(927),
        y: convertToPercentageY(1638),
        duration: 200,
      },
    ],
  },
];
