import { TextTimer } from '../../TextTimer';
import UI_ShopIcon from '../Shop3/UI_ShopIcon';

export class UI_EventPostcard extends UI_ShopIcon {
  /**
   * Opens shop or more postcards depending on environment
   */
  openCoinShop() {
    if (!G.IAP) {
      if (G.saveState.isMsgRead(G.OMTsettings.postcardEvent.eventId)) {
        G.sb('pushWindow').dispatch('eventPostcard', false, G.WindowMgr.LayerNames.Base);
      } else {
        G.sb('pushWindow').dispatch('eventPostcardPromo', false, G.WindowMgr.LayerNames.Base);
      }
    } else {
      G.sb('pushWindow').dispatch(['coinShop', { freeGift: true }]);
    }
  }

  /**
   * Creates the timer object. Can be overwritten
   */
  makeTimerObject() {
    return new TextTimer({
      x: -5,
      y: 10,
      style: {
        style: 'font-num-blue',
        fontSize: 30,
      },
      anchor: 0.5,
      maxWidth: this._container.width
    });
  }
}
