let _instance = null;

/**
 * Class for tracking multi-step async initialization status
 */
export class InitFlowStatusTracker {
  /**
   * get static instance
   * @returns {InitFlowStatusTracker}
   */
  static getInstance() {
    if (!_instance) _instance = new InitFlowStatusTracker();
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    this._assetsLoaded = false;
    this._omtServicesStarted = false;
    this._levelDataReady = false;
  }

  /**
   * flag assets as loaded
   */
  setAssetsLoaded() {
    this._assetsLoaded = true;
  }

  /**
   * flag OMT services as started
   */
  setOMTServicesStarted() {
    this._omtServicesStarted = true;
  }

  /**
   * flag level data ready
   */
  setLevelDataReady() {
    this._levelDataReady = true;
  }

  /**
   * true if we are ready to continue on with game initialization
   */
  isReadyToStartupGame() {
    return this._assetsLoaded && this._omtServicesStarted && this._levelDataReady;
  }
}
