/**
 * banner with time display used for special event sales in the shop
 */
export default class Shop3_CornerBanner extends Phaser.Group {
  /**
   * constructor
   * @param {number} saleEndTime
   * @param {{assetImage:string}} config
   */
  constructor(config) {
    super(game);
    this._config = config;

    this._loadSettings();

    this._beginDrawing(this._config.assetImage);
  }

  /**
   * Loads settings
   */
  _loadSettings() {
    this._width = 230;
    this._height = 60;
    this._iconSize = 50;
    this._textPadding = 8;
    this._textOffsetY = G.OMTsettings.specialShopDeal.saleBannerText.offsetY;
  }

  /**
   * Draws everything required
   */
  _beginDrawing(image) {
    // this._drawBgRect();
    this._initIcon(image);
  }

  /**
   * draw bg rect for placement / debugging
   */
  _drawBgRect() {
    const bg = new Phaser.Graphics(game);
    bg.beginFill(0x00FF00, 1);
    bg.drawRect(0, 0, this._width, this._height);
    bg.endFill();
    this.addChildAt(bg, 0);
    this._bg = bg;
  }

  /**
   * initialize the sale icon
   */
  _initIcon(image) {
    const icon = G.makeImage(0, 0, image, 0.5, null);
    icon.width = this._iconSize;
    icon.scale.y = icon.scale.x;
    icon.x = icon.y = this._height / 2;
    this.addChild(icon);
  }

  /**
   * @returns {number}
   */
  get width() {
    return this._width;
  }
}
