import { SPECIAL_TYPES } from '@omt-game-board/Board/BoardConstants';
import { MILLISECONDS_IN_DAY, MILLISECONDS_IN_MIN, MILLISECONDS_IN_WEEKS } from '@omt-components/Utils/TimeUtil';

G.OMTsettingsDefault = {
  /* configuration for Delta DNA, comment out block or remove to disable */
  /*
  DDNA: {
    collect: 'https://collect15042cndyr.deltadna.net/collect/api/',
    engage: 'https://engage15042cndyr.deltadna.net/',
    env: G.BuildEnvironment.production ? '99892253560587544228437458715538' : '99892245470978161777838279315538',
    queueInterval: 10000,
    ftuxVersion : 1 // this should be a integer
  },
  */

  shoutouts: {
    amountTier0: 3,
    amountTier1: 2,
  },

  cascadeSkipSettings: {
    specialCandyValues: {
      [SPECIAL_TYPES.CROSS]: 150,
      [SPECIAL_TYPES.HORIZONTAL]: 70,
      [SPECIAL_TYPES.VERTICAL]: 70,
      [SPECIAL_TYPES.SPIRAL]: 150,
      [SPECIAL_TYPES.RANDOM_LINE]: 0,
    },
  },

  targetedOffers: {
    // STANDARD OFFERS
    NON_PAYER_OOM: {
      productID: 'target_6k',
      maxSessionViewCount: 3,
      isTimedOffer: false,
      minLevel: 21,
      coinChance: [{ maxCoins: 4000, probability: 1 }],
      oomViewChance: [
        0.5,
        0.75,
        0.88,
        0.95,
        1,
      ],
    },
    NON_PAYER_FAIL: {
      productID: 'target_12k_b8_2_b1_2',
      maxSessionViewCount: 1,
      isTimedOffer: true,
      purchaseCooldown: 24 * MILLISECONDS_IN_DAY,
      timeLimit: 15 * MILLISECONDS_IN_MIN,
      highPriority: false,
      minLevel: 21,
      coinChance: [
        { maxCoins: 3000, probability: 1 },
        { maxCoins: 5000, probability: 0.5 },
        { maxCoins: 10000, probability: 0.3 },
      ],
    },
    NON_PAYER_FIRST_GATE: {
      productID: 'target_8k_il_6',
      isTimedOffer: true,
      timeLimit: 15 * MILLISECONDS_IN_MIN,
      highPriority: true,
      minLevel: 21,
      maxSessionViewCount: 1,
    },
    NON_PAYER_LAST_X_SESSIONS: {
      productID: 'target_5k_b7_1_b4_1',
      isTimedOffer: true,
      timeLimit: 15 * MILLISECONDS_IN_MIN,
      purchaseCooldown: 24 * MILLISECONDS_IN_DAY,
      highPriority: false,
      maxSessionViewCount: 1,
      minLevel: 21,
      sessionsSinceLastTargetedOffer: 3,
    },
    PAYER_OOM: {
      productID: 'target_12k',
      maxSessionViewCount: 1,
      isTimedOffer: false,
      minLevel: 21,
      coinChance: [{ maxCoins: 4000, probability: 1 }],
      oomViewChance: [
        0.5,
        0.75,
        0.88,
        0.95,
        1,
      ],
    },
    PAYER_LAST_X_DAYS: {
      maxSessionViewCount: 1,
      isTimedOffer: true,
      timeLimit: 15 * MILLISECONDS_IN_MIN,
      highPriority: false,
      minLevel: 21,
      gameStartedChance: 0.5,
      daysSinceLastPurchase: {
        enforceProductID: false,
        chance: [
          { days: 5, probability: 0.75 },
          { days: 6, probability: 1 },
        ],
      },
      targetProducts: [
        { // Group 1
          productID: 'target_60k_b1_6_b2_6_b3_6_b4_6',
          closeCooldown: 7 * MILLISECONDS_IN_DAY,
          purchaseCooldown: 1,
          conditions: [
            {
              daysSinceLastPurchase: {
                chance: [
                  { days: 30, probability: 1 },
                ],
              },
              coinChance: [{ maxCoins: 5000, probability: 1 }],
              boosterChance: 4,
              preBoosterChance: 9,
              purchaseAmount: 20,
            },
          ],
        },
        { // Group 2.1
          productID: 'target_40k_b1_4_b2_4_b3_4_b4_4',
          closeCooldown: 7 * MILLISECONDS_IN_DAY,
          purchaseCooldown: 1,
          conditions: [
            {
              daysSinceLastPurchase: {
                chance: [
                  { days: 30, probability: 1 },
                ],
              },
              coinChance: [{ maxCoins: 4000, probability: 1 }],
              boosterChance: 4,
              preBoosterChance: 9,
              purchaseAmount: 10,
            },
          ],
        },
        { // Group 2.2
          productID: 'target_15k_b1_3_b2_3_b3_3_b4_3',
          closeCooldown: 7 * MILLISECONDS_IN_DAY,
          purchaseCooldown: 1,
          conditions: [
            {
              daysSinceLastPurchase: {
                chance: [
                  { days: 30, probability: 1 },
                ],
              },
              coinChance: [{ maxCoins: 4000, probability: 1 }],
              boosterChance: 4,
              preBoosterChance: 9,
              purchaseAmount: 1,
            },
          ],
        },
        { // Default. Always last
          productID: 'target_20k_b7_3_b3_3_b4_3_il_3',
          conditions: [
            {
              purchaseAmount: 1,
            },
          ],
        },
      ],
    },
    PAYER_LAST_X_SESSIONS: {
      productID: 'target_6k_b1_2_b2_2_b3_1',
      isTimedOffer: true,
      timeLimit: 15 * MILLISECONDS_IN_MIN,
      purchaseCooldown: 24 * MILLISECONDS_IN_DAY,
      highPriority: false,
      maxSessionViewCount: 1,
      minLevel: 21,
      sessionsSinceLastTargetedOffer: 4,
    },
    // EVENT OFFERS
    NON_PAYER_TOKENEVENT: {
      gameStartedChance: 1,
      isTimedOffer: true,
      productID: 'oktoberfestfirst_5k_b1_2_b7_2_il_1',
      windowCustomizations: 'G.OMTsettings.tokenEvent.popupOfferLayout',
      maxSessionViewCount: 1,
      timeLimit: 15 * MILLISECONDS_IN_MIN,
      highPriority: false,
      minLevel: 21,
      daysSinceLastPurchase: {
        productIDFilters: ['stpatricktarget_30k_b3_7_b4_7_b8s_7_il_3'],
        chance: [
          { days: 3, probability: 1 },
        ],
      },
    },
    PAYER_TOKENEVENT: {
      gameStartedChance: 1,
      isTimedOffer: true,
      productID: 'oktoberfest_50k_b8_6_b2_6_b4_6_il_3',
      windowCustomizations: 'G.OMTsettings.tokenEvent.popupOfferLayout',
      maxSessionViewCount: 1,
      timeLimit: 15 * MILLISECONDS_IN_MIN,
      highPriority: false,
      minLevel: 21,
      daysSinceLastPurchase: {
        productIDFilters: ['stpatricktarget_30k_b3_7_b4_7_b8_7_il_3'],
        chance: [
          { days: 3, probability: 1 },
        ],
      },
    },
  },

  /* configuration for tournaments */
  tournaments: {
    levelLoop: ['tournament_1'],
    rotationOriginTimestamp: 1589155200000, // unix timestamp (1589155200000 is Monday 00:00:00, May 11th, 2020)
    rotationIntervalHrs: 24 * 7, // total hours for level rotation including the cooldown
    cooldownDurationHrs: 24 * 2, // hours at beginging of rotation
    worldMapIcon: {
      offsetY: -10,
      height: 110,
      width: 150,
    },
  },

  tournamentPromo: {
    bannerGradient: ['#ff35bb', '#f00082'],
  },

  /* transaction tracking */
  transactionTracking: {
    // Divide timestamps by this number to reduce their storage size
    timestampScale: 60 * 1000, // 1 minute
    // Minimum time to wait between taking snapshots
    snapshotInterval: 24 * 60, // 1 day

    // Set to -1 to disable
    transactionExpirationTimes: {
      ss: 14 * 24 * 60, // snapshots, 14 days
      rm: -1, // real money, never
      inv: 3 * 24 * 60, // in-game inventory changes, 3 days
      crmInv: -1, // CRM inventory changes, never
    },
  },

  postcardEvent: { // Data for the postcard event
    eventId: 'easter2022', // Event ID for the developer message. Stored in saveState under eventMsg
    eventGift: [{ prize: 'coin', amount: 500 }], // The gift from the developer
    cardTitle: 'Easter', // String for the title
    titleAsset: null, // The asset next to the title banner
    bannerColours: 'eventPostcardHeader', // ['#FFBFDA', '#FDA9CD'],
    titleColours: 'eventPostcardTitle', // ['#FFFFFF', '#FDEFCB'],
    messageSubtext: 'You received a Easter postcard! Let\'s open it!', // The text that is sent underneath the postcard in FB Message
    xButton: 'btn_x',
    title: {
      textOffset: { x: 0, y: 0 },
      imageOffset: { x: 0, y: 0 },
    },
    unlockData: {
      lockedImage: 'tokenEventLocked',
      lockHint: 'Collect %CURRENCY% in levels to unlock exclusive postcards!',
      imageOffset: { x: 0, y: -10 },
      currencyUnlock: [0, 10, 25, 60, 100, 150, 300, 500, 1000, 2200, 2200],
      unlockSendGift: [
        [{ prize: 'coin', amount: 100 }],
        [{ prize: 'coin', amount: 200 }],
        [{ prize: 'lifeUnlimited', amount: 30 }],
        [{ prize: 'coin', amount: 600 }],
        [{ prize: 'booster#7', amount: 1 }],
        [{ prize: 'booster#2', amount: 1 }],
        [{ prize: 'booster#4', amount: 1 }],
        [{ prize: 'booster#3', amount: 1 }, { prize: 'lifeUnlimited', amount: 60 }],
        [{ prize: 'booster#4', amount: 1 }, { prize: 'booster#3', amount: 1 }, { prize: 'booster#1', amount: 1 }],
        [{ prize: 'coin', amount: 5000 }, { prize: 'lifeUnlimited', amount: 30 }, { prize: 'booster#4', amount: 2 }, { prize: 'booster#8', amount: 1 }],
      ],
    },
    cardData: {
      avatarFrame: 'tokenEventAvatarFrame',
      avatarFrameOffset: {
        x: -27,
        y: 20,
      },
      endCardTextColor: '#2D97FF',
      cardCaption: 'Share a postcard with your friend for the holidays', // String for the caption
      promoCaption: 'Happy Easter!', // Developer's message
      imageOffset: { x: 0, y: 10 },
      postcardData: [
        { image: 'img/eventPostcard/tokenEvent/postcardImage1.png', shareImage: 'img/eventPostcard/eventPostcard1.png', speechText: 'Happy Easter!' },
        { image: 'img/eventPostcard/tokenEvent/postcardImage2.png', shareImage: 'img/eventPostcard/eventPostcard2.png', speechText: 'Happy Easter!' },
        { image: 'img/eventPostcard/tokenEvent/postcardImage3.png', shareImage: 'img/eventPostcard/eventPostcard3.png', speechText: 'Happy Easter!' },
        { image: 'img/eventPostcard/tokenEvent/postcardImage4.png', shareImage: 'img/eventPostcard/eventPostcard4.png', speechText: 'Happy Easter!' },
        { image: 'img/eventPostcard/tokenEvent/postcardImage5.png', shareImage: 'img/eventPostcard/eventPostcard5.png', speechText: 'Happy Easter!' },
        { image: 'img/eventPostcard/tokenEvent/postcardImage6.png', shareImage: 'img/eventPostcard/eventPostcard6.png', speechText: 'Happy Easter!' },
        { image: 'img/eventPostcard/tokenEvent/postcardImage7.png', shareImage: 'img/eventPostcard/eventPostcard7.png', speechText: 'Happy Easter!' },
        { image: 'img/eventPostcard/tokenEvent/postcardImage8.png', shareImage: 'img/eventPostcard/eventPostcard8.png', speechText: 'Happy Easter!' },
        { image: 'img/eventPostcard/tokenEvent/postcardImage9.png', shareImage: 'img/eventPostcard/eventPostcard9.png', speechText: 'Happy Easter!' },
        { image: 'img/eventPostcard/tokenEvent/postcardImage10.png', shareImage: 'img/eventPostcard/eventPostcard10.png', speechText: 'Happy Easter!' },
      ],
      speechBubble: {
        slice: {
          top: 29,
          bottom: 27,
          left: 41,
          right: 30,
        },
      },
      extraAssets: [
        // { asset: 'tokenEventLeaf', anchor: 'bottom_left', offset: { x: 20, y: -30 } },
        // { asset: 'tokenEventStamp', anchor: 'top_right', offset: { x: -25, y: 20 } },
        // { asset: 'tokenEventBanner', anchor: 'top_left', offset: { x: 14, y: 10 } },
      ],
    },
    shop: {
      firstTimeBuyerKey: 'first',
      popupKey: 'popup',
      background: 'specialdeal_bg',
      chest: 'shop3_specialDeal1',
      coinbar: 'shop3_dealCoinBar',
      specialPopupOffer: 'Easter special offer',
    },
    worldMapIcon: {
      width: 150,
      height: 150,
    },
  },

  tokenEvent: {
    DDNAEventID: 'EASTER',
    eventTokens: {
      dropChance: 5,
      pcParam: 'easterTokensCollected', // player characterization parameter in DDNADataCapture
    },
    gameBackgroundAsset: 'tokenEvent_background',
    boardTokenAsset: 'tokenEvent_token',
    // List of level numbers for the event
    levelList: [], // Populate in game repo
    mapIcon: {
      icon: 'postcardCurrencyBig',
      width: 150,
      height: 120,
      layout: {
        bg: {
          x: 0,
          y: 0,
        },
        token: {
          size: 89,
          x: 0,
          y: 0,
        },
        timerBanner: {
          x: 0,
          y: 0,
        },
        timerText: {
          x: 12,
          y: 51,
        },
      },
    },
    // configuration for special event leaderboard
    leaderboardConfig: {
      leaderboard: {
        name: 'specialEvents',
        instanceId: 'easter-2022',
        entryLimit: 6,
      },
      header: {
        scale: 0.75,
        bg: { asset: 'eventPostcardHeader' },
        titleBg: { x: 0, y: -20, asset: 'eventPostcardTitle' },
        title: {
          x: 0, y: 93, baseStyle: 'tokenEvent-eventLevelTitle', fontSize: '65px', maxWidth: 370, maxHeight: 100, text: 'Easter',
        },
        subTitleBg: { x: 0, y: 163, asset: 'leaderboard-subheader-bar' },
        subTitle: {
          x: 0, y: 163, baseStyle: 'font-white', fontSize: '45px', maxWidth: 370, maxHeight: 80, text: 'Leaderboard',
        },
        // overlayImage: { x: -215, y: 85, asset: 'postcard_titleAsset' },
      },
      collectMsg: {
        text: 'Collect %CURRENCY% in levels to unlock exclusive postcards!',
        currency: 'Pumpkins',
        fieldConfig: {},
      },
      nullNameReplacements: ['Bubbles', 'Finn', 'Dolly', 'Shelly', 'Spike'], // these are in localizations
      tabs: {
        dividerStroke: { thickness: 3, color: 0xFF9C00, alpha: 1 },
        buttonGraphics: { selected: 'event-leaderboard-tab-on', unselected: 'event-leaderboard-tab-off' },
        buttonSpacing: 20,
      },
      panel: {
        userView: {
          icon: {
            asset: 'postcardCurrencyBig',
            size: 70,
            offsetY: 0,
          },
          scoreField: {
            fontStyle: {
              fill: '#ae2501',
              style: 'font-white',
            },
          },
          usernameField: {
            fontStylePlayer: {
              // fontSize: '50px',
              style: 'font-orange',
              // fill: '#41f52a',
            },
          },
        },
      },
      closeBtn: {
        common: {
          topOffset: 45, scale: 0.9, asset: 'btn_x',
        },
        vertical: { rightOffset: -40 },
        horizontal: { rightOffset: -40 },
      },
    },
    shopData: {
      shopKey: 'easter', // Used for finding out which pack base to get from the catalog
      layoutKey: 'easter', // Used for arranging certain special packs from the layout pack
      firstTimeBuyerKey: 'first',
      coinBarTint: 0x93001A,
      coinBarTextStyle: 'shop3-boosterTextStyleTokenEvent',
      valueTextMultiplier: 0.9,
      specialDealSlots: 5,
      boosterBox: {
        asset: 'shop3_specialDealBoosterBox',
        coinBar: {
          tint: 0x1E88FF,
          textStyle: 'shop3-boosterTextStyle3',
        },
      },
    },
    popupOfferLayout: {
      showTargetOffer: true, // Show offers at all?
      deal: {
        assetsKeys: {
          NON_PAYER_TOKENEVENT: 'tokenEvent_offer_small',
          PAYER_TOKENEVENT: 'tokenEvent_offer_big',
        },
      },
      bg: {
        height: 550,
        outerBgAsset: 'tokenEvent_popup_background',
        outerSlices: null, // background not 9-sliced
        innerOffsetX: -20,
        innerOffsetY: 45,
        innerFrameVisible: false, // we don't have a inner frame for this design
      },
      header: {
        offsetY: 20,
        ribbonOffsetY: 5,
        textOffsetY: 25,
        ribbonConfig: {
          asset: 'tokenEvent_banner',
          sliceRect: null,
          height: 175,
        },
        textConfig: {
          text: 'Exclusive Offer',
          style: { style: 'font-white', fontSize: '40px' },
          maxWidth: 240,
        },
      },
      multiplier: {
        offsetY: 52,
        offsetX: -30,
        scale: 0.8,
        bgScale: 1.05,
        txt: { offsetX: 1, offsetY: 1 },
        altBgAsset: 'tokenEvent-offer-multiplier-bg',
      },
      dealCoinbar: {
        tint: 0xff0000,
      },
      playerCoinbar: {
        offsetY: -265,
        scale: 0.8,
      },
      exitConfirm: {
        mascot: { offsetX: -10000 }, // mascot offscreen doesn't fit the layout
        bubble: { offsetX: 0, offsetY: -344 }, // bubble needs to be re-centered with mascot missing
      },
      buyButton: { offsetY: 15 },
    },
    styling: {
      windowTextFill: '#9C1603', // colour of text in level flow windows
      taskTextFill: '#9C1603', // Colour of the task text on the level window
    },
    window_eventLevel: {
      boosterBgWidth: false, // to overwrite the booster bg width on level window
    },
    // config for event window headers
    // (generally, any window that has text that uses 'tokenEvent-eventLevelTitle' style)
    windowHeader: {
      text: {
        x: 0,
        y: -280,
      },
    },
    postcard: {
      header: {
        banner: {
          offsetY: 0, // The long background
        },
        title: {
          offsetY: 0, // The container holding text
        },
      },
    },
    mainLeaderboard: {
      EXPANDED_ODD_ROW_COLOR: 0xFFF1E1,
      EXPANDED_EVEN_ROW_COLOR: 0xFAD0B6,
      PANEL_BG_FILL_COLOR: 0xFFF1E1,
    },
    gameBoard: { // The assets used on the game board
      topbar: { // The asset used for the top bar
        horizontal: 'horizontal-topBarNormal', // In horizontal mode
        horizontalOffset: { x: 0, y: 0 },
      },
      boosterBar: { // Asset used for the booster bar
        horizontal: 'horizontal-boosterbgNormal', // In horizontal mode
      },
      counter: { // Positioning config for stuff on the counter
        textOffset: { x: 0, y: 0 },
        tokenOffset: { x: 0, y: 0 },
        doublingOfset: { x: 0, y: 0 },
      },
    },
    windowEventEntry: {
      bgAsset: 'tokenEvent_popup_background',
      headerData: {
        asset: {
          x: 0,
          y: -295,
          assetString: 'tokenEvent_banner',
        },
        text: { // positioned in relation inside .asset (above)
          x: 0,
          y: 0,
          text: 'Easter',
          textStyle: 'tokenEvent-eventLevelTitle',
          width: 0.8, // Multiplier based on size of the asset above
          height: 0.8,
        },
      },
      bannerData: {
        asset: {
          x: 0,
          y: -80,
          assetString: 'tokenEventContentBanner',
        },
      },
      textData: {
        debug: false,
        text: [
          /* eslint-disable object-curly-newline */ // -213
          { x: 0, y: 71, string: 'Join the feast and celebrations while Easter is in town!', textStyle: 'tokenEvent-entryText' },
          { x: 0, y: 153, string: 'Collect %CURRENCY% to unlock postcards to recieve gifts!', textStyle: 'tokenEvent-entryText' },
          { x: 0, y: 238, string: 'Show off your skills on the leaderboards!', textStyle: 'tokenEvent-entryText' },
          /* eslint-enable object-curly-newline */
        ],
      },
      playButton: {
        x: 0,
        y: 0,
        text: 'Play',
      },
      mascot: {
        assetString: 'tokenEventMascotBig',
        offset: { x: 0, y: 0 },
        scale: {
          horizontal: 1.4,
          vertical: 1.2,
        },
        pos: {
          horizontal: { x: -510, y: 360 },
          vertical: { x: -230, y: 510 },
        },
      },
    },
  },

  treasureHuntSuper: {
    gameBackground: 'background_treasureHunt', // Game background asset
    // If the player hasn't played the treasure hunt in this amount of time,
    // don't send a bot message reminding them of when the next one will start
    maxIdleTime: 3 * MILLISECONDS_IN_WEEKS,
    levels: {
      useList: false,
      levelList: [],
      startingLevel: 395, // The first level ever on the treasure hunt.
    },
    reward: { // Reward stuff
      currencyUnlock: [15, 60, 300], // The amount needed to unlock. Used in displaying chests
      prizes: [ // Prizes per level unlocked
        [{ prize: 'coin', amount: 100 }, { prize: 'life', amount: 1 }],
        [{ prize: 'lifeUnlimited', amount: 30 }, { prize: 'booster#R', amount: 1 }],
        [{ prize: 'booster#R', amount: 1 }, { prize: 'badge' }, { prize: 'coin', amount: 3000 }],
      ],
      sfxFrameTiming: [24, 23, 30], // The frame which the img sequence plays a sound when redeeming prizes
    },
    mascotOrder: [ // Mascots shown on the treasure hunt
      'gingy',
      'ginger',
      'chip',
      'cookie',
      'nutmeg',
      'graham',
    ],
    worldMapIcon: { // World map icon stuff
      width: 150,
      height: 110,
      timerText: { // Timer text adjustment
        x: 13,
        y: 55,
      },
      timerPos: {
        x: 0,
        y: 54,
      },
      animation: [ // Animation works on COOK!
        /* eslint-disable object-curly-newline */
        { asset: 'treasure_hunt_active_layer1', x: 0, y: -6, scaleTween: { x: 1.08 }, time: 6000, scale: 1, delay: 250 },
        { asset: 'treasureHunt_token', x: -5, y: -5, tween: { y: -15 }, time: 6000, scale: 0.6, delay: 250 },
        { asset: 'treasureHunt_token', x: -25, y: -35, tween: { y: -45 }, time: 6000, scale: 0.5, delay: 700 },
        { asset: 'treasureHunt_token', x: 15, y: -40, tween: { y: -50 }, time: 6000, scale: 0.7, delay: 0 },
        { asset: 'treasureHunt_iconSparkle', x: 35, y: -50, tween: { y: -60 }, time: 6000, scale: 1, delay: 300 },
        { asset: 'treasureHunt_iconSparkle', x: -45, y: -40, tween: { y: -50 }, time: 6000, scale: 0.6, delay: 100 },
        { asset: 'treasureHunt_iconSparkle', x: -15, y: -55, tween: { y: -65 }, time: 6000, scale: 0.7, delay: 600 },
        { asset: 'treasureHunt_iconSparkle', x: 10, y: -10, tween: { y: -20 }, time: 6000, scale: 0.9, delay: 700 },
        { asset: 'treasureHunt_iconSparkle', x: 20, y: -5, tween: { y: -15 }, time: 6000, scale: 0.6, delay: 250 },
        { asset: 'treasureHunt_iconSparkle', x: 40, y: -40, tween: { y: -50 }, time: 6000, scale: 0.5, delay: 400 },
        /* eslint-enable object-curly-newline */
      ],
    },
    levelWindow: { // Level window stuff
      mascot: { // Mascot placed around here
        offsetX: -20,
        offsetY: -20,
      },
      timer: { // Timer placed around here
        text: { // Text placement adjustment
          x: 10,
          y: 3,
        },
      },
      buttons: {
        horizontal: {
          badge: { x: -200, y: 405 },
          leaderboard: { x: 200, y: 405 },
        },
        vertical: {
          badge: { x: 0, y: 405 },
          leaderboard: { x: 0, y: 495 },
        },
      },
    },
    updateWindow: { // Level window stuff
      left: {
        mascot: 0,
        offset: { x: -20, y: -20 },
        scale: {
          horizontal: 2,
          vertical: 1.5,
        },
        pos: {
          horizontal: { x: -510, y: 360 },
          vertical: { x: -230, y: 510 },
        },
      },
      right: {
        mascot: 1,
        offset: { x: -20, y: -20 },
        scale: 1.8,
        pos: { x: 510, y: 360 },
      },
    },
    leaderboardConfig: {
      avatar: {
        framePlayer: 'treasureHunter_leaderboard_playerAvatar',
        frameOther: 'treasureHunter_leaderboard_otherAvatar',
      },
      leaderboard: {
        name: 'treasure-hunt',
        entryLimit: 50,
      },
      header: {
        scale: 0.75,
        bg: { asset: 'treasureHunt_leaderboard_header' },
        titleBg: {
          x: 0, y: 3, asset: 'treasureHunt_banner', scale: 1.3,
        },
        title: {
          x: 0, y: 115, baseStyle: 'font-white', fontSize: '65px', maxWidth: 370, maxHeight: 100, text: 'Treasure Hunt',
        },
        subTitleBg: { x: 0, y: 210, asset: 'treasureHunt_leaderboardSubtitle' },
        subTitle: {
          x: 0, y: 210, baseStyle: 'font-white', fontSize: '45px', maxWidth: 370, maxHeight: 80, text: 'Leaderboard',
        },
        // overlayImage: { x: -215, y: 85, asset: 'postcard_titleAsset' },
      },
      collectMsg: {
        fieldConfig: {
          topOffset: 810,
        },
      },
      nullNameReplacements: ['Bubbles', 'Finn', 'Dolly', 'Shelly', 'Spike'], // these are in localizations
      tabs: {
        dividerStroke: { thickness: 3, color: 0xFB3489, alpha: 1 },
        buttonGraphics: { selected: 'treasurehunt_leaderboard_tabOn', unselected: 'treasurehunt_leaderboard_tabOff' },
        buttonSpacing: 20,
      },
      panel: {
        topOffset: 180,
        userView: {
          icon: {
            asset: 'treasureHunt_token',
            size: 70,
            offsetY: -3,
          },
          rankField: {
            bg: {
              assetPlayer: 'treasureHunt_leaderboard_RankPlayerTab',
              assetOther: 'treasureHunt_leaderboard_rankOtherTab',
            },
          },
          scoreField: {
            bg: {
              assetPlayer: 'treasureHunt_leaderboard_scorePlayer',
              assetOther: 'treasureHunt_leaderboard_scoreOther',
            },
            fontStyle: {
              fill: '#ffffff',
              style: 'font-white',
            },
          },
          usernameField: {
            fontStylePlayer: {
              // fontSize: '50px',
              style: 'font-orange',
              // fill: '#41f52a',
            },
          },
        },
        userViewDivider: {
          asset: 'treasureHunt_leaderboard_userDivider',
        },
      },
      closeBtn: {
        common: {
          topOffset: 45, scale: 0.9, asset: 'btn_x',
        },
        vertical: { rightOffset: -40 },
        horizontal: { rightOffset: 220 },
      },
      playBtn: {
        topOffset: 900,
        placementTopOffset: 900, // Offset position when in placement view
      },
    },
    badgeWindow: {
      badgeExtraPadding: [-0.1, 0, 0.1, 0.1], // Basic, Bronze, Silver, Gold
      dividerStroke: { thickness: 3, color: 0xFFA514, alpha: 1 },
      headerConfig: {
        scale: 0.75,
        bg: { asset: 'treasureHunt_leaderboard_header' },
        titleBg: {
          x: 0, y: 3, asset: 'treasureHunt_banner', scale: 1.3,
        },
        title: {
          x: 0, y: 115, baseStyle: 'font-white', fontSize: '65px', maxWidth: 370, maxHeight: 100, text: 'Badges',
        },
      },
      counterBg: 'treasureHunt_gameCounterBg',
      miniHeader: {
        position: { x: 0, y: 0 },
      },
    },
    promo: {
      mascotOffset: {
        gingy: { y: 21 },
        ginger: { y: 36 },
        chip: { y: 21 },
        cookie: { y: 10 },
        nutmeg: { y: 16 },
        graham: { y: 27 },
      },
    },
    podium: {
      positions: [
        { x: 0, y: 170 },
        { x: -165, y: 220 },
        { x: 165, y: 240 },
      ],
      tokenPos: [
        { x: 10, y: 450 },
        { x: -165, y: 430 },
        { x: 180, y: 430 },
      ],
    },
    waitingWindow: {
      gingyPos: { // Only in horizontal mode
        scale: 2,
        x: -510,
        y: 360,
      },
    },
    gameBoard: {
      progressBar: null, // The asset string of a custom progress bar
      counter: {
        textOffset: { x: 0, y: 0 },
        tokenOffset: { x: 0, y: 0 },
        doublingOfset: { x: 0, y: 0 },
      },
      horizontal: {
        topBar: {
          offset: { x: 0, y: 0 },
        },
      },
    },
    eventReminder: { // Settings for a window that shows a reminder to play!
      bgAsset: 'popup_background_treasureHunt',
      headerData: {
        asset: {
          x: 0,
          y: -265,
          assetString: 'treasureHunt_banner',
        },
        text: { // positioned in relation inside .asset (above)
          x: 0,
          y: 0,
          text: '%MASCOT%\'s Treasure Hunt',
          textStyle: 'treasureHunt-headerTitleText',
          height: 0.8,
          width: 0.7,
        },
      },
      bannerData: {
        asset: {
          x: 2,
          y: -63,
          assetString: 'treasureHuntContentBanner',
        },
        badge: { // Relative to .asset above
          x: 171,
          y: 37,
          size: 125,
        },
      },
      textData: {
        debug: false,
        text: [
          /* eslint-disable object-curly-newline */ //
          { x: 0, y: 71, string: 'The treasure hunt is here!', textStyle: 'treasureHunt-hintWindowTitle' },
          { x: 0, y: 117, string: 'Join %MASCOT% and search for treasures!', textStyle: 'treasureHunt-hintWindowSubtitle', bullet: true },
          { x: 0, y: 172, string: 'Collects %TOKEN% to earn rewards and unlock badges.', textStyle: 'treasureHunt-hintWindowSubtitle', bullet: true },
          { x: 0, y: 242, string: 'Reach the top of the leaderboard to earn special badges!', textStyle: 'treasureHunt-hintWindowSubtitle', bullet: true },
          /* eslint-enable object-curly-newline */
        ],
      },
      playButton: {
        x: 0,
        y: 0,
        text: 'Play',
      },
      mascot: {
        assetString: 'treasureHunt_prelevelMascot',
        offset: { x: -20, y: -20 },
        scale: {
          horizontal: 2,
          vertical: 1.5,
        },
        pos: {
          horizontal: { x: -510, y: 360 },
          vertical: { x: -230, y: 510 },
        },
      },
    },
  },

  // Maximum number of payload ids stored per type (see SaveState.markMsgAsRead)
  maxPayloadIdsSavedPerType: 100,

  // ===================================================================
  global: {
    defaultFont: 'AlegreyaSansBold',
    leaderboardName: 'completedLevels',
    window: {
      title: {
        textStyle: {
          style: 'font-white',
          fontSize: '72px',
          align: 'center',
        },
      },
      mediumTitle: {
        textStyle: {
          style: 'font-white',
          fontSize: '60px',
          align: 'center',
        },
      },
      outOfMovesTitle: {
        textStyle: {
          style: 'font-blue',
          fontSize: '60px',
          align: 'center',
        },
      },
      smallTitle: {
        textStyle: {
          style: 'font-white',
          fontSize: '50px',
          align: 'center',
        },
      },
    },
  },

  elements: {
    leaderboard: {
      tabLivesSelected: false,
    },
    gingy: {
      backEyes: {
        x: 6,
        y: -30,
      },
      eyes: {
        x: 6,
        y: -30,
      },
      eyelids: {
        x: 6,
        y: -30,
      },
      mouth: {
        x: 10,
        y: 11,
      },
    },
    ginger: {
      backEyes: {
        x: 9,
        y: 10,
      },
      eyes: {
        x: 11,
        y: 13,
      },
      eyelids: {
        x: 9,
        y: 10,
      },
      mouth: {
        x: 16,
        y: 48,
      },
    },
    chip: {
      backEyes: {
        x: -4,
        y: -9,
      },
      eyes: {
        x: -3,
        y: -6,
      },
      eyelids: {
        x: -4,
        y: -9,
      },
      mouth: {
        x: -5,
        y: 28,
      },
    },
    cookie: {
      backEyes: {
        x: 6,
        y: -29,
      },
      eyes: {
        x: 5,
        y: -27,
      },
      eyelids: {
        x: 6,
        y: -29,
      },
      mouth: {
        x: 7,
        y: 25,
      },
    },
    nutmeg: {
      backEyes: {
        x: -14,
        y: -20,
      },
      eyes: {
        x: -14,
        y: -17,
      },
      eyelids: {
        x: -14,
        y: -20,
      },
      mouth: {
        x: -19,
        y: 19,
      },
    },
    graham: {
      backEyes: {
        x: 0,
        y: -105,
      },
      eyes: {
        x: 25,
        y: -5,
      },
      eyelids: {
        x: 25,
        y: -5,
      },
      mouth: {
        x: 27,
        y: 21,
      },
    },
    fancyGingy: {
      mugshot: {
        x: 78,
        y: 93,
        width: 395,
        height: 395,
      },
      backEyes: {
        x: 4,
        y: -24,
      },
      eyes: {
        x: 6,
        y: -25,
      },
      eyelids: {
        x: 4,
        y: -24,
      },
      mouth: {
        x: 11,
        y: 17,
      },
    },
    treasureHuntgingy: {
      backEyes: {
        x: 12,
        y: -20,
      },
      eyes: {
        x: 12,
        y: -20,
      },
      eyelids: {
        x: 13,
        y: -20,
      },
      mouth: {
        x: 14,
        y: 17,
      },
    },
    treasureHuntginger: {
      backEyes: {
        x: 16,
        y: 15,
      },
      eyes: {
        x: 16,
        y: 15,
      },
      eyelids: {
        x: 17,
        y: 15,
      },
      mouth: {
        x: 25,
        y: 52,
      },
    },
    treasureHuntchip: {
      backEyes: {
        x: -16,
        y: -9,
      },
      eyes: {
        x: -13,
        y: -9,
      },
      eyelids: {
        x: -16,
        y: -9,
      },
      mouth: {
        x: -16,
        y: 30,
      },
    },
    treasureHuntcookie: {
      backEyes: {
        x: 6,
        y: -19,
      },
      eyes: {
        x: 5,
        y: -19,
      },
      eyelids: {
        x: 6,
        y: -19,
      },
      mouth: {
        x: 7,
        y: 35,
      },
    },
    treasureHuntnutmeg: {
      backEyes: {
        x: -4,
        y: -7,
      },
      eyes: {
        x: 0,
        y: -7,
      },
      eyelids: {
        x: -4,
        y: -7,
      },
      mouth: {
        x: -8,
        y: 33,
      },
    },
    treasureHuntgraham: {
      backEyes: {
        x: 0,
        y: -105,
      },
      eyes: {
        x: 0,
        y: 0,
      },
      eyelids: {
        x: 0,
        y: 0,
      },
      mouth: {
        x: 24,
        y: 23,
      },
    },
    chestShuffle: {
      rates: [
        { char: 'graham', rate: 10 },
        { char: 'ginger', rate: 10 },
        { char: 'nutmeg', rate: 10 },
        { char: 'cookie', rate: 20 },
        { char: 'chip', rate: 20 },
        { char: 'fancyGingy', rate: 30 },
      ],
      gifts: {
        fancyGingy: {
          infiniteLives: [
            ['lifeUnlimited_60', 0.1],
            ['lifeUnlimited_30', 0.3],
            ['lifeUnlimited_15', 0.6],
          ],
          coins: [
            ['coin_500', 0.1],
            ['coin_300', 0.3],
            ['coin_100', 0.6],
          ],
          lives: [
            ['life_1', 0.6],
            ['life_2', 0.3],
            ['life_3', 0.1],
          ],
        },
        ginger: {
          infiniteLives: [
            ['lifeUnlimited_60', 0.1],
            ['lifeUnlimited_30', 0.3],
            ['lifeUnlimited_15', 0.6],
          ],
          coins: [
            ['coin_500', 0.95],
            ['coin_300', 0.5],
            ['coin_100', 0.0],
          ],
          lives: [
            ['life_1', 0.4],
            ['life_2', 0.5],
            ['life_3', 0.1],
          ],
        },
        nutmeg: {
          infiniteLives: [
            ['lifeUnlimited_60', 0.1],
            ['lifeUnlimited_30', 0.3],
            ['lifeUnlimited_15', 0.6],
          ],
          coins: [
            ['coin_500', 0.1],
            ['coin_300', 0.5],
            ['coin_100', 0.4],
          ],
          lives: [
            ['life_1', 0.0],
            ['life_2', 0.5],
            ['life_3', 0.95],
          ],
        },
        cookie: {
          infiniteLives: [
            ['lifeUnlimited_60', 0.1],
            ['lifeUnlimited_30', 0.3],
            ['lifeUnlimited_15', 0.6],
          ],
          coins: [
            ['coin_500', 0.1],
            ['coin_300', 0.7],
            ['coin_100', 0.2],
          ],
          lives: [
            ['life_1', 0.2],
            ['life_2', 0.7],
            ['life_3', 0.1],
          ],
        },
        chip: {
          infiniteLives: [
            ['lifeUnlimited_60', 0.1],
            ['lifeUnlimited_30', 0.75],
            ['lifeUnlimited_15', 0.15],
          ],
          coins: [
            ['coin_500', 0.5],
            ['coin_300', 0.35],
            ['coin_100', 0.6],
          ],
          lives: [
            ['life_1', 0.6],
            ['life_2', 0.35],
            ['life_3', 0.5],
          ],
        },
        graham: {
          infiniteLives: [
            ['lifeUnlimited_60', 1],
            ['lifeUnlimited_30', 0],
            ['lifeUnlimited_15', 0],
          ],
          coins: [
            ['coin_500', 0.1],
            ['coin_300', 0.4],
            ['coin_100', 0.5],
          ],
          lives: [
            ['life_1', 0.5],
            ['life_2', 0.4],
            ['life_3', 0.1],
          ],
        },
      },
    },
    characterAssets: {
      gingy: {
        name: 'gingy',
        fullBody: 'chestShuffleGingyFull',
        backEyes: 'gingy_eyes_back',
        eyes: 'gingy_eyes',
        body: 'gingy_body',
        eyelids: 'gingy_eyelids',
        mouth_closed: 'gingy_mouth_closed',
        mouths: [
          'gingy_mouth_closed',
          'gingy_mouth_open',
          'gingy_mouth_surprised',
        ],
        yBottom: 156,
      },
      ginger: {
        name: 'ginger',
        fullBody: 'chestShuffleGingerFull',
        backEyes: 'ginger_eyes_back',
        eyes: 'ginger_eyes',
        body: 'ginger_body',
        eyelids: 'ginger_eyelids',
        mouth_closed: 'ginger_mouth_closed',
        mouths: [
          'ginger_mouth_closed',
          'ginger_mouth_open',
          'ginger_mouth_surprised',
        ],
        yBottom: 150,
      },
      nutmeg: {
        name: 'nutmeg',
        fullBody: 'chestShuffleNutmegFull',
        body: 'nutmeg_body',
        backEyes: 'nutmeg_eyes_back',
        eyes: 'nutmeg_eyes',
        eyelids: 'nutmeg_eyelids',
        mouth_closed: 'nutmeg_mouth_closed',
        mouths: [
          'nutmeg_mouth_closed',
          'nutmeg_mouth_open',
          'nutmeg_mouth_surprised',
        ],
        yBottom: 101,
      },
      cookie: {
        name: 'cookie',
        fullBody: 'chestShuffleCookieFull',
        body: 'cookie_body',
        backEyes: 'cookie_eyes_back',
        eyes: 'cookie_eyes',
        eyelids: 'cookie_eyelids',
        mouth_closed: 'cookie_mouth_closed',
        mouths: [
          'cookie_mouth_closed',
          'cookie_mouth_open',
          'cookie_mouth_surprised',
        ],
        yBottom: 95,
      },
      chip: {
        name: 'chip',
        fullBody: 'chestShuffleChipFull',
        body: 'chip_body',
        backEyes: 'chip_eyes_back',
        eyes: 'chip_eyes',
        eyelids: 'chip_eyelids',
        mouth_closed: 'chip_mouth_closed',
        mouths: [
          'chip_mouth_closed',
          'chip_mouth_open',
          'chip_mouth_surprised',
        ],
        yBottom: 104,
      },
      graham: {
        name: 'graham',
        fullBody: 'chestShuffleGrahamFull',
        body: 'graham_body',
        backEyes: null,
        eyes: 'graham_eyes',
        eyelids: 'graham_eyelids',
        mouth_closed: 'graham_mouth_closed',
        mouths: [
          'graham_mouth_closed',
          'graham_mouth_open',
          'graham_mouth_surprised',
        ],
        yBottom: 102,
      },
      fancyGingy: {
        name: 'gingy', // Willy
        fullBody: 'chestShuffleGingyFull',
        backEyes: 'gingy_eyes_back',
        eyes: 'gingy_eyes',
        body: 'fancy_gingy_body',
        eyelids: 'gingy_eyelids',
        mouth_closed: 'gingy_mouth_closed',
        mouths: [
          'gingy_mouth_closed',
          'gingy_mouth_open',
          'gingy_mouth_surprised',
        ],
        yBottom: 150,
      },
      treasureHuntgingy: {
        name: 'gingy',
        fullBody: 'treasureHunt_prelevelMascot0',
        backEyes: 'gingy_eyes_back',
        eyes: 'gingy_eyes',
        body: 'treasureHuntgingy_body',
        eyelids: 'treasureHuntgingy_eyelids',
        mouth_closed: 'gingy_mouth_closed',
        mouths: [
          'gingy_mouth_closed',
          'gingy_mouth_open',
          'gingy_mouth_surprised',
        ],
        yBottom: 123,
      },
      treasureHuntginger: {
        name: 'ginger',
        fullBody: 'treasureHunt_prelevelMascot1',
        backEyes: 'ginger_eyes_back',
        eyes: 'ginger_eyes',
        body: 'treasureHuntginger_body',
        eyelids: 'treasureHuntginger_eyelids',
        mouth_closed: 'treasureHuntginger_mouth_closed',
        mouths: [
          'treasureHuntginger_mouth_closed',
          'ginger_mouth_open',
          'ginger_mouth_surprised',
        ],
        yBottom: 130,
      },
      treasureHuntnutmeg: {
        name: 'nutmeg',
        fullBody: 'treasureHunt_prelevelMascot4',
        body: 'treasureHuntnutmeg_body',
        backEyes: 'nutmeg_eyes_back',
        eyes: 'nutmeg_eyes',
        eyelids: 'treasureHuntnutmeg_eyelids',
        mouth_closed: 'treasureHuntnutmeg_mouth_closed',
        mouths: [
          'treasureHuntnutmeg_mouth_closed',
          'treasureHuntnutmeg_mouth_open',
          'treasureHuntnutmeg_mouth_surprised',
        ],
        yBottom: 120,
      },
      treasureHuntcookie: {
        name: 'cookie',
        fullBody: 'treasureHunt_prelevelMascot3',
        body: 'treasureHuntcookie_body',
        backEyes: 'cookie_eyes_back',
        eyes: 'cookie_eyes',
        eyelids: 'treasureHuntcookie_eyelids',
        mouth_closed: 'treasureHuntcookie_mouth_closed',
        mouths: [
          'treasureHuntcookie_mouth_closed',
          'treasureHuntcookie_mouth_open',
          'treasureHuntcookie_mouth_surprised',
        ],
        yBottom: 110,
      },
      treasureHuntchip: {
        name: 'chip',
        fullBody: 'treasureHunt_prelevelMascot2',
        body: 'treasureHuntchip_body',
        backEyes: 'chip_eyes_back',
        eyes: 'chip_eyes',
        eyelids: 'treasureHuntchip_eyelids',
        mouth_closed: 'treasureHuntchip_mouth',
        mouths: [
          'treasureHuntchip_mouth',
          'chip_mouth_open',
          'chip_mouth_surprised',
        ],
        yBottom: 110,
      },
      treasureHuntgraham: {
        name: 'graham',
        fullBody: 'treasureHuntgraham_body',
        body: 'treasureHuntgraham_body',
        backEyes: null,
        eyes: 'graham_eyes',
        eyelids: 'graham_eyelids',
        mouth_closed: 'treasureHuntgraham_mouth_closed',
        mouths: [
          'treasureHuntgraham_mouth_closed',
          'treasureHuntgraham_mouth_open',
          'treasureHuntgraham_mouth_surprised',
        ],
        yBottom: 110,
      },
    },
    badges: { // Each key is the badge name. The data inside is the frame offset that is used with OMT_UI_AvatarWithFrameOffset
      /* eslint-disable object-curly-newline */
      default: { x: 5, y: -2, scale: 1.13, asset: 'avatar_frame_big' },
      leaderboardPlayer: { x: 0, y: 0, scale: 1, asset: 'lb2_avatar_user_box_empty' },
      leaderboardOther: { x: 0, y: 0, scale: 1, asset: 'lb2_avatar_main_box_empty' },
      treasureHuntGoldgingy: { x: 0, y: 1, scale: 1.8, asset: 'treasureHuntGoldgingy' },
      treasureHuntSilvergingy: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntSilvergingy' },
      treasureHuntBronzegingy: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntBronzegingy' },
      treasureHuntBasicgingy: { x: 2, y: 5, scale: 1.4, asset: 'treasureHuntBasicgingy' },
      treasureHuntGoldginger: { x: 0, y: 1, scale: 1.8, asset: 'treasureHuntGoldginger' },
      treasureHuntSilverginger: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntSilverginger' },
      treasureHuntBronzeginger: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntBronzeginger' },
      treasureHuntBasicginger: { x: 2, y: 5, scale: 1.4, asset: 'treasureHuntBasicginger' },
      treasureHuntGoldchip: { x: 0, y: 1, scale: 1.8, asset: 'treasureHuntGoldchip' },
      treasureHuntSilverchip: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntSilverchip' },
      treasureHuntBronzechip: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntBronzechip' },
      treasureHuntBasicchip: { x: 2, y: 5, scale: 1.4, asset: 'treasureHuntBasicchip' },
      treasureHuntGoldnutmeg: { x: 2, y: 2, scale: 1.8, asset: 'treasureHuntGoldnutmeg' },
      treasureHuntSilvernutmeg: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntSilvernutmeg' },
      treasureHuntBronzenutmeg: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntBronzenutmeg' },
      treasureHuntBasicnutmeg: { x: 2, y: 5, scale: 1.4, asset: 'treasureHuntBasicnutmeg' },
      treasureHuntGoldcookie: { x: 0, y: 1, scale: 1.8, asset: 'treasureHuntGoldcookie' },
      treasureHuntSilvercookie: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntSilvercookie' },
      treasureHuntBronzecookie: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntBronzecookie' },
      treasureHuntBasiccookie: { x: 2, y: 5, scale: 1.4, asset: 'treasureHuntBasiccookie' },
      treasureHuntGoldgraham: { x: 0, y: 1, scale: 1.8, asset: 'treasureHuntGoldgraham' },
      treasureHuntSilvergraham: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntSilvergraham' },
      treasureHuntBronzegraham: { x: 0, y: 0, scale: 1.4, asset: 'treasureHuntBronzegraham' },
      treasureHuntBasicgraham: { x: 2, y: 5, scale: 1.4, asset: 'treasureHuntBasicgraham' },
      /* eslint-enable object-curly-newline */
    },
    tutorialMsg: {
      fontSize: 45,
      lineSpacing: -17,
    },
    LossAversionWheelGroup: {
      defaultCounterStyle: {
        style: 'soloInstance-coinBar',
        fontSize: 35,
      },

      titleStyle: {
        style: 'font-white',
        fontSize: '60px',
        align: 'center',
      },
      useVillainTitles: false, // Uses villain font style titles if its on a villain level
    },
    dailyReward: {
      title: {
        x: 0,
        y: -285,
        style: {
          fill: 'white',
          font: G.Text.defaultFont,
          fontSize: '55px',
        },
      },
      comeBackTxt: {
        lineSpacing: -20,
      },
    },
    dailyIcon: {
      unlockTxt: {
        x: 0,
        y: 0,
      },
      adIcon: {
        x: 20,
        y: 20,
      },
      glow: {
        x: 0,
        y: 0,
      },
      timer: {
        x: 0,
        y: 7,
      },
      withAdTimer: {
        x: 0,
        y: 57,
      },
      icon: {
        x: 0,
        y: 0,
      },
      freeText: {
        x: 0,
        y: 0,
        shadow: {
          x: 0,
          y: 0,
        },
      },
      xCenterOffset: 260,
      x: 855,
      y: 140,
    },
    dailyChallengeIcon: {
      icon: {
        x: 0,
        y: 0,
      },
      adIcon: {
        x: 20,
        y: 20,
      },
      xShift: 0,
      xCenterOffset: -265,
      x: 855,
      y: 300,
    },
    dailyChallengeSettings: {
      coinSize: 130, // Coin size on the win screen so it can fit into the blanks
    },
    dailyMission: {
      ribbonText: {
        yPos: 0, // unset it?
      },
    },
    giftUI: {
      sideGingy: {
        txtBg: {
          scale: {
            x: 0.9,
            y: 0.8,
          },
        },
        gingyScale: {
          vertical: 0.66,
          horizontal: 1.5,
        },
        txt: {
          lineSpacing: 0,
          fontSize: '60px',
        },
      },
    },
    worldSidePanelLockedWidget: {
      unlockTxt: {
      },
    },
    Window_shop3: {
      overrideTier: false,
      useBoldSchema: false,
      maxSpecialSlots: 3, // on Expanded view
      twoSlotChestAsset: {
        horizontal: 'shop3_specialDeal1',
        vertical: 'shop3_specialDealFTU',
      },
      weekendDealReplace: 'special_45k_b1_5_b2_5_b3_5_il_5',
    },
    highscoreBeaten: {
      continueBtn: {
        x: 0,
        y: 245,
      },
    },
    checkedIconPast: {
      x: 35,
      y: 15,
    },
    checkedIcon: {
      x: 35,
      y: 15,
    },
    mapGift: {
      btn: {
        x: 3,
        y: 20,
      },
      height: 115,
      timer: {
        x: 0,
        y: 75,
      },
      xCenterOffset: 260,
      y: 20,
    },
    worldMapLvlButton: {
      lvlNrText: {
        style: {
          style: 'font-white',
          fontSize: '50px',
        },
      },
    },
    UI_MapPanel: {
      starData: {
        vertical: {
          xPos: -90,
          icon: {
            x: 0,
            y: 36,
            size: 45,
          },
          text: {
            y: 43,
            style: 'UIMapStarsTxt',
            size: 35,
          },
        },
        horizontal: {
          xPos: -190,
          icon: {
            x: 0,
            y: 40,
            size: 70,
          },
          text: {
            y: 48,
            style: 'UIMapStarsTxt',
            size: 55,
          },
        },
      },
      coinData: {
        vertical: {
          position: {
            x: 115,
            y: 37,
          },
          image: {
            size: 52, // Scaled proportionally to height
          },
          text: {
            y: 5, // Relative to center of container
            style: 'UIMapCoinsTxt',
            size: 35,
            maxWidth: 95,
          },
          plusIcon: { // Relative to center of container
            x: 90,
            y: 0,
            size: 58,
          },
        },
        horizontal: {
          position: {
            x: 170,
            y: 45,
          },
          image: {
            size: 75,
          },
          text: {
            y: 6,
            style: 'UIMapCoinsTxt',
            size: 50,
            maxWidth: 140,
          },
          plusIcon: {
            x: 120,
            y: 1,
            size: 75,
          },
        },
      },
      settingsMenu: {
        vertical: {
          x: 280,
          y: 38,
          size: 68,
        },
        horizontal: {
          x: 440,
          y: 45,
          size: 80,
        },
      },
      moveTo: {
        ifNotLifes: {
          stars: -270,
        },
      },
    },
    Window_level: {
      vertical: {
        scale: 1,
        yOffset: 0,
      },
      horizontal: {
        scale: 0.9,
        yOffset: -50,
      },
    },
    Window_friendOvertaken: {
      titleTxt: {
        style: {
          style: 'font-white',
          fontSize: '60px',
        },
      },
    },
    Window_timedTargetedOffer: {
      vertical: {
        scale: 1.1,
        deal: {
          moneyOnlyKey: 'shop3_specialDeal2',
          fewBoostersKey: 'shop3_specialDeal1',
          manyBoostersKey: 'shop3_specialDeal0',
          scale: 1.1,
          offset: -65,
        },
        coinbar: {
          scale: 1.5,
          fontSize: 36,
          tint: 0xfe6124,
          offsetY: -260,
        },
        multiplier: {
          offsetY: 0,
          offsetX: 0,
          scale: 1,
          bgScale: 1,
          txt: {
            offsetX: 10,
            offsetY: 0,
          },
        },
        bg: {
          height: 550,
          innerOffset: -25,
          sliceLeft: 145,
          sliceRight: 149,
          sliceTop: 221,
          sliceBottom: 139,

          innerSliceLeft: 72,
          innerSliceRight: 72,
          innerSliceTop: 66,
          innerSliceBottom: 66,
          innerTint: 0xffb20d,
        },
        headerOffset: -110,
        ribbonOffset: 0,
        mascotOffsetY: 0,
        topBarTxtColor: '#ffffff',
        buyButtonOffsetY: 0,
      },
      horizontal: {
        scale: 1.1,
        deal: {
          moneyOnlyKey: 'shop3_specialDeal2',
          fewBoostersKey: 'shop3_specialDeal1',
          manyBoostersKey: 'shop3_specialDeal0',
          scale: 1.1,
          offset: -65,
        },
        coinbar: {
          scale: 1.5,
          fontSize: 36,
          tint: 0xfe6124,
          offsetY: -260,
        },
        multiplier: {
          offsetY: 0,
          offsetX: 0,
          scale: 1,
          bgScale: 1,
          txt: {
            offsetX: 10,
            offsetY: 0,
          },
        },
        bg: {
          height: 550,
          innerOffset: -25,
          sliceLeft: 145,
          sliceRight: 149,
          sliceTop: 221,
          sliceBottom: 139,

          innerSliceLeft: 72,
          innerSliceRight: 72,
          innerSliceTop: 66,
          innerSliceBottom: 66,
          innerTint: 0xffb20d,
        },
        headerOffset: -110,
        ribbonOffset: 0,
        mascotOffsetY: 0,
        topBarTxtColor: '#ffffff',
        buyButtonOffsetY: 0,
      },
    },
    Window_oneTimeTargetedOffer: {
      vertical: {
        scale: 1.1,
        deal: {
          moneyOnlyKey: 'shop3_specialDeal2',
          fewBoostersKey: 'shop3_specialDeal1',
          manyBoostersKey: 'shop3_specialDeal0',
          scale: 1.1,
          offset: -65,
        },
        coinbar: {
          scale: 1.5,
          fontSize: 36,
          tint: 0xfe6124,
          offsetY: -260,
        },
        multiplier: {
          offsetY: 0,
          offsetX: 0,
          scale: 1,
          bgScale: 1,
          txt: {
            offsetX: 10,
            offsetY: 0,
          },
        },
        bg: {
          height: 550,
          innerOffset: -25,
          sliceLeft: 145,
          sliceRight: 149,
          sliceTop: 221,
          sliceBottom: 139,

          innerSliceLeft: 72,
          innerSliceRight: 72,
          innerSliceTop: 66,
          innerSliceBottom: 66,
          innerTint: 0xffb20d,
        },
        headerOffset: -110,
        ribbonOffset: 0,
        mascotOffsetY: 0,
        topBarTxtColor: '#ffffff',
        buyButtonOffsetY: 0,
      },
      horizontal: {
        scale: 1.1,
        deal: {
          moneyOnlyKey: 'shop3_specialDeal2',
          fewBoostersKey: 'shop3_specialDeal1',
          manyBoostersKey: 'shop3_specialDeal0',
          scale: 1.1,
          offset: -65,
        },
        coinbar: {
          scale: 1.5,
          fontSize: 36,
          tint: 0xfe6124,
          offsetY: -260,
        },
        multiplier: {
          offsetY: 0,
          offsetX: 0,
          scale: 1,
          bgScale: 1,
          txt: {
            offsetX: 10,
            offsetY: 0,
          },
        },
        bg: {
          height: 550,
          innerOffset: -25,
          sliceLeft: 145,
          sliceRight: 149,
          sliceTop: 221,
          sliceBottom: 139,

          innerSliceLeft: 72,
          innerSliceRight: 72,
          innerSliceTop: 66,
          innerSliceBottom: 66,
          innerTint: 0xffb20d,
        },
        headerOffset: -110,
        ribbonOffset: 0,
        mascotOffsetY: 0,
        topBarTxtColor: '#ffffff',
        buyButtonOffsetY: 0,
      },
    },
    Window_highscoreBeaten: {
      titleTxt: {
        style: {
          style: 'font-white',
          fontSize: '60px',
        },
      },
    },
    Window_crossPromo: {
      titleTxt: {
        x: 0,
        y: -272,
        style: {
          style: 'font-white',
          fontSize: '60px',
        },
      },
      continueBtn: {
        x: 0,
        y: 245,
      },
      continueBtnTxt: {
        x: 0,
        y: 0,
        style: {
          style: 'font-white',
          fontSize: '40px',
          lineSpacing: -15,
        },
      },
    },
    Window_OutOfMovesIAP: {
      elementLayouts: {
        windowHeight: { min: 960, max: 1140 },
        yPos: { // range of y-positions (for responsiveness)
          coinBar: { min: -420, max: -460 },
        },
      },
    },
    Window_giveUp: {
      levelTxt: {
        style: {
          style: 'font-white',
          fontSize: '60px',
        },
      },
    },
    Window_notEnoughLives: {
      livesNrTxt: {
        x: 0,
        y: -60,
      },
      coinsTxt: {
        x: 10,
        y: -279,
        style: {
          style: 'font-white',
          fontSize: '35px',
        },
      },
      buyLifeButton_coinTxt: {
        style: {
          style: 'font-white',
          fontSize: '40px',
        },
      },
    },
    Window_taskSlider: {
      taskTxt: {
        y: 45,
        style: {
          style: 'font-blue',
          fontSize: '32px',
        },
      },
      goal: {
        style: {
          style: 'font-blue',
          fontSize: '50px',
        },
      },
    },
    Window_newsHomeScreen: {
      button: {
        shouldPulse: false,
        x: 0,
        y: 285,
      },
      continueBtn: {
        x: 0,
        y: 300,
      },
      continueBtnTxt: {
        x: 0,
        y: 300,
      },
    },
    Window_treasureHuntEvent: {
      bgSize: {
        vertical: {
          width: 582,
          height: 660,
        },
        horizontal: {
          width: 582,
          height: 600,
        },
      },
    },
    Window_dailyWheel: {
      screenWidth: {
        min: 640,
        max: 1280,
      },
      screenHeight: {
        min: 960,
        max: 1380,
      },

      bannerConfig: {
        parentOffset: {
          y: -105,
        },
        elemOffsets: {
          closeButton: {
            x: {
              min: 260,
              max: 360,
            },
            y: -175,
          },
          realMoneyQuitButton: {
            y: -210,
          },
          bannerGroup: {
            y: -175,
          },
          ribbon: {
            y: 98,
          },
        },
      },

      spinButtonConfig: {
        parentOffset: {
          y: {
            min: 435,
            max: 535,
          },
        },

        elemOffsets: {
          realMoneySpinBtn: {
            y: {
              min: 45,
              max: -10,
            },
          },
        },
      },

      mascotHostConfig: {
        offset: {
          x: {
            min: 0,
            max: -425,
          },
        },
      },
    },
    Window_RealMoneyWheel: {
      screenWidth: {
        min: 640,
        max: 1280,
      },
      screenHeight: {
        min: 960,
        max: 1380,
      },

      bannerConfig: {
        parentOffset: {
          y: -105,
        },
        elemOffsets: {
          quitButton: {
            x: {
              min: 260,
              max: 360,
            },
            y: -285,
          },
          bannerGroup: {
            y: -285,
          },
        },
      },

      coinBarConfig: {
        y: {
          min: -195,
          max: -255,
        },
      },

      spinButtonConfig: {
        parentOffset: {
          y: {
            min: 435,
            max: 535,
          },
        },

        elemOffsets: {
          realMoneySpinBtn: {
            y: {
              min: 45,
              max: -10,
            },
          },
        },
      },

      mascotHostConfig: {
        offset: {
          x: {
            min: 0,
            max: -425,
          },
        },
      },
    },

    Window_EventLevel: {
      yPos: {
        minScreenHeight: 960,
        maxScreenHeight: 1080,
        vertical: {
          min: -365,
          max: -440,
        },
        horizontal: {
          min: -400,
          max: -440,
        },
      },
    },

    EventTokenCounter: {
      landscapeXOffset: -50,
      yPos: {
        minScreenHeight: 1100,
        maxScreenHeight: 1386,
        vertical: {
          min: 180,
          max: 270,
        },
        horizontal: 85,
      },
      scale: {
        vertical: {
          minScreenHeight: 960,
          maxScreenHeight: 1200,
          min: 0.75,
          max: 1,
        },
        horizontal: 0.67,
      },
    },
    Window_FortuneCookie: {
      coinRewards: [25, 50],
      glowColors: {
        game: 0xFF7D16,
        prediction: 0x49E0FF,
        funny: 0x5BFF00,
        wisdom: 0xFF70FB,
        love: 0xFF7F9F,
      },
      categories: {
        funny: {
          index: 1,
          enabled: true,
        },
        prediction: {
          index: 3,
          enabled: true,
        },
        love: {
          index: 2,
          enabled: true,
        },
        wisdom: {
          index: 4,
          enabled: true,
        },
        game: {
          index: 0,
          enabled: true,
        },
      },
      elementLayouts: {
        windowHeight: { min: 960, max: 1140 },
        bannerTextOffset: { x: 12, y: -10 },
        yPos: { // range of y-positions (for responsiveness)
          coinBar: { min: -317, max: -383 },
          shareBannerGroup: { min: -375, max: -460 },
          msgGroup: { min: -212, max: -242 },
          msgImgGroup: { min: 90, max: 100 },
          shareBtn: { min: 395, max: 460 },
        },
        scaling: { // range of scale factors (for responsiveness)
          msgGroup: { min: 0.84, max: 1 },
          msgImgGroup: { min: 1.05, max: 1.22 },
        },
      },
      avatarLayout: {
        funny: {
          x: -117,
          y: -90,
          width: 110,
          height: 110,
          angle: -4,
        },
        prediction: {
          x: -8,
          y: -123,
          width: 115,
          height: 115,
          angle: 0,
        },
        love: {
          x: -67,
          y: -94,
          width: 114,
          height: 114,
          angle: 3,
        },
        wisdom: {
          x: 100,
          y: -90,
          width: 108,
          height: 108,
          angle: 0,
        },
        game: {
          x: 38,
          y: -88,
          width: 110,
          height: 110,
          angle: 14,
        },
      },
      tagSymbolOnly: true,
      tagPosition: {
        x: -50,
        y: 38,
      },
    },

    Window_noAdsPopup: {
      vertical: {
        window: {
          x: 0,
          y: 0,
        },
        title: {
          x: 0,
          y: -284,
          text: 'Did you know?',
          style: 'noAdsPopup-title',
          maxWidth: 340,
        },
        background: {
          width: 560,
          height: 650,
        },
        dealChest: {
          x: 110,
          y: 170,
          scale: 0.9,
        },
        gingy: {
          x: -130,
          y: 182,
          scale: 0.8,
        },
        adCrossed: {
          x: 0,
          y: -5,
          scale: 0.475,
        },
        message: {
          x: 0,
          y: -230,
          maxWidth: 448,
          maxHeight: 165,
          text: 'You can remove forced ads forever by making any purchase in the coins store',
          style: {
            style: 'noAdsPopup-message',
            fontSize: 36,
          },
        },
        continueButton: {
          x: 0,
          y: 320,
          width: 230,
          height: 90,
          text: 'Continue',
          style: {
            style: 'font-white',
            fontSize: 64,
          },
          pulse: 1.05,
        },
      },

      horizontal: {
        window: {
          x: 0,
          y: 100,
        },
        title: {
          x: 0,
          y: -355,
          text: 'Did you know?',
          style: 'noAdsPopup-title',
          maxWidth: 500,
        },
        background: {
          width: 1300,
          height: 800,
        },
        dealChest: {
          x: 380,
          y: 160,
          scale: 1.55,
        },
        gingy: {
          x: -390,
          y: 171,
          scale: 1.4,
        },
        adCrossed: {
          x: 0,
          y: 25,
          scale: 0.95,
        },
        message: {
          x: 0,
          y: -290,
          maxWidth: 1000,
          maxHeight: 200,
          text: 'You can remove forced ads forever by making any purchase in the coins store',
          style: {
            style: 'noAdsPopup-message',
            fontSize: 64,
          },
        },
        continueButton: {
          x: 0,
          y: 300,
          width: 275,
          height: 130,
          text: 'Continue',
          style: {
            style: 'font-white',
            fontSize: 72,
          },
          pulse: 1.05,
        },
      },
    },

    MsgFortuneCookie: {
      avatarLayout: {
        funny: {
          x: 496,
          y: 113,
          width: 138,
          height: 138,
          angle: 5.62,
        },
        prediction: {
          x: 496,
          y: 113,
          width: 138,
          height: 138,
          angle: 5.62,
        },
        love: {
          x: 496,
          y: 113,
          width: 138,
          height: 138,
          angle: 5.62,
        },
        wisdom: {
          x: 496,
          y: 113,
          width: 138,
          height: 138,
          angle: 5.62,
        },
        game: {
          x: 496,
          y: 113,
          width: 138,
          height: 138,
          angle: 5.62,
        },
      },
    },
    MsgMailbox: {
      background: {
        x: 0,
        y: -150,
        width: 672,
        height: 672,
      },
      postcard: { x: 336, y: 535, scale: 1.1 },
      photo: {
        x: 336,
        y: 155,
        scale: 0.8,
        angle: 5,
      },
      text: {
        x: 336,
        y: 337,
        maxWidth: 525,
        maxHeight: 100,
      },
    },
    CandyType_FortuneCookie: {
      timerPosition: {
        x: -24,
        y: 11,
      },
    },
    UI_Life: {
      position: {
        vertical: {
          x: -278,
          y: 36,
        },
        horizontal: {
          x: -450,
          y: 40,
        },
      },
      heartImageSize: {
        vertical: 290,
        horizontal: 340,
      },
      livesNrTxt: {
        vertical: {
          x: 0,
          y: 0,
          size: 40,
        },
        horizontal: {
          x: 0,
          y: 3,
          size: 45,
        },
      },
      timerTxt: {
        vertical: {
          x: 85,
          y: 7,
          style: 'UILifeTimerTxt',
          size: 35,
        },
        horizontal: {
          x: 85,
          y: 7,
          style: 'UILifeTimerTxt',
          size: 45,
        },
      },
      unlimitedTimerTxt: {
        vertical: {
          x: 85,
          y: 7,
          style: 'UILifeUnlimited',
          size: 35,
        },
        horizontal: {
          x: 90,
          y: 7,
          style: 'UILifeUnlimited',
          size: 45,
        },
      },
      timerMaxTxt: {
        vertical: {
          x: 80,
          y: 7,
          style: 'UILifeTimerMax',
          size: 35,
        },
        horizontal: {
          x: 80,
          y: 7,
          style: 'UILifeTimerMax',
          size: 50,
        },
      },
    },
    UI_PointsCounter: {
      text: {
        style: 'UIPointsLabel-normal',
      },
    },
    UI_BoosterButton: {
      amount: {
        style: {
          style: 'font-blue',
          fontSize: '25px',
        },
      },
    },
    UI_StartBoosterButton: {
      amountTxt: {
        style: {
          style: 'font-blue',
          fontSize: '25px',
        },
      },
    },
    taskCollectPanels: {
      amount: {
        style: {
          style: 'font-blue',
          fontSize: '50px',
        },
      },
    },
    UI_GoalPanelCollect: {
      panelNr: {
        style: {
          style: 'font-blue',
          fontSize: '60px',
        },
      },
    },
    UI_GoalPanelPoints: {
      labelTxt: {
        style: {
          style: 'font-blue',
          fontSize: '40px',
        },
      },
      pointsCounter: {
        style: {
          style: 'font-blue',
          fontSize: '40px',
        },
      },
    },
    UI_ProgressBarHorizontal: {
      scale: 1,
      offset: {
        x: 0,
        y: 0,
      },
    },
    gifts: {
      // whether we open gift by hiding lid or with two-frame animation
      twoFrameOpen: false,
      shineOffsetX: 0,
      shineOffsetY: 0,
    },
    chestShuffleGifts: [
    ],
    // these colors use { GIFT_COLORS } from '../../js/Elements/Windows/GiftUI/GiftGraphic'
    dailyRewardCalendar: {
      doubleable: true,
      days: [
        {
          gifts: [['coin', 200]],
          boxIcon: [],
        },

        {
          gifts: [['booster#2', 1]],
          boxIcon: [
            {
              giftType: 1,
              boxTint: 'Green',
              ribbonTint: 'Red',
              pos: [0, 0],
            },
          ],
        },

        {
          gifts: [['booster#7', 1]],
          boxIcon: [
            {
              giftType: 2,
              boxTint: 'Pink',
              ribbonTint: 'Light_Blue',
              pos: [-10, 0],
            },
            {
              giftType: 1,
              boxTint: 'Teal',
              ribbonTint: 'Red',
              pos: [25, 15],
              scale: [0.75, 0.75],
            },
          ],
        },

        {
          gifts: [['booster#5', 1]], // #5/#6 fixed in dailyRewardMgr
          boxIcon: [
            {
              giftType: 2,
              boxTint: 'Purple',
              ribbonTint: 'Red',
              pos: [0, 0],
            },
            {
              giftType: 1,
              boxTint: 'Light_Blue',
              ribbonTint: 'Red',
              pos: [-30, 20],
              scale: [0.6, 0.6],
            },
            {
              giftType: 1,
              boxTint: 'Green',
              ribbonTint: 'Orange',
              pos: [30, 20],
              scale: [0.6, 0.6],
            },
          ],
        },

        {
          gifts: [['booster#1', 1]],
          boxIcon: [
            {
              giftType: 2,
              boxTint: 'Teal',
              ribbonTint: 'Red',
              pos: [20, 0],
            },
            {
              giftType: 1,
              boxTint: 'Light_Blue',
              ribbonTint: 'Orange',
              pos: [-30, 12],
              scale: [0.75, 0.75],
            },
            {
              giftType: 2,
              boxTint: 'Red',
              ribbonTint: 'Dark_Blue',
              pos: [0, 25],
              scale: [0.55, 0.55],
            },
          ],
        },

        {
          gifts: [['booster#8', 1]],
          boxIcon: [
            {
              giftType: 1,
              boxTint: 'Red',
              ribbonTint: 'Dark_Blue',
              pos: [20, 0],
            },
            {
              giftType: 2,
              boxTint: 'Green',
              ribbonTint: 'Red',
              pos: [-25, 5],
              scale: [0.8, 0.8],
            },
            {
              giftType: 2,
              boxTint: 'Teal',
              ribbonTint: 'Red',
              pos: [0, 25],
              scale: [0.5, 0.5],
            },
          ],
        },

        {
          gifts: [
            ['booster#5', 1], // #5/#6 fixed in dailyRewardMgr
            ['booster#8', 1],
          ],
          boxIcon: [
            // leftmost
            {
              giftType: 2,
              boxTint: 'Teal',
              ribbonTint: 'Pink',
              pos: [-70, 0],
              scale: [0.7, 0.7],
            },
            // second from right
            {
              giftType: 1,
              ribbonTint: 'Dark_Blue',
              boxTint: 'Pink',
              pos: [55, -10],
              scale: [0.9, 0.9],
            },
            // center
            {
              giftType: 2,
              boxTint: 'Green',
              ribbonTint: 'Red',
              pos: [0, 0],
              scale: [1, 1],
            },
            // second frm left
            {
              giftType: 1,
              boxTint: 'Dark_Blue',
              ribbonTint: 'Orange',
              pos: [-40, 20],
              scale: [0.7, 0.7],
            },
            // rightmost
            {
              giftType: 2,
              boxTint: 'Teal',
              ribbonTint: 'Red',
              pos: [80, 10],
              scale: [0.6, 0.6],
            },
          ],
        },
      ],
    },
    superhard: {
      window_level: {
        hardWidth: 1340,
        superHardWidth: 1340,
        bannerYOffset: 0,
        villainsXOffset: 0,
        villainsYOffset: 0,
      },
      window_dailyChallenge: {
        bannerYOffset: 0,
        villainsXOffset: 0,
        villainsYOffset: 0,
        titleMaxWidthMultipler: 0.9,
      },
      window_levelFailed: {
        hardWidth: 1340,
        superHardWidth: 1340,
        bannerTextMaxWidthMultipler: 1,
      },
      sagaMapTutorial: {
        gingyOffset: 0.65,
      },
      villain_speech: {
        dailyChallengeOOM0: 'Wow, you\'re struggling on this level?',
        dailyChallengeOOM1: 'Do you need help? That\'s too bad!',
        dailyChallengeOOM2: 'I thought you\'d be more of a challenge.',
        dailyChallengeFail0: 'Try a little harder next time.',
        dailyChallengeFail1: 'Ha! You can\'t beat us!',
        dailyChallengeFail2: 'We didn\'t even break a sweat!',
        dailyChallengeWin0: 'No way! We\'ll be back!',
        dailyChallengeWin1: 'No fair, you just got lucky!',
        dailyChallengeWin2: 'What!? How did you beat us?',
        villainYOffsetMultipler: 0.5,
      },
      gingyBubble: {
        offsetX: 0.05, // Multiplied number for position into the middle
      },
      bubbleTipPos: {
        hard: {
          'top-right': {
            x: 300,
            y: -44,
            scale: 1,
          },
          'top-left': {
            x: 200,
            y: -44,
            scale: -1,
          },
          right: {
            x: 513,
            y: 130,
            scale: -1,
          },
          left: {
            x: -48,
            y: 129,
            scale: 1,
          },
        },
        superhard: {
          'top-right': {
            x: 300,
            y: -44,
            scale: 1,
          },
          'top-left': {
            x: 200,
            y: -44,
            scale: -1,
          },
          right: {
            x: 513,
            y: 130,
            scale: -1,
          },
          left: {
            x: -48,
            y: 129,
            scale: 1,
          },
        },
      },
      tutorialSpeech: {
        dailyChallenge: {
          sagaMap: [
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [1, 1],
              text: 'What do we have here?',
              pos: 'right',
              x: 50,
              y: 10,
              duration: 3000,
            },
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [1, 1],
              text: 'This cookie found our hideout.',
              pos: 'left',
              x: 50,
              y: 10,
              duration: 3000,
            },
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [1, 1],
              text: 'How is that possible!?',
              pos: 'right',
              x: 50,
              y: 10,
              duration: 3000,
            },
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [1, 1],
              text: 'Well it\'s not like it\'s hidden...',
              pos: 'left',
              x: 50,
              y: 10,
              duration: 3000,
            },
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [1, 1],
              text: 'Are you looking to challenge us?',
              pos: 'right',
              x: 50,
              y: 10,
              duration: 3000,
            },
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [1, 1],
              text: 'Good luck with that!',
              pos: 'double',
              x: 50,
              y: 10,
              duration: 3000,
            },
          ],
          sagaMapRetry0: [
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [1, 1],
              text: 'Are you looking for a rematch?',
              pos: 'double',
              x: 50,
              y: 10,
            },
          ],
          sagaMapRetry1: [
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [1, 1],
              text: 'You\'re a persistent one aren\'t you?',
              pos: 'double',
              x: 50,
              y: 10,
            },
          ],
          sagaMapRetry2: [
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [1, 1],
              text: 'We meet again!',
              pos: 'double',
              x: 50,
              y: 10,
            },
          ],
        },
      },
    },
  },

  unlimitedLivesMinDefaultReward: 15, // the unlimited lives reward for treasure hunt and daily missions

  // ===================================================================
};
