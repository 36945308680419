// import ShareTickButton from '../ShareTickbutton';
import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { TextTimer } from '../TextTimer';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

export class Window_DailyReward extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} dayToShow
   * @param {Function} animCallback
   */
  constructor(parent, dayToShow, animCallback) {
    super(parent);

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._dayIndex = game.math.clamp(dayToShow.getDayIndex(), 0, 6);

    this.y = 50;

    this._initLayout();
    this._showGiftWeek(animCallback);

    G.sb('hideMainHighscorePanel').dispatch();
    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenDailyRewardShareBtn', 1);
  }

  /**
   * init the layout and title
   */
  _initLayout() {
    this._bg = this.addGeneric9SliceBackground(522, 920);
    this._bg.y = 120;
    this._ribbon = G.makeImage(0, -285, 'daily_rewards_ribbon', 0.5, this);

    this._title = new G.Text(
      G.OMTsettings.elements.dailyReward.title.x,
      G.OMTsettings.elements.dailyReward.title.y,
      `${OMT.language.getText('Daily Reward')}!`,
      G.OMTsettings.elements.dailyReward.title.style,
      0.5, 400,
    );
    this._title.padding.x = 10;
    this._title.setShadow(0, -4, 'rgba(175,95,26,1)', 0);
    this.add(this._title);
  }

  /**
   * show week / gifts
   * @param {*} animCallback
   */
  _showGiftWeek(animCallback) {
    const finalY = this._isLandscape ? -5 : -30;
    game.add.tween(this).to({ y: finalY }, 300, Phaser.Easing.Sinusoidal.InOut, true);
    // game.add.tween(this._bgBottom).to({ y: 60 }, 300, Phaser.Easing.Sinusoidal.Out, true);
    this._week = this.add(new G.DailyRewardWeek(0, 15, this._dayIndex, undefined, animCallback));

    this._comeBackTxttn = this.add(new G.Text(0, 245, OMT.language.getText('Come back tomorrow for more rewards!'), {
      style: 'window-blue-2',
      fontSize: '30px',
      lineSpacing: G.OMTsettings.elements.dailyReward.comeBackTxt.lineSpacing,
    }, 0.5, 400, null, true, 'center'));
    this._comeBackTxttn.scale.setTo(0);
    game.add.tween(this._comeBackTxttn.scale)
      .to({
        x: 1,
        y: 1,
      }, 600, Phaser.Easing.Elastic.Out, true, 200);

    this._timerBg = G.makeImage(0, 308, 'future_day_box_big', 0.5, this);
    this._timerBg.height = 40;
    this._timerBg.alpha = 0;
    game.add.tween(this._timerBg).to({ alpha: 1 }, 600, Phaser.Easing.Sinusoidal.Out, true, 600);
    this.timer = new TextTimer({
      x: 0,
      y: 310,
      date: G.saveState.data.dailyReward.nextDaily * (24 * 60 * 60 * 1000),
      style: 'window-blue-2',
      anchor: 0.5,
    });
    this.add(this.timer);
    this.timer.alpha = 0;
    game.add.tween(this.timer).to({ alpha: 1 }, 600, Phaser.Easing.Sinusoidal.Out, true, 600);

    const onClick = () => {
      this._coolBtn.inputEnabled = false;
      this.closeWindow();
      G.sb('showMainHighscorePanel').dispatch();
    };
    this._coolBtn = new OMT_UI_SquareButton(0, 385, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Cool'),
        textStyle: {
          style: 'font-white',
          fontSize: 40,
        },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });
    this._coolBtn.scale.setTo(0);
    game.add.tween(this._coolBtn.scale)
      .to({ x: 1, y: 1 }, 300, Phaser.Easing.Cubic.Out, true, 400);
    this.add(this._coolBtn);

    // this._shareUI = new ShareTickButton(0, 440, {
    //   style: 'font-white',
    //   fontSize: 36,
    //   lineSpacing: -10,
    // }, true);
    // this._shareUI.signals.onClick.addOnce(this._handleShare, this);
    // this.add(this._shareUI);
  }

  /**
   * handle share clicked
   */
  _handleShare() {
    // eslint-disable-next-line no-unused-vars
    const userConfig = OMT.envData.settings.user;
    G.GiftUI.HelperFunctions.showShareLoadingOverlay(this);
    const closed = false;

    const timeout = G.AsyncUtils.createScheduler(game);
    timeout.rescheduleAction(() => {
      this.closeWindow();
      G.sb('showMainHighscorePanel').dispatch();
    }, 5000);

    OMT.social.shareDailyRewardGetMsg(() => {
      // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('usedDailyRewardShareBtn', 1);
      if (closed) return;
      timeout.cancelSchedule();
      this.closeWindow();
      G.sb('showMainHighscorePanel').dispatch();
    });
  }
}


// create global references
if (!window.Windows) window.Windows = {};
Windows.dailyReward = Window_DailyReward;
