import { GameScaleController } from './Scaling/GameScaleController';
import { OMT_AssetLoader } from '../Services/OMT/OMT_AssetLoader';
import { lodash } from 'lodash'; /* eslint-disable-line no-unused-vars */ // Oddly required.

export class Boot {
  /**
   * Phaser state init
   */
  init() {
    this._initLegacyGlobalVars();
    this._initExternalLoader();
    this._initSoundManager();
    this._configurePhaserAndStage();

    // configure game scaling and tiling background
    GameScaleController.getInstance().init(game);
  }

  /**
   * preload assets for BOOT only
   */
  preload() {
    OMT_AssetLoader.getInstance().loadBootAssets(() => { this._onBootAssetsLoaded(); });
  }

  /**
   * called when BOOT assets are loaded
   */
  _onBootAssetsLoaded() {
    game.resizeGame();
    game.state.start('Preloader');
  }

  /**
   * init legacy global variables
   */
  _initLegacyGlobalVars() {
    window.game = game;

    G.ALL_LEVELFINISH = false;
    G.PERLEVELHIGHSCORE = true;
    G.LIVES = true;
    G.MYSTERYGIFT = true;
    G.PERLEVEL_LEADERBOARD = true;
    G.GINGY_TUTORIAL = true;

    G.PerSessionSettings = {
      shareBoxesCheckedByDefault: false,
      interstitialsInSession: 0,
      sessionId: game.rnd.uuid().split('-')[0],
    };
  }

  /**
   * init external loader global ref in the legacy format.e.
   */
  _initExternalLoader() {
    G.extLoader = new G.ExtLoader(game);
    G.extLoader.crossOrigin = 'anonymous';
  }

  /**
   * configure further Phaser and stage settings
   */
  _configurePhaserAndStage() {
    this.stage.disableVisibilityChange = true;
    this.stage.backgroundColor = 0x000000;
    this.input.maxPointers = 1; // no-multitouch

    game.time.advancedTiming = true; // set use of advanced timing
    game.tweens.frameBased = false; // set time based tweens as default
    // fixed for high refresh rates
    game.time.desiredFps = 300;
    game.time.slowMotion = 1;
    // game.forceSingleRender = false;
    // game.forceSingleUpdate = false;

    // eslint-disable-next-line no-undef
    game.plugins.add(PhaserNineSlice.Plugin);

    // set global mousehweel input event
    this.input.mouseWheel.callback = (event) => {
      G.sb('mouseWheelEvent').dispatch(event);
    };
  }

  /**
   * init the sound manager instance and set the global reference in the legacy format.
   */
  _initSoundManager() {
    // init sound manager instance
    G.soundManager = G.createSoundManager(
      () => game.sound.mute,
      (muted) => { game.sound.mute = muted; },
    );
    // set event to resume sound on focus
    game.canvas.addEventListener('click', () => {
      try {
        game.sound.context.resume();
      } catch (e) {
        //
      }
    });
  }
}
