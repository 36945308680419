if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getBoxJumpAnimation = function getBoxJumpAnimation() {
  return { animate: animate };

  function animate(target) {
    if(!target) target = this;
    if (
      target.alpha == 1 &&
      target.visible &&
      target.game !== null &&
      !target.opened &&
      !target.stopShake
    ) {
      var moveTweenA = game.add
        .tween(target)
        .to({ y: -15 }, 150, Phaser.Easing.Cubic.Out);
      var moveTweenB = game.add
        .tween(target)
        .to({ y: 0 }, 150, Phaser.Easing.Circular.In);
      moveTweenA.chain(moveTweenB);
      moveTweenA.start();

      var tweenAngleA = game.add
        .tween(target)
        .to({ angle: -3 }, 100, Phaser.Easing.Cubic.InOut);
      var tweenAngleB = game.add
        .tween(target)
        .to({ angle: 3 }, 170, Phaser.Easing.Sinusoidal.In);
      var tweenAngleC = game.add
        .tween(target)
        .to({ angle: 0 }, 30, Phaser.Easing.Cubic.InOut);

      tweenAngleA.chain(tweenAngleB, tweenAngleC);
      tweenAngleA.start();

      game.time.events.add(1000, function(){animate(target);}, this);
    }
  };
}
