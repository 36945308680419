if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getClockPointersAnimation = function getClockPointersAnimation() {

  var minutesStepsAmount = 12;
  var minutesPoitnerLoopDuration = minutesStepsAmount * 1000;
  var hoursFactor = 12;
  var fullLoopDuration = minutesPoitnerLoopDuration * hoursFactor;

  return { animate: animate };

  function animate(game, target) {

    var startOffset = Math.floor(hoursFactor * Math.random()) / hoursFactor;

    var pointer1 = G.Utils.find1stObjectWithName(target, "pointer1");
    var pointer2 = G.Utils.find1stObjectWithName(target, "pointer2");

    game.add.tween(target).to(
      null,
      fullLoopDuration, Phaser.Easing.Linear.None, true, 0,
      -1
    ).onUpdateCallback(update);

    function update(tween, progress) {
      var minutesSteps = progress * hoursFactor * minutesStepsAmount;

      // Snapping remainder for ticky animation
      var fullMinutesSteps = Math.floor(minutesSteps);
      var currentMinuteStepProgress = minutesSteps - fullMinutesSteps;
      var showMinutes = fullMinutesSteps + Phaser.Easing.Elastic.Out(
        Math.min(
          1,
          currentMinuteStepProgress / 0.33
        )
      );

      var showProgress1h = showMinutes / minutesStepsAmount;
      var showProgress12h = showProgress1h / hoursFactor;

      if (pointer1) {
        pointer1.angle = 360 * (startOffset + showProgress12h);
      }
      if (pointer2) {
        pointer2.angle = 360 * (startOffset + showProgress12h) * hoursFactor;
      }
    }
  }
}
