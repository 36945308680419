import { Window_OutOfMovesIAP } from '../Windows/OOM/Window_outOfMovesIAP';
import TreasureHuntLossAversionWheelGroup from './TreasureHuntLossAversionWheelGroup';
import { TreasureHuntOOMFailView } from './TreasureHuntOOMFailView';

/**
 * token event out of moves window
 */
class Window_TreasureHuntOOM extends Window_OutOfMovesIAP {
  /**
   * init the background graphics
   */
  _initBackground() {
    this.addBackground('popup_background_treasureHunt');
  }

  /**
   * init the fail view, overrides baseclass
   */
  _initFailView() {
    const { additionalBoosterData, tokens } = this._popUpConfig;
    const failViewInstanece = new TreasureHuntOOMFailView(additionalBoosterData);
    failViewInstanece.setTokens(tokens);
    super._initFailView(failViewInstanece);
  }

  /**
   * Makes the class that is the loss aversion wheel group
   * @param {{failFlow: boolean, coinTargetObject:Phaser.DisplayObject}} featureConfig
   * @returns {LossAversionWheelGroup}
   */
  _makeLossAversionWheelGroup(featureConfig) {
    return new TreasureHuntLossAversionWheelGroup(featureConfig);
  }

  /**
   * overrides event check for special event
   */
  _shouldShowEventGingyNow() {
  }

  /**
   * go to coin shop. hide gingy if needed.
   */
  _goToShopCoin() {
    super._goToShopCoin('treasureHuntOutOfMoves');
  }
}

if (!window.Windows) window.Windows = {};
Windows.treasureHuntOutOfMoves = Window_TreasureHuntOOM;
