import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

let _trackerInstance = null;

/**
 * Used for not getting the keys wrong when trying to use getUserCooldownRemaining
 */
export const FORTUNE_COOLDOWN_KEY = {
  // ACTIVE: 'fortuneCookie_active',
  COOLDOWN: 'fortuneCookie_cooldown',
};

/**
 * Manages the stuff in the savestate that is related to the fortune cookie!
 */
export default class FortuneCookieDataManager {
  /**
   * get singleton instance
   */
  static getInstance() {
    if (_trackerInstance === null) {
      _trackerInstance = new FortuneCookieDataManager();
    }
    return _trackerInstance;
  }

  constructor() {
    this.externalSave = new Phaser.Signal();

    // Tracks data for one level (or until reset)
    this._missionData = {
      timesDismissed: 0, // Total number of times the cookie has been dismissed
      timesSelected: 0, // Total number of times a cookie has been selected
      timesOpened: 0, // Total number of times a cookie has been opened
      timesShared: 0, // Total number of times a cookie has been potentially shared
      timesFullDismiss: 0, // Total number of times the event was disabled from dismiss limit
      timesSpawned: 0, // Total number of times the cookie was spawned
      timesCollected: 0, // Total number of times the cookie was collected
      currentMsg: '', // The id of the most recent msg that was shown
      currentCategory: '', // The id of the most recent category that was shown
    };
  }

  /**
   * Inits data with default values
   * @param {Object} dataReference
   */
  initData(dataReference) {
    this.dataReference = dataReference;

    const defaultData = {
      timesDismissed: 0, // Total number of times the cookie has been dismissed
      messagesSeen: [], // Messages seen array index
      timesOpened: 0, // Total number of times a cookie has been opened
      timesShared: 0, // Total number of times a cookie has been potentially shared
      timesFullDismiss: 0, // Total number of times the event was disabled from dismiss limit
      timesSpawned: 0, // Total number of times the cookie was spawned
      timesCollected: 0, // Total number of times the cookie was collected
      currentMsg: '', // The id of the most recent msg that was shown
      currentCategory: '', // The id of the most recent category that was shown
    };

    OMT_Utils.mergeMissingObject(this.dataReference, defaultData);
  }

  /**
   * Init player characterization parameters with fortune cookie data
   */
  initDataCapture() {
    if (!this.dataReference) return;
    /*this.dataCapture = DDNA.tracking.getDataCapture();

    this.dataCapture.setPlayerCharacterizationParam(
      'fortuneCookieTotalDismissed',
      this.dataReference.timesDismissed,
      false,
    );

    this.dataCapture.setPlayerCharacterizationParam(
      'fortuneCookieTotalEventsRejected',
      this.dataReference.timesFullDismiss,
      false,
    );

    this.dataCapture.setPlayerCharacterizationParam(
      'fortuneCookieTotalCategorySelected',
      this.dataReference.timesOpened,
      false,
    );

    this.dataCapture.setPlayerCharacterizationParam(
      'fortuneCookieTotalShared',
      this.dataReference.timesShared,
      false,
    );

    this.dataCapture.setPlayerCharacterizationParam(
      'fortuneCookieTotalSpawned',
      this.dataReference.timesSpawned,
      false,
    );

    this.dataCapture.setPlayerCharacterizationParam(
      'fortuneCookieTotalCollected',
      this.dataReference.timesCollected,
      false,
    );*/
  }

  /**
   * Reset mission data, typically at mission completion or failure
   */
  resetMissionData() {
    this._missionData = {
      timesDismissed: 0, // Total number of times the cookie has been dismissed
      timesSelected: 0, // Total number of times a cookie has been selected
      timesOpened: 0, // Total number of times a cookie has been opened
      timesShared: 0, // Total number of times a cookie has been potentially shared
      timesFullDismiss: 0, // Total number of times the event was disabled from dismiss limit
      timesSpawned: 0, // Total number of times the cookie was spawned
      timesCollected: 0, // Total number of times the cookie was collected
      currentMsg: '', // The id of the most recent msg that was shown
      currentCategory: '', // The id of the most recent category that was shown
    };
  }

  /**
   * Whenever the user dismisses the fortune cookie, the counter goes up.
   * After it gets past the limit, it resets and enters the cooldown.
   */
  triggerDismiss() {
    if (!(OMT.feature.getFortuneCookieEvent())) {
      console.log('Fortune Cookie event not active');
      return;
    }
    this.incrementParameter('timesDismissed', 'fortuneCookieTotalDismissed');

    if ((this.dataReference.timesDismissed % G.featureUnlock.fortuneCookie.dismissLimit) === 0) {
      this.incrementParameter('timesFullDismiss', 'fortuneCookieTotalEventsRejected');
      this.setInactiveTillNextReset();
    }
  }

  /**
   * Here are your messages!
   * @returns {Array<{ i: number, c: string }>}
   */
  getMessages() {
    return this.dataReference.messagesSeen;
  }

  /**
   * Saves the message
   * @param {Object} messageData
   * @param {number} messageData.i The index of the message
   * @param {string} messageData.c The category of the message
   * @param {boolean} saveNow
   */
  setMessageSeen(messageData, saveNow = true) {
    this.dataReference.messagesSeen.push(messageData);
    if (saveNow) {
      G.saveState.fortuneCookie_setData(this.dataReference);
    }
  }

  /**
   * Removes all messages seen in the given category by simply remaking the array without it
   * @param {string} category
   */
  clearMessageCategory(category) {
    this.dataReference.messagesSeen = this.dataReference.messagesSeen.filter((message) => message.c !== category);
  }

  /**
   * Sets the fortune cookie to inactive until the next upcoming reset day
   */
  setInactiveTillNextReset() {
    const nextResetDay = new Date();
    const now = nextResetDay.getTime();
    nextResetDay.setUTCHours(0, 0, 0, 0);
    // Add on the number of days till the next reset day to today
    nextResetDay.setUTCDate(nextResetDay.getUTCDate() + ((7 - nextResetDay.getUTCDay()) % 7) + G.featureUnlock.fortuneCookie.resetDay);
    const difference = nextResetDay.getTime() - now;
    G.saveState.setUserCooldown(FORTUNE_COOLDOWN_KEY.COOLDOWN, '', difference);
  }

  /**
   * Increments a parameter
   * @param {string} paramName parameter name in dataReference and missionData
   * @param {string} pcName parameter name in player characterization data
   * @param {boolean} saveNow save the changes to the GBC now
   */
  incrementParameter(paramName, pcName, saveNow = true) {
    this.dataReference[paramName]++;
    this._missionData[paramName]++;
    /* this.dataCapture.setPlayerCharacterizationParam(
      pcName,
      this.dataReference[paramName],
      false,
    ); */
    if (saveNow) {
      G.saveState.fortuneCookie_setData(this.dataReference);
    }
  }

  /**
   * Gets number of times the fortune cookie window was dismissed
   * @returns {number}
   */
  get timesDismissed() {
    return this.dataReference.timesDismissed;
  }

  /**
   * Increments the number of times the fortune cookie window was opened
   */
  incrementOpened() {
    this.incrementParameter('timesOpened', 'fortuneCookieTotalCategorySelected');
  }

  /**
   * Returns the number of times the fortune cookie window was opened
   * @returns {number}
   */
  get timesOpened() {
    return this.dataReference.timesOpened;
  }

  /**
   * Increments the number of times the fortune cookie window was selected (mission only)
   */
  incrementSelected() {
    this._missionData.timesSelected++;
  }

  /**
   * Increments the number of times a message has been potentially shared
   */
  incrementShared() {
    this.incrementParameter('timesShared', 'fortuneCookieTotalShared');
  }

  /**
   * Returns the number of times a cookie has been shared
   * @returns {number}
   */
  get timesShared() {
    return this.dataReference.timesShared;
  }

  /**
   * Returns the number of times the fortune cookie event ended because of a full dismiss
   * @returns {number}
   */
  get timesFullDismiss() {
    return this.dataReference.timesFullDismiss;
  }

  /**
   * Increments the number of times the fortune cookie has been spawned
   */
  incrementSpawned() {
    this.incrementParameter('timesSpawned', 'fortuneCookieTotalSpawned');
  }

  /**
   * Returns the number of times the fortune cookie has been spawned
   * @returns {number}
   */
  get timesSpawned() {
    return this.dataReference.timesSpawned;
  }

  /**
   * Increments the number of times the fortune cookie has been collected
   * @returns {number}
   */
  incrementCollected() {
    this.incrementParameter('timesCollected', 'fortuneCookieTotalCollected');
  }

  setCurrentCategory(catId) {
    this.dataReference.currentCategory = catId;
    this._missionData.currentCategory = catId;
  }

  setCurrentMsg(msgId) {
    this.dataReference.currentMsg = msgId;
    this._missionData.currentMsg = msgId;
  }

  /**
   * Returns the number of times the fortune cookie has been collected
   * @returns {number}
   */
  get timesCollected() {
    return this.dataReference.timesCollected;
  }

  get missionData() {
    return this._missionData;
  }
}
