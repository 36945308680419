import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../States/Scaling/GameScaleController';

export default class OMT_UI_DrawUtil {
  /**
   * Creates a nineslice box of varying width. Useful for images that need a tile visual but nineslice for elasticity
   * @param {Object} config
   * @param {string} config.spreadsheetName
   * @param {string} config.assetName
   * @param {Phaser.Rectangle} config.sliceDimensions
   * @param {number} config.bodyWidth
   * @param {?number} config.offset
   * @param {?number} config.offset.left
   * @param {?number} config.offset.right
   * @returns {Phaser.Group}
   */
  static drawNineSliceTileBox(config) {
    const rightSide = new Phaser.Image(game, 0, 0, config.spreadsheetName, config.assetName); // Make the right side first so I can copy its texture

    const targetSlice = config.sliceDimensions;
    const leftSide = new Phaser.Image(game, 0, 0, rightSide.generateTexture(1, PIXI.scaleModes.DEFAULT, game.renderer)); // Make new image with copied texture
    leftSide.crop(new Phaser.Rectangle(0, 0, targetSlice.left, leftSide.height)); // Crop out the rest of the part. Just keep the left side
    leftSide.x = 0;

    const middleTexture = rightSide.generateTexture(1, PIXI.scaleModes.DEFAULT, game.renderer); // Copy texture so I can crop it
    middleTexture.crop = new Phaser.Rectangle(targetSlice.left, 0, middleTexture.width - targetSlice.right - targetSlice.left, middleTexture.height); // Get the middle
    const middleSide = new Phaser.TileSprite(game, 0, 0, 0.5 + config.bodyWidth - targetSlice.left - targetSlice.right, middleTexture.height, middleTexture); // Tilesprite using texture
    middleSide.tileScale.x = 0.5;
    middleSide.x = leftSide.x + leftSide.width;

    // Finally use the image and just modify it.
    rightSide.crop(new Phaser.Rectangle(rightSide.width - targetSlice.right, 0, targetSlice.right, rightSide.height), true); // Crop out the not-right side
    rightSide.x = middleSide.x + middleSide.width;

    if (config.offset && config.offset.left) {
      leftSide.x += config.offset.left;
    }
    if (config.offset && config.offset.right) {
      rightSide.x += config.offset.right;
    }

    const border = new Phaser.Group(game, null); // Combine!
    border.addChild(leftSide);
    border.addChild(middleSide);
    border.addChild(rightSide);
    return border;
  }

  /**
   *
   * @param {Object} config
   * @param {Phaser.Rectangle} config.iconDimension
   * @param {number} config.width
   * @param {Array<{ amount:number, prize:string }>} config.prizes
   * @param {Object} config.textStyle
   * @param {string} config.textStyle
   */
  static drawDynamicPrizes(config) {
    const givenWidth = config.width;
    const prizeSection = new Phaser.Group(G.game, null);
    const iconDimensions = config.iconDimension;

    const prizes = []; // Array of row elements
    const makeNewRowElement = () => ({ width: 0, elements: [] }); // Returns the bare minimum for row positioning
    let rowElements = makeNewRowElement();
    const horizontalSpacing = 30;
    for (let i = 0; i < config.prizes.length; i++) {
      const curPrize = config.prizes[i];
      const prizeSect = new Phaser.Group(G.game, null);
      let isCoin = false; // Its not a coin~ :J
      let icon;
      switch (curPrize.prize) {
        case 'coin':
          icon = G.makeImage(0, 0, 'coin_3', [0, 0.5], prizeSect);
          isCoin = true; // It is a coin! O_O;
          break;
        case 'life':
          icon = G.makeImage(0, 0, 'heart', [0, 0.5], prizeSect);
          break;
        default: // Perhaps bad, but default is assuming booster
          icon = G.makeImage(0, 0, this.getGiftString(curPrize.prize), [0, 0.5], prizeSect); // Get the correct booster image
          break;
      }
      icon.width = icon.height = iconDimensions.width;

      let amountText;
      if (isCoin) { // Coin gets a different text
        amountText = new G.Text(Math.round(iconDimensions.width) + 5, 0, `${curPrize.amount}`, config.textStyle, [0, 0.5], givenWidth, 120, true, 'center');
      } else {
        amountText = new G.Text(Math.round(iconDimensions.width) + 5, 0, `x ${curPrize.amount}`, config.textStyle, [0, 0.5], givenWidth, 120, true, 'center');
      }
      prizeSect.addChild(amountText);

      if (rowElements.width + prizeSect.width + horizontalSpacing > givenWidth) { // If everything combined exceeds the given width
        prizes.push(rowElements); // The current row is pushed into prizes
        rowElements = makeNewRowElement(); // A new current row is made
      }
      rowElements.width += prizeSect.width; // The width is added
      rowElements.elements.push(prizeSect); // The element is added to the current row
    }
    if (rowElements.width > 0) { // If theres any leftover elements
      prizes.push(rowElements);
    }

    // Now positioning elements in their rows
    let nextY = 0;
    for (let i = 0; i < prizes.length; i++) { // For all rows
      const elements = prizes[i]; // Element row
      // Dynamically finding out the horizontal spacing between elements (or 0 if just 1 element)
      const horiSpacing = elements.elements.length > 1 ? ((elements.width / 2) / elements.elements.length) / 2 : 0;
      let nextX = -(elements.width + horiSpacing) / 2; // Offsetting the initial X
      for (let j = 0; j < elements.elements.length; j++) { // For all elements in element row
        const elem = elements.elements[j];
        elem.x = Math.round(nextX);
        elem.y = Math.round(nextY);
        nextX += elem.width + horiSpacing;
        prizeSection.addChild(elem);
      }
      nextY += iconDimensions.height + 5;
    }

    return prizeSection;
  }

  /**
   * Algorithm for the tokenEvent postcard prizes.
   * Its very customized for tokenEvent and probably can't be reused unless tweaking is made
   * @param {Object} config
   * @param {Phaser.Rectangle} config.iconDimension
   * @param {number} config.width
   * @param {Array<{ amount:number, prize:string }>} config.prizes
   * @param {Object} config.textStyle
   * @param {string} config.textStyle
   */
  static drawDynamicCustomPrize(config) {
    const givenWidth = config.width;
    const prizeSection = new Phaser.Group(G.game, null);
    const iconDimensions = config.iconDimension;

    const prizes = []; // Array of row elements
    const makeNewRowElement = () => ({ width: 0, elements: [] }); // Returns the bare minimum for row positioning
    let rowElements = makeNewRowElement();
    const horizontalSpacing = 30;
    for (let i = 0; i < config.prizes.length; i++) {
      const curPrize = config.prizes[i];
      const prizeSect = new Phaser.Group(G.game, null);
      let icon;
      switch (curPrize.prize.toLowerCase()) {
        case 'coin':
          icon = G.makeImage(0, 0, 'coin_3', 0.5, prizeSect);
          {
            const bar = G.makeImage(0, 0, 'tokenEventRewardTag', 0.5, icon);
            bar.y = iconDimensions.height - bar.height;
            const text = new G.Text(0, 3, `${OMT.language.toLocaleNumber(curPrize.amount)}`, config.textStyle, 0.5, bar.width, bar.height * 1.15, true, 'center');
            bar.addChild(text);
          }
          break;
        case 'life':
          icon = G.makeImage(0, 0, 'heart', 0.5, prizeSect);
          {
            const bar = G.makeImage(0, icon.height / 2, 'tokenEventRewardTag', 0.5, icon);
            const text = new G.Text(0, 3, `${OMT.language.toLocaleNumber(curPrize.amount)}`, config.textStyle, 0.5, bar.width, bar.height * 1.15, true, 'center');
            bar.addChild(text);
          }
          break;
        case 'lifeunlimited':
          icon = new Phaser.Group(game, prizeSect);
          {
            const heart = G.makeImage(0, 0, 'heart_unlimited', 0.5, icon);
            const bar = G.makeImage(0, 0, 'tokenEventRewardTag', 0.5, icon);
            bar.y = iconDimensions.height - bar.height;
            heart.scale.set(1.3);
            const text = new G.Text(0, 3, '%TIME% min'.replace('%TIME%', curPrize.amount),
              config.textStyle, 0.5, bar.width, bar.height * 1.15, true, 'center');
            bar.addChild(text);
          }
          break;
        default: // Perhaps bad, but default is assuming booster
          icon = G.makeImage(0, 10, this.getGiftString(curPrize.prize), 0.5, prizeSect); // Get the correct booster image
          if (curPrize.amount > 1) {
            const amountBox = G.makeImage(-icon.width / 2.2, -icon.height / 2.2, 'booster_ammount', 0.5, icon);
            const text = new G.Text(0, 3, curPrize.amount, G.OMTsettings.elements.UI_StartBoosterButton.amountTxt.style, 0.5, 100);
            amountBox.addChild(text);
          }
          break;
      }
      icon.width = icon.height = iconDimensions.width;

      if (rowElements.width + prizeSect.width + horizontalSpacing > givenWidth) { // If everything combined exceeds the given width
        prizes.push(rowElements); // The current row is pushed into prizes
        rowElements = makeNewRowElement(); // A new current row is made
      }
      rowElements.width += prizeSect.width; // The width is added
      rowElements.elements.push(prizeSect); // The element is added to the current row
    }
    if (rowElements.width > 0) { // If theres any leftover elements
      prizes.push(rowElements);
    }

    // Now positioning elements in their rows
    let nextY = 0;
    for (let i = 0; i < prizes.length; i++) { // For all rows
      const elements = prizes[i]; // Element row
      // Dynamically finding out the horizontal spacing between elements (or 0 if just 1 element)
      const horiSpacing = elements.elements.length > 1 ? ((elements.width / 2) / elements.elements.length) / 2 : 0;
      let nextX = 0;
      if (elements.elements.length > 1) { // Offsetting the initial X
        if (elements.elements.length % 2 === 0) {
          nextX = (horiSpacing - elements.width) / 2;
        } else {
          nextX = horiSpacing - elements.width / 2;
        }
      }
      for (let j = 0; j < elements.elements.length; j++) { // For all elements in element row
        const elem = elements.elements[j];
        elem.x = Math.round(nextX);
        elem.y = Math.round(nextY);
        nextX += elem.width + horiSpacing;
        prizeSection.addChild(elem);
      }
      nextY += iconDimensions.height + 5;
    }

    return prizeSection;
  }

  /**
   * Static function used by most of RequestHelp feature. Gets the asset string
   * @param {string} str
   */
  static getGiftString(str) {
    let giftString = '';
    if (str.indexOf('#9') > -1) {
      giftString = 'ui_booster_9';
    } else {
      giftString = G.gift.getIconForType(str);
    }

    return giftString;
  }

  /**
   * PLEASE REMEMBER TO DESTROY THE BITMAPDATA TEXTURE WHEN YOU ARE DONE WITH IT
   *
   * Creates a bitmap texture that with a gradient class
   * Draw using the returned texture, and passing the gradient as the fill
   * @param {Array<{string}>} gradientData
   * @param {Phaser.Game} inGame
   * @param {string} bitmapTextureName
   * @param {Phaser.Rectangle} bitmapDimensions
   * @param {string} style Only available style is linear
   * @param {Array<Phaser.Point>} styleData
   * @returns {{bitmap:Phaser.BitmapTexture, gradient:any}}
   */
  static createGradientTexture(gradientData, inGame, bitmapTextureName, bitmapDimensions, style, styleData) {
    if (!bitmapTextureName) {
      bitmapTextureName = `${Math.random() * 10000}bitmapTextureData`;
    }
    const bitmapTexture = new Phaser.BitmapData(inGame, bitmapTextureName, bitmapDimensions.width, bitmapDimensions.height);
    let gradient;
    const setGradient = (gData, givenGradient) => {
      const arrLength = gData.length - 1;
      for (let i = 0; i < gData.length; i++) {
        givenGradient.addColorStop(i / arrLength, gData[i]);
      }
    };
    if (style.toLowerCase() === 'linear') {
      gradient = bitmapTexture.context.createLinearGradient(styleData[0].x, styleData[0].y, styleData[1].x, styleData[1].y);
      setGradient(gradientData, gradient);
    } else {
      console.log('Invalid style! Cannot create gradient!');
      bitmapTexture.destroy();
      return null;
    }

    return {
      bitmap: bitmapTexture,
      gradient,
    };
  }

  /**
   * Rotates avatar appearance
   * @param {Phaser.Image} avatarA
   * @param {Phaser.Image} avatarB
   * @param {Phaser.Group} avatar
   * @param {PIXI.Texture} nextTexture
   */
  static rotateAvatar(avatarA, avatarB, avatar, nextTexture, size = 80) {
    if (avatar.game) {
      avatarB.changeTexture(nextTexture); // Sets B to the next texture
      avatarB.width = avatarB.height = size; // Resize
      avatarB.alpha = 1; // Show
      avatar.addChild(avatarB); // Add behind avatarA of avatar group
      avatar.addChild(avatarA); // Add behind avatarA of avatar group
      const disappear = game.add.tween(avatarA) // AvatarA fades out, revealing B
        .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.In);

      disappear.onComplete.addOnce(() => {
        avatarA.changeTexture(nextTexture); // AvatarA changes immediately to B
        avatarA.width = avatarA.height = size;
        avatarA.alpha = 1; // AvatarA is showing now, but looks the same as B
        avatarB.alpha = 0; // AvatarB hides
      });

      disappear.start();
    }
  }

  /**
   * Draws the weird event postcard fullscreen header
   * @param {Object} config
   * @param {Array<String>} config.upperGradient
   * @param {Array<String>} config.titleGradient
   * @param {string} config.titleText
   * @param {Object} config.titleStyle or String
   */
  static drawGradientSlopedHeader(config) {
    // Top border
    const headerContainer = new Phaser.Group(game, null);

    this.redrawGradients(headerContainer, config.upperGradient, config.titleGradient);

    const text = new G.Text(0, 0, OMT.language.getText(config.titleText), config.titleStyle, 0.5,
      headerContainer.gradientData.titleBg.image.width * 0.9, headerContainer.gradientData.titleBg.image.height * 0.95, true, 'center');
    headerContainer.addChild(text);
    headerContainer.titleText = text;

    return headerContainer;
  }

  /**
   * Takes an object that is preferrably modified by redrawGradients.
   * Destroys the gradient data
   * @param {Phaser.Group} container
   */
  static clearGradientData(container) {
    if (container.gradientData) {
      for (const key in container.gradientData) {
        if (Object.prototype.hasOwnProperty.call(container.gradientData, key)) {
          const data = container.gradientData[key];
          data.image.destroy();
          data.bitmap.destroy();
        }
      }
      container.gradientData = null;
    }
  }

  /**
   * Redraws the gradients for the fullscreen header
   * BE SURE TO DELETE BITMAP DATA WHEN YOU'RE DONE
   * @param {Phaser.Group} container
   * @param {Array<String>} upperGradient
   * @param {Array<String} titleGradient
   */
  static redrawGradients(container, upperGradient, titleGradient) {
    this.clearGradientData(container);
    const isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;

    // The top gradient
    const gradientWidth = isLandscape ? game.width / GameScaleController.getInstance().gameScale : game.width;
    const gradientHeight = 110;

    const topGradientDimension = { width: gradientWidth, height: gradientHeight };
    const topBorderGradientPack = this.createGradientTexture(upperGradient, game, null, topGradientDimension, 'linear', [
      { x: topGradientDimension.width / 2, y: 0 }, // This is the positioning of the gradient points
      { x: topGradientDimension.width / 2, y: topGradientDimension.height },
    ]);
    topBorderGradientPack.bitmap.polygon([ // This draws the weird rectangle shape
      { x: 0, y: 0 },
      { x: gradientWidth, y: 0 },
      { x: gradientWidth, y: topBorderGradientPack.bitmap.height - 25 },
      { x: 0, y: topBorderGradientPack.bitmap.height },
      { x: 0, y: 0 },
    ], topBorderGradientPack.gradient);
    const pinkBg = new Phaser.Image(game, 0, 0, topBorderGradientPack.bitmap); // Put it into an image
    container.addChild(pinkBg);

    // The white text box
    const whiteTextBoxWidth = Math.min(game.width * 0.7, 448); // Width of the box
    const whiteTextBoxRadius = 20; // Radius of corners
    const whiteTextBoxHeight = 110; // Max height
    const whiteBgDimension = { width: whiteTextBoxWidth, height: whiteTextBoxHeight }; // Gradient dimensions for positioning
    const whiteGradientPack = this.createGradientTexture(titleGradient, game, null, whiteBgDimension, 'linear', [
      { x: whiteBgDimension.width / 2, y: 0 },
      { x: whiteBgDimension.width / 2, y: whiteBgDimension.height },
    ]);
    whiteGradientPack.bitmap.polygon([ // Draws that terrible box
      { x: whiteTextBoxRadius * 1.5, y: whiteTextBoxRadius },
      { x: whiteTextBoxWidth - whiteTextBoxRadius, y: 0 },
      { x: whiteTextBoxWidth, y: whiteTextBoxRadius },
      { x: whiteTextBoxWidth, y: 90 - whiteTextBoxRadius },
      { x: whiteTextBoxWidth - whiteTextBoxRadius, y: 90 },
      { x: whiteTextBoxRadius * 2, y: whiteTextBoxHeight },
      { x: whiteTextBoxRadius, y: whiteTextBoxHeight - whiteTextBoxRadius + 5 },
      { x: whiteTextBoxRadius * 0.5, y: whiteTextBoxRadius * 2 },
      { x: whiteTextBoxRadius * 1.5, y: whiteTextBoxRadius },
    ], whiteGradientPack.gradient); // Below adds in the circle corners
    whiteGradientPack.bitmap.circle(whiteTextBoxWidth - whiteTextBoxRadius, whiteTextBoxRadius, whiteTextBoxRadius, whiteGradientPack.gradient);
    whiteGradientPack.bitmap.circle(whiteTextBoxWidth - whiteTextBoxRadius, 90 - whiteTextBoxRadius, whiteTextBoxRadius, whiteGradientPack.gradient);
    whiteGradientPack.bitmap.circle(whiteTextBoxRadius * 2, whiteTextBoxHeight - whiteTextBoxRadius, whiteTextBoxRadius, whiteGradientPack.gradient);
    whiteGradientPack.bitmap.circle(whiteTextBoxRadius * 1.5, whiteTextBoxRadius * 2, whiteTextBoxRadius, whiteGradientPack.gradient);
    const whiteBg = new Phaser.Image(game, 0, 0, whiteGradientPack.bitmap); // Stick it into an image
    whiteBg.x = (pinkBg.width - whiteBg.width) / 2; // Position it
    whiteBg.y = whiteTextBoxHeight * 0.25;
    container.addChild(whiteBg);

    container.gradientData = {
      backBg: { image: pinkBg, bitmap: topBorderGradientPack.bitmap },
      titleBg: { image: whiteBg, bitmap: whiteGradientPack.bitmap },
    };
  }

  /**
   * Creates an image that is masked in the shape of the given image.
   * REMEMBER TO DESTROY THE ALPHA MASK
   * @param {Phaser.Image} sourceImage
   * @param {Phaser.Bitmap} maskImage
   * @param {string} textureKeyPrefix
   * @returns {{image:Phaser.Image, alphaMask:Phaser.BitmapData}}
   */
  static createImageMask(sourceImage, maskImage, alphaMaskTextureId) {
    const alphaMask = new Phaser.BitmapData(game, alphaMaskTextureId, sourceImage.width, sourceImage.height);
    alphaMask.alphaMask(maskImage, sourceImage);
    const img = new Phaser.Image(game, 0, 0, alphaMask);
    img.anchor.set(0.5, 0.5);
    return {
      image: img,
      alphaMask,
    };
  }

  /**
   * Turns on input enabled for elements in `everything` and enables drag. It spits out its position after.
   * Used for getting location of where assets should be. Should be taken off before production
   * @param {Array<Phaser.Sprite} everything
   */
  static debugPlacement(everything) {
    everything.forEach((thing) => {
      let target = thing;
      target.inputEnabled = true;
      if (!thing.input) {
        const spr = new Phaser.Sprite(game);
        thing.parent.addChild(spr);
        spr.x = thing.x;
        spr.y = thing.y;
        spr.angle = thing.angle;
        spr.inputEnabled = true;
        thing.x = 0;
        thing.y = 0;
        thing.angle = 0;
        thing.inputEnabled = false;
        spr.addChild(thing);
        console.warn(thing, 'has been parented to a sprite to move');
        target = spr;
      }
      target.input.enableDrag(false);
      target.events.onDragStop.add(() => { console.log(target.position); });
    });
  }
}
