/* eslint-disable no-use-before-define */

/**
 * class for the ask friends status indicator header
 */
export class AskFriendsStatusHeader extends Phaser.Group {
  /**
   * constructor
   * @param {number} requiredAsks
   * @param {number} headerWidth
   * @param {number} indicatorRadius
   * @param {number} connectionColor
   * @param {number} inactiveConnectionColor
   */
  constructor(requiredAsks, headerWidth, indicatorRadius, connectionColor, inactiveConnectionColor) {
    super(game);

    this._headerWidth = headerWidth;
    this._connectionColor = connectionColor;
    this._inactiveConnectionColor = inactiveConnectionColor;
    this._statusIndicators = [];

    this._initStatusIndicators(requiredAsks, indicatorRadius);
  }

  /**
   * init the status indicators
   * @param {number} count
   * @param {number} indicatorRadius
   */
  async _initStatusIndicators(count, indicatorRadius) {
    let indicator;
    let labelText;
    for (let i = 0; i < count; i++) {
      labelText = `${i + 1}`;
      indicator = new AskFriendStatusIndicator(labelText, indicatorRadius, this._connectionColor, this._inactiveConnectionColor);
      this.addChild(indicator);
      if (count > 1) indicator.x = Math.round((i * this._headerWidth) / (count - 1));
      this._statusIndicators.push(indicator);
    }
  }

  /**
   * draw connections between current indicators
   */
  _drawStatusIndicatorConnections() {
    this._destroyConnectionGraphics();

    const connectionHeight = 8;
    this._connectionGroup = new Phaser.Group(game);
    this._connectionGroup.y -= connectionHeight / 2;
    this.addChildAt(this._connectionGroup, 0);

    let prevIndicator;
    let indicator;
    let connection;

    for (let i = 1; i < this._statusIndicators.length; i++) {
      prevIndicator = this._statusIndicators[i - 1];
      indicator = this._statusIndicators[i];
      connection = new Phaser.Graphics(game);
      connection.beginFill(indicator.isActive ? this._connectionColor : this._inactiveConnectionColor);
      connection.drawRect(prevIndicator.x, 0, indicator.x - prevIndicator.x, connectionHeight);
      connection.endFill();
      this._connectionGroup.addChild(connection);
    }
  }

  /**
   * set indicators to active state
   * @param {number} activeCount
   */
  setActiveIndicators(activeCount) {
    let indicator;
    for (let i = 0; i < this._statusIndicators.length; i++) {
      indicator = this._statusIndicators[i];
      indicator.toggleState(i < activeCount);
    }
    this._drawStatusIndicatorConnections();
  }

  /**
   * destroy the grahphics for the connections
   */
  _destroyConnectionGraphics() {
    if (!this._connectionGroup) return;
    this.removeChild(this._connectionGroup);
    this._connectionGroup.destroy();
    this._connectionGroup = null;
  }

  /**
   * destruction method
   */
  destroy() {
    this._destroyConnectionGraphics();
    super.destroy();
  }
}

/**
 * class for the ask friends status indicator
 */
class AskFriendStatusIndicator extends Phaser.Group {
  /**
   * constructor
   * @param {string} labelText
   * @param {number} indicatorRadius
   * @param {number} activeColor
   * @param {number} inactiveColor
   */
  constructor(labelText, indicatorRadius, activeColor, inactiveColor) {
    super(game);

    this._isActive = false;
    this._radius = indicatorRadius;

    this._initBackground(activeColor, inactiveColor);
    this._initLabel(labelText);
  }

  /**
   * true if the friend count is >= this indicators position
   * @retuns {boolean}
   */
  get isActive() {
    return this._isActive;
  }

  /**
   * toggle the active state
   * @param {boolean} active
   */
  toggleState(active) {
    this._isActive = active;
    this._activeBg.visible = active;
    this._inactiveBg.visible = !active;
  }

  /**
   * init the indicator background
   * @param {number} activeColor
   * @param {number} inactiveColor
   */
  _initBackground(activeColor, inactiveColor) {
    // active graphic
    const activeBg = new Phaser.Graphics(game);
    activeBg.beginFill(activeColor);
    activeBg.drawCircle(0, 0, this._radius * 2);
    activeBg.endFill();
    activeBg.anchor.set(0.5);
    activeBg.visible = false;
    this.addChild(activeBg);

    // inactive graphic
    const inactiveBg = new Phaser.Graphics(game);
    inactiveBg.beginFill(inactiveColor);
    inactiveBg.drawCircle(0, 0, this._radius * 2);
    inactiveBg.endFill();
    inactiveBg.anchor.set(0.5);
    inactiveBg.visible = true;
    this.addChild(inactiveBg);

    this._activeBg = activeBg;
    this._inactiveBg = inactiveBg;
  }

  /**
   * init the indicator label 1,2,3, etc..
   * @param {string} labelText
   */
  _initLabel(labelText) {
    const style = { style: 'font-white', fontSize: Math.round(this._radius) };
    const label = new G.Text(0, 3, labelText, style, [0.5, 0.5], this._radius * 2, this._radius * 2, true);
    this.addChild(label);
  }
}
