import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import { PROGRESS_MARKER_TYPE } from './UI_ProgressBarUtils';

export default class UI_ProgressBarBase extends Phaser.Group {
  /**
   * This is the progress bar seen on the top bar in the game
   * Its like a n shape and marks your points progress
   * @param {number} x
   * @param {number} y
   * @param {PROGRESS_MARKER_TYPE} markerType
   * @param {number} baseCoinMarkerScale
   * @param {number} baseStarMarkerScale
   */
  constructor(x, y, markerType, baseCoinMarkerScale, baseStarMarkerScale) {
    super(game);

    const { starsReq } = LvlDataManager.getInstance();

    this.x = Math.floor(x);
    this.y = Math.floor(y);

    this._points = 0;
    this._pointsTarget = 0;
    this._barMaxPoints = starsReq[2] * 1.2;
    this._markerType = markerType;

    this.barProgress = G.makeImage(0, 0, this._getBarProgressImage(), [0.5, 1], this); // Accessed externally

    this._barProgressMask = game.add.graphics();
    this.add(this._barProgressMask);
    this._barProgressMask.position = this.barProgress.position;
    this.barProgress.mask = this._barProgressMask;

    this._barProgressMask.beginFill(0x000000);
    G.drawCircleSegment(this._barProgressMask, 0, 0, 100, 170, 171);

    const distance = this._getStarsDistance();

    if (this._markerType !== PROGRESS_MARKER_TYPE.NONE) {
      this.stars = [
        this._drawStarAsset(1, this._markerType, starsReq[0], distance, baseCoinMarkerScale, baseStarMarkerScale),
        this._drawStarAsset(2, this._markerType, starsReq[1], distance, baseCoinMarkerScale, baseStarMarkerScale),
        this._drawStarAsset(3, this._markerType, starsReq[2], distance, baseCoinMarkerScale, baseStarMarkerScale),
      ];

      this.stars.forEach((elem, index) => {
        elem.req = starsReq[index];
      });
    }

    this._tweenObj = undefined; // To track to clean up later
    this._signalBindings = [G.sb('onPointsChange').add((amount) => {
      this._pointsTarget = amount;
    })];
  }

  /**
   * Returns asset name of the progress bar. Overwritten
   * @returns {string}
   */
  _getBarProgressImage() {
    return 'ingame_progress_bar';
  }

  /**
   * Draws the radius for the mask
   * @returns {number}
   */
  _getMaskRadius() {
    return 100;
  }

  /**
   * Returns the distance between the center and the stars
   * @returns {number}
   */
  _getStarsDistance() {
    return 100;
  }

  /**
   * Destroy!
   */
  destroy() {
    for (const binding of this._signalBindings) binding.detach();
    this._signalBindings.length = 0;
    if (this._tweenObj) {
      this._tweenObj.stop();
      this._tweenObj = null;
    }
    super.destroy();
  }

  /**
   * Draws the asset on the progress bar.
   * Is either coins or stars, depending on the markerType
   * @param {number} assetIndex Used for determining asset
   * @param {PROGRESS_MARKER_TYPE} markerType Shows as coin or stars
   * @param {number} starReq
   * @param {number} distance
   * @param {number} baseCoinMarkerScale
   * @param {number} baseStarMarkerScale
   * @returns {Phaser.Image}
   */
  _drawStarAsset(assetIndex, markerType, starReq, distance, baseCoinMarkerScale, baseStarMarkerScale) {
    if (markerType === PROGRESS_MARKER_TYPE.COINS) {
      const coinMarker = G.makeImage(
        G.lengthDirX(this._pointsToAngle(starReq), distance + 15, false),
        5 + G.lengthDirY(this._pointsToAngle(starReq), distance + 15, false),
        `coin_${assetIndex}`, 0.5, this,
      );

      const coinScale = baseCoinMarkerScale / coinMarker.width;
      coinMarker.scale.set(coinScale);
      return coinMarker;
    }

    const starMarker = G.makeImage(
      G.lengthDirX(this._pointsToAngle(starReq), distance + 15, false),
      5 + G.lengthDirY(this._pointsToAngle(starReq), distance + 15, false),
      `progress_bar_star_${assetIndex}`, 0.5, this,
    );

    starMarker.scale.set(baseStarMarkerScale);
    return starMarker;
  }

  /**
   * Clamps the number down
   * @param {number} points
   */
  _pointsToAngle(points) {
    return game.math.clamp(180 + (points / this._barMaxPoints) * 180, 0, 380);
  }

  /**
   * Progress bar tweens into shape here
   */
  update() {
    if (this._points === this._pointsTarget || this._pointsTarget === -1) return;
    this._changePoints(game.math.clamp(Math.ceil((this._pointsTarget - this._points) * 0.05), 0, this._pointsTarget - this._points));
  }

  /**
   * The visuals of the increasing points
   * @param {number} change
   */
  _changePoints(change) {
    const oldPoints = this._points;
    this._points += change;

    this._barProgressMask.clear();
    this._barProgressMask.beginFill(0x000000);
    G.drawCircleSegment(this._barProgressMask, 0, 0, this._getMaskRadius(), 90, this._pointsToAngle(this._points));

    // Track star requirements
    if (this._markerType !== PROGRESS_MARKER_TYPE.NONE) {
      for (let i = 0; i < 3; i++) {
        if (oldPoints < this.stars[i].req && this.stars[i].req <= this._points) {
          LvlDataManager.getInstance().stars++;
          if (i < 2) {
            G.sfx.xylophone_positive.play();
          } else {
            G.sfx.xylophone_positive2.play();
          }

          const starScaleX = this.stars[i].scale.x;
          const starScaleY = this.stars[i].scale.y;
          this._tweenObj = game.add.tween(this.stars[i].scale).to({ x: starScaleX + 0.5, y: starScaleY + 0.5 }, 300, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);

          for (let iterator = 0; iterator < 4; iterator++) {
            G.sb('UIfx').dispatch(this.stars[i].worldPosition.x + game.world.bounds.x, this.stars[i].worldPosition.y, 'whiteStarPart');
          }
        }
      }
    }
  }
}
