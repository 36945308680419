import { Window } from '../../../00_IMMEDIATE/Window';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { FriendshipChest_Card } from './FriendshipChest_Card';

/**
 * Friendship chest window promo that tells you how to use it!
 * @author Sandra Koo
 */
class Window_friendshipChestPromo extends Window {
  /**
   * constructor
   * @param {Object} parent
   */
  constructor(parent) {
    super(parent);

    if (game.state.current === 'World') {
      this.y = -G.WindowMgr.Constants.WorldVerticalOffset;
    }

    this.card = new FriendshipChest_Card(G.game, this, 0);
    if (OMT.feature.getFriendshipChestStory()) {
      this.card.fillCardAsStoryPromo();
    } else {
      this.card.fillCardAsPromo();
    }
    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      this.card.scale.set(1.1);
    }
    this.card.signals.onClick.add(this.onRewardMe, this);
    this.card.signals.onRejectClick.add(this.onCloseMe, this);

    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenFriendshipChestPromo', 1);
  }

  /**
   * destroy override
   */
  destroy() {
    this.card.destroyCard();
    this.card = null;
    super.destroy();
  }

  /**
   * When they click 'I want rewards!'
   * @param {FriendshipChest_Card} card
   */
  onRewardMe(card) { // eslint-disable-line no-unused-vars
    G.saveState.friendshipChestDataManager.setNumberOfLevelsToAppear(20);
    G.saveState.friendshipChestDataManager.triggerPromoInitial();
    G.sb('pushWindow').dispatch(['friendshipChest']);
    this.closeWindow();
  }

  /**
   * 'When they click no thanks...'
   * @param {FriendshipChest_Card} card
   */
  onCloseMe(card) { // eslint-disable-line no-unused-vars
    G.saveState.friendshipChestDataManager.setNumberOfLevelsToAppear(20);
    G.saveState.friendshipChestDataManager.triggerPromoInitial();
    this.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.friendshipChestPromo = Window_friendshipChestPromo;
