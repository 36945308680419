/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-spread */
const { default: ComponentGameHooks } = require('@omt-components/ComponentGameHooks');

G.Text = function (
  x,
  y,
  txt,
  style,
  anchor,
  maxWidth,
  maxHeight,
  textWrap,
  align,
  optDontDecreaseFontSize,
  optDownscaleToFit,
  optScaleUniformly,
) {
  style = G.Text.getStyle(style);

  G.Text.setMaxWidth(this, maxWidth || Infinity);
  G.Text.setMaxHeight(this, maxHeight || Infinity);

  if (textWrap) {
    style.wordWrap = true;
    style.wordWrapWidth = maxWidth;
    style.align = align || 'left';
  }

  Phaser.Text.call(this, game, x, y, txt, style);

  if (style.lineSpacing) {
    this.lineSpacing = style.lineSpacing;
  }

  if (style.shadow) {
    this.setShadow.apply(this, style.shadow);
    this.padding.setTo(style.shadow[0], style.shadow[1]);
  }

  if (anchor) {
    if (typeof anchor === 'number') {
      this.anchor.setTo(anchor);
    } else {
      this.anchor.setTo(anchor[0], anchor[1]);
    }
  }

  if (!optDontDecreaseFontSize) {
    while (
      this.width > this.customComponents.text.maxWidth
      || this.height > this.customComponents.text.maxHeight
    ) {
      this.fontSize -= 2;
      if (this.fontSize < 10) break;
    }
  }

  this.customComponents.text.scaleUniformly = optScaleUniformly;

  if (optDownscaleToFit) {
    this.applyMaxSizeConstraint();
  }
};

G.Text.prototype = Object.create(Phaser.Text.prototype);

/**
 * set the default font style
 * @param {string} defaultFont
 */
G.Text.setDefaultFont = function setDefaultFont(defaultFont) {
  G.Text.defaultFont = defaultFont;

  G.Text.defaultFontStyle = {
    font: G.Text.defaultFont,
    fontSize: '30px',
    fill: 'white',
  };
};
G.Text.setDefaultFont('Lobster');

G.Text.setMaxWidth = function setMaxWidth(target, width) {
  G.Text.ensureTextComponentAvailable(target);
  target.customComponents.text.maxWidth = width;
};

G.Text.setMaxHeight = function setMaxHeight(target, height) {
  G.Text.ensureTextComponentAvailable(target);
  target.customComponents.text.maxHeight = height;
};

G.Text.ensureTextComponentAvailable = function ensureTextComponentAvailable(target) {
  target.customComponents = target.customComponents || {};
  target.customComponents.text = target.customComponents.text || {};
};

//

G.Text.styles = {};

G.Text.addStyle = function (name, obj) {
  G.Text.styles[name] = obj;
};

G.Text.getStyle = function (style) {
  let result;

  try {
    if (typeof style !== 'object') {
      result = G.Utils.clone(G.Text.styles[style]);
    } else if (style.style || style.fontStyle) {
      style.style = style.fontStyle || style.style;
      // if style comes as {style: blue, fontSize: 20px}
      const orgStyle = G.Utils.clone(G.Text.styles[style.style]);
      if (style.scaleStroke
        && style.fontSize
        && orgStyle.fontSize
        && orgStyle.strokeThickness) {
        const orgStrokeSizeRatio = orgStyle.strokeThickness / parseInt(orgStyle.fontSize);
        style.strokeThickness = Math.ceil(parseInt(style.fontSize) * orgStrokeSizeRatio);
      }
      result = Object.assign(orgStyle, style);
    } else {
      result = style;
    }
  } catch (error) {
    result = G.Utils.clone(G.Text.defaultFontStyle);
    console.warn(`G.Text.getStyle() could not process style ${JSON.stringify(style)}. Default style was returned.`);
  }
  return result;
};

G.Text.prototype.applyMaxSizeConstraint = function () {
  this.scale.setTo(1);
  this.width = Math.min(this.width, this.customComponents.text.maxWidth);
  this.height = Math.min(this.height, this.customComponents.text.maxHeight);
  if (this.customComponents.text.scaleUniformly) {
    const minScale = Math.min(this.scale.x, this.scale.y);
    this.scale.setTo(minScale);
  }
};

G.Text.prototype.setText = function (txt) {
  Phaser.Text.prototype.setText.call(this, txt);
  this.applyMaxSizeConstraint();
};

G.Text.prototype.setStyle = function (style, update) {
  style = G.Text.getStyle(style);
  Phaser.Text.prototype.setStyle.call(this, style, update);
};

G.TextCounter = function (x, y, amount, style, anchor, maxWidth, config) {
  this.amount = amount;
  this.amountDisplayed = amount;

  G.Text.call(this, x, y, '', style, anchor, maxWidth);

  this.displayAmount();

  config = config || { lerpValue: 0.5 };

  // addConfig
  this.lerp = true;
  this.lerpValue = config.lerpValue;

  this.stepCurrent = 0;
  this.step = 0;
};

G.TextCounter.prototype = Object.create(G.Text.prototype);

G.TextCounter.prototype.setAmount = function (amount, immediately) {
  this.amount = amount;
  if (immediately) {
    this.amountDisplayed = amount;
    this.displayAmount();
  }
};

G.TextCounter.prototype.changeAmount = function (change, immediately) {
  this.amount += change;
  if (immediately) {
    this.amountDisplayed = this.amount;
    this.displayAmount();
  }
};

G.TextCounter.prototype.increaseAmount = function (change, immediately) {
  this.changeAmount(change);
};

G.TextCounter.prototype.update = function () {
  if (this.amountDisplayed !== this.amount && this.stepCurrent-- <= 0) {
    this.stepCurrent = this.step;
    if (this.lerp) this.lerpUpdate();
  }
};

G.TextCounter.prototype.lerpUpdate = function () {
  this.amountDisplayed = (G.lerp(this.amountDisplayed, this.amount, this.lerpValue, 0.2));
  this.displayAmount();
};

G.TextCounter.prototype.displayAmount = function () {
  if (this.amountDisplayed === null) this.setText('...');
  else this.setText(ComponentGameHooks.toLocaleNumber(Math.round(this.amount).toString()));
};
