/* eslint-disable no-unused-vars */
export const DEFAULT_FRICITON = 0.95;
export const DEFAULT_ELASTICITY = 0.125;
export const LOCK_INPUT_VELOCITY = 1;
export const MAX_OVERSCROLL = 150;
export const WHEEL_SCROLL_SPEED = 0.05;

/**
 * Base class for UI_VerticalScrollArea + UI_HorizontaScrollArea
 */
export class UI_ScrollAreaBase extends Phaser.Group {
  /**
   * constructor
   * @param {Phaser.Rectangle} scrollRect Rectangle for scrollable area
   */
  constructor(scrollRect) {
    super(game);

    // create signals object
    this.signals = {
      onScrolled: new Phaser.Signal(),
    };

    // pointer tracking
    this._enabled = true;
    this._pointerDown = false;
    this._pointerDownInContent = false;
    this._scrollConditions = [];

    // physics parameters
    this._friction = DEFAULT_FRICITON;
    this._elasticity = DEFAULT_ELASTICITY;

    // for input locking
    this._childInputEnabled = true;
    this._childrenWithInput = [];

    // set position from scroll rect
    this.x = scrollRect.x; this.y = scrollRect.y;

    // clone the scroll rect and make relative
    this._scrollRect = scrollRect.clone();
    this._scrollRect.x = this._scrollRect.y = 0;
    this._pointerRect = this._scrollRect.clone();
  }

  /**
   * get a reference to the scrollRect
   * @returns {Phaser.Rectangle}
   */
  get scrollRect() {
    return this._scrollRect;
  }

  /**
   * init the scroll content container
   */
  _initContentContainer() {
    this._content = new Phaser.Group(game);
    this.addChild(this._content);
  }

  /**
   * get a reference to the scroll content group
   * @returns {Phaser.Group}
   */
  get content() {
    return this._content;
  }

  /**
   * init / apply the scroll mask
   */
  _initScrollMask() {
    this._scrollMask = new Phaser.Graphics(game);
    this._drawScrollMask(this._scrollMask);
    this._scrollMask.enabled = false;
    this.addChildAt(this._scrollMask, 0);
    this._content.mask = this._scrollMask;
    // this._scrollMask.alpha = 0.5;
  }

  /**
   * draw / redraw mask graphcis
   * @param {Phaser.Graphics} graphics
   */
  _drawScrollMask(graphics) {
    if (!graphics) return;
    graphics.clear();
    graphics.beginFill(0xff0000);
    graphics.drawRect(0, 0, this._scrollRect.width, this._scrollRect.height);
    graphics.endFill();
  }

  /**
   * resize the scrollable area
   * @param {number} width
   * @param {number} height
   */
  resizeScrollArea(width, height) {
    this._scrollRect.width = this._pointerRect.width = width;
    this._scrollRect.height = this._pointerRect.height = height;
    this._drawScrollMask(this._scrollMask); // modifying a masks width / height breaks in web-gl
  }

  /**
   * recusively enable / disable input on children.
   * we keep a list of the disabled children as we dont want to enable intentionally disabled things.
   * @param {Object} displayObject
   * @param {boolean} enabled
   * @param {number} depth
   */
  _updateInputState(displayObject, enabled, depth = 0) {
    // set the input state when at depth 0
    if (depth === 0) {
      if (enabled === this._childInputEnabled) return;
      this._childInputEnabled = enabled;
    }

    let child;
    // only enable children that were previously disabled
    if (enabled) {
      for (let i = 0; i < this._childrenWithInput.length; i++) {
        child = this._childrenWithInput[i];
        if (!child.game) { continue; }
        child.inputEnabled = true;
        if (child.isUsingHandCursor) child.input.useHandCursor = true;
      }
      this._childrenWithInput.length = 0;
    } else { // disable children if they have active input
      for (let i = 0; i < displayObject.children.length; i++) {
        child = displayObject.children[i];
        if (child.input && child.inputEnabled) {
          child.isUsingHandCursor = child.input.useHandCursor;
          child.inputEnabled = false;
          this._childrenWithInput.push(child);
        }
        if (child.children) {
          this._updateInputState(child, enabled, depth + 1);
        }
      }
    }
  }

  /**
   * add extra conditions for scrolling. If these do not all resolve to true, scrolling will be locked.
   * @param {Function} scrollCondition
   */
  addScrollCondition(scrollCondition) {
    this._scrollConditions.push(scrollCondition);
  }

  /**
   * check if scroll conditions are all true
   * @returns {boolean}
   */
  get scrollConditionsOK() {
    for (const scrollCondition of this._scrollConditions) {
      if (!scrollCondition()) return false;
    }
    return true;
  }

  /**
   * get local pointer position
   * @returns {Phaser.Point}
   */
  _getLocalPointer() {
    return this.toLocal(game.input);
  }

  /**
   * set callback on pointer moved to fix issue of no clicking when not in focus
   */
  _setPointerMovedCallback() {
    try {
      game.input.addMoveCallback(this._onPointerMoved, this);
    } catch (error) {
      console.error('game.input.addMoveCallback() failed');
    }
  }

  /**
   * delete the pointer moved callback
   */
  _deletePointerMovedCallback() {
    try {
      game.input.deleteMoveCallback(this._onPointerMoved, this);
    } catch (error) {
      console.error('game.input.deleteMoveCallback() failed');
    }
  }

  /**
   * on pointer moved callback
   */
  _onPointerMoved() {
    // override to implement
  }

  /**
   * set the content friction, should be 0-1
   * @param {number} value
   */
  set friction(value) {
    this._friction = value;
  }

  /**
   * get the content friction
   * @returns {number}
   */
  get friction() {
    return this._friction;
  }

  /**
   * set the content elasticity, should be 0-1
   * @param {number} value
   */
  set elasticity(value) {
    this._elasticity = value;
  }

  /**
   * get the content elasticity
   * @returns {number}
   */
  get elasticity() {
    return this._elasticity;
  }

  /**
   * enable the scrolling
   */
  enable() {
    if (this._enabled) return;
    this._enabled = true;
    this._setPointerMovedCallback();
  }

  /**
   * disable the scrolling
   */
  disable() {
    if (!this._enabled) return;
    this._enabled = false;
    this._deletePointerMovedCallback();
  }

  /**
   * destruction method
   */
  destroy() {
    this._scrollConditions.length = 0;
    if (this.signals) Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    this._deletePointerMovedCallback();
    super.destroy();
  }
}
