if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getDoubleShineAnimation = function getDoubleShineAnimation() {
  return { animate: animate };

  function animate(game, target) {
    var shine1 = G.Utils.find1stObjectWithName(target, "shine1");
    var shine2 = G.Utils.find1stObjectWithName(target, "shine2");

    if (shine1) {
      game.add.tween(shine1.scale)
      .to({
        x: 1.1,
        y: 1.1
      }, 400, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
      game.add.tween(shine1)
        .to({
          angle: -360,
        }, 20000, Phaser.Easing.Linear.None, true, 0, -1)
    }
    
    if (shine2) {
      game.add.tween(shine2.scale)
      .to({
        x: 1.1,
        y: 1.1
      }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
      game.add.tween(shine2)
        .to({
          angle: 360,
        }, 15000, Phaser.Easing.Linear.None, true, 0, -1);
    }
  };
}
