import OMT_UI_SquareButton, { BUTTONCOLOURS } from "../OMT_UI_SquareButton";

/* eslint-disable no-multi-assign */
/**
 * generic button for invite more friends. This was ripped from request help.
 */
export class OMT_UI_InviteMoreFriendsButton extends Phaser.Group {
  /**
   * constructor
   * @param {Function} onClickCallback
   */
  constructor(onClickCallback) {
    super(game);

    this._onClickCallback = onClickCallback;
    this._initInviteButton();
    this._initAvatarRotator();
  }

  /**
   * init the actual button object
   */
  _initInviteButton() {
    const onClick = () => {
      this._onClickCallback();
    };
    this._actualFriendButton = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 445,
          height: 100,
        },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });
    const friendButtonText = new G.Text(0, 0, OMT.language.getText('Invite more friends!'), 'font-white', 0.5, this._actualFriendButton.width * 0.7, this._actualFriendButton.height);
    friendButtonText.x = 2 + (friendButtonText.width - this._actualFriendButton.width) / 4;
    this._actualFriendButton.addChild(friendButtonText);
    this.addChild(this._actualFriendButton);
  }

  /**
   * init the avataor rotator
   */
  _initAvatarRotator() {
    const avatar = new Phaser.Group(G.Game, this._actualFriendButton);
    const avatarBelow = new Phaser.Graphics(game);
    avatarBelow.beginFill(0xbd5728);
    avatarBelow.drawRect(0, 0, 1, 1);
    avatarBelow.endFill();
    avatar.addChild(avatarBelow);
    const avatarList = ArrayUtil.jumbleArray(['avatar_f', 'avatar_m']);
    const avatarA = G.makeImage(0, 0, avatarList[0], 0.5, avatar);
    const avatarB = G.makeImage(0, 0, avatarList[1], 0.5, avatar);
    avatarB.alpha = 0;
    const avatarFrame = G.makeImage(0, 0, 'avatar_frame', 0.5, avatar);
    const avatarScale = (this._actualFriendButton.height) / avatarFrame.height;
    avatarBelow.height = avatarA.height = avatarB.height = avatarA.height * avatarScale;
    avatarBelow.width = avatarA.width = avatarB.width = avatarA.width * avatarScale;
    avatarBelow.x = avatarBelow.y = -avatarBelow.height / 2;
    avatar.x = (this._actualFriendButton.width - avatar.width) / 2;
    avatarFrame.height = avatarBelow.height * 1.15;
    avatarFrame.width = avatarBelow.width * 1.15;
    // eslint-disable-next-line no-unused-vars
    let avatarCounter = 0;
    const avatarChangeDelay = game.time.events.repeat(3000, Infinity, () => { // Changes every 3 seconds
      if (avatar.game) {
        const nextTexture = avatarList.shift();
        avatarList.push(nextTexture);
        avatarB.changeTexture(nextTexture);
        avatarB.width = avatarB.height = avatarBelow.height;
        avatarB.alpha = 1;
        avatar.addChildAt(avatarB, 1);
        const disappear = game.add.tween(avatarA)
          .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.In);

        disappear.onComplete.addOnce(() => {
          avatarA.changeTexture(nextTexture);
          avatarA.width = avatarA.height = avatarBelow.height;
          avatarA.alpha = 1;
          avatarB.alpha = 0;
        });

        disappear.start();
      } else {
        game.time.events.remove(avatarChangeDelay); // Removes itself if the game isn't there anymore
      }
      avatarCounter++;
    }, this);
  }

  /**
   * set the button active state
   * @param {boolean} value
   */
  set active(value) {
    this._actualFriendButton.active = value;
  }
}
