import EventPostcardAssetManager from '../Elements/Windows/eventPostcard/EventPostcardAssetManager'; // eslint-disable-line import/no-cycle

let _bgLoadingUtil = null;

/**
 * @author Sandra Koo
 * An OMT service that will load image assets in the background, asyronchronously, hopefully.
 */
export default class OMT_BgLoadingUtil {
  /**
   * Single instance
   */
  static getInstance() {
    if (!_bgLoadingUtil) {
      _bgLoadingUtil = new OMT_BgLoadingUtil();
    }

    return _bgLoadingUtil;
  }

  /**
   * \o7
   */
  constructor() {
    this._textureQueue = [];
    this._isQueueLoading = false;
  }

  static startBackgroundAssetLoading() {
    if (OMT.feature.isTokenEventOn(true)) {
      EventPostcardAssetManager.loadAssets();
    }
  }

  /**
   * Pass in an array of strings, which is the asset you wish to load
   * This keeps track of a batch, and has a callback when everything completes.
   * Useful for getting features ready
   * @param {Array<String>} batch
   * @param {function} onComplete
   */
  async addBatchToQueue(batch, onComplete) {
    const loadedBatch = [];
    let failCount = 0;

    const onTextureFinish = () => {
      if (loadedBatch.length + failCount === batch.length) {
        onComplete({
          passed: loadedBatch,
          failed: failCount,
        });
      }
    };

    const onSuccess = (textureData) => {
      loadedBatch.push(textureData);
      onTextureFinish();
    };
    const onFail = (textureData) => { // eslint-disable-line no-unused-vars
      failCount++;
      onTextureFinish();
    };

    batch.forEach((text) => {
      this.addToQueue(text, onSuccess, onFail);
    });
  }

  /**
   * Adds a single texture to queue.
   * If things goes well, it'll have a call back.
   * Immediately starts loading the queue when it gets added
   * @param {String} targetTexture
   * @param {function} onSuccess
   * @param {function} onFail
   */
  async addToQueue(targetTexture, onSuccess, onFail) {
    this._textureQueue.push({
      texture: targetTexture,
      onSuccess,
      onFail,
    });

    if (!this._isQueueLoading) {
      this._loadQueue();
    }
  }

  /**
   * Attempts to load 1 item from queue
   * When complete, it'll run the _onLoadComplete
   */
  _loadQueue() {
    const textPack = this._peekQueue(); // Check the first item
    if (textPack) {
      this._isQueueLoading = true;

      const onLoadSuccess = (textureData) => {
        if (textPack.onSuccess) {
          textPack.onSuccess(textureData);
        }
        this._onLoadComplete();
      };

      const onLoadFail = (textureData) => {
        if (textPack.onFail) {
          textPack.onFail(textureData);
        }
        this._onLoadComplete();
      };

      /**
      * Loading attempt happens here
      * G.getExtTexture attempts to load it and saves it in its own G.extImagesKeys cache
      * But the cache keys don't really make sense and serves as a key to some other object called
      * G.extLoader.loadInfoPerUrl. But if you try to access the same texture url after it loads,
      * you get it.
      *
      * Also this only loads the texture into the cache. Does not return the Texture object,
      * nor does it make one. Make one using G.makeExtImage.
      *
      * Any asset that is already loaded will return as a success, so it won't try to load it again
      */
      G.getExtTexture(textPack.texture, onLoadSuccess).catch(() => {
        onLoadFail();
      });
    }
  }

  /**
   * Is called when a load succeeds or fails.
   * Shifts the queue, and repeats loading if there is more to load
   * Otherwise turns itself off really.
   */
  _onLoadComplete() {
    this._textureQueue.shift();
    if (this._textureQueue.length === 0) {
      this._isQueueLoading = false;
    } else {
      this._loadQueue();
    }
  }

  /**
   * Takes a peek without modifying
   */
  _peekQueue() {
    return this._textureQueue.length === 0 ? undefined : this._textureQueue[0];
  }
}
