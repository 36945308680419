import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import { TournamentSocialDisplayBase } from './Tournament/TournamentSocialDisplayBase';

export default class UI_GoalTournamentBase extends Phaser.Group {
  /**
   * The goal panel for when its collecting points rather than gems
   * @param {number} x
   * @param {number} y
   */
  constructor(x, y) {
    super(game);

    this.x = Math.floor(x);
    this.y = Math.floor(y);

    this._gameHooks = LvlDataManager.getInstance()._gameHooks;

    this._points = 0;

    this._setupSocialDisplay();

    this._createPointsCounter();

    this._onScreenResize();

    // create external bindings
    this._signalBindings = [
      G.sb('onScreenResize').add(this._onScreenResize, this),
    ];
  }

  /**
   * Creates the social display
   */
  _setupSocialDisplay() {
    this._socialDisplay = this._createTournamentDisplay();
    this._socialDisplay.setPoints(0);
    this.add(this._socialDisplay);
  }

  /**
   * Returns the class used for socialDisplay
   * @returns {Class}
   */
  _createTournamentDisplay() {
    return new TournamentSocialDisplayBase();
  }

  /**
   * Creates the points counter
   */
  _createPointsCounter() {
    this._pointsCounter = new G.Text(0, 0, `${0} ${OMT.language.getText('points')}`, 'UIGoalPanelPointAmount-tournament', 0.5, 200);
    this.add(this._pointsCounter);
  }

  /**
   * Updates text
   * @param {boolean} forceUpdate
   */
  update(forceUpdate = false) {
    const { points } = LvlDataManager.getInstance();
    if (this._points !== points || forceUpdate) {
      this._pointsCounter.setText(`${points} ${this._gameHooks.getText('points')}`);
      this._points = points;
      this._socialDisplay.setPoints(this._points);
    }
  }

  /**
   * update the tournament social display
   */
  _updateSocialDisplayPoints() {
    this._socialDisplay.setPoints(this._points);
  }

  /**
   * Repositions layout
   */
  _onScreenResize() {
    this._pointsCounter.updateText();
    this._pointsCounter.updateCache();
  }

  /**
   * destruction method
   */
  destroy() {
    for (const signalBinding of this._signalBindings) signalBinding.detach();
    this._signalBindings.length = 0;
    super.destroy();
  }
}
