/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import TutorialGrid from '../BoardAnim/G.TutorialGrid';
import TutorialGroup from '../BoardAnim/TutorialGroup';
import OMT_UI_SquareButton, {
  BUTTONCOLOURS,
} from '../../../OMT_UI/OMT_UI_SquareButton';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';
import OMT_StackManager from '../../../Utils/OMT_StackManager';
import OMT_TweenUtils from '../../../Utils/Animation/OMT_TweenUtils';

const tileSize = 90;
const gridScale = 1.2;

class BurntCandyTutorial extends TutorialGroup {
  constructor() {
    super(game, null);

    this.currentPage = 0;
    this.pageOne = new Phaser.Group(game, null);
    this.gridBg = undefined;
    this.gems = undefined;
    this.gemEmitter = [];
    this.tutHand = undefined;
    this.description = undefined;
    this.continueButton = undefined;

    this.pageTwo = new Phaser.Group(game, null);

    this.drawEverything();
    this.showPageOne();
  }

  /**
   * Draws all elements
   * Initializes some of them
   */
  drawEverything() {
    this.gridBg = new TutorialGrid(); // Grid bg. Has the background sprite

    this.createGoalArrows();

    this.gems = new TutorialGrid(); // For the gems

    this.extras = new TutorialGrid(); // Extra grid for dropping down extra candy.

    for (let i = 0; i < 3; i++) {
      // Particle emitters for the gems
      const emitter = new FxParticle(); // For the sfx
      emitter.scale.setTo(gridScale); // It also grows big
      this.gemEmitter.push(emitter);
    }

    this.description = new G.Text(
      0,
      -150,
      ' ',
      {
        // The instructions
        style: 'font-blue',
        fontSize: 35,
        lineSpacing: -15,
      },
      0.5,
      400,
      200,
      true,
      'center',
    );

    this.continueButton = new OMT_UI_SquareButton(0, 260, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onContinueClick.bind(this),
        },
      },
    });
    this.addChild(this.continueButton);
  }

  /**
   * Adds goal arrows to cells
   */
  createGoalArrow() {
    const image = new Phaser.Group(game, null);
    const goalImage = G.makeImage(0, 0, 'collect_cell_2', 0.5, null);
    goalImage.scale.setTo((tileSize / goalImage.width) * 0.8);
    image.addChild(goalImage);

    return image;
  }

  /**
   * Creates goal arrows for the current page
   */
  createGoalArrows() {
    this.arrows = [];
    for (let i = 0; i < 4; i++) {
      const arrow = this.createGoalArrow();
      arrow._goalIndex = i;
      this.arrows.push(arrow);
    }
  }

  /**
   * When continue is clicked
   */
  onContinueClick() {
    this.stopAllActions(); // Stop all tweens
    if (this.currentPage === 1) {
      // If page one, go to page 2
      this.removeChild(this.pageOne);
      this.showPageTwo();
    } else {
      // Otherwise close
      this.signals.onContinueClick.dispatch();
      this.currentPage = -1;
      this.destroy();
    }
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this.gems) {
      this.gems.destroy();
    }

    if (this.gridBg) {
      this.gridBg.destroy();
    }

    if (this.extras) {
      this.extras.destroy();
    }

    this.pageOne.destroy();
    this.pageTwo.destroy();

    super.destroy();
  }

  /**
   * Positions all grids
   * @param {number} targetX
   * @param {number} targetY
   */
  positionGrids(targetX, targetY) {
    this.gridBg.x = targetX;
    this.gridBg.y = targetY;

    this.gems.x = targetX;
    this.gems.y = targetY;

    this.extras.x = targetX;
    this.extras.y = targetY - tileSize; // Is a little offset above
  }

  /**
   * Shows the first part of the tutorial
   */
  showPageOne() {
    this.currentPage = 1;
    const gridX = 4;
    const gridY = 2;

    this.tutHandPageOne = new Phaser.Group(game);
    G.makeImage(0, 0, 'tut_hand', 0, this.tutHandPageOne); // Haaaand

    this.description.setText(
      OMT.language.getText(
        "%BURNT_COOKIES% can't be matched or crushed, but they can be removed!",
      ),
    );

    this.gridBg.init(gridX, gridY, tileSize, 'tut_tile', gridScale);

    const gemsData = [
      'burntCandy',
      'burntCandy',
      3,
      'burntCandy',
      1,
      2,
      3,
      2,
    ].map((num) => {
      if (num === 0) return null;
      return `candy_${num}`;
    });
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    this.positionGrids(
      0,
      this.description.y + this.description.height + this.gridBg.height / 2,
    );

    for (const arrow of this.arrows) {
      const { _goalIndex } = arrow;
      const cell = this.gridBg.getSpriteByCell(_goalIndex, 1);
      const { height } = cell;
      const startingYPos = height * 0.1;
      arrow.position.setTo(0, startingYPos);
      cell.addChild(arrow);

      game.add
        .tween(arrow.position)
        .to(
          { y: startingYPos * 1.3 },
          500,
          Phaser.Easing.Linear.None,
          true,
          0,
          -1,
          true,
        );
    }

    this.tutHandPageOne.alpha = 0; // Hide this
    this.pageOne.alpha = 0;

    this.addChild(this.description);
    this.pageOne.addChild(this.gridBg);
    this.pageOne.addChild(this.gems);
    this.pageOne.addChild(this.tutHandPageOne);
    this.addChild(this.pageOne);
    this.addChild(this.continueButton);

    this.animatePageOne();
  }

  /**
   * Returns the page location of an object
   * @param {object} gem
   * @param {TutorialGrid} page
   */
  getPagePosition(gem, page) {
    return page.toLocal(gem.worldPosition);
  }

  /**
   * Returns an object that contains useful information about a gem
   * @param {object} gem
   * @param {TutorialGrid} page
   */
  getGemPositionObject(gem, page) {
    return {
      pagePosition: this.getPagePosition(gem, page),
      localPosition: gem.position.clone(),
    };
  }

  /**
   * Get an object that enables us to access objects with their names
   * @param {string} name
   * @param {object} gem
   */
  getNamedGemObject(name, gem) {
    return {
      name,
      gem,
    };
  }

  /**
   * Animates page one
   */
  async animatePageOne() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 1; // Modifies the shouldEventComplete by checking the page

    const gem30 = this.gems.getSpriteByCell(3, 0);
    const gem20 = this.gems.getSpriteByCell(2, 0);

    const gemPositions = {
      gem30: this.getGemPositionObject(gem30, this.pageOne),
      gem20: this.getGemPositionObject(gem20, this.pageOne),
    };

    await OMT_TweenUtils.changeAlphaTo({
      object: this.pageOne,
      alpha: 1,
      duration: tweenTime,
      ease: Phaser.Easing.Sinusoidal.InOut,
    });
    this.gems.bringToTop(gem30);
    const stack = OMT_StackManager.getFreeStack();
    stack.repeat(0);
    stack.addEvent(() => {
      this.tutHandPageOne.scale.set(1); // Hand is normal sized, in position, hidden
      const { x, y } = gemPositions.gem30.pagePosition;
      this.tutHandPageOne.position.setTo(x, y);
      this.tutHandPageOne.alpha = 0;
    });
    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: this.tutHandPageOne,
        alpha: 1,
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
      }),
    );
    stack.wait(tweenDelay / 2);

    const handStack = OMT_StackManager.getFreeStack();
    handStack.setReusable(true);
    handStack.addPromise(() => {
      const { x } = gemPositions.gem20.pagePosition;
      return OMT_TweenUtils.tweenMultiple({
        object: this.tutHandPageOne,
        props: {
          scale: 0.9,
          x,
        },
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
      });
    });
    handStack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: this.tutHandPageOne,
        alpha: 0,
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
      }),
    );

    const gem30Stack = OMT_StackManager.getFreeStack();
    gem30Stack.setReusable(true);
    const gem30Scale = gem30.scale.x;
    gem30Stack.addPromise(() => {
      const { x } = gemPositions.gem20.localPosition;
      return OMT_TweenUtils.tweenMultiple({
        object: gem30,
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
        props: {
          scale: gem30Scale * 1.1,
          x,
        },
      });
    });
    gem30Stack.wait(tweenTime / 4);
    gem30Stack.addPromise(() => {
      const { x } = gemPositions.gem30.localPosition;
      return OMT_TweenUtils.tweenMultiple({
        object: gem30,
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
        props: {
          scale: gem30Scale,
          x,
        },
      });
    });
    gem30Stack.addPromise(() =>
      OMT_TweenUtils.changeAngleTo({
        object: gem30,
        duration: tweenTime / 8,
        ease: Phaser.Easing.Sinusoidal.InOut,
        angle: 20,
      }),
    );
    gem30Stack.addPromise(() =>
      OMT_TweenUtils.changeAngleTo({
        object: gem30,
        duration: tweenTime / 8,
        ease: Phaser.Easing.Sinusoidal.InOut,
        angle: -20,
      }),
    );
    gem30Stack.addPromise(() =>
      OMT_TweenUtils.changeAngleTo({
        object: gem30,
        duration: tweenTime / 8,
        ease: Phaser.Easing.Sinusoidal.InOut,
        angle: 20,
      }),
    );
    gem30Stack.addPromise(() =>
      OMT_TweenUtils.changeAngleTo({
        object: gem30,
        duration: tweenTime / 8,
        ease: Phaser.Easing.Sinusoidal.InOut,
        angle: 0,
      }),
    );

    const gem20Stack = OMT_StackManager.getFreeStack();
    gem20Stack.setReusable(true);
    const gem20Scale = gem20.scale.x;
    gem20Stack.addPromise(() => {
      const { x } = gemPositions.gem30.localPosition;
      return OMT_TweenUtils.tweenMultiple({
        object: gem20,
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
        props: {
          scale: gem20Scale * 1.1,
          x,
        },
      });
    });
    gem20Stack.wait(tweenTime / 4);
    gem20Stack.addPromise(() => {
      const { x } = gemPositions.gem20.localPosition;
      return OMT_TweenUtils.tweenMultiple({
        object: gem20,
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
        props: {
          scale: gem20Scale,
          x,
        },
      });
    });
    stack.addParallel([handStack, gem20Stack, gem30Stack]);
    stack.wait(tweenDelay);

    stack.addEvent(() => {
      if (this.shouldEventComplete() && this.currentPage === 1) {
        stack.repeat(1);
      } else {
        const subStacks = [handStack, gem20Stack, gem30Stack];
        subStacks.forEach((subStack) => {
          subStack.clear();
        });
      }
    });

    return stack.run();
  }

  /**
   * Shows page two
   */
  showPageTwo() {
    this.currentPage = 2;
    const gridX = 4;
    const gridY = 2;

    this.tutHandPageTwo = new Phaser.Group(game);
    G.makeImage(0, 0, 'tut_hand', 0, this.tutHandPageTwo); // Haaaand

    this.description.setText(
      OMT.language.getText(
        'To remove a %BURNT_COOKIES%, clear the path to one of the arrows at the bottom!',
      ),
    );

    this.gridBg.init(gridX, gridY, tileSize, 'tut_tile', gridScale);

    this.createGoalArrows();

    // Init grids
    const gemsData = [3, 2, 'burntCandy', 2, 1, 2, 1, 1].map((num) => {
      if (num === 0) return null;
      return `candy_${num}`;
    });
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    const extraData = [0, 1, 3, 1, 0, 0, 0, 0].map((num) => {
      if (num === 0) return null;
      return `candy_${num}`;
    });
    this.extras.init(gridX, gridY, tileSize, extraData, gridScale);

    this.positionGrids(
      0,
      this.description.y + this.description.height + this.gridBg.height / 2,
    );

    for (const arrow of this.arrows) {
      const { _goalIndex } = arrow;
      const cell = this.gridBg.getSpriteByCell(_goalIndex, 1);
      const { height } = cell;
      const startingYPos = height * 0.1;
      arrow.position.setTo(0, startingYPos);
      cell.addChild(arrow);

      game.add
        .tween(arrow.position)
        .to(
          { y: startingYPos * 1.3 },
          500,
          Phaser.Easing.Linear.None,
          true,
          0,
          -1,
          true,
        );
    }

    this.tutHandPageTwo.alpha = 0; // Hide these
    this.pageTwo.alpha = 0;

    this.addChild(this.description);
    this.pageTwo.addChild(this.gridBg);
    this.pageTwo.addChild(this.gems);
    this.pageTwo.addChild(this.extras);
    for (let i = 0; i < this.gemEmitter.length; i++) {
      this.pageTwo.addChild(this.gemEmitter[i]);
    }
    this.pageTwo.addChild(this.tutHandPageTwo);
    this.addChild(this.pageTwo);
    this.addChild(this.continueButton);

    this.animatePageTwo();
  }

  /**
   * Animates page two
   */
  async animatePageTwo() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 2; // Modifies the shouldEventComplete by checking the page

    const gemExtra10 = this.extras.getSpriteByCell(1, 0);
    const gemExtra20 = this.extras.getSpriteByCell(2, 0);
    const gemExtra30 = this.extras.getSpriteByCell(3, 0);
    const gem10 = this.gems.getSpriteByCell(1, 0);
    const gem20 = this.gems.getSpriteByCell(2, 0);
    const gem30 = this.gems.getSpriteByCell(3, 0);
    const gem01 = this.gems.getSpriteByCell(0, 1);
    const gem11 = this.gems.getSpriteByCell(1, 1);
    const gem21 = this.gems.getSpriteByCell(2, 1);
    const gem31 = this.gems.getSpriteByCell(3, 1);

    const fxGemTargets = [
      this.getNamedGemObject('gem11', gem11),
      this.getNamedGemObject('gem21', gem21),
      this.getNamedGemObject('gem31', gem31),
    ]; // Target gems for the sfx
    const fadeOutGems = [
      this.getNamedGemObject('gem01', gem01),
      this.getNamedGemObject('gem21', gem21),
      this.getNamedGemObject('gem31', gem31),
    ];
    const extraGems = [
      this.getNamedGemObject('gemExtra10', gemExtra10),
      this.getNamedGemObject('gemExtra20', gemExtra20),
      this.getNamedGemObject('gemExtra30', gemExtra30),
    ];
    const movableGems = [
      this.getNamedGemObject('gem10', gem10),
      this.getNamedGemObject('gem20', gem20),
      this.getNamedGemObject('gem30', gem30),
      this.getNamedGemObject('gem01', gem01),
      this.getNamedGemObject('gem11', gem11),
      this.getNamedGemObject('gem21', gem21),
      this.getNamedGemObject('gem31', gem31),
    ];

    const gemPositions = {};
    for (const gemData of extraGems) {
      const { name, gem } = gemData;
      gemPositions[name] = this.getGemPositionObject(gem, this.pageTwo);
      gem.alpha = 0;
    }
    for (const gemData of movableGems) {
      const { name, gem } = gemData;
      gemPositions[name] = this.getGemPositionObject(gem, this.pageTwo);
    }

    this.gems.bringToTop(gem01);
    const stack = OMT_StackManager.getFreeStack();
    stack.repeat(0);
    stack.addEvent(() => {
      this.tutHandPageTwo.scale.set(1); // Hand is normal sized, in position, hidden
      const { x, y } = gemPositions.gem01.pagePosition;
      this.tutHandPageTwo.position.setTo(x, y);
      this.tutHandPageTwo.alpha = 0;

      for (const gemData of extraGems) {
        const { name, gem } = gemData;
        gem.alpha = 0;
        gem.position.copyFrom(gemPositions[name].localPosition);
      }
      for (const gemData of movableGems) {
        const { name, gem } = gemData;
        gem.alpha = 1;
        gem.position.copyFrom(gemPositions[name].localPosition);
      }
    });
    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: this.pageTwo,
        alpha: 1,
        duration: tweenTime,
        ease: Phaser.Easing.Sinusoidal.InOut,
      }),
    );
    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: this.tutHandPageTwo,
        alpha: 1,
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
      }),
    );
    stack.wait(tweenDelay / 2);

    const handStack = OMT_StackManager.getFreeStack();
    handStack.setReusable(true);
    handStack.addPromise(() => {
      const { x } = gemPositions.gem11.pagePosition;
      return OMT_TweenUtils.tweenMultiple({
        object: this.tutHandPageTwo,
        props: {
          scale: 0.9,
          x,
        },
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
      });
    });
    handStack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: this.tutHandPageTwo,
        alpha: 0,
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
      }),
    );

    const gem01Stack = OMT_StackManager.getFreeStack();
    gem01Stack.setReusable(true);
    const gem01Scale = gem01.scale.x;
    gem01Stack.addPromise(() => {
      const { x } = gemPositions.gem11.localPosition;
      return OMT_TweenUtils.tweenMultiple({
        object: gem01,
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
        props: {
          scale: gem01Scale * 1.1,
          x,
        },
      });
    });
    gem01Stack.addPromise(() =>
      OMT_TweenUtils.transformTo({
        object: gem01,
        duration: tweenTime / 4,
        ease: Phaser.Easing.Sinusoidal.InOut,
        scale: gem01Scale,
      }),
    );

    const gem11Stack = OMT_StackManager.getFreeStack();
    gem11Stack.setReusable(true);
    gem11Stack.addPromise(() => {
      const { x } = gemPositions.gem01.localPosition;
      return OMT_TweenUtils.translateTo({
        object: gem11,
        duration: tweenTime / 2,
        ease: Phaser.Easing.Sinusoidal.InOut,
        x,
      });
    });

    const firstSubstacks = [handStack, gem01Stack, gem11Stack];
    stack.addParallel(firstSubstacks);

    const secondSubstacks = [];
    for (let i = 0; i < fxGemTargets.length; i++) {
      const gemEmitter = this.gemEmitter[i];
      const fxGem = fxGemTargets[i];
      const fadeOutGem = fadeOutGems[i];

      const substack = OMT_StackManager.getFreeStack();
      substack.setReusable(true);
      substack.wait((i * tweenTime) / 4);
      substack.addEvent(() => {
        const { name } = fxGem;
        const { x, y } = gemPositions[name].pagePosition;
        gemEmitter.burstCandy(x, y);
      });
      substack.addPromise(() => {
        const { gem } = fadeOutGem;
        return OMT_TweenUtils.changeAlphaTo({
          object: gem,
          duration: tweenTime / 4,
          ease: Phaser.Easing.Sinusoidal.InOut,
          alpha: 0,
        });
      });
      secondSubstacks.push(substack);
    }
    stack.addParallel(secondSubstacks);

    const firstCascade = [];
    for (let i = 0; i < 3; i++) {
      const from = movableGems[i];
      const to = movableGems[i + 4];
      const extra = extraGems[i];
      firstCascade.push({ from, to });
      firstCascade.push({ from: extra, to: from });
    }
    const thirdSubstacks = [];
    for (const pair of firstCascade) {
      const substack = OMT_StackManager.getFreeStack();
      substack.setReusable(true);
      const { from, to } = pair;
      substack.addPromise(() => {
        const { name: fromName, gem } = from;
        const { y: startY } = gemPositions[fromName].pagePosition;
        const { y: startYLocal } = gemPositions[fromName].localPosition;
        const { name: endName } = to;
        const { y: endY } = gemPositions[endName].pagePosition;
        const delta = endY - startY;
        return OMT_TweenUtils.tweenMultiple({
          object: gem,
          duration: tweenTime / 2,
          ease: Phaser.Easing.Sinusoidal.InOut,
          props: {
            alpha: 1,
            y: startYLocal + delta,
          },
        });
      });
      thirdSubstacks.push(substack);
    }
    stack.addParallel(thirdSubstacks);

    stack.wait(tweenDelay / 4);
    stack.addEvent(() => {
      const gemEmitter = this.gemEmitter[0];
      const { x, y } = gemPositions.gem21.pagePosition;
      gemEmitter.burstCandy(x, y);
    });
    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: gem20,
        duration: tweenTime / 4,
        ease: Phaser.Easing.Sinusoidal.InOut,
        alpha: 0,
      }),
    );

    stack.wait(tweenDelay);
    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: this.pageTwo,
        alpha: 0,
        duration: tweenTime,
        ease: Phaser.Easing.Sinusoidal.InOut,
      }),
    );

    stack.addEvent(() => {
      if (this.shouldEventComplete() && this.currentPage === 2) {
        stack.repeat(1);
      } else {
        [...firstSubstacks, ...secondSubstacks, ...thirdSubstacks].forEach(
          (subStack) => {
            subStack.clear();
          },
        );
      }
    });
    return stack.run();
  }
}
// create global references
G.BurntCandyTutorial = BurntCandyTutorial;
