import UI_HorizontalScrollArea from '@omt-components/UI/Scroll/UI_HorizontalScrollArea';
import {
  LOCK_INPUT_VELOCITY,
  MAX_OVERSCROLL,
  WHEEL_SCROLL_SPEED,
} from '@omt-components/UI/Scroll/UI_ScrollAreaBase';
import OMT_UI_HorizontalScrollBar from './ScrollBar/OMT_UI_HorizontalScrollBar';
import OMT_UI_HorizontalScrollBarConfig from './ScrollBarConfig/OMT_UI_HorizontalScrollBarConfig';

/**
 * simpler scroll area of horizontal scrolling
 */
export default class OMT_UI_HorizontalScrollArea extends UI_HorizontalScrollArea {
  /**
   * constructor
   * @param {Phaser.Rectangle} scrollRect Rectangle for scrollable area
   * @param {boolean} maskContent do you want to apply a mask to the content ?
   * @param {boolean} hasScrollBar should the scrollArea have a scrollbar?
   * @param {OMT_UI_HorizontalScrollBarConfig} scrollBarConfig Class reference for the scroll bar config. Should extend OMT_UI_ScrollBarConfigBase.
   */
  constructor(scrollRect, maskContent = true, hasScrollBar = true, scrollBarConfig = null) {
    super(scrollRect);

    this._lastContentWidth = 0;
    this._extraWidth = 0;
    this._autoResize = true;
    this._allowOverscroll = false;

    // init scroll physics params
    this._contentDragStartX = 0;
    this._contentDragVelX = 0;

    this._initContentContainer();
    if (maskContent) this._initScrollMask();
    this._setPointerMovedCallback();
    this._setMouseWheelEvent();

    if (hasScrollBar) { // scroll bar should be active
      // set default scrollBarConfig if none passed
      if (!scrollBarConfig) scrollBarConfig = new OMT_UI_HorizontalScrollBarConfig(12, 5, 1, 1, null, null, 'shop2', 'scrollbar_slide', true, null, false);
      // adjust rectangles to compensate for scrollbar overlap / no overlap
      if (!scrollBarConfig.overlapScrollArea) this._scrollRect.height -= scrollBarConfig.height;
      else this._pointerRect.height -= scrollBarConfig.height;
      this._initScrollBar(scrollBarConfig);
      this.addScrollCondition(() => this._enabled && !this._scrollBar.dragActive && !OMT.social.isOverlayVisible);
    } else { // no scrollbar
      this.addScrollCondition(() => this._enabled && !OMT.social.isOverlayVisible);
    }
  }

  /**
   * on pointer moved callback
   */
  _onPointerMoved() {
    try {
      const localPointer = this._getLocalPointer();
      const containsPointer = this.scrollConditionsOK && Phaser.Rectangle.containsPoint(this._scrollRect, localPointer);
      const velocityLock = Math.abs(this._contentDragVelX) > LOCK_INPUT_VELOCITY;
      this._updateInputState(this._content, containsPointer && !velocityLock);
    } catch (error) {
      this._deletePointerMovedCallback();
    }
  }

  /**
   * set the mouse wheel moved event
   */
  _setMouseWheelEvent() {
    G.sb('mouseWheelEvent').add(this._onMouseWheel, this);
  }

  /**
   * on mouse wheel moved
   * @param {Object} event
   * @param {number} event.deltaX
   */
  _onMouseWheel(event) {
    if (!this.scrollConditionsOK) return; // scrolling is locked
    const localPointer = this._getLocalPointer();
    const containsPointer = this.scrollConditionsOK && Phaser.Rectangle.containsPoint(this._scrollRect, localPointer);
    if (containsPointer) { // check if pointer is in the scroll area
      const deltaPos = Math.abs(event.deltaX) > Math.abs(event.deltaY) ? event.deltaX : event.deltaY;

      const contentWidth = this._getContentWidth(this._autoResize);
      const velX = this._contentDragVelX + (deltaPos * WHEEL_SCROLL_SPEED);
      const newXPos = this._content.x - (velX / Math.abs(velX));
      const contentLeftOverflow = (newXPos + contentWidth) - this._scrollRect.width;
      const contentRightOverflow = newXPos;
      // only add velocity if new position is in bounds
      if (contentLeftOverflow > 0 && contentRightOverflow < 0) {
        this._contentDragVelX = velX;
      }
    }
  }

  /**
   * init the scroll bar
   * @param {OMT_UI_HorizontalScrollBarConfig} scrollBarConfig
   */
  _initScrollBar(scrollBarConfig) {
    this._scrollBar = new OMT_UI_HorizontalScrollBar(this._scrollRect, scrollBarConfig);
    this.addChild(this._scrollBar);
    this._scrollBar.signals.onScrollChange.add(this._onScrollBarScrolled, this);
  }

  /**
   * get a reference to the scrollbar
   * @returns {OMT_UI_HorizontalScrollBar} returns a reference to the scrollbar
   */
  get scrollBar() {
    return this._scrollBar;
  }

  /**
   * user scrolled with scrollbar
   */
  _onScrollBarScrolled() {
    const contentWidth = this._getContentWidth();
    const visiblePercent = this._scrollRect.width / contentWidth;
    const scrollWidth = contentWidth * (1 - visiblePercent);
    const scrollPercent = contentWidth > this._scrollRect.width ? this._scrollBar.getScrollPercent() : 0;
    this._content.x = -scrollWidth * scrollPercent;
    this._contentDragVelX = 0;
    this.signals.onScrolled.dispatch();
  }

  /**
   * main update function
   */
  update() {
    super.update();

    const localPointer = this._getLocalPointer();
    const containsPointer = this.scrollConditionsOK && Phaser.Rectangle.containsPoint(this._scrollRect, localPointer);
    this._updateContentDragging(containsPointer);

    const velocityLock = Math.abs(this._contentDragVelX) > LOCK_INPUT_VELOCITY;
    this._updateInputState(this._content, containsPointer && !velocityLock);

    if (this._scrollBar) { // update scrollbar if set
      if (this._autoResize) this.updateScrollBar();
      this._scrollBar.updateEnabledState();
    }
  }

  /**
   * check if the scroll area width changed and adjust if neccessary
   */
  updateScrollBar() {
    const contentWidth = this._getContentWidth(true);
    const scrollWidth = contentWidth - this._scrollRect.width;

    // update scrollbar / knob
    if (this._lastContentWidth !== contentWidth) {
      const viewPercent = this._scrollRect.height / contentWidth;
      const scrollPercent = -this._content.y / scrollWidth;
      this._scrollBar.updateScrollKnobGraphics(viewPercent);
      this._scrollBar.setScrollPercent(scrollPercent, false);
      this._lastContentWidth = contentWidth;
    }
  }

  /**
   * true if the scrollBar is currently visible
   * @returns {boolean}
   */
  get isScrollBarVisible() {
    return this._scrollBar != null ? this._scrollBar.visible : false;
  }

  /**
   * update content dragging
   * @param {boolean} containsPointer
   */
  _updateContentDragging(containsPointer) {
    const contentWidth = this._getContentWidth(this._autoResize);
    if (contentWidth < this._scrollRect.width) return;
    const lastX = this._content.x;

    // capture the pointer down state
    if (!this._pointerDown && game.input.activePointer.isDown) {
      this._pointerDown = true;
      this._pointerDownInContent = containsPointer;
      this._contentDragStartX = game.input.x;
    } else if (this._pointerDown && !game.input.activePointer.isDown) {
      this._pointerDown = false;
      this._pointerDownInContent = false;
    } else if (this._pointerDown && (!containsPointer && !this._allowOverscroll)) {
      this._pointerDownInContent = false;
    }

    const contentLeftOverflow = (this._content.x + contentWidth) - this._scrollRect.width;
    const contentRightOverflow = this._content.x;

    if (this._pointerDownInContent) { // drag active
      const overflow = Math.max(-contentLeftOverflow, contentRightOverflow, 0);
      const overScrollResistance = Math.min(overflow / MAX_OVERSCROLL, 1);
      this._contentDragVelX = (this._contentDragStartX - game.input.x) * (1 - overScrollResistance);
      this._contentDragStartX = game.input.x;
    } else if (contentLeftOverflow < -0.5) { // drag inactive, bounce back to top limit
      this._contentDragVelX = 0;
      this._content.x -= contentLeftOverflow * this._elasticity;
    } else if (contentRightOverflow > 0.5) { // drag inactive, bounce back to bottom limit
      this._contentDragVelX = 0;
      this._content.x -= contentRightOverflow * this._elasticity;
    }

    if (!this._allowOverscroll) { // dont allow overscrolling if this._allowOverscroll = false
      if (this._contentDragVelX > 0 && this._content.x === contentWidth) { this._contentDragVelX = 0; return; } // Clamped to left
      if (this._contentDragVelX < 0 && this._content.x === 0) { this._contentDragVelX = 0; return; } // Clamped to right
    }

    // scroll as long as we have velocity
    if (Math.abs(this._contentDragVelX) > 0) {
      // update content position
      this._content.x -= this._contentDragVelX;
      // dont allow overscrolling if this._allowOverscroll = false
      if (!this._allowOverscroll) this._content.x = Math.max(Math.min(this._content.x, 0), this._scrollRect.width - contentWidth);

      if (this._scrollBar) { // update scroll bar position
        const scrollPercent = -this._content.x / (contentWidth - this._scrollRect.width);
        this._scrollBar.setScrollPercent(scrollPercent, false);
      }

      // apply friction to drag velocity
      this._contentDragVelX *= this._friction;
    }

    // dispatch onScrolled event
    if (this._content.x !== lastX) this.signals.onScrolled.dispatch();
  }

  /**
   * jump to content position
   * @param {number} xPos
   */
  jumpToContentPosition(xPos) {
    // move content
    const contentWidth = this._getContentWidth(this._autoResize);
    this._contentDragVelX = 0;

    if (contentWidth > this._scrollRect.width) {
      this._content.x = -xPos;
      this._content.x = Math.max(Math.min(this._content.x, 0), this._scrollRect.width - contentWidth);
    } else {
      this._content.x = 0;
    }

    if (this._scrollBar) { // update scroll bar position
      const scrollPercent = -this._content.x / (contentWidth - this._scrollRect.width);
      this._scrollBar.setScrollPercent(scrollPercent, false);
    }

    // dispatch onScrolled event
    this.signals.onScrolled.dispatch();
  }

  /**
   * resize the scroll rect / view
   * @param {number} width
   * @param {number} height
   */
  resizeScrollArea(width, height) {
    super.resizeScrollArea(width, height);
    this.jumpToContentPosition(-this._content.x);
  }

  /**
   * toggle auto resizing of the scroll content area
   * @param {boolean} value
   */
  set autoResize(value) {
    this._autoResize = value;
  }

  /**
   * get the width of the content ignoring negative portion
   * @param {boolean} update update height values
   * @returns {number}
   */
  _getContentWidth(update = true) {
    if (!update) return this._lastContentWidth;
    const contentMinX = this._content.getBounds(this._content).x;
    const contentWidth = this._extraWidth + (contentMinX >= 0 ? this._content.width : this._content.width + contentMinX);
    return contentWidth;
  }

  /**
   * set some extra height if needed as sometimes Phaser width calculations can be buggy
   * @param {number} value
   */
  set extraWidth(value) {
    this._extraWidth = value;
  }

  /**
   * toggle if we allow over scrolling
   * @param {boolean} value
   */
  set allowOverScroll(value) {
    this._allowOverscroll = value;
  }

  /**
   * descruction method
   */
  destroy() {
    G.sb('mouseWheelEvent').remove(this._onMouseWheel, this);
    super.destroy();
  }
}
