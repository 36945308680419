if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getCubicFadeOutAnimation = function getCubicFadeOutAnimation() {
  return { animate: animate };

  function animate(game, target, duration) {
    return game.add
      .tween(target)
      .to({alpha: 0}, duration || 500, Phaser.Easing.Sinusoidal.Out, true);
  };
}