/**
 * converted from legacy code, class for sorting through level leaderboard data in the game state
 */
export default class PerLevelData {
  /**
  * constructor
  * @param {Object} lvlData level data object
  * @param {boolean} isDailyChallenge
  */
  constructor(lvlData, isDailyChallenge) {
    // this._initAsync(lvlData, isDailyChallenge);
  }

  /**
   * async initialization
   * @param {Object} lvlData level data object
   * @param {boolean} isDailyChallenge
   * @returns {Promise}
   */
  async _initAsync(lvlData, isDailyChallenge) {
    this._starsAtStart = G.saveState.getAllStars();
    this._currentUserScore = 0;
    const instanceId = lvlData.id + (isDailyChallenge ? '_challenge' : '');

    try {
      this._data = await OMT.leaderboards.getFriendsLeaderboardEntries('default', instanceId);
      this._data = this._data.sort((a, b) => b.score - a.score);
      this._updateCurrentUserScore(this._data);
    } catch (error) {
      console.log(error);
      this._data = null;
    }
  }

  /**
   * update parameters for the current users score
   * @param {Array} data entries list
   */
  _updateCurrentUserScore(data) {
    const currentUser = data.find((user) => user.isCurrentUser);
    if (currentUser) {
      this._currentUser = currentUser;
      this._currentUserScore = currentUser.score;
    }
  }

  /**
   * modify the user data on passing a level
   * @param {number} score
   * @returns {Object}
   */
  passLevel(score) {
    const oldScore = this._currentUserScore || 0;
    const result = {
      leaderboard: null,
      passed: this._getPassedFriends(oldScore, score),
      scoreImprovement: score > oldScore,
      scoreImprovementDelta: score - oldScore,
      requestFriendsUpdate: true,
      starsEarned: G.saveState.getAllStars() - this._starsAtStart,
    };
    if (!this._data) return result;

    if (this._currentUser) {
      this._currentUserScore = Math.max(oldScore, score);
    } else {
      this._data.push({ // Create whole user element
        userId: OMT.envData.settings.user.userId,
        name: OMT.envData.settings.user.name,
        image: OMT.envData.settings.user.avatar,
        score,
        isCurrentUser: true,
      });
    }

    this._data = this._data.sort((a, b) => b.score - a.score);
    this._data.forEach((user, i) => {
      user.rank = i + 1;
    });
    result.leaderboard = this._data;
    return result;
  }

  /**
   * get a list of friends who scores you passed
   * @param {number} oldScore your old score
   * @param {number} newScore your new score
   * @returns {Array.<Object>}
   */
  _getPassedFriends(oldScore, newScore) {
    if (!this._data) return null;
    if (oldScore >= newScore) return null;

    // Check filter people who had higher than old score
    const peopleAhead = this._data.filter((user) => !user.isCurrentUser && user.score > oldScore && user.score < newScore);

    return peopleAhead || null;
  }
}
