G.StartBoosterBubble = function (position, sprite, target, onPop, context) {
  Phaser.Image.call(this, game, 0, 0);

  this.anchor.setTo(0.5);

  this.state = game.state.getCurrentState();

  this.board = this.state.board;

  this.x = this.board.x + (this.board.width * position[0]);
  this.y = this.board.y + (this.board.height * position[1]);

  this.tweenFloating = game.add.tween(this).to({ y: this.y + 30 }, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

  game.add.tween(this.scale).from({ x: 0, y: 0 }, 1000, Phaser.Easing.Elastic.Out, true);

  G.changeTexture(this, sprite);


  this.target = target || null;

  this.onPop = onPop || function () {};
  this.onPopContext = context || this;

  this.goingToTarget = false;
};


G.StartBoosterBubble.prototype = Object.create(Phaser.Image.prototype);

G.StartBoosterBubble.prototype.goToTarget = function (delay) {
  if (this.target == null) {
    game.time.events.add(delay + 500, function () {
      this.tweenFloating.stop();
      this.pop();
    }, this);
  } else {
    game.time.events.add(delay, function () {
      this.tweenFloating.stop();
      game.add.tween(this).to({
        x: game.world.bounds.x + this.target.worldPosition.x,
        y: game.world.bounds.y + this.target.worldPosition.y,
      },
      300, Phaser.Easing.Sinusoidal.In, true).onComplete.add(this.pop, this);

      game.add.tween(this.scale).to({
        x: 0.6,
        y: 0.6,
      }, 300, Phaser.Easing.Sinusoidal.In, true);
    }, this);
  }
};


G.StartBoosterBubble.prototype.pop = function () {
  G.sfx[`match_${game.rnd.between(1, 5)}`].play();
  this.onPop.call(this.onPopContext);

  for (let i = 0; i < 4; i++) {
    G.sb('UIfx').dispatch(this.worldPosition.x + game.world.bounds.x, this.worldPosition.y, 'whiteStarPart');
  }
  this.destroy();
};
