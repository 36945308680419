if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};
G.Animations.Generic = G.Animations.Generic || {};

G.Animations.Generic.createRotateCubicOutAnimation = (
  function createRotateCubicOutAnimation(targetAngle, duration) {
    return { animate: animate };
    function animate(game, target) {
      game.add.tween(target).to(
        { angle: targetAngle },
        duration, Phaser.Easing.Cubic.Out, true
      );
    }
  }
);

