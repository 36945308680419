import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';
import GingyCharacterTutorial from '../../../GingyTutorial/G.GingyCharacterTutorial';

/* eslint-disable no-use-before-define */
const MASCOT_LOOKAT = { x: 0.1, y: -0.1 };
const MASCOT_ANIMATION_OFFSET_Y = 60;
const MASCOT_ANIMATION_DURATION = 250;

const SPEECH_BUBBLE_RECT_HORIZONTAL = { width: 250, height: 100, cornerSize: 30 };
const SPEECH_BUBBLE_RECT_VERTICAL = { width: 360, height: 100, cornerSize: 30 };
const SPEECH_BUBBLE_BG_COLOR = 0xffffff;
const SPEECH_BUBBLE_TEXT = 'Are you sure? This exclusive offer will be gone if you close it!';
const SPEECH_BUBBLE_TEXT_STYLE = 'window_popupOffer-mascotTxt';
const SPEECH_BUBBLE_TEXT_OFFSET = { x: -40, y: -10 };
const SPEECH_BUBBLE_ANIMATION_DURATION = 500;
const SPEECH_BUBBLE_ANIMATION_DELAY = 100;

/**
 * class for TargetedOffer exit confirm display + mascot
 */
export class TargetedOffer_MascotGroup extends Phaser.Group {
  /**
   * constructor
   * @param {TargetedOffer_BackgroundGroup} bg
   * @param {Object} mascotConfig { offsetX:number, offsetY:number }
   * @param {Object} bubbleConfig { offsetX:number, offsetY:number }
   */
  constructor(bg, mascotConfig, bubbleConfig) {
    super(game);

    this._bg = bg;
    this._initSpeechBubble(bubbleConfig);
    this._initMascot(mascotConfig);

    this.visible = false;
  }

  /**
   * initialize the mascot
   * @param {Object} mascotConfig { offsetX:number, offsetY:number }
   */
  _initMascot(mascotConfig) {
    this._character = new GingyCharacterTutorial(0, 0);
    this._character.lookAt(MASCOT_LOOKAT);
    this._character.surprised();
    this._character.scale.setTo(mascotConfig.scaleX, mascotConfig.scaleY);
    this._character.alpha = 0;
    this._character.x = this._bg.outerBgX - (this._bg.outerWidth / 2) + mascotConfig.offsetX;
    this._character.y = this._bg.outerBgY - (this._bg.outerHeight / 2) + mascotConfig.offsetY;
    this.add(this._character);
  }

  /**
   * animate in the mascot
   */
  _animateInMascot() {
    game.add.tween(this._character).to(
      { y: this._character.y - MASCOT_ANIMATION_OFFSET_Y },
      MASCOT_ANIMATION_DURATION, Phaser.Easing.Quadratic.Out, true,
    );
    game.add.tween(this._character).to({ alpha: 1 }, MASCOT_ANIMATION_DURATION).start();
  }

  /**
   * @param {Object} bubbleConfig { offsetX:number, offsetY:number }
   */
  _initSpeechBubble(bubbleConfig) {
    this._speechBubble = new TargetedOffer_MascotSpeechBubble();
    this._speechBubble.x = bubbleConfig.offsetX;
    this._speechBubble.y = bubbleConfig.offsetY;
    this.addChild(this._speechBubble);
  }

  /**
   * animate in all content
   */
  animateIn() {
    this._animateInMascot();
    this._speechBubble.animateIn();
    this.visible = true;
  }
}

/**
 * mascot speech bubble component
 */
class TargetedOffer_MascotSpeechBubble extends Phaser.Group {
  /**
   * constructor
   */
  constructor() {
    super(game);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;

    this._drawBubbleBg();
    this._drawTextField();
  }

  /**
   * draw the bubble background
   */
  _drawBubbleBg() {
    const bgGraphics = new Phaser.Graphics(game);
    const speechBubbleRect = this._isLandscape ? SPEECH_BUBBLE_RECT_HORIZONTAL : SPEECH_BUBBLE_RECT_VERTICAL;
    bgGraphics.beginFill(SPEECH_BUBBLE_BG_COLOR);
    bgGraphics.drawRoundedRect(
      speechBubbleRect.x, speechBubbleRect.y,
      speechBubbleRect.width, speechBubbleRect.height,
      speechBubbleRect.cornerSize,
    );
    bgGraphics.endFill();
    bgGraphics.x = -bgGraphics.width / 2;
    bgGraphics.y = -bgGraphics.height / 2;
    this.addChild(bgGraphics);
  }

  /**
   * draw the bubble text field
   */
  _drawTextField() {
    const textField = new G.Text(
      0, 0,
      OMT.language.getText(SPEECH_BUBBLE_TEXT),
      SPEECH_BUBBLE_TEXT_STYLE,
      0.5,
      this.width + SPEECH_BUBBLE_TEXT_OFFSET.x,
      this.height + SPEECH_BUBBLE_TEXT_OFFSET.y,
      true, 'center',
    );
    this.addChild(textField);
  }

  /**
   * animate in the bubble
   */
  animateIn() {
    this.scale.set(0);
    game.add.tween(this.scale).to(
      { x: 1, y: 1 },
      SPEECH_BUBBLE_ANIMATION_DURATION,
      Phaser.Easing.Back.Out,
      true,
      SPEECH_BUBBLE_ANIMATION_DELAY,
    );
  }
}
