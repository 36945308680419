G.Gingy = function() {
  Phaser.Group.call(this, game);

  this.gingyShown = false;

  this.gingyImg = G.makeImage(0, 0, "gingerbread_man_large", 0.5, this);
  this.gingyImg.angle = 20;
  this.gingyImg.pivot.y = -340;
  this.gingyImg.scale.setTo(0.76);
  this.gingyImg.x = -150;
  this.gingyImg.pY = 1;


  var bubblesPos = [100, 60, 60, 100];
  this.bubbles = [];
  for (var i = 0; i < 4; i++) {
    const bubble = G.makeImage(bubblesPos[i], 0, 'bubble', 0.5, this);
    bubble.scale.setTo(0);

    // starts scaled down, we want the biggest scale to be 1
    const scaleIncrement = 0.15;
    bubble.destScale = (1 - (scaleIncrement * 3)) + i * scaleIncrement;
    this.bubbles.push(bubble);
  }
  this.bubbleMsg = G.makeImage(320, 0, "tut_bubble_speech", 0.5, this);
  this.bubbleMsg.pY = 0.3;
  this.bubbleMsg.pivot.y = 140;
  this.bubbleMsg.scale.setTo(0);

  this.updateRelY();
};

G.Gingy.prototype = Object.create(Phaser.Group.prototype);

G.Gingy.prototype.showGingy = function(callback, context) {
  G.stopTweens(this.gingyImg);
  this.gingyShown = true;
  this.gingyImg.pY = 1.3;
  this.gingyImg.x = 0;

  var tween = game.add.tween(this.gingyImg)
    .to({x: 200, pY: 0.65}, 800, Phaser.Easing.Cubic.Out, true);

  tween.onComplete.add(function() {
    game.add.tween(this.gingyImg)
      .to({pY: 0.655}, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  }, this);
  if (callback) {
    tween.onComplete.add(callback, context);
  }
};

G.Gingy.prototype.updateRelY = function() {
  for (var i = this.children.length; i--; ){
    if (this.children[i].pY !== undefined) {
      this.children[i].y = this.children[i].pY * game.height;
    }
  }
};

G.Gingy.prototype.update = function() {
  this.updateRelY();
};


G.Gingy.prototype.showMsg = function(text, pY) {
  if (!this.gingyShown) {
    return this.showGingy(function() {
      this.showMsg(text, pY);
    }, this);
  }

  this.bubbleMsg.alpha = 1;
  this.bubbleMsg.scale.setTo(0);
  this.bubbleMsg.pY = 0.3;
  if (this.msg) this.msg.destroy();
  this.msg = new G.Text(0, -5, text, {
    style: 'font-blue',
    fontSize: "40px",
    lineSpacing: -15,
  }, 0.5, 560, 180, true, "center"); 
  this.bubbleMsg.addChild(this.msg);
  
  var nrOfBubbles = this.bubbles.length;
  var gingyPY = 0.65;
  var diff = (gingyPY-0.3);
  var offsetPY = diff/nrOfBubbles;

  this.bubbles.forEach(function(bubble, i) {
    G.stopTweens(bubble);
    bubble.pY = gingyPY - ((i+0.2)*offsetPY);
    if (i == 0) bubble.pY -= 0.2*offsetPY;
    if (i == 3) bubble.pY -= 0.2*offsetPY;
    bubble.alpha = 1;
    bubble.scale.setTo(0);
    game.add.tween(bubble.scale).to({
      x: bubble.destScale,
      y: bubble.destScale,
    }, 400, Phaser.Easing.Elastic.Out, true, i * 100);
  }, this);

  game.add.tween(this.bubbleMsg.scale)
    .to({x : 1, y: 1}, 400, Phaser.Easing.Elastic.Out, true, 400);

  this.updateRelY();
};


G.Gingy.prototype.hide = function() {
  this.gingyShown = false;

  G.stopTweens(this.gingyImg);
  game.add.tween(this.gingyImg).to({
    pY: 1.3,
    x: 0
  }, 300, Phaser.Easing.Cubic.In, true);
  
  G.stopTweens(this.bubbleMsg);
  game.add.tween(this.bubbleMsg)
    .to({alpha: 0}, 200, Phaser.Easing.Cubic.Out, true);

  this.bubbles.forEach(function(bubble, i) {
    G.stopTweens(bubble);
    game.add.tween(bubble)
      .to({alpha: 0}, 200, Phaser.Easing.Cubic.Out, true);    
  }, this);
};

G.Gingy.prototype.setMsg = function(msg) {
  if (msg) {
    this.showMsg(OMT.language.getText(msg.text), 0.3);  
  } else {
    this.hide();
  }
};