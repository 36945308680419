/* eslint-disable no-unused-vars */
import { CandyStatus } from './CandyStatus';
import { TOKEN_TYPES, EDITOR_SYMBOLS } from '../../BoardConstants';

/**
 * class for rope candy status. Seems very similar to Chain?? Not sure why there are 2.
 */
export class CandyStatus_Rope extends CandyStatus {
  /**
   * constructor
   * @param {BoardGameHooks} gameHooks
   */
  constructor(gameHooks) {
    super(gameHooks);
  }

  /**
   * main intialization method
   * @param {Candy} candy
   * @param {Object} config
   */
  init(candy, config) {
    super.init(candy, config);
    this._wrapperImg = G.makeImage(0, 0, 'status_chain_2', 0.5, candy);
  }

  /**
   * on parent candy part of a match.
   * @returns {boolean}
   */
  onMatch() {
    this.signals.onRemove.dispatch();
    this.remove();
    return false;
  }

  /**
   * remove the status and animate it out.
   */
  remove() {
    this._gameHooks.playSound('brick_break');
    G.sb('onCollectableRemove').dispatch('chain', this._candy);

    game.add.tween(this._wrapperImg)
      .to({
        width: this._wrapperImg.width * 1.5,
        height: this._wrapperImg.height * 1.5,
        alpha: 0,
      }, 1000, Phaser.Easing.Cubic.Out, true)
      .onComplete.add(() => {
        this._wrapperImg.destroy();
      }, this);

    G.sb('fx').dispatch('changeCircle', this._candy);
    G.sb('fxTop').dispatch('burstChainAnim', this._candy, this._candy);
    this._gameHooks.playSound('chain_rattle');
  }
}
