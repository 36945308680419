/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-continue */

import RuntimeSpritesheetManager from '@omt-components/Imaging/Spritesheets/RuntimeSpritesheetManager';

/* eslint-disable no-param-reassign */
const MAX_RELOAD_ATTEMPTS = 10;
const RELOAD_ATTEMPT_DELAY = 500;

const DEFAULT_SECONDARY_IMAGES_TIMEOUT = 15000;

let _instance = null;

/**
 * Class for pre-loading OMT assets. This was refactored from legacy code.
 */
export class OMT_AssetLoader {
  /**
   * get static instance
   * @returns {OMT_AssetLoader}
   */
  static getInstance() {
    if (!_instance) _instance = new OMT_AssetLoader();
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    this._assetsFolder = window.assetsFolder ? window.assetsFolder : './assets';
    this.currentConfig = 'hd';
    this.currentConfigMulti = 1;
    this.loadingScreenActive = false;
    this.failedFiles = null;
    this.sfxSprite = null;
    this.shoutoutSprite = null;

    this._secondaryLoader = new Phaser.Loader(game);
    this._secondaryAssetsMap = {};

    this.hasShoutouts = Boolean(G.json['assets/audiosprites/shoutout']);
  }

  /**
   * load boot assets
   * @param {Function} callback
   */
  loadBootAssets(callback) {
    this.failedFiles = [];

    this.loadImages(G.ASSETS.images, true);
    this.loadSpritesheets(G.ASSETS.spritesheets, true);

    const onFileErrorBinding = game.load.onFileError.add((key, file) => {
      this.failedFiles.push(file);
    });

    game.load.onLoadComplete.addOnce(() => {
      this.loadAssetsComplete(0, true, () => {
        onFileErrorBinding.detach();
        this.createSpritesheetMap(true);
        callback();
      });
    });
  }

  /**
   * load game assets
   * @param {Object} soundManager
   * @param {Function} callback
   */
  loadPrimaryAssets(soundManager, callback) {
    this.failedFiles = [];

    game.load.audioSprite('sfx', ['ogg', 'mp3'].map((ext) => `${this._assetsFolder}/sfx/sfx.${ext}`), null, G.json['assets/audiosprites/sfx']);

    if (this.hasShoutouts) {
      game.load.audioSprite('shoutout', ['ogg', 'mp3'].map((ext) => `${this._assetsFolder}/shoutout/shoutout.${ext}`), null, G.json['assets/audiosprites/shoutout']);
    }
    this.loadMusic(G.ASSETS.music);
    this.loadImages(G.ASSETS.images);
    this.loadSpritesheets(G.ASSETS.spritesheets);

    // are these used currently?
    // if(G.ASSETS.json) this.loadJson(G.ASSETS.json);
    this.loadFonts(G.ASSETS.fonts);

    const onFileErrorBinding = game.load.onFileError.add((key, file) => {
      this.failedFiles.push(file);
    });

    game.load.onLoadComplete.addOnce(() => {
      this.loadAssetsComplete(0, false, () => {
        onFileErrorBinding.detach();
        this.processAssets(soundManager);
        callback();
      });
    });
  }

  /**
   * Called when asset loading is complete. Re-load attempts for failed files will be set here.
   * @param {number} reloadCount
   * @param {boolean} optBootPhase
   * @param {Function} callback
   */
  loadAssetsComplete(reloadCount, optBootPhase, callback) {
    if (this.failedFiles.length > 0) { // retry if any errors occurred
      reloadCount++;
      if (reloadCount > MAX_RELOAD_ATTEMPTS) {
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.LoadingAssetFailed, this.failedFiles.length);
        // let errorMsg = "GAME FILES COULD NOT BE LOADED: " + this.getFailedFileOutput();
        // sentry error disabled to reduce spam
        // try { G.Utils.SentryLog.logError(errorMsg) } catch(e) { throw new Error(errorMsg) };
        return;
      }

      // attempt to load each type of failed asset
      let fileName;
      this.failedFiles.forEach((file) => {
        fileName = file.url.indexOf('/') === -1 ? file.url : file.url.split('/').pop();
        if (file.type === 'textureatlas') {
          this.loadSpritesheets([this.removeExt(fileName)], optBootPhase);
        } else if (file.type === 'image') {
          this.loadImages([fileName], optBootPhase);
        } else if (file.type === 'audio') {
          this.loadMusic([this.removeExt(fileName)]);
        }
      });

      // clear failed file list
      this.failedFiles.length = 0;

      // restart asset loading with failed assets
      game.load.onLoadComplete.addOnce(() => { this.loadAssetsComplete(reloadCount, optBootPhase, callback); }, this);
      // add some delay before retrying so not to hammer the server
      setTimeout(() => { game.load.start(); }, RELOAD_ATTEMPT_DELAY);
    } else { // no errors occurred finish
      callback();
    }
  }

  /**
   * get the names of the failed files
   */
  getFailedFileOutput() {
    let output = '';
    this.failedFiles.forEach((file) => {
      if (output.length > 0) output += ', ';
      output += file.url;
    });
    output = `( ${output} )`;
    return output;
  }

  /**
   * process / initialize assets once loading is completed
   * @param {Object} soundManager
   */
  processAssets(soundManager) {
    // if(G.ASSETS.json) this.processJson(G.ASSETS.json);
    this.sfxSprite = new Phaser.AudioSprite(game, 'sfx');
    if (this.hasShoutouts) {
      this.shoutoutSprite = new Phaser.AudioSprite(game, 'shoutout');
    }
    this.processAudio(G.ASSETS.music, soundManager);
    this.createSpritesheetMap();
  }

  /**
   * map frames from spritesheet
   * @param {boolean} boot
   */
  createSpritesheetMap(boot) {
    if (!G.spritesheetMap) G.spritesheetMap = {};

    for (let i = 0, len = G.ASSETS.spritesheets.length; i < len; i++) {
      if (!this._checkIfCorrectLoadingPhase(G.ASSETS.spritesheets[i], boot)) continue;
      const sheetName = this.cutOffPrefixes(G.ASSETS.spritesheets[i]);
      if (game.cache.checkImageKey(sheetName)) {
        const sheet = game.cache.getFrameData(sheetName);
        for (let frameIndex = 0; frameIndex < sheet._frames.length; frameIndex++) {
          const frame = sheet._frames[frameIndex];
          if (G.spritesheetMap[frame.name]) console.warn(`Images name collision: ${frame.name}`);
          G.spritesheetMap[frame.name] = sheetName;
        }
      }
    }
  }

  /**
   * set loading of music files
   * @param {Array} list
   */
  loadMusic(list) {
    list.forEach((fileName) => {
      const name = this.removeExt(fileName);
      game.load.audio(
        name,
        ['ogg', 'mp3'].map((ext) => `${this._assetsFolder}/music/${name}.${ext}`),
      );
    }, this);
  }

  /**
   * load font files
   * @param {Object} fontObj
   */
  loadFonts(fontObj) {
    // eslint-disable-next-line prefer-const
    for (let font in fontObj) {
      if (this._checkIfCorrectLoadingPhase(font)) {
        game.load.bitmapFont(
          this.cutOffPrefixes(font),
          `${this._assetsFolder}/${this.currentConfig}/fonts/${fontObj[font].frame}`,
          `${this._assetsFolder}/${this.currentConfig}/fonts/${fontObj[font].data}`,
        );
      }
    }
  }

  /**
   * set loading of images
   * @param {Array} list
   * @param {boolean} optBootPhase
   */
  loadImages(list, optBootPhase = false) {
    list.forEach((fileName) => {
      if (!this._checkIfCorrectLoadingPhase(fileName, optBootPhase)) return;
      game.load.image(
        this.removeExt(this.cutOffPrefixes(fileName)),
        `${this._assetsFolder}/${this.currentConfig}/images/${fileName}`,
      );
    }, this);
  }

  /**
   * set loading of json files
   * @param {Array} list
   */
  loadJson(list) {
    list.forEach((fileName) => {
      game.load.json(this.removeExt(fileName), `${this._assetsFolder}/json/${fileName}`);
    }, this);
  }

  /**
   * set loading of spritesheets
   * @param {Array} list
   * @param {boolean} optBootPhase
   */
  loadSpritesheets(list, optBootPhase = false) {
    list.forEach((elem) => {
      if (!this._checkIfCorrectLoadingPhase(elem, optBootPhase)) return;
      game.load.atlasJSONHash(
        this.cutOffPrefixes(elem),
        `${this._assetsFolder}/${this.currentConfig}/spritesheets/${elem}.png`,
        undefined,
        G.json[elem],
      );
    }, this);
  }

  /**
   * check if files needs to be loaded in this loading phase
   * @param {string} fileName
   * @param {boolean} optBootPhase
   */
  _checkIfCorrectLoadingPhase(fileName, optBootPhase) {
    function contains(str, substr) { return str.indexOf(substr) !== -1; }
    return (
      contains(fileName, 'BOOT-') === Boolean(optBootPhase) && (OMT.systemInfo.deviceIsDesktop ? !contains(fileName, 'MOBILE-') : !contains(fileName, 'DESKTOP-'))
    );
  }

  /**
   * cut prefixes off the file name
   * @param {string} fileName
   */
  cutOffPrefixes(fileName) {
    // cut off lang prefix
    // eslint-disable-next-line no-useless-escape
    fileName = fileName.replace(/^[A-Z]{2}\-/, '');
    fileName = fileName.replace('BOOT-', '');
    fileName = fileName.replace('MOBILE-', '');
    fileName = fileName.replace('DESKTOP-', '');
    return fileName;
  }

  /**
   * remove extension from filename
   * @param {string} fileName
   */
  removeExt(fileName) {
    if (fileName.lastIndexOf('.') === -1) return fileName;
    return fileName.slice(0, fileName.lastIndexOf('.'));
  }

  /**
   * process json files
   * @param {Array} list
   */
  processJson(list) {
    G.json = {};
    list.forEach((fileName) => {
      fileName = this.removeExt(fileName);
      G.json[fileName] = game.cache.getJSON(fileName);
    }, this);
  }

  /**
   * process audio files
   * @param {Array} list
   * @param {Object} soundManager
   */
  processAudio(list, soundManager) {
    G.sfx = {};
    game.sfx = G.sfx;

    const clusters = {};

    list.forEach((elem) => {
      elem = this.removeExt(elem);

      if (elem === 'music') {
        G.music = soundManager.wrapAudioElement(game.add.audio(elem), elem);
      }
    }, this);

    this.processAudiosprite(this.sfxSprite, soundManager, clusters);
    if (this.hasShoutouts) {
      this.processAudiosprite(this.shoutoutSprite, soundManager, clusters);
    }

    // Organizes SFX into clusters (randomized pools)
    // Randomized SFX are currently not being used in the game

    // Object.keys(clusters).forEach((key) => {
    //   const sfxArray = clusters[key];
    //   G.sfx[key] = {
    //     sfxArray,
    //     play: (volume, loop, forceRestart) => {
    //       const sound = game.rnd.pick(sfxArray);
    //       sound.play('', 0, volume, loop, forceRestart);
    //     },
    //   };
    // });
  }

  /**
   * processes audiosprite for use in game
   * @param {Object} audiosprite
   * @param {Object} soundManager
   * @param {Array} clusters
   */
  processAudiosprite(audiosprite, soundManager, clusters) {
    for (const key in audiosprite.sounds) {
      if (Object.prototype.hasOwnProperty.call(audiosprite.sounds, key)) {
        const elem = audiosprite.sounds[key];
        G.sfx[key] = soundManager.wrapAudioElement(elem, key, audiosprite);
        const lastIndex = key.lastIndexOf('_');

        if (lastIndex !== -1 && !isNaN(key.slice(lastIndex + 1))) {
          const name = key.slice(0, lastIndex);
          if (!clusters[name]) clusters[name] = [];
          clusters[name].push(G.sfx[key]);
        }
      }
    }
  }

  /**
   * load secondary assets from ./build/img folder to the game cache
   * @param {string} filterString to apply to asset loading
   * @param {boolean} trimPath (optional) remove path segments from asset keys in the game cache
   * @param {string} spritesheetID (optional) if passed the assets will be added to a RuntimeSpritesheet instance
   * @returns {Promise}
   */
  async loadSecondaryImages(filterString, trimPath = false, spritesheetID = null) {
    let secondaryAssetData = this._secondaryAssetsMap[filterString];
    // if load has already been called we return the promise already created, or null in the case it is loaded
    if (this._secondaryAssetsMap[filterString] != null) return secondaryAssetData.promise;
    // object for tracking status of secondary asset loading
    secondaryAssetData = {
      urlList: G.ASSETS.extImages.filter((url) => url.indexOf(filterString) === 0),
      loaded: false,
      keys: [],
      promise: null,
    };
    this._secondaryAssetsMap[filterString] = secondaryAssetData;

    secondaryAssetData.promise = new Promise((resolve) => {
      const imagesToLoad = secondaryAssetData.urlList.length;
      let imagesLoaded = 0;
      // loader.onFileLoaded callback
      const onFileLoadedBinding = this._secondaryLoader.onFileComplete.add((progress, key, success) => {
        if (secondaryAssetData.keys.indexOf(key) === -1) return; // different filter string
        imagesLoaded++;
        if (imagesLoaded === imagesToLoad) { // all assets loaded
          onFileLoadedBinding.detach();
          secondaryAssetData.loaded = true;
          secondaryAssetData.promise = null;
          if (spritesheetID) { // convert to a spritesheet
            this.convertSecondaryImagesToRuntimeSpritesheet(filterString, spritesheetID);
            this.purgeSecondaryImages(filterString);
          }
          console.log(`[LOAD COMPLETE] ${filterString}`);
          resolve();
        }
      });
      // start the image loading and parse the cache keys
      let imgKey;
      for (const imgURL of secondaryAssetData.urlList) {
        [imgKey] = imgURL.split('.');
        if (trimPath) imgKey = imgKey.split('/').pop();
        this._secondaryLoader.image(imgKey, `img/${imgURL}`);
        secondaryAssetData.keys.push(imgKey);
      }
      this._secondaryLoader.start();
    });
    return secondaryAssetData.promise;
  }

  /**
   * wait on secondary image loads. will return false if this operation times out.
   * @param {Array.<string>} filterList list of filter strings
   * @param {number} timeout (optional)
   * @returns {Promise<boolean>} success
   */
  async waitOnSecondaryImages(filterList, timeout = DEFAULT_SECONDARY_IMAGES_TIMEOUT) {
    let imagesLoaded = this.areSecondaryImagesLoaded(filterList);
    if (imagesLoaded) return true;

    const startTime = Date.now();
    const timeoutTime = startTime + timeout;
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        imagesLoaded = this.areSecondaryImagesLoaded(filterList);
        if (imagesLoaded) {
          clearInterval(intervalId);
          resolve(true);
        } else if (Date.now() >= timeoutTime) {
          resolve(false);
        }
      }, 100);
    });
  }

  /**
   * @param {Array.<string>} filterList list of filter strings
   * @returns {boolean}
   */
  areSecondaryImagesLoaded(filterList) {
    for (const filterString of filterList) {
      if (!this._secondaryAssetsMap[filterString] || !this._secondaryAssetsMap[filterString].loaded) return false;
    }
    return true;
  }

  /**
   * purge secondary images from game cache and destroy baseTextures
   * @param {string} filterString filter used for loadSecondaryImages()
   */
  purgeSecondaryImages(filterString) {
    const secondaryAssetData = this._secondaryAssetsMap[filterString];
    if (!secondaryAssetData) return;
    for (const key of secondaryAssetData.keys) {
      game.cache.removeImage(key);
    }
  }

  /**
   * convert secondary images loaded to a RuntimeSpritesheet
   * @param {string} filterString filter used for loadSecondaryImages()
   * @param {string} spritesheetID
   */
  convertSecondaryImagesToRuntimeSpritesheet(filterString, spritesheetID) {
    const secondaryAssetData = this._secondaryAssetsMap[filterString];
    if (!secondaryAssetData || !secondaryAssetData.loaded) return;
    for (const key of secondaryAssetData.keys) {
      RuntimeSpritesheetManager.getInstance().renderBaseTextureToSpritesheet(spritesheetID, key);
    }
  }
}
