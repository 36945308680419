import WorldMap2_Util, { interactionType, WorldMapTwoMapInteractionLimit } from '../../../../Elements/WorldMap2/WorldMap2_Util';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

export const MAILBOX_MANAGER_DATA_KEY = 'mailbox-manager';

/**
 * Class for handling mailbox data
 */
export class MailboxManager {
  constructor() {
    this._config = G.json['configs/mailbox'];
  }

  init(dataRef) {
    this.dataReference = dataRef;

    let requiresFixing = false;
    if (!dataRef.um && dataRef.ms) {
      requiresFixing = true; // 07/10/20 - Found a bug that didn't really mark mailboxes as seen, because it was tracked in the wrong array
    }

    /**
     * Mail Seen is for postcard indexes. It keeps track which postcard has been seen and which shouldn't show again until all are seen
     * Unopened Mailbox are for mailbox entries that are generated and not opened. Will be removed when opened
     */
    const defaultData = {
      cr: 0, // cs = Current Reward
      ms: [], // ms = Mail Seen, the postcard index
      o: 0, // Number of times mailbox has been [O]pened
      um: [], // um = Unopened Mailbox, as seen from the world map
    };

    OMT_Utils.mergeMissingObject(this.dataReference, defaultData);

    let doSaveCall = false;
    if (requiresFixing) {
      this._fixMailboxData();
      doSaveCall = true;
    }
    if (doSaveCall) {
      this.save();
    }
  }

  /**
   * 07/10/20 - I identified an issue where I was tracking mailbox entries in the mail (postcard) seen array.
   * In the case where all postcards were opened, it would wipe itself, and suddenly, every mailbox possible is opened again!
   *
   * This function will separate the postcards from the mailbox opened array.
   * Unopened mailboxes will be put into its own array and managed like the other map interactions where they generate when a gate opens
   * After the separation, there might be an edge case where a postcard was previously seen and is now set to unseen.
   *
   * Migration only occurs when the unseenMail parameter in the data is not there when it comes in
   */
  _fixMailboxData() {
    const unlockLevelsFromZero = this.determineUnlockLevel(); // Determine unlock levels

    const realMailSeenArray = []; // Array that holds postcard indexes
    const mailboxOpenedArray = []; // Array that holds opened mailbox instances

    // First need to separate postcard indexes with mailbox indexes
    for (const mailIndex of this.dataReference.ms) { // eslint-disable-line guard-for-in
      if (mailIndex < WorldMapTwoMapInteractionLimit) { // Couldn't have been a seen mail anyways
        realMailSeenArray.push(mailIndex);
      } else if (mailIndex > this._config.messages.length) { // Definitely a level and not a mail index
        mailboxOpenedArray.push(mailIndex);
      } else if (unlockLevelsFromZero.indexOf(mailIndex % G.saveState.gateManager.levelGap) > -1) { // It could have been a mail
        mailboxOpenedArray.push(mailIndex);
      } else {
        realMailSeenArray.push(mailIndex);
      }
    }
    this.dataReference.ms = realMailSeenArray; // Mail seen is now fixed

    // Calculate the mailboxes that weren't opened based on array of opened mailboxes
    const unOpenedMailboxes = [];
    const prevGate = Math.max(G.saveState.gateManager.getNextGateLevel() - G.saveState.gateManager.levelGap, 0);
    // Calculate mailbox entries up to the gate you just opened
    for (let currentLevel = 0; currentLevel < prevGate; currentLevel += G.saveState.gateManager.levelGap) {
      for (const mailIndex of unlockLevelsFromZero) {
        const unlockLevel = mailIndex + currentLevel;
        // If mailbox unlock level has not been opened previously, and greater than the interaction limit
        if (mailboxOpenedArray.indexOf(unlockLevel) === -1 && unlockLevel > WorldMapTwoMapInteractionLimit) {
          unOpenedMailboxes.push(unlockLevel); // Push into potentially open-able mailbox entries
        }
      }
    }
    if (unOpenedMailboxes.length > 0) {
      this.generateMailboxes(unOpenedMailboxes); // Generate mailboxes based on level.
    }
  }

  /**
   * Determines the levels the chest shuffle should be unlockable at, starting from 0
   * Generates levels calculating from the rewards per tile length
   * @returns {Array<number>}
   */
  determineUnlockLevel() {
    const mailboxInstances = G.OMTsettings.elements.worldMap.rewardPerTile.map((reward) => reward.toLowerCase() === interactionType.mailbox);
    const levelUnlocksFromZero = [];
    mailboxInstances.forEach((isMailbox, index) => {
      if (isMailbox) {
        levelUnlocksFromZero.push(WorldMap2_Util.getLevelCountAtTile(index) - 1);
      }
    });
    return levelUnlocksFromZero;
  }

  /**
   * Generates a mailbox at the given levels
   * If no levels are given, it will generate some at the beginning
   * @param {Array<number>} levelUnlock
   */
  generateMailboxes(levelUnlock = []) {
    if (levelUnlock.length === 0) {
      levelUnlock = this.determineUnlockLevel();
    }

    levelUnlock.forEach((level) => {
      if (!this.doesMailboxExistAtLevel(level) && level > WorldMapTwoMapInteractionLimit) {
        this.dataReference.um.push(level);
      }
    });
  }

  /**
   * Returns the number of times the mailbox pop up has been opened
   * @returns {number}
   */
  get opened() {
    return this.dataReference.o;
  }

  /**
   * Returns all levels that a mailbox would unlock at
   * @returns {Array<number>}
   */
  getAllExistingMailboxLevels() {
    return this.dataReference.um.map((mailbox) => mailbox);
  }

  getNextReward() {
    const saveData = this.dataReference;
    const reward = this._config.rewards[saveData.cr];
    const nextReward = saveData.cr + 1;

    if (nextReward >= this._config.rewards.length) {
      this.setMailboxReward(0);
    } else {
      this.setMailboxReward(nextReward);
    }

    return reward;
  }

  /**
   * Returns a random postcard
   * @returns {{category:string, lines: Array<string>, photo:string, avatar:{x:number, y:number, angle:number, width:number, height:number}}}
   */
  getRandomMail() {
    const saveData = this.dataReference;
    const allChoices = [];
    for (let i = 0; i < this._config.messages.length; i++) {
      allChoices.push(i);
    }

    const availableChoices = [...allChoices].filter((choice) => !saveData.ms.includes(choice));
    let index;

    if (availableChoices.length === 0) {
      index = allChoices[ArrayUtil.getRandomIndex(allChoices)];
      this.clearSeenMail();
    } else {
      index = availableChoices[ArrayUtil.getRandomIndex(availableChoices)];
    }

    this.markMailAsSeen(index);
    return this._config.messages[index];
  }

  /**
   * Return a specific mail postcard, for debugging purposes
   * @param {integer} mailNum
   * @param {boolean} track if true, mark the mail as seen as if it were legitimately chosen at random
   */
  _getSpecificMail(mailNum, track = false) {
    if (track) this.markMailAsSeen(mailNum);
    return this._config.messages[mailNum];
  }

  /**
   * Increment the number of times the mailbox has been seen
   */
  incrementSeenMail() {
    this.dataReference.o++;
  }

  /**
   * Sets mailbox reward number
   * @param {integer} num
   * @param {boolean} saveNow
   */
  setMailboxReward(num, saveNow = true) {
    this.dataReference.cr = num; // currentReward
    if (saveNow) this.save(); // Mail Seen
  }

  /**
   * Marks all mailbox postcard as unseen
   * @param {boolean} saveNow
   */
  clearSeenMail(saveNow = true) {
    this.dataReference.ms = [];
    if (saveNow) this.save();
  }

  /**
   * Mark a mail postcard as seen
   * @param {integer} num Usually the level it unlocks at
   * @param {boolean} saveNow
   */
  markMailAsSeen(num, saveNow = true) {
    if (!this.hasMailBeenSeen(num)) {
      this.dataReference.ms.push(num);
      if (saveNow) this.save();
    }
  }

  /**
   * Checks if the mail has already been seen
   * @param {number} mailId
   * @returns {boolean}
   */
  hasMailBeenSeen(mailId) {
    return this.dataReference.ms.indexOf(mailId) > -1;
  }

  /**
   * Checks if theres supposed to be a mailbox at the given level.
   * Not to be confused with postcard mail
   * @param {number} level
   */
  doesMailboxExistAtLevel(level) {
    return this.dataReference.um.find((mailboxLevel) => mailboxLevel === level);
  }

  /**
   * Marks the mailbox as seen by ripping it out of the array
   * Not to be confused with postcard indexes
   * @param {number} level
   * @param {boolean} saveNow
   */
  markMailboxAsSeen(level, saveNow) {
    const mailbox = this.doesMailboxExistAtLevel(level);
    if (mailbox) {
      this.dataReference.um.splice(this.dataReference.um.indexOf(mailbox), 1);
      if (saveNow) {
        this.save();
      }
    }
  }

  /**
   * Marks all mail up to the given level as seen
   * @param {number} level
   */
  removeAllMailboxesUpToLevel(level) {
    _.remove(this.dataReference.um, (mailboxUnlock) => mailboxUnlock < level);
    this.save();
  }

  /**
   * Save call for save key
   */
  save() {
    OMT.userData.writeUserData(MAILBOX_MANAGER_DATA_KEY, this.dataReference);
  }
}
