import { GameScaleController } from '../../../../States/Scaling/GameScaleController';
import { OMT_SystemInfo, ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';
import UI_BoosterLabel from '../Boosters/UI_BoosterLabel';

export default class Overlay {
  /**
   * Overlay class that shows up on the game board.
   * Not to be confused with Overlay2
   * @param {Game} state
   */
  constructor(state) {
    if (!this._overlayBitmap) {
      this._overlayBitmap = game.make.bitmapData(256, 256);
      this._overlayBitmap.fill(255, 0, 0, 1);
    }

    this._state = state; // Game.js
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    if (game.width !== this._overlayBitmap.width || game.height !== this._overlayBitmap.height) {
      this._overlayBitmap.resize(game.width, game.height);
    }

    this._board = this._state.board;
    this._fadeTween = undefined; // Tween for fading in and out. Needs to be assigned so it can be stopped

    this._img = this._overlayBitmap.addToWorld();
    this._img.x = game.world.bounds.x;
    this._img.alpha = 0;

    this._signalBindings = [];
    this._signalBindings.push(G.sb('onScreenResize').add(this.onResize, this));

    // Group placeholder for aboveGroup palcement
    this.topBar = game.add.group();
    this.topBar.position = this._state.topBar.position;

    // Group placeholder for aboveGroup palcement
    this.boosterGroup = game.add.group();
    this.boosterGroup.position = this._state.boosterPanel.position;

    this._tasks = [];
    this._aboveObjects = [];

    this._signalBindings.push(G.sb('closeOverlay').add(() => {
      if (!G.tutorialOpened) this.hideAndClear();
    }));
    this._signalBindings.push(G.sb('startOverlay').add((tasks) => {
      if (!G.tutorialOpened) this.start(tasks);
    }));

    this._alphaValue = 0.7;
    this.boosterLabel = new UI_BoosterLabel(this._board); // Accessed externally
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._signalBindings) {
      this._signalBindings.forEach((binding) => {
        if (binding.detach) {
          binding.detach();
        }
      });
      this._signalBindings = null;
    }
    this._img.destroy();
  }

  /**
   * Hides the fader
   */
  hideAndClear() {
    G.stopTweens(this);
    if (this._fadeTween) {
      this._fadeTween.stop();
    }
    this._fadeTween = game.add.tween(this._img).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
    this._fadeTween.onComplete.add(() => {
      this._tasks = [];
      this.moveAboveObjectsToOriginalParents();
    });
  }

  /**
   * Creates a square in the overlay to show a cell.
   * Possibly used in tutorial
   * @param {number} x
   * @param {number} y
   */
  clearCell(x, y) {
    const tileSize = this._board.tileSize * this._board.scale.x;
    const xx = this._board.x + (x * tileSize);
    const yy = this._board.y + (y * tileSize);
    this._overlayBitmap.context.clearRect(-game.world.bounds.x + xx, yy, tileSize, tileSize);
  }

  /**
   * Clears an area in the overlay to emphasize the area
   * @param {string} areaName
   */
  clearArea(areaName) {
    let areaBounds;
    let centerPoint;
    switch (areaName.toLowerCase()) {
      case 'points':
        areaBounds = this._state.topBar.pointsCounter.getBounds().clone();
        centerPoint = areaBounds.x + areaBounds.width / 2;
        areaBounds.width = 355; // Based on Fish's image
        areaBounds.height = 45;
        areaBounds.x = centerPoint - areaBounds.width / 2;
        break;
      case 'goal':
        if (!this._isLandscape) {
          areaBounds = this._state.topBar.goalPanel.getBounds().clone();
          centerPoint = areaBounds.x + areaBounds.width / 1.5;
          areaBounds.width = 355; // Based on Fish's image
          areaBounds.height = 70;
          areaBounds.x = centerPoint - areaBounds.width / 2;
        } else {
          areaBounds = this._state.topBar.taskAreaDimensions.getBounds().clone();
          centerPoint = {
            // x: this._state.topBar.x + areaBounds.x - 55,
            // y: this._state.topBar.y + areaBounds.y - 170,
            x: this._state.topBar.x + areaBounds.x - 190,
            y: this._state.topBar.y + areaBounds.y - 420,
          };

          areaBounds.width = 238; // Based on RAIN's image
          areaBounds.height = 446;
          areaBounds.x = centerPoint.x;
          areaBounds.y = centerPoint.y;
        }
        break;
      case 'moves': {
        const content = this._state.topBar.progressBar.barProgress.getBounds().clone();
        content.height += 50; // Extra for the Moves part
        centerPoint = content.x + content.width / 2; // Find center
        const centerY = content.y + content.height / 2; // Find center
        content.width += 40; // For any stars outside
        content.x = centerPoint - content.width / 2; // Scoot
        content.height += 20; // Another 20, for the stars
        content.y = centerY - content.height / 2; // Scoot
        areaBounds = content;
      }
        break;
      case 'menu':
        areaBounds = this._state.settingsMenu.getTriggerButtonBounds();
        /* if (this._isLandscape) {
          areaBounds.x += 278;
          areaBounds.y += 110;
        } */
        break;
      case 'menu_expanded':
        areaBounds = this._state.settingsMenu.getExpandedMenuBounds();
        /* if (this._isLandscape) {
          areaBounds.x += 310;
          areaBounds.y += 110;
        } */
        break;
      default: break;
    }

    if (areaBounds) {
      if (this._isLandscape && areaName.toLowerCase() !== 'menu' && areaName.toLowerCase() !== 'menu_expanded') {
        areaBounds.x = (areaBounds.x - 480) * this._gameScale + 480;
        areaBounds.y = (areaBounds.y - 320) * this._gameScale + 320;
        areaBounds.width *= this._gameScale;
        areaBounds.height *= this._gameScale;
      }
      this._overlayBitmap.context.clearRect(areaBounds.x, areaBounds.y, areaBounds.width, areaBounds.height);
    }
  }

  /**
   * Sets the tasks in
   * @param {Array<any>} task
   */
  set(task) {
    if (task) {
      this.start(task);
    } else {
      this.hideAndClear();
    }
  }

  /**
   * Starts doing tasks
   * @param {Array<any>} tasks
   */
  start(tasks) {
    G.stopTweens(this);
    if (this._fadeTween) {
      this._fadeTween.stop();
    }

    this._tasks = tasks;

    this._overlayBitmap.cls();
    this._overlayBitmap.fill(0, 0, 0, this._alphaValue);
    this.redoTasks();

    if (this._img.alpha === 1) return;
    this._fadeTween = game.add.tween(this._img).to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out, true);
  }

  /**
   * Unfortunately the previous code was so bad I don't know what its trying to do
   * And I still don't.
   * The commands come from tutorials.json as overlayTask
   */
  redoTasks() {
    for (let i = this._tasks.length - 1; i > -1; i--) {
      const task = this._tasks[i];
      this[task[0]].apply(this, task.slice(1)); // eslint-disable-line prefer-spread
    }
  }

  /**
   * Clears a bunch of cells
   * @param {Array<number} array
   */
  clearCells(array) {
    for (let i = 0, len = array.length; i < len; i += 2) {
      // console.log('creal cells: '+i);
      this.clearCell(array[i], array[i + 1]);
    }
  }

  /**
   * Clears the entire board
   */
  clearBoard() {
    const tileSize = this._board.tileSize * this._board.scale.x;
    const halfTilesize = tileSize * 0.5;
    this._board.levelGridData.loop((val, x, y) => {
      if (this._board.isCellOnBoard(x, y)) {
        const pxOut = this._board.cellToPxOut([x, y]);
        this._overlayBitmap.context.clearRect(
          -game.world.bounds.x + pxOut[0] - halfTilesize - 6,
          pxOut[1] - halfTilesize - 6,
          tileSize + 12,
          tileSize + 12,
        );
      }
    });
  }

  /**
   * Resizes the overlay and then redoTasks to make the cuts
   */
  onResize() {
    if (game.width !== this._overlayBitmap.width || game.height !== this._overlayBitmap.height) {
      this._overlayBitmap.resize(game.width, game.height);
    }
    this._overlayBitmap.fill(0, 0, 0, this._alphaValue);
    this._img.x = game.world.bounds.x;

    if (this.continuePrompt) {
      this.continuePrompt.y = game.world.bounds.y + game.height - this.continuePrompt.height;
      this.continuePrompt.x = game.world.bounds.x + game.width / 2;
    }

    game.time.events.add(5, this.redoTasks.bind(this));
  }

  /**
   * Puts certain objects on the aboveGroup, which should be over the overlay, to avoid being overlayed
   * @param {DisplayObject} obj
   * @param {DisplayObject} aboveGroup
   */
  moveToAboveGroup(obj, aboveGroup, aboveGroupScale = 1) {
    // check if it is already here
    if (obj.parent === this[aboveGroup]) {
      return;
    }

    obj._originalParent = obj.parent;
    obj._aboveGroupParentData = {
      parent: this[aboveGroup],
      scale: this[aboveGroup].scale.x,
    };
    this[aboveGroup].scale.setTo(aboveGroupScale);
    this[aboveGroup].add(obj);
    this._aboveObjects.push(obj);
  }

  /**
   * Moves objects back to parents
   */
  moveAboveObjectsToOriginalParents() {
    for (let i = this._aboveObjects.length - 1; i > -1; i--) {
      const obj = this._aboveObjects[i];
      const { parent, scale } = obj._aboveGroupParentData;
      parent.scale.setTo(scale);
      obj._originalParent.add(obj);
    }
  }

  /**
   * Shows a Tap to continue... message on the bottom
   */
  showContinuePrompt() {
    this.continuePrompt = new G.Text(0, 0, this._board.gameHooks.getText('Tap to continue...'), { style: 'font-white', fontSize: 48 }, 0.5, 500);
    this.continuePrompt.y = game.world.bounds.y + game.height - this.continuePrompt.height * 2;
    this.continuePrompt.x = game.world.bounds.x + game.width / 2;
    game.add.tween(this.continuePrompt)
      .to({ alpha: 0.5 }, 1000, Phaser.Easing.Sinusoidal.InOut, true, null, -1, true);
    if (this._img.parent) {
      const par = this._img.parent;
      // const index = Math.max(0, par.getChildIndex(this._img) - 1);
      par.addChild(this.continuePrompt);
    }
  }

  /**
   * Tap to continue prompt is destroyed
   */
  removeContinuePrompt() {
    if (this.continuePrompt) {
      if (this.continuePrompt.parent) {
        this.continuePrompt.parent.removeChild(this.continuePrompt);
        this.continuePrompt.destroy();
        this.continuePrompt = null;
      }
    }
  }
}
