import OMT_UI_AvatarWithBadge from '../../../OMT_UI/FriendsList/OMT_UI_AvatarWithBadge';
import { OMT_UI_AvatarWithFrame } from '../../../OMT_UI/FriendsList/OMT_UI_AvatarWithFrame';

import {
  LEADERBOARD_AVATAR_FONT_FACE,
  LEADERBOARD_AVATAR_TOP3_POS,
  LEADERBOARD_AVATAR_TOP3_FONT_SIZE,
  LEADERBOARD_AVATAR_OTHER_POS,
  LEADERBOARD_AVATAR_OTHER_FONT_SIZE,
  LEADERBOARD_AVATAR_FRAME_CORNER_SIZE,
  LEADERBOARD_AVATAR_CROWN_OFFSET,
  LEADERBOARD_AVATAR_CROWN_ANGLE,
  LEADERBOARD_AVATAR_CROWN_SCALE,
} from '../../ComponentSettings';

const DEFAULT_AVATAR_SIZE = 60;

/**
 * Custom Avatar for the leadboards
 */
export class LeaderboardAvatar extends OMT_UI_AvatarWithFrame {
  /**
   * constructor
   * @param {Object} friendData
   * @param {number} imageSize (optional)
   * @param {number} rank (optional)
   */
  constructor(friendData, imageSize = DEFAULT_AVATAR_SIZE, rank = -1) {
    super(friendData.image, imageSize);
    this._frameSet = false;
    this._imageLoaded = false;
    this._initLeaderboardAvatarElements(friendData, rank);
    this.scale.set(imageSize / DEFAULT_AVATAR_SIZE);
  }

  /**
   * we dont want this to execute, uses _initLeaderboardAvatarElements instead
   */
  _initAvatarElements() {}

  /**
   * init the avatar elements with the rank added
   * @param {Object} friendData
   * @param {number} rank
   */
  _initLeaderboardAvatarElements(friendData, rank) {
    const isYourAvatar = friendData.userId === OMT.envData.settings.user.userId;
    let targetBadge = friendData.badge;
    let avatarSize = this._imageSize;
    if (!targetBadge) {
      targetBadge = isYourAvatar ? 'leaderboardPlayer' : 'leaderboardOther';
    } else {
      avatarSize = this._imageSize * 0.65;
    }
    this._avatarImage = new OMT_UI_AvatarWithBadge(friendData.image, targetBadge, avatarSize);
    this.addChild(this._avatarImage);
    if (rank >= 1) this._setRank(rank, isYourAvatar);
  }

  /**
   * Doesn't init the avatar frame
   */
  _initAvatarFrame() {
  }

  /**
   * Doesn't the user avatar image
   */
  _initAvatarImage() {
  }

  /**
   * called when the intial avatar image has loded
   * @param {Phaser.Image} image
   */
  _onAvatarImageLoaded(image) {
    super._onAvatarImageLoaded(image);
    this._avatarImage = image;
    this.addChildAt(this._avatarImage, 0);
    // this._applyAvatarMask();
  }

  /**
   * apply / create the avatar mask
   */
  _applyAvatarMask() {
    if (!this._avatarMask) { // create mask if not set
      const cornerSize = LEADERBOARD_AVATAR_FRAME_CORNER_SIZE;
      const avatarMask = new Phaser.Graphics(game);
      avatarMask.beginFill(0xFF0000, 0.5);
      avatarMask.drawRoundedRect(0, 0, 100, 100, cornerSize);
      avatarMask.endFill();
      avatarMask.x = avatarMask.y = -(this._imageSize / 2);
      avatarMask.width = avatarMask.height = this._imageSize;
      this.addChild(avatarMask);
      this._avatarMask = avatarMask;
    }
    this._avatarImage.mask = this._avatarMask;
  }

  /**
   * set the optional rank display
   * @param {number} rank user rank #
   * @param {boolean} isYourAvatar
   */
  _setRank(rank, isYourAvatar) {
    // create rank graphics group
    const rankGroup = new Phaser.Group(game);
    rankGroup.x = rankGroup.y = -(this._imageSize / 2);
    rankGroup.scale.set(this._imageSize / DEFAULT_AVATAR_SIZE);
    this.addChild(rankGroup);

    // fill rank content
    let assetFrame; let rankBgGraphic; let rankField;
    if (rank <= 3) { // rank 1 - 3
      // bg graphics
      if (rank === 1) assetFrame = 'lb2_1st_place';
      else if (rank === 2) assetFrame = 'lb2_2nd_place';
      else if (rank === 3) assetFrame = 'lb2_3rd_place';
      rankBgGraphic = G.makeImage(0, 0, assetFrame, [0.2, 0.2]);
      rankGroup.addChild(rankBgGraphic);
      // rank field
      rankField = new G.Text(
        LEADERBOARD_AVATAR_TOP3_POS.x, LEADERBOARD_AVATAR_TOP3_POS.y,
        `${rank}`, { style: LEADERBOARD_AVATAR_FONT_FACE, fontSize: LEADERBOARD_AVATAR_TOP3_FONT_SIZE },
        [0.5, 0.5], 40, 40, false, 'center',
      );
      rankGroup.addChild(rankField);
      // add the crown to the image if #1
      if (rank === 1) {
        const crownImage = G.makeImage(0, 0, 'lb2_crown', [0.5, 0.5], rankGroup);
        crownImage.x = this._avatarImage.x + DEFAULT_AVATAR_SIZE + LEADERBOARD_AVATAR_CROWN_OFFSET.x;
        crownImage.y = this._avatarImage.y + LEADERBOARD_AVATAR_CROWN_OFFSET.y;
        crownImage.angle = LEADERBOARD_AVATAR_CROWN_ANGLE;
        crownImage.scale.set(LEADERBOARD_AVATAR_CROWN_SCALE);
      }
    } else { // rank 4 +
      // bg graphics
      assetFrame = isYourAvatar ? 'lb2_avatar_user_position' : 'lb2_avatar_main_position';
      rankBgGraphic = G.makeImage(0, 0, assetFrame, [0, 0]);
      rankBgGraphic.scale.set(0.75);
      // rankBgGraphic.alpha = 0.5;
      rankGroup.addChildAt(rankBgGraphic, 0);
      // rank field
      rankField = new G.Text(
        LEADERBOARD_AVATAR_OTHER_POS.x, LEADERBOARD_AVATAR_OTHER_POS.y,
        `${rank}`, { style: LEADERBOARD_AVATAR_FONT_FACE, fontSize: LEADERBOARD_AVATAR_OTHER_FONT_SIZE, align: 'left' },
        [0, 0.5], 40, 40, false,
      );
      rankField.x -= rankField.width / 2; // align wasnt working right
      rankGroup.addChild(rankField);
    }
  }
}
