import OMT_PrizeWheel from './OMT_PrizeWheel';

// used to configure this class + elements of UI_PrizeWheel
const DEFAULT_CONFIG = {
  spinner: {
    wheelRotation: 120,
    wheelSegments: 24,
    bgAssetNormal: 'prize_wheel_2',
    bgAssetPremium: 'gold_wheel',
    bgOffsetAngle: 180,
  },
  isMoreMoneyMode: false,
  isPremiumMode: true,
  isFirstTimeSpinning: false,
  bg: {
    asset: 'shine_godrays', scale: 1, x: 0, y: 0, anchor: [0.5, 0.5], alpha: 1, rotationSpeed: 0.01,
  },
};

/**
 * daily / daily premium prize wheel
 */
export default class OMT_DailyPrizeWheel extends OMT_PrizeWheel {
  /**
   * constructor
   * @param {Object} config (optional) components configurations
   * @param {Object} config.spinner (optional) overrides for PrizeWheel_Spinner DEFAULT_CONFIG
   * @param {Object} config.pointer (optional) overrides for PrizeWheel_Pointer DEFAULT_CONFIG
   * @param {Object} config.itemConfigsOverride (opional) overrides for ITEM_CONFIGS from OMT_PrizeWheel
   * @param {boolean} config.isMoreMoneyMode (optional) more money mode effects prize table
   * @param {boolean} config.isPremiumMode (optional) premium mode effects prize table
   * @param {boolean} config.isFirstTimeSpinning (optional) first-time user flag
   */
  constructor(config = {}) {
    super(_.merge(_.cloneDeep(DEFAULT_CONFIG), config));

    const { itemConfigOverrides } = this._config;
    const prizeTable = this._getPrizeTable();
    const itemDatalist = this._convertPrizeList(prizeTable, itemConfigOverrides);
    this.initPrizeItems(itemDatalist);

    this._createBackground(this._config.bg);
  }

  /**
   * create spinning wheel
   * @param {Object} config overrides for PrizeWheel_Spinner DEFAULT_CONFIG
   */
  _createSpinner(config) {
    const { isPremiumMode } = this._config;
    const { bgAssetPremium, bgAssetNormal } = config;
    config.bgAsset = isPremiumMode ? bgAssetPremium : bgAssetNormal;
    super._createSpinner(config);
  }

  /**
   * Gets a list of wheel prizes (free and ad-supported)
   * @returns {Array.<Object>}
   */
  _getPrizeTable() {
    const { isMoreMoneyMode, isPremiumMode } = this._config;
    let prizeTable = null;
    if (G.IAP) prizeTable = G.json['configs/spinWheels'].prizeWheelNormal;
    else if (isMoreMoneyMode && isPremiumMode) prizeTable = G.json['configs/spinWheels'].moreMoney;
    else if (!isMoreMoneyMode && isPremiumMode) prizeTable = G.json['configs/spinWheels'].prizeWheelGold;
    else prizeTable = G.json['configs/spinWheels'].prizeWheelNormal;
    return _.cloneDeep(prizeTable);
  }
}
