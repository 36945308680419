G.UI_PopOutMoney = function() {
	
	Phaser.Image.call(this,game,0,0,null);

	this.state = game.state.getCurrentState();
	this.double = this.state.doubleMoney;

	G.changeTexture(this,this.double ? 'coin_2' : 'coin_1');
	this.anchor.setTo(0.5);
	this.kill();


};

G.UI_PopOutMoney.prototype = Object.create(Phaser.Image.prototype);

G.UI_PopOutMoney.prototype.init = function(x,y) {

	G.stopTweens(this);
	this.revive();

	G.saveState.changeCoins(this.double ? 2 : 1);
	G.sb("onLevelMoneyGain").dispatch(this.double ? 2 : 1);
	G.sfx.cash_register.play();

	this.x = x;
	this.y = y;

	this.scale.setTo(0);
	this.angle = -10;

	game.add.tween(this).to({y: this.y-Math.floor((Math.random()*20)+30)},500,Phaser.Easing.Cubic.InOut,true,0,0,true);

	game.add.tween(this.scale).to({x: 1,y:1},500,Phaser.Easing.Cubic.InOut,true,0,0,true).onComplete.add(this.kill,this);


};
