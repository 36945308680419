if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getProgressionFlowPanelProgressionAnimation = function getProgressionFlowPanelProgressionAnimation() {

  var onStart = new Phaser.Signal();
  var onUpdate = new Phaser.Signal();
  var onComplete = new Phaser.Signal();

  return {
    animate: animate,
    onStart: onStart,
    onUpdate: onUpdate,
    onComplete: onComplete,
  };

  function animate(game, target) {
    var valueObj = target.valueObj;
    if (!valueObj) {
      return onComplete.dispatch();
    };

    var valueFrom = valueObj.value;
    var valueTo = valueObj.valueTarget;

    var tween = game.add.tween(target.valueObj).to({
      value: valueTo,
    }, 1000, Phaser.Easing.Sinusoidal.Out, false, 100);

    tween.onStart.add(onStart.dispatch, onStart);
    tween.onComplete.add(function() {
      onAnimationProgress(null, 1);
      onComplete.dispatch();
    }, onComplete);
    tween.onUpdateCallback(onAnimationProgress);

    tween.start();

    function onAnimationProgress(tween, progress) {
      onUpdate.dispatch(progress);
    };
  }

};