import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../../States/Scaling/GameScaleController';
import GingyCharacterTutorial from '../../../GingyTutorial/G.GingyCharacterTutorial';
import TutorialMsgText from '../../../GingyTutorial/G.TutorialMsgText';

const CONTENT_OFFSET_Y = -55;

const MASCOT_CENTER_OFFSET_X = -150;

const TEXT_BG_BOX_HEIGHT = 200;
const TEXT_STYLE = { style: 'ui-tournament-promo', fontSize: 45 }; // this can be override by child classes
const TEXT_FIELD_PADDING = 45;

/**
 * Generic class for displaying horizontal tutorial bar with mascot.
 */
export class GameModePromo_TutorialTextBase extends Phaser.Group {
  /**
   * constructor
   * @param {string} bgAsset
   * @param {string} textStyle
   */
  constructor(bgAsset, textStyle) {
    super(game);

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._initDisplayElements(bgAsset, textStyle || TEXT_STYLE);

    if (this._isLandscape) {
      this.scale.setTo(this._gameScale);
    }
  }

  /**
   * initialize display elements
   * @param {string} bgAsset
   * @param {string} textStyle
   */
  _initDisplayElements(bgAsset, textStyle) {
    this._initTextBgBox(bgAsset);
    this._initTutorialMascot();
    this._initTutorialMsgText(textStyle);
  }

  /**
   * create the background box for the text
   */
  _initTextBgBox(bgAsset) {
    this._textBgBox = new G.Image(0, 0, bgAsset, 0.5, this);
    this._textBgBox.width = game.width;
    this._textBgBox.height = TEXT_BG_BOX_HEIGHT;
    this._textBgBox.y = CONTENT_OFFSET_Y;
  }

  /**
   * init the tutorial mascot
   */
  _initTutorialMascot() {
    this._gingy = new GingyCharacterTutorial(0, 0);
    this.addChild(this._gingy);
    this._gingy.x = MASCOT_CENTER_OFFSET_X;
    this._gingy.y = this._textBgBox.y - this._gingy.height / 2 + G.OMTsettings.tournaments.promotionPosOffset.gingy.y;
  }

  /**
   * create tutorial msg instance for animated text effect.
   */
  _initTutorialMsgText(textStyle) {
    const textField = new G.Text(
      0, this._textBgBox.y, '', textStyle, [0.5, 0.5], game.width - (TEXT_FIELD_PADDING * 2), TEXT_BG_BOX_HEIGHT, true, 'center',
    );
    this._tutorialMsgText = new TutorialMsgText();
    this._tutorialMsgText.setCustomTextObj(textField);
    this.addChild(this._tutorialMsgText);
  }

  /**
   * display text lines in sequence using TutorialMsgText.displayCustomText
   * @param {Array.<string>} textLines
   * @param {Function} callback (optional)
   */
  displayTextLines(textLines, callback = null) {
    textLines = textLines.slice();
    if (textLines.length > 0) {
      this._gingy.talk();
      this._tutorialMsgText.displayText(textLines.shift(), true);
      this._tutorialMsgText.onTextAnimationFinished.addOnce(() => {
        this.displayTextLines(textLines, callback);
      });
    } else {
      this._gingy.idle();
      if (callback) callback();
    }
  }

  /**
   * resize funciton
   */
  resize() {
    // resize text bg
    this._textBgBox.width = this._isLandscape ? game.width / this._gameScale : game.width;
    // resize text field
    const { textObj } = this._tutorialMsgText;
    if (textObj) {
      G.Text.setMaxWidth(textObj, game.width - (TEXT_FIELD_PADDING * 2));
      textObj.applyMaxSizeConstraint();
    }
  }
}
