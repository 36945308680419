import OMT_UI_FloatingItem from '../OMT_UI/OMT_FloatingItem';
import {
  Window_ItemShop,
  Window_ItemShop_ITEM_DETAILS,
} from './Windows/Window_ItemShop';

export class UI_StartBoosterButton extends Phaser.Group {
  /**
   * @param {object} parentScope The parent this should be added to
   * @param {number} x The x position.
   * @param {number} y The y position.
   * @param {number} boosterNr The booster that this will control.
   * @param {number} lvlNr The current level number.
   * @param {number} windowType The type of window we're in. (Can be none, daily challenge, or tournament)
   * @param {object} levelInfo The level info to pass on when starting the level.
   * @param {boolean} disabled Whether this button should be disabled.
   * @param {boolean} allowMultiple Whether the player should be allowed to activate more than one of this booster.
   * @param {string} textThemeColour overrides text fill colour
   */
  constructor(
    parentScope,
    x,
    y,
    boosterNr,
    lvlNr,
    windowType,
    levelInfo,
    disabled = false,
    allowMultiple = false,
    textThemeColour,
  ) {
    super(game);

    // checking for +3/+5 booster and picking depending on G.IAP
    if (boosterNr === 5 || boosterNr === 6) {
      boosterNr = G.IAP ? 6 : 5;
    }

    this.allowMultiple = allowMultiple;
    this._textThemeColour = textThemeColour || null;

    this.state = game.state.getCurrentState();

    const isTournamentWindow = windowType === 'tournament';
    this.unlocked = G.saveState.isBoosterUnlocked(boosterNr) || isTournamentWindow;
    this.basicBoosterTexture = G.gift.getIconForType(`booster#${boosterNr}`);

    this.parentScope = parentScope;

    this.x = Math.floor(x);
    this.y = Math.floor(y);

    this.boosterNr = boosterNr;
    this.boosterId = `booster#${boosterNr.toString()}`;
    this.lvlIndex = lvlNr;
    this.windowType = windowType;
    this.returnLevelInfo = levelInfo; // type is {layerName:string, args:Array<any>}
    this.disabled = disabled;
    this.onSelected = null;

    if (!Array.isArray(this.returnLevelInfo.args)) {
      this.returnLevelInfo.args = [];
    }

    if (this.unlocked) {
      this.initUnlocked(boosterNr, lvlNr);

      if (
        G.saveState.data.startBoosterAnim[boosterNr - 5]
        && !isTournamentWindow
      ) {
        G.saveState.data.startBoosterAnim[boosterNr - 5] = false;
        G.saveState.save();
        this.initUnlockAnimation();
      }
    } else {
      this.img = G.makeImage(
        0,
        0,
        `${this.basicBoosterTexture}_locked`,
        0.5,
        this,
      );
    }

    this.refreshBoosterVisuals();

    // Add signal bindings array to detach them on destroy
    this._signalBindings = [];

    // Various signals
    this._signalBindings.push(
      G.sb('refreshBoosterAmount').add((boosterNum) => {
        if (boosterNum !== this.boosterNr) return;
        this.refreshBoosterVisuals();
      }),
    );
  }

  /**
   * Updates the bg light as well as any children we have.
   */
  update() {
    if (this.bgLight) {
      this.bgLight.angle++;
    }

    for (let i = this.children.length; i--; i >= 0) {
      this.children[i].update();
    }
  }

  /**
   * When this booster is destroyed
   */
  destroy() {
    if (this._signalBindings) {
      this._signalBindings.forEach((binding) => {
        if (binding.detach) {
          binding.detach();
        }
      });
      this._signalBindings = null;
    }
    super.destroy();
  }

  /**
   * When this booster button is selected.
   */
  select(changeConfig = true) {
    if (this.onSelected) this.onSelected(this);
    if (this.selected) return;
    if (!this.disabled) {
      if (changeConfig) {
        this.startBoosterConfig.increment(this.levelNr, this.boosterNr);
      }
      this.selected = true;
      this.amount--;
      this.amountTxt.setText(this.amount.toString());
      this.amountTxt.visible = false;
      this.amountCheck.visible = true;
      this.setSelectedTexture(true);
      this.refreshBoosterVisuals();
    }
  }

  /**
   * When this booster button is deselected.
   */
  deselect(changeConfig = true) {
    if (!this.disabled) {
      if (changeConfig) {
        this.startBoosterConfig.decrement(this.levelNr, this.boosterNr);
      }
      this.selected = false;
      this.amount++;
      this.amountTxt.setText(this.amount.toString());
      this.amountTxt.visible = true;
      this.amountCheck.visible = false;
      this.setSelectedTexture(false);
      this.refreshBoosterVisuals();
    }
  }

  /**
   * Increments the amount of this booster we'll be using.
   */
  increment(changeConfig = true) {
    if (this.disabled) return;
    if (changeConfig) {
      this.startBoosterConfig.increment(this.levelNr, this.boosterNr);
    }
    this.amountUsed++;
    this.amount--;
    this.amountTxt.setText(this.amount.toString());
    this.amountUsedTxt.setText(this.amountUsed.toString());
    if (this.amountUsed > 0) {
      this.amountTxt.visible = false;
      this.amountCheck.visible = true;
    }
    this.refreshBoosterVisuals();
  }

  /**
   * Decrements the amount of this booster we'll be using.
   */
  decrement(changeConfig = true) {
    if (this.disabled) return;
    if (changeConfig) {
      this.startBoosterConfig.decrement(this.levelNr, this.boosterNr);
    }
    this.amountUsed--;
    this.amount++;
    this.amountTxt.setText(this.amount.toString());
    this.amountUsedTxt.setText(this.amountUsed.toString());
    if (this.amountUsed <= 0) {
      this.amountTxt.visible = true;
      this.amountCheck.visible = false;
    }
    this.refreshBoosterVisuals();
  }

  /**
   * This runs every time the player clicks the plus or minus buttons.  Checks if they can still click either button / changes visuals accordingly
   */
  refreshMultipleSelector() {
    if (!this.allowMultiple) return;

    // resetting state so we can recalculate
    if (this.amount <= 1 && this.amountUsed === 0) {
      this.multipleSelector.alpha = 0;
      this.plusBtn.ignoreChildInput = true;
      this.minusBtn.ignoreChildInput = true;
    } else {
      this.btn.inputEnabled = false;
      this.multipleSelector.alpha = 1;
      this.priceTxt.visible = false;
      this.plusBtn.ignoreChildInput = false;
      this.minusBtn.ignoreChildInput = false;
    }

    this.plusBG.tint = 0x0ee000;
    this.minusBG.tint = 0xff3126;
    this.plusBtn.alpha = 1;
    this.minusBtn.alpha = 1;

    // setting state based on amount used and amount selected
    if (this.amountUsed === 0) {
      this.setSelectedTexture(false);
      this.minusBtn.ignoreChildInput = true;
      this.minusBG.tint = 0x000000;
      this.minusBtn.alpha = 0.5;
    } else {
      this.setSelectedTexture(true);
    }

    if (this.amount === 0 || this.amountUsed === 5) {
      this.plusBtn.ignoreChildInput = true;
      this.plusBG.tint = 0x000000;
      this.plusBtn.alpha = 0.5;
    }

    this.amountUsedTxt.setText(this.amountUsed.toString());
  }

  /**
   * Changing texture based on whether we're currently selected.
   */
  setSelectedTexture(active) {
    if (
      this.boosterNr === 5
      || this.boosterNr === 6
      || this.boosterNr === 7
      || this.boosterNr === 8
    ) {
      this.btn.changeTexture(
        `${this.basicBoosterTexture}${active ? '_active' : ''}`,
      );
    }
  }

  /**
   * If we're unlocked, initialize whatever we need to be selected/deselected
   * @param {int} boosterNr Booster number
   * @param {int} lvlIndex Index of the current level.
   */
  initUnlocked(boosterNr, lvlIndex) {
    this.startBoosterConfig = game.state.getCurrentState().startBoosterConfig;

    this.boosterNr = boosterNr;
    this.levelNr = lvlIndex;

    this.bgLight = G.makeImage(0, 0, 'popup_lighht', 0.5, this);
    this.bgLight.scale.setTo(0.6);
    this.bgLight.blendMode = 1;
    this.bgLight.angle = Math.random() * 360;
    this.bgLight.alpha = 0;

    this.btn = new G.Button(0, 0, this.basicBoosterTexture, () => {
      this.handleClick(lvlIndex);
    });
    this.add(this.btn);

    this.selected = false;

    this.amountBg = G.makeImage(-40, -40, 'booster_ammount', 0.5, this);

    this.amount = G.saveState.getBoosterAmount(boosterNr);

    // Recolour amount text style if this._textThemeColour is given
    // TODO: Should probably refactor to use two different predefined text styles in the future
    let amountTxtStyle;
    if (typeof G.OMTsettings.elements.UI_StartBoosterButton.amountTxt.style === 'object') {
      amountTxtStyle = {
        ...G.OMTsettings.elements.UI_StartBoosterButton.amountTxt.style,
      };
    } else {
      amountTxtStyle = {
        style: G.OMTsettings.elements.UI_StartBoosterButton.amountTxt.style,
      };
    }

    if (this._textThemeColour) {
      amountTxtStyle.fill = this._textThemeColour;
    }

    this.amountTxt = new G.Text(
      -40,
      -37,
      this.amount.toString(),
      amountTxtStyle,
      0.5,
      100,
    );
    this.add(this.amountTxt);

    this.amountCheck = G.makeImage(-37, -42, 'checkmark', 0.5, this);
    this.amountCheck.scale.setTo(0.45);
    this.amountCheck.visible = false;
    this.add(this.amountTxt);

    const priceTxtStyle = {
      style: 'font-num-blue',
      fontSize: 30,
      scaleStroke: true,
      ...(this._textThemeColour && { stroke: this._textThemeColour }),
    };

    const localeNumber = OMT.language.toLocaleNumber(
      G.json.settings[`priceOfBooster${this.boosterNr}`],
    );
    this.priceTxt = new G.LabelTextT(
      `${localeNumber} @coin_1@`,
      10,
      40,
      priceTxtStyle,
      0.5,
      100,
    );
    this.add(this.priceTxt);

    // The plus sign for booster selling panel
    this._plusIcon = new Phaser.Group(game, this);
    this._plusIconBG = new G.Image(0, 0, 'btn_plus', 0.5, this._plusIcon);
    this._plusIcon.scale.setTo(0.5);
    this._plusIcon.position.setTo(40, 33);

    this.amountUsed = 0;
    this.tutorialAmountUsed = 0;
    if (this.allowMultiple) {
      this.setupMultipleSelection();
    }
  }

  /**
   * If multiple selection is enabled, sets up the UI for it.
   */
  setupMultipleSelection() {
    this.selected = false;

    this.amountUsed = 0;

    const uiScale = 0.35;

    this.multipleSelector = new Phaser.Group(game, this);

    this.plusBtn = new Phaser.Group(game, this.multipleSelector);
    this.plusBtn.scale.set(uiScale);

    this.plusBG = new G.Image(0, 0, 'booster_icon_bg', 0.5, this.plusBtn);

    const plusIcon = new G.Image(0, 0, 'booster_plus', 0.5, this.plusBtn);
    plusIcon.x += plusIcon.width / 2;

    const plusHitArea = new G.Button(0, 0, 'booster_icon_bg', () => {
      if (this.plusAnim) {
        this.plusAnim.stop();
        this.plusBtn.scale.set(uiScale);
      }
      this.plusAnim = game.add
        .tween(this.plusBtn.scale)
        .to(
          { x: uiScale * 1.2, y: uiScale * 1.2 },
          110,
          Phaser.Easing.Linear.None,
          true,
          0,
          0,
          1,
        );
      this.increment();
      G.sfx.pop.play();
      G.sb('startBoosterMultipleSelectorClicked').dispatch(plusHitArea);
    });
    plusHitArea.tutorialAction = () => {
      this.tutorialAmountUsed++;
      this.amountUsedTxt.setText(this.tutorialAmountUsed.toString());
    };
    plusHitArea.tutorialPoint = () => {
      const { worldPosition, width } = plusHitArea;
      const { x, y } = worldPosition;
      const absWidth = Math.abs(width);

      return new Phaser.Point(x + absWidth * 0.075, y);
    };
    plusHitArea.alpha = 0;
    plusHitArea.width = this.plusBtn.getBounds().width;
    plusHitArea.height = this.plusBtn.getBounds().height;
    this.plusHitArea = plusHitArea;
    this.plusBtn.add(plusHitArea);

    this.minusBtn = new Phaser.Group(game, this.multipleSelector);
    this.minusBtn.scale.set(uiScale);

    this.minusBG = new G.Image(0, 0, 'booster_icon_bg', 0.5, this.minusBtn);
    this.minusBG.scale.x *= -1;

    const minusIcon = new G.Image(0, 0, 'booster_minus', 0.5, this.minusBtn);
    minusIcon.x -= minusIcon.width / 2;

    const minusHitArea = new G.Button(0, 0, 'booster_icon_bg', () => {
      if (this.minusAnim) {
        this.minusAnim.stop();
        this.minusBtn.scale.set(uiScale);
      }
      this.minusAnim = game.add
        .tween(this.minusBtn.scale)
        .to(
          { x: uiScale * 1.2, y: uiScale * 1.2 },
          110,
          Phaser.Easing.Linear.None,
          true,
          0,
          0,
          1,
        );
      this.decrement();
      G.sfx.pop.play();
      G.sb('startBoosterMultipleSelectorClicked').dispatch(minusHitArea);
    });
    minusHitArea.tutorialAction = () => {
      this.tutorialAmountUsed--;
      this.amountUsedTxt.setText(this.tutorialAmountUsed.toString());
    };
    minusHitArea.tutorialPoint = () => {
      const { worldPosition } = minusHitArea;
      const { x, y } = worldPosition;

      return new Phaser.Point(x, y);
    };
    minusHitArea.alpha = 0;
    minusHitArea.width = this.minusBtn.getBounds().width;
    minusHitArea.height = this.minusBtn.getBounds().height;
    this.minusHitArea = minusHitArea;
    this.minusBtn.add(minusHitArea);

    this.amountUsedBox = new Phaser.Group(game, this.multipleSelector);
    this.amountUsedBg = new G.Image(
      0,
      0,
      'booster_counter',
      0.5,
      this.amountUsedBox,
    );
    this.amountUsedBg.scale.set(uiScale);
    this.amountUsedTxt = new G.Text(
      0,
      0,
      this.amountUsed.toString(),
      G.OMTsettings.elements.UI_StartBoosterButton.amountTxt.style,
      0.5,
      100,
      0,
      false,
      'center',
    );
    this.amountUsedTxt.x += 1;
    this.amountUsedTxt.y += 2;
    this.amountUsedBox.add(this.amountUsedTxt);

    this.plusBtn.x += this.plusBtn.width / 2;
    this.minusBtn.x -= this.minusBtn.width / 2;

    this.multipleSelector.y = this.btn.height / 2 + 10;
  }

  /**
   * Set plus and minus button click speed
   * @param {boolean} immediate
   */
  setMultipleSelectionImmediate(immediate) {
    if (!this.allowMultiple) return;
    const { plusHitArea, minusHitArea } = this;

    plusHitArea.IMMEDIATE = immediate;
    minusHitArea.IMMEDIATE = immediate;
  }

  /**
   * Initialize the animation for when this booster was just unlocked
   */
  initUnlockAnimation() {
    this.ignoreChildInput = true;
    this.amountTxt.alpha = 0;
    this.amountBg.alpha = 0;

    const delay = 500;
    // this.amountTxt.setText(0);

    const circle = G.makeImage(0, 0, 'circle', 0.5, this);
    const orgW = circle.width;
    const orgH = circle.height;
    circle.scale.setTo(0);
    circle.blendMode = 1;
    game.add.tween(circle).to(
      {
        width: orgW * 2,
        height: orgH * 2,
        alpha: 0,
      },
      600,
      Phaser.Easing.Cubic.Out,
      true,
      delay,
    );

    game.time.events.add(
      delay,
      () => {
        G.sfx.match_1.play();

        for (let i = 0; i < 5; i++) {
          const start = G.makeImage(0, 0, 'starPart', 0.5, this);
          start.angle = Math.random() * 360;
          start.velX = Math.random(20) * -20 + 10;
          start.velY = Math.random() * -9 - 3;
          start.gravity = 0.5;
          start.update = () => {
            start.x += start.velX * G.deltaTime;
            start.y += start.velY * G.deltaTime;
            start.angle += start.velX * 0.1;
            start.velX *= 0.99;
            start.velY += start.gravity * G.deltaTime;
            start.alpha -= 0.02;
            if (start.alpha <= 0) start.kill();
          };
        }

        game.add
          .tween(this.amountTxt)
          .to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out, true);
        game.add
          .tween(this.amountBg)
          .to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out, true);
        this.ignoreChildInput = false;

        // this.amountTxt.setText(G.json.settings.boostersOnStart);
      },
      this,
    );
    this.lock = G.makeImage(
      0,
      0,
      `${this.basicBoosterTexture}_locked`,
      0.5,
      this,
    );
    game.add
      .tween(this.lock)
      .to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true, delay);
  }

  /**
   * Refresh amount text according to save state
   */
  refreshBoosterVisuals() {
    if (!this.unlocked) { return; }
    this.alpha = 1;
    this.btn.inputEnabled = true;
    this.priceTxt.visible = false;
    this.bgLight.alpha = 0;
    this.amountTxt.visible = true;
    this.amountBg.visible = true;
    this._plusIcon.visible = false;
    this.amount = G.saveState.getBoosterAmount(this.boosterNr) - this.amountUsed;

    if (
      this.amount === 0
      && !game.incentivised()
      && !G.IAP
      && G.saveState.getCoins() < G.json.settings[`priceOfBooster${this.boosterNr}`]
    ) {
      this.alpha = 0.5;
      this.btn.inputEnabled = false;
    } else if (this.disabled) {
      this.amountTxt.visible = false;
      this.amountBg.visible = false;
      this.priceTxt.visible = false;
      this.alpha = 0.5;
    } else if (this.amount === 0 && this.amountUsed === 0 && !this.selected) {
      if (OMT.feature.isMultipleBoosterBuyingPanelEnabled()) {
        this.priceTxt.visible = false;
        this._plusIcon.visible = true;
      } else {
        this.priceTxt.visible = true;
        this._plusIcon.visible = false;
      }
    } else if (this.amount > 0) {
      this.priceTxt.visible = false;
    }

    if (this.selected || this.amountUsed > 0) {
      this.bgLight.alpha = 0.5;
      this.amountTxt.visible = false;
      this.amountBg.visible = false;
    } else {
      this.bgLight.visible = false;
    }

    this.amountTxt.setText(this.amount.toString());
    this.refreshMultipleSelector();
  }

  /**
   * Handle the click on the booster button
   * @param {number} lvlIndex
   */
  handleClick(lvlIndex) {
    if (this.selected) {
      this.deselect();
    } else {
      // we go thru with the selection when disabled because we want to hook into the callback
      // to display a message letting the player know this booster is disabled.
      // eslint-disable-next-line no-lonely-if
      if (this.amount > 0 || this.disabled) {
        this.select();
        G.sb('onStartBoosterSelectBounce').dispatch();
      } else if (!this.disabled) {
        if (OMT.feature.isMultipleBoosterBuyingPanelEnabled()) {
          this.setupAndDispatchItemShop();
        } else if (G.saveState.isEnoughToBuyBooster(this.boosterNr)) {
          this.handleBuyOneBooster(lvlIndex);
          this.select();
          G.sb('onStartBoosterSelectBounce').dispatch();
        } else {
          this.handleNotEnoughCoins();
        }
      }
    }
  }

  /**
   * Open the shop to let the user buy coins to buy a booster later on
   */
  handleNotEnoughCoins() {
    // If you don't have enough mmoney, show the shop
    if (
      G.Helpers.pushOutOfCoinsPopUp(
        false,
        { event: OMT.platformTracking.Events.OpenShopLvlStart },
        false,
        G.WindowMgr.LayerNames.OverlayLayer,
      )
    ) {
      const pushLevelPopUp = async (windowType) => {
        if (windowType === 'dailyChallenge') {
          G.sb('pushWindow').dispatch(
            ['dailyChallenge'].concat(this.returnLevelInfo.args),
            false,
            this.returnLevelInfo.layerName,
          );
        } else if (windowType === 'tournament') {
          const score = await OMT.platformTournaments.getTournamentHighScore();
          G.sb('pushWindow').dispatch(
            ['tournament', score],
            false,
            this.returnLevelInfo.layerName,
          );
        } else {
          G.sb('pushWindow').dispatch(
            [windowType].concat(this.returnLevelInfo.args),
            false,
            this.returnLevelInfo.layerName,
          );
        }
      };
      pushLevelPopUp(this.windowType);
      this.parentScope.closeWindow();
      G.sb('levelWindowClose').dispatch();
      // if we are disabled
    }
  }

  /**
   * Handles buying one booster with a click
   * @param {number} lvlIndex
   */
  handleBuyOneBooster(lvlIndex) {
    const coinCost = G.json.settings[`priceOfBooster${this.boosterNr}`];
    // DDNA.transactionHelper.trackBoosterInventoryPurchase(
    //   this.boosterNr,
    //   1,
    //   coinCost,
    //   lvlIndex + 1,
    // );

    OMT.transactionTracking.logInventoryTransactionBegin();
    OMT.transactionTracking.addInventoryChange(
      'boostersReceived',
      this.boosterNr,
      1,
    );
    OMT.transactionTracking.addInventoryChange('coins', 0, -coinCost);
    OMT.transactionTracking.logInventoryTransactionEnd();

    G.saveState.buyBooster(this.boosterNr, lvlIndex);
  }

  /**
   * Configure and launch an item shop that sells this booster
   */
  setupAndDispatchItemShop() {
    const { boosterNr } = this;
    const multiBoosterBuy = G.featureUnlock.multipleBoosterBuyingPanel.multiAndOne;
    const boosterShopPanelConfig = Window_ItemShop.getDefaultConfigObject();
    const boosterDetails = Window_ItemShop_ITEM_DETAILS.BOOSTERS[boosterNr];
    const { keyBase } = Window_ItemShop_ITEM_DETAILS.BOOSTERS;
    const { name, description } = boosterDetails;
    boosterShopPanelConfig.title = OMT.language.getText(name);
    const texture = keyBase + boosterNr;
    const boosterQuantity = 3;
    boosterShopPanelConfig.item.image.texture = texture;
    boosterShopPanelConfig.item.quantity.text = `x${boosterQuantity}`;
    boosterShopPanelConfig.item.description.text = OMT.language.getText(description);
    boosterShopPanelConfig.item.image.scale = multiBoosterBuy ? 1.45 : boosterShopPanelConfig.item.image.scale;
    const singlePrice = G.json.settings[`priceOfBooster${boosterNr}`];
    const discount = G.json.settings.multiBoosterDiscount;
    const multiPrice = singlePrice * boosterQuantity;
    boosterShopPanelConfig.item.price = {
      single: singlePrice,
      multi: multiPrice - (multiPrice * discount),
    };

    const onBuyComplete = (price, quantity) => {
      if ((quantity === 1 && G.saveState.isEnoughToBuyBooster(boosterNr))
      || (quantity === 3 && G.saveState.isEnoughToBuyThreeBoosters(boosterNr, discount))) {
        G.saveState.trackMultipleBoosterBought({
          boosterNum: boosterNr,
          quantity,
          coinCost: price,
          lvlIndex: G.lvlData.levelIndex,
        });
        G.saveState.refreshAllBoosterAmounts();

        let delay = 500;

        const currentXPos = this.worldPosition.x / game.width;
        const currentYPos = this.worldPosition.y / game.height;
        let xPos = currentXPos - 0.2;
        let yPos;
        for (let i = 0; i < quantity; i++) {
          xPos += 0.1;
          if (i === 1) {
            yPos -= 0.05;
          } else {
            yPos = currentYPos - 0.1;
          }
          const floatingItem = new OMT_UI_FloatingItem(
            { x: xPos, y: yPos },
            texture,
            this,
            () => {
              G.saveState.changeBoosterAmount(boosterNr, 1);
            },
          );
          floatingItem.goToTarget(delay);
          delay += 200;
          game.world.add(floatingItem);
        }
      } else {
        this.handleNotEnoughCoins();
      }
    };
    boosterShopPanelConfig.details.onBuyComplete = onBuyComplete.bind(this);
    boosterShopPanelConfig.extraOverlay = true;

    G.sb('pushWindow').dispatch(
      ['itemShop', boosterShopPanelConfig],
      false,
      G.WindowMgr.LayerNames.OverlayLayer,
      true,
    );
  }
}

G.UI_StartBoosterButton = UI_StartBoosterButton;
