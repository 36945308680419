import ClotheslineMenu from '../../../OMT_UI/Menus/ClotheslineMenu';
import FriendshipChest_Polaroid from './FriendshipChest_Polaroid';
import OMT_UI_DrawUtil from '../../../OMT_UI/Drawing/OMT_UI_DrawUtil';
import OMT_UI_SquareButton, { BUTTONCOLOURS, BUTTONSTATE } from '../../../OMT_UI/OMT_UI_SquareButton';
import { SOCIAL_STATUS } from '../../../Services/OMT/OMT_Social';
import { OMT_UI_AvatarWithFrame } from '../../../OMT_UI/FriendsList/OMT_UI_AvatarWithFrame';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';

export const FRIENDSHIPCHEST_BUTTONMODE = { // The state of the button
  CLAIM: 'CLAIM',
  INVITE: 'INVITE',
};

export const FRIENDSHIPCHESTCARD_STATUS = {
  OPEN: 0, //  Invite friend button
  PENDING: 1, //  Friend invitation is sent
  FRIEND: 2, //  Showing the friend with the shine and the stars
  UNCLAIMED: 3, //  Friend has joined! or Claim button
  CLAIMED: 4, //  Claimed checkmark card
  END: 5, //  [?] card. For very end cards
  PROMO: 6, //  For details about the chest and telling you how it works
  FTUX: 7, //  For the First Time User Experience
};

export default class Friendship_Chestv2 {
  /**
   * The new Friendship chest v2 with mostly copied over code and new stuff.
   * Features full screen effects!
   * @param {Object} config
   * @param {Window} config.window
   * @param {Phaser.Group} config.container
   * @param {boolean} tutorialMode
   */
  constructor(config) {
    this.parentWindow = config.window; // The window parent
    this.displayContainer = config.container; // The group where most of the display
    this.tutorialMode = config.tutorialMode; // There is potential for there to be a tutorial

    this.signals = {
      onCloseClicked: new Phaser.Signal(), // Signal to the window to close everything
      onReady: new Phaser.Signal(), // When the module is ready
      onTutorialProgress: new Phaser.Signal(), // Signal to progress the tutorial, if there is one.
    };

    this._gradientData = { // Gradient data for the title
      upperGradient: ['#4AEAFF', '#24CCF9'],
      titleGradient: ['#FFFFFF', '#FDEFCC'],
    };
    this._UIAlpha_tween = undefined; // The tween that fades UI
    this._extraUITweens = []; // The tween for closeButton + hand
    this._prizeSection = undefined; // The section where the prize is at
    this._claimAsset = undefined; // The claimed (checkmark) bottomside
    this._sentAsset = undefined; // The invitation is pending
    this._friendAsset = undefined; // The friend container that is made when a friend joins
    this._errorAsset = undefined; // The screen when you try to invite a friend who plays
    this._prizeTable = undefined; // collected in assembleData;
    this._polaroidsAreMoving = false; // Polaroid moving flag
    this._readyFlags = { // Ready flags for init
      reg: false,
      async: false,
    };
    this._perpetualHand = undefined; // The perpetual pointing hand
    this._gameScale = GameScaleController.getInstance().gameScale;
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;

    this._init();
  }

  /**
   * Async init because the assembling data part could take a long time
   */
  async _init() {
    G.saveState.friendshipChestDataManager.incrementOpened(); // Increment the number of times this has opened. Only happens once per chest window
    let waitingIcon = new G.WaitingIcon(0, 0, 'waiting_icon'); // Loading...
    this.displayContainer.addChild(waitingIcon);
    let initTimer = game.time.events.add(2000, () => { // Timeout close button option
      if (waitingIcon.parent) { // Still there
        game.add.tween(this._closeBtn) // Bring in the close button
          .to({ alpha: 1 }, 200, Phaser.Easing.Sinusoidal.InOut);
      }
    });
    const initData = await this.assembleData(); // This will take a loooooong time!
    if (initTimer) { // If data is assembled before timer fired, remove it
      game.time.events.remove(initTimer);
      initTimer = null;
    }
    if (waitingIcon.parent) { // Remove the icon
      waitingIcon.parent.removeChild(waitingIcon);
      waitingIcon.destroy();
      waitingIcon = null;
    }

    if (!this.parentWindow.game) { return; } // If the game is still here...

    const rewardsAvailable = initData.unclaimed || false; // Are there rewards?
    const startingIndex = rewardsAvailable ? initData.unclaimed.i : initData.count - 1; // Starting card index

    this._drawInitialHeader();

    // Creates polaroid images and attaches it on the clothesline menu
    this._allPolaroids = this._makeImages(initData.count);
    this._clothesline = new ClotheslineMenu({
      game,
      parent: this.displayContainer,
      images: this._allPolaroids,
      startingIndex,
      onReadyCallback: (curPol, visArr) => { // When clothesline is ready
        this._adjustPolaroid(visArr); // reset the polaroids
        this._readyFlags.async = true;
        this._postSyncInit();
      },
      distance: () => (!this._isLandscape ? game.width / 2 : game.width / 3),
    });

    if (this._isLandscape) {
      this._clothesline.scale.setTo(this._gameScale);
    }

    this._clothesline.signals.onMove.add(this._onPolaroidsMove.bind(this));
    this._clothesline.signals.onMoveFinished.add(this._onPolaroidsDoneMoving.bind(this));

    // Rest of init will happen when clothesline is ready
    this._readyFlags.reg = true;
    this._postSyncInit();

    // if (!this.tutorialMode) DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenFriendshipChestShareBtn', 1);
  }

  /**
   * Part 2 of init. Needed because things after require clotheslineMenu to be initialized
   */
  _postSyncInit() {
    if (!(this._readyFlags.async && this._readyFlags.reg)) { return; }

    // Close button
    this._closeBtn = new G.Button(0, 0, 'white_x', () => { this.signals.onCloseClicked.dispatch(); });
    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      this._closeBtn.scale.setTo(this._gameScale);
    }
    this.parentWindow.addChild(this._closeBtn);

    // Button that clicks
    const scaleFactor = this._isLandscape ? this._gameScale : 1;

    this._button = new OMT_UI_SquareButton(0, 0, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 445 * scaleFactor,
          height: 100 * scaleFactor,
        },
      },
      text: {
        string: OMT.language.getText('Invite friends!'),
        textStyle: { style: 'font-white', fontSize: 64 * scaleFactor },
      },
      options: {
        clickFunction: {
          onClick: this._onButtonClick.bind(this),
          disableAfterClick: true,
        },
        cacheButton: false,
      },
    });
    this._drawClaimedAsset(); // Draw the claim checkmark (most likely needed)
    this.drawInvitationSent(); // Draw the pending asset (most likely needed)
    this._readyPerpetualHand(); // Get the hand ready

    // Resize
    this._resizeToken = G.sb('onScreenResize').add(this._onResize.bind(this));
    this._generatePrizeSection();

    // Check the current index
    const cardData = this._getCurrentPolaroidStatus();
    if (cardData === FRIENDSHIPCHESTCARD_STATUS.FRIEND) { // If its a friend, do something else
      this._onResize(); // Positioning
      this._readyFriend(() => { // Ready friend
        this._animateInFriend(); // Animate
      });
    } else {
      this.refreshPolaroid(cardData); // Ready the card view
    }

    this.signals.onReady.dispatch(); // Ready!
  }

  /**
   * Destroy!!
   */
  destroy() {
    if (this._resizeToken) {
      if (this._resizeToken.detach) {
        this._resizeToken.detach();
      }
      this._resizeToken = null;
    }
    if (this._prizeSection) {
      this._prizeSection.destroy();
    }
    if (this._errorAsset) {
      if (this._errorAsset.fadeOut) {
        this._errorAsset.fadeOut.stop();
      }
      this._errorAsset.destroy();
    }
    if (this._claimAsset) {
      this._claimAsset.destroy();
    }
    if (this._sentAsset) {
      this._sentAsset.destroy();
    }
    if (this._friendAsset) {
      this._friendAsset.destroy();
    }
    Object.keys(this.signals).forEach((key) => {
      this.signals[key].dispose();
    });
    if (this._UIAlpha_tween) {
      this._UIAlpha_tween.stop();
      this._UIAlpha_tween = null;
    }
    this.signals = {};
    this._clothesline.destroy();
  }

  /** Tutorial Stuff */

  /**
   * Gets the current polaroid
   * @returns {FriendshipChest_Polaroid}
   */
  get currentPolaroid() {
    return this._clothesline.currentPolaroid;
  }

  /**
   * Returns the button. Mostly for external use
   * @@returns {OMT_UI_SquareButton}
   */
  get button() {
    return this._button;
  }

  /**
   * Refreshes the current polaroid, visually
   * @param {FRIENDSHIPCHESTCARD_STATUS} status
   */
  refreshPolaroid(status) {
    this._updateButtonAreaByStatus(status);
    this.showUI();
    this._onResize();
  }

  /**
   * Animates the friend in with tutorial data.
   * @param {Object} tutorialOverride
   */
  aniamteTutorialFriendIn(tutorialOverride) {
    this._readyFriend(() => {
      this._animateInFriend();
    }, tutorialOverride);
  }

  /** End of tutorial stuff */
  /** Drawing and stuff */

  /**
   * Assembling data.
   */
  async assembleData() {
    G.saveState.friendshipChestDataManager.sortCards(); // This might take a butt long time!

    this._prizeTable = G.json.settings.friendshipChest_prizes.prizeTable;

    const data = {
      count: G.saveState.friendshipChestDataManager.getCardCount(),
      unclaimed: G.saveState.friendshipChestDataManager.getUnclaimedCard(), // Unclaimed or ready to be claimed cards
    };

    return data;
  }

  _drawInitialHeader() {
    // Draws the header
    this._header = OMT_UI_DrawUtil.drawGradientSlopedHeader({
      upperGradient: this._gradientData.upperGradient,
      titleGradient: this._gradientData.titleGradient,
      titleText: 'Friendship Chest',
      titleStyle: 'friendshipChest-title',
    });

    if (this._isLandscape) {
      this._header.scale.setTo(this._gameScale);
    }
    this.parentWindow.addChild(this._header);

    // The subtitle text
    const maxWidth = this._isLandscape ? (game.width * 0.95) * 1 : game.width * 0.95;
    const maxHeight = this._isLandscape ? (game.height / 8.7) * 1 : game.height / 8.7;

    this._subText = new G.Text(0, 0,
      OMT.language.getText('Invite Friends to %GameName% and earn rewards when they join! Your friend needs to click your message!'),
      'friendshipChest-subTitle', 0.5, maxWidth, maxHeight, true, 'center');
    this.displayContainer.addChild(this._subText);
  }

  /**
   * Makes a single polaroid.
   * @param {number} index
   * @returns {FriendshipChest_Polaroid}
   */
  _makeSinglePolaroid(index) {
    const storyChestData = G.OMTsettings.friendshipChest.assets.storyChests[Math.min(index, G.OMTsettings.friendshipChest.assets.storyChests.length - 1)];
    const img = new FriendshipChest_Polaroid({
      game,
      background: storyChestData.background,
      mainImage: storyChestData.asset,
      index,
    });
    return img;
  }

  /**
   * Creates polaroid images and establishes links
   * @returns {Array<Phaser.Group>}
   */
  _makeImages(amountOfCards) {
    const arr = [];
    for (let i = 0; i < amountOfCards; i++) { // Make the amount of polaroids that the user has... Which can be a lot
      const img = this._makeSinglePolaroid(i);
      arr.push(img);
    }

    // Establish links
    for (let i = 0; i < arr.length; i++) {
      if (i !== 0) {
        arr[i].prevPolaroid = arr[i - 1];
      }

      if (i + 1 < arr.length) {
        arr[i].nextPolaroid = arr[i + 1];
      }
    }

    return arr;
  }

  /**
   * Draws the claim asset
   */
  _drawClaimedAsset() {
    const claimedGroup = new Phaser.Group(G.game, null);
    const claimedText = new G.Text(0, 0, OMT.language.getText('Claimed'), 'friendshipChest-prizeSection', [0, 0.5], game.width * 0.9, 100, true, 'center');
    const checkMark = G.makeImage(10 + claimedText.width, 0, 'task_complete', [0, 0.5], null);
    checkMark.y -= checkMark.height / 8;

    claimedGroup.addChild(claimedText);
    claimedGroup.addChild(checkMark);
    claimedGroup.x -= claimedGroup.width / 2;

    this._claimAsset = claimedGroup;
    this._claimAsset.checkMark = checkMark;
  }

  /**
   * Draws the invitation sent message but doesn't add it
   */
  drawInvitationSent(customAvatar) {
    if (this._sentAsset) {
      this._sentAsset.destroy();
    }
    const set = new Phaser.Group(G.game, null);
    // Waiting for friend
    const waitingText = new G.Text(0, 0, OMT.language.getText('Waiting for friend to join the game'), 'friendshipChest-sentSubText', 0.5, game.width * 0.8, 50, true, 'center');
    const friendInviteSent = new Phaser.Group(G.game, null);
    const friendImage = new Phaser.Group(G.game, friendInviteSent);
    let friendAvatar;
    if (customAvatar) { // Tutorial override avatar? Or a normal one?
      friendAvatar = customAvatar;
      friendImage.addChild(friendAvatar);
    } else {
      friendAvatar = G.makeImage(0, 0, 'avatar_e', 0.5, friendImage);
    }
    const friendFrame = G.makeImage(0, 0, 'avatar_frame', 0.5, friendImage);
    friendAvatar.height = friendAvatar.width = 60;
    friendFrame.height = friendFrame.width = friendAvatar.height * 1.15;
    friendImage.x = -game.width * 0.35;
    friendImage.y = -5;

    // Invitation sent!
    const friendInviteText = new G.Text(0, 0, OMT.language.getText('Friend invitation sent!'), 'font-white', 0.5, game.width * 0.7, 100, true, 'center');
    friendInviteText.x = friendImage.x + friendImage.width / 2 + (game.width * 0.7) / 2;
    friendImage.x = friendInviteText.x - 10 - (friendInviteText.width + friendImage.width) / 2;
    friendInviteSent.addChild(friendImage);
    friendInviteSent.addChild(friendInviteText);

    waitingText.y = friendInviteSent.y - (friendInviteSent.height + waitingText.height) / 2;

    set.addChild(waitingText);
    set.addChild(friendInviteSent);

    // Animation blink time
    const blinkTime = 3000;
    const pulse = game.time.events.loop(blinkTime, () => { // Instance of the event so it can be removed when the card is removed
      if (this._sentAsset.visible) {
        game.add.tween(waitingText)
          .to({ alpha: 0.2 }, blinkTime / 2, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
      }
    }, this);

    set.pulse = pulse;
    if (this._isLandscape) {
      set.scale.setTo(this._gameScale);
    }
    this._sentAsset = set;
  }

  /**
   * Draws the hand that will be pointing to the button, perpetually
   */
  _readyPerpetualHand() {
    if (!this._perpetualHand) {
      this._perpetualHand = new Phaser.Group(game, this.displayContainer);
      if (this._isLandscape) {
        this._perpetualHand.scale.setTo(this._gameScale);
      }

      const hand = G.makeImage(0, 20, 'tut_hand', 0, this._perpetualHand); // Taken from G.DailyIcon
      this._perpetualHand.tween = game.add.tween(hand).to({ x: 20, y: 50 }, 300, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
      this._perpetualHand.lockVisible = !this._closeBtn.visible;
      hand.update = () => {
        if (this._button.parent && this._button.currentState === BUTTONSTATE.ENABLED && this._button.alpha > 0) {
          if (!this._perpetualHand.visible && !this._perpetualHand.lockVisible) {
            this._perpetualHand.visible = true;
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (this._perpetualHand.visible && !this._perpetualHand.lockVisible) {
            this._perpetualHand.visible = false;
          }
        }
      };
    }
  }

  /**
   * Draws the prize section of a card and does its layout.
   * Also used in the claim screen
   * @param {Object || String} amountTextStyle
   * @param {boolean} replacePrize Replaces _prizeSection reference if replacing
   * @returns {{visual: Phaser.Group, data: Array, index: number}}
   */
  _generatePrizeSection(amountTextStyle = 'friendshipChest-prizeSection', replacePrize = true) {
    const prizeIndex = Math.min(this.currentPolaroid.index, this._prizeTable.length - 1);
    const targetPrize = this._prizeTable[prizeIndex];

    let oldPos = { x: 0, y: 0 };
    let oldAlpha = 1;
    if (this._prizeSection && replacePrize) {
      if (targetPrize === this._prizeSection.prizeData) { // Same prize
        return {
          visual: this._prizeSection,
          data: targetPrize, // Prize data
          index: prizeIndex, // The prize index. Used for tracking
        };
      }
      oldPos = this._prizeSection.position.clone();
      oldAlpha = this._prizeSection.alpha;
      this._prizeSection.destroy();
    }
    const givenWidth = game.width;
    const iconDimensions = {
      x: 0,
      y: 0,
      width: 75,
      height: 75,
    };
    const prizeSection = OMT_UI_DrawUtil.drawDynamicPrizes({
      iconDimension: iconDimensions,
      width: givenWidth,
      prizes: targetPrize,
      textStyle: amountTextStyle,
    });
    prizeSection.x = oldPos.x;
    prizeSection.y = oldPos.y;
    prizeSection.alpha = oldAlpha;
    prizeSection.prizeData = targetPrize;
    if (replacePrize) {
      this._prizeSection = prizeSection;
      this.displayContainer.addChild(this._prizeSection);
    }
    return {
      visual: prizeSection,
      data: targetPrize, // Prize data
      index: prizeIndex, // The prize index. Used for tracking
    };
  }

  /** End of drawing and stuff */
  /** Functionality */

  /**
   * Gets the current polaroid status
   * @returns {FRIENDSHIPCHESTCARD_STATUS}
   */
  _getCurrentPolaroidStatus() {
    return G.saveState.friendshipChestDataManager.findCardByIndex(this.currentPolaroid.index, true).s;
  }

  /**
   * Hides the UI
   * @param {number} tweenTime
   */
  hideUI(tweenTime = 200, affectArrows = true) {
    this._extraUITweens.forEach((tw) => {
      tw.stop();
    });
    this._extraUITweens = [];

    if (affectArrows) {
      this._clothesline.toggleLock(true);
    }

    let closeTween;
    if (this._closeBtn && this._closeBtn.visible) {
      closeTween = game.add.tween(this._closeBtn)
        .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.In);
      closeTween.onComplete.addOnce(() => {
        this._closeBtn.visible = false;
      });
      this._extraUITweens.push(closeTween);
    }

    let perpetualHand;
    if (this._perpetualHand && this._perpetualHand.visible) {
      this._perpetualHand.lockVisible = true;
      perpetualHand = game.add.tween(this._perpetualHand)
        .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.In);
      perpetualHand.onComplete.addOnce(() => {
        this._perpetualHand.visible = false;
      });
      this._extraUITweens.push(perpetualHand);
    }

    if (perpetualHand) { perpetualHand.start(); }
    if (closeTween) { closeTween.start(); }
  }

  /**
   * Hides the bottom half of the card
   */
  _hideBottomSide() {
    const obj = { cur: 1, target: 0 };
    this._UIAlpha_tween = game.add.tween(obj)
      .to({ cur: obj.target }, 200, Phaser.Easing.Sinusoidal.InOut, false);
    this._UIAlpha_tween.onUpdateCallback(() => {
      this._prizeSection.alpha = obj.cur;
      this._claimAsset.alpha = obj.cur;
      this._sentAsset.alpha = obj.cur;
      this._button.alpha = obj.cur;
    });
    this._UIAlpha_tween.start();
  }

  /**
   * Shows the UI
   * @param {number} tweenTime
   */
  showUI(tweenTime = 200) {
    this._extraUITweens.forEach((tw) => {
      tw.stop();
    });
    this._extraUITweens = [];

    this._clothesline.toggleLock(false);
    this._clothesline.lockLeft(!this.currentPolaroid.prevPolaroid); // If there is no card, no show
    this._clothesline.lockRight(!this.currentPolaroid.nextPolaroid);

    let closeTween;
    if (!this._closeBtn.visible) {
      this._closeBtn.visible = true;
      closeTween = game.add.tween(this._closeBtn)
        .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.Out);
      closeTween.onComplete.add(() => { this._closeBtn.alpha = 1; });
      this._extraUITweens.push(closeTween);
    }

    let perpetualHand;
    if (this._perpetualHand && !this._perpetualHand.visible) {
      this._perpetualHand.lockVisible = false;
      this._perpetualHand.visible = Boolean(this._button.parent);
      perpetualHand = game.add.tween(this._perpetualHand)
        .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.Out);
      perpetualHand.onComplete.add(() => { this._perpetualHand.alpha = 1; });
      this._extraUITweens.push(perpetualHand);
    }

    if (perpetualHand) { perpetualHand.start(); }
    if (closeTween) { closeTween.start(); }
  }

  /**
   * Shows the bottom side
   */
  _showBottomSide() {
    if (this._UIAlpha_tween && this._UIAlpha_tween.isRunning) {
      this._UIAlpha_tween.stop();
    }
    const obj = { cur: this._prizeSection.alpha, target: 1 };
    this._UIAlpha_tween = game.add.tween(obj)
      .to({ cur: obj.target }, 200, Phaser.Easing.Sinusoidal.InOut, false);
    this._UIAlpha_tween.onUpdateCallback(() => {
      this._prizeSection.alpha = obj.cur;
      this._claimAsset.alpha = obj.cur;
      this._sentAsset.alpha = obj.cur;
      this._button.alpha = obj.cur;
    });
    this._UIAlpha_tween.onComplete.addOnce(() => {
      this._UIAlpha_tween = null;
      this._button.alpha = obj.target;
    });
    this._UIAlpha_tween.start();
  }

  /**
   * Adds a polaroid to the right, assuming that it is the very last
   */
  _addPolaroidToTheRight() {
    const cur = this.currentPolaroid;

    const img = this._makeSinglePolaroid(cur.index + 1);

    cur.nextPolaroid = img;
    img.prevPolaroid = cur;

    this._allPolaroids.push(img); // Sorts it
    this._allPolaroids.sort((a, b) => {
      if (a.index > b.index) return 1;
      if (a.index < b.index) return -1;
      return 0;
    });
    this._clothesline.updatePolaroids(this._allPolaroids, cur.index); // Updates the clothesline

    // Tweens it in
    img.alpha = 0;
    game.add.tween(img)
      .to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.InOut, true);
  }

  /**
   * Shows and hides the bottom area based on card status
   * @param {FRIENDSHIPCHESTCARD_STATUS} status
   */
  _updateButtonAreaByStatus(status) {
    switch (status) {
      case FRIENDSHIPCHESTCARD_STATUS.CLAIMED: // Card is claimed
        // draw Claimed
        this.displayContainer.removeChild(this._sentAsset);
        this.displayContainer.removeChild(this._button);
        this.displayContainer.addChild(this._claimAsset);
        break;
      case FRIENDSHIPCHESTCARD_STATUS.FRIEND: // Don't do anything in friend
        this.displayContainer.removeChild(this._sentAsset);
        this.displayContainer.removeChild(this._button);
        this.displayContainer.removeChild(this._claimAsset);
        // Friendo mode
        break;
      case FRIENDSHIPCHESTCARD_STATUS.FTUX: // Card is FTUX
        this.displayContainer.removeChild(this._sentAsset);
        this.displayContainer.removeChild(this._button);
        this.displayContainer.removeChild(this._claimAsset);
        break;
      case FRIENDSHIPCHESTCARD_STATUS.PENDING: // Pending card
        // Pending
        this.displayContainer.addChild(this._sentAsset);
        this.displayContainer.removeChild(this._button);
        this.displayContainer.removeChild(this._claimAsset);
        break;
      case FRIENDSHIPCHESTCARD_STATUS.OPEN: // Card is open
        this.displayContainer.removeChild(this._sentAsset);
        this.displayContainer.addChild(this._button);
        this.displayContainer.removeChild(this._claimAsset);
        this._button.setText(OMT.language.getText('Invite friends!'));
        this._button.friendshipChestButtonMode = FRIENDSHIPCHEST_BUTTONMODE.INVITE;
        break;
      case FRIENDSHIPCHESTCARD_STATUS.UNCLAIMED: // Card is unclaimed
        this.displayContainer.removeChild(this._sentAsset);
        this.displayContainer.addChild(this._button);
        this.displayContainer.removeChild(this._claimAsset);
        if (this._button.friendshipChestButtonMode !== FRIENDSHIPCHEST_BUTTONMODE.CLAIM) {
          this._button.setText(OMT.language.getText('Claim'));
        }
        this._button.friendshipChestButtonMode = FRIENDSHIPCHEST_BUTTONMODE.CLAIM;
        break;
      default: break;
    }
    if (this._perpetualHand) {
      this.displayContainer.addChild(this._perpetualHand);
    }
  }

  /**
   * Swaps the button out with the sent asset
   */
  swapToPending() {
    const tweenTime = 200;
    const targetY = this._button.height + game.height;
    if (this._sentAsset.y === 0) {
      this._repositionUIElements();
    }
    const oriY = this._sentAsset.y;
    this._sentAsset.y = targetY;
    this.displayContainer.addChild(this._sentAsset);
    const tw = game.add.tween(this._button)
      .to({ y: targetY, alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
    game.add.tween(this._sentAsset)
      .to({ y: oriY, alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
    return tw;
  }

  /**
   * Adds a new card and transitions to the right
   */
  _addNewEndCardAndTransitionRight() {
    if (!this.currentPolaroid.nextPolaroid) { // If there isn't a polaroid to the right, make one
      this._addPolaroidToTheRight();
      G.saveState.friendshipChestDataManager.openNewCard(this.currentPolaroid.nextPolaroid.index);
    }
    this._clothesline.displayNextPolaroid();
    this._repositionUIElements();
  }

  /** End of funtionality */
  /** On the fly drawing and ready */

  /**
   * Draws the friend card in async. Requires await for the friend list data
   * @param {function} onComplete
   */
  async _readyFriend(onComplete, tutorialOverride) {
    if (this._friendAsset) {
      this._friendAsset.destroy();
    }
    let targetFriend;
    if (tutorialOverride) {
      targetFriend = 'GINGY';
    } else {
      targetFriend = G.saveState.friendshipChestDataManager.getFriend(); // Grabs a friend in the pending array
    }
    this._friendAsset = new Phaser.Group(G.game, null);
    if (targetFriend === undefined) { console.log('NO FRIENDS INVITED'); return; } // Cries
    this.hideUI();
    this._hideBottomSide();

    const shine = G.makeImage(0, 0, 'reward_BG', 0.5, null);
    shine.scale.set(1.15);
    shine.y = 0; // this._clothesline.y + (this._clothesline.height - shine.height) / 2;

    let friendName;
    let friendImage;
    let friendText = '%Name% joined the game!';
    if (tutorialOverride) {
      friendName = tutorialOverride.name;
    } else {
      const friends = await OMT.friends.getFriendsList(); // Might need to not use cache since new friends
      const friendData = friends.filter((fr) => fr.id == targetFriend)[0]; // eslint-disable-line eqeqeq
      friendName = (friendData && friendData.name !== '') ? friendData.name : '';
      friendImage = (friendData && friendData.image) ? friendData.image : null;
    }

    if (friendName === '') {
      friendText = 'Your friend joined the game!';
    }

    const avatarSize = 150;
    const avatar = new Phaser.Group(game, null);
    let avatarImage;
    if (tutorialOverride) {
      avatarImage = tutorialOverride.image;
      avatar.addChild(avatarImage);
    } else if (!friendImage) {
      avatarImage = G.makeImage(0, 0, 'avatar_e', 0.5, avatar);
    } else {
      avatarImage = G.makeExtImage(0, 0, friendImage, 'avatar_e', 0.5, avatar, false, function manipulateThis() {
        this.width = this.height = avatarSize;
      });
    }
    const frame = G.makeImage(0, 0, 'avatar_frame_big', 0.5, avatar);
    avatarImage.width = avatarImage.height = avatarSize;
    frame.width = frame.height = avatarImage.width * 1.1;
    avatar.y = shine.y;

    const text = OMT.language.getText(friendText).replace('%Name%', friendName);
    const joinedTheGameText = new G.Text(0, 0, text, 'friendshipChest-title', 0.5, game.width * 0.85, 250, true, 'center');
    joinedTheGameText.y = this._prizeSection.y;

    this._friendAsset.addChild(shine);
    this._friendAsset.addChild(avatar);
    this._friendAsset.addChild(joinedTheGameText);

    if (this._isLandscape) {
      this._friendAsset.y += 50;
    }

    // Friend animation. Is used in Friendship_Chest.js
    let pulseTween; // I need a reference to the beginning tween
    let lastTween; // I also need the last tweek
    for (let i = 0; i < 4; i++) { // Yoyo effects with chaining doesn't work very well
      const tw = game.add.tween(avatar.scale) // normal size
        .to({ x: 1, y: 1 }, 500, Phaser.Easing.Sinusoidal.In, false);
      const tw2 = game.add.tween(avatar.scale) // Big size
        .to({ x: 1.2, y: 1.2 }, 500, Phaser.Easing.Sinusoidal.Out, false);
      if (!lastTween) { // Get reference and chain
        pulseTween = tw;
        tw.chain(tw2);
      } else {
        lastTween.chain(tw, tw2); // Otherwise chain
      }
      lastTween = tw2;
    }
    const enterAnimation = game.add.tween(avatar.scale) // The enter animation, used when card is entering or tweening from tutotirla
      .to({ x: 1.2, y: 1.2 }, 500, Phaser.Easing.Sinusoidal.Out, false);
    avatar.scale.set(0.5);
    this._friendAsset.animation = { // A reference for accessing outside
      enter: enterAnimation, // The enter tween
      exit: {
        start: () => { // Spoof the tween call! :'D
          if (enterAnimation.isRunning) { // Chain the pulse tween to start if enterAnimation is running
            enterAnimation.chain(pulseTween);
          } else {
            pulseTween.start(); // Otherwise just start it yourself
          }
        },
      },
    };

    if (onComplete) { onComplete(); }
  }

  /**
   * The friend screen fades in while the clothesline fades out
   */
  _animateInFriend() {
    this._friendAsset.alpha = 0;
    this.displayContainer.addChild(this._friendAsset);
    const enterTween = game.add.tween(this._friendAsset) // Tween is initialized to add things if anything
      .to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out, false);
    const fadeOutTween = game.add.tween(this._clothesline)
      .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, false);
    const prizeTween = game.add.tween(this._prizeSection) // Prize fades out
      .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, false);

    if (this._friendAsset.animation) { // Start animation if there is one
      this._friendAsset.animation.enter.start();
    }

    enterTween.onComplete.addOnce(() => { // Friend is animated out on complete
      this._animateOutFriend();
    });
    enterTween.start();
    fadeOutTween.start();
    prizeTween.start();
  }

  /**
   * Fades the friend info out before enabling the claim button
   */
  _animateOutFriend() {
    if (this._friendAsset.animation) { // Start the exit animation if there is one
      this._friendAsset.animation.exit.start();
    }
    game.time.events.add(3000, () => { // Waits 3s before fading out the friend
      const fadeOutTween = game.add.tween(this._friendAsset) // Tween is initialized to add things if anything
        .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, false);
      const enterTween = game.add.tween(this._clothesline)
        .to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out, false);
      const prizeTween = game.add.tween(this._prizeSection) // Prize fades out
        .to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out, false);
      enterTween.onComplete.addOnce(() => {
        G.saveState.friendshipChestDataManager.setClaimable(this.currentPolaroid.index); // Sets the card to its new state
        const stat = this._getCurrentPolaroidStatus();
        this._onResize();
        this._button.currentState = BUTTONSTATE.ENABLED;
        this._updateButtonAreaByStatus(stat);
        this._showBottomSide();
        this.showUI();
        if (this.tutorialMode) { // Progress tutorial when friend leaves
          this.signals.onTutorialProgress.dispatch('finishedRequestStep');
        }
      });
      fadeOutTween.start();
      enterTween.start();
      prizeTween.start();
    }, this);
  }

  /**
   * Draws everything needed for the claim animation and returns it or calls onComplete with the info
   * @param {function} onComplete
   * @returns {
    *  container: Phaser.Group,
    *  prizeData: Object,
    *  shine: Phaser.Image,
    *  chestOpen: Phaser.Group,
    *  chestClosed : Phaser.Group,
    *  prizeDisplay: Phaser.Group,
    *  applyGifts: function
    * }
    */
  _readyClaim(onComplete) { // eslint-disable-line consistent-return
    const container = new Phaser.Group(G.game, null);

    const center = this._clothesline.y + this._clothesline.height / 2; // Finds the location of the center of the chest in the card
    const shine = G.makeImage(0, center, 'shine_godrays', 0.5, container);
    shine.scale.set(1.5, 1.5);
    shine.update = () => {
      if (container.visible && shine.alpha > 0) {
        shine.angle += 0.25;
      }
    };
    shine.alpha = 0;

    // eslint-disable-next-line max-len
    const prizeData = this._generatePrizeSection({ style: 'font-white', fontSize: Math.round(Math.max(64, Math.min(128, game.height / 18))) }, false); // Generates the prize section again from the card
    const prizeSection = prizeData.visual;
    const prize = prizeData.data;
    const prizeIndex = prizeData.index;

    prizeSection.y = this._prizeSection.y;
    container.addChild(prizeSection);

    const chestOpen = G.makeImage(0, center, 'friendship_chest_full', 0.5, container);
    chestOpen.alpha = 0;
    chestOpen.scale.setTo(this._isLandscape ? 1.8 * this._gameScale : 1.8);

    const chestClosed = new Phaser.Group(G.game, container);
    G.makeImage(0, 0, 'friendship_chest_closed', 0.5, chestClosed);
    chestClosed.y = center;

    // When the claim button is clicked
    const applyGifts = () => {
      const trackerArray = [];
      OMT.transactionTracking.logInventoryTransactionBegin();

      for (let i = 0; i < prize.length; i++) {
        const curPrize = prize[i];
        trackerArray.push([curPrize.prize, curPrize.amount]); // Puts into an array for data tracking

        switch (curPrize.prize) {
          case 'coin':
            this.parentWindow.state.uiTargetParticles.createCoinBatch( // Show bling
              game.world.bounds.x + prizeSection.worldPosition.x,
              prizeSection.worldPosition.y,
              this.parentWindow.state.panel.coinsTxt,
              curPrize.amount,
              false,
            );
            OMT.transactionTracking.addInventoryChange('coins', 0, curPrize.amount);
            break;
          case 'life':
            G.saveState.addLife(curPrize.amount); // Add life
            break;
          default: { // Perhaps bad, but default is assuming booster
            const boosterIndex = parseInt(curPrize.prize[8], 10);
            G.saveState.changeBoosterAmount(boosterIndex, curPrize.amount); // Add in booster
            OMT.transactionTracking.addInventoryChange('boostersReceived', boosterIndex, curPrize.amount);
            break;
          }
        }
      }

      // DDNA.transactionHelper.trackRewards(trackerArray, [], { // DDNA tracking
      //   transactionType: 'REWARD',
      //   tActionType: 'FRIENDSHIPCHEST_REWARD',
      //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
      //   mFriendshipChestOpened: G.saveState.friendshipChestDataManager.getOpened(),
      //   mFriendshipChestSocialRewardId: prizeIndex,
      // });
      OMT.transactionTracking.logInventoryTransactionEnd();
      G.saveState.save(); // Save!
    };

    const returnedGoods = {
      container,
      prizeData: prize,
      shine,
      chestOpen,
      chestClosed,
      prizeDisplay: prizeSection,
      applyGifts,
    };

    if (onComplete) { onComplete(returnedGoods); } else { return returnedGoods; }
  }

  /**
    * Animates the claim animation with a ton of tweens
    * @param {Object} claimDisplay
    */
  _animateClaim(claimDisplay) {
    this.hideUI();
    this._hideBottomSide();
    this._UIAlpha_tween.onComplete.add(() => {
      this._UIAlpha_tween = null;
    });
    this.displayContainer.removeChild(this._button);

    const tweenTime = 500;

    const cardOut = game.add.tween(this._clothesline) // Current card fades out
      .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);

    // Get positions and sets the beginning displays of things
    this.displayContainer.addChild(claimDisplay.container);
    const chestY = claimDisplay.chestClosed.y;
    claimDisplay.prizeDisplay.alpha = 0;
    const prizeY = claimDisplay.prizeDisplay.y;
    claimDisplay.prizeDisplay.y = chestY;
    claimDisplay.chestClosed.scale.set(0.3, 0.3);
    claimDisplay.chestClosed.y = chestY - 20;
    claimDisplay.shine.alpha = 0;

    let outro;
    // Is this too much tweening...?
    const prizeIn = game.add.tween(claimDisplay.container) // The claim display container fades in (with whatever isn't hidden)
      .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    prizeIn.onStart.addOnce(() => { // At the same time
      game.add.tween(claimDisplay.chestClosed) // The closed chest hops out
        .to({ y: [chestY - 150, chestY] }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);

      const closedChestScale = this._isLandscape
        ? {
          x: claimDisplay.chestOpen.scale.x,
          y: claimDisplay.chestOpen.scale.y,
        }
        : {
          x: claimDisplay.chestOpen.scale.x,
          y: claimDisplay.chestOpen.scale.y,
        };
      const claimChest = game.add.tween(claimDisplay.chestClosed.scale) // The chest also grows from tiny to big
        .to(closedChestScale, tweenTime, Phaser.Easing.Sinusoidal.Out, true);

      claimChest.onComplete.addOnce(() => { // When its done hopping
        const shakeTween1 = game.add.tween(claimDisplay.chestClosed) // It'll shake twice
          .to({ angle: [10, -10, 0] }, 250, Phaser.Easing.Sinusoidal.InOut, true, tweenTime);
        const shakeTween2 = game.add.tween(claimDisplay.chestClosed) // copy of shake one but I need a longer delay here
          .to({ angle: [10, -10, 0] }, 250, Phaser.Easing.Sinusoidal.InOut, false, tweenTime * 1.5);
        shakeTween1.chain(shakeTween2);
        shakeTween2.onComplete.addOnce(() => { // Before showing...
          game.time.events.add(tweenTime * 2, () => { // The suspense is killing me!
            game.add.tween(claimDisplay.shine) // The shine in the background
              .to({ alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true);
            game.add.tween(claimDisplay.chestOpen) // The opened chest fades in (looking like it opened)
              .to({ alpha: 1 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true);
            game.add.tween(claimDisplay.chestClosed) // The closed chest fades away
              .to({ alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut, true);
            game.time.events.add(tweenTime * 1.5, () => { // A delay...
              const prizeOut = game.add.tween(claimDisplay.prizeDisplay) // Before the prize drops out of the chest
                .to({ alpha: 1, y: prizeY }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
              prizeOut.onComplete.addOnce(() => { // When it drops out...
                G.saveState.friendshipChestDataManager.setClaimed(this.currentPolaroid.index); // Card is now claimed
                G.saveState.friendshipChestDataManager.setNumberOfLevelsToAppear(40);
                claimDisplay.applyGifts(); // Gifts are applied

                game.time.events.add(2000, () => { // Wait some more...
                  outro.start(); // Outro
                }, this);
              }, this);
            }, this);
          });
        });
      });
    });

    // Outro is outside so it can be found easier
    outro = game.add.tween(claimDisplay.container) // Claim display container fades away
      .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
    outro.onStart.addOnce(() => { // At the same time
      const cardTween = game.add.tween(this._clothesline) // Card comes fades back in
        .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      game.add.tween(this._prizeSection) // Card comes fades back in
        .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);

      const { checkMark } = this._claimAsset;
      this._claimAsset.alpha = 0;
      const claimedMask = new Phaser.Graphics(game);
      claimedMask.beginFill(0, 1);
      claimedMask.drawCircle(0, 0, checkMark.width * 1.5);
      claimedMask.x = checkMark.x - claimedMask.width * 0.75;
      const halfWidth = claimedMask.width / 2;
      checkMark.mask = claimedMask;
      checkMark.visible = true;
      this._claimAsset.addChild(claimedMask);
      this.displayContainer.addChild(this._claimAsset);
      game.add.tween(this._claimAsset) // Card comes fades back in
        .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      cardTween.onComplete.addOnce(() => {
        // Animate the check mark
        const tw = game.add.tween(claimedMask) // Mask slides in
          .to({ x: checkMark.x + halfWidth }, 2500, Phaser.Easing.Sinusoidal.InOut, true);
        tw.onComplete.addOnce(() => {
          checkMark.mask = null;
          claimedMask.destroy();
        });

        claimDisplay.shine.update = () => {}; // Claim animation elements are cleaned up
        claimDisplay.container.destroy();
        if (claimDisplay.container.parent) {
          claimDisplay.container.parent.removeChild(claimDisplay.container);
        }
        claimDisplay = null; // Discarded

        this._button.currentState = BUTTONSTATE.ENABLED;
        game.time.events.add(3000, () => { // Add new card or transition out.
          this._addNewEndCardAndTransitionRight();
        }, this);
      });
    });

    // Tweens start
    cardOut.start();
    prizeIn.start();
  }

  /**
   * Draws a fake fill for the big red error screen. Fades away after a bit if nothing changed
   * @param {Object} friendInfo
   */
  _drawErrorCard(friendInfo) {
    const tryInvitingNewFriend = new G.Text(0, 0, OMT.language.getText('Try inviting a new friend!'), 'friendshipChest-prizeSection', 0.5, game.width * 0.8, 250, true, 'center');
    tryInvitingNewFriend.y = this._prizeSection.y;
    if (this._isLandscape) {
      tryInvitingNewFriend.scale.setTo(this._gameScale);
    }

    const text = OMT.language.getText('Your friend %Name% already plays %GameName%...')
      .replace('%Name%', friendInfo.name);

    const errorTextWidth = this._isLandscape ? (game.width * 0.7) / this._gameScale : game.width * 0.7 ;
    const errorText = new G.Text(0, 0, text, 'friendshipChest-redText', 0.5, errorTextWidth, 200, true, 'center');
    if (this._isLandscape) {
      errorText.scale.setTo(this._gameScale);
    }

    errorText.y = this._subText.y + 20 + (this._subText.height + errorText.height) / 2;

    const leftoverSpace = (game.height + tryInvitingNewFriend.y - (tryInvitingNewFriend.height / 2)) - (game.height + errorText.y + (errorText.height / 2));
    const avatar = new OMT_UI_AvatarWithFrame(friendInfo.photo, 150);
    avatar.y = errorText.y + leftoverSpace - avatar.height / 2; // Center the avatar between the two text
    if (this._isLandscape) {
      avatar.scale.setTo(this._gameScale);
    }

    if (this._errorAsset) { // Purged fulfilled
      for (let i = 0; i < this._errorAsset.children.length; i++) {
        this._errorAsset.children[i].destroy();
      }
      this._errorAsset.removeChildren();
      this._errorAsset.visible = true;
    } else { // Or make a new one
      this._errorAsset = new Phaser.Group(G.game, null);
    }

    this._errorAsset.addChild(errorText);
    this._errorAsset.addChild(avatar);
    this._errorAsset.addChild(tryInvitingNewFriend);

    // Starts the error animation
    game.add.tween(this._clothesline) // Hide clothesline
      .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
    game.add.tween(this._prizeSection) // Hide prize
      .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
    this._errorAsset.alpha = 0;
    this.displayContainer.addChild(this._errorAsset);
    game.add.tween(this._errorAsset) // Show error
      .to({ alpha: 1 }, 200, Phaser.Easing.Sinusoidal.InOut, true);
    if (this._errorAsset.fadeOut) {
      this._errorAsset.fadeOut.stop();
      this._errorAsset.fadeOut = null;
    }
    if (!this._errorAsset.timeOut) {
      this._errorAsset.timeOut = _.debounce(() => { // Creates a debounce on thie error so that the full 5s can be seen
        if (this._errorAsset) {
          this._fadeOutError(500);
        }
      }, 5000);
    }
    this._errorAsset.timeOut();
  }

  /**
   * The error fades out
   * @param {number} tweenTime
   * @param {boolean} prizeSec Also applies fading on prize section if true
   */
  _fadeOutError(tweenTime, prizeSec = true) {
    if (this._errorAsset && this._errorAsset.visible) {
      this._errorAsset.fadeOut = game.add.tween(this._errorAsset)
        .to({ alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, false);
      this._errorAsset.fadeOut.onStart.add(() => {
        if (prizeSec) {
          game.add.tween(this._prizeSection)
            .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
        }
        game.add.tween(this._clothesline)
          .to({ alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut, true);
      });
      this._errorAsset.fadeOut.onComplete.add(() => {
        this._errorAsset.visible = false;
      });
      this._errorAsset.fadeOut.start();
    }
  }

  /** End of on the fly ready and animations */
  /** Signal movement */

  /**
   * When polaroids move, things happen
   * @param {Array<FriendshipChest_Polaroid>} visibleArr
   */
  _onPolaroidsMove(visibleArr) {
    this._adjustPolaroid(visibleArr); // Fade/Size polaroids
    if (this._errorAsset && this._errorAsset.visible && !this._errorAsset.fadeOut) { // If an _errorAsset exists
      this._fadeOutError(200, false); // Fade it but don't touch the prize
    }
    if (!this._polaroidsAreMoving) {
      this.hideUI(200, false); // Hide UI
      this._hideBottomSide(); // Hide the prize here
      this._polaroidsAreMoving = true;
    }
  }

  /**
   * When polaroids are done moving
   * @param {Array<FriendshipChest_Polaroid>} visibleArr
   */
  _onPolaroidsDoneMoving(visArr) {
    this._adjustPolaroid(visArr); // One last time to make sure its working

    const stat = this._getCurrentPolaroidStatus(); // Check status
    if (stat === FRIENDSHIPCHESTCARD_STATUS.FRIEND) { // If friend, do the stuff, ignore everything else
      this._readyFriend(() => {
        this._animateInFriend();
      });
      return;
    }
    // Otherwise act as normal
    this._updateButtonAreaByStatus(stat); // Update bottom
    this._generatePrizeSection(); // Refresh prize
    this._showBottomSide();
    this.showUI();
    this._polaroidsAreMoving = false;

    if (this.tutorialMode) {
      this.signals.onTutorialProgress.dispatch('doneMoving');
    }
  }

  /**
   * Adjust each polaroid's size, tint, and position when moving, based on the distance of the polaroid to the edge
   * of the screen
   * @param {Array<FriendshipChest_Polaroid>} visArr
   */
  _adjustPolaroid(visArr) {
    const lowestTint = 0.5;
    const lowestScale = 0.8;
    const highestY = -52;

    visArr.forEach((pol) => {
      const ratio = (Math.abs(pol.x) / (game.width / 2)); // This polaroid's ratio to the edge of the screen

      const targetAlpha = ((ratio * (lowestTint - 1)) + 1);
      pol.tintFrame(targetAlpha);
      pol.tintInner(targetAlpha);

      const targetScale = ((ratio * (lowestScale - 1)) + 1);
      pol.scale.set(targetScale);

      const targetY = ((ratio * ((pol.clotheslineY + highestY) - pol.clotheslineY)) + pol.clotheslineY);
      pol.y = targetY;
    });
  }

  /**
   * Reposition UI elements on resize
   */
  _onResize() {
    const scaleFactor = this._isLandscape ? this._gameScale : 1;

    OMT_UI_DrawUtil.redrawGradients(this._header, this._gradientData.upperGradient, this._gradientData.titleGradient);
    G.Text.setMaxWidth(this._header.titleText, this._header.gradientData.titleBg.image.width * 0.9);
    this._header.titleText.setText(this._header.titleText.text);
    this._header.titleText.x = Math.round(this._header.gradientData.titleBg.image.x + this._header.gradientData.titleBg.image.width / 2);
    this._header.titleText.y = Math.round(this._header.gradientData.titleBg.image.y + 5 + this._header.gradientData.titleBg.image.height / 2);
    this._header.addChild(this._header.titleText);

    this._header.x = -game.width / 2;
    this._header.y = -(game.height) / 2;

    G.Text.setMaxWidth(this._subText, game.width * 0.95);
    this._subText.setText(this._subText.text);
    this._subText.y = Math.round(this._header.y + 10 + this._header.height + this._subText.height / 2);
    this._clothesline.y = Math.max(this._subText.y + 60 + (this._subText.height / 2), -20 - game.height / 6);

    this._closeBtn.x = -(this._closeBtn.width / 4) + (game.width - this._closeBtn.width) / 2;
    this._closeBtn.y = (this._closeBtn.height / 4) + (this._closeBtn.height - game.height) / 2;
    this.parentWindow.addChild(this._closeBtn);

    this._prizeSection.y = Math.round(Math.max(this._clothesline.y + (this.currentPolaroid.y + (this.currentPolaroid.calculatedBounds.height + this._prizeSection.height) / 2) * scaleFactor,
      ((game.height + this._prizeSection.height) / 4) * scaleFactor));

    if (this._isLandscape) {
      this._prizeSection.scale.setTo(this._gameScale);
    }
    this._repositionUIElements();
  }

  /**
   * Specifically repositions the bottom side
   */
  _repositionUIElements() {
    const scaleFactor = this._isLandscape ? this._gameScale : 1;

    this._button.y = Math.round(Math.min((game.height * scaleFactor - (this._button.height) / 2) - 30,
      (this._prizeSection.y + this._prizeSection.height / 2) + ((game.height / 2) - (this._prizeSection.y + this._prizeSection.height / 2)) / 2));
    this._sentAsset.y = Math.round(Math.min((game.height * scaleFactor - (this._sentAsset.height) / 2) - 10,
      (this._prizeSection.y + this._prizeSection.height / 2) + ((game.height / 2) - (this._prizeSection.y + this._prizeSection.height / 2)) / 2));
    this._claimAsset.y = Math.round(Math.min((game.height * scaleFactor - (this._claimAsset.height) / 2) - 30,
      (this._prizeSection.y + this._prizeSection.height / 2) + ((game.height / 2) - (this._prizeSection.y + this._prizeSection.height / 2)) / 2));
    this._perpetualHand.x = this._button.x + this._button.width * 0.35;
    this._perpetualHand.y = this._button.y - (this._button.height * 0.6);

    if (this._isLandscape) {
      this._sentAsset.y += 20;
    }
  }

  /**
   * When the button is clicked.
   * Same as in a card
   */
  async _onButtonClick() {
    const card = this.currentPolaroid;
    if (this.tutorialMode && card.index === 0 && this._button.friendshipChestButtonMode === FRIENDSHIPCHEST_BUTTONMODE.INVITE) { // Hijack the button click for tutorial
      this.signals.onTutorialProgress.dispatch();
      return;
    }

    this.hideUI();

    // You need to make a loading screen or overlay...
    if (this._button.friendshipChestButtonMode === FRIENDSHIPCHEST_BUTTONMODE.INVITE) { // If the button is INVITE FRIENDS
      let result = false; // Result of adding friends
      let triedToInvite = null; // The ID of a friend user tried to invite
      const findOtherPlayer = async () => {
        const FBdata = await OMT.social.getPlayersInContext(); // Checking context for other players
        if (Array.isArray(FBdata)) { // Just in case something weird comes back
          result = FBdata.length < 2; // Might be just you here
          if (!result) { // Back to false?
            for (let i = 0; i < FBdata.length; i++) {
              const player = FBdata[i].$1;
              if (player.id !== OMT.envData.settings.user.userId) {
                triedToInvite = player; // Found out who you tried to invite
                return;
              }
            }
          }
        }
      };
      result = await OMT.social.sendFriendshipChestInvite(async () => { // Waiting for status...
        await findOtherPlayer();
        return !triedToInvite;
      });
      if (result === SOCIAL_STATUS.SAME_CONTEXT) {
        await findOtherPlayer();
        if (!triedToInvite) { // Acts similarily to trying to switch context to someone whom you're with already
          result = SOCIAL_STATUS.PASS;
        }
      }

      if (result === SOCIAL_STATUS.PASS) {
        // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenFriendshipChestShareBtn', 1);
        // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('usedFriendshipChestShareBtn', 1);
        // DDNA.socialTracker.incrementParam('numFriendsInvites', 1);
        // DDNA.tracking.socialActionEvent('friendshipChestInvite', '', '', '');
        G.saveState.friendshipChestDataManager.setPending(card.index);
        G.saveState.friendshipChestDataManager.setNumberOfLevelsToAppear(40, true);
        const tw = this.swapToPending();
        tw.onComplete.addOnce(() => {
          this._button.currentState = BUTTONSTATE.ENABLED;
        });
        game.time.events.add(2200, () => { // 2secs + 200ms for animation
          this._addNewEndCardAndTransitionRight();
        }, this);
      } else { // !result
        if (triedToInvite) { /* eslint-disable-line no-lonely-if */ // check again
          // Show error card
          this._drawErrorCard(triedToInvite);
          this._button.currentState = BUTTONSTATE.ENABLED;
        } else { // General failure
          this._button.currentState = BUTTONSTATE.ENABLED;
        }
        this.showUI();
      }
    } else if (this._button.friendshipChestButtonMode === FRIENDSHIPCHEST_BUTTONMODE.CLAIM) { // Button is CLAIM instead
      // Do fancy animation
      if (this.tutorialMode) { // Progress tutorial too
        this.signals.onTutorialProgress.dispatch();
      }
      this._readyClaim((goods) => { // Draw everything
        this._animateClaim(goods); // Go time!
      });
    }
  }
  /** End of signal movement */
}
