import { TextTimer } from './TextTimer';

export default class UI_Life extends Phaser.Group {
  constructor(x, y, orientationIsVertical) {
    super(game);
    this.x = Math.floor(x);
    this.y = Math.floor(y);

    this._prevStatus = null;
    this._currentLivesNr = G.saveState.getLives();
    this.livesMax = G.json.settings.livesMax;

    this._uiContainer = new Phaser.Group(game, this);
    this._drawHeartIcon(orientationIsVertical);
    this._drawText();

    this._allSignals = [
      G.sb('onWallClockTimeUpdate').add(this.onTickUpdate, this),
      G.sb('onLifeAdded').add(this.onTickUpdate, this),
      G.sb('onLifeTimerUpdate').add(this.timer.setSecLeft, this.timer),
      G.sb('onUnlimitedLivesChanged').add(this.unlimitedTimer.setSecLeft, this.unlimitedTimer),
      G.sb('onWindowOpened').add(this.lockInput, this),
      G.sb('onAllWindowsClosed').add(this.unlockInput, this),
      G.sb('pauseAllUpdate').add(() => {
        this._skipUpdate = true;
      }),
      G.sb('resumeAllUpdate').add(() => {
        this._skipUpdate = false;
      }),
    ];
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._allSignals) {
      for (const sig of this._allSignals) {
        if (sig && sig.detach) {
          sig.detach();
        }
      }
      this._allSignals.length = 0;
    }
    super.destroy();
  }

  /**
   * Draws the hearts in a single container
   */
  _drawHeartIcon(orientationIsVertical) {
    this._heartIconContainer = new Phaser.Group(game, this._uiContainer);

    // Glow for unlimited lives
    this.glow = G.makeImage(0, 0, 'popup_lighht', 0.5, this._heartIconContainer);
    this.glow.blendMode = 1;
    this.glow.alpha = 0.2;

    // Regular life heart icon
    const shadowDistance = 3;
    const heartShadow = G.makeImage(shadowDistance, shadowDistance, 'heart', 0.5, this._heartIconContainer);
    const lifeIconScale = 52 / heartShadow.height;
    heartShadow.tint = 0;
    heartShadow.alpha = 0.3;
    heartShadow.scale.set(lifeIconScale, lifeIconScale);
    this._lifeIcon = new G.Button(0, 0, 'heart', () => {
      if (G.saveState.getLives() === 0 && G.saveState.getUnlimitedLivesSec() === 0) {
        G.sb('pushWindow').dispatch(['notEnoughLives'], false, G.WindowMgr.LayerNames.AboveHighScorePanel);
      } else {
        G.sb('openLifeTransactionsMenu').dispatch();
      }
    }, this);
    this._lifeIcon.scale.set(lifeIconScale, lifeIconScale);

    // Unlimited lives heart. Goes on top of glow
    this.lifeIconUnlimited = G.makeImage(0, 0, 'heart_unlimited', 0.5, this._heartIconContainer);
    game.add.tween(this.lifeIconUnlimited.scale)
      .to({ x: 1.1, y: 1.1 }, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    this._heartIconContainer.add(this._lifeIcon);

    const heartSize = G.OMTsettings.elements.UI_Life.heartImageSize;
    this._heartIconContainer.scale.set((orientationIsVertical ? heartSize.vertical : heartSize.horizontal) / this._heartIconContainer.height);
  }

  /**
   * Draws the text in a single container.
   * It should be noted that while the heart image is centered on where it should be, the text's positioning
   * is determined by positioning of what is in G.OMTSettings
   */
  _drawText() {
    this._textContainer = new Phaser.Group(game, this._uiContainer);
    const orientation = OMT.systemInfo.orientationKey;
    // Regular lives text
    const targetTextData = G.OMTsettings.elements.UI_Life.livesNrTxt[orientation];
    this.livesNrTxt = new G.Text(targetTextData.x, targetTextData.y, this._currentLivesNr.toString(), {
      style: 'font-white',
      fontSize: `${targetTextData.size}px`,
    }, 0.5, 150);
    this._textContainer.addChild(this.livesNrTxt);

    // Out of lives timer
    const timerTextData = G.OMTsettings.elements.UI_Life.timerTxt[orientation];
    this.timer = new TextTimer({
      x: timerTextData.x,
      y: timerTextData.y,
      date: 0,
      style: {
        style: timerTextData.style,
        fontSize: timerTextData.size,
      },
      anchor: 0.5,
      maxWidth: 140,
      timerFormat: 'ms',
    });
    this._textContainer.addChild(this.timer);

    // Unlimited lives timer
    const unlimitedTimerTextData = G.OMTsettings.elements.UI_Life.unlimitedTimerTxt[orientation];
    this.unlimitedTimer = new TextTimer({
      x: unlimitedTimerTextData.x,
      y: unlimitedTimerTextData.y,
      date: 0,
      style: {
        style: unlimitedTimerTextData.style,
        fontSize: unlimitedTimerTextData.size,
      },
      anchor: 0.5,
      maxWidth: 140,
      timerFormat: 'hms',
    });
    this._textContainer.addChild(this.unlimitedTimer);

    // Max text
    const maxTextData = G.OMTsettings.elements.UI_Life.timerMaxTxt[orientation];
    this.timerMax = new G.Text(maxTextData.x, maxTextData.y, OMT.language.getText('Max'), {
      style: maxTextData.style,
      fontSize: maxTextData.size,
    }, 0.5, 100);
    this._textContainer.addChild(this.timerMax);

    this.timer.start();
  }

  /**
   * When ticks happen
   */
  onTickUpdate() {
    const newCurrentLives = G.saveState.getLives();

    if (this._currentLivesNr !== newCurrentLives) {
      this._currentLivesNr = newCurrentLives;
      this.livesNrTxt.setText(this._currentLivesNr.toString());
    }
  }

  /**
   * Update function
   */
  update() {
    if (this._skipUpdate) { return; }
    if (this.glow.visible) {
      this.glow.angle += G.deltaTime;
    }
    let status;
    if (G.saveState.getUnlimitedLivesSec() > 0) {
      status = 'unlimited';
    } else if (this._currentLivesNr < this.livesMax) {
      status = 'normal';
    } else {
      status = 'max';
    }

    if (status !== this._prevStatus) {
      switch (status) {
        case 'max':
          this.showMax();
          break;
        case 'normal':
          this.showNormal();
          break;
        case 'unlimited':
          this.showUnlimited();
          break;
        default: break;
      }
    }
    this._prevStatus = status;
  }

  /**
   * Show assets for unlimited mode
   */
  showUnlimited() {
    this.glow.visible = true;
    this.timer.visible = false;
    this.lifeIconUnlimited.visible = true;
    this._lifeIcon.visible = false;
    this.unlimitedTimer.visible = true;
    this.unlimitedTimer.start(G.saveState.getUnlimitedLivesSec());
    this.timerMax.visible = false;
    this.timer.visible = false;
    this.livesNrTxt.visible = false;
  }

  /**
   * Show assets for max mode
   */
  showMax() {
    this.glow.visible = false;
    this.timer.visible = false;
    this.lifeIconUnlimited.visible = false;
    this.unlimitedTimer.visible = false;
    this.timerMax.visible = true;
    this.timer.visible = false;
    this._lifeIcon.visible = true;
    this.lifeIcon.inputEnabled = false;
    this.livesNrTxt.visible = true;
  }

  /**
   * Show asset for regular mode
   */
  showNormal() {
    this.glow.visible = false;
    this.timer.visible = true;
    this.lifeIconUnlimited.visible = false;
    this.unlimitedTimer.visible = false;
    this.timerMax.visible = false;
    this.timer.visible = true;
    this.lifeIcon.inputEnabled = OMT.feature.isGiftingSupported();
    this._lifeIcon.visible = true;
    this.livesNrTxt.visible = true;
  }

  /**
   * Lock input
   */
  lockInput() {
    this.ignoreChildInput = true;
  }

  /**
   * Unlock input
   */
  unlockInput() {
    this.ignoreChildInput = false;
  }

  /**
   * Returns the container that holds the asset wanted for positioning purposes
   */
  get lifeIcon() {
    return this._heartIconContainer;
  }
}
