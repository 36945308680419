import { FX_CoinShower } from '@omt-components/FX/confetti/FX_CoinShower';
import LightsBorder from './Props/LightsBorder';
import { LIGHTS_MODES, LIGHT_STYLE } from './Props/LightsConfig';
import MascotWheelHost from './Props/MascotWheelHost';
import { RMWHEEL_EPS, RMWHEEL_MODES, RMWHEEL_PRIZETABLES } from './rmWheelEnums';
import OMT_PrizeWheel from '../OMT_PrizeWheel';
import Sparkle from './Props/Sparkle';

// used to configure this class + elements of UI_PrizeWheel
const DEFAULT_CONFIG = {
  spinner: {
    wheelRotation: 120,
    wheelSegments: 12,
    bgAsset: 'realMoney_wheel',
    bgOffsetAngle: 180,
  },
  pointer: {
    x: 0,
    y: -210,
    pointerAsset: 'realMoney_prize_wheel_arrow',
    anchor: [0.5, 0.2],
  },
  sparkles: [
    { x: 180, y: 275, scale: 1 },
    { x: -210, y: 190, scale: 0.45 },
    { x: -85, y: -230, scale: 1 },
    { x: 175, y: -210, scale: 0.5 },
    { x: 210, y: 205, scale: 0.35 },
    { x: -230, y: 235, scale: 0.75 },
    { x: -165, y: -185, scale: 0.6 },
    { x: 100, y: 315, scale: 0.4 },
    { x: -180, y: 330, scale: 0.5 },
  ],
  maxTransitionMaskRadius: 700,
};

// merged with ITEM_CONFIGS from OMT_PrizeWheel + DEFAULT_CONFIG from PrizeWheel_Item
const ITEM_CONFIGS = {
  COINS: {
    text: {
      style: {
        fontSize: 27,
      },
      offset: { x: 125, y: 0 },
    },
    icon: {
      scale: 0.7,
      offset: { x: 150, y: 0 },
    },
  },
};

// Used for adjusting positions of element depending on the aspect ratio
const RESPONSIVE_CONFIG = {
  screenWidth: {
    min: 640,
    max: 1280,
  },
  screenHeight: {
    min: 960,
    max: 1140,
  },

  config: {
    scale: {
      min: 0.85,
      max: 1,
    },
    wheelScaleLandscape: 1.15,
  },
};

/**
 * real money specific prize wheel
 */
export default class OMT_RealMoneyPrizeWheel extends OMT_PrizeWheel {
  /**
   * constructor
   * @param {Object} config (optional) components configurations
   * @param {Object} config.spinner (optional) overrides for PrizeWheel_Spinner DEFAULT_CONFIG
   * @param {Object} config.pointer (optional) overrides for PrizeWheel_Pointer DEFAULT_CONFIG
   * @param {Object} config.itemConfigsOverride (opional) overrides for ITEM_CONFIGS from OMT_PrizeWheel
   */
  constructor(config = {}) {
    super(_.merge(_.cloneDeep(DEFAULT_CONFIG), config));

    this._entryPoint = config.entryPoint;
    this._wheelMode = config.wheelMode;

    // eslint-disable-next-line dot-notation
    this._itemConfigOverrides = this._config.itemConfigOverrides;
    const prizeTable = this._getPrizeTable(this._entryPoint, this._wheelMode);
    const itemDatalist = this._convertPrizeList(prizeTable, _.merge(_.cloneDeep(ITEM_CONFIGS), this._itemConfigOverrides));
    this.initPrizeItems(itemDatalist);

    this._makeTransitionWheel();
    this._makeShine();
    this._makeWheelOverlay();

    this._makeLightsBorder();
    this._makePlatinumWheelCenter();

    // Add platinum wheel center
    this._platWheelCenter = G.makeImage(0, 0, 'realMoney_center_plat', 0.5, this);
    this._platWheelCenter.visible = false;

    this._makeOverlay();
    this._makeUnavailableNotice();
    this._makeSparkles();
    this._makeFloodLights();

    // Check for the special case where the wheel should actually be the platinum version
    const wheelMode = config.wheelMode === RMWHEEL_MODES.HighValue && config.entryPoint === RMWHEEL_EPS.TargetedOffer
      ? RMWHEEL_MODES.Platinum
      : config.wheelMode;

    const mascotSpeakAtStart = !config.freeSpinMode;
    this._yOffset = this._isLandscape ? -50 : 0;

    this._gingy = new MascotWheelHost(0, this._yOffset, wheelMode, mascotSpeakAtStart, this._lightsMgr);
    if (this._isLandscape) {
      this._gingy.scale.setTo(0.8 / this._gameScale);
    }

    this.add(this._gingy);

    this._makeCoinShower();
    this._makeWinText();
    this._makeQuitBanner();

    this._onResize();
    this._onResizeSB = G.sb('onScreenResize').add(this._onResize, this);
  }

  _onResize() {
    const {
      screenWidth,
      screenHeight,
      config,
    } = RESPONSIVE_CONFIG;

    const lerpFactor = {
      x: G.lerpFactor(game.width, screenWidth.min, screenWidth.max),
      y: G.lerpFactor(game.height, screenHeight.min, screenHeight.max),
    };

    this.scale.setTo(G.lerp(config.scale.min, config.scale.max, lerpFactor.y, 0));

    if (this._isLandscape) {
      this._wheelDisplay.scale.setTo(config.wheelScaleLandscape);
      this._transitionGroup.scale.setTo(config.wheelScaleLandscape);
      this._platWheelCenter.scale.setTo(config.wheelScaleLandscape);
    }

    this._overlay.clear();
    this._overlay.beginFill(0x000000, 0.7);
    this._overlay.drawRect(-game.width, -game.height, game.width * 2, game.height * 2);
  }

  /**
   * Creates lights border
   */
  _makeLightsBorder() {
    this._lightsMgr = new LightsBorder(0, 0);
    this.add(this._lightsMgr);
  }

  /**
   * Creates platinum wheel center
   */
  _makePlatinumWheelCenter() {
    this._platWheelCenter = G.makeImage(0, 0, 'realMoney_center_plat', 0.5, this);
    this._platWheelCenter.visible = false;
    this.addElementToDarkenList(this._platWheelCenter);
  }

  /**
   * Creates sparkles for platinum wheel
   */
  _makeSparkles() {
    this._sparkles = DEFAULT_CONFIG.sparkles.map((sparkleProps) => {
      const newSparkle = new Sparkle(sparkleProps.x, sparkleProps.y, sparkleProps.scale);
      this.add(newSparkle);
      return newSparkle;
    });
  }

  /**
   * Creates flood lights for platinum wheel
   */
  _makeFloodLights() {
    this._floodLightBitmap = game.make.bitmapData(720, game.height / 2);
    const gradient = this._floodLightBitmap.context.createLinearGradient(0, 0, 0, game.height);
    gradient.addColorStop(0, 'rgba(255, 255, 255, 0)');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0.5)');

    // Draw triangular flood lights
    this._floodLightBitmap.polygon([
      { x: 50, y: this._floodLightBitmap.height },
      { x: 0, y: 0 },
      { x: 350, y: 0 },
      { x: 50, y: this._floodLightBitmap.height },
    ], gradient);

    this._floodLightBitmap.polygon([
      { x: this._floodLightBitmap.width - 50, y: this._floodLightBitmap.height },
      { x: this._floodLightBitmap.width, y: 0 },
      { x: this._floodLightBitmap.width - 350, y: 0 },
      { x: this._floodLightBitmap.width - 50, y: this._floodLightBitmap.height },
    ], gradient);

    this._floodLight = game.add.image(0, 0, this._floodLightBitmap);
    this._floodLight.anchor.x = 0.5;
    this._floodLight.anchor.y = 0;
    this._floodLight.visible = false;
    this.add(this._floodLight);
  }

  /**
   * Creates fake wheel for golden -> platinum wheel transformation animation
   */
  _makeTransitionWheel() {
    this._transitionGroup = new Phaser.Group(game);
    this._transitionFrame = G.makeImage(0, -20, 'realMoney_wheelFrame_plat', 0.5, null);
    this._transitionFrame.scale.setTo(0.95);
    this._transitionPointer = G.makeImage(0, -215, 'realMoney_prize_wheel_arrow_plat', 0.5, null);
    this._transitionWheel = G.makeImage(0, 0, 'realMoney_wheel_plat', 0.5, null);
    this._transitionWheel.angle = this._spinner.angle;

    this._transitionMask = new Phaser.Graphics(game);
    this._transitionMask.beginFill(0xFF0000, 0.5);
    this._transitionMask.drawCircle(0, 0, 0);
    this._transitionMask.endFill();

    this._transitionGroup.add(this._transitionWheel);
    this._transitionGroup.add(this._transitionFrame);
    this._transitionGroup.add(this._transitionPointer);
    this._transitionGroup.mask = this._transitionMask;
    // this._transitionGroup.cacheAsBitmap = true;
    this._transitionGroup.visible = false;

    this.add(this._transitionGroup);
    this.add(this._transitionMask);
  }

  /**
   * Creates coin shower particle effect
   */
  _makeWheelOverlay() {
    this._wheelOverlay = G.makeImage(0, 0, 'realMoney_wheelFrame', [0.5, 0.5]);
    this._wheelDisplay.addChildAt(this._wheelOverlay, 1);
    this.addElementToDarkenList(this._wheelOverlay);
  }

  /**
   * Gets a list of wheel prizes
   * @param {RMWHEEL_EPS} entryPoint
   * @param {RMWHEEL_MODES} isConversion
   * @returns {Array.<Object>}
   */
  _getPrizeTable(entryPoint, isConversion) {
    const prizeTableName = RMWHEEL_PRIZETABLES[entryPoint][isConversion];
    const prizeTable = G.json['configs/spinWheels'][prizeTableName];
    return _.cloneDeep(prizeTable);
  }

  /**
   * Returns the wheel images
   */
  _makeWheelImage() {
    return {
      wheelImage: G.makeImage(0, 0, 'realMoney_wheel', 0.5, null),
      frameImage: G.makeImage(0, 0, 'realMoney_wheelFrame', 0.5, null),
    };
  }

  /**
   * Creates godrays that appear behind win text
   */
  _makeShine() {
    this._shine = G.makeImage(0, 0, 'shine_godrays', 0.5, null); // Shiiiiiine
    this._shine.update = () => {
      if (this.visible) {
        this._shine.angle += 0.01;
        if (this._shine.angle === 360) { this._shine.angle = 0; }
      }
    };

    this.addChildAt(this._shine, 0);
  }

  /**
   * Creates darken overlay
   */
  _makeOverlay() {
    this._overlay = game.add.graphics();
    this.add(this._overlay);
    this._overlay.visible = false;
  }

  /**
   * Creates coin shower particle effect
   */
  _makeCoinShower() {
    this._party = new FX_CoinShower();
    this._party.x = 0;
    this._party.y = -game.height / 2;
    this.add(this._party);
  }

  /**
   * Creates text elements to be displayed when the player wins coins
   */
  _makeWinText() {
    this._winRays = G.makeImage(0, 0, 'shine_godrays', 0.5, this);
    game.add.tween(this._winRays).to({ rotation: 2 * Math.PI }, 20000, Phaser.Easing.Linear.None, true, 0, -1, false);
    this._winRays.scale.setTo(1.25);
    this._winRays.blendMode = Phaser.blendModes.SCREEN;
    this._winRays.visible = false;

    this._winGlowBitmap = game.make.bitmapData(200, 200);
    const gradient = this._winGlowBitmap.context.createRadialGradient(100, 100, 20, 100, 100, 100);
    gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
    this._winGlowBitmap.circle(100, 100, 100, gradient);

    this._winGlow = game.add.image(0, 0, this._winGlowBitmap);
    this._winGlow.anchor.setTo(0.5);
    this._winGlow.scale.setTo(2, 0.75);
    this._winGlow.visible = false;
    this.add(this._winGlow);

    this._giftGfx = new G.LabelTextT('', 0, 0, {
      style: 'font-num-realMoneyWheel',
      fontSize: '110px',
      scaleStroke: true,
    }, 0.5, 300);
    this.add(this._giftGfx);
    this._giftGfx.visible = false;

    this._giftGfxPlat = new G.LabelTextT('', 0, 0, 'realMoneyWheel-giftGfxPlat', 0.5, 300);
    this.add(this._giftGfxPlat);
    this._giftGfxPlat.visible = false;

    this._jackpotGroup = new Phaser.Group(G.Game, null);
    this._jackpotTxt = new G.Text(0, 0, OMT.language.getText('JACKPOT!'), {
      style: 'realMoneyWheel-jackpot',
      fontSize: '90px',
    }, 0.5, 400);
    this._jackpotBg = G.makeImage(0, 0, 'realMoney_jackpot_bg', 0.5, null);

    this._jackpotTxtPlat = new G.Text(0, 0, OMT.language.getText('JACKPOT!'), {
      style: 'realMoneyWheel-jackpot',
      fill: 'white',
      fontSize: '90px',
    }, 0.5, 400);
    this._jackpotTxtPlat.visible = false;
    this._jackpotBgPlat = G.makeImage(0, 0, 'realMoney_jackpot_bg_plat', 0.5, null);
    this._jackpotBgPlat.visible = false;

    this._jackpotGroup.add(this._jackpotBg);
    this._jackpotGroup.add(this._jackpotBgPlat);
    this._jackpotGroup.add(this._jackpotTxt);
    this._jackpotGroup.add(this._jackpotTxtPlat);
    this._jackpotGroup.visible = false;
    this._jackpotGroup.y = -125;
    this.add(this._jackpotGroup);
  }

  /**
   * Creates quit warning banner
   */
  _makeQuitBanner() {
    this._bannerGroup = new Phaser.Group(G.Game, null);
    this._banner = new PhaserNineSlice.NineSlice(
      -game.width / 2,
      -125,
      'popups',
      'realMoney_banner',
      game.width,
      250,
      {
        top: 100,
        bottom: 150,
        left: 20,
        right: 47,
      },
    );

    const bannerStr = OMT.language.getText('Are you sure you want to exit? You may not see the %WHEELNAME% for a while.')
      .replace('%WHEELNAME%', OMT.language.getText('Golden Wheel'));
    this._bannerTxt = new G.Text(0, 0, bannerStr, {
      style: 'realMoneyWheel-warning',
      fontSize: '55px',
    }, 0.5, 600, 250, true, 'center');

    this._bannerGroup.add(this._banner);
    this._bannerGroup.add(this._bannerTxt);
    this._bannerGroup.y = -250;
    this.add(this._bannerGroup);
    this._bannerGroup.visible = false;

    this._bannerPlatGroup = new Phaser.Group(G.Game, null);
    this._bannerPlat = new PhaserNineSlice.NineSlice(
      -game.width / 2,
      -125,
      'popups',
      'realMoney_banner_plat',
      game.width,
      250,
      {
        top: 100,
        bottom: 150,
        left: 20,
        right: 47,
      },
    );

    const bannerPlatStr = OMT.language.getText('Are you sure you want to exit? You may not see the %WHEELNAME% for a while.')
      .replace('%WHEELNAME%', OMT.language.getText('Platinum Wheel'));
    this._bannerPlatTxt = new G.Text(0, 0, bannerPlatStr, 'realMoneyWheel-warningPlat', 0.5, 600, 250, true, 'center');

    this._bannerPlatGroup.add(this._bannerPlat);
    this._bannerPlatGroup.add(this._bannerPlatTxt);
    this._bannerPlatGroup.y = -250;
    this.add(this._bannerPlatGroup);
    this._bannerPlatGroup.visible = false;
  }

  /**
   * Creates out of order screen to replace non-functioning wheel
   */
  _makeUnavailableNotice() {
    this._oopsTxt = new G.Text(0, 0, OMT.language.getText('Something went wrong! Please, try again.'), {
      style: 'font-white-blue-out',
      fontSize: '38px',
    }, 0.5, 420, 300, true, 'center');
    this.add(this._oopsTxt);
    this._oopsTxt.visible = false;
  }

  /**
   * Determines prize based off of the chance parameter.
   * Returns the index of the prize
   * @returns {number}
   */
  _determinePrize() {
    // Hijack OMT_PrizeWheel's _determinePrize function to prevent
    // players from exploiting payload wheel spins
    const prize = super._determinePrize();
    if (this._spinId) {
      G.saveState.saveRealMoneySpinResult(this._spinId, prize);
    }

    return prize;
  }

  /**
   * Toggles win text
   * @param {boolean} visible
   * @param {Object} prize
   * @param {boolean} isJackpot is the prize a jackpot prize?
   */
  toggleWinText(visible, prize, isJackpot) {
    const curGiftGfx = this._wheelMode === RMWHEEL_MODES.Platinum ? this._giftGfxPlat : this._giftGfx;

    // Turn on
    if (visible) {
      this._winRays.alpha = 0;
      this._winRays.visible = true;
      game.add.tween(this._winRays).to({ alpha: 1 }, 600, Phaser.Easing.Sinusoidal.Out, true);

      this._winGlow.visible = true;
      this._winGlow.alpha = 0;
      game.add.tween(this._winGlow).to({ alpha: 1 }, 600, Phaser.Easing.Sinusoidal.Out, true);

      const prizeString = G.gift.getLabelString([prize.prize, prize.amount]);
      curGiftGfx.updateText(prizeString);
      curGiftGfx.visible = true;
      curGiftGfx.alpha = 1;
      curGiftGfx.y = 0;
      curGiftGfx.scale.setTo(0);
      this._winGlow.y = 0;
      this.toggleCoinShower(true);

      game.add.tween(curGiftGfx.scale).to({ x: 1, y: 1 }, 600, Phaser.Easing.Elastic.Out, true);

      if (isJackpot) {
        this._lightsMgr.changeLightsMode(LIGHTS_MODES.Jackpot);
        curGiftGfx.y = 30;
        this._winGlow.y = 30;
        this._jackpotGroup.scale.setTo(0);
        this._jackpotGroup.visible = true;
        this._jackpotGroup.alpha = 1;
        game.add.tween(this._jackpotGroup.scale).to({ x: 1, y: 1 }, 600, Phaser.Easing.Elastic.Out, true);
      }
    } else { // Turn off
      this.toggleCoinShower(false);
      game.add.tween(this._winRays).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true)
        .onComplete
        .add(() => {
          this._winRays.visible = false;
        });

      game.add.tween(this._winGlow).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true)
        .onComplete
        .add(() => {
          this._winGlow.visible = false;
        });

      game.add.tween(curGiftGfx).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true)
        .onComplete
        .add(() => {
          curGiftGfx.visible = false;
        });

      game.add.tween(this._jackpotGroup).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true);
    }
  }

  /**
   * Toggles quit warning
   * @param {boolean} visible
   */
  toggleQuitBanner(visible) {
    const currentBannerGroup = this._wheelMode === RMWHEEL_MODES.Platinum
      ? this._bannerPlatGroup
      : this._bannerGroup;

    if (visible) {
      currentBannerGroup.visible = true;
      currentBannerGroup.alpha = 0;
      this._overlay.visible = true;
      this._overlay.alpha = 0;
      game.add.tween(currentBannerGroup).to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out, true);
      game.add.tween(this._overlay).to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out, true);
    } else {
      game.add.tween(currentBannerGroup).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true)
        .onComplete.add(() => {
          currentBannerGroup.visible = false;
        });
      game.add.tween(this._overlay).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true)
        .onComplete.add(() => {
          this._overlay.visible = false;
        });
    }
  }

  /**
   * Turns coin shower on or off
   * @param {boolean} isOn
   */
  toggleCoinShower(isOn) {
    if (isOn) {
      this._party.startTheParty();
    } else {
      this._party.stopTheParty();
    }
  }

  /**
   * Toggles unavailable notice
   * @param {boolean} visible
   */
  toggleUnavailableNotice(visible) {
    this._oopsTxt.visible = visible;
    this._lightsMgr.visible = !visible;
    this._gingy.visible = !visible;
    this._wheelDisplay.visible = !visible;
    this._shine.visible = !visible;
  }

  /**
   * Toggles sparkles for platinum wheel
   * @param {boolean} visible
   */
  _toggleSparkles(visible) {
    this._sparkles.forEach((sparkle, i) => {
      if (!visible) {
        sparkle.stopAnims();
        sparkle.visible = false;
      } else {
        setTimeout(() => {
          sparkle.startFadeIn();
        }, i * 150);
      }
    });
  }

  /**
   *
   * @param {boolean} visible
   */
  _toggleTransitionWheel(visible) {
    this._transitionWheel.angle = this._spinner.angle;
    this._transitionGroup.visible = visible;
  }

  /**
   * Scoots mascot down or up a bit
   * @param {boolean} down if true, move down, else move up
   */
  moveGingy(down) {
    game.add.tween(this._gingy).to({ y: down ? 75 + this._yOffset : this._yOffset }, 250, Phaser.Easing.Sinusoidal.Out, true);
  }

  /**
   * Changes wheel between conversion mode and high value mode
   * @param {RMWHEEL_MODES} mode
   * @param {boolean} showSpeech if false, mascot host doesn't show speech bubble
   * @returns {boolean} whether there was a mode change or not
   */
  changeWheelMode(mode, showSpeech = true) {
    // Validate new mode
    if (mode === this._wheelMode) return false;
    // if (mode !== RMWHEEL_MODES.Conversion && mode !== RMWHEEL_MODES.HighValue) return false; // TODO: validate
    this._wheelMode = mode;

    if (mode !== RMWHEEL_MODES.Platinum) {
      // Change to (super) golden wheel
      this._lightsMgr.changeLightsMode(LIGHTS_MODES.Single);
      this._toggleSparkles(false);

      G.changeTexture(this._wheelOverlay, 'realMoney_wheelFrame');
      this._wheelOverlay.y = 0;
      this._wheelOverlay.scale.setTo(1);

      this._jackpotBgPlat.visible = false;
      this._jackpotTxtPlat.visible = false;
      this._jackpotBg.visible = true;
      this._jackpotBgPlat.visible = true;

      this._lightsMgr.changeLightsStyle(LIGHT_STYLE.NORMAL);
      this._spinner.changeWheelBackground('realMoney_wheel');
      this._pointer.changeAsset('realMoney_prize_wheel_arrow');

      game.add.tween(this._shine).to({ alpha: 0 }, 250, Phaser.Easing.Sinusoidal.In, true);
      game.add.tween(this._wheelDisplay).to({ alpha: 0 }, 250, Phaser.Easing.Sinusoidal.In, true, 500);
      game.add.tween(this._wheelDisplay).to({ x: -500, angle: -180 }, 750, Phaser.Easing.Back.In, true)
        .onComplete.add(() => {
          // Update prize list
          const prizeTable = this._getPrizeTable(this._entryPoint, this._wheelMode);
          const itemDatalist = this._convertPrizeList(prizeTable, _.merge(_.cloneDeep(ITEM_CONFIGS), this._itemConfigOverrides));
          this.initPrizeItems(itemDatalist);

          this._wheelDisplay.x = 500;
          this._wheelDisplay.angle = 180;
          game.add.tween(this._wheelDisplay).to({ alpha: 1 }, 250, Phaser.Easing.Sinusoidal.Out, true, 500);
          game.add.tween(this._wheelDisplay).to({ x: 0, angle: 0 }, 750, Phaser.Easing.Back.Out, true, 500)
            .onComplete.add(() => {
              if (showSpeech) {
                this._gingy.toggleSpeech(true, mode);
              }
              this._lightsMgr.changeLightsMode(LIGHTS_MODES.Off);
            });
          game.add.tween(this._shine).to({ alpha: 1 }, 250, Phaser.Easing.Sinusoidal.In, true, 750);
        });
    } else {
      // Change to platinum wheel
      this._platWheelCenter.visible = true;
      this._transitionFrame.visible = true;
      this._transitionGroup.visible = true;

      // Swoop in platinum wheel center
      game.add.tween(this._platWheelCenter).from({ alpha: 0 }, 500, Phaser.Easing.Quadratic.In, true);
      game.add.tween(this._platWheelCenter).from({ x: -400 }, 1000, Phaser.Easing.Sinusoidal.In, true);
      game.add.tween(this._platWheelCenter).from({ y: -100 }, 1000, Phaser.Easing.Cubic.Out, true)
        .onComplete.add(() => {
          // Start transformation
          this._lightsMgr.changeLightsStyle(LIGHT_STYLE.PLATINUM);
          this._floodLight.visible = true;
          this._floodLight.alpha = 0;
          game.add.tween(this._floodLight).to({ alpha: 1 }, 1000, Phaser.Easing.Linear.None, true);
          game.add.tween(this._wheelDisplay).to({ y: 40 }, 1000, Phaser.Easing.Cubic.Out, true);
          game.add.tween(this._transitionGroup).to({ y: 40 }, 1000, Phaser.Easing.Cubic.Out, true);
          game.add.tween(this._platWheelCenter).to({ y: 40 }, 1000, Phaser.Easing.Cubic.Out, true)
            .onUpdateCallback(this._updateMask.bind(this))
            .onComplete.add(() => this._onMaskComplete(showSpeech));
        });

      this._jackpotBgPlat.visible = true;
      this._jackpotTxtPlat.visible = true;
      this._jackpotBg.visible = false;
      this._jackpotTxt.visible = false;

      this._toggleTransitionWheel(true);
      this._toggleSparkles(true);
    }

    return true;
  }

  /**
   * Updates transition wheel mask during the wheel's trnsformation from golden to platinum
   * @param {Phaser.Tween} tween
   * @param {number} progress
   */
  _updateMask(tween, progress) {
    this._transitionMask.clear();
    this._transitionMask.beginFill(0xFF0000, 0.5);
    this._transitionMask.drawCircle(0, 0, DEFAULT_CONFIG.maxTransitionMaskRadius * progress);
    this._transitionMask.endFill();
  }

  /**
   * Runs when the wheel has completed it transformation from golden to platinum
   * @param {boolean} showSpeech
   */
  _onMaskComplete(showSpeech) {
    // Update prize list
    const prizeTable = this._getPrizeTable(this._entryPoint, this._wheelMode);
    const itemDatalist = this._convertPrizeList(prizeTable, _.merge(_.cloneDeep(ITEM_CONFIGS), this._itemConfigOverrides));
    this.initPrizeItems(itemDatalist);

    this._lightsMgr.changeLightsStyle(LIGHT_STYLE.PLATINUM);

    // Change assets to platinum versions
    this._spinner.changeWheelBackground('realMoney_wheel_plat');
    this._pointer.changeAsset('realMoney_prize_wheel_arrow_plat');
    G.changeTexture(this._wheelOverlay, 'realMoney_wheelFrame_plat');
    this._transitionFrame.visible = false;
    this._wheelDisplay.y = 40;
    this._wheelOverlay.y = -20;
    this._wheelOverlay.scale.setTo(0.95);
    this._pointer.y = -225;

    game.add.tween(this._transitionGroup).to({ alpha: 0 }, 500, Phaser.Easing.Linear.None, true)
      .onComplete.add(() => {
        this._transitionGroup.visible = false;
        if (showSpeech) {
          this._gingy.toggleSpeech(true, RMWHEEL_MODES.Platinum);
        }
      });
  }

  /**
   * Determine config of prize item text
   * @param {Object} itemConfigs
   * @param {boolean} isSpecial
   */
  _getItemConfig(itemConfigs, isSpecial) {
    if (this._wheelMode === RMWHEEL_MODES.Platinum) {
      return isSpecial ? itemConfigs.COINS_SPECIAL_PLAT : itemConfigs.COINS_PLAT;
    }
    return super._getItemConfig(itemConfigs, isSpecial);
  }

  destroy() {
    super.destroy();
    this._winGlowBitmap.destroy();
    this._floodLightBitmap.destroy();
    this._party.destroy();
    this._onResizeSB.detach();
    this.removeAll(true);
    if (this._floodLightGradientPack) this._floodLightGradientPack.bitmap.destroy();
  }


  /**
   * Sets spinId
   * @param {string} id
   */
  set spinId(id) {
    this._spinId = id;
  }

  get isConversion() {
    return this._wheelMode === RMWHEEL_MODES.Conversion;
  }

  get gingy() {
    return this._gingy;
  }

  get lights() {
    return this._lightsMgr;
  }

  get giftGfx() {
    return this._wheelMode === RMWHEEL_MODES.Platinum ? this._giftGfxPlat : this._giftGfx;
  }
}
