G.WorldSidePanelRibbon = function(x, y, config) {
  Phaser.Group.call(this, game);
  this.position.setTo(x, y);

  this.ribbon = G.makeImage(0, 10, config.ribbon, [0.5, 0], this);
  this.ribbon.width = 350;

  this.text = new G.Text(0, 45, config.text, {
    style: "font-white",
    fontSize: 50,
  }, 0.5, 260);
  this.add(this.text);

};

G.WorldSidePanelRibbon.prototype = Object.create(Phaser.Group.prototype);

G.WorldSidePanelRibbon.prototype.getContentHeight = function() {
  return 120;
};

G.WorldSidePanelRibbon.prototype.show = function(immediate, delay) {
  G.stopTweens(this.ribbon.scale);

  if (immediate) {
    this.scale.setTo(1);
  } else {
    game.add.tween(this.scale)
      .to({x: 1, y: 1}, 500, Phaser.Easing.Custom.SoftBounce, true, delay || 0);
  }
};

G.WorldSidePanelRibbon.prototype.hide = function(immediate, delay) {
  G.stopTweens(this.ribbon.scale);

  if (immediate) {
    this.scale.setTo(0);
  } else {
    game.add.tween(this.scale)
      .to({x: 0, y: 0}, 500, Phaser.Easing.Custom.SoftBounce, true, delay || 0);
  }
};
