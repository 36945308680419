import { TOKEN_TYPES, EDITOR_SYMBOLS } from '../BoardConstants';
import { TokenLayer_Spreading } from './TokenLayer_Spreading';
import { BoardToken_SpreadingJam } from '../Tokens/BoardToken_SpreadingJam';

/**
 * TokenLayer for BoardToken_SpreadingJam instances.
 */
export class TokenLayer_SpreadingJam extends TokenLayer_Spreading {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    super(board, {
      constructor: BoardToken_SpreadingJam,
      maxHp: 3,
      hpToken: true,
      editorSymbol: EDITOR_SYMBOLS.JAM,
      collectableType: TOKEN_TYPES.JAM,
      blockMove: true,
      blockMatch: true,
      stopHitPropagation: true,
      tokenHitEventId: 'hitJam',
    }, true);
  }
}
