/* eslint-disable no-restricted-globals */

/**
 * get sum of inputted array values
 * @param {Array} array
 */
function getArraySum(array) {
  let sum = 0;
  for (const value of array) {
    if (isNaN(value)) continue;
    sum += value;
  }
  return sum;
}

/**
 * data comparison functions referenced in SaveStateKeyMappings
 */
export const COMPARE_METHODS = {
  GREATER_NUMERIC_VALUE: (a, b) => {
    if (b > a) return b;
    return a;
  },
  GREATER_ARRAY_SUM: (a, b) => {
    if (getArraySum(b) > getArraySum(a)) return b;
    return a;
  },
};
