/* eslint-disable no-unused-vars */
import { TournamentSocialDisplayBase } from '../../base/Tournament/TournamentSocialDisplayBase';
import { TournamentCompetitorScoreView } from './TournamentCompetitorScoreView';
import { TournamentCompetitorTopScoreView } from './TournamentCompetitorTopScoreView';

const BG_POS = { x: -190, y: 34 };

const COMPETITOR_POS = { x: 105, y: 71 };

const VS_ICON_IMAGE = 'competitor_vs_icon';
const VS_ICON_ANCHOR = [0.5, 0.5];
const VS_ICON_POS = { x: 0, y: 33 };

/**
 * class for showing / managing tournament social activity during gameplay
 */
export class TournamentSocialDisplay extends TournamentSocialDisplayBase {
  /**
   * init the bg image
   */
  _initBg() {
    super._initBg();
    this._bgImage = G.makeImage(BG_POS.x, BG_POS.y, 'competitor_vs_bar');
    this.addChild(this._bgImage);
  }

  /**
   * init the VS icon overlay
   */
  _initVsIcon() {
    super._initVsIcon();
    this._vsIcon = G.makeImage(VS_ICON_POS.x, VS_ICON_POS.y, VS_ICON_IMAGE, VS_ICON_ANCHOR);
    this.addChild(this._vsIcon);
  }

  /**
   * show the next competitor view
   * @param {{imaage:string, rank:number, score:number}} entryData
   * @returns {Promise}
   */
  _showNextCompetitorView(entryData) {
    const competitorView = super._showNextCompetitorView({
      Class: TournamentCompetitorScoreView,
      x: COMPETITOR_POS.x,
      y: COMPETITOR_POS.y,
      entryData,
    });
    this.addChildAt(competitorView, 1);
    this._competitorView = competitorView;
  }

  /**
   * show the view for overtaking #1 on leaderboard
   */
  _showTopScoreView() {
    const topScoreView = super._showTopScoreView({
      Class: TournamentCompetitorTopScoreView,
      x: COMPETITOR_POS.x,
      y: COMPETITOR_POS.y,
    });
    this.addChildAt(topScoreView, 1);
    this._topScoreView = topScoreView;
    this._topScoreView.animateIn();

    this._hideVsIcon();
  }
}
