/* eslint-disable func-names */
/**
 * config = {
 *  x: number,
 *  y: number
 * }
 */

import { RMWHEEL_EPS } from './SpinningWheels/RealMoneyWheel/rmWheelEnums';
import { TextTimer } from './TextTimer';

G.UI_DailyIcon = function (config) {
  this.active = G.saveState.getLastPassedLevelNr() >= G.featureUnlock.unlockLevels.dailyWheel;
  this.tutorial = false; // this.active && !G.saveState.data.sawDailyTut;

  this.state = game.state.getCurrentState();

  Phaser.Group.call(this, game);
  this.dailyIconContainer = new Phaser.Group(game);

  this._config = config || {};
  this.ignorePosition = !(this._config.x || this._config.y);
  if (!this.ignorePosition) {
    this.x = Math.floor(this._config.x);
    this.y = Math.floor(this._config.y);
  }

  if (G.IAP) {
    this._addIcon();
    this.addGlow();
    this.addTimersAndFreeText();
    this.addUnlockTxt();
  }

  // Show appropriate timers and text based on activity
  if (this.active) {
    this.noAdTimer.active = true;
    this.noAdTimer.visible = true;
    if (this.withAdTimer) {
      this.withAdTimer.active = true;
    }
    this.freeText.visible = true;
  } else {
    this.unlockTxt.visible = true;
  }

  this.notificationBubble = new G.NotificationBubble(30, -30);
  this.dailyIconContainer.add(this.notificationBubble);

  this.add(this.dailyIconContainer);
  this.update();

  if (this.tutorial) {
    this._tutHand = G.makeImage(0, 20, 'tut_hand', 0, this);
    game.add.tween(this._tutHand).to({ x: 20, y: 50 }, 300, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  }

  if (!this.ignorePosition) {
    G.sb('onScreenResize').add(this.onResize, this);
    this.onResize();
  }
  G.sb('pauseAllUpdate').add(() => {
    this._skipUpdate = true;
  });
  G.sb('resumeAllUpdate').add(() => {
    this._skipUpdate = false;
  });

  OMT.platformTracking.logFTUEvent('FTUX_SpinWheelUnlock');
};

G.UI_DailyIcon.prototype = Object.create(Phaser.Group.prototype);

G.UI_DailyIcon.prototype.update = function () {
  if (this._skipUpdate) { return; }
  if (this.active) {
    this.unlockTxt.visible = false;
    if (!game.incentivised() || OMT.feature.prizeWheelFreeSpins()) {
      if (OMT.feature.prizeWheelIsLimited()) {
        this.freeText.visible = G.saveState.data.freeSpin && !G.saveState.isPrizeWheelOnCooldown();
        if (this.icon) this.icon.adIcon.visible = !this.freeText.visible && !G.saveState.isPrizeWheelOnCooldown();
      } else {
        this.freeText.visible = G.saveState.data.freeSpin;
        if (this.icon) this.icon.adIcon.visible = !this.freeText.visible;
      }
    } else if (OMT.feature.prizeWheelIsLimited()) {
      this.freeText.visible = false;
      if (this.icon) this.icon.adIcon.visible = !G.saveState.isPrizeWheelOnCooldown();
    } else {
      this.freeText.visible = false;
      if (this.icon) this.icon.adIcon.visible = true;
    }

    if (this.notificationBubble) {
      this.notificationBubble.setImportant(this.freeText.visible);
    }

    if (game.incentivised()) {
      this.noAdTimer.visible = false;
      if (OMT.feature.prizeWheelIsLimited()) {
        this.withAdTimer.visible = G.saveState.isPrizeWheelOnCooldown();
        this.iconDarkOverlay.visible = this.withAdTimer.visible;
      }
    } else if (OMT.feature.prizeWheelFreeSpins()) {
      this.noAdTimer.visible = !this.freeText.visible;
    } else {
      this.noAdTimer.visible = !G.saveState.data.freeSpin;
    }
  } else {
    if (this.notificationBubble) {
      this.notificationBubble.setImportant(false);
    }
    if (this.icon) this.icon.adIcon.visible = true; this.icon.adIcon.visible = false;
  }
};

G.UI_DailyIcon.prototype.onResize = function () {
  const center = game.world.bounds.x + Math.floor(game.width * 0.5);
  this.x = center + G.OMTsettings.elements.dailyIcon.xCenterOffset;
};

G.UI_DailyIcon.prototype._addIcon = function () {
  this.icon = new G.Button(0, 20, 'new_wheel_menu', function () {
    // Disable click under certain circumstances
    if (!this.active) return;
    if (!game.incentivised() && !G.saveState.data.freeSpin) return;
    if (game.incentivised()
      && OMT.feature.prizeWheelIsLimited()
      && G.saveState.isPrizeWheelOnCooldown()) {
      return;
    }

    // Roll for a chance to show replacement wheel first if possible
    // (IAP env + real money replacement wheel on +
    //  player never bought a spin before (is conversion) +
    //  real money wheel not on cooldown)
    let rollSuccessful = false;

    // if (G.IAP && DDNA.tracking.getDataCapture().getRealMoneyWheelConversionStatus()
    if (OMT.feature.getFeatureRealMoneyReplacementWheel()
      && G.saveState.getRealMoneyWheelOnCoolDown() <= 0) {
      const currentAppearanceChance = G.json.settings.realMoneyPrizeWheel.appearanceChance[0];
      rollSuccessful = Math.random() < currentAppearanceChance / 100;
    }

    if (rollSuccessful) {
      G.sb('pushWindow').dispatch(['realMoneyWheel', {
        afterQuit: this._showDailyWheel.bind(this), // go back to regular wheel after closing
        entryPoint: RMWHEEL_EPS.Replacement,
        freeSpin: false,
        worldState: true,
      }], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
    } else {
      this._showDailyWheel();
    }
    G.sb('hideMainHighscorePanel').dispatch();

    if (this.tutorial) {
      if (this._tutHand) {
        this._tutHand.destroy();
      }
      G.saveState.data.sawDailyTut = true;
      G.saveState.save();
      G.sb('onWindowClosed').addOnce(() => {
        const state = game.state.getCurrentState();
        if (state.lvlTutHand) {
          game.add.tween(state.lvlTutHand).to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.Out, true);
        }
      });
    }
  }, this);

  this.icon.adIcon = G.makeImage(0, 40, 'video_wheel_menu', 0.5, this.icon);
  game.add.tween(this.icon.adIcon.scale)
    .to({ x: 1.2, y: 1.2 }, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  this.icon.adIcon.angle = -4;
  game.add.tween(this.icon.adIcon)
    .to({ angle: 4 }, 2000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
  this.dailyIconContainer.add(this.icon);

  if (OMT.feature.prizeWheelIsLimited()) {
    this.iconDarkOverlay = G.makeImage(0, 20, 'new_wheel_menu', 0.5, this.dailyIconContainer);
    this.iconDarkOverlay.tint = 0x000000;
    this.iconDarkOverlay.alpha = 0.5;
  }

  if (!game.incentivised()) {
    if (this.icon.adIcon) {
      this.icon.adIcon.renderable = false;
    }
    if (this.iconDarkOverlay) {
      this.iconDarkOverlay.renderable = false;
    }
  }
};

G.UI_DailyIcon.prototype._showDailyWheel = function () {
  G.sb('pushWindow').dispatch(['dailyWheel', {
    worldState: true,
    asMoreMoney: false,
  }], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
};

G.UI_DailyIcon.prototype.onClick = function (extraArgs) {
  if (!game.incentivised() && !G.saveState.data.freeSpin) return;
  const dailyWheelArgs = {
    worldState: true,
    asMoreMoney: false,
  };
  if (extraArgs) {
    _.forEach(extraArgs, (value, key) => {
      dailyWheelArgs[key] = value;
    });
  }
  G.sb('pushWindow').dispatch(['dailyWheel', dailyWheelArgs], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
  G.sb('hideMainHighscorePanel').dispatch();
};

G.UI_DailyIcon.prototype.addGlow = function () {
  this.glow = G.makeImage(0, 20, 'popup_lighht', 0.5, this);
  this.glow.alpha = 0.5;
  this.glow.scale.setTo(0.5);
  this.glow.blendMode = 1;
  this.glow.update = function () { this.angle += G.deltaTime; };
  this.sendToBack(this.glow);
};

G.UI_DailyIcon.prototype.addTimersAndFreeText = function () {
  // Spin refill timer for non-ad env
  const noAdTimerConfig = G.OMTsettings.elements.dailyIcon.timer;
  this.noAdTimer = new TextTimer({
    x: noAdTimerConfig.x,
    y: noAdTimerConfig.y + 20,
    style: {
      style: 'font-num-blue',
      fontSize: '30px',
    },
    anchor: 0.5,
    maxWidth: 130,
  });
  this.noAdTimer.setSecLeft((G.saveState.data.lastDaily + 86400000 - Date.now()) / 1000);
  this.noAdTimer.active = false;
  this.noAdTimer.visible = false;
  this.dailyIconContainer.add(this.noAdTimer);

  G.sb('onDailyFreeSpinGain').add(function () {
    this.noAdTimer.setSecLeft((G.saveState.data.lastDaily + 86400000 - Date.now()) / 1000);
  }, this);

  // Spin refill timer for ad env
  if (game.incentivised() && OMT.feature.prizeWheelIsLimited()) {
    const withAdTimerConfig = G.OMTsettings.elements.dailyIcon.withAdTimer;
    this.withAdTimer = new TextTimer({
      x: withAdTimerConfig.x,
      y: withAdTimerConfig.y + 20,
      style: {
        style: 'font-num-blue',
        fontSize: '30px',
      },
      anchor: 0.5,
      maxWidth: 130,
    });
    const cooldownRemaining = Math.floor(G.saveState.getUserCooldownRemaining('prizeWheelSpins', '') / 1000);
    this.withAdTimer.setSecLeft(cooldownRemaining);
    this.withAdTimer.active = false;
    this.withAdTimer.visible = false;
    this.iconDarkOverlay.visible = false;
    this.dailyIconContainer.add(this.withAdTimer);

    G.sb('wheelSpin').add(() => {
      const cooldown = Math.floor(G.saveState.getUserCooldownRemaining('prizeWheelSpins', '') / 1000);
      this.withAdTimer.setSecLeft(cooldown);
    }, this);
  }

  this.freeText = new G.Text(0, 20, OMT.language.getText('Free spin!'), 'ui-dailyIcon', 0.5, 150, 150, true, 'center');
  this.freeText.lineSpacing = -15;
  this.freeText.setShadow(0, 0, 'black', 3);
  this.freeText.visible = false;
  this.dailyIconContainer.add(this.freeText);

  game.add.tween(this.freeText.scale).to({ x: 0.9, y: 0.9 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
};

G.UI_DailyIcon.prototype.addUnlockTxt = function () {
  this.unlockTxt = new G.Text(0, 20, OMT.language.getText('Unlock at Level X').replace('X', G.featureUnlock.unlockLevels.dailyWheel + 1), 'ui-dailyIcon', 0.5, 150, 150, true, 'center');
  this.unlockTxt.lineSpacing = -15;
  this.unlockTxt.setShadow(0, 0, 'black', 3);
  this.unlockTxt.visible = false;
  this.dailyIconContainer.add(this.unlockTxt);

  game.add.tween(this.unlockTxt.scale).to({ x: 0.9, y: 0.9 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
};
