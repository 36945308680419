/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
import OMT_TweenUtils from '../../Utils/Animation/OMT_TweenUtils';
import OMT_StackManager from '../../Utils/OMT_StackManager';
import OMT_VILLAINS from '../OMT_Villains';

/**
 * This class is responsible for puttine a smile on villains' faces
 * It can create ha-ha animation using pooling
 */
export default class VillainsLaugh extends Phaser.Group {
  constructor(text, angle = 2) {
    super(game);
    this._baseTextAngle = angle;

    this.config = { text, angle };

    this.deadObjects = [];
  }

  /**
   * Add a animated text based on config
   */
  async addAnimatedText() {
    const { text, angle } = this.config;
    let laugh = this._getFreeLaugh();
    if (!laugh) {
      laugh = new G.Text(
        0,
        0,
        text,
        OMT_VILLAINS.getPrefixedName('villain_1'),
        0.5,
      );
    }
    this._laugh = laugh;
    this.add(laugh);

    const stack = OMT_StackManager.getFreeStack();
    stack.addEvent(() => {
      laugh.alpha = 0;
      laugh.scale.setTo(0.01);
      laugh.angle = angle * 1.2;
    });
    stack.addPromise(() =>
      OMT_TweenUtils.tweenMultiple({
        object: laugh,
        duration: 250,
        props: {
          scale: 1,
          alpha: 1,
        },
      }),
    );
    const subStack1 = OMT_StackManager.getFreeStack();
    subStack1.addPromise(() => {
      const textHeight = laugh.height;
      const distance = textHeight * 3;
      let newX = distance * Math.cos((Math.abs(angle) * Math.PI) / 180);
      if (angle < 0) {
        newX *= -1;
      }
      const newY = -distance * Math.sin((Math.abs(angle) * Math.PI) / 180);

      return OMT_TweenUtils.tweenMultiple({
        object: laugh,
        duration: 2000,
        props: {
          x: newX,
          y: newY,
          scale: 1.5,
          alpha: 0,
        },
      });
    });
    const subStack2 = OMT_StackManager.getFreeStack();
    subStack2.addEvent(() => {
      game.add
        .tween(laugh)
        .to(
          { angle: angle * 0.8 },
          500,
          Phaser.Easing.Sinusoidal.InOut,
          true,
          0,
          -1,
          true,
        );
    });
    stack.addParallel([subStack1, subStack2]);
    stack.addEvent(() => {
      laugh.position.setTo(0);
      this.deadObjects.push(laugh);
    });

    return stack.run();
  }

  /**
   * Returns a free laugh object to be used again
   */
  _getFreeLaugh() {
    if (this.deadObjects.length === 0) return undefined;
    return this.deadObjects.pop();
  }

  /**
   * Starts the spawner, creating one text after another
   * @param {number} spawnDelay
   */
  startLaughSpawner(spawnDelay = 1000) {
    const stack = OMT_StackManager.getFreeStack();
    stack.repeat();
    stack.addEvent(() => {
      this.addAnimatedText();
    });
    stack.wait(spawnDelay);
    stack.run();
    this._spawner = stack;
  }

  /**
   * Stop the laugh spawner
   * This function is called to optimize performance when the villains are invisible
   */
  stopLaughSpawner() {
    const { _spawner } = this;
    if (!_spawner) return;
    _spawner.repeat(0);
  }
}
