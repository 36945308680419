/* eslint-disable func-names */
/* eslint-disable no-unused-vars */

import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

export class Window_HighscoreBeaten extends Window {
  /**
   * cosntructor
   * @param {Object} parent
   * @param {Object} user2
   */
  constructor(parent, user2) {
    super(parent);

    const user1 = OMT.envData.settings.user;

    this._initLayout(user1, user2);
    this._initButtons(user1, user2);
  }

  /**
   * init layout
   * @param {Object} user1
   * @param {Object} user2
   */
  _initLayout(user1, user2) {
    // background
    this.addBackground('popup_background_2');

    // title
    this._titleTxt = new G.Text(0, -272, OMT.language.getText('Highscore Beaten!'), G.OMTsettings.elements.Window_highscoreBeaten.titleTxt.style, 0.5, 420);
    this.add(this._titleTxt);

    // avatars
    this._avatar1 = G.makeExtImage(-103, -134, user1.image || user1.avatar, null, 0, this, false, function () {
      this.width = 98;
      this.height = 98;
    });
    this._avatar2 = G.makeExtImage(31, -75, user2.image || user2.avatar, null, 0, this, false, function () {
      this.width = 100;
      this.height = 100;
    });

    // event image
    this._img = G.makeImage(0, -50, 'Beat_Overall_Highscore_asset', 0.5, this);
  }

  /**
   * init buttons
   * @param {Object} user1
   * @param {Object} user2
   */
  _initButtons(user1, user2) {
    this.addCloseButton();

    const onClick = () => {
      OMT.social.sendHighscoreBeatMsg(user1, user2, (success) => {});
      this.closeWindow();
    };
    this._continueBtn = new OMT_UI_SquareButton(G.OMTsettings.elements.highscoreBeaten.continueBtn.x, G.OMTsettings.elements.highscoreBeaten.continueBtn.y, {
      button: {
        tint: BUTTONCOLOURS.blue,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Challenge (name)').replace('(name)', user2.name),
        textStyle: {
          style: 'font-white',
          fontSize: '40px',
          lineSpacing: -15,
        },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
        pulse: 1.1,
      },
    });
    this.registerButtons(this._continueBtn);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.highscoreBeaten = Window_HighscoreBeaten;
