/* eslint-disable no-unused-vars */
let _instance = null;

const VIDEO_BORDER_SIZE = 30;
const DEFAULT_VIDEO_SIZE = 640;

/**
 * Class for displaying cross promo video ads
 */
export class OMT_CrossPromoVideoPlayer {
  /**
   * get singleton instance
   * @returns {OMT_CrossPromoVideoPlayer}
   */
  static getInstance() {
    if (_instance === null) _instance = new OMT_CrossPromoVideoPlayer();
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    this._placement = null;
    this._onSuccess = null;
    this._onError = null;

    // set video element events
    const videoElement = document.getElementById('xpromoVideo');
    videoElement.addEventListener('ended', () => { this.onVideoEnded(); }, false);
    videoElement.addEventListener('error', (error) => { this.onVideoError(error); }, false);
    videoElement.addEventListener('click', () => { this.onVideoClicked(); }, false);

    G.sb('onScreenResize').add(() => { this.onScreenResize(); });
  }

  /**
   * attempt to play a video ad from the placement
   * @param {string} trackingName
   * @param {Object} placement
   * @param {Function} onSuccess optional callback
   * @param {Function} onError optional callback
   */
  playVideoAd(trackingName, placement, onSuccess = null, onError = null) {
    const videoAssetData = OMT.crossPromo.getVideoDataForPromo(placement, OMT.envData.settings.env.lang);
    if (!videoAssetData) return; // no video found

    this._placement = placement;
    this._onSuccess = onSuccess;
    this._onError = onError;

    const trackingEventName = OMT.platformTracking.Events.XPromoVisible.replace('%id%', trackingName);
    OMT.platformTracking.logEvent(trackingEventName);

    const overlayElement = document.getElementById('xpromoOverlay');
    overlayElement.style.visibility = 'visible';
    this.onScreenResize();

    const videoElement = document.getElementById('xpromoVideo');
    videoElement.setAttribute('src', videoAssetData.src);
    videoElement.play();

    OMT.crossPromo.loadCrossPromoData(); // reload crosspromo data
  }

  /**
   * called when the video ad has completed
   */
  onVideoEnded() {
    const overlayElement = document.getElementById('xpromoOverlay');
    overlayElement.style.visibility = 'hidden';

    if (this._onSuccess) this._onSuccess(true);
    this._onSuccess = this._onError = null;
  }

  /**
   * called when a error playing a video occurred
   * @param {string} error
   */
  onVideoError(error) {
    const overlayElement = document.getElementById('xpromoOverlay');
    overlayElement.style.visibility = 'hidden';

    const videoElement = document.getElementById('xpromoVideo');
    videoElement.pause();

    if (this._onError) this._onError(false);
    this._onSuccess = this._onError = null;
  }

  /**
   * called when the video ad is clicked
   * @returns {Promise}
   */
  async onVideoClicked() {
    const videoElement = document.getElementById('xpromoVideo');
    videoElement.pause();
    const success = await OMT.crossPromo.switchToAnotherGame(this._placement);
    if (!success) videoElement.play();
  }

  /**
   * called on a screen resize
   */
  onScreenResize() {
    const videoContainer = document.getElementById('xpromoVideoContainer');
    const borderSize = VIDEO_BORDER_SIZE;
    const videoSize = Math.floor(Math.min(DEFAULT_VIDEO_SIZE, window.innerHeight, window.innerWidth)) - borderSize;
    videoContainer.style.width = `${videoSize}px`;
    videoContainer.style.height = `${videoSize}px`;
  }
}
