import OMT_VILLAINS from '../OMT_Villains';

/**
 * Base villains class
 */
export default class VillainsBaseClass {
  /**
   * Get objects based on config
   * @param {object} config
   * @param {number} config.index Object index
   * @param {number} config.subIndex Object subIndex (if any)
   * @param {string} config.type Object type
   * @returns {any[]} objects
   */
  getObjects(config = {}, mapFunc) {
    this.indexObjects();

    if (!mapFunc) {
      mapFunc = (object) => object.object;
    }

    return this.objects
      .filter((object) => {
        const comparison = [];
        for (const [objectKey, objectValue] of Object.entries(object)) {
          if (Object.keys(config).includes(objectKey)) {
            comparison.push(objectValue === config[objectKey]);
          }
        }
        return comparison.every((c) => c);
      })
      .map(mapFunc);
  }

  /**
   * Returns the class container for that object, if any
   */
  getClassContainer() {
    this.indexObjects();

    return this.getObjects({
      type: 'group',
    })[0];
  }

  /**
   * Index objects based on their given config, to be fetched later on
   */
  indexObjects() {
    this.objects = [];

    if (!this.objectPrefix) {
      return;
    }

    for (const [key, value] of Object.entries(this)) {
      if (key.startsWith(this.objectPrefix)) {
        value.parent = this;
        this.objects.push(value);
      }
    }
  }

  /**
   * Register all events required by the class
   */
  registerEvents() {
    if (!this.signalList) return;
    this.signals = [];

    for (const [key, value] of Object.entries(this.signalList)) {
      this.signals.push(
        G.sb(OMT_VILLAINS.getInGameState(key)).addOnce(value, this),
      );
    }
  }

  /**
   * Detaches signals if any, additional functionality can be added to this
   */
  destroy() {
    const { signals, objects } = this;
    if (signals) {
      for (let i = 0; i < signals.length; i++) {
        const signal = signals[i];
        if (signal.detach) {
          signal.detach();
        }
        signals[i] = null;
      }
    }

    if (objects) {
      this.objects = null;
    }
  }

  /**
   * Add the class container or all its objects to the given parent
   * @param {any} parent The parent to add the class to
   */
  addTo(parent) {
    this.parent = parent;

    const classContainer = this.getClassContainer();

    if (!classContainer) {
      const objects = this.getObjects(undefined);
      objects.forEach((object) => parent.add(object));
    } else {
      parent.add(classContainer);
    }
  }

  /**
   * Randomize the values like angle and duration for more organic villain movement
   * @param {any} value The value to randomize
   * @param {*} diffRatio A ratio between 0-Infinity to get the random value 0 = the same number, Infinity = Totally random
   */
  randomizeValue(value, diffRatio) {
    const diff = value * diffRatio;
    return value + diff * (-1 + 2 * Math.random());
  }

  /**
   * Gets the object with the highest height from the class
   * @param {object} config Object containing additional parameters to filter out the objects
   */
  getTallestObject(config) {
    return this.getObjects(config).sort((a, b) => b.height - a.height)[0];
  }
}
