import ShareMsgBase from './A_G.ShareMsgBase';

class MsgHelpClosed extends ShareMsgBase {
  /**
   * The message that is sent when you're in a context with friend but you don't send anything but you closed the game
   * @param {string} userAvatar
   * @param {function} onAllImagesReadyCallback
   */
  constructor(userAvatar, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    this.avatar1 = this._addExtImage(278, 117, userAvatar, 0, 132);
    this.bg = this._addExtImage(0, 0, 'img/Msg_Help_App_Closed.png', 0);

    const currentMaxLvl = G.saveState.getLastPassedLevelNr() + 1;

    this.needHelpTxt = new G.Text(334, 49,
      OMT.language.getText(G.json.shareMessageText.sendClosedHelpReq[0]).replace('%Level%', currentMaxLvl),
      'msg-help', 0.5, 600);
    this.add(this.needHelpTxt);

    this._objectLoadComplete();
  }
}

G.MsgHelpClosed = MsgHelpClosed;
