import UI_GoalPanelPointsBase from '../base/UI_GoalPanelPointsBase';

export default class UI_GoalPanelPoints extends UI_GoalPanelPointsBase {
  _drawEverything(gameMode) {
    const { label } = this._getTextStyles(gameMode);

    this._labelTxt = new G.Text(0, 0, `/${G.lvlData.goal[1]}`, label, [0, 0.5], 150);
    super._drawEverything(gameMode);
    this.add(this._labelTxt);
    this.add(this._pointsCounter);
  }

  update() {
    this.centerTexts();
    super.update();
  }

  /**
   * Repositions layout
   */
  _onScreenResize() {
    super._onScreenResize();
    this._pointsCounter.anchor.x = 1;
    this._labelTxt.y = this._pointsCounter.y = 0;

    this._pointsCounter.updateText();
    this._pointsCounter.updateCache();

    this.centerTexts();
  }

  /**
   * Keeps it centered
   */
  centerTexts() {
    const xx = (this._pointsCounter.width + this._labelTxt.width) * -0.5;
    this._pointsCounter.x = xx + this._pointsCounter.width;
    this._labelTxt.x = xx + this._pointsCounter.width;
  }
}
