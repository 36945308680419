const LABEL_PADDING_X = 10;

/**
 * class for standard booster icons for targeted offers
 */
export class TargetedOffer_BoosterIcon extends Phaser.Group {
  /**
   * constructor
   * @param {string} boosterID
   * @param {number} boosterCount
   */
  constructor(boosterID, boosterCount) {
    super(game);

    this._drawBoosterImage(boosterID);
    this._drawBoosterLabel(boosterCount);
  }

  /**
   * init / draw the booster image
   * @param {string} boosterID
   */
  _drawBoosterImage(boosterID) {
    const boosterNum = boosterID.split('#')[1];
    this._img = G.makeImage(0, 0, `ui_booster_${boosterNum}`, 0.5);
    this.addChild(this._img);
  }

  /**
   * init / draw the booster label
   * @param {number} boosterCount
   */
  _drawBoosterLabel(boosterCount) {
    const textX = this._img.width / 2;
    this._label = new G.Text(0, 0, `x${boosterCount}`, 'window_oneTimePopupOffer-boosterTxt', 0.5, 0, 0, 0, 'center');
    this._label.x = textX + (this._label.getBounds().width / 2) + LABEL_PADDING_X;
    this.addChild(this._label);
  }
}

/**
 * class for unlimited lives booster icons
 */
export class TargetedOffer_ULBoosterIcon extends Phaser.Group {
  /**
   * constructor
   * @param {number} infiniteLivesMins
   */
  constructor(infiniteLivesMins) {
    super(game);

    this._drawBoosterImage();
    this._drawBoosterLabel(infiniteLivesMins);
  }

  /**
   * init / draw the booster image
   * @param {string} boosterID
   */
  _drawBoosterImage() {
    this._img = G.makeImage(0, 0, 'heart_unlimited', 0.5);
    this.addChild(this._img);
  }

  /**
   * init / draw the booster label
   * @param {number} infiniteLivesMins
   */
  _drawBoosterLabel(infiniteLivesMins) {
    const infiniteLivesHrs = Math.round((infiniteLivesMins / 60) * 10) / 10;
    const textX = this._img.width / 2;
    this._label = new G.Text(0, 0, `${infiniteLivesHrs}h`, 'window_oneTimePopupOffer-boosterTxt', 0.5, 0, 0, 0, 'center');
    this._label.x = textX + (this._label.getBounds().width / 2) + LABEL_PADDING_X;
    this.addChild(this._label);
  }
}
