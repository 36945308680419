import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getShowGingyAnimation = function getShowGingyAnimation() {
  return { animate: animate };

  function animate(gingy, config, callback) {
    gingy.visible = true;
    const xOffset = OMT.systemInfo.orientation === ORIENTATION.horizontal
      ? -175
      : 0;

    gingy._sideGingy.update = function() {
      if (this.hugLeft) {
        this.x = game.width * -0.5 + config.gingyOffset.x + xOffset;
      }
    };

    gingy.gingyTxtGroup.x = -1000;
    game.add
      .tween(gingy.gingyTxtGroup)
      .to(
        { x: config.gingyTitleOffset.x },
        1000,
        Phaser.Easing.Elastic.Out,
        true
      )
      .onComplete.add(function() {
        if (gingy._sideGingy_subtxt) {
          game.add
            .tween(gingy._sideGingy_subtxt)
            .to({ y: 105 }, 600, Phaser.Easing.Exponential.Out, true);
        }
        if(callback) callback();
      }, this);
  
      gingy._sideGingy.x = -game.width;
    game.add
      .tween(gingy._sideGingy)
      .to(
        { x: game.width * -0.5 + config.gingyOffset.x + xOffset},
        600,
        Phaser.Easing.Exponential.Out,
        true
      )
      .onComplete.add(function() {
        gingy._sideGingy.hugLeft = true;
      }, this);
  };
}
