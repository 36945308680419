/*
     _____                  _____ _        _       _    _ _   _ _
  / ____|                / ____| |      | |     | |  | | | (_) |
 | (___   __ ___   _____| (___ | |_ __ _| |_ ___| |  | | |_ _| |___
  \___ \ / _` \ \ / / _ \\___ \| __/ _` | __/ _ \ |  | | __| | / __|
  ____) | (_| |\ V /  __/____) | || (_| | ||  __/ |__| | |_| | \__ \
 |_____/ \__,_| \_/ \___|_____/ \__\__,_|\__\___|\____/ \__|_|_|___/

*/

/**
 * Utility Class for the save state
 */
export class SaveStateUtils {
  /**
   * constructor
   */
  constructor() {
    //
  }

  /**
   * compute the reward for the level
   * @param {number} lvlIndex index of level passed
   * @param {number} starsBefore previous star amount
   * @param {number} starsAfter current star amount
   * @param {number} [movesLeft] the number of moves left
   * @param {string} [levelDifficulty] The level type of the level
   */
  static computeReward(lvlIndex, starsBefore, starsAfter, movesLeft, levelDifficulty) {
    const levelCompleteRewardConfig = G.json.settings.levelCompleteReward;
    let coinReward = 0;
    if (levelCompleteRewardConfig.active) {
      coinReward = SaveStateUtils.computeRewardByLevelComplete(lvlIndex, starsBefore, starsAfter, G.featureUnlock.progressiveLevelReward.enabled);
    } else {
      coinReward = SaveStateUtils.computeRewardByStars(lvlIndex, starsBefore, starsAfter);
    }
    if (G.featureUnlock.progressiveLevelReward.enabled) {
      coinReward = SaveStateUtils.computeRewardByStarsProgressive(starsBefore, starsAfter, coinReward);
    }
    if (G.featureUnlock.extraCoinsPerMove.enabled) {
      coinReward += SaveStateUtils._computeExtraCoinsPerMovesLeft(starsBefore, movesLeft, levelDifficulty);
    }
    return coinReward;
  }

  /**
   * Provides extra coins if the level was their first time
   * Originally a part of NoGatesRebalanced on OMT-4927
   * @param {number} starsBefore previous star amount
   * @param {number} [movesLeft] the number of moves left
   * @param {string} [levelDifficulty] The level type of the level
   */
  static _computeExtraCoinsPerMovesLeft(starsBefore, movesLeft, levelDifficulty) {
    let coinReward = 0;
    if (starsBefore === 0) {
      let difficultyAmount = G.featureUnlock.extraCoinsPerMove[levelDifficulty];
      if (!difficultyAmount) { difficultyAmount = 0; }
      coinReward += ((movesLeft || 0) * difficultyAmount);
    }
    return coinReward;
  }

  /**
   * compute level reward by star count
   * @param {number} lvlIndex index of level passed
   * @param {number} starsBefore previous star amount
   * @param {number} starsAfter current star amount
   */
  static computeRewardByStars(lvlIndex, starsBefore, starsAfter) {
    if (starsAfter > starsBefore) {
      const coinsForStars = G.json.settings.coinsForStar;
      return coinsForStars[starsAfter - 1] - (coinsForStars[starsBefore - 1] || 0);
    }
    return 0;
  }

  /**
   * computer reward by progressive stars?? used for daily challenge.
   * @param {number} starsBefore
   * @param {number} starsAfter
   * @param {number} totalCoins
   */
  static computeRewardByStarsProgressive(starsBefore, starsAfter, totalCoins) {
    const { increments } = G.featureUnlock.progressiveLevelReward;
    let result = 0;
    let percent = 0;
    for (let i = starsBefore; i < starsAfter; i++) {
      percent += increments[i];
    }
    result = Math.round(totalCoins * percent);
    return result;
  }

  /**
   * compute complete reward for beating a level
   * @param {number} lvlIndex index of level passed
   * @param {number} starsBefore previous star amount
   * @param {number} starsAfter current star amount
   */
  static computeRewardByLevelComplete(lvlIndex, starsBefore, starsAfter, forceGiveReward = false) {
    const rewardConfig = G.json.settings.levelCompleteReward;
    if ((starsBefore === 0 && starsAfter > 0) || forceGiveReward) {
      const rewardTier = Math.floor(lvlIndex / rewardConfig.increaseEveryNthLevel);
      return rewardConfig.baseLineReward + rewardTier * rewardConfig.rewardIncrease;
    }
    return 0;
  }

  /**
   * add a timestamp to the specified data Object
   * @param {Object} data
   */
  static addTimestampToObj(data) {
    if (Object.prototype.hasOwnProperty.call(data, 'lastChange')) {
      data.lastChange = new Date().getTime();
      return data;
    }
    const newData = {
      lastChange: new Date().getTime(),
    };
    return Object.assign(newData, data);
  }

  /**
   * check if 2 date objects occur on the same date
   * @param {Object} d1 Date instance
   * @param {Object} d2 Date instance
   */
  static isSameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear()
        && d1.getMonth() === d2.getMonth()
        && d1.getDate() === d2.getDate();
  }
}
