G.runDebugTests = function() {

  // check if tutorials are correct
  var tutorials = G.json.tutorials;

  // check if tutorials are in multi step format
  // var noStep = Object.entries(tutorials)
  //   .filter(function(entry) {
  //     var id = entry[0];
  //     var data = entry[1];
  //     return !data.steps;
  //   })
  //   .forEach(function(entry) {
  //     console.log("%c No steps in tutorial "+entry[0], "background: orange; color: white");
  //   });

  // unused tutorial
  var unused = Object.entries(tutorials)
    .filter(function(entry){
      var id = entry[0];
      if (id === "POPUPTUTORIALS") return false;
      return !G.Helpers.levelDataMgr.getLevelWithTutorialId(id);
    })
    .forEach(function(entry) {
      console.log("%c Unused tutorial "+entry[0], "background: red; color: white");
    });


};
