import { OMT_UI_FriendsListView } from '../../../OMT_UI/FriendsList/OMT_UI_FriendsListView';
import { LifeRequestExpandedUserView, LIFE_REQUEST_STATUS } from './LifeRequestExpandedUserView';

import {
  EXPANDED_LIST_SCROLL_CONFIG,
} from '../../MainLeaderboardSettings';

const RENDER_ITEM_DELAY = 16;

/*
  send / request list expanded list view
*/
export class LifeRequestExpanded extends OMT_UI_FriendsListView {
  /**
   * main init function. call manually or from extending Class
   * @param {number} itemHeight
   * @param {Phaser.Rectangle} scrollRect
   * @returns {Promise}
   */
  async init(itemHeight, scrollRect) {
    const [EXPANDED_ODD_ROW_COLOR, EXPANDED_EVEN_ROW_COLOR] = this.getAlternatingRowColors();
    await super.init(itemHeight, scrollRect, EXPANDED_ODD_ROW_COLOR, EXPANDED_EVEN_ROW_COLOR, EXPANDED_LIST_SCROLL_CONFIG, RENDER_ITEM_DELAY);
    this._scrollArea.allowOverScroll = true;
    this._scrollArea.addScrollCondition(() => {
      const { windowMgr } = game.state.getCurrentState();
      return windowMgr.isQueueEmpty;
    });
    this.disable();
  }

  /**
   * create the ExpandedUserView
   * @param {Object} friendData
   * @param {number} itemWidth
   * @param {number} itemHeight
   * @param {number} bgColor
   */
  _createFriendListItem(friendData, itemWidth, itemHeight, bgColor) {
    const listItem = new LifeRequestExpandedUserView(friendData, itemWidth, itemHeight, bgColor);
    listItem.signals.onSendLife.add(this._onLifeRequestStatusUpdate, this);
    listItem.signals.onRequestLife.add(this._onLifeRequestStatusUpdate, this);
    return listItem;
  }

  /**
   * lock the display while request is in progress
   * @param {LifeRequestExpandedUserView} userView
   * @param {number} status
   */
  _onLifeRequestStatusUpdate(userView, status) {
    if (status === LIFE_REQUEST_STATUS.REQUESTED) {
      this.disable();
    } else if (status === LIFE_REQUEST_STATUS.SUCCESS) {
      this.enable();
    } else if (status === LIFE_REQUEST_STATUS.FAILED) {
      this.enable();
    }
  }

  /**
   * enable interactivity
   */
  enable() {
    super.enable();
    for (const listItem of this._listItems) listItem.enable();
  }

  /**
   * disable interactivity
   */
  disable() {
    super.disable();
    for (const listItem of this._listItems) listItem.disable();
  }

  /**
   * main update method
   */
  update() {
    if (this._enabled) super.update();
  }
}
