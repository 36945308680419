import { Window_GameModePromoBase } from './Window_GameModePromoBase';
import { TreasureHuntPromo_Header } from './Components/TreasureHuntPromo_Header';
import { TreasureHuntPromo_Text } from './Components/TreasureHuntPromo_Text';
import TreasureHuntManager from '../../../Services/OMT/dataTracking/treasureHuntManager/treasureHuntManager';
import TreasureHuntIcon from '../../TreasureHunt/WorldMap/TreasureHuntIcon';

const TREASURE_HUNT_OFFSET = {
  vertical: 70,
  horizontal: 35,
};

/**
 * class for tournament promotion window.
 */
export default class Window_TreasureHuntPromo extends Window_GameModePromoBase {
  /**
  * constuctor
   * @param {Object} parent
   * @param {Object} config { icon:UI_TournamentIcon }
   */
  constructor(parent, config) {
    super(parent, config);
    G.saveState.treasureHuntManager.setPromoSeen();
    G.saveState.treasureHuntManager.setContentEntrySeen();

    this._tutorialTextKey = "It looks like there is something new on the map. Let's check it out!";
  }

  /**
   * initialize display elements
   */
  _initDisplayElements() {
    this._initHeaderGroup(TreasureHuntPromo_Header);
    this._initTutorialText(TreasureHuntPromo_Text);
    this._initGamePromoIcon(TreasureHuntIcon);
  }

  _initGamePromoIcon(icon) {
    super._initGamePromoIcon(icon);
    this._promoIcon.y += TREASURE_HUNT_OFFSET[OMT.systemInfo.orientationKey];
  }

  /**
   * Returns the target position the xpromo should go to.
   * @returns {{x: number, y: number}}
   */
  _getTargetPromoPosition() {
    return { x: 0, y: -TREASURE_HUNT_OFFSET[OMT.systemInfo.orientationKey] };
  }

  /**
   * show the tournament window after clicking on the icon
   */
  async _showWindow() {
    this._mapIcon.alpha = 1;
    TreasureHuntManager.openTreasureHuntPopup();
    this.closeWindow();
  }

  /**
   * resize method
   */
  _onResize() {
    // re-position / resize header
    this._headerGroup.y = (-game.height / 2) + this._headerGroup.height;
    this._headerGroup.resize();
    // re-position / resize tutorial text
    this._tutorialTextDisplay.resize();
    this._tutorialTextDisplay.y = game.height / 2;
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.treasureHuntPromo = Window_TreasureHuntPromo;
