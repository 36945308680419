let _instance = null; // singleton instance

const MAX_INVENTORY_LOGS = 100;

export class OMT_TransactionTracking {
  /**
   * return singleton instance
   * @returns {OMT_TransactionTracking}
   */
  static getInstance() {
    if (!_instance) _instance = new OMT_TransactionTracking();
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    this._dataStoreKey = 'transactionLog-data';
    this._timestampScale = G.OMTsettings.transactionTracking.timestampScale;
    this._snapshotInterval = G.OMTsettings.transactionTracking.snapshotInterval;
    this._transactionExpirationTimes = G.OMTsettings.transactionTracking.transactionExpirationTimes;
    this._logLoaded = false;

    this.logInventoryTransactionBegin();
    this._preinit();
  }

  /**
   * Pre-initializes transaction data
   */
  _preinit() {
    this._transactions = {
      ss: [], // snapshots
      rm: [], // real money
      inv: [], // in-game inventory changes
      crmInv: [], // CRM inventory changes
    };

    this._types = Object.keys(this._transactions);
  }

  /**
   * Initialization
   * @returns {Promise}
   */
  async init() {
    // Handle purging of logs
    if (OMT.feature.getFeatureTransactionPurge()) {
      let hasPurged = false;
      // await this.loadLog();

      const purgeLog = (type, logName) => {
        if (OMT.feature.getFeatureTransactionPurge(type)) {
          console.log(`Warning: purging ${logName}...`);
          if (this._transactions[type].length > 0) {
            this._transactions[type].length = 0;
            hasPurged = true;
            console.log(`Purged ${logName}`);
          } else {
            const capitalizedStr = `${logName[0].toUpperCase()}${logName.slice(1)}`;
            console.log(`${capitalizedStr} already empty`);
          }
        }
      };

      purgeLog('ss', 'transaction snapshots');
      purgeLog('rm', 'real money transaction log');
      purgeLog('inv', 'in-game inventory transaction log');
      purgeLog('crmInv', 'CRM inventory transaction log');

      if (hasPurged) {
        console.log('Purge complete: saving...');
        await this.saveLog();
      } else {
        console.log('Purge complete: no changes made');
      }
    }

    this._checkSnapshots();
  }

  /**
   * Logs real money purchases
   * @param {number} price
   * @param {string} productID
   * @param {string} priceCurrencyCode
   */
  logRealMoneyTransaction(price, productID, priceCurrencyCode) {
    if (OMT.feature.getFeatureTransactionTracking('rm')) {
      const dataToGo = [price, productID, priceCurrencyCode];
      if (G.saveState.iapMultiplier > 1) {
        dataToGo.push(`iapBonus:${G.saveState.iapMultiplier}`);
      }
      return this._logTransaction('rm', false, dataToGo);
    }
    return null;
  }

  /**
   * Initializes data for a new inventory transaction session
   */
  logInventoryTransactionBegin() {
    this._inventoryTxData = {
      coins: 0,
      lives: 0,
      boostersReceived: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      boostersUsed: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      changed: false,
    };
  }

  /**
   * Adds a change to inventory transaction session
   * @param {string} key the inventory item that changed (coins, lives, boostersUsed, or boostersReceived)
   * @param {number} boosterNum booster number (used only if key is 'boostersUsed' or 'boostersReceived')
   * @param {number} amount
   */
  addInventoryChange(key, boosterNum, amount) {
    if (amount !== 0) {
      if (key === 'coins' || key === 'lives') {
        this._inventoryTxData[key] += amount;
        this._inventoryTxData.changed = true;
      } else if (key === 'boostersUsed' || key === 'boostersReceived') {
        this._inventoryTxData[key][boosterNum] += amount;
        this._inventoryTxData.changed = true;
      } else {
        console.log(`Error in addInventoryChange: invalid key ${key}`);
      }
    }
  }

  /**
   * Ends current inventory transaction session and logs the results
   */
  logInventoryTransactionEnd() {
    if (OMT.feature.getFeatureTransactionTracking('inv')) {
      const {
        coins,
        lives,
        boostersReceived,
        boostersUsed,
        changed,
      } = this._inventoryTxData;

      if (changed) {
        /**
         * Encode transaction data into a more compact form
         * c - Coins
         * l - Lives
         * b#r - Boosters received
         * b#u - Boosters used
         */

        const formattedData = [];

        if (coins !== 0) {
          formattedData.push(`c:${coins}`);
        }

        if (lives !== 0) {
          formattedData.push(`l:${lives}`);
        }

        for (let i = 0; i < boostersReceived.length; i++) {
          if (boostersReceived[i] !== 0) {
            formattedData.push(`b${i}r:${boostersReceived[i]}`);
          }
        }

        for (let i = 0; i < boostersUsed.length; i++) {
          if (boostersUsed[i] !== 0) {
            formattedData.push(`b${i}u:${boostersUsed[i]}`);
          }
        }

        return this._logTransaction('inv', false, formattedData);
      }

      console.log('Warning: Transaction is empty! Skip logging');
      return '';
    }
    return null;
  }

  /**
   * Log the current state of the inventory
   */
  logInventorySnapshot() {
    if (OMT.feature.getFeatureTransactionTracking('ss')) {
      const snapShotStr = [
        `c:${G.saveState.getCoins()}`,
        `l:${G.saveState.getLives()}`,
        `b:${JSON.stringify(G.saveState.getBoosterArray())}`,
      ];

      this._logTransaction('ss', true, snapShotStr);
    }
  }

  /**
   * Logs a transaction
   * @param {string} type
   * @param {boolean} skipSnapshotCheck
   * @param {Array<*>} data info regarding this transaction
   */
  _logTransaction(type, skipSnapshotCheck, data) {
    if (OMT.feature.getFeatureTransactionTracking()) {
      // Capture a snapshot
      if (!skipSnapshotCheck) {
        this._checkSnapshots();
      }

      // Create TSV from provided data, prefixed with a timestamp
      const entry = [Math.floor(Date.now() / this._timestampScale), ...data].join('\t');
      this._transactions[type].push(entry);

      // restrict the transaction log count for non real money transactions
      if (type === 'inv' && this._transactions[type].length > MAX_INVENTORY_LOGS) {
        const overflowCount = this._transactions[type].length - MAX_INVENTORY_LOGS;
        this._transactions[type].splice(0, overflowCount);
      }

      this.saveLog();
      console.log(`Transaction logged: ${entry}`);
      return entry;
    }
    return null;
  }

  /**
   * Removes transactions past their expiry date
   */
  _clearExpiredData() {
    const now = Math.floor(Date.now() / this._timestampScale);

    for (const type of this._types) {
      const transactionsOfType = this._transactions[type];

      for (let i = 0; i < transactionsOfType.length; i++) {
        // Note: Don't delete transactions with no timestamps, just in case
        const timestamp = this._getTimestamp(transactionsOfType[i]) || Infinity;
        if (this._transactionExpirationTimes[type] !== -1
            && timestamp + this._transactionExpirationTimes[type] < now) {
          transactionsOfType.splice(i, 1);
        }
      }
    }
  }

  /**
   * Check if it's time to log a new snapshot
   */
  _checkSnapshots() {
    if (OMT.feature.getFeatureTransactionTracking('ss')) {
      const now = Date.now() / this._timestampScale;
      const lastSnapshotTime = this._transactions.ss.reduce((latest, tx) => {
        const current = this._getTimestamp(tx);
        return current > latest ? current : latest;
      }, 0);

      if (lastSnapshotTime + this._snapshotInterval < now) {
        this.logInventorySnapshot();
      }
    }
  }

  /**
   * Save transaction log data to game backend and local storage
   * @returns {Promise}
   */
  async saveLog() {
    this._clearExpiredData();
    if (OMT.feature.getFeatureTransactionTracking()
        || OMT.feature.getFeatureTransactionPurge()) {
      return new Promise((resolve) => {
        // eslint-disable-next-line no-undef
        LZUTF8.compressAsync(JSON.stringify(this._transactions), { outputEncoding: 'Base64', inputEncoding: 'String' }, (result, error) => {
          if (error) {
            console.log(`Error compressing transaction log: ${error}`);
            resolve(false);
          }
          OMT.userData.writeUserData(this._dataStoreKey, result);
          resolve(true);
        });
      });
    }
    return null;
  }

  /**
   * Load transaction log data from game backend or local storage
   * @returns {Promise}
   */
  async loadLog() {
    if ((OMT.feature.getFeatureTransactionTracking() || OMT.feature.getFeatureTransactionPurge())
        && !this._logLoaded) {
      this._logLoaded = true;
      const data = OMT.userData.getUserData(this._dataStoreKey);
      return new Promise((resolve) => {
        // eslint-disable-next-line no-undef
        LZUTF8.decompressAsync(data, { inputEncoding: 'Base64', outputEncoding: 'String' }, (result, error) => {
          if (error) {
            console.log(`Error decompressing transaction log: ${error}`);
            resolve(false);
          }

          try {
            this._transactions = JSON.parse(result);
            this._migrateData();
            this._clearExpiredData();
            resolve(true);
          } catch (_error) {
            console.log(`Error decompressing transaction log: ${_error}`);
            resolve(false);
          }
        });
      });
    }
    return new Promise((resolve) => resolve(false));
  }

  /**
   * Prints a list of expiry times for each transaction for debug purposes
   */
  _getExpiryTimes() {
    const now = Math.floor(Date.now() / this._timestampScale);
    const expTimes = {};

    for (const type of this._types) {
      const transactionsOfType = this._transactions[type];
      expTimes[type] = [];

      for (let i = 0; i < transactionsOfType.length; i++) {
        const timestamp = this._getTimestamp(transactionsOfType[i]) || Infinity;
        const expiryTime = this._transactionExpirationTimes[type] === -1
          ? Infinity
          : timestamp + this._transactionExpirationTimes[type] - now;
        expTimes[type].push(`${expiryTime} minutes`);
      }
    }

    console.log(expTimes);
  }

  /**
   * Clears transaction log both locally and on the backend
   * @returns {Promise}
   */
  async _clearTransactions() {
    this._preinit();
    this.init();
    await this.saveLog();
  }

  /**
   * Extracts timestamp from log string
   * @param {string} logStr
   */
  _getTimestamp(logStr) {
    if (logStr == null) {
      return null;
    }
    const timestampRegex = /^(\d+)/; // finds timestamp in transaction string (always first number in string)
    return parseInt(logStr.match(timestampRegex)[0]);
  }

  /**
   * Adds any missing data to the transaction log after loading an older version of the log schema
   */
  _migrateData() {
    if (!this._transactions.crmInv) {
      this._transactions.crmInv = [];
    }
  }

  /**
   * get the time since the last real money transaction
   * @returns {number} time in milliseconds
   */
  getTimeSinceLastRealMoneyTransaction(productID = null) {
    let transactions = this._transactions.rm;
    if (transactions.length === 0) return Infinity;

    if (productID != null) { // filter by logged productID
      transactions = transactions.filter((tx) => {
        const splitData = tx.split('\t');
        if (splitData.length < 4) return false;
        const logProductID = splitData[2].substring(3);
        return logProductID === productID;
      });
    }

    const lastTimestamp = transactions.reduce((latest, tx) => {
      const current = this._getTimestamp(tx);
      return current > latest ? current : latest;
    }, 0);

    const timeScale = this._timestampScale;
    const timeSinceLastTransaction = Date.now() - (lastTimestamp * timeScale);
    return timeSinceLastTransaction;
  }

  /**
   * get the list of transactions
   * @returns {Array}
   */
  get transactions() {
    this._clearExpiredData();
    return this._transactions;
  }
}
