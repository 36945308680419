G.DotBg = function(lvl_gfx) {

	this.texture = game.add.renderTexture(game.width,game.height);

	this.marker = G.makeImage(0,0,'background_star_tile',0,null);
	this.marker.alpha = 0.4;

	this.img = game.add.image(0,0,this.texture);

	G.sb("onScreenResize").add(this.onScreenResize,this);
	this.onScreenResize();

};

G.DotBg.prototype = Object.create(Phaser.Image.prototype);

G.DotBg.prototype.onScreenResize = function() {

	this.texture.resize(game.width,game.height);
	this.texture.clear();

	this.img.x = game.world.bounds.x;

	for (var xx = 0; xx < game.width; xx += this.marker.width) {
		for (var yy = 0; yy < game.height; yy += this.marker.height) {

			this.texture.renderXY(this.marker,xx,yy); 


		}
	}

};