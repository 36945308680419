import { WorldMap2_globalSignals } from '../WorldMap2_Util';

/* eslint-disable no-use-before-define */
const TIMERS = {
  enter: 333,
  wait: 4000,
  exit: 500,
};

const NGBubbleState = {
  CLOSED: 'closed',
  IDLE: 'idle',
  OPENING: 'opening',
  CLOSING: 'closing',
};

const c_mapInteractionFailBubblePadding = 15;

export default class WorldMapInteractionNGPool extends Phaser.Group {
  /**
   * Full class name is read World Map Interaction No Good Pool
   * Shows up when an interaction is clicked but its not ready to open
   */
  constructor() {
    super(game, null);
    this._actives = [];
    this._deadObjects = [];
  }

  /**
   * Returns a free element of the given basicNodeType
   */
  getFreeElement() {
    let element;

    if (this._deadObjects.length > 0) {
      element = this._deadObjects.pop();
    } else {
      element = new NoGoodBubble();
      element.events.onKilled.add(this._onElementKilled, this);
      element.signals.internalKill.add(this.killTile, this);
    }
    return element;
  }

  /**
   * Kills the basic node and puts it in the proper dead array
   * @param {BasicNode} elem
   */
  _onElementKilled(elem) {
    if (!elem.parent) return;
    this._deadObjects.push(elem);
    if (elem.parent) {
      elem.parent.removeChild(elem);
    }
  }

  /**
   * Kills tile at index
   * @param {number} index
   */
  killTile(index) {
    const tile = this._actives.find((seg) => seg.index === index);
    if (tile) {
      tile.kill();
      this._actives.splice(this._actives.indexOf(tile), 1);
    }
  }

  /**
   * Adds the river tile
   * @param {MapInteractionNode} node
   * @param {number} yPos
   * @returns {NoGoodBubble}
   */
  addTile(node, str) {
    if (this._actives.find((seg) => seg.index === node.index)) { return null; }
    const noGood = this.getFreeElement();
    noGood.init(node.index, str);
    noGood.x = node.x;
    noGood.y = node.y - c_mapInteractionFailBubblePadding - (node.height + noGood.height) * 2;
    this._actives.push(noGood);
    this.addChild(noGood);
    return noGood;
  }

  /**
   * Moves tiles based on deltaY
   * @param {number} deltaY
   */
  moveTiles(deltaY) {
    // update active segments
    for (let i = this._actives.length - 1; i >= 0; i--) {
      this._actives[i].y += deltaY;
    }
  }
}

class NoGoodBubble extends Phaser.Image {
  /**
   * The little speech bubble that says the feature is unlocked at x level
   */
  constructor() {
    super(game);
    this._drawEverything();

    this.signals = {
      internalKill: new Phaser.Signal(),
    };

    this._bubbleState = NGBubbleState.CLOSED;
    this._allTweens = [];

    this._signalTokens = [ // Signal tokens hooked from G.sb to delete
      G.sb(WorldMap2_globalSignals.mapClicked).add(this._closeBubble.bind(this)),
    ];
  }

  /**
   * Prepares the asset
   */
  _drawEverything() {
    this._speechGroup = new Phaser.Group(game, this);
    const bubble = G.makeImage(0, 0, 'realMoney_speech_bubble', 0.5, this._speechGroup);
    bubble.scale.set(200 / bubble.width);
    this._text = new G.Text(0, (-8 * bubble.scale.y), 'a', 'worldMap-interactionFailText', 0.5, bubble.width * 0.95, bubble.height * 0.95, true, 'center');
    this._speechGroup.addChild(this._text);
  }

  /**
   * Updates the text in the asset and springs it open
   * @param {string} text
   */
  init(index, text) {
    this.index = index;
    this._stopAllTweens();
    this._text.setText(text);
    this.revive();

    this._bubbleState = NGBubbleState.OPENING;
    const tw = game.add.tween(this._speechGroup.scale).to({ x: 1, y: 1 }, TIMERS.enter, Phaser.Easing.Bounce.Out, true);
    tw.onComplete.add(this._waitBubble.bind(this));
    this._allTweens.push(tw);
  }

  /**
   * The bubble enters a waiting state before self closing
   */
  _waitBubble() {
    if (!this.game) { return; }
    if (this._bubbleState !== NGBubbleState.OPENING) { return; }
    this._bubbleState = NGBubbleState.IDLE;
    const tw = game.add.tween(this._speechGroup.scale).to({ x: 1, y: 1 }, TIMERS.wait, Phaser.Easing.Bounce.Out, true);
    tw.onComplete.add(this._closeBubble.bind(this));
    this._allTweens.push(tw);
  }

  /**
   * The bubble begins to close
   */
  _closeBubble() {
    if (!this.game) { return; }
    if (this._bubbleState === NGBubbleState.CLOSING || this._bubbleState === NGBubbleState.CLOSED) { return; } // Don't attempt to close if already closing
    this._bubbleState = NGBubbleState.CLOSING;
    const tw = game.add.tween(this._speechGroup.scale).to({ x: 0, y: 0 }, TIMERS.exit, Phaser.Easing.Elastic.In, true);
    tw.onComplete.add(this._internalKill.bind(this));
    this._allTweens.push(tw);
  }

  /**
   * The bubble closes and sends a signal up to remove it from the pool
   */
  _internalKill() {
    this._bubbleState = NGBubbleState.CLOSED;
    this.signals.internalKill.dispatch(this.index);
  }

  /**
   * All tweens are stopped
   */
  _stopAllTweens() {
    for (const tw of this._allTweens) {
      if (tw && tw.stop) {
        tw.stop();
      }
    }
    this._allTweens.length = 0;
    this._speechGroup.scale.set(0.1);
  }

  /**
   * Killed
   */
  kill() {
    this._stopAllTweens();
    super.kill();
  }

  /**
   * Destroyed!
   */
  destroy() {
    this._stopAllTweens();
    if (this._speechGroup && this._speechGroup.destroy) {
      this._speechGroup.destroy();
    }
    this._speechGroup = null;
    for (const signal of this._signalTokens) if (signal) signal.detach();
    this._signalTokens.length = 0;
    super.destroy();
  }
}
