/* eslint-disable no-await-in-loop */
/* eslint-disable prefer-object-spread */
/* eslint-disable no-unused-vars */

import {
  createScheduledBotMessage as GBC_createScheduledBotMessage,
  deleteScheduledBotMessage as GBC_deleteScheduledBotMessage,
  getScheduledBotMessages as GBC_getScheduledBotMessages,
  PROVIDER,
} from '@sgorg/game-backend-client';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import {
  messageConfig,
  colours,
} from './OMT_GameMessageConfig';

/**
 * Class for handling and scheduling bot messages and app-to-user notifications
 */
export class OMT_Notifications {
  constructor() {
    // Local copy of schedule
    this._localMessages = {
      bot: [],
    };
    this._timesSent = {};
    this._timeLastSyncedWithGB = null;

    // Debugging stuff
    this._forceBotsUnsubscribed = false; // Pretend the player is unsubscribed from bot message, even if subscribed
    this._impatientMode = false; // Reduce delay of all messages to _impatientDelay (debugging purposes only)
    this._impatientDelay = 5 * 60; // 5 minutes
  }

  /**
   * Public Interface
   * These functions should be the only ones used by other modules within the game
   * @returns {Promise}
   */
  async init() {
    if (OMT.feature.getFeatureGameTriggeredBotMessages()) {
      OMT_Utils.stylizedLog('Game-triggered messages: bot message scheduling service active', colours.ok);
    }

    if (!this._forceBotsUnsubscribed && G.saveState.getBotOptIn()) {
      OMT_Utils.stylizedLog('Bot subscription status: ON', colours.ok);
    } else {
      OMT_Utils.stylizedLog('Bot subscription status: OFF', colours.ok);
    }

    // Fill in player_id keys in messageConfig with actual player id
    for (const msgType in messageConfig) {
      if (messageConfig[msgType].params && messageConfig[msgType].params.player_id === '[myUserId]') {
        messageConfig[msgType].params.player_id = OMT.envData.settings.user.userId;
      }
    }

    // Bot Messages
    // await this._syncLocalMessagesWithGB(true);
  }

  /**
   * Triggers a game message
   * @param {string} receipientId
   * @param {string} messageType
   * @param {number} delay in seconds. must be at least 1
   * @param {boolean} force if true, always schedule the message, regardless of priority
   * @returns {Promise<Object>} message object from GB client
   */
  async scheduleGameTriggeredMessage(receipientId, messageType, delay, force = false) {
    if (this._canUseBotService()) {
      if (OMT.connect.isConnected()) {
        if (this._timeLastSyncedWithGB === null) {
          // await this._syncLocalMessagesWithGB(true);
        }

        return this._scheduleGBMessage(receipientId, messageType, delay, force);
      }
      OMT_Utils.stylizedLog('Error: Could not schedule GTM - not connected to game backend', colours.error);
      return {};
    }
    return {};
  }

  /**
   * Removes a previously game-triggered message
   * @param {string} messageType
   * @returns {Promise<boolean>} was is successful?
   */
  async removeGameTriggeredMessage(messageType) {
    if (this._canUseBotService()) {
      if (OMT.connect.isConnected()) {
        if (this._timeLastSyncedWithGB === null) {
          // await this._syncLocalMessagesWithGB(true);
        }

        return this._unscheduleGBMessageByType(messageType);
      }
      OMT_Utils.stylizedLog('Error: Could not remove scheduled GTM - not connected to game backend', colours.error);
      return false;
    }
    return false;
  }

  /**
   * Returns a list all scheduled game-triggered messages, with optional type filter
   * @param {string} messageType filter messages by this type. If blank, don't filter any messages
   * @returns {Promise<Array.<Object>>} list of messages
   */
  async findGameTriggeredMessages(messageType = '') {
    const service = this._getServiceString();

    if (service === 'bot' && this._timeLastSyncedWithGB === null && OMT.connect.isConnected()) {
      // await this._syncLocalMessagesWithGB(true);
    }
    return this._findLocalMessageByType(messageType, service);
  }

  /**
   * Deletes all scheduled messages from the queue
   * @param {string} type defaults to 'bot'
   * @returns {Promise}
   */
  async unscheduleAllGameTriggeredMessages(type = 'bot') {
    if (OMT.connect.isConnected()) {
      // Bot messages
      if (type === 'bot') {
        OMT_Utils.stylizedLog('Unscheduling all bot message(s)', colours.ok);
        if (this._timeLastSyncedWithGB === null) {
          // await this._syncLocalMessagesWithGB(true);
        }

        const scheduledMessages = await this._findScheduledGBMessages('', false);
        for (const msg of scheduledMessages) {
          await this._unscheduleGBMessageById(msg.id);
        }
        this._localMessages.bot = [];
      }
    } else {
      OMT_Utils.stylizedLog('Error: Could not unschedule all GTM - not connected to game backend', colours.error);
    }
  }

  /**
   * Game Backend Services (Bot Messages and App-to-User)
   * Functions that interface with the the two messaging services and the local messsage schedule
   * The service used depends on the player's bot opt-in status
   */

  /**
   * Schedules a new message, taking into consideration priority
   * @param {string} receipientId
   * @param {string} type message type
   * @param {integer} delay in seconds
   * @param {boolean} force if true, always schedule the message, regardless of priority
   * @returns {Promise<Object>} message object from GB client
   */
  async _scheduleGBMessage(receipientId, type, delay, force = false) {
    if (this._canUseBotService()) {
      const service = this._getServiceString();

      // Check if message type is disabled
      if (!messageConfig[type][`enabled_${service}`]) {
        OMT_Utils.stylizedLog(`Warning: ${service} ${type} messages are disabled`, colours.warning);
        return null;
      }

      // Check how many times this message has been sent against how many times it should be sent
      if (!Object.keys(this._timesSent).includes(type)) {
        this._timesSent[type] = 0;
      }
      if (this._timesSent[type] >= messageConfig[type].limitPerSession) {
        OMT_Utils.stylizedLog(`Warning: The limit for scheduling messages of type ${type} has been reached.`, colours.warning);
        return null;
      }

      OMT_Utils.stylizedLog(`Scheduling new ${service} message (${type})...`, colours.ok);
      const scheduledMessages = await this._findLocalMessageByType('', service);

      // If the message has transcendent priority (-1), check if another of message of the same type
      // has already been scheduled. If there is, don't schedule another
      if (messageConfig[type].priority === -1) {
        for (const msg of scheduledMessages) {
          if (msg.id === type) {
            OMT_Utils.stylizedLog(`Warning: A ${service} message (${msg.id}) was previously scheduled.`
            + 'Can\'t schedule duplicate transcendent priority messages of a given type', colours.warning);
            return null;
          }
        }
      } else if (scheduledMessages.length > 0) {
        let canSchedule = true;
        const removedMsgIds = [];

        for (const msg of scheduledMessages) {
          const msgType = msg.messageType;
          const scheduledMessagePriority = messageConfig[msgType].priority;

          if (scheduledMessagePriority === -1) {
            continue; // Transcendent priority, ignore
          } else if (!force && messageConfig[type].priority > scheduledMessagePriority) {
            // Check if another message has already been schdeuled
            // If it's higher, leave it alone; if it's lower or equal, delete it
            OMT_Utils.stylizedLog(`Warning: A(n) ${service} message with a higher priority (${msgType}) than the pending message (${type}) was previously scheduled!`, colours.warning);
            canSchedule = false;
          } else {
            OMT_Utils.stylizedLog(`Unscheduling previous ${service} message (${msgType})...`, colours.ok);
            await this._unscheduleGBMessageById(msg.id);
            removedMsgIds.push(msg.id); // remove local messages later, so that it doesn't cause the for..of loop to bug out
          }
        }

        // Remove local messages
        for (const msgId of removedMsgIds) {
          this._removeLocalMessageById(msgId, service);
        }

        // If there is at least one message with a higher priority, don't schedule the pending one
        if (!canSchedule) return null;
      }

      OMT_Utils.stylizedLog(`Creating ${service} message (${type})...`, colours.ok);

      // Override delay if impatient mode is on (debugging purposes only)
      if (this._impatientMode) {
        delay = Math.min(this._impatientDelay, delay);
      }

      const delayHMS = G.changeSecToHMS(delay);

      // Bot message
      const newMessage = await this._createScheduledBotMessage(
        receipientId,
        parseInt(delayHMS[0]),
        parseInt(delayHMS[1]),
        parseInt(delayHMS[2]),
        [receipientId],
        type,
        {},
        OMT.language.lang,
        false,
      );

      if (newMessage) {
        OMT_Utils.stylizedLog(`Bot message ${newMessage.id} scheduled to be delivered in ${delayHMS[0]}:${delayHMS[1]}:${delayHMS[2]}.`, colours.ok);
        this._timesSent[type]++;
        return newMessage;
      }

      OMT_Utils.stylizedLog(`Error: Could not schedule ${service} message`, colours.error);
      return null;
    }
    return null;
  }

  /**
   * Returns all scheduled bot messages from the backend queue, with optional type filter
   * @param {string} messageType filter messages by this type. If blank, don't filter any messages
   * @param {boolean} showLog show detailed output
   * @returns {Promise<Array.<Object>>} list of messages
   */
  async _findScheduledGBMessages(messageType = '', showLog = true) {
    // Get bot messages
    try {
      const messages = await GBC_getScheduledBotMessages({ playerId: OMT.envData.settings.user.userId });

      if (showLog) console.log(messages);

      if (messageType) {
        return messages.filter((msg) => msg.messageId === messageType);
      }

      return messages;
    } catch (error) {
      OMT_Utils.stylizedLog('ERROR: could not get scheduled bot messages', colours.error);
      OMT_Utils.stylizedLog(error, colours.error);
      return [];
    }
  }

  /**
   * Deletes a scheduled message from the queue by message id
   * @param {string} messageId
   * @returns {Promise<boolean>} was is successful?
   */
  async _unscheduleGBMessageById(messageId) {
    const service = this._getServiceString();

    OMT_Utils.stylizedLog(`Unscheduling ${service} message(s) with message id ${messageId}...`, colours.ok);
    // Bot message
    try {
      await GBC_deleteScheduledBotMessage({ scheduledMessageId: messageId });

      OMT_Utils.stylizedLog(`Scheduled ${service} message ${messageId} has been deleted`, colours.ok);
      return true;
    } catch (error) {
      OMT_Utils.stylizedLog(`ERROR: did not delete scheduled ${service} message ${messageId}`, colours.error);
      OMT_Utils.stylizedLog(error, colours.error);
      return false;
    }
  }

  /**
   * Deletes a scheduled message from the queue by message type
   * @param {string} messageType
   * @returns {Promise<boolean>} was is successful?
   */
  async _unscheduleGBMessageByType(messageType) {
    const service = this._getServiceString();
    OMT_Utils.stylizedLog(`Unscheduling ${service} message(s) with message type ${messageType}...`, colours.ok);

    try {
      // Bot message
      const messagesFound = this._findLocalMessageByType(messageType, service);
      const removedMsgIds = [];

      if (messagesFound.length > 0) {
        // eslint-disable-next-line no-unused-vars
        for (const msg of messagesFound) {
          await this._unscheduleGBMessageById(msg.id);
          removedMsgIds.push(msg.id); // remove local messages later, so that it doesn't cause the for..of loop to bug out
        }

        // Remove local messages
        for (const msgId of removedMsgIds) {
          this._removeLocalMessageById(msgId, service);
        }

        return true;
      }
      OMT_Utils.stylizedLog(`No scheduled ${service} messages found with message id ${messageType}`, colours.ok);
      return false;
    } catch (error) {
      OMT_Utils.stylizedLog(`ERROR: did not delete scheduled ${service} message of type ${messageType}`, colours.error);
      OMT_Utils.stylizedLog(error, colours.error);
      return false;
    }
  }

  /**
   * Bot Message Funcs
   * Helper functions working with the game backend client and scheduled bot messages
   */

  /**
   * Schedules a bot message to be sent.
   * @param {number} playerId
   * @param {number} inHours
   * @param {number} inMinutes
   * @param {number} inSeconds combined time of inHours + inMinutes + inSecond must total at least 1 second
   * @param {Array} recipientIds
   * @param {string} messageType see OMT_GameMessageConfig for a reference of IDs
   * @param {Object} messageData
   * @param {string} locale
   * @param {boolean} showLog show detailed output
   * @returns {Promise<Object>}
   */
  async _createScheduledBotMessage(playerId, inHours, inMinutes, inSeconds, recipientIds, messageType, messageData, locale, showLog = true) {
    try {
      const payload = {
        playerId,
        provider: PROVIDER.FACEBOOK_INSTANT_GAME,
        inHours,
        inMinutes,
        inSeconds,
        recipientIds,
        messageId: messageType,
        messageData,
        locale,
      };

      const newScheduledMessage = await GBC_createScheduledBotMessage(payload);
      if (showLog) console.log(newScheduledMessage);

      this._addLocalMessage({
        id: newScheduledMessage.id,
        messageType: newScheduledMessage.messageId,
        createDate: new Date(newScheduledMessage.createDate).getTime(),
        scheduledFor: new Date(newScheduledMessage.scheduledFor).getTime(),
      }, 'bot');

      return newScheduledMessage;
    } catch (error) {
      OMT_Utils.stylizedLog('Error: Could not schedule bot message', colours.error);
      console.log(error);
      return null;
    }
  }

  /**
   * Local
   * Functions that interface with the local message schedule
   */

  /**
   * Adds message object to local schedule
   * @param {Object} message
   * @param {string} service 'bot'
   */
  _addLocalMessage(message, service) {
    this._clearSentMessages();
    this._localMessages[service].push(message);
  }

  /**
   * Finds message that matches an id
   * @param {string} messageId
   * @param {string} service 'bot'
   * @returns {Object} message details
   */
  _findLocalMessageById(messageId, service) {
    this._clearSentMessages();
    return this._localMessages[service].findIndex((msg) => msg.id === messageId);
  }

  /**
   * Returns all scheduled bot messages from the local queue, with optional type filter
   * @param {string} messageType filter messages by this type. If blank, don't filter any messages
   * @param {string} service 'bot'
   * @returns {Array.<Object>} list of messages
   */
  _findLocalMessageByType(messageType, service) {
    this._clearSentMessages();
    if (messageType) {
      if (service === 'bot') return this._localMessages[service].filter((msg) => msg.messageType === messageType);
      return [];
    }
    return this._localMessages[service];
  }

  /**
   * Removes message from local schedule by its id
   * @param {string} messageId
   * @param {string} service 'bot'
   */
  _removeLocalMessageById(messageId, service) {
    const index = this._findLocalMessageById(messageId, service);
    this._localMessages[service].splice(index, 1);
  }

  /**
   * Removes message from local schedule by its local index
   * @param {number} index
   * @param {string} category 'bot'
   */
  _removeLocalMessageByIndex(index, service) {
    this._localMessages[service].splice(index, 1);
  }

  /**
   * Checks local messages and removes those that should have been sent by the current time
   */
  _clearSentMessages() {
    const now = Date.now();

    this._localMessages.bot.forEach((msg, i) => {
      const sendTime = msg.scheduledFor;
      if (sendTime < now) {
        this._removeLocalMessageByIndex(i, 'bot');
      }
    });
  }

  /**
   * Fetches the list of scheduled messages from the game backend and saves it to a local copy
   * @param {boolean} overwrite if true, replaces existing local message list with game backend's list
   * @returns {Promise}
   */
  async _syncLocalMessagesWithGB(overwrite) {
    try {
      if (!this._timeLastSyncedWithGB && OMT.connect.isConnected()) {
        // Bot messages
        OMT_Utils.stylizedLog('Syncing bot message schedule with game backend...', colours.ok);
        const gbcMessages = await this._findScheduledGBMessages('', false);
        this._localMessages.bot = overwrite ? this._localMessages.bot : [];

        gbcMessages.forEach((msg) => {
          this._addLocalMessage({
            id: msg.id,
            messageType: msg.messageId,
            createDate: new Date(msg.createDate).getTime(),
            scheduledFor: new Date(msg.scheduledFor).getTime(),
          }, 'bot');
        });

        this._timeLastSyncedWithGB = Date.now();
        OMT_Utils.stylizedLog('Sync complete!', colours.ok);
      }
    } catch (error) {
      OMT_Utils.stylizedLog('Error: Could not sync message schedule with game backend', colours.error);
      console.log(error);
    }
  }

  /**
   * Utils
   * Other helper functions
   */

  /**
   * Checks if the bot message service should be used based on opt-in status and manual overrides
   * @returns if bot messages can be used
   */
  _canUseBotService() {
    return OMT.feature.getFeatureGameTriggeredBotMessages()
      && !this._forceBotsUnsubscribed
      && G.saveState.getBotOptIn();
  }

  /**
   * Translates _canUseBotService status to service name
   * @returns {string} 'bot', the only service currently in use
   */
  _getServiceString() {
    return 'bot';
  }
}
