export const dailyWheelResponsiveConfig = {
  screenWidth: {
    min: 640,
    max: 1280,
  },
  screenHeight: {
    min: 960,
    max: 1380,
  },

  bannerConfig: {
    parentOffset: {
      y: -105,
    },
    elemOffsets: {
      closeButton: {
        x: {
          min: 260,
          max: 360,
        },
        y: -175,
      },
      realMoneyQuitButton: {
        y: -210,
      },
      bannerGroup: {
        y: -175,
      },
      ribbon: {
        y: 98,
      },
    },
  },

  spinButtonConfig: {
    parentOffset: {
      y: {
        min: 435,
        max: 535,
      },
    },

    elemOffsets: {
      realMoneySpinBtn: {
        y: {
          min: 45,
          max: -10,
        },
      },
    },
  },

  mascotHostConfig: {
    offset: {
      x: {
        min: 0,
        max: -600,
      },
    },
  },
};

export const realMoneyWheelResponsiveConfig = {
  screenWidth: {
    min: 640,
    max: 1280,
  },
  screenHeight: {
    min: 960,
    max: 1380,
  },

  bannerConfig: {
    parentOffset: {
      y: -105,
    },
    elemOffsets: {
      quitButton: {
        x: {
          min: 260,
          max: 360,
        },
        y: -285,
      },
      bannerGroup: {
        y: -285,
      },
    },
  },

  coinBarConfig: {
    y: {
      min: -195,
      max: -255,
    },
  },

  spinButtonConfig: {
    parentOffset: {
      y: {
        min: 435,
        max: 535,
      },
    },

    elemOffsets: {
      realMoneySpinBtn: {
        y: {
          min: 45,
          max: -10,
        },
      },
    },
  },

  mascotHostConfig: {
    offset: {
      x: {
        min: 0,
        max: -600,
      },
    },
  },
};
