import ComponentGameHooks from '../../../ComponentGameHooks';

const DEFAULT_CONFIG = {
  x: 0,
  y: -210,
  pointerAsset: 'prize_wheel_arrow',
  anchor: [0.5, 0.2],
  soundId: 'pop',
  soundMaxVolume: 0.25,
};

const MAX_HIT_FORCE = 10;
const MIN_HIT_FORCE = 5;
const MAX_ACTIVATION_ANGLE = 2;
const LERP_INTERPOLATION_POINT = 0.2;
const MINIMUM_SOUND_DELAY = 250;

/**
 * Class for the prize pointer at the top of the wheel for UI_PrizeWheel.
 */
export default class PrizeWheel_Pointer extends Phaser.Sprite {
  /**
   * constructor
   * @param {Object} config (optional) overrides for DEFAULT_CONFIG
   */
  constructor(config = {}) {
    super(game);

    this._config = _.merge(_.cloneDeep(DEFAULT_CONFIG), config);

    // apply settings from the config
    const {
      pointerAsset, anchor, x, y,
    } = this._config;

    // set sprite texture
    G.changeTexture(this, pointerAsset);

    // set sprite position and anchor
    this.x = x; this.y = y;
    [this.anchor.x, this.anchor.y] = anchor;

    this._lastPlayedSoundAt = 0;
  }

  /**
   * call to start the peg reaction animation
   * @param {number} spinVelocity
   */
  onHitPeg(spinVelocity) {
    if (Math.abs(this.angle) > MAX_ACTIVATION_ANGLE) return;

    // start reaction animation
    const hitForce = Math.max(Math.min(Math.abs(spinVelocity) / MAX_HIT_FORCE, 1) * MAX_HIT_FORCE, MIN_HIT_FORCE);
    if (spinVelocity > 0) this.angle = hitForce * -Math.sign(spinVelocity);

    // play hit peg sound
    if (Date.now() - this._lastPlayedSoundAt > MINIMUM_SOUND_DELAY) {
      const { soundId, soundMaxVolume } = this._config;
      const volume = (hitForce / MAX_HIT_FORCE) * soundMaxVolume;
      ComponentGameHooks.playSoundById(soundId, volume);
    }
  }

  /**
   * update peg animation
   */
  _updatePeg() {
    const deltaAngle = G.lerp(this.angle, 0, LERP_INTERPOLATION_POINT) - this.angle;
    this.angle += deltaAngle * G.deltaTime;
  }

  /**
   * update peg animation
   */
  update() {
    super.update();
    this._updatePeg();
  }

  /**
   * Changes pointer asset
   * @param {string} asset key of new asset
   */
  changeAsset(asset) {
    G.changeTexture(this, asset);
  }
}
