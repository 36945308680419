
export const TIMER_FORMAT = {
  DHMS: 'dhms',
  DH: 'dh',
  HMS: 'hms',
  HM: 'hm',
  MS: 'ms',
};

/**
 * @typedef {TextTimerConfig}
 * @property {number} x
 * @property {number} y
 * @property {string} date
 * @property {string|Object} style
 * @property {number|Array} anchor
 * @property {number} maxWidth
 * @property {number} maxHeight
 * @property {string} timerFormat 'hms', 'ms', or 'dhms'
 * @property {string} wrapperText
 * @property {boolean} blinkColon makes the colon (:) blink every other second
 * @property {string} sfx if not null, plays a sound effect every second
 */

/**
 * Class for text timer component, combines functionality of G.Timer and G.Text
 * Replaces G.TextTimer
 */
export class TextTimer extends Phaser.Group {
  /**
   * Constructor
   * @param {TextTimerConfig} config
   */
  constructor(config) {
    super(game, null);

    this._text = new G.Text(config.x, config.y, '???', config.style, config.anchor, config.maxWidth, config.maxHeight || null);
    this.add(this._text);

    this.active = false;
    this._secLeft = 0;
    this._timerFormat = config.timerFormat || TIMER_FORMAT.HMS;
    this._wrapperText = config.wrapperText || '';
    this._blinkColon = config.blinkColon || false;
    this._colonVisible = true;
    this._sfx = config.sfx || null;

    this._timerBinding = G.sb('onWallClockTimeUpdate').add(this.updateTimer, this);

    if (config.date) {
      this.setDate(config.date);
    }
  }

  /**
   * Updates the timer
   */
  updateTimer() {
    if (!this.active) return;
    if (this._sfx) this._sfx.play();

    this._secLeft = Math.max(0, this._secLeft - 1);
    this.updateTimerText(this._secLeft, this._colonVisible);
    if (this._blinkColon) this._colonVisible = !this._colonVisible;
  }

  /**
   * Sets the time remaining in seconds
   * @param {number} secLeft
   */
  setSecLeft(secLeft) {
    const _secLeft = parseInt(secLeft, 10);
    this._secLeft = Math.max(0, _secLeft);
    this.updateTimerText(this._secLeft, true);
  }

  /**
   * Updates the timer text
   */
  updateTimerText() {
    let dataArray;
    switch (this._timerFormat) {
      case TIMER_FORMAT.MS: dataArray = G.changeSecToMS(this._secLeft); break;
      case TIMER_FORMAT.HMS: dataArray = G.changeSecToHMS(this._secLeft); break;
      case TIMER_FORMAT.DH: dataArray = G.changeSecToDHMS(this._secLeft).splice(0, 2); break;
      case TIMER_FORMAT.HM: dataArray = G.changeSecToHMS(this._secLeft).splice(0, 2); break;
      default: dataArray = G.changeSecToDHMS(this._secLeft); break; // dhms
    }
    if (this._wrapperText !== '') {
      this._text.setText(this._wrapperText.replace('%TIME%', this._formatTime(dataArray)));
    } else {
      this._text.setText(this._formatTime(dataArray));
    }
  }

  _formatTime(dataArray) {
    if (this._timerFormat === TIMER_FORMAT.DH) {
      return `${dataArray[0]}d ${dataArray[1]}h`;
    }
    return dataArray.join(this._colonVisible ? ':' : ' ');
  }

  /**
   * Optionally sets and starts the timer
   * @param {number} secLeft
   */
  start(secLeft) {
    if (secLeft) this.setSecLeft(secLeft);
    this.active = true;
  }

  /**
   * Sets the timer using date string
   * @param {string} dateString
   */
  setDate(dateString) {
    const ms = new Date(dateString).getTime();
    const now = Date.now();
    const diffSec = Math.ceil((ms - now) / 1000);
    this.setSecLeft(diffSec);
    this.active = true;
  }

  /**
   * Changes format of time display
   * @param {TIMER_FORMAT} targetFormat
   */
  changeTimerFormat(targetFormat) {
    this._timerFormat = targetFormat;
  }

  /**
   * Destroy function
   */
  destroy() {
    super.destroy();
    this.active = false;
    this._timerBinding.detach();
  }

  /**
   * Returns the number of seconds left on the text timer
   * @returns {number}
   */
  get secLeft() {
    return this._secLeft;
  }
}
