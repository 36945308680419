import MockFriendEntry from './MockFriendEntry';

/**
 * This is the Facebook Mock Invite Friends List.
 * @author Sandra K
 */
export default class MockInviteFriend extends Phaser.Group {
  /**
   * @param {Object} config
   * @param {number} friendCount
   * @param {Array}
   */
  constructor(config) {
    super(game, null);

    this.config = config;

    this.signals = {
      onClick: new Phaser.Signal(),
    };

    this.friendEntries = []; // Holds every friend entry so it can access later

    this.drawEverything();
  }

  /**
   * Destroy!!
   */
  destroy() {
    this.signals.onClick.dispose();
    super.destroy();
  }

  /**
   * Draw everything!
   */
  drawEverything() {
    const limitWidth = 380; // The max width of the mock invite list
    const grayColour = 0xF7F7F7;
    const padding = 20;

    this.bgOverlay = new Phaser.Graphics(game); // big dark overlay
    this.bgOverlay.beginFill(0, 0.7);
    this.bgOverlay.drawRect(0, 0, game.width * 1.5, game.height * 1.5);
    this.bgOverlay.endFill();
    this.bgOverlay.inputEnabled = true;
    this.bgOverlay.x -= this.bgOverlay.width / 2;
    this.bgOverlay.y -= this.bgOverlay.height / 2;
    this.addChild(this.bgOverlay);

    const headerHeight = 50; // The total height the header has to be
    const friendGroup = new Phaser.Group(game, null);
    const fakeClose = new G.Text(0, 0, '×', 'mockFacebook-Gray', 0.5); // Fake close button
    const header = new G.Text(limitWidth / 2, 0, OMT.language.getText('Play with Friends'), 'mockFacebook-Black', 0.5, 430, 50, true, 'center');
    header.y = headerHeight / 2;
    fakeClose.x = limitWidth - fakeClose.width - 5;
    fakeClose.y = header.y;
    const headerLine = new Phaser.Graphics(game); // The horizontal line after the header
    headerLine.lineStyle(2, grayColour);
    headerLine.lineTo(limitWidth, 0);
    headerLine.y = headerHeight;

    const divider = new Phaser.Group(game, friendGroup); // The grey divider that says Friends
    const greyBg = new Phaser.Graphics(game);
    greyBg.beginFill(grayColour, 1);
    greyBg.drawRect(0, 0, limitWidth, 30);
    greyBg.endFill();
    const friendText = new G.Text(greyBg.x + 25, 0, OMT.language.getText('Friends'), 'mockFacebook-Gray', [0, 0.5], greyBg.width, greyBg.height, false, 'left');
    friendText.y = greyBg.y + (greyBg.height + (friendText.height / 4)) / 2;
    divider.addChild(greyBg);
    divider.addChild(friendText);
    divider.y = headerLine.y + padding;

    friendGroup.addChild(fakeClose);
    friendGroup.addChild(header);
    friendGroup.addChild(headerLine);
    friendGroup.addChild(divider);

    // Now we fill with friends
    let nextY = divider.y + divider.height + padding;
    const NPCFriends = this.config.friends.sort(() => 0.5 - Math.random());
    for (let i = 0; i < this.config.friendCount; i++) {
      const targetFriend = NPCFriends[i % (NPCFriends.length)]; // Loop through the list of friends if the number of friends exceeds the friends
      const friend = new MockFriendEntry({
        image: targetFriend.image,
        name: OMT.language.getText(targetFriend.name),
        totalWidth: limitWidth,
      });
      friend.y = nextY + (friend.height / 2);
      friend.signals.onClick.add(this.onFriendClicked.bind(this));

      nextY += friend.height + padding;
      friendGroup.addChild(friend);
      this.friendEntries.push(friend);
    }

    // Now we make the white background that will encompass everything else
    this.friendBg = new Phaser.Graphics(game);
    const { friendBg } = this;
    friendBg.beginFill(0xFFFFFF, 1);
    friendBg.drawRoundedRect(0, 0, limitWidth, Math.min(friendGroup.height + (padding * 2), 850), 25);
    friendBg.endFill();

    this.friendBg.addChild(friendGroup);
    this.friendBg.y -= this.friendBg.height / 2;
    this.friendBg.x -= this.friendBg.width / 2;
    this.addChild(this.friendBg);
  }

  /**
   * When a friend entry gets triggered it comes here to go out some more
   * @param {MockFriendEntry} friend
   */
  onFriendClicked(friend) {
    this.signals.onClick.dispatch(friend);
  }

  /**
   * Returns the first entry
   */
  getFirstEntryButton() {
    return this.friendEntries[0];
  }
}
