import { CollapsedUserViewBase } from '../Components/CollapsedView/CollapsedUserViewBase';

import {
  USER_VIEW_RECT,
  USER_VIEW_AVATAR_SIZE, USER_VIEW_AVATAR_POS,
  USER_VIEW_TEXT_MAX_WIDTH,
  USER_VIEW_USERNAME_TEXT_STYLE, USER_VIEW_USERNAME_POS,
  USER_VIEW_SCORE_TEXT_STYLE, USER_VIEW_SCORE_POS,
  USER_VIEW_BG_RECT,
} from '../LevelLeaderboardSettings';

/**
 * class for level leaderboard user view
 */
export class LevelLeaderboardUserView extends CollapsedUserViewBase {
  /**
   * main initialization method
   * @param {Object} friendData friendData to display
   */
  init(friendData) {
    // create signals
    this.signals.onCoinRequest = new Phaser.Signal();

    // init user view displays
    super.init(friendData, USER_VIEW_RECT);
    this._initBGImage();
    this._initNameField();
    this._initScoreField();

    // cache content container
    this.cacheContent = true;
  }

  /**
   * draw the bg rect for Phaser to detect the bounds correctly and help with layout
   * @param {Phaser.Rectangle} contentRect
   */
  _drawBGRect(contentRect) {
    super._drawBGRect(contentRect, 0, 0, 0xFF0000);
  }

  /**
   * init the bg image
   */
  _initBGImage() {
    const bgImage = G.makeImage(0, 0, 'highscore-h-playerBg_small', [0, 0]);
    bgImage.x = USER_VIEW_BG_RECT.x; bgImage.y = USER_VIEW_BG_RECT.y;
    bgImage.width = USER_VIEW_BG_RECT.width; bgImage.height = USER_VIEW_BG_RECT.height;
    this._cacheableContent.addChildAt(bgImage, 0);
  }

  /**
   *initialize the user avatar group inside a button so its clickable
   */
  _initAvatar() {
    super._initAvatar(0, 0, USER_VIEW_AVATAR_SIZE, this._friendData.rank);
    this._avatar.x = USER_VIEW_AVATAR_POS.x;
    this._avatar.y = USER_VIEW_AVATAR_POS.y;
  }

  /**
   * initialize the user name field
   */
  _initNameField() {
    this._nameField = new G.Text(
      USER_VIEW_USERNAME_POS.x,
      USER_VIEW_USERNAME_POS.y,
      this._shortenName(this._friendData.name),
      USER_VIEW_USERNAME_TEXT_STYLE,
      [0, 0.5], // anchor
      USER_VIEW_TEXT_MAX_WIDTH,
      null,
      false,
    );
    this._cacheableContent.addChild(this._nameField);
  }

  /**
   * initialize the user name field
   */
  _initScoreField() {
    this._nameField = new G.Text(
      USER_VIEW_SCORE_POS.x,
      USER_VIEW_SCORE_POS.y,
      this._friendData.score,
      USER_VIEW_SCORE_TEXT_STYLE,
      [0, 0.5], // anchor
      USER_VIEW_TEXT_MAX_WIDTH,
      null,
      false,
    );
    this._cacheableContent.addChild(this._nameField);
  }
}
