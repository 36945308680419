import {
  STAR_GROUP_FONT_STYLE,
  STAR_GROUP_TEXT_WIDTH_RATIO,
  STAR_GROUP_BG_SCALE,
  STAR_GROUP_STAR_SCALE,
  STAR_GROUP_STAR_ANGLE,
  STAR_GROUP_TEXT_OFFSET,

} from '../../ComponentSettings';

/**
 * class for stylized star display in the leaderboard
 */
export class StarDisplayGroup extends Phaser.Group {
  /**
   * constructor
   */
  constructor(score = 0) {
    super(game);

    this._initBGGraphics();
    this._initScoreField();
    this.setScore(score);
  }

  /**
   * init background graphics (bg image + star image)
   */
  _initBGGraphics() {
    this._bgImage = G.makeImage(0, 0, 'lb2_points_box', [0, 0.5], this);
    this._bgImage.scale.set(STAR_GROUP_BG_SCALE);
    this._starImage = G.makeImage(0, 0, 'star', [0.5, 0.5], this);
    this._starImage.angle = STAR_GROUP_STAR_ANGLE;
    this._starImage.scale.set(0.375 || STAR_GROUP_STAR_SCALE);
  }

  /**
   * update the score text
   */
  _initScoreField() {
    const maxWidth = this._bgImage.width * STAR_GROUP_TEXT_WIDTH_RATIO;
    const maxHeight = this._bgImage.height;
    const textOffsetX = Math.round(this._bgImage.width / 2) + STAR_GROUP_TEXT_OFFSET.x;
    const textOffsetY = STAR_GROUP_TEXT_OFFSET.y;
    this._scoreField = new G.Text(textOffsetX, textOffsetY, '', STAR_GROUP_FONT_STYLE, [0.5, 0.5], maxWidth, maxHeight, false);
    this.addChild(this._scoreField);
  }

  /**
   * update the score text
   * @param {number} score
   */
  setScore(score) {
    this._scoreField.text = `${score > 0 ? score : '???'}`;
  }
}
