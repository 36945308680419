import { UI_LoadingIndicatorDisplay } from '@omt-components/UI/Loading/UI_LoadingIndicatorDisplay';
import { GameScaleController } from '../../States/Scaling/GameScaleController';
import { Window } from '../../00_IMMEDIATE/Window';

export default class Window_delayedWaiting extends Window {
  /**
   * A window that shows a loading indicator and waits for something to happen before calling the callback function.
   *
   * If passed is true, it will just fire the callback function, otherwise it will wait for the G.sb(signal) to fire.
   * Though, after 5 seconds of nothing, it will show the close button
   * @param {DisplayObject} parent
   * @param {Boolean} passed
   * @param {string} GSBSignal
   * @param {function} callback
   */
  constructor(parent, passed, GSBSignal, callback) {
    super(parent);

    this._callback = callback;
    this._init(passed, GSBSignal);
  }

  /**
   * Sets up a signal to await for a G.sb signal. If the value passed in was already true, we skip the signal waiting
   * @param {boolean} passed
   * @param {string} signal
   */
  _init(passed, signal) {
    if (passed) { this._signalRecieved(); return; }
    this._gameScale = GameScaleController.getInstance().gameScale;

    G.sb(signal).addOnce(this._signalRecieved.bind(this));
    this.addCloseButton(0, 0);
    this.closeButton.alpha = 0;
    this.closeButton.x = ((game.width * this._gameScale) - this.closeButton.width) / 2;
    this.closeButton.y = (this.closeButton.height - (game.height * this._gameScale)) / 2;
    game.time.events.add(5000, this._showCloseButton.bind(this));

    this._loadingIndicator = new UI_LoadingIndicatorDisplay();
    game.world.addChild(this._loadingIndicator);
    this._loadingIndicator.show();
  }

  /**
   * Shows the close button
   */
  _showCloseButton() {
    if (this.closeButton) {
      game.add.tween(this._closeBtn) // Bring in the close button
        .to({ alpha: 1 }, 200, Phaser.Easing.Sinusoidal.InOut);
    }
  }

  /**
   * Calls the callback and leaves
   */
  _signalRecieved() {
    if (this._callback) {
      this._callback();
    }
    this.closeWindow();
  }

  /**
   * Close the window!
   */
  closeWindow() {
    if (this._loadingIndicator) {
      this._loadingIndicator.hide();
      this._loadingIndicator.destroy();
      this._loadingIndicator = null;
    }
    super.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.delayedWaiting = Window_delayedWaiting;
