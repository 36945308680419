/* eslint-disable no-unused-vars */
import OMT_UI_VerticalScrollArea from './OMT_UI_VerticalScrollArea';
import OMT_UI_VerticalScrollBarConfig from './ScrollBarConfig/OMT_UI_VerticalScrollBarConfig';

/**
 * class for windows with scrollable content
 */
export default class OMT_UI_RoundedScrollArea extends OMT_UI_VerticalScrollArea {
  /**
   * constructor
   * @param {Phaser.Rectangle} scrollRect Rectangle for scrollable area
   * @param {number} cornerRadius size for rounded corners
   * @param {boolean} hasScrollBar should the scrollArea have a scrollbar?
   * @param {OMT_UI_VerticalScrollBarConfig} scrollBarConfig Class reference for the scroll bar config. Should extend OMT_UI_ScrollBarConfigBase.
   */
  constructor(scrollRect, cornerRadius, hasScrollBar = true, scrollBarConfig = null) {
    super(scrollRect, hasScrollBar, scrollBarConfig);
    this._initRoundedScrollMask(cornerRadius);
  }

  /**
   * override mask creation
   */
  _initScrollMask() {
    // we dont want this to get called
  }

  /**
   * init / apply the scroll mask
   */
  _initRoundedScrollMask(cornerRadius) {
    this._scrollMask = new Phaser.Graphics(game);
    this._scrollMask.beginFill(0xff0000);
    this._scrollMask.drawRoundedRect(0, 0, this._scrollRect.width, this._scrollRect.height, cornerRadius);
    this._scrollMask.endFill();
    this._scrollMask.enabled = false;
    this.addChildAt(this._scrollMask, 0);
    this._content.mask = this._scrollMask;
  }
}
