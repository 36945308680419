G.SPDM_CupcakeIcon = function(x, y, filled) {
  this.dailyIcons = G.dailyMissionsMgr.getCollectionIcons();

  G.Image.call(this, x, y, filled ? this.dailyIcons.icon : this.dailyIcons.hole, 0.5);

  this.overlay = G.makeImage(0 ,0, this.dailyIcons.icon, 0.5, this);
  this.overlay.visible = false;
  this.filled = filled;

};

G.SPDM_CupcakeIcon.prototype = Object.create(G.Image.prototype);

G.SPDM_CupcakeIcon.prototype.isEmpty = function() {
  return !this.filled;
};

G.SPDM_CupcakeIcon.prototype.fill = function() {
  this.filled = true;
  this.changeTexture(this.dailyIcons.icon);
  this.overlay.visible = true;
  this.overlay.alpha = 0;
  this.overlay.blendMode = 1;
  game.add.tween(this.overlay)
    .to({alpha: 1}, 200, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
};