import { Window_GameModePromoBase } from './Window_GameModePromoBase';
import { UI_TournamentIcon } from '../../UI_TournamentIcon';
import { TournamentPromo_TutorialText } from './Components/TournamentPromo_TutorialText';
import { TournamentPromo_Header } from './Components/TournamentPromo_Header';

/**
 * class for tournament promotion window.
 */
export default class Window_TournamentPromo extends Window_GameModePromoBase {
  /**
  * constuctor
   * @param {Object} parent
   * @param {Object} config { icon:UI_TournamentIcon }
   */
  constructor(parent, config) {
    super(parent, config);
    G.saveState.setTournamentPromoSeen();

    this._tutorialTextKey = "Tournament? What's that? We should try tapping on it!";
  }

  /**
   * initialize display elements
   */
  _initDisplayElements() {
    this._initHeaderGroup(TournamentPromo_Header);
    this._initTutorialText(TournamentPromo_TutorialText);
    this._initGamePromoIcon(UI_TournamentIcon);
  }

  /**
   * show the tournament window after clicking on the icon
   */
  async _showWindow() {
    this._mapIcon.alpha = 1;
    await this._promoIcon.showTournamentWindow();
    this.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.tournamentPromotion = Window_TournamentPromo;
