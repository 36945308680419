import ShareMsgBase from './A_G.ShareMsgBase';

class MsgGateRequest extends ShareMsgBase {
  /**
   * The message that is sent when you want a friend's help in opening a gate
   * @param {function} onAllImagesReadyCallback
   */
  constructor(onAllImagesReadyCallback = null) {
    super(1, onAllImagesReadyCallback);
    this.bg = this._addExtImage(0, 0, 'img/Msg_Gate_Request.png', 0);
  }
}

G.MsgGateRequest = MsgGateRequest;
