/* eslint-disable no-use-before-define */

import { default as OMT_UI_ExpandingButtonList, ExpandTriggerButton, ICON_LAYOUT_STYLE } from '../OMT_UI_ExpandingButtonList';
import OMT_UI_HomeButton from '../../Buttons/Settings/OMT_UI_HomeButton';
import OMT_UI_SoundButton from '../../Buttons/Settings/OMT_UI_SoundButton';
import OMT_UI_MusicButton from '../../Buttons/Settings/OMT_UI_MusicButton';
import OMT_UI_SupportButton from '../../Buttons/Settings/OMT_UI_SupportButton';
import OMT_UI_FullScreenButton from '../../Buttons/Settings/OMT_UI_FullscreenButton';
import OMT_UI_HintButton from '../../Buttons/Settings/OMT_UI_HintButton';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

export { ICON_LAYOUT_STYLE } from '../OMT_UI_ExpandingButtonList';

export const BUTTON_TYPES = { // You can access buttons by name with this.getButtonByKey()
  MENU: 'MENU',
  MUSIC: 'MUSIC',
  SOUND: 'SOUND',
  SUPPORT: 'SUPPORT',
  FULLSCREEN: 'FULL_SCREEN',
  HINT: 'HINT',
  HOME: 'HOME',
};

/**
 * Expandable settings menu. Supports veritcal and horizonal layouts.
 */
export default class OMT_UI_SettingsMenu extends OMT_UI_ExpandingButtonList {
  /**
   * constructor
   */
  constructor() {
    super();

    this._keepOpen = false; // New thing for tutorials. Forces the settings menu to stay open

    if (game.state.current === 'World') {
      this.initButtons(ICON_LAYOUT_STYLE.VERTICAL, this._getWorldStateButtonList(), new SettingsTriggerButton());
    } else if (game.state.current === 'Game') {
      if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
        this.initButtons(ICON_LAYOUT_STYLE.HORIZONTAL, this._getGameStateButtonList(), new SettingsTriggerButton());
      } else {
        this.initButtons(ICON_LAYOUT_STYLE.INVERSE_HORIZONTAL, this._getGameStateButtonList(), new SettingsTriggerButton());
      }
    }

    this._signalBindings = [];
    this._signalBindings.push(G.sb('disableGameBottomBar').add(() => {
      this.lockInput();
    }));
  }

  /**
   * get the menu button list for the 'World' state
   * @returns {Array}
   */
  _getWorldStateButtonList() {
    const buttonList = [];
    buttonList.push({ key: BUTTON_TYPES.MUSIC, obj: new OMT_UI_MusicButton() });
    buttonList.push({ key: BUTTON_TYPES.SOUND, obj: new OMT_UI_SoundButton() });
    // buttonList.push({ key: BUTTON_TYPES.SUPPORT, obj: new OMT_UI_SupportButton() });
    if (G.IAP) {
      buttonList.push({ key: BUTTON_TYPES.SUPPORT, obj: new OMT_UI_SupportButton() });
    }

    if (OMT.feature.isFullScreenSupported()) {
      buttonList.push({ key: BUTTON_TYPES.FULLSCREEN, obj: new OMT_UI_FullScreenButton() });
    }
    return buttonList;
  }

  /**
   * get the menu button list for the 'Game' state
   * @returns {Array}
   */
  _getGameStateButtonList() {
    const buttonList = [];
    buttonList.push({ key: BUTTON_TYPES.MUSIC, obj: new OMT_UI_MusicButton() });
    buttonList.push({ key: BUTTON_TYPES.SOUND, obj: new OMT_UI_SoundButton() });
    buttonList.push({ key: BUTTON_TYPES.HINT, obj: new OMT_UI_HintButton() });
    buttonList.push({ key: BUTTON_TYPES.HOME, obj: new OMT_UI_HomeButton(this) });
    return buttonList;
  }

  /**
   * set to force menu to stay open.
   * @param {boolean} state
   */
  set keepOpen(state) { // New tutorial setting to keep the menu open
    this._keepOpen = state;
  }

  /**
   * override unlockInput to set this._keepOpen
   */
  unlockInput() {
    this._keepOpen = false; // Automatically fixes it
    super.unlockInput();
  }

  /**
   * override _checkPointerDownOutside() to respect this._keepOpen
   */
  _checkPointerDownOutside() { // Prevents the menu from hiding again when anywhere on the board is clicked when we wnat a specific click
    if (!this._keepOpen) {
      super._checkPointerDownOutside();
    }
  }

  /**
   * override _toggleChildInput() to respect this._keepOpen
   * @param {boolean} enableState
   */
  _toggleChildInput(enableState) {
    if (!this._keepOpen) { // Prevents any tween from fiddling with the input locked-ness during tutorial mode
      super._toggleChildInput(enableState);
    }
  }

  /**
   * show a suggestion / hint hand for a button
   * @param {Object} buttonKey key of buton to target
   */
  showSuggestion(buttonKey) {
    if (!this.hand) {
      this.hand = new Phaser.Group(game);
      const handImg = G.makeImage(-24 / 90, -11 / 98, 'tut_hand', 0, this.hand);
      this.hand.angle = 230;
      this.hand.handTween = game.add.tween(handImg)
        .to({ y: 15 }, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    }
    game.world.addChild(this.hand);
    this.hand.visible = true;

    if (buttonKey === BUTTON_TYPES.MENU) {
      this.hand.target = this._expandTriggerButton;
    } else {
      this.hand.target = this.getButtonByKey(buttonKey);
    }
  }

  /**
   * hide suggestion / hint hand
   */
  hideSuggestion() {
    if (this.hand) {
      this.hand.target = null;
      this.hand.visible = false;
    }
  }

  /**
   * add hints updating to the update function
   */
  update() {
    super.update();
    if (this.hand && this.hand.target) {
      const converted = game.world.toLocal({ x: this.hand.target.x, y: this.hand.target.y }, this.hand.target.parent);
      if (!(this.hand.x === converted.x && this.hand.y === converted.y)) {
        this.hand.x = converted.x;
        this.hand.y = converted.y;
      }
    }
  }

  /**
   * destruction method
   */
  destroy() {
    if (this.hand) this.hand.destroy();
    if (this._signalBindings) {
      this._signalBindings.forEach((sig) => {
        if (sig && sig.detach) {
          sig.detach();
        }
      });
      this._signalBindings = null;
    }
    super.destroy();
  }
}

/**
 * settings menu trigger button. adds the settings icon and animation.
 */
class SettingsTriggerButton extends ExpandTriggerButton {
  /**
   * constructor
   */
  constructor() {
    super();
    this._icon = G.makeImage(0, 0, 'settings_icon', [0.5, 0.5]);
    this.addChild(this._icon);
  }

  /**
   * on expand toggle
   */
  _onExpand() {
    super._onExpand();
    G.sb('settingsMenuStateChange').dispatch({ expand: true });
    game.add.tween(this._icon).to({ angle: 360 }, 333, Phaser.Easing.Cubic.Out, true);
  }

  /**
   * on contract toggle
   */
  _onContract() {
    super._onContract();
    G.sb('settingsMenuStateChange').dispatch({ expand: false });
    game.add.tween(this._icon).to({ angle: -360 }, 333, Phaser.Easing.Cubic.Out, true);
  }
}
