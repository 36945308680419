let _instance;

const STATES_WITH_TILE_BG = [];
const STANDARD_WIDTH = [640, 960];
const STANDARD_HEIGHT = [960, 640];

export class GameScaleController {
  /**
   * get static instance
   * @returns {GameScaleController}
   */
  static getInstance() {
    if (!_instance) _instance = new GameScaleController();
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    this._bgImg = null;
    this._gameScale = 1;
  }

  /**
   * configure game scaling / scale events
   * @param {Phaser.Game} game
   */
  init(game) {
    this._configureGameScaling(game);
  }

  /**
   * configure game scaling / scale events
   * @param {Phaser.Game} game
   */
  _configureGameScaling(game) {
    game.scale.scaleMode = Phaser.ScaleManager.SHOW_ALL;
    game.scale.pageAlignHorizontally = true;
    game.scale.pageAlignVertically = true;
    this._gameScale = STANDARD_HEIGHT[OMT.systemInfo.orientation] / 960;

    if (FBInstant.deviceDectorFunctions().isMobileOrTab()) {
      game.resizeGame = () => {
        setTimeout(() => {
          this._resizeGame(game);
        }, 500);
      };
    } else {
      game.resizeGame = () => { this._resizeGame(game); };
    }

    // configure state change callback for setting background
    game.state.onStateChange.add(() => {
      this._updateTilingSprite(game);
    });

    game.scale.setResizeCallback(() => { // resize game if window size changes
      if (game._oldWidth !== window.innerWidth || game._oldHeight !== window.innerHeight) {
        game._oldWidth = window.innerWidth;
        game._oldHeight = window.innerHeight;
        game.resizeGame();
      }
    });
  }

  /**
   * called on window resize to resize game area.
   * @param {Phaser.Game} game
   */
  _resizeGame(game) {
    const state = game.state.getCurrentState();
    if (state.NOTRESIZABLE) return; // dont resize

    if (FBInstant.deviceDectorFunctions().isMobileOrTab() && window.orientation !== 0) return;
    // const standardWidth = STANDARD_WIDTH[OMT.systemInfo.orientation];
    // const standardHeight = STANDARD_HEIGHT[OMT.systemInfo.orientation];
    const standardWidth = (window.orientation === 0) ? STANDARD_WIDTH[0] : STANDARD_WIDTH[1];
    const standardHeight = (window.orientation === 0) ? STANDARD_HEIGHT[0] : STANDARD_HEIGHT[1];
    const standardRatio = standardWidth / standardHeight;
    const ratio = window.innerWidth / window.innerHeight;
    this._gameScale = standardHeight / 960;

    if (ratio > standardRatio) { // if current aspect is wider then the standard aspect.
      game.scale.setGameSize(Math.ceil(standardHeight * ratio), standardHeight);
      game.world.setBounds(
        Math.ceil(-0.5 * (game.width - standardWidth)), 0,
        game.width, game.height,
      );
    } else { // aspect is taller then the standard aspect.
      game.scale.setGameSize(standardWidth, Math.ceil(standardWidth / ratio));
      game.world.setBounds(
        Math.ceil(-0.5 * (game.width - standardWidth)), 0,
        Math.ceil(-0.5 * (game.height - standardHeight)), game.height,
      );
    }

    // resize the game background tile sprite
    this._updateTilingSprite(game);

    // dispatch screen resize event to the rest of the game
    G.sb('onScreenResize').dispatch(game.width, game.height);
  }

  /**
   * update the background tiling sprite
   * @param {Phaser.Game} game
   */
  _updateTilingSprite(game) {
    if (STATES_WITH_TILE_BG.indexOf(game.state.current.toString()) === -1) {
      this._destroyTilingSprite();
      return; // currently only using this for the World State
    }
    if (!this._bgImg) this._createTilingSprite();
    this._bgImg.width = game.width;
    this._bgImg.height = game.height;
  }

  /**
   * create the tiling background
   */
  _createTilingSprite() {
    this._bgImg = game.add.tileSprite(0, 0, game.width, game.height, 'bg_tile');
    game.stage.addChildAt(this._bgImg, 0);
  }

  /**
   * destroy the tiling sprite
   */
  _destroyTilingSprite() {
    if (this._bgImg) {
      if (this._bgImg.parent) this._bgImg.parent.removeChild(this._bgImg);
      this._bgImg.destroy();
      this._bgImg = null;
    }
  }

  get gameScale() {
    return this._gameScale;
  }
}
