/**
 * class for animating a DisplayObjects state from 1 state to another
 */
export class OMT_TransformAnimationController {
  /**
   * constructor
   * @param {Phaser.DisplayObject} target transform target
   */
  constructor(target) {
    this._target = target;
    this._activeTweens = [];
  }

  /**
   * get a snapshot of the targets current state
   * @param {Array.<string>} paramList parameters to capture
   * @returns {Object} an object storing the values of the requested parameters
   */
  getTranformSnapshot(paramList) {
    const snapshot = {};
    for (const paramName of paramList) {
      snapshot[paramName] = this._target[paramName];
    }
    return snapshot;
  }

  /**
   * apply a transform snapshot, but do not animate
   * @param {Object} transformSnapshot snapshot captured with getTransformSnapshot() or manually created
   */
  applyTransform(transformSnapshot) {
    Object.assign(this._target, transformSnapshot);
  }

  /**
   * animate to a transform snapshot
   * @param {Object} transformSnapshot snapshot captured with getTransformSnapshot() or manually created
   * @param {Function} easing Phaser.easing function
   * @param {number} duration milliseconds
   * @param {number} delay (optional) milliseconds
   * @param {Function} onCompleteCallback (optional) onComplete callback
   */
  animateToSnapshot(transformSnapshot, easing, duration, delay = 0, onCompleteCallback = null) {
    const tween = game.add.tween(this._target);
    tween.to(transformSnapshot, duration, easing, false, delay);
    this._activeTweens.push(tween);
    tween.onComplete.addOnce(() => {
      this._onTweenComplete(tween, onCompleteCallback);
    });
    tween.start();
  }

  /**
   * on tween completed
   * @param {Phaser.Tween} tween triggering tween instance
   * @param {Function} onCompleteCallback (optional) onComplete callback
   */
  _onTweenComplete(tween, onCompleteCallback = null) {
    const tweenIndex = this._activeTweens.indexOf(tween);
    this._activeTweens.splice(tweenIndex, 1);
    if (onCompleteCallback) onCompleteCallback();
  }

  /**
   * stop all active tweens
   */
  stopAllTweens() {
    for (const tween of this._activeTweens) tween.stop();
    this._activeTweens.length = 0;
  }

  /**
   * destruction method, please call
   */
  destroy() {
    this.stopAllTweens();
  }
}
