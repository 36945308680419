import { GameModePromo_HeaderBase } from './GameModePromo_HeaderBase';

const HEADER_TEXT_STYLE = { style: 'ui-tournament-promo', fontSize: 54 };

/**
 * Class for Tournament Promotion Header
 */
export class TournamentPromo_Header extends GameModePromo_HeaderBase {
  /**
   * constructor
   */
  constructor() {
    super('Tournament', 'tournament_promo_header_bg', HEADER_TEXT_STYLE);
  }
}
