import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import OMT_VILLAINS from '../../../../../OMT_UI/OMT_Villains';

export default class UI_GoalPanelPointsBase extends Phaser.Group {
  /**
   * The goal panel for when its collecting points rather than gems
   * @param {number} x
   * @param {number} y
   */
  constructor(x, y) {
    super(game);

    this.x = Math.floor(x);
    this.y = Math.floor(y);

    this._state = game.state.getCurrentState(); // Game.js

    this._drawEverything();

    this._signalBinding = G.sb('onScreenResize').add(this._onScreenResize, this);
    this._onScreenResize();
  }

  /**
   * Draw everything
   * @param {LevelType} gameMode
   */
  _drawEverything(gameMode) { // eslint-disable-line no-unused-vars
    const { points } = this._getTextStyles(gameMode);
    this._pointsCounter = new G.Text(0, 0, 0, points, 0.5, 150);
  }

  /**
   * Returns the text styles used for this class
   * @param {LevelType} gameMode
   * @returns {{label:(string|object), points:(string|object)}}
   */
  _getTextStyles() {
    const lvlDataManager = LvlDataManager.getInstance();
    const { isTokenEventLevel, isDailyChallengeLevel } = lvlDataManager;
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;

    this._objectsToCollect = JSON.parse(JSON.stringify(lvlDataManager.collectGoals));

    let labelTextStyle = G.OMTsettings.elements.UI_GoalPanelPoints.labelTxt.style;
    let pointsCounterStyle = G.OMTsettings.elements.UI_GoalPanelPoints.pointsCounter.style;

    if (isNotNormalLevel) {
      const difficultyName = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_2') : OMT_VILLAINS.getPrefixedName('hard_2');
      labelTextStyle = {
        style: difficultyName,
        fontSize: 50,
      };
      pointsCounterStyle = {
        style: difficultyName,
        fontSize: 50,
      };
    } else if (isTokenEventLevel) {
      labelTextStyle = 'UIGoalPanelPointsLabel-tokenEvent';
      pointsCounterStyle = 'UIGoalPanelPointAmount-tokenEvent';
    }

    return {
      label: labelTextStyle,
      points: pointsCounterStyle,
    };
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._signalBinding) {
      if (this._signalBinding.detach) {
        this._signalBinding.detach();
      }
      this._signalBinding = null;
    }
    super.destroy();
  }

  /**
   * Updates text
   */
  update() {
    this._pointsCounter.setText(this._state.topBar.pointsCounter.text);
  }

  /**
   * Repositions layout
   */
  _onScreenResize() {
    // Override
  }
}
