export default class PoolGroup extends Phaser.Group {
  /**
   * A Phaser.Group that holds instances of given Class<T>.
   * Instances will be killed and revived repeatedly for as long as it is required.
   * Can pass in multiple Class<T> in an object with key-object values. The pool group then must use the key when using
   * getFreeElement() or kill. In the case that the key is not passed, it will default to the first element created
   *
   * Arguments in argumentsArray will be passed into every newly constructed element of Class<T>
   *
   * Elements in the PoolGroup can be created by the signal passed in
   * @param {({string, Class<T>}|Class<T>)} elementConstructor
   * @param {Array} argumentsArray
   * @param {string} signal
   * @param {Array<elementConstructor>} initFill
   */
  constructor(elementConstructor, argumentsArray, signal, initFill) {
    super(game, null);

    this._deadObjects = {}; // A collection of dead objects
    this._constructors = {}; // Constructors to be used for PoolGroup
    if (typeof elementConstructor === 'function') { // If the passed in elementConstructor is not an array, make it one
      const givenClass = elementConstructor;
      elementConstructor = {};
      elementConstructor[givenClass.name] = givenClass;
    }
    // eslint-disable-next-line guard-for-in
    for (const eleKey in elementConstructor) { // Initializes the poolGroups for the element
      const ele = elementConstructor[eleKey];
      this._deadObjects[eleKey] = [];
      this._constructors[eleKey] = ele;
    }

    // The default key that will be used when no key is given
    this._defaultKey = Object.keys(this._constructors)[0]; // eslint-disable-line prefer-destructuring

    // Argument array that will be applied to all newly constructed elements
    this._argumentsArray = argumentsArray || [];
    this._argumentsArray.unshift(null);

    if (signal) { // Create with signal
      this._signalToken = G.sb(signal).add(this.init, this);
    }

    if (initFill) { // Immediately creates stuff
      for (let i = 0; i < initFill; i++) {
        const element = new (Function.prototype.bind.apply(this._constructors[this._defaultKey], this._argumentsArray))();
        this.add(element);
        element.events.onKilled.add(this._onElementKilled, this, 0, this._defaultKey);
        element.kill();
      }
    }
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._signalToken) {
      if (this._signalToken.detach) {
        this._signalToken.detach();
      }
      this._signalToken = null;
    }
    for (const key in this._deadObjects) { // eslint-disable-line guard-for-in
      for (const obj of this._deadObjects[key]) {
        if (obj && obj.destroy) {
          obj.destroy();
        }
      }
      this._deadObjects[key].length = 0;
    }
    super.destroy();
  }

  /**
   * Gets a free element using the passed in key
   * If no key is passed, it will assume the first element of the passed in constructors, or if it wasn't an array, the only constructor
   * In the case that the key is a Class<T>, it will use the name of the Class as the key
   *
   * This function can return null if the given key does not exist in the originally passed in constructors
   * @param {(string|Class<T>)} key
   * @returns {(Phaser.Object|null)}
   */
  getFreeElement(key) {
    // No key given? Default to first thing in the constructors
    if (!key) {
      key = this._defaultKey; // eslint-disable-line prefer-destructuring
    }

    if (!this._constructors[key]) { return null; } // Key doesn't exist? Don't try.

    let element;
    if (this._deadObjects[key].length > 0) {
      element = this._deadObjects[key].pop();
    } else {
      element = new (Function.prototype.bind.apply(this._constructors[key], this._argumentsArray))();
      element.events.onKilled.add(this._onElementKilled, this, 0, key);
    }

    this.add(element, true);
    return element;
  }

  /**
   * When an element is killed, it will be placed into the deadObjects array of that Class<T>
   * @param {Class<T>} elem
   * @param {string} key
   */
  _onElementKilled(elem, key) {
    if (this !== elem.parent) return;
    if (!this._deadObjects[key]) { return; } // key doesn't exist? Don't do it
    this._deadObjects[key].push(elem);
    this.removeChild(elem);
  }

  /**
   * Inits the element via signal or initBatch
   */
  init(key) {
    const elem = this.getFreeElement(key);
    elem.init.apply(elem, arguments); // eslint-disable-line prefer-spread, prefer-rest-params

    return elem;
  }

  /**
   * Inits a number of elements deteremined by nr
   * @param {number} nr
   */
  initBatch(nr) {
    for (let i = 0; i < nr; i++) {
      this.init.apply(this, [].slice.call(arguments, 1)); // eslint-disable-line prefer-spread, prefer-rest-params
    }
  }
}

G.PoolGroup = PoolGroup;
