import { OMT_AssetLoader } from '../../../Services/OMT/OMT_AssetLoader';
import { RUNTIME_SPRITESHEET_IDS } from '@omt-components/Imaging/Spritesheets/RuntimeSpritesheetManager';
import WorldMap2_Util, { interactionType } from '../WorldMap2_Util';

/* eslint-disable no-use-before-define */
export default class WorldMapMailboxEntry extends G.PoolGroup {
  /**
   * The pool group for the mailbox entry
   */
  constructor() {
    super(WorldMapMailbox);

    this._interactionType = interactionType.mailbox; // Set interaction type as mailbox
    this._actives = [];
    this.signals = {
      onMailboxClick: new Phaser.Signal(),
      onMailboxError: new Phaser.Signal(),
    };
  }

  /**
   * Move active tiles by deltaY
   * @param {number} deltaY
   */
  moveTiles(deltaY) {
    // update active segments
    for (let i = this._actives.length - 1; i >= 0; i--) {
      this._actives[i].y += deltaY;
    }
  }

  /**
   * Kill tile at index
   * @param {number} index
   */
  killTile(index) {
    const tile = this._actives.find((seg) => seg.index === index);
    if (tile) {
      tile.kill();
      this._actives.splice(this._actives.indexOf(tile), 1);
    }
  }

  /**
   * Add mailbox tile with the given config
   * @param {{index: number, unlockLevel:number, seed:number, startY:number, tileIndex:number, dummy:boolean}} config
   * @returns {(WorldMapMailboxEntry | null)}
   */
  addTile(config) {
    if (config.index > 0) { return null; }
    if (!config.dummy) {
      const doesMailboxExist = G.saveState.mailboxManager.doesMailboxExistAtLevel(config.unlockLevel);
      if (!doesMailboxExist) { return null; }
    }

    const mailbox = this.getFreeElement();
    mailbox.interactionType = this._interactionType;
    // Find positioning
    const positoning = WorldMap2_Util.getEntryInArrayLoop(WorldMap2_Util.getEntryInArrayLoop(G.OMTsettings.elements.worldMap.mailboxPositions, config.tileIndex), config.seed);
    mailbox.x = positoning.x;
    mailbox.mailboxScale = positoning.scale; // Set facing
    mailbox.y = positoning.y + config.startY;
    mailbox.init(config.index, config.unlockLevel); // init
    mailbox.onClick.add(() => {
      if (mailbox.interactive) {
        this.signals.onMailboxClick.dispatch(mailbox);
      } else {
        this.signals.onMailboxError.dispatch(mailbox, OMT.language.getText('Win level %LEVEL%').replace('%LEVEL%', mailbox.unlockLevel));
      }
    });
    if (!config.dummy) {
      this._preloadMailboxAssets();
    }
    this._actives.push(mailbox);
    this.addChild(mailbox);
    return this;
  }

  /**
   * Starts to preload the mailbox assets
   */
  async _preloadMailboxAssets() {
    if (!OMT_AssetLoader.getInstance().areSecondaryImagesLoaded(['mailbox'])) {
      await OMT_AssetLoader.getInstance().loadSecondaryImages('mailbox', true, RUNTIME_SPRITESHEET_IDS.MAILBOX);
    }
  }
}

class WorldMapMailbox extends G.Button {
  /**
   * The mailbox image
   */
  constructor() {
    super(0, 0, null);
    this.scaleOnClick = false;
    this.anchor.y = 1;
    this._interactive = false;
    this._allTweens = [];
    this._mailbox = G.makeImage(0, 0, 'map_mailbox_disabled', [0.5, 1], this);

    this._signalTokens = [
      G.sb('pauseAllUpdate').add(this._pauseAnimation.bind(this)),
      G.sb('resumeAllUpdate').add(this._resumeAnimation.bind(this)),
    ];
    this._resumeAnimation();
  }

  /**
   * Destroy!
   */
  destroy() {
    for (const signal of this._signalTokens) if (signal) signal.detach();
    this._signalTokens.length = 0;
    super.destroy();
  }

  set mailboxScale(sc) {
    this._mailbox.scale.x = sc;
  }

  /**
   * The init
   * @param {number} index
   * @param {number} unlockLevel
   */
  init(index, unlockLevel) {
    this.index = index;
    this._unlockLevel = unlockLevel;
    const isEligibleToOpen = G.saveState.getLastPassedLevelNr() >= this._unlockLevel; // It can it opened
    this.scale.set(1);
    if (isEligibleToOpen) {
      G.changeTexture(this._mailbox, 'map_mailbox_closed'); // Waiting for open!!
      this._interactive = true;
    } else {
      G.changeTexture(this._mailbox, 'map_mailbox_disabled'); // Not openable
      this._interactive = false;
    }
    if (this._interactive) { // If its openable, we want to jump
      this._loopTimer = game.time.events.loop(2000, this.jump.bind(this));
    }
    this.revive();
  }

  /**
   * Was the mailbox opened before?
   * @returns {boolean}
   */
  get isOpened() {
    return this._isOpened;
  }

  /**
   * Get the interaction state
   * @returns {boolean}
   */
  get interactive() {
    return this._interactive;
  }

  /**
   * Returns the unlock level for this mailbox
   * @returns {number}
   */
  get unlockLevel() {
    return this._unlockLevel;
  }

  /**
   * When the mailbox is opened, set the neccessary flags
   */
  setToOpened() {
    G.saveState.mailboxManager.markMailboxAsSeen(this._unlockLevel, true);
    this._interactive = false;
    this.onClick.removeAll();
    G.changeTexture(this._mailbox, 'map_mailbox_open');
    this._killJumpTimer();
    this.scale.set(1);
  }

  /**
   * Kills the mailbox
   */
  kill() {
    this._killJumpTimer();
    this._killTweens();
    this.onClick.removeAll();
    this._isOpened = false;
    super.kill();
  }

  _killJumpTimer() {
    if (this._loopTimer) {
      game.time.events.remove(this._loopTimer);
      this._loopTimer = null;
    }
  }

  /**
   * Kills all tweens
   */
  _killTweens() {
    for (const tw in this._allTweens) {
      if (tw.stop) {
        tw.stop();
      }
    }
    this._allTweens.length = 0;
  }

  /**
   * When the mailbox is clicked, jumps!
   */
  click() {
    super.click();

    if (this._interactive) {
      this.jump();
    }
  }

  /**
   * Makes the mailbox jump!
   */
  jump() {
    this._killTweens();
    this.scale.set(1);
    if (!this._isTweenAllowedToRun()) { return; }
    const squish = game.add.tween(this.scale).to({ x: 1.3, y: 0.7 }, 400, Phaser.Easing.Cubic.In);
    squish.onComplete.addOnce(() => {
      if (!this._isTweenAllowedToRun()) { return; }
      const pause = game.add.tween(this.scale).to({ x: 1.3, y: 0.7 }, 20, Phaser.Easing.Linear.None);
      pause.onComplete.addOnce(() => {
        if (!this._isTweenAllowedToRun()) { return; }
        const pull = game.add.tween(this.scale).to({ x: [0.8, 1.1, 1], y: [1.2, 0.9, 1] }, 800, Phaser.Easing.Cubic.Out);
        this._allTweens.push(pull);
        pull.start();
      });
      this._allTweens.push(pause);
      pause.start();
    });

    this._allTweens.push(squish);
    squish.start();
  }

  /**
   * Sets the animation flag to off
   */
  _pauseAnimation() {
    this._animate = false;
  }

  /**
   * Sets the animation flag to on
   */
  _resumeAnimation() {
    this._animate = true;
  }

  /**
   * Checks if this is allowed to animate
   */
  _isTweenAllowedToRun() {
    return this._animate && this.alive;
  }
}
