if (typeof G === 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getWaitingIconAnimation = function getWaitingIconAnimation(passedIcon = null) {
  let tween = null;

  return {
    animate,
  };

  function animate(game, target) {
    const icon = passedIcon || G.Utils.find1stObjectWithName(target, 'waitingIcon');

    if (icon) {
      icon.events.onDestroy.addOnce(stop);
      tween = game.add.tween(icon)
        .to(null, 16 * 5, Phaser.Easing.Linear.None, true, 0, -1);

      tween.onLoop.add(() => {
        icon.angle += 45;
      });

      return tween;
    }
  }

  function stop() {
    if (tween) {
      tween.stop();
      tween = null;
    }
  }
};
