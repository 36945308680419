/* eslint-disable radix */
/* eslint-disable semi */
/* eslint-disable no-use-before-define */

import { OMT_Connect } from './OMT_Connect';
import { OMT_EnvData } from './OMT_EnvData';
import { OMT_UserData } from './OMT_UserData';
import { OMT_Leaderboards } from './OMT_Leaderboards';
import { OMT_Friends } from './OMT_Friends';
import { OMT_Social } from './OMT_Social';
import { OMT_Payments } from './OMT_Payments';
import { OMT_Ads } from './ads/OMT_Ads';
// import { OMT_CrossPromo } from './OMT_CrossPromo';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { OMT_DataCache } from '@omt-components/Services/Utils/OMT_DataCache';
import { OMT_Crypt } from './OMT_Crypt';
import { OMT_PlatformTracking } from './OMT_PlatformTracking';
import { OMT_PlatformLeaderboards } from './OMT_PlatformLeaderboards';
import { OMT_PlatformFunctions } from './OMT_PlatformFunctions';
import { OMT_FeatureFlags } from './OMT_FeatureFlags';
import { OMT_JSONLoader } from './OMT_JSON';
import { OMT_Language } from './OMT_Language';
import { OMT_Bots } from './OMT_Bots';
// import { OMT_MessageService } from './OMT_MessageService';
import { OMT_Notifications } from './OMT_Notifications';
import { OMT_TransactionTracking } from './OMT_TransactionTracking';
import OMT_Cheats from './OMT_Cheats';
// import { OMT_PerformanceMonitor } from './OMT_PerformanceMonitor';
import { OMT_SystemInfo } from './OMT_SystemInfo';
import { OMT_PlatformTournaments } from './OMT_PlatformTournaments';
import { OMT_MilestoneTracking } from './milestoneTracking/OMT_MilestoneTracking';
// import OMT_TierLeaderboard from './OMT_TierLeaderboard';

const DATA_EXPIRY_SECONDS = 2 * 60; // expiry time for leaderboard data

/**
 * create service instances, but do not connect / start
 * @returns {Promise}
 */
export async function omtServicesInit() {
  await FBInstant.initializeAsync();
  OMT.platformTracking.logFTUEvent('FTUFacebookSDKInit', 'RTUFacebookSDKInit');
  OMT.platformTracking.logFTUEvent('FTUX_SDKINT', null);

  OMT_Utils.stylizedLog(`OMT_INIT: You are now playing ${G.BuildEnvironment.APP_ID.toUpperCase()} version ${G.BuildEnvironment.version}`);

  // G.Utils.SentryLog.connect(G.BuildEnvironment.RAVEN_DSN, G.BuildEnvironment.SENTRY_RELEASE_CFG, G.BuildEnvironment.version);

  const dataCache = new OMT_DataCache();

  window.OMT = {
    connect: new OMT_Connect(),
    envData: new OMT_EnvData(),
    userData: new OMT_UserData(),
    friends: new OMT_Friends(Number.MAX_SAFE_INTEGER, dataCache),
    leaderboards: new OMT_Leaderboards(DATA_EXPIRY_SECONDS, dataCache),
    // tierLeaderboard: new OMT_TierLeaderboard(),
    social: new OMT_Social(),
    payments: new OMT_Payments(),
    ads: new OMT_Ads(),
    // crossPromo: new OMT_CrossPromo(),
    feature: new OMT_FeatureFlags(),
    jsonLoader: new OMT_JSONLoader(),
    language: new OMT_Language(),
    bots: new OMT_Bots(),
    // messaging: new OMT_MessageService(),
    notifications: new OMT_Notifications(),
    platformFunctions: new OMT_PlatformFunctions(),
    platformLeaderboards: new OMT_PlatformLeaderboards(),
    platformTournaments: new OMT_PlatformTournaments(),
    platformTracking: OMT_PlatformTracking.getInstance(), // 'OMT.platformTracking' will already be defined, but re-created reference
    // performanceMonitor: OMT_PerformanceMonitor.getInstance(),
    systemInfo: OMT_SystemInfo.getInstance(), // 'OMT.systemInfo' will already be defined, but re-created reference
    transactionTracking: OMT_TransactionTracking.getInstance(),
    milestoneTracking: OMT_MilestoneTracking.getInstance(), // 'OMT.milestoneTracking' will already be defined, but re-created reference
    utils: OMT_Utils,
    crypt: OMT_Crypt,
    // COMMENT/ UNCOMMENT TO DISABLE/ ENABLE CHEATS
    // jaffles: OMT_Cheats,
  };

  // await FB payments ready / or timeout
  await OMT.payments.waitForPaymentsReady();

  // execute when game-backend is conneted
  OMT.connect.signals.onGBConnected.addOnce(() => {
    OMT.platformTracking.logFTUEvent('FTUGBSyncDone', 'RTUGBSyncDone');
  });

  // FBInstant 6.3 user information can be retrieved earlier only on 'WEB'
  if (OMT.systemInfo.deviceIsUsingFBSixPointThreePlus) {
    OMT.envData.defineSettings();
    await OMT.envData.defineEntryPoint();
    OMT.envData.definePayload();
    OMT.language.setPrimaryLanguage(OMT.envData.settings.env);
    // G.Utils.SentryLog.setUserContext(OMT.envData.settings.user.userId);
    // G.Utils.SentryLog.setEntryPoint();
  }

  // Set WebGL info in SentryLogger
  // G.Utils.SentryLog.setWebGLParams();

  // track js init time
  OMT.platformTracking.logEvent(OMT.platformTracking.Events.FBIGInit, OMT.platformTracking.timeSinceJSInit);
}

/**
 * finish initialization after all game assets loaded
 * @returns {Promise}
 */
export async function omtServicesStartGame() {
  await FBInstant.startGameAsync();
  OMT.platformTracking.logFTUEvent('FTUStartGameAsync', 'RTUStartGameAsync');

  // OMT.performanceMonitor.init();

  // FBInstant 6.3 set user information for non 'WEB' platforms
  if (!OMT.systemInfo.deviceIsUsingFBSixPointThreePlus) {
    OMT.envData.defineSettings();
    await OMT.envData.defineEntryPoint();
    OMT.envData.definePayload();
    OMT.language.setPrimaryLanguage(OMT.envData.settings.env);
    // G.Utils.SentryLog.setUserContext(OMT.envData.settings.user.userId);
    // G.Utils.SentryLog.setEntryPoint();
  }

  const { isCompatiblePlatform } = OMT.systemInfo;
  if (!isCompatiblePlatform) return; // exit flow here if we are on a incompatible platform

  setIAPAndIncentivised();

  // init friend list
  // await OMT.friends.init();

  if (OMT.connect.isConnected()) {
    // post offline scores now if they exist
    // await OMT.leaderboards.postOfflineScores();
    OMT.userData.writeUserData('username', OMT.envData.settings.user.name); // Updates user data's public username if it changed
    OMT.userData.writeUserData('useravatar', OMT.envData.settings.user.avatar);
  }

  // post offline scores collected whenever GB reconnects
  OMT.connect.signals.onGBConnected.add(() => {
    // OMT.leaderboards.postOfflineScores();
    OMT.userData.writeUserData('username', OMT.envData.settings.user.name);
    OMT.userData.writeUserData('useravatar', OMT.envData.settings.user.avatar);
  });

  // track to FBA the size of the localStorage in use, not currently active
  // OMT.platformTracking.trackLocalStorageSize();

  // load transaction log
  // await OMT.transactionTracking.loadLog();
}

/**
 * set the game IAP and Rewarded video setting based on availability of FB APIS
 * @returns {boolean}
 */
function setIAPAndIncentivised() {
  // detect android 5 as this was reporting wrong info for purchaes being available
  const isIncompatibleAndroid = OMT.systemInfo.deviceIsAndroid5;
  G.IAP = FBInstant.getSupportedAPIs().indexOf("payments.purchaseAsync") !== -1;//true//OMT.payments.paymentsReady && !isIncompatibleAndroid;
  game.incentivised = () => {
    const incentivised = G.BuildEnvironment.production === false || OMT.feature.rewardedAdsAvailable();
    return incentivised;
  };
}

/**
 * Sorts users into ABC groups based on the given JSON.
 * If they're not in it, then its "randomly" decided by the last digit of their id
 */
export function sortIntoABCGroups() {
  const list = G.json['configs/userABC'];
  const playerID = OMT.envData.settings.user.userId.toString();
  for (const key in list) { // eslint-disable-line guard-for-in
    const playerList = list[key];
    const playerFound = playerList.find((value) => value.toString() === playerID); // Is the user id in the list?
    if (playerFound) {
      G.saveState.sessionData.ABCGroup = key;
    }
  }
  if (!G.saveState.sessionData.ABCGroup) {
    const lastDigit = Number.parseInt(playerID.substr(playerID.length - 1, 1));
    if (lastDigit <= 4) { // 0, 1, 2, 3, 4
      G.saveState.sessionData.ABCGroup = 'Control';
    } else { // 5, 6, 7, 8, 9
      G.saveState.sessionData.ABCGroup = 'A';
    }
  }
}
