/* eslint-disable no-unused-vars */
/* eslint-disable prefer-object-spread */
/* eslint-disable import/no-unresolved */

import {
  registerWithFacebookInstantGameSignature as GBC_registerWithFacebookInstantGameSignature,
  loginWithGameBackendCredentials as GBC_loginWithGameBackendCredentials,
  getUserProfile as GBC_getUserProfile,
  getVersion as GBC_getVersion,
  getServerTimestamp as GBC_getServerTimestamp,
  setUserProfile as GBC_setUserProfile,
  getPublicUserData as GBC_getPublicUserData,
  GBCError,
  ERROR_CODE,
} from '@sgorg/game-backend-client';

import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { OMT_DataCache } from '@omt-components/Services/Utils/OMT_DataCache';

// timing constants for retry throttling in milliseconds
const RETRY_MIN_DELAY = 500;
const RETRY_MAX_DELAY = 30000;
const RETRY_DELAY_INCREMENT = 2000;
const AUTH_DATA_KEY = 'authData';

/**
 * class for interfacing with game-backend modules
 */
export class OMT_Connect {
  /**
   * constructor
   */
  constructor() {
    this._connected = false;
    this._prevAuthData = null;
    this._errorCache = new OMT_DataCache();

    this._serverTimestampFetchedAt = 0; // local time timesstamp was fetched at
    this._serverTimestamp = 0; // server timestamp

    this._defineSignals();
  }

  /**
   * define phaser signals
   */
  _defineSignals() {
    this.signals = {
      onGBConnected: new Phaser.Signal(),
    };
  }

  /**
   * connect to FB and game-backend
   * @returns {Promise}
   */
  async init() {
    // 2 connection attempts then continue.
    this._connected = await this._connectToGB(false, 0, 1);
    // G.Utils.SentryLog.setGBConnectivity();
    // retry connection, but dont wait for result.
    if (!this._connected) this._retryGBConnect();

    await OMT.userData.init();
  }

  /**
   * check if game-backend connection is established
   * @returns {boolean}
   */
  isConnected() {
    return this._connected;
  }

  /**
   * get users profile from the game-backend
   * @returns {Promise<Object>} profile data Object
   */
  async _getGBUserProfile() {
    let gbUserProfileData;
    try {
      gbUserProfileData = await GBC_getUserProfile();
    } catch (error) {
      gbUserProfileData = null;
      OMT_Utils.stylizedLog('could not get user profile data from the game-backend', '#FFFF00');
    }
    return gbUserProfileData;
  }

  /**
   * get the retry delay
   * @param {number} multiplier
   * @returns {number} delay in milliseconds
   */
  _getRetryDelay(multiplier) {
    const delay = RETRY_MIN_DELAY + (RETRY_DELAY_INCREMENT * multiplier);
    return Math.min(delay, RETRY_MAX_DELAY);
  }

  /**
   * connect to the game-backend
   * @param {boolean} isRetry true if a connection retry
   * @returns {Promise<Boolean>} connection status
   */
  async _connectToGB(forceRegister = false, retryCount = 0, maxRetries = 0) {
    let authData;
    const gameId = G.BuildEnvironment.APP_ID;
    const connected = await (async () => {
      // not a reconnection
      if (!this._prevAuthData) {
        // Fetch the FB signature key to securly login to the GB
        try {
          const data = await FBInstant.player.getDataAsync([AUTH_DATA_KEY]);
          authData = data[AUTH_DATA_KEY];
        } catch (error) {
          this._logConnectError(error);
        }
        // If there is no stored signature key, register the user and store the key
        if (!authData || forceRegister) {
          // const playerInfo = await FBInstant.player.getSignedPlayerInfoAsync();
          // const signature = playerInfo.getSignature();
          // const signature = 'signature';
          // authData = await GBC_registerWithFacebookInstantGameSignature({ gameId, signature });
          authData = 'authData';
          try { // store authData returned from register
            await FBInstant.player.setDataAsync({ [AUTH_DATA_KEY]: authData });
          } catch (error) {
            this._logConnectError(error);
          }
        }
      } else { // use authData from previous connection
        authData = this._prevAuthData;
      }

      // const loginConfig = Object.assign({ gameId }, authData);
      // await GBC_loginWithGameBackendCredentials(loginConfig);
    })().then(() => {
      this._prevAuthData = authData; // store valid authData for reconnection
      OMT_Utils.stylizedLog(`OMT_Connect: Connected (GBC v${GBC_getVersion()})`);
      return true;
    }).catch(async (error) => {
      // we hit our max retry count
      if (maxRetries !== 0 && retryCount === maxRetries) {
        OMT_Utils.stylizedLog('OMT_Connect: Max retries hit');
        return false;
      }
      // delay reconnection
      await new Promise((resolve) => setTimeout(resolve, this._getRetryDelay(retryCount)));

      // connection retry
      OMT_Utils.stylizedLog('OMT_Connect: retry');
      this._logConnectError(error);
      const authfailed = this._checkIfAuthFailed(error);
      const retrySuccess = await this._connectToGB(authfailed, retryCount + 1, maxRetries);
      return retrySuccess;
    });

    // set game-backend profile data once connected
    if (connected && !this._connected) {
      this._connected = true;
      // await this._updateGBUserProfileOnLogin();
      // const gbUserProfileData = await this._getGBUserProfile();
      // await this.getServerTimestamp();
      // OMT.envData.setGBUserProfileData(gbUserProfileData);
      // this._errorCache.clearAllData();
      // this.signals.onGBConnected.dispatch();
    }

    return connected;
  }

  /**
   * check if auth failed. NOT_AUTHORIZED && NOT_FOUND are for compatibility with GBC 2.X.X
   * @param {Object} error
   * @return {boolean}
   */
  _checkIfAuthFailed(error) {
    if (!error || !error.code) return true;
    return error.code === ERROR_CODE.INVALID_PARAMETER || error.code === ERROR_CODE.WRONG_CREDENTIALS || error.code === ERROR_CODE.NOT_AUTHORIZED || error.code === ERROR_CODE.NOT_FOUND;
  }

  /**
   * log error in connection flow, but we only want to do this once per connection cycle per error
   * @param {Error} error
   */
  _logConnectError(error) {
    const cacheKey = `error-${(error.code || error.message || 'unknown').toString()}`;
    if (!this._errorCache.hasKey(cacheKey)) {
      const naString = 'N/A';
      this._errorCache.setData(cacheKey, { triggered: true });
      let errorString = `OMT_Connect Error: { code: ${error.code || naString}, message: ${error.message || naString}`;
      if (error.parentError) errorString += `, parent-code: ${error.parentError.code || naString}, parent-message: ${error.parentError.message || naString}`;
      errorString += ' }';
      // G.Utils.SentryLog.logError(errorString, {}, false);
      console.error(errorString);
    }
  }

  /**
   * throttled reconnect to game-backend
   * @returns {Promise}
   */
  async _retryGBConnect() {
    this._connected = await this._connectToGB(false, 0, 0);
  }

  /**
   * update game-backend user profile on login
   */
  async _updateGBUserProfileOnLogin() {
    try {
      const result = await GBC_setUserProfile({ handle: FBInstant.player.getName(), locale: OMT.language.lang });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * get the timestamp from the server once connected. If it cannot be retreived will return the local Date.now().
   * @returns {Promise<number>}
   */
  async getServerTimestamp() {
    // add delta to fetched server timestamp if existing
    const adjustedTimestamp = this.getServerTimestampSync(false);
    if (adjustedTimestamp > 0) return adjustedTimestamp;

    // attempt to connect to get the server time
    if (this._connected) {
      try {
        const serverTimestamp = await GBC_getServerTimestamp();
        this._serverTimestamp = parseInt(serverTimestamp);
        this._serverTimestampFetchedAt = Date.now(); // local time timestamp was fetched at
        return this._serverTimestamp;
      } catch (error) {
        // console could not get timestamp from the server
      }
    }
    // fallback use local UTC time
    return Date.now();
  }

  /**
   * get the server timestamp syncronously if not synced will return 0
   * @param {boolean} useFallback allow fallback to system time
   * @returns {number}
   */
  getServerTimestampSync(useFallback = true) {
    if (this._serverTimestamp > 0 && this._serverTimestampFetchedAt > 0) {
      const deltaTime = Date.now() - this._serverTimestampFetchedAt;
      return this._serverTimestamp + deltaTime;
    }
    return useFallback ? Date.now() : 0;
  }

  /**
   *
   * @param {Array<string>} userLists
   * @param {Array<string>} keys
   * @returns {{'userId':{'key':{ data }}}} Not a string "userId", but the "userId" is the key, and the keys, are individual keys inside
   */
  getPublicUserData(userLists, keys) {
    return GBC_getPublicUserData(userLists, keys);
  }
}
