import { RMWHEEL_DDNAPARAMS } from '../../Elements/SpinningWheels/RealMoneyWheel/rmWheelEnums';

export default class DDNARealMoneyWheelTransactionTracker {
  constructor() {
    this._paramObj = {};
    this.reset();
  }

  /**
   * Return param object. If all params are 0, then return null.
   * @returns {Object}
   */
  getParamObj() {
    return this._paramObj;
  }

  /**
   * Resets real money wheel params
   */
  reset() {
    this._paramObj.tEligibleReplacementMoneyWheel = 0;
    this._paramObj.tSeenReplacementConversionMoneyWheel = 0;
    this._paramObj.tSeenReplacementHighValueWheel = 0;
    this._paramObj.tPurchasedReplacementConversionMoneyWheel = 0;
    this._paramObj.tPurchasedReplacementHighValueWheel = 0;
    this._paramObj.tSeenTargetedOfferConversionMoneyWheel = 0;
    this._paramObj.tSeenTargetedOfferHighValueWheel = 0;
    this._paramObj.tPurchasedTargetedOfferConversionMoneyWheel = 0;
    this._paramObj.tPurchasedTargetedOfferHighValueWheel = 0;
    this._paramObj.tClaimedPayloadConversionMoneyWheel = 0;
    this._paramObj.tClaimedPayloadHighValueMoneyWheel = 0;
    this._paramObj.tPurchasedPayloadConversionMoneyWheel = 0;
    this._paramObj.tPurchasedPayloadHighValueMoneyWheel = 0;
  }

  /**
   * Updated queued data
   * @param {RMWHEEL_EPS} wheelType
   * @param {RMWHEEL_EVENT} wheelEvent
   * @param {RMWHEEL_MODE} wheelMode
   * @param {number} amount
   * @param {boolean} increment
   */
  update(wheelType, wheelEvent, wheelMode, amount, increment) {
    const key = RMWHEEL_DDNAPARAMS[wheelType][wheelEvent][wheelMode];
    if (key) {
      this._paramObj[key] = increment ? this._paramObj[key] + amount : amount;
    }
  }
}
