import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import { INTERSTITIAL_RULES } from '../../../Services/OMT/ads/OMT_InterstitialAdRules';
import { Window_LevelFailed } from '../Window_levelFailed';
import { EventLevelProgression } from './EventLevelProgression';

/**
 * Level failed window for special event levels
 */
class Window_EventLevelFailed extends Window_LevelFailed {
  constructor(parent) {
    super(parent);
    this._initProgressBar();
  }

  /**
   * init window layout
   */
  _initLayout() {
    // background
    this.addBackground('tokenEvent_popup_background');

    this.banner = G.makeImage(
      0, -295,
      'tokenEvent_banner',
      0.5, this,
    );

    // level title text
    this.levelTxt = new G.Text(
      G.OMTsettings.tokenEvent.windowHeader.text.x,
      G.OMTsettings.tokenEvent.windowHeader.text.y,
      OMT.language.getText('tokenEvent Level'), 'tokenEvent-eventLevelTitle', 0.5, G.OMTsettings.tokenEvent.windowHeader.text.width, 125, true, 'center',
    );
    this.add(this.levelTxt);

    // level goal
    if (G.lvl.goalManager.isPointBased()) {
      this.add(new G.Text(0, 100, `${OMT.language.getText('points').toUpperCase()}:\n${G.lvl.points}/${G.lvl.goalManager.getPointTarget()}`, {
        style: 'font-blue',
        fontSize: '50px',
        fill: G.OMTsettings.tokenEvent.styling.windowTextFill,
      }, 0.5, 380));
    } else {
      this._makeLevelFailedTaskCollectPanels(95, 'tokenEvent_popup_bigtext_backgr');
    }
  }

  /**
   * init window buttons
   */
  _initButtons() {
    // close button
    this.closeButton = new G.Button(250, -270, 'btn_x', () => {
      this.state.endPointCheckForAds(() => {
        G.sb('onStateChange').dispatch('World', undefined, () => {
          G.saveState.disableDiscount();
        });
      }, INTERSTITIAL_RULES.EVERY_N);
    }, this);
    this.registerButtons(this.closeButton);

    // retry buttons
    const onClick = () => {
      this.closeWindow();
      this.state.endPointCheckForAds(() => {
        const layerName = game.state.current === 'World' ? G.WindowMgr.LayerNames.AboveHighScorePanel : G.WindowMgr.LayerNames.Base;
        G.sb('pushWindow').dispatch(['eventLevel', G.saveState.getEventLevel(), { showPointer: Math.random() < 0.5 }], false, layerName);
      }, INTERSTITIAL_RULES.EVERY_N);
    };
    this.retryBtn = new OMT_UI_SquareButton(5, 250, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText('Retry'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
      },
    });
    this.registerButtons(this.retryBtn);
  }

  /**
   * display event progress
   */
  _initProgressBar() {
    this._progressBar = new EventLevelProgression(0, -100);
    this.add(this._progressBar);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.eventLevelFailed = Window_EventLevelFailed;
