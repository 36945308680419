/**
 * This is the horizontal card menu that was taken from the Friendship Chest.
 * This modular enough that you have to pass in everything required when the function is called.
 * This will specifically only deal with positioning and visibility of UI elements. It will not help
 * creation of extra objects. There are functions that this class will call when a tween is completed.
 * Some functions will require a function to be passed in. Though it may be the same across classes, the data structure
 * of what is being passed in may not be the same.
 * @author Sandra Koo
 */
export default class OMT_UI_HorizontalCardMenu {
  /**
   *
   * @param {Object} config
   */
  constructor(config) {
    this._config = config;

    this.signals = {
      onLeftClick: new Phaser.Signal(),
      onRightClick: new Phaser.Signal(),
    };
  }

  /**
   * Destroy!!!
   */
  destroy() {
    this.signals.onLeftClick.dispose();
    this.signals.onRightClick.dispose();
  }

  /**
   * Positions arrows, shows or hides it depending if there are cards for it to show
   * @param {Object} config
   * @param {Object} config.current
   * @param {Object} config.nextCard
   * @param {Object} config.prevCard
   * @param {G.Button} config.left
   * @param {G.Button} config.right
   * @param {number} config.leftPosition
   * @param {number} config.rightPostion
   * @param {Phaser.Group} config.container
   * @param {number} config.prevPosition
   * @param {number} config.nextPosition
   * @param {G.Button} config.closeBtn
   */
  checkUI(config) {
    const prev = config.prevCard;
    const next = config.nextCard;
    const { left } = config;
    if (prev) {
      left.x = config.leftPosition;
      config.container.addChild(left);
      left.enabled = true;
    } else {
      left.enabled = false;
    }

    const { right } = config;
    if (next) {
      right.x = config.rightPostion;
      config.container.addChild(right);
      right.enabled = true;
    } else {
      right.enabled = false;
    }

    this.blinkOutUIElements({ // Fades out buttons that are not used
      left: left.enabled ? null : left,
      right: right.enabled ? null : right,
      close: null,
      tweenTime: 100,
    });
    this.blinkInUIElements({ // Fades in buttons that are used
      left: left.enabled ? left : null,
      right: right.enabled ? right : null,
      close: config.closeBtn,
      tweenTime: 200,
    });
    this.toggleArrowListeners({ left, right }); // Toggles listeners
  }

  /**
   * Turns on or removes listeners for arrows
   * @param {Object} arrows
   * @param {G.Button} arrows.left
   * @param {G.Button} arrows.right
   */
  toggleArrowListeners(arrows) {
    const { left } = arrows;
    if (left.visible) {
      if (!left.onClick.has(this.onLeftClick, this)) { // Adds it once
        left.onClick.add(this.onLeftClick, this);
      }
    } else {
      left.onClick.remove(this.onLeftClick, this);
    }

    const { right } = arrows;
    if (right.visible) {
      if (!right.onClick.has(this.onRightClick, this)) { // Adds it once
        right.onClick.add(this.onRightClick, this);
      }
    } else {
      right.onClick.remove(this.onRightClick, this);
    }
  }

  /**
   * Function for when the left button is clicked
   * @param {boolean} force
   */
  onLeftClick(force = false) {
    this.signals.onLeftClick.dispatch(force);
  }

  /**
   * Function for when the right button is clicked
   * @param {boolean} force
   */
  onRightClick(force = false) {
    this.signals.onRightClick.dispatch(force);
  }

  /**
   * Fades out UI elements based on info from config
   * @param {{left?:G.Button, right?:G.Button, close?:G.Button, tweenTime: number}} config
   */
  blinkOutUIElements(config) {
    let leftTween;

    if (config.left) {
      const { left } = config;
      if (left.visible) {
        left.enabled = false;
        leftTween = game.add.tween(left)
          .to({ alpha: 0 }, config.tweenTime, Phaser.Easing.Sinusoidal.In);
        leftTween.onComplete.addOnce(() => {
          left.visible = false;
        });
      }
    }

    let rightTween;
    if (config.right) {
      const { right } = config;
      if (right.visible) {
        right.enabled = false;
        rightTween = game.add.tween(right)
          .to({ alpha: 0 }, config.tweenTime, Phaser.Easing.Sinusoidal.In);
        rightTween.onComplete.addOnce(() => {
          right.visible = false;
        });
      }
    }

    let closeTween;
    if (config.close && config.close.visible) {
      closeTween = game.add.tween(config.close)
        .to({ alpha: 0 }, config.tweenTime, Phaser.Easing.Sinusoidal.In);
      closeTween.onComplete.addOnce(() => {
        config.close.visible = false;
      });
    }

    if (leftTween) { leftTween.start(); }
    if (rightTween) { rightTween.start(); }
    if (closeTween) { closeTween.start(); }
  }

  /**
   * Fades in UI elements based on info from config
   * @param {{left?:G.Button, right?:G.Button, close?:G.Button, tweenTime: number}} config
   */
  blinkInUIElements(config) {
    let leftTween;
    if (config.left) {
      const { left } = config;
      if (!left.visible) {
        left.visible = true;
        leftTween = game.add.tween(left)
          .to({ alpha: 1 }, config.tweenTime, Phaser.Easing.Sinusoidal.Out);
      }
    }

    let rightTween;
    if (config.right) {
      const { right } = config;
      if (!right.visible) {
        right.visible = true;
        rightTween = game.add.tween(right)
          .to({ alpha: 1 }, config.tweenTime, Phaser.Easing.Sinusoidal.Out);
      }
    }

    let closeTween;
    if (config.close && !config.close.visible) {
      config.close.visible = true;
      closeTween = game.add.tween(config.close)
        .to({ alpha: 1 }, config.tweenTime, Phaser.Easing.Sinusoidal.Out);
    }

    if (leftTween) { leftTween.start(); }
    if (rightTween) { rightTween.start(); }
    if (closeTween) { closeTween.start(); }
  }

  /**
   * Tweens the cards in the direction dictated by isRight.
   * Calls onComplete when finished tweening
   * Functions are passed in because data structure might not be the same for all cases
   * @param {Object} config
   * @param {Object} config.current
   * @param {Object} config.prev
   * @param {Object} config.next
   * @param {number} config.prevPosition
   * @param {number} config.nextPosition
   * @param {Phaser.Rectangle} config.objectDimension
   * @param {function} config.onComplete
   * @param {boolean} config.isRight
   * @param {function} config.onPrevRightStart
   * @param {function} config.onNextRightStart
   * @param {function} config.onPrevComplete
   * @param {function} config.onNextComplete
   * @param {number} config.tweenTime
   */
  tweenObjects(config) {
    const tweenTime = 200;
    const { prev } = config;
    const { next } = config;
    const currentTarget = config.isRight ? config.prevPosition : config.nextPosition; // Which way?
    const currentTween = game.add.tween(config.current)
      .to({ x: currentTarget }, tweenTime, Phaser.Easing.Sinusoidal.In);
    currentTween.onComplete.addOnce(() => { if (config.onComplete) { config.onComplete(); } });

    let prevTween;
    if (prev) { // If theres a previous card to tween
      const prevTarget = config.isRight ? config.prevPosition - config.objectDimension.width : 0; // Which position?
      prevTween = game.add.tween(prev)
        .to({ x: prevTarget }, tweenTime, Phaser.Easing.Sinusoidal.In);
      if (!config.isRight) {
        if (config.onPrevRightStart) {
          prevTween.onStart.addOnce(config.onPrevRightStart);
        }
      } else { // It should be noted that the conditions is that its not right, and theres a function behind.
        if (config.onPrevComplete) { // eslint-disable-line no-lonely-if
          prevTween.onComplete.addOnce(config.onPrevComplete);
        }
      }
    }

    let nextTween;
    if (next) {
      const nextTarget = config.isRight ? 0 : config.nextPosition + config.objectDimension.width; // Where?
      nextTween = game.add.tween(next)
        .to({ x: nextTarget }, tweenTime, Phaser.Easing.Sinusoidal.In);
      if (config.isRight) {
        if (config.onNextRightStart) {
          nextTween.onStart.addOnce(config.onNextRightStart);
        }
      } else { // It should be noted that the conditions is that its not right, and theres a function behind.
        if (config.onNextComplete) { // eslint-disable-line no-lonely-if
          nextTween.onComplete.addOnce(config.onNextComplete);
        }
      }
    }

    // Start tweens
    currentTween.start();
    if (prevTween) { prevTween.start(); }
    if (nextTween) { nextTween.start(); }
  }
}
