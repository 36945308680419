/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import OMT_StackManager from '../OMT_StackManager';

/* eslint-disable object-curly-newline */
export default class OMT_TweenUtils {
  // Tween utils

  /**
   * Changes the opacity of an object with the given parameters
   * @param {object} config for the tween
   * @param {object} config.object The object to tween
   * @param {number} config.alpha The alpha value to tween to
   * @param {number} config.duration The duration of the tween
   * @param {number} config.ease The easing to apply to the tween
   * @param {Function} config.callback The callback function to call after the tween ends (note that infinite tweens do not trigger this)
   */
  static async changeAlphaTo(config) {
    const {
      object,
      alpha,
      duration,
      ease,
      callback,
    } = this.validateTweenConfig(config);

    if (alpha === undefined) return Promise.resolve();

    return new Promise((resolve) => {
      game.add
        .tween(object)
        .to({ alpha }, duration, ease, true)
        .onComplete.addOnce(() => {
          callback(object);
          resolve();
        });
    });
  }

  /**
   * Changes the angle of an object with the given parameters
   * @param {object} config for the tween
   * @param {object} config.object The object to tween
   * @param {number} config.angle The angle value to tween to
   * @param {number} config.duration The duration of the tween
   * @param {number} config.ease The easing to apply to the tween
   * @param {Function} config.callback The callback function to call after the tween ends (note that infinite tweens do not trigger this)
   */
  static async changeAngleTo(config) {
    const {
      object,
      angle,
      duration,
      ease,
      callback,
    } = this.validateTweenConfig(config);

    if (angle === undefined) return Promise.resolve();

    return new Promise((resolve) => {
      game.add
        .tween(object)
        .to({ angle }, duration, ease, true)
        .onComplete.addOnce(() => {
          callback(object);
          resolve();
        });
    });
  }

  /**
   * Changes the coordinates of an object with the given parameters
   * @param {object} config for the tween
   * @param {object} config.object The object to tween
   * @param {number} config.x The x value to tween to
   * @param {number} config.y The y value to tween to
   * @param {number} config.duration The duration of the tween
   * @param {number} config.ease The easing to apply to the tween
   * @param {Function} config.callback The callback function to call after the tween ends (note that infinite tweens do not trigger this)
   */
  static async translateTo(config) {
    const { object, x, y, duration, ease, callback } = this.validateTweenConfig(
      config,
    );

    if ([x, y].every((property) => property === undefined)) {
      return Promise.resolve();
    }

    const translateObject = {};
    if (x !== undefined) {
      translateObject.x = x;
    }
    if (y !== undefined) {
      translateObject.y = y;
    }

    return new Promise((resolve) => {
      game.add
        .tween(object)
        .to(translateObject, duration, ease, true)
        .onComplete.addOnce(() => {
          callback(object);
          resolve();
        });
    });
  }

  /**
   * Changes the scale of an object with the given parameters
   * @param {object} config for the tween
   * @param {object} config.object The object to tween
   * @param {number} config.scale The scale value to tween to
   * @param {number} config.scaleX The scale x value to tween to
   * @param {number} config.scaleY The scale y value to tween to
   * @param {number} config.duration The duration of the tween
   * @param {number} config.ease The easing to apply to the tween
   * @param {Function} config.callback The callback function to call after the tween ends (note that infinite tweens do not trigger this)
   */
  static async transformTo(config) {
    const {
      object,
      scale,
      scaleX,
      scaleY,
      duration,
      ease,
      callback,
    } = this.validateTweenConfig(config);

    if ([scale, scaleX, scaleY].every((property) => property === undefined)) {
      return Promise.resolve();
    }

    const translateObject = {};
    if (scale !== undefined) {
      translateObject.x = scale;
      translateObject.y = scale;
    }
    if (scaleX !== undefined) {
      translateObject.x = scaleX;
    }
    if (scaleY !== undefined) {
      translateObject.y = scaleY;
    }

    return new Promise((resolve) => {
      game.add
        .tween(object.scale)
        .to(translateObject, duration, ease, true)
        .onComplete.addOnce(() => {
          callback(object);
          resolve();
        });
    });
  }

  /**
   * Changes the scale of an object with the given parameters
   * @param {object} config for the tween
   * @param {object} config.object The object to tween
   * @param {number} config.duration The duration of the tween
   * @param {number} config.ease The easing to apply to the tween
   * @param {Function} config.callback The callback function to call after the tween ends (note that infinite tweens do not trigger this)
   * @param {object} config.props The properties of the object to tween
   * @param {number} config.props.alpha The alpha value to tween to
   * @param {number} config.props.angle The angle value to tween to
   * @param {number} config.props.x The x value to tween to
   * @param {number} config.props.y The y value to tween to
   * @param {number} config.props.scale The scale value to tween to
   * @param {number} config.props.scaleX The scaleX value to tween to
   * @param {number} config.props.scaleY The scaleY value to tween to
   */
  static async tweenMultiple(config) {
    const {
      object,
      duration,
      ease,
      callback,
      props,
    } = this.validateTweenConfig(config);
    const { alpha, angle, x, y, scale, scaleX, scaleY } = props;

    if ([alpha, angle, x, y, scale, scaleX, scaleY].every((property) => property === undefined)) {
      return Promise.resolve();
    }

    const stack = OMT_StackManager.getFreeStack(() => {
      callback(object);
    });
    const subStacks = [];

    if (alpha !== undefined || angle !== undefined) {
      const propertiesObject = {};
      if (alpha !== undefined) {
        propertiesObject.alpha = alpha;
      }
      if (angle !== undefined) {
        propertiesObject.angle = angle;
      }

      const subStack = OMT_StackManager.getFreeStack();
      subStack.addPromise(() => new Promise((resolve) => {
        game.add
          .tween(object)
          .to(propertiesObject, duration, ease, true)
          .onComplete.addOnce(() => {
            callback(object);
            resolve();
          });
      }));
      subStacks.push(subStack);
    }
    if (x !== undefined || y !== undefined) {
      const subStack = OMT_StackManager.getFreeStack();
      subStack.addPromise(() =>
        OMT_TweenUtils.translateTo({
          object,
          duration,
          ease,
          x,
          y,
        }),
      );
      subStacks.push(subStack);
    }
    if (scale !== undefined || scaleX !== undefined || scaleY !== undefined) {
      const subStack = OMT_StackManager.getFreeStack();
      subStack.addPromise(() =>
        OMT_TweenUtils.transformTo({
          object,
          duration,
          ease,
          scale,
          scaleX,
          scaleY,
        }),
      );
      subStacks.push(subStack);
    }

    if (subStacks.length) {
      stack.addParallel(subStacks);
    }

    return stack.run();
  }

  /**
   * Validates and applies default values to the config for the tweens
   * @param {object} config for the tween
   * @param {number} config.duration The duration of the tween
   * @param {number} config.ease The easing to apply to the tween
   * @param {Function} config.callback The callback function to call after the tween ends (note that infinite tweens do not trigger this)
   */
  static validateTweenConfig(config) {
    const {
      duration = 1000,
      ease = Phaser.Easing.Linear.None,
      callback = () => Promise.resolve(),
    } = config;

    config.duration = duration;
    config.ease = ease;
    config.callback = callback;

    return config;
  }
}
