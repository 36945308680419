/* eslint-disable prefer-arrow-callback */

// NOTE: This helper is deprecated. Booster unlock levels are no longer overridden using tutorial configs,
//       but just using settings.js and settingsIAP.js instead

G.Helpers = G.Helpers || {};

G.Helpers.setupBoosterUnlocks = function () {
  // imports
  const { levelDataMgr } = G.Helpers;

  const boostersUnlock = [null, 0, 0, 0, 0];

  Object.keys(G.json.tutorials).forEach(function (tutorialId) {
    const tutorialData = G.json.tutorials[tutorialId];
    const lvlData = levelDataMgr.getLevelWithTutorialId(tutorialId);
    if (!lvlData) return;

    if (tutorialData.steps) {
      const boosterStep = tutorialData.steps.find(function (step) {
        return step.type === 'booster';
      });
      if (boosterStep) {
        if (lvlData) {
          boostersUnlock[boosterStep.boosterNr] = lvlData.levelNumber;
        }
      }
    } else if (tutorialData.boosterNr) {
      boostersUnlock[tutorialData.boosterNr] = lvlData.levelNumber;
    }
  });

  G.json.settings.startBoosterUnlock = boostersUnlock;
  if (G.json.settingsIAP) {
    G.json.settingsIAP.startBoosterUnlock = boostersUnlock;
  }
};
