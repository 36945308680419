import { Action, ACTION_TYPES } from './Action';

/**
 * Action for the swap booster
 */
export class ActionBoosterSwap extends Action {
  /**
   * initialize the action
   */
  _init(boosterData) {
    this._boosterData = boosterData;

    this._availableCandies = [];
    this._clickedCandy = null;
    this._clickedCandy2 = null;
    this._madeMove = false;

    this._signalBinding = game.input.onDown.add(this._onPointerDown, this);
  }

  /**
   * on pointer down signal
   * @param {Object} pointer
   */
  _onPointerDown(pointer) {
    const { inputController } = this._board;
    const cell = inputController.pointerToCell(pointer);
    if (!cell) return;
    // check if the targeted cell is moveable
    if (this._board.isMoveable(cell[0], cell[1])) {
      const candy = this._board.getCandy(cell[0], cell[1]);
      const candyAvailable = this._availableCandies.length === 0 || this._availableCandies.indexOf(candy) !== -1;
      if (!candyAvailable || candy.isGoalCollectType()) return;

      // look for first clicked candy
      if (!this._clickedCandy) {
        this._selection = this._board.candySelectDisplay;
        this._selection.selectCandy(candy);
        G.sb('onBoosterSwapCandySelect').dispatch(candy);
        this._clickedCandy = candy;
        return;
      }

      // look for 2nd clicked candy
      if (this._clickedCandy !== candy) {
        this._clickedCandy2 = candy;
        const { gameHooks } = this._board;
        gameHooks.onBoosterUse(1, this._boosterData.unlimitedMode);
      }
    }
  }

  /**
   * main update method
   */
  update() {
    super.update();
    // skip update if no candy selected
    if (!this._clickedCandy || !this._clickedCandy2) return;

    const board = this._board;
    const { matcher } = board;

    // begin the moving the candies if 2 selected and movement not started
    if (!this._madeMove) {
      this._madeMove = true;
      this._signalBinding.detach();
      if (this._selection) this._selection.hide();
      this._clickedCandy2.moveToAndUpdateGrid(this._clickedCandy.cellX, this._clickedCandy.cellY);
      this._clickedCandy.moveToAndUpdateGrid(this._clickedCandy2.cellX, this._clickedCandy2.cellY, true);
    }

    // look end of movement
    if (!board.candiesAreAnimating && !board.candiesAreFalling) {
      if (matcher.isMoveValid(this._clickedCandy)) board.checkMatchList.push(this._clickedCandy);
      if (matcher.isMoveValid(this._clickedCandy2)) board.checkMatchList.push(this._clickedCandy2);
      this._clickedCandy.markSuccessfulMove();
      this._clickedCandy2.markSuccessfulMove();

      // set actions to process matches and collapse the board
      if (board.checkMatchList.length > 0) this._actionManager.newAction(ACTION_TYPES.PROCESS_MATCH);
      this._actionManager.newAction(ACTION_TYPES.PROCESS_FALL);

      // action finished events
      G.sb('onBoosterActionFinished').dispatch();
      this.finish();
    }
  }

  /**
   * action finished. remove the action.
   */
  finish() {
    if (this._selection) this._selection.hide();
    super.finish();
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._signalBinding.detach();
    this._availableCandies = null;
    this._clickedCandy = null;
    this._clickedCandy2 = null;
    this._boosterData = null;
  }

  /** GETTER / SETTER METHODS ********************************* */

  /** @param {Array.<Candy>} candyList set by tutorial to limit possible candies for booster */
  set availableCandies(candyList) { this._availableCandies = candyList; }
}
