import { GameScaleController } from '../../../States/Scaling/GameScaleController';
import { BUTTONSTATE } from '../../../OMT_UI/OMT_UI_SquareButton';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { RMWHEEL_EPS, RMWHEEL_EVENTS } from '../../SpinningWheels/RealMoneyWheel/rmWheelEnums';

export class HelperRealMoneyWheel extends Phaser.Group {
  constructor(game) {
    super(game);

    this._gameScale = GameScaleController.getInstance().gameScale;
    this.isVisible = false;
    this._miniwheel = new G.Button(320, 175, 'realMoney_miniwheel', this._onClickWheelIcon, this);

    this._orientationSettings = this._getOrientationSettings();

    this._miniwheel.visible = false;

    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      this._miniwheel.scale.setTo(this._gameScale);
    }

    this._wiggleAnim = null;

    this.add(this._miniwheel);
    this._onResize();
    this._onResizeSB = G.sb('onScreenResize').add(this._onResize, this);
  }

  /**
   * Returns an object that is used for layout purposes
   * @returns {{ baseScale: number, heightRatio: number, yOffset: number }}
   */
  _getOrientationSettings() {
    if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
      return {
        baseScale: 0.5,
        heightRatio: 0.3,
        yOffset: 175,
      };
    }

    return { // Horizontal
      baseScale: 0.8,
      heightRatio: 0,
      yOffset: 60,
    };
  }

  /**
   * Screen resize listener callback
   */
  _onResize() {
    const yOffset = Math.max((game.height - 960) * this._orientationSettings.heightRatio, 0);
    this._miniwheel.y = yOffset + this._orientationSettings.yOffset;

    this._miniwheel.scale.setTo((this._orientationSettings.baseScale + (yOffset / 240) * 1.1) * this._gameScale);
  }

  _onClickWheelIcon() {
    G.sb('pushWindow').dispatch(['realMoneyWheel', {
      afterQuit: this.hideWheelIcon.bind(this),
      entryPoint: RMWHEEL_EPS.Helper,
      freeSpin: false,
      worldState: false,
    }], false, G.WindowMgr.LayerNames.BelowHeaderPanel);

    // Track wheel seen
    // const isConversion = DDNA.tracking.getDataCapture().getRealMoneyWheelConversionStatus();
    // DDNA.missionTracker.trackWheelEvent(RMWHEEL_EPS.Helper, RMWHEEL_EVENTS.Opened, isConversion, 1, false);
  }

  /**
   * Make wheel icon roll in on screen
   */
  showWheelIcon() {
    this.isVisible = true;
    this._miniwheel.visible = true;
    this._miniwheel.x = OMT.systemInfo.orientation === ORIENTATION.horizontal
      ? (game.width) / 2 + 335
      : 720;
    this._miniwheel.rotation = 3 * Math.PI;
    this._miniwheel.alpha = 0;
    const targetX = this._miniwheel.x - 400;

    game.add.tween(this._miniwheel).to({ alpha: 1 }, 250, Phaser.Easing.Linear.None, true);
    game.add.tween(this._miniwheel).to({ x: targetX, rotation: 0 }, 1500, Phaser.Easing.Back.Out, true)
      .onComplete.add(() => {
        // Start wiggle animation
        this._wiggleAnim = [
          game.add.tween(this._miniwheel).to({ angle: -15 }, 150, Phaser.Easing.Sinusoidal.InOut, false, 1500),
          game.add.tween(this._miniwheel).to({ angle: 15 }, 300, Phaser.Easing.Sinusoidal.InOut, false),
          game.add.tween(this._miniwheel).to({ angle: 0 }, 150, Phaser.Easing.Sinusoidal.InOut, false),
        ];

        for (let i = 0; i < this._wiggleAnim.length - 1; i++) {
          this._wiggleAnim[i].chain(this._wiggleAnim[i + 1]);
        }

        this._wiggleAnim[this._wiggleAnim.length - 1].chain(this._wiggleAnim[0]);
        this._wiggleAnim[0].start();
      });
  }

  /**
   * Make wheel icon roll out off screen
   * @param {boolean} wasUnavailable was the wheel unavailable?
   */
  hideWheelIcon(wasUnavailable) {
    if (wasUnavailable) return;

    // Stop wiggle animation
    if (this._wiggleAnim) {
      for (const anim of this._wiggleAnim) {
        anim.stop();
      }
    }

    this.isVisible = false;
    this._miniwheel.currentState = BUTTONSTATE.DISABLED;
    this._miniwheel.visible = true;
    this._miniwheel.rotation = 0;
    this._miniwheel.alpha = 1;
    const targetX = this._miniwheel.x - 400;

    game.add.tween(this._miniwheel).to({ alpha: 0 }, 250, Phaser.Easing.Linear.None, true, 1750);
    game.add.tween(this._miniwheel).to({ x: targetX, rotation: -3 * Math.PI }, 1500, Phaser.Easing.Back.In, true, 500);
  }

  destroy() {
    super.destroy();
    this._onResizeSB.detach();
  }
}
