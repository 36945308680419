import Shop3_CornerBanner from './Shop3_CornerBanner';
import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';

/**
 * banner with time display used for special event sales in the shop
 */
export default class Shop3_iapMultiplierBanner extends Shop3_CornerBanner {
  /**
   * constructor
   * @param {{assetImage:string, text:string, orientation:string}} config
   */
  constructor(config) {
    super(config);
  }

  /**
   * Draws everything required
   */
  _beginDrawing(assetImage) {
    super._beginDrawing(assetImage);
    this._drawText();
  }

  /**
   * initialize the sale icon
   */
  _initIcon(image) {
    const isHorizontal = this._config.orientation === ORIENTATION.horizontal;
    const targetAnchor = isHorizontal ? [0, 0.5] : [0, 0];
    const icon = G.makeImage(0, 0, image, targetAnchor, null);
    if (isHorizontal) {
      icon.y = this._height / 2;
    } else {
      icon.y = 0;
    }
    this.addChild(icon);

    this._banner = icon;
  }

  /**
   * initialize the countdown text field
   */
  _drawText() {
    const isHorizontal = this._config.orientation === ORIENTATION.horizontal;
    const textWidth = this._banner.width * 0.9;
    const textHeight = this._height;
    const targetAnchor = isHorizontal ? [0, 0.5] : 0.5;
    const textX = isHorizontal ? this._textPadding * 2 : this._textPadding + (textWidth / 2);
    const textY = this._textOffsetY + (isHorizontal ? this._height / 2 : this._height);
    const textWrap = !isHorizontal;
    const alignment = isHorizontal ? 'left' : 'center';
    const countDownText = new G.Text(textX, textY,
      OMT.language.getText(this._config.text).replace('%MULT%', G.saveState.iapMultiplier),
      'shop3-saleBanner', targetAnchor, textWidth, textHeight, textWrap, alignment, undefined, undefined, true);
    this.addChild(countDownText);
  }
}
