/* eslint-disable no-undef */

import { MILLISECONDS_IN_SEC } from '@omt-components/Utils/TimeUtil';
import { TextTimer, TIMER_FORMAT } from '../../../TextTimer';

/**
 * @typedef {Object} DefaultHeaderConfig
 * @property {string} asset asset key for header
 * @property {number} sliceRect (9-sliced only) rectangle for 9-slicing, if not passed will be treated as normal texture
 * @property {number} horizontalPadding (9-sliced only) padding used in addition to textWidth when 9-scaling width
 * @property {number} height height of header asset
 */
const DEFAULT_HEADER_CONFIG = {
  asset: 'IAP-store-Booth-header',
  isNineSliced: true,
  sliceRect: {
    left: 60, right: 60, top: 28, bottom: 28,
  },
  horizontalPadding: 140,
  height: 60,
};

/**
 * @typedef {Object} DefaultTextConfig
 * @property {string} text string to be displayed
 * @property {Object || string} style text style to apply
 * @property {number} maxWidth max width of text
 */
const DEFAULT_TEXT_CONFIG = {
  text: 'Graham\'s Bakery',
  style: 'window_timedPopupOffer-headerTxt',
  maxWidth: 400,
};

/**
 * class for TargetedOffer header group
 */
export class TimedTargetedOffer_HeaderGroup extends Phaser.Group {
  /**
   * constructor
   * @param {Object} config
   * @param {number} config.textOffsetY vertical text pixel offset
   * @param {number} config.ribbonOffsetY vertical header pixel offset
   * @param {Object} config.ribbonConfig additional properties to add to DEFAULT_HEADER_CONFIG
   * @param {Object} config.textConfig additional properties to add to DEFAULT_TEXT_CONFIG
   * @param {number} expiryTime
   */
  constructor(config, expiryTime) {
    super(game);

    const {
      textOffsetY,
      timerOffsetY,
      textConfig,
      ribbonConfig,
    } = config;
    const headerConfig = { ...DEFAULT_HEADER_CONFIG, ...ribbonConfig };
    const titleTextConfig = { ...DEFAULT_TEXT_CONFIG, ...textConfig };

    this._drawHeader(headerConfig);
    this._drawHeaderText(titleTextConfig, textOffsetY);
    this._drawTimer(expiryTime, timerOffsetY);

    // this.cacheAsBitmap = true;
  }

  /**
   * draw the header header
   * @param {Object} headerConfig if not passed DEFAULT_HEADER_CONFIG will be used
   * @param {string} headerConfig.asset asset key for header
   * @param {number} headerConfig.sliceRect (9-sliced only) rectangle for 9-slicing, if not passed will be treated as normal texture
   * @param {number} headerConfig.horizontalPadding (9-sliced only) padding used in addition to textWidth when 9-scaling width
   * @param {number} headerConfig.height height of header asset
   * @param {number} headerOffsetY
   */
  _drawHeader(headerConfig) {
    // non 9-scaled, for insconsistent designs
    this._header = G.makeImage(0, 0, headerConfig.asset);
    this._header.anchor.set(0.5);
    this.addChildAt(this._header, 0);
  }

  /**
   * draw the timer
   * @param {number} expiryTime
   * @param {number} timerOffsetY
   */
  _drawTimer(expiryTime, timerOffsetY) {
    const timeLeft = (expiryTime - Date.now()) / MILLISECONDS_IN_SEC;
    const timer = new TextTimer({
      x: 10,
      y: timerOffsetY,
      style: 'font-white',
      anchor: 0.5,
      maxWidth: this._header.width,
      timerFormat: TIMER_FORMAT.MS,
    });
    timer.start(timeLeft);
    this.addChild(timer);
  }

  /**
   * draw the header text
   * @param {Object} textConfig if not passed DEFAULT_TEXT_CONFIG will be used
   * @param {string} textConfig.text string to be displayed
   * @param {Object || string} textConfig.style text style to apply
   * @param {number} textConfig.maxWidth max width of text
   * @param {number} textOffsetY
   */
  _drawHeaderText(textConfig, textOffsetY) {
    const headerTxt = new G.Text(0, textOffsetY, OMT.language.getText(textConfig.text), textConfig.style, 0.5, textConfig.maxWidth, null, true, 'center');
    this.addChild(headerTxt);
    this._headerTxt = headerTxt;
  }
}
