import { default as OMT_UI_SquareButton, BUTTONCOLOURS } from '../../../../OMT_UI/OMT_UI_SquareButton';

const BUTTON_CONFIG = {
  dimensions: { width: 260, height: 80 },
  tint: BUTTONCOLOURS.lightGreen,
  isEnabled: true,
  extraDetail: true,
};

const BUTTON_TEXT_STYLE = {
  style: 'font-white',
  fontSize: '56px',
};

const DISABLED_SCALE_DOWN_RATE = 0.99;

/**
 * class for TargetedOffer animated buy button.
 */
export class TargetedOffer_BuyButton extends OMT_UI_SquareButton {
  /**
   * constructor
   * @param {string} price
   */
  constructor(price) {
    super(0, 0, {
      button: BUTTON_CONFIG,
      text: {
        string: price,
        textStyle: BUTTON_TEXT_STYLE,
        dimensionMods: { width: 1.5, height: 1.5 },
      },
    });
    this._animationActive = true;
  }

  /**
   * enable the animation
   */
  enableAnimation() {
    this._animationActive = true;
  }

  /**
   * disable the animation
   */
  disableAnimation() {
    this._animationActive = false;
  }

  /**
   * Map a value from one range to another range. e.g. map(5, 1, 10, 1, 20) would return 10.
   * Something Taylor wrote for the button animation effect.. i dunno
   * @param {number} in_min The minimum number of the first range.
   * @param {number} in_max The maximum number of the first range.
   * @param {number} out_min The minimum number of the range to be mapped to.
   * @param {number} out_max The maximum number of the range to be mapped to.
   */
  _calcButtonScale(in_min, in_max, out_min, out_max) {
    const val = Math.sin(Date.now() * 0.006);
    return (val - in_min) * ((out_max - out_min) / (in_max - in_min)) + out_min;
  }

  /**
   * Phaser update method
   */
  update() {
    super.update();

    if (this._animationActive) { // update pulsing active state
      this.scale.set(this._calcButtonScale(-1, 1, 1, 1.1));
    } else if (!this._animationActive && this.scale.x > 1) { // scale down to 1 if not active
      let newScale = this.scale.x * DISABLED_SCALE_DOWN_RATE;
      if (newScale < 1) newScale = 1;
      this.scale.set(newScale);
    }
  }
}
