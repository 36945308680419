/* eslint-disable no-undef */

import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';

const DEFAULT_RIBBON_CONFIG = {
  asset: 'shop3_dealRibbon1',
  isNineSliced: true,
  sliceRect: {
    left: 60, right: 60, top: 28, bottom: 28,
  },
  horizontalPadding: 140,
  height: 60,
};

const DEFAULT_TEXT_CONFIG = {
  text: 'Exclusive Offer',
  style: 'window_oneTimePopupOffer-headerTxt',
  maxWidth: 500,
};

/**
 * class for TargetedOffer header group
 */
export class TargetedOffer_HeaderGroup extends Phaser.Group {
  /**
   * constructor
   * @param {number} textOffsetY vertical text pixel offset
   * @param {number} ribbonOffsetY vertical ribbon pixel offset
   * @param {Object} ribbonConfig if not passed DEFAULT_RIBBON_CONFIG will be used
   * @param {string} ribbonConfig.asset asset key for ribbon
   * @param {number} ribbonConfig.sliceRect (9-sliced only) rectangle for 9-slicing, if not passed will be treated as normal texture
   * @param {number} ribbonConfig.horizontalPadding (9-sliced only) padding used in addition to textWidth when 9-scaling width
   * @param {number} ribbonConfig.height height of ribbon asset
   * @param {Object} textConfig if not passed DEFAULT_TEXT_CONFIG will be used
   * @param {string} textConfig.text string to be displayed
   * @param {Object || string} textConfig.style text style to apply
   * @param {number} textConfig.maxWidth max width of text
   */
  constructor(textOffsetY, ribbonOffsetY, ribbonConfig = DEFAULT_RIBBON_CONFIG, textConfig = DEFAULT_TEXT_CONFIG) {
    super(game);

    if (ribbonConfig !== DEFAULT_RIBBON_CONFIG) ribbonConfig = { ...DEFAULT_RIBBON_CONFIG, ...ribbonConfig };
    if (textConfig !== DEFAULT_TEXT_CONFIG) textConfig = { ...DEFAULT_TEXT_CONFIG, ...textConfig };

    this._drawHeaderText(textConfig, textOffsetY);
    this._drawHeaderRibbon(ribbonConfig, ribbonOffsetY);

    this.cacheAsBitmap = true;
  }

  /**
   * draw the header ribbon
   * @param {Object} ribbonConfig if not passed DEFAULT_RIBBON_CONFIG will be used
   * @param {string} ribbonConfig.asset asset key for ribbon
   * @param {number} ribbonConfig.sliceRect (9-sliced only) rectangle for 9-slicing, if not passed will be treated as normal texture
   * @param {number} ribbonConfig.horizontalPadding (9-sliced only) padding used in addition to textWidth when 9-scaling width
   * @param {number} ribbonConfig.height height of ribbon asset
   * @param {number} ribbonOffsetY
   */
  _drawHeaderRibbon(ribbonConfig, ribbonOffsetY) {
    const textWidth = this._headerTxt.width;
    let headerRibbon;

    if (ribbonConfig.sliceRect) { // 9-sliced
      headerRibbon = new UI_NineSlice(
        0, ribbonOffsetY, ribbonConfig.asset, textWidth + ribbonConfig.horizontalPadding, ribbonConfig.height, ribbonConfig.sliceRect,
      );
    } else { // non 9-scaled, for insconsistent designs
      headerRibbon = G.makeImage(0, ribbonOffsetY, ribbonConfig.asset);
      headerRibbon.height = ribbonConfig.height;
      headerRibbon.scale.x = headerRibbon.scale.y;
    }
    headerRibbon.anchor.set(0.5);
    this.addChildAt(headerRibbon, 0);
  }

  /**
   * draw the header text
   * @param {Object} textConfig if not passed DEFAULT_TEXT_CONFIG will be used
   * @param {string} textConfig.text string to be displayed
   * @param {Object || string} textConfig.style text style to apply
   * @param {number} textConfig.maxWidth max width of text
   * @param {number} textOffsetY
   */
  _drawHeaderText(textConfig, textOffsetY) {
    const headerTxt = new G.Text(0, textOffsetY, OMT.language.getText(textConfig.text), textConfig.style, 0.5, textConfig.maxWidth, null, true, 'center');
    this.addChild(headerTxt);
    this._headerTxt = headerTxt;
  }
}
