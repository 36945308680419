/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */

import OMT_TweenUtils from '../../Utils/Animation/OMT_TweenUtils';
import OMT_StackManager from '../../Utils/OMT_StackManager';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import OMT_VILLAINS from '../OMT_Villains';
import VillainsBaseClass from './VillainsBaseClass';
import VillainsBaseContainer from './VillainsBaseContainer';

export default class VillainsSpeechBubble extends VillainsBaseClass {
  /**
   * The speech bubble with the tip part of the villain speech bundle
   * @param {any} parent
   * @param {number} villainIndex The villain to use
   * @param {'left'|'top-left'|'top-right'|'right'|'double'} tipPos The position of the tip
   * @param {string} text The text of the speech bubble
   */
  constructor(parent, villainIndex, tipPos, text) {
    super(parent);

    this.villainIndex = villainIndex;
    this.objectPrefix = '_villain';

    const classContainer = new VillainsBaseContainer(this);
    this._villain_container = {
      type: 'group',
      object: classContainer,
    };

    let bubbleTexture = OMT_VILLAINS.getPrefixedName('hard_bubble');
    let bubbleSlice =
      G.OMTsettings.elements.superhard.speech_bubble.hard_bubble;
    if (villainIndex === 2) {
      bubbleTexture = OMT_VILLAINS.getPrefixedName('super_hard_bubble');
      bubbleSlice =
        G.OMTsettings.elements.superhard.speech_bubble.super_hard_bubble;
    }
    let bubble;
    if (!tipPos.includes('top')) {
      bubble = new UI_NineSlice(
        0,
        0,
        bubbleTexture,
        709.5,
        277.5,
        bubbleSlice,
      );
    } else {
      bubble = new UI_NineSlice(0, 0, bubbleTexture, 760, 210, bubbleSlice);
    }
    bubble.anchor.setTo(0.5);
    bubble.scale.setTo(0.66);
    classContainer.add(bubble);
    this._villain_bubble = {
      type: 'speechBubble',
      object: bubble,
    };

    const speechTxtStyle = OMT_VILLAINS.getPrefixedName('villain_2')
      + (villainIndex === 2 ? '_superhard' : '_hard');
    const bubble_text = new G.Text(
      bubble.x,
      bubble.y + bubble.height * 0.025,
      OMT.language.getText(text),
      speechTxtStyle,
      0.5,
      bubble.width * 0.9,
      bubble.height * 0.75,
      true,
      'center',
      false,
      true,
      true,
    );
    classContainer.add(bubble_text);
    this._villain_bubble_text = {
      type: 'text',
      object: bubble_text,
    };

    this.createTips(tipPos);
    parent.add(classContainer);
  }

  /**
   * Creates the tips for the speech bubble
   * @param {'left'|'top-left'|'top-right'|'right'|'double'} tipPos The position of the tip
   */
  createTips(tipPos) {
    const { villainIndex } = this;
    const classContainer = this.getClassContainer();
    let tipTexture = OMT_VILLAINS.getPrefixedName('hard_tip_');
    let tipSet = 'hard';
    if (villainIndex === 2) {
      tipTexture = OMT_VILLAINS.getPrefixedName('super_hard_tip_');
      tipSet = 'superhard';
    }
    tipTexture += tipPos.startsWith('top') ? 1 : 2;

    const tip_1 = G.makeImage(0, 0, tipTexture, 0.5, classContainer);
    classContainer.addChildAt(tip_1, 1);
    tip_1.scale.setTo(0.6385);
    this._villain_tip_1 = {
      index: 1,
      type: 'tip',
      object: tip_1,
    };

    if (tipPos === 'double') {
      const tip_2 = G.makeImage(0, 0, tipTexture, 0.5, classContainer);
      classContainer.addChildAt(tip_2, 1);
      tip_2.scale.setTo(0.6385);
      this._villain_tip_2 = {
        index: 2,
        type: 'tip',
        object: tip_2,
      };

      this.setTipPos(tip_1, 'left', tipSet);
      this.setTipPos(tip_2, 'right', tipSet);

      classContainer.scale.setTo(0.712);
    } else {
      this.setTipPos(tip_1, tipPos, tipSet);

      if (['left', 'right'].includes(tipPos)) {
        classContainer.scale.setTo(0.856);
      } else if (tipPos.includes('top')) {
        classContainer.scale.setTo(0.95);
      }
    }
  }

  /**
   * Sets up the positions of a tip to the given position
   * @param {Phaser.Image} tip The tip to properly position
   * @param {'left'|'top-left'|'top-right'|'right'|'double'} tipPos The position of the tip
   * @param {'hard'|'superhard'} tipSet The set of the tip
   */
  setTipPos(tip, tipPos, tipSet) {
    const bubble = this.getObjects({ type: 'speechBubble' })[0];
    let tipX = bubble.x - bubble.width * 0.5;
    let tipY = bubble.y - bubble.height * 0.5;

    const tipPosData = G.OMTsettings.elements.superhard.bubbleTipPos[tipSet][tipPos];
    if (tipPosData) {
      tipX += tipPosData.x;
      tipY += tipPosData.y;
      tip.scale.x = tipPosData.scale;
    }

    tip.x = tipX + tip.width * 0.5;
    tip.y = tipY + tip.height * 0.5;
  }

  /**
   * Sets the text of the speech bubble
   * @param {string} text the text to set for the speech
   */
  async setText(text) {
    this.setTextSync(text);
    const bubbleText = this.getObjects({ type: 'text' })[0];

    bubbleText.alpha = 0;
    const stack = OMT_StackManager.getFreeStack();

    stack.addPromise(() =>
      OMT_TweenUtils.changeAlphaTo({
        object: bubbleText,
        alpha: 1,
        duration: 300,
      }),
    );

    return stack.run();
  }

  /**
   * Sets the text of the speech bubble instantly
   * @param {string} text the text to set for the speech
   */
  setTextSync(text) {
    const { villainIndex } = this;
    const classContainer = this.getClassContainer();
    const bubble = this.getObjects({ type: 'speechBubble' })[0];
    const bubbleText = this.getObjects({ type: 'text' })[0];

    bubbleText.destroy();
    const speechTxtStyle = OMT_VILLAINS.getPrefixedName('villain_2')
      + (villainIndex === 2 ? '_superhard' : '_hard');
    const bubble_text = new G.Text(
      bubble.x,
      bubble.y + bubble.height * 0.025,
      OMT.language.getText(text),
      speechTxtStyle,
      0.5,
      bubble.width * 0.9,
      bubble.height * 0.75,
      true,
      'center',
      false,
      true,
      true,
    );
    classContainer.add(bubble_text);
    this._villain_bubble_text = {
      type: 'text',
      object: bubble_text,
    };
  }

  /**
   * Shows the object over time
   */
  async show() {
    const classContainer = this.getClassContainer();
    return OMT_TweenUtils.changeAlphaTo({
      object: classContainer,
      duration: 200,
      ease: Phaser.Easing.Sinusoidal.In,
      alpha: 1,
    });
  }

  /**
   * Shows the object over time
   */
  async hide() {
    const classContainer = this.getClassContainer();
    return OMT_TweenUtils.changeAlphaTo({
      object: classContainer,
      duration: 200,
      ease: Phaser.Easing.Sinusoidal.In,
      alpha: 0,
    });
  }
}
