import { Window } from '../../00_IMMEDIATE/Window';
import LevelEconomyTracker from '../GameTracking/LevelEconomyTracker';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import { INTERSTITIAL_RULES } from '../../Services/OMT/ads/OMT_InterstitialAdRules';

/**
 * Window for giving up on a level
 */
class Window_GiveUp extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Function} onGiveUp
   */
  constructor(parent, onGiveUp) {
    super(parent);
    this.state = game.state.getCurrentState();
    this._initLayout();
    this._initButtons(onGiveUp);
  }

  /**
   * @returns {string}
   */
  _getTileLabel() {
    switch (this.state.mode) {
      case LevelType.CHALLENGE: return OMT.language.getText('Daily Challenge');
      case LevelType.TOURNAMENT: return OMT.language.getText('Tournament');
      case LevelType.COLLECT_EVENT: return OMT.language.getText('tokenEvent Level');
      case LevelType.TREASURE_HUNT: return OMT.language.getText('Treasure Hunt Level');
      default: return `${OMT.language.getText('Level')} ${G.lvl.lvlIndex + 1}`;
    }
  }

  /**
   * init window layout
   */
  _initLayout() {
    // gingy / mascot image
    if (G.LIVES && this.state.removedLifeAtStart) {
      const gingyImage = G.makeImage(0, -400, 'sad_gingy_heart_small', 0.5, this);
      gingyImage.scale.setTo(1.33, 1.33);
      // gingyImage.rotation = -30 * (Math.PI / 180);
    }

    // set background
    this.addBackground('popup_background_2');

    // level info text
    const textStyle = typeof G.OMTsettings.global.window.mediumTitle.textStyle === 'string'
      ? { style: G.OMTsettings.global.window.mediumTitle.textStyle }
      : G.OMTsettings.global.window.mediumTitle.textStyle;

    this._levelTxt = new G.Text(0, -292,
      this._getTileLabel(), {
        ...textStyle,
        lineSpacing: -20,
      }, 0.5, 440, 250, true, 'center');
    this.add(this._levelTxt);

    // lost progress warning
    this._loseProgressTxt = new G.Text(0, -70, OMT.language.getText('Your level progress will be lost!'), {
      style: 'font-blue',
      fontSize: '45px',
    }, 0.5, 500, 140, true, 'center');
    this.add(this._loseProgressTxt);

    // player id text field
    this._playerIdText = new G.Text(0, 295,
      // eslint-disable-next-line prefer-template
      'Player ID: ' + (
        typeof OMT.envData.settings.user.userId === 'number'
          ? OMT.envData.settings.user.userId.toString() : OMT.envData.settings.user.userId
      ),
      {
        style: 'font-blue',
        fontSize: '20px',
      }, 0.5, 440);
    // this.add(this._playerIdText);

    // lives status text / image
    if (G.LIVES && this.state.removedLifeAtStart) {
      this._brokenHeart = G.makeImage(-120, 215, 'broken_heart', 0.5, this);
      this.minusOneTxt = new G.Text(-125, 215, '-1', {
        style: 'font-white',
        fontSize: '35px',
      }, 0.5, 50);
      this.add(this.minusOneTxt);
    }
  }

  /**
   * init buttons
   * @param {Function} onGiveUp
   */
  _initButtons(onGiveUp) {
    // close button
    this.addCloseButton();

    // give up button
    const giveUpClick = async () => {
      G.sb('onLevelFailed').dispatch();
      const isDailyChallenge = this.state.mode === LevelType.CHALLENGE;

      const shouldLoseMysteryStreak = [LevelType.TOURNAMENT, LevelType.TREASURE_HUNT].indexOf(this.state.mode) === -1; // If not these, lose it
      if (G.MYSTERYGIFT && shouldLoseMysteryStreak && !isDailyChallenge && !G.lvlData.noPreBoosters) {
        G.saveState.mysteryGiftManager.finishStreak();
      }
      G.winsInRow = 0;
      G.saveState.resetAchievementWinCount();
      G.saveState.resetCWinCount();
      if (!isDailyChallenge) {
        LevelEconomyTracker.getInstance().finishLevel(0);
      }

      if (isDailyChallenge) {
        G.sb('onDailyChallengeFinished').dispatch();
      } else if (this.state.mode === LevelType.TOURNAMENT) {
        // check if we need to show the bot opt-in
        // const ftuBotOptIn = OMT.feature.getFTUBotOptInAtTournament();
        // if (ftuBotOptIn) await OMT.bots.checkStateAndAttemptSubcribe();
      }

      this.state.endPointCheckForAds(() => {
        if (onGiveUp) onGiveUp();
      }, INTERSTITIAL_RULES.GIVE_UP);
    };
    this._giveUpBtn = new OMT_UI_SquareButton(0, 215, {
      button: {
        tint: BUTTONCOLOURS.red,
        dimensions: {
          width: 166,
          height: 86,
        },
        extraDetail: false,
      },
      text: {
        string: OMT.language.getText('Give up'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: giveUpClick.bind(this),
        },
      },
    });
    this.registerButtons(this._giveUpBtn);

    // continue button
    const onClick = () => {
      this.closeWindow();
    };
    this._continueBtn = new OMT_UI_SquareButton(0, 100, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Keep Playing'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: onClick.bind(this),
        },
        pulse: 1.1,
      },
    });
    this.registerButtons(this._continueBtn);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.giveUp = Window_GiveUp;
