/* eslint-disable object-curly-newline */
const DEFAULT_CONFIG = {
  text: {
    style: {
      style: 'font-num-blue',
      fontSize: 22,
      scaleStroke: true,
    },
    offset: { x: 110, y: 0 },
    anchor: [1, 0.5],
    angle: 0,
    visible: true,
  },
  icon: { // for no icon this can be undefined / null
    asset: 'coin_1',
    anchor: [0.5, 0.5],
    scale: 0.6,
    offset: { x: 110, y: 0 },
    angle: 0,
    visible: true,
  },
};

const SPIN_ANGLE_VARIANCE = 0.99; // randomness for spin angle

/**
 * Class for prize wheel items
 */
export default class PrizeWheel_Item extends Phaser.Group {
  /**
   * constructor
   * @param {PrizeWheel_ItemData} itemData
   */
  constructor(itemData) {
    super(game);

    this._data = itemData;

    const { config } = itemData; // overrides for DEFAULT_CONFIG
    this._config = _.merge(_.cloneDeep(DEFAULT_CONFIG), config);

    this._createPrizeText();
    this._createPrizeIcon();

    this._prizeText.visible = this._config.text.visible;
    this._iconImage.visible = this._config.icon.visible;
  }

  /**
   * intit rotate / align to mid point between startAngle / endAngle
   * @param {number} startAngle
   * @param {number} endAngle
   */
  setRotation(startAngle, endAngle) {
    this._startAngle = startAngle;
    this._endAngle = endAngle;
    const itemRotation = this._startAngle + ((this._endAngle - this._startAngle) / 2);
    this.angle = itemRotation;
  }

  /**
   * create prize text
   */
  _createPrizeText() {
    const { style, offset, angle, anchor } = this._config.text;
    const prizeTextGroup = new Phaser.Group(game);
    const prizeText = new G.Text(0, 0, this._data.label, style, anchor, undefined, undefined, false);
    prizeTextGroup.x = offset.x;
    prizeTextGroup.y = offset.y;
    prizeTextGroup.angle = angle;
    prizeTextGroup.addChild(prizeText);
    this.addChild(prizeTextGroup);
    this._prizeText = prizeText;
  }

  /**
   * create prize icon if defined
   */
  _createPrizeIcon() {
    const config = this._config.icon;
    if (config == null) return;

    const { asset, anchor, scale, offset, angle } = config;
    const iconImage = G.makeImage(0, 0, asset, anchor);
    iconImage.x = offset.x;
    iconImage.y = offset.y;
    iconImage.scale.x = iconImage.scale.y = scale;
    iconImage.angle = angle;
    this.addChild(iconImage);
    this._iconImage = iconImage;
  }

  /**
   * get a random angle between startAgle and endAngle
   * @returns {number}
   */
  getRandomizedAngle() {
    const rangeDeltaAngle = this._endAngle - this._startAngle;
    const centerAngle = this._startAngle + (rangeDeltaAngle / 2);
    const randomAngle = centerAngle + ((Math.random() * (rangeDeltaAngle / 2)) * SPIN_ANGLE_VARIANCE);
    return randomAngle;
  }

  /** @returns {PrizeWheel_ItemData} */
  get data() { return this._data; }

  /** @returns {number} */
  get startAngle() { return this._startAngle; }

  /** @returns {number} */
  get endAngle() { return this._endAngle; }

  /** @returns {Object} gobal x,y of icon */
  get globalIconPos() { return this.toGlobal(this._iconImage); }

  /** @returns {ItemDataType} returns the item type */
  get itemType() { return this._data.type; }
}
