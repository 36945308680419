/* eslint-disable no-undef */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-use-before-define */
import { OMT_Utils } from '../../Services/Utils/OMT_Utils';
import RuntimeSpritesheet from './RuntimeSpritesheet';
import RuntimeSpritesheetSprite from './RuntimeSpritesheetSprite';

// spritesheet IDs. Each spritesheet should have a ID.
export const RUNTIME_SPRITESHEET_IDS = {
  DEFAULT: 'rtss:default',
  SHOP: 'rtss:shop',
  WORLD_MAP: 'rtss:world-map',
  AVATARS: 'rtss:avatars',
  FRIENDSHIPCHEST: 'rtss:friendshipChest',
  MAILBOX: 'rtss:mailbox',
  FORTUNECOOKIE: 'rtss:fortuneCookie',
  TREASUREHUNT: 'rtss:treasureHunt',
  TIMEDIAP: 'rtss:timedIAP',
};

// configs for spritesheets. Each spritesheet in use should have a config.
const RUNTIME_SPRITESHEET_CONFIGS = {
  [RUNTIME_SPRITESHEET_IDS.DEFAULT]: { size: 1024, destroyOnStageChange: true },
  [RUNTIME_SPRITESHEET_IDS.SHOP]: { size: 2048, destroyOnStageChange: false },
  [RUNTIME_SPRITESHEET_IDS.WORLD_MAP]: { size: 2048, destroyOnStageChange: true },
  [RUNTIME_SPRITESHEET_IDS.AVATARS]: { size: 1024, destroyOnStageChange: true },
  [RUNTIME_SPRITESHEET_IDS.FRIENDSHIPCHEST]: { size: 2048, destroyOnStageChange: false },
  [RUNTIME_SPRITESHEET_IDS.MAILBOX]: { size: 2048, destroyOnStageChange: false },
  [RUNTIME_SPRITESHEET_IDS.FORTUNECOOKIE]: { size: 2048, destroyOnStageChange: false },
  [RUNTIME_SPRITESHEET_IDS.TIMEDIAP]: { size: 2048, destroyOnStageChange: false },
};

let _instance; // singleton instance for RuntimeSpritesheetManager

/**
 * class for managing runtime spritehsheets
 */
export default class RuntimeSpritesheetManager {
  /**
   * get static instance
   * @returns {RuntimeSpritesheetManager}
   */
  static getInstance() {
    if (!_instance) _instance = new RuntimeSpritesheetManager();
    return _instance;
  }

  /**
   * constructor
   */
  constructor() {
    this._sheetPageData = {};
    this._frameDataTable = {};

    game.state.onStateChange.addPermanent(this._destroyOnStageChange, this);
  }

  /**
   * generate a RuntimeSpritesheetSprite instance
   * @param {Phaser.DisplayObject} sprite displayObject to clone
   * @param {string} textureId texture id for cache
   * @returns {RuntimeSpritesheetSprite}
   */
  addSprite(sourceSprite, textureId, spriteSheetId = RUNTIME_SPRITESHEET_IDS.DEFAULT) {
    let frameData = this.getFrameData(textureId, spriteSheetId);
    if (!frameData) { // need to add a new frame to the SpriteSheet
      this.addFrame(textureId, spriteSheetId, sourceSprite);
      frameData = this.getFrameData(textureId, spriteSheetId);
    }
    const rtSprite = this.getSprite(textureId, spriteSheetId);
    return rtSprite;
  }

  /**
   * get a RuntimeSpritesheetSprite instance
   * @param {string} textureId texture id for cache
   * @returns {RuntimeSpritesheetSprite}
   */
  getSprite(textureId, spriteSheetId) {
    const frameData = this.getFrameData(textureId, spriteSheetId);
    if (!frameData) return null;
    const sprite = new RuntimeSpritesheetSprite(frameData);
    return sprite;
  }

  /**
   * get a PIXI.Texture instance from the spritesheet
   * @param {string} textureId
   * @param {string} spriteSheetId
   * @returns {PIXI.Texture}
   */
  getTexture(textureId, spriteSheetId) {
    const frameData = this.getFrameData(textureId, spriteSheetId);
    if (!frameData) return null;
    return frameData.texture;
  }

  /**
   * searches RuntimeSpritesheet instances for the textureID
   * @param {string} textureId
   * @returns {string}
   */
  findSpritesheetIdByFrame(textureId) {
    for (const spritesheetId in this._frameDataTable) {
      const spriteSheetFrameData = this._frameDataTable[spritesheetId];
      if (!spriteSheetFrameData) continue;
      if (spriteSheetFrameData[textureId] != null) return spritesheetId;
    }
    return null;
  }

  /**
   * create a new RuntimeSpritesheet instance
   * @param {string} spriteSheetId
   */
  _createNewSpriteSheetPage(spriteSheetId) {
    if (!this._sheetPageData[spriteSheetId]) this._sheetPageData[spriteSheetId] = { pages: [] };
    const { pages } = this._sheetPageData[spriteSheetId];
    const config = RUNTIME_SPRITESHEET_CONFIGS[spriteSheetId] || RUNTIME_SPRITESHEET_CONFIGS[RUNTIME_SPRITESHEET_IDS.DEFAULT];
    pages.push(new RuntimeSpritesheet(spriteSheetId, config.size));
    OMT_Utils.stylizedLog(`RuntimeSpritesheetManager: CREATED, id: ${spriteSheetId}, page: ${pages.length}`);
  }

  /**
   * get RuntimeSpritesheet instance for drawing
   * @param {string} spriteSheetId
   * @returns {RuntimeSpritesheet}
   */
  getSpriteSheetPage(spriteSheetId) {
    if (!this._sheetPageData[spriteSheetId]) this._createNewSpriteSheetPage(spriteSheetId);
    const { pages } = this._sheetPageData[spriteSheetId];
    const lastPage = pages[pages.length - 1];
    return lastPage;
  }

  /**
   * add a frame to a runtime spritesheet
   * @param {string} textureId
   * @param {string} spriteSheetId
   * @param {Phaser.Sprite} sourceSprite
   */
  addFrame(textureId, spriteSheetId, sourceSprite) {
    const runtimeSpriteSheet = this.getSpriteSheetPage(spriteSheetId);
    const frameData = runtimeSpriteSheet.addFrame(textureId, sourceSprite);
    if (frameData) { // create a reference to the FrameData by spriteSheetId, textureId
      if (!this._frameDataTable[spriteSheetId]) this._frameDataTable[spriteSheetId] = {};
      const spriteSheetFrameData = this._frameDataTable[spriteSheetId];
      spriteSheetFrameData[textureId] = frameData;
    } else {
      this._createNewSpriteSheetPage(spriteSheetId);
      this.addFrame(textureId, spriteSheetId, sourceSprite);
    }
  }

  /**
   * get texture frame data for a sprite instance
   * @param {string} textureId
   * @param {string} spriteSheetId
   * @returns {Object}
   */
  getFrameData(textureId, spriteSheetId) {
    const spriteSheetFrameData = this._frameDataTable[spriteSheetId];
    if (!spriteSheetFrameData) return null;
    return spriteSheetFrameData[textureId];
  }

  /**
   * render baseTextures / images from the game cache to a spritesheet
   * @param {string} spriteSheetId
   * @param {string} cacheKey baseTexture key in the game cache
   */
  renderBaseTextureToSpritesheet(spriteSheetId, cacheKey) {
    if (this.getFrameData(cacheKey, spriteSheetId) != null) return;
    this._drawSprite = new Phaser.Sprite(game, 0, 0, cacheKey);
    const drawSprite = this._drawSprite;
    this.addSprite(drawSprite, cacheKey, spriteSheetId);
  }

  /**
   * destroy spritesheet by id
   * @param {string} spriteSheetId
   */
  destroySpritesheet(spriteSheetId) {
    if (!this._sheetPageData[spriteSheetId]) return;
    const { pages } = this._sheetPageData[spriteSheetId];
    for (const page of pages) {
      page.destroy();
      OMT_Utils.stylizedLog(`RuntimeSpritesheetManager: DESTROYED, id: ${spriteSheetId}, page: ${pages.indexOf(page) + 1}`);
    }
    pages.length = 0;
    delete this._sheetPageData[spriteSheetId];
    this._frameDataTable[spriteSheetId] = null;
  }

  /**
   * destroy spritesheets with destroyOnStageChange flagged
   */
  _destroyOnStageChange() {
    for (const spriteSheetId in RUNTIME_SPRITESHEET_CONFIGS) {
      const { destroyOnStageChange } = RUNTIME_SPRITESHEET_CONFIGS[spriteSheetId];
      if (destroyOnStageChange) this.destroySpritesheet(spriteSheetId);
    }
  }

  /**
   * is this spritesheet a runtime spritesheet?
   * @param {string} spriteSheetId
   * @returns {boolean}
   */
  isRuntimeSpritesheet(spriteSheetId) {
    return this._sheetPageData[spriteSheetId] != null;
  }
}

// for easy reference in old code without import
window.RuntimeSpritesheetManager = RuntimeSpritesheetManager;
