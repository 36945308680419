/* eslint-disable */
import TreasureHuntManager, { TREASURE_HUNT_BOT_MSG_END, TREASURE_HUNT_BOT_MSG_START, TREASURE_HUNT_TIME_READY } from '../../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';
import { LIFE_SEND_COOLDOWN } from '../../Leaderboards/MainLeaderboardSettings';
import { BOT_MESSAGE_TYPE } from '../../Services/OMT/OMT_Bots';
import { createRealMoneySpinId } from '../SpinningWheels/RealMoneyWheel/RealMoneyWheelHelpers';
import { RMWHEEL_MODES, RMWHEEL_EPS } from '../SpinningWheels/RealMoneyWheel/rmWheelEnums';
import TargetedOfferDataManager from '../../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { MILLISECONDS_IN_MIN } from '@omt-components/Utils/TimeUtil';

G.WorldMapAppearFlow = G.WorldMapAppearFlow || {};
export default class PayloadChecker {
  constructor() {
    // we initialize this at checkPayloadAtPreloader, the data isn't available yet
    this.currentPlayerId = null;
    // we use this to push windows for given payloads
    this.onPopUpRequested = new Phaser.Signal();
  }

  /**
 * Checks the payload at PRELOADER
 * @param {Object} payload
 */
  checkPayloadAtPreloader(payload) {
    this.currentPlayerId = OMT.envData.settings.user.userId;

    const forwardedPayload = payload.userId !== this.currentPlayerId && payload.additions && payload.additions.restoreProgression;
    if (forwardedPayload) {
      console.log('ERROR WITH RESTORE PROGRESS PAYLOAD: ID of payload does not match ID of player. Rejecting payload.');
      OMT.envData.rejectPayload();
    }

    const isMsgForUser = G.saveState.isMsgForUser(payload);
    if (!isMsgForUser) {
      console.log('ERROR WITH PAYLOAD: ID of payload does not match ID of player. Rejecting payload.');
      OMT.envData.rejectPayload();
      return;
    }

    if (payload.friendshipChestInvite) {
      this.checkFriendshipChestInvitePayload(payload, payload.friendshipChestInvite, true);
    }

    // fortune cookie payload
    if (payload.fortuneCookie && G.firstTime) {
      this.checkFortuneCookiePayload(payload, payload.fortuneCookie);
    }

    // player state change payload.
    if (payload.stateChange) {
      this.checkStateChangePayload(payload, payload.stateChange);
    }

    // brag 2.0 entry point
    if (payload.sendParams && payload.sendParams.msgName === 'BRAG' && OMT.feature.isBrag2Active()) {
      this.checkBragPayload(payload);
    }

    if (payload.crossPromoReward) {
      this._checkCrossPromoPayloadAtPreloader(payload.crossPromoReward);
    }

    // targeted offer entry
    if (payload.targetedOffer) {
      this.checkTargetedOffer(payload);
    }
  }

  /**
   * Checks the payload at WORLD
   * @param {Object} payload
   */
  async checkPayloadAtWorld(payload) {
    if (payload.inGameMsg === 'Invite') {
      this.checkInvitePayload();
    }

    if (payload.command) {
      this.checkCommandPayload(payload, payload.command);
    }

    if (payload.gift) {
      if (payload.additions && payload.additions.quiz) {
        this.checkQuizPayload(payload, payload.gift, payload.additions.quiz.correctAnswer);
      } else if (!(payload.additions && payload.additions.restoreProgression)) {
        this.checkGiftPayload(payload, payload.gift);
      }
    }

    if (payload.life) {
      this.checkLifePayload(payload, payload.life);
    }

    if (payload.lifeRequest) {
      this.checkLifeRequestPayload(payload, payload.lifeRequest);
    }

    if (payload.gateRequest) {
      this.checkGateRequestPayload(payload, payload.gateRequest);
    }

    if (payload.thanks) {
      this.checkThanksPayload(payload, payload.thanks);
    }

    if (payload.additions && payload.additions.restoreProgression) {
      this.checkRestoreProgressionPayload(payload, payload.additions.restoreProgression);
    }

    if (payload.promoReward) {
      this.checkPromoRewardPayload(payload);
    }

    if (payload.requestHelp) {
      this.checkRequestHelpPayload(payload, payload.requestHelp);
    }

    if (payload.friendshipChestInvite) {
      this.checkFriendshipChestInvitePayload(payload, payload.friendshipChestInvite, false);
    }

    if (payload.friendshipChestJoin) {
      this.checkFriendshipChestJoinPayload(payload, payload.friendshipChestJoin);
    }

    if (payload.fortuneCookie) {
      this.checkFortuneCookiePayload(payload, payload.fortuneCookie);
    }

    if (payload.postcardEvent) {
      this.checkEventPostcardWorld(payload);
    }

    if (payload.treasureHunt) {
      this.checkTreasureHunt();
    }

    // Real money Spin Payload:
    // Shareable links = payload.realMoneyWheelSpin
    // Bot messages = payload.additions.realMoneyWheelSpin
    if (payload.realMoneyWheelSpin || (payload.additions && payload.additions.realMoneyWheelSpin)) {
      this.checkRealMoneyWheelPayload(payload);
    }

    if (payload.additions && payload.additions.tokenEvent) {
      if (payload.additions.tokenEvent === G.OMTsettings.tokenEvent.DDNAEventID
        && OMT.feature.isTokenEventOn(true, true)
        && G.saveState.tokenEventManager.FTUE.ee) {
          this.onPopUpRequested.dispatch(['eventEntry']);
      }
    }

    if (payload.additions && payload.additions.treasureHunt) {
      if (!G.saveState.treasureHuntManager.isTimeReady) {
        await new Promise((resolve) => { // Short time, to wait for the connection to establish
          game.time.events.add(2000, () => { resolve(); });
        });
      }
      if (OMT.feature.isTreasureHuntOn(true, true, false, true) && !G.saveState.treasureHuntManager.contentEntry) {
        G.saveState.treasureHuntManager.sessionData.onLoadPopupPending = true;
        this.onPopUpRequested.dispatch(['treasureHuntActiveNotice']);
      }
    }

    // track bot message on game-backend
    // if (OMT.bots.entryPointIsBotMsg !== BOT_MESSAGE_TYPE.NOT_A_BOT_MESSAGE) {
    //   OMT.bots.trackBotConversion();
    // }

    if (payload.crossPromoReward) {
      this._checkCrossPromoPayloadAtWorld(payload.crossPromoReward);
    }
  }

  /**
   * Checks bot message entry point ids. Because sometimes they need to do stuff
   */
  checkBotMsgAtWorld() {
    const entryPointDataMessageId = OMT.envData.entryPointData.messageId;
    if (!entryPointDataMessageId) { return true; }
    if (entryPointDataMessageId.includes(TREASURE_HUNT_BOT_MSG_START) || entryPointDataMessageId.includes(TREASURE_HUNT_BOT_MSG_END)) {
      this.checkTreasureHunt();
    }
  }

  /**
   * Get the message ID. We check a couple places for backwards compatibility
   * @param {Object} payload
   * @returns {string}
   */
  getMsgId(payload) {
    let msgId = null;

    if (payload) { // in case payload is null
      if (payload.sendParams && payload.sendParams.msgID) {
        msgId = payload.sendParams.msgID;
      } else if (payload.additions && payload.additions.realMoneyWheelSpin) { // real money spins
        msgId = payload.payloadId;
      } else { // recursively check for id value
        // eslint-disable-next-line guard-for-in
        for (const i in payload) {
          if (i === 'id') return payload[i];
          if (typeof payload[i] === 'object') { // Note: this also evaluates to true if payload is null
            msgId = this.getMsgId(payload[i]);
            if (msgId) break;
          }
        }
      }
    }

    return msgId;
  }

  /**
   * Reformat gift data object array to the ['booster#1',1] style format used in the game
   * @param {Object} giftData
   * @returns {Array}
   */
  reformatGiftData(giftData) {
    let gameGiftDataList = [];
    try {
      const giftDataList = Array.isArray(giftData) ? giftData : [giftData];
      giftDataList.forEach((data) => {
        gameGiftDataList.push([data.itemId, data.amount]);
      });
    } catch (e) {
      // G.Utils.SentryLog.logError('No gift data attached to gift payload!', {});
      gameGiftDataList = G.gift.getGift('normals');
    }
    return gameGiftDataList;
  }

  /**
   *Does nothing right now.
   *
   */
  checkInvitePayload() {
    // does nothing yet except tracking
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.InviteFromReferral);
  }

  /**
   * Will immediately bring up the level window for a given level.
   * @param {Object} payload The full payload
   * @param {Object} command The data we want to use from the payload
   */
  checkCommandPayload(payload, command) {
    if (command.goToLevel) {
      const lastPassedLvlNum = G.saveState.getLastPassedLevelNr();
      const { levelIndex } = command.goToLevel;
      if (levelIndex <= lastPassedLvlNum && G.Helpers.levelDataMgr.getLevelByIndex(levelIndex)) {
        this.onPopUpRequested.dispatch(['level', levelIndex]);
      } else {
        console.error(`PayloadChecker: cannot process goToLevel command for index ${levelIndex}, you have not reached this level.`);
      }
    }
  }

  /**
   * Will give the player a gift.
   * @param {Object} payload The full payload
   * @param {Object} giftPayload The data we want to use from the payload
   */
  checkGiftPayload(payload, giftPayload) {
    const giftDataList = this.reformatGiftData(giftPayload.giftData);
    const msgId = this.getMsgId(payload);
    const payLoadOK = !G.saveState.isMsgRead(msgId) && giftPayload.sender !== this.currentPlayerId;
    const payLoadForUser = G.saveState.isMsgForUser(payload);

    if (payLoadOK && payLoadForUser) {
      this.onPopUpRequested.dispatch(['gift', {
        showTitleBar: false,
        showGingy: false,
        showGingyGiftBar: false,
        autoOpen: true,
        autoOpenDelay: 1500,
        noSwapAnimation: true,
        gifts: giftDataList,
        onClaim() {
          G.saveState.markMsgAsRead('payloadGifts', msgId);
        },
        monetiseType: false,
        resourceTracking: {
          itemType: 'Reward',
          itemId: 'PayloadGift',
        },
        shareable: false,
        ddnaEventData: { transactionType: 'REWARD', tActionType: 'BOT', tGameArea: 'MAP' },
      }], false, G.WindowMgr.LayerNames.Base);
    }
  }

  /**
   * Will give the player a single life.
   * @param {Object} payload The full payload
   * @param {Object} lifePayload The data we want to use from the payload
   */
  checkLifePayload(payload, lifePayload) {
    const msgId = this.getMsgId(payload);
    if (!G.saveState.isMsgRead(msgId)
      && lifePayload.sender !== this.currentPlayerId) {
      this.onPopUpRequested.dispatch('lifeReceived');
      G.saveState.markMsgAsRead('acceptedLives', msgId);
    }
  }

  /**
   * Gives the player a notification that they successfully sent a life to a friend.
   * @param {Object} payload The full payload
   * @param {Object} lifeRequestPayload The data we want to use from the payload
   */
  checkLifeRequestPayload(payload, lifeRequestPayload) {
    const msgId = this.getMsgId(payload);

    if (!G.saveState.isMsgRead(msgId) && lifeRequestPayload !== this.currentPlayerId) {
      const sender = {
        userId: lifeRequestPayload.sender,
        image: lifeRequestPayload.senderAvatar,
      }
      const canResourceBeSent = G.saveState.getUserCooldownRemaining(LIFE_SEND_COOLDOWN.id, sender.userId) === 0;
      if (canResourceBeSent) {
        OMT.social.sendLifeMessage(sender, (success) => {
          if (!success) {
            this.onPopUpRequested.dispatch('lifeSentSuccessfully');
            G.saveState.markMsgAsRead('acceptedLivesRequests', msgId);
            G.saveState.setUserCooldown(LIFE_SEND_COOLDOWN.id, sender.userId, LIFE_SEND_COOLDOWN.duration);
            G.saveState.save();
          }
        });
      }
    }
  }

  /**
   * Will give the player a thank you message for opening a gate for their friend.
   * @param {Object} payload The full payload
   * @param {Object} gateRequestPayload The data we want to use from the payload
   */
  async checkGateRequestPayload(payload, gateRequestPayload) {
    const msgId = this.getMsgId(payload);
    if (!G.saveState.isMsgRead(msgId)
    && gateRequestPayload.sender !== this.currentPlayerId) {
      const result = await OMT.social.sendGateMsg(gateRequestPayload.sender, gateRequestPayload.gateId);
      if (result) {
        // OMT.messaging.sendMessage(gateRequestPayload.gbUserId, 'gateHereKey', {
        //   messageType: 'gateHereKey', gateId: gateRequestPayload.gateId, sender: gateRequestPayload.sender, senderAvatar: gateRequestPayload.senderAvatar,
        // });
        G.saveState.markMsgAsRead('acceptedGateRequests', msgId);
        this.onPopUpRequested.dispatch(['thanksForHelp', gateRequestPayload]);
      }
    }
  }

  /**
   * check thanks for help payload
   * @param {Object} payload 
   * @param {Object} thanksPayload 
   */
  checkThanksPayload(payload, thanksPayload) {
    const msgId = this.getMsgId(payload, thanksPayload);
    if (!G.saveState.isMsgRead(msgId)
      && thanksPayload.sender !== this.currentPlayerId) {
      G.saveState.markMsgAsRead('thanksMsg', msgId);
      this.onPopUpRequested.dispatch(['thanksForHelp', thanksPayload]);
    }
  }

  /**
   * check the brag 2.0 payload
   * @param {Object} payload
   */
  checkBragPayload(payload) {
    const sendParams = payload.sendParams;
    const lvlIndex = parseInt(sendParams.epSocialActionLevel) - 1;
    const friendName = sendParams.senderName
    // clicked your own brag, start level normally
    if (payload.sendParams.senderId === OMT.envData.settings.user.userId) { 
      window.startupState = { state: 'Game', data: { lvlIndex } }; 
    } else { // brag from another user, start a brag challenge level
      window.startupState = { state: 'Game', data: { lvlIndex, tutorial: 'brag_2.0', friendName } }; 
    }
  }

  /**
   * Checks the payload at preloader.
   * ONLY CHECKS IAPBONUS AND SKIP TUTORIAL
   * Rewards are checked at World
   * @param {{senderParams:{senderId:number}, iapBonus?:number, skipTutorial?:boolean}} payload 
   */
  _checkCrossPromoPayloadAtPreloader(payload) {
    if (OMT.envData.entryPoint === 'game_switch') { 
      if (payload.iapBonus) {
        G.saveState.changeIAPMultiplier(payload.iapBonus);
        G.saveState.save();
      } else if (Number.isFinite(payload.skipTutorial) && G.saveState.getLastPassedLevelNr() < payload.skipTutorial) {
        G.saveState.sessionData.crossPromoSkipTutorial = 60;
      }
    }
  }

  /**
   * Check targeted offer payload
   * @param {Object} payload 
   */
  checkTargetedOffer(payload) {
    if (!G.IAP) return; // Ignore payload if not in IAP environment
    const offerData = payload.targetedOffer;
    const newOffer = {
      pid: offerData.productId,
      et: Date.now() + offerData.timeLimitInMins * MILLISECONDS_IN_MIN,
    }

    const targetedOfferDataManager = TargetedOfferDataManager.getInstance();
    targetedOfferDataManager.enqueueTargetedOffer(newOffer, offerData.highPriority);

    // Mark the new offer with im = true, if it is next in line to be shown,
    // so that it appears immediately in the World state
    const timeLeft = targetedOfferDataManager.getCurrentTargetedOfferTimeRemaining();

    if (timeLeft <= 0) {
      const currentOffer = targetedOfferDataManager.dequeueTargetedOffer();
      if (currentOffer.pid === newOffer.pid) {
        currentOffer.im = true;
      }
    }
  }

  /**
   * Will give the player a gift depending on if they answered a question correctly or not.
   * @param {Object} payload The full payload
   * @param {Object} giftPayload The data we want to use from the payload
   */
  checkQuizPayload(payload, giftPayload, correctAnswer) {
    const msgId = this.getMsgId(payload);
    if (G.saveState.isMsgRead(msgId)) return;

    if (correctAnswer) {
      G.saveState.markMsgAsRead('quiz', msgId);
      const giftPack = [];
      for (let i = 0; i < giftPayload.giftData.length; i++) {
        const gift = [giftPayload.giftData[i].itemId, giftPayload.giftData[i].amount];
        giftPack.push(gift);
      }
      this.onPopUpRequested.dispatch(['gift', {
        showTitleBar: false,
        showGingy: true,
        showGingyGiftBar: false,
        autoOpen: true,
        titleTextBar: {
          titleTxt: OMT.language.getText('Congratulations!'),
          subtitleTxt: OMT.language.getText('You answered correctly!'),
          y: -200,
        },
        gingyTitleOffset: {
          x: 0,
          y: -240,
        },
        gingyOffset: {
          x: 40,
          y: 100,
        },
        giftOffset: {
          x: 0,
          y: 20,
        },
        buttonsOffset: {
          x: 0,
          y: -60,
        },
        gifts: giftPack,
        monetiseType: false,
        resourceTracking: {
          itemType: 'Reward',
          itemId: 'Quiz',
        },
        shareable: false,
        incentivisedSharing: false,
      // and blocks input
      // }], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
      }]);
    } else {
      this.onPopUpRequested.dispatch(['incorrectAnswer', {
        titleTextBar: {
          titleTxt: OMT.language.getText('Too bad!'),
          subtitleTxt: OMT.language.getText('You answered incorrectly!'),
          y: 0,
        },
      }]);
    }
  }

  /**
   * Will restore the player's coins, and stars.
   * @param {Object} payload The full payload
   * @param {Object} restoreProgressPayload The data we want to use from the payload
   */
  checkRestoreProgressionPayload(payload, restoreProgressPayload) {
    try {
      const { levelsCompleted } = restoreProgressPayload;
      const coins = restoreProgressPayload.coins ? restoreProgressPayload.coins : 0;
      // let restored = false;
      if (levelsCompleted !== undefined) {
        G.saveState.restoreProgress(levelsCompleted, coins);
      }

      game.state.onStateChange.addOnce(() => {
        game.state.onUpdateCallback = () => {
          delete game.state.onUpdateCallback;
          if (payload.gift) {
            setTimeout(() => {
              this.checkGiftPayload(payload, payload.gift);
            }, 250);
          }
        };
      });
      window.game.state.restart();
    } catch (error) {
      // G.Utils.SentryLog.logError(`Failed to restore progression from payload: ${error}`);
    }
  }

  /**
   * Gives the player a gift for xpromo. Unsure if this is still in use.
   * @param {Object} payload The full payload
   */
  checkPromoRewardPayload(payload) {
    let gift = [
      ['coin', 2000],
    ];
    // Ensure the user didn't get the gift in the past
    if (G.saveState.isMsgRead(payload.originGameId)) return;
    // Define reward, and track
    if (G.saveState.data.finishedTutorials.length >= 2) {
      gift = [
        ['coin', 500],
      ];
    }

    // Open popup
    console.log('new promo reward, payload.originGameId = ', payload.originGameId);
    this.onPopUpRequested.dispatch(['gift', {
      titleTextBar: {
        titleTxt: OMT.language.getText('Thanks for subscribing!'),
      },
      showGingy: false,
      showGingyGiftBar: false,
      autoOpen: true,
      autoOpenDelay: 1500,
      gifts: gift,
      onClaim() {
        G.saveState.markMsgAsRead('promoReward', payload.originGameId);
      },
      monetiseType: false,
      resourceTracking: {
        itemType: 'Reward',
        itemId: 'CrossPromoReward',
      },
      shareable: false,
    }], false, G.WindowMgr.LayerNames.Base);
  }

  /**
 * Payload for when the player clicks on a request help message
 * @param {Object} payload The full payload
 * @param {Object} requestHelpPayload The data we want to use from the payload
 */
  checkRequestHelpPayload(payload, requestHelpPayload) {
    if (!payload.sendParams) return;
    const msgId = this.getMsgId(payload);

    if (!G.saveState.isMsgRead(msgId)
      && payload.sendParams.senderId !== this.currentPlayerId) {
      G.saveState.markMsgAsRead('requestHelpMsg', msgId);
      this.onPopUpRequested.dispatch(['requestHelpMessage', requestHelpPayload]);
    }
  }

  /**
 * Payload for when a player clicks on a friendship chest invitation message
 * @param {Object} payload The full payload
 * @param {Object} friendshipChestPayload The data we want to use from the payload
 * @param {Object} isPreloader Whether this is being called from the preloader or the world.
 */
  checkFriendshipChestInvitePayload(payload, friendshipChestPayload, isPreloader = false) {
    if (isPreloader) { // Happens at preloader check. If not, it continues to work check. Assuming message is still valid
      if (G.firstTime) {
        G.saveState.friendshipChestDataManager.setInvitedBy(friendshipChestPayload.inviterFbId);
        // OMT.messaging.sendMessage(friendshipChestPayload.inviterGbId, 'friendJoined', { messageType: 'friendJoined', senderUserData: this.currentPlayerId });
        OMT.notifications.scheduleGameTriggeredMessage(friendshipChestPayload.inviterFbId, 'FriendshipChestClaimed', 1, false);
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.InviteFromReferral);
      }
      return;
    }

    if (!payload.sendParams) return;
    const msgId = this.getMsgId(payload);

    if (!G.saveState.isMsgRead(msgId)
      && payload.sendParams.senderId !== this.currentPlayerId) {
      G.saveState.markMsgAsRead('friendshipChestMsg', msgId);
      if (G.firstTime) {
        OMT.social.sendFriendshipChestJoined({
          userId: friendshipChestPayload.inviterFbId,
          image: friendshipChestPayload.inviterAvatar,
        });
      }
    }
  }

  /**
   * Payload to notify you when the player you invited has joined the game.
   *
   * @param {*} payload The full payload
   */
  checkFriendshipChestJoinPayload(payload) {
    if (!payload.sendParams) return;
    const msgId = this.getMsgId(payload);

    if (!G.saveState.isMsgRead(msgId)
      && payload.sendParams.senderId !== this.currentPlayerId) {
      G.saveState.markMsgAsRead('friendshipChestMsg', msgId);

      if (OMT.feature.getFeatureFriendshipChest() && OMT.feature.getFriendshipChestAssetStatus().highPrio && G.saveState.friendshipChestDataManager.getUnclaimedCard()) {
        G.sb('pushWindow').dispatch(['friendshipChest']);
      }
    }
  }

  /**
   * Overrides your coins, levels and boosters based on a payload. Similar to restore progress payload.
   * @param {Object} payload The payload being passed
   * @param {Object} stateChange The part of the payload with the data we want to use
   */
  checkStateChangePayload(payload, stateChange) {
    const msgId = this.getMsgId(payload);
    if (!msgId || G.saveState.isMsgRead(msgId)) return;
    try {
      const { levelsCompleted, coins, boosters, unlimitedLives } = stateChange;
      if (levelsCompleted || coins || boosters || unlimitedLives) {
        G.saveState.applyStateChange(levelsCompleted, coins, boosters, unlimitedLives);
        G.saveState.markMsgAsRead('acceptedStateChange', msgId);
      }
    } catch (error) {
      // G.Utils.SentryLog.logError(`Failed to apply state from payload: ${error}`);
    }
  }

  /**
   * Payload for when a player clicks on a shared fortune cookie message.
   * @param {Object} payload The full payload
   * @param {Object} fortuneCookiePayload The data we want to use from the payload
   */
  checkFortuneCookiePayload(payload, fortuneCookiePayload) {
    if (!payload.sendParams) return;
    const msgId = this.getMsgId(payload);
    if (payload.sendParams.senderId === this.currentPlayerId) {
      G.sb('pushWindow').dispatch(['fortuneCookie', {
        playOpenAnim: false,
        showMessage: true,
        msgData: fortuneCookiePayload.msgData,
        categoryData: fortuneCookiePayload.categoryData,
      }]);
    } else if (!G.saveState.isMsgRead(msgId)) {
      if (G.firstTime) {
        // here we show it in the first level.
        G.showFortuneCookie = true;
      } else {
        G.sb('pushWindow').dispatch(['fortuneCookie', { playOpenAnim: true, hideNotNow: true }]);
      }
    }
    G.saveState.markMsgAsRead('fortuneCookieMsg', msgId);
  }

  /**
   * Pops up the event postcard when world happens
   * If the window does not push, the second half will start at flowMgr.checkEvents
   * @param {Object} payload
   */
  checkEventPostcardWorld(payload) {
    if (!payload.sendParams) return;
    if (OMT.feature.getEventPostcardFeature(true)) {
      if (G.saveState.isMsgRead(G.OMTsettings.postcardEvent.eventId)) {
        G.sb('pushWindow').dispatch(['eventPostcard', null, { startIndex: payload.postcardEvent.cardIndex }], false, G.WindowMgr.LayerNames.Base);
      }
    }
  }

  /**
   * Checks the payload for the treasure hunt
   * @param {Object} payload
   * @returns {null}
   */
  checkTreasureHunt() {
    G.saveState.treasureHuntManager.sessionData.onLoadPopupPending = true;
    G.sb('pushWindow').dispatch(['delayedWaiting', G.saveState.treasureHuntManager.isTimeReady, TREASURE_HUNT_TIME_READY, () => {
      if (OMT.feature.isTreasureHuntOn(true, true, false, true)) {
        if (OMT.feature.isTreasureHuntOn(true, true, false)) {
          TreasureHuntManager.openTreasureHuntPopup();
          return;
        }
      }
      if (!OMT.feature.isTokenEventOn(true, true)) {
        G.sb('pushWindow').dispatch('treasureHuntWaiting');
      }
    }]);
  }

  /**
   * Redeems real money wheel spin from payload
   * @param {Object} payload 
   */
  checkRealMoneyWheelPayload(payload) {
    // Shareable links = payload.realMoneyWheelSpin
    // Bot messages = payload.additions.realMoneyWheelSpin
    const payloadData = payload.additions ? payload.additions.realMoneyWheelSpin : payload.realMoneyWheelSpin;
    const { wheelMode } = payloadData;
    const msgId = this.getMsgId(payload);
    if (!msgId || G.saveState.isMsgRead(msgId)) return;

    // Validate wheelMode 
    let isValid = false;

    for (const mode in RMWHEEL_MODES) {
      isValid = isValid || wheelMode === RMWHEEL_MODES[mode];
    }
    
    if (!isValid) return;

    const freeSpin = payloadData.freeSpin || false;
    const predeterminedPrize = payloadData.predeterminedPrize || -1;
    const spinId = createRealMoneySpinId(msgId, RMWHEEL_EPS.EPPayload, freeSpin, freeSpin);
    G.saveState.addPendingRealMoneySpin(wheelMode, spinId);
    G.saveState.saveRealMoneySpinResult(spinId, predeterminedPrize);
    G.saveState.markMsgAsRead('realMoneyWheelSpin', msgId);
  }

  /**
   * Checks the payload at preloader.
   * ONLY CHECKS REWARDS
   * iapbonus and skipTutorial are checked at payload
   * @param {{senderParams:{senderId:number}, reward?:Array<{itemId:string, amount:number}>}} payload 
   */
   _checkCrossPromoPayloadAtWorld(payload) {
    if (OMT.envData.entryPoint === 'game_switch') { 
      if (payload.iapBonus) {
        const tutorialData = JSON.parse(JSON.stringify(G.json.tutorials.crossPromoIAPBonus));
        tutorialData.text = OMT.language.getText(tutorialData.text).replace('%AMOUNT%', G.saveState.iapMultiplier);
        const config = {
          msg: [{ ...tutorialData, localize: false }],
        };
        this.onPopUpRequested.dispatch(['crossPromoLanding', config]);
      } else if (payload.reward) { // TODO: Get the tracking info
        const tutorialData = G.json.tutorials.crossPromoRewards;
        const giftDataList = this.reformatGiftData(payload.reward);
        const config = {
          onComplete: () => {
            G.sb('pushWindow').dispatch(['gift', {
              showTitleBar: false,
              showGingy: false,
              showGingyGiftBar: false,
              autoOpen: true,
              autoOpenDelay: 1500,
              noSwapAnimation: true,
              gifts: giftDataList,
              onClaim: null,
              monetiseType: false,
              resourceTracking: {
                // itemType: 'Reward',
                // itemId: 'PayloadGift',
              },
              shareable: false,
              ddnaEventData: { transactionType: 'REWARD', tActionType: 'XPROMO_REWARD', tGameArea: 'MAP' },
            }], false, G.WindowMgr.LayerNames.Base);
          },
          msg: [tutorialData],
        };
        G.sb('pushWindow').dispatch(['crossPromoLanding', config]);
      }
    }
  }


  /**
   * Removing listeners from signals.
   */
  cleanup() {
    this.onPopUpRequested.removeAll();
  }
}

G.WorldMapAppearFlow.payloadChecker = new PayloadChecker();
