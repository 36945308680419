/* eslint-disable object-property-newline */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */

// lang map for Facebook localizations
const FB_LANG_MAP = {
  en: 'en_US', de: 'de_DE', es: 'es_ES', fr: 'fr_FR', it: 'it_IT', pt: 'pt_PT', 'pt-br': 'pt_BR',
  ru: 'ru_RU', tr: 'tr_TR', nl: 'nl_NL', pl: 'pl_PL', hi: 'hi_IN', vi: 'vi_VN', th: 'th_TH', ja: 'ja_JA',
};

const PRIMARY_LANG_RETRY_DELAY = 200;

/**
 * class for managing language and localizations
 */
export class OMT_Language {
  /**
   * constructor
   */
  constructor() {
    this._assetsFolder = window.assetsFolder ? window.assetsFolder : './assets';
    this._primaryLanguage = 'en';
    this._languageData = {};
    this._defineAvailableLanguages();
  }

  /**
   * set the primary language
   * @param {{lang:string, locale?:string}} localeEnv info about the player's location (language, country, locale)
   */
  setPrimaryLanguage(localeEnv) {
    if (G.OMTsettings.defaultGameLanguage) {
      this._primaryLanguage = G.OMTsettings.defaultGameLanguage;
      return;
    }
    if (localeEnv.lang === 'pt') {
      if (localeEnv.locale === 'pt_BR') {
        if (this._availableLanguages.includes('pt-br')) {
          this._primaryLanguage = 'pt-br';
        } else if (this._availableLanguages.includes('pt')) {
          this._primaryLanguage = 'pt';
        } else {
          this._primaryLanguage = 'en';
        }
      } else {
        this._primaryLanguage = this._availableLanguages.includes('pt') ? 'pt' : 'en';
      }
    } else {
      this._primaryLanguage = this._availableLanguages.includes(localeEnv.lang) ? localeEnv.lang.toLowerCase() : 'en';
    }
  }

  /**
   * get the primary language
   * @returns {string}
   */
  get lang() {
    return this._primaryLanguage;
  }

  /**
   * get a reference to the language data Object
   * @returns {Object}
   */
  get languageData() {
    return this._languageData;
  }

  /**
   * define array of available languages 'en', 'de', 'es', etc..
   */
  _defineAvailableLanguages() {
    const langFiles = G.ASSETS.langJSON.filter((id) => id.startsWith('language_'));
    this._availableLanguages = [];
    for (let i = 0; i < langFiles.length; i++) {
      this._availableLanguages.push(langFiles[i].split('_')[1].split('.')[0].toLowerCase());
    }
  }

  /**
   * load the users primary language
   * @returns {Promise}
   */
  async loadPrimaryLanguage(retries = 5) {
    const primaryLang = this._primaryLanguage;
    const primaryLangIndex = this._availableLanguages.indexOf(primaryLang);
    let langData;

    // attempt to load the primary langague, retry a few times before failing
    while (!langData && retries > 0) {
      langData = await OMT.jsonLoader.loadJSON(`${this._assetsFolder}/json/languages/language_${primaryLang}.json`, 'languages');
      if (langData) break;
      retries--;
      await new Promise((resolve) => setTimeout(resolve, PRIMARY_LANG_RETRY_DELAY));
    }
    if (!langData) throw new Error('Failed to load primary language data.');

    // set the primary language reference and create the secondary lang load list
    this._languageData[primaryLang] = langData;
    OMT.utils.stylizedLog(`OMT_Language: Primary language loaded (${Object.keys(this._languageData).toString()})`);

    const languagesToLoad = this._availableLanguages.slice();
    languagesToLoad.splice(primaryLangIndex, 1);

    // laod secondary languages, but we do not wait for this
    this.loadSecondaryLanguages(languagesToLoad);
  }

  /**
   * load other languages for localized messages
   * @param {Array} languagesToLoad list of languages to load
   * @returns {Promise}
   */
  async loadSecondaryLanguages(languagesToLoad) {
    let secondaryLang;
    let langData;
    while (languagesToLoad.length > 0) {
      secondaryLang = languagesToLoad.shift();
      langData = await OMT.jsonLoader.loadJSON(`${this._assetsFolder}/json/languages/language_${secondaryLang}.json`, 'languages');
      if (langData !== null) {
        this._languageData[secondaryLang] = langData;
      } else {
        console.warn(`COULD NOT LOAD SECONDARY LANG (${secondaryLang}).`);
      }
    }
    OMT.utils.stylizedLog(`OMT_Language: Languages loaded (${Object.keys(this._languageData).toString()})`);
  }

  doesTextExist(lang, text) {
    return this._languageData[lang] && this._languageData[lang][text];
  }

  /**
   * get a string from the language data Object
   * @param {string} text text / id to get
   * @returns {string} text from language table
   */
  getText(text, lang = null) {
    if (!lang) lang = this._primaryLanguage;

    // Try to get localized text
    if (this.doesTextExist(lang, text)) {
      return this._replaceCommonVariables(this._languageData[lang][text]);
    }

    // If the locale is PT-BR, try to fall back to PT-PT
    if (lang === 'pt-br' && this.doesTextExist('pt', text)) {
      return this._replaceCommonVariables(this._languageData.pt[text]);
    }

    // Then try to fall back to EN
    if (this.doesTextExist('en', text)) {
      return this._replaceCommonVariables(this._languageData.en[text]);
    }

    // Give up and fall back to "key***"
    return this._replaceCommonVariables(`${text}***`);
  }

  /**
   * creates a new Array with the language string data
   * @param {Array.<String>} textList text / id to get
   * @returns {Array.<String>} Array of text from language table
   */
  getTextList(textList, lang = null) {
    const output = [];
    for (let i = 0; i < textList.length; i++) {
      output.push(this.getText(textList[i], lang));
    }
    return output;
  }

  /**
   * replace common variables in a string
   * @param {string} inText
   */
  _replaceCommonVariables(inText) {
    let text = inText;
    text = text.replace('%GameName%', G.OMTsettings.global.gameName);
    return text;
  }

  /**
   * Primarily used to transform strings or numbers to proper notation based on
   * user locale
   * @param {string | number} text
   * @returns {string}
   */
  toLocaleNumber(text) {
    const num = parseInt(text, 10);
    let returnText = text;
    if (!Number.isNaN(num)) {
      let locale = FBInstant.getLocale();
      locale = locale.replace('_', '-');
      returnText = num.toLocaleString(locale);
    }
    return returnText.toString();
  }

  /**
   * generate a FB localization Object
   * @param {string} txt
   * @param {*} replace should be a string or an Array.<string>
   * @param {*} insert should be a string or an Array.<string>
   * @returns {Object} localization Object
   */
  generateFBLocalization(txt, replace = null, insert = null) {
    const returnValue = {};
    const availableLanguages = Object.keys(this._languageData);
    availableLanguages.forEach((lang) => {
      returnValue[FB_LANG_MAP[lang]] = this._languageData[lang][txt] || `${txt}***`;

      if (replace && insert) {
        // replace / insert is an array
        if (Array.isArray(replace) && Array.isArray(insert)) {
          for (let i = 0; i < replace.length; i++) {
            returnValue[FB_LANG_MAP[lang]] = returnValue[FB_LANG_MAP[lang]].replace(replace[i], insert[i]);
          }
        } else { // replace / insert are strings
          returnValue[FB_LANG_MAP[lang]] = returnValue[FB_LANG_MAP[lang]].replace(replace, insert);
        }
      }
    });
    return returnValue;
  }
}
