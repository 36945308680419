import ShareMsgBase from './A_G.ShareMsgBase';

class MsgBeaten extends ShareMsgBase {
  /**
   * The message that is sent when you beat a friend's score
   * @param {Object} user1
   * @param {Object} user2
   * @param {*} onAllImagesReadyCallback
   */
  constructor(user1, user2, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    if (!user1.avatar) user1.avatar = user1.image;
    if (!user2.avatar) user2.avatar = user2.image;

    this.avatar1 = this._addExtImage(447, 105, user1.avatar, 0, 132);
    this.avatar2 = this._addExtImage(66, 109, user2.avatar, 0, 132);
    this.bg = this._addExtImage(0, 0, 'img/Msg_Friend_Beaten.png', 0);
  }
}

// create global references
G.MsgBeaten = MsgBeaten;
