/* eslint-disable no-shadow */
import OOMFailView from './OOMFailView';
import { BUTTONSTATE } from '../../../../OMT_UI/OMT_UI_SquareButton';
import { ProgressBar } from '../../../ProgressBar';
import { TextTimer } from '../../../TextTimer';

/**
 * Out Of Moves fail view with spin limit additions
 */
export default class OOMFailViewWithSpinLimits extends OOMFailView {
  /**
   * constructor
   * @param {Array} additionalBoosterData
   */
  constructor(additionalBoosterData) {
    super(additionalBoosterData);
  }

  /**
   * main intialization method
   */
  _init() {
    this._wheelLimitConfig = G.json.settings.lossAversionWheelLimits;
    super._init();

    this._initDiscountLabel();
    this._initSpinsLeftBar();
    this._initCooldownTimer();

    this._addGlobalListeners();
  }

  /**
   * add global listeners to the signal box
   */
  _addGlobalListeners() {
    this._addSignalBinding(G.sb('onLossAversionWheelSpin').add(this._onLossAversionWheelSpin, this));
    this._addSignalBinding(G.sb('onLossAversionSpinRefill').add(this._onLossAversionSpinRefill, this));
  }

  /**
   * init the spin button and set its initial state
   */
  _initSpinButton() {
    super._initSpinButton();
    // set intial spin button state
    const spinBtn = this._spinBtn;
    const { spinsLeft } = this._getSpinStats();
    spinBtn.currentState = spinsLeft > 0 ? BUTTONSTATE.ENABLED : BUTTONSTATE.DISABLED;
  }

  /**
   * init discount label
   */
  _initDiscountLabel() {
    this._discountLabel = game.make.group();
    this.add(this._discountLabel);

    G.makeImage(150, 65, 'discount_tag', 0.5, this._discountLabel);

    const { failFlowConfig } = G.json.settings;
    const discountAmount = failFlowConfig ? failFlowConfig.extraMovesNoSpinsDiscount : 0;
    const discountText = new G.Text(150, 67, `-${discountAmount}%`, {
      style: 'font-white',
      fontSize: '32px',
    }, 0.5, 400);
    discountText.angle = 8;
    this._discountLabel.add(discountText);

    if (this._canDiscountLabelBeSeen()) {
      this._showDiscountLabel(this._discountLabel);
    } else {
      this._discountLabel.visible = false;
    }
  }

  /**
   * init the UI bar showing remaining spins
   */
  _initSpinsLeftBar() {
    const { spinsLeft } = this._getSpinStats();
    this._spinsLeftBar = new ProgressBar(game, {
      x: 0,
      y: 290,
      width: 300,
      height: 36,
      value: spinsLeft,
      valueMax: this._wheelLimitConfig.numSpins,
    }, this);
  }

  /**
   * init spin cooldown TextTimer instance
   */
  _initCooldownTimer() {
    const { spinsUsed } = this._getSpinStats();
    this._spinCooldownTimer = new TextTimer({
      x: 0,
      y: 325,
      style: 'lossAversionTimer',
      anchor: 0.5,
      maxWidth: 480,
      timerFormat: 'hms',
      wrapperText: OMT.language.getText('%TIME% until full'),
    });
    this._setCooldownTimer(spinsUsed);
    this._spinCooldownTimer.active = true;
    this.updateCooldownTimerVisibility();
    this.add(this._spinCooldownTimer);
  }

  /**
   * update the cooldownTimer visibility based on spins used
   */
  updateCooldownTimerVisibility() {
    const { spinsUsed } = this._getSpinStats();
    this._spinCooldownTimer.visible = spinsUsed > 0;
  }

  /**
   * Gets number of wheel spins left and used
   * @returns {Object} { spinsLeft, spinsUsed }
   */
  _getSpinStats() {
    const spinsLeft = Math.max(this._wheelLimitConfig.numSpins - G.saveState.getLossAversionWheelSpinCount(), 0);
    const spinsUsed = this._wheelLimitConfig.numSpins - spinsLeft;
    return { spinsLeft, spinsUsed };
  }

  /**
   * Lots of checks if discount can be seen
   */
  _canDiscountLabelBeSeen() {
    return G.IAP
    && this._getSpinStats().spinsLeft === 0
    && G.lvl.numOfExtraMovesBought === 0
    && G.lvl.getExtraMovesDiscount() > 0
    && G.saveState.canDiscountBeenSeen();
  }

  /**
   * Shows the discount label, sets the flags to true
   * @param {Phaser.Group} label
   */
  _showDiscountLabel(label) {
    label.visible = true;
    G.saveState.initDiscountData();
  }

  /**
   * Set cooldown timer based on number of spins left
   * @param {number} spinsUsed
   */
  _setCooldownTimer(spinsUsed) {
    const cooldownLeft = Math.floor(G.saveState.getUserCooldownRemaining('lossAversionWheelSpins', '') / 1000);
    // Fixes incorrect time calculations when switching between IAP and non-IAP environments
    const spinUsedCapped = Math.min(spinsUsed, G.json.settings.lossAversionWheelLimits.numSpins);
    const pendingCooldown = Math.max((spinUsedCapped - 1) * this._wheelLimitConfig.refillTime * 60, 0);
    this._spinCooldownTimer.setSecLeft(cooldownLeft + pendingCooldown);
  }

  /**
   * called on 'onLossAversionWheelSpin' global signal
   * @param {boolean} isFull
   */
  _onLossAversionWheelSpin() {
    const spinBtn = this._spinBtn;
    const { spinsLeft, spinsUsed } = this._getSpinStats();

    if (spinsLeft < this._wheelLimitConfig.numSpins) {
      this._setCooldownTimer(spinsUsed);
      // Handle out of spins
      if (spinsLeft === 0) {
        spinBtn.currentState = BUTTONSTATE.DISABLED;

        if (G.lvl.numOfExtraMovesBought === 0) {
          const { failFlowConfig } = G.json.settings;
          this._price = G.lvl.getPriceOfExtraMoves();
          if (!failFlowConfig) {
            this._price = Math.round(this._price * 1.1);
          }

          this.buyButton.updatePrice(this._price);
          if (this._canDiscountLabelBeSeen()) {
            this._showDiscountLabel(this._discountLabel);
          }
        } else {
          this._discountLabel.visible = false;
        }
      }
    } else {
      this._discountLabel.visible = false;
    }
    this._spinsLeftBar.updateValue(spinsLeft);
  }

  /**
   * called on 'onLossAversionSpinRefill' global signal
   * @param {boolean} isFull
   */
  _onLossAversionSpinRefill(isFull) {
    if (!this.game) { return; }
    const { spinsLeft, spinsUsed } = this._getSpinStats();
    const spinBtn = this._spinBtn;
    if (spinsLeft > 0) {
      spinBtn.currentState = BUTTONSTATE.ENABLED;
    }
    this._spinsLeftBar.updateValue(spinsLeft);

    if (isFull) {
      this._spinCooldownTimer.visible = false;
    } else {
      this._setCooldownTimer(spinsUsed);
      this._spinCooldownTimer.visible = true;
    }
  }
}
