if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getTHFailGingyAnimationShow = function getTHFailGingyAnimationShow() {

  return { animate: animate };

  function animate(game, target) {
    var speakBubble = G.Utils.find1stObjectWithName(target, "speakBubble");
    var gingyCharacter = G.Utils.find1stObjectWithName(target, "gingyCharacter");

    if (gingyCharacter) {
      game.add.tween(gingyCharacter)
        .to({y: 10}, 300, Phaser.Easing.Cubic.Out, true);
    } 

    if (speakBubble) {
      speakBubble.scale.setTo(0);
      game.add.tween(speakBubble.scale)
        .to({x: 1, y: 1}, 400, Phaser.Easing.Elastic.Out, true, 200);
    }
  }
}
