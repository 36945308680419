import { TokenEvent_LeaderboardHeader } from '../../Windows/tokenEvent/Leaderboard/Components/TokenEvent_LeaderboardHeader';
import { Window_SpecialEventLeaderboard } from '../../../OMT_UI/SpecialEvents/Leaderboard/Window_SpecialEventLeaderboard';
import { SPECIALEVENT_LEADERBOARD_VIEWMODE } from '../../../OMT_UI/SpecialEvents/Leaderboard/SpecialEvent_LeaderboardUserListPanel';
import { DATA_KEYS } from '../../../Services/SaveState/SaveStateKeyMappings';
import TreasureHuntLeaderboard_Util from './TreasureHuntLeaderboard_Util';
import { SagaMap_Tutorial } from '../../GingyTutorial/SagaMap_Tutorial';
import TreasureHuntManager from '../../../Services/OMT/dataTracking/treasureHuntManager/treasureHuntManager';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

class Window_TreasureHuntLeaderboard extends Window_SpecialEventLeaderboard {
  static getMergedConfig(viewMode) {
    return {
      ...G.OMTsettings.treasureHuntSuper.leaderboardConfig,
      viewMode: viewMode || SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL,
    };
  }

  /**
   * The window that shows up for the leaderboard on the treasure hunt event!
   * @param {Object} parent
   * @param {Object} returnArgs An object package that will re-open the a window that was given to it, as well as its args and layer
   * @param {string} returnArgs.windowName The window to re-open to
   * @param {Array<any>} returnArgs.args The args to go to that window
   * @param {string} returnArgs.layerName The layer for that window
   * @param {Object} config Supposedly G.OMTsettings.treasureHuntSuper.leaderboardConfig, but the need to adjust view modes is required
   */
  constructor(parent, returnArgs, viewMode) {
    super(parent, Window_TreasureHuntLeaderboard.getMergedConfig(viewMode));
    this._returnArgs = returnArgs;

    const targetText = this._config.viewMode !== SPECIALEVENT_LEADERBOARD_VIEWMODE.ENDING ? OMT.language.getText('Collect treasure tokens in treasure hunt levels and raise in rank') : '';
    this._initCollectMessageField(targetText, this._config.collectMsg.fieldConfig);
  }

  /**
   * async initialization
   */
  async _init(config) {
    this._createConfig(config);
    this._initLoadingIndicator();
    this._loadingIndicator.show();

    // load leadboards and determine default
    try {
      const previousLeaderboard = G.saveState.treasureHuntManager.lastExpiredLeaderboard;
      const leaderboardId = previousLeaderboard ? previousLeaderboard.id : null;
      this._globalEntries = await TreasureHuntLeaderboard_Util.getLeaderboardEntries(DATA_KEYS.TREASURE_HUNT, false, leaderboardId, 50, true);

      if (this._globalEntries.length === 0) {
        throw new Error('Something is wrong, we should not continue.');
      }

      if (this._config.viewMode === SPECIALEVENT_LEADERBOARD_VIEWMODE.ENDING) {
        this._gingyCharacter = previousLeaderboard.cw;
        this._initGingyTutorial();
      }
      this._initPlayButton();
      this._initLeaderboardPanel(this._config.panel, this._globalEntries, this._config.viewMode);
    } catch (e) {
      console.error(e);
      this.removeChildren();
      if (this._gingyTutorial) {
        this._removeGingy();
      }
      this._errorTxt = new G.Text(0, -50, OMT.language.getText('Something went wrong! Please, try again.'), {
        style: 'font-white-blue-out',
        fontSize: '38px',
      }, 0.5, 420, 300, true, 'center');
      this.addChild(this._errorTxt);
      this._leaveWindow = true;
    }

    this._initHeaderGroup();
    if (this._config.viewMode !== SPECIALEVENT_LEADERBOARD_VIEWMODE.ENDING || this._leaveWindow) {
      this._initCloseButton();
    }
    this.hideIndicator();

    this._openWindow();
  }

  /**
   * initialize loading indicator component
   */
  _initLoadingIndicator() {
    super._initLoadingIndicator();
    const isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    const offset = isLandscape ? 1.25 : 0;
    this._loadingIndicator.x = (this._loadingIndicator.width * offset) + (game.width * this._gameScale) / 2;
  }


  /**
   * Removes the loading indicator properly
   */
  async hideIndicator() {
    await this._loadingIndicator.hide();
    this._loadingIndicator.destroy();
  }

  /**
   * Creates the gingy tutorial
   */
  _initGingyTutorial() {
    this._gingyTutorial = new SagaMap_Tutorial({ game, scale: 1, character: `treasureHunt${TreasureHuntManager.getMascotName(this._gingyCharacter)}` });
    this._gingyTutorial.toggleVisibility(false);

    if (this._isLandscape) {
      this._gingyTutorial.rh_levelTutorialGingy.scale.setTo(this._gameScale);
    }

    this._hitBoard = G.makeImage(0, 0, null, 0, null);
    this._hitBoard.inputEnabled = true;
    this._hitBoard.input.useHandCursor = true;
    this._hitBoard.hitArea = new Phaser.Rectangle(-2000, -2000, 4000, 4000);
    this._hitBoard.events.onInputDown.add((this._removeGingy.bind(this)));
  }

  /**
   * called when leaderboard entries are loaded / ready to display
   * @param {Object} panelConfig
   * @param {Array.<Object>} leaderboardEntries
   * @param {SPECIALEVENT_LEADERBOARD_VIEWMODE} viewmode
   */
  async _initLeaderboardPanel(panelConfig, leaderboardEntries, viewmode) {
    super._initLeaderboardPanel(panelConfig, leaderboardEntries, viewmode);

    if (viewmode !== SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL) {
      // Show gingy
      if (this._gingyTutorial) {
        await this._gingyTutorial.displayMsgAsync(G.json.tutorials.treasureHunt.placement);
      }
      this._animateLeaderboard();
      // Continues at this._onScoresAnimationComplete
    }
  }

  /**
   * create the header group containing the title
   */
  _initHeaderGroup() {
    const { header: headerConfig } = this._config;
    if (headerConfig) {
      const headerGroup = new TokenEvent_LeaderboardHeader(headerConfig);
      this.addChild(headerGroup);
      this._headerGroup = headerGroup;
    }
  }

  /**
   * Checks if there was any args that was given. If there it, it will re-open the window (assuming nothing is in queue)
   * then continues to close itself
   */
  closeWindow() {
    const layerName = this._returnArgs && this._returnArgs.layerName ? this._returnArgs.layerName : undefined;
    if (this._config.viewMode === SPECIALEVENT_LEADERBOARD_VIEWMODE.NORMAL) {
      if (this._returnArgs && this._returnArgs.windowName) {
        G.sb('pushWindow').dispatch([this._returnArgs.windowName].concat(this._returnArgs.args), false, layerName);
      }
    } else if (this._globalEntries.length > 0 && this._config.viewMode === SPECIALEVENT_LEADERBOARD_VIEWMODE.ENDING) {
      // Save prize now so we don't lose it
      let showPodium = false;
      const rankFirst = this._leaderboardPanel.leaderboardEntries[0];
      if (rankFirst && G.saveState.treasureHuntManager.eligibleForAllPrizes(rankFirst.score)) {
        showPodium = true;
      }

      const { rank, score } = this._leaderboardPanel.userData;
      const finishedCollecting = G.saveState.treasureHuntManager.eligibleForAllPrizes(score);
      if (finishedCollecting && rank <= 3) {
        G.saveState.treasureHuntManager.putBadgeIntoPending(this._gingyCharacter, Math.max(0, rank - 1), G.saveState.treasureHuntManager.lastExpiredLeaderboard.tid, true);
        showPodium = true;
      }
      if (showPodium) {
        G.sb('pushWindow').dispatch(['treasureHuntPrizeClaim', true], false, layerName);
      } else {
        G.saveState.treasureHuntManager.leaderboardPlacementIsSeen();
      }
    } else if (this._config.viewMode === SPECIALEVENT_LEADERBOARD_VIEWMODE.PLACEMENT) {
      TreasureHuntManager.openTreasureHuntPopup();
    }
    if (this._gingyTutorial) {
      this._gingyTutorial.close();
    }
    super.closeWindow();
  }

  /**
   * on score lines animation complete fade in other elements
   * Unless its placement viewing, then we're doing something else!
   */
  async _onScoresAnimationComplete() {
    if (!this.game) return;
    if (this._config.viewMode === SPECIALEVENT_LEADERBOARD_VIEWMODE.ENDING && this._gingyTutorial) {
      this.addChild(this._hitBoard);
      const { rank, score } = this._leaderboardPanel.userData;
      if (rank > -1) {
        const finishedCollecting = G.saveState.treasureHuntManager.eligibleForAllPrizes(score);
        if (finishedCollecting) {
          if (rank <= 3) {
            const topThreeDataTutorialData = { ...G.json.tutorials.treasureHunt.topThree };
            let placementText;
            switch (rank) {
              case 1: placementText = 'first'; break;
              case 2: placementText = 'second'; break;
              case 3: placementText = 'third'; break;
              default: break;
            }
            topThreeDataTutorialData.text = topThreeDataTutorialData.text.replace('%place%', placementText);
            await this._gingyTutorial.displayMsgAsync(topThreeDataTutorialData);
          } else {
            G.sfx.try_again.play();
            await this._gingyTutorial.displayMsgAsync(G.json.tutorials.treasureHunt.tooBad);
          }
        } else {
          await this._gingyTutorial.displayMsgAsync(G.json.tutorials.treasureHunt.notFinished);
        }
      }
      game.time.events.add(3000, this._removeGingy.bind(this));
    }
    super._onScoresAnimationComplete();
  }

  /**
   * Removes gingy tutorial, if there is one
   */
  _removeGingy() {
    if (this._gingyTutorial) {
      this._gingyTutorial.close();
      this._gingyTutorial = null;

      this.removeChild(this._hitBoard);
      this._hitBoard.destroy();
      this._hitBoard = null;
    }
  }

  /**
   * resize window, but a little more dynamic
   */
  resize() {
    super.resize();
    const {
      _playBtn: playBtn,
      _collectField: collectField,
    } = this;

    if (playBtn) {
      if (this._isLandscape) {
        const offset = game.state.current === 'Game' ? G.WindowMgr.Constants.WorldVerticalOffset - 10 : -G.WindowMgr.Constants.WorldVerticalOffset;
        playBtn.y = (offset + (game.height + playBtn.height)) * this._gameScale;
      } else {
        const offset = game.state.current === 'Game' ? G.WindowMgr.Constants.WorldVerticalOffset : 0;
        playBtn.y = offset + game.height / 2;
      }

      if (collectField) {
        collectField.y = playBtn.y - (playBtn.height + collectField.height) / 2;
      }
    }
  }
}

if (!window.Windows) window.Windows = {};
Windows.treasureHuntLeaderboard = Window_TreasureHuntLeaderboard;
