import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import UI_ExtraMovesBuyButton from '../vertical/UI_ExtraMovesBuyButton';

export default class UI_TopBarBase extends Phaser.Group {
  /**
   * This is the top bar of the game state. Has all the piececs
   * @param {LvlObject} gameplayController
   * @param {Object} levelData
   * @param {string} gameMode
   */
  constructor(gameplayController, levelData, gameMode) {
    super(game);

    this._gameplayController = gameplayController;

    const { isTournamentLevel } = LvlDataManager.getInstance();

    // create the layout for the current goal
    this._createGoalLayout(levelData, gameMode);

    if (UI_ExtraMovesBuyButton.willItShow() && !isTournamentLevel) { // Check if it'll show before creating
      this._extraMovesBtn = new UI_ExtraMovesBuyButton(this._gameplayController);
      this._extraMovesBtn.x = 250;
      this._extraMovesBtn.targetY = 100;
    }

    this._signalBindings = [];
    this._signalBindings.push(G.sb('changeMoveNumber').add(() => {
      this._movesAmountTxt.setText(this._gameplayController.moves.toString());
    }));

    this._signalBindings.push(G.sb('onScreenResize').add(this._onScreenResize.bind(this)));

    this._onScreenResize();
  }

  /**
   * creat the goal panel
   * @param {Object} levelData
   * @param {string} gameMode
   */
  _createGoalLayout(levelData, gameMode) { // eslint-disable-line no-unused-vars
    // Override
  }

  /**
   * init the bg graphics
   * @param {string} assetFrame
   */
  _createGameBG(assetFrame) { // eslint-disable-line no-unused-vars
    // Override
  }

  /**
   * create tournament banner / streamer image
   */
  _createTournamentStreamer() {
    // Override
  }

  /**
   * create the moves display group
   * @param {LevelType} gameMode
   */
  _createMovesGroup(gameMode) { // eslint-disable-line no-unused-vars
    // Override
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._signalBindings) {
      this._signalBindings.forEach((binding) => {
        if (binding.detach) {
          binding.detach();
        }
      });
      this._signalBindings = null;
    }
    if (this._extraMovesBtn) {
      this._extraMovesBtn.destroy();
    }
    this.progressBar.destroy();
    if (this._hand) {
      this._hand.destroy();
    }
    super.destroy();
  }

  /**
   * Has a resize function but it doesn't do anything...
   */
  _onScreenResize() {
    // Override
  }

  /**
   * Makes the hand appear and points to a specific spot
   * @param {string} key
   */
  showSuggestion(key) {
    if (!this._hand) {
      this._hand = new Phaser.Group(game);
      const handImg = G.makeImage(0, 0, 'tut_hand', [24 / 90, 11 / 98], this._hand);
      this._hand.handTween = game.add.tween(handImg)
        .to({ y: 15 }, 1000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    }
    game.world.addChild(this._hand);
    this._hand.visible = true;

    let targetPosition;
    switch (key) {
      case 'points':
        targetPosition = {
          obj: this.pointsCounter,
          offset: { x: 0, y: 0 },
        };
        break;
      case 'goal':
        targetPosition = {
          obj: this.goalPanel,
          offset: { x: 0, y: 0 },
        };
        break;
      case 'moves':
        targetPosition = {
          obj: this._movesAmountTxt,
          offset: { x: this._movesTxt.width / 2, y: this._movesAmountTxt.height },
        };
        break;
      default: break;
    }

    if (targetPosition) {
      const converted = game.world.toLocal({ x: targetPosition.obj.x, y: targetPosition.obj.y }, targetPosition.obj.parent);
      this._hand.x = converted.x + (targetPosition.obj.width * 0.3) + targetPosition.offset.x;
      this._hand.y = converted.y + (targetPosition.obj.height * 0.1) + targetPosition.offset.y;
    }
  }

  /**
   * Hides the hand
   */
  hideSuggestion() {
    if (this._hand) {
      this._hand.visible = false;
    }
  }
}
