/* eslint-disable no-unused-vars */
import { TOKEN_TYPES, EDITOR_SYMBOLS, WALL_DIRECTIONS } from '../BoardConstants';
import { CandyType_Basic } from './Types/CandyType_Basic';
import { CandyType_Cake } from './Types/CandyType_Cake';
import { CandyType_Chest } from './Types/CandyType_ChestType';
import { CandyType_Infection } from './Types/CandyType_Infection';
import { CandyType_FortuneCookie } from './Types/CandyType_FortuneCookie';
import { CandyStatus_GemBox } from './Status/CandyStatus_GemBox';
import { CandyStatus_Rope } from './Status/CandyStatus_Rope';
import { CandyStatus_Chains } from './Status/CandyStatus_Chains';
import { CandyStatus_Infected } from './Status/CandyStatus_Infected';
import { SpecialType } from './SpecialTypes/SpecialType';
import { CandyType_EventToken } from './Types/CandyType_EventToken';
import CandyDataManager from './CandyDataManager';
import LvlDataManager from '../../Managers/LvlDataManager';
import { CandyType_TreasureHunt } from './Types/CandyType_TreasureHunt';

/**
 * class for Board Candy instances. Candies contain a baseType, specialType, and candyStatus elements.
 */
export class Candy extends Phaser.Image {
  /**
   * constructor
   * @param {Board} board
   * @param {GridArray} grid
   * @param {boolean} postRandomPhase is post randomization phase
   */
  constructor(board, grid, postRandomPhase) {
    super(game, 0, 0);

    if (board && grid) {
      this._grid = grid;
      this._board = board;
      this._candiesLayer = board.candiesLayer;
    }

    this._signalBindings = [];

    this._postRandomPhase = postRandomPhase || false;

    this._baseTypeData = null;
    this._specialTypeData = null;

    this._baseType = null;
    this._specialType = null;
    this._candyStatus = null;

    this.anchor.setTo(0.5, 0.5);

    this._animationData = { active: false };

    // parameters for falling motion
    this._fallData = {
      alpha0: this._board ? this._board.cellYToPxIn(-1) : 0,
      alpha1: this._board ? this._board.cellYToPxIn(0) : 0,
      alphaDistance: this._board ? Math.abs(this._board.cellYToPxIn(-1) - this._board.cellYToPxIn(0)) : 0,
      active: false,
      delay: 0,
      targetY: 0,
      targetX: 0,
      velY: 0,
      grav: 2.5,
    };

    this.kill(); // set inactive, revived with init()
  }

  /**
   * initialize instance 1st time use or resuse
   * @param {number} cellX
   * @param {number} cellY
   * @param {string} type
   */
  init(cellX, cellY, type) {
    this._resetProperties();
    this.cellX = cellX;
    this.cellY = cellY;
    if (this._board) {
      this.x = this._board.cellXToPxIn(cellX);
      this.y = this._board.cellYToPxIn(cellY);
    }
    this.import(type, true);
    this.revive();
  }

  /**
   * reset properties for reuse
   */
  _resetProperties() {
    this.loadTexture(null);
    this.scale.setTo(1);
    this.alpha = 1;
    this.angle = 0;

    this._baseType = null;
    this._specialType = false;
    this._animationData.active = false;
    this._fallData.active = false;
    this._onMatchFx = [];

    // TODO: maybe refactor these to getter / setters
    this.activatedByMove = false;
    this.exe = [];
    this.matchable = true;
    this.goalCandy = false;

    this._removeStatus();
    this._removeListeners();
  }

  /**
   * mark candy transformed from random candy
   */
  markPostRandomPhase() {
    this._postRandomPhase = true;
    if (this.hasStatus()) this._candyStatus.markPostRandomPhase();
  }

  /**
   * set candy BaseType
   * @param {Object} data
   * @param {any} args
   */
  setBaseType(data, args) {
    this._baseTypeData = data;
    const { gameHooks } = this._board;

    if (data.layeredCake) this._baseType = new CandyType_Cake(gameHooks);
    else if (data.infectionSource) this._baseType = new CandyType_Infection(gameHooks);
    else if (data.chest) this._baseType = new CandyType_Chest(gameHooks);
    else if (data.fortuneCookie) this._baseType = new CandyType_FortuneCookie(gameHooks);
    else if (data.eventToken) this._baseType = new CandyType_EventToken(gameHooks);
    else if (data.treasureHunt) this._baseType = new CandyType_TreasureHunt(gameHooks);
    else this._baseType = new CandyType_Basic(gameHooks);

    this._signalBindings.push(
      this._baseType.signals.removeCandy.add(this.remove, this),
      this._baseType.signals.changeCandyTexture.add((sprite) => {
        this.changeTexture(sprite);
      }, this),
    );

    this._baseType.init(this, data, args);
  }

  /**
   * get editor symbol for BaseType
   * @returns {string}
   */
  getBaseTypeSymbol() {
    return this._baseType.getEditorSymbol();
  }

  /**
   * set candy SpecialType. Must first have a BaseType.
   * @param {Object} data
   * @param {boolean} skipAnim (optional) skip animation
   * @param {boolean} imported (optional) created by import() function
   */
  setSpecialType(data, skipAnim = false, imported = false) {
    if (!this._baseType) {
      console.warn('no base type data'); return;
    }
    if (!this._baseType.canHaveSpecialType()) {
      console.warn(`${this._baseType.export()} cannot have special type`); return;
    }

    if (!skipAnim) G.sb('fx').dispatch('changeCircle', this);

    this._specialType = new SpecialType();
    this._signalBindings.push(
      this._specialType.signals.removeCandy.add(this.remove, this),
      this._specialType.signals.changeCandyTexture.add((sprite) => {
        this.changeTexture(sprite);
      }, this),
    );
    this._specialType.init(this, data, imported);
  }

  /**
   * get string representing the type. Used by the editor.
   * @returns {string}
   */
  getSpecialType() {
    return this.specialType && this.specialType.getSpecialType();
  }

  /**
   * does candy have special type
   * @returns {boolean}
   */
  hasSpecialType() {
    return Boolean(this._specialType);
  }

  /**
   * get SpecialType name
   * @returns {string}
   */
  getSpecialTypeName() {
    return this._specialType && this._specialType.getSpecialType();
  }

  /**
   * true if special is activated by a move
   * @returns {boolean}
   */
  isSpecialActivatedByMove() {
    return this._specialType && this._specialType.isActivatedByMove();
  }

  /**
   * get special execution data
   * @returns {Array}
   */
  getSpecialExe() {
    return this._specialType && this._specialType.getExe();
  }

  /**
   * clear SpecialType. Used by the editor.
   */
  clearSpecialType() {
    this._specialType = null;
    if (this._baseTypeData) this.setBaseType(this._baseTypeData);
  }

  /**
   * set CandyStatus instnace for candies that have special statuses.
   * @param {CandyStatus} status
   * @param {any} argsChunk
   */
  setStatus(status, argsChunk) {
    const { gameHooks } = this._board;

    if (this._candyStatus) this._removeStatus();
    if (status.wrapper) this._candyStatus = new CandyStatus_Rope(gameHooks);
    if (status.chainMulti) this._candyStatus = new CandyStatus_Chains(gameHooks);
    if (status.box) this._candyStatus = new CandyStatus_GemBox(gameHooks);
    if (status.infected) this._candyStatus = new CandyStatus_Infected(gameHooks);

    this._signalBindings.push(
      this._candyStatus.signals.checkIfIsMatching.add(() => {
        if (this._board) this._board.pushToFallCheckList(this);
      }, this),
      this._candyStatus.signals.onRemove.add(() => {
        if (this._board) this._board.pushToFallCheckList(this);
        this._candyStatus = null;
      }, this),
    );

    this._candyStatus.init(this, status, argsChunk);
  }

  /**
   * remove CandyStatus instance.
   */
  _removeStatus() {
    if (!this._candyStatus) return;
    this._candyStatus.remove();
    this._candyStatus = null;
  }

  /**
   * check if the candy has a CandyStatus instance.
   * @returns {boolean}
   */
  hasStatus() {
    return Boolean(this._candyStatus);
  }

  /**
   * check if candy has a specific tag
   * @param {string} tag
   * @returns {boolean}
   */
  hasTag(tag) {
    if (this._baseType.hasTag(tag)) return true;
    if (this._candyStatus && this._candyStatus.hasTag(tag)) return true;
    if (this._specialType && this._specialType.hasTag(tag)) return true;
    return false;
  }

  /**
   * init data used for fall / collapse animations.
   */
  _initFallData() {
    this._fallData = {
      alpha0: this._board ? this._board.cellYToPxIn(-1) : 0,
      alpha1: this._board ? this._board.cellYToPxIn(0) : 0,
      alphaDistance: this._board ? Math.abs(this._board.cellYToPxIn(-1) - this._board.cellYToPxIn(0)) : 0,
      active: false,
      delay: 0,
      targetY: 0,
      targetX: 0,
      velY: 0,
      grav: 2.5,
    };
  }

  /**
   * fall to specified cell
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} delay
   */
  fallTo(cellX, cellY, delay) {
    this.setCell(cellX, cellY);
    if (!this._fallData.active) G.sb('onCandyFallStart').dispatch(this);

    this._fallData.active = true;
    this._fallData.delay = delay || 0;
    this._fallData.velY = G.IMMEDIATE ? 1000 : 0;
    this._fallData.targetY = this._board.cellYToPxIn(cellY);
    this._fallData.targetX = this._board.cellXToPxIn(cellX);
  }

  /**
   * true if faling is blocked by the basetype.
   * @param {boolean}
   * @returns {boolean}
   */
  isFallingBlocked(checkWalls = true) {
    const { walls } = this._board;
    if (checkWalls) {
      if (walls.doesCellPosHaveWallAtDir(this.cellX, this.cellY, WALL_DIRECTIONS.SOUTH) || walls.doesCellPosHaveWallAtDir(this.cellX, this.cellY + 1, WALL_DIRECTIONS.NORTH)) {
        return true;
      }
    }
    return this._baseType.fallingBlocked || false;
  }

  /**
   * stores last candy this candy was moved with.
   * @param {Candy} candy
   */
  movedWith(candy) {
    this._lastMovedWith = candy;
  }

  /**
   * make a successful move
   * @param {Candy} candy
   */
  markSuccessfulMove(candy) {
    this._baseType.onMove();
  }

  /**
   * change into another type of candy.
   * @param {string} type
   * @param {boolean} skipAnim (optional) skip animation
   * @param {boolean} countAsImported (optional) count as imported at start of level, mostly for start-boosters
   */
  changeInto(type, skipAnim = false, countAsImported = false) {
    this.bringToTop();
    if (CandyDataManager.isTypeSpecial(type)) { // transform into a special
      const data = CandyDataManager.getSpecialData(type);
      this.setSpecialType(data, skipAnim, countAsImported);
    } else { // update base CandyType
      const baseData = CandyDataManager.getBaseData(type);
      if (baseData) {
        this.setBaseType(baseData);
      } else {
        throw new Error(`Can't find base type: ${type}`);
      }
    }
  }

  /**
   * set / update the containing grid cell.
   * @param {number} cellX
   * @param {number} cellY
   */
  setCell(cellX, cellY) {
    if (this._grid.get(this.cellX, this.cellY) === this) {
      this._grid.set(this.cellX, this.cellY, null);
    }
    this.cellX = cellX; this.cellY = cellY;
    this._grid.set(cellX, cellY, this);
  }

  /**
   * remove from candiesLayer layer.
   */
  remove() {
    this._candiesLayer.removeCandy(this);
  }

  /**
   * clear candy from containing grid.
   */
  detachFromGrid() {
    this._candiesLayer.grid.set(this.cellX, this.cellY, null);
  }

  /**
   * when hit by another adjacent match.
   */
  hit() {
    if (this._candyStatus && !this._candyStatus.onHit()) return false;
    if (this._baseType && !this._baseType.onHit()) return false;
    return true;
  }

  /**
   * on booster used on cell
   * @returns {boolean}
   */
  onBooster() {
    this.hit();
    // hack for special candies being triggered immediately
    // which effectively will have no effect
    if (this.hasSpecialType()) {
      this._board.checkSpecialMatchList.push(this);
    } else if (this.isMatchable()) {
      this.match();
      const { gameHooks, lvlDataManager } = this._board;
      gameHooks.playSound('boom');
      lvlDataManager.processMatch(this._board, 1, this.cellX, this.cellY);
    } else if (this._baseType && this._baseType.onBooster) {
      this._baseType.onBooster();
    }
    return true;
  }

  /**
   * on matching with another candy. sets candy swap animation.
   * @param {number} delay
   * @param {number} cellX
   * @param {number} cellY
   */
  match(delay, cellX, cellY) {
    if (!this.isMatchable() || this._animationData.active) return;
    if (this._candyStatus && !this._candyStatus.onMatch()) return;
    if (this._specialType && !this._specialType.onMatch()) return;
    if (!this._baseType.onMatch()) return;

    this.detachFromGrid();
    G.sb('onCandyMatch').dispatch(this);

    if (this._specialType) {
      this._specialType.startMatchFx();
      game.camera.shake(0.0075, 250);
      this._candiesLayer.specialCandyGroup.add(this);
      this.startAnimation('growAndFade', [delay]);
      return;
    }

    const { lvlDataManager } = this._board;
    if (lvlDataManager.isGoalType(this._baseType.export())) {
      this.remove(); return;
    }

    // optional cellX for merge animation
    if (cellX == null) this.startAnimation('vanishAlphaBurst', [delay]);
    else this.startAnimation('moveTo', [delay, cellX, cellY]);
  }

  /**
   * get token for match comparison
   * @returns {string}
   */
  getMatchToken() {
    let token = this._baseType.export();
    if (this._specialType && this._specialType.changeMatchToken) {
      token = this._specialType.changeMatchToken(token);
    }
    if (this._candyStatus && this._candyStatus.changeMatchToken) {
      token = this._candyStatus.changeMatchToken(token);
    }
    return token;
  }

  /**
   * check if candy is matchable.
   * @returns {boolean}
   */
  isMatchable() {
    let matchable = this._baseType.isMatchable();
    if (this.isGoalCollectType()) return false;
    if (this._specialType) matchable = this._specialType.passMatchable(matchable);
    if (this._candyStatus) matchable = this._candyStatus.passMatchable(matchable);
    return matchable;
  }

  /**
   * check if candy is matchable with another candy.
   * @param {Candy} otherCandy
   * @returns {boolean}
   */
  isMatchableWith(otherCandy) {
    return this.isMatchable() && otherCandy.isMatchable()
      && this.getMatchToken() === otherCandy.getMatchToken();
  }

  /**
   * add match fx animations
   * @param {Array} fx
   */
  addMatchFx(fx) {
    this._onMatchFx.push(fx);
  }

  /**
   * dispatch global events for candy collection
   */
  dispatchCollectables() {
    if (!this._baseType.isAlreadyCollected()) {
      G.sb('onCollectableRemove').dispatch(this.getBaseTypeSymbol(), this.getSpecialTypeName() ? false : this);
    }
    if (this.hasSpecialType()) {
      G.sb('onCollectableRemove').dispatch(this.getSpecialTypeName(), this);
    }
  }

  /**
   * update fall sequence / animation.
   */
  _updateFall() {
    if (!this._fallData.active) return;
    if (this._fallData.delay > 0) {
      this._fallData.delay -= 1 * G.deltaTime; return;
    }

    // update fall velocity
    this._fallData.velY += this._fallData.grav * G.deltaTime;
    this.y += this._fallData.velY * G.deltaTime;

    // alpha during falling
    if (this.y < this._fallData.alpha1) {
      if (this.y < this._fallData.alpha0) this.alpha = 0;
      else this.alpha = Math.abs(this._fallData.alpha0 - this.y) / this._fallData.alphaDistance;
    } else this.alpha = 1;

    const xDif = this._fallData.targetX - this.x;
    const yDif = this._fallData.targetY - this.y;
    if (Math.abs(xDif) > yDif) { // check for column change, diagnal falling
      this.x = this._fallData.targetX - yDif * game.math.sign(xDif);
    }

    // falling complete, play imact sfx / animation
    if (this.y > this._fallData.targetY) {
      this.y = this._fallData.targetY;
      this.x = this._fallData.targetX;
      this._fallData.active = false;
      this.startAnimation('bounce');

      const { gameHooks } = this._board;
      const soundId = `stone_impact_${game.rnd.between(1, 3)}`;
      gameHooks.playSound(soundId);

      G.sb('onCandyFallFinish').dispatch(this);
    }
  }

  /**
   * move candy to new position and update the grid.
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} scale
   */
  moveToAndUpdateGrid(cellX, cellY, scale = false) {
    if (G.IMMEDIATE) {
      G.sb('onCandyAnimationFinish').dispatch(this);
      this.cellX = cellX; this.cellY = cellY;
      this._animationData.active = false;
      this._candiesLayer.grid.set(this.cellX, this.cellY, this);
      return;
    }

    this.bringToTop();

    G.sb('onCandyAnimationStart').dispatch();
    this._animationData.active = true;

    if (scale) { // optional scale animation
      game.add.tween(this.scale).to({ x: this.scale.x * 2, y: this.scale.y * 2 }, 250, Phaser.Easing.Sinusoidal.InOut, true, 0, 0, true);
    }

    game.add.tween(this).to({ x: this._board.cellXToPxIn(cellX), y: this._board.cellYToPxIn(cellY) }, 500, Phaser.Easing.Sinusoidal.InOut, true)
      .onComplete.add(() => {
        this.cellX = cellX; this.cellY = cellY;
        this._animationData.active = false;
        this._candiesLayer.grid.set(this.cellX, this.cellY, this);
        G.sb('onCandyAnimationFinish').dispatch(this);
      }, this);
  }

  /**
   * move candy to its new shuffled position.
   */
  shuffleMoveToOwnCell() {
    const orgParent = this.parent;
    if (this.hasSpecialType()) this._candiesLayer.specialCandyGroup.add(this);
    else this._candiesLayer.movingCandyGroup.add(this);

    G.sb('onCandyAnimationStart').dispatch();
    this._animationData.active = true;

    const x = this._board.cellXToPxIn(this.cellX); const y = this._board.cellXToPxIn(this.cellY);
    game.add.tween(this).to({ x, y }, 500, Phaser.Easing.Sinusoidal.InOut, true).onComplete.add(() => {
      orgParent.add(this);
      G.sb('onCandyAnimationFinish').dispatch(this);
      this._animationData.active = false;
    }, this);
  }

  /**
   * start a animation by id
   * @param {string} type
   * @param {any} args
   */
  startAnimation(type, args = []) {
    const animationFunc = `animation_${type}`;
    if (this._animationData.active) {
      console.warn('tried to set Candy animation during another animation'); return;
    }
    if (this[animationFunc]) {
      this._animationData.active = true;
      G.sb('onCandyAnimationStart').dispatch();
      this[animationFunc](...args);
    }
  }

  /**
   * set the candy bounce animation
   */
  animation_bounce() {
    if (G.IMMEDIATE) { // immediate action no animation
      this._animationData.active = false;
      G.sb('onCandyAnimationFinish').dispatch(this);
      return;
    }
    game.add.tween(this).to({ y: this.y - 5 }, 100, Phaser.Easing.Sinusoidal.Out, true, 0, 0, true).onComplete.add(() => {
      this._animationData.active = false;
      G.sb('onCandyAnimationFinish').dispatch(this);
    }, this);
  }

  /**
   * set the alpha burst / remove animation
   * @param {number} delay
   */
  animation_vanishAlphaBurst(delay) {
    G.sb('fx').dispatch('burstCandy', this, this);
    G.sb('onCandyAnimationFinish').dispatch(this);
    this.remove();
  }

  /**
   * set a grow and fade out animation.
   */
  animation_growAndFade() {
    if (G.IMMEDIATE) { // immediate action no animation
      G.sb('onCandyAnimationFinish').dispatch(this);
      this.remove();
      return;
    }
    this._candiesLayer.specialCandyGroup.add(this);
    this.bringToTop();
    const scaleTween = game.add.tween(this.scale).to({ x: 2.5, y: 2.5 }, 200, Phaser.Easing.Sinusoidal.In, true);
    game.add.tween(this).to({ alpha: 0 }, 100, Phaser.Easing.Sinusoidal.In, true, 100).onComplete.add(() => {
      scaleTween.stop();
      G.sb('onCandyAnimationFinish').dispatch(this);
      this.remove();
    }, this);
  }

  /**
   * sets an animation to move the candy to the specified grid position, but does not update grid data.
   * @param {number} delay
   * @param {number} cellX
   * @param {number} cellY
   */
  animation_moveTo(delay, cellX, cellY) {
    if (G.IMMEDIATE) { // immediate action no animation
      G.sb('onCandyAnimationFinish').dispatch(this);
      this.remove();
      return;
    }
    const startMoveToAnimation = () => {
      const x = this._board.cellXToPxIn(cellX); const y = this._board.cellYToPxIn(cellY);
      const moveTween = game.add.tween(this).to({ x, y }, 300, Phaser.Easing.Sinusoidal.In, true);
      game.add.tween(this).to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.In, true, 100).onComplete.add(() => {
        moveTween.stop();
        G.sb('onCandyAnimationFinish').dispatch(this);
        this.remove();
      }, this);
    };

    if (delay > 0) game.time.events.add(delay, startMoveToAnimation, this);
    else startMoveToAnimation();
  }

  /**
   * move candy to combo position.
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} angle (optional)
   */
  animation_moveToCombo(cellX, cellY, angle) {
    if (G.IMMEDIATE) { // immediate action no animation
      G.sb('onCandyAnimationFinish').dispatch(this);
      this.remove();
      return;
    }

    const rotateTween = game.add.tween(this).to({ angle }, 300, Phaser.Easing.Sinusoidal.InOut, true);
    const x = this._board.cellXToPxIn(cellX); const y = this._board.cellYToPxIn(cellY);
    const moveTween = game.add.tween(this).to({ x, y }, 300, Phaser.Easing.Sinusoidal.InOut, true);
    game.add.tween(this).to({ alpha: 0.8 }, 200, Phaser.Easing.Sinusoidal.In, true, 200).onComplete.add(() => {
      moveTween.stop();
      if (rotateTween) rotateTween.stop();
      G.sb('onCandyAnimationFinish').dispatch(this);
      game.time.events.add(1, this.remove, this);
    }, this);
  }

  /**
   * update animations not using tweens.
   */
  _updateAnimation() {
    if (!this._animationData.active) return;
    if (this._animationData.func) this._animationData.func.call(this);
    if (!this._animationData.active) G.sb('onCandyAnimationFinish').dispatch();
  }

  /**
   * main update method.
   */
  update() {
    this._updateFall();
    this._updateAnimation();
  }

  /**
   * check if candy has a specific editor symbol
   * @param {string} symbol
   * @returns {boolean}
   */
  hasEditorSymbol(symbol) {
    return ((this._baseType && this._baseType.getEditorSymbol() === symbol)
      || (this._specialType && this._specialType.getEditorSymbol() === symbol)
      || (this._candyStatus && this._candyStatus.getEditorSymbol() === symbol));
  }

  /**
   * check if candy has a specific export string
   * @param {string} symbol
   * @returns {boolean}
   */
  hasExportString(symbol) {
    return ((this._baseType && this._baseType.export() === symbol)
      || (this._specialType && this._specialType.export() === symbol)
      || (this._candyStatus && this._candyStatus.export() === symbol))
      || this.export() === symbol;
  }

  /**
   * import a candy from editor data.
   * @param {Object} importData
   * @param {boolean} skipAnim (optional) skip animation
   */
  import(importData, skipAnim = false) {
    const importStr = importData.toString();
    importStr.split(':')
      .forEach((chunk) => {
        const editorSymbol = chunk.split('|')[0];
        const argsChunk = chunk.split('|')[1];
        // candy baseTypes
        const baseType = CandyDataManager.getBaseTypeByEditorSymbol(editorSymbol);
        if (baseType) return this.setBaseType(baseType, argsChunk);

        // candy specialTypes
        const specialType = CandyDataManager.getSpecialTypeByEditorSymbol(editorSymbol);
        if (specialType) return this.setSpecialType(specialType, skipAnim, true);

        // candies with special status
        const status = CandyDataManager.getStatusByEditorSymbol(editorSymbol);
        if (status) return this.setStatus(status, argsChunk);

        return null;
      }, this);
  }

  /**
   * export editor data
   * @returns {string}
   */
  export() {
    let expStr = this._baseType.export();
    if (this._specialType) expStr += `:${this._specialType.export()}`;
    if (this._candyStatus) expStr += `:${this._candyStatus.export()}`;
    return expStr;
  }

  /**
   * remove listeners / signal bindings
   */
  _removeListeners() {
    for (const signalBinding of this._signalBindings) signalBinding.detach();
    this._signalBindings.length = 0;
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._removeListeners();
    this._board = null;
    this._candiesLayer = null;
    this._grid = null;
    this._candyStatus = null;
    if (this._baseType) { this._baseType.destroy(); this._baseType = null; }
    if (this._specialType) { this._specialType.destroy(); this._specialType = null; }
    if (this._candyStatus) { this._candyStatus.this.destroy(); this._candyStatus = null; }
  }

  /** CANDY TYPE CHECKS  ***************************** */

  /** true if this candy is only collected at goal arrows  @returns {boolean} */
  isGoalCollectType() { return this.isGoalCandy() || this.isBurntCandy(); }

  /** true if this is a goal candy  @returns {boolean} */
  isGoalCandy() { return this.hasTag(TOKEN_TYPES.GOAL_CANDY); }

  /** true if this is a burn candy @returns {boolean} */
  isBurntCandy() { return this.hasTag(TOKEN_TYPES.BURNT); }

  /** true if this is a layered cake candy @returns {boolean} */
  isLayeredCakeCandy() { return this.hasEditorSymbol(TOKEN_TYPES.LAYER_CAKE); }

  /** true if this is an infected source candy @returns {boolean} */
  isCandyInfected() { return this.hasTag(TOKEN_TYPES.INFECTION); }

  /** true if candy BaseType is random @returns {boolean} */
  isRandom() { return this._baseType.hasTag('random'); }

  /** true if candy is shuffleable @returns {boolean} */
  isShuffleable() { return !this.hasTag('non-shufflable'); }

  /** true if candy is a chest or a fortune cookie @returns {boolean} */
  isChestOrFortune() { return this.hasTag(TOKEN_TYPES.CHEST) || this.hasTag(TOKEN_TYPES.FORTUNE); }

  /** true if candy is a 'normal' candy @returns {boolean} */
  isNormalCandy() {
    if (!this.alive) return false;
    if (this.hasSpecialType() || this.hasStatus()) return false;
    if (this.isGoalCollectType()) return false;
    if (!this._board.isCellMatchable(this.cellX, this.cellY)) return false;
    if (!this._board.isMoveable(this.cellX, this.cellY)) return false;
    if (this.isChestOrFortune()) return false;
    return true;
  }

  /** GETTER METHODS ********************************* */

  /** @returns {Board} get Board instance */
  get board() { return this._board; }

  /** @returns {CandyStatus} get CandyStatus instance */
  get candyStatus() { return this._candyStatus; }

  /** @returns {CandyType} get BaseType instance */
  get baseType() { return this._baseType; }

  /** @returns {Object} get BaseTypeData instance */
  get baseTypeData() { return this._baseTypeData; }

  /** @returns {SpecialType} get SpecialType instance if defined */
  get specialType() { return this._specialType; }

  /** @returns {Candy} get related candy for last movement */
  get lastMovedWith() { return this._lastMovedWith; }

  /** @returns {boolean} randomization state */
  get postRandomPhase() { return this._postRandomPhase; }
}
