import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../../States/Scaling/GameScaleController';

/**
 * The countdown banner on the top of Window_Level.js
 * Hopefully doesn't clash with anything!
 * @author Sandra Koo
 */
export class FriendshipChest_Countdown extends Phaser.Group {
  constructor(inGame, inParent) {
    super(inGame, inParent);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this.container = undefined;
    this.originalBounds = undefined;
    this.renderedBounds = undefined;

    this.drawBanner();

    if (this._isLandscape) {
      this.scale.setTo(this._gameScale);
    }
  }

  drawBanner() {
    this.container = new Phaser.Group(G.Game, this);

    const bg = G.makeImage(0, 0, 'leaderboard_ingame_panel', 0.5, this.container);
    bg.angle = 180;
    bg.y = bg.height / 2;

    const maskLayer = new Phaser.Graphics(game); // Mask
    maskLayer.beginFill(0, 0.5);
    /** Handy comment block will tell you what to put in for the positionals for the mask
     *
    maskLayer.drawRoundedRect(0, 0, bg.width*0.97, bg.height, 20); //Currently rounded rect
    maskLayer.x = -maskLayer.width/2;
    maskLayer.y = bg.y + (bg.height*0.42) - maskLayer.height;
    console.log(maskLayer.x, maskLayer.y, maskLayer.width, maskLayer.height);
    */
    maskLayer.drawRoundedRect(
      G.OMTsettings.friendshipChest.layoutData.bannerMask.x,
      G.OMTsettings.friendshipChest.layoutData.bannerMask.y,
      G.OMTsettings.friendshipChest.layoutData.bannerMask.width,
      G.OMTsettings.friendshipChest.layoutData.bannerMask.height,
      15,
    );
    maskLayer.endFill();

    const innerGroup = new Phaser.Group(G.game, this);
    const text = new G.Text(0, bg.y - 5, G.txt('Levels left to unlock your Friendship Chest reward'), 'font-blue', 0.5, bg.width * 0.6, bg.height * 0.9, true, 'center');

    const levelData = G.OMTsettings.friendshipChest.layoutData.bannerText;
    const levelCounterText = G.featureUnlock.unlockLevels.friendshipChest - G.saveState.getLastPassedLevelNr();
    const levelCounter = new G.Text(0, bg.y + levelData.yOffset, `${levelCounterText}`, { style: 'font-blue', fontSize: levelData.fontSize }, 0.5, bg.width * 0.5, bg.height * 0.9);

    const chest = G.makeImage(0, bg.y - 5, 'friendship_chest_full', 0.5, null);
    const chestScale = (bg.height * 0.9) / chest.height;
    chest.scale.set(chestScale, chestScale);

    text.x = (bg.width * 0.1) + (text.width - bg.width) / 2;
    if (G.OMTsettings.friendshipChest.layoutData.chestIsFacingRight) {
      chest.scale.x *= -1;
      chest.x = (bg.width / 2) + (chest.width * 0.3);
      levelCounter.x = (levelCounter.width / levelData.offsetDivision) + (text.x + chest.x + (text.width + chest.width) / 2) / 2;
    } else {
      chest.x = (bg.width / 2) - (chest.width * 0.3);
      levelCounter.x = (levelCounter.width / levelData.offsetDivision) + (text.x + chest.x + (text.width - chest.width) / 2) / 2;
    }

    innerGroup.addChild(text);
    innerGroup.addChild(levelCounter);
    innerGroup.addChild(chest);
    innerGroup.addChild(maskLayer);
    this.container.addChild(bg);
    this.container.addChild(innerGroup);
    innerGroup.mask = maskLayer;

    this.originalBounds = this.container.getBounds().clone();
    this.renderedBounds = this.container.getBounds().clone();
  }

  /**
   * Toggles the appearance of the banner. Either coming in or not. Tries to prevent coming in more than once
   * @param {boolean} vis
   */
  toggleVisibility(vis, selfDestroy = false) {
    // console.log('!!toggleVisibility = ' + vis);
    if (vis) game.world.add(this);

    if (this.visible === vis) { // If the same, don't do anything
      return;
    }

    let givenVis = vis;
    if (givenVis === undefined) { // If nothing was given, do a flip of it
      givenVis = !this.visible; // flip
    }

    const awayY = -this.height;
    let tween;
    if (givenVis) { // Tweens in
      this.container.y = awayY;
      tween = game.add.tween(this.container)
        .to({ y: 0 }, 200, Phaser.Easing.Sinusoidal.Out);
      this.visible = true;
    } else { // Tweens out
      this.container.y = 0;
      tween = game.add.tween(this.container)
        .to({ y: awayY }, 200, Phaser.Easing.Sinusoidal.In);
      tween.onComplete.addOnce(() => {
        this.visible = false;
        if (selfDestroy) {
          this.destroy();
        }
        if (this.parent) {
          this.parent.removeChild(this);
        }
      });
    }
    tween.start();
  }
}
