import { GameModePromo_TutorialTextBase } from './GameModePromo_TutorialTextBase';

const TEXT_STYLE = { style: 'ui-tournament-promo', fontSize: 45 };

/**
 * Class for displaying horizontal tutorial bar with mascot for tournament.
 * Currently, the daily challenge promo also uses this class
 */
export class TournamentPromo_TutorialText extends GameModePromo_TutorialTextBase {
  /**
   * constructor
   */
  constructor() {
    super('tutorial_bar', TEXT_STYLE);
  }
}
