/**
 * this was kept around as Window-friendBeaten relies on this, needs refactoring
 */

/* eslint-disable */

import Overlay2 from '../../Elements/GameState/UI/Layers/Overlay2';

(function GHighscorePanel2HelperIIFE(){

  G.HighscorePanel2Helper = {

    createWaitingIconsManager:  createWaitingIconsManager,
    createTimerUpdatersManager: createTimerUpdatersManager,
    createAvatarsManager:       createAvatarsManager,
    createScrollManager:        createScrollManager,

    createAnimator:   createAnimator,
    createMaxRect:    createMaxRect,
    createPositioner: createPositioner,
    createFabricator: createFabricator,

    setShownInstant: setShownInstant,
    showInstant:     showInstant,
    hideInstant:     hideInstant,

    addOverlay: addOverlay,
    addMaskTo: addMaskTo,
    r2t: r2t

  };

  return;

  //

  function createWaitingIconsManager(exports, name, animator) {
    
    var waitingIcons = [];

    exports.forEvery(name, function(element, index, exports) {
      var uiComponent = element.customComponents.ui;
      var waitingIcon = new G.WaitingIcon(
        uiComponent.sizeAfterLastResize.x,
        uiComponent.sizeAfterLastResize.y
      );
      element.add(waitingIcon);
      waitingIcon.left = 0;
      waitingIcon.top  = 0;
      waitingIcons.push(waitingIcon);
    });

    var showReasons = new G.Reasons(function(show) {
      waitingIcons.forEach(function(waitingIcon) {
        show ? animator.show(waitingIcon) : animator.hide(waitingIcon);
      });
    });

    return {
      showReasons: showReasons
    };
  }

  //

  function createTimerUpdatersManager(game) {

    var getResourceCooldownSeconds = G.HighscorePanel2Data.getResourceCooldownSeconds;

    var funcsPerUserIdPerResourceType = G.StorageUtils.createDynamicMap(
      function(resourceType, index) {
        var funcsPerUserId = G.StorageUtils.createDynamicMap(
          function(userId, index) { return createFuncs(); }
        );
        funcsPerUserId.createSignal.add(function(userId) {
          if (getResourceCooldownSeconds(userId, resourceType) > 0) {
            startTimer(userId, resourceType, true);
          }
        });
        return funcsPerUserId;
      }
    );
    var runningTimersPerUserIdPerResourceType = G.StorageUtils.createDynamicMap(
      function(resourceType, index) { return {}; }
    );

    var frequentUpdatePaused = false;

    game.time.events.loop(1000, updateTimers);

    return {
      addFrequentUpdateFunc: addFrequentUpdateFunc,
      addUpdateFunc: addUpdateFunc,
      startTimer: startTimer,
      forceUpdate: update,
      pauseFrequentUpdate:  pauseFrequentUpdate,
      resumeFrequentUpdate: resumeFrequentUpdate
    };

    function addFrequentUpdateFunc(userId, resourceType, func) {
      getFuncs(userId, resourceType).frequentUpdateFuncs.push(func);
      func(getResourceCooldownSeconds(userId, resourceType));
    }

    function addUpdateFunc(userId, resourceType, func) {
      getFuncs(userId, resourceType).updateFuncs.push(func);
      func(getResourceCooldownSeconds(userId, resourceType));
    }

    function startTimer(userId, resourceType, optDontUpdate) {
      runningTimersPerUserIdPerResourceType.getValue(resourceType)[userId] = true;

      var secondsLeft = getResourceCooldownSeconds(userId, resourceType);
      getFuncs(userId, resourceType).frequentUpdateFuncs.forEach(
        function(func) { func(secondsLeft); }
      );

      if (!optDontUpdate) update();
    }

    function pauseFrequentUpdate()  { frequentUpdatePaused = true;  }
    function resumeFrequentUpdate() { frequentUpdatePaused = false; }

    function updateTimers() {
      var timersToStop = updateFrequent();
      if (timersToStop.length > 0) {
        timersToStop.forEach(function(data) {
          delete runningTimersPerUserIdPerResourceType.getValue(data.resourceType)[data.userId];
        });
        update();
      }
    }

    //

    function createFuncs() {
      return {
        updateFuncs: [],
        frequentUpdateFuncs: []
      };
    }

    function getFuncs(userId, resourceType) {
      return funcsPerUserIdPerResourceType.getValue(resourceType).getValue(userId);
    }

    //

    function update() {
      funcsPerUserIdPerResourceType.forEachKeyValuePair(function(resourceType, dataPerUserId) {
        dataPerUserId.forEachKeyValuePair(function(userId, funcs) {
          var secondsLeft = getResourceCooldownSeconds(userId, resourceType);
          funcs.updateFuncs.forEach(function(func) { func(secondsLeft); });
        });
      });
    }

    function updateFrequent() {
      var timersToStop = [];
      runningTimersPerUserIdPerResourceType.forEachKeyValuePair(
        function(resourceType, dataPerUserId) {
          Object.keys(dataPerUserId).forEach(function(userId) {
            var secondsLeft = getResourceCooldownSeconds(userId, resourceType);
            if (secondsLeft > 0) {
              if (!frequentUpdatePaused) {
                var funcs = getFuncs(userId, resourceType);
                funcs.frequentUpdateFuncs.forEach(function(func) { func(secondsLeft); });
              }
            } else {
              timersToStop.push({
                userId: userId,
                resourceType: resourceType
              });
            }
          });
        }
      );
      return timersToStop;
    }
  }

  //

  function createAvatarsManager(game, avatarAlphaMask, stateTracker, maxSize) {

    var avatarsCache = G.StorageUtils.createCache(
      function(avatarUrl, width, height) { return width + 'x' + height + '::' + avatarUrl; }
    );

    var createdRTs = {};

    stateTracker.killedSignal.addOnce(function() {
      Object.keys(createdRTs).forEach(function(avatarUrl) { createdRTs[avatarUrl].destroy(true); });
      createdRTs = {};
      avatarsCache.clear();
    });

    var futurePromisePerDataType = G.StorageUtils.createDynamicMap(function() {
      return G.AsyncUtils.createFuturePromise();
    });

    return {
      tryPreload: tryPreload,
      getFuturePromise: getFuturePromise,
      emplaceAvatar: emplaceAvatar
    };

    function getFuturePromise(dataType) {
      return futurePromisePerDataType.getValue(dataType).read;
    }

    function tryPreload(dataType, avatarUrls) {
      var futurePromise = futurePromisePerDataType.getValue(dataType);
      G.AsyncUtils.waitForAllPromisesToSettle(
        avatarUrls.map(function(avatarUrl) {
          return (
            avatarsCache.get(avatarUrl, maxSize.width, maxSize.height) ||
            avatarsCache.set(
              loadAvatar(avatarUrl, maxSize.width, maxSize.height),
              avatarUrl, maxSize.width, maxSize.height
            )
          );
        })
      ).catch(function(results) {
        console.log(
          'Failed to preload avatars:\n' +
          G.StorageUtils.filterAndMap(
            results,
            function(skip, result, index, array) {
              if (result.success) return skip();
              return avatarUrls[index] + ' - ' + result.error;
            }
          ).join('\n')
        );
      }).then(futurePromise.control.fulfill, futurePromise.control.reject);
      return futurePromise.read.promise;
    }

    function loadAvatar(avatarUrl, width, height) {
      return G.getExtTexture(avatarUrl).then(function(avatarTextureName) {
        if (!stateTracker.isAlive()) return;

        var bitmapData = game.make.bitmapData(width, height);
        var rect = new Phaser.Rectangle(0, 0, width, height);
        bitmapData.alphaMask(avatarTextureName, avatarAlphaMask, rect, rect);

        var rt = game.make.renderTexture(width, height);
        var img = bitmapData.addToWorld();
        rt.renderXY(img, 0, 0);
        bitmapData.destroy();
        img.destroy();

        createdRTs[avatarUrl] = rt;

        return rt;
      });
    }

    function emplaceAvatar(image, avatarUrl) {

      var rt = createdRTs[avatarUrl];

      if (!rt) {
        console.warn("Avatar manager could not emplace avatar as it is not loaded");
        return;
      }

      var originalWidth  = image.width;
      var originalHeight = image.height;
      image.loadTexture(rt);
      image.width  = originalWidth;
      image.height = originalHeight;

      // Changing width/height happens relative to image's pivot point,
      // this effectively moves the image

      G.UI.resize(
        image,
        image.parent.customComponents.ui.sizeAfterLastResize.x,
        image.parent.customComponents.ui.sizeAfterLastResize.y
      );

    }
  }

  //

  /**
   * @param getScrollContent {() => PIXI.DisplayObject}
   * @param containerActualSize {Object}
   * @param containerActualSize.x {number}
   * @param containerActualSize.y {number}
   * @param parentDesiredSize {Object}
   * @param parentDesiredSize.x {number}
   * @param parentDesiredSize.y {number}
   * @param direction {Object}
   * @param direction.x {number}
   * @param direction.y {number}
   * @param scrollAnchor {Object}
   * @param scrollAnchor.x {number|null}
   * @param scrollAnchor.y {number|null}
   */
  function createScrollManager(
    getScrollContent,
    containerActualSize,
    parentDesiredSize,
    direction,
    scrollAnchor,
  ) {

    var scrollInfo = {
      x: { position: 0, containerSize: containerActualSize.x, contentSize: 0 },
      y: { position: 0, containerSize: containerActualSize.y, contentSize: 0 }
    };

    var scrollInput = new G.ScrollInput(
      0, 0,
      scrollInfo.x.containerSize,
      scrollInfo.y.containerSize
    );
    scrollInput.alpha = 0;

    G.UI.addUIComponent(
      scrollInput,
      G.UI.createUIComponent(G.UI.ElementType.Image, parentDesiredSize.x, parentDesiredSize.y)
    );

    scrollInput.signals.postUpdate.add(onScrollUpdate);

    return {
      group: scrollInput,
      expandToContain: expandToContain
    };

    function expandToContain(anchoredLayout, parentSize) {
      var start = anchoredLayout.getLocalPosition(parentSize.x, parentSize.y, { x: 0, y: 0 });
      var end   = anchoredLayout.getLocalPosition(parentSize.x, parentSize.y, { x: 1, y: 1 });

      scrollInfo.x.contentSize = Math.max(scrollInfo.x.contentSize, start.x);
      scrollInfo.x.contentSize = Math.max(scrollInfo.x.contentSize, end.x);
      scrollInfo.y.contentSize = Math.max(scrollInfo.y.contentSize, start.y);
      scrollInfo.y.contentSize = Math.max(scrollInfo.y.contentSize, end.y);
    }

    function onScrollUpdate() {

      var scrollContent = getScrollContent();

      if (scrollAnchor.x !== null) {
        updateScrollContentPosition(
          scrollInfo.x,
          direction.x * scrollInput.getVelX(),
          scrollInfo.x.contentSize,
          scrollAnchor.x
        );
        scrollContent.x = scrollInfo.x.position;
      }
      if (scrollAnchor.y !== null) {
        updateScrollContentPosition(
          scrollInfo.y,
          direction.y * scrollInput.getVelY(),
          scrollInfo.y.contentSize,
          scrollAnchor.y
        );
        scrollContent.y = scrollInfo.y.position;
      }
      updateScrollContentChildren();
    
    }

    function updateScrollContentPosition(scrollInfoAxis, velocity, currentSize, anchor) {
      var limits;
      if (currentSize < scrollInfoAxis.containerSize) {
        var position = anchor * (scrollInfoAxis.containerSize - currentSize);
        limits = {
          min: position,
          max: position
        };
      } else {
        limits = {
          min: Math.min(0, scrollInfoAxis.containerSize - currentSize),
          max: 0
        };
      }
      scrollInfoAxis.position -= velocity;
      scrollInfoAxis.position = rubberband(scrollInfoAxis.position, limits.max, true);
      scrollInfoAxis.position = rubberband(scrollInfoAxis.position, limits.min, false);
    }

    function updateScrollContentChildren() {

      var childStart = new G.Math.Vector2();
      var childEnd   = new G.Math.Vector2();

      var windowStartX = -scrollInfo.x.position;
      var windowStartY = -scrollInfo.y.position;
      var windowEndX = windowStartX + scrollInfo.x.containerSize;
      var windowEndY = windowStartY + scrollInfo.y.containerSize;

      getScrollContent().children.forEach(function(child, index, array) {

        var uiComponent = child.customComponents.ui;

        // Not using children.left/top/right/bottom accessors
        // since they are affected by mask, visibility and are also expensive

        childStart = uiComponent.anchoredLayout.getLocalPosition(
          scrollInfo.x.containerSize,
          scrollInfo.y.containerSize,
          { x: 0, y: 0 },
          childStart
        );
        childEnd = uiComponent.anchoredLayout.getLocalPosition(
          scrollInfo.x.containerSize,
          scrollInfo.y.containerSize,
          { x: 1, y: 1 },
          childEnd
        );

        // Vertical scroll can have children that are wider than container
        // That shouldn't prevent `fullyIn` from being true for its matter
        var ignoreX = scrollAnchor.x === null;
        var ignoreY = scrollAnchor.y === null;

        var fullyIn = (
          (ignoreX || isFullyIn(childStart.x, childEnd.x, windowStartX, windowEndX)) &&
          (ignoreY || isFullyIn(childStart.y, childEnd.y, windowStartY, windowEndY))
        );
        var atLeastPartiallyIn = fullyIn || (
          (ignoreX || isAtLeastPartiallyIn(childStart.x, childEnd.x, windowStartX, windowEndX)) &&
          (ignoreY || isAtLeastPartiallyIn(childStart.y, childEnd.y, windowStartY, windowEndY))
        );

        child.exists = fullyIn;

        var ignoreInput = !fullyIn;
        if (child.ignoreChildInput !== ignoreInput) {
          G.Utils.setIgnoreChildrenRec(child, ignoreInput);
        }

        child.visible = atLeastPartiallyIn;
      });
    }

    //

    function rubberband(current, limit, positive) {
      var FACTOR = 0.5;
      var THRESHOLD = 1;
      var newValue = current;
      if ((newValue > limit) === positive) {
        newValue = G.lerp(newValue, limit, FACTOR);
        if ((newValue < limit + THRESHOLD) === positive) {
          newValue = limit;
        }
      }
      return newValue;
    }

    //

    function isFullyIn(childStart, childEnd, windowStart, windowEnd) {
      return (
        childStart >= windowStart &&
        childEnd   <= windowEnd
      );
    }

    function isAtLeastPartiallyIn(childStart, childEnd, windowStart, windowEnd) {
      return (
        childEnd   > windowStart &&
        childStart < windowEnd
      );
    }

  }

  //

  function createAnimator(game) {

    var duration = 250;

    var animations = {
      show: G.Animations.Generic.createFadeInAnimation(duration),
      hide: G.Animations.Generic.createFadeOutAnimation(duration)
    };

    return {
      getDuration: getDuration,
      show: show,
      hide: hide
    };

    function getDuration() { return duration; }

    function show(target) { animations.show.animate(game, target); }
    function hide(target) { animations.hide.animate(game, target); }

  }

  //

  function createMaxRect() {
    var result = {
      width:  1,
      height: 1,
      expand: expand
    };
    return result;
    function expand(width, height) {
      result.width  = Math.max(result.width,  width);
      result.height = Math.max(result.height, height);
    }
  }

  function createPositioner(exampleUIComponentFirst, exampleUIComponentNext) {
    var anchorsFirst = exampleUIComponentFirst.anchoredLayout.anchors;
    var anchorsNext  = exampleUIComponentNext.anchoredLayout.anchors;
    
    var anchorXStart = anchorsFirst.start.x;
    var anchorYStart = anchorsFirst.start.y;

    var width  = anchorsFirst.end.x - anchorsFirst.start.x;
    var height = anchorsFirst.end.y - anchorsFirst.start.y;

    var deltaX = anchorsNext.start.x - anchorsFirst.start.x;
    var deltaY = anchorsNext.start.y - anchorsFirst.start.y;

    return { position: position };

    function position(element, index) {
      
      var layout = element.customComponents.ui.anchoredLayout;

      var anchorX = anchorXStart + deltaX * index;
      layout.anchors.start.x = anchorX;
      layout.anchors.end.x = anchorX + width;

      var anchorY = anchorYStart + deltaY * index;
      layout.anchors.start.y = anchorY;
      layout.anchors.end.y = anchorY + height;
    }
  }

  function createFabricator(game, factory, dataList) {
    var intervalMs = 50;

    var index = 0;
    var scheduler = G.AsyncUtils.createScheduler(game);

    return {
      startFabricating: startFabricating,
      stopFabricating:  stopFabricating
    };

    function startFabricating() {
      stopFabricating();
      index = 0;
      keepFabricating();
    }

    function keepFabricating() {
      if (index >= dataList.length) return;
      factory.fabricate(dataList[index], index);
      ++index;
      scheduler.rescheduleAction(keepFabricating, intervalMs);
    }

    function stopFabricating() {
      scheduler.cancelSchedule();
    }
  }

  //

  function setShownInstant(element, shown) {
    shown ? showInstant(element) : hideInstant(element);
  }

  function showInstant(element) {
    element.visible = true;
    element.exists = true;
  }

  function hideInstant(element) {
    element.visible = false;
    element.exists = false;
  }

  //

  function addOverlay(pointerUpListener) {
    var overlay = new Overlay2(true);
    game.world.add(overlay);
    overlay.pointerUpSignal.add(pointerUpListener);
    return overlay;
  }

  function addMaskTo(target) {
    var size = G.UI.getSize(target);

    var maskGfx = target.add(game.add.graphics());
    G.UI.addUIComponent(G.UI.createUIComponent(G.UI.ElementType.Image, size.width, size.height));

    maskGfx.clear();
    maskGfx.beginFill(0xff0000, 1);

    maskGfx.drawRect(0, 0, 16, 16);
    maskGfx.width  = size.width;
    maskGfx.height = size.height;
    
    target.mask = maskGfx;
  }

  function r2t(group) {
    var size = G.UI.getSize(group);
    var rt = game.make.renderTexture(size.width, size.height);
    var image = game.make.image(0, 0, rt);
    image.name = (group.name || '') + '_R2T_Cache';
    rt.renderXY(group, 0, 0);
    group.removeAll(true);
    group.add(image);
    group.onDestroy.addOnce(function() { rt.destroy(true); });
  }

}());
