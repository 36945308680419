import { SpecialEvent_LeaderboardUserView } from '../../../OMT_UI/SpecialEvents/Leaderboard/SpecialEvent_LeaderboardUserView';

export default class TreasureHuntPodiumUserView extends SpecialEvent_LeaderboardUserView {
  /**
   * constructor
   * @param {{id:string, rank:number, name:string, score:number}} entryData
   * @param {Object} userViewConfig
   */
  constructor(entryData, userViewConfig) {
    super(entryData, userViewConfig);
  }

  /**
   * init the avatar iamge
   */
  _initAvatarImage() {
    // Don't
  }
}
