/* eslint-disable func-names */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
if (typeof G === 'undefined') G = {};

{
  /**
   * @param addFunc ((...any) => T)
   *     must return something unique that will be later passed to `removeFunc`
   * @param removeFunc ((unique: T) => void)
   */
  const createTrackingScheduler = (addFunc, removeFunc) => {
    const tracked = [];

    const debugCheck = (result) => {
      console.assert(
        !tracked.includes(result),
        'addFunc must return something unique',
      );
    };

    const track = (result) => {
      debugCheck(result);
      tracked.push(result);
    };

    const add = (...args) => {
      const result = addFunc.bind(null)(...args);
      track(result);

      return result;
    };

    const untrack = (result) => {
      const index = tracked.indexOf(result);

      if (index >= 0) {
        tracked.splice(index, 1);
        return true;
      }

      return false;
    };

    const remove = (result) => {
      if (untrack(result)) {
        removeFunc(result);
      }
    };

    const removeAll = () => {
      while (tracked.length > 0) {
        remove(tracked[0]);
      }
    };

    return {
      add,
      remove,
      removeAll,
    };
  };

  const promisifyCallbacks = (
    optDoneCallback,
    optFailCallback,
    optAsyncStartCallback,
    optDoneSyncCallback,
  ) => {
    const finishSync = (value) => {
      if (optDoneSyncCallback) {
        optDoneSyncCallback(value);
      } else if (optDoneCallback) {
        optDoneCallback(value);
      }

      return Promise.resolve(value);
    };

    const startAsync = (promise) => {
      if (optAsyncStartCallback) {
        optAsyncStartCallback();
      }

      return promise.then(
        (result) => {
          if (optDoneCallback) {
            optDoneCallback(result);
          }

          return result;
        },
        (error) => {
          if (optFailCallback) {
            optFailCallback();
          }

          return Promise.reject(error);
        },
      );
    };

    return {
      finishSync,
      startAsync,
    };
  };

  // A promise that you can resolve or reject outside of a function passed to its constructor
  const createFuturePromise = (optDefaultValue) => {
    let settled = false;
    let succeed = false;
    let value = optDefaultValue;

    let fulfillFunc;
    let rejectFunc;

    const promise = new Promise((_fulfill, _reject) => {
      fulfillFunc = _fulfill;
      rejectFunc = _reject;
    });

    const isSettled = () => settled;
    const isSucceed = () => succeed;
    const getValue = () => value;

    const fulfill = (result) => {
      if (!settled) {
        settled = true;
        succeed = true;
        value = result;
        fulfillFunc(value);
      }
    };

    const reject = (error) => {
      if (!settled) {
        settled = true;
        rejectFunc(error);
      }
    };

    return {
      read: {
        promise,
        isSettled,
        isSucceed,
        getValue,
      },
      control: {
        fulfill,
        reject,
      },
    };
  };

  const waitForAllPromisesToSettle = (promises) => {
    const wrapPromise = (promise) =>
      promise.then(
        (value) => ({ value, success: true }),
        (error) => ({ error, success: false }),
      );

    return Promise.all(promises.map(wrapPromise)).then((results) => {
      const success = results.every((result) => result.success);

      return success ? Promise.resolve(results) : Promise.reject(results);
    });
  };

  /**
   * Wraps a function so that it only executes once
   * @param {Function} func
   * @param {any} optContext
   * @returns {Function} wrapped
   */
  const wrapToDoOnce = (func, optContext) => {
    let funcToCallNextTime = func;
    let context = optContext;

    const wrapped = (...args) => {
      if (funcToCallNextTime) {
        const temp = funcToCallNextTime;
        funcToCallNextTime = null;
        temp.bind(context)(...args);
        context = null;
      }
    };

    return wrapped;
  };

  /**
   * Helper when you need to schedule an action to be performed after a delay,
   * but you might need to re-schedule it or cancel
   *
   * @param arg {false|Phaser.Game} if false, the scheduler will use setTimeout
   */
  const createScheduler = (arg, optDefaultDelayMs) => {
    let cancelFunc = null;

    const getScheduleFunc = (_arg) => {
      const game = _arg;
      const timer = game.time.events;

      const scheduleTimeEvent = (delayMs, func) => {
        const timeEvent = timer.add(delayMs, func);
        const cancel = () => timer.remove(timeEvent);

        return cancel;
      };

      const scheduleTimeout = (delayMs, func) => {
        const timeoutId = setTimeout(func, delayMs);
        const cancel = () => clearTimeout(timeoutId);

        return cancel;
      };

      if (_arg === false) {
        return scheduleTimeout;
      }

      if (!_arg) {
        throw TypeError(
          'Argument must be an instance of Phaser.Game or a false',
        );
      }

      return scheduleTimeEvent;
    };

    const schedule = getScheduleFunc(arg);

    const isRunning = () => cancelFunc !== null;

    const cancelSchedule = () => {
      if (isRunning()) {
        const cancelFuncCopy = cancelFunc;
        cancelFunc = null;
        cancelFuncCopy();
      }
    };

    const rescheduleAction = (action, optDelayMs) => {
      cancelSchedule();
      const delayMs = G.Utils.defined(optDelayMs, optDefaultDelayMs, 1000);

      cancelFunc = schedule(delayMs, () => {
        cancelFunc = null;
        action();
      });
    };

    return {
      isRunning,
      rescheduleAction,
      cancelSchedule,
    };
  };

  /**
   * @param arg {false|Phaser.Game} see `G.AsyncUtils.createScheduler`
   */
  const delay = (arg, func, delayMs) => {
    const scheduler = createScheduler(arg);
    const cancel = () => {
      scheduler.cancelSchedule();
    };

    scheduler.rescheduleAction(func, delayMs);

    return cancel;
  };

  /**
   * @param action {(callback: (succeed: Boolean) => void) => (cancel: () => void)}
   * @param [optGame] {Phaser.Game} if provided, will use game's timer
   * @param [optGetDelayFunc] {(count: Number) => Number}
   */
  const retryAsyncAction = (action, optGame, optGetDelayFunc) => {
    const scheduler = createScheduler(optGame || false);

    const createIncreasingDelayManager = (_optGetDelayFunc) => {
      let counter = 0;

      const createDefaultGetDelayFunc = (initialDelay, maxDelay) => {
        const _getDelayFunc = (_counter) =>
          Math.min(maxDelay, initialDelay * 2 ** (_counter - 1));

        return _getDelayFunc;
      };

      const getDelayFunc =
        _optGetDelayFunc || createDefaultGetDelayFunc(1000, 30 * 1000);

      const getDelay = () => (counter === 0 ? 0 : getDelayFunc(counter));

      const increase = () => {
        counter += 1;
      };
      const reset = () => {
        counter = 0;
      };

      return {
        getDelay,
        increase,
        reset,
      };
    };

    const increasingDelayManager = createIncreasingDelayManager(
      optGetDelayFunc,
    );

    let running = false;
    const isRunning = () => running;

    let cancelled = false;
    const isCancelled = () => cancelled;

    let cancelAction = null;

    const handleSuccess = () => {
      //
    };

    const retry = () => {
      if (isCancelled()) throw new Error('Retry after cancel is not supported');

      if (isRunning()) return;

      running = true;

      let cancelActionWasNulled = false;
      cancelAction = action((succeed) => {
        running = false;

        cancelAction = null;
        cancelActionWasNulled = true;

        if (!isCancelled()) {
          if (succeed) {
            handleSuccess();
          } else {
            // eslint-disable-next-line no-use-before-define
            handleFail();
          }
        }
      });
      if (cancelActionWasNulled) cancelAction = null;
    };

    const scheduleRetry = () => {
      if (isRunning()) return;
      scheduler.cancelSchedule();
      const delayMs = Math.max(0, increasingDelayManager.getDelay());
      if (Number.isFinite(delayMs)) scheduler.rescheduleAction(retry, delayMs);
    };

    const handleFail = () => {
      increasingDelayManager.increase();
      scheduleRetry();
    };

    scheduleRetry();

    const cancel = () => {
      if (isCancelled()) return;
      cancelled = true;
      running = false;
      scheduler.cancelSchedule();
      if (cancelAction) {
        cancelAction();
      }
    };

    const resetDelays = () => {
      if (isCancelled()) throw new Error('Reset after cancel is not supported');
      increasingDelayManager.reset();
      scheduleRetry();
    };

    return {
      isRunning,
      isCancelled,
      cancel,
      resetDelays,
    };
  };

  /**
   * @param arg {false|Phaser.Game} see `G.AsyncUtils.createScheduler`
   */
  const createWaiter = (arg) => {
    const timer = createScheduler(arg);
    let timedOut = false;

    const signals = {
      stoppedBeforeTimeout: new Phaser.Signal(),
      timedOut: new Phaser.Signal(),
      stoppedAfterTimeout: new Phaser.Signal(),
    };

    const startWaiting = (durationMs) => {
      timer.rescheduleAction(() => {
        timedOut = true;
        signals.timedOut.dispatch();
      }, durationMs);
    };

    const stopWaiting = (succeed) => {
      timer.cancelSchedule();

      if (!timedOut) {
        signals.stoppedBeforeTimeout.dispatch(succeed);
      } else {
        signals.stoppedAfterTimeout.dispatch(succeed);
      }
    };

    return {
      signals,
      startWaiting,
      stopWaiting,
    };
  };

  const createAliveStatusTracker = (registerKillerListener) => {
    const killedSignal = new Phaser.Signal();
    const deadReasons = new G.Reasons((dead) => {
      if (dead) killedSignal.dispatch();
    });

    const kill = () => {
      deadReasons.add('killed');
    };

    registerKillerListener(kill);

    return {
      isAlive: deadReasons.hasNone,
      killedSignal,
    };
  };

  const createPhaserStateStatusTracker = (game) =>
    createAliveStatusTracker((callback) => {
      game.state.onStateChange.addOnce(callback);
    });

  const debounce = (getter, durationMs) => {
    let last = -Infinity;
    let saved;

    const debounced = function (...args) {
      const now = Date.now();
      if (now - last >= durationMs) {
        last = now;
        saved = getter.bind(this)(...args);
      }

      return saved;
    };

    return debounced;
  };

  G.AsyncUtils = {
    createTrackingScheduler,
    promisifyCallbacks,
    createFuturePromise,
    waitForAllPromisesToSettle,
    wrapToDoOnce,
    delay,
    createScheduler,
    retryAsyncAction,
    createWaiter,
    createPhaserStateStatusTracker,
    debounce,
  };
}
