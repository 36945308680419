import { SPECIAL_TYPES } from '@omt-game-board/Board/BoardConstants';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

export const MYSTERY_GIFT_SESSION_DATA_KEY = 'mysteryGift-session'; // Key for data held in sesson
const MysteryGiftSaveStateGifts = {
  SPIRAL: 's',
  VERTICAL: 'v',
  HORIZONTAL: 'h',
  CROSS: 'c',
};
const MysteryGiftActiveTimerKey = 'mysteryGift';

export class MysteryGiftManager {
  /**
   * @returns {{eols:number, g:Array<string>, s:number, dl:boolean}}
   */
  static getDefaultData() {
    return {
      eols: 0, // End Of Last Streak
      g: [MysteryGiftSaveStateGifts.SPIRAL, MysteryGiftSaveStateGifts.VERTICAL, MysteryGiftSaveStateGifts.HORIZONTAL, MysteryGiftSaveStateGifts.CROSS],
      s: 0, // streak
      dl: false, // During Level
    };
  }

  /**
   * Migrates old data to new data structure
   * @param {{endOfLastStreak:number, streak:number, duringLevel: boolean, gifts:Array<String>}} oldData
   * @returns {{eols:number, g:Array<string>, s:number, dl:boolean}}
   */
  static migrateData(oldData) {
    const outData = MysteryGiftManager.getDefaultData();
    outData.eols = Boolean(oldData.endOfLastStreak);
    outData.s = oldData.streak;
    outData.dl = oldData.duringLevel;

    outData.g = [];
    const oldGiftArr = [];
    for (const gift of oldData.gifts) { // Look at old order of gifts
      for (const key in SPECIAL_TYPES) {
        if (SPECIAL_TYPES[key] === gift) { // Find the correct key
          const targetGift = MysteryGiftSaveStateGifts[key];
          oldGiftArr.push(targetGift); // Push in the shorthand version
          outData.g.splice(outData.g.indexOf(targetGift), 1); // Remove it from dataRef for now
          break;
        }
      }
    }
    outData.g = oldGiftArr.concat(outData.g); // Attach in any new short hand gifts that weren't removed to the end
    return outData;
  }

  /**
   * Nothing here
   */
  constructor() {
    // shrug
  }

  /**
   * Inits the data
   * @param {{eols:number, g:Array<string>, s:number, dl:boolean} | null} dataRef
   */
  init(dataRef) {
    this.dataReference = dataRef;

    OMT_Utils.mergeMissingObject(this.dataReference, MysteryGiftManager.getDefaultData());

    this._checkData();
  }

  /**
   * Returns the time of the end of last streak
   * @returns {number}
   */
  get endOfLastStreak() {
    return this.dataReference.eols;
  }

  /**
   * Sets the time of the end of last streak
   * @param {number} e
   */
  set endOfLastStreak(e) {
    this.dataReference.eols = e;
  }

  /**
   * Gets the state of the during level flag
   * @returns {Boolean}
   */
  get duringLevel() {
    return this.dataReference.dl;
  }

  /**
   * Sets the current streak
   * @returns {number}
   */
  get streak() {
    return this.dataReference.s;
  }

  /**
   * reset mystery streak progress
   * @param {boolean} keepStreak
   * @param {boolean} keepStreakEndTime
   */
  resetProgress(keepStreak, keepStreakEndTime) {
    this.dataReference.s = keepStreak ? this.dataReference.s : 0;
    this.dataReference.dl = false;
    this.dataReference.eols = keepStreakEndTime ? this.dataReference.eols : Date.now();
    if (!keepStreakEndTime) {
      G.saveState.setUserCooldown(MysteryGiftActiveTimerKey, '', 0);
    }
    this.save();
  }

  /**
   * Checks the state of the mystery gift
   */
  _checkData() {
    if (this.dataReference.s > 0 && this.dataReference.dl) {
      console.log('++ resetting mystery gift streak - duringLevel');
      this.resetProgress(false, false);
    }
    // gift over
    if (this.dataReference.s > 0 && this.getRemainingActiveTime() === 0) {
      console.log('++ resetting mystery gift streak - gift timer over');
      this.resetProgress(false, false);
    }
  }

  /**
   * get mystery gift current gift list
   * @returns {Array<SPECIAL_TYPES>}
   */
  getCurrentGifts() {
    const chosenGifts = this.dataReference.g.slice(0, Math.min(3, this.dataReference.s));
    const arr = [];
    for (const gift of chosenGifts) { // Loop through chosen gifts. These are short hand; 's', 'v', 'h', 'c'
      for (const giftKey in MysteryGiftSaveStateGifts) { /* eslint-disable-line guard-for-in */ // Loop through the const for the KEY
        const curGift = MysteryGiftSaveStateGifts[giftKey];
        if (gift === curGift) { // Are they the same, comparing short hand
          arr.push(SPECIAL_TYPES[giftKey]); // Array is filled with long string version that works with the board
          break;
        }
      }
    }
    return arr;
  }

  /**
   * check if the mystery gift is ready / available to the user
   */
  isModeReady() {
    if (!OMT.feature.getMysteryGiftUnlockByLevel(true)) { return false; }
    const lastTime = this.dataReference.eols;
    const cooldown = G.json.settings.mysteryGift.readyCooldownMin * 60 * 1000;
    return Date.now() - lastTime > cooldown;
  }

  /**
   * increment the mystery gift streak by 1 (indpeendent of regular win streak counter)
   * @returns {boolean}
   */
  increaseStreak() {
    if (!this.isModeReady()) return false;
    if (this.dataReference.s === 0) {
      Phaser.ArrayUtils.shuffle(this.dataReference.g);
      this.setGiftActiveTime();
    }

    if (this.dataReference.s < 4 && this.getRemainingActiveTime() >= 0) {
      this.setGiftActiveTime();
    }

    this.dataReference.s++;
    return true;
  }

  /**
   * finish a mystery gift streak
   * @param {Boolean} peak peak streak reached
   */
  finishStreak(peak) {
    if ((this.dataReference.s > 0 || peak) && OMT.feature.getMysteryGiftUnlockByLevel()) {
      console.log('++ resetting mystery gift streak - finished');
      this.resetProgress(false, false);
    }
  }

  /**
   * get the current mystery gift streak
   * @returns {number}
   */
  getCurrentStreak() {
    return this.dataReference.s;
  }


  /**
   * set cool down time for a user for mystery gifts
   * Formerly known as setGiftCooldown
   */
  setGiftActiveTime() {
    this.dataReference.eols = 0;
    G.saveState.setUserCooldown(MysteryGiftActiveTimerKey, '', G.json.settings.mysteryGift.giftCooldownMin * 60 * 1000);
  }

  /**
   * Get mystery gift remaining active time
   * Formerly known for getRemainingGiftCooldown
   * @returns {number} duration in milliseconds
   */
  getRemainingActiveTime() {
    return G.saveState.getUserCooldownRemaining(MysteryGiftActiveTimerKey, '');
  }

  /**
   * update the level for the mystery streak
   */
  markLevelBeginning() {
    this.dataReference.dl = true;
    this.save();
  }

  /**
   * mark mystery gift level as finished
   */
  markLevelFinished() {
    this.dataReference.dl = false;
    this.save();
  }

  /**
   * Deletes the session data
   */
  inGame_clearSessionData() {
    delete G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY];
  }

  /**
   * Creates session data for the in game board to track with
   */
  inGame_createSessionData() {
    G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY] = {
      mysteryGiftModePeak: false,
      mysteryBoostersInitialized: false,
      treasureHuntMode: false,
    };
  }

  /**
   * Checks if the mystery boosters have been initalized in the in game board yet
   * @returns {boolean}
   */
  inGame_hasMysteryGiftBeenInitialized() {
    return G.MYSTERYGIFT && G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY] && !G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY].mysteryBoostersInitialized;
  }

  /**
   * Sets that the mystery gift boosters are initalized
   * @param {boolean} b
   */
  set mysteryBoostersInitialized(b) {
    if (!G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY]) { return; }
    G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY].mysteryBoostersInitialized = b;
  }

  /**
   * Sets the mystery gift mode peak
   * @param {boolean} b
   */
  set mysteryGiftModePeak(b) {
    if (!G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY]) { return; }
    G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY].mysteryGiftModePeak = b;
  }

  /**
   * Returns the mystery gift mode peak
   * @returns {boolean}
   */
  get mysteryGiftModePeak() {
    if (!G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY]) { return false; }
    return G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY].mysteryGiftModePeak;
  }

  /**
   * Sets the treasure hunt mode
   * @param {boolean}
   */
  set treasureHuntMode(b) {
    if (!G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY]) { return; }
    G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY].treasureHuntMode = b;
  }

  /**
   * Returns the treasure hunt mode
   * @returns {boolean}
   */
  get treasureHuntMode() {
    if (!G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY]) { return false; }
    return G.saveState.sessionData[MYSTERY_GIFT_SESSION_DATA_KEY].treasureHuntMode;
  }

  /**
   * Turns off mystery gift then turns it back on again
   * @param {number} streak streak length
   * @param {number} timeLeft duration of mystery gift in seconds
   */
  debugActivateMysteryGift(streak, timeleft) {
    this.dataReference.s = streak || 0;
    this.dataReference.eols = 0;
    G.saveState.setUserCooldown(MysteryGiftActiveTimerKey, '', timeleft * 1000);
    this.dataReference.dl = false;
    this.save();
  }

  /**
   * Save is set externally in G.SaveState before it becomes relevant in this class
   */
  // save() {
  // }
}
