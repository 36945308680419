/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */

export const WINDOW_LAYER_NAMES = {
  Base: 'base',
  BelowHeaderPanel: 'belowHeaderPanel',
  AboveHighScorePanel: 'aboveHighScorePanel',
  OverlayLayer: 'overlayLayer',
  // Theres also "retryLevel" from window_levelfailed.js
};

export const WINDOW_CONSTANTS = {
  WorldVerticalOffset: -70,
};

let _instance; // keep track of last created instance

/**
 * The class for handling / displaying windows.
 */
export class WindowManager extends Phaser.Group {
  /**
   * get layer names
   * @returns {Object}
   */
  static get LayerNames() { return WINDOW_LAYER_NAMES; }

  /**
   * get constants
   * @returns {Object}
   */
  static get Constants() { return WINDOW_CONSTANTS; }

  /**
   * get the last created instance
   * @returns {WindowManager}
   */
  static getInstance() {
    return _instance;
  }

  /**
   * constructor
   * @param {Number} offsetH
   * @param {Number} offsetV
   */
  constructor(offsetH, offsetV) {
    super(game);

    this._queue = [];
    this.layers = {};
    this.activeWindows = [];
    this.fixedToCamera = true;
    this.prevLength = 0;

    this.offsetH = Math.floor(offsetH || 0);
    this.offsetV = Math.floor(offsetV || 0);

    G.sb('onScreenResize').add(this.resize, this);
    G.sb('onWindowOpened').add(this.cacheWindow, this);
    G.sb('onWindowClosed').add(this.onWindowClosed, this);
    G.sb('pushWindow').add(this.pushWindow, this);

    // keep track of last created instance
    _instance = this;
  }

  /**
   * add a windowing layer to the world
   * @param {string} name
   * @param {Object} options
   */
  addLayerToWorld(name, options) {
    if (this.hasLayer(name)) {
      console.warn('G.WindowMgr - Layer %s already exists!', name);
      return this.getLayer(name);
    }
    console.log(`Adding layer\nname: ${name}\noptions: ${JSON.stringify(options)}`);
    const newLayer = new Phaser.Group(game);
    newLayer.isLayer = true;
    newLayer.fixedToCamera = true;
    newLayer.options = options;
    newLayer.layerName = name;
    if (options && options.offset) {
      newLayer.cameraOffset.x = Math.floor(game.width * 0.5) + options.offset.x;
      newLayer.cameraOffset.y = Math.floor(game.height * 0.5) + options.offset.y;
    } else {
      newLayer.cameraOffset.x = Math.floor(game.width * 0.5);
      newLayer.cameraOffset.y = Math.floor(game.height * 0.5);
    }
    newLayer.windows = [];
    this.createOverlay(newLayer);
    this.layers[name] = newLayer;
    return this.layers[name];
  }

  /**
   * check if a layer exists
   * @param {string} name
   * @return {boolean}
   */
  hasLayer(name) {
    return this.layers[name] != null;
  }

  /**
   * get a layer object
   * @param {string} name
   * @return {Phaser.Group}
   */
  getLayer(name) {
    if (!this.layers[name]) {
      console.warn(`Layer ${name} did not exist`);
      return null;
    }
    return this.layers[name];
  }

  /**
   * create a overlay for blocking events
   * @param {*} parent
   */
  createOverlay(parent) {
    const fadeImg = G.GiftUI.Elements.Overlay();
    parent.fadeImg = fadeImg;
    fadeImg.alpha = 0;
    fadeImg.visible = false;
    parent.add(fadeImg);
  }

  /**
   * close all windows currently open
   * @param {Function} callback
   */
  closeAllWindows(callback) {
    G.sb('onAllWindowsClosed').addOnce(callback);
    this.activeWindows.forEach((window) => {
      window.closeWindow();
    });
  }

  /**
   * updates display stack when a window is closed
   */
  onWindowClosed() {
    // remove window from global list
    const windowClosed = this.activeWindows.pop();
    if (windowClosed.logData) {
      console.log(`Closing window: \n${windowClosed.logData}`);
    }

    // remove window from specific layer list
    // eslint-disable-next-line prefer-const
    for (let key in this.layers) {
      if (!this.hasLayer(key)) continue;
      const layerIndex = this.layers[key].windows.indexOf(windowClosed);
      if (layerIndex >= 0) { // remove window from layer list
        this.layers[key].windows.splice(layerIndex, 1);
      }
    }
    if (this._queue.length > 0 && this.activeWindows.length === 0) {
      const args = this._queue.shift();
      const windowName = args[0];

      const layerName = args[1];
      let layer;
      if (layerName) {
        layer = this.getLayer(layerName);
        if (!layer) {
          // error is already logged at this point
          layer = this.addLayerToWorld(G.WindowMgr.LayerNames.Base);
        }
      }
      this.createWindow(windowName, layer);
    } else if (this.activeWindows.length === 0) {
      G.sb('onAllWindowsClosed').dispatch();
    }
  }

  /**
   * Create a window instance. This supports both the legacy windows and the new Class style windows.
   * @param {Array.<*>} argsPassed
   * @param {Object} layer
   * @returns {Window}
   */
  createWindow(argsPassed, layer, logData) {
    const windowType = Array.isArray(argsPassed) ? argsPassed[0] : argsPassed;
    let window;
    if (Windows[windowType] === undefined) { // legacy windows. Everything is part of G.Window (LegacyWindow)
      window = new G.Window(argsPassed, layer);
    } else { // new Class style windows which extend Window
      const args = [layer];
      // if a arguement array was passed add them after the current arguements
      if (Array.isArray(argsPassed)) {
        for (let i = 1; i < argsPassed.length; i++) {
          args.push(argsPassed[i]);
        }
      }
      // construct window and pass the arguement array
      window = new Windows[windowType](...args);
    }
    window.windowType = windowType;
    window.logData = logData;
    return window;
  }

  /**
   * add a window to the screen and the active window list
   * @param {Window} win
   * @param {Object} layer
   */
  cacheWindow(win, layer) {
    if (!layer.isLayer) {
      win.parent.remove(win, false);
      if (this.activeWindows.length > 0 && this.activeWindows[this.activeWindows.length - 1].parent) {
        layer = this.activeWindows[this.activeWindows.length - 1].parent;
      } else {
        layer = this.getLayer(G.WindowMgr.LayerNames.Base);
        if (!layer) {
          // error is already logged at this point
          layer = this.addLayerToWorld(G.WindowMgr.LayerNames.Base);
        }
      }
      layer.add(win);
    }
    this.activeWindows.push(win);
    layer.windows.push(win);
    layer.fadeImg.visible = true;
    this.resize();
  }

  /**
   * push a window into the queue
   * @param {string} type
   * @param {boolean} unshift
   * @param {string} layerName
   * @param {boolean} force
   */
  pushWindow(type, unshift, layerName, force) {
    let data = '';
    try {
      data = JSON.stringify(type[1]);
    } catch (err) {
      data = 'Cannot read data';
    }
    // if the type is a string, then we know that all that's passed here is just the name of the window.
    const typeString = typeof (type) === 'string' ? type : type[0];
    let logData = `type:       ${typeString}`;
    // if data is length 3, then we know that type was passed as a string and data is just a single char of it plus two quotation marks
    if (data && data.length > 3) logData += `\ndata:       ${data}`;
    if (unshift) logData += `\nunshift:    ${unshift}`;
    if (layerName) logData += `\nlayerName:  ${layerName}`;
    if (force) logData += `\nforce:      ${force}`;
    console.log(
      `Pushing window! \n${logData}`,
    );
    if ((this._queue.length === 0 && this.activeWindows.length === 0) || force === true) {
      let layer;
      if (layerName) {
        layer = this.getLayer(layerName);
        if (!layer) {
          // error is already logged at this point
          layer = this.addLayerToWorld(G.WindowMgr.LayerNames.Base);
        }
      }
      this.createWindow(type, layer, logData);
    } else if (unshift) {
      this._queue.unshift([type, layerName]);
    } else {
      this._queue.push([type, layerName]);
    }

    // event for when a window is added to the display
    G.sb('onWindowPushed').dispatch(typeString);
  }

  /**
   * called when the queue is emptied
   */
  onQueueEmpty() {
    return new Promise((resolve) => {
      if (this._queue.length === 0 && this.activeWindows.length === 0) {
        resolve();
      } else {
        G.sb('onAllWindowsClosed').addOnce(resolve);
      }
    });
  }

  /**
   * true if no items are queued or visible
   */
  get isQueueEmpty() {
    return this._queue.length === 0 && this.activeWindows.length === 0;
  }

  /**
   * check if a specific window type is active
   * @param {string} windowType
   * @returns {boolean}
   */
  checkIfWindowTypeActive(windowType) {
    for (let i = 0; i < this.activeWindows.length; i++) {
      if (this.activeWindows[i].windowType === windowType) return true;
    }
    return false;
  }


  /**
   * on resize function
   */
  resize() {
    if (this.activeWindows.length > 0) {
      for (let i = 0; i < this.activeWindows.length; i++) {
        const curr = this.activeWindows[i].parent;
        if (!curr) continue;
        if (curr.options && curr.options.offset) {
          curr.cameraOffset.x = Math.floor(game.width * 0.5) + curr.options.offset.x;
          curr.cameraOffset.y = Math.floor(game.height * 0.5) + curr.options.offset.y;
        } else {
          curr.cameraOffset.x = Math.floor(game.width * 0.5) + this.offsetH;
          curr.cameraOffset.y = Math.floor(game.height * 0.5) + this.offsetV;
        }
        curr.fadeImg.clear();
        curr.fadeImg.beginFill(0x000000, 0.8);
        curr.fadeImg.drawRect(0, 0, game.width + 10, game.height + 10);
        curr.fadeImg.endFill();
      }
    }
  }

  /**
   * main update loop
   */
  update() {
    // fade out layer which no longer have windows
    // eslint-disable-next-line prefer-const
    for (let key in this.layers) {
      if (!this.hasLayer(key)) continue;
      const curr = this.layers[key];
      if (curr.windows.length === 0 && curr.fadeImg.visible) {
        curr.fadeImg.alpha = Math.max(0, curr.fadeImg.alpha - 0.2);
        curr.fadeImg.visible = (curr.fadeImg.alpha > 0);
      }
    }

    // fade in layers for active windows
    for (let i = 0; i < this.activeWindows.length; i++) {
      let activeWindowLayer = this.activeWindows[i].parent;
      if (!activeWindowLayer) {
        if (this.activeWindows[i].game) {
          activeWindowLayer = this.getLayer(G.WindowMgr.LayerNames.Base);
        } else continue;
      }
      if (!this.activeWindows[i].stopFade) {
        activeWindowLayer.fadeImg.alpha = Math.min(1, activeWindowLayer.fadeImg.alpha + 0.1);
        activeWindowLayer.fadeImg.visible = true;
      }
      activeWindowLayer.fadeImg.x = -activeWindowLayer.cameraOffset.x;
      activeWindowLayer.fadeImg.y = -activeWindowLayer.cameraOffset.y;
      // this.activeWindows[i].update();
    }
  }
}
// set global reference
G.WindowMgr = WindowManager;
