import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { MILLISECONDS_IN_DAY, MILLISECONDS_IN_MIN } from '@omt-components/Utils/TimeUtil';

/**
 * @typedef {object} AnnuitySaveObject
 * @property {Array<AnnuityDataObject>} ap [A]nuity [P]urchased
 */

/**
 * @typedef {object} AnnuityDataObject The individual data of annuity
 * @property {string} id The product ID that was bought
 * @property {number} lc [L]ast [C]laimed time. To calculate when to give it
 * @property {number} d [D]uration. How long this annuity will last for
 * @property {number} tdc [T]otal [D]ays [C]laimed.
 * @property {number} cpd [C]oins [P]er [D]ay
 */

const annuityResetTime = [0, 0, 0, 1]; // 0:0:0:1 of every day, GMT

export default class AnnuityManager {
  /**
   * Returns the default values for the Annuity
   * @returns {AnnuitySaveObject}
   */
  static getDefaultValues() {
    return {
      ap: [],
    };
  }

  /**
   * Returns information about an annuity based on product id and desc
   * @param {string} productId
   * @param {string} productDesc
   * @returns {{id:string, coin:number, days:number}}
   */
  static getAnnuityDetails(productId, productDesc) {
    const descSplit = productDesc.split(' ');
    if (descSplit.length > 0) {
      return {
        id: productId.substring('t#_'.length),
        coin: Number.parseInt(descSplit[1]),
        days: Number.parseInt(descSplit[descSplit.length - 2]),
      };
    }
    OMT_Utils.stylizedLog('[Annuity] Given product information is not valid', 0xFF0000);
    return {};
  }

  constructor() {
    // Nothing?
  }

  /**
   * Init!
   * @param {AnnuitySaveObject} dataRef
   */
  init(dataRef) {
    const today = new Date(OMT.connect.getServerTimestampSync());
    today.setUTCHours(annuityResetTime[0], annuityResetTime[1], annuityResetTime[2], annuityResetTime[3]);
    /** @private @type {number} 0:0:0:1 of today's date, in local time! */
    this._todaysTime = today.getTime() + (today.getTimezoneOffset() * MILLISECONDS_IN_MIN);

    this.dataReference = dataRef;
    OMT_Utils.mergeMissingObject(this.dataReference, AnnuityManager.getDefaultValues());

    this._removeClaimedPackages();
  }

  /**
   * Removes fully claimed packages
   */
  _removeClaimedPackages(saveNow = false) {
    const removedPacks = [];
    for (const adObj of this.dataReference.ap) {
      if (adObj.tdc >= adObj.d) { // Greater than obv is a problem...
        removedPacks.push(adObj);
      }
    }

    if (removedPacks.length > 0) {
      for (const rmrPack of removedPacks) {
        this.dataReference.ap.splice(this.dataReference.ap.indexOf(rmrPack), 1);
      }
      saveNow = true;
    }
    if (saveNow) {
      this.save();
    }
  }

  /**
   * Returns the number of days the given annuity pack hasn't been claimed for
   * @returns {Array<AnnuityDataObject>}
   */
  getNumberOfDaysSinceLastClaimed(targetPackId = null) {
    const targetPack = this.dataReference.ap.find((adObj) => adObj.id === targetPackId);
    if (targetPack) {
      const differenceInDay = Math.floor(this.getTimeUntilReset(targetPack, true) / MILLISECONDS_IN_DAY);
      return Math.max(0, Math.min(differenceInDay, targetPack.d - targetPack.tdc));
    }
    return 0;
  }

  /**
   * Claims the amount of annuity the player should get based on how many days they haven't gotten it yet
   * @param {string} packageid
   * @param {Function} particleFunc
   */
  claimAnnuityForMissingDays(packageid, particleFunc) {
    const targetPack = this.dataReference.ap.find((adObj) => adObj.id === packageid);
    if (!targetPack) {
      console.log(`[Annuity] Unable to find Annuity ${packageid}. Not claiming`);
      return;
    }
    const daysLastClaimed = this.getNumberOfDaysSinceLastClaimed(targetPack.id);
    if (daysLastClaimed <= 0) {
      console.log(`[Annuity] Days since last claim for ${packageid} is 0.`);
      return;
    }

    // Cash in
    const totalCoins = Math.floor(targetPack.cpd * daysLastClaimed);
    if (particleFunc) {
      particleFunc(totalCoins);
    } else {
      G.saveState.changeCoins(totalCoins);
    }
    targetPack.lc = this._todaysTime;
    targetPack.tdc += daysLastClaimed;
    this._removeClaimedPackages(true); // Save happens in here
  }

  /**
   * Adds newly bought annuity to user save.
   * @param {string} packageId
   * @param {number} numberOfDays
   * @param {number} coinsPerDay
   */
  addNewAnnuity(packageId, numberOfDays, coinsPerDay) {
    const data = {
      id: packageId,
      lc: this._todaysTime - MILLISECONDS_IN_DAY - 1, // Make sure they're able to claim now
      d: numberOfDays,
      tdc: 0,
      cpd: coinsPerDay,
    };
    this.dataReference.ap.push(data);
    this.save();
  }

  /**
   * Returns the time left until the annuity deal resets
   * @param {AnnuityDataObject} annuityDealPack
   */
  getTimeUntilReset(annuityDealPack, useResetTime = true) {
    const startTime = useResetTime ? this._todaysTime : new Date(OMT.connect.getServerTimestampSync());
    const lastClaimedDay = new Date(annuityDealPack.lc);
    return startTime - lastClaimedDay.getTime();
  }

  /**
   * Returns true if the user has at least 1 annuity package going on
   * @returns {boolean}
   */
  isThereAnnuityPackage() {
    return this.dataReference.ap.length > 0;
  }

  /**
   * Returns true if the user has at least 1 annuity pack to claim
   * @returns {boolean}
   */
  isThereAnnuityToClaim() {
    return Boolean(this.dataReference.ap.find((adObj) => this.getNumberOfDaysSinceLastClaimed(adObj.id) > 0));
  }

  /**
   * Returns the annuity data object if it exists
   * @param {string} annuityPackName
   * @returns {(AnnuityDataObject|undefined)}
   */
  getAnnuityPack(annuityPackName) {
    return this.dataReference.ap.find((adObj) => adObj.id === annuityPackName);
  }
}
