G.ScrollInput = function (x, y, width, height) {
  G.Image.call(this, x, y, null, 0);

  this.resizeArea(width, height);

  this.activePointer = null;

  this.active = false;

  this.scrollSettings = {
    INERTIA_FACTOR: 0.2,
    ACCELERATE_FACTOR: 0.95,
    MIN_VELOCITY_THRESHOLD: 1
  };

  this._inputData = {
    x: createAxisInfo(),
    y: createAxisInfo(),
  };

  this.signals = {
    scroll: new Phaser.Signal(),
    postUpdate: new Phaser.Signal()
  };

  this._initCustomInput();

  return;

  function createAxisInfo() {
    const axisInfo = {
      position: null,
      velocity: 0,
      delta: 0,
    };
    return axisInfo;
  }
};

G.ScrollInput.prototype = Object.create(G.Image.prototype);

// Getters

// TODO: add geters for values

G.ScrollInput.prototype.isDuringDrag = function () { return this.active;                };
G.ScrollInput.prototype.getVelX      = function () { return this._inputData.x.velocity; };
G.ScrollInput.prototype.getVelY      = function () { return this._inputData.y.velocity; };
G.ScrollInput.prototype.getDeltaX    = function () { return this._inputData.x.delta;    };
G.ScrollInput.prototype.getDeltaY    = function () { return this._inputData.y.delta;    };

// Custom input

G.ScrollInput.prototype._initCustomInput = function (width, height) {
  const dragHandler = G.Input.createCustomInputDragHandler();
  dragHandler.inputDownSignal.add(this.setVelToZero, this);
  dragHandler.dragStartSignal.add(this.startDragging, this);
  dragHandler.dragStopSignal.add(this.stopDragging, this);
  dragHandler.dragCancelSignal.add(this.stopDragging, this);

  G.Input.initializeCustomInput(this);
  this.customInput.addHandler(dragHandler);
};

G.ScrollInput.prototype.startDragging = function (pointer) {
  this.activePointer = pointer;
  this.active = true;
  this._inputData.x.position = pointer.worldX;
  this._inputData.y.position = pointer.worldY;
};

G.ScrollInput.prototype.stopDragging = function () {
  this.activePointer = null;
  this.active = false;
  this._inputData.x.delta = 0;
  this._inputData.y.delta = 0;
};

//

G.ScrollInput.prototype.resizeArea = function (width, height) {
  this.hitArea = new Phaser.Rectangle(0, 0, width, height);
};

G.ScrollInput.prototype.setVelToZero = function () {
  this._inputData.x.vel = 0;
  this._inputData.y.vel = 0;
};

G.ScrollInput.prototype.update = function () {
  if (this.activePointer) {
    // Workaround for cases when input up happened after element's inputEnabled was set to false
    if (!this.activePointer.isDown) {
      this.stopDragging();
    } else {
      this._updateAxisInput(this._inputData.x, this.activePointer.worldX);
      this._updateAxisInput(this._inputData.y, this.activePointer.worldY);
      this.signals.scroll.dispatch();
    }
  } else {
    this._updateAxisDeceleration(this._inputData.x);
    this._updateAxisDeceleration(this._inputData.y);
  }
  this.signals.postUpdate.dispatch();
};

G.ScrollInput.prototype._updateAxisInput = function (axisInfo, newPosition) {
  axisInfo.delta = axisInfo.position - newPosition;
  axisInfo.position = newPosition;
  axisInfo.velocity = G.lerp(axisInfo.delta, axisInfo.velocity, this.scrollSettings.INERTIA_FACTOR);
};

G.ScrollInput.prototype._updateAxisDeceleration = function (axisInfo) {
  axisInfo.velocity *= this.scrollSettings.ACCELERATE_FACTOR;
  if (Math.abs(axisInfo.velocity) < this.scrollSettings.MIN_VELOCITY_THRESHOLD) {
    axisInfo.velocity = 0;
  }
};
