/* eslint-disable no-unused-vars */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-param-reassign */

import { ORIENTATION } from '../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../States/Scaling/GameScaleController';

/**
 * Window class refactor of legacy G.Window to ES 6 class
 * @param {string} type
 * @param {Phaser.Group} [layer]
 */
export class Window extends Phaser.Group {
  /**
   * constructor
   * @param {Object} parent
   * @param {boolean} isFullscreen
   */
  constructor(parent, isFullscreen = false) {
    super(game, parent);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this.onClose = new Phaser.Signal();
    this.onFinishedEnter = new Phaser.Signal();

    this.buttonsList = [];
    this.state = game.state.getCurrentState();

    this._timedEvents = [];
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._setOpenWindowAnimation();

    G.sb('onWindowOpened').dispatch(this, this.parent);
    G.Utils.cleanUpOnDestroy(this, G.sb('onStateChange').add(this.lockInput, this));
  }

  /**
   * animate the window in
   * @param {Boolean} animateScale (optional)
   */
  _setOpenWindowAnimation(animateScale = true) {
    this.scale.y = OMT.systemInfo.orientation === ORIENTATION.horizontal && !this.isFullscreen
      ? this._gameScale
      : 1;

    if (!this.NOFADEINOUT) {
      if (animateScale) {
        this.scale.x = 0;
        const finalScaleX = OMT.systemInfo.orientation === ORIENTATION.horizontal && !this.isFullscreen
          ? this._gameScale
          : 1;
        game.add.tween(this.scale).to({ x: finalScaleX }, 300, Phaser.Easing.Elastic.Out, true);
      }
      this.alpha = 0;
      game.add.tween(this).to({ alpha: 1 }, 200, Phaser.Easing.Sinusoidal.In, true)
        .onComplete.add(() => { this.onFinishedEnter.dispatch(); });
    } else {
      this.onFinishedEnter.dispatch();
    }
  }

  /**
   * close window
   * @param {Function} callback
   * @param {Object} context
   * @param {Number} scaleAmount (optional) override scale effect
   */
  closeWindow(callback, context, scaleAmount = 1.5) {
    if (this.closing) return;

    this.lockInput();

    this.closing = true;
    this.onClose.dispatch();

    if (this.boosterHighlight) {
      this.boosterHighlight.inputEnabled = false;
      game.add.tween(this.boosterHighlight.shine).to({ alpha: 0 }, 800, Phaser.Easing.Sinusoidal.Out, true).onComplete.add(() => {
        this.boosterHighlight.destroy();
      }, this);
    }

    game.add.tween(this.scale).to({ x: scaleAmount }, 200, Phaser.Easing.Sinusoidal.In, true);
    game.add.tween(this).to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.Out, true).onComplete.add(() => {
      G.sb('onWindowClosed').dispatch();
      this.destroy();
      if (callback) {
        callback.call(context || false);
      }
    }, this);
  }

  /**
   * add a background image
   * @param {Object} image Phaser.Image
   */
  addBackground(image) {
    image = image || 'popup';
    this.bg = G.makeImage(0, 0, image, 0.5, this);
  }

  /**
   * add a 9 slice generic background
   * @param {*} height
   */
  addGeneric9SliceBackground(width = 639, height = 600) {
    const whitespace = G.OMTsettings.elements.windowNineSlice.whiteSpace;
    const offsetWidth = Math.round((whitespace.left + whitespace.right) / 2);
    const offsetHeight = Math.round((whitespace.top + whitespace.bottom) / 2);
    const bg = new PhaserNineSlice.NineSlice(0, 0, 'popups', 'popup_generic', width + offsetWidth, height + offsetHeight, G.OMTsettings.elements.windowNineSlice.slice);
    bg.anchor.set(0.5);
    this.addChildAt(bg, 0);
    return bg;
  }

  /**
   * add the close button
   * @param {number} x
   * @param {number} y
   * @param {Function} callback
   * @param {Object} context
   * @param {Function} customClickFunction If this is specified, it will be fired instead of closeWindow
   * @param {string} asset (optional)
   */
  addCloseButton(x, y, callback, context, customClickFunction, asset = 'btn_x') {
    callback = callback || false;
    context = context || this;
    this.closeButton = new G.Button(x || 250, y || -270, asset, () => {
      if (customClickFunction) customClickFunction();
      else {
        this.closeWindow(callback, context);
      }
    }, this);

    this.registerButtons(this.closeButton);
  }

  /**
   * add the title text
   * @param {string} text
   */
  addTitle(text, style = G.OMTsettings.global.window.title.textStyle) {
    this.add(
      new G.Text(
        0, -270,
        OMT.language.getText(text),
        style,
        0.5,
        350,
      ),
    );
    this.add(this.titleText);
  }

  /**
   * set the title text
   * @param {string} text
   */
  setTitle(text) {
    this.titleText.setText(OMT.language.getText(text));
  }

  /**
   * register buttons to the window
   * @param {Object} obj not used ?
   */
  registerButtons(obj) {
    for (let i = 0; i < arguments.length; i++) {
      this.buttonsList.push(arguments[i]);
      this.add(arguments[i]);
    }
  }

  /**
   * force window to fit on screen
   */
  forceFitOnScreen() {
    const bounds = this.getBounds(game.stage);
    if (bounds.y < 0) {
      const newHeight = bounds.height + (bounds.y * 2);
      // enforce max scale down of 50%
      if (newHeight >= bounds.height / 2) {
        const ratio = newHeight / this.height;
        this.height = newHeight;
        this.width *= ratio;
      }
    }
  }

  /**
   * lock user input
   */
  lockInput() {
    this.buttonsList.forEach((child) => {
      child.input.enabled = false;
    });
  }

  /**
   * unlock user input
   */
  unlockInput() {
    this.buttonsList.forEach((child) => {
      child.input.enabled = true;
      child.input.useHandCursor = true;
    });
  }

  /**
   * shows a semi transperant black overlay below the FB switch game UI, and prevent clicks on UI in background
   */
  showOverlay() {
    this.overlay = game.add.graphics();
    this.overlay.beginFill(0x000000, 0.7);
    this.overlay.drawRect(0, 0, game.width, game.height);
    this.overlay.inputEnabled = true;
  }

  /**
   * hide the semi transparent overlay
   */
  hideOverlay() {
    this.overlay.destroy();
  }

  /**
   * registered a timed event for auto removal when window destroyed.
   * pass in instance created by game.time.events.add().
   * @param {TimedEvent} timedEvent
   */
  _registerTimedEvent(timedEvent) {
    this._timedEvents.push(timedEvent);
  }

  /**
   * destroy timed events pushed to this._timedEvents.
   */
  _destroyTimedEvents() {
    for (const timedEvent of this._timedEvents) {
      try {
        game.time.events.remove(timedEvent);
      } catch (error) {
        //
      }
    }
    this._timedEvents.length = 0;
  }

  /**
   * destruction method
   */
  destroy() {
    this._destroyTimedEvents();
    super.destroy();
    this.onClose.dispose();
    this.onFinishedEnter.dispose();
  }

  /**
   * @returns {boolean} is the window fullscreen? (for low res landscape purposes)
   */
  get isFullscreen() {
    return false;
  }
}

/**
 * hack for legacy G.Window so code doesnt break
 */
class LegacyWindow extends Window {
  /**
   * constructor
   * @param {*} type
   * @param {Object} parent
   */
  constructor(type, parent) {
    super(parent);

    // apply arguements to startup function for window
    if (type.constructor === Array) {
      this[type[0]].apply(this, type.slice(1));
    } else {
      this[type].apply(this, Array.prototype.slice.call(arguments, 1));
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.base = Window;

// legacy reference for old windows
G.Window = LegacyWindow;
