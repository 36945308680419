if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};

G.Animations.getTHFailGingyAnimationHide = function getTHFailGingyAnimationHide() {

  return { animate: animate };

  function animate(game, target) {
    var speakBubble = G.Utils.find1stObjectWithName(target, "speakBubble");
    var gingyCharacter = G.Utils.find1stObjectWithName(target, "gingyCharacter");

    if (gingyCharacter) {
      game.add.tween(gingyCharacter)
        .to({y: 300}, 100, Phaser.Easing.Sinusoidal.In, true, 100);
    } 

    if (speakBubble) {
      game.add.tween(speakBubble.scale)
        .to({x: 0, y: 0}, 100, Phaser.Easing.Sinusoidal.Out, true);
    }
  }
}
