/* eslint-disable func-names */

import Window_Level from './Window_level';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { SagaMapPromo } from './Tournament/SagaMapPromo';
import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import { INTERSTITIAL_RULES } from '../../Services/OMT/ads/OMT_InterstitialAdRules';

export const DISPLAY_STATES = {
  CONTINUE: 'continue',
  RETRY: 'retry',
};

/**
 * Class for pre-level screen for weekly tournament
 */
class Window_Tournament extends Window_Level {
  /**
   * constructor
   * @param {Object} parent
   * @param {number} highScore
   * @param {boolean} hideCloseButton (optional) hide the close button
   * @param {boolean} showSagaPromo (optional) show saga map promo
   * @param {string} displayState (optional) display state to force from DISPLAY_STATES
   */
  constructor(parent, highScore, hideCloseButton = false, showSagaPromo = false, displayState = null) {
    super(parent);

    const isGameState = this._isGameState = game.state.current === 'Game';

    this._highScore = highScore;
    this._showSagaPromo = showSagaPromo;

    // set the display state based on scene or passed override
    this._displayState = displayState || (isGameState ? DISPLAY_STATES.RETRY : DISPLAY_STATES.CONTINUE);

    // first time user bt opt-in flags
    const ftuBotOptIn = OMT.feature.getFTUBotOptInAtTournament();
    this._checkBotOptInOnClose = ftuBotOptIn;
    this._checkBotOptInOnRetry = ftuBotOptIn && isGameState;

    this._initTournamentWindowButtons();
    this._initHighScoreDisplay();

    // hide close button for FTUE users on first viewing
    if (hideCloseButton) this._closeButton.visible = false;

    // force a update of players in the tournament
    OMT.platformTournaments.updateContextPlayerCount();

    G.saveState.setTournamentPromoSeen();

    // shift the UI for the game state
    if (isGameState) this.y -= 50;
  }

  /**
   * Manage the mode tracker
   */
  _manageMode() {
    if (game.state.getCurrentState().mode === LevelType.NONE) { // Game state will manage but World state does not
      game.state.getCurrentState().mode = LevelType.TOURNAMENT;
    }
    this.gameMode = LevelType.TOURNAMENT;
  }

  /**
   * setup level data
   */
  _manageData() {
    OMT_VILLAINS.setLevelType(LevelType.TOURNAMENT);
    G.lvlData = OMT.platformTournaments.getTournamentLevelData();
    G.lvlNr = '--';
    this.levelData = G.lvlData;
  }

  /**
   * init the layout
   * @param {string} headerTxt The text the header should have
   */
  _initLayout(headerTxt) {
    headerTxt = headerTxt || `${OMT.language.getText('Tournament')}`;
    this._extraContainer = new Phaser.Group(game, this); // For some reason calling this.toLocal returns NaN but this.Group.toLocal is fine
    this._levelContainer = new Phaser.Group(game, this._extraContainer);
    this.bg = G.makeImage(0, 0, 'popup_background_2', 0.5, this._levelContainer);

    // banner for normal / hard level
    this._blue_banner = G.makeImage(
      0, -320,
      'tournament_header',
      0.5,
      this._levelContainer,
    );

    // level title text
    this._levelTxt = new G.Text(0, -300, (headerTxt), {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, 330);
    this._levelContainer.add(this._levelTxt);
  }

  /**
   * init task UI
   */
  _initTasks() {
    this._taskBg = G.makeImage(0, 15, 'popup_bigtext_backgr', 0.5, this._levelContainer);
    this._taskTxt = new G.Text(0, -40, `${OMT.language.getText('Task')}:`, {
      style: 'font-blue',
      fontSize: '45px',
    }, 0.5, 380);
    this._levelContainer.add(this._taskTxt);

    // highscore task
    const taskText = OMT.language.getText('Best score in %moves% moves').replace('%moves%', this.levelData.moves);
    this._levelContainer.add(new G.Text(0, 18, taskText, {
      style: 'font-blue',
      fontSize: '50px',
    }, 0.5, 380));
  }

  /**
   * init the highscore display
   */
  _initHighScoreDisplay() {
    this._scoreBg = G.makeImage(0, -105, 'popup_bigtext_backgr', 0.5, this._levelContainer);
    const scoreLabelText = this._displayState === DISPLAY_STATES.RETRY ? 'Score' : 'Best score';
    this._scoreTxt = new G.Text(0, -160, `${OMT.language.getText(scoreLabelText)}:`, {
      style: 'font-blue',
      fontSize: '45px',
    }, 0.5, 380);
    this._levelContainer.add(this._scoreTxt);

    // highscore task
    this._highScoreField = new G.Text(0, -102, this._highScore, {
      style: 'font-blue',
      fontSize: '50px',
    }, 0.5, 380);
    this._levelContainer.add(this._highScoreField);
  }

  /**
   * init window buttons
   */
  _initTournamentWindowButtons() {
    const continueClickFunc = () => {
      this._checkInterstitialBeforeLeaving(this._onContinueClick.bind(this));
    };
    super._initButtons(BUTTONCOLOURS.green, continueClickFunc.bind(this), false);
    if (this._displayState === DISPLAY_STATES.RETRY) {
      // relabel 'continue' button as 'retry'
      this._continueBtn.setText(OMT.language.getText('Retry'));
      this._continueBtn.resize(250, 100);

      const isFinalLevel = G.saveState.getLastPassedLevelNr() >= G.json.levels.order.length;
      if (!isFinalLevel) { // show button to go to saga map
        this._initPlaySagaButton();
        this._continueBtn.x -= 100;
        this._toSagaBtn.x += 150;
        // show the play saga map tutorial if required
        if (this._showSagaPromo) this._initSagaMapPromoTutorial();
      }
    }
  }

  /**
   * init the play saga button
   */
  _initPlaySagaButton() {
    const playSagaMapFunc = () => {
      this._checkInterstitialBeforeLeaving(this._onPlaySagaClick.bind(this));
    };
    this._toSagaBtn = new OMT_UI_SquareButton(0, 300, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Play Saga'),
        textStyle: { style: 'font-white', fontSize: 48 },
      },
      options: {
        clickFunction: {
          onClick: playSagaMapFunc,
        },
      },
    });
    this.registerButtons(this._toSagaBtn);
    this._levelContainer.add(this._toSagaBtn);
  }

  /**
   * init booster and booster UI
   */
  _initBoosters() {
    super._initBoosters(100, 'tournament');
  }

  /**
   * intialize tutorial for playing the saga map
   */
  _initSagaMapPromoTutorial() {
    this._sagaMapPromo = new SagaMapPromo(0, 0, G.saveState.getLevelRetries('tournament'));
    this.add(this._sagaMapPromo);

    // set button pulse states
    this._continueBtn.stopPulse(1);
    this._toSagaBtn.pulse(1.1);

    // hide level leaderboard
    G.sb('hideHighscoreBoard').dispatch();
  }

  /**
   * close the level, but first check the botOptIn if applicable
   */
  async _closeLevel() {
    // if (this._checkBotOptInOnClose) await OMT.bots.checkStateAndAttemptSubcribe();
    this._checkInterstitialBeforeLeaving(super._closeLevel.bind(this));
  }

  /**
   * on continue / retry button clicked
   */
  async _onContinueClick() {
    // check bot opt-in for first time users if feature enabled
    // if (this._checkBotOptInOnRetry) await OMT.bots.checkStateAndAttemptSubcribe();
    // retry level
    const levelData = {
      debugMode: false,
      startBoosters: super._assembleLevelBoosters(100),
      tournamentLvl: this.levelData,
      preLevelTrackingData: {},
      prevHighScore: this._highScore,
    };
    G.sb('onStateChange').dispatch('Game', levelData);

    G.saveState.startChallenge();
    G.saveState.setTournamentLastPlayed();
    if (game.state.current === 'World') {
      G.saveState.resetTournamentRetries();
    }
  }

  /**
   * Interscept PlaySagaMap to check for interstitials
   */
  _checkInterstitialBeforeLeaving(func) {
    if (this._isGameState) {
      this.state.endPointCheckForAds(func, INTERSTITIAL_RULES.TOURNAMENT_WIN);
    } else {
      func();
    }
  }

  /**
   * When the Play Saga button is clicked on the game screen!
   */
  async _onPlaySagaClick() {
    // check bot opt-in for first time users if feature enabled
    // if (this._checkBotOptInOnRetry) await OMT.bots.checkStateAndAttemptSubcribe();

    const highestLvlIndex = G.saveState.getLastPassedLevelNr();

    // Determine how the player should go to their highest unlocked level:
    // New user: Directly (to level 1 most likely)
    // < Level 11: Directly
    // >= Level 11: Via Saga Map
    if (highestLvlIndex < 10) {
      // Mark tutorials from Levels 1-6 as seen (Level 2 has no tutorial)
      const tutorialOverrides = ['1', '3', '4', '5', '6'];
      const { finishedTutorials } = G.saveState.data;
      const newFinishedTutorials = new Set([...finishedTutorials, ...tutorialOverrides]);
      G.saveState.data.finishedTutorials = [...newFinishedTutorials];
      G.saveState.save();

      // FTUE flow
      G.lvlNr = highestLvlIndex;
      G.sb('onStateChange').dispatch('Game', { lvlIndex: highestLvlIndex });
    } else {
      G.sb('onStateChange').dispatch('World', null, () => { // Normal flow
        const nextLevelGated = G.saveState.getLastPassedLevelNr() >= G.saveState.gateManager.getNextGateLevel();
        if (!nextLevelGated) {
          // show next level window if not blocked by a gate
          // AND if the user has enough lives
          if (G.saveState.getLives() > 0 || G.saveState.getUnlimitedLivesSec() > 0) {
            G.sb('pushWindow').dispatch(['level', highestLvlIndex], false, G.WindowMgr.LayerNames.Base);
          }
        } else {
          game.state.getCurrentState().map.externallyOpenGate();
        }
      });
    }
  }

  _initButtons() { } // disabled, super will be called by _initTournamentWindowButtons()

  _initStars() { } // disabled

  _initFriendshipChestBanner() {} // disabled

  _setupTournamentPromoButton() {} // disabled

  _setupPostcardEvent() {} // disabled

  _addMysteryGiftHeader() {} // disabled
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.tournament = Window_Tournament;
