/* eslint-disable prefer-spread */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
if (typeof G === 'undefined') G = {};

(function GStorageUtilsIIFE() {
  G.StorageUtils = {
    setObjProp,
    getObjProp,
    filterAndMap,
    unique,
    addUnique,
    moveArrayElementToBeFirst,
    createDynamicMap,
    createCache,
    createRingBuffer,
  };

  function setObjProp(obj, prop, val) {
    let currentObj = obj;
    if (typeof prop === 'string') {
      prop = prop.split('.');
    }

    try {
      for (let i = 0; i < prop.length - 1; i++) {
        currentObj = currentObj[prop[i]];
      }
      currentObj[prop[prop.length - 1]] = val;
    } catch (e) {
      return null;
    }
  }

  function getObjProp(obj, prop) {
    let current = obj;
    if (typeof prop === 'string') {
      prop = prop.split('.');
    }

    try {
      for (let i = 0; i < prop.length; i++) {
        current = current[prop[i]];
      }
    } catch (e) {
      return undefined;
    }

    return current;
  }

  function filterAndMap(array, func) {
    return array.reduce(
      (acc, element, index, array) => {
        let skip = false;
        const mapped = func(skipFunc, element, index, array);
        if (!skip) acc.push(mapped);
        return acc;
        function skipFunc() { skip = true; }
      },
      [],
    );
  }

  function unique(array) {
    const result = [];
    array.forEach((elem) => {
      if (result.indexOf(elem) === -1) {
        result.push(elem);
      }
    });
    return result;
  }

  function addUnique(array, element) {
    if (array.indexOf(element) === -1) {
      array.push(element);
    }
  }

  function moveArrayElementToBeFirst(array, element) {
    const index = array.indexOf(element);
    if (index > 0) {
      array.unshift(array.splice(index, 1)[0]);
      return true;
    }
    return false;
  }

  function createDynamicMap(createElementFunc) {
    let counter = 0;

    const map = {};

    const createSignal = new Phaser.Signal();

    return {
      getValue,
      setValue,
      hasKey,
      removeKey,
      getKeys,
      forEachKeyValuePair,
      createSignal,
    };

    function getValue(key) {
      if (hasKey(key)) {
        return map[key];
      }
      const oldCounterValue = counter;
      ++counter;
      const value = createElementFunc(key, oldCounterValue);
      map[key] = value;
      createSignal.dispatch(key);
      return value;
    }

    function setValue(key, value) {
      map[key] = value;
    }

    function hasKey(key) {
      return map.hasOwnProperty(key);
    }

    function removeKey(key) {
      delete map[key];
    }

    function getKeys() {
      return Object.keys(map);
    }

    function forEachKeyValuePair(func) {
      Object.keys(map).forEach((key) => { func(key, map[key]); });
    }
  }

  //

  function createCache(optGetKeyFromArgsFunc) {
    const getKeyFromArgsFunc = optGetKeyFromArgsFunc || defaultCacheKeyFunc;

    let cache = {};

    return {
      has,
      get,
      set,
      clear,
    };

    function has(/* arguments */) {
      const key = getKeyFromArgsFunc.apply(null, arguments);
      return cache.hasOwnProperty(key);
    }

    function get(/* arguments */) {
      if (has.apply(null, arguments)) {
        const key = getKeyFromArgsFunc.apply(null, arguments);
        return cache[key];
      }
      return null;
    }

    function set(data /* ...rest */) {
      const rest = Array.prototype.slice.call(arguments, 1);
      const key = getKeyFromArgsFunc.apply(null, rest);
      cache[key] = data;
      return data;
    }

    function clear() {
      cache = {};
    }
  }

  function defaultCacheKeyFunc(key) { return key; }

  //

  function createRingBuffer(size) {
    const array = [];
    let indexOfHead = 0;

    return {
      push,
      forEach,
      getActualSize,
    };

    function push(value) {
      if (array.length < size) {
        array.push(value);
      } else {
        array[indexOfHead] = value;
        indexOfHead = (indexOfHead + 1) % size;
      }
    }

    function getActualSize() {
      return array.length;
    }

    function forEach(action) {
      for (let i = 0; i < array.length; ++i) {
        const index = (indexOfHead + i) % size;
        action(array[index], i);
      }
    }
  }
}());
