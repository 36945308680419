G.Helpers = G.Helpers || {};

/**
* @param {object} config
* @param {number} config.width
* @param {number} config.height
* @param {number} config.color
* @param {number} config.alpha
* @param {(number|Array)} config.anchor
*/

G.Helpers.createRectangleGraphics = function (config) {
  const gfx = game.add.graphics();

  const width = G.Utils.defined(config.width, 500);
  const height = G.Utils.defined(config.height, 300);
  const color = G.Utils.defined(config.color, 0x000000);
  const alpha = G.Utils.defined(config.alpha, 0.5);

  let anchor = [0, 0];
  if (Array.isArray(config.anchor)) {
    anchor = config.anchor;
  } else if (typeof config.anchor === 'number') {
    anchor = [config.anchor, config.anchor];
  }

  gfx.beginFill(color, alpha);
  gfx.drawRect(anchor[0] * width * -1, anchor[1] * height * -1, width, height);

  if (config.inputEnabled) {
    gfx.inputEnabled = true;
  }

  return gfx;
};
