/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
/**
 * Welcome to the map chest data manager that is supposed to only affect the map chest data
 * that is in the save state.
 *
 * This replaces some functionality mapChestMgr was supposed to do.
 * mapChestMgr is still here just so migration code can happen.
 *
 * Other files you might want to look at if its about map chests:
 * World2.js
 * WorldMap2.js
 * WorldMapSagaMapChestEntry.js
 * mapChestMgr.js
 *
 * @author Sandra Koo
 */

import WorldMap2_Util, { interactionType, WorldMapTwoMapInteractionLimit } from '../../../../Elements/WorldMap2/WorldMap2_Util';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { MILLISECONDS_IN_DAY } from '@omt-components/Utils/TimeUtil';

export const SAGA_MAP_CHEST_MANAGER_DATA_KEY = 'saga-map-chest-Manager';

export default class MapChestData {
  /**
   * The object is created in saveState. This class modifies the object reference.
   *
   * @param {Object} data
   */
  init(data) {
    this.dataReference = data;

    const defaultData = {
      unOpenedChests: [],
      chestSeed: 0,
    };

    /**
     * unOpenedChest is an array of objects = {
     *  i: number, // i = index or level
     *  sr: number, // sr = star requirement // Old now
     * }
     */
    OMT_Utils.mergeMissingObject(this.dataReference, defaultData);

    if (G.firstTime && !OMT.envData.isRestoreProgressPayload) { // If the user is playing the game for the first time, this will generate chests before the first gate
      this.generateSGTwoChests();
      this.save();
    }
  }

  /**
   * Finds the map chest that is nearest to the given level
   * @param {number} level
   * @returns {{i:number, x:number, y:number, sr:number}} Chest Data
   */
  getMapChestNearestToLevel(level) {
    const starReqAtChangePoint = this.calculateStarsAtFormulaChangePoint();
    const rtrChest = this.dataReference.unOpenedChests.find((chest) => {
      if (chest.sr) {
        const chestLevel = this.approximateLevelFromStars(chest.sr, starReqAtChangePoint);
        return Math.abs(chestLevel - level) <= 3;
      }
      if (chest.i) {
        return chest.i === level;
      }
      return false;
    });
    return rtrChest;
  }

  /**
   * Returns all opened chest's unlock level and not by star rating
   * @returns {Array<number>}
   */
  getAllEligibleUnopenedChests() {
    const curStars = G.saveState.getAllStars();
    const arr = [];
    for (const chest of this.dataReference.unOpenedChests) {
      if (chest.i && curStars >= this.calculateStarsFromLevel(chest.i)) {
        arr.push(chest.i);
      }
    }
    return arr;
  }

  /**
   * Generates chest at specific levels
   * @param {Array<number>} levelUnlock
   */
  generateSGTwoChests(levelUnlock = []) {
    if (levelUnlock.length === 0) {
      levelUnlock = this.determineUnlockLevel();
    }

    levelUnlock.forEach((level) => {
      if (!this._doesChestAtLevelExist(level) && level > WorldMapTwoMapInteractionLimit) {
        this.dataReference.unOpenedChests.push({
          i: level,
          x: 0,
          y: 0,
        });
      }
    });
  }

  /**
   * Checks if theres supposed to be a chest at the given level
   * @param {number} level
   */
  _doesChestAtLevelExist(level) {
    return this.dataReference.unOpenedChests.find((chestData) => chestData.i === level);
  }

  /**
   * Determines when a chest should be unlocked on a tile in SagaMap2.
   * @returns {Array<number>}
   */
  determineUnlockLevel() {
    const shuffleInstances = G.OMTsettings.elements.worldMap.rewardPerTile.map((reward) => reward.toLowerCase() === interactionType.chestMap);
    const levelUnlocksFromZero = [];
    shuffleInstances.forEach((isAShuffle, index) => {
      if (isAShuffle) {
        levelUnlocksFromZero.push(WorldMap2_Util.getLevelCountAtTile(index) - 3);
      }
    });
    return levelUnlocksFromZero;
  }

  /**
   * Grabs a copy of the unOpened chests array.
   * If theres old chest data, it merges it together.
   * deepClone gives back a deepClone version so it can be modified without modifying the saveState's
   * @param {boolean} deepClone
   */
  getChestArray(deepClone = false) {
    let consideringArray = this.dataReference.unOpenedChests.slice();
    if (deepClone) {
      consideringArray = this.deepClone(consideringArray);
    }
    return consideringArray;
  }

  /**
   * Deep
   * @param {Array} inArr
   */
  deepClone(inArr) {
    const temp = [];
    for (let j = 0; j < inArr.length; j++) {
      const entry = inArr[j];
      temp.push({
        i: entry.i,
        sr: entry.sr,
        x: entry.x,
        y: entry.y,
      });
    }
    return temp;
  }

  /**
   * Opens the chest, marks it as open (by removing it from data lol)
   * and returns the gifts inside
   * @param {number} id
   */
  openChest(id) {
    const chestData = this.getChestArray();
    const chest = chestData.filter((cData) => cData.i === id)[0];
    if (!chest) {
      console.log('Invalid chest was opened');
      return [];
    }

    const giftFormula = G.gift.getMapChestFormulaGifts();
    let prizes = this.getGiftFromChest(this.dataReference.chestSeed, giftFormula); // Gifts!

    this.dataReference.chestSeed++; // Increments seed.
    if (this.dataReference.chestSeed > (giftFormula.length - 1)) { // Keeps it within boundaries
      this.dataReference.chestSeed = 0;
    }

    const originatedArray = this.dataReference.unOpenedChests; // Was this chest from unOpenedChests?
    const chestIndex = originatedArray.indexOf(chest);
    if (chestIndex > -1) { // Nope!
      originatedArray.splice(chestIndex, 1);
    } else {
      console.log('Warning: Invalid index for opened chest');
    }

    // Convert excess boosters to coin if needed
    prizes = this.limitBoosters(prizes);

    return prizes;
  }

  /**
   * Returns the gifts from the chest based on the chestSeed
   * @param {number} chestSeed
   * @param {Array} giftFormula
   */
  getGiftFromChest(chestSeed, giftFormula) {
    const chosenPrize = giftFormula[chestSeed];

    const coinPrize = Phaser.Math.between(chosenPrize.coin.min, chosenPrize.coin.max); // Coins

    let ingameBooster; // Initialized here first, just in case its (not) needed
    let preLevelBooster;
    const prizes = [['coin', coinPrize]]; // Put in the coins first
    for (let i = 0; i < chosenPrize.booster.length; i++) { // Checks the booster gifts
      const boost = chosenPrize.booster[i];
      switch (boost.type) {
        case G.gift.GiftContentType.RandomInGameBooster: // Is a random in game booster
          if (!ingameBooster || (ingameBooster && ingameBooster.length === 0)) { // Doesn't exist yet or has been exhausted
            ingameBooster = G.gift.getAvailableInGameBoosters();
          }
          { // Stupid switch scoping issue
            const selectedIndex = Phaser.Math.between(0, ingameBooster.length - 1);
            prizes.push([ingameBooster[selectedIndex], boost.amount]); // Prize goes in
            ingameBooster.splice(selectedIndex, 1); // Takes it out to avoid repetition
          }
          break;
        case G.gift.GiftContentType.RandomPreLevelBooster: // Is a random pre-level booster
          if (!preLevelBooster || (preLevelBooster && preLevelBooster.length === 0)) { // Doesn't exist yet or has been exhausted
            preLevelBooster = G.gift.getAvailablePreLevelBoosters();
            const plusThree = preLevelBooster.indexOf(G.gift.GiftContentType.StartBoosterDefaultMoves); // removes +moves boosters
            const plusFive = preLevelBooster.indexOf(G.gift.GiftContentType.StartBoosterSecondaryMoves); // Because +3 or +5 can get messy
            if (plusThree > -1) {
              preLevelBooster.splice(plusThree, 1);
            }
            if (plusFive > -1) {
              preLevelBooster.splice(plusFive, 1);
            }
            if (preLevelBooster.length === 0) { // If by removing these we don't have any boosters
              preLevelBooster = G.gift.getAvailableInGameBoosters(); // Just return them as in game boosters
            }
          }
          { // Stupid switch scoping issue
            const selectedIndex = Phaser.Math.between(0, preLevelBooster.length - 1);
            prizes.push([preLevelBooster[selectedIndex], boost.amount]); // Prize goes in
            preLevelBooster.splice(selectedIndex, 1); // Takes it out to avoid repetition
          }
          break;
        default: break; // Linting...!!!!
      }
    }

    return prizes;
  }

  /**
   * Returns an object used to calculate stars
   * @returns {Object} star requirement object for approximateLevelFromStars
   */
  calculateStarsAtFormulaChangePoint() {
    const starsAtChangePoint = 26 * (G.OMTsettings.mapChest.formulaChangePoint / G.OMTsettings.mapChest.reoccuringLevel);
    return {
      starsAtChangePoint,
      starsAfterChangePoint: starsAtChangePoint + (27 * (1 / G.OMTsettings.mapChest.reoccuringLevel)),
    };
  }

  /**
   * Calculate approximate level of old chest based on star rating
   * @param {Object} starReq the star requirement of the chest
   * @param {Object} starReqAtChangePoint star requirement object returned from calculateStarsAtFormulaChangePoint
   * @returns {number} the approximated level
   */
  approximateLevelFromStars(starReq, starReqAtChangePoint) {
    const { starsAtChangePoint, starsAfterChangePoint } = starReqAtChangePoint;

    if (starReq < starsAfterChangePoint) {
      return Math.floor(starReq / 26 * G.OMTsettings.mapChest.reoccuringLevel);
    }

    const remainingStars = starReq - starsAtChangePoint;
    const levelsAfterChangePoint = remainingStars / 27 * G.OMTsettings.mapChest.reoccuringLevel;
    return Math.floor(levelsAfterChangePoint + G.OMTsettings.mapChest.formulaChangePoint);
  }

  /**
   * Calculates the number of stars required to open the chest at this level
   * @param {number} level
   */
  calculateStarsFromLevel(level) {
    let starAmount = 0; // How many stars does this need to open?
    if (level < G.OMTsettings.mapChest.formulaChangePoint) {
      starAmount = 26 * (level / G.OMTsettings.mapChest.reoccuringLevel);
    } else { // At some point the formula changes
      starAmount = (26 * (G.OMTsettings.mapChest.formulaChangePoint / G.OMTsettings.mapChest.reoccuringLevel))
        + (27 * ((level - G.OMTsettings.mapChest.formulaChangePoint) / G.OMTsettings.mapChest.reoccuringLevel));
    }
    return starAmount;
  }

  /**
   * Removes all chests up to the level given
   * @param {number} level
   */
  removeAllChestsUpToLevel(level) {
    _.remove(this.dataReference.unOpenedChests, (chest) => chest.i < level);
  }

  /**
   * Converts excess boosters in chest rewards into coins
   * @param {Array} chestData
   */
  limitBoosters(chestData) {
    // Only do this in IAP environments
    if (!G.IAP || !OMT.feature.isBoosterLimitOn()) {
      return chestData;
    }

    // Get player characterization data
    const boosterArray = G.saveState.getBoosterArray().slice(1, 9); // On count boosters that can be bought with coins
    const numBoosters = boosterArray.reduce((sum, amount) => sum + amount, 0);
    const daysSinceLastIAP = Math.floor(OMT.transactionTracking.getTimeSinceLastRealMoneyTransaction() / MILLISECONDS_IN_DAY);
    const { boosterLimit, daysSinceLastIAPLimit } = G.json.settings.boosterLimiter;
    let coinPrizeArray = null;

    if (numBoosters > boosterLimit && daysSinceLastIAP >= daysSinceLastIAPLimit) {
      // Check for coin data first
      for (const prize of chestData) {
        if (prize[0] === 'coin') {
          coinPrizeArray = prize;
          break;
        }
      }

      // If the chest data does not already have coins as prizes, create a new entry for them
      if (coinPrizeArray === null) {
        coinPrizeArray = ['coin', 0];
      }

      // Convert excess boosters to coins
      const { boosterConversionRate } = G.json.settings.boosterLimiter;

      for (const prize of chestData) {
        const boosterIdParts = prize[0].match(/(booster)#(\d)/);

        if (boosterIdParts && boosterIdParts[1] === 'booster') {
          const priceKey = `priceOfBooster${boosterIdParts[2]}`;
          const boosterConvertedPrice = G.json.settings[priceKey] * (boosterConversionRate / 100);
          coinPrizeArray[1] += boosterConvertedPrice;
        }
      }

      return [coinPrizeArray];
    }

    return chestData;
  }

  save() {
    OMT.userData.writeUserData(SAGA_MAP_CHEST_MANAGER_DATA_KEY, this.dataReference);
  }
}
