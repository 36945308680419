/* eslint-disable func-names */
G.Debug = G.Debug || {};

class OutOfMemoryOnBitmapsReporter {
  /**
   * Check if the error is of type RangeError
   * @param {Error} error
   */
  static isOutOfMemoryError(error) {
    return error instanceof RangeError;
  }

  /**
   * Convert byte to KB
   * @param {number} bytes The byte value to convert to KB
   */
  static bytesToKilobytes(bytes) {
    return bytes / 1024;
  }

  /**
   * Report the error to sentry
   * @param {string} funcName The function name to report
   * @param {*} requestedMemory The requested memory that caused the error
   */
  static reportOutOfMemory(funcName, requestedMemory) {
    // G.Utils.SentryLog.logError(`Out of memory on ${funcName}`, {
    //   tags: {
    //     requestedMemory: this.bytesToKilobytes(requestedMemory),
    //   },
    // });
  }

  /**
   * Wrap the getImageData function of CanvasRenderingContext2D so that we can check if the original function can be applied with the given parameters
   */
  static register() {
    const getImageDataProxy = function (...args) {
      const originalFunc = args[0];
      const sw = args[3];
      const sh = args[4];
      try {
        const restArgs = args.slice(1);
        return originalFunc.apply(this, restArgs);
      } catch (error) {
        if (OutOfMemoryOnBitmapsReporter.isOutOfMemoryError(error)) {
          const BYTES_PER_PIXEL = 4;
          OutOfMemoryOnBitmapsReporter.reportOutOfMemory('getImageData', sw * sh * BYTES_PER_PIXEL);
        }
        throw error;
      }
    };

    // The actual wrapping function
    G.Utils.wrapFunction(
      CanvasRenderingContext2D.prototype,
      'getImageData',
      null,
      null,
      getImageDataProxy,
    );
  }
}

G.Debug.OutOfMemoryOnBitmapsReporter = OutOfMemoryOnBitmapsReporter;
