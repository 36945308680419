import { TournamentCompetitorAvatar } from '../../vertical/Tournament/TournamentCompetitorAvatar';

/**
 * view for when you overtake the top score
 */
export class TournamentCompetitorTopScoreViewBase extends Phaser.Group {
  /**
   * constructor
   * @param {Array} leaderboardEntrie
   */
  constructor(leaderboardEntries) {
    super(game);
    this._init(leaderboardEntries);
  }

  _init(leaderboardEntries) {
    this._initBg();
    this._adjustOffset();

    this._initYourAvatar();
    this._initYourRankIcon();
    this._initCompetitorAvatars(leaderboardEntries);
  }

  /**
   * Adjust offset
   */
  _adjustOffset() {
    this._offsetX = 0;
  }

  /**
   * init the background image and set the offset for the background anchoring
   */
  _initBg() {
    // Override
  }

  /**
   * init your avatar
   * @param {{avatar:{x: number, y:number}, trophy:{img:string, anchor:(number|Array<number>), x:number, y:number, angle:number}, AvatarClass:TournamentCompetitorAvatarBase}} config
   */
  _initYourAvatar(config) {
    // crate the primary avatar on the left
    this._yourAvatar = new config.AvatarClass(OMT.envData.settings.user.avatar);
    this._yourAvatar.x = config.avatar.x;
    this._yourAvatar.y = config.avatar.y;
    this._yourAvatar.showDashLines();
    this.addChild(this._yourAvatar);

    // add the trophy and align to the avatar
    this._trophy = G.makeImage(0, 0, config.trophy.img, config.trophy.anchor);
    this._trophy.x = this._yourAvatar.x + config.trophy.x;
    this._trophy.y = this._yourAvatar.y + config.trophy.y;
    this._trophy.angle = config.trophy.angle;
    this.addChild(this._trophy);
  }

  /**
   * init your rank icon (1st place)
   * @param {{x:number, y:number, img:string, anchor:(number|Array<number>)}} config
   */
  _initYourRankIcon(config) {
    this._rankGraphic = G.makeImage(config.x, config.y, config.img, config.anchor);
    this.addChild(this._rankGraphic);
  }

  /**
   * init the comptetior avatars in order of rank
   * @param {{limit:number, center:{x:number, y:number}, entries:Array<leaderboardEntries>}} config
   */
  _initCompetitorAvatars(config) {
    const totalEntryCount = config.entries.length;
    // check if we have more entries that can be displayed
    if (totalEntryCount > config.limit) {
      config.entries = config.entries.slice(0, config.limit - 1);
      this._initPlusPlayersIcon(totalEntryCount - config.limit);
    }
    // create the competitor avatars
    const competitorAvatarGroup = new Phaser.Group(game);
    competitorAvatarGroup.x = config.center.x;
    competitorAvatarGroup.y = config.center.y;
    this.addChildAt(competitorAvatarGroup, 1);
    let competitorAvatar; let xPos = 0;
    for (const entryData of config.entries) {
      competitorAvatar = new TournamentCompetitorAvatar(entryData.image);
      competitorAvatar.x = xPos;
      competitorAvatarGroup.addChildAt(competitorAvatar, 0);
      xPos += config.center.spacing;
    }
    return competitorAvatarGroup;
  }

  /**
   * init the + players display group
   * @param {{playerCount:number, counter:{x:number, y:number, anchor:Array<number>}, text:{x:number, y:number, font:(string|Object), margin:number}}} config
   */
  _initPlusPlayersIcon(config) {
    const group = new Phaser.Group(game);
    group.x = config.counter.x;
    group.y = config.counter.y;
    this.addChild(group);
    // create + players bg
    const bg = G.makeImage(0, 0, 'competitor_plus_players_bg', config.counter.anchor);
    group.addChild(bg);
    // create + players text
    const playerCountField = new G.Text(
      config.text.x,
      config.text.y,
      `+${config.playerCount}`,
      config.text.font,
      0,
      bg.width - config.text.margin,
      bg.height - config.text.margin,
      false, // wordwrap
    );
    playerCountField.x -= playerCountField.width * config.counter.anchor[0];
    playerCountField.y -= playerCountField.height * config.counter.anchor[1];
    group.addChild(playerCountField);
  }

  /**
   * set the in transistion
   * @param {{tweenData:Object, duration:number, delay:number}} config
   */
  animateIn(config) {
    this._tween = game.add.tween(this).from(
      config.tween,
      config.duration,
      Phaser.Easing.Quadratic.Out,
      true,
      config.delay,
    );
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    if (this._tween) this._tween.stop();
  }
}
