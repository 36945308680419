import ShareMsgBase from './A_G.ShareMsgBase';

class MsgDailyRewardGet extends ShareMsgBase {
  /**
   * The message that is sent when you get something nice from the daily reward and you want to brag
   * @param {string} user1AvatarURL
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1AvatarURL, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    this.avatar1 = this._addExtImage(490, 94, user1AvatarURL, 0, 134, null);
    this.bg = this._addExtImage(0, 0, 'img/Share_Daily_Reward.png', 0, null, null);

    const str = OMT.language.getText(G.json.shareMessageText.shareDailyRewardGetMsg);
    this.txt = new G.Text(343, 330, str, 'shareMessageText', 0.5, 650);
    this.add(this.txt);

    this._objectLoadComplete();
  }
}

// create global references
G.MsgDailyRewardGet = MsgDailyRewardGet;
