
/* eslint-disable no-unused-vars */

import {
  initialize as initXPromoModule,
  getXPromo as getXPromoPlacements,
  switchGame,
} from '@sgorg/x-promo-game-client';

import TimeUtil from '@omt-components/Utils/TimeUtil';

export const XPROMO_PLACEMENTS = {
  ICON_PICTURE_COMBO: 'icon_picture_combo',
  INTERSTITIAL: 'interstitial_replacement',
  NO_LIVES: 'no_lives_flow',
  NO_REWARDED_ADS: 'no_rewarded_ads',
  AFTER_GAME_LOST: 'afterGameLosts', // like 3 times
  ENDOFCONTENT: 'endOfContent',
};

export const EXTRA_PAYLOADS_REWARDS = {
  SKIPTUTORIAL: 'skipTutorial',
  IAPBONUS: 'iapBonus',
  REWARD: 'reward',
};

/**
 * class for cross promotion and switching between games
 */
export class OMT_CrossPromo {
  /**
   * constructor
   */
  constructor() {
    // define signals
    this.signals = {
      onGetXPromo: new Phaser.Signal(),
    };

    this._availablePromos = [];
    this._loadFinished = false;

    // interstitial placement is currently disabled
    this._inactivePlacements = [XPROMO_PLACEMENTS.INTERSTITIAL];
    this._allowedPlacements = [XPROMO_PLACEMENTS.NO_LIVES, XPROMO_PLACEMENTS.ICON_PICTURE_COMBO, XPROMO_PLACEMENTS.ENDOFCONTENT, XPROMO_PLACEMENTS.AFTER_GAME_LOST];

    this._config = G.OMTsettings.crossPromo;
    this._gameId = this._config.promoId; // 'cookie-crush-2-test1' test id with all campaigns
    this._showCrossPromoIcon = this._config.showIcon || false;
    this._crossPromoEnabled = G.featureUnlock.crossPromo;
    this._crossPromoSeen = false;
    this._crossPromoRewards = null;

    // for cross promo video ad tracking
    this._noFillAdsShown = 0;
    this._noFillAdsMax = parseInt(this._config.xpromoNofillSessionThreshold || 1);
  }

  /**
   * intialize the SG X-Promo module
   */
  init() {
    if (!this.isCrossPromoSupported()) return;
    // switch game callback
    // eslint-disable-next-line arrow-body-style
    const switchFunc = ({ targetGameId, payload }) => {
      // append OMT player ids to the payload
      payload.omtPlayerIDs = G.saveState.getOMTPlayerIDs();
      if (this._crossPromoRewards) {
        payload.crossPromoReward = this._crossPromoRewards;
        this._crossPromoRewards = null; // Remove it from state
      }
      // execute game switch
      console.log(`XPROMO SWITCH GAMES (${targetGameId}) = `, payload);
      return new Promise((resolve, reject) => {
        FBInstant.switchGameAsync(targetGameId, payload).then(() => {
          resolve(true);
        }).catch((error) => {
          resolve(false);
        });
      });
    };

    // tracking callback
    // eslint-disable-next-line arrow-body-style
    const trackingFunc = ({ eventName, params }) => {
      FBInstant.logEvent(eventName, 1, params);
    };

    // create payload passed to x-promo module
    const payload = {
      ...OMT.envData.entryPointData,
    };

    // init the @sgorg x-promo module
    initXPromoModule({
      userData: this.getCrossPromoUserData(),
      gameId: this._gameId,
      playerId: OMT.envData.settings.user.userId,
      payload: OMT.envData.entryPointData,
      trackingFunc,
      switchFunc,
    });
  }

  /**
   * get crossPromo userData Object
   * @returns {Object}
   */
  getCrossPromoUserData() {
    // load promo data from module
    const loginStats = G.saveState.getLoginStats();
    const userLifetime = !loginStats ? 0 : TimeUtil.getDaysSinceDate(new Date(loginStats.creation));
    const sessionOfDay = !loginStats ? 0 : loginStats.dailyCount;

    // xpromo userData Object
    return {
      userLanguage: FBInstant.getLocale(),
      sourceNetwork: null,
      originPlayerId: OMT.envData.gbUserId,
      payer: G.saveState.getIAPCount() > 0,
      payerTotalPurchases: G.saveState.getIAPCount(),
      gameBotSubscribed: G.saveState.getBotOptIn(),
      highestLevel: G.saveState.getLastPassedLevelNr() + 1,
      iapTier: OMT.envData.settings.env.tier,
      country: OMT.envData.settings.env.country || '',
      fps: OMT.performanceMonitor.sessionAverageFPS,
      userLifetime,
      sessionOfDay,
      device: OMT.systemInfo.deviceIsDesktop ? 'desktop' : 'mobile',
    };
  }

  /**
   * load / configure cross promo data
   * @returns {Promise}
   */
  async loadCrossPromoData() {
    if (!this.isCrossPromoSupported()) {
      this._loadFinished = true;
      return;
    }

    this._loadFinished = false;
    const userData = this.getCrossPromoUserData();
    const serverTimestamp = await OMT.connect.getServerTimestamp();
    const serverTime = serverTimestamp === -1 ? new Date() : new Date(serverTimestamp);

    // load xpromos from the module
    getXPromoPlacements({ userData, serverTime }).then((data) => { // handle data
      if (!this.isCrossPromoSupported()) {
        this._loadFinished = true;
        return;
      }
      this._availablePromos = data;
      console.log('X-PROMOS AVAILABLE = ', this._availablePromos);
      this.signals.onGetXPromo.dispatch();

      // preload relevant placement assets
      this._preloadPlacementAssets(XPROMO_PLACEMENTS.ICON_PICTURE_COMBO);
      this._preloadPlacementAssets(XPROMO_PLACEMENTS.NO_LIVES);
      this._preloadPlacementAssets(XPROMO_PLACEMENTS.INTERSTITIAL);
    }).catch((error) => { // handle error
      console.log(error);
    }).finally(() => {
      this._loadFinished = true;
    });
  }

  /**
   * get count of active promos
   * @returns {number}
   */
  get activePromoCount() {
    return this._availablePromos.length;
  }

  /**
   * get promo data by placementId
   * @param {string} placementId
   * @returns {Object}
   */
  getPromoByPlacementId(placementId) {
    const promo = this._availablePromos.find((data) => data.placeholder === placementId);
    return promo;
  }

  /**
   * get the appropriate asset definitions for the promo / placment.
   * @param {Object} promo
   * @param {string} language
   * @param {string} viewportType
   * @returns {Object}
   */
  getAssetsForPromo(promo, language = 'default', viewportType = 'portrait') {
    let placement = promo.assets.find((data) => data.type === viewportType && data.language === language);
    if (!placement) {
      placement = promo.assets.find((data) => data.type === viewportType && data.language === 'default');
    }
    return placement;
  }

  /**
   * get video file src for placement / placment.
   * @param {Object} promo
   * @param {string} language
   * @returns {Object}
   */
  getVideoDataForPromo(promo, language = 'default') {
    let placement = promo.assets.find((data) => data.language === language);
    if (!placement) placement = promo.assets.find((data) => data.language === 'default');
    this._noFillAdsShown++;
    return placement;
  }

  /**
   * preload an asset from a placement
   * @param {string} placementId
   */
  _preloadPlacementAssets(placementId) {
    const placement = this.getPromoByPlacementId(placementId);
    if (!placement) return;
    const assetData = this.getAssetsForPromo(placement);
    if (!assetData) return;
    G.makeExtImage(0, 0, assetData.src, null, 0, null, null, (img) => {
      img.destroy();
    });
  }

  /**
   * show cross promo overlay window
   * @param {string} trackingName
   * @param {Object} [crossPromoData]
   * @param {Function} [callback]
   * @param {boolean} [forceShow]
   */
  showCrossPromoWindow(trackingName, crossPromoData, callback = null, forceShow = false) {
    if (this._crossPromoSeen && !forceShow) return;
    const assetData = crossPromoData ? this.getAssetsForPromo(crossPromoData, OMT.envData.settings.env.lang, 'portrait') : null;
    if (crossPromoData && assetData) {
      const { placeholder } = crossPromoData;
      if (!OMT.crossPromo.isPlacementActive(placeholder)) return; // placement is inactive we dont show it
      if (this._allowedPlacements.includes(placeholder)) {
        const trackingEventName = OMT.platformTracking.Events.XPromoVisible.replace('%id%', trackingName);
        OMT.platformTracking.logEvent(trackingEventName);
        G.sb('pushWindow').dispatch(['crossPromoOverlay', crossPromoData, assetData, trackingName, callback], null, G.WindowMgr.LayerNames.OverlayLayer, true);
      }
    }
    // this._showCrossPromoIcon = false; // Turned off OMT-3056
    this._crossPromoSeen = true;
    G.sb('crossPromoSeen').dispatch();

    // reload crosspromo data
    OMT.crossPromo.loadCrossPromoData();
  }

  /**
   * check if the cross-promo placement is active
   * @param {string} placement
   * @returns {boolean}
   */
  isPlacementActive(placement) {
    if (placement === XPROMO_PLACEMENTS.NO_REWARDED_ADS && this._noFillAdsShown >= this._noFillAdsMax) return false;
    return this.isCrossPromoSupported() && this.getPromoByPlacementId(placement) != null && this._inactivePlacements.indexOf(placement) === -1;
  }

  /**
   * check if cross-promo is supported
   * @returns {boolean}
   */
  isCrossPromoSupported() {
    return !OMT.systemInfo.browserIsIE && !OMT.systemInfo.deviceIsIOS && FBInstant && FBInstant.getSupportedAPIs().includes('switchGameAsync') && this._crossPromoEnabled;
  }

  /**
   * check if the cross promo has already been seen this session
   * @returns {boolean}
   */
  isCrossPromoSeen() {
    return this._crossPromoSeen;
  }

  /**
   * true if cross promo icon is enabled
   * @returns {boolean}
   */
  get showCrossPromoIcon() {
    return this._showCrossPromoIcon;
  }

  /**
   * true if getXPromoPlacements was called and finished,
   * whether it was successful or not
   */
  get loadFinished() {
    return this._loadFinished;
  }

  /**
   * If cross promotion is supported, switches to the promo game and passes a payload
   * @param {Object} placement placement object from the buildEvironment
   * @param {{iapBonus?:number, reward?:Array<{itemId:string, amount:number}>, skipTutorial?:boolean}}
   * @returns {Promise<boolean>}
   */
  async switchToAnotherGame(placement, rewards) {
    this._crossPromoRewards = rewards;
    const success = await switchGame(placement.id);
    return success;
  }
}
