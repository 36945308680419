/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import { Window } from '../../../00_IMMEDIATE/Window';
import OMT_UI_HighlightBackground from '../../../OMT_UI/OMT_UI_HighlightBackground';
import OMT_UI_TutorialHand from '../../../OMT_UI/OMT_UI_TutorialHand';
import VillainsConversation from '../../../OMT_UI/Villains/VillainsConversation';
import OMT_StackManager from '../../../Utils/OMT_StackManager';

/**
 * Class for villains tutorials
 * It is created as a window and then creates villains conversation object on top to play the tutorial flow
 */
export default class Window_villainsTutorial extends Window {
  /**
   * constuctor
   * @param {Object} parent
   * @param {object} config
   */
  constructor(parent, config) {
    super(parent);

    this.config = config;

    this._initDisplayElements();
    this._setupHighlight();
    this._setupPointer();
    this._initInputEvent();

    this.onFinishedEnter.addOnce(async () => {
      this._onResize();
      await this.stack.run();

      this.tutorialFinished = true;

      const { callbacks } = config;
      if (callbacks) {
        const { end } = callbacks;
        if (end) {
          await end(this);
        }
      }
    });

    G.sb('onScreenResize').add(this._onResize, this);
  }

  /**
   * We don't want the default open window behaviour here
   */
  async _setOpenWindowAnimation() {
    const stack = OMT_StackManager.getFreeStack();
    stack.addEvent(() => {
      this.alpha = 0;
    });
    stack.wait(1);
    stack.addEvent(() => {
      this.onFinishedEnter.dispatch();
    });
    stack.wait(1);
    stack.addEvent(() => {
      this.alpha = 1;
    });
    return stack.run();
  }

  /**
   * initialize display elements
   */
  _initDisplayElements() {
    const { config } = this;
    const { difficulty, stage, callbacks, tapToContinue } = config;

    const stack = OMT_StackManager.getFreeStack();
    const villainsConversation = new VillainsConversation(this, {
      window: this,
      tapToContinue,
    });
    this.villainsConversation = villainsConversation;

    if (callbacks) {
      const { start, steps } = callbacks;
      if (start) {
        stack.addEvent(() => {
          start({
            window: this,
          });
        });
      }

      if (steps) {
        villainsConversation.addStepFunctions(steps);
      }
    }

    stack.addPromise(() =>
      villainsConversation.createTutorial(difficulty, stage),
    );
    this.stack = stack;
  }

  /**
   * Setup the highlight element to focus on elements on the screen like level nodes or booster panels
   */
  _setupHighlight() {
    const { config, villainsConversation } = this;
    const { highlight } = config;
    if (!highlight) return;
    const { steps } = highlight;

    this.stopFade = true;
    this.parent.fadeImg.alpha = 0;

    const maskObject = new OMT_UI_HighlightBackground({ alpha: 0.8, isFullscreen: true });
    this.maskObject = maskObject;

    if (steps) {
      for (const [stepCount, object] of Object.entries(steps)) {
        steps[stepCount] = {
          start: () => {
            maskObject.highlight(object);
          },
        };
      }
      villainsConversation.addStepFunctions(steps);
    }

    this.addChildAt(maskObject, 0);
  }

  /**
   * Setup a pointer / tutorial hand to animate later
   */
  _setupPointer() {
    const { config, villainsConversation } = this;
    const { pointer } = config;
    if (!pointer) return;
    const { tween, steps, callbacks } = pointer;

    const pointerObject = new OMT_UI_TutorialHand();
    pointerObject.scale.setTo(0.6);
    this.pointerObject = pointerObject;

    if (steps) {
      for (const [stepCount, object] of Object.entries(steps)) {
        steps[stepCount] = {
          start: async () => {
            const stack = OMT_StackManager.getFreeStack();

            if (Array.isArray(object)) {
              stack.addEvent(() => {
                pointerObject.show();
              });
              stack.addPromise(() => {
                if (callbacks) {
                  return pointerObject.moveBetween(
                    object,
                    callbacks[stepCount],
                  );
                }
                return pointerObject.moveBetween(object);
              });
            } else {
              stack.addEvent(() => {
                pointerObject.targetObject = object;
                this._positionPointer();
              });
              stack.addPromise(() => pointerObject.show());
              if (tween) {
                stack.addEvent(() => {
                  pointerObject.toggleTween(true);
                });
              }
            }

            return stack.run();
          },
        };
      }
      villainsConversation.addStepFunctions(steps);
    }

    this.add(pointerObject);
  }

  /**
   * After the tutorial is finished, close the window with a click on anywhere of the screen
   */
  _initInputEvent() {
    game.input.onDown.add(() => {
      if (!this.tutorialFinished || this.finished) return;
      this.finished = true;
      this.closeWindow();
    });
  }

  update() {
    super.update();

    const { villainsConversation, config } = this;
    const { gingy, continuePrompt } = villainsConversation;
    const { gingyPosition } = config;

    if (gingy && gingyPosition) {
      if (gingyPosition.anchorToBottom && !gingyPosition.anchorToBottom()) {
        const { x, y } = gingyPosition;

        const gingyPosScale = {
          x: this._isLandscape ? 70 : 100,
          y: this._isLandscape ? 122 : 100,
        };

        villainsConversation._positionClass(
          gingy,
          (x / game.width) * gingyPosScale.x,
          (y / game.height) * gingyPosScale.y,
        );
      } else {
        let yPos = (game.height - gingy.getClassContainer().height * 0.32);
        const { y } = continuePrompt.worldPosition;
        if (continuePrompt) yPos = y - continuePrompt.height - gingy.getClassContainer().height * 0.32;
        villainsConversation._positionClass(
          gingy,
          50,
          (yPos / game.height) * 100,
        );
      }
    }
  }

  /**
   * resize method
   */
  _onResize() {
    const { maskObject, pointerObject } = this;

    if (maskObject) {
      maskObject.y = this.parent.toLocal({
        x: game.width * 0.5,
        y: game.height * 0.5,
      }).y;
      maskObject.resize();
    }

    if (pointerObject) {
      this._positionPointer();
    }
  }

  /**
   * Keep pointer on the target object at all times (part of resize callback)
   */
  _positionPointer() {
    const { pointerObject } = this;

    if (pointerObject) {
      const { targetObject } = pointerObject;
      if (targetObject) {
        const { x, y } = this.parent.toLocal(targetObject.worldPosition);
        pointerObject.position.setTo(x, y);
      }
    }
  }

  /**
   * Destruction method
   */
  destroy() {
    this.stopFade = false;
    super.destroy();
    G.sb('onScreenResize').remove(this._onResize, this);
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.villainsTutorial = Window_villainsTutorial;
