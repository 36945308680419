/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
import OMT_TweenUtils from '../../Utils/Animation/OMT_TweenUtils';
import OMT_StackManager from '../../Utils/OMT_StackManager';
import OMT_VILLAINS from '../OMT_Villains';
import VillainsBaseClass from './VillainsBaseClass';
import VillainsBaseContainer from './VillainsBaseContainer';
import VillainsLaugh from './VillainsLaugh';
import VillainsPoofAnimation from './VillainsPoofAnimation';

export default class VillainsLevelNode extends VillainsBaseClass {
  /**
   * Creates the villains and their animations on the level node
   * @param {LevelNode} parent
   */
  constructor(parent) {
    super(parent);

    this.parent = parent;
    this.objectPrefix = '_villain';
    this.baseTextAngle = 35;

    const classContainer = new VillainsBaseContainer(this);
    this._villain_container = {
      type: 'group',
      object: classContainer,
    };

    const villain_1 = G.makeImage(
      0,
      0,
      OMT_VILLAINS.getPrefixedName('villain_1_5'),
      0.5,
      classContainer,
    );
    this.villain_1 = villain_1;

    const villain_1_shadow = G.makeImage(
      0,
      0,
      'chestShuffleShadow',
      0.5,
      classContainer,
    );
    this.villain_1_shadow = villain_1_shadow;

    classContainer.bringToTop(villain_1);

    const villain_2 = G.makeImage(
      0,
      0,
      OMT_VILLAINS.getPrefixedName('villain_2_5'),
      0.5,
      classContainer,
    );
    this.villain_2 = villain_2;

    const villain_2_shadow = G.makeImage(
      0,
      0,
      'chestShuffleShadow',
      0.5,
      classContainer,
    );
    this.villain_2_shadow = villain_2_shadow;

    classContainer.bringToTop(villain_2);

    const villain_1_laugh = new VillainsLaugh(
      OMT.language.getText('HA'),
      this.baseTextAngle,
      3,
      250,
    );
    classContainer.add(villain_1_laugh);
    this.villain_1_laugh = villain_1_laugh;

    const villain_2_laugh = new VillainsLaugh(
      OMT.language.getText('HA'),
      -this.baseTextAngle,
      3,
      250,
    );
    classContainer.add(villain_2_laugh);
    this.villain_2_laugh = villain_2_laugh;

    this.init();

    const poofAnimation = new VillainsPoofAnimation(this);
    const poof_object = poofAnimation.getClassContainer();
    this._villain_poof_animation = {
      type: 'poof',
      object: poof_object,
      parentObject: poofAnimation,
    };

    parent.add(classContainer);
  }

  /**
   * Initialize / reset villain and text positions
   */
  init() {
    const {
      parent,
      villain_1,
      villain_1_shadow,
      villain_2,
      villain_2_shadow,
    } = this;

    const villainOffsetXCoeff = -0.05;

    villain_1.anchor.setTo(0.5);
    villain_1.position.setTo(0);
    villain_1.scale.setTo(0.3);
    villain_1.x +=
      villain_1.width * 0.5 + parent._baseNode.width * villainOffsetXCoeff;
    villain_1.y -= villain_1.height * 0.5;
    this._villain_1 = {
      index: 1,
      type: 'villain',
      object: villain_1,
      doubleVillainPosition: villain_1.x,
      startingYScale: villain_1.scale.y,
    };

    villain_1_shadow.position.setTo(0);
    villain_1_shadow.scale.setTo(1);
    villain_1_shadow.x = villain_1.x;
    villain_1_shadow.y += villain_1.y + villain_1.height * 0.52;
    this._villain_1_shadow = {
      index: 1,
      type: 'shadow',
      object: villain_1_shadow,
    };

    villain_2.anchor.setTo(0.5);
    villain_2.position.setTo(0);
    villain_2.scale.setTo(0.3);
    villain_2.x -=
      villain_2.width * 0.5 + parent._baseNode.width * villainOffsetXCoeff;
    villain_2.y -= villain_2.height * 0.5;
    this._villain_2 = {
      index: 2,
      type: 'villain',
      object: villain_2,
      startingYScale: villain_2.scale.y,
    };

    villain_2_shadow.position.setTo(0);
    villain_2_shadow.scale.setTo(1);
    villain_2_shadow.x = villain_2.x;
    villain_2_shadow.y += villain_2.y + villain_2.height * 0.52;
    this._villain_2_shadow = {
      index: 2,
      type: 'shadow',
      object: villain_2_shadow,
    };

    this._positionVillains();
  }

  /**
   * Position villains based on their indexes (only villain_1 for now)
   * @param {1 | 2} index The index of the objects to show
   */
  _positionVillains(index) {
    const villain_1 = this.getObjects(
      { index: 1, type: 'villain' },
      (object) => object,
    )[0];
    const villain_2 = this.getObjects({ index: 2, type: 'villain' })[0];
    const villain_1_shadow = this.getObjects({ index: 1, type: 'shadow' })[0];
    const { object } = villain_1;

    if (index === 1) {
      object.x = 0;
    } else if (index === 2) {
      object.x = villain_1.doubleVillainPosition;
    }
    villain_1_shadow.x = object.x;

    object.scale.x = Math.abs(object.scale.x);
    this._positionVillainLaugh();
    object.anchor.setTo(0.5, 1);
    object.y = 0;
    villain_2.anchor.setTo(0.5, 1);
    villain_2.y = 0;
    object.scale.x *= -1;
    this.startVillainIdleAnimation();
  }

  /**
   * Position the villain laugh based on the positions of villains
   */
  _positionVillainLaugh() {
    const { villain_1, villain_2, villain_1_laugh, villain_2_laugh } = this;

    villain_1_laugh.position.setTo(villain_1.x, villain_1.y);
    villain_1_laugh.x += villain_1.width * 0.4;
    villain_1_laugh.y -= villain_1.height * 0.4;
    this._villain_1_laugh = {
      index: 1,
      type: 'text',
      object: villain_1_laugh,
    };

    villain_2_laugh.position.setTo(villain_2.x, villain_2.y);
    villain_2_laugh.x -= villain_2.width * 0.4;
    villain_2_laugh.y -= villain_1.height * 0.1;
    this._villain_2_laugh = {
      index: 2,
      subIndex: 1,
      type: 'text',
      object: villain_2_laugh,
    };
  }

  /**
   * Starts villains' idle animation which is a scaleY tween
   */
  startVillainIdleAnimation() {
    const villains = this.getObjects({ type: 'villain' }, (object) => object);

    for (const villain of villains) {
      const { object, idleAnimation } = villain;
      if (idleAnimation) {
        idleAnimation.stop();
      }
      const targetScale = villain.startingYScale * 0.95;
      const tween = game.add
        .tween(object.scale)
        .to(
          { y: targetScale },
          500,
          Phaser.Easing.Sinusoidal.InOut,
          true,
          0,
          -1,
          true,
        );
      villain.idleAnimation = tween;
    }
  }

  /**
   * Stops the idle animation
   * This function is called to optimize performance when the villains are invisible
   */
  stopVillainIdleAnimation() {
    const villains = this.getObjects({ type: 'villain' }, (object) => object);

    for (const villain of villains) {
      const { idleAnimation, object } = villain;
      if (idleAnimation) {
        idleAnimation.stop();
        object.scale.y = villain.startingYScale;
        villain.idleAnimation = undefined;
      }
    }
  }

  /**
   * Hides villain images
   */
  hide() {
    VillainsLevelNode.toggleShowQueue(this, false);
    const objects = this.getObjects(undefined, (object) => object).filter(
      (object) => !['group', 'poof'].includes(object.type),
    );
    objects.forEach((object) => {
      object.object.alpha = 0;
    });
    this.stopVillainIdleAnimation();
    objects
      .filter((object) => object.type === 'text')
      .forEach((object) => {
        object.object.stopLaughSpawner();
      });
  }

  /**
   * Shows villains based on their index
   * @param {1 | 2} index The index of the objects to show
   */
  show(index) {
    VillainsLevelNode.toggleShowQueue(this, true, index);
    if (VillainsLevelNode.getPauseStatus()) {
      return;
    }

    this._positionVillains(index);

    this.getObjects({ index }).forEach((object) => {
      object.alpha = 1;
    });

    this.getObjects({ index, type: 'text' }).forEach((object) => {
      object.startLaughSpawner(650);
    });
  }

  /**
   * Shows villains based on their index over time
   * @param {1 | 2} index The index of the objects to show
   */
  async showAnimate(index) {
    VillainsLevelNode.toggleShowQueue(this, true, index);
    if (VillainsLevelNode.getPauseStatus()) {
      return Promise.resolve();
    }

    this._positionVillains(index);

    const stack = OMT_StackManager.getFreeStack();
    const subStacks = [];

    this.getObjects({ index }).forEach((object) => {
      const subStack = OMT_StackManager.getFreeStack();

      subStack.addPromise(() => OMT_TweenUtils.changeAlphaTo({
        object,
        duration: 500,
        ease: Phaser.Easing.Circular.Out,
        alpha: 1,
      }));

      subStacks.push(subStack);
    });

    if (subStacks.length) {
      stack.addParallel(subStacks);
      stack.addEvent(() => {
        for (const object of this.getObjects({ index, type: 'text' })) {
          object.startLaughSpawner(650);
        }
      });
    }

    return stack.run();
  }

  /**
   * Trigger the poof explosion animation
   */
  async explodePoof() {
    if (!this.explodePoofReady) return Promise.resolve();
    const stack = OMT_StackManager.getFreeStack();
    stack.addEvent(() => {
      VillainsLevelNode.togglePause(true);
    });
    stack.addPromise(() => {
      const poof = this.getObjects({ type: 'poof' }, (object) => object)[0]
        .parentObject;
      return poof.explode();
    });
    stack.addEvent(() => {
      VillainsLevelNode.togglePause(false);
      this.explodePoofReady = false;
    });
    return stack.run();
  }

  /**
   * Toggle the visibility of villains in the map
   * @param {boolean} state
   */
  static togglePause(state) {
    if (!state) {
      state = !this.pauseShow;
    }

    this.pauseShow = state;

    if (!this.showQueue) {
      return;
    }

    for (const queue of Object.values(this.showQueue)) {
      const { instance, index } = queue;

      if (state) {
        instance.hide();
      } else {
        instance.showAnimate(index);
      }
    }

    if (!state) {
      this.showQueue = [];
    }
  }

  /**
   * Returns the current pause status of villains rendering
   * @returns {boolean} pauseShow Current pause status of the static class
   */
  static getPauseStatus() {
    return this.pauseShow;
  }

  /**
   * The registry for when some villains wants to show on the map but the static class is paused
   * @param {VillainsLevelNode} instance The instance of the villains
   * @param {boolean} state True for adding an instance to show queue
   * @param {1 | 2} index The index of the objects to show
   */
  static toggleShowQueue(instance, state, index) {
    if (!this.showQueue) {
      this.showQueue = [];
    }

    const instanceIndex = Object.values(this.showQueue).indexOf({
      instance,
      index,
    });

    if (state) {
      if (instanceIndex === -1) {
        this.showQueue.push({
          instance,
          index,
        });
      } else {
        this.showQueue[instanceIndex] = {
          instance,
          index,
        };
      }
    } else if (instanceIndex > -1) {
      this.showQueue[instanceIndex] = null;
    }
  }
}
