/* eslint-disable func-names */
/* eslint-disable no-use-before-define */
(function () {
  G.AnimationUtils = {
    createPositionAnimator,
  };

  // eslint-disable-next-line no-useless-return
  return;

  /**
   * @param game {Phaser.Game}
   * @param target {PIXI.DisplayObject} must have customComponents.ui
   *     if `optRememberInitialPosition` then this must have parent that has ui component
   * @param optRememberInitialPosition {Boolean = false}
   * @param optInitialPositionName {String = 'initial'}
   * @param optScale {Boolean = false}
   * @param optEasing {Phaser.Easing}
   */
  function createPositionAnimator(
    game,
    target,
    optRememberInitialPosition,
    optInitialPositionName,
    optScale,
    optEasing,
  ) {
    // imports
    const { defined } = G.Utils;

    //

    const positions = {};

    if (optRememberInitialPosition) {
      addPosition(defined(optInitialPositionName, 'initial'), target, true);
    }

    return {
      target,
      addPosition,
      animateToPosition,
      setPosition,
    };

    /**
     * @param name {String}
     * @param object {PIXI.DisplayObject} must have customComponents.ui
     * @param optCopy {Boolean = false}
     */
    function addPosition(name, object, optCopy) {
      if (optCopy) {
        const group = game.add.group(object.parent, `${target.name}$position$${name}`);
        const uiComponent = G.UI.cloneUIComponent(
          object.customComponents.ui,
          G.UI.ElementType.Group,
        );
        const parentSize = object.parent.customComponents.ui.sizeAfterLastResize;
        G.UI.addUIComponent(group, uiComponent);
        G.UI.resize(group, parentSize.x, parentSize.y);

        positions[name] = group;
      } else {
        positions[name] = object;
      }
    }

    /**
     * @param name {String}
     * @param durationMs {Number}
     */
    function animateToPosition(name, durationMs) {
      // TODO: solve overlapping animations
      const easing = optEasing || Phaser.Easing.Cubic.Out;
      const targetPositionObject = positions[name];
      game.add.tween(target).to(
        {
          x: targetPositionObject.x,
          y: targetPositionObject.y,
        },
        durationMs, easing, true,
      );
      if (optScale) {
        const uiComponent = targetPositionObject.customComponents.ui;
        game.add.tween(target).to(
          {
            // TODO: use function from G.UI.resize
            width: uiComponent.sizeAfterLastResize.x,
            height: uiComponent.sizeAfterLastResize.y,
          },
          durationMs, easing, true,
        );
      }
    }

    /** @param name {String} */
    function setPosition(name) {
      const targetPositionObject = positions[name];
      target.x = targetPositionObject.x;
      target.y = targetPositionObject.y;
      if (optScale) {
        const uiComponent = targetPositionObject.customComponents.ui;
        // TODO: use function from G.UI.resize
        target.width = uiComponent.sizeAfterLastResize.x;
        target.height = uiComponent.sizeAfterLastResize.y;
      }
    }
  }
}());
