/* eslint-disable no-unused-vars */

/**
 * Base class for layers containing BoardTokens. Candies are part of the CandyLayer.
 */
export class TokenLayer extends Phaser.Group {
  /**
   * constructor
   * @param {Board} board
   * @param {Object} config config object for the BoardToken
   * @param {Function} config.constructor constructor for the BoardToken
   * @param {boolean} config.hpToken (optional) true if the token has hp / mulptiple hits
   * @param {number} config.maxHp (optional) hp / hits token can take
   * @param {string} config.editorSymbol editor symbol for the BoardToken
   * @param {boolean} config.blockMove (optional) should token block movement
   * @param {boolean} config.blockBoosterChange (optional) should token block booster change / transform
   * @param {boolean} config.blockMatch (optional) should token block matching
   */
  constructor(board, config) {
    super(game);

    this.position = board.position;
    this.scale = board.scale;

    this._board = board;
    this._config = config;

    const { width, height } = this._board.boardGridData;
    this._grid = new G.GridArray(width, height, false);
  }

  /**
   * clears all tokens in the layer
   */
  clearLayer() {
    this._grid.loop((elem, cellX, cellY) => {
      if (elem) this.removeToken(cellX, cellY);
    }, this);
  }

  /**
   * check if token blocks a movement
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isMoveBlocked(cellX, cellY) {
    const { blockMove } = this._config;
    return this.isToken(cellX, cellY) && blockMove;
  }

  /**
   * check if token blocks a match
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isMatchBlocked(cellX, cellY) {
    const { blockMatch } = this._config;
    return this.isToken(cellX, cellY) && blockMatch;
  }

  /**
   * check if booster transform / change is blocked
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isBoosterChangeBlocked(cellX, cellY) {
    return this.isMoveBlocked(cellX, cellY);
  }

  /**
   * create BoardToken instance and add it to the layer.
   * @param {number} cellX
   * @param {number} cellY
   * @param {number} hp
   * @returns {BoardToken}
   */
  _createToken(cellX, cellY, hp) {
    const Tokenclass = this._config.constructor;
    const elem = this.add(new Tokenclass(this, cellX, cellY, hp));
    this._grid.set(cellX, cellY, elem);
    elem.grid = this._grid;
    return elem;
  }

  /**
   * remove token at the specified position.
   * @param {number} cellX
   * @param {number} cellY
   */
  removeToken(cellX, cellY) {
    // remove BoardToken
    const elem = this._grid.get(cellX, cellY);
    if (elem) {
      this._grid.set(cellX, cellY, false);
      if (this._config.collectableType) {
        G.sb('onCollectableRemove').dispatch(
          this._config.collectableType,
          elem,
          elem.frameName,
        );
      }
      elem.deathAnimation();
      // if token removed was blocking a candy unlock it now
      const candy = this._board.getCandy(cellX, cellY);
      if (candy) {
        if (this._config.blockMove) this._board.pushToFallCheckList(candy);
        if (this._config.blockMatch) {
          if (this._board.matcher.quickMatchCheck(candy)) {
            this._board.checkMatchList.push(candy);
          }
        }
      }
    }
  }

  /**
   * get BoardToken at the specified position.
   * @param {number} cellX
   * @param {number} cellY
   * @returns {BoardToken}
   */
  getToken(cellX, cellY) {
    return this._grid.get(cellX, cellY);
  }

  /**
   * check if cell contains a token.
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  isToken(cellX, cellY) {
    const token = this.getToken(cellX, cellY);
    return token !== false;
  }

  /**
   * get a list of all tokens in the layer.
   * @returns {Array}
   */
  getAllTokens() {
    const result = [];
    this._grid.loop((token, x, y) => {
      if (token) result.push(token);
    });
    return result;
  }

  /**
   * on match action at the specified position.
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  onMatch(cellX, cellY) {
    const token = this.getToken(cellX, cellY);
    if (!token) return true;
    token.onMatch();
    return this._config.stopMatchPropagation !== true;
  }

  /**
   * on adjacent cell hit by match.
   * @param {number} cellX
   * @param {number} cellY
   * @returns {boolean}
   */
  onHit(cellX, cellY) {
    const token = this.getToken(cellX, cellY);
    if (!token) return true;
    if (token) token.onHit();
    return this._config.stopHitPropagation !== true;
  }

  /**
   * on booster used at the specified position.
   * @param {number} cellX
   * @param {number} cellY
   */
  onBooster(cellX, cellY) {
    const token = this.getToken(cellX, cellY);
    if (!token) return true;
    if (!token.onBooster()) return false;
    return true;
  }

  /**
   * used by editor. just a proxy for remove candy.
   * @param {number} cellX
   * @param {number} cellY
   */
  destroyCell(cellX, cellY) {
    this.removeToken(cellX, cellY);
  }

  /**
   * import from editor data
   * @param {number} cellX
   * @param {number} cellY
   * @param {Array} chunk
   * @returns {boolean}
   */
  import(cellX, cellY, chunk) {
    if (chunk.indexOf(this._config.editorSymbol) === 0) {
      if (this._config.hpToken) {
        const hp = chunk[this._config.editorSymbol.length];
        this._createToken(cellX, cellY, hp);
      }
      return true;
    }
    return false;
  }

  /**
   * export editor data
   * @param {number} cellX
   * @param {number} cellY
   * @returns {string}
   */
  export(cellX, cellY) {
    const elem = this.getToken(cellX, cellY);
    if (elem) {
      if (this._config.hpToken) return this._config.editorSymbol + elem.hp;
      if (elem.export) return elem.export();
      return this._config.editorSymbol;
    }
    return null;
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    this._board = null;
    this._config = null;
    this._grid.destroy(); this._grid = null;
  }

  /** GETTER METHODS ********************************* */

  /** @returns {Board} get Board instance */
  get board() { return this._board; }

  /** @returns {GridArray} get GridArray instance */
  get grid() { return this._grid; }

  /** @returns {Object} get config passed in on construction */
  get config() { return this._config; }
}
