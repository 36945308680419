export default class LabelTextT extends Phaser.Group {
  /**
   * A class that creates a string with an icon with it
   * @param {string} str
   * @param {number} x
   * @param {number} y
   * @param {(Object|string)} textStyle
   * @param {(number|Array<number>)} anchor
   * @param {number} [maxWidth]
   * @param {number} [distanceBetween]
   */
  constructor(str, x, y, textStyle, anchor, maxWidth, distanceBetween) {
    super(game, null);
    this._str = str;
    this._tagArray = G.LabelParser.changeIntoTagArray(str);

    this.x = x;
    this.y = y;
    this._textStyle = G.Text.getStyle(textStyle);
    this._fontSize = parseInt(this._textStyle.fontSize);

    this._distanceBetween = distanceBetween || 0;

    if (typeof anchor === 'number') {
      this._anchorX = this._anchorY = anchor;
    } else {
      this._anchorX = anchor[0]; // eslint-disable-line prefer-destructuring
      this._anchorY = anchor[1]; // eslint-disable-line prefer-destructuring
    }

    this.maxWidth = maxWidth || 0;

    this._processTagArray();
  }

  /**
   * Processes tag array into something workable
   */
  _processTagArray() {
    for (let i = 0; i < this._tagArray.length; i++) {
      if (this._tagArray[i].type === 'img') {
        const img = G.makeImage(0, 0, this._tagArray[i].content, 0, this);
        img.tagScale = this._tagArray[i].scale;
      } else if (this._tagArray[i].type === 'separator') {
        const img = G.makeImage(0, 0, null, 0, this);
        img.SEPARATOR = true;
        img.SEP_LENGTH = this._tagArray[i].length * 0.5;
      } else {
        this.add(new G.Text(0, 0, this._tagArray[i].content, this._textStyle));
      }
    }
    this._refresh();
  }

  /**
   * Redraws image
   */
  _refresh() {
    this._applySizeAndAnchor();
    if (this.maxWidth > 0 && this._getWholeWidth() > this.maxWidth) {
      while (this._getWholeWidth() > this.maxWidth) {
        this._distanceBetween = Math.floor(this._distanceBetween * 0.9);
        this._fontSize = Math.floor(this._fontSize * 0.9);
        this._applySizeAndAnchor();
      }
    }
    this._spreadElements();
  }

  /**
   * Layout elements accordingly
   */
  _applySizeAndAnchor() {
    for (const child of this.children) {
      child.anchor.setTo(this._anchorX, this._anchorY);
      if (child.fontSize) {
        child.fontSize = this._fontSize;
        child.updateTransform();
        child.y += child.padding.y;
      } else {
        child.height = this._fontSize * (child.tagScale || 1);
        child.scale.x = child.scale.y;
      }

      if (child.SEPARATOR) {
        child.width = this._fontSize * child.SEP_LENGTH;
      }
    }
  }

  /**
   * Gets the whole width
   */
  _getWholeWidth() {
    const allDistanceBetween = (this.children.length - 1) * this._distanceBetween;
    let widthOfAllElements = 0;
    for (const child of this.children) {
      widthOfAllElements += child.width;
    }
    return allDistanceBetween + widthOfAllElements;
  }

  /**
   * Positions elements accordingly
   */
  _spreadElements() {
    const startX = this._getWholeWidth() * this._anchorX * -1;
    this.children.forEach((e, index, array) => {
      e.left = (index === 0 ? startX : array[index - 1].right + this._distanceBetween);
    });
  }

  /**
   * Offsets Y
   * @param {number} value
   */
  addOffsetYToText(value) {
    for (const child of this.children) {
      if (child.fontSize) child.y += value;
    }
  }

  /**
   * Updates the text with new string
   * @param {string} newStr
   */
  updateText(newStr) {
    this._str = newStr;
    this._tagArray = G.LabelParser.changeIntoTagArray(newStr);
    this.removeAll(true, false, false);
    this._processTagArray();
  }
}

if (G === undefined) G = {};
G.LabelTextT = LabelTextT;
