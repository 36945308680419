import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import OMT_VILLAINS from '../../../../OMT_UI/OMT_Villains';
import OMT_UI_FloatingItem from '../../../../OMT_UI/OMT_FloatingItem';
import { OMT_SystemInfo, ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';
import { Window_ItemShop, Window_ItemShop_ITEM_DETAILS } from '../../../Windows/Window_ItemShop';
import { GameScaleController } from '../../../../States/Scaling/GameScaleController';

export default class UI_BoosterButton extends Phaser.Group {
  /**
   * The invidiual booster buttons on the game state board
   * @param {Board} board
   * @param {number} x
   * @param {number} y
   * @param {number} nr
   */
  constructor(board, x, y, nr) {
    super(game);

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._board = board;
    this.x = Math.floor(x);
    this.y = Math.floor(y);
    this.orgX = this.x;
    this.orgY = this.y;

    this.orgScaleX = 1;
    this.orgScaleY = 1;

    this._state = game.state.getCurrentState(); // Game.js
    this._boosterNr = nr;

    this._selected = false;

    this._hl = G.makeImage(0, 0, 'popup_lighht', 0.5, this);
    this._hl.blendMode = 1;
    this._hl.alpha = 0;
    this._hl.angle2 = 0;

    this._openLock = false;
    this._unlimitedMode = false;

    this._tweenData = [];
    this._signalBindings = [];
    this._signalBindings.push(G.sb('onTutorialFinish').add(this.hideSuggestion.bind(this)));

    // eslint-disable-next-line consistent-return
    this._btn = new G.Button(0, 0, `ui_booster_${nr}`, () => {
      this.handleClick();
    });
    this.add(this._btn);

    // Be clickable only if the action list is empty or is selected
    this._btn.addTerm(() => !this._state.board.actionManager.hasActiveAction || this._selected);

    // Some weird tween bookkeeping
    this._boosterActiveOffset = 20;
    this._tweenObj = { angle: -15, alpha: 1 };
    this._tweenData.push(game.add.tween(this._tweenObj).to({ angle: 15 }, 2000, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true));
    this._tweenData.push(game.add.tween(this._tweenObj).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true));
    this._angleMulti = 0;

    // The price of the booster
    let price = G.json.settings[`priceOfBooster${nr}`];
    if (OMT.feature.isMultipleBoosterBuyingPanelEnabled()) {
      price *= 3;
    }
    this._priceLabel = new G.LabelTextT(
      /* eslint-disable-next-line prefer-template */ // Doesn't really work with nested variables
      price + '@currency@',
      0, 35, {
        style: 'font-white',
        fontSize: '40px',
      }, 0.5, 85,
    );
    this.add(this._priceLabel);
    this._booster_amount = G.makeImage(40, 30, 'booster_ammount', 0.5, this);
    this._booster_amount.visible = false;

    // The amount of boostersy ou have left
    let _amountStyle = G.OMTsettings.elements.UI_BoosterButton.amount.style;
    const { isNotNormalLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const { isDailyChallengeLevel } = LvlDataManager.getInstance();
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallengeLevel;
    if (isNotNormalLevel) {
      _amountStyle = {
        style: useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_2') : OMT_VILLAINS.getPrefixedName('hard_2'),
        fontSize: 25,
      };
    }
    this._amount = new G.Text(40, 33, G.saveState.getBoosterAmount(nr).toString(), _amountStyle, 0.5, 100);
    this.add(this._amount);

    // The hand that shows up during tutorial
    this._hand = G.makeImage(0, 0, 'tut_hand', [24 / 90, 11 / 98], this);
    this._hand.visible = false;
    this._alphaTween = false;

    // The plus sign for booster selling panel
    this._plusIcon = new Phaser.Group(game, this);
    this._plusIconBG = new G.Image(0, 0, 'btn_plus', 0.5, this._plusIcon);
    this._plusIcon.scale.setTo(0.5);
    this._plusIcon.position.setTo(40, 33);

    // Refresh
    this._refreshBoosterAmount();

    // Various signals
    this._signalBindings.push(G.sb('refreshBoosterAmount').add((boosterNum) => {
      if (boosterNum !== this._boosterNr) return;
      this._refreshBoosterAmount();
    }));

    this._signalBindings.push(G.sb('onBoosterSelect').add((boosterData) => {
      const boosterNum = boosterData.boosterNr;
      if (boosterNum === this._boosterNr) {
        this.select();
      } else {
        this.squeeze();
      }
    }));

    this._signalBindings.push(G.sb('onBoosterUsed').add((boosterNum) => {
      if (boosterNum === this._boosterNr) {
        this.deselect();
      } else {
        this.unsqueeze();
      }
    }));

    this._signalBindings.push(G.sb('onBoosterDeselect').add((boosterNum) => {
      if (boosterNum === this._boosterNr) {
        this.deselect();
      } else {
        this.unsqueeze();
      }
    }));
  }

  /**
   * Destroy
   */
  destroy() {
    if (this._signalBindings) {
      this._signalBindings.forEach((binding) => {
        if (binding.detach) {
          binding.detach();
        }
      });
      this._signalBindings = null;
    }
    if (this._tweenData) {
      this._tweenData.forEach((tween) => {
        tween.stop();
      });
      this._tweenData = null;
    }
    super.destroy();
  }

  /**
   * Refreshes the booster and checks what to show and how many
   */
  _refreshBoosterAmount() {
    if (G.saveState.getBoosterAmount(this._boosterNr) === 0) {
      this._booster_amount.visible = false;
      this._amount.visible = false;
      if (OMT.feature.isMultipleBoosterBuyingPanelEnabled()) {
        this._priceLabel.visible = false;
        this._plusIcon.visible = true;
      } else {
        this._priceLabel.visible = true;
        this._plusIcon.visible = false;
      }
    } else {
      this._booster_amount.visible = true;
      this._amount.visible = true;
      this._priceLabel.visible = false;
      this._plusIcon.visible = false;
      this._amount.setText(G.saveState.getBoosterAmount(this._boosterNr).toString());
    }
  }

  /**
   * Mostly an Animation update for when it wiggles up
   */
  update() {
    this.angle = this._angleMulti * this._tweenObj.angle;
    this.x = this.orgX;
    this.y = this.orgY - (this._angleMulti * this._boosterActiveOffset);

    this._hl.angle2++;
    this._hl.angle = -this.angle + this._hl.angle2;
    this._hl.alpha = G.lerp(this._hl.alpha, this._selected || this.tutHighlight ? 0.5 : 0, 0.1);
  }

  /**
   * Select, ON!
   */
  select() {
    // Signals the overlay to clear the board and add the booster to the above group
    G.sb('startOverlay').dispatch([
      ['clearBoard'],
      ['moveToAboveGroup', this, 'boosterGroup'],
    ]);

    this._selected = true;
    game.add.tween(this).to({ angleMulti: 1 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
  }

  /**
   * Signal OFF
   */
  deselect() {
    G.sb('closeOverlay').dispatch();

    this._selected = false;
    game.add.tween(this).to({ angleMulti: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
  }

  /**
   * Animation
   */
  squeeze() {
    game.add.tween(this.scale).to({ x: this.orgScaleX * 0.8, y: this.orgScaleY * 0.8 }, 300, Phaser.Easing.Sinusoidal.Out, true);
  }

  /**
   * Animation
   */
  unsqueeze() {
    if (this.scale.x === 1) return;
    game.add.tween(this.scale).to({ x: this.orgScaleX * 1, y: this.orgScaleY * 1 }, 300, Phaser.Easing.Sinusoidal.Out, true);
  }

  /**
   * Cannot be clicked
   */
  lock() {
    this.ignoreChildInput = true;
  }

  /**
   * Now clickable
   */
  unlock() {
    this.ignoreChildInput = false;
  }

  /**
   * Hides the hand
   */
  hideSuggestion() {
    if (this._hand.alpha === 0) return;


    if (this._alphaTween) this._alphaTween.stop();
    G.stopTweens(this._hand);
    this._alphaTween = game.add.tween(this._hand).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true);
    this._alphaTween.onComplete.add(() => { this._hand.visible = false; });
  }

  /**
   * Shows the hand
   */
  showSuggestion() {
    if (this._openLock) return;

    if (this._alphaTween) this._alphaTween.stop();
    this._hand.alpha = 0;
    this._hand.visible = true;
    this._alphaTween = game.add.tween(this._hand).to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.Out, true);

    this._animateHand(this._hand, this._btn);

    this._openLock = true;

    if (!G.tutorialOpened) {
      game.time.events.add(5000, () => {
        this.hideSuggestion();
      });
    }

    game.time.events.add(15000, () => {
      this._openLock = false;
    });
  }

  /**
   * Makes the hand point
   * @param {DisplayObject} hand
   * @param {DisplayObject} target
   */
  _animateHand(hand, target) {
    const vector = this._getHandSlideVector(target);
    const angle = Math.atan2(-vector.y, vector.x);
    const distanceFrom = 50;
    const distanceTo = distanceFrom + 80;
    hand.position.setTo(
      distanceFrom * vector.x,
      distanceFrom * vector.y,
    );
    const assetVisualRotation = ((25 + 90) * Math.PI) / 180;
    hand.rotation = Math.PI + assetVisualRotation - angle;
    this._tweenData.push(game.add.tween(hand).to({ x: distanceTo * vector.x, y: distanceTo * vector.y }, 800, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true));
  }

  /**
   * Does a calculation...
   * @param {DisplayObject} target
   */
  _getHandSlideVector(target) {
    const screenWidth = 640;
    const screenHeight = 960;
    const vector = new Phaser.Point(0.5 * screenWidth, 0.5 * screenHeight);
    vector.subtract(target.worldPosition.x, target.worldPosition.y);
    vector.normalize();
    return vector;
  }

  /**
   * Highlights something
   */
  showTutorialHighlight() {
    this.tutHighlight = true;
    this._priceLabel.alpha = 0;
    const destY = this._isLandscape ? 50 * this._gameScale : 50;
    game.add.tween(this.pivot)
      .to({ y: destY }, 300, Phaser.Easing.Sinusoidal.Out, true);
    this.parent.bringToTop(this);
  }

  /**
   * Hides it
   */
  hideTutorialHighlight() {
    this.tutHighlight = false;
    this._priceLabel.alpha = 1;
    this._amount.alpha = 1;
    G.stopTweens(this.pivot);
    game.add.tween(this.pivot)
      .to({ y: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true);
  }

  /**
   * During tutorial levels, the new booster is free to use infinitely
   */
  setUnlimitedMode() {
    this._unlimitedMode = true;
    if (this._priceLabel && this._priceLabel.parent) {
      this._priceLabel.parent.removeChild(this._priceLabel);
    }
    if (this._booster_amount && this._booster_amount.parent) {
      this._booster_amount.parent.removeChild(this._booster_amount);
    }
    if (this._amount && this._amount.parent) {
      this._amount.parent.removeChild(this._amount);
    }
  }

  /**
   * Handle the click on the booster button
   */
  handleClick() {
    if (this._selected && !G.tutorialOpened) { // If it was selected, toggled it back down
      return G.sb('onBoosterDeselect').dispatch(this._boosterNr);
    }
    // eslint-disable-next-line consistent-return
    if (this._state.board.actionManager.hasActiveAction) return; // If animation is going, don't do anything

    // If you don't have enough boosters but you can buy it, wiggle it up
    if (G.saveState.getBoosterAmount(this._boosterNr) > 0 || this._unlimitedMode) {
      G.sb('onBoosterSelect').dispatch({
        boosterNr: this._boosterNr,
        unlimitedMode: this._unlimitedMode,
      });
    } else if (OMT.feature.isMultipleBoosterBuyingPanelEnabled()) {
      this.setupAndDispatchItemShop();
    } else if (G.saveState.isEnoughToBuyBooster(this._boosterNr)) {
      G.sb('onBoosterSelect').dispatch({
        boosterNr: this._boosterNr,
        unlimitedMode: this._unlimitedMode,
      });
    } else {
      this.handleNotEnoughCoins();
    }
    return true;
  }

  /**
   * Open the shop to let the user buy coins to buy a booster later on
   */
  handleNotEnoughCoins() {
    if (!G.Helpers.pushOutOfCoinsPopUp(false, { event: OMT.platformTracking.Events.OpenShopBoosters })) {
      // If you don't have enough mmoney, show the shop
      // Price label animation
      G.stopTweens(this._priceLabel);
      this._priceLabel.scale.setTo(1);
      this._tweenData.push(game.add.tween(this._priceLabel.scale).to({ x: 0.6, y: 1.4 }, 150, Phaser.Easing.Bounce.InOut, true, 0, 2, true));
    }
  }

  /**
   * Configure and launch an item shop that sells this booster
   */
  setupAndDispatchItemShop() {
    const { _boosterNr } = this;
    const multiBoosterBuy = G.featureUnlock.multipleBoosterBuyingPanel.multiAndOne;
    const boosterShopPanelConfig = Window_ItemShop.getDefaultConfigObject();
    const boosterDetails = Window_ItemShop_ITEM_DETAILS.BOOSTERS[_boosterNr];
    const { keyBase } = Window_ItemShop_ITEM_DETAILS.BOOSTERS;
    const { name, description } = boosterDetails;
    boosterShopPanelConfig.title = OMT.language.getText(name);
    const texture = keyBase + _boosterNr;
    const boosterQuantity = 3;
    boosterShopPanelConfig.item.image.texture = texture;
    boosterShopPanelConfig.item.quantity.text = `x${boosterQuantity}`;
    boosterShopPanelConfig.item.description.text = OMT.language.getText(description);
    boosterShopPanelConfig.item.image.scale = multiBoosterBuy ? 1.45 : boosterShopPanelConfig.item.image.scale;
    const singlePrice = G.json.settings[`priceOfBooster${_boosterNr}`];
    const discount = G.json.settings.multiBoosterDiscount;
    const multiPrice = singlePrice * boosterQuantity;
    boosterShopPanelConfig.item.price = {
      single: singlePrice,
      multi: multiPrice - (multiPrice * discount),
    };

    const onBuyComplete = (price, quantity) => {
      if ((quantity === 1 && G.saveState.isEnoughToBuyBooster(_boosterNr))
      || (quantity === 3 && G.saveState.isEnoughToBuyThreeBoosters(_boosterNr, discount))) {
        G.saveState.trackMultipleBoosterBought({
          boosterNum: _boosterNr,
          quantity,
          coinCost: price,
          lvlIndex: G.lvlData.levelIndex,
        });
        G.saveState.refreshAllBoosterAmounts();

        let delay = 500;

        const getItemLocations = () => {
          const result = [];
          const currentXPos = this.worldPosition.x / game.width;
          const currentYPos = this.worldPosition.y / game.height;
          if (OMT_SystemInfo.getInstance().orientation === ORIENTATION.vertical) {
            let xPos = currentXPos - 0.2;
            let yPos;
            for (let i = 0; i < quantity; i++) {
              xPos += 0.1;
              if (i === 1) {
                yPos -= 0.05;
              } else {
                yPos = currentYPos - 0.1;
              }
              result.push({ xPos, yPos });
            }
          } else {
            let xPos;
            let yPos = currentYPos - 0.2;
            for (let i = 0; i < quantity; i++) {
              yPos += 0.1;
              if (i === 1) {
                xPos -= 0.05;
              } else {
                xPos = currentXPos - 0.1;
              }
              result.push({ xPos, yPos });
            }
          }
          return result;
        };

        const itemLocations = getItemLocations();
        for (let i = 0; i < quantity; i++) {
          const { xPos, yPos } = itemLocations.shift();
          const floatingItem = new OMT_UI_FloatingItem({ x: xPos, y: yPos }, texture, this, () => {
            G.saveState.changeBoosterAmount(_boosterNr, 1);
          });
          floatingItem.goToTarget(delay);
          delay += 200;
          this._state.startBoosterFXLayer.add(floatingItem);
        }
      } else {
        this.handleNotEnoughCoins();
      }
    };
    boosterShopPanelConfig.details.onBuyComplete = onBuyComplete.bind(this);

    G.sb('pushWindow').dispatch(['itemShop', boosterShopPanelConfig]);
  }
}
