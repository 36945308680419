import UI_GoalTournamentBase from '../base/UI_GoalTournament';
import { TournamentSocialDisplay } from './Tournament/TournamentSocialDisplay';

export default class UI_GoalTournament extends UI_GoalTournamentBase {
  /**
   * Returns the class used for socialDisplay
   * @returns {Class}
   */
  _createTournamentDisplay() {
    return new TournamentSocialDisplay();
  }

  /**
   * Creates the points counter
   */
  _createPointsCounter() {
    this._pointsCounter = new G.Text(0, 0, `${0} ${OMT.language.getText('points')}`, 'UIGoalPanelPointAmount-tournament', 0.5, 200);
    this.add(this._pointsCounter);
  }

  /**
   * Updates text
   * @param {boolean} forceUpdate
   */
  update(forceUpdate = false) {
    const { points } = G.lvl;
    if (this._points !== points || forceUpdate) {
      this._pointsCounter.setText(`${points} ${OMT.language.getText('points')}`);
      this._points = points;
      this._socialDisplay.setPoints(this._points);
    }
  }
}
