import { Window } from '../../../00_IMMEDIATE/Window';
import { CHARACTER_KEYS } from '../../GingyTutorial/G.GingyCharacterTutorial';
import { SagaMap_Tutorial } from '../../GingyTutorial/SagaMap_Tutorial';

class Window_CrossPromoLanding extends Window {
  /**
   * Landing window when coming from another game's cross promo entry.
   * Mostly for Gingy to come and welcome the user with text.
   * Most of the state adjustments are done in the payload checker already
   * @param {Object} parent
   * @param {{msg:Array<{position:string, text:string, localize?:boolean}>, onComplete:function, onStart:function}} config
   */
  constructor(parent, config) {
    super(parent);
    this._config = config;
    this._msg = this._config.msg;
    this._totalSteps = this._msg.length - 1;
    this._proceedText = false;
    this._curStep = 0;

    this._initGingy();
    this._readMessage();

    this._startMessages();
  }

  /**
   * Inits gingy and hitbox
   */
  _initGingy() {
    this._gingyTutorial = new SagaMap_Tutorial({ game, scale: 1, character: CHARACTER_KEYS.gingy });
    this._gingyTutorial.toggleVisibility(false);

    this._gingyTutorialHitbox = G.makeImage(0, 0, null, 0, game.world);
    this._gingyTutorialHitbox.inputEnabled = true;
    this._gingyTutorialHitbox.input.useHandCursor = true;
    this._gingyTutorialHitbox.hitArea = new Phaser.Rectangle(-2000, -2000, 4000, 4000);
    this._gingyTutorialHitbox.events.onInputDown.add(this._readMessage.bind(this));
  }

  /**
   * Starts reading messages
   */
  async _startMessages() {
    if (this._config.onStart) {
      this._config.onStart();
    }
    await this._proceedMessage();
  }

  /**
   * Gingy tutorial, and when showing the next step to Gingy text
   */
  async _proceedMessage() {
    const textData = this._msg[this._curStep];
    await this._gingyTutorial.displayMsgAsync(textData);
    this._proceedText = true;
    this._curStep++;
  }

  /**
   * When user clicks
   * @returns {null}
   */
  async _readMessage() {
    if (!this._proceedText) { return; } // Block clicking until ready
    this._proceedText = false; // No clicks from now
    if (this._curStep > this._totalSteps) {
      this._gingyTutorialHitbox.events.onInputDown.removeAll(); // Remove click listeners
      await this._gingyTutorial.close(); // Close gingy

      if (this._config.onComplete) {
        this._config.onComplete();
      }
      this.closeWindow();
    } else {
      await this._proceedMessage(); // Increment text
    }
  }

  /**
   * Closes window, cleans up!
   */
  closeWindow() {
    if (this._gingyTutorialHitbox) {
      if (this._gingyTutorialHitbox.parent) {
        this._gingyTutorialHitbox.parent.removeChild(this._gingyTutorialHitbox);
      }
      this._gingyTutorialHitbox.destroy();
    }
    super.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.crossPromoLanding = Window_CrossPromoLanding;
