import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

G.TreasureHunt = G.TreasureHunt || {};

G.TreasureHunt.GingyFailCharacter = function () {
  Phaser.Group.call(this, game);

  this.fixedToCamera = true;

  this.gingy = G.makeImage(0, 300, 'Cookie_Pirate_Header', [0.5, 169 / 185], this);
  this.gingy.scale.setTo(1.2);
  this.gingy.name = 'gingyCharacter';

  this.msg = game.add.group(this);
  this.msg.name = 'speakBubble';
  this.msg.scale.setTo(0);
  this.msg.position.setTo(50, -90);
  this.bubble = G.makeImage(0, 0, 'info_bubble', 0, this.msg);
  this.bubble.scale.setTo(1.8, -1.8);

  const textProps = {
    x: this.bubble.x + this.bubble.width / 2,
    y: this.bubble.y + this.bubble.height / 2 + 4,
    fontSize: 25,
    lineSpacing: -10,
    width: 120,
    height: 110,
  };

  if (OMT.language.lang === 'th') {
    textProps.y = this.bubble.y + this.bubble.height / 2;
    textProps.lineSpacing = 0;
    textProps.fontSize = 14;
    textProps.width = 148;
  }

  this.msgTxt = this.msg.add(new G.Text(textProps.x, textProps.y,
    OMT.language.getText('Don’t quit! You’ll lose your treasure!'), {
      style: 'font-blue',
      fontSize: textProps.fontSize,
      lineSpacing: textProps.lineSpacing,
    }, 0.5, textProps.width, textProps.height, true, 'center'));

  G.sb('onScreenResize').add(this.onResize, this);
  this.onResize();
};

G.TreasureHunt.GingyFailCharacter.prototype = Object.create(Phaser.Group.prototype);

G.TreasureHunt.GingyFailCharacter.prototype.onResize = function () {
  this.cameraOffset.x = Math.floor(game.width * 0.15);
  this.cameraOffset.y = OMT.systemInfo.orientation === ORIENTATION.wertical
    ? game.height
    : game.height + 21;
};

G.TreasureHunt.GingyFailCharacter.prototype.show = function () {
  const animation = G.Animations.getTHFailGingyAnimationShow();
  animation.animate(this.game, this);
};

G.TreasureHunt.GingyFailCharacter.prototype.hide = function () {
  const animation = G.Animations.getTHFailGingyAnimationHide();
  animation.animate(this.game, this);
};
