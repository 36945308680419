import { Window_SpecialEventLeaderboard } from '../../../../OMT_UI/SpecialEvents/Leaderboard/Window_SpecialEventLeaderboard';
import { TokenEvent_LeaderboardHeader } from './Components/TokenEvent_LeaderboardHeader';

/**
 * class for tokenEvent leaderboard window
 */
class Window_TokenEventLeaderboard extends Window_SpecialEventLeaderboard {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} returnArgs An object package that will re-open the a window that was given to it, as well as its args and layer
   * @param {string} returnArgs.windowName The window to re-open to
   * @param {Array<any>} returnArgs.args The args to go to that window
   * @param {string} returnArgs.layerName The layer for that window
   */
  constructor(parent, returnArgs) {
    super(parent, (G.OMTsettings.tokenEvent != null) ? G.OMTsettings.tokenEvent.leaderboardConfig : {});
    this._returnArgs = returnArgs;

    const { collectMsg } = this._config;
    if (collectMsg) {
      const msgText = OMT.language.getText(collectMsg.text).replace('%CURRENCY%', OMT.language.getText(collectMsg.currency));
      this._initCollectMessageField(msgText, collectMsg.fieldConfig || null);
    }
  }

  /**
   * create the header group containing the title
   */
  _initHeaderGroup() {
    const { header: headerConfig } = this._config;
    if (headerConfig) {
      const headerGroup = new TokenEvent_LeaderboardHeader(headerConfig);
      this.addChild(headerGroup);
      this._headerGroup = headerGroup;
    }
  }

  /**
   * on play button clicked
   */
  _onPlayClicked() {
    this._returnArgs = null;
    G.sb('pushWindow').dispatch(['eventLevel', G.saveState.getEventLevel(false)]);
    this.closeWindow();
  }

  /**
   * Checks if there was any args that was given. If there it, it will re-open the window (assuming nothing is in queue)
   * then continues to close itself
   */
  closeWindow() {
    if (this._returnArgs && this._returnArgs.layerName) {
      G.sb('pushWindow').dispatch([this._returnArgs.windowName].concat(this._returnArgs.args), false, this._returnArgs.layerName);
    }
    super.closeWindow();
  }
}

if (!window.Windows) window.Windows = {};
Windows.tokenEventLeaderboard = Window_TokenEventLeaderboard;
