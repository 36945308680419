import WorldMap2_Util, { interactionType } from '../WorldMap2_Util';

/* eslint-disable no-use-before-define */
export default class WorldMapSagaMapChestEntry extends G.PoolGroup {
  constructor() {
    super(WorldMapSagaMapChest);

    this._interactionType = interactionType.chestMap;
    this._actives = [];
    this.signals = {
      onChestClicked: new Phaser.Signal(),
    };
  }

  moveTiles(deltaY) {
    // update active segments
    for (let i = this._actives.length - 1; i >= 0; i--) {
      this._actives[i].y += deltaY;
    }
  }

  killTile(index) {
    const tile = this._actives.find((seg) => seg.index === index);
    if (tile) {
      tile.kill();
      this._actives.splice(this._actives.indexOf(tile), 1);
    }
  }

  /**
   * @param {{index: number, unlockLevel:number, seed:number, startY:number, tileIndex:number, dummy:boolean}} config
   * @returns {(WorldMapSagaMapChestEntry | null)}
   */
  addTile(config) {
    if (config.index > 0) { return null; }
    const existingChest = config.dummy || G.saveState.mapChestDataManager.getMapChestNearestToLevel(config.unlockLevel);
    if (existingChest) {
      const chest = this.getFreeElement();
      chest.interactionType = this._interactionType;
      const positoning = WorldMap2_Util.getEntryInArrayLoop(WorldMap2_Util.getEntryInArrayLoop(G.OMTsettings.elements.worldMap.sagaMapChestPositions, config.tileIndex), config.seed);
      chest.x = positoning.x;
      chest.setChestScale(positoning.scale);
      chest.y = positoning.y + config.startY;
      chest.init(config.index, existingChest, config.dummy);
      chest.onClick.add(() => {
        if (chest.interactive) {
          this.signals.onChestClicked.dispatch(chest);
        } else {
          chest.jump();
        }
      });
      this._actives.push(chest);
      this.addChild(chest);
      return this;
    }
    return null;
  }

  /**
   *
   * @param {WorldMapSagaMapChest} chest
   */
  onOpened(chest) {
    const okToKill = chest.onOpened();
    if (okToKill) {
      game.add.tween(chest).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 1000).onComplete.add(() => {
        this.killTile(chest.index);
        chest.alpha = 1;
      });
    }
  }
}

class WorldMapSagaMapChest extends G.Image {
  constructor() {
    super(0, 0, null);
    this._chestGroup = new Phaser.Group(game, this);
    this._shadow = G.makeImage(0, 40, 'chest_shadow', 0.5, this._chestGroup);
    this._chest = new G.Button(0, 0, 'chest');
    this._chestGroup.addChild(this._chest);
    this._chest.scale.set(1);
    this._chest.IMMEDIATE = true;
    this._loopTimer = undefined;

    this._allTweens = [];
    this._signalTokens = [
      G.sb('pauseAllUpdate').add(this._pauseAnimation.bind(this)),
      G.sb('resumeAllUpdate').add(this._resumeAnimation.bind(this)),
    ];
    this._resumeAnimation();
  }

  /**
   * Destroy!
   */
  destroy() {
    for (const signal of this._signalTokens) if (signal) signal.detach();
    this._signalTokens.length = 0;
    super.destroy();
  }

  get onClick() {
    return this._chest.onClick;
  }

  /**
   *
   * @param {*} index
   * @param {{sr:number, id:number}} chestData
   * @param {boolean} dummy
   */
  init(index, chestData, dummy) {
    this.alpha = 1;
    this.index = index;
    this.chestData = chestData;
    this.id = this.chestData.id;
    let starRequirement = 0;
    if (dummy) {
      this._currentStars = 10000;
      this.interactive = true;
    } else {
      this._currentStars = G.saveState.getAllStars();
      starRequirement = Math.floor(G.saveState.mapChestDataManager.calculateStarsFromLevel(this.chestData.i));
      this.interactive = this._currentStars >= starRequirement;
    }

    G.changeTexture(this._chest, 'chest');
    this._chest.y = 0;
    this._chest.angle = 0;
    this._shadow.scale.set(1);
    this._shadow.alpha = 1;
    if (this.interactive) {
      this._chestGlow = G.makeImage(0, 0, 'chest', 0.5, this._chest);
      this._chestGlow.blendMode = 1;
      this._chestGlow.alpha = 0.4;
      this._allTweens.push(game.add.tween(this._chestGlow).to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true));
      this._chestGlow.visible = true;
      this._loopTimer = game.time.events.loop(2000, this.jump.bind(this));
    } else {
      this.label = new G.LabelTextT(
        `${Math.min(this._currentStars, starRequirement)}/${starRequirement}@map-nodes/map_star_1@`,
        0, 50, {
          style: 'font-white',
          fontSize: '40px',
        }, 0.5, 150,
      );
      this.addChild(this.label);
    }
    this.revive();
  }

  setChestScale(sc) {
    this._chestGroup.scale.x = sc;
  }

  kill() {
    if (this._chestGlow) {
      this._chestGlow.destroy();
      this._chestGlow = null;
    }
    if (this.label) {
      this.label.destroy();
      this.label = null;
    }
    this._removeJumpTimer();
    this.killTweens();
    this.onClick.removeAll();
    super.kill();
  }

  jump() {
    this.killTweens();
    this._shadow.scale.set(1);
    this._shadow.alpha = 1;
    this._chest.angle = 0;
    this._chest.y = 0;
    if (!this._isTweenAllowedToRun()) { return; }
    const moveTweenA = game.add.tween(this._chest).to({ y: -50 }, 300, Phaser.Easing.Cubic.Out);
    const shadowTweenA = game.add.tween(this._shadow).to({ alpha: 0.3 }, 300, Phaser.Easing.Circular.Out);
    const shadowTweenC = game.add.tween(this._shadow.scale).to({ x: 1.15, y: 1.15 }, 300, Phaser.Easing.Circular.Out);
    moveTweenA.onComplete.addOnce(() => {
      if (!this._isTweenAllowedToRun()) { return; }
      const moveTweenB = game.add.tween(this._chest).to({ y: 0 }, 300, Phaser.Easing.Circular.In);
      const shadowTweenB = game.add.tween(this._shadow).to({ alpha: 1 }, 300, Phaser.Easing.Circular.In);
      const shadowTweenD = game.add.tween(this._shadow.scale).to({ x: 1, y: 1 }, 300, Phaser.Easing.Circular.In);
      moveTweenB.start();
      shadowTweenB.start();
      shadowTweenD.start();
      this._allTweens.push([moveTweenB, shadowTweenB, shadowTweenD]);
    });

    const tweenAngleA = game.add.tween(this._chest).to({ angle: -15 }, 200, Phaser.Easing.Cubic.InOut);
    tweenAngleA.onComplete.addOnce(() => {
      if (!this._isTweenAllowedToRun()) { return; }
      const tweenAngleB = game.add.tween(this._chest).to({ angle: 15 }, 375, Phaser.Easing.Sinusoidal.In);
      this._allTweens.push(tweenAngleB);
      tweenAngleB.start();
      tweenAngleB.onComplete.addOnce(() => {
        if (!this._isTweenAllowedToRun()) { return; }
        const tweenAngleC = game.add.tween(this._chest).to({ angle: 0 }, 50, Phaser.Easing.Cubic.InOut);
        this._allTweens.push(tweenAngleC);
        tweenAngleC.start();
      });
    });
    moveTweenA.start();
    tweenAngleA.start();
    shadowTweenA.start();
    shadowTweenC.start();

    this._allTweens.push([moveTweenA, tweenAngleA, shadowTweenA, shadowTweenC]);
  }

  /**
   * Removes the timer event to call jump
   */
  _removeJumpTimer() {
    if (this._loopTimer) {
      game.time.events.remove(this._loopTimer);
      this._loopTimer = null;
    }
  }

  /**
   * Removes every tween
   */
  killTweens() {
    for (const tw of this._allTweens) {
      if (tw.stop) {
        tw.stop();
      }
    }
    this._allTweens.length = 0;
  }

  onOpened() {
    if (this.interactive) {
      G.changeTexture(this._chest, 'chest_open');
      G.changeTexture(this._chestGlow, 'chest_open');
      this._removeJumpTimer();
      this.killTweens();

      this.chestData.o = true;

      const chestGifts = G.saveState.mapChestDataManager.openChest(this.chestData.i);

      this.interactive = false;

      G.sb('pushWindow').dispatch(['gift', {
        showTitleBar: false,
        showGingy: false,
        showGingyGiftBar: false,
        autoOpen: true,
        autoOpenDelay: 700,
        reason: G.GiftUI.Constants.GiftReasons.Chest,
        shareable: false,
        gifts: chestGifts,
        monetiseType: false,
        onlyOneBox: true,
        onClaim: () => {
          G.sb('mapChestWindowClosed').dispatch();
          G.saveState.mapChestDataManager.save();
        },
        ddnaEventData: {
          transactionType: 'REWARD',
          tActionType: 'MAP_CHEST',
          tGameArea: 'MAP',
        },
      }], false, G.WindowMgr.LayerNames.BelowHeaderPanel);
      return true;
    }

    this._chest.inputEnabled = false;
    this.jump(() => {
      this._chest.inputEnabled = true;
      this._chest.input.useHandCursor = true;
    });
    return false;
  }

  /**
   * Sets the animation flag to off
   */
  _pauseAnimation() {
    this._animate = false;
  }

  /**
   * Sets the animation flag to on
   */
  _resumeAnimation() {
    this._animate = true;
  }

  /**
   * Checks if this is allowed to animate
   */
  _isTweenAllowedToRun() {
    return this._animate && this.alive;
  }
}
