import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';
import GingyCharacterTutorial from '../../../GingyTutorial/G.GingyCharacterTutorial';

const MASCOT_LOOKAT = { x: 0.1, y: -0.1 };

const SPEECH_BUBBLE_RECT_HORIZONTAL = { width: 250, height: 100, cornerSize: 30 };
const SPEECH_BUBBLE_RECT_VERTICAL = { width: 360, height: 100, cornerSize: 30 };
const SPEECH_BUBBLE_BG_COLOR = 0xffffff;
const SPEECH_BUBBLE_TEXT_NORMAL = 'Come take a look at what I baked today!';
const SPEECH_BUBBLE_TEXT_WARNING = 'Are you sure? Cookies are better when they are warm.';
const SPEECH_BUBBLE_TEXT_STYLE = 'window_popupOffer-mascotTxt';
const SPEECH_BUBBLE_TEXT_OFFSET = { x: -40, y: -10 };
const SPEECH_BUBBLE_ANIMATION_DURATION = 250;
const SPEECH_BUBBLE_ANIMATION_DELAY = 100;

/**
 * mascot speech bubble component
 */
class TimedTargetedOffer_MascotSpeechBubble extends Phaser.Group {
  /**
   * constructor
   */
  constructor() {
    super(game);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;

    this._drawBubbleBg();
    this._drawTextField();
  }

  /**
   * draw the bubble background
   */
  _drawBubbleBg() {
    const bgGraphics = new Phaser.Graphics(game);
    const speechBubbleRect = this._isLandscape ? SPEECH_BUBBLE_RECT_HORIZONTAL : SPEECH_BUBBLE_RECT_VERTICAL;
    bgGraphics.beginFill(SPEECH_BUBBLE_BG_COLOR);
    bgGraphics.drawRoundedRect(
      speechBubbleRect.x, speechBubbleRect.y,
      speechBubbleRect.width, speechBubbleRect.height,
      speechBubbleRect.cornerSize,
    );
    bgGraphics.endFill();
    bgGraphics.x = -bgGraphics.width / 2;
    bgGraphics.y = -bgGraphics.height / 2;
    this.addChild(bgGraphics);
  }

  /**
   * draw the bubble text field
   */
  _drawTextField() {
    this._textFieldNormal = new G.Text(
      0, 0,
      OMT.language.getText(SPEECH_BUBBLE_TEXT_NORMAL),
      SPEECH_BUBBLE_TEXT_STYLE,
      0.5,
      this.width + SPEECH_BUBBLE_TEXT_OFFSET.x,
      this.height + SPEECH_BUBBLE_TEXT_OFFSET.y,
      true, 'center',
    );
    this.addChild(this._textFieldNormal);

    this._textFieldWarning = new G.Text(
      0, 0,
      OMT.language.getText(SPEECH_BUBBLE_TEXT_WARNING),
      SPEECH_BUBBLE_TEXT_STYLE,
      0.5,
      this.width + SPEECH_BUBBLE_TEXT_OFFSET.x,
      this.height + SPEECH_BUBBLE_TEXT_OFFSET.y,
      true, 'center',
    );
    this._textFieldWarning.alpha = 0;

    this.addChild(this._textFieldWarning);
  }

  /**
   * animate in the bubble
   */
  showWarning() {
    const fadeOut = game.add.tween(this._textFieldNormal).to(
      { alpha: 0 },
      SPEECH_BUBBLE_ANIMATION_DURATION,
      Phaser.Easing.Linear.None,
      false,
      SPEECH_BUBBLE_ANIMATION_DELAY,
    );

    const fadeIn = game.add.tween(this._textFieldWarning).to(
      { alpha: 1 },
      SPEECH_BUBBLE_ANIMATION_DURATION,
      Phaser.Easing.Linear.None,
      false,
      0,
    );

    fadeOut.chain(fadeIn);
    fadeOut.start();
  }
}

/**
 * class for TimedTargetedOffer mascot group
 */
export class TimedTargetedOffer_MascotGroup extends Phaser.Group {
  constructor(mascotConfig, bubbleConfig) {
    super(game);
    this._initMascot(mascotConfig);
    this._initSpeechBubble(bubbleConfig);
  }

  /**
   * Inits mascot character
   * @param {*} mascotConfig
   */
  _initMascot(mascotConfig) {
    this._character = new GingyCharacterTutorial(0, 0, mascotConfig.character);
    this._character.lookAt(MASCOT_LOOKAT);
    this._character.scale.setTo(mascotConfig.scale, mascotConfig.scale);
    this._character.alpha = 1;
    this._character.x = mascotConfig.offsetX;
    this._character.y = mascotConfig.offsetY;
    this.add(this._character);
  }

  /**
   * Inits the speech bubble
   * @param {Object} bubbleConfig
   */
  _initSpeechBubble(bubbleConfig) {
    this._speechBubble = new TimedTargetedOffer_MascotSpeechBubble();
    this._speechBubble.x = bubbleConfig.offsetX;
    this._speechBubble.y = bubbleConfig.offsetY;
    this._character.addChild(this._speechBubble);
  }

  /**
   * Show closing warning text
   */
  showWarning() {
    this._speechBubble.showWarning();
    this._character.surprised();
  }
}
