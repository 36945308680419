
export const INSTANCE_SLUG = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
};

export default class OMT_TierLeaderboardUtil {
  /**
   * Returns the instance slug from a number. Used in treasureHuntManager
   * @param {number} is
   * @returns {INSTANCE_SLUG}
   */
  static getInstanceSlugStringFromNumber(is) {
    if (is === 1) { return INSTANCE_SLUG.POSITIVE; }
    return INSTANCE_SLUG.NEGATIVE;
  }

  /**
   * Returns a number from the instance slug. Used in treasureHuntManager
   * @param {INSTANCE_SLUG} is
   * @returns {number}
   */
  static getInstanceSlugNumberFromString(is) {
    if (is === INSTANCE_SLUG.POSITIVE) { return 1; }
    return 0;
  }

  /**
   * Gets the badge data for the given array of users in an object
   * This function then modifies each entry in the array by giving it a `badge` parameter
   * @param {Array<{id:string, badge:string}>} userListObjects
   * @param {string} badgeKey
   */
  static async getBadgesForUsers(userListObjects, badgeKey) {
    const badgeData = await OMT.connect.getPublicUserData({
      playerIds: userListObjects.map((a) => a.id),
      keys: [badgeKey],
    });
    for (const user of userListObjects) {
      if (badgeData[user.id]) {
        user.badge = badgeData[user.id][badgeKey];
      }
    }
  }
}
