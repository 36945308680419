import { OMT_UI_AvatarWithFrame } from './OMT_UI_AvatarWithFrame';

/**
 * Class for rotating brag friend avatars and updating their info
 */
export class OMT_UI_AvatarRotator extends OMT_UI_AvatarWithFrame {
  /**
   * @param {Array.<Object>} friends
   * @param {number} imageSize
   * @param {number} rotationDelay time in ms
   * @param {number} rotationFadeDuration time in ms
   * @param {Array.<string>} defaultFrames
   */
  constructor(friends, imageSize, rotationDelay, rotationFadeDuration, defaultFrames) {
    super(null, imageSize, defaultFrames);
    this._friends = friends.slice();
    this._rotationIndex = 0;
    this._rotationDelay = rotationDelay;
    this._rotationFadeDuration = rotationFadeDuration;
    this._initBackgroundImage();
  }

  /**
   * we need a background for when the image isnt fully visible
   * @param {number} color (optional)
   */
  _initBackgroundImage(color = 0xC95C2B) {
    const backgroundImage = new Phaser.Graphics(game);
    backgroundImage.beginFill(color);
    backgroundImage.drawRect(-this._imageSize / 2, -this._imageSize / 2, this._imageSize, this._imageSize);
    backgroundImage.endFill();
    this.addChildAt(backgroundImage, 0);
  }

  /**
   * start the rotating the avatar image
   */
  startRotation() {
    this._setFriend(this._friends[this._rotationIndex]);
  }

  /**
   * set the active friend load their Avatar image + text
   * @param {Objecty} friendData
   */
  _setFriend(friendData) {
    G.getExtTexture(friendData.image, (textureData) => {
      this._avatarImage.changeTexture(textureData);
      this._avatarImage.width = this._avatarImage.height = this._imageSize;
      this._avatarImage.alpha = 0;
      this._animateInThenOut();
    }).catch(() => {
      // could not load texture. halt.
    });
  }

  /**
   * set next friend in rotation
   */
  _setNextFriend() {
    this._rotationIndex = this._rotationIndex + 1 < this._friends.length ? this._rotationIndex + 1 : 0;
    this._setFriend(this._friends[this._rotationIndex]);
  }

  /**
   * animate the avatar
   */
  _animateInThenOut() {
    const imageTween = game.add.tween(this._avatarImage);
    imageTween.to({ alpha: 1 }, this._rotationFadeDuration, Phaser.Easing.Quadratic.Out, true);
    if (this._friends.length > 1) {
      imageTween.onComplete.addOnce(() => {
        this._timeout = setTimeout(() => {
          this._timeout = null;
          this._animateOut();
        }, this._rotationDelay);
      });
    }
  }

  /**
   * animate out the avatar
   */
  _animateOut() {
    const imageTween = game.add.tween(this._avatarImage);
    imageTween.to({ alpha: 0 }, this._rotationFadeDuration, Phaser.Easing.Quadratic.In, true);
    imageTween.onComplete.addOnce(() => {
      this._setNextFriend();
    });
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    if (this._timeout) clearTimeout(this._timeout);
  }
}
