import { Window_GameModePromoBase } from './Window_GameModePromoBase';
import { UI_DailyChallengeIcon } from '../../UI_DailyChallengeIcon';
import { TournamentPromo_TutorialText } from './Components/TournamentPromo_TutorialText';
import { DailyChallengePromo_Header } from './Components/DailyChallengePromo_Header';

/**
 * class for tournament promotion window.
 */
export default class Window_DailyChallengePromo extends Window_GameModePromoBase {
  /**
  * constuctor
   * @param {Object} parent
   * @param {Object} config { icon:UI_DailyChallengeIcon }
   */
  constructor(parent, config) {
    super(parent, config);
    G.saveState.setDailyChallengePromoSeen();

    this._tutorialTextKey = "Daily Challenge? What's that? We should try tapping on it!";
  }

  /**
   * initialize display elements
   */
  _initDisplayElements() {
    this._initHeaderGroup(DailyChallengePromo_Header);
    // Uses the same tutorial text background as Tournament promo. This can be swapped out
    // in the future if the Daily Challenge promo gets redesigned
    this._initTutorialText(TournamentPromo_TutorialText);
    this._initGamePromoIcon(UI_DailyChallengeIcon);
  }

  /**
   * show the daily challenge window after clicking on the icon
   */
  async _showWindow() {
    this._mapIcon.alpha = 1;
    await this._promoIcon.showDailyChallengeWindow();
    this.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.dailyChallengePromotion = Window_DailyChallengePromo;
