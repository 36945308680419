G.featureUnlockDefault = {
  useNoGates: false,
  useWebGLRenderer: false,
  crossPromo: true, // Cross promo
  shoutoutAudio: false, // Shoutouts have audio?
  requestHelp: false, // Request Help
  requestMoveHelp: false, // Extra moves for sending message to friend. AKA Helper 2.0
  friendshipChest: {
    // Friendship chest
    enabled: true,
    storyMode: false, // Have normal friendship chest or story mode
  },
  targetedOffers: true, // use targeted offers? otherwise use popup offers
  FTUE5_0: true, // Use FTUE5.0?
  botOptInOnLoad: true, // Get asked to subscribe to bot when you enter the game
  treasureHunt: { // Treasure Hunt super, now active on certain days!
    enabled: false,
    range: [5, 6, 0], // Active days are Fri, Sat, Sun, UTC
    showUpdate: false,
  },
  extraMovesBubble: false, // The extra moves bubble that is not helper 2.0. Check settings.js for when it appears
  outOfLives_buyLife: true, // if false, player watches ad for lives
  // if true, allows player to buy unlimited lives time, if false, player can ask friends for more lives
  outOfLives_buyUnlimitedLives: true,
  mapGiftDoubling: false, // Double your 3h map gift in certain conditions
  starsForCoinsAlgorithm: true, // Algorithm is in settings.js levelCompleteReward
  tournamentPromo: true, // Whether to show the tournament promotion window / button
  dailyChallengePromo: true, // Whether to show the daily challenge promotion window / button
  gameTriggeredMessages: {
    // Activates game-triggered bot messages
    botMessages: false,
  },
  transactionTracking: {
    track: { // Activates "high-value" transaction event tracking
      ss: false, // player inventory snapshots
      rm: false, // real-money transactions
      inv: false, // in-game inventory transactions
      crmInv: false, // CRM inventory transactions, should always be false for now
    },
    purge: { // Determines whether the game should purge transaction histories
      ss: false,
      rm: false,
      inv: false,
      crmInv: false, // should always be false for now
    },
  },
  dailyReward: {
    // daily reward features
    allowTripleReward: true, // 3x reward allowed when doubling is enabled
  },
  eventPostcard: {
    enabled: false,
    range: {
      start: 1606262400000, // Nov 25, 2020 00:00:00 UTC
      end: 1606953600000, // Dec 3, 2020 00:00:00 UTC
    },
    levelButton: false,
  },
  fortuneCookie: {
    enabled: false,
    dropChance: 20, // The base drop chance (%) of a fortune piece
    movesLeft: 15, // Piece drops at > movesLeft
    dismissLimit: 3, // The number of times you can dismiss the fortune cookie before cooldown
    activeDays: [5, 6, 0], // Active days are Fri, Sat, Sun, UTC
    resetDay: 1, // The day (Mon) dismiss is reset
    // range: { // Adding a range will make sure it stays active within range
    //   start: 1588320000000, // May 1, 2020, 08:00:00 UTC
    //   end: 1588838400000, // May 7th, 2020, 08:00:00 UTC
    // }
  },
  achievementGifts: true, // Win 3 in a row, get a gift! This overrides the unlockLevel of the feature below.
  progressiveLevelReward: {
    // whether to calculate end of level reward coins by how many stars the player got
    enabled: false,
    // the increment of coins the player will earn depending on their stars.
    // this is cumulative, so if they go from 0 to 3 stars they get 0.1 + 0.3 + 0.6, which adds up to the total.
    increments: [0.1, 0.3, 0.6],
  },
  extraCoinsPerMove: { // Awards coins based off of extra moves left at EoL
    enabled: false,
    NORMAL: 5,
    HARD: 10,
    SUPER_HARD: 20,
  },

  freePrizeWheelSpin: true, // if this is false, the player will always have to watch an ad to spin the prize wheel
  freeFailFlowWheelSpin: true, // if this is false, the player will always have to watch an ad to spin the wheel on the oom window
  multipleExtraMovesFailFlow: false, // controls whether or not the fail flow wheel has three extra moves possibilities, rather than just one.

  // limit the number of times a wheel can be spun. Settings are in json/settings(IAP).js
  limitSpins: {
    prizeWheel: false,
    lossAversionWheel: false,
  },

  gateAlgorithm: 'aggressive', // Algorithm for gate unlocking

  // Add real money wheels to game
  realMoneyPrizeWheel: false,
  realMoneyHelperWheel: false,
  realMoneyTargetedOfferWheel: false,

  unlockLevels: {
    coinDouble: 8, // x2 coins at EOL
    isShowing3hGift: 10, // Unlock 3h gift
    interstitialAds: 10, // Interstitial ads show after this
    eventPostcard: 10, // The event for postcards
    tokenEvent: 10, // The level unlock for special events
    achievementGifts: 12, // Win 3 in a row, get a gift!
    dailyWheel: 14, // Daily wheel unlocks
    friendshipChest: 16, // Friendship chest unlocks
    requestHelp: 18, // Request help unlocks
    requestMoveHelp: 18, // Request help unlocks
    treasureHunt: 20, // Treasure Hunt unlocks
    dailyChallenge: 20, // Daily Challenge
    globalGoals: 20, // Daily missions unlocked
    mysteryGift: 21, // Mystery gift unlocks
    preLevelBoosterAd: 40, // The purple booster button on window_level
    tournamentPromo: 19, // tournament promotion overlay
    boosters: [
      null, // unused
      7, // swap
      9, // breaker
      14, // horizontal
      21, // vertical
      11, // +5 moves
      11, // +3 moves
      11, // special gems pack
      18, // color bomb
    ],
  },
  preventMysteryGift: false, // Prevents gaining any more mystery gifts. Allows existing streaks to keep going though
  reskin: {
    // Optional. Set this in game repo. Allows the build script to reskin the game and adding the asset
    easter: false,
    cincodemayo: false,
  },
  weeklyTournament: {
    // weekly tournament settings
    enabled: false,
  },
  dailyChallenge: {
    // daily challenge settings
    enabled: true,
  },
  brag2: {
    enabled: false,
  },
  multiplePreLevelBoosters: false, // Set this to allow for multiple pre level boosters on hard and super hard levels.
  specialShopDeal: {
    enabled: false,
    activeDays: [5, 6, 0], // Current active says are Fri, Sat, Sun
    range: {
      start: 1597881600000, // Aug 20, 2020 00:00:00 UTC
      end: 1598918400000, // Sep 1, 2020 00:00:00 UTC
    },
  },
  villains: false, // Setting this to false disables super hard levels, tutorials and corruption
  delayedBoardAppereance: false, // Set this to enable delayed board appereance for hard levels.
  tokenEvent: { // Special Events are token collectable events + skinning + postcards
    enabled: false,
    range: {
      start: 1649894400000, // Apr 14, 2022 12:00:00 AM GMT
      end: 1650499200000, // Apr 21, 2022 12:00:00 AM GMT
    },
  },
  allowLoyaltySupport: false,
  milestoneTracking: false,
  homescreenShortcut: {
    enabled: true,
    showAt: 10,
  },
  multipleBoosterBuyingPanel: {
    enabled: false,
    multiAndOne: false,
  },
  additionalInterstitialRules: {
    initial: false, // Toggles addition interstitial display rules. This is the initial implementaton. Includes EveryN, Out of Moves, Win 2 in a row, Give up
    tournamentWin: {
      enabled: false,
      level: 190, // Past 190 only
      playedFor: 20, // Played for 20+ days
    },
    giftAds: {
      enabled: false,
      level: 190, // Past 190 only
      playedFor: 20, // Played for 20+ days
    },
  },
  boosterLimiter: true, // Enables conversion of excess boosters to coins
  noAdsPopup: true, // Enables popup that tells player they can disable ads with an IAP
  weekendShopDeal: {
    enabled: false,
    startingDay: 5,
    startingFullHour: [0, 0, 0, 0],
    saleTime: 24 * 60 * 60 * 1000,
  },
  annuityPacks: {
    enabled: false,
  },
};
