/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
G.OMTsettings = {
  /* configuration for Delta DNA, comment out block or remove to disable */
  DDNA: {
    collect: 'https://collect15042cndyr.deltadna.net/collect/api/',
    engage: 'https://engage15042cndyr.deltadna.net/',
    env: G.BuildEnvironment.production ? '99892253560587544228437458715538' : '99892245470978161777838279315538',
    queueInterval: 1000,
    eventPriority: 2,
    ftuxVersion: 2, // this should be a integer
  },

  /* configuration for cross promotions */
  crossPromo: {
    promoId: 'candy-rain-7',
    enabled: true,
    showIcon: true,
    worldMapIcon: {
      width: 127,
      height: 127,
    },
  },

  /* configuration for tournaments */
  tournaments: {
    levelLoop: ['tournament_3', 'tournament_2288', 'tournament_2289', 'tournament_2290', 'tournament_2286', 'tournament_2287'],
    rotationOriginTimestamp: 1603065600000, // unix timestamp (1603065600000, is Monday 00:00:00, October 19th, 2020)
    rotationIntervalHrs: 24 * 7, // total hours for level rotation including the cooldown
    cooldownDurationHrs: 24 * 2, // hours at beginging of rotation
    worldMapIcon: {
      activeLabelOffset: { x: 0, y: 7 },
    },
    promotionPosOffset: {
      gingy: {
        y: 21,
      },
      tournamentText: {
        y: 14,
      },
    },
  },

  shoutouts: {
    amountTier0: 3,
    amountTier1: 2,
  },

  adFallback_AskFriend: {
    title: {
      style: { style: 'font-white', fontSize: 40 },
      offsetY: -20,
    },
    graphics: {
      evenColourBg: 0xffca93,
      oddColourBg: 0xfee6c4,
    },
  },

  requestHelp: {
    positionals: {
      useTwoPiecePopup: true,
      friendBg3Color: 0xF9A453,
      maskRadius: 25,
      listOffsetX: 0,
      scrollbarOffsetHeight: 0,
    },
  },
  requestMoveHelp: {
    feature: false,
    // the index on this array will be the amount of times the current level has been retried - 1
    // if the player has retried the levels more times than there are percentages,
    // we pick the last percentage in the array.
    probabilities: [
      10,
      20,
      30,
      40,
    ],
  },

  friendshipChest: {
    assets: {
      storyChests: [
        { asset: 'storyFriendshipChest1', background: 'friendshipPolaroidBg' },
        { asset: 'storyFriendshipChest2', background: 'friendshipPolaroidBg' },
        { asset: 'storyFriendshipChest3', background: 'friendshipPolaroidBg' },
        { asset: 'storyFriendshipChest4', background: 'friendshipPolaroidBg' },
        { asset: 'storyFriendshipChest5', background: 'friendshipPolaroidBg' },
        { asset: 'storyFriendshipChest6', background: 'friendshipPolaroidBg' },
        { asset: 'storyFriendshipChest7', background: 'friendshipPolaroidBg' },
      ],
    },
    layoutData: {
      polaroidImageOffsetY: -58,
      messageHardBackground: 0xF9A453,
      cardBackground: 0xFFF6D5,
      messageJoined: {
        fontSize: '150px',
        lineSpacing: -20,
        width: 272,
        height: 175,
      },
      bannerMask: {
        x: -301.74,
        y: -9.599999999999994,
        width: 603.48,
        height: 108,
      },
      bannerText: {
        yOffset: -5,
        fontSize: 48,
        offsetDivision: 4,
      },
    },
    maxCards: 50,
    width: 150,
  },

  FTUXSettings: {
    runLevelsUpTo: 10,
    gingyPosOffset: {
      y: -2,
    },
  },

  mapChest: {
    startLevel: 10,
    reoccuringLevel: 10,
    formulaChangePoint: 100,
    mapTileSizeInLevels: 15,
    appearanceNodes: [
      [ // level 1 ~ 5
        { x: -165, y: -274 },
        { x: 179, y: -441 },
        { x: -25, y: -513 },
        { x: 265, y: -609 },
        { x: 167, y: -680 },
      ],
      [ // 6 ~ 10
        { x: -233, y: -733 },
        { x: -41, y: -879 },
        { x: 23, y: -919 },
        { x: 245, y: -1098 },
        { x: -22, y: -1197 },
      ],
      [ // 11 ~ 15
        { x: 215, y: -1083 },
        { x: -48, y: -1368 },
        { x: 107, y: -1467 },
        { x: -203, y: -1672 },
        { x: 25, y: -1749 },
      ],
    ],
  },

  postcardEvent: { // Data for the postcard event
    unlockData: {
      saveKey: 'valentines2020',
    },
    cardData: {
      avatarFrameOffset: {
        x: -24,
        y: 22,
      },
      extraAssets: [
        { asset: 'tokenEventStamp', anchor: 'top_right', offset: { x: -25, y: 22 } },
        { asset: 'tokenEventBanner', anchor: 'top_left', offset: { x: 20, y: 15 } },
      ],
    },
    shop: {
      firstTimeBuyerKey: 'first',
      popupKey: 'popup',
      background: 'eventPostcardShopBg',
      chest: 'eventPostcardShopChest',
      coinbar: 'eventPostcardShopCoinbar',
      specialPopupOffer: 'Special offer',
      popupDimensions: {
        width: 525,
        height: 650,
      },
    },
  },

  // Config for the special shop deal. Similar to eventPostcard but doesn't come with a lot of extra stuff
  specialShopDeal: {
    firstTimePackId: 'summer2020First',
    regularPackId: 'summer2020',
    valueTagMultiplier: 1,
    valueTagColours: 0xFF2EC2,
    coinBarTint: 0xff4200,
    mapIcon: {
      asset: 'summerSaleMapIcon',
      width: 150,
      height: 150,
    },
    saleBannerText: {
      offsetY: 0,
    },
  },

  unlimitedLivesMinCap: 1440, // The maximum amount of minutes you can get when you get get unlimited lives

  tokenEvent: {
    gameBackgroundAsset: 'background_1', // otherwise 'tokenEvent_background'
    // List of level numbers for the event
    levelList: [
      103, 104, 106, 115, 118, 125, 126, 129, 130, 131, 135, 136, 137,
      148, 149, 164, 167, 176, 182, 189, 191, 193, 194, 199, 205, 206,
      209, 222, 230, 232, 234, 235, 237, 268, 282, 306, 330, 344, 347,
      359, 396, 411, 413, 430, 434, 439, 442, 443, 447, 458, 463, 478,
      482, 495, 497, 502, 503, 517, 539, 553, 555, 573, 575, 576, 577,
      586, 603, 617, 631, 673, 702, 711, 713, 714, 719, 720, 721, 723,
      734, 738, 744, 748, 753, 760, 768, 770, 778, 806, 811, 817, 821,
      825, 826, 831, 849, 856, 874, 887, 892, 899,
    ],
    mapIcon: {
      layout: {
        bg: {
          x: 0,
          y: 0,
        },
        token: {
          x: 0,
          y: 25,
        },
        timerBanner: {
          x: 0,
          y: 56,
        },
        timerText: {
          x: 12,
          y: 56,
        },
      },
    },
    // configuration for special event leaderboard
    leaderboardConfig: {
      header: {
        scale: 0.825,
        bg: { asset: 'eventPostcardHeader' },
        titleBg: { x: 0, y: -13, asset: 'eventPostcardTitle' },
        title: {
          x: 0, y: 73, baseStyle: 'tokenEvent-eventLevelTitle', fontSize: '65px', maxWidth: 370, maxHeight: 100, text: 'Valentine\'s',
        },
        subTitleBg: { x: 0, y: 150, asset: 'leaderboard-subheader-bar' },
        subTitle: {
          x: 0, y: 150, baseStyle: 'font-white', fontSize: '45px', maxWidth: 370, maxHeight: 80, text: 'Leaderboard',
        },
      },
      collectMsg: {
        currency: 'Pumpkins',
        fieldConfig: {
          topOffset: 845,
        },
      },
      tabs: {
        topOffset: 250,
        dividerStroke: { color: 0x00ACBD },
      },
      playBtn: {
        topOffset: 940,
      },
      panel: {
        topOffset: 315,
        userView: {
          icon: {
            offsetY: 0,
          },
          scoreField: {
            fontStyle: {
              fill: '#FFFFFF',
            },
          },
          usernameField: {
            fontStylePlayer: {
              fontSize: '50px',
              style: 'font-orange',
              fill: '#75FDFF',
            },
          },
        },
      },
    },
    popupOfferLayout: {
      deal: {
        assetsKeys: {
          NON_PAYER_TOKENEVENT: 'tokenEvent_offer_small',
          PAYER_TOKENEVENT: 'tokenEvent_offer_big',
        },
      },
      bg: {
        height: 550,
        outerBgAsset: 'tokenEvent_popup_background',
        outerSlices: null, // background not 9-sliced
        innerOffsetX: -20,
        innerOffsetY: 45,
        innerFrameVisible: false, // we don't have a inner frame for this design
      },
      header: {
        offsetY: 20,
        ribbonOffsetY: 5,
        textOffsetY: 25,
        ribbonConfig: {
          asset: 'tokenEvent_banner',
          sliceRect: null,
          height: 175,
        },
        textConfig: {
          text: 'Exclusive Offer',
          style: { style: 'tokenEvent-eventLevelTitle', lineSpacing: -15, fontSize: '40px' },
          maxWidth: 200,
        },
      },
      multiplier: {
        offsetY: 40,
        offsetX: -40,
        scale: 0.8,
        bgScale: 1.05,
        txt: { offsetX: 0, offsetY: 0 },
        altBgAsset: 'tokenEvent_shopBadge',
      },
      dealGraphic: {
        offsetX: 5,
      },
      dealCoinbar: {
        tint: 0x792F19,
      },
      playerCoinbar: {
        offsetY: -265,
        scale: 0.8,
      },
      exitConfirm: {
        mascot: { offsetX: -10000 }, // mascot offscreen doesn't fit the layout
        bubble: { offsetX: 0, offsetY: -344 }, // bubble needs to be re-centered with mascot missing
      },
      buyButton: { offsetY: 15 },
      windowHeader: { // config for event window headers. Any window that has text that uses "tokenEvent-eventLevelTitle" style)
        text: {
          x: 0,
          y: -270,
        },
      },
    },
    shopData: {
      shopKey: 'valentine',
      firstTimeBuyerKey: 'first',
      background: 'specialdeal_bg',
      chest: 'shop3_specialDeal1',
      coinbar: 'shop3_dealCoinBar',
      specialPopupOffer: 'Valentine special offer',
      coinBarTint: 0x792F19,
      coinBarTextStyle: 'eventPostcard-shopCoinText',
    },
    styling: {
      windowTextFill: '#E50057', // colour of text in level flow windows
    },
    window_eventLevel: {
      boosterBgWidth: 510,
    },
    postcard: {
      header: {
        banner: {
          offsetY: 40, // The long background
        },
        title: {
          offsetY: -40, // The container holding text
        },
      },
    },
  },

  // ===================================================================
  global: {
    gameName: 'Candy Rain',
    defaultFont: 'Lobster',
    leaderboardName: 'completed-levels',
    gameTracking: {
      active: false,
    },
  },

  elements: {
    windowNineSlice: {
      slice: { // The 9 slice of the popup
        top: 121,
        left: 126,
        right: 111,
        bottom: 80,
      },
      whiteSpace: { // The whitespace in the popup
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      inner: { // The px to the inner look-good dimensions of the popup
        top: 90,
        left: 126,
        right: 111,
        bottom: 80,
      },
    },
    leaderboard: {
      tabLivesSelected: false,
    },
    gingy: {
      mugshot: {
        x: 59,
        y: 54,
        width: 432,
        height: 432,
      },
      backEyes: {
        x: 0,
        y: 23,
      },
      eyes: {
        x: 0,
        y: 21.5,
      },
      eyelids: {
        x: -0.5,
        y: 21.5,
      },
      mouth: {
        x: 2,
        y: 43,
      },
    },
    ginger: { // Loly
      backEyes: {
        x: -11,
        y: 10,
      },
      eyes: {
        x: -15.5,
        y: 7.3,
      },
      eyelids: {
        x: -16,
        y: 5.5,
      },
      mouth: {
        x: -14,
        y: 43,
      },
    },
    chip: { // Sugar
      backEyes: {
        x: 0,
        y: 56,
      },
      eyes: {
        x: 7,
        y: 55,
      },
      eyelids: {
        x: 7.5,
        y: 56,
      },
      mouth: {
        x: 10,
        y: 75,
      },
    },
    cookie: { // Caramel
      backEyes: {
        x: 0,
        y: 66.5,
      },
      eyes: {
        x: 0,
        y: 69,
      },
      eyelids: {
        x: 0,
        y: 66.5,
      },
      mouth: {
        x: 2,
        y: 92,
      },
    },
    nutmeg: { // Icey
      backEyes: {
        x: 18,
        y: -1,
      },
      eyes: {
        x: 19,
        y: -8,
      },
      eyelids: {
        x: 18,
        y: -8,
      },
      mouth: {
        x: 22,
        y: 24,
      },
    },
    graham: { // Bubba
      backEyes: {
        x: 0,
        y: 25,
      },
      eyes: {
        x: -2,
        y: 14,
      },
      eyelids: {
        x: -2,
        y: 17,
      },
      mouth: {
        x: -1,
        y: 44,
      },
    },
    fancyGingy: {
      mugshot: {
        x: 78,
        y: 93,
        width: 395,
        height: 395,
      },
      backEyes: {
        x: -7,
        y: -4,
      },
      eyes: {
        x: -10.5,
        y: -3.5,
      },
      eyelids: {
        x: -11,
        y: -4,
      },
      mouth: {
        x: -10,
        y: 22,
      },
    },
    characterAssets: {
      gingy: { // Poppy
        yBottom: 154,
      },
      ginger: { // Lolly
        yBottom: 130,
      },
      nutmeg: { // Icey
        yBottom: 102,
      },
      cookie: { // Caramel
        yBottom: 110,
      },
      chip: { // Sugar
        yBottom: 88,
      },
      graham: { // Boba
        backEyes: 'graham_eyes_back',
        eyes: 'graham_eyes',
        eyelids: 'graham_eyelids',
        yBottom: 116,
      },
      fancyGingy: { // Poppy
        yBottom: 100,
      },
    },
    buttonNineSlice: {
      layers: { // Default layers for buttons at regular size
        shadow: 'buttons/btn_shadow',
        base: 'buttons/btn_base',
        largeDetail: ['buttons/btn_pattern1'],
        highlights: 'buttons/btn_highlight',
      },
      smallSlice: { // Default slice for small buttons
        left: 23,
        right: 21,
        top: 26,
        bottom: 20,
      },
      largeDetail: { // Default slice for large/detailed buttons
        left: 41,
        right: 36,
        top: 41,
        bottom: 47,
      },
    },
    buttonTintColours: { // Button nine-slice colour definitions
      green: 0x27fa00,
      blue: 0x3d9bff,
      red: 0xff0000,
      purple: 0xc73eff,
      orange: { // Provides its own layer
        shadow: 'buttons/btn_shadow',
        base: 'buttons/btn_orange',
        largeDetail: ['buttons/btn_orangePattern'],
      },
      gray: 0x888888,
      lightGreen: {
        shadow: 'buttons/btn_shadow',
        base: 'buttons/btn_lightGreen',
        largeDetail: ['buttons/btn_pattern1'],
      },
    },
    chestPositioning: {
      inGame: {
        bottom: { x: 0, y: 0 },
        top: { x: -32, y: -15 },
        glow: { x: 0, y: -20 },
      },
      closed: {
        top: {
          x: 0,
          y: -51,
        },
      },
      opened: {
        bottom: {
          x: 0,
          y: 0,
        },
        top: {
          x: 35,
          y: -61,
        },
        glow: {
          x: 3,
          y: -41,
        },
      },
    },
    chestShuffle: {
      charSizeScale: 1.1,
    },
    tutorialMsg: {
      fontSize: 42,
      lineSpacing: -10,
    },
    UI_MapPanel: {
      coinData: {
        vertical: {
          text: {
            style: 'soloInstance-coinBar',
          },
        },
        horizontal: {
          text: {
            style: 'soloInstance-coinBar',
          },
        },
      },
    },
    dailyReward: {
      title: {
        x: 0,
        y: -300,
        style: 'DailyRewardTitle',
      },
      comeBackTxt: {
        lineSpacing: -10,
      },
    },
    dailyIcon: {
      width: 135,
      height: 90,
    },
    dailyChallengeIcon: {
      offsetY: 5,
      height: 120,
      width: 150,
    },
    dailyMission: {
      ribbonText: {
        yPos: 55,
      },
    },
    deconstructMsg: {
      tournamentTaunt: {
        aheadFriend: {
          x: 221,
          y: 163,
          angle: 0,
          avatar: {
            scale: 1.75,
            x: -7,
            y: -11,
            angle: -5,
          },
        },
        midFriend: {
          x: -48,
          y: 168,
          angle: 0,
          avatar: {
            scale: 1.35,
            x: 3,
            y: 17,
            angle: 15,
          },
        },
        lastFriend: {
          x: -188,
          y: 156,
          angle: 0,
          avatar: {
            scale: 1.1,
            x: -18,
            y: -18,
            angle: 4,
          },
        },
        text: {
          x: 0,
          y: -240,
          angle: 0,
        },
        trophy: { // Relative to aheadFriend
          x: 246,
          y: -71,
          angle: -15,
        },
        sparkles: [ // Relative to the trophy
          { x: -22, y: -54, angle: 0 },
          { x: 54, y: 69, angle: 0 },
          { x: -91, y: -30, angle: 0 },
          { x: 89, y: -9, angle: 0 },
          { x: -67, y: 40, angle: 0 },
          { x: 54, y: -81, angle: 0 },
        ],
      },
    },
    Window_ItemShop: {
      titleTextStyle: {
        style: 'font-white',
        fontSize: '60px',
        align: 'center',
      },
    },
    mockFacebookFriends: {
      friends: [
        { name: 'Gingy', image: 'friendshipGingy' },
        { name: 'Bubbles', image: 'friendshipBubbles' },
        { name: 'Finn', image: 'friendshipFinn' },
        { name: 'Dolly', image: 'friendshipDolly' },
        { name: 'Shelly', image: 'friendshipShelly' },
        { name: 'Spike', image: 'friendshipSpike' },
      ],
    },
    giftUI: {
      sideGingy: {
        txt: {
          fontSize: '48px',
        },
      },
    },
    highscoreBeaten: {
      continueBtn: {
        x: 0,
        y: 215,
      },
    },
    checkedIconPast: {
      x: 55,
      y: 5,
    },
    checkedIcon: {
      x: 55,
      y: 5,
    },
    worldMap: {
      mapScale: 0.7,
      allowHScrolling: false,
      bubbleScale: 0.7,
      tiles: [
        'Map_path_tile3',
        'Map_path_tile0',
        'Map_path_tile1',
        'Map_path_tile2',
      ],
      tilePropCenter: {
        vertical: [
          [{ x: -145, y: 55, pool: 'left' }],
          [{ x: 145, y: 45, pool: 'right' }],
          [{ x: -145, y: 5, pool: 'behindRiver|left' }, { x: -150, y: 155, pool: 'aboveRiver' }],
          [{ x: 145, y: 75, pool: 'right' }],
        ],
        horizontal: [
          [{ x: -250, y: 95, pool: 'left' }],
          [{ x: 250, y: 85, pool: 'right' }],
          [{ x: -250, y: 55, pool: 'behindRiver|left' }, { x: -200, y: 155, pool: 'aboveRiver' }],
          [{ x: 250, y: 95, pool: 'right' }],
        ],
      },
      riverTileExtra: {
        index: 2, // The index on the tiles array
        levelNode: 1, // the index of the level node on that tile
        offset: [{ x: 337, y: 40 }, { x: 424, y: 72 }, { x: 471, y: 141 }, { x: 435, y: 228 }],
      },
      levelNodePositions: [ // for each tile, from the bottom
        [{ x: 279, y: 38 }, { x: 371, y: 85 }, { x: 446, y: 146 }, { x: 417, y: 220 }],
        [{ x: 336, y: 26 }, { x: 253, y: 72 }, { x: 233, y: 152 }, { x: 259, y: 232 }],
        [{ x: 330, y: 42 }, { x: 417, y: 78 }, { x: 470, y: 145 }, { x: 436, y: 224 }],
        [{ x: 361, y: 37 }, { x: 271, y: 74 }, { x: 192, y: 132 }, { x: 206, y: 215 }],
      ],
      mailboxPositions: [
        [{ x: 216, y: 231, scale: -1 }],
        [{ x: 384, y: 220, scale: 1 }],
        [{ x: 241, y: 244, scale: -1 }],
        [{ x: 396, y: 251, scale: 1 }],
      ],
      chestShufflePositions: [
        [{ x: 234, y: 257, scale: -1 }],
        [{ x: 385, y: 249, scale: 1 }],
        [{ x: 244, y: 270, scale: -1 }],
        [{ x: 390, y: 275, scale: 1 }],
      ],
      sagaMapChestPositions: [
        [{ x: 223, y: 187, scale: -1 }],
        [{ x: 381, y: 177, scale: 1 }],
        [{ x: 241, y: 196, scale: -1 }],
        [{ x: 389, y: 208, scale: 1 }],
      ],
      rewardPerTile: [
        'mailbox',
        'chestMap',
        'chestShuffle',
        'chestMap',
        '',
      ],
      riverTint: [
        0xb4ffde,
        0xffe2fd,
        0xc2f1ff,
        0xffef91,
        0xebcbff,
      ],
      grassColour: 0x32e089,
    },
    worldMapButtonOrder: {
      vertical: {
        left: ['dailyIcon', 'mapGift'],
        right: ['tournament', 'friendshipChest', 'tokenEvent', 'dailyChallenge', 'xPromo'],
        alignXDynamically: true,
      },
      horizontal: {
        left: ['xPromo'],
        right: ['tournament', 'friendshipChest', 'tokenEvent', 'dailyChallenge', 'dailyIcon', 'mapGift'],
        alignXDynamically: true,
      }
    },
    worldMapLvlButton: {
      scale: 0.8,
      lvlNrText: {
        style: 'WorldMapLvlNrBtn',
      },
      closedMsg: {
        style: 'lvlButtonCloseMessage',
        x: 554,
        y: 236,
        width: 148,
        height: 132,
      },
    },

    Window_FortuneCookie: {
      categories: {
        funny: {
          index: 1,
          enabled: true,
        },
        prediction: {
          index: 3,
          enabled: true,
        },
        love: {
          index: 2,
          enabled: true,
        },
        wisdom: {
          index: 4,
          enabled: true,
        },
        game: {
          index: 0,
          enabled: false,
        },
      },
      elementLayouts: {
        bannerTextOffset: { x: 12, y: -10 },
        yPos: { // range of y-positions (for responsiveness)
          shareBannerGroup: { min: -375, max: -460 },
          msgGroup: { min: -212, max: -242 },
          msgImgGroup: { min: 60, max: 80 },
          shareBtn: { min: 395, max: 460 },
        },
        scaling: { // range of scale factors (for responsiveness)
          msgGroup: { min: 0.84, max: 1 },
          msgImgGroup: { min: 1.05, max: 1.22 },
        },
      },
      avatarLayout: {
        funny: {
          x: -123,
          y: -77,
          width: 117,
          height: 117,
          angle: 2,
        },
        prediction: {
          x: 0,
          y: -45,
          width: 115,
          height: 115,
          angle: 3,
        },
        love: {
          x: -98,
          y: -51,
          width: 115,
          height: 115,
          angle: 2,
        },
        wisdom: {
          x: -107,
          y: -61,
          width: 116,
          height: 116,
          angle: 2,
        },
        game: { // unused
          x: 38,
          y: -88,
          width: 110,
          height: 110,
          angle: 15,
        },
      },
      tagSymbolOnly: false,
      tagPosition: {
        x: 45,
        y: -3,
      },
    },
    MsgFortuneCookie: {
      avatarLayout: {
        funny: {
          x: 495,
          y: 108,
          width: 138,
          height: 138,
          angle: 5.62,
        },
        prediction: {
          x: 496,
          y: 113,
          width: 138,
          height: 138,
          angle: 5.62,
        },
        love: {
          x: 496,
          y: 113,
          width: 138,
          height: 138,
          angle: 5.62,
        },
        wisdom: {
          x: 496,
          y: 113,
          width: 138,
          height: 138,
          angle: 5.62,
        },
        game: {
          x: 496,
          y: 113,
          width: 138,
          height: 138,
          angle: 5.62,
        },
      },
    },
    CandyType_FortuneCookie: {
      timerPosition: {
        x: -24,
        y: 11,
      },
    },
    RealMoneyWheel: {
      gingy: {
        x: 0,
        y: 270,
        bobbingAnim: true,
        bobbingAnimConfig: {
          vDisplacement: 10,
          hDisplacement: 0,
          cycleLength: 7500, // ms
        },
      },
    },

    // config overrides for wheels / wheel items
    wheelConfigs: {
      dailyPrizeWheel: {
        spinner: { wheelSegments: 24 }, // amount of pegs
        itemConfigOverrides: {
          COINS: {
            text: { style: { fontSize: 33 }, offset: { y: 4 } },
            icon: { scale: 0.9 },
          },
          COINS_SPECIAL: {
            text: { style: { fontSize: 29 }, offset: { y: 2 } },
            icon: { scale: 0.9 },
          },
          BOOSTER: {
            text: { style: { fontSize: 33 }, offset: { y: 4 } },
          },
        },
      },
      lossAversionPrizeWheel: {
        spinner: { wheelSegments: 24 }, // amount of pegs
        itemConfigOverrides: {
          COINS: {
            text: { style: { fontSize: 40 } },
          },
        },
      },
      requestHelpPrizeWheel: {
        spinner: { wheelSegments: 24 }, // amount of pegs
        itemConfigOverrides: {
          COINS: {
            text: { style: { fontSize: 40 } },
          },
        },
      },
      realMoneyPrizeWheel: {
        itemConfigOverrides: {
          COINS: {
            text: {
              style: 'realMoneyWheel-coinTextGold',
              offset: { x: 125, y: 0 },
            },
            icon: {
              scale: 0.9,
              offset: { x: 145, y: 0 },
            },
          },

          COINS_SPECIAL: {
            text: {
              style: 'realMoneyWheel-coinTextGoldSpecial',
              offset: { x: 125, y: 0 },
            },
            icon: {
              scale: 0.9,
              offset: { x: 145, y: 0 },
            },
          },

          COINS_PLAT: {
            text: {
              style: 'realMoneyWheel-coinTextPlat',
              offset: { x: 145, y: 0 },
            },
            icon: {
              scale: 0.9,
              offset: { x: 165, y: 0 },
            },
          },

          COINS_SPECIAL_PLAT: {
            text: {
              style: 'realMoneyWheel-coinTextSpecialPlat',
              offset: { x: 145, y: 0 },
            },
            icon: {
              scale: 0.9,
              offset: { x: 165, y: 0 },
            },
          },
        },
      },
    },
    Window_mysteryGift: {
      titleOffsetY: 0,
    },
    Window_shop2: {
      topBarOffset: 15,
      topBarTextOffset: -25,
      isShowing3hGift: true,
      useTileEntryBorder: true,
      headerImgAdjustments: {
        width: 525,
        height: 150,
      },
    },
    Window_shop3: {
      useBoldSchema: true,
      maxSpecialSlots: 6,
      regularDealFrameSlice: { // Slices for the frame
        top: 37,
        bottom: 121,
        right: 60,
        left: 75,
      },
      headerSlice: { // Nineslice for header pieces
        top: 19,
        left: 8,
        bottom: 19,
        right: 8,
      },
      headerTextOffset: {
        y: -2,
      },
      specialDealFrameExtra: {
        offsetX: 0,
        offsetY: 0,
        topOfFrame: 45,
        bottomOfFrame: 158,
      },
      specialDealFrameSlice: { // The slices for the special deal frames
        top: 45,
        bottom: 158,
        left: 68,
        right: 201,
      },
      specialDealRibbonSize: { // Size of ribbion
        height: 70,
      },
      specialDealRibbonSlice: { // The slices for the ribbon
        top: 30,
        bottom: 30,
        left: 58,
        right: 58,
      },
      specialDealBoosterBoxSlice: { // The slices for the booster box details
        top: 40,
        bottom: 35,
        left: 43,
        right: 49,
      },
      tagBaseMultipler: 1,
      regularDealTagIndex: 0,
      specialDealTintData: [ // Tint data on the layout
        {
          boosterBox: {
            asset: 'shop3_specialDealBoosterBox0',
            coinBar: {
              tint: 0xFF2400,
              textStyle: 'shop3-boosterTextStyle0',
            },
          },
          valueTagTint: 2,
        },
        {
          boosterBox: {
            asset: 'shop3_specialDealBoosterBox1',
            coinBar: {
              tint: 0x0082FF,
              textStyle: 'shop3-boosterTextStyle1',
            },
          },
          valueTagTint: 1,
        },
        {
          boosterBox: {
            asset: 'shop3_specialDealBoosterBox2',
            coinBar: {
              tint: 0xFF0090,
              textStyle: 'shop3-boosterTextStyle2',
            },
          },
          valueTagTint: 0,
        },
        {
          boosterBox: {
            asset: 'shop3_specialDealBoosterBoxFTU',
            coinBar: {
              tint: 0xFF2400,
              textStyle: 'shop3-boosterTextStyle0',
            },
          },
          valueTagTint: 2,
        },
      ],
    },
    Window_newsHomeScreen: {
      button: {
        shouldPulse: true,
        x: 0,
        y: 215,
      },
      continueBtn: {
        x: 0,
        y: 295,
      },
      continueBtnTxt: {
        x: 0,
        y: 295,
      },
    },
    Window_Brag: {
      bgScaleMode: 'scaleToFit',
      closeButton: { offsetX: 0, offsetY: 0 },
      bannerImage: { fullscreen: 'purple_banner' },
      content: {
        friendsLayout: { // double avatar layout
          fullscreen: { x: 0, y: -40, minWidth: 540, maxWidth: 540 },
          friendAvatar: {
            avatarSize: 100,
            x: 124,
            y: 80,
            angle: 11,
          },
          playerAvatar: {
            avatarSize: 100,
            x: -142,
            y: 28,
            angle: -6,
          },
        },
        soloLayout: { // single avatar layout
          fullscreen: { x: 50, y: 10, minWidth: 640, maxWidth: 700 },
          playerAvatar: {
            avatarSize: 106,
            x: -124,
            y: 28,
            angle: -8,
          },
        },
        skipTutorialLayout: { // for first time users winning a challenge
          fullscreen: { x: 0, y: 50, minWidth: 0, maxWidth: 650 },
          imageScale: 0.65,
          playerAvatar: {
            avatarSize: 100,
            x: -100,
            y: -50,
            angle: 0,
          },
        },
      },
    },
    Window_popupOffer: {
      vertical: {
        deal: {
          assetsKeys: {
            moneyOnly: 'shop3_specialDeal2',
            fewBoosters: 'shop3_specialDeal1',
            manyBoosters: 'shop3_specialDeal0',
          },
        },
        playerCoinbar: {
          offsetY: -240,
        },
        dealCoinbar: {
          scale: 1.2,
          fontSize: 36,
          tint: 0xFF4210,
        },
        multiplier: {
          offsetY: 12,
          offsetX: -17,
          scale: 1,
          bgScale: 1.1,
          txt: { offsetX: 0, offsetY: 0 },
        },
        bg: {
          height: 550,
          outerSlices: {
            left: 145, right: 149, top: 221, bottom: 139,
          },
          innerSlices: {
            left: 72, right: 72, top: 66, bottom: 66,
          },
          innerOffsetY: -12,
          innerTint: 0xffb20d,
        },
        header: {
          offsetY: -2,
          ribbonOffsetY: 5,
          textOffsetY: 2,
          ribbonConfig: {
            asset: 'shop3_dealRibbon2',
          },
        },
        exitConfirm: {
          mascot: { offsetX: 45, offsetY: 62, scaleX: 0.75, scaleY: 0.75 },
          bubble: { offsetX: 15, offsetY: -340 },
        },
        buyButton: { offsetY: -1 },
      },
      horizontal: {
        deal: {
          assetsKeys: {
            moneyOnly: 'shop3_specialDeal2',
            fewBoosters: 'shop3_specialDeal1',
            manyBoosters: 'shop3_specialDeal0',
          },
        },
        playerCoinbar: {
          offsetY: -260,
        },
        dealCoinbar: {
          scale: 1.2,
          fontSize: 36,
          tint: 0xFF4210,
        },
        multiplier: {
          offsetY: 12,
          offsetX: -812,
          scale: 1,
          bgScale: 1.1,
          txt: { offsetX: 0, offsetY: 0 },
        },
        bg: {
          height: 550,
          outerSlices: {
            left: 145, right: 149, top: 221, bottom: 139,
          },
          innerSlices: {
            left: 72, right: 72, top: 66, bottom: 66,
          },
          innerOffsetX: -240,
          innerOffsetY: 23,
          innerTint: 0xffb20d,
        },
        header: {
          offsetY: -2,
          ribbonOffsetY: 5,
          textOffsetY: 2,
          ribbonConfig: {
            asset: 'shop3_dealRibbon2',
          },
        },
        exitConfirm: {
          mascot: { offsetX: 1045, offsetY: 5, scaleX: -0.75, scaleY: 0.75 },
          bubble: { offsetX: 563, offsetY: -180 },
        },
        buyButton: { offsetY: -1 },
      },
    },

    Window_noAdsPopup: {
      vertical: {
        window: {
          x: 0,
          y: 0,
        },
        title: {
          x: 0,
          y: -284,
          text: 'Did you know?',
          style: 'noAdsPopup-title',
          maxWidth: 340,
        },
        background: {
          width: 560,
          height: 650,
        },
        dealChest: {
          x: 110,
          y: 190,
          scale: 0.9,
        },
        gingy: {
          x: -130,
          y: 217,
          scale: 0.7,
        },
        adCrossed: {
          x: 0,
          y: -5,
          scale: 0.475,
        },
        message: {
          x: 0,
          y: -230,
          maxWidth: 448,
          maxHeight: 165,
          text: 'You can remove forced ads forever by making any purchase in the coins store',
          style: {
            style: 'noAdsPopup-message',
            fontSize: 36,
          },
        },
        continueButton: {
          x: 0,
          y: 320,
          width: 230,
          height: 90,
          text: 'Continue',
          style: {
            style: 'font-white',
            fontSize: 64,
          },
          pulse: 1.05,
        },
      },

      horizontal: {
        window: {
          x: 0,
          y: 100,
        },
        title: {
          x: 0,
          y: -355,
          text: 'Did you know?',
          style: 'noAdsPopup-title',
          maxWidth: 500,
        },
        background: {
          width: 1300,
          height: 800,
        },
        dealChest: {
          x: 380,
          y: 198,
          scale: 1.55,
        },
        gingy: {
          x: -390,
          y: 209,
          scale: 1.4,
        },
        adCrossed: {
          x: 0,
          y: 25,
          scale: 0.95,
        },
        message: {
          x: 0,
          y: -290,
          maxWidth: 1000,
          maxHeight: 200,
          text: 'You can remove forced ads forever by making any purchase in the coins store',
          style: {
            style: 'noAdsPopup-message',
            fontSize: 64,
          },
        },
        continueButton: {
          x: 0,
          y: 300,
          width: 275,
          height: 130,
          text: 'Continue',
          style: {
            style: 'font-white',
            fontSize: 72,
          },
          pulse: 1.05,
        },
      },
    },

    UI_ProgressBarHorizontal: {
      scale: 1,
      offset: {
        x: -1,
        y: 0,
      },
    },
    // these colors use { GIFT_COLORS } from "../../js/Elements/Windows/GiftUI/GiftGraphic"
    gifts: {
      shineEnabled: true,
      shineOffsetX: -5,
      shineOffsetY: -52,
    },
    dailyRewardCalendar: {
      doubleable: true,
      days: [
        // top left
        {
          gifts: [['coin', 200]],
          boxIcon: [],
        },
        // top middle
        {
          gifts: [['booster#2', 1]],
          boxIcon: [
            {
              giftType: 1,
              boxTint: 'Red',
              ribbonTint: 'Yellow',
              pos: [0, 0],
            },
          ],
        },
        // top right
        {
          gifts: [['booster#7', 1]],
          boxIcon: [
            {
              giftType: 1,
              boxTint: 'Red',
              ribbonTint: 'Yellow',
              pos: [15, -16],
              scale: [0.85, 0.85],
            },
            {
              giftType: 2,
              boxTint: 'Light_Blue',
              ribbonTint: 'Red',
              pos: [-18, 10],
              scale: [0.75, 0.75],
            },
          ],
        },
        // middle left
        {
          gifts: [['booster#5', 1]], // #5/#6 fixed in dailyRewardMgr
          boxIcon: [
            {
              giftType: 1,
              boxTint: 'Green',
              ribbonTint: 'Pink',
              pos: [20, -10],
              scale: [0.8, 0.8],
            },
            {
              giftType: 2,
              boxTint: 'Light_Blue',
              ribbonTint: 'Red',
              pos: [-20, 8],
              scale: [0.75, 0.75],
            },
            {
              giftType: 1,
              boxTint: 'Red',
              ribbonTint: 'Yellow',
              pos: [5, 20],
              scale: [0.6, 0.6],
            },
          ],
        },
        // middle center
        {
          gifts: [['booster#1', 1]],
          boxIcon: [
            {
              giftType: 2,
              boxTint: 'Purple',
              ribbonTint: 'Light_Blue',
              pos: [20, -10],
              scale: [0.8, 0.8],
            },
            {
              giftType: 1,
              boxTint: 'Teal',
              ribbonTint: 'Red',
              pos: [-25, 7],
              scale: [0.8, 0.8],
            },
            {
              giftType: 2,
              boxTint: 'Light_Blue',
              ribbonTint: 'Yellow',
              pos: [0, 20],
              scale: [0.6, 0.6],
            },
          ],
        },
        // middle right
        {
          gifts: [['booster#8', 1]],
          boxIcon: [
            {
              giftType: 2,
              boxTint: 'Purple',
              ribbonTint: 'Red',
              pos: [0, -14],
              scale: [0.7, 0.7],
            },
            {
              giftType: 1,
              boxTint: 'Red',
              ribbonTint: 'Yellow',
              pos: [-36, 5],
              scale: [0.55, 0.55],
            },
            {
              giftType: 1,
              boxTint: 'Teal',
              ribbonTint: 'Red',
              pos: [33, 5],
              scale: [0.75, 0.75],
            },
            {
              giftType: 2,
              boxTint: 'Purple',
              ribbonTint: 'Light_Blue',
              pos: [-5, 25],
              scale: [0.6, 0.6],
            },
          ],
        },
        // bottom
        {
          gifts: [
            ['booster#5', 1], // #5/#6 fixed in dailyRewardMgr
            ['booster#8', 1],
          ],
          boxIcon: [
            // leftmost
            {
              giftType: 1,
              boxTint: 'Red',
              ribbonTint: 'Yellow',
              pos: [-90, -20],
              scale: [0.75, 0.75],
            },
            // rightmost
            {
              giftType: 1,
              boxTint: 'Light_Blue',
              ribbonTint: 'Red',
              pos: [90, -20],
              scale: [0.75, 0.75],
            },
            // second from right
            {
              giftType: 2,
              boxTint: 'Purple',
              ribbonTint: 'Light_Blue',
              pos: [55, -10],
              scale: [0.8, 0.8],
            },
            // second frm left
            {
              giftType: 2,
              boxTint: 'Purple',
              ribbonTint: 'Red',
              pos: [-55, -10],
              scale: [0.8, 0.8],
            },
            // center
            {
              giftType: 1,
              boxTint: 'Teal',
              ribbonTint: 'Red',
              pos: [0, 0],
              scale: [1.2, 1.2],
            },
          ],
        },
      ],
    },

    // Super hard update element config
    superhard: {
      window_level: {
        hard_popup: {
          top: 317,
          bottom: 294,
          left: 266,
          right: 269,
        },
        super_hard_popup: {
          top: 383,
          bottom: 227,
          left: 257,
          right: 276,
        },
        hard_tint: 0xEBB5FF,
        super_hard_tint: 0xFFBEBF,
      },
      sagaMapTutorial: {
        gingyOffset: 0.675,
      },
      speech_bubble: {
        hard_bubble: {
          top: 139,
          bottom: 4,
          left: 146,
          right: 182,
        },
        super_hard_bubble: {
          top: 80,
          bottom: 73,
          left: 33,
          right: 42,
        },
        gingy_bubble: {
          top: 0,
          bottom: 0,
          left: 161,
          right: 113,
        },
      },
      villain_speech: {
        hard_win: 'You won this time but soon all the land will be mine!',
        super_hard_win: 'This isn’t the end! We will come back!',
        hard_oom: "What's wrong? Giving up already?",
        super_hard_oom: "What's wrong? Giving up already?",
        hard_lose: 'That was so easy! Try harder next time, haha!',
        super_hard_lose: 'That was so easy! Try harder next time, haha!',
        hard_mystery_gift: 'Look at all the stuff you are going to lose!',
        super_hard_mystery_gift: 'Look at all the stuff you are going to lose!',
      },
      bubbleTipPos: {
        hard: {
          'top-right': {
            x: 300,
            y: -44,
            scale: 1,
          },
          'top-left': {
            x: 200,
            y: -44,
            scale: -1,
          },
          right: {
            x: 563,
            y: 130,
            scale: -1,
          },
          left: {
            x: -98,
            y: 129,
            scale: 1,
          },
        },
        superhard: {
          'top-right': {
            x: 300,
            y: -44,
            scale: 1,
          },
          'top-left': {
            x: 200,
            y: -44,
            scale: -1,
          },
          right: {
            x: 533,
            y: 130,
            scale: -1,
          },
          left: {
            x: -68,
            y: 129,
            scale: 1,
          },
        },
      },
      tutorialSpeech: {
        hard: {
          pre_win_saga_map: [
            {
              source: 'gingy',
              text: "Watch out! It's that greedy %mainVillain%!",
              x: 50,
              y: 80,
              clickable: true,
            },
            {
              source: 'villain',
              index: 1,
              subIndex: 1,
              text: "I'm claiming this land and you can't stop me!",
              x: 50,
              y: 20,
              pos: 'top-left',
              clickable: true,
            },
          ],
          level_window: [
            {
              source: 'villain',
              index: 1,
              subIndex: 4,
              text: 'Bow before your queen!',
              x: 50,
              y: 20,
              pos: 'left',
              duration: 3000,
            },
            {
              source: 'gingy',
              text: 'You can use additional boosters on hard levels!',
              x: 50,
              y: 80,
            },
          ],
          post_corruption_level_window: [
            {
              source: 'villain',
              index: 1,
              subIndex: 4,
              text: "Bow before your queen!|Give up while you can!|You've got no chance!",
              x: 50,
              y: 20,
              pos: 'left',
            },
          ],
        },
        super_hard: {
          pre_win_saga_map: [
            {
              source: 'gingy',
              text: "It's %mainVillain%! And she brought company!",
              x: 50,
              y: 80,
              clickable: true,
            },
            {
              source: 'villain',
              index: 1,
              subIndex: 1,
              text: 'Bow before your highness, %sideVillain%!',
              x: 50,
              y: 20,
              pos: 'top-left',
              clickable: true,
            },
            {
              source: 'villain',
              index: 2,
              subIndex: 4,
              text: 'How dare you defy royalty! We will conquer the whole kingdom!',
              x: 50,
              y: 20,
              pos: 'top-left',
            },
          ],
          level_window: [
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [4, 4],
              text: 'Get ready for our royal wrath!',
              x: 50,
              y: 20,
              pos: 'double',
              duration: 3000,
            },
            {
              source: 'gingy',
              text: 'Using extra boosters will help us defeat them!',
              x: 50,
              y: 80,
            },
          ],
          post_corruption_level_window: [
            {
              source: 'villain',
              index: [1, 2],
              subIndex: [4, 4],
              text: 'Get ready for our royal wrath!|Resistance is futile!|Royalty always wins!',
              x: 50,
              y: 20,
              pos: 'double',
            },
          ],
        },
      },
    },
  },
};
