/* eslint-disable no-multi-spaces */
import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

/**
 * Pop up tutorials!
 * Made easy (???) by Sandra
 * @author ???
 */
export default class Window_PopupTutorial extends Window {
  constructor(parent, popUpTutId) {
    super(parent);

    console.log('Showing tutorial', popUpTutId);

    this.addBackground('popup_background_2');

    this._tutId = popUpTutId;
    this._tutData = G.json.tutorials.POPUPTUTORIALS[popUpTutId].tutorial; // Looks into tutorials.json and finds the tutorial section.

    this._initLayout();
    this._initTutorialModule();
  }

  /**
   * init layout elements
   */
  _initLayout() {
    // background
    this.addBackground('popup_background_2');
    // title
    this._titleTxt = new G.Text(0, -262, OMT.language.getText(this._tutData.title), G.OMTsettings.global.window.mediumTitle.textStyle, 0.5, 440);
    this.add(this._titleTxt);
  }

  /**
   * init the tutorial module
   */
  _initTutorialModule() {
    if (G[this._tutData.module]) { // module === class
      this._module = new G[this._tutData.module](); // Calls for the class by a not-very nice global call...
      this.add(this._module);                       // Please find the modules at \js\Elements\PopUpTutorials\

      if (this._module.signals) {
        if (this._module.signals.onContinueClick) {
          this._module.signals.onContinueClick.addOnce(this.onContinueClicked, this);
        }
      }
      if (this._tutData.modulePos) { // Positions the module tutorial within the background window
        this._module.position.copyFrom(this._tutData.modulePos);
      }
    }

    if (this._tutData.button) { // Puts a button at the bottom of the window
      this._btn = new OMT_UI_SquareButton(0, 0, {
        button: {
          tint: BUTTONCOLOURS[this._tutData.button.sprite],
          dimensions: {
            width: 196,
            height: 100,
          },
        },
        options: {
          clickFunction: {
            onClick: this.onContinueClicked.bind(this),
          },
        },
      });
      this.add(this._btn);
      if (this._tutData.button.label) { // The text in the button
        this._btn.setTextStyle(this._tutData.button.label.style);
        this._btn.setText(OMT.language.getText(this._tutData.button.label.text));
      }
      if (this._tutData.button.pos) {
        this._btn.position.copyFrom(this._tutData.button.pos);
      }
    }
  }

  onContinueClicked() {
    G.saveState.passPopUpTutorial(this._tutId);
    this.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.popUpTutorial = Window_PopupTutorial;
