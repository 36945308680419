/**
 * class for handling data caching
 */
export class OMT_DataCache {
  /**
   * constuctor
   */
  constructor() {
    this._cache = {};
  }

  /**
   * check if the cache has the specified data key
   * @param {string} key
   */
  hasKey(key) {
    return this._cache[key] != null;
  }

  /**
   * get cached data by key
   * @param {string} key
   * @returns {Object}
   */
  getData(key) {
    if (!this._cache[key]) return null;
    return this._cache[key].data;
  }

  /**
   * set data by key
   * @param {string} key
   * @param {Object} data
   * @param {boolean} updateTimestamp
   */
  setData(key, data, updateTimestamp = true) {
    if (!this._cache[key]) this._cache[key] = {};
    const cacheObj = this._cache[key];
    cacheObj.data = data;
    if (updateTimestamp || !cacheObj.timestamp) cacheObj.timestamp = Date.now();
  }

  /**
   * check if data is expired / older then the duration time
   * @param {string} key
   * @param {number} duration time in seconds
   * @returns {boolean}
   */
  isDataExpired(key, duration = 0) {
    if (!this._cache[key]) return true;
    if (duration === 0) return false;
    const cacheObj = this._cache[key];
    const secondsPassed = (Date.now() - cacheObj.timestamp) / 1000;
    const isExpired = secondsPassed > duration;
    // console.log("!!CACHE key = " + key + ", timeRemaining = " + (duration - secondsPassed) + ", EXPIRED = " + isExpired);
    return isExpired;
  }

  /**
   * clear data by key
   * @param {string} key
   */
  clearData(key) {
    delete this._cache[key];
  }

  /**
   * clear all data from the cache
   */
  clearAllData() {
    // eslint-disable-next-line prefer-const
    for (let i in this._cache) {
      if (this._cache[i]) this.clearData(i);
    }
  }

  /**
   * get a list of current keys
   * @returns {Array.<string>}
   */
  getKeys() {
    return Object.keys(this._cache);
  }
}
