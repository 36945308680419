import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import UI_ProgressBarBase from '../base/UI_ProgressBarBase';

export default class UI_ProgressBarHorizontal extends UI_ProgressBarBase {
  /**
   * This is the progress bar seen on the top bar in the game
   * Its like a n shape and marks your points progress
   * @param {number} x
   * @param {number} y
   * @param {PROGRESS_MARKER_TYPE} markerType
   */
  constructor(x, y, markerType) {
    super(x, y, markerType, 45, 1.5);

    // Tweak position and scale of progress bar
    if (!this._isTreasureHuntHorizontalBar()) {
      const settings = G.OMTsettings.elements.UI_ProgressBarHorizontal;
      this.barProgress.scale.setTo(settings.scale);
      this.barProgress.x = settings.offset.x;
      this.barProgress.y = settings.offset.y;
    }
    this._barProgressMask.position = this.barProgress.position;
  }

  /**
   * Returns asset name of the progress bar.
   * @returns {string}
   */
  _getBarProgressImage() {
    if (this._isTreasureHuntHorizontalBar()) {
      return G.OMTsettings.treasureHuntSuper.gameBoard.progressBarHorizontal;
    }
    return 'horizontal-progressBar';
  }

  _isTreasureHuntHorizontalBar() {
    return LvlDataManager.getInstance().isTreasureHuntLevel && G.OMTsettings.treasureHuntSuper.gameBoard.progressBarHorizontal;
  }

  /**
   * Draws the radius for the mask
   */
  _getMaskRadius() {
    return 260;
  }

  /**
   * Returns the distance between the center and the stars
   * @returns {number}
   */
  _getStarsDistance() {
    return 130;
  }
}
