import { GameScaleController } from '../../../../States/Scaling/GameScaleController';
import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';

export default class UI_BoosterLabel extends Phaser.Group {
  /**
   * The text label that appears when you click on a booster and it teaches you how to use it
   * in brief words
   * @param {Board} board
   */
  constructor(board) {
    super(game);

    this._board = board;
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._ico = G.makeImage(0, -200, null, 0.5, this); // Booster preview image

    this._txt = new G.Text(0, -200, '', { // Booster test
      style: 'font-white',
      fontSize: '30px',
      lineSpacing: '-5px',
    }, 0, false, false, true, 'center');
    this.add(this._txt);

    this._coinCounter = new G.UI_CoinCounter();
    this.add(this._coinCounter);

    if (this._isLandscape) {
      this.scale.setTo(this._gameScale);
    }

    /* eslint-disable quote-props */
    this.textLookup = {
      '1': board.gameHooks.getText('Use the swap booster to change the place of two cookies!'),
      '2': board.gameHooks.getText('Use the star wand to crush one cookie!'),
      '3': board.gameHooks.getText('Use the horizontal pin to clear the whole row!'),
      '4': board.gameHooks.getText('Use the vertical pin to clear the whole column!'),
    };
    /* eslint-enable quote-props */

    this._signalBindings = [];
    this._signalBindings.push(G.sb('onScreenResize').add(this._resize, this));

    this.pivotYSettings = null; // Accessed externally

    this._signalBindings.push(G.sb('onBoosterSelect').add(this._init, this));
    this._signalBindings.push(G.sb('closeOverlay').add(this._hide, this));
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._signalBindings) {
      this._signalBindings.forEach((binding) => {
        if (binding.detach) {
          binding.detach();
        }
      });
      this._signalBindings = null;
    }
    this._board = null;
    super.destroy();
  }

  /**
   * Inits the label when it shows up
   * @param {Object} boosterData
   * @param {number} boosterData.boosterNr
   */
  _init(boosterData) {
    if (G.tutorialOpened) return;

    const { boosterNr } = boosterData;
    G.changeTexture(this._ico, `ui_booster_${boosterNr}`);
    this._txt.setText(this.textLookup[boosterNr.toString()]);


    // this._refreshPivot();

    this.alpha = 0;
    G.stopTweens(this);
    game.add.tween(this)
      .to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.Out, true);
    this._resize();
  }

  _hide() {
    G.stopTweens(this);
    game.add.tween(this)
      .to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.Out, true);
  }

  /**
   * Fixes pivot
   */
  _refreshPivot() {
    if (this.pivotYSettings !== null) {
      this.pivot.y = this.pivotYSettings;
      this.pivotYSettings = null;
    }
  }

  /**
   * Resize
   */
  _resize() {
    /**
     * Horizontal feedback for booster labels seemed like it was a half done job sort of thing
     * Plus it is not certain that there is even enough horizontal space for the text to be in at a certain resolution
     * Maybe in the future we can use the horizontal space but for now, what works well should be used.
    */
    this.x = this._isLandscape ? 440 : 330;
    this._txt.wordWrapWidth = 400;
    this._refreshVertical();
    // }
    this.y = !this._isLandscape
      ? this._board.y - 140
      : this._board.y - 50;
  }

  /**
   * Repositions if in horizontal mode
   */
  _refreshHorizontal() {
    const startY = (this._ico.height + this._txt.height + 10) * -0.5;
    this._ico.y = startY;
    this._ico.x = 0;
    this._txt.y = this._ico.y + this._ico.height + 10;
    this._txt.x = 0;
    this._txt.anchor.setTo(0.5, 0);
  }

  /**
   * Repositions if in vertical mode
   */
  _refreshVertical() {
    const startX = (this._ico.width + this._txt.width + 10) * -0.5;
    this._ico.y = 0;
    this._ico.x = startX;
    this._txt.x = this._ico.x + this._ico.width + 10;
    this._txt.y = 0;

    if (this._isLandscape) {
      this._coinCounter.y = this._txt.y;
      this._coinCounter.x = this._txt.x + this._txt.width + this._coinCounter.width;
    } else {
      this._coinCounter.y = this._txt.y + this._txt.height + 5;
      this._coinCounter.x = this._txt.x + this._txt.width / 2;
    }
    this._txt.anchor.setTo(0, 0.5);
  }
}
