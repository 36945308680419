// Generic 9-slice progress bar component based on G.PFProgressBar

import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';

export class ProgressBar extends Phaser.Group {
  constructor(game, config, parent) {
    super(game, parent);
    /* eslint-disable-next-line prefer-object-spread */
    const cfg = Object.assign({
      atlas: 'shared-elements',
      bgSprite: 'progressBar-bg',
      fgSprite: 'progressBar-fg',
      x: 0,
      y: 0,
      width: 400,
      height: 136,
      nineSliceBounds: {
        top: 12,
        bottom: 12,
        left: 14,
        right: 14,
      },
      value: 1,
      valueMax: 3,
      hasLabel: true,
      isAnimated: false,
      fillSfx: null,
      fullSfx: null,
    }, config);

    // Save relevant values for later
    this._x = cfg.x;
    this._y = cfg.y;
    this._value = cfg.value; // The actual value
    this._displayValue = cfg.value; // The value currently displayed
    this._valueMax = cfg.valueMax;
    this._fullWidth = cfg.width;
    this._height = cfg.height;
    this._isAnimated = cfg.isAnimated;
    this._fillSfx = cfg.fillSfx;
    this._fullSfx = cfg.fullSfx;
    this._tween = undefined;

    this._isFilled = cfg.value >= cfg.valueMax;

    // Create elements
    const centeredX = this._x - this._fullWidth / 2;
    const centeredY = this._y - this._height / 2;

    this._barBg = new UI_NineSlice(
      centeredX,
      centeredY,
      cfg.bgSprite,
      this._fullWidth,
      this._height,
      cfg.nineSliceBounds,
    );

    this._barFg = new UI_NineSlice(
      centeredX,
      centeredY,
      cfg.fgSprite,
      Math.max(Math.floor(this._fullWidth * (this._displayValue / this._valueMax)), 1),
      this._height,
      cfg.nineSliceBounds,
    );

    this.add(this._barBg);
    this.add(this._barFg);

    if (cfg.hasLabel) {
      this._label = new G.Text(this._x, this._y + 3, `${this._value}/${this._valueMax}`, {
        style: 'font-white',
        fontSize: '35px',
      }, 0.5, 400);
      this.add(this._label);
    }
  }

  /**
   * Shows/hides progress bar
   * @param {boolean} visible
   */
  changeVisibility(visible) {
    this.visible = visible;
  }

  /**
   * Change value and max value and animate bar
   * @param {number} value
   * @param {number} valueMax
   * @param {function} onFinish function that runs where animation is finished
   */
  updateValue(value, valueMax, onFinish, tweenEasing) {
    if (valueMax) {
      this._valueMax = valueMax;
    }
    if (!tweenEasing) {
      tweenEasing = Phaser.Easing.Quadratic.Out;
    }

    const oldValue = this._value;
    this._value = value;

    // Note: Don't start animation if the value delta is 0, otherwise the tween will assign NaN to _displayValue
    if (this._isAnimated && oldValue !== this._value) {
      if (this._fillSfx) {
        G.sfx[this._fillSfx].play();
      }

      this.stop();
      this._tween = game.add.tween(this).to({ _displayValue: this._value }, 1000, tweenEasing, true, 0);
      this._tween.onUpdateCallback(this._updateBar, this);
      this._tween.onComplete.add(() => {
        this._updateBar();
        if (onFinish) {
          onFinish();
        }
      }, this);
    } else {
      this._displayValue = this._value;
      this._updateBar();
    }
  }

  /**
   * Update bar fill
   */
  _updateBar() {
    // The bar itself can't go over 100% filled, but the label can display fractions > 1 (e.g. 13/5)
    const clampedDisplayValue = Math.min(this._displayValue, this._valueMax);
    this._barFg.resize(Math.max(Math.floor(this._fullWidth * (clampedDisplayValue / this._valueMax)), 1), this._barFg.height);
    this._updateLabel();

    if (this._fullSfx && !this._isFilled && this._displayValue >= this._valueMax) {
      this._isFilled = true;
      G.sfx[this._fullSfx].play();
    }
  }

  /**
   * Update bar label if it exists
   */
  _updateLabel() {
    if (this._label) {
      this._label.text = `${Math.floor(this._displayValue)}/${this._valueMax}`;
    }
  }

  /**
   * Stops the tween to prevent crashes
   */
  stop() {
    if (this._tween) {
      if (this._tween.stop) {
        this._tween.stop();
      }
      this._tween = null;
    }
  }
}
