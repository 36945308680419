/* eslint-disable operator-linebreak */
if (typeof G === 'undefined') G = {};
G.UI = G.UI || {};

{
  const setUIElementSize = (uiElement, size) => {
    // imports
    const { ElementType } = G.UI;
    const setTextMaxWidth = G.Text.setMaxWidth;
    const setTextMaxHeight = G.Text.setMaxHeight;

    const uiComponent = uiElement.customComponents.ui;
    const elementType = uiComponent.type;
    if (elementType === ElementType.Text) {
      if (Number.isFinite(uiElement.customComponents.text.maxWidth)) {
        setTextMaxWidth(uiElement, size.x);
      }
      if (Number.isFinite(uiElement.customComponents.text.maxHeight)) {
        setTextMaxHeight(uiElement, size.y);
      }
      uiElement.wordWrapWidth = size.x;
      uiElement.applyMaxSizeConstraint();
    } else if (elementType === ElementType.NineSlice) {
      uiElement.resize(size.x, size.y);
    } else if (
      elementType !== ElementType.Group &&
      elementType !== ElementType.JSON
    ) {
      uiElement.width = size.x;
      uiElement.height = size.y;
    }
  };

  const downscaleToMeetAspectRatio = (
    outVector,
    width,
    height,
    aspectRatio,
  ) => {
    const x1 = height * aspectRatio;
    const y1 = height;

    const x2 = width;
    const y2 = width / aspectRatio;

    if (x1 * y1 < x2 * y2) {
      outVector.x = x1;
      outVector.y = y1;
    } else {
      outVector.x = x2;
      outVector.y = y2;
    }
  };

  const applyLayoutConstrains = (
    outSize,
    outCurrentOffset,
    size,
    constrains,
    desiredSize,
  ) => {
    const availableWidth = size.x;
    const availableHeight = size.y;

    let newWidth = availableWidth;
    let newHeight = availableHeight;

    // Constrain `newWidth` / `newHeight`
    if (constrains.widthMin !== null) {
      newWidth = Math.max(newWidth, constrains.widthMin);
    }
    if (constrains.widthMax !== null) {
      newWidth = Math.min(newWidth, constrains.widthMax);
    }
    if (constrains.heightMin !== null) {
      newHeight = Math.max(newHeight, constrains.heightMin);
    }
    if (constrains.heightMax !== null) {
      newHeight = Math.min(newHeight, constrains.heightMax);
    }

    let aspectRatio = null;

    // Figure out current `aspectRatio`
    if (constrains.preserveAspectRatio) {
      if (desiredSize.x > 0 && desiredSize.y > 0) {
        aspectRatio = desiredSize.x / desiredSize.y;
      }
    } else if (
      constrains.aspectRatioMin !== null ||
      constrains.aspectRatioMax !== null
    ) {
      if (availableWidth > 0 && availableHeight > 0) {
        aspectRatio = availableWidth / availableHeight;
      }
    }

    // Constrain `aspectRatio`
    if (aspectRatio !== null) {
      if (constrains.aspectRatioMin !== null) {
        aspectRatio = Math.max(aspectRatio, constrains.aspectRatioMin);
      }
      if (constrains.aspectRatioMax !== null) {
        aspectRatio = Math.min(aspectRatio, constrains.aspectRatioMax);
      }
    }

    // Apply `aspectRatio`
    if (aspectRatio !== null) {
      downscaleToMeetAspectRatio(outSize, newWidth, newHeight, aspectRatio);
      newWidth = outSize.x;
      newHeight = outSize.y;
    } else {
      outSize.x = newWidth;
      outSize.y = newHeight;
    }

    outCurrentOffset.x +=
      constrains.constrainAnchorX * (availableWidth - newWidth);
    outCurrentOffset.y +=
      constrains.constrainAnchorY * (availableHeight - newHeight);
  };

  const resize = (uiElement, parentWidth, parentHeight) => {
    // imports
    const { ElementType } = G.UI;
    const uiComponent = uiElement.customComponents.ui;

    if (!uiComponent) return false;

    const layout = uiComponent.anchoredLayout;
    const pivotLocalOffsetFromParentTopLeft = layout.getLocalPosition(
      parentWidth,
      parentHeight,
      uiComponent.pivot,
    );
    const size = layout.getLocalSize(parentWidth, parentHeight);

    applyLayoutConstrains(
      size,
      pivotLocalOffsetFromParentTopLeft,
      size,
      uiComponent.layoutConstrains,
      uiComponent.desiredSize,
    );

    // When a button has child, it affects its world transform - this chunk is to counter that
    if (!(uiElement.parent instanceof Phaser.Group)) {
      pivotLocalOffsetFromParentTopLeft.x /= uiElement.parent.scale.x;
      pivotLocalOffsetFromParentTopLeft.y /= uiElement.parent.scale.y;
      size.x /= uiElement.parent.scale.x;
      size.y /= uiElement.parent.scale.y;
    }

    uiElement.x = pivotLocalOffsetFromParentTopLeft.x;
    uiElement.y = pivotLocalOffsetFromParentTopLeft.y;

    uiComponent.sizeAfterLastResize.copyFrom(size);
    setUIElementSize(uiElement, size);

    if (uiComponent.type !== ElementType.Text) {
      uiElement.pivot.set(
        (uiComponent.pivot.x * size.x) / uiElement.scale.x,
        (uiComponent.pivot.y * size.y) / uiElement.scale.y,
      );
    }

    if (uiElement.children) {
      uiElement.children.forEach((child) => {
        if (child.customComponents) {
          resize(child, size.x, size.y);
        }
      });
    }

    return true;
  };

  G.UI.resize = resize;
  G.UI.setUIElementSize = setUIElementSize;
}
