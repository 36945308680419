/* eslint-disable no-unused-vars */
import { CandyType_Basic } from './CandyType_Basic';

/**
 * class for infection CandyType
 */
export class CandyType_Infection extends CandyType_Basic {
  /**
   * constructor
   * @param {BoardGameHooks} gameHooks
   */
  constructor(gameHooks) {
    super(gameHooks);
  }

  /**
   * on candy hit by adjacent match.
   * @returns {boolean}
   */
  onHit() {
    G.sb('fxTop').dispatch('burstInfectionAnim', this._candy, this._candy);
    this.signals.removeCandy.dispatch();
  }
}
