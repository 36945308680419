/* eslint-disable function-paren-newline */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable no-new */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import { default as SettingsMenu } from '../OMT_UI/Menus/Settings/OMT_UI_SettingsMenu';
import TutorialNewGingy from '../Elements/GingyTutorial/G.TutorialNewGingy';
import LevelEconomyTracker from '../Elements/GameTracking/LevelEconomyTracker';
import { FadeLayer } from '../Elements/FadeLayer';
// import { XPROMO_PLACEMENTS } from '../Services/OMT/OMT_CrossPromo';
import LevelBg from '../Elements/GameState/UI/Layers/LevelBg';
import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import StartBoosterConfig from '../Elements/StartBoosterConfig';
import { LevelType, TreasureChestTypes } from '@omt-game-board/Managers/GameEnums';
import PerLevelData from '../Leaderboards/Legacy/PerLevelData';
import CollectableAnimLayer from '../Elements/GameState/CollectableAnim/CollectableAnimLayer';
import { UI_HelpersManager } from '../Elements/GameState/UI/UI_HelpersManager';
import UI_ShoutOuts from '../Elements/GameState/UI/UI_ShoutOut';
import PointsLayer from '@omt-game-board/Elements/GameState/UI/Layers/PointsLayer';
import Overlay from '../Elements/GameState/UI/Layers/Overlay1';
import Overlay2 from '../Elements/GameState/UI/Layers/Overlay2';
import UI_BoosterPanel from '../Elements/GameState/UI/Boosters/UI_BoosterPanel';
import UITargetParticles from '../Elements/G.UITargetParticles';
import ChestLayer from '../Elements/GameState/UI/Layers/ChestLayer';
import UIFxLayer from '@omt-game-board/Elements/GameState/UI/Layers/UIFxLayer';
import TopFxLayer from '@omt-game-board/Elements/GameState/UI/Layers/TopFxLayer';
import { Board } from '@omt-game-board/Board/Board';
import { BragRightsManager } from '../Elements/Windows/Brag/BragRightsManager';
// import { LevelLeaderboard } from '../Leaderboards/Level/LevelLeaderboard';
import { OMT_TexturePreloader } from '../Utils/OMT_TexturePreloader';
import TargetedOfferDataManager, { TARGETED_OFFER_IDS } from '../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { DeconstructMsgTournament } from '@omt-game-board/Board/deconstructMsg/DeconstructMsgTournament';
// import { DeconstructMsgTournamentTaunt } from '@omt-game-board/Board/deconstructMsg/DeconstructMsgTournamentTaunt';
import { DeconstructMsgDefault } from '@omt-game-board/Board/deconstructMsg/DeconstructMsgDefault';
import { TournamentFTUETutorial } from '../Elements/GingyTutorial/TournamentFTUETutorial';
import { sagaPromoChance, leaderboardHeightLimit } from '../Elements/Windows/Tournament/SagaMapPromoConfig';
import MysteryGiftHeaderInGame from '../Elements/G.MysteryGiftHeaderInGame';
import { EventTokenCounter } from '../Elements/SpecialEvent/EventTokenCounter';
import { EventGingyFail } from '../Elements/SpecialEvent/EventGingyFail';
import OMT_VILLAINS from '../OMT_UI/OMT_Villains';
import { OMT_SessionUtil } from '../Utils/OMT_SessionUtil';
import OMT_AnimationFactory from '../Utils/Animation/OMT_AnimationFactory';
import OMT_StackManager from '../Utils/OMT_StackManager';
import VillainsInGame from '../OMT_UI/Villains/VillainsInGame';
import { OMT_BoardGameHooks } from '../Board/OMT_BoardGameHooks';
import { BOARD_FEATURE_KEYS } from '@omt-game-board/Board/BoardGameHooks';
import { OMT_SystemInfo, ORIENTATION } from '../Services/OMT/OMT_SystemInfo';
import UI_TopBarHorizontal from '../Elements/GameState/UI/TopBar/horizontal/UI_TopBarHorizontal';
import { UI_BoosterButtonHorizontal } from '../Elements/GameState/UI/UI_BoosterPanelHorizontal';
import VillainsInGameLandscape from '../OMT_UI/Villains/VillainsInGameLandscape';
import LevelNumberTab from '../Elements/GameState/UI/TopBar/vertical/LevelNumberTab';
import LevelNumberTabHorizontal from '../Elements/GameState/UI/TopBar/horizontal/LevelNumberTabHorizontal';
import { INTERSTITIAL_RULES } from '../Services/OMT/ads/OMT_InterstitialAdRules';
import { GameScaleController } from './Scaling/GameScaleController';
import TreasureHuntCounter from '../Elements/TreasureHunt/TreasureHuntCounter';
import UI_TopBar from '../Elements/GameState/UI/TopBar/vertical/UI_TopBar';
// import WallClockTimer from './../ ../../../Utils/Timers/WallClockTimer';
import WallClockTimer from '../Utils/Timers/WallClockTimer';

let stateId = 0; // this needs to be globally accessible to be checked properly

/**
 * Welcome to the game state where a lot of the fun happens!
 * A lot of global variable accessing happens and its hard to know what is what, but here's quick list:
 *
 * state = game.js
 * game.state.getCurrentState() = game.js (unless on world map)
 * this.state = game.js (unless on world map)
 * G.lvl = this._lvlDataManager
 */
export default class Game {
  /**
   * Required by Phaser
   * This is the very first thing that gets called when the state starts.
   * Called before preload or create
   * @param {Object} config
   * @param {number} [config.lvlIndex]
   * @param {boolean} [config.debugMode]
   * @param {Object || Array<number>} [config.startBoosters]
   * @param {Array<number>} [config.startBoosters.player] = Regular preboosters
   * @param {Array<number>} [config.startBoosters.adRewarded] = Ad-rewarded preboosters
   * @param {Array<number>} [config.startBoosters.requestHelp] = Request help preboosters
   * @param {Object} [config.challengeLvl]
   * @param {Object} [config.preLevelTrackingData]
   */
  init(config) {
    // Set up variable so that game.js can be accessed from literally anywhere.
    window.s = game.state.getCurrentState(); // IT IS this.
    this.state = s; // ALSO this.
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;

    this._config = config;
    this._removedLifeAtStart = false;
    this.warningGingyShown = false;

    this._preLevelTrackingData = this._config.preLevelTrackingData || {};

    // increment the state count / id
    stateId++;

    // Getting level data
    this._collectLevelData();
    this._setDebugMode(this._config.debugMode);
    // TODO: Fix brag 2.0 for hard and super hard levels
    this._configureBrag2Level();

    this._signalBindings = []; // For collecting signals to dispose of later

    // Check if this level is going to corrupt
    this._setLevelCorruptionData();

    // Initialize DDNA mission tracker. To be used in create()
    this._createGameSessionData();
    /* this._trackDDNAOnInit(); */
  }

  /**
   * Required by Phaser. Preload is called after init
   */
  preload() {
    G.Helpers.loadShoutouts(OMT.language.lang);
  }

  /**
   * Required by Phaser. Create is immediately called after preload is finished
   */
  create() {
    OMT_VILLAINS.setLevelType(this.mode);
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const isDailyChallenge = this.mode === LevelType.CHALLENGE;
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallenge;

    this._trackGameplayFlags();

    // Initialize Gameplay controller and G.lvl
    this._createLvlDataManager(this.lvlIndex, this.lvlData, this.mode);

    // Background
    let _bgTexture = 'background_1';
    if (this.mode === LevelType.COLLECT_EVENT) {
      _bgTexture = G.OMTsettings.tokenEvent.gameBackgroundAsset;
    } else if (this.mode === LevelType.TREASURE_HUNT) {
      _bgTexture = G.OMTsettings.treasureHuntSuper.gameBackground;
    } else if (isNotNormalLevel || isDailyChallenge) {
      _bgTexture = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_background') : OMT_VILLAINS.getPrefixedName('hard_background');
    }
    this._bg = new LevelBg(_bgTexture);

    // Board
    this._createBoard();

    // Top bar
    if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
      this.topBar = new UI_TopBar(this._lvlDataManager, this.lvlData, this.mode); // Accessed outside
    } else {
      this.topBar = new UI_TopBarHorizontal(this._lvlDataManager, this.lvlData, this.mode); // Accessed outside
    }

    // Create treasure hunt counter after top bar
    if (this.mode === LevelType.TREASURE_HUNT) {
      G.saveState.treasureHuntManager.resetTempTokens();
      const isDoubling = G.saveState.treasureHuntManager.levelAttempts === 0;
      if (isDoubling) {
        G.saveState.treasureHuntManager.activateDoubleToken();
      }
      this._treasureHuntCounter = new TreasureHuntCounter(game.world);
      G.saveState.treasureHuntManager.incrementLevelAttempts();
    }

    // Reposition treasure hunt counter relative to top bar
    if (this._treasureHuntCounter) {
      const repositionCounter = () => {
        this._treasureHuntCounter.x = game.world.x + game.width / 2;
        let beginningSpot = 0;
        const boardPos = (this.board.cellToPxOut([0, 0])[1] - (this.board.tileSize)) * this.board.scale.y;
        if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
          beginningSpot = this.topBar.bg.y + this.topBar.bg.height;
        }
        const posY = beginningSpot + (boardPos - beginningSpot) / 2;
        this._treasureHuntCounter.y = posY;
      };
      this._signalBindings.push(G.sb('onScreenResize').add(repositionCounter.bind(this)));
      repositionCounter();
    }

    // The number tab
    this._createNumberTab();

    // Set up special event in-game level UI elements if active
    // Note: This isn't done in _checkEventsOnBoard because the UI elements don't appear above the dark overlay.
    if (this.mode === LevelType.COLLECT_EVENT) {
      this._setupInLevelEventUI();
    }


    // Creates the booster panel and the booster buttons
    if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
      this.boosterPanel = new UI_BoosterPanel(this.board, this.mode);
    } else {
      this.boosterPanel = new UI_BoosterButtonHorizontal(this.board, this.mode); // Accessed outside
    }

    // Initialize the DDNA mission tracker
    /* this._createDDNATrackingOnCreate(); */

    // Update level number for Sentry
    this._updateSentryLevelNumber();

    // Overlay 2 layer
    this._createOverlay2();

    // Create the settings menu
    this._createSettingsMenu();

    this.initPostCorruptionAnimation();

    // Collectable animation layer for gems breaking
    this.collectableAnimLayer = new CollectableAnimLayer(this.board, this.topBar.goalPanel);

    // Chest layer for the game board
    this._createChestLayer();

    // FX Layers
    this._createFXLayers();

    // Points
    this.pointsLayer = new PointsLayer(this.topBar.pointsCounter);

    // The Request Help 2.0, intialized here rather then below to maintain previous layering
    if (this.mode !== LevelType.TOURNAMENT) this._initHelpersManager();

    // Shoutout layer
    this.shoutOuts = new UI_ShoutOuts(this.board);

    // Overlay 1 layer. For tutorials and boosters
    this.overlay = new Overlay(this); // Accessed externally

    // Window manager for when windows pop up in the board
    this._createWindowManager();

    // Particle layers
    this.uiTargetParticles = new UITargetParticles();

    const { lvlIndex, corruptionSourceLevel } = this;
    const session = OMT_SessionUtil.getInstance();
    const sessionData = session.checkAndCreateWithKey(OMT_VILLAINS.getLevelTrackerKey());
    const levelSessionData = sessionData.checkAndCreateWithKey(lvlIndex);

    G.fadeLayer = new FadeLayer(!corruptionSourceLevel);

    // prepare spritesheets used for gameplay
    this._prepSpriteSheets();

    if (isNotNormalLevel && (this.mode === LevelType.NORMAL || this.mode === LevelType.CHALLENGE) && OMT.feature.isVillainsEnabled()) {
      // Get ready for villains win-lose animation
      this._initVillains();
      G.Utils.cleanUpOnDestroy(this.topBar,
        G.sb('onGoalAchieved').addOnce(() => {
          // Villain enter angrily animation
          G.sb(OMT_VILLAINS.getInGameState('WIN_START')).dispatch(this);
        }),
      );
    }

    this._signalBindings.push(G.sb('GameExtraMovesAmoount').add((amount) => { this._movesLeftOver = amount; }));

    game.resizeGame();

    const levelPlayedThisSession = levelSessionData.getData('played');

    if (OMT.feature.delayedBoardAppereanceEnabled() && isNotNormalLevel && !levelPlayedThisSession && this.mode === LevelType.NORMAL) {
      this._triggerBoardAnimation(1000).then(() => {
        this._initTutorials();
        levelSessionData.setData('played', true);
      });
    } else {
      if (!corruptionSourceLevel) {
        levelSessionData.setData('played', true);
      }
      this._initTutorials();
    }
    window.GBCXPromo.gameStart();
  }

  /**
   * Checks if this level is going to be corrupted
   * @returns {object | undefined}
   */
  _setLevelCorruptionData() {
    const { lvlIndex } = this;
    const session = OMT_SessionUtil.getInstance();
    const sessionData = session.checkAndCreateWithKey(OMT_VILLAINS.getLevelTrackerKey());
    const levelSessionData = sessionData.checkAndCreateWithKey(lvlIndex);
    const corruptionSessionData = levelSessionData.checkAndCreateWithKey('corruption_game');
    const corruptionSourceLevel = corruptionSessionData.getData('source');
    this.corruptionSourceLevel = corruptionSourceLevel;
  }

  /**
   * Initialize and check for tutorial content in the current level
   */
  async _initTutorials() {
    const { corruptionSourceLevel } = this;

    if (this.mode !== LevelType.TOURNAMENT) {
      const anyOfThese = [LevelType.COLLECT_EVENT, LevelType.CHALLENGE, LevelType.TREASURE_HUNT];
      if (anyOfThese.indexOf(this.mode) > -1) {
        this._checkEventsOnBoard(); // Check the events that will alter the board. Such as fortune cookie or mystery gift
        this.windowMgr.pushWindow(['taskSlider', this.mode]);
        this._signalBindings.push(G.sb('onAllWindowsClosed').addOnce(() => {
          this.board.actionManager.newAction('startBoosterInit');
          if (this.treasureHuntCounter) {
            this.treasureHuntCounter.show();
          }
        }, this));
      } else if (corruptionSourceLevel !== undefined) {
        await this._initVillainsEvents();
      } else {
        await this._initVillainsEvents();
        this._checkEventsOnBoard(); // Check the events that will alter the board. Such as fortune cookie or mystery gift
        this._checkTutorials(); // Check for tutorial
        this.windowMgr.pushWindow(['taskSlider', this.mode]);
      }
    } else {
      const tournamentTutorialEnabled = G.firstTime === true;
      const finishedTutorial = G.saveState.isFinishedTutorial('1') || G.saveState.isFinishedTutorial('tournamentFTUETutorial');
      if (tournamentTutorialEnabled && !finishedTutorial) { // FTUE tournament tutorial
        this._showTournamentTutorial();
      } else { // no tutorial apply start boosters now
        this.board.actionManager.newAction('startBoosterInit');
      }
    }
    // set the active tutorial references in the LvlDataManager / gameController
    if (this.tut) this._lvlDataManager.activeTutorial = this.tut;
  }

  /**
   * Triggers a board reveal animation after specified duration in milliseconds
   * @param {number} ms The duration to wait before the animation starts
   * @returns {Promise}
   */
  async _triggerBoardAnimation(ms) {
    await this.board.hide();
    await new Promise((resolve) => {
      game.time.events.add(ms, async () => {
        await this.board.showWithAnimation();
        resolve();
      });
    });
  }

  /**
   * Update
   */
  update() {
    G.delta();
    // OMT.performanceMonitor.update();
    if (G.DEBUG) {
      this._dbgPos = this.board.inputController.pointerToCell(game.input.activePointer);
    }
  }

  /**
   * Shutdown is called when the state is closed.
   * Don't forget to clean up signal bindings!
   */
  shutdown() {
    if (this._signalBindings) {
      this._signalBindings.forEach((binding) => {
        if (binding.detach) {
          binding.detach();
        }
      });
      this._signalBindings = null;
    }
    G.saveState.mysteryGiftManager.inGame_clearSessionData();
    this._lvlDataManager.destroy();
    this._chestLayer.destroy();
    this.shoutOuts.destroy();
    this._bg.destroy();
    this.boosterPanel.destroy();
    this.pointsLayer.destroy();
    if (this.movesHelper) this.movesHelper.destroy();
    this.overlay.destroy();
    this.topBar.destroy();
    this._overlayUnderSettings.destroy();
    this.fxTopLayer.destroy();
    this.UIFxLayer.destroy();
    this.startBoosterFXLayer.destroy();
    if (this.treasureHuntCounter) {
      this.treasureHuntCounter.destroy();
      this._treasureHuntCounter = null;
    }
    this.eventTokenCounter = null;
    this._perLevelData = null;
  }

  get removedLifeAtStart() {
    return this._removedLifeAtStart;
  }

  /**
   * create some instances from the required spritesheets to reduce lag during gameplay
   */
  _prepSpriteSheets() {
    const burstImage = new G.Image(0, 0, 'cookie_match_1', 0.5, null);
    burstImage.y = -200;
    game.world.addChild(burstImage);
  }

  /**
   * Finds out level data and sets up references
   */
  _collectLevelData() {
    if (this._config.challengeLvl) {
      this.lvlIndex = 10000 + (G.saveState.data.dailyBeaten || 0);
      this.lvlData = this._config.challengeLvl;
      this.mode = LevelType.CHALLENGE;
    } else if (this._config.tournamentLvl) {
      this.lvlData = this._config.tournamentLvl;
      this.mode = LevelType.TOURNAMENT;
    } else if (this._config.eventLvl) {
      this.lvlIndex = Math.min(G.Helpers.levelDataMgr.getMaxLevelIndex(), this._config.lvlIndex);
      this.lvlData = this._config.eventLvl;
      this.mode = LevelType.COLLECT_EVENT;
    } else if (this._config.treasureHuntLevel) {
      this.lvlIndex = this._config.lvlIndex;
      this.lvlData = this._config.treasureHuntLevel;
      this.mode = LevelType.TREASURE_HUNT;
    } else {
      this.lvlIndex = Math.min(G.Helpers.levelDataMgr.getMaxLevelIndex(), this._config.lvlIndex);
      this.lvlData = G.Helpers.levelDataMgr.getLevelByIndex(this.lvlIndex);
      this.mode = LevelType.NORMAL;
    }
    G.gameMode = this.mode; // added to be able to easily track the game mode
    G.lvlData = this.lvlData;
  }

  /**
   * Create object data to be used to keep track of things in the game board
   * The session data should always refresh when the game board refreshes
   */
  _createGameSessionData() {
    // If this level is going to be corrupted, skip this
    const { corruptionSourceLevel } = this;
    if (corruptionSourceLevel) return;

    // Created in game.js init(), deleted in game.js shutdown()
    G.saveState.mysteryGiftManager.inGame_createSessionData();
  }

  /**
   * configure settings for brag 2.0 level
   */
  _configureBrag2Level() {
    if (!OMT.feature.isBrag2Active()) return;
    this.isBrag2Level = BragRightsManager.getInstance().isBragLevel(G.lvlData.levelNumber);
    this.isBrag2Challenge = this._config.tutorial === 'brag_2.0';
    const refillCount = (G.firstTime && this.isBrag2Level ? 2 : G.json.settings.boostersOnStart);
    G.saveState.refillLockedOrInvalidBoosters(refillCount, 'configure brag 2.0 level');
  }

  /**
   * Track last level attempted by the player and reset player rewards
   */
  _trackDDNAOnInit() {
    // If this level is going to be corrupted, skip this
    // const { corruptionSourceLevel } = this;
    // if (corruptionSourceLevel) return;

    // send any previous incomplete level rewards
    // DDNA.transactionHelper.sendQueuedLevelCoinRewards();
    // DDNA.transactionHelper.resetQueuedLevelRewards();
    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam(
    //   'lastLevelAttempted',
    //   this.mode !== LevelType.NORMAL ? -1 : this.lvlData.levelNumber, true,
    // );
  }

  /**
   * Debug on or off
   * @param {boolean} dbg
   */
  _setDebugMode(dbg) {
    this._debugMode = dbg || false;
    G.debugMode = this._debugMode;
  }

  /**
   * This tracks various flags like lose a life, mystery gift, high score
   */
  _trackGameplayFlags() {
    // If this level is going to be corrupted, skip this
    const { corruptionSourceLevel } = this;
    if (corruptionSourceLevel) return;

    if (G.PERLEVELHIGHSCORE && this.mode !== LevelType.TOURNAMENT) { // Get a level data object going
      this._perLevelData = new PerLevelData(this.lvlData, this.mode === LevelType.CHALLENGE);
    }
    if (G.MYSTERYGIFT && !G.lvlData.noPreBoosters && this.mode !== LevelType.TREASURE_HUNT) {
      G.saveState.mysteryGiftManager.markLevelBeginning(); // Mark mystery gift
    }

    // Record level attempts
    const lvlId = this.mode === LevelType.TOURNAMENT ? 'tournament' : this.lvlIndex;
    G.saveState.onLevelRetry(lvlId);

    // Lose life to prevent cheating
    const levelsThatCanLoseLife = [LevelType.NORMAL];
    if (levelsThatCanLoseLife.indexOf(this.mode) > -1 && G.saveState.getUnlimitedLivesSec() === 0 && !this.isBrag2Challenge) {
      G.saveState.loseLife();
      this._removedLifeAtStart = true;
    }
  }

  /**
   * Initialize the gameplay controller. Also known has the fearful G.lvl.
   */
  _createLvlDataManager(lvlIndex, lvlData, gameMode) {
    // configuration for this seems over-complicated??
    const failFlowConfig = G.json.settings.failFlowConfig || {};

    // object holding settings so omt-game-board sub-module does not need to reference them.
    const settings = {
      lvlIndex,
      lvlData,
      gameMode,
      user: OMT.envData.settings.user,
      startBoosters: this._assembleStartBoosters(),
      scoring: {
        comboBonus: G.json.settings.comboBonus,
        pointsForMovesLeft: G.json.settings.pointsForMoveLeft,
      },
      lossAversion: {
        wheelIsLimited: OMT.feature.lossAversionWheelIsLimited(),
        wheelLimits: G.json.settings.lossAversionWheelLimits,
        extraMovesStartPrice: failFlowConfig.extraMovesStartPrice || G.json.settings.priceOfExtraMoves,
        extraMovesIncreaser: failFlowConfig.extraMovesIncreaser || G.json.settings.priceOfExtraMovesIncreaser,
        extraMovesNoSpinsDiscount: failFlowConfig.extraMovesNoSpinsDiscount || 0,
      },
      gameplayGoals: G.json['configs/gameplayGoals'],
      dropChances: {
        [BOARD_FEATURE_KEYS.FORTUNE_COOKIE]: G.featureUnlock.fortuneCookie.dropChance,
        [BOARD_FEATURE_KEYS.EVENT_TOKENS]: G.OMTsettings.tokenEvent.eventTokens.dropChance,
      },
      dropModifiers: {
        chests: G.saveState.getStars(lvlIndex) === 0 ? 1 : G.json.settings.completedLevelCoinsProb,
      },
      layouts: {
        tournamentTaunt: G.OMTsettings.elements.deconstructMsg.tournamentTaunt,
        fortuneCookieTimer: G.OMTsettings.elements.CandyType_FortuneCookie.timerPosition,
        tokenEvent: { boardTokenAsset: G.OMTsettings.tokenEvent.boardTokenAsset },
      },
    };

    // create board hooks instance. This should be used for ./Libs/ code to access other game features.
    const gameHooks = new OMT_BoardGameHooks(lvlData);
    // create the level data manager
    this._lvlDataManager = new LvlDataManager(gameHooks, settings);
    // legacy dependencies for OMT only !!
    G.lvl = this._lvlDataManager;
    G.lvlNr = this._lvlDataManager.lvlIndex;
  }

  /**
   * Imports the data from the config to the StartBoosterConfig
   * @returns {Object}
   */
  _assembleStartBoosters() {
    this.startBoosterConfig = new StartBoosterConfig(); // Accessed externally
    if (this._config.startBoosters && !this._config.tournamentLvl) {
      const {
        player,
        adRewarded,
        requestHelp,
        lvlIndex,
      } = this._config.startBoosters;
      this.startBoosterConfig.importData(player, adRewarded, requestHelp, lvlIndex);
    }
    const result = this._config.startBoosters || [];
    this._preLevelTrackingData.startBoosters = result;
    return result; // Access externally
  }

  /**
   * Create the great lord and mighty B O A R D
   */
  _createBoard() {
    this.board = new Board(this._lvlDataManager);
    this.board.onBoardDeconstructed.add(this._onBoardDeconstructed, this);
    this.board.onActionQueueEmpty.add(this._onActionQueueEmpty, this);

    this._onBoardResize();
    this._signalBindings.push(G.sb('onScreenResize').add(this._onBoardResize, this));
  }

  /**
   * Create the number tab that shows up on the top of the top bar
   */
  _createNumberTab() {
    const getLevelNumberToDisplay = (lvlIndex) => {
      if (lvlIndex >= 10000) return null;
      return lvlIndex + 1;
    };
    const levelIndexToDisplay = getLevelNumberToDisplay(this.lvlIndex);
    const { isNormalLevel } = this._lvlDataManager;
    if (isNormalLevel) {
      // Made with JSON UI...
      let levelNumberTab;
      if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
        levelNumberTab = new LevelNumberTab({
          game,
          parent: game.world,
          initialWidth: 640,
          initialHeight: 960,
          levelNumber: levelIndexToDisplay,
        });
        this._signalBindings.push(G.sb('onScreenResize').add(() => { levelNumberTab.resize(640, 960); }));
      } else {
        levelNumberTab = new LevelNumberTabHorizontal({
          levelNumber: levelIndexToDisplay,
        });
      }
      this._signalBindings.push(G.sb('onAllWindowsClosed').addOnce(levelNumberTab.animateSlideIn, levelNumberTab));
    }
  }

  /**
   * Create and initializes the DDNA mission tracker
   * Also tracks data
   */
  _createDDNATrackingOnCreate() {
    const { corruptionSourceLevel } = this;
    if (corruptionSourceLevel !== undefined) return;

    const extraData = {};
    if (this._preLevelTrackingData) {
      Object.assign(extraData, this._preLevelTrackingData);
    } else {
      this._preLevelTrackingData = {};
    }

    // DDNA.missionTracker.init(this.mode, extraData);
    // DDNA.tracking.getDataCapture().addToPlayerCharacterizationSessionParam('levelAttemptsThisSession', 1);

    // Begin tracking
    LevelEconomyTracker.getInstance().onLevelStarted(this.lvlIndex);
    OMT.platformTracking.logEvent(this.mode === LevelType.NORMAL ? 'level_start' : 'level_start_dc');

    // DDNA.tracking.ftuxEvent(3, 'gameFieldIsVisible');
    OMT.platformTracking.logFTUEvent('FTUGameFieldIsVisible');

    if (this.lvlIndex === 1) {
      // DDNA.tracking.ftuxEvent(7, 'secondLevelStart');
    } else if (this.lvlIndex === 2) {
      // DDNA.tracking.ftuxEvent(10, 'thirdLevelStart');
    } else if (this.lvlIndex === 3) {
      // DDNA.tracking.ftuxEvent(14, 'fourthLevelStart');
    } else if (this.lvlIndex === 10) {
      // DDNA.tracking.ftuxEvent(18, 'eleventhLevelStart');
    }

    OMT.milestoneTracking.incrementLevelAttempts();
  }

  /**
   * Update current level number in Sentry
   */
  _updateSentryLevelNumber() {
    const curLvl = this.mode === LevelType.NORMAL ? G.lvl.data.levelNumber : -1;
    // G.Utils.SentryLog.setCurrentLevel(curLvl);
  }

  /**
   * Creates an Overlay2.
   * Its slightly different from Overlay1 which is for the tutorials
   */
  _createOverlay2() {
    this._overlayUnderSettings = new Overlay2(true, 0.4);
    this._overlayUnderSettings.pointerUpSignal.add(
      function () { this.settingsMenu.expandedState = false; },
      this,
    );
  }

  /**
   * create the settings menu
   */
  _createSettingsMenu() {
    this.settingsMenu = new SettingsMenu(); // Accessed externally
    // on screen resized
    const onScreenResize = () => {
      if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
        this.settingsMenu.x = 74;
        this.settingsMenu.y = game.height - 65;
        if (!FBInstant.deviceDectorFunctions().isMobileOrTab()) {
          this.settingsMenu.x = game.width / 4 - 5;
        }
      } else {
        this.settingsMenu.scale.setTo(GameScaleController.getInstance().gameScale);
        this.settingsMenu.x = this.boosterPanel.x;
        this.settingsMenu.y = game.height - 65;
      }
    };
    // on menu expanded
    this.settingsMenu.signals.onExpand.add(() => {
      this._overlayUnderSettings.showOverlay();
      this.boosterPanel.animatePositionToHidden();
    });
    // on menu contracted
    this.settingsMenu.signals.onContract.add(() => {
      this._overlayUnderSettings.hideOverlay();
      this.boosterPanel.animatePositionToVisible();
    });

    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      this.settingsMenu.scale.set(1.15);
    }

    game.world.addChild(this.settingsMenu);
    onScreenResize();
    this._signalBindings.push(G.sb('onScreenResize').add(onScreenResize));
  }

  /**
   * Creates the chest layer that animates the chest on the board
   */
  _createChestLayer() {
    this._chestLayer = new ChestLayer(this);
    this._chestLayer.onChestGiftShown.add(this.onChestGiftShown, this);
  }

  /**
   * Creates FX layers for the UI and the board
   */
  _createFXLayers() {
    // User Interface FX layer (primarily used for start boosters). Accessed externally
    this.UIFxLayer = new UIFxLayer(this.board);

    // fx layer for start booster bubbles
    this.startBoosterFXLayer = new Phaser.Group(game);

    // Board fx layer
    this.fxTopLayer = new TopFxLayer(this.board, 'fxTop');
    this.fxTopLayer.position = this.board.candiesLayer.position;
    this.fxTopLayer.scale = this.board.candiesLayer.scale;
  }

  /**
   * Creates the window manager so windows can be shown
   */
  _createWindowManager() {
    this.windowMgr = new G.WindowMgr();
    this.windowMgr.addLayerToWorld('base');
    this.windowMgr.addLayerToWorld('retryLevel', { offset: { x: 0, y: G.WindowMgr.Constants.WorldVerticalOffset } }); // For the retry level popup when you fail the mission only
    this.windowMgr.addLayerToWorld(G.WindowMgr.LayerNames.OverlayLayer);
  }

  /**
   * init helpers UI for request help 2.0
   */
  _initHelpersManager() {
    this.movesHelper = new UI_HelpersManager(game, this, this._lvlDataManager);
    this.movesHelper.init();
  }

  /**
   * Checks for any events that should happen before the game board starts
   * If this is a special event level, all other events are vetoed.
   */
  _checkEventsOnBoard() {
    if (this.mode === LevelType.COLLECT_EVENT) {
      this._setupPostLevelEventUI();
    } else if (this.mode === LevelType.TREASURE_HUNT) {
      // Do not set up anything if treasure hunt
    } else if (this.mode !== LevelType.CHALLENGE) {
      this._checkFortuneCookie();
      this._checkMysteryGift();
      this._checkHelpers();
    }
  }

  /**
   * If this is a new user and they've launched the game from a shared fortune cookie msg
   */
  _checkFortuneCookie() {
    if (G.showFortuneCookie) {
      G.sb('pushWindow').dispatch(['fortuneCookie', {
        playOpenAnim: true,
        hideNotNow: true,
      }]);
      G.showFortuneCookie = false;
    }
  }

  /**
   * Gives user feedback that mystery gift is active
   */
  _checkMysteryGift() {
    // Keep winning to have more mystery gifts!
    if (G.MYSTERYGIFT
      && G.saveState.mysteryGiftManager.isModeReady()
      && this._lvlDataManager.latestLevel
      && G.saveState.getLevelRetries(this._lvlDataManager.lvlIndex) === 0
      && !this.lvlData.noPreBoosters
    ) {
      const currentStreak = G.saveState.mysteryGiftManager.getCurrentStreak();
      if (currentStreak === 0 && !OMT.feature.isMysteryGiftIsAllowed()) { return; }
      if (currentStreak < 3) {
        // allow to increase mysteryGift
        G.saveState.mysteryGiftManager.mysteryGiftModePeak = true;
        this.windowMgr.pushWindow('mysteryGiftLevel');
      }
    }
  }

  /**
   * Checks if Helpers 2.0 is active
   */
  _checkHelpers() {
    if (!G.saveState.sessionData.levelsFailedSinceUsingHelper) G.saveState.sessionData.levelsFailedSinceUsingHelper = {};
  }

  /**
   * show the tournament FTUE tutorial
   */
  _showTournamentTutorial() {
    this.tut = new TournamentFTUETutorial();
    this._signalBindings.push(G.sb('onTutorialFinish').addOnce(() => {
      if (!this.board.actionManager.hasActiveAction) {
        this.board.actionManager.newAction('startBoosterInit');
      } else {
        this._signalBindings.push(G.sb('actionQueueEmpty').addOnce(() => {
          this.board.actionManager.newAction('startBoosterInit');
        }, this));
      }
    }, this));
  }

  /**
   * Checks if a tutorial is in the level.
   * Also checks for popup tutorials after
   */
  _checkTutorials() {
    const tutID = this._config.tutorial ? this._config.tutorial : G.lvlData.tutID;
    const tutData = G.json.tutorials[tutID];
    const isValidTutorial = tutID != null && tutData != null;

    if (isValidTutorial && (tutData.alwaysShow || G.saveState.data.finishedTutorials.indexOf(tutID) === -1)) {
      const startTutorial = () => {
        if (tutData.steps) { // normal tutorial
          const textReplacements = []; // apply tutorial text replacements if there are any
          if (this._config.friendName) textReplacements.push({ token: '%friendName%', value: this._config.friendName });
          this.tut = new TutorialNewGingy(tutID, textReplacements);
        } else {
          this.tut = new G.Tutorial(tutID);
        }
      };
      this._signalBindings.push(G.sb('onAllWindowsClosed').addOnce(startTutorial));

      this._signalBindings.push(G.sb('onTutorialFinish').addOnce(() => {
        if (!this.board.actionManager.hasActiveAction) {
          this.board.actionManager.newAction('startBoosterInit');
        } else {
          this._signalBindings.push(G.sb('actionQueueEmpty').addOnce(() => {
            this.board.actionManager.newAction('startBoosterInit');
          }, this));
        }
      }, this));
      this._signalBindings.push(G.sb('onTutorialFinishDisplay').addOnce(() => {
        if (G.MYSTERYGIFT && G.saveState.mysteryGiftManager.isModeReady() && !G.lvlData.noPreBoosters) {
          new MysteryGiftHeaderInGame(this._lvlDataManager.latestLevel);
        }
        this.handleLastLifeNotification();
      }, this));
    } else {
      this._signalBindings.push(G.sb('onAllWindowsClosed').addOnce(() => {
        this.board.actionManager.newAction('startBoosterInit');
        if (G.MYSTERYGIFT && G.saveState.mysteryGiftManager.isModeReady() && !G.lvlData.noPreBoosters) {
          new MysteryGiftHeaderInGame(this._lvlDataManager.latestLevel);
        }
        this.handleLastLifeNotification();
      }, this));
    }

    // Check for pop up tutorials after that
    G.checkPopUpTutorials(this);
  }

  /**
   * Initialize villains update FTUX flow
   */
  async _initVillainsEvents() {
    // If this level is going to be corrupted, skip this
    const { lvlIndex, corruptionSourceLevel } = this;
    const session = OMT_SessionUtil.getInstance();
    const sessionData = session.checkAndCreateWithKey(OMT_VILLAINS.getLevelTrackerKey());
    const levelSessionData = sessionData.checkAndCreateWithKey(lvlIndex);
    const corruptionSessionData = levelSessionData.checkAndCreateWithKey('corruption_game');

    if (corruptionSourceLevel !== undefined) {
      const stack = OMT_StackManager.getFreeStack();
      let animationName = OMT_VILLAINS.getPrefixedName('hard_corruption_animation_game');
      if (corruptionSourceLevel.isSuperHardLevel) {
        animationName = OMT_VILLAINS.getPrefixedName('super_hard_corruption_animation_game');
      }

      const animationFactory = new OMT_AnimationFactory();
      stack.addEvent(() => {
        game.input.enabled = false;
      });
      stack.wait(1500);
      stack.addEvent(() => {
        G.sfx.exchange.play();
      });
      stack.addPromise(() => animationFactory.runAnimation(animationName, { parent: this.boosterPanel }));
      stack.addEvent(() => {
        game.input.enabled = true;

        corruptionSessionData.setData('source', undefined);
        const sourceLevelSessionData = sessionData.checkAndCreateWithKey(corruptionSourceLevel.index);
        const sourceGameCorruptionSessionData = sourceLevelSessionData.checkAndCreateWithKey('corruption_game');
        sourceGameCorruptionSessionData.setData('done', true);
        sourceGameCorruptionSessionData.setData('post_corrupt', true);

        const levelData = {
          lvlIndex: corruptionSourceLevel.index,
          debugMode: false,
          startBoosters: corruptionSourceLevel.startBoosters,
          challengeLvl: null,
          preLevelTrackingData: {},
          noFadeLayer: true,
        };
        G.saveState.disableDiscount();
        G.sb('onStateChange').dispatch('Game', levelData);
      });
      return stack.run();
    }
    return Promise.resolve();
  }

  /**
   * Initialize the take over animation that started on the corrupted level before
   */
  initPostCorruptionAnimation() {
    const { lvlIndex } = this;
    const { isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    const session = OMT_SessionUtil.getInstance();
    const sessionData = session.checkAndCreateWithKey(OMT_VILLAINS.getLevelTrackerKey());
    const levelSessionData = sessionData.checkAndCreateWithKey(lvlIndex);
    const corruptionSessionData = levelSessionData.checkAndCreateWithKey('corruption_game');
    const postCorruptionCorrupt = corruptionSessionData.getData('post_corrupt');
    if (postCorruptionCorrupt) {
      let animationName = OMT_VILLAINS.getPrefixedName('hard_post_corruption_animation_game');
      if (isSuperHardLevel) {
        animationName = OMT_VILLAINS.getPrefixedName('super_hard_post_corruption_animation_game');
      }

      const animationFactory = new OMT_AnimationFactory();
      game.input.enabled = false;
      animationFactory.runAnimation(animationName, { parent: this.boosterPanel }).then(() => {
        G.sfx.line.play();
        game.input.enabled = true;
        corruptionSessionData.setData('post_corrupt', false);
      });
    }
  }

  /**
   * Handles the last life notification to make you feel like your life is on the line!
   */
  handleLastLifeNotification() {
    if (G.saveState.getLives() !== 0 || G.saveState.getUserCooldownRemaining('unlimitedLives', '') > 0) return;

    G.NotificationsMgr.pushNotification(
      OMT.language.getText('This is your last life! Try your best!'),
      'broken_heart',
      2500,
    );
  }

  /**
   * End of level, check if we can show ads
   * @param {function} callback
   * @param {INTERSTITIAL_RULES} rule
   */
  endPointCheckForAds(callback, rule) {
    if (!G.saveState.sessionData.tryNumber) G.saveState.sessionData.tryNumber = 0;
    let showAd = false;

    if (G.saveState.getLastPassedLevelNr() >= G.featureUnlock.unlockLevels.interstitialAds) {
      // Check all interstitial ad rules to determine if ad should be shown
      switch (rule) {
        // Every N tries
        case INTERSTITIAL_RULES.EVERY_N:
          showAd = !OMT.feature.useAdditionalInterstitialAdRules()
            && G.saveState.sessionData.tryNumber % G.json.settings.interstitialSettings.everyN === 0;
          G.saveState.sessionData.tryNumber++;
          break;

        // Out of moves
        case INTERSTITIAL_RULES.OUT_OF_MOVES:
          showAd = OMT.feature.useAdditionalInterstitialAdRules();
          break;

        // Winning twice in a row
        case INTERSTITIAL_RULES.WIN_TWICE_IN_A_ROW:
          showAd = OMT.feature.useAdditionalInterstitialAdRules()
            && G.saveState.getCWinCount() > 0
            && G.saveState.getCWinCount() % 2 === 0;
          break;

        // Manually quitting a level
        case INTERSTITIAL_RULES.GIVE_UP:
          showAd = OMT.feature.useAdditionalInterstitialAdRules();
          break;

        case INTERSTITIAL_RULES.TOURNAMENT_WIN:
          showAd = OMT.feature.useAdditionalInterstitialTournamentWin();
          break;

        default:
          console.log('** end point error - invalid interstitial ad rule');
      }
    }

    if (showAd && G.saveState.getIAPCount() === 0) {
      // if (OMT.crossPromo.isPlacementActive(XPROMO_PLACEMENTS.INTERSTITIAL) && !OMT.crossPromo.isCrossPromoSeen()) {
      //   const crossPromoData = OMT.crossPromo.getPromoByPlacementId(XPROMO_PLACEMENTS.ICON_PICTURE_COMBO); // TODO: update placement id
      //   OMT.crossPromo.showCrossPromoWindow('interstitial', crossPromoData, callback);
      //   console.log('** end point showCrossPromoAd');
      // } else
      if (OMT.feature.interstitialAdsAvailable() && false) {
        console.log('** end point showAd');
        let placement;
        switch (G.PerSessionSettings.interstitialsInSession) {
          case 0:
            placement = G.BuildEnvironment.adPlacements.firstInterstitialInSession;
            break;
          case 1:
            placement = G.BuildEnvironment.adPlacements.secondInterstitialInSession;
            break;
          default:
            placement = G.BuildEnvironment.adPlacements.interstitial;
        }

        G.PerSessionSettings.interstitialsInSession++;

        // needed to ad the ad shown event on success
        const successCallback = () => {
          // Decide whether or not to push the no ads popup
          if (G.IAP
            && G.saveState.getIAPCount() === 0
            && OMT.feature.isNoAdsPopupOn()) {
            const highestLevel = G.saveState.getLastPassedLevelNr() + 1;
            const sessionsNotSeen = G.saveState.getAdsSinceLastNoAdsPopup();
            const { startLevel, periodLength } = G.json.settings.noAdsPopup;

            if (startLevel > -1 && startLevel <= highestLevel && sessionsNotSeen >= periodLength) {
              G.saveState.setAdsSinceLastNoAdsPopup(0);
              G.sb('pushWindow').dispatch(['noAdsPopup']);
            } else {
              G.saveState.setAdsSinceLastNoAdsPopup(sessionsNotSeen + 1);
            }
          }
          callback();
        };

        OMT.ads.showAd(placement, successCallback, callback);
      } else {
        console.log('** end point dont showAd');
        callback();
      }
    } else {
      console.log('** end point dont showAd');
      callback();
    }
  }

  /**
   * When the board is done. Usually when the goal is achieved, this is called
   * Or through cheating s._onBoardDeconstructed
   */
  async _onBoardDeconstructed() {
    // imports
    const configForPopUp = {
      lvlIndex: this.lvlIndex,
      points: this._lvlDataManager.points,
      passedConfig: this._config,
      ignoreStars: false,
    };

    G.saveState.incrementCWinCount();

    // Mystery Gift clean up
    if (G.saveState.mysteryGiftManager.mysteryGiftModePeak) {
      const increase = G.saveState.mysteryGiftManager.increaseStreak();
      configForPopUp.mysteryGiftStreakIncrease = increase;
    }
    G.saveState.mysteryGiftManager.markLevelFinished();

    // Level complete DDNA tracking
    if (this.lvlIndex === 0) {
      // DDNA.tracking.ftuxEvent(5, 'firstLevelComplete');
      OMT.platformTracking.logFTUEvent('FTUFirstLevelComplete');
    } else if (this.lvlIndex === 1) {
      // DDNA.tracking.ftuxEvent(9, 'secondLevelComplete');
    } else if (this.lvlIndex === 2) {
      // DDNA.tracking.ftuxEvent(11, 'thirdLevelComplete');
    } else if (this.lvlIndex === 4) {
      // DDNA.tracking.ftuxEvent(15, 'fifthLevelDone');
    } else if (this.lvlIndex === 5) {
      // DDNA.tracking.ftuxEvent(16, 'sixthLevelDone');
    }
    // track last level won by the player
    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('lastLevelWon', this.mode === LevelType.NORMAL ? this.lvlData.levelNumber : -1, true);

    // Pass scores for highscore user passing
    configForPopUp.globalScoreChange = {
      old: 0, // this should be the old star count, highscoreBeat event is disabled atm
      new: 0, // this should be the new star count, highscoreBeat event is disabled atm
    };

    // Ignore stars if the level is a special event level
    configForPopUp.ignoreStars = (this.state.mode === LevelType.COLLECT_EVENT || this.state.mode === LevelType.CHALLENGE);

    configForPopUp.extraMoves = this._movesLeftOver;

    // track last level won by the player
    // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('lastLevelWon', this.mode === 'CHALLENGE' ? -1 : G.lvlData.levelNumber, true);

    const winFunc = () => {
      if (window.flushUserData) {
        window.flushUserData();
      }
      window.GBCXPromo.gameOver();
      if (this.isBrag2Challenge) { // brag 2.0 challenge won
        this.handleBrag2Win();
      } else { // normal win case
        this.handleWin(configForPopUp);
      }
    };

    if (this.board.fortuneCookie && this.board.fortuneCookie.collected && !this.board.fortuneCookie.isSeen) {
      this.handleFortuneCookie(winFunc.bind(this));
    } else {
      winFunc();
    }
  }

  /**
   * called on window resize. center and scale board to fit.
   */
  _onBoardResize() {
    const isHorizontal = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    const topMargin = isHorizontal ? 117 : 220;
    const bottomMargin = isHorizontal ? 33 : 150;
    const sideMargin = 20;

    const maxWidth = isHorizontal
      ? Math.min(640, game.width - 400)
      : 640 - sideMargin * 2;
    const maxScale = isHorizontal ? 3 : 1;

    const pxWidth = this.board.innerWidth;
    const pxHeight = this.board.innerHeight;
    const scaleX = Math.min(maxScale, maxWidth / pxWidth);
    const scaleY = Math.min(maxScale, (game.height - topMargin - bottomMargin) / pxHeight);
    const scale = Math.min(scaleX, scaleY);
    this.board.scale.setTo(scale);
    this._gameScale = GameScaleController.getInstance().gameScale;

    if (isHorizontal) {
      // this.board.x = ((game.width - this.board.innerWidth * scale) / 2) * 0.85;
      // this.board.x = ((game.width - this.board.innerWidth) / 2);
      this.board.x = game.world.bounds.x + game.width / 2 - (this.board.width * this._gameScale) / 2 - 10;
      this.board.y = game.world.bounds.y + game.height / 2 + 50;
      // If board is not constrained vertically, move it to the absolute center of the window (ignoring margins)
      this.board.y = scale === scaleY
        ? topMargin + (game.height - topMargin - bottomMargin - (pxHeight * scale)) * 0.5
        : (game.height - (pxHeight * scale)) * 0.5;
    } else {
      this.board.x = (game.width - this.board.innerWidth * scale) / 2;
      // Ipad specific check
      const isIpad = FBInstant.deviceDectorFunctions().isIpad();
      if (isIpad) {
        this.board.x = (window.innerWidth - this.board.width) / 2;
      }
      // Galaxy Fold specific check
      const isFold = /SM-F916B/i.test(navigator.userAgent);
      if (isFold && Math.abs(window.innerWidth - window.innerHeight) < 100) {
        this.board.x = (window.innerWidth - this.board.width) / 2 + 100;
      }
      this.board.y = topMargin + (game.height - topMargin - bottomMargin - (pxHeight * scale)) * 0.5;
    }
  }

  /**
   * called when a brag 2 win / loss is resolved.
   */
  onBrag2ChallengeCompleted() {
    if (G.firstTime && G.saveState.getLastPassedLevelNr() === 0) { // first time user start FTUX flow (level 1)
      G.sb('onStateChange').dispatch('Game', {
        lvlIndex: 0,
      });
    } else { // returning user go back to world map
      const lastPassedLevel = G.saveState.getLastPassedLevelNr();
      const nextLevelIndex = this.lvlIndex + 1 > lastPassedLevel ? Math.max(lastPassedLevel - 1, 0) : this.lvlIndex;
      G.sb('onStateChange').dispatch(G.debugMode ? 'EditorWorld' : 'World', {
        lvlNr: nextLevelIndex,
        reward: 0,
        starImprovement: 0,
      });
    }
  }

  /**
   * show the window allowing new users to skip the tutorial if they beat a brag challenge
   */
  showBrag2SkipTutorialWindow() {
    G.sb('pushWindow').dispatch(['bragSkipTutorial', this.lvlIndex + 1], false, G.WindowMgr.LayerNames.OverlayLayer);
    G.sb('onAllWindowsClosed').addOnce(() => {
      this.onBrag2ChallengeCompleted();
    });
  }

  /**
   * normal win handling
   * Handles the win window. Various background flags are set while the win window is open
   * @param {Object} configForPopUp
   * @param {number} configForPopUp.lvlIndex
   * @param {number} configForPopUp.points
   * @param {boolean} configForPopUp.mysteryGiftStreakIncrease
   * @param {Object} configForPopUp.globalScoreChange
   * @param {number} configForPopUp.globalScoreChange.old
   * @param {number} configForPopUp.globalScoreChange.new
   * @param {number} configForPopUp.ignoreStars
   * @param {number} configForPopUp.extraMoves
   */
  handleWin(configForPopUp) {
    this.endPointCheckForAds(() => {
      let windowName = '';
      switch (this.state.mode) {
        case LevelType.COLLECT_EVENT: windowName = 'eventWin'; break;
        case LevelType.TREASURE_HUNT: windowName = 'treasureHuntWin'; break;
        default: windowName = 'win'; break;
      }

      this.windowMgr.pushWindow([windowName, configForPopUp]);
      if (this._perLevelData && [LevelType.COLLECT_EVENT, LevelType.TREASURE_HUNT].indexOf(this.state.mode) === -1) {
        // this.handleLeaderboards(configForPopUp.points);
      }

      // Number of levels to show until the friendship chest shows decrements
      if (OMT.feature.getFeatureFriendshipChest(false)) {
        G.saveState.friendshipChestDataManager.decrementNumberOfLevels(false);
      }
    }, INTERSTITIAL_RULES.WIN_TWICE_IN_A_ROW);
  }

  /**
   * brag 2.0 challenge won
   */
  handleBrag2Win() {
    G.sb('pushWindow').dispatch(['bragChallengeWon', this.lvlIndex + 1], false, G.WindowMgr.LayerNames.OverlayLayer);
    G.sb('onAllWindowsClosed').addOnce(() => {
      if (!G.firstTime) { // returning users
        this.onBrag2ChallengeCompleted();
      } else { // first time users will have the chance to skip the FTUX flow
        this.showBrag2SkipTutorialWindow();
      }
    });
  }

  /**
   * brag 2.0 challenge lost
   */
  handleBrag2Lost() {
    G.sb('pushWindow').dispatch(['bragChallengeLost', this.lvlIndex + 1], false, G.WindowMgr.LayerNames.OverlayLayer);
    G.sb('onAllWindowsClosed').addOnce(() => {
      this.onBrag2ChallengeCompleted();
    });
  }

  /* Shows the leaderboard when the promise resolves.
  * @param {number} points
  */
  async handleLeaderboards(points) {
    const leaderboardStateId = stateId; // store state id when async process starts
    const passLevelResp = this._perLevelData.passLevel(points);
    const lvlNr = this._config.lvlIndex + 1;
    // console.log('passLevelResp = ', passLevelResp);

    if (passLevelResp && passLevelResp.scoreImprovement) {
      const isNormalLevel = this.mode === LevelType.NORMAL;
      const isDailyChallenge = this.mode === LevelType.CHALLENGE;
      const { lvlData } = this;
      const instanceId = lvlData.id + (isDailyChallenge ? '_challenge' : '');

      // update global score, no longer in use, replaced by totalStars
      // await OMT.leaderboards.postScoreToLeaderboard('default', 'default', OMT.leaderboards.getYourTotalScore() + points);

      // update level score
      // await OMT.leaderboards.postScoreToLeaderboard('default', instanceId, points);

      // update map position, only for normal levels on the PATH
      // if (isNormalLevel) await OMT.leaderboards.postMapPositionUpdate(lvlNr);
    }

    // update stars if any earned
    if (passLevelResp && passLevelResp.starsEarned) {
      // await OMT.leaderboards.postScoreToLeaderboard('totalStars', 'default', G.saveState.getAllStars());
    }

    let isCorrectState = game.state.current === 'Game' && stateId === leaderboardStateId;
    if (!isCorrectState) return; // we want to exit if the scene changed

    // console.log('pass level data = ', passLevelResp, passLevelResp.leaderboard);
    if (passLevelResp && passLevelResp.leaderboard) {
      if (lvlNr === 1 && G.firstTime) return;

      const currentUser = passLevelResp.leaderboard
        .find((user) => user.isCurrentUser);

      // init / show the leaderboard
      // await this._initLevelLeaderboard(leaderboardStateId, passLevelResp.leaderboard);

      // show score beaten display
      isCorrectState = game.state.current === 'Game' && stateId === leaderboardStateId;
      if (!isCorrectState) return; // we want to exit if the scene changed
      let friendBeatenHeaderText;
      if (this.mode === LevelType.CHALLENGE) {
        friendBeatenHeaderText = 'Daily Challenge';
      }
      if (passLevelResp.passed && passLevelResp.passed.length) {
        G.sb('pushWindow').dispatch([
          'friendBeaten',
          this.lvlIndex,
          currentUser,
          passLevelResp.passed[0],
          passLevelResp.passed[1], // <-- It's fine if this is undefined
          { x: 20, y: -70 },
          // this._levelLeaderboard,
          friendBeatenHeaderText,
        ]);
        OMT.notifications.scheduleGameTriggeredMessage(passLevelResp.passed[0].id, 'ScoreBeaten', 1, false);
      }
    }
  }

  /**
   * intialize the level leaderboard
   * @param {number} leaderboardStateId
   * @param {Array} entriesList
   */
  async _initLevelLeaderboard(leaderboardStateId, entriesList) {
    // this._levelLeaderboard = new LevelLeaderboard();
    try {
      const windowDisplayIndex = game.world.children.indexOf(this.windowMgr.getLayer(G.WindowMgr.LayerNames.Base));
      if (windowDisplayIndex === -1) throw new Error();
      // game.world.addChildAt(this._levelLeaderboard, windowDisplayIndex + 1);
    } catch (error) {
      console.warn('could not add level leaderboard to display list');
      return;
    }
    // await OMT_TexturePreloader.getInstance().loadTexturesAsync(entriesList.map((userData) => userData.image));
    // await this._levelLeaderboard.setEntries(entriesList);
    const isCorrectState = game.state.current === 'Game' && stateId === leaderboardStateId;
    if (!isCorrectState) return; // don't execute if the scene changed
    // this._levelLeaderboard.show();

    const binding = G.sb('hideHighscoreBoard').add(() => {
      // if (this._levelLeaderboard) this._levelLeaderboard.hide();
    });
    this._signalBindings.push(binding);
  }

  /**
   * When the action queue is done
   */
  _onActionQueueEmpty() {
    // we need check if we have unsaved mission updates from the last move
    G.dailyMissionsMgr.checkUnsavedMissionProgress();

    // no moves, trigger out of moves. This was sometimes getting executed twice.
    if (this._lvlDataManager.moves === 0
      && (!this.windowMgr.checkIfWindowTypeActive('outOfMovesIAP')
        || !this.windowMgr.checkIfWindowTypeActive('tokenEventOutOfMoves'))) {
      if (this.mode === LevelType.TOURNAMENT) {
        this._handleTournamentOutOfMoves();
      } else if (!this._lvlDataManager.isGoalAchieved()) {
        this.outOfMoves();
      }
    }
  }

  /**
   * When out of moves
   */
  outOfMoves() {
    // Increase helper 2.0 tracking
    const isNotTheseLevels = [LevelType.COLLECT_EVENT, LevelType.CHALLENGE, LevelType.TREASURE_HUNT];
    if (isNotTheseLevels.indexOf(this.mode) === -1) {
      if (!G.saveState.sessionData.levelsFailedSinceUsingHelper[this.lvlIndex]) G.saveState.sessionData.levelsFailedSinceUsingHelper[this.lvlIndex] = 1;
      else G.saveState.sessionData.levelsFailedSinceUsingHelper[this.lvlIndex]++;
    }

    // Record failed level
    if (typeof G.saveState.data.failedCurrentLevel !== 'undefined'
      && this.lvlIndex === G.saveState.data.failedCurrentLevel.level) {
      G.saveState.data.failedCurrentLevel.fails++;
    } else {
      G.saveState.data.failedCurrentLevel = {
        level: this.lvlIndex,
        fails: 1,
      };
    }

    const configForPopUp = {};

    let windowName = '';
    switch (this.mode) {
      case LevelType.COLLECT_EVENT: windowName = 'tokenEventOutOfMoves'; break;
      case LevelType.TREASURE_HUNT: windowName = 'treasureHuntOutOfMoves'; break;
      default: windowName = 'outOfMovesIAP'; break;
    }

    if (this.state.mode === LevelType.TREASURE_HUNT) {
      configForPopUp.points = this._lvlDataManager.points;
      configForPopUp.tokens = G.saveState.treasureHuntManager.tempTokens;
    }

    // Show the pop up for out of moves
    if (G.IAP
      || game.incentivised()
      || G.saveState.getCoins() >= this._lvlDataManager.getPriceOfExtraMoves() * 2
      || G.saveState.isFreeMoneySpinAvailable()) {
      if (this.state.mode === LevelType.COLLECT_EVENT) {
        configForPopUp.preCloseCallback = (() => {
          if (!this.warningGingyShown && G.saveState.tokenEventManager.levelTokensCollected > 0) {
            this.warningGingyShown = true;
            this.specialEventGingy.show();
            return false;
          }
          return true;
        });
        configForPopUp.closeCallback = (() => {
          if (this.warningGingyShown) {
            this.warningGingyShown = false;
            this.specialEventGingy.hide();
          }
        });
      }

      configForPopUp.onResolveCallback = () => {
        // to indicate to the helpers that in this session, this level cant get helpers any more
        if (!G.levelsResolvedOOM) G.levelsResolvedOOM = {};
        G.levelsResolvedOOM[this.lvlIndex] = true;
      };

      // check targeted offer and display if needed
      const targetedOfferManager = TargetedOfferDataManager.getInstance();
      targetedOfferManager.showPopupOfferIfPossible([TARGETED_OFFER_IDS.NON_PAYER_OOM, TARGETED_OFFER_IDS.PAYER_OOM]);
      targetedOfferManager.incrementOOM();

      // Show OOM window, maybe after an ad
      this.endPointCheckForAds(() => {
        this.windowMgr.pushWindow([windowName, configForPopUp, (this.isBrag2Challenge) ? this.handleBrag2Lost.bind(this) : undefined]);
      }, INTERSTITIAL_RULES.OUT_OF_MOVES);
    } else if (this.isBrag2Challenge) { // brag challenge was lost
      this.handleBrag2Lost();
    } else {
      TargetedOfferDataManager.getInstance().showPopupOfferIfPossible([TARGETED_OFFER_IDS.NON_PAYER_FAIL, TARGETED_OFFER_IDS.PAYER_LAST_X_DAYS]);
      this.windowMgr.pushWindow(windowName, configForPopUp);
    }
  }

  /**
   * handle when the user runs out of moves during tournament play
   * @returns {Promise}
   */
  async _handleTournamentOutOfMoves() {
    // we dont want to trigger the default _onBoardDeconstructed or _onActionQueueEmpty
    this.board.onActionQueueEmpty.removeAll(); this.board.onBoardDeconstructed.removeAll();

    // lock game input
    game.input.enabled = false;

    // show the tournament window when the board is deconstructed
    this.board.onBoardDeconstructed.addOnce(() => {
      // short delay then show the tournament window
      game.time.events.add(100, async () => {
        await this._showTournamentWindow();
        game.input.enabled = true;
      });
    });

    // collapse specials then show the tournament result / high score display
    this.board.actionManager.startTournamentEndSequence(async () => {
      const defaultDeconstructMsg = new DeconstructMsgDefault(this._lvlDataManager, true);
      const deconstructMessages = [defaultDeconstructMsg];
      const score = this._lvlDataManager.points;
      // New highscore. Pass in the tournament highscore display to show on deconstruct.
      if (score > this._config.prevHighScore) {
        // msg to insert into the boards deconstruct
        const deconstructMsg = new DeconstructMsgTournament(this._lvlDataManager, score, this.shoutOuts, game.world.children.indexOf(this._bg) + 1);
        deconstructMessages.splice(deconstructMessages.indexOf(defaultDeconstructMsg), 1, deconstructMsg);
      }

      // let tournamentEntries = OMT.platformTournaments.lastFetchedTournamentEntries; // Get the last tournament data
      // if (tournamentEntries.length === 0) {
      //   tournamentEntries = await OMT.platformTournaments.getTournamentEntries(); // If there was none then wait for it
      // }
      // tournamentEntries = tournamentEntries.concat([]); // Create a copy
      // let yourTournamentEntry = tournamentEntries.find((entry) => entry.userId === OMT.envData.settings.user.userId); // Find yourself
      // if (!yourTournamentEntry) { // Can't find yourself? Spoof it in
      //   yourTournamentEntry = { userId: OMT.envData.settings.user.userId, image: OMT.envData.settings.user.avatar, score: this._lvlDataManager.points };
      //   tournamentEntries.push(yourTournamentEntry);
      // } else {
      //   yourTournamentEntry.score = { // New object to not mess up the tournament one
      //     userId: yourTournamentEntry.userId,
      //     image: yourTournamentEntry.image,
      //     score: this._lvlDataManager.points,
      //   };
      // }
      // tournamentEntries.sort((a, b) => b.score - a.score); // Sort
      // if (tournamentEntries.length > 0) {
      //   let friends = await OMT.friends.getFriendsList(true);
      //   friends = friends.map((friendo) => friendo.userId);
      //   friends.push(OMT.envData.settings.user.userId); // Yourself
      //   const filteredToFriends = tournamentEntries.filter((tournamentUser) => friends.indexOf(tournamentUser.userId) > -1);
      //   const yourEntry = filteredToFriends.find((tournamentFriend) => tournamentFriend.userId === OMT.envData.settings.user.userId);
      //   const topFriend = filteredToFriends[0];
      //   const miniList = [topFriend];
      //   if (topFriend === yourEntry) {
      //     const maxCount = Math.min(filteredToFriends.length, 3);
      //     for (let i = 1; i < maxCount; i++) {
      //       if (filteredToFriends[i]) {
      //         miniList.push(filteredToFriends[i]);
      //       }
      //     }
      //   } else {
      //     const yourIndex = filteredToFriends.indexOf(yourEntry);
      //     if (yourIndex - 1 === 0) { // You're right under friend
      //       miniList.push(yourEntry);
      //       if (filteredToFriends[yourIndex + 1]) {
      //         miniList.push(filteredToFriends[yourIndex + 1]);
      //       }
      //     } else { // One or more persons between you and top friend
      //       miniList.push(filteredToFriends[yourIndex - 1], yourEntry);
      //     }
      //   }
      //   deconstructMessages.push(new DeconstructMsgTournamentTaunt(this._lvlDataManager, miniList));
      // }
      this.board.deconstruct(deconstructMessages);
    });
  }

  /**
   * show the tournament window
   * @returns {Promise}
   */
  async _showTournamentWindow() {
    const leaderboardStateId = stateId; // store state id when async process starts
    // DDNA.missionTracker.onLevelFinished(); // trigger mission complete event
    const score = this._lvlDataManager.points;
    let entriesList;

    // daily mission update event
    G.sb('onTournamentFinished').dispatch();

    // post to leaderboards if context was already set
    if (OMT.platformTournaments.tournamentContextId != null) {
      await OMT.platformTournaments.switchToTournamentContext();
      await OMT.platformTournaments.postSessionScore(score);
      // await OMT.platformTournaments.postTournamentLeaderboardScore(score);
      entriesList = await OMT.platformTournaments.getTournamentEntries();
    } else { // no context was set attempt to post a score to leaderboards upon creation and show mock data
      OMT.platformTournaments.setTournamentCreatedCallback(() => {
        // OMT.platformTournaments.postTournamentLeaderboardScore(score, 1);
      });
      await OMT.platformTournaments.postSessionScore(score);
      entriesList = OMT.platformTournaments.getMockTournamentEntries(score, 1);
    }

    // Show tournament window and possibly saga map promo
    const attempts = Math.min(G.saveState.getLevelRetries('tournament'), sagaPromoChance.length);
    let showSagaPromo;
    const highestLevelIndex = G.saveState.getLastPassedLevelNr();

    if (OMT.platformTournaments.tournamentContextId != null && highestLevelIndex === 0) {
      // New user from tournament post
      showSagaPromo = Math.random() < (sagaPromoChance[attempts - 1] / 100);
    } else if (highestLevelIndex < 10) {
      // Returning user on < Level 11 from any entry point
      showSagaPromo = attempts > 1 && Math.random() < (sagaPromoChance[attempts - 1] / 100);
    } else {
      // Returning user on >= Level 11 from any entry point
      showSagaPromo = false;
    }

    // If the screen has the vertical space to fit the saga map promo, show the level leaderboard
    if (!showSagaPromo || game.height >= leaderboardHeightLimit) {
      // await this._initLevelLeaderboard(leaderboardStateId, entriesList);
    }

    // leaderboard disabled here unless FB changes something
    G.sb('pushWindow').dispatch(['tournament', this._lvlDataManager.points, false, showSagaPromo]);
  }

  /**
   * Opens the fortune cookie window.
   * The fortune cookie window will fire a specific signal which this will
   * (hopefully in all cases) catch and then continue the flow of the win/lose functionality
   * @param {Function} returnFunc
   */
  async handleFortuneCookie(returnFunc) {
    G.sb('pushWindow').dispatch(['fortuneCookie', {
      playOpenAnim: true,
      hideNotNow: false,
      uiIcon: this.board.fortuneCookie.icon,
      coinReward: true,
    }]);
    this._signalBindings.push(G.sb('fortuneCookieWindowClose').addOnce(returnFunc));

    this.board.fortuneCookie.isSeen = true;
  }

  /**
   * Sets up in-level special event UI elements
   */
  _setupInLevelEventUI() {
    const counterX = this._isLandscape ? Math.floor(game.width / 2 - 50) : Math.floor(game.config.width) / 2;
    this.eventTokenCounter = new EventTokenCounter(counterX);
  }

  /**
   * Sets up post-level special event level UI elements
   */
  _setupPostLevelEventUI() {
    this.specialEventGingy = new EventGingyFail(95);
    game.world.add(this.specialEventGingy);
  }

  /**
   * When the chest is broken and the gift comes out
   * @param {any} chest
   * @param {any} giftData
   */
  onChestGiftShown(chest, giftData) {
    // Chest is a treasure hunt chest
    const coins = giftData[0] === 'coin' ? parseInt(giftData[1]) : 0;
    G.gift.applyGift(giftData);
    if (coins > 0) {
      // DDNA.transactionHelper.queueLevelCoinReward(coins, 'GameplayChest');
      G.sb('coinsFromGameplayChest').dispatch(giftData[1]);
    }
  }

  /**
   * Create in game villains for the win and lose animations
   */
  _initVillains() {
    const isDailyChallenge = this.mode === LevelType.CHALLENGE;
    if (OMT_SystemInfo.getInstance().orientation === ORIENTATION.vertical) {
      this.villains = new VillainsInGame(this.topBar, isDailyChallenge);
    } else {
      this.villains = new VillainsInGameLandscape(this.topBar, isDailyChallenge);
    }
  }

  /**
   * Locks the input on the settings menu and booster panel
   */
  lockInput() {
    this.settingsMenu.lockInput();
    this.boosterPanel.lockInput();
  }

  /**
   * Unlocks the input on the setting menu and booster panel
   */
  unlockInput() {
    this.settingsMenu.unlockInput();
    this.boosterPanel.unlockInput();
  }

  /**
   * @returns {TreasureHuntCounter}
   */
  get treasureHuntCounter() {
    return this._treasureHuntCounter;
  }
}

G.Game = Game;
