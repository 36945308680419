export default class FullScreenDragMenu {
  /**
   * @param {Object} config
   * @param {Phaser.Group} [config.centerButton]
   * @param {number} [config.minDragThreshold]
   * @param {number} [config.maxDragThreshold]
   */
  constructor(config = {}) {
    if (!config.centerButton) {
      config.centerButton = this.makeHitboxButton();
    }

    this._config = config;
    this.centerButton = config.centerButton;
    this._dragDetails = undefined;
    this.lock = false;

    this.signals = {
      leftTransition: new Phaser.Signal(),
      rightTransition: new Phaser.Signal(),
      centerDeltaChange: new Phaser.Signal(),
      onNoChange: new Phaser.Signal(),
    };

    this._setupInteractions();
  }

  makeHitboxButton() {
    const overlay = G.makeImage(0, 0, null, 0, null);
    overlay.inputEnabled = true;
    overlay.input.useHandCursor = true;
    overlay.input.enableDrag(false);
    overlay.input.setDragLock(true, false);
    overlay.hitArea = new Phaser.Rectangle(0, 0, 0, 0);
    return overlay;
  }

  _setupInteractions() {
    this._resetDragDetails();
    this.centerButton.events.onDragStart.add(this._onCenterDragStart.bind(this));
    this.centerButton.events.onDragUpdate.add(this._onCenterDrag.bind(this));
    this.centerButton.events.onDragStop.add(this._onCenterDragEnd.bind(this));
  }

  _resetDragDetails() {
    if (this._dragDetails) {
      this._dragDetails.center.start.x = 0;
      this._dragDetails.center.start.y = 0;
      this._dragDetails.center.delta.x = 0;
      this._dragDetails.center.delta.y = 0;
    } else {
      this._dragDetails = {
        center: {
          world: { x: 0, y: 0 },
          start: { x: 0, y: 0 },
          delta: { x: 0, y: 0 },
        },
        threshold: {
          min: this._config.minDragThreshold || 20,
          max: this._config.maxDragThreshold || 200,
        },
      };
    }
  }

  _onCenterDragStart(obj, pointer, sX) {
    if (this.lock) { return; }
    this._dragDetails.center.delta.x = this._dragDetails.center.start.x = sX;
    this._dragDetails.center.world.x = pointer.x - game.width / 2;
  }

  _onCenterDrag(obj, pointer, newX, newY, snapPoint, fromStart) {
    if (this.lock) { return; }
    const oldD = this._dragDetails.center.delta.x;
    this._dragDetails.center.delta.x = newX;
    if (!fromStart) {
      this.signals.centerDeltaChange.dispatch(this._dragDetails.center.delta.x - oldD, this._dragDetails.center.delta.x - this._dragDetails.center.start.x);
    }
  }

  _onCenterDragEnd() {
    if (this.lock) { return; }
    const delta = (this._dragDetails.center.delta.x - this._dragDetails.center.start.x);
    if (this._dragDetails.center.world.x < 0 && ((delta >= this._dragDetails.threshold.max || delta <= this._dragDetails.threshold.min) && delta > -this._dragDetails.threshold.min)) {
      this.signals.leftTransition.dispatch();
    } else if (this._dragDetails.center.world.x > 0 && ((delta <= -this._dragDetails.threshold.max || delta >= -this._dragDetails.threshold.min) && delta < this._dragDetails.threshold.min)) {
      this.signals.rightTransition.dispatch();
    } else {
      this.signals.onNoChange.dispatch();
    }
    this._resetDragDetails();
  }
}
