/* eslint-disable no-use-before-define */
/* eslint-disable padded-blocks */
import WorldMap2 from '../Elements/WorldMap2/WorldMap2';
import StartBoosterConfig from '../Elements/StartBoosterConfig';
import { UI_DailyChallengeIcon } from '../Elements/UI_DailyChallengeIcon';
import { UI_TournamentIcon } from '../Elements/UI_TournamentIcon';
// import { UI_FriendshipChest } from '../Elements/Windows/FriendshipChest/UI_FriendshipChest';
// import { Friendship_Chest } from '../Elements/Windows/FriendshipChest/Friendship_Chest';
// import OMT_UI_CrossPromoMapButton from '../OMT_UI/Buttons/OMT_UI_CrossPromoMapButton';
import { UI_EventPostcard } from '../Elements/Windows/eventPostcard/UI_EventPostcard';
import { FadeLayer } from '../Elements/FadeLayer';
import FxMapLayer from '../Elements/WorldMap2/Layers/FxMapLayer';
import UIFxLayer from '@omt-game-board/Elements/GameState/UI/Layers/UIFxLayer';
import OMT_BgLoadingUtil from '../Utils/OMT_BgLoadingUtil';
import { WindowManager } from '../Elements/WindowMgr';
// import { MainLeaderboard } from '../Leaderboards/Main/MainLeaderboard';
// import { LevelLeaderboard } from '../Leaderboards/Level/LevelLeaderboard';
// import { OMT_TexturePreloader } from '../Utils/OMT_TexturePreloader';
// import { XPROMO_PLACEMENTS } from '../Services/OMT/OMT_CrossPromo';
import TargetedOfferDataManager, {
  ENTRY_POINTS as TARGETED_OFFER_ENTRY_POINTS,
  TARGETED_OFFER_IDS,
} from '../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import UI_ShopIcon from '../Elements/Windows/Shop3/UI_ShopIcon';
import ShopUtils from '../Elements/Windows/Shop/Shop_Utils';
import WorldMap2_postLevelFx from '../Elements/WorldMap2/WorldMap2_postLevelFx';
import TokenEventUIIcon from '../Elements/Windows/tokenEvent/TokenEventMapUIIcon';
import WorldMap2_Util from '../Elements/WorldMap2/WorldMap2_Util';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import OMT_VILLAINS from '../OMT_UI/OMT_Villains';
import OMT_StackManager from '../Utils/OMT_StackManager';
import MapGift from '../Elements/G.MapGift';
import { OMT_SystemInfo, ORIENTATION } from '../Services/OMT/OMT_SystemInfo';
import UI_MapPanel from '../Elements/UI_MapPanel';
import UI_EventBlocker from '@omt-components/UI/EventBlocker/UI_EventBlocker';
import { GameScaleController } from './Scaling/GameScaleController';
import TreasureHuntIcon from '../Elements/TreasureHunt/WorldMap/TreasureHuntIcon';
import { INTERSTITIAL_RULES } from '../Services/OMT/ads/OMT_InterstitialAdRules';
import { SagaMap_Tutorial } from '../Elements/GingyTutorial/SagaMap_Tutorial';
import { CHARACTER_KEYS } from '../Elements/GingyTutorial/G.GingyCharacterTutorial';
import { UI_TimedTargetedOffer } from '../Elements/UI_TimedTargetedOffer';
import { OMT_AssetLoader } from '../Services/OMT/OMT_AssetLoader';
import UI_AnnuityReminderIcon from '../Elements/Windows/Shop3/UI_AnnuityReminderIcon';

/**
 * Refactor of World.js the first.
 * This is the second
 */
export class World2 {
  /**
   * Phaser state init
   */
  init(lastLevelData, onCreateCallback) {
    OMT_VILLAINS.resetLevelType();
    this.mode = G.gameMode = LevelType.NONE;
    this._isLandscape = OMT_SystemInfo.getInstance().orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;
    this._assetLoader = OMT_AssetLoader.getInstance();
    this._targetedOfferDataManager = TargetedOfferDataManager.getInstance();
    G.dailyMissionsMgr.dayCheck();

    this.lastLevelData = lastLevelData;
    this._onCreateCallback = onCreateCallback;
    this._promoActive = false;

    this.startBoosterConfig = new StartBoosterConfig();

    OMT.feature.checkFeatureRequestMoveHelp();

    if (!G.saveState.sessionData.worldMapSessionCount) { G.saveState.sessionData.worldMapSessionCount = 0; }
    G.saveState.sessionData.worldMapSessionCount++;
    this._sessionId = G.saveState.sessionData.worldMapSessionCount;

    // update level numbers for Sentry
    // G.Utils.SentryLog.setCurrentLevel(-999);
    // G.Utils.SentryLog.setHighestLevel();

    this._canCheckMysteryGiftTimer = false;
    this._mysteryGiftWindowDispatched = false;
    this._villainsTutorialWillPopup = this._willVillainsTutorialPopup();
    this._signalBindings = [];

    if (G.IAP) OMT.payments.checkUnconsumedProducts(); // Check payment
  }

  /**
   * Phaser state create
   */
  create() {
    game.stage.backgroundColor = WorldMap2_Util.getMapBackgroundColour();
    game.resizeGame();

    this._orientationIsVertical = OMT.systemInfo.orientation === ORIENTATION.vertical;

    this.windowMgr = new WindowManager(0, 0); // Aka G.windowMgr
    this.windowMgr.offsetV = G.WindowMgr.Constants.WorldVerticalOffset;
    this.windowMgr.resize();

    // need to bypass world startup in restore progress
    // Can this be moved elsewhere?
    if (OMT.envData.isRestoreProgressPayload && !G.saveState.progressWasRestored) {
      G.fadeLayer = new FadeLayer(); // Bypass fade layer
      this._initFlow();
      return;
    }

    const extravagantLevelSkippingAnimation = Number.isFinite(G.saveState.sessionData.crossPromoSkipTutorial);

    if (G.saveState.badgeManager && !extravagantLevelSkippingAnimation) {
      G.saveState.badgeManager.checkBadgeExpiry();
    }
    if (!extravagantLevelSkippingAnimation) { this._gateCheckWhenTheresNoGates(); }

    const mapScrollerConfig = {};
    if (extravagantLevelSkippingAnimation) {
      mapScrollerConfig.animateSkipTo = {
        level: G.saveState.sessionData.crossPromoSkipTutorial,
        fxFunc: this._createPostLevelBatches.bind(this),
        tweenTime: 3100,
      };
    }
    this._mapScroller = new WorldMap2(mapScrollerConfig);
    if (!extravagantLevelSkippingAnimation) {
      this._mapScroller.signals.onGateOpen.add(this._openGate.bind(this));
      this._mapScroller.signals.onComingSoonClicked.add(this._onComingSoonClicked.bind(this));
    } else {
      this._mapScroller.signals.mapIsReady.add(this._animateLevelSkipping.bind(this));
    }
    game.world.add(this._mapScroller);

    if (!extravagantLevelSkippingAnimation) {
      this._sideBarIconLayer = new Phaser.Group(game);
      this._createTimedTargetedOfferButton();
      this._organizeWorldSideButtons();

      // init the main leaderboard instance
      this._initMainLeaderboard();
    }

    this.windowMgr.addLayerToWorld(G.WindowMgr.LayerNames.BelowHeaderPanel);
    this.panel = new UI_MapPanel(this._orientationIsVertical);
    this.windowMgr.addLayerToWorld(G.WindowMgr.LayerNames.Base);
    this.uiTargetParticlesBW = new G.UITargetParticles();

    this._fxMapLayer = new FxMapLayer();

    if (!extravagantLevelSkippingAnimation) {
      // this._initLevelLeaderboard();
      this.worldSidePanel = new G.WorldSidePanel();
    }

    this.uiFxLayer = new UIFxLayer();
    this.uiTargetParticles = new G.UITargetParticles();

    G.fadeLayer = new FadeLayer(); // Normal fade layer

    if (extravagantLevelSkippingAnimation) {
      return;
    }

    if (G.IAP) OMT.payments.checkUnconsumedProducts(); // Check payment

    this._initFlow();

    // set listeners and load / reload crosspromo data
    // this._loadCrossPromoData();

    OMT_BgLoadingUtil.startBackgroundAssetLoading();

    this._sendClosedHelpRequest(); // set automated message on close if in friend context

    this._signalBindings.push(G.sb('showTimedTargetedOfferButton').add((delay) => this._showTimedTargetedOfferButton(delay)));
    this._signalBindings.push(G.sb('hideTimedTargetedOfferButton').add((delay) => this._hideTimedTargetedOfferButton(delay)));
    this._signalBindings.push(G.sb('onWallClockTimeUpdate').add(this._onTick, this)); // run _onTick every second

    // update this._canCheckMysteryGiftTimer from outside this class
    this._signalBindings.push(G.sb('mysteryGiftWindowDispatched').add((state) => {
      this._canCheckMysteryGiftTimer = state;
    }, this));

    this._signalBindings.push(G.sb('xPromoTimeout').add(() => {
      this._repositionSideButtons();
    }));

    this._signalBindings.push(G.sb('onWindowPushed').add(() => {
      this._mapScroller.inputEnabled = false;
      G.sb('pauseAllUpdate').dispatch();
    }));

    this._signalBindings.push(G.sb('onAllWindowsClosed').add(() => {
      this._mapScroller.inputEnabled = true;
      G.sb('resumeAllUpdate').dispatch();
    }));

    this._signalBindings.push(G.sb('mapChestWindowClosed').add(() => {
      this.checkForAds(() => {}, INTERSTITIAL_RULES.GIFT_GAIN);
    }));
  }

  /**
   * Creates the gingy tutorial.
   * Also creates a giant hitbox
   */
  _createGingyTutorial() {
    this._gingyTutorial = new SagaMap_Tutorial({ game, scale: 1, character: CHARACTER_KEYS.gingy });
    this._gingyTutorial.toggleVisibility(false);

    this._gingyTutorialHitbox = G.makeImage(0, 0, null, 0, game.world);
    this._gingyTutorialHitbox.inputEnabled = true;
    this._gingyTutorialHitbox.input.useHandCursor = true;
    this._gingyTutorialHitbox.hitArea = new Phaser.Rectangle(-2000, -2000, 4000, 4000);
  }

  /**
   * Animates the level skipping, cleans it, then reloads the world
   */
  _animateLevelSkipping() {
    const tutorialData = G.json.tutorials.crossPromoLevelSkip;
    const config = {
      onStart: () => {
        // this._mapScroller.forceAddPlayerAvatar(0);
      },
      onComplete: async () => {
        // Animate and get the number of coins
        const totalCoin = await this._mapScroller.sequentiallyUnlockAndScrollToLevel(G.saveState.sessionData.crossPromoSkipTutorial);
        G.saveState.restoreProgress(G.saveState.sessionData.crossPromoSkipTutorial, totalCoin, 2);

        delete G.saveState.sessionData.crossPromoSkipTutorial; // Delete this and restart the world
        G.sb('onStateChange').dispatch('World');
      },
      msg: [
        tutorialData.step1,
        tutorialData.step2,
      ],
    };
    G.sb('pushWindow').dispatch(['crossPromoLanding', config]);
  }

  /**
   * Phaser state update
   */
  update() {
    G.delta();
    // OMT.performanceMonitor.update();
  }

  /**
   * Phaser state shutdown event
   */
  shutdown() {
    // OMT.crossPromo.signals.onGetXPromo.removeAll();
    // G.sb('crossPromoSeen').removeAll();
    G.sb('onWallClockTimeUpdate').remove(this._onTick, this);
    G.sb('showTimedTargetedOfferButton').removeAll();
    G.sb('hideTimedTargetedOfferButton').removeAll();
    G.sb('mysteryGiftWindowDispatched').removeAll();
    for (const signal of this._signalBindings) {
      if (signal && signal.detach) {
        signal.detach();
      }
    }
    this._signalBindings.length = 0;
    const { flowMgr } = G.WorldMapAppearFlow;
    flowMgr.cleanup();
  }

  /**
   * Phaser Render
   */
  render() {
    // eslint-disable-next-line prefer-rest-params
    Phaser.State.prototype.render.apply(this, arguments);
    return;
    // eslint-disable-next-line no-unreachable
    game.debug.text(this.map.y - game.height, 10, 50, '#ff0000');
    game.debug.text(game.load.isLoading, 10, 30);
    game.debug.text(game.load.progressFloat, 10, 60);
  }

  /**
   * Init DDNA tracking things
   */
  _initLevelDDNATracking() {
    // moved body added background styles to stylesheet.css
    document.body.classList.add('initialized');

    OMT.platformTracking.logFTUEvent('FTUSagaMapVisible', 'RTUSagaMapVisible');
    OMT.platformTracking.logFTUEvent('FTUX_SagaMapVis', null);

    // send any previous incomplete level rewards
    // DDNA.transactionHelper.sendQueuedLevelCoinRewards();
  }

  /**
   * Returns true if the current scene is the world map that you should be seeing
   * @returns {Boolean}
   */
  _isCorrectScene() {
    if (this.state && this.state.current) {
      return game.state.current === this.state.current && this._sessionId === G.saveState.sessionData.worldMapSessionCount;
    }
    return false;
  }

  /**
   * Added button to open current timed targeted offer
   */
  async _createTimedTargetedOfferButton() {
    if (!G.IAP) return; // Don't create button if not in IAP environment

    // Get current targeted offer data
    let currentOffer;
    // Get time left for current offer
    const timeLeft = this._targetedOfferDataManager.getCurrentTargetedOfferTimeRemaining();

    if (timeLeft <= 0) {
      // Current offer does not exist or has expired
      currentOffer = this._targetedOfferDataManager.dequeueTargetedOffer();
      if (!currentOffer) return; // no offers available
    } else {
      // Current offer is still good
      currentOffer = this._targetedOfferDataManager.getCurrentTargetedOffer();
    }

    const expiryTime = currentOffer.et;
    const result = await this._assetLoader.waitOnSecondaryImages(['shop/timed-iap'], 2000);

    if (result) {
      this._timedTargetedOfferButton = new UI_TimedTargetedOffer(expiryTime);
      G.sb('showTimedTargetedOfferButton').dispatch(1000);

      if (this._isCorrectScene()) {
        const sideLayerIndex = game.world.getChildIndex(this._sideBarIconLayer);
        game.world.addChildAt(this._timedTargetedOfferButton, sideLayerIndex);
      } else if (this._timedTargetedOfferButton.destroy) {
        this._timedTargetedOfferButton.destroy();
      }
    }
  }

  /**
   * Shows timed targeted offer button after a delay
   * @param {number} delay
   */
  _showTimedTargetedOfferButton(delay = 1000) {
    if (!this._timedTargetedOfferButton) {
      this._createTimedTargetedOfferButton();
      return;
    }
    setTimeout(() => this._timedTargetedOfferButton.show(), delay);
  }

  /**
   * Hides timed targeted offer button after a delay
   * @param {number} delay
   */
  _hideTimedTargetedOfferButton(delay = 1) {
    if (!this._timedTargetedOfferButton) return;
    setTimeout(() => this._timedTargetedOfferButton.hide(), delay);
  }

  /**
   * Organizes the side buttons on the side of the world state.
   * Positions them along the sides too
   */
  _organizeWorldSideButtons() {
    // Check async event flags here
    /**
     * This is the data object that holds the info about the icons on the world map
     * It sorts it by left and right.
     * The largestWidth is to calculate the middle alignment of all elements in that side
     * so it doesn't look out of place.
     */
    this._UI_button = {
      left: {
        elements: [],
        largestWidth: 0,
      },
      right: {
        elements: [],
        largestWidth: 0,
      },
    };
    const buttonOrder = G.OMTsettings.elements.worldMapButtonOrder[OMT.systemInfo.orientationKey];
    if (buttonOrder) { // The button order is different per game
      const buttonSwitch = (target, isRight) => { // Function that adds the elements into this._UI_button
        switch (target.toLowerCase()) { // only checks lowercase
          case 'mapgiftannuityreminder':
            this._createMapGiftAnnuityReminderUIButton(isRight);
            break;
          case 'mapgift':
            this._createMapGiftUIButton(isRight);
            break;
          case 'dailychallenge':
            this._createDailyChallengeUIButton(isRight);
            break;
          case 'tournament':
            this._createTournamentUIButton(isRight);
            break;
          case 'dailyicon':
            if (G.IAP) this._createDailySpinUIButton(isRight);
            break;
          // case 'friendshipchest':
          //   this._createFriendshipChestUIButton(isRight);
          //   break;
          // case 'xpromo':
          //   this._createXpromoUIButton(isRight);
          //   break;
          case 'eventpostcard':
            this._createEventPostcardUIButton(isRight);
            break;
          case 'specialshopdeal':
            this._createSpecialShopDealUIButton(isRight);
            break;
          case 'tokenevent':
            this._createTokenEventButton(isRight);
            break;
          case 'treasurehunt':
            this._createTreasureHuntButton(isRight);
            break;
          case 'annuityreminder':
            this._createAnnuityReminder(isRight);
            break;
          default: break;
        }
      };
      buttonOrder.left.forEach((leftCheek) => buttonSwitch(leftCheek, false));
      buttonOrder.right.forEach((rightCheek) => buttonSwitch(rightCheek, true));

      G.Utils.cleanUpOnDestroy(this._mapScroller, G.sb('onScreenResize').add(this._repositionSideButtons.bind(this)));
      this._repositionSideButtons();
    }
  }

  /**
   * Creates a button pack that will be used by the button positioner.
   * Pushes it into the correct array depending on where it shows up
   * @param {boolean} isRight
   * @param {Phaser.DisplayObject} button
   * @param {number} givenWidth
   * @param {number} givenHeight
   */
  _addUIButtonToSide(isRight, button, data) {
    // Wrap button in a group so it can be scaled independently of any scale tweens applied to the button
    const wrappedButton = new Phaser.Group(game);
    wrappedButton.addChild(button);
    this._sideBarIconLayer.addChild(wrappedButton);

    const pack = {
      icon: button,
      button: wrappedButton,
      width: data.width || 100,
      height: data.height || 100,
      offsetY: data.offsetY || 0,
    };

    // Scale down on landscape
    if (this._isLandscape) {
      wrappedButton.scale.setTo(this._gameScale);
      pack.height *= this._gameScale;
      pack.offsetY *= this._gameScale;
    }

    let UI_pack;
    if (isRight) {
      UI_pack = this._UI_button.right;
    } else {
      UI_pack = this._UI_button.left;
    }

    UI_pack.elements.push(pack);
    if (UI_pack.largestWidth < pack.width) {
      UI_pack.largestWidth = pack.width;
    }
  }

  /**
   * Repositions the UI buttons on the side of the map
   */
  _repositionSideButtons() {
    for (const uiKey in this._UI_button) { /* eslint-disable-line guard-for-in */ // For left and right
      const UI_data = this._UI_button[uiKey];
      const isRight = uiKey === 'right';
      const oriY = isRight ? 95 : 300;
      let nextY = oriY;
      let xMod = 0;
      for (const pack of UI_data.elements) {
        if (G.OMTsettings.elements.worldMapButtonOrder[OMT.systemInfo.orientationKey].alignXDynamically) { // If its not aligning X dynamically, the elements are using X from their own resize call
          if (!isRight) {
            pack.button.x = game.world.bounds.x + xMod + Math.round(UI_data.largestWidth / 2);
          } else {
            pack.button.x = game.world.bounds.x + xMod + game.width - Math.round(UI_data.largestWidth / 2);
          }
        }
        let targetY = nextY + pack.offsetY + Math.round(pack.height / 4);
        if (targetY > game.world.bounds.height) {
          xMod += (UI_data.largestWidth / 1.3) * (isRight ? -1 : 1);
          pack.button.x += xMod;
          nextY = oriY;
          targetY = nextY + pack.offsetY + Math.round(pack.height / 4);
        }
        pack.button.y = targetY;
        nextY += pack.height * 1.25;
      }
    }
  }

  /**
   * Loads cross promo data
   */
  _loadCrossPromoData() {
    if (!OMT.crossPromo.loadFinished) {
      // OMT.crossPromo.signals.onGetXPromo.removeAll();
      OMT.crossPromo.signals.onGetXPromo.addOnce(this._onXPromosReady.bind(this));
      OMT.crossPromo.loadCrossPromoData();
    } else {
      this._onXPromosReady();
    }
  }

  /**
   * The flow that happens after in create.
   * Flow manager checks payload and pushes windows. Once that's all done, it continues
   */
  async _initFlow() {
    const { flowMgr } = G.WorldMapAppearFlow;

    flowMgr.onPopUpRequested.add(function requested() {
      this.windowMgr.pushWindow.apply(this.windowMgr, arguments); // eslint-disable-line prefer-spread, prefer-rest-params
    }, this);
    await flowMgr.startFlow(this.lastLevelData);
    if (OMT.envData.isRestoreProgressPayload) return; // dont continue if this is a restore progress flow

    await this._check_villainsTutorial();
    // this._check_GBMessages();
    // this._check_friendshipChest();
    await this._checkIngamePromos();
    await this._checkHomescreenShortcut();

    if (this.lastLevelData) {
      // we want to set the coins counter to what it would have been before level reward for the batch animation
      G.sb('onCoinsChange').dispatch(G.saveState.getCoins() - this.lastLevelData.reward);

      if (OMT.feature.getUseNewFTUX()) { // Giant check to see if you just came out of the last level and the wheel just unlocked
        if (`${G.saveState.getLastPassedLevelNr()}` === `${G.featureUnlock.unlockLevels.dailyWheel}`
          && G.saveState.data.freeSpin && (this.lastLevelData.lvlNr + 1) === G.featureUnlock.unlockLevels.dailyWheel) {
          this.dailySpinIcon.onClick({ showCloseButton: false });
        }
      }
      await this.windowMgr.onQueueEmpty();
      await this._postLevelFlow(this.lastLevelData);
      // if (this._mainLeaderboard && this.windowMgr.isQueueEmpty) {
      //   this._mainLeaderboard.show();
      // }
    } else {
      this._canCheckMysteryGiftTimer = true;
    }

    if (this._onCreateCallback) {
      this._onCreateCallback();
    }

    if (G.IAP && !G.saveState.sessionData.worldMapSeen) { // world map not seen show the targeted offer if conditions met
      if (!this._villainsTutorialWillPopup && !this._promoActive) {
        // Immediately show payload targeted offers marked with im = true if possible
        const targetedOfferDataManager = TargetedOfferDataManager.getInstance();

        if (targetedOfferDataManager.getCurrentTargetedOfferTimeRemaining() >= 0) {
          const currentOffer = targetedOfferDataManager.getCurrentTargetedOffer();
          if (currentOffer && currentOffer.im) {
            currentOffer.im = false; // set im to false so it doesn't immediately popup again
            targetedOfferDataManager.showTimedPopupOfferWindow(currentOffer);
          }
        }

        targetedOfferDataManager.showPopupOfferIfPossible(TARGETED_OFFER_IDS.PAYER_LAST_X_DAYS, TARGETED_OFFER_ENTRY_POINTS.GAME_STARTED);
      }
    }
    G.saveState.sessionData.worldMapSeen = true;

    // Check milestones
    OMT.milestoneTracking.checkMilestoneCompletion();
  }

  /**
   * Triggers the post level flow when coming out of a level and getting back to the map
   * @param {{lvlNr:number, starImprovement:number, reward:number, challenge:boolean}} lastLevelData
   */
  async _postLevelFlow(lastLevelData) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (!lastLevelData.challenge) {
        if (lastLevelData
          && (lastLevelData.starImprovement > 0 || lastLevelData.reward > 0)) {
          this._mapScroller.inputEnabled = false;

          // set amount to count up from and update the diplsay
          const coinCounter = { coins: lastLevelData.reward > 0 ? G.saveState.getCoins() - lastLevelData.reward : 0 };
          G.sb('onCoinsChange').dispatch(coinCounter.coins);

          // OMT.transactionTracking.logInventoryTransactionBegin();
          // OMT.transactionTracking.addInventoryChange('coins', 0, lastLevelData.reward);
          // OMT.transactionTracking.logInventoryTransactionEnd();

          // Send bot messages
          const levelPassedUser = this._getLevelPassedPlayer();
          if (levelPassedUser) {
            OMT.notifications.scheduleGameTriggeredMessage(levelPassedUser.id, 'PlayerPassed', 1, false);
          }

          // Check milestones
          OMT.milestoneTracking.checkMilestoneCompletion();

          // Reward stars and coins
          game.time.events.add(500, () => {
            this._createPostLevelBatches({
              lastLevelData,
              coinCounter,
              levelPosition: this._mapScroller.getWorldPositionOfLevelNodeAt(lastLevelData.lvlNr),
            }).then(((async () => {
              await this._afterBatch();
              resolve();
            })));
          });
        } else {
          // If no stars or coins were rewarded, resolve immediately
          resolve();
        }
      } else {
        // If the previous level was a challenge level
        // Wait a bit before resolving promise
        game.time.events.add(1000, () => resolve());
      }
    });
  }

  /**
   * Creates a batch of fx
   * @param {{}} config
   * @param {{starImprovement:number, reward:number}} config.lastLevelData
   * @param {{coins:number}} config.coinCounter
   * @param {{x:number, y:number}} config.levelPosition
   * @param {number} config.finalCoins
   * @returns {Promise}
   */
  _createPostLevelBatches(config) {
    return WorldMap2_postLevelFx.startBatches({
      lastLevelData: config.lastLevelData,
      coinCounter: config.coinCounter,
      levelPosition: config.levelPosition,
      finalCoins: config.finalCoins,
      uiFx: this.uiTargetParticlesBW,
      panel: this.panel,
    });
  }

  /**
   * State checks when particle effects end after the post level flow
   */
  async _afterBatch() {
    const { levelDataMgr } = G.Helpers;

    const numberOfLevels = levelDataMgr.getNumberOfLevels();

    // Chest scrolling was here but was taken out
    this._mapScroller.animatePlayerAvatarIncrease();
    this._mapScroller.inputEnabled = true;

    if (G.saveState.getLastPassedLevelNr() === this._mapScroller.maxMapLevel) return;

    const lvlIndex = game.math.clamp(G.saveState.getLastPassedLevelNr(), 0, numberOfLevels - 1);
    const lvlData = G.Helpers.levelDataMgr.getLevelByIndex(lvlIndex);

    // TODO: get rid of those globals
    G.lvlNr = lvlIndex;
    G.lvlData = lvlData;

    // TODO: make these progress events work again
    // these get overwritten by the next level window as well?
    await this.checkLevelProgress();

    if (this._isCorrectScene()) {
      this._checkMysteryGiftTimer();
      if (lvlData) {
        G.sb('pushWindow').dispatch(['level', lvlIndex], false, G.WindowMgr.LayerNames.AboveHighScorePanel);
      }
    }
    this._canCheckMysteryGiftTimer = true;
  }

  /**
   * Checks GB messages
   */
  async _check_GBMessages() {
    // await OMT.messaging.loadMessages();
  }

  /**
   * Checks the state of the friendship chest after GB messages are checked
   */
  async _check_friendshipChest() {
    if (this._villainsTutorialWillPopup) return;
    if (!OMT.feature.getFeatureFriendshipChest(false)) { return; }
    /**
     * If the chest is unlocked at level and activated.
     * It'll takes appearance-amount of levels to open the promo
     * If the user is new, it should open as soon as they unlock
     */

    if (OMT.feature.getFeatureFriendshipChestActiveByLevel(false)) {
      if (G.saveState.friendshipChestDataManager.getInvitedData() && !G.saveState.friendshipChestDataManager.getPromoInitial()) {
        G.sb('pushWindow').dispatch('friendshipChestFTUAward');
        return;
      }
      if (OMT.feature.getFeatureFriendshipChest()) { // Check again but check iOS
        if (G.saveState.friendshipChestDataManager.getNumberOfLevelsToAppear() <= 0) { // eslint-disable-line no-else-return
          const assetStatus = OMT.feature.getFriendshipChestAssetStatus();
          if (G.saveState.friendshipChestDataManager.getCardCount() === 1) {
            if (assetStatus.highPrio) {
              G.sb('pushWindow').dispatch('friendshipChestPromo');
            } else {
              G.saveState.friendshipChestDataManager.setNumberOfLevelsToAppear(1);
            }
          } else if (assetStatus.complete) {
            G.sb('pushWindow').dispatch('friendshipChestPromo');
          } else {
            G.saveState.friendshipChestDataManager.setNumberOfLevelsToAppear(1);
          }
        }
      }
      G.sb('FriendshipChestCheck').dispatch();
    }
  }

  /**
   * Check a lot of in game promos
   */
  async _checkIngamePromos() {
    await this._check_tournamentPromo();
    await this._check_dailyChallengePromo();
    await this._check_treasureHuntPromo();
  }

  /**
   * Checks if the tournament promo can be displayed
   */
  async _check_tournamentPromo() {
    return new Promise((resolve) => {
      if (this._promoActive || this._villainsTutorialWillPopup) {
        resolve();
      } else if (
        OMT.feature.isTournamentPromoEnabled()
        && OMT.platformTournaments.getCooldownTimeRemaining() <= 0
        && G.saveState.getLastPassedLevelNr() >= G.featureUnlock.unlockLevels.tournamentPromo
        && !G.saveState.getTournamentPromoSeen()
        && G.saveState.getTournamentLastPlayed() == null
        && G.saveState.sessionData.worldMapSeen
      ) {
        if (this._tournamentIcon) {
          this._promoActive = true;
          game.input.enabled = false;
          game.time.events.add(1000, () => {
            G.sb('pushWindow').dispatch(['tournamentPromotion', { icon: this._tournamentIcon }]);
            game.input.enabled = true;
            resolve();
          });
        } else {
          console.log('not finding tournament icon, not displaying tourney promo');
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  /**
   * Checks if the daily challenge promo can be displayed
   */
  async _check_dailyChallengePromo() {
    return new Promise((resolve) => {
      if (this._promoActive || this._villainsTutorialWillPopup) {
        resolve();
      } else if (
        OMT.feature.isDailyChallengeEnabled(OMT.feature.isVillainsEnabled())
        && OMT.feature.isDailyChallengePromoEnabled()
        && G.saveState.isChallengeAvailable()
        && G.saveState.getLastPassedLevelNr() >= G.featureUnlock.unlockLevels.dailyChallenge
        && !G.saveState.getDailyChallengePromoSeen()
        && G.saveState.villainsDataManager.getTutorialStatus('super_hard', 'post_win_saga_map')
      ) {
        if (this._dailyChallengeIcon
            && (this._dailyChallengeIcon.visible || this._dailyChallengeIcon.icon.adIcon.visible)) {
          this._promoActive = true;
          game.input.enabled = false;
          game.time.events.add(1000, () => {
            G.sb('pushWindow').dispatch(['dailyChallengePromotion', { icon: this._dailyChallengeIcon }]);
            game.input.enabled = true;
            resolve();
          });
        } else {
          console.log('not finding daily challenge icon, not displaying daily challenge promo');
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  /**
   * Goes through lots of checks to see if the treasure hunt promotion will appear
   * @returns {Promise}
   */
  _check_treasureHuntPromo() {
    return new Promise((resolve) => {
      if (this._promoActive || this._villainsTutorialWillPopup) {
        resolve();
      } else if (
        OMT.feature.isTreasureHuntOn(true, true, false, true)
        && G.saveState.treasureHuntManager.inActiveCycle
        && !G.saveState.treasureHuntManager.promoSeen
      ) {
        if (this._treasureHuntIcon && this._treasureHuntIcon.visible) {
          this._promoActive = true;
          game.input.enabled = false;
          game.time.events.add(1000, () => {
            G.sb('pushWindow').dispatch(['treasureHuntPromo', { icon: this._treasureHuntIcon }]);
            game.input.enabled = true;
            resolve();
          });
        } else {
          console.log('Treasure Hunt icon not found');
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  /**
   * Shows the pop up for the home screen shortcut if a condition is true
   */
  async _checkHomescreenShortcut() {
    const result = await OMT.platformFunctions.canCreateShortcutAsync();
    if (result) {
      if (G.featureUnlock.homescreenShortcut.enabled) {
        if (G.saveState.homescreenShortcutManager.showPopup) {
          OMT.platformFunctions.createShortcutAsync()
            .then(() => {
              // Shortcut created
              G.sb('showMainHighscorePanel').dispatch();
              G.saveState.homescreenShortcutManager.onShortcutAdded();
              OMT.platformTracking.logEvent(OMT.platformTracking.Events.HomeScreenIconAccepted);
              /** Coin reward removed
              const { uiTargetParticles } = this;
              uiTargetParticles.createCoinBatch(
                game.world.bounds.x + this.button.worldPosition.x,
                this.button.worldPosition.y,
                this.state.panel.coinsTxt,
                1000,
              );

              OMT.transactionTracking.logInventoryTransactionBegin();
              OMT.transactionTracking.addInventoryChange('coins', 0, 1000);
              OMT.transactionTracking.logInventoryTransactionEnd();
              */
            })
            .catch(() => {
              // Shortcut not created
              G.sb('showMainHighscorePanel').dispatch();
              OMT.platformTracking.logEvent(OMT.platformTracking.Events.HomeScreenIconReject);
            });
        }
        G.saveState.homescreenShortcutManager.toggleOncePerSession();
      }
    } else {
      console.log('social.createShortcut', result);
    }
  }

  /**
   * Checks if you passed a friend
   */
  async checkLevelProgress() {
    if (this._villainsTutorialWillPopup) return;
    const passedUser = await this._getLevelPassedPlayer();
    if (passedUser) G.sb('pushWindow').dispatch(['friendOvertaken', passedUser]);
  }

  /**
   * Checks if villains tutorial should be played
   */
  async _check_villainsTutorial() {
    if (!OMT.feature.isVillainsEnabled()) return Promise.resolve();
    const stack = OMT_StackManager.getFreeStack();
    stack.wait(100);
    stack.addPromise(() => this._checkVillainsPostWinSagaMapTutorial());
    stack.addPromise(() => this._checkVillainsPreWinSagaMapTutorial());
    return stack.run();
  }

  /**
   * Check the villains pre-win saga map tutorial
   */
  async _checkVillainsPreWinSagaMapTutorial() {
    const lastLevel = G.saveState.getLastPassedLevelNr();
    const { isHardLevel, isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty(
      G.Helpers.levelDataMgr.getLevelByIndex(lastLevel),
    );
    const stack = OMT_StackManager.getFreeStack();
    stack.wait(100);
    const currentLevelNodeImage = this._mapScroller.getLevelNodeAt(lastLevel);
    const nextLevelNodeImage = this._mapScroller.getLevelNodeAt(lastLevel + 1);
    const currentLevelNode = this._mapScroller.getVillainWrapperAt(lastLevel);
    const nextLevelNode = this._mapScroller.getVillainWrapperAt(lastLevel + 1);
    stack.addPromise(async () => {
      if (G.WindowMgr.getInstance().isQueueEmpty) {
        if (currentLevelNode && currentLevelNode.villains) {
          await currentLevelNode.villains.explodePoof();
        }
      } else {
        await new Promise((resolve) => {
          this._signalBindings.push(G.sb('onAllWindowsClosed').addOnce(async () => {
            if (currentLevelNode && currentLevelNode.villains) {
              await currentLevelNode.villains.explodePoof();
            }
            resolve();
          }));
        });
      }
    });

    if (isNotNormalLevel) {
      const difficulty = isSuperHardLevel ? 'super_hard' : 'hard';
      // const DDNATrackingSeen = isSuperHardLevel ? 'superHardLevelTutorialSeen' : 'hardLevelTutorialSeen';

      const preWinSagaMapTutorial = G.saveState.villainsDataManager.getTutorialStatus(difficulty, 'pre_win_saga_map');

      if (!preWinSagaMapTutorial && nextLevelNode && nextLevelNodeImage) {
        stack.addEvent(() => {
          if (this._mapScroller.mapInteractionPanButton) {
            this._mapScroller.mapInteractionPanButton.animateOut();
          }
          this._mapScroller.socialFriendsLayer.setVisibility(false);
        });
        stack.addPromise(() => {
          if (!game.state.getCurrentState().map) return Promise.resolve();
          const panIndex = lastLevel - 2;
          return game.state.getCurrentState().map.panToLevelNode(panIndex, true);
        });
        stack.wait(100);
        stack.addEvent(() => {
          const queryObject = {
            type: 'villain',
          };
          if (isHardLevel) {
            queryObject.index = 1;
          }
          const highlightObjects = nextLevelNode.villains.getObjects(queryObject).filter((object) => object.worldPosition !== undefined);
          const highlightParameters = {
            worldPosition: {
              x: nextLevelNodeImage.worldPosition.x,
              y: nextLevelNodeImage.worldPosition.y - nextLevelNodeImage.height * 2,
            },
            width: highlightObjects.sort((a, b) => b.right - a.right)[0].right - highlightObjects.sort((a, b) => a.left - b.left)[0].left,
            height: highlightObjects.sort((a, b) => b.height - a.height)[0].height,
          };

          if (this._isLandscape) {
            highlightParameters.height *= 3 * this._gameScale;
          } else {
            highlightParameters.height *= 2.25;
          }

          const gingyPosition = {
            // x: this._mainLeaderboard._contentGroup.worldPosition.x + this._mainLeaderboard.width * 0.5,
            // y: this._mainLeaderboard._contentGroup.worldPosition.y - this._mainLeaderboard._contentGroup.height * 0.5 * G.OMTsettings.elements.superhard.sagaMapTutorial.gingyOffset,
            // anchorToBottom: () => this._mainLeaderboard._locked,
          };
          const baseNode = currentLevelNode._baseNode;

          const windowConfig = {
            difficulty,
            stage: 'pre_win_saga_map',
            gingyPosition,
            highlight: {
              steps: {
                0: highlightParameters,
                1: {
                  worldPosition: {
                    x: baseNode.worldPosition.x,
                    y: baseNode.worldPosition.y,
                  },
                  width: baseNode.width * 1.2,
                  height: baseNode.height * 1.2,
                },
              },
            },
            pointer: {
              tween: true,
              steps: {
                1: currentLevelNode._baseNode,
              },
            },
            tapToContinue: true,
            callbacks: {
              start: () => {
                G.sfx.xylophone_positive2.play();
                this._sideBarIconLayer.alpha = 0;
              },
              steps: {
                1: {
                  start: () => {
                    G.sfx.line.play();
                  },
                },
              },
              end: () => {
                G.saveState.villainsDataManager.setTutorialStatus(difficulty, 'pre_win_saga_map', true);
                G.sb('onWindowClosed').addOnce(() => {
                  currentLevelNodeImage.onClick.dispatch();
                  this._sideBarIconLayer.alpha = 1;
                  if (this._mapScroller.mapInteractionPanButton) {
                    this._mapScroller.mapInteractionPanButton.animateIn();
                  }
                  this._mapScroller.socialFriendsLayer.setVisibility(true);
                });
              },
            },
          };

          if (isSuperHardLevel) {
            windowConfig.highlight.steps = {
              0: highlightParameters,
              2: currentLevelNode._baseNode,
            };
            windowConfig.pointer.steps = {
              2: currentLevelNode._baseNode,
            };
          }

          G.sb('pushWindow').dispatch(['villainsTutorial', windowConfig]);
          // DDNA.tracking.ftuxEvent(-1, DDNATrackingSeen);
        });
      }
    }

    return stack.run();
  }

  /**
   * Check villains post-win saga map tutorial
   * It is disabled for now, but can be implemented later
   */
  async _checkVillainsPostWinSagaMapTutorial() {
    return Promise.resolve();
  }

  /**
   * Make a check for villains tutorial to see if it will popup
   * so that other popups can be managed
   */
  _willVillainsTutorialPopup() {
    if (!OMT.feature.isVillainsEnabled()) return false;
    const lastLevel = G.saveState.getLastPassedLevelNr();
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty(
      G.Helpers.levelDataMgr.getLevelByIndex(lastLevel),
    );
    let difficulty = 'hard';
    if (isSuperHardLevel) {
      difficulty = 'super_hard';
    }
    const preWinSagaMapTutorial = G.saveState.villainsDataManager.getTutorialStatus(difficulty, 'pre_win_saga_map');
    return isNotNormalLevel && !preWinSagaMapTutorial;
  }

  /**
   * Checks if friend is passed
   */
  async _getLevelPassedPlayer() {
    // const friendsList = await OMT.friends.getFriendsList();
    if (!this.lastLevelData) return null;

    if (this.lastLevelData.lvlNr + 1 === G.saveState.getLastPassedLevelNr()
      && this.lastLevelData.starImprovement === G.saveState.data.levels[this.lastLevelData.lvlNr]) {
      // const levelLeaderboard = friendsList.filter((user) => user.maxLevel);
      // const passedUser = levelLeaderboard.find((user) => user.maxLevel === G.saveState.getLastPassedLevelNr());
      // if (passedUser && this._isCorrectScene()) return passedUser;
    }

    return null;
  }

  /**
   * Checks mystery gift timer
   */
  _checkMysteryGiftTimer() {
    if (this._villainsTutorialWillPopup) return;

    if (!G.MYSTERYGIFT
        || !G.saveState.mysteryGiftManager
        || !this._canCheckMysteryGiftTimer
        || this._mysteryGiftWindowDispatched) {
      return;
    }

    if (G.saveState.mysteryGiftManager.endOfLastStreak === 0
      && G.saveState.mysteryGiftManager.getCurrentStreak() > 0
      && G.saveState.mysteryGiftManager.isModeReady()
      && G.saveState.mysteryGiftManager.getRemainingActiveTime() === 0) {
      G.sb('pushWindow').dispatch('mysteryGiftStreakIncrese');
      this._mysteryGiftWindowDispatched = true;
      this._canCheckMysteryGiftTimer = false;
    }
  }

  /**
   * When a gate opens, stuff triggers here.
   * @param {number} gateLevel
   */
  _openGate(gateLevel) {
    // When gate is opened
    if (gateLevel === 20) {
      TargetedOfferDataManager.getInstance().showPopupOfferIfPossible(TARGETED_OFFER_IDS.NON_PAYER_FIRST_GATE);
    }

    // Treasure hunt would be shown here upon a gate opening

    if (OMT.feature.isVillainsEnabled()) {
      const stack = OMT_StackManager.getFreeStack();
      stack.wait(100);
      stack.addEvent(() => {
        this._checkVillainsPreWinSagaMapTutorial();
      });
      stack.run();
    }
  }

  /**
   * When the coming soon part is clicked on the world map
   */
  _onComingSoonClicked() {
    // const crossPromoData = OMT.crossPromo.getPromoByPlacementId(XPROMO_PLACEMENTS.ENDOFCONTENT); // TODO: update placement id
    // OMT.crossPromo.showCrossPromoWindow('endofcontent', crossPromoData, null, true);
  }

  /**
   * CrossPromo data is loaded
   */
  _onXPromosReady() {
    // if (game.state.current !== 'World') return;
    // const promoActive = OMT.crossPromo.isPlacementActive(XPROMO_PLACEMENTS.ICON_PICTURE_COMBO);
    // if (promoActive) this._showCrossPromoIcon(OMT.crossPromo.getPromoByPlacementId(XPROMO_PLACEMENTS.ICON_PICTURE_COMBO));
  }

  /**
   * show CrossPromo icon
   * @param {Object} crossPromoData
   */
  _showCrossPromoIcon(crossPromoData) {
    if (!this._xpromoButton || !OMT.crossPromo.showCrossPromoIcon) return;
    const assetData = OMT.crossPromo.getAssetsForPromo(crossPromoData, OMT.envData.settings.env.lang, 'icon');
    if (!assetData) return;
    this._xpromoButton.init(crossPromoData, assetData.src);
    this._repositionSideButtons();
    this._xpromoButton.enterButton();
  }

  /**
   * send automated message on close if in friend context. TODO: fix this once we replace the leaderboard
   */
  _sendClosedHelpRequest() {
    if (!G.closedHelpRequestSent) {
      // delayed this call 5 seconds as it seemed to be causing issues with loading the leaderboards
      setTimeout(() => {
        OMT.social.sendClosedHelpRequest(() => {});
      }, 5000);
      G.closedHelpRequestSent = true;
    }
  }

  /**
   * intialize the main leaderboard
   */
  _initMainLeaderboard() {
    const externalUIBlocker = new UI_EventBlocker();
    externalUIBlocker.hide(0);
    externalUIBlocker.x = game.world.bounds.x;
    // this._mainLeaderboard = new MainLeaderboard(this._rewardCoins.bind(this), externalUIBlocker);
    game.world.add(externalUIBlocker);
    // game.world.add(this._mainLeaderboard);
    G.Utils.cleanUpOnDestroy(this._mapScroller, G.sb('onScreenResize').add(this._positionLeaderboard.bind(this)));
    this._positionLeaderboard();

    // this._mainLeaderboard.signals.onExpanded.add(() => {
    //   if (this.worldSidePanel) this.worldSidePanel.hideReasons.add('LeaderboardExpanded');
    //   this._mapScroller.inputEnabled = false;
    //   this._mapScroller.stopDragInertia();
    // });

    // this._mainLeaderboard.signals.onCollapsed.add(() => {
    //   if (this.worldSidePanel) this.worldSidePanel.hideReasons.remove('LeaderboardExpanded');
    //   this._mapScroller.inputEnabled = true;
    //   this._mapScroller.stopDragInertia();
    // });

    // this._mapScroller.setScrollDeadSpot(() => new Phaser.Rectangle(
    //   this._mainLeaderboard.x,
    //   this._mainLeaderboard.y - this._mainLeaderboard.collapsedHeight,
    //   this._mainLeaderboard.width,
    //   this._mainLeaderboard.collapsedHeight,
    // ));
  }

  _positionLeaderboard() {
    if (!this._orientationIsVertical) { // Horizontal
      // this._mainLeaderboard.x = game.world.bounds.x + 20;
    }
    // Vertical mode is in the center only
  }

  // reward from highscore coin button clicked
  _rewardCoins(targetUserId, animateFrom) {
    const worldState = this;
    const amount = G.json.settings.coinsForChangingContext;

    worldState.uiTargetParticles.createCoinBatch(
      game.world.bounds.x + animateFrom.x,
      animateFrom.y,
      worldState.panel.coinsTxt,
      amount,
    );

    // DDNA.transactionHelper.trackRewards([G.gift.GiftContentType.Coins, amount], [], {
    //   transactionType: 'REWARD', tActionType: 'SOCIAL', tGameArea: 'BOTTOM_SCREEN',
    // });

    // DDNA.socialTracker.incrementParam('numCoinSends', 1);
    // DDNA.socialTracker.incrementParam('numFriendsSentCoins', 1);
    // DDNA.tracking.socialActionEvent('SEND_COIN', 'COINS', targetUserId, '');
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.LBCoinsGathered, amount, {
      amount,
    });
  }

  /**
   * intialize the level leaderboard
   */
  _initLevelLeaderboard() {
    // this._levelLeaderboard = new LevelLeaderboard();
    // game.world.addChild(this._levelLeaderboard);

    const friendShipChestBannerShowing = OMT.feature.getFeatureFriendshipChest()
      && !OMT.feature.getFeatureFriendshipChestActiveByLevel() && G.saveState.friendshipChestDataManager.getInvitedData() != null;

    this._signalBindings.push(G.sb('levelWindowOpen').add(async (lvlData, gameMode, showLeaderboard = true) => {
      // get leaderboard entries
      let entriesList;
      if (lvlData.id.indexOf('tournament_') >= 0) { // tournament leaderboard
        entriesList = await OMT.platformTournaments.getTournamentEntries();
      } else if (gameMode === LevelType.COLLECT_EVENT) { // special event
        // Special events levels don't use per level leaderboards
        return;
      } else { // normal leaderboards
        if (friendShipChestBannerShowing) return;
        const instanceId = lvlData.id + (gameMode === LevelType.CHALLENGE ? '_challenge' : '');
        entriesList = await OMT.leaderboards.getFriendsLeaderboardEntries('default', instanceId);
      }
      if (entriesList.length === 0) return; // no entries, no show
      // await OMT_TexturePreloader.getInstance().loadTexturesAsync(entriesList.map((userData) => userData.image));
      // await this._levelLeaderboard.setEntries(entriesList);
      if (game.state.current !== 'World') return; // if somehow we are not in the World state do nothing

      // Show level leaderboard if it fits
      if (showLeaderboard) {
        // this._levelLeaderboard.show();
      } else {
        // this._levelLeaderboard.hide();
      }
    }));

    this._signalBindings.push(G.sb('hideHighscoreBoard').add(() => {
      // if (this._levelLeaderboard) this._levelLeaderboard.hide();
    }));

    this._signalBindings.push(G.sb('levelWindowClose').add(() => {
      // if (this._levelLeaderboard) this._levelLeaderboard.hide();
    }));
  }

  /**
   * Callback that runs every tick
   */
  _onTick() {
    this._checkMysteryGiftTimer();
  }

  /**
   * Check if we can show ads
   * @param {function} callback
   * @param {INTERSTITIAL_RULES} rule
   */
  checkForAds(callback, rule) {
    let showAd = false;
    switch (rule) {
      case INTERSTITIAL_RULES.GIFT_GAIN:
        showAd = OMT.feature.useAdditionalInterstitialGiftAds();
        break;

      default:
        console.log('** end point error - invalid interstitial ad rule');
    }
    if (showAd && G.saveState.getIAPCount() === 0) {
      // if (OMT.crossPromo.isPlacementActive(XPROMO_PLACEMENTS.INTERSTITIAL) && !OMT.crossPromo.isCrossPromoSeen()) {
      //   const crossPromoData = OMT.crossPromo.getPromoByPlacementId(XPROMO_PLACEMENTS.ICON_PICTURE_COMBO); // TODO: update placement id
      //   OMT.crossPromo.showCrossPromoWindow('interstitial', crossPromoData, callback);
      //   console.log('** end point showCrossPromoAd');
      // } else
      if (OMT.feature.interstitialAdsAvailable()) {
        console.log('** end point showAd');
        let placement;
        switch (G.PerSessionSettings.interstitialsInSession) {
          case 0:
            placement = G.BuildEnvironment.adPlacements.firstInterstitialInSession;
            break;
          case 1:
            placement = G.BuildEnvironment.adPlacements.secondInterstitialInSession;
            break;
          default:
            placement = G.BuildEnvironment.adPlacements.interstitial;
        }

        G.PerSessionSettings.interstitialsInSession++;

        // needed to ad the ad shown event on success
        const successCallback = () => {
          callback();
        };

        OMT.ads.showAd(placement, successCallback, callback);
      }
    } else {
      console.log('** end point dont showAd');
      callback();
    }
  }

  /**
   * Opens the gate and bypasses DDNA reporting since its not really being opened.
   * Only for ABC testing OMT-4927 right now
   * Gates are opened before the map is initialized to prevent the map from re-rendering the tile again
   * and doing all the animations.
   */
  _gateCheckWhenTheresNoGates() {
    if (G.featureUnlock.useNoGates) {
      const lastestLevel = G.saveState.getLastPassedLevelNr();
      if (lastestLevel % G.saveState.gateManager.levelGap === 0 && lastestLevel > G.saveState.gateManager.lastGate) {
        G.saveState.gateManager.openGate(lastestLevel);
        G.saveState.gateManager.save();
        G.saveState.mapChestDataManager.save();
        G.saveState.chestShuffleDataManager.save();
        G.saveState.mailboxManager.save();
      }
    }
  }

  /**
   * Creates the map gift button on the side of the world map
   * @param {boolean} isRight
   */
  _createMapGiftUIButton(isRight) {
    if (OMT.feature.getFeature3hGift()) {
      const hourGift = new MapGift();

      this._addUIButtonToSide(isRight, hourGift, G.OMTsettings.elements.mapGift);
      return hourGift;
    }
    return null;
  }

  /**
   * Create a button that goes to the shop.
   * @param {boolean} isRight
   */
  _createMapGiftAnnuityReminderUIButton(isRight) {
    if (G.saveState.annuityManager.isThereAnnuityToClaim() && G.IAP) {
      this._createAnnuityReminder(isRight, true);
    } else {
      this._createMapGiftUIButton(isRight);
    }
  }

  /**
   * Creates the daily challenge button on the side of the world map
   * @param {boolean} isRight
   */
  _createDailyChallengeUIButton(isRight) {
    if (OMT.feature.isDailyChallengeEnabled(OMT.feature.isVillainsEnabled())) {
      this._dailyChallengeIcon = new UI_DailyChallengeIcon();
      if (this._dailyChallengeIcon.visible || this._dailyChallengeIcon.icon.adIcon.visible) { // If the daily challenge is visible

        this._addUIButtonToSide(isRight, this._dailyChallengeIcon, G.OMTsettings.elements.dailyChallengeIcon);
      }
    }
  }

  /**
   * Creates the tournament button on the side of the world map
   * @param {boolean} isRight
   */
  _createTournamentUIButton(isRight) {
    if (G.featureUnlock.weeklyTournament.enabled) {
      const tournamentIcon = this._tournamentIcon = new UI_TournamentIcon();

      this._addUIButtonToSide(isRight, tournamentIcon, G.OMTsettings.tournaments.worldMapIcon);
    }
  }

  /**
   * Creates the daily spin button on the side of the world map
   * @param {boolean} isRight
   */
  _createDailySpinUIButton(isRight) {
    this.dailySpinIcon = new G.UI_DailyIcon();

    this._addUIButtonToSide(isRight, this.dailySpinIcon, G.OMTsettings.elements.dailyIcon);
  }

  /**
   * Creates the friendship chest button on the side of the world map
   * @param {boolean} isRight
   */
  _createFriendshipChestUIButton(isRight) {
    if ((OMT.feature.getFriendshipChestStory()
      && OMT.feature.getFeatureFriendshipChest())
      || (!OMT.feature.getFeatureFriendshipChest() && G.saveState.friendshipChestDataManager.getUnclaimedCard())) {
      // const assetStatus = OMT.feature.getFriendshipChestAssetStatus();
      // const friendshipChest = new UI_FriendshipChest();
      // if (!assetStatus.complete) { // Not ready
      //   friendshipChest.visible = false;
      //   friendshipChest.scale.set(0.1);
      //   Friendship_Chest.areAssetsReady(() => { // Background loading happens here too
      //     if (game.state.current !== 'World') return;
      //     friendshipChest.visible = true;
      //     game.add.tween(friendshipChest.scale).to({ x: 1, y: 1 }, 250, Phaser.Easing.Sinusoidal.InOut, true);
      //   });
      // }

      // this._addUIButtonToSide(isRight, friendshipChest, G.OMTsettings.friendshipChest);
    }
  }

  /**
   * Creates the Cross Promo button on the side of the world map
   * @param {boolean} isRight
   */
  _createXpromoUIButton(isRight) { // init cross promo icon in the UI. Content will get initialized later.
    // const xpromoButton = new OMT_UI_CrossPromoMapButton(isRight);
    // this._xpromoButton = xpromoButton;

    // this._addUIButtonToSide(isRight, xpromoButton, G.OMTsettings.crossPromo.worldMapIcon);
  }

  /**
   * Creates the eventPostcard button on the side of the world map
   * @param {boolean} isRight
   */
  _createEventPostcardUIButton(isRight) {
    const eventPostCardEvent = OMT.feature.getEventPostcardFeature();
    if (eventPostCardEvent) {
      const postCard = new UI_EventPostcard({
        icon: 'eventPostcardWorldMap',
        range: {
          end: G.featureUnlock.eventPostcard.range.end,
        },
      });

      this._addUIButtonToSide(isRight, postCard, G.OMTsettings.postcardEvent.worldMapIcon);
    }
  }

  /**
   * Creates the special shop deal button on the side of the world map
   * @param {boolean} isRight
   */
  _createSpecialShopDealUIButton(isRight) {
    if (G.IAP && OMT.feature.isSpecialShopDealsOn(true)) {
      const shopIcon = new UI_ShopIcon({
        icon: G.OMTsettings.specialShopDeal.mapIcon.asset,
        range: {
          end: ShopUtils.determineSpecialDealsTimeOut(),
        },
      });

      this._addUIButtonToSide(isRight, shopIcon, G.OMTsettings.specialShopDeal.mapIcon);
    }
  }

  /**
   * Creates the tokenEvent button on the side of the world map
   * @param {boolean} isRight
   */
  _createTokenEventButton(isRight) {
    if (OMT.feature.isTokenEventOn(true, true)) {
      const icon = new TokenEventUIIcon();

      this._addUIButtonToSide(isRight, icon, G.OMTsettings.tokenEvent.mapIcon);
    }
  }

  /**
   * Creates the treasure hunt entry point on the map
   * @param {boolean} isRight
   */
  _createTreasureHuntButton(isRight) {
    if (OMT.feature.isTreasureHuntOn(true, false, true, true)) { // Turn true to check range and not show when not active
      this._treasureHuntIcon = new TreasureHuntIcon(this._removeIconMidway.bind(this));
      if (!this._treasureHuntIcon.removedItself) {
        this._addUIButtonToSide(isRight, this._treasureHuntIcon, G.OMTsettings.treasureHuntSuper.worldMapIcon);
      }
    }
  }

  /**
   * Creates the annuity reminder button
   * @param {boolean} isRight
   */
  _createAnnuityReminder(isRight, changeToMapGift = false) {
    if (G.saveState.annuityManager.isThereAnnuityToClaim() && G.IAP) {
      const annuityReminder = new UI_AnnuityReminderIcon(() => {
        if (changeToMapGift) {
          const targetPack = isRight ? this._UI_button.right : this._UI_button.left;
          const targetUIPack = targetPack.elements.find((pack) => pack.icon === annuityReminder);
          const targetIndex = targetPack.elements.indexOf(targetUIPack);
          const hourGift = this._createMapGiftUIButton(isRight);
          const newPack = targetPack.elements.find((pack) => pack.icon === hourGift);
          if (newPack) {
            targetPack.elements.splice(targetPack.elements.indexOf(newPack), 1);
            targetPack.elements.splice(targetIndex, 0, newPack);
          }
        }
        this._removeIconMidway(annuityReminder);
      });
      this._addUIButtonToSide(isRight, annuityReminder, G.OMTsettings.elements.annuityReminderIcon);
    }
  }

  /**
   * Callback function from inside the TreasureHuntIcon that finds itself, removes it from the back
   * recalculate width, and redo the ordering/side bar, if possible
   * @param {MapUIIcon} icon
   * @returns {null}
   */
  _removeIconMidway(icon) {
    let targetPack;
    let targetElement;
    for (const pack of [this._UI_button.right, this._UI_button.left]) {
      const ele = pack.elements.find((element) => element.button === icon || element.icon === icon);
      if (ele) {
        targetPack = pack;
        targetElement = ele;
        break;
      }
    }
    if (!targetPack) { return; }

    targetPack.elements.splice(targetPack.elements.indexOf(targetElement), 1);
    targetPack.largestWidth = 0;
    for (const element of targetPack.elements) {
      targetPack.largestWidth = Math.max(targetPack.largestWidth, element.width);
    }
    this._repositionSideButtons();
  }

  /**
   * Allows access to the map scroller externally by map
   */
  get map() {
    return this._mapScroller;
  }
}
