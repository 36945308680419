/* eslint-disable no-trailing-spaces */
/* eslint-disable no-undef */
/* eslint-disable no-continue */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */

import { Window_FillRateFallback } from './Window_FillRateFallback';
import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

/**
 * generic gift window used to display gifts of all types
 */
class Window_Gift extends Window {
  /**
   * cosntructor
   * @param {Object} parent
   * @param {Object} config
   */
  constructor(parent, config) {
    super(parent);

    G.GiftUI.HelperFunctions.processConfig(G.json.settings.GiftUIDefaultConfig, config, this);
    this._shareOverlay = null; // reference to share overlay, so it can be removed any time after its creation
    this._isAchievement = this._config.reason === G.GiftUI.Constants.GiftReasons.Achievement;
    this._lvlIndex = this._config.lvlIndex;
    this._didShare = false; // did the player share the booster/gift?
    this._firstGiftOpened = false; // has the player opened the first gift (applicable multigift only)
    this._selectedBoosterToShare = -1; // if not -1, is the index of the booster selected to share
    this.x += this._config.offset.x;
    this.y += this._config.offset.y;
    this._claimedGifts = [];
    this._rewardMulti = 1; // 1x, 2x, 3x rewards
    
    // true if this is the daily reward git window
    this._isDailyReward = this._config.reason === G.GiftUI.Constants.GiftReasons.DailyReward;
    // allow gift 3x, currently only for the daily reward.
    this._allowTripleReward = this._isDailyReward && G.featureUnlock.dailyReward != null && G.featureUnlock.dailyReward.allowTripleReward; 

    if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
      this.y += 50;
    }

    if (this._isAchievement && this._lvlIndex === 2) { // track level 3 gift show
      // DDNA.tracking.ftuxEvent(12, 'achievementGiftClaim');
    }

    this._animations = {
      btnFadeIn: G.Animations.getBtnFadeInAnimation(),
      AtoB: G.Animations.getAtoBAnimation(),
      swapTwoElements: G.Animations.getSwapTwoElementsAnimation(),
      cubicFadeOut: G.Animations.getCubicFadeOutAnimation(),
      cubicRescale: G.Animations.getCubicRescaleAnimation(),
      resetTransform: G.Animations.getResetTransformAnimation(),
    };

    // init savedata
    if (!G.saveState.data.lastGiftShareTime) {
      G.saveState.data.lastGiftShareTime = 0;
    }

    if (!config.skipGiftProcessing) {
      for (let i = 0; i < this._config.gifts.length; i++) {
        G.gift.processGift(this._config.gifts[i]);
      }
    }

    if (
      this._config.monetiseType === G.GiftUI.Constants.MonetiseOptions.DoubleReward
      && !G.GiftUI.HelperFunctions.canDouble(this._config)
    ) {
      // G.Utils.SentryLog.logError('Double reward selected but cannot double', {});
      this._config.monetiseType = G.GiftUI.Constants.MonetiseOptions.None;
    }

    if (this._config.overlay) {
      const fadeImg = G.GiftUI.Elements.Overlay();
      this.add(fadeImg);
    }

    if (this._config.showTitleBar) {
      this.add(G.GiftUI.Elements.TitleBar(this, this._config.reason || ''));
    }

    if (this._config.titleTextBar) {
      this.add(G.GiftUI.Elements.TitleTextBar(this, this._config.titleTextBar));
    }

    if (this._config.showGingy || this._config.showGingyGiftBar) {
      this._sideGingyGroup = G.GiftUI.Elements.Gingy(this, this._config);
      this._sideGingyGroup.visible = false;
      this._sideGingyGroup._sideGingy.x += this._config.gingyOffset.x;
      this._sideGingyGroup._sideGingy.y += this._config.gingyOffset.y;
    }

    if (this._config.reason === G.GiftUI.Constants.GiftReasons.Chest) {
      this._createChest();
    } else {
      this._createGifts(this._config.onlyOneBox);
    }

    // this._createShareUI();

    this._createButtons();

    this._showGingy();
    this._showGifts();

    if (this._config.autoOpen) {
      this._openGiftsWithDelay(this._config.autoOpenDelay);
    }

    if (this._config.onOpen) {
      this._config.onOpen();
    }

    G.sb('hideMainHighscorePanel').dispatch();
  }

  /**
   * create chest graphics
   */
  _createChest() {
    this._giftGroup = new Phaser.Group(game, this);
    this._gifts = [];
    this._gifts.push(G.GiftUI.Elements.Chest(0, 150, this._config));
    this._gifts[0].onOpen.add(this._openPresent, this, 0, 0);
    this._gifts[0].x += this._config.giftOffset.x;
    this._gifts[0].y += this._config.giftOffset.y;
    this._giftGroup.add(this._gifts[0]);
    this._giftGroup.visible = false;
  }

  /**
   * create 1 or more gifts to display
   * @param {Array} singleGift
   */
  _createGifts(singleGift) {
    this._giftGroup = new Phaser.Group(game, this);
    this._gifts = [];

    if (singleGift) { // left in for legacy code, not sure if it makes a difference
      this._gifts.push(
        G.GiftUI.Elements.GiftBox(
          0,
          125,
          singleGift ? this._config.gifts : this._config.gifts[0],
          G.GiftUI.HelperFunctions.getGiftBoxTypeFromType(this._config.giftBoxType),
          this._config,
        ),
      );
    } else { // multiple, or single gifts
      for (const giftData of this._config.gifts) {
        this._gifts.push(
          G.GiftUI.Elements.GiftBox(
            0,
            125,
            giftData,
            G.GiftUI.HelperFunctions.getGiftBoxTypeFromType(this._config.giftBoxType),
            this._config,
          ),
        );
      }
      
      // adjust gift spacing, no longer only for 3 gifts
      if (this._gifts.length > 0) {
        const giftSpacing = this._config.tripleGiftSpacing;
        let giftX = -(giftSpacing / 2) * (this._gifts.length - 1);
        for (const giftInstance of this._gifts) {
          giftInstance.x = giftX;
          giftX += giftSpacing;
        }
      }
    }

    if (this._gifts.length <= 1) { // increase scale for single gifts
      this._giftGroup.scale.setTo(1.25); 
    } else if (this._gifts.length > 3) { // force fit more then 3 gifts horizontally
      const groupScale = 3 / this._gifts.length;
      this._giftGroup.scale.setTo(groupScale);
    }
    
    for (let i = 0; i < this._gifts.length; i++) {
      const currGiftIndex = i;
      this._gifts[i].scale.setTo(0.75);
      this._gifts[i].onOpen.add(this._openPresent, this, 0, currGiftIndex);
      this._gifts[i].x += this._config.giftOffset.x;
      this._gifts[i].y += this._config.giftOffset.y;
      this._giftGroup.add(this._gifts[i]);
      this._claimedGifts.push(false);
    }

    this._giftGroup.visible = false;
  }

  /**
   * create the share UI
   */
  _createShareUI() {
    if (this._shareUI) return;
    this._shareUI = G.GiftUI.Elements.ShareTickbox(-5, 495, '');
    this._shareUI.visible = false;
    this._shareUI.signals.onClick.add(this._handleShare, this);
    this.add(this._shareUI);
  }

  /**
   * hide game mascot
   */
  _hideGingy() {
    if (!this._config.showGingy || !this._sideGingyGroup) return;
    this._sideGingyGroup.hideGingy.animate(this._sideGingyGroup);
  }

  /**
   * show game mascot
   */
  _showGingy() {
    if (!this._config.showGingy || !this._sideGingyGroup) return;
    this._sideGingyGroup.showGingy.animate(this._sideGingyGroup, this._config);
  }

  /**
   * show / animation current gifts
   */
  _showGifts() {
    this._giftGroup.visible = true;
    for (let i = 0; i < this._gifts.length; i++) {
      // const curr = this._gifts[i];
      // scaling was causing issues so it was disabled
      /*
      curr.scale.setTo(0);
      game.add
        .tween(curr.scale)
        .to({ x: 0.75, y: 0.75 }, 500, Phaser.Easing.Bounce.Out, true);
      */
    }
  }

  /**
   * opens gifts with some delay
   * @param {number} delayMs
   */
  _openGiftsWithDelay(delayMs) {
    const delay = G.AsyncUtils.createScheduler(game);
    delay.rescheduleAction(() => {
      for (let i = 0; i < this._gifts.length; i++) {
        if (!this._gifts[i].gift.opened) {
          this._openPresent(i);
        }
      }
    }, delayMs || this._config.autoOpenDelay || 1000);
  }

  /**
   * open a gift
   * @param {number} giftIndex
   */
  _openPresent(giftIndex) {
    const selectedGift = this._gifts[giftIndex];
    selectedGift.gift.inputEnabled = false;
    selectedGift.gift.opened = true;

    // mark which gifts have been opened (for achievement gifts)
    if (giftIndex < this._claimedGifts.length && giftIndex >= 0) {
      this._claimedGifts[giftIndex] = true;
    }

    if (this._gifts.length > 1) {
      for (let i = 0; i < this._gifts.length; i++) {
        if (i === giftIndex) continue;
        this._gifts[i].gift.inputEnabled = false;
      }
    }

    // We only move gifts up, not chests
    if (this._config.reason !== G.GiftUI.Constants.GiftReasons.Chest) {
      game.add
        .tween(this._giftGroup)
        .to({ y: -100 }, 500, Phaser.Easing.Sinusoidal.Out, true);
    }

    // swap present positions if not the first one
    if (!this._firstGiftOpened && giftIndex !== 0 && !this._config.noSwapAnimation) {
      this._animations.swapTwoElements.animate(game, this._gifts[0], selectedGift);
    }

    // Enlarge main present and open it
    G.GiftUI.HelperFunctions.openPresentWithAnimation(selectedGift, this._config, () => {
      game.sfx.line.play();
      this._showOptionToClaimAll();
      if (
        !this._firstGiftOpened
        && this._config.monetiseType === G.GiftUI.Constants.MonetiseOptions.OpenOtherGifts
        && this._gifts.length === 3
      ) {
        selectedGift.light.shouldRotate = false;

        game.add
          .tween(selectedGift.light)
          .to({ alpha: 0 }, 500, Phaser.Easing.Sinusoidal.Out, true)
          .onComplete.add(function () {
            this.visible = false;
          }, selectedGift.light);

        for (let i = 0; i < this._gifts.length; i++) {
          if (i === giftIndex) continue;
          else {
            const curr = this._gifts[i];
            curr.gift.stopShake = false;
            curr.light.visible = true;
            curr.light.shouldRotate = true;
          }
        }
      }
      if (!this._firstGiftOpened) this._firstGiftOpened = true;
    });
  }

  /**
   * claim all prizes
   */
  _claimAllBtnClicked() {
    OMT.transactionTracking.logInventoryTransactionBegin();

    if (this._config.onClaim) {
      this._config.onClaim();
    }

    if (this._lvlIndex === 2) { // track level 3 gift claim
      // DDNA.tracking.ftuxEvent(10, 'achievementGiftClaim');
    }

    if (this._config.incentivisedSharing && this._didShare) {
      G.gift.applyGift(
        [G.gift.GiftContentType.Coins, 100],
        false,
        G.gift.ItemType.Reward,
        G.gift.ItemId.ShareBooster,
      );
    }

    if (this._config.reason === G.GiftUI.Constants.GiftReasons.Achievement) {
      game.sfx.chain_rattle.play();
    }

    for (let i = 0; i < this._config.gifts.length; i++) {
      if ((!this._gifts[i] && !this._config.onlyOneBox)) {
        continue;
      }
      if (
        this._config.gifts[i][0].indexOf('coin') !== -1
        && this.state.panel
        && this._config.coinFeedback
      ) {
        const fromPos = {
          x: 0,
          y: 0,
        };

        if (this._gifts[0].inside.chestGifts) {
          fromPos.x = this._gifts[0].inside.chestGifts[i].worldPosition.x;
          fromPos.y = this._gifts[0].inside.chestGifts[i].worldPosition.y;
        } else if (this._config.monetiseType === G.GiftUI.Constants.MonetiseOptions.DoubleReward) {
          fromPos.x = this._gifts[i].inside_dbl.worldPosition.x;
          fromPos.y = this._gifts[i].inside_dbl.worldPosition.y;
        } else {
          fromPos.x = this._gifts[i].inside.worldPosition.x;
          fromPos.y = this._gifts[i].inside.worldPosition.y;
        }

        this.state.uiTargetParticles.createCoinBatch(
          game.world.bounds.x + fromPos.x,
          fromPos.y,
          this.state.panel.coinsTxt,
          this._config.gifts[i][1],
        );

        // Count coins for transaction tracker
        OMT.transactionTracking.addInventoryChange('coins', 0, this._config.gifts[i][1]);
      } else if ((this._config.reason === G.GiftUI.Constants.GiftReasons.Achievement && this._claimedGifts[i])
        || this._config.reason !== G.GiftUI.Constants.GiftReasons.Achievement) {
        // If the gifts are from an achievement, apply only those that have been opened,
        // Otherwise, claim them all
        G.gift.applyGift(
          this._config.gifts[i],
          false,
          this._config.resourceTracking.itemType,
          this._config.resourceTracking.itemId,
        );
      }
    }

    // log gifts to transaction tracker
    OMT.transactionTracking.logInventoryTransactionEnd();

    // no event data override passed
    if (!this._config.ddnaEventData) {
      // Achievement Gifts
      if (this._config.reason === G.GiftUI.Constants.GiftReasons.Achievement) {
        // const chosenGifts = this._config.gifts.filter((gift, i) => this._claimedGifts[i]);
        // const totalCoins = chosenGifts.reduce((total, gift) => (gift[0] === 'coin' ? total + gift[1] : total), 0);

        // DDNA.transactionHelper.trackRewards(chosenGifts, [], {
        //   transactionType: 'REWARD',
        //   tActionType: 'REWARD',
        //   tLevelEndGift: totalCoins,
        //   tAdRewardType: chosenGifts.length > 1 ? 'EXTRA_GIFT' : '',
        //   tGameArea: 'LEVEL',
        // });
      } else { // Other Gifts
        let tAdRewardType = '';
        if (this._rewardMulti === 2) tAdRewardType = 'DOUBLE_VALUE';
        if (this._rewardMulti >= 3) tAdRewardType = 'TRIPLE_VALUE';

        // DDNA.transactionHelper.trackRewards(this._config.gifts, [], {
        //   transactionType: 'REWARD',
        //   tActionType: 'REWARD',
        //   tAdRewardType,
        //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
        // });
      }
    } else { // data passed in config
      // DDNA.transactionHelper.trackRewards(this._config.gifts, [], this._config.ddnaEventData);
    }
    this._handleClose();
  }

  /**
   * handle share action
   */
  _handleShare() {
    if (
      this._isAchievement
      && this._lvlIndex !== undefined
    ) {
      // Save reference to share overlay so it can be removed after sharing
      this._shareOverlay = G.GiftUI.HelperFunctions.showShareLoadingOverlay(this);

      OMT.social.shareWonThreeTimes(this._lvlIndex + 1, () => {
        G.GiftUI.HelperFunctions.removeShareLoadingOverlay(this, this._shareOverlay);
      });
    } else {
      this._handleClose();
    }
  }

  /**
   * handle close action
   */
  _handleClose() {
    this.closed = true;
    if (this._config.hideShowMainHighscorePanel) {
      G.sb('showMainHighscorePanel').dispatch();
    }
    if (this._config.onClose) {
      this._config.onClose();
    }
    this.closeWindow();
  }

  /**
   * handle selected booster
   * @param {Object} selectedGift
   * @param Object callback
   */
  _handleSelectedBooster(selectedGift, callback) {
    const type = G.GiftUI.HelperFunctions.getGiftTypeFromString(
      this._config.gifts[
        selectedGift === undefined ? 0 : selectedGift
      ][0],
    );

    this._gifts[0].insideExtraTxt.setText(G.GiftUI.HelperFunctions.getSharingInsideExtraTextFromType(type));

    if (selectedGift !== undefined) {
      for (let i = 0; i < this._gifts[0].inside_dbl.chestGifts.length; i++) {
        if (i === selectedGift) continue;
        const curr = this._gifts[0].inside_dbl.chestGifts[i].boosterImg;
        this._animations.cubicFadeOut.animate(game, curr, 600)
          .onComplete.add(function () {
            this.visible = false;
          }, curr);
      }
      this._animations.resetTransform.animate(game,
        this._gifts[0].inside_dbl.chestGifts[selectedGift], 600)
        .onComplete.add(() => { callback(selectedGift); }, this);
    } else {
      callback(selectedGift);
    }
  }

  /**
   * play the share animation
   * @param {Function} callback
   */
  _playShareAnimation(callback) {
    const curr = this._gifts[0];
    G.saveState.data.lastGiftShareTime = Date.now();
    this._didShare = true;

    this._hideGingy();

    this.shareBoosterBtn.inputEnabled = false;
    this.claimAllBtn.inputEnabled = false;
    this._animations.cubicFadeOut.animate(game, this.shareBoosterBtn);
    this._animations.cubicFadeOut.animate(game, this.claimAllBtn);
    this._animations.cubicFadeOut.animate(game, curr.gift);

    const target = curr.inside_dbl || curr.inside;
    game.add
      .tween(target)
      .to({ y: -6 }, 750, Phaser.Easing.Quadratic.Out, true)
      .onComplete.add(() => {
        G.GiftUI.HelperFunctions.playBoosterSplitAnimation(
          curr, this._config, this._selectedBoosterToShare, this._animations, callback,
        );
      });
  }

  /**
   * watch ad button clicked
   * @param {*} index
   */
  _premiumBtnClaimGiftClicked(index) {
    const onAdSuccess = () => {
      this._gifts[index]._premiumOpenOtherBtn.visible = false;
      game.sfx.chain_rattle.play();
      this._openPresent(index);
      this._numberOfOpenedPremiumGifts++;

      OMT.platformTracking.logEvent(OMT.platformTracking.Events.WatchedAdAccomplishGift);
      if (this._numberOfOpenedPremiumGifts === 1) {
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.WatchedAdToOpen1stAccomplishGift);
      } else if (this._numberOfOpenedPremiumGifts === 2) {
        OMT.platformTracking.logEvent(OMT.platformTracking.Events.WatchedAdToOpen2ndAccomplishGift);
      }
    };

    const onAdFail = () => {
      if (!G.IAP) { // no IAPS dont use ad fallbacks
        // eslint-disable-next-line no-new
        new Window_FillRateFallback(undefined, {
          placement: this._config.placement || G.BuildEnvironment.adPlacements.claimExtraGift,
          callback: onAdSuccess,
          context: this,
        });
      } else { // show ad fallback
        OMT.ads.showAdFallback(onAdSuccess);
      }
    };

    OMT.ads.showAd(
      this._config.placement || G.BuildEnvironment.adPlacements.claimExtraGift,
      onAdSuccess,
      onAdFail,
    );
  }

  /**
   * on ad successfully shown
   */
  _adSuccess() {
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.WatchedAdDailyReward);

    this.claimAllBtn_Premium.visible = false;
    this.noThanksBtn.visible = false;

    G.sfx.chain_rattle.play();

    if (this._config.monetiseType === G.GiftUI.Constants.MonetiseOptions.DoubleReward) {
      for (let i = 0; i < this._config.gifts.length; i++) {
        this._config.gifts[i][1] += parseInt(this._config.gifts[i][1] / this._rewardMulti);
      }
      this._rewardMulti++; // 1x, 2x, 3x rewards
      this._playDoubleAnim();
    } else {
      this._openAllPresents();
    }
  }

  /**
   * create buttons for overlay
   */
  _createButtons() {
    G.GiftUI.HelperFunctions.createButtons(
      this,
      this._config,
      this._premiumBtnClaimAllClicked.bind(this),
      this._claimAllBtnClicked.bind(this),
      () => {},
      () => { this._onNoThanksClicked(); },
    );
  }

  /**
   * no thanks clicked
   */
  _onNoThanksClicked() {
    if (this._config.onClaim) {
      this._config.onClaim();
    }
    G.sfx.chain_rattle.play();
    if (this.claimAllBtn_Premium) this.claimAllBtn_Premium.inputEnabled = false;
    if (this.noThanksBtn) this.noThanksBtn.inputEnabled = false;
    this._applyAllGiftsInConfig(() => {
      this._handleShare();
    });
  }

  /**
   * apply all gifts in bundle
   * @param {Function} callback
   */
  _applyAllGiftsInConfig(callback) {
    let callbackQueued = false;
    
    OMT.transactionTracking.logInventoryTransactionBegin();

    for (let i = 0; i < this._config.gifts.length; i++) {
      if (
        G.GiftUI.HelperFunctions.getGiftTypeFromString(
          this._config.gifts[i][0],
        ) === G.GiftUI.Constants.GiftTypes.Coins
        && this.state.panel
        && this._config.coinFeedback
      ) {
        callbackQueued = true;

        this.state.uiTargetParticles.createCoinBatch(
          game.world.bounds.x + this._gifts[i].inside.worldPosition.x,
          this._gifts[i].inside.worldPosition.y,
          this.state.panel.coinsTxt,
          this._config.gifts[i][1],
        );

        // Count coins for transaction tracker
        OMT.transactionTracking.addInventoryChange('coins', 0, this._config.gifts[i][1]);

        game.time.events.add(
          1000,
          () => {
            callback();
          },
        );
      } else {
        // DDNA.transactionHelper.trackRewards(this._config.gifts[i], [], {
        //   transactionType: 'REWARD',
        //   tActionType: 'DAILY_LOGIN',
        //   tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
        // });

        G.gift.applyGift(
          this._config.gifts[i],
          false,
          this._config.resourceTracking.itemType,
          this._config.resourceTracking.itemId,
        );
      }
    }

    // log gifts to transaction tracker
    OMT.transactionTracking.logInventoryTransactionEnd();

    if (!callbackQueued) {
      callback();
    }
  }

  /**
   * play double animation
   */
  _playDoubleAnim() {
    for (let i = 0; i < this._gifts.length; i++) {
      const curr = this._gifts[i];
      const currConfig = this._config.gifts[i];
      const index = i;

      
      if (curr.inside_dbl) { // if already doubled we reasign and re-create the dbl asset
        curr.inside = curr.inside_dbl;
      }

      curr.inside_dbl = null;
      if (this._config.onlyOneBox) {
        curr.inside_dbl = G.GiftUI.Elements.MultiGiftInside(this._config.gifts, curr);
      } else {
        curr.inside_dbl = G.GiftUI.Elements.GiftImageFromType(
          G.GiftUI.HelperFunctions.getGiftTypeFromString(currConfig[0]),
          currConfig,
          curr,
        );
      }
      curr.inside_dbl.scale.setTo(0.3);
      curr.inside_dbl.x = curr.inside.x;
      curr.inside_dbl.y = curr.inside.y;
      curr.inside_dbl.visible = false;

      // add shaking anim
      curr.inside.shouldShake = true;
      curr.insideExtraTxt.shouldShake = true;
      curr.inside._shakeStartPos = { x: curr.inside.x, y: curr.inside.y };
      curr.insideExtraTxt._shakeStartPos = {
        x: curr.insideExtraTxt.x,
        y: curr.insideExtraTxt.y,
      };
      curr.inside.update = function () {
        if (this.shouldShake) {
          this.x = this._shakeStartPos.x + (Math.random() * 3 - 1.5);
          this.y = this._shakeStartPos.y + (Math.random() * 3 - 1.5);
        }
      };
      curr.insideExtraTxt.update = curr.inside.update;

      game.add
        .tween(curr.insideExtraTxt.scale)
        .to({ x: 0.3, y: 0.3 }, 1150, Phaser.Easing.Quadratic.In, true);

      game.add
        .tween(curr.inside.scale)
        .to({ x: 0.3, y: 0.3 }, 1200, Phaser.Easing.Quadratic.In, true)
        .onComplete.add(() => {
          curr.inside.shouldShake = false;
          curr.insideExtraTxt.shouldShake = false;
          curr.insideExtraTxt.setText(
            this._config.customExtraTextDoubled
            || G.GiftUI.HelperFunctions.getGiftExtraText(currConfig, true),
          );
          curr.insideExtraTxt.scale.setTo(0.3);
          curr.inside.visible = false;
          curr.inside_dbl.visible = true;
          game.add
            .tween(curr.inside_dbl.scale)
            .to({ x: 1.2, y: 1.2 }, 450, Phaser.Easing.Elastic.Out, true);
          game.add
            .tween(curr.insideExtraTxt.scale)
            .to({ x: 1, y: 1 }, 450, Phaser.Easing.Elastic.Out, true)
            .onComplete.add(() => {
              // if last
              if (index === this._gifts.length - 1) {
                this._onDoubleAnimationComplete();
              }
            });
        });
    }
  }

  /**
   * on double / triple reward animation complete
   */
  _onDoubleAnimationComplete() {
    if (this._allowTripleReward && this._rewardMulti < 3) {
      this.claimAllBtn_Premium.setText(OMT.language.getText('Triple Reward'));
      this._fadeInButton(this.claimAllBtn_Premium);
      this._fadeInButton(this.noThanksBtn);
    } else {
      this.claimAllBtn_Premium.visible = false;
      this.noThanksBtn.visible = false;
      this._fadeInButton(this.claimAllBtn);
    }
  }

  /**
   * fade in claim / no thanks buttons.
   * @param {Phaser.Sprite} btn 
   */
  _fadeInButton(btn) {
    btn.alpha = 0; btn.visible = true;
    game.add
      .tween(btn)
      .to({ alpha: 1 }, 450, Phaser.Easing.Quadratic.In, true);
  }

  /**
   * premium claim all
   */
  _premiumBtnClaimAllClicked() {
    const onAdSuccess = () => {
      this._adSuccess();
    };

    const onAdFail = () => {
      if (!G.IAP) { // no IAPS dont use ad fallbacks
        // eslint-disable-next-line no-new
        new Window_FillRateFallback(undefined, {
          placement: this._config.placement || G.BuildEnvironment.adPlacements.claimAllGifts,
          callback: onAdSuccess,
          context: this,
        });
      } else { // show ad fallback
        OMT.ads.showAdFallback(onAdSuccess);
      }
    };

    OMT.ads.showAd(
      this._config.placement || G.BuildEnvironment.adPlacements.claimAllGifts,
      onAdSuccess,
      onAdFail,
    );
  }

  /**
   * show open to claim all prizes
   */
  _showOptionToClaimAll() {
    if (this._config.monetiseType === G.GiftUI.Constants.MonetiseOptions.DoubleReward) {
      this._animations.btnFadeIn.animate(game, this.claimAllBtn_Premium);
      this._animations.btnFadeIn.animate(game, this.noThanksBtn);
    } else if (!this.claimAllBtn.visible) {
      this._animations.btnFadeIn.animate(game, this.claimAllBtn);
    }

    if (
      this._config.shareable
      && G.GiftUI.HelperFunctions.canShare(this._config)
      && this.noThanksBtn.visible === false
    ) {
      this._animations.btnFadeIn.animate(game, this.shareBoosterBtn);
    }

    if (this._config.monetiseType === G.GiftUI.Constants.MonetiseOptions.OpenOtherGifts) {
      if (G.BuildEnvironment.production && !OMT.feature.rewardedAdsAvailable()) {
        for (let i = 0; i < this._gifts.length; i++) {
          const curr = this._gifts[i];

          if (curr.gift.opened || curr._premiumOpenOtherBtn) continue;
          game.add
            .tween(curr)
            .to(
              { alpha: 0 },
              400,
              Phaser.Easing.Sinusoidal.Out,
              true,
            )
            .onComplete.add(function () {
              this.visible = false;
            },
            curr);
        }
      } else {
        // show buttons on unopened gifts
        for (let i = 0; i < this._gifts.length; i++) {
          if (this._gifts[i].gift.opened || this._gifts[i]._premiumOpenOtherBtn) continue;

          this._gifts[i].gift.inputEnabled = false;
          this._numberOfOpenedPremiumGifts = 0;
    
          const onClick = () => {
            this._premiumBtnClaimGiftClicked(i);
          };
          const icon = G.makeImage(-50, 0, 'btn-movie-icon', 0.5, null);
          const butt = new OMT_UI_SquareButton(0, 115, {
            button: {
              tint: BUTTONCOLOURS.purple,
              dimensions: {
                width: 235,
                height: 120,
              },
            },
            text: {
              string: OMT.language.getText('Open'),
              textStyle: {
                style: 'font-white',
                fontSize: 45,
              },
              icon: {
                image: icon,
                offset: {
                  x: icon.width / 2,
                },
                align: 'left',
              },
            },
            options: {
              clickFunction: {
                onClick: onClick.bind(this),
              },
            },
          });
          butt._index = i;
          this._gifts[i]._premiumOpenOtherBtn = butt;
          this._gifts[i].add(butt);

          this._animations.btnFadeIn.animate(game, butt);
        }
      }
    }

    if (this._isAchievement) {
      this._shareUI.visible = true;
      // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('seenAchievementGiftShareBtn', 1);

      if (this.noThanksBtn.visible === false) {
        this._animations.AtoB.animate(game, this._shareUI, this.noThanksBtn);
      }
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.gift = Window_Gift;
