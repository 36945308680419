import { ORIENTATION } from '../../../../Services/OMT/OMT_SystemInfo';
import Light from './Light';
import { LIGHTS_MODES, LIGHT_STYLE } from './LightsConfig';

// Class for adding and managing a flashing light border to a window
export default class LightsBorder extends Phaser.Group {
  /**
   * Constructor
   * @param {number} x
   * @param {number} y
   */
  constructor(x, y) {
    super(game, null);
    this.x = x;
    this.y = y;

    this._lightsMode = LIGHTS_MODES.Alternating;
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._init();
  }

  /**
   * Init
   */
  _init() {
    const borderX = this._isLandscape ? -760 : -280;
    const borderY = -255;
    const lightAppearanceInterval = 50;
    const lightSpacing = 80;
    const numLightsVertical = 11;
    const numLightsHorizontal = this._isLandscape ? 18 : 6;
    let isOn = true;
    let lightAppearanceDelay = this._isLandscape ? 300 : 500;
    let startX;
    let startY;

    // Light Border
    // Left
    startX = borderX;
    startY = borderY + (numLightsVertical - 1) * lightSpacing;

    for (let i = 0; i < numLightsVertical; i++) {
      const newLight = new Light(startX, startY - i * lightSpacing, isOn, LIGHT_STYLE.NORMAL);
      newLight.startAnim(0);
      newLight.alpha = 0;
      game.add.tween(newLight).to({ alpha: 1 }, 500, Phaser.Easing.Cubic.Out, true, lightAppearanceDelay);
      isOn = !isOn;
      lightAppearanceDelay += lightAppearanceInterval;
      this.add(newLight);
    }

    // Top
    startX = borderX + lightSpacing;
    startY = borderY;

    for (let i = 0; i < numLightsHorizontal; i++) {
      const newLight = new Light(startX + i * lightSpacing, startY, isOn, LIGHT_STYLE.NORMAL);
      newLight.startAnim(0);
      newLight.alpha = 0;
      game.add.tween(newLight).to({ alpha: 1 }, 500, Phaser.Easing.Cubic.Out, true, lightAppearanceDelay);
      isOn = !isOn;
      lightAppearanceDelay += lightAppearanceInterval;
      this.add(newLight);
    }

    // Right
    startX = borderX + (numLightsHorizontal + 1) * lightSpacing;
    startY = borderY;

    for (let i = 0; i < numLightsVertical; i++) {
      const newLight = new Light(startX, startY + i * lightSpacing, isOn, LIGHT_STYLE.NORMAL);
      newLight.startAnim(0);
      newLight.alpha = 0;
      game.add.tween(newLight).to({ alpha: 1 }, 500, Phaser.Easing.Cubic.Out, true, lightAppearanceDelay);
      isOn = !isOn;
      lightAppearanceDelay += lightAppearanceInterval;
      this.add(newLight);
    }

    // Bottom
    startX = borderX + (numLightsHorizontal) * lightSpacing;
    startY = borderY + (numLightsVertical - 1) * lightSpacing;

    for (let i = 0; i < numLightsHorizontal; i++) {
      const newLight = new Light(startX - i * 80, startY, isOn, LIGHT_STYLE.NORMAL);
      newLight.startAnim(0);
      newLight.alpha = 0;
      game.add.tween(newLight).to({ alpha: 1 }, 500, Phaser.Easing.Cubic.Out, true, lightAppearanceDelay);
      isOn = !isOn;
      lightAppearanceDelay += lightAppearanceInterval;
      this.add(newLight);
    }
  }

  /**
   * Changes the behaviour of the border lights
   * @param {LIGHTS_MODES} mode
   */
  changeLightsMode(mode) {
    // If the new mode is the same as the current mode,
    // exit this function to prevent jerky, discontinuous animations
    if (mode === this._lightsMode) return;
    this._lightsMode = mode;

    switch (mode) {
      case LIGHTS_MODES.Off:
        for (const light of this.children) {
          light.stopAnim(false);
          light.setBlinkLimit(-1);
          light.setOnOffTime(220, 220);
          light.setLooping(false);
        }
        break;

      case LIGHTS_MODES.On:
        for (const light of this.children) {
          light.stopAnim(true);
          light.setBlinkLimit(-1);
          light.setOnOffTime(220, 220);
          light.setLooping(false);
        }
        break;

      case LIGHTS_MODES.Single: {
        let i = 26;
        let done = false;
        let delay = 0;

        while (!done) {
          const light = this.children[i];
          light.stopAnim(true);
          light.setBlinkLimit(1);
          light.setLooping(true);
          light.setOnOffTime(250, 250);
          light.startAnim(delay, true);
          delay += 50;

          if (i === 0) {
            i = this.children.length - 1;
          } else {
            i--;
          }

          if (i === 26) {
            done = true;
          }
        }
        break;
      }

      case LIGHTS_MODES.Jackpot:
        for (const light of this.children) {
          light.setState(false);
          light.setBlinkLimit(-1);
          light.setLooping(true);
          light.setOnOffTime(220, 220);
          light.startAnim(0, true);
        }
        break;

      case LIGHTS_MODES.Alternating: {
        let isOn = true;
        for (const light of this.children) {
          light.setState(isOn);
          light.setBlinkLimit(-1);
          light.setLooping(true);
          light.setOnOffTime(220, 220);
          light.startAnim(0, true);
          isOn = !isOn;
        }
        break;
      }

      default:
    }
  }

  changeLightsStyle(style) {
    for (const light of this.children) {
      light.changeStyle(style);
    }
  }

  get lightsMode() {
    return this._lightsMode;
  }
}
