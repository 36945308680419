/* eslint-disable no-use-before-define */
import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { GiftGraphic } from './GiftUI/GiftGraphic';

class Window_TreasureHuntEvent extends Window {
  constructor(parent) {
    super(parent);

    this.treasureHuntMgr = G.TreasureHunt.treasureHuntMgr;
    this.generalEventInfo = this.treasureHuntMgr.getGeneralEventInfo();

    this._initLayout();

    G.sb('hideMainHighscorePanel').dispatch();
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.TreasureHuntPanelVisible);
  }

  /**
   * Creating and positioning layout elements.
   */
  _initLayout() {
    const buttonHeight = 100;
    // Settings for vertical or horizontal mode
    const treasureHuntDimensions = G.OMTsettingsDefault.elements.Window_treasureHuntEvent.bgSize[OMT.systemInfo.orientationKey];
    const buttonY = OMT.systemInfo.orientation === ORIENTATION.vertical ? 320 : (treasureHuntDimensions.height) / 2;

    // the window and backdrop image
    const bg = this.addGeneric9SliceBackground(treasureHuntDimensions.width, treasureHuntDimensions.height);
    bg.y = 90; // offset because the mascot and treasure chest should be above the window

    const headerGroup = game.add.group(this);
    G.makeImage(-64, -205, 'Cookie_Pirate_Header', [0.5, 1], headerGroup);
    G.makeImage(0, -177, 'Treasure_Hunt_header', 0.5, headerGroup);
    headerGroup.add(new G.Text(0, -177, OMT.language.getText('Treasure Hunt'), {
      style: 'font-white',
      fontSize: 60,
    }, 0.5, 310));
    G.makeImage(76, -294, 'Chest_Full', 0.5, headerGroup);

    this.add(new G.Button(253, -196, 'btn_x', this.closePopUp, this));

    const clock = this.add(new G.CommonObjects.ClockTimer(0, -70));
    clock.setEndDate(Date.now() + this.generalEventInfo.duration);

    // adding content below the clock
    const pageGroup = game.add.group(this);

    pageGroup.unlockTxt = pageGroup.add(new G.Text(0, 0,
      OMT.language.getText('You unlocked a special event!'), {
        style: 'font-blue',
        fontSize: 40,
      }, 0.5, 520));

    let desc = OMT.language.getText('Open [AMOUNT] Treasure Chests in [DAYS_NR] days to get a Cookie Chest!');
    desc = desc.replace('[AMOUNT]', this.generalEventInfo.gemsTarget);
    desc = desc.replace('[DAYS_NR]', this.generalEventInfo.durationDays);

    pageGroup.descTxt = pageGroup.add(new G.Text(0, 70, desc, {
      style: 'font-blue',
      fontSize: 25,
    }, 0.5, 450, 100, true, 'center'));

    pageGroup.gem = G.makeImage(-160, 180, 'gem5_small', 0.5, pageGroup);
    pageGroup.arrow1 = G.makeImage(-80, 180, 'arrow_right', 0.5, pageGroup);
    pageGroup.treasureChest = G.makeImage(0, 180, 'Chest_Full', 0.5, pageGroup);
    pageGroup.treasureChest.scale.setTo(0.65);
    pageGroup.arrow2 = G.makeImage(80, 180, 'arrow_right', 0.5, pageGroup);
    pageGroup.chest = new GiftGraphic(180, 180, { giftType: 1, useFullGift: true }, pageGroup);
    // pageGroup.chest = G.makeImage(160, 180, 'prezent', 0.5, pageGroup);
    pageGroup.chest.scale.setTo(0.65);
    G.centerElements([
      pageGroup.gem,
      pageGroup.arrow1,
      pageGroup.treasureChest,
      pageGroup.arrow2,
      pageGroup.chest,
    ], 10);

    // big play button
    const button = new OMT_UI_SquareButton(0, buttonY, {
      button: {
        tint: BUTTONCOLOURS.purple,
        dimensions: {
          width: 309,
          height: buttonHeight,
        },
      },
      text: {
        string: OMT.language.getText('Play'),
        textStyle: { style: 'font-white', fontSize: 64 },
      },
      options: {
        clickFunction: {
          onClick: this.onBtnClicked.bind(this),
        },
      },
    });
    this.add(button);

    const windowPanel = this.add(
      new G.TreasureHunt.TreasureHuntWindowPanel({
        tutorial: true,
      }),
    );
    windowPanel.y = 445;
  }

  /**
   * Start the level, or notify the user that they dont have enough lives to start the level.
   */
  async onBtnClicked() {
    console.log('treasure hunt window');

    // Show treasure hunt window
    if (this.treasureHuntMgr.isEventReadyToStart()) {
      this.treasureHuntMgr.startEvent();
    }
    // Close this pop-up
    this.closePopUp();
    const lvlIndex = this.treasureHuntMgr.getTreasureHuntLevelIndex();
    const worldMap = game.state.getCurrentState().map; // WorldMap2.js
    const nextGate = G.saveState.gateManager.getNextGateLevel();
    if (G.saveState.gateManager.isLevelPotentiallyAGate(lvlIndex) && lvlIndex >= nextGate && !G.saveState.gateManager.hasGateBeenOpened(lvlIndex)) {
      // Close side panel if open
      if (game.state.getCurrentState().worldSidePanel.getSidePanelState()) {
        game.state.getCurrentState().worldSidePanel.toggleSidePanel();
      }
      await worldMap.panToLevelNode(lvlIndex);
      const unlockStatus = await worldMap.externallyOpenGate();
      if (!unlockStatus) {
        return;
      }
    }

    if (G.saveState.getLives() === 0 && G.saveState.getUnlimitedLivesSec() === 0) {
      G.sb('pushWindow').dispatch(['notEnoughLives'], false, G.WindowMgr.LayerNames.AboveHighScorePanel);
    } else {
      if (lvlIndex !== null) { // eslint-disable-line no-lonely-if
        G.sb('pushWindow').dispatch([
          'level', lvlIndex,
        ]);
      }
    }
  }

  // Helper function for opening new gate window to avoid "no-loop-func" ESLint rule
  _openGateWindow(gateObject, gateInstance, gateId, showTreasureHuntWindow) {
    G.sb('pushWindow').dispatch(['gateNew', {
      gateObject,
      onOpenCallback: () => {
        gateInstance.unlock();
        gateInstance.onOpen.dispatch(gateId, showTreasureHuntWindow);
      },
    }]);
  };

  closePopUp() {
    // TODO: avoid that, mediator should take care
    G.sb('showMainHighscorePanel').dispatch();
    this.closeWindow();
  }
}
// create global references
if (!window.Windows) window.Windows = {};
Windows.treasureHuntEvent = Window_TreasureHuntEvent;
