/* eslint-disable object-curly-newline */

const DEFAULT_CONFIG = {
  offsetX: 47,
  offsetY: 33,
  textAngle: -27,
  textStyle: 'shop3-discountTag',
  textBaselineShift: 2,
  tagAsset: 'shop3_discount',
};

/**
 * tag width discount % text
 */
export default class Shop3_DiscountTag extends Phaser.Group {
  /**
   * constructor
   * @param {number} discountPercent integer of discount % to display
   * @param {Object} config (optional) see DEFAULT_CONFIG for assignable values.
   */
  constructor(discountPercent, config = {}) {
    super(game);

    this._config = { ...DEFAULT_CONFIG, ...config };

    this._initTagBackground();
    this._initTextField(discountPercent);
  }

  /**
   * initialize the tag background. image should be center aligned.
   */
  _initTagBackground() {
    const { tagAsset, offsetX, offsetY } = this._config;
    const tagImage = G.makeImage(0, 0, tagAsset, 0.5);
    tagImage.x = offsetX; tagImage.y = offsetY;
    this.addChildAt(tagImage, 0);
  }

  /**
   * initialize the tag text field for the discount %
   * @param {number} discountPercent
   */
  _initTextField(discountPercent) {
    const { textStyle, textAngle, textBaselineShift, offsetX, offsetY } = this._config;
    const tagTextStyle = G.Text.getStyle(textStyle);
    const tagText = `-${discountPercent}%`;
    const tagField = new G.Text(0, 0, tagText, tagTextStyle, 0.5);
    tagField.x = offsetX;
    tagField.y = offsetY + textBaselineShift;
    tagField.angle = textAngle;
    this.addChild(tagField);
  }
}
