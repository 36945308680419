import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';
import UI_ProgressBarBase from '../base/UI_ProgressBarBase';

export default class UI_ProgressBar extends UI_ProgressBarBase {
  /**
   * This is the progress bar seen on the top bar in the game
   * Its like a n shape and marks your points progress
   * @param {number} x
   * @param {number} y
   * @param {PROGRESS_MARKER_TYPE} markerType
   */
  constructor(x, y, markerType) {
    super(x, y, markerType, 36, 1);
  }

  /**
   * Returns asset name of the progress bar. Overwritten
   * @returns {string}
   */
  _getBarProgressImage() {
    if (LvlDataManager.getInstance().isTreasureHuntLevel && G.OMTsettings.treasureHuntSuper.gameBoard.progressBar) {
      return G.OMTsettings.treasureHuntSuper.gameBoard.progressBar;
    }
    return 'ingame_progress_bar';
  }

  /**
   * Draws the radius for the mask
   * @returns {number}
   */
  _getMaskRadius() {
    return 100;
  }

  /**
   * Returns the distance between the center and the stars
   * @returns {number}
   */
  _getStarsDistance() {
    return 90;
  }
}
