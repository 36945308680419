/**
 * Particle instance class
 */
export class FX_Particle extends Phaser.Sprite {
  /**
   * constructor
   */
  constructor(spritesheet, frame) {
    super(game, 0, 0, spritesheet, frame);
    this.anchor.set(0.5, 0.5);
    this.velocity = new Phaser.Point(0, 0);
  }

  /**
   * initialize / reintialize the particle
   * @param {string} frame spritesheet frame
   * @param {boolean} allowTransforms allow transformations ( rotation / scale )
   * @param {number} pLife particle life ms
   * @param {number} pScale particle scale
   * @param {number} xOffset emitter 0,0 offset x
   * @param {number} yOffset emitter 0,0 offset y
   * @param {number} velX velocity x per sec
   * @param {number} velY velocity y per sec
   * @param {number} rotation rotation radians
   * @param {number} fadeRate alpha fade rate per sec
   */
  init(frame, allowTransforms, pLife, pScale, xOffset, yOffset, velX, velY, rotation, fadeRate) {
    this.frameName = frame;

    this.alpha = 0;
    this.x = xOffset; this.y = yOffset;

    this._fadeRate = fadeRate;
    this._pTime = 0;
    this._pLife = pLife;

    this.velocity.x = velX;
    this.velocity.y = velY;

    if (allowTransforms) {
      this.scale.set(pScale);
      this.rotation = rotation;
    }
  }

  /**
   * update loop
   * @param {number} dtMs delta time ms
   * @param {number} dtSec delta time secs
   */
  update(dtMs, dtSec) {
    this._pTime += dtMs;

    this.x += dtSec * this.velocity.x;
    this.y += dtSec * this.velocity.y;

    // fade in / out particle
    if (this._pTime > this._pLife) {
      this.alpha -= dtSec * this._fadeRate;
      if (this.alpha < 0) this.alpha = 0;
    } else if (this.alpha < 1) {
      this.alpha += dtSec * this._fadeRate;
      if (this.alpha > 1) this.alpha = 1;
    }
  }

  /**
   * check if the particle is expired and faded out
   * @returns {boolean}
   */
  isExpired() {
    return this._pTime > this._pLife && this.alpha <= 0;
  }
}
