import GingyCharacterTutorial, { CHARACTER_KEYS } from '../GingyTutorial/G.GingyCharacterTutorial';

export class EventGingyFail extends Phaser.Group {
  constructor(x) {
    super(game, null);

    this.x = x;
    this._group = new Phaser.Group(game, this);
    this._initGingy();
    this._initSpeechBubble();
    this._group.y = this._gingy.height;

    this._onResize();
    this._onResizeSB = G.sb('onScreenResize').add(this._onResize, this);
  }

  _initGingy() {
    this._gingy = new GingyCharacterTutorial(0, 0, CHARACTER_KEYS.gingy);
    this._gingy.scale.setTo(0.75);
    this._gingy.surprised();
    this._gingy.lookAt({ x: 0.75, y: -1 });
    this._group.add(this._gingy);
  }

  /**
   * draw the bubble background
   */
  _initSpeechBubble() {
    this._speechBubble = new Phaser.Group(game);
    this._speechBubble.x = 90;
    this._speechBubble.y = -132;
    this._speechBubble.alpha = 0;
    this._group.add(this._speechBubble);

    const bgGraphics = new Phaser.Graphics(game);
    bgGraphics.beginFill(0xffffff);
    bgGraphics.drawRoundedRect(0, 0, 410, 120, 30);
    bgGraphics.endFill();
    this._speechBubble.add(bgGraphics);

    const textStr = OMT.language.getText("Don't lose all your %CURRENCY%!").replace('%CURRENCY%', OMT.language.getText('Pumpkins'));
    const textField = new G.Text(205, 60, textStr, 'window_popupOffer-mascotTxt', 0.5, 390, 100, true, 'center');
    this._speechBubble.add(textField);
  }

  _onResize() {
    this.y = game.height;
  }

  show() {
    this._group.y = this._gingy.height;
    this._speechBubble.alpha = 0;
    game.add.tween(this._group).to({ y: 0 }, 200, Phaser.Easing.Quadratic.Out, true);
    game.add.tween(this._speechBubble).to({ alpha: 1 }, 200, Phaser.Easing.Linear.None, true);
  }

  hide() {
    this._group.y = 0;
    this._speechBubble.alpha = 1;
    game.add.tween(this._speechBubble).to({ alpha: 0 }, 200, Phaser.Easing.Linear.None, true);
    game.add.tween(this._group).to({ y: this._gingy.height }, 200, Phaser.Easing.Quadratic.Out, true);
  }

  destroy() {
    super.destroy();
    this._onResizeSB.detach();
  }
}
