/* eslint-disable func-names */
const { ACTION_TYPES } = require('../../../Board/Actions/Action');

G.BoosterVertical = function (board, cellX, cellY, nr) {
  this.board = board;
  this.am = this.board.actionManager;
  this.cellX = cellX;
  this.cellY = cellY;

  Phaser.Image.call(
    this,
    game,
    this.board.cellXToPxIn(cellX),
    this.board.cellYToPxIn(-0.5),
  );

  this.anchor.setTo(0.5);

  this.boosterNr = nr;

  this.oldCellY = -1;

  this.orgY = this.y;
  this.targetY = this.board.cellYToPxIn(this.board.boardGridData.height) + 30;

  G.changeTexture(this, `ui_booster_${nr}`);
  this.alpha = 0;
  this.scale.setTo(2);

  game.add.tween(this.scale).to({ x: 1, y: 1 }, 1000, Phaser.Easing.Sinusoidal.Out, true);
  game.add.tween(this).to({ alpha: 1 }, 1000, Phaser.Easing.Sinusoidal.Out, true).onComplete.add(function () {
    this.active = true;
  }, this);
};

G.BoosterVertical.prototype = Object.create(Phaser.Image.prototype);

G.BoosterVertical.prototype.getMatchCandy = function (nr) {
  if (nr === 2) return { cellX: this.cellX, cellY: this.cellY, exe: [['specific', [0, 0]]] };
  if (nr === 3) return { cellX: this.board.boardGridData.width - 1, cellY: this.cellY, exe: [['loop', { x: -1, y: 0 }], ['loop', { x: 1, y: 0 }]] };
  if (nr === 4) return { cellX: this.cellX, cellY: this.board.boardGridData.height - 1, exe: [['loop', { x: 0, y: -1 }], ['loop', { x: 0, y: 1 }]] };
  return null;
};

G.BoosterVertical.prototype.update = function () {
  if (!this.active) return;

  this.y += 10 * G.deltaTime;

  const cellY = this.board.pxInToCellY(this.y);

  if (cellY !== this.oldCellY) {
    this.oldCellY = cellY;
    this.board.useBoosterOnCell(this.cellX, cellY - 1);
  }

  if (this.y >= this.targetY && !this.board.candiesAreAnimating) {
    this.active = false;
    if (this.board.checkSpecialMatchList.length === 0) {
      this.am.newAction(ACTION_TYPES.PROCESS_FALL);
    } else {
      this.am.newAction(ACTION_TYPES.PROCESS_MATCH);
    }

    G.sb('onBoosterActionFinished').dispatch();
    this.am.removeAction();
    this.destroy();
  }
};
