import UI_AnimationGroup from '@omt-components/UI/Animation/UI_AnimationGroup';
import FrameAnimationController from '@omt-components/Utils/Animation/FrameAnimationController';
import OMT_UI_SquareButton, { BUTTONCOLOURS, BUTTONSTATE } from '../../OMT_UI/OMT_UI_SquareButton';
import OMT_UI_AvatarWithBadge from '../../OMT_UI/FriendsList/OMT_UI_AvatarWithBadge';
import { DATA_KEYS } from '../../Services/SaveState/SaveStateKeyMappings';
import TreasureHuntLeaderboard_Util from './Leaderboard/TreasureHuntLeaderboard_Util';
import TreasureHuntPodiumUserView from './Leaderboard/TreasureHuntPodiumUserView';
import { SagaMap_Tutorial } from '../GingyTutorial/SagaMap_Tutorial';
import TreasureHuntManager from '../../Services/OMT/dataTracking/treasureHuntManager/treasureHuntManager';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../States/Scaling/GameScaleController';

export default class TreasureHuntPodiumGroup extends UI_AnimationGroup {
  /**
   * An animation group for the podium. Used with the prize and prize window because of the transition
   * between the two is really quick.
   * @param {DisplayObject} inParent
   * @param {{closeWindow:function}} config
   */
  constructor(inParent, config) {
    super(game, inParent);

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this._closeWindow = config.closeWindow;
    this._badgeAnimationTweenTime = 1000;
    this.visible = false;
  }

  /**
   * Called externally, because the wait is important
   */
  async init() {
    // await this._readyPodium();
  }

  /**
   * Draws the podium and other assets
   */
  async _readyPodium() {
    const previousLeaderboard = G.saveState.treasureHuntManager.lastExpiredLeaderboard;
    const leaderboardId = previousLeaderboard ? previousLeaderboard.id : G.saveState.getTierLeaderboardData(DATA_KEYS.TREASURE_HUNT).instanceId;
    // Grab all of them first, because we need your entry to be there, if theres ties
    const topThree = await TreasureHuntLeaderboard_Util.getLeaderboardEntries(DATA_KEYS.TREASURE_HUNT, false, leaderboardId, 50, false);
    topThree.splice(3);
    this._yourEntry = await OMT.tierLeaderboard.getYourEntry({ storageKey: DATA_KEYS.TREASURE_HUNT, leaderboardId });
    this._podiumContainer = new Phaser.Group(game, this);
    this._podiumContainer.y -= 250;
    this._podiumImageString = 'treasureHuntPodium';
    this._podiumImage = G.makeImage(0, 0, `${this._podiumImageString}12`, 0.5, this._podiumContainer);
    this._podiumController = new FrameAnimationController();

    this._playerIsTopThree = false;
    this._rankingPlayers = [];
    this._tokenCounters = [];
    for (let i = 0; i < topThree.length; i++) {
      const player = topThree[i];
      this._tokenCounters.push(this._makeTokenCounter(player.score, i));
      if (player.userId === OMT.envData.settings.user.userId) {
        this._playerIsTopThree = {
          rank: i,
          player,
        };
      }
      this._makeUserBadge(player.image, player.userbadge, i);
    }

    this._gingyTutorial = new SagaMap_Tutorial({ game, scale: 1, character: `treasureHunt${TreasureHuntManager.getMascotName(previousLeaderboard.cw)}` });
    this._gingyTutorial.visible = false;
    this._gingyTutorial.toggleVisibility(false);

    G.saveState.treasureHuntManager.leaderboardPlacementIsSeen();
    G.sb('forceTreasureHuntIconRefresh').dispatch();
  }

  /**
   * Makes the user avatar with badge asset.
   * @param {string} img
   * @param {string} userBadge
   * @returns {OMT_UI_AvatarWithBadge}
   */
  _makeUserBadge(img, userBadge, rank) {
    const avatar = new OMT_UI_AvatarWithBadge(img, userBadge, 100);
    avatar.x = G.OMTsettings.treasureHuntSuper.podium.positions[rank].x;
    avatar.alpha = 0;
    this._podiumContainer.addChild(avatar);
    this._rankingPlayers.push({
      img: avatar,
      destination: { ...G.OMTsettings.treasureHuntSuper.podium.positions[rank] },
      rank,
    });
    return avatar;
  }

  /**
   * Draws the tokens underneath the player on the podium
   * @param {number} score
   * @param {number} rank
   */
  _makeTokenCounter(score, rank) {
    const positionData = G.OMTsettings.treasureHuntSuper.podium.tokenPos[rank];
    const counterGroup = new Phaser.Group(game, this._podiumContainer);
    counterGroup.position.set(positionData.x, positionData.y);
    const counterBg = G.makeImage(0, 0, 'treasureHunt_gameCounterBg', 0.5, counterGroup);
    counterBg.scale.set(125 / counterBg.width);
    this._counterText = new G.Text(5, 2, `x${score}`, 'treasureHunt-gameBoardCounter', 0.5, counterBg.width * 0.6);
    counterGroup.addChild(this._counterText);
    const token = G.makeImage(0, 0, 'treasureHunt_token', 0.5, counterGroup);
    token.x = -counterBg.width / 2;
    counterGroup.alpha = 0;
    return counterGroup;
  }

  /**
   * Called from the Prize Group after connecting to it.
   * Readies the extra asset too.
   * @param {string} badge
   */
  gainedBadge(badge) {
    this._gainedBadge = badge;
    const playerData = this._playerIsTopThree;
    this._glow = G.makeImage(0, 0, 'shine_godrays', 0.5, this._podiumContainer);
    this._glow.scale.set(300 / this._glow.height);
    this._glow.alpha = 0;
    const oldBadge = this._rankingPlayers.find((a) => a.rank === playerData.rank);
    if (oldBadge) {
      this._rankingPlayers.splice(this._rankingPlayers.indexOf(oldBadge), 1);
      oldBadge.img.destroy();
    }
    const avatar = this._makeUserBadge(playerData.player.image, badge, playerData.rank);
    this._glow.follow = avatar;
    this._rankingPlayers.sort((a, b) => a.rank - b.rank);
    this.readyExtraAsset();
  }

  /**
   * Extra assets under the podium.
   * Prize window can will this if the prize group didn't
   * Draws the cool button, then determines whats the extra asset to draw
   */
  readyExtraAsset() {
    const extraAssetYPos = 300;
    this._coolButton = new OMT_UI_SquareButton(0, extraAssetYPos + 125, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 254,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Cool'),
        textStyle: {
          style: 'font-white',
          fontSize: 70,
        },
        dimensionMods: {
          width: 0.9,
          height: 0.9,
        },
      },
      options: {
        clickFunction: {
          onClick: this._onCoolClicked.bind(this),
          disableAfterClick: true,
        },
        cacheButton: false,
      },
    });
    this.addChild(this._coolButton);
    this._coolButton.alpha = 0;
    if (!this._yourEntry) { return; }
    if (this._gainedBadge) {
      this._extraAsset = new OMT_UI_SquareButton(0, extraAssetYPos, {
        button: {
          tint: BUTTONCOLOURS.orange,
          dimensions: {
            width: 254,
            height: 100,
          },
        },
        text: {
          string: OMT.language.getText('Share'),
          textStyle: {
            style: 'font-white',
            fontSize: 70,
          },
          dimensionMods: {
            width: 0.9,
            height: 0.9,
          },
        },
        options: {
          clickFunction: {
            onClick: this._onBragClicked.bind(this),
            disableAfterClick: true,
          },
        },
      });
    } else {
      this._extraAsset = new TreasureHuntPodiumUserView({
        id: this._yourEntry.userId,
        rank: this._yourEntry.rank,
        name: this._yourEntry.username,
        score: this._yourEntry.score,
      }, this._mergeUserView());
      this._extraAsset.y = extraAssetYPos;
      this._extraAsset.x -= this._extraAsset.width / 2;
    }
    this.addChild(this._extraAsset);
    this._extraAsset.alpha = 0;
  }

  /**
   * Returns a big object that merges the settings with the default
   * @returns {Object}
   */
  _mergeUserView() {
    return _.merge({ // Copied from Window_SpecialEventLeaderboard's DEFAULT_CONFIG
      icon: {
        asset: 'star',
        size: 70,
        offsetX: 10,
        offsetY: -4,
      },
      rankField: {
        fontStyle: {},
      },
      usernameField: {
        fontStylePlayer: {},
        fontStyleOther: {},
      },
      scoreField: {
        fontStyle: {},
      },
      avatar: {},
    }, G.OMTsettings.treasureHuntSuper.leaderboardConfig.panel.userView);
  }

  /**
   * Animates the podium stuff
   */
  async animate() {
    // Move your badge away
    this.visible = true;
    await this._podiumController.asyncInit(this._podiumImage, this._podiumImageString, null, 14 / 24, 0, 0);
    for (let i = this._rankingPlayers.length - 1; i > -1; i--) {
      const player = this._rankingPlayers[i];
      player.img.x = player.destination.x;
      player.img.y = player.destination.y - 200;
      this.wrapTween(this._tokenCounters[i], { alpha: 1 }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.In);
      this.wrapTween(player.img, { alpha: 1 }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.In);
      if (this._glow && this._glow.follow === player.img) {
        this.wrapTween(this._glow, { alpha: 1 }, this._badgeAnimationTweenTime, Phaser.Easing.Sinusoidal.In);
        this.wrapTween(this._glow, { x: player.destination.x, y: player.destination.y }, this._badgeAnimationTweenTime, Phaser.Easing.Elastic.Out); // eslint-disable-line no-await-in-loop
      }
      if (i === 2) {
        G.sfx.xylophone_positive_12.play();
      }
      await this.wrapTween(player.img, { x: player.destination.x, y: player.destination.y }, this._badgeAnimationTweenTime, Phaser.Easing.Elastic.Out); // eslint-disable-line no-await-in-loop
    }
    await this.wait(this._badgeAnimationTweenTime);
    this._gingyTutorial.displayMsg(G.json.tutorials.treasureHunt.seeYou);
    this._gingyTutorial.visible = true;
    this._coolButton.currentState = BUTTONSTATE.ENABLED;
    this.wrapTween(this._extraAsset, { alpha: 1 }, this._badgeAnimationTweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
    await this.wrapTween(this._coolButton, { alpha: 1 }, this._badgeAnimationTweenTime / 2, Phaser.Easing.Sinusoidal.InOut);
    this._coolButton.alpha = 1;
    this._extraAsset.alpha = 1;
  }

  /**
   * When the brag button is clicked
   */
  async _onBragClicked() {
    const result = await OMT.social.shareTreasureHuntBrag(this._gainedBadge, this._playerIsTopThree.player.score);
    if (result) {
      // DDNA.tracking.getDataCapture().setPlayerCharacterizationParam('usedPostcardShareBtn', 1);
      this._closeView();
    } else {
      this._extraAsset.currentState = BUTTONSTATE.ENABLED;
    }
  }

  /**
   * When the cool button is clicked
   */
  _onCoolClicked() {
    this._closeView();
  }

  /**
   * Close the window and gingy
   */
  _closeView() {
    if (this._gingyTutorial) {
      this._gingyTutorial.close();
    }
    this._closeWindow();
  }

  /**
   * Update!
   */
  update() {
    super.update();
    if (this._podiumController) {
      this._podiumController.update();
    }

    if (this._glow && this._glow.alpha !== 0) {
      this._glow.angle += 0.05;
    }
  }
}
