/* eslint-disable no-param-reassign */
import { FRIENDSHIPCHESTCARD_STATUS } from './FriendshipChest_Card'; // Friendship card states are explained over here

export const FRIENDSHIP_CHEST_SAVE_KEY = 'friendshipChestData';

/**
 * This is the data manager for the friendship chest.
 * It modifies the data object given by SaveState.
 */
export class FriendshipChest_DataManager {
  static getBrandNewData() {
    return {
      cards: [{ i: 0, s: FRIENDSHIPCHESTCARD_STATUS.OPEN, pi: 0 }],
      friends: {
        pending: [],
        claimed: [],
      },
      promoAppearance: {
        initial: false,
        levelsToAppear: 0,
      },
      wasInvited: null,
      numberOpened: 0,
      tutorial: {
        started: false,
        requestFinished: false,
        ended: false,
      },
    };
  }

  constructor() {
    this.externalSave = new Phaser.Signal();
  }

  /**
   * {
   *  cards: {
   *    data: Array<{
   *      i(ndex): number, // The visual index
   *      s(tatus): number, // The status of the card
   *      p(rize)i(ndex): number // The prize index of the card
   *    }>,
   *    friends: Array<string>
   *  },
   *  promoAppearance: {
   *    initial: boolean,  //AKA Has the promotional card been seen once yet?
   *    levelsToAppear: number
   *  },
      wasInvited : string,
      numberOpened: number
   * }
   }
   * @param {Object} dataReference
   */
  initData(dataReference) {
    this.dataReference = dataReference;
  }

  /**
   * Save is now an external function set in SaveState.
   * Used internally though
   */
  // save() {
  //   this.externalSave.dispatch();
  // }

  /**
   * Get id of user who invited you via friendship chest
   * @returns {string}
   */
  getInvitedData() {
    return this.dataReference.wasInvited;
  }

  /**
   * Set your friendship chest referer id
   * @param {string} friendId
   */
  setInvitedBy(friendId) {
    if (!this.dataReference.wasInvited) {
      this.dataReference.wasInvited = friendId;
      this.save();
    }
  }

  /**
   * Open a new friendship chest card
   * @param {number} index
   */
  openNewCard(index) {
    this.dataReference.cards.push({ i: index, s: FRIENDSHIPCHESTCARD_STATUS.OPEN, pi: index });
    this.save();
  }

  /**
   * Set a friendship chest card to the pending state.
   * @param {number} cardIndex
   */
  setPending(index) {
    const card = this.findCardByIndex(index);
    if (card) {
      card.s = FRIENDSHIPCHESTCARD_STATUS.PENDING;
      this.save();
    }
  }

  /**
   * Handle receipt of a activated friendship chest
   * @param {string} userId
   * @param {Object} payload
   */
  setFriendCard(userId, payload) {
    const fbUserId = payload.senderUserData;
    // If the friend is neither a pending friend or claimed friend
    if (this.dataReference.friends.pending.indexOf(fbUserId) === -1 && this.dataReference.friends.claimed.indexOf(fbUserId) === -1) {
      const card = this.findFirstCardByStatus(FRIENDSHIPCHESTCARD_STATUS.PENDING); // Find a pending card
      if (card) {
        card.s = FRIENDSHIPCHESTCARD_STATUS.FRIEND; // Set it to Friend has been invited! card
        this.dataReference.friends.pending.push(fbUserId); // Friend is now a pending friend to be claimed with
        this.save();
        G.sb('FriendshipChestCheck').dispatch();
      }
    }
  }

  /**
   * Set a friendship chest as claimable
   * @param {number} cardIndex
   */
  setClaimable(index) {
    const card = this.findCardByIndex(index);
    if (card) {
      card.s = FRIENDSHIPCHESTCARD_STATUS.UNCLAIMED;
      this.save();
    }
  }

  /**
   * set a friendship chest card to the claimed state.
   * @param {number} cardIndex
   */
  setClaimed(index) {
    const card = this.findCardByIndex(index);
    if (card) {
      card.s = FRIENDSHIPCHESTCARD_STATUS.CLAIMED;
      this.save();
    }
  }

  /**
   * gets user id for the next friendship chest ready to be claimed
   * @returns {string}
   */
  getFriend() {
    let rtr;
    if (this.dataReference.friends.pending.length > 0) {
      rtr = this.dataReference.friends.pending.splice(0, 1); // Puts the pending friend to the claimed pile
      this.dataReference.friends.claimed.push(rtr[0]);
    }
    return rtr;
  }

  /**
   * re-sorts friendship chest cards
   */
  sortCards() {
    // Get these arrays ready
    const claimed = [];
    const unclaimed = [];
    const friends = [];
    const pending = [];
    const opened = [];
    // Sort the cards
    this.dataReference.cards.filter((card) => { // eslint-disable-line array-callback-return
      switch (card.s) {
        case FRIENDSHIPCHESTCARD_STATUS.OPEN: opened.push(card); break;
        case FRIENDSHIPCHESTCARD_STATUS.FRIEND: friends.push(card); break;
        case FRIENDSHIPCHESTCARD_STATUS.PENDING: pending.push(card); break;
        case FRIENDSHIPCHESTCARD_STATUS.UNCLAIMED: unclaimed.push(card); break;
        case FRIENDSHIPCHESTCARD_STATUS.CLAIMED: claimed.push(card); break;
        default: break;
      }
    });

    // Sort them again by prize index
    const miniSort = (arr) => {
      arr.sort((a, b) => {
        if (a.pi < b.pi) { return -1; }
        if (a.pi > b.pi) { return 1; }
        // if (a.pi === b.pi) {
        return 0;
      });
    };
    miniSort(claimed);
    miniSort(unclaimed);
    miniSort(friends);
    miniSort(pending);
    miniSort(opened);

    // Slam them all together
    const bigArr = claimed.concat(unclaimed, friends, pending, opened);

    // Fix their index
    for (let i = 0; i < bigArr.length; i++) {
      bigArr[i].i = i;
    }

    // Put them back together
    this.dataReference.cards = bigArr;
  }

  /**
   * get friendship chest card data by display index.
   * @param {number} index
   * @param {boolean} silent
   */
  findCardByIndex(index, silent = false) {
    let rtr;
    for (let i = 0; i < this.dataReference.cards.length; i++) {
      const curCard = this.dataReference.cards[i];
      if (curCard.i === index) {
        rtr = curCard;
        break;
      }
    }
    if (!rtr && !silent) {
      console.log('FriendshipChestDataManager - Invalid Index. Cannot find card');
    }
    return rtr;
  }

  /**
   * Finds the first card with the matching status
   * @param {FRIENDSHIPCHESTCARD_STATUS} status
   */
  findFirstCardByStatus(status) {
    let rtr;
    for (let i = 0; i < this.dataReference.cards.length; i++) {
      const curCard = this.dataReference.cards[i];
      if (curCard.s === status) {
        rtr = curCard;
        break;
      }
    }
    return rtr;
  }

  /**
   * get your total friendship chest card count
   * @return {number}
   */
  getCardCount() {
    return this.dataReference.cards.length;
  }

  /**
   * Returns the last card, regardless of status
   * @return {Object}
   */
  getLastCard() {
    const cardCount = this.getCardCount();
    if (cardCount > 0) {
      return this.dataReference.cards[cardCount - 1];
    }
    return null;
  }

  /**
   * Gets an unclaimed card.
   * Usually for checking if theres a reason to open the Friendship Chest window or not
   * @returns {Object}
   */
  getUnclaimedCard() {
    let rtr;
    for (let i = 0; i < this.dataReference.cards.length; i++) {
      const data = this.dataReference.cards[i];
      if (data.s === FRIENDSHIPCHESTCARD_STATUS.UNCLAIMED || (data.s === FRIENDSHIPCHESTCARD_STATUS.FRIEND)) {
        rtr = data;
        break;
      }
    }
    return rtr;
  }

  /**
   * Gets the flag for the promo appearance and if it should show when levelsToAppear == 0
   * @returns {boolean}
   */
  getPromoInitial() {
    return this.dataReference.promoAppearance.initial;
  }

  /**
   * Sets the first time the player has seen the chest promo and from now on, should appear at every levelsToAppear == 0
   * @returns {boolean}
   */
  triggerPromoInitial() {
    if (!this.dataReference.promoAppearance.initial) {
      this.dataReference.promoAppearance.initial = true;
      this.save();
    }
  }

  /**
   * Get the number of levels the player has to complete before the promo card shows up again
   * @returns {number}
   */
  getNumberOfLevelsToAppear() {
    return this.dataReference.promoAppearance.levelsToAppear;
  }

  /**
   * Sets the number of levels the player has to complete before the promo card shows up again
   * @param {number} step
   */
  setNumberOfLevelsToAppear(step, saveNow = false) {
    if (this.dataReference.promoAppearance.levelsToAppear !== step) {
      this.dataReference.promoAppearance.levelsToAppear = step;
      if (saveNow) {
        this.save();
      }
    }
  }

  /**
   * Decrements the number of levels until the promo for friendship chest appears again
   * @param {boolean} saveNow
   */
  decrementNumberOfLevels(saveNow) {
    if (this.dataReference.promoAppearance.initial) {
      const oldLevels = this.dataReference.promoAppearance.levelsToAppear;
      this.dataReference.promoAppearance.levelsToAppear = Math.max(this.dataReference.promoAppearance.levelsToAppear - 1, 0);
      if (saveNow && oldLevels !== this.dataReference.promoAppearance.levelsToAppear) {
        this.save();
      }
    }
  }

  /**
   * Increment the number of times the friendship chest window has been opened
   * @param {boolean} saveNow
   */
  incrementOpened(saveNow) {
    if (!this.dataReference.numberOpened) { this.dataReference.numberOpened = 0; }
    this.dataReference.numberOpened++;
    if (saveNow) { this.save(); }
  }

  /**
   * Gets the number of times the friendship chest window has been opened
   * @returns {number}
   */
  getOpened() {
    if (!this.dataReference.numberOpened) { this.dataReference.numberOpened = 0; }
    return this.dataReference.numberOpened;
  }

  setTutorialStarted() {
    if (!this.dataReference.tutorial.started) {
      this.dataReference.tutorial.started = true;
    }
  }

  setTutorialRequestFinished() {
    if (!this.dataReference.tutorial.requestFinished) {
      this.dataReference.tutorial.requestFinished = true;
    }
  }

  getTutorialEnded() {
    return this.dataReference.tutorial.ended;
  }

  shouldTutorialCommence() {
    if (!this.dataReference.tutorial) {
      this.dataReference.tutorial = {
        started: false,
        requestFinished: false,
        ended: false,
      };
    }

    const tutorialStarted = this.dataReference.tutorial.started;
    const { requestFinished } = this.dataReference.tutorial;
    const tutorialEnded = this.dataReference.tutorial.ended;
    const firstCardIsOpen = this.dataReference.cards[0].s === FRIENDSHIPCHESTCARD_STATUS.OPEN;
    // If tutorial has not started and the first card is an open card, or the tutorial did start but did not completely end
    return {
      thereIsTutorial: (!tutorialStarted && firstCardIsOpen) || (tutorialStarted && !tutorialEnded),
      requestFinished,
    };
  }

  setTutorialDone() {
    this.dataReference.tutorial.ended = true;
    this.save();
  }
}
