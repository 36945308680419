import OMT_UI_AvatarWithCustomFrame from '../../FriendsList/OMT_UI_AvatarWithFrameOffset';

const RANK_FIELD_CONFIG = {
  x: 50,
  y: 0,
  anchor: [0.5, 0.5],
  maxWidth: 50,
  maxHeight: 60,
  fontStyle: {
    fontSize: '50px',
    style: 'font-white',
    align: 'center',
  },
  bg: {
    offsetX: -50,
    offsetY: 0,
    assetPlayer: 'event-leaderboard-rank-bg-player',
    assetOther: 'event-leaderboard-rank-bg-other',
    anchor: [0, 0.5],
  },
};

const AVATAR_CONFIG = {
  x: 115,
  y: 0,
  size: 65,
  framePlayer: 'event-leaderboard-avatar-frame-player',
  frameOther: 'event-leaderboard-avatar-frame-other',
  frameOffset: { x: 0, y: 0 },
  defaultFrames: [
    'event-leaderboard-female-1',
    'event-leaderboard-female-2',
    'event-leaderboard-female-3',
    'event-leaderboard-male-1',
    'event-leaderboard-male-2',
  ],
};

const USERNAME_FIELD_CONFIG = {
  x: 170,
  y: 0,
  anchor: [0, 0.5],
  maxWidth: 200,
  maxHeight: 60,
  fontStylePlayer: {
    fontSize: '50px',
    style: 'font-orange',
    fill: '#ffa800',
  },
  fontStyleOther: {
    fontSize: '50px',
    style: 'font-white',
  },
};

const SCORE_FIELD_CONFIG = {
  x: 450,
  y: 0,
  anchor: [0.5, 0.5],
  maxWidth: 150,
  maxHeight: 60,
  fontStyle: {
    fontSize: '50px',
    style: 'font-orange',
    align: 'center',
  },
  bg: {
    offsetX: 10,
    offsetY: 0,
    assetPlayer: 'event-leaderboard-count-bg-player',
    assetOther: 'event-leaderboard-count-bg',
    anchor: [0.5, 0.5],
  },
};

const BG_FILL_RECT = {
  x: 70, y: -28, width: 350, height: 56, color: 0xFFFFFF, alpha: 0.15, enabled: true,
};

/**
 * class for individual user views for special event leadboards
 */
export class SpecialEvent_LeaderboardUserView extends Phaser.Group {
  /**
   * constructor
   * @param {id:string, rank:number, image:string, name:string, score:number} entryData
   * @param {Object} userViewConfig
   */
  constructor(entryData, userViewConfig) {
    super(game, null);

    this._init(entryData, userViewConfig);
  }

  /**
   * init group for storing cached images
   */
  _initCacheGroup() {
    this._cacheGroup = new Phaser.Group(game);
    this.addChild(this._cacheGroup);
  }

  /**
   * Returns true if the userview is the current player
   * @returns {boolean}
   */
  get isPlayer() {
    return this._isPlayer;
  }

  /**
   * Returns the saved rank
   * @returns {string}
   */
  get rank() {
    return this._rank;
  }

  /**
   * Returns the saved score
   * @returns {number}
   */
  get score() {
    return this._score;
  }

  /**
   * initialize display elements
   * @param {id:string, rank:number, image:string, name:string, score:number} entryData
   * @param {Object} userViewConfig
   */
  _init(entryData, userViewConfig) {
    const playerId = entryData.id || entryData.userId || undefined;
    this._isPlayer = playerId === OMT.envData.settings.user.userId;
    const {
      icon: iconConfig,
      rankField,
      usernameField,
      scoreField,
      avatar,
      bgFillRect,
    } = userViewConfig;

    this._initCacheGroup();
    this._drawBgRect({ ...BG_FILL_RECT, ...bgFillRect });
    this._initRankField(entryData.rank, _.merge(_.cloneDeep(RANK_FIELD_CONFIG), rankField), this.isPlayer);
    this._initAvatarImage(entryData.image, _.merge(_.cloneDeep(AVATAR_CONFIG), avatar), this.isPlayer);
    this._initUsernameField(entryData.name, _.merge(_.cloneDeep(USERNAME_FIELD_CONFIG), usernameField), this.isPlayer);
    this._initScoreField(entryData.score, _.merge(_.cloneDeep(SCORE_FIELD_CONFIG), scoreField), this.isPlayer);
    if (iconConfig) this._initCollectIcon(iconConfig);

    this.toggleCache(true);
  }

  /**
   * init the avatar iamge
   * @param {string} imageURL
   * @param {boolean} isPlayer
   */
  _initAvatarImage(imageURL, avatarConfig, isPlayer) {
    const frameAsset = isPlayer ? avatarConfig.framePlayer : avatarConfig.frameOther;
    const avatar = new OMT_UI_AvatarWithCustomFrame({
      frameAsset,
      frameOffset: avatarConfig.frameOffset,
      imageURL,
      imageSize: avatarConfig.size,
      defaultFrames: avatarConfig.defaultFrames,
    });
    // const avatar = new OMT_UI_AvatarWithFrame(imageURL, AVATAR_CONFIG.size);
    avatar.x = avatarConfig.x;
    avatar.y = avatarConfig.y;
    this.addChild(avatar);
  }

  /**
   * create the username field
   * @param {number} rank
   * @param {Object} fieldConfig
   * @param {boolean} isPlayer
   */
  _initRankField(rank, fieldConfig, isPlayer) {
    if (rank == null || rank === 0) rank = '--';
    if (fieldConfig.bg) this._addFieldBackground(fieldConfig, fieldConfig.bg, isPlayer);
    const rankString = `${rank}`;
    this._rankField = new G.Text(
      fieldConfig.x,
      fieldConfig.y,
      rankString,
      fieldConfig.fontStyle,
      fieldConfig.anchor,
      fieldConfig.maxWidth,
      fieldConfig.maxHeight,
      false,
    );
    this._rank = rank;
    this._cacheGroup.addChild(this._rankField);
  }

  /**
   * Turns off or on the bitmap cache
   * @param {boolean} b
   */
  toggleCache(b) {
    this._cacheGroup.cacheAsBitmap = b;
  }

  /**
   * Updates the textfield and saved rank
   * @param {string} givenRank
   */
  updateRank(givenRank) {
    if (this._rankField) {
      this._rankField.setText(givenRank);
      this._rank = givenRank;
    }
  }

  /**
   * Updates the textfield of the score
   * @param {number} givenScore
   */
  updateScore(givenScore) {
    if (this._scoreField) {
      this._scoreField.setText(givenScore);
      this._score = givenScore;
    }
  }

  /**
   * create the username field
   * @param {string} username
   * @param {Object} fieldConfig
   * @param {boolean} isPlayer
   */
  _initUsernameField(username, fieldConfig, isPlayer) {
    if (fieldConfig.bg) this._addFieldBackground(fieldConfig, fieldConfig.bg, isPlayer);
    const userameField = new G.Text(
      fieldConfig.x,
      fieldConfig.y,
      username,
      isPlayer ? fieldConfig.fontStylePlayer : fieldConfig.fontStyleOther,
      fieldConfig.anchor,
      fieldConfig.maxWidth,
      fieldConfig.maxHeight,
      false,
    );
    this._cacheGroup.addChild(userameField);
  }

  /**
   * create the user score field
   * @param {number} score
   * @param {Object} fieldConfig
   * @param {boolean} isPlayer
   */
  _initScoreField(score, fieldConfig, isPlayer) {
    if (fieldConfig.bg) this._addFieldBackground(fieldConfig, fieldConfig.bg, isPlayer);
    const scoreField = new G.Text(
      fieldConfig.x,
      fieldConfig.y,
      score.toString(),
      fieldConfig.fontStyle,
      fieldConfig.anchor,
      fieldConfig.maxWidth,
      fieldConfig.maxHeight,
      false,
    );
    this._score = score;
    this._cacheGroup.addChild(scoreField);
    this._scoreField = scoreField;
  }

  /**
   * add background for a text field
   * @param {Object} fieldConfig field config
   * @param {Object} bgConfig field background config
   * @param {Object} isPlayer is this the current player
   */
  _addFieldBackground(fieldConfig, bgConfig, isPlayer) {
    const {
      assetPlayer,
      assetOther,
      offsetX,
      offsetY,
      anchor,
    } = bgConfig;

    const x = fieldConfig.x + (offsetX || 0);
    const y = fieldConfig.y + (offsetY || 0);
    G.makeImage(x, y, isPlayer ? assetPlayer : assetOther, anchor, this._cacheGroup);
  }

  /**
   * draw rectangular bg
   */
  _drawBgRect(rectangle) {
    if (!rectangle.enabled || rectangle.width === 0 || rectangle.height === 0) return;
    const graphics = new Phaser.Graphics(game);
    graphics.lineStyle(1, rectangle.color, 0);
    graphics.beginFill(rectangle.color, rectangle.alpha);
    graphics.drawRect(rectangle.x, rectangle.y, rectangle.width, rectangle.height);
    graphics.endFill();
    this._cacheGroup.addChildAt(graphics, 0);
  }

  /**
   * create the optional collect icon
   * @param {Object} collectIconConfig
   */
  _initCollectIcon(collectIconConfig) {
    const {
      asset,
      size,
      offsetX,
      offsetY,
    } = collectIconConfig;

    const icon = G.makeImage(0, 0, asset, [0.5, 0.5], this._cacheGroup);
    icon.width = icon.height = size;
    icon.x = SCORE_FIELD_CONFIG.x + (SCORE_FIELD_CONFIG.maxWidth / 2) + (offsetX || 0);
    icon.y = (offsetY || 0);
  }

  /**
   * insert a horizontal divider graphic
   * @param {Phaser.DisplayObject} dividerInstance
   * @param {Number} lineSpacing
   * @param {Number} paddingTop
   * @param {Number} paddingBottom
   */
  insertHorizontalDivider(dividerInstance, lineSpacing, paddingTop, paddingBottom) {
    // shift existing children
    for (const child of this.children) {
      child.y += dividerInstance.height + paddingTop + paddingBottom;
    }
    // insert divider
    const bounds = this.getBounds(this);
    dividerInstance.x = bounds.x;
    dividerInstance.y = (-lineSpacing / 2) + paddingTop;
    dividerInstance.width = bounds.width;
    this.addChild(dividerInstance);
  }
}
