import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

export const LAYOUT_TYPE = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  NONE: 'NONE',
};

const LAYOUT_STYLE = {
  TOP: {
    rotation: 0,
    labelOffset: { x: 0, y: 50 },
    offsetY: {
      vertical: 160,
      horizontal: -80,
    },
  },
  BOTTOM: {
    rotation: 180,
    labelOffset: { x: 0, y: 43 },
    offsetY: {
      vertical: 290,
      horizontal: 160,
    },
  },
};

const AVATAR_SIZE = 80;

const LABEL_FONT_STYLE = {
  style: 'font-white',
  fontSize: '26px',
};

/**
 * class for quick pan avatar button in the WorldMap
 */
export default class MapQuickPanButton extends G.Button {
  /**
   * constructor
   */
  constructor(mapOrientation) {
    super(0, 0, null);

    this._additionalOffsetY = mapOrientation === ORIENTATION.vertical ? 0 : -80;
    // this._initAvatarImage();
    // this._initAvatarFrame();
    // this._initLevelText();
    this._initLoopTween();
    this._initStates();
  }

  /**
   * Initializes states for the pointer
   * @param {LAYOUT_TYPE} initialState
   */
  _initStates(initialState) {
    this._states = {};
    this._states[LAYOUT_TYPE.NONE] = {
      onEnter: this._stateTypeNoneEnter.bind(this),
      onExit: this._stateTypeNoneExit.bind(this),
    };
    this._states[LAYOUT_TYPE.TOP] = {
      onEnter: this._stateTypeTopEnter.bind(this),
      onExit: this._stateTypeTopExit.bind(this),
    };
    this._states[LAYOUT_TYPE.BOTTOM] = {
      onEnter: this._stateTypeBottomEnter.bind(this),
      onExit: this._stateTypeBottomExit.bind(this),
    };
    if (initialState) {
      this.layoutState = initialState;
    }
  }

  /**
   * State type NONE enter
   */
  _stateTypeNoneEnter() {
    this.animateOut();
  }

  /**
   * State type NONE exit
   */
  _stateTypeNoneExit() {
    // Nothing
  }

  /**
   * State type TOP enter
   */
  _stateTypeTopEnter() {
    this.setLayoutType(this._currentState);
    this.animateIn();
  }

  /**
   * State type TOP exit
   */
  _stateTypeTopExit() {
    // Nothing
  }

  /**
   * State type BOTTOM enter
   */
  _stateTypeBottomEnter() {
    this.setLayoutType(this._currentState);
    this.animateIn();
  }

  /**
   * State type BOTTOM exit
   */
  _stateTypeBottomExit() {
    // Nothing
  }

  /**
   * Sets the layout state to what was given
   * @param {LAYOUT_TYPE} newState
   */
  set layoutState(newState) {
    if (this._currentState !== newState) {
      if (this._currentState) {
        this._states[this._currentState].onExit();
      }
      this._currentState = newState;
      this._states[this._currentState].onEnter();
    }
  }

  /**
   * Returns the current layout state
   * @returns {LAYOUT_STYLE}
   */
  get layoutState() {
    return this._currentState;
  }

  /**
   * init the avatar image
   */
  _initAvatarImage() {
    this._avatar = G.makeExtImage(0, -8, OMT.envData.settings.user.avatar, 'avatar_m', 0, this, false, (avatar) => {
      avatar.width = avatar.height = AVATAR_SIZE;
    });
    this._avatar.anchor.setTo(0.5);
    this._avatar.width = this._avatar.height = AVATAR_SIZE;
    this.addChild(this._avatar);
  }

  /**
   * init the frame overlay for the avatar
   */
  _initAvatarFrame() {
    this._frame = G.makeImage(0, 0, 'avatar_2', 0.5, this);
  }

  /**
   * init the level # text
   */
  _initLevelText() {
    const levelNum = G.saveState.getLastPassedLevelNr() + 1;
    this._levelTxt = new G.Text(0, 0, levelNum.toString(), LABEL_FONT_STYLE, 0.5, 100, 100);
    this.addChild(this._levelTxt);
  }

  /**
   * set the layout type / style
   * @param {string} layoutType as defined in LAYOUT_TYPE
   */
  setLayoutType(layoutType) {
    const layoutStyle = LAYOUT_STYLE[layoutType];
    if (!layoutStyle) return;

    // this.angle = this._avatar.angle = this._levelTxt.angle = layoutStyle.rotation;

    // this._levelTxt.x = layoutStyle.labelOffset.x;
    // this._levelTxt.y = layoutStyle.labelOffset.y;

    switch (layoutStyle) {
      case LAYOUT_STYLE.TOP: this.y = LAYOUT_STYLE[this._currentState].offsetY[OMT.systemInfo.orientationKey]; break;
      case LAYOUT_STYLE.BOTTOM: this.y = game.height - LAYOUT_STYLE[this._currentState].offsetY[OMT.systemInfo.orientationKey] + this._additionalOffsetY; break;
      default: break;
    }
  }

  /**
   * Resizes accordingly
   */
  resize() {
    if (this._currentState !== null) this.setLayoutType(this._currentState);
  }

  /**
   * animateIn quick pan
   */
  animateIn() {
    this.visible = true;
    this.scale.set(0);
    this.stopScaleTween();
    this._scaleTween = game.add.tween(this.scale).to({ x: 1, y: 1 }, 250, Phaser.Easing.Sinusoidal.Out, true);
    this._scaleTween.onComplete.add(this._resumeLoopTween, this);
  }

  /**
   * animateIn quick pan
   */
  animateOut() {
    this.stopScaleTween();
    this._scaleTween = game.add.tween(this.scale).to({ x: 0, y: 0 }, 250, Phaser.Easing.Sinusoidal.Out, true);
    this._scaleTween.onComplete.add(this._onAnimateOutComplete, this);
  }

  /**
   * on animateOut animation completed
   */
  _onAnimateOutComplete() {
    this.visible = false;
    this._pauseLoopTween();
  }

  /**
   * init attract / loop animation
   */
  _initLoopTween() {
    this._loopTween = game.add.tween(this.pivot);
    this._loopTween.to({ y: 10 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
    this._loopTween.pause();

    this._xTween = undefined;
  }

  /**
   * resume loop animation
   */
  _resumeLoopTween() {
    this._loopTween.resume();
  }

  /**
   * pause loop animation
   */
  _pauseLoopTween() {
    this._loopTween.resume();
  }

  /**
   * Tweens the x position of the pan button
   * @param {number} pos
   */
  tweenXPositionTo(pos) {
    if (pos !== this.x && !this._xTween) {
      this._xTween = game.add.tween(this)
        .to({ x: pos }, 200, Phaser.Easing.Linear.None, true);
      this._xTween.onComplete.add(this._nullXTween.bind(this));
    }
  }

  /**
   * When tween is complete, null it out.
   */
  _nullXTween() {
    this._xTween = null;
  }

  /**
   * destruction method
   */
  destroy() {
    if (this._currentState) {
      this._states[this._currentState].onExit();
      this._currentState = null;
    }
    this.stopScaleTween();
    super.destroy();
  }

  /**
   * Checks and stops scale tween
   */
  stopScaleTween() {
    if (this._scaleTween && this._scaleTween.stop) this._scaleTween.stop();
  }
}
