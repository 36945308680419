
import { Window_BragBaseWindow } from './Window_BragBaseWindow';

const BANNER_TITLE_STRING = 'You did it!';
const BANNER_SUBTITLE_STRINGS = ["You play like a pro, let's try something else"];

/**
 * Window for when you won a brag 2.0 challenge from clickin on a share post
 */
class Window_BragChallengeWon extends Window_BragBaseWindow {
  /**
   * constructor
   * @param {object} parent
   * @param {number} levelNum
   */
  constructor(parent, levelNum) {
    super(parent, G.OMTsettings.elements.Window_Brag, levelNum);

    // we skipped the normal level flow so we will send the onLevelFinished event now
    G.sb('onLevelFinished').dispatch(levelNum - 1, 0, 0);
  }

  /**
   * override customize the various window banners
   */
  _createBanners() {
    this._createTopBanner(OMT.language.getText(BANNER_TITLE_STRING));
    this._createSubTitles(OMT.language.getTextList(BANNER_SUBTITLE_STRINGS));
  }

  /**
   * create the specific window content
   */
  _createContent() {
    super._createContent();
    this._initSoloAvatarLayout();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.bragChallengeWon = Window_BragChallengeWon;
