import ShareMsgBase from './A_G.ShareMsgBase';

class MsgLives extends ShareMsgBase {
  /**
   * The message that sends back when you got the lives and you want to thank your friend via message
   * @param {string} user1AvatarUrl
   * @param {string} user2AvatarUrl
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1AvatarUrl, user2AvatarUrl, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    this.avatar2 = this._addExtImage(45, 155, user2AvatarUrl, 0, 132, null);
    this.avatar1 = this._addExtImage(506, 144, user1AvatarUrl, 0, 132, null);
    this.bg = this._addExtImage(0, 0, 'img/Msg_Life.png', 0, null, null);
  }
}

G.MsgLives = MsgLives;
