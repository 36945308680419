/* eslint-disable no-undef */

/**
 * class for managing Sentry connection / events
 */
class SentryLogger {
  /**
   * constructor
   */
  constructor() {
    this._debugMode = false;
  }

  /**
   * connect / init sentry
   * @param {string} sentryDSN
   * @param {Object} sentryCFG
   * @param {string} gameVersion
   */
  connect(sentryDSN, sentryCFG, gameVersion) {
    try {
      const ignoreErrors = [
        'Non-Error promise rejection captured with keys: code, message', // facebook spam
      ];

      // use this to inspect or filter errors by property. return null to ignore a error.
      // eslint-disable-next-line arrow-body-style
      const beforeSend = (error) => {
        // console.log('SentryLogger() beforeSend ', error);
        return error;
      };

      if (sentryDSN && sentryCFG) {
        Sentry.init({
          debug: this._debugMode,
          dsn: sentryDSN,
          release: `${sentryCFG.projectId}@${gameVersion}`,
          beforeSend,
          ignoreErrors,
        });
      } else if (sentryDSN) {
        Sentry.init({
          debug: this._debugMode,
          dsn: sentryDSN,
          beforeSend,
          ignoreErrors,
        });
      }
    } catch (error) {
      console.log('ERROR: could not connect to sentry');
    }
  }

  /**
   * manually log a sentry error
   * @param {string} message
   * @param {Object} optOptions
   * @param {boolean} debug
   */
  logError(message, optOptions, debug = true) {
    try {
      if (debug) {
        G.Utils.debugError(message);
      }
      if (typeof Sentry !== 'undefined') {
        Sentry.captureMessage(message, optOptions);
      }
    } catch (error) {
      console.log('Sentry Error: could not logError.');
    }
  }

  /**
   * set the user context for sentry
   * @param {string} userId
   */
  setUserContext(userId) {
    try {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: userId,
        });
      });
    } catch (error) {
      console.log('Sentry Error: could not setUserContext.');
    }
  }

  /**
   * set the user's IAP tier for sentry
   */
  setIAPTier() {
    try {
      Sentry.configureScope((scope) => {
        scope.setTag('iapTier', OMT.envData.settings.env.tier);
      });
    } catch (error) {
      console.log('Sentry Error: could not setIAPTier');
    }
  }

  /**
   * set the user's country code for sentry
   */
  setUserCountry() {
    try {
      Sentry.configureScope((scope) => {
        scope.setTag('country', OMT.envData.settings.env.country);
      });
    } catch (error) {
      console.log('Sentry Error: could not setUserCountry');
    }
  }

  /**
   * Set the highest level completed
   */
  setHighestLevel() {
    try {
      Sentry.configureScope((scope) => {
        scope.setTag('highestLevel', G.saveState.getLastPassedLevelNr() + 1);
      });
    } catch (error) {
      console.log('Sentry Error: could not setHighestLevel');
    }
  }

  /**
   * Set current level
   * @param {number} levelNumber
   */
  setCurrentLevel(levelNumber) {
    try {
      Sentry.configureScope((scope) => {
        scope.setTag('currentLevel', levelNumber);
      });
    } catch (error) {
      console.log('Sentry Error: could not setCurrentLevel');
    }
  }

  /**
   * Set whether we are connected to the game backend for sentry
   */
  setGBConnectivity() {
    try {
      Sentry.configureScope((scope) => {
        scope.setTag('gb_connected', OMT.connect.isConnected());
      });
    } catch (error) {
      console.log('Sentry Error: could not setGBCConnectivity');
    }
  }

  /**
   * Set the entry point for sentry
   */
  setEntryPoint() {
    try {
      Sentry.configureScope((scope) => {
        scope.setTag('entryPoint', OMT.envData.entryPoint);
      });
    } catch (error) {
      console.log('Sentry Error: could not setEntryPoint');
    }
  }

  /**
   * Set the FPS of the user for sentry
   */
  setFPS() {
    try {
      Sentry.configureScope((scope) => {
        scope.setTag('fps', OMT.performanceMonitor.lastMinAverageFPS);
      });
    } catch (error) {
      console.log('Sentry Error: could not setFPS');
    }
  }

  /**
   * Set WebGL parameters
   */
  setWebGLParams() {
    try {
      const { webGLInfo } = OMT.systemInfo;
      for (const param in webGLInfo) {
        if (Object.prototype.hasOwnProperty.call(webGLInfo, param)) {
          Sentry.configureScope((scope) => {
            scope.setTag(`webGL_${param}`, webGLInfo[param]);
          });
        }
      }
    } catch (error) {
      console.log('Sentry Error: could not setWebGLParams');
    }
  }
}

// create global instance
G.Utils.SentryLog = new SentryLogger();
