// Config for OMT_GameMessageScheduler

// priority: Lower number = more important
// -1 = transcendent priority: These exist outside the normal priority hierarchy, only one of each type can be scheduled at any given time
// enabled_bot: can be sent as bot message
// limitPerSession: how many times can the message be sent per session

export const messageConfig = {
  FullLives: {
    priority: 1,
    enabled_bot: false,
    limitPerSession: Infinity,
  },
  HourlyGift: {
    priority: 2,
    enabled_bot: true,
    limitPerSession: Infinity,
  },
  GateOpen: {
    priority: 3,
    enabled_bot: true,
    limitPerSession: Infinity,
  },
  ResetWheel: {
    priority: 4,
    enabled_bot: true,
    limitPerSession: Infinity,
  },
  DailyMis: {
    priority: 5,
    enabled_bot: false,
    limitPerSession: Infinity,
  },
  DailyChallenge: {
    priority: 5,
    enabled_bot: false,
    limitPerSession: Infinity,
  },
  DailReward: {
    priority: 6,
    enabled_bot: false,
    limitPerSession: Infinity,
  },
  PlayerPassed: {
    priority: -1,
    enabled_bot: false,
    limitPerSession: 1,
  },
  ScoreBeaten: {
    priority: -1,
    enabled_bot: false,
    limitPerSession: 1,
  },
  SentGift: {
    priority: -1,
    enabled_bot: false,
    limitPerSession: Infinity,
  },
  FriendshipChestClaimed: {
    priority: -1,
    enabled_bot: false,
    limitPerSession: Infinity,
  },
  THuntStart: {
    priority: -1,
    enabled_bot: true,
    limitPerSession: 1,
  },
  THuntEnd: {
    priority: -1,
    enabled_bot: true,
    limitPerSession: 1,
  },
};

// Colours for console messages
export const colours = {
  ok: '#80A0FF',
  warning: '#FFFFA0',
  error: '#FF8080',
};
