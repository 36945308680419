export default class WorldMap2_postLevelFx {
  /**
   * Creates particle batches based on the amount of rewards.
   * For the Saga Map 2.0
   * @param {{lastLevelData:{reward:number}, coinCounter:Object, levelPosition:{x:number, y:number}, uiFx: G.UITargetParticles, panel:UI_MapPanel, finalCoins:number}} config
   */
  static startBatches(config) {
    const { lastLevelData } = config;
    const { coinCounter } = config;
    const { levelPosition } = config;
    const { finalCoins } = config;
    return new Promise(((resolve) => {
      const batches = [];

      const batchData = {
        particleEmitter: config.uiFx,
        panel: config.panel,
        levelPosition,
        coinCounter,
      };

      if (lastLevelData.starImprovement > 0) {
        batches.push(this._createBounceParticlesBatch(
          batchData, lastLevelData.starImprovement, 'stars',
        ));
      }

      if (lastLevelData.reward > 0) {
        batches.push(this._createBounceParticlesBatch(
          batchData, lastLevelData.reward, 'coins',
        ));
      }

      if (batches.length === 0) {
        resolve();
      } else {
        Promise.all(batches).then(() => {
          // trigger coin change dispatch and save state change without altering coin total
          // G.saveState.changeCoins(0, true);
          G.sb('onCoinsChange').dispatch(finalCoins || G.saveState.getCoins());
          resolve();
        });
      }
    }));
  }

  /**
   * Creates particle batches
   * @param {{particleEmitter:G.UITargetParticles, panel:UI_MapPanel, levelPosition:{x:number, y:number}, coinCounter:Object}} config
   * @param {number} amount
   * @param {string} objType
   */
  static _createBounceParticlesBatch(config, amount, objType) {
    const coins = objType === 'coins';

    const onParticleStart = (particle) => {
      if (coins) particle.scale.setTo(0.75);
      // this.vel.setTo(game.rnd.realInRange(-12,12),game.rnd.realInRange(-12,12));
      particle.speedDelta = 1.5;
      particle.speedMax = 35;

      let verOffsetY = 0;
      let verVelY = (Math.random() * -2) - 5;
      let velX = game.rnd.realInRange(-2.5, 2.5);
      let yy = particle.y;
      let velYY = game.rnd.realInRange(-2.5, 2.5);
      let grounded = false;

      const originalUpdateMethod = particle.update;
      const particleCustomUpdate = () => {
        if (grounded) return;
        particle.x += velX;
        yy += velYY;
        verOffsetY += verVelY;

        if (verOffsetY > 0) {
          if (Math.abs(verVelY) > 4) {
            verVelY *= -0.7;
          } else {
            velX = 0;
            velYY = 0;
            grounded = true;
            game.time.events.add(game.rnd.between(200, 600), () => {
              particle.update = originalUpdateMethod;
            });
          }
        } else {
          verVelY += 0.5;
        }
        verOffsetY = Math.min(0, verOffsetY);
        particle.y = yy + verOffsetY;
      };
      particle.update = particleCustomUpdate;
    };

    const onParticleFinish = (particle) => {
      if (coins) {
        config.coinCounter.coins += particle.carriedValue;
        G.sb('onCoinsChange').dispatch(config.coinCounter.coins);
      } else {
        const { starsTxt } = config.panel;
        starsTxt.setText(parseInt(starsTxt.text) + particle.carriedValue);
      }
    };

    return new Promise(((resolve) => {
      if (!config.levelPosition) return resolve();
      const batch = config.particleEmitter.createDividedBatch(
        game.world.bounds.x + config.levelPosition.x,
        config.levelPosition.y,
        coins ? 'coin_1' : 'map-nodes/map_star_1',
        coins ? config.panel.coinsTxt : config.panel.starsTxt,
        amount,
        coins ? 5 : 1,
      );

      batch.addOnPartStart(onParticleStart);
      batch.addOnPartFinish(onParticleFinish);
      batch.onFinish.addOnce(resolve);

      batch.start();
      return null;
    }));
  }
}
