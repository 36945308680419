import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
// import { OMT_CrossPromoVideoPlayer } from '../OMT_CrossPromoVideoPlayer';
// import { XPROMO_PLACEMENTS } from '../OMT_CrossPromo';
import { FBAdLoader, FBAdType } from '@sgorg/fb-ad-preloader/dist/fb-ad-preloader';
import WallClockTimer from '../../../Utils/Timers/WallClockTimer';

// adInstance types
const AD_TYPES = {
  REWARDED: 'REWARDED',
  INTERSTITIAL: 'INTERSTITIAL',
};

const AD_PRELOAD_CODES = ['ADS_FREQUENT_LOAD', 'ADS_NO_FILL'];

// for ad fallbacks
export const ASK_FRIENDS_REQUIRED_FRIENDS = 3;
export const ASK_FRIENDS_COOLDOWN_ID = 'adFallback1';

/**
 * class for handling ad display logic and interfacing with the game and the OMT_AdsPlacement service
 */
export class OMT_Ads {
  /**
   * constructor
   */
  constructor() {
    // param(s) for last showAd() values
    this._lastAdPlacement = null;

    // for debugging ad fallbacks
    this._forceAdFail = false;

    this._errorCodeMap = new Map();

    // define phaser signals
    this.signals = {
      onAdSuccess: new Phaser.Signal(),
    };
  }

  /**
   * initialize placement service
   */
  init() {
    if (G.BuildEnvironment.adPlacementIDs) {
      // At this point, G.json.settings hasn't been properly set to IAP or non-IAP yet,
      // Manually choose the proper settings here.
      const settings = G.IAP ? G.json.settingsIAP : G.json.settings;

      const canSpinPrizeWheel = OMT.feature.prizeWheelIsLimited()
        ? !(G.saveState.getPrizeWheelSpinCount() >= settings.prizeWheelLimits.numSpins)
        : true;
      const canSpinLossAversionWheel = OMT.feature.lossAversionWheelIsLimited()
        ? !(G.saveState.getLossAversionWheelSpinCount() >= settings.lossAversionWheelLimits.numSpins)
        : true;

      const preloadAds = !G.firstTime && (canSpinPrizeWheel || canSpinLossAversionWheel);
      console.log(`Preloading ads: ${preloadAds}`, FBAdType, FBAdLoader);

      /** @type {FBAdLoaderConfig} */
      const config = {
        placements: [
          {
            name: 'rewarded-1',
            type: 'rewarded',
            id: G.BuildEnvironment.adPlacementIDs.REWARDED,
            preload: preloadAds,
          },
          {
            name: 'interstitial-1',
            type: 'interstitial',
            id: G.BuildEnvironment.adPlacementIDs.INTERSTITIAL,
            preload: preloadAds,
          },
        ],
        // eslint-disable-next-line no-unused-vars
        onError: (eventName, placement, errorCode) => {
          if (errorCode === 'TIME_OUT') return;
          const isPreloadError = AD_PRELOAD_CODES.includes(errorCode);
          this._errorCodeMap.set(placement.id, { code: errorCode, isPreloadError });
        },
        verbose: true,
      };
      this._placementService = new FBAdLoader(config);
    } else {
      OMT_Utils.stylizedLog('OMT_Ads: G.BuildEnvironment.adPlacementID was not defined so OMT_AdPlacementService could not be initialized', '#FFFF00');
    }
  }

  /**
   * Show an advertisement. Intefaces with the AdPlacementService.
   * @param {Object} placement placement Object
   * @param {Function} successCallback
   * @param {Function} failCallback
   */
  showAd(placement, successCallback, failCallback) {
    this._lastAdPlacement = placement;
    failCallback = failCallback || successCallback;
    const self = this;

    if (navigator && navigator.onLine === false) {
      // failCallback.call(context);
      // reportFailedAd(placement, 'Offline');
      return;
    }

    if (!G.BuildEnvironment.production) {
      if (Math.random() < 0.2) {
        // failCallback.call(context);
        // reportFailedAd(placement, 'Dev Test');
      } else {
        // successCallback.call(context);
        // reportWatchedAd(placement);
        self.signals.onAdSuccess.dispatch(placement);
      }
      // return;
    }

    // const properPlacement = placement || G.BuildEnvironment.adPlacements.rewarded;
    // const properPlacement = placement || G.BuildEnvironment.adPlacementIDs.REWARDED;
    const properPlacement = G.BuildEnvironment.adPlacementIDs.REWARDED;

    // const adOverlay = new G.AdLoadOverlay();
    const rewarded = placement.adType;

    // logEvent(rewarded ? 'RewardedAdRequested' : 'InterstitialAdRequested');

    let videoInstance = null;

    function finish(success) {
      // if (adOverlay) adOverlay.destroy();
      if (success) {
        self.signals.onAdSuccess.dispatch(placement);
        // reportWatchedAd(placement);
        successCallback();
      } else {
        failCallback();
      }
    }

    function afterAdShown(success, trackingMsg) {
      OMT.ads._resumeGame();
      // logEvent(trackingMsg);
      finish(success);
    }

    function onAdLoaded() {
      OMT.ads._pauseGame();

      videoInstance
        .showAsync()
        .then((filled) => {
          if (filled) {
            afterAdShown(true, rewarded ? 'RewardedAdDisplayed' : 'InterstitialAdDisplayed');
          } else {
            // reportFailedAd(placement, 'Video-AdCannotBeDisplayedFillrate');
            afterAdShown(false, 'Video-AdCannotBeDisplayedFillrate');
          }
        })
        .catch((err) => {
          // reportFailedAd(placement, 'Video-AdCannotBeDisplayedFillrate');
          afterAdShown(false, 'Video-AdCannotBeDisplayedFillrate');
        });
    }

    const fbadFunc = rewarded ? 'getRewardedVideoAsync' : 'getInterstitialAdAsync';
    window.FBInstant[fbadFunc](properPlacement)
      .then((video) => {
        console.log('GOT VIDEO', video);
        videoInstance = video;
        try {
          video
            .loadAsync()
            .then(onAdLoaded)
            .catch((err) => {
              // gameTracking.design('Video-AdCannotBeDisplayedFillrate');
              // reportFailedAd(placement, 'Video-AdCannotBeDisplayedFillrate');
              finish(false);
            });
        } catch (e) {}
      })
      .catch((err) => {
        // gameTracking.design('Video-AdCannotBeDisplayedFillrate');
        // reportFailedAd(placement, 'Video-AdCannotBeDisplayedFillrate');
        finish(false);
      });

    /* // DDNA.adEventHelper.trackAdRequest(placement);

    if (this._forceAdFail) { // we want to force ad failure for testing purposes
      failCallback();
      // DDNA.adEventHelper.trackAdShown(placement, false, null);
      return;
    }

    const onAdSuccess = (trackAdShown = true) => { // on ad show success
      // if (trackAdShown) DDNA.adEventHelper.trackAdShown(placement, true, null);
      if (this.adOverlay) this.adOverlay.destroy();
      this._resumeGame();
      this.signals.onAdSuccess.dispatch(placement);
      successCallback();
    };

    const onAdFail = () => { // on ad show failure
      // const error = this._errorCodeMap.get(placement.id);
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.AdFillrateError);
      // DDNA.adEventHelper.trackAdShown(placement, false, error);
      // show alternate cross promo video if available in place of rewarded ads
      // if (placement.adType === AD_TYPES.REWARDED && OMT.crossPromo.isPlacementActive(XPROMO_PLACEMENTS.NO_REWARDED_ADS)) {
      if (placement.adType === AD_TYPES.REWARDED) {
        //   const promo = OMT.crossPromo.getPromoByPlacementId(XPROMO_PLACEMENTS.NO_REWARDED_ADS);
        const onPromoSuccess = () => { onAdSuccess(false); };
        const onPromoFail = () => {
          this._resumeGame();
          failCallback();
        };
        // OMT_CrossPromoVideoPlayer.getInstance().playVideoAd(XPROMO_PLACEMENTS.NO_REWARDED_ADS, promo, onPromoSuccess, onPromoFail);
      } else { // no promo fallback
        this._resumeGame();
        failCallback();
      }
    };

    if (!G.BuildEnvironment.production) {
      this.adOverlay = new G.AdLoadOverlay();
      this.mockAds(placement, onAdSuccess, onAdFail);
      return;
    }

    if (placement == null) {
      console.warn('call to adShow with no placement => skipped');
      return;
    }

    // attempt to show an ad
    if (G.BuildEnvironment.adPlacementIDs) {
      if (placement.adType === 'REWARDED') {
        this._placementService.showAd('rewarded-1', onAdSuccess, onAdFail);
      } else {
        this._placementService.showAd('interstitial-1', onAdSuccess, onAdFail);
      }
    } else { // placementIDs not defined so we cant show ads
      onAdFail();
    } */
  }

  /**
   * show a mock ad
   * @param {Object} placement Object
   * @param {Function} successCallback
   * @param {Function} failCallback
   */
  mockAds(placement, successCallback, failCallback) {
    if (Math.random() < 0.2) {
      failCallback();
    } else {
      successCallback();
      this.signals.onAdSuccess.dispatch(placement);
    }
  }

  /**
   * show an ad-fallback
   * @param {Function} onSuccess
   * @param {string} layerName
   * @param {number} retryCount this should only be passed interally, leave unset.
   */
  async showAdFallback(onSuccess, layerName = null, retryCount = 0) {
    if (!layerName) layerName = G.WindowMgr.LayerNames.OverlayLayer;
    const askFriendsAvailable = false; //await this.checkIfAskFriendsAvailable();
    const onRetry = retryCount === 0 ? this._adFallbackRetryLastAd.bind(this, onSuccess, layerName, retryCount) : null;

    if (askFriendsAvailable) {
      G.sb('pushWindow').dispatch(['FallbackAskFriends', onSuccess, onRetry], null, layerName, true);
    } else {
      G.sb('pushWindow').dispatch(['NoAdsOpenCoinShop', onRetry], null, layerName, true);
    }
  }

  /**
   * retries the last showAd() call for ad-fallback retry
   * @param {Function} onSuccess
   * @param {string} layerName
   * @param {number} retryCount
   */
  _adFallbackRetryLastAd(onSuccess, layerName, retryCount) {
    this.showAd(this._lastAdPlacement, onSuccess, () => {
      this.showAdFallback(onSuccess, layerName, retryCount + 1);
    });
  }

  /**
   * check if the ask friends functionality is available
   */
  async checkIfAskFriendsAvailable() {
    const requiredFriends = ASK_FRIENDS_REQUIRED_FRIENDS - G.saveState.adFallback_askFriends_getAskCount();
    const friendList = await OMT.friends.getFriendsList();

    if (friendList.length < requiredFriends) return true;

    let friendsAvailable = 0;
    let cooldownRemaining;
    friendList.forEach((friendData) => {
      cooldownRemaining = G.saveState.getUserCooldownRemaining(ASK_FRIENDS_COOLDOWN_ID, friendData.userId);
      if (cooldownRemaining === 0) friendsAvailable++;
    });

    return friendsAvailable >= requiredFriends;
  }

  /**
   * pause the game / sounds
   */
  _pauseGame() {
    WallClockTimer.stop();
    G.soundManager.pause(true); // pause sound during ads
    // this.adOverlay = new G.AdLoadOverlay();
  }

  /**
   * resume game / sounds context if lost
   */
  _resumeGame() {
    if (this.adOverlay) this.adOverlay.destroy();
    WallClockTimer.start();
    G.soundManager.resume(true); // resume sound after ads

    setTimeout(() => {
      try {
        if (game.sound.context.state !== 'running') {
          game.sound.context.resume()
            .then(() => {
              // console.log('ac resumed');
            })
            .catch(() => {
              // console.log('ac not resumed');
            });
        }
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }

  /**
   * set to true to force ad fail to trigger fallback
   * @param {boolean} state
   */
  set forceAdFail(state) {
    this._forceAdFail = state;
  }
}

