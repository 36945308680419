G.AdLoadOverlay = function() {
  Phaser.Group.call(this, game);
  this.fixedToCamera = true;
  this.gfx = game.add.graphics();
  this.gfx.inputEnabled = true;
  this.add(this.gfx);
  this.gfx.beginFill(0x000000, 0.8);
  this.gfx.drawRect(0, 0, 16, 16);
  this.gfx.width = game.width;
  this.gfx.height = game.height;

  this.waitingIcon = new G.WaitingIcon(game.width*0.5, game.height*0.5);
  G.changeTexture(this.waitingIcon, "waiting_icon_white");
  this.add(this.waitingIcon);
}

G.AdLoadOverlay.prototype = Object.create(Phaser.Group.prototype);