import { EDITOR_SYMBOLS } from './BoardConstants';

const BG_ASSETS = {
  BORDER_STRAIGHT: 'tile_border_straight',
  INSIDE_CORNER: 'tile_border_inside_corner',
  OUTSIDE_CORNER: 'tile_border_outside_corner',
};

/**
 * class for dynamic board backgrounds
 */
export class BoardBackground extends Phaser.Group {
  /**
   * constructor
   * @param {Board} board
   */
  constructor(board) {
    super(game);
    this._board = board;
    this._borderSize = 8;

    this._renderTexture = game.add.renderTexture(1, 1);

    this._markerImg = game.make.image();
    this._markerImg.anchor.setTo(0.5);
  }

  /**
   * redraw the renderTexture
   */
  redraw() {
    this._renderTexture.resize(
      (this._board.boardGridData.width + 2) * this._board.tileSize,
      (this._board.boardGridData.height + 2) * this._board.tileSize,
      true,
    );
    this._renderTexture.clear();
    this.drawBg();
  }

  /**
   * draw the background tiles to the renderTexture
   */
  drawBg() {
    for (let col = -1; col < this._board.boardGridData.width + 1; col++) {
      for (let row = -1; row < this._board.boardGridData.height + 1; row++) {
        const tile = this.checkIfTile(col, row);
        const up = this.checkIfTile(col, row - 1);
        const down = this.checkIfTile(col, row + 1);
        const left = this.checkIfTile(col - 1, row);
        const right = this.checkIfTile(col + 1, row);
        const ur = this.checkIfTile(col + 1, row - 1);
        const ul = this.checkIfTile(col - 1, row - 1);
        const dr = this.checkIfTile(col + 1, row + 1);
        const dl = this.checkIfTile(col - 1, row + 1);

        if (tile) {
          const tileAsset = `tile_${1 + (((col % 2) + (row % 2)) % 2)}`;
          this.drawSprite(col, row, tileAsset, 0);
          continue;
        }

        if (up) this.drawSprite(col, row, BG_ASSETS.BORDER_STRAIGHT, 180);
        if (down) this.drawSprite(col, row, BG_ASSETS.BORDER_STRAIGHT, 0);
        if (left) this.drawSprite(col, row, BG_ASSETS.BORDER_STRAIGHT, 90);
        if (right) this.drawSprite(col, row, BG_ASSETS.BORDER_STRAIGHT, 270);

        if (dr && !right && !down) this.drawSprite(col, row, BG_ASSETS.OUTSIDE_CORNER, 0);
        if (dl && !left && !down) this.drawSprite(col, row, BG_ASSETS.OUTSIDE_CORNER, 90);
        if (ur && !right && !up) this.drawSprite(col, row, BG_ASSETS.OUTSIDE_CORNER, 270);
        if (ul && !left && !up) this.drawSprite(col, row, BG_ASSETS.OUTSIDE_CORNER, 180);

        if (down && right) this.drawSprite(col, row, BG_ASSETS.INSIDE_CORNER, 0);
        if (down && left) this.drawSprite(col, row, BG_ASSETS.INSIDE_CORNER, 90);
        if (up && right) this.drawSprite(col, row, BG_ASSETS.INSIDE_CORNER, 270);
        if (up && left) this.drawSprite(col, row, BG_ASSETS.INSIDE_CORNER, 180);
      }
    }
  }

  /**
   * draw a sprite to the renderTexture
   * @param {number} x
   * @param {number} y
   * @param {Phaser.Sprite} sprite sprite to draw to the renderTexture
   * @param {number} angle angle to draw at in degrees
   */
  drawSprite(x, y, sprite, angle) {
    const px = {
      x: x * this._board.tileSize,
      y: y * this._board.tileSize,
    };

    this._markerImg.angle = angle || 0;
    G.changeTexture(this._markerImg, sprite);
    this._markerImg.updateTransform();

    this._renderTexture.renderXY(this._markerImg, px.x + (this._board.tileSize * 1.5), px.y + (this._board.tileSize * 1.5));
  }

  /**
   * checks if there is a tile at the cell x,y
   * @param {number} x
   * @param {number} y
   */
  checkIfTile(x, y) {
    const val = this._board.boardGridData.get(x, y);
    if (val === false) return false;
    if (val === EDITOR_SYMBOLS.BLANK) return false;
    return true;
  }

  /**
   * destruction method
   */
  destroy() {
    this._renderTexture.destroy(true);
    super.destroy();

    this._board = null;
    this._renderTexture = null;
    this._markerImg = null;
  }

  /**
   * get a reference to the generated renderTexture
   * @returns {Phaser.RenderTexture}
   */
  get renderTexture() {
    return this._renderTexture;
  }
}
