/* eslint-disable no-shadow */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable func-names */
import { Window } from '../../../00_IMMEDIATE/Window';
import { Window_FillRateFallback } from '../Window_FillRateFallback';
import OMT_UI_SquareButton, { BUTTONCOLOURS, BUTTONSTATE } from '../../../OMT_UI/OMT_UI_SquareButton';
import { LIGHTS_MODES } from '../../SpinningWheels/RealMoneyWheel/Props/LightsConfig';
import {
  RMWHEEL_EPS,
  RMWHEEL_EVENTS,
  RMWHEEL_PCMETRICS,
} from '../../SpinningWheels/RealMoneyWheel/rmWheelEnums';
import { createRealMoneySpinId, isConversionToEnum } from '../../SpinningWheels/RealMoneyWheel/RealMoneyWheelHelpers';
import ShopUtils from '../Shop/Shop_Utils';
import { TextTimer } from '../../TextTimer';
// new prize wheels
import OMT_DailyPrizeWheel from '../../SpinningWheels/OMT_DailyPrizeWheel';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import OMT_RealMoneyPrizeWheel from '../../SpinningWheels/RealMoneyWheel/OMT_RealMoneyPrizeWheel';
import { dailyWheelResponsiveConfig as RESPONSIVE_CONFIG } from './PrizeWheelWindowConfig';

/**
 * Window Class for the daily wheel reward
 */
class Window_DailyWheel extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} config
   */
  constructor(parent, config) {
    super(parent);
    this._worldState = config.worldState;
    this._moreMoneyMode = config.asMoreMoney;
    this._isAdFallback = config.isAdFallback === true;
    this._showCloseButtonInitially = config.showCloseButton !== undefined ? config.showCloseButton === true : true;
    this._responsiveSettings = G.OMTsettings.elements.Window_dailyWheel;

    if (this._isLandscape) {
      this.y = this._worldState ? 0 : G.WindowMgr.Constants.WorldVerticalOffset;
    } else {
      this.y = this._worldState ? -50 : -50 + G.WindowMgr.Constants.WorldVerticalOffset;
    }

    if (!this._worldState) this._coinBar = this.add(new G.CoinBar(0, -300, true));

    this._incentivised = game.incentivised();
    this._premium = false;

    this._realMoneyMode = false;
    this._showRealMoneyWheel = false;
    // this._dataCapture = DDNA.tracking.getDataCapture();
    this._realMoneyIsConversion = G.IAP; //this._dataCapture.getRealMoneyWheelConversionStatus();
    this._timesSpun = 0;
    this._spinId = '';

    this._wheelIsLimited = OMT.feature.prizeWheelIsLimited();
    this._wheelLimitConfig = G.json.settings.prizeWheelLimits;

    this._initRealMoneyBanner();
    this._initWheel();
    this._initSpinCounter();
    // set regular or premium rewards
    if (this._isAdFallback || (!this._moreMoneyMode && G.saveState.data.freeSpin)) this._changeToRegular();
    else this._changeToPremium(false);
    this.add(this._activeWheel);
    this._initPrizeBanner();
    this._initButtons();
    this._initPostSpinElements();

    // Get price for a real money wheel spin
    if (OMT.feature.getFeatureRealMoneyReplacementWheel() && G.IAP) {
      this._getRealMoneyWheelPrice();
    } else {
      this._realMoneyWheelOffer = null;
    }

    this._giftGroup = this.add(game.make.group());
    this._giftGroup.y = 80;

    this._startInitialAnimation();

    this._onResize();
    this._onResizeSB = G.sb('onScreenResize').add(this._onResize, this);
  }

  /**
   * Screen resize listener callback
   */
  _onResize() {
    const {
      screenWidth,
      screenHeight,
      bannerConfig,
      spinButtonConfig,
      mascotHostConfig,
    } = RESPONSIVE_CONFIG;
    const lerpFactor = {
      x: G.lerpFactor(game.width, screenWidth.min, screenWidth.max),
      y: G.lerpFactor(game.height, screenHeight.min, screenHeight.max),
    };

    const parentBannerY = lerpFactor.y * bannerConfig.parentOffset.y;
    const { elemOffsets: bannerOffsets } = bannerConfig;

    // Position banners
    this._realMoneySuperBannerGroup.y = parentBannerY;
    this._realMoneyBannerGroup.y = parentBannerY;

    this._bannerGroup.y = parentBannerY + bannerOffsets.bannerGroup.y;

    // Position close button
    this._closeButton.x = G.lerp(bannerOffsets.closeButton.x.min, bannerOffsets.closeButton.x.max, lerpFactor.x, 0);
    this._closeButton.y = parentBannerY + bannerOffsets.closeButton.y;
    this._realMoneyQuitButton.x = this._closeButton.x;
    this._realMoneyQuitButton.y = parentBannerY + bannerOffsets.realMoneyQuitButton.y;

    // Position spin, stop, and claim buttons
    const { elemOffsets: spinBtnOffsets } = spinButtonConfig;
    const parentSpinBtnY = G.lerp(
      spinButtonConfig.parentOffset.y.min,
      spinButtonConfig.parentOffset.y.max,
      lerpFactor.y,
      0,
    );

    this._freeStopBtn.y = parentSpinBtnY;
    this._premiumSpinBtn.y = parentSpinBtnY;
    this._premiumStopBtn.y = parentSpinBtnY;
    this._realMoneySpinBtn.y = parentSpinBtnY + G.lerp(
      spinBtnOffsets.realMoneySpinBtn.y.min,
      spinBtnOffsets.realMoneySpinBtn.y.max,
      lerpFactor.y,
    );

    this._claimButtonRegularY = parentSpinBtnY;

    // Position Mascot Host
    if (this._realMoneyGame && this._realMoneyGame.gingy) {
      this._realMoneyGame.gingy.x = G.lerp(
        mascotHostConfig.offset.x.min,
        mascotHostConfig.offset.x.max,
        lerpFactor.x,
      );
    }
  }

  /**
   * Get price for real money wheel and set its spin button's label
   */
  async _getRealMoneyWheelPrice() {
    const catalog = await OMT.payments.getCatalogAsync();
    const realMoneyOffers = ShopUtils.getRealMoneyWheelOffer(
      catalog,
      RMWHEEL_EPS.Replacement,
      isConversionToEnum(this._realMoneyIsConversion),
    );
    // eslint-disable-next-line prefer-destructuring
    this._realMoneyWheelOffer = realMoneyOffers[0];
    this._updateSpinBtnLabel();
  }

  /**
   * Updates spin button label
   */
  _updateSpinBtnLabel() {
    if (this._realMoneyWheelOffer) {
      this._realMoneySpinBtn.buttonText.text = this._realMoneyWheelOffer.price;
      this._realMoneySpinBtn.currentState = BUTTONSTATE.ENABLED;
    } else {
      this._realMoneySpinBtn.buttonText.text = '---';
      this._realMoneySpinBtn.currentState = BUTTONSTATE.DISABLED;
    }
  }

  /**
   * start the intro animation
   */
  _startInitialAnimation() {
    this._premiumSpinBtn.visible = false;
    this._freeStopBtn.visible = false;
    // this._activeWheel.fillWheel();
    // this._activeWheel.enterTheWheel();
    game.add.tween(this._activeWheel)
      .to({ y: this._activeWheel.y }, 500, Phaser.Easing.Circular.Out, true)
      .onComplete.add(() => {
        if (this.game === null) return;
        if (G.saveState.data.firstTimeWheelSpinner
            && game.incentivised() && (this._premium || !OMT.feature.prizeWheelFreeSpins())) {
          // Show premium button
          game.time.events.add(600, () => {
            if (this._shouldGiveAnotherSpin()) {
              this._premiumSpinBtn.currentState = BUTTONSTATE.ENABLED;
              if (this._wheelIsLimited) {
                this._spinsLeftText.visible = this._incentivised;
              }
            } else {
              this._premiumSpinBtn.currentState = BUTTONSTATE.DISABLED;
              if (this._wheelIsLimited) {
                this._spinCooldownGroup.visible = this._incentivised;
              }
            }
            this._premiumSpinBtn.visible = true;
          });
        } else {
          // Show free button
          game.time.events.add(900, () => {
            if (this._shouldGiveAnotherSpin()) {
              this._freeStopBtn.currentState = BUTTONSTATE.ENABLED;
              if (this._wheelIsLimited) {
                this._spinsLeftText.visible = false;
              }
            } else {
              this._freeStopBtn.currentState = BUTTONSTATE.DISABLED;
              if (this._wheelIsLimited) {
                this._spinCooldownGroup.visible = this._incentivised;
              }
            }
            this._freeStopBtn.visible = true;
          });

          // Start wheel spin
          game.time.events.add(400, () => {
            if (this._shouldGiveAnotherSpin()) {
              this._startSpinningWheel_Free();
            }
          });
        }
      });
  }

  /**
   * init the prize wheel instance
   */
  _initWheel() {
    // create prize wheel instance and set prizes
    const prizeWheel = new OMT_DailyPrizeWheel({
      isPremiumMode: G.IAP === true,
      isMoreMoneyMode: this._moreMoneyMode,
      isFirstTimeSpinning: !G.saveState.data.firstTimeWheelSpinner,
      ...OMT_Utils.stringToReference('wheelConfigs.dailyPrizeWheel', G.OMTsettings.elements),
    });

    if (this._isLandscape) {
      prizeWheel.scale.setTo(1.15);
    }

    prizeWheel.y = 135;
    this._dailyGame = prizeWheel;
  }

  /**
   * init the prize wheel banner
   */
  _initPrizeBanner() {
    this._bannerGroup = new Phaser.Group(game, this);

    if (!G.IAP && !this._moreMoneyMode) {
      const { elemOffsets } = RESPONSIVE_CONFIG.bannerConfig;

      this._ribbonImg = G.makeImage(0, elemOffsets.ribbon.y, 'gold_wheel_ribbon', 0.5, this._bannerGroup);
      this._ribbonTxt = new G.Text(0, -10, OMT.language.getText('3x higher winnings!'), {
        style: 'font-white',
        fontSize: '50px',
      }, 0.5, 350);
      this._ribbonImg.addChild(this._ribbonTxt);
      this._ribbonImg.scale.setTo(0);
    }

    this._banner = G.makeImage(0, 0, 'green_wheel_ribbon', 0.5, this._bannerGroup);
    this._banner.scale.setTo(1.25);
    this._dailyTxt = new G.Text(0, -10, OMT.language.getText('Prize wheel'), {
      style: 'font-white',
      fontSize: '58px',
    }, 0.5, 400);
    this._bannerGroup.add(this._dailyTxt);
    this._bannerGroup.visible = true;
  }

  /**
   * Init the real money banner
   */
  _initRealMoneyBanner() {
    // Regular Banner
    this._realMoneyBannerGroup = new Phaser.Group(game, this);
    this._realMoneyBanner = G.makeImage(0, -210, 'realMoney_wheel_banner', 0.5, this._realMoneyBannerGroup);
    this._realMoneyTxt = new G.Text(0, -210, OMT.language.getText('Golden Wheel'), {
      style: 'font-white',
      fontSize: '47px',
    }, 0.5, 400);
    this._realMoneyBannerGroup.add(this._realMoneyTxt);
    this._realMoneyBannerGroup.visible = false;

    // Super Banner
    this._realMoneySuperBannerGroup = new Phaser.Group(game, this);
    this._realMoneySuperBanner = G.makeImage(0, -210, 'realMoney_wheel_banner', 0.5, this._realMoneySuperBannerGroup);
    this._realMoneySuperTxt = new G.Text(0, -210, OMT.language.getText('Super Golden Wheel'), {
      style: 'font-white',
      fontSize: '47px',
    }, 0.5, 400);
    this._realMoneySuperBannerGroup.add(this._realMoneySuperTxt);
    this._realMoneySuperBannerGroup.visible = false;
  }

  /**
   * init window buttons
   */
  _initButtons() {
    // close button
    this._closeButton = new G.Button(260, -190, 'btn_x', this._closeButtonPressed, this);
    this.registerButtons(this._closeButton);
    this._closeButton.visible = this._showCloseButtonInitially;

    // stay button for real money wheel
    this._stayButton = new OMT_UI_SquareButton(130, 90, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 230,
          height: 100,
        },
        isEnabled: false,
      },
      text: {
        string: OMT.language.getText('Stay'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: this._onStayButtonPressed.bind(this),
        },
      },
    });
    this.add(this._stayButton);
    this._stayButton.visible = false;
    this._stayButton.currentState = BUTTONSTATE.DISABLED;

    // leave button for real money wheel
    this._leaveButton = new OMT_UI_SquareButton(-130, 90, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 230,
          height: 100,
        },
        isEnabled: false,
      },
      text: {
        string: OMT.language.getText('Leave'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: this._onLeaveButtonPressed.bind(this),
        },
      },
    });
    this.add(this._leaveButton);
    this._leaveButton.visible = false;
    this._leaveButton.currentState = BUTTONSTATE.DISABLED;

    // standard stop button
    this._freeStopBtn = new OMT_UI_SquareButton(0, 405, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
        isEnabled: false,
      },
      text: {
        string: OMT.language.getText('Stop'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: this._stopSpinningWheel_Free.bind(this),
        },
      },
    });
    this.add(this._freeStopBtn);
    this._freeStopBtn.visible = false;

    // alt spin button for more money mode
    const adImg = G.makeImage(0, 0, 'btn-movie-icon', 0.5, null);
    this._premiumSpinBtn = new OMT_UI_SquareButton(0, 405, {
      button: {
        tint: BUTTONCOLOURS.purple,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.feature.prizeWheelFreeSpins()
          ? OMT.language.getText('Spin again')
          : OMT.language.getText('Spin'),
        textStyle: {
          style: 'font-white',
          fontSize: 40,
        },
        icon: {
          image: adImg,
          offset: {
            x: adImg.width / 2,
          },
          align: 'left',
        },
        offset: {
          x: adImg.width / 4,
        },
        dimensionMods: {
          width: 0.6,
        },
      },
      options: {
        clickFunction: {
          onClick: this._showSpinWheelAd.bind(this),
        },
        cacheButton: false,
      },
    });
    this.add(this._premiumSpinBtn);
    this._premiumSpinBtn.visible = false;

    // alt stop button for more money mode
    this._premiumStopBtn = new OMT_UI_SquareButton(0, 435, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Stop'),
        textStyle: {
          style: 'font-white',
          fontSize: 49,
        },
      },
      options: {
        clickFunction: {
          onClick: this._stopSpinningWheel_Premium.bind(this),
        },
        cacheButton: false,
      },
    });
    this.add(this._premiumStopBtn);
    this._premiumStopBtn.visible = false;

    // alt spin button for more money mode
    this._realMoneySpinBtn = new OMT_UI_SquareButton(0, 505, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: '---',
        textStyle: {
          style: 'font-white',
          fontSize: 40,
        },
        dimensionMods: {
          width: 0.6,
        },
      },
      options: {
        clickFunction: {
          onClick: this._buyRealMoneySpin.bind(this),
        },
        cacheButton: false,
      },
    });
    this.add(this._realMoneySpinBtn);
    this._realMoneySpinBtn.visible = false;

    // stop button for real money wheel
    this._realMoneyStopBtn = new OMT_UI_SquareButton(0, 505, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Stop'),
        textStyle: {
          style: 'font-white',
          fontSize: 49,
        },
      },
      options: {
        clickFunction: {
          onClick: this._stopSpinningWheel_RealMoney.bind(this),
        },
        cacheButton: false,
      },
    });
    this.add(this._realMoneyStopBtn);
    this._realMoneyStopBtn.visible = false;

    this._realMoneyQuitButton = new G.Button(260, -210, 'btn_x', this._showRealMoneyQuitDialog, this);
    this.add(this._realMoneyQuitButton);
    this._realMoneyQuitButton.visible = false;
    this._realMoneyQuitButton.currentState = BUTTONSTATE.DISABLED;
  }

  /**
   * init spin quota counter and cooldown timer (shown if number of spins is limited)
   */
  _initSpinCounter() {
    if (this._wheelIsLimited) {
      // counter
      const spinsLeft = Math.max(this._wheelLimitConfig.numSpins - G.saveState.getPrizeWheelSpinCount(), 0);
      const spinTemplate = spinsLeft === 1
        ? OMT.language.getText('%number% Spin left')
        : OMT.language.getText('%number% Spins left');
      const spinText = spinTemplate.replace('%number%', spinsLeft);

      this._spinsLeftText = new G.Text(0, 525, spinText, {
        style: 'font-white',
        fontSize: '58px',
      }, 0.5, 400);
      this.add(this._spinsLeftText);
      this._spinsLeftText.visible = false;

      // timer
      this._spinCooldownGroup = game.make.group();
      this._spinCooldownIcon = G.makeImage(-113, 525, 'clock_icon', 0.5, this._spinCooldownGroup);
      this._spinCooldownIcon.scale.set(0.65, 0.65);
      this._spinCooldownTimer = new TextTimer({
        x: 42,
        y: 525,
        style: {
          style: 'font-white',
          fontSize: '58px',
        },
        anchor: 0.5,
        maxWidth: 480,
        timerFormat: 'hms',
        wrapperText: '%TIME%',
      });
      const cooldownRemaining = Math.floor(G.saveState.getUserCooldownRemaining('prizeWheelSpins', '') / 1000);
      this._spinCooldownTimer.setSecLeft(cooldownRemaining);
      this._spinCooldownTimer.active = true;

      this._spinCooldownGroup.add(this._spinCooldownTimer);
      this.add(this._spinCooldownGroup);
      this._spinCooldownGroup.visible = false;

      // refill signal
      G.sb('onPrizeWheelSpinRefill').add(() => {
        this._freeStopBtn.currentState = BUTTONSTATE.ENABLED;
        this._premiumSpinBtn.currentState = BUTTONSTATE.ENABLED;

        const spinsLeft = Math.max(this._wheelLimitConfig.numSpins - G.saveState.getPrizeWheelSpinCount(), 0);
        const spinTemplate = spinsLeft === 1
          ? OMT.language.getText('%number% Spin left')
          : OMT.language.getText('%number% Spins left');
        this._spinsLeftText.text = spinTemplate.replace('%number%', spinsLeft);
        this._spinsLeftText.visible = this._incentivised;
        this._spinCooldownGroup.visible = false;
      }, this);
    }
  }

  /**
   * init the post spin / reward elements
   */
  _initPostSpinElements() {
    this._youWinTxt = new G.Text(0, 0, '', {
      style: 'font-white',
      fontSize: '60px',
    }, 0.5, 500);
    this.add(this._youWinTxt);
    this._youWinTxt.visible = false;

    this._claimButton = new OMT_UI_SquareButton(0, 405, {
      button: {
        tint: BUTTONCOLOURS.green,
        dimensions: {
          width: 309,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Claim'),
        textStyle: {
          style: 'font-white',
          fontSize: 50,
        },
      },
      options: {
        clickFunction: {
          onClick: this._claimBtnAction.bind(this),
        },
      },
    });

    this.registerButtons(this._claimButton);
    const tw = game.add.tween(this._claimButton).to({ alpha: 0 }, 150, Phaser.Easing.Sinusoidal.InOut, true);
    tw.onComplete.add(() => {
      this._claimButton.visible = false;
    });
  }

  /**
   * change to regular reward mode
   */
  _changeToRegular() {
    this._premium = false;
    if (this._ribbonImg && this._ribbonImg.scale.x > 0) {
      G.stopTweens(this._ribbonImg);
      game.add.tween(this._ribbonImg.scale).to({ x: 0, y: 0 }, 200, Phaser.Easing.Cubic.In, true);
    }
    this._activeWheel = this._dailyGame;
  }

  /**
   * Creates the premium wheel
   */
  _makePremiumWheel() {
    // create prize wheel instance and set prizes
    const prizeWheel = new OMT_DailyPrizeWheel({
      isPremiumMode: true,
      isMoreMoneyMode: this._moreMoneyMode,
      isFirstTimeSpinning: false,
      ...OMT_Utils.stringToReference('wheelConfigs.dailyPrizeWheel', G.OMTsettings.elements),
    });

    if (this._isLandscape) {
      prizeWheel.scale.setTo(1.15);
    }

    prizeWheel.y = 135;
    this._dailyGamePremium = prizeWheel;
  }

  /**
   * Creates real money wheel
   * @param {boolean} isConversion
   */
  _makeRealMoneyWheel(isConversion) {
    // Real money wheel takes a prizeList.wheelMode parameter in its config instead of prizeList.current,
    // which is filled in by the wheel itself
    this._realMoneyGame = new OMT_RealMoneyPrizeWheel({
      prizeList: {
        predeterminedPrize: -1,
      },
      entryPoint: RMWHEEL_EPS.Replacement,
      wheelMode: isConversionToEnum(isConversion),
      ...OMT_Utils.stringToReference('wheelConfigs.realMoneyPrizeWheel', G.OMTsettings.elements),
    });
    this._realMoneyGame.y = 100;
    this._onResize();
  }

  /**
   * change to premium reward mode
   */
  _changeToPremium(playAnimation) {
    if (!this._dailyGamePremium) {
      this._makePremiumWheel();
    }
    const clearDailyGame = () => {
      if (this._dailyGame) {
        this._dailyGame.destroy();
        this._dailyGame = null;
      }
    };
    this._activeWheel = this._dailyGamePremium;
    this._premium = true;
    if (this._ribbonImg && this._ribbonImg.scale.x < 1) {
      G.stopTweens(this._ribbonImg);
      game.add.tween(this._ribbonImg.scale).to({ x: 1, y: 1 }, 500, Phaser.Easing.Elastic.Out, true);
    }
    if (playAnimation) {
      const tempPos = this._dailyGame.x;
      this._dailyGamePremium.x = 1000;
      this.addChildAt(this._dailyGamePremium, this.getChildIndex(this._dailyGame));
      game.add.tween(this._dailyGamePremium)
        .to({ x: tempPos }, 600, Phaser.Easing.Circular.Out, true);
      const tw = game.add.tween(this._dailyGame)
        .to({ x: -1000 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
      tw.onComplete.add(clearDailyGame, this);
    } else {
      clearDailyGame();
    }
  }

  /**
   * Change to real money wheel
   * @param {boolean} playAnimation
   * @param {boolean} isConversion
   */
  _changeToRealMoney(playAnimation, isConversion) {
    if (this._wheelIsLimited) {
      this._spinCooldownGroup.visible = false;
      this._spinsLeftText.visible = false;
    }

    // Update real money replacement wheel seen state
    G.saveState.resetRealMoneyWheelSessionsNotSeen();

    if (!this._realMoneyGame) {
      this._makeRealMoneyWheel(isConversion);
    }
    game.time.events.add(3500, () => {
      this._realMoneySpinBtn.visible = true;
    });

    // Keep a reference to the currently active wheel
    // (which could be either the free or premium wheel)
    let oldActiveWheel = this._activeWheel;

    const clearDailyGame = () => {
      if (oldActiveWheel) {
        if (oldActiveWheel.parent) {
          oldActiveWheel.parent.removeChild(oldActiveWheel);
        }
        oldActiveWheel.destroy();
        oldActiveWheel = null;
      }
    };

    this._activeWheel = this._realMoneyGame;
    this._closeButton.visible = false;
    this._closeButton.currentState = BUTTONSTATE.DISABLED;

    if (playAnimation) {
      this._onResize();

      game.time.events.add(3500, () => {
        this._realMoneyQuitButton.visible = true;
        this._realMoneyQuitButton.currentState = BUTTONSTATE.ENABLED;
      });

      const tempPos = oldActiveWheel.x;
      this._realMoneyGame.x = 1000;
      this.addChildAt(this._realMoneyGame, this.getChildIndex(oldActiveWheel));
      game.add.tween(this._realMoneyGame)
        .to({ x: tempPos }, 600, Phaser.Easing.Circular.Out, true);
      const tw = game.add.tween(oldActiveWheel.container || oldActiveWheel)
        .to({ x: -1000 }, 300, Phaser.Easing.Sinusoidal.InOut, true);
      tw.onComplete.add(clearDailyGame, this);

      game.add.tween(this._bannerGroup).to({ x: -1000, alpha: 0 }, 600, Phaser.Easing.Circular.Out, true)
        .onComplete.add(() => {
          this._bannerGroup.visible = false;
        });

      if (this._realMoneyIsConversion) {
        this._realMoneyBannerGroup.visible = true;
        this._realMoneyBannerGroup.x = 1000;
        this._realMoneyBannerGroup.alpha = 0;
        game.add.tween(this._realMoneyBannerGroup).to({ x: 0, alpha: 1 }, 600, Phaser.Easing.Circular.Out, true);
      } else {
        this._realMoneySuperBannerGroup.visible = true;
        this._realMoneySuperBannerGroup.x = 1000;
        this._realMoneySuperBannerGroup.alpha = 0;
        game.add.tween(this._realMoneySuperBannerGroup).to({ x: 0, alpha: 1 }, 600, Phaser.Easing.Circular.Out, true);
      }
    } else {
      clearDailyGame();
    }
  }

  _attachListenersToWheel(wheel, isRealMoneyMode) {
    wheel.signals.collectWheelPrizeVisually.add((prizeSnapshot) => {
      this._darkenWheel();

      if (isRealMoneyMode) {
        this._realMoneyGame.moveGingy(false);
        const isJackpot = prizeSnapshot.current[prizeSnapshot.chosenPrize].jackpot;
        this._showPrizeRealMoney(prizeSnapshot.current[prizeSnapshot.chosenPrize], isJackpot, false);
      } else {
        this._showPrize(prizeSnapshot.current[prizeSnapshot.chosenPrize]);
      }
    });
  }

  /**
   * start free wheel spin
   */
  _startSpinningWheel_Free() {
    this._activeWheel.setSpinningState();

    if (this._wheelIsLimited) {
      this._spinsLeftText.visible = false;
      this._spinCooldownGroup.visible = false;
    }
  }

  /**
   * stop free wheel spin
   */
  async _stopSpinningWheel_Free() {
    if (this._wheelIsLimited && this._incentivised) {
      G.saveState.incrementPrizeWheelSpinCount(1);
      this._updateSpinLimitState();
    }

    this._freeStopBtn.visible = false;
    if (!this._moreMoneyMode) {
      OMT.platformTracking.logFTUEvent('FTUX_FirstSpinMade', null);
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.AdWheelSpin, null);
    }

    // spin to a random prize
    this._activeWheel.spinToNextPrize((item) => {
      this._showPrize(item.data.reward);
    });

    G.sb('wheelSpin').dispatch();
    if (!G.saveState.data.firstTimeWheelSpinner) {
      G.saveState.markWheelAsSpun(false);
      this._activeWheel.isFirstTimeSpinning = false;
    }
    this._closeButton.visible = false;

    if (!this._isAdFallback) {
      G.saveState.data.freeSpin = false;
      G.saveState.save();

      // Schedule game triggered message for next wheel spin, time in hours rounded up
      const secondsUntilNextFreeSpin = Math.ceil((24 * 60 * 60) - ((Date.now() - G.saveState.data.lastDaily) / 1000));
      await OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, 'ResetWheel', secondsUntilNextFreeSpin);
    }
    this._premiumSpinBtn.visible = false;
  }

  /**
   * start spinning wheel premium
   */
  _startSpinningWheel_Premium() {
    this._activeWheel.setSpinningState();

    this._closeButton.visible = false;
    this._premiumSpinBtn.visible = false;

    if (this._wheelIsLimited) {
      this._spinsLeftText.visible = false;
      this._spinCooldownGroup.visible = false;
    }
  }

  /**
   * stop spinning wheel premium
   */
  async _stopSpinningWheel_Premium() {
    if (this._wheelIsLimited && this._incentivised) {
      G.saveState.incrementPrizeWheelSpinCount(1);
      this._updateSpinLimitState();
    }

    // spin to a random prize
    this._activeWheel.spinToNextPrize((item) => {
      this._showPrize(item.data.reward);
    });

    OMT.platformTracking.logEvent(OMT.platformTracking.Events.AdMoreCoinsWheelSpin);

    G.sb('wheelSpin').dispatch();
    if (!G.saveState.data.firstTimeWheelSpinner) {
      G.saveState.markWheelAsSpun(false);
      this._activeWheel.isFirstTimeSpinning = false;
    }

    if (!this._isAdFallback) {
      G.saveState.data.freeSpin = false;
      G.saveState.save();

      // Schedule game triggered message for next wheel spin, time in hours rounded up
      const secondsUntilNextFreeSpin = Math.ceil((24 * 60 * 60) - ((Date.now() - G.saveState.data.lastDaily) / 1000));
      await OMT.notifications.scheduleGameTriggeredMessage(OMT.envData.settings.user.userId, 'ResetWheel', secondsUntilNextFreeSpin);
    }
    this._premiumStopBtn.visible = false;
  }

  /**
   * Purchase a real money spin
   */
  _buyRealMoneySpin() {
    if (!this._realMoneyWheelOffer) return;
    // const wheelMode = isConversionToEnum(this._realMoneyIsConversion);

    ShopUtils.purchaseItem(this._realMoneyWheelOffer, null, null, false, () => {
      // DDNA.transactionHelper.queueRealMoneyWheelEvent(
      //   RMWHEEL_EPS.Replacement,
      //   RMWHEEL_EVENTS.Purchased,
      //   wheelMode,
      //   this._showRealMoneyWheel ? 1 : 0,
      // );
      this._realMoneyQuitButton.visible = false;
      this._realMoneyQuitButton.currentState = BUTTONSTATE.DISABLED;
      this._startSpinningWheel_RealMoney();
    });
  }

  /**
   * start spinning real money wheel
   */
  _startSpinningWheel_RealMoney() {
    const wheelMode = isConversionToEnum(this._realMoneyIsConversion);
    this._spinId = createRealMoneySpinId(null, RMWHEEL_EPS.Replacement, false, true);

    // this._dataCapture.addToPlayerCharacterizationParam(RMWHEEL_PCMETRICS[RMWHEEL_EPS.Replacement][wheelMode], 1);
    this._realMoneyGame.spinId = this._spinId;
    this._activeWheel.setSpinningState();
    this._realMoneyGame.gingy.toggleSpeech(false, isConversionToEnum(this._realMoneyIsConversion));

    // Move mascot host down only if in portrait mode
    if (game.width <= RESPONSIVE_CONFIG.screenWidth.min) {
      this._realMoneyGame.moveGingy(true);
    }

    this._realMoneyGame.lights.changeLightsMode(LIGHTS_MODES.Alternating);
    this._realMoneySpinBtn.visible = false;
    G.saveState.addPendingRealMoneySpin(wheelMode, this._spinId);
    game.time.events.add(1400, () => {
      this._realMoneyStopBtn.visible = true;
    });
  }

  /**
   * stop spinning real money wheel
   */
  async _stopSpinningWheel_RealMoney() {
    // spin to a random prize
    this._activeWheel.spinToNextPrize((item) => {
      const isJackpot = item.data.reward.jackpot;
      this._showPrizeRealMoney(item.data.reward, isJackpot, false);
    });

    G.sb('wheelSpin').dispatch();

    this._realMoneyStopBtn.visible = false;
  }

  /**
   * darken the wheel graphics
   */
  _darkenWheel() {
    this._activeWheel.darken();
  }

  /**
   * lighten the wheel graphics
   */
  _brightenWheel() {
    this._activeWheel.brighten();
  }

  /**
   * on ad successfully shown
   */
  _adSuccess() {
    this._startSpinningWheel_Premium();
    this._premiumSpinBtn.visible = false;
    game.time.events.add(1400, () => {
      this._premiumStopBtn.visible = true;
    });
  }

  /**
   * show and ad for a wheel spin
   */
  _showSpinWheelAd() {
    if (this._moreMoneyMode) {
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.AdMoreCoinsWheelSpin);
    } else {
      OMT.platformTracking.logFTUEvent('FTUX_FirstAdSpinMade', null);
      OMT.platformTracking.logEvent(OMT.platformTracking.Events.AdWheelSpin);
    }

    OMT.ads.showAd(
      G.BuildEnvironment.adPlacements.spinTheDailyWheel,
      this._adSuccess.bind(this),
      () => {
        if (!G.IAP) { // no IAPS dont use ad fallbacks
          // eslint-disable-next-line no-new
          new Window_FillRateFallback(undefined, {
            placement: G.BuildEnvironment.adPlacements.spinTheDailyWheel,
            callback: this._adSuccess.bind(this),
            onExit: () => {
              this._premiumSpinBtn.visible = true;
              this._closeButton.visible = true;
            },
            context: this,
          });
        } else { // open ad fallback
          OMT.ads.showAdFallback(this._adSuccess.bind(this));
        }
      },
    );
  }

  /**
   * on close button pressed
   */
  _closeButtonPressed() {
    if (this._wonPrize) {
      OMT.transactionTracking.logInventoryTransactionBegin();

      this._applyGift(this._moreMoneyMode, this._wonPrize);
      this._wonPrize = null;

      OMT.transactionTracking.logInventoryTransactionEnd();
    }
    G.sb('showMainHighscorePanel').dispatch();
    this._onResizeSB.detach();
    this.closeWindow();
  }

  /**
   * Show quit confirmation dialog for real money wheel
   */
  _showRealMoneyQuitDialog() {
    if (this._realMoneyMode) {
      this._realMoneyGame.gingy.toggleSpeech(false, isConversionToEnum(this._realMoneyIsConversion));
      this._realMoneyGame.toggleQuitBanner(true);
      this._realMoneyQuitButton.visible = false;
      this._realMoneyQuitButton.currentState = BUTTONSTATE.DISABLED;
      this._realMoneySpinBtn.visible = false;
      this._stayButton.visible = true;
      this._stayButton.currentState = BUTTONSTATE.ENABLED;
      this._leaveButton.visible = true;
      this._leaveButton.currentState = BUTTONSTATE.ENABLED;
    }
  }

  /**
   * stay button pressed action
   */
  _onStayButtonPressed() {
    if (this._realMoneyMode) {
      this._realMoneyGame.toggleQuitBanner(false);
      this._realMoneyQuitButton.visible = true;
      this._realMoneyQuitButton.currentState = BUTTONSTATE.ENABLED;
      this._realMoneySpinBtn.visible = true;
      this._stayButton.visible = false;
      this._stayButton.currentState = BUTTONSTATE.DISABLED;
      this._leaveButton.visible = false;
      this._leaveButton.currentState = BUTTONSTATE.DISABLED;
    }
  }

  /**
   * leave button pressed action
   */
  _onLeaveButtonPressed() {
    G.saveState.setRealMoneyWheelOnCoolDown(this._realMoneyIsConversion);
    this._realMoneyGame.destroy();
    this._closeButtonPressed();
  }

  /**
   * on claim button clicked
   */
  _claimBtnAction() {
    if (this._wonPrize.prize.indexOf('booster') === 0) {
      G.sfx.line.play();
    } else if (this._wonPrize.prize.indexOf('coin') === 0) {
      G.sfx.chain_rattle.play();
    }

    const oldIsConversion = this._realMoneyIsConversion; // store old isConversion value
    this._realMoneyIsConversion = G.IAP// this._dataCapture.getRealMoneyWheelConversionStatus();
    // const wheelMode = isConversionToEnum(this._realMoneyIsConversion);

    // Track if player is eligible for seeing the real money replacement wheel
    if (G.IAP && OMT.feature.getFeatureRealMoneyReplacementWheel()
      && G.saveState.getRealMoneyWheelOnCoolDown() <= 0) {
      // this._dataCapture.addToPlayerCharacterizationParam('eligibleReplacementMoneyWheel', 1);

      // DDNA.transactionHelper.queueRealMoneyWheelEvent(
      //   RMWHEEL_EPS.Replacement,
      //   RMWHEEL_EVENTS.Eligible,
      //   wheelMode,
      //   1,
      // );
    }

    // Roll for real money wheel if it's not on cooldown and game is in IAP environment
    // If player is already shown the real money wheel, then continue to show it
    const spinsUsed = this._wheelIsLimited
      ? Math.min(G.saveState.getPrizeWheelSpinCount(),
        this._wheelLimitConfig.numSpins,
        G.json.settings.realMoneyPrizeWheel.appearanceChance.length - 1)
      : Math.min(this._timesSpun, G.json.settings.realMoneyPrizeWheel.appearanceChance.length - 1);
    const currentAppearanceChance = G.json.settings.realMoneyPrizeWheel.appearanceChance[spinsUsed];
    this._timesSpun++;

    this._showRealMoneyWheel = G.IAP && this._realMoneyWheelOffer
      && OMT.feature.getFeatureRealMoneyReplacementWheel()
      && ((G.saveState.getRealMoneyWheelOnCoolDown() <= 0
      && Math.random() <= (currentAppearanceChance / 100))
      || this._realMoneyMode);

    // for testing real money hweel
    // this._showRealMoneyWheel = true;

    // Track real money wheel sighting (or no sighting). This will be sent
    // with the next transaction event sent in _applyPrize
    // DDNA.transactionHelper.queueRealMoneyWheelEvent(
    //   RMWHEEL_EPS.Replacement,
    //   RMWHEEL_EVENTS.Seen,
    //   wheelMode,
    //   this._showRealMoneyWheel ? 1 : 0,
    // );

    // Mark current spin as claimed
    if (this._spinId !== '') {
      const oldWheelMode = isConversionToEnum(oldIsConversion);
      G.saveState.removePendingRealMoneySpin(oldWheelMode, this._spinId);
      G.saveState.removeRealMoneySpinResult(this._spinId);
      G.saveState.save();
      this._spinId = '';
    }

    if (this._realMoneyMode) {
      this._applyPrize(this._wonPrize, this._realMoneyGame.giftGfx, oldIsConversion);
    } else {
      this._applyPrize(this._wonPrize, this._giftGfx, oldIsConversion);
    }
    this._wonPrize = false;
    this._claimButton.visible = false;
  }

  /**
   * show / animate in the users prize
   * @param {Array} prize
   */
  _showPrize(prize) {
    this._darkenWheel();
    this._giftGroup.removeChildren();
    let winTxt = '';
    if (prize.prize.indexOf('coin') !== -1) {
      winTxt = OMT.language.getText('More Coins!');
    } else if (prize.prize.indexOf('booster') !== -1) {
      if (prize.amount > 1) {
        winTxt = OMT.language.getText('%#% Boosters').replace('%#%', prize.amount.toString());
      } else {
        winTxt = OMT.language.getText('1 Booster');
      }
    }
    this._youWinTxt.setText(winTxt);
    this._youWinTxt.scale.setTo(0);
    this._youWinTxt.visible = true;
    game.add.tween(this._youWinTxt.scale).to({ x: 1, y: 1 }, 600, Phaser.Easing.Elastic.Out, true);

    this._giftGfx = new G.LabelTextT(G.gift.getLabelString([prize.prize, prize.amount]), 0, 65, {
      style: 'font-num-blue',
      fontSize: '110px',
      scaleStroke: true,
    }, 0.5, 300);
    this._giftGfx.scale.setTo(0);

    game.add.tween(this._giftGfx.scale).to({ x: 1, y: 1 }, 600, Phaser.Easing.Elastic.Out, true);

    this._giftGroup.add(this._giftGfx);

    this._wonPrize = prize;

    game.time.events.add(1000, () => {
      this._claimButton.y = this._claimButtonRegularY;
      this._claimButton.alpha = 1;
      this._claimButton.scale.setTo(0);
      game.add.tween(this._claimButton.scale).to({ x: 1, y: 1 }, 600, Phaser.Easing.Elastic.Out, true).onComplete.add(() => {
        if (this.game === null) return; // eslint-disable-line no-useless-return
      }, this);
      this._claimButton.visible = true;

      if (this._wheelIsLimited) {
        if (this._shouldGiveAnotherSpin()) {
          this._spinsLeftText.visible = this._incentivised;
        } else {
          this._spinCooldownGroup.visible = this._incentivised;
        }
      }

      if (this._shareCheckbox) {
        this._shareCheckbox.visible = true;
        this._shareCheckbox.alpha = 0;
        game.add.tween(this._shareCheckbox).to({ alpha: 1 }, 600, Phaser.Easing.Elastic.Out, true).onComplete.add(() => {
          if (this.game === null) return;
          this._shareCheckbox.ignoreChildInput = false;
        }, this);
      }
    }, this);
  }

  /**
   * show / animate in the users prize for the real money wheel
   * @param {Object} prize
   * @param {boolean} isJackpot
   * @param {boolean} autoClaim
   */
  _showPrizeRealMoney(prize, isJackpot, autoClaim) {
    this._darkenWheel();
    this._realMoneyGame.toggleWinText(true, prize, isJackpot);
    this._realMoneyGame.moveGingy(false);

    this._wonPrize = prize;

    game.time.events.add(1000, () => {
      if (autoClaim) {
        this._claimBtnAction();
      } else {
        this._claimButton.y = 505;
        this._claimButton.alpha = 1;
        this._claimButton.scale.setTo(0);
        game.add.tween(this._claimButton.scale).to({ x: 1, y: 1 }, 600, Phaser.Easing.Elastic.Out, true).onComplete.add(() => {
          if (this.game === null) return; // eslint-disable-line no-useless-return
        }, this);
        this._claimButton.visible = true;
      }

      if (this._shareCheckbox) {
        this._shareCheckbox.visible = true;
        this._shareCheckbox.alpha = 0;
        game.add.tween(this._shareCheckbox).to({ alpha: 1 }, 600, Phaser.Easing.Elastic.Out, true).onComplete.add(() => {
          if (this.game === null) return;
          this._shareCheckbox.ignoreChildInput = false;
        }, this);
      }
    }, this);
  }

  /**
   * apply the prize and set the window to reset
   * @param {Array} prize
   * @param {Object} sourceObj from which object the coins are flying from
   * @param {boolean} isConversion
   */
  _applyPrize(prize, sourceObj, isConversion) {
    OMT.transactionTracking.logInventoryTransactionBegin();
    this._wonPrize = null;

    const formattedGift = [prize.prize, prize.amount];
    if (prize.prize === 'coin' && this._worldState) {
      this.state.uiTargetParticles.createCoinBatch(
        game.world.bounds.x + sourceObj.worldPosition.x,
        sourceObj.worldPosition.y,
        this.state.panel.coinsTxt,
        prize.amount,
      );
      OMT.transactionTracking.addInventoryChange('coins', 0, prize.amount);
    } else {
      this._applyGift(this._moreMoneyMode, formattedGift);
    }

    OMT.transactionTracking.logInventoryTransactionEnd();
    // DDNA.transactionHelper.trackWheelReward(formattedGift, this._realMoneyMode, isConversion, false);

    if (!this._isAdFallback) {
      game.time.events.add(1000, this._restart, this);
    } else { // for ad fallback we just exit
      this._isAdFallback = false;
      game.time.events.add(1000, this._restart, this);
      // G.sb("showMainHighscorePanel").dispatch();
      // this.closeWindow();
    }
  }

  /**
   * apply prize data to user inventory
   * @param {boolean} moreMoneyMode
   * @param {Array} gift
   */
  _applyGift(moreMoneyMode, gift) {
    if (moreMoneyMode) {
      G.gift.applyGift(
        gift,
        G.gift.ItemType.GetCoinsSpin,
        this._worldState ? G.gift.ItemId.Map : G.gift.ItemId.InGame,
      );
    } else {
      G.gift.applyGift(gift, G.gift.ItemType.Reward, G.gift.ItemId.DailyReward);
    }
  }

  /**
   * Handles real money wheel mode change animations
   * @returns {boolean} whether a mode changed happened
   */
  _changeRealMoneyWheelMode() {
    const wheelMode = isConversionToEnum(this._realMoneyIsConversion);
    const changed = this._realMoneyGame.changeWheelMode(wheelMode, true);

    if (changed) {
      const incomingGroup = this._realMoneyIsConversion ? this._realMoneyBannerGroup : this._realMoneySuperBannerGroup;
      const outgoingGroup = this._realMoneyIsConversion ? this._realMoneySuperBannerGroup : this._realMoneyBannerGroup;

      // Tracking
      // if (this._realMoneyIsConversion) {
      //   this._dataCapture.addToPlayerCharacterizationParam('seenReplacementConversionMoneyWheel', 1);
      // } else {
      //   this._dataCapture.addToPlayerCharacterizationParam('seenReplacementHighValueWheel', 1);
      // }

      incomingGroup.visible = true;
      incomingGroup.x = 500;
      incomingGroup.alpha = 0;
      game.add.tween(incomingGroup).to({ alpha: 1 }, 250, Phaser.Easing.Quintic.Out, true, 1250);
      game.add.tween(incomingGroup).to({ x: 0 }, 750, Phaser.Easing.Quintic.Out, true, 1250)
        .onComplete.add(() => {
          this._realMoneySpinBtn.visible = true;
          this._realMoneyQuitButton.visible = true;
          this._realMoneyQuitButton.currentState = BUTTONSTATE.ENABLED;
        });

      outgoingGroup.visible = true;
      outgoingGroup.x = 0;
      outgoingGroup.alpha = 1;
      game.add.tween(outgoingGroup).to({ alpha: 0 }, 250, Phaser.Easing.Quintic.In, true, 500);
      game.add.tween(outgoingGroup).to({ x: -500 }, 750, Phaser.Easing.Quintic.In, true)
        .onComplete.add(() => {
          outgoingGroup.visible = false;
        });
    } else {
      // no wheel change, show spin and quit buttons again
      game.time.events.add(1000, () => {
        this._realMoneySpinBtn.visible = true;
        this._realMoneyQuitButton.visible = true;
        this._realMoneyQuitButton.currentState = BUTTONSTATE.ENABLED;
      });
    }

    return changed;
  }

  /**
   * restart the prize wheel flow
   */
  _restart() {
    if (!this._incentivised) {
      G.sb('showMainHighscorePanel').dispatch();
      this.closeWindow();
      return;
    }

    this._brightenWheel();

    if (this._shareCheckbox) {
      this._shareCheckbox.ignoreChildInput = true;
      game.add.tween(this._shareCheckbox).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true);
    }

    game.add.tween(this._claimButton).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true);

    this._premiumSpinBtn.visible = false;

    if (!this._showRealMoneyWheel) {
      game.time.events.add(300, () => {
        this._premiumSpinBtn.currentState = this._shouldGiveAnotherSpin() ? BUTTONSTATE.ENABLED : BUTTONSTATE.DISABLED;
        this._premiumSpinBtn.visible = true;
      });
    }

    game.add.tween(this._activeWheel).to({ alpha: 1 }, 300, Phaser.Easing.Sinusoidal.InOut, true, 400).onComplete.add(async () => {
      if (this.game === null) return;

      if (this._showRealMoneyWheel) {
        if (!this._realMoneyMode) {
          this._realMoneyMode = true;
          this._changeToRealMoney(true, this._realMoneyIsConversion);

          // if (this._realMoneyIsConversion) {
          //   this._dataCapture.addToPlayerCharacterizationParam('seenReplacementConversionMoneyWheel', 1);
          // } else {
          //   this._dataCapture.addToPlayerCharacterizationParam('seenReplacementHighValueWheel', 1);
          // }
        } else {
          if (OMT.feature.getFeatureRealMoneyReplacementWheel() && G.IAP) {
            await this._getRealMoneyWheelPrice();
          } else {
            this._realMoneyWheelOffer = null;
          }

          this._changeRealMoneyWheelMode();

          if (this._realMoneyGame.lights.lightsMode !== LIGHTS_MODES.Single) {
            this._realMoneyGame.lights.changeLightsMode(LIGHTS_MODES.Off);
          }
        }
      } else if (this._isAdFallback || (!this._moreMoneyMode && G.saveState.data.freeSpin)) {
        this._closeButton.visible = true;
        this._changeToRegular();
      } else {
        this._closeButton.visible = true;
        // eslint-disable-next-line no-lonely-if
        if (this._premium) {
          this._changeToPremium(false);
        } else {
          this._changeToPremium(true);
        }
      }
    }, this);

    game.add.tween(this._youWinTxt.scale).to({ x: 0, y: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true);

    game.add.tween(this._giftGroup).to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true).onComplete.add(() => {
      if (this.game === null) return;
      this._giftGroup.destroy();
      this._giftGroup = this.add(game.make.group());
      this._giftGroup.y = 80;
    }, this);

    if (this._realMoneyGame) {
      this._realMoneyGame.toggleWinText(false, null, null);
    }
  }

  /**
   * Check to see if another spin should be given according to limit spin flags
   */
  _shouldGiveAnotherSpin() {
    return !this._incentivised
      || !this._wheelIsLimited
      || G.saveState.getPrizeWheelSpinCount() < this._wheelLimitConfig.numSpins;
  }

  /**
   * Update UI and spin limit cooldowns based number of spins remaining
   */
  _updateSpinLimitState() {
    if (this._wheelIsLimited) {
      const spinsLeft = Math.max(this._wheelLimitConfig.numSpins - G.saveState.getPrizeWheelSpinCount(), 0);

      if (spinsLeft === 0) {
        G.saveState.setPrizeWheelOnCooldown();

        const cooldownRemaining = Math.floor(G.saveState.getUserCooldownRemaining('prizeWheelSpins', '') / 1000);
        this._spinCooldownTimer.setSecLeft(cooldownRemaining);
      } else {
        const spinTemplate = spinsLeft === 1
          ? OMT.language.getText('%number% Spin left')
          : OMT.language.getText('%number% Spins left');
        this._spinsLeftText.text = spinTemplate.replace('%number%', spinsLeft);
        this._spinCooldownGroup.visible = false;
      }
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.dailyWheel = Window_DailyWheel;
