import { TextTimer } from '../../TextTimer';

export default class UI_ShopIcon extends Phaser.Group {
  /**
   * The icon on the world map for eventPostcard that brings you to the shop
   * @param {Object} config
   * @param {string} config.icon
   * @param {Object} config.range
   * @param {number} config.range.end
   * @param
   */
  constructor(config) {
    super(game, null);

    this._drawAsset(config.icon);

    this._ready = {
      drawing: false,
      text: false,
    };

    if (config.range) {
      this.makeTimerText(config.range);
    } else {
      this._ready.text = true;
    }

    this._ready.drawing = true;
    this.show();

    this._skipUpdate = false;
    this._signalTokens = [];
    this._signalTokens.push(G.sb('pauseAllUpdate').add(() => {
      this._skipUpdate = true;
    }));
    this._signalTokens.push(G.sb('resumeAllUpdate').add(() => {
      this._skipUpdate = false;
    }));
  }

  _drawAsset(asset) {
    this._container = new G.Button(0, 0, null, this.openCoinShop, this);
    this._innerContainer = new Phaser.Group(game, this._container);
    this._ray = G.makeImage(0, 0, 'popup_lighht', 0.5, this._innerContainer);
    this._ray.alpha = 0.5;
    this._ray.scale.setTo(0.7);
    this._ray.blendMode = 1;
    G.makeImage(0, 0, asset, 0.5, this._innerContainer);
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._timer) {
      this._timer.destroy();
    }
    this._signalTokens.forEach((signal) => {
      if (signal) {
        if (signal.detach) {
          signal.detach();
        }
        signal = null;
      }
    });
    this._signalTokens = [];
    super.destroy();
  }

  update() {
    super.update();

    if (this._skipUpdate) {
      return;
    }

    if (this._ray) {
      this._ray.angle += G.deltaTime;
    }
  }

  /**
   * Opens shop or more postcards depending on environment
   */
  openCoinShop() {
    G.sb('pushWindow').dispatch(['coinShop', { freeGift: true }]);
  }

  /**
   * Icon is ready and will show
   */
  show() {
    if (this._ready.drawing && this._ready.text) {
      this._container.scale.set(0.01);
      this.addChild(this._container);
      game.add.tween(this._container.scale).to({ x: 1, y: 1 }, 250, Phaser.Easing.Sinusoidal.InOut, true);
    }
  }

  /**
   * Timer text is made for the event postcard
   */
  makeTimerText(range) {
    const curTime = OMT.connect.getServerTimestampSync();
    const endTime = range.end;
    this._timer = this.makeTimerObject();
    this._timer.setSecLeft((endTime - curTime) / 1000);
    this._timer.active = true;
    this._innerContainer.addChild(this._timer);
    this._ready.text = true;
    this.show();
  }

  /**
   * Creates the timer object. Can be overwritten
   */
  makeTimerObject() {
    return new TextTimer({
      x: 0,
      y: 1,
      style: {
        style: 'font-num-blue',
        fontSize: 30,
      },
      anchor: 0.5,
      maxWidth: this._innerContainer.width,
    });
  }
}
