/* eslint-disable no-use-before-define */

/**
 * UITargetParticles is a class that handles some particle animation.
 * The particles usually fly towards a targetObj in world space.
 * The most common function from this class is createCoinBatch
 *
 * Can be accessed externally by G.UITargetParticles
 */
export default class UITargetParticles extends G.BatchPoolGroup {
  constructor() {
    super(UITargetParticle); // Pool Group of UITargetParticle (at the bottom of this file)
  }

  /**
   * Creates a batch of coin particles.
   *
   * If you are saving now, please be mindful that the saveState will change immediately and save,
   * then change back (without saving) immediately
   *
   * If you are not saving at all, it will not save AT ALL, but it will trigger calls, such as
   * the delta increment signals, DDNA Tracking, and the bulk change signal
   * Please call the save function externally if you are not saving at all through this
   * Preferably, like right after you create this
   * @param {number} x
   * @param {number} y
   * @param {DisplayObject} targetObj
   * @param {number} amount
   * @param {boolean} saveNow
   * @param {Function} callback
   * @param {G.Text} coinText
   */
  createCoinBatch(x, y, targetObj, amount, saveNow = true, callback = null, coinText = null) {
    let before = G.saveState.getCoins();
    G.saveState.changeCoins(amount, !saveNow, true, true, false); // Change the coin amount NOW. Track data. SAVE

    const batch = this.createDividedBatch( // Create the particle batches
      x,
      y,
      'coin_1',
      targetObj,
      amount,
      33,
      12,
    );

    batch.addOnPartStart((part) => { // On start, be small, or something
      part.scale.setTo(0.75);
      part.vel.setTo(game.rnd.realInRange(-12, 12), game.rnd.realInRange(-12, 12));
    });

    batch.addOnPartFinish((part) => {
      G.sfx.pop.play();
      before += part.carriedValue;
      G.sb('onCoinsChange').dispatch(before);
      if (coinText) coinText.setText(OMT.language.toLocaleNumber(before.toString())); // Update a counter if one was given
    });

    batch.start();
    batch.onFinish.add(() => {
      if (callback) callback();
    });

    return batch;
  }

  /**
   * Creates some batch of particles. Each batch represents maxPartNr || 25
   * @param {number} x
   * @param {number} y
   * @param {string} sprite
   * @param {DisplayObject} targetObj
   * @param {number} amount
   * @param {number} interval
   * @param {number} maxPartNr
   */
  createDividedBatch(x, y, sprite, targetObj, amount, interval, maxPartNr) {
    const batchObj = new UITargetParticlesBatchObj();

    maxPartNr = maxPartNr || 25;
    const partNr = (amount / interval);
    if (partNr > maxPartNr) {
      interval = Math.ceil(amount / maxPartNr);
    }

    const nrOfPartsInBatch = Math.floor(amount / interval) + Math.sign(amount % interval);

    for (let i = 0; i < nrOfPartsInBatch; i++) {
      const part = this.init(x, y, sprite, targetObj, Math.min(interval, amount)); // G.PoolGroup has an init and it does the init of UITargetParticle
      amount -= interval;
      batchObj.add(part);
    }

    return batchObj;
  }
}

/**
 * The Object that contains the batch of particles
 */
class UITargetParticlesBatchObj {
  constructor() {
    this._parts = []; // Collection of UITargetParticle
    this._nrOfParts = 0; // This is just the length...
    this._nrOfFinished = 0; // Finished counter
    this.onFinish = new Phaser.Signal(); // Signal
  }

  /**
   * Collects the batch into the array
   * @param {G.PoolGroup<UITargetParticle>} part
   */
  add(part) {
    this._parts.push(part);
    part.onFinish.addOnce(this._onPartFinish, this);
    this._nrOfParts++;
  }

  /**
   * Signal is fired when all batches are finished
   */
  _onPartFinish() {
    this._nrOfFinished++;
    if (this._nrOfFinished === this._nrOfParts) {
      this.onFinish.dispatch();
    }
  }

  /**
   * A callback for when the batches start
   * @param {Function} func
   * @param {any} context
   */
  addOnPartStart(func, context) {
    this._parts.forEach((part) => {
      part.onStart.addOnce(() => {
        func.call(context, part);
      }, null, 1);
    });
  }

  /**
   * A callback for when a single part finishes
   * @param {Function} func
   * @param {any} context
   */
  addOnPartFinish(func, context) {
    this._parts.forEach((part) => {
      part.onFinish.addOnce(() => {
        func.call(context, part);
      }, null, 1);
    });
  }

  /**
   * Starts the animation for each part with a delay inbetween, if any
   * @param {number} delayBetween
   */
  start(delayBetween) {
    let delay = 0;
    this._parts.forEach((part) => {
      part.start(delay);
      delay += delayBetween || 0;
    });
  }
}

class UITargetParticle extends G.Image {
  /**
   * A single particle
   */
  constructor() {
    super(0, 0, null, 0.5);
    this.onStart = new Phaser.Signal();
    this.onFinish = new Phaser.Signal();

    this._speed = 0;
    this._speedMax = 30;
    this._speedDelta = 0.75;

    this.vel = new Phaser.Point();

    this.kill();
  }

  /**
   * Init... of something!
   * @param {number} x
   * @param {number} y
   * @param {string} sprite the graphic
   * @param {DisplayObject} targetObj
   * @param {number} carriedValue value of this particle
   */
  init(x, y, sprite, targetObj, carriedValue) {
    this.position.setTo(x, y);

    this.changeTexture(sprite);

    this.onStart.removeAll();
    this.onFinish.removeAll();

    this.carriedValue = carriedValue || 1; // The numerical value of this particle

    this._targetObj = targetObj;

    this.stopTweens(this);
    this.scale.setTo(1);
    this.alpha = 1;

    this._speed = 0;
    this._speedMax = 30;
    this._speedDelta = 0.75;

    this.vel.setTo(0, 0);
  }

  /**
   * Starts the animation after a delay
   * @param {number} delay
   */
  start(delay) {
    if (delay) {
      game.time.events.add(delay, this.start, this);
      return;
    }

    this.revive();

    // because updateTransform will happen after update :/
    this.worldPosition.x = 9999;
    this.worldPosition.y = 9999;
    // Not too sure. Maybe this particle spawns in the wrong place before updateTransform kicks in

    this.onStart.dispatch(this, this.carriedValue);
  }

  /**
   * The animation part
   */
  update() {
    if (!this.alive) return;

    this.position.add(this.vel.x * G.deltaTime, this.vel.y * G.deltaTime);
    this.vel.x *= 0.95 ** G.deltaTime;
    this.vel.y *= 0.95 ** G.deltaTime;

    this._speed += this._speedDelta * G.deltaTime;
    this._speed = Math.min(this._speed, this._speedMax);

    const distanceToTarget = Phaser.Point.distance(this.worldPosition, this._targetObj.worldPosition);
    const angleToTarget = Phaser.Point.angle(this._targetObj.worldPosition, this.worldPosition);
    this.position.add(
      G.lengthDirX(angleToTarget, Math.min(distanceToTarget, this._speed), true) * G.deltaTime,
      G.lengthDirY(angleToTarget, Math.min(distanceToTarget, this._speed), true) * G.deltaTime,
    );

    if (distanceToTarget < this._speedMax) {
      this.onFinish.dispatch(this, this.carriedValue);
      this.kill();
    }
  }
}

G.UITargetParticles = UITargetParticles;
