import ShareMsgBase from './A_G.ShareMsgBase';
/**
 * Brag 2.0 share message
 */
class MsgBrag extends ShareMsgBase {
  /**
   * constructor
   * @param {string} user1AvatarURL
   * @param {string} userName
   * @param {number} lvlIndex
   * @param {string} bannerText
   * @param {string} bubbleText
   * @param {Function} onAllImagesReadyCallback
   */
  constructor(user1AvatarURL, bannerText, bubbleText, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    // avatar image
    this.avatar1 = this._addExtImage(110, 170, user1AvatarURL, 0.5, 144, 144);
    this.avatar1.angle = -6;

    // background image
    this.bg = this._addExtImage(0, 0, 'img/Share_Brag_2.png', 0, null, null);

    // top banner text
    const bannerTextField = new G.Text(345, 52, bannerText, 'msgBrag-banner', 0.5, 455);
    this.add(bannerTextField);

    // bubble text
    const bubbleTextField = new G.Text(450, 288, bubbleText, 'msgBrag-bubble', 0.5, 380);
    bubbleTextField.angle = -3;
    this.add(bubbleTextField);

    this._objectLoadComplete();
  }
}

// create global references
G.MsgBrag = MsgBrag;
