/* eslint-disable no-unused-vars */
import OMT_VILLAINS from '../../../../../OMT_UI/OMT_Villains';

export const DIFFICULTY = {
  NORMAL: 0,
  HARD: 1,
  SUPERHARD: 2,
};

export default class LevelNumberTabBase {
  /**
   * A base class for the level number tab that shows up on the game board.
   * @param {Object} config
   * @param {Game} config.game
   * @param {Phaser.DisplayObject} config.parent
   * @param {number} config.initialWidth
   * @param {number} config.initialHeight
   * @param {number} config.levelNumber
   */
  constructor(config) {
    const difficult = this._determineDifficulty();
    config.difficult = difficult;
    this._drawBg(config);
    this._drawLevelNumber(config);
  }

  /**
   * Determines difficulty of the current level
   * @returns {DIFFICULTY}
   */
  _determineDifficulty() {
    let difficult = DIFFICULTY.NORMAL;
    const { isHardLevel, isSuperHardLevel } = OMT_VILLAINS.getDifficulty();
    if (isHardLevel) {
      difficult = DIFFICULTY.HARD;
    } else if (isSuperHardLevel) {
      difficult = DIFFICULTY.SUPERHARD;
    }
    return difficult;
  }

  /**
   * Draws the background of the level number tab.
   * Overridden
   * @param {{parent:Phaser.DisplayObject, initialWidth:number, initialHeight:number, levelNumber:number, difficult:DIFFICULTY}} config
   */
  _drawBg(config) {
    // Override
  }

  /**
   * Draws the level number of the level number tab.
   * Overridden
   * @param {{levelNumber:number, difficult:DIFFICULTY}} config
   */
  _drawLevelNumber(config) {
    // Override
  }

  /**
   * Animates the level number tab in
   */
  animateSlideIn() {
    // Override
  }

  /**
   * Animates out the level number tab
   */
  animateSlideOut() {
    // Override
  }

  /**
   * When resize hapens
   * @param {number} width
   * @param {number} height
   */
  resize(width, height) {
    // Override
  }
}
