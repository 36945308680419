import OMT_StackManager from '../../Utils/OMT_StackManager';
import OMT_VILLAINS from '../OMT_Villains';
import VillainsBaseClass from './VillainsBaseClass';
import VillainsBaseContainer from './VillainsBaseContainer';
import OMT_AnimationFactory from '../../Utils/Animation/OMT_AnimationFactory';
import { OMT_SessionUtil } from '../../Utils/OMT_SessionUtil';

export default class VillainsPoofAnimation extends VillainsBaseClass {
  /**
   * Poof animation for the vanishing of villains on the map
   * @param {LevelNodeVillains} parent
   */
  constructor(parent) {
    super(parent);

    this.objectPrefix = '_villain';

    const classContainer = new VillainsBaseContainer(this);
    classContainer.y -= 230 / 2;
    this._villain_container = {
      type: 'group',
      object: classContainer,
    };

    const animationHolder = new Phaser.Image(game, 0, 0);
    animationHolder.anchor.setTo(0.5);
    animationHolder.width = 360;
    animationHolder.height = 230;
    classContainer.add(animationHolder);

    const parentContainer = parent.getClassContainer();
    parentContainer.add(classContainer);
  }

  /**
   * Animate the poof here
   */
  async explode() {
    const classContainer = this.getClassContainer();
    let difficulty = OMT_SessionUtil.getInstance().checkAndCreateWithKey('villains_poof_animation').getData('last_difficulty');
    if (!difficulty) {
      difficulty = 'hard';
    }
    const animationName = OMT_VILLAINS.getPrefixedName(`${difficulty}_poof_animation`);
    const animationFactory = new OMT_AnimationFactory();
    const stack = OMT_StackManager.getFreeStack();
    stack.addPromise(() => animationFactory.runAnimation(animationName, { parent: classContainer, localOnly: true }));
    return stack.run();
  }
}
