import { LevelLeaderboardScrollView } from './LevelLeaderboardScrollView';
import { LevelLeaderboardInviteButton } from './LevelLeaderboardInviteButton';
import { GameScaleController } from '../../States/Scaling/GameScaleController';

import {
  HIDE_ANIMATION_DURATION,
  HIDE_OFFSET_Y,
  DUMMY_USER_DATA,
  MIN_ENTRIES_LIST_LENGTH,
} from '../LevelLeaderboardSettings';

export class LevelLeaderboard extends Phaser.Group {
  /**
   * constuctor
   */
  constructor() {
    super(game);

    this.signals = {};

    this._initContentGroup();
    this._initPanelBackground();

    this._inviteButton = new LevelLeaderboardInviteButton();
    this._contentGroup.addChild(this._inviteButton);

    this.visible = false;

    const { gameScale } = GameScaleController.getInstance();

    this._contentGroup.x = (this._bg.width / gameScale) / 2;
    this._contentGroup.y = this._bg.height / gameScale;
    this._contentGroup.scale.setTo(gameScale);

    // add the screen resize event
    G.sb('onScreenResize').add(this._onScreenResize, this);
    this._onScreenResize();
  }

  /**
   * init the content container gorup
   */
  _initContentGroup() {
    this._contentGroup = new Phaser.Group(game);
    this.addChild(this._contentGroup);
  }

  /**
   * init the panel background
   */
  _initPanelBackground() {
    this._bg = G.makeImage(0, 0, 'leaderboard_ingame_panel', [0.5, 1], this._contentGroup);
    this._bg.inputEnabled = true; // also using this to block click through
  }

  /**
   * set the leaderboard entriesList to display
   * @param {Array} entriesList
   * @returns {Promise}
   */
  async setEntries(entriesList) {
    entriesList = !entriesList ? [] : entriesList.slice();
    while (entriesList.length < MIN_ENTRIES_LIST_LENGTH) entriesList.push(DUMMY_USER_DATA);
    this._destroyScrollView();
    await this._initScrollView(entriesList);
  }

  /**
   * init the LevelLeaderboardScrollView containing LevelLeaderboardUserView instances
   * @returns {Promise}
   */
  async _initScrollView(entriesList) {
    this._scrollView = new LevelLeaderboardScrollView();
    await this._scrollView.init(entriesList);
    this._scrollView.enable();
    try {
      this._contentGroup.addChildAt(this._scrollView, 1);
    } catch (error) {
      //
    }
  }

  /**
   * destroy the previous scrolview
   */
  _destroyScrollView() {
    this.visible = false;
    if (!this._scrollView) return;
    this.removeChild(this._scrollView);
    this._scrollView.destroy();
    this._scrollView = null;
  }

  /**
   * resize content to fit screen layout
   */
  _onScreenResize() {
    this.y = game.height; // anchor leaderboard to the bottom of the screen
  }

  /**
   * show the leaderboard, if it was hidden
   */
  show() {
    this.visible = true;
    if (this._hideTween) this._hideTween.stop();
    this._hideTween = game.add.tween(this._contentGroup);
    this._hideTween.to({ y: 0 }, HIDE_ANIMATION_DURATION, Phaser.Easing.Quadratic.Out, false);
    this._hideTween.start();
  }

  /**
   * hide the leaderboard offscreen
   */
  hide() {
    if (this._hideTween) this._hideTween.stop();
    this._hideTween = game.add.tween(this._contentGroup);
    this._hideTween.to({ y: HIDE_OFFSET_Y }, HIDE_ANIMATION_DURATION, Phaser.Easing.Quadratic.Out, false);
    this._hideTween.onComplete.addOnce(this._destroyScrollView, this);
    this._hideTween.start();
  }

  /**
   * destruction method
   */
  destroy() {
    super.destroy();
    // stop tweens
    if (this._hideTween) this._hideTween.stop();
    // clear signals
    Object.values(this.signals).forEach((signal) => { signal.dispose(); });
    // remove global events
    G.sb('onScreenResize').remove(this._onScreenResize, this);
  }
}
