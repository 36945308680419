import { DeconstructMsg } from './DeconstructMsg';
/**
 * Tournament High Score text, shows on board.deconstruct()
 */
export class DeconstructMsgDefault extends DeconstructMsg {
  /**
   * Please check DeconstructMsg constructor comment
   * @param {LvlDataManager} lvlDataManager
   * @param {boolean} showSkip
   */
  constructor(lvlDataManager, showSkip) {
    super(lvlDataManager);

    this._pauseDuration = 1200;
    this._afterDelay = 300;
    this._drawElements();

    if (!showSkip) {
      this.destroySkip();
    }
  }

  _drawElements() {
    this.glowImg = G.makeImage(0, 0, 'popup_lighht', 0.5, this);
    this.glowImg.blendMode = 1;
    this.glowLoopTween = game.add.tween(this.glowImg).to({ angle: 360 }, 6000, Phaser.Easing.Linear.None, true).loop();
    this._allTweens.push(this.glowLoopTween);
    this.glowImg.alpha = 0;
    const shoutout = 'well_done';
    this.wellDoneTxt = game.add.image(0, 0, 'shoutouts', shoutout);
    this.wellDoneTxt.anchor.setTo(0.5);
    this.add(this.wellDoneTxt);
    this.wellDoneTxt.visible = false;
  }

  /**
   * animate in the msg
   */
  animateIn() {
    super.animateIn();

    this._allTweens.push(game.add.tween(this.glowImg).to({ alpha: 0.2 }, 300, Phaser.Easing.Sinusoidal.Out, true));
    this.wellDoneTxt.visible = true;
    this.wellDoneTxt.scale.setTo(0);
    this._allTweens.push(game.add.tween(this.wellDoneTxt.scale).to({ x: 1, y: 1 }, 500, Phaser.Easing.Elastic.Out, true));
  }
}
