/* eslint-disable object-curly-newline */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import { Window } from '../../../00_IMMEDIATE/Window';
import ShopUtils from '../Shop/Shop_Utils';
import { UI_LoadingIndicatorDisplay } from '@omt-components/UI/Loading/UI_LoadingIndicatorDisplay';

import TargetedOfferDataManager from '../../../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { TargetedOffer_BuyButton } from './Components/TargetedOffer_BuyButton';
import { TimedTargetedOffer_Background } from './Components/TimedTargetedOffer_Background';
import { TimedTargetedOffer_HeaderGroup } from './Components/TimedTargetedOffer_HeaderGroup';
import { TimedTargetedOffer_DealCoinBar } from './Components/TimedTargetedOffer_DealCoinBar';
import { TimedTargetedOffer_BoosterIcon, TimedTargetedOffer_ULBoosterIcon } from './Components/TimedTargetedOffer_BoosterIcon';
import { TimedTargetedOffer_MascotGroup } from './Components/TimedTargetedOffer_MascotGroup';
import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';
import { OMT_AssetLoader } from '../../../Services/OMT/OMT_AssetLoader';
import { ORIENTATION } from '../../../Services/OMT/OMT_SystemInfo';

const CONTENT_SCALE = 1.2;

const CLOSE_BUTTON_CONFIG_VERTICAL = { x: 285, y: -440, scale: 0.75 };
const CLOSE_BUTTON_CONFIG_HORIZONTAL = { x: 465, y: -210, scale: 1 };

const LOADING_INDICATOR_X = 320;
const MAX_ASPECT_RATIO = 1.775;

const BOOSTER_SCALE = 0.75;
const BOOSTER_PADDING = 0;

const DEAL_LAYOUT_CONFIGS = {
  vertical: {
    NO_BOOSTERS: {
      dealGraphic: { offsetX: 0, offsetY: 0, scale: 0.95 },
      dealCoinbar: { offsetX: 0, offsetY: 160, scale: 1.1 },
      assetKey: 'moneyOnly',
    },
    SINGLE_BOOSTERS: {
      dealGraphic: { offsetX: 0, offsetY: 0, scale: 0.8 },
      dealCoinbar: { offsetX: 0, offsetY: -125, scale: 1 },
      iconGroup: { offsetX: 26, offsetY: 157, scale: 0.8 },
      assetKey: 'fewBoosters',
    },
    FEW_BOOSTERS: {
      dealGraphic: { offsetX: 0, offsetY: 0, scale: 0.8 },
      dealCoinbar: { offsetX: 0, offsetY: -125, scale: 1 },
      iconGroup: { offsetX: 26, offsetY: 157, scale: 0.8 },
      assetKey: 'fewBoosters',
    },
    MANY_BOOSTERS: {
      dealGraphic: { offsetX: 0, offsetY: 0, scale: 0.8 },
      dealCoinbar: { offsetX: 0, offsetY: -125, scale: 1 },
      iconGroup: { offsetX: 26, offsetY: 157, scale: 0.8 },
      assetKey: 'manyBoosters',
    },
  },

  horizontal: {
    NO_BOOSTERS: {
      dealGraphic: { offsetX: -37, offsetY: 110, scale: 1.3 },
      dealCoinbar: { offsetX: 165, offsetY: 0, scale: 1.1 },
      assetKey: 'moneyOnly',
    },
    SINGLE_BOOSTERS: {
      dealGraphic: { offsetX: -37, offsetY: 110, scale: 1.3 },
      dealCoinbar: { offsetX: 275, offsetY: 20, scale: 1.1 },
      iconGroup: { offsetX: 305, offsetY: 105, scale: 1 },
      assetKey: 'fewBoosters',
    },
    FEW_BOOSTERS: {
      dealGraphic: { offsetX: -37, offsetY: 110, scale: 1.3 },
      dealCoinbar: { offsetX: 275, offsetY: 20, scale: 1.1 },
      iconGroup: { offsetX: 305, offsetY: 105, scale: 1 },
      assetKey: 'fewBoosters',
    },
    MANY_BOOSTERS: {
      dealGraphic: { offsetX: -37, offsetY: 110, scale: 1.3 },
      dealCoinbar: { offsetX: 275, offsetY: 20, scale: 1.1 },
      iconGroup: { offsetX: 305, offsetY: 105, scale: 1 },
      assetKey: 'manyBoosters',
    },
  },
};

/**
 * Window for Timed Targeted Offers
 */
class Window_TimedTargetedOffer extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {string} productID
   * @param {String} offerID
   * @param {number} expiryTime
   * @param {Object} windowCustomizations customizations to merge with G.OMTsettings.elements.Window_timedTargetedOffer
   */
  constructor(parent, productID, offerID, expiryTime, windowCustomizations = null) {
    super(parent);
    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;

    this._baseConfig = G.OMTsettings.elements.Window_timedTargetedOffer[OMT.systemInfo.orientationKey];
    this._config = _.cloneDeep(this._baseConfig);
    if (windowCustomizations) { // merge in customizations to config object if any
      const customizationObj = OMT_Utils.stringToReference(windowCustomizations, window);
      this._config = _.merge(this._config, customizationObj);
    }
    this._productID = productID;
    this._offerID = offerID;
    this._expiryTime = expiryTime;
    this._currentDeal = null;
    this._closeClicks = 0;

    if (game.state.current === 'World') {
      this.y = -G.WindowMgr.Constants.WorldVerticalOffset;
    }

    if (this._isLandscape) {
      this.y -= 35;
    }

    this.visible = false;
    this._init(productID);
  }

  /**
   * async initialization method
   * @param {string} productID
   */
  async _init(productID) {
    this._initLoadingIndicator();
    this._loadingIndicator.show();

    const assetLoader = OMT_AssetLoader.getInstance();
    const assetsLoaded = await assetLoader.waitOnSecondaryImages(['shop/ui', 'shop/nine-slices', 'shop/deal-graphics', 'shop/timed-iap']);
    if (assetsLoaded) {
      const offerData = await this._getOfferData(productID);
      if (offerData) {
        this._currentDeal = offerData;
        this._showOffer();
        this.visible = true;
      } else { // no offer found throw error
        this._logOfferCatalogError(productID);
        this.closeWindow();
      }
    } else {
      this.closeWindow();
    }
    this._loadingIndicator.hide();
    G.sb('hideHighscoreBoard').dispatch();
  }

  /**
   * log a offer / catalog error to Sentry
   * @param {string} productID productID attempted
   */
  _logOfferCatalogError(productID) {
    const productIDList = OMT.payments.getProductIDList();
    const { paymentsReady } = OMT.payments;
    let errorString = `TargetedOffer Error: offer ${productID} not found in catalog!, \n`;
    errorString += `paymentsReady: ${paymentsReady}, \n`;
    errorString += `catalog: ${productIDList.join(', ')}`;
    // G.Utils.SentryLog.logError(errorString, {}, false);
    console.error(errorString);
  }

  /**
   * disabled due to loading delay. call super._setOpenWindowAnimation() when ready.
   */
  _setOpenWindowAnimation() {
    this.alpha = 0;
  }

  /**
   * initialize loading indicator component
   */
  _initLoadingIndicator() {
    this._loadingIndicator = new UI_LoadingIndicatorDisplay();
    this._loadingIndicator.x = LOADING_INDICATOR_X; this._loadingIndicator.y = game.height / 2;
    game.world.addChild(this._loadingIndicator);
  }

  /**
   * get the targeted offer data from the catalog
   * @returns {Object}
   */
  async _getOfferData(productID) {
    const catalog = await OMT.payments.getCatalogAsync();
    if (!catalog || catalog.length === 0) return null;
    const offerData = ShopUtils.getTargetedOfferByID(catalog, productID);
    return offerData;
  }

  /**
   * create the content group for offer layout
   */
  _initContentGroup() {
    this._contentGroup = new Phaser.Group(game);
    this._contentGroup.scale.x = this._contentGroup.scale.y = CONTENT_SCALE;
    this.addChild(this._contentGroup);
  }

  /**
   * show / draw the targeted offer once set
   */
  _showOffer() {
    const rewards = OMT.payments.parseRewards(this._currentDeal);
    const { coins, booster: boosters, infiniteLives: infiniteLivesMins } = rewards;
    const config = this._config;

    this._initContentGroup();
    this._drawBg(config.bg);
    this._drawHeader(config.header, this._expiryTime);
    this._drawMainDealGraphic(config.deal.assetsKeys);
    this._drawPlayerCoinbar(config.playerCoinbar);
    this._drawDealCoinbar(config.dealCoinbar, coins);
    this._initBoosterIcons(boosters, infiniteLivesMins);
    this._updateDealLayout(config.deal.assetsKeys);
    this._initMascotGroup();

    this._initBuyButton(config.buyButton.offsetY);
    this._initCloseButton();

    super._setOpenWindowAnimation();
  }

  /**
   * draw the 9-sliced background
   * @param {Object} bgConfig see TargetedOffer_BackgroundGroup constructor
   */
  _drawBg(bgConfig) {
    this._bg = new TimedTargetedOffer_Background(bgConfig);
    this._bg.y = this._isLandscape ? 20 : -50;
    this._contentGroup.addChildAt(this._bg, 0);
  }

  /**
   * draw / initialize the TargetedOffer_HeaderGroup instance
   * @param {Object} headerConfig
   * @param {number} headerConfig.offsetY
   * @param {number} headerConfig.textOffsetY
   * @param {number} headerConfig.ribbonOffsetY
   * @param {Object} headerConfig.ribbonConfig
   * @param {Object} headerConfig.textConfig
   * @param {number} expiryTime
   */
  _drawHeader(headerConfig, expiryTime) {
    const headerGroup = new TimedTargetedOffer_HeaderGroup(headerConfig, expiryTime);
    headerGroup.y = this._bg.y - this._bg.outerHeight / 2 + headerConfig.offsetY;

    this._contentGroup.addChild(headerGroup);
  }

  /**
   * Draw the players coinbar. Not part of the deal graphic.
   * @param {Object} coinbarConfig
   * @param {number} coinbarConfig.offsetX
   * @param {number} coinbarConfig.offsetY
   * @param {number} coinbarConfig.scale (optional)
   */
  _drawPlayerCoinbar(coinbarConfig) {
    this._coinbar = new G.CoinBar(coinbarConfig.offsetX, coinbarConfig.offsetY, true, 'window_oneTimePopupOffer-coinbarTxt', 'top-panel-coinsBg');
    if (coinbarConfig.scale != null) this._coinbar.scale.x = this._coinbar.scale.y = coinbarConfig.scale;
    this._contentGroup.addChild(this._coinbar);
  }

  /**
   * draw the main deal graphic
   * @param {Object} assetsKeys { moneyOnly, fewBoosters, manyBoosters }
   */
  _drawMainDealGraphic(assetsKeys) {
    this._dealGraphic = G.makeImage(0, 0, assetsKeys.manyBoosters, 0.5);
    this._dealGraphic.scale.set(0.9);
    this._contentGroup.addChild(this._dealGraphic);
  }

  /**
   * draw the coinbar inside the deal layout
   * @param {Object} coinbarConfig { scale:number, fontSize:number, tint:number, offsetY:number }
   * @param {number} coins
   */
  _drawDealCoinbar(coinbarConfig, coins) {
    this._dealCoinbar = new TimedTargetedOffer_DealCoinBar(coinbarConfig, coins);
    this._contentGroup.addChild(this._dealCoinbar);
  }

  /**
   * setup booster icons
   * @param {Array.<{item:string, amount:number}>} boosters
   * @param {number} infiniteLivesMins
   */
  _initBoosterIcons(boosters, infiniteLivesMins) {
    this._iconGroup = new Phaser.Group(game);
    this._contentGroup.addChild(this._iconGroup);

    // normal boosters
    for (let i = 0; i < boosters.length; i++) {
      const { item: boosterID, amount: boosterCount } = boosters[i];
      const { boosterIcon: boosterIconConfig } = this._config;
      const boosterIcon = new TimedTargetedOffer_BoosterIcon(boosterID, boosterCount, boosterIconConfig);
      boosterIcon.scale.set(BOOSTER_SCALE);
      this._iconGroup.addChild(boosterIcon);
    }
    // unlimited lives booster
    if (infiniteLivesMins > 0) {
      const { unlimitedLivesIcon: unlimitedLivesIconConfig } = this._config;
      const ulBoosterIcon = new TimedTargetedOffer_ULBoosterIcon(infiniteLivesMins, unlimitedLivesIconConfig);
      ulBoosterIcon.scale.set(BOOSTER_SCALE);
      this._iconGroup.addChild(ulBoosterIcon);
    }
  }

  /**
   * horizontally align booster icons
   * @param {Phaser.Group} iconGroup
   * @param {nuber} xPos (optional) initial x
   */
  _horizontalAlignBoosterIcons(iconGroup, xPos = 0) {
    for (const icon of iconGroup.children) { icon.x = xPos; xPos += icon.width + BOOSTER_PADDING; }
  }

  /**
   * vertically align booster icons
   * @param {Phaser.Group} iconGroup
   * @param {nuber} yPos (optional) initial y
   */
  _verticalAlignBoosterIcons(iconGroup, yPos = 0) {
    for (const icon of iconGroup.children) { icon.y = yPos; yPos += icon.height + BOOSTER_PADDING; }
  }

  /**
   * Align icons in a square
   * @param {Phaser.Group} iconGroup
   */
  _squareAlignBoosterIcons(iconGroup) {
    const numIconsPerRow = 2;
    const numRows = Math.ceil(iconGroup.children.length / numIconsPerRow);
    const iconWidth = iconGroup.children[0].width;
    const iconHeight = iconGroup.children[0].height;

    for (let i = 0; i < numRows; i++) {
      for (let j = 0; j < numIconsPerRow; j++) {
        const index = i * 2 + j;
        const icon = iconGroup.children[index];
        icon.y = (iconHeight * i) + BOOSTER_PADDING * (i - 1);

        if (index === iconGroup.children.length - 1 && j === 0) {
          // There are an odd number of icons and this is the last one
          icon.x = (iconWidth * (j + 0.5)) + BOOSTER_PADDING * (j - 1);
          return;
        }
        icon.x = (iconWidth * j) + BOOSTER_PADDING * (j - 1);
      }
    }
  }

  /**
   * get the deal graphic asset key, look for overrides by productID
   * @param {Obejct} assetsKeys dictionary of asset keys
   * @param {Obejct} layoutConfig deal layout config
   * @returns {string}
   */
  _getDealGraphicAssetKey(assetsKeys, layoutConfig) {
    if (assetsKeys[this._offerID] != null) return assetsKeys[this._offerID]; // check if this offerID has a specific asset defined in the assetKeys
    return assetsKeys[layoutConfig.assetKey]; // use default asset from the layoutConfig
  }

  /**
   * update / rearrange deal layout for specific offers
   * @param {Object} assetsKey { moneyOnly, fewBoosters, manyBoosters }
   */
  _updateDealLayout(assetsKeys) {
    const {
      _iconGroup: iconGroup,
      _dealGraphic: dealGraphic,
      _dealCoinbar: dealCoinbar,
    } = this;

    let layoutConfig;
    const layoutConfigForOrientation = this._isLandscape
      ? DEAL_LAYOUT_CONFIGS.horizontal
      : DEAL_LAYOUT_CONFIGS.vertical;

    const innerBGOffset = { // issues ocurred when shifting frames for alternate layouts. so offsets were calculated.
      x: (this._config.bg.innerOffsetX || 0) - (this._baseConfig.bg.innerOffsetX || 0),
      y: (this._config.bg.innerOffsetY || 0) - (this._baseConfig.bg.innerOffsetY || 0),
    };

    if (iconGroup.children.length === 0) { // LAYOUT COINS ONLY
      layoutConfig = layoutConfigForOrientation.NO_BOOSTERS;
      G.changeTexture(dealGraphic, this._getDealGraphicAssetKey(assetsKeys, layoutConfig));
      dealGraphic.scale.set(layoutConfig.dealGraphic.scale);
      dealGraphic.x = layoutConfig.dealGraphic.offsetX + innerBGOffset.x;
      dealGraphic.y = layoutConfig.dealGraphic.offsetY + innerBGOffset.y;
      dealCoinbar.x = layoutConfig.dealCoinbar.offsetX + innerBGOffset.x;
      dealCoinbar.y = layoutConfig.dealCoinbar.offsetY + innerBGOffset.y;
      dealCoinbar.scale.set(layoutConfig.dealCoinbar.scale);
    } else if (!this._isLandscape && iconGroup.children.length === 1) { // LAYOUT SINGLE BOOSTERS FOR PORTRAIT
      layoutConfig = layoutConfigForOrientation.SINGLE_BOOSTERS;
      if (this._isLandscape) this._squareAlignBoosterIcons(iconGroup);
      else this._horizontalAlignBoosterIcons(iconGroup);

      G.changeTexture(dealGraphic, this._getDealGraphicAssetKey(assetsKeys, layoutConfig));
      dealGraphic.scale.set(layoutConfig.dealGraphic.scale);
      dealGraphic.x = layoutConfig.dealGraphic.offsetX + innerBGOffset.x;
      dealGraphic.y = layoutConfig.dealGraphic.offsetY + innerBGOffset.y;

      dealCoinbar.x = layoutConfig.dealCoinbar.offsetX;
      dealCoinbar.y = layoutConfig.dealCoinbar.offsetY;
      dealCoinbar.scale.set(layoutConfig.dealCoinbar.scale);

      iconGroup.x = -iconGroup.width * layoutConfig.iconGroup.scale * 0.5 + layoutConfig.iconGroup.offsetX;
      iconGroup.y = layoutConfig.iconGroup.offsetY;
    } else if (iconGroup.children.length < 3) { // LAYOUT 1-3 (LANDSCAPE) / 2-3 (PORTRAIT) BOOSTERS
      layoutConfig = iconGroup.children.length === 1
        ? layoutConfigForOrientation.SINGLE_BOOSTERS
        : layoutConfigForOrientation.FEW_BOOSTERS;

      if (this._isLandscape) this._squareAlignBoosterIcons(iconGroup);
      else this._horizontalAlignBoosterIcons(iconGroup);

      G.changeTexture(dealGraphic, this._getDealGraphicAssetKey(assetsKeys, layoutConfig));
      dealGraphic.scale.set(layoutConfig.dealGraphic.scale);
      dealGraphic.x = layoutConfig.dealGraphic.offsetX + innerBGOffset.x;
      dealGraphic.y = innerBGOffset.y + layoutConfig.dealGraphic.offsetY;

      dealCoinbar.x = layoutConfig.dealCoinbar.offsetX;
      dealCoinbar.y = layoutConfig.dealCoinbar.offsetY;
      dealCoinbar.scale.set(layoutConfig.dealCoinbar.scale);

      iconGroup.x = -iconGroup.width * layoutConfig.iconGroup.scale * 0.5 + layoutConfig.iconGroup.offsetX;
      iconGroup.y = layoutConfig.iconGroup.offsetY;
      iconGroup.scale.setTo(layoutConfig.iconGroup.scale);
    } else { // LAYOUT 3 OR MORE BOOSTERS
      layoutConfig = layoutConfigForOrientation.MANY_BOOSTERS;
      if (this._isLandscape) this._squareAlignBoosterIcons(iconGroup);
      else this._horizontalAlignBoosterIcons(iconGroup);

      G.changeTexture(dealGraphic, this._getDealGraphicAssetKey(assetsKeys, layoutConfig));
      dealGraphic.scale.set(layoutConfig.dealGraphic.scale);
      dealGraphic.x = layoutConfig.dealGraphic.offsetX + innerBGOffset.x;
      dealGraphic.y = innerBGOffset.y + layoutConfig.dealGraphic.offsetY;

      dealCoinbar.x = layoutConfig.dealCoinbar.offsetX;
      dealCoinbar.y = layoutConfig.dealCoinbar.offsetY;
      dealCoinbar.scale.set(layoutConfig.dealCoinbar.scale);

      iconGroup.x = -iconGroup.width * layoutConfig.iconGroup.scale * 0.5 + layoutConfig.iconGroup.offsetX;
      iconGroup.y = layoutConfig.iconGroup.offsetY;
      iconGroup.scale.setTo(layoutConfig.iconGroup.scale);
    }
  }

  /**
   * init the group containing the mascot and speechbubble
   */
  _initMascotGroup() {
    const { mascot: mascotConfig, bubble: bubbleConfig } = this._config.exitConfirm;
    this._mascotGroup = new TimedTargetedOffer_MascotGroup(mascotConfig, bubbleConfig);
    window.mascotGroup = this._mascotGroup;
    this._contentGroup.addChild(this._mascotGroup);

    this._mascotGroup.y = this._isLandscape
      ? game.height / 1.03 - this._mascotGroup.height / 2
      : game.height / 2 - this._mascotGroup.height / 2;
  }

  /**
   * create the buy button
   * @param {number} offsetY
   */
  _initBuyButton(offsetY) {
    this._buyButton = new TargetedOffer_BuyButton(this._currentDeal.price);
    this._buyButton.signals.onClick.add(() => { this.onBuyClicked(); });
    this._buyButton.y = offsetY;
    this._contentGroup.addChild(this._buyButton);
  }

  /**
   * When the player clicks on the buy button.
   */
  onBuyClicked() {
    const dataMgr = TargetedOfferDataManager.getInstance();
    if (this._offerID) dataMgr.onOfferPurchased(this._offerID);
    dataMgr.clearCurrentTargetedOffer();

    if (game.state.current === 'World') {
      G.sb('hideTimedTargetedOfferButton').dispatch(1);
    }
    this._purchaseItem(this._currentDeal);
  }

  /**
  * tries to purchase an item
  * @param {Object} item to buy
  */
  _purchaseItem(item) {
    ShopUtils.purchaseItem(item, this._buyButton, this._coinbar, true, () => {
      this.closeWindow();
    });
  }

  /**
   * create the clsoe button
   */
  _initCloseButton() {
    const targetConfig = this._isLandscape
      ? CLOSE_BUTTON_CONFIG_HORIZONTAL
      : CLOSE_BUTTON_CONFIG_VERTICAL;

    this.addCloseButton(targetConfig.x, targetConfig.y, null, null, this.onCloseClicked.bind(this));
    this.closeButton.scale.set(targetConfig.scale);
  }

  /**
   * If the close button is hit once, the character appears and asks if they really want to close
   * If it's hit twice, the window closes
   */
  onCloseClicked() {
    if (this._closeClicks === 0) { // 1st click
      this._closeClicks++;
      this._buyButton.disableAnimation();
      this._mascotGroup.showWarning();
    } else if (this._closeClicks === 1) { // 2nd click
      TargetedOfferDataManager.getInstance().onOfferClosed(this._offerID);
      this.closeWindow();
    }
  }

  /**
   * make sure the loading indicator is destroyed on close
   */
  closeWindow() {
    this._loadingIndicator.destroy();
    super.closeWindow();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.timedTargetedOffer = Window_TimedTargetedOffer;
