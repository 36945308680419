import TutorialGrid from '../BoardAnim/G.TutorialGrid';
import TutorialGroup from '../BoardAnim/TutorialGroup';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../../OMT_UI/OMT_UI_SquareButton';
import FxParticle from '@omt-game-board/Elements/GameState/FxParticle';

const tileSize = 90;
const gridScale = 1.2;

/**
 * @author Sandra Koo
 */
class RopeMultiTutorial extends TutorialGroup {
  constructor() {
    super(game, null);

    this.currentPage = 0;
    this.pageOne = new Phaser.Group(game, null);
    this.description = undefined; // Text
    this.ropeImage = undefined; // The ice imagery

    this.pageTwo = new Phaser.Group(game, null);
    this.gridBg = undefined; // Grid background
    this.rope = undefined; // ice fronts
    this.gems = undefined; // gems
    this.tutHand = undefined; // Haaaaaand
    this.gemEmitters = []; // sfx
    this.ropeEmitters = []; // sfx
    this.continueButton = undefined; // Button
    this.goalIcon = undefined;
    this.goalNumber = undefined;

    this.drawEverything();
    this.showPageOne();
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this.gridBg) {
      this.gridBg.destroy();
    }

    if (this.rope) {
      this.rope.destroy();
    }

    if (this.cracks) {
      this.cracks.destroy();
    }

    if (this.gems) {
      this.gems.destroy();
    }

    this.pageOne.destroy();
    this.pageTwo.destroy();

    super.destroy();
  }

  /**
   * Draws all elements
   * Initializes some of them
   */
  drawEverything() {
    const gridX = 3;
    const gridY = 2;
    const maxDimension = 70 * 1.2;

    const makeGemRoped = (ropeStrength) => { // Makes crushed ice ;)
      const pack = new Phaser.Group(game, null);
      G.makeImage(0, 0, 'candy_3', 0.5, pack);
      G.makeImage(0, 0, `status_chain_${ropeStrength}`, 0.5, pack);
      pack.width = maxDimension;
      pack.height = maxDimension;
      return pack;
    };

    const makeArrowImage = () => { // Makes arrow and makes it big
      const pack = new Phaser.Group(game, null);
      const arrow = G.makeImage(3, 0, 'arrow_right', 0.5, pack);
      pack.width = maxDimension / 1.5;
      pack.height = maxDimension / 1.5;
      const tw = game.add.tween(arrow)
        .to({ x: -3 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);
      this._everyTween.push(tw);
      return pack;
    };

    this.ropeImage = new Phaser.Group(game, null);
    const ropeImages = [makeGemRoped('3'), makeArrowImage(), makeGemRoped('2'), makeArrowImage(), makeGemRoped('1')]; // Makes all the ice images
    const distance = 10;
    const totalWidth = (maxDimension * ropeImages.length) + (distance * (ropeImages.length - 1)); // Calculates total width
    let nextX = (maxDimension - totalWidth) / 2; // Sets it back
    for (let i = 0; i < ropeImages.length; i++) { // Centers accordingly
      const img = ropeImages[i];
      img.x = nextX;
      this.ropeImage.addChild(img);
      nextX += maxDimension + distance;
    }

    this.gridBg = new TutorialGrid(); // Grid bg. Has the background sprite
    this.gridBg.init(gridX, gridY, tileSize, 'tut_tile', gridScale);

    this.rope = new TutorialGrid(); // For the Ice

    this.gems = new TutorialGrid(); // For the gems

    for (let i = 0; i < 3; i++) { // Particle emitters
      const emitter = new FxParticle(); // For the sfx
      emitter.scale.setTo(gridScale); // It also grows big
      this.gemEmitters.push(emitter);

      const anotherEmitter = new FxParticle();
      anotherEmitter.scale.setTo(gridScale);
      this.ropeEmitters.push(anotherEmitter);
    }

    this.tutHand = new Phaser.Group(game, null);
    G.makeImage(0, 0, 'tut_hand', 0, this.tutHand); // Haaaand

    this.description = new G.Text(0, -150, ' ', { // The instructions
      style: 'font-blue',
      fontSize: 35,
      lineSpacing: -15,
    }, 0.5, 400, 200, true, 'center');

    this.goalIcon = G.makeImage(-15, 0, 'status_chain_2', 0.5, null); // The goal Icon
    this.goalIcon.scale.setTo(0.8);

    this.goalNumber = new G.Text(32, 0, '1', { // The goal counter next to the goal
      style: 'font-blue',
      fontSize: 40,
    }, 0.5);

    this.continueButton = new OMT_UI_SquareButton(0, 270, {
      button: {
        tint: BUTTONCOLOURS.orange,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Continue'),
        textStyle: 'font-white',
      },
      options: {
        clickFunction: {
          onClick: this.onContinueClick.bind(this),
        },
      },
    });
    this.addChild(this.continueButton);
  }

  /**
   * When continue is clicked
   */
  onContinueClick() {
    this.stopAllActions(); // Stop all tweens
    if (this.currentPage === 1) { // If page one, go to page 2
      this.removeChild(this.pageOne);
      this.showPageTwo();
    } else { // Otherwise close
      this.signals.onContinueClick.dispatch();
      this.destroy();
    }
  }

  showPageOne() {
    this.currentPage = 1;
    this.description.setText(OMT.language.getText('Some %gems% are tied with multiple layers of %ropes%.'));

    this.ropeImage.y = this.description.y + (this.description.height / 2) + (this.ropeImage.height * 1.5); // Puts it down in the middle

    this.pageOne.addChild(this.description);
    this.pageOne.addChild(this.ropeImage);

    this.addChild(this.pageOne);
    this.addChild(this.continueButton);
  }

  showPageTwo() {
    this.currentPage = 2;
    const gridX = 3;
    const gridY = 2;

    this.description.setText(OMT.language.getText('Match %gems% tied with %ropes% to clear the different layers!'));

    this.goalNumber.setText('3');
    this.goalNumber.y = this.description.y + (this.description.height + (this.goalNumber.height * 1.5)) / 2;
    this.goalIcon.y = this.goalNumber.y;

    // init grids with candies
    const mechanicData = [3, 0, 1,
                          0, 2, 0]; // eslint-disable-line indent
    this.rope.init(gridX, gridY, tileSize, mechanicData.map((num) => (num === 0 ? null : `status_chain_${num}`)), gridScale); // Passes in an array of null/string to denote which

    const gemsData = [2, 3, 2,
                      1, 2, 5].map((num) => (num === 0 ? null : `candy_${num}`)); // eslint-disable-line indent
    this.gems.init(gridX, gridY, tileSize, gemsData, gridScale); // Passes in array of string to load which gem in where

    this.gridBg.y = this.goalIcon.y + this.goalIcon.height + this.gridBg.height / 2;
    this.rope.y = this.gridBg.y;
    this.gems.y = this.gridBg.y;

    this.pageTwo.alpha = 0;
    this.tutHand.alpha = 0;

    this.addChild(this.description);
    this.pageTwo.addChild(this.goalNumber);
    this.pageTwo.addChild(this.goalIcon);
    this.pageTwo.addChild(this.gridBg);
    this.pageTwo.addChild(this.gems);
    this.pageTwo.addChild(this.rope);
    for (let i = 0; i < this.gemEmitters.length; i++) {
      this.pageTwo.addChild(this.gemEmitters[i]);
      this.pageTwo.addChild(this.ropeEmitters[i]);
    }
    this.pageTwo.addChild(this.tutHand);
    this.addChild(this.pageTwo);
    this.addChild(this.continueButton);

    this.animatePageTwo();
  }

  animatePageTwo() {
    const tweenTime = 500;
    const tweenDelay = 1500;

    this.shouldEventComplete = () => this.game && this.currentPage === 2; // Modifies shouldEventComplete by checking the page

    // Handy function because I'll need a lot of gems
    const getGemData = (board, cellX, cellY) => ({
      sprite: board.getSpriteByCell(cellX, cellY),
      pos: board.getPxPos(cellX, cellY),
    });

    const gem10 = getGemData(this.gems, 1, 0);
    const gem11 = getGemData(this.gems, 1, 1);
    const rope00 = getGemData(this.rope, 0, 0);
    const rope11 = getGemData(this.rope, 1, 1);
    const rope20 = getGemData(this.rope, 2, 0);
    const iconPos = this.gems.toLocal(this.goalIcon.position, this.pageTwo); // Position of the goal icon
    const fxGemTargets = [getGemData(this.gems, 0, 0), gem10, getGemData(this.gems, 2, 0)]; // Target gems for the sfx

    this.tutHand.pivot.copyFrom(this.gems.pivot); // Copies and sets hand pivot to the grid

    const tweenGo = async () => {
      // Reset EVERYTHING
      this.tutHand.scale.set(1); // Hand is normal sized, in position, hidden
      this.tutHand.position.set(this.gems.x + gem11.pos.x, this.gems.y + gem11.pos.y);
      this.tutHand.alpha = 0;
      gem11.sprite.position.copyFrom(gem11.pos);
      gem10.sprite.position.copyFrom(gem10.pos);
      rope00.sprite.position.copyFrom(rope00.pos);
      rope00.sprite.changeTexture('status_chain_3');
      rope11.sprite.position.copyFrom(rope11.pos);
      rope11.sprite.changeTexture('status_chain_2');
      rope20.sprite.position.copyFrom(rope20.pos);
      rope20.sprite.changeTexture('status_chain_1');
      rope20.sprite.alpha = 1;
      rope20.sprite.scale.set(gridScale);
      this.goalNumber.setText('3'); // Goal made to look normal

      await this.wrapTween(this.pageTwo, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Page two fades in
      await this.wrapTween(this.tutHand, { alpha: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand fades in
      await this.wait(tweenDelay / 2); // Wait
      await this.wrapTween(this.tutHand.scale, { x: 0.9, y: 0.9 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand clicks
      this.wrapTween(gem10.sprite, gem11.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The above moves down
      this.wrapTween(gem11.sprite, gem10.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut); // The below moves up
      this.wrapTween(rope11.sprite, gem10.pos, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wrapTween(this.tutHand, { x: this.gems.x + gem10.pos.x, y: this.gems.y + gem10.pos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand moves
      this.wrapTween(this.tutHand, { alpha: 0 }, tweenTime / 2, Phaser.Easing.Sinusoidal.InOut); // Hand fades out quick
      this.wrapTween(this.tutHand.scale, { scale: 1 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Hand unclicks (but fades out before you can see)
      for (let i = 0; i < this.gemEmitters.length; i++) {
        this.gemEmitters[i].burstCandy(this.gems.x + fxGemTargets[i].pos.x - tileSize, this.gems.y + fxGemTargets[i].pos.y - tileSize / 2, 2); // sfx happens
        this.ropeEmitters[i].burstChainAnim(this.gems.x + fxGemTargets[i].pos.x - tileSize, this.gems.y + fxGemTargets[i].pos.y - tileSize / 2); // rope breaks
      }
      rope00.sprite.changeTexture('status_chain_2'); // The rope texture changes
      rope11.sprite.changeTexture('status_chain_1');
      await this.wait(tweenTime / 2);
      await this.wrapTween(rope20.sprite.scale, { x: 1.5, y: 1.5 }, tweenTime, Phaser.Easing.Back.InOut); // Rope grows big
      await this.wait(tweenTime);
      this.wrapTween(rope20.sprite, { x: iconPos.x, y: iconPos.y }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Rope goes to goal icon
      await this.wrapTween(rope20.sprite.scale, { x: 0.8, y: 0.8 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      this.goalNumber.setText('2'); // Goal number updates
      this.wrapTween(rope20.sprite.scale, { x: 2, y: 2 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Goal gem grows really big and fades out
      await this.wrapTween(rope20.sprite, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut);
      await this.wait(tweenDelay);
      await this.wrapTween(this.pageTwo, { alpha: 0 }, tweenTime, Phaser.Easing.Sinusoidal.InOut); // Fades out

      if (this.shouldEventComplete() && this.currentPage === 2) { // Repeats
        tweenGo();
      }
    };
    tweenGo();
  }
}
// create global references
G.RopeMultiTutorial = RopeMultiTutorial;
