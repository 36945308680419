import GameplayChest from './GameplayChest';

export default class ChestLayer extends Phaser.Group {
  /**
   * The group that manages the treasure chest
   * @param {Game} state
   */
  constructor(state) {
    super(game);

    this._state = state;
    this._board = this._state.board;

    this.onChestGiftShown = new Phaser.Signal();

    this._deadArray = [];

    this._signalBinding = G.sb('onChestOpen').add((elem, type) => {
      const pxOut = this._board.cellToPxOut([elem.cellX, elem.cellY]);
      this.getFreeParticle().init(pxOut[0], pxOut[1], type);
      this.sort('orgY', Phaser.Group.SORT_ASCENDING);
    });
  }

  /**
   * Destroy!
   */
  destroy() {
    this._deadArray.forEach((child) => {
      child.destroy();
    });
    if (this._signalBinding) {
      if (this._signalBinding.detach) {
        this._signalBinding.detach();
      }
      this._signalBinding = null;
    }
    super.destroy();
  }

  /**
   * When a chest is killed
   * @param {GameplayChest} elem
   */
  _onElemKilled(elem) {
    if (this !== elem.parent) return;
    this._deadArray.push(elem);
    this.removeChild(elem);
  }

  /**
   * Chest is generated or recycled
   * @returns {GameplayChest}
   */
  getFreeParticle() {
    let part;

    if (this._deadArray.length > 0) {
      part = this._deadArray.pop();
    } else {
      part = new GameplayChest(this._board, this);
      part.onGiftShown.add((giftData) => {
        this.onChestGiftShown.dispatch(part, giftData);
      });
      part.events.onKilled.add(this._onElemKilled, this);
    }

    this.add(part);
    return part;
  }
}
