import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../States/Scaling/GameScaleController';
import FullScreenDragMenu from './FullScreenDragMenu';

const tweenTime = 500;

export default class ClotheslineMenu extends Phaser.Group {
  /**
   * @param {Object} config
   * @param {Game} config.game
   * @param {DisplayObject} [config.parent]
   * @param {Array<FriendshipChest_Polaroid>} config.images
   * @param {number} [config.startingIndex]
   * @param {number} [config.distance] // The distance between polaroids from main polaroid
   * @param {function} config.onReadyCallback
   */
  constructor(config) {
    super(config.game, config.parent);

    this._config = config;
    if (!this._config.distance) {
      this._config.distance = () => game.width / 2;
    }
    this._container = new Phaser.Group(game, this);
    this._rope = undefined;
    this._polaroidImages = undefined;
    this.currentPolaroid = undefined;
    this._visiblePolaroids = [];
    this._temporaryVisible = undefined;
    this._dragDetails = undefined;
    this._transitionTween = undefined;
    this._reorganizePolaroids = _.debounce(this._reorganizeLayers, 10);
    this._polaroidDistance = this._config.distance();
    this._polaroidMaxForce = 10;
    this.signals = {
      onMove: new Phaser.Signal(),
      onMoveFinished: new Phaser.Signal(),
    };

    this._drawClothesline();
    this._setupPolaroids();
    this._setupInteraction();

    this.displayPolaroidAtIndex(config.startingIndex || 0);

    this._resizeToken = G.sb('onScreenResize').add(this.onResize.bind(this));
    this.onResize();
    if (config.onReadyCallback) {
      config.onReadyCallback(this.currentPolaroid, this._visiblePolaroids);
    }
  }

  destroy() {
    if (this._resizeToken && this._resizeToken.detach) {
      this._resizeToken.detach();
    }
    this._resizeToken = null;
    if (this._transitionTween) {
      this._transitionTween.stop();
      this._transitionTween = null;
    }
    Object.keys(this.signals).forEach((key) => {
      this.signals[key].dispose();
    });
    this._dragDetails = {};
    super.destroy();
  }

  _drawClothesline() {
    this._rope = G.makeImage(0, 0, 'friendshipChest_line', 0.5, this._container);
  }

  updatePolaroids(givenPolaroids, currentIndex) {
    this._config.images = givenPolaroids;
    this._setupPolaroids();
    this.displayPolaroidAtIndex(currentIndex);
    this.signals.onMoveFinished.dispatch(this._visiblePolaroids);
  }

  _setupPolaroids() {
    this._polaroidImages = [];
    this._config.images.forEach((img) => {
      if (img.clotheslineY === undefined) {
        const pin = G.makeImage(0, 0, 'friendshipChest_pin', [0.5, 1], img); // Neighbouring cards have their pins gone...
        pin.y = img.y + 35 - img.calculatedBounds.height / 2;
        img.y = this._rope.y + img.calculatedBounds.height / 2;
        img.clotheslineY = img.y;
      }

      this._polaroidImages.push(img);
    });
  }

  /**
   * @param {number} index
   * @returns {FriendshipChest_Polaroid}
   */
  getPolaroidAtIndex(index) {
    for (let i = 0; i < this._polaroidImages.length; i++) {
      if (this._polaroidImages[i].index === index) {
        return this._polaroidImages[i];
      }
    }
    return null;
  }

  displayPolaroidAtIndex(index) {
    const p = this.getPolaroidAtIndex(index);
    if (!p) {
      console.log(`Could not find card at index ${index}`);
      return;
    }
    this.displayPolaroid(p);
  }

  _makePolaroidPhysicTween(target) {
    let sign = Math.sign(target);
    let direction = target;
    let quickReset = 1;
    const absPos = Math.abs(this.currentPolaroid.x);
    const absTarget = Math.abs(target);
    let ratioToTarget = 1 - (absPos / absTarget);
    if (sign === 0) {
      ratioToTarget = 1;
      quickReset = 4;
      direction = Math.sign(this.currentPolaroid.x);
      sign = direction * -1;
    }

    let easing = Phaser.Easing.Sinusoidal.InOut;
    if (ratioToTarget < 0.33) {
      easing = Phaser.Easing.Sinusoidal.Out;
    } else if (ratioToTarget > 0.66) {
      easing = Phaser.Easing.Sinusoidal.In;
    }
    const obj = { start: absPos, target: absTarget }; // eslint-disable-line object-shorthand
    let lastVal = obj.cur = obj.start;
    if (this._transitionTween) {
      this._transitionTween.stop();
    }
    this._transitionTween = game.add.tween(obj)
      .to({ cur: obj.target }, (tweenTime * ratioToTarget) / quickReset, easing, false);
    this._transitionTween.onUpdateCallback(() => {
      this._onDragMove((lastVal - obj.cur) * sign, direction);
      lastVal = obj.cur;
    });
    return this._transitionTween;
  }

  displayNextPolaroid() {
    this._dragDetails.lock = true;
    if (this.currentPolaroid.nextPolaroid && !this._lock.right) {
      this._showNextNextCard();
      this._makePolaroidPhysicTween(this._polaroidDistance);
      this._transitionTween.onComplete.addOnce(() => {
        this._dragDetails.lock = false;
        this.displayPolaroid(this.currentPolaroid.nextPolaroid);
        this.signals.onMoveFinished.dispatch(this._visiblePolaroids);
      });
      this._transitionTween.start();
    } else {
      this._returnToOrigin();
    }
  }

  displayPrevPolaroid() {
    this._dragDetails.lock = true;
    if (this.currentPolaroid.prevPolaroid && !this._lock.left) {
      this._showPrevPrevCard();
      this._makePolaroidPhysicTween(-this._polaroidDistance);
      this._transitionTween.onComplete.addOnce(() => {
        this._dragDetails.lock = false;
        this.displayPolaroid(this.currentPolaroid.prevPolaroid);
        this.signals.onMoveFinished.dispatch(this._visiblePolaroids);
      });
      this._transitionTween.start();
    } else {
      this._returnToOrigin();
    }
  }

  _cleanPolaroidVisuals() {
    if (this.currentPolaroid) {
      this._visiblePolaroids.forEach((pol) => {
        if (!(pol === this.currentPolaroid || pol === this.currentPolaroid.prevPolaroid || pol === this.currentPolaroid.nextPolaroid)) {
          if (pol !== this._temporaryVisible) {
            pol.killForce();
          } else {
            this._temporaryVisible = null;
          }
          this._container.removeChild(pol);
        }
      });
    }
  }

  _resetVisiblePolaroids() {
    this._visiblePolaroids = [];
    if (this.currentPolaroid.index > 0) {
      this._visiblePolaroids.push(this.currentPolaroid.prevPolaroid);
    }
    if (this.currentPolaroid.index < this._polaroidImages.length - 1) {
      this._visiblePolaroids.push(this.currentPolaroid.nextPolaroid);
    }
    this._visiblePolaroids.push(this.currentPolaroid);
  }

  /**
   *
   * @param {FriendshipChest_Polaroid} polaroid
   */
  displayPolaroid(polaroid) {
    this._cleanPolaroidVisuals();
    this.currentPolaroid = polaroid;
    this._resetVisiblePolaroids();
    this._visiblePolaroids.forEach((pol) => {
      this._container.addChild(pol);
    });
    this.onResize();
  }

  _setupInteraction() {
    this._dragDetails = new FullScreenDragMenu();
    this._dragDetails.centerButton.y = -game.height / 2;
    this.addChild(this._dragDetails.centerButton);
    this._dragDetails.signals.onNoChange.add(() => {
      this._returnToOrigin();
    });
    this._dragDetails.signals.leftTransition.add(this.displayPrevPolaroid.bind(this));
    this._dragDetails.signals.rightTransition.add(this.displayNextPolaroid.bind(this));
    this._dragDetails.signals.centerDeltaChange.add(this._onDragMove.bind(this));
    this._lock = {
      left: false,
      right: false,
    };
  }

  _onDragMove(deltaX, newX) {
    if (newX > 0 && newX <= this._polaroidDistance) { // Dragged rightwards to go left
      if (newX !== 0) {
        this._showPrevPrevCard();
      }
      this._visiblePolaroids.forEach((pol) => {
        pol.x += deltaX;
        pol.applyForce(this._polaroidMaxForce * Math.min(deltaX / this._polaroidDistance, 1));
      });
    } else if (newX <= 0 && newX >= -this._polaroidDistance) { // Dragged leftwards to go right
      if (newX !== 0) {
        this._showNextNextCard();
      }
      this._visiblePolaroids.forEach((pol) => {
        pol.x += deltaX;
        pol.applyForce(this._polaroidMaxForce * Math.max(deltaX / this._polaroidDistance, -1));
      });
    }
    this._reorganizePolaroids();
    this.signals.onMove.dispatch(this._visiblePolaroids);
  }

  _reorganizeLayers() {
    this._visiblePolaroids.sort((a, b) => {
      const nA = Math.abs(a.x);
      const nB = Math.abs(b.x);
      if (nA < nB) return 1;
      if (nA > nB) return -1;
      return 0;
    });
    this._visiblePolaroids.forEach((pol) => {
      this._container.addChild(pol);
    });
  }

  _showNextNextCard() {
    if (!this._temporaryVisible && this.currentPolaroid.nextPolaroid) {
      this._temporaryVisible = this.currentPolaroid.nextPolaroid.nextPolaroid;
      if (this._temporaryVisible && this._temporaryVisible.index !== 0) {
        this._temporaryVisible.x = this.currentPolaroid.nextPolaroid.x + this._polaroidDistance;
        this._visiblePolaroids.push(this._temporaryVisible);
        this._container.addChild(this._temporaryVisible);
      } else {
        this._temporaryVisible = null;
      }
    }
  }

  _showPrevPrevCard() {
    if (!this._temporaryVisible && this.currentPolaroid.prevPolaroid) {
      this._temporaryVisible = this.currentPolaroid.prevPolaroid.prevPolaroid;
      if (this._temporaryVisible && this._temporaryVisible.index < this.currentPolaroid.index) {
        this._temporaryVisible.x = this.currentPolaroid.prevPolaroid.x - this._polaroidDistance;
        this._visiblePolaroids.push(this._temporaryVisible);
        this._container.addChild(this._temporaryVisible);
      } else {
        this._temporaryVisible = null;
      }
    }
  }

  /**
   * Locks the drag input or not.
   * The argument is a little backwards, but 
   * true = yes please lock it
   * false = No I want to drag it around
   * @param {boolean} b
   */
  toggleLock(b) {
    this._dragDetails.centerButton.visible = !b;
  }

  /**
   * Prevents the transition left signal
   * @param {boolean} b
   */
  lockLeft(b) {
    this._lock.left = b;
  }

  /**
   * Prevents the transition right signal
   * @param {boolean} b
   */
  lockRight(b) {
    this._lock.right = b;
  }

  _repositionCenter() {
    this._dragDetails.centerButton.x = -game.width / 2;
    this._dragDetails.centerButton.y = -game.height / 2;
  }

  _returnToOrigin() {
    this._dragDetails.lock = true;
    this._repositionCenter();
    this._makePolaroidPhysicTween(0);
    this._transitionTween.onComplete.addOnce(() => {
      this._dragDetails.lock = false;
      this._cleanPolaroidVisuals();
      this._resetVisiblePolaroids();
      this.signals.onMoveFinished.dispatch(this._visiblePolaroids);
    });
    this._transitionTween.start();
  }

  onResize() {
    this._polaroidDistance = this._config.distance();
    this.currentPolaroid.x = 0;
    if (this.currentPolaroid.prevPolaroid) {
      this.currentPolaroid.prevPolaroid.x = -this._polaroidDistance;
    }
    if (this.currentPolaroid.nextPolaroid) {
      this.currentPolaroid.nextPolaroid.x = this._polaroidDistance;
    }

    this._dragDetails.centerButton.hitArea.width = game.width;
    this._dragDetails.centerButton.hitArea.height = game.height;
    this._repositionCenter();

    if (this._rope) {
      const { gameScale } = GameScaleController.getInstance();
      const isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
      this._rope.width = isLandscape
        ? (game.width * 1.5) / gameScale
        : game.width * 1.5;
    }
  }
}
