import ArrayUtil from '../../Utils/ArrayUtil';
import PrizeWheel_Item from './Components/PrizeWheel_Item';
import PrizeWheel_ItemData from './Components/PrizeWheel_ItemData';
import PrizeWheel_Pointer from './Components/PrizeWheel_Pointer';
import PrizeWheel_Spinner, { SPIN_STATES } from './Components/PrizeWheel_Spinner';

const ITEM_GROUP_OFFSET_ANGLE = -90;
const DEG_360 = 360;
const IDLE_SPIN_TO_ITEM_DELAY = 500;

export default class UI_PrizeWheel extends Phaser.Group {
  /**
   * constructor
   * @param {Object} config (optional) components configurations
   * @param {Object} config.spinner (optional) overrides for PrizeWheel_Spinner DEFAULT_CONFIG
   * @param {Object} config.pointer (optional) overrides for PrizeWheel_Pointer DEFAULT_CONFIG
   */
  constructor(config = {}) {
    super(game);

    this._prizeItems = [];
    this._spinQueue = [];
    this._config = config;
    // list of other assets to darken with darken() method
    this._otherElementsToDarken = [];

    // Group that contains the spinner and pointer
    this._wheelDisplay = new Phaser.Group(game, this);
    this.add(this._wheelDisplay);

    this._createSpinner(this._config.spinner);
    this._createPrizePointer(this._config.pointer);
    this._createDarkenOverlay();
    // this._enableDebugMode();
  }

  /**
   * debug function for test spins. click to spin / change state.
   */
  _enableDebugMode() {
    // create some dummy items
    const itemCount = 12;
    const itemDataList = [];
    for (let i = 0; i < itemCount; i++) {
      const coinAmount = (i + 1) * 100;
      const itemData = new PrizeWheel_ItemData(`${coinAmount}`, { coins: coinAmount });
      itemDataList.push(itemData);
    }
    this.initPrizeItems(itemDataList);

    // make spinner clickable and spin to random items
    const spinner = this._spinner;
    spinner._bg.inputEnabled = true;
    spinner._bg.events.onInputDown.add(() => {
      if (spinner.state === SPIN_STATES.IDLE) {
        spinner.setSpinningState();
      } else if (spinner.state === SPIN_STATES.SPINNING) {
        console.log('///////////////////');
        const item = ArrayUtil.getRandomElement(this._prizeItems);
        console.log(`SPIN TO ITEM : ${item.data.label}`);
        this.spinToItem(item, (resultItem) => {
          console.log(`SPIN COMPLETED TO ITEM : ${resultItem.data.label}, REWARD: ${JSON.stringify(resultItem.data.reward)}`);
        });
      }
    });
  }

  /**
   * create background element
   * @param {Object} config configuration for background
   * @param {string} config.asset asset id string
   * @param {number} config.scale scale of bg element
   * @param {number} x x position
   * @param {number} y y position
   * @param {Array.<number>} anchor anchor [x,y]
   * @param {number} alpha opacticy 0-1
   */
  _createBackground(config) {
    const {
      asset, scale, x, y, anchor, alpha, rotationSpeed,
    } = config;

    const bgImage = G.makeImage(x, y, asset, anchor);
    bgImage.scale.x = bgImage.scale.y = scale;
    bgImage.alpha = alpha;
    this.addChildAt(bgImage, 0);
    this._bgImage = bgImage;

    // rotate background on update if rotationSpeed is set
    if (rotationSpeed !== undefined && rotationSpeed !== 0) {
      bgImage.update = () => {
        bgImage.angle += rotationSpeed * G.deltaTime;
        if (bgImage.angle > DEG_360) bgImage.angle -= DEG_360;
      };
    }
  }

  /**
   * create spinning wheel
   * @param {Object} config overrides for PrizeWheel_Spinner DEFAULT_CONFIG
   */
  _createSpinner(config) {
    this._spinner = new PrizeWheel_Spinner(config || {});
    this._wheelDisplay.addChild(this._spinner);
  }

  /**
   * create prize pointer
   * @param {Object} config overrides for PrizeWheel_Pointer DEFAULT_CONFIG
   */
  _createPrizePointer(config) {
    const pointer = new PrizeWheel_Pointer(config || {});
    this._wheelDisplay.addChild(pointer);
    this._spinner.signals.onHitPeg.add((spinVelocity) => {
      pointer.onHitPeg(spinVelocity);
    });
    this._pointer = pointer;
  }

  /**
   * create prize items
   * @param {Array.<PrizeWheel_ItemData>} itemDataList
   */
  initPrizeItems(itemDataList) {
    this._prizeItems.length = 0;

    // create group for prize item overlay
    const prizeItemGroup = new Phaser.Group(game);
    prizeItemGroup.angle = ITEM_GROUP_OFFSET_ANGLE;

    const prizeCount = itemDataList.length;
    const rotationIncrement = DEG_360 / prizeCount;
    let startAngle = 0;
    let endAngle = rotationIncrement;

    // create PrizeWheel_Item instances distributed evenly across 360 degrees
    for (const itemData of itemDataList) {
      const item = new PrizeWheel_Item(itemData);
      item.setRotation(startAngle, endAngle);
      this._prizeItems.push(item);
      prizeItemGroup.addChild(item);
      // update angles for next item
      startAngle += rotationIncrement;
      endAngle += rotationIncrement;
    }

    // add overlay at the end it will be cached
    this._spinner.addOverlay(prizeItemGroup);
  }

  /**
   * set the spinner to the spinning state
   * @param {Function} onMaxVelReached (optional) callback once max velocity reached
   */
  setSpinningState(onMaxVelReached = null) {
    if (this._spinner.state !== SPIN_STATES.IDLE) return;
    const { onMaxSpinVelocityReached } = this._spinner.signals;
    onMaxSpinVelocityReached.removeAll();
    onMaxSpinVelocityReached.addOnce(() => {
      if (onMaxVelReached != null) onMaxVelReached();
    });
    this._spinner.setSpinningState();
  }

  /**
   * spin to a specific item
   * @param {PrizeWheel_Item} item
   * @param {Function} callback (optional) spin complete callback function
   */
  spinToItem(item, callback = null) {
    const spinner = this._spinner;
    if (spinner.state === SPIN_STATES.SPIN_TO_PRIZE) return;
    if (spinner.state === SPIN_STATES.IDLE) { // wheel was idle
      this.setSpinningState(() => {
        game.time.events.add(IDLE_SPIN_TO_ITEM_DELAY, () => {
          this.spinToItem(item, () => {
            callback(item);
          });
        });
      });
    } else if (spinner.state === SPIN_STATES.SPINNING) { // wheel spinning
      const spinToAngle = item.getRandomizedAngle();
      spinner.setSpinToPrizeState(spinToAngle);
      spinner.signals.onSpinToPrizeCompleted.addOnce(() => {
        if (callback != null) callback(item);
      });
    }
  }

  /**
   * spin to the item with the matching itemData instance
   * @param {PrizeWheel_ItemData} itemData
   * @param {Function} callback (optional) spin complete callback function
   */
  spinToItemData(itemData, callback = null) {
    const item = this.getItemByItemData(itemData);
    this.spinToItem(item, callback);
  }

  /**
   * spin to a random item
   * @param {Function} callback (optional) spin complete callback function
   */
  spinToRandomItem(callback = null) {
    const item = ArrayUtil.getRandomElement(this._prizeItems);
    this.spinToItem(item, callback);
  }

  /**
   * spin to first item found with matching reward
   * @param {any} reward
   * @param {Function} callback (optional) spin complete callback function
   */
  spinToReward(reward, callback = null) {
    const item = this.getItemByReward(reward);
    if (item === null) return;
    this.spinToItem(item, callback);
  }

  /**
   * get item with matching data
   * @param {PrizeWheel_ItemData} itemData
   * @returns {PrizeWheel_Item}
   */
  getItemByItemData(itemData) {
    for (const item of this._prizeItems) {
      if (item.data === itemData) return item;
    }
    return null;
  }

  /**
   * get item with matching data
   * @param {any} reward
   * @returns {PrizeWheel_Item}
   */
  getItemByReward(reward) {
    for (const item of this._prizeItems) {
      if (item.data.reward === reward) return item;
    }
    return null;
  }

  /**
   * add a item to the spin queue
   * @param {PrizeWheel_Item} item
   */
  addItemToSpinQueue(item) {
    this._spinQueue.push(item);
  }

  /**
   * add a item to the spin queue by its reward data
   * @param {any} reward
   */
  addItemToSpinQueueByReward(reward) {
    this.addItemToSpinQueue(this.getItemByReward(reward));
  }

  /**
   * spin to first item found with matching reward
   * @param {Function} callback (optional) spin complete callback function
   */
  _spinToQueuedItem(callback = null) {
    if (this._spinQueue.length === 0) {
      console.error('ERROR: OMT_PrizeWheel.spinToQueuedReward() failed. no PrizeWheel_Item(s) have been queued.');
      if (callback) callback();
      return;
    }
    const item = this._spinQueue.shift();
    this.spinToItem(item, callback);
  }

  /**
   * spin to next prize. uses prize queue, or random if no queue
   * @param {Function} callback (optional) spin complete callback function
   */
  spinToNextPrize(callback = null) {
    if (this._spinQueue.length > 0) this._spinToQueuedItem(callback);
    else this.spinToRandomItem(callback);
  }

  /**
   * create darken overlay for dimming when prizes show.
   */
  _createDarkenOverlay() {
    const spinnerDepth = this._wheelDisplay.children.indexOf(this._spinner);
    const { bgAsset } = this._config.spinner;
    this._wheelDarkOverlay = G.makeImage(0, 0, bgAsset, 0.5);
    this._wheelDarkOverlay.tint = 0x000000;
    this._wheelDarkOverlay.alpha = 0;
    this._wheelDarkOverlay.visible = false;
    this._wheelDisplay.addChildAt(this._wheelDarkOverlay, spinnerDepth + 1);
  }

  /**
   * add a element to darken along with the wheel.
   * @param {Phaser.DisplayObject} obj
   */
  addElementToDarkenList(obj) {
    this._otherElementsToDarken.push(obj);
  }

  /**
   * animate darkening the wheel. taken from old code.
   */
  darken() {
    this._wheelDarkOverlay.visible = true;
    game.add.tween(this._wheelDarkOverlay)
      .to({ alpha: 0.5 }, 300, Phaser.Easing.Sinusoidal.InOut, true);

    const curStep = { step: 0 };
    const startTint = this._pointer.tint;
    game.add.tween(curStep)
      .to({ step: 100 }, 300, Phaser.Easing.Sinusoidal.InOut, true)
      .onUpdateCallback(() => {
        const col = Phaser.Color.interpolateColorWithRGB(startTint, 124, 124, 124, 100, curStep.step); // Tints to a mid grey
        this._pointer.tint = Math.abs(col);
        for (const asset of this._otherElementsToDarken) asset.tint = this._pointer.tint;
      });
  }

  /**
   * animate darkening the wheel. taken from old code.
   */
  brighten() {
    game.add.tween(this._wheelDarkOverlay)
      .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.InOut, true)
      .onComplete.add(function temp() {
        this._wheelDarkOverlay.visible = false;
      }, this);

    const curStep = { step: 0 };
    const startTint = this._pointer.tint;
    const tw = game.add.tween(curStep)
      .to({ step: 100 }, 300, Phaser.Easing.Sinusoidal.InOut, false);
    tw.onUpdateCallback(() => {
      const col = Phaser.Color.interpolateColorWithRGB(startTint, 255, 255, 255, 100, curStep.step); // Tints back to off
      this._pointer.tint = Math.abs(col);
      for (const asset of this._otherElementsToDarken) asset.tint = this._pointer.tint;
    });
    tw.start();
  }

  /**
   * @returns {boolean} true if wheel is not in IDLE state
   */
  get isSpinning() {
    return this._spinner.state !== SPIN_STATES.IDLE;
  }
}
