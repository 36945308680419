/**
 * replacement for OOMInfoPanel class
 */
export class TokenEventOOMInfoPanel extends Phaser.Group {
  /**
   * constructor
   */
  constructor() {
    super(game);
    this._initTokenGroup();
  }

  /**
   * init token status group
   */
  _initTokenGroup() {
    const tokenGroup = new Phaser.Group(game, null);
    tokenGroup.y = -50;
    // bg box
    const tokenBg = G.makeImage(0, 0, 'tokenEvent_popup_bigtext_backgr', 0.5, this);
    tokenBg.scale.setTo(0.5, 1);
    // crossed out icon
    const tokenIcon = G.makeImage(50, 0, G.OMTsettings.tokenEvent.boardTokenAsset, 0.5, this);
    tokenIcon.scale.set(89 / tokenIcon.width);

    const cross = G.makeImage(50, 0, 'tokenEvent_crossed', 0.5, this);
    // token count
    const tokenAmount = new G.Text(-50, 0, G.saveState.tokenEventManager.levelTokensCollected, {
      style: 'font-white',
      fontSize: '48px',
      fill: G.OMTsettings.tokenEvent.styling.windowTextFill,
    }, 0.5);
    // layer elements
    tokenGroup.addChild(tokenBg);
    tokenGroup.addChild(tokenIcon);
    tokenGroup.addChild(cross);
    tokenGroup.addChild(tokenAmount);
    this.addChild(tokenGroup);
  }
}
