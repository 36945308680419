/**
 * Class for managing the 'wall clock timer' updates.
 */
export default class WallClockTimer {
  /**
   * Start the timer tick.
   */
  static start() {
    this.stop(); // if already running clear the interval

    /** @private @type {number} */
    this._intervalId = setInterval(() => {
      this._dispatchClockUpdate();
    }, 1000);

    this._dispatchClockUpdate();
  }

  /**
   * Stop the timer Tick.
   */
  static stop() {
    if (this._intervalId !== undefined) {
      clearInterval(this._intervalId);
      this._intervalId = undefined;
    }
  }

  /**
   * Dispatch the clock update.
   * @private
   */
  static _dispatchClockUpdate() {
    const currentTime = Date.now();
    G.sb('onWallClockTimeUpdate').dispatch(currentTime);
  }
}
