/**
 * The icon that appears in World that opens up the friendship chest
 * @author Sandra Koo
 */
export class UI_FriendshipChest extends Phaser.Group {
  /**
   * config = {
   *  x: number,
   *  y: number
   * }
   */
  constructor(config) {
    super(game);

    this.state = game.state.getCurrentState();

    const isFCUnlocked = OMT.feature.getFeatureFriendshipChest() ? OMT.feature.getFeatureFriendshipChestActiveByLevel() : Boolean(G.saveState.friendshipChestDataManager.getUnclaimedCard());

    this._config = config || {};
    this.icon = new G.Button(0, 0, 'friendship_chest_closed');
    const targetScale = 120 / 166; // Target size 68, original width 166
    this.icon.scale.set(targetScale, targetScale);
    this._signalTokens = [];

    // Is unlocked? Do you need to make it shine? Is the notification on!?
    if (isFCUnlocked) {
      this.icon.onClick.add(async () => {
        G.sb('pushWindow').dispatch(['friendshipChest']);
      });
      this.shine = G.makeImage(0, 0, 'popup_lighht', 0.5, this);
      this.shine.alpha = 0.5;
      this.shine.scale.setTo(0.5);
      this.shine.blendMode = 1;
      this.shine.visible = false;
      this.shine.update = () => {
        if (this._skipUpdate) { return; }
        if (this.shine.visible) {
          this.shine.angle += G.deltaTime;
        }
      };
      this.notificationBubble = new G.NotificationBubble(30, -30);
      this.notificationBubble.visible = false;

      this.subtleShakingAnim = game.add.tween(this.icon)
        .to({ angle: [0, -3, 3, 0] }, 1000, Phaser.Easing.Sinusoidal.In, true, 0, 0, true);
      const subtlePause = game.add.tween(this.icon)
        .to({ angle: 0 }, 500, Phaser.Easing.Sinusoidal.In);
      this.subtleShakingAnim.chain(subtlePause);
      subtlePause.chain(this.subtleShakingAnim);

      this.add(this.icon);
      this.add(this.notificationBubble);

      this._signalTokens.push(G.sb('FriendshipChestCheck').add(() => { this.notificationCheck(); }));
      this.notificationCheck();
    } else { // Not unlocked? Tell them when!
      this.unlockTxt = new G.Text(0, 0, OMT.language.getText('Unlock at Level X').replace('X', G.featureUnlock.unlockLevels.friendshipChest + 1), 'ui-friendChest', 0.5, 150, 150, true, 'center');
      this.unlockTxt.lineSpacing = -15;
      this.unlockTxt.setShadow(0, 0, 'black', 3);

      game.add.tween(this.unlockTxt.scale).to({ x: 0.9, y: 0.9 }, 500, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

      this.add(this.icon);
      this.add(this.unlockTxt);
    }

    if (this._config.x && this._config.y) {
      this._signalTokens.push(G.sb('onScreenResize').add(this.onResize, this));
      this.onResize();
    }

    this._signalTokens.push(G.sb('pauseAllUpdate').add(() => {
      this._skipUpdate = true;
    }));
    this._signalTokens.push(G.sb('resumeAllUpdate').add(() => {
      this._skipUpdate = false;
    }));
  }

  destroy() {
    if (this.subtleShakingAnim) {
      this.subtleShakingAnim.stop();
    }
    this._signalTokens.forEach((signal) => {
      if (signal) {
        if (signal.detach) {
          signal.detach();
        }
        signal = null;
      }
    });
    this._signalTokens = [];
    super.destroy();
  }

  onResize() {
    const center = game.world.bounds.x + Math.floor(game.width * 0.5);
    this.x = center + G.OMTsettings.friendshipChest.layoutData.xCenterOffset + this.icon.width / 4;
    this.y = G.OMTsettings.friendshipChest.layoutData.y;
  }

  /**
   * Whenever G.sb('FriendshipChestCheck') happens (from World.js), it'll check if there are messages and if the notification should show!
   */
  async notificationCheck() {
    const cur = this.notificationBubble.visible;
    if (G.saveState.friendshipChestDataManager.getUnclaimedCard()) {
      if (!cur) {
        G.changeTexture(this.icon, 'friendship_chest_full');
        this.notificationBubble.setImportant(true);
        this.notificationBubble.visible = true;
        this.shine.visible = true;
      }
    } else if (cur) {
      G.changeTexture(this.icon, 'friendship_chest_closed');
      this.notificationBubble.visible = false;
      this.shine.visible = false;
    }
  }
}
