G.MapTilesRenderer = function(){
	
	Phaser.Group.call(this,game);

	this.marker = G.makeImage(0,0,null);

	this.rts = [];
	this.imgs = [];

	var totalHeight = this.getMapTotalHeight();
	var heightToCover = totalHeight;
	var tileHeight = 600;
	var nrOfTiles = Math.ceil(heightToCover/tileHeight);

	var mapTiles = {
		totalHeight: totalHeight,
		tiles: []
	};

	for (var i = 0; i < nrOfTiles; i++){

		var height = Math.min(tileHeight,heightToCover);
		this.rts[i] = game.make.renderTexture(1200, tileHeight, 'map-tile-'+i,true);
		this.drawMap(this.rts[i],G.json.map,tileHeight*i);
		heightToCover -= tileHeight;

		mapTiles.tiles.push({
			rt: 'map-tile-'+i,
			y: -i*tileHeight
		});

	}

	G.json.settings.mapTiles = mapTiles;

	this.marker.destroy();

};

G.MapTilesRenderer.prototype = Object.create(Phaser.Group.prototype);


G.MapTilesRenderer.prototype.getMapTotalHeight = function(){

	for (var i = 0; i < G.json.map.length; i++){
		if (G.json.map[i].label && G.json.map[i].label === 'ENDMARKER') {
			return Math.floor(G.json.map[i].y*-1);
		}
	}


};

G.MapTilesRenderer.prototype.drawMap = function(rt,list,offsetY){

	var xOffset = rt.width*0.5;

	var yOffset = rt.height+offsetY;

	for (var i = 0; i < list.length; i++){

		var elem = list[i];

		if (elem.label && elem.label === 'ENDMARKER') continue;
		this.drawElementXY(elem.x+xOffset,elem.y+yOffset,elem,rt);

	}



};

G.MapTilesRenderer.prototype.drawElementXY = function(x,y,elem,rt){

	this.marker.position.setTo(x,y);
	this.marker.anchor.setTo(elem.anchor[0],elem.anchor[1]);
	this.marker.angle = elem.angle;
	this.marker.scale.setTo(elem.scale[0],elem.scale[1]);
	G.changeTexture(this.marker,elem.frame);
	this.marker.updateTransform();
	rt.renderXY(this.marker, x, y);


};