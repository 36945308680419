import ShareMsgBase from './A_G.ShareMsgBase';

class MsgWonThreeTimes extends ShareMsgBase {
/**
 * The message that gets sent when you beat 3 levels in a row
 * @param {string} user1AvatarURL
 * @param {string} userName
 * @param {number} lvlIndex
 * @param {function} onAllImagesReadyCallback
 */
  constructor(user1AvatarURL, userName, lvlIndex, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    this.avatar1 = this._addExtImage(250, 78, user1AvatarURL, 0, 188, null);
    this.bg = this._addExtImage(0, 0, 'img/Share_ThreeTimesWon.png', 0, null, null);

    const beatLvlStr = OMT.language.getText('(name) beat three levels in a row!').replace('(name)', userName);
    this.beatLvlTxt = new G.Text(343, 320, beatLvlStr, 'msg-yellow-2', 0.5, 400);
    this.add(this.beatLvlTxt);
    this.beatLvlTxt.addColor('#ffffff', userName.length);

    this.levelTxtGroup = new Phaser.Group(game, this);
    this.levelMasterTxt = new G.Text(565, 82, OMT.language.getText('3 in a row!'), 'msg-orangeFontStyle', 0.5, 190);
    this.levelTxt = new G.Text(565, 148, OMT.language.getText('Level'), 'msg-blueFontStyle', 0.5, 110);
    this.levelNumberTxt = new G.Text(565, 215, lvlIndex.toString(), {
      style: 'msg-blueFontStyle',
      fontSize: '90px',
    }, 0.5, 110);
    this.levelTxtGroup.add(this.levelMasterTxt);
    this.levelTxtGroup.add(this.levelTxt);
    this.levelTxtGroup.add(this.levelNumberTxt);

    this._objectLoadComplete();
  }
}

G.MsgWonThreeTimes = MsgWonThreeTimes;
