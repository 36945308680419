import { Window } from '../../00_IMMEDIATE/Window';
import TreasureHuntManager from '../../Services/OMT/dataTracking/treasureHuntManager/TreasureHuntManager';
import TreasureHuntPrizeGroup from './TreasureHuntPrizeGroup';
import TreasureHuntPodiumGroup from './TreasureHuntPodiumGroup';

export default class Window_TreasureHuntPrizeClaim extends Window {
  /**
   * Window for claiming prizes.
   * Most of the visuals come from TreasureHuntPrizeGroup though
   *
   * The Podium is a part of this window too because of the seemless flow between getting a badge and the podium
   * @param {Phaser.DisplayObject} parent
   * @param {boolean} [showPodium]
   * @param {number} [prizeIndex]
   * @param {number} [chestIndex]
   */
  constructor(parent, showPodium, prizeIndex, chestIndex) {
    super(parent);
    if (game.state.current === 'Game') this.y -= 75;
    this._initElements(prizeIndex, chestIndex, showPodium);
  }

  /**
   * Inits everything
   * @param {number} prizeIndex
   * @param {number} chestIndex
   * @param {boolean} showPodium
   */
  async _initElements(prizeIndex, chestIndex, showPodium) {
    if (showPodium) {
      this._podiumGroup = new TreasureHuntPodiumGroup(this, {
        closeWindow: this.closeWindow.bind(this),
      });
      await this._podiumGroup.init();
    }
    if (!Number.isFinite(prizeIndex)) {
      const isThereAPrize = G.saveState.treasureHuntManager.nextPrize;
      if (isThereAPrize) {
        prizeIndex = isThereAPrize.i;
      }
    }
    const maxChest = G.OMTsettings.treasureHuntSuper.reward.currencyUnlock.length - 1;
    if (!Number.isFinite(chestIndex)) {
      chestIndex = Math.min(maxChest, Math.max(0, prizeIndex));
    }
    if (prizeIndex < 0 && !showPodium) {
      this.closeWindow();
    }

    if (prizeIndex !== undefined) {
      this._prizeIndex = prizeIndex;
      this._prizeGroupAnimation = new TreasureHuntPrizeGroup(this, {
        prizeIndex,
        chestIndex,
        closeWindow: this.closeWindow.bind(this),
        podium: this._podiumGroup,
      });
      this._prizeGroupAnimation.animate(); // Prize group will call podium's animation when ready
    } else if (this._podiumGroup) {
      this._podiumGroup.readyExtraAsset();
      this._podiumGroup.animate();
    }
    G.sb('hideMainHighscorePanel').dispatch();
  }

  /**
   * Open the proper treasure hunt window again
   */
  closeWindow() {
    // Move window up
    if (!this._podiumGroup) {
      G.sb('forceTreasureHuntIconRefresh').dispatch();
      G.sb('showMainHighscorePanel').dispatch();
      TreasureHuntManager.openTreasureHuntPopup(true);
    }
    super.closeWindow();
  }

  /**
   * Destroy!!
   */
  destroy() {
    if (this._prizeGroupAnimation) {
      this._prizeGroupAnimation.destroy();
    }
    super.destroy();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.treasureHuntPrizeClaim = Window_TreasureHuntPrizeClaim;
