import OMT_VILLAINS from '../../OMT_UI/OMT_Villains';

const width = 640;
const height = 1242;

const convertToPercentageX = (x) => (x / width) * 100;
const convertToPercentageY = (y) => (y / height) * 100;

const yOffset = 52;

export default [
  {
    texture: OMT_VILLAINS.getPrefixedName('villain_1_1'),
    landscape: {
      baseWidth: width * 1.08,
      baseHeight: height,
    },
    transforms: [
      // Initial transform
      {
        x: convertToPercentageX(-70),
        y: convertToPercentageY(133 + yOffset),
        callback: (object) => {
          object.scale.setTo(0.45);
          object.scale.x *= -1;
        },
      },
      {
        x: convertToPercentageX(23),
        y: convertToPercentageY(101 + yOffset),
        duration: 100,
      },
      {
        x: convertToPercentageX(90),
        y: convertToPercentageY(148 + yOffset),
        duration: 100,
        callback: (object) => {
          object.changeTexture(OMT_VILLAINS.getPrefixedName('villain_1_4'));
        },
      },
      {
        wait: 1200,
        callback: (object) => {
          object.changeTexture(OMT_VILLAINS.getPrefixedName('villain_1_1'));
        },
      },
      {
        y: convertToPercentageY(105 + yOffset),
        duration: 70,
      },
      {
        y: convertToPercentageY(84 + yOffset),
        duration: 30,
        callback: (object, config) => {
          const { parent } = config;
          parent.addChildAt(object, 0);
        },
      },
      {
        y: convertToPercentageY(147 + yOffset),
        duration: 70,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('villain_2_1'),
    landscape: {
      baseWidth: width * 1.08,
      baseHeight: height,
    },
    transforms: [
      // Initial transform
      {
        x: convertToPercentageX(704),
        y: convertToPercentageY(109 + yOffset),
        callback: (object) => {
          object.scale.setTo(0.45);
        },
      },
      {
        x: convertToPercentageX(640),
        y: convertToPercentageY(90 + yOffset),
        duration: 100,
      },
      {
        x: convertToPercentageX(537),
        y: convertToPercentageY(148 + yOffset),
        duration: 100,
        callback: (object) => {
          object.changeTexture(OMT_VILLAINS.getPrefixedName('villain_2_4'));
          G.sfx.whoosh.play();
        },
      },
      {
        wait: 970,
        callback: (object) => {
          object.changeTexture(OMT_VILLAINS.getPrefixedName('villain_2_1'));
          G.sfx.xylophone_positive_12.play();
        },
      },
      {
        y: convertToPercentageY(102 + yOffset),
        duration: 100,
        callback: (object, config) => {
          const { parent } = config;
          parent.addChildAt(object, 0);
        },
      },
      {
        y: convertToPercentageY(113 + yOffset),
        duration: 35,
      },
      {
        y: convertToPercentageY(145 + yOffset),
        duration: 100,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('super_hard_corruption'),
    destroyOnComplete: true,
    landscape: {
      scaleCoeff: {
        scale: 1.6,
      },
    },
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(-8),
        y: convertToPercentageY(-217),
        scale: 0.68,
      },
      {
        wait: 370,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(747),
        y: convertToPercentageY(1162),
        duration: 300,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('super_hard_corruption'),
    destroyOnComplete: true,
    landscape: {
      scaleCoeff: {
        scale: 1.6,
      },
    },
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(-71),
        y: convertToPercentageY(-39),
        scale: 0.37,
      },
      {
        wait: 370,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(646),
        y: convertToPercentageY(1333),
        duration: 730,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('super_hard_corruption'),
    destroyOnComplete: true,
    landscape: {
      scaleCoeff: {
        scale: 1.6,
      },
    },
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(102),
        y: convertToPercentageY(-188),
        scale: 0.67,
      },
      {
        wait: 535,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(826),
        y: convertToPercentageY(1170),
        duration: 300,
        callback: () => {
          G.sb('corruptWindow').dispatch();
        },
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('super_hard_corruption'),
    destroyOnComplete: true,
    landscape: {
      scaleCoeff: {
        scale: 1.6,
      },
    },
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(265),
        y: convertToPercentageY(-141),
        scale: 0.9,
      },
      {
        wait: 700,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(797),
        y: convertToPercentageY(769),
        duration: 500,
      },
    ],
  },
  {
    texture: OMT_VILLAINS.getPrefixedName('super_hard_corruption'),
    destroyOnComplete: true,
    landscape: {
      scaleCoeff: {
        scale: 1.6,
      },
    },
    transforms: [
      {
        alpha: 0,
        x: convertToPercentageX(-254),
        y: convertToPercentageY(-312),
        scale: 2.1,
      },
      {
        wait: 770,
      },
      {
        alpha: 1,
      },
      {
        x: convertToPercentageX(927),
        y: convertToPercentageY(1638),
        duration: 400,
      },
    ],
  },
];
