/* eslint-disable no-unused-vars */
import { CandyStatus } from './CandyStatus';
import { TOKEN_TYPES, EDITOR_SYMBOLS } from '../../BoardConstants';

/**
 * class for gem-box candy status.
 */
export class CandyStatus_GemBox extends CandyStatus {
  /**
   * constructor
   * @param {BoardGameHooks} gameHooks
   */
  constructor(gameHooks) {
    super(gameHooks);
  }

  /**
   * main intialization method
   * @param {Candy} candy
   * @param {Object} config
   * @param {string} optColo optional candy color
   */
  init(candy, config, optColor) {
    super.init(candy, config);

    // determine gem box color
    if (optColor === EDITOR_SYMBOLS.RANDOM && this._postRandomPhase) {
      optColor = null;
    }
    if (!optColor) {
      const maxTypeNumber = candy.board ? candy.board.lvl.nrOfTypes : 4;
      this._boxColor = game.rnd.between(1, maxTypeNumber).toString();
      if (!this._postRandomPhase) {
        this._boxColor = EDITOR_SYMBOLS.RANDOM;
      }
    } else {
      this._boxColor = optColor;
    }

    this._wrapperImg = G.makeImage(0, 0, `gem_box_${this._boxColor}`, 0.5, candy);
  }

  /**
   * set / update match token color for the gem box.
   * @param {string} color
   */
  setMatchToken(color) {
    this._boxColor = color;
    this._wrapperImg.changeTexture(`gem_box_${this._boxColor}`);
  }

  /**
   * return a match token override.
   * @param {string} token
   * @returns {string}
   */
  changeMatchToken(token) {
    if (this._boxColor === EDITOR_SYMBOLS.RANDOM) return token;
    return this._boxColor;
  }

  /**
   * on parent candy part of a match.
   * @returns {boolean}
   */
  onMatch() {
    this.signals.onRemove.dispatch();
    this.remove();
    return false;
  }

  /**
   * on parent hit by adjacent match.
   * @returns {boolean}
   */
  onHit() {
    return false;
  }

  /**
   * inherit matchable from candy.
   * @param {boolean} matchable
   * @returns {boolean}
   */
  passMatchable(matchable) {
    return true;
  }

  /**
   * remove the status and animate it out.
   */
  remove() {
    this._gameHooks.playSound('brick_break');
    G.sb('onCollectableRemove').dispatch('bx', this._candy);
    G.sb('onCollectableRemove').dispatch(`bx:${this._boxColor}`, this._candy);

    // set remove animation
    if (!this._postRandomPhase) {
      this._wrapperImg.destroy();
    } else {
      game.add.tween(this._wrapperImg)
        .to({
          y: this._wrapperImg.y - 50,
          alpha: 0,
        }, 1000, Phaser.Easing.Cubic.Out, true)
        .onComplete.add(() => {
          this._wrapperImg.destroy();
        }, this);
    }
  }

  /**
   * loops through status states for the editor.
   */
  changeForEditor() {
    const options = [EDITOR_SYMBOLS.RANDOM, '1', '2', '3', '4', '5', '6'];
    const newColor = options[options.indexOf(this._boxColor) + 1];
    if (newColor) {
      this._boxColor = newColor;
      this._wrapperImg.changeTexture(`gem_box_${this._boxColor}`);
    } else {
      this.signals.onRemove.dispatch();
      this.remove();
    }
  }

  /**
   * export editor data.
   * @returns {string}
   */
  export() {
    return `${this._editorSymbol}|${this._boxColor}`;
  }
}
