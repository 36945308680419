import UI_AnimationGroup from '@omt-components/UI/Animation/UI_AnimationGroup';
import { TextTimer, TIMER_FORMAT } from '../../TextTimer';

const c_maxFrames = 30;

export default class TokenEventUIIcon extends UI_AnimationGroup {
  /**
   * The icon on the world map for eventPostcard that brings you to the shop
   */
  constructor() {
    super(game, null);

    this._drawAsset();

    this._ready = {
      drawing: false,
      text: false,
    };

    // Determine range
    if (G.featureUnlock.tokenEvent.range) {
      this.makeTimerText(G.featureUnlock.tokenEvent.range.end);
    } else {
      this._ready.text = true;
    }

    this._ready.drawing = true;
    this.show();

    this._animate = true;
    this._signalTokens = [];
    this._signalTokens.push(G.sb('pauseAllUpdate').add(() => {
      this._animate = false;
    }));
    this._signalTokens.push(G.sb('resumeAllUpdate').add(() => {
      this._animate = true;
    }));
  }

  /**
   * Destroy!
   */
  destroy() {
    if (this._animateTimer && this._animateTimer.destroy) {
      this._animateTimer.destroy();
    }
    for (const signal of this._signalTokens) if (signal && signal.detach) signal.detach();
    this._signalTokens.length = 0;
    super.destroy();
  }

  /**
   * Draws Asset
   */
  _drawAsset() {
    this._container = new G.Button(0, 0, null, this.onClick, this);
    this._innerContainer = new Phaser.Group(game, this._container);

    this._bg = G.makeImage(G.OMTsettings.tokenEvent.mapIcon.layout.bg.x, G.OMTsettings.tokenEvent.mapIcon.layout.bg.y, 'tokenEventIcon-bg', [0.5, 1], this._innerContainer);
    this._bg.y += this._bg.height / 2;
    this._currency = new Phaser.Image(game, G.OMTsettings.tokenEvent.mapIcon.layout.token.x, G.OMTsettings.tokenEvent.mapIcon.layout.token.y);
    this._innerContainer.addChild(this._currency);
    const token = G.makeImage(0, 0, G.OMTsettings.tokenEvent.boardTokenAsset, [0.5, 1], this._currency);
    token.scale.set(G.OMTsettings.tokenEvent.mapIcon.layout.token.size / token.width);
    this._currency.y += this._currency.height / 2;
    this._timerBg = G.makeImage(G.OMTsettings.tokenEvent.mapIcon.layout.timerBanner.x, G.OMTsettings.tokenEvent.mapIcon.layout.timerBanner.y, 'tokenEventIcon-timer', 0.5, this._innerContainer);

    this._bg.originalPosition = this._bg.position.clone();
    this._currency.originalPosition = this._currency.position.clone();

    this._animateTimer = game.time.events.loop(2000, this._animateIcon.bind(this));
  }

  /**
   * Animates the icon
   */
  async _animateIcon() {
    this._bg.position.set(this._bg.originalPosition.x, this._bg.originalPosition.y);
    this._currency.position.set(this._currency.originalPosition.x, this._currency.originalPosition.y);
    this._bg.scale.set(1);
    this._currency.scale.set(1);

    if (!this.shouldEventComplete()) { return; }

    // Squish
    await this.wrapTween(this._currency.scale, { y: 0.88 }, this._framesToTime(4), Phaser.Easing.Linear.None);

    // Pop
    await this.wrapTween(this._currency.scale, { y: 1.1 }, this._framesToTime(2), Phaser.Easing.Linear.None);

    // Jump
    this.wrapTween(this._currency, { y: this._currency.originalPosition.y - 18 }, this._framesToTime(10), Phaser.Easing.Linear.None);
    await this.wrapTween(this._currency.scale, { y: 0.95 }, this._framesToTime(10), Phaser.Easing.Linear.None);

    // Return back down
    this.wrapTween(this._currency, { y: this._currency.originalPosition.y }, this._framesToTime(5), Phaser.Easing.Linear.None);
    this.wrapTween(this._bg.scale, { y: 1.06 }, this._framesToTime(4), Phaser.Easing.Linear.None);

    // Touch down
    await this.wrapTween(this._currency.scale, { y: 0.88 }, this._framesToTime(4), Phaser.Easing.Linear.None);

    // Revert back
    this.wrapTween(this._bg.scale, { y: 1 }, this._framesToTime(5), Phaser.Easing.Linear.None);
    await this.wrapTween(this._currency.scale, { y: 1 }, this._framesToTime(5), Phaser.Easing.Linear.None);
  }

  /**
   * Opens shop or more postcards depending on environment
   */
  onClick() {
    G.sb('pushWindow').dispatch(['eventLevel', G.saveState.getEventLevel(false)]);
  }

  /**
   * Icon is ready and will show
   */
  show() {
    if (this._ready.drawing && this._ready.text) {
      this._container.scale.set(0.01);
      this.addChild(this._container);
      game.add.tween(this._container.scale).to({ x: 1, y: 1 }, 250, Phaser.Easing.Sinusoidal.InOut, true);
    }
  }

  /**
   * Timer text is made for the event postcard
   */
  makeTimerText(endTime) {
    const curTime = OMT.connect.getServerTimestampSync();
    const durationLeftSec = (endTime - curTime) / 1000;
    let timeStyle = TIMER_FORMAT.MS;
    const shownEndTime = G.changeSecToDHMS(durationLeftSec);
    if (Number.parseInt(shownEndTime[0]) > 0) {
      timeStyle = TIMER_FORMAT.DH;
    } else if (Number.parseInt(shownEndTime[1]) > 0) {
      timeStyle = TIMER_FORMAT.HM;
    }
    this._timer = this.makeTimerObject(timeStyle);
    this._timer.setSecLeft(durationLeftSec);
    this._timer.active = true;
    this._innerContainer.addChild(this._timer);
    this._ready.text = true;
    this.show();
  }

  /**
   * Creates the timer object. Can be overwritten
   */
  makeTimerObject(timeStyle) {
    return new TextTimer({
      x: G.OMTsettings.tokenEvent.mapIcon.layout.timerText.x,
      y: G.OMTsettings.tokenEvent.mapIcon.layout.timerText.y,
      style: 'eventPostcard-mapIconTimer',
      anchor: 0.5,
      maxWidth: this._innerContainer.width,
      timerFormat: timeStyle,
    });
  }

  /**
   * Checks if this is allowed to animate
   */
  shouldEventComplete() {
    return this._animate && this.alive;
  }

  _framesToTime(frame) {
    return (frame / c_maxFrames) * 1000;
  }
}
