import { ActionStartBoosters } from './ActionStartBooster';

const INTIAL_DELAY = 500;

/**
 * Action for handling board startBoosters
 */
export class ActionOOMBoosters extends ActionStartBoosters {
  /**
   * initialize the action.
   * @param {Array.<string>} boosterList
   */
  _init(boosterList) {
    this._boosters = [];
    this._popCounter = 0;
    this._startPositions = this._generateBubbleStartPositions();
    this._positionIndex = 0;
    this._delay = INTIAL_DELAY;

    const { lvlDataManager } = this._board;
    lvlDataManager.clearStartBoosters();

    // list of normal candies boosters can be applied to
    this._normals = this._getTargetCandies();
    this._normalsIndex = 0;

    this._initOOMBoosters(boosterList);
  }

  /**
   * initialize the action.
   * @param {Array.<string>} boosterList
   */
  _initOOMBoosters(boosterList) {
    const { gameHooks } = this._board;
    for (const boosterType of boosterList) {
      this._createStartBoosterBubble(boosterType);
      gameHooks.onOOMBoosterUse(boosterType);
    }
  }
}
