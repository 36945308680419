import { OMT_Utils } from '@omt-components/Services/Utils/OMT_Utils';

/**
 * Class for backing up and restoring local storage data manually from the local machine
 */
export class UserDataBackup {
  /**
   * constructor
   */
  constructor() {
    // Overlay elements
    this._overlayElement = null;
    this._exportTitleElement = null;
    this._importTitleElement = null;
    this._fileInputElement = null;
    this._importButtonElement = null;
    this._exportButtonElement = null;
    this._downloadLink = null;

    // Download feature flags
    this._downloadsEnabled = false;
    // Use legacy save method:
    // true = download via <a>
    // false = download via File System Access API
    this._useLegacyExport = typeof window.showSaveFilePicker !== 'function';
  }

  /**
   * Initializes and shows overlay
   */
  initOverlay() {
    if (!this._overlayElement) {
      if (window.FileList && window.File) {
        // Create elements
        this._overlayElement = document.createElement('div');
        this._overlayElement.style.top = '180px';
        this._overlayElement.style.left = '0%';
        this._overlayElement.style.position = 'fixed';
        this._overlayElement.style.width = '180px';
        this._overlayElement.style.height = '120px';
        this._overlayElement.style.color = 'rgb(0,255,0)';
        this._overlayElement.style.backgroundColor = 'rgba(0,0,0,0.6)';
        this._overlayElement.style.paddingTop = '10px';
        this._overlayElement.style.paddingLeft = '10px';
        this._overlayElement.style.fontSize = '15px';
        document.body.appendChild(this._overlayElement);

        this._exportTitleElement = document.createElement('span');
        this._exportTitleElement.innerHTML = 'Export user data';
        this._exportTitleElement.style.position = 'absolute';
        this._exportTitleElement.style.top = '0px';
        this._overlayElement.appendChild(this._exportTitleElement);

        this._exportButtonElement = document.createElement('input');
        this._exportButtonElement.type = 'button';
        this._exportButtonElement.value = 'Export';
        this._exportButtonElement.style.position = 'absolute';
        this._exportButtonElement.style.top = '20px';
        this._overlayElement.appendChild(this._exportButtonElement);

        this._importTitleElement = document.createElement('span');
        this._importTitleElement.innerHTML = 'Import user data';
        this._importTitleElement.style.position = 'absolute';
        this._importTitleElement.style.top = '50px';
        this._overlayElement.appendChild(this._importTitleElement);

        this._fileInputElement = document.createElement('input');
        this._fileInputElement.type = 'file';
        this._fileInputElement.accept = '.json';
        this._fileInputElement.style.position = 'absolute';
        this._fileInputElement.style.top = '70px';
        this._fileInputElement.style.color = 'rgb(255,255,255)';
        this._overlayElement.appendChild(this._fileInputElement);

        this._importButtonElement = document.createElement('input');
        this._importButtonElement.type = 'button';
        this._importButtonElement.value = 'Import';
        this._importButtonElement.disabled = true;
        this._importButtonElement.style.position = 'absolute';
        this._importButtonElement.style.top = '100px';
        this._overlayElement.appendChild(this._importButtonElement);

        this._downloadLink = document.createElement('a');
        this._downloadLink.display = 'none';
        this._downloadLink.style.position = 'absolute';
        this._downloadLink.style.top = '0px';
        this._overlayElement.appendChild(this._downloadLink);

        // Add listeners
        this._exportButtonElement.addEventListener('click', async () => this._exportUserData());

        this._fileInputElement.addEventListener('change', () => {
          this._importButtonElement.disabled = false;
        });

        this._importButtonElement.addEventListener('click', async () => this._importUserData());
      } else {
        OMT_Utils.stylizedLog('Error: FileList/File is not supported on this browser', '#FF8080');
      }
    }
  }

  /**
   * Removes overlay
   */
  removeOverlay() {
    if (this._overlayElement) {
      document.body.removeChild(this._overlayElement);
      this._exportTitleElement = null;
      this._importTitleElement = null;
      this._fileInputElement = null;
      this._importButtonElement = null;
      this._exportButtonElement = null;
      this._downloadLink = null;
      this._overlayElement = null;
    }
  }

  /**
   * Export current user data for download
   */
  async _exportUserData() {
    const exportedData = OMT.userData.exportUserData() || {};

    const json = JSON.stringify(exportedData);

    // Last ditch fallback
    OMT_Utils.stylizedLog('Exported user data:', '#80FF80');
    OMT_Utils.stylizedLog(json, '#80FF80');

    if (this._downloadsEnabled) {
      if (!this._useLegacyExport) {
        // Download using File System Access API
        // This doesn't work in Firefox
        // Chrome also blocks downloads from cross-origin iframes :(

        // Get download handle
        const dlOptions = {
          types: [
            {
              description: 'User Data',
              accept: {
                'application/json': ['.json'],
              },
            },
          ],
        };
        const fileHandle = await window.showSaveFilePicker(dlOptions);

        // Write file
        const writable = await fileHandle.createWritable();
        await writable.write(json);
        await writable.close();
      } else {
        // Fallback download method with <a>
        const filename = 'userdata.json';
        const dlOptions = { type: 'application/json' };
        const file = new File([json], '', dlOptions);

        this._downloadLink.href = window.URL.createObjectURL(file);
        this._downloadLink.setAttribute('download', filename);
        this._downloadLink.click();
      }
    }
  }

  /**
   * Imports user data file from local machine
   */
  async _importUserData() {
    const file = this._fileInputElement.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', async (loadEvent) => {
      try {
        const importedData = JSON.parse(loadEvent.target.result);
        await G.saveState.resetAllData();

        const importKeys = Object.keys(importedData);
        for (const key of importKeys) {
          // eslint-disable-next-line no-await-in-loop
          await OMT.userData.writeUserData(key, importedData[key]);
        }

        OMT_Utils.stylizedLog('[CHEAT] User data import successful! Restarting game...', '#FFFF80');
        setTimeout(OMT.platformFunctions.restartGame, 500);
      } catch (error) {
        OMT_Utils.stylizedLog(`[CHEAT] ERROR: could not import user data - ${error}`, '#FF8000');
      }
    });
    reader.readAsText(file);
  }
}
