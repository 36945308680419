import FxParticle from '../../FxParticle';

export default class TopFxLayer extends Phaser.SpriteBatch {
  /**
   * The spot where sfx happens
   * @param {Board} board
   * @param {string} [signalName] defaults to 'fx'
   */
  constructor(board, signalName) {
    super(game);

    this.aboveThirdFloorLayer = false; // An extra group layer that is assigned externally

    this._board = board;

    this._signalBinding = [];

    this._deadArray = [];

    this._init(signalName);
  }

  _init(signalName) {
    this._signalBinding.push(G.sb(signalName || 'fx').add(this.initEffect, this));
  }

  /**
   * Destroy!
   */
  destroy() {
    this._deadArray.forEach((child) => {
      child.destroy();
    });
    if (this._signalBinding) {
      if (this._signalBinding.detach) {
        this._signalBinding.detach();
      }
      this._signalBinding = null;
    }
    super.destroy();
  }

  /**
   * When a particle is kill
   * @param {FxParticle} elem
   */
  _onElemKilled(elem) {
    if (this !== elem.parent) return;
    this.removeChild(elem);
    this._deadArray.push(elem);
  }

  /**
   * A particle is either generated or recycled
   */
  _getFreeParticle() {
    let part;
    if (this._deadArray.length > 0) {
      part = this._deadArray.pop();
    } else {
      part = new FxParticle(this._board, this);
      part.events.onKilled.add(this._onElemKilled, this);
    }

    this.add(part);
    return part;
  }

  /**
   * Particle effect happens. Can be called externally
   * @param {string} effect
   * @param {Candy} candy
   * @param {any} args
   * @param {any} args2
   */
  initEffect(effect, candy, args, args2) {
    const part = this._getFreeParticle();
    part[effect](
      this._board.cellXToPxIn(candy.cellX),
      this._board.cellYToPxIn(candy.cellY),
      args,
      args2,
    );

    return part;
  }
}
