/* eslint-disable no-confusing-arrow */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
if (typeof G === 'undefined') G = {};
G.UI = G.UI || {};

{
  const hasUIComponent = (object) =>
    Boolean(object.customComponents && object.customComponents.ui);

  const getSize = (object) => {
    let width;
    let height;
    if (hasUIComponent(object)) {
      const uiComponent = object.customComponents.ui;
      width = uiComponent.desiredSize.x;
      height = uiComponent.desiredSize.y;
    } else {
      width = object.width;
      height = object.height;
    }

    return {
      width,
      height,
    };
  };

  const addUIComponent = (target, uiComponent) => {
    target.customComponents = target.customComponents || {};
    target.customComponents.ui = uiComponent;
  };

  const fillLayoutFromData = (
    layout,
    layoutData,
    pivotData,
    scaleX,
    scaleY,
  ) => {
    const offsets = layoutData.offsets || [0, 0, 0, 0];
    layout.cornerOffsets.fromLeft = offsets[0] * scaleX;
    layout.cornerOffsets.fromTop = offsets[1] * scaleY;
    layout.cornerOffsets.fromRight = -offsets[2] * scaleX;
    layout.cornerOffsets.fromBottom = -offsets[3] * scaleY;

    if (layoutData.anchorsManualEditHelper) {
      const data = layoutData.anchorsManualEditHelper;
      const imaginaryPivotData = data.imaginaryPivot || pivotData;
      layout.calculateAnchorsFor(
        data.imaginaryParentSize[0],
        data.imaginaryParentSize[1],
        data.imaginaryPosition[0] -
          imaginaryPivotData[0] * data.imaginarySize[0],
        data.imaginaryPosition[1] -
          imaginaryPivotData[1] * data.imaginarySize[1],
        data.imaginarySize[0],
        data.imaginarySize[1],
      );
    } else if (layoutData.anchors) {
      layout.anchors.start.set(layoutData.anchors[0], layoutData.anchors[1]);
      layout.anchors.end.set(layoutData.anchors[2], layoutData.anchors[3]);
    }
  };

  const getConstrains = (constrainsData, scaleX, scaleY) => {
    const { defined } = G.Utils;
    const center = defined(constrainsData.constrainCenter, [0.5, 0.5]);

    const scaled = (value, scale) =>
      value === undefined ? null : value * scale;

    return {
      widthMin: scaled(constrainsData.widthMin, scaleX),
      widthMax: scaled(constrainsData.widthMax, scaleX),
      heightMin: scaled(constrainsData.heightMin, scaleY),
      heightMax: scaled(constrainsData.heightMax, scaleY),
      preserveAspectRatio: defined(constrainsData.preserveAspectRatio, false),
      aspectRatioMin: defined(constrainsData.aspectRatioMin, null),
      aspectRatioMax: defined(constrainsData.aspectRatioMax, null),
      constrainAnchorX: center[0],
      constrainAnchorY: center[1],
    };
  };

  /**
   * @param uiElementType {G.UI.ElementType}
   * @returns uiComponent {G.UI.UIComponent}
   */
  const createUIComponent = (
    uiElementType,
    parentWidth,
    parentHeight,
    optLayoutData,
    optScaleX,
    optScaleY,
  ) => {
    // imports
    const { defined } = G.Utils;
    const { AnchoredLayoutComponent } = G.UI;
    const { Vector2 } = G.Math;

    const scaleX = defined(optScaleX, 1);
    const scaleY = defined(optScaleY, 1);
    const layoutData = optLayoutData || {};

    const pivotData = layoutData.pivot || [0, 0];
    const pivot = new Vector2(pivotData[0], pivotData[1]);

    const layout = new AnchoredLayoutComponent();
    fillLayoutFromData(layout, layoutData, pivotData, scaleX, scaleY);
    const desiredSize = layout.getLocalSize(parentWidth, parentHeight);

    const sizeAfterLastResize = new Vector2(NaN, NaN);

    const constrains = getConstrains(
      layoutData.constrains || {},
      scaleX,
      scaleY,
    );

    const uiComponent = {
      type: uiElementType,
      desiredSize,
      sizeAfterLastResize,
      anchoredLayout: layout,
      layoutConstrains: constrains,
      pivot,
    };

    return uiComponent;
  };

  const cloneConstrains = (other) => ({
    widthMin: other.widthMin,
    widthMax: other.widthMax,
    heightMin: other.heightMin,
    heightMax: other.heightMax,
    preserveAspectRatio: other.preserveAspectRatio,
    aspectRatioMin: other.aspectRatioMin,
    aspectRatioMax: other.aspectRatioMax,
    constrainAnchorX: other.constrainAnchorX,
    constrainAnchorY: other.constrainAnchorY,
  });

  const cloneUIComponent = (other, type) => {
    const uiComponent = {
      type,
      desiredSize: other.desiredSize.clone(),
      sizeAfterLastResize: other.sizeAfterLastResize.clone(),
      anchoredLayout: other.anchoredLayout.clone(),
      layoutConstrains: cloneConstrains(other.layoutConstrains),
      pivot: other.pivot.clone(),
    };

    return uiComponent;
  };

  G.UI.getSize = getSize;
  G.UI.hasUIComponent = hasUIComponent;
  G.UI.addUIComponent = addUIComponent;
  G.UI.createUIComponent = createUIComponent;
  G.UI.cloneUIComponent = cloneUIComponent;
}
