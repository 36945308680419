/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';
import { GameScaleController } from '../../States/Scaling/GameScaleController';
import { WINDOW_CONSTANTS } from '../../Elements/WindowMgr';
import OMT_TweenUtils from '../../Utils/Animation/OMT_TweenUtils';
import OMT_StackManager from '../../Utils/OMT_StackManager';
import OMT_GingySpeechTutorial from '../OMT_GingySpeechTutorial';
import OMT_UI_HighlightBackground from '../OMT_UI_HighlightBackground';
import OMT_UI_TutorialHand from '../OMT_UI_TutorialHand';
import VillainsBaseClass from './VillainsBaseClass';
import VillainsSpeech from './VillainSpeech';

/**
 * Responsible for creating villains related tutorials
 */
export default class VillainsConversation extends VillainsBaseClass {
  /**
   * Construct the villains!
   * @param {Phaser.DisplayObject} parent
   * @param {{highlight:boolean, pointer:boolean}} config
   */
  constructor(parent, config = {}) {
    super(parent);
    const { highlight, pointer, tapToContinue } = config;

    this._isLandscape = OMT.systemInfo.orientation === ORIENTATION.horizontal;
    this._gameScale = GameScaleController.getInstance().gameScale;

    this.config = config;
    this.parent = parent;
    this.resolveArray = [];

    this.gingyData = {};
    this.villainData = {};

    if (highlight) {
      const maskObject = new OMT_UI_HighlightBackground({
        alpha: highlight,
        isFullscreen: false,
      });
      this.maskObject = maskObject;

      parent.add(maskObject);
    }

    if (pointer) {
      const pointerObject = new OMT_UI_TutorialHand();
      this.pointerObject = pointerObject;

      pointerObject.alpha = 0;
      parent.add(pointerObject);
    }

    if (tapToContinue) {
      const continuePrompt = new G.Text(0, 0, OMT.language.getText('Tap to continue...'), {
        style: 'font-white',
        fontSize: 36,
      }, 0.5, 500);
      continuePrompt.alpha = 0;
      parent.add(continuePrompt);
      this.continuePrompt = continuePrompt;

      const { x, y } = parent.toLocal({
        x: game.width * 0.5,
        y: game.height - WINDOW_CONSTANTS.WorldVerticalOffset - continuePrompt.height,
      });
      continuePrompt.position.setTo(x, y);
    }

    this.resizeSb = G.sb('onScreenResize').add(this._onResize, this);
    this.signals = [this.resizeSb];
    this._onResize();

    this.stepCount = 0;
    this.stepFunctions = {};
  }

  /**
   * Create tutorial for villains
   * @param {'hard'|'super_hard'} difficulty The difficulty setting for the tutorial
   * @param {'pre_win_saga_map'|'level_window'|'game'|'post_win_saga_map'} stage The tutorial stage to animate
   */
  async createTutorial(difficulty, stage) {
    const { parent, maskObject, continuePrompt } = this;
    const tutorialData = this._getTutorialData(difficulty, stage);

    const stack = OMT_StackManager.getFreeStack();

    if (maskObject) {
      stack.addPromise(() => maskObject.show());
    }

    for (const step of tutorialData) {
      const {
        source,
        text,
        x,
        y,
        index,
        subIndex,
        pos,
        duration,
        offset,
        clickable,
      } = step;

      stack.addEvent(() => {
        this._triggerStepFunction(this.stepCount, 'start');
      });

      if (continuePrompt) {
        if (clickable && !continuePrompt.alpha) {
          stack.addEvent(() => {
            OMT_TweenUtils.changeAlphaTo({
              object: continuePrompt,
              alpha: 0.7,
              duration: 200,
            });
          });
        } else if (!clickable && continuePrompt.alpha) {
          stack.addEvent(() => {
            OMT_TweenUtils.changeAlphaTo({
              object: continuePrompt,
              alpha: 0,
              duration: 200,
            });
          });
        }
      }

      if (source === 'gingy') {
        if (this.villain) {
          stack.addPromise(() => this.villain.hideBubble());
        }
        if (!this.gingy) {
          this.gingy = new OMT_GingySpeechTutorial(parent, this.getText(text), {
            offset,
          });

          stack.addEvent(() => {
            if (this.gingyPositionFunc) {
              this.gingyPositionFunc(this.gingy);
            } else {
              this._positionClass(this.gingy, x, y);
              this.gingyData = {
                x,
                y,
              };
            }

            G.sfx.pop.play();
          });
          stack.addPromise(() => this.gingy.show());
          stack.addEvent(() => {
            const gingyCharacter = this.gingy.getObjects({ type: 'gingy' })[0];
            gingyCharacter.surprised();
          });
        } else {
          const comparisonData = {
            x,
            y,
          };

          stack.addPromise(() => {
            const { gingy, gingyPositionFunc } = this;
            if (gingyPositionFunc) {
              gingyPositionFunc(gingy);
            } else if (!_.isEqual(comparisonData, this.gingyData)) {
              const subStack = OMT_StackManager.getFreeStack();

              subStack.addPromise(() => this.gingy.hide());
              subStack.addEvent(() => {
                this._positionClass(this.gingy, x, y);

                this.gingyData = {
                  x,
                  y,
                };
              });
              subStack.addEvent(() => {
                this.gingy.setTextSync(this.getText(text));
                G.sfx.pop.play();
              });
              subStack.addPromise(() => this.gingy.show());
              subStack.addPromise(() => {
                if (offset) {
                  this.gingy.config.offset = offset;
                  return this.gingy.positionBubbleContainer();
                }
                return Promise.resolve();
              });

              return subStack.run();
            } else if (offset) {
              const subStack = OMT_StackManager.getFreeStack();

              subStack.addPromise(() => {
                if (offset) {
                  this.gingy.config.offset = offset;
                  return this.gingy.positionBubbleContainer();
                }
                return Promise.resolve();
              });
              subStack.addPromise(() => this.gingy.setText(this.getText(text)));

              return subStack.run();
            }

            return this.gingy.setText(this.getText(text));
          });
        }
      } else {
        if (this.gingy) {
          stack.addPromise(() => this.gingy.hideBubble());
        }
        if (!this.villain) {
          this.villain = new VillainsSpeech(
            parent,
            this.getText(text),
            pos,
            index,
            subIndex,
            undefined,
            'center',
          );

          stack.addEvent(() => {
            if (this.villainPositionFunc) {
              this.villainPositionFunc(this.villain);
            } else {
              this._positionClass(this.villain, x, y);
              this.villainData = {
                x,
                y,
                index,
                subIndex,
                pos,
              };
              G.sfx.exchange.play();
            }
          });
          stack.addPromise(() => this.villain.show());
        } else {
          const comparisonData = {
            x,
            y,
            index,
            subIndex,
            pos,
          };

          stack.addPromise(() => {
            const { villain, villainPositionFunc } = this;

            if (!_.isEqual(comparisonData, this.villainData)) {
              const subStack = OMT_StackManager.getFreeStack();

              subStack.addPromise(() => this.villain.hide());
              subStack.addEvent(() => {
                this.villain.getClassContainer().destroy();
              });
              subStack.addEvent(() => {
                this.villain = new VillainsSpeech(
                  parent,
                  this.getText(text),
                  pos,
                  index,
                  subIndex,
                  undefined,
                  'center',
                );

                if (this.villainPositionFunc) {
                  this.villainPositionFunc(this.villain);
                } else {
                  this._positionClass(this.villain, x, y);
                  this.villainData = {
                    x,
                    y,
                    index,
                    subIndex,
                    pos,
                  };
                  G.sfx.exchange.play();
                }
              });
              subStack.addPromise(() => this.villain.show());

              return subStack.run();
            }

            if (villainPositionFunc) {
              villainPositionFunc(villain);
            }

            return this.villain.setText(this.getText(text));
          });
        }
      }

      if (clickable) {
        stack.addParallel(this._getAdvancedWaitUntilStackArray(duration));
      } else if (duration !== undefined) {
        stack.wait(duration);
      }

      stack.addEvent(() => this._triggerStepFunction(this.stepCount, 'end'));
      stack.addEvent(() => {
        this.stepCount++;
      });
    }

    this._handleClick();
    return stack.run().then(() => {
      this.resolveArray = null;
    });
  }

  /**
   * Gets the tutorial data from OMTsettings
   * @param {'hard'|'super_hard'} difficulty The difficulty setting for the tutorial
   * @param {'pre_win_saga_map'|'level_window'|'game'|'post_win_saga_map'} stage The tutorial stage to animate
   */
  _getTutorialData(difficulty, stage) {
    return G.OMTsettings.elements.superhard.tutorialSpeech[difficulty][stage];
  }

  /**
   * Filters the resolved promise function from the resolveArray
   * @param {*} resolveFunc The resolve function of the resolved promise
   */
  _handleResolve(resolveFunc) {
    this.resolveArray = this.resolveArray.filter(
      (resolve) => resolve !== resolveFunc,
    );
  }

  /**
   * Add an advanced wait until task that does not wait for animations to complete
   * @param {any} duration
   * @param {() => void} callback
   * @returns {OMT_StackManager[]} array
   */
  _getAdvancedWaitUntilStackArray(duration, callback) {
    const waitUntilStack = OMT_StackManager.getFreeStack();
    const resolve = waitUntilStack.waitUntil(duration, () => {
      this._handleResolve(resolve);
      if (callback) {
        callback();
      }
    });
    const resolveArrayStack = OMT_StackManager.getFreeStack();
    resolveArrayStack.addEvent(() => {
      this.resolveArray.push(resolve);
    });
    return [resolveArrayStack, waitUntilStack];
  }

  /**
   * Add the pointer down handler to the game for skipping tutorial points
   */
  _handleClick() {
    game.input.onDown.add(() => {
      if (!this.resolveArray || this.resolveArray.length === 0) return;
      const nextResolve = this.resolveArray.shift();
      nextResolve();
    });
  }

  /**
   * Positions the class container with the given values
   * @param {any} character The class that extends the baseClass to position
   * @param {number} x X coordinate of the position
   * @param {number} y Y coordinate of the position
   */
  _positionClass(character, x, y) {
    const { parent } = this;
    const classContainer = character.getClassContainer();
    const { x: _x, y: _y } = parent.toLocal({
      x: (x * game.width) / 100,
      y: (y * game.height) / 100,
    });
    classContainer.x = _x;
    classContainer.y = _y;
  }

  /**
   * Positions the class container with the given values
   * @param {any} character The class that extends the baseClass to position
   * @param {number} scale The scale to set the class to
   */
  _scaleClass(character, scale) {
    const classContainer = character.getClassContainer();
    classContainer.scale.setTo(scale);
  }

  /**
   * Add functions to be called in certain steps
   * Can be additive, does not overwrite, but adds functions to the queue
   * @param {object} config
   */
  addStepFunctions(config) {
    for (const [stepCount, callbacks] of Object.entries(config)) {
      if (!this.stepFunctions[stepCount]) {
        this.stepFunctions[stepCount] = {};
        this.stepFunctions[stepCount].start = [];
        this.stepFunctions[stepCount].end = [];
      }

      for (const [timing, funcs] of Object.entries(callbacks)) {
        let _functions = funcs;
        if (!Array.isArray(funcs)) _functions = [funcs];
        this.stepFunctions[stepCount][timing].push(..._functions);
      }
    }
  }

  /**
   * Trigger a specific step function at a step count and timing
   * @param {number} stepCount
   * @param {'start'|'end'} timing
   */
  async _triggerStepFunction(stepCount, timing) {
    const { config, stepFunctions } = this;

    if (stepFunctions[stepCount] && stepFunctions[stepCount][timing]) {
      const stack = OMT_StackManager.getFreeStack();

      stepFunctions[stepCount][timing].forEach((func) => {
        stack.addEvent(async () => {
          await func({
            conversation: this,
            ...config,
          });
        });
      });

      return stack.run();
    }

    return Promise.resolve();
  }

  /**
   * Calls this function each time Gingy is refreshed to overwrite the position and other parameters of Gingy
   * @param {Function} gingyPositionFunc
   */
  fixGingyToPosition(gingyPositionFunc) {
    this.gingyPositionFunc = gingyPositionFunc;
  }

  /**
   * Calls this function each time a villain is refreshed to overwrite the position and other parameters of the villain
   * @param {Function} villainPositionFunc
   */
  fixVillainToPosition(villainPositionFunc) {
    this.villainPositionFunc = villainPositionFunc;
  }

  /**
   * Hides the conversation by hiding all elements in parallel
   */
  async hide() {
    const { gingy, villain, maskObject } = this;

    const stack = OMT_StackManager.getFreeStack();
    const subStacks = [];

    if (gingy) {
      const subStack = OMT_StackManager.getFreeStack();
      subStack.addPromise(() => gingy.hide());
      subStacks.push(subStack);
    }
    if (villain) {
      const subStack = OMT_StackManager.getFreeStack();
      subStack.addPromise(() => villain.hide());
      subStacks.push(subStack);
    }
    if (maskObject) {
      const subStack = OMT_StackManager.getFreeStack();
      subStack.addPromise(() =>
        OMT_TweenUtils.changeAlphaTo({
          object: maskObject,
          alpha: 0,
          duration: 500,
        }),
      );
      subStacks.push(subStack);
    }

    if (subStacks.length) {
      stack.addParallel(subStacks);
    }

    return stack.run();
  }

  /**
   * Returns the highlight object
   */
  getHighlight() {
    return this.maskObject;
  }

  /**
   * Returns the pointer object
   */
  getPointer() {
    return this.pointerObject;
  }

  /**
   * Get text for speech, getting a random one if needed
   * @param {string} text
   */
  getText(text) {
    text = text.split('|');
    return text[Math.floor(Math.random() * text.length)];
  }

  /**
   * resize method
   */
  _onResize() {
    const { maskObject, gingy, gingyPositionFunc, villain, villainPositionFunc } = this;

    if (maskObject) {
      maskObject.y = this.parent.toLocal({
        x: game.width * 0.5,
        y: game.height * 0.5,
      }).y;
      maskObject.resize();
    }

    if (gingy && gingyPositionFunc) {
      gingyPositionFunc(gingy);
    }

    if (villain && villainPositionFunc) {
      villainPositionFunc(villain);
    }
  }
}
