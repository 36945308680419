/* eslint-disable no-unused-vars */

/**
 * class for basic / base CandyType
 */
export class CandyType_Basic {
  /**
   * constructor
   * @param {BoardGameHooks}
   */
  constructor(gameHooks) {
    this._gameHooks = gameHooks;
    this._collected = false;
    this._fallingBlocked = false;
    this._defineSignals();
  }

  /**
   * define signals
   */
  _defineSignals() {
    this.signals = {
      removeCandy: new Phaser.Signal(),
      changeCandyTexture: new Phaser.Signal(),
    };
  }

  /**
   * remove signals
   */
  _removeSignals() {
    this.onBoardDeconstructed.dispose();
    this.onActionQueueEmpty.dispose();
  }

  /**
   * main initialization
   * @param {Candy} candy
   * @param {Object} config
   */
  init(candy, config) {
    this._config = config;
    this._candy = candy;
    if (config.texture) this.signals.changeCandyTexture.dispatch(config.texture);

    this._candyType = config.candyType;
    this._matchable = G.Utils.defined(config.matchable, true);
    this._changeableToSpecialType = G.Utils.defined(config.canHaveSpecialType, true);
    this._tags = G.Utils.defined(config.tags, []);
    this._editorSymbol = G.Utils.defined(config.editorSymbol, '');
  }

  /**
   * check if this candy has already been collected.
   * @returns {boolean}
   */
  isAlreadyCollected() {
    return this._collected;
  }

  /**
   * mark candy as collected.
   */
  markAsCollected() {
    this._collected = true;
  }

  /**
   * check if candy should block a move.
   * @returns {boolean}
   */
  isBlockingMove() {
    return false;
  }

  /**
   * check if candy can be matched.
   * @returns {boolean}
   */
  isMatchable() {
    return this._matchable;
  }

  /**
   * on moved with another candy.
   * @param {Candy} otherCandy
   * @returns {boolean}
   */
  onMove(otherCandy) {
    return true;
  }

  /**
   * on candy part of a match.
   * @returns {boolean}
   */
  onMatch() {
    return true;
  }

  /**
   * on booster used on candy.
   */
  onBooster() {
    // override to implement
  }

  /**
   * on candy hit by adjacent match.
   * @returns {boolean}
   */
  onHit() {
    return false;
  }

  /**
   * check if this candy can have a special type.
   * @returns {boolean}
   */
  canHaveSpecialType() {
    return this._changeableToSpecialType;
  }

  /**
   * get the editor symbol for the CandyType.
   * @returns {string}
   */
  getEditorSymbol() {
    return this._editorSymbol;
  }

  /**
   * collect the CandyType.
   */
  collect() {
    // override to implement
  }

  /**
   * export editor symbol for editor
   * @returns {string}
   */
  export() {
    return this._editorSymbol;
  }

  /**
   * check if this CandyType has a specific tag.
   * @param {string} tag
   * @returns {boolean}
   */
  hasTag(tag) {
    return this._tags.indexOf(tag) !== -1;
  }

  /**
   * destruction method
   */
  destroy() {
    this._candy = null;
  }

  /** GETTER METHODS ********************************* */

  /** @returns {Array} get list of tags */
  get tags() { return this._tags; }

  /** @returns {boolean} true if falling is blocked */
  get fallingBlocked() { return this._fallingBlocked; }
}
