if (typeof G == 'undefined') G = {};
G.Animations = G.Animations || {};
G.Animations.Generic = G.Animations.Generic || {};

G.Animations.Generic.createFadeOutAnimation = (
  function createFadeOutAnimation(duration) {
    return { animate: animate };
    function animate(game, target) {
      game.add.tween(target).to(
        { alpha: 0 },
        duration, Phaser.Easing.Cubic.Out, true
      ).onComplete.addOnce(function() {
        target.visible = false;
        target.exists = false;
      });
    }
  }
);
