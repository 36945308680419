import { FX_ParticleEmitter } from '../particles/FX_ParticleEmitter';
import { FX_ParticleEmitterConfig } from '../particles/FX_ParticleEmitterConfig';

export class FX_CoinShower extends FX_ParticleEmitter {
  /**
   * constructor
   */
  constructor() {
    super(new FX_ParticleEmitterConfig({
      allowTransforms: true,
      maxParticles: 100,
      spawnRandomX: 1170,
      spawnRandomY: 0,
      spritesheet: 'shared-elements',
      frames: ['coin_1'],
      emitAngle: Math.PI / 2,
      emitAngleVariance: 0,
      gravity: 40,
      minParticleVel: 800,
      maxParticleVel: 1000,
      minSpawnDelay: 20,
      maxSpawnDelay: 40,
      minParticleLife: 1500,
      maxParticleLife: 2000,
    }));
    this.spawnActive = false;
  }

  /**
   * start the emitter
   */
  startTheParty() {
    this.spawnActive = true;
  }

  /**
   * stop the emitter
   */
  stopTheParty() {
    this.spawnActive = false;
  }
}
