import { Window } from '../../00_IMMEDIATE/Window';
import OMT_UI_SquareButton, { BUTTONCOLOURS } from '../../OMT_UI/OMT_UI_SquareButton';

/**
 * Class for ad retry if no other fallbacks are present
 */
export class Window_FillRateFallback extends Window {
  /**
   * constructor
   * @param {Object} parent
   * @param {Object} config
   */
  constructor(parent, config) {
    super(parent);

    this._config = config;
    if (!this._config.reqAmount) this._config.reqAmount = 3;
    this._success = false;

    this._reqAmount = this._config.reqAmount;
    this._currentAmount = 0;

    this._initLayout();
    this._initButtons();

    this._firstScreen = this.add(this._createFirstScreen());
    this._fadeIn();
  }

  /**
   * fade in the window
   */
  _fadeIn() {
    this.alpha = 0;
    game.add.tween(this).to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.Out, true);
  }

  /**
   * init the graphics layout
   */
  _initLayout() {
    // make bg
    this.add(G.GiftUI.Elements.Overlay());
    this.y = 20;
    this._bg = G.makeImage(0, 0, 'popup_background_2', 0.5, this);

    // title
    this._titleTxt = new G.Text(0, -260, OMT.language.getText('Get your reward'), G.OMTsettings.global.window.smallTitle.textStyle, 0.5, 450);
    this.add(this._titleTxt);
  }

  /**
   * init buttons
   */
  _initButtons() {
    // close button
    this._closeBtn = new G.Button(250, -270, 'btn_x', () => {
      this._onClosed();
      if (this._config.onExit) this._config.onExit();
    }, this);
    this._closeBtn.addTerm(() => !this._success, this);
    this.add(this._closeBtn);

    // try again button
    this._tryAgainBtn = new OMT_UI_SquareButton(0, 190, {
      button: {
        tint: BUTTONCOLOURS.blue,
        dimensions: {
          width: 196,
          height: 100,
        },
      },
      text: {
        string: OMT.language.getText('Try again'),
        textStyle: {
          style: 'font-white',
          fontSize: 30,
          lineSpacing: -20,
        },
      },
      options: {
        clickFunction: {
          onClick: this._tryToWatchAd.bind(this),
        },
      },
    });
    this.add(this._tryAgainBtn);
  }

  /**
   * create first screen display group
   */
  _createFirstScreen() {
    const group = game.make.group();
    group.position.setTo(0, 0);
    group.somethingWentWrongTxt = new G.Text(0, -150,
      OMT.language.getText('No video available'), {
        style: 'font-blue',
        fontSize: '45px',
      }, 0.5, 480);
    group.add(group.somethingWentWrongTxt);
    group.gingy = G.makeImage(0, -25, 'failed_dimond', 0.5, group);
    group.gingy.scale.setTo(1.2);
    return group;
  }

  /**
   * try to watch the ad again
   */
  _tryToWatchAd() {
    if (this._success) { return; }
    OMT.ads.showAd(
      this._config.placement || G.BuildEnvironment.adPlacements.rewarded,
      () => {
        this._success = true;
        this._onClosed();
      },
      () => {
        this.alpha = 0;
        game.add.tween(this).to({ alpha: 1 }, 500, Phaser.Easing.Sinusoidal.Out, true);
      },
    );
  }

  /**
   * on window closed
   */
  _onClosed() {
    if (!this.closing) {
      this.ignoreChildInput = true;
      game.add.tween(this)
        .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.In, true)
        .onComplete.add(() => {
          this.closeWindow();
          this.destroy();
          G.sb('fillRateFallbackClosed').dispatch();
          if (this._success && this._config.callback) {
            this._config.callback.call(this._config.context);
          }
        }, this);
    }
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.FillRateFallback = Window_FillRateFallback;
