import ShareMsgBase from './A_G.ShareMsgBase';

class MsgHardLevelComplete extends ShareMsgBase {
  /**
   * The message that is shared when you beat a hard level and you want to brag
   * @param {string} user1AvatarURL
   * @param {string} userName
   * @param {number} lvlIndex
   * @param {function} onAllImagesReadyCallback
   */
  constructor(user1AvatarURL, userName, lvlIndex, onAllImagesReadyCallback = null) {
    super(3, onAllImagesReadyCallback);

    this.avatar1 = this._addExtImage(490, 94, user1AvatarURL, 0, 134, null);
    this.bg = this._addExtImage(0, 0, 'img/Share_Hard_Level_Complete.png', 0, null, null);

    const beatLvlStr = OMT.language.getText(G.json.shareMessageText.shareHardLevelCompleteMsg[2]);
    this.beatLvlTxt = new G.Text(343, 330, beatLvlStr, 'shareMessageText', 0.5, 650);
    this.add(this.beatLvlTxt);
    this.beatLvlTxt.addColor('#ffff00', userName.length);

    this.levelTxtGroup = new Phaser.Group(game, this);
    this.levelMasterTxt = new G.Text(343, 243, OMT.language.getText(G.json.shareMessageText.shareHardLevelCompleteMsg[0])
      .replace('%NL%', '\n')
      .replace('%NAME%', userName), {
      style: 'msg-purpleFontStyle',
      lineSpacing: -20,
    }, 0.5, 300);
    this.levelTxt = new G.Text(343, 40, OMT.language.getText(G.json.shareMessageText.shareHardLevelCompleteMsg[1]), {
      style: 'msg-purpleFontStyle',
    }, 0.5, 110);
    this.levelNumberTxt = new G.Text(343, 110, lvlIndex.toString(), {
      style: 'msg-purpleFontStyle',
      fontSize: '72px',
    }, 0.5, 220);
    this.levelTxtGroup.add(this.levelMasterTxt);
    this.levelTxtGroup.add(this.levelTxt);
    this.levelTxtGroup.add(this.levelNumberTxt);

    this._objectLoadComplete();
  }
}

G.MsgHardLevelComplete = MsgHardLevelComplete;
