export const LIGHT_OFF_ASSETS = [
  'realMoney_light_off',
  'realMoney_light_off_plat',
];

export const LIGHT_STYLE = {
  NORMAL: 0,
  PLATINUM: 1,
};

export const LIGHTS_MODES = {
  Off: 'OFF',
  On: 'ON',
  Single: 'SINGLE',
  Alternating: 'ALTERNATING',
  Jackpot: 'JACKPOT',
};
