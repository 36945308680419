const COIN_BAR_COUNTER_POS = { x: 8, y: 4 };
const COIN_BAR_COIN_SCALE = 0.6;

/**
 * class for deal coinbar graphics
 */
export class TargetedOffer_DealCoinbar extends Phaser.Group {
  /**
   * constructor
   * @param {number} coins
   * @param {number} scale
   * @param {number || string} fontSize
   * @param {number} tint color #
   */
  constructor(coins, scale, fontSize, tint) {
    super(game);

    this._drawCoinbarBg(tint);
    this._drawCoinIcon();
    this._drawCoinTextCounter(coins, fontSize);

    this.cacheAsBitmap = true;

    this.scale.set(scale);
  }

  /**
   * draw the coin bar bg
   * @param {number} tint color #
   */
  _drawCoinbarBg(tint) {
    this._coinbarBg = G.makeImage(0, 0, 'shop3_specialDealCoinBar', 0.5, this);
    this._coinbarBg.tint = tint * 1;
    this._coinBarBgHightlight = G.makeImage(0, 0, 'shop3_specialDealCoinBarHighlight', 0.5, this._coinbarBg);
  }

  /**
   * draw the coin icon
   */
  _drawCoinIcon() {
    const xPos = -this._coinbarBg.width / 2;
    const dealCoinIcon = G.makeImage(xPos, 0, 'coin_3', 0.5, this);
    dealCoinIcon.scale.set(COIN_BAR_COIN_SCALE);
  }

  /**
   * draw coin counter text
   * @param {number} coins
   * @param {number || string} fontSize
   */
  _drawCoinTextCounter(coins, fontSize) {
    const cointCounter = new G.TextCounter(
      COIN_BAR_COUNTER_POS.x, COIN_BAR_COUNTER_POS.y,
      coins,
      { style: 'font-white', fontSize },
      0.5, 0,
    );
    this.addChild(cointCounter);
  }
}
