import { GameScaleController } from '../../States/Scaling/GameScaleController';
import { ORIENTATION } from '../../Services/OMT/OMT_SystemInfo';

G.TreasureHunt = G.TreasureHunt || {};

G.TreasureHunt.GameplayGemCounter = function(initAmount, maxAmount) {
  Phaser.Group.call(this, game);
  this.fixedToCamera = true;

  this.mainGroup = game.add.group(this);
  this.mainGroup.name = "mainGroup";
  this.mainGroup.x = 320;

  if (OMT.systemInfo.orientation === ORIENTATION.horizontal) {
    this.mainGroup.scale.setTo(GameScaleController.getInstance().gameScale);
  }

  this.shine = G.makeImage(-60, 0, "popup_lighht", 0.5, this.mainGroup);
  this.shine.blendMode = 1;
  this.shine.name = "shine";
  this.shine.alpha = 0;
  G.Animations.getShineAnimation().animate(this.game, this.shine);

  this.textGroup = new Phaser.Group(game);
  this.mainGroup.addChild(this.textGroup);

  this.bg = G.makeImage(-26, 0, "ingame_counter", [1, 0.5], this.textGroup);
  this.bg.scale.setTo(1.05);

  const mask = new Phaser.Graphics(game); // Fancy text mask
  mask.beginFill(0xffffff);
  mask.drawRect(0, 0, this.bg.width, this.bg.height);
  mask.endFill();
  mask.x = -mask.width - 45;
  mask.y = -mask.height / 2;
  mask.dirty = true;

  this.textGroup.mask = mask;
  this.mainGroup.addChild(mask);

  this.chest = G.makeImage(-36, -7, "Chest_half_full", 0.5, this.mainGroup);
  this.chest.scale.setTo(0.55);

  this.amount = initAmount || 0;
  this.maxAmount = maxAmount || 50;

  this.amountTxt = this.textGroup.add(new G.Text(-110, 0, "0", {
    style: "font-blue",
    fontSize: 35,
  }, 0.5));

  this.showReasons = new G.Reasons(this.onShowReasonChanged, this);

  G.Utils.cleanUpOnDestroy(this, 
    G.sb("onScreenResize").add(this.onResize, this));
  this.onResize();
};

G.TreasureHunt.GameplayGemCounter.prototype = Object.create(Phaser.Group.prototype);

G.TreasureHunt.GameplayGemCounter.ReasonToShow = {
  Gem: "gem",
  OutOfMovesPopUp: "outOfMoves",
};

G.TreasureHunt.GameplayGemCounter.prototype.onResize = function() {
  this.cameraOffset.x = game.width;
  if (OMT.systemInfo.orientation === ORIENTATION.vertical) {
    this.cameraOffset.y = Math.floor(game.height * 0.875);
  } else {
    this.cameraOffset.y = Math.floor(game.height * 0.1375);
  }
};

G.TreasureHunt.GameplayGemCounter.prototype.onShowReasonChanged = function(hasReasons) {
  if (hasReasons) {
    this.showAnim();
  } else {
    this.hideAnim();
  }
};

G.TreasureHunt.GameplayGemCounter.prototype.showAnim = function() {
  game.add.tween(this.mainGroup)
    .to({x: -30}, 200, Phaser.Easing.Sinusoidal.Out, true);
};

G.TreasureHunt.GameplayGemCounter.prototype.hideAnim = function() {
  game.add.tween(this.mainGroup)
    .to({x: 320}, 200, Phaser.Easing.Sinusoidal.Out, true);
};

G.TreasureHunt.GameplayGemCounter.prototype.show = function(reason) {
  var reasonToShow = G.TreasureHunt.GameplayGemCounter.ReasonToShow;
  if (reason == reasonToShow.OutOfMovesPopUp
    && !this.showReasons.has(reason)) {
    game.add.tween(this.shine)
    .to({alpha: 0.5}, 300, Phaser.Easing.Sinusoidal.Out,true);
  }
  this.showReasons.add(reason, true);
};

G.TreasureHunt.GameplayGemCounter.prototype.hide = function(reason) {
  var reasonToShow = G.TreasureHunt.GameplayGemCounter.ReasonToShow;

  if (reason === reasonToShow.OutOfMovesPopUp) {
    this.showReasons.remove(reason, true);
    if (!this.showReasons.has(reason)) {
      game.add.tween(this.shine)
        .to({alpha: 0}, 300, Phaser.Easing.Sinusoidal.Out, true);
    }
  } else {
    game.time.events.add(2000, function() {
      this.showReasons.remove(reason, true);
    }, this);
  }
};

G.TreasureHunt.GameplayGemCounter.prototype.increaseAmount = function() {
  if (this.amount === this.maxAmount) return;

  this.amount++;
  this.amountTxt.setText(this.amount); 
};

G.TreasureHunt.GameplayGemCounter.prototype.getTargetObject = function() {
  return this.chest;
};