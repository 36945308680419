
const maxForce = 10; // Maximum force a card could have

export default class FriendshipChest_Polaroid extends Phaser.Group {
  /**
   * A friendship chest polaroid. Only works as an image
   * @param {Object} config
   * @param {Game} config.game
   * @param {string} [config.background]
   * @param {string} [config.mainImage]
   * @param {Array<string>} [config.foreground]
   * @param {number} config.index
   */
  constructor(config) {
    super(config.game, null);

    this._config = config;

    this._force = 0;
    this.index = this._config.index;
    this._polaroidFrame = undefined;
    this.prevPolaroid = undefined;
    this.nextPolaroid = undefined;
    this._tintableImages = [];

    this._drawPolaroid();

    this.calculatedBounds = { // Calculated bounds of the polaroid not including anything added on top later
      width: this.width,
      height: this.height,
    };
  }

  /**
   * Draws the main image
   */
  _drawPolaroid() {
    const container = new Phaser.Image(game);
    this.addChild(container);
    const imageContainer = new Phaser.Image(game);
    container.addChild(imageContainer);
    if (this._config.background) {
      const bg = G.makeImage(0, 0, this._config.background, 0.5, imageContainer);
      this._tintableImages.push(bg);
    }

    let mainImage;
    if (this._config.mainImage) {
      mainImage = this._test = G.makeImage(0, 0, this._config.mainImage, 0.5, imageContainer);
      this._tintableImages.push(mainImage);
    }

    if (this._config.foreground) {
      this._config.foreground.forEach((img) => {
        const i = G.makeImage(0, 0, img, 0.5, imageContainer);
        this._tintableImages.push(i);
      });
    }

    const polaroidAlpha = new Phaser.Graphics(game);
    imageContainer.addChild(polaroidAlpha);
    const frame = this._polaroidFrame = G.makeImage(0, 0, 'friendshipPolaroidFrame', 0.5, this);
    const cappedIndex = Math.min(this.index, G.OMTsettings.friendshipChest.assets.storyChests.length); // Capped index to keep showing the extra garnish on the polaroid
    const num = G.makeImage(0, 0, `friendshipNumber${(cappedIndex + 1)}`, 0.5, frame);
    num.y = frame.y + (frame.height / 2) - num.height * 0.90;
    num.x = frame.x + ((frame.width * (cappedIndex % 2 === 0 ? -1 : 1)) / 4); // Even index? Go to the left.

    polaroidAlpha.beginFill(0, 1);
    polaroidAlpha.moveTo(7, 8);
    polaroidAlpha.lineTo(510, 8);
    polaroidAlpha.lineTo(499, 340);
    polaroidAlpha.lineTo(18, 340);
    polaroidAlpha.lineTo(7, 98);
    polaroidAlpha.endFill();
    polaroidAlpha.x = frame.x - frame.width / 2;
    polaroidAlpha.y = frame.y - frame.height / 2;
    if (mainImage) {
      imageContainer.mask = polaroidAlpha;
    }
  }

  /**
   * Tints the frame. 0 for full black, 1 for normal
   * @param {number} ratio
   */
  tintFrame(ratio) {
    const col = 255 * ratio;
    const targetColor = Phaser.Color.createColor(col, col, col, 1, 1, 1, 1, 1).color;
    this._polaroidFrame.tint = targetColor;
  }

  /**
   * Tints the inner image. 0 for full black, 1 for normal
   * @param {number} ratio
   */
  tintInner(ratio) {
    const col = 255 * ratio;
    const targetColor = Phaser.Color.createColor(col, col, col, 1, 1, 1, 1, 1).color;
    this._tintableImages.forEach((child) => {
      if (child.tint !== undefined) {
        child.tint = targetColor;
      }
    });
  }

  /**
   * Applies force
   * @param {number} v
   */
  applyForce(v) {
    this._force += v;
  }

  /**
   * Kills the force, resets the angle
   */
  killForce() {
    this._force = 0;
    this.angle = 0;
  }

  /**
   * Force update here
   */
  update() {
    if (!this.visible) { return; } // eslint-disable-line no-useless-return

    if (Math.abs(this._force) > 0) { // While theres force
      if (Math.abs(this._force) < 0.003) { // If its super tiny, just kill it
        this._force = 0;
      }
      this._force *= 0.78; // How can I make it swing?
      this.angle = maxForce * this._force; // Angle of the polaroid by force
    }
  }
}
