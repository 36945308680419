if (typeof G === 'undefined') G = {};
G.Animations = G.Animations || {};

export default class GetGameplayChestOpenAnimation {
  constructor() {
    this.onComplete = new Phaser.Signal();
  }

  animate(game, target) {
    const base = G.Utils.find1stObjectWithName(target, 'base');
    const cover = G.Utils.find1stObjectWithName(target, 'cover');
    const light = G.Utils.find1stObjectWithName(target, 'light');

    target.alpha = 1;
    game.add.tween(target)
      .to({ y: target.y - 30 }, 1500, Phaser.Easing.Sinusoidal.Out, true);
    game.add.tween(target.scale)
      .to({ x: 1.2, y: 1.2 }, 400, Phaser.Easing.Sinusoidal.Out, true);

    game.add.tween(target)
      .to({ alpha: 0 }, 300, Phaser.Easing.Sinusoidal.Out, true, 1200)
      .onComplete.add(() => {
        this.onComplete.dispatch();
      });

    if (base) {
      base.changeTexture('chest_bottom');
    }

    if (cover) {
      let coverFrameIndex = 0;
      cover.changeTexture('chest_top_00');
      const tween = game.add.tween(cover)
        .to(null, 50, Phaser.Easing.Sinusoidal.In, false, 0, 3);
      tween.onRepeat.add(() => {
        coverFrameIndex++;
        cover.changeTexture(`chest_top_0${coverFrameIndex}`);
      });
      tween.start();
    }

    if (light) {
      light.angle = 0;
      light.alpha = 0;
      game.add.tween(light)
        .to({ angle: -250 }, 1500, Phaser.Easing.Linear.None, true);
      game.add.tween(light)
        .to({ alpha: 0.3 }, 200, Phaser.Easing.Sinusoidal.Out, true)
        .onComplete.add(() => {
          game.add.tween(light)
            .to({ alpha: 0 }, 200, Phaser.Easing.Sinusoidal.Out, true, 1300);
        });
    }
  }
}
