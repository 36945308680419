/* eslint-disable func-names */
/* eslint-disable no-prototype-builtins */
// Refer to phaser-nineslice-softgames.js
G.Utils.NineSlice = {};

G.Utils.NineSlice.fromJSON = function (jsonpath, x, y, width, height) {
  let json;
  if (!G.json.hasOwnProperty(jsonpath)) {
    console.warn('NineSlice warn - JSON does not exist or is not loaded');
    json = {
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    };
  } else if (G.json[jsonpath].type !== 'nineslice') {
    console.warn('NineSlice warn - Type of JSON is not nineslice');
    json = {
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    };
  } else {
    json = G.json[jsonpath].data;
  }

  const splitPath = jsonpath.split('/');
  if (splitPath.length < 3) throw new Error('NineSlice error! Path to JSON is invalid!');

  const spritesheet = splitPath[splitPath.length - 2];
  const frame = splitPath[splitPath.length - 1];

  return new PhaserNineSlice.NineSlice(x, y, spritesheet, frame, width, height, json);
};
