/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-multi-spaces */
/* eslint-disable key-spacing */

import { LevelType } from '@omt-game-board/Managers/GameEnums';
import LevelEconomyTracker from '../../Elements/GameTracking/LevelEconomyTracker';
import DDNARealMoneyWheelTransactionTracker from './DDNARealMoneyWheelTransactionTracker';

/**
* class for helping form complex transaction events
*/
class DDNATransactionHelper {
  /**
  * constructor
  */
  constructor() {
    // booster name reference for indexed boosters
    this._indexedBoosterItemNames = [
      'swap',
      'remover',
      'horizontal',
      'vertical',
      'mapExtraMoves',
      'levelExtraMoves',
      'specialTile',
      'bomb',
      'cross',
    ];

    // booster parameter name references for eventParams
    this._boosterParams = {
      swap :            { received:'tSwapReceived',               used:'tSwapUsed' },
      remover :         { received:'tRemoverReceived',            used:'tRemoverUsed' },
      horizontal :      { received:'tHorizontalReceived',         used:'tHorizontalUsed' },
      vertical :        { received:'tVerticalReceived',           used:'tVerticalUsed' },
      mapExtraMoves :   { received:'tMapExtraMovesReceived',      used:'tMapExtraMovesUsed' },
      levelExtraMoves : { received:'tLevelExtraMovesReceived',    used:'tLevelExtraMovesUsed' },
      specialTile :     { received:'tMapSpecialTileReceived',     used:'tMapSpecialTileUsed' },
      bomb :            { received:'tMapBombReceived',            used:'tMapBombUsed' },
      cross :           { received:'tMapCrossReceived',           used:'tMapCrossUsed' },
      // these boosters are not indexed and not stored in the boosters []
      oneExtraMove :    { received:'tLevelOneExtraMoveReceived',  used:'tLevelOneExtraMoveUsed' },
      twoExtraMoves :   { received:'tLevelTwoExtraMovesReceived', used:'tLevelTwoExtraMovesUsed' },
    };

    // coin sources mapped to keys for getCoinsBySource
    this._coinSources = {
      LevelEnd: '_coinsFromLevelEnd',
      GameplayChest: '_coinsFromChests',
    };

    // create object for tracking level coin rewards to be sent at the end of a level
    this._levelTransactionTracker = new DDNALevelTransactionTracker();

    this._realMoneyWheelTracker = new DDNARealMoneyWheelTransactionTracker();
  }

  /**
  * create a paramObj with all the transaction parameters
  * @returns {Object}
  */
  createParamObj() {
    const paramObj = {};
    paramObj.transactionID = '';
    paramObj.transactionType = '';
    paramObj.tActionType = '';
    paramObj.tGameArea = '';
    paramObj.tGameMode = '';
    paramObj.tCoinsReceived = 0;
    paramObj.tCoinsUsed = 0;
    paramObj.tUnlimitedLivesMinutesAdded = 0;
    paramObj.tLivesAdded = 0;
    paramObj.tFailFlowExtraMoves = 0;
    paramObj.tFailFlowEMBought = 0;
    paramObj.tLevelExtraMoves = false;
    paramObj.tLevelExtraMovesNumber = 0;
    paramObj.tCoinsLevelEnd = 0;
    paramObj.tAdCoins = 0;
    paramObj.tAdBoosters = 0;
    paramObj.tAdValue = 0;
    paramObj.tAdRewardType = '';
    paramObj.tLevelBoosterPurchase = 0;
    paramObj.tLevelEndGift = 0;
    paramObj.tLevelLootChests = 0;
    paramObj.iapBonus = G.saveState.iapMultiplier;

    try {
      const levelActive = game.state.getCurrentState().key === 'Game' && G.lvl;
      if (levelActive) {
        paramObj.tLevelContext = !G.lvl.lvlIndex ? -1 : G.lvl.lvlIndex + 1;
        if (paramObj.tLevelContext >= 10000) paramObj.tLevelContext = -1;
        paramObj.tMovesLeft = G.lvl.moves;
        paramObj.tFailFlowEMBought = G.lvl.numOfExtraMovesBought;
        paramObj.tLevelExtraMovesNumber = G.lvl.numOfExtraMovesBought;   // *** COOK-OMT Duplicate ***
        paramObj.tLevelBoosterPurchase = LevelEconomyTracker.getInstance().getLossAversionCoinsSpent();
      }
    } catch (e) {
      //
    }

    // define booster parameters
    for (let i = 0; i < this._boosterParams.length; i++) {
      paramObj[this._boosterParams[i].received] = 0;
      paramObj[this._boosterParams[i].used] = 0;
    }

    return paramObj;
  }

  /**
  * track single booster purchased and stored in inventory
  * @param {number} boosterNum # id of booster used
  * @param {number} amount amount of booster used
  * @param {number} coinCost cost of booster purchased
  * @param {number} tLevelOverride level override. used to assign a level outside the Game state.
  */
  trackBoosterInventoryPurchase(boosterNum, amount, coinCost, tLevelOverride = -1) {
    const resourceParams = this.createParamObj();
    resourceParams.transactionType = 'COIN_PURCHASE';
    resourceParams.tActionType = 'INVENTORY_PURCHASE';
    resourceParams.tCoinsUsed = coinCost;
    resourceParams.tGameArea = 'LEVEL';
    resourceParams.tGameMode = game.state.getCurrentState().mode || LevelType.NONE;
    resourceParams.tCoinsReceived = 0;
    if (tLevelOverride >= 0) resourceParams.tLevelContext = tLevelOverride;

    const itemsUsed = [];
    const itemsReceived = this.processItemData([`booster#${boosterNum}`, amount]).items;
    this.assignItemParams(resourceParams, itemsUsed, itemsReceived);

    // DDNA.tracking.resourceEvent(resourceParams);
  }

  /**
   * Generates a base resource param object to track later
   * @param {number} coinCost cost of booster purchased
   */
  getBoosterUseResourceParams(coinCost = 0) {
    const resourceParams = this.createParamObj();
    resourceParams.transactionType = coinCost === 0 ? 'INVENTORY' : 'COIN_PURCHASE';
    resourceParams.tActionType = 'BOOSTER_USE';
    resourceParams.tGameArea = 'LEVEL';
    resourceParams.tGameMode = game.state.getCurrentState().mode || LevelType.NONE;
    resourceParams.tCoinsUsed = coinCost;

    return resourceParams;
  }

  /**
  * track single booster use, this can also be a purchase when purchased and immediately used.
  * @param {number} boosterNum # id of booster used
  * @param {number} coinCost cost of booster purchased
  * @param {number} boostersUsed amount of boosters used
  */
  trackBoosterUse(boosterNum, coinCost, boostersUsed = 1) {
    const resourceParams = this.getBoosterUseResourceParams(coinCost);

    const itemsUsed = this.processItemData([`booster#${boosterNum}`, boostersUsed]).items;
    const itemsReceived = coinCost > 0 ? itemsUsed.slice() : [];
    this.assignItemParams(resourceParams, itemsUsed, itemsReceived);

    // DDNA.tracking.resourceEvent(resourceParams);
  }

  /**
   * Tracks multiple boosters at once and sends only one event to DDNA
   * @param {object[]} boosters
   * @param {number} boosters.boosterNum
   * @param {number} boosters.coinCost
   * @param {number} boosters.boosterCount
   */
  trackMultipleBoosterUse(boosters) {
    const resourceParams = this.getBoosterUseResourceParams();
    const itemsUsedArray = [];
    const itemsReceivedArray = [];
    for (const boosterData of boosters) {
      const { boosterNum, coinCost, boosterCount: boostersUsed = 1 } = boosterData;
      const itemsUsed = this.processItemData([`booster#${boosterNum}`, boostersUsed]).items;
      const itemsReceived = coinCost > 0 ? itemsUsed.slice() : [];
      itemsUsedArray.push(...itemsUsed);
      itemsReceived.push(...itemsReceived);
    }
    this.assignItemParams(resourceParams, itemsUsedArray, itemsReceivedArray);

    // DDNA.tracking.resourceEvent(resourceParams);
  }

  /**
  * track rewards / gifts. Rewards can be a single item or an array of items.
  * @param {*} gameItemsReceived single item or Array of items received
  * @param {*} gameItemsUsed single item or Array of items used
  * @param {Object} eventData data to be sent with event
  * @param {Object} purchaseData purchaseData sent if this is a coinshop purchase
  */
  trackRewards(gameItemsReceived, gameItemsUsed, eventData, purchaseData = null) {
    // apply parameters passed in eventData
    const resourceParams = this.createParamObj();
    Object.assign(resourceParams, eventData);
    resourceParams.tGameMode = game.state.getCurrentState().mode || LevelType.NONE;

    const itemsUsed = this.processItemData(gameItemsUsed).items;
    const rewardData = this.processItemData(gameItemsReceived, resourceParams);
    const itemsReceived = rewardData.items;
    resourceParams.tCoinsReceived += rewardData.coins;
    this.assignItemParams(resourceParams, itemsUsed, itemsReceived);

    // get the priority from the eventData Object and delete it once retrieved
    // const priority = eventData.priority ? eventData.priority : 0;
    delete eventData.priority;

    if (purchaseData) { // transaction event is only for real money purchases now
      const transactionParams = {
        transactionType: eventData.transactionType,
        productID: purchaseData.productID,
        simpleProductID: purchaseData.productID.substring(purchaseData.productID.indexOf('_') + 1),
      };
      if (resourceParams.tCoinsUsed > 0) this.defineVirtualCurrencySpent(transactionParams, resourceParams.tCoinsUsed);
      if (resourceParams.tCoinsReceived > 0) this.defineVirtualCurrencyReceived(transactionParams, resourceParams.tCoinsReceived);
      this.defineRealCurrencySpent(transactionParams, purchaseData);
      // DDNA.tracking.transactionEvent(transactionParams, itemsUsed, itemsReceived, priority);
    }

    // DDNA.tracking.resourceEvent(resourceParams, priority);
  }

  /**
  * track a coinshop purchase.
  * @param {*} gameItemsReceived single item or Array of items received
  * @param {Object} eventData data to be sent with event
  * @param {Object} purchaseData purchaseData for coinshop purchase
  */
  trackCoinShopPurchase(gameItemsReceived, eventData, purchaseData) {
    const gameItemsUsed = [];
    this.trackRewards(gameItemsReceived, gameItemsUsed, eventData, purchaseData);
  }

  /**
  * track the state of a coinshop purchase.
  * @param {Object} eventData data to be sent with event
  * @param {Object} purchaseData purchaseData for coinshop purchase
  */
  trackCoinShopState(eventData, purchaseData) {
    // get the priority from the eventData Object and delete it once retrieved
    // const priority = eventData.priority ? eventData.priority : 0;
    delete eventData.priority;

    if (purchaseData) { // transaction state event is only for real money purchases
      // const transactionParams = {
      //   tGameMode: game.state.getCurrentState().mode || LevelType.NONE,
      //   transactionState: eventData.transactionState,
      //   transactionFailReason: eventData.transactionFailReason,
      //   tsProductID: purchaseData.productID,
      //   tsRealCurrencyType: purchaseData.priceCurrencyCode.toUpperCase(),
      //   tsRealCurrencyAmount: DDNA.utils.formatCurrencyValueAsInteger(purchaseData.price) || 0,
      //   tsSimpleProductID: purchaseData.productID.substring(purchaseData.productID.indexOf('_') + 1),
      // };
      // DDNA.tracking.transactionStateEvent(transactionParams, priority);
    }
  }

  /**
  * check if the event data passed has all the required parameters
  * @param {Object} eventData data to be sent with event
  * @returns {boolean}
  */
  isEventDataValid(eventData) {
    if (!eventData || !eventData.tActionType || !eventData.transactionType || !eventData.tGameArea) {
      console.log('ERROR DDNATransactionHelper missing parameters on eventData, eventData.tActionType, eventData.transactionType, eventData.tGameArea');
      return false;
    }
    return true;
  }

  /**
  * define the realCurrency object and apply it to the paramObj
  * @param {Object} paramObj Object to apply real currency spent to
  * @param {Object} purchaseData purchaseData for coinshop purchase
  */
  defineRealCurrencySpent(paramObj, purchaseData) {
    if (!paramObj.productsSpent) paramObj.productsSpent = {};
    paramObj.productsSpent.realCurrency = {
      realCurrencyType: purchaseData.priceCurrencyCode.toUpperCase(),
      realCurrencyAmount: DDNA.utils.formatCurrencyValueAsInteger(purchaseData.price),
    };
  }

  /**
  * define the virutalCurrency spent object and apply it to the paramObj
  * @param {Object} paramObj Object to apply virtual currency spent to
  * @param {number} coins coins spent
  */
  defineVirtualCurrencySpent(paramObj, coins) {
    this.defineVirtualCurrencyObj(paramObj, coins, 'productsSpent');
  }

  /**
  * define the virutalCurrency received object and apply it to the paramObj
  * @param {Object} paramObj Object to apply virtual currency received to
  * @param {number} coins coins spent
  */
  defineVirtualCurrencyReceived(paramObj, coins) {
    this.defineVirtualCurrencyObj(paramObj, coins, 'productsReceived');
  }

  /**
  * define the virutalCurrency object and apply it to the paramObj
  * @param {Object} paramObj Object to apply virtual currency received to
  * @param {number} coins coins spent
  * @param {string} productPhase productsReceived || productsSpent
  */
  defineVirtualCurrencyObj(paramObj, coins, productPhase) {
    if (!paramObj[productPhase]) paramObj[productPhase] = {};
    paramObj[productPhase].virtualCurrencies = [{
      virtualCurrency: {
        virtualCurrencyName: 'COINS',
        virtualCurrencyType: 'GRIND',
        virtualCurrencyAmount: parseInt(coins),
      },
    }];
  }

  /**
  * convert game item/gift data to something DDNA will accept
  * @param {*} gameItems Array or single item to process
  * @returns {Object}
  */
  processItemData(gameItems) {
    if (gameItems.length === 0) return { items:[], coins:0 };
    if (!Array.isArray(gameItems[0])) gameItems = [gameItems];

    let gameItem; let gameItemId; let gameItemAmount;
    const items = []; let coins = 0;

    for (let i = 0; i < gameItems.length; i++) {
      gameItem = gameItems[i]; gameItemId = gameItem[0]; gameItemAmount = parseInt(gameItem[1]);
      if (gameItemId === 'coin') {
        coins += gameItemAmount;
      } else if (gameItemId.indexOf('booster') === 0) {
        items.push({ item: { itemType:'booster', itemName:this.getBoosterItemName(gameItemId), itemAmount: gameItemAmount } });
      } else if (gameItemId === 'lifeUnlimited') {
        items.push({ item: { itemType:'life', itemName:'unlimitedLivesMins', itemAmount: gameItemAmount } });
      } else if (gameItemId === 'life') {
        items.push({ item: { itemType:'life', itemName:'life', itemAmount: gameItemAmount } });
      } else {
        console.log(`ERROR, DDNATransactionHelper.processItemData(): ITEM HAS NO HANDLER : ${gameItem[0]}`);
      }
    }
    return { items, coins };
  }

  /**
  * get the name of the booster for DDNA tracking
  * @param {string} boosterId booster id name
  * @returns {string}
  */
  getBoosterItemName(boosterId) {
    if (boosterId.indexOf('!') >= 0) return boosterId.split('!')[1]; // non-index booster use passed name
    const boosterIndex = parseInt(boosterId.split('#')[1]) - 1;
    return this._indexedBoosterItemNames[boosterIndex];
  }

  /**
  * get the value of a DDNA tracking parameter
  * @param {string} source name of source (either 'LevelEnd' or 'Chests')
  * @returns {*}
  */
  getCoinsBySource(source) {
    const sourceKey = this._coinSources[source];
    return this._levelTransactionTracker[sourceKey];
  }

  /**
  * assign and fill item tracking parameters / values
  * @param {Object} paramObj paramObj to apply parameters to.
  * @param {Array} itemsUsed list of items used
  * @param {Array} itemsReceived list of items received
  */
  assignItemParams(paramObj, itemsUsed, itemsReceived) {
    // create booster tracking params
    let boosterParams;
    const boosterItemNames = Object.keys(this._boosterParams);
    for (const boosterItemName of boosterItemNames) {
      boosterParams = this._boosterParams[boosterItemName];
      if (boosterParams) {
        paramObj[this._boosterParams[boosterItemName].received] = 0;
        paramObj[this._boosterParams[boosterItemName].used] = 0;
      }
    }

    // assign used / received values
    this.assignItemValues(paramObj, itemsUsed, 'used');
    this.assignItemValues(paramObj, itemsReceived, 'received');

    // assign and reset real money wheel event data
    const realMoneyWheelEventData = this._realMoneyWheelTracker.getParamObj();
    Object.assign(paramObj, realMoneyWheelEventData);
    this.resetQueuedRealMoneyWheelEvents();
  }

  /**
  * assign item vaues from the used / receieved list
  * @param {Object} paramObj paramObj to apply parameters to.
  * @param {Array} itemList list of items
  * @param {string} itemState used / received
  */
  assignItemValues(paramObj, itemList, itemState) {
    for (let i = 0; i < itemList.length; i++) {
      const item = itemList[i].item;
      if (item.itemType === 'booster') {
        paramObj[this._boosterParams[item.itemName][itemState]] = item.itemAmount;
      } else if (item.itemName === 'unlimitedLivesMins') {
        paramObj.tUnlimitedLivesMinutesAdded = item.itemAmount;
      } else if (item.itemName === 'life') {
        paramObj.tLivesAdded = item.itemAmount;
      }
    }
  }

  /**
  * queue a level reward / gift for later sending
  */
  queueLevelCoinReward(coins, coinSource) {
    this._levelTransactionTracker.update(coins, coinSource);
  }

  /**
  * reset the queued level rewards
  */
  resetQueuedLevelRewards() {
    this._levelTransactionTracker.reset(this.createParamObj());
  }

  /**
  * send the queued level rewards
  */
  sendQueuedLevelCoinRewards() {
    const eventData = this._levelTransactionTracker.getParamObj();
    if (!eventData || eventData.tCoinsReceived === 0) return;

    this.trackRewards([], [], Object.assign(eventData, {
      tActionType: 'LEVEL_END',
    }));
    this._levelTransactionTracker.clearFromLocalStorage();
    this.resetQueuedLevelRewards();
  }

  /**
  * restore the queued level rewards and send
  */
  restoreQueuedLevelCoinRewards() {
    const dataRestored = this._levelTransactionTracker.restoreFromLocalStorage();
    if (dataRestored) {
      this.sendQueuedLevelCoinRewards();
      // DDNA.tracking.sendQueuedEvents(); // force send this event now
    }
  }

  /**
   * queue real money wheel event occurence
   * @param {RMWHEEL_EPS} wheelType
   * @param {RMWHEEL_EVENT} wheelEvent
   * @param {RMWHEEL_MODE} wheelMode
   * @param {number} amount
   * @param {boolean} increment
   */
  queueRealMoneyWheelEvent(wheelType, wheelEvent, wheelMode, amount, increment = true) {
    this._realMoneyWheelTracker.update(wheelType, wheelEvent, wheelMode, amount, increment);
  }

  /**
  * track wheel reward + real money event params
  * @param {Object} gift formatted gift from wheel
  * @param {boolean} isRealMoneyWheel
  * @param {boolean} isConversion
  * @param {boolean} isPlatinumWheel
  */
  trackWheelReward(gift, isRealMoneyWheel, isConversion, isPlatinumWheel) {
    let actionType;

    if (isRealMoneyWheel) {
      if (isPlatinumWheel) {
        actionType = 'PLATINUM_WHEEL_SPIN';
      } else if (isConversion) {
        actionType = 'CONV_WHEEL_SPIN';
      } else {
        actionType = 'HIGH_VAL_WHEEL_SPIN';
      }
    } else {
      actionType = 'WHEEL_SPIN';
    }

    this.trackRewards(gift, [], {
      transactionType: 'REWARD',
      tActionType: actionType,
      tGameArea: game.state.getCurrentState().key === 'Game' ? 'LEVEL' : 'MAP',
    });
  }

  /**
  * reset the queued real money wheel event params
  */
  resetQueuedRealMoneyWheelEvents() {
    this._realMoneyWheelTracker.reset();
  }

  /**
   * get a reference to the real money wheel tracker
   */
  getRealMoneyWheelTracker() {
    return this._realMoneyWheelTracker;
  }
}

/**
* internal class for capturing level reward data for later/merged sending
*/
class DDNALevelTransactionTracker {
  /**
  * constructor
  */
  constructor() {
    // coin sources mapped to keys for getCoinsBySource
    this._coinSources = {
      LevelEnd: '_coinsFromLevelEnd',
      GameplayChest: '_coinsFromChests',
    };

    this._lsKey = 'DDNA-levelCoinTracker';
    this._paramObj = null;
    this._coins = 0;
    this._coinsFromChests = 0;
    this._coinsFromLevelEnd = 0;
  }

  /**
  * reset level tracking params
  * @param {Object} paramObj paramObj to apply changes to
  */
  reset(paramObj) {
    this._paramObj = paramObj;
    this._paramObj.transactionType = 'REWARD';
    this._paramObj.tActionType = 'REWARD';
    this._paramObj.tGameArea = 'LEVEL';
    this._paramObj.tmCoinsLevelEnd = 0;
    this._paramObj.tmCoinsChests = 0;
    this._paramObj.tCoinsReceived = 0;
    this._paramObj.tLevelExtraMoves = false;
    this._paramObj.tLevelExtraMovesNumber = 0;
    this._paramObj.tCoinsLevelEnd = 0;
    this._paramObj.tAdCoins = 0;
    this._paramObj.tAdBoosters = 0;
    this._paramObj.tAdValue = 0;
    this._paramObj.tAdRewardType = '';
    this._paramObj.tLevelBoosterPurchase = 0;
    this._paramObj.tLevelEndGift = 0;
    this._paramObj.tLevelLootChests = 0;

    this._coins = 0;
    this._coinsFromChests = 0;
    this._coinsFromLevelEnd = 0;
  }

  /**
  * get a reference to the transaction paramObj
  * @returns {Object}
  */
  getParamObj() {
    return this._paramObj;
  }

  /**
  * update queued data
  * @param {number} coins count amount to add
  * @param {string} coinSource source id of coins being added GameplayChest || LevelEnd
  */
  update(coins, coinSource) {
    // this shouldnt happen but check for invalid coin submission
    // eslint-disable-next-line no-restricted-globals
    if (coins == null || isNaN(coins)) {
      console.log(`!!ERROR INVALID COINS = ${coins}`);
      return;
    }

    // update coin coints
    this._coins += coins;
    const coinSourceKey = this._coinSources[coinSource];
    this[coinSourceKey] += coins;

    // update transaction param values
    this._paramObj.tCoinsReceived = this._coinsFromChests + this._coinsFromLevelEnd;
    this._paramObj.tmCoinsChests = this._coinsFromChests;
    this._paramObj.tLevelLootChests = this._paramObj.tmCoinsChests; // *** COOK-OMT Duplicate ***
    this._paramObj.tmCoinsLevelEnd = this._coinsFromLevelEnd;
    this._paramObj.tCoinsLevelEnd = this._coinsFromLevelEnd; // *** COOK-OMT Duplicate ***

    // keep track of fail flow extra moves
    this._paramObj.tFailFlowEMBought = G.lvl.numOfExtraMovesBought;
    this._paramObj.tLevelExtraMovesNumber = this._paramObj.tFailFlowEMBought; // *** COOK-OMT Duplicate ***
    this._paramObj.tLevelBoosterPurchase = LevelEconomyTracker.getInstance().getLossAversionCoinsSpent();

    this.writeToLocalStorage();
  }

  /**
  * write to local storage for restoration
  */
  writeToLocalStorage() {
    try {
      localStorage.setItem(this._lsKey, JSON.stringify(this._paramObj));
    } catch (e) { console.log(`Error could not write key '${this._lsKey}' to local storage`); }
  }

  /**
  * restore from local storage
  * @returns {boolean}
  */
  restoreFromLocalStorage() {
    try {
      const data = localStorage.getItem(this._lsKey);
      if (!data) return false;
      this._paramObj = JSON.parse(data);
      return true;
    } catch (e) {
      //
    }
    return false;
  }

  /**
  * clear from local storage
  */
  clearFromLocalStorage() {
    try {
      localStorage.removeItem(this._lsKey);
    } catch (e) { console.log(`Error could not clear key '${this._lsKey}' from local storage`); }
  }
}

// create global references
if (!window.DDNA) window.DDNA = {};
DDNA.transactionHelper = new DDNATransactionHelper();
