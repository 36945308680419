export const CHARACTER_KEYS = {
  fancyGingy: 'fancyGingy',
  gingy: 'gingy',
  ginger: 'ginger',
  nutmeg: 'nutmeg',
  graham: 'graham',
  chip: 'chip',
  cookie: 'cookie',
  treasureHuntgingy: 'treasureHuntgingy',
  treasureHuntginger: 'treasureHuntginger',
  treasureHuntnutmeg: 'treasureHuntnutmeg',
  treasureHuntgraham: 'treasureHuntgraham',
  treasureHuntchip: 'treasureHuntchip',
  treasureHuntcookie: 'treasureHuntcookie',
};

export default class GingyCharacterTutorial extends Phaser.Group {
  /**
   *
   * @param {number} x
   * @param {number} y
   * @param {CHARACTER_KEYS} charKey
   */
  constructor(x, y, charKey) {
    super(game, null);
    this.position.set(x, y);

    if (!charKey) {
      charKey = CHARACTER_KEYS.gingy;
    }
    this.charAssets = G.OMTsettings.elements.characterAssets[charKey];
    this.charConfig = G.OMTsettings.elements[charKey];

    this.gingy = new Phaser.Group(game, this);
    this.backEyes = G.makeImage(this.charConfig.backEyes.x, this.charConfig.backEyes.y, this.charAssets.backEyes, 0.5, this.gingy);

    this.eyes = G.makeImage(this.charConfig.eyes.x, this.charConfig.eyes.y, this.charAssets.eyes, 0.5, this.gingy);
    if (this.charAssets.eyes === 'none') this.eyes.alpha = 0;
    this.eyesDefaultPos = this.charConfig.eyes;

    this.body = G.makeImage(0, 0, this.charAssets.body, 0.5, this.gingy);

    this.eyelids = G.makeImage(this.charConfig.eyelids.x, this.charConfig.eyelids.y, this.charAssets.eyelids, 0.5, this.gingy);
    if (this.charAssets.eyelids === 'none') this.eyelids.alpha = 0;
    this.eyelids.visible = false;

    this.mouth = G.makeImage(this.charConfig.mouth.x, this.charConfig.mouth.y, this.charAssets.mouth_closed, 0.5, this.gingy);

    this.gingy.y = (((this.charAssets.yBottom || 0) - this.body.height) / 2) - this.body.height / 4;

    this.breathingTween = game.add.tween(this.gingy.scale)
      .to({ y: 1.01 }, 1200, Phaser.Easing.Sinusoidal.InOut, true, 0, -1, true);

    this.lookAtTween = null;

    this.blinkingTween = game.add.tween(this)
      .to(null, 2500, Phaser.Easing.Linear.None, true, 0, -1);
    this.blinkingTween.onUpdateCallback((tween, progress) => {
      this.eyelids.visible = (progress > 0.75 && progress < 0.8) || (progress > 0.95);
    }, this);

    this.gingyTalkingTween = game.add.tween(this)
      .to(null, 150, Phaser.Easing.Linear.None, true, 0, -1);
    this.gingyTalkingTween.onLoop.add(() => {
      if (this.mouth.cache === null) return;
      // this syntax does a shallow copy of this.charAssets.mouths
      const textures = [...this.charAssets.mouths];
      textures.splice(textures.indexOf(this.mouth.frameName), 1);
      // console.log(textures.splice(textures.indexOf(this.mouth.frameName), 1));

      this.mouth.changeTexture(game.rnd.pick(textures));
    });

    this.idle();
  }

  destroy() {
    this.blinkingTween.stop();
    this.gingyTalkingTween.stop();
    this.gingyTalkingTween.stop();
    super.destroy();
  }

  talk() {
    this.blinkingTween.resume();
    this.gingyTalkingTween.resume();
  }

  idle() {
    this.blinkingTween.resume();
    this.gingyTalkingTween.pause();
    this.mouth.changeTexture(this.charAssets.mouth_closed);
  }

  smile() {
    this.blinkingTween.pause();
    this.eyelids.visible = true;
    this.gingyTalkingTween.pause();
    this.mouth.changeTexture(this.charAssets.mouth_closed);
  }

  lookAt(obj, instant) {
    if (!obj) return;
    const normX = obj.x;
    const normY = obj.y;

    if (this.lookAtTween) this.lookAtTween.stop();
    if (!instant) {
      this.lookAtTween = game.add.tween(this.eyes.pivot)
        .to({ x: normX * -7, y: normY * -7 }, 500, Phaser.Easing.Sinusoidal.Out, true);
    } else {
      this.eyes.pivot.setTo(normX * -7, normY * -7);
    }
  }

  surprised() {
    this.blinkingTween.pause();
    this.eyelids.visible = false;
    this.gingyTalkingTween.pause();
    this.mouth.changeTexture(this.charAssets.mouths[2]);
  }
}
