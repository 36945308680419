import LossAversionWheelGroup from '../../IAP/LossAversionWheelGroup';
import WindowWithViewSwitching from '../../../00_IMMEDIATE/WindowWithViewSwitching';
import OOMFailView from './views/OOMFailView';
import LevelEconomyTracker from '../../GameTracking/LevelEconomyTracker';
import OOMFailViewWithSpinLimits from './views/OOMFailViewWithSpinLimits';
import TargetedOfferDataManager, { TARGETED_OFFER_IDS } from '../../../Services/OMT/dataTracking/targetedOffer/TargetedOfferDataManager';
import { LevelType } from '@omt-game-board/Managers/GameEnums';
import OMT_VILLAINS from '../../../OMT_UI/OMT_Villains';
import { UI_NineSlice } from '@omt-components/UI/Drawing/UI_NineSlice';
import VillainsSpeech from '../../../OMT_UI/Villains/VillainSpeech';
import LvlDataManager from '@omt-game-board/Managers/LvlDataManager';

export const VIEW_IDS = {
  FAIL: 'failView',
  WHEEL: 'lossAversionWheelView',
};

export class Window_OutOfMovesIAP extends WindowWithViewSwitching {
  /**
   * Shows when the player runs out of moves in a level.
   * @param {Object} parent
   * @param {Object} popUpConfig
   * @param {Function} levelFailedCallback (optional) function to call instead of 'levelFailed'
   */
  constructor(parent, popUpConfig, levelFailedCallback) {
    super(parent);

    this._elementLayouts = G.OMTsettings.elements.Window_OutOfMovesIAP.elementLayouts;

    this._signalBindings = [];

    this._popUpConfig = popUpConfig || {};
    if (popUpConfig.position) this.position.copyFrom(popUpConfig.position);
    this._levelFailedCallback = levelFailedCallback;

    this._outOfMovesResolved = false;
    this._price = 0;

    this._init();
  }

  /**
   * main intialization method
   */
  _init() {
    OMT_VILLAINS.setLevelType(this.state.mode);

    this._initBackground();
    this._initCoinBar();
    this._initViews();

    this.addCloseButton();

    this._addVillains();

    this._onResize();
    this._signalBindings.push(G.sb('onScreenResize').add(this._onResize, this));
  }

  /**
   * init the background graphics
   */
  _initBackground() {
    const targetHeight = 700;
    let offsetY = 20;
    if (G.OMTsettings.elements.Window_OutOfMoves && G.OMTsettings.elements.Window_OutOfMoves.offset) {
      offsetY = G.OMTsettings.elements.Window_OutOfMoves.offset.y || 0;
    }
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const isDailyChallenge = LvlDataManager.getInstance().isDailyChallengeLevel;
    const useSuperHardGraphics = isSuperHardLevel || isDailyChallenge;

    if (isNotNormalLevel) {
      const targetWidth = (useSuperHardGraphics
        ? G.OMTsettings.elements.superhard.window_levelFailed.superHardWidth
        : G.OMTsettings.elements.superhard.window_levelFailed.hardWidth) * 0.4;
      const popupHeight = 1750 * 0.4;
      const targetImage = useSuperHardGraphics ? OMT_VILLAINS.getPrefixedName('super_hard_popup') : OMT_VILLAINS.getPrefixedName('hard_popup');
      const targetSlice = useSuperHardGraphics ? G.OMTsettings.elements.superhard.window_level.super_hard_popup : G.OMTsettings.elements.superhard.window_level.hard_popup;
      this.bg = new UI_NineSlice(0, 0, targetImage, targetWidth, popupHeight, targetSlice);
    } else {
      this.bg = this.addGeneric9SliceBackground(582, targetHeight - offsetY);
      this.bg.y = offsetY;
    }
    if (isNotNormalLevel || isDailyChallenge) {
      this.bg.anchor.setTo(0.5);
      this.add(this.bg);
    }
  }

  /**
   * create the coinbar element
   */
  _initCoinBar() {
    // coin bar to show the player how many coins they have
    this._coinBar = new G.CoinBar(0, -420);
    // cant add coins while wheel is spinning or we're already in the catalog
    this._coinBar.onPlusBtnClicked.add(() => {
      this._goToShopCoin('outOfMovesIAP');
    });

    this._coinBar.plusBtn.visible = G.IAP;
    this.add(this._coinBar);
  }

  /**
   * init views / panels
   */
  _initViews() {
    this._initLossAversionWheelView();
    this._initFailView();

    this._showView(VIEW_IDS.FAIL);
  }

  /**
   * init the fail view
   * @param {OOMFailView} failViewInstance (optional) pass alternative instance. Should extend OMTFailView.
   */
  _initFailView(failViewInstance = undefined) {
    const wheelIsLimited = OMT.feature.lossAversionWheelIsLimited();
    const { additionalBoosterData } = this._popUpConfig;

    let failView;
    if (failViewInstance) failView = failViewInstance;
    else failView = !wheelIsLimited ? new OOMFailView(additionalBoosterData) : new OOMFailViewWithSpinLimits(additionalBoosterData);

    failView.signals.onSpinSuccess.add(() => {
      LevelEconomyTracker.getInstance().onWheelSpin();
      this._showView(VIEW_IDS.WHEEL);
    });
    failView.signals.onResolveOutOfMoves.add(() => {
      this._resolveOutOfMoves(true);
    });
    failView.signals.onGoToCoinShop.add(() => {
      this._goToShopCoin('outOfMovesIAP');
    });
    this._addView(VIEW_IDS.FAIL, failView);
  }

  /**
   * init the loss averasion wheel
   */
  _initLossAversionWheelView() {
    const featureConfig = { failFlow: true, coinTargetObject: this._coinBar };
    const wheel = this._makeLossAversionWheelGroup(featureConfig);
    // if the player gets the booster, then they have more moves and we can resolve this window
    wheel.onBoosterWon.add((prize) => { this._resolveOutOfMoves(false, prize); });
    // if not, then we just go back to the fail view
    wheel.onCoinsWon.add(() => {
      if (OMT.feature.lossAversionWheelIsLimited()) {
        this._getViewById(VIEW_IDS.FAIL).updateCooldownTimerVisibility();
      }
      this._showView(VIEW_IDS.FAIL);
    });
    // don't allow exit to shop during a spin
    this._coinBar.plusBtn.addTerm(() => !wheel.isDuringSpin());
    // add wheel view to view list
    this._addView(VIEW_IDS.WHEEL, wheel);
  }

  /**
   * Makes the class that is the loss aversion wheel group
   * @param {{failFlow: boolean, coinTargetObject:Phaser.DisplayObject}} featureConfig
   * @returns {LossAversionWheelGroup}
   */
  _makeLossAversionWheelGroup(featureConfig) {
    return new LossAversionWheelGroup(featureConfig);
  }

  /**
   * Handles the case where the player earns more moves
   * @param {boolean} force5moves (optional) default false
   * @param {Object} prize prize / reward data
   */
  _resolveOutOfMoves(force5moves = false, prize) {
    this._outOfMovesResolved = true;
    const oomBoosters = [];
    const { GiftContentType } = G.gift;

    if (prize === GiftContentType.StartBoosterDefaultMovesInstant || prize === GiftContentType.StartBoosterSecondaryMovesInstant || !prize) {
      // essentially, we should always give three moves unless the buy extra moves button was clicked (it uses force5Moves)
      if ((!G.IAP && !G.featureUnlock.multipleExtraMovesFailFlow) || force5moves) {
        oomBoosters.push(GiftContentType.StartBoosterDefaultMovesInstant);
      } else {
        oomBoosters.push(GiftContentType.StartBoosterSecondaryMovesInstant);
      }
    } else if (prize === GiftContentType.StartBooster1MoveInstant || prize === GiftContentType.StartBooster2MovesInstant) { // prize was one or two moves
      oomBoosters.push(prize);
    }

    if (this.state.board) this.state.board.actionManager.newAction('oomBoosterInit', oomBoosters);
    if (this._popUpConfig.onResolveCallback) this._popUpConfig.onResolveCallback();
    this.closeWindow();
  }

  /**
   * sends the player to the shop, if they want to buy more coins.
   * @param {string} returnWindowName the name of the window to return to after closing the shop
   * @returns {boolean} success
   */
  _goToShopCoin(returnWindowName = 'outOfMovesIAP') {
    const isWheelSpinning = this._getViewById(VIEW_IDS.WHEEL).isDuringSpin();
    if (isWheelSpinning) return false;

    // get the additional booster setting from the FAIL vew if set
    this._popUpConfig.additionalBoosterData = this._getViewById(VIEW_IDS.FAIL).additionalBoosterData;

    G.sb('pushWindow').dispatch(['coinShop']);
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.OpenShop);
    OMT.platformTracking.logEvent(OMT.platformTracking.Events.OpenShopMoves);
    G.sb('pushWindow').dispatch([returnWindowName, this._popUpConfig]);
    this.closeWindow(false);
    return true;
  }

  /**
   * add the close button to the window with custom actions
   * @param {number} x (optional)
   * @param {number} y (optional)
   * @param {string} asset (optional)
   */
  addCloseButton(x = 252, y = -265, asset = undefined) {
    const onCloseClicked = () => {
      // show fail view if closing from wheel view
      if (this._currentView === this._getViewById(VIEW_IDS.WHEEL)) {
        this._showView(VIEW_IDS.FAIL);
      } else { // just close the window otherwise
        this.closeWindow();
      }
    };
    // create button instance
    super.addCloseButton(x, y, undefined, undefined, onCloseClicked, asset);
    const { isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    if (isNotNormalLevel || this.state.mode === LevelType.CHALLENGE) {
      this.closeButton.y -= 20;
      this.closeButton.x -= 30;
    }
    // don't allow closing during spins
    this.closeButton.addTerm(() => {
      const isWheelSpinning = this._getViewById(VIEW_IDS.WHEEL).isDuringSpin();
      return !isWheelSpinning;
    });
  }

  /**
   * Check whether we can show the targeted offer
   */
  _checkTargetedOffer() {
    const targetedOfferDataManager = TargetedOfferDataManager.getInstance();
    if (G.lvl.lvlIndex === G.saveState.data.failedCurrentLevel.level) {
      G.saveState.save();
      targetedOfferDataManager.showPopupOfferIfPossible([TARGETED_OFFER_IDS.NON_PAYER_FAIL, TARGETED_OFFER_IDS.PAYER_LAST_X_DAYS], {});
    }
  }

  _addVillains() {
    if (!OMT.feature.isVillainsEnabled()) return;
    const { isSuperHardLevel, isNotNormalLevel } = OMT_VILLAINS.getDifficulty();
    const isChallenge = this.state.mode === LevelType.CHALLENGE;
    if (isNotNormalLevel || isChallenge) {
      let villainText = G.OMTsettings.elements.superhard.villain_speech.hard_oom;
      let villainPos = 'right';
      let villainIndex = 1;
      let villainSubIndex = 4;

      if (G.MYSTERYGIFT && G.saveState.mysteryGiftManager.isModeReady()
      && G.saveState.mysteryGiftManager.getCurrentStreak() > 0) {
        villainText = G.OMTsettings.elements.superhard.villain_speech.hard_mystery_gift;
        villainPos = 'left';
      }

      if (isSuperHardLevel || isChallenge) {
        if (isChallenge) {
          const rng = Math.round(Math.random() * 1000) % 3;
          villainText = G.OMTsettings.elements.superhard.villain_speech[`dailyChallengeOOM${rng}`];
        } else {
          villainText = G.OMTsettings.elements.superhard.villain_speech.super_hard_oom;
        }
        villainPos = 'double';
        villainIndex = [2, 1];
        villainSubIndex = [4, 4];

        if (G.MYSTERYGIFT && G.saveState.mysteryGiftManager.isModeReady()
        && G.saveState.mysteryGiftManager.getCurrentStreak() > 0) {
          villainText = G.OMTsettings.elements.superhard.villain_speech.super_hard_mystery_gift;
        }
      }

      this.villains = new VillainsSpeech(this, villainText, villainPos, villainIndex, villainSubIndex, undefined, 'bottom');
      const villainsClassContainer = this.villains.getClassContainer();

      // Reset villains scale in landscape
      if (this._isLandscape) {
        villainsClassContainer.scale.setTo(1);
      }

      this.onFinishedEnter.addOnce(() => {
        const yScale = this._isLandscape ? 0.4 : 0.6;

        villainsClassContainer.y = this.toLocal({
          x: 0,
          y: game.height - this.villains.getTallestObject({ type: 'villain' }).height * yScale,
        }).y;
        this.villains.show();
      });
    }
  }

  /**
   * Handles when the outOfMoves window is closed.
   *
   * @memberof Window_OutOfMovesIAP
   */
  closeWindow(doClosingProcess = true) {
    if (doClosingProcess) {
      if (this._popUpConfig.closeCallback) {
        this._popUpConfig.closeCallback();
      }

      if (!this._outOfMovesResolved) {
        if (G.featureUnlock.targetedOffers) this._checkTargetedOffer();

        // execute level failed
        if (this._levelFailedCallback != null) {
          this._levelFailedCallback();
        } else {
          let windowName = '';
          switch (this.state.mode) {
            case LevelType.COLLECT_EVENT: windowName = 'eventLevelFailed'; break;
            case LevelType.TREASURE_HUNT: windowName = 'treasureHuntFailed'; break;
            default: windowName = 'levelFailed'; break;
          }
          G.sb('pushWindow').dispatch(windowName);
        }
      }
    }
    super.closeWindow();
  }

  /**
   * handle resize event
   */
  _onResize() {
    const { windowHeight, yPos } = this._elementLayouts;
    const lerpFactor = Math.min(Math.max((game.height - windowHeight.min) / (windowHeight.max - windowHeight.min), 0), 1);
    this._coinBar.y = G.lerp(yPos.coinBar.min, yPos.coinBar.max, lerpFactor);
  }

  /**
   * destruction method
   */
  destroy() {
    for (const signal of this._signalBindings) if (signal) signal.detach();
    this._signalBindings.length = 0;
    super.destroy();
  }
}

// create global references
if (!window.Windows) window.Windows = {};
Windows.outOfMovesIAP = Window_OutOfMovesIAP;
