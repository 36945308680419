/* eslint-disable object-shorthand */
/* eslint-disable func-names */

import LvLGoalMgr from './LvlGoalMgr';
import { LevelType } from './GameEnums';

let lastCreatedInstance = null;

/**
 * A manager of the level goals / configurations
 */
export default class LvlDataManager {
  /**
   * get the last created LvlDataManager instance if it exists.
   * @returns {LvlDataManager}
   */
  static getInstance() { return lastCreatedInstance; }

  /**
   * constructor
   * @param {BoardGameHooks} gameHooks class to set game hooks outside of the scope of board functionality
   * @param {Object} settings object for external configurations
   */
  constructor(gameHooks, settings) {
    // config with new way of counting price
    this._gameHooks = gameHooks;
    this._settings = _.cloneDeep(settings);
    this._lvlData = this._settings.lvlData;
    this._lvlIndex = this._settings.lvlIndex; // level Index
    this._latestLevel = this._lvlIndex === gameHooks.lastPassedLevelNum; // Is this our highest unpassed level? For Mystery gift
    this._gameMode = settings.gameMode;
    this._startBoosters = settings.startBoosters;
    this._tutorial = null;
    this._stars = 0;
    this._combo = 0;
    this._goalAchieved = false;
    this._moves = this._lvlData.moves;
    this._points = 0;
    this._movesMade = 0;
    this._signalBindings = [];
    this._activateMovesHelperOverride = false; // A cheat flag to bring up the helpers 2.0
    this._goal = this._lvlData.goal;
    this._goalManager = new LvLGoalMgr(this._lvlData.goal, this.gameplayGoals); // This too
    this._numOfExtraMovesBought = 0; // Previously known as extraMovesBoughtNr
    this._matchNumber = 0;
    this._moneyGained = 0;

    this._addExternalBindings();

    // assign this instance to be easily fetched elsewhere
    lastCreatedInstance = this;
  }

  /**
   * add external event bindings.
   */
  _addExternalBindings() {
    this._signalBindings.push(this._goalManager.onGoalAchieved.add(() => {
      this._goalAchieved = true;
      G.sb('GameExtraMovesAmoount').dispatch(this.moves);
      G.sb('onGoalAchieved').dispatch();
    }));

    this._signalBindings.push(G.sb('onLevelMoneyGain').add((change) => {
      this._moneyGained += change;
    }));
  }

  /**
   * get price of extra moves for loss aversion flow.
   * @returns {number}
   */
  getPriceOfExtraMovesForBubble() { return this.getPriceOfExtraMoves(); }

  /**
   * get price of extra moves. Not sure if this shoud be different then getPriceOfExtraMovesForBubble?
   * @returns {number}
   */
  getPriceOfExtraMoves() {
    const gameHooks = this._gameHooks;
    let priceMultiplier = 1;

    const {
      extraMovesStartPrice,
      extraMovesIncreaser,
      wheelIsLimited,
      wheelLimits,
    } = this._settings.lossAversion;

    if (wheelIsLimited) {
      // Apply discount if spins were all used up and is the first purchase of the level
      // This discount is only applied once when going from 1 spin to 0 spins
      const maxWheelSpins = wheelLimits.numSpins;
      if (gameHooks.canShowLossAversionDiscount(maxWheelSpins) && this._numOfExtraMovesBought === 0) {
        const discount = this.getExtraMovesDiscount();
        priceMultiplier = 1 - (discount / 100);
      }
    }

    return (extraMovesStartPrice + (this._numOfExtraMovesBought * extraMovesIncreaser)) * priceMultiplier;
  }

  /**
   * Get the discount for extra moves when out of loss aversion spins
   */
  getExtraMovesDiscount() {
    const { extraMovesNoSpinsDiscount } = this._settings.lossAversion;
    return extraMovesNoSpinsDiscount;
  }

  /**
   * Increments the number of extra moves bought
   * @param {number} price
   */
  markExtraMovesPurchase(price) {
    if (window.LevelEconomyTracker) window.LevelEconomyTracker.getInstance().onExtraMovesBought(price, this._moves);
    this._numOfExtraMovesBought++;
  }

  /**
   * Buys extra moves, reduces amount of coins
   */
  buyExtraMoves() {
    const price = this.getPriceOfExtraMoves();
    this._gameHooks.changeCoins(-price);
    this._numOfExtraMovesBought++;
    this.changeMoveNumber(5);
    G.sb('onExtraMovesUsed').dispatch();
  }

  /**
   * Is goal achieved?
   * @returns {boolean}
   */
  isGoalAchieved() {
    return this._goalAchieved;
  }

  /**
   * Things updating when moves are made
   */
  madeMove() {
    if (!G.IMMEDIATE) {
      this.changeMoveNumber(-1);
    }
    if (!this._goalAchieved) {
      this._movesMade++;
      G.sb('userMadeMove').dispatch();
    }
    G.sb('madeMove').dispatch();
  }

  /**
   * Number of moves are updated
   * @param {number} change
   */
  changeMoveNumber(change) {
    this._moves += change;
    G.sb('changeMoveNumber').dispatch();
  }

  /**
   * Number of points are updated
   * @param {number} change
   */
  changePointsNumber(change) {
    this._points += change;
    G.sb('onPointsAdded').dispatch(change);
    G.sb('onPointsChange').dispatch(this._points);
  }

  /**
   * Combo count increases
   */
  increaseCombo() {
    this._combo++;
    G.sb('onComboIncrease').dispatch(this._combo);
  }

  /**
   * Combo breaker
   */
  endCombo() {
    this._combo = 0;
    G.sb('onComboBreak').dispatch();
  }

  /**
   * Match happens, points are flying, some tracking happens
   * @param {Board} board
   * @param {number} amount
   * @param {number} meanX
   * @param {number} meanY
   * @param {number} color
   */
  processMatch(board, amount, meanX, meanY, color) {
    const pointsToAdd = amount * (10 + this._calculateComboBonus());
    this.changePointsNumber(pointsToAdd);
    const pxOut = board.cellToPxOut([meanX, meanY]);
    G.sb('displayPoints').dispatch(pxOut[0], pxOut[1], pointsToAdd, color);

    this._matchNumber++;

    if (this.isTournamentLevel || (this._lvlIndex === 0 && this._tutorial && !this._tutorial.skipped)) {
      switch (this._matchNumber) {
        case 1:
          this._gameHooks.logFTUXEvent(4, 'firstMatch', 'FTUFirstMatch');
          break;
        case 2:
          // no longer tracking this event
          break;
        case 3:
          // no longer tracking this event
          break;
        default: break;
      }
    }
  }

  /**
   * The bonus points you get for having a combo
   * @returns {number}
   */
  _calculateComboBonus() {
    const { comboBonus } = this._settings.scoring;
    return comboBonus[Math.min(this._combo, comboBonus.length - 1)];
  }

  /**
   * Is this type a goal?
   * @param {string} type
   * @returns {boolean}
   */
  isGoalType(type) { return this._goalManager.isGoal(type); }

  /**
   * get drop chance for special candies fortune cookie, event Tokens.
   * will be 0 if id is not set.
   * @param {string} eventId
   * @returns {number}
   */
  getSpecialCandyDropChance(eventId) {
    const { dropChances } = this._settings;
    return dropChances[eventId] || 0;
  }

  /**
   * clear entries in the startBooster Object
   */
  clearStartBoosters() {
    for (const key of Object.keys(this._startBoosters)) {
      delete this._startBoosters[key];
    }
  }

  /**
   * get layout config Object from settings
   * @param {string} layoutId id / key of layout from settings
   * @returns {Object}
   */
  getLayoutConfig(layoutId) {
    return this._settings.layouts[layoutId];
  }

  /** GETTER / SETTER  METHODS ********************************* */

  /** @returns {Object} get parsed raw level data */
  get data() { return this._lvlData; }

  /** @returns {BoardGameHooks} get gameHooks instance */
  get gameHooks() { return this._gameHooks; }

  /** @returns {boolean} check if this is the editor */
  get isEditor() { return false; }

  /** @returns {number} */
  get lvlIndex() { return this._lvlIndex; }

  /** @returns {string} get game mode string. */
  get gameMode() { return this._gameMode; }

  /** @returns {Object} get user settings object. */
  get user() { return this._settings.user; }

  /** @returns {Object} get the start boosters object. */
  get startBoosters() { return this._startBoosters || {}; }

  /** @returns {Object} Object from settings defining gameplay goal. */
  get gameplayGoals() { return this._settings.gameplayGoals; }

  /** @returns {number} gets the point tallied. */
  get points() { return this._points; }

  /** @param {number} newVal sets the point tallied. */
  set points(newVal) { this._points = newVal; }

  /** @returns {number}  gets the number of moves left. */
  get moves() { return this._moves; }

  /** @param {number} newVal sets the number of moves left. */
  set moves(newVal) { this._moves = newVal; }

  /** @param {number} newVal sets the number of stars. Max of 3. */
  set stars(newVal) { this._stars = newVal; }

  /** @returns {number} get stars earned */
  get stars() { return this._stars; }

  /** @returns {Array.<number>} get list of star requirements */
  get starsReq() { return this._lvlData.starsReq; }

  /** @returns {number} get combo length. */
  get combo() { return this._combo; }

  /** @returns {number} get points for remaining moves at leel end. */
  get pointsForMovesLeft() { return this._settings.scoring.pointsForMovesLeft; }

  /** @returns {number} get coin-chest drop modifier. */
  get coinChanceProb() { return this._settings.dropModifiers.chests || 1; }

  /** @returns {LvLGoalMgr} get the goal manager instance. */
  get goalManager() { return this._goalManager; }

  /** @returns {Array} get the goal list. */
  get goal() { return this._lvlData.goal; }

  /** @returns {boolean} get if level goal was acheived. */
  get goalAchieved() { return this._goalAchieved; }

  /** @returns {string} get level goal type. */
  get goalType() { return this._lvlData.goal[0]; }

  /** @returns {Array.<Object>} get list of level goals. */
  get collectGoals() { return this._lvlData.goal[1]; }

  /** @returns {number} get points for goal. if goal is points. */
  get goalPoints() { return parseInt(this._lvlData.goal[1]); }

  /** @param {TutorialNewGingy} tutorial set the active tutorial reference. */
  set activeTutorial(tutorial) { this._tutorial = tutorial; }

  /** @returns {number} get moves made this level. */
  get movesMade() { return this._movesMade; }

  /** @returns {number} get the amount of money gained for the level. */
  get moneyGained() { return this._moneyGained; }

  /** @param {number} newValSets set the amount of money gained for the level. */
  set moneyGained(newVal) { this._moneyGained = newVal; }

  /** @returns {boolean} is this the last level the player has access to. */
  get latestLevel() { return this._latestLevel; }

  /** @returns {number} number of extra moves purchased this level. */
  get numOfExtraMovesBought() { return this._numOfExtraMovesBought; }

  /** @returns {boolean} true if this is a normal type level. */
  get isNormalLevel() { return this.gameMode === LevelType.NORMAL; }

  /** @returns {boolean} true if this is a tournament level. */
  get isTournamentLevel() { return this.gameMode === LevelType.TOURNAMENT; }

  /** @returns {boolean} true if this is a token collection event level. */
  get isTokenEventLevel() { return this.gameMode === LevelType.COLLECT_EVENT; }

  /** @returns {boolean} true if this is the daily challenge. */
  get isDailyChallengeLevel() { return this.gameMode === LevelType.CHALLENGE; }

  /** @returns {boolean} true if this is the treasure hunt */
  get isTreasureHuntLevel() { return this._gameMode === LevelType.TREASURE_HUNT; }

  /** @returns {boolean} true if this level is flagged as a hard level. */
  get isHardLevel() { return this._lvlData.showAsHard === true; }

  /** @returns {boolean} cheat flag to bring up the helpers 2.0 */
  get activateMovesHelperOverride() { return this._activateMovesHelperOverride; }

  /** @param {boolean} value cheat flag to bring up the helpers 2.0 */
  set activateMovesHelperOverride(value) { this._activateMovesHelperOverride = value; }

  /**
   * destruction method
   */
  destroy() {
    if (lastCreatedInstance === this) lastCreatedInstance = null;
    for (const binding of this._signalBindings) binding.detach();
    this._signalBindings.length = 0;
    this._goalManager.destroy();
  }
}
